import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Status: string;
}
interface UserProperties {
  'Hidden from Search': boolean;
}

export const mixpanelTrackToggledHideMeFromSearchResults = async (
  eventProperties: EventProperties,
  userProperties: UserProperties
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Toggled - Hide me from Search Results',
    payload: {
      event_properties: eventProperties,
      user_properties: userProperties,
    },
  });
};
