import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import CloseIcon from '../../atoms/icons/CloseIcon';
import { backgroundTheme, textColor } from '../../style';
import Headline from '../../components/common/Headline';
import { SITE_NAME, s3BucketDirect } from '../../config/Master';
import Button from '../../atoms/buttons/Button';
import { getBrowserDetails } from '../../utils/helpers';

const ImgIDV = `${s3BucketDirect}idv/idv.png`;

ReactModal.setAppElement('body');

const defaultStyle = {
  overlay: {
    zIndex: 8000,
    backgroundColor: 'rgba(0,0,0,0.65)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '420px',
    transform: 'scale(1)',
    margin: '0 auto',
    outline: 'none',
    bottom: 'initial',
    ':focus': {
      outline: 'none',
    },
  },
};

const setModalStyle = customStyle => {
  const customOverlay = customStyle.overlay || {};
  const customContent = customStyle.content || {};

  return {
    overlay: {
      zIndex: 8000,
      backgroundColor: 'rgba(0,0,0,0.65)',
      overflowY: 'auto',
      ...customOverlay,
    },
    content: {
      maxWidth: '520px',
      transform: 'scale(1)',
      margin: '0 auto',
      outline: 'none',
      top: getBrowserDetails().isSmallViewPort ? 0 : 82,
      height: getBrowserDetails().isSmallViewPort ? '100%' : 'auto',
      bottom: 'initial',
      ':focus': {
        outline: 'none',
      },
      backgroundColor: backgroundTheme.block,
      maxHeight: '80vh',
      overflow: 'auto',
      ...customContent,
      ...textColor.DARK,
    },
  };
};

const Content = styled('div')({
  position: 'relative',
  minHeight: '75px',
});

const IconContainer = styled('div')({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  fontSize: '28px',
  lineHeight: '16px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const Description = styled('p')({
  margin: '16px auto 24px',
  maxWidth: '360px',
  // textAlign: 'left',
});

interface ConfirmVerificationModalProps {
  closeOnOverlayClick: any;
  onClose: any;
  hideClose: any;
  onConfirm: any;
  [keys: string]: any;
}

const ConfirmVerificationModal = ({
  closeOnOverlayClick,
  onClose,
  hideClose,
  onConfirm,
  ...rest
}: ConfirmVerificationModalProps): ReactElement => {
  return (
    <ReactModal
      style={setModalStyle(defaultStyle)}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={onClose}
      {...rest}
    >
      <Content>
        {!hideClose && (
          <IconContainer onClick={onClose}>
            <CloseIcon />
          </IconContainer>
        )}
        <Headline style={{ textAlign: 'center' }}>Get Verified</Headline>
        <Headline style={{ textAlign: 'center' }}>
          <img src={ImgIDV} width="200" style={{ margin: 20 }} />
        </Headline>

        <Description>Want higher quality dates?</Description>
        <Description>
          {SITE_NAME} has partnered with Au10tix.com to individually verify the identity of all members who complete
          this process.
        </Description>
        <Description>
          Members who do receive a blue checkmark icon and can be searched by verification on the dashboard. Purchasing
          an ID verification costs $4.99, and will begin immediately.
        </Description>
        <Headline style={{ textAlign: 'center' }}>
          <Button buttonType="primary" onClick={onConfirm}>
            Buy Verification
          </Button>
        </Headline>
      </Content>
    </ReactModal>
  );
};

ConfirmVerificationModal.propTypes = {
  /* Name of modal - Required by react-modal for accessibility*/
  contentLabel: PropTypes.string.isRequired,
  /* Bool to determine if modal is open */
  isOpen: PropTypes.bool.isRequired,
  /* Bool to determine if modal will close on clicking overlay */
  closeOnOverlayClick: PropTypes.bool,
  /* Function to handle closing modal; called on clicking close button or overlay click (optional)*/
  onClose: PropTypes.func.isRequired,
  /* Bool to determine if close icon on top right of modal should be hidden */
  hideClose: PropTypes.bool,
};

ConfirmVerificationModal.defaultProps = {
  closeOnOverlayClick: false,
  customStyle: {},
};

export default ConfirmVerificationModal;
