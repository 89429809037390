import React, { ReactElement, SVGProps } from 'react';

const WYPButtonIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  const isFD = process.env.REACT_APP_NAME === 'fd';
  return isFD ? (
    <svg width="19" height="18" viewBox="0 0 90 70" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="#FFFFFF"
            d="M63.53,0A25.41,25.41,0,0,0,44.2,8.7,25.43,25.43,0,0,0,24.86,0C11.13,0,0,10.47,0,23.38c0,4.4,1.86,9.29,4.81,14.28l.39-.15h0a115.32,115.32,0,0,1,33.71-6.63h.15l2.17-.06h2.19l.84,0,1.2,0,1.07,0,2.65.14c-.43-.15-1.29-.43-2.51-.74l-3-3.08A1.58,1.58,0,0,1,43.17,26a1.64,1.64,0,0,1,.49-1.16l3.6-3.55a1.66,1.66,0,0,1,1.16-.47h0a1.64,1.64,0,0,1,1.16.49L63.81,35.81,49.35,50a1.65,1.65,0,0,1-1.15.47h0A1.66,1.66,0,0,1,47,50L43.48,46.4a1.64,1.64,0,0,1,0-2.32L46,41.59a18.21,18.21,0,0,1,5-.79v-.06c-1.06-.11-2.34-.21-3.81-.28h0c-.49,0-1,0-1.54-.06h-.39l-1.32,0H41.17l-1.32,0-.74,0-1.75.09-.8.06-1.38.1-1,.08-1.42.15-1.21.13-1.67.21-1.05.15-.56.08h0a108.05,108.05,0,0,0-17.88,4.28h0c-.94.31-1.9.63-2.86,1s-2,.72-3,1.11L.27,49.58v.06s1.94.54,4.49,1a66,66,0,0,0,10.82,1.19A227.69,227.69,0,0,0,44.2,77.94S88.4,44.88,88.4,23.38C88.4,10.47,77.27,0,63.53,0Z"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.69092 9.45868C8.78818 9.35018 8.89108 9.24447 8.99539 9.14153C7.85078 8.03848 6.31288 7.25536 5.00194 6.46946C4.97092 6.44303 4.92723 6.41521 4.86662 6.38739C4.46629 6.14536 4.09273 5.90055 3.75865 5.6446C2.404 4.31065 3.44713 2.64843 5.0837 2.73189C5.97035 2.7764 7.02616 3.60125 7.57027 4.08114C7.42931 3.23264 7.97483 2.15463 9.025 1.28109C8.06646 0.543874 6.69771 0 5.05973 0C4.9963 0 4.93427 0.00417294 4.87084 0.00695491C4.6749 0.0139098 4.48319 0.0292106 4.29571 0.0542483C1.95856 0.365828 0.355829 2.06143 0.0541697 4.12148C0.034435 4.25362 0.0203405 4.38716 0.0118828 4.52208C0.0203405 4.38716 0.034435 4.25362 0.0541697 4.12148C0.0118811 4.38437 -0.00644329 4.65283 0.00201443 4.9199C0.00060481 4.82114 0.00483195 4.72377 0.00906081 4.6278C0.00483195 4.72656 0.00060481 4.82532 0.00201443 4.92547V4.9199C0.00201443 4.9199 0.00201443 4.92825 0.00201443 4.93242C0.00201443 4.97137 0.00341958 5.00753 0.00623882 5.04509C0.00905806 5.08265 0.0118828 5.11881 0.0118828 5.15637C0.210639 8.34033 3.21736 8.98574 4.87366 9.98586C5.99572 10.6619 7.05153 11.2489 7.9114 12C7.78312 11.1765 7.96074 10.2724 8.69092 9.45868ZM6.27059 0.11267C6.23535 0.105715 6.20011 0.100151 6.16487 0.0945868C6.20011 0.100151 6.23535 0.107106 6.27059 0.11267ZM5.34588 0.00834589C5.31487 0.00834589 5.28245 0.00556393 5.25144 0.00556393C5.28245 0.00556393 5.31487 0.00695491 5.34588 0.00834589Z"
        fill="white"
      />
      <path
        d="M9.26487 14.7693C8.34622 14.7693 7.6001 15.4928 7.6001 16.3825C7.6001 17.2721 8.34622 18.0001 9.26487 18.0001C10.1835 18.0001 10.9251 17.2766 10.9251 16.3825C10.9251 15.4884 10.182 14.7693 9.26487 14.7693Z"
        fill="white"
      />
      <path
        d="M13.6108 0.00697679C13.7704 -6.75756e-09 13.8435 0 13.7499 0C13.7001 0 13.6547 0.00697679 13.605 0.00697679C13.5216 0.00837215 13.4338 0.015349 13.3489 0.019535C12.9055 0.0418608 12.4826 0.104652 12.0845 0.19535C12.247 0.159071 12.4123 0.129768 12.5762 0.106047C12.4123 0.132559 12.2484 0.159071 12.0801 0.196746C8.79331 0.943263 7.11332 3.74096 8.6499 4.82097C9.65379 5.52562 10.6138 4.53771 10.7645 4.43585C10.7645 4.43585 12.424 2.80188 13.725 2.73909C15.4152 2.65676 16.4937 4.30887 15.1167 5.64423C13.4967 6.8568 10.8904 7.81262 9.29818 9.52752C7.92258 11.0094 8.42892 12.7899 9.31428 13.8462C10.2435 12.0266 11.9996 11.1503 13.9416 10.0187C15.702 8.9917 18.9289 8.34006 18.9991 4.94795C19.0562 2.26606 16.8698 -0.050233 13.6094 0.00697679H13.6108Z"
        fill="white"
      />
    </svg>
  );
};

export default WYPButtonIcon;
