import React, { useState } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, buttonTheme, type } from '../../../../../style';
import { commonIcons } from '../../../../../atoms/icons/materialIcons';
import { auth10tixVerification } from '../../../../../config/Master';
import { useOffers } from '../../../../../contexts/OffersContext';
import { Account } from '../../../../../models/Account';
import OneTapSwipeOfferMediaSlider from './OneTapSwipeOfferMediaSlider';
import OneTapSwipeOfferMediaCounter from './OneTapSwipeOfferMediaCounter';

const CardContainer = styled('div')({
  position: 'relative',
  border: '1px solid #DAE1E9',
  backgroundColor: '#fff',
  width: 'calc(100vw - 40px)',
  borderRadius: 10,
  overflow: 'hidden',
});

const PhotoAspectRatio = styled('div')(({ paddingTop }) => ({
  paddingTop: paddingTop || '133%',
  position: 'relative',
}));

const AccountDetailsContainer = styled('div')({
  color: 'white',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  zIndex: 3,
});

const AccountDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'bottom',
  paddingLeft: '12px',
  paddingBottom: '10px',
  flexWrap: 'wrap',
  width: '75%',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

const UsernameContainer = styled('div')({
  ...type.MD,
  width: '100%',
  color: buttonTheme.GreyAndWhite,
});

const Username = styled('div')({
  width: 'auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  maxWidth: '80%',
});

const UserDetails = styled('div')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
  width: '85%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  position: 'absolute',
  width: '100%',
  height: 74,
  bottom: '-1px',
  left: 0,
  zIndex: 2,
});

const OnlineDot = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: 'lightgreen',
  borderRadius: '50%',
  display: 'inline-block',
});

const VerifiedBadge = styled('span')({
  marginLeft: 4,
  zIndex: 2,
});

const BackgroundOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5))',
  zIndex: 1,
});

interface CardProps {
  profile: any;
  account: Account;
}

const OneTapSwipeOfferProfileCard = ({ profile, account }: CardProps): JSX.Element => {
  const { photoAspectRatioRef, oneTapMediaRatioValues, oneTapActiveAccount } = useOffers();
  const [showToolTip, setShowToolTip] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const galleries = [
    ...account.data.photo.data.filter(item => item.status === 'approved'),
    ...account.data.video.data.filter(item => item.status === 'approved'),
  ].filter(Boolean);

  const publicPhotosVideos = galleries.filter(item => !item.private);
  const privatePhotosVideos = galleries.filter(item => item.private);
  const sortedGalleries = [...publicPhotosVideos, ...privatePhotosVideos].filter(Boolean);

  const isVerified = account.data.id_verification?.data?.status === 'approved';

  const handleSlide = (step: number) => {
    const nextSlide = currentSlide + step;

    if (nextSlide >= 0 && nextSlide < galleries.length) {
      setCurrentSlide(nextSlide);
    }
  };

  return (
    <CardContainer className="card">
      {oneTapActiveAccount?.hash_id !== account.data.hash_id && <BackgroundOverlay />}
      <PhotoAspectRatio paddingTop={oneTapMediaRatioValues} ref={photoAspectRatioRef}>
        <AccountDetailsContainer>
          <AccountDetails>
            <UsernameContainer>
              <Username>{account.data.username}</Username>
              {auth10tixVerification && isVerified && (
                <VerifiedBadge
                  data-test-id="data-verified-badge"
                  onMouseEnter={() => setShowToolTip(true)}
                  onMouseLeave={() => setShowToolTip(false)}
                >
                  <commonIcons.check.badge showToolTip={showToolTip} />
                </VerifiedBadge>
              )}
            </UsernameContainer>
            <UserDetails data-test-id="data-member-age-city">
              {profile.online ? <OnlineDot /> : ''} {profile.age} &middot; {profile.city}
            </UserDetails>
          </AccountDetails>
        </AccountDetailsContainer>
        <Gradient />
        <OneTapSwipeOfferMediaSlider photos={sortedGalleries} currentSlide={currentSlide} handleSlide={handleSlide} />
      </PhotoAspectRatio>
      <OneTapSwipeOfferMediaCounter
        photos={sortedGalleries}
        currentSlide={currentSlide}
        hashId={account.data.hash_id}
        otherAccountIsGenerous={account.getProfile().isGenerous()}
      />
    </CardContainer>
  );
};

export default OneTapSwipeOfferProfileCard;
