import React, { ReactElement } from 'react';

const DashboardDiscoverModeInfoIcon = ({
  customStyle,
  ...rest
}: {
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <path
        d="M15.4918 22.5C15.8879 22.5 16.2227 22.3652 16.4961 22.0957C16.7695 21.8262 16.9062 21.4922 16.9062 21.0938V14.5312C16.9062 14.1328 16.7723 13.7988 16.5043 13.5293C16.2364 13.2598 15.9043 13.125 15.5082 13.125C15.1121 13.125 14.7773 13.2598 14.5039 13.5293C14.2305 13.7988 14.0938 14.1328 14.0938 14.5312V21.0938C14.0938 21.4922 14.2277 21.8262 14.4957 22.0957C14.7636 22.3652 15.0957 22.5 15.4918 22.5ZM15.4918 10.3125C15.8879 10.3125 16.2227 10.1785 16.4961 9.91059C16.7695 9.64262 16.9062 9.31059 16.9062 8.91449C16.9062 8.51837 16.7723 8.18359 16.5043 7.91016C16.2364 7.63672 15.9043 7.5 15.5082 7.5C15.1121 7.5 14.7773 7.63397 14.5039 7.90191C14.2305 8.16988 14.0938 8.50191 14.0938 8.89801C14.0938 9.29413 14.2277 9.62891 14.4957 9.90234C14.7636 10.1758 15.0957 10.3125 15.4918 10.3125ZM15.5108 30C13.4463 30 11.5026 29.6094 9.67969 28.8281C7.85677 28.0469 6.26172 26.9727 4.89453 25.6055C3.52734 24.2383 2.45312 22.6438 1.67188 20.822C0.890625 19.0001 0.5 17.0535 0.5 14.9821C0.5 12.9107 0.890625 10.9701 1.67188 9.16016C2.45312 7.35026 3.52734 5.76172 4.89453 4.39453C6.26172 3.02734 7.85622 1.95312 9.67805 1.17188C11.4999 0.390625 13.4465 0 15.5179 0C17.5893 0 19.5299 0.390625 21.3398 1.17188C23.1497 1.95312 24.7383 3.02734 26.1055 4.39453C27.4727 5.76172 28.5469 7.35318 29.3281 9.16891C30.1094 10.9847 30.5 12.9248 30.5 14.9892C30.5 17.0537 30.1094 18.9974 29.3281 20.8203C28.5469 22.6432 27.4727 24.2383 26.1055 25.6055C24.7383 26.9727 23.1468 28.0469 21.3311 28.8281C19.5153 29.6094 17.5752 30 15.5108 30Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardDiscoverModeInfoIcon;
