import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import ProfileMediaRenderDots from './ProfileMediaRenderDots';

const MediaCounterContainer = styled('div')({
  position: 'absolute',
  height: 50,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  bottom: 0,
  zIndex: 2,
});
const CounterWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const DotsWrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '5px 8px',
  borderRadius: '8px',
  background: '#262626',
  gap: 6,
});

interface ProfileMediaCounterProps {
  photos: any;
  currentSlide: number;
}

const ProfileMediaCounter = ({ photos, currentSlide }: ProfileMediaCounterProps): ReactElement => {
  return (
    <MediaCounterContainer>
      <CounterWrapper>
        <DotsWrapper>
          {photos.map((item, index) => {
            return (
              <ProfileMediaRenderDots
                key={index}
                item={item}
                index={index}
                currentSlide={currentSlide}
                photos={photos}
              />
            );
          })}
        </DotsWrapper>
      </CounterWrapper>
    </MediaCounterContainer>
  );
};

export default ProfileMediaCounter;
