import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { backgroundTheme, textColor, dimensions } from '../../style';
import { addCommas, getBrowserDetails, isDarkMode } from '../../utils/helpers';
import '../../style/customSWui.css';
import { handlePackagesVysionEvent } from '../../utils/vysion/packages';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { PackagesClickVysionEventsEnum } from '../../utils/vysion/packages/click';
import CheckIcon from '../icons/CheckIcon';
import { useApp } from '../../contexts/AppContext';

const { isMobile } = getBrowserDetails();

const slideInFromRightAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedCheckIcon = styled(CheckIcon)`
  animation: ${slideInFromRightAnimation} 0.2s ease-in-out;
`;

const PackageContainer = styled('div')({
  paddingRight: 20,
  paddingBottom: 8,
  paddingLeft: 20,
  minHeight: 'auto',
});

const Card = styled('div')(({ selected }) => ({
  width: '47%',
  marginTop: 'auto !important',
  cursor: 'pointer',
  borderRadius: 7,
  backgroundColor: backgroundTheme.packageCard,
  boxShadow: selected ? 'none' : '0px 3px 3px 0px rgba(0, 0, 0, 0.25)',
  border: selected ? 'solid 2px #FFAF00' : 'none',
  overflow: 'hidden',
  textAlign: 'center',
  float: 'left',
  position: 'relative',
  lineHeight: '30px',
  margin: selected ? '-6px 1%' : '0 1%',
  padding: '10px 0 0 !important',
}));

const CreditsPerCurrency = styled('b')({
  fontSize: getBrowserDetails().isMobile ? 18 : 26,
  color: backgroundTheme.greyText,
});

const CreditContainer = styled('div')({
  paddingTop: 0,
});

const CreditsTitle = styled('div')(() => ({
  fontSize: 36,
  fontWeight: 900,
  color: `#595959`,
  textAlign: 'center',
  marginTop: 30,
  marginBottom: 30,
  [dimensions.SCREEN_MAX_XS]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 24,
    marginTop: 0,
    marginBottom: 0,
  },
  [dimensions.SCREEN_MAX_MD]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 24,
    marginTop: 10,
    marginBottom: 15,
  },
}));

const CreditSubtitle = styled('span')(() => ({
  fontSize: 36,
  color: isDarkMode() ? `#AFB0B1` : `#595959`,
  fontWeight: 600,
  lineHeight: '12px',
  marginBottom: 10,
  marginLeft: 5,
  [dimensions.SCREEN_MAX_MD_2]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 0,
    marginBottom: 5,
    marginTop: 5,
  },
  [dimensions.SCREEN_MAX_MD]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 0,
    marginBottom: 5,
    marginTop: 5,
  },
  [dimensions.SCREEN_MAX_XS]: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 0,
    marginTop: 6,
    marginBottom: -1,
  },
}));

const PackageInfoLabel = styled('label')(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: isDarkMode() ? '#CFCFCF' : '#353946',
  marginTop: 15,
  marginBottom: 15,
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 14,
    marginBottom: 12,
    marginTop: 5,
  },
  [dimensions.SCREEN_MAX_XS]: {
    fontSize: 14,
    marginBottom: 3,
    marginTop: 0,
  },
}));

const DashedSeparator = styled('hr')({
  border: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
});

const Amount = styled('div')({
  fontSize: 26,
  fontWeight: '700',
  color: textColor.DARK,
  textAlign: 'center',
  lineHeight: '20px',
  margin: '0 auto',
  marginTop: 30,
  marginBottom: 20,
  [dimensions.SCREEN_MAX_MD_2]: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 18,
  },
  [dimensions.SCREEN_MAX_MD]: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 18,
  },
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: -2,
    marginBottom: -1,
    fontSize: 18,
  },
});

const PackageInfo = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ButtonContainer = styled('div')({
  paddingTop: 18,
  textAlign: 'center',
  display: 'flex',

  [dimensions.SCREEN_MAX_MD_2]: {
    paddingTop: 0,
  },
  [dimensions.SCREEN_MAX_MD]: {
    paddingTop: 10,
  },
  [dimensions.SCREEN_MAX_XS]: {
    paddingTop: 5,
  },
});

const renderCreditsPerCurrency = (credits, hasTopTag, price, currencyCode = '$', showDecimalPrice = false) => {
  const newPrice = (price / credits).toFixed(2);
  const creditsText = `${currencyCode}${newPrice}`;
  const totaPrice = showDecimalPrice ? `${currencyCode}${price}` : `${currencyCode}${parseInt(price).toFixed()}`;

  return isMobile ? (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 1 }}>
      <CreditsPerCurrency hasTopTag={hasTopTag}>{creditsText}</CreditsPerCurrency>
      <small style={{ fontWeight: 'normal', fontSize: 12, letterSpacing: -0.24, lineHeight: '12px', marginTop: 3 }}>
        per credit / {totaPrice} total
      </small>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <CreditsPerCurrency hasTopTag={hasTopTag}>{`${creditsText} / Credit`}</CreditsPerCurrency>
      <span style={{ fontWeight: 400, fontSize: 16, paddingTop: 10 }}>{`${totaPrice}`} total</span>
    </div>
  );
};

interface BillingPackagePromoVariantProps {
  selectPackage: any;
  credits: any;
  identifier: any;
  currencyCharCode: any;
  price: any;
  index: any;
  variant: 'vip' | 'special' | 'intro_credit_promo';
}

const BillingPackagePromoVariant = ({
  selectPackage,
  credits,
  identifier,
  currencyCharCode,
  price,
  index,
  variant,
}: BillingPackagePromoVariantProps): any => {
  const { selectedSpecialOrVipPromo, setSelectedSpecialOrVipPromo } = useApp();

  const variantStyle = () => {
    switch (variant) {
      case 'vip':
        return {
          color: '#BC2028',
        };
      case 'special':
        return {
          color: '#2B8FD7',
        };
      case 'intro_credit_promo':
        return {
          color: '#DD0B24',
        };
    }
  };

  const renderPackageInfo = () => {
    switch (parseInt(credits)) {
      case 1500: {
        return 'Save 60%!';
      }
      case 300: {
        return 'Save 34%!';
      }
      case 250: {
        return variant === 'vip' ? 'Save 34%!' : 'Save 20%!';
      }
      case 20: {
        return isMobile ? 'Get Started!' : 'Special Welcome Credit Pack!';
      }
    }
  };

  const introCreditCustomStyle = {
    packageInfo: {
      paddingBottom: 8,
    },
    packageInfoLabel: {
      fontSize: 13,
      marginBottom: 0,
      letterSpacing: -0.26,
      lineHeight: '15px',
      marginTop: 6,
    },
    packageCredit: {
      lineHeight: '18px',
    },
  };

  return (
    <Card
      id={`package-${variant}-${index}`}
      data-test-id={`package-${variant}-${index}`}
      onClick={() => {
        setSelectedSpecialOrVipPromo(identifier);
        selectPackage(identifier);
        handlePackagesVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PackagesClickVysionEventsEnum.CLICK_PACKAGE_SELECTED_PACKAGE,
          component: `${credits} credits`,
          newEventName: `packages-${credits}credits`,
        });
      }}
      selected={selectedSpecialOrVipPromo === identifier}
    >
      <PackageContainer>
        <CreditContainer>
          <CreditsTitle style={{ color: selectedSpecialOrVipPromo === identifier ? variantStyle().color : 'unset' }}>
            <b
              style={{
                fontWeight: 'bolder',
                ...(variant === 'intro_credit_promo' && isMobile ? introCreditCustomStyle.packageCredit : {}),
              }}
            >
              {addCommas(credits)}
            </b>
            <CreditSubtitle
              style={selectedSpecialOrVipPromo === identifier && !isMobile ? { color: variantStyle().color } : {}}
            >
              Credits
            </CreditSubtitle>
          </CreditsTitle>
          <DashedSeparator />
        </CreditContainer>

        {isMobile ? (
          <Fragment>
            <ButtonContainer>
              <Amount>
                {renderCreditsPerCurrency(
                  credits,
                  true,
                  price,
                  String.fromCharCode(currencyCharCode),
                  variant === 'intro_credit_promo'
                )}
              </Amount>
            </ButtonContainer>
            <DashedSeparator style={{ marginBottom: 9, marginTop: 12 }} />

            <PackageInfo style={variant === 'intro_credit_promo' ? introCreditCustomStyle.packageInfo : {}}>
              {selectedSpecialOrVipPromo === identifier && (
                <AnimatedCheckIcon customStyle={{ marginRight: 10 }} strokeColor={variantStyle().color} />
              )}
              <PackageInfoLabel style={variant === 'intro_credit_promo' ? introCreditCustomStyle.packageInfoLabel : {}}>
                {renderPackageInfo()}
              </PackageInfoLabel>
            </PackageInfo>
          </Fragment>
        ) : (
          <Fragment>
            <PackageInfo>
              {selectedSpecialOrVipPromo === identifier && (
                <AnimatedCheckIcon customStyle={{ marginRight: 10 }} strokeColor={variantStyle().color} />
              )}
              <PackageInfoLabel>{renderPackageInfo()}</PackageInfoLabel>
            </PackageInfo>
            <DashedSeparator />
            <Amount>
              {renderCreditsPerCurrency(
                credits,
                true,
                price,
                String.fromCharCode(currencyCharCode),
                variant === 'intro_credit_promo'
              )}
            </Amount>
          </Fragment>
        )}
      </PackageContainer>
    </Card>
  );
};

export default BillingPackagePromoVariant;
