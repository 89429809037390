import React, { ReactElement } from 'react';

interface BuyCTAIconProps {
  customStyle?: React.CSSProperties;
}

const BuyCTAIcon = ({ customStyle }: BuyCTAIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" style={customStyle}>
      <path
        d="M5.99084 10.25H7.07416V7.585H9.75V6.50168H7.07416V3.75H5.99084V6.50168H3.25V7.585H5.99084V10.25ZM6.50288 13.5C5.60564 13.5 4.76245 13.3294 3.9733 12.9881C3.18416 12.6469 2.49528 12.1819 1.90666 11.5933C1.31805 11.0047 0.853125 10.3156 0.511875 9.5259C0.170625 8.73621 0 7.89244 0 6.99459C0 6.09672 0.170625 5.25295 0.511875 4.46328C0.853125 3.67359 1.31805 2.98625 1.90666 2.40125C2.49528 1.81625 3.18443 1.35312 3.9741 1.01187C4.76379 0.670625 5.60756 0.5 6.50541 0.5C7.40328 0.5 8.24705 0.670625 9.03672 1.01187C9.82641 1.35312 10.5138 1.81625 11.0988 2.40125C11.6838 2.98625 12.1469 3.67417 12.4881 4.465C12.8294 5.25583 13 6.09987 13 6.99712C13 7.89436 12.8294 8.73755 12.4881 9.5267C12.1469 10.3158 11.6838 11.0041 11.0988 11.5913C10.5138 12.1786 9.82583 12.6435 9.035 12.9861C8.24417 13.3287 7.40013 13.5 6.50288 13.5Z"
        fill="white"
      />
    </svg>
  );
};

export default BuyCTAIcon;
