import * as sdk from '../sdk';
import store from '../store';
import { saveToken } from '../actions/authActions';
import { getBiometricsList, refreshProfile } from '../actions/profileActions';
import { setCookie, getCookie, getBrowserDetails } from './helpers';
import { NavigateFunction } from 'react-router-dom';

const cose_alg_ECDSA_w_SHA256 = -7;
const challegeValue = new Uint8Array(26);

const getCredentialInfo = {
  // Format of new credentials is publicKey
  publicKey: {
    // Relying Party
    rp: {
      name: 'members.whatsyourprice.com',
      id: 'localhost',
      icon: 'https://login.example.com/login.ico',
    },
    // Cryptographic challenge from the server
    challenge: challegeValue,
    // User
    user: {
      id: new Uint8Array(16),
      name: 'john.p.smith@example.com',
      displayName: 'John P. Smith',
    },
    // Requested format of new keypair
    pubKeyCredParams: [
      {
        type: 'public-key',
        alg: cose_alg_ECDSA_w_SHA256,
      },
    ],
    // Timeout after 1 minute
    timeout: 60000,
    // Do not send the authenticator's origin attestation
    attestation: 'none',
    extensions: {
      uvm: true,
      exts: true,
    },
    // Filter out authenticators which are bound to the device
    authenticatorSelection: {
      requireResidentKey: false,
      userVerification: 'preferred',
    },
    // session needed?
    session: {
      id: 5885838135132160,
      challenge: challegeValue,
      origin: 'members.whatsyourprice.com',
      created: new Date(),
    },
  },
};

const _base64Decode = input => {
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, '+').replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    input += new Array(5 - pad).join('=');
  }

  return input;
};

const _bufferDecode = value => {
  const t = window.atob(value);
  return Uint8Array.from(t, c => c.charCodeAt(0));
};

const _bufferEncode = value => {
  return window.btoa(String.fromCharCode.apply(null, new Uint8Array(value)));
};

const _credentialDecode = credentials => {
  return credentials.map(data => {
    return {
      id: _bufferDecode(_base64Decode(data.id)),
      type: data.type,
      transports: data.transports,
    };
  });
};

/*
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

const advancedOptions = {
  requireResidentKey: false,
  excludeCredentials: false,
  userVerification: 'preferred',
  authenticatorAttachment: 'none',
  attestationConveyancePreference: 'none',
};

const serializeUvm = (uvm): any[] => {
  const uvmJson = [];
  for (const arr of uvm) {
    const uvmEntry = {};
    uvmEntry.userVerificationMethod = arr[0];
    uvmEntry.keyProtectionType = arr[1];
    uvmEntry.atchuvmJsonerProtectionType = arr[2];
    uvmJson.push(uvmEntry);
  }
  return uvmJson;
};

const removeCurrentCredentials = (id: string | number): void => {
  const registeredID = getCookie('biometric_id');
  if (registeredID === id.toString()) {
    setCookie('biometric_hash_id', '');
    setCookie('biometric_id', '');
  }
  sdk.deleteBiometric(id).then(res => {
    if (res.data.result) {
      store.dispatch(refreshProfile()).then(() => {
        store.dispatch(getBiometricsList());
      });
    }
  });
};

const checkBiometricKeys = (): void => {
  sdk
    .getBiometricKeys()
    .then(res => {
      console.log('Biometric key exists', res);
    })
    .catch(() => {
      setCookie('biometric_hash_id', '');
    });
};

const registerNewCredential = (): void => {
  makeCredential(advancedOptions);
};

const registerPlatformAuthenticator = (): void => {
  makeCredential(advancedOptions);
};

const getCredentialData = (publicKeyCredential, prop, attestation) => {
  if (prop in attestation) {
    publicKeyCredential[prop] = prop === 'rawId' ? binToStr(attestation[prop]) : attestation[prop];
  }
};

const makeCredential = options => {
  const formData = {
    advanced: true,
    advancedOptions: JSON.stringify(options),
    browser: getBrowserDetails().browserName(),
    platform: getBrowserDetails().deviceName(),
  };

  sdk.getCredentialPublicKey(formData).then(res => {
    const credentialInfo = res.data;
    credentialInfo.publicKey.user.id = _bufferDecode(credentialInfo.publicKey.user.id);
    credentialInfo.publicKey.challenge = _bufferDecode(_base64Decode(credentialInfo.publicKey.challenge));
    if (credentialInfo.publicKey.excludeCredentials) {
      credentialInfo.publicKey.excludeCredentials = _credentialDecode(credentialInfo.publicKey.excludeCredentials);
    }

    // Mock credentials Info
    navigator.credentials
      .create(credentialInfo)
      .then(attestation => {
        const publicKeyCredential = {};

        for (const key in attestation) {
          getCredentialData(publicKeyCredential, key, attestation);
        }

        if (!attestation.response) {
          showErrorMsg("Make Credential response lacking 'response' attribute");
        }

        const response = {};
        response.clientDataJSON = binToStr(attestation.response.clientDataJSON);
        response.attestationObject = binToStr(attestation.response.attestationObject);

        // Check for included extensions
        if (attestation.getClientExtensionResults) {
          publicKeyCredential.extensions = attestation.getClientExtensionResults();
          if (attestation.getClientExtensionResults().uvm != null) {
            publicKeyCredential.uvm = serializeUvm(attestation.getClientExtensionResults().uvm);
          }
        }

        // Check if transports are included in the registration response.
        if (attestation.response.getTransports) {
          response.transports = attestation.response.getTransports();
        }

        publicKeyCredential.response = response;

        const displayName = `${credentialInfo.publicKey.user.displayName} ${getBrowserDetails().deviceName()}`;

        sdk
          .registerBiometric(
            JSON.stringify({
              register: publicKeyCredential,
              name: displayName,
              browser: getBrowserDetails().browserName(),
              platform: getBrowserDetails().deviceName(),
            })
          )
          .then(resData => {
            setCookie('biometric_hash_id', resData.data.data.hash_id);
            setCookie('biometric_id', resData.data.data.id);
            store.dispatch(refreshProfile()).then(() => {
              store.dispatch(getBiometricsList());
            });
          });

        return publicKeyCredential;
      })
      .then(parameters => {
        console.log('received attestation parameters:', parameters);
      })
      .catch(err => {
        console.log(err.toString());
      });
  });
};

// User Verifying Platform Authenticator Availability
const isUVPAA = (): Promise<any> | boolean => {
  if (typeof PublicKeyCredential === 'undefined' || getBrowserDetails().isIos) {
    return false;
  }

  if (PublicKeyCredential && PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
    PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      .then(response => {
        return response;
      })
      .catch(() => {
        return false;
      });
  }
  return true;
};

const getAssertion = (navigate: NavigateFunction): void => {
  const biometricHashId = getCookie('biometric_hash_id');
  sdk
    .getAuthenticationPublicKey({
      token: biometricHashId,
      browser: getBrowserDetails().browserName(),
      platform: getBrowserDetails().deviceName(),
    })
    .then(res => {
      const credentialInfo = res.data;
      const publicKeyCredentialOptions = credentialInfo.publicKey;
      publicKeyCredentialOptions.challenge = _bufferDecode(_base64Decode(credentialInfo.publicKey.challenge));
      if (credentialInfo.publicKey.allowCredentials) {
        publicKeyCredentialOptions.allowCredentials = _credentialDecode(credentialInfo.publicKey.allowCredentials);
      }

      navigator.credentials
        .get({
          publicKey: publicKeyCredentialOptions,
        })
        .then(assertion => {
          const publicKeyCredential = {};

          if ('id' in assertion) {
            publicKeyCredential.id = assertion.id;
          }
          if ('type' in assertion) {
            publicKeyCredential.type = assertion.type;
          }
          if ('rawId' in assertion) {
            publicKeyCredential.rawId = _bufferEncode(assertion.rawId);
          }
          if (!assertion.response) {
            throw new Error("Get assertion response lacking 'response' attribute");
          }
          if (assertion.getClientExtensionResults) {
            if (assertion.getClientExtensionResults().uvm != null) {
              publicKeyCredential.uvm = serializeUvm(assertion.getClientExtensionResults().uvm);
            }
          }

          const _response = assertion.response;

          publicKeyCredential.response = {
            clientDataJSON: _bufferEncode(_response.clientDataJSON),
            authenticatorData: _bufferEncode(_response.authenticatorData),
            signature: _bufferEncode(_response.signature),
            userHandle: _bufferEncode(_response.userHandle),
          };

          sdk
            .authenticateBiometric(
              JSON.stringify({
                token: biometricHashId,
                data: publicKeyCredential,
                browser: getBrowserDetails().browserName(),
                platform: getBrowserDetails().deviceName(),
              })
            )
            .then(resData => {
              store.dispatch(saveToken(resData.data));

              // retrieve logged in profile.
              store.dispatch(refreshProfile()).then(() => {
                navigate('/dashboard');
              });
            });

          return publicKeyCredential;
        });
    });
};

function binToStr(bin) {
  return btoa(new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), ''));
}

export {
  registerNewCredential,
  registerPlatformAuthenticator,
  getAssertion,
  isUVPAA,
  removeCurrentCredentials,
  checkBiometricKeys,
  getCredentialInfo,
};
