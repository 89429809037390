import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import styled from '@emotion/styled';
import 'react-image-crop/dist/ReactCrop.css';

import RotateButton from '../../atoms/buttons/RotateButton';
import RotateRight from '../../atoms/icons/RotateRight';
import RotateLeft from '../../atoms/icons/RotateLeft';
import { theme } from '../../theme';
import colors, { white } from '../../theme/colors';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import GalleryUploadMobileActionButtonContainer from './GalleryUpload/components/GalleryUploadMobileActionButtonContainer';
import { dimensions } from '../../style';

const PhotoCropContainer = styled('div')({
  // maxWidth: '400px',
});

const HiddenCanvas = styled('div')({
  display: 'none',
});

const StyledRotateActionMobileWrapper = styled.div`
  display: none;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -5px;
  height: 40px;

  ${dimensions.SCREEN_MAX_XS} {
    display: block;
  }
`;

const StyledReactCrop = styled(ReactCrop)({
  '.ReactCrop__crop-selection': {
    border: 'none',
  },
  '.ReactCrop__drag-handle': {
    borderRadius: '50%',
    backgroundColor: white,
    height: '20px',
    width: '20px',
  },
});

const CropContainer = styled('div')({
  // set different bg color from figma file. because the cropper bg is black
  // and when following bg color from figma. the result is different
  backgroundColor: theme === 'light' ? colors.gray[100] : '#2970a4',
  overflow: 'hidden',
  minHeight: 320,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  marginTop: 31,
  marginBottom: -5,
  padding: 38,

  [dimensions.SCREEN_MAX_XS]: {
    height: '100%',
  },
});

const StyledDesktopActionButtons = styled('div')({
  padding: '33px 5px',
  display: 'flex',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const RotateButtons = styled('div')({
  [dimensions.SCREEN_MAX_XS]: {
    marginRight: 'initial',
    marginLeft: 'initial',
  },
});

const ASPECT_RATIO = 3 / 4;
const DEFAULT_HEIGHT = 390;

const calculateMaxCropHeight = imageWidth => {
  return (4 / 3) * imageWidth;
};

const getCropHeight = (imageWidth, imageHeight) => {
  const imageAspectRatio = imageWidth / imageHeight;
  // If image has an aspect ratio less than 3:4,
  // it is longer in height and the crop height will
  // be restricted to max possible 3:4 crop area that
  // can be made on the image
  if (imageAspectRatio < ASPECT_RATIO) {
    return calculateMaxCropHeight(imageWidth);
  } else {
    return imageHeight;
  }
};

const getBlob = dataUrl => {
  const arr = dataUrl.split(',');
  const mime = (arr && arr.length > 0 && arr[0].match(/:(.*?);/)[1]) || 'jpg';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const RotateActionButtons = ({
  handleRotateLeft,
  handleRotateRight,
  isLoading,
}: {
  handleRotateLeft: () => void;
  handleRotateRight: () => void;
  isLoading: boolean;
}) => {
  return (
    <RotateButtons>
      <RotateButton
        disabled={isLoading}
        icon={<RotateLeft fill={theme === 'light' ? colors.light.blue.tertiary : colors.dark.blue.secondary} />}
        onClick={handleRotateLeft}
        data-test-id="photo-rotate-left"
        customStyle={{
          backgroundColor: theme === 'light' ? colors.blue[100] : colors.blue[800],
          marginLeft: 0,
        }}
      />
      <RotateButton
        disabled={isLoading}
        icon={<RotateRight fill={theme === 'light' ? colors.light.blue.tertiary : colors.dark.blue.secondary} />}
        onClick={handleRotateRight}
        data-test-id="photo-rotate-right"
        customStyle={{
          backgroundColor: theme === 'light' ? colors.blue[100] : colors.blue[800],
        }}
      />
    </RotateButtons>
  );
};

interface PhotocropperProps {
  imageUrl: string;
  fileName?: string;
  onAddPhoto(image: Blob, isLastPrev?: boolean): void;
  onCancel(): void;
}

const PhotoCropper = ({ imageUrl, fileName, onAddPhoto, onCancel }: PhotocropperProps): JSX.Element => {
  const [crop, setCrop] = useState({});
  const [rotation, setRotation] = useState(360);
  const [currentImg, setCurrentImg] = useState('');
  const [imgUrls, setImgUrls] = useState({
    90: '',
    180: '',
    270: '',
    360: '',
  });
  const [cropCanvasWidth, setCropCanvasWidth] = useState(0);
  const [cropCanvasHeight, setCropCanvasHeight] = useState(0);
  const [loadingPhoto, setLoadingPhoto] = useState(true);
  const cropRef = useRef();
  const canvasRef90 = useRef();
  const canvasRef180 = useRef();
  const canvasRef270 = useRef();
  const canvasRef360 = useRef();
  const cropImageRef = useRef();
  const fullImageCanvasRef = useRef();

  const setCropRef = myCropRef => (cropRef.current = myCropRef);

  const set90DegCanvasRef = canvasRef => {
    canvasRef90.current = canvasRef;
  };

  const set180DegCanvasRef = canvasRef => {
    canvasRef180.current = canvasRef;
  };

  const set270DegCanvasRef = canvasRef => {
    canvasRef270.current = canvasRef;
  };

  const set360DegCanvasRef = canvasRef => {
    canvasRef360.current = canvasRef;
  };

  const setCropImageRef = cropMyImageRef => {
    cropImageRef.current = cropMyImageRef;
  };

  const setFullImageCanvasRef = myFullImageCanvasRef => {
    fullImageCanvasRef.current = myFullImageCanvasRef;
  };

  useEffect(() => {
    handleWindowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImg]);

  useEffect(() => {
    // Generate data urls for each rotation
    const newImgUrls = { ...imgUrls };
    const refs = [
      [90, canvasRef90.current],
      [180, canvasRef180.current],
      [270, canvasRef270.current],
      [360, canvasRef360.current],
    ];

    refs.map(([degree, cropCanvas]) => {
      const ctx = cropCanvas.getContext('2d');
      ctx.save();

      const image = new Image();
      image.crossOrigin = 'Use-Credentials';
      image.src = imageUrl;

      image.onload = () => {
        let imgWidth;
        let imgHeight;

        // Limit canvases to max width of 500 pixels
        // Allows for generating data urls to be faster than using original image widths
        // if (image.width > 500) {
        //   imgWidth = 500;
        //   imgHeight = (500 * image.height) / image.width;
        // } else {
        //   imgWidth = image.width;
        //   imgHeight = image.height;
        // }

        if (image.height > DEFAULT_HEIGHT) {
          imgHeight = DEFAULT_HEIGHT;
          imgWidth = (DEFAULT_HEIGHT * image.width) / image.height;
        } else {
          imgWidth = image.width;
          imgHeight = image.height;
        }

        // Set canvas width and height to match expected rotation of image
        const height = degree === 90 || degree === 270 ? imgWidth : imgHeight;
        const width = degree === 90 || degree === 270 ? imgHeight : imgWidth;
        cropCanvas.height = height;
        cropCanvas.width = width;

        // Rotate image about its center and draw to canvas
        ctx.translate(width / 2, height / 2);
        ctx.rotate((degree * Math.PI) / 180);
        ctx.translate(-imgWidth / 2, -imgHeight / 2);
        ctx.drawImage(image, 0, 0, imgWidth, imgHeight);

        // Create data url from canvas
        const imgUrl = cropCanvas.toDataURL();
        newImgUrls[degree] = imgUrl;

        // Set initial current image
        if (degree === 360) {
          setCurrentImg(imgUrl);
        }
      };

      ctx.restore();
    });

    setImgUrls(newImgUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const handleWindowResize = () => {
    // Currently, react-image-crop does not resize the crop selection on window resize
    // Will reset crop state on change of window resize and canvas width

    const resizedCanvasWidth = cropRef.current.componentRef.clientWidth;
    const copyResizedCanvasWidth = resizedCanvasWidth;
    const resizedCanvasHeight = cropRef.current.componentRef.clientHeight;

    if (resizedCanvasWidth !== cropCanvasWidth || resizedCanvasHeight !== cropCanvasHeight) {
      const newHeight = getCropHeight(resizedCanvasWidth, resizedCanvasHeight);

      const newCrop = makeAspectCrop({ aspect: 3 / 4, height: newHeight }, resizedCanvasWidth / copyResizedCanvasWidth);

      setCrop(newCrop);
      setCropCanvasWidth(resizedCanvasWidth);
      setCropCanvasHeight(resizedCanvasHeight);
    }
  };

  const handleOnChange = cropVal => {
    setCrop(cropVal);
  };

  const handleAddPhoto = () => {
    const imageCanvasWidth = cropRef.current.componentRef.clientWidth;
    const imageCanvasHeight = cropRef.current.componentRef.clientHeight;

    setLoadingPhoto(true);

    const fullImage = new Image();
    fullImage.crossOrigin = 'Use-Credentials';
    fullImage.src = imageUrl;

    fullImage.onload = async () => {
      const canvas = fullImageCanvasRef.current;

      const fullImageWidth = fullImage.width;
      const fullImageHeight = fullImage.height;

      // Set canvas width and height to match expected rotation of image
      const canvasWidth = rotation === 90 || rotation === 270 ? fullImageHeight : fullImageWidth;
      const canvasHeight = rotation === 90 || rotation === 270 ? fullImageWidth : fullImageHeight;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const ctx = canvas.getContext('2d');
      ctx.save();

      // Original image drawn is at 360deg
      // If rotation is not 360, will need to rotate image to appropriate deg
      if (rotation !== 360) {
        ctx.translate(canvasWidth / 2, canvasHeight / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-fullImageWidth / 2, -fullImageHeight / 2);
      }
      ctx.drawImage(fullImage, 0, 0, fullImageWidth, fullImageHeight);
      ctx.restore();

      // Generate data url from full image drawn to hidden canvas
      // This url will be used to get full-sized cropped image
      const fullImgUrl = await canvas.toDataURL();

      const croppedImage = new Image();
      croppedImage.crossOrigin = 'Use-Credentials';
      croppedImage.src = fullImgUrl;

      const percentWidth = crop.width / imageCanvasWidth;
      const percentHeight = crop.height / imageCanvasHeight;
      const percentX = crop.x / imageCanvasWidth;
      const percentY = crop.y / imageCanvasHeight;

      croppedImage.onload = () => {
        const croppedCanvas = cropImageRef.current;

        // Calculate crop values of full image size
        const pixelWidth = percentWidth * croppedImage.width;
        const pixelHeight = percentHeight * croppedImage.height;
        const pixelX = percentX * croppedImage.width;
        const pixelY = percentY * croppedImage.height;

        croppedCanvas.width = pixelWidth;
        croppedCanvas.height = pixelHeight;

        const croppedCtx = croppedCanvas.getContext('2d');
        croppedCtx.save();
        croppedCtx.drawImage(croppedImage, pixelX, pixelY, pixelWidth, pixelHeight, 0, 0, pixelWidth, pixelHeight);

        // Generate data url from cropped image drawn to hidden canvas
        // Image is in jpg format at 90% quality
        const croppedFileName = fileName ? fileName : 'blob';
        const croppedImgUrl = croppedCanvas.toDataURL('image/jpeg', 0.9);
        const croppedImgBlob = getBlob(croppedImgUrl);
        const croppedImgFile = new Blob([croppedImgBlob], { type: croppedImgBlob.type });
        croppedImgFile.name = croppedFileName;

        croppedCtx.restore();

        setLoadingPhoto(false);
        onAddPhoto(croppedImgFile);
      };
    };
  };

  const handleRotateRight = () => {
    let newRotation = rotation + 90;

    if (newRotation > 360) {
      newRotation = 90;
    }

    setRotation(newRotation);
    setCurrentImg(imgUrls[newRotation]);
  };

  const handleRotateLeft = () => {
    let newRotation = rotation - 90;

    if (newRotation < 0) {
      newRotation = 270;
    }

    if (newRotation === 0) {
      newRotation = 360;
    }

    setRotation(newRotation);
    setCurrentImg(imgUrls[newRotation]);
  };

  const refreshCropPosition = imageCrop => {
    setCrop({
      ...crop,
      ...imageCrop,
    });
  };

  const handleOnImageLoaded = image => {
    // Calc and set initial crop dimensions
    const maxCropImageHeight = getCropHeight(image.width, image.height);

    const newCrop = makeAspectCrop(
      { aspect: 3 / 4, height: maxCropImageHeight },
      image.naturalWidth / image.naturalHeight
    );

    const isLandscape = newCrop.width < image.width;
    const isPortrait = newCrop.height < image.height;

    let newX = 0;
    let newY = 0;

    if (isLandscape) {
      newX = image.width / 2 - newCrop.width / 2;
      newCrop.x = newX;
    }

    if (isPortrait) {
      newY = image.height / 2 - newCrop.height / 2;
      newCrop.y = newY;
    }

    setCropCanvasWidth(cropRef.current.componentRef.clientWidth);
    setCropCanvasHeight(cropRef.current.componentRef.clientHeight);
    setLoadingPhoto(false);
    setCrop(newCrop);
    setTimeout(() => {
      refreshCropPosition(newCrop);
    }, 100);
  };

  return (
    <PhotoCropContainer data-test-id="upload-photo-cropper">
      <HiddenCanvas>
        <canvas ref={set90DegCanvasRef} />
        <canvas ref={set180DegCanvasRef} />
        <canvas ref={set270DegCanvasRef} />
        <canvas ref={set360DegCanvasRef} />
        <canvas ref={setFullImageCanvasRef} />
        <canvas ref={setCropImageRef} />
      </HiddenCanvas>

      <CropContainer>
        <StyledReactCrop
          ref={ref => setCropRef(ref)}
          src={currentImg}
          onChange={handleOnChange}
          crop={crop}
          style={{
            overflow: 'visible',
            margin: '0 auto',
          }}
          keepSelection={true}
          onImageLoaded={handleOnImageLoaded}
          minWidth={64}
        />
      </CropContainer>
      {/* <BottomContent> */}
      <StyledDesktopActionButtons>
        <RotateActionButtons
          handleRotateLeft={handleRotateLeft}
          handleRotateRight={handleRotateRight}
          isLoading={loadingPhoto}
        />
        <PrimaryButton
          size="small"
          color="blue"
          label="Save and Next"
          onClick={handleAddPhoto}
          disabled={loadingPhoto}
          data-test-id="submit-photo-cropper"
          customStyle={{ width: 'inherit' }}
        />
      </StyledDesktopActionButtons>
      {/* </BottomContent> */}
      <StyledRotateActionMobileWrapper>
        <RotateActionButtons
          handleRotateLeft={handleRotateLeft}
          handleRotateRight={handleRotateRight}
          isLoading={loadingPhoto}
        />
      </StyledRotateActionMobileWrapper>
      <GalleryUploadMobileActionButtonContainer onCancel={onCancel}>
        <PrimaryButton
          size="small"
          color="blue"
          label="Save and Next"
          onClick={handleAddPhoto}
          disabled={loadingPhoto}
          data-test-id="submit-photo-cropper"
          customStyle={{ width: 'inherit', minWidth: 150 }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </PhotoCropContainer>
  );
};

PhotoCropper.defaultProps = {
  fileName: 'blob',
};

export default PhotoCropper;
