import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const CreditIcon = styled('span')(
  {
    display: 'inline-block',
    lineHeight: 0,
    backgroundColor: '#BC2028',
    borderRadius: '50%',
  },
  ({ height, padding }) => ({
    height: height,
    padding: padding,
  })
);

interface CreditsIconProps {
  height: any;
  padding: any;
}

const CreditsIcon = ({ height, padding }: CreditsIconProps): ReactElement => {
  return (
    <CreditIcon padding={padding} height={height}>
      <svg xmlns="http://www.w3.org/2000/svg" width={height} height={height} fill="#fff" viewBox="0 0 22 22">
        <polygon points="9 9 0 9 0 13 9 13 9 22 13 22 13 13 22 13 22 9 13 9 13 0 9 0" />
      </svg>
    </CreditIcon>
  );
};

CreditsIcon.defaultProps = {
  height: '8px',
  padding: '2px',
};

export default CreditsIcon;
