import React, { useMemo, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, amountButtonTheme } from '../../style';
import { getBrowserDetails } from '../../utils/helpers';

const DefaultButton = styled('div')(
  {
    color: '#0D5C93 !important',
    padding: 0,
    background: amountButtonTheme.backgroundStyle,
    borderRadius: '5px',
    width: 132,
    height: 72,
    border: amountButtonTheme.borderStyle,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [dimensions.SCREEN_MAX_MD_2]: {
      width: 88,
    },
    [dimensions.SCREEN_MAX_MD]: {
      padding: 0,
      minWidth: 90,
      width: '100%',
      maxWidth: 100,
      borderWidth: 2,
      height: 57,
    },
    [dimensions.SCREEN_MAX_SM]: {
      padding: 0,
      minWidth: 90,
      width: '100%',
      maxWidth: 100,
      borderWidth: 2,
      height: 57,
    },
    [dimensions.SCREEN_MAX_XS]: {
      minWidth: 90,
      maxWidth: 90,
    },
    [dimensions.SCREEN_MIN_XS_2]: {
      minWidth: 80,
      maxWidth: 100,
    },
    [dimensions.SCREEN_MIN_XS_3]: {
      minWidth: 80,
      maxWidth: 90,
    },
    [dimensions.SCREEN_MAX_XXS]: {
      minWidth: 60,
      maxWidth: 74,
    },
  },
  ({ viewType, type }) =>
    viewType === 'grid'
      ? {
          margin: '5px 15px',
        }
      : {
          margin: '5px 10px',
          [dimensions.SCREEN_MAX_SM]: {
            margin: type === 'sent' ? '5px 0px' : '5px 10px',
          },
          [dimensions.SCREEN_MIN_XS_2]: {
            minWidth: 80,
            maxWidth: 80,
          },
          [dimensions.SCREEN_MIN_XS_3]: {
            minWidth: 60,
            maxWidth: 80,
          },
          [dimensions.SCREEN_MAX_XXS]: {
            minWidth: 60,
            maxWidth: 80,
          },
        },

  ({ hasHoverEffect }) =>
    hasHoverEffect && {
      '&:hover': {
        border: amountButtonTheme.borderHoverStyle,
        backgroundColor: '#2490DE',
        '> label': {
          color: '#fff',
        },
      },
    },
  ({ customBoxStyle }) => ({ ...customBoxStyle })
);

const OfferLabel = styled('label')(
  {
    color: '#8F8F8F',
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: '-0.04em',
    [dimensions.SCREEN_MAX_MD_2]: {
      fontSize: 11,
    },
    [dimensions.SCREEN_MAX_MD]: {
      fontSize: 12,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 11,
    },
  },
  ({ viewType }) =>
    viewType === 'list' && {
      [dimensions.SCREEN_MIN_XS_2]: {
        fontSize: 10,
      },
    }
);

const Amount = styled('label')(
  {
    color: '#0D5C93',
    fontSize: 42,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    [dimensions.SCREEN_MAX_MD_2]: {
      fontSize: 25,
    },
    [dimensions.SCREEN_MAX_MD]: {
      fontSize: 30,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 28,
    },
    [dimensions.SCREEN_MAX_XXS]: {
      fontSize: 24,
      marginBottom: 1,
    },
  },
  ({ viewType }) =>
    viewType === 'list' && {
      [dimensions.SCREEN_MIN_XS_2]: {
        fontSize: 23,
      },
    }
);

const EmptyAmount = styled('label')(
  {
    color: '#0D5C93',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    maxWidth: 83,
    textAlign: 'center',
  },
  ({ viewType }) =>
    viewType === 'list' && {
      [dimensions.SCREEN_MIN_XS_2]: {
        fontSize: 12,
      },
    }
);

interface OfferAmountButtonProps {
  price: string;
  type: string;
  hasHoverEffect: boolean;
  viewType: any;
  forceLabel?: string;
}

const OfferAmountButton = ({
  price,
  type,
  hasHoverEffect = false,
  forceLabel,
  ...rest
}: OfferAmountButtonProps): ReactElement => {
  const { isMobile } = getBrowserDetails();
  const receivedLabel = useMemo(() => (isMobile ? 'Suggest an Amount' : 'Asked You to Suggest an Amount'), [isMobile]);
  const style = useMemo(() => (hasHoverEffect ? { cursor: 'pointer' } : {}), [hasHoverEffect]);
  const renderLabel = type === 'received' ? receivedLabel : 'No Amount Suggested';

  return (
    <DefaultButton style={style} className="offer-amount-button" {...rest} hasHoverEffect={hasHoverEffect} type={type}>
      {price !== '' ? (
        <>
          <Amount
            style={style}
            className="offer-amount-label"
            viewType={rest.viewType}
            data-test-id={'btn-amount-price'}
          >
            {price}
          </Amount>
          <OfferLabel style={style} className="offer-amount-label2" viewType={rest.viewType}>
            Offer Amount
          </OfferLabel>
        </>
      ) : (
        <EmptyAmount style={style} className="suggest-amount-label" viewType={rest.viewType} forceLabel={forceLabel}>
          {forceLabel ? forceLabel : renderLabel}
        </EmptyAmount>
      )}
    </DefaultButton>
  );
};

export default OfferAmountButton;
