import React, { useState } from 'react';
import { PhotoListTypes, PhotoFileType } from '../../constants';
import GalleryPhotoUploaderPreview from './GalleryPhotoUploaderPreview';
import GalleryUploaderModal from '../GalleryFileUploader/GalleryUploaderModal';
import PhotoCropper from '../../PhotoCropper';
import PhotoBlur from '../../PhotoBlur';
import sortBy from 'lodash/sortBy';
import PhotoPreview from '../../PhotoPreview';
import { connect } from 'react-redux';
import { showUploadSnackbar } from '../../../../actions/uploadSnackbarActions';
import uuidv4 from 'uuid';
import { IUploadSnackbarItem } from '../../../../reducers/types/uploadSnackbar';
import { galleryBulkUploadMedia } from '../../../../actions/galleryActions';
import { getImage } from '../../helpers';

type StepsType = 'preview' | 'crop' | 'blur' | 'finalize';

interface IGalleryFileReview {
  files: PhotoListTypes;
  isOpen: boolean;
  existingUploadedMediaCount: number;
  onClose: () => void;
  uploadPhotos: () => void;
  showSnackbar: (payload: IUploadSnackbarItem) => void;
}

const GalleryPhotoUploaderContainer: React.FC<IGalleryFileReview> = ({
  onClose,
  isOpen,
  files,
  existingUploadedMediaCount,
  showSnackbar,
  uploadPhotos,
}: IGalleryFileReview) => {
  const [activeFile, setActiveFile] = useState<PhotoFileType | null>(null);
  const [photos, setPhotos] = useState<PhotoListTypes>(files);
  const [currentStep, setCurrentStep] = useState<StepsType>('preview');

  const onEdit = (file: PhotoFileType) => {
    setCurrentStep('crop');
    setActiveFile(file);
  };

  const reset = () => {
    setActiveFile(null);
    setCurrentStep('preview');
  };

  const onCancel = () => {
    const steps = {
      preview: () => {
        reset();
        onClose();
      },
      crop: () => {
        reset();
      },
      blur: () => {
        setCurrentStep('finalize');
      },
      finalize: () => {
        setCurrentStep('crop');
      },
    };

    steps[currentStep]();
  };

  const sortPhotos = (photos: PhotoListTypes) => {
    return sortBy(photos, ['index']);
  };

  const onSubmit = async () => {
    for (const photo of photos) {
      showSnackbar({
        id: uuidv4(),
        status: '',
        type: 'photo',
        file: photo,
      });
    }

    // start upload process
    uploadPhotos();

    reset();
    onClose();
  };

  const onRemove = (selectedPhoto: PhotoFileType) => {
    const clonedPhones = [...photos];
    const cleanPhotos = clonedPhones.filter(photo => photo.index != selectedPhoto.index);

    if (cleanPhotos.length < 1) {
      reset();
      onClose();
      return;
    }

    setPhotos(sortPhotos(cleanPhotos));
  };

  const updateFileLists = async (file: File) => {
    if (activeFile === null) {
      return;
    }

    const updatedActiveFile = { ...activeFile };
    updatedActiveFile.edited = (await getImage(file)) as string;
    setActiveFile(updatedActiveFile);

    const clonedPhones = [...photos];
    const cleanPhotos = clonedPhones.filter(photo => photo.index != activeFile.index);

    setPhotos(sortPhotos([...cleanPhotos, updatedActiveFile]));
  };

  const updatePrivateFile = (isPrivate: boolean) => {
    if (activeFile === null) return;

    const updatedActiveFile = { ...activeFile };
    updatedActiveFile.private = isPrivate; // Toggle the 'private' property

    setActiveFile(updatedActiveFile);
    const clonedPhones = [...photos];
    const cleanPhotos = clonedPhones.filter(photo => photo.index != activeFile.index);

    setPhotos(sortBy([...cleanPhotos, updatedActiveFile], ['index']));
  };

  const onCropDone = (file: File) => {
    updateFileLists(file);
    setCurrentStep('finalize');
  };

  const onBlurDone = (file: File) => {
    updateFileLists(file);
    setCurrentStep('finalize');
  };

  return (
    <GalleryUploaderModal
      onClose={onCancel}
      isOpen={isOpen}
      contentLabel=""
      title={activeFile ? 'Edit Photo' : 'Upload Photos'}
      description={
        activeFile &&
        (currentStep === 'crop'
          ? 'Step 1 of 2: Cropping'
          : currentStep === 'finalize'
          ? 'Step 2 of 2: Finalize'
          : currentStep === 'blur'
          ? 'Step 2 of 2: Blur'
          : '')
      }
    >
      {!activeFile && currentStep === 'preview' && (
        <GalleryPhotoUploaderPreview
          photos={photos}
          existingUploadedMediaCount={existingUploadedMediaCount}
          onCancel={onCancel}
          onEdit={onEdit}
          onRemove={onRemove}
          onSubmit={onSubmit}
        />
      )}

      {activeFile && currentStep === 'crop' && (
        <PhotoCropper onAddPhoto={onCropDone} imageUrl={activeFile.raw} onCancel={onCancel} />
      )}

      {activeFile && currentStep === 'blur' && (
        <PhotoBlur
          onLastPreview={onBlurDone}
          cancelBlur={() => {
            setCurrentStep('finalize');
          }}
          imageUrl={activeFile.edited}
          onCancel={onCancel}
        />
      )}
      {activeFile && currentStep === 'finalize' && (
        <PhotoPreview
          isPrivatePhoto={activeFile.private}
          nextBlur={() => {
            setCurrentStep('blur');
          }}
          onAddPhoto={({ isPrivate }) => {
            updatePrivateFile(isPrivate);
            reset();
          }}
          imageUrl={activeFile.edited}
          onCancel={onCancel}
        />
      )}
    </GalleryUploaderModal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: payload => {
      dispatch(showUploadSnackbar(payload));
    },
    uploadPhotos: () => {
      dispatch(galleryBulkUploadMedia('photo'));
    },
  };
};

export default connect(null, mapDispatchToProps)(GalleryPhotoUploaderContainer);
