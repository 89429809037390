import store from '../store';

type AllowedKeys = 'dashboard_discover_swipe_enable' | 'offer_discover_swipe_enable';

export const isDiscoverModeSwipeEnabled = (key: AllowedKeys): boolean => {
  return store.getState().settings[key];
};

export const getSwipeProfilePhotoUrl = (photo: any): string => {
  return (
    photo.urls['820px'] ||
    photo.urls['720px'] ||
    photo.urls['410px'] ||
    photo.urls['resized'] ||
    photo.urls['profile'] ||
    photo.urls[Object.keys(photo.urls)[0]]
  );
};
