import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import ResultList from '../../blocks/ResultList';
import BlankSlate from '../../blocks/BlankSlate';
import { Select } from '../../../atoms/Select';
import Button from '../../../atoms/buttons/Button';
import Header from '../../blocks/Header';
import Headline from '../../common/Headline';
import Offer from '../Offers/Offer';
import SkeletonLoader from '../../../atoms/SkeletonLoader';
import Loader from '../../../atoms/LoaderStyled';

const ListItem = styled('ul')`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;

const ButtonContainer = styled('div')({
  margin: '16px auto',
  maxWidth: '200px',
});

interface CanceledOffersProps {
  offerType: any;
  refreshOfferType: any;
  isFetching: any;
  profile: any;
  checkProfile: any;
  loadRejectedMore: any;
  offers: any;
  moreExists: any;
}

const CanceledOffers = ({
  offerType,
  refreshOfferType,
  isFetching,
  profile,
  checkProfile,
  loadRejectedMore,
  offers,
  moreExists,
}: CanceledOffersProps): any => {
  const [orderingType, setOrderingType] = useState('recent');
  const [lastOfferType, setLastOfferType] = useState(offerType);

  useEffect(() => {
    const nextOfferType = offerType;
    if (nextOfferType !== lastOfferType) {
      setLastOfferType(nextOfferType);
      setOrderingType('recent');
    }
  }, [offerType, lastOfferType]);

  const renderOrderDropDown = () => {
    return (
      <Select onChange={changeOrder} value={orderingType} noSpacing>
        <option value="recent" key="new">
          Newest First
        </option>
        <option value="oldest" key="old">
          Oldest First
        </option>
        <option value="highest" key="high">
          Highest Price
        </option>
        <option value="lowest" key="low">
          Lowest Price
        </option>
      </Select>
    );
  };

  const changeOrder = e => {
    setOrderingType(e.target.value);
    refreshOfferType(e.target.value, true);
  };

  const offerList = (offers, noResults) => {
    const { offer_counts } = profile;
    const actionBtn = (
      <Button to="/search" buttonType="primary">
        Find a date
      </Button>
    );

    if (offer_counts && !offer_counts.rejected)
      return <BlankSlate img={'offer'} title="No cancelled offers found" message={noResults.msg} action={actionBtn} />;

    if (isFetching && offers && offers.length <= 0)
      return (
        <ResultList>
          <Header bottomRuled panelHeader>
            <Headline size="SM">{`Canceled Offers (${offer_counts.rejected})`}</Headline>
          </Header>
          <ListItem>
            <SkeletonLoader width="100%" />
          </ListItem>
        </ResultList>
      );

    // loop through each mail item.
    if (!_.isEmpty(offers)) {
      const offerList = Object.keys(offers).map(key => {
        const offer = offers[key];
        const { id, hash_id, to_account_hash_id } = offer;

        return (
          <Offer
            key={`${id}_${hash_id}_${to_account_hash_id}`}
            offer={offer}
            profile={profile}
            checkProfile={checkProfile}
          />
        );
      });

      return (
        <div style={{ padding: 0 }}>
          <Header bottomRuled panelHeader>
            <Headline
              data-test-id="cancelled-offers-header"
              size="SM"
            >{`Canceled Offers (${offer_counts.rejected})`}</Headline>
            {renderOrderDropDown()}
          </Header>
          <ListItem>{offerList}</ListItem>
        </div>
      );
    } else {
      return <BlankSlate img={'offer'} title={noResults.title} message={noResults.msg} action={actionBtn} />;
    }
  };

  const { offer_counts } = profile;
  if (!offer_counts) return null;

  const subRoutes = [
    {
      page: `Rejected (${offer_counts.rejected})`,
      url: '/offers/rejected',
      active: '/.*rejected*/',
      noResultsTitle: 'No rejected Offers',
    },
  ];

  const noResults = {};

  subRoutes.map(function(route) {
    if (route.url === location.pathname) {
      noResults.title = route.noResultsTitle;
      noResults.msg = route.noResultsMsg;
      return route.page;
    } else {
      return null;
    }
  });

  return (
    <div>
      {offerList(offers, noResults)}
      {moreExists ? (
        <Loader active={isFetching} background={'transparent'}>
          <ButtonContainer>
            <Button
              buttonType="primary"
              fit
              onClick={() => {
                loadRejectedMore(orderingType);
              }}
            >
              Load More
            </Button>
          </ButtonContainer>
        </Loader>
      ) : null}
    </div>
  );
};

export default CanceledOffers;
