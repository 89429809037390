export default {
  light: {
    primary: '#F5F7FA',
    primaryCard: '#FFF',
    confirmErrorModal: '#FFF8F8',
    blue: '#EAF4FB'
  },
  dark: {
    primary: '#020D15',
    primaryCard: '#041A2A',
    confirmErrorModal: '#2A0404',
    blue: '#041A2A',
  },
};
