import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SvgQuestion extends PureComponent {
  render(): any {
    const { styles, viewBox } = this.props;

    return (
      <span>
        <svg viewBox={viewBox} style={styles}>
          <path
            d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M252.9,431.1
        c-16.6,0-30.1-13.5-30.1-30.1s13.5-30.1,30.1-30.1s30.1,13.9,30.1,30.5C283.1,418,269.6,431.1,252.9,431.1z M283.1,296.2v32.2
        c0,5.6-4.5,10.1-10.1,10.1h-40.2c-5.6,0-10.1-4.5-10.1-10.1v-50.2c0-22.2,18-40.2,40.2-40.2s40.2-18,40.2-40.2s-18-40.2-40.2-40.2
        s-40.2,18-40.2,40.2v10.1c0,5.6-4.5,10.1-10.1,10.1h-40.2c-5.6,0-10.1-4.5-10.1-10.1v-10.1c0-55.4,45.1-100.5,100.5-100.5
        s100.5,45.1,100.5,100.5C363.5,245.9,329.5,286.8,283.1,296.2z"
          />
        </svg>
      </span>
    );
  }
}

SvgQuestion.propTypes = {
  styles: PropTypes.object,
  viewBox: PropTypes.string,
};

SvgQuestion.defaultProps = {
  styles: {
    fill: '#979797',
    height: 13,
    width: 20,
  },
  viewBox: '0 0 512 512',
};

export default SvgQuestion;
