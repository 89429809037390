import React, { useState, useEffect, useRef, ReactElement, useCallback, useMemo } from 'react';
import imagesLoaded from 'imagesloaded';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import _isEmpty from 'lodash/isEmpty';
import { renderAvatar, tsSince } from '../../common';
import { backgroundTheme, borders, color, dimensions, textColor, buttonTheme, inputTheme } from '../../style';
import Button from '../../atoms/buttons/Button';
import MessageBubble from '../../atoms/mail/MessageBubble';
import DotMenu from '../../atoms/icons/DotMenu';
import ArrowLeft from '../../atoms/icons/ArrowLeft';
import { MenuList, MenuListItem } from '../../components/blocks/MenuList';
import ConfirmationModal from '../modals/ConfirmationModal';
import Loader from '../../atoms/LoaderStyled';
import Avatar from '../../atoms/Avatar';
import { getBrowserDetails } from '../../utils/helpers';
import BasicModal from '../modals/BasicModal';
import BlankSlate from '../../components/blocks/BlankSlate';
import VideoCallModal from '../modals/VideoCallModal';
import { commonIcons } from '../../atoms/icons/materialIcons';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import '../../style/customGifEmojiModal.css';
import TextareaAutosize from 'react-textarea-autosize';
import emojiRegexRGI from 'emoji-regex/es2015/RGI_Emoji';
import MasterConfig, {
  enabledPhotoMessage,
  GENERAL_MESSAGE_USERNAME,
  GENERAL_MESSAGE_AVATAR,
} from '../../config/Master';
import { browser } from 'detectrtc';
import Dropzone from 'react-dropzone';
import { useStateCallback } from '../../utils/customHooks';
import { sendSufficientCredsUnlockAction, sendInsufficientCredsUnlockAction } from '../../actions/purchaseActions';
import { triggerTracking } from '../../utils/helpers';
import useMixPanel from '../../hooks/useMixPanel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PhotoConfig } from '../../config/Media';

const iOSnotPWA = getBrowserDetails().isMobile && getBrowserDetails().isSafari && !getBrowserDetails().isStandaloneMode;
const isChromeIos = browser.isChrome && getBrowserDetails().isIpad;

const ConversationContainer = styled('div')({});

const ConversationHeader = styled('div')({
  borderBottom: borders.DEFAULT,
  padding: getBrowserDetails().isMobile ? '7.5px 10px' : 30,
  display: 'flex',
  justifyContent: 'space-between',
});

const PokeContainer = styled('div')({
  margin: '0 auto',
  width: '50%',
});

const ConversationBody = styled('div')(
  {
    overflow: 'hidden',
    overflowY: 'scroll',
    paddingTop: 10,
  },
  ({ isLocked, showPwaInstallPromptBanner }) => ({
    height: `calc(${getBrowserDetails().isIpad && screen.height > 1024 ? '87vh' : '98vh'} - ${isLocked ? 0 : '290px'})`,

    [dimensions.SCREEN_MAX_MD]: {
      height: iOSnotPWA
        ? `calc(98vh - ${isLocked ? 0 : showPwaInstallPromptBanner ? '250px' : '185px'})`
        : `calc(100vh - 100px)`,
    },
  })
);

const UserInfo = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '> div': {
    display: 'flex',
    flexDirection: 'column',
  },

  '> div > span': {
    marginLeft: 10,
  },

  '> div > span:last-child': {
    fontSize: 12,
    color: '#788596',
  },
});

const Details = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',

    '> span': {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#263444',
    },
  },
  ({ mobileDetail }) => ({
    display: mobileDetail ? 'unset' : 'flex',
  })
);

const Overview = styled('div')({
  textAlign: 'center',
  padding: '20px 0',
  borderBottom: borders.DEFAULT,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,
  alignItems: 'center',
});

const HeaderDetailsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 10,
  alignItems: 'flex-start',
});

const OfferAccepted = styled('span')({
  fontSize: getBrowserDetails().isMobile ? 16 : 18,
  color: '#3E79A5',
  fontWeight: 'bold',
});

const LocationInfo = styled('span')({
  marginBottom: 20,
  fontSize: getBrowserDetails().isMobile ? 12 : 14,
});

const OfferIncentive = styled('span')({
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom: 10,
});

const Messages = styled('div')(
  {
    paddingBottom: isChromeIos ? 100 : 40,
    position: 'relative',
  },
  ({ isIos, isStandaloneMode, initialMsgSize }) => ({
    height: initialMsgSize ? '50%' : '100%',
    [dimensions.SCREEN_MAX_SM]: {
      paddingBottom: isIos && !isStandaloneMode ? 100 : !isIos && isStandaloneMode ? 35 : 70,
    },
  })
);

const ConversationInput = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderTop: borders.DEFAULT,
    position: 'relative',
    bottom: 0,
    overflow: 'visible',

    [dimensions.SCREEN_MAX_MD]: {
      position: 'fixed',
      width: `calc(100vw - 20px)`,
      backgroundColor: backgroundTheme.block,
      marginTop: 1,
      maxHeight: 100,
    },
  },
  ({ isIos }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      paddingBottom: isIos ? 20 : 5,
    },
  })
);

const ConversationTextArea = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderTop: borders.DEFAULT,
    position: 'relative',
    bottom: 0,
    overflow: 'visible',

    [dimensions.SCREEN_MAX_MD]: {
      position: 'fixed',
      width: `calc(100vw - 20px)`,
      backgroundColor: backgroundTheme.block,
      marginTop: 1,
      maxHeight: 100,
    },
  },
  ({ isIos }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      paddingBottom: isIos ? 20 : 5,
    },
  })
);

const ConversationButtons = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderTop: borders.DEFAULT,
    position: 'relative',
    bottom: 0,
    overflow: 'visible',

    [dimensions.SCREEN_MAX_MD]: {
      position: 'fixed',
      width: `calc(100vw - 20px)`,
      backgroundColor: backgroundTheme.block,
      marginTop: 1,
      maxHeight: 100,
      marginBottom: 60,
    },
  },
  ({ isIos, textAreaHeight }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      paddingBottom: isIos ? 20 : 5,
      marginBottom: `calc(24px + ${textAreaHeight}px)`,
    },
  })
);

const NewMessageNotification = styled('div')({
  width: '45%',
  margin: '10px auto 0',

  [dimensions.SCREEN_MAX_MD]: {
    width: '80vw',
  },
});

const ReminderContainer = styled('div')({
  textAlign: 'center',
  marginBottom: 20,
});

const CloseConversation = styled('div')({
  display: 'none !important',
  position: 'relative',
  width: 40,
  height: '100%',

  [dimensions.SCREEN_MAX_MD]: {
    display: 'block !important',
  },
});

const TypingIndicator = styled('div')({
  fontStyle: 'italic',
  fontSize: 12,
  position: 'absolute',
  top: -20,
  left: 10,
  paddingLeft: 10,
  backgroundColor: backgroundTheme.block,
  height: 13,
  marginTop: -20,
});

const WarningMessage = styled('div')({
  fontStyle: 'italic',
  fontSize: 12,
  padding: '10px 20px',
  marginBottom: 20,
  textAlign: 'justify',
  color: '#788596',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
});

const UserCountry = styled('span')({
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const TextAreaStyled = styled(TextareaAutosize)({
  width: '100%',
  border: '1px solid #CCCCCC',
  padding: '10px 40px 10px 10px',
  outline: 'none',
  borderRadius: 3,
  fontSize: 16,
  margin: 0,
  lineHeight: 1,
  fontFamily: 'inherit',
  maxHeight: 70,
  resize: 'none',
  background: inputTheme.backgroundInput,
  color: inputTheme.colorInput,
  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 5,
  },
});

const MessageOverlay = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000000',
    zIndex: 2,
    opacity: '0.7',
  },
  ({ pickerOpen }) => ({
    display: pickerOpen ? 'block' : 'none',
  })
);

const StyledDropzone = styled(Dropzone)({
  cursor: 'pointer',
  padding: getBrowserDetails().isMobile ? '1px' : '0 6px 0 0',
  height: 'auto',
  width: 40,
  float: 'left',
  marginTop: getBrowserDetails().isMobile ? '-2px' : 0,
});

const MobileFixSpacer = styled('div')({
  height: getBrowserDetails().isMobile
    ? getBrowserDetails().isStandaloneMode
      ? 150
      : getBrowserDetails().isIpad && screen.height > 1024
      ? 30
      : 260
    : 30,
  position: 'relative',
});

const ButtonModalStyles = {
  '&:focus': {
    background: 'transparent',
    color: `${buttonTheme.darkReverse}`,
  },
  '&:hover': {
    border: 'none',
    background: 'transparent',
  },
};

const VideoCallModalStyles = {
  '&:focus': {
    background: 'transparent',
    color: 'unset',
  },
  '&:hover:enabled': {
    background: 'transparent',
  },
};

const OnlineIcon = styled('div')({
  height: 12,
  width: 12,
  borderRadius: 7,
  backgroundColor: color.BRAND_1_ONLINE,
  display: 'inline-block',
});

const mobileSendBtnStyle = {
  height: 45,
  position: 'absolute',
  zIndex: 10,
  width: 50,
  right: 1,
  top: 2,
};

const setCorrectUnlockingAction = (e, unlockStatus) => {
  switch (unlockStatus) {
    case 'sufficient':
      sendSufficientCredsUnlockAction(e);
      break;
    case 'insufficient':
      sendInsufficientCredsUnlockAction(e);
      break;
    default:
      return;
  }
};

interface ConversationProps {
  onPostPoke: any;
  poke: any;
  other_profile: any;
  handleDeleteImage: any;
  id: any;
  authUser: any;
  offer: any;
  messages: any;
  onUnlockOffer: any;
  onDeleteConversation: any;
  onPostMessage: any;
  deleted_reason: any;
  onCloseConversation: any;
  locked: any;
  refund_requested_at: any;
  locked_request_refund: any;
  handlePostTypingStatus: any;
  uploadPhotoMessage: any;
  auth: any;
  archived: any;
  goToProfile: any;
  onFetchConversation: any;
  onToggleArchiveConversation: any;
  onResendMessage: any;
  onCancelOffer: any;
  onBlockUser: any;
  fetching: any;
  isOtherAccountTyping: any;
  isVideoButtonEnabled: any;
  iceServers: any;
  videoInfo: any;
  isUploading: any;
  handleUnblurImage: any;
  handleReportImage: any;
  fetchPageNux: any;
  updatePageNux: any;
  admin: boolean;
  showPwaInstallPromptBanner: boolean;
}

const Conversation = ({
  onPostPoke,
  pokes,
  other_profile,
  handleDeleteImage,
  id,
  authUser,
  offer,
  messages,
  onUnlockOffer,
  onDeleteConversation,
  onPostMessage,
  deleted_reason,
  onCloseConversation,
  locked,
  refund_requested_at,
  locked_request_refund,
  handlePostTypingStatus,
  uploadPhotoMessage,
  auth,
  archived,
  goToProfile,
  onFetchConversation,
  onToggleArchiveConversation,
  onResendMessage,
  onCancelOffer,
  onBlockUser,
  fetching,
  isOtherAccountTyping,
  isVideoButtonEnabled,
  iceServers,
  videoInfo,
  isUploading,
  handleUnblurImage,
  handleReportImage,
  fetchPageNux,
  updatePageNux,
  admin,
  skipNuxGuides,
  showPwaInstallPromptBanner,
}: ConversationProps): ReactElement => {
  const defaultPickerBottomOffset = 130;
  const [message, setMessage] = useState('');
  const [actionsOpen, setActionsOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [unlockModalOpen, setUnlockModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showSeveredActions, setShowSeveredActions] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [pickerBottomOffset, setPickerBottomOffset] = useState(defaultPickerBottomOffset);
  const [deleteImgModal, setDeleteImgModal] = useState(false);
  const [deleteFor, setDeleteFor] = useState('');
  const [messageID, setMessageID] = useStateCallback('');
  const [reportImgModal, setReportImgModal] = useState(false);
  const [disablePoke, setDisablePoke] = useState(false);
  const [lastMessage, setLastMessage] = useState({});

  const [typingIndicator, setTypingIndicator] = useState(null);
  const conversationRef = useRef(null);
  const sufficientCredits = useRef(null);
  const insufficientCredits = useRef(null);
  const [textAreaHeight, setTextAreaHeight] = useState(0);

  const otherProfile = (other_profile && other_profile.data) || {};
  const otherAccount = (otherProfile.account && otherProfile.account.data) || {};
  const avatar = (otherProfile.avatar && otherProfile.avatar.data.urls) || {};
  const memoisedOtherProfileThumbAvatar = useMemo(() => renderAvatar(avatar, true), [avatar]);
  const memoisedOtherProfileAvatar = useMemo(() => renderAvatar(avatar), [avatar]);
  const memoisedProfileAvatar = useMemo(() => renderAvatar(authUser?.profile?.data?.avatar.data.urls), [
    authUser?.profile?.data?.avatar,
  ]);
  const offerData = (offer && offer.data) || {};
  const currency = (offerData.currency_original && offerData.currency_original.data) || {};
  const price = `${currency.initial}${String.fromCharCode(currency.label_unicode)}${offerData.price_original}`;
  const isAttractive = authUser && authUser.profile && authUser.profile.data.account_type === 'Attractive';
  const showRefundListItem = !isAttractive && !locked;
  const { isMobile, isIos, isStandaloneMode } = getBrowserDetails();
  const showOverlay = showEmojiPicker;
  const uploadInProgress = isUploading && isUploading.public;
  const disableSend = !message || uploadInProgress;
  const bigIpad = getBrowserDetails().isIpad && screen.height > 1024;
  const isOnline = otherProfile.online;

  const [messagesCollection, setMessagesCollection] = useState([]);
  const messagesPagination = useMemo(() => (messages && messages.meta.pagination) || {}, [messages]);

  const { trackMessageTyped, trackPackagesPageVisited, trackOfferCancelled } = useMixPanel();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (messages && messages.data) {
      setMessagesCollection(messages.data);
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
    setDisablePoke(pokes && pokes.can_send_poke);
    sufficientCredits.current = document.getElementById('sufficientCredits');
    insufficientCredits.current = document.getElementById('insufficientCredits');
    triggerTracking('sufficient', sufficientCredits, setCorrectUnlockingAction);
    triggerTracking('insufficient', insufficientCredits, setCorrectUnlockingAction);
  }, [pokes, scrollToBottom]);

  useEffect(() => {
    const textAreaStyled = document.getElementById('textAreaStyled');
    textAreaStyled && setTextAreaHeight(textAreaStyled.clientHeight);
  }, []);

  useEffect(() => {
    if (!fetching && messagesPagination.current_page && messagesPagination.current_page > 1) {
      setTimeout(() => {
        const convEl = document.getElementById('conversation_body');

        if (convEl && !_isEmpty(lastMessage)) {
          const lastEl = document.getElementById(lastMessage.id);

          if (lastEl) {
            convEl.scrollTo(0, lastEl?.offsetTop ?? 0);
          }
        }
      }, 300);
    }
  }, [fetching, messagesPagination, lastMessage]);

  useEffect(() => {
    const convEl = conversationRef?.current;

    const handleImagesLoaded = () => {
      if (convEl) {
        const lastMessage = messagesCollection[messagesCollection.length - 1];
        const lastEl = document.getElementById(lastMessage?.id ?? null);

        if (lastEl) {
          const bottomPosition = isMobile ? lastEl?.offsetTop ?? convEl.scrollHeight : convEl.scrollHeight;
          convEl.scrollTo(0, bottomPosition);
        }
      }
    };

    if (messagesCollection.length > 0 && convEl && messagesPagination?.current_page === 1) {
      //  initially set scroll to bottom based on scroll height

      convEl.scrollTo(0, convEl.scrollHeight);
      const imgLoad = imagesLoaded(convEl);

      // Wait for images to be loaded
      imgLoad.on('always', handleImagesLoaded);
    }
  }, [conversationRef?.current, messagesCollection, isMobile]);

  const handleLoadOldMessage = () => {
    const currentPage = messagesPagination.current_page || 0;

    setLastMessage([...messagesCollection].shift() || {});
    onFetchConversation(currentPage + 1);
  };

  const handlePoke = hashId => {
    setDisablePoke(true);
    onPostPoke(hashId);
  };

  const canSendPokes = pokes => {
    const canSendPokeInFuture = pokes.max_pokes - pokes.pokes_count > 0;
    return !(pokes.can_send_poke && canSendPokeInFuture && disablePoke);
  };

  const renderAttractiveLockedNotice = () => {
    const isFinalPoke = pokes.max_pokes - pokes.pokes_count === 1;
    const canSendPokeInFuture = pokes.max_pokes - pokes.pokes_count > 0;
    return (
      <PokeContainer>
        <p style={{ marginBottom: 0 }}>
          To ensure genuine interest, {otherAccount.username} has to spend money to unlock this conversation.
        </p>
        {canSendPokeInFuture && (
          <React.Fragment>
            <Button
              disabled={canSendPokes(pokes)}
              onClick={() => handlePoke(otherAccount.hash_id)}
              buttonType="primary"
              customStyle={{ marginTop: 10 }}
            >
              Send a Reminder
            </Button>
            {pokes.can_send_poke && <p>{`You can send a ${isFinalPoke ? 'final' : ''}  reminder after 48 hours.`}</p>}
          </React.Fragment>
        )}
      </PokeContainer>
    );
  };

  const handleOnClickUnlock = () => {
    setUnlockModalOpen(true);
  };

  const handleOnClickDelete = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteImg = useCallback((deleteFor, messageID) => {
    setDeleteImgModal(true);
    setDeleteFor(deleteFor);
    setMessageID(messageID);
  }, []);

  const confirmDelete = () => {
    setDeleteImgModal(false);
    handleDeleteImage(deleteFor, messageID);
  };

  const handleReportImg = useCallback(messageID => {
    setReportImgModal(true);
    setMessageID(messageID);
  }, []);

  const confirmReportImg = () => {
    setReportImgModal(false);
    setMessageID('', () => {
      handleReportImage(messageID);
    });
  };

  const renderGenerousActions = () => {
    let actionButton = (
      <Button id="sufficientCredits" onClick={handleOnClickUnlock} buttonType="primary">
        Unlock for {offerData.credit_cost} credits
      </Button>
    );

    // not enough credits so we redirect the user to billing page
    if (offerData.credit_cost > authUser.credits) {
      actionButton = (
        <Button
          id="insufficientCredits"
          to="/packages"
          buttonType="primary"
          onClick={() => {
            trackPackagesPageVisited({ Source: 'Unlock Attempt' });
          }}
        >
          Unlock for {offerData.credit_cost} credits
        </Button>
      );
    }

    return (
      <React.Fragment>
        {actionButton}

        <ConfirmationModal
          header={'Unlock Offer'}
          description={`Do you want to unlock this date for ${offerData.credit_cost} credits?`}
          isOpen={unlockModalOpen}
          closeOnOverlayClick={true}
          cancelText={'Cancel'}
          confirmText={'Unlock'}
          onClose={() => setUnlockModalOpen(false)}
          onConfirm={() => {
            onUnlockOffer(id, otherAccount.hash_id);
          }}
        />
        <NewMessageNotification>
          {messages && messages.data.length > 0 && (
            <React.Fragment>
              {otherAccount.username} sent you <b>{messages.data.length}</b>{' '}
              {messages.data.length > 1 ? 'messages' : 'message'}!{' '}
            </React.Fragment>
          )}
          Use credits to unlock the conversation and set up your date!
        </NewMessageNotification>
      </React.Fragment>
    );
  };

  const renderDeleteConversation = () => {
    return (
      <React.Fragment>
        <ConfirmationModal
          header={'Delete Conversation'}
          description={`Do you want to delete this conversation?`}
          isOpen={deleteModalOpen}
          closeOnOverlayClick={true}
          cancelText={'Cancel'}
          confirmText={'Delete'}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => onDeleteConversation(id, otherAccount.hash_id)}
        />
      </React.Fragment>
    );
  };

  const handleChangeMessage = e => {
    const message = e.target.value;
    setMessage(message);

    if (typingIndicator === null && message.trim() !== '') {
      handlePostTypingStatus();
      setTypingIndicator(true);
      trackMessageTyped(true);
      setTimeout(() => {
        setTypingIndicator(null);
      }, 6000);
    }
  };

  const handlePostMessage = () => {
    setMessage('');
    hidePickers();
    trackMessageTyped(false);

    const emojiArr = [];

    if (message.length < 7) {
      const regex = emojiRegexRGI();
      const strMatched = message.match(/.{1,2}/g);

      if (strMatched) {
        strMatched.map(str => {
          let match;
          while ((match = regex.exec(str))) {
            // weird workaround not working without this while statement
          }
          emojiArr.push(regex.exec(str) !== null);
          return match;
        });
      }
    }

    const nonEmoji = emojiArr.includes(false) || message.length > 6;
    onPostMessage(message, nonEmoji ? '' : 'emoji');
    scrollToBottom();
  };

  const handleToggleActionsModal = status => {
    setActionsOpen(status);
    setShowSeveredActions(deleted_reason === 'severed');
  };

  const handleCloseConversation = e => {
    e.stopPropagation();
    onCloseConversation(navigate, location, params);
  };

  const scrollToBottom = useCallback(() => {
    const scrollNow = () => {
      const scrollHeight = conversationRef && conversationRef.current && conversationRef.current.scrollHeight;
      if (scrollHeight) conversationRef.current.scrollTop = scrollHeight ? scrollHeight : 0;
    };

    if (messagesCollection && messagesCollection.length > 1) {
      scrollNow();
    }
  }, [messagesCollection]);

  const renderWarningMessage = () => {
    const userHasSentMessage = messagesCollection.find(message => message.hash_id === authUser.hash_id);

    return (
      (locked || !userHasSentMessage) && (
        <WarningMessage>
          Never send or ask for money or gift cards before meeting. Never share or request banking information. Report
          anyone who does.
        </WarningMessage>
      )
    );
  };

  const formatRefundListItem = () => {
    if (refund_requested_at) {
      return 'Refund requested';
    }

    if (!refund_requested_at && locked_request_refund) {
      return 'Conversation has been open for at least 30 days';
    }

    if (!refund_requested_at && !locked_request_refund) {
      return 'For refund requests, please contact customer service';
    }
  };

  const handleAddEmoji = e => {
    const emoji = e.native;
    const myMessage = `${message}${emoji}`;

    setMessage(myMessage);
    if (typingIndicator === null && message.trim() !== '') {
      handlePostTypingStatus();
      setTypingIndicator(true);
      setTimeout(() => {
        setTypingIndicator(null);
      }, 6000);
    }
  };

  const showHidePicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
    setPickerBottomOffset(calcInputContainerHeight());
  };

  const calcInputContainerHeight = () => {
    const convContainer = document.getElementsByClassName('conversation-input-container');

    if (typeof convContainer[0] === 'undefined') {
      return defaultPickerBottomOffset;
    }

    return convContainer[0].offsetHeight;
  };

  const hidePickers = () => {
    setShowEmojiPicker(false);
  };

  const onDropPhoto = async imageFile => {
    hidePickers();
    // create temp data for processing
    const date = moment().format('YY–MM-DD HH:mm:ss');
    const processData = {
      created_at: {
        date,
      },
      hash_id: authUser.hash_id,
      id: null,
      type: 'photo',
      is_deleted: false,
      is_visible: true,
      message: '',
      isBlurred: false,
      isUploading: true,
    };

    setMessagesCollection(prevState => {
      return [...prevState, processData];
    });
    const resImage = await uploadPhotoMessage(imageFile[0], other_profile.data.hash_id);
    if (resImage === undefined) return;
  };

  const handleSubmitPhoto = () => {
    onDropPhoto(document.getElementById('upload-qa-message-photo').files);
  };

  const actionButton = (
    <Button onClick={handleOnClickDelete} buttonType="primary">
      Delete conversation
    </Button>
  );

  const renderSelectEmoji = () => {
    return (
      <div
        data-test-id="select-emoji-container"
        style={{
          display: showOverlay ? 'block' : 'none',
          position: isMobile && !bigIpad ? 'fixed' : 'initial',
          height: '100%',
          width: isMobile && !bigIpad ? '100%' : 'auto',
          bottom: pickerBottomOffset,
          left: 0,
          zIndex: 100,
        }}
      >
        <Picker
          style={{
            minWidth: '100%',
            position: isMobile && !bigIpad ? 'fixed' : 'absolute',
            bottom: pickerBottomOffset,
            left: 0,
            display: showEmojiPicker ? 'block' : 'none',
            backgroundColor: backgroundTheme.block,
          }}
          onSelect={handleAddEmoji}
          title="Pick your emoji…"
          emoji="point_up"
        />
      </div>
    );
  };

  const renderEmojiBtn = () => {
    const emojiIcon = uploadInProgress ? <commonIcons.emoticon.inactive /> : <commonIcons.emoticon.default />;
    return (
      <Button
        customStyle={{ padding: '0 6px', height: 35, width: 45, float: 'left' }}
        onClick={showHidePicker}
        buttonType="chromeless"
        data-test-id="emoji-picker-button"
        disabled={uploadInProgress}
      >
        {showEmojiPicker ? <commonIcons.emoticon.keyboard /> : emojiIcon}
      </Button>
    );
  };

  const renderTypingIndicator = () => {
    return isOtherAccountTyping ? (
      <TypingIndicator>
        <MessageBubble
          key={Math.floor(Math.random() * 999)}
          isRead
          id={Math.floor(Math.random() * 999)}
          type="indicator"
        />
      </TypingIndicator>
    ) : (
      ' '
    );
  };

  const renderStyledDropzone = () => {
    return (
      <StyledDropzone
        accept={PhotoConfig.allowedMimeType}
        multiple={false}
        onDrop={onDropPhoto}
        maxSize={MasterConfig.MAX_PHOTO_FILE_SIZE}
        disabled={uploadInProgress || !enabledPhotoMessage}
        disableClick={uploadInProgress || !enabledPhotoMessage}
      >
        {uploadInProgress ? <commonIcons.photo.inactive /> : <commonIcons.photo.default />}
      </StyledDropzone>
    );
  };

  const handleKeyDownMessage = e => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      handlePostMessage();
    }
  };

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .ReactModalPortal { z-index: 9999; position: relative; }`,
        }}
      />
      {deleted_reason === 'severed' ? (
        <ConversationContainer>
          <ConversationHeader>
            <UserInfo>
              <CloseConversation onClick={handleCloseConversation}>
                <ArrowLeft messageBack />
              </CloseConversation>
            </UserInfo>
            <Details>
              <Button onClick={() => handleToggleActionsModal(true)} style={{ border: 'none' }}>
                <DotMenu />
              </Button>
            </Details>
          </ConversationHeader>
          <ConversationBody>
            <BlankSlate
              img={'msg'}
              title={'This profile no longer exists'}
              message={
                'The member profile was either deleted by the member or our moderators for violating our community guidelines.'
              }
              action={actionButton}
            />
            {!locked && otherProfile.account_type === 'Attractive' && renderDeleteConversation()}
          </ConversationBody>
        </ConversationContainer>
      ) : (
        <ConversationContainer>
          <ConversationHeader data-test-id="conversation-header">
            <UserInfo onClick={() => !admin && goToProfile(otherAccount.hash_id)}>
              <CloseConversation onClick={handleCloseConversation}>
                <ArrowLeft messageBack />
              </CloseConversation>
              <Avatar
                src={admin ? GENERAL_MESSAGE_AVATAR : renderAvatar(avatar, true)}
                width={100}
                data-test-id="conversation-header-avatar"
              />
              <div>
                <span
                  className="conversation-header-username"
                  style={{ fontWeight: 600, fontSize: isMobile ? 16 : 18 }}
                >
                  {admin ? GENERAL_MESSAGE_USERNAME : otherAccount.username || ''}{' '}
                  {!admin && isOnline && <OnlineIcon />}
                  {admin && <commonIcons.check.badge />}
                </span>
                {!admin && (
                  <HeaderDetailsContainer>
                    <LocationInfo className="conversation-header-location">
                      {otherProfile.age} &bull; {otherProfile.city}
                      <UserCountry>, {otherProfile.country}</UserCountry>
                    </LocationInfo>
                    <OfferAccepted className="offer-accepted-text">Offer Accepted!</OfferAccepted>
                    <span style={{ ...textColor.DARKER, fontSize: 14 }} className="first-date-incentive-text">
                      First date incentive: <b>{price}</b>
                    </span>
                  </HeaderDetailsContainer>
                )}
              </div>
            </UserInfo>
            {!admin && (
              <Details mobileDetail={isMobile}>
                {!locked && (
                  <VideoCallModal
                    customStyle={{ marginLeft: 5, ...VideoCallModalStyles }}
                    profile={authUser}
                    auth={auth}
                    otherProfile={other_profile.data}
                    isVideoButtonEnabled={isVideoButtonEnabled}
                    iceServers={iceServers}
                    videoInfo={videoInfo}
                    fetchPageNux={fetchPageNux}
                    updatePageNux={updatePageNux}
                    skipNuxGuides={skipNuxGuides}
                    navigate={navigate}
                    location={location}
                    profileAvatar={memoisedProfileAvatar}
                    otherProfileAvatar={memoisedOtherProfileAvatar}
                  />
                )}
                <Button
                  data-test-id="btn-dot-menu"
                  onClick={() => handleToggleActionsModal(true)}
                  customStyle={{ border: 'none', ...ButtonModalStyles, marginLeft: isMobile ? 5 : 0 }}
                >
                  <DotMenu color={backgroundTheme.iconFill} />
                </Button>
              </Details>
            )}
          </ConversationHeader>

          <ConversationBody
            id="conversation_body"
            isLocked={locked}
            ref={conversationRef}
            showPwaInstallPromptBanner={showPwaInstallPromptBanner}
          >
            {locked && (
              <Overview>
                <Avatar
                  onClick={() => goToProfile(otherAccount.hash_id)}
                  src={renderAvatar(avatar, true)}
                  width={72}
                  customStyle={{
                    cursor: 'pointer',
                  }}
                />
                <OfferAccepted>Offer Accepted</OfferAccepted>
                <OfferIncentive>First date incentive {price}</OfferIncentive>

                {otherProfile.account_type === 'Generous' ? renderAttractiveLockedNotice() : renderGenerousActions()}
              </Overview>
            )}
            <Messages isIos={isIos} isStandaloneMode={isStandaloneMode} initialMsgSize={messagesCollection.length < 7}>
              {isMobile && <MessageOverlay pickerOpen={!isMobile && showOverlay}>&nbsp;</MessageOverlay>}
              {messagesPagination.current_page < messagesPagination.total_pages && !fetching && (
                <Button onClick={() => handleLoadOldMessage()} style={{ width: '100%', marginBottom: 10 }}>
                  Load older...
                </Button>
              )}
              {fetching && <Loader active={true} background="#fff" style={{ width: '100%', height: 100 }} />}
              {locked &&
                pokes &&
                pokes.data.reverse().map((poke, index) => (
                  <ReminderContainer key={poke.id}>
                    <p style={{ marginBottom: 0 }}>
                      <i>
                        {`${poke.hash_id === authUser.hash_id ? 'You' : otherAccount.username} sent a ${
                          pokes.max_pokes === index + 1 ? 'final' : ''
                        } reminder about this date.`}
                      </i>
                    </p>
                    <span>
                      <small>{tsSince(poke.created_at.date)}</small>
                    </span>
                  </ReminderContainer>
                ))}
              {(!locked || !!isAttractive) &&
                messagesCollection.map(message => (
                  <MessageBubble
                    isBlurred={message.isBlurred}
                    handleDeleteImg={handleDeleteImg}
                    handleUnblurImage={handleUnblurImage}
                    handleReportImg={handleReportImg}
                    isUploading={messages?.isUploading ?? uploadInProgress}
                    key={`${message.id} - ${message.hash_id}`}
                    onResendMessage={onResendMessage}
                    isRead={authUser.hash_id === message.hash_id && message.they_read}
                    isSent={authUser.hash_id === message.hash_id}
                    avatar={admin ? GENERAL_MESSAGE_AVATAR : memoisedOtherProfileThumbAvatar}
                    scrollToBottom={scrollToBottom}
                    urls={message.urls}
                    id={message.id}
                    {...message}
                  />
                ))}
              {renderWarningMessage()}
              {/* message bottom margin fix */}
              <MobileFixSpacer />
            </Messages>
          </ConversationBody>
          {/* */}
          {!admin && !locked && !isMobile && (
            <ConversationInput
              className="conversation-input-container"
              pickerOpen={isMobile && showOverlay}
              isIos={isIos}
            >
              {renderTypingIndicator()}
              {renderEmojiBtn()}
              <div style={{ height: 38 }}>{renderStyledDropzone()}</div>
              {renderSelectEmoji()}
              <Button
                customStyle={{ height: 'auto' }}
                onClick={handlePostMessage}
                buttonType="chromeless"
                disabled={disableSend}
                data-test-id="send-message-button"
              >
                {disableSend ? <commonIcons.send.inactive /> : <commonIcons.send.default />}
              </Button>
              <TextAreaStyled
                placeholder={`Message ${otherAccount.username}...`}
                elastic="true"
                value={message}
                onChange={handleChangeMessage}
                onClick={hidePickers}
                onKeyDown={handleKeyDownMessage}
              />
            </ConversationInput>
          )}
          {/* */}

          {/*Test New UI */}
          {!admin && !locked && isMobile && (
            <ConversationButtons
              className="conversation-button-container"
              pickerOpen={isMobile && showOverlay}
              isIos={isIos}
              textAreaHeight={textAreaHeight}
            >
              {renderTypingIndicator()}
              <div style={{ height: 38, margin: '0 auto' }}>
                {renderEmojiBtn()}
                {renderStyledDropzone()}
              </div>
              {renderSelectEmoji()}
            </ConversationButtons>
          )}
          {!admin && !locked && isMobile && (
            <ConversationTextArea
              className="conversation-textarea-container"
              pickerOpen={isMobile && showOverlay}
              isIos={isIos}
            >
              <Button
                customStyle={mobileSendBtnStyle}
                onClick={handlePostMessage}
                buttonType="chromeless"
                disabled={disableSend}
                data-test-id="send-message-button"
              >
                {disableSend ? <commonIcons.send.inactive /> : <commonIcons.send.default />}
              </Button>
              <TextAreaStyled
                placeholder={`Message ${otherAccount.username}...`}
                id="textAreaStyled"
                elastic="true"
                value={message}
                onChange={handleChangeMessage}
                onKeyDown={handleKeyDownMessage}
                onClick={hidePickers}
              />
            </ConversationTextArea>
          )}
          {/**/}
        </ConversationContainer>
      )}

      <div data-test-id="hidden-upload-form" style={{ display: 'none' }}>
        <form>
          <input type="file" name="file" id="upload-qa-message-photo" data-test-id="upload-qa-message-photo" />

          <button
            type="button"
            id="submit-qa-message-photo"
            data-test-id="submit-qa-message-photo"
            onClick={handleSubmitPhoto}
          >
            Submit
          </button>
        </form>
      </div>

      <BasicModal
        contentLabel="Conversation actions"
        isOpen={actionsOpen}
        closeOnOverlayClick={true}
        onClose={() => handleToggleActionsModal(false)}
        hideClose={true}
      >
        <MenuList>
          {!showSeveredActions && (
            <MenuListItem>
              <Button
                fit
                buttonType="chromeless"
                to={`/report/${otherAccount.hash_id}`}
                customStyle={{ ...textColor.DARK }}
              >
                Report User
              </Button>
            </MenuListItem>
          )}
          {!showSeveredActions && (
            <MenuListItem>
              <Button
                fit
                buttonType="chromeless"
                onClick={e => {
                  e.preventDefault();
                  setBlockModalOpen(true);
                  handleToggleActionsModal(false);
                }}
                customStyle={{ ...textColor.DARK }}
              >
                {otherAccount.is_blocked ? 'Unblock User' : 'Block User'}
              </Button>
            </MenuListItem>
          )}
          {!locked ? (
            <MenuListItem>
              <Button
                fit
                buttonType="chromeless"
                to=""
                onClick={() => {
                  onToggleArchiveConversation(otherAccount.hash_id);
                  handleToggleActionsModal(false);
                }}
                customStyle={{ ...textColor.DARK }}
              >
                {archived ? 'Unarchive' : 'Archive'} This Conversation
              </Button>
            </MenuListItem>
          ) : (
            <MenuListItem>
              <Button
                data-test-id="btn-cancel-offer"
                fit
                buttonType="chromeless"
                to=""
                onClick={() => {
                  onCancelOffer(otherAccount.hash_id);
                  handleToggleActionsModal(false);
                  trackOfferCancelled(offerData.initiator_hash_id, {
                    Amount: offerData.price_original,
                    User: otherAccount.hash_id,
                    Source: 'conversation',
                  });
                }}
                customStyle={{ ...textColor.DARK }}
              >
                Cancel this offer
              </Button>
            </MenuListItem>
          )}
          {showRefundListItem && (
            <MenuListItem>
              <label style={{ ...textColor.DARK }} dangerouslySetInnerHTML={{ __html: formatRefundListItem() }}></label>
              <br />
              <br />
              {!isAttractive && !locked_request_refund && !locked && (
                <span style={{ ...textColor.DARK }}>
                  <small>
                    <i>You cannot have discussed contact info or date specifics. Subject to discretion.</i>
                  </small>
                </span>
              )}
            </MenuListItem>
          )}
        </MenuList>
      </BasicModal>

      <ConfirmationModal
        contentLabel="Block Modal"
        isOpen={blockModalOpen}
        description={`Are you sure you want to block ${otherAccount.username}?`}
        cancelText="Nevermind"
        confirmText="Block"
        onClose={() => setBlockModalOpen(false)}
        onConfirm={() => onBlockUser(otherAccount.hash_id, otherAccount.username)}
      />

      <ConfirmationModal
        contentLabel="Delete Modal"
        isOpen={deleteImgModal}
        description={`Are you sure you want to delete this for ${deleteFor === 'self' ? 'yourself' : 'both'}?`}
        cancelText="Nevermind"
        confirmText="Delete"
        onClose={() => setDeleteImgModal(false)}
        onConfirm={confirmDelete}
      />

      <ConfirmationModal
        contentLabel="Report Modal"
        isOpen={reportImgModal}
        description={
          'Are you sure you want to report this photo message? This user will be blocked from you after reporting.'
        }
        cancelText="Nevermind"
        confirmText="Report"
        onClose={() => setReportImgModal(false)}
        onConfirm={confirmReportImg}
      />
    </React.Fragment>
  );
};

Conversation.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  offer: PropTypes.object.isRequired,
  other_profile: PropTypes.object.isRequired,
  pokes: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired,
  goToProfile: PropTypes.func.isRequired,
  onPostMessage: PropTypes.func.isRequired,
};

export default Conversation;
