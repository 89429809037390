import React, { ReactElement } from 'react';

const DashboardDiscoverModeArrowRightIcon = ({
  customStyle,
  ...rest
}: {
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <path
        d="M19.5 34C17.425 34 15.475 33.6062 13.65 32.8187C11.825 32.0312 10.2375 30.9625 8.8875 29.6125C7.5375 28.2625 6.46875 26.675 5.68125 24.85C4.89375 23.025 4.5 21.075 4.5 19C4.5 16.925 4.89375 14.975 5.68125 13.15C6.46875 11.325 7.5375 9.7375 8.8875 8.3875C10.2375 7.0375 11.825 5.96875 13.65 5.18125C15.475 4.39375 17.425 4 19.5 4C21.575 4 23.525 4.39375 25.35 5.18125C27.175 5.96875 28.7625 7.0375 30.1125 8.3875C31.4625 9.7375 32.5312 11.325 33.3187 13.15C34.1062 14.975 34.5 16.925 34.5 19C34.5 21.075 34.1062 23.025 33.3187 24.85C32.5312 26.675 31.4625 28.2625 30.1125 29.6125C28.7625 30.9625 27.175 32.0312 25.35 32.8187C23.525 33.6062 21.575 34 19.5 34ZM19.8 20.5L18.45 21.85C18.175 22.125 18.0375 22.475 18.0375 22.9C18.0375 23.325 18.175 23.675 18.45 23.95C18.725 24.225 19.075 24.3625 19.5 24.3625C19.925 24.3625 20.275 24.225 20.55 23.95L24.45 20.05C24.75 19.75 24.9 19.4 24.9 19C24.9 18.6 24.75 18.25 24.45 17.95L20.55 14.05C20.275 13.775 19.925 13.6375 19.5 13.6375C19.075 13.6375 18.725 13.775 18.45 14.05C18.175 14.325 18.0375 14.675 18.0375 15.1C18.0375 15.525 18.175 15.875 18.45 16.15L19.8 17.5H15C14.575 17.5 14.2188 17.6438 13.9312 17.9313C13.6438 18.2188 13.5 18.575 13.5 19C13.5 19.425 13.6438 19.7813 13.9312 20.0688C14.2188 20.3563 14.575 20.5 15 20.5H19.8Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardDiscoverModeArrowRightIcon;
