import { Account } from '../models/Account';
import { Profile } from '../models/Profile';
import { evaluateOrOperator } from '../utils/helpers';
import { AM_SET_DEFAULT_OFFER_FEATURE_NAME } from './evidentlyFeatures/AMSetDefaultOffer';

const getEvidentlyFeatureByKey = (profile: Profile, settings: any, featureName: string) => {
  const feature = settings?.evidently?.[featureName];

  if (feature && profile && profile.isAttractive()) {
    return {
      experiment: featureName,
      variation: feature.variation,
      hasSetDefaultOfferAmount: profile.getData()?.has_set_default_offer_amount || false,
    };
  }

  return null;
};

const getPushSubscriptionID = () => {
  if ('pushcrew' in window && pushcrew.subscriberId) {
    return pushcrew.subscriberId;
  }
  return '';
};

const isPushApproved = () => {
  if ('pushcrew' in window && pushcrew.subscriberId) {
    return true;
  }
  return false;
};

const getSeekingType = (profile: any) => {
  let lookingType = '';
  if (profile && profile?.looking_female && profile?.looking_male) {
    lookingType = 'both';
  }

  if (profile && profile?.looking_female && !profile?.looking_male) {
    lookingType = 'female';
  }

  if (profile && !profile?.looking_female && profile?.looking_male) {
    lookingType = 'male';
  }

  return lookingType;
};

const getDaysSinceJoin = (profile: any) => {
  const join_date = profile?.created_at?.date ? profile?.created_at?.date : '';

  if (join_date !== '') {
    const jd = new Date(join_date);
    const nd = new Date();
    const diffTime = Math.abs(nd - jd);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  return '';
};

export const getVWOObj = (account: Account, settings: any): ReturnType<any> => {
  if (!account.getData() || !settings || !account.getProfile()) {
    return;
  }

  const profile = account.getProfile();
  const profileData = profile.getData();
  const accountData = account.getData();

  return {
    uuid: accountData.hash_id || '',
    current_city: evaluateOrOperator(profileData && profileData.city, ''),
    current_state: evaluateOrOperator(profileData && profileData.region, ''),
    current_country: evaluateOrOperator(profileData && profileData.country, ''),
    account_type: evaluateOrOperator(profileData && profileData.account_type, ''),
    gender: evaluateOrOperator(profileData && profileData.gender, ''),
    offers: evaluateOrOperator(profileData && profileData.offers_counts && profileData.offers_counts.new, ''),
    credits: accountData.credits || 0,
    join_date: evaluateOrOperator(profileData && profileData.created_at.date, ''),
    seeking_type: evaluateOrOperator(profileData && getSeekingType(profileData), ''),
    pathname: evaluateOrOperator(location.pathname, ''),
    days_since_join: evaluateOrOperator(profileData && getDaysSinceJoin(profileData), ''),
    pushSubscriptionID: evaluateOrOperator(getPushSubscriptionID(), ''),
    pushApproved: evaluateOrOperator(isPushApproved(), ''),
    campaign: [],
    campaign_exposure: [],
    monetized_at: evaluateOrOperator(profileData && profileData.monetized_date, ''),
    product_affinity: accountData.product_affinity || '',
    evidently: getEvidentlyFeatureByKey(profile, settings, AM_SET_DEFAULT_OFFER_FEATURE_NAME),
  };
};

export const setVWOObj = (account: Account, settings: any): void => {
  if (!account || !settings) {
    return;
  }

  if (!window.VWOobj || window.VWOobj.pathname !== location.pathname) {
    window.VWOobj = getVWOObj(account, settings);
  }
};

export const clearVWOObj = (): void => {
  if (window.VWOobj) {
    delete window.VWOobj;
  }
};
