import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';
interface BackPackageIconProps {
  customStyle?: any;
  onClick?: (event: React.MouseEvent) => void;
}

const BackPackageIcon = ({ customStyle, onClick, ...rest }: BackPackageIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      style={customStyle}
      onClick={onClick}
      {...rest}
    >
      <g opacity="0.65" filter="url(#filter0_d_5723_14494)">
        <circle cx="18" cy="16" r="14" fill={!isDarkMode() ? '#F5F7FA' : 'black'} />
      </g>
      <path
        d="M24 15.5H11M11 15.5L17.5 22M11 15.5L17.5 9"
        stroke={!isDarkMode() ? '#0A5B91' : 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_5723_14494"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5723_14494" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5723_14494" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default BackPackageIcon;
