import React, { Fragment, ReactElement } from 'react';
import styled from '@emotion/styled';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { backgroundTheme, color, dimensions, textColor, type } from '../../style';
import { IsMobileViewPort, tabletWidth } from '../../utils/helpers';
import PriceInput, { adjustStylePerDigit } from '../../components/pages/Offers/PriceInput';
import OfferPriceBtnSet from '../../components/pages/Offers/OfferPriceBtnSet';
import ButtonSet from '../../components/blocks/ButtonSet';
import Button from '../buttons/Button';
import WYPButtonIcon from '../icons/WYPButtonIcon';
import useModal from '../../hooks/useModal';
import { sendCancel } from '../../actions/profileTrackAction';
import { renderAvatar, tsSince } from '../../common';
import OfferSuccess from '../../components/pages/Offers/OfferSuccess';
import OfferModalFooter from '../../components/pages/Offers/OfferModalFooter';
import useEvidently from '../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../constants/evidently';
import { CurrencyLabelSpan, InputProps } from '../../components/pages/Offers/common';

const PriceContainer = styled('div')(
  {
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: 'red',
    textAlign: 'center',
    width: '85%',
    borderRadius: 6,
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.12)',
  },

  ({ hasErrors }) => ({
    backgroundColor: hasErrors ? 'rgba(204, 84, 84,0.15)' : backgroundTheme.fsModal,
    border: hasErrors ? '1px solid #cc5454' : '1px solid #C4C4C4',
  })
);

const CurrencyTip = styled('div')(
  {
    display: 'inline-block',
    position: 'relative',
  },
  ({ offerPrice }) => ({
    ...setColor[offerPrice],
    ':before': {
      position: 'absolute',
      top: 14,
      right: '85%',
      fontSize: 16,
    },
  })
);

const Time = styled('div')({
  color: '#8492a6',
  textAlign: 'center',
  ...type.SM,
  position: 'absolute',
  bottom: 0,
  right: 15,
  [dimensions.SCREEN_MAX_XS_2]: {
    bottom: -15,
    right: 10,
  },
});

const ButtonDiv = styled('div')({
  paddingTop: 0,
  marginTop: 10,

  [dimensions.SCREEN_MAX_SM]: {
    paddingTop: 0,
    marginTop: 10,
    width: '100%',
  },
});

const OfferAmounts = [50, 100, 150, 200, '???'];
const OfferAmounts75Min = [75, 100, 150, 200, '???'];

const DeclineButton = styled(Button)({
  borderColor: '#2490de',
  color: '#2490de',
  backgroundColor: '#fff',

  '&:hover': {
    color: '#fff',
    backgroundColor: '#2490de',
  },
});

const MaxOfferText = styled('p')({
  margin: '0px auto',
  textAlign: 'center',
  ...type.XS,
});

const CustomButtonStyles = {
  '&:hover': {
    opacity: 0.7,
  },
};

const setColor = {
  default: {
    color: 'grey',
  },
  brand: {
    color: color.BRAND_1,
  },
  alert: { ...textColor.RED },
};

const status = currentUserIsGenerous => (currentUserIsGenerous ? 'Offer' : 'Request');

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

interface InitialOfferPanelProps {
  errors: any;
  hasErrors: any;
  inputRef: any;
  offerPrice: any;
  inputOnChange: any;
  validateTextInput: any;
  updateOfferPrice: any;
  currencyInitial: any;
  currLabel: any;
  currentUserIsGenerous: any;
  offer: any;
  createdAt: any;
  handleSubmit: any;
  sendOfferBtn: any;
  isOfferSending: boolean;
  ignoreOffer: any;
  otherAccount: any;
  otherProfile: any;
  isGenerous: any;
}

const InitialOfferPanel = ({
  hasErrors,
  inputRef,
  offerPrice,
  inputOnChange,
  validateTextInput,
  updateOfferPrice,
  currencyInitial,
  currLabel,
  currentUserIsGenerous,
  offer,
  createdAt,
  handleSubmit,
  sendOfferBtn,
  isOfferSending,
  ignoreOffer,
  otherAccount,
  otherProfile,
  isGenerous,
}: InitialOfferPanelProps): ReactElement => {
  const { confirmModal, basicModal, resetModal } = useModal();
  const { useActiveVariation } = useEvidently();

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const handleOfferSuccess = () => {
    const offerAmount = `${currencyInitial}${currLabel}${offerPrice}`;

    basicModal({
      dateTestId: 'offer-success',
      modalContentLabel: 'Success Offer Modal',
      isModalOpen: true,
      modalBody: (
        <Fragment>
          <OfferSuccess
            offerAmount={offerPrice === '???' ? '' : offerAmount}
            avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
            username={otherAccount.username}
            successOpen={resetModal}
            buttonID="interestedOffers"
            isCounter={false}
            isRevised={false}
            isSuggest={offerPrice === '???' ? true : false}
          />
          <OfferModalFooter />
        </Fragment>
      ),
    });
  };

  const handleCancelSentOffer = () => {
    confirmModal({
      dataTestID: 'offer-cancel-sent-offers',
      modalContentLabel: 'Offers Decline Sent Offers',
      confirmationProps: {
        title: `Decline ${isGenerous ? 'Offer' : 'Request'}?`,
        message: `Are you sure you want to <strong>Decline</strong> your ${
          isGenerous ? 'offer' : 'request'
        } to <strong>${otherAccount.username}</strong>?`,
        confirmText: `Decline ${isGenerous ? 'Offer' : 'Request'}`,
        confirmAction: async () => {
          await ignoreOffer(otherAccount.hash_id);
          sendCancel();
          resetModal();
        },
      },
    });
  };

  const formatSubmitButtonText = () => {
    return isOfferSending ? `Sending ${status(currentUserIsGenerous)}...` : `Send ${status(currentUserIsGenerous)}`;
  };

  return (
    <div style={{ position: 'relative' }}>
      <PriceContainer hasErrors={hasErrors}>
        <CurrencyTip
          offerPrice={colorType(offerPrice, hasErrors)}
          style={{
            paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
          }}
        >
          <CurrencyLabelSpan>
            {currencyInitial}
            {currLabel}
          </CurrencyLabelSpan>
          <PriceInput
            inputRef={inputRef}
            data-test-id="profile-offer-price-input"
            hasErrors={hasErrors}
            value={offerPrice}
            onChange={inputOnChange}
            validateTextFn={validateTextInput}
            minWidth={IsMobileViewPort && !tabletWidth ? 80 : 115}
            inputProps={{
              style: {
                ...InputProps.style,
                marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                textAlign: 'left',
              },
            }}
          />
        </CurrencyTip>
      </PriceContainer>
      <OfferPriceBtnSet
        prices={minOfferIncreaseVariation === '75Min' ? OfferAmounts75Min : OfferAmounts}
        updateOfferPrice={updateOfferPrice}
        currentAmount={offerPrice}
        currLabel={currLabel}
        currInitial={currencyInitial}
        offer={offer}
        isCounter={false}
        customStyle={{ marginTop: 0, marginBottom: 10 }}
      />
      <MaxOfferText>
        Maximum offer amount is {currencyInitial}
        {currLabel}500
      </MaxOfferText>
      <ButtonDiv>
        <ButtonSet align="center">
          {offer && (
            <DeclineButton buttonType={'normal'} onClick={handleCancelSentOffer}>
              Decline
            </DeclineButton>
          )}
          <Button
            id={'make-initial-offer'}
            buttonRef={sendOfferBtn}
            disabled={hasErrors || isOfferSending}
            buttonType="error"
            onClick={e => handleSubmit(e, handleOfferSuccess)}
            type="submit"
            customStyle={CustomButtonStyles}
          >
            <WYPButtonIcon style={{ marginRight: 6 }} />
            {formatSubmitButtonText()}
          </Button>
        </ButtonSet>
      </ButtonDiv>
      {createdAt ? <Time>{createdAt && tsSince(createdAt)}</Time> : null}
    </div>
  );
};

export default InitialOfferPanel;
