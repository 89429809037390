import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageViews = (actionMethod: any): void => {
  const location = useLocation();
  useEffect(() => {
    actionMethod({ isLeaving: false, location });
    return () => {
      actionMethod({ isLeaving: true, location });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};

export default usePageViews;
