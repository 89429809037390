import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import BasicModal from './BasicModal';
import Button from '../../atoms/buttons/Button';
import { textColor } from '../../style';
import { getBrowserDetails, tabletWidth } from '../../utils/helpers';

const isMobile = getBrowserDetails().isMobile && !tabletWidth;

const ModalHeader = styled('h3')(({ prominent }) => ({
  marginTop: 0,
  padding: prominent ? '20px 0px' : 'inherit',
  borderBottom: prominent ? '1px solid #464F67' : 0,
  fontWeight: prominent ? 600 : 0,
  fontSize: prominent ? 24 : 'inherit',
  lineHeight: prominent ? '32px' : 'inherit',
  margin: prominent ? '0px 20px' : 'inherit',
  ...textColor.DARKER,
}));

const ModalDescription = styled('p')(
  {
    paddingBottom: '20px',
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const ModalDescriptionv2 = styled('div')({
  padding: '0px 20px',
  ...textColor.DARK,
});

const ButtonSet = styled('div')(
  ({ mobile, hasFooter }) => ({
    button: {
      marginRight: '8px',
      marginTop: mobile && hasFooter ? 20 : 0,
    },
    'button:last-child': {
      marginRight: 0,
    },
  }),
  ({ align }) => ({ textAlign: align }),
  ({ hasFooter, mobile }) =>
    hasFooter && {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: mobile ? 'flex-start' : 'center',
      padding: '10px 20px 20px 20px',
      flexDirection: mobile ? 'column' : 'row',
    }
);

const ConfirmationModal = (props: any): ReactElement => {
  const {
    onClose,
    onConfirm,
    header,
    description,
    cancelText,
    confirmText,
    hideCancel,
    buttonPosition,
    children,
    buttonID,
    version2,
    footerLinks,
    allChecked,
    modalFooter,
    hideButton = false,
    ...rest
  } = props;

  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  const disabled = version2 ? !allChecked : false;

  return (
    <BasicModal hideClose={true} onClose={onClose} {...rest}>
      {header && <ModalHeader prominent={version2}>{header}</ModalHeader>}
      {description && !version2 && <ModalDescription hasHeader={Boolean(header)}>{description}</ModalDescription>}
      {description && version2 && <ModalDescriptionv2 version2={version2}>{description}</ModalDescriptionv2>}
      {children}
      {!hideButton && (
        <ButtonSet mobile={isMobile} align={buttonPosition} hasFooter={version2}>
          {<div style={{ textAlign: 'left' }}>{footerLinks}</div>}
          {!hideCancel && (
            <Button data-test-id={`${buttonID}Cancel`} onClick={onClose}>
              {cancelText}
            </Button>
          )}
          <Button
            customStyle={version2 ? { width: 146, height: 48, borderRadius: 90 } : {}}
            data-test-id="modal-confirm-button"
            onClick={handleOnConfirm}
            buttonType="primary"
            disabled={disabled}
          >
            {confirmText}
          </Button>
        </ButtonSet>
      )}

      {modalFooter}
    </BasicModal>
  );
};

ConfirmationModal.defaultProps = {
  cancelText: 'No',
  confirmText: 'Yes',
  buttonPosition: 'right',
  onClose: () => null,
};

ConfirmationModal.propTypes = {
  /* Bolded header */
  header: PropTypes.string,
  /* Additional description */
  description: PropTypes.string,
  /* Text of cancel button */
  cancelText: PropTypes.string,
  /* Text of confirm button */
  confirmText: PropTypes.string,
  /* Bool to determine if cancel button should be hidden */
  hideCancel: PropTypes.bool,
  /* Function to handle on clicking confirm button */
  onConfirm: PropTypes.func.isRequired,
  /* Function to handle on clicking cancel button; will also be called if closeOnOverlayClick is set to true and overlay is clicked */
  onClose: PropTypes.func,

  // props for Basic Modal
  /* Name of modal - Required by react-modal for accessibility*/
  contentLabel: PropTypes.string,
  /* Bool to determine if modal is open */
  isOpen: PropTypes.bool.isRequired,
  /* Bool to determine if modal will close on clicking overlay */
  closeOnOverlayClick: PropTypes.bool,
};

export default ConfirmationModal;
