import React from 'react';
import styled from '@emotion/styled';
import RadioButton from '../../atoms/RadioButton';
import ApplePayIcon from '../../atoms/billing/GooglePay/ApplePayIcon';
import { APAY_PAYMENT_CHANNEL } from '../../config/apay';
import { isDarkMode } from '../../utils/helpers';

const Container = styled('div')({
  padding: 0,
  position: 'relative',
});

const ApplePayPaymentOptionContent = styled('div')({
  zIndex: 1,
  position: 'absolute',
  width: 'calc(100% - 40px)',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  padding: '15px 20px',
});

interface IApplePayPaymentOptionProps {
  onChange: (isNewCard: boolean, paymentChannel: string | null) => void;
  paymentChannel: string | null | undefined;
}

const ApplePayPaymentOption: React.FC<IApplePayPaymentOptionProps> = ({ onChange, paymentChannel }) => {
  return (
    <Container className="apple-pay-method">
      <ApplePayPaymentOptionContent>
        <RadioButton
          id="apple_pay"
          name="apple_pay"
          label="Buy with Apple Pay"
          value={'apple_pay'}
          onRadioChange={() => onChange(false, APAY_PAYMENT_CHANNEL)}
          checked={paymentChannel === APAY_PAYMENT_CHANNEL}
          style={{ flexGrow: 4 }}
          labelId="radio-apple-pay"
        />
        <ApplePayIcon darkmode={isDarkMode()} />
      </ApplePayPaymentOptionContent>
    </Container>
  );
};

export default ApplePayPaymentOption;
