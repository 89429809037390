import {
  NOTIFICATION_PUSH,
  NOTIFICATION_POP,
  NOTIFICATION_SHIFT,
  NOTIFICATION_DELETE,
  NOTIFICATION_TOGGLE,
  AlertsState,
  AlertActionTypes,
} from './types/Alert.d';

const initialState: AlertsState = {
  notifications: [],
  enabled: {
    count: false,
    popup: false,
    popup_favorite: false,
    popup_offer: false,
    popup_mail: false,
    popup_favorite_settings: false,
    popup_offer_settings: false,
    popup_mail_settings: false,
  },
};

const alerts = (state = initialState, action: AlertActionTypes): any => {
  const notifications = Object.assign([], state.notifications);
  switch (action.type) {
    case NOTIFICATION_PUSH:
      notifications.push(action.payload);
      return {
        ...state,
        notifications: notifications,
      };
    case NOTIFICATION_POP:
      notifications.pop();
      return {
        ...state,
        notifications: notifications,
      };
    case NOTIFICATION_SHIFT:
      notifications.shift();
      return {
        ...state,
        notifications: notifications,
      };
    case NOTIFICATION_DELETE:
      return {
        ...state,
        notifications: notifications.filter(notification => notification.id !== action.payload),
      };
    case NOTIFICATION_TOGGLE:
      return {
        ...state,
        enabled: action.payload,
      };
    default:
      return state;
  }
};

export default alerts;
