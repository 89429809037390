import React from 'react';
import { isMobile } from 'react-device-detect';
import Button from '../atoms/buttons/Button';
import {
  ButtonAction,
  Description,
  Heading,
  LinkAction,
  StyledContainer,
} from '../components/phone/phoneSettings/styledElements';
import useModal from './useModal';
import RoundErrorIcon from '../atoms/icons/RoundErrorIcon';
import { requestOtpAuth, requestOtp, verifyPassword } from '../sdk';
import { useApp } from '../contexts/AppContext';
import CheckCircleIcon from '../atoms/icons/CheckCircleIcon';
import SignUpEditPhone from '../modules/phoneSignup/SignUpEditPhone';
import { NUMBER_OF_ATTEMPTS } from '../constants/phone';
import { pluralize } from '../common';
import { handleLoginVysionEvent } from '../utils/vysion/login';
import { VYSION_ACTIONS } from '../utils/vysion';
import { LoginClickVysionEventsEnum } from '../utils/vysion/login/click';
import { LoginViewVysionEventsEnum } from '../utils/vysion/login/view';
import { handleJoinVysionEvent } from '../utils/vysion/join';
import { JoinViewVysionEventsEnum } from '../utils/vysion/join/view';
import { JoinClickVysionEventsEnum } from '../utils/vysion/join/click';
import { handlePhoneSettingsVysionEvent } from '../utils/vysion/phoneSettings';
import { PhoneSettingsClickVysionEventsEnum } from '../utils/vysion/phoneSettings/click';

interface RequestResendOtpProps {
  isLogin: boolean;
  isSignUp?: boolean;
  phoneNumber: string;
  showSuccessModal?: boolean;
  onResendSuccess?: () => void;
  onResendFailed?: () => void;
  onResendCompleted?: () => void;
}
interface RequestVerifyPasswordProps {
  password;
  onVerifySuccess?: () => void;
  onVerifyFailed?: (error: any) => void;
  onVerifyCompleted?: () => void;
}
interface ShowIncorrectCodeModalProps {
  isLogin: boolean;
  isSignUp?: boolean;
  phoneNumber: string;
  onResendSuccess?: () => void;
  remainingAttempts?: number;
}
interface showExpiredOTPCountdownModalProps {
  isLogin: boolean;
  phoneNumber: string;
  onResendSuccess?: () => void;
}
interface ShowPhoneSuccessModalProps {
  title: string;
  isSignUp?: boolean;
  isPhoneSettings?: boolean;
  isVerified?: boolean;
  onClosed?: () => void;
  onCloseModal?: () => void;
  showIcon?: boolean;
}

interface showCodeAttemptsExceededModalProps {
  isLogin: boolean;
}
interface showAttemptsExceededModalProps {
  isLogin: boolean;
}

interface IUsePhoneActionModal {
  showIncorrectCodeModal: (props: ShowIncorrectCodeModalProps) => void;
  showExpiredOTPCountdownModal: (props: showExpiredOTPCountdownModalProps) => void;
  showAttemptsExceededModal: (props: showAttemptsExceededModalProps) => void;
  showCodeAttemptsExceededModal: (props: showCodeAttemptsExceededModalProps) => void;
  showPhoneSuccessModal: (props: ShowPhoneSuccessModalProps) => void;
  showEditPhoneNumber: () => void;
  utils: {
    requestResendOtp: (props: RequestResendOtpProps) => void;
    requestVerifyPassword: (props: RequestVerifyPasswordProps) => void;
  };
}

const usePhoneActionModal = (): IUsePhoneActionModal => {
  const { basicModal, resetModal } = useModal();
  const {
    setOtpResendThreshold,
    setShowOtpCountdown,
    otpRemainingAttempts,
    setOtpRemainingAttempts,
    setHasRequestOtpError,
    setOtpFormInputFocus,
  } = useApp();

  const showIncorrectCodeModal = ({
    isLogin,
    isSignUp,
    phoneNumber,
    onResendSuccess,
    remainingAttempts,
  }: ShowIncorrectCodeModalProps) => {
    if (isLogin) {
      handleLoginVysionEvent({
        action: VYSION_ACTIONS.VIEW,
        event: LoginViewVysionEventsEnum.VIEW_INCORRECT_CODE,
      });
    }

    const renderRemainingAttempts = () => {
      if (remainingAttempts && remainingAttempts > 0) {
        const attemptsLeft = NUMBER_OF_ATTEMPTS - remainingAttempts;
        setOtpRemainingAttempts(attemptsLeft);
        return attemptsLeft;
      } else {
        return otpRemainingAttempts;
      }
    };

    const handleVysionClickTryAgain = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_TRY_AGAIN,
        });
      }
    };

    const handleVysionClickClose = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_CLOSE,
        });
      }
    };

    const handleVysionResend = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_RESEND,
        });
      }
    };

    const handleVysionCancel = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_CANCEL,
        });
      }
    };

    const handleResend = async () => {
      handleVysionResend();
      requestResendOtp({ isLogin, isSignUp, phoneNumber, onResendSuccess });
    };

    basicModal({
      dateTestId: 'otp-modal',
      modalContentLabel: 'OTP Modal',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      onCloseClickHandler: handleVysionClickClose,
      modalBody: (
        <StyledContainer style={{ paddingBottom: 20 }}>
          <RoundErrorIcon />
          <Heading>Incorrect code</Heading>
          <Description>{`Incorrect code or your code has expired. ${renderRemainingAttempts()} ${pluralize(
            renderRemainingAttempts(),
            'attempt',
            'attempts'
          )} remaining.`}</Description>
          <ButtonAction>
            <Button
              data-test-id="phone-settings-button"
              customStyle={{ background: '#FFFFFF', color: '#2B8FD7', margin: '0 auto' }}
              buttonType="primary"
              onClick={() => {
                setOtpFormInputFocus(prev => prev + 1);
                resetModal();
                handleVysionClickTryAgain();
              }}
              type={'button'}
              size="small"
            >
              Try Again?
            </Button>
            <Button
              customStyle={{ margin: '0 auto' }}
              onClick={handleResend}
              data-test-id="otp-modal-button"
              buttonType="primary"
              type="submit"
              size="small"
            >
              Resend
            </Button>
          </ButtonAction>

          <LinkAction
            style={{ color: '#595959', fontSize: 14 }}
            onClick={() => {
              handleVysionCancel();
              resetModal();
            }}
          >
            Cancel
          </LinkAction>
        </StyledContainer>
      ),
      customContentStyle: { maxWidth: isMobile ? 420 : 500 },
    });
  };

  const showExpiredOTPCountdownModal = ({ isLogin, phoneNumber, onResendSuccess }: ShowIncorrectCodeModalProps) => {
    const handleResend = () => {
      requestResendOtp({ isLogin, phoneNumber, onResendSuccess });
    };

    basicModal({
      dateTestId: 'otp-modal',
      modalContentLabel: 'OTP Modal',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      modalBody: (
        <StyledContainer style={{ paddingBottom: 20 }}>
          <RoundErrorIcon />
          <Heading>Code Has Expired!</Heading>
          <ButtonAction>
            <Button
              customStyle={{ margin: '0 auto' }}
              onClick={handleResend}
              data-test-id="otp-modal-button"
              buttonType="primary"
              type="submit"
              size="small"
            >
              Resend
            </Button>
          </ButtonAction>
        </StyledContainer>
      ),
      customContentStyle: { maxWidth: isMobile ? 420 : 500 },
    });
  };

  const showAttemptsExceededModal = ({ isLogin }: showAttemptsExceededModalProps) => {
    if (isLogin) {
      handleLoginVysionEvent({
        action: VYSION_ACTIONS.VIEW,
        event: LoginViewVysionEventsEnum.VIEW_REQUEST_ATTEMPTS_EXCEEDED,
      });
    }

    const handleVysionClickClose = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_CLOSE,
        });
      }
    };

    const handleVysionClickOk = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_OK,
        });
      }
    };

    basicModal({
      dateTestId: 'otp-modal',
      modalContentLabel: 'OTP Modal',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      onCloseClickHandler: handleVysionClickClose,
      modalBody: (
        <StyledContainer>
          <RoundErrorIcon />
          <Heading>Requests attempts exceeded</Heading>
          <Description>You have requested too many verification codes.</Description>
          <Description> Please try again in 60 minutes.</Description>
          <ButtonAction>
            <Button
              customStyle={{ margin: '0 auto' }}
              onClick={() => {
                setShowOtpCountdown(false);
                resetModal();
                handleVysionClickOk();
              }}
              data-test-id="otp-modal-button"
              buttonType="primary"
              type="button"
              size="small"
            >
              OK
            </Button>
          </ButtonAction>
        </StyledContainer>
      ),
      customContentStyle: { maxWidth: isMobile ? 420 : 500 },
    });
  };

  const showCodeAttemptsExceededModal = ({ isLogin }: showCodeAttemptsExceededModalProps) => {
    if (isLogin) {
      handleLoginVysionEvent({
        action: VYSION_ACTIONS.VIEW,
        event: LoginViewVysionEventsEnum.VIEW_INCORRECT_CODE_NO_MORE_ATTEMPTS,
      });
    }

    const handleVysionClickClose = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_NO_MORE_ATTEMPTS_CLOSE,
        });
      }
    };

    const handleVysionClickOk = () => {
      if (isLogin) {
        handleLoginVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: LoginClickVysionEventsEnum.CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_OK,
        });
      }
    };

    basicModal({
      dateTestId: 'otp-modal',
      modalContentLabel: 'OTP Modal',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      onCloseClickHandler: handleVysionClickClose,
      modalBody: (
        <StyledContainer>
          <RoundErrorIcon />
          <Heading>Incorrect code</Heading>
          <Description>You have used all your attempts.</Description>
          <Description> You may try again in 60 minutes.</Description>
          <ButtonAction>
            <Button
              customStyle={{ margin: '0 auto' }}
              onClick={() => {
                setShowOtpCountdown(false);
                resetModal();
                handleVysionClickOk();
              }}
              data-test-id="otp-modal-button"
              buttonType="primary"
              type="button"
              size="small"
            >
              OK
            </Button>
          </ButtonAction>
        </StyledContainer>
      ),
      customContentStyle: { maxWidth: isMobile ? 420 : 500 },
    });
  };

  const showPhoneSuccessModal = ({
    title,
    isSignUp = false,
    isPhoneSettings = false,
    onClosed,
    isVerified = false,
    showIcon = true,
    onCloseModal = () => undefined,
  }: ShowPhoneSuccessModalProps) => {
    const handleVysionClickClose = () => {
      if (isSignUp) {
        if (isVerified) {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_CLOSE,
          });
        } else {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: JoinClickVysionEventsEnum.CLICK_JOIN_NEW_VERIFICATION_MODAL_CLOSE,
          });
        }
      }

      if (isPhoneSettings && isVerified) {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_PHONE_NUMBER_UPDATE_SUCCESS_CLOSE,
        });
      }

      onCloseModal();
    };

    const handleVysionClickOk = () => {
      if (isSignUp) {
        if (isVerified) {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_OK,
          });
        } else {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: JoinClickVysionEventsEnum.CLICK_JOIN_NEW_VERIFICATION_MODAL_OK,
          });
        }
      }

      if (isPhoneSettings && isVerified) {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_PHONE_NUMBER_UPDATE_SUCCESS_OK,
        });
      }
    };

    basicModal({
      dateTestId: 'phone-success-verified',
      modalContentLabel: 'Phone Success Verified',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      onCloseClickHandler: handleVysionClickClose,
      modalBody: (
        <StyledContainer>
          {showIcon && <CheckCircleIcon />}
          <Description>{title}</Description>
          <Button
            data-test-id="phone-settings-button"
            customStyle={{ marginTop: 20, marginBottom: 20 }}
            buttonType="primary"
            onClick={() => {
              resetModal();
              if (typeof onClosed === 'function') {
                onClosed();
              }
              handleVysionClickOk();
              onCloseModal();
            }}
            type={'button'}
            size="small"
          >
            OK
          </Button>
        </StyledContainer>
      ),
      customContentStyle: { maxWidth: 420 },
    });
  };

  const showEditPhoneNumber = () => {
    handleJoinVysionEvent({
      action: VYSION_ACTIONS.VIEW,
      event: JoinViewVysionEventsEnum.VIEW_EDIT_PHONE_NUMBER_MODAL,
    });

    const handleVysionClickClose = () => {
      handleJoinVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_PHONE_UPDATE_CLOSE,
      });
    };

    basicModal({
      dateTestId: 'otp-modal',
      modalContentLabel: 'OTP Modal',
      isModalOpen: true,
      withoutBgPattern: true,
      closeOnOverlayClick: false,
      modalBody: <SignUpEditPhone />,
      customContentStyle: { maxWidth: isMobile ? 420 : 500 },
      onCloseClickHandler: handleVysionClickClose,
    });
  };

  const requestResendOtp = async ({
    isLogin,
    isSignUp,
    phoneNumber,
    showSuccessModal = true,
    onResendSuccess,
    onResendFailed,
    onResendCompleted,
  }: RequestResendOtpProps) => {
    try {
      if (isLogin) {
        await requestOtpAuth({ phone_number: `+${phoneNumber}` });
      } else {
        await requestOtp({ phone_number: `+${phoneNumber}` });
      }

      if (typeof onResendSuccess === 'function') {
        onResendSuccess();
      }

      setShowOtpCountdown(true);
      setOtpResendThreshold(prev => prev + 1);
      setHasRequestOtpError('');

      if (showSuccessModal) {
        showPhoneSuccessModal({
          isSignUp,
          showIcon: false,
          title: 'New verification code sent!',
          onClosed: () => setOtpFormInputFocus(prev => prev + 1),
        });
      }
    } catch (error) {
      if (typeof onResendFailed === 'function') {
        onResendFailed();
      }

      const errorCode = error.response.data.code ?? null;

      if (errorCode === 40022002) {
        showAttemptsExceededModal({ isLogin });
      }

      const errorData = error.response.data.errors;

      if (errorData) {
        setHasRequestOtpError(errorData[0].detail);
      }

      const errorFormData = error.response.data?.phone_number ?? [];

      if (errorFormData.length > 0) {
        setHasRequestOtpError(errorFormData[0]);
      }
    } finally {
      if (typeof onResendCompleted === 'function') {
        onResendCompleted();
      }
    }
  };

  const requestVerifyPassword = async ({
    password,
    onVerifySuccess,
    onVerifyFailed,
    onVerifyCompleted,
  }: RequestVerifyPasswordProps) => {
    try {
      await verifyPassword({ password });
      if (typeof onVerifySuccess === 'function') {
        onVerifySuccess();
      }
    } catch (error) {
      if (typeof onVerifyFailed === 'function') {
        onVerifyFailed(error);
      }
    } finally {
      if (typeof onVerifyCompleted === 'function') {
        onVerifyCompleted();
      }
    }
  };

  return {
    showIncorrectCodeModal,
    showExpiredOTPCountdownModal,
    showAttemptsExceededModal,
    showCodeAttemptsExceededModal,
    showPhoneSuccessModal,
    showEditPhoneNumber,
    utils: {
      requestResendOtp,
      requestVerifyPassword,
    },
  };
};

export default usePhoneActionModal;
