import React, { ReactElement } from 'react';

const DashboardDiscoverModeArrowLeftIcon = ({
  customStyle,
  ...rest
}: {
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <path
        d="M19.2 20.5H24C24.425 20.5 24.7812 20.3563 25.0688 20.0688C25.3563 19.7812 25.5 19.425 25.5 19C25.5 18.575 25.3563 18.2188 25.0688 17.9313C24.7812 17.6438 24.425 17.5 24 17.5H19.2L20.55 16.15C20.825 15.875 20.9625 15.525 20.9625 15.1C20.9625 14.675 20.825 14.325 20.55 14.05C20.275 13.775 19.925 13.6375 19.5 13.6375C19.075 13.6375 18.725 13.775 18.45 14.05L14.55 17.95C14.25 18.25 14.1 18.6 14.1 19C14.1 19.4 14.25 19.75 14.55 20.05L18.45 23.95C18.725 24.225 19.075 24.3625 19.5 24.3625C19.925 24.3625 20.275 24.225 20.55 23.95C20.825 23.675 20.9625 23.325 20.9625 22.9C20.9625 22.475 20.825 22.125 20.55 21.85L19.2 20.5ZM19.5 34C17.425 34 15.475 33.6062 13.65 32.8187C11.825 32.0312 10.2375 30.9625 8.8875 29.6125C7.5375 28.2625 6.46875 26.675 5.68125 24.85C4.89375 23.025 4.5 21.075 4.5 19C4.5 16.925 4.89375 14.975 5.68125 13.15C6.46875 11.325 7.5375 9.7375 8.8875 8.3875C10.2375 7.0375 11.825 5.96875 13.65 5.18125C15.475 4.39375 17.425 4 19.5 4C21.575 4 23.525 4.39375 25.35 5.18125C27.175 5.96875 28.7625 7.0375 30.1125 8.3875C31.4625 9.7375 32.5312 11.325 33.3187 13.15C34.1062 14.975 34.5 16.925 34.5 19C34.5 21.075 34.1062 23.025 33.3187 24.85C32.5312 26.675 31.4625 28.2625 30.1125 29.6125C28.7625 30.9625 27.175 32.0312 25.35 32.8187C23.525 33.6062 21.575 34 19.5 34Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardDiscoverModeArrowLeftIcon;
