/**
 * @Author: zachalam
 * @Date:   2017-01-19T16:33:10-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T16:57:12-08:00
 */

import React, { useEffect, FC } from 'react';
import { connect } from 'react-redux';
import { refreshCredits, isFetchingCredits } from '../actions/profileActions';
import Credits from '../components/pages/Settings/Credits';
import { Profile, ProfileMaker } from '../models/Profile';
import Container from '../components/blocks/Container';
import { Content, PrimaryContent } from '../components/blocks/ColLayout';
import Panel from '../atoms/panels/Panel';

interface Props {
  credits: any;
  moreExists: boolean;
  profile: Profile;
  getCredits(): void;
  loadMore(): void;
}
const CreditsContainer: FC<Props> = ({ credits, moreExists, profile, getCredits, loadMore }) => {
  useEffect(() => {
    getCredits();
  }, []);

  return (
    <Container screenTop fullWidth>
      <Content>
        <PrimaryContent fullWidth>
          <Panel withHeader>
            <Credits credits={credits} loadMore={loadMore} moreExists={moreExists} isGenerous={profile.isGenerous()} />
          </Panel>
        </PrimaryContent>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    credits: state.credits,
    moreExists: Boolean(state.meta.credit_current_page < state.meta.credit_total_pages),
    profile: ProfileMaker.create(state.profile.profile),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadMore: () => {
      dispatch(refreshCredits(false));
    },
    getCredits() {
      dispatch(isFetchingCredits());
      dispatch(refreshCredits(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditsContainer);
