import { localMessageThreads } from '../utils/storedLocalData';

const initialState = {
  threads: localMessageThreads(),
  conversation: {},
  isOtherAccountTyping: false,
  fetchingThreads: false,
  fetchingMoreThreads: false,
  fetchingConversation: false,
  messagePendingUpdate: {},
  order: null,
  failedMsg: {},
  error: null,
};

const mail = (state = initialState, action: any): any => {
  switch (action.type) {
    case 'SET_PAGE': {
      return {
        ...state,
        order: action.payload.order,
        conversation: action.payload.conversation,
      };
    }
    case 'STORE_THREADS': {
      return {
        ...state,
        threads: {
          ...action.payload,
        },
      };
    }
    case 'TOGGLE_OTHER_ACCT_TYPING': {
      return {
        ...state,
        isOtherAccountTyping: !state.isOtherAccountTyping,
      };
    }
    case 'START_FETCHING_THREADS':
      return {
        ...state,
        fetchingThreads: action.payload.fetchingThreads,
        fetchingMoreThreads: action.payload.fetchingMoreThreads,
      };
    case 'STORE_FETCHED_THREADS': {
      return {
        ...state,
        fetchingThreads: false,
        fetchingMoreThreads: false,
        threads: { ...action.payload },
      };
    }
    case 'STORE_CONVERSATION': {
      return {
        ...state,
        conversation: {
          ...action.payload,
        },
      };
    }
    case 'STORE_POST_MESSAGE': {
      return {
        ...state,
        threads: {
          ...action.payload.threads,
        },
        conversation: {
          ...action.payload.conversation,
        },
        messagePendingUpdate: {
          ...action.payload.messagePendingUpdate,
        },
      };
    }
    case 'UPDATE_CONVERSATION': {
      return {
        ...state,
        conversation: {
          ...action.payload,
        },
      };
    }
    case 'UPDATE_CONVERSATION_COMPLETE': {
      return {
        ...state,
        conversation: {
          ...action.payload,
        },
        messagePendingUpdate: null,
      };
    }
    case 'UPDATE_THREAD_MESSAGES': {
      return {
        ...state,
        threads: action.payload.threads,
        conversation: action.payload.conversation,
      };
    }
    case 'SET_FETCHING_CONVERSATION':
      return {
        ...state,
        fetchingConversation: action.payload,
      };
    case 'SET_FETCHING_THREADS':
      return {
        ...state,
        fetchingThreads: action.payload,
      };
    case 'CLOSE_CONVERSATION':
      return {
        ...state,
        conversation: {},
        fetchingConversation: false,
      };
    case 'SET_NEW_THREADS':
      return {
        ...state,
        conversation: {},
        threads: {
          ...action.payload,
        },
      };
    case 'SET_FAILED_MSG':
      return {
        ...state,
        failedMsg: {
          ...action.payload,
        },
      };
    case 'PRESET_ORDER':
      return {
        ...state,
        order: action.payload,
      };
    case 'STORE_MAIL_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'CLEAR_MAIL_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default mail;
