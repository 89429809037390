import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import GalleryModal from './GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import { dimensions } from '../../style';
import { PhotoConfig } from '../../config/Media';

const ModalHeader = styled('h3')(() => ({
  marginTop: 30,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '35px',
  color: '#BC2028',
  letterSpacing: -0.56,
  marginBottom: 8,
  [dimensions.SCREEN_MAX_SM]: {
    lineHeight: '28px',
    maxWidth: 200,
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const ModalSubheader = styled('p')(() => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  color: '#595959',
  margin: 0,
  marginBottom: 25,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 350,
    margin: 'auto',
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const AgreementList = styled('ul')({
  padding: 0,
  width: '100%',
  paddingLeft: 20,
  margin: 'auto',
  marginBottom: 25,
  maxWidth: 252,
});

const StyleList = styled('li')({
  padding: '5px 0',
  fontSize: 14,
  lineHeight: '19px',
});

const StyleStrong = styled('strong')({
  fontWeight: 700,
});

const UploadJoinFlowContentModal = (props: any): ReactElement => {
  const { onClose, onConfirm, ...rest } = props;
  useDisableBodyScroll(props.isOpen);

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{ content: { maxWidth: 450, left: 0, right: 0 } }}
      {...rest}
      contentLabel=""
      customContentStyle={{ padding: 20 }}
      customCloseBtnStyle={{ top: 8, right: 8 }}
    >
      <ModalDescription hasHeader={true} data-test-id="gallery-agreement">
        <ModalHeader>Upload Gallery Content</ModalHeader>
        <ModalSubheader>Show off with up to 20 photos</ModalSubheader>
        <AgreementList>
          <StyleList>
            <StyleStrong>All photos will be moderated for content</StyleStrong>
          </StyleList>
          <StyleList>
            Recommended width: <StyleStrong>400px or more</StyleStrong>{' '}
          </StyleList>

          <StyleList>
            Max file size: <StyleStrong>15 Mb</StyleStrong>
          </StyleList>
          <StyleList>
            Max photos allowed: <StyleStrong>20</StyleStrong>
          </StyleList>
          <StyleList>
            Accepted image formats: <br />
            <StyleStrong>{PhotoConfig.allowedMimeTypeText}</StyleStrong>
          </StyleList>
        </AgreementList>

        <PrimaryButton
          customStyle={{
            width: 'inherit',
            fontSize: 14,
            fontWeight: 600,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 22,
            paddingRight: 22,
            marginBottom: 10,
            height: 40,
          }}
          data-test-id={`gallery-upload-content-type`}
          onClick={() => {
            onConfirm(false);
          }}
          label="Select Photo(s)"
        />
      </ModalDescription>
    </GalleryModal>
  );
};

export default UploadJoinFlowContentModal;
