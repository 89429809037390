import React, { useState, useEffect, useRef, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';
import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import TextLink from '../../common/TextLink';
import PriceInput, { adjustStylePerDigit } from './PriceInput';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import {
  counterOffer,
  counterCancel,
  counterModalCustomAmount,
  counterModalClose,
} from '../../../actions/profileTrackAction';
import useModal from '../../../hooks/useModal';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { PriceContainer, InputProps, CurrencyTip, CurrencyLabelSpan } from './common';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 26,
    lineHeight: '26px',
    letterSpacing: '-0.52px',
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

const CounterRange = styled('div')({
  fontSize: 14,
  marginBottom: 9,
  textAlign: 'center',
});

const ErrorContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
});

const ErrorMessage = styled('div')({
  color: 'rgb(204, 84, 84)',
  margin: '16px auto 0',
  maxWidth: '280px',
});

const PurchaseCredits = styled('div')({
  textAlign: 'center',
  marginTop: '12px',
  textDecoration: 'underline',
});

const ButtonDiv = styled('div')({
  marginTop: 25,

  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 0,
    marginBottom: 12,
    paddingTop: 8,
    width: '100%',
  },
});

const getHeadline = username => {
  return (
    <span>
      Counter <strong>{username}&lsquo;s</strong> offer by submitting a new amount below.
    </span>
  );
};

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.min_counter_price : offer && offer.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const renderCounterRange = (currencyInitial, currLabel, minValue, maxValue) => {
  return (
    <CounterRange>
      Enter a new amount between {currencyInitial}
      {currLabel}
      {maxValue} and {currencyInitial}
      {currLabel}
      {minValue}.
    </CounterRange>
  );
};

const setCreateOfferAction = (e, offerAction) => {
  switch (offerAction) {
    case 'Send Counter':
      counterOffer(e);
      break;
    case 'Cancel Counter':
      counterCancel(e);
      break;
    case 'Counter Modal Close':
      counterModalClose(e);
      break;
    default:
      return;
  }
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};

interface OfferCounterOfferProps {
  avatarSrc: any;
  offer: any;
  errors: any;
  closeModal: any;
  currentUserIsGenerous: any;
  clearErrors: any;
  username: any;
  counterOffer: any;
  hashId: any;
  buttonID: any;
  successOpen: any;
  mixpanelEventSource?: string;
}

const OfferCounterOffer = ({
  avatarSrc,
  offer,
  errors,
  closeModal,
  currentUserIsGenerous,
  clearErrors,
  username,
  counterOffer,
  hashId,
  buttonID,
  successOpen,
  mixpanelEventSource,
}: OfferCounterOfferProps): ReactElement => {
  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState('');
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer.data, 'min', '5'));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer.data, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);
  const sendOfferBtn = useRef(null);
  const sendCancelBtn = useRef(null);
  const priceInput = useRef(null);
  const [currencyInitial, setCurrencyInitial] = useState('');
  const [currencyLabel, setCurrencyLabel] = useState('');
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const { resetModal, setModalStateHandler } = useModal();

  useOnClickTriggerTracking('Cancel Counter', sendCancelBtn, setCreateOfferAction);

  useOnClickTriggerTracking('Counter Modal Close', sendCancelBtn, setCreateOfferAction);

  useEffect(() => {
    setModalStateHandler({
      onCloseClick: () => {
        counterModalClose();
        resetModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearErrors();
    trackFocus(priceInput, counterModalCustomAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { data } = offer;
    setMaxValue(offerMinMaxVal(data, 'max', '500'));
    setMinValue(offerMinMaxVal(data, 'min', '5'));
    setCurrencyInitial(data.currency_original.data.initial);
    setCurrencyLabel(data.currency_original.data.label_unicode);
  }, [offer]);

  useEffect(() => {
    setOfferPrice('');
    if (priceInput.current) {
      priceInput.current.focus();
    }
  }, [priceInput]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const inputOnChange = e => {
    if (!e.target || typeof e.target.value !== 'string') {
      return;
    }

    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValueNum.toString().length;

    if (e.target.value.length <= maxLength) {
      const isError = inputVal < minValueNum || inputVal > maxValueNum;
      updateOfferPrice(inputVal, isError);
    } else {
      e.preventDefault();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = { source: mixpanelEventSource, price: offerPrice, account: hashId };
    const offerAmount = `${currencyInitial}${currLabel}${offerPrice}`;

    setOfferSending(true);

    showMandatoryCompleteProfile(
      () => {
        setCreateOfferAction(e, 'Send Counter');

        counterOffer(formData)
          .then(() => {
            closeModal();
            setOfferSending(false);
            successOpen(true, offerAmount, true);
          })
          .catch(() => {
            setOfferSending(false);
            setHasErrors(true);
          });
      },
      () => setOfferSending(false),
      'Counter Offer'
    );
  };

  const currLabel = String.fromCharCode(currencyLabel);

  return (
    <>
      <Form>
        <HeaderDiv>
          <AvatarTitleDiv>
            <Avatar
              data-test-id="offer-modal-avatar"
              src={avatarSrc}
              width={getBrowserDetails().isMobile ? '75px' : '100px'}
              customStyle={{
                backgroundColor: '#ccc',
              }}
            />
            <TitleDiv>Counter Offer!</TitleDiv>
          </AvatarTitleDiv>
          <Headline
            style={{
              color: patternModal.headerColor,
              margin: 'auto',
              marginTop: 20,
              marginBottom: 7,
              fontSize: 16,
              fontWeight: 400,
              lineHeight: '21px',
              letterSpacing: '-0.32px',
              maxWidth: 296,
            }}
            data-test-id="profile-offer-headline"
            size="SM"
          >
            {getHeadline(username)}
          </Headline>
        </HeaderDiv>
        <Content>
          <div>
            <PriceContainer hasErrors={hasErrors}>
              <CurrencyTip
                offerPrice={colorType(offerPrice, hasErrors)}
                style={{
                  paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
                }}
              >
                <CurrencyLabelSpan>
                  {currencyInitial}
                  {currLabel}
                </CurrencyLabelSpan>
                <PriceInput
                  inputRef={priceInput}
                  data-test-id="offer-price-input"
                  hasErrors={hasErrors}
                  value={offerPrice}
                  onChange={inputOnChange}
                  validateTextFn={validateTextInput}
                  placeholder={defaultValue}
                  inputProps={{
                    style: {
                      ...InputProps.style,
                      marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                      textAlign: 'left',
                    },
                  }}
                  minWidth={IsMobileViewPort ? 100 : 120}
                />
              </CurrencyTip>
            </PriceContainer>
            {renderCounterRange(currencyInitial, currLabel, minValue, maxValue)}
            {errors ? (
              <ErrorContainer>
                <ErrorMessage>{errors.price}</ErrorMessage>
                {currentUserIsGenerous && Number(offerPrice) >= 200 && (
                  <PurchaseCredits>
                    <TextLink to="/packages" onClick={() => closeModal()}>
                      Purchase credits now.
                    </TextLink>
                  </PurchaseCredits>
                )}
              </ErrorContainer>
            ) : null}
          </div>
          <ButtonDiv>
            <ButtonSet align="center">
              <Button
                id={buttonID}
                buttonRef={sendOfferBtn}
                disabled={hasErrors || isOfferSending}
                buttonType="error"
                onClick={handleSubmit}
                type="submit"
                data-test-id="modal-accept-offer-btn"
                customStyle={{ fontSize: 14 }}
              >
                Send Counter Offer
              </Button>
            </ButtonSet>
            <ButtonSet align="center">
              <Button
                buttonType="chromeless"
                buttonRef={sendCancelBtn}
                data-test-id={`${buttonID}Cancel`}
                disabled={isOfferSending}
                onClick={e => {
                  e.preventDefault();
                  closeModal();
                }}
                customStyle={{ color: '#8392A7', fontWeight: 400, fontSize: 14 }}
              >
                {' '}
                Cancel
              </Button>
            </ButtonSet>
          </ButtonDiv>
        </Content>
      </Form>
    </>
  );
};

export default OfferCounterOffer;
