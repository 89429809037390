import React, { Component } from 'react';
import { connect } from 'react-redux';
import Restore from '../components/pages/Restore';
import { validateResetPasswordToken, setNewPassword } from '../actions/authActions';
import queryString from 'query-string';
import Footer from '../components/blocks/Footer';
import PageWithFooter from '../components/blocks/PageWithFooter';
import NotificationBanner from '../components/blocks/NotificationBanner';
import { clearNotificationMessage } from '../actions/notificationActions';

class RestoreContainer extends Component {
  constructor(props) {
    super(props);

    const queryStringParsed = queryString.parse(props.location.search);

    // validate resettoken
    props.validateReset(queryStringParsed.token);
  }

  render() {
    const { notification, isAuthenticated, location, clearNotificationMessage, loadPageUrl } = this.props;

    const queryStringParsed = queryString.parse(location.search);

    return (
      <React.Fragment>
        <NotificationBanner
          notification={notification}
          clearNotificationMessage={clearNotificationMessage}
          loadPageUrl={loadPageUrl}
          isAuthenticated={isAuthenticated}
          location={location}
        />

        <div id="main">
          <PageWithFooter>
            <Restore token={queryStringParsed.token} {...this.props} />
          </PageWithFooter>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    tokenValid: state.auth.resetTokenValidity,
    errors: state.common.errors,
    notification: state.notification,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    loadPageUrl(linkUrl) {
      dispatch(clearNotificationMessage());
      ownProps.navigate(linkUrl);
    },
    handleSubmit: formData => {
      dispatch(setNewPassword(formData, ownProps.navigate));
    },
    validateReset: token => {
      dispatch(validateResetPasswordToken(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreContainer);
