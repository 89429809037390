import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { clearEverything } from '../actions/commonActions';
import { unlinkAccount, storeUserToUnlink } from '../actions/unlinkActions';
import UnlinkAccount from '../components/pages/UnlinkAccount';
import { getAccountToUnlink } from '../sdk';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const UnlinkEmailAccountContainer = ({ clearEverything, getAccountToUnlink, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryStringParsed, setQueryStringParsed] = useState(queryString.parse(location.search));

  useEffect(() => {
    getAccountToUnlink({
      data: queryStringParsed.data,
    }, navigate);

    clearEverything();

    () => {
      setQueryStringParsed('');
    };
  }, [clearEverything, getAccountToUnlink, queryStringParsed.data]);

  return <UnlinkAccount {...rest} location={queryStringParsed} />;
};

const mapStateToProps = ({ unlink }) => {
  return {
    email: unlink.email,
    hasBeenUnlinked: unlink.hasBeenUnlinked,
    hashId: unlink.hash_id,
    username: unlink.username,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearEverything: () => {
      dispatch(clearEverything());
    },
    submit: payload => {
      dispatch(unlinkAccount(payload));
    },
    getAccountToUnlink: (payload, navigate) => {
      getAccountToUnlink(payload).then(
        resp => {
          const response = {
            ...resp.data.data,
            email: resp.data.meta.email,
          };

          dispatch(storeUserToUnlink(response));
        },
        () => {
          navigate('/login');
        }
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkEmailAccountContainer);
