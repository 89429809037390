import React, { useState, useEffect, useRef, ReactElement, Dispatch, SetStateAction } from 'react';
import MasterConfig, { MAX_NUMBER_PHOTOS } from '../../../config/Master';
import styled from '@emotion/styled';
import { css } from 'glamor';
import Button from '../../../atoms/buttons/Button';
import Dropzone from 'react-dropzone';
// import Loader from '../../../atoms/LoaderStyled';
import Loader from '../../../atoms/loader/Loader';
import { dimensions, backgroundTheme } from '../../../style';
import { getBrowserDetails, IsMobileViewPort, tabletWidth, ternaryFunc } from '../../../utils/helpers';
import AddContentIcon from '../../../atoms/icons/AddContentIcon';
import { PhotoConfig } from '../../../config/Media';
import UnsupportedFileTypeErrorModal from '../../../modules/modals/UnsupportedFileTypeErrorModal';
import UploadMediaAgreementModal from '../../../modules/modals/UploadMediaAgreementModal';
import GalleryUploadRemoveIcon from '../../../modules/gallery/GalleryUpload/icons/GalleryUploadRemoveIcon';
import IconPencilFillCircle from '../../../atoms/icons/IconPencilFillCircle';
import { black, white } from '../../../theme/colors';
import { PhotoFileType } from '../../../modules/gallery/constants';
import VisibilityIcon from '../../../atoms/icons/VisibilityIcon';
import { validateFiles } from '../../../modules/gallery/helpers';
import IconPrimary from '../../../atoms/icons/IconPrimary';
import UploadJoinFlowContentModal from '../../../modules/modals/UploadJoinFlowContentModal';
import TooManyFilesWarningModal from '../../../modules/modals/TooManyFilesWarningModal';
import InvalidFilesErrorModal from '../../../modules/modals/InvalidFilesErrorModal';

const StyledDropzone = styled(Dropzone)(({ photoheight, customStyledDropzoneStyles }) => ({
  boxSizing: 'border-box',
  border: '1px solid #2B8FD7',
  background: backgroundTheme.AddMedia,
  borderRadius: 6,
  textAlign: 'center',
  cursor: 'pointer',
  paddingTop: !getBrowserDetails().isMobile ? '37%' : ternaryFunc(IsMobileViewPort, '27%', '44%'),
  width: '100%',
  minHeight: photoheight ? photoheight : ternaryFunc(tabletWidth, 237, 205),
  [dimensions.SCREEN_MAX_MD]: {
    minHeight: photoheight ? photoheight : ternaryFunc(IsMobileViewPort, 148, 237),
  },
  ...customStyledDropzoneStyles,
}));

const PhotoItem = styled('div')(
  {
    boxSizing: 'border-box',
    float: 'left',
    width: '100%',
    // height: '100%',
  },
  ({ isRelative }: { isRelative: boolean }) => (isRelative ? { position: 'relative' } : null)
);

const hoverStyles = `
  color: #bc2028 !important;

  svg,
  svg path,
  button svg path {
    fill: #bc2028 !important;
    color: #bc2028 !important;
    stroke: #bc2028 !important;
  }
`;

const MediaDropzone = styled(StyledDropzone)`
  &:hover {
    ${props => (props.disableHover ? '' : hoverStyles)};
  }
`;

const PrivateEyeContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  height: 80,
  width: 80,
  zIndex: 1,
  borderTopLeftRadius: 5,
});

const PrivateNotch = styled('div')({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: 'rgb(188, 32, 40, 0.9)',
  transform: 'rotate(45deg)',
  width: 80,
  height: 80,
  top: -40,
  left: -53,
});

interface PhotoDropZoneProps {
  refreshAndClearErrors: any;
  isUploading: any;
  photosLength: any;
  hasPublicPhotos: boolean;
  customStyledDropzoneStyles?: React.CSSProperties;
  photoItemStyle?: React.CSSProperties;
  mediaUrl?: string;
  shouldPreviewMedia?: boolean;
  customAddIconStyle?: React.CSSProperties;
  refKey?: number | null;
  onCancelUpload?: () => void;
  disableHover?: boolean;
  setFileList: Dispatch<SetStateAction<any>>;
  onRemove: (photo: PhotoFileType) => void;
  onEdit: (photo: PhotoFileType) => void;
  isPrivate: boolean;
  isLoading: boolean;
  isSmallThumbnail: boolean;
  isMainProfile?: boolean;
  countTotalPhotos: number;
}

const RemovePhoto = ({ onClick, isSmallThumbnail }: { onClick: () => void; isSmallThumbnail: boolean }) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        position: 'absolute',
        bottom: isSmallThumbnail ? 10 : 13,
        right: isSmallThumbnail ? 10 : 14,
        cursor: 'pointer',
        zIndex: 3,
      }}
    >
      <GalleryUploadRemoveIcon
        customStyle={{ height: isSmallThumbnail ? 20 : 24, width: isSmallThumbnail ? 20 : 24 }}
      />
    </div>
  );
};

const EditPhoto = ({ onClick, isSmallThumbnail }: { onClick: () => void; isSmallThumbnail: boolean }) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        position: 'absolute',
        top: isSmallThumbnail ? 10 : 12,
        right: isSmallThumbnail ? 11 : 14,
        cursor: 'pointer',
        zIndex: 3,
      }}
    >
      <IconPencilFillCircle
        fillColor={black}
        iconColor={white}
        customStyle={{ height: isSmallThumbnail ? 20 : 24, width: isSmallThumbnail ? 20 : 24 }}
      />
    </div>
  );
};

const MainProfileLabel = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 12,
        bottom: 13,
        zIndex: 3,
        background: '#BC2028',
        padding: 4,
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 6,
      }}
    >
      <IconPrimary />
      <span style={{ color: '#fff', marginLeft: 3, fontSize: 12, fontWeight: 500, letterSpacing: -0.24 }}>
        Main Profile
      </span>
    </div>
  );
};

const JoinPhotoDropzone = ({
  refreshAndClearErrors,
  isUploading,
  photosLength,
  customStyledDropzoneStyles,
  mediaUrl,
  shouldPreviewMedia = false,
  customAddIconStyle,
  refKey = null,
  onCancelUpload,
  setFileList,
  onRemove,
  onEdit,
  isPrivate,
  isLoading,
  isSmallThumbnail,
  isMainProfile = false,
  photoItemStyle,
  countTotalPhotos,
}: PhotoDropZoneProps): ReactElement => {
  const dropZone = useRef(refKey);
  const [photoHeight, setPhotoHeight] = useState(null);
  const [showUnsupportedFileModal, setShowUnsupportedFileModal] = useState(false);
  const [showUnsupportedFileWarningModal, setShowUnsupportedFileWarningModal] = useState(false);
  const [showTooManyFilesWarningModal, setShowTooManyFilesWarningModal] = useState(false);
  const [showInvalidFilesErrorModal, setShowInvalidFilesErrorModal] = useState(false);
  const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
  const [showContentTypeModal, setShowContentTypeModal] = useState(false);
  const [allInvalidFiles, setInvalidFiles] = useState<File[]>([]);
  const [allValidFiles, setValidFiles] = useState<File[]>([]);

  useEffect(() => {
    refreshAndClearErrors(false);
    document.getElementById('Photo0') && setPhotoHeight(document.getElementById('Photo0').clientHeight);
    document.getElementById('PhotoB0') && setPhotoHeight(document.getElementById('PhotoB0')?.clientHeight);
  }, [refreshAndClearErrors]);

  const onDropPublic = async (acceptedFiles, rejectFiles = []) => {
    const { validFiles, invalidFiles } = await validateFiles('photo', [...acceptedFiles, ...rejectFiles]);
    const totalFilesCount = validFiles.length;

    if (invalidFiles.length > 0) {
      setValidFiles(validFiles);
      setInvalidFiles(invalidFiles);
      setShowInvalidFilesErrorModal(true);
    } else if (rejectFiles.length > 0) {
      if (rejectFiles.length > 1) {
        setShowUnsupportedFileWarningModal(true);
      } else {
        setShowUnsupportedFileModal(true);
      }
    }

    if (totalFilesCount + countTotalPhotos > MAX_NUMBER_PHOTOS) {
      setShowTooManyFilesWarningModal(true);
    }

    setFileList(Array.from(validFiles));
  };

  const handleSubmitPhoto = async () => {
    const allFiles = document.getElementById('upload-qa-photo')?.files;
    const allSelectedFiles: File[] = Array.from(allFiles);

    for (let index = 0; index < allSelectedFiles.length; index++) {
      const selectedFile: File = allSelectedFiles[index];

      if (!PhotoConfig.allowedMimeType.includes(selectedFile.type)) {
        setShowUnsupportedFileModal(true);
        return;
      }
    }

    onDropPublic(Array.from(allSelectedFiles));
  };

  const handleConfirmUpload = () => {
    // Programatically trigger dropzone click event
    dropZone && dropZone.current && dropZone.current.open();
  };

  const onClickPublic = () => {
    if (isUploading || isLoading) {
      return;
    }

    setShowUnsupportedFileModal(false);
    setShowUnsupportedFileWarningModal(false);
    handleShowAgreement();
  };

  const handleShowAgreement = () => {
    if (localStorage.getItem('wyp_gallery_agreement') !== 'true') {
      setShowConfirmUploadModal(true);
    } else {
      setShowContentTypeModal(true);
    }
  };

  const onConfirmAgreement = alwasyShow => {
    setShowConfirmUploadModal(false);
    setShowContentTypeModal(true);
    localStorage.setItem('swuiMediaType', 'photo');

    if (alwasyShow === false && localStorage.getItem('wyp_gallery_agreement') !== 'true') {
      localStorage.setItem('wyp_gallery_agreement', 'true');
    }
  };

  const handleCloseUnsupportedFileTypeModal = () => {
    setShowUnsupportedFileModal(false);
    setShowUnsupportedFileWarningModal(false);
  };

  const handleCloseInvalidFilesErrorModal = () => {
    setShowInvalidFilesErrorModal(false);
    setValidFiles([]);
    setInvalidFiles([]);
  };

  const renderDropzone = (isFetching, disabled = false, mediaUrl, shouldPreviewMedia) => {
    return (
      <PhotoItem isRelative onClick={onClickPublic} data-test-a="PhotoItem">
        <Loader active={isFetching || isLoading} imgStyle={{ width: isSmallThumbnail ? 40 : 60 }} />

        {isPrivate && (
          <PrivateEyeContainer>
            <PrivateNotch />
            <VisibilityIcon
              customStyle={{ position: 'absolute', top: -1, left: -3, zIndex: 3, padding: 8, width: 15, height: 15 }}
            />
          </PrivateEyeContainer>
        )}
        {mediaUrl && !isFetching && (
          <div>
            <EditPhoto onClick={onEdit} isSmallThumbnail={isSmallThumbnail} />
            <RemovePhoto onClick={onRemove} isSmallThumbnail={isSmallThumbnail} />
            {isMainProfile && <MainProfileLabel />}
          </div>
        )}

        <div
          style={{
            backgroundImage: mediaUrl && shouldPreviewMedia ? `url(${mediaUrl})` : '',
            backgroundSize: 'cover',
            borderRadius: 6,
          }}
        >
          <MediaDropzone
            accept={PhotoConfig.allowedMimeType}
            ref={dropZone}
            multiple={true}
            onDrop={onDropPublic}
            disabled={disabled}
            disableClick={true}
            data-test-id="upload-photo"
            data-nux="add-content"
            photoheight={photoHeight}
            onFileDialogCancel={() => {
              if (onCancelUpload && typeof onCancelUpload === 'function') {
                onCancelUpload();
              }
            }}
            customStyledDropzoneStyles={{
              ...customStyledDropzoneStyles,
              ...{
                backgroundImage: mediaUrl && shouldPreviewMedia ? `url(${mediaUrl})` : '',
                backgroundSize: 'cover',
              },
            }}
          >
            {!disabled ? (
              <div className="add-content-box" style={{ height: '100%', position: 'relative' }}>
                {(!mediaUrl || !shouldPreviewMedia) && !isLoading ? (
                  <>
                    <AddContentIcon
                      cssStyle={{
                        ...customAddIconStyle,
                        ...{ marginBottom: 5, '&:hover': { color: '#BC2028 !important' } },
                      }}
                    />
                    <Button
                      type="button"
                      buttonType="chromeless"
                      data-test-id="gallery-add-content"
                      customStyle={{
                        fontWeight: 'bold',
                        color: '#3e79a5',
                        padding: 0,
                        width: '100%',
                        '&:hover': { color: '#BC2028 !important' },
                      }}
                    ></Button>
                  </>
                ) : null}
              </div>
            ) : (
              <div className={`${css({ display: 'table', height: '100%' })}`}>
                <div
                  className={`${css({
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    fontSize: 14,
                    padding: '0 5px',
                  })}`}
                >
                  You have reached your maximum number of photos.{' '}
                </div>
              </div>
            )}
          </MediaDropzone>
        </div>
      </PhotoItem>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', overflow: 'hidden', ...(photoItemStyle ?? {}) }}>
      {renderDropzone(
        isUploading,
        Boolean(photosLength >= MasterConfig.MAX_NUMBER_PHOTOS),
        mediaUrl,
        shouldPreviewMedia
      )}
      <div style={{ display: 'none' }}>
        <form>
          <input type="file" multiple name="file" id="upload-qa-photo" data-test-id="upload-qa-photo" />
          <button id="submit-qa-photo" data-test-id="submit-qa-photo" type="button" onClick={handleSubmitPhoto}>
            Submit
          </button>
        </form>
      </div>

      <UploadJoinFlowContentModal
        isOpen={showContentTypeModal}
        onClose={() => setShowContentTypeModal(false)}
        onConfirm={() => {
          setShowContentTypeModal(false);
          handleConfirmUpload();
        }}
      />

      <UnsupportedFileTypeErrorModal
        isOpen={showUnsupportedFileModal || showUnsupportedFileWarningModal}
        onUploadAnother={handleCloseUnsupportedFileTypeModal}
        onClose={handleCloseUnsupportedFileTypeModal}
        isWarning={showUnsupportedFileWarningModal}
        isMultiple={true}
      />

      <TooManyFilesWarningModal
        isOpen={showTooManyFilesWarningModal}
        onClose={() => {
          setShowTooManyFilesWarningModal(false);
        }}
      />

      <InvalidFilesErrorModal
        mediaType="photo"
        validFiles={allValidFiles}
        invalidFiles={allInvalidFiles}
        isOpen={showInvalidFilesErrorModal}
        onClose={handleCloseInvalidFilesErrorModal}
        hasMultipleFileSelected={true}
      />

      <UploadMediaAgreementModal
        isOpen={showConfirmUploadModal}
        onClose={() => setShowConfirmUploadModal(false)}
        onConfirm={alwasyShow => {
          onConfirmAgreement(alwasyShow);
        }}
      />
    </div>
  );
};

export default JoinPhotoDropzone;
