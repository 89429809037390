// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

import { subscribeWebPush, unsubscribeWebPush } from '../sdk';
import { PWA_PUBLIC_KEY } from '../config/Master';
import { setLocalItem, getLocalItem, removeLocalItem } from '../common';
import store from '../store';
import { toggleDesktopNotificationStatus } from '../actions/profileActions';
import { urlBase64ToUint8Array, getBrowserDetails, checkActiveSubscription } from './helpers';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const requestNotificationPermission = () => {
  // ios dos not support notification API
  // https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#Browser_compatibility
  const NotifFunction = typeof Notification === 'function';
  const alreadyGranted = Notification && Notification.permission === 'granted';
  if (alreadyGranted) {
    return new Promise(resolve => {
      store.dispatch(toggleDesktopNotificationStatus(true));
      resolve(alreadyGranted);
    });
  }
  return new Promise((resolve, reject) => {
    if (NotifFunction) {
      const permissionResult = Notification.requestPermission(result => {
        // Enable redux desktopNotification on first grant
        const notifStatus = result === 'granted';
        store.dispatch(toggleDesktopNotificationStatus(notifStatus));
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }
  }).then(permissionResult => {
    if (permissionResult !== 'granted') {
      throw new Error('Permission denied');
    }
  });
};

export function register(config: any = null): any {
  const desktopNotifiocationStatus = getLocalItem('wyp_desktop_notification');
  if (desktopNotifiocationStatus && desktopNotifiocationStatus === 'false') {
    removeLocalItem('pushSubscription');
    toggleDesktopNotificationStatus(false);
    return;
  }

  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    // adding url string to avoid cache issue
    const swUrl = `/sw.js?t_=${Math.floor(Math.random() * 999)}`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config);
      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit http://bit.ly/CRA-PWA'
        );
      });
    } else {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config);
    }
  }
}

const manageSubscription = async (currentSubscription, isSameSubscription, subscription, pushSubscriptionData) => {
  if (currentSubscription === null && !isSameSubscription) {
    // unsubscribe first in case current endpoint is subscribed
    await unsubscribeWebPush(subscription);
    // subscribes to push if no previous subscription
    const resData = await subscribeWebPush(subscription);
    if (!(resData.data && resData.data.success)) {
      return;
    } else {
      setLocalItem('pushSubscription', pushSubscriptionData);
    }
    return resData;
  }
};

const sendToServer = async subscription => {
  const currentSubscription = getLocalItem('pushSubscription');
  const currentSubscriptionEndpoint = currentSubscription !== null && JSON.parse(currentSubscription).endpoint;
  const currentSubscriptionUser = currentSubscription !== null && JSON.parse(currentSubscription).user;
  const isSameSubscription =
    currentSubscriptionEndpoint === JSON.parse(subscription).endpoint &&
    currentSubscriptionUser === store.getState().profile.hash_id;
  const pushSubscriptionData = JSON.stringify({
    endpoint: JSON.parse(subscription).endpoint,
    user: store.getState().profile.hash_id,
    date: new Date().toString(),
  });

  if (checkActiveSubscription() || (currentSubscription !== null && !isSameSubscription)) {
    // unsubscribe previous subscription if new endpoint does not match
    const unsubRes = await unsubscribeWebPush(currentSubscription);

    if (!(unsubRes.data && unsubRes.data.success)) {
      console.log('An error occurred');
      return;
    } else {
      // Removes pushSubscription data from localStorage
      removeLocalItem('pushSubscription');

      // re-subscribe the new subscription data
      const resData = await subscribeWebPush(subscription);
      if (!(resData.data && resData.data.success)) {
        console.log('An error occurred');
        return;
      } else {
        setLocalItem('pushSubscription', pushSubscriptionData);
      }
      return resData;
    }
  }

  manageSubscription(currentSubscription, isSameSubscription, subscription, pushSubscriptionData);

  // else, leave as it is because current subscription is active
  console.log('Current Subscription is Active');
};

// ignoring this rule for now until futher discussion on an alternative approach to fix it
window.addEventListener('appinstalled', event => {
  console.log('App Installed Event!', event);
  // add dataLayer push on app install
  window.dataLayer.push({
    event: 'pwa_installed',
  });
});

// ignoring this rule for now until futher discussion on an alternative approach to fix it
// https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
window.addEventListener('beforeinstallprompt', event => {
  // event.userChoice returns 'accepted' or dismissed'
  event.userChoice
    .then(choice => {
      window.dataLayer.push({
        event: 'pwa_prompt',
        userAction: choice.outcome,
        isIos: getBrowserDetails().isIos,
        type: store.getState().profile && store.getState().profile.data.account_type,
      });
    })
    .catch(e => {
      console.log('error', e);
    });
});

export const requestNotifPermission = (): any => {
  requestNotificationPermission()
    .then(() => {
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PWA_PUBLIC_KEY),
      };
      return registration.pushManager.subscribe(options);
    })
    .then(
      pushSubscription => {
        const pushSub = JSON.stringify(pushSubscription);
        sendToServer(pushSub);
      },
      err => {
        console.log('Service Worker registration failed', err);
      }
    );
};

const generateCallback = (config, prop, registration) => {
  if (config && config[prop]) {
    config[prop](registration);
  }
};

function registerValidSW(swUrl, config) {
  const wypAutomationTest = getLocalItem('wyp_automation_testing');
  const isAutomation = wypAutomationTest && wypAutomationTest === 'true';
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      global.registration = registration;

      if (!isAutomation) {
        requestNotifPermission();
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See http://bit.ly/CRA-PWA.'
              );

              // Execute callback
              generateCallback(config, 'onUpdate', registration);
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              generateCallback(config, 'onSuccess', registration);
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

function absorbEvent_(event) {
  const e = event || window.event;
  e.preventDefault && e.preventDefault();
  e.stopPropagation && e.stopPropagation();
  e.cancelBubble = true;
  e.returnValue = false;
  return false;
}

function preventLongPressMenu(node) {
  node.ontouchstart = absorbEvent_;
  node.ontouchmove = absorbEvent_;
  node.ontouchend = absorbEvent_;
  node.ontouchcancel = absorbEvent_;
}

// if is standalone android OR safari
if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
  // prevent long press with all <a> tag
  preventLongPressMenu(document.getElementsByTagName('a'));
  // disable context menu
  window.oncontextmenu = function(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };
  // https://stackoverflow.com/questions/51735869/check-if-user-has-already-installed-pwa-to-homescreen-on-chrome
  console.log('detects if user is already in standalone');

  // Implements this styles once detected in standalone
  document.getElementsByTagName('body')[0].style =
    '-webkit-touch-callout:none;' +
    '-webkit-user-select:none;' +
    '-khtml-user-select:none;' +
    '-moz-user-select:none;' +
    '-ms-user-select:none;' +
    'user-select:none;';
}
