import React, { ReactElement } from 'react';

const IconPencilFillCircle = ({
  iconColor: iconColor,
  fillColor,
  customStyle,
  ...rest
}: {
  iconColor: string;
  fillColor: string;
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 24, height: 24, ...customStyle }}
      {...rest}
    >
      <circle opacity="0.65" cx="12" cy="12" r="12" fill={fillColor} />
      <path
        d="M16.4122 10.1798L13.794 7.58859L14.6565 6.72486C14.8926 6.48837 15.1828 6.37012 15.527 6.37012C15.8707 6.37012 16.1607 6.48837 16.3968 6.72486L17.2593 7.58859C17.4955 7.82508 17.6187 8.11052 17.629 8.44491C17.6392 8.77888 17.5263 9.06411 17.2901 9.30061L16.4122 10.1798ZM6.98643 17.6294C6.81188 17.6294 6.66566 17.5702 6.54779 17.4517C6.4295 17.3337 6.37036 17.1872 6.37036 17.0124V15.2696C6.37036 15.1873 6.38576 15.1077 6.41657 15.0308C6.44737 14.9535 6.49357 14.884 6.55518 14.8223L12.9007 8.46773L15.519 11.0898L9.17347 17.4443C9.11186 17.506 9.04265 17.5523 8.96585 17.5831C8.88864 17.614 8.80896 17.6294 8.72682 17.6294H6.98643Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconPencilFillCircle;
