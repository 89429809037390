/**
 * @Author: zachalam
 * @Date:   2017-01-19T14:12:03-08:00
 * @Last modified by:   Daniel
 * @Last modified time: 2017-02-14T10:10:01-08:00
 */

import React from 'react';
import Container from '../blocks/Container';
import BlankSlate from '../blocks/BlankSlate';

const NotFound = (): JSX.Element  => {
  return (
    <Container>
      <BlankSlate title="Uh Oh!" message="Sorry, we couldn't find the page you're looking for." />
    </Container>
  );
};

export default NotFound;
