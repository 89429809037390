import styled from '@emotion/styled';
import { isDarkMode } from '../../../../utils/helpers';
import colors, { primaryBGDark, white } from '../../../../theme/colors';

export const TutorialHeader = styled('h1')({
  fontSize: 26,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  letterSpacing: -0.52,
  color: isDarkMode() ? '#CFCFCF' : white,
});

export const TutorialSectionWrapper = styled('div')({
  marginBottom: 30,
  color: isDarkMode() ? '#CFCFCF' : white,
});

export const TutorialSectionSubheader = styled('p')({
  color: isDarkMode() ? '#AFB0B1' : white,
});

export const TutorialComponentContainer = styled('div')({
  flexShrink: 0,
  background: isDarkMode() ? primaryBGDark : colors['light'].blue.secondary,
  opacity: 0.96,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  color: white,
  padding: '48px 0px 140px 0px',
  zIndex: 1,
  overflow: 'hidden',
  transition: 'transform 0.5s ease',
});

export const IconDescription = styled('p')({
  letterSpacing: '-0.32px',
  fontSize: 16,
  fontWeight: 700,
});
