import React, { FC } from 'react';
import Button from '../../atoms/buttons/Button';
import Container from '../blocks/Container';
import Headline from '../common/Headline';
import Panel from '../../atoms/panels/Panel';
import Text from '../common/Text';
import HeaderOut from '../../atoms/HeaderOut';
import { Link } from 'react-router-dom';
import NeedAccountMessage from '../../atoms/NeedAccountMessage';

const Unsubscribe: FC = () => {
  return (
    <Container width="thin">
      <HeaderOut />
      <Panel padding="large">
        <Headline spaceAfter>We&apos;ve removed you from our database.</Headline>
        <Text>We apologize for the inconvenience.</Text>
        <div style={{ paddingTop: '100px' }}>
          <Link to="/login">
            <Button buttonType="primary" fit>
              Got it!
            </Button>
          </Link>
        </div>
      </Panel>
      <NeedAccountMessage />
    </Container>
  );
};

export default Unsubscribe;
