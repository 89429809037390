/**
 * @Author: zachalam
 * @Date:   2017-01-19T17:12:00-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T17:12:59-08:00
 */

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { getAccountByUserName, refreshProfile, updateShowTutorialOffer } from '../actions/profileActions';
import {
  refreshOffers,
  sendMessage,
  ignoreOfferFromList,
  removeAllOffers,
  counterOffer,
  createNewOffer,
  acceptOffer,
  ignoreOffer,
  sendSuggestion,
  updateOffer,
} from '../actions/offerActions';
import OffersV2 from '../components/pages/Offers/OffersV2';
import { withPusher } from '../contexts/PusherContext';
import { PUSHER } from '../config/constants';
import { clearErrors } from '../actions/commonActions';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import { displayWarnMessage } from '../actions/notificationActions';
import { OffersProvider } from '../contexts/OffersContext';
import { PER_PAGE, ORDER_BY } from '../constants/offers';
import { unlockOutsideMessage } from '../actions/mailActions';
import { useParams } from 'react-router-dom';

const OffersContainer = props => {
  const params = useParams();
  const { refresh, meta, offers } = props;
  const { offerType = 'new' } = params;
  const [lastPage, setLastPage] = useState(offerType);
  const [isRealtimeFetching, setIsRealtimeFetching] = useState(false);
  // determine which fav list to get (and pagination).
  const offerList = offers[offerType];
  const [updating, setUpdating] = useState(false);
  const isFirstCall = meta[`offer_${offerType}_current_page`] <= 1;
  const memoisedCurrentPage = useMemo(() => meta[`offer_${offerType}_current_page`], [meta]);
  const memoisedTotalPage = useMemo(() => meta[`offer_${offerType}_total_pages`], [meta]);

  const moreExists = useMemo(() => Boolean(memoisedCurrentPage < memoisedTotalPage) && offerList.length > 0, [
    memoisedCurrentPage,
    memoisedTotalPage,
    offerList,
  ]);

  useEffect(() => {
    if (!isFirstCall && memoisedCurrentPage > 1 && offers[offerType].length < 1) {
      refresh(offerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, offerType, offers[offerType], memoisedCurrentPage]);

  useEffect(() => {
    if (isFirstCall) {
      refresh(offerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, offerType]);

  useEffect(() => {
    const { bindListener, refreshGlobalNotification } = props;

    bindListener(listen);

    return () => {
      // refresh Global Notification on page unload
      refreshGlobalNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUpdating(true);
    const next = offerType;
    if (next !== lastPage) {
      setLastPage(next);
    }

    return () => {
      props.stopListening(listen);
    };
  }, [offerType]);

  const listen = () => {
    const { onRealtimeEvent, refresh } = props;
    const { EVENTS } = PUSHER;

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      if (data.type === 'offers' && (data.action === 'receive' || data.action === 'suggest')) {
        setIsRealtimeFetching(true);
        refresh(offerType, ORDER_BY, true, PER_PAGE, true);
      }
    });
  };

  return (
    <OffersProvider>
      <OffersV2
        {...props}
        offers={offerList}
        moreExists={moreExists}
        offerType={offerType}
        updating={updating}
        setUpdating={setUpdating}
        isFirstCall={isFirstCall}
        isRealtimeFetching={isRealtimeFetching}
        setIsRealtimeFetching={setIsRealtimeFetching}
      />
    </OffersProvider>
  );
};

const mapStateTopProps = state => {
  return {
    auth: state.auth,
    meta: state.meta,
    profile: state.profile,
    offers: state.offers,
    isFetching: state.offers.isFetching,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refresh(route, orderType = ORDER_BY, firstCall = true, perPage = PER_PAGE, isRealtime = false) {
      dispatch(refreshProfile());
      dispatch(refreshOffers(route, orderType, firstCall, perPage, isRealtime));
    },
    removeAllOffers() {
      dispatch(removeAllOffers());
    },
    acceptOffer: (username, message, source) => {
      return dispatch(acceptOffer(username, message, false, null, null, true, source, ownProps.navigate));
    },
    loadMore(route, orderType = ORDER_BY, firstCall = false, perPage) {
      // show more
      dispatch(refreshOffers(route, orderType, firstCall, perPage));
    },
    ignoreOfferFromList(offerObject = {}) {
      // ignore offer from list
      dispatch(
        ignoreOfferFromList(offerObject, ownProps.location.pathname, ownProps.params.offerType, ownProps.navigate)
      );
    },
    sendMessage(username) {
      dispatch(sendMessage(username, ownProps.navigate));
    },
    checkProfile: username => {
      return dispatch(getAccountByUserName(username));
    },
    getNextOfferQueue: () => {
      dispatch(refreshOffers('highlights', ORDER_BY, true, 1));
    },
    updateOfferTutorialState: () => {
      dispatch(updateShowTutorialOffer(false));
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
    counterOffer: (formData, favKey, section) => {
      return dispatch(counterOffer(formData, false, ownProps.navigate, favKey, section, true));
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    createNewOffer: formData => {
      return dispatch(createNewOffer(formData, false, null, null, true));
    },
    ignoreOffer: hashId => {
      dispatch(ignoreOffer(hashId, false, false, null, null));
    },
    sendSuggestion: (username, favKey, section, showToastMessage) => {
      return dispatch(sendSuggestion(username, true, favKey, section, showToastMessage));
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    updateOffer: formData => {
      return dispatch(updateOffer(formData));
    },
    triggerUnlockMessage: (hashId, triggerToBuy, callback = () => undefined) => {
      dispatch(unlockOutsideMessage(hashId, triggerToBuy, callback));
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(withPusher(OffersContainer));
