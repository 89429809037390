import React, { useState, useEffect, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { borders, dimensions } from '../style';
import TextInput from './TextInput';
import Loading from '../atoms/Loading';
import { usePrevious } from '../utils/customHooks';
import RoomIcon from '@material-ui/icons/Room';
import { getPostalLookup } from '../sdk';
import { allowedPostalLookupCountry, enabledPostalLookup } from '../config/Master';

const InputWrapper = styled('div')({
  position: 'relative',
  paddingBottom: 0,
});

const PostalAddressContainer = styled('div')({
  position: 'absolute',
  top: '100%',
  backgroundColor: '#ffffff',
  border: `${borders.DARKER}`,
  borderRadius: '2px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  width: '98.5%',
  marginTop: '-24px',
  zIndex: '1000',
  '&:after': {
    content: '""',
    padding: '1px 1px 1px 0',
    height: '16px',
    textAlign: 'right',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120px 14px',
  },
  [dimensions.SCREEN_MAX_SM]: {
    width: '98%',
  },
});

const PostalAddressItem = styled('div')({
  fontSize: 13,
  padding: '0 4px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: '30px',
  textAlign: 'left',
  borderTop: '1px solid #e6e6e6',
  color: '#555555',
  cursor: 'default',
  backgroundColor: `${props => (props.isActive ? '#f5f5f5' : '#ffffff')}`,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '&:active': {
    backgroundColor: '#f5f5f5',
  },
});

const PostalAddressItemWrap = styled('div')({
  paddingLeft: 15,
  position: 'relative',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    cursor: 'pointer',
  },
});

const LocationIconContainer = styled('div')({
  position: 'absolute',
  right: 8,
  cursor: 'pointer',
  top: 3,
});

const setEmptyPostalAddress = (hasResponseData, setPostalAddresses) => {
  if (!hasResponseData) {
    setPostalAddresses([]);
  }
};

const setMyAddress = (responseData, selectAddress, setFetching) => {
  if (responseData.length === 1) {
    selectAddress(responseData[0]);
    setFetching(false);
  }
};

interface PostalLookupProps {
  inputProps: any;
  onSelect: any;
  postalAddressItem: any;
  country: any;
}

const PostalLookup = ({ inputProps, onSelect, postalAddressItem, country }: PostalLookupProps): ReactElement => {
  const prevInputProps = usePrevious(inputProps);
  const [hasSelected, setHasSelected] = useState(false);
  const [postalAddresses, setPostalAddresses] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [fetching, setFetching] = useState(false);

  const getMinLength = () => {
    if (country === 'GB') {
      return 2;
    }

    if (['GB', 'TW', 'IS', 'MG'].includes(country)) {
      return 3;
    }

    // check country has 6 or more digits
    if (['UK', 'CA', 'JP', 'ID', 'SG'].includes(country)) {
      return 6;
    }

    // check country has 5 or more digits
    if (['DE', 'BR', 'MX', 'IT', 'SP'].includes(country)) {
      return 5;
    }

    return 4;
  };

  const fetchPostalAddresses = value => {
    if (hasSelected) return;

    if (value.length < 4 && postalAddresses.length > 0) {
      setTimeout(() => {
        setPostalAddresses([]);
      }, 500);
    }

    if (value === prevInputProps.value) {
      return;
    }

    if (shouldRequestPostalLookup(value) && isAllowedCountry(country)) {
      setFetching(true);
      setTimeout(() => {
        getPostalLookup(value, country)
          .then(response => {
            let hasResponseData = false;
            const responseData = response.data && response.data.data;
            if (responseData) {
              hasResponseData = true;

              setMyAddress(responseData, selectAddress, setFetching);

              setPostalAddresses(responseData);
            }

            setEmptyPostalAddress(hasResponseData, setPostalAddresses);

            setFetching(false);
          })
          .catch(() => {
            setPostalAddresses([]);
            setFetching(false);
          });
      }, 500);
    }
  };

  const shouldRequestPostalLookup = value => {
    if (country === 'US') {
      return value.length === 5;
    }

    return value.length >= getMinLength() && value.length <= 8;
  };

  const isAllowedCountry = countryCode => {
    const allowedCountries = allowedPostalLookupCountry || 'US';

    if (allowedCountries === 'all') return true;

    const allowedCountriesArray = allowedCountries.split(',');

    return allowedCountriesArray.some(item => item === countryCode);
  };

  useEffect(() => {
    setStatusMessage('');

    if (postalCode) {
      fetchPostalAddresses(postalCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  const handleInputChange = event => {
    setHasSelected(false);
    inputProps.onChange(event.target.value);
    setPostalCode(event.target.value);

    if (!event.target.value && enabledPostalLookup) {
      setPostalAddresses([]);
    }
  };

  const getInputProps = () => {
    return {
      ...inputProps,
      autoComplete: 'off',
      onChange: event => {
        handleInputChange(event);
      },
      onBlur: event => {
        handleInputChange(event);
      },
    };
  };

  const setActiveItemAtIndex = index => {
    setPostalAddresses(
      postalAddresses.map((item, idx) => {
        if (idx === index) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );
  };

  const selectAddress = address => {
    setHasSelected(true);
    onSelect(address);
    setPostalAddresses([]);
  };

  const getFormattedAddress = address => {
    let formatedAddress = `${address.zip_code}, ${address.place}, ${address.state_code}`;

    if (address.country) {
      formatedAddress = `${formatedAddress}, ${address.country}`;
    }

    return address.formatted_address || formatedAddress;
  };

  const sanitisedInputProps = getInputProps();
  // Pull status off inputProps instead of typing inputProps.status everytime
  const { status, ...rest } = sanitisedInputProps;
  const autoFillErrorMessage = sanitisedInputProps.statusMessage;
  const errorMessage = statusMessage || autoFillErrorMessage;

  return (
    <InputWrapper>
      <TextInput
        {...rest}
        status={(autoFillErrorMessage && 'error') || status}
        statusMessage={errorMessage || null}
        maxLength={16}
        customStyle={{ marginRight: 4 }}
      />
      <LocationIconContainer>{fetching ? <Loading width={32} color="#CACACA" /> : null}</LocationIconContainer>
      {postalAddresses.length > 1 && (
        <PostalAddressContainer data-test-id="postal-lookup-predictions">
          {postalAddresses.map(address => (
            <PostalAddressItem
              data-test-id="postal-lookup-predictions-item"
              isActive={address.active}
              key={address.id}
              onMouseOver={() => setActiveItemAtIndex(address.id)}
              onMouseDown={() => selectAddress(address)}
              //Add Touch Events to work on mobile
              onTouchStart={() => setActiveItemAtIndex(address.id)}
              onTouchEnd={() => selectAddress(address)}
            >
              {postalAddressItem({ address: getFormattedAddress(address) })}
            </PostalAddressItem>
          ))}
        </PostalAddressContainer>
      )}
    </InputWrapper>
  );
};

PostalLookup.propTypes = {
  inputProps: (props, propName) => {
    const inputProps = props[propName];

    if (!('value' in inputProps)) {
      throw new Error(`'inputProps' must have 'value'.`);
    }

    if (!('onChange' in inputProps)) {
      throw new Error(`'inputProps' must have 'onChange'`);
    }
  },
  onSelect: PropTypes.func,
  postalAddressItem: PropTypes.func,
  debounce: PropTypes.number, // I nmilliseconds (ms)
};

PostalLookup.defaultProps = {
  postalAddressItem: ({ address }) => (
    <PostalAddressItemWrap>
      <RoomIcon fontSize="small" style={{ height: '14px', position: 'absolute', top: 8, left: 0 }} />
      {address}
    </PostalAddressItemWrap>
  ),
  debounce: 500,
  highlightFirstSuggestion: true,
};

export default PostalLookup;
