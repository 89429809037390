import React, { useEffect, useMemo, ReactElement } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import '../../../style/customSWui.css';

const sizerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
  height: 0,
  overflow: 'scroll',
  whiteSpace: 'pre',
};

const StyledInput = styled.input`
  ::placeholder {
    color: #3e79a5;
    opacity: 0.5;
  }
`;

interface PriceInputProps {
  inputRef: any;
  onAutosize: any;
  minWidth: any;
  hasErrors: any;
  className: any;
  inputProps: any;
  placeholder: any;
  onChange: any;
  validateTextFn: any;
  style: any;
  value: any;
  overideInputStyle?: React.CSSProperties;
}

export const adjustStylePerDigit = (number: number | string): any => {
  const marginLeft = 5;
  let paddingLeft = 10;

  if (number !== null && number !== undefined) {
    const numString = number.toString();
    switch (numString.length) {
      case 3:
        paddingLeft = 15;
        break;
      case 2:
        paddingLeft = 25;
        break;
      case 1:
        paddingLeft = 65;
        break;
      default:
        paddingLeft = 45;
        break;
    }
  }

  return {
    marginLeft,
    paddingLeft,
  };
};

const PriceInput = ({
  inputRef,
  onAutosize,
  minWidth,
  hasErrors,
  className,
  inputProps,
  placeholder,
  onChange,
  validateTextFn,
  style,
  value,
  overideInputStyle,
  ...rest
}: PriceInputProps): ReactElement => {
  const inputWidth = useMemo(() => minWidth, [minWidth]);

  useEffect(() => {
    if (typeof onAutosize === 'function') {
      onAutosize(inputWidth);
    }
  }, [inputWidth, onAutosize]);

  const highlightText = e => {
    e.target.select();
  };

  const wrapperStyle = style || {};
  if (!wrapperStyle.display) wrapperStyle.display = 'inline-block';

  // Define the Styles for the input ( Some of it is received from PROPS )
  const inputStyle = Object.assign({}, inputProps.style);
  inputStyle.width = inputWidth + 'px';
  inputStyle.boxSizing = 'content-box';
  inputStyle.background = 'transparent';
  inputStyle.color = hasErrors && value ? inputStyle?.errorColor || 'rgb(204, 84, 84)' : inputStyle.color;
  inputStyle.textAlign = inputProps.style?.textAlign ?? 'center';
  inputStyle.padding = '0px';
  inputStyle.fontFamily = 'system-ui, -apple-system, Arial, sans-serif';

  // Define the props to pass to the input.
  const InputProps = {
    className: inputProps.className,
    style: {
      ...inputStyle,
      ...overideInputStyle,
    },
    onChange,
    onKeyPress: validateTextFn,
    value,
    placeholder,
    type: 'text',
    pattern: '[0-9]*',
    inputMode: 'numeric',
    noValidate: true,
    onClick: highlightText,
  };

  return (
    <div className={className} style={wrapperStyle} {...rest}>
      <StyledInput ref={inputRef} {...InputProps} />
      <div style={sizerStyle}>{value}</div>
      {placeholder ? <div style={sizerStyle}>{placeholder}</div> : null}
    </div>
  );
};

PriceInput.propTypes = {
  hasErrors: PropTypes.bool, // If there are any validation errors (Can be used for styling)
  className: PropTypes.string, // className for the outer element
  defaultValue: PropTypes.any, // default field value
  inputProps: PropTypes.shape({
    className: PropTypes.string, // className for the input element
    style: PropTypes.object, // css styles for the input element
  }),
  minWidth: PropTypes.oneOfType([
    // minimum width for input element
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func, // onChange handler: function(newValue) {}
  style: PropTypes.object, // css styles for the outer element
  value: PropTypes.any, // field value
};

PriceInput.defaultProps = {
  minWidth: 140,
};

export default PriceInput;
