import React, { ReactElement } from 'react';

import { ArrowBack } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { backgroundTheme, buttonTheme } from '../../style';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { black } from '../../theme/colors';

const ArrowContainer = styled('div')({
  backgroundColor: theme === 'dark' ? 'rgba(4, 26, 42, 0.75)' : black,
  opacity: theme === 'dark' ? 1 : 0.65,
  borderRadius: '50%',
  height: 24,
  width: 24,
  padding: 5,
});

interface ArrowLeftProps {
  color?: any;
  cssStyle?: any;
  profileBack?: boolean;
  messageBack?: boolean;
}

const ArrowLeft = ({
  color = backgroundTheme.iconFill,
  cssStyle = {},
  profileBack = false,
  messageBack = false,
}: ArrowLeftProps): ReactElement => {
  switch (true) {
    case profileBack:
      return (
        <ArrowContainer>
          <SvgIcon
            component={ArrowBack}
            viewBox={'3 3 18 18'}
            style={{ fontSize: 24, color: buttonTheme.GreyAndWhite, ...cssStyle }}
          />
        </ArrowContainer>
      );
    case messageBack:
      return (
        <SvgIcon
          component={ArrowBack}
          viewBox={'4 3 18 18'}
          style={{
            fontSize: 22,
            color: color,
            marginRight: 10,
            cursor: 'pointer',
            display: 'none !important',
            borderRadius: '50%',
            padding: 6,
            backgroundColor: '#f5f7fa',
            boxShadow: '0px 1px 2px #aaa',
            marginTop: 10,
            position: 'absolute',
            ...cssStyle,
          }}
        />
      );
    default:
      return (
        <SvgIcon component={ArrowBack} viewBox={'4 3 18 18'} style={{ fontSize: 26, color: color, ...cssStyle }} />
      );
  }
};

export default ArrowLeft;
