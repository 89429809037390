import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { backgroundTheme, dimensions, patternModal, textColor, borders } from '../../../style';
import { css } from 'glamor';
import ModalPattern from '../ModalPattern';
import { SvgIcon } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import styled from '@emotion/styled';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import { getBrowserDetails } from '../../../utils/helpers';

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1000,
});

const closeButton = {
  fontSize: 20,
  color: '#8F8F8F',
};

const ModalStyles = {
  background: patternModal.bgGradient,
  position: 'relative',
  boxSizing: 'border-box',
  border: patternModal.borderStyle,
  outline: 'none',
  maxHeight: '80vh',
  overflow: 'auto',
  ...textColor.DARK,
  ':focus': {
    outline: 'none',
  },

  [dimensions.SCREEN_MAX_SM]: {
    height: '100%',
  },

  [dimensions.SCREEN_MIN_SM]: {
    borderRadius: 10,
    position: 'relative',
    top: 40,
    maxWidth: 600,
    margin: '32px auto',
  },
};

const ModalWithoutPatternStyles = {
  ...ModalStyles,
  background: backgroundTheme.block,
  border: borders.DEFAULT,
  maxHeight: '80vh',
  overflow: 'auto',
};

const OverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
  backgroundColor: 'rgba(0,0,0,0.65)',
  overflow: 'auto',
  [dimensions.SCREEN_MAX_SM]: {
    backgroundColor: '#fff',
  },
};

ReactModal.setAppElement('body');

export interface ModalWithPatternProps {
  dataTestID: string;
  isModalOpen: boolean;
  modalContentLabel: string;
  closeOnOverlayClick: boolean;
  closeRequest(): void;
  closeBtnClassName: string;
  closeBtnDataTestId: string;
  onCloseClick(): void;
  children: React.ReactNode;
  customOverlayStyle?: Record<string, unknown>;
  customContentStyle?: Record<string, unknown>;
  hideCloseBtn?: boolean;
  customRef?: React.ReactNode;
  withoutBgPattern?: boolean;
  modalPatternPadding?: number;
  modalRef?: any;
  enableTouchMove?: boolean;
  customContentBodyStyle?: React.CSSProperties;
  customCloseIconStyle?: React.CSSProperties;
  customCloseBtnStyle?: React.CSSProperties;
}

const ModalWithPattern = ({
  dataTestID,
  isModalOpen,
  modalContentLabel,
  closeOnOverlayClick = true,
  closeRequest,
  closeBtnClassName,
  closeBtnDataTestId,
  onCloseClick,
  customOverlayStyle = {},
  customContentStyle = {},
  hideCloseBtn = false,
  children,
  customRef,
  withoutBgPattern = false,
  enableTouchMove = true,
  customContentBodyStyle,
  customCloseIconStyle = {},
  customCloseBtnStyle = {},
}: ModalWithPatternProps): JSX.Element => {
  useDisableBodyScroll(isModalOpen, enableTouchMove);

  return (
    <ReactModal
      ref={customRef}
      className={`${css(withoutBgPattern ? ModalWithoutPatternStyles : ModalStyles)}`}
      overlayClassName={`${css(OverlayStyles)}`}
      data-test-id={dataTestID}
      isOpen={isModalOpen}
      contentLabel={modalContentLabel}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={closeRequest}
      style={{
        overlay: customOverlayStyle,
        content: customContentStyle,
      }}
    >
      <ModalPattern padding={getBrowserDetails().isMobile ? 10 : 22} customStyle={customContentBodyStyle}>
        {!hideCloseBtn && (
          <CloseBtn
            className={closeBtnClassName}
            data-test-id={closeBtnDataTestId}
            onClick={onCloseClick}
            style={customCloseBtnStyle}
          >
            <SvgIcon component={CancelRounded} style={{ ...closeButton, ...customCloseIconStyle }} />
          </CloseBtn>
        )}
        {children}
      </ModalPattern>
    </ReactModal>
  );
};

ModalWithPattern.propTypes = {
  styles: PropTypes.object,
  contentLabel: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelBtnText: PropTypes.string.isRequired,
  confirmationBtnText: PropTypes.string.isRequired,
  onCloseHandler: PropTypes.func,
  btnAlignment: PropTypes.string,
  className: PropTypes.string,
  hideCancelBtn: PropTypes.bool,
  hideConfirmBtn: PropTypes.bool,
  hideModalCloseBtn: PropTypes.bool,
  confirmationHeaderText: PropTypes.string,
  isConfirmBtnDisabled: PropTypes.bool,
};

ModalWithPattern.defaultProps = {
  confirmationHeaderText: '',
  confirmationText: 'Are you sure with this?',
  confirmationBtnText: 'Yes',
  cancelBtnText: 'No',
  shouldOpen: false,
  btnAlignment: 'right',
  styles: {
    overlay: { zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.65)', overflowY: 'auto' },
    content: {
      background: backgroundTheme.block,
      bottom: 'initial',
      top: 82,
      maxWidth: '520px',
      transform: 'scale(1)',
      margin: '0 auto',
      outline: 'none',
      ':focus': { outline: 'none' },
    },
  },
  shouldCloseOnOverlayClick: false,
  hideCancelBtn: false,
  hideConfirmBtn: false,
  hideModalCloseBtn: true,
  isConfirmBtnDisabled: false,
  contentLabel: '',
};

export default ModalWithPattern;
