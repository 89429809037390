import React from 'react';
import styled from '@emotion/styled';
import { getBrowserDetails } from '../../../../../utils/helpers';
import { textColor } from '../../../../../style';
import { handlePaymentVysionEvent } from '../../../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../../../utils/vysion';
import { PaymentClickVysionEventsEnum } from '../../../../../utils/vysion/payment/click';

const isMobile = getBrowserDetails().isMobile;

const CreditThresholdContainer = styled('div')({
  position: 'relative',
  paddingBottom: 50,
});
const RangeStepSlider = styled('div')({
  position: 'absolute',
  top: 0,
  zIndex: 4,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
});

const RangeStepSliderTick = styled('div')(
  {
    background: '#D9D9D9',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    width: '13px',
    height: '13px',
    position: 'relative',
    cursor: 'pointer',
  },
  ({ selected }) => selected && { background: '#2B8FD7 !important' }
);

const RangeStepSliderTickText = styled('span')(
  {
    paddingTop: '1.6rem',
    ...textColor.DARK,
    fontWeight: 400,
    fontSize: 14,
  },
  ({ selected }) => selected && { fontWeight: 700, color: '#2B8FD7 !important' }
);

const RangeStepSliderInput = styled('div')({
  background: '#D9D9D9',
  width: 'calc(100% - 1px)',
  height: 6,
  borderRadius: 2,
  border: 0,
  margin: 0,
  padding: 0,
  position: 'absolute',
  top: isMobile ? '0.3rem' : '0.3rem',
  zIndex: 3,
  WebkitAppearance: 'none',
  MozAppearance: 'none',
});

interface PaymentSettingsProps {
  threshold: any;
  setThreshold: any;
}

const CreditThreshold = ({ threshold, setThreshold }: PaymentSettingsProps): any => {
  const handleThresholdChange = threshold => {
    setThreshold(threshold);

    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_THRESHOLD,
      newEventName: `accountMenu-paymentSettings-creditThreshold-${threshold}`,
      extraInfo: `${threshold}`,
    });
  };

  return (
    <CreditThresholdContainer>
      <RangeStepSliderInput type={'range'} min={1} max={100} step={2} />
      <RangeStepSlider>
        <RangeStepSliderTick onClick={() => handleThresholdChange(10)} selected={threshold === 10}>
          <RangeStepSliderTickText selected={threshold === 10}>10</RangeStepSliderTickText>
        </RangeStepSliderTick>
        <RangeStepSliderTick onClick={() => handleThresholdChange(15)} selected={threshold === 15}>
          <RangeStepSliderTickText selected={threshold === 15}>15</RangeStepSliderTickText>
        </RangeStepSliderTick>
        <RangeStepSliderTick onClick={() => handleThresholdChange(25)} selected={threshold === 25}>
          <RangeStepSliderTickText selected={threshold === 25}>25</RangeStepSliderTickText>
        </RangeStepSliderTick>
        <RangeStepSliderTick onClick={() => handleThresholdChange(50)} selected={threshold === 50}>
          <RangeStepSliderTickText selected={threshold === 50}>50</RangeStepSliderTickText>
        </RangeStepSliderTick>
      </RangeStepSlider>
    </CreditThresholdContainer>
  );
};

export default CreditThreshold;
