import React, { ReactElement } from 'react';
import StepDoneIcon from '../../../atoms/icons/StepDoneIcon';
import StepTwoIcon from '../../../atoms/icons/StepTwoIcon';
import { BodyWrapper, StepLabel } from '../styledElements';
import { DashlineSeparator } from '../../join/styledElements';
import JoinActionButton from '../../join/JoinActionButton';
import JoinSkipButton from '../../join/JoinSkipButton';
import { useApp } from '../../../contexts/AppContext';
interface IEmailVerifiedIncompleteProfileStepProps {
  setSkip: () => any;
  handleMixpanel: () => any;
}

const EmailVerifiedIncompleteProfileStep = ({
  setSkip,
  handleMixpanel,
}: IEmailVerifiedIncompleteProfileStepProps): ReactElement => {
  const { setProfileCompletionStep } = useApp();
  return (
    <BodyWrapper>
      <div style={{ display: 'flex' }}>
        <StepDoneIcon />
        <StepLabel cross>Verify your email address</StepLabel>
      </div>
      <DashlineSeparator />
      <div style={{ display: 'flex' }}>
        <StepTwoIcon />
        <StepLabel>All profile info filled out</StepLabel>
      </div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <JoinActionButton
          buttonType="primary"
          label="Finish My Profile"
          onClick={() => {
            setProfileCompletionStep(2);
            handleMixpanel();
          }}
          customStyle={{ marginBottom: 25 }}
        />
        <JoinSkipButton label="Skip for now" onClick={setSkip} />
      </div>
    </BodyWrapper>
  );
};

export default EmailVerifiedIncompleteProfileStep;
