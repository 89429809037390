import React, { ReactElement } from 'react';

interface FDIconProps {
  customStyle?: React.CSSProperties;
}

const FDIcon = ({ customStyle }: FDIconProps): ReactElement => {
  return (
    <svg width="50" height="42" viewBox="0 0 80 90" xmlns="http://www.w3.org/2000/svg" style={customStyle}>
      <g>
        <path
          fill="#d21b28"
          d="M63.53,0A25.41,25.41,0,0,0,44.2,8.7,25.43,25.43,0,0,0,24.86,0C11.13,0,0,10.47,0,23.38c0,4.4,1.86,9.29,4.81,14.28l.39-.15h0a115.32,115.32,0,0,1,33.71-6.63h.15l2.17-.06h2.19l.84,0,1.2,0,1.07,0,2.65.14c-.43-.15-1.29-.43-2.51-.74l-3-3.08A1.58,1.58,0,0,1,43.17,26a1.64,1.64,0,0,1,.49-1.16l3.6-3.55a1.66,1.66,0,0,1,1.16-.47h0a1.64,1.64,0,0,1,1.16.49L63.81,35.81,49.35,50a1.65,1.65,0,0,1-1.15.47h0A1.66,1.66,0,0,1,47,50L43.48,46.4a1.64,1.64,0,0,1,0-2.32L46,41.59a18.21,18.21,0,0,1,5-.79v-.06c-1.06-.11-2.34-.21-3.81-.28h0c-.49,0-1,0-1.54-.06h-.39l-1.32,0H41.17l-1.32,0-.74,0-1.75.09-.8.06-1.38.1-1,.08-1.42.15-1.21.13-1.67.21-1.05.15-.56.08h0a108.05,108.05,0,0,0-17.88,4.28h0c-.94.31-1.9.63-2.86,1s-2,.72-3,1.11L.27,49.58v.06s1.94.54,4.49,1a66,66,0,0,0,10.82,1.19A227.69,227.69,0,0,0,44.2,77.94S88.4,44.88,88.4,23.38C88.4,10.47,77.27,0,63.53,0Z"
        />
      </g>
    </svg>
  );
};

export default FDIcon;
