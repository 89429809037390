import styled from '@emotion/styled';
import React, { SetStateAction, Dispatch } from 'react';
import { PhotoFileType } from '../../../modules/gallery/constants';
// import { displayFailMessage } from '../../../actions/notificationActions';
import JoinPhotoDropzone from './JoinPhotoDropzone';

const StyledPhotoItem = styled('div')(
  {
    boxSizing: 'border-box',
    width: '100%',
    padding: 3,
  },
  ({ isRelative }) => (isRelative ? { position: 'relative' } : null)
);

const StyledMaxLengthContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const StyledSpan1 = styled('span')({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.32,
  color: '#3E79A5',
  marginBottom: 14,
});

const StyledSpan2 = styled('span')({
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: -0.32,
  color: '#3E79A5',
  width: 61,
});

interface IJoinPhotoUploadItemProps {
  customStyledDropzoneStyles: React.CSSProperties;
  customAddIconStyle?: React.CSSProperties;
  photoItemStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  photoUrl?: string;
  key?: number | null;
  onCancelUpload?: () => void;
  isUploading?: boolean;
  setFileList: Dispatch<SetStateAction<any>>;
  onRemove: (photo: PhotoFileType) => void;
  onEdit: (photo: PhotoFileType) => void;
  isPrivate?: boolean;
  isLoading: boolean;
  isSmallThumbnail: boolean;
  isMainProfile?: boolean;
  isMaxLength?: boolean;
  countTotalPhotos: number;
}
const JoinPhotoUploadItem: React.FC<IJoinPhotoUploadItemProps> = ({
  customStyledDropzoneStyles,
  photoUrl,
  customAddIconStyle,
  key = null,
  onCancelUpload,
  isUploading,
  onRemove,
  onEdit,
  setFileList,
  containerStyle,
  isPrivate = false,
  isLoading,
  isSmallThumbnail,
  isMainProfile = false,
  isMaxLength = false,
  photoItemStyle,
  countTotalPhotos,
}) => {
  return (
    <StyledPhotoItem style={containerStyle}>
      <JoinPhotoDropzone
        refKey={key}
        hasPublicPhotos={false}
        refreshAndClearErrors={() => null}
        isUploading={isUploading}
        photosLength={1}
        customStyledDropzoneStyles={customStyledDropzoneStyles}
        shouldPreviewMedia={true}
        mediaUrl={photoUrl}
        customAddIconStyle={customAddIconStyle}
        onCancelUpload={onCancelUpload}
        onRemove={onRemove}
        setFileList={setFileList}
        onEdit={onEdit}
        isPrivate={isPrivate}
        isLoading={isLoading}
        isSmallThumbnail={isSmallThumbnail}
        isMainProfile={isMainProfile}
        photoItemStyle={photoItemStyle}
        countTotalPhotos={countTotalPhotos}
      />
      {isMaxLength && (
        <StyledMaxLengthContainer>
          <StyledSpan1>Wow!</StyledSpan1>
          <StyledSpan2>You look great!</StyledSpan2>
        </StyledMaxLengthContainer>
      )}
    </StyledPhotoItem>
  );
};

export default JoinPhotoUploadItem;
