const VideoConfig = {
  maxFileSizeByte: 75000000, // bytes
  maxFileSizeMB: 75, // megabytes
  maxDuration: 15, // in seconds
  maxDurationBuffer: 2, // in seconds
  allowedMimeType:
    'video/mp4,video/webm,video/x-matroska,video/mkv,video/x-ms-wmv,video/x-msvideo,video/avi, video/quicktime',
  allowedMimeTypeText: 'mp4, AVI, MOV, WMV, WebM, MKV, AVCHD',
  isEnabled: process.env.REACT_APP_ALLOW_VIDEO_UPLOAD === 'true',
};

const PhotoConfig = {
  maxFileSizeByte: 15000000, // bytes
  maxFileSizeMB: 15, // megabytes
  allowedMimeType: 'image/jpeg,image/jpg,image/gif,image/png,image/tiff,image/heic',
  allowedMimeTypeText: 'JPEG, JPG, GIF, PNG, TIFF, HEIC',
  isEnabled: true,
};

const RecordingConfig = {
  maxDuration: 15000, // max duration in milliseconds
  maxDurationDisplay: 15, // max duration in seconds
};

export { VideoConfig, PhotoConfig, RecordingConfig };
