export default {
  lineHeight: '16px',
  light: {
    heading: '#333333', // h1, h2, ..., tags
    primaryText: '#595959',
    secondaryText: '#8F8F8F',
    inactive: '##C9C9C9',
  },
  dark: {
    heading: '#CFCFCF',
    primaryText: '#AFB0B1',
    secondaryText: '#8F8F8F',
    inactive: '##C9C9C9',
  },
};
