import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ErrorIcon from '@material-ui/icons/Error';
import styled from '@emotion/styled';
import { backgroundTheme } from '../style';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(
  {
    position: 'absolute !important',
    borderRadius: '20px !important',
    minWidth: '40px !important',
  },
  ({ sent, error }) => ({
    right: sent === 'true' ? 'unset' : '-55px',
    left: sent === 'true' ? '-55px' : 'unset',
    top: error === 'true' ? '10px' : '-10px',
  })
);

interface PhotomenuProps {
  isSent: boolean;
  handleDeleteImg(deleteFor: string, messageId: string): void;
  messageId: string;
  handleReportImg(messageId: string): void;
  hasError?: boolean;
}

const PhotoMenu = ({ isSent, handleDeleteImg, messageId, handleReportImg, hasError }: PhotomenuProps): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(currOpen => !currOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onRemoveImg = deleteFor => {
    handleDeleteImg(deleteFor, messageId);
  };

  const onReportImg = () => {
    handleReportImg(messageId);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <StyledButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sent={isSent.toString()}
        error={hasError.toString()}
      >
        <MoreHorizIcon style={{ color: backgroundTheme.iconFill }} />
      </StyledButton>
      <Popper style={{ zIndex: 10 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {isSent && <MenuItem onClick={() => onRemoveImg('both')}>Delete Photo</MenuItem>}
                  {!isSent && (
                    <div>
                      <MenuItem onClick={() => onRemoveImg('self')}>Delete for Me</MenuItem>
                      <MenuItem onClick={onReportImg}>
                        Report Image <ErrorIcon style={{ marginLeft: 5 }} color="error" />
                      </MenuItem>
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default PhotoMenu;
