import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from '../atoms/buttons/Button';
import { idvPurchaseEnabled } from '../config/Master';
import ConfirmVerificationModal from './modals/ConfirmVerificationModal';
import { commonIcons } from '../atoms/icons/materialIcons';
import { backgroundTheme, color, dimensions, idvTheme, textColor } from '../style';
import { useNavigate } from 'react-router-dom';
import { mixpanelTrackIdVerificationPopupOpened } from '../utils/mixpanel/idVerificationPopupOpened';
import { mixpanelTrackBuyVerificationPageView } from '../utils/mixpanel/buyVerificationPageView';

const IDVerificationStatus = styled('div')({
  padding: '10px 10px',
  color: textColor.DARKER.color,
  borderRadius: 10,
  backgroundColor: backgroundTheme.completionBanner,
  border: 'solid 1px #5BB1F1',
  margin: '0px 10px',
  minWidth: 350,
  [dimensions.SCREEN_MAX_XS_2]: {
    width: '100%',
    display: 'flex',
  },
});

const InfoItemContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  margin: '0 auto',
});

const InfoItemDescription = styled('div')({
  fontSize: 'inherit',
  color: idvTheme.textColor,
  width: '100%',
});

const BtnVerificationContent = styled('div')({
  fontWeight: 600,
  fontSize: 16,
  color: idvTheme.textColor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const BtnVerification = styled(Button)(
  {
    width: 430,
    background: idvTheme.gradientBG,
    borderRadius: '10px',
    border: '1px solid transparent',
    [dimensions.SCREEN_MIN_SM]: {
      width: 350,
    },

    ':hover': {
      background: idvTheme.gradientBG,
      borderRadius: '10px',
      border: '1px solid transparent',
    },
  },
  ({ verified }) => ({
    borderColor: verified ? '#24A83A' : 'transparent',
    boxShadow: verified ? 'none' : '0px 2px 3px rgba(0, 0, 0, 0.2)',
    pointerEvents: verified ? 'none' : 'auto',
  })
);

const disabledVerifedBadgeStyled = {
  fontSize: 24,
  color: color.BRAND_2,
  opacity: 0.5,
  width: 24,
  height: 24,
};

const verifedBadgeStyled = {
  fontSize: 24,
  color: color.BRAND_2,
  width: 24,
  height: 24,
};

const SideBarStyle = {
  maxWidth: 200,
  margin: '0 auto',
  minWidth: 'unset',
  fontSize: 15,
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  borderRadius: 6,
  fontStyle: 'italic',
  padding: '25px 10px',
  background: idvTheme.sideBarGradientBG,
};

interface PurchaseIDVProps {
  onConfirmIDV: any;
  account: any;
  onContinueIDV: any;
  desktopContinue: boolean;
  sideBar?: boolean;
  customIDVerificationStatusStyle?: React.CSSProperties;
}

const PurchaseIDV = (props: PurchaseIDVProps): ReactElement => {
  const navigate = useNavigate();

  const {
    onConfirmIDV = () => navigate('/idv-packages?step=payment&package_id=idv_last_minute'),
    account,
    onContinueIDV = () => navigate('/verification'),
    desktopContinue = false,
    sideBar = false,
    customIDVerificationStatusStyle = {},
  } = props;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const renderBtnIDV = () => {
    if (!account) return;

    const { status, status_text } = account ? (account.id_verification ? account.id_verification.data : '') : '';
    const isPending = status === 'pending';
    const isRequired = status === 'required';
    const isPaidStatus = status === 'paid';
    const isNotStarted = status === '' || status === 'not_started';
    const showContinueBtn = !isPending && (isRequired || isPaidStatus);

    const customSideStyle = sideBar ? SideBarStyle : {};

    if (isNotStarted && desktopContinue) {
      return (
        <BtnVerification
          data-test-id={sideBar ? 'sidebar-buy-idv' : 'btn-buy-idv'}
          customStyle={customSideStyle}
          onClick={() => {
            document.body.style.overflow = 'hidden';
            setShowConfirmModal(true);
            mixpanelTrackIdVerificationPopupOpened();
          }}
        >
          <BtnVerificationContent>
            <commonIcons.check.badge customStyle={disabledVerifedBadgeStyled} />
            &ensp;<strong>Get ID Verified</strong>
          </BtnVerificationContent>
        </BtnVerification>
      );
    }

    if (showContinueBtn && desktopContinue && sideBar) {
      return (
        <BtnVerification
          customStyle={{ ...customSideStyle, border: 'solid 1px #5BB1F1', background: idvTheme.btnBG }}
          onClick={onContinueIDV}
        >
          <BtnVerificationContent>
            <commonIcons.check.badge customStyle={disabledVerifedBadgeStyled} />
            &ensp;
            <InfoItemDescription style={{ fontWeight: 'normal' }}>
              <i>
                IDV:{' '}
                <strong>
                  <i>Not Complete</i>
                </strong>
                <br />
                <small style={{ fontSize: 12 }}>(Press to Continue)</small>
              </i>
            </InfoItemDescription>
          </BtnVerificationContent>
        </BtnVerification>
      );
    }

    if (isPending && desktopContinue) {
      return (
        <IDVerificationStatus
          style={
            sideBar
              ? { ...customSideStyle, background: idvTheme.btnBG, padding: 10 }
              : { ...customSideStyle, ...customIDVerificationStatusStyle }
          }
          status="info"
        >
          <InfoItemContainer data-test-id="idv-status-banner">
            <commonIcons.check.badge customStyle={disabledVerifedBadgeStyled} />
            &ensp;
            <InfoItemDescription>
              <i>
                {sideBar ? 'IDV' : 'ID Verification'}:{' '}
                <strong>
                  <i>{status_text}</i>
                </strong>
              </i>
            </InfoItemDescription>
          </InfoItemContainer>
        </IDVerificationStatus>
      );
    }

    if (showContinueBtn && desktopContinue) {
      return (
        <IDVerificationStatus status="info" style={customIDVerificationStatusStyle}>
          <InfoItemContainer data-test-id="idv-status-banner">
            <commonIcons.check.badge customStyle={disabledVerifedBadgeStyled} />
            &ensp;
            <InfoItemDescription>
              <i>
                {sideBar ? 'IDV' : 'ID Verification'}:{' '}
                <strong>
                  <i>{isPending ? status_text : 'Not Complete'}</i>
                </strong>
              </i>
            </InfoItemDescription>
            <Button
              buttonType="error"
              onClick={onContinueIDV}
              customStyle={{
                padding: '5px 30px',
              }}
            >
              Continue
            </Button>
          </InfoItemContainer>
        </IDVerificationStatus>
      );
    }

    return (
      <BtnVerification verified="true" customStyle={customSideStyle}>
        <BtnVerificationContent>
          <commonIcons.check.badge customStyle={verifedBadgeStyled} />
          &ensp;
          <strong>
            <i>ID Verified!</i>
          </strong>
        </BtnVerificationContent>
      </BtnVerification>
    );
  };

  return (
    <React.Fragment>
      <ConfirmVerificationModal
        onClose={() => {
          document.body.style.overflow = 'unset';
          setShowConfirmModal(false);
        }}
        contentLabel={'Confirm ID Verification'}
        isOpen={showConfirmModal}
        onConfirm={() => {
          setShowConfirmModal(false);
          mixpanelTrackBuyVerificationPageView();
          onConfirmIDV();
        }}
      />
      {idvPurchaseEnabled && renderBtnIDV()}
    </React.Fragment>
  );
};

export default PurchaseIDV;
