/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef, ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import { color, textColor, dimensions, borders, patternModal, backgroundTheme } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import TextLink from '../../common/TextLink';
import PriceInput from './PriceInput';
import OfferPriceBtnSet from './OfferPriceBtnSet';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { addCommas } from '../../../utils/helpers';
import {
  sendOffer,
  sendCancel,
  sendSuggest,
  sendAmountCustom,
  counterOffer,
  counterCancel,
  counterModalCustomAmount,
} from '../../../actions/profileTrackAction';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../../constants/evidently';
import useEvidently from '../../../hooks/useEvidently';
import { getDefaultOfferAmount } from '../../../utils/offers';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const setColor = {
  default: {
    color: 'grey',
  },
  brand: {
    color: color.BRAND_1,
  },
  alert: { ...textColor.RED },
};

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

const CurrencyTip = styled('div')(
  {
    display: 'inline-block',
    position: 'relative',
  },
  ({ offerPrice }) => ({
    ...setColor[offerPrice],
    ':before': {
      position: 'absolute',
      top: 14,
      right: '85%',
      fontSize: 22,
    },
  })
);

const CounterRange = styled('div')({
  fontSize: '13px',
  marginBottom: '12px',
  textAlign: 'center',
});

const PriceContainer = styled('div')(
  {
    background: 'red',
    textAlign: 'center',
    width: '260px',
    margin: '1rem auto',
    borderRadius: '2px',
  },

  ({ hasErrors }) => ({
    backgroundColor: hasErrors ? 'rgba(204, 84, 84,0.15)' : backgroundTheme.fsModal,
    border: hasErrors ? '1px solid #cc5454' : '1px solid #5BB1F1',
  })
);

const ErrorContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
});

const ErrorMessage = styled('div')({
  color: 'rgb(204, 84, 84)',
  margin: '16px auto 0',
  maxWidth: '280px',
});

const PurchaseCredits = styled('div')({
  textAlign: 'center',
  marginTop: '12px',
  textDecoration: 'underline',
});

const ButtonDiv = styled('div')({
  marginTop: 22,

  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 12,
    paddingTop: 12,
    borderTop: borders.DEFAULT,
    width: '100%',
  },
});

const OfferAmounts = [50, 75, 100, 150, 200];

const InputProps = {
  style: {
    fontSize: 55,
    height: 72,
    lineHeight: '72px',
    fontWeight: 300,
    outline: 'none',
    border: 'none',
    color: patternModal.amtColor,
  },
};

const getHeadline = (label, currInitial, offer, currentUserIsGenerous, isCounter, username) => {
  const hasOffer =
    offer && !offer.suggest && offer.price_local && offer.price_original && offer.price_original !== null;

  const offerDescription = currentUserIsGenerous && isCounter ? 'request' : 'offer';

  if (isCounter || hasOffer) {
    const price_original = offer && offer.price_original ? offer.price_original : 0;
    return `Counter ${currInitial}${label}${price_original} ${offerDescription} from ${username}`;
  } else {
    return `Request a date with ${username}`;
  }
};

const status = currentUserIsGenerous => (currentUserIsGenerous ? 'Offer' : 'Request');

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.min_counter_price : offer && offer.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const renderCounterRange = (isCounter, currencyInitial, currLabel, minValue, maxValue) => {
  if (isCounter) {
    return (
      <CounterRange>
        min {currencyInitial}
        {currLabel}
        {minValue} max {currencyInitial}
        {currLabel}
        {maxValue}
      </CounterRange>
    );
  }
  return null;
};

const onClickRenderSuggestionBtn = (e, cannot_make_offer_reasons, sendSuggestion, showWarn) => {
  e.preventDefault();
  // check to see if user can make an offer before allowing it.
  if (cannot_make_offer_reasons === null) {
    // process suggestion
    sendSuggestion();
  } else {
    // explain suggestion fail reason.
    showWarn(`You cannot send an offer suggestion: ${cannot_make_offer_reasons[0]}`);
  }
};

const setCreateOfferAction = (e, offerAction) => {
  switch (offerAction) {
    case 'Send Counter':
      counterOffer(e);
      break;
    case 'Cancel Counter':
      counterCancel(e);
      break;
    case 'Send Offer':
      sendOffer(e);
      break;
    case 'Cancel Offer':
      sendCancel(e);
      break;
    case 'Suggest Offer':
      sendSuggest(e);
      break;
    default:
      return;
  }
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};
interface OfferCreateProps {
  avatarSrc: any;
  offer: any;
  errors: any;
  isCounter: any;
  closeModal: any;
  currencyLabel: any;
  currencyInitial: any;
  currentUserIsGenerous: any;
  sex: any;
  justSuggested: any;
  ignoreStatus: any;
  clearErrors: any;
  username: any;
  sendSuggestion: any;
  showWarn: any;
  cannot_make_offer_reasons: any;
  counterOffer: any;
  createNewOffer: any;
  hashId: any;
  buttonID: any;
  otherProfile: any;
}

const OfferCreate = ({
  avatarSrc,
  offer,
  errors,
  isCounter,
  closeModal,
  currencyLabel,
  currencyInitial,
  currentUserIsGenerous,
  sex,
  justSuggested,
  ignoreStatus,
  clearErrors,
  username,
  sendSuggestion,
  showWarn,
  cannot_make_offer_reasons,
  counterOffer,
  createNewOffer,
  hashId,
  buttonID,
  otherProfile,
}: OfferCreateProps): ReactElement => {
  const { useActiveVariation } = useEvidently();
  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultOfferPrice = useMemo(() => {
    const defaultAmount = getDefaultOfferAmount();

    if (otherProfile.account_type === 'Attractive') {
      return otherProfile?.default_offer_amount ?? defaultAmount;
    }

    return defaultAmount;
  }, [otherProfile]);

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState(isCounter ? '' : defaultOfferPrice);
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer, 'min', defaultMinOffer));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);
  const sendOfferBtn = useRef(null);
  const sendCancelBtn = useRef(null);
  const sendSuggestLink = useRef(null);
  const priceInput = useRef(null);

  useOnClickTriggerTracking(isCounter ? 'Send Counter' : 'Send Offer', sendOfferBtn, setCreateOfferAction);
  useOnClickTriggerTracking(isCounter ? 'Cancel Counter' : 'Cancel Offer', sendCancelBtn, setCreateOfferAction);
  useOnClickTriggerTracking('Suggest Offer', sendSuggestLink, setCreateOfferAction);

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    trackFocus(priceInput, isCounter ? counterModalCustomAmount : sendAmountCustom);
  }, [isCounter]);

  useEffect(() => {
    setMaxValue(offerMinMaxVal(offer, 'max', '500'));
    setMinValue(offerMinMaxVal(offer, 'min', defaultMinOffer));
  }, [offer, defaultMinOffer]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const inputOnChange = e => {
    const InputVal = e.target.value;
    const MaxLength = maxValue.toString().length;

    if (InputVal.length <= MaxLength) {
      const Error = Boolean(Number(InputVal) < Number(minValue) || Number(InputVal) > Number(maxValue));
      updateOfferPrice(InputVal, Error);
    } else {
      e.preventDefault();
    }
  };

  const currLabel = String.fromCharCode(currencyLabel);

  const mixpanelSource = () => {
    switch (buttonID) {
      case 'counterOfferHighlights':
        return 'Modal';
      case 'button-offer-create':
        return 'Profile';
      default:
        return 'Received Offers';
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = { price: offerPrice, account: hashId, source: mixpanelSource() };

    setOfferSending(true);

    showMandatoryCompleteProfile(
      () => {
        if (isCounter || (offer && offer.suggest)) {
          counterOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        } else {
          createNewOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        }
      },
      () => setOfferSending(false),
      isCounter || (offer && offer.suggest) ? 'Counter Offer' : 'Make Offer'
    );
  };

  const formatSubmitButtonText = () => {
    return isOfferSending ? `Sending ${status(currentUserIsGenerous)}...` : `Send ${status(currentUserIsGenerous)}`;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <HeaderDiv>
        <Avatar
          data-test-id="offer-modal-avatar"
          src={avatarSrc}
          width={'72px'}
          customStyle={{
            backgroundColor: '#ccc',
            margin: '0 auto',
            marginBottom: 18,
          }}
        />
        <Headline data-test-id="profile-offer-headline" size="SM">
          {getHeadline(currLabel, currencyInitial, offer, currentUserIsGenerous, isCounter, username)}
        </Headline>
        <div data-test-id="profile-offer-desc">
          {currentUserIsGenerous ? 'Offers' : 'Requests'} can be up to{' '}
          {currencyInitial + currLabel + addCommas(parseInt(maxValue, 10))}
        </div>
      </HeaderDiv>
      <Content>
        <div>
          <PriceContainer hasErrors={hasErrors}>
            <CurrencyTip
              offerPrice={colorType(offerPrice, hasErrors)}
              css={{
                ':before': {
                  content: `'${currencyInitial}${currLabel}'`,
                },
              }}
            >
              <PriceInput
                inputRef={priceInput}
                data-test-id="offer-price-input"
                hasErrors={hasErrors}
                value={offerPrice}
                onChange={inputOnChange}
                validateTextFn={validateTextInput}
                placeholder={defaultValue}
                inputProps={InputProps}
              />
            </CurrencyTip>
            {renderCounterRange(isCounter, currencyInitial, currLabel, minValue, maxValue)}
          </PriceContainer>
          <OfferPriceBtnSet
            prices={minOfferIncreaseVariation === '75Min' ? OfferAmounts.filter(amount => amount !== 50) : OfferAmounts}
            updateOfferPrice={updateOfferPrice}
            currentAmount={offerPrice}
            currLabel={currLabel}
            currInitial={currencyInitial}
            offer={offer}
            isCounter={isCounter}
          />
          {errors ? (
            <ErrorContainer>
              <ErrorMessage>{errors.price}</ErrorMessage>
              {currentUserIsGenerous && Number(offerPrice) >= 200 && (
                <PurchaseCredits>
                  <TextLink to="/packages">Purchase credits now.</TextLink>
                </PurchaseCredits>
              )}
            </ErrorContainer>
          ) : null}
        </div>
        <ButtonDiv marginTop={16}>
          <ButtonSet align="center">
            <Button
              buttonRef={sendCancelBtn}
              data-test-id={`${buttonID}Cancel`}
              disabled={isOfferSending}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
            >
              {' '}
              Cancel
            </Button>
            <Button
              id={buttonID}
              buttonRef={sendOfferBtn}
              disabled={hasErrors || isOfferSending}
              buttonType="primary"
              onClick={handleSubmit}
              type="submit"
            >
              {formatSubmitButtonText()}
            </Button>
          </ButtonSet>
          {!(offer || justSuggested || ignoreStatus) && (
            <span>
              <div style={{ textAlign: 'center', padding: '8px 0' }}>or</div>
              <ButtonSet align="center">
                <TextLink
                  linkRef={sendSuggestLink}
                  data-test-id="offer-suggest-link"
                  to="/"
                  disabled={hasErrors || isOfferSending}
                  onClick={e => onClickRenderSuggestionBtn(e, cannot_make_offer_reasons, sendSuggestion, showWarn)}
                  type="submit"
                >
                  {' '}
                  Ask {sex === 'Male' ? 'him' : 'her'} to suggest an amount
                </TextLink>
              </ButtonSet>
            </span>
          )}
        </ButtonDiv>
      </Content>
    </Form>
  );
};

export default OfferCreate;
