import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { markAsReadInterested, updateViewedMeCount } from '../../actions/favActions';
import { getLocalItem, setLocalItem } from '../../common';
import { useLocation } from 'react-router-dom';

const INITIAL_CENTER_PADDING = 81;
const INITIAL_CENTER_PADDING_RATIO = 11.46; // (375 + 553) / 81px

export const enum SliderSource {
  INTERESTS = 'interests',
  OFFERS = 'offers',
}

export const enum SliderSourceLSKey {
  INTERESTS_LS = 'interests_mark_as_read',
}

interface GridViewSliderProps {
  source: SliderSource.INTERESTS | SliderSource.OFFERS;
  enableSlider: boolean;
  children: any;
  sliderLoadMore: () => void;
  updateViewedMeCount: () => void;
  batchMarkAsRead: (ids: string[]) => void;
}

const StyledSlider = styled(Slider)`
  .slick-slide > div {
    margin-left: 6px;
    margin-right: 6px;
  }
  .slick-slide .grid-list-item {
    z-index: -1;
  }
  .slick-active .grid-list-item {
    z-index: 1;
  }
  .slick-slide .grid-list-item button {
    width: 45px;
    height: 45px;
    &::before {
      width: 45px;
      height: 45px;
    }
  }

  .slick-slide .css-offers-list-item .offer-amount-button {
    margin: 5px;
  }

  .slick-slide .css-offers-list-item .favorite-offer-new-button {
    margin: 5px 15px !important;
  }

  @media screen and (max-width: 320px), screen and (max-height: 620px) {
    .slick-slide .css-offers-list-item button {
      width: 40px; /* Adjust for iPhone SE and similar devices */
      height: 40px;
      margin: 5px !important;
    }
    .slick-slide .css-offers-list-item .offer-amount-button {
      min-width: 65px;
      max-width: 65px;
      margin-top: 0px;
      margin-bottom: 0px;
      height: 54px !important;
    }
    .slick-slide .css-offers-list-item .offer-amount-label {
      font-size: 22px;
    }
    .slick-slide .css-offers-list-item .offer-amount-label2 {
      font-size: 10px;
    }
    .slick-slide .css-offers-list-item .suggest-amount-label {
      font-size: 11px;
    }
    .slick-slide .css-offers-list-item .favorite-offer-new-button {
      margin: 5px !important;
    }
  }
`;

const GridViewSlider = ({
  source,
  enableSlider,
  children,
  sliderLoadMore = () => undefined,
  updateViewedMeCount,
  batchMarkAsRead,
}: GridViewSliderProps): ReactNode => {
  const sliderRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [hashIds, setHashIds] = useState([]);
  const location = useLocation();
  const [cloneChildren, setCloneChildren] = useState(children);
  const [centerPadding, setCenterPadding] = useState('70px');

  const handleSlideChange = (index: number) => {
    const slider = sliderRef.current;
    const sliderItemsCount = Math.round(slider.props.children.length / 2);
    const currentIndex = index + 1;

    if (sliderItemsCount === currentIndex) {
      sliderLoadMore();
    }

    setCurrentSlideIndex(index);
  };

  const handleBeforeChange = (current: number) => {
    if (source === SliderSource.INTERESTS) {
      const slider = sliderRef.current;
      const currentItem = slider.props.children[current];

      const updatedItem = {
        ...currentItem,
        props: {
          ...currentItem.props,
          isRead: true,
        },
      };

      setCloneChildren(() =>
        React.Children.map(slider.props.children, (child, index) => {
          if (index === current) {
            return updatedItem;
          }
          return child;
        })
      );
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 25,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding,
    arrows: false,
    afterChange: handleSlideChange,
    swipeToSlide: true,
    beforeChange: handleBeforeChange,
  };

  const handleOnSwipe = (currentItem: any) => {
    if (!currentItem.props.isRead && !hashIds.includes(currentItem.props.hashId)) {
      switch (source) {
        case SliderSource.INTERESTS:
          setHashIds(prevState => {
            const updateHashIds = [...prevState, currentItem.props.hashId];
            setLocalItem(SliderSourceLSKey.INTERESTS_LS, JSON.stringify(updateHashIds));
            return updateHashIds;
          });
          updateViewedMeCount();
          break;
        default:
          break;
      }
    }
  };

  const handleViewedMeMarkAsRead = () => {
    const storageHashIds = JSON.parse(getLocalItem(SliderSourceLSKey.INTERESTS_LS)) || [];
    if (storageHashIds.length > 0) {
      batchMarkAsRead(storageHashIds);
      setHashIds([]);
    }
  };

  useEffect(() => {
    return () => {
      if (location.pathname === '/interests') {
        handleViewedMeMarkAsRead();
      }
    };
  }, [location]);

  useEffect(() => {
    if (enableSlider) {
      const slider = sliderRef.current;
      const currentItem = slider.props.children[currentSlideIndex];

      handleOnSwipe(currentItem);
    }
  }, [currentSlideIndex, enableSlider]);

  useEffect(() => {
    setCloneChildren(children);
  }, [children]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if (screenWidth <= 320 && screenHeight <= 568) {
        setCenterPadding('25%'); //  for super small devices
      } else if (screenWidth <= 375 && screenHeight <= 560) {
        setCenterPadding(`${INITIAL_CENTER_PADDING}px`); //
      } else {
        const diagonalSize = screenWidth + screenHeight;
        const diagonalToPaddingRatio = diagonalSize / INITIAL_CENTER_PADDING_RATIO;
        const diffPaddingFromRatioo = diagonalToPaddingRatio - INITIAL_CENTER_PADDING;
        const totalDiff = Math.round(INITIAL_CENTER_PADDING - diffPaddingFromRatioo);
        setCenterPadding(`${totalDiff}px`);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return enableSlider ? (
    <StyledSlider ref={sliderRef} {...settings}>
      {cloneChildren}
    </StyledSlider>
  ) : (
    children
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    batchMarkAsRead: (hashIds: string[]) => {
      dispatch(markAsReadInterested(hashIds));
    },
    updateViewedMeCount: () => {
      dispatch(updateViewedMeCount());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewSlider);
