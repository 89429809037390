import React from 'react';
import styled from '@emotion/styled';

import RadioButton from '../../RadioButton';
import GooglePayIcon from './GooglePayIcon';
import './GooglePayStyle.css';
import { GPAY_PAYMENT_CHANNEL } from '../../../config/gpay';
import { isDarkMode } from '../../../utils/helpers';

const Container = styled('div')({
  padding: 0,
  position: 'relative',
});

const GooglePayContent = styled('div')({
  zIndex: 1,
  position: 'absolute',
  width: 'calc(100% - 40px)',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  padding: '15px 20px',
});

interface IGooglePayProps {
  onChange: (isNewCard: boolean, paymentChannel: string | null) => void;
  paymentChannel: string | null | undefined;
  use2PayData: any;
  handleSubmit: any;
  discount: any;
}

const GooglePay: React.FC<IGooglePayProps> = ({ onChange, paymentChannel }) => {
  return (
    <Container className="google-pay-method">
      <GooglePayContent>
        <RadioButton
          id="google_pay"
          name="google_pay"
          label="Buy with Google Pay"
          value={'google_pay'}
          onRadioChange={() => onChange(false, GPAY_PAYMENT_CHANNEL)}
          checked={paymentChannel === GPAY_PAYMENT_CHANNEL}
          style={{ flexGrow: 4 }}
          labelId="radio-google-pay"
        />
        <GooglePayIcon darkmode={isDarkMode()} />
      </GooglePayContent>
    </Container>
  );
};

export default GooglePay;
