import MasterConfig from '../config/Master';
import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import store from '../store';

const api = MasterConfig.api;
const siteId = MasterConfig.SITE_ID;
const apiRTNotify = MasterConfig.apiRTNotify;
const apiRTNotifyKey = MasterConfig.apiRTNotifyKey;

const getThreads = (
  type = 'recent',
  page = 1,
  source: CancelTokenSource | null,
  perPage = MasterConfig.per_page
): AxiosPromise => {
  return axios({
    method: 'GET',
    cancelToken: source?.token,
    url: `${api}/thread/pre/${type}?include=other_profile.account,offer&page=${page}&per_page=${perPage}`,
  });
};

const getConversation = (hashId: string, page = 1, perPage = MasterConfig.per_page): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/thread/pre/${hashId}?page=${page}&per_page=${perPage}&include=other_profile.account,offer,pokes`,
  });
};

const postMessage = (hashId: string, message: string, type = '', extension = null): AxiosPromise => {
  const messageData = () => {
    switch (type) {
      case 'emoji':
      case 'photo':
        return {
          hashId,
          message,
          type,
          extension,
        };
      default:
        return {
          hashId,
          message,
        };
    }
  };

  return axios({
    method: 'POST',
    url: `${api}/message`,
    data: messageData(),
  });
};

const sendPoke = (targetId: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/poke/${targetId}`,
  });
};

/**
    Toggles/untoggled archive related to thread.
**/
const toggleArchive = (token: any, hashId: string | number): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/thread/${hashId}`,
    data: {},
  });
};

const sendTypingIndicator = (recipientHashId: string): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${apiRTNotify}/typing-indicator`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${store.getState().auth.jwToken}`,
      'x-api-key': apiRTNotifyKey,
      'x-site-id': siteId,
    },
    data: {
      receiverId: recipientHashId,
    },
  });
};

const toggleThreadMessageStatus = (token: any, thread_id: any, hash_id: any, status: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/message/toggle-all-status`,
    data: {
      thread_id,
      hash_id,
      status,
    },
  });
};

const deleteMailThread = (token: any, username: string): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/thread/${username}`,
    data: {},
  });
};

const deletePhotoMessage = (option: any, messageID: string | number): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/message/${messageID}`,
    data: {
      option,
    },
  });
};

const reportPhotoMessage = (messageID: string | number): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/message/${messageID}/report`,
  });
};

export {
  getThreads,
  getConversation,
  postMessage,
  sendPoke,
  toggleArchive,
  sendTypingIndicator,
  toggleThreadMessageStatus,
  deleteMailThread,
  deletePhotoMessage,
  reportPhotoMessage,
};
