import React, { ReactElement } from 'react';

const ExistingVideo = (): ReactElement => {
  return (
    <svg
      width="48"
      data-test-id="existing-video-icon"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_852_5312"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_852_5312)">
        <path
          d="M4.9 39.1C5.5 39.7 6.2 40 7 40H33C33.8 40 34.5 39.7 35.1 39.1C35.7 38.5 36 37.8 36 37V26.25L42.7 32.95C42.9667 33.2167 43.25 33.2833 43.55 33.15C43.85 33.0167 44 32.7833 44 32.45V15.55C44 15.2167 43.85 14.9833 43.55 14.85C43.25 14.7167 42.9667 14.7833 42.7 15.05L36 21.75V11C36 10.2 35.7 9.5 35.1 8.9C34.5 8.3 33.8 8 33 8H7C6.2 8 5.5 8.3 4.9 8.9C4.3 9.5 4 10.2 4 11V37C4 37.8 4.3 38.5 4.9 39.1Z"
          fill="#2B8FD7"
        />
      </g>
    </svg>
  );
};

export default ExistingVideo;
