import React, { ReactElement } from 'react';

const GalleryUploadRemoveIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 24, height: 24, borderRadius: 50, ...customStyle }}
      {...rest}
    >
      <circle opacity="0.65" cx="12" cy="12" r="12" fill="white" />
      <circle cx="12" cy="12" r="8" fill="white" />
      <rect x="1" y="1" width="22" height="22" fill="#D9D9D9" />
      <g mask="url(#mask0_2906_1624)">
        <path
          d="M12 13.1689L14.6583 15.8273C14.8263 15.9953 15.0211 16.0755 15.2427 16.0679C15.4642 16.0603 15.659 15.9724 15.827 15.8044C15.9951 15.6363 16.0791 15.4415 16.0791 15.22C16.0791 14.9985 15.9951 14.804 15.827 14.6366L13.1687 11.9887L15.827 9.34088C15.9951 9.17348 16.0791 8.97901 16.0791 8.75749C16.0791 8.53596 15.9951 8.34117 15.827 8.17311C15.659 8.00506 15.4642 7.92103 15.2427 7.92103C15.0211 7.92103 14.8263 8.00506 14.6583 8.17311L12 10.8314L9.34162 8.17311C9.17356 8.00506 8.98259 7.92103 8.7687 7.92103C8.55481 7.92103 8.36384 8.00506 8.19579 8.17311C8.02773 8.34117 7.9437 8.53596 7.9437 8.75749C7.9437 8.97901 8.02706 9.17381 8.19379 9.34186L10.8312 12.0002L8.17287 14.6585C8.00481 14.8266 7.9246 15.0176 7.93224 15.2314C7.93988 15.4453 8.02773 15.6363 8.19579 15.8044C8.36384 15.9724 8.55863 16.0564 8.78016 16.0564C9.00169 16.0564 9.19581 15.9731 9.36254 15.8064L12 13.1689ZM12.0063 20.8002C10.7951 20.8002 9.65481 20.571 8.58537 20.1127C7.51592 19.6544 6.58016 19.0242 5.77808 18.2221C4.97599 17.42 4.34578 16.4845 3.88745 15.4157C3.42912 14.3469 3.19995 13.2049 3.19995 11.9897C3.19995 10.7745 3.42912 9.63596 3.88745 8.57415C4.34578 7.51235 4.97599 6.5804 5.77808 5.77832C6.58016 4.97624 7.5156 4.34603 8.58441 3.8877C9.65322 3.42936 10.7952 3.2002 12.0104 3.2002C13.2257 3.2002 14.3642 3.42936 15.426 3.8877C16.4878 4.34603 17.4197 4.97624 18.2218 5.77832C19.0239 6.5804 19.6541 7.51406 20.1125 8.57929C20.5708 9.64453 20.8 10.7827 20.8 11.9939C20.8 13.205 20.5708 14.3453 20.1125 15.4148C19.6541 16.4842 19.0239 17.42 18.2218 18.2221C17.4197 19.0242 16.4861 19.6544 15.4209 20.1127C14.3556 20.571 13.2174 20.8002 12.0063 20.8002Z"
          fill="#BC2028"
        />
      </g>
    </svg>
  );
};

export default GalleryUploadRemoveIcon;
