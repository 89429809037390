import React from 'react';

const VisaIcon = ({ customStyle }: { customStyle?: React.CSSProperties }): React.ReactElement => {
  return (
    <svg
      width="40"
      height="26"
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...customStyle }}
    >
      <rect x="0.5" width="39" height="26" rx="4" fill="#2A2A6C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1594 14.3565C25.1644 13.1177 24.1426 12.5826 23.3497 12.1673C22.8478 11.9045 22.4375 11.6896 22.4375 11.3746C22.4375 11.1065 22.6975 10.8221 23.25 10.749C23.9076 10.684 24.5704 10.7991 25.1675 11.0821L25.5088 9.45715C24.9267 9.23888 24.3104 9.12607 23.6888 9.12402C21.7713 9.12402 20.4388 10.1478 20.4388 11.6103C20.4388 12.6909 21.4056 13.2921 22.1369 13.6496C22.8681 14.0071 23.1525 14.259 23.1444 14.584C23.1444 15.0878 22.5431 15.3153 21.9825 15.3234C21.2909 15.3323 20.6086 15.1645 20 14.8359L19.6506 16.4609C20.3392 16.7297 21.0727 16.8648 21.8119 16.859C23.8513 16.859 25.1919 15.8515 25.2 14.2915L25.1594 14.3565ZM19.8863 9.2541L18.2613 16.7697H16.3113L17.9363 9.2541H19.8863ZM28.06 14.129L29.0838 11.3096L29.6769 14.129H28.06ZM32.0331 16.794H30.2294L29.9938 15.6728H27.5481L27.15 16.794H25.1025L28.0194 9.8309C28.1539 9.4996 28.4744 9.28167 28.8319 9.2784H30.4569L32.0331 16.794ZM13.9956 16.7453L17.1481 9.22966H15.0356L13.0125 14.3403L12.2 9.99341C12.1281 9.5517 11.7457 9.22784 11.2981 9.22966H7.99125L7.9425 9.44904C8.60578 9.57881 9.25003 9.79174 9.86 10.0828C10.1198 10.2049 10.2959 10.4551 10.3231 10.7409L11.875 16.7453H13.9956Z"
        fill="white"
      />
    </svg>
  );
};

export default VisaIcon;
