import React, { Component } from 'react';
import PrivacyPolicy from '../components/pages/GDPR/PrivacyPolicy';
import { connect } from 'react-redux';
import { buttonEventHandler, notifyEmailUpdate } from '../actions/profileActions';

class PrivacyPolicyGDPR extends Component {
  render() {
    return <PrivacyPolicy {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    gdpr_acknowledged: state.auth.gdpr_acknowledged,
    gdpr_Step2: state.auth.gdpr_Step2,
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.profile,
    username: state.profile.username,
    isFetching: state.common.isFetching,
    notification: state.notification,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    buttonEventHandler(isAccept) {
      dispatch(buttonEventHandler(isAccept, ownProps.navigate));
    },
    notifyEmailUpdate: (notifyEmail, callback) => {
      dispatch(notifyEmailUpdate(notifyEmail, ownProps.navigate)).then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyGDPR);
