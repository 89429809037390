/** @jsx jsx */
import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '../../atoms/icons/favoriteIcons';
import { jsx } from '@emotion/core';
import { ButtonContainer, CustomButton, IconContainer, ButtonWrap } from './FavoriteButtonStyles';
import StarFillIcon from '../icons/StarFillIcon';
import { getBrowserDetails } from '../../utils/helpers';

const FavoriteButton = (props: any): ReactElement => {
  const { favorited, ...rest } = props;
  const { isMobile, isDesktop } = getBrowserDetails();

  return (
    <ButtonContainer data-test-id="btn-container" aria-label={favorited ? 'Unfavorite' : 'Favorite'}>
      <CustomButton
        data-test-id={favorited ? 'remove-fav-btn' : 'add-fav-btn'}
        title={favorited ? 'Remove from My Favorites' : 'Add to My Favorite'}
        className="favorite-button"
        isFavorited={favorited}
        {...rest}
        aria-label={favorited ? 'Unfavorite' : 'Favorite'}
      >
        <IconContainer>
          {favorited ? (
            <StarFillIcon
              height={isMobile ? 28.21 : 38.21}
              width={isMobile ? 35 : 55}
              cssStyle={{
                height: isMobile && !isDesktop ? '25px' : '32px',
                width: '55px',
              }}
            />
          ) : (
            <ButtonIcon fill={favorited ? '' : ''} />
          )}
        </IconContainer>
      </CustomButton>
      <label>{favorited ? 'Unfavorite' : 'Favorite'}</label>
    </ButtonContainer>
  );
};

export const HighlightFavoriteButton = (props: any): ReactElement => {
  const { favorited, ...rest } = props;
  const favoriteButtonStyle = favorited ? { ...style.default, ...style.favorited } : style.default;
  return (
    <ButtonWrap customStyle={favoriteButtonStyle} {...rest}>
      <IconContainer>
        <ButtonIcon fill={favorited ? 'rgb(255, 255, 255)' : 'rgb(255, 175, 0)'} />
      </IconContainer>
    </ButtonWrap>
  );
};

FavoriteButton.defaultProps = {
  favorited: false,
};

FavoriteButton.propTypes = {
  favorited: PropTypes.any,
};

export default FavoriteButton;
