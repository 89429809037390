import { VYSION_ACTIONS } from '..';
import { handleVysionEvent, EventData } from '../vysionService';
import { paymentClickVysionEvents } from './click';
import { paymentOnFocusVysionEvents } from './onFocus';
import { paymentViewVysionEvents } from './view';

export const events = {
  [VYSION_ACTIONS.ON_FOCUS]: { ...paymentOnFocusVysionEvents },
  [VYSION_ACTIONS.CLICK]: { ...paymentClickVysionEvents },
  [VYSION_ACTIONS.VIEW]: { ...paymentViewVysionEvents },
};

export const handlePaymentVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
