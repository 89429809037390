import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, backgroundTheme, textType, type } from '../../style';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import ButtonGroup from '../../atoms/buttons/ButtonGroup';
import OfferActionButton from '../../atoms/offers/OfferActionButton';
import OffersTutorialModal from '../../components/common/modals/OffersTutorialModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';

const ReminderContainer = styled('div')(({ isOpen }) => ({
  display: isOpen ? 'block' : 'none',
  position: 'fixed',
  top: '20%',
  left: '50%',
  marginLeft: -200,
  zIndex: 998,

  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
  },
}));

const BackdropContainer = styled('div')({
  background: 'rgba(0, 0, 0, 0.4)',
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100%',
  height: '100%',
  textAlign: 'center',
});

const OfferAlertContainer = styled('div')`
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  min-height: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin: 40px auto 0;
  position: relative;
  background-clip: padding-box;
  box-sizing: border-box;
  border: solid 5px transparent;
  top: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(135deg, #ff0099 0%, #ffba72 100%);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }
  ${dimensions.SCREEN_MAX_XS} {
    top: 47%;
    transform: translateY(-50%);
  }
  ${dimensions.SCREEN_MIN_XXS} {
    width: 300px;
    margin: 15px auto 0;
  }
  ${dimensions.SCREEN_MIN_XS} {
    width: 320px;
    margin: 30px auto 0;
  }
  ${dimensions.SCREEN_MIN_SM} {
    width: 360px;
    margin: 40px auto 0;
  }
`;

const OfferAvatar = styled('div')(
  {
    width: '100%',
    height: 340,
    background: '#000000',
    flexShrink: 0,
    borderRadius: '20px 20px 0px 0px',
    position: 'relative',
  },
  ({ backgroundUrl }) =>
    backgroundUrl && {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundPosition: 'top',
      backgroundSize: 'cover',
    }
);

const OfferClose = styled('a')({
  fontWeight: 600,
  position: 'absolute',
  right: 10,
  top: 10,
  filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8))',
  color: '#fff',
  zIndex: 4,
});

const OfferProfileContainer = styled('div')({
  textShadow: '0px 0px 4px rgba(0, 0, 0, 0.8)',
  color: '#fff',
  position: 'absolute',
  bottom: 10,
  left: 10,
  textAlign: 'left',
  zIndex: 3,
});

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81%, rgba(0,0,0,1) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
});

const OfferUsername = styled('div')({
  fontWeight: 600,
});

const OfferProfileDetail = styled('div')({
  paddingTop: 5,
});

const OfferDescription = styled('span')({
  paddingTop: 5,
});

const OfferMessageContainer = styled('div')({
  flex: 1,
  padding: 14,
  borderRadius: '0px 0px 20px 20px',
  backgroundColor: backgroundTheme.block,
});

const OfferMessage = styled('div')({
  textAlign: 'center',
});

const OfferLabel = styled('div')({
  ...textType.header,
});

const PriceLabel = styled('div')({
  paddingTop: '2px',
  verticalAlign: 'top',
  display: 'inline-block',
  lineHeight: '30px',
  color: '#1383d2',
  fontWeight: 600,
  ...type.XL,
});

const Price = styled('div')({
  color: '#1383d2',
  fontSize: type.XXXL.fontSize,
  fontWeight: 600,
  lineHeight: type.XXXL.fontSize,
  display: 'inline-block',
});

interface OfferModalProps {
  isOpen: any;
  avatar: any;
  onClose: any;
  setClickProfileAction: any;
  username: any;
  age: any;
  location: any;
  offerDescription: any;
  lastUpdated: any;
  currency: any;
  price: any;
  offerType: any;
  handleIgnoreOffer: any;
  handleCounter: any;
  counterDisabled: any;
  accountHashId: any;
  handleAcceptOffer: any;
  handleSuggestOffer: any;
  isGenerous: any;
  handleCancelOffer: any;
  handleCancelSuggestion: any;
}

const OfferModal = ({
  isOpen,
  avatar,
  onClose,
  setClickProfileAction,
  username,
  age,
  location,
  offerDescription,
  lastUpdated,
  currency,
  price,
  offerType,
  handleIgnoreOffer,
  handleCounter,
  counterDisabled,
  accountHashId,
  handleAcceptOffer,
  handleSuggestOffer,
  isGenerous,
  handleCancelOffer,
  handleCancelSuggestion,
}: OfferModalProps): ReactElement => {
  useDisableBodyScroll(isOpen);

  const formatPrice = (setCurrency, setPrice, offerLabel) => {
    if (!setCurrency || !setPrice) {
      return (
        <React.Fragment>
          No amount suggested. <OffersTutorialModal iconOnly />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <OfferLabel>
          {offerLabel} <OffersTutorialModal iconOnly />
        </OfferLabel>
        <PriceLabel>
          {setCurrency.initial}
          {String.fromCharCode(setCurrency.label)}
        </PriceLabel>
        <Price>{setPrice}</Price>
      </React.Fragment>
    );
  };

  return (
    <ReminderContainer isOpen={isOpen}>
      {isOpen && (
        <BackdropContainer>
          <OfferAlertContainer data-test-id="OfferHilightModal">
            <OfferAvatar data-test-id="profile-image-offer" backgroundUrl={avatar}>
              <OfferClose
                data-test-id="complete-profile-skip"
                onClick={e => {
                  e.preventDefault();
                  onClose();
                }}
              >
                <CloseIcon style={{ fontSize: 28 }} />
              </OfferClose>
              <Link
                data-test-id="link-to-profile"
                className="css-offer-profile-list-item-link-desktop"
                onClick={() => {
                  setClickProfileAction(true);
                }}
                to="#"
              >
                <OfferProfileContainer>
                  <OfferUsername data-test-id="offer-highlight-username">{username}</OfferUsername>
                  <OfferProfileDetail data-test-id="offer-highlight-info">
                    {age} • {location}
                  </OfferProfileDetail>
                  {offerDescription && <OfferDescription>{offerDescription} &bull;</OfferDescription>} {lastUpdated}
                </OfferProfileContainer>
                <Gradient />
              </Link>
            </OfferAvatar>
            <OfferMessageContainer>
              <OfferMessage>
                <div data-test-id="offer-highlight-title" className="css-offer-profile-price">
                  {formatPrice(currency, price, 'First Date Incentive Offer')}
                </div>
              </OfferMessage>
              <OfferMessage>
                <ButtonGroup>
                  <OfferActionButton
                    offerType={offerType}
                    isOfferHighlight
                    handleIgnoreOffer={handleIgnoreOffer}
                    handleCounter={handleCounter}
                    counterOfferDisabled={counterDisabled}
                    accountHashId={accountHashId}
                    handleAcceptOffer={handleAcceptOffer}
                    handleSuggestOffer={handleSuggestOffer}
                    currentUserIsGenerous={isGenerous}
                    handleCancelOffer={handleCancelOffer}
                    handleCancelSuggestion={handleCancelSuggestion}
                  />
                </ButtonGroup>
              </OfferMessage>
            </OfferMessageContainer>
          </OfferAlertContainer>
        </BackdropContainer>
      )}
    </ReminderContainer>
  );
};

export default OfferModal;
