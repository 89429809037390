import React, { ReactElement } from 'react';
import Panel from './panels/Panel';
import styled from '@emotion/styled';
import ToggleButton from './togglebutton/ToggleButton';
import Button from './buttons/Button';
import { getBrowserDetails, isDarkMode } from '../utils/helpers';
import CancelButton from './buttons/ProfileCancelButton';

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  borderTop: '1px solid #DAE1E9',
  paddingTop: 14,
});

const checkTabletWidth = screen.width >= 768 && screen.width <= 820;

const ToggleButtonsContainer = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: getBrowserDetails().isMobile && checkTabletWidth ? '1fr 1fr 1fr' : '1fr 1fr',
  gridTemplateRows: getBrowserDetails().isMobile && checkTabletWidth ? '52% 52%' : '34% 34% 34%',
  paddingBottom: 10,
});

interface EditableInterestedInProps {
  allInterests: any;
  onChangeHandler: any;
  findInterest: any;
  title: any;
  className: any;
  handleCancel: any;
  handleSubmit: any;
}

const EditableInterestedIn = ({
  allInterests,
  onChangeHandler,
  findInterest,
  title,
  className,
  handleCancel,
  handleSubmit,
}: EditableInterestedInProps): ReactElement => {
  const checkForAtLeast1 = () => {
    let count = 0;
    for (const property in allInterests) {
      allInterests[property] && count++;
    }
    return count >= 1;
  };

  return (
    <Panel
      withHeader
      title={title}
      className={className}
      isProfile
      cancelButton={
        <CancelButton
          dataTestId="editable-textarea-cancel-btn"
          handleCancel={handleCancel}
          customStyle={{ marginRight: 0 }}
        />
      }
      panelStyle={isDarkMode() ? 'borderless' : 'white'}
    >
      <ToggleButtonsContainer>
        {// need to iterate through the list of interests and decide whether which ones to set active and which one not to
        Object.keys(allInterests).map((item, index) => (
          <ToggleButton
            key={index}
            title={findInterest(item, 'value')}
            customStyle={{ cursor: 'pointer', order: index + 1, marginLeft: 0 }}
            id={item}
            isChecked={allInterests[item]}
            onChangeHandler={onChangeHandler}
          />
        ))}
      </ToggleButtonsContainer>
      <ButtonContainer>
        <Button
          customStyle={{ paddingRight: 30, paddingLeft: 30 }}
          buttonType="error"
          onClick={handleSubmit}
          disabled={!checkForAtLeast1()}
        >
          Save
        </Button>
      </ButtonContainer>
    </Panel>
  );
};

export default EditableInterestedIn;
