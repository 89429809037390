import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
  page: 'Payment',
};

export const enum PaymentViewVysionEventsEnum {
  VIEW_PAYMENT_NEVER_MISS_A_CONNECTION = 'paymentNeverMissAConnection',
  VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS = 'accountMenuPaymentSettings',
  VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL = 'accountMenuPaymentSettingsSuccess',
}

export const paymentViewVysionEvents = {
  [PaymentViewVysionEventsEnum.VIEW_PAYMENT_NEVER_MISS_A_CONNECTION]: {
    ...commonValues,
    eventName: 'payment-neverMissAConnection',
    component: 'Auto Credit Top-Up Tool Tip',
    extraInfo: '"Never Miss a Connection"',
  },
  [PaymentViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS]: {
    ...commonValues,
    eventName: 'accountMenu-paymentSettings',
    component: 'Payment Settings',
    extraInfo: '',
  },
  [PaymentViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoTopUpSuccessful',
    component: 'Auto Top-Up Success Modal',
    extraInfo: '',
  },
};
