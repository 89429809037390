import React, { useEffect, useState, Fragment } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Container from '../../blocks/Container';
import Panel from '../../../atoms/panels/Panel';
import Button from '../../../atoms/buttons/Button';
import Header from '../../blocks/Header';
import Text from '../../common/Text';
import TextArea from '../../../atoms/TextArea';
import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  Radio,
  RadioGroup,
  SvgIcon,
  withStyles,
} from '@material-ui/core';
import { Report, Visibility, VisibilityOff } from '@material-ui/icons';
import GDPRCompliantBorderlessIcon from '../../../atoms/icons/GDPRCompliantBorderlessIcon';
import { backgroundTheme, dimensions, textColor, textType } from '../../../style';
import { fetchSurveyQuestions } from '../../../sdk';
import { sendGDPRDeleteAction } from '../../../actions/authActions';
import { getBrowserDetails, isDarkMode } from '../../../utils/helpers';
import MasterConfig, { s3BucketDirect } from '../../../config/Master';
import TextLink from '../../common/TextLink';
import useModal from '../../../hooks/useModal';
import { handleDeactivateAccountVysionEvent } from '../../../utils/vysion/deactivateAccount';
import { DeactivateAccountClickVysionEventsEnum } from '../../../utils/vysion/deactivateAccount/click';
import Loader from '../../../atoms/LoaderStyled';
import { useNavigate } from 'react-router-dom';
import { mixpanelTrackDeletePageViewed } from '../../../utils/mixpanel/deletePageViewed';
import { mixpanelTrackEvent } from '../../../utils/mixpanel/common';
import { AccountDeletionStartedProps } from '../../../utils/mixpanel/mixpanel';
import { EVENT_NAMES } from '../../../constants/mixpanel';

const { isMobile } = getBrowserDetails();

interface GDPRDeleteProps {
  gdprDelete: any;
  errors: any;
}

const MainPanel = styled(Panel)(
  {
    position: 'relative',
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundTheme.personalInfo,

    [dimensions.SCREEN_MAX_SM]: {
      margin: '10px auto',
      width: '100%',
    },
  },
  ({ darkMode }) => ({
    border: darkMode ? 'none' : '1px solid #DAE1E9',
  })
);

const GDPRIconContainer = styled('div')({
  position: 'absolute',
  top: 15,
  right: 15,

  [dimensions.SCREEN_MAX_XS]: {
    right: 0,
    top: 12,
  },
});

const PanelTitle1 = styled('div')({
  fontSize: 20,
  color: '#595959',
  display: 'flex',
  alignItems: 'center',
});

const PanelTitle2 = styled('div')({
  fontSize: 20,
  color: '#595959',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '21px',

  [dimensions.SCREEN_MAX_XS]: {
    fontSize: 18,
  },
});

const FormContainer = styled('div')({
  maxWidth: 600,
  padding: '24px 40px',

  [dimensions.SCREEN_MAX_MD_2]: {
    padding: isMobile ? '14px 0' : '24px 40px',
    margin: '0 100px',
    width: '100%',
  },

  [dimensions.SCREEN_MAX_SM]: {
    padding: isMobile ? '14px 0 0 0' : '24px 40px',
    margin: '0',
    width: '100%',
  },
});

const FormPanel = styled(Panel)({
  backgroundColor: backgroundTheme.abandonmentBG,
  padding: 30,
  marginTop: 20,
  border: '2px solid #fff',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)',

  [dimensions.SCREEN_MAX_XS]: {
    padding: 15,
    margin: '15px auto',
    width: 'auto',
    border: '2px solid #fff',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)',
    paddingBottom: 30,
  },
});

const FormTitleBox = styled('div')({
  width: '100%',

  [dimensions.SCREEN_MAX_XS]: {
    textAlign: 'start',
  },
});

const CustomRadio = withStyles({
  root: {
    padding: 6,
    color: '#2B8FD7',
    '&:checked': {
      color: '#2B8FD7',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(props => <Radio color="default" {...props} />);

const AnchorLink = styled('a')({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#2490de',
});

const DescriptionText = styled(Text)({
  ...textColor.DARK_2,
  marginBottom: 10,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.32px',
});

const LabelText = styled(Text)({
  ...textColor.DARK_2,
  marginBottom: 0,
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '-0.28px',
});

const RedAsterisk = styled.span`
  font-size: 14px;
  color: #bc2028;
`;

const DeactivateTitle = styled('p')({
  ...textType.blockHeader,
  marginTop: 0,
  marginBottom: 0,
});

const ActionButtonContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  marginTop: 20,

  [dimensions.SCREEN_MAX_XS]: {
    flexDirection: 'column-reverse',
    justifyContent: 'space-evenly',
    height: 150,
  },
});

const Divider = styled('hr')({
  borderColor: '#8392A7',
  borderStyle: 'dashed',
  marginTop: isMobile ? 20 : 30,
  marginBottom: isMobile ? 20 : 30,
});

const StyleTextLink = styled(TextLink)({
  marginTop: 5,
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.24px',
  textDecoration: 'underline',
  display: 'inline-block',
  '&:hover': {
    textDecoration: 'none',
  },
});

const ModalParagraph = styled('p')({
  margin: 0,
  marginBottom: 10,
});

const customButtonStyle = {
  padding: '16px 32px',
  fontSize: isMobile ? 14 : 18,
  maxWidth: isMobile ? 255 : 330,
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: isMobile ? 600 : 700,
  lineHeight: '16px',
  width: isMobile ? 202 : 330,
  borderWidth: 2,
  marginBottom: 20,

  [dimensions.SCREEN_MAX_XS]: {
    width: '202px !important',
    maxWidth: '255px !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    borderWidth: '1px !important',
    marginBottom: 15,
  },
};

const CharacterCount = styled('div')({
  fontSize: 12,
  color: '#8f8f8f',
  letterSpacing: '-0.24px',
  display: 'flex',
  justifyContent: 'flex-end',
});

const AnimatedDiv = styled('div')(({ toggle }) => ({
  overflow: 'hidden',
  transformOrigin: 'top',
  maxHeight: toggle ? '1000px' : '0',
  opacity: toggle ? 1 : 0,
  transition: 'max-height 0.4s ease-in-out , opacity 0.7s ease-in-out',
}));

const LoaderWrapper = styled('div')({
  position: 'relative',
  height: 110,
});

const MAX_CHARACTER_COUNT = 500;

const GDPRDelete = ({ gdprDelete, errors, profile }: GDPRDeleteProps): any => {
  const [question, setQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [password, setPassword] = useState('');
  const [answer, setAnswer] = useState(0);
  const [improve, setImprove] = useState('');
  const [surveyAnswer, setSurveyAnswer] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { confirmActionModal, resetModal } = useModal();
  const navigate = useNavigate();

  const isGenerous = profile && profile.profile && profile.profile.data.account_type.toLowerCase() === 'generous';

  const fetchQuestions = async () => {
    const res = await fetchSurveyQuestions('delete_account');

    if (res && res.data) {
      setQuestion(res.data.data);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    mixpanelTrackDeletePageViewed();
  }, []);

  useEffect(() => {
    if (question) {
      setOptions(question.answers.data);
    }
  }, [question]);

  const handleSubmit = e => {
    e.preventDefault();

    const survey_id = question.id;
    const answer_id = answer;
    const raw_answer = options.find(item => item.id === answer_id);

    sendGDPRDeleteAction({
      reason: raw_answer,
      feedback: improve,
    });

    gdprDelete({
      password,
      survey_id,
      answer_id,
      improve,
      survey_answer: surveyAnswer,
    });
  };

  const passChange = e => {
    setPassword(e.target.value);
  };

  const onChangeAnswer = e => {
    setAnswer(parseInt(e.target.value));
    setSurveyAnswer('');
  };

  const onChangeImprove = e => {
    setImprove(e.target.value);
  };

  const handleClickShowPassword = () => {
    if (showPassword === true) {
      setShowPassword(false);
    }

    if (showPassword === false) {
      setShowPassword(true);
    }
  };

  const handleSurveyAnswerChange = e => {
    const newValue = e.target.value;

    if (newValue.length <= MAX_CHARACTER_COUNT) {
      setSurveyAnswer(newValue);
    }
  };

  const allowSubmit = Boolean(password) && Boolean(answer !== 0);

  const handleDeleteAccount = e => {
    confirmActionModal({
      dataTestID: 'deleting-your-account-modal',
      modalContentLabel: 'Deleting your account',
      modalIcon: (
        <SvgIcon component={Report} style={{ color: '#DD0B24', stroke: '#DD0B24', width: 40, fontSize: 40 }} />
      ),
      confirmationProps: {
        title: `Deleting your account`,
        message: (
          <>
            <ModalParagraph style={{ marginBottom: isGenerous ? 0 : 10 }}>This is permanent.</ModalParagraph>
            {isGenerous ? <ModalParagraph>Any unused credits WILL NOT be refunded.</ModalParagraph> : ''}
            <ModalParagraph>Are you sure?</ModalParagraph>
          </>
        ),
        confirmText: `Yes`,
        confirmAction: () => {
          const data: AccountDeletionStartedProps = {
            eventName: EVENT_NAMES.ACCOUNT_DELETION_STARTED,
            payload: {
              eventProperties: {
                Result: 'Delete My Account',
              },
            },
          };

          mixpanelTrackEvent(data);

          handleSubmit(e);
          resetModal();
        },
      },
    });
  };

  return (
    <Container
      css={{
        [dimensions.SCREEN_MAX_SM]: {
          padding: 0,
          display: 'flex',
          margin: '10px 10px',
        },
        [dimensions.SCREEN_MAX_XS]: {
          padding: 0,
          display: 'flex',
          margin: '10px 10px',
        },
      }}
    >
      <MainPanel withHeader darkMode={isDarkMode()}>
        <FormContainer>
          <Header style={{ position: 'relative' }}>
            <PanelTitle1 data-test-id="fs-modal-headline">
              <img
                src={`${s3BucketDirect}cart_abandonment/report_abandonment.svg`}
                height="30"
                width="30"
                alt="Report Abandonment Icon"
              />
              &ensp;
              <DeactivateTitle>DELETE Account</DeactivateTitle>
            </PanelTitle1>
            <GDPRIconContainer data-test-id="gdpr-watermark">
              <GDPRCompliantBorderlessIcon customWidth={isMobile ? 75 : 100} />
            </GDPRIconContainer>
          </Header>
          <DescriptionText>
            <strong>Deleting your account is permanent.</strong>
            Your privacy is important to us and protecting your personal data is a top priority. Deleting your account
            will remove your profile and its associated data and content from our servers.
          </DescriptionText>
          <DescriptionText>
            If you want to use WhatsYourPrice again, you will need to create a new account. We would recommend{' '}
            <AnchorLink
              onClick={() => {
                navigate('/deactivate');
                handleDeactivateAccountVysionEvent({
                  event: DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATING,
                });
              }}
            >
              deactivating
            </AnchorLink>{' '}
            your account instead if you plan to return.
          </DescriptionText>
          <FormPanel>
            <FormTitleBox>
              <PanelTitle2>
                <strong>We are sorry to see you leave us!</strong>
              </PanelTitle2>
              <br />
              <DescriptionText>
                If there is anything we can do to help improve what we’re doing at WhatsYourPrice, please let us know by
                filling out the short survey below.
              </DescriptionText>
              <DescriptionText>
                <strong>
                  Your answers are anonymous and will help us create better products. No personally identifiable data
                  will be retained.
                </strong>
              </DescriptionText>
            </FormTitleBox>
            <Divider />
            <FormControl style={{ width: '100%' }}>
              {question && options ? (
                <Fragment>
                  <FormLabel>
                    <LabelText>
                      {question && question.content}
                      <RedAsterisk>*</RedAsterisk>
                    </LabelText>
                  </FormLabel>
                  <RadioGroup onChange={onChangeAnswer} value={answer}>
                    {options &&
                      options.map((item, index) => {
                        const content = item.content.replace(':origin', `${MasterConfig.SITE_NAME}`);
                        return (
                          <div key={index}>
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              control={<CustomRadio />}
                              label={<LabelText>{content}</LabelText>}
                            />
                            {item.description && (
                              <AnimatedDiv toggle={answer === item.id}>
                                <TextArea
                                  placeholder={item.description}
                                  name="survey_answer"
                                  value={surveyAnswer}
                                  id="survey_answer"
                                  onChange={handleSurveyAnswerChange}
                                  resize="vertical"
                                  customInputStyle={{
                                    padding: 10,
                                    overflow: 'unset',
                                    border: 0,
                                    fontSize: 14,
                                    height: 41,
                                  }}
                                  customStyle={{ paddingBottom: 0, marginTop: 5 }}
                                />
                                <CharacterCount>
                                  {surveyAnswer.length}/{MAX_CHARACTER_COUNT}
                                </CharacterCount>
                              </AnimatedDiv>
                            )}
                          </div>
                        );
                      })}
                  </RadioGroup>
                </Fragment>
              ) : (
                <LoaderWrapper>
                  <Loader
                    active={true}
                    background={'#fff'}
                    topPosition={20}
                    style={{ width: '100%', height: 100 }}
                    text=""
                  />
                </LoaderWrapper>
              )}
            </FormControl>
            <Divider />
            <FormControl fullWidth style={{ paddingBottom: 0 }}>
              <FormLabel>
                <LabelText style={{ marginBottom: 4 }}>How can we improve?</LabelText>
              </FormLabel>
              <TextArea
                placeholder="Please provide feedback here..."
                name="improve"
                value={improve}
                id="improve"
                onChange={onChangeImprove}
                resize="vertical"
                customStyle={{ paddingBottom: 0 }}
                customInputStyle={{
                  padding: 10,
                  fontSize: 14,
                  overflow: 'unset',
                  border: 0,
                  height: 80,
                  '::placeholder': {
                    fontSize: 14,
                  },
                }}
              />
            </FormControl>
            <Divider />
            <FormControl fullWidth style={{ paddingBottom: 0 }}>
              <FormLabel>
                <LabelText style={{ marginBottom: 4 }}>
                  Current Password <RedAsterisk>*</RedAsterisk>
                </LabelText>
              </FormLabel>
              <Input
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={passChange}
                disableUnderline
                endAdornment={
                  <InputAdornment style={{ border: 0 }} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      style={{ color: isDarkMode() ? '#fff' : '#cfcfcf' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                placeholder="Enter your password to confirm account deletion"
                style={{
                  backgroundColor: isDarkMode() ? 'rgba(255,255,255,0.07)' : '#fff',
                  padding: 10,
                  color: isDarkMode() ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                  borderRadius: 3,
                  marginTop: '0',
                }}
                error={errors && errors.error_password_gdpr ? true : false}
              />
            </FormControl>
            <StyleTextLink to="/restoring">Forgot Your Password?</StyleTextLink>
            <Divider />
            <FormLabel style={{ textAlign: isMobile ? 'left' : 'center' }}>
              <LabelText style={{ marginTop: 10, color: '#BC2028' }}>*required</LabelText>
            </FormLabel>
            <ActionButtonContainer>
              <Button
                customStyle={{ ...customButtonStyle }}
                buttonType="secondaryBlue"
                onClick={() => {
                  const data: AccountDeletionStartedProps = {
                    eventName: EVENT_NAMES.ACCOUNT_DELETION_STARTED,
                    payload: {
                      eventProperties: {
                        Result: 'Keep my account',
                      },
                    },
                  };

                  mixpanelTrackEvent(data);

                  navigate('/dashboard');
                }}
              >
                Keep My Account
              </Button>
              <Button
                customStyle={{ ...customButtonStyle }}
                buttonType="deactivate"
                onClick={() => {
                  localStorage.setItem('came_from_delete_page', 'true');

                  const data: AccountDeletionStartedProps = {
                    eventName: EVENT_NAMES.ACCOUNT_DELETION_STARTED,
                    payload: {
                      eventProperties: {
                        Result: 'Deactivate Instead',
                      },
                    },
                  };

                  mixpanelTrackEvent(data);

                  handleDeactivateAccountVysionEvent({
                    event: DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATE_INSTEAD,
                  });

                  navigate('/deactivate');
                }}
              >
                Deactivate Instead
              </Button>
              <Button
                customStyle={{ ...customButtonStyle, color: '#FFF' }}
                buttonType="primaryRed"
                onClick={handleDeleteAccount}
                disabled={!allowSubmit}
              >
                DELETE My Account
              </Button>
            </ActionButtonContainer>
          </FormPanel>
        </FormContainer>
      </MainPanel>
    </Container>
  );
};

export default GDPRDelete;
