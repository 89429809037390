export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER';
export const REMOVE_NOTIFICATION_BANNER = 'REMOVE_NOTIFICATION_BANNER';
export const CLEAR_NOTIFICATION_BANNERS = 'CLEAR_NOTIFICATION_BANNERS';

export type NotificationBannerType = 'success' | 'warning' | 'error';

export type NotificationBannerItem = {
  id: string;
  message: string | React.ReactNode;
  type: NotificationBannerType;
  hideTimeout?: number;
};

export type NotificationBannerState = NotificationBannerItem[];

const initialState: NotificationBannerState = [];

export interface ShowBannerAction {
  type: typeof SHOW_NOTIFICATION_BANNER;
  payload: NotificationBannerItem;
}

export interface ClearNotificationBannersAction {
  type: typeof CLEAR_NOTIFICATION_BANNERS;
}

export interface RemoveNotificationBannerAction {
  type: typeof REMOVE_NOTIFICATION_BANNER;
  payload: {
    id: string;
  };
}

export const showNotificationBanner = (payload: NotificationBannerItem): ShowBannerAction => ({
  type: SHOW_NOTIFICATION_BANNER,
  payload,
});

export const removeNotificationBanner = (payload: { id: string }): RemoveNotificationBannerAction => ({
  type: REMOVE_NOTIFICATION_BANNER,
  payload,
});

export const clearNotificationBanners = (): ClearNotificationBannersAction => ({
  type: CLEAR_NOTIFICATION_BANNERS,
});

type Action = ShowBannerAction | ClearNotificationBannersAction | RemoveNotificationBannerAction;

const notificationBanners = (state = initialState, action: Action): NotificationBannerItem[] => {
  switch (action.type) {
    case SHOW_NOTIFICATION_BANNER:
      return [...state, { ...action.payload, hideTimeout: action.payload.hideTimeout || 3000 }];

    case REMOVE_NOTIFICATION_BANNER:
      return state.filter(banner => banner.id !== action.payload.id);

    case CLEAR_NOTIFICATION_BANNERS:
      return [];

    default:
      return state;
  }
};

export default notificationBanners;
