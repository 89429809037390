import axios, { AxiosPromise } from 'axios';
import queryString from 'query-string';
import MasterConfig from '../config/Master';
import {
  EVIDENTLY_FEATURES,
  EVIDENTLY_NEW_PROFILE_WALL_METRIC_KEYS,
  OffersMetrics,
  phoneNumberSignupMetrics,
} from '../constants/evidently';

export interface TrackEvidentlyMetricsParams<KeysType> {
  hashId?: string;
  experimentName: string;
  featureName: string;
  key: EVIDENTLY_NEW_PROFILE_WALL_METRIC_KEYS | OffersMetrics | phoneNumberSignupMetrics | KeysType;
  value: number;
  shouldAddUpValue?: boolean;
}

export interface SendEvidentlyMetricsParams {
  userId: string;
  details: { [key: string]: string | number };
}

const api = MasterConfig.api;

/**
 Gets evidently variation response
 **/
const fetchEvalFeature = (featureName: any): AxiosPromise => {
  const formData = {
    featureName: featureName,
  };

  return axios({
    method: 'POST',
    url: `${api}/evidently/evaluate-feature`,
    data: formData,
  });
};

/**
 Gets evidently variation response
 **/
const batchEvaluateFeature = ({
  userId,
  features,
}: {
  userId?: string;
  features: {
    feature: EVIDENTLY_FEATURES;
    entityId?: string;
    evaluationContext?: Record<string, unknown>;
  }[];
}): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/evidently/batch-evaluate-feature`,
    data: { userId, features },
  });
};

/**
 Send metrics on evidently variants
 **/
const sendEvidentlyMetrics = ({ userId, details }: SendEvidentlyMetricsParams): AxiosPromise => {
  const formData = {
    details,
    userDetails: {
      userId,
      sessionId: userId,
    },
  };

  return axios({
    method: 'POST',
    url: `${api}/evidently/send-metric`,
    data: formData,
  });
};

/**
 *
 * @param experiment string
 * @returns AxiosPromise
 */
const getEvidentlyExperiment = (experiment: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/evidently/experiment/${experiment}/get`,
  });
};

const doWebTimestampCheck = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/webTimestampCheck`,
  });
};

const fetchRandomUsername = (params: any): AxiosPromise => {
  const queryParams = queryString.stringify(params);

  return axios({
    method: 'GET',
    url: `${MasterConfig.NICKNAMER_URL}/v1/suggest-name?${queryParams}`,
    headers: { 'x-client-id': MasterConfig.NICKNAMER_CLIENT_ID },
    withCredentials: false,
  });
};

/**
 Track metrics on evidently variants
 **/
const trackEvidentlyMetrics = <KeysType>(formData: TrackEvidentlyMetricsParams<KeysType>): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/evidently/track-metric`,
    data: { ...formData },
  });
};

/**
 Track multiple metrics on evidently variants
 **/
const trackEvidentlyMultipleMetrics = <KeysType>(formData: TrackEvidentlyMetricsParams<KeysType>[]): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/evidently/track-multiple-metric`,
    data: {
      metrics: [...formData],
    },
  });
};

export {
  fetchEvalFeature,
  doWebTimestampCheck,
  sendEvidentlyMetrics,
  getEvidentlyExperiment,
  fetchRandomUsername,
  batchEvaluateFeature,
  trackEvidentlyMetrics,
  trackEvidentlyMultipleMetrics,
};
