import React from 'react';
import styled from '@emotion/styled';
import ButtonGroup from '../../../../atoms/buttons/ButtonGroup';
import Button from '../../../../atoms/buttons/Button';
import { EditOutlined } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from '../../../../atoms/Avatar';
import { backgroundTheme, buttonTheme, dimensions, textType } from '../../../../style';
import { getBrowserDetails, isDarkMode } from '../../../../utils/helpers';
import { auth10tixVerification } from '../../../../config/Master';
import { commonIcons } from '../../../../atoms/icons/materialIcons';
import useMixPanel from '../../../../hooks/useMixPanel';
import ArrowLeft from '../../../../atoms/icons/ArrowLeft';

const HeaderContainer = styled('div')({
  padding: 10,
  height: screen.width <= 280 ? 310 : 270,
});

const smallButtonsIcon = {
  fontSize: 14,
};

const themeDarkMode = isDarkMode();

const buttonStyles = {
  border: themeDarkMode ? '1px solid #6c6c6c' : '1px solid #2B8FD7',
  color: themeDarkMode ? '#f5f7fa' : '#2B8FD7',
  background: buttonTheme.MenuGreyAndWhite,
  boxShadow: themeDarkMode ? '1px 1px 4px rgba(4, 26, 42, 0.75)' : '1px 1px 4px #cbcbcb',
  ':hover': {
    background: !themeDarkMode ? '#CAE3F5' : '#041a2a',
    border: themeDarkMode ? '1px solid #aaa' : '1px solid #2B8FD7',
    color: themeDarkMode ? 'rgb(245, 247, 250)' : 'rgb(28,115,177)',
  },
  ':active': {
    background: !themeDarkMode ? '#2272AC' : '#041a2a',
    border: themeDarkMode ? '1px solid #aaa' : '1px solid #2272AC',
    color: themeDarkMode ? 'rgb(245, 247, 250)' : '#CAE3F5',
  },
};

const ButtonGroupContainer = styled('div')({
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  [dimensions.SCREEN_MAX_XS]: {
    textAlign: 'center',
    padding: 8,
  },
});

const StyledAvatar = styled(Avatar)(
  {
    marginTop: 40,
  },
  ({ isMobile }) => {
    return {
      marginLeft: isMobile ? screen.width / 2 - 72 : 142,
    };
  }
);

const AvatarName = styled('p')({
  fontSize: 26,
  textAlign: 'center',
  marginBlockStart: 15,
  marginBlockEnd: 15,
  marginInlineStart: 0,
  marginInlineEnd: 0,
  ...textType.blockHeader,
});

const BackArrowContainer = styled('div')({
  position: 'fixed',
  top: 16,
  borderRadius: '50%',
  width: 'auto',
  height: 'auto',
  boxShadow: themeDarkMode ? '1px 1px 4px rgba(4, 26, 42, 0.75)' : '1px 1px 4px #cbcbcb',
  background: themeDarkMode ? 'rgba(4, 26, 42, 0.75)' : '#FFF',
});

const SidebarHeader = (props: any): any => {
  const { avatar, username, handleSidebarToggle, hashId, isVerified } = props;
  const { trackPageView } = useMixPanel();
  return (
    <HeaderContainer id="sidebar-header">
      <BackArrowContainer style={getBrowserDetails().isMobile ? { right: 16 } : { left: 16 }}>
        <Button
          customStyle={{ padding: 15, height: 'unset' }}
          buttonType="link"
          onClick={() => handleSidebarToggle(false)}
          id="sidebar-close-button"
          data-test-id="sidebar-close-button"
        >
          <ArrowLeft
            width="14"
            color={backgroundTheme.iconFill}
            cssStyle={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: getBrowserDetails().isMobile
                ? 'translate(-50%, -50%)'
                : 'translate(-50%, -50%) rotateZ(180deg)',
              transformStyle: 'preserve-3d',
            }}
          />
        </Button>
      </BackArrowContainer>
      <Link
        data-test-id="cy-sidebar-profile-link"
        to={`/profile/${hashId}`}
        style={{ cursor: 'pointer' }}
        onClick={() => handleSidebarToggle(false)}
      >
        <StyledAvatar
          src={avatar}
          alt="profile"
          width={120}
          shape="circle"
          handleSidebarToggle={handleSidebarToggle}
          isMobile={getBrowserDetails().isMobile && !getBrowserDetails().isTablet}
          sidebar
        />
        <AvatarName>
          {username} {auth10tixVerification && isVerified && <commonIcons.check.badge />}
        </AvatarName>
      </Link>
      <ButtonGroupContainer>
        <ButtonGroup>
          <Button
            id="EditProfile"
            buttonType="normal"
            size="small"
            customStyle={buttonStyles}
            to={`/profile/${hashId}`}
            onClick={() => {
              handleSidebarToggle(false);
              trackPageView(true, 'menu_edit_profile');
            }}
          >
            <SvgIcon component={EditOutlined} style={smallButtonsIcon} />
            &nbsp;Edit Profile
          </Button>
        </ButtonGroup>
      </ButtonGroupContainer>
    </HeaderContainer>
  );
};

export default SidebarHeader;
