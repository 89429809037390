import React, { PureComponent } from 'react';

export default function(importComponent: any): any {
  class AsyncContainer extends PureComponent {
    state = {
      component: null,
    };

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const Component = this.state.component;

      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncContainer;
}
