import React from 'react';

const CvvIcon = ({ customStyle }: { customStyle?: React.CSSProperties }): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="26"
      viewBox="0 0 39 26"
      fill="none"
      style={{ ...customStyle }}
    >
      <g opacity="0.5" clipPath="url(#clip0_6851_5826)">
        <path
          d="M38.5032 4.19443V6.01052H0.496582V4.19443C0.496582 2.37332 1.96992 0.892578 3.78192 0.892578H35.2179C37.0302 0.892578 38.5032 2.37299 38.5032 4.19443Z"
          fill="#E8E8E8"
        />
        <path
          d="M38.5032 9.75391V21.8062C38.5032 23.6273 37.0302 25.1081 35.2179 25.1081H3.78192C1.96958 25.1081 0.496582 23.6277 0.496582 21.8062V9.75391H38.5029H38.5032Z"
          fill="#E8E8E8"
        />
        <path
          d="M35.218 0.392578H3.782C1.69667 0.392578 0 2.09778 0 4.1936V21.8067C0 23.9026 1.69667 25.6078 3.782 25.6078H35.218C37.3033 25.6078 39 23.9026 39 21.8067V4.1936C39 2.09778 37.3033 0.392578 35.218 0.392578ZM38.5033 21.8067C38.5033 23.6279 37.0303 25.1086 35.218 25.1086H3.782C1.96967 25.1086 0.496667 23.6282 0.496667 21.8067V9.75443H38.503V21.8067H38.5033ZM38.5033 6.00969H0.496667V4.1936C0.496667 2.37249 1.97 0.891743 3.782 0.891743H35.218C37.0303 0.891743 38.5033 2.37215 38.5033 4.1936V6.00969Z"
          fill="#494949"
        />
        <path
          d="M21.2388 13H2.98076C2.8436 13 2.73242 13.1117 2.73242 13.2496V17.4938C2.73242 17.6317 2.8436 17.7434 2.98076 17.7434H21.2388C21.3759 17.7434 21.4871 17.6317 21.4871 17.4938V13.2496C21.4871 13.1117 21.3759 13 21.2388 13Z"
          fill="#F9F9F9"
        />
        <path
          d="M36.0191 13H27.0765C26.9393 13 26.8281 13.1117 26.8281 13.2496V17.4938C26.8281 17.6317 26.9393 17.7434 27.0765 17.7434H36.0191C36.1563 17.7434 36.2675 17.6317 36.2675 17.4938V13.2496C36.2675 13.1117 36.1563 13 36.0191 13Z"
          fill="#F9F9F9"
        />
        <path
          d="M36.0189 17.9926H27.0763C26.8023 17.9926 26.5796 17.7688 26.5796 17.4934V13.2492C26.5796 12.9738 26.8026 12.75 27.0763 12.75H36.0189C36.2929 12.75 36.5156 12.9738 36.5156 13.2492V17.4934C36.5156 17.7688 36.2929 17.9926 36.0189 17.9926ZM27.0763 13.2492V17.4934H36.0193V13.2492H27.0763Z"
          fill="#E80748"
        />
        <path
          d="M29.1674 15.8494C29.4303 15.8494 29.6434 15.6352 29.6434 15.371C29.6434 15.1068 29.4303 14.8926 29.1674 14.8926C28.9045 14.8926 28.6914 15.1068 28.6914 15.371C28.6914 15.6352 28.9045 15.8494 29.1674 15.8494Z"
          fill="#353535"
        />
        <path
          d="M31.5478 15.8494C31.8107 15.8494 32.0238 15.6352 32.0238 15.371C32.0238 15.1068 31.8107 14.8926 31.5478 14.8926C31.2849 14.8926 31.0718 15.1068 31.0718 15.371C31.0718 15.6352 31.2849 15.8494 31.5478 15.8494Z"
          fill="#353535"
        />
        <path
          d="M33.9284 15.8494C34.1913 15.8494 34.4044 15.6352 34.4044 15.371C34.4044 15.1068 34.1913 14.8926 33.9284 14.8926C33.6655 14.8926 33.4524 15.1068 33.4524 15.371C33.4524 15.6352 33.6655 15.8494 33.9284 15.8494Z"
          fill="#353535"
        />
      </g>
      <defs>
        <clipPath id="clip0_6851_5826">
          <rect width="39" height="25.2155" fill="white" transform="translate(0 0.392578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CvvIcon;
