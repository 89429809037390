import React, { useState, useEffect, useCallback } from 'react';
import ModalWithPattern from '../components/common/modals/ModalWithPattern';
import { useApp } from '../contexts/AppContext';
import ConfirmationV2, { ConfirmationProps } from '../components/common/modals/ConfirmationV2';
import { IsMobileViewPort, getBrowserDetails } from '../utils/helpers';
import { MAX_MODAL_HEIGHT_DIFF } from '../constants/modal';
import omit from 'lodash/omit';
import TransactionAwareness from '../components/pages/Offers/TransactionAwareness';
import OfferModalFooter from '../components/pages/Offers/OfferModalFooter';
import ConfirmAction from '../components/common/modals/ConfirmAction';
import ConfirmModalSuccessIcon from '../atoms/icons/ConfirmModalSuccessIcon';
import { theme } from '../theme';
import borders from '../theme/borders';
import ConfirmModalErrorIcon from '../atoms/icons/ConfirmModalErrorIcon';
import colors from '../theme/colors';
import backgrounds from '../theme/backgrounds';
import TooManyPendingOffersModal from '../components/common/modals/TooManyPendingOffersModal';
import { MixpanelInfoModalShownSourcePage } from '../utils/mixpanel/infoModalShown';

export const doNotShowDeclineOfferConfirmationKey = 'wyp_doNotShowDeclineOfferConfirmation';

interface ConfirmActionModalProps {
  dataTestID: string;
  modalContentLabel: string;
  confirmationProps: ConfirmationProps;
  closeBtnDataTestId?: string | null;
  modalIcon?: any;
  centerAlign?: boolean;
  hideCloseBtn?: boolean;
  withCancel?: boolean;
  customStyle?: {
    [key: string]: unknown;
  };
  customOverlayStyle?: React.CSSProperties;
}

type ConfirmDeclineOffer = (params: {
  onConfirm(): void;
  isGenerous: boolean;
  username: string;
  onCancel?: () => void;
}) => void;

type ShowTooManyPendingOffersErrorModal = (params?: {
  onCloseCallback?: () => void;
  source?: MixpanelInfoModalShownSourcePage;
  type?: string;
}) => void;

interface UseModalProps {
  initModalLayout: any;
  confirmModal: any;
  basicModal: any;
  resetModal: () => void;
  resetModalPosition: () => void;
  setModalStateHandler: any;
  confirmActionModal: any;
  confirmSuccessModal: (params: ConfirmActionModalProps) => void;
  confirmErrorModal: (params: ConfirmActionModalProps) => void;
  confirmDeclineOffer: ConfirmDeclineOffer;
  showTooManyPendingOffersErrorModal: (params?: any) => void;
}

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

const useModal = (): UseModalProps => {
  const { modalState, setModalState, modalMobileOverideContentStyle, setModalMobileOverideContentStyle } = useApp();

  const [modalRef, setModalRef] = useState(null);

  const setModalStateHandler = newState => {
    setModalState(prevState => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  const setPositionModal = (heightDiff: number, currentModalContentHeight: number, currentWindowHeight: number) => {
    if (heightDiff <= MAX_MODAL_HEIGHT_DIFF) {
      setModalMobileOverideContentStyle({
        marginTop: MAX_MODAL_HEIGHT_DIFF,
        marginBottom: MAX_MODAL_HEIGHT_DIFF,
      });
    } else if (currentModalContentHeight > currentWindowHeight) {
      setModalMobileOverideContentStyle({
        marginTop: heightDiff,
      });
    } else {
      setModalMobileOverideContentStyle({});
    }
  };

  useEffect(() => {
    // auto detect & set the modal position if modal content overlaps to the screen
    // applies for mobile devices only
    if (modalRef && IsMobileViewPort) {
      const currentWindowHeight = window.innerHeight;
      const currentModalOverlayNode = modalRef?.node?.children[0];
      const currentModalContentHeight = currentModalOverlayNode?.children[0]?.clientHeight;

      if (currentModalContentHeight) {
        const heightDiff = Math.abs(currentModalContentHeight - currentWindowHeight);
        setPositionModal(heightDiff, currentModalContentHeight, currentWindowHeight);
        currentModalOverlayNode.scrollTo(0, 0);
      } else {
        setModalMobileOverideContentStyle({});
      }
    }
    return () => {
      setModalRef(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef, IsMobileViewPort]);

  const initModalLayout = useCallback(() => {
    const {
      dataTestID,
      isModalOpen,
      modalContentLabel,
      closeRequest,
      closeBtnClassName,
      closeBtnDataTestId,
      onCloseClick,
      children,
      hideCloseBtn,
      customOverlayStyle,
      customContentStyle,
      withoutBgPattern,
      closeOnOverlayClick,
      enableTouchMove,
    } = modalState;

    return (
      <ModalWithPattern
        customRef={node => setModalRef(node)}
        dataTestID={dataTestID}
        isModalOpen={isModalOpen}
        modalContentLabel={modalContentLabel}
        closeOnOverlayClick={closeOnOverlayClick}
        closeRequest={closeRequest}
        closeBtnClassName={closeBtnClassName}
        closeBtnDataTestId={closeBtnDataTestId}
        onCloseClick={onCloseClick}
        customOverlayStyle={IsMobileViewPort ? { ...customOverlayStyle, ...mobileOverlayStyle } : customOverlayStyle}
        customContentStyle={
          IsMobileViewPort
            ? { ...mobileContentStyle, ...customContentStyle, ...modalMobileOverideContentStyle }
            : customContentStyle
        }
        hideCloseBtn={hideCloseBtn}
        withoutBgPattern={withoutBgPattern}
        modalRef={modalRef}
        enableTouchMove={enableTouchMove}
      >
        {children}
      </ModalWithPattern>
    );
  }, [modalState, modalRef, setModalRef]);

  interface ConfirmModalProps {
    dataTestID: string;
    modalContentLabel: string;
    confirmationProps: ConfirmationProps;
    closeBtnDataTestId?: string | null;
    includeFooter?: boolean;
    includeAwarenessText?: boolean;
    includeDoNotShowAgainOption?: boolean;
    includeDoNotShowAgainLabel?: string;
    doNotShowAgainValue?: boolean;
  }

  const confirmModal = ({
    dataTestID,
    modalContentLabel,
    confirmationProps,
    closeBtnDataTestId,
    includeFooter,
    includeAwarenessText,
    includeDoNotShowAgainOption,
    includeDoNotShowAgainLabel,
    doNotShowAgainValue,
  }: ConfirmModalProps) => {
    const cancelAction = confirmationProps?.cancelAction ?? resetModal;

    const confirmModalState = {
      dataTestID: `${dataTestID}-modal`,
      closeBtnClassName: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      closeBtnDataTestId: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      isModalOpen: true,
      modalContentLabel,
      children: (
        <React.Fragment>
          <ConfirmationV2
            cancelAction={cancelAction}
            {...omit(confirmationProps, 'cancelAction')}
            includeDoNotShowAgainOption={includeDoNotShowAgainOption}
            includeDoNotShowAgainLabel={includeDoNotShowAgainLabel}
            doNotShowAgainValue={doNotShowAgainValue}
          />
          {includeAwarenessText && <TransactionAwareness />}
          {includeFooter && <OfferModalFooter />}
        </React.Fragment>
      ),
      closeRequest: cancelAction,
      onCloseClick: cancelAction,
    };

    setModalStateHandler(confirmModalState);
  };

  interface BasicModalProps {
    dataTestID: string;
    modalContentLabel: string;
    modalBody: React.ReactNode;
    isModalOpen?: boolean;
    closeBtnDataTestId?: string | null;
    onCloseClickHandler?: () => void;
    customOverlayStyle?: React.CSSProperties;
    customContentStyle?: React.CSSProperties;
    withoutBgPattern?: boolean;
    hideCloseBtn?: boolean;
    closeOnOverlayClick?: any;
    enableTouchMove?: boolean;
    closeRequest?: () => void;
  }

  const basicModal = ({
    dataTestID,
    modalContentLabel,
    modalBody,
    isModalOpen = true,
    closeBtnDataTestId,
    onCloseClickHandler,
    customOverlayStyle,
    customContentStyle,
    withoutBgPattern = false,
    hideCloseBtn = false,
    closeOnOverlayClick = true,
    enableTouchMove = true,
    closeRequest,
  }: BasicModalProps) => {
    setModalStateHandler({
      dataTestID: `${dataTestID}-modal`,
      closeBtnClassName: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      closeBtnDataTestId: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      isModalOpen,
      modalContentLabel,
      children: modalBody,
      closeRequest: () => {
        closeRequest && closeRequest();
        resetModal();
      },
      onCloseClick: () => {
        onCloseClickHandler && onCloseClickHandler();
        resetModal();
      },
      customOverlayStyle,
      customContentStyle,
      withoutBgPattern,
      hideCloseBtn,
      closeOnOverlayClick,
      enableTouchMove,
    });
  };

  const resetModal = () => {
    setModalState({
      dataTestID: '',
      isModalOpen: false,
      modalContentLabel: '',
      closeRequest: () => undefined,
      closeBtnClassName: '',
      closeBtnDataTestId: '',
      onCloseClick: () => undefined,
      children: <></>,
      hideCloseBtn: false,
      customOverlayStyle: {},
      customContentStyle: {},
      withoutBgPattern: false,
      enableTouchMove: true,
    });
    setModalMobileOverideContentStyle({});
  };

  const resetModalPosition = () => {
    // disable for mobile bug WYP-12492
    // if (IsMobileViewPort) {
    //   setModalMobileOverideContentStyle({});
    // }
  };

  const confirmActionModal = ({
    dataTestID,
    modalContentLabel,
    confirmationProps,
    closeBtnDataTestId,
    modalIcon,
    centerAlign,
    withCancel,
    hideCloseBtn,
    customStyle,
    customOverlayStyle,
  }: ConfirmActionModalProps) => {
    const cancelAction = confirmationProps?.cancelAction ?? resetModal;
    setModalStateHandler({
      dataTestID: `${dataTestID}-modal`,
      closeBtnClassName: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      closeBtnDataTestId: `${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`,
      isModalOpen: true,
      withoutBgPattern: true,
      hideCloseBtn: hideCloseBtn,
      centerAlign: false,
      modalContentLabel,
      customContentStyle: { maxWidth: 400, ...customStyle },
      customOverlayStyle,
      children: (
        <React.Fragment>
          <ConfirmAction
            closeBtnClassName={`${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`}
            closeBtnDataTestId={`${closeBtnDataTestId ? closeBtnDataTestId : dataTestID}-modal-close`}
            modalIcon={modalIcon}
            centerAlign={centerAlign}
            withCancel={withCancel}
            hideCloseBtn={hideCloseBtn}
            cancelAction={cancelAction}
            {...omit(confirmationProps, 'cancelAction')}
          />
        </React.Fragment>
      ),
      closeRequest: resetModal,
      onCloseClick: resetModal,
    });
  };

  const confirmSuccessModal = ({
    dataTestID,
    modalContentLabel,
    centerAlign = false,
    withCancel = false,
    hideCloseBtn = true,
    confirmationProps,
    ...rest
  }: ConfirmActionModalProps) => {
    confirmActionModal({
      confirmationProps,
      customStyle: { border: theme === 'dark' ? `1px solid ${borders.dark.primaryCard}` : 'inherit' },
      dataTestID,
      centerAlign,
      withCancel,
      hideCloseBtn,
      modalContentLabel,
      modalIcon: <ConfirmModalSuccessIcon />,
      ...rest,
    });
  };

  const confirmErrorModal = ({
    dataTestID,
    modalContentLabel,
    centerAlign = false,
    withCancel = false,
    hideCloseBtn = true,
    confirmationProps,
    ...rest
  }: ConfirmActionModalProps) => {
    confirmActionModal({
      customStyle: {
        border: `1px solid ${colors.light.red.warning}`,
        backgroundColor: backgrounds[theme].confirmErrorModal,
        boxShadow: '0px 15px 37px 7px rgba(0, 0, 0, 0.05)',
      },
      confirmationProps,
      dataTestID,
      centerAlign,
      withCancel,
      hideCloseBtn,
      modalContentLabel,
      modalIcon: <ConfirmModalErrorIcon />,
      ...rest,
    });
  };

  const confirmDeclineOffer: ConfirmDeclineOffer = ({ onConfirm, isGenerous, username, onCancel }) => {
    const doNotShowDeclineOfferConfirmation = localStorage.getItem(doNotShowDeclineOfferConfirmationKey);
    const shouldShowConfirmation = !doNotShowDeclineOfferConfirmation || doNotShowDeclineOfferConfirmation === 'false';

    if (shouldShowConfirmation) {
      const doNotShowAgainValue = !doNotShowDeclineOfferConfirmation || doNotShowDeclineOfferConfirmation === 'true';

      confirmModal({
        doNotShowAgainValue,
        dataTestID: 'offer-cancel-sent-offers',
        modalContentLabel: 'Received Ignore',
        includeDoNotShowAgainOption: true,
        confirmationProps: {
          title: `Decline ${isGenerous ? 'Offer' : 'Request'}?`,
          message: `Are you sure you want to <strong>Decline</strong> the ${
            isGenerous ? 'offer' : 'request'
          } from <strong>${username}</strong>?`,
          confirmText: `Decline ${isGenerous ? 'Offer' : 'Request'}`,
          confirmAction: doNotShowAgain => {
            if (typeof doNotShowAgain !== 'undefined') {
              localStorage.setItem(doNotShowDeclineOfferConfirmationKey, doNotShowAgain.toString());
            }
            onConfirm();
          },
          cancelAction: onCancel,
        },
      });
    } else {
      onConfirm();
    }
  };

  const showTooManyPendingOffersErrorModal: ShowTooManyPendingOffersErrorModal = params => {
    const { onCloseCallback, source, type } = params || {};

    setModalStateHandler({
      dataTestID: 'tooManyPendingOffersModal',
      closeBtnClassName: 'tooManyPendingOffersModal-modal-close',
      closeBtnDataTestId: 'tooManyPendingOffersModal-modal-close',
      isModalOpen: true,
      withoutBgPattern: true,
      hideCloseBtn: true,
      centerAlign: false,
      customContentStyle: getBrowserDetails().scWidth <= 480 ? { maxWidth: 315, margin: '0 auto' } : { maxWidth: 400 },
      children: (
        <TooManyPendingOffersModal
          source={source}
          onClose={() => {
            resetModal();
            onCloseCallback?.();
          }}
          type={type}
        />
      ),
      closeRequest: () => {
        resetModal();
        onCloseCallback?.();
      },
      onCloseClick: () => {
        resetModal();
        onCloseCallback?.();
      },
    });
  };

  return {
    initModalLayout,
    confirmModal,
    basicModal,
    resetModal,
    resetModalPosition,
    setModalStateHandler,
    confirmActionModal,
    confirmSuccessModal,
    confirmErrorModal,
    confirmDeclineOffer,
    showTooManyPendingOffersErrorModal,
  };
};

export default useModal;
