export const LOGIN_USER = 'LOGIN_USER';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const DONE_REFRESHING_TOKEN = 'DONE_REFRESHING_TOKEN';
export const RESET_TOKEN_VALIDITY = 'RESET_TOKEN_VALIDITY';
export const STORE_WEB_TIMESTAMP = 'STORE_WEB_TIMESTAMP';
export const GDPR_ACKNOWLEDGED = 'GDPR_ACKNOWLEDGED';
export const GDPR_OPEN_STEP2 = 'GDPR_OPEN_STEP2';
export const REMOVE_STALE_HTTP_REQUESTS = 'REMOVE_STALE_HTTP_REQUESTS';
export const STORE_HTTP_REQUEST = 'STORE_HTTP_REQUEST';
export const REMOVE_HTTP_REQUEST = 'REMOVE_HTTP_REQUEST';
export const SET_VIRGIL = 'SET_VIRGIL';
export const UNSET_VIRGIL = 'UNSET_VIRGIL';
export const STORE_RELOADED = 'STORE_RELOADED';
export const AUTH_REMOVE_BLOCK_PROPERTIES = 'AUTH_REMOVE_BLOCK_PROPERTIES';
export const IS_FETCHING = 'IS_FETCHING';
export const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

export interface FreshTokenPromise {
  freshTokenPromise: Record<string, unknown> | null;
}

export interface ResetTokenValidity {
  resetTokenValidity: boolean;
}

export interface WebTimestamp {
  webTimestamp: string;
  bundleUpdate?: boolean;
}

export interface Location {
  loc?: string | null;
}

export interface HttpRequest extends Location {
  time: number;
}

export interface RequestList {
  requestList: HttpRequest[];
}

export interface JWTToken {
  jwToken: string;
}

export interface UserInfo {
  token?: JWTToken;
  gdpr_acknowledged: boolean | null | undefined;
  password_disabled: boolean;
}

export interface AuthState
  extends FreshTokenPromise,
    ResetTokenValidity,
    WebTimestamp,
    RequestList,
    UserInfo,
    JWTToken {
  isAuthenticated: boolean;
  gdpr_Step2: boolean;
  jwtUnixExpiration: string;
  virgil: boolean;
  isRecentlyReloaded: boolean;
  tokenRefreshing: boolean;
  isFetching: boolean;
}

interface LOGIN_USER_ACTION {
  type: typeof LOGIN_USER;
  payload: UserInfo;
}

interface REFRESHING_TOKEN_ACTION {
  type: typeof REFRESHING_TOKEN;
  payload: FreshTokenPromise;
}

interface DONE_REFRESHING_TOKEN_ACTION {
  type: typeof DONE_REFRESHING_TOKEN;
}

interface RESET_TOKEN_VALIDITY_ACTION {
  type: typeof RESET_TOKEN_VALIDITY;
  payload: ResetTokenValidity;
}

interface STORE_WEB_TIMESTAMP_ACTION {
  type: typeof STORE_WEB_TIMESTAMP;
  payload: WebTimestamp;
}

interface GDPR_ACKNOWLEDGED_ACTION {
  type: typeof GDPR_ACKNOWLEDGED;
}

interface GDPR_OPEN_STEP2 {
  type: typeof GDPR_OPEN_STEP2;
}

interface REMOVE_STALE_HTTP_REQUESTS_ACTION {
  type: typeof REMOVE_STALE_HTTP_REQUESTS;
}

interface STORE_HTTP_REQUEST_ACTION {
  type: typeof STORE_HTTP_REQUEST;
  payload: Location;
}

interface REMOVE_HTTP_REQUEST_ACTION {
  type: typeof REMOVE_HTTP_REQUEST;
  payload: Location;
}

interface SET_VIRGIL_ACTION {
  type: typeof SET_VIRGIL;
}

interface UNSET_VIRGIL_ACTION {
  type: typeof UNSET_VIRGIL;
}

interface STORE_RELOADED_ACTION {
  type: typeof STORE_RELOADED;
}

interface AUTH_REMOVE_BLOCK_PROPERTIES_ACTION {
  type: typeof AUTH_REMOVE_BLOCK_PROPERTIES;
}

export type AuthActionTypes =
  | LOGIN_USER_ACTION
  | REFRESHING_TOKEN_ACTION
  | DONE_REFRESHING_TOKEN_ACTION
  | RESET_TOKEN_VALIDITY_ACTION
  | STORE_WEB_TIMESTAMP_ACTION
  | GDPR_ACKNOWLEDGED_ACTION
  | GDPR_OPEN_STEP2
  | REMOVE_STALE_HTTP_REQUESTS_ACTION
  | STORE_HTTP_REQUEST_ACTION
  | REMOVE_HTTP_REQUEST_ACTION
  | SET_VIRGIL_ACTION
  | UNSET_VIRGIL_ACTION
  | STORE_RELOADED_ACTION
  | AUTH_REMOVE_BLOCK_PROPERTIES_ACTION
  | IS_FETCHING;
