import React, { ReactElement } from 'react';

const DashboardDiscoverModeCrossIcon = ({
  customStyle,
  ...rest
}: {
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      style={{ ...customStyle }}
      {...rest}
    >
      <path
        d="M2.25746 27L0 24.7425L11.1931 13.5L0 2.25746L2.25746 0L13.5 11.1931L24.7425 0L27 2.25746L15.8069 13.5L27 24.7425L24.7425 27L13.5 15.8069L2.25746 27Z"
        fill="#BC2028"
      />
    </svg>
  );
};

export default DashboardDiscoverModeCrossIcon;
