import uuidv4 from 'uuid';

import {
  CLEAR_DDC_DATA,
  DDCActionTypes,
  IDDC,
  IDDCData,
  IDDCResponse,
  ISaveDataCollectorPayload,
  SAVE_DATA_COLLECTOR,
  UPDATE_DDC_IFRAME_LOADED,
  UPDATE_FINGER_PRINT_START_TIME,
  UPDATE_FINGER_PRINT_STATUS,
} from './types/DDC.d';
import { GATEWAY as GATEWAY_ENUM } from '../utils/billingEnum';
import { logException } from '../utils/helpers';

type FormattingDataCollectorResponse = (payload: ISaveDataCollectorPayload) => IDDCData[];

type DDCReducer = (state: IDDC, action: DDCActionTypes) => IDDC;

const initialState: IDDC = {
  ddcData: [],
  bin: '',
  initialIDs: {},
  isAllFingerPrintSaved: false,
};

const handleFormattingDataCollectorResponse: FormattingDataCollectorResponse = ({ ddcResponse, bin }) =>
  ddcResponse.map(
    ({ DDC_URL, DDC_REFERENCEID, JWT, PMT_BIN, GATEWAY }): IDDCData => {
      /**
       * As agreed with U2P if response of INOVIO_PAY doesn't have DDC_REFERENCEID we are going ignore it.
       * Because that only way for FE to differentiate the gateway.
       */
      let isInvalidResponse = (GATEWAY === GATEWAY_ENUM.INOVIO_PAY && !DDC_REFERENCEID) || false;

      if (!DDC_URL || !JWT) {
        isInvalidResponse = true;

        logException(
          'DDC',
          {
            step: `DDC response gateway: ${GATEWAY}`,
            ddcResponse,
          },
          'info'
        );
      }

      return {
        ddcUrl: DDC_URL,
        initialDdcReferenceId: DDC_REFERENCEID,
        ddcReferenceId: DDC_REFERENCEID,
        gateway: GATEWAY,
        jwt: JWT,
        bin: bin || PMT_BIN,
        iFrameKey: uuidv4(),
        shouldRenderIframe: !isInvalidResponse,
        isSaved: false,
        status: isInvalidResponse ? 'INVALID_RESPONSE' : undefined,
        isSubmitted: false,
        isIFrameLoaded: false,
      };
    }
  );

const getGatewayBin = (ddcData: IDDCResponse[]) => {
  let bin: any;
  ddcData.forEach(ddc => {
    bin = bin || ddc.PMT_BIN;
  });
  return bin;
};

const getInitialIDs = (ddcData: IDDCResponse[]) => {
  const initialIDs: Record<string, string> = {};
  ddcData.forEach(ddc => {
    initialIDs[ddc.GATEWAY] = ddc.DDC_REFERENCEID;
  });

  return initialIDs;
};

const ddc: DDCReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_COLLECTOR:
      return {
        ...state,
        ddcData: handleFormattingDataCollectorResponse(action.payload),
        initialIDs: getInitialIDs(action.payload.ddcResponse),
        bin: action.payload.bin || getGatewayBin(action.payload.ddcResponse),
      };

    case UPDATE_FINGER_PRINT_STATUS:
      return {
        ...state,
        ddcData: state.ddcData.map(
          (data): IDDCData => {
            if (data.gateway === action.gateway) {
              return {
                ...data,
                ddcReferenceId: action.ddcReferenceId || data.ddcReferenceId,
                isSaved: true,
                status: 'SUCCESS',
              };
            }
            return data;
          }
        ),
        isAllFingerPrintSaved: state.ddcData.every(
          data => data.isSaved || action.gateway === data.gateway || !data.shouldRenderIframe
        ),
      };

    case UPDATE_DDC_IFRAME_LOADED:
      return {
        ...state,
        ddcData: state.ddcData.map(data => {
          if (data.gateway === action.gateway) {
            return {
              ...data,
              isIFrameLoaded: true,
            };
          }
          return data;
        }),
      };

    case UPDATE_FINGER_PRINT_START_TIME:
      return {
        ...state,
        ddcData: state.ddcData.map(
          (data): IDDCData => {
            if (data.gateway === action.gateway) {
              return {
                ...data,
                startedTime: Date.now(),
                isSubmitted: true,
              };
            }
            return data;
          }
        ),
      };

    case CLEAR_DDC_DATA:
      return initialState;

    default:
      return state;
  }
};

export default ddc;
