import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
  component: 'Skip',
};

export const enum JoinViewVysionEventsEnum {
  VIEW_ABOUT_SKIP = 'joinAboutSkip',
  VIEW_PHOTO_UPLOAD_SKIP = 'joinPhotoUploadSkip',
  VIEW_PHONE_ENTER_VERIFICATION = 'joinPhoneEnterVerification',
  VIEW_EDIT_PHONE_NUMBER_MODAL = 'joinEditPhoneNumberModal',
  VIEW_VERIFICATON_MODAL = 'joinNewVerification',
  VIEW_SUCCESSFULLY_VERIFIED = 'joinSuccessfullyVerified',
}

export const JoinViewVysionEvents = {
  [JoinViewVysionEventsEnum.VIEW_ABOUT_SKIP]: {
    ...commonValues,
    page: 'About',
    eventName: 'join-about-skip',
  },
  [JoinViewVysionEventsEnum.VIEW_PHOTO_UPLOAD_SKIP]: {
    ...commonValues,
    page: 'Photo Upload',
    eventName: 'join-photoUpload-skip',
  },
  [JoinViewVysionEventsEnum.VIEW_PHONE_ENTER_VERIFICATION]: {
    ...commonValues,
    page: 'Join',
    eventName: 'join-enterVerificationCode',
    component: 'Enter Verification Code',
  },
  [JoinViewVysionEventsEnum.VIEW_EDIT_PHONE_NUMBER_MODAL]: {
    ...commonValues,
    page: 'Join',
    eventName: 'join-enterANewNumber',
    component: 'Enter a New Number Modal',
  },
  [JoinViewVysionEventsEnum.VIEW_VERIFICATON_MODAL]: {
    ...commonValues,
    page: 'Join',
    eventName: 'join-newVerification',
    component: 'New Verification Modal',
  },
  [JoinViewVysionEventsEnum.VIEW_SUCCESSFULLY_VERIFIED]: {
    ...commonValues,
    page: 'Join',
    eventName: 'join-successfullyVerified',
    component: 'Phone Number Verified Success Modal',
  },
};
