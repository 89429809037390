import React, { PureComponent } from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')(
  {
    fill: 'rgb(118, 118, 118)',
    height: '16px',
    width: '16px',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

class CloseIcon extends PureComponent {
  render(): any {
    const { customStyle } = this.props;

    return (
      <Icon viewBox="0 0 24 24" aria-hidden="true" customStyle={customStyle}>
        <path
          fillRule="evenodd"
          d="M23.25 24a.744.744 0 0 1-.53-.22L12 13.062 1.28 23.782a.75.75 0 0 1-1.06-1.06L10.94 12 .22 1.28A.75.75 0 1 1 1.28.22L12 10.94 22.72.22a.749.749 0 1 1 1.06 1.06L13.062 12l10.72 10.72a.749.749 0 0 1-.53 1.28"
        />
      </Icon>
    );
  }
}

CloseIcon.defaultProps = {
  customStyle: {},
};

export default CloseIcon;
