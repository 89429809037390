import { AccountDataObject } from '../../models/Account';

export const STORE_DISCOVER_MODE_ACCOUNTS = 'STORE_DISCOVER_MODE_ACCOUNTS';
export const REMOVE_DISCOVER_MODE_ACCOUNTS = 'REMOVE_DISCOVER_MODE_ACCOUNTS';
export const RESET_DISCOVER_MODE_ACCOUNTS = 'RESET_DISCOVER_MODE_ACCOUNTS';
export const UPDATE_DISCOVER_MODE_ACCOUNTS = 'UPDATE_DISCOVER_MODE_ACCOUNTS';

type AccountType = AccountDataObject & { status?: 'viewed' | 'offer_sent' };

export interface DiscoverModeAccounts {
  DiscoverModeAccounts: AccountType[];
  DiscoverModeAccountsExpiration: number;
}

export interface DashboardState extends DiscoverModeAccounts {
  isFetching: boolean;
}
export interface STORE_DISCOVER_MODE_ACCOUNTS_ACTION {
  type: typeof STORE_DISCOVER_MODE_ACCOUNTS;
  payload: DiscoverModeAccounts;
}

export interface UPDATE_DISCOVER_MODE_ACCOUNTS_ACTION {
  type: typeof UPDATE_DISCOVER_MODE_ACCOUNTS;
  payload: DiscoverModeAccounts;
}

export interface RESET_DISCOVER_MODE_ACCOUNTS {
  type: typeof RESET_DISCOVER_MODE_ACCOUNTS;
}

export interface REMOVE_DISCOVER_MODE_ACCOUNTS_ACTION {
  type: typeof REMOVE_DISCOVER_MODE_ACCOUNTS;
  payload: string;
}

export type DashboardActionType =
  | STORE_DISCOVER_MODE_ACCOUNTS_ACTION
  | REMOVE_DISCOVER_MODE_ACCOUNTS_ACTION
  | UPDATE_DISCOVER_MODE_ACCOUNTS_ACTION
  | RESET_DISCOVER_MODE_ACCOUNTS;
