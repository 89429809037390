import React, { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TextInput from '../../atoms/TextInput';
import Button from '../../atoms/buttons/Button';
import { Select, CountrySelect } from '../../atoms/Select';
import { dimensions, statusColor, inputTheme, creditCardInputField } from '../../style';
import SvgQuestion from '../../atoms/icons/SvgQuestion';
import InputLabel from '../../atoms/InputLabel';
import CreditCardInput from 'react-credit-card-input';
import Tooltip from 'rc-tooltip';
import { getBrowserDetails, renderTaxLabel } from '../../utils/helpers';
import LockIcon from '@material-ui/icons/Lock';
import AfterPayment from './AfterPayment';
import PostalLookup from '../../atoms/PostalLookup';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import { useStateCallback } from '../../utils/customHooks';
import { countryWithTaxByProvince } from '../../config/Master';
import { checkCardExpiry } from '../../utils/helpers';
import '../../style/customCC.css';
import { handlePaymentVysionEvent } from '../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { PaymentOnFocusVysionEventsEnum } from '../../utils/vysion/payment/onFocus';
import Checkbox from '../../atoms/Checkbox';
import {
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  EVIDENTLY_MELISSA_METRIC_KEYS,
  trackEvidentlyMetric,
} from '../../constants/evidently';
import BillingTermsAndCondition from './BillingTermsAndCondition';

const currentYear = new Date().getFullYear();

const ExpAndCode = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  [dimensions.SCREEN_MAX_XS]: {
    flexDirection: 'column',
  },
});

const StatusMessage = styled('div')(
  {
    marginTop: '-20px',
  },
  ({ status }) => ({
    color: statusColor[status],
  })
);

const BillingAddressSection = styled('section')({});

const BillingAddressHeading = styled('h4')({
  display: 'block',
  marginTop: 0,
  fontWeight: 700,
  position: 'relative',
  marginBottom: 14,
});

const ExpDateContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  maxWidth: '276px',
  position: 'relative',
});

const FormSection = styled('div')(({ newCard }) =>
  newCard !== true
    ? {
        padding: '10px 10px',
        borderRadius: 5,
        marginBottom: 25,
      }
    : {
        marginBottom: 10,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTop: 0,
        padding: '10px 10px',
      }
);

const AutocompleteContainer = styled('div')({});

const PayWithCardButton = styled(Button)({
  margin: '0 auto',
  backgroundColor: 'rgb(19, 131, 210)',
  padding: '16px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
  height: 51,
  width: 232,
  borderRadius: 90,
});

const mobileOrtablet = () => getBrowserDetails().isMobile || getBrowserDetails().isTablet;

const notBigTablets = () => screen.width >= 1080;

const notTabletORMobile = () => (notBigTablets() ? 20 : 50);

const setLabelTooltipTop = caseNo => {
  switch (caseNo) {
    case 1:
      return 43;
    case 2:
      return 40;
    case 3:
      return 40;
    case 4:
      return 38;
    default:
      return;
  }
};

const setCaseNo = () => {
  switch (true) {
    case getBrowserDetails().isIos && getBrowserDetails().isMobile:
      return 1;
    case mobileOrtablet():
      return 2;
    case notBigTablets():
      return 3;
    default:
      return 4;
  }
};

const LabelToolTip = styled('span')({
  position: 'absolute',
  cursor: 'pointer',
  right: mobileOrtablet() ? 15 : notTabletORMobile(),
  top: setLabelTooltipTop(setCaseNo()),
  zIndex: 10,
  width: 30,
  marginRight: mobileOrtablet() ? 5 : 0,
});

const dropDownOptions = (start, end, defaultValue) => {
  const options = [];
  options.push(
    <option selected key={defaultValue} value="" defaultValue={defaultValue} disabled>
      {defaultValue}
    </option>
  );

  for (let i = start; i <= end; i++) {
    options.push(
      <option value={i} key={i.toString()}>
        {i}
      </option>
    );
  }
  return options;
};

const getStatus = status => {
  return status && status.status ? status.status : 'default';
};

const getStatusMessage = status => {
  return status && status.message ? status.message : null;
};

const getExpDateStatus = (month, year) => {
  const expDateHasStatus = statusType => {
    return month.status === statusType || year.status === statusType;
  };

  // status of exp date dropdown will be determined by the dropdown with
  // the highest priority status
  // i.e. if one dropdown is marked as 'error', both exp date dropdowns are
  // marked as 'error'
  // priority: error > caution > success > default
  if (expDateHasStatus('error')) {
    return 'error';
  } else if (expDateHasStatus('caution')) {
    return 'caution';
  } else if (expDateHasStatus('success')) {
    return 'success';
  } else return 'default';
};

const renderExpStatusMessage = (month, year, status) => {
  const message = (month.status === status && month.message) || (year.status === status && year.message);

  if (message && status !== 'default') {
    return <StatusMessage status={status}>{message}</StatusMessage>;
  } else return null;
};

const verifyStatus = (status, props) => {
  return status && getStatus(status[props]);
};

const verifyStatusMessage = (status, props) => {
  return status && getStatusMessage(status[props]);
};

interface BillingFormProps {
  status: any;
  cardNumber: any;
  cardExpMonth: any;
  cardExpYear: any;
  cardCVV: any;
  onInputChange: any;
  onAddressChange: any;
  handleCountryChange: any;
  handleSubmit: any;
  nameOnCard: any;
  billingAddress: any;
  billingCity: any;
  billingState: any;
  billingCountry: any;
  billingZip: any;
  saveCard: any;
  storyMode: any;
  totalAmount: any;
  currencyCharCode: any;
  selectedPackage: any;
  descriptors: any;
  newCard: any;
  userCountry: any;
  handleUpdateMonthYrInput: any;
  discountAmount: any;
  disablePurchase: any;
  onSelectSaveCard: any;
  recurring: any;
}

const BillingForm = ({
  status,
  cardNumber,
  cardExpMonth,
  cardExpYear,
  cardCVV,
  onInputChange,
  onAddressChange,
  handleCountryChange,
  handleSubmit,
  nameOnCard,
  billingAddress,
  billingCity,
  billingState,
  billingCountry,
  billingZip,
  storyMode = false,
  totalAmount,
  currencyCharCode,
  selectedPackage,
  descriptors,
  newCard,
  userCountry,
  handleUpdateMonthYrInput,
  discountAmount,
  onSelectSaveCard,
  saveCard,
  recurring,
}: BillingFormProps): ReactElement => {
  const [cardExpiry, setCardExpiry] = useState('');
  const [currStatus] = useState(status);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [myCardNumber, setMyCardNumber] = useState('');
  const [disableBtn, setDisableBtn] = useStateCallback(true);
  const [disableState, setDisableState] = useState(false);
  const [hasError, setHasError] = useState(false);
  const mounted = useRef();

  const onChangeAddress = value => {
    //this will admit letters, numbers, spaces and dashes, but not two dashes or spaces in a row
    const regex = /^(?![\s-])(?!.*[\s-]{2})[\w\s-]+$/;

    if (value.match(regex) || value === '') {
      onInputChange('billing_zip', value);
    }
  };

  const locationInputProps = {
    value: billingZip,
    id: 'billing_zip',
    placeholder: '',
    type: 'text',
    onChange: onChangeAddress,
    onFocus: () => {
      handlePaymentVysionEvent({
        action: VYSION_ACTIONS.ON_FOCUS,
        event: PaymentOnFocusVysionEventsEnum.ZIP_POSTAL_CODE_ENTRY,
      });
    },
    status: status && getStatus(status.use2pay_billing_zip),
    statusMessage: status && getStatusMessage(status.use2pay_billing_zip),
    noBorder: true,
  };

  const expiryVal = checkCardExpiry(cardExpiry, cardExpMonth, cardExpYear);

  const renderCardErrorMsg = (type, value) => {
    switch (type) {
      case 'cardNumber':
        return value === '' ? 'Credit card number is required.' : 'Enter a valid credit card number.';
      case 'expiryDate':
        return value === '' ? 'Expiry date is required.' : 'Expiry date is invalid.';
      case 'cvc':
        return value === '' ? 'Security code is required.' : 'CVC is invalid.';
      default:
        return;
    }
  };

  const expDateStatus =
    status && status.use2pay_card_exp_month && status.use2pay_card_exp_year
      ? getExpDateStatus(status.use2pay_card_exp_month, status.use2pay_card_exp_year)
      : 'default';

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      checkAllowSubmit();
      mounted.current = true;
    }
  });

  useEffect(() => {
    if (status !== currStatus) {
      validateRequiredFields();
    }
  }, [status, currStatus, validateRequiredFields]);

  const checkAllowSubmit = () => {
    const reqValues = cardNumber && cardExpMonth && cardExpYear && cardCVV;
    setAllowSubmit(!!reqValues);
  };

  const inputOnChange = e => {
    setDisableBtn(false);
    checkAllowSubmit();
    onInputChange(e.target.id, e.target.value);
  };

  const onStateChange = e => {
    if (countryWithTaxByProvince.split(',').includes(billingCountry)) {
      onAddressChange({
        use2pay_billing_state: e.target.value,
      });
    }
  };

  const onCardChange = (targetId, e) => {
    setDisableBtn(false);
    const inputVal = e.target.value;
    if (targetId === 'card_number') {
      setMyCardNumber(inputVal);
    }
    if (targetId === 'card_expiry') {
      setCardExpiry(inputVal);
      if (inputVal.length === 7) {
        const valArr = inputVal.split(' / ');
        handleUpdateMonthYrInput(parseInt(valArr[0]), parseInt(`20${valArr[1]}`));
        checkAllowSubmit();
      }
      return;
    }
    onInputChange(targetId, inputVal);
    checkAllowSubmit();
  };

  const onCardError = () => {
    setAllowSubmit(false);
    setDisableBtn(true);
  };

  const onCountryChange = e => {
    handleCountryChange(e.target.value);
    setDisableState(false);
  };

  const onHandleSubmit = e => {
    e.preventDefault();
    if (!allowSubmit) {
      setDisableBtn(true, () => document.getElementById('card_number').focus());
      return;
    }

    // No error on 1st attempt
    // Send evidently metric
    if (![billingCountry, billingCity, billingState, billingAddress].includes('') && !hasError) {
      trackEvidentlyMetric(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA, {
        experimentName: EVIDENTLY_EXPERIMENTS.MELISSA,
        featureName: EVIDENTLY_FEATURES.MELISSA,
        key: EVIDENTLY_MELISSA_METRIC_KEYS.PURCHASED_FROM_1ST_ATTEMPT,
        value: 1,
      });
    }

    handleSubmit();
  };

  const onSelectLocation = myAddress => {
    setDisableBtn(false);
    const newAddress = {
      use2pay_billing_state: myAddress.state_code,
      use2pay_billing_city: myAddress.city,
      use2pay_billing_zip: myAddress.zip_code,
    };
    onAddressChange(newAddress);
    checkAllowSubmit();

    if (countryWithTaxByProvince.split(',').includes(myAddress.country_code) && myAddress.state_code) {
      setDisableState(true);
    } else {
      setDisableState(false);
    }
  };

  const validateRequiredFields = useCallback(() => {
    switch (true) {
      case !!status.use2pay_card_number:
      case !!status.use2pay_card_cvv:
        setHasError(true);
        document.getElementById('card_number').focus();
        break;
      case !!status.use2pay_billing_name_on_card:
        setHasError(true);
        document.getElementById('billing_name_on_card').focus();
        break;
      case !!status.use2pay_billing_zip:
        setHasError(true);
        document.getElementById('billing_zip').focus();
        break;
      case !!status.use2pay_billing_city:
        setHasError(true);
        document.getElementById('billing_city').focus();
        break;
      case !!status.use2pay_billing_address:
        setHasError(true);
        document.getElementById('billing_address').focus();
        break;
      case !!status.use2pay_billing_phone:
        setHasError(true);
        document.getElementById('billing_phone').focus();
        break;
      default:
        setHasError(false);
        break;
    }
  }, [status]);

  const verifyValidCardNumber = () => {
    return myCardNumber && myCardNumber.split(' ').length > 1 ? '23' : '16';
  };

  return (
    <form onSubmit={onHandleSubmit} style={{ position: 'relative' }}>
      <FormSection newCard={newCard}>
        <label data-test-id="label-card_info" style={{ fontWeight: '400' }}>
          Card Information
        </label>
        <Tooltip
          placement={'topRight'}
          visible={showTooltip}
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          overlay="The CVC/security code has 3 digits and is located at the back of your credit card. For American Express cards, the security code has 4 digits and is located at the front"
          overlayStyle={{ maxWidth: 300 }}
        >
          <LabelToolTip onMouseOver={() => setShowToolTip(true)} onMouseOut={() => setShowToolTip(false)}>
            <SvgQuestion styles={{ height: 14, fill: 'rgb(151, 151, 151)', float: 'right', paddingTop: '10px' }} />
          </LabelToolTip>
        </Tooltip>
        {!storyMode ? (
          <CreditCardInput
            cardNumberInputProps={{
              value: cardNumber,
              onChange: e => onCardChange('card_number', e),
              onFocus: e => {
                e.target.select();
                handlePaymentVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: PaymentOnFocusVysionEventsEnum.CARD_NUMBER_ENTRY,
                });
              },
              placeholder: 'Card Number',
              maxLength: verifyValidCardNumber(),
              id: 'card_number',
              onError: () => onCardError('card_number'),
            }}
            cardExpiryInputProps={{
              value: expiryVal,
              onChange: e => onCardChange('card_expiry', e),
              onFocus: e => {
                e.target.select();
                handlePaymentVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: PaymentOnFocusVysionEventsEnum.CARD_EXPIRATION_ENTRY,
                });
              },
              id: 'card_expiry',
              onError: () => onCardError('card_expiry'),
            }}
            cardCVCInputProps={{
              value: cardCVV,
              onChange: e => onCardChange('card_cvv', e),
              onFocus: e => {
                e.target.select();
                handlePaymentVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: PaymentOnFocusVysionEventsEnum.CARD_SECURITY_CODE_ENTRY,
                });
              },
              id: 'card_cvv',
              onError: () => onCardError('card_cvv'),
            }}
            dangerTextClassName="error-card-text"
            containerStyle={{
              width: 'calc(100% - 3px)',
              height: '40px',
              marginBottom: '15px',
              marginTop: '8px',
              paddingBottom: '8px',
            }}
            fieldStyle={{
              // border: '1px solid rgba(0,0,0,0.15)',
              borderRadius: '3px',
              padding: '12px',
              background: inputTheme.backgroundInput,
            }}
            inputStyle={{
              fontSize: '14px',
              color: inputTheme.colorInput,
              background: creditCardInputField.backgroundInput,
            }}
            customTextLabels={{
              invalidCardNumber: renderCardErrorMsg('cardNumber', cardNumber),
              expiryError: {
                invalidExpiryDate: renderCardErrorMsg('expiryDate', expiryVal),
              },
              invalidCvc: renderCardErrorMsg('cvc', cardCVV),
            }}
          />
        ) : (
          <React.Fragment>
            <TextInput
              id="card_number"
              type="text"
              inputLabel="Card number"
              value={cardNumber}
              onChange={inputOnChange}
              onFocus={() => {
                handlePaymentVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: PaymentOnFocusVysionEventsEnum.CARD_NUMBER_ENTRY,
                });
              }}
              status={verifyStatus(status, 'use2pay_card_number')}
              statusMessage={verifyStatusMessage(status, 'use2pay_card_number')}
              isBilling
            />
            <ExpAndCode>
              <ExpDateContainer>
                <InputLabel label="Expiration date" />
                <div style={{ display: 'flex' }}>
                  <Select
                    id="card_exp_month"
                    value={cardExpMonth}
                    status={expDateStatus}
                    noSpacing
                    onChange={inputOnChange}
                    style={{ flex: 1, paddingRight: 8, marginRight: '0px' }}
                  >
                    {dropDownOptions(1, 12, 'Month')}
                  </Select>
                  <Select
                    id="card_exp_year"
                    value={cardExpYear}
                    status={expDateStatus}
                    showStatusIcon={true}
                    onChange={inputOnChange}
                    style={{
                      flex: 1,
                      display: 'flex',
                      paddingRight: 4,
                    }}
                  >
                    {dropDownOptions(currentYear, currentYear + 20, 'Year')}
                  </Select>
                </div>
                {status &&
                  status.use2pay_card_exp_month &&
                  status.use2pay_card_exp_year &&
                  renderExpStatusMessage(status.use2pay_card_exp_month, status.use2pay_card_exp_year, expDateStatus)}
              </ExpDateContainer>
              <TextInput
                width={134}
                id="card_cvv"
                type="text"
                inputLabel="Security code"
                value={cardCVV}
                tooltip={{
                  text:
                    'The security code has 3 digits and is located at the back of your credit card. For American Express cards, the security code has 4 digits and is located at the front',
                  overlayStyle: { maxWidth: '300px' },
                  placement: 'top',
                  icon: <SvgQuestion />,
                }}
                onChange={inputOnChange}
                status={verifyStatus(status, 'use2pay_card_cvv')}
                statusMessage={verifyStatusMessage(status, 'use2pay_card_cvv')}
                customStyle={{ maxWidth: 134 }}
              />
            </ExpAndCode>
          </React.Fragment>
        )}
        <TextInput
          id="billing_name_on_card"
          type="text"
          inputLabel="Name on Card"
          value={nameOnCard}
          customStyle={{ marginRight: 4 }}
          customInputStyle={{ border: 'none' }}
          onChange={inputOnChange}
          status={verifyStatus(status, 'use2pay_billing_name_on_card')}
          statusMessage={verifyStatusMessage(status, 'use2pay_billing_name_on_card')}
          onFocus={() => {
            handlePaymentVysionEvent({
              action: VYSION_ACTIONS.ON_FOCUS,
              event: PaymentOnFocusVysionEventsEnum.CARD_NAME_ENTRY,
            });
          }}
          noBorder
        />
        <BillingAddressSection>
          <BillingAddressHeading>Billing Address</BillingAddressHeading>
          <CountrySelect
            countryDropdownType="short"
            id="billing_country"
            inputLabel={'Country'}
            value={billingCountry}
            style={{ marginRight: 4, border: 0, boxShadow: 'none' }}
            onChange={onCountryChange}
            status={verifyStatus(status, 'use2pay_billing_country')}
            statusMessage={verifyStatusMessage(status, 'use2pay_billing_country')}
            isBilling
          />
          <AutocompleteContainer>
            <InputLabel label="Zip / Postal Code" isBilling />
            <PostalLookup
              inputProps={locationInputProps}
              onSelect={onSelectLocation}
              error={''}
              status={verifyStatus(status, 'use2pay_billing_zip')}
              statusMessage={verifyStatusMessage(status, 'use2pay_billing_zip')}
              country={billingCountry}
            />
          </AutocompleteContainer>

          <TextInput
            isBilling
            id="billing_city"
            type="text"
            inputLabel="City"
            value={billingCity}
            customStyle={{ marginRight: 4 }}
            onChange={inputOnChange}
            onFocus={() => {
              handlePaymentVysionEvent({
                action: VYSION_ACTIONS.ON_FOCUS,
                event: PaymentOnFocusVysionEventsEnum.CITY_ENTRY,
              });
            }}
            status={verifyStatus(status, 'use2pay_billing_city')}
            statusMessage={verifyStatusMessage(status, 'use2pay_billing_city')}
            noBorder
          />
          <TextInput
            isBilling
            id="billing_state"
            type="text"
            inputLabel="State"
            value={billingState}
            customStyle={{ marginRight: 4 }}
            onChange={inputOnChange}
            onBlur={onStateChange}
            disabled={disableState}
            onFocus={() => {
              handlePaymentVysionEvent({
                action: VYSION_ACTIONS.ON_FOCUS,
                event: PaymentOnFocusVysionEventsEnum.STATE_PROVINCE_ENTRY,
              });
            }}
            status={verifyStatus(status, 'use2pay_billing_state')}
            statusMessage={verifyStatusMessage(status, 'use2pay_billing_state')}
            noBorder
          />
          <TextInput
            isBilling
            id="billing_address"
            type="text"
            inputLabel="Street Address"
            value={billingAddress}
            customStyle={{ marginRight: 4 }}
            onChange={inputOnChange}
            onFocus={() => {
              handlePaymentVysionEvent({
                action: VYSION_ACTIONS.ON_FOCUS,
                event: PaymentOnFocusVysionEventsEnum.ADDRESS_ENTRY,
              });
            }}
            status={verifyStatus(status, 'use2pay_billing_address')}
            statusMessage={verifyStatusMessage(status, 'use2pay_billing_address')}
            noBorder
          />
        </BillingAddressSection>
        <Checkbox
          id="cc_save"
          label={'Save for next time'}
          disabled={recurring && selectedPackage?.identifier !== 'idv_last_minute'}
          value={recurring && selectedPackage?.identifier !== 'idv_last_minute' ? true : saveCard}
          onChange={onSelectSaveCard}
          customStyle={
            recurring && selectedPackage?.identifier !== 'idv_last_minute'
              ? { backgroundColor: '#a7d2f1d9 !important', borderColor: '#a7d2f1d9 !important' }
              : {}
          }
        />
      </FormSection>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <PayWithCardButton
          disabled={disableBtn}
          data-test-id="billing-purchase-button"
          customStyle={{ margin: '0 auto', backgroundColor: 'rgb(19, 131, 210)', padding: '0 40px' }}
          buttonType="primary"
        >
          <LockIcon style={{ fontSize: 19, paddingRight: 3 }} />
          {` Pay ${String.fromCharCode(currencyCharCode)}`}
          {selectedPackage ? renderTaxLabel(selectedPackage, discountAmount) : totalAmount}
        </PayWithCardButton>
      </div>
      <BillingTermsAndCondition userCountry={userCountry} />
      <BillingPaymentProcessor userCountry={userCountry} />
      <AfterPayment descriptors={descriptors} />
    </form>
  );
};

BillingForm.propTypes = {
  nameOnCard: PropTypes.string,
  cardNumber: PropTypes.string,
  cardCVV: PropTypes.string,
  billingAddress: PropTypes.string,
  billingCity: PropTypes.string,
  billingState: PropTypes.string,
  billingCountry: PropTypes.string,
  billingZip: PropTypes.string,
  cardExpMonth: PropTypes.number,
  cardExpYear: PropTypes.number,
  /** Determines if purchase button should be disabled */
  disablePurchase: PropTypes.bool.isRequired,
  /** Function to handle changes on input */
  onInputChange: PropTypes.func.isRequired,
  /** Function to handle selecting a country */
  handleCountryChange: PropTypes.func.isRequired,
  /** Function to handle submitting payment */
  handleSubmit: PropTypes.func,
  /** Object that contains status and message for each form field */
  status: PropTypes.object,
  onSelectSaveCard: PropTypes.func.isRequired,
  saveCard: PropTypes.bool,
};

export default BillingForm;
