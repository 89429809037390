export const STORE_ACCOUNT_UNLINK = 'STORE_ACCOUNT_UNLINK';
export const ACCOUNT_HAS_BEEN_UNLINKED = 'ACCOUNT_HAS_BEEN_UNLINKED';

export interface UnlinkState {
    hasBeenUnlinked: boolean;
    accountId: number;
    email: string;
}

interface STORE_ACCOUNT_UNLINK_ACTION {
    type: typeof STORE_ACCOUNT_UNLINK;
    payload: unknown[];
}

interface ACCOUNT_HAS_BEEN_UNLINKED_ACTION {
    type: typeof ACCOUNT_HAS_BEEN_UNLINKED;
    payload: unknown[];
    hasBeenUnlinked: boolean;
}

export type UnlinkActionType =
  | STORE_ACCOUNT_UNLINK_ACTION
  | ACCOUNT_HAS_BEEN_UNLINKED_ACTION;