import React, { ReactElement } from 'react';
import BillingPackageStackedOld from '../../atoms/billing/BillingPackageStackedOld';
import BillingPackageOld from '../../atoms/billing/BillingPackageOld';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const DivContainer = styled('div')`
  margin: 0 auto;
`;

const PackageListContainer = styled(`div`)`
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
`;

const PackageList = styled('div')`
  display: unset;
  align-items: center;
  justify-content: space-between;
`;

const PackagePromoList = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled('div')`
  height: 1px;
  border-bottom: 1px solid #cfd8e3;
  max-width: 865px;
  margin: 16px auto;
`;

interface BillingPackagesStackedOldProps {
  packages: any;
  promoPackages: any;
  selected: any;
  selectPackage: any;
  forceShowVat: any;
  withNewPackage: any;
  stepBilling: any;
  emailPromo: any;
  showPayment: any;
}

const BillingPackagesStackedOld = ({
  packages,
  promoPackages,
  selected,
  selectPackage,
  forceShowVat,
  withNewPackage,
  stepBilling,
  emailPromo,
  showPayment,
}: BillingPackagesStackedOldProps): ReactElement => {
  const hasPromoPackage = promoPackages && promoPackages.length > 0;

  const renderPackages = packages => {
    return packages.map(pkg => {
      if (!withNewPackage && pkg.packageVersion === 'new') {
        return false;
      } else {
        const recommended = withNewPackage
          ? pkg.packageVersion === 'new' && pkg.recommended
          : !hasPromoPackage && pkg.recommended;

        return (
          <BillingPackageStackedOld
            key={pkg.identifier}
            selected={selected === pkg.identifier}
            selectPackage={selectPackage}
            credits={pkg.credits}
            promotional={pkg.promotional}
            identifier={pkg.identifier}
            currencyCharCode={pkg.currency.data.label_unicode}
            price={pkg.price}
            hasPromoPackage={hasPromoPackage}
            vatDetails={pkg.taxes}
            forceShowVat={forceShowVat}
            isRecommended={recommended}
            savings={pkg.savings}
            withNewPackage={withNewPackage}
            stepBilling={stepBilling}
            emailPromo={emailPromo}
            showPayment={showPayment}
          />
        );
      }
    });
  };

  const renderPromoPackages = packages => {
    return packages.map(pkg => {
      return (
        <BillingPackageOld
          key={pkg.identifier}
          selected={selected === pkg.identifier}
          selectPackage={selectPackage}
          credits={pkg.credits}
          promotional={pkg.promotional}
          identifier={pkg.identifier}
          currencyCharCode={pkg.currency.data.label_unicode}
          price={pkg.price}
          hasPromoPackage={hasPromoPackage}
          vatDetails={pkg.taxes}
          checkBoxVariant
          savings={pkg.savings}
          withNewPackage={withNewPackage}
          stepBilling={stepBilling}
          emailPromo={emailPromo}
          showPayment={showPayment}
        />
      );
    });
  };

  // TODO Switch Promo and normal Package
  return (
    <DivContainer>
      <PackageListContainer maxWidth={546}>
        {hasPromoPackage && promoPackages.length > 0 ? (
          <PackagePromoList>{renderPromoPackages(promoPackages)}</PackagePromoList>
        ) : null}
      </PackageListContainer>
      {hasPromoPackage && packages.length > 0 ? <Line /> : null}
      <PackageListContainer maxWidth={800}>
        {packages.length > 0 ? <PackageList>{renderPackages(packages)}</PackageList> : null}
      </PackageListContainer>
    </DivContainer>
  );
};

BillingPackagesStackedOld.propTypes = {
  /** List of default packages */
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** List of promo packages */
  promoPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Function to handle selecting a package */
  selectPackage: PropTypes.func.isRequired,
  /** Signifies which package is selected */
  selected: PropTypes.string.isRequired,
};

export default BillingPackagesStackedOld;
