import { VYSION_ACTIONS } from '../..';
import { EventData, handleVysionEvent } from '../../vysionService';
import { profileUploadGalleryClickVysionEvents } from './click';

export const events = {
  [VYSION_ACTIONS.CLICK]: { ...profileUploadGalleryClickVysionEvents },
};

export const handleUploadGAlleryVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
