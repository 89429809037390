import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Source: string;
  Amount: number | string;
  User: string;
}

export const mixpanelTrackOfferDeclined = async (
  initiatorHashId: string,
  eventProperties: EventProperties
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Offer Declined',
    payload: {
      event_properties: eventProperties,
    },
    other_account_hash_id: initiatorHashId,
  });
};
