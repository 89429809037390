import styled from '@emotion/styled';
import { dimensions } from '../../style';

export const Content = styled('div')({
  [dimensions.SCREEN_MAX_XS]: {
    padding: '0 10px',
  },
  [dimensions.SCREEN_MIN_MD]: {
    minWidth: 0,
  },
},
({isMobile}) => !isMobile && {
  [dimensions.SCREEN_MAX_MD]: {
    marginTop: 20,
    padding: '0 10px',
  },
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: 20,
  },
});

export const Aside = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    width: '100%',
  },
});

export const PrimaryContent = styled('div')({
  [dimensions.SCREEN_MIN_XS]: {
    minHeight: 175,
  },
  [dimensions.SCREEN_MIN_MD]: {
    minWidth: 0,
  },
});
