import React, { ReactElement } from 'react';
import { isDarkMode } from '../../../../utils/helpers';

interface IShareIconProps {
  customStyle?: any;
}

const ShareIcon = ({ customStyle }: IShareIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none" style={customStyle}>
      <path
        d="M2.15221 16C1.44425 16 0.908555 15.8116 0.545133 15.4347C0.181711 15.0628 0 14.5074 0 13.7685V6.7987C0 6.05486 0.181711 5.49698 0.545133 5.12506C0.908555 4.74818 1.44425 4.55974 2.15221 4.55974H4V5.82427H2.20177C1.88083 5.82427 1.6354 5.91353 1.46549 6.09205C1.29558 6.26561 1.21062 6.53092 1.21062 6.88796V13.6792C1.21062 14.0263 1.29558 14.2892 1.46549 14.4677C1.6354 14.6462 1.88083 14.7355 2.20177 14.7355H9.80531C10.1215 14.7355 10.367 14.6462 10.5416 14.4677C10.7162 14.2892 10.8035 14.0263 10.8035 13.6792V6.88796C10.8035 6.53092 10.7162 6.26561 10.5416 6.09205C10.367 5.91353 10.1215 5.82427 9.80531 5.82427H8.01416V4.55974H9.84779C10.5652 4.55974 11.1032 4.74818 11.4619 5.12506C11.8206 5.50194 12 6.05982 12 6.7987V13.7685C12 14.5074 11.8206 15.0628 11.4619 15.4347C11.1032 15.8116 10.5652 16 9.84779 16H2.15221ZM6.00354 10.4361C5.84307 10.4361 5.70619 10.3766 5.59292 10.2576C5.47965 10.1385 5.42301 9.99969 5.42301 9.841V2.68526L5.46549 1.63645L5.03363 2.1497L4.06372 3.2357C3.95988 3.35968 3.82773 3.42166 3.66726 3.42166C3.51622 3.42166 3.39115 3.37208 3.29204 3.2729C3.19764 3.16876 3.15044 3.04231 3.15044 2.89354C3.15044 2.74477 3.20472 2.61336 3.31327 2.4993L5.57168 0.208275C5.64248 0.133891 5.71327 0.0818224 5.78407 0.0520688C5.85487 0.0173563 5.92802 0 6.00354 0C6.07906 0 6.14985 0.0173563 6.21593 0.0520688C6.28673 0.0818224 6.35752 0.133891 6.42832 0.208275L8.68673 2.4993C8.8 2.61336 8.85664 2.74477 8.85664 2.89354C8.85664 3.04231 8.80472 3.16876 8.70088 3.2729C8.60177 3.37208 8.47906 3.42166 8.33274 3.42166C8.17227 3.42166 8.04248 3.35968 7.94336 3.2357L6.96637 2.1497L6.53451 1.64389L6.58407 2.68526V9.841C6.58407 9.99969 6.52507 10.1385 6.40708 10.2576C6.29381 10.3766 6.15929 10.4361 6.00354 10.4361Z"
        fill={isDarkMode() ? 'white' : 'black'}
      />
    </svg>
  );
};

export default ShareIcon;
