/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef, ReactElement, useMemo, Fragment } from 'react';
import styled from '@emotion/styled';
import { isDarkMode } from '../../utils/helpers';
import {
  sendOffer,
  sendCancel,
  sendSuggest,
  sendAmountCustom,
  counterOffer,
  counterCancel,
  counterModalCustomAmount,
} from '../../actions/profileTrackAction';
import OffersTutorialModal from '../../components/common/modals/OffersTutorialModal';
import InitialOfferPanel from './InitialOfferPanel';
import { dimensions, type } from '../../style';
import PendingOfferPanel from './PendingOfferPanel';
import AcceptedOfferPanel from './AcceptedOfferPanel';
import useOnClickTriggerTracking from '../../hooks/useOnclickTriggerTracking';
import useCompleteProfileModal from '../../hooks/useCompleteProfileModal';
import useEvidently from '../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../constants/evidently';
import { getDefaultOfferAmount } from '../../utils/offers';
import { useProfile } from '../../contexts/ProfileContext';
import { useApp } from '../../contexts/AppContext';
import useMixPanel from '../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../constants/mixpanel';
import useModal from '../../hooks/useModal';
import { AccountMaker } from '../../models/Account';
import { canGenerousUserMakeOffer } from '../../common';

const Form = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderRadius: 6,
    paddingBottom: 20,
    paddingTop: 20,
    height: 'auto',
    maxHeight: 200,
    minHeight: 199,
    [dimensions.SCREEN_MAX_XS_2]: {
      minHeight: '100%',
      maxHeight: '100%',
      height: '100%',
    },
  },
  ({ bgColor }) => ({
    backgroundColor: bgColor,
  })
);

const HeaderDiv = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  ({ moreSpacing }) => ({
    marginTop: 0,
    marginBottom: moreSpacing ? 0 : 'auto',

    [dimensions.SCREEN_MAX_SM]: {
      marginTop: 0,
    },
    [dimensions.SCREEN_MIN_XS_4]: {
      marginTop: 0,
      maxWidth: 310,
      margin: 'auto',
    },
  })
);

const MainText = styled('span')({
  fontSize: 15,
  textAlign: 'center',
});

const SubText = styled('span')({
  textAlign: 'center',
  ...type.XS,
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const offerMinMaxVal = (offer, type, defaultAmount) => {
  if (!offer) return defaultAmount;

  if (type === 'min' && offer.suggest) {
    return defaultAmount;
  }

  return type === 'min' ? offer.min_counter_price : offer.max_counter_price;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const setCreateOfferAction = (e, offerAction) => {
  switch (offerAction) {
    case 'Send Counter':
      counterOffer();
      break;
    case 'Cancel Counter':
      counterCancel();
      break;
    case 'Send Offer':
      sendOffer();
      break;
    case 'Cancel Offer':
      sendCancel();
      break;
    case 'Suggest Offer':
      sendSuggest();
      break;
    default:
      return;
  }
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};
interface OfferPanelProps {
  account: any;
  offerType: any;
  createdAt: any;
  myProfile: any;
  avatarSrc: any;
  offer: any;
  errors: any;
  isCounter: any;
  closeModal: any;
  currencyLabel: any;
  currencyInitial: any;
  currentUserIsGenerous: any;
  sex: any;
  justSuggested: any;
  ignoreStatus: any;
  clearErrors: any;
  username: any;
  sendSuggestion: any;
  showWarn: any;
  cannot_make_offer_reasons: any;
  counterOffer: any;
  createNewOffer: any;
  hashId: any;
  showOfferTutorial: boolean;
  updateOfferTutorialState: any;
  isFirstTimeTutorial: boolean;
  acceptOffer: any;
  ignoreOffer: any;
  updateOffer: any;
  canMakeOffer: any;
  unlockMessage: any;
  setIsUserFinishProfileModalOpen: any;
}

const OfferPanelV2 = ({
  account,
  offerType,
  createdAt,
  myProfile,
  offer,
  errors,
  isCounter,
  closeModal,
  currencyLabel,
  currencyInitial,
  currentUserIsGenerous,
  clearErrors,
  username,
  sendSuggestion,
  cannot_make_offer_reasons,
  counterOffer,
  createNewOffer,
  hashId,
  showOfferTutorial,
  updateOfferTutorialState,
  isFirstTimeTutorial,
  acceptOffer,
  ignoreOffer,
  updateOffer,
  unlockMessage,
  canMakeOffer,
  setIsUserFinishProfileModalOpen,
}: OfferPanelProps): ReactElement => {
  const { useActiveVariation } = useEvidently();
  const otherProfile = account.profile.data;
  const accountModel = AccountMaker.create(myProfile);
  const { showTooManyPendingOffersErrorModal } = useModal();

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultOfferPrice = useMemo(() => {
    const defaultAmount = getDefaultOfferAmount();

    if (otherProfile.account_type === 'Attractive') {
      return otherProfile?.default_offer_amount ?? defaultAmount;
    }

    return defaultAmount;
  }, [otherProfile?.default_offer_amount]);

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const [offerPrice, setOfferPrice] = useState(isCounter ? '' : defaultOfferPrice);
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer, 'min', defaultMinOffer));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);
  const acceptOfferBtn = useRef(null);
  const sendOfferBtn = useRef(null);
  const sendSuggestLink = useRef(null);
  const priceInput = useRef(null);
  const [isFirstTimeOfferTutorial, setFirstTimeOfferTutorial] = useState(false);
  const otherAccount = account;
  const isGenerous = currentUserIsGenerous;
  const iAmSender = offer ? offer.i_sent : false;
  const iAmAcceptor = offer ? offer.acceptor_hash_id === myProfile.hash_id : false;
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const { footerTopPosition } = useApp();
  const { calculateGalleryMediaRatio, offerBoxContainerRef, profileCardBannerRef } = useProfile();
  const { mixpanelData, resetMixpanelData } = useMixPanel();

  useEffect(() => {
    // setTimeout to offset priority for NUX before tutorial modal
    setTimeout(() => setFirstTimeOfferTutorial(isFirstTimeTutorial), 300);
  }, [isFirstTimeTutorial]);

  useEffect(() => {
    if (!isCounter && defaultOfferPrice && ['initial', 'suggestion_new'].includes(offerType)) {
      setOfferPrice(defaultOfferPrice);
    }
  }, [isCounter, defaultOfferPrice, offerType]);

  useEffect(() => {
    if (offer && offer.suggest) {
      setOfferPrice(offer.price_original ? offer.price_original : defaultOfferPrice);
    }
  }, [offer]);

  useOnClickTriggerTracking(isCounter ? 'Send Counter' : 'Send Offer', sendOfferBtn, setCreateOfferAction);
  useOnClickTriggerTracking('Suggest Offer', sendSuggestLink, setCreateOfferAction);

  useEffect(() => {
    clearErrors();
    trackFocus(priceInput, isCounter ? counterModalCustomAmount : sendAmountCustom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMaxValue(offerMinMaxVal(offer, 'max', '500'));
    setMinValue(offerMinMaxVal(offer, 'min', defaultMinOffer));
  }, [offer, defaultMinOffer]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }

    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const inputOnChange = e => {
    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValue.toString().length;

    if (e.target.value.length <= maxLength) {
      const isError = inputVal < minValueNum || inputVal > maxValueNum;
      updateOfferPrice(inputVal, isError);
    } else {
      e.preventDefault();
    }
  };

  const currLabel = String.fromCharCode(currencyLabel);

  const handleSubmit = async (e, callback) => {
    e.preventDefault();

    if (!accountModel.isMandatoryProfileCompleted() && isGenerous) {
      setIsUserFinishProfileModalOpen(true);
      return;
    }

    setOfferSending(true);

    showMandatoryCompleteProfile(
      () => {
        if (canGenerousUserMakeOffer(myProfile)) {
          if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            setOfferSending(false);
            return showTooManyPendingOffersErrorModal({
              onCloseCallback: () => {
                setOfferSending(false);
              },
            });
          }

          if (accountModel.shouldShowTooManySentOffers()) {
            showTooManyPendingOffersErrorModal({
              type: 'sent',
              onCloseCallback: () => {
                setOfferSending(false);
              },
            });
            return;
          }
        }

        // Send offer from Profile
        const mxOfferMadePayload = mixpanelData[EVENT_NAMES.OFFER_MADE];
        const formData = { price: offerPrice, account: hashId, source: mxOfferMadePayload.source };

        const canProceed = canMakeOffer(e);

        if (!canProceed && isGenerous) {
          setOfferSending(false);

          return;
        }

        if (offerPrice === '???') {
          sendSuggestion(hashId);
          setOfferSending(false);
          callback();
          return;
        }

        if (isCounter || (offer && offer.suggest)) {
          counterOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
              callback();
              resetMixpanelData(EVENT_NAMES.OFFER_MADE);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        } else {
          createNewOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
              callback();
              resetMixpanelData(EVENT_NAMES.OFFER_MADE);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        }
      },
      () => setOfferSending(false),
      isCounter || (offer && offer.suggest) ? 'Counter Offer' : 'Make Offer'
    );
  };

  const getOfferText = offerType => {
    const offerOrRequest = isGenerous ? 'offer' : 'request';

    switch (offerType) {
      case 'initial': {
        return isGenerous ? (
          <MainText>
            Send <strong>{username}</strong> a first date incentive!
          </MainText>
        ) : (
          <MainText>
            Request a first date incentive from <strong>{username}</strong>.
          </MainText>
        );
      }
      case 'suggestion_new':
      case 'suggestion_pending': {
        return iAmSender ? (
          <MainText>
            <strong>{username}</strong> is considering your {offerOrRequest}.
          </MainText>
        ) : (
          <MainText>
            <strong>{username}</strong> requests you to suggest an offer amount.
          </MainText>
        );
      }
      case 'offer_new':
      case 'offer_pending': {
        return iAmSender ? (
          <MainText>
            <strong>{username}</strong> is considering your {offerOrRequest}.
          </MainText>
        ) : (
          <MainText>
            <strong>{username}</strong> sent you a date offer{isGenerous ? ' request' : ''}.
          </MainText>
        );
      }
      case 'offer_accepted':
        return iAmAcceptor ? (
          <MainText>
            You have accepted <strong>{username}</strong> {isGenerous ? 'request' : 'offer'}.
          </MainText>
        ) : (
          <MainText>
            <strong>{username}</strong> accepted your {isGenerous ? 'request' : 'offer'}.
          </MainText>
        );
      default:
        return '';
    }
  };

  const getOfferSubText = offerType => {
    switch (offerType) {
      case 'offer_new':
      case 'offer_pending':
      case 'suggestion_pending':
        return (
          iAmSender && (
            <SubText style={{ marginTop: 5 }}>
              <small style={{ fontSize: 12 }}>Click Offer Amount to edit your offer.</small>
            </SubText>
          )
        );
      case 'offer_accepted':
        return offer.is_deal_unlocked ? (
          <SubText style={{ marginTop: 5 }}>Messaging unlocked!</SubText>
        ) : isGenerous ? (
          <SubText style={{ marginTop: 5 }}>
            Unlock messaging for <strong>{offer.credit_cost} Credits</strong> and start planning your date!
          </SubText>
        ) : (
          <div />
        );
      default:
        return '';
    }
  };

  const getBgColor = offerType => {
    const darkMode = isDarkMode();

    if (offerType === 'initial' || offerType === 'offer_accepted') {
      return darkMode ? 'rgba(17, 57, 86)' : 'rgba(230, 244, 253, 1)';
    }

    return darkMode ? 'rgba(17, 57, 86)' : 'rgba(255, 175, 0, 0.15)';
  };

  useEffect(() => {
    if (offerType) {
      calculateGalleryMediaRatio();
    }
  }, [offerType, footerTopPosition]);

  useEffect(() => {
    const onAnimationEnd = () => {
      calculateGalleryMediaRatio();
    };

    if (profileCardBannerRef.current) {
      profileCardBannerRef.current.addEventListener('animationend', onAnimationEnd);
    }

    return () => {
      if (profileCardBannerRef.current) {
        profileCardBannerRef.current.removeEventListener('animationend', onAnimationEnd);
      }
    };
  }, [profileCardBannerRef?.current, footerTopPosition]);

  return (
    <Form bgColor={getBgColor(offerType)} ref={offerBoxContainerRef}>
      <HeaderDiv moreSpacing={offerType === 'initial'}>
        <div>
          {getOfferText(offerType)}
          &ensp;
          {!isFirstTimeOfferTutorial && (
            <OffersTutorialModal showTutorial={false} onClose={updateOfferTutorialState} iconOnly />
          )}
        </div>
        <Fragment>{getOfferSubText(offerType)}</Fragment>
      </HeaderDiv>

      <Content>
        {['initial', 'suggestion_new'].includes(offerType) && (
          <InitialOfferPanel
            errors={errors}
            hasErrors={hasErrors}
            inputRef={priceInput}
            offerPrice={offerPrice}
            inputOnChange={inputOnChange}
            validateTextInput={validateTextInput}
            updateOfferPrice={updateOfferPrice}
            currencyInitial={currencyInitial}
            currLabel={currLabel}
            currentUserIsGenerous={isGenerous}
            offer={offer}
            createdAt={createdAt}
            handleSubmit={handleSubmit}
            sendOfferBtn={sendOfferBtn}
            isOfferSending={isOfferSending}
            ignoreOffer={ignoreOffer}
            otherAccount={otherAccount}
            otherProfile={otherProfile}
            isGenerous={isGenerous}
            canMakeOffer={cannot_make_offer_reasons === null}
          />
        )}
        {['suggestion_pending', 'offer_new', 'offer_pending'].includes(offerType) && (
          <PendingOfferPanel
            errors={errors}
            clearErrors={clearErrors}
            offer={offer}
            createdAt={createdAt}
            myProfile={myProfile}
            otherProfile={otherProfile}
            otherAccount={otherAccount}
            isGenerous={isGenerous}
            acceptOffer={acceptOffer}
            ignoreOffer={ignoreOffer}
            updateOffer={updateOffer}
            acceptOfferBtn={acceptOfferBtn}
            counterOffer={counterOffer}
            unlockMessage={unlockMessage}
            createNewOffer={createNewOffer}
            setIsUserFinishProfileModalOpen={setIsUserFinishProfileModalOpen}
          />
        )}
        {offerType === 'offer_accepted' && (
          <AcceptedOfferPanel
            offer={offer}
            createdAt={createdAt}
            offerPrice={offerPrice}
            myProfile={myProfile}
            otherProfile={otherProfile}
            otherAccount={otherAccount}
            isGenerous={isGenerous}
            unlockMessage={unlockMessage}
          />
        )}
        {isFirstTimeOfferTutorial && (
          <OffersTutorialModal
            showTutorial={showOfferTutorial}
            onClose={updateOfferTutorialState}
            isFirstTimeOffer={isFirstTimeOfferTutorial}
          />
        )}
      </Content>
    </Form>
  );
};

export default OfferPanelV2;
