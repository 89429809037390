import React, { useState } from 'react';
import GalleryUploaderModal from '../../../modules/gallery/GalleryUpload/GalleryFileUploader/GalleryUploaderModal';
import { PhotoFileType } from '../../../modules/gallery/constants';
import PhotoCropper from '../../../modules/gallery/PhotoCropper';
import PhotoBlur from '../../../modules/gallery/PhotoBlur';
import PhotoPreview from '../../../modules/gallery/PhotoPreview';
import { useDisableBodyElementScroll } from '../../../hooks/useDisableBodyScroll';

type StepsType = 'crop' | 'blur' | 'finalize';

interface IGalleryFileReview {
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  activeFile: PhotoFileType | null;
  updateFileLists: any;
  updatePrivatePrimaryFile: any;
}

const JoinEditablePhotoModal: React.FC<IGalleryFileReview> = ({
  activeFile,
  onClose,
  isOpen,
  onReset,
  updateFileLists,
  updatePrivatePrimaryFile,
}: IGalleryFileReview) => {
  const [currentStep, setCurrentStep] = useState<StepsType>('crop');
  useDisableBodyElementScroll(isOpen);

  const reset = () => {
    onReset();
    setCurrentStep('crop');
  };

  const onCancel = () => {
    const steps = {
      preview: () => {
        reset();
        onClose();
      },
      crop: () => {
        reset();
      },
      blur: () => {
        setCurrentStep('finalize');
      },
      finalize: () => {
        setCurrentStep('crop');
      },
    };

    steps[currentStep]();
  };

  const onCropDone = (file: File) => {
    updateFileLists(file);
    setCurrentStep('finalize');
  };

  const onBlurDone = (file: File) => {
    updateFileLists(file);
    setCurrentStep('finalize');
  };

  return (
    <GalleryUploaderModal
      onClose={onCancel}
      isOpen={isOpen}
      contentLabel=""
      title={'Edit Photo'}
      description={
        currentStep === 'crop'
          ? 'Step 1 of 2: Cropping'
          : currentStep === 'finalize'
          ? 'Step 2 of 2: Finalize'
          : currentStep === 'blur'
          ? 'Step 2 of 2: Blur'
          : ''
      }
    >
      {activeFile && currentStep === 'crop' && (
        <PhotoCropper onAddPhoto={onCropDone} imageUrl={activeFile.raw} onCancel={onCancel} />
      )}
      {activeFile && currentStep === 'blur' && (
        <PhotoBlur
          onLastPreview={onBlurDone}
          cancelBlur={() => {
            setCurrentStep('finalize');
          }}
          imageUrl={activeFile.edited}
          onCancel={onCancel}
        />
      )}
      {activeFile && currentStep === 'finalize' && (
        <PhotoPreview
          isPrivatePhoto={activeFile.private}
          showPrimarySelector={true}
          isPrimaryPhoto={activeFile.primary}
          nextBlur={() => {
            setCurrentStep('blur');
          }}
          onAddPhoto={({ isPrimary, isPrivate }) => {
            updatePrivatePrimaryFile({ isPrimary, isPrivate });
            reset();
          }}
          imageUrl={activeFile.edited}
          onCancel={onCancel}
        />
      )}
    </GalleryUploaderModal>
  );
};

export default JoinEditablePhotoModal;
