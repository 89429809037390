import { STORE_ACCOUNT_UNLINK, ACCOUNT_HAS_BEEN_UNLINKED, UnlinkState, UnlinkActionType } from './types/Unlink.d';

const initialState: UnlinkState = {
  accountId: null,
  hasBeenUnlinked: false,
  email: null,
};

const unlink = (state = initialState, action: UnlinkActionType): any => {
  switch (action.type) {
    case STORE_ACCOUNT_UNLINK:
      return {
        ...state,
        ...action.payload,
      };
    case ACCOUNT_HAS_BEEN_UNLINKED:
      return {
        ...state,
        hasBeenUnlinked: true,
      };
    default:
      return state;
  }
};

export default unlink;
