import React, { useState } from 'react';
import styled from '@emotion/styled';
import TopNav from '../../blocks/TopNav';
import Button from '../../../atoms/buttons/Button';
import Container from '../../blocks/Container';
import { dimensions } from '../../../style';
import { Content, Aside, PrimaryContent } from '../../blocks/FlexLayout';
import ResultList from '../../blocks/ResultList';
import { StarIcon, EyeIcon, StarOutlineIcon, GridView, ListView } from '../../../atoms/icons/favoriteIcons';
import { connectScreenSize } from 'react-screen-size';
import { getLocalItem, setLocalItem } from '../../../common';
import { getBrowserDetails } from '../../../utils/helpers';
import Loading from '../../../atoms/Loading';
import FavoritedList from './FavoritedList';
import { useLocation } from 'react-router-dom';
import CompleteProfileModalV2 from '../../../modules/modals/CompleteProfileModalV2';
import { useApp } from '../../../contexts/AppContext';
import { DEFAULT_OLD_COMPLETE_MODAL_PROPS } from '../../../constants/modal';
import { mixpanelTrackInterestsDisplayModeChanged } from '../../../utils/mixpanel/interestsDisplayModeChanged';

const ButtonContainer = styled('div')({
  margin: '16px auto',
  maxWidth: '200px',
});

const SubHeader = styled('div')({
  fontSize: 18,
  fontWeight: 400,
  color: 'rgba(140, 140, 140, 1)',
  borderBottom: '1px solid rgba(201, 201, 201, 1)',
  paddingBottom: 10,
  flex: '1 0 100%',
  width: '100%',
  [dimensions.SCREEN_MAX_SM]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const FavoritedListWrapper = styled('div')(
  {
    padding: '0px 40px',
    display: 'flex',
    flexFlow: 'wrap',
    flex: '1 0 100%',
    // gap: '10px 23px',
    ['> * + *']: {
      marginLeft: '23px',
      ['@media (max-width: 962px)']: {
        marginLeft: '20px',
      },
      ['&:nth-of-type(4n+5)']: {
        marginLeft: 0,
        [dimensions.SCREEN_MAX_MD]: {
          marginLeft: '8px',
        },
      },
    },
    [dimensions.SCREEN_MAX_SM]: {
      justifyContent: 'flex-start',
      marginLeft: '5vw',
    },
    [dimensions.SCREEN_MAX_MD_2]: {
      marginLeft: 0,
    },
    [dimensions.SCREEN_MAX_MD]: {
      padding: '0px 4px',
    },
  },
  ({ isListView }) =>
    isListView && {
      padding: '0px 40px',
      marginLeft: 0,
      ['> * + *']: {
        marginLeft: 0,
      },
      [dimensions.SCREEN_MAX_SM]: {
        marginLeft: '0vw',
        width: '100%',
        overflow: 'hidden',
      },
    },
  ({ enableSlider }) =>
    enableSlider && {
      display: 'block',
      overflow: 'hidden',
      [dimensions.SCREEN_MAX_SM]: {
        marginLeft: 0,
        paddingTop: 0,
      },
    }
);

const FavoritesList = styled(ResultList)({
  padding: '26px 40px',
  display: 'flex',
  flexFlow: 'wrap',
  borderRadius: '10px !important',
  [dimensions.SCREEN_MAX_MD]: {
    padding: '16px 10px',
    borderRadius: '0 !important',
  },
  [dimensions.SCREEN_MAX_SM]: {
    padding: 0,
  },
  justifyContent: 'center',
});

const ViewActions = styled('a')({
  float: 'right',
  cursor: 'pointer',
  height: 22,
  width: 22,
  [dimensions.SCREEN_MAX_SM]: {
    marginRight: 15,
  },
});

const SubHeaderText = styled('span')({
  [dimensions.SCREEN_MAX_SM]: {
    display: 'none',
  },
});

interface InterestsProps {
  userProfile: any;
  fav: any;
  toggle: any;
  moreExists: any;
  loadMore: any;
  isFetching: any;
  page: any;
  ignoreUser: any;
  clearErrors: any;
  createNewOffer: any;
  sendSuggestion: any;
  errors: any;
  showWarn: any;
  screenSizeIsMobile: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  newFavorites: any;
  newViews: any;
  triggerUnlockMessage: any;
}

const Interests = ({
  userProfile,
  fav,
  toggle,
  moreExists,
  loadMore,
  isFetching,
  page,
  ignoreUser,
  clearErrors,
  createNewOffer,
  sendSuggestion,
  errors,
  showWarn,
  screenSizeIsMobile,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  newFavorites,
  newViews,
  triggerUnlockMessage,
}: InterestsProps) => {
  const { oldCompleteProfileModalState, setOldCompleteProfileModalState } = useApp();
  const location = useLocation();
  const [viewType, setViewType] = useState(getLocalItem('favorites-view') || 'grid');
  const [subRoutes] = useState([
    {
      page: `Viewed Me`,
      icon: {
        default: <EyeIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={false} />,
        active: <EyeIcon fill="rgba(188,32,40,1)" width={getBrowserDetails().isMobile ? 14 : 18} isActive={true} />,
      },
      url: '/interests',
      active: /.*interests$/,
      noResultsTitle: `It takes two to tango!`,
      noResultsMsg: `The more you interact with other members, the more interest you'll get!`,
      noResultsImg: 'favorite',
    },
    {
      page: 'Favorited Me',
      icon: {
        default: <StarIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={false} />,
        active: <StarIcon fill="rgba(188,32,40,1)" width={getBrowserDetails().isMobile ? 14 : 18} isActive={true} />,
      },
      url: '/interests/favorited-me',
      active: /.*favorited-me.*/,
      noResultsTitle: 'It takes two to tango!',
      noResultsMsg: "The more you interact with other members, the more favorites you'll get!",
      noResultsImg: 'favorite',
    },
    {
      page: 'My Favorites',
      icon: {
        default: <StarOutlineIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={false} />,
        active: (
          <StarOutlineIcon fill="rgba(188,32,40,1)" width={getBrowserDetails().isMobile ? 14 : 18} isActive={true} />
        ),
      },
      url: '/interests/my-favorites',
      active: /.*my-favorites.*/,
      noResultsTitle: `Haven't found any favorites?`,
      noResultsMsg: `Use the star icon to add to your favorites list, or send them an offer! The more you do, the more you’ll get back.`,
      noResultsImg: 'favorite',
    },
  ]);

  const enableSlider = false;

  if (!userProfile.profile) return null;

  const noResults = {};
  for (let i = 0; i < subRoutes.length; i++) {
    const subRoute = subRoutes[i];
    if (subRoute.active.test(location.pathname)) {
      noResults.title = subRoute.noResultsTitle;
      noResults.msg = subRoute.noResultsMsg;
      noResults.img = subRoute.noResultsImg;
    }
  }

  const getActivePagetTitle = () => {
    const activePage = location.pathname;

    const activeRoute = subRoutes.find(function(routeItem) {
      const regex = routeItem.active;
      const active = regex.test(activePage);

      return active ? routeItem : false;
    });

    return activeRoute && activeRoute.page;
  };

  const handleViewSwitcher = () => {
    const viewTypeValue = viewType === 'list' ? 'grid' : 'list';
    setViewType(viewTypeValue);
    setLocalItem('favorites-view', viewTypeValue);
    mixpanelTrackInterestsDisplayModeChanged(viewTypeValue);
  };

  const renderMoreExists = () => {
    if (!moreExists) return;
    return (
      <>
        {fav.length && !isFetching ? (
          <ButtonContainer>
            <Button
              buttonType="primary"
              fit
              onClick={() => {
                loadMore(page);
              }}
            >
              Load More
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Loading />
          </ButtonContainer>
        )}
      </>
    );
  };

  const isSliderLoadMore = () => {
    if (!moreExists) return;
    !isFetching && loadMore(page);
  };

  const { isMobile } = getBrowserDetails();

  return (
    <Container fullWidth screenTop>
      <Content
        className="favorites-content"
        isMobile={isMobile}
        style={enableSlider ? { position: 'fixed', width: 'calc(100% - 20px)' } : {}}
      >
        <Aside className="favorites--menu-wrap">
          <TopNav
            className="favorites-sub-nav"
            location={location}
            subRoutes={subRoutes}
            favCount={newFavorites}
            viewCount={newViews}
          />
        </Aside>
        <PrimaryContent>
          <FavoritesList>
            <SubHeader viewType={viewType}>
              <SubHeaderText data-test-id="favorites-sub-header">{getActivePagetTitle()}</SubHeaderText>
              <ViewActions
                data-test-id="list-grid-view-btn"
                onClick={() => handleViewSwitcher()}
                aria-label={viewType === 'list' ? 'List' : 'Grid'}
              >
                {viewType === 'list' ? <GridView /> : <ListView />}
              </ViewActions>
            </SubHeader>
            <FavoritedListWrapper
              data-test-id="fav-list-wrapper"
              isListView={viewType === 'list'}
              enableSlider={enableSlider}
            >
              <FavoritedList
                favorites={fav}
                page={page}
                toggle={toggle}
                noResultsTitle={noResults.title}
                noResultsMsg={noResults.msg}
                noResultsImg={noResults.img}
                isFetching={isFetching}
                user={userProfile}
                screenSizeIsMobile={screenSizeIsMobile}
                viewType={viewType}
                ignoreUser={ignoreUser}
                createNewOffer={createNewOffer}
                sendSuggestion={sendSuggestion}
                clearErrors={clearErrors}
                errors={errors}
                showWarn={showWarn}
                acceptOffer={acceptOffer}
                counterOffer={counterOffer}
                ignoreOffer={ignoreOffer}
                unlockMessage={triggerUnlockMessage}
                pageTitle={getActivePagetTitle()}
                enableSlider={enableSlider}
                sliderLoadMore={isSliderLoadMore}
              />
            </FavoritedListWrapper>
            {!enableSlider && renderMoreExists()}
          </FavoritesList>
          <CompleteProfileModalV2
            {...oldCompleteProfileModalState.props}
            isOpen={oldCompleteProfileModalState.isOpen}
            onClose={() => setOldCompleteProfileModalState({ isOpen: false, props: DEFAULT_OLD_COMPLETE_MODAL_PROPS })}
            scrollableInMobile
          />
        </PrimaryContent>
      </Content>
    </Container>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
  };
};

export default connectScreenSize(mapScreenToProps)(Interests);
