import React, { useState, useEffect, ReactElement } from 'react';
import styled from '@emotion/styled';
import { color } from '../../style';
import { getBrowserDetails } from '../../utils/helpers';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';

const { isMobile } = getBrowserDetails();

const BackToTopDesktop = styled('span')({
  fontSize: isMobile ? 46 : 16,
  color: isMobile ? '#FFF' : color.BRAND_1,
  fontWeight: 600,
  position: 'fixed',
  width: isMobile ? '25%' : '100%',
  left: isMobile ? '75%' : 0,
  bottom: isMobile ? 110 : 10,
  zIndex: 99,
});

const BackToTopText = styled('div')({
  backgroundColor: isMobile ? color.BRAND_2 : '#FFF',
  cursor: 'pointer',
  width: isMobile ? 55 : 120,
  padding: isMobile ? '5px 4px 0px 4px' : '5px 5px 8px 5px',
  borderRadius: isMobile ? '50%' : 4,
  opacity: isMobile ? 1 : 0.8,
  margin: isMobile ? '0 20px' : '0 auto',
  textAlign: 'center',
  float: isMobile ? 'right' : 'unset',
});

interface BackToTopContainerProps {
  onClick: any;
  isVisible: boolean;
}

const BackToTopContainer = ({ onClick, isVisible = false }: BackToTopContainerProps): ReactElement => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!isVisible && !isMobile) return null;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  if (scrollPosition < 300) return null;

  return (
    <BackToTopDesktop data-test-id="back-to-top-desktop">
      <BackToTopText onClick={onClick}>
        <ArrowUpwardRoundedIcon style={{ fontSize: isMobile ? 46 : 16 }} />
        {!isMobile && ' Back to top'}
      </BackToTopText>
    </BackToTopDesktop>
  );
};

export default BackToTopContainer;
