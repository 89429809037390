import styled from '@emotion/styled';
import React from 'react';
import borders from '../../../theme/borders';
import { theme } from '../../../theme';
import WYPIcon from '../../../atoms/icons/WYPIcon';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import { getBrowserDetails } from '../../../utils/helpers';
import backgrounds from '../../../theme/backgrounds';
import { useNavigate } from 'react-router-dom';
import { dimensions } from '../../../style';

export const Container = styled('div')({
  height: getBrowserDetails().hImgCalibration,
  maxHeight: getBrowserDetails().hImgCalibration,
  paddingBottom: 150,
  [dimensions.SCREEN_MIN_MD]: {
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 0,
  },
});

export const MessageCard = styled('div')({
  borderRadius: 10,
  background: backgrounds[theme].primaryCard,
  border: `1px solid ${borders[theme].primary}`,
  paddingTop: 94,
  paddingBottom: 94,
  marginBottom: 18,
  [dimensions.SCREEN_MIN_MD]: {
    marginBottom: 0,
  },
});

export const DescriptionText = styled('p')({
  fontSize: 18,
  lineHeight: '23px',
});

export const SearchButtonContainer = styled(PrimaryButton)({
  [dimensions.SCREEN_MIN_MD]: {
    marginTop: 20,
  },
});

const SearchButton = () => {
  const navigate = useNavigate();

  return (
    <SearchButtonContainer
      size="regular"
      color="blue"
      label="Go to Search"
      onClick={() => navigate('/search')}
      customStyle={{
        width: 'inherit',
        padding: '16px 32px',
        minWidth: 207,
        height: 48,
        fontSize: 18,
        fontWeight: 700,
      }}
    />
  );
};

const DashboardDiscoverModeNoMoreProfilesScreen: React.FC = () => {
  const isMobile = getBrowserDetails().isMobile;

  return (
    <Container>
      <MessageCard>
        <WYPIcon customStyle={{ width: 100, height: 79 }} />
        <h3 style={{ fontSize: 18, marginTop: 29, marginBottom: 16 }}>{"You've"} reached the end.</h3>
        <DescriptionText>
          Check back soon for <br />
          more profiles.
        </DescriptionText>
        <DescriptionText>
          In the meantime, browse <br /> the Search page for more <br /> members.
        </DescriptionText>
        {!isMobile && <SearchButton />}
      </MessageCard>
      {isMobile && <SearchButton />}
    </Container>
  );
};

export default DashboardDiscoverModeNoMoreProfilesScreen;
