import React, { useState, useEffect, ReactElement, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '../../components/blocks/Container';
import styled from '@emotion/styled';
import { removeLocalItem, setLocalItem, toaster } from '../../common';
import BillingPackagesVariant from '../../modules/billing/BillingPackagesVariant';
import find from 'lodash/find';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import { getBrowserDetails } from '../../utils/helpers';
import { textColor } from '../../style';
import BillingCarousel from '../../components/common/BillingCarousel';
import queryString from 'query-string';
import BillingPackageHeader from '../../modules/billing/BillingPackageHeader';
import './BillingPaymentStyles.css';

const isMobile = getBrowserDetails().isMobile;
const isTablet = getBrowserDetails().isTablet;
const isNotDesktopWidth = getBrowserDetails().isNotDesktopWidth;

const ErrorMessage = styled('div')({
  margin: '10px 0',
  padding: 10,
  borderRadius: 4,
  color: '#FFF',
  backgroundColor: 'rgb(204, 84, 84)',
});

const PackageContainer = styled('div')({
  margin: '0px auto',
  padding: '5px 0 5px 0',
  textAlign: 'center',
});

const PromoAppliedIndicator = styled('div')`
  background: linear-gradient(135deg, #89d40e 0%, #24a83a 100%);
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 25px;
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.17px;
  margin-bottom: ${isMobile ? '38px' : '40px'};
  font-style: italic;
  max-width: ${isTablet && isNotDesktopWidth ? '546px' : '100%'};
  margin-left: ${isTablet && isNotDesktopWidth ? 'auto' : 'unset'};
  margin-right: ${isTablet && isNotDesktopWidth ? 'auto' : 'unset'};
`;

const VatContainer = styled('div')({
  margin: '0 auto',
  marginTop: 20,
  fontSize: 13,
  ...textColor.DARK,
  textAlign: 'center',
});

interface PackagesProps {
  showPayment: any;
  defaultPackageId: any;
  history: any;
  packages: any;
  promoCode: any;
  errorMessage: string | undefined | null;
  stackedVariant: any;
  featureVariant: any;
  promoCodeFromUrlParam: string | null;
}

const Packages = ({
  showPayment,
  defaultPackageId,
  packages,
  promoCode,
  errorMessage,
  stackedVariant,
  featureVariant,
  promoCodeFromUrlParam,
}: PackagesProps): ReactElement => {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [unlimited, setUnlimited] = useState(false);
  const [use2payPackageId, setUse2PayPackageId] = useState('');
  const [emailPromo, setEmailPromo] = useState(false);
  const memoizedCarousel = useMemo(
    () => <BillingCarousel activeSlideIndex={unlimited ? 1 : 0} hideArrows clickableIndicators />,
    [unlimited]
  );

  const mySelectedPackage =
    find(packages, { identifier: use2payPackageId || defaultPackageId }) || packages[1] || packages[0];

  // Set types of packages
  const promoPackages = filter(packages, { promotional: true, email_promo: !!emailPromo }) || null;
  const normalPackages = filter(packages, { promotional: false, email_promo: !!emailPromo }) || null;

  // Set when promo applied using the url params "promo"
  const normalPackagePromoApplied = find(normalPackages, 'discount') || null;

  const renderPromoAppliedDiscountAmount = useMemo(() => {
    if (!normalPackagePromoApplied) return null;

    let discountAmount = '';
    const { currency, discount } = normalPackagePromoApplied;

    switch (discount.type) {
      case 'fixed':
        discountAmount = `${String.fromCharCode(currency.data.label_unicode)}${discount.discount} `;
        break;
      case 'percentage':
        discountAmount = (discount.discount * 100).toFixed(0) + '%';
        break;
    }

    return `${discountAmount} OFF Promo Applied!`;
  }, [normalPackagePromoApplied]);

  // Sets promo countdown variables
  const promoCountdownPackages = reject(promoPackages, { expires_at: null });
  const promoCountdownExpiresAt = promoCountdownPackages.length > 0 ? promoCountdownPackages[0].expires_at : null;
  const isPromo = promoCountdownPackages.length > 0 ? promoCountdownPackages : promoPackages;

  const routeChange = useCallback(() => {
    const path = '/packages';
    navigate(path);
  }, [navigate]);

  // Links to payment with conditions
  const packageIdNotExist =
    defaultPackageId &&
    use2payPackageId &&
    find(packages, { identifier: use2payPackageId || defaultPackageId }) === undefined;

  useEffect(() => {
    if (promoCodeFromUrlParam && !normalPackagePromoApplied) {
      navigate('/packages');
      normalPackages.forEach(item => delete item.discount);
      toaster.warn('Promo package is no longer valid.');
    }
  }, [promoCountdownExpiresAt, promoCodeFromUrlParam, normalPackagePromoApplied]);

  useEffect(() => {
    const queryStringParsed = queryString.parse(location.search);
    setUnlimited(queryStringParsed.unlimitedAccess === 'true');
  }, [featureVariant]);

  useEffect(() => {
    if (showPayment && !packageIdNotExist) {
      // Redirects when mySelectedPackage is found
      // Otherwise, directs to packages page

      if (mySelectedPackage) {
        handleShowPayment(mySelectedPackage?.identifier, true);
      }
    }

    if (showPayment && defaultPackageId === 'idv_last_minute') {
      routeChange();
      handleShowPayment(defaultPackageId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPayment, defaultPackageId, mySelectedPackage, packageIdNotExist, routeChange]);

  useEffect(() => {
    setSelectedPackage();
  }, [searchParams, defaultPackageId])
  

  const setSelectedPackage = useCallback(() => {
    if (!searchParams) {
      return;
    }

    const selectedPackage = searchParams.get('selectedPackage');
    const isEmailPromo = searchParams.get('isEmailPromo');

    setEmailPromo(isEmailPromo === 'true');
    setUse2PayPackageId(selectedPackage || defaultPackageId);
  }, [defaultPackageId, searchParams]);

  const handleSelectPackage = identifier => {
    setLocalItem('wyp_selected_package', identifier);
    setUse2PayPackageId(identifier);
  };

  const handleShowPayment = useCallback(
    (packageId, isEmailPromo = false) => {
      const defaultPkg = find(packages, { name: '500 CREDIT PACKAGE' });
      let promoCodeId = '';

      setLocalItem('wyp_billing_packages', JSON.stringify(packages));

      if (isEmailPromo && promoCode !== null) {
        promoCodeId = `&promoCode=${promoCode}`;
      }

      const isPromoApplied = find(normalPackages, { identifier: packageId })?.discount;

      //  need to check if this promo applied to the selected package
      if (promoCodeFromUrlParam && isPromoApplied) {
        promoCodeId = `&promoCode=${promoCodeFromUrlParam}`;
      }

      const selectedPackage = defaultPackageId === 'idv_last_minute' ? defaultPackageId : packageId;
      const isFromIDVPackage = defaultPackageId === 'idv_last_minute' ? '&isFromIDVPackage=true' : '';

      navigate(
        `/payment?isEmailPromo=${isEmailPromo}&selectedPackageId=${
          selectedPackage ? selectedPackage : defaultPkg.identifier
        }${promoCodeId}${isFromIDVPackage}`
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultPackageId, packages, promoCode, promoCodeFromUrlParam, normalPackages]
  );

  const handleBackBtn = () => {
    removeLocalItem('wyp_billing_packages');
    history.go(-1);
    // history.push('/dashboard');
  };
  const renderBillingVariants = stackedVariant => {
    return (
      <BillingPackagesVariant
        packages={normalPackages}
        selected={mySelectedPackage?.identifier}
        selectPackage={handleSelectPackage}
        promoPackages={isPromo}
        stepBilling
        emailPromo={emailPromo}
        showPayment={handleShowPayment}
        selectionOff={false}
        selectedPackage={use2payPackageId}
        stackedVariant={stackedVariant}
        normalPromoBanner={
          (promoCountdownExpiresAt || promoPackages.length > 0) &&
          normalPackagePromoApplied && (
            <PromoAppliedIndicator style={{ marginTop: isMobile ? 38 : 40 }}>
              {renderPromoAppliedDiscountAmount}
            </PromoAppliedIndicator>
          )
        }
        promoCountdownExpiresAt={promoCountdownExpiresAt}
      />
    );
  };

  return (
    <Container size="MD" style={{ marginBottom: '20px' }}>
      {memoizedCarousel}
      {!isMobile ? (
        <BillingPackageHeader label={`Choose Your Credit Package`} />
      ) : (
        <BillingPackageHeader label={``} onBackClick={handleBackBtn} />
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!promoCountdownExpiresAt && normalPackagePromoApplied && promoPackages.length === 0 && (
        <PromoAppliedIndicator>{renderPromoAppliedDiscountAmount}</PromoAppliedIndicator>
      )}
      <PackageContainer>{renderBillingVariants(stackedVariant)}</PackageContainer>
      <VatContainer>
        <div>
          One-time purchase.
          {getBrowserDetails().isMobile ? <br /> : ' '}
          All prices exclude applicable taxes.
        </div>
      </VatContainer>
    </Container>
  );
};

export default Packages;
