import React from 'react';
import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import { backgroundTheme, textColor } from '../../style';
import { CancelRounded } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { IsMobileViewPort } from '../../utils/helpers';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';

ReactModal.setAppElement('body');

const setModalStyle = customStyle => {
  const customOverlay = (customStyle.overlay && customStyle.overlay) || {};
  const customContent = (customStyle.content && customStyle.content) || {};

  const mobileOverlayStyle = {
    borderRadius: 10,
    height: '100%',
    width: '90%',
    padding: '0 10%',
    left: '-5%',
  };

  const overlayStyle = IsMobileViewPort ? { ...customOverlay, ...mobileOverlayStyle } : customOverlay;
  const mobileCenterPosition = IsMobileViewPort
    ? { top: '45%', transform: 'translate(0,-50%)' }
    : { top: 82, transform: 'scale(1)' };

  return {
    overlay: {
      zIndex: 8000,
      backgroundColor: 'rgba(0,0,0,0.65)',
      ...overlayStyle,
    },
    content: {
      margin: '0 auto',
      outline: 'none',
      ...mobileCenterPosition,
      bottom: 'initial',
      ':focus': {
        outline: 'none',
      },
      padding: 0,
      position: 'relative',
      borderRadius: 10,
      backgroundColor: backgroundTheme.block,
      maxHeight: '80vh',
      overflow: 'auto',
      ...customContent,
      ...textColor.DARK,
    },
  };
};

const Content = styled('div')({
  position: 'relative',
});

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: 48,
  height: 48,
  position: 'absolute',
  top: 20,
  right: 16,
});

const closeButton = {
  fontSize: 24,
  color: '#8F8F8F',
};

const AutoTopUpBaseModal = (props: any): any => {
  const {
    closeOnOverlayClick,
    onClose,
    hideClose,
    customStyle,
    children,
    isOpen,
    customContentStyle,
    customCloseBtnStyle,
    forceDisableBodyScroll = false,
    ...rest
  } = props;

  useDisableBodyScroll(forceDisableBodyScroll ? true : isOpen);

  return (
    <ReactModal
      style={setModalStyle(customStyle)}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={onClose}
      isOpen={isOpen}
      {...rest}
    >
      <Content style={customContentStyle}>
        {!hideClose && (
          <CloseBtn data-test-id={`confirm-upload-close`} onClick={onClose} style={customCloseBtnStyle}>
            <SvgIcon component={CancelRounded} style={closeButton} />
          </CloseBtn>
        )}
        {children}
      </Content>
    </ReactModal>
  );
};

export default AutoTopUpBaseModal;
