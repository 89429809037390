import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from '../../atoms/buttons/Button';
import { getCookie, setCookie } from '../../utils/helpers';
import { dimensions } from '../../style';

const CookieBannerContainer = styled('div')`
  transition: 0.25s ease-in-out;
  border: none;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  z-index: 1002;
  color: #fff;
  font-weight: 500;
  display: flex;
  ${props => !props.isVisible && 'opacity: 0; height: 0; overflow: hidden; display: none'};
`;

const TextContainer = styled('div')`
  text-align: left;
  padding-right: 10px;
  ${dimensions.SCREEN_MAX_MD} {
    padding-right: 50px;
    padding-bottom: 10px;
  }
`;

const StyledCloseBtn = styled('div')`
  margin-left: 20px;
  cursor: pointer;
  ${dimensions.SCREEN_MAX_MD} {
    position: absolute;
    top: 15px;
    right: 35px;
  }
`;

const CenterContainer = styled('div')`
  margin: 0 auto;
  display: flex;
  align-items: center;
  ${dimensions.SCREEN_MAX_MD} {
    display: block;
  }
`;

interface CookieBannerProps {
  forceShow: any;
}

const CookieBanner = ({ forceShow }: CookieBannerProps): ReactElement => {
  const [cookieBannerStatus, setCookieBannerStatus] = useState({
    isAccepted: getCookie('wyp_isCookieAllowed'),
    message:
      'We use cookies to enhance your experience and analyse site traffic. By continuing to use the site, you consent to the use of cookies.',
  });

  const onHandleClose = () => {
    setCookie('wyp_isCookieAllowed', true, true);
    setCookieBannerStatus({
      ...cookieBannerStatus,
      isAccepted: getCookie('wyp_isCookieAllowed'),
    });
  };

  const CloseBtn = ({ onClickClose }) => (
    <StyledCloseBtn onClick={onClickClose}>
      <svg viewBox="0 0 12 12" height="12" width="12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="11" x2="11" y2="1" stroke="#FFFFFF" strokeWidth="3" />
        <line x1="1" y1="1" x2="11" y2="11" stroke="#FFFFFF" strokeWidth="3" />
      </svg>
    </StyledCloseBtn>
  );

  return (
    <CookieBannerContainer id="CookieBanner" isVisible={forceShow || !cookieBannerStatus.isAccepted}>
      <CenterContainer>
        <TextContainer>{cookieBannerStatus.message}</TextContainer>
        <Button buttonType="primary" type="submit" name="accept" onClick={onHandleClose}>
          Accept
        </Button>
        <CloseBtn onClickClose={onHandleClose} />
      </CenterContainer>
    </CookieBannerContainer>
  );
};

export default CookieBanner;
