import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import { useApp } from '../../contexts/AppContext';
import PWASidebarBannerContent from './PWASidebarBannerContent';
import { getBrowserDetails, evaluateOrOperator } from '../../utils/helpers';
import { trackPWaMixpanelEventInstalled } from '../../utils/pwa';
import { Account, AccountMaker } from '../../models/Account';

const { showInstall, showAndroidInstall, showInstallIosChrome } = getBrowserDetails();

const PWASidebarBannerContainer = styled('div')({
  visibility: 'visible',
});

interface Props {
  ownAccount: Account;
}

const PWASidebarBanner: React.FC = ({ ownAccount }: Props) => {
  const { setShowIntallationBanner, pwaBannerState, setPwaBannerState, setForceCloseSliderMenu } = useApp();

  return (
    <PWASidebarBannerContainer>
      {showAndroidInstall && (
        <PWAInstallerPrompt
          render={({ onClick }) => <PWASidebarBannerContent onClick={onClick} />}
          callback={() => {
            setForceCloseSliderMenu(prev => prev + 1);
          }}
        />
      )}
      {evaluateOrOperator(showInstall, showInstallIosChrome) &&
        !ownAccount.data.account_config?.is_account_installed_pwa_in_ios && (
          <PWASidebarBannerContent
            onClick={() => {
              setPwaBannerState(prev => {
                return {
                  ...prev,
                  show: false,
                };
              });
              setShowIntallationBanner(true);
              setForceCloseSliderMenu(prev => prev + 1);
              trackPWaMixpanelEventInstalled(pwaBannerState.source);
            }}
          />
        )}
    </PWASidebarBannerContainer>
  );
};

const mapStateToProps = state => {
  return {
    ownAccount: AccountMaker.create(state.profile),
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PWASidebarBanner);
