import React from 'react';
import { ButtonColors, ButtonSizes, buttonCommonStyles, ButtonStyleReturnType } from '../../theme/button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { dimensions } from '../../style';
import colors, { white } from '../../theme/colors';
import { theme } from '../../theme';

export const primaryButtonColorStyle = ({ color }: { color: ButtonColors }): ButtonStyleReturnType => {
  return {
    light: {
      ...buttonCommonStyles,
      color: white,
      borderColor: colors[color][500],
      backgroundColor: colors[color][500],
      hover: {
        backgroundColor: colors[color][400],
        borderColor: colors[color][400],
      },
      disabled: {
        color: colors[color][100],
        backgroundColor: colors[color][300],
        borderColor: colors[color][300],
      },
      active: {
        color: colors[color][300],
        backgroundColor: colors[color][600],
        borderColor: colors[color][600],
      },
    },
    dark: {
      ...buttonCommonStyles,
      color: white,
      borderColor: colors[color][500],
      backgroundColor: colors[color][500],
      hover: {
        backgroundColor: colors[color][400],
        borderColor: colors[color][400],
      },
      disabled: {
        color: colors[color][900],
        backgroundColor: colors[color][700],
        borderColor: colors[color][700],
      },
      active: {
        color: colors[color][300],
        backgroundColor: colors[color][600],
        borderColor: colors[color][600],
      },
    },
  };
};

const primaryButtonSizeStyles = (size: ButtonSizes) => {
  const { fontSize, width, height, fontWeight, padding, borderRadius, lineHeight } = buttonCommonStyles;

  return css`
    font-size: ${fontSize[size]};
    width: ${width[size]};
    height: ${height[size]};
    font-weight: ${fontWeight[size]};
    padding: ${padding[size]};
    border-radius: ${borderRadius};
    line-height: ${lineHeight};
  `;
};

const styles = ({ color = 'blue', size = 'small' }: { color: ButtonColors; size: ButtonSizes }) => {
  const { backgroundColor, borderColor, hover, disabled, active, color: defaultColor } = primaryButtonColorStyle({
    color,
  })[theme];

  return css`
    color: ${defaultColor};
    background-color: ${backgroundColor};
    border: 2px solid ${borderColor};
    ${primaryButtonSizeStyles(size)}

    &:hover {
      background-color: ${hover.backgroundColor};
      border: 2px solid ${hover.borderColor};
      color: ${defaultColor};

      & svg {
        & path {
          fill: ${defaultColor} !important;
        }
      }
    }

    &:disabled {
      color: ${disabled.color};
      background-color: ${disabled.backgroundColor};
      border: 2px solid ${disabled.borderColor};
      cursor: not-allowed;
    }

    &:active {
      color: ${active.color};
      background-color: ${active.backgroundColor};
      border: 2px solid ${active.borderColor};
    }

    ${dimensions.SCREEN_MAX_SM} {
      ${primaryButtonSizeStyles('small')}
    }

    ${dimensions.SCREEN_MAX_XS} {
      ${primaryButtonSizeStyles('x-small')}
    }
  `;
};

const StyledButton = styled.button`
  ${styles};
`;

interface IPrimaryButtonProps {
  label: string;
  color: ButtonColors;
  size: ButtonSizes;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick: () => void;
  id?: string;
  withIconLeft?: React.ReactElement;
  withIconRight?: React.ReactElement;
}

const PrimaryButton: React.FC<IPrimaryButtonProps> = (props: IPrimaryButtonProps) => {
  const {
    withIconLeft,
    withIconRight,
    disabled = false,
    customStyle,
    label,
    size = 'regular',
    color = 'blue',
    onClick,
    ...rest
  } = props;
  return (
    <StyledButton disabled={disabled} size={size} color={color} onClick={onClick} style={{ ...customStyle }} {...rest}>
      {withIconLeft}
      {label}
      {withIconRight}
    </StyledButton>
  );
};

export default PrimaryButton;
