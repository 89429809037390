import React, { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '../../components/blocks/Container';
import styled from '@emotion/styled';
import OrderSummary from '../../atoms/billing/OrderSummary';
import CreditCardPanel from '../../atoms/billing/CreditCardPanel';
import { browserHistory, getLocalItem, removeLocalItem, setLocalItem, toaster } from '../../common';
import { textColor, dimensions, textType, backgroundTheme, paymentTheme } from '../../style';
import every from 'lodash/every';
import values from 'lodash/values';
import omit from 'lodash/omit';
import CreditCardSwitcher from '../../atoms/billing/CreditCardSwitcher';
import Button from '../../atoms/buttons/Button';
import BillingHeader from '../../components/blocks/headerNav/BillingHeader';
// import queryString from 'query-string';
import find from 'lodash/find';
import { getBrowserDetails, getCookie, removeCookie, renderTaxLabel, tabletWidth } from '../../utils/helpers';
import LockIcon from '@material-ui/icons/Lock';
import AfterPayment from '../../modules/billing/AfterPayment';
import { fetchEvalFeature, getIDVPackages, promoCodeCheck } from '../../sdk';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import PaymentDeclined from '../../atoms/billing/PaymentDeclined';
import { countryWithTaxByProvince, s3BucketDirect } from '../../config/Master';
import useMixPanel from '../../hooks/useMixPanel';
import { EVENT_NAMES, IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY } from '../../constants/mixpanel';
import { handlePaymentVysionEvent } from '../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { PaymentClickVysionEventsEnum } from '../../utils/vysion/payment/click';
import BillingFormWithMelissaIntegration from '../../modules/billing/BillingFormWithMelissaIntegration';
import {
  DEFAULT_REASON,
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  EVIDENTLY_MELISSA_METRIC_KEYS,
  evidentlyMelissaVariations,
  trackEvidentlyMultipleMetric,
  evidentlyEnabled,
} from '../../constants/evidently';
import BillingForm from '../../modules/billing/BillingForm';
import TrackLoadingToMixpanel from '../../components/common/TrackLoadingToMixPanel';
import { PROMO_COOKIE } from '../../config/constants';
import SofortForm from '../../modules/billing/SofortForm';
import BillingTermsAndCondition from '../../modules/billing/BillingTermsAndCondition';
import BillingLoading from './BillingLoading/BillingLoading';
import Spinner from '../../atoms/loader/Spinner/Spinner';
import GooglePayForm from '../../atoms/billing/GooglePay/GooglePayForm';
import { GPAY_PAYMENT_CHANNEL } from '../../config/gpay';
import './BillingPaymentStyles.css';
import ApplePayForm from '../../modules/ApplePay/ApplePayForm';
import { APAY_PAYMENT_CHANNEL } from '../../config/apay';
import { isApplePayEligible } from '../../utils/billing';

const FormPanel = styled('div')({
  maxWidth: 708,
  margin: '0 auto',
  borderRadius: 10,
  marginBottom: 60,
  [dimensions.SCREEN_MAX_SM]: {
    backgroundColor: 'transparent',
    border: 0,
  },
});

const BillingFormContainer = styled('div')({
  padding: getBrowserDetails().isMobile ? '20px 10px 20px 10px' : '30px 72px',
  maxWidth: 708,
  margin: '0 auto',
  border: '1px solid #24A83A',
  background: backgroundTheme.paymentBlock,
  borderRadius: 10,
});

const BillingFormHeader = styled('div')({
  ...textColor.DARKER,
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20,
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  width: 564,
  [dimensions.SCREEN_MAX_MD_2]: {
    width: 'auto',
    marginBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ProcessingHeader = styled('h3')({
  ...textType.processingHeader,
});

const PackageSection = styled('div')({
  maxWidth: 708,
  margin: '0 auto',
  borderRadius: 10,
  border: `1px solid ${paymentTheme.SUMMARY.borderColor}`,
  padding: 20,
  background: backgroundTheme.orderSummary,
  marginBottom: 20,
  [dimensions.SCREEN_MAX_MD]: {
    padding: 10,
  },
});

const PaymentCheck = styled('img')({
  float: 'left',
});

const PaymentSecureText = styled('span')({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: '-0.18px',
  float: 'left',
});

const PaymentCheckMobile = styled(PaymentCheck)({
  [dimensions.SCREEN_MAX_MD_2]: {
    marginLeft: 'auto',
    float: 'none',
  },
});

const PaymentSecureTextMobile = styled(PaymentSecureText)({
  [dimensions.SCREEN_MAX_MD_2]: {
    marginRight: 'auto',
    float: 'none',
  },
});

const CreditCardPanelMobile = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexBasis: '100%',
});

const PayWithSavedCardButton = styled(Button)({
  margin: '0 auto',
  backgroundColor: 'rgb(19, 131, 210)',
  padding: '16px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
  height: 51,
  width: 232,
  borderRadius: 90,
});

interface BillingPaymentProps {
  savedCard: any;
  errors: any;
  defaultCountry: string;
  defaultState: string;
  profile: any;
  history: History;
  processingPayment: any;
  statuses: any;
  descriptors: any;
  handleSubmit: (cardData) => any;
  handleDeleteSavedCard: (tokenId) => any;
  location: any;
  packages: any;
  calculateChargeAmount: (country, packageId, promoCode, paymentChannel) => any;
  alternativePayment: boolean;
  rocketgateGooglepay: boolean;
  showAutoTopup: boolean;
  setIsLoading?: any;
  isLoading?: boolean;
  applepayCredentials?: any;
}

const BillingPayment = ({
  savedCard,
  errors,
  defaultCountry,
  profile,
  processingPayment,
  statuses,
  descriptors,
  handleSubmit,
  handleDeleteSavedCard,
  packages,
  calculateChargeAmount,
  alternativePayment,
  rocketgateGooglepay,
  defaultState,
  showAutoTopup,
  setIsLoading,
  isLoading = false,
  applepayCredentials,
}: BillingPaymentProps): ReactElement => {
  const profileData = profile.profile.data;
  const [feature, setFeature] = useState(evidentlyMelissaVariations.CONTROL);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [recurring, setRecurring] = useState(true);
  const [allowTokenSubmit, setAllowTokenSubmit] = useState(true);
  const [use2PayData, setUse2PayData] = useState({
    use2pay_package_id: '',
    use2pay_billing_name_on_card: '',
    use2pay_card_number: '',
    use2pay_card_cvv: '',
    use2pay_billing_address: '',
    use2pay_billing_city: '',
    use2pay_billing_state: defaultState,
    use2pay_billing_zip: '',
    use2pay_billing_country: defaultCountry,
    use2pay_card_exp_month: null,
    use2pay_card_exp_year: null,
    promo_code: null,
    save_card: true,
    new_card: true,
    selectedPackage: null,
    payment_channel: null,
    showForm: false,
    showDeclinedPayment: false,
    recurring: false,
    showCvv2: false,
  });
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discount, setDiscount] = useState({
    discount: 0,
    type: null,
    code: null,
  });
  const [selectedPackageData, setSelectedPackageData] = useState({});
  const mounted = useRef();
  const pckgRef = useRef();

  const { setMixpanelDataByKeyHandler } = useMixPanel();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isEvidentlyEnabled = evidentlyEnabled();

  useEffect(() => {
    const getExperimentVariant = async () => {
      if (!isEvidentlyEnabled) {
        removeLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA);
        setIsEvaluating(false);
        return;
      }

      const existingVariation = JSON.parse(getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA));

      if (existingVariation !== null) {
        setIsEvaluating(false);
        const { variation } = existingVariation;
        setFeature(variation);
        return;
      }

      try {
        setIsEvaluating(true);
        const res = await fetchEvalFeature(EVIDENTLY_FEATURES.MELISSA);
        const { userId, reason, variation } = res.data;
        setLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA, JSON.stringify({ variation, userId, reason }));

        if (reason !== DEFAULT_REASON) {
          trackEvidentlyMultipleMetric(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA, [
            {
              experimentName: EVIDENTLY_EXPERIMENTS.MELISSA,
              featureName: EVIDENTLY_FEATURES.MELISSA,
              key: EVIDENTLY_MELISSA_METRIC_KEYS.PACKAGES_PURCHASED,
              value: 0,
            },
            {
              experimentName: EVIDENTLY_EXPERIMENTS.MELISSA,
              featureName: EVIDENTLY_FEATURES.MELISSA,
              key: EVIDENTLY_MELISSA_METRIC_KEYS.PURCHASED_FROM_1ST_ATTEMPT,
              value: 0,
            },
            {
              experimentName: EVIDENTLY_EXPERIMENTS.MELISSA,
              featureName: EVIDENTLY_FEATURES.MELISSA,
              key: EVIDENTLY_MELISSA_METRIC_KEYS.PURCHASED_ERRORS_FROM_KOUNT,
              value: 0,
            },
          ]);
        }

        setFeature(variation);
        setIsEvaluating(false);
      } catch (error) {
        console.error('fetchEvalFeatureSdk.error', error);
        setIsEvaluating(false);
      }
    };

    getExperimentVariant();
  }, [isEvidentlyEnabled]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      setSelectedPackage();
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      if (!use2PayData.showForm && errors && typeof errors.data !== 'undefined') {
        const newData = {
          showForm: true,
          use2pay_card_number: '',
          use2pay_card_cvv: '',
          use2pay_card_exp_month: null,
          use2pay_card_exp_year: null,
          showDeclinedPayment: true,
          showCvv2: errors.data.error_code === 'BILL_CVV2_REQUIRED',
        };

        setAllowTokenSubmit(errors.data.error_code !== 'BILL_CVV2_REQUIRED');

        setUse2PayData({
          ...use2PayData,
          ...newData,
        });
      }
    }
  }, [errors, use2PayData, setSelectedPackage]);

  useEffect(() => {
    if (use2PayData.showForm && !pckgRef.current && defaultCountry === use2PayData.use2pay_billing_country) {
      pckgRef.current = true;
      handleCalculateChargeVat(use2PayData.use2pay_billing_country, use2PayData.selectedPackage);
    }

    browserHistory.listen((loc, action) => {
      if (action === 'POP' && selectedPackageData && selectedPackageData.identifier === 'idv_last_minute') {
        navigate('/dashboard');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackageData]);

  useEffect(() => {
    if (defaultCountry !== use2PayData.use2pay_billing_country) {
      setTimeout(() => {
        const newUse2PayData = {
          ...use2PayData,
          ...{ use2pay_billing_country: defaultCountry },
        };
        setUse2PayData(newUse2PayData);
        handleCalculateChargeVat(defaultCountry, newUse2PayData.selectedPackage, {}, defaultState);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountry]);

  const handleCalculateChargeVat = useCallback(
    async (country, packageData, newCard = {}, state = null, paymentChannel = null) => {
      const newTaxLocation = packageData && typeof packageData.taxes !== 'undefined' ? packageData.taxes : null;
      const currentTaxLocation =
        selectedPackageData && typeof selectedPackageData.taxes !== 'undefined' ? selectedPackageData.taxes : null;
      const taxCountryPayload = haveTaxByProvince(country) && state ? `${country}-${state}` : country;

      if (
        newTaxLocation &&
        currentTaxLocation &&
        taxCountryPayload === currentTaxLocation?.location &&
        ![GPAY_PAYMENT_CHANNEL, APAY_PAYMENT_CHANNEL].includes(paymentChannel)
      ) {
        return;
      }

      if (use2PayData.showForm && packageData.identifier) {
        setIsLoading(true);
        calculateChargeAmount(taxCountryPayload, packageData.identifier, use2PayData.promo_code, paymentChannel)
          .then(response => {
            const chargeVatPackage = response.data.data || {};

            if (chargeVatPackage.identifier) {
              setSelectedPackageData(chargeVatPackage);
              setUse2PayData({
                ...use2PayData,
                ...{
                  selectedPackage: chargeVatPackage,
                  use2pay_billing_country: country,
                  use2pay_billing_state: state ? state : use2PayData.use2pay_billing_state,
                },
                ...newCard,
              });
            }

            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        const selectedPackageCopy = use2PayData && use2PayData.selectedPackage;

        if (selectedPackageCopy) {
          if (selectedPackageCopy.taxes) {
            delete selectedPackageCopy.taxes;
          }

          setUse2PayData({
            ...use2PayData,
            ...{
              selectedPackage: selectedPackageCopy,
              use2pay_billing_country: country,
              use2pay_billing_state: state ? state : use2PayData.use2pay_billing_state,
            },
            ...newCard,
          });
          setSelectedPackageData(selectedPackageCopy);
        }
        setIsLoading(false);
      }
    },
    [calculateChargeAmount, use2PayData, setSelectedPackageData, selectedPackageData]
  );

  const handlePostalCodeChange = newAddress => {
    if (haveTaxByProvince(use2PayData.use2pay_billing_country)) {
      handleCalculateChargeVat(
        use2PayData.use2pay_billing_country,
        use2PayData.selectedPackage,
        {},
        newAddress.use2pay_billing_state
      );
    }
    setUse2PayData({
      ...use2PayData,
      ...newAddress,
    });
  };

  const handleTextFieldChange = (id, value) => {
    const textInput = { [`use2pay_${id}`]: value };
    setUse2PayData({
      ...use2PayData,
      ...textInput,
    });
  };

  const handleTokenCvvInputChange = (event, cardType): any => {
    const amexType = ['american express', 'amex'];
    const maxChar = amexType.includes(cardType?.toLowerCase()) ? 4 : 3;
    const value = ((event?.target?.value || '').match(/\d+/g) || []).join('');
    const charLEngth = value.length || 0;

    event.preventDefault();

    if (charLEngth > maxChar) {
      return;
    }

    const textInput = {
      use2pay_token_cvv: value,
      showDeclinedPayment: charLEngth <= 0,
    };

    setUse2PayData({
      ...use2PayData,
      ...textInput,
    });
    setAllowTokenSubmit(charLEngth === maxChar);
  };

  const handleUpdateMonthYrInput = (mth, yr) => {
    const cardMthYr = {
      use2pay_card_exp_month: mth,
      use2pay_card_exp_year: yr,
    };

    setUse2PayData({
      ...use2PayData,
      ...cardMthYr,
    });
  };

  const handleCountryChange = value => {
    const billingCountry = { use2pay_billing_country: value, showForm: true };
    const newUse2PayData = {
      ...use2PayData,
      ...billingCountry,
    };

    setUse2PayData(newUse2PayData);

    if (haveTaxByProvince(value) && use2PayData.use2pay_billing_state.length > 0) {
      handleCalculateChargeVat(value, use2PayData.selectedPackage, {}, use2PayData.use2pay_billing_state);
    } else {
      handleCalculateChargeVat(value, newUse2PayData.selectedPackage);
    }
  };

  const handleSelectSaveCard = () => {
    if (use2PayData.recurring) return;

    const saveCard = { save_card: !use2PayData.save_card };
    setUse2PayData({
      ...use2PayData,
      ...saveCard,
    });
  };

  const handleChangePaymentOption = (isNewCard, paymentChannel = null) => {
    const newCard = {
      new_card: isNewCard,
      payment_channel: paymentChannel,
      use2pay_payment_channel: paymentChannel,
      use2pay_billing_state: isNewCard ? '' : defaultState,
      showForm: true,
    };

    if (paymentChannel === null) {
      delete newCard.use2pay_payment_channel;
      delete use2PayData.use2pay_payment_channel;
    }

    if (
      isNewCard &&
      haveTaxByProvince(use2PayData.use2pay_billing_country) &&
      use2PayData.use2pay_billing_state !== '' &&
      use2PayData.selectedPackage &&
      use2PayData.selectedPackage.taxes
    ) {
      delete use2PayData.selectedPackage.taxes;
    }

    setUse2PayData({
      ...use2PayData,
      ...newCard,
    });

    if (paymentChannel === null && !isNewCard && savedCard && typeof savedCard.country !== 'undefined') {
      handleCalculateChargeVat(
        savedCard.country,
        use2PayData.selectedPackage,
        newCard,
        newCard.use2pay_billing_state,
        paymentChannel
      );
    }

    if (paymentChannel !== null || (paymentChannel === null && use2PayData.payment_channel !== null)) {
      handleCalculateChargeVat(
        use2PayData.use2pay_billing_country,
        use2PayData.selectedPackage,
        newCard,
        newCard.use2pay_billing_state,
        paymentChannel
      );
    }
  };

  const handleShowPackages = () => {
    if (selectedPackageData && selectedPackageData.identifier === 'idv_last_minute') {
      navigate('/dashboard', {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  const setSelectedPackage = useCallback(async () => {
    // const queryStringParsed = queryString.parse(location.search);

    const promoCode = searchParams.get('promoCode') || null;
    const packageId = searchParams.get('selectedPackageId') || null;
    const isFromIDVPackage = searchParams.get('isFromIDVPackage') === 'true' || null;

    if (!packageId) {
      navigate('/packages');
      return;
    }

    const storedPackages = getLocalItem('wyp_billing_packages');
    let pckgs = storedPackages ? JSON.parse(storedPackages) : packages;
    const selectedPackageId = searchParams.get('selectedPackageId');

    const packageIDVExists = find(pckgs, { identifier: selectedPackageId });

    if (
      (selectedPackageId === 'idv_last_minute' && !isFromIDVPackage) ||
      (selectedPackageId === 'idv_last_minute' && !packageIDVExists && isFromIDVPackage)
    ) {
      const idvPackagesRes = await getIDVPackages();
      const idvPackages = idvPackagesRes.data.data;

      if (idvPackages) {
        pckgs = idvPackages.concat(pckgs);
      }
    }

    if (!pckgs) {
      navigate('/packages');
      return;
    }

    const selectedPackage = find(pckgs, { identifier: selectedPackageId });

    if (!selectedPackage) {
      navigate('/packages');
      toaster.warn('Promo package is no longer valid.');
      return;
    }

    const newData = {
      selectedPackage,
      use2pay_package_id: selectedPackageId || null,
      showForm: true,
      promo_code: promoCode,
      new_card: typeof savedCard === 'undefined',
      recurring: true,
    };

    const newUse2PayData = {
      ...use2PayData,
      ...newData,
    };

    setUse2PayData(newUse2PayData);
    setSelectedPackageData(selectedPackage);
    // Checks promoCode and packageId
    if (promoCode && packageId) {
      const promoCodeRef = getCookie(PROMO_COOKIE);
      const checkRes = await promoCodeCheck(
        profile.profile.data.hash_id,
        promoCode,
        packageId,
        promoCodeRef,
        use2PayData.use2pay_billing_country,
        true
      );

      if (!checkRes.data) {
        navigate('/packages');
        toaster.warn('Promo package is no longer valid.');

        if (promoCodeRef && promoCodeRef === promoCode) {
          removeCookie(PROMO_COOKIE);
        }

        return;
      }

      if (checkRes) {
        let discountAmount = 0;

        if (checkRes.data.discount_rate) {
          const discountRate = checkRes.data.discount_rate;
          discountAmount = selectedPackage.price * discountRate;
        }

        if (checkRes.data.discount_amount) {
          discountAmount = checkRes.data.discount_amount;
        }

        if (checkRes.data.package) {
          setSelectedPackageData(checkRes.data.package.data);
        }

        setDiscount(checkRes.data);
        setDiscountAmount(discountAmount);
      }
    }
  }, [searchParams, packages, profile.profile.data.hash_id, savedCard, use2PayData, setSelectedPackageData]);

  const hidePackageForm = () => {
    const showForm = { showForm: false };
    setUse2PayData({
      ...use2PayData,
      ...showForm,
    });
  };

  const applyPromoCode = code => {
    const newUse2PayData = { promo_code: code };

    setUse2PayData({
      ...use2PayData,
      ...newUse2PayData,
    });
  };

  const haveTaxByProvince = country => countryWithTaxByProvince.split(',').includes(country);

  if ((processingPayment || !use2PayData.showForm) && (statuses && Object.keys(statuses).length) <= 0) {
    return (
      <div
        data-test-id="cy-processing-payment"
        style={{
          textAlign: 'center',
          margin: `${getBrowserDetails().isMobile ? '50%' : '180px'} auto 0`,
          maxWidth: 350,
        }}
      >
        <Spinner />
        <TrackLoadingToMixpanel page="Payment" />
        <ProcessingHeader>Processing payment...</ProcessingHeader>
        <p>
          Please wait a moment as we authorize your payment. You may be asked for additonal verification from your card
          issuer. If so, please return after providing it.
        </p>
      </div>
    );
  }

  const handleAutoTopupChange = () => {
    const newRecurringtState = !recurring;

    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: newRecurringtState
        ? PaymentClickVysionEventsEnum.CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_ON
        : PaymentClickVysionEventsEnum.CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_OFF,
    });

    const recurringData = { recurring: newRecurringtState, save_card: true };
    setUse2PayData({
      ...use2PayData,
      ...recurringData,
    });
    setRecurring(newRecurringtState);
  };

  const isIDV = use2PayData && use2PayData.selectedPackage?.identifier === 'idv_last_minute';
  const renderSummaryHeader = () => {
    return getBrowserDetails().isMobile && tabletWidth ? (
      <BillingFormHeader data-test-id="billing-form-header">
        <PaymentCheckMobile
          src={`${s3BucketDirect}payment/payment_check.png`}
          width="32"
          height="32"
          alt="Secure Payment Details"
        />{' '}
        <PaymentSecureTextMobile>Secure Payment Details</PaymentSecureTextMobile>
        <CreditCardPanelMobile>
          <CreditCardPanel
            isApplePayEligible={isApplePayEligible(applepayCredentials)}
            rocketgateGooglepay={rocketgateGooglepay}
          />
        </CreditCardPanelMobile>
      </BillingFormHeader>
    ) : (
      <BillingFormHeader data-test-id="billing-form-header">
        <PaymentCheck
          src={`${s3BucketDirect}payment/payment_check.png`}
          width="32"
          height="32"
          alt="Secure Payment Details"
        />{' '}
        <PaymentSecureText>Secure Payment Details</PaymentSecureText>{' '}
        <CreditCardPanel
          isApplePayEligible={isApplePayEligible(applepayCredentials)}
          rocketgateGooglepay={rocketgateGooglepay}
        />
      </BillingFormHeader>
    );
  };

  const getPaymentMethod = use2PayData => {
    return use2PayData.payment_channel === 'sofort' ? 'Sofort' : 'Credit Card';
  };

  const handleDeleteCard = tokenId => {
    setUse2PayData({
      ...use2PayData,
      ...{ showDeclinedPayment: false },
    });

    return handleDeleteSavedCard(tokenId);
  };

  const renderBillingForm = () => {
    if (use2PayData.payment_channel && use2PayData.payment_channel === GPAY_PAYMENT_CHANNEL) {
      return (
        <div style={{ marginTop: isIDV ? 20 : 0 }}>
          <GooglePayForm
            paymentChannel={GPAY_PAYMENT_CHANNEL}
            use2PayData={use2PayData}
            handleSubmit={handleSubmit}
            hidePackageForm={hidePackageForm}
            userCountry={profileData.country}
            discount={discountAmount}
            descriptors={descriptors}
          />
        </div>
      );
    }

    if (use2PayData.payment_channel && use2PayData.payment_channel === APAY_PAYMENT_CHANNEL) {
      return (
        <div style={{ marginTop: isIDV ? 20 : 0 }}>
          <ApplePayForm
            use2PayData={use2PayData}
            handleSubmit={handleSubmit}
            hidePackageForm={hidePackageForm}
            userCountry={profileData.country}
            discount={discountAmount}
            applepayCredentials={applepayCredentials}
            descriptors={descriptors}
          />
        </div>
      );
    }

    if (use2PayData.payment_channel && use2PayData.payment_channel === 'sofort') {
      return (
        <SofortForm
          billingFirstName={use2PayData.use2pay_billing_fname}
          billingLastName={use2PayData.use2pay_billing_lname}
          billingChannel={use2PayData.payment_channel}
          billingCountry={use2PayData.use2pay_billing_country}
          handleTextFieldChange={handleTextFieldChange}
          handleUpdateMonthYrInput={handleUpdateMonthYrInput}
          handleCountryChange={handleCountryChange}
          handleSubmit={() => {
            hidePackageForm();
            handleSubmit(
              omit(use2PayData, [
                'new_card',
                'use2pay_billing_address',
                'use2pay_billing_city',
                'use2pay_billing_name_on_card',
                'use2pay_billing_state',
                'use2pay_billing_zip',
                'use2pay_card_cvv',
                'use2pay_card_exp_month',
                'use2pay_card_exp_year',
                'use2pay_card_number',
              ])
            );
          }}
          totalAmount={use2PayData.selectedPackage.price}
          currencyCharCode={use2PayData.selectedPackage.currency.data.label_unicode}
          newCard={savedCard && use2PayData.new_card}
          selectedPackage={use2PayData.selectedPackage}
          descriptors={descriptors}
          userCountry={profileData.country}
          discountAmount={discountAmount}
          recurring={recurring}
        />
      );
    }

    if (savedCard && !use2PayData.new_card) {
      return (
        <div style={{ marginTop: isIDV ? 20 : 0 }}>
          <PayWithSavedCardButton
            buttonType="primary"
            onClick={() => {
              hidePackageForm();

              const tokenPayData = {
                use2pay_package_id: use2PayData.selectedPackage?.identifier,
                use2pay_card_reference: savedCard.token,
                promoCode: use2PayData.promo_code,
                use2pay_billing_country: savedCard.country,
                use2pay_billing_state: savedCard.state,
                address_country: savedCard.country,
                use2pay_token_cvv: use2PayData.use2pay_token_cvv,
                recurring: recurring,
                paymentType: savedCard.payment_type,
              };

              if (tokenPayData.use2pay_billing_state === null || tokenPayData.use2pay_billing_state.length <= 0) {
                delete tokenPayData.use2pay_billing_state;
              }

              handleSubmit(tokenPayData);
              setMixpanelDataByKeyHandler({
                key: EVENT_NAMES.CREDITS_PURCHASED,
                payload: {
                  Amount: selectedPackageData.credits,
                  Total: `${renderTaxLabel(selectedPackageData, discountAmount)}`,
                  AutoTopup: recurring,
                  PaymentMethod: 'Token',
                },
              });
            }}
            disabled={!allowTokenSubmit}
          >
            <LockIcon style={{ fontSize: 19, paddingRight: 3 }} />
            {` Pay ${String.fromCharCode(selectedPackageData.currency?.data.label_unicode)}`}
            {renderTaxLabel(selectedPackageData, discountAmount)}
          </PayWithSavedCardButton>
          <BillingTermsAndCondition userCountry={profileData.country} />
          <BillingPaymentProcessor userCountry={profileData.country} />
          <AfterPayment descriptors={descriptors} />
        </div>
      );
    }

    return (
      <div>
        {!isEvaluating && feature === evidentlyMelissaVariations.CONTROL ? (
          <BillingForm
            nameOnCard={use2PayData.use2pay_billing_name_on_card}
            cardNumber={use2PayData.use2pay_card_number}
            cardCVV={use2PayData.use2pay_card_cvv}
            billingAddress={use2PayData.use2pay_billing_address}
            billingCity={use2PayData.use2pay_billing_city}
            billingState={use2PayData.use2pay_billing_state}
            billingZip={use2PayData.use2pay_billing_zip}
            billingCountry={use2PayData.use2pay_billing_country}
            cardExpMonth={use2PayData.use2pay_card_exp_month}
            cardExpYear={use2PayData.use2pay_card_exp_year}
            onInputChange={handleTextFieldChange}
            onAddressChange={handlePostalCodeChange}
            handleUpdateMonthYrInput={handleUpdateMonthYrInput}
            handleCountryChange={handleCountryChange}
            disablePurchase={!every(values(omit(use2PayData, ['save_card', 'new_card'])), v => Boolean(v))}
            handleSubmit={() => {
              setLocalItem(IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY, 'false');

              hidePackageForm();
              handleSubmit(omit(use2PayData, ['new_card']));

              setMixpanelDataByKeyHandler({
                key: EVENT_NAMES.CREDITS_PURCHASED,
                payload: {
                  Amount: selectedPackageData.credits,
                  Total: `${renderTaxLabel(selectedPackageData, discountAmount)}`,
                  AutoTopup: use2PayData.recurring,
                  PaymentMethod: getPaymentMethod(use2PayData),
                },
              });
            }}
            status={statuses}
            onSelectSaveCard={handleSelectSaveCard}
            saveCard={use2PayData.save_card}
            totalAmount={selectedPackageData.price}
            currencyCharCode={selectedPackageData.currency?.data.label_unicode}
            newCard={savedCard && use2PayData.new_card}
            selectedPackage={selectedPackageData}
            descriptors={descriptors}
            userCountry={profileData.country}
            discountAmount={discountAmount}
            recurring={recurring}
          />
        ) : (
          <BillingFormWithMelissaIntegration
            nameOnCard={use2PayData.use2pay_billing_name_on_card}
            cardNumber={use2PayData.use2pay_card_number}
            cardCVV={use2PayData.use2pay_card_cvv}
            billingAddress={use2PayData.use2pay_billing_address}
            billingCity={use2PayData.use2pay_billing_city}
            billingState={use2PayData.use2pay_billing_state}
            billingZip={use2PayData.use2pay_billing_zip}
            billingCountry={use2PayData.use2pay_billing_country}
            cardExpMonth={use2PayData.use2pay_card_exp_month}
            cardExpYear={use2PayData.use2pay_card_exp_year}
            onInputChange={handleTextFieldChange}
            onAddressChange={handlePostalCodeChange}
            handleUpdateMonthYrInput={handleUpdateMonthYrInput}
            handleCountryChange={handleCountryChange}
            disablePurchase={!every(values(omit(use2PayData, ['save_card', 'new_card'])), v => Boolean(v))}
            handleSubmit={() => {
              setLocalItem(IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY, 'true');

              hidePackageForm();
              handleSubmit(omit(use2PayData, ['new_card']));

              setMixpanelDataByKeyHandler({
                key: EVENT_NAMES.CREDITS_PURCHASED,
                payload: {
                  Amount: selectedPackageData.credits,
                  Total: `${renderTaxLabel(selectedPackageData, discountAmount)}`,
                  AutoTopup: use2PayData.recurring,
                  PaymentMethod: getPaymentMethod(use2PayData),
                },
              });
            }}
            status={statuses}
            onSelectSaveCard={handleSelectSaveCard}
            saveCard={use2PayData.save_card}
            totalAmount={selectedPackageData.price}
            currencyCharCode={selectedPackageData.currency?.data.label_unicode}
            newCard={savedCard && use2PayData.new_card}
            selectedPackage={selectedPackageData}
            descriptors={descriptors}
            userCountry={profileData.country}
            discountAmount={discountAmount}
            recurring={recurring}
          />
        )}
      </div>
    );
  };

  return (
    <Container size="MD">
      <BillingLoading isLoading={isLoading} />
      {getBrowserDetails().isMobile ? (
        <BillingHeader newBilling label="Your Payment Details" onBackClick={() => handleShowPackages()} />
      ) : (
        <BillingHeader label="Your Payment Details" />
      )}
      {(use2PayData.showForm || statuses) && (
        <FormPanel data-test-id="payment-form-panel">
          {selectedPackageData && (
            <PackageSection>
              <OrderSummary
                price={selectedPackageData.price}
                credits={parseFloat(selectedPackageData.credits)}
                currency={selectedPackageData.currency}
                currencyCharCode={selectedPackageData.currency?.data?.label_unicode}
                currencyCode={selectedPackageData.currency?.data?.code}
                vatDetails={selectedPackageData.taxes}
                userCountry={profileData.country}
                isIDV={isIDV}
                discountAmount={discountAmount}
                hasRecurring={showAutoTopup}
                recurring={recurring}
                onAutoTopupChange={handleAutoTopupChange}
                packages={packages}
                selectedPackageData={selectedPackageData}
                discountData={discount}
                profile={profile}
                setDiscount={setDiscount}
                setDiscountAmount={setDiscountAmount}
                applyPromoCode={applyPromoCode}
                taxCountry={use2PayData.use2pay_billing_country}
                paymentChannel={use2PayData.payment_channel}
                setSelectedPackageData={setSelectedPackageData}
              />
            </PackageSection>
          )}
          <BillingFormContainer data-test-id="payment-form-container">
            {/* If has saved card, show switcher */}
            {renderSummaryHeader()}
            {use2PayData.showDeclinedPayment && <PaymentDeclined showTips={!use2PayData.showCvv2} />}
            <CreditCardSwitcher
              alternativePayment={alternativePayment}
              savedCardId={savedCard ? savedCard.id : ''}
              savedCardToken={savedCard ? savedCard.token : ''}
              savedCardType={savedCard ? savedCard.card_type : ''}
              savedCardLastFour={savedCard ? savedCard.last_four : ''}
              savedCardPaymentType={savedCard ? savedCard.payment_type : ''}
              onRadioButtonChangeHandler={handleChangePaymentOption}
              newCard={savedCard ? use2PayData.new_card : true}
              handleDeleteCard={handleDeleteCard}
              paymentChannel={use2PayData.payment_channel}
              use2PayData={use2PayData}
              handleSubmit={handleSubmit}
              handleSelectSaveCard={handleSelectSaveCard}
              discountAmount={discountAmount}
              statuses={statuses}
              savedCard={savedCard}
              rocketgateGooglepay={rocketgateGooglepay}
              onTokenCvvInputChange={handleTokenCvvInputChange}
              showCvv2={use2PayData.showCvv2}
              allowTokenSubmit={allowTokenSubmit}
              isApplePayEligible={isApplePayEligible(applepayCredentials)}
            />
            {renderBillingForm()}
          </BillingFormContainer>
        </FormPanel>
      )}
    </Container>
  );
};

export default BillingPayment;
