import React from 'react';
import styled from '@emotion/styled';

import { type, textColor, spacing, backgroundTheme } from '../../style';
import PlaceholderImg from '../common/PlaceholderImg';

const Container = styled('div')`
  padding: 48px 22px;
  flex: 100%;
  ${props =>
    props.bordered &&
    'border-radius: 3px;' + 'border: 1px solid rgba(0,0,0,0.15)' + 'border-width: 2px;' + 'border-style: dashed'};
`;

const Content = styled('div')({
  textAlign: 'center',
  ...textColor.NORMAL,
});

const ImgContainer = styled('div')({
  marginBottom: '12px',
});

const Title = styled('div')(
  {
    ...type.title.SM,
    ...textColor.DARKER,
    ...spacing.MARGIN_BOTTOM_SM,
  },
  ({ size }) =>
    size === 'SM' && {
      ...type.title.XS,
    }
);

const Message = styled('div')({
  maxWidth: '380px',
  margin: '12px auto 22px',
  color: backgroundTheme.blankDetails,
});

interface BlankSlateProps {
  title: string;
  message: string;
  size?: string;
  bordered?: boolean;
  img?: string;
  action?(): void;
  children?: unknown;
}

const BlankSlate = ({ title, message, size, bordered, img, action, children }: BlankSlateProps): JSX.Element  => {
  return (
    <Container bordered={bordered}>
      <Content>
        {img && (
          <ImgContainer>
            <PlaceholderImg placeholder={img} />
          </ImgContainer>
        )}
        <Title size={size}>{title || 'Not found'}</Title>
        <Message>{message}</Message>
        {action}
        {children}
      </Content>
    </Container>
  );
};

BlankSlate.defaultProps = {
  message: 'Nothing to see here… But there are a lot of potential dates out there to find. Get searching!',
};

export default BlankSlate;
