import React from 'react';

const ddcContent = () =>
  `
  <html>
    <form name="ddcForm" id="ddcForm" method="POST" action="">
      <input type="hidden" name="JWT" id="JWT" value="">
      <input type="hidden" name="Bin" id="Bin" value="">
    </form>
  </html>
`;

const ThreeDSIFrame = React.forwardRef<HTMLIFrameElement, { isChinaUser: boolean; key: string; onLoad: () => void }>(
  ({ isChinaUser, key, onLoad }, ref) =>
    !isChinaUser ? (
      <iframe
        key={key}
        style={{ display: 'none' }}
        ref={ref}
        srcDoc={ddcContent()}
        title="ddcContent"
        data-cy-iframe="ddc"
        onLoad={onLoad}
      />
    ) : null
);

export default ThreeDSIFrame;
