import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Avatar from './Avatar';
import Button from './buttons/Button';
import { Link } from 'react-router-dom';
import { dimensions, spacing, type, borders, color, textColor } from '../style';

const StyledContainer = styled('div')({
  ...textColor.DARKER,
  display: 'flex',
  alignItems: 'center',
  ...spacing.PADDING_MD,
  borderBottom: borders.DEFAULT,

  [dimensions.SCREEN_MIN_MD]: {
    ':hover': {
      backgroundColor: color.GREY_LIGHTEST,
    },
  },

  [dimensions.SCREEN_MAX_SM]: {
    ...spacing.PADDING_SM,
  },
});

const UserLink = styled(Link)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const Title = styled('div')({
  ...type.title.XS,
});

const Meta = styled('div')({
  ...type.SM,
  ...textColor.NORMAL,
});

const ButtonContainer = styled('div')({
  flex: '0 0 auto',
});

interface BlockedUserItemProps {
  hashId: any;
  avatar: any;
  username: any;
  userDetails: any;
  onUnblock: any;
}

const BlockedUserItem = ({ hashId, avatar, username, userDetails, onUnblock }: BlockedUserItemProps): ReactElement => {
  return (
    <StyledContainer>
      <UserLink to={`/profile/${hashId}`}>
        <Avatar
          src={avatar}
          width={70}
          customStyle={{
            ...spacing.MARGIN_RIGHT_MD,
          }}
        />
        <div>
          <Title>{username}</Title>
          <Meta>{userDetails}</Meta>
        </div>
      </UserLink>
      <ButtonContainer>
        <Button buttonType="primary" onClick={() => onUnblock(username, hashId)}>
          Unblock
        </Button>
      </ButtonContainer>
    </StyledContainer>
  );
};

BlockedUserItem.propTypes = {
  hashId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  userDetails: PropTypes.string.isRequired,
  onUnblock: PropTypes.func.isRequired,
};

export default BlockedUserItem;
