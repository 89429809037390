import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setCartAbandonVysion = (param: any): void => {
  switch (param.event) {
    case 'DEACTIVATE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'account-deactivation');
      break;
    case 'GDPR_DELETE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'account-gdpr-delete');
      break;
    default:
      return;
  }
};

export { setCartAbandonVysion };
