/* global google */

import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import _debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import { borders, inputTheme } from '../style';
import TextInput from './TextInput';
import LocationIcon from './icons/Location';
import LocationV2Icon from './icons/LocationV2';
import Loading from '../atoms/Loading';
import { loadGoogleMaps } from '../utils/helpers';
import { usePrevious } from '../utils/customHooks';
import { s3BucketDirect } from '../config/Master';
import {
  searchBytext,
  searchByCoordinates,
  formatTomTomResults,
  TomtomLocation,
  FormattedTomtomLocation,
  tomtomHasNoResults,
} from '../utils/tomtom';
import { tomtomEnabled } from '../config/Master';
import { commonIcons } from './icons/materialIcons';
import Button from './buttons/Button';

const InputWrapper = styled('div')`
  position: relative;
  padding-bottom: 0;
`;

const AutocompleteContainer = styled('div')(
  {
    position: 'absolute',
    top: '100%',
    backgroundColor: '#ffffff',
    border: borders.DARKER,
    borderRadius: 2,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    width: '100%',
    zIndex: 1000,
    ':after': {
      content: '',
      padding: '1px 1px 1px 0',
      height: 16,
      textAlign: 'right',
      display: 'block',
      backgroundImage: tomtomEnabled ? '' : `url(${s3BucketDirect}join_onboarding/powered-by-google-on-white3.png)`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '120px 14px',
    },
  },
  ({ joinFormVariant }: any) => ({
    marginTop: joinFormVariant ? 0 : -24,
  })
);

const AutocompleteItem = styled('div')`
  font-size: 13px;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  color: #555555;
  cursor: default;
  background-color: ${props => (props.isActive ? '#f5f5f5' : '#ffffff')};

  &:hover,
  &:active {
    background-color: #f5f5f5;
  }
`;

const PacIcon = styled('span')`
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(${s3BucketDirect}join_onboarding/autocomplete-icons.png);
  background-size: 34px;
  ${props => props.isMarker && 'background-position: -1px -161px;'};
`;

const LocationIconContainer = styled('div')(
  {
    position: 'absolute',
    cursor: 'pointer',
  },
  ({ pullDown, joinFormVariant, isFetching, isControlRegVersion }: any) => ({
    top: pullDown ? (joinFormVariant ? 30 : 25) : isFetching ? 8 : 2,
    right: isControlRegVersion ? -15 : joinFormVariant ? 7 : isFetching ? 16 : 10,
  })
);

const StyledClearBtn = styled(Button)({
  position: 'absolute',
  padding: 0,
  height: 38,
  width: 38,
  borderRadius: 0,
  top: 1,
  right: 1,
  opacity: 0.7,
  background: inputTheme.clearBtn,
  zIndex: 1000,
  backgroundColor: 'transparent',
});

interface AutocompleteProps {
  inputProps: any;
  onSelect: any;
  onError: any;
  clearItemsOnError: any;
  highlightFirstSuggestion: any;
  options: any;
  debounce: any;
  onEnterKeyDown?: any;
  autocompleteItem: any;
  customInputStyle?: any;
  emptyLocation?: any;
  isLogin?: boolean;
  joinForm?: boolean;
  setFetchingLocation?: any;
  customStyle?: any;
  isEditProfile?: boolean;
  onBoarding?: boolean;
  customStyleLabel?: React.CSSProperties;
  joinFormVariant?: boolean;
  noIcon?: boolean;
  isControlRegVersion?: boolean;
  isRequired?: boolean;
}

const Autocomplete = ({
  inputProps,
  onSelect,
  onError,
  clearItemsOnError,
  highlightFirstSuggestion,
  debounce,
  onEnterKeyDown,
  autocompleteItem,
  customInputStyle = {},
  emptyLocation,
  isLogin = false,
  joinForm = false,
  setFetchingLocation = () => null,
  customStyle = {},
  isEditProfile = false,
  onBoarding = false,
  customStyleLabel,
  options,
  joinFormVariant = false,
  noIcon = false,
  isControlRegVersion = false,
  isRequired = true,
}: AutocompleteProps): ReactElement => {
  const prevInputProps = usePrevious(inputProps);
  const [hasSelected, setHasSelected] = useState(false);
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [fetching, setFetching] = useState(false);
  let autocompleteService = null;
  let autoCompleteOK = null;

  if (typeof window.google !== 'function') {
    loadGoogleMaps(() => {
      if (window.google !== undefined && window.google?.maps?.places) {
        autocompleteService = new window.google.maps.places.AutocompleteService();
        autoCompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
      }
    });
  }

  const fetchPredictions = newInputProp => {
    if (!newInputProp || !newInputProp?.value) return;

    if (hasSelected) return;

    const value = newInputProp?.value ?? '';

    if (value.length < 3) return;

    if (!tomtomEnabled) {
      searchGoogle(value);
      return;
    }

    searchByTomtom(value);
  };

  const debouncedFetchPredictions = useCallback(
    _debounce(newInputProp => fetchPredictions(newInputProp), debounce),
    []
  );

  const searchByTomtom = async (value: string): Promise<void> => {
    let res: null | { data: TomtomLocation[] } = null;
    let tomTomResults: FormattedTomtomLocation[] = [];

    try {
      res = await searchBytext(value);

      // if Tomtom still has no results, search by google then terminate
      if (tomtomHasNoResults(res)) {
        searchGoogle(value);
        return;
      }

      tomTomResults = formatTomTomResults(res?.data ?? []);
    } catch (err) {
      searchGoogle(value);
    }

    // @TODO figure out the problem with autocompleteService/autoCompleteOK being null
    autocompleteService = new window.google.maps.places.AutocompleteService();
    autoCompleteOK = window.google.maps.places.PlacesServiceStatus.OK;

    autocompleteCallback(tomTomResults, 'OK');
  };

  const searchGoogle = value => {
    // @TODO figure out the problem with autocompleteService/autoCompleteOK being null
    autocompleteService = new window.google.maps.places.AutocompleteService();
    autoCompleteOK = window.google.maps.places.PlacesServiceStatus.OK;

    //Google Implementation
    autocompleteService.getPlacePredictions(
      {
        ...options,
        input: value,
      },
      autocompleteCallback
    );
  };

  useEffect(() => {
    if (!window.google) {
      loadGoogleMaps();
      return console.error('Google Maps Javascript API library must be loaded. Retrying...');
    }

    if (window.google && !window.google?.maps?.places) {
      loadGoogleMaps();
      return console.error('Google Maps Places library must be loaded. Retrying...');
    }
  }, []);

  useEffect(() => {
    setStatusMessage('');
    if (!hasSelected && prevInputProps && prevInputProps.value !== inputProps.value)
      debouncedFetchPredictions(inputProps);
  }, [inputProps, prevInputProps, debouncedFetchPredictions, hasSelected]);

  const searchCoordinatesWithGoogle = pos => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: pos }, function(results, state) {
      toggleFetching(false);

      if (state !== 'OK') {
        return console.info('No location results found.');
      }
      const place = results.find(myPlace => myPlace.types.indexOf('locality') > -1);

      if (place) {
        onSelect(place.formatted_address, place.place_id);
      }
    });
  };

  const autoFillLocation = () => {
    if (!('navigator' in window)) {
      return console.warn('Cannot fetch current location. API not supported on this browser');
    }

    const { navigator } = window;

    if (navigator.geolocation === undefined) {
      return console.info('Geolocation API is not supported by this browser');
    }
    const selectLocation = onSelect;
    toggleFetching(true);
    navigator.geolocation.getCurrentPosition(
      position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        if (tomtomEnabled) {
          searchByCoordinates(pos).then(
            res => {
              toggleFetching(false);
              const place: TomtomLocation = res[0];
              if (!place) {
                return console.info('No location results found.');
              }

              selectLocation(place.name, place.placeId, place);
            },
            () => {
              searchCoordinatesWithGoogle(pos);
            }
          );
        } else {
          searchCoordinatesWithGoogle(pos);
        }
      },
      error => {
        toggleFetching(false);
        setStatusMessage(`For some reason, we couldn't figure out your current location. Please enter it manually.`);
        console.error('Error in getting current location:', error);
      }
    );
  };

  const toggleFetching = (processing = false) => {
    setFetching(processing);
    setFetchingLocation(processing);
  };

  const autocompleteCallback = (predictions, state) => {
    if (state !== autoCompleteOK) {
      onError(state);
      if (clearItemsOnError) {
        clearAutocomplete();
      }
      return;
    }

    const formattedSuggestion = structured_formatting => ({
      mainText: structured_formatting.main_text,
      secondaryText: structured_formatting.secondary_text,
    });

    setAutocompleteItems(
      predictions.map((p, idx) => ({
        suggestion: p.description,
        placeId: p.place_id,
        active: highlightFirstSuggestion && idx === 0,
        index: idx,
        details: typeof p.details !== 'undefined' ? p.details : null,
        formattedSuggestion: formattedSuggestion(p.structured_formatting),
      }))
    );
  };

  const clearAutocomplete = () => {
    setAutocompleteItems([]);
  };

  const selectAddress = (address, placeId, details = null) => {
    clearAutocomplete();
    handleSelect(address, placeId, details);
  };

  const handleSelect = (address, placeId, details = null) => {
    setHasSelected(true);
    onSelect ? onSelect(address, placeId, details) : inputProps.onChange(address);
  };

  const getActiveItem = () => {
    return autocompleteItems.find(item => item.active);
  };

  const selectActiveItemAtIndex = index => {
    setActiveItemAtIndex(index);
  };

  const handleEnterKey = () => {
    const activeItem = getActiveItem();
    if (activeItem === undefined) {
      handleEnterKeyWithoutActiveItem();
    } else {
      selectAddress(activeItem.suggestion, activeItem.placeId, activeItem.details);
    }
  };

  const handleEnterKeyWithoutActiveItem = () => {
    if (onEnterKeyDown) {
      onEnterKeyDown(inputProps.value);
      clearAutocomplete();
      debouncedFetchPredictions.cancel();
    }
  };

  const handleDownKey = () => {
    if (autocompleteItems.length === 0) {
      return;
    }

    const activeItem = getActiveItem();
    if (activeItem === undefined) {
      selectActiveItemAtIndex(0);
    } else {
      const nextIndex = (activeItem.index + 1) % autocompleteItems.length;
      selectActiveItemAtIndex(nextIndex);
    }
  };

  const handleUpKey = () => {
    if (autocompleteItems.length === 0) {
      return;
    }

    const activeItem = getActiveItem();
    if (activeItem === undefined) {
      selectActiveItemAtIndex(autocompleteItems.length - 1);
    } else {
      let prevIndex;
      if (activeItem.index === 0) {
        prevIndex = autocompleteItems.length - 1;
      } else {
        prevIndex = (activeItem.index - 1) % autocompleteItems.length;
      }
      selectActiveItemAtIndex(prevIndex);
    }
  };

  const handleInputKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        handleEnterKey();
        break;
      case 'ArrowDown':
        event.preventDefault();
        handleDownKey();
        break;
      case 'ArrowUp':
        event.preventDefault();
        handleUpKey();
        break;
      case 'Escape':
        event.preventDefault();
        clearAutocomplete();
        break;
      default:
        break;
    }

    if (inputProps.onKeyDown) {
      inputProps.onKeyDown(event);
    }
  };

  const setActiveItemAtIndex = index => {
    setAutocompleteItems(
      autocompleteItems.map((item, idx) => {
        if (idx === index) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );
  };

  const handleInputChange = event => {
    setHasSelected(false);
    inputProps.onChange(event.target.value);
    if (!event.target.value) {
      clearAutocomplete();
      return;
    }

    debouncedFetchPredictions();
  };

  const handleInputOnBlur = event => {
    clearAutocomplete();

    if (inputProps.onBlur) {
      inputProps.onBlur(event);
    }
  };

  const handleInputClear = () => {
    setHasSelected(false);
    inputProps.onChange('');
    clearAutocomplete();
  };

  const getInputProps = () => {
    return {
      ...inputProps,
      autoComplete: 'off',
      onChange: event => {
        handleInputChange(event);
      },
      onKeyDown: event => {
        handleInputKeyDown(event);
      },
      onBlur: event => {
        handleInputOnBlur(event);
      },
      onFocus: event => {
        /** Make a call to the Places API and show a dropdown when the user refocuses the input */
        handleInputChange(event);
        if (inputProps.onFocus) {
          inputProps.onFocus(event);
        }
      },
    };
  };

  const sanitisedInputProps = getInputProps();
  // Pull status off inputProps instead of typing inputProps.status everytime
  const { status, ...rest } = sanitisedInputProps;
  const autoFillErrorMessage = sanitisedInputProps.statusMessage;

  const errorMessage = statusMessage || autoFillErrorMessage;

  const autoCompleteOption = () => {
    if (tomtomEnabled) {
      return !hasSelected && autocompleteItems.length > 0;
    }
    return autocompleteItems.length > 0;
  };

  const renderIcon = () => {
    if (noIcon) return;

    return joinFormVariant ? (
      <LocationV2Icon fill={onBoarding ? '#2B8FD7' : '#80868B'} onClick={autoFillLocation} />
    ) : (
      <LocationIcon
        fill={onBoarding ? '#2B8FD7' : '#80868B'}
        isEditProfile={isEditProfile}
        onClick={autoFillLocation}
      />
    );
  };
  return (
    <InputWrapper style={joinFormVariant ? { position: 'relative' } : {}}>
      <TextInput
        {...rest}
        inputProps={inputProps}
        isLogin={isLogin}
        joinForm={joinForm}
        customInputStyle={customInputStyle}
        status={((emptyLocation ? emptyLocation : autoFillErrorMessage) && 'error') || status}
        statusMessage={(emptyLocation ? 'Location is required' : errorMessage) || ''}
        customStyle={customStyle}
        customStyleLabel={customStyleLabel}
        isRequired={isRequired}
      />
      {inputProps.value !== '' && !joinFormVariant && !isLogin && (
        <StyledClearBtn buttonType="chromeless" onClick={() => handleInputClear}>
          <span onClick={handleInputClear}>
            <commonIcons.close.clear />
          </span>
        </StyledClearBtn>
      )}
      <LocationIconContainer
        isFetching={fetching}
        pullDown={Boolean(rest.inputLabel)}
        joinFormVariant={joinFormVariant}
        isControlRegVersion={isControlRegVersion}
      >
        {fetching ? (
          <Loading width={joinFormVariant ? 22 : 22} color="#CACACA" isEditProfile={isEditProfile} />
        ) : (
          renderIcon()
        )}
      </LocationIconContainer>
      {autoCompleteOption() && (
        <AutocompleteContainer joinFormVariant={joinFormVariant || noIcon}>
          {autocompleteItems.map(p => (
            <AutocompleteItem
              isActive={p.active}
              key={p.placeId}
              onMouseOver={() => setActiveItemAtIndex(p.index)}
              onMouseDown={() => selectAddress(p.suggestion, p.placeId, p.details)}
              //Add Touch Events to work on mobile
              onTouchStart={() => setActiveItemAtIndex(p.index)}
              onTouchEnd={() => selectAddress(p.suggestion, p.placeId, p.details)}
            >
              {autocompleteItem({ suggestion: p.suggestion, formattedSuggestion: p.formattedSuggestion })}
            </AutocompleteItem>
          ))}
        </AutocompleteContainer>
      )}
    </InputWrapper>
  );
};

Autocomplete.defaultProps = {
  clearItemsOnError: true,
  onError: status =>
    console.error(
      '[Autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
      status
    ),
  autocompleteItem: ({ suggestion }) => (
    <div>
      <PacIcon isMarker />
      {suggestion}
    </div>
  ),
  // https://developers.google.com/places/web-service/supported_types
  options: {
    types: ['(cities)'],
  },
  debounce: 500,
  highlightFirstSuggestion: true,
};

export default Autocomplete;
