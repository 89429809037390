import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useApp } from '../../contexts/AppContext';
import PWAGlobalBannerContent from './PWAGlobalBannerContent';
import { trackPWaMixpanelEventInstalled } from '../../utils/pwa';
import queryString from 'query-string';
import MemoizedPWAndroidInstallerPrompt from './PWAAndroidInstallerPrompt';

const PWAGlobalBannerContainer = styled('div')({
  display: 'none',
});

const PWAGlobalBanner: React.FC = () => {
  const { pwaBannerState, setShowIntallationBanner, setPwaBannerState } = useApp();
  const location = useLocation();
  const excludeToShowBanner = ['mail'];
  const isExcludeToShowBanner = excludeToShowBanner.includes(location.pathname.split('/')[1]);

  const queryStringParsed = queryString.parse(location.search);
  const isFirsTimeLogin = queryStringParsed.userFirstTimeLogin === '1';

  return (
    <PWAGlobalBannerContainer style={{ display: isExcludeToShowBanner ? 'none' : 'block' }}>
      {pwaBannerState.show && pwaBannerState.platform === 'android' && <MemoizedPWAndroidInstallerPrompt />}
      {pwaBannerState.show && pwaBannerState.platform === 'ios' && (
        <PWAGlobalBannerContent
          onClick={() => {
            setPwaBannerState(prev => {
              return {
                ...prev,
                show: false,
              };
            });
            trackPWaMixpanelEventInstalled(isFirsTimeLogin ? 'Registration' : pwaBannerState.source);
            setShowIntallationBanner(true);
          }}
        />
      )}
    </PWAGlobalBannerContainer>
  );
};

export default PWAGlobalBanner;
