import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';

const api = MasterConfig.api;

const addNewMedia = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/video`,
    data: formData,
  });
};

const deleteMedia = (token: any, id: any): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/video/${id}`,
    data: {},
  });
};

const updateMediaPrivacy = (token: any, id: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/video/${id}`,
    data: {},
  });
};

const bulkDelete = (formData: any): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/gallery/delete-all`,
    data: formData,
  });
};

const bulkTogglePrivacy = (formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/gallery/toggle-privacy-all`,
    data: formData,
  });
};

export { addNewMedia, deleteMedia, updateMediaPrivacy, bulkDelete, bulkTogglePrivacy };
