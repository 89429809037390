import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import DashboardDiscoverModeSwipeLeftIcon from './icons/DashboardDiscoverModeSwipeLeftIcon';
import {
  TutorialSectionWrapper,
  TutorialSectionSubheader,
  TutorialHeader,
  TutorialComponentContainer,
  IconDescription,
  TutorialSectionSubHeaderWrapper,
  TutorialSectionSwipeInfoWrapper,
  TutorialSectionProfileInfoWrapper,
} from './DashboardDisCoverModeStyle';
import DashboardDiscoverModeSwipeRightIcon from './icons/DashboardDiscoverModeSwipeRightIcon';
import DashboardDiscoverModeGotItButton from './components/DashboardDiscoverModeGotItButton';
import DashboardDiscoverModeArrowLeftIcon from './icons/DashboardDiscoverModeArrowLeftIcon';
import DashboardDiscoverModeArrowRightIcon from './icons/DashboardDiscoverModeArrowRightIcon';
import DashboardDiscoverModeInfoIcon from './icons/DashboardDiscoverModeInfoIcon';
import { useApp } from '../../../contexts/AppContext';
import DiscoverModeNewVariantPassIcon from './icons/DiscoverModeNewVariantPassIcon';
import DiscoverModeNewVariantOfferIcon from './icons/DiscoverModeNewVariantOfferIcon';
import { Profile, ProfileMaker } from '../../../models/Profile';
import { dimensions } from '../../../style';
import { getBrowserDetails } from '../../../utils/helpers';

interface ITutorialPanel {
  transform: string;
  opacity: number;
}

export const TutorialPanel = styled('div')(({ transform, opacity }: ITutorialPanel) => ({
  overflow: 'hidden',
  overflowY: 'scroll',
  transition: 'transform 0.5s ease , opacity 0.5s ease',
  opacity,
  paddingLeft: 50,
  paddingRight: 50,
  transform,
  [dimensions.SCREEN_MIN_MD]: {
    opacity: 1,
    transform: 'translateY(0%)',
    overflowY: 'hidden',
  },
}));

interface IProps {
  onConfirm: () => void;
  ownProfile: Profile;
}

const DashboardDiscoverModeTutorial: React.FC<IProps> = ({ onConfirm, ownProfile }: IProps) => {
  const { siteFooterLogoRef } = useApp();
  const [activePanel, setActivePanel] = useState<string>('panel1');
  const [tutorialTransform, setTutorialTransform] = useState<string>('translateY(0%)');
  const [dynamicHeight, setDynamicHeight] = useState();

  const { isMobile } = getBrowserDetails();

  const handlePanelTransition = () => {
    setActivePanel(prevPanel => (prevPanel === 'panel1' ? 'panel2' : 'panel1'));
  };

  const handleHideTutorial = () => {
    setTutorialTransform('translateY(100%)');
    onConfirm();
  };

  useEffect(() => {
    const siteFooterLogoEl = siteFooterLogoRef?.current;
    if (siteFooterLogoEl) {
      const siteFooterLogoClRec = siteFooterLogoEl.getBoundingClientRect();
      const SITE_HEADER_HEIGHT = 48;

      setDynamicHeight(`${siteFooterLogoClRec.top - SITE_HEADER_HEIGHT}px`);
    }
  }, [siteFooterLogoRef?.current]);

  return (
    <TutorialComponentContainer style={{ transform: tutorialTransform }}>
      <TutorialPanel
        id="panel1"
        transform={activePanel === 'panel1' ? 'translateY(0%)' : 'translateY(-100%)'}
        opacity={activePanel === 'panel1' ? 1 : 0}
        style={{
          height: dynamicHeight,
          maxHeight: dynamicHeight,
        }}
      >
        <TutorialSectionWrapper paddingTop={50}>
          <TutorialHeader>Welcome to the new Discover page</TutorialHeader>
        </TutorialSectionWrapper>
        <TutorialSectionSubHeaderWrapper>
          <TutorialSectionSubheader maxWidth={241} style={{ margin: 'auto' }}>
            Sending date {ownProfile.isGenerous() ? 'offers' : 'offer requests'} to new members is easier than ever!
          </TutorialSectionSubheader>
        </TutorialSectionSubHeaderWrapper>
        <TutorialSectionSwipeInfoWrapper>
          <TutorialSectionWrapper>
            <DashboardDiscoverModeSwipeLeftIcon customStyle={{ marginRight: 42 }} />
            <DiscoverModeNewVariantPassIcon customStyle={{ position: 'relative', top: -6 }} />
            <IconDescription>Swipe Left or Tap to Pass</IconDescription>
          </TutorialSectionWrapper>
          <TutorialSectionWrapper marginBottom={36}>
            <DashboardDiscoverModeSwipeRightIcon customStyle={{ marginRight: 42 }} />
            <DiscoverModeNewVariantOfferIcon customStyle={{ position: 'relative', top: -2, left: 5 }} />
            <IconDescription>
              Swipe Right or {isMobile && <br />}
              Tap to {!isMobile && <br />} Send {ownProfile.isGenerous() ? 'an Offer' : 'an Offer Request'}
            </IconDescription>
          </TutorialSectionWrapper>
        </TutorialSectionSwipeInfoWrapper>
        {isMobile && <DashboardDiscoverModeGotItButton onConfirm={handlePanelTransition} />}
      </TutorialPanel>

      <TutorialPanel
        id="panel2"
        transform={activePanel === 'panel2' ? 'translateY(-100%)' : 'translateY(0%)'}
        opacity={activePanel === 'panel2' ? 1 : 0}
        style={{
          height: dynamicHeight,
          maxHeight: dynamicHeight,
        }}
      >
        {isMobile && (
          <TutorialSectionWrapper style={{ paddingTop: 71 }}>
            <TutorialHeader>Like what you see?</TutorialHeader>
          </TutorialSectionWrapper>
        )}
        <TutorialSectionSubHeaderWrapper>
          <TutorialSectionSubheader>
            Tap these icons to see more {isMobile && <br />} about the member
          </TutorialSectionSubheader>
        </TutorialSectionSubHeaderWrapper>
        <TutorialSectionSwipeInfoWrapper>
          <TutorialSectionProfileInfoWrapper marginBottom={72}>
            <DashboardDiscoverModeArrowLeftIcon customStyle={{ marginRight: 42 }} />
            <DashboardDiscoverModeArrowRightIcon />
            <IconDescription style={{ maxWidth: 241, margin: 'auto', marginTop: 30 }}>
              View additional profile photos {isMobile && <>(or tap the right or left sides of the photo)</>}
            </IconDescription>
          </TutorialSectionProfileInfoWrapper>
          <TutorialSectionProfileInfoWrapper>
            <DashboardDiscoverModeInfoIcon />
            <IconDescription style={{ marginTop: 30 }}>Check out the full profile</IconDescription>
          </TutorialSectionProfileInfoWrapper>
        </TutorialSectionSwipeInfoWrapper>
        <DashboardDiscoverModeGotItButton onConfirm={handleHideTutorial} />
      </TutorialPanel>
    </TutorialComponentContainer>
  );
};

const mapStateToProps = state => {
  return {
    ownProfile: ProfileMaker.create(state.profile.profile),
  };
};

export default connect(mapStateToProps, {})(DashboardDiscoverModeTutorial);
