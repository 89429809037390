import styled from '@emotion/styled';
import { dimensions, backgroundTheme } from '../../../../style';
import { getBrowserDetails, IsMobileViewPort, tabletWidth, ternaryFunc } from '../../../../utils/helpers';
const PhotoAspectRatio = styled('div')(
  {
    paddingTop: '133%',
    position: 'relative',
  },
  ({ isPrivate }) => isPrivate && { backgroundColor: backgroundTheme.gallery }
);

const PhotoStatusTitle = styled('span')(
  {
    fontSize: 13,
    fontWeight: 400,
    verticalAlign: 'middle',
  },
  ({ mobilePending }) => ({ paddingLeft: mobilePending ? 2 : 0 }),
  ({ isPending }) => ({ paddingLeft: isPending ? 4 : 5 })
);

const PhotoStatus = styled('div')(
  {
    padding: '3px 0',
    backgroundColor: 'rgba(13,92,147,0.9)',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'left',
    color: '#fff',
    cursor: 'auto',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  ({ isCenterAlign }) => ({ textAlign: isCenterAlign ? 'center' : 'left' })
);

const Photo = styled('div')(
  {
    width: '100%',
    height: '100%',
    borderRadius: 6,
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  ({ imgUrl }) => {
    return {
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }
);

const PrivateTotal = styled('div')({
  position: 'absolute',
  top: '0',
  margin: '0 auto',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
  color: '#3a9be1',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #dde3ec',
  borderRadius: 6,
  boxSizing: 'border-box',
});

const VideoInProgress = styled('div')(({ photoHeight }) => ({
  boxSizing: 'border-box',
  border: '2px solid #3e79a5',
  background: 'repeating-linear-gradient(75deg, #1667A0 0 15px,#0E5D92 15px 30px)',
  borderRadius: 4,
  textAlign: 'center',
  cursor: 'pointer',
  paddingTop: !getBrowserDetails().isMobile ? '15%' : ternaryFunc(IsMobileViewPort, '15%', '44%'),
  width: '100%',
  height: '100%',
  color: '#CAE3F5',
  fontWeight: 'bold',
  position: 'absolute',
  top: 0,
  minHeight: photoHeight ? photoHeight : ternaryFunc(tabletWidth, 237, '100%'),
  [dimensions.SCREEN_MAX_MD]: {
    minHeight: photoHeight ? photoHeight : ternaryFunc(IsMobileViewPort, 148, 237),
    fontSize: 12,
  },
}));

const MediaPlayWrap = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
});

export { Photo, PhotoAspectRatio, PhotoStatusTitle, PhotoStatus, PrivateTotal, VideoInProgress, MediaPlayWrap };
