import React, { ReactElement } from 'react';

interface AddContentIconProps {
  cssStyle?: any;
  onBoarding?: any;
}

const ProfileCamera = ({ cssStyle = {}, onBoarding = '#8F8F8F' }: AddContentIconProps): ReactElement => {
  return (
    <svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={cssStyle}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.76614 3.63545H11.1383C12.081 2.1702 12.738 0 14.3996 0H22.717C24.3786 0 25.0356 2.1702 25.9783 3.63545H34.2339C35.755 3.63545 37 4.89378 37 6.43121V24.2042C37 25.7417 35.755 27 34.2339 27H2.76614C1.245 27 0 25.7417 0 24.2042V6.43121C0 4.89378 1.245 3.63545 2.76614 3.63545ZM4.64434 1.86223C4.57293 1.86223 4.51579 1.92238 4.51579 1.99216V3.10132C4.51579 3.1735 4.57531 3.23124 4.64434 3.23124H8.05559C8.127 3.23124 8.18413 3.17109 8.18413 3.10132V1.99216C8.18413 1.91998 8.12462 1.86223 8.05559 1.86223H4.64434ZM28.9135 6.92443C28.754 6.92443 28.6254 7.05676 28.6254 7.21556V9.68651C28.6254 9.84771 28.7564 9.97763 28.9135 9.97763H32.5532C32.7127 9.97763 32.8413 9.8453 32.8413 9.68651V7.21556C32.8413 7.05436 32.7104 6.92443 32.5532 6.92443H28.9135ZM16.6063 10.2976C13.7616 11.3442 12.2976 14.5225 13.3308 17.3953C14.3663 20.2705 17.5109 21.7501 20.3532 20.7059C23.1979 19.6593 24.6619 16.481 23.6288 13.6083C22.5933 10.7331 19.4486 9.25343 16.6063 10.2976ZM11.2288 18.17C12.6856 22.2169 17.1134 24.3053 21.1197 22.8304C25.1237 21.358 27.19 16.8828 25.7307 12.8335C24.2739 8.78667 19.8462 6.69827 15.8398 8.17314C11.8358 9.64561 9.76954 14.1207 11.2288 18.17Z"
        fill={onBoarding}
      />
    </svg>
  );
};

export default ProfileCamera;
