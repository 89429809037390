import React, { useState } from 'react';
import styled from '@emotion/styled';
import Transition from 'react-transition-group/Transition';
import { connectScreenSize } from 'react-screen-size';

import { type, borders, color, spacing, dimensions, textColor } from '../../style';

const DropArrow = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({ hasSelectedCount }) => hasSelectedCount && { color: color.BRAND_2 }
);

const SelectedCount = styled('div')({
  fontSize: '14px',
});

const HeaderContainer = styled('div')(
  {
    margin: '0',
    ...type.MD,
    fontWeight: 400,
    color: '#484848',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'justify',
    paddingTop: 16,

    [dimensions.SCREEN_MIN_SM]: {
      float: 'left',
      width: '25%',
      paddingTop: 10,
      fontWeight: 400,
      fontSize: 18,
    },
  },

  ({ collapsable }) => ({
    paddingBottom: collapsable ? 16 : 6,
    [dimensions.SCREEN_MAX_SM]: {
      cursor: collapsable ? 'pointer' : 'inherit',
    },
  })
);

const Header = styled('div')({
  display: 'inline-block',
  verticalAlign: 'middle',
  flexGrow: '1',
  ...textColor.DARKER,
});

const ArrowContainer = styled('div')({
  marginLeft: '8px',
});

const ArrowSvg = styled('svg')(
  {
    display: 'block',
    fill: 'currentcolor',
    height: '10px',
    width: '10px',

    transitionProperty: 'transform',
    transitionDuration: '250ms',
    transitionTimingFunction: 'ease-in-out',
  },
  ({ arrowRotation }) => ({ transform: `rotate(${arrowRotation}deg)` })
);

const FilterItem = styled('div')({
  display: 'inline-block',
  width: '100%',

  [dimensions.SCREEN_MIN_XS]: {
    width: '50%',
  },

  [dimensions.SCREEN_MIN_MD]: {
    width: '33.333%',
  },
});

const SectionContent = styled('div')({
  [dimensions.SCREEN_MIN_SM]: {
    width: '75%',
    display: 'inline-block',
  },
});

const FilterGroup = styled('div')({
  display: 'inline-block',
  width: '100%',
  borderBottom: borders.DEFAULT,

  [dimensions.SCREEN_MIN_SM]: {
    ...spacing.PADDING_TOP_MD,
    ...spacing.PADDING_BOTTOM_MD,
  },
});

const FilterBody = styled('div')(
  {
    opacity: 0,
    transition: 'transform 300ms, opacity 300ms',
    // overflow: 'hidden',
    height: 0,
    transform: 'translateY(-20px)',
  },
  ({ transitioned }) => ({
    [dimensions.SCREEN_MAX_SM]: {
      marginBottom: transitioned ? 16 : 0,
    },
  }),
  ({ status }) => {
    if (status === 'entered') {
      return {
        height: 'auto',
        opacity: 1,
        transform: 'translateY(0px)',
        // overflow: 'auto'
      };
    }
    if (status === 'exiting') {
      return {
        height: 'auto',
        transform: 'translateY(-20px)',
        opacity: 0,
        // overflow: 'auto'
      };
    }
  }
);

const FilterRow = styled('div')({
  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 16,
  },
});

const FilterSection = ({ fullWidth, collapsable, header, mobile, numActive, hideSelectedText, children }) => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const buildFilterItems = () => {
    //noCols: stops the iteration of the immediate children, just returns
    // all the children wrapped in a filterItem.

    const items = React.Children.map(children, child => {
      return <FilterItem>{child}</FilterItem>;
    });

    return <SectionContent>{fullWidth ? children : items}</SectionContent>;
  };

  const arrowRotation = menuActive ? 180 : 0;

  const transitionedSection = displayTransition => {
    if (displayTransition === true) {
      return (
        <Transition
          in={menuActive}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          mountOnEnter
          unmountOnExit
        >
          {status => (
            <FilterBody status={status} transitioned>
              {buildFilterItems()}
            </FilterBody>
          )}
        </Transition>
      );
    }
    return <FilterRow>{buildFilterItems()}</FilterRow>;
  };

  const renderDropArrow = () => {
    const selectedText = !hideSelectedText ? ' selected' : '';
    const currCount = numActive ? numActive + selectedText : '';

    return (
      <DropArrow hasSelectedCount={currCount}>
        <SelectedCount>{currCount}</SelectedCount>
        <ArrowContainer>
          <ArrowSvg
            viewBox="0 0 18 18"
            role="presentation"
            aria-hidden="true"
            focusable="false"
            arrowRotation={arrowRotation}
          >
            <path
              fillRule="evenodd"
              d="M16.291 4.295a1 1 0 1 1 1.414 1.415l-8 7.995a1 1 0 0 1-1.414 0l-8-7.995a1 1 0 1 1 1.414-1.415l7.293 7.29 7.293-7.29z"
            />
          </ArrowSvg>
        </ArrowContainer>
      </DropArrow>
    );
  };

  return (
    <FilterGroup>
      <HeaderContainer onClick={toggleMenu} collapsable={collapsable}>
        <Header>{header}</Header>
        {collapsable && mobile && renderDropArrow()}
      </HeaderContainer>
      {transitionedSection(collapsable && mobile)}
    </FilterGroup>
  );
};

const mapScreenSizeToProps = screenSize => screenSize;

export default connectScreenSize(mapScreenSizeToProps)(FilterSection);
