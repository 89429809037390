import { vysionService, vysionTrack } from '../utils/vysion/vysionService';
import { convertSentanceTitleCase, convertTitleCase } from '../utils/helpers';

const setProfileAction = (eventType: any): any => {
  return {
    type: 'OFFERS',
    payload: {
      event: eventType,
      eventData: {
        action: setActionType(eventType),
        page: setEventPage(eventType),
        extraInfo: setExtraInfo(eventType),
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setActionType = eventType => {
  switch (eventType) {
    case 'DISPLAY MODAL OFFER':
      return 'view';
    case 'SEND AMOUNT CUSTOM':
      return 'focus';
    default:
      return 'click';
  }
};

const setEventPage = eventType => {
  switch (eventType) {
    case 'MAKE OFFER PROFILE':
    case 'CANCEL OFFER PROFILE':
    case 'IGNORE OFFER PROFILE':
    case 'COUNTER OFFER PROFILE':
    case 'ACCEPT OFFER PROFILE':
    case 'PROFILE PHOTO':
    case 'OTHER PROFILE PHOTO':
    case 'PRIVATE PHOTO':
      return 'Profile';
    case 'DISPLAY MODAL OFFER':
    case 'SEND OFFER':
    case 'ACCEPT OFFER':
    case 'REVISE OFFER':
    case 'DECLINE OFFER':
    case 'SEND CANCEL':
    case 'SEND CLOSE':
    case 'SEND SUGGEST':
    case 'SEND AMOUNT CUSTOM':
    case 'SEND AMOUNT 50':
    case 'SEND AMOUNT 75':
    case 'SEND AMOUNT 100':
    case 'SEND AMOUNT 150':
    case 'SEND AMOUNT 200':
    case 'UNLOCK MESSAGE':
      return 'Offer Modal';
  }
};

const splitEventName = eventName => {
  const splitName = eventName.split(' ');
  splitName.splice(2, 1);
  return splitName.join(' ');
};

const setComponent = eventType => {
  switch (eventType) {
    case 'MAKE OFFER PROFILE':
      return convertSentanceTitleCase(splitEventName(eventType));
    case 'CANCEL OFFER PROFILE':
      return convertSentanceTitleCase(splitEventName(eventType));
    case 'IGNORE OFFER PROFILE':
      return convertSentanceTitleCase(splitEventName(eventType));
    case 'COUNTER OFFER PROFILE':
      return convertSentanceTitleCase(splitEventName(eventType));
    case 'ACCEPT OFFER PROFILE':
      return convertSentanceTitleCase(splitEventName(eventType));
    case 'DISPLAY MODAL OFFER':
      return convertTitleCase(
        eventType
          .split(' ')
          .splice(0, 1)
          .join('')
      );
    case 'SEND OFFER':
    case 'ACCEPT OFFER':
    case 'REVISE OFFER':
    case 'DECLINE OFFER':
    case 'UNLOCK MESSAGE':
      return convertSentanceTitleCase(eventType);
    case 'SEND CANCEL':
      return convertTitleCase(
        eventType
          .split(' ')
          .splice(1, 1)
          .join('')
      );
    case 'SEND CLOSE':
      return convertTitleCase(
        eventType
          .split(' ')
          .splice(1, 1)
          .join('')
      );
    case 'SEND SUGGEST':
      return convertTitleCase(
        eventType
          .split(' ')
          .splice(1, 1)
          .join('')
      );
    case 'SEND AMOUNT CUSTOM':
      return 'Custom Amount';
    case 'SEND AMOUNT 50':
      return eventType
        .split(' ')
        .splice(2, 1)
        .join('');
    case 'SEND AMOUNT 75':
      return eventType
        .split(' ')
        .splice(2, 1)
        .join('');
    case 'SEND AMOUNT 100':
      return eventType
        .split(' ')
        .splice(2, 1)
        .join('');
    case 'SEND AMOUNT 150':
      return eventType
        .split(' ')
        .splice(2, 1)
        .join('');
    case 'SEND AMOUNT 200':
      return eventType
        .split(' ')
        .splice(2, 1)
        .join('');
    default:
      return null;
  }
};

const setExtraInfo = eventType => {
  switch (eventType) {
    case 'PROFILE PHOTO':
      return convertSentanceTitleCase(eventType);
    case 'OTHER PROFILE PHOTO':
      return 'Other Photos';
    case 'PRIVATE PHOTO':
      return 'Private Photo Placeholder';
    case 'ACCEPT OFFER':
      return 'Received Offers';
    default:
      return null;
  }
};

const profileMakeOffer = (): void => {
  vysionService(setProfileAction('MAKE OFFER PROFILE'));
};

const profileCancelOffer = (): void => {
  vysionService(setProfileAction('CANCEL OFFER PROFILE'));
};

const profileIgnoreOffer = (): void => {
  vysionService(setProfileAction('IGNORE OFFER PROFILE'));
};

const profileCounterOffer = (): void => {
  vysionService(setProfileAction('COUNTER OFFER PROFILE'));
};

const profileAcceptOffer = (): void => {
  vysionService(setProfileAction('ACCEPT OFFER PROFILE'));
};

const profilePhoto = (): void => {
  vysionService(setProfileAction('PROFILE PHOTO'));
};

const otherProfilePhoto = (): void => {
  vysionService(setProfileAction('OTHER PROFILE PHOTO'));
};

const privatePhoto = (): void => {
  vysionService(setProfileAction('PRIVATE PHOTO'));
};

const displayOfferModal = (): void => {
  vysionService(setProfileAction('DISPLAY MODAL OFFER'));
};

const sendOffer = (): void => {
  vysionService(setProfileAction('SEND OFFER'));
};

const acceptOffer = (): void => {
  vysionService(setProfileAction('ACCEPT OFFER'));
};

const reviseOffer = (): void => {
  vysionService(setProfileAction('REVISE OFFER'));
};

const declineOffer = (): void => {
  vysionService(setProfileAction('DECLINE OFFER'));
};

const sendCancel = (): void => {
  vysionService(setProfileAction('SEND CANCEL'));
};

const sendClose = (): void => {
  vysionService(setProfileAction('SEND CLOSE'));
};

const sendSuggest = (): void => {
  vysionService(setProfileAction('SEND SUGGEST'));
};

const sendAmountCustom = (): void => {
  vysionService(setProfileAction('SEND AMOUNT CUSTOM'));
};

const sendAmount50 = (): void => {
  vysionService(setProfileAction('SEND AMOUNT 50'));
};

const sendAmount75 = (): void => {
  vysionService(setProfileAction('SEND AMOUNT 75'));
};

const sendAmount100 = (): void => {
  vysionService(setProfileAction('SEND AMOUNT 100'));
};

const sendAmount150 = (): void => {
  vysionService(setProfileAction('SEND AMOUNT 150'));
};

const sendAmount200 = (): void => {
  vysionService(setProfileAction('SEND AMOUNT 200'));
};

const trackUnlockMessage = (): void => {
  vysionService(setProfileAction('UNLOCK MESSAGE'));
};

const counterOffer = (): any => {
  vysionTrack({
    action: 'click',
    component: 'Send Request',
    extraInfo: '',
    page: 'Counter Modal',
  });
};

const counterCancel = (): any => {
  vysionTrack({
    action: 'click',
    component: 'cancel',
    extraInfo: '',
    page: 'Counter Modal',
  });
};

const counterModal = (event: any): any => {
  vysionTrack(
    {
      action: event,
      component: 'Display',
      extraInfo: '',
      page: 'Counter Modal',
    },
    'counterModal'
  );
};

const counterModalClose = (): any => {
  vysionTrack({
    action: 'click',
    component: 'close',
    extraInfo: '',
    page: 'Counter Modal',
  });
};

const counterModalCustomAmount = (): any => {
  vysionTrack({
    action: 'focus',
    component: 'Custom Amount',
    extraInfo: '',
    page: 'Counter Modal',
  });
};

export {
  profileMakeOffer,
  profileCancelOffer,
  profileIgnoreOffer,
  profileCounterOffer,
  profileAcceptOffer,
  profilePhoto,
  otherProfilePhoto,
  privatePhoto,
  displayOfferModal,
  sendOffer,
  acceptOffer,
  reviseOffer,
  declineOffer,
  sendCancel,
  sendClose,
  sendSuggest,
  sendAmountCustom,
  sendAmount50,
  sendAmount75,
  sendAmount100,
  sendAmount150,
  sendAmount200,
  trackUnlockMessage,
  counterOffer,
  counterCancel,
  counterModal,
  counterModalClose,
  counterModalCustomAmount,
};
