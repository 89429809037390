import { mixpanelTrackEvent } from '../../sdk/MixpanelSDK';
import store from '../../store';
import { AccountMaker } from '../../models/Account';
import * as Sentry from '@sentry/react';
import { detectUserPlatform, getQueryParam } from '../helpers';

// Example payload object
// payload: {
//   event_properties: {... key paired values},
// }
export interface MixpanelTrackEvent {
  event_name: string;
  payload: Record<string, any>;
  other_account_hash_id?: string;
}

export const mixpanelTrackEventFromBE = async (data: MixpanelTrackEvent): Promise<void> => {
  const { payload } = data;
  const platform = detectUserPlatform();
  if (data?.other_account_hash_id) {
    const account = AccountMaker.create(store.getState().profile);

    if (!account?.isEligibleForMixpanelEvents()) return;
  }

  try {
    await mixpanelTrackEvent({
      ...data,
      payload: {
        ...payload,
        super_properties: {
          Platform: platform,
          ...trackLastTouchUtmTags(),
        },
      },
    });
  } catch (error) {
    const exception = error?.error || error?.message || error?.originalError || error?.errorMessage || error;
    Sentry.captureException(exception, {
      contexts: {
        mixpanelData: { ...data },
      },
    });
    throw new Error('Failed to track event');
  }
};

export const trackLastTouchUtmTags = (): Record<string, any> => {
  // reference https://help.mixpanel.com/hc/en-us/articles/360001337103
  const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  let index;
  const params = {};
  const first_params = {};
  const currentUrl = document.URL;

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(currentUrl, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(currentUrl, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  return params;
};
