/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, borders, patternModal } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails } from '../../../utils/helpers';
import { connect } from 'react-redux';
import { ProfileState } from '../../../reducers/profile';
import { ProfileMaker } from '../../../models/Profile';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 23,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'space-between',
});

const ButtonDiv = styled('div')({
  marginTop: 22,

  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 12,
    paddingTop: 12,
    borderTop: borders.DEFAULT,
    width: '100%',
  },
});

const getHeadline = (isCounter = false, username, isRevised = false, isGenerous, subTitleOne = '') => {
  if (subTitleOne) {
    return <span>{subTitleOne}</span>;
  } else {
    return isRevised ? (
      <span>
        Your revised offer {isGenerous ? '' : 'request'} to <strong>{username}</strong> has been sent!
      </span>
    ) : (
      <span>
        Your {isCounter ? 'counter offer' : 'offer'} to <strong>{username}</strong> has been sent!
      </span>
    );
  }
};

interface OfferSuccessProps {
  offerAmount: any;
  avatarSrc: any;
  username: any;
  buttonID: any;
  successOpen: any;
  isCounter: any;
  isRevised: boolean;
  isSuggest?: boolean;
  profile: ProfileState;
  mainTitle?: string;
  subTitleOne?: string;
  subTitleTwo?: string;
  customHeaderStyle?: React.CSSProperties;
  customTitleStyle?: React.CSSProperties;
}

const OfferSuccess = ({
  offerAmount,
  avatarSrc,
  username,
  buttonID,
  successOpen,
  isCounter,
  isRevised = false,
  isSuggest = false,
  profile,
  mainTitle = '',
  subTitleOne = '',
  subTitleTwo = '',
  customHeaderStyle = {},
  customTitleStyle = {},
}: OfferSuccessProps): ReactElement => {
  const profileModel = ProfileMaker.create(profile.profile);
  const isGenerous = profileModel.isGenerous();

  const handleSubmit = e => {
    e.preventDefault();
    if (sessionStorage.getItem('hasAddedPhoto')) {
      sessionStorage.removeItem('hasAddedPhoto');
    }
    successOpen(false);
  };

  const renderTitleLabel = () => {
    if (mainTitle) {
      return mainTitle;
    } else {
      if ((isRevised || isSuggest) && offerAmount === '') {
        return 'Suggest offer sent!';
      } else {
        return `${offerAmount} ${isGenerous ? 'Offer' : 'Request'} Sent!`;
      }
    }
  };

  return (
    <Form>
      <HeaderDiv style={customHeaderStyle}>
        <AvatarTitleDiv>
          {avatarSrc && (
            <Avatar
              data-test-id="offer-modal-avatar"
              src={avatarSrc}
              width={getBrowserDetails().isMobile ? '75px' : '100px'}
              customStyle={{
                backgroundColor: '#ccc',
              }}
            />
          )}
          <TitleDiv style={customTitleStyle}>{renderTitleLabel()}</TitleDiv>
        </AvatarTitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            height: 30,
            marginTop: 20,
            fontSize: 16,
            lineHeight: '21px',
            letterSpacing: '-0.32px',
          }}
          data-test-id="profile-offer-headline"
          size="SM"
        >
          {getHeadline(isCounter, username, isRevised, isGenerous, subTitleOne)}
        </Headline>
        {subTitleTwo && (
          <Headline
            style={{
              color: patternModal.headerColor,
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '21px',
              letterSpacing: '-0.32px',
              marginBottom: '12px',
            }}
          >
            {subTitleTwo}
          </Headline>
        )}
      </HeaderDiv>
      <Content>
        <ButtonDiv style={{ marginTop: 8, marginBottom: 20, borderTopWidth: 0 }}>
          <ButtonSet align="center">
            <Button id={buttonID} buttonType="error" onClick={handleSubmit} type="submit">
              Close
            </Button>
          </ButtonSet>
        </ButtonDiv>
      </Content>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(OfferSuccess);
