/**
 * @Author: zachalam
 * @Date:   2017-01-19T14:12:03-08:00
 * @Last modified by:   Daniel
 * @Last modified time: 2017-02-14T10:10:01-08:00
 */

import React, { FC } from 'react';
import Container from '../blocks/Container';
import { getBrowserDetails } from '../../utils/helpers';
import BillingHeader from '../blocks/headerNav/BillingHeader';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const TopTitle = styled('div')({
  color: '#3C4858',
  fontSize: '18px',
  textAlign: 'center',
  margin: '22px 0 6px 0',
});

const BillingMaintenance: FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      {getBrowserDetails().isMobile ? (
        <BillingHeader label="Billing Maintenance" onBackClick={() => navigate('/dashboard')} />
      ) : (
        <BillingHeader label="Billing Maintenance" />
      )}
      <TopTitle>Payments are temporarily not available.</TopTitle>
    </Container>
  );
};

export default BillingMaintenance;
