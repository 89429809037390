import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import useNav from '../../../../hooks/useNav';
import useMixPanel from '../../../../hooks/useMixPanel';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../../constants/mixpanel';
import DiscoverCounterInfoIcon from '../../../../atoms/icons/DiscoverCounterInfoIcon';
import DashboardDiscoverModeMediaRenderDots from './DashboardDiscoverModeMediaRenderDots';

const MediaCounterContainer = styled('div')({
  background: '#000',
  height: 41,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

const CounterWrapper = styled('div')({
  display: 'inline-block',
  marginRight: 1,
  marginTop: 2,
  marginLeft: '80px',
  width: 'calc(100% - 160px)',
});

const DotsWrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '5px 8px',
  borderRadius: '8px',
  background: '#262626',
  gap: 6,
});

const InfoIconWrapper = styled('div')({
  padding: '1px 32px 11px 22px',
  cursor: 'pointer',
});

interface DashboardDiscoverModeMediaCounterProps {
  photos: any;
  currentSlide: number;
  hashId: string;
}

interface ItemProps {
  id: number | null;
  hash_id: string;
  path: string;
  status: string;
  private: boolean;
  is_avatar: boolean;
  type: string;
  urls: {
    resized: string;
    '720px': string;
    '820px': string;
  };
  created_dt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  approved_by: string;
  approved_dt: string;
  denied_dt: string;
  denied_reason: string;
}

const DashboardDiscoverModeMediaCounter = ({
  photos,
  currentSlide,
  hashId,
}: DashboardDiscoverModeMediaCounterProps): ReactElement => {
  const { goTo } = useNav();
  const { setMixpanelDataByKeyHandler, trackProfileView } = useMixPanel();

  return (
    <MediaCounterContainer>
      <CounterWrapper className="counter-info-wrapper">
        {photos.length > 0 && (
          <DotsWrapper className="dots-wrapper">
            {photos.map((item: ItemProps, index: number) => {
              return (
                <DashboardDiscoverModeMediaRenderDots
                  key={index}
                  item={item}
                  index={index}
                  currentSlide={currentSlide}
                  photos={photos}
                />
              );
            })}
          </DotsWrapper>
        )}
      </CounterWrapper>
      <InfoIconWrapper
        onClick={() => {
          goTo(`/profile/${hashId}?origin=dashboard`);
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.DISCOVER_MODE_ACTION,
            payload: { 'Profile Viewed': true },
          });
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.OFFER_MADE,
            payload: { source: TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewDiscover },
          });
          trackProfileView({ Source: 'Discover Mode', User: hashId });
        }}
        className="pressable"
      >
        <DiscoverCounterInfoIcon />
      </InfoIconWrapper>
    </MediaCounterContainer>
  );
};

export default DashboardDiscoverModeMediaCounter;
