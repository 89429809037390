import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import InputLabel from './InputLabel';
import { borders, statusColor, inputTheme } from '../style/';
import StatusIcon from '../atoms/icons/StatusIcon';
import { isDarkMode } from '../utils/helpers';
import { TextAreaV2 } from 'swui';

const Container = styled('div')({
  paddingBottom: 24,
});

const StyledTextArea = styled(TextAreaV2)(
  {
    width: '100%',
    padding: '5px 10px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    background: inputTheme.backgroundInput,
    color: `${inputTheme.colorInput} !important`,
    fontFamily: 'system-ui, -apple-system, Arial, sans-serif',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
      border: borders.FOCUS,
      background: inputTheme.backgroundInput,
      color: `${inputTheme.colorInput} !important`,
    },
    '&::placeholder': {
      color: '#C9C9C9',
      fontWeight: 400,
      lineHeight: '23px',
      letterSpacing: '-0.32px',
    },
  },
  ({ resize }) => ({
    resize,
  })
);

const TextAreaContainer = styled('div')`
  position: relative;
`;

const StatusMessage = styled('div')(({ status }) => ({
  color: statusColor[status],
}));

const Counter = styled('div')({
  color: 'rgb(204, 84, 84)',
});

const MessageContainer = styled('div')({
  position: 'absolute',
});

const renderCounter = (minCount, value) => {
  const hasValue = Boolean(value);

  // if value is empty, value is of length 0, or value has met min count, return null
  if (!hasValue || value.length === 0 || value.length >= minCount) {
    return null;
  }

  return (
    <Counter>
      {value.length}/{minCount} characters
    </Counter>
  );
};

const renderStatusMessage = (status, statusMessage) => <StatusMessage status={status}>{statusMessage}</StatusMessage>;

const renderStatusIcon = status => {
  return (
    <div style={{ position: 'absolute', right: '-24px', top: '0' }}>
      <StatusIcon status={status} />
    </div>
  );
};

interface TextAreaProps {
  id: any;
  value: any;
  inputLabel: any;
  tooltip: any;
  labelDescription: any;
  status: any;
  statusMessage: any;
  resize: any;
  showCounter: any;
  minCount: any;
  customInputStyle: any;
  placeholder: string;
  customStyle?: React.CSSProperties;
  isRequired?: boolean;
  customLabelStyle?: React.CSSProperties;
}

const TextArea = ({
  id,
  value,
  inputLabel,
  tooltip,
  labelDescription,
  status,
  statusMessage,
  resize,
  showCounter,
  minCount,
  customInputStyle,
  customStyle,
  isRequired = false,
  customLabelStyle,
  ...rest
}: TextAreaProps): ReactElement => {
  const darkMode = isDarkMode();
  return (
    <Container style={customStyle}>
      {inputLabel ? (
        <InputLabel
          label={inputLabel}
          tooltip={tooltip}
          labelDescription={labelDescription}
          inputId={`label-${id}`}
          data-test-id={`label-${id}`}
          isRequired={isRequired}
          customStyle={customLabelStyle}
        />
      ) : null}
      <TextAreaContainer className={darkMode ? 'darkmode' : 'lightmode'}>
        <StyledTextArea
          id={id}
          hasError={status === 'error'}
          hasSuccess={status === 'success'}
          resize={resize}
          style={customInputStyle}
          value={value}
          {...rest}
        />
        {status ? renderStatusIcon(status) : null}
      </TextAreaContainer>
      <MessageContainer className={`status-${id}`}>
        {showCounter && renderCounter(minCount, value)}
        {statusMessage && status !== 'default' ? renderStatusMessage(status, statusMessage) : null}
      </MessageContainer>
    </Container>
  );
};

TextArea.defaultProps = {
  status: 'default',
  resize: 'none',
  showCounter: false,
  minCount: 100,
  rows: 6,
  value: '',
  onChange: () => null,
};

TextArea.propTypes = {
  /** Unique id for the input field */
  id: PropTypes.string,
  /** Textarea value */
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  /** Textarea rows */
  rows: PropTypes.number,
  /** Label of textarea */
  inputLabel: PropTypes.string,
  /** Label description */
  labelDescription: PropTypes.string,
  /** Tooltip next to label */
  tooltip: PropTypes.shape({
    icon: PropTypes.any,
    text: PropTypes.string,
    overlayStyle: PropTypes.object,
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  }),
  /** Show character counter below textarea */
  showCounter: PropTypes.bool,
  /** Minimum amount of characters required */
  minCount: PropTypes.number,
  /** Determines how border color and status should be rendered*/
  status: PropTypes.oneOf(['default', 'caution', 'error', 'success']),
  /** Status Message */
  statusMessage: PropTypes.any,
  /** Axis textarea can be resized to */
  resize: PropTypes.oneOf(['horizontal', 'vertical', 'both', 'none']),
  /** Custom textarea styles */
  customInputStyle: PropTypes.object,
};

export default TextArea;
