import React, { ReactElement } from 'react';

import Container from '../../blocks/Container';
import ResultList from '../../blocks/ResultList';
import Button from '../../../atoms/buttons/Button';
import { Header } from '../../../atoms/text';
import Headline from '../../common/Headline';
import BlankSlate from '../../blocks/BlankSlate';
import BlockedUserItem from '../../../atoms/BlockedUserItem';
import _ from 'lodash';
import { displayUserLocation } from '../../../common';
import Loader from '../../../atoms/LoaderStyled';

interface BlockedProps {
  blocked: any;
  myProfile: any;
  unblock: any;
  loadMore: any;
  moreExists: any;
  isFetching: any;
}

const Blocked = ({ blocked, myProfile, unblock, loadMore, moreExists, isFetching }: BlockedProps): ReactElement => {
  const getInfoText = (profile, otherProfile) => {
    if (!profile || !otherProfile) return '';

    const userLoc = {
      country: profile.country,
      region: profile.region,
      city: profile.city,
    };

    const offerUserLoc = {
      country: otherProfile.country,
      region: otherProfile.region,
      city: otherProfile.city,
    };

    return `${otherProfile.age} • ${displayUserLocation(userLoc, offerUserLoc)}`;
  };

  const renderBlockList = (blocked, myProfile) => {
    // loop through each mail item.
    if (!_.isEmpty(blocked.blockedItems)) {
      const BlockList = blocked.blockedItems.map((userData, index) => {
        const account = userData.to_account.data;
        const profile = account && account.profile ? account.profile.data : null;
        return profile ? (
          <BlockedUserItem
            key={index}
            hashId={account.hash_id}
            avatar={profile.avatar.data.urls.thumbs}
            username={account.username}
            userDetails={getInfoText(myProfile, profile)}
            onUnblock={unblock}
            description={profile.description}
          />
        ) : null;
      });
      return (
        <div>
          <ResultList>{BlockList}</ResultList>
          {moreExists ? (
            <Loader active={isFetching} background="transparent">
              <div style={{ margin: '16px auto', maxWidth: '200px' }}>
                <Button
                  buttonType="primary"
                  fit
                  onClick={() => {
                    loadMore(blocked.pagination !== undefined ? parseInt(blocked.pagination.current_page, 10) + 1 : 1);
                  }}
                >
                  Load More
                </Button>
              </div>
            </Loader>
          ) : null}
        </div>
      );
    } else {
      return <BlankSlate title={"You haven't blocked anyone"} message="" />;
    }
  };

  return (
    <div>
      <Container fullWidth>
        <Header bottomRuled>
          <div style={{ padding: '0 1rem' }}>
            <Headline data-test-id="fs-modal-headline" hasDescription>
              Blocked accounts
            </Headline>
          </div>
        </Header>
        {renderBlockList(blocked, myProfile)}
      </Container>
    </div>
  );
};

export default Blocked;
