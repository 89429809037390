import {
  DashboardState,
  DashboardActionType,
  STORE_DISCOVER_MODE_ACCOUNTS,
  REMOVE_DISCOVER_MODE_ACCOUNTS,
  RESET_DISCOVER_MODE_ACCOUNTS,
  UPDATE_DISCOVER_MODE_ACCOUNTS,
} from './types/Dashboard.d';

const initialState: DashboardState = {
  isFetching: false,
  DiscoverModeAccounts: [],
  DiscoverModeAccountsExpiration: 0,
};

const dashboard = (state = initialState, action: DashboardActionType): any => {
  switch (action.type) {
    case STORE_DISCOVER_MODE_ACCOUNTS:
      return {
        ...state,
        DiscoverModeAccounts: action.payload,
        DiscoverModeAccountsExpiration: new Date().getTime() + 3600000,
        isFetching: false,
      };
    case UPDATE_DISCOVER_MODE_ACCOUNTS:
      return {
        ...state,
        DiscoverModeAccounts: action.payload,
        isFetching: false,
      };
    case RESET_DISCOVER_MODE_ACCOUNTS:
      return {
        ...state,
        DiscoverModeAccounts: [],
        DiscoverModeAccountsExpiration: 0,
        isFetching: false,
      };
    case REMOVE_DISCOVER_MODE_ACCOUNTS:
      return {
        ...state,
        DiscoverModeAccounts: [],
        isFetching: false,
      };
    default:
      return state;
  }
};

export default dashboard;
