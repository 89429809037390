import React, { useState, useEffect } from 'react';
import { isDarkMode } from '../../../utils/helpers';
import EditablePersonalInfo from '../../../atoms/EditablePersonalInfo';
import PanelStatsWithHeader from '../../../atoms/panels/PanelStatsWithHeader';
import { backgroundTheme } from '../../../style';
import Loader from '../../../atoms/LoaderStyled';
import _find from 'lodash/find';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';
import { BODY_TYPE_OPTIONS } from '../../join/constants';

const generateBodytypeChildrenOptions = (type, data, mapFields, renderSelectedField) => {
  switch (type) {
    case 'Body Type':
      if (data.body_types.length > 1) {
        return data.body_types[0].label + ', ' + data.body_types[1].label;
      } else if (data.body_types.length === 1) {
        return data.body_types[0].label;
      } else {
        return '—';
      }
    case 'Children':
      if (Number.isInteger(data.children)) {
        return data.children > 0 ? renderSelectedField(mapFields.children, data.children) : '—';
      } else {
        return data.children;
      }
    default:
      return;
  }
};

const generateSmokingDrinkingOptions = (type, data, mapFields, renderSelectedField) => {
  switch (type) {
    case 'Smoking':
      if (Number.isInteger(data.smoking)) {
        return data.smoking > 0 ? renderSelectedField(mapFields.smoking, data.smoking) : '—';
      } else {
        return data.smoking;
      }
    case 'Drinking':
      if (Number.isInteger(data.drinking)) {
        return data.drinking > 0 ? renderSelectedField(mapFields.drinking, data.drinking) : '—';
      } else {
        return data.drinking;
      }
    default:
      return;
  }
};

const generateIncomeOptions = (data, mapFields, renderSelectedField) => {
  return Number.isInteger(data.income) ? renderSelectedField(mapFields.income, data.income) : data.income;
};

const lookingFor = (targetId, clearIndividualError) => {
  if (targetId === 'looking_male' || targetId === 'looking_female') clearIndividualError('looking_male');
};

const setBodyTypeVal = value => {
  return typeof value !== 'number' ? parseInt(value, 10) : value;
};

const setBodyTypeLabel = item => {
  return item.description ? item.description : item.label;
};

const findFields = (myInfo, fields, key) => {
  return myInfo[key] === '' && fields.find(field => field === key);
};

const setNonMandatoryFields = (nonManFields, data) => {
  const mapObj = {};

  for (const key in data) {
    if (data[key] || findFields(data, nonManFields, key)) {
      mapObj[key] = data[key];
    }
  }
  return mapObj;
};

const renderInfo = data => {
  return data || '—';
};

const PersonalInfo = (props: any): any => {
  /* manage and maintain the user about info state here and toggle between the
     readonly and editable component
  */
  const {
    myProfile,
    mapFields,
    errors,
    clearIndividualError,
    isProcessing,
    isLogin = false,
    acctProfile,
    ...rest
  } = props;
  const [aboutFields, setAboutFields] = useState(false);
  const [myEvent, setMyEvent] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    body_types: [],
    height: '',
    children: 0,
    smoking: 0,
    drinking: 0,
    education: 0,
    occupation: null,
    income: 0,
    looking_male: false,
    looking_female: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    mapAllStates();

    return () => {
      setAboutFields(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile]);

  useEffect(() => {
    if (!errors) return;
    const { height, body_type, occupation, looking_male } = errors;
    if (!height && !body_type && !occupation && !looking_male && isSubmitted) {
      toggleEditable(myEvent);
      setIsSubmitted(false);
      setMyEvent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const mapAllStates = () => {
    if (!myProfile) return;
    const {
      body_types,
      height,
      children,
      smoking,
      drinking,
      education,
      occupation,
      income,
      looking_female,
      looking_male,
    } = myProfile;

    setPersonalInfo({
      body_types: mapBodyType(body_types),
      height: height,
      children: children,
      smoking: smoking,
      drinking: drinking,
      education: education,
      occupation: occupation,
      income: income,
      looking_female: looking_female,
      looking_male: looking_male,
    });
  };

  const makeChange = (category, value) => {
    setPersonalInfo({
      ...personalInfo,
      [category]: value,
    });
  };

  const toggleEditable = e => {
    e.preventDefault();
    setAboutFields(!aboutFields);
  };

  const onChangeBodyType = val => {
    const currentBodyTypes = personalInfo.body_types;
    const isIncluded = _find(currentBodyTypes, val);
    if (!isIncluded && currentBodyTypes.length !== 1) {
      currentBodyTypes.push(val);
      setPersonalInfo({
        ...personalInfo,
        body_types: currentBodyTypes,
      });
    } else if (isIncluded) {
      const filteredArr = currentBodyTypes.filter(item => item.value !== val.value);
      setPersonalInfo({
        ...personalInfo,
        body_types: filteredArr,
      });
    }
  };

  const onToggleChangeHandler = targetId => {
    lookingFor(targetId, clearIndividualError);
    makeChange(targetId, !personalInfo[targetId]);
  };

  const onToggleChildren = (id, value) => {
    clearIndividualError(id);
    makeChange(id, isNaN(value) ? value : parseInt(value));
  };

  const onChangeHandler = e => {
    const id = e.target.id;
    const value = e.target.value;
    clearIndividualError(id);
    makeChange(id, isNaN(value) || e.target.type !== 'select-one' ? value : parseInt(value));
  };

  const setNonMandatoryData = info => {
    const nonMandatoryFields = ['children', 'smoking', 'drinking', 'education', 'occupation', 'income'];
    return setNonMandatoryFields(nonMandatoryFields, info);
  };

  const handleSubmit = e => {
    const { updateProfileAbout } = props;
    personalInfo.body_types.map(item => {
      BODY_TYPE_OPTIONS.find(type => type.value === item.value);
    });

    const { children, smoking, drinking, education, income, occupation } = personalInfo;
    const isGenerous = myProfile.account_type === 'Generous';
    const requiredPersonalInfo = isGenerous
      ? children === 0 || smoking === 0 || drinking === 0 || education === 0 || occupation === '' || income === 0
      : children === 0 || smoking === 0 || drinking === 0 || education === 0;

    if (requiredPersonalInfo) {
      return;
    }

    setIsSubmitted(true);

    const nonManData = setNonMandatoryData(personalInfo);

    setMyEvent(e);

    updateProfileAbout({
      height: personalInfo.height,
      body_types: personalInfo.body_types,
      looking_female: personalInfo.looking_female,
      looking_male: personalInfo.looking_male,
      ...nonManData,
    });
  };

  const handleCancel = e => {
    // need to clear the fields if user select cancel button
    toggleEditable(e);
    mapAllStates();
  };

  const mapBodyType = bTypes => {
    const renderDefaultBodyTypes = [];

    if (
      myProfile &&
      myProfile !== undefined &&
      bTypes &&
      bTypes !== undefined &&
      bTypes.data !== undefined &&
      bTypes.data.length > 0
    ) {
      bTypes.data.map(item => {
        renderDefaultBodyTypes.push({
          value: setBodyTypeVal(item.value),
          label: setBodyTypeLabel(item),
        });
      });
    }

    return renderDefaultBodyTypes;
  };

  const renderSelectedField = (field, selected) => {
    return field[selected];
  };

  const renderData = () => {
    const commonData = [
      {
        label: 'Body Type',
        info: generateBodytypeChildrenOptions('Body Type', personalInfo, mapFields, renderSelectedField),
        dataId: 'body-type',
      },
      {
        label: 'Height',
        info: renderInfo(personalInfo.height),
        dataId: 'height',
      },
      {
        label: 'Children',
        info: generateBodytypeChildrenOptions('Children', personalInfo, mapFields, renderSelectedField),
      },
      {
        label: 'Smoking',
        info: generateSmokingDrinkingOptions('Smoking', personalInfo, mapFields, renderSelectedField),
      },
      {
        label: 'Drinking',
        info: generateSmokingDrinkingOptions('Drinking', personalInfo, mapFields, renderSelectedField),
      },
      {
        label: 'Education',
        info: renderInfo(personalInfo.education),
      },
      {
        label: 'Occupation',
        info: renderInfo(personalInfo.occupation),
      },
    ];

    if (acctProfile && acctProfile.hash_id === myProfile.hash_id && myProfile.account_type === 'Attractive') {
      return commonData;
    }

    return [
      ...commonData,
      {
        label: 'Income',
        info: generateIncomeOptions(personalInfo, mapFields, renderSelectedField),
      },
    ];
  };

  return (
    <div>
      {aboutFields ? (
        <Loader active={isProcessing} background={backgroundTheme.personalInfo}>
          {isProcessing && <TrackLoadingToMixpanel page="Profile - saving about me info" />}
          <EditablePersonalInfo
            errors={errors}
            bodyTypes={personalInfo.body_types}
            height={personalInfo.height}
            totalChildren={personalInfo.children}
            smoking={personalInfo.smoking}
            drinking={personalInfo.drinking}
            education={personalInfo.education}
            occupation={personalInfo.occupation}
            income={personalInfo.income}
            lookingMale={personalInfo.looking_male}
            lookingFemale={personalInfo.looking_female}
            onChangeHandler={onChangeHandler}
            mapFields={mapFields}
            className="profile__personal-info"
            title="About Me"
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            onChangeBodyType={onChangeBodyType}
            onCheckboxChangeHandler={onToggleChangeHandler}
            onToggleChildren={onToggleChildren}
            isGenerous={myProfile.account_type === 'Generous'}
            gender={myProfile.gender}
          />
        </Loader>
      ) : (
        <PanelStatsWithHeader
          className="profile__personal-info"
          title="About Me"
          data={renderData()}
          toggleEditable={toggleEditable}
          isLogin={isLogin}
          dataTestId="edit-about-info"
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
          {...rest}
        />
      )}
    </div>
  );
};

export default PersonalInfo;
