import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

interface MediaPlayIconProps {
  cssStyle?: any;
  customWrapperStyle?: any;
  onClick?: any;
  maskId?: any;
}

const MediaPlayButton = styled('div')(
  {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    width: '100%',
    display: 'inline-flex',
    flexFlow: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
  ({ customStyle }) =>
    customStyle && {
      ...customStyle,
    }
);

const MediaPlayIcon = ({ cssStyle, customWrapperStyle, onClick, maskId }: MediaPlayIconProps): ReactElement => {
  return (
    <MediaPlayButton customStyle={customWrapperStyle} onClick={onClick}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          borderRadius: '50%',
          backgroundColor: 'rgba(51,51,51,0.75)',
          padding: 8,
          ...cssStyle,
        }}
      >
        <mask
          id={`mask0-${maskId}`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="48"
          height="48"
        >
          <rect width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask={`mask0-${maskId}`}>
          <path
            d="M19.15 18.25V29.75C19.15 30.35 19.4083 30.8 19.925 31.1C20.4417 31.4 20.95 31.3833 21.45 31.05L30.5 25.25C30.9667 24.95 31.2 24.5333 31.2 24C31.2 23.4667 30.9667 23.05 30.5 22.75L21.45 16.95C20.95 16.6167 20.4417 16.6 19.925 16.9C19.4083 17.2 19.15 17.65 19.15 18.25ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2667 4.525 18.6833 5.575 16.25C6.625 13.8167 8.05833 11.6917 9.875 9.875C11.6917 8.05833 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7333 4 29.3167 4.525 31.75 5.575C34.1833 6.625 36.3083 8.05833 38.125 9.875C39.9417 11.6917 41.375 13.8167 42.425 16.25C43.475 18.6833 44 21.2667 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.9417 36.3083 38.125 38.125C36.3083 39.9417 34.1833 41.375 31.75 42.425C29.3167 43.475 26.7333 44 24 44Z"
            fill="white"
          />
        </g>
      </svg>
    </MediaPlayButton>
  );
};

export default MediaPlayIcon;
