import React, { ReactElement } from 'react';
interface ICreditCardOffProps {
  customStyle?: any;
  customFill?: any;
  customHeight?: any;
  customWidth?: any;
}

const CreditCardOff = ({
  customStyle,
  customFill = '#BC2028',
  customHeight = 35,
  customWidth = 37,
}: ICreditCardOffProps): ReactElement => {
  return (
    <svg
      width={customWidth}
      height={customHeight}
      viewBox={`0 0 ${customWidth} ${customHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
    >
      <path
        d="M35.8001 29.1005L22.9891 16.3287H32.6534V10.5232H17.1836L9.68595 2.98629H32.6534C33.6651 2.98629 34.5327 3.34811 35.2564 4.07174C35.98 4.79538 36.3418 5.66305 36.3418 6.67474V27.3148C36.3418 27.6582 36.2929 27.977 36.1951 28.2711C36.0972 28.5652 35.9656 28.8416 35.8001 29.1005ZM5.34661 16.3287H14.2597L8.45411 10.5232H5.34661V16.3287ZM32.2077 34.2116L28.9578 30.9876H5.34661C4.33492 30.9876 3.46988 30.6284 2.75149 29.91C2.03307 29.1916 1.67386 28.3265 1.67386 27.3148V6.67474C1.67386 5.76772 1.97681 4.9694 2.58269 4.27979C3.18856 3.59018 3.92668 3.18256 4.79707 3.05695H5.35261L8.9704 6.67474H4.60569L1.05553 3.12458C0.756834 2.82588 0.608778 2.46347 0.611361 2.03733C0.614 1.61122 0.769903 1.24356 1.07907 0.934369C1.37779 0.635675 1.7376 0.486328 2.15849 0.486328C2.57938 0.486328 2.94442 0.635675 3.25361 0.934369L34.3979 32.063C34.6967 32.3722 34.846 32.7303 34.846 33.1373C34.846 33.5443 34.6967 33.9024 34.3979 34.2116C34.0888 34.5207 33.7237 34.6753 33.3028 34.6753C32.8819 34.6753 32.5169 34.5207 32.2077 34.2116Z"
        fill={customFill}
      />
    </svg>
  );
};

export default CreditCardOff;
