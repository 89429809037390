import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sendTicket } from '../actions/supportActions';
import SupportTicketFSModal from '../screens/SupportTicketFSModal';
import { CATEGORIES } from '../modules/SupportTicketForm';
import { useNavigate } from 'react-router-dom';
import { mixpanelTrackAbuseReported } from '../utils/mixpanel/abuseReported';

const SupportTicketContainer = ({ profile }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const submitSupportTicket = async formData => {
    setError(null);
    setSuccess(false);
    try {
      await sendTicket(formData);
      setSuccess(true);
      mixpanelTrackAbuseReported(CATEGORIES[formData.subject]);
      setTimeout(() => {
        'history' in window ? navigate(-1) : navigate('/dashboard');
      }, 3000);
    } catch (err) {
      setError((err.response && err.response.status) || err);
    }
  };

  const id = (profile && profile.hash_id) || null;
  return <SupportTicketFSModal id={id} submitSupportTicket={submitSupportTicket} success={success} errorCode={error} />;
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(SupportTicketContainer);
