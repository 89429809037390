import React, { ReactElement } from 'react';
import colors from '../../theme/colors';

interface Props {
  customStyle?: React.CSSProperties;
  fill?: string;
}
const IconExclamationPointCircle = ({ fill = colors.light.red.warning, customStyle, ...rest }: Props): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <g mask="url(#mask0_3822_8693)">
        <path
          d="M13.4995 18.417C13.7706 18.417 13.9979 18.3253 14.1814 18.142C14.365 17.9586 14.4568 17.7314 14.4568 17.4604C14.4568 17.1893 14.3651 16.962 14.1817 16.7785C13.9984 16.5949 13.7712 16.5031 13.5002 16.5031C13.2291 16.5031 13.0018 16.5948 12.8182 16.7782C12.6347 16.9615 12.5429 17.1887 12.5429 17.4597C12.5429 17.7308 12.6346 17.9581 12.8179 18.1416C13.0013 18.3252 13.2285 18.417 13.4995 18.417ZM13.5572 14.2101C13.8139 14.2101 14.028 14.1235 14.1995 13.9505C14.371 13.7775 14.4568 13.5631 14.4568 13.3073V8.37809C14.4568 8.1223 14.37 7.90789 14.1963 7.73487C14.0227 7.56184 13.8075 7.47533 13.5508 7.47533C13.2942 7.47533 13.0801 7.56184 12.9085 7.73487C12.737 7.90789 12.6512 8.1223 12.6512 8.37809V13.3073C12.6512 13.5631 12.7381 13.7775 12.9117 13.9505C13.0853 14.1235 13.3005 14.2101 13.5572 14.2101ZM13.5046 23.8337C12.0092 23.8337 10.6039 23.5493 9.28868 22.9805C7.97344 22.4118 6.8253 21.6369 5.84427 20.6559C4.86326 19.6749 4.08838 18.5263 3.51963 17.2102C2.95088 15.894 2.6665 14.4877 2.6665 12.9913C2.6665 11.4949 2.95088 10.0886 3.51963 8.77245C4.08838 7.45631 4.86326 6.31074 5.84427 5.33574C6.8253 4.36074 7.97388 3.58887 9.29 3.02012C10.6061 2.45137 12.0124 2.16699 13.5089 2.16699C15.0053 2.16699 16.4116 2.45137 17.7277 3.02012C19.0439 3.58887 20.1894 4.36074 21.1644 5.33574C22.1394 6.31074 22.9113 7.45727 23.48 8.77533C24.0488 10.0934 24.3332 11.5001 24.3332 12.9955C24.3332 14.4909 24.0488 15.8962 23.48 17.2115C22.9113 18.5267 22.1394 19.6737 21.1644 20.6526C20.1894 21.6314 19.0429 22.4062 17.7248 22.9772C16.4068 23.5482 15 23.8337 13.5046 23.8337ZM13.5089 22.0281C16.0186 22.0281 18.1491 21.1494 19.9006 19.392C21.6519 17.6346 22.5276 15.501 22.5276 12.9913C22.5276 10.4816 21.6531 8.35102 19.9039 6.59961C18.1548 4.84822 16.0201 3.97253 13.4998 3.97253C10.9961 3.97253 8.86557 4.8471 7.10817 6.59623C5.35075 8.34538 4.47204 10.4801 4.47204 13.0003C4.47204 15.504 5.35075 17.6346 7.10817 19.392C8.86557 21.1494 10.9991 22.0281 13.5089 22.0281Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconExclamationPointCircle;
