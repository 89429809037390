import React, { ReactElement, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import CounterSmallDotIcon from '../../../../../atoms/icons/CounterSmallDotIcon';
import CounterDotIcon from '../../../../../atoms/icons/DiscoverCounterDotIcon';
import colors from '../../../../../theme/colors';

interface Photo {
  id: number;
  hash_id: string;
  path: string;
  status: string;
  private: boolean;
  is_avatar: boolean;
  type: string;
  urls: {
    resized: string;
    '720px': string;
    '820px': string;
  };
  created_dt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  approved_by: string;
  approved_dt: string;
  denied_dt: string;
  denied_reason: string;
}

const OneTapSwipeOfferMediaRenderDots = ({
  item,
  index,
  currentSlide,
  photos,
}: {
  item: Photo;
  index: number;
  currentSlide: number;
  photos: Photo[];
}): ReactElement => {
  const activeDot = currentSlide === index;
  const lastDotSmall = photos.length >= 4 && index > currentSlide + 2;
  const hideDots = photos.length >= 4 && (index > currentSlide + 3 || index < currentSlide - 3);
  const firstDotSmall = photos.length >= 4 && index < currentSlide - 2;

  const renderDots = style => {
    const animatedStyles = { ...style, display: 'inherit', height: 6, alignItems: 'center' };

    if (lastDotSmall || firstDotSmall) {
      return hideDots ? (
        <></>
      ) : (
        <animated.div style={animatedStyles}>
          <CounterSmallDotIcon
            key={`small-${index}-${item.id}`}
            fillCircle={item.private ? '#D06369' : colors.gray['500']}
          />
        </animated.div>
      );
    } else if (!item.private) {
      return (
        <animated.div style={animatedStyles}>
          <CounterDotIcon key={`public-${index}-${item.id}`} fillColor={activeDot ? 'white' : colors.gray['500']} />
        </animated.div>
      );
    } else {
      return (
        <animated.div style={animatedStyles}>
          <CounterDotIcon key={`private-${index}-${item.id}`} isActivePrivate={activeDot} fillColor={'#D06369'} />
        </animated.div>
      );
    }
  };

  const transRef = useSpringRef();
  const transitions = useTransition(activeDot, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(5%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    trail: 150,
  });

  useEffect(() => {
    transRef.start();
  }, [activeDot]);

  return transitions(style => {
    return renderDots(style);
  });
};

export default OneTapSwipeOfferMediaRenderDots;
