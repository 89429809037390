import React, { useState, useRef, useEffect, ReactElement } from 'react';
import styled from '@emotion/styled';
import { css } from 'glamor';
import StatusIcon from '../atoms/icons/StatusIcon';
import { borders, statusColor, textColor, inputTheme } from '../style';
import InputLabel from './InputLabel';
import { isDarkMode } from '../utils/helpers';
import { InputV2 } from 'swui';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const Input = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  '> div:first-of-type': {
    width: '100% !important',
  },
});

const InputStyle = styled(InputV2)(
  {
    color: inputTheme.colorInput,
    '&:focus': {
      color: inputTheme.colorInput,
      background: inputTheme.backgroundInput,
    },
  },
  ({ islogin, joinForm }) => ({
    width: '100%',
    appearance: 'none',
    boxSizing: 'border-box',
    padding: '0 15px',
    height: 40,
    outline: 'none',
    borderRadius: 3,
    border: islogin === 'true' ? '1px solid rgba(0,0,0,0.15) !important' : borders.DARKER,
    background: inputTheme.backgroundInput,
    color: islogin === 'true' ? '#000000' : inputTheme.colorInput,
    borderWidth: 1,
    borderStyle: 'solid',
    transition: 'border-color: .2s ease-in-out',
    fontSize: joinForm === 'true' ? 15 : 16,
    '&:focus': {
      border: borders.FOCUS,
      color: islogin === 'true' ? '#000000' : inputTheme.colorInput,
    },
  }),
  ({ status }) =>
    status && {
      borderColor: statusColor[status],
    },
  ({ borderless }) =>
    borderless === 'true' && {
      border: 'none !important',
    }
);

const StatusDescription = styled('div')(
  {
    marginTop: 4,
  },
  ({ status }) =>
    status && {
      color: statusColor[status],
    }
);

const ShowPassword = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  // color: #3e79a5;
  color: #c9c9c9;
  font-weight: 500;
`;

const LabelContainer = styled('div')`
  position: relative;
  // margin-bottom: 10px;
  min-height: 17px;
  color: ${textColor.DARK.color};
`;

const InputContainer = styled('div')`
  padding-bottom: 24px;
`;

const StatusIconContainer = styled('div')`
  position: absolute;
  right: 10px;
`;

const renderStatusIcon = status => {
  return (
    <StatusIconContainer>
      <StatusIcon status={status} />
    </StatusIconContainer>
  );
};

const renderInputLabel = (inputLabel, labelDescription, tooltip, inputId, isBilling, customStyleLabel, isRequired) => {
  return (
    <InputLabel
      label={inputLabel}
      labelDescription={labelDescription}
      tooltip={tooltip}
      inputId={`label-${inputId}`}
      customStyle={customStyleLabel}
      isBilling
      isRequired={isRequired}
    />
  );
};

interface TextInputProps {
  id: any;
  onChange: any;
  type: any;
  inputLabel: any;
  tooltip: any;
  labelDescription: any;
  eleRef: any;
  statusMessage: any;
  status: any;
  showPassword: any;
  customStyle: any;
  customInputStyle: any;
  autoFocus: any;
  isBilling: boolean;
  customStyleLabel: any;
  isLogin?: boolean;
  joinForm?: boolean;
  noBorder: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isRequired: boolean;
  hideStatusIcon: boolean;
}

const TextInput = ({
  id,
  onChange,
  type,
  inputLabel,
  tooltip,
  labelDescription,
  eleRef,
  statusMessage,
  status = 'default',
  showPassword = false,
  customStyle,
  customInputStyle,
  autoFocus,
  isBilling = false,
  customStyleLabel,
  isLogin = false,
  joinForm = false,
  noBorder = false,
  isRequired = false,
  hideStatusIcon = false,
  ...rest
}: TextInputProps): ReactElement => {
  const textInput = useRef(null);
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [hideError, setHideError] = useState(false);
  const darkMode = isDarkMode();

  useEffect(() => {
    if (autoFocus) {
      focusTextInput();
    }
  }, [autoFocus]);

  const focusTextInput = () => {
    if (textInput.current) textInput.current.focus();
  };

  const handleShowPassword = () => {
    setPasswordInputType(passwordInputType === 'text' ? 'password' : 'text');
  };

  const showPasswordToggle = showPassword ? (
    <ShowPassword onClick={handleShowPassword} id="toggle_password">
      {passwordInputType === 'password' ? <Visibility /> : <VisibilityOff />}
    </ShowPassword>
  ) : null;

  return (
    <InputContainer ref={eleRef} style={customStyle}>
      {(inputLabel || showPassword) && (
        <LabelContainer style={{ color: isLogin ? 'rgba(0,0,0,.65)' : '' }}>
          {inputLabel &&
            renderInputLabel(inputLabel, labelDescription, tooltip, id, isBilling, customStyleLabel, isRequired)}
          {showPassword && showPasswordToggle}
        </LabelContainer>
      )}
      <Input className={darkMode && !isLogin ? 'darkmode' : 'lightmode'}>
        <InputStyle
          id={id}
          onChange={onChange}
          data-test-id={`text-${id}`}
          type={showPassword ? passwordInputType : type}
          status={status}
          ref={textInput}
          onFocus={() => (isBilling ? setHideError(true) : null)}
          islogin={isLogin.toString()}
          joinForm={joinForm.toString()}
          data-lpignore="true"
          autoComplete="off"
          {...css(customInputStyle)}
          borderless={noBorder ? 'true' : 'false'}
          {...rest}
        />
        {!hideStatusIcon && status !== 'default' ? renderStatusIcon(status) : null}
        <StatusDescription status={status} />
      </Input>
      {!hideError && statusMessage && status !== 'default' ? (
        <StatusDescription data-test-id={`${status}-${id}`} status={status}>
          {statusMessage}
        </StatusDescription>
      ) : null}
    </InputContainer>
  );
};

export default TextInput;
