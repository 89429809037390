import React, { useState } from 'react';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import Button from '../../../atoms/buttons/Button';
import styled from '@emotion/styled';
import colors from '../../../theme/colors';
import { theme } from '../../../theme';
import Checkbox from '../../../atoms/Checkbox';
import borders from '../../../theme/borders';

const ConfirmationModalContainer = styled('div')({
  textAlign: 'center',
  padding: 'inherit',
  paddingTop: 52,
});

const ConfirmationModalTitle = styled('h1')({
  fontSize: 28,
  color: colors[theme].red.primary,
  marginBottom: 24,
  lineHeight: '28px',
});

const ConfirmationModalDescription = styled('p')({
  lineHeight: '21px',
});

const ConfirmationConfirmButtonCustomStyle = {
  display: 'block',
  padding: '16px 32px',
  margin: '0 auto',
  minWidth: 70,
  height: 'inherit',
  width: 'inherit',
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '16px',
};

export const ConfirmationCancelButtonCustomStyle = {
  fontWeight: 'normal',
  textDecoration: 'underline',
  display: 'block',
  margin: '0 auto',
  color: colors[theme].blue.secondary,
};

interface IDashboardDiscoverModeSendOffConfirmationProps {
  title: string;
  desc: string | JSX.Element;
  confirmButtonLabel: string;
  onCancel: () => void;
  onConfirm: (doNotShowAgain?: boolean) => void;
}

const DashboardDiscoverModeActionConfirmation: React.FC<IDashboardDiscoverModeSendOffConfirmationProps> = ({
  onCancel,
  onConfirm,
  title,
  desc,
  confirmButtonLabel,
}: IDashboardDiscoverModeSendOffConfirmationProps) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(true);

  return (
    <ConfirmationModalContainer>
      <ConfirmationModalTitle>{title}</ConfirmationModalTitle>
      <ConfirmationModalDescription>{desc}</ConfirmationModalDescription>
      <PrimaryButton
        label={confirmButtonLabel}
        onClick={() => onConfirm(doNotShowAgain)}
        size="regular"
        color="red"
        customStyle={{ ...ConfirmationConfirmButtonCustomStyle }}
      />
      <Button
        buttonType="chromeless"
        data-test-id="id"
        onClick={onCancel}
        customStyle={{
          ...ConfirmationCancelButtonCustomStyle,
        }}
      >
        Cancel
      </Button>
      <Checkbox
        label={`Don't show this again`}
        onChange={() => {
          setDoNotShowAgain(!doNotShowAgain);
        }}
        value={doNotShowAgain}
        labelCustomStyle={{ textAlign: 'center', marginTop: 24, marginRight: 0 }}
        customLabelTextStyle={{
          color: borders.dark.line1,
          paddingLeft: 4,
          fontSize: 14,
          paddingTop: 1,
          position: 'relative',
          top: 0.5,
        }}
        customStyle={{
          height: 12,
          width: 12,
          backgroundColor: doNotShowAgain ? colors.light.blue.tertiary : 'inherit',
          borderColor: colors.light.blue.tertiary,
        }}
        svgCustomStyle={{ height: 25, width: 26, paddingTop: 1, paddingLeft: 0.3 }}
      />
    </ConfirmationModalContainer>
  );
};

export default DashboardDiscoverModeActionConfirmation;
