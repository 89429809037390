import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { backgroundTheme } from '../style';

interface SkeletonLoaderProps {
  height: any;
  width: any;
  speed: any;
  primaryColor: any;
  secondaryColor: any;
  backgroundColor: any;
  children: ReactNode;
}

const SkeletonLoader = ({
  height,
  width,
  speed,
  primaryColor,
  secondaryColor,
  backgroundColor,
  children,
}: SkeletonLoaderProps): ReactElement => {
  return (
    <div style={{ width: '90%', padding: '5%' }}>
      <ContentLoader
        height={height}
        width={width}
        speed={speed}
        primarycolor={primaryColor}
        secondarycolor={secondaryColor}
        backgroundColor={backgroundColor}
      >
        {children}
      </ContentLoader>
    </div>
  );
};

SkeletonLoader.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
  speed: PropTypes.any,
};

SkeletonLoader.defaultProps = {
  speed: 2,
  primaryColor: backgroundTheme.block,
  secondaryColor: backgroundTheme.surface,
  backgroundColor: backgroundTheme.block,
};

export default SkeletonLoader;
