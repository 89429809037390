export const MONTHS_OPTIONS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const GENDER_OBJECTS = {
  1: 'Man',
  2: 'Woman',
};

export const GENDER_OPTIONS = [
  {
    id: 'sex_man',
    name: 'sex',
    label: 'Man',
    value: 1,
  },
  {
    id: 'sex_woman',
    name: 'sex',
    label: 'Woman',
    value: 2,
  },
];

export const ACCOUNT_TYPE_SUCCESSFUL_USER = 1;
export const ACCOUNT_TYPE_ATTRACTIVE_USER = 2;

export const ACCOUNT_TYPES_OBJECT = {
  1: 'Make Offers',
  2: 'Receive Offers',
};

export const ACCOUNT_TYPES_OPTIONS = [
  {
    id: 'account_type_make_bids',
    name: 'account_type',
    label: 'Make Offers',
    value: 1,
  },
  {
    id: 'account_type_receive_bids',
    name: 'account_type',
    label: 'Receive Offers',
    value: 2,
  },
];

export const INTERESTED_IN_OBJECT = {
  1: 'Women',
  2: 'Men',
  3: 'Both',
};

export const INTERESTED_IN_OPTIONS = [
  {
    id: 'interested_in_women',
    name: 'interested_in',
    label: 'Women',
    value: 1,
  },
  {
    id: 'interested_in_men',
    name: 'interested_in',
    label: 'Men',
    value: 2,
  },
  {
    id: 'interested_in_both',
    name: 'interested_in',
    label: 'Both',
    value: 3,
  },
];

export const ANNUAL_INCOME_OPTIONS = [
  { value: 101, label: '< US $100,000' },
  { value: 102, label: 'US $100k - $300k' },
  { value: 103, label: 'US $300k - $1M' },
  { value: 104, label: '> US $1M' },
];

export const EDUCATION_OPTIONS = [
  { id: 1, value: 'High School', label: 'High school' },
  { id: 2, value: 'Some College', label: 'Some college' },
  { id: 3, value: 'Associates Degree', label: 'Associate Deg.' },
  { id: 4, value: "Bachelor's Degree", label: 'Bachelors Deg.' },
  { id: 5, value: 'Graduate Degree', label: 'Graduate Deg.' },
  { id: 6, value: 'PhD / Post Doctoral', label: 'Post-Doctoral' },
];

export const INTERESTS_OPTIONS = [
  { value: 'dating_just_dating', label: 'Dating' },
  { value: 'dating_friendship', label: 'Friendship' },
  { value: 'dating_marriage', label: 'Long-term' },
  { value: 'dating_married', label: 'Discreet affairs' },
  { value: 'dating_adult', label: 'Casual dating' },
  { value: 'dating_mentorship', label: 'Mentorship' },
];

export const MALE_USER_EXCLUDE_BODY_TYPES = [6, 4];

export const BODY_TYPES = {
  3: 'Average',
  5: 'A Few Extra',
  4: 'Curvy',
  6: 'Full-figured',
  2: 'Athletic',
  7: 'Muscular',
  1: 'Slender',
  8: 'Other',
};

export const BODY_TYPE_OPTIONS = [
  { value: 3, label: 'Average' },
  { value: 5, label: 'A Few Extra Pounds' },
  { value: 4, label: 'Curvy' },
  { value: 6, label: 'Full-figured' },
  { value: 2, label: 'Athletic' },
  { value: 7, label: 'Muscular' },
  { value: 1, label: 'Slender' },
  { value: 8, label: 'Other' },
];

export const MALE_BODY_TYPE_OPTIONS = [
  { value: 1, label: 'Slender' },
  { value: 3, label: 'Average' },
  { value: 5, label: 'A Few Extra Pounds' },
  { value: 2, label: 'Athletic' },
  { value: 7, label: 'Muscular' },
  { value: 8, label: 'Other' },
];

export const FEMALE_BODY_TYPE_OPTIONS = [
  { value: 1, label: 'Slender' },
  { value: 3, label: 'Average' },
  { value: 2, label: 'Athletic' },
  { value: 7, label: 'Muscular' },
  { value: 4, label: 'Curvy' },
  { value: 5, label: 'A Few Extra Pounds' },
  { value: 6, label: 'Full-figured' },
  { value: 8, label: 'Other' },
];

export const YES_NO_OPTIONS_OBJECT = {
  2: 'Yes',
  1: 'No',
  3: 'Occasionally',
};

export const YES_NO_OPTIONS = (
  showOccasionally?: boolean,
  reverseOrder?: boolean
): { value: number; label: string }[] => {
  let options = [
    { value: 2, label: 'Yes' },
    { value: 1, label: 'No' },
  ];

  if (reverseOrder) {
    options = options.reverse();
  }

  return showOccasionally ? [...options, { value: 3, label: 'Occasionally' }] : options;
};
