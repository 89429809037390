import { useEffect, useCallback, useMemo } from 'react';
import { storeEvidently } from '../actions/globalActions';
import {
  EvidentlyFeatureExperiment,
  EVIDENTLY_FEATURES,
  getFeatureDetails,
  showNewVariation,
} from '../constants/evidently';
import { useApp } from '../contexts/AppContext';
import { Profile } from '../models/Profile';
import { TrackEvidentlyMetricsParams, batchEvaluateFeature, trackEvidentlyMultipleMetrics } from '../sdk/CommonSDK';
import store from '../store';

interface FeatureEvaluationParams {
  localStorageKey: string;
  featureName: string;
  experimentName: string;
  featureMetrics?: <KeysType>(details: EvidentlyFeatureExperiment) => TrackEvidentlyMetricsParams<KeysType>[];
  featureHandleEvaluate?: (profile?: Profile) => boolean;
  featureCallback?: (
    props: EvidentlyFeatureExperiment,
    isAccountIsMixpanelEligableEnabled: boolean,
    profile?: Profile
  ) => void; // Optional callback function
}

type GetFeatureNamesToEvaluateCallbackType = () => { feature: EVIDENTLY_FEATURES }[];

const useEvidently = (): any => {
  const { setIsEvaluating, isAccountIsMixpanelEligableEnabled } = useApp();

  const useFeaturesEvaluation = async (params: FeatureEvaluationParams[], profile?: Profile): Promise<void> => {
    const getFeatureNamesToEvaluate = useCallback<GetFeatureNamesToEvaluateCallbackType>(() => {
      const props = store.getState();
      const evaluateFeatures = params.map(item => {
        const { featureName } = item;

        let isAllowToEvaluate = true;

        if (typeof item?.featureHandleEvaluate === 'function') {
          isAllowToEvaluate = item.featureHandleEvaluate(profile);
        }

        const existingVariation =
          props.settings.evidently && props.settings.evidently[featureName]
            ? props.settings.evidently[featureName]
            : null;

        return existingVariation === null && isAllowToEvaluate ? { feature: featureName } : null;
      });

      return evaluateFeatures.filter(feature => feature !== null);
    }, [params.length]);

    useEffect(() => {
      const features = getFeatureNamesToEvaluate();

      if (features.length === 0) return;

      (async () => {
        try {
          setIsEvaluating(true);
          const response = await batchEvaluateFeature({ features });
          const metrics: TrackEvidentlyMetricsParams<unknown>[] = [];

          response.data.map(feature => {
            const { reason, variation, userId, featureName } = feature;
            store.dispatch(storeEvidently({ [featureName]: { variation, userId, reason } }));

            // Check if a featureCallback is provided for this feature and call it
            const featureParam = params.find(item => item.featureName === featureName);

            if (featureParam) {
              featureParam.featureCallback?.(feature, isAccountIsMixpanelEligableEnabled, profile);
              featureParam.featureMetrics?.(feature).forEach(metric => metrics.push(metric));
            }
          });

          if (metrics.length > 0) {
            await trackEvidentlyMultipleMetrics(metrics);
          }

          setIsEvaluating(false);
        } catch (error) {
          console.error('batchEvaluateFeature', { error, features });
        }
      })();
    }, [getFeatureNamesToEvaluate]);
  };

  const useActiveVariation = (feature: string) => {
    const props = store.getState();
    return useMemo(() => showNewVariation(props.settings?.evidently, feature), [props.settings?.evidently]);
  };

  const useFeatureDetails = (feature: string) => {
    const props = store.getState();
    return useMemo(() => getFeatureDetails(props.settings?.evidently, feature), [props.settings?.evidently]);
  };

  return {
    useFeaturesEvaluation,
    useActiveVariation,
    useFeatureDetails,
  };
};

export default useEvidently;
