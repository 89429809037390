import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import MailCheck from 'react-mailcheck';
import { borders, textColor, box } from '../style';

import TextInput from './TextInput';
import { EMAIL_ALLOWED_DOMAINS } from '../config/constants';
import { EVENT_NAMES, trackMixpanelAnonymousEvents } from '../constants/mixpanel';
import { useApp } from '../contexts/AppContext';
import styled from '@emotion/styled';
import { isEmpty } from '../utils/helpers';
import { handleNewJoinFlowVysionEvent } from '../utils/vysion/join/newJoinFlow';
import { NewJoinFlowClickVysionEventsEnum } from '../utils/vysion/join/newJoinFlow/click';
import { mixpanelTrackUserClickedEmailDomain } from '../utils/mixpanel/userClickedEmailDomain';

const EmailAutoSuggestWrapper = styled('div')({
  position: 'relative',
  maxWidth: '452px',
  width: '100%',
});

const AutoSuggestList = styled('ul')({
  ...box,
  boxSizing: 'border-box',
  boxShadow: '0px 5px 6px 0px rgba(0,0,0,0.3)',
  position: 'absolute',
  margin: 0,
  padding: 0,
  backgroundColor: '#ffffff',
  zIndex: 999,
  top: '60px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
  borderTop: 0,
  border: '1px solid rgba(0,0,0,0.15)',
  '> li.active': {
    backgroundColor: '#F5F7FA',
    cursor: 'default',
  },
});

const AutoSuggestListItem = styled('li')({
  listStyle: 'none',
  margin: '0 12px',
  padding: '0 4px',
  lineHeight: '30px',
  overflow: 'hidden',
  textWrap: 'nowrap',
  ':first-of-type': {
    borderTop: borders.DASHED,
  },
  ':last-of-type': {
    marginBottom: '5px',
  },
  ':hover': {
    backgroundColor: '#F5F7FA',
    cursor: 'default',
  },
});

const checkEmail = email => {
  if (email.includes('@z.incube8.sg')) {
    return true;
  }

  const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])*(\.\w{2,3})+$/;

  return reg.test(email);
};

interface EmailInputProps {
  onChange: (e: { target: { id: string; value: string } }) => void;
  value: any;
  inputLabel: any;
  statusMessage: any;
  status: any;
  autoFocus?: boolean;
  customStyle: React.CSSProperties;
  customInputStyle: React.CSSProperties;
  isLogin: boolean;
}

const EmailInput = ({
  onChange,
  value: val,
  inputLabel,
  statusMessage,
  status,
  customStyle,
  isLogin = false,
  autoFocus,
  customInputStyle,
  ...rest
}: EmailInputProps): ReactElement => {
  const [fieldError, setFieldError] = useState('');
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [suggestedDomains, setSuggestedDomains] = useState<string[]>([]);
  const [showDomainSuggestion, setShowDomainSuggestion] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const { accountHashId } = useApp();
  const pathname = window.location.pathname;

  const getEmailStatusMessage = suggestion => {
    if (suggestion && checkEmail(suggestion.full)) {
      return (
        <div style={{ color: 'black' }}>
          Did you mean{' '}
          <span
            style={{ cursor: 'pointer', color: textColor.BRAND.color, fontWeight: 600 }}
            onClick={() => {
              setEmailSuggestion('');
              setOnChangeEmail(suggestion.full);
            }}
          >
            {suggestion.full}
          </span>
          ?
        </div>
      );
    }
  };

  const setOnChangeEmail = value => {
    onChange({ target: { id: 'email', value } });
  };

  const getDomainSuggestions = (actualValue, slicedValue) => {
    const strValue = slicedValue.trim().toLowerCase();
    const strLength = strValue.length;
    const allowedDomains = EMAIL_ALLOWED_DOMAINS;

    if (strLength === 0) {
      return [];
    }

    const domainSuggestions = allowedDomains.filter(domain => {
      return domain.split(' ').some(text => {
        return text.toLowerCase().slice(0, strLength) === strValue;
      });
    });

    if (domainSuggestions.length === 1 && domainSuggestions[0] === strValue) {
      return [];
    }

    return domainSuggestions.map(domains => actualValue + domains);
  };

  const handleEmailAutoSuggest = e => {
    const { value } = e.target;
    const slicedValue = value.slice(value.indexOf('@'));
    const actualValue = value.slice(0, value.indexOf('@'));
    const suggestedDomainList = getDomainSuggestions(actualValue, slicedValue);

    setCursor(-1);
    setShowDomainSuggestion(!isEmpty(actualValue) && suggestedDomainList.length > 0);
    setSuggestedDomains(suggestedDomainList);
    setOnChangeEmail(value);
  };

  const handleOnClickVysionEvent = () => {
    pathname === '/join' &&
      handleNewJoinFlowVysionEvent({
        event: NewJoinFlowClickVysionEventsEnum.EMAIL_ENTRY,
      });
  };

  const setSelectedDomain = value => {
    setOnChangeEmail(value);
    const domain = value.slice(value.indexOf('@'));

    pathname === '/join' &&
      handleNewJoinFlowVysionEvent({
        event: NewJoinFlowClickVysionEventsEnum.EMAIL_DOMAIN_SELECTION,
      });

    if (accountHashId) {
      mixpanelTrackUserClickedEmailDomain({ Domain: domain });
    } else {
      trackMixpanelAnonymousEvents(EVENT_NAMES.USER_CLICKED_EMAIL_DOMAIN, {
        Domain: domain,
      });
    }
  };

  const handleOnTextChange = (e, suggestion) => {
    const { value } = e.target;
    let error = '';
    const emailMessage = getEmailStatusMessage(suggestion);

    if (typeof value === 'string' && value.length > 0 && checkEmail(value) === false) {
      error = 'Please use a valid email address.';
    }
    onChange({ target: { id: 'email', value } }, !!(error || emailMessage));

    setFieldError(error);
    setShowDomainSuggestion(false);
    setEmailSuggestion(emailMessage ? emailMessage : '');
  };

  const keyboardNavigation = e => {
    if (!['ArrowDown', 'ArrowUp', 'Escape', 'Enter'].includes(e.key)) {
      return;
    }

    showDomainSuggestion && e.preventDefault();

    if (e.key === 'ArrowDown') {
      showDomainSuggestion
        ? setCursor(prevState => (prevState < suggestedDomains.length - 1 ? prevState + 1 : 0))
        : setShowDomainSuggestion(false);
    }

    if (e.key === 'ArrowUp') {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : suggestedDomains.length - 1));
    }

    if (e.key === 'Escape') {
      setShowDomainSuggestion(false);
    }

    if (e.key === 'Enter' && showDomainSuggestion) {
      setSelectedDomain(suggestedDomains[cursor]);
      setShowDomainSuggestion(false);
    }
  };

  return (
    <MailCheck email={val}>
      {suggestion => {
        return (
          <EmailAutoSuggestWrapper onKeyDown={e => keyboardNavigation(e)}>
            <TextInput
              id="email"
              inputLabel={inputLabel}
              name="email"
              type="email"
              value={val}
              onBlur={e => handleOnTextChange(e, suggestion)}
              status={(emailSuggestion && 'caution') || ((fieldError || statusMessage) && 'error') || status}
              statusMessage={emailSuggestion || fieldError || statusMessage}
              customStyle={{ maxWidth: '452px', ...customStyle }}
              onChange={e => handleEmailAutoSuggest(e)}
              onFocus={e => handleEmailAutoSuggest(e)}
              onClick={() => handleOnClickVysionEvent()}
              customInputStyle={{
                ...(isLogin && { borderColor: 'rgba(0, 0, 0, 0.15) !important' }),
                ...customInputStyle,
                ...(showDomainSuggestion && {
                  boxShadow: '0px 5px 6px 0px rgba(0,0,0,0.3)',
                  borderColor: 'rgba(0, 0, 0, 0.15) !important',
                }),
              }}
              isLogin={isLogin}
              autoFocus={autoFocus}
              {...rest}
            />
            {showDomainSuggestion && (
              <AutoSuggestList>
                <li style={{ listStyle: 'none', borderTop: '1px dashed #ccc', margin: '0 12px', lineHeight: '5px' }}>
                  &nbsp;
                </li>
                {suggestedDomains.map((domain, idx) => {
                  return (
                    <AutoSuggestListItem
                      onMouseDown={() => setSelectedDomain(domain)}
                      key={domain}
                      className={cursor === idx ? 'active' : ''}
                    >
                      {domain}
                    </AutoSuggestListItem>
                  );
                })}
              </AutoSuggestList>
            )}
          </EmailAutoSuggestWrapper>
        );
      }}
    </MailCheck>
  );
};

EmailInput.propTypes = {
  status: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  statusMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default EmailInput;
