/**
 * @Author: zachalam
 * @Date:   2017-04-03T16:34:01-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-04-03T16:34:01-07:00
 */

export const storeMeta = (payload: Record<string, unknown>): { payload: Record<string, unknown>; type: string } => {
  // store all threads for a user
  return {
    type: 'STORE_META',
    payload,
  };
};
