import React, { ReactElement, useState } from 'react';
import JoinCheckboxFormGroup from '../../join/JoinCheckboxFormGroup';
import { DashlineSeparator } from '../../join/styledElements';
import { styledDropzoneStylesSmallPicture } from '../styledElements';
import { getHeicImage, getImage } from '../../../modules/gallery/helpers';
import JoinPhotoUploadItem from '../../join/bulkUpload/JoinPhotoUploadItem';
import { PhotoFileType, PhotoListTypes } from '../../../modules/gallery/constants';
import uuidv4 from 'uuid';
import colors from '../../../theme/colors';
import JoinEditablePhotoModal from '../../join/bulkUpload/JoinEditablePhotoModal';
import orderBy from 'lodash/orderBy';

interface ICompletePhotoUploadFormProps {
  handlePhotoUpload: any;
  label: string;
  onCancelUpload?: () => void;
}

const CompletePhotoUploadForm = ({
  handlePhotoUpload,
  onCancelUpload,
  label,
}: ICompletePhotoUploadFormProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [previewPhotos, setPreviewPhotos] = useState<PhotoListTypes>([]);
  const [activeFile, setActiveFile] = useState<PhotoFileType | null>(null);

  const cleanupFiles = async photos => {
    const promises = [];

    Object.keys(photos).forEach(index => {
      const currentFile = photos[index];

      if (!currentFile || /^image\/[a-z0-9]*/.test(currentFile.type) === false) {
        return;
      }

      if (['image/heic', 'image/heif'].includes(currentFile.type)) {
        promises.push(getHeicImage(currentFile) as never);
      } else {
        promises.push(getImage(currentFile) as never);
      }
    });

    return await Promise.all(promises);
  };

  const preparePhotos = async photos => {
    setIsLoading(true);
    const cleanFiles = await cleanupFiles(photos);
    const promises = [];

    for (const resultImage of cleanFiles) {
      promises.push(
        new Promise(resolve => {
          resolve({
            edited: resultImage,
            index: uuidv4(),
            raw: resultImage,
            private: false,
            primary: false,
          });
        }) as never
      );
    }

    Promise.all(promises).then(data => {
      setPreviewPhotos(prev => {
        const updateData = Array.from([...prev, ...data]);

        return updateData.map((item, index) => {
          item.primary = false;

          if (index === 0) {
            item.primary = true;
          }

          return item;
        });
      });
      handlePhotoUpload({ photos: [...previewPhotos, ...data] });
      setIsLoading(false);
    });
  };

  const onRemove = (selectedPhotoIndex: number) => {
    const clonedPhotos = [...previewPhotos];
    const cleanPhotos = clonedPhotos.filter(photo => photo.index !== clonedPhotos[selectedPhotoIndex].index);

    if (cleanPhotos.length > 0) {
      cleanPhotos[0].primary = true;
      cleanPhotos[0].private = false;
      cleanPhotos.slice(1).forEach(photo => {
        photo.primary = false;
      });
    }

    setPreviewPhotos(cleanPhotos);
    handlePhotoUpload({ photos: cleanPhotos });
  };

  const onEdit = (file: PhotoFileType) => {
    setActiveFile(file);
  };

  const updateFileLists = async (file: File) => {
    if (activeFile === null) {
      return;
    }

    const updatedActiveFile = { ...activeFile };
    updatedActiveFile.edited = (await getImage(file)) as string;
    setActiveFile(updatedActiveFile);

    const updatePreviewPhotos = previewPhotos.map(photo =>
      photo.index === activeFile.index ? updatedActiveFile : photo
    );

    setPreviewPhotos(updatePreviewPhotos);
    handlePhotoUpload({ photos: updatePreviewPhotos });
  };

  const updatePrivatePrimaryFile = ({ isPrimary, isPrivate }) => {
    if (activeFile === null) return;

    const updatedActiveFile = { ...activeFile };
    updatedActiveFile.primary = isPrimary;
    updatedActiveFile.private = isPrivate;

    setActiveFile(updatedActiveFile);

    const updatePreviewPhotos = previewPhotos.map(photo =>
      photo.index === activeFile.index ? updatedActiveFile : { ...photo, primary: false }
    );
    const orderdPreviewPhotos = orderBy(updatePreviewPhotos, ['primary'], ['desc']);
    setPreviewPhotos(orderdPreviewPhotos);
    handlePhotoUpload({ photos: orderdPreviewPhotos });
  };

  const previewPhotoUrl = (index: number) => {
    return previewPhotos[index] ? previewPhotos[index].edited : undefined;
  };

  const onPhotoUpload = photos => {
    preparePhotos(photos);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            color: colors.red[500],
            fontSize: 14,
            fontWeight: 600,
            margin: '20px 0',
          }}
        >
          Hang tight, we’re preparing your photos...
        </div>
      )}

      <JoinCheckboxFormGroup label={label} isRequired>
        <div style={{ display: 'flex' }}>
          <JoinPhotoUploadItem
            key={0}
            photoUrl={previewPhotoUrl(0)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(0)}
            onEdit={() => onEdit(previewPhotos[0])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[0]?.private ?? false}
          />
          <JoinPhotoUploadItem
            key={1}
            photoUrl={previewPhotoUrl(1)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(1)}
            onEdit={() => onEdit(previewPhotos[1])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[1]?.private ?? false}
          />
          <JoinPhotoUploadItem
            key={2}
            photoUrl={previewPhotoUrl(2)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(2)}
            onEdit={() => onEdit(previewPhotos[2])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[2]?.private ?? false}
          />
        </div>
        <div className="other-pic" style={{ display: 'flex' }}>
          <JoinPhotoUploadItem
            key={3}
            photoUrl={previewPhotoUrl(3)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(3)}
            onEdit={() => onEdit(previewPhotos[3])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[3]?.private ?? false}
          />
          <JoinPhotoUploadItem
            key={4}
            photoUrl={previewPhotoUrl(4)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(4)}
            onEdit={() => onEdit(previewPhotos[4])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[4]?.private ?? false}
          />
          <JoinPhotoUploadItem
            key={5}
            photoUrl={previewPhotoUrl(5)}
            customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
            onCancelUpload={onCancelUpload}
            setFileList={onPhotoUpload}
            onRemove={() => onRemove(5)}
            onEdit={() => onEdit(previewPhotos[5])}
            isLoading={isLoading}
            isSmallThumbnail
            countTotalPhotos={previewPhotos.length}
            isPrivate={previewPhotos[5]?.private ?? false}
          />
        </div>

        {previewPhotos.length > 6 &&
          (() => {
            const groupCount = Math.ceil((previewPhotos.length - 6) / 3);
            const items: JSX.Element[] = [];

            for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
              const groupItems: JSX.Element[] = [];

              for (let itemIndex = 0; itemIndex < 3; itemIndex++) {
                const photoIndex = 6 + groupIndex * 3 + itemIndex;

                if (photoIndex < previewPhotos.length) {
                  groupItems.push(
                    <JoinPhotoUploadItem
                      key={photoIndex}
                      photoUrl={previewPhotoUrl(photoIndex)}
                      customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
                      customAddIconStyle={{
                        height: 32.98,
                        width: 37.102,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      setFileList={onPhotoUpload}
                      onRemove={() => (previewPhotoUrl(photoIndex) ? onRemove(photoIndex) : undefined)}
                      onEdit={() => (previewPhotoUrl(photoIndex) ? onEdit(previewPhotos[photoIndex]) : undefined)}
                      isPrivate={previewPhotos[photoIndex]?.private ?? false}
                      isLoading={isLoading}
                      isSmallThumbnail
                      countTotalPhotos={previewPhotos.length}
                    />
                  );
                } else {
                  groupItems.push(
                    <JoinPhotoUploadItem
                      key={photoIndex}
                      containerStyle={
                        previewPhotos.length === 20
                          ? { visibility: 'visible', position: 'relative' }
                          : { visibility: 'hidden' }
                      }
                      photoItemStyle={{ visibility: 'hidden' }}
                      photoUrl={undefined}
                      customStyledDropzoneStyles={styledDropzoneStylesSmallPicture}
                      customAddIconStyle={{
                        height: 32.98,
                        width: 37.102,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      isUploading={false}
                      setFileList={onPhotoUpload}
                      onRemove={() => undefined}
                      onEdit={() => undefined}
                      isLoading={false}
                      isSmallThumbnail
                      isMaxLength={previewPhotos.length === 20}
                      countTotalPhotos={0}
                    />
                  );
                }
              }

              items.push(
                <div className="other-pic" style={{ display: 'flex' }} key={groupIndex}>
                  {groupItems}
                </div>
              );
            }

            return items;
          })()}
      </JoinCheckboxFormGroup>
      <DashlineSeparator />

      <JoinEditablePhotoModal
        isOpen={activeFile ? true : false}
        onReset={() => setActiveFile(null)}
        activeFile={activeFile}
        onClose={() => setActiveFile(null)}
        updateFileLists={updateFileLists}
        updatePrivatePrimaryFile={updatePrivatePrimaryFile}
      />

      <JoinEditablePhotoModal
        isOpen={activeFile ? true : false}
        onReset={() => setActiveFile(null)}
        activeFile={activeFile}
        onClose={() => setActiveFile(null)}
        updateFileLists={updateFileLists}
        updatePrivatePrimaryFile={updatePrivatePrimaryFile}
      />
    </>
  );
};

export default CompletePhotoUploadForm;
