import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { dimensions } from '../../../style';

const PrivateMonkey = styled('img')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '20px',
  zIndex: 2,
  padding: '6px',
});

const PrivateCorner = styled('div')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '20px',
  zIndex: 1,
  padding: '6px',
  backgroundColor: '#fff',
});

const PrivateTotal = styled('div')({
  position: 'absolute',
  top: '0',
  margin: '0 auto',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
  color: '#3a9be1',

  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #dde3ec',
  borderRadius: '4px',
  boxSizing: 'border-box',
});

const PrivateTotalCount = styled('div')({
  paddingTop: '12px',
});

const PrivateTotalTitle = styled('div')({
  paddingTop: '5px',
  fontSize: '12px',
});

const Photos = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-.5%',
});

const PhotoContainer = styled('div')({
  margin: '.5%',
  width: '24%',
  position: 'relative',
  ':hover': {
    cursor: 'pointer',
  },
  [dimensions.SCREEN_MAX_SM]: {
    margin: '.5%',
    width: '32%',
  },
});

const PhotoAspectRatio = styled('div')({
  paddingTop: '133%',
  position: 'relative',
});

const Photo = styled('div')(
  {
    width: '100%',
    height: '100%',

    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  ({ imgUrl }) => {
    return {
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }
);

const renderPrivateMonkeyCount = (url, total) => {
  if (total && total > 0) {
    return (
      <PrivateTotal>
        <img data-test-id="private-img-monkey" src={url} style={{ width: '34%' }} />
        <PrivateTotalCount>+{total}</PrivateTotalCount>
        <PrivateTotalTitle>Private photos/videos</PrivateTotalTitle>
      </PrivateTotal>
    );
  }
};

class Gallery extends PureComponent {
  render(): any {
    return <div>{this.renderGallery()}</div>;
  }

  renderGallery(): any {
    const { images, openLightbox } = this.props;

    if (!images) return null;

    const gallery = images.map((obj, i) => {
      return (
        <PhotoContainer
          key={i}
          onClick={e => {
            e.preventDefault();
            openLightbox(i, e);
          }}
        >
          {obj.privateReal && (
            <div>
              <PrivateCorner />
              <PrivateMonkey src={obj.thumbnail} />
            </div>
          )}
          {obj.private ? (
            <PhotoAspectRatio data-test-id="private-photo-container" isPrivate>
              {renderPrivateMonkeyCount(obj.src, obj.total)}
            </PhotoAspectRatio>
          ) : (
            <PhotoAspectRatio data-test-id="public-photo-container">
              <Photo imgUrl={obj.src} />
            </PhotoAspectRatio>
          )}
        </PhotoContainer>
      );
    });
    return <Photos>{gallery}</Photos>;
  }
}

Gallery.propTypes = {
  images: PropTypes.array,
  openLightbox: PropTypes.func.isRequired,
};

export default Gallery;
