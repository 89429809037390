import React, { ReactElement } from 'react';
interface GDPRCompliantIconProps {
  customWidth: any;
}

const GDPRCompliantIcon = ({ customWidth = 100 }: GDPRCompliantIconProps): ReactElement => {
  return (
    <svg width={customWidth} height="39" viewBox="0 0 100 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_73_1032)">
        <path
          d="M19.2673 4.29457L17.9655 5.07743C17.9074 5.11322 17.8492 5.13111 17.7866 5.12664C17.724 5.12216 17.6703 5.09979 17.6211 5.06401C17.5719 5.02822 17.5361 4.98348 17.5092 4.92533C17.4824 4.87165 17.4779 4.80902 17.4913 4.74192L17.8358 3.2612L16.6816 2.26361C16.628 2.2144 16.5967 2.1652 16.5832 2.10257C16.5698 2.04441 16.5743 1.98178 16.5967 1.9281C16.619 1.86995 16.6503 1.82521 16.6906 1.78495C16.7309 1.74916 16.789 1.7268 16.8651 1.71338L18.386 1.57917L18.9721 0.183448C18.9989 0.12082 19.0392 0.0716114 19.0929 0.0402971C19.1465 0.00898276 19.2047 -0.00891113 19.2628 -0.00891113C19.321 -0.00891113 19.3747 0.00898276 19.4328 0.0402971C19.4865 0.0716114 19.5268 0.12082 19.5536 0.183448L20.1396 1.57917L21.6606 1.71338C21.7322 1.72232 21.7904 1.74916 21.8351 1.78495C21.8754 1.82074 21.9067 1.86995 21.929 1.9281C21.9514 1.98626 21.9559 2.04441 21.9425 2.10257C21.929 2.16072 21.8977 2.2144 21.844 2.26361L20.6899 3.2612L21.0343 4.74192C21.0522 4.80902 21.0433 4.87165 21.0164 4.92533C20.9896 4.97901 20.9538 5.02375 20.9046 5.06401C20.8554 5.09979 20.8017 5.12216 20.7391 5.12664C20.6765 5.13111 20.6183 5.11769 20.5601 5.07743L19.2584 4.29457H19.2673Z"
          fill="#FFAF00"
        />
        <path
          d="M27.5566 6.53126L26.2548 7.31412C26.1967 7.34991 26.1385 7.3678 26.0759 7.36333C26.0133 7.35886 25.9596 7.33649 25.9104 7.3007C25.8612 7.26491 25.8254 7.22018 25.7985 7.16202C25.7717 7.10834 25.7672 7.04571 25.7807 6.97861L26.1251 5.49789L24.971 4.50031C24.9173 4.4511 24.886 4.40189 24.8725 4.33926C24.8591 4.28111 24.8636 4.21848 24.886 4.1648C24.9083 4.10664 24.9396 4.06191 24.9799 4.02165C25.0202 3.98586 25.0783 3.96349 25.1544 3.95007L26.6753 3.81587L27.2614 2.42014C27.2882 2.35751 27.3285 2.30831 27.3822 2.27699C27.4358 2.24568 27.494 2.22778 27.5522 2.22778C27.6103 2.22778 27.664 2.24568 27.7221 2.27699C27.7758 2.30831 27.8161 2.35751 27.8429 2.42014L28.429 3.81587L29.9499 3.95007C30.0215 3.95902 30.0797 3.98586 30.1244 4.02165C30.1647 4.05743 30.196 4.10664 30.2183 4.1648C30.2407 4.22295 30.2452 4.28111 30.2318 4.33926C30.2183 4.39742 30.187 4.4511 30.1333 4.50031L28.9792 5.49789L29.3236 6.97861C29.3415 7.04571 29.3326 7.10834 29.3058 7.16202C29.2789 7.21571 29.2431 7.26044 29.1939 7.3007C29.1447 7.33649 29.091 7.35886 29.0284 7.36333C28.9658 7.3678 28.9076 7.35438 28.8495 7.31412L27.5477 6.53126H27.5566Z"
          fill="#FFAF00"
        />
        <path
          d="M10.9824 35.233L9.68062 36.0159C9.62247 36.0517 9.56431 36.0696 9.50168 36.0651C9.43906 36.0606 9.38537 36.0383 9.33617 36.0025C9.28696 35.9667 9.25117 35.922 9.22433 35.8638C9.19749 35.8101 9.19302 35.7475 9.20644 35.6804L9.55089 34.1997L8.39674 33.2021C8.34306 33.1529 8.31174 33.1037 8.29832 33.041C8.2849 32.9829 8.28937 32.9203 8.31174 32.8666C8.33411 32.8084 8.36542 32.7637 8.40568 32.7234C8.44595 32.6876 8.5041 32.6653 8.58015 32.6519L10.1011 32.5176L10.6872 31.1219C10.714 31.0593 10.7543 31.0101 10.8079 30.9788C10.8616 30.9475 10.9198 30.9296 10.9779 30.9296C11.0361 30.9296 11.0898 30.9475 11.1479 30.9788C11.2016 31.0101 11.2419 31.0593 11.2687 31.1219L11.8547 32.5176L13.3757 32.6519C13.4473 32.6608 13.5054 32.6876 13.5502 32.7234C13.5904 32.7592 13.6218 32.8084 13.6441 32.8666C13.6665 32.9247 13.671 32.9829 13.6575 33.041C13.6441 33.0992 13.6128 33.1529 13.5591 33.2021L12.405 34.1997L12.7494 35.6804C12.7673 35.7475 12.7584 35.8101 12.7315 35.8638C12.7047 35.9175 12.6689 35.9622 12.6197 36.0025C12.5705 36.0383 12.5168 36.0606 12.4542 36.0651C12.3915 36.0696 12.3334 36.0562 12.2752 36.0159L10.9735 35.233H10.9824Z"
          fill="#FFAF00"
        />
        <path
          d="M27.5566 35.233L26.2548 36.0159C26.1967 36.0517 26.1385 36.0696 26.0759 36.0651C26.0133 36.0606 25.9596 36.0383 25.9104 36.0025C25.8612 35.9667 25.8254 35.922 25.7985 35.8638C25.7717 35.8101 25.7672 35.7475 25.7807 35.6804L26.1251 34.1997L24.971 33.2021C24.9173 33.1529 24.886 33.1037 24.8725 33.041C24.8591 32.9829 24.8636 32.9203 24.886 32.8666C24.9083 32.8084 24.9396 32.7637 24.9799 32.7234C25.0202 32.6876 25.0783 32.6653 25.1544 32.6519L26.6753 32.5176L27.2614 31.1219C27.2882 31.0593 27.3285 31.0101 27.3822 30.9788C27.4358 30.9475 27.494 30.9296 27.5522 30.9296C27.6103 30.9296 27.664 30.9475 27.7221 30.9788C27.7758 31.0101 27.8161 31.0593 27.8429 31.1219L28.429 32.5176L29.9499 32.6519C30.0215 32.6608 30.0797 32.6876 30.1244 32.7234C30.1647 32.7592 30.196 32.8084 30.2183 32.8666C30.2407 32.9247 30.2452 32.9829 30.2318 33.041C30.2183 33.0992 30.187 33.1529 30.1333 33.2021L28.9792 34.1997L29.3236 35.6804C29.3415 35.7475 29.3326 35.8101 29.3058 35.8638C29.2789 35.9175 29.2431 35.9622 29.1939 36.0025C29.1447 36.0383 29.091 36.0606 29.0284 36.0651C28.9658 36.0696 28.9076 36.0562 28.8495 36.0159L27.5477 35.233H27.5566Z"
          fill="#FFAF00"
        />
        <path
          d="M35.855 20.8822L34.5532 21.6651C34.495 21.7009 34.4369 21.7188 34.3742 21.7143C34.3116 21.7098 34.2579 21.6874 34.2087 21.6517C34.1595 21.6159 34.1237 21.5711 34.0969 21.513C34.07 21.4593 34.0656 21.3967 34.079 21.3296L34.4234 19.8488L33.2693 18.8513C33.2156 18.8021 33.1843 18.7528 33.1709 18.6902C33.1575 18.6321 33.1619 18.5694 33.1843 18.5157C33.2067 18.4576 33.238 18.4129 33.2782 18.3726C33.3185 18.3368 33.3767 18.3144 33.4527 18.301L34.9737 18.1668L35.5597 16.7711C35.5866 16.7085 35.6268 16.6593 35.6805 16.6279C35.7342 16.5966 35.7923 16.5787 35.8505 16.5787C35.9086 16.5787 35.9623 16.5966 36.0205 16.6279C36.0742 16.6593 36.1144 16.7085 36.1413 16.7711L36.7273 18.1668L38.2483 18.301C38.3198 18.31 38.378 18.3368 38.4227 18.3726C38.463 18.4084 38.4943 18.4576 38.5167 18.5157C38.539 18.5739 38.5435 18.6321 38.5301 18.6902C38.5167 18.7484 38.4854 18.8021 38.4317 18.8513L37.2775 19.8488L37.622 21.3296C37.6399 21.3967 37.6309 21.4593 37.6041 21.513C37.5772 21.5667 37.5415 21.6114 37.4923 21.6517C37.443 21.6874 37.3894 21.7098 37.3267 21.7143C37.2641 21.7188 37.206 21.7053 37.1478 21.6651L35.846 20.8822H35.855Z"
          fill="#FFAF00"
        />
        <path
          d="M2.68415 20.8822L1.38237 21.6651C1.32421 21.7009 1.26606 21.7188 1.20343 21.7143C1.1408 21.7098 1.08712 21.6874 1.03791 21.6517C0.988704 21.6159 0.952916 21.5711 0.926076 21.513C0.899235 21.4593 0.894761 21.3967 0.908182 21.3296L1.25264 19.8488L0.10743 18.8513C0.0537486 18.8021 0.0224342 18.7528 0.00901383 18.6902C-0.00440659 18.6321 6.68834e-05 18.5694 0.0224342 18.5157C0.0448016 18.4576 0.0761159 18.4129 0.116377 18.3726C0.156638 18.3368 0.214794 18.3144 0.290843 18.301L1.81182 18.1668L2.39785 16.7711C2.42469 16.7085 2.46495 16.6593 2.51863 16.6279C2.57231 16.5966 2.63047 16.5787 2.68862 16.5787C2.74678 16.5787 2.80046 16.5966 2.85862 16.6279C2.9123 16.6593 2.95256 16.7085 2.9794 16.7711L3.56542 18.1668L5.0864 18.301C5.15798 18.31 5.21614 18.3368 5.26087 18.3726C5.30113 18.4084 5.33245 18.4576 5.35481 18.5157C5.37718 18.5739 5.38165 18.6321 5.36823 18.6902C5.35481 18.7484 5.3235 18.8021 5.26982 18.8513L4.11566 19.8488L4.46012 21.3296C4.47801 21.3967 4.46907 21.4593 4.44222 21.513C4.41538 21.5667 4.3796 21.6114 4.33039 21.6517C4.28118 21.6874 4.2275 21.7098 4.16487 21.7143C4.10224 21.7188 4.04409 21.7053 3.98593 21.6651L2.68415 20.8822Z"
          fill="#FFAF00"
        />
        <path
          d="M4.94334 12.6063L3.64156 13.3892C3.58341 13.425 3.52525 13.4429 3.46262 13.4384C3.39999 13.4339 3.34631 13.4116 3.2971 13.3758C3.2479 13.34 3.21211 13.2953 3.18527 13.2371C3.15843 13.1834 3.15395 13.1208 3.16737 13.0537L3.51183 11.573L2.35767 10.5754C2.30399 10.5262 2.27268 10.477 2.25926 10.4143C2.24584 10.3562 2.25031 10.2936 2.27268 10.2399C2.29505 10.1817 2.32636 10.137 2.36662 10.0967C2.40688 10.0609 2.46504 10.0386 2.54109 10.0251L4.06207 9.89094L4.64809 8.49522C4.67493 8.43259 4.71519 8.38338 4.76888 8.35206C4.82256 8.32075 4.88071 8.30286 4.93887 8.30286C4.99702 8.30286 5.0507 8.32075 5.10886 8.35206C5.16254 8.38338 5.2028 8.43259 5.22964 8.49522L5.81567 9.89094L7.33665 10.0251C7.40822 10.0341 7.46638 10.0609 7.51112 10.0967C7.55138 10.1325 7.58269 10.1817 7.60506 10.2399C7.62743 10.298 7.6319 10.3562 7.61848 10.4143C7.60506 10.4725 7.57374 10.5262 7.52006 10.5754L6.36591 11.573L6.71036 13.0537C6.72826 13.1208 6.71931 13.1834 6.69247 13.2371C6.66563 13.2908 6.62984 13.3355 6.58063 13.3758C6.53142 13.4116 6.47774 13.4339 6.41511 13.4384C6.35249 13.4429 6.29433 13.4295 6.23617 13.3892L4.93439 12.6063H4.94334Z"
          fill="#FFAF00"
        />
        <path
          d="M33.5959 12.6063L32.2942 13.3892C32.236 13.425 32.1778 13.4429 32.1152 13.4384C32.0526 13.4339 31.9989 13.4116 31.9497 13.3758C31.9005 13.34 31.8647 13.2953 31.8379 13.2371C31.811 13.1834 31.8065 13.1208 31.82 13.0537L32.1644 11.573L31.0103 10.5754C30.9566 10.5262 30.9253 10.477 30.9118 10.4143C30.8984 10.3562 30.9029 10.2936 30.9253 10.2399C30.9476 10.1817 30.9789 10.137 31.0192 10.0967C31.0595 10.0609 31.1176 10.0386 31.1937 10.0251L32.7147 9.89094L33.3007 8.49522C33.3275 8.43259 33.3678 8.38338 33.4215 8.35206C33.4751 8.32075 33.5333 8.30286 33.5915 8.30286C33.6496 8.30286 33.7033 8.32075 33.7614 8.35206C33.8151 8.38338 33.8554 8.43259 33.8822 8.49522L34.4683 9.89094L35.9892 10.0251C36.0608 10.0341 36.119 10.0609 36.1637 10.0967C36.204 10.1325 36.2353 10.1817 36.2576 10.2399C36.28 10.298 36.2845 10.3562 36.2711 10.4143C36.2576 10.4725 36.2263 10.5262 36.1726 10.5754L35.0185 11.573L35.363 13.0537C35.3808 13.1208 35.3719 13.1834 35.3451 13.2371C35.3182 13.2908 35.2824 13.3355 35.2332 13.3758C35.184 13.4116 35.1303 13.4339 35.0677 13.4384C35.0051 13.4429 34.9469 13.4295 34.8888 13.3892L33.587 12.6063H33.5959Z"
          fill="#FFAF00"
        />
        <path
          d="M33.5959 29.1537L32.2942 29.9366C32.236 29.9723 32.1778 29.9902 32.1152 29.9858C32.0526 29.9813 31.9989 29.9589 31.9497 29.9231C31.9005 29.8873 31.8647 29.8426 31.8379 29.7845C31.811 29.7308 31.8065 29.6681 31.82 29.601L32.1644 28.1203L31.0103 27.1227C30.9566 27.0735 30.9253 27.0243 30.9118 26.9617C30.8984 26.9035 30.9029 26.8409 30.9253 26.7872C30.9476 26.7291 30.9789 26.6843 31.0192 26.6441C31.0595 26.6083 31.1176 26.5859 31.1937 26.5725L32.7147 26.4383L33.3007 25.0426C33.3275 24.98 33.3678 24.9307 33.4215 24.8994C33.4751 24.8681 33.5333 24.8502 33.5915 24.8502C33.6496 24.8502 33.7033 24.8681 33.7614 24.8994C33.8151 24.9307 33.8554 24.98 33.8822 25.0426L34.4683 26.4383L35.9892 26.5725C36.0608 26.5815 36.119 26.6083 36.1637 26.6441C36.204 26.6799 36.2353 26.7291 36.2576 26.7872C36.28 26.8454 36.2845 26.9035 36.2711 26.9617C36.2576 27.0199 36.2263 27.0735 36.1726 27.1227L35.0185 28.1203L35.363 29.601C35.3808 29.6681 35.3719 29.7308 35.3451 29.7845C35.3182 29.8381 35.2824 29.8829 35.2332 29.9231C35.184 29.9589 35.1303 29.9813 35.0677 29.9858C35.0051 29.9902 34.9469 29.9768 34.8888 29.9366L33.587 29.1537H33.5959Z"
          fill="#FFAF00"
        />
        <path
          d="M4.94334 29.1537L3.64156 29.9366C3.58341 29.9723 3.52525 29.9902 3.46262 29.9858C3.39999 29.9813 3.34631 29.9589 3.2971 29.9231C3.2479 29.8873 3.21211 29.8426 3.18527 29.7845C3.15843 29.7308 3.15395 29.6681 3.16737 29.601L3.51183 28.1203L2.35767 27.1227C2.30399 27.0735 2.27268 27.0243 2.25926 26.9617C2.24584 26.9035 2.25031 26.8409 2.27268 26.7872C2.29505 26.7291 2.32636 26.6843 2.36662 26.6441C2.40688 26.6083 2.46504 26.5859 2.54109 26.5725L4.06207 26.4383L4.64809 25.0426C4.67493 24.98 4.71519 24.9307 4.76888 24.8994C4.82256 24.8681 4.88071 24.8502 4.93887 24.8502C4.99702 24.8502 5.0507 24.8681 5.10886 24.8994C5.16254 24.9307 5.2028 24.98 5.22964 25.0426L5.81567 26.4383L7.33665 26.5725C7.40822 26.5815 7.46638 26.6083 7.51112 26.6441C7.55138 26.6799 7.58269 26.7291 7.60506 26.7872C7.62743 26.8454 7.6319 26.9035 7.61848 26.9617C7.60506 27.0199 7.57374 27.0735 7.52006 27.1227L6.36591 28.1203L6.71036 29.601C6.72826 29.6681 6.71931 29.7308 6.69247 29.7845C6.66563 29.8381 6.62984 29.8829 6.58063 29.9231C6.53142 29.9589 6.47774 29.9813 6.41511 29.9858C6.35249 29.9902 6.29433 29.9768 6.23617 29.9366L4.93439 29.1537H4.94334Z"
          fill="#FFAF00"
        />
        <path
          d="M10.9824 6.53126L9.68062 7.31412C9.62247 7.34991 9.56431 7.3678 9.50168 7.36333C9.43906 7.35886 9.38537 7.33649 9.33617 7.3007C9.28696 7.26491 9.25117 7.22018 9.22433 7.16202C9.19749 7.10834 9.19302 7.04571 9.20644 6.97861L9.55089 5.49789L8.39674 4.50031C8.34306 4.4511 8.31174 4.40189 8.29832 4.33926C8.2849 4.28111 8.28937 4.21848 8.31174 4.1648C8.33411 4.10664 8.36542 4.06191 8.40568 4.02165C8.44595 3.98586 8.5041 3.96349 8.58015 3.95007L10.1011 3.81587L10.6872 2.42014C10.714 2.35751 10.7543 2.30831 10.8079 2.27699C10.8616 2.24568 10.9198 2.22778 10.9779 2.22778C11.0361 2.22778 11.0898 2.24568 11.1479 2.27699C11.2016 2.30831 11.2419 2.35751 11.2687 2.42014L11.8547 3.81587L13.3757 3.95007C13.4473 3.95902 13.5054 3.98586 13.5502 4.02165C13.5904 4.05743 13.6218 4.10664 13.6441 4.1648C13.6665 4.22295 13.671 4.28111 13.6575 4.33926C13.6441 4.39742 13.6128 4.4511 13.5591 4.50031L12.405 5.49789L12.7494 6.97861C12.7673 7.04571 12.7584 7.10834 12.7315 7.16202C12.7047 7.21571 12.6689 7.26044 12.6197 7.3007C12.5705 7.33649 12.5168 7.35886 12.4542 7.36333C12.3915 7.3678 12.3334 7.35438 12.2752 7.31412L10.9735 6.53126H10.9824Z"
          fill="#FFAF00"
        />
        <path
          d="M19.2673 37.4653L17.9655 38.2482C17.9074 38.284 17.8492 38.3019 17.7866 38.2974C17.724 38.2929 17.6703 38.2706 17.6211 38.2348C17.5719 38.199 17.5361 38.1543 17.5092 38.0961C17.4824 38.0424 17.4779 37.9798 17.4913 37.9127L17.8358 36.432L16.6816 35.4344C16.628 35.3852 16.5967 35.336 16.5832 35.2733C16.5698 35.2152 16.5743 35.1526 16.5967 35.0989C16.619 35.0407 16.6503 34.996 16.6906 34.9557C16.7309 34.9199 16.789 34.8976 16.8651 34.8842L18.386 34.7499L18.9721 33.3542C18.9989 33.2916 19.0392 33.2424 19.0929 33.2111C19.1465 33.1798 19.2047 33.1619 19.2628 33.1619C19.321 33.1619 19.3747 33.1798 19.4328 33.2111C19.4865 33.2424 19.5268 33.2916 19.5536 33.3542L20.1396 34.7499L21.6606 34.8842C21.7322 34.8931 21.7904 34.9199 21.8351 34.9557C21.8754 34.9915 21.9067 35.0407 21.929 35.0989C21.9514 35.157 21.9559 35.2152 21.9425 35.2733C21.929 35.3315 21.8977 35.3852 21.844 35.4344L20.6899 36.432L21.0343 37.9127C21.0522 37.9798 21.0433 38.0424 21.0164 38.0961C20.9896 38.1498 20.9538 38.1945 20.9046 38.2348C20.8554 38.2706 20.8017 38.2929 20.7391 38.2974C20.6765 38.3019 20.6183 38.2885 20.5601 38.2482L19.2584 37.4653H19.2673Z"
          fill="#FFAF00"
        />
        <path
          d="M15.0667 26.5008C14.682 26.5008 14.3509 26.3621 14.0781 26.0893C13.8052 25.8164 13.6665 25.4853 13.6665 25.1006V18.0996C13.6665 17.7149 13.8052 17.3839 14.0781 17.111C14.3509 16.8381 14.682 16.6994 15.0667 16.6994H15.769V15.2992C15.769 14.3285 16.109 13.5054 16.7935 12.8209C17.4779 12.1365 18.301 11.7965 19.2718 11.7965C20.2425 11.7965 21.0656 12.1365 21.7501 12.8209C22.4345 13.5054 22.7745 14.3285 22.7745 15.2992V16.6994H23.4768C23.8615 16.6994 24.1926 16.8381 24.4655 17.111C24.7383 17.3839 24.877 17.7149 24.877 18.0996V25.1006C24.877 25.4853 24.7383 25.8164 24.4655 26.0893C24.1926 26.3621 23.8615 26.5008 23.4768 26.5008H15.0756H15.0667ZM19.2673 22.9981C19.652 22.9981 19.983 22.8594 20.2559 22.5865C20.5288 22.3136 20.6675 21.9826 20.6675 21.5979C20.6675 21.2132 20.5288 20.8821 20.2559 20.6092C19.983 20.3364 19.652 20.1977 19.2673 20.1977C18.8826 20.1977 18.5515 20.3364 18.2787 20.6092C18.0058 20.8821 17.8671 21.2132 17.8671 21.5979C17.8671 21.9826 18.0058 22.3136 18.2787 22.5865C18.5515 22.8594 18.8826 22.9981 19.2673 22.9981ZM17.1648 16.695H21.3653V15.2948C21.3653 14.7132 21.1596 14.2167 20.7525 13.8051C20.3454 13.398 19.8488 13.1922 19.2628 13.1922C18.6768 13.1922 18.1847 13.398 17.7732 13.8051C17.3661 14.2122 17.1603 14.7087 17.1603 15.2948V16.695H17.1648Z"
          fill="#8F8F8F"
        />
        <path
          d="M52.0712 7.28723C55.0639 7.28723 56.907 8.76348 57.909 10.4634L54.961 12.0202C54.4063 11.1165 53.364 10.4008 52.0712 10.4008C49.8389 10.4008 48.2598 12.1231 48.2598 14.3553C48.2598 16.5876 49.8389 18.3099 52.0712 18.3099C53.1358 18.3099 54.0976 17.9386 54.6524 17.4912V16.346H51.5165V13.4159H58.1148V18.7662C56.6386 20.3856 54.6524 21.4279 52.0712 21.4279C47.9958 21.4279 44.6765 18.7035 44.6765 14.3598C44.6765 10.016 47.9958 7.2917 52.0712 7.2917V7.28723Z"
          fill="#8F8F8F"
        />
        <path
          d="M60.2219 7.51086H65.9614C70.2649 7.51086 73.356 10.0921 73.356 14.3329C73.356 18.5738 70.2604 21.1773 65.9793 21.1773H60.2219V7.51086ZM65.9614 18.104C68.3592 18.104 69.7504 16.3639 69.7504 14.3329C69.7504 12.302 68.48 10.5841 65.9793 10.5841H63.747V18.104H65.9614Z"
          fill="#8F8F8F"
        />
        <path
          d="M75.0425 7.51086H81.8869C84.9602 7.51086 86.6422 9.58208 86.6422 12.0604C86.6422 14.5387 84.9602 16.5473 81.8869 16.5473H78.5676V21.1773H75.0425V7.51086ZM81.4127 10.4812H78.5631V13.5545H81.4127C82.3566 13.5545 83.05 12.9596 83.05 12.038C83.05 11.1165 82.3521 10.4812 81.4127 10.4812Z"
          fill="#8F8F8F"
        />
        <path
          d="M93.2718 16.5473H91.4689V21.1773H87.9438V7.51086H94.7883C97.8213 7.51086 99.5436 9.51945 99.5436 12.0604C99.5436 14.4358 98.0897 15.7063 96.8192 16.1581L99.6062 21.1773H95.5711L93.2762 16.5473H93.2718ZM94.2559 10.4812H91.4689V13.5545H94.2559C95.1775 13.5545 95.9558 12.9596 95.9558 12.0201C95.9558 11.0807 95.1775 10.4812 94.2559 10.4812Z"
          fill="#8F8F8F"
        />
        <path
          d="M45.6248 28.2321C45.6248 26.1698 47.1458 24.7562 49.047 24.7562C50.2638 24.7562 51.0914 25.3288 51.6238 26.0848L50.917 26.4785C50.5367 25.9149 49.8388 25.5033 49.0426 25.5033C47.6021 25.5033 46.4837 26.6306 46.4837 28.2321C46.4837 29.8336 47.6021 30.9609 49.0426 30.9609C49.8388 30.9609 50.5322 30.5583 50.917 29.9857L51.6327 30.3794C51.069 31.1443 50.2638 31.708 49.0426 31.708C47.1369 31.708 45.6204 30.2989 45.6204 28.2321H45.6248Z"
          fill="#8F8F8F"
        />
        <path
          d="M56.0034 24.7562C57.9896 24.7562 59.3361 26.2459 59.3361 28.2321C59.3361 30.2183 57.9851 31.708 56.0034 31.708C54.0216 31.708 52.6707 30.2183 52.6707 28.2321C52.6707 26.2459 54.0082 24.7562 56.0034 24.7562ZM56.0034 25.5033C54.4914 25.5033 53.534 26.6619 53.534 28.2321C53.534 29.8023 54.4914 30.9609 56.0034 30.9609C57.5154 30.9609 58.4728 29.7934 58.4728 28.2321C58.4728 26.6709 57.5065 25.5033 56.0034 25.5033Z"
          fill="#8F8F8F"
        />
        <path
          d="M66.2161 25.9954L63.9302 31.5872H63.5857L61.3087 25.9954V31.5872H60.4722V24.868H61.6711L63.7557 29.9857L65.8493 24.868H67.0482V31.5872H66.2116V25.9954H66.2161Z"
          fill="#8F8F8F"
        />
        <path
          d="M68.2965 24.868H70.994C72.345 24.868 73.101 25.7941 73.101 26.8945C73.101 27.995 72.3271 28.921 70.994 28.921H69.1285V31.5917H68.292V24.8725L68.2965 24.868ZM70.8955 25.6151H69.133V28.1739H70.8955C71.6918 28.1739 72.2331 27.6505 72.2331 26.8945C72.2331 26.1385 71.6918 25.6151 70.8955 25.6151Z"
          fill="#8F8F8F"
        />
        <path d="M74.1387 24.868H74.9752V30.8401H78.0977V31.5872H74.1387V24.868Z" fill="#8F8F8F" />
        <path d="M79.1758 24.868H80.0123V31.5872H79.1758V24.868Z" fill="#8F8F8F" />
        <path
          d="M85.8503 30.0931H82.446L81.8511 31.5828H80.925L83.6226 24.8636H84.6604L87.3713 31.5828H86.4453L85.8503 30.0931ZM82.6876 29.3461H85.5998L84.1504 25.6107L82.6876 29.3461Z"
          fill="#8F8F8F"
        />
        <path
          d="M89.1383 26.1654V31.5828H88.3018V24.8636H89.1562L93.0257 30.1737V24.8636H93.8623V31.5828H93.0571L89.1383 26.1654Z"
          fill="#8F8F8F"
        />
        <path d="M97.0294 25.6107H94.9045V24.8636H99.9998V25.6107H97.866V31.5828H97.0294V25.6107Z" fill="#8F8F8F" />
      </g>
      <defs>
        <clipPath id="clip0_73_1032">
          <rect width="100" height="38.2974" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GDPRCompliantIcon;
