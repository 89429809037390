import React, { ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import 'react-image-crop/dist/ReactCrop.css';
import { dimensions } from '../../style';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';
import PublicEyeIcon from '../../atoms/icons/PublicEyeIcon';
import PrivateEyeIcon from '../../atoms/icons/PrivateEyeIcon';
import VisibilityIcon from '../../atoms/icons/VisibilityIcon';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import SecondaryButton from '../../atoms/buttons/SecondaryButton';
import GalleryUploadMobileActionButtonContainer from './GalleryUpload/components/GalleryUploadMobileActionButtonContainer';
import RoundPrivateIcon from '../../atoms/icons/RoundPrivateIcon';
import RoundPublicIcon from '../../atoms/icons/RoundPublicIcon';
import RoundBlurToolIcon from '../../atoms/icons/RoundBlurToolIcon';
import { ToggleV2 } from 'swui';
import RoundPrimaryIcon from '../../atoms/icons/RoundPrimaryIcon';

const PrivateNotch = styled('div')({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: 'rgb(188, 32, 40, 0.9)',
  transform: 'rotate(45deg)',
  width: 80,
  height: 80,
  top: -40,
  left: -40,
});

const StyledToggle = styled(ToggleV2)({
  width: 45,
  height: 20,
  [dimensions.SCREEN_MAX_XS]: {
    width: 50,
    height: 25,
    marginTop: 0,
    zIndex: 2,
  },
});

const PrivateEyeContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  height: 80,
  width: 80,
  zIndex: 1,
  borderTopLeftRadius: 5,
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const StyledDesktopActionButtons = styled('div')({
  padding: '20px 5px',
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'space-between',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const ButtonIconStyle = {
  marginRight: 6,
  cursor: 'pointer',
  height: 14,
  width: 14,
};

const PhotoContainer = styled('div')({
  overflow: 'hidden',
  minHeight: 320,
  padding: 38,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: screen.height <= 667 ? 380 : getBrowserDetails().hImgCalibration,
  backgroundColor: isDarkMode() ? '#113956' : '#7F7F7F',
  borderRadius: 8,
  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
    padding: 0,
    height: '100%',
    maxHeight: '100%',
    position: 'relative',
    marginBottom: 27,
  },
});

const PhotoPreviewContainer = styled('div')({
  marginTop: 31,
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: 18,
  },
});

const StyledPrivatePhotoMobileLabel = styled('div')({
  display: 'none',
  background: '#BC2028',
  color: '#FFF',
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '-0.42px',
  height: 38,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  top: '0',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'flex',
  },
});

const StyledFinalizeActionMobileWrapper = styled.div`
  display: none;

  ${dimensions.SCREEN_MAX_XS} {
    display: block;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    top: 15px;
    left: 17px;
  }
`;

const StyledPreviewImage = styled('img')({
  height: getBrowserDetails().hImgCalibration,
  margin: '0 auto',
  maxHeight: getBrowserDetails().hImgCalibration,
  borderRadius: 8,
  [dimensions.SCREEN_MAX_XS]: {
    height: '100%',
    maxHeight: '100%',
    width: '100%',
  },
});

const StyledPreviewMobileImage = styled('img')({
  margin: '0 auto',
  borderRadius: 8,
  display: 'none',
  [dimensions.SCREEN_MAX_XS]: {
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    display: 'block',
  },
});

const StyledDesktopPhotoWrapper = styled('div')({
  display: 'relative',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

interface PhotoPreviewProps {
  imageUrl: string;
  onAddPhoto: () => any;
  nextBlur: () => any;
  onCancel?: () => any;
  isPrivatePhoto: boolean;
  showPrimarySelector?: boolean;
  isPrimaryPhoto?: boolean;
}

const PhotoPreview = ({
  imageUrl,
  onAddPhoto,
  nextBlur,
  isPrivatePhoto,
  showPrimarySelector = false,
  isPrimaryPhoto,
  onCancel = () => undefined,
}: PhotoPreviewProps): ReactElement => {
  const [isPrimary, setIsPrimary] = useState(isPrimaryPhoto ?? false);
  const [isPrivate, setIsPrivate] = useState(isPrivatePhoto ?? false);

  return (
    <PhotoPreviewContainer>
      <PhotoContainer data-test-id="upload-photo-preview">
        <StyledFinalizeActionMobileWrapper>
          {showPrimarySelector && !isPrimaryPhoto && (
            <RoundPrimaryIcon
              customStyle={{ marginBottom: isPrimary ? 0 : 10 }}
              fill={!isPrimary ? '#C9C9C9' : '#BC2028'}
              onClick={() => {
                setIsPrimary(prev => {
                  const primary = !prev;
                  if (primary) {
                    setIsPrivate(false);
                  }

                  return primary;
                });
              }}
            />
          )}
          {isPrivate && !isPrimary && <RoundPrivateIcon onClick={() => setIsPrivate(prev => !prev)} />}
          {!isPrivate && !isPrimary && <RoundPublicIcon onClick={() => setIsPrivate(prev => !prev)} />}
          <RoundBlurToolIcon onClick={nextBlur} customStyle={{ marginTop: 10 }} />
        </StyledFinalizeActionMobileWrapper>
        <StyledDesktopPhotoWrapper>
          {isPrivate && (
            <PrivateEyeContainer>
              <PrivateNotch />
              <VisibilityIcon customStyle={{ position: 'absolute', top: 0, left: 0, zIndex: 3, padding: 8 }} />
            </PrivateEyeContainer>
          )}
          <StyledPreviewImage src={imageUrl} />
        </StyledDesktopPhotoWrapper>

        {isPrivate && <StyledPrivatePhotoMobileLabel>Private photo set</StyledPrivatePhotoMobileLabel>}
        {isPrimary && <StyledPrivatePhotoMobileLabel>Profile pic set</StyledPrivatePhotoMobileLabel>}

        <StyledPreviewMobileImage src={imageUrl} />
      </PhotoContainer>
      <StyledDesktopActionButtons>
        <div style={{ display: 'flex' }}>
          <SecondaryButton
            size="small"
            color="blue"
            onClick={nextBlur}
            customStyle={{ width: 'inherit' }}
            data-test-id="upload-photo-blur"
            label="Blur Tool"
          />

          {!isPrimary && (
            <SecondaryButton
              size="small"
              color="blue"
              onClick={() => setIsPrivate(prev => !prev)}
              data-test-id="upload-photo-submit"
              label={isPrivate ? 'Make Public' : 'Make Private'}
              customStyle={{ width: 'inherit', marginLeft: 15, display: 'flex', alignItems: 'center' }}
              withIcon={
                isPrivate ? (
                  <PublicEyeIcon customStyle={ButtonIconStyle} color="#2B8FD7" />
                ) : (
                  <PrivateEyeIcon customStyle={ButtonIconStyle} color="#2B8FD7" />
                )
              }
            />
          )}

          {showPrimarySelector && !isPrimaryPhoto && (
            <div style={{ marginLeft: 16, display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 12, letterSpacing: '-0.42px', marginBottom: 3 }}>Make Primary</span>
              <StyledToggle
                className="toggleSWUI"
                checked={isPrimary}
                onChange={event => {
                  const isChecked = event.target.checked;
                  setIsPrimary(isChecked);
                  if (isChecked) {
                    setIsPrivate(false);
                  }
                }}
              />
            </div>
          )}
        </div>

        <PrimaryButton
          size="small"
          color="blue"
          label="Save and Close"
          onClick={() => onAddPhoto({ isPrimary, isPrivate })}
          data-test-id="upload-photo-submit"
          customStyle={{ width: 'inherit' }}
        />
      </StyledDesktopActionButtons>

      <GalleryUploadMobileActionButtonContainer onCancel={onCancel}>
        <PrimaryButton
          size="small"
          color="blue"
          label="Save and Close"
          onClick={() => onAddPhoto({ isPrimary, isPrivate })}
          data-test-id="upload-photo-submit"
          customStyle={{ width: 'inherit', minWidth: 150 }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </PhotoPreviewContainer>
  );
};

PhotoPreview.defaultProps = {
  fileName: 'blob',
};

export default PhotoPreview;
