import React from 'react';
import { generalIcons } from '../icons/materialIcons';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const OfferIcons = styled('div')(
  {
    display: 'inline-flex',
    marginTop: 10,
  },
  ({ isOfferHighlight }) =>
    isOfferHighlight && {
      svg: {
        width: '64px !important',
        height: '64px !important',
      },
    }
);

interface OfferActionButtonProps {
  offerType: any;
  isOfferHighlight: any;
  handleIgnoreOffer: any;
  handleCounter: any;
  counterOfferDisabled: any;
  accountHashId: any;
  handleAcceptOffer: any;
  handleSuggestOffer: any;
  currentUserIsGenerous: any;
  handleCancelOffer: any;
  handleCancelSuggestion: any;
}

const OfferActionButton = ({
  offerType,
  isOfferHighlight,
  handleIgnoreOffer,
  handleCounter,
  counterOfferDisabled,
  accountHashId,
  handleAcceptOffer,
  handleSuggestOffer,
  currentUserIsGenerous,
  handleCancelOffer,
  handleCancelSuggestion,
}: OfferActionButtonProps): any => {
  switch (offerType) {
    case 'offer_new':
      return (
        <React.Fragment>
          <OfferIcons
            id="triggerIgnoreOfferHighlight"
            data-test-id="trigger-ignore-offer-highlight"
            onClick={handleIgnoreOffer}
            isOfferHighlight={isOfferHighlight}
          >
            <generalIcons.ignore.highlight />
          </OfferIcons>
          {isOfferHighlight ? (
            <OfferIcons
              id="triggerCounterOfferHighlight"
              data-test-id="trigger-counter-offer-highlight"
              disabled={counterOfferDisabled}
              onClick={handleCounter}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.offers.highlight />
            </OfferIcons>
          ) : (
            <OfferIcons
              id="triggerCounterOfferHighlight"
              data-test-id="trigger-counter-offer-highlight"
              isOfferHighlight={isOfferHighlight}
            >
              <Link to={!counterOfferDisabled && `/profile/${accountHashId}?fromOfferList=true&isCounter=true`}>
                <generalIcons.offers.highlight />
              </Link>
            </OfferIcons>
          )}
          <OfferIcons
            id="triggerAcceptOfferHighlight"
            data-test-id="trigger-accept-offer-highlight"
            onClick={handleAcceptOffer}
            isOfferHighlight={isOfferHighlight}
          >
            <generalIcons.accept.highlight />
          </OfferIcons>
        </React.Fragment>
      );
    case 'suggestion_new':
      return (
        <React.Fragment>
          <OfferIcons
            id="triggerIgnoreOfferHighlight"
            data-test-id="trigger-ignore-offer-highlight"
            onClick={handleIgnoreOffer}
            isOfferHighlight={isOfferHighlight}
          >
            <generalIcons.ignore.highlight />
          </OfferIcons>
          {isOfferHighlight ? (
            <OfferIcons
              id="triggerCounterRequestHighlight"
              data-test-id="trigger-counter-request-highlight"
              onClick={handleSuggestOffer}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.suggest.highlight />
            </OfferIcons>
          ) : (
            <OfferIcons
              id="triggerCounterRequestHighlight"
              data-test-id="trigger-counter-request-highlight"
              isOfferHighlight={isOfferHighlight}
            >
              <Link to={`/profile/${accountHashId}?fromOfferList=true`}>
                {currentUserIsGenerous ? <generalIcons.offers.genOffer /> : <generalIcons.offers.request />}
              </Link>
            </OfferIcons>
          )}
        </React.Fragment>
      );
    case 'offer_pending':
      return (
        <React.Fragment>
          <OfferIcons
            id="triggerCancelOfferHighlight"
            data-test-id="trigger-cancel-offer-highlight"
            onClick={handleCancelOffer}
            isOfferHighlight={isOfferHighlight}
          >
            <generalIcons.ignore.cancel />
          </OfferIcons>
        </React.Fragment>
      );
    case 'suggestion_pending':
      return (
        <React.Fragment>
          <OfferIcons
            id="triggerCancelSuggestHighlight"
            data-test-id="trigger-cancel-suggest-highlight"
            onClick={handleCancelSuggestion}
            isOfferHighlight={isOfferHighlight}
          >
            <generalIcons.ignore.cancel />
          </OfferIcons>
        </React.Fragment>
      );

    default:
      return null;
  }
};

export default OfferActionButton;
