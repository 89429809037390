/**
 * @Author: zachalam
 * @Date:   2017-01-31T15:33:18-08:00
 * @Last modified by:   Daniel
 * @Last modified time: 2017-08-23T10:16:34-07:00
 */
/* globals google */
import {
  isBefore,
  distanceInWordsStrict,
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import UserConfig from './config/User';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import React from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import MasterConfig, { tomtomEnabled } from './config/Master';
import { convertPathToTitle, getBrowserDetails, ternaryFunc } from './utils/helpers';
import { extractLocationDetails } from './utils/tomtom';
import { IProfile } from './types/common';

const defaultTimeZone = 'America/Los_Angeles';

// as per API spec - use LAX time.
export const getDate = (date = null, timeZone = defaultTimeZone, shouldConvertToUtc = true): Date => {
  let formattedDate = '';

  if (date) {
    if (shouldConvertToUtc) {
      //  by default the expiry date is in UTC from the API
      const utcDateTime = new Date(date);

      // convert the UTC into Local DateTime
      const localDateTime = new Date(utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60000);
      formattedDate = format(localDateTime, 'YYYY/MM/DD HH:mm:ss');
    } else {
      formattedDate = format(date, 'YYYY/MM/DD HH:mm:ss');
    }
  } else {
    // check if not supported in mobile
    if (typeof formatToTimeZone === 'function') {
      formattedDate = formatToTimeZone(new Date(), 'YYYY/MM/DD HH:mm:ss', { timeZone });
    } else {
      formattedDate = new Date().toLocaleString('en-US', { timeZone, hour12: false });
    }
  }

  return new Date(new Date(formattedDate).toUTCString());
};

// returns description for timeStamps
export const stringDesc = (timestamp: any, hasDesc = false, shouldConvertToUtc = true): string => {
  // time stamp difference -> 30 minutes, 2 hours
  const distanceWords = distanceInWordsStrict(getDate(), getDate(timestamp, undefined, shouldConvertToUtc));
  // difference in Minutes=
  const minDiff = differenceInMinutes(getDate(), getDate(timestamp, undefined, shouldConvertToUtc));
  // difference in Hours
  const hrDiff = differenceInHours(getDate(), getDate(timestamp, undefined, shouldConvertToUtc));
  // difference in Days
  const dayDiff = differenceInDays(getDate(), getDate(timestamp, undefined, shouldConvertToUtc));
  // day of the week -> Mon, Tue, Wed..
  const dayOfWeek = format(timestamp, 'ddd');
  // Month Day -> Nov 20
  const monthDay = format(timestamp, 'MMM DD');
  // Trim minutes to mins
  const trimMins = distance => {
    const wordsArr = distance.split(' ');
    return `${wordsArr[0]} ${wordsArr[1].slice(0, 3)}${wordsArr[1].slice(6, 7)}`;
  };

  if (minDiff <= 5) {
    return 'Just Now';
  } else if (minDiff > 5 && minDiff < 60) {
    return hasDesc ? `${trimMins(distanceWords)} ago` : trimMins(distanceWords);
  } else if (minDiff >= 60 && hrDiff <= 24) {
    return hasDesc ? `${distanceWords} ago` : distanceWords;
  } else if (hrDiff > 24 && dayDiff <= 7) {
    return hasDesc ? `${dayDiff} days ago` : dayOfWeek;
  } else {
    return monthDay;
  }
};

/**
 * returns human readable "since" timestamp (ie: 1 min ago)
 *
 * @param timestamp
 * @param hasDesc boolean
 * @param shouldConvertToUtc boolean If the datetime should be converted to UTC
 * @returns string
 */
export const tsSince = (timestamp: any, hasDesc = false, shouldConvertToUtc = true): string => {
  if (!timestamp || timestamp === 0) {
    return 'Recently';
  } else if (
    isBefore(getDate(timestamp, undefined, shouldConvertToUtc), getDate(undefined, undefined, shouldConvertToUtc))
  ) {
    return hasDesc
      ? `Active ${stringDesc(timestamp, true, shouldConvertToUtc)}`
      : stringDesc(timestamp, false, shouldConvertToUtc);
  } else {
    return 'Just Now';
  }
};

export const tsCustomFormat = (timestamp: any, formatter = 'MMM DD'): any => {
  return format(getDate(timestamp), formatter);
};

export const tsNow = (): any => {
  return getDate().valueOf();
};

export const getGa = (): any => {
  const ga = ReactGA.ga();
  if (ga !== undefined && typeof ga.getAll === 'function') {
    const gaGetAll = ga.getAll()[0];
    return gaGetAll !== undefined ? gaGetAll.get('clientId') : null;
  }

  return null;
};

export const toaster = {
  warn: (msg: any, link = null): any => {
    if (link) toast.warn(React.createElement('a', { href: link }, msg));
    else toast.warn(msg);
  },
  error: (msg: any): any => {
    toast.error(msg);
  },
  success: (msg: any): any => {
    toast(msg, { type: toast.TYPE.INFO });
  },
  dismissAll: (): any => {
    toast.dismiss();
  },
};

/**
 * Plucks the given address components
 *
 * @param {Object} location
 * @param {String|Array} locationType
 *
 * @returns {String|Array}
 */
export const findAddressComponent = (location: any, locationType: string | string[]): string | string[] | undefined => {
  const { address_components } = location;

  if (address_components === undefined) {
    throw new Error('Address Components is not found');
  }

  // if string, pluck it from the address_components
  if (typeof locationType === 'string') {
    return address_components.find(address => address.types.indexOf(String(locationType)) > -1);
  }

  // if location type is array, return all matches and filter all undefined
  if (Array.isArray(locationType)) {
    return locationType
      .map(type => {
        return address_components.find(address => address.types.indexOf(type) > -1);
      })
      .filter(val => val !== undefined);
  }
};

/**
 * Cleans up city,region to avoid duplicates
 *
 * @returns {string}
 */
export const normalizeLocationDisplay = (city: any, region: any, country: any): string => {
  if (tomtomEnabled && (city === country || region === country)) {
    return `${country}`;
  }

  const location = [city, region]
    .filter(function(value, index, array) {
      return index === array.indexOf(value);
    })
    .join(', ');

  return `${location}, ${country}`;
};

export const isLocationContainsGoogleComponents = (location: any): boolean => {
  try {
    findAddressComponent(location, ['locality', 'administrative_area_level_3', 'administrative_area_level_2']);

    findAddressComponent(location, 'country');

    findAddressComponent(location, 'country');

    return true;
  } catch (e) {
    return false;
  }
};

export const extractLocationData = (location: any): any => {
  if (MasterConfig.tomtomEnabled && isLocationContainsGoogleComponents(location) === false) {
    try {
      return extractLocationDetails(location);
    } catch (e) {
      Sentry.captureException(e, {
        tags: { function: 'extractLocationDetails', message: 'Cannot extract location details' },
      });
    }
  }

  // extracts google map data for data expected by API.
  // city => locality
  // region => administrative_area_level_1
  // country => country
  let city, region, country, latitude, longitude;

  try {
    city = findAddressComponent(location, ['locality', 'administrative_area_level_3', 'administrative_area_level_2']);

    city = (city && city.length > 0 && city[0].long_name) || '';

    // occasionally administrative_area_level_1 will not contain data, if that's the case use level_2/political,
    // otherwise we do not have any data to send back for the region.
    region = findAddressComponent(location, [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'political',
    ]);

    region = (region && region.length > 0 && region[0].long_name) || '';

    country = findAddressComponent(location, 'country');
    country = (country && country.long_name) || '';

    latitude = location.geometry.location.lat();
    longitude = location.geometry.location.lng();
  } catch (e) {
    // could not find proper location data.
    region = '';
    city = '';
    country = '';
    latitude = '';
    longitude = '';
  }

  return {
    city,
    region,
    country,
    latitude,
    longitude,
  };
};

export const cleanObject = (myObj: any): any => {
  Object.keys(myObj).forEach(key => myObj[key] === '' && delete myObj[key]);
  return myObj;
};

export const stripHeightMaxes = (dataObj: any): any => {
  if (dataObj.height_min === UserConfig.minimumHeight && dataObj.height_max === UserConfig.maximumHeight) {
    // delete these items from dataObj.
    delete dataObj.height_min;
    delete dataObj.height_max;
  }

  return dataObj;
};

export const metricConv = (n: any): any => {
  const realFeet = (n * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + '"';
};

const getGeocodeData = (resolve: any, reject: any, payloadData: any): any => {
  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;
  geocoder.geocode(payloadData, (results: any, status: any) => {
    if (status !== OK) {
      reject(status);
    }

    resolve(results);
  });
};

export const geocodeByAddress = (address: any): any => {
  return new Promise((resolve, reject) => {
    getGeocodeData(resolve, reject, { address });
  });
};

export const geocodeByPlaceId = (placeId: any): any => {
  return new Promise((resolve, reject) => {
    getGeocodeData(resolve, reject, { placeId });
  });
};

/**
 * Function which takes in a user Location object and an otherUser Location object
 * returns a string based on whether the countries are equal or not.
 * @param {Object} user The logged in user
 * @param {Object} otherUser The user to Compare too
 * @param {Boolean} cityOnly Shows city only
 * Object in the form of {country: "String", city: "String", region: "String"}
 */
export const displayUserLocation = (user: any, otherUser: any, cityOnly = false): string => {
  if (!user || !otherUser) return '';

  if (user.country === otherUser.country) {
    return cityOnly ? `${otherUser.city}` : `${otherUser.city}${otherUser.region ? `, ${otherUser.region}` : ''}`;
  } else {
    return cityOnly ? `${otherUser.city}` : `${otherUser.city}${otherUser.country ? `, ${otherUser.country}` : ''}`;
  }
};

export const pluralize = (count: number, singular: string, plural: string): any => (count === 1 ? singular : plural);

export const numberWithCommas = (x: any): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Get member type
 *
 * Member types legend:
 * GM - Generous Male
 * AF - Attractive Female
 * B - Both
 * <?> - Unknown
 * ---------
 * 0 - GM4<?> or AF4<?>
 * 1 - GM4F or GM4B
 * 2 - AF4M or AF4B
 * 3 - Default
 *
 * e.g. GM4F -> Generous Male looking for Female
 *
 * @param {object}
 */
export const getMemberType = (profile: any): number => {
  if (!profile || !profile.account_type || !profile.gender) {
    return 3;
  }

  const { account_type, gender, looking_male, looking_female } = profile;
  const lookingBoth = looking_male && looking_female;

  if (!looking_male && !looking_female) {
    return 0;
  }

  if (account_type === 'Generous' && gender === 'Male' && (looking_female || lookingBoth)) {
    return 1;
  }

  if (account_type === 'Attractive' && gender === 'Female' && (looking_male || lookingBoth)) {
    return 2;
  }

  return 3;
};

export const getBlacklistedCountries = (): string[] => {
  return [
    'BI',
    'BY',
    'MM',
    'CI',
    'CF',
    'CU',
    'CG',
    'CD',
    'IR',
    'IQ',
    'LB',
    'RU',
    'LR',
    'LY',
    'ML',
    'NI',
    'KP',
    'SO',
    'SS',
    'SD',
    'SY',
    'YE',
    'UA',
    'VE',
    'ZW',
  ];
};

export const getPriorityCountries = (): string[] => {
  return ['US', 'GB', 'CA', 'AU'];
};

export const getLocalItem = (key: string): any => {
  if (!('localStorage' in window) || !window.localStorage) {
    return null;
  }

  return window.localStorage.getItem(key);
};

export const removeLocalItem = (key: string): any => {
  if (!('localStorage' in window) || !window.localStorage) {
    return null;
  }

  return window.localStorage.removeItem(key);
};

export const setLocalItem = (key: string, value: any): any => {
  if (!('localStorage' in window) || !window.localStorage) {
    return null;
  }

  return window.localStorage.setItem(key, value);
};

export const browserHistory = createBrowserHistory({ window });

export const updatePageTitle = (notificationCount = null): void => {
  const pathTitle = convertPathToTitle(window.location.pathname);
  let pageTitle = `${MasterConfig.SITE_NAME} | ${pathTitle || 'Bid on first dates'}`;

  if (notificationCount > 0) {
    pageTitle = `(${notificationCount}) ` + pageTitle;
  }

  document.title = pageTitle;
};

export const imageOrientation = (src: any): string | null => {
  let orientation = <string | null>null;
  const img = new Image();
  img.src = src;

  if (img.naturalWidth > img.naturalHeight) {
    orientation = 'landscape';
  } else if (img.naturalWidth < img.naturalHeight) {
    orientation = 'portrait';
  } else {
    orientation = 'even';
  }

  return orientation;
};

const mobileRetina = (isRetina: any, photoUrls: any, forMobile: any): any => {
  const { isMobile } = getBrowserDetails();
  const mobile = forMobile ? isMobile : true;

  if (isRetina() && mobile && photoUrls['820px']) {
    if (imageOrientation(photoUrls['820px']) === 'landscape') {
      return photoUrls['profile'];
    }
    return photoUrls['820px'];
  }
};

const setMobilePhotoUrl = (photoUrls: any, prop: any): any => {
  if (photoUrls[prop]) {
    return photoUrls[prop];
  }
};

export const renderAvatar = (photoUrls: any, isThumbs = false): any => {
  const isRetina = () => {
    const mediaQuery = `(-webkit-min-device-pixel-ratio: 1.5),\
              (min--moz-device-pixel-ratio: 1.5),\
              (-o-min-device-pixel-ratio: 3/2),\
              (min-resolution: 1.5dppx)`;

    if (window.devicePixelRatio > 1) {
      return true;
    }

    return window.matchMedia && window.matchMedia(mediaQuery).matches;
  };

  /**
   * https://jira.infostreamgroup.com/browse/WYP-7180
   *
   * If the photoUrls are all defaulting to "upload_photo", pick up the resized photo since it is
   * the proper photo for default picture.
   */

  if (isThumbs && photoUrls['profile']) {
    return photoUrls['profile'];
  }

  const shouldDisplayDefaultPicture =
    photoUrls &&
    Object.keys(photoUrls).every(value => {
      return photoUrls[value].includes('upload_photo_');
    });

  if (shouldDisplayDefaultPicture && photoUrls['resized']) {
    return photoUrls['resized'];
  }

  if (window.innerWidth <= 600) {
    /* https://github.com/frontend-collective/react-image-lightbox/issues/188
    known issue for iOS Safari not fully rendering with larger images,
    Tried with 720px as well but issue still persist. 410px is too small for retina.
    Best option for now as tested is to use resized images */

    const mobileAndRetina = mobileRetina(isRetina, photoUrls, true);
    if (mobileAndRetina) return mobileAndRetina;

    const set410px = setMobilePhotoUrl(photoUrls, '410px');
    if (set410px) return set410px;
  }

  return ternaryFunc(
    setMobilePhotoUrl(photoUrls, '820px'),
    setMobilePhotoUrl(photoUrls, '820px'),
    ternaryFunc(
      setMobilePhotoUrl(photoUrls, '720px'),
      setMobilePhotoUrl(photoUrls, '720px'),
      ternaryFunc(
        setMobilePhotoUrl(photoUrls, 'resized'),
        setMobilePhotoUrl(photoUrls, 'resized'),
        ternaryFunc(
          mobileRetina(() => true, photoUrls, false),
          mobileRetina(() => true, photoUrls, false),
          photoUrls['profile']
        )
      )
    )
  );
};

export const getCurrentUTCTime = (): number => Math.round(new Date().getTime() / 1000);

export const getOfferType = (offer: any, profile: any): string => {
  if (!offer) {
    return 'initial';
  }

  let offerType = 'offer';
  let offerStatus = offer.status;

  if (offer.suggest) {
    offerType = 'suggestion';
  }

  // this fixes an old cancelled offer page issue when user is refunded WYP-8262
  if (offerStatus === 'refunded') {
    offerStatus = 'rejected';
  }

  if (offer.to_account_hash_id === profile.hash_id && offerStatus !== 'accepted' && offerStatus !== 'rejected') {
    offerStatus = 'new';
  }

  if (offerStatus === 'rejected') {
    offerStatus = 'rejected';
  }

  return `${offerType}_${offerStatus}`;
};

export const getFileSizeDelay = (fileSize: number): any => {
  return fileSize > 10000000
    ? 11000
    : ternaryFunc(fileSize > 5000000, 8500, ternaryFunc(fileSize > 3000000, 6000, 4000));
};

export const shouldShowHeignInFeet = (country: any): boolean => {
  const countries = ['us', 'united states'];

  if (!country) {
    return false;
  }

  return countries.includes(country.toLocaleLowerCase());
};

export const canGenerousUserMakeOffer = (profile: IProfile): boolean => {
  const isGenerous = profile.profile.data.account_type.toLowerCase() === 'generous';
  return isGenerous && !profile.can_make_offer;
};
