/**
 * @Author: zachalam
 * @Date:   2017-03-21T14:27:30-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-21T14:27:30-07:00
 */

import * as sdk from '../sdk';
import { storeMeta } from './metaActions';
import MasterConfig from '../config/Master';

export const clearSearch = (): any => {
  return {
    type: 'CLEAR_SEARCH',
  };
};

export const saveFilters = (payload: any): any => {
  return {
    type: 'SAVE_FILTERS',
    payload,
  };
};

export const resetFilters = (): any => {
  return {
    type: 'RESET_FILTERS',
  };
};

export const resetIndividualFilter = (payload: any): any => {
  // resets individual filters.
  return {
    type: 'RESET_INDIVIDUAL_FILTER',
    payload,
  };
};

export const storeProfileSearch = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_SEARCH',
    payload,
  };
};

export const storeProfileResultCount = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_RESULT_COUNT',
    payload,
  };
};

export const requestSearch = (): any => {
  return {
    type: 'REQUEST_SEARCH',
  };
};

export const failedSearch = (): any => {
  return {
    type: 'REQUEST_SEARCH_FAILED',
  };
};

export const storeLastSelectedSearchResult = (payload: any): any => {
  return {
    type: 'STORE_LAST_SELECTED_SEARCH_RESULT',
    payload,
  };
};

export const storePageCacheId = (payload: any): any => {
  return {
    type: 'STORE_PGID',
    payload,
  };
};

export const profileSearch = (
  formData = null,
  fetchFirst = true,
  refTimestamp = true,
  trackMixpanel = () => undefined
): any => {
  return async (dispatch: any, getState: any) => {
    const { profile, search } = getState();

    let pgid = search.pgid;

    if (pgid === null || typeof pgid === 'undefined') {
      const time = new Date().getTime();
      pgid = `${profile.hash_id}.${time}`;
      dispatch(storePageCacheId({ pgid }));
    }

    dispatch(requestSearch());
    return sdk
      .profileSearch(formData, fetchFirst, refTimestamp, pgid)
      .then(results => {
        const { data, meta } = MasterConfig.searchMicroserviceEnabled ? results.data.data : results.data;
        trackMixpanel({ data, meta });
        dispatch(storeProfileSearch(data));
        // save last search if microservice is enable
        if (MasterConfig.searchMicroserviceEnabled) {
          sdk.saveLastSearch(formData, fetchFirst);
        }

        // store pagination
        const page = meta ? meta.pagination : 1;
        const timestamp = meta ? meta.request.datetime : 0;
        // store number of results
        if (!page.max_page_limit) {
          dispatch(storeProfileResultCount(page.total));
        }

        // store pagination details.
        const metaValues = {
          search_current_page: page.current_page,
          search_total_pages: page.total_pages,
          search_last_time: timestamp,
          search_max_limit: page.max_page_limit,
          search_timestamp: refTimestamp ? meta && meta.search_timestamp : 0,
        };

        const newMetaValues =
          MasterConfig.searchMicroserviceBoost && meta && meta.featured_profiles && meta.featured_profiles.data
            ? {
                ...metaValues,
                search_featured_profiles: meta.featured_profiles.data,
              }
            : metaValues;

        dispatch(storeMeta(newMetaValues));
      })
      .catch(e => {
        console.error('Failed Search', e);
        dispatch(failedSearch());
      });
  };
};

export const preserveResults = (): any => {
  return (dispatch: any): any => {
    dispatch({
      type: 'SEARCH_PRESERVE_RESULT',
    });
  };
};

export const loadPreservedResults = (): any => {
  return (dispatch: any): any => {
    dispatch({
      type: 'SEARCH_LOAD_PRESERVED_RESULT',
    });
  };
};
