import { VYSION_ACTIONS } from '..';
import { EventData, handleVysionEvent } from '../vysionService';
import { phoneSettingsClickVysionEvents } from './click';
import { phoneSettingsOnFocusVysionEvents } from './onFocus';
import { phoneSettingsViewVysionEvents } from './view';

export const events = {
  [VYSION_ACTIONS.ON_FOCUS]: { ...phoneSettingsOnFocusVysionEvents },
  [VYSION_ACTIONS.CLICK]: { ...phoneSettingsClickVysionEvents },
  [VYSION_ACTIONS.VIEW]: { ...phoneSettingsViewVysionEvents },
};

export const handlePhoneSettingsVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
