import React, { PureComponent, ReactElement, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import EmailInput from '../atoms/EmailInput';
import TextInput from '../atoms/TextInput';
import Button from '../atoms/buttons/Button';

const Email = styled('div')({
  fontSize: '16px',
});

const Description = styled('p')({
  fontStyle: 'italic',
  margin: '14px 0',
  fontSize: '12px',
});

class ChangeEmailAndPasswordForm extends PureComponent {
  handleOnSubmit = (e: ChangeEvent): void => {
    e.preventDefault();
    this.props.onSubmit();
  };
  render(): ReactElement {
    const { currentEmail, currentPassword, newEmail, newPassword, onChange, disableSubmit, errors } = this.props;

    return (
      <form onSubmit={this.handleOnSubmit} style={{ minHeight: '50vh' }}>
        <Email>
          <b>Current email:</b> {currentEmail}
        </Email>
        <Description>Enter either a new email, new password, or both.</Description>

        <TextInput
          id={'current_password'}
          type="password"
          showPassword={true}
          inputLabel="Current Password"
          value={currentPassword}
          onChange={onChange}
          autoFocus={true}
          status={errors.current_password && 'error'}
          statusMessage={errors.current_password && errors.current_password[0]}
          customStyle={{ maxWidth: '452px' }}
        />

        <EmailInput
          id={'new_email'}
          inputLabel={'New Email?'}
          value={newEmail}
          onChange={onChange}
          status={errors.email && 'error'}
          statusMessage={errors.email && errors.email[0]}
        />

        <TextInput
          id={'password'}
          type="password"
          showPassword={true}
          inputLabel="New Password?"
          value={newPassword}
          onChange={onChange}
          status={errors.password && 'error'}
          statusMessage={errors.password && errors.password[0]}
          customStyle={{ maxWidth: '452px' }}
        />

        <Button buttonType="primary" type="submit" disabled={disableSubmit}>
          Update
        </Button>
      </form>
    );
  }
}

ChangeEmailAndPasswordForm.propTypes = {
  currentEmail: PropTypes.string.isRequired,
  newEmail: PropTypes.string,
  newPassword: PropTypes.string,
  currentPassword: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  formError: PropTypes.any,
};

export default ChangeEmailAndPasswordForm;
