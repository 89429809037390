import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { resendEmail } from '../../actions/authActions';
import AlertIcon from '../../atoms/icons/AlertIcon';
import { Wrapper, HeaderWrapper, Title } from './styledElements';
import EmailProfileIncompleteStep from './firstStep/EmailProfileIncompleteStep';
import EmailVerifiedIncompleteProfileStep from './firstStep/EmailVerifiedIncompleteProfileStep';
import ProfileVerifiedEmailIncompleteStep from './firstStep/ProfileVerifiedEmailIncompleteStep';
import JoinSkipConfirmation from '../join/JoinSkipConfirmation';
import { useApp } from '../../contexts/AppContext';
import useModal from '../../hooks/useModal';
import useNav from '../../hooks/useNav';
import { DashlineSeparator } from '../join/styledElements';
import { getBrowserDetails } from '../../utils/helpers';
import EmailConfirmV2 from '../../modules/modals/EmailConfirmV2';
import { handleProfileCompletionVysionEvent } from '../../utils/vysion/profileCompletion';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { ProfileCompletionClickVysionEventsEnum } from '../../utils/vysion/profileCompletion/click';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';

const FirstStepCompleteProfile = ({
  emailConfirmed,
  profileDetailsCompleted,
  profile,
  resendReminderEmail,
}): ReactElement => {
  const { setProfileCompletionStep, setModalState, profileCompletionCallbackAfterValidate } = useApp();
  const { goTo } = useNav();
  const { resetModal } = useModal();
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { isMobile } = getBrowserDetails();
  const location = useLocation();

  useDisableBodyScroll(openStatus);

  const onSkip = () => {
    setProfileCompletionStep(2);
    setShowAgreementModal(false);
    profileCompletionCallbackAfterValidate();
    resetModal();
    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_SKIP_FOR_NOW,
      },
      location.pathname
    );
  };

  const handleModalOverlay = state => {
    setModalState(prev => {
      return {
        ...prev,
        customOverlayStyle: state,
      };
    });
  };

  const handleOnClickSkip = () => {
    handleModalOverlay({ display: 'none' });
    setShowAgreementModal(true);
  };

  const handleResendEmail = async () => {
    setIsSending(true);

    await resendReminderEmail();
    setProfileCompletionStep(2);
    profileCompletionCallbackAfterValidate();
    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_RESEND_EMAIL,
      },
      location.pathname
    );
    setOpenStatus(true);
    setIsSending(false);
    handleModalOverlay({ display: 'none' });
    profileCompletionCallbackAfterValidate();
  };

  const handleChangeEmail = () => {
    setProfileCompletionStep(2);
    profileCompletionCallbackAfterValidate();
    resetModal();
    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_CHANGE_EMAIL_ADDRESS,
      },
      location.pathname
    );
    goTo('/settings/email-password');
  };

  const handleTrackMixpanelStep2Shown = () => {
    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_FINISH_MY_PROFILE,
      },
      location.pathname
    );
  };

  const renderEmailResend = (variant: string) => {
    const email = profile && profile.email;
    return (
      <EmailConfirmV2
        email={email}
        openStatus={openStatus}
        handleOnClick={handleResendEmail}
        handleToggleOpenStatus={() => setOpenStatus(false)}
        variant={variant}
        isSending={isSending}
      />
    );
  };

  return (
    <Wrapper>
      <HeaderWrapper style={{ marginTop: 10 }}>
        <AlertIcon />
        <Title>Hold up! You need a complete profile first.</Title>
      </HeaderWrapper>
      <div style={{ paddingLeft: isMobile ? 'unset' : 65, paddingRight: isMobile ? 'unset' : 65 }}>
        <DashlineSeparator />
        {!emailConfirmed && !profileDetailsCompleted && (
          <EmailProfileIncompleteStep
            setSkip={handleOnClickSkip}
            handleChangeEmail={handleChangeEmail}
            handleMixpanel={handleTrackMixpanelStep2Shown}
          >
            {renderEmailResend('variant1')}
          </EmailProfileIncompleteStep>
        )}
        {emailConfirmed && !profileDetailsCompleted && (
          <EmailVerifiedIncompleteProfileStep
            setSkip={handleOnClickSkip}
            handleMixpanel={handleTrackMixpanelStep2Shown}
          />
        )}
        {!emailConfirmed && profileDetailsCompleted && (
          <ProfileVerifiedEmailIncompleteStep setSkip={handleOnClickSkip} handleChangeEmail={handleChangeEmail}>
            {renderEmailResend('variant2')}
          </ProfileVerifiedEmailIncompleteStep>
        )}
        <JoinSkipConfirmation
          isOpen={showAgreementModal}
          alwaysDisabledBodyScroll
          onGoBack={() => {
            setShowAgreementModal(false);
            handleModalOverlay({ display: 'block' });
          }}
          onClose={() => {
            setShowAgreementModal(false);
            handleModalOverlay({ display: 'block' });
          }}
          onSkip={onSkip}
        />
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({ profile }) => {
  const profileCompletion = profile.mandatory_profile_status;
  return {
    profile,
    emailConfirmed: profileCompletion.profile_email_confirmed,
    profileDetailsCompleted: profileCompletion.profile_details_completed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resendReminderEmail() {
      return dispatch(resendEmail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstStepCompleteProfile);
