import React, { useState, useEffect } from 'react';
import Packages from '../screens/billing/Packages';
import { connect } from 'react-redux';
import { getIDVPackages, getKountDetails, postVisitLogs } from '../actions/billingActions';
import { clearErrors } from '../actions/commonActions';

import KountLogo from '../atoms/billing/KountLogo';
import queryString from 'query-string';
import { getBrowserDetails } from '../utils/helpers';
import { useComponentWillMount } from '../utils/customHooks';
import Loading from '../atoms/Loading';
import styled from '@emotion/styled';
import { textType } from '../style';
import { useLocation } from 'react-router-dom';

const LoadingPackagesHeader = styled('h3')({
  ...textType.processingHeader,
});

const stepLayout = (location, setShowPayment, setPromoCode) => {
  const queryStringParsed = queryString.parse(location.search);
  const payment = queryStringParsed.step === 'payment';
  const myPromoCode = queryStringParsed.promo_code || null;

  setShowPayment(payment);
  setPromoCode(myPromoCode);
};

const BillingPackagesContainer = props => {
  const [processingPayment, setProcessingPayment] = useState(false);
  const [timestampIdentifier, setMyTimestampIdentifier] = useState('');
  const [isStackedVariant, setIsStackedVariant] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [isVisitLogged, setIsVisitLogged] = useState(false);

  const location = useLocation();
  const { logPageVisit, paymentToken, errors, packages, kountDetails } = props;

  const defaultPackageId = 'idv_last_minute';

  useComponentWillMount(() => {
    props.getBillingPackages();
    props.getKountDetails();
    props.clearErrors();
  });

  useEffect(() => {
    setTimeStampIdentifier();
    setVariantLayout();
    stepLayout(location, setShowPayment, setPromoCode);
  }, [location]);

  useEffect(() => {
    if (!isVisitLogged) {
      setTimeout(() => {
        logPageVisit('packages', 1);
        setIsVisitLogged(true);
      }, 500);
    }
  }, [isVisitLogged, logPageVisit]);

  useEffect(() => {
    const savedCard = (paymentToken && paymentToken[0]) || null;
    const newCard = document.getElementById('new_card');

    if (processingPayment && errors && typeof errors.data !== 'undefined') {
      setProcessingPayment(false);

      if (savedCard && newCard !== null) {
        newCard.click();
      }
    }
  }, [errors, paymentToken, processingPayment]);

  const setVariantLayout = () => {
    const stackedVariant = getBrowserDetails().isMobile;
    setIsStackedVariant(stackedVariant);
  };

  const setTimeStampIdentifier = () => {
    const dateTime = Date.now();
    const timeIdentifier = Math.floor(dateTime / 1000);
    setMyTimestampIdentifier(timeIdentifier);
  };

  if (typeof packages !== 'object') {
    return false;
  }

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: 'center',
          margin: `${getBrowserDetails().isMobile ? '50%' : '180px'} auto 0`,
          maxWidth: 350,
        }}
      >
        <Loading width={80} />
        <LoadingPackagesHeader>Loading Package Payment...</LoadingPackagesHeader>
      </div>
      <div style={{ visibility: 'hidden' }}>
        <Packages
          packages={packages}
          stackedVariant={isStackedVariant}
          defaultPackageId={defaultPackageId}
          showPayment={showPayment}
          promoCode={promoCode}
          {...props}
        />
      </div>
      <KountLogo details={kountDetails} timestamp={timestampIdentifier} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    packages: state.billing.idv_packages,
    kountDetails: state.billing.kountDetails,
    loading: state.common.isFetching,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBillingPackages: () => {
      dispatch(getIDVPackages());
    },
    getKountDetails: () => {
      dispatch(getKountDetails());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    logPageVisit: (type, step) => {
      dispatch(postVisitLogs(type, step));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPackagesContainer);
