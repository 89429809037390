import React, { ReactElement } from 'react';

const ProfilePercentDot = (): ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="7.5" fill="#95C7EB" stroke="#2B8FD7" strokeWidth="5" />
    </svg>
  );
};

export default ProfilePercentDot;
