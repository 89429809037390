/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, ReactElement } from 'react';
import { dimensions, shadowTheme } from '../../../style';
import Container from '../Container';
import FooterNavLink from './FooterNavLink';
import { navbarIcons } from '../../../atoms/icons/materialIcons';
import styled from '@emotion/styled';
import FooterWYPLogo from './FooterWYPLogo';
import { getBrowserDetails, iOSwithHomeBtn, ipadNoHomeButton, tabletWidth } from '../../../utils/helpers';
import {
  sendDashboardNavbarSearch,
  sendDashboardNavbarInterests,
  sendDashboardNavbarOffers,
  sendDashboardNavbarFavourites,
} from '../../../actions/dashboardTrackAction';
import { triggerTracking } from '../../../utils/helpers';
import { useApp } from '../../../contexts/AppContext';

const containerStyles = {
  display: 'flex',
  padding: '0 0',
  [dimensions.SCREEN_MAX_SM]: { justifyContent: 'space-evenly' },
};

const heightLength = homeBtn => (homeBtn ? 60 : 56);
const bottomLength = homeBtn => (homeBtn ? 25 : 0);

const SiteFooter = styled('div')(({ isDarkMode }) => ({
  boxShadow: shadowTheme.footer,
  backgroundColor: isDarkMode ? '#041a2a' : '#ffffff',
  verticalAlign: 'middle',
  height:
    getBrowserDetails().isIos && !tabletWidth && getBrowserDetails().isStandaloneMode
      ? heightLength(!iOSwithHomeBtn())
      : heightLength(ipadNoHomeButton),
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 995,
  paddingBottom:
    getBrowserDetails().isIos && !tabletWidth && getBrowserDetails().isStandaloneMode
      ? bottomLength(!iOSwithHomeBtn())
      : bottomLength(ipadNoHomeButton),
}));

const NavBar = styled('div')`
  padding-top: 60px;
`;

const renderIcon = (isDarkMode, icon, status) => {
  switch (status) {
    case 'default':
      return isDarkMode ? icon.dark : icon.default;
    case 'active':
      return isDarkMode ? icon.darkActive : icon.active;
    default:
      return;
  }
};

const setCorrectPageAction = (e, page) => {
  switch (page) {
    case 'Search':
      sendDashboardNavbarSearch(e);
      break;
    case 'Favourites':
      sendDashboardNavbarFavourites(e);
      break;
    case 'Offers':
      sendDashboardNavbarOffers(e);
      break;
    case 'Interests':
      sendDashboardNavbarInterests(e);
      break;
    default:
      return;
  }
};

interface FooterProps {
  newFavorites: any;
  newOffers: any;
  messages: any;
  pathname: any;
  isDarkMode: any;
  requiredVerification: any;
}

const Footer = ({
  newFavorites,
  newOffers,
  messages,
  pathname,
  isDarkMode,
  requiredVerification,
}: FooterProps): ReactElement => {
  const noNavPages =
    pathname.indexOf('/payment') > -1 || pathname.indexOf('/packages') > -1 || pathname.indexOf('/billing') > -1;

  const visitSearch = useRef(null);
  const visitFavourites = useRef(null);
  const visitOffers = useRef(null);
  const visitInterests = useRef(null);
  const { siteFooterRef } = useApp();

  useEffect(() => {
    triggerTracking('Search', visitSearch, setCorrectPageAction);
    triggerTracking('Interests', visitFavourites, setCorrectPageAction);
    triggerTracking('Offers', visitOffers, setCorrectPageAction);
    triggerTracking('Interests', visitInterests, setCorrectPageAction);
  }, []);

  if (!requiredVerification && noNavPages) return null;

  return (
    <NavBar>
      <SiteFooter id="site-footer" isDarkMode={isDarkMode} ref={siteFooterRef}>
        <FooterWYPLogo currentPath={pathname} activePathPattern={/.*dashboard/} />
        <Container fullWidth css={containerStyles}>
          <FooterNavLink
            footerRef={visitSearch}
            to="/search"
            currentPath={pathname}
            activePathPattern={/.*search/}
            iconComponent={isDarkMode ? navbarIcons.search.dark : navbarIcons.search.default}
            activeIconComponent={isDarkMode ? navbarIcons.search.darkActive : navbarIcons.search.active}
            isDarkMode={isDarkMode}
          />
          <FooterNavLink
            footerRef={visitFavourites}
            to={'/interests'}
            currentPath={pathname}
            activePathPattern={/.*interests/}
            iconComponent={renderIcon(isDarkMode, navbarIcons.interests, 'default')}
            activeIconComponent={renderIcon(isDarkMode, navbarIcons.interests, 'active')}
            notification={newFavorites}
            isDarkMode={isDarkMode}
          />
          <div style={{ padding: 38, flexGrow: 1 }}></div>
          <FooterNavLink
            footerRef={visitOffers}
            to="/offers/new"
            currentPath={pathname}
            activePathPattern={/^.offers/}
            iconComponent={isDarkMode ? navbarIcons.offers.dark : navbarIcons.offers.default}
            activeIconComponent={isDarkMode ? navbarIcons.offers.darkActive : navbarIcons.offers.active}
            notification={newOffers}
            isDarkMode={isDarkMode}
          />
          <FooterNavLink
            footerRef={visitInterests}
            to="/mail"
            currentPath={pathname}
            activePathPattern={/^.mail$/}
            iconComponent={isDarkMode ? navbarIcons.mail.dark : navbarIcons.mail.default}
            activeIconComponent={isDarkMode ? navbarIcons.mail.darkActive : navbarIcons.mail.active}
            notification={messages}
            isDarkMode={isDarkMode}
          />
        </Container>
      </SiteFooter>
    </NavBar>
  );
};

export default Footer;
