import React, { Fragment, useState, ReactElement } from 'react';
import Button from '../../../atoms/buttons/Button';
import OtpFormInput from '../OtpFormInput';
import TextInput from '../../../atoms/TextInput';
import { FormWrapper, DashlineSeparator, Heading1, ButtonAction, Description } from './styledElements';
import RequestOtpLinkAction from '../RequestOtpLinkAction';
import { PHONE_VERIFICATION_TYPES } from '../../../constants/phone';
import usePhoneActionModal from '../../../hooks/usePhoneActionModal';
import { handlePhoneSettingsVysionEvent } from '../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PhoneSettingsOnFocusVysionEventsEnum } from '../../../utils/vysion/phoneSettings/onFocus';
import { PhoneSettingsClickVysionEventsEnum } from '../../../utils/vysion/phoneSettings/click';
import { isDarkMode } from '../../../utils/helpers';

interface IVerificationFormProps {
  type: PHONE_VERIFICATION_TYPES;
  onSuccess?: (setIsProcessing?: any) => void;
  onCancel?: () => void;
  currentPhone: string;
  hashId: string;
  hasUpdatedPhone?: boolean;
}

const VerificationForm = ({
  type,
  onSuccess,
  onCancel,
  currentPhone,
  hashId,
  hasUpdatedPhone = false,
}: IVerificationFormProps): ReactElement => {
  const [password, setPassword] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorPassword, setErrorPassword] = useState([]);

  const {
    utils: { requestVerifyPassword },
  } = usePhoneActionModal();

  const handleSubmitPassword = () => {
    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_PASSWORD,
    });

    setIsVerifying(true);
    requestVerifyPassword({
      password,
      onVerifySuccess: onSuccess,
      onVerifyFailed: error => {
        if (error.response.data?.password) {
          setErrorPassword(error.response.data?.password);
        }
      },
      onVerifyCompleted: () => {
        setIsVerifying(false);
      },
    });
  };

  const handleCancelVerification = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }

    if (type === PHONE_VERIFICATION_TYPES.PASSWORD) {
      handlePhoneSettingsVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_PASSWORD_CANCEL,
      });
    } else {
      if (hasUpdatedPhone) {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_CANCEL_VERIFICATION,
        });
      } else {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_CANCEL_VERIFICATION,
        });
      }
    }
  };

  const handleVysionOnfocus = () => {
    if (hasUpdatedPhone) {
      handlePhoneSettingsVysionEvent({
        action: VYSION_ACTIONS.ON_FOCUS,
        event: PhoneSettingsOnFocusVysionEventsEnum.UPDATE_ENTER_NUMBER_VERIFICATION_CODE,
      });
    } else {
      handlePhoneSettingsVysionEvent({
        action: VYSION_ACTIONS.ON_FOCUS,
        event: PhoneSettingsOnFocusVysionEventsEnum.CURRENT_NUMBER_VERIFICATION_ENTER_CODE,
      });
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <FormWrapper>
      {type === PHONE_VERIFICATION_TYPES.OTP ? (
        <Fragment>
          <Heading1>Enter Verification Code</Heading1>
          <Description>We sent a 6-digit code to the current number above.</Description>
          <OtpFormInput
            phoneValue={currentPhone}
            onSuccess={onSuccess}
            handleOnFocus={handleVysionOnfocus}
            hasUpdatedPhone={hasUpdatedPhone}
          >
            <ButtonAction>
              <Button data-test-id="phone-settings-button" buttonType="primary" type="submit" size="small">
                Verify
              </Button>
            </ButtonAction>
          </OtpFormInput>
        </Fragment>
      ) : (
        <Fragment>
          <Heading1>Enter Password</Heading1>
          <TextInput
            id={'login_password'}
            type="password"
            showPassword={true}
            inputLabel="Enter your WYP account password"
            value={password}
            onChange={onInputChange}
            status={errorPassword.length > 0 ? 'error' : 'default'}
            customStyle={{ marginRight: 0 }}
            customInputStyle={{ borderColor: 'rgba(0, 0, 0, 0.15) !important', marginRight: 0 }}
            statusMessage={errorPassword.length > 0 && errorPassword[0]}
            onFocus={() => {
              setErrorPassword([]);
              handlePhoneSettingsVysionEvent({
                action: VYSION_ACTIONS.ON_FOCUS,
                event: PhoneSettingsOnFocusVysionEventsEnum.ENTER_PASSWORD,
              });
            }}
          />
          <ButtonAction>
            <Button
              data-test-id="phone-settings-button"
              customStyle={{}}
              buttonType="primary"
              onClick={handleSubmitPassword}
              disabled={password === '' || isVerifying}
              type={'button'}
              size="small"
            >
              {isVerifying ? 'Verifying...' : 'Verify'}
            </Button>
          </ButtonAction>
        </Fragment>
      )}
      {type === PHONE_VERIFICATION_TYPES.OTP && (
        <RequestOtpLinkAction currentPhone={currentPhone} hashId={hashId} hasUpdatedPhone={hasUpdatedPhone} />
      )}
      <DashlineSeparator />
      <ButtonAction>
        <Button
          data-test-id="phone-settings-button"
          customStyle={{ background: isDarkMode() ? 'transparent' : '#FFFFFF', color: '#2B8FD7' }}
          buttonType="primary"
          onClick={handleCancelVerification}
          type={'button'}
          size="small"
        >
          Cancel Verification
        </Button>
      </ButtonAction>
    </FormWrapper>
  );
};

export default VerificationForm;
