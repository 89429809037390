import {
  STORE_PROFILE_EXTERNAL,
  STORE_REPORT_REASONS,
  STORE_PROFILE_EXTERNAL_404,
  REMOVE_PROFILE_EXTERNAL,
  STORE_PROFILE_FAV_CHANGE,
  TOGGLE_BLOCK_USER_PROFILE,
  STORE_OFFER_IN_PROFILE,
  REMOVE_SINGLE_OFFER,
  REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA,
  ExtProfileState,
  ExtProfileActionType,
} from './types/ExternalProfile.d';
import { updateVisitedProfiles } from '../utils/storedLocalData';

const initialState: ExtProfileState = {
  reasons: [],
  offer: null,
  is_favorite: null,
  is_blocked: null,
};

/* stores logged in profile */
const extprofile = (state = initialState, action: ExtProfileActionType): any => {
  let newState = {};
  switch (action.type) {
    case STORE_PROFILE_EXTERNAL:
      if (action.payload.photo && action.payload.photo.data && action.payload.video && action.payload.video.data) {
        action.payload.photo.data = [...action.payload.photo.data, ...action.payload.video.data];
      }

      return {
        ...state,
        offer: null, // reset offer data every store attempt
        ...action.payload,
      };
    case STORE_REPORT_REASONS:
      newState = {
        ...state,
        reasons: action.payload,
      };
      updateVisitedProfiles('store_reason', newState);
      return newState;
    case STORE_PROFILE_EXTERNAL_404:
      return {
        notFound: true,
      };

    case REMOVE_PROFILE_EXTERNAL:
      return {};

    case STORE_PROFILE_FAV_CHANGE: {
      // add/remove profile favorite (when viewing profile)
      const is_favorite = state.is_favorite === null ? true : null;
      newState = {
        ...state,
        is_favorite,
      };
      updateVisitedProfiles('fav_change', newState);
      return newState;
    }
    case TOGGLE_BLOCK_USER_PROFILE: {
      // toggle block user profile.
      const is_blocked = state.is_blocked === null ? true : null;
      newState = {
        ...state,
        is_blocked,
      };
      updateVisitedProfiles('block_user', newState);
      return newState;
    }
    case STORE_OFFER_IN_PROFILE: {
      newState = {
        ...state,
        offer: { data: action.payload },
      };
      updateVisitedProfiles('store_offer', newState);
      return newState;
    }
    case REMOVE_SINGLE_OFFER: {
      newState = {
        ...state,
        offer: null,
      };
      updateVisitedProfiles('remove_offer', newState);
      return newState;
    }
    case REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA: {
      // get whole data and spread instead of photos
      const { data } = action.payload;

      if (data.photo && data.photo.data && data.video && data.video.data) {
        data.photo.data = [...data.photo.data, ...data.video.data];
      }

      newState = {
        ...state,
        offer: action.payload.data.offer,
        ...data,
      };

      updateVisitedProfiles('refresh_external', newState);
      return newState;
    }
    default:
      return state;
  }
};

export default extprofile;
