/**
 * @Author: zachalam
 * @Date:   2017-03-15T13:45:38-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-15T13:45:38-07:00
 */

import React from 'react';
import { connect } from 'react-redux';
import { deleteGDPR } from '../actions/profileActions';
import GDPRDelete from '../components/pages/Settings/GDPRDelete';

const GDPRDeleteContainer = props => {
  return <GDPRDelete {...props} />;
};

const mapStateToProps = state => {
  return {
    errors: state.common.errors,
    profile: state.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    gdprDelete: formData => {
      dispatch(deleteGDPR(formData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GDPRDeleteContainer);
