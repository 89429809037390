import { Reducer } from 'react-redux';
import { SET_SITE_ATTRIBUTES } from '../actions/attributeActions';
import { AttributesActionType, IAttributesState } from './types/Attributes';

const initialState: IAttributesState = {
  isEuUser: false,
  isJoinCaptchaEnabled: false,
  isLoginCaptchaEnabled: false,
  captcha: null,
  cf_country: '',
};

const attributes: Reducer<IAttributesState> = (state = initialState, action: AttributesActionType): any => {
  if (action.type === SET_SITE_ATTRIBUTES) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

export default attributes;
