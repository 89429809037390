import React, { ReactElement } from 'react';
interface ICheckIconProps {
  customStyle?: any;
  strokeColor?: any;
}

const CheckIcon = ({ customStyle, strokeColor = '#0F6CE2', ...rest }: ICheckIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      style={{ ...customStyle, ...rest?.style }}
      {...rest}
    >
      <path
        d="M20 2L7.625 13.3514L2 8.19122"
        stroke={strokeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
