import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loading-overlay';
Loader.propTypes = undefined; // workaround for console error on styles.content

// This fixes IE11 issue on spinner trail not showing
const LoaderStyled = (props: any): ReactElement => {
  if (props.barLoader) {
    return (
      <Loader
        {...props}
        styles={{
          overlay: base => ({
            ...base,
            background: props.background || 'none',
            top: props.topPosition,
          }),
          content: contentStyle => ({
            ...contentStyle,
            color: props.color,
          }),
          spinner: base => ({
            ...base,
            color: props.color,
            '& svg circle': {
              stroke: props.color,
              strokeDasharray: '20, 200',
            },
          }),
        }}
      />
    );
  }

  return (
    <Loader
      {...props}
      styles={{
        overlay: overlayStyle => ({
          ...overlayStyle,
          background: props.background || 'none',
          top: props.topPosition,
          opacity: props.opacity,
          heigh: '100%',
        }),
        content: base => ({
          ...base,
          color: props.color,
          fontSize: props.fontSize,
          minHeight: 60,
        }),
        spinner: spinnerStyle => ({
          ...spinnerStyle,
          color: props.color,
          '& svg circle': {
            stroke: props.color,
            strokeDasharray: '20, 200',
          },
        }),
      }}
      className="loader-rotate-fix"
    />
  );
};

LoaderStyled.propTypes = {
  spinner: PropTypes.any,
  text: PropTypes.string,
  color: PropTypes.string,
  zindex: PropTypes.number,
  fontSize: PropTypes.number,
  opacity: PropTypes.number,
};

LoaderStyled.defaultProps = {
  spinner: true,
  text: 'Loading...',
  color: '#1383D2',
  zindex: 999,
};

export default LoaderStyled;
