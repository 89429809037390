import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Status: string;
}
interface UserProperties {
  'Online status & login date hidden': boolean;
}

export const mixpanelTrackToggleHideMyOnlineStatusAndLoginDate = async (
  eventProperties: EventProperties,
  userProperties: UserProperties
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Toggle - Hide my online status & login date',
    payload: {
      event_properties: eventProperties,
      user_properties: userProperties,
    },
  });
};
