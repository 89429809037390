import React, { ReactElement } from 'react';
interface IArrowBackIconProps {
  customStyle?: any;
}

const ArrowBackIcon = ({ customStyle }: IArrowBackIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" style={customStyle}>
      <g opacity="0.65" filter="url(#filter0_b_2106_7747)">
        <circle cx="14" cy="14" r="14" fill="black" />
      </g>
      <path
        d="M20 13.5H7M7 13.5L13.5 20M7 13.5L13.5 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_2106_7747"
          x="-10"
          y="-10"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2106_7747" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2106_7747" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default ArrowBackIcon;
