import React, { ReactElement } from 'react';
import Button from '../../atoms/buttons/Button';
import ButtonSet from '../blocks/ButtonSet';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import '../../style/customModalAnimate.css';
import { backgroundTheme, textColor } from '../../style';

const ModalHeader = styled('span')({
  marginTop: 0,
  flex: 1,
});

interface UnsupportedBrowserModalProps {
  setModalOpen: any;
  isOpen: any;
}

const UnsupportedBrowserModal = ({ setModalOpen, isOpen }: UnsupportedBrowserModalProps): ReactElement => {
  const setModalVisibility = status => {
    setModalOpen(status);
  };

  const setModalStyle = () => {
    return {
      overlay: {
        zIndex: 8000,
        backgroundColor: 'rgba(0,0,0,0.65)',
        overflowY: 'auto',
      },
      content: {
        maxWidth: '520px',
        transform: 'scale(1)',
        margin: '0 auto',
        outline: 'none',
        top: '82px',
        bottom: 'initial',
        background: backgroundTheme.block,
        color: textColor.DARK.color,
        maxHeight: '80vh',
        overflow: 'auto',
        ':focus': {
          outline: 'none',
        },
      },
    };
  };

  return (
    <ReactModal
      closeTimeoutMS={300}
      style={setModalStyle()}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setModalVisibility(false)}
      isOpen={isOpen}
    >
      <ModalHeader>
        The person you are calling might be busy at the moment. Try at a later time or send them a message.
      </ModalHeader>
      <ButtonSet align={'right'}>
        <Button onClick={() => setModalVisibility(false)} buttonType="primary" customStyle={{ marginTop: 15 }}>
          OK
        </Button>
      </ButtonSet>
    </ReactModal>
  );
};

export default UnsupportedBrowserModal;
