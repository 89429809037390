import * as sdk from '../sdk';
import * as Sentry from '@sentry/react';
import _isEmpty from 'lodash/isEmpty';
import { updateMessageThreads, updateMessageConversations } from '../utils/storedLocalData';
import { getBrowserDetails, tabletWidth } from '../utils/helpers';
import { toaster, tsCustomFormat, tsNow } from '../common';
import { refreshAccount } from '../actions/profileActions';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import { Location, NavigateFunction } from 'react-router-dom';
import { CancelTokenSource } from 'axios';

const triggerThreadsFetching = (
  page: any,
  navigate: any,
  location: Location,
  axiosCancelRequestTokenSource: CancelTokenSource | null
): any => {
  return (dispatch: any, getState: any) => {
    const threads = getState().mail.threads;

    dispatch(setFetching(threads.collection.length === 0, page > 1));
    return sdk
      .getThreads(getState().mail.order, page, axiosCancelRequestTokenSource)
      .then(response => {
        const { data } = response;

        const threadCollection = data.data;

        // map the threads and off the fetching threads state
        dispatch(setFetching(false, false));

        dispatch(storeFetchedThreads(page, threadCollection, data)).then(() => {
          const threads = getState().mail.threads;
          updateMessageThreads(threads);

          if (
            location.pathname === '/mail' &&
            threads !== undefined &&
            threads['collection'].length > 0 &&
            (!getBrowserDetails().isMobile || (getBrowserDetails().isMobile && tabletWidth))
          ) {
            navigate(`${location.pathname}/${threads['collection'][0]['other_profile']['data']['hash_id']}`, {
              replace: true,
            });
          }
        });
      })
      .catch(error => {
        dispatch(setFetchingThreads(false));
        const errorMessage = error?.message || error?.errorMessage || error;

        if ('Raven' in window) {
          Sentry.captureException(errorMessage, { tags: { component: 'Mail', action: 'Threads' } });
        }
        console.error('error thread triggerThreadsFetching: ', error);
      });
  };
};

const fetchThreadTimeout = (navigate: NavigateFunction, location: Location, page = 1): any => {
  return dispatch => {
    dispatch(triggerThreadsFetching(page, navigate, location, null));
  };
};

const fetchReceiverThread = (page = 1): any => {
  return (dispatch, getState) => {
    return sdk
      .getThreads(getState().mail.order, page, null)
      .then(response => {
        const { data } = response;
        const threadCollection = data.data;
        dispatch(updateThreads(threadCollection));
      })
      .catch(error => {
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Threads' } });
        }
        console.error('error thread fetchReceiverThread: ', error);
      });
  };
};

const setConversationPage = (section: any, hashId: any): any => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_PAGE',
      payload: {
        order: section,
        conversation: hashId ? {} : getState().mail.conversation,
      },
    });
  };
};

const enableConversation = (): any => {
  return (dispatch, getState) => {
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...getState().mail.conversation,
        locked: false,
      },
    });
  };
};

const updateThreads = (collection: any): any => {
  return (dispatch, getState) => {
    dispatch({
      type: 'STORE_THREADS',
      payload: {
        ...getState().mail.threads,
        collection,
      },
    });
  };
};

const handleDeleteConversation = (threadId: any, hashId: string, navigate: any, location: any, params: any): any => {
  return (dispatch: any, getState: any) => {
    sdk
      .deleteMailThread(true, hashId)
      .then(() => {
        dispatch(refreshAccount());
        dispatch(updateThreadsAfterDelete(threadId)).then(() => {
          updateMessageThreads(getState().mail.threads);
        });
        dispatch(closeConversation(navigate, location, params));
      })
      .catch(error => {
        toaster.error('Sorry, something went wrong.');

        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Unlock' } });
        }

        console.error(error);
      });
  };
};

const updateThreadsAfterDelete = threadId => {
  return (dispatch, getState) => {
    const threads = getState().mail.threads;

    dispatch({
      type: 'STORE_THREADS',
      payload: {
        ...threads,
        collection: threads.collection.filter(thread => thread.id !== threadId),
      },
    });
    return Promise.resolve();
  };
};

const updateThreadsCollection = (senderHashId: any): any => {
  return (dispatch: any, getState: any) => {
    const threads = getState().mail.threads;
    dispatch({
      type: 'STORE_THREADS',
      payload: {
        ...threads,
        collection: threads.collection.map(item => {
          if (item.hash_id === senderHashId || item.to_account_hash_id === senderHashId) {
            item.locked = false;
          }

          return item;
        }),
      },
    });
    return Promise.resolve();
  };
};

const handleResendMessage = (id: any, navigate: NavigateFunction, location: Location, params: any): any => {
  return (dispatch: any, getState: any) => {
    dispatch(updateConvoResendMessages(id)).then(() => {
      const failedMsg = getState().mail.failedMsg;
      dispatch(
        triggerPostMessageProcess(failedMsg.message, failedMsg.type, failedMsg.source, null, params, navigate, location)
      );
    });
  };
};

const updateConvoResendMessages = id => {
  let failedMsg = {};

  return (dispatch, getState) => {
    const conversation = getState().mail.conversation;
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        messages: {
          ...conversation.messages,
          data: conversation.messages.data.filter(item => {
            if (item.id !== id) {
              return item;
            }

            failedMsg = item;
          }),
        },
      },
    });
    dispatch({
      type: 'SET_FAILED_MSG',
      payload: failedMsg,
    });
    return Promise.resolve();
  };
};

const presetOrder = (order: any): any => {
  return {
    type: 'PRESET_ORDER',
    payload: order,
  };
};

const updateConvoMessages = conversation => {
  return dispatch => {
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        messages: {
          ...conversation.messages,
        },
      },
    });
  };
};

const handleUnblurImage = (messageID: any): any => {
  return (dispatch: any, getState: any) => {
    const conversation = getState().mail.conversation;
    const { data: messageData } = conversation.messages;

    const msgIdx = messageData.findIndex(msg => msg.id === messageID);
    messageData[msgIdx] = {
      ...messageData[msgIdx],
      isBlurred: false,
    };

    dispatch({
      type: 'UPDATE_CONVERSATION',
      payload: {
        ...conversation,
        messages: {
          ...conversation.messages,
          data: messageData,
        },
      },
    });
  };
};

const updateMessages = (messages: any): any => {
  return (dispatch: any, getState: any) => {
    const conversation = getState().mail.conversation;
    const conversationMessages = conversation?.messages?.data || [];
    const newMsgData = [...conversationMessages, ...(messages?.data || [])];
    const uniqMessages = {
      data: _uniqBy(_sortBy(newMsgData, 'id'), 'id'),
      meta: messages.meta,
    };

    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        messages: {
          ...uniqMessages,
        },
      },
    });
  };
};

const updatePostConvoMessagesPokes = data => {
  return (dispatch, getState) => {
    const conversation = getState().mail.conversation;
    const { can_send_poke, max_pokes, pokes_count } = data.meta;

    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        pokes: {
          ...conversation.pokes,
          data: conversation.pokes.data.concat(data.data),
          can_send_poke,
          max_pokes,
          pokes_count,
        },
      },
    });
  };
};

const setSendPoke = (hashId: string): any => {
  return (dispatch: any) => {
    sdk
      .sendPoke(hashId)
      .then(response => {
        dispatch(updatePostConvoMessagesPokes(response.data));
      })
      .catch(error => {
        toaster.error('Sorry, something went wrong.');

        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Poke' } });
        }

        console.error(error);
      });
  };
};

const triggerConvoFetching = (currentConvo: any, params: any, navigate: any, location: any, page = 1): any => {
  const currentHashId = window.location.pathname.split('/')[3] || window.location.pathname.split('/')[2];
  const { hashId } = params;
  return (dispatch: any) => {
    const currentId = currentHashId ? currentHashId : hashId;
    if (currentId === '' || currentId === null || currentId === undefined) {
      return false;
    }

    dispatch(setFetchingConversation(_isEmpty(currentConvo)));
    dispatch(fetchConversation(currentId, page, params, navigate, location));
  };
};

const updateConvoDataAfterFetchPage1 = data => {
  const { can_send_poke, max_pokes, pokes_count, can_request_refund, locked_request_refund } = data.meta;

  return dispatch => {
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...data.data,
        can_request_refund,
        locked_request_refund,
        pokes: {
          ...data.data.pokes,
          can_send_poke,
          max_pokes,
          pokes_count,
        },
        isVideoButtonEnabled: data.meta.video_call_enabled,
      },
    });
    return Promise.resolve();
  };
};

const updateConvoMessagesPokes = data => {
  return (dispatch, getState) => {
    const { can_send_poke, max_pokes, pokes_count, can_request_refund } = data.meta;
    const conversation = getState().mail.conversation;
    const conversationMessageData = [...data.data.messages.data, ...conversation.messages.data];
    const conversationPayload = {
      ...data.data,
      can_request_refund,
      pokes: {
        ...conversation.pokes,
        can_send_poke,
        max_pokes,
        pokes_count,
      },
      messages: {
        data: conversationMessageData,
        meta: data.data.messages.meta,
      },
      isVideoButtonEnabled: data.meta.video_call_enabled,
    };

    updateMessageConversations('add', conversationPayload);
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: conversationPayload,
    });
  };
};

const updateMessageandConvo = (): any => {
  return (dispatch: any, getState: any) => {
    const conversation = getState().mail.conversation;
    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        messages: {
          ...conversation.messages,
          data: conversation.messages
            ? conversation.messages.data.map(item => {
                item.they_read = true;
                return item;
              })
            : [],
        },
      },
    });
  };
};

const setThread = (newThread: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: 'STORE_THREADS',
      payload: newThread,
    });
    return Promise.resolve();
  };
};

const archiveConversation = archive => {
  return (dispatch, getState) => {
    const conversation = getState().mail.conversation;

    dispatch({
      type: 'STORE_CONVERSATION',
      payload: {
        ...conversation,
        archive,
      },
    });
  };
};

const setToggleArchive = (hashId: string, page: any, navigate: NavigateFunction, location: Location): any => {
  return (dispatch: any, getState: any) => {
    sdk
      .toggleArchive(true, hashId)
      .then(response => {
        const archived = response.data.archived;
        dispatch(archiveConversation(archived));

        if (!archived) {
          return dispatch(triggerThreadsFetching(page, navigate, location, null));
        }

        dispatch(updateThreadsForArchive(response)).then(() => {
          updateMessageThreads(getState().mail.threads);
        });
      })
      .catch(error => {
        toaster.error('Sorry, something went wrong.');

        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Archive' } });
        }

        console.error(error);
      });
  };
};

const updateThreadsForArchive = data => {
  return (dispatch, getState) => {
    const threads = getState().mail.threads;

    dispatch({
      type: 'STORE_THREADS',
      payload: {
        ...threads,
        collection: threads.collection.filter(thread => {
          return thread.id !== data.data.id;
        }),
      },
    });
    return Promise.resolve();
  };
};

const closeConversation = (navigate: any, location: any, params: any): any => {
  return (dispatch: any) => {
    dispatch({
      type: 'CLOSE_CONVERSATION',
    });
    navigate(location.pathname.replace(params.hashId, ''));
  };
};

const handleThreadClick = (threadId: any, hashId: string, params: any, navigate: any): any => {
  return (dispatch: any, getState: any) => {
    const { order, threads } = getState().mail;

    if (params.hashId === hashId) {
      return false;
    }

    navigate(`/mail${order !== 'recent' ? `/${order}` : ''}/${hashId}`);
    dispatch(setNewThread(threadId)).then(() => {
      updateMessageThreads(threads);
    });
  };
};

const setNewThread = threadId => {
  return (dispatch, getState) => {
    const threads = getState().mail.threads;

    dispatch({
      type: 'SET_NEW_THREADS',
      payload: {
        ...threads,
        collection: threads.collection.map(thread => {
          if (thread.id === threadId) {
            thread.status = 'read';

            if (thread.locked === false && thread.last_message) {
              thread.last_message.data.i_read = true;
            }
          }

          return thread;
        }),
      },
    });
    return Promise.resolve();
  };
};

const toggleAcctTyping = (): any => {
  return {
    type: 'TOGGLE_OTHER_ACCT_TYPING',
  };
};

const setFetching = (isFecthingThreads, isFetchingMoreThreads) => {
  return {
    type: 'START_FETCHING_THREADS',
    payload: {
      fetchingThreads: isFecthingThreads,
      fetchingMoreThreads: isFetchingMoreThreads,
    },
  };
};

const storeFetchedThreads = (page, threadCollection, data) => {
  return (dispatch, getState) => {
    const threads = getState().mail.threads;
    dispatch({
      type: 'STORE_FETCHED_THREADS',
      payload: {
        collection:
          page > 1 ? [...(threads.collection ? threads.collection : []), ...threadCollection] : threadCollection,
        meta: {
          pagination: data.meta.pagination,
        },
      },
    });
    return Promise.resolve();
  };
};

const setFetchingThreads = fetchingThreads => {
  return {
    type: 'SET_FETCHING_THREADS',
    payload: fetchingThreads,
  };
};

const setFetchingConversation = fetchingConveration => {
  return {
    type: 'SET_FETCHING_CONVERSATION',
    payload: fetchingConveration,
  };
};

const setMailError = (code: string | number, message: string): any => {
  return {
    type: 'STORE_MAIL_ERROR',
    payload: {
      code: code,
      message: message,
    },
  };
};

const clearMailError = (): any => {
  return {
    type: 'STORE_MAIL_ERROR',
  };
};

const triggerPostMessageProcess = (
  message: any,
  type = '',
  source = null,
  extension: any,
  params: any,
  navigate: any,
  location: any
): any => {
  return (dispatch: any, getState: any) => {
    const { conversation } = getState().mail;
    const hashId = getState().profile.hash_id;
    const { data: messageData } = conversation.messages;
    const lastMessage = messageData[messageData.length - 1];

    if (message.trim() === '') {
      return false;
    }

    const tempMsgId = lastMessage ? lastMessage.id + 1 : Math.floor(Math.random() * 1000);

    const newMessage = {
      id: tempMsgId,
      hash_id: hashId,
      message,
      created_at: { date: tsCustomFormat(tsNow(), 'YYYY-MM-DD HH:mm:ss') },
      i_read: true,
      type,
      source,
      isBlurred: false,
    };

    const messagePendingUpdate = {
      message,
      type,
      source,
      tempMsgId,
      extension,
    };

    dispatch(storePostMessageData(newMessage, messagePendingUpdate)).then(() => {
      // will update this later after we update handleReplaceMessageId method in action
      if (type !== 'photo') {
        dispatch(triggerReplaceMessageId(params)).then(() => {
          dispatch(fetchThreadTimeout(navigate, location));
        });
      } else {
        dispatch(fetchThreadTimeout(navigate, location));
      }
      const conversationBody = document.getElementById('conversation_body');
      conversationBody.scrollTop = conversationBody.scrollHeight;
    });
  };
};

const storePostMessageData = (newMessage: any, messagePendingUpdate: any): any => {
  return (dispatch, getState) => {
    const threads = getState().mail.threads;
    const conversation = getState().mail.conversation;
    dispatch({
      type: 'STORE_POST_MESSAGE',
      payload: {
        threads: {
          ...threads,
          collection: threads.collection.map(item => {
            if (item.id === conversation.id) {
              item.last_message = { data: newMessage };
            }
            return item;
          }),
        },
        conversation: {
          ...conversation,
          messages: {
            ...conversation.messages,
            data: conversation.messages.data.concat(newMessage),
          },
        },
        messagePendingUpdate: {
          ...messagePendingUpdate,
        },
      },
    });
    return Promise.resolve();
  };
};

const handlePhotoMsgActionErr = (error: any): any => {
  return (dispatch: any, getState: any) => {
    const conversation = getState().mail.conversation;

    toaster.error('Sorry, something went wrong.');

    if (conversation.messages.data.length > 0) {
      conversation.messages.data[conversation.messages.data.length - 1].error = true;
    }

    dispatch(updateConvoMessages(conversation));

    if ('Raven' in window) {
      Sentry.captureException(error, { tags: { component: 'Mail', action: 'Message' } });
    }

    console.error(error);
  };
};

const setDeletePhotoMessage = (option: any, messageID: any): any => {
  return dispatch => {
    sdk.deletePhotoMessage(option, messageID).catch(error => {
      dispatch(handlePhotoMsgActionErr(error));
    });
  };
};

const handleDeleteImage = (
  newMessage: any,
  messageID: any,
  option: any,
  navigate: NavigateFunction,
  location: Location
): any => {
  return (dispatch: any) => {
    dispatch(updateNewThreadMessages(newMessage, messageID, navigate, location));
    dispatch(setDeletePhotoMessage(option, messageID));
  };
};

const updateNewThreadMessages = (newMessage, messageID, navigate, location) => {
  return (dispatch, getState) => {
    const { threads, conversation } = getState().mail;
    const { data: messageData } = conversation.messages;
    const lastMessage = messageData[messageData.length - 1];
    const msgIdx = messageData.findIndex(msg => msg.id === messageID);

    messageData[msgIdx] = {
      ...messageData[msgIdx],
      ...newMessage,
    };

    const newThread =
      messageData[msgIdx].id === lastMessage.id
        ? {
            ...threads,
            collection: threads.collection.map(item => {
              if (item.id === conversation.id) {
                item.last_message = { data: newMessage };
              }

              return item;
            }),
          }
        : {
            ...threads,
          };

    dispatch(updateThreadConvoMessages(newThread, conversation, messageData)).then(() => {
      dispatch(fetchThreadTimeout(navigate, location));
      const conversationBody = document.getElementById('conversation_body');
      conversationBody.scrollTop = conversationBody.scrollHeight;
    });
  };
};

const updateThreadConvoMessages = (newThread, conversation, messageData) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_THREAD_MESSAGES',
      payload: {
        threads: newThread,
        conversation: {
          ...conversation,
          messages: {
            ...conversation.messages,
            data: messageData,
          },
        },
      },
    });
    return Promise.resolve();
  };
};

const handleUnlockOffer = (
  currConvo: any,
  threadId: any,
  hashId: string,
  params: any,
  navigate: any,
  location: any,
  triggerToBuy: boolean,
  callback: () => void
): any => {
  return (dispatch: any, getState: any) => {
    dispatch(clearMailError());
    return sdk
      .unlockOffer(true, hashId, triggerToBuy)
      .then((): any => {
        dispatch(triggerConvoFetching(currConvo, params, navigate, location));
        dispatch(refreshAccount());
        dispatch(updateUnlockThreadConvo(threadId)).then(() => {
          updateMessageThreads(getState().mail.threads);
        });
        callback();

        return Promise.resolve();
      })
      .catch((error): any => {
        if (error.status === 404) {
          console.info('Offer is already unlocked');
          return;
        }

        if (getState().mail?.error?.code === 403) {
          return Promise.reject(error);
        }

        toaster.error('Sorry, something went wrong.');
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Unlock' } });
        }

        console.error(error);

        return Promise.resolve();
      });
  };
};

const unlockOutsideMessage = (hashId: string, triggerToBuy: boolean, callback: () => void): any => {
  return (dispatch: any, getState: any) => {
    sdk
      .unlockOffer(true, hashId, triggerToBuy)
      .then(() => {
        dispatch(refreshAccount());
        callback();
      })
      .catch(error => {
        if (error.status === 404) {
          console.info('Offer is already unlocked');
          return;
        }

        if (getState().mail?.error?.code === 403) {
          return Promise.reject(error);
        }

        toaster.error('Sorry, something went wrong.');
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Unlock' } });
        }

        console.error(error);
      });
  };
};

const updateUnlockThreadConvo = (threadId: any): any => {
  return (dispatch: any, getState: any) => {
    const { conversation, threads } = getState().mail;

    dispatch({
      type: 'UPDATE_THREAD_MESSAGES',
      payload: {
        threads: {
          ...threads,
          collection: threads.collection.map(thread => {
            if (thread.id === threadId) {
              thread.locked = false;
              thread.status = 'read';

              if (thread.last_message) {
                thread.last_message.data.i_read = true;
              }
            }

            return thread;
          }),
        },
        conversation: {
          ...conversation,
          locked: false,
        },
      },
    });
    return Promise.resolve();
  };
};

const handleCancelOffer = (hashId: string, navigate: NavigateFunction, location: any, params: any): any => {
  return (dispatch: any, getState: any) => {
    sdk
      .ignoreOffer(true, hashId)
      .then(() => {
        toaster.success('Offer Removed!');
        dispatch(updateThreadsForCancel()).then(() => {
          updateMessageThreads(getState().mail.threads);
        });
        dispatch(closeConversation(navigate, location, params));
      })
      .catch(error => {
        toaster.error('Sorry, something went wrong.');
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Cancel Offer' } });
        }

        console.error(error);
      });
  };
};

const updateThreadsForCancel = (): any => {
  return (dispatch: any, getState: any) => {
    const conversation = getState().mail.conversation;
    const threads = getState().mail.threads;

    dispatch({
      type: 'STORE_THREADS',
      payload: {
        ...threads,
        collection: threads.collection.filter(thread => {
          return thread.id !== conversation.id;
        }),
      },
    });
    return Promise.resolve();
  };
};

const triggerReplaceMessageId = (params: any): any => {
  return async (dispatch: any, getState: any) => {
    try {
      const { conversation, messagePendingUpdate } = getState().mail;
      if (_isEmpty(conversation) || _isEmpty(messagePendingUpdate)) {
        return;
      }

      const { message, type, source, tempMsgId, extension } = messagePendingUpdate;
      const { hashId } = params;
      const { data: messageData } = conversation.messages;

      if (message.trim() === '') {
        return false;
      }

      let msgToPost = message;

      if (type === 'photo') {
        const msgResp = JSON.parse(message);
        msgToPost = msgResp.path;
      }

      const postMessageResponse = await sdk.postMessage(hashId, msgToPost, type, extension);

      if (postMessageResponse?.data?.data) {
        const newMessage = {
          ...postMessageResponse.data.data,
          i_read: true,
          message,
          type,
          source,
          isBlurred: false,
        };

        const msgIdx = messageData.findIndex(msg => msg.id === tempMsgId);

        if (msgIdx !== -1) {
          messageData[msgIdx] = {
            ...messageData[msgIdx],
            ...newMessage,
          };
        }

        dispatch({
          type: 'UPDATE_CONVERSATION_COMPLETE',
          payload: {
            ...conversation,
            messages: {
              ...conversation.messages,
              data: messageData,
            },
          },
        });
      }
    } catch (error) {
      const conversation = getState().mail.conversation;

      if (error?.errorMessage === 'requestBlocked') {
        toaster.error('Slow Down you are going to fast...Please wait!');
      } else {
        toaster.error('Sorry, something went wrong.');
      }

      if (conversation.messages.data.length > 0) {
        conversation.messages.data[conversation.messages.data.length - 1].error = true;
      }

      dispatch(updateConvoMessages(conversation));

      if ('Raven' in window) {
        Sentry.captureException(error, { tags: { component: 'Mail', action: 'Message' } });
      }

      console.error(error);
    }
    return Promise.resolve();
  };
};

const fetchConversation = (hashId: string, page: any, params?: any, navigate?: any, location?: any): any => {
  return (dispatch: any, getState: any) => {
    return sdk
      .getConversation(hashId, page)
      .then(response => {
        dispatch(setFetchingConversation(false));
        if (page === 1) {
          const data = response.data;

          dispatch(updateConvoDataAfterFetchPage1(data)).then(() => {
            updateMessageConversations('add', getState().mail.conversation);
          });

          return;
        }
        dispatch(updateConvoMessagesPokes(response.data));
      })
      .catch(error => {
        if (error?.errorMessage !== 'requestBlocked') {
          dispatch(closeConversation(navigate, location, params));
          toaster.error('Sorry, something went wrong');
        }
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Conversation' } });
        }

        console.error(error);
      });
  };
};

const setblockUser = (hashId: string, username: string, navigate: any): any => {
  return () => {
    sdk
      .blockUser(true, hashId)
      .then(() => {
        toaster.warn(`You have blocked ${username}`);
        navigate('/blocked');
      })
      .catch(error => {
        toaster.error('Sorry, something went wrong.');
        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Mail', action: 'Block' } });
        }
        console.error(error);
      });
  };
};

const handlePostTypingStatus = (hashId: any): any => {
  return () => {
    sdk.sendTypingIndicator(hashId);
  };
};

const setToggleThreadMessageStatus = (isRead: any, id: any, hash_id: string, status: any): any => {
  return async () => {
    await sdk.toggleThreadMessageStatus(isRead, id, hash_id, status);
  };
};

const handleReportImage = (newMessage: any, messageID: any, navigate: NavigateFunction, location: Location): any => {
  return (dispatch: any) => {
    dispatch(updateNewThreadMessages(newMessage, messageID, navigate, location));

    sdk
      .reportPhotoMessage(messageID)
      .then(() => {
        toaster.success('Photo message reported');
        navigate('/blocked');
      })
      .catch(error => {
        dispatch(handlePhotoMsgActionErr(error));
      });
  };
};

export {
  triggerThreadsFetching,
  fetchThreadTimeout,
  setConversationPage,
  enableConversation,
  updateThreadsCollection,
  setThread,
  updateMessages,
  toggleAcctTyping,
  updateMessageandConvo,
  triggerConvoFetching,
  triggerPostMessageProcess,
  triggerReplaceMessageId,
  handleDeleteImage,
  handleUnblurImage,
  setSendPoke,
  handleReportImage,
  handleResendMessage,
  handleUnlockOffer,
  handleDeleteConversation,
  setToggleArchive,
  handleCancelOffer,
  closeConversation,
  handleThreadClick,
  setblockUser,
  handlePostTypingStatus,
  setToggleThreadMessageStatus,
  setDeletePhotoMessage,
  presetOrder,
  updateThreads,
  unlockOutsideMessage,
  fetchReceiverThread,
  setMailError,
};
