import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Payment',
};

export const enum MelissaClickVysionEventsEnum {
  PAYMENT_COUNTRY = 'paymentCountry',
  PAYMENT_COUNTRY_SELECT = 'paymentCountrySelect',
  PAYMENT_ADDRESS_SELECT = 'paymentAddressAddressSelect',
}

export const melissaBillingFormOnClickVysionEvents = {
  [MelissaClickVysionEventsEnum.PAYMENT_COUNTRY]: {
    ...commonValues,
    eventName: 'payment-country',
    component: 'Country Drop-Down',
  },
  [MelissaClickVysionEventsEnum.PAYMENT_COUNTRY_SELECT]: {
    ...commonValues,
    eventName: 'payment-country-countrySelect',
    component: 'Country Drop-Down',
    extraInfo: 'Country Selection'
  },
  [MelissaClickVysionEventsEnum.PAYMENT_ADDRESS_SELECT]: {
    ...commonValues,
    eventName: 'payment-address-addressSelect',
    component: 'Address Form',
    extraInfo: 'Address Selection'
  },
};
