import React, { ReactElement } from 'react';

interface CopyIconProps {
  width?: any;
  height?: any;
  fill?: string;
  onClick?: any;
}

const CopyIcon = ({ width = 30, height = 30, fill = '#2B8FD7', onClick = () => null }: CopyIconProps): ReactElement => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="4 -6 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9809 22.1639C7.2234 22.1639 6.57493 21.8942 6.03549 21.3547C5.49605 20.8153 5.22633 20.1669 5.22633 19.4094V3.0959C5.22633 2.33517 5.49605 1.68394 6.03549 1.14221C6.57493 0.600465 7.2234 0.32959 7.9809 0.32959H20.5443C21.3051 0.32959 21.9563 0.600465 22.4981 1.14221C23.0398 1.68394 23.3106 2.33517 23.3106 3.0959V19.4094C23.3106 20.1669 23.0398 20.8153 22.4981 21.3547C21.9563 21.8942 21.3051 22.1639 20.5443 22.1639H7.9809ZM3.14302 27.0137C2.38229 27.0137 1.73105 26.7427 1.1893 26.2009C0.647574 25.6592 0.376709 25.008 0.376709 24.2472V7.40528C0.376709 7.01986 0.510251 6.69395 0.777334 6.42756C1.04442 6.16118 1.37315 6.028 1.76352 6.028C2.1539 6.028 2.48141 6.16118 2.74605 6.42756C3.0107 6.69395 3.14302 7.01986 3.14302 7.40528V24.2472H16.0475C16.4329 24.2472 16.7588 24.3808 17.0252 24.6478C17.2916 24.9149 17.4247 25.2437 17.4247 25.634C17.4247 26.0244 17.2916 26.3519 17.0252 26.6166C16.7588 26.8813 16.4329 27.0137 16.0475 27.0137H3.14302Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyIcon;
