import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MasterConfig from '../config/Master';

import Button from '../atoms/buttons/Button';
import { Select } from '../atoms/Select';
import InputLabel from '../atoms/InputLabel';
import TextArea from '../atoms/TextArea';

export const CATEGORIES = {
  billing: 'Billing',
  privacy: 'Privacy Concerns',
  website_issue: 'Problems using the website',
  report_member: 'Report a member',
  joining: `Starting out with ${MasterConfig.SITE_NAME}`,
  suggestions_feedback: 'Suggestions and Feedback',
  other: 'Other',
};

const FormToastContainer = styled('div')`
  background-color: ${props => {
    if (props.error) {
      return '#cc0000';
    }

    if (props.success) {
      return '#77b300';
    }
  }};
  padding: 12px;
  color: #fff;
  border-radius: 4px;
`;

const MailToLink = styled('a')`
  font-weight: bold;
`;

const Form = styled('form')`
  min-height: 50vh;
`;

const mapCodeToError = code => {
  // NOTE: This is a placeholder until a proper mapping of error code to message is implemented
  switch (code) {
    default:
      return (
        <React.Fragment>
          Oh no! Something went wrong, but we&#39;re not sure what. Try again, or email&nbsp;
          <MailToLink
            href={`mailto:support@${MasterConfig.BASE_URL}`}
          >{`support@${MasterConfig.BASE_URL}`}</MailToLink>{' '}
          directly.
        </React.Fragment>
      );
  }
};

const renderDropdownCategories = () => {
  return Object.entries(CATEGORIES).map(([key, category]) => (
    <option key={key} value={key}>
      {category}
    </option>
  ));
};

const renderDefaultField = (label, defaultField) => {
  const formattedDefaultField = defaultField.charAt(0).toUpperCase() + defaultField.slice(1);

  return (
    <React.Fragment>
      <InputLabel label={label} customContainerStyle={{ marginRight: 0 }} />
      <div style={{ paddingBottom: '24px' }}>{formattedDefaultField}</div>
    </React.Fragment>
  );
};

interface SupportTicketFormProps {
  onSubmit: any;
  subject: any;
  message: any;
  defaultSubject: any;
  onChangeSubject: any;
  onChangeMessage: any;
  errorCode: any;
  success: any;
}

const SupportTicketForm = ({
  onSubmit,
  subject,
  message,
  defaultSubject,
  onChangeSubject,
  onChangeMessage,
  errorCode,
  success,
}: SupportTicketFormProps): ReactElement => {
  const handleSubmit = e => {
    onSubmit(e);
  };

  return (
    <div>
      {Boolean(errorCode && errorCode !== 0) && (
        <FormToastContainer data-test-id="support-ticket-error" error>
          {mapCodeToError(errorCode)}
        </FormToastContainer>
      )}
      {success && (
        <FormToastContainer data-test-id="support-ticket-success" success>
          Report has been filed successfully! Loading your last page.
        </FormToastContainer>
      )}
      <div style={{ maxWidth: '480px' }} data-test-id="support-ticket">
        <Form onSubmit={handleSubmit}>
          {defaultSubject ? (
            renderDefaultField('Subject', defaultSubject)
          ) : (
            <Select
              id="support-ticket-subject"
              inputLabel="Subject"
              value={subject}
              noSpacing
              onChange={onChangeSubject}
              baseSelectStyle={{ paddingBottom: 24 }}
            >
              <option value="default" disabled>
                Select category
              </option>
              {renderDropdownCategories()}
            </Select>
          )}
          <TextArea
            id="support-ticket-message"
            inputLabel="Message"
            resize="vertical"
            rows={7}
            value={message}
            onChange={onChangeMessage}
          />

          <Button
            buttonType="primary"
            type="submit"
            data-test-id="support-ticket-submit"
            disabled={!subject || !message || subject === '' || message === ''}
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

SupportTicketForm.defaultProps = {
  message: '',
};

SupportTicketForm.propTypes = {
  /** Preselected subject; Subject dropdown will not render if this is provided */
  defaultSubject: PropTypes.string,
  /** Text in Message textarea */
  message: PropTypes.string,
  /** Function to handle Subject dropdown selection */
  onChangeSubject: (props, propName, componentName) => {
    if (!props['defaultSubject'] && typeof props['onChangeSubject'] !== 'function') {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} must be a function if no default subject provided`
      );
    }
  },
  /** Error code to determine which error message is to be displayed */
  errorCode: PropTypes.number,
  /** Function to handle submitting button */
  onSubmit: PropTypes.func,
  /** Determine if the ticket submission is a success */
  success: PropTypes.bool,
};

export default SupportTicketForm;
