import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, buttonTheme, dimensions, type } from '../../../../style';
import { commonIcons } from '../../../../atoms/icons/materialIcons';
import { auth10tixVerification } from '../../../../config/Master';
import DashboardDiscoverModeMediaSlider from './DashboardDiscoverModeMediaSlider';
import DashboardDiscoverModeMediaCounter from './DashboardDiscoverModeMediaCounter';
import { useDashboardDiscoverMode } from '../../../../contexts/DashboardDiscoverModeContext';
import { Account } from '../../../../models/Account';
import useMixPanel from '../../../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../../../constants/mixpanel';
import { useLocation } from 'react-router-dom';

const CardContainer = styled('div')({
  position: 'relative',
  border: '1px solid #DAE1E9',
  backgroundColor: '#fff',
  width: 'calc(100vw - 20px)',
  borderRadius: 10,
  overflow: 'hidden',
  [dimensions.SCREEN_MIN_MD]: {
    width: 348,
  },
});

const PhotoAspectRatio = styled('div')(({ paddingTop }) => ({
  paddingTop: paddingTop || '133%',
  position: 'relative',
  [dimensions.SCREEN_MIN_MD]: {
    paddingTop: 465,
  },
}));

const AccountDetailsContainer = styled('div')({
  color: 'white',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  zIndex: 3,
  overflow: 'hidden',
});

const AccountDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'bottom',
  paddingLeft: '12px',
  paddingBottom: '1px',
  flexWrap: 'wrap',
  width: '75%',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

const UsernameContainer = styled('div')({
  ...type.MD,
  width: '100%',
  color: buttonTheme.GreyAndWhite,
});

const Username = styled('div')({
  width: 'auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  maxWidth: '80%',
});

const UserDetails = styled('div')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
  width: '85%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  position: 'absolute',
  width: '100%',
  height: 74,
  bottom: '-1px',
  left: 0,
  zIndex: 2,
});

const OnlineDot = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: 'lightgreen',
  borderRadius: '50%',
  display: 'inline-block',
});

const VerifiedBadge = styled('span')({
  marginLeft: 4,
  zIndex: 2,
});

const BackgroundOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5))',
  zIndex: 1,
});

interface CardProps {
  profile: any;
  account: Account;
}

const DashboardDiscoverModeProfileCard = ({ profile, account }: CardProps): JSX.Element => {
  const { pathname } = useLocation();
  const { setMixpanelDataByKeyHandler } = useMixPanel();
  const [showToolTip, setShowToolTip] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [paddingTop, setPaddingTop] = useState('133%'); // Default value
  const [photosViewedCount, setPhotosViewedCount] = useState(0);
  const { photoAspectRatioRef, offerActionContainerRef, discoverActiveAccount } = useDashboardDiscoverMode();

  const mergePhotosVideos = [
    ...account.data.photo.data.filter(item => item.status === 'approved'),
    ...account.data.video.data.filter(item => item.status === 'approved'),
  ].filter(Boolean);

  const isVerified = account.data.id_verification?.data?.status === 'approved';

  const photosApprovedPublic = mergePhotosVideos.filter(item => item.id);
  const photosApprovedPrivate = mergePhotosVideos.filter(item => !item.id);
  const privatePhotos = photosApprovedPrivate.length > 0 ? [photosApprovedPrivate[0]] : [];
  const photosPublicWithOnePrivate = [...photosApprovedPublic, ...privatePhotos].filter(Boolean);

  useEffect(() => {
    if (photosViewedCount > 0) {
      setMixpanelDataByKeyHandler({
        key: EVENT_NAMES.DISCOVER_MODE_ACTION,
        payload: { 'Number of Photos Viewed': photosViewedCount + 1 },
      });
    }
  }, [photosViewedCount]);

  const handleSlide = (step: number) => {
    const nextSlide = currentSlide + step;

    if (nextSlide >= 0 && nextSlide < photosPublicWithOnePrivate.length) {
      setCurrentSlide(nextSlide);
      setPhotosViewedCount(prevState => (prevState < nextSlide ? prevState + 1 : prevState));
    }
  };

  useEffect(() => {
    calculateHeightPhotoSlider();
  }, [offerActionContainerRef?.current, photoAspectRatioRef?.current]);

  useEffect(() => {
    const cardHeightTimeout = setTimeout(() => {
      calculateHeightPhotoSlider();
    }, 300);

    return () => {
      clearTimeout(cardHeightTimeout);
    };
  }, [pathname]);

  const calculateHeightPhotoSlider = () => {
    //  handle auto height for photo slider
    const photoAspectEl = photoAspectRatioRef?.current;
    const offerActionEl = offerActionContainerRef?.current;

    if (photoAspectEl && offerActionEl) {
      const offerActionClRec = offerActionEl.getBoundingClientRect();
      const photoAspectClRec = photoAspectEl.getBoundingClientRect();

      const PHOTO_SLIDER_COUNTER_HEIGHT = 41;
      const PHOTO_SLIDER_MARGIN_BOTTOM = 15;
      const dynamicPaddingTop =
        offerActionClRec.top - photoAspectClRec.top - PHOTO_SLIDER_COUNTER_HEIGHT - PHOTO_SLIDER_MARGIN_BOTTOM;

      setPaddingTop(`${dynamicPaddingTop}px`);
    }
  };

  return (
    <CardContainer className="card">
      {discoverActiveAccount?.hash_id !== account.data.hash_id && <BackgroundOverlay />}
      <PhotoAspectRatio paddingTop={paddingTop} ref={photoAspectRatioRef}>
        <AccountDetailsContainer>
          <AccountDetails>
            <UsernameContainer>
              <Username>{account.data.username}</Username>
              {auth10tixVerification && isVerified && (
                <VerifiedBadge
                  data-test-id="data-verified-badge"
                  onMouseEnter={() => setShowToolTip(true)}
                  onMouseLeave={() => setShowToolTip(false)}
                >
                  <commonIcons.check.badge showToolTip={showToolTip} />
                </VerifiedBadge>
              )}
            </UsernameContainer>
            <UserDetails data-test-id="data-member-age-city">
              {profile.online ? <OnlineDot /> : ''} {profile.age} {profile.city && <>&middot; {profile.city}</>}
            </UserDetails>
          </AccountDetails>
        </AccountDetailsContainer>
        <Gradient />
        <DashboardDiscoverModeMediaSlider
          photos={photosPublicWithOnePrivate}
          currentSlide={currentSlide}
          handleSlide={handleSlide}
          privateCount={photosApprovedPrivate.length}
        />
      </PhotoAspectRatio>
      <DashboardDiscoverModeMediaCounter
        photos={photosPublicWithOnePrivate}
        currentSlide={currentSlide}
        hashId={account.data.hash_id}
      />
    </CardContainer>
  );
};

export default DashboardDiscoverModeProfileCard;
