import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { dimensions, borders, backgroundTheme, buttonTheme } from '../../style';
import { numberWithCommas } from '../../common';
import RadioButton from '../RadioButton';
import Button from '../buttons/Button';

const PackageStacked = styled('div')`
  padding: 10px 20px;

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const StyledFlexDiv = styled('div')`
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 0 100%;
  padding-bottom: 10px;
`;

const StackedCredits = styled('div')`
  color: #2490de;
  align-self: flex-end;
  font-size: ${props => (props.fontSize ? props.fontSize : 22)}px;
`;

const StackedSaveContainer = styled('div')`
  color: ${buttonTheme.darkReverse};
  align-self: flex-end;
  font-size: ${props => (props.fontSize ? props.fontSize : 22)}px;
`;

const StackedCurrency = styled('div')`
  color: #3c4858;
  font-size: 18px;
  flex: 2;
  text-align: left;
`;

const StackedCreditContainer = styled('div')`
  flex: 2;
  text-align: right;
`;

const StackedRight = styled('div')`
  float: right;
`;

const Card = styled('div')`
  min-width: 31.5%;
  max-width: 250px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${backgroundTheme.block};
  border: ${borders.DEFAULT};
  text-align: center;
  flex: 1;
  padding: 1px 0;
  position: relative;
  ${props =>
    props.selected &&
    `
        margin: -1px;
        border: 2px solid #1478ca;
        cursor: default;
        padding: 0 0;
        background: ${backgroundTheme.selectedPackage};
        ${dimensions.SCREEN_MAX_MD} {
          margin: 0;
        }
      `} ${props =>
    props.large &&
    `
        max-width: 280px;
        ${dimensions.SCREEN_MAX_MD} {
          margin: 0;
        }
      `};
  ${dimensions.SCREEN_MIN_XXS} {
    max-width: 600px;
    margin: 0 auto 20px;
  }
`;

const CreditsPerCurrency = styled('div')`
  font-size: 12px;
  color: ${backgroundTheme.creditText};
  padding-top: 16px;
  ${dimensions.SCREEN_MIN_XXS} {
    padding-top: 0px;
    font-size: 14px;
  }
`;

const PackageTag = styled('div')`
  font-size: 18px;
  color: #ffffff;
  background-color: #1378ca;
  text-align: center;
  position: absolute;
  align-self: center;
  width: 30%;
  left: 35%;
  top: -14px;
  border-radius: 6px;
  padding: 5px 0px;
  ${({ selected }) =>
    selected &&
    `
      padding: 5px 0px;
      top: -15px;
    `};
  ${dimensions.SCREEN_MAX_XS} {
    font-size: 13px;
  }
`;

const PackageBanner = styled('div')`
  padding-top: 18px;
`;

const ShowBuyButton = styled(Button)({
  padding: '0 25px',
  margin: '0 -6px',
});

const renderCreditsPerCurrency = (credits, hasTopTag, price, currencyCode = '$') => {
  let creditsText;
  const creditsPP = Math.round((credits / parseInt(price, 10)) * 2) / 2;

  switch (parseInt(credits, 10)) {
    case 220:
      creditsText = `2.2 credits per ${currencyCode}1`;
      break;
    case 500:
      creditsText = `3.3 credits per ${currencyCode}1`;
      break;
    case 100:
    case 250:
    case 450:
    case 1000:
    case 1500:
      creditsText = `${creditsPP} credits per ${currencyCode}1`;
      break;
    case 300:
      // FIXME: added this as temp as 3.5 credits per $1 is simply untrue
      creditsText = `3 credits per ${currencyCode}1`;
      break;
    default:
      creditsText = '';
  }
  return creditsText ? <CreditsPerCurrency hasTopTag={hasTopTag}>{creditsText}</CreditsPerCurrency> : null;
};

const renderPackageTag = (recommended, promotional, selected) => {
  return (
    <div>
      <PackageBanner>
        <PackageTag selected={selected}>{recommended && !promotional ? `Popular` : `Special offer!`}</PackageTag>
      </PackageBanner>
    </div>
  );
};

interface BillingPackageStackedOldProps {
  selected: any;
  selectPackage: any;
  credits: any;
  promotional: any;
  hasPromoPackage: any;
  identifier: any;
  currencyCharCode: any;
  price: any;
  isRecommended: any;
  savings: any;
  withNewPackage: any;
  stepBilling: any;
  emailPromo: any;
  showPayment: any;
}

const BillingPackageStackedOld = ({
  selected,
  selectPackage,
  credits,
  promotional,
  hasPromoPackage,
  identifier,
  currencyCharCode,
  price,
  isRecommended,
  savings,
  withNewPackage,
  stepBilling,
  emailPromo,
  showPayment,
}: BillingPackageStackedOldProps): any => {
  // Recommended tag will only render if the package is recommended and there are no promotional packages in the list of packages
  // Special Offer! tag will only render if the package is promotional and selected
  const isRecommendedPackage = isRecommended && !hasPromoPackage;
  const isSpecialPackage = isRecommendedPackage || promotional;

  return (
    <Card
      selected={selected}
      large={isSpecialPackage}
      onClick={() => {
        if (!selected) selectPackage(identifier);
        if (stepBilling && withNewPackage) showPayment(identifier, emailPromo);
      }}
    >
      {isSpecialPackage ? renderPackageTag(isRecommendedPackage, promotional, selected) : null}
      <PackageStacked isSpecialPackage={isSpecialPackage}>
        <StackedCurrency>
          <StackedCredits fontSize={22}>
            <b>
              {String.fromCharCode(currencyCharCode)} {price}
            </b>
          </StackedCredits>
          <StackedCredits fontSize={16}>
            {renderCreditsPerCurrency(credits, true, price, String.fromCharCode(currencyCharCode))}
          </StackedCredits>
        </StackedCurrency>
        <StackedCreditContainer>
          <StackedCredits fontSize={22}>
            <b>{numberWithCommas(credits)}</b>
          </StackedCredits>
          <StackedCredits fontSize={18}>credits</StackedCredits>
        </StackedCreditContainer>
        <StyledFlexDiv>
          <StackedCurrency>
            {savings > 0 && (
              <StackedSaveContainer fontSize={18}>
                Save <b>{savings}%</b>
              </StackedSaveContainer>
            )}
          </StackedCurrency>
          <StackedRight>
            {stepBilling && withNewPackage ? (
              <ShowBuyButton
                data-test-id="package-buy-now"
                buttonType="error"
                onClick={e => {
                  e.stopPropagation();
                  selectPackage(identifier);
                  showPayment(identifier, emailPromo);
                }}
                customStyle={{ backgroundColor: '#BC2028' }}
              >
                Buy
              </ShowBuyButton>
            ) : (
              <RadioButton
                label={''}
                checked={selected}
                onRadioChange={() => null}
                name={'Package Selected'}
                id={price}
              />
            )}
          </StackedRight>
        </StyledFlexDiv>
      </PackageStacked>
    </Card>
  );
};

BillingPackageStackedOld.propTypes = {
  /** Signifies if package is selected */
  selected: PropTypes.bool,
  /** Function to handle selecting a package */
  selectPackage: PropTypes.func.isRequired,
  /** Number of credits */
  credits: PropTypes.number.isRequired,
  /** Signifies if tag on top of package card should render "Special Offer!" */
  promotional: PropTypes.bool,
  /** Unique identifier for package (i.e. generous_male_1) - this is used for use2pay */
  identifier: PropTypes.string.isRequired,
  /** Currency char code */
  currencyCharCode: PropTypes.string.isRequired,
  /** Price of package */
  price: PropTypes.string.isRequired,
  /** Signifies this package is part of a package list that includes a promo package */
  hasPromoPackage: PropTypes.bool,
};

export default BillingPackageStackedOld;
