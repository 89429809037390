import React from 'react';

import MasterConfig from '../config/Master';

import DashboardContainer from '../containers/DashboardContainer';
import SearchContainer from '../containers/SearchContainer';
import ProfileContainer from '../containers/ProfileContainer';
import InterestsContainer from '../containers/InterestsContainer';
import OffersContainer from '../containers/OffersContainer';
import MailContainer from '../containers/MailContainer';
import PhotosContainer from '../containers/PhotosContainer';
import BillingSuccessContainer from '../containers/BillingSuccessContainer';
import BillingOrderContainer from '../containers/BillingOrderContainer';
import BillingHistoryContainer from '../containers/BillingHistoryContainer';
import BillingMaintenanceContainer from '../containers/BillingMaintenanceContainer';
import BillingPackagesContainer from '../containers/BillingPackagesContainer';
import BillingPaymentContainer from '../containers/BillingPaymentContainer';
import IDVPackagesContainer from '../containers/IDVPackagesContainer';
import SettingsContainer from '../containers/SettingsContainer';
import DeactivateContainer from '../containers/DeactivateContainer';
import GDPRDeleteContainer from '../containers/GDPRDeleteContainer';
import BlockedContainer from '../containers/BlockedContainer';
import BiometricsContainer from '../containers/BiometricsContainer';
import ReportContainer from '../containers/ReportContainer';
import SupportTicketContainer from '../containers/SupportTicketContainer';
import PrivacyPolicyGDPR from '../containers/PrivacyPolicyGDPR';
import DemoContainer from '../containers/DemoContainer';
import VerificationContainer from '../containers/VerificationContainer';
import CreditsContainer from '../containers/CreditsContainer';

export default [
  {
    path: '/',
    component: <DashboardContainer />,
  },
  {
    path: '/dashboard',
    component: <DashboardContainer />,
  },
  {
    path: '/search',
    component: <SearchContainer />,
  },
  {
    path: '/profile',
    component: <ProfileContainer />,
    children: [
      {
        path: ':hashId',
        component: <ProfileContainer />,
      },
    ],
  },
  {
    path: '/photos',
    component: <PhotosContainer />,
  },
  {
    path: '/interests',
    component: <InterestsContainer />,
    children: [
      {
        path: 'favorited-me',
        component: <InterestsContainer />,
      },
      {
        path: 'my-favorites',
        component: <InterestsContainer />,
      },
    ],
  },
  {
    path: '/offers/:offerType',
    component: <OffersContainer />,
  },
  {
    path: '/billing',
    component: MasterConfig.billingMaintenance ? <BillingMaintenanceContainer /> : <BillingOrderContainer />,
  },
  {
    path: '/billing/success',
    component: <BillingSuccessContainer />,
  },
  {
    path: '/billing/order',
    component: <BillingOrderContainer />,
  },
  {
    path: '/billing/cancelled',
    component: <BillingOrderContainer />,
  },
  {
    path: '/billing/history',
    component: <BillingHistoryContainer />,
  },
  {
    path: '/packages',
    component: MasterConfig.billingMaintenance ? <BillingMaintenanceContainer /> : <BillingPackagesContainer />,
  },
  {
    path: '/idv-packages',
    component: MasterConfig.billingMaintenance ? <BillingMaintenanceContainer /> : <IDVPackagesContainer />,
  },
  {
    path: '/payment',
    component: MasterConfig.billingMaintenance ? <BillingMaintenanceContainer /> : <BillingPaymentContainer />,
  },
  {
    path: '/mail',
    component: <MailContainer />,
    children: [
      {
        path: ':hashId',
        component: <MailContainer />,
      },
      {
        path: 'unread',
        component: <MailContainer />,
      },
      {
        path: 'unread/:hashId',
        component: <MailContainer />,
      },

      {
        path: 'locked',
        component: <MailContainer />,
      },
      {
        path: 'locked/:hashId',
        component: <MailContainer />,
      },

      {
        path: 'unlocked',
        component: <MailContainer />,
      },
      {
        path: 'unlocked/:hashId',
        component: <MailContainer />,
      },

      {
        path: 'archive',
        component: <MailContainer />,
      },
      {
        path: 'archive/:hashId',
        component: <MailContainer />,
      },
    ],
  },
  {
    path: '/settings/*',
    component: <SettingsContainer />,
  },
  {
    path: '/settings/email-password',
    component: <SettingsContainer />,
  },
  {
    path: '/cancelled-offers',
    component: <SettingsContainer />,
  },
  {
    path: '/credits',
    component: <CreditsContainer />,
  },
  {
    path: '/deactivate',
    component: <DeactivateContainer />,
  },
  {
    path: '/gdpr-delete',
    component: <GDPRDeleteContainer />,
  },
  {
    path: '/blocked',
    component: <BlockedContainer />,
  },
  {
    path: '/biometrics',
    component: <BiometricsContainer />,
  },
  {
    path: '/report/:username',
    component: <ReportContainer />,
  },
  {
    path: '/support/contact',
    component: <SupportTicketContainer />,
  },
  {
    path: '/privacy-updated',
    component: <PrivacyPolicyGDPR />,
  },
  {
    path: '/demo',
    component: <DemoContainer />,
  },
  {
    path: '/verification',
    component: <VerificationContainer />,
  },
];
