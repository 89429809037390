import {
  DECREMENT_UNREAD_OFFERS_COUNT,
  IDecrementUnreadOffersCount,
  IIncrementUnreadOffersCount,
  INCREMENT_UNREAD_OFFERS_COUNT,
  IResetUnreadOffersCount,
  RESET_UNREAD_OFFERS_COUNT,
} from '../reducers/unreadOffersCount';

export const unreadOffersCountKey = 'wyp_unreadOffersCount';

export function resetLocalStorageValue(): void {
  localStorage.setItem(unreadOffersCountKey, '0');
}

function getUnreadCount(): number {
  const unreadOffersCountValue = localStorage.getItem(unreadOffersCountKey);
  return !unreadOffersCountValue ? 0 : parseInt(unreadOffersCountValue);
}

export function incrementLocalStorageValue(): void {
  const newCount = getUnreadCount() + 1;
  localStorage.setItem(unreadOffersCountKey, newCount.toString());
}

export function decrementLocalStorageValue(): void {
  const unreadOffersCount = getUnreadCount();

  if (unreadOffersCount < 1) return;

  const newCount = unreadOffersCount - 1;
  localStorage.setItem(unreadOffersCountKey, newCount.toString());
}

export const incrementUnreadOneTapOffersCount = (): IIncrementUnreadOffersCount => {
  incrementLocalStorageValue();

  return {
    type: INCREMENT_UNREAD_OFFERS_COUNT,
  };
};

export const decrementUnreadOneTapOffersCount = (): IDecrementUnreadOffersCount => {
  decrementLocalStorageValue();

  return {
    type: DECREMENT_UNREAD_OFFERS_COUNT,
  };
};

export const resetUnreadOneTapOffersCount = (): IResetUnreadOffersCount => {
  resetLocalStorageValue();

  return {
    type: RESET_UNREAD_OFFERS_COUNT,
  };
};
