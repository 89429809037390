import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SliderArrowRightIcon from '../../../../atoms/icons/SliderArrowRightIcon';
import SliderArrowLeftIcon from '../../../../atoms/icons/SliderArrowLeftIcon';
import SliderPlayIcon from '../../../../atoms/icons/SliderPlayIcon';
import { useDashboardDiscoverMode } from '../../../../contexts/DashboardDiscoverModeContext';
import { WYP_PRIVATE_IMG_FALLBACK } from '../../../../config/Master';
import DiscoverModePrivateIcon from '../../../../atoms/icons/DIscoverModePrivateIcon';
import { getSwipeProfilePhotoUrl } from '../../../../utils/swipe';

const SliderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
`;

const SliderContent = styled.div`
  display: flex;
  transition: transform 0.5s;
  width: ${props => props.width || '100%'}; // Set a fixed width for SliderContent
  height: 100%; // Set a fixed height for SliderContent
`;

const Slide = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 100%; // Set a fixed height for each slide
`;

const Img = styled('div')(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  ({ imgUrl }) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
);

const PrivateDetailsContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  textAlign: 'center',
  zIndex: 1,
});

const PrivateLabelCount = styled('p')({
  fontSize: 20,
  fontWeight: 900,
  color: '#fff',
  lineHeight: '20px',
  letterSpacing: '-0.4px',
  margin: 0,
  padding: 0,
});

const SliderArrowContainer = styled('div')({
  position: 'absolute',
  width: '150px',
  height: '100%',
  top: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  transform: 'translate(0px,-50%)',
  paddingTop: '22px',
  zIndex: 2,
});

const PrivateLabelInfo = styled('p')({
  fontSize: 14,
  fontWeight: 500,
  color: '#fff',
  lineHeight: '20px',
  letterSpacing: '-0.28px',
  margin: 0,
  padding: 0,
  maxWidth: 105,
});

interface DashboardDiscoverModeMediaSliderProps {
  photos: any;
  contentWidth?: string; // Add a prop to customize the width of SliderContent
  currentSlide: number;
  handleSlide: (step: number) => any;
  privateCount: number;
}

const DashboardDiscoverModeMediaSlider = ({
  photos,
  contentWidth = '100%',
  handleSlide,
  currentSlide,
  privateCount,
}: DashboardDiscoverModeMediaSliderProps): JSX.Element => {
  const { setVideoPreview } = useDashboardDiscoverMode();
  const [selectedVideo, setSelectedVideo] = useState({ type: '', thumbnail: '', media: '' });

  useEffect(() => {
    const slide = photos[currentSlide];

    if (slide.type === 'video' && slide.id) {
      setSelectedVideo({
        type: slide.type,
        thumbnail: slide.urls['thumbnail'],
        media: slide.urls['media'],
      });
    } else {
      setSelectedVideo({ type: '', thumbnail: '', media: '' });
    }
    return () => {
      setSelectedVideo({ type: '', thumbnail: '', media: '' });
    };
  }, [currentSlide]);

  return (
    <SliderContainer>
      <SliderContent width={contentWidth} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {photos.map((photo, index) => {
          const photoUrl = photo.type === 'video' ? photo.urls['thumbnail'] : getSwipeProfilePhotoUrl(photo);
          const imgUrl = !photo.id ? WYP_PRIVATE_IMG_FALLBACK : photoUrl;
          return (
            <Slide key={index}>
              <Img className="data-avatar-image-url" imgUrl={imgUrl} />
              {!photo.id && (
                <PrivateDetailsContainer>
                  <DiscoverModePrivateIcon style={{ marginBottom: 20 }} />
                  <PrivateLabelCount>{`+ ${privateCount}`}</PrivateLabelCount>
                  <PrivateLabelInfo>Private Photos</PrivateLabelInfo>
                  <PrivateLabelInfo style={{ marginTop: 20 }}>Accepted offers get access!</PrivateLabelInfo>
                </PrivateDetailsContainer>
              )}
            </Slide>
          );
        })}
      </SliderContent>
      {selectedVideo && selectedVideo.type === 'video' && selectedVideo.media && (
        <SliderPlayIcon
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -35%)',
            zIndex: 10,
          }}
          onClick={() => {
            setVideoPreview({
              thumbnail: selectedVideo.thumbnail,
              media: selectedVideo.media,
            });
          }}
        />
      )}
      {currentSlide > 0 && (
        <SliderArrowContainer
          className="pressable"
          onClick={() => handleSlide(-1)}
          style={{ left: 0, paddingLeft: '12px', justifyContent: 'flex-start' }}
        >
          <SliderArrowLeftIcon customStyle={{ pointerEvents: 'none' }} />
        </SliderArrowContainer>
      )}
      {currentSlide < photos.length - 1 && (
        <SliderArrowContainer
          className="pressable"
          onClick={() => {
            handleSlide(1);
          }}
          style={{ right: 0, paddingRight: '12px', justifyContent: 'flex-end' }}
        >
          <SliderArrowRightIcon customStyle={{ pointerEvents: 'none' }} />
        </SliderArrowContainer>
      )}
    </SliderContainer>
  );
};

export default DashboardDiscoverModeMediaSlider;
