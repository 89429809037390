import {
  STORE_GLOBAL_NOTIFICATION,
  CLEAR_GLOBAL_NOTIFICATION,
  REQUEST_GLOBAL_NOTIFICATION,
  GlobalNotificationState,
  GlobalNotificationActionType,
} from './types/GlobalNotify.d';

const initialState: GlobalNotificationState = null;

const globalNotifications = (state = initialState, action: GlobalNotificationActionType): any => {
  switch (action.type) {
    case STORE_GLOBAL_NOTIFICATION:
      return {
        ...action.payload,
        isFetching: false,
      };
    case CLEAR_GLOBAL_NOTIFICATION:
      return initialState;
    case REQUEST_GLOBAL_NOTIFICATION:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

export default globalNotifications;
