import React, { useEffect } from 'react';
import Profile from '../components/pages/AccountMigration/Profile';
import { connect } from 'react-redux';
import { refreshProfile, updateProfile } from '../actions/profileActions';
import { clearIndividualError, clearDirtyStatus, clearErrors, killLoading } from '../actions/commonActions';
import queryString from 'query-string';
import DataLayer from '../utils/dataLayer';
import _isEmpty from 'lodash/isEmpty';
import { clearNotificationMessage } from '../actions/notificationActions';
import { ThemeProvider } from '@emotion/react';
import { WYPTheme } from 'swui';

const ProfileEditContainer = props => {
  const { location, profile, account, errors } = props;

  useEffect(() => {
    const queryStringParsed = queryString.parse(location.search);
    if (queryStringParsed.userFirstTimeLogin === '1' && !_isEmpty(profile)) {
      new DataLayer().push({
        submitted_at: profile.submitted_date || null,
        completed_at: profile.completed_date || null,
        monetized_at: profile.monetized_date || null,
        gender: profile.sex,
        age: profile.age,
        availableCredits: account.credits,
        publicPhotos: account.approved_public_photos_count || 0,
        outstandingOffers: account.offer_counts.new,
        type: profile.account_type,
        looking_male: profile.looking_male,
        looking_female: profile.looking_female,
        event: 'joinComplete',
      });
    }
    props.refresh();

    return () => {
      if (typeof errors === 'object') {
        const errorKeys = Object.keys(errors);

        if (errorKeys.length > 0) {
          errorKeys.map(key => {
            props.clearIndividualError(key);
          });
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={WYPTheme}>
      <Profile {...props} />
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    account: state.profile,
    profile: state.profile.profile !== undefined ? state.profile.profile.data : null,
    errors: state.common.errors,
    isDirty: state.common.isDirty,
    mapFields: state.meta.map_fields,
    notification: state.notification,
    isAuthenticated: state.auth.isAuthenticated,
    isProcessing: state.profile.isProcessing,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refresh() {
      // get profile data.
      dispatch(clearErrors());
      dispatch(clearNotificationMessage());
      dispatch(killLoading());
      dispatch(refreshProfile());
    },
    update(formData, needsPhoto, isMigration, hashID) {
      dispatch(clearDirtyStatus());
      // update profile in API.
      dispatch(updateProfile(formData, needsPhoto, isMigration, hashID, ownProps.navigate));
    },
    clearIndividualError: fieldName => {
      dispatch(clearIndividualError(fieldName));
    },
    loadPageUrl(linkUrl) {
      dispatch(clearNotificationMessage());
      ownProps.navigate(linkUrl);
    },
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditContainer);
