import React, { useState } from 'react';
import styled from '@emotion/styled';

import { color, buttonTheme } from '../../style';
import Button from '../buttons/Button';
import Headline from '../../components/common/Headline';
import ConfirmationModal from '../../modules/modals/ConfirmationModal';
import TrashIcon from '../icons/TrashIcon';
import Avatar from '../Avatar';

const PhotoTile = styled('div')({
  position: 'relative',
  borderRadius: '3px',
  boxSizing: 'border-box',
  padding: 0,
  transition: 'border-width 200ms ease-out, transform 200ms ease-out',

  '&::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: `0px dashed ${color.BRAND_2}`,
    opacity: 0,
    borderRadius: 4,
    boxSizing: 'content-box',
  },
});

const PhotoContainer = styled('div')({
  display: 'block',
  overflow: 'hidden',
  paddingBottom: '100%',
  position: 'relative',
  borderRadius: 3,
  transition: 'opacity 200ms',
});

const PhotoStatusContainer = styled('div')({
  padding: '4px 8px',
  backgroundColor: 'rgba(0,0,0,0.6)',
});

const PhotoStatus = styled('div')({
  padding: '4px',
  fontSize: '14px',
  userSelect: 'none',
});

const OptionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  top: '100%',
  width: '100%',
  padding: '6px 0',
  boxSizing: 'border-box',
});

const StatusSlot = styled('div')({
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  color: '#fff',
  zIndex: '100',
  width: '100%',
  fontSize: 12.5,
});

const StyledAvatar = styled(Avatar)({
  height: '100%',
  width: '100%',
  position: 'absolute',
  left: '0',
  top: '0',
  transition: 'padding .25s',
  boxSizing: 'border-box',
  WebkitUserDrag: 'none',
  KhtmlUserDrag: 'none',
  MozUserDrag: 'none',
  OUserDrag: 'none',
  userDrag: 'none',
});

const DeleteLastPhotoTitle = styled('div')({
  textAlign: 'center',
});

const DeleteLastPhotoHeadline = styled(Headline)({
  fontSize: '20px',
});

const DeleteLastPhotoDescription = styled('div')({
  margin: '16px 0 24px',
  textAlign: 'center',
});

interface UserphotoProps {
  imgUrl: string;
  isPending: boolean;
  photoId: number;
  isAvatar: boolean;
  isPrivate: boolean;
  theKey: number;
  hasPublicPhotos: boolean;
  hasPublicApprovedPhotos: boolean;
  singlePublicLeft: boolean;
  isFetching: boolean;
  setPrivacy(photoId: string, key: number): void;
  makeDefault(photoId: string, key: number): void;
  deletePhoto(photoId: string, key: number): void;
}

const UserPhoto = ({
  imgUrl,
  isPending,
  photoId,
  isAvatar,
  isPrivate,
  theKey,
  hasPublicPhotos,
  hasPublicApprovedPhotos,
  singlePublicLeft,
  isFetching,
  setPrivacy,
  makeDefault,
  deletePhoto,
}: UserphotoProps): JSX.Element => {
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState(false);

  const toggleDeletePhotoModal = () => {
    setDeletePhotoModalOpen(!deletePhotoModalOpen);
  };

  const photoOptions = () => {
    const key = theKey;
    const btnDisabled = (isPending && hasPublicApprovedPhotos) || isAvatar || (isPrivate && hasPublicPhotos);
    return (
      <OptionsContainer>
        <div>
          <Button
            disabled={isFetching}
            size="small"
            customStyle={{
              backgroundColor: buttonTheme.GreyAndWhite,
              marginRight: 4,
              color: buttonTheme.DarkButton,
            }}
            onClick={e => {
              e.preventDefault();
              setPrivacy(photoId, key);
            }}
          >
            {`${isPrivate ? 'Set Public' : 'Set Private'}`}
          </Button>

          {isAvatar ? null : (
            <Button
              size="small"
              customStyle={{
                backgroundColor: buttonTheme.GreyAndWhite,
                marginRight: 4,
                color: btnDisabled ? 'rgba(0, 0, 0, 0.3)' : buttonTheme.DarkButton,
              }}
              disabled={btnDisabled}
              onClick={e => {
                e.preventDefault();
                makeDefault(photoId, key);
              }}
            >
              Set Profile
            </Button>
          )}
        </div>

        <Button
          size="small"
          icon={<TrashIcon />}
          customStyle={{
            backgroundColor: '#fff',
            padding: 0,
            width: 30,
          }}
          onClick={toggleDeletePhotoModal}
        />

        {!isPrivate && singlePublicLeft ? (
          <ConfirmationModal
            contentLabel="Delete Photo Modal"
            isOpen={deletePhotoModalOpen}
            cancelText="Cancel"
            confirmText="Delete"
            onClose={toggleDeletePhotoModal}
            onConfirm={() => deletePhoto(photoId, key)}
            buttonPosition="center"
          >
            <DeleteLastPhotoTitle>
              <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#1F85CF"
                    fillRule="nonzero"
                    d="M63.61 59.839L4.161.39a1.334 1.334 0 0 0-1.886 0L.39 2.276c-.521.52-.521 1.365 0 1.885l11.026 11.026A34.267 34.267 0 0 0 .064 31.593a1.34 1.34 0 0 0 0 .815C4.599 46.52 17.434 56 32 56a32.951 32.951 0 0 0 16.068-4.16l11.77 11.77c.521.52 1.365.52 1.886 0l1.885-1.886c.521-.52.521-1.365 0-1.885zM32 50.667c-10.293 0-18.667-8.374-18.667-18.667 0-4.052 1.333-7.96 3.733-11.164l5.797 5.798c-.96 1.61-1.53 3.43-1.53 5.366 0 5.882 4.786 10.667 10.667 10.667 1.936 0 3.756-.57 5.367-1.53l5.796 5.797A18.622 18.622 0 0 1 32 50.667zM30.714 22.162a1.354 1.354 0 0 0 .29 1.48l9.354 9.353a1.352 1.352 0 0 0 2.309-.972c-.068-5.928-4.763-10.622-10.69-10.69-.539.058-1.049.321-1.263.829z"
                  />
                  <path
                    fill="#1F85CF"
                    fillRule="nonzero"
                    d="M22.472 15.163c.4.395 1.01.496 1.518.257a18.666 18.666 0 0 1 8.026-1.812c10.288 0 18.657 8.258 18.657 18.41 0 2.742-.618 5.407-1.836 7.919a1.303 1.303 0 0 0 .26 1.497l4.62 4.558c.249.246.587.385.941.385a1.35 1.35 0 0 0 .948-.39 34.19 34.19 0 0 0 8.33-13.568c.085-.26.085-.543 0-.804-4.532-13.917-17.36-23.267-31.92-23.267-3.996 0-7.922.7-11.672 2.085a1.32 1.32 0 0 0-.835.953c-.097.437.04.893.36 1.209l2.603 2.568z"
                  />
                  <path d="M0 0h64v64H0z" />
                </g>
              </svg>
              <DeleteLastPhotoHeadline>Are you sure you want to delete this photo?</DeleteLastPhotoHeadline>
            </DeleteLastPhotoTitle>
            <DeleteLastPhotoDescription>
              If you have no public photos, you won&apos;t be seen in search and people and are less likely to interact
              with you.
            </DeleteLastPhotoDescription>
          </ConfirmationModal>
        ) : (
          <ConfirmationModal
            contentLabel="Delete Photo Modal"
            isOpen={deletePhotoModalOpen}
            header="Delete Photo"
            description="Are you sure? Deleting this picture will remove it from your profile."
            cancelText="Cancel"
            confirmText="Delete"
            onClose={toggleDeletePhotoModal}
            onConfirm={() => deletePhoto(photoId, key)}
          />
        )}
      </OptionsContainer>
    );
  };

  const renderStatusText = () => {
    const isProfile = isAvatar ? 'Profile' : '';
    const isPendingApproval = isPending ? 'Pending approval' : '';

    return (
      <PhotoStatusContainer>
        {isProfile ? <PhotoStatus>{isProfile}</PhotoStatus> : null}
        {isPendingApproval ? <PhotoStatus>{isPendingApproval}</PhotoStatus> : null}
      </PhotoStatusContainer>
    );
  };

  return (
    <div>
      <PhotoTile>
        <PhotoContainer>
          {(isAvatar || isPending) && <StatusSlot>{renderStatusText()}</StatusSlot>}
          <StyledAvatar src={imgUrl} alt="thumbnail" />
        </PhotoContainer>
      </PhotoTile>
      {photoOptions()}
    </div>
  );
};

UserPhoto.defaultProps = {
  isAvatar: false,
};

export default UserPhoto;
