import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';

import Avatar from '../../../atoms/Avatar';
import { dimensions } from '../../../style';
import { displayUserLocation, getOfferType, renderAvatar } from '../../../common';
import ConfirmationModal from '../../../modules/modals/ConfirmationModal';
import TextArea from '../../../atoms/TextArea';
import OfferListItem from '../../../atoms/offers/OfferListItem';

const AvatarContainer = styled('div')`
  display: none;

  ${dimensions.SCREEN_MIN_XS} {
    display: block;
    width: 96px;
    height: 96px;
    margin-right: 16px;
    flex-shrink: 0;
  }
`;
interface OfferProps {
  index: any;
  offer: any;
  profile: any;
  ignoreOffer: any;
  acceptOffer: any;
  counterOffer: any;
  checkProfile: any;
  getNextOfferQueue: any;
}

const Offer = ({
  index,
  offer,
  profile,
  ignoreOffer,
  acceptOffer,
  counterOffer,
  checkProfile,
  getNextOfferQueue,
}: OfferProps): ReactElement => {
  const [ignoreOpen, setIgnoreOpen] = useState(false);
  const [dealMessage, setDealMessage] = useState('');
  const [acceptOfferModalOpen, setAcceptOfferModalOpen] = useState(false);
  const [cancelOfferModalOpen, setCancelOfferModalOpen] = useState(false);

  const acceptAttractive = (hashId, message) => {
    // close modal and accept offer.
    acceptOffer(hashId, message);
  };

  const changeMessage = e => {
    setDealMessage(e.target.value);
  };

  const toggleIgnoreModal = () => {
    setIgnoreOpen(!ignoreOpen);
  };

  const toggleCancelOfferModal = () => {
    setCancelOfferModalOpen(!cancelOfferModalOpen);
  };

  const toggleAcceptOfferModal = () => {
    setAcceptOfferModalOpen(!acceptOfferModalOpen);
  };

  const accountData = offer.other_account.data;
  const otherProfile = accountData.profile.data;
  const avatar = renderAvatar(otherProfile.avatar.data.urls);
  const counterDisabled = offer.min_counter_price === offer.max_counter_price;
  const isGenerous = otherProfile.account_type === 'Attractive';
  const offerType = getOfferType(offer, profile);

  // only country of user in needed to format location
  const userLocation = { country: profile.country };
  const offerLocation = {
    country: otherProfile.country,
    region: otherProfile.region,
    city: otherProfile.city,
  };
  const location = displayUserLocation(userLocation, offerLocation);

  return (
    <React.Fragment>
      <OfferListItem
        index={index}
        currentUserIsGenerous={profile.profile.data.account_type === 'Generous'}
        offerType={offerType}
        accountHashId={accountData.hash_id}
        avatar={avatar}
        username={accountData.username}
        date={offer.updated_at.date}
        age={otherProfile.age}
        location={location}
        currency={{
          initial: offer.currency_original.data.initial,
          label: offer.currency_original.data.label_unicode,
        }}
        price={offer.price_original}
        ignoreOffer={() => {
          ignoreOffer(offer);
        }}
        acceptOffer={isGenerous ? acceptOffer : toggleAcceptOfferModal}
        counterOffer={counterOffer}
        counterOfferDisabled={counterDisabled}
        cancelSuggestion={() => {
          ignoreOffer(offer);
        }}
        cancelOffer={toggleCancelOfferModal}
        checkProfile={checkProfile}
        getNextOfferQueue={getNextOfferQueue}
      />

      {/* Modals */}

      {/*  Accept Offer Modal - ONLY ATTRACTIVE */}
      <ConfirmationModal
        contentLabel="Accept Offer Modal"
        isOpen={acceptOfferModalOpen}
        cancelText="Cancel"
        confirmText="Accept and Send"
        onClose={toggleAcceptOfferModal}
        onConfirm={() => acceptAttractive(accountData.hash_id, dealMessage)}
        buttonPosition="center"
      >
        <AvatarContainer style={{ margin: '0 auto', display: 'block', width: '96px' }}>
          <Avatar src={avatar} />
        </AvatarContainer>
        <p style={{ color: '#4396d7', fontWeight: 'bold', textAlign: 'center' }}>
          Send {accountData.username} a message to accept their offer.
        </p>
        <TextArea
          rows={6}
          onChange={changeMessage}
          value={dealMessage}
          placeholder="Introduce yourself and tell them what you'd like to do on your first date!"
          resize="vertical"
          customInputStyle={{ fontSize: '16px' }}
        />
      </ConfirmationModal>
      <ConfirmationModal
        contentLabel="Ignore Offer Modal"
        header={`Ignore ${isGenerous ? 'Request' : 'Offer'}`}
        description={`Are you sure you want to ignore this ${isGenerous ? 'request' : 'offer'}?`}
        cancelText="Cancel"
        confirmText="Ignore"
        onConfirm={() => {
          ignoreOffer(offer);
        }}
        isOpen={ignoreOpen}
        onClose={toggleIgnoreModal}
      />
      <ConfirmationModal
        contentLabel="Cancel Offer Modal"
        header={`Cancel ${isGenerous ? 'Offer' : 'Request'}`}
        description={`Are you sure you want to cancel this ${isGenerous ? 'offer' : 'request'}?`}
        cancelText="Nevermind"
        confirmText={`Cancel ${isGenerous ? 'Offer' : 'Request'}`}
        onConfirm={() => {
          ignoreOffer(offer);
        }}
        onClose={toggleCancelOfferModal}
        isOpen={cancelOfferModalOpen}
      />
    </React.Fragment>
  );
};

export default Offer;
