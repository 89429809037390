import React, { ReactElement } from 'react';

interface MessageBubbleProps {
  width: number;
  height: number;
  color: string;
}

const MessageBubble = ({ width = 96, height = 96, color = '#7EBBE5' }: MessageBubbleProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 96 96">
      <path
        fill={color}
        fillRule="evenodd"
        d="M162.673469,56 C162.673469,58.6040816 160.542857,60.7346939 157.938776,60.7346939 L110.591837,60.7346939 L91.6530612,79.6734694 L91.6530612,13.3877551 C91.6530612,10.7836735 93.7836735,8.65306122 96.3877551,8.65306122 L157.938776,8.65306122 C160.542857,8.65306122 162.673469,10.7836735 162.673469,13.3877551 L162.673469,56 Z M172.142857,27.5918367 L181.612245,27.5918367 C184.216327,27.5918367 186.346939,29.722449 186.346939,32.3265306 L186.346939,103.346939 L167.408163,84.4081633 L115.326531,84.4081633 C112.722449,84.4081633 110.591837,82.277551 110.591837,79.6734694 L110.591837,70.2040816 L164.142857,70.2040816 C168.561135,70.2040816 172.142857,66.6223596 172.142857,62.2040816 L172.142857,27.5918367 Z"
        transform="translate(-91 -8)"
      />
    </svg>
  );
};

export default MessageBubble;
