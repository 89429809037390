import { useEffect } from 'react';

export const useOnClickTriggerTracking = (eventType: string, ref: any, actionMethod: any): any => {
  useEffect(() => {
    /**
     * handle if user clicked
     */
    const handleOnClick = event => {
      if (ref.current && ref.current.contains(event.target)) {
        actionMethod(event, eventType);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleOnClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOnClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useOnClickTriggerTracking;
