import React, { ReactElement } from 'react';

interface AddContentIconProps {
  cssStyle?: any;
  onBoarding?: any;
}

const ProfileInfo = ({ cssStyle = {}, onBoarding = '#8F8F8F' }: AddContentIconProps): ReactElement => {
  return (
    <svg width="53" height="25" viewBox="0 0 53 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={cssStyle}>
      <path
        d="M3.20876 25H21.9037C23.6746 25 25.1073 23.5755 25.1073 21.8117V3.19089C25.1073 1.4296 23.6746 0 21.9037 0H3.20876C1.43787 0 0 1.4296 0 3.19089V21.8117C0 23.5755 1.43787 25 3.20876 25ZM12.5562 3.23717C15.1816 3.23717 17.3113 5.36357 17.3113 7.97593C17.3113 10.5883 15.1816 12.7173 12.5562 12.7173C9.93088 12.7173 7.79601 10.596 7.79601 7.97593C7.79601 5.35586 9.9283 3.23717 12.5562 3.23717ZM19.7456 17.7903V21.7628H5.35911V17.7903C5.35911 15.8156 6.96995 14.2086 8.95767 14.2086H16.1522C18.1348 14.2086 19.7456 15.8156 19.7456 17.7903Z"
        fill={onBoarding}
      />
      <path
        d="M31.4396 19.1839H45.2711C45.9474 19.1839 46.4921 18.6414 46.4921 17.9677C46.4921 17.294 45.9474 16.7541 45.2711 16.7541H31.4396C30.7684 16.7541 30.2186 17.2992 30.2186 17.9677C30.2186 18.6362 30.7684 19.1839 31.4396 19.1839Z"
        fill={onBoarding}
      />
      <path
        d="M31.4396 13.7149H51.7815C52.4553 13.7149 53 13.1724 53 12.4987C53 11.8251 52.4553 11.2851 51.7815 11.2851H31.4396C30.7684 11.2851 30.2186 11.8276 30.2186 12.4987C30.2186 13.1698 30.7684 13.7149 31.4396 13.7149Z"
        fill={onBoarding}
      />
      <path
        d="M31.4396 8.24597H51.7815C52.4553 8.24597 53 7.70344 53 7.03235C53 6.36126 52.4553 5.81616 51.7815 5.81616H31.4396C30.7684 5.81616 30.2186 6.36126 30.2186 7.03235C30.2186 7.70344 30.7684 8.24597 31.4396 8.24597Z"
        fill={onBoarding}
      />
    </svg>
  );
};

export default ProfileInfo;
