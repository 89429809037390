import { vysionService } from '../utils/vysion/vysionService';
import { convertTitleCase, convertSentanceTitleCase } from '../utils/helpers';

const setPurchaseAction = (eventType: any): any => {
  return {
    type: 'PURCHASES',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: setEventPage(eventType),
        extraInfo: splitEventName(eventType)[2] === 'UNLOCK' ? convertTitleCase(setCreditStatus(eventType)) : null,
        component:
          setComponent(eventType) === 'BUY CREDITS'
            ? convertSentanceTitleCase(setComponent(eventType))
            : convertTitleCase(setComponent(eventType)),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setComponent = (eventName: any): any => {
  switch (getKeyWord(eventName)) {
    case 'NAVBAR': {
      const currEventName = splitEventName(eventName);
      currEventName.splice(2, 1);
      return currEventName.join(' ');
    }
    case 'ACCTMENU': {
      const currEventName = splitEventName(eventName);
      currEventName.splice(2, 1);
      return currEventName.join(' ');
    }
    case 'UNLOCK': {
      const currEventName = splitEventName(eventName);
      currEventName.splice(0, 2);
      return currEventName.join('');
    }
    case 'CREDITS': {
      const currEventName = splitEventName(eventName);
      currEventName.splice(0, 1);
      return currEventName.join(' ');
    }
    default:
      return;
  }
};

const setCreditStatus = (eventName: any): any => {
  const currEventName = splitEventName(eventName);
  currEventName.splice(2, 1);
  return currEventName.join(' ');
};

const splitEventName = eventName => eventName.split(' ');

const getKeyWord = eventName => splitEventName(eventName)[2];

const setEventPage = eventName => {
  switch (getKeyWord(eventName)) {
    case 'NAVBAR':
      return 'Nav Bar';
    case 'ACCTMENU':
      return 'Account Menu';
    case 'UNLOCK':
      return 'Messages';
    case 'CREDITS':
      return 'Packages';
    default:
      return;
  }
};

const sendPurchaseNavBuyCreditAction = (): any => {
  vysionService(setPurchaseAction('BUY CREDITS NAVBAR'));
};

const sendPurchaseAccountMenuBuyCreditAction = (): any => {
  vysionService(setPurchaseAction('BUY CREDITS ACCTMENU'));
};

const sendSufficientCredsUnlockAction = (): any => {
  vysionService(setPurchaseAction('SUFFICIENT CREDITS UNLOCK'));
};

const sendInsufficientCredsUnlockAction = (): any => {
  vysionService(setPurchaseAction('INSUFFICIENT CREDITS UNLOCK'));
};

const sendBuyCredits = (event: any, type: any): any => {
  vysionService(setPurchaseAction(`BUY ${type} CREDITS`));
};

export {
  sendPurchaseNavBuyCreditAction,
  sendPurchaseAccountMenuBuyCreditAction,
  sendSufficientCredsUnlockAction,
  sendInsufficientCredsUnlockAction,
  sendBuyCredits,
};
