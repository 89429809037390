import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { dimensions, iconsTheme, patternModal, completionTheme } from '../../style';
import { connect } from 'react-redux';
import { resendEmail } from '../../actions/authActions';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import ProfileCamera from '../../atoms/icons/ProfileCamera';
import ProfileEmail from '../../atoms/icons/ProfileEmail';
import ProfileInfo from '../../atoms/icons/ProfileInfo';
import { profileAllAboutFields, profilePercentage } from '../../utils/helpers';
import { ProgressBar } from 'swui';
import EmailConfirmV2 from '../../modules/modals/EmailConfirmV2';
import ProfilePercentDot from '../../atoms/icons/ProfilePercentDot';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../../contexts/ProfileContext';
import { useApp } from '../../contexts/AppContext';

const Container = styled('div')({
  padding: 0,
  margin: '0 auto',
  [dimensions.SCREEN_MAX_MD]: {
    padding: 0,
    marginBottom: ({ spacedBottom }) => (spacedBottom ? 22 : 0),
  },
  [dimensions.SCREEN_MIN_MD]: {
    marginTop: ({ screenTop }) => (screenTop ? 22 : 0),
    marginBottom: ({ screenTop }) => (screenTop ? 22 : 0),
  },
  [dimensions.SCREEN_MAX_XS_2]: {
    marginTop: 15,
  },
});

const InfoContainer = styled('div')({
  padding: 15,
  borderRadius: 5,
  display: 'block',
  backgroundColor: completionTheme.containerBG,
  border: completionTheme.containerBG,
  marginBottom: 0,
  [dimensions.SCREEN_MAX_MD_2]: {
    marginBottom: 0,
  },
});

const InfoHeader = styled('div')`
  text-align: center;
  font-weight: 600;
  max-width: 1070px;
  margin: 0 auto;
  color: #2b8fd7;
  font-size: 16px;
`;

const InfoSubHeader = styled('div')`
  text-align: center;
  max-width: 1070px;
  margin: 10px auto;
`;

const IconContainer = styled('div')({
  height: 40,
});

const CheckIconContainer = styled('div')({
  position: 'absolute',
  right: '-10px',
  top: '-10px',
  background: completionTheme.itemBG,
  borderRadius: '50%',
  height: 26,
  padding: 2,
});

const InfoItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 1070px;
  margin: 0 auto;
`;

const InfoItem = styled('div')({
  padding: '5px 0',
  width: '30%',
  background: completionTheme.itemBG,
  height: 80,
  borderRadius: 6,
  margin: '1.5%',
  textAlign: 'center',
  position: 'relative',
  maxWidth: 90,
});

const InfoItemText = styled('div')`
  font-size: 12px;
`;

const InfoItemDescription = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  margin: '1px 0',
});

const PercentageLabel = styled('div')({
  fontSize: 12,
  color: patternModal.cancelBlue,
  position: 'relative',
  height: 20,
});

const determineColor = status => {
  switch (status) {
    case 'complete':
    case 'approved':
      return '#098a00';
    case 'denied':
    case 'error':
      return '#BC2028';
    case 'not submitted':
    case 'not_started':
    default:
      return iconsTheme.profileCompleteness;
  }
};

const commonStyles = status => {
  return {
    fontSize: 26,
    color: determineColor(status),
  };
};

const CompletProfileBanner = ({ onboarding, resendReminderEmail }) => {
  const { footerTopPosition } = useApp();
  const { offerBoxContainerRef, calculateGalleryMediaRatio } = useProfile();
  const [openStatus, setOpenStatus] = useState(false);
  const navigate = useNavigate();

  const handleResendEmail = async () => {
    const res = await resendReminderEmail();
    setOpenStatus(res);
  };

  const handleToggleOpenStatus = status => {
    const fullUrl = window.location.href.split('?');
    const profileId = fullUrl[0].split('/').pop();
    navigate(`/profile/${profileId}`);
    setOpenStatus(status);
  };

  const renderEmailResend = () => {
    const email = onboarding.profile && onboarding.profile.email;
    return (
      <EmailConfirmV2
        email={email}
        openStatus={openStatus}
        handleOnClick={handleResendEmail}
        handleToggleOpenStatus={handleToggleOpenStatus}
        variant="completionProgress"
      />
    );
  };

  const renderEmailVerify = isVerified => {
    return (
      <InfoItem data-test-id="profile-verify-email">
        {isVerified && (
          <CheckIconContainer>
            <SvgIcon id="CheckCircleRounded" component={CheckCircleRounded} style={commonStyles('complete')} />
          </CheckIconContainer>
        )}
        <InfoItemText>
          <InfoItemDescription style={{ color: '#8f8f8f' }}>
            {isVerified ? <span>&nbsp;</span> : 'Verify Email'}
          </InfoItemDescription>
        </InfoItemText>
        <IconContainer>
          <ProfileEmail cssStyle={{ marginTop: isVerified ? 0 : 10 }} onBoarding={isVerified ? '#2B8FD7' : '#8F8F8F'} />
        </IconContainer>
        {isVerified ? (
          <InfoItemText>
            <InfoItemDescription style={{ color: '#2B8FD7' }}>Verify Email</InfoItemDescription>
          </InfoItemText>
        ) : (
          renderEmailResend()
        )}
      </InfoItem>
    );
  };

  const setPhotoStatus = onboarding => {
    const { photoApproved, hasPhoto, photoFinished, profile, publicPhotos, publicVideos } = onboarding;

    switch (true) {
      case Boolean(!photoApproved && hasPhoto && publicPhotos > publicVideos && publicPhotos >= 1):
        return 'pending';
      case photoApproved && publicPhotos > publicVideos:
        return 'approved';
      case photoFinished && !photoApproved && profile.photo.data.length <= 0 && profile.photo_complete:
        return 'denied';
      default:
        return null;
    }
  };

  const renderPhotoVerify = onboarding => {
    const photoStatus = setPhotoStatus(onboarding);
    const isVerified = photoStatus === 'approved';
    const isDenied = photoStatus === 'denied';

    return (
      <InfoItem data-test-id="profile-upload-photo">
        {photoStatus && (
          <CheckIconContainer>
            {photoStatus === 'denied' ? (
              <SvgIcon id="CheckCloseRounded" component={CancelRounded} style={commonStyles(photoStatus)} />
            ) : (
              <SvgIcon id="CheckCircleRounded" component={CheckCircleRounded} style={commonStyles(photoStatus)} />
            )}
          </CheckIconContainer>
        )}
        <InfoItemText>
          <InfoItemDescription>&nbsp;</InfoItemDescription>
        </InfoItemText>
        <IconContainer style={isDenied ? { height: 35 } : {}}>
          <ProfileCamera onBoarding={isVerified ? '#2B8FD7' : determineColor(photoStatus)} />
        </IconContainer>
        <InfoItemText>
          <InfoItemDescription style={{ color: isVerified ? '#2B8FD7' : determineColor(photoStatus) }}>
            Public Photo{isDenied ? ' Denied' : ''}
          </InfoItemDescription>
        </InfoItemText>
      </InfoItem>
    );
  };

  const renderProfileInfoVerify = isVerified => {
    return (
      <InfoItem data-test-id="profile-complete-info">
        {isVerified && (
          <CheckIconContainer>
            <SvgIcon id="CheckCircleRounded" component={CheckCircleRounded} style={commonStyles('complete')} />
          </CheckIconContainer>
        )}
        <InfoItemText>
          <InfoItemDescription>&nbsp;</InfoItemDescription>
        </InfoItemText>
        <IconContainer>
          <ProfileInfo onBoarding={isVerified ? '#2B8FD7' : '#8F8F8F'} />
        </IconContainer>
        <InfoItemText>
          <InfoItemDescription style={{ color: isVerified ? '#2B8FD7' : '#8f8f8f' }}>Profile Info</InfoItemDescription>
        </InfoItemText>
      </InfoItem>
    );
  };

  const renderPercentageCalc = () => {
    const percentageCount = profilePercentage(onboarding);
    return (
      <InfoSubHeader>
        <ProgressBar percent={percentageCount} />
        <PercentageLabel>
          <span
            style={{
              position: 'absolute',
              top: '-15px',
              left:
                percentageCount === 100 ? '95%' : `${percentageCount !== 0 ? percentageCount - 4 : percentageCount}%`,
            }}
          >
            <ProfilePercentDot />
            <br />
            {percentageCount}%
          </span>
        </PercentageLabel>
      </InfoSubHeader>
    );
  };

  useEffect(() => {
    if (onboarding) {
      calculateGalleryMediaRatio();
    }
  }, [onboarding, footerTopPosition]);

  return (
    <Container data-test-id="completion-banner">
      <InfoContainer data-test-id="info-container" ref={offerBoxContainerRef}>
        <InfoHeader>Profile Completion Progress</InfoHeader>
        <InfoSubHeader>Complete profiles get seen more and get more attention!</InfoSubHeader>
        {renderPercentageCalc()}
        <InfoItemContainer data-test-id="profile-completion-banner">
          {renderEmailVerify(onboarding.emailConfirmed)}
          {renderPhotoVerify(onboarding)}
          {renderProfileInfoVerify(
            onboarding.profileFinished ||
              profileAllAboutFields({ profile: onboarding.profile, isIncludeRequiredFields: true })
          )}
        </InfoItemContainer>
      </InfoContainer>
    </Container>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    onboarding: {
      profile,
      emailConfirmed: profile && profile.email_confirmed,
      profileApproved: profile && profile.is_approved,
      profileFinished: profile && profile.required_fields_completed,
      photoFinished: profile && profile.photo_complete,
      verificationStatus: profile && profile.id_verification?.data.status,
      photoApproved:
        profile &&
        Boolean(profile.photo && profile.photo.data[0] && profile.photo.data[0].status === 'approved') &&
        profile.photo.data.filter(photo => photo.private === false).length >= 1,
      hasPhoto: profile && profile.photo && profile.photo.data.length,
      publicPhotos: profile && profile.photo && profile.photo.data.filter(photo => photo.private === false).length,
      publicVideos: profile && profile.video && profile.video.data.filter(vid => vid.private === false).length,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resendReminderEmail() {
      return dispatch(resendEmail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletProfileBanner);
