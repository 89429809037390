import React, { ReactElement, useRef, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import 'react-image-crop/dist/ReactCrop.css';
import { dimensions } from '../../style';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';
import { isSafari } from 'react-device-detect';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import GalleryUploadMobileActionButtonContainer from './GalleryUpload/components/GalleryUploadMobileActionButtonContainer';

const BottomContent = styled('div')({
  padding: '20px 5px',
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const PhotoContainer = styled('div')({
  overflow: 'hidden',
  padding: 0,
  height: getBrowserDetails().hImgCalibration,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  maxHeight: 390,
  backgroundColor: isDarkMode() ? '#113956' : '#7F7F7F',
  borderRadius: 6,
  marginTop: 31,
  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
    marginTop: 18,
  },
});

const UnSupportedPreview = styled('div')({
  color: '#fff',
  textAlign: 'center',
  width: '100%',
  '& h4': {
    margin: 0,
    marginBottom: 8,
  },
  '& p': {
    margin: 0,
    marginBottom: 8,
  },
});

const VideoPlayer = styled('video')({
  height: '100%',
  margin: '0 auto',
  maxWidth: 670,
  [dimensions.SCREEN_MAX_MD_2]: {
    maxWidth: '100%',
  },
});

interface VideoPreviewProps {
  videoFile: any;
  onClosePreview: () => void;
}

const VideoPreview = ({ videoFile, onClosePreview }: VideoPreviewProps): ReactElement => {
  const previewVideoRef = useRef();

  const isUnSupportedVideo = useCallback(() => {
    if (videoFile && videoFile.type === 'video/quicktime' && !isSafari) {
      return true;
    }

    return false;
  }, [videoFile]);

  useEffect(() => {
    if (videoFile && previewVideoRef && previewVideoRef.current && !isUnSupportedVideo()) {
      previewVideoRef.current.load();
    }
  }, [videoFile, isUnSupportedVideo]);

  // @TODO Add checking if encounter issue rendering video, MOV is fully supported only in Safari browser
  return (
    <React.Fragment>
      <PhotoContainer data-test-id="upload-video-preview">
        {videoFile && isUnSupportedVideo() ? (
          <UnSupportedPreview>
            <h4>{`We're sorry. Your browser does not support previewing this video.`}</h4>
            <p>Please check your Gallery again after your video has processed.</p>
            <div>{videoFile.name}</div>
          </UnSupportedPreview>
        ) : (
          <VideoPlayer controls ref={previewVideoRef} preload="none" autoPlay>
            <source src={URL.createObjectURL(videoFile)} />
          </VideoPlayer>
        )}
      </PhotoContainer>

      <BottomContent>
        <PrimaryButton
          color="blue"
          size="small"
          data-test-id="close-preview"
          onClick={onClosePreview}
          label="Close Preview"
        />
      </BottomContent>

      <GalleryUploadMobileActionButtonContainer
        shouldShowGradient={true}
        onCancel={onClosePreview}
        customStyle={{ marginTop: -45 }}
      >
        <PrimaryButton
          size="small"
          color="blue"
          label="Close Preview"
          onClick={onClosePreview}
          data-test-id="close-preview"
          customStyle={{ width: 'inherit' }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </React.Fragment>
  );
};

VideoPreview.defaultProps = {
  videoFile: 'blob',
};

export default VideoPreview;
