import React from 'react';
import styled from '@emotion/styled';
import WYPBannerIcon from './icons/WYPBannerIcon';
import FDBannerIcon from './icons/FDBannerIcon';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import { isDarkMode } from '../../utils/helpers';
import { isFD, SITE_NAME } from '../../config/Master';
import { useApp } from '../../contexts/AppContext';
import { trackPWaMixpanelEventInstalled } from '../../utils/pwa';

const PWASidebarContainer = styled('div')({
  marginLeft: 22,
  marginRight: 22,
  marginTop: 20,
});

const BannerLogoActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 20,
});

const SidebarBannerTitle = styled('p')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '18px',
  color: isDarkMode() ? '#CFCFCF' : '#333',
  padding: 0,
  margin: 0,
  letterSpacing: '-0.16px',
});

const BannerDescription = styled('p')({
  fontSize: 14,
  fontWeight: 400,
  color: isDarkMode() ? 'AFB0B1' : '#595959',
  padding: 0,
  margin: 0,
  marginTop: 10,
  lineHeight: '19px',
  letterSpacing: '-0.14px',
});

interface PWASidebarBannerContentProps {
  onClick?: any;
}

const PWASidebarBannerContent: React.FC<PWASidebarBannerContentProps> = ({ onClick }) => {
  const { pwaBannerState } = useApp();
  return (
    <PWASidebarContainer>
      <SidebarBannerTitle>{`Install ${SITE_NAME} on your device!`} </SidebarBannerTitle>
      <BannerDescription>
        {isFD
          ? 'Get instant notifications for Date Offers and messages, and a faster load time!'
          : 'Get instant notifications for WYP Date Offers and messages, and a faster load time!'}
      </BannerDescription>
      <BannerLogoActions>
        {isFD ? (
          <FDBannerIcon customStyle={{ width: 66, height: 66 }} />
        ) : (
          <WYPBannerIcon customStyle={{ width: 66, height: 66 }} />
        )}
        <PrimaryButton
          customStyle={{ width: 'inherit' }}
          data-test-id="pwa-banner-install"
          id="install"
          onClick={() => {
            onClick();
            trackPWaMixpanelEventInstalled(pwaBannerState.source);
          }}
          label="Install the App"
          size="small"
          color="blue"
        />
      </BannerLogoActions>
    </PWASidebarContainer>
  );
};

export default PWASidebarBannerContent;
