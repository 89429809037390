import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { statusColor } from '../../../style';
import { ternaryFunc } from '../../../utils/helpers';

const NotificationCount = styled('span')({
  position: 'absolute',
  left: '50%', //offset by padding
  top: -4,
  fontSize: 13,
  color: '#fff',
  backgroundColor: statusColor.notification,
  lineHeight: '14px',
  minWidth: 14,
  textAlign: 'center',
  borderRadius: 16,
  padding: 4,
  fontWeight: 600,
  border: `2px solid ${statusColor.notification}`,
});

const IconPlaceholder = styled('div')({
  position: 'relative',
});

const NavLink = styled(Link)(
  {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    textAlign: 'center',
    padding: '6px 5px 6px 5px',
    width: '63px !important',
  },
  ({ active, dark }) => ({
    backgroundColor:
      active === 'true'
        ? ternaryFunc(dark === 'true', 'rgba(255, 255, 255, 0.07)', 'rgba(0, 0, 0, 0.05)')
        : 'transparent',
  })
);

interface FooterNavLinkProps {
  to: any;
  footerRef: any;
  currentPath: any;
  activePathPattern: any;
  iconComponent: any;
  activeIconComponent: any;
  notification: any;
  isDarkMode: boolean;
}

const FooterNavLink = ({
  to,
  footerRef,
  currentPath,
  activePathPattern,
  iconComponent,
  activeIconComponent,
  notification,
  isDarkMode = false,
}: FooterNavLinkProps): ReactElement => {
  let Icon;
  let isActive = false;
  if (iconComponent) {
    if (activePathPattern.test(currentPath)) {
      Icon = activeIconComponent;
      isActive = true;
    } else {
      Icon = iconComponent;
    }
  }

  return (
    <NavLink ref={footerRef} to={to} active={isActive.toString()} dark={isDarkMode.toString()}>
      <IconPlaceholder>
        {notification > 0 && <NotificationCount>{notification > 99 ? '99+' : notification}</NotificationCount>}
        <Icon />
      </IconPlaceholder>
    </NavLink>
  );
};

export default FooterNavLink;
