import React, { memo, useMemo, useState } from 'react';
import ModalWithPattern from './ModalWithPattern';
import styled from '@emotion/styled';
import { isDarkMode, IsMobileViewPort } from '../../../utils/helpers';
import { textColor } from '../../../style';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import useEvidently from '../../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../../constants/evidently';
import colors, { white } from '../../../theme/colors';
import PriceInput from '../../pages/Offers/PriceInput';
import CancelButton from '../../../atoms/buttons/ProfileCancelButton';
import typography from '../../../theme/typography';
import { theme } from '../../../theme';
import borders from '../../../theme/borders';
import { getDefaultOfferAmount } from '../../../utils/offers';

const InitialInputProps = {
  style: {
    fontSize: 36,
    height: 55,
    lineHeight: '21px',
    fontWeight: 700,
    outline: 'none',
    border: 'none',
    marginLeft: -17,
    letterSpacing: '-0.72px',
    fontFamily: 'system-ui, -apple-system, Arial, sans-serif',
    backgroundColor: white,
    color: isDarkMode() ? 'rgb(207, 207, 207)' : colors.light.blue.tertiary,
  },
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const setColor = {
  default: {
    color: 'grey',
  },
  brand: {
    color: '#3e79a5',
  },
  alert: { ...textColor.RED },
};

const Heading1 = styled('h6')({
  width: '100%',
  fontSize: 20,
  fontWeight: 600,
  color: typography[theme].heading,
  letterSpacing: '-0.6px',
  lineHeight: 'normal',
  margin: 0,
});

const LineSeparator = styled('hr')({
  borderColor: borders[theme].primary,
  marginTop: 10,
  marginBottom: 10,
});

const CurrencyTip = styled('div')(
  {
    display: 'inline-block',
    position: 'relative',
    paddingLeft: 10,
  },
  ({ offerPrice }) => ({
    ...setColor[offerPrice],
    ':before': {
      position: 'absolute',
      top: 14,
      right: '85%',
      fontSize: 16,
    },
  })
);

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 14,
});

const OfferAmountLabel = styled('label')({
  width: '100%',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.26px',
  color: typography[theme].primaryText,
});

const PriceContainer = styled('div')(
  {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
    width: '100%',
    height: 53,
    borderRadius: 3,
    boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.20) inset',
    backgroundColor: isDarkMode() ? '#020D15' : white,
  },

  ({ hasErrors }) => ({
    backgroundColor: hasErrors ? 'rgba(204, 84, 84,0.15)' : isDarkMode() ? '#020D15' : white,
    border: hasErrors ? '1px solid #cc5454' : '1px solid #DAE1E9',
  })
);

const CurrencyLabelSpan = styled('span')({
  fontSize: 36,
  fontWeight: 700,
  color: colors.light.blue.tertiary,
  letterSpacing: '-0.6px',
  lineHeight: '21px',
});

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  maxHeight: 600,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

const offerPriceEmptyStyle = {
  paddingLeft: 0,
  width: '100%',
  textAlign: 'center',
  marginLeft: 0,
};

interface EditableOpeningOfferModalProps {
  title: any;
  handleCancel: any;
  handleSubmit: any;
  currencySymbols: any;
  offerPrice: any;
  setOfferPrice: any;
  toggleEditOpeningOffer: boolean;
}

const EditableOpeningOfferModal = ({
  title,
  handleCancel,
  handleSubmit,
  currencySymbols,
  offerPrice,
  setOfferPrice,
  toggleEditOpeningOffer,
}: EditableOpeningOfferModalProps): JSX.Element => {
  const { useActiveVariation } = useEvidently();
  const [hasErrors, setHasErrors] = useState(false);

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const minValue = defaultMinOffer;
  const maxValue = '500';

  const inputOnChange = e => {
    const inputValue = e.target.value;
    const MaxLength = maxValue.toString().length;

    if (inputValue === '' || inputValue.length <= MaxLength) {
      const isInvalid =
        inputValue !== '' && Boolean(Number(inputValue) < Number(minValue) || Number(inputValue) > Number(maxValue));
      setOfferPrice(inputValue);
      setHasErrors(isInvalid);
    } else {
      e.preventDefault();
    }
  };

  const colorType = (price, hasErrors) => {
    switch (price) {
      case true:
        return 'default';
      case false:
        return !hasErrors ? 'brand' : 'alert';
      default:
        return;
    }
  };

  return (
    <ModalWithPattern
      dataTestID="editable-opening-offer-modal"
      isModalOpen={toggleEditOpeningOffer}
      modalContentLabel="Editable Opening Offer Modal"
      closeBtnClassName="first-date-offer-tutorial-close"
      closeBtnDataTestId="first-date-offer-tutorial-close"
      onCloseClick={handleCancel}
      customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
      customContentStyle={IsMobileViewPort ? mobileContentStyle : { maxWidth: 349 }}
      customContentBodyStyle={{ padding: '20px 15px' }}
      hideCloseBtn
    >
      <Heading1>{title}</Heading1>
      <LineSeparator />
      <OfferAmountLabel>{`Enter a new default offer amount`}</OfferAmountLabel>
      <PriceContainer hasErrors={hasErrors}>
        {offerPrice !== '' && (
          <CurrencyLabelSpan style={{ paddingLeft: 44 }}>
            {currencySymbols.currencyInitial}
            {currencySymbols.currencyLabel}
          </CurrencyLabelSpan>
        )}
        <CurrencyTip
          offerPrice={colorType(offerPrice, hasErrors)}
          style={{
            paddingLeft: 0,
          }}
        >
          <PriceInput
            data-test-id="dashboard-discovermode-offer-price-input"
            hasErrors={hasErrors}
            value={offerPrice}
            onChange={inputOnChange}
            validateTextFn={validateTextInput}
            placeholder={`${currencySymbols.currencyInitial}${currencySymbols.currencyLabel}${getDefaultOfferAmount()}`}
            inputProps={{
              style: {
                ...InitialInputProps.style,
                marginLeft: 3,
                textAlign: 'left',
              },
            }}
            minWidth={100}
            overideInputStyle={offerPrice === '' ? offerPriceEmptyStyle : { paddingLeft: 0 }}
          />
        </CurrencyTip>
      </PriceContainer>

      <ButtonContainer>
        <CancelButton
          dataTestId="editable-textarea-cancel-btn"
          handleCancel={handleCancel}
          customStyle={{ marginRight: 0, fontSize: 13 }}
        />
        <PrimaryButton
          color="red"
          size="x-small"
          data-test-id="close-preview"
          onClick={handleSubmit}
          label="Save"
          customStyle={{ width: 70 }}
          disabled={hasErrors}
        />
      </ButtonContainer>
    </ModalWithPattern>
  );
};

export default memo(EditableOpeningOfferModal);
