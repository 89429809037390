import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';
import Button from '../../../atoms/buttons/Button';
import { SvgIcon } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import typography from '../../../theme/typography';
import { theme } from '../../../theme';
import borders from '../../../theme/borders';

const ContainerDiv = styled('div')(
  {
    paddingTop: 0,
  },
  ({ centerAlign }) =>
    !centerAlign && {
      padding: '20px 0',
    }
);

const HeaderDiv = styled('div')(
  {
    textAlign: 'center',
    marginTop: 26,

    [dimensions.SCREEN_MAX_SM]: {
      marginTop: 12,
    },
  },
  ({ centerAlign }) =>
    !centerAlign && {
      textAlign: 'left',
      padding: '0 20px',
    }
);

const ModalTitle = styled('span')({
  color: typography[theme].heading,
  fontSize: 24,
  lineHeight: '29px',
  fontStyle: 'normal',
  fontWeight: 600,
  display: 'block',
  marginBottom: 15,

  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 22,
    lineHeight: '27px',
  },
});

const ButtonDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: 18,
});

const IconDiv = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  ({ centerAlign }) =>
    !centerAlign && {
      justifyContent: 'start',
    }
);

const ModalContent = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    fontSize: 16,
    color: typography[theme].primaryText,
    fontWeight: 400,
    lineHeight: '21px',

    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 15,
      lineHeight: '20px',
    },
  },
  ({ centerAlign }) =>
    !centerAlign && {
      textAlign: 'left',
    }
);

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: 15,
  right: 15,
});

const closeButton = {
  fontSize: 24,
  color: theme === 'light' ? typography.light.secondaryText : borders.dark.primary,
};
export interface ConfirmActionProps {
  title: string;
  message: string | React.ReactNode;
  buttonID?: string;
  cancelText?: string;
  confirmText?: string;
  cancelAction?: () => void;
  confirmAction: () => void;
  modalIcon?: any;
  centerAlign?: boolean;
  withCancel?: boolean;
  hideCloseBtn?: boolean;
  closeBtnClassName?: string;
  closeBtnDataTestId?: string;
}

const customButtonstyle = {
  fontSize: 14,
  padding: '12px 22px',
};

const ConfirmAction = ({
  title = '',
  message = '',
  buttonID = '',
  cancelText = 'No',
  confirmText = 'Yes',
  cancelAction,
  confirmAction,
  modalIcon,
  centerAlign = true,
  withCancel = true,
  hideCloseBtn = false,
  closeBtnClassName,
  closeBtnDataTestId,
}: ConfirmActionProps): ReactElement => {
  return (
    <ContainerDiv centerAlign={centerAlign}>
      <HeaderDiv centerAlign={centerAlign}>
        <IconDiv centerAlign={centerAlign}>{modalIcon}</IconDiv>
        {hideCloseBtn && (
          <CloseBtn className={closeBtnClassName} data-test-id={closeBtnDataTestId} onClick={cancelAction}>
            <SvgIcon component={CancelRounded} style={closeButton} />
          </CloseBtn>
        )}
        <ModalTitle centerAlign={centerAlign}>{title}</ModalTitle>
        <ModalContent centerAlign={centerAlign}>{message}</ModalContent>
      </HeaderDiv>
      <ButtonDiv>
        {withCancel && (
          <Button
            buttonType="normal"
            onClick={cancelAction}
            data-test-id={`${buttonID}-cancel`}
            type="submit"
            customStyle={{
              ...customButtonstyle,
              background: patternModal.cancelBG,
              border: patternModal.cancelBorder,
              color: patternModal.cancelBlue,
              marginRight: 20,
              '&:hover': {
                background: `${patternModal.cancelBlue} !important`,
                color: '#FFF !important',
              },
            }}
          >
            {cancelText}
          </Button>
        )}
        <Button
          buttonType="primary"
          data-test-id={`${buttonID}-confirm`}
          customStyle={{ border: patternModal.cancelBorder, ...customButtonstyle }}
          onClick={e => {
            confirmAction(e);
            e.preventDefault();
          }}
        >
          {confirmText}
        </Button>
      </ButtonDiv>
    </ContainerDiv>
  );
};

export default ConfirmAction;
