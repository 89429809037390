/**
 * @Author: zachalam
 * @Date:   2017-01-26T14:15:34-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-07-27T17:30:33-07:00
 */

import * as sdk from '../sdk';
import * as Sentry from '@sentry/react';
import {
  storeErrors,
  storeSpecificErrors,
  clearErrors,
  setLoading,
  clearEverything,
  killLoading,
} from './commonActions';
import { clearNotificationMessage, displayFailMessage } from './notificationActions';
import { toaster, setLocalItem, getFileSizeDelay, getLocalItem } from '../common';
import { storeMeta } from './metaActions';
import { clearIsFetching } from './favActions';
import { saveToken, removeBlockFlags } from './authActions';
import DataLayer from '../utils/dataLayer';
import {
  localVisitedProfiles,
  updateVisitedProfiles,
  localDashboardProfiles,
  // localRecentActivity, // WYP-13499 Deprecate ActivityFeed
  // updateRecentActivity, // WYP-13499 Deprecate ActivityFeed
} from '../utils/storedLocalData';
import _union from 'lodash/union';
import { formatFormData } from '../utils/helpers';
import MasterConfig, { nuxEnabled } from '../config/Master';
import { EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS_BASED_ON_FEATURES } from '../constants/evidently';
import { NavigateFunction } from 'react-router-dom';
import { removeDiscoverModeAccountsByHashId } from './discoverModeActions';

export type UploadMediaFormDataType = {
  id: string;
  file: string; // base64
  isPrivate: boolean;
  isCropped: boolean;
};

declare global {
  interface Window {
    dataLayer?: null;
  }
}

export const getProfileV2 = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_V2',
    payload,
  };
};

export const saveProfileDescription = (payload: any): any => {
  return {
    type: 'SAVE_PROFILE_DESCRIPTION',
    payload,
  };
};

export const saveProfileSeeking = (payload: any): any => {
  return {
    type: 'SAVE_PROFILE_SEEKING',
    payload,
  };
};

export const getProfile = (payload: any): any => {
  return {
    type: 'STORE_PROFILE',
    payload,
  };
};

export const getExternalProfile = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_EXTERNAL',
    payload,
  };
};

export const storeProfileMapFields = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_MAP_FIELDS',
    payload,
  };
};

export const storeReportReasons = (payload: any): any => {
  return {
    type: 'STORE_REPORT_REASONS',
    payload,
  };
};

export const externalProfile404 = (payload: any): any => {
  return {
    type: 'STORE_PROFILE_EXTERNAL_404',
    payload,
  };
};

export const remExternalProfile = (): any => {
  return {
    type: 'REMOVE_PROFILE_EXTERNAL',
  };
};

export const storeSettings = (payload: any): any => {
  if (payload.evidently) {
    Object.keys(payload.evidently).forEach(feature => {
      const storageKey = EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS_BASED_ON_FEATURES[feature];

      if (storageKey) {
        setLocalItem(storageKey, JSON.stringify(payload.evidently[feature]));
      }
    });
  }

  return {
    type: 'STORE_SETTINGS',
    payload,
  };
};

export const storeVideoSettings = (payload: any): any => {
  return {
    type: 'STORE_VIDEO_SETTINGS',
    payload,
  };
};

export const storeVideoInfo = (payload: any): any => {
  return {
    type: 'STORE_VIDEO_INFO',
    payload,
  };
};

export const clearVideoInfo = (): any => {
  return {
    type: 'CLEAR_VIDEO_INFO',
  };
};

export const storeIceServers = (payload: any): any => {
  return {
    type: 'STORE_ICE_SERVERS',
    payload,
  };
};

export const storeBlocked = (payload: any): any => {
  return {
    type: 'STORE_BLOCKED',
    payload,
  };
};

export const storeBiometricList = (payload: any): any => {
  return {
    type: 'STORE_BIOMETRIC_LIST',
    payload,
  };
};

export const storeNewBlockedUser = (payload: any): any => {
  return {
    type: 'STORE_NEW_BLOCKED_USER',
    payload,
  };
};

export const invertSetting = (payload: any): any => {
  return {
    type: 'INVERT_SETTING',
    payload,
  };
};

export const invertVideoSetting = (payload: any): any => {
  return {
    type: 'INVERT_VIDEO_SETTING',
    payload,
  };
};

export const storeSinglePhoto = (payload: any): any => {
  return {
    type: 'STORE_SINGLE_PHOTO',
    payload,
  };
};

export const updatePhotoUploadProcess = (payload: any): any => {
  return {
    type: 'UPDATE_PHOTO_UPLOAD_PROCESS',
    payload,
  };
};

export const makeAllAvatarsFalse = (): any => {
  return {
    type: 'MAKE_ALL_AVATARS_FALSE',
  };
};

export const removePhoto = (photoKey: any): any => {
  return {
    type: 'DELETE_SINGLE_PHOTO',
    payload: photoKey,
  };
};

export const removePhotofromProfile = (photoKey: any): any => {
  return {
    type: 'DELETE_SINGLE_PHOTO_FROM_PROFILE',
    payload: photoKey,
  };
};

export const storeDefaultPhoto = (photoKey: any): any => {
  return {
    type: 'STORE_DEFAULT_PHOTO',
    payload: photoKey,
  };
};

export const storeNewPrivacy = (photoKey: any): any => {
  return {
    type: 'STORE_NEW_PRIVACY',
    payload: photoKey,
  };
};

export const makePremium = (): any => {
  return {
    type: 'MAKE_PREMIUM',
  };
};

export const changeCallStatus = (payload: any): any => {
  return dispatch => {
    dispatch({
      type: 'CHANGE_CALL_STATUS',
      payload,
    });
  };
};

/*
// WYP-13499 Deprecate ActivityFeed
export const storeActivity = (payload: any): any => {
  return {
    type: 'STORE_ACTIVITY',
    payload,
  };
};
*/
export const lessOffersCount = (): any => {
  return {
    type: 'STORE_OFFERS_COUNT',
  };
};

// block a specific user (when viewing their extprofile)
export const toggleBlockInStore = (username: any): any => {
  return dispatch => {
    dispatch({
      type: 'TOGGLE_BLOCK_USER_PROFILE',
      payload: username,
    });

    // remove from search results if available
    dispatch({
      type: 'REMOVE_FROM_SEARCH',
      payload: username,
    });
  };
};
//---------

/*
// WYP-13499 Deprecate ActivityFeed
export const requestActivity = (): any => {
  return {
    type: 'REQUEST_ACTIVITY',
  };
};
*/

export const pendingUpload = (isPrivate: any): any => {
  return {
    type: 'PENDING_UPLOAD',
    payload: { isPrivate },
  };
};

export const endPendingUpload = (isPrivate: any): any => {
  return {
    type: 'END_PENDING_UPLOAD',
    payload: { isPrivate },
  };
};

export const gdprAcknowledged = (): any => {
  return {
    type: 'GDPR_ACKNOWLEDGED',
  };
};

export const gdprOpenStep2 = (): any => {
  return {
    type: 'GDPR_OPEN_STEP2',
  };
};

export const storeUserInfo = (payload: any): any => {
  return {
    type: 'STORE_GDPR_USER_INFO',
    payload,
  };
};

export const setProcessing = (): any => {
  return {
    type: 'IS_PROCESSING',
  };
};

export const setIsUpdatingProfileCover = (payload: boolean): any => {
  return {
    type: 'IS_UPDATING_PROFILE_COVER',
    payload,
  };
};

export const setIsUpdatingDescription = (payload: boolean): any => {
  return {
    type: 'IS_UPDATING_DESCRIPTION',
    payload,
  };
};

export const setIsUpdatingSeekingDesc = (payload: boolean): any => {
  return {
    type: 'IS_UPDATING_SEEKING_DESC',
    payload,
  };
};

export const storeNuxGuides = (payload: any): any => {
  return {
    type: 'STORE_NUX_GUIDES',
    payload,
  };
};

export const clearNuxGuides = (): any => {
  return {
    type: 'CLEAR_NUX_GUIDES',
  };
};

export const updateNuxGuides = (payload: any): any => {
  return {
    type: 'UPDATE_NUX_GUIDES',
    payload,
  };
};

export const resetNuxStates = (payload: any): any => {
  return {
    type: 'RESET_NUX_STATES',
    payload,
  };
};

export const setNuxGuidesFetching = (): any => {
  return {
    type: 'SET_NUX_GUIDES_FETCHING',
  };
};

export const setGalleryLoading = (): any => {
  return {
    type: 'SET_GALLERY_FETCHING',
  };
};

export const killGalleryLoading = (): any => {
  return {
    type: 'KILL_GALLERY_FETCHING',
  };
};

export const updateVideoStatus = (key: any, status: any): any => {
  return {
    type: 'UPDATE_VIDEO_STATUS',
    payload: { key, status },
  };
};

export const createProfile = (formData: any): any => {
  return (dispatch, getState) => {
    return sdk
      .createUserProfile(getState().auth.jwToken, formData)
      .then(() => {
        // refresh profile now
        dispatch(refreshProfile());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const refreshProfile = (): any => {
  // get the logged in users profile
  return (dispatch, getState) => {
    return sdk
      .getLoggedProfile(getState().auth.jwToken)
      .then(response => {
        const accountData = response.data.data;
        dispatch(getProfile(accountData));
        dispatch(killGalleryLoading());
        return accountData;
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getProfileMapFields = (): any => {
  return dispatch => {
    return sdk
      .getProfileMapFields()
      .then(response => {
        const profileMapFields = response.data;
        dispatch(storeProfileMapFields(profileMapFields));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const setErrorFlow = (error, dispatch, redirect) => {
  dispatch(setLoading());
  // Clears session, adds an error and redirects to /login
  const { data } = error.response;
  dispatch(clearEverything());
  dispatch(storeSpecificErrors('form', data.message));

  setLocalItem('redirectTo', redirect === '/login' ? 'dashboard' : location.search.replace('?redirectTo=', ''));

  window.location.href = '/login';
};

const saveProfileFlow = (response, dispatch, isRefreshProfile = false) => {
  // clear errors
  dispatch(clearErrors());
  // save new profile
  dispatch(getProfileV2(response.data.data));
  if (isRefreshProfile) {
    dispatch(refreshProfile());
  } else {
    dispatch(refreshAccount());
  }
};

export const checkEmailToken = (token: any, username = null, redirect = null, navigate: NavigateFunction): any => {
  return dispatch => {
    if (typeof token === 'undefined') {
      dispatch(clearEverything());
      window.location.href = '/login';
      return null;
    }

    dispatch(setLoading());

    return sdk
      .getSignedLinkToken(token)
      .then(response => {
        // save token
        dispatch(saveToken(response.data));

        // check if user is gdpr from email_token
        if (
          response.data.gdpr_acknowledged !== null &&
          response.data.gdpr_acknowledged !== undefined &&
          !response.data.gdpr_acknowledged
        ) {
          navigate('/privacy-updated');
        }

        // retrieve logged in profile.
        dispatch(refreshProfile()).then(() => {
          // hide loading
          dispatch(killLoading());
          // clear errors
          dispatch(clearErrors());

          dispatch(getProfileSettings());

          dispatch(removeBlockFlags());

          if (username) {
            dispatch(refreshExternalProfile(username));
          }
        });
      })
      .catch(error => {
        setErrorFlow(error, dispatch, redirect);
      });
  };
};

export const checkEmailPromoToken = (token: any, redirect = null): any => {
  return dispatch => {
    if (typeof token === 'undefined') {
      dispatch(clearEverything());
      window.location.href = '/login';
      return null;
    }

    dispatch(setLoading());

    return sdk
      .getSignedEmailPromoToken(token)
      .then(response => {
        dispatch(saveToken(response.data));
        dispatch(setLoading());
        dispatch(clearErrors());
      })
      .catch(error => {
        setErrorFlow(error, dispatch, redirect);
      });
  };
};

export const refreshAccount = (): any => {
  return (dispatch: any, getState: any) => {
    return sdk
      .getLoggedAccount(getState().auth.jwToken)
      .then(response => {
        const accountData = response.data.data;
        dispatch(getProfile(accountData));
        dispatch(killGalleryLoading());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const buttonEventHandler = (isAccept: any, navigate: NavigateFunction): any => {
  return dispatch => {
    if (isAccept) {
      return dispatch(gdprOpenStep2());
    }
    return navigate('/settings?gdpr=true');
  };
};

export const updateProfileCover = (formData: any): any => {
  return async (dispatch, getState) => {
    dispatch(setIsUpdatingProfileCover(true));

    return await sdk
      .updateProfileCoverData(getState().auth.jwToken, formData)
      .then(response => {
        saveProfileFlow(response, dispatch);
        dispatch(setIsUpdatingProfileCover(false));
      })
      .catch(error => {
        dispatch(setIsUpdatingProfileCover(false));
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileOpeningOffer = (formData: any): any => {
  return (dispatch, getState) => {
    dispatch(setProcessing());
    return sdk
      .updateProfileOpeningOfferData(getState().auth.jwToken, formData)
      .then(response => {
        // hide loading
        dispatch(setProcessing());
        // clear errors
        dispatch(clearErrors());
        // save new profile
        dispatch(getProfileV2(response.data.data));
        dispatch(refreshProfile());
      })
      .catch(error => {
        // hide loading
        dispatch(setProcessing());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileAbout = (formData: any): any => {
  return (dispatch, getState) => {
    dispatch(setProcessing());
    const updatedFormData = formatFormData(formData);
    return sdk
      .updateProfileAboutData(getState().auth.jwToken, updatedFormData)
      .then(response => {
        // hide loading
        dispatch(setProcessing());
        // clear errors
        dispatch(clearErrors());
        // save new profile
        dispatch(getProfileV2(response.data.data));
        dispatch(refreshProfile());
      })
      .catch(error => {
        // hide loading
        dispatch(setProcessing());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileMandatoryCompletion = (formData: any, callback: any): any => {
  return dispatch => {
    dispatch(setProcessing());
    return sdk
      .updateMandatoryCompletion(formData)
      .then(response => {
        // hide loading
        dispatch(setProcessing());
        // clear errors
        dispatch(clearErrors());
        // save new profile
        dispatch(getProfileV2(response.data.data));
        dispatch(refreshProfile());
        callback();
      })
      .catch(error => {
        // hide loading
        dispatch(setProcessing());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileInterest = (formData: any): any => {
  return (dispatch, getState) => {
    return sdk
      .updateProfileInterestData(getState().auth.jwToken, formData)
      .then(response => {
        saveProfileFlow(response, dispatch, true);
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileDescription = (formData: any): any => {
  return (dispatch, getState) => {
    dispatch(setIsUpdatingDescription(true));
    return sdk
      .updateProfileDescriptionData(getState().auth.jwToken, formData)
      .then(response => {
        dispatch(saveProfileDescription(response.data.data));
        dispatch(setIsUpdatingDescription(false));
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
        dispatch(setIsUpdatingDescription(false));
      });
  };
};

export const updateProfileSeeking = (formData: any): any => {
  return (dispatch, getState) => {
    dispatch(setIsUpdatingSeekingDesc(true));
    return sdk
      .updateProfileSeekingData(getState().auth.jwToken, formData)
      .then(response => {
        dispatch(saveProfileSeeking(response.data.data));
        dispatch(setIsUpdatingSeekingDesc(false));
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
        dispatch(setIsUpdatingSeekingDesc(false));
      });
  };
};

export const updateProfile = (
  formData: any,
  needsPhoto = false,
  isMigration = false,
  hashID = '',
  navigate: NavigateFunction
): any => {
  return (dispatch: any, getState: any) => {
    dispatch(setProcessing());
    const updatedFormData = formatFormData(formData);
    return sdk
      .updateLoggedProfile(getState().auth.jwToken, updatedFormData)
      .then(response => {
        // clear errors
        dispatch(clearErrors());
        // save new profile
        dispatch(getProfile(response.data.data));
        // redirect to dashboard
        if (isMigration || needsPhoto) {
          dispatch(refreshProfile());
          setTimeout(() => {
            dispatch(setProcessing());
            navigate(`/profile/${hashID}?successfulMigration=true`);
          }, 500);
        } else {
          dispatch(setProcessing());
          navigate('/dashboard');
        }
      })
      .catch(error => {
        dispatch(setProcessing());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const getAccountByUserName = (username: any): any => {
  return (dispatch: any, getState: any) => {
    dispatch(remExternalProfile());

    return sdk
      .getAccountByUserName(getState().auth.jwToken, username)
      .then(response => {
        return response;
      })
      .catch(() => {
        // could not find this profile.
        dispatch(externalProfile404(username));
      });
  };
};

export const removeExternalProfile = (): any => {
  return dispatch => {
    dispatch(remExternalProfile());
  };
};

export const refreshExternalProfile = (username: any): any => {
  return (dispatch, getState) => {
    const visitedProfiles = localVisitedProfiles();
    const dashboardProfiles = localDashboardProfiles();
    const availableProfiles = _union(visitedProfiles, dashboardProfiles);

    const prevExists = availableProfiles.find(profile => profile['hash_id'] === username);
    if (prevExists) {
      dispatch(getExternalProfile(prevExists));
    }

    return sdk
      .getExternalProfile(getState().auth.jwToken, username)
      .then(response => {
        updateVisitedProfiles('add', response.data.data);
        return dispatch(getExternalProfile(response.data.data));
      })
      .catch(() => {
        // could not find this profile.
        dispatch(externalProfile404(username));
      });
  };
};

export const getProfileSettings = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getAccountSettings(getState().auth.jwToken)
      .then(response => {
        dispatch(storeSettings(response.data.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getVideoCallSettings = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getVideoChatPreferences(getState().profile.hash_id)
      .then(response => {
        dispatch(storeVideoSettings(response.data.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getIceServers = (): any => {
  return dispatch => {
    return sdk
      .getTurnCredentials()
      .then(response => {
        dispatch(storeIceServers(response.data.ice_servers));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getCheckInfoRequest = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getCheckInfoRequest(getState().auth.jwToken)
      .then(response => {
        dispatch(storeUserInfo(response.data.data.length === 0 ? null : response.data.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const requestUserInfo = (): any => {
  return (dispatch, getState) => {
    return sdk
      .requestUserInfo(getState().auth.jwToken)
      .then(response => {
        dispatch(storeUserInfo(response.data.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getBlockedList = (): any => {
  // store users that this person has blocked.
  return (dispatch, getState) => {
    return sdk.getBlockedList(getState().auth.jwToken).then(response => {
      dispatch(storeBlocked(response.data));
    });
  };
};

export const getBiometricsList = (): any => {
  // store users that this person has blocked.
  return dispatch => {
    return sdk.getBiometricKeys().then(response => {
      dispatch(storeBiometricList(response.data));
    });
  };
};

export const updateBiometricName = (newName: any, deviceId: any): any => {
  return (dispatch: any) => {
    dispatch(clearNotificationMessage());
    return sdk
      .editBiometricName(newName, deviceId)
      .then(response => {
        return response;
      })
      .catch(error => {
        dispatch(
          displayFailMessage({
            info: error.response.data.name[0],
          })
        );

        return error.response;
      });
  };
};

export const loadMoreData = (page: any): any => {
  return (dispatch, getState) => {
    return sdk
      .getBlockedList(getState().auth.jwToken, page)
      .then(response => {
        dispatch(storeNewBlockedUser(response.data));
        dispatch(clearIsFetching());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateProfileSetting = (updatedField: string): any => (_, getState) => {
  const settings = getState().settings;

  delete settings.id;
  return sdk.updateAccountSettings(getState().auth.jwToken, { ...getState().settings, updatedField });
};

export const updateVideoSetting = (): any => {
  return (dispatch, getState) => {
    return sdk.updateVideoChatPreferences(getState().profile.hash_id, getState().settings.video_call);
  };
};

export const deleteGDPR = (password: any): any => {
  return (dispatch: any, getState: any) => {
    const currentSubscription = getLocalItem('pushSubscription');
    currentSubscription && sdk.unsubscribeWebPush(currentSubscription);

    return sdk
      .gdprDelete(getState().auth.jwToken, password)
      .then(() => {
        dispatch(clearErrors());
        toaster.success('Your account has been successfully deleted.');
        window.location.href = '/login?logged_out=true';
      })
      .catch(error => {
        dispatch(storeSpecificErrors('error_password_gdpr', error.response.data.password[0]));
      });
  };
};

export const notifyEmailUpdate = (notifyEmail: any, navigate: NavigateFunction): any => {
  return (dispatch, getState) => {
    return sdk.gdprAcknowledged(getState().auth.jwToken, { notify_email: notifyEmail }).then(() => {
      dispatch(clearErrors());
      dispatch(gdprAcknowledged());

      const isUserFirstTimeLogin = JSON.parse(getLocalItem('user_first_time_login'));

      if (isUserFirstTimeLogin) {
        if (getState()?.profile?.profile?.data?.account_type === 'Generous') {
          navigate('/packages');
        } else {
          navigate('/search');
        }
      } else {
        navigate('/dashboard');
      }

      toaster.success('Updated Settings');
    });
  };
};

export const endImageUploadLoader = (response: any, isPrivate: any): any => {
  return dispatch => {
    // new photo accepted.
    dispatch(endPendingUpload(isPrivate));
    // if this is a avatar photo - reset all avatars.
    if (response.data.data.is_avatar) dispatch(makeAllAvatarsFalse());

    dispatch(storeSinglePhoto(response.data.data));
    dispatch(updatePhotoUploadProcess(response.data.meta));
  };
};

export const stopPendingPhotoUpload = (isPrivate = false): any => {
  return dispatch => {
    dispatch(endPendingUpload(isPrivate));
    dispatch(clearPendingPhotosUpload());
    dispatch(
      displayFailMessage({
        info: 'Error uploading photo. Please try again or ',
        linkInfo: 'contact support.',
        url: 'faq/giving-feedback',
      })
    );
  };
};

const uploadErrorFlow = (error: any, isPrivate: any, dispatch: any): any => {
  // toast error
  dispatch(endPendingUpload(isPrivate));
  let msg = null;

  if (error.response && error.response.data) {
    if (error.response.data.photo) {
      msg = error.response.data.photo[0];
    } else if (error.response.data.errors) {
      msg = error.response.data.errors[0].detail;
    }
  }

  if (msg) {
    toaster.warn(msg);
  } else {
    dispatch(
      displayFailMessage({
        info: 'Error uploading photo. Please try again or ',
        linkInfo: 'contact support.',
        url: 'faq/giving-feedback',
      })
    );
  }
};

export const getPhotoUploadResponse = (
  useDirectUpload: any,
  fileExtension: any,
  isPrivate: any,
  formData: any,
  allState: any
): any => {
  let photoUploadResponse;

  if (useDirectUpload === true) {
    photoUploadResponse = sdk.mockProcessPhoto(allState().auth.jwToken, {
      private: isPrivate,
      fileExtension: fileExtension,
      is_cropped: true,
    });
  } else {
    photoUploadResponse = sdk.postNewPhoto(allState().auth.jwToken, formData);
  }

  return photoUploadResponse;
};

export const addNewPhoto = (formData: any, isPrivate: any): any => {
  return (dispatch, getState) => {
    // validate 15mb file size
    if (!formData || typeof formData.size === 'undefined' || formData.size >= 15000000) {
      dispatch(endPendingUpload(isPrivate));
      dispatch(
        displayFailMessage({
          info: 'Error uploading photo. Please try again or ',
          linkInfo: 'contact support.',
          url: 'faq/giving-feedback',
        })
      );
      return;
    }

    dispatch(pendingUpload(isPrivate));

    const useDirectUpload = getState().profile.direct_s3_upload;
    let photoUploadResponse = null;
    const fileExtension = formData.type.substr(formData.type.lastIndexOf('/') + 1);

    photoUploadResponse = getPhotoUploadResponse(useDirectUpload, fileExtension, isPrivate, formData, getState);

    return photoUploadResponse
      .then(response => {
        const apiResponse = response.data.data;
        const { uploadUrl } = response.data.meta;

        if (useDirectUpload === true) {
          const fileName = apiResponse.path + '.' + fileExtension;
          let newFile = null;
          const photoUrl = apiResponse.urls['820px'] || null;

          try {
            newFile = new File([formData], fileName, { type: formData.type });
          } catch (err) {
            newFile = new Blob([formData], { type: formData.type });
            newFile.lastModifiedDate = new Date();
            newFile.lastModified = +newFile.lastModifiedDate;
            newFile.name = fileName;
          }

          sdk.uploadToS3(uploadUrl, newFile).then(
            () => {
              const directPhotoUrl = `${MasterConfig.s3BucketDirect}820px/${apiResponse.path}.jpg`;

              setTimeout(() => {
                sdk
                  .getPhoto(directPhotoUrl)
                  .then(resp => {
                    if (!('data' in resp)) {
                      Sentry.captureException('Photo failed to upload/processed', {
                        extra: {
                          photoPath: photoUrl,
                          data: resp,
                        },
                      });
                      dispatch(stopPendingPhotoUpload(isPrivate));
                      return false;
                    }

                    sdk
                      .processPhoto(getState().auth.jwToken, {
                        private: isPrivate,
                        path: apiResponse.path,
                        is_cropped: true,
                      })
                      .then(processedPhoto => {
                        dispatch(endImageUploadLoader(processedPhoto, isPrivate));
                        dispatch(refreshProfile());
                        dispatch(refreshAccount());
                      });
                  })
                  .catch(error => {
                    Sentry.captureException('Photo failed to upload/processed', {
                      extra: {
                        photoPath: apiResponse.path,
                        data: error,
                      },
                    });
                    dispatch(stopPendingPhotoUpload(isPrivate));
                  });
              }, 6000); // lets wait for the lambda to fully process the image resizing
            },
            () => {
              dispatch(deletePhoto(apiResponse.id));
              dispatch(stopPendingPhotoUpload(isPrivate));
            }
          );
        } else {
          dispatch(endImageUploadLoader(response, isPrivate));
          dispatch(refreshProfile());
          dispatch(refreshAccount());
        }
      })
      .catch(error => {
        uploadErrorFlow(error, isPrivate, dispatch);
      });
  };
};

export const uploadPhotoMessage = (formData: any, otherHashId: any, isPrivate = false): any => {
  return (dispatch, getState) => {
    // validate 15mb file size
    if (!formData || typeof formData.size === 'undefined' || formData.size >= 15000000) {
      dispatch(endPendingUpload(isPrivate));
      dispatch(
        displayFailMessage({
          info: 'Error uploading photo due to file size. Please try again or ',
          linkInfo: 'contact support.',
          url: 'faq/giving-feedback',
        })
      );
      return;
    }

    const fileSizeDelay = getFileSizeDelay(formData.size);

    dispatch(pendingUpload(isPrivate));

    const useDirectUpload = getState().profile.direct_s3_upload;
    const fileExtension = formData.type.substr(formData.type.lastIndexOf('/') + 1);

    const photoMessageUploadResponse = sdk.processPhotoMessage(getState().auth.jwToken, {
      fileExtension: fileExtension,
      hash_id: otherHashId,
    });
    return photoMessageUploadResponse
      .then(response => {
        const apiResponse = response.data.data;
        const { uploadUrl } = response.data.meta;

        if (useDirectUpload === true) {
          const apiMessageResp = JSON.parse(apiResponse.message);
          const fileName = apiMessageResp.path + '.' + fileExtension;
          let newFile = null;
          const photoUrl = apiResponse.urls['820px'] || null;

          try {
            newFile = new File([formData], fileName, { type: formData.type });
          } catch (err) {
            newFile = new Blob([formData], { type: formData.type });
            newFile.lastModifiedDate = new Date();
            newFile.lastModified = +newFile.lastModifiedDate;
            newFile.name = fileName;
          }

          sdk.uploadToS3(uploadUrl, newFile).then(
            () => {
              setTimeout(() => {
                sdk
                  .getPhoto(photoUrl)
                  .then(resp => {
                    if (!('data' in resp)) {
                      Sentry.captureException('Message Photo Upload failed to upload/process', {
                        extra: {
                          photoPath: apiResponse.path,
                          data: resp,
                        },
                      });
                      dispatch(stopPendingPhotoUpload(isPrivate));
                      return false;
                    }
                    dispatch(endPendingUpload(isPrivate));
                  })
                  .catch(error => {
                    Sentry.captureException('Message Photo Upload failed to upload/process', {
                      extra: {
                        photoPath: photoUrl,
                        data: error,
                      },
                    });
                    dispatch(stopPendingPhotoUpload(isPrivate));
                  });
              }, fileSizeDelay); // delay based on file size
            },
            () => {
              dispatch(deletePhoto(apiResponse.id));
              dispatch(stopPendingPhotoUpload(isPrivate));
            }
          );
        } else {
          dispatch(endImageUploadLoader(response, isPrivate));
        }
        return apiResponse;
      })
      .catch(error => {
        uploadErrorFlow(error, isPrivate, dispatch);
      });
  };
};

export const deletePhoto = (photoId: any): any => {
  return (dispatch, getState) => {
    return sdk
      .deletePhoto(getState().auth.jwToken, photoId)
      .then(() => {
        /*dispatch(refreshProfile()); /* refresh main photo */
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const makePhotoDefault = (photoId: any, photoKey: any): any => {
  // set default photo.
  return (dispatch: any, getState: any) => {
    // notify store
    dispatch(storeDefaultPhoto(photoKey));
    // notify api
    return sdk.putPhotoAvatar(getState().auth.jwToken, photoId);
  };
};

export const setNewPrivacy = (photoId: any, photoKey: any): any => {
  // set new photo privacy.
  return (dispatch: any, getState: any) => {
    // notify store
    dispatch(storeNewPrivacy(photoKey));
    // notify api
    return sdk.putPhotoPrivacy(getState().auth.jwToken, photoId);
  };
};

export const reportUser = (formData: any, navigate: NavigateFunction): any => {
  return (dispatch, getState) => {
    return sdk
      .reportUser(getState().auth.jwToken, formData)
      .then(() => {
        removeDiscoverModeAccountsByHashId([formData?.account]);

        // clear errors
        dispatch(clearErrors());
        // redirect user to dashboard
        navigate('/dashboard');
        // notify by toast
        toaster.warn('Profile reported');
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const getReportReasons = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getReportReasons(getState().auth.jwToken)
      .then(response => {
        dispatch(storeReportReasons(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const toggleBlockProfile = (username: any, hashId: any, isBlocking = true): any => {
  return (dispatch, getState) => {
    const blockType = isBlocking ? 'blocked' : 'unblocked';
    return sdk
      .blockUser(getState().auth.jwToken, hashId)
      .then(response => {
        removeDiscoverModeAccountsByHashId([hashId]);

        dispatch(
          displayFailMessage({
            info: `You have ${blockType} ${username}`,
          })
        );
        return response;
      })
      .catch(err => {
        console.log('error', err);
      });
  };
};

export const clearPendingPhotosUpload = (): any => {
  return {
    type: 'CLEAR_ALL_UPLOADS',
  };
};
// ---------------

export const clearCredits = (): any => {
  return {
    type: 'CLEAR_CREDITS',
  };
};

export const storeCredits = (payload: any): any => {
  return {
    type: 'STORE_CREDITS',
    payload,
  };
};

export const isFetchingCredits = (): any => {
  return {
    type: 'IS_FETCHING_CREDITS',
  };
};

export const refreshCredits = (firstCall = true, per_page = 0): any => {
  return (dispatch, getState) => {
    let pageToGet = 0;

    if (firstCall) {
      dispatch(clearCredits());
    } else {
      pageToGet = getState().meta.credit_current_page + 1;
    }

    return sdk
      .getCredits(getState().auth.jwToken, per_page, pageToGet)
      .then(response => {
        dispatch(storeCredits(response.data.data));
        // store pagination
        const page = response.data.meta.pagination;
        dispatch(storeMeta({ credit_current_page: page.current_page, credit_total_pages: page.total_pages }));
        firstCall && dispatch(isFetchingCredits());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

/*
// WYP-13499 Deprecate ActivityFeed
export const getActivity = (num = 20): any => {
  // store recent activity for an account
  return (dispatch, getState) => {
    // get recent activity from localStorage
    const recentActivity = localRecentActivity();
    if (recentActivity) dispatch(storeActivity(recentActivity));

    if (!getState().dashboard.activity.length) dispatch(requestActivity());
    return sdk
      .recentActivity(getState().auth.jwToken, num)
      .then(response => {
        // update recent activity in localStorage
        updateRecentActivity(response.data.data);
        dispatch(storeActivity(response.data.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
*/
export const toggleDesktopNotificationStatus = (status: any): any => {
  return (dispatch, getState) => {
    const profile = getState().profile;

    if (status === true && typeof window.dataLayer !== 'undefined') {
      new DataLayer().push({
        event: 'enableDesktopNotification',
        hashId: profile.hash_id,
        submitted_at: profile.profile && profile.profile.data && profile.profile.data.submitted_date,
        completed_at: profile.profile && profile.profile.data && profile.profile.data.completed_date,
        monetized_at: profile.profile && profile.profile.data && profile.profile.data.monetized_date,
      });
    }

    dispatch({
      type: 'SETTINGS_TOGGLE_DESKTOP_NOTIFICATION',
      payload: {
        status,
      },
    });
  };
};

export const updateProfileContent = (hashId: any): any => {
  return dispatch => {
    return sdk
      .getExternalProfile(true, hashId)
      .then(response => {
        dispatch({
          type: 'REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA',
          payload: {
            data: response.data.data,
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateMsgReminder = (): any => {
  return dispatch => {
    return sdk
      .updateMessageReminder()
      .then(() => {
        dispatch(refreshProfile());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateShowTutorialOffer = (offerState: any): any => {
  return dispatch => {
    return sdk
      .updateShowOfferTutorial(offerState)
      .then(() => {
        dispatch(refreshProfile());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const fetchPageNux = (curRoute = null): any => {
  if (!nuxEnabled) {
    return;
  }

  return dispatch => {
    dispatch(clearNuxGuides());
    dispatch(setNuxGuidesFetching());
    return sdk.getNuxGuides(curRoute).then(response => {
      dispatch(storeNuxGuides(response.data));
    });
  };
};

export const updateNuxGuideStates = (nuxUpdates: any): any => {
  return dispatch => {
    return sdk
      .updateNuxGuideStates(nuxUpdates)
      .then(response => {
        dispatch(updateNuxGuides(response.data));
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const updateShowWishlist = (value: boolean, callback: any): any => {
  return dispatch => {
    return sdk
      .updateShowWishlist(value)
      .then(() => {
        dispatch(refreshProfile());
        callback();
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateShowGiftSent = (value: boolean, callback: any): any => {
  return dispatch => {
    return sdk
      .updateShowGiftSent(value)
      .then(() => {
        dispatch(refreshProfile());
        callback();
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const resetNuxGuide = (): any => {
  return dispatch => {
    return sdk
      .resetNuxGuide()
      .then(response => {
        dispatch(resetNuxStates(response.data));
        toaster.success('Guide Tour has been reset');
      })
      .catch(e => {
        if ('Raven' in window) {
          Sentry.captureException(e, { tags: { component: 'NUX', action: 'Reset' } });
        }
        console.log(e);
      });
  };
};

export const skipNuxGuides = (): any => {
  return dispatch => {
    return sdk
      .skipNuxGuides()
      .then(response => {
        const res = response.data;
        const isSkipped = res.success;

        if (isSkipped) {
          dispatch(clearNuxGuides());
        }
      })
      .catch(e => {
        if ('Raven' in window) {
          Sentry.captureException(e, { tags: { component: 'NUX', action: 'Skip' } });
        }
        console.log(e);
      });
  };
};

export const updatePhoneNumber = (payload: any, callback: any): any => {
  return dispatch => {
    return sdk
      .updatePhoneNumber(payload)
      .then(() => {
        dispatch(refreshProfile());
        callback();
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const updateProfileNewJoinFlowAction = (formData: any): any => {
  return () => {
    return sdk.updateProfileNewJoinFlow(formData);
  };
};

export const updateAccountConfig = ({ key, state }: { key: string; state: boolean }): any => {
  return dispatch => {
    return sdk
      .updateStateBasedSettings({ key, state })
      .then(() => {
        dispatch(refreshProfile());
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const resetHiddenDialogs = (): any => {
  return dispatch => {
    return sdk
      .resetHiddenDialogs()
      .then(() => {
        dispatch(refreshProfile());
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};
