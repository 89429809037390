/**
 * @Author: zachalam
 * @Date:   2017-01-13T14:07:06-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-24T11:01:04-08:00
 */

// determine which api to use?
export const api =
  process.env.REACT_APP_SDK_BASE_URL || (process.env.NODE_ENV === 'development' ? 'http://api-wyp.test/api' : '/api');
export const per_page = 24; // number of API results to allow per page
export const isFD = process.env.REACT_APP_NAME === 'fd';

export const SITE_NAME = isFD ? 'FirstDate' : 'WhatsYourPrice';
export const SOCIAL_SITE_NAME = isFD ? 'firstdatefast' : 'WhatsYourPrice';
export const SITE_ACRONYM = process.env.REACT_APP_NAME || 'wyp';
export const BASE_URL = isFD ? `FirstDate.com` : `WhatsYourPrice.com`;
export const SITE_ID = process.env.REACT_APP_SITE_ID || 'wyp';
export const FULL_URL = process.env.REACT_APP_FULL_URL || 'https://www.whatsyourprice.com';
export const SOCIAL_LINKS = {
  FACEBOOK: `https://www.facebook.com/${SOCIAL_SITE_NAME}`,
  TWITTER: `https://twitter.com/${SOCIAL_SITE_NAME}`,
  INSTAGRAM: `http://instagram.com/${SITE_NAME === 'WhatsYourPrice' ? 'whatsyourpricedating' : SOCIAL_SITE_NAME}`,
};
export const MAX_NUMBER_PHOTOS = 20;
export const MAX_PHOTO_FILE_SIZE = 15 * 1024 * 1024;
export const EXPIRE_SEARCH_HOURS = 7200;

export const MAX_COOKIE_AGE = 7776000;
export const EXP_MESSAGE_DAYS = 60;

export const GOOGLE_AUTH = 'google';
export const FACEBOOK_AUTH = 'facebook';

export const apiRTNotify = process.env.REACT_APP_AWS_API_GATEWAY_RTNOTIFY_URL || 'http://localhost:3902';
export const apiRTNotifyKey = process.env.REACT_APP_AWS_API_GATEWAY_RTNOTIFY_KEY || 'd41d8cd98f00b204e9800998ecf8427e';

export const PWA_PUBLIC_KEY =
  process.env.REACT_APP_PWA_PUBLIC_KEY ||
  'BG4pJF7ZfbPVleZJXv-CYLPBdQSpZCaInZyMOxHvx8WL1tuF5Y-qJk1X0M81_hBnQ_qtndyIbVQWp1P4zUngpZs';
export const s3BucketDirect =
  process.env.REACT_APP_S3_BUCKET || 'https://s3-us-west-1.amazonaws.com/wyp-dev/dev/images/';

export const notificationExpiration = process.env.NOTIFICATION_QUEUE_EXPIRATION || 1800; // notification queue expiration in seconds

export const googleMapKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCcyTBh3Yhnb61nkHTSfHZ54gqtihE55mU';
export const apiGiphyKey = process.env.REACT_APP_GIPHY_API_KEY || 'P53KmwGsXudBwgK2xHucNnDbUSupRdMr';
export const enabledPhotoMessage = process.env.REACT_APP_PHOTO_MESSAGE === 'true';
export const enabledOfferHighlights = process.env.REACT_APP_OFFER_HIGHLIGHTS_ENABLED === 'true';
export const enableBoostedProfiles = process.env.REACT_APP_BOOST_ENABLED === 'true';
export const billingMaintenance = process.env.REACT_APP_BILLING_MAINTENANCE === 'true';

export const allowedPostalLookupCountry = process.env.REACT_APP_POSTAL_LOOKUP_ALLOWED_COUNTRY || 'US';
export const countryWithTaxByProvince = process.env.REACT_APP_COUNTRY_WITH_TAX_BY_PROVINCE || '';
export const enabledPostalLookup = process.env.REACT_APP_POSTAL_LOOKUP_ALLOWED_ENABLE === 'true';

export const MAX_SUBSCRIPTION_HRS = 24;

export const searchMicroserviceEnabled = process.env.REACT_APP_SEARCH_MICROSERVICE_ENABLED === 'true';
export const searchMicroserviceUrl =
  process.env.REACT_APP_SEARCH_MICROSERVICE_URL || 'https://search-dev.rflxm.io/search';
export const searchMicroserviceKey =
  process.env.REACT_APP_SEARCH_MICROSERVICE_KEY || '6e2a62f6-5745-4ad2-95ad-ee1b4cfe2cda';
export const searchMicroserviceBoost = process.env.REACT_APP_SEARCH_MICROSERVICE_BOOST === 'true';
export const messagingReminderEnabled = process.env.REACT_APP_MESSAGING_REMINDER === 'true';
export const auth10tixOrigin = process.env.REACT_APP_AUTH10TIX_ORIGIN || 'https://secure-me.au10tixservices.com';
export const auth10tixVerification = process.env.REACT_APP_AUTH10TIX_VERIFICATION === 'true';

export const vysionApiKey = process.env.REACT_APP_VYSION_API_KEY || 'd0285d1de9674404bb2584e018fbddbc';
export const vysionStage =
  process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'prodfd' ? 'prod' : 'sandbox';
export const vysionEnabled = process.env.REACT_APP_VYSION_ENABLED === 'true' && process.env.REACT_APP_ENV !== 'dev'; //disable vysion if env is dev (local)

export const idvPurchaseEnabled = process.env.REACT_APP_IDV_PURCHASE === 'true';

export const nuxEnabled = process.env.REACT_APP_NUX_ENABLED === 'true';

export const DDC_FINGER_PRINTING_URL = process.env.REACT_APP_DDC_FINGER_PRINTING_URL;

export const GENERAL_MESSAGE_USERNAME = 'Administrator';
export const GENERAL_MESSAGE_AVATAR = `${s3BucketDirect}admin_message_logo/WYP_AdminAvatar_512.png`;

export const tomtomEnabled = process.env.REACT_APP_ENABLE_TOMTOM === 'true';
export const tomtomApiKey = process.env.REACT_APP_TOMTOM_API_KEY;

export const NICKNAMER_URL = process.env.REACT_APP_NICKNAMER_URL || 'https://outsourcer-sandbox.rflxm.io';
export const NICKNAMER_CLIENT_ID = process.env.REACT_APP_NICKNAMER_CLIENT_ID || '1fe6cee7-99c7-44e7-838f-0033b6e27e5b';
export const NICKNAMER_ENABLED = process.env.REACT_APP_NICKNAMER_ENABLED === 'true';

export const isProdEnv = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'prodfd';

export const ADDRESS_COMPLETER_MICROSERVICE_ENABLED = process.env.REACT_APP_ADDRESS_COMPLETER_MICROSERVICE_ENABLED;
export const ADDRESS_COMPLETER_MICROSERVICE_URI = process.env.REACT_APP_ADDRESS_COMPLETER_MICROSERVICE_URI;
export const ADDRESS_COMPLETER_MICROSERVICE_CLIENT_ID = process.env.REACT_APP_ADDRESS_COMPLETER_MICROSERVICE_CLIENT_ID;
export const LOCATIONS_MICROSERVICE_URI =
  process.env.REACT_APP_LOCATIONS_MICROSERVICE_URI || 'https://outsourcer-dev.rflxm.io/v2/location';
export const LOCATIONS_MICROSERVICE_CLIENT_ID =
  process.env.REACT_APP_LOCATIONS_MICROSERVICE_CLIENT_ID || '2f4968bf-7344-4c7b-bd04-8350ce1bc069';

export const WYP_GIF_LOADER = `${s3BucketDirect}loader/wyp_imageloader.gif`;
export const WYP_PRIVATE_IMG_FALLBACK = `${s3BucketDirect}private_img_fallback.png`;
export const PWA_ASSETS_URL = `${s3BucketDirect}pwa`;

export const IS_IPQA_ENABLED = process.env.REACT_APP_ENV !== 'local';
export const WYP_IPQS_KEY = process.env.REACT_APP_IPQS_KEY_WYP;
export const FD_IPQS_KEY = process.env.REACT_APP_IPQS_KEY_FD;

export const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

export const EXCLUDE_HTTP_REQUESTS = ['mixpanel/track-event'];

export const MasterConfig = {
  api,
  per_page,
  BASE_URL,
  SITE_NAME,
  SITE_ACRONYM,
  SITE_ID,
  FULL_URL,
  SOCIAL_LINKS,
  MAX_NUMBER_PHOTOS,
  MAX_PHOTO_FILE_SIZE,
  EXPIRE_SEARCH_HOURS,
  GOOGLE_AUTH,
  FACEBOOK_AUTH,
  apiRTNotify,
  apiRTNotifyKey,
  MAX_COOKIE_AGE,
  EXP_MESSAGE_DAYS,
  PWA_PUBLIC_KEY,
  notificationExpiration,
  apiGiphyKey,
  enabledOfferHighlights,
  enabledPhotoMessage,
  billingMaintenance,
  enabledPostalLookup,
  allowedPostalLookupCountry,
  MAX_SUBSCRIPTION_HRS,
  searchMicroserviceEnabled,
  messagingReminderEnabled,
  searchMicroserviceUrl,
  searchMicroserviceKey,
  enableBoostedProfiles,
  searchMicroserviceBoost,
  s3BucketDirect,
  auth10tixOrigin,
  auth10tixVerification,
  vysionApiKey,
  vysionStage,
  vysionEnabled,
  googleMapKey,
  idvPurchaseEnabled,
  nuxEnabled,
  isFD,
  DDC_FINGER_PRINTING_URL,
  tomtomEnabled,
  tomtomApiKey,
  NICKNAMER_URL,
  NICKNAMER_CLIENT_ID,
  NICKNAMER_ENABLED,
  isProdEnv,
  countryWithTaxByProvince,
  ADDRESS_COMPLETER_MICROSERVICE_ENABLED,
  ADDRESS_COMPLETER_MICROSERVICE_URI,
  ADDRESS_COMPLETER_MICROSERVICE_CLIENT_ID,
  IS_LOCAL,
};

export default MasterConfig;
