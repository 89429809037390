import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { SITE_ACRONYM, SITE_NAME } from '../../../config/Master';
import { dimensions } from '../../../style';

const CenterDashDiv = styled('div')({
  textAlign: 'center',
  borderTop: '1px dashed #8F8F8F',
  padding: 20,
  marginTop: 10,
  [dimensions.SCREEN_MAX_SM]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
  },
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: 10,
  },
});

const SpanText = styled('span')({
  fontSize: 16,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 15,
  },
  [dimensions.SCREEN_MAX_XS]: {
    fontSize: 14,
  },
});

const TransactionAwareness = (): ReactElement => {
  return (
    <CenterDashDiv>
      <SpanText>
        {SITE_ACRONYM.toUpperCase()} Date Offers are <b>not</b> exchanged or processed through the {SITE_NAME} app.
        Besides in person cash transactions, you may also safely utilize the services of available online 3rd party
        payment processors and escrow apps.
      </SpanText>
    </CenterDashDiv>
  );
};

export default TransactionAwareness;
