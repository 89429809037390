export const STORE_META = 'STORE_META';
export const STORE_PROFILE_MAP_FIELDS = 'STORE_PROFILE_MAP_FIELDS';
export const STORE_DESCRIPTORS = 'STORE_DESCRIPTORS';

export interface Meta {
    meta: Record<string, unknown>;
}

export interface MapFields {
    map_fields: {
        children: string[];
        drinking: string[];
        smoking: string[];
        income: {
            0: string;
            101: string;
            102: string;
            103: string;
            104: string;
        }
    }
}

export interface Descriptors {
    descriptors: string | null;
}

export interface MetaState extends MapFields, Descriptors {
    mail_current_page: number;
    mail_total_pages: number;
    mailItems_current_page: number;
    mailItems_total_pages: number;

    fav_current_page: number;
    fav_total_pages: number;
    fav_viewed_current_page: number;
    fav_viewed_total_pages: number;
    fav_to_current_page: number;
    fav_to_total_pages: number;
    fav_from_current_page: number;
    fav_from_total_pages: number;

    search_current_page: number;
    search_total_pages: number;

    offer_current_page: number;
    offer_total_pages: number;
    offer_new_current_page: number;
    offer_new_total_pages: number;
    offer_sent_current_page: number;
    offer_sent_total_pages: number;
    offer_accepted_current_page: number;
    offer_accepted_total_pages: number;
    offer_rejected_current_page: number;
    offer_rejected_total_pages: number;
    offer_highlights_current_page: number;
    offer_highlights_total_pages: number;

    credit_current_page: number;
    credit_total_pages: number;
    search_scroll_height: number;
    search_last_time: number | null;
}

interface STORE_META_ACTION {
    type: typeof STORE_META;
    payload: Meta;
}

interface STORE_PROFILE_MAP_FIELDS_ACTION {
    type: typeof STORE_PROFILE_MAP_FIELDS;
    payload: MapFields;
}

interface STORE_DESCRIPTORS_ACTION {
    type: typeof STORE_DESCRIPTORS;
    payload: Descriptors;
}

export type MetaActionType = 
    | STORE_META_ACTION
    | STORE_PROFILE_MAP_FIELDS_ACTION
    | STORE_DESCRIPTORS_ACTION;

