import React from 'react';
import LoadingV2 from '../../../atoms/loader/LoadingV2';
import styled from '@emotion/styled';

const Container = styled('div')({
  position: 'fixed',
  width: '100vw',
  height: '110vh',
  background: 'rgba(0, 0, 0, 0.6)',
  left: 0,
  top: 0,
  zIndex: 995,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FFF',
  textAlign: 'center',
  marginTop: 0,
});

const LoadingContent = styled('div')({});

const LoadingLabel = styled('div')({
  color: '#FFF',
  textAlign: 'center',
});

interface IBillingLoadingProps {
  isLoading: boolean;
  showLabel: boolean;
  label: string | null;
}

const BillingLoading: React.FC<IBillingLoadingProps> = ({
  isLoading = false,
  showLabel = true,
  label = 'Loading...',
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Container>
      <LoadingContent>
        <LoadingV2 />
        {showLabel && <LoadingLabel>{label}</LoadingLabel>}
      </LoadingContent>
    </Container>
  );
};

export default BillingLoading;
