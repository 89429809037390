import React, { useEffect, useRef, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { backgroundTheme, dimensions, patternModal, textColor } from '../../style';
import Button from '../buttons/Button';
import CloseIcon from '@material-ui/icons/Close';
import {
  sendToastMessageView,
  sendToastMessageClick,
  sendToastFavouriteView,
  sendToastFavouriteClick,
  sendToastOfferView,
  sendToastOfferClick,
} from '../../actions/toastTrackAction';
import { triggerTracking } from '../../utils/helpers';
import ModalPattern from '../../components/common/ModalPattern';
import { StarIcon } from '../icons/favoriteIcons';
import { getBrowserDetails } from '../../utils/helpers';

const activityColor = {
  blue: {
    panelColor: '#2B8FD7',
    fontColor: '#81ECEC',
  },
  green: {
    panelColor: '#00B894',
    fontColor: '#005B49',
  },
  gold: {
    panelColor: '#BF9200',
    fontColor: '#7F5700',
  },
  white: {
    panelColor: '#FFFFFF',
    fontColor: '#333333',
  },
  red: {
    panelColor: patternModal.titleColor,
    fontColor: patternModal.titleColor,
  },
};

const AlertContainer = styled('div')`
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 460px;
  min-height: 72px;
  padding: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin-top: 10px;
  background: ${patternModal.bgGradient};
  border: ${patternModal.borderStyle};

  ${dimensions.SCREEN_MAX_XS} {
    width: 296px;
    min-height: 68px;
    padding: 4px 14px;
    margin: 10px auto 0;
    position: fixed;
    bottom: 5%;
    left: 0;
    right: 0;
  }
`;

const Avatar = styled('div')(
  {
    width: 100,
    height: 100,
    background: '#000000',
    flexShrink: 0,
    borderRadius: 4,
    [dimensions.SCREEN_MAX_XS]: {
      width: 75,
      height: 75,
    },
  },
  ({ backgroundUrl }) =>
    backgroundUrl && {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
);

const MessageContainer = styled('div')`
  flex: 1;
  padding-left: 14px;

  ${dimensions.SCREEN_MAX_SM} {
    padding-left: 10px;
  }
`;

const Message = styled('div')`
  font-size: 22px;
  font-weight: 800;
  color: ${props => (props.color ? activityColor[props.color].fontColor : 'inherit')};

  ${dimensions.SCREEN_MAX_XS} {
    font-size: 14px;
  }
`;

const Action = styled('div')(
  {
    color: 'white',
    fontSize: getBrowserDetails().isMobile ? 12 : 16,
    fontWeight: 700,
    textAlign: 'right',
    paddingTop: 10,
    // textDecoration: 'underline',
    padding: '8px 20px',
    borderRadius: 20,
    position: 'absolute',
    right: getBrowserDetails().isMobile ? 5 : 15,
    bottom: getBrowserDetails().isMobile ? 5 : 15,
  },
  ({ bgColor }) =>
    bgColor && {
      background: activityColor[bgColor].panelColor,
    }
);

const OfferAlertContainer = styled('div')`
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  min-height: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin: 40px auto 0;
  position: relative;
  background-clip: padding-box;
  box-sizing: border-box;
  border: solid 5px transparent;
  top: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(135deg, #ff0099 0%, #ffba72 100%);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }
  ${dimensions.SCREEN_MAX_XS} {
    top: 47%;
    transform: translateY(-50%);
  }
  ${dimensions.SCREEN_MIN_XXS} {
    width: 300px;
    margin: 15px auto 0;
  }
  ${dimensions.SCREEN_MIN_XS} {
    width: 320px;
    margin: 30px auto 0;
  }
  ${dimensions.SCREEN_MIN_SM} {
    width: 360px;
    margin: 40px auto 0;
  }
`;

const OfferAvatar = styled('div')(
  {
    width: '100%',
    height: 340,
    background: '#000000',
    flexShrink: 0,
    borderRadius: '20px 20px 0px 0px',
    position: 'relative',
  },
  ({ backgroundUrl }) =>
    backgroundUrl && {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundPosition: 'top',
      backgroundSize: 'cover',
    }
);

const OfferMessageContainer = styled('div')`
  flex: 1;
  padding: 14px;
  border-radius: 0px 0px 20px 20px;
  background-color: ${props => (props.color ? activityColor[props.color].panelColor : backgroundTheme.block)};
`;

const OfferMessage = styled('div')`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${props => (props.color ? activityColor[props.color].fontColor : textColor.DARK.color)};
`;

const OfferInfo = styled('div')`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
`;

const OfferSpan = styled('span')({
  borderRadius: 5,
  padding: 10,
  background: backgroundTheme.block,
  ...textColor.DARK,
});

const BackdropContainer = styled('div')`
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const OfferClose = styled('a')`
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 10px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  color: #fff;
  z-index: 4;
`;

const OfferProfileContainer = styled('div')`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-align: left;
  z-index: 3;
`;
const OfferAmount = styled('span')({
  fontSize: 22,
  fontWeight: 600,
  ...textColor.DARK,
});

const OfferUsername = styled('div')`
  font-weight: bold;
`;
const OfferProfileDetail = styled('div')``;

const StyleLink = styled('a')({
  ...textColor.DARK,
});

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81%, rgba(0,0,0,1) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
});

const timeOutFunc = (timeout, onTimeout) => {
  if (timeout > 0 && typeof onTimeout === 'function') {
    setTimeout(() => onTimeout(), timeout);
  }
};

const modalPatternCustomStyle = {
  width: '100%',
  position: 'relative',
};

interface OfferAlertProps {
  timeout: any;
  onTimeout: any;
  color: any;
  avatar: any;
  message: any;
  offerAmount: any;
  userName: any;
  userAge: any;
  userLocation: any;
  onClick: any;
  onClose: any;
}

export const OfferAlert = ({
  timeout,
  onTimeout,
  color,
  avatar,
  message,
  offerAmount,
  userName,
  userAge,
  userLocation,
  onClick,
  onClose,
}: OfferAlertProps): ReactElement => {
  useEffect(() => {
    timeOutFunc(timeout, onTimeout);
  }, [timeout, onTimeout]);

  return (
    <BackdropContainer>
      <OfferAlertContainer id="offer-alert" color={color}>
        <OfferAvatar backgroundUrl={avatar}>
          <OfferClose
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            <CloseIcon style={{ fontSize: 28 }} />
          </OfferClose>
          <OfferProfileContainer>
            <OfferUsername>{userName}</OfferUsername>
            <OfferProfileDetail>
              {' '}
              {userAge} • {userLocation}
            </OfferProfileDetail>
          </OfferProfileContainer>
          <Gradient />
        </OfferAvatar>
        <OfferMessageContainer>
          <OfferMessage color={color}>
            <OfferSpan>{message}</OfferSpan>
          </OfferMessage>
          <OfferInfo>
            <OfferAmount>{offerAmount}</OfferAmount>
          </OfferInfo>
          <OfferInfo>
            <Button buttonType="primary" onClick={onClick}>
              Send a message
            </Button>
          </OfferInfo>
          <OfferInfo>
            <StyleLink
              onClick={e => {
                e.preventDefault();
                onTimeout();
              }}
            >
              Not yet
            </StyleLink>
          </OfferInfo>
        </OfferMessageContainer>
      </OfferAlertContainer>
    </BackdropContainer>
  );
};

const setToastAction = (e, eventType) => {
  switch (eventType) {
    case 'click_toast_message':
      sendToastMessageClick(e);
      break;
    case 'click_toast_favourite':
      sendToastFavouriteClick(e);
      break;
    case 'click_toast_offer':
      sendToastOfferClick(e);
      break;
    default:
      return;
  }
};

interface ActivityAlertProps {
  timeout: any;
  onTimeout: any;
  color: any;
  avatar: any;
  message: any;
  actionText: any;
  notificationType: any;
  onClick: any;
  hasFavIcon: boolean;
}

const ActivityAlert = ({
  timeout,
  onTimeout,
  color,
  avatar,
  message,
  actionText,
  notificationType,
  onClick,
  hasFavIcon = false,
}: ActivityAlertProps): ReactElement => {
  const clickToastForMessage = useRef(null);
  const clickToastForFavourite = useRef(null);
  const clickToastForOffer = useRef(null);

  useEffect(() => {
    timeOutFunc(timeout, onTimeout);
    clickToastForMessage.current = document.getElementById('activity-alert-mail');
    clickToastForFavourite.current = document.getElementById('activity-alert-favorites');
    clickToastForOffer.current = document.getElementById('activity-alert-offers');
    triggerTracking('click_toast_message', clickToastForMessage, setToastAction);
    triggerTracking('click_toast_favourite', clickToastForFavourite, setToastAction);
    triggerTracking('click_toast_offer', clickToastForOffer, setToastAction);
  }, [onTimeout, timeout]);

  useEffect(() => {
    switch (notificationType) {
      case 'favorites':
        sendToastFavouriteView(null);
        break;
      case 'mail':
        sendToastMessageView(null);
        break;
      case 'offers':
        sendToastOfferView(null);
        break;
      default:
        return;
    }
  }, [notificationType]);

  return (
    <AlertContainer id={`activity-alert-${notificationType}`} color={color} onClick={onClick}>
      <ModalPattern
        padding={getBrowserDetails().isMobile ? 5 : 15}
        customStyle={modalPatternCustomStyle}
        backgroundSize="65%"
      >
        <Avatar backgroundUrl={avatar} />
        <MessageContainer>
          <Message color={'red'}>
            {hasFavIcon && (
              <StarIcon
                width={getBrowserDetails().isMobile ? 12 : 18}
                height={getBrowserDetails().isMobile ? 12 : 18}
                isActive
                fill={patternModal.titleColor}
              />
            )}
            {message}
          </Message>
          <Action bgColor={color}>
            {hasFavIcon && (
              <StarIcon
                width={getBrowserDetails().isMobile ? 12 : 18}
                height={getBrowserDetails().isMobile ? 12 : 18}
                isActive
                fill="#FFFFFF"
              />
            )}
            {actionText}
          </Action>
        </MessageContainer>
      </ModalPattern>
    </AlertContainer>
  );
};

ActivityAlert.propTypes = {
  /** Defines color theme of activity alert */
  color: PropTypes.oneOf(['blue', 'green', 'gold', 'white', 'red']),
  /** Avatar url */
  avatar: PropTypes.string,
  /** Message */
  message: PropTypes.string.isRequired,
  /** Text of action button */
  actionText: PropTypes.string.isRequired,
  /** Function to handle clicking on activity alert */
  onClick: PropTypes.func.isRequired,
  /** Timer before ontimeout prop is triggered */
  timeout: PropTypes.number,
  /** Triggers upon finishing the timeout */
  onTimeout: PropTypes.func,
  /** Triggers close event */
  onClose: PropTypes.func,
  /** Alert Type */
  notificationType: PropTypes.string,
};

export default ActivityAlert;
