import { useEffect } from 'react';

const preventDefault = e => {
  e.preventDefault();
};

export const disableScroll = (): void => {
  document.body.addEventListener('touchmove', preventDefault, { passive: false });
};

export const enableScroll = (): void => {
  document.body.removeEventListener('touchmove', preventDefault);
};

export const useDisableBodyScroll = (open: boolean, enableTouchMove = true): void => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      if (enableTouchMove) {
        enableScroll();
      } else {
        disableScroll();
      }
    } else {
      document.body.style.overflow = 'unset';
      enableScroll();
    }
  }, [open, enableTouchMove]);
};

export const useDisableBodyElementScroll = (open: boolean): any => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
};

export const unsetBodyScrollOverflow = (): any => {
  document.body.style.overflow = 'unset';
};

export const useDisableXBodyElementScroll = (): any => {
  document.body.style.overflowX = 'hidden';
};
