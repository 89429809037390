import {
  HIDE_UPLOAD_SNACKBAR,
  IUploadSnackbar,
  SHOW_UPLOAD_SNACKBAR,
  UPDATE_UPLOAD_SNACKBAR_STATUS,
  UploadSnackbarActionTypes,
  IUploadSnackbarItem,
  UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE,
  INCREMENT_UPLOAD_SNACKBAR_RETRY,
} from './types/uploadSnackbar.d';

export const MAX_RETRY = 3;

const initialState: IUploadSnackbar = {
  show: false,
  uploadType: 'photo',
  items: new Array<IUploadSnackbarItem>(),
};

const uploadSnackbar = (state = initialState, action: UploadSnackbarActionTypes): IUploadSnackbar => {
  switch (action.type) {
    case SHOW_UPLOAD_SNACKBAR:
      return {
        ...state,
        show: true,
        items: [...state.items, { ...action.payload, retry: 0 }],
      };

    case HIDE_UPLOAD_SNACKBAR:
      return {
        ...initialState,
      };

    case UPDATE_UPLOAD_SNACKBAR_STATUS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, status: action.payload.status };
          }
          return item;
        }),
      };

    case INCREMENT_UPLOAD_SNACKBAR_RETRY:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, retry: item.retry + 1 };
          }
          return item;
        }),
      };

    case UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE:
      return {
        ...state,
        uploadType: action.payload,
      };

    default:
      return state;
  }
};

export default uploadSnackbar;
