import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import SliderArrowLeftIcon from '../icons/SliderArrowLeftIcon';
import SliderArrowRightIcon from '../icons/SliderArrowRightIcon';
import SliderPlayIcon from '../icons/SliderPlayIcon';
import DiscoverModePrivateIcon from '../icons/DIscoverModePrivateIcon';
import { WYP_PRIVATE_IMG_FALLBACK } from '../../config/Master';
import PrivateMonkeyIcon from '../icons/PrivateMonkeyIcon';
import { Profile, ProfileMaker } from '../../models/Profile';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useProfile } from '../../contexts/ProfileContext';
import { mixpanelTrackLightBoxOpened } from '../../utils/mixpanel/lightboxOpened';
import { updateMixpanelProfileClosedPayload } from '../../utils/mixpanel/profileClosed';
import useMixPanel from '../../hooks/useMixPanel';

const SliderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
`;

const Slide = styled.div`
  position: relative;

  height: 100%;
`;

const Img = styled('div')(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  ({ imgUrl }) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
);

const PrivateDetailsContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  textAlign: 'center',
  zIndex: 1,
});

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 82.5%)',
  position: 'absolute',
  width: '100%',
  height: 130,
  bottom: 0,
  left: 0,
  zIndex: 1,
});

const MediaStatus = styled('div')({
  position: 'absolute',
  bottom: 11,
  right: 15,
  zIndex: 2,
  color: '#fff',
  fontSize: 12,
  background: '#FFAF00',
  borderRadius: 15,
  padding: '6px 14px',
});

const SliderArrowContainer = styled('div')({
  position: 'absolute',
  transform: 'translate(0px,-50%)',
  top: '50%',
  height: 245,
  width: 50,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const AnimatedOpacityIcon = styled('div')(
  {
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  (props: { visible: boolean }) => ({
    opacity: props.visible ? 1 : 0,
  })
);

const PrivateLabelCount = styled('p')({
  fontSize: 20,
  fontWeight: 900,
  color: '#fff',
  lineHeight: '20px',
  letterSpacing: '-0.4px',
  margin: 0,
  padding: 0,
});

const PrivateLabelInfo = styled('p')({
  fontSize: 14,
  fontWeight: 500,
  color: '#fff',
  lineHeight: '20px',
  letterSpacing: '-0.28px',
  margin: 0,
  padding: 0,
  maxWidth: 105,
});

interface ProfileMediaSliderProps {
  photos: any;
  contentWidth?: string;
  currentSlide: number;
  privateCount: number;
  onClickPhoto: (index: number, e: Record<string, unknown>) => void;
  profile: Profile;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
  isOtherProfile: boolean;
}

const ProfileMediaSlider = ({
  photos,
  currentSlide,
  privateCount,
  onClickPhoto,
  profile,
  setCurrentSlide,
  isOtherProfile,
}: ProfileMediaSliderProps): JSX.Element => {
  const { setMixpanelDataByKeyHandler } = useMixPanel();
  const [arrowVisible, setArrowVisible] = useState(true);
  const { gallerySwiper, setGallerySwiper } = useProfile();
  const hasNavigatedSlideGalleries = sessionStorage.getItem('hasNavigatedSlideGalleries');
  const [photoViewCount, setPhotoViewCount] = useState(0);

  useEffect(() => {
    setArrowVisible(true);

    const timer = setTimeout(() => {
      // add condition to check for current session
      if (currentSlide > 0 || hasNavigatedSlideGalleries) {
        setArrowVisible(false);
      }
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide, photos, hasNavigatedSlideGalleries]);

  useEffect(() => {
    if (gallerySwiper?.realIndex === 0 && photos.length > 1 && photoViewCount === 0) {
      gallerySwiper.slideToLoop(0, 0);
    }
  }, [gallerySwiper, photos, photoViewCount]);

  useEffect(() => {
    if (!hasNavigatedSlideGalleries && currentSlide > 0) {
      sessionStorage.setItem('hasNavigatedSlideGalleries', 'true');
    }
  }, [currentSlide, hasNavigatedSlideGalleries]);

  useEffect(() => {
    if (photoViewCount > 0) {
      updateMixpanelProfileClosedPayload(setMixpanelDataByKeyHandler, photoViewCount);
    }
  }, [photoViewCount]);

  return (
    <SliderContainer>
      <Swiper
        allowSlidePrev={true}
        allowSlideNext={true}
        onSwiper={swiper => {
          setGallerySwiper(swiper);
        }}
        style={{ height: '100%' }}
        loop={photos.length > 1}
        onSlideChange={swiperCore => {
          setCurrentSlide(swiperCore.realIndex);
          setPhotoViewCount(count => count + 1);
        }}
      >
        {photos.map((photo, index) => {
          const photoUrl = photo.image;
          const imgUrl = photo.private ? WYP_PRIVATE_IMG_FALLBACK : photoUrl;

          return (
            <SwiperSlide key={index}>
              <Slide
                onClick={e => {
                  onClickPhoto(index, e);

                  if (profile.hashId() && photo.type == 'photo') {
                    if (isOtherProfile) {
                      mixpanelTrackLightBoxOpened('Main Photo');
                    }
                  }
                }}
              >
                <Img className="data-avatar-image-url" imgUrl={imgUrl} />
                {photo.type === 'video' && (
                  <SliderPlayIcon
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -35%)',
                      zIndex: 2,
                    }}
                    onClick={e => {
                      onClickPhoto(index, e);
                    }}
                  />
                )}

                {photo.private && (
                  <PrivateDetailsContainer>
                    <DiscoverModePrivateIcon style={{ marginBottom: 20 }} />
                    <PrivateLabelCount>{`+ ${privateCount}`}</PrivateLabelCount>
                    <PrivateLabelInfo>Private Photos</PrivateLabelInfo>
                    <PrivateLabelInfo style={{ marginTop: 20 }}>Accepted offers get access!</PrivateLabelInfo>
                  </PrivateDetailsContainer>
                )}
                {photo.privateReal && (
                  <PrivateMonkeyIcon style={{ position: 'absolute', top: 15, right: 15, zIndex: 2 }} />
                )}

                {photo.status === 'new' && <MediaStatus>Pending</MediaStatus>}

                <Gradient />
              </Slide>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {photos.length > 1 && (
        <>
          <SliderArrowContainer
            onClick={() => {
              if (gallerySwiper) {
                gallerySwiper.slidePrev();
              }
            }}
            style={{ left: 0, paddingLeft: '15px', zIndex: 2 }}
          >
            <AnimatedOpacityIcon visible={arrowVisible}>
              <SliderArrowLeftIcon />
            </AnimatedOpacityIcon>
          </SliderArrowContainer>

          <SliderArrowContainer
            onClick={() => {
              if (gallerySwiper) {
                gallerySwiper.slideNext();
              }
            }}
            style={{ right: 0, paddingRight: '15px', zIndex: 2, justifyContent: 'flex-end' }}
          >
            <AnimatedOpacityIcon visible={arrowVisible}>
              <SliderArrowRightIcon />
            </AnimatedOpacityIcon>
          </SliderArrowContainer>
        </>
      )}
    </SliderContainer>
  );
};

const mapStateToProps = state => {
  return {
    profile: ProfileMaker.create(state.profile.profile),
  };
};

export default connect(mapStateToProps, {})(ProfileMediaSlider);
