import React, { ReactElement, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CheckboxV2 } from 'swui';
import styled from '@emotion/styled';
import { CheckCircleRounded } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';

const CheckboxInput = styled(CheckboxV2)({
  position: 'absolute',
  width: '0',
  opacity: '0',
});

const CheckboxContainer = styled('div')(
  {
    position: 'relative',
  },
  ({ customStyle }) =>
    customStyle && {
      ...customStyle,
    }
);

const StyledRoundedCheckbox = styled('div')(
  {
    backgroundColor: '#C9C9C9',
    border: '2px solid #FFFFFF',
    borderRadius: '50%',
    cursor: 'pointer',
    height: 22,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ checked }) =>
    checked && {
      backgroundColor: '#FFFFFF',
    },
  ({ customStyle }) =>
    customStyle && {
      ...customStyle,
    }
);

const checkedStyle = () => {
  return {
    fontSize: 26,
    color: '#098a00',
  };
};

interface RoundedCheckboxProps {
  isChecked: boolean;
  onChange: any;
  customStyle: any;
  containerStyle: any;
  ref: any;
  mediaIds?: any;
  data: any;
}

const RoundedCheckbox = forwardRef(
  ({ isChecked, onChange, containerStyle, customStyle, mediaIds, data }: RoundedCheckboxProps, ref): ReactElement => {
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
      if (mediaIds && data) {
        const privacy = data.private ? 'private' : 'public';

        if (!mediaIds[data.type][privacy].includes(data.id)) {
          setChecked(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaIds]);

    const handleOnClick = () => {
      setChecked(!checked);
      onChange();
    };

    return (
      <CheckboxContainer customStyle={containerStyle}>
        <CheckboxInput ref={ref} checked={checked} value={checked} />
        <StyledRoundedCheckbox customStyle={customStyle} checked={checked} onClick={handleOnClick}>
          {checked && <SvgIcon id="CheckCircleRounded" component={CheckCircleRounded} style={checkedStyle()} />}
        </StyledRoundedCheckbox>
      </CheckboxContainer>
    );
  }
);

RoundedCheckbox.defaultProps = {
  hasError: false,
  isChecked: false,
};

RoundedCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.any,
  customStyle: PropTypes.any,
  inline: PropTypes.bool,
  isChecked: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default RoundedCheckbox;
