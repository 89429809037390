import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Status: string;
}
interface UserProperties {
  'Profile views hidden': boolean;
}

export const mixpanelTrackToggleHideProfileViews = async (
  eventProperties: EventProperties,
  userProperties: UserProperties
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Toggle - Hide profile views',
    payload: {
      event_properties: eventProperties,
      user_properties: userProperties,
    },
  });
};
