import {
  CLEAR_DDC_DATA,
  ClearDDCData,
  SAVE_DATA_COLLECTOR,
  SaveDataCollector,
  UPDATE_DDC_IFRAME_LOADED,
  UPDATE_FINGER_PRINT_START_TIME,
  UPDATE_FINGER_PRINT_STATUS,
  UpdateDDCIFrameLoaded,
  UpdateFingerPrintStartTime,
  UpdateFingerPrintStatus,
} from '../reducers/types/DDC.d';

export const saveDataCollector: SaveDataCollector = payload => ({
  type: SAVE_DATA_COLLECTOR,
  payload,
});

export const updateFingerPrint: UpdateFingerPrintStatus = (gateway, ddcReferenceId) => ({
  type: UPDATE_FINGER_PRINT_STATUS,
  gateway,
  ddcReferenceId: ddcReferenceId || '',
});

export const updateFingerPrintStartTime: UpdateFingerPrintStartTime = gateway => ({
  type: UPDATE_FINGER_PRINT_START_TIME,
  gateway,
});

export const clearDDCData: ClearDDCData = () => ({
  type: CLEAR_DDC_DATA,
});

export const updateDCCIFrameLoaded: UpdateDDCIFrameLoaded = gateway => ({
  type: UPDATE_DDC_IFRAME_LOADED,
  gateway,
});
