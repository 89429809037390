import React, { ReactElement } from 'react';
interface CapturePhotoProps {
  customStyle?: any;
}

const CapturePhoto = ({ customStyle }: CapturePhotoProps): ReactElement => {
  return (
    <svg
      width="42"
      height="37"
      style={{ ...customStyle }}
      viewBox="0 0 42 37"
      data-test-id="capture-photo-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_852_5281)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.5358 3.89474H38.0931V1.46142C38.0931 0.655674 37.4371 0 36.627 0C35.817 0 35.161 0.653887 35.161 1.46142V3.89652H32.7183C31.91 3.89652 31.2523 4.55041 31.2523 5.35794C31.2523 6.16548 31.9082 6.81936 32.7183 6.81936H35.161V9.25447C35.161 10.0602 35.817 10.7159 36.627 10.7159C37.4371 10.7159 38.0931 10.062 38.0931 9.25447V6.81936H40.5358C41.3441 6.81936 42.0018 6.16548 42.0018 5.35794C42.0018 4.55041 41.3459 3.89652 40.5358 3.89652V3.89474Z"
          fill="#2B8FD7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.767 8.75596C40.6899 8.76132 40.6128 8.76311 40.5358 8.76311H40.0465V9.25084C40.0465 11.1321 38.5142 12.6596 36.627 12.6596C34.7398 12.6596 33.2075 11.1321 33.2075 9.25084V8.76311H32.7182C30.8311 8.76311 29.3005 7.23558 29.3005 5.35432C29.3005 4.61289 29.5389 3.92684 29.9421 3.36765L29.6859 2.85668C29.107 1.70256 27.9223 0.973633 26.6284 0.973633H15.368C14.0723 0.973633 12.8894 1.70256 12.3106 2.85668L10.461 6.54596C10.3786 6.71033 10.2101 6.81574 10.0237 6.81574H5.37299C2.40512 6.81574 0 9.21332 0 12.1719V31.6456C0 33.0659 0.566332 34.4273 1.57354 35.4331C2.58075 36.4372 3.9482 37.0017 5.37299 37.0017H36.6288C38.0536 37.0017 39.4192 36.4372 40.4282 35.4331C41.4355 34.4291 42.0018 33.0659 42.0018 31.6456V12.1719C42.0018 10.8731 41.5376 9.68319 40.767 8.75596ZM21.0009 11.6842C15.8788 11.6842 11.7209 15.829 11.7209 20.9351C11.7209 26.0411 15.8788 30.1859 21.0009 30.1859C26.123 30.1859 30.2809 26.0411 30.2809 20.9351C30.2809 15.829 26.123 11.6842 21.0009 11.6842ZM21.0009 14.6052C24.5046 14.6052 27.3506 17.4405 27.3506 20.9351C27.3506 24.4296 24.5064 27.2649 21.0009 27.2649C17.4954 27.2649 14.6512 24.4296 14.6512 20.9351C14.6512 17.4405 17.4954 14.6052 21.0009 14.6052Z"
          fill="#2B8FD7"
        />
      </g>
      <defs>
        <clipPath id="clip0_852_5281">
          <rect width="42" height="37" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CapturePhoto;
