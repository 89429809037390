/**
 * @Author: zachalam
 * @Date:   2017-03-15T13:45:38-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-15T13:45:38-07:00
 */

import React from 'react';
import Deactivate from '../components/pages/Settings/Deactivate';
import { connect } from 'react-redux';
import { deactivateAccount } from '../actions/authActions';

const DeactivateContainer = props => {
  return <Deactivate {...props} />;
};

const mapStateToProps = state => {
  return {
    errors: state.common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deactivate: formData => {
      dispatch(deactivateAccount(formData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeactivateContainer);
