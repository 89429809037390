import * as tt from '@tomtom-international/web-sdk-services';
import { LOCATIONS_MICROSERVICE_CLIENT_ID, LOCATIONS_MICROSERVICE_URI, tomtomApiKey } from '../config/Master';

export interface TomtomLocation {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  placeId: string;
  region: string;
}

export interface FormattedTomtomLocation {
  description: string;
  place_id: string;
  active: boolean;
  index: number;
  details: TomtomLocation;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}
export interface PositionCoordinates {
  lat: number;
  lng: number;
}

const TOMTOM_API_KEY = tomtomApiKey;

export const tomtomHasNoResults = (res: any): boolean => {
  if (typeof res.data === 'undefined' || typeof res.data.length === 'undefined') {
    return true;
  }

  if (res.data.length < 1) {
    return true;
  }

  return false;
};

export const extractLocationDetails = (place: TomtomLocation): any => {
  return {
    city: place.city,
    country: place.country,
    latitude: place.latitude,
    longitude: place.longitude,
    region: place.region,
  };
};

export const formatTomTomResults = (response: TomtomLocation[]): FormattedTomtomLocation[] => {
  return response.map((p, idx) => ({
    description: p.name,
    place_id: p.placeId,
    active: true,
    index: idx,
    details: p,
    structured_formatting: {
      main_text: p.name,
      secondary_text: p.name,
    },
  }));
};

/**
 * @deprecated
 * @param placeId
 * @param address
 */
export const searchByPlaceId = (placeId: any, address: any = null): any => {
  if (address) {
    return new Promise((resolve, reject) => {
      tt.services
        .fuzzySearch({
          key: TOMTOM_API_KEY,
          // entityId: placeId, the placeId of TomTom is currently not returning results, email has been sent to their cust rep.
          query: address,
        })
        .then(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  return new Promise((resolve, reject) => {
    tt.services
      .placeById({
        key: TOMTOM_API_KEY,
        // entityId: placeId, the placeId of TomTom is currently not returning results, email has been sent to their cust rep.
        entityId: 'If3vPFcp24MOwyms0fZ8Vg',
      })
      .then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
  });
};

export const searchByCoordinates = async (position: PositionCoordinates): Promise<any> => {
  const requestUrl = `${LOCATIONS_MICROSERVICE_URI}?query=${position.lat},${position.lng}`;

  const response = await fetch(requestUrl, {
    headers: {
      'x-client-id': LOCATIONS_MICROSERVICE_CLIENT_ID,
    },
  });

  const objectResponse = await response.json();

  if (typeof objectResponse.data !== 'undefined') {
    return objectResponse.data;
  }

  return objectResponse;
};

export const searchBytext = async (query: any): Promise<any> => {
  const requestUrl = `${LOCATIONS_MICROSERVICE_URI}?query=${query}`;

  const response = await fetch(requestUrl, {
    headers: {
      'x-client-id': LOCATIONS_MICROSERVICE_CLIENT_ID,
    },
  });

  return await response.json();
};
