import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';
import queryString from 'query-string';

const api = MasterConfig.api;

const getBillingPackages = (token: any, order: string, promoCode?: string, isPreJoin?: boolean): AxiosPromise => {
  let url = `${api}/payment/packages?order=${order}`;

  if (promoCode) {
    url = `${api}/payment/packages?order=${order}&promo_code=${promoCode}${isPreJoin ? '&prejoin=true' : ''}`;
  }

  return axios({
    method: 'get',
    url,
    data: {},
  });
};

const getIDVPackages = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/id-verification/package`,
    data: {},
  });
};

const getKountDetails = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/kount/configs`,
  });
};

const getBillingDescriptors = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/billing/descriptors`,
  });
};

const postBillingPayment = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/payment`,
    data: formData,
  });
};

const postDDC = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/payment/data_collector`,
    data: formData,
  });
};

const postIDVPayment = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/id-verification/purchase`,
    data: formData,
  });
};

const getBillingPayments = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/payment?page=1&per_page=1`,
    data: {},
  });
};

const getPaymentToken = (data?: any): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/payment_token${data ? '?' + queryString.stringify(data) : ''}`,
  });
};

const deleteSavedPaymentToken = (token: any, tokenId: any): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/payment_token/${tokenId}`,
    data: {},
  });
};

const logPaymentVisit = (data: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/payment/visited`,
    data,
  });
};

const checkChargeVat = (data: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/payment/check-vat`,
    data,
  });
};

const getRecurringBillingPackages = (token: any, order: string): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/payment/recurring-packages?order=${order}`,
    data: {},
  });
};

const calculateChargeAmount = (data: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/payment/calculate`,
    data,
  });
};

const getApplePayCredential = (data: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/payment/authorize-applepay?domain=${data.domain}`,
  });
};

export {
  getBillingPackages,
  getKountDetails,
  getBillingDescriptors,
  postBillingPayment,
  getBillingPayments,
  getPaymentToken,
  deleteSavedPaymentToken,
  logPaymentVisit,
  getIDVPackages,
  postIDVPayment,
  postDDC,
  checkChargeVat,
  getRecurringBillingPackages,
  calculateChargeAmount,
  getApplePayCredential,
};
