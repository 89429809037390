import {
  DEFAULT_REASON,
  EvidentlyFeatureExperiment,
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
} from '../../constants/evidently';
import { TrackEvidentlyMetricsParams } from '../../sdk/CommonSDK';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

const handleIsFeatureEvaluate = (): boolean => {
  // add params profile and add condition if you want tho only allow evaluate for a certain condtion
  // eq. profile.isGenerous()
  return true;
};

const handleFeatureCallback = (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled: boolean
): void => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  mixpanelTrackExperimentStarted({
    'Experiment name': EVIDENTLY_FEATURES.MIN_OFFER_INCREASE,
    'Variant name': details.variation ?? 'Control',
  });
};

const featureMetrics = (details: EvidentlyFeatureExperiment): TrackEvidentlyMetricsParams<string>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  return [
    {
      featureName: EVIDENTLY_FEATURES.MIN_OFFER_INCREASE,
      experimentName: EVIDENTLY_EXPERIMENTS.MIN_OFFER_INCREASE,
      hashId: details.userId,
      key: 'minOfferIncreaseOffersSent',
      value: 0,
    },
    {
      featureName: EVIDENTLY_FEATURES.MIN_OFFER_INCREASE,
      experimentName: EVIDENTLY_EXPERIMENTS.MIN_OFFER_INCREASE,
      hashId: details.userId,
      key: 'minOfferIncreaseOfferAccepted',
      value: 0,
    },
    {
      featureName: EVIDENTLY_FEATURES.MIN_OFFER_INCREASE,
      experimentName: EVIDENTLY_EXPERIMENTS.MIN_OFFER_INCREASE,
      hashId: details.userId,
      key: 'minOfferIncreaseAmountPurchased',
      value: 0,
    },
  ];
};

export const minOfferIncreaseEvaluation = [
  {
    featureMetrics,
    featureName: EVIDENTLY_FEATURES.MIN_OFFER_INCREASE,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
