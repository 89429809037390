import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const Input = styled('input')`
  display: none;
  cursor: pointer;
  &:checked + label::before {
    border: 3px solid #cae3f5;
    background: #2b8fd7;
  }
`;

const Label = styled('label')`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 1px solid #2b8fd7;
    border-radius: 50%;
  }
  &::before + input:checked {
    border: 3px solid #cae3f5;
    background: #2b8fd7;
  }
`;

const updateToBold = (label, id, isChecked) => {
  if (isChecked) {
    return <strong>{`${label}`}</strong>;
  }

  return label;
};

interface PackageRadioButtonProps {
  id: any;
  name: any;
  label: any;
  style: any;
  onRadioChange: any;
  value: any;
  checked: any;
  labelId: string | null;
}

const PackageRadioButton = ({
  id,
  name,
  label,
  style,
  onRadioChange,
  value,
  checked,
  labelId = '',
}: PackageRadioButtonProps): ReactElement => {
  return (
    <div style={style}>
      <Input
        type="radio"
        data-test-id={`radio-${name}-${value}`}
        name={name}
        id={id}
        onChange={onRadioChange}
        value={value}
        checked={checked}
      />
      <Label htmlFor={`${id}`} data-test-id={labelId}>
        <span>{updateToBold(label, labelId, checked)}</span>
      </Label>
    </div>
  );
};

export default PackageRadioButton;
