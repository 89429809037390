import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Button = styled('div')({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: '8px',
  marginBottom: 0,

  ':last-child': {
    marginRight: 0,
  },
});

const OtherButton = styled('div')(
  {
    whiteSpace: 'nowrap',
  },
  ({ align }) => {
    return {
      textAlign: align,
    };
  }
);

interface ButtonSetProps {
  align: any;
  children: any;
}

const ButtonSet = ({ align, children }: ButtonSetProps): ReactElement => {
  // Wrap each element in a ButtonSet with a div to control the vertical alignment.
  const renderBtns = () => {
    return React.Children.map(children, function(child) {
      if (child !== null) {
        return <Button>{child}</Button>;
      }
    });
  };

  return <OtherButton align={align}>{renderBtns()}</OtherButton>;
};

ButtonSet.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonSet;
