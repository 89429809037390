import React, {
  useState,
  useContext,
  createContext,
  ReactElement,
  Dispatch,
  SetStateAction,
  CSSProperties,
  useRef,
  useEffect,
} from 'react';
import { getLocalItem } from '../common';
import { ModalWithPatternProps } from '../components/common/modals/ModalWithPattern';
import { DEFAULT_OLD_COMPLETE_MODAL_PROPS } from '../constants/modal';

interface UseAppProps {
  modalState: ModalWithPatternProps;
  setModalState: Dispatch<SetStateAction<any>>;
  modalMobileOverideContentStyle: CSSProperties;
  setModalMobileOverideContentStyle: Dispatch<SetStateAction<CSSProperties>>;
  accountHashId: string;
  setAccountHashId: Dispatch<SetStateAction<string>>;
  profileCompletionStep: number;
  setProfileCompletionStep: Dispatch<SetStateAction<number>>;
  customOverlayStyle?: React.CSSProperties;
  customContentStyle?: React.CSSProperties;
  profileCompletionCallback: () => any;
  setProfileCompletionCallback: Dispatch<SetStateAction<any>>;
  profileCompletionCallbackAfterValidate: () => any;
  setProfileCompletionCallbackAfterValidate: Dispatch<SetStateAction<any>>;
  otpClockRef: any;
  otpResendThreshold: number;
  setOtpResendThreshold: Dispatch<SetStateAction<number>>;
  showOtpCountdown: boolean;
  setShowOtpCountdown: Dispatch<SetStateAction<boolean>>;
  joinPhoneNumber: string;
  setJoinPhoneNumber: Dispatch<SetStateAction<string>>;
  otpRemainingAttempts: number;
  setOtpRemainingAttempts: Dispatch<SetStateAction<number>>;
  hasRequestOtpError: string;
  setHasRequestOtpError: Dispatch<SetStateAction<string>>;
  otpFormInputFocus: number;
  setOtpFormInputFocus: Dispatch<SetStateAction<number>>;
  currentPhoneSettingsStep: number;
  setCurrentPhoneSettingsStep: Dispatch<SetStateAction<number>>;
  forceCloseSliderMenu: number;
  setForceCloseSliderMenu: Dispatch<SetStateAction<number>>;
  paymentSettingsOpen: boolean;
  setPaymentSettingsOpen: Dispatch<SetStateAction<boolean>>;
  oldCompleteProfileModalState: any;
  setOldCompleteProfileModalState: Dispatch<SetStateAction<any>>;
  selectedSpecialOrVipPromo: string | null;
  setSelectedSpecialOrVipPromo: Dispatch<SetStateAction<string | null>>;
  forceResetTabs: number;
  setForceResetTabs: Dispatch<SetStateAction<number | null>>;
  isEvaluating: boolean;
  setIsEvaluating: Dispatch<SetStateAction<boolean>>;
  siteFooterRef: any;
  siteFooterLogoRef: any;
  pwaBannerState: any;
  setPwaBannerState: Dispatch<SetStateAction<any>>;
  showInstallationBanner: boolean;
  setShowIntallationBanner: Dispatch<SetStateAction<boolean>>;
  isAccountIsMixpanelEligableEnabled: boolean;
  setIsAccountIsMixpanelEligableEnabled: Dispatch<SetStateAction<boolean>>;
  footerTopPosition: number;
}

const AppContext = createContext({});

const AppProvider = (props: any): ReactElement => {
  const [modalState, setModalState] = useState<ModalWithPatternProps>({
    dataTestID: '',
    isModalOpen: false,
    modalContentLabel: '',
    closeOnOverlayClick: true,
    closeRequest: () => undefined,
    closeBtnClassName: '',
    closeBtnDataTestId: '',
    onCloseClick: () => undefined,
    children: <div />,
    hideCloseBtn: false,
    customOverlayStyle: {},
    customContentStyle: {},
    withoutBgPattern: false,
    enableTouchMove: true,
  });
  const [accountHashId, setAccountHashId] = useState<string>('');
  const [isAccountIsMixpanelEligableEnabled, setIsAccountIsMixpanelEligableEnabled] = useState<boolean>(false);
  const [modalMobileOverideContentStyle, setModalMobileOverideContentStyle] = useState({});
  const [profileCompletionStep, setProfileCompletionStep] = useState(2);
  const [profileCompletionCallback, setProfileCompletionCallback] = useState(() => () => undefined);
  const [profileCompletionCallbackAfterValidate, setProfileCompletionCallbackAfterValidate] = useState(() => () =>
    undefined
  );
  const otpClockRef = useRef(null);
  const siteFooterRef = useRef(null);
  const siteFooterLogoRef = useRef(null);
  const [otpResendThreshold, setOtpResendThreshold] = useState(0);
  const [showOtpCountdown, setShowOtpCountdown] = useState(true);
  const [joinPhoneNumber, setJoinPhoneNumber] = useState('');
  const [otpRemainingAttempts, setOtpRemainingAttempts] = useState(2);
  const [otpFormInputFocus, setOtpFormInputFocus] = useState(0);
  const [hasRequestOtpError, setHasRequestOtpError] = useState('');
  const [currentPhoneSettingsStep, setCurrentPhoneSettingsStep] = useState(1);
  const [forceCloseSliderMenu, setForceCloseSliderMenu] = useState(0);
  const [forceResetTabs, setForceResetTabs] = useState(0);
  const [paymentSettingsOpen, setPaymentSettingsOpen] = useState(false);
  const [oldCompleteProfileModalState, setOldCompleteProfileModalState] = useState({
    props: DEFAULT_OLD_COMPLETE_MODAL_PROPS,
    isOpen: false,
  });
  const [selectedSpecialOrVipPromo, setSelectedSpecialOrVipPromo] = useState(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [pwaBannerState, setPwaBannerState] = useState({
    show: false,
    platform: null,
    source: undefined,
  });
  const [showInstallationBanner, setShowIntallationBanner] = useState(false);
  const [footerTopPosition, setFooterTopPosition] = useState(0);

  useEffect(() => {
    const siteFooterEl = siteFooterLogoRef?.current;

    if (siteFooterEl) {
      const siteFooterClRec = siteFooterEl.getBoundingClientRect();
      setFooterTopPosition(siteFooterClRec.top);
    }
  }, [siteFooterLogoRef?.current]);

  const lsSelectedPkg = getLocalItem('wyp_selected_package') ?? '';

  useEffect(() => {
    const isSelectedBefore = [
      'generous_pp_special_2',
      'generous_pp_special_1',
      'generous_cyber_monday_2017_2',
      'generous_cyber_monday_2017_1',
    ].includes(lsSelectedPkg);
    if (isSelectedBefore) {
      setSelectedSpecialOrVipPromo(lsSelectedPkg);
    }
  }, [lsSelectedPkg]);

  const values: UseAppProps = {
    modalState,
    setModalState,
    modalMobileOverideContentStyle,
    setModalMobileOverideContentStyle,
    accountHashId,
    setAccountHashId,
    profileCompletionStep,
    setProfileCompletionStep,
    profileCompletionCallback,
    setProfileCompletionCallback,
    profileCompletionCallbackAfterValidate,
    setProfileCompletionCallbackAfterValidate,
    otpClockRef,
    otpResendThreshold,
    setOtpResendThreshold,
    showOtpCountdown,
    setShowOtpCountdown,
    joinPhoneNumber,
    setJoinPhoneNumber,
    otpRemainingAttempts,
    setOtpRemainingAttempts,
    hasRequestOtpError,
    setHasRequestOtpError,
    otpFormInputFocus,
    setOtpFormInputFocus,
    currentPhoneSettingsStep,
    setCurrentPhoneSettingsStep,
    forceCloseSliderMenu,
    setForceCloseSliderMenu,
    setPaymentSettingsOpen,
    paymentSettingsOpen,
    oldCompleteProfileModalState,
    setOldCompleteProfileModalState,
    selectedSpecialOrVipPromo,
    setSelectedSpecialOrVipPromo,
    forceResetTabs,
    setForceResetTabs,
    isEvaluating,
    setIsEvaluating,
    siteFooterRef,
    siteFooterLogoRef,
    pwaBannerState,
    setPwaBannerState,
    showInstallationBanner,
    setShowIntallationBanner,
    isAccountIsMixpanelEligableEnabled,
    setIsAccountIsMixpanelEligableEnabled,
    footerTopPosition,
  };

  return <AppContext.Provider value={values} {...props} />;
};

const useApp = (): UseAppProps => useContext(AppContext) as UseAppProps;

export { AppProvider, useApp };
