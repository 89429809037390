import { styled } from '@material-ui/styles';
import React, { ReactElement, Fragment, useState, useEffect } from 'react';
import { canGenerousUserMakeOffer, renderAvatar, tsSince } from '../../common';
import OfferAccept from '../../components/pages/Offers/OfferAccept';
import OfferAcceptUnlock from '../../components/pages/Offers/OfferAcceptUnlock';
import OfferAcceptMessage from '../../components/pages/Offers/OfferAcceptMessage';
import OfferEdit from '../../components/pages/Offers/OfferEdit';
import OfferModalFooter from '../../components/pages/Offers/OfferModalFooter';
import OfferSuccess from '../../components/pages/Offers/OfferSuccess';
import useModal from '../../hooks/useModal';
import Button from '../buttons/Button';
import FavoriteOfferButton from '../buttons/FavoriteOfferButton';
import OfferAmountButton from '../buttons/OfferAmountButton';
import WYPButtonIcon from '../icons/WYPButtonIcon';
import { dimensions, type } from '../../style';
import {
  declineOffer,
  sendCancel,
  profileAcceptOffer,
  profileCounterOffer,
  counterModalClose,
} from '../../actions/profileTrackAction';
import useMixPanel from '../../hooks/useMixPanel';
import useCompleteProfileModal from '../../hooks/useCompleteProfileModal';
import { toaster } from '../../common';
import { connect } from 'react-redux';
import CreateNewOffer from '../../components/pages/Offers/CreateNewOffer';
import { sendSuggestion } from '../../actions/offerActions';
import { AccountMaker } from '../../models/Account';

interface PendingOfferPanelProps {
  errors: any;
  clearErrors: any;
  offer: any;
  createdAt: any;
  myProfile: any;
  otherProfile: any;
  otherAccount: any;
  isGenerous: any;
  acceptOffer: any;
  acceptOfferBtn: any;
  ignoreOffer: any;
  updateOffer: any;
  counterOffer: any;
  unlockMessage: any;
  createNewOffer: (formData: any) => void;
  sendSuggestion: (hashId: string) => void;
  setIsUserFinishProfileModalOpen: (flag: boolean) => void;
}

const Time = styled('div')({
  color: '#8492a6',
  textAlign: 'center',
  ...type.XS,
  marginTop: 10,
});

const PendingAmountContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
});

const CancelButton = styled(Button)({
  borderColor: '#2490de',
  color: '#2490de',
  backgroundColor: '#fff',

  '&:hover': {
    color: '#fff',
    backgroundColor: '#2490de',
  },
});

const CustomAmountButtonStyle = {
  marginTop: 0,
  marginBottom: 0,
  padding: '5px 10px',

  [dimensions.SCREEN_MIN_XS_2]: {
    padding: '5px 10px',
    height: 74,
    minWidth: 130,
    maxWidth: 130,

    '> [data-test-id="btn-amount-price"]': {
      fontSize: 42,
    },
  },
};

const PendingOfferPanel = ({
  errors,
  clearErrors,
  offer,
  createdAt,
  myProfile,
  otherProfile,
  otherAccount,
  acceptOffer,
  acceptOfferBtn,
  isGenerous,
  ignoreOffer,
  updateOffer,
  counterOffer,
  unlockMessage,
  createNewOffer,
  sendSuggestion,
  setIsUserFinishProfileModalOpen,
}: PendingOfferPanelProps): ReactElement => {
  const [rawAmount, setRawAmount] = useState('');
  const [offerPrice, setOfferPrice] = useState('');
  const [isAcceptingOffer, setIsAcceptingOffer] = useState(false);
  const { basicModal, confirmDeclineOffer, confirmModal, resetModal } = useModal();
  const iAmSender = offer.hash_id === myProfile.hash_id;
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const accountModel = AccountMaker.create(myProfile);
  const { showTooManyPendingOffersErrorModal } = useModal();

  const { trackOfferDeclined, trackOfferCancelled } = useMixPanel();

  useEffect(() => {
    setOfferPrice(
      formatPrice(
        {
          initial: offer ? offer.currency_original.data.initial : '',
          label: offer ? offer.currency_original.data.label_unicode : '',
        },
        offer.price_original
      )
    );

    setRawAmount(offer.price_original);
  }, [offer]);

  const handleCancelSentOffer = () => {
    confirmModal({
      dataTestID: 'offer-cancel-sent-offers',
      modalContentLabel: 'Offers Cancel Sent Offers',
      confirmationProps: {
        title: `Cancel ${isGenerous ? 'Offer' : 'Request'}?`,
        message: `Are you sure you want to <strong>Cancel</strong> your ${
          isGenerous ? 'offer' : 'request'
        } to <strong>${otherAccount.username}</strong>?`,
        confirmText: `Cancel ${isGenerous ? 'Offer' : 'Request'}`,
        confirmAction: async () => {
          await ignoreOffer(otherAccount.hash_id);
          sendCancel();
          trackOfferCancelled(offer.initiator_hash_id, {
            Amount: offer.price_original,
            User: myProfile.hash_id,
            Source: 'profile',
          });
          resetModal();
        },
      },
    });
  };

  const handleNewOffer = () => {
    if (!iAmSender && myProfile.can_make_offer) {
      return;
    }

    if (canGenerousUserMakeOffer(myProfile)) {
      return setIsUserFinishProfileModalOpen(true);
    }

    showMandatoryCompleteProfile(
      () => {
        basicModal({
          dateTestId: 'new-offer',
          modalContentLabel: 'New Offer Modal',
          isModalOpen: true,
          modalBody: (
            <Fragment>
              <CreateNewOffer
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={clearErrors}
                createNewOffer={createNewOffer}
                successOpen={handleOfferSuccess}
                // onOfferSuccess={() =>}
                closeModal={resetModal}
                currencyInitial={otherAccount.currency.data.initial}
                currencyLabel={otherAccount.currency.data.label_unicode}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                sex={otherProfile.sex}
                sendSuggestion={sendSuggestion}
                cannot_make_offer_reasons={myProfile.cannot_make_offer_reasons}
                buttonID="interestedOffers"
                isSuggestRequest={false}
                title={isGenerous ? 'Make an Offer!' : 'Request A Date!'}
                pageTitle={'Make Request'}
                defaultOfferAmount={otherAccount.profile.data.default_offer_amount}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Edit Sent Offer'
    );
  };

  const handleEditSentOffer = () => {
    if (!iAmSender && myProfile.can_make_offer) {
      return;
    }

    showMandatoryCompleteProfile(
      () => {
        if (canGenerousUserMakeOffer(myProfile)) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            setIsAcceptingOffer(false);
            setIsUserFinishProfileModalOpen(true);
            return;
          } else if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            setIsAcceptingOffer(false);
            showTooManyPendingOffersErrorModal();
            return;
          } else if (accountModel.shouldShowTooManySentOffers()) {
            setIsAcceptingOffer(false);
            showTooManyPendingOffersErrorModal({ type: 'sent' });
            return;
          }
        }

        basicModal({
          dateTestId: 'edit-offer',
          modalContentLabel: 'Edit Offer Modal',
          isModalOpen: true,
          modalBody: (
            <Fragment>
              <OfferEdit
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={clearErrors}
                updateOffer={updateOffer}
                closeModal={resetModal}
                currencyInitial={otherAccount.currency.data.initial}
                currencyLabel={otherAccount.currency.data.label_unicode}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                buttonID="editOffer"
                currentOffer={rawAmount}
                successOpen={handleOfferSuccess}
                sex={otherAccount && otherAccount.profile.data.sex}
                onCancelAndSendNewOfferHandler={async () => {
                  await ignoreOffer(otherAccount.hash_id);
                  sendCancel();
                  trackOfferCancelled(offer.initiator_hash_id, {
                    Amount: offer.price_original,
                    User: myProfile.hash_id,
                    Source: 'profile',
                  });

                  handleNewOffer();
                }}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Edit Sent Offer'
    );
  };

  const handleIgnoreOffer = async () => {
    confirmDeclineOffer({
      isGenerous,
      username: otherAccount.username,
      onConfirm: async () => {
        await ignoreOffer(otherAccount.hash_id);
        declineOffer();
        trackOfferDeclined(offer.initiator_hash_id, {
          Amount: offer.price_original,
          User: myProfile.hash_id,
          Source: 'Profile',
        });

        toaster.warn('Offer Declined');
        resetModal();
      },
    });
  };

  const handleCounterOffer = formData => {
    profileCounterOffer();
    return counterOffer(formData);
  };

  const handleForceOpenCounterModal = () => {
    const offerData = { data: offer };

    showMandatoryCompleteProfile(
      () => {
        if (canGenerousUserMakeOffer(myProfile)) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            setIsAcceptingOffer(false);
            return setIsUserFinishProfileModalOpen(true);
          } else if (accountModel.shouldShowTooManyLockedOffersDeals('counter')) {
            setIsAcceptingOffer(false);
            return showTooManyPendingOffersErrorModal();
          } else if (accountModel.shouldShowTooManySentOffers()) {
            setIsAcceptingOffer(false);
            return showTooManyPendingOffersErrorModal({ type: 'sent' });
          }
        }

        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          onCloseClickHandler: counterModalClose,
          modalBody: (
            <Fragment>
              <OfferAccept
                acceptUnlock={handleAcceptUnlock}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={() => undefined}
                onHandleSubmit={() => {
                  acceptOffer(otherAccount.hash_id, '');
                }}
                successOpen={handleOfferSuccess}
                counterOffer={handleCounterOffer}
                closeModal={resetModal}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                buttonID="acceptOffer"
                offer={offerData}
                ignoreOffer={handleIgnoreOffer}
                forceCounter={true}
                onHandleAttractiveSubmit={handleAttractiveMessage}
                pageTitle={'Profile'}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Counter Offer'
    );
  };

  const handleAcceptUnlock = (toggle, amount = '', result) => {
    if (canGenerousUserMakeOffer(myProfile)) {
      return setIsUserFinishProfileModalOpen(true);
    }

    basicModal({
      dateTestId: 'offer-success',
      modalContentLabel: 'Success Offer Modal',
      isModalOpen: toggle,
      modalBody: (
        <Fragment>
          <OfferAcceptUnlock
            offerAmount={amount}
            avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
            username={otherAccount.username}
            successOpen={resetModal}
            buttonID="interestedOffers"
            currentUserIsGenerous={isGenerous}
            creditCost={result.credit_cost}
            currentCredit={myProfile.credits}
            hashId={otherAccount.hash_id}
            closeModal={resetModal}
            unlockMessage={unlockMessage}
          />
          <OfferModalFooter />
        </Fragment>
      ),
    });
  };

  const handleAttractiveMessage = (amount = '') => {
    setIsAcceptingOffer(false);

    showMandatoryCompleteProfile(() => {
      basicModal(
        {
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          modalBody: (
            <Fragment>
              <OfferAcceptMessage
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                buttonID="interestedOffers"
                closeModal={resetModal}
                onSubmit={dealMessage => {
                  profileAcceptOffer();
                  return acceptOffer(otherAccount.hash_id, dealMessage);
                }}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        },
        () => undefined,
        'Accept Offer'
      );
    });
  };

  const handleOfferSuccess = (toggle, amount = '', isCounter = false, isRevised = false, isSuggest = false) => {
    setOfferPrice(amount);

    basicModal({
      dateTestId: 'offer-success',
      modalContentLabel: 'Success Offer Modal',
      isModalOpen: toggle,
      modalBody: (
        <Fragment>
          <OfferSuccess
            offerAmount={amount}
            avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
            username={otherAccount.username}
            successOpen={resetModal}
            buttonID="interestedOffers"
            isCounter={isCounter}
            isRevised={isRevised}
            isSuggest={isSuggest}
          />
          <OfferModalFooter />
        </Fragment>
      ),
    });
  };

  const formatPrice = (currency, price) => {
    if (!currency || !price) {
      return '';
    }

    return `${currency.initial}${String.fromCharCode(currency.label)}${price}`;
  };

  const handleGenerousAcceptOffer = () => {
    if (canGenerousUserMakeOffer(myProfile)) {
      if (!accountModel.isMandatoryProfileCompleted()) {
        setIsAcceptingOffer(false);
        return setIsUserFinishProfileModalOpen(true);
      } else if (accountModel.shouldShowTooManyLockedOffersDeals('accept')) {
        setIsAcceptingOffer(false);
        return showTooManyPendingOffersErrorModal();
      }
    }

    profileAcceptOffer();
    acceptOffer(otherAccount.hash_id, '');
  };

  const handleAcceptOffer = () => {
    setIsAcceptingOffer(true);

    isGenerous ? handleGenerousAcceptOffer() : handleAttractiveMessage(offerPrice);
  };

  const renderOfferAcceptButton = () => {
    return (
      <Button
        id={'btn-accept-offer'}
        buttonRef={acceptOfferBtn}
        buttonType="error"
        onClick={handleAcceptOffer}
        type="button"
        disabled={isAcceptingOffer}
      >
        <WYPButtonIcon style={{ marginRight: 6 }} />
        {isAcceptingOffer ? 'Accepting Offer' : 'Accept Offer'}
      </Button>
    );
  };

  return offer ? (
    <Fragment>
      <PendingAmountContainer>
        {!iAmSender && <FavoriteOfferButton type={'decline'} onClick={handleIgnoreOffer} />}
        <OfferAmountButton
          type={'receive'}
          price={offerPrice}
          viewType={'grid'}
          hasHoverEffect={iAmSender ? true : false}
          onClick={handleEditSentOffer}
          customBoxStyle={CustomAmountButtonStyle}
        />
        {!iAmSender && <FavoriteOfferButton type={'counter'} onClick={handleForceOpenCounterModal} />}
      </PendingAmountContainer>
      {createdAt ? <Time>{createdAt && tsSince(createdAt)}</Time> : null}
      <ButtonContainer>
        {iAmSender ? (
          <CancelButton buttonType={'normal'} onClick={handleCancelSentOffer} data-test-id="offer-cancel-action">
            Cancel {iAmSender && offer.suggest ? 'Request' : 'Offer'}
          </CancelButton>
        ) : (
          renderOfferAcceptButton()
        )}
      </ButtonContainer>
    </Fragment>
  ) : (
    <div />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    sendSuggestion: hashId => {
      return dispatch(sendSuggestion(hashId, false, null, null, false));
    },
  };
};

export default connect(null, mapDispatchToProps)(PendingOfferPanel);
