import React from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../../theme/colors';
import OfferFlagIcons from '../icons/OfferFlagIcons';
import { theme } from '../../../../../theme';
import { useOffers } from '../../../../../contexts/OffersContext';

const ContainerWrapper = styled('div')(({ variant }) => ({
  display: 'inline-flex',
  padding: '13px 10px',
  alignItems: 'center',
  borderRadius: 15,
  border: `3px solid ${
    ['decline', 'counter'].includes(variant) ? colors[theme].red.primary : colors[theme].green.primary
  }`,
  background: white,
  position: 'absolute',
  right: ['accept', 'request', 'offer'].includes(variant) ? 10 : 'auto',
  left: ['decline', 'counter'].includes(variant) ? 10 : 'auto',
  top: 30,
  opacity: 0.6,
}));

const FlagTextWrapper = styled('div')(({ variant }) => ({
  color: ['decline', 'counter'].includes(variant) ? colors[theme].red.primary : colors[theme].green.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
  textTransform: 'uppercase',
  marginLeft: 5,
}));

const OneTapSwipeOfferFlagOverlay: React.FC = () => {
  const { oneTapOfferFlagVariant } = useOffers();

  const renderLabel = () => {
    switch (oneTapOfferFlagVariant) {
      case 'decline':
        return 'Decline';
      case 'accept':
        return 'Accept!';
      case 'offer':
        return 'Offer!';
      case 'request':
        return 'Request!';
      case 'counter':
        return 'Counter!';
      default:
        return '';
    }
  };

  return (
    <ContainerWrapper variant={oneTapOfferFlagVariant}>
      <OfferFlagIcons variant={oneTapOfferFlagVariant} />
      <FlagTextWrapper variant={oneTapOfferFlagVariant}>{renderLabel()}</FlagTextWrapper>
    </ContainerWrapper>
  );
};

export default OneTapSwipeOfferFlagOverlay;
