import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Account Menu',
};

export const enum PhoneSettingsClickVysionEventsEnum {
  CLICK_ACCOUNT_MENU_SETTINGS = 'accountMenuSettings',
  CLICK_ACCOUNT_MENU_CHANGE_PHONE_NUMBER = 'accountMenuChangePhoneNumber',
  CLICK_ACCOUNT_EDIT_CHANGE_PHONE_NUMBER = 'accountEditChangePhoneNumber',
  CLICK_ACCOUNT_VERIFY_USE_PASSWORD = 'accountVerifyUsePassword',
  CLICK_ACCOUNT_VERIFY_PASSWORD = 'accountClickVerifyPassword',
  CLICK_ACCOUNT_VERIFY_PASSWORD_CANCEL = 'accountClickVerifyPasswordCancel',
  CLICK_CHANGE_PHONE_NUMBER_COUNTRY_CODE = 'accountChangePhoneNumberCountryCode',
  CLICK_CHANGE_PHONE_NUMBER_COUNTRY = 'accountChangePhoneNumberCountry',
  CLICK_CHANGE_PHONE_NUMBER_UPDATE = 'accountChangePhoneNumberUpdate',
  CLICK_UPDATED_NUMBER_VERIFICATION_VERIFY = 'accountUpdatedNumberVerificationVerify',
  CLICK_UPDATED_NUMBER_VERIFICATION_CANCEL_VERIFICATION = 'accountUpdatedNumberVerificationCancelVerification',
  CLICK_UPDATED_NUMBER_VERIFICATION_REQUEST_AGAIN = 'accountUpdatedNumberVerificationRequestAgain',
  CLICK_PHONE_NUMBER_UPDATE_SUCCESS_OK = 'accountPhoneNumberUpdateSuccessOk',
  CLICK_PHONE_NUMBER_UPDATE_SUCCESS_CLOSE = 'accountPhoneNumberUpdateSuccessClose',
  CLICK_SEND_CODE = 'accountClickSendCode',
  CLICK_CURRENT_NUMBER_VERIFICATION_VERIFY = 'accountCurrentNumberVerificationVerify',
  CLICK_CURRENT_NUMBER_VERIFICATION_CANCEL_VERIFICATION = 'accountCurrentNumberVerificationCancelVerification',
  CLICK_CURRENT_NUMBER_VERIFICATION_REQUEST_AGAIN = 'accountCurrentNumberVerificationRequestAgain',
}

export const phoneSettingsClickVysionEvents = {
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_MENU_SETTINGS]: {
    ...commonValues,
    eventName: 'accountMenu-accountSettings',
    component: 'Account Settings',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_MENU_CHANGE_PHONE_NUMBER]: {
    ...commonValues,
    eventName: 'accountMenu-accountSettings-changePhoneNumber',
    component: 'Account Settings',
    extraInfo: 'Change Phone Number',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_EDIT_CHANGE_PHONE_NUMBER]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber-editPhoneNumber',
    component: 'Change Phone Number',
    extraInfo: 'Edit Phone Number',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_USE_PASSWORD]: {
    ...commonValues,
    eventName: 'accountMenu-verifyAccount-usePassword',
    component: 'Verify Account Ownership',
    extraInfo: 'Use Password',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_PASSWORD]: {
    ...commonValues,
    eventName: 'accountMenu-verifyAccount-enterPassword-verify',
    component: 'Enter Password',
    extraInfo: 'Verify',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_PASSWORD_CANCEL]: {
    ...commonValues,
    eventName: 'accountMenu-verifyAccount-enterPassword-cancelVerification',
    component: 'Enter Password',
    extraInfo: 'Cancel Verification',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY_CODE]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber-countryCode',
    component: 'Change Phone Number',
    extraInfo: 'Country Code Selection',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber-countryCode-',
    component: 'Change Phone Number',
    extraInfo: '',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_UPDATE]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber-update',
    component: 'Change Phone Number',
    extraInfo: 'Update',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_VERIFY]: {
    ...commonValues,
    eventName: 'accountMenu-updatedNumberVerification-verify',
    component: 'Updated Number Verification Code',
    extraInfo: 'Verify',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_CANCEL_VERIFICATION]: {
    ...commonValues,
    eventName: 'accountMenu-updatedNumberVerification-cancelVerification',
    component: 'Updated Number Verification Code',
    extraInfo: 'Cancel Verification',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_REQUEST_AGAIN]: {
    ...commonValues,
    eventName: 'accountMenu-updatedNumberVerification-requestAgain',
    component: 'Updated Number Verification Code',
    extraInfo: 'Request Again Link',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_PHONE_NUMBER_UPDATE_SUCCESS_OK]: {
    ...commonValues,
    eventName: 'accountMenu-phoneNumberUpdateSuccess-ok',
    component: 'Phone Updated Successfully Modal',
    extraInfo: 'Ok',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_PHONE_NUMBER_UPDATE_SUCCESS_CLOSE]: {
    ...commonValues,
    eventName: 'accountMenu-phoneNumberUpdateSuccess-close',
    component: 'Phone Updated Successfully Modal',
    extraInfo: 'Close',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_SEND_CODE]: {
    ...commonValues,
    eventName: 'accountMenu-sendCode',
    component: 'Verify Account Ownership',
    extraInfo: 'Send Code',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_VERIFY]: {
    ...commonValues,
    eventName: 'accountMenu-currentNumberVerification-verify',
    component: 'Current Number Verification Code',
    extraInfo: 'Verify',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_CANCEL_VERIFICATION]: {
    ...commonValues,
    eventName: 'accountMenu-currentNumberVerification-cancelVerification',
    component: 'Current Number Verification Code',
    extraInfo: 'Cancel Verification',
  },
  [PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_REQUEST_AGAIN]: {
    ...commonValues,
    eventName: 'accountMenu-currentNumberVerification-requestAgain',
    component: 'Current Number Verification Code',
    extraInfo: 'Request Again Link',
  },
};
