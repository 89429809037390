import React, { useEffect } from 'react';
import Photos from '../components/pages/Photos/Photos';
import { connect } from 'react-redux';
import {
  deletePhoto,
  makePhotoDefault,
  removePhoto,
  setNewPrivacy,
  addNewPhoto,
  clearPendingPhotosUpload,
  refreshProfile,
  pendingUpload,
} from '../actions/profileActions';
import { displayFailMessage } from '../actions/notificationActions';
import { filter, find } from 'lodash';
import { scrollToTop } from '../utils/helpers';

const PhotosContainer = ({ refreshProfile, ...rest }) => {
  useEffect(() => {
    refreshProfile();
    setTimeout(() => {
      document.body.style.overflow = '';
      scrollToTop();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Photos {...rest} />;
};

const mapStateToProps = state => {
  return {
    photos: state.profile.photo,
    hasPublicPhotos: Boolean(
      find(state.profile.photo.data, function(p) {
        return Boolean(p.private === false);
      })
    ),
    hasPublicApprovedPhotos: Boolean(state.profile.has_photo),
    singlePublicLeft: Boolean(filter(state.profile.photo.data, { private: false }).length === 1),
    isUploading: state.profile.pendingUploads,
    isFetching: state.profile.isFetching,
    hasPrivateAvatar: Boolean(
      find(state.profile.photo.data, function(p) {
        return Boolean(p.is_avatar && p.private);
      })
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadPhoto: (formData, loadingStatus) => {
      dispatch(addNewPhoto(formData, loadingStatus));
    },
    pendingUpload: isPrivate => {
      dispatch(pendingUpload(isPrivate));
    },
    deletePhoto: (photoId, photoKey) => {
      // remove photo from API.
      dispatch(deletePhoto(photoId)).then(() => {
        dispatch(refreshProfile());
      });
      // remove from store.
      dispatch(removePhoto(photoKey));
    },
    setPrivacy: (photoId, photoKey) => {
      // swaps photo privacy.
      dispatch(setNewPrivacy(photoId, photoKey));
    },
    makeDefault: (photoId, photoKey) => {
      // makes photo your default
      dispatch(makePhotoDefault(photoId, photoKey));
    },
    refreshAndClearErrors: () => {
      dispatch(clearPendingPhotosUpload());
      //dispatch(refreshProfile())
    },
    refreshProfile: () => dispatch(refreshProfile()),
    displayFailMessage: ({ info, linkInfo, url }) => dispatch(displayFailMessage({ info, linkInfo, url })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhotosContainer);
