import { VYSION_ACTIONS } from '..';
import { EventData, handleVysionEvent } from '../vysionService';
import { melissaBillingFormOnClickVysionEvents} from './click';
import { melissaBillingFormOnFocusVysionEvents } from './onFocus';

export const events = {
  [VYSION_ACTIONS.ON_FOCUS]: { ...melissaBillingFormOnFocusVysionEvents },
  [VYSION_ACTIONS.CLICK]: { ...melissaBillingFormOnClickVysionEvents },
};

export const handleMelissaBillingFormVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
