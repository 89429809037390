import { STORE_META, STORE_PROFILE_MAP_FIELDS, STORE_DESCRIPTORS, MetaState, MetaActionType } from './types/Meta.d';

const initialState: MetaState = {
  mail_current_page: 0,
  mail_total_pages: 0,
  mailItems_current_page: 0,
  mailItems_total_pages: 0,

  fav_current_page: 0,
  fav_total_pages: 0,
  fav_viewed_current_page: 0,
  fav_viewed_total_pages: 0,
  fav_to_current_page: 0,
  fav_to_total_pages: 0,
  fav_from_current_page: 0,
  fav_from_total_pages: 0,

  search_current_page: 0,
  search_total_pages: 0,

  offer_current_page: 0,
  offer_total_pages: 0,
  offer_new_current_page: 0,
  offer_new_total_pages: 0,
  offer_sent_current_page: 0,
  offer_sent_total_pages: 0,
  offer_accepted_current_page: 0,
  offer_accepted_total_pages: 0,
  offer_rejected_current_page: 0,
  offer_rejected_total_pages: 0,
  offer_highlights_current_page: 0,
  offer_highlights_total_pages: 0,

  credit_current_page: 0,
  credit_total_pages: 0,
  search_scroll_height: 0,
  search_last_time: null,

  map_fields: {
    children: ['—', 'No', 'Yes'],
    drinking: ['—', 'No', 'Yes'],
    smoking: ['—', 'No', 'Yes'],
    income: {
      0: '—',
      101: 'Less than US$100,000',
      102: 'US$100,000 - US$300,000',
      103: 'US$300,000 - US$1 Million',
      104: 'More than US$1 Million',
    },
  },

  descriptors: null,
};

const meta = (state = initialState, action: MetaActionType): any => {
  switch (action.type) {
    case STORE_META:
      return {
        ...state,
        ...action.payload,
      };
    case STORE_PROFILE_MAP_FIELDS:
      return {
        ...state,
        map_fields: action.payload,
      };
    case STORE_DESCRIPTORS:
      // stores descriptors
      return {
        ...state,
        descriptors: action.payload,
      };
    default:
      return state;
  }
};

export default meta;
