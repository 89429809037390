import styled from '@emotion/styled';
import React from 'react';
import { isDarkMode } from '../../utils/helpers';

const StyledButton = styled.button<{ darkmode?: boolean }>`
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: ${props => (props.darkmode ? 'white' : 'black')};
  --apple-pay-button-width: 236px;
  --apple-pay-button-height: 50px;
  --apple-pay-button-border-radius: 8px;
  --apple-pay-button-padding: 14px 14px;
  --apple-pay-button-box-sizing: border-box;
  height: 50px;
  border-radius: 8px;
  width: 236px;
`;

interface IApplePayButton {
  handleClick: () => void;
  disabled?: boolean;
}

const ApplePayButton: React.FC<IApplePayButton> = ({ handleClick, disabled = false }: any): React.ReactElement => {
  return <StyledButton lang="en" onClick={handleClick} darkmode={isDarkMode()} disabled={disabled} />;
};

export default ApplePayButton;
