import styled from '@emotion/styled';
import { statusColor } from '../../style';

export const DashlineSeparator = styled('hr')({
  border: '1px dashed #DAE1E9;',
  marginTop: 20,
  marginBottom: 20,
});

export const StatusMessage = styled('div')(
  {
    marginBottom: 4,
  },
  ({ status }: { status: string }) => ({
    color: status !== 'default' ? statusColor[status] : 'inherit',
  })
);

export const VerticalSeparator = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SeparatorLine = styled('div')({
  flex: 1,
  height: '1px',
  backgroundColor: '#dae1e9',
  width: '20px',
  transform: 'rotate(90deg)',
});

export const FlexContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
