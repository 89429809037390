import React, { ReactElement } from 'react';
interface ISliderPauseIconProps {
  style?: any;
  onClick?: () => any;
}

const SliderPauseIcon = ({ ...rest }: ISliderPauseIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      className="pressable"
      {...rest}
    >
      <circle opacity="0.75" cx="38" cy="38.5" r="38" fill="#333333" />
      <path
        d="M33.1653 47.9668C33.7057 47.9668 34.1592 47.7931 34.5259 47.4457C34.8926 47.0983 35.076 46.6351 35.076 46.0561V30.9439C35.076 30.4421 34.8926 30.0078 34.5259 29.6411C34.1592 29.2744 33.7057 29.0911 33.1653 29.0911C32.5863 29.0911 32.123 29.2648 31.7756 29.6122C31.4282 29.9596 31.2545 30.4228 31.2545 31.0018V46.114C31.2545 46.6158 31.4282 47.0501 31.7756 47.4168C32.123 47.7835 32.5863 47.9668 33.1653 47.9668ZM42.7768 47.9668C43.3559 47.9668 43.8191 47.7931 44.1665 47.4457C44.5139 47.0983 44.6876 46.6351 44.6876 46.0561V30.9439C44.6876 30.4421 44.5139 30.0078 44.1665 29.6411C43.8191 29.2744 43.3559 29.0911 42.7768 29.0911C42.2364 29.0911 41.7829 29.2648 41.4162 29.6122C41.0495 29.9596 40.8661 30.4228 40.8661 31.0018V46.114C40.8661 46.6158 41.0495 47.0501 41.4162 47.4168C41.7829 47.7835 42.2364 47.9668 42.7768 47.9668ZM37.9711 62.5C34.69 62.5 31.5923 61.8727 28.6779 60.6182C25.7636 59.3637 23.2159 57.646 21.035 55.465C18.854 53.2841 17.1363 50.7461 15.8818 47.851C14.6273 44.956 14 41.8486 14 38.5289C14 35.2479 14.6273 32.1502 15.8818 29.2358C17.1363 26.3215 18.854 23.7738 21.035 21.5929C23.2159 19.4119 25.7636 17.6846 28.6779 16.4107C31.5923 15.1369 34.69 14.5 37.9711 14.5C41.2521 14.5 44.3498 15.1369 47.2642 16.4107C50.1785 17.6846 52.7262 19.4119 54.9071 21.5929C57.0881 23.7738 58.8154 26.3215 60.0893 29.2358C61.3631 32.1502 62 35.2479 62 38.5289C62 41.81 61.3631 44.9077 60.0893 47.8221C58.8154 50.7364 57.0881 53.2841 54.9071 55.465C52.7262 57.646 50.1882 59.3637 47.2931 60.6182C44.3981 61.8727 41.2907 62.5 37.9711 62.5Z"
        fill="white"
      />
    </svg>
  );
};

export default SliderPauseIcon;
