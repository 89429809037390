import React, { useState, useEffect, ReactElement } from 'react';
import { css } from 'glamor';
import { NOTIF_TIMEOUT as notifTimeout } from '../../config/constants';
import { getBrowserDetails } from '../../utils/helpers';

const Alert = {
  padding: '2px 0 1px',
  borderRadius: 0,
  marginBottom: 0,
  minHeight: 0,
  zIndex: 1000,
  width: '100%',
  fontWeight: '600',
  position: 'relative',
  top: 65,
  margin: '0 auto',
  maxWidth: 600,
};

const NonAuthenticatedAlert = {
  padding: '2px 0 1px',
  borderRadius: 0,
  marginBottom: 0,
  minHeight: 0,
  zIndex: 1000,
  width: '100%',
  fontWeight: '600',
  position: 'relative',
  top: 65,
  margin: '0 auto',
  maxWidth: 600,
};

const AlertSuccess = {
  color: '#fff',
  backgroundColor: '#77B300',
};

const AlertWarn = {
  color: '#fff',
  backgroundColor: '#FF7C00',
};

const AlertFail = {
  color: '#fff',
  backgroundColor: '#CC0000',
};

const FixedTopStyle = {
  position: 'fixed',
  width: '100%',
  zIndex: 10,
};

interface NotificationBannerProps {
  clearNotificationMessage: any;
  location: any;
  notification: any;
  isAuthenticated: any;
  loadPageUrl: any;
  style: any;
}

const NotificationBanner = ({
  clearNotificationMessage,
  location,
  notification,
  isAuthenticated,
  loadPageUrl,
  style = {},
}: NotificationBannerProps): ReactElement => {
  const [alertStatus, setAlertStatus] = useState(null);
  const [isNoHeaderMobile, setNoHeaderMobile] = useState(false);

  useEffect(() => {
    const noHeaderPages = ['/billing', '/payment', '/profile'];
    const isPageNoHeader = noHeaderPages.filter(item => document.location.pathname.indexOf(item) > -1).length > 0;

    const noHeaderMobile = getBrowserDetails().isMobile && isPageNoHeader;
    setNoHeaderMobile(noHeaderMobile);
    return () => {
      clearNotificationMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (notification) handleStatusChange(notification.status);

    if (!notification?.isPersist) {
      const timeoutClear = setTimeout(() => {
        clearNotificationMessage();
      }, notifTimeout);

      return () => {
        clearTimeout(timeoutClear);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const handleStatusChange = status => {
    switch (status) {
      case 'success':
        setAlertStatus(AlertSuccess);
        break;
      case 'offline':
      case 'warn':
        setAlertStatus(AlertWarn);
        break;
      case 'fail':
        setAlertStatus(AlertFail);
        break;
      default:
        setAlertStatus(null);
    }
  };

  if (!notification || notification.status === undefined) {
    return null;
  }

  return (
    <div style={{ ...FixedTopStyle, ...style }} className="common_error_content" data-test-id="error-banner-container">
      <div
        className={
          isAuthenticated ? `${css(Alert)} ${css(alertStatus)}` : `${css(NonAuthenticatedAlert)} ${css(alertStatus)}`
        }
        style={{
          top: isNoHeaderMobile ? 0 : getBrowserDetails().isMobile ? 0 : 65,
          zIndex: getBrowserDetails().isMobile ? 1001 : 1000,
        }}
      >
        <div className="layoutContainer">
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', paddingLeft: '0.5rem' }}>
                  <div className="container">
                    {notification.message.info !== undefined ? <span>{notification.message.info}</span> : null}
                    {notification.message.linkInfo !== undefined && notification.message.url !== undefined ? (
                      <span
                        onClick={() => loadPageUrl(notification.message.url)}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          marginLeft: '5px',
                        }}
                      >
                        {notification.message.linkInfo}
                      </span>
                    ) : null}
                  </div>
                </td>
                <td
                  data-test-id="error-banner-close"
                  onClick={() => clearNotificationMessage()}
                  style={{
                    textAlign: 'center',
                    width: '45px',
                    cursor: 'pointer',
                    fontSize: '24px',
                    opacity: notification.status === 'offline' ? 0 : 1,
                  }}
                >
                  &times;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
