import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import MessageBubbleIcon from '../icons/MessageBubble';
import Button from '../buttons/Button';

interface NoMessagesProps {
  onClick: any;
}

const NoMessages = ({ onClick }: NoMessagesProps): ReactElement => {
  return (
    <div style={{ textAlign: 'center', width: '50%', margin: '50px auto 0' }} data-test-id="no-messages-content">
      <MessageBubbleIcon />
      <p style={{ fontSize: 20 }}>No Messages!</p>
      <p style={{ color: '#ABABAB' }}>
        Send messages to plan your dates once you have an accepted and unlocked offer. Start making some offers!
      </p>
      <Button onClick={onClick} buttonType="primary">
        Find a date
      </Button>
    </div>
  );
};

NoMessages.propTypes = {
  onClick: PropTypes.func,
};

NoMessages.defaultProps = {
  onClick: () => null,
};

export default NoMessages;
