import React from 'react';
import GalleryUploadRemoveIcon from '../icons/GalleryUploadRemoveIcon';
import IconPencilFillCircle from '../../../../atoms/icons/IconPencilFillCircle';
import { black, white } from '../../../../theme/colors';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PhotoFileType } from '../../constants';
import VisibilityIcon from '../../../../atoms/icons/VisibilityIcon';
import { StyledMediaItemContainer } from '../styles';

const photoContainerStyles = ({ src }: { src: string }) => {
  return css`
    background-image: url(${src});
    width: 100%;
    height: 100%;
    background-position: center top;
    background-size: cover;
    border-radius: 10px;
  `;
};

const PrivateEyeContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  height: 80,
  width: 80,
  zIndex: 1,
  borderTopLeftRadius: 5,
});

const PrivateNotch = styled('div')({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: 'rgb(188, 32, 40, 0.9)',
  transform: 'rotate(45deg)',
  width: 80,
  height: 80,
  top: -40,
  left: -40,
});

// const PrivateMonkey = styled('img')({
//   position: 'absolute',
//   top: '0px',
//   left: '0px',
//   width: '20px',
//   zIndex: 3,
//   padding: '8px',
// });

const PhotoContainer = styled.div`
  ${photoContainerStyles}
`;

interface IGalleryFileUploaderMediaCardProps {
  file: PhotoFileType;
  onEdit: () => void;
  onRemove: () => void;
}

const RemovePhoto = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick} style={{ position: 'absolute', bottom: 15, right: 17.5, cursor: 'pointer' }}>
      <GalleryUploadRemoveIcon />
    </div>
  );
};

const EditPhoto = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick} style={{ position: 'absolute', top: 17.5, right: 17.5, cursor: 'pointer' }}>
      <IconPencilFillCircle fillColor={black} iconColor={white} customStyle={{ height: 24, width: 24 }} />
    </div>
  );
};

const GalleryPhotoUploaderMediaCard: React.FC<IGalleryFileUploaderMediaCardProps> = (
  props: IGalleryFileUploaderMediaCardProps
) => {
  const { file, onEdit, onRemove } = props;
  return (
    <StyledMediaItemContainer>
      {file.private && (
        <PrivateEyeContainer>
          <PrivateNotch />
          <VisibilityIcon customStyle={{ position: 'absolute', top: 0, left: 0, zIndex: 3, padding: 8 }} />
        </PrivateEyeContainer>
      )}

      <PhotoContainer src={file.edited}>
        <EditPhoto onClick={onEdit} />
        <RemovePhoto onClick={onRemove} />
      </PhotoContainer>
    </StyledMediaItemContainer>
  );
};

export default GalleryPhotoUploaderMediaCard;
