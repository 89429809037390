import React, { ReactElement } from 'react';

interface AddContentIconProps {
  cssStyle?: any;
  onBoarding?: any;
}

const ProfileEmail = ({ cssStyle = {}, onBoarding = '#8F8F8F' }: AddContentIconProps): ReactElement => {
  return (
    <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={cssStyle}>
      <path d="M24.7261 20.2309L35 26.4209V15.645L24.7261 20.2309Z" fill={onBoarding} />
      <path
        d="M34.5473 12.053L33.6003 11.1699V14.0228L35 13.3997V12.9208C35 12.6041 34.8569 12.308 34.6071 12.1071L34.5473 12.0556V12.053Z"
        fill={onBoarding}
      />
      <path d="M10.2739 20.2309L0 15.645V26.4209L10.2739 20.2309Z" fill={onBoarding} />
      <path
        d="M0.392852 12.1021C0.143093 12.3003 0 12.5964 0 12.9157V13.3946L1.39969 14.0178V11.1648L0.392852 12.0995V12.1021Z"
        fill={onBoarding}
      />
      <path
        d="M0 28.8207V28.9546C0 29.5314 0.4735 30 1.05627 30H33.9437C34.5265 30 35 29.5314 35 28.9546V28.8207L18.0502 18.6061C17.712 18.4027 17.2906 18.4027 16.9524 18.6061L0.00260419 28.8207H0Z"
        fill={onBoarding}
      />
      <path
        d="M17.4987 16.402C18.0606 16.402 18.6226 16.5539 19.1247 16.8552L22.5823 18.9383L31.5294 14.9446V0.744142C31.5294 0.332161 31.1912 0 30.7775 0H4.22507C3.80881 0 3.47321 0.334736 3.47321 0.744142V14.9446L12.4203 18.9383L15.8779 16.8552C16.38 16.5539 16.9419 16.402 17.5039 16.402H17.4987ZM11.6658 8.26024C11.6658 5.0751 14.2831 2.48476 17.4987 2.48476C20.7143 2.48476 23.3316 5.0751 23.3316 8.26024C23.3316 11.4454 20.7143 14.0331 17.4987 14.0331C14.2831 14.0331 11.6658 11.4428 11.6658 8.26024Z"
        fill={onBoarding}
      />
      <path
        d="M15.4122 11.4814L15.9611 9.09966L14.1192 7.49808L16.5543 7.28694L17.5013 5.03906L18.4483 7.28694L20.8835 7.49808L19.0415 9.09966L19.5904 11.4814L17.5039 10.2172L15.4174 11.4814H15.4122Z"
        fill={onBoarding}
      />
    </svg>
  );
};

export default ProfileEmail;
