import React, { ReactElement } from 'react';
interface IAlertIconProps {
  customStyle?: any;
}

const AlertIcon = ({ customStyle }: IAlertIconProps): ReactElement => {
  return (
    <svg width="57" height="52" viewBox="0 0 57 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1504_3273"
        style={{ maskType: 'alpha', ...customStyle }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="13"
        width="33"
        height="34"
      >
        <rect x="12.1479" y="13.899" width="32.7036" height="32.7036" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1504_3273)">
        <path
          d="M28.4976 42.5147C27.8101 42.5147 27.2222 42.2699 26.7339 41.7803C26.2456 41.2907 26.0015 40.7021 26.0015 40.0145C26.0015 39.327 26.2463 38.7391 26.7359 38.2508C27.2255 37.7625 27.8141 37.5183 28.5017 37.5183C29.1892 37.5183 29.7771 37.7632 30.2654 38.2528C30.7537 38.7424 30.9978 39.331 30.9978 40.0185C30.9978 40.7061 30.753 41.294 30.2634 41.7823C29.7738 42.2706 29.1852 42.5147 28.4976 42.5147ZM28.4976 34.3388C27.8101 34.3388 27.2222 34.0942 26.7339 33.605C26.2456 33.1157 26.0015 32.5276 26.0015 31.8406V20.4852C26.0015 19.7982 26.2463 19.2101 26.7359 18.7209C27.2255 18.2316 27.8141 17.987 28.5017 17.987C29.1892 17.987 29.7771 18.2316 30.2654 18.7209C30.7537 19.2101 30.9978 19.7982 30.9978 20.4852V31.8406C30.9978 32.5276 30.753 33.1157 30.2634 33.605C29.7738 34.0942 29.1852 34.3388 28.4976 34.3388Z"
          fill="#DD0B24"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5735 43.3323L30.6238 5.31434C29.6798 3.67915 27.3196 3.67916 26.3755 5.31433L4.42583 43.3323C3.48175 44.9675 4.66185 47.0115 6.54998 47.0115H50.4493C52.3375 47.0115 53.5176 44.9675 52.5735 43.3323ZM34.1641 3.27037C31.6466 -1.09011 25.3528 -1.09013 22.8352 3.27036L0.885562 41.2883C-1.63196 45.6488 1.51493 51.0994 6.54998 51.0994H50.4493C55.4844 51.0994 58.6313 45.6488 56.1138 41.2883L34.1641 3.27037Z"
        fill="#DD0B24"
      />
    </svg>
  );
};

export default AlertIcon;
