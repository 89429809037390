import React, { Component } from 'react';

import EmailPassword from './EmailPassword';
import Panel from '../../../atoms/panels/Panel';
import Container from '../../blocks/Container';
import { Content, PrimaryContent } from '../../blocks/ColLayout';
import CanceledOffers from './CanceledOffers';
import SkeletonLoader from '../../../atoms/SkeletonLoader';

class Settings extends Component {
  constructor(props: any) {
    super(props);
    this.renderSection = this.renderSection.bind(this);
  }

  isGenerous(profile: any): any {
    return profile && profile.data && profile.data.account_type === 'Generous';
  }

  renderSection(page: string | null): any {
    switch (page) {
      case 'rejected': {
        const offerType = 'rejected';
        const offerList = this.props.offers[offerType];
        const moreExists = Boolean(
          this.props.meta[`offer_${offerType}_current_page`] < this.props.meta[`offer_${offerType}_total_pages`]
        );

        if (offerList === undefined) {
          return null;
        }
        return <CanceledOffers {...this.props} offers={offerList} moreExists={moreExists} offerType={offerType} />;
      }
      case 'email-password':
        return (
          <EmailPassword
            profile={this.props.profile}
            errors={this.props.errors}
            needsPassword={this.props.profile.needs_account_password}
            settings={this.props.settings}
            validateCredentials={this.props.validateCredentials}
            addAccountEmailPassword={this.props.addAccountEmailPassword}
            updateAccountEmailPassword={this.props.updateAccountEmailPassword}
            validateSocialCredentials={this.props.validateSocialCredentials}
          />
        );
      case 'account-information':
        window.location.replace('/dashboard?showSettings=true&accountInfo=true');
        return <SkeletonLoader width="100%" />;
      default:
        window.location.replace('/dashboard');
        return <SkeletonLoader width="100%" />;
    }
  }

  render(): any {
    const { page, profile } = this.props;
    const { offer_counts } = profile;

    if (!offer_counts) return null;

    if (page === 'email-password') {
      return this.renderSection(page);
    }
    
    return (
      <Container screenTop fullWidth>
        <Content style={{ background: 'transparent' }}>
          <PrimaryContent fullWidth>
            <Panel
              withHeader
              customStyle={{
                padding: page === 'rejected' ? 0 : '',
                boxShadow: page === 'email-password' ? 'none' : '',
                border: page === 'email-password' ? 'none' : '',
                borderRadius: page === 'email-password' ? 0 : '',
                display: 'block',
              }}
            >
              {this.renderSection(page)}
            </Panel>
          </PrimaryContent>
        </Content>
      </Container>
    );
  }
}

export default Settings;
