import React, { useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';
import JoinOptionButton from './JoinOptionButton';

const ButtonGroupContainer = styled('div')({
  height: '100%',
});

interface IButtonOptionGroupProps {
  lists: any[];
  onChangeHandler: any;
  value?: string | number;
  additionalStyle?: React.CSSProperties;
  customButtonGroupStyle?: React.CSSProperties;
  animate?: boolean;
  name?: string;
}

const JoinOptionButtonGroup = ({
  lists,
  onChangeHandler,
  value,
  additionalStyle,
  animate = true,
  name = '',
  customButtonGroupStyle,
}: IButtonOptionGroupProps): any => {
  const refs = useRef<HTMLDivElement[]>([]);

  useLayoutEffect(() => {
    if (animate) {
      const firstIndexElement: HTMLDivElement = refs.current[0];
      const firstIndexElementOffsetHeight = firstIndexElement.offsetHeight + 14;

      refs.current.map((elem: HTMLDivElement, idx) => {
        // lets animate
        if (idx === 0) {
          // animate parent height
          const parentElement: any = elem.parentElement;
          parentElement.style.height = value === null ? '100%' : `${firstIndexElementOffsetHeight}px`;
          parentElement.style.transition = '0.2s';
        }

        if (elem) {
          // get current element offsets
          // make offsets to negative then add up first index offsets
          const leftOffsetVal = elem.offsetLeft * -1 + firstIndexElement.offsetLeft;
          const topOffsetVal = elem.offsetTop * -1 + firstIndexElement.offsetTop;

          if (value === null) {
            // unsets all css properties if not selected
            elem.style.left = '0';
            elem.style.top = '0';
            elem.style.transition = `left 0.5s, top 0.5s, opacity 0.5s`;
          } else {
            // set all css properties  based on offsets value
            elem.style.left = `${leftOffsetVal}px`;
            elem.style.top = `${topOffsetVal}px`;
            elem.style.transition = `left 0.5s, top 0.5s, opacity 0.5s`;
          }

          // disabled onClick when animation is started
          elem.addEventListener('transitionstart', () => {
            elem.style.pointerEvents = 'none';
          });

          // enabled onClick when animation is running
          elem.addEventListener('transitionrun', () => {
            elem.style.pointerEvents = 'none';
          });

          // enabled onClick when animation is ended
          elem.addEventListener('transitionend', () => {
            elem.style.pointerEvents = 'auto';
          });
        }
      });
    }
  }, [refs, value, animate]);

  return (
    <ButtonGroupContainer style={customButtonGroupStyle}>
      {lists.map((item, index) => (
        <JoinOptionButton
          name={name}
          index={index}
          elementRef={(element: HTMLDivElement) => {
            refs.current[index] = element;
          }}
          key={index}
          title={name === 'body_type' && item.value === 5 ? 'A Few Extra' : item.label}
          id={index + 1}
          isSelected={value === item.value}
          value={value}
          animate={animate}
          onChangeHandler={() => onChangeHandler(item)}
          additionalStyle={additionalStyle}
        />
      ))}
    </ButtonGroupContainer>
  );
};

export default JoinOptionButtonGroup;
