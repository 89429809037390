import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';

const IconUploadFailed = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <circle cx="13" cy="13" r="13" fill={isDarkMode() ? '#D06369' : '#DD0B24'} />
      <mask
        id="mask0_3469_1254"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="18"
        height="18"
      >
        <rect x="4.15918" y="4.16016" width="17.68" height="17.68" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3469_1254)">
        <path
          d="M12.9963 16.9442C13.2394 16.9442 13.4401 16.8677 13.5983 16.7147C13.7566 16.5617 13.8357 16.3636 13.8357 16.1205C13.8357 15.8774 13.7575 15.675 13.601 15.5133C13.4445 15.3515 13.2448 15.2706 13.0017 15.2706C12.7586 15.2706 12.5579 15.3514 12.3996 15.5129C12.2414 15.6745 12.1622 15.8768 12.1622 16.1199C12.1622 16.363 12.2405 16.5611 12.3969 16.7143C12.5534 16.8676 12.7532 16.9442 12.9963 16.9442ZM13.0575 13.8766C13.2635 13.8766 13.4361 13.8063 13.5751 13.6657C13.7142 13.5251 13.7837 13.3522 13.7837 13.1471V9.76967C13.7837 9.56456 13.7131 9.3917 13.5719 9.25109C13.4307 9.1105 13.257 9.04021 13.051 9.04021C12.845 9.04021 12.6724 9.1105 12.5334 9.25109C12.3943 9.3917 12.3248 9.56456 12.3248 9.76967V13.1471C12.3248 13.3522 12.3954 13.5251 12.5366 13.6657C12.6778 13.8063 12.8514 13.8766 13.0575 13.8766ZM13.0053 20.6275C11.9469 20.6275 10.9555 20.4286 10.0309 20.0309C9.10638 19.6332 8.29763 19.0878 7.60469 18.3949C6.91175 17.702 6.36642 16.8932 5.96868 15.9687C5.57094 15.0442 5.37207 14.0523 5.37207 12.9928C5.37207 11.9334 5.57094 10.941 5.96868 10.0155C6.36642 9.09004 6.91121 8.28372 7.60307 7.59656C8.29491 6.9094 9.10334 6.36539 10.0284 5.96455C10.9534 5.5637 11.9459 5.36328 13.0059 5.36328C14.0659 5.36328 15.059 5.56343 15.9853 5.96374C16.9116 6.36404 17.7181 6.90732 18.4047 7.59356C19.0914 8.2798 19.635 9.08677 20.0355 10.0145C20.436 10.9422 20.6363 11.9359 20.6363 12.9957C20.6363 14.055 20.4359 15.0469 20.035 15.9714C19.6342 16.8958 19.0902 17.7029 18.403 18.3928C17.7159 19.0826 16.9091 19.6275 15.9826 20.0275C15.0561 20.4275 14.0637 20.6275 13.0053 20.6275Z"
          fill={isDarkMode() ? '#113855' : 'white'}
        />
      </g>
    </svg>
  );
};

export default IconUploadFailed;
