import React from 'react';
import styled from '@emotion/styled';
import SecondaryButton from '../../../../atoms/buttons/SecondaryButton';
import RoundCheckIcon from '../../../../atoms/icons/RoundCheckIcon';
import { dimensions } from '../../../../style';

interface IProps {
  onConfirm: () => void;
  customStyle?: React.CSSProperties;
}

const DashboardDiscoverModeGotItButtonWrapper = styled(SecondaryButton)({
  [dimensions.SCREEN_MAX_MD]: {
    width: 'inherit',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 50,
  },
  [dimensions.SCREEN_MIN_MD]: {
    width: 'inherit',
    margin: '0 auto',
    padding: '16px 32px',
    height: '51px',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
  },
});

const RoundCheckIconWrapper = styled(RoundCheckIcon)(() => ({
  [dimensions.SCREEN_MAX_MD]: {
    width: 13,
    height: 13,
  },
  [dimensions.SCREEN_MIN_MD]: {
    width: 19,
    height: 19,
  },
}));

const DashboardDiscoverModeGotItButton: React.FC<IProps> = ({ onConfirm, customStyle }: IProps) => {
  return (
    <DashboardDiscoverModeGotItButtonWrapper
      size="x-small"
      color="blue"
      onClick={onConfirm}
      data-test-id="dashboard-swipe-mode-got-it"
      label="Got It!"
      customStyle={customStyle}
      withIcon={<RoundCheckIconWrapper customStyle={{ marginRight: 6 }} />}
    />
  );
};

export default DashboardDiscoverModeGotItButton;
