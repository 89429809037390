import React, { ReactElement } from 'react';

const GDPRDarkIcon = (): ReactElement => {
  return (
    <svg width="80" height="31" viewBox="0 0 80 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_529_631)">
        <path
          d="M0 8.00001C0 3.58173 3.58172 0 8 0H29.8246V30.1887H7.99999C3.58172 30.1887 0 26.607 0 22.1887V8.00001Z"
          fill="#6A6A6A"
        />
        <path
          d="M29.8245 0H71.9999C76.4182 0 79.9999 3.58172 79.9999 8V22.1887C79.9999 26.607 76.4182 30.1887 71.9999 30.1887H29.8245V0Z"
          fill="#041A2A"
        />
        <path
          d="M15.0451 6.14509L14.2656 6.61687C14.2308 6.63844 14.1959 6.64922 14.1584 6.64653C14.1209 6.64383 14.0888 6.63035 14.0593 6.60878C14.0299 6.58722 14.0084 6.56026 13.9924 6.52521C13.9763 6.49286 13.9736 6.45512 13.9816 6.41468L14.1879 5.52234L13.4968 4.92115C13.4647 4.8915 13.4459 4.86184 13.4379 4.8241C13.4298 4.78905 13.4325 4.75131 13.4459 4.71896C13.4593 4.68391 13.4781 4.65695 13.5022 4.63269C13.5263 4.61112 13.5611 4.59764 13.6066 4.58956L14.5174 4.50868L14.8683 3.66756C14.8844 3.62982 14.9085 3.60016 14.9406 3.58129C14.9728 3.56242 15.0076 3.55164 15.0424 3.55164C15.0772 3.55164 15.1094 3.56242 15.1442 3.58129C15.1763 3.60016 15.2005 3.62982 15.2165 3.66756L15.5674 4.50868L16.4782 4.58956C16.5211 4.59495 16.5559 4.61112 16.5827 4.63269C16.6068 4.65426 16.6255 4.68391 16.6389 4.71896C16.6523 4.75401 16.655 4.78905 16.647 4.8241C16.6389 4.85915 16.6202 4.8915 16.588 4.92115L15.8969 5.52234L16.1032 6.41468C16.1139 6.45512 16.1085 6.49286 16.0925 6.52521C16.0764 6.55756 16.055 6.58452 16.0255 6.60878C15.996 6.63035 15.9639 6.64383 15.9264 6.64653C15.8889 6.64922 15.8541 6.64113 15.8192 6.61687L15.0397 6.14509H15.0451Z"
          fill="#FFD477"
        />
        <path
          d="M20.0087 7.49305L19.2292 7.96483C19.1944 7.9864 19.1596 7.99718 19.1221 7.99449C19.0846 7.99179 19.0524 7.97831 19.023 7.95674C18.9935 7.93518 18.9721 7.90822 18.956 7.87317C18.9399 7.84082 18.9372 7.80308 18.9453 7.76264L19.1515 6.8703L18.4604 6.26911C18.4283 6.23946 18.4095 6.2098 18.4015 6.17206C18.3935 6.13701 18.3961 6.09927 18.4095 6.06692C18.4229 6.03187 18.4417 6.00491 18.4658 5.98065C18.4899 5.95908 18.5247 5.94561 18.5703 5.93752L19.481 5.85664L19.8319 5.01552C19.848 4.97778 19.8721 4.94812 19.9042 4.92925C19.9364 4.91038 19.9712 4.8996 20.006 4.8996C20.0409 4.8996 20.073 4.91038 20.1078 4.92925C20.14 4.94812 20.1641 4.97778 20.1802 5.01552L20.5311 5.85664L21.4418 5.93752C21.4847 5.94291 21.5195 5.95908 21.5463 5.98065C21.5704 6.00222 21.5891 6.03187 21.6025 6.06692C21.6159 6.10197 21.6186 6.13701 21.6106 6.17206C21.6025 6.20711 21.5838 6.23946 21.5516 6.26911L20.8605 6.8703L21.0668 7.76264C21.0775 7.80308 21.0722 7.84082 21.0561 7.87317C21.04 7.90552 21.0186 7.93248 20.9891 7.95674C20.9597 7.97831 20.9275 7.99179 20.89 7.99449C20.8525 7.99718 20.8177 7.9891 20.7829 7.96483L20.0034 7.49305H20.0087Z"
          fill="#FFD477"
        />
        <path
          d="M10.0842 24.7899L9.30465 25.2617C9.26983 25.2833 9.235 25.2941 9.1975 25.2914C9.16 25.2887 9.12786 25.2752 9.09839 25.2536C9.06893 25.2321 9.0475 25.2051 9.03142 25.17C9.01535 25.1377 9.01267 25.1 9.02071 25.0595L9.22697 24.1672L8.53586 23.566C8.50372 23.5363 8.48497 23.5067 8.47693 23.4689C8.4689 23.4339 8.47158 23.3961 8.48497 23.3638C8.49836 23.3287 8.51711 23.3018 8.54122 23.2775C8.56533 23.256 8.60015 23.2425 8.64569 23.2344L9.55645 23.1535L9.90736 22.3124C9.92343 22.2747 9.94754 22.245 9.97968 22.2261C10.0118 22.2073 10.0467 22.1965 10.0815 22.1965C10.1163 22.1965 10.1484 22.2073 10.1833 22.2261C10.2154 22.245 10.2395 22.2747 10.2556 22.3124L10.6065 23.1535L11.5173 23.2344C11.5601 23.2398 11.5949 23.256 11.6217 23.2775C11.6458 23.2991 11.6646 23.3287 11.678 23.3638C11.6914 23.3988 11.6941 23.4339 11.686 23.4689C11.678 23.504 11.6592 23.5363 11.6271 23.566L10.936 24.1672L11.1422 25.0595C11.153 25.1 11.1476 25.1377 11.1315 25.17C11.1155 25.2024 11.094 25.2294 11.0646 25.2536C11.0351 25.2752 11.0029 25.2887 10.9654 25.2914C10.9279 25.2941 10.8931 25.286 10.8583 25.2617L10.0788 24.7899H10.0842Z"
          fill="#FFD477"
        />
        <path
          d="M20.0087 24.7899L19.2292 25.2617C19.1944 25.2833 19.1596 25.2941 19.1221 25.2914C19.0846 25.2887 19.0524 25.2752 19.023 25.2536C18.9935 25.2321 18.9721 25.2051 18.956 25.17C18.9399 25.1377 18.9372 25.1 18.9453 25.0595L19.1515 24.1672L18.4604 23.566C18.4283 23.5363 18.4095 23.5067 18.4015 23.4689C18.3935 23.4339 18.3961 23.3961 18.4095 23.3638C18.4229 23.3287 18.4417 23.3018 18.4658 23.2775C18.4899 23.256 18.5247 23.2425 18.5703 23.2344L19.481 23.1535L19.8319 22.3124C19.848 22.2747 19.8721 22.245 19.9042 22.2261C19.9364 22.2073 19.9712 22.1965 20.006 22.1965C20.0409 22.1965 20.073 22.2073 20.1078 22.2261C20.14 22.245 20.1641 22.2747 20.1802 22.3124L20.5311 23.1535L21.4418 23.2344C21.4847 23.2398 21.5195 23.256 21.5463 23.2775C21.5704 23.2991 21.5891 23.3287 21.6025 23.3638C21.6159 23.3988 21.6186 23.4339 21.6106 23.4689C21.6025 23.504 21.5838 23.5363 21.5516 23.566L20.8605 24.1672L21.0668 25.0595C21.0775 25.1 21.0722 25.1377 21.0561 25.17C21.04 25.2024 21.0186 25.2294 20.9891 25.2536C20.9597 25.2752 20.9275 25.2887 20.89 25.2914C20.8525 25.2941 20.8177 25.286 20.7829 25.2617L20.0034 24.7899H20.0087Z"
          fill="#FFD477"
        />
        <path
          d="M24.9777 16.1415L24.1982 16.6133C24.1634 16.6349 24.1286 16.6457 24.0911 16.643C24.0536 16.6403 24.0214 16.6268 23.9919 16.6052C23.9625 16.5837 23.9411 16.5567 23.925 16.5217C23.9089 16.4893 23.9062 16.4516 23.9143 16.4111L24.1205 15.5188L23.4294 14.9176C23.3973 14.888 23.3785 14.8583 23.3705 14.8206C23.3625 14.7855 23.3651 14.7478 23.3785 14.7154C23.3919 14.6804 23.4107 14.6534 23.4348 14.6292C23.4589 14.6076 23.4937 14.5941 23.5392 14.586L24.45 14.5051L24.8009 13.664C24.817 13.6263 24.8411 13.5966 24.8732 13.5777C24.9054 13.5589 24.9402 13.5481 24.975 13.5481C25.0099 13.5481 25.042 13.5589 25.0768 13.5777C25.109 13.5966 25.1331 13.6263 25.1491 13.664L25.5001 14.5051L26.4108 14.586C26.4537 14.5914 26.4885 14.6076 26.5153 14.6292C26.5394 14.6507 26.5581 14.6804 26.5715 14.7154C26.5849 14.7505 26.5876 14.7855 26.5796 14.8206C26.5715 14.8556 26.5528 14.888 26.5206 14.9176L25.8295 15.5188L26.0358 16.4111C26.0465 16.4516 26.0412 16.4893 26.0251 16.5217C26.009 16.554 25.9876 16.581 25.9581 16.6052C25.9286 16.6268 25.8965 16.6403 25.859 16.643C25.8215 16.6457 25.7867 16.6376 25.7519 16.6133L24.9723 16.1415H24.9777Z"
          fill="#FFD477"
        />
        <path
          d="M5.11517 16.1415L4.33567 16.6133C4.30085 16.6349 4.26602 16.6457 4.22852 16.643C4.19102 16.6403 4.15888 16.6268 4.12941 16.6052C4.09994 16.5837 4.07852 16.5567 4.06244 16.5217C4.04637 16.4893 4.04369 16.4516 4.05173 16.4111L4.25799 15.5188L3.57224 14.9176C3.5401 14.888 3.52135 14.8583 3.51331 14.8206C3.50527 14.7855 3.50795 14.7478 3.52135 14.7154C3.53474 14.6804 3.55349 14.6534 3.5776 14.6292C3.60171 14.6076 3.63653 14.5941 3.68207 14.586L4.59283 14.5051L4.94374 13.664C4.95981 13.6263 4.98392 13.5966 5.01606 13.5777C5.04821 13.5589 5.08303 13.5481 5.11785 13.5481C5.15267 13.5481 5.18482 13.5589 5.21964 13.5777C5.25179 13.5966 5.27589 13.6263 5.29197 13.664L5.64288 14.5051L6.55364 14.586C6.59649 14.5914 6.63132 14.6076 6.6581 14.6292C6.68221 14.6507 6.70096 14.6804 6.71436 14.7154C6.72775 14.7505 6.73043 14.7855 6.72239 14.8206C6.71436 14.8556 6.69561 14.888 6.66346 14.9176L5.97236 15.5188L6.17862 16.4111C6.18933 16.4516 6.18397 16.4893 6.1679 16.5217C6.15183 16.554 6.1304 16.581 6.10093 16.6052C6.07147 16.6268 6.03932 16.6403 6.00182 16.643C5.96432 16.6457 5.9295 16.6376 5.89467 16.6133L5.11517 16.1415Z"
          fill="#FFD477"
        />
        <path
          d="M6.46794 11.1541L5.68844 11.6259C5.65362 11.6475 5.61879 11.6583 5.58129 11.6556C5.54379 11.6529 5.51165 11.6394 5.48218 11.6178C5.45271 11.5962 5.43128 11.5693 5.41521 11.5342C5.39914 11.5019 5.39646 11.4642 5.4045 11.4237L5.61076 10.5314L4.91965 9.93018C4.88751 9.90053 4.86876 9.87088 4.86072 9.83313C4.85269 9.79809 4.85536 9.76034 4.86876 9.72799C4.88215 9.69295 4.9009 9.66599 4.92501 9.64172C4.94912 9.62016 4.98394 9.60668 5.02948 9.59859L5.94024 9.51771L6.29115 8.67659C6.30722 8.63885 6.33133 8.6092 6.36347 8.59032C6.39562 8.57145 6.43044 8.56067 6.46526 8.56067C6.50009 8.56067 6.53223 8.57145 6.56705 8.59032C6.5992 8.6092 6.62331 8.63885 6.63938 8.67659L6.99029 9.51771L7.90105 9.59859C7.94391 9.60398 7.97873 9.62016 8.00552 9.64172C8.02962 9.66329 8.04838 9.69295 8.06177 9.72799C8.07516 9.76304 8.07784 9.79809 8.06981 9.83313C8.06177 9.86818 8.04302 9.90053 8.01087 9.93018L7.31977 10.5314L7.52603 11.4237C7.53674 11.4642 7.53139 11.5019 7.51531 11.5342C7.49924 11.5666 7.47781 11.5936 7.44835 11.6178C7.41888 11.6394 7.38674 11.6529 7.34923 11.6556C7.31173 11.6583 7.27691 11.6502 7.24209 11.6259L6.46258 11.1541H6.46794Z"
          fill="#FFD477"
        />
        <path
          d="M23.6249 11.1541L22.8454 11.6259C22.8106 11.6475 22.7758 11.6583 22.7383 11.6556C22.7008 11.6529 22.6686 11.6394 22.6392 11.6178C22.6097 11.5962 22.5883 11.5693 22.5722 11.5342C22.5561 11.5019 22.5534 11.4642 22.5615 11.4237L22.7677 10.5314L22.0766 9.93018C22.0445 9.90053 22.0257 9.87088 22.0177 9.83313C22.0097 9.79809 22.0123 9.76034 22.0257 9.72799C22.0391 9.69295 22.0579 9.66599 22.082 9.64172C22.1061 9.62016 22.1409 9.60668 22.1865 9.59859L23.0972 9.51771L23.4481 8.67659C23.4642 8.63885 23.4883 8.6092 23.5205 8.59032C23.5526 8.57145 23.5874 8.56067 23.6222 8.56067C23.6571 8.56067 23.6892 8.57145 23.724 8.59032C23.7562 8.6092 23.7803 8.63885 23.7964 8.67659L24.1473 9.51771L25.058 9.59859C25.1009 9.60398 25.1357 9.62016 25.1625 9.64172C25.1866 9.66329 25.2054 9.69295 25.2188 9.72799C25.2321 9.76304 25.2348 9.79809 25.2268 9.83313C25.2188 9.86818 25.2 9.90053 25.1679 9.93018L24.4768 10.5314L24.683 11.4237C24.6937 11.4642 24.6884 11.5019 24.6723 11.5342C24.6562 11.5666 24.6348 11.5936 24.6053 11.6178C24.5759 11.6394 24.5437 11.6529 24.5062 11.6556C24.4687 11.6583 24.4339 11.6502 24.3991 11.6259L23.6196 11.1541H23.6249Z"
          fill="#FFD477"
        />
        <path
          d="M23.6249 21.1262L22.8454 21.598C22.8106 21.6196 22.7758 21.6304 22.7383 21.6277C22.7008 21.625 22.6686 21.6115 22.6392 21.5899C22.6097 21.5684 22.5883 21.5414 22.5722 21.5064C22.5561 21.474 22.5534 21.4363 22.5615 21.3958L22.7677 20.5035L22.0766 19.9023C22.0445 19.8726 22.0257 19.843 22.0177 19.8052C22.0097 19.7702 22.0123 19.7325 22.0257 19.7001C22.0391 19.6651 22.0579 19.6381 22.082 19.6138C22.1061 19.5923 22.1409 19.5788 22.1865 19.5707L23.0972 19.4898L23.4481 18.6487C23.4642 18.611 23.4883 18.5813 23.5205 18.5624C23.5526 18.5436 23.5874 18.5328 23.6222 18.5328C23.6571 18.5328 23.6892 18.5436 23.724 18.5624C23.7562 18.5813 23.7803 18.611 23.7964 18.6487L24.1473 19.4898L25.058 19.5707C25.1009 19.5761 25.1357 19.5923 25.1625 19.6138C25.1866 19.6354 25.2054 19.6651 25.2188 19.7001C25.2321 19.7351 25.2348 19.7702 25.2268 19.8052C25.2188 19.8403 25.2 19.8726 25.1679 19.9023L24.4768 20.5035L24.683 21.3958C24.6937 21.4363 24.6884 21.474 24.6723 21.5064C24.6562 21.5387 24.6348 21.5657 24.6053 21.5899C24.5759 21.6115 24.5437 21.625 24.5062 21.6277C24.4687 21.6304 24.4339 21.6223 24.3991 21.598L23.6196 21.1262H23.6249Z"
          fill="#FFD477"
        />
        <path
          d="M6.46794 21.1262L5.68844 21.598C5.65362 21.6196 5.61879 21.6304 5.58129 21.6277C5.54379 21.625 5.51165 21.6115 5.48218 21.5899C5.45271 21.5684 5.43128 21.5414 5.41521 21.5064C5.39914 21.474 5.39646 21.4363 5.4045 21.3958L5.61076 20.5035L4.91965 19.9023C4.88751 19.8726 4.86876 19.843 4.86072 19.8052C4.85269 19.7702 4.85536 19.7325 4.86876 19.7001C4.88215 19.6651 4.9009 19.6381 4.92501 19.6138C4.94912 19.5923 4.98394 19.5788 5.02948 19.5707L5.94024 19.4898L6.29115 18.6487C6.30722 18.611 6.33133 18.5813 6.36347 18.5624C6.39562 18.5436 6.43044 18.5328 6.46526 18.5328C6.50009 18.5328 6.53223 18.5436 6.56705 18.5624C6.5992 18.5813 6.62331 18.611 6.63938 18.6487L6.99029 19.4898L7.90105 19.5707C7.94391 19.5761 7.97873 19.5923 8.00552 19.6138C8.02962 19.6354 8.04838 19.6651 8.06177 19.7001C8.07516 19.7351 8.07784 19.7702 8.06981 19.8052C8.06177 19.8403 8.04302 19.8726 8.01087 19.9023L7.31977 20.5035L7.52603 21.3958C7.53674 21.4363 7.53139 21.474 7.51531 21.5064C7.49924 21.5387 7.47781 21.5657 7.44835 21.5899C7.41888 21.6115 7.38674 21.625 7.34923 21.6277C7.31173 21.6304 7.27691 21.6223 7.24209 21.598L6.46258 21.1262H6.46794Z"
          fill="#FFD477"
        />
        <path
          d="M10.0842 7.49305L9.30465 7.96483C9.26983 7.9864 9.235 7.99718 9.1975 7.99449C9.16 7.99179 9.12786 7.97831 9.09839 7.95674C9.06893 7.93518 9.0475 7.90822 9.03142 7.87317C9.01535 7.84082 9.01267 7.80308 9.02071 7.76264L9.22697 6.8703L8.53586 6.26911C8.50372 6.23946 8.48497 6.2098 8.47693 6.17206C8.4689 6.13701 8.47158 6.09927 8.48497 6.06692C8.49836 6.03187 8.51711 6.00491 8.54122 5.98065C8.56533 5.95908 8.60015 5.94561 8.64569 5.93752L9.55645 5.85664L9.90736 5.01552C9.92343 4.97778 9.94754 4.94812 9.97968 4.92925C10.0118 4.91038 10.0467 4.8996 10.0815 4.8996C10.1163 4.8996 10.1484 4.91038 10.1833 4.92925C10.2154 4.94812 10.2395 4.97778 10.2556 5.01552L10.6065 5.85664L11.5173 5.93752C11.5601 5.94291 11.5949 5.95908 11.6217 5.98065C11.6458 6.00222 11.6646 6.03187 11.678 6.06692C11.6914 6.10197 11.6941 6.13701 11.686 6.17206C11.678 6.20711 11.6592 6.23946 11.6271 6.26911L10.936 6.8703L11.1422 7.76264C11.153 7.80308 11.1476 7.84082 11.1315 7.87317C11.1155 7.90552 11.094 7.93248 11.0646 7.95674C11.0351 7.97831 11.0029 7.99179 10.9654 7.99449C10.9279 7.99718 10.8931 7.9891 10.8583 7.96483L10.0788 7.49305H10.0842Z"
          fill="#FFD477"
        />
        <path
          d="M15.0451 26.1352L14.2656 26.607C14.2308 26.6286 14.1959 26.6393 14.1584 26.6366C14.1209 26.6339 14.0888 26.6205 14.0593 26.5989C14.0299 26.5773 14.0084 26.5504 13.9924 26.5153C13.9763 26.483 13.9736 26.4452 13.9816 26.4048L14.1879 25.5125L13.4968 24.9113C13.4647 24.8816 13.4459 24.852 13.4379 24.8142C13.4298 24.7792 13.4325 24.7414 13.4459 24.7091C13.4593 24.674 13.4781 24.6471 13.5022 24.6228C13.5263 24.6012 13.5611 24.5878 13.6066 24.5797L14.5174 24.4988L14.8683 23.6577C14.8844 23.6199 14.9085 23.5903 14.9406 23.5714C14.9728 23.5525 15.0076 23.5417 15.0424 23.5417C15.0772 23.5417 15.1094 23.5525 15.1442 23.5714C15.1763 23.5903 15.2005 23.6199 15.2165 23.6577L15.5674 24.4988L16.4782 24.5797C16.5211 24.5851 16.5559 24.6012 16.5827 24.6228C16.6068 24.6444 16.6255 24.674 16.6389 24.7091C16.6523 24.7441 16.655 24.7792 16.647 24.8142C16.6389 24.8493 16.6202 24.8816 16.588 24.9113L15.8969 25.5125L16.1032 26.4048C16.1139 26.4452 16.1085 26.483 16.0925 26.5153C16.0764 26.5477 16.055 26.5746 16.0255 26.5989C15.996 26.6205 15.9639 26.6339 15.9264 26.6366C15.8889 26.6393 15.8541 26.6312 15.8192 26.607L15.0397 26.1352H15.0451Z"
          fill="#FFD477"
        />
        <path
          d="M12.5231 19.5262C12.2929 19.5262 12.0948 19.4426 11.9316 19.2781C11.7683 19.1136 11.6853 18.9141 11.6853 18.6822V14.462C11.6853 14.2301 11.7683 14.0306 11.9316 13.8661C12.0948 13.7016 12.2929 13.618 12.5231 13.618H12.9434V12.774C12.9434 12.1888 13.1468 11.6926 13.5563 11.2801C13.9658 10.8675 14.4584 10.6625 15.0392 10.6625C15.62 10.6625 16.1126 10.8675 16.5221 11.2801C16.9316 11.6926 17.1351 12.1888 17.1351 12.774V13.618H17.5553C17.7855 13.618 17.9836 13.7016 18.1468 13.8661C18.3101 14.0306 18.3931 14.2301 18.3931 14.462V18.6822C18.3931 18.9141 18.3101 19.1136 18.1468 19.2781C17.9836 19.4426 17.7855 19.5262 17.5553 19.5262H12.5285H12.5231ZM15.0365 17.4148C15.2667 17.4148 15.4648 17.3312 15.6281 17.1667C15.7914 17.0022 15.8743 16.8027 15.8743 16.5708C15.8743 16.3388 15.7914 16.1393 15.6281 15.9748C15.4648 15.8103 15.2667 15.7267 15.0365 15.7267C14.8063 15.7267 14.6083 15.8103 14.445 15.9748C14.2817 16.1393 14.1987 16.3388 14.1987 16.5708C14.1987 16.8027 14.2817 17.0022 14.445 17.1667C14.6083 17.3312 14.8063 17.4148 15.0365 17.4148ZM13.7785 13.6153H16.2919V12.7713C16.2919 12.4207 16.1688 12.1214 15.9252 11.8733C15.6816 11.6279 15.3845 11.5039 15.0339 11.5039C14.6832 11.5039 14.3888 11.6279 14.1425 11.8733C13.8989 12.1187 13.7758 12.418 13.7758 12.7713V13.6153H13.7785Z"
          fill="#CFCFCF"
        />
        <path
          d="M35.0909 21.4872C35.0909 19.9068 36.2047 18.8235 37.5969 18.8235C38.488 18.8235 39.094 19.2623 39.4838 19.8417L38.9662 20.1433C38.6878 19.7114 38.1767 19.396 37.5937 19.396C36.5388 19.396 35.7199 20.2599 35.7199 21.4872C35.7199 22.7145 36.5388 23.5784 37.5937 23.5784C38.1767 23.5784 38.6845 23.2699 38.9662 22.831L39.4904 23.1327C39.0776 23.719 38.488 24.1509 37.5937 24.1509C36.1981 24.1509 35.0876 23.071 35.0876 21.4872H35.0909Z"
          fill="#AFB0B1"
        />
        <path
          d="M42.5885 18.9094C44.0462 18.9094 45.0345 20.0142 45.0345 21.4872C45.0345 22.9602 44.043 24.065 42.5885 24.065C41.1341 24.065 40.1426 22.9602 40.1426 21.4872C40.1426 20.0142 41.1242 18.9094 42.5885 18.9094ZM42.5885 19.4635C41.4788 19.4635 40.7762 20.3227 40.7762 21.4872C40.7762 22.6517 41.4788 23.5109 42.5885 23.5109C43.6982 23.5109 44.4008 22.6451 44.4008 21.4872C44.4008 20.3294 43.6917 19.4635 42.5885 19.4635Z"
          fill="#AFB0B1"
        />
        <path
          d="M49.9597 19.7744L48.2592 24.065H48.0029L46.3091 19.7744V24.065H45.6868V18.9094H46.5786L48.1294 22.8362L49.6868 18.9094H50.5787V24.065H49.9564V19.7744H49.9597Z"
          fill="#AFB0B1"
        />
        <path
          d="M51.2343 18.9094H53.2466C54.2544 18.9094 54.8183 19.6195 54.8183 20.4633C54.8183 21.3071 54.241 22.0172 53.2466 22.0172H51.855V24.065H51.231V18.9129L51.2343 18.9094ZM53.1732 19.4823H51.8583V21.4443H53.1732C53.7672 21.4443 54.1709 21.043 54.1709 20.4633C54.1709 19.8836 53.7672 19.4823 53.1732 19.4823Z"
          fill="#AFB0B1"
        />
        <path d="M55.4705 18.9094H56.0907V23.4918H58.4056V24.065H55.4705V18.9094Z" fill="#AFB0B1" />
        <path d="M59.0579 18.9094H59.7101V24.065H59.0579V18.9094Z" fill="#AFB0B1" />
        <path
          d="M63.9756 22.922H61.4783L61.0418 24.065H60.3625L62.3414 18.9094H63.1027L65.0914 24.065H64.4121L63.9756 22.922ZM61.6555 22.3488H63.7919L62.7286 19.4826L61.6555 22.3488Z"
          fill="#AFB0B1"
        />
        <path
          d="M66.3569 19.9083V24.065H65.7437V18.9094H66.3701L69.2069 22.9838V18.9094H69.8202V24.065H69.2299L66.3569 19.9083Z"
          fill="#AFB0B1"
        />
        <path d="M72.1045 19.4826H70.4724V18.9094H74.3859V19.4826H72.747V24.065H72.1045V19.4826Z" fill="#AFB0B1" />
        <path
          d="M40.4455 6.03772C42.6139 6.03772 43.9493 7.15006 44.6754 8.43093L42.5394 9.60394C42.1375 8.92305 41.3822 8.38374 40.4455 8.38374C38.8281 8.38374 37.6839 9.68146 37.6839 11.3634C37.6839 13.0454 38.8281 14.3432 40.4455 14.3432C41.2169 14.3432 41.9138 14.0634 42.3157 13.7263V12.8634H40.0436V10.6556H44.8245V14.687C43.7549 15.9072 42.3157 16.6925 40.4455 16.6925C37.4927 16.6925 35.0876 14.6398 35.0876 11.3668C35.0876 8.09385 37.4927 6.04109 40.4455 6.04109V6.03772Z"
          fill="#AFB0B1"
        />
        <path
          d="M45.7981 6.20422H49.9112C52.9952 6.20422 55.2104 8.15372 55.2104 11.3567C55.2104 14.5597 52.992 16.5261 49.924 16.5261H45.7981V6.20422ZM49.9112 14.2049C51.6295 14.2049 52.6265 12.8906 52.6265 11.3567C52.6265 9.82279 51.716 8.52538 49.924 8.52538H48.3243V14.2049H49.9112Z"
          fill="#AFB0B1"
        />
        <path
          d="M56.1841 6.20422H61.1633C63.399 6.20422 64.6227 7.76855 64.6227 9.64034C64.6227 11.5121 63.399 13.0292 61.1633 13.0292H58.7485V16.5261H56.1841V6.20422ZM60.8183 8.44767H58.7453V10.7688H60.8183C61.505 10.7688 62.0094 10.3195 62.0094 9.62345C62.0094 8.92744 61.5017 8.44767 60.8183 8.44767Z"
          fill="#AFB0B1"
        />
        <path
          d="M69.4516 13.0292H68.1471V16.5261H65.5964V6.20422H70.5489C72.7435 6.20422 73.9897 7.72125 73.9897 9.64034C73.9897 11.4344 72.9377 12.394 72.0184 12.7352L74.035 16.5261H71.1153L69.4548 13.0292H69.4516ZM70.1637 8.44767H68.1471V10.7688H70.1637C70.8305 10.7688 71.3937 10.3195 71.3937 9.60993C71.3937 8.90041 70.8305 8.44767 70.1637 8.44767Z"
          fill="#AFB0B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_529_631">
          <rect width="80" height="30.1887" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GDPRDarkIcon;
