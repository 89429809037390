import { Dispatch, SetStateAction } from 'react';
import CrpytoMd5 from 'crypto-js/md5';
import mixpanel from 'mixpanel-browser';
import {
  MIXPANEL_ENABLE,
  UserPropertyProps,
  UserPropertyOnceProps,
  TrackFirstStepCompletedProps,
  TrackSecondStepCompletedProps,
  EVENT_NAMES,
  INCREMENTAL_VALUES_NAMES,
  TrackPackagesPageVisitedProps,
  TrackProfileViewProps,
  TrackOfferDeclinedProps,
  TrackOfferCancelledProps,
  TrackOfferIgnoredProps,
  TRACK_PAGE_VIEW_PATHS,
  MIXPANEL_DEFAULT_STATE,
  MixpanelDefaultStateProps,
  TrackSearchViewProps,
  TrackPhotoStepCompletedProps,
  IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY,
  MIXPANEL_CREDITS_PURCHASED,
} from '../constants/mixpanel';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { useMixpanelState } from '../contexts/MixpanelContext';
import { useApp } from '../contexts/AppContext';
import * as sdk from '../sdk';
import { getLocalItem, removeLocalItem } from '../common';
import { mixpanelTrackPackagesVisited } from '../utils/mixpanel/packagesVisited';
import { mixpanelTrackOfferDeclined } from '../utils/mixpanel/offerDeclined';
import { mixpanelTrackOfferCancelled } from '../utils/mixpanel/offerCancelled';
import { mixpanelTrackOfferIgnored } from '../utils/mixpanel/offerIgnored';

interface UseMixPanelProps {
  mixpanelActions: {
    identify: (id: string) => void;
    alias: (id: string) => void;
    track: (name: string, props: any) => void;
    people: {
      set: (props: Partial<UserPropertyProps>) => void;
      set_once: (props: Partial<UserPropertyOnceProps>) => void;
      increment: (key: string, value?: number) => void;
      append: (props: Partial<UserPropertyProps>) => void;
      union: (props: Partial<UserPropertyProps>) => void;
      unset: (props: string[]) => void;
    };
  };
  trackFirstStepCompleted: (identifier: string, payload: TrackFirstStepCompletedProps, alias?: string) => any;
  trackSecondStepCompleted: (
    identifier: string,
    alias: string,
    payload: TrackSecondStepCompletedProps,
    isNewVariant: boolean
  ) => any;
  trackPhotoStepCompleted: (identifier: string, alias: string, payload: TrackPhotoStepCompletedProps) => void;
  trackCreditsPurchased: (payload: any) => void;
  trackPackagesPageVisited: (payload: TrackPackagesPageVisitedProps) => void;
  trackProfileView: (payload: TrackProfileViewProps, isStarted?: boolean) => void;
  trackOfferDeclined: (identifier: string, payload: TrackOfferDeclinedProps) => void;
  trackOfferCancelled: (identifier: string, payload: TrackOfferCancelledProps) => void;
  trackOfferIgnored: (identifier: string, payload: TrackOfferIgnoredProps) => void;
  trackMessageTyped: (isStarted: boolean) => string;
  trackPageView: (isStarted: boolean, pathname: string) => string;
  trackSearchView: (isStarted: boolean, payload: Partial<TrackSearchViewProps>) => void;
  mixpanelData: MixpanelDefaultStateProps;
  setMixpanelDataByKeyHandler: Dispatch<SetStateAction<any>>;
  resetMixpanelData: (key: string) => void;
  trackProfileWallStep: (eventName: string, payload: any) => void;
  trackEvent: (data: { name: EVENT_NAMES; payload: unknown; identifier?: string }) => void;
}

const useMixPanel = (): UseMixPanelProps => {
  const { mixpanelData, setMixpanelDataByKeyHandler } = useMixpanelState();
  const { accountHashId, isAccountIsMixpanelEligableEnabled } = useApp();

  const resetMixpanelData = (key: string) => {
    setMixpanelDataByKeyHandler({
      key,
      payload: MIXPANEL_DEFAULT_STATE[key],
    });
  };

  const mixpanelActions = {
    identify: (id: string | number) => {
      if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.identify(id);
    },
    alias: (id: string, identifier?: string) => {
      if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.alias(id, identifier);
    },
    track: (name: string, props: any) => {
      if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.track(name, props);
    },
    people: {
      set: (props: Partial<UserPropertyProps>) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.set(props);
      },
      set_once: (props: Partial<UserPropertyOnceProps>) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.set_once(props);
      },
      increment: (key, value = 1) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.increment(key, value);
      },
      append: (props: Partial<UserPropertyProps>) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.append(props);
      },
      union: (props: Partial<UserPropertyProps>) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.union(props);
      },
      unset: (props: string[]) => {
        if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.people.unset(props);
      },
    },
    time_event: (name: string) => {
      if (MIXPANEL_ENABLE && isAccountIsMixpanelEligableEnabled) mixpanel.time_event(name);
    },
  };

  const trackFirstStepCompleted = (identifier, payload, alias) => {
    try {
      mixpanelActions.identify(identifier);

      if (alias && typeof alias !== 'undefined') {
        mixpanelActions.identify(alias);
        mixpanelActions.alias(alias, identifier);
      }

      mixpanelActions.people.set(payload);
      mixpanelActions.track(EVENT_NAMES.FIRST_STEP_COMPLETED, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackFirstStepCompleted' } });
    }

    return {
      track: EVENT_NAMES.FIRST_STEP_COMPLETED,
      identifier,
      payload,
    };
  };

  const trackSecondStepCompleted = (identifier, alias, payload, isNewVariant) => {
    try {
      const encryptAlias = CrpytoMd5(alias).toString();

      mixpanelActions.identify(identifier);
      mixpanelActions.identify(encryptAlias); // call as 1st step use this as identifier
      mixpanelActions.alias(encryptAlias, identifier);
      mixpanelActions.people.set(payload);

      // registration date only available in oldVariant
      if (!isNewVariant) {
        mixpanelActions.people.set_once({
          'Registration Date': format(new Date(), 'MM/DD/YYYY h:mm'),
        });
      }

      mixpanelActions.track(EVENT_NAMES.SECOND_STEP_COMPLETED, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackSecondStepCompleted' } });
    }

    return {
      track: EVENT_NAMES.SECOND_STEP_COMPLETED,
      identifier,
      payload,
    };
  };

  const trackPhotoStepCompleted = (identifier, alias, payload) => {
    try {
      const encryptAlias = CrpytoMd5(alias).toString();
      mixpanelActions.identify(identifier);
      mixpanelActions.identify(encryptAlias);
      mixpanelActions.alias(encryptAlias, identifier);
      mixpanelActions.people.set_once({
        'Registration Date': format(new Date(), 'MM/DD/YYYY h:mm'),
      });

      mixpanelActions.track(EVENT_NAMES.PHOTO_STEP_COMPLETED, payload);
    } catch (error) {
      Sentry.captureException(error, {
        tags: { component: 'useMixPanel', action: 'trackPhotoStepCompleted' },
      });
    }
  };

  const trackCreditsPurchased = async (additionalPayload = {}) => {
    if (!MIXPANEL_ENABLE || !isAccountIsMixpanelEligableEnabled) {
      resetMixpanelData(EVENT_NAMES.CREDITS_PURCHASED);
      removeLocalItem(IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY);
      removeLocalItem(MIXPANEL_CREDITS_PURCHASED);
      return;
    }

    try {
      const localStorageData = JSON.parse(getLocalItem(MIXPANEL_CREDITS_PURCHASED));

      const shouldUseLocalStorageData = localStorageData !== null && typeof localStorageData === 'object';
      const data = shouldUseLocalStorageData ? localStorageData : mixpanelData[EVENT_NAMES.CREDITS_PURCHASED];
      const melissaUsed = getLocalItem(IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY);

      if (data.Amount > 0) {
        const payload = {
          event_name: EVENT_NAMES.CREDITS_PURCHASED,
          source: data.Source ? data.Source : 'Direct URL',
          amount: data.Amount,
          total_costs: data.Total,
          auto_topup: data.AutoTopup,
          payment_method: data.PaymentMethod ?? null,
          melissa_used: typeof melissaUsed === 'string' && melissaUsed === 'true',
          ...additionalPayload,
        };

        await sdk.mixpanelTrack(payload);
      }

      resetMixpanelData(EVENT_NAMES.CREDITS_PURCHASED);

      removeLocalItem(IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY);
      removeLocalItem(MIXPANEL_CREDITS_PURCHASED);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackCreditsPurchased' } });
    }
  };

  const trackPackagesPageVisited = payload => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        // add event property when user try to unclock offer without any credits left
        if (payload['Source'] === 'Unlock Attempt') {
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.CONVERSATION_UNLOCKED,
            payload: { triggerToBuy: true },
          });
        }

        setMixpanelDataByKeyHandler({
          key: EVENT_NAMES.CREDITS_PURCHASED,
          payload,
        });

        mixpanelTrackPackagesVisited(payload);
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackPackagesPageVisited' } });
      }
    }
  };

  const trackProfileView = (payload, isStarted = true) => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        mixpanelActions.identify(accountHashId);
        if (isStarted) {
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.PROFILE_VIEW,
            payload,
          });
          mixpanelActions.time_event(EVENT_NAMES.PROFILE_VIEW);
        } else {
          if (payload.Source !== '' && payload.User !== '') {
            mixpanelActions.people.increment(INCREMENTAL_VALUES_NAMES.PROFILE_VIEWS);
            mixpanelActions.track(EVENT_NAMES.PROFILE_VIEW, payload);
          }
          resetMixpanelData(EVENT_NAMES.PROFILE_VIEW);
        }
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackProfileView' } });
      }
    }
  };

  const trackOfferDeclined = (initiatorHashId, payload) => {
    try {
      mixpanelTrackOfferDeclined(initiatorHashId, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackOfferDeclined' } });
    }
  };

  const trackOfferCancelled = (initiatorHashId, payload) => {
    try {
      mixpanelTrackOfferCancelled(initiatorHashId, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackOfferCancelled' } });
    }
  };

  const trackOfferIgnored = (initiatorHashId, payload) => {
    try {
      mixpanelTrackOfferIgnored(initiatorHashId, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackOfferIgnored' } });
    }
  };

  const trackMessageTyped = (isStarted = false) => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        mixpanelActions.identify(accountHashId);
        if (isStarted) {
          mixpanelActions.time_event(EVENT_NAMES.MESSAGE_TYPED);
        } else {
          mixpanelActions.track(EVENT_NAMES.MESSAGE_TYPED, {});
        }
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackMessageTyped' } });
      }
    }

    return EVENT_NAMES.MESSAGE_TYPED;
  };

  const trackPageView = (isStarted = false, pathname) => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        mixpanelActions.identify(accountHashId);
        const pageName = TRACK_PAGE_VIEW_PATHS[pathname];

        if (isStarted && pageName) {
          mixpanelActions.time_event(EVENT_NAMES.PAGE_VIEW);
        }

        if (!isStarted && pageName) {
          const overidePageName = localStorage.getItem('mxTrackPageView_page_name');
          const pageNameValue = overidePageName ? overidePageName : pageName;
          const payload = {
            'Page Name': pageNameValue,
          };

          mixpanelActions.track(EVENT_NAMES.PAGE_VIEW, payload);
          localStorage.removeItem('mxTrackPageView_page_name');
        }
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackPageView' } });
      }
    }

    return EVENT_NAMES.PAGE_VIEW;
  };

  const trackSearchView = (isStarted = false, payload) => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        mixpanelActions.identify(accountHashId);
        if (isStarted) {
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.SEARCH_VIEW,
            payload,
          });
          mixpanelActions.time_event(EVENT_NAMES.SEARCH_VIEW);
        } else {
          const currentPayload = mixpanelData[EVENT_NAMES.SEARCH_VIEW];
          const finalPayload = {
            ...currentPayload,
            ...payload,
          };
          mixpanelActions.track(EVENT_NAMES.SEARCH_VIEW, finalPayload);
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.SEARCH_VIEW,
            payload: finalPayload,
          });
        }
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackSearchView' } });
      }
    }

    return EVENT_NAMES.SEARCH_VIEW;
  };

  const trackProfileWallStep = (eventName, payload) => {
    if (accountHashId && isAccountIsMixpanelEligableEnabled) {
      try {
        mixpanelActions.identify(accountHashId);
        mixpanelActions.track(eventName, payload);
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'useMixPanel', action: 'trackProfileWallStep' } });
      }
    }
  };

  const trackEvent: UseMixPanelProps['trackEvent'] = ({ identifier, name, payload }) => {
    const hashId = identifier ?? accountHashId;

    if (!hashId && !isAccountIsMixpanelEligableEnabled) {
      return;
    }

    try {
      mixpanelActions.identify(hashId);
      mixpanelActions.track(name, payload);
    } catch (error) {
      Sentry.captureException(error, { tags: { component: 'useMixPanel', action: `trackEvent: ${name}` } });
    }
  };

  return {
    mixpanelActions,
    trackFirstStepCompleted,
    trackSecondStepCompleted,
    trackPhotoStepCompleted,
    trackCreditsPurchased,
    trackPackagesPageVisited,
    trackProfileView,
    trackOfferDeclined,
    trackOfferCancelled,
    trackOfferIgnored,
    trackMessageTyped,
    trackPageView,
    trackSearchView,
    mixpanelData,
    setMixpanelDataByKeyHandler,
    resetMixpanelData,
    trackProfileWallStep,
    trackEvent,
  };
};

export default useMixPanel;
