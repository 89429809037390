import React, { ReactElement, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { Wrapper, HeaderWrapper, Title2, Description } from './styledElements';
import { DashlineSeparator } from '../join/styledElements';
import CompletePhotoUploadForm from './secondStep/CompletePhotoUploadForm';
import JoinActionButton from '../join/JoinActionButton';
import JoinSkipButton from '../join/JoinSkipButton';
import CompleteProfileDetailsForm from './secondStep/CompleteProfileDetailsForm';
import CompleteAboutMeForm from './secondStep/CompleteAboutMeForm';
import JoinSkipConfirmation from '../join/JoinSkipConfirmation';
import useModal from '../../hooks/useModal';
import { useApp } from '../../contexts/AppContext';
import _isEmpty from 'lodash/isEmpty';
import { updateProfileMandatoryCompletion } from '../../actions/profileActions';
import { getBrowserDetails } from '../../utils/helpers';
import useMixPanel from '../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../constants/mixpanel';
import { handleProfileCompletionVysionEvent } from '../../utils/vysion/profileCompletion';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { ProfileCompletionClickVysionEventsEnum } from '../../utils/vysion/profileCompletion/click';
import {
  DEFAULT_REASON,
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS,
  EVIDENTLY_NEW_PROFILE_WALL_METRIC_KEYS,
  trackEvidentlyMetric,
  evidentlyEnabled,
} from '../../constants/evidently';
import { getLocalItem, removeLocalItem, setLocalItem } from '../../common';
import { fetchEvalFeature } from '../../sdk/CommonSDK';
import Optional from '../../atoms/Optional';
import uuidv4 from 'uuid';
import { showUploadSnackbar } from '../../actions/uploadSnackbarActions';
import { galleryBulkUploadMedia } from '../../actions/galleryActions';
import { baseurltoFile } from '../../modules/gallery/helpers';
import { MAX_NUMBER_PHOTOS } from '../../config/Master';

const ScrollbarsContentView = styled('div')`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const convertToFormData = async data => {
  const formDataObj = new FormData();
  const promises = [];

  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === undefined) continue;

    if (key === 'photos') {
      const promise = baseurltoFile(value[0].edited).then(file => {
        formDataObj.append('photos[]', file);
      });
      promises.push(promise);
    } else {
      formDataObj.append(key, value === null ? '' : (value as string));
    }
  }

  await Promise.all(promises);

  return formDataObj;
};

const SecondStepCompleteProfile = ({
  hasRequiredPhoto,
  profileData,
  updateMandatoryCompletion,
  action,
  profile,
  showSnackbar,
  uploadPhotos,
}): ReactElement => {
  const location = useLocation();
  const { resetModal } = useModal();
  const { trackProfileWallStep } = useMixPanel();
  const {
    setProfileCompletionStep,
    setModalState,
    profileCompletionCallback,
    setProfileCompletionCallback,
    profileCompletionCallbackAfterValidate,
  } = useApp();
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const { isMobile } = getBrowserDetails();

  const [formData, setFormData] = useState({
    body_type: null,
    children: null,
    smoking: null,
    drinking: null,
    education: null,
    income: null,
    height: null,
    occupation: null,
    description: '',
    seeking: '',
    photos: [],
  });
  const [variant, setVariant] = useState<EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS>(
    EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.OLD_PROFILE_WALL
  );

  const showRequiredPhoto = useMemo(() => hasRequiredPhoto, [hasRequiredPhoto]);
  const isEvidentlyEnabled = evidentlyEnabled();

  const allowSubmit = useMemo(() => {
    const hasUploadedPhoto = showRequiredPhoto
      ? formData.photos.length > 0 && formData.photos.length <= MAX_NUMBER_PHOTOS
        ? true
        : false
      : true;

    let validDescriptionOrSeeking = true;
    if (variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.OLD_PROFILE_WALL) {
      const validateDesc = formData.description ? formData.description.length >= 50 : false;
      const validateSeek = formData.seeking ? formData.seeking.length >= 50 : false;
      validDescriptionOrSeeking = validateDesc && validateSeek;
    }

    if (variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.NEW_PROFILE_WALL_OPT_ABOUT) {
      const validateDesc = !formData.description ? true : formData.description.length >= 50;
      const validateSeek = !formData.seeking ? true : formData.seeking.length >= 50;
      validDescriptionOrSeeking = validateDesc && validateSeek;
    }

    const incompleteFields = [
      formData.body_type,
      formData.height,
      formData.children,
      formData.smoking,
      formData.drinking,
      formData.education,
      formData.height,
      ...(profileData.account_type === 'Generous' ? [formData.income, formData.occupation] : []),
    ];

    const updateIncompleteFields = incompleteFields.filter(
      field => field === '' || field === null || field === undefined
    );

    return Boolean(updateIncompleteFields.length === 0 && hasUploadedPhoto && validDescriptionOrSeeking);
  }, [formData, showRequiredPhoto, profileData]);

  useEffect(() => {
    (async () => {
      removeLocalItem('evidently_new_profile_wall'); // depreciated this in future sprint

      if (!isEvidentlyEnabled) {
        removeLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.NEW_PROFILE_WALL);
        return;
      }

      const existing = JSON.parse(getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.NEW_PROFILE_WALL));
      if (existing !== null) {
        const { variation } = existing;

        setVariant(variation);
        return;
      }

      try {
        const res = await fetchEvalFeature(EVIDENTLY_FEATURES.NEW_PROFILE_WALL);
        const { variation, userId, reason } = res.data;
        setLocalItem(
          EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.NEW_PROFILE_WALL,
          JSON.stringify({
            variation,
            userId,
            reason,
          })
        );

        setVariant(variation);
      } catch (error) {
        console.error(`fetchEvalFeature.${EVIDENTLY_FEATURES.NEW_PROFILE_WALL}.error`, error);
      }
    })();
  }, [isEvidentlyEnabled]);

  const handleFormData = state => {
    setFormData(prev => {
      return {
        ...prev,
        ...state,
      };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const updatedFormData = await convertToFormData(formData);

    // send metric to evidently
    const existing = JSON.parse(getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.NEW_PROFILE_WALL));
    if (existing && existing.reason !== DEFAULT_REASON) {
      sendEvidentlyMetric(1);
    }

    updateMandatoryCompletion(updatedFormData, async () => {
      // close mandatory popup
      resetModal();

      const clonedPhotos = [...formData.photos];
      clonedPhotos.splice(0, 1);

      for (const photo of clonedPhotos) {
        showSnackbar({
          id: uuidv4(),
          status: '',
          type: 'photo',
          file: photo,
        });
      }

      // start upload process
      uploadPhotos();

      // add mixpanel profile wall step with photos
      trackProfileWallStep(EVENT_NAMES.PROFILE_WALL_STEP_2_COMPLETED, {
        '# of photos added': formData.photos.length,
        Result: 'Completed',
        Action: action,
      });

      // launch this callback pass from the last component action
      profileCompletionCallback();

      // reset all state in mandatory popup with .8 delay
      setTimeout(() => {
        setProfileCompletionStep(2);
        setProfileCompletionCallback(() => undefined);
      }, 800);
    });

    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_SAVE_AND_FINISH,
      },
      location.pathname
    );
  };

  const onSkip = () => {
    sendEvidentlyMetric(0);
    setProfileCompletionStep(2);
    setProfileCompletionCallback(() => undefined);
    profileCompletionCallbackAfterValidate();
    resetModal();

    trackProfileWallStep(EVENT_NAMES.PROFILE_WALL_STEP_2_COMPLETED, {
      '# of photos added': formData.photos.length,
      Result: 'Skipped',
      Action: action,
    });

    handleProfileCompletionVysionEvent(
      {
        action: VYSION_ACTIONS.CLICK,
        event: ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_SKIP_FOR_NOW,
      },
      location.pathname
    );
  };

  const handleModalOverlay = state => {
    setModalState(prev => {
      return {
        ...prev,
        customOverlayStyle: state,
      };
    });
  };

  const handleOnClickSkip = () => {
    handleModalOverlay({ display: 'none' });
    setShowAgreementModal(true);
  };

  const sendEvidentlyMetric = (value: number) => {
    trackEvidentlyMetric(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.NEW_PROFILE_WALL, {
      value,
      experimentName: EVIDENTLY_EXPERIMENTS.NEW_PROFILE_WALL,
      featureName: EVIDENTLY_FEATURES.NEW_PROFILE_WALL,
      key: EVIDENTLY_NEW_PROFILE_WALL_METRIC_KEYS.COMPLETIONS,
    });
  };

  const showRequiredProfileDetailLists = useMemo(() => {
    const {
      height,
      body_types,
      children,
      smoking,
      drinking,
      education,
      income,
      description,
      seeking,
      occupation,
      account_type,
    } = profileData;

    const bodyType = body_types.data.length > 0 ? parseInt(body_types.data[0].value) : null;

    handleFormData({
      body_type: bodyType,
      children: children === 0 ? null : children,
      smoking: smoking === 0 ? null : smoking,
      drinking: drinking === 0 ? null : drinking,
      education: education === 0 ? null : education,
      income: income === 0 ? null : income,
      height,
      occupation,
      description,
      seeking,
    });

    return {
      body_type: body_types.data.length === 0,
      children: children === 0,
      smoking: smoking === 0,
      drinking: drinking === 0,
      education: education === 0,
      height: _isEmpty(height),
      income: account_type === 'Attractive' ? false : income === 0,
      occupation: account_type === 'Attractive' ? false : _isEmpty(occupation),
      description:
        variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.NEW_PROFILE_WALL_NO_ABOUT
          ? false
          : _isEmpty(description) || (!_isEmpty(description) && description.length < 50),
      seeking:
        variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.NEW_PROFILE_WALL_NO_ABOUT
          ? false
          : _isEmpty(seeking) || (!_isEmpty(seeking) && seeking.length < 50),
    };
  }, [profileData]);

  const memoizedCompletePhotoUploadForm = useMemo(
    () =>
      showRequiredPhoto && (
        <CompletePhotoUploadForm
          label="Upload at least 1 public photo"
          onCancelUpload={() => handleModalOverlay({ display: 'block' })}
          handlePhotoUpload={handleFormData}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showRequiredPhoto]
  );

  return (
    <Scrollbars
      autoHeight
      autoHeightMin={100}
      autoHeightMax={678}
      style={{ marginTop: 10 }}
      renderView={props => <ScrollbarsContentView {...props} />}
    >
      <Wrapper
        style={{ paddingLeft: isMobile ? 0 : 60, paddingRight: isMobile ? 0 : 60, paddingBottom: isMobile ? 12 : 40 }}
      >
        <HeaderWrapper style={{ marginBottom: 20 }}>
          <Title2>{`Let's Finish Up Your Profile`}</Title2>
          <Description>
            Completed profiles allow you to make and accept WYP Date Offers, and add members to your favorites.
          </Description>
        </HeaderWrapper>
        <DashlineSeparator />
        {memoizedCompletePhotoUploadForm}
        <CompleteProfileDetailsForm
          showRequiredProfileDetailLists={showRequiredProfileDetailLists}
          formData={formData}
          handleFormData={handleFormData}
          gender={profileData.gender}
          country={profileData.country}
        />
        <Optional show={variant !== EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.NEW_PROFILE_WALL_NO_ABOUT}>
          <CompleteAboutMeForm
            showRequiredProfileDetailLists={showRequiredProfileDetailLists}
            formData={formData}
            handleFormData={handleFormData}
            variant={variant}
          />
        </Optional>
        <div style={{ textAlign: 'center' }}>
          <JoinActionButton
            label={profile.isProcessing ? 'Processing ' : 'Save and Finish'}
            customStyle={{ marginBottom: 10 }}
            isDisabled={!allowSubmit || profile.isProcessing}
            onClick={handleSubmit}
          />
          <JoinSkipButton label="Skip for now" onClick={handleOnClickSkip} />
          <JoinSkipConfirmation
            isOpen={showAgreementModal}
            alwaysDisabledBodyScroll={true}
            onGoBack={() => {
              setShowAgreementModal(false);
              handleModalOverlay({ display: 'block' });
            }}
            onClose={() => {
              setShowAgreementModal(false);
              handleModalOverlay({ display: 'block' });
            }}
            onSkip={onSkip}
            customStyleSkipButton={{ paddingBottom: 20 }}
          />
        </div>
      </Wrapper>
    </Scrollbars>
  );
};

const mapStateToProps = ({ profile, common }) => {
  const profileCompletion = profile.mandatory_profile_status;
  return {
    profile,
    profileData: profile && profile.profile?.data,
    hasRequiredPhoto: profileCompletion.fields_missed.includes('Photos'),
    errors: common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMandatoryCompletion(formData, callback) {
      return dispatch(updateProfileMandatoryCompletion(formData, callback));
    },
    showSnackbar: payload => {
      dispatch(showUploadSnackbar(payload));
    },
    uploadPhotos: () => {
      dispatch(galleryBulkUploadMedia('photo'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStepCompleteProfile);
