import React, {
  useState,
  useContext,
  createContext,
  ReactElement,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { VideoPreviewProps, VIDEO_PREVIEW_DEFAULT } from '../constants/dashboardDiscoverMode';
import { DEFAULT_VISITED_PAGE, DEFAULT_VISITED_PAGE_PROPS } from '../constants/offers';

type offerVariants = 'decline' | 'accept' | 'offer' | 'request' | 'counter';

interface IUseOffers {
  offersActions: any;
  setOffersActionHandler: Dispatch<SetStateAction<any>>;
  visitedTab: DEFAULT_VISITED_PAGE_PROPS;
  setVisitedTab: Dispatch<SetStateAction<DEFAULT_VISITED_PAGE_PROPS>>;
  setVisitedTabHandler: any;
  offerActionContainerRef: any;
  photoAspectRatioRef: any;
  videoPreview: VideoPreviewProps;
  setVideoPreview: Dispatch<SetStateAction<VideoPreviewProps>>;
  oneTapActiveAccount: any;
  setOneTapActiveAccount: Dispatch<SetStateAction<any>>;
  oneTapOfferFlagVariant: offerVariants | null;
  setOneTapOfferFlagVariant: Dispatch<SetStateAction<offerVariants | null>>;
  oneTapMediaRatioValues: string;
  calculateDynamicMediaRatio: () => void;
  declineBtnRef: any;
  offerBtnRef: any;
}

const OffersContext = createContext({});

const OffersProvider = (props: any): ReactElement => {
  const [offersActions, setOffersActions] = useState({});
  const [visitedTab, setVisitedTab] = useState(DEFAULT_VISITED_PAGE);
  const [videoPreview, setVideoPreview] = useState(VIDEO_PREVIEW_DEFAULT);
  const offerActionContainerRef = useRef(null);
  const photoAspectRatioRef = useRef(null);
  const declineBtnRef = useRef(null);
  const offerBtnRef = useRef(null);
  const [oneTapActiveAccount, setOneTapActiveAccount] = useState(null);
  const [oneTapOfferFlagVariant, setOneTapOfferFlagVariant] = useState<offerVariants | null>(null);
  const [oneTapMediaRatioValues, setoOneTapMediaRatioValues] = useState('133%');

  const setOffersActionHandler = useCallback(
    () => (newState: any) => {
      setOffersActions(prevState => {
        return {
          ...prevState,
          ...newState,
        };
      });
    },
    []
  );

  const setVisitedTabHandler = useCallback(
    () => (key: string, value: boolean): any => {
      setVisitedTab(prevState => {
        return {
          ...prevState,
          [key]: value,
        };
      });
    },
    []
  );

  const calculateDynamicMediaRatio = useCallback(() => {
    const photoAspectEl = photoAspectRatioRef?.current;
    const offerActionEl = offerActionContainerRef?.current;

    if (photoAspectEl && offerActionEl) {
      const offerActionClRec = offerActionEl.getBoundingClientRect();
      const photoAspectClRec = photoAspectEl.getBoundingClientRect();

      const PHOTO_SLIDER_COUNTER_HEIGHT = 41;
      const PHOTO_SLIDER_MARGIN_BOTTOM = 15;
      const dynamicPaddingTop =
        offerActionClRec.top - photoAspectClRec.top - PHOTO_SLIDER_COUNTER_HEIGHT - PHOTO_SLIDER_MARGIN_BOTTOM;

      setoOneTapMediaRatioValues(`${dynamicPaddingTop}px`);
    }
  }, [photoAspectRatioRef?.current, offerActionContainerRef?.current]);

  const values: IUseOffers = {
    offersActions,
    setOffersActionHandler,
    visitedTab,
    setVisitedTab,
    setVisitedTabHandler,
    videoPreview,
    setVideoPreview,
    offerActionContainerRef,
    photoAspectRatioRef,
    oneTapActiveAccount,
    setOneTapActiveAccount,
    oneTapOfferFlagVariant,
    setOneTapOfferFlagVariant,
    oneTapMediaRatioValues,
    calculateDynamicMediaRatio,
    declineBtnRef,
    offerBtnRef,
  };

  return <OffersContext.Provider value={values} {...props} />;
};

const useOffers = (): IUseOffers => useContext(OffersContext) as IUseOffers;

export { OffersProvider, useOffers };
