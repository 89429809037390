import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
  page: 'Account Menu',
};

export const enum PhoneSettingsViewVysionEventsEnum {
  VIEW_ACCOUNT_CHANGE_PHONE_NUMBER = 'accountChangePhoneNumber',
  VIEW_ACCOUNT_MENU_VERIFY_ACCOUNT = 'accountMenuVerifyAccount',
  VIEW_ACCOUNT_MENU_UPDATED_NUMBER_VERIFICATION = 'accountMenuUpdatedNumberVerification',
  VIEW_ACCOUNT_MENU_PHONE_UPDATE_SUCCESS = 'accountMenuPhoneUpdateSuccess',
  VIEW_CURRENT_NUMBER_VERIFICATION = 'accountMenuCurrentNumberVerification',
}

export const phoneSettingsViewVysionEvents = {
  [PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_CHANGE_PHONE_NUMBER]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber',
    component: 'Change Phone Number',
  },
  [PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_VERIFY_ACCOUNT]: {
    ...commonValues,
    eventName: 'accountMenu-verifyAccount',
    component: 'Verify Account Ownership',
  },
  [PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_UPDATED_NUMBER_VERIFICATION]: {
    ...commonValues,
    eventName: 'accountMenu-updatedNumberVerification',
    component: 'Updated Number Verification Code',
  },
  [PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PHONE_UPDATE_SUCCESS]: {
    ...commonValues,
    eventName: 'accountMenu-phoneNumberUpdateSuccess',
    component: 'Phone Updated Successfully Modal',
  },
  [PhoneSettingsViewVysionEventsEnum.VIEW_CURRENT_NUMBER_VERIFICATION]: {
    ...commonValues,
    eventName: 'accountMenu-currentNumberVerification',
    component: 'Current Number Verification Code',
  },
};
