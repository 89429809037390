import React from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../theme/colors';
import { theme } from '../../../../theme';
import { CurrencySymbols } from '../DashboardDiscoverMode';
import { dimensions } from '../../../../style';

const ContainerWrapper = styled('div')(() => ({
  display: 'inline-flex',
  padding: '11px 12px',
  alignItems: 'center',
  gap: 5,
  borderRadius: 15,
  border: `3px solid ${colors[theme].green.primary}`,
  background: white,
  position: 'absolute',
  right: 10,
  top: 30,
  opacity: 0.6,
  [dimensions.SCREEN_MIN_MD]: {
    right: 20,
  },
}));

const IconWrapper = styled('div')({
  background: colors[theme].green.primary,
  borderRadius: 50,
  height: 30,
  width: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PassTextWrapper = styled('div')({
  color: colors[theme].green.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
});

interface Props {
  currencySymbols: CurrencySymbols;
}

const DashboardDiscoverModeOfferSentFlagOverlay: React.FC<Props> = ({ currencySymbols }) => {
  return (
    <ContainerWrapper>
      <IconWrapper>
        <span
          style={{
            color: white,
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          {currencySymbols.currencyLabel}
        </span>
      </IconWrapper>
      <PassTextWrapper>OFFER!</PassTextWrapper>
    </ContainerWrapper>
  );
};

export default DashboardDiscoverModeOfferSentFlagOverlay;
