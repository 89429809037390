import React, { ReactElement } from 'react';
const VideoSmall = (): ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_852_5291"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_852_5291)">
        <path
          d="M3.41699 16.688C2.93099 16.688 2.51766 16.5177 2.17699 16.177C1.83699 15.837 1.66699 15.424 1.66699 14.938V5.104C1.66699 4.618 1.83699 4.205 2.17699 3.865C2.51766 3.52434 2.93099 3.354 3.41699 3.354H13.25C13.736 3.354 14.1493 3.52434 14.49 3.865C14.83 4.205 15 4.618 15 5.104V8.646L17.542 6.104C17.6947 5.95134 17.8647 5.91667 18.052 6C18.2393 6.08334 18.333 6.22234 18.333 6.417V13.583C18.333 13.7777 18.2393 13.9167 18.052 14C17.8647 14.0833 17.6947 14.0487 17.542 13.896L15 11.354V14.938C15 15.424 14.83 15.837 14.49 16.177C14.1493 16.5177 13.736 16.688 13.25 16.688H3.41699Z"
          fill="#CAE3F5"
        />
      </g>
    </svg>
  );
};

export default VideoSmall;
