import React from 'react';
import DiscoverModeNewVariantOfferIcon from '../../icons/DiscoverModeNewVariantOfferIcon';

interface IProps {
  onClick: () => void;
  disableManualClick: boolean;
}

const DashboardDiscoverModeOfferActionOffer: React.FC<IProps> = ({ onClick, disableManualClick }: IProps) => {
  const handleClick = () => {
    if (!disableManualClick) {
      onClick();
    }
  };

  return (
    <div onClick={handleClick}>
      <DiscoverModeNewVariantOfferIcon customStyle={{ cursor: disableManualClick ? 'not-allowed' : 'pointer' }} />
    </div>
  );
};

export default DashboardDiscoverModeOfferActionOffer;
