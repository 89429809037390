import React, { useState, useContext, createContext, ReactElement, Dispatch, SetStateAction, useCallback } from 'react';
import { EVENT_NAMES, MixpanelDefaultStateProps, MIXPANEL_DEFAULT_STATE, MIXPANEL_CREDITS_PURCHASED } from '../constants/mixpanel';

interface UseMixpanelStateProps {
  mixpanelData: MixpanelDefaultStateProps;
  setMixpanelDataHandler: Dispatch<SetStateAction<any>>;
  setMixpanelDataByKeyHandler: Dispatch<SetStateAction<any>>;
}

const MixpanelContext = createContext({});

const MixpanelProvider = (props: any): ReactElement => {
  const [mixpanelData, setMixpanelData] = useState(MIXPANEL_DEFAULT_STATE);

  const setMixpanelDataByKeyHandler = ({ key, payload }) => {
    setMixpanelData(prevState => {
      const updateData = {
        ...prevState,
        [key]: {
          ...prevState[key],
          ...payload,
        },
      };

      if (key === EVENT_NAMES.CREDITS_PURCHASED) {
        const serializedState = JSON.stringify(updateData[EVENT_NAMES.CREDITS_PURCHASED]);
        localStorage.setItem(MIXPANEL_CREDITS_PURCHASED, serializedState);
      }

      return updateData;
    });
  };

  const setMixpanelDataHandler = useCallback(
    () => (newState: any) => {
      setMixpanelData(prevState => {
        return {
          ...prevState,
          ...newState,
        };
      });
    },
    []
  );

  const values: UseMixpanelStateProps = {
    mixpanelData,
    setMixpanelDataHandler,
    setMixpanelDataByKeyHandler,
  };

  return <MixpanelContext.Provider value={values} {...props} />;
};

const useMixpanelState = (): UseMixpanelStateProps => useContext(MixpanelContext) as UseMixpanelStateProps;

export { MixpanelProvider, useMixpanelState };
