import React, { ReactElement, useEffect, useState } from 'react';
import { ToggleV2 } from 'swui';
import styled from '@emotion/styled';
import '../../../style/customSWui.css';
import SvgQuestion from '../../icons/SvgQuestion';
import { getLocalItem } from '../../../common';
import startCase from 'lodash/startCase';
import { handlePaymentVysionEvent } from '../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PaymentViewVysionEventsEnum } from '../../../utils/vysion/payment/view';

const AutoTopupContainer = styled('div')({
  display: 'flex',
});

const AutoTopupDetails = styled('div')({
  maxWidth: 430,
  marginLeft: 0,
});

const AutoTopupSentence = styled('p')({
  fontSize: 13,
  marginBottom: 0,
});

const AutoTopupNote = styled('p')({
  fontSize: 13,
  fontStyle: 'italic',
  marginBottom: 0,
});

const AutoTopupTitle = styled('h5')({
  fontSize: 16,
  fontWeight: 'normal',
  margin: 0,
});

const AutoTopupAction = styled('div')({
  flex: 'auto',
  marginRight: 0,
});

const StyledToggle = styled(ToggleV2)({
  float: 'right',
  width: 40,
  height: 20,
});

const AutoTopupHelp = styled('div')({
  fontSize: 12,
  display: 'inline-block',
  position: 'relative',
  color: '#2490DE',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2490DE',
  },
});

const Popover = styled('div')(
  {
    position: 'absolute',
    margin: 12,
    right: -180,
    width: 350,
    left: 'auto',
    height: 'auto',
    zIndex: 1006,
    border: '1px solid #2b8fd7',
    borderRadius: 4,
    boxSizing: 'border-box',
    boxShadow: '0 1px 1px -1px rgba(0,0,0,.44)',
    display: 'flex',
    alignConent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0,
    color: '#595959',
    padding: 20,
    //top-hanging arrow
    '&:before, &:after': {
      border: 'solid transparent 12px',
      bottom: '100%',
      content: '" "',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      width: 0,
      borderBottomColor: '#2b8fd7',
    },

    '&:after': {
      borderBottomColor: '#fff',
      borderWidth: 12,
      top: -22,
    },
  },
  ({ isDarkMode }) => ({
    background: isDarkMode ? '#777' : '#FFFFFF',
    '&:before': {
      borderBottomColor: isDarkMode ? '#777' : '#2b8fd7',
    },
    '&:after': {
      borderBottomColor: isDarkMode ? '#777' : '#FFFFFF',
    },
  })
);

const PopoverHeader = styled('h4')({
  color: '#BC2028',
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '23px',
  letterSpacing: '-0.36px',
  margin: 0,
  marginBottom: 10,
  textAlign: 'left',
  width: '100%',
});

const PopoverText = styled('p')({
  margin: 0,
  fontSize: 13,
  marginBottom: 10,
  lineHeight: '19px',
  letterSpacing: '-0.13',
});

interface AutoTopupToggleProps {
  selectedPackage: any;
  onAutoTopupChange: any;
  packages: any;
  recurring: boolean;
}

const PopOverOverlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1005,
  backgroundColor: 'rgba(0,0,0,0.65)',
  overflow: 'auto',
});

const AutoTopupToggle = ({
  selectedPackage,
  onAutoTopupChange,
  recurring,
  packages,
}: AutoTopupToggleProps): ReactElement => {
  const [showHelp, setShowHelp] = useState(false);

  const getRecurringPackages = () => {
    const lsBillingPackages = JSON.parse(getLocalItem('wyp_billing_packages')) ?? [];
    const availablePackages = packages ? packages : lsBillingPackages;

    const recurringPackages = availablePackages.filter(
      pack => typeof pack.with_recurring !== 'undefined' && pack.with_recurring === true
    );

    return recurringPackages;
  };

  const [recurringPackages] = useState(getRecurringPackages());

  const renderSelectpackage = () => {
    const isRecurrigPackage = recurringPackages.filter(
      recurringPackage => recurringPackage.identifier === selectedPackage?.identifier
    );

    if (isRecurrigPackage.length > 0) {
      return selectedPackage && startCase(selectedPackage.name.toLowerCase());
    }

    const defaultPackage = recurringPackages.filter(recurringPackage => recurringPackage.name == '100 CREDIT PACKAGE');

    return defaultPackage[0] && defaultPackage[0].name
      ? startCase(defaultPackage[0].name.toLowerCase())
      : '100 Credit Package';
  };

  useEffect(() => {
    if (showHelp) {
      handlePaymentVysionEvent({
        action: VYSION_ACTIONS.VIEW,
        event: PaymentViewVysionEventsEnum.VIEW_PAYMENT_NEVER_MISS_A_CONNECTION,
      });
    }
  }, [showHelp]);

  return (
    <AutoTopupContainer data-test-id="auto-topup-toggle-container">
      <AutoTopupDetails>
        <AutoTopupTitle>
          Auto Credit Top-Up{' '}
          <AutoTopupHelp
            auto-topup-toggle="auto-topup-toggle-help"
            onClick={() => {
              setShowHelp(!showHelp);
            }}
          >
            <SvgQuestion viewBox="0 0 512 500" styles={{ fill: '#2490DE', height: 12, width: 12 }} />
            {showHelp && (
              <div>
                <PopOverOverlay
                  onClick={() => {
                    setShowHelp(false);
                  }}
                />
                <Popover
                  isDarkMode={false}
                  onClick={() => {
                    setShowHelp(false);
                  }}
                >
                  <PopoverHeader>Never miss a connection!</PopoverHeader>
                  <PopoverText>
                    Auto Credit Top-Up ensures you&apos;re never out of the game by automatically refilling your credits
                    when you reach your low credit threshold.
                  </PopoverText>
                  <PopoverText>
                    You can change your top-up credit pack and low-credit threshold in Account Settings.
                  </PopoverText>
                </Popover>
              </div>
            )}
          </AutoTopupHelp>
        </AutoTopupTitle>
        {recurring && (
          <div>
            <AutoTopupSentence data-test-id="auto-topup-toggle-text">
              {renderSelectpackage()} will automatically repurchase when your credit balance gets below 25 credits. You
              can adjust this in Account Settings.
            </AutoTopupSentence>
            <AutoTopupNote data-test-id="auto-topup-toggle-note">
              Please note, you must have a saved payment card on file to enroll in Auto Credit Top-Up.
            </AutoTopupNote>
          </div>
        )}
      </AutoTopupDetails>
      <AutoTopupAction>
        <StyledToggle className="toggleSWUI" id="auto-topup-toggle" checked={recurring} onChange={onAutoTopupChange} />
      </AutoTopupAction>
    </AutoTopupContainer>
  );
};

export default AutoTopupToggle;
