import { STORE_BIOMETRIC_LIST, BiometricState, BiometricActionType } from './types/Biometric.d';

const initialState: BiometricState = {
  biometricsList: [],
  howManyPagesLoaded: 1,
};

const blocked = (state = initialState, action: BiometricActionType): any => {
  switch (action.type) {
    case STORE_BIOMETRIC_LIST:
      return {
        ...state,
        biometricsList: action.payload.data,
      };
    default:
      return state;
  }
};

export default blocked;
