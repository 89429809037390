import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  IconDescription,
  TutorialComponentContainer,
  TutorialHeader,
  TutorialSectionSubheader,
  TutorialSectionWrapper,
} from '../OneTapSwipeStyle';
import SwipeRightIcon from '../icons/SwipeRightIcon';
import SwipeLeftIcon from '../icons/SwipeLeftIcon';
import OfferDeclineIcon from '../icons/OfferDeclineIcon';
import OneTapSwipeTutorialGotItButton from './OneTapSwipeTutorialGotItButton';
import { useApp } from '../../../../../contexts/AppContext';
import OfferAcceptIcon from '../icons/OfferAcceptIcon';

export const TutorialPanel = styled('div')({
  overflow: 'hidden',
  overflowY: 'scroll',
  transition: 'transform 0.5s ease , opacity 0.5s ease',
  opacity: 1,
  paddingLeft: 50,
  paddingRight: 50,
});

interface IProps {
  onConfirm: () => void;
}

const OneTapSwipeOfferTutorial: React.FC<IProps> = ({ onConfirm }: IProps) => {
  const { siteFooterLogoRef } = useApp();
  const [dynamicHeight, setDynamicHeight] = useState('');
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    const siteFooterLogoEl = siteFooterLogoRef?.current;
    if (siteFooterLogoEl) {
      const siteFooterLogoClRec = siteFooterLogoEl.getBoundingClientRect();
      const SITE_HEADER_HEIGHT = 48;

      setDynamicHeight(`${siteFooterLogoClRec.top - SITE_HEADER_HEIGHT}px`);
    }
  }, [siteFooterLogoRef?.current]);

  return (
    <TutorialComponentContainer
      id="oneTapOfferSwipeTutorial"
      style={{ transform: shouldShow ? 'translateY(0%)' : 'translateY(100%)', textAlign: 'center' }}
    >
      <TutorialPanel
        id="panel1"
        style={{
          transform: shouldShow ? 'translateY(0%)' : 'translateY(100%)',
          opacity: 1,
          height: dynamicHeight,
          maxHeight: dynamicHeight,
        }}
      >
        <TutorialSectionWrapper style={{ paddingTop: 50 }}>
          <TutorialHeader>
            Welcome to the new <br />
            Offers page
          </TutorialHeader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper>
          <TutorialSectionSubheader>
            Now you can easily manage all <br /> your incoming Offers with a <br /> swipe or tap!
          </TutorialSectionSubheader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper>
          <SwipeLeftIcon customStyle={{ marginRight: 42 }} />
          <OfferDeclineIcon customStyle={{ position: 'relative', top: -6 }} />
          <IconDescription>Swipe Left or Tap to Decline</IconDescription>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper>
          <SwipeRightIcon customStyle={{ marginRight: 42 }} />
          <OfferAcceptIcon customStyle={{ position: 'relative', top: -2, left: 5 }} />
          <IconDescription>
            Swipe Right or Tap to <br /> Accept the Offer
          </IconDescription>
        </TutorialSectionWrapper>
        <OneTapSwipeTutorialGotItButton
          onConfirm={() => {
            setShouldShow(false);
            onConfirm();
          }}
          customStyle={{ marginBottom: 50 }}
        />
      </TutorialPanel>
    </TutorialComponentContainer>
  );
};

export default OneTapSwipeOfferTutorial;
