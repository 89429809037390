import { mixpanelTrackEventFromBE } from '.';
import { MixpanelTrackEventProps } from './mixpanel';

export const mixpanelTrackEvent = async (props: MixpanelTrackEventProps): Promise<void> => {
  const { eventName, payload } = props;

  await mixpanelTrackEventFromBE({
    event_name: eventName,
    payload: {
      event_properties: payload?.eventProperties ?? {},
      user_properties: payload?.userProperties ?? {},
      incremental_properties: payload?.incrementalProperties ?? {},
    },
  });
};
