import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AvatarWrapper = styled('div')(
  {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 'normal',
  },
  ({ width, shape, customStyle }) => {
    return {
      width: width,
      borderRadius: shape === 'square' ? '4px' : '50%',
      ...customStyle,
    };
  }
);

const AvatarContainer = styled('div')(
  {
    position: 'relative',
    // maintin 1:1 aspect ratio of avatar
    paddingTop: '100%',
  },
  ({ sidebar }) =>
    sidebar && {
      zIndex: -1,
    }
);

const Img = styled('img')(
  {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  ({ isAdmin }) =>
    isAdmin && {
      width: '100%',
      top: 0,
      left: 0,
    }
);

const BackgroundImg = styled('div')(
  {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  ({ src }) => ({
    backgroundImage: `url(${src})`,
  })
);

interface AvatarProps {
  src: any;
  className: any;
  alt: any;
  width: any;
  shape: any;
  customStyle: any;
  sidebar: any;
}

const Avatar = ({ src, className, alt, width, shape, customStyle, sidebar, ...rest }: AvatarProps): ReactElement => {
  const getImageElement = () => {
    if ('object-fit' in document.documentElement.style) {
      return <Img src={src} alt={alt || 'profile'} {...rest} />;
    }
    // IE
    else {
      return <BackgroundImg src={src} />;
    }
  };

  return (
    <AvatarWrapper className={className} width={width} shape={shape} customStyle={customStyle}>
      <AvatarContainer sidebar={sidebar}>{getImageElement()}</AvatarContainer>
    </AvatarWrapper>
  );
};

Avatar.defaultProps = {
  width: '96px',
  customStyle: {},
  shape: 'square',
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  // width of avatar
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // custom styles for avatar container
  customStyle: PropTypes.object,
  shape: PropTypes.oneOf(['square', 'circle']),
};

export default Avatar;
