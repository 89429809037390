import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import { SvgIcon } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { isDarkMode } from '../../utils/helpers';
import colors from '../../theme/colors';
import { dimensions } from '../../style';

const StyledEditButton = styled(Button)(
  {
    fontSize: 14,
    color: '#2B8FD7',
    fontWeight: 600,
    borderRadius: 90,
    border: '1px solid #2B8FD7',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    padding: 12,
    height: 32,
    cursor: 'pointer',
    lineHeight: '17px',
    marginBottom: 10,
    ':hover': {
      backgroundColor: isDarkMode() ? 'transparent' : 'unset',
      color: 'rgb(28,115,177)',
      border: '1px solid rgb(28,115,177)',
    },
    ':focus': {
      backgroundColor: 'inherit',
      color: colors.blue[500],
      border: 'inherit',
    },

    [dimensions.SCREEN_MAX_XS_2]: {
      border: 0,
      paddingRight: 0,
      marginBottom: 0,
      ':hover': {
        border: 'none',
      },
    },
  },
  ({ disabled }) => ({
    ':disabled': { opacity: disabled ? 0.8 : 1, color: colors.blue[500], backgroundColor: 'inherit' },
  })
);

const closeButton = {
  marginRight: 4,
  color: '#2B8FD7',
  cursor: 'pointer',
  height: 16,
  width: 16,
};

interface CancelButtonProps {
  handleClick(): void;
  dataTestId: string;
  customStyle: any;
  disabled?: boolean;
}

const EditButton = ({ handleClick, dataTestId, disabled = false, customStyle }: CancelButtonProps): ReactElement => {
  return (
    <StyledEditButton data-test-id={dataTestId} onClick={handleClick} customStyle={customStyle} disabled={disabled}>
      <SvgIcon component={EditIcon} style={closeButton} />
      Edit
    </StyledEditButton>
  );
};

EditButton.defaultProps = {
  handleCancel: () => null,
  dataTestId: '',
  customStyle: {},
};

export default EditButton;
