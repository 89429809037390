import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import Avatar from '../../../atoms/Avatar';
import { backgroundTheme, dimensions, textType, buttonTheme, textColor } from '../../../style';
import CreditsIcon from '../../../atoms/icons/CreditsIcon';
import { Link, useLocation } from 'react-router-dom';
import { getBrowserDetails } from '../../../utils/helpers';
import { sendPurchaseNavBuyCreditAction } from '../../../actions/purchaseActions';
import useMixPanel from '../../../hooks/useMixPanel';
import { useApp } from '../../../contexts/AppContext';
import BuyCTAIcon from '../../../atoms/icons/BuyCTAIcon';

const NavItemContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
  [dimensions.SCREEN_MAX_MD]: {
    // flex: 'none',
    paddingRight: '16px',
  },
});

const NavItem = styled('div')(
  ({ isMobile }) => ({
    display: 'flex',
    alignItems: isMobile ? 'unset' : 'center',
    paddingTop: isMobile ? 8 : 0,
    cursor: 'pointer',
    color: '#FFF',
  }),
  ({ navHeight, isMobile }) => navHeight && !isMobile && { height: navHeight }
);

const MobileCreditCountContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 8,
});

const BuyButtonCTA = styled('span')({
  padding: '8px 14px',
  borderRadius: 90,
  height: 13,
  width: 43,
  background: '#BC2028',
});

const DotMenuIcon = styled('div')({
  marginLeft: 6,
  marginRight: 6,
  lineHeight: 0,
  [dimensions.SCREEN_MAX_SM]: {
    marginLeft: 20,
  },
});

const StyledAvatar = styled(Avatar)(
  {
    marginRight: 4,
  },

  ({ hideMobile }) =>
    hideMobile && {
      [dimensions.SCREEN_MAX_SM]: {
        display: 'none',
      },
    }
);

const IconContainer = styled('div')({}, ({ marginRight, marginLeft }) => ({
  marginRight: marginRight && '4px',
  marginLeft: marginLeft && '4px',
}));

const UserContainer = styled('div')({
  lineHeight: '18px',
  marginRight: '8px',
  marginLeft: '4px',
  [dimensions.SCREEN_MAX_SM]: {
    display: 'none',
  },
});

const Username = styled('span')({
  display: 'block',
  maxWidth: '110px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...textType.caption,
  fontSize: 13,
});

const Credits = styled('div')(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  ...textType.caption,
  fontSize: 13,
  marginTop: isMobile ? 5 : 0,
  width: !getBrowserDetails().isMobile && 'auto',
  ...textColor.DARKER,
}));

const BuyButtonCTALabel = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 13,
  color: '#FFF',
  lineHeight: '13px',
  letterSpacing: '-0.26px',
  fontWeight: 500,
});

const CreditsText = styled('span')({
  lineHeight: '18px',
  ...textType.caption,
});

// width 100% - 120px
const BuyCreditBUtton = styled(Link)`
  border: none;
  display: flex;
  padding: 1px 7px 2px 7px;
  align-items: center;
  background: ${backgroundTheme.creditColor};
  color: ${buttonTheme.buttonText};
  margin-top: 14px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 21px;
  width: 120px;
  justify-content: center;
  max-width: 180px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    max-width: 125px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 760px) {
    display: none !important;
  }
`;


interface UserNavItemProps {
  clickHandler: any;
  credits: any;
  navHeight: any;
  avatar: any;
  username: any;
  canUpgrade: any;
  isMobile: any;
}

const UserNavItem = ({
  clickHandler,
  credits,
  navHeight,
  avatar,
  username,
  canUpgrade,
  isMobile,
}: UserNavItemProps): any => {
  const buyCredits = useRef(null);
  const { trackPackagesPageVisited } = useMixPanel();
  const { accountHashId } = useApp();
  const location = useLocation();

  useEffect(() => {
    const buyCreditsNode = buyCredits.current;

    if (buyCredits && buyCreditsNode) {
      buyCreditsNode.addEventListener(
        'click',
        e => {
          sendPurchaseNavBuyCreditAction(e);
          trackPackagesPageVisited({ Source: 'Buy Credits Header' });
        },
        false
      );

      return () => {
        buyCreditsNode.removeEventListener('click', () => null, false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountHashId]);

  const isBillingPage = (): boolean => {
    return (
      location.pathname.indexOf('/payment') > -1 ||
      location.pathname.indexOf('/packages') > -1 ||
      location.pathname.indexOf('/billing') > -1
    );
  };

  if (isMobile && (isBillingPage() || !canUpgrade)) {
    return null;
  }

  return (
    <NavItemContainer>
      {canUpgrade && !isMobile ? (
        <BuyCreditBUtton
          ref={buyCredits}
          to="/packages"
          id="btn-buy-credits"
          onClick={() => {
            trackPackagesPageVisited({ Source: 'Buy Credits Header' });
          }}
        >
          Buy Credits
        </BuyCreditBUtton>
      ) : null}
      <NavItem
        isMobile={isMobile}
        data-test-id="submenu-show-hide"
        navHeight={navHeight}
        onClick={() => {
          !isMobile && clickHandler(true);
        }}
      >
        {canUpgrade && isMobile && !isBillingPage() ? (
          <MobileCreditCountContainer>
              <BuyButtonCTA data-test-id="cypress-credit-count-container">
                <Link ref={buyCredits} to="/packages">
                  <BuyButtonCTALabel>
                    <BuyCTAIcon customStyle={{ marginRight: 6 }} />
                    {'Buy'}
                  </BuyButtonCTALabel>
                </Link>
              </BuyButtonCTA>
          </MobileCreditCountContainer>
        ) : (
          isMobile && <div style={{ padding: 25 }} />
        )}

        {!isMobile && <StyledAvatar src={avatar} alt="profile" width={32} hideMobile={canUpgrade} />}
        {!isMobile && (
          <UserContainer>
            <Username>{username}</Username>
            {canUpgrade ? (
              <Credits>
                <IconContainer marginRight>
                  <CreditsIcon />
                </IconContainer>
                <CreditsText data-test-id="header-profile-credits">{credits} credits</CreditsText>
              </Credits>
            ) : null}
          </UserContainer>
        )}
        {!isMobile && (
          <DotMenuIcon data-test-id="header-dot-menu">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="33" viewBox="0 -4 4 33">
              <g fill={backgroundTheme.iconFill} fillRule="evenodd">
                <circle cx="2" cy="0" r="3" />
                <circle cx="2" cy="12" r="3" />
                <circle cx="2" cy="24" r="3" />
              </g>
            </svg>
          </DotMenuIcon>
        )}
      </NavItem>
    </NavItemContainer>
  );
};

export default UserNavItem;
