import {
  ClearNotificationBannersAction,
  NotificationBannerItem,
  RemoveNotificationBannerAction,
  ShowBannerAction,
  clearNotificationBanners,
  removeNotificationBanner,
  showNotificationBanner,
} from '../reducers/notificationBanners';
import store from '../store';
import uuidv4 from 'uuid';

export type NotificationBanner = {
  showNotificationBanner: (
    params: Pick<NotificationBannerItem, 'type' | 'message' | 'hideTimeout'>
  ) => ShowBannerAction;
  removeNotificationBanner: (id: string) => RemoveNotificationBannerAction;
  clearNotificationBanners: () => ClearNotificationBannersAction;
};

const useNotificationBanner = (): NotificationBanner => {
  return {
    showNotificationBanner: ({ message, type, hideTimeout = 3000 }) => {
      return store.dispatch(showNotificationBanner({ message, type, hideTimeout, id: uuidv4() }));
    },
    removeNotificationBanner: (id: string) => {
      return store.dispatch(removeNotificationBanner({ id }));
    },
    clearNotificationBanners: () => store.dispatch(clearNotificationBanners()),
  };
};

export default useNotificationBanner;
