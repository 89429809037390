import React from 'react';
import styled from '@emotion/styled';
import colors from '../../../theme/colors';
import { theme } from '../../../theme';
import { isDarkMode } from '../../../utils/helpers';
import StepNumberIcon from './icons/StepNumberIcon';
import ShareIcon from './icons/ShareIcon';
import { isFD, PWA_ASSETS_URL } from '../../../config/Master';

const InstallScreenContainer = styled('div')({
  marginTop: 15,
});

const InstallTitle = styled('h4')({
  fontSize: 26,
  fontWeight: 700,
  letterSpacing: '-0.52px',
  lineHeight: '31px',
  color: colors[theme].black.primary,
  padding: 0,
  margin: 0,
});
const InstallDescription = styled('p')({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '-0.14px',
  lineHeight: '19px',
  color: colors[theme].black.secondary,
  paddingTop: 15,
  paddingBottom: 15,
  margin: 0,
});

const InstallTutorial = styled('div')({
  borderRadius: 8,
  background: isDarkMode() ? '#424242' : '#DEDEDE',
  padding: 12,
});

const InstallTutorialLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
});

const InstallTutorialLabel = styled('p')({
  padding: 0,
  margin: 0,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '19px',
  color: isDarkMode() ? '#FFF' : '#000',
});

const InstallInstructionImg = styled('img')({
  marginLeft: 30,
});

const PWAInstallIosChrome: React.FC = () => {
  return (
    <InstallScreenContainer>
      <InstallTitle>
        Get the App-like <br />
        Experience Now
      </InstallTitle>
      <InstallDescription>
        {isFD
          ? 'Install the app on your device to get instant notifications for Date Offers and messages, and a faster load time!'
          : 'Install the app on your device to get instant notifications for WYP Date Offers and messages, and a faster load time!'}
      </InstallDescription>
      <InstallTutorial>
        <InstallTutorialLabelContainer>
          <StepNumberIcon number={1} customStyle={{ marginRight: 10 }} />
          <InstallTutorialLabel>Tap the share icon in the address bar -</InstallTutorialLabel>
          <ShareIcon customStyle={{ marginLeft: 6 }} />
        </InstallTutorialLabelContainer>
        <InstallInstructionImg
          src={isFD ? `${PWA_ASSETS_URL}/iosChrome/step1-fd.png` : `${PWA_ASSETS_URL}/iosChrome/step1-wyp.png`}
        />

        <InstallTutorialLabelContainer style={{ marginTop: 14 }}>
          <StepNumberIcon number={2} customStyle={{ marginRight: 10 }} />
          <InstallTutorialLabel>Scroll and select “Add to home screen”</InstallTutorialLabel>
        </InstallTutorialLabelContainer>
        <InstallInstructionImg src={`${PWA_ASSETS_URL}/iosChrome/step2-add-home-screen.png`} />
      </InstallTutorial>
    </InstallScreenContainer>
  );
};

export default PWAInstallIosChrome;
