import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  'Dark Mode Enabled': boolean;
}

export const mixpanelTrackDarkModeEnabled = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Dark Mode Enabled',
    payload: {
      event_properties: eventProperties,
    },
  });
};
