import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import { dimensions, spacing, type, textColor } from '../../style';
import TextLink from '../common/TextLink';

const InputGroupContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    ...spacing.MARGIN_BOTTOM_XLG,

    [dimensions.SCREEN_MIN_MD]: {
      flexDirection: 'row',
      ...spacing.MARGIN_BOTTOM_XLG,
    },

    ':last-child:not(:first-of-type)': {
      marginBottom: 0,
    },
  },
  ({ vertical }) =>
    vertical && {
      display: 'block',
    },
  ({ width }) =>
    width === 'SM' && {
      maxWidth: 480,
    }
);

const InputSpacer = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    flexBasis: '180px',
    ...spacing.MARGIN_RIGHT_MD,
    textAlign: 'right',
  },
});

const Label = styled('label')(
  {
    flexBasis: '22px',
    ...spacing.MARGIN_RIGHT_MD,
    ...spacing.MARGIN_TOP_SM,
    ...spacing.MARGIN_BOTTOM_SM,
    ...type.BOLD,

    [dimensions.SCREEN_MIN_MD]: {
      flexBasis: '180px',
      textAlign: 'right',
    },
  },
  ({ vertical }) =>
    vertical && {
      display: 'inline-block',
      marginTop: '0',
    }
);

const InputControl = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    flex: '0 0 400px',
  },
});

const Description = styled('span')({
  margin: '0 0 0 -5px',
  padding: 0,
  fontWeight: 'normal',
  fontSize: 12,
});

const ErrorDescription = styled('div')({
  ...spacing.MARGIN_TOP_XS,
  ...textColor.RED,
});

const InputGroup = (props: any): ReactElement => {
  const { customLabelStyle } = props;
  let errorText = null;
  if (props.errors) {
    errorText =
      props.errors[0] === 'This email is already in use.' ? (
        <div>
          {props.errors} Please <TextLink to="/logout">log in</TextLink> or{' '}
          <TextLink to="/restoring">reset your password</TextLink>.
        </div>
      ) : (
        <ErrorDescription>{props.errors}</ErrorDescription>
      );
  }

  return (
    <React.Fragment>
      <InputGroupContainer ref={props.eleRef} vertical={props.vertical} width={props.width}>
        {!props.vertical && !props.inputLabel && <InputSpacer />}
        {props.inputLabel && (
          <span>
            <Label style={customLabelStyle} vertical={props.vertical}>
              {props.inputLabel}
              {props.labelIcon}
            </Label>
            {props.rightText !== undefined && props.rightText !== null && props.rightText !== '' && (
              <Description>{props.rightText}</Description>
            )}
          </span>
        )}
        <InputControl>
          {props.children}
          {errorText}
        </InputControl>
      </InputGroupContainer>
    </React.Fragment>
  );
};

export default InputGroup;
