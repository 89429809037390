import React, { Dispatch, ReactElement, SetStateAction, CSSProperties, useState, useEffect } from 'react';
import Button from '../../../atoms/buttons/Button';
import RoundCheckIcon from '../../../atoms/icons/RoundCheckIcon';
import { PHONE_VERIFICATION_TYPES } from '../../../constants/phone';
import { IsMobileViewPort } from '../../../utils/helpers';
import { FormWrapper, Heading, Description, ButtonAction } from './styledElements';
import usePhoneActionModal from '../../../hooks/usePhoneActionModal';
import { handlePhoneSettingsVysionEvent } from '../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PhoneSettingsViewVysionEventsEnum } from '../../../utils/vysion/phoneSettings/view';
import { PhoneSettingsClickVysionEventsEnum } from '../../../utils/vysion/phoneSettings/click';

interface IVerifyAccountOwnerShipProps {
  nextStep: () => void;
  handleCurrenStepVerification: Dispatch<SetStateAction<any>>;
  currentPhone: string;
  customStyle?: CSSProperties;
  onCancel?: () => void;
}

const VerifyAccountOwnerShip = ({
  nextStep,
  handleCurrenStepVerification,
  currentPhone,
  customStyle,
  onCancel,
}: IVerifyAccountOwnerShipProps): ReactElement => {
  const {
    utils: { requestResendOtp },
  } = usePhoneActionModal();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUsePassword = () => {
    nextStep();
    handleCurrenStepVerification(PHONE_VERIFICATION_TYPES.PASSWORD);
    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_VERIFY_USE_PASSWORD,
    });
  };
  const handleSendCode = () => {
    setIsProcessing(true);

    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PhoneSettingsClickVysionEventsEnum.CLICK_SEND_CODE,
    });

    requestResendOtp({
      isLogin: false,
      phoneNumber: currentPhone,
      showSuccessModal: false,
      onResendSuccess: () => {
        nextStep();
        handleCurrenStepVerification(PHONE_VERIFICATION_TYPES.OTP);
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.VIEW,
          event: PhoneSettingsViewVysionEventsEnum.VIEW_CURRENT_NUMBER_VERIFICATION,
        });
        setIsProcessing(true);
      },
      onResendFailed: onCancel,
    });
  };

  useEffect(() => {
    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.VIEW,
      event: PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_VERIFY_ACCOUNT,
    });
  }, []);

  return (
    <FormWrapper style={customStyle}>
      {!IsMobileViewPort && <RoundCheckIcon />}
      <Heading>Verify Account Ownership</Heading>
      <Description>Please select a method to verify ownership of the account.</Description>
      <Description>
        <strong>Send Code</strong> will send a verification code to the current phone number on file.
      </Description>
      <ButtonAction>
        <Button
          data-test-id="phone-settings-button"
          buttonType="primary"
          onClick={handleUsePassword}
          type={'button'}
          size="small"
          customStyle={{ width: '100%', marginRight: 15 }}
        >
          Use Password
        </Button>
        <Button
          data-test-id="phone-settings-button"
          buttonType="primary"
          onClick={handleSendCode}
          disabled={isProcessing}
          type={'button'}
          size="small"
          customStyle={{ width: '100%', marginLeft: 15 }}
        >
          {isProcessing ? 'Sending Code...' : 'Send Code'}
        </Button>
      </ButtonAction>
    </FormWrapper>
  );
};

export default VerifyAccountOwnerShip;
