import React, { ReactElement } from 'react';
interface ICircleIconProps {
  fill: string;
  customStyle?: any;
}

const CircleIcon = ({ fill = '#2B8FD7', customStyle }: ICircleIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none" style={customStyle}>
      <circle cx="3" cy="2.5" r="2.5" fill={fill} />
    </svg>
  );
};

export default CircleIcon;
