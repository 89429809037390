import React, { Component } from 'react';

import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails } from '../../../utils/helpers';

class UserActions extends Component {
  render(): any {
    const isMobile = getBrowserDetails().isMobile;
    return (
      <ButtonSet align={!isMobile ? '' : 'center'}>
        <Button to={`/profile/${this.props.hashId}`} data-test-id="onboarding-summary-edit-profile">
          Edit Profile
        </Button>
        <Button buttonType={'primary'} to="/privacy-updated" data-test-id="onboarding-summary-find-a-date">
          Find A Date
        </Button>
      </ButtonSet>
    );
  }
}

export default UserActions;
