import * as sdk from '../sdk';
import { storeMeta } from './metaActions';
import { storeErrors } from './commonActions';
import { refreshAccount } from './profileActions';
import { displayFailMessage, displayWarnMessage } from './notificationActions';
import { toaster } from '../common';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import { storeFavOfferChange } from './favActions';
import { NavigateFunction } from 'react-router-dom';
import { decrementUnreadOneTapOffersCount, incrementUnreadOneTapOffersCount } from './unreadOneTapOffersCountActions';
import { getFeatureDetails } from '../constants/evidently';
import { ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME } from '../utils/evidentlyFeatures/oneTapOfferManagement';
import { removeDiscoverModeAccountsByHashId } from './discoverModeActions';

export const clearOffers = (): any => {
  return {
    type: 'CLEAR_OFFERS',
  };
};

export const storeOffers = (payload: any, section: any, firstCall: boolean, isRealtimeEvent: boolean): any => {
  return {
    type: 'STORE_OFFERS',
    payload,
    section,
    firstCall,
    isRealtimeEvent,
  };
};

export const storeCounter = (payload: any): any => {
  return {
    type: 'STORE_COUNTER',
    payload,
  };
};

export const requestOffers = (): any => {
  return {
    type: 'REQUEST_OFFERS',
  };
};

export const removeLockFromMessage = (payload: any): any => {
  return {
    type: 'REMOVE_LOCK_FROM_MESSAGE',
    payload,
  };
};

export const storeSingleOffer = (payload: any): any => {
  return {
    type: 'STORE_OFFER_IN_PROFILE',
    payload,
  };
};

export const removeSingleOffer = (): any => {
  return {
    type: 'REMOVE_SINGLE_OFFER',
  };
};

export const updateOfferList = (payload: any): any => {
  return {
    type: 'UPDATE_OFFER_LIST',
    payload,
  };
};

export const removeAllOffers = (): any => {
  return dispatch => {
    dispatch(clearOffers());
  };
};

export const resetNewOffers = (): any => {
  return {
    type: 'RESET_NEW_OFFERS',
  };
};

export const terminateFetching = (): any => {
  return {
    type: 'TERMINATE_FETCHING',
  };
};

export const refreshOffers = (
  offerType: string,
  order = 'recent',
  firstCall = true,
  per_page = 0,
  isRealtimeEvent = false
): any => {
  return (dispatch: any, getState: any) => {
    let pageToGet = 0;
    dispatch(requestOffers());

    if (!firstCall) {
      pageToGet = getState().meta[`offer_${offerType}_current_page`] + 1;
    }

    if (
      firstCall &&
      !isRealtimeEvent &&
      offerType === 'new' &&
      getState().offers['new'] &&
      getState().offers['new'].length > 0 &&
      getState().offers.offerPreFetchExpiration > new Date().getTime()
    ) {
      dispatch(terminateFetching());
      return;
    }

    return sdk
      .getOffers(getState().auth.jwToken, offerType, order, per_page, pageToGet)
      .then(response => {
        if (firstCall) {
          dispatch(clearOffers());
        }

        dispatch(storeOffers(response.data.data, offerType, firstCall, isRealtimeEvent));
        // store pagination
        const page = response.data.meta.pagination;

        const offersView = localStorage.getItem('offers-view') as string;

        // increment when there is a new offer notification from pusher
        // and user is in the 1 tap variant page
        if (isRealtimeEvent && (!offersView || offersView === 'grid')) {
          dispatch(incrementUnreadOneTapOffersCount());
        }

        dispatch(
          storeMeta({
            [`offer_${offerType}_current_page`]: page.current_page,
            [`offer_${offerType}_total_pages`]: page.total_pages,
            [`offer_${offerType}_is_realtime_event`]: isRealtimeEvent,
          })
        );
      })
      .catch(error => error);
  };
};

export const sendSuggestion = (
  username: any,
  isFromFavorites: boolean,
  favKey: string | null,
  favSection: any,
  showToastMessage = true
): any => {
  return (dispatch, getState) => {
    return sdk
      .sendSuggestion(getState().auth.jwToken, username)
      .then(response => {
        dispatch(storeSingleOffer(response.data.data));
        dispatch(refreshAccount());

        if (isFromFavorites) {
          dispatch(storeFavOfferChange(response.data, favSection, favKey));
        }

        if (showToastMessage) {
          toaster.success('Date request sent!');
        }
      })
      .catch(error => {
        if (
          error.response.data !== undefined &&
          error.response.data.errors[0] !== undefined &&
          error.response.data.errors[0].source !== undefined &&
          error.response.data.errors[0].source[0] === 'offer'
        ) {
          return dispatch(
            displayFailMessage({
              info: error.response.data.errors[0].detail,
            })
          );
        }

        dispatch(
          displayWarnMessage({
            info: 'Request not sent, because you have too many open offers',
            linkInfo: 'Go to your offers.',
            url: 'offers/sent',
          })
        );
      });
  };
};

export const createNewOffer = (
  formData: any,
  isFromFavorites = false,
  favKey = null,
  favSection = null,
  isFromOffers = false
): any => {
  // calls sdk for new offer.
  return (dispatch, getState) => {
    return sdk
      .createNewOffer(getState().auth.jwToken, formData)
      .then(response => {
        dispatch(storeSingleOffer(response.data.data));
        dispatch(refreshAccount());

        if (isFromFavorites) {
          dispatch(storeFavOfferChange(response.data, favSection, favKey));
        }

        removeDiscoverModeAccountsByHashId([formData.account]);

        if (isFromOffers) {
          const offerList = getState().offers['new'];
          remove(offerList, {
            hash_id: formData.account,
          });

          dispatch(removeSingleOffer());
          dispatch(
            updateOfferList({
              offerType: 'new',
              offerList,
            })
          );
        }
      })
      .catch(error => {
        error?.response?.data && dispatch(storeErrors(error.response.data));
        if (typeof error?.response?.data !== 'undefined' && typeof error?.response?.data?.errors !== 'undefined') {
          if (
            error?.response?.data !== undefined &&
            error?.response?.data?.errors[0] !== undefined &&
            error?.response?.data?.errors[0].source !== undefined &&
            error?.response?.data?.errors[0].source[0] === 'offer'
          ) {
            return dispatch(
              displayFailMessage({
                info: error.response.data.errors[0].detail,
              })
            );
          }
        }

        return Promise.reject(error.response.data);
      });
  };
};

export const counterOffer = (
  formData: any,
  fromDashboard: any,
  navigate: undefined | NavigateFunction,
  favKey = null,
  favSection = null,
  isFromOffers = false
): any => {
  // calls sdk for counter offer.
  return (dispatch, getState) => {
    return sdk
      .counterOffer(getState().auth.jwToken, formData)
      .then(response => {
        if (!fromDashboard && !isFromOffers && typeof navigate !== 'undefined') {
          navigate(`/profile/${formData.account}`);
        }

        dispatch(storeSingleOffer(response.data.data));

        if (fromDashboard) {
          dispatch(refreshOffers('highlights', 'recent', true, 1));
          dispatch(storeFavOfferChange(response && response.data, favSection, favKey));
        }

        if (isFromOffers) {
          const offerList = getState().offers['new'];
          remove(offerList, {
            hash_id: formData.account,
          });

          dispatch(decrementUnreadOneTapOffersCount());
          dispatch(removeSingleOffer());
          dispatch(
            updateOfferList({
              offerType: 'new',
              offerList,
            })
          );
        }

        dispatch(refreshAccount());
      })
      .catch(error => {
        // offer not okay
        dispatch(storeErrors(error.response.data));
        return Promise.reject(error.response.data);
      });
  };
};

export const acceptOffer = (
  hashId: string | null,
  message: any,
  isFromFavorites = false,
  favKey = null,
  favSection = null,
  isFromOffers = false,
  source = '',
  navigate: NavigateFunction
): any => {
  return (dispatch: any, getState: any) => {
    const shouldShowToast = () => {
      const pathName = window.location.pathname.split('?')[0]; // Remove query parameters
      const offersView = localStorage.getItem('offers-view') as string;

      if (pathName !== '/offers/new' || offersView === 'list') {
        return true;
      }

      const oneTapSwipeEvidentlyDetails = getFeatureDetails(
        getState().settings.evidently,
        ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME
      );

      return oneTapSwipeEvidentlyDetails?.variation !== '1-TapOfferManagement';
    };

    const memberType = getState().profile.profile.data.account_type;
    const offerList = getState().offers['new'];
    remove(offerList, {
      hash_id: hashId,
    });

    return sdk
      .acceptOffer(getState().auth.jwToken, hashId, message, source)
      .then(response => {
        if (isFromFavorites) {
          dispatch(storeFavOfferChange(response.data, favSection, favKey));
          return response.data.data;
        } else if (isFromOffers) {
          dispatch(decrementUnreadOneTapOffersCount());
          dispatch(removeSingleOffer());
          dispatch(
            updateOfferList({
              offerType: 'new',
              offerList,
            })
          );

          dispatch(refreshAccount());

          if (memberType === 'Attractive' && shouldShowToast()) {
            toaster.success(`Offer accepted`);
          }

          return response.data.data;
        } else {
          // offer accepted, redirect user to mail.
          navigate(`/mail/${hashId}`);
          // offer accepted.
          toaster.success(`${memberType === 'Generous' ? 'Request' : 'Offer'} accepted`);
        }
      })
      .catch(error => error);
  };
};

export const acceptOfferHighlight = (hashId: any, message: any): any => {
  return (dispatch, getState) => {
    const memberType = getState().profile.profile.data.account_type;
    return sdk
      .acceptOffer(getState().auth.jwToken, hashId, message, 'Modal')
      .then(() => {
        dispatch(refreshOffers('highlights', 'recent', true, 1));
        // offer accepted.
        toaster.success(`${memberType === 'Generous' ? 'Request' : 'Offer'} accepted`);
      })
      .catch(error => error);
  };
};

export const ignoreOfferFromList = (
  offerObject: any,
  redirectTo: any,
  offerType: any,
  navigate: NavigateFunction
): any => {
  return (dispatch, getState) => {
    const offerList = getState().offers[offerType];

    remove(offerList, {
      id: offerObject.id,
    });

    return sdk
      .ignoreOffer(getState().auth.jwToken, offerObject.other_account.data.hash_id)
      .then(() => {
        // offer ignored.
        dispatch(decrementUnreadOneTapOffersCount());
        dispatch(removeSingleOffer());
        dispatch(
          updateOfferList({
            offerType: 'sent',
            offerList,
          })
        );
        dispatch(refreshAccount());
        navigate(redirectTo);
      })
      .catch(error => error);
  };
};

export const ignoreOffer = (
  username: string | null,
  isOfferHighlight = false,
  isFromFavorites = false,
  favKey = null,
  favSection = null
): any => {
  return (dispatch: any, getState: any) => {
    const offerList = getState().offers['new'];

    remove(offerList, {
      hash_id: username,
    });

    return sdk
      .ignoreOffer(getState().auth.jwToken, username)
      .then(response => {
        isOfferHighlight && dispatch(refreshOffers('highlights', 'recent', true, 1));

        if (isFromFavorites) {
          dispatch(storeFavOfferChange(response.data, favSection, favKey));
        }

        // offer ignored.
        dispatch(decrementUnreadOneTapOffersCount());
        dispatch(removeSingleOffer());
        dispatch(
          updateOfferList({
            offerType: 'new',
            offerList,
          })
        );
        dispatch(refreshAccount());
      })
      .catch(error => error);
  };
};

export const unlockOffer = (hashID: any, messageInStoreId: any, triggerToBuy: boolean): any => {
  return (dispatch, getState) => {
    return sdk
      .unlockOffer(getState().auth.jwToken, hashID, triggerToBuy)
      .then(() => {
        // offer ignored.
        toaster.success('Conversation unlocked');
        dispatch(refreshAccount());

        // unlock message if on mail and has messageId saved in store
        if (messageInStoreId) {
          dispatch(removeLockFromMessage(messageInStoreId));
        }
      })
      .catch(error => error);
  };
};

export const sendMessage = (username: string, navigate: NavigateFunction): any => {
  return () => {
    return navigate(`/mail/${username}`);
  };
};

export const updateOffer = (formData: any): any => {
  // calls sdk for new offer.
  return (dispatch: any, getState: any) => {
    return sdk
      .updateOffer(formData)
      .then(response => {
        const updateoffer = response.data.data;

        const currentOfferList = getState().offers['sent'];
        const offerList = currentOfferList.map(item => {
          return item.to_account_hash_id === formData.account ? { ...item, ...updateoffer } : item;
        });

        // move updateOffer to first index
        const sortedItems = sortBy(offerList, ({ to_account_hash_id }) =>
          to_account_hash_id === formData.account ? 0 : 1
        );

        dispatch(storeSingleOffer(updateoffer));

        dispatch(
          updateOfferList({
            offerType: 'sent',
            offerList: sortedItems,
          })
        );
        dispatch(refreshAccount());
        return updateoffer;
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));

        if (
          error.response.data !== undefined &&
          error.response.data.errors[0] !== undefined &&
          error.response.data.errors[0].source !== undefined &&
          error.response.data.errors[0].source[0] === 'offer'
        ) {
          return dispatch(
            displayFailMessage({
              info: error.response.data.errors[0].detail,
            })
          );
        }

        return Promise.reject(error.response.data);
      });
  };
};

export const postQuickOffer = (formData: { accounts: string[]; price: number }): any => {
  // calls sdk for new offer.
  return dispatch => {
    return sdk
      .quickOffer(formData)
      .then(() => {
        dispatch(refreshAccount());
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
        if (typeof error?.response?.data !== 'undefined' && typeof error?.response?.data?.errors !== 'undefined') {
          if (
            error?.response?.data !== undefined &&
            error?.response?.data?.errors[0] !== undefined &&
            error?.response?.data?.errors[0].source !== undefined &&
            error?.response?.data?.errors[0].source[0] === 'offer'
          ) {
            return dispatch(
              displayFailMessage({
                info: error.response.data.errors[0].detail,
              })
            );
          }
        }

        return Promise.reject(error.response.data);
      });
  };
};
