import React, { FC } from 'react';
import styled from '@emotion/styled';

import { textColor } from '../../style';
import Button from '../../atoms/buttons/Button';
import GalleryModal from '../../modules/modals/GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import IconExclamationPointCircle from '../../atoms/icons/IconExclamationPointCircle';
import colors from '../../theme/colors';
import { MAX_NUMBER_PHOTOS } from '../../config/Master';

const ModalHeader = styled('h3')(() => ({
  marginTop: 10,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '31px',
  textAlign: 'left',
  paddingRight: 30,
  marginBottom: 0,
  ...textColor.DARKEST,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: '20px',
    marginTop: 20,
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }: { hasHeader: boolean }) => !hasHeader && { marginTop: 0 }
);

interface TooManyFilesWarningModalProps {
  onClose(): void;
  isOpen: boolean;
  alwaysDisabledBodyScroll?: boolean;
}

const TooManyFilesWarningModal: FC<TooManyFilesWarningModalProps> = ({
  onClose,
  isOpen,
  alwaysDisabledBodyScroll = false,
}) => {
  useDisableBodyScroll(isOpen || alwaysDisabledBodyScroll);

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{
        overlay: {
          zIndex: 10001,
        },
        content: {
          borderColor: '#DD0B24',
          backgroundColor: '#FFF8F8',
          padding: 20,
          maxWidth: 335,
          left: 0,
          right: 0,
        },
      }}
      contentLabel=""
      isOpen={isOpen}
    >
      <ModalDescription hasHeader={true} data-test-id="unsupported-file-type">
        <IconExclamationPointCircle
          customStyle={{
            width: 40,
            height: 40,
          }}
          fill={colors.dark.notifications.yellow}
        />
        <ModalHeader>Uh oh! Too many files</ModalHeader>
        <p>Your profile can only have {MAX_NUMBER_PHOTOS} pics and videos in total.</p>
        <p>Please go through your upload and remove extra files.</p>

        <div style={{ marginTop: 5 }} data-test-id="always-show-agreement"></div>
        <Button
          data-test-id={`upload-another`}
          onClick={onClose}
          customStyle={{
            borderColor: '#2B8FD7',
            backgroundColor: '#2B8FD7',
            color: '#FFFFFF',
            fontWeight: 600,
            width: 'fit-content',
            padding: '12px 22px',
            margin: '0 auto',
          }}
        >
          OK
        </Button>
      </ModalDescription>
    </GalleryModal>
  );
};

const MemoizedTooManyFilesWarningModal = React.memo(TooManyFilesWarningModal);

export default MemoizedTooManyFilesWarningModal;
