import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setOfferVysion = (param: any): void => {
  switch (param.event) {
    case 'VISIT CARD ACTIVITY':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-activityFeed-activityCard');
      break;
    case 'SHOW MORE ACTIVITY':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-activityFeed-showMore');
      break;
    case 'NAVBAR SEARCH':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-navBar-search');
      break;
    case 'NAVBAR INTERESTS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-navBar-interests');
      break;
    case 'NAVBAR MESSAGES':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-navBar-messages');
      break;
    case 'NAVBAR OFFERS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-navBar-offers');
      break;
    case 'NAVBAR FAVOURITES':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'dashboard-navBar-favorites');
      break;
    case 'TOAST MESSAGE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'toast-messagedYou');
      break;
    case 'TOAST FAVOURITE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'toast-favoritedYou');
      break;
    case 'TOAST OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'toast-offeredYou');
      break;
    case 'MAKE OFFER PROFILE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-makeOffer');
      break;
    case 'CANCEL OFFER PROFILE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-cancelOffer');
      break;
    case 'IGNORE OFFER PROFILE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-ignoreOffer');
      break;
    case 'COUNTER OFFER PROFILE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-counterOffer');
      break;
    case 'ACCEPT OFFER PROFILE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-acceptOffer');
      break;
    case 'PROFILE PHOTO':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-profilePhoto');
      break;
    case 'OTHER PROFILE PHOTO':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-otherPhoto');
      break;
    case 'PRIVATE PHOTO':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'profile-privatePhotoPlaceholder');
      break;
    case 'IGNORE OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offers-receivedOffers-ignoreOffer');
      break;
    case 'COUNTER OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offers-receivedOffers-counterOffer');
      break;
    case 'ACCEPT OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offers-receivedOffers-acceptOffer');
      break;
    case 'CANCEL OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offers-sentOffers-cancelOffer');
      break;
    case 'DISPLAY MODAL OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal');
      break;
    case 'SEND OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-sendOffer');
      break;
    case 'REVISE OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-reviseOffer');
      break;
    case 'DECLINE OFFER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-declineOffer');
      break;
    case 'SEND CANCEL':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-cancel');
      break;
    case 'SEND CLOSE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-close');
      break;
    case 'SEND SUGGEST':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-suggest');
      break;
    case 'SEND AMOUNT CUSTOM':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-customAmount');
      break;
    case 'SEND AMOUNT 50':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-50');
      break;
    case 'SEND AMOUNT 75':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-75');
      break;
    case 'SEND AMOUNT 100':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-100');
      break;
    case 'SEND AMOUNT 150':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-150');
      break;
    case 'SEND AMOUNT 200':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-200');
      break;
    case 'UNLOCK MESSAGE':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'offerModal-unlockMessage');
      break;
    default:
      return;
  }
};

export { setOfferVysion };
