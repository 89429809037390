import React from 'react';
import GalleryUploadRemoveIcon from '../icons/GalleryUploadRemoveIcon';
import { black, white } from '../../../../theme/colors';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FileType } from '../../constants';
import { VideoInProgress, MediaPlayWrap } from '../../../../components/pages/Profile/Gallery/GalleryStyles';
import MediaPlayIcon from '../../../../atoms/icons/MediaPlayIcon';
import VideoGear from '../../../../icons/VIdeoGear';
import IconMakePublic from '../../../../atoms/icons/IconMakePublic';
import IconMakePrivate from '../../../../atoms/icons/IconMalePrivate';
import VisibilityIcon from '../../../../atoms/icons/VisibilityIcon';
import { StyledMediaItemContainer } from '../styles';

const photoContainerStyles = ({ src }: { src: string }) => {
  return css`
    background-image: url(${src});
    width: 100%;
    height: 100%;
    background-position: center top;
    background-size: cover;
    border-radius: 10px;
  `;
};

const PrivateEyeContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  height: 80,
  width: 80,
  zIndex: 1,
  borderTopLeftRadius: 5,
});

const PrivateNotch = styled('div')({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: 'rgb(188, 32, 40, 0.9)',
  transform: 'rotate(45deg)',
  width: 80,
  height: 80,
  top: -40,
  left: -40,
});

const PhotoContainer = styled.div`
  ${photoContainerStyles}
`;

interface IGalleryFileUploaderMediaCardProps {
  file: FileType;
  isLoading: boolean;
  onToggleVisibility: () => void;
  onRemove: () => void;
  onPlay: () => void;
}

const RemovePhoto = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick} style={{ position: 'absolute', bottom: 15, right: 17.5, cursor: 'pointer' }}>
      <GalleryUploadRemoveIcon />
    </div>
  );
};

const VisiblityIcons = ({ onClick, isPrivate }: { onClick: () => void; isPrivate: boolean }) => {
  return (
    <div onClick={onClick} style={{ position: 'absolute', top: 17.5, right: 17.5, cursor: 'pointer' }}>
      {isPrivate ? (
        <IconMakePublic fillColor={black} iconColor={white} customStyle={{ height: 24, width: 24 }} />
      ) : (
        <IconMakePrivate fillColor={black} iconColor={white} customStyle={{ height: 24, width: 24 }} />
      )}
    </div>
  );
};

const GalleryVideoUploaderMediaCard: React.FC<IGalleryFileUploaderMediaCardProps> = (
  props: IGalleryFileUploaderMediaCardProps
) => {
  const { file, onToggleVisibility, onRemove, isLoading, onPlay } = props;

  return (
    <StyledMediaItemContainer>
      {file.private && (
        <PrivateEyeContainer>
          <PrivateNotch />
          <VisibilityIcon customStyle={{ position: 'absolute', top: 0, left: 0, zIndex: 3, padding: 8 }} />
        </PrivateEyeContainer>
      )}
      <PhotoContainer src={file.temp_thumbnail ?? ''}>
        {isLoading ? (
          <VideoInProgress style={{ position: 'relative' }}>
            <VideoGear
              customStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
            />
          </VideoInProgress>
        ) : (
          <>
            <VisiblityIcons onClick={onToggleVisibility} isPrivate={file.private} />
            <RemovePhoto onClick={onRemove} />
            <MediaPlayWrap>
              <MediaPlayIcon onClick={onPlay} customWrapperStyle={{ cursor: 'pointer' }} />
            </MediaPlayWrap>
          </>
        )}
      </PhotoContainer>
    </StyledMediaItemContainer>
  );
};

export default GalleryVideoUploaderMediaCard;
