import React, { useState, useContext, createContext, ReactElement, Dispatch, SetStateAction, useRef } from 'react';
import { VIDEO_PREVIEW_DEFAULT, VideoPreviewProps } from '../constants/dashboardDiscoverMode';

interface UseDashboardDiscoverModeProps {
  videoPreview: VideoPreviewProps;
  setVideoPreview: Dispatch<SetStateAction<VideoPreviewProps>>;
  offerActionContainerRef: any;
  photoAspectRatioRef: any;
  discoverActiveAccount: any;
  setDiscoverActiveAccount: Dispatch<SetStateAction<any>>;
}

const DashboardDiscoverContext = createContext({});

const DashboardDiscoveModeProvider = (props: any): ReactElement => {
  const [videoPreview, setVideoPreview] = useState(VIDEO_PREVIEW_DEFAULT);
  const offerActionContainerRef = useRef(null);
  const photoAspectRatioRef = useRef(null);
  const [discoverActiveAccount, setDiscoverActiveAccount] = useState(null);

  const values: UseDashboardDiscoverModeProps = {
    videoPreview,
    setVideoPreview,
    offerActionContainerRef,
    photoAspectRatioRef,
    discoverActiveAccount,
    setDiscoverActiveAccount,
  };

  return <DashboardDiscoverContext.Provider value={values} {...props} />;
};

const useDashboardDiscoverMode = (): UseDashboardDiscoverModeProps =>
  useContext(DashboardDiscoverContext) as UseDashboardDiscoverModeProps;

export { DashboardDiscoveModeProvider, useDashboardDiscoverMode };
