import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Buttons = styled('div')(({ align }) => {
  if (align === 'left') {
    return {
      button: {
        marginRight: '8px',
      },
    };
  }

  if (align === 'right') {
    return {
      'button:': {
        marginLeft: '8px',
      },
    };
  }
});

const ButtonGroup = (props: any): ReactElement => {
  const { align } = props;
  return <Buttons align={align}>{props.children}</Buttons>;
};

ButtonGroup.defaultProps = {
  align: 'left',
};

ButtonGroup.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
};

export default ButtonGroup;
