import {
  NOTIFICATION_BANNER_DISPLAY_SUCCESS,
  NOTIFICATION_BANNER_DISPLAY_WARN,
  NOTIFICATION_BANNER_DISPLAY_FAIL,
  NOTIFICATION_BANNER_CLEAR,
  NotificationActionType,
} from './types/Notification.d';

const notification = (state = null, action: NotificationActionType): string => {
  switch (action.type) {
    case NOTIFICATION_BANNER_DISPLAY_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.message,
      };
    case NOTIFICATION_BANNER_DISPLAY_WARN:
      return {
        ...state,
        status: 'warn',
        message: action.message,
      };
    case NOTIFICATION_BANNER_DISPLAY_FAIL:
      return {
        ...state,
        status: 'fail',
        message: action.message,
        isPersist: action.isPersist,
      };
    case NOTIFICATION_BANNER_CLEAR:
      return null;
    default:
      return state;
  }
};

export default notification;
