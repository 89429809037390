export const UPDATE_USER_SOCIAL = 'UPDATE_USER_SOCIAL';
export const STORE_SETTINGS = 'STORE_SETTINGS';
export const STORE_VIDEO_SETTINGS = 'STORE_VIDEO_SETTINGS';
export const STORE_VIDEO_INFO = 'STORE_VIDEO_INFO';
export const CLEAR_VIDEO_INFO = 'CLEAR_VIDEO_INFO';
export const STORE_ICE_SERVERS = 'STORE_ICE_SERVERS';
export const STORE_GDPR_USER_INFO = 'STORE_GDPR_USER_INFO';
export const INVERT_SETTING = 'INVERT_SETTING';
export const INVERT_VIDEO_SETTING = 'INVERT_VIDEO_SETTING';
export const SETTINGS_TOGGLE_DESKTOP_NOTIFICATION = 'SETTINGS_TOGGLE_DESKTOP_NOTIFICATION';
export const SETTINGS_EVIDENTLY = 'SETTINGS_EVIDENTLY';
export const SETTINGS_RECURRING_PACKAGES = 'SETTINGS_RECURRING_PACKAGES';
export const STORE_SETTINGS_RECURRING = 'STORE_SETTINGS_RECURRING';
export const REMOVE_SETTINGS_RECURRING = 'REMOVE_SETTINGS_RECURRING';
export const SET_UPDATING_EMAIL_AND_PASSWORD = 'SET_UPDATING_EMAIL_AND_PASSWORD';

export interface Data {
  data: Record<string, unknown>;
}

export interface Social {
  social: {
    data: Data;
  };
}

export interface Payload {
  payload: Record<string, unknown>;
  status?: string;
  is_eu_user?: boolean;
}

export interface VideoCall {
  video_call: unknown[];
}

export interface VideoInfo {
  video_info: unknown[];
}

export interface SettingsState {
  social: unknown[];
  desktopNotificationStatus: boolean;
  video_call?: Record<number, unknown>;
  evidently: {
    [key: string]: unknown;
  };
  email_and_password: {
    isUpdating: boolean;
  };
  default_offer_amount: number | null;
  isEuUser?: boolean;
}

interface UPDATE_USER_SOCIAL_ACTION {
  type: typeof UPDATE_USER_SOCIAL;
  payload: Payload;
}

interface STORE_SETTINGS_ACTION {
  type: typeof STORE_SETTINGS;
  payload: Payload;
}

interface STORE_VIDEO_SETTINGS_ACTION {
  type: typeof STORE_VIDEO_SETTINGS;
  payload: Payload;
}

interface STORE_VIDEO_INFO_ACTION {
  type: typeof STORE_VIDEO_INFO;
  payload: Payload;
}

interface CLEAR_VIDEO_INFO_ACTION {
  type: typeof CLEAR_VIDEO_INFO;
}

interface STORE_ICE_SERVERS_ACTION {
  type: typeof STORE_ICE_SERVERS;
  payload: Payload;
}

interface STORE_GDPR_USER_INFO_ACTION {
  type: typeof STORE_GDPR_USER_INFO;
  payload: Payload;
}

interface INVERT_SETTING_ACTION {
  type: typeof INVERT_SETTING;
  payload: string;
}

interface INVERT_VIDEO_SETTING_ACTION {
  type: typeof INVERT_VIDEO_SETTING;
  payload: string;
}

interface SETTINGS_TOGGLE_DESKTOP_NOTIFICATION_ACTION {
  type: typeof SETTINGS_TOGGLE_DESKTOP_NOTIFICATION;
  payload: Payload;
}

interface SETTINGS_EVIDENTLY {
  type: typeof SETTINGS_EVIDENTLY;
  payload: Payload;
}
interface SETTINGS_RECURRING_PACKAGES {
  type: typeof SETTINGS_RECURRING_PACKAGES;
  payload: Payload;
}
interface STORE_SETTINGS_RECURRING {
  type: typeof STORE_SETTINGS_RECURRING;
  payload: Payload;
}
interface REMOVE_SETTINGS_RECURRING {
  type: typeof REMOVE_SETTINGS_RECURRING;
  payload: Payload;
}

interface SET_UPDATING_EMAIL_AND_PASSWORD {
  type: typeof SET_UPDATING_EMAIL_AND_PASSWORD;
  payload: boolean;
}

export type SettingsActionType =
  | UPDATE_USER_SOCIAL_ACTION
  | STORE_SETTINGS_ACTION
  | STORE_VIDEO_SETTINGS_ACTION
  | STORE_VIDEO_INFO_ACTION
  | CLEAR_VIDEO_INFO_ACTION
  | STORE_ICE_SERVERS_ACTION
  | STORE_GDPR_USER_INFO_ACTION
  | INVERT_SETTING_ACTION
  | INVERT_VIDEO_SETTING_ACTION
  | SETTINGS_TOGGLE_DESKTOP_NOTIFICATION_ACTION
  | SETTINGS_RECURRING_PACKAGES
  | STORE_SETTINGS_RECURRING
  | REMOVE_SETTINGS_RECURRING
  | SETTINGS_EVIDENTLY
  | SET_UPDATING_EMAIL_AND_PASSWORD;
