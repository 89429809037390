import React, { useState } from 'react';
import find from 'lodash/find';
import ConfirmationModal from '../../../../common/modals/Confirmation';
import { GOOGLE_AUTH } from '../../../../../config/Master';
import Toggle from '../../../../../atoms/toggle/Toggle';

interface SliderGoogleToggleProps {
  needsAccountPassword: any;
  social: any;
  attachSocialLogin: any;
  handleSidebarToggle: any;
  menuOpen: any;
  isDisabled: any;
}

const SliderGoogleToggle = ({
  needsAccountPassword,
  social,
  attachSocialLogin,
  handleSidebarToggle,
  menuOpen,
  isDisabled,
}: SliderGoogleToggleProps): any => {
  /* Google Toggle Slider will have 3 scenarios:
       - Account not connected to google(the blue button with google logo under settings)
       - Account connected to google(the white button with google logo under settings)
       - Not logged in
       Need a way to detect if the google account is connected, if so will toggle which
       method to invoke whether connect/disconnect
    */

  const [modalOpen, toggleModalOpen] = useState(false);
  const [socialSelected, setSocial] = useState(null);

  const google = find(social.data, { social_site_name: GOOGLE_AUTH }) || null;

  const attachGoogle = async action => {
    try {
      const auth = await _googleAuth();

      if (action !== 'connect') {
        await auth.signOut();
        return attachSocialLogin({}, GOOGLE_AUTH, action);
      }

      const authUser = await auth.signIn({ prompt: 'select_account' });
      setTimeout(() => {
        handleSidebarToggle(!menuOpen, true);
      }, 1000);
      attachSocialLogin(authUser, GOOGLE_AUTH, action);
    } catch (e) {
      setTimeout(() => {
        handleSidebarToggle(!menuOpen, true);
      }, 1000);
      console.error(e);
    }
  };

  const handleToggleModal = (openStatus, mySocial) => {
    toggleModalOpen(openStatus);
    setSocial(mySocial);
  };

  const handleDetachSocial = () => {
    toggleModalOpen(false);
    setSocial(false);

    if (socialSelected === 'google') {
      return attachGoogle('disconnect');
    }
  };

  const determineToggle = () => {
    google ? handleToggleModal(true, 'google') : attachGoogle('connect');
  };

  return (
    <div>
      <Toggle
        toggleId="google_toggle"
        isChecked={Boolean(google)}
        disabled={isDisabled || needsAccountPassword}
        onChange={determineToggle}
      />

      <ConfirmationModal
        contentLabel="Social Attachment Modal"
        shouldOpen={modalOpen}
        onCloseHandler={() => toggleModalOpen(false)}
        cancelBtnText="Nevermind"
        confirmationBtnText="Disconnect"
        confirmAction={handleDetachSocial}
      />
    </div>
  );
};

export default SliderGoogleToggle;
