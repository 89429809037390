import React, { ReactElement } from 'react';

interface AddContentIconProps {
  cssStyle?: any;
  width?: number;
  height?: number;
}

const StarV2Icon = ({ cssStyle, height = 15, width = 16 }: AddContentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...cssStyle }}
    >
      <path
        d="M5.24381 11.8025L8 10.1299L10.7562 11.8217L10.025 8.64742L12.4437 6.51909L9.25638 6.234L8 3.24997L6.74363 6.21485L3.55634 6.49993L5.97504 8.64742L5.24381 11.8025ZM3.36869 14.4444L4.58772 9.10355L0.5 5.51149L5.89989 5.03665L8 0L10.1001 5.03665L15.5 5.51149L11.4123 9.10355L12.6313 14.4444L8 11.6123L3.36869 14.4444Z"
        fill="white"
      />
    </svg>
  );
};

export default StarV2Icon;
