import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Source: string;
  User: string;
}

export const mixpanelTrackProfileFavorited = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Profile Favorited',
    payload: {
      event_properties: eventProperties,
      incremental_properties: {
        '# favorites': 1,
      },
    },
  });
};
