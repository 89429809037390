import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  component: 'Skip',
};
const aboutCommonValues = {
  page: 'About',
  ...commonValues,
};
const photoUploadCommonValues = {
  page: 'Photo Upload',
  ...commonValues,
};
const joinPhoneCommonValues = {
  page: 'Join',
  ...commonValues,
};

export const enum JoinClickVysionEventsEnum {
  CLICK_ABOUT_SKIP = 'joinAboutSkip',
  CLICK_ABOUT_SKIP_CLOSE = 'joinAboutSkipClose',
  CLICK_ABOUT_SKIP_NO_GO_BACK_AND_FINISH = 'joinAboutSkipNoGoBackAndFinish',
  CLICK_ABOUT_SKIP_YES_SKIP_FOR_NOW = 'joinAboutSkipYesSkipForNow',
  CLICK_PHOTO_UPLOAD_SKIP = 'joinPhotoUploadSkip',
  CLICK_PHOTO_UPLOAD_SKIP_CLOSE = 'joinPhotoUploadSkipClose',
  CLICK_PHOTO_SKIP_YES_SKIP_FOR_NOW = 'joinPhotoUploadSkipYesSkipForNow',
  CLICK_PHOTO_SKIP_NO_GO_BACK_AND_FINISH = 'joinPhotoUploadSkipNoGoBackAndFinish',
  CLICK_JOIN_DISPLAYNAME_SUGGEST_USERNAME = 'joinDisplayNameSuggestUsername',
  CLICK_JOIN_PHONE_COUNTRY_CODE = 'joinPhoneCountryCode',
  CLICK_JOIN_PHONE_COUNTRY_CODE_COUNTRY = 'joinPhoneCountryCodeCountry',
  CLICK_JOIN_PHONE_NEXT = 'joinPhoneNext',
  CLICK_JOIN_PHONE_ENTER_VERIFICATION_CODE_EDIT_PHONE_NUMBER = 'joinPhoneEnterEditPhoneNumber',
  CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE = 'joinEnterANewNumberCountryCode',
  CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE_COUNTRY = 'joinEnterANewNumberCountryCodeCountry',
  CLICK_JOIN_ENTER_PHONE_UPDATE = 'joinEnterANewNumberUpdate',
  CLICK_JOIN_ENTER_PHONE_UPDATE_CLOSE = 'joinEnterANewNumberClose',
  CLICK_JOIN_ENTER_VERIFICATION_CODE_VERIFY = 'joinEnterVerificationCodeVerify',
  CLICK_JOIN_ENTER_VERIFICATION_CODE_REQUEST_AGAIN = 'joinEnterVerificationCodeRequestAgain',
  CLICK_JOIN_NEW_VERIFICATION_MODAL_OK = 'joinNewVerificationOk',
  CLICK_JOIN_NEW_VERIFICATION_MODAL_CLOSE = 'joinNewVerificationClose',
  CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_OK = 'joinSuccessfullyVerifiedOk',
  CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_CLOSE = 'joinSuccessfullyVerifiedClose',
}

export const joinClickVysionEvents = {
  [JoinClickVysionEventsEnum.CLICK_ABOUT_SKIP]: {
    ...aboutCommonValues,
    eventName: 'join-about-skip',
  },
  [JoinClickVysionEventsEnum.CLICK_ABOUT_SKIP_CLOSE]: {
    ...aboutCommonValues,
    eventName: 'join-about-skip-close',
    extraInfo: 'Close',
  },
  [JoinClickVysionEventsEnum.CLICK_ABOUT_SKIP_NO_GO_BACK_AND_FINISH]: {
    ...aboutCommonValues,
    eventName: 'join-about-skip-noGoBackAndFinish',
    extraInfo: 'No, go back and finish',
  },
  [JoinClickVysionEventsEnum.CLICK_ABOUT_SKIP_YES_SKIP_FOR_NOW]: {
    ...aboutCommonValues,
    eventName: 'join-about-skip-yesSkipForNow',
    extraInfo: 'Yes, skip for now',
  },

  // photo upload
  [JoinClickVysionEventsEnum.CLICK_PHOTO_UPLOAD_SKIP]: {
    ...photoUploadCommonValues,
    eventName: 'join-photoUpload-skip',
  },
  [JoinClickVysionEventsEnum.CLICK_PHOTO_UPLOAD_SKIP_CLOSE]: {
    ...photoUploadCommonValues,
    eventName: 'join-photoUpload-skip-close',
    extraInfo: 'Close',
  },
  [JoinClickVysionEventsEnum.CLICK_PHOTO_SKIP_YES_SKIP_FOR_NOW]: {
    ...photoUploadCommonValues,
    eventName: 'join-photoUpload-skip-yesSkipForNow',
    extraInfo: 'Yes, skip for now',
  },
  [JoinClickVysionEventsEnum.CLICK_PHOTO_SKIP_NO_GO_BACK_AND_FINISH]: {
    ...photoUploadCommonValues,
    eventName: 'join-photoUpload-skip-noGoBackAndFinish',
    extraInfo: 'No, go back and finish',
  },

  // suggest username
  [JoinClickVysionEventsEnum.CLICK_JOIN_DISPLAYNAME_SUGGEST_USERNAME]: {
    ...aboutCommonValues,
    component: 'Display Name',
    eventName: 'join-displayName-suggestUsername',
    extraInfo: 'Suggest username',
    page: 'Join',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_COUNTRY_CODE]: {
    ...joinPhoneCommonValues,
    component: 'Phone Number Entry',
    eventName: 'join-countryCode',
    extraInfo: 'Country Code Selection',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_COUNTRY_CODE_COUNTRY]: {
    ...joinPhoneCommonValues,
    component: 'Country Code Selection',
    eventName: 'join-countryCode-',
    extraInfo: '',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_NEXT]: {
    ...joinPhoneCommonValues,
    component: 'Next Button',
    eventName: 'join-next',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_ENTER_VERIFICATION_CODE_EDIT_PHONE_NUMBER]: {
    ...joinPhoneCommonValues,
    page: '',
    component: 'Enter Verification Code',
    eventName: 'join-enterVerificationCode-editPhoneNumber',
    extraInfo: 'Edit Phone Number Link',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE]: {
    ...joinPhoneCommonValues,
    component: 'Enter a New Number Modal',
    eventName: 'join-enterANewNumber-countryCode',
    extraInfo: 'Country Code Selection',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE_COUNTRY]: {
    ...joinPhoneCommonValues,
    page: '',
    component: 'Enter a New Number Modal',
    eventName: 'join-enterANewNumber-countryCode-',
    extraInfo: '',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_PHONE_UPDATE]: {
    ...joinPhoneCommonValues,
    component: 'Enter a New Number Modal',
    eventName: 'join-enterANewNumber-update',
    extraInfo: 'Update Button',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_PHONE_UPDATE_CLOSE]: {
    ...joinPhoneCommonValues,
    component: 'Enter a New Number Modal',
    eventName: 'join-enterANewNumber-close',
    extraInfo: 'Close',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_VERIFICATION_CODE_VERIFY]: {
    ...joinPhoneCommonValues,
    component: 'Enter Verification Code',
    eventName: 'join-enterVerificationCode-verify',
    extraInfo: 'Verify Button',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_VERIFICATION_CODE_REQUEST_AGAIN]: {
    ...joinPhoneCommonValues,
    component: 'Enter Verification Code',
    eventName: 'join-enterVerificationCode-requestAgain',
    extraInfo: 'Request Again Link',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_NEW_VERIFICATION_MODAL_OK]: {
    ...joinPhoneCommonValues,
    component: 'New Verification Modal',
    eventName: 'join-newVerification-ok',
    extraInfo: 'Ok Button',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_NEW_VERIFICATION_MODAL_CLOSE]: {
    ...joinPhoneCommonValues,
    component: 'New Verification Modal',
    eventName: 'join-newVerification-close',
    extraInfo: 'Close',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_OK]: {
    ...joinPhoneCommonValues,
    component: 'Phone Number Verified Success Modal',
    eventName: 'join-successfullyVerified-ok',
    extraInfo: 'OK',
  },
  [JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_SUCCESSFULLY_VERIFIED_CLOSE]: {
    ...joinPhoneCommonValues,
    component: 'Phone Number Verified Success Modal',
    eventName: 'join-successfullyVerified-close',
    extraInfo: 'Close',
  },
};
