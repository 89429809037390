import React, { ReactElement } from 'react';

const IconMakePrivate = ({
  iconColor: iconColor,
  fillColor,
  customStyle,
  ...rest
}: {
  iconColor: string;
  fillColor: string;
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 24, height: 24, ...customStyle }}
      {...rest}
    >
      <circle opacity="0.65" cx="12" cy="12" r="12" fill={fillColor} />
      <path
        d="M16.9636 19L14.2909 16.3917C13.9197 16.5069 13.5459 16.5934 13.1696 16.6513C12.7929 16.7087 12.403 16.7374 12 16.7374C10.3985 16.7374 8.97197 16.3001 7.72045 15.4257C6.46894 14.5508 5.56212 13.4167 5 12.0236C5.22273 11.4684 5.50379 10.9524 5.84318 10.4755C6.18258 9.99913 6.5697 9.57238 7.00455 9.19527L5.25455 7.43545L6.14545 6.55554L17.8545 18.1201L16.9636 19ZM12 14.8518C12.1167 14.8518 12.2253 14.8466 12.3258 14.8361C12.4268 14.8257 12.5356 14.8047 12.6523 14.7733L9.21591 11.3793C9.18409 11.4946 9.16288 11.602 9.15227 11.7018C9.14167 11.8011 9.13636 11.9083 9.13636 12.0236C9.13636 12.8092 9.41467 13.4771 9.97127 14.0272C10.5283 14.577 11.2045 14.8518 12 14.8518ZM16.6455 15.1347L14.625 13.1549C14.6992 12.9768 14.7576 12.7962 14.8 12.6131C14.8424 12.4296 14.8636 12.2331 14.8636 12.0236C14.8636 11.2379 14.5853 10.57 14.0287 10.0199C13.4717 9.47014 12.7955 9.19527 12 9.19527C11.7879 9.19527 11.5889 9.21622 11.4031 9.25812C11.2177 9.30002 11.0348 9.36287 10.8545 9.44668L9.23182 7.84398C9.66667 7.6659 10.1121 7.53224 10.5682 7.44299C11.0242 7.35417 11.5015 7.30975 12 7.30975C13.6015 7.30975 15.028 7.74698 16.2795 8.62145C17.5311 9.49633 18.4379 10.6304 19 12.0236C18.7561 12.6416 18.4353 13.2152 18.0378 13.7444C17.6399 14.2732 17.1758 14.7366 16.6455 15.1347ZM13.7023 12.2435L11.7932 10.358C12.0902 10.3056 12.3634 10.3291 12.6128 10.4284C12.8618 10.5281 13.0765 10.6723 13.2568 10.8608C13.4371 11.0494 13.5672 11.2668 13.6469 11.5132C13.7262 11.7592 13.7447 12.0026 13.7023 12.2435Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconMakePrivate;
