import * as sdk from '../sdk';
import { getRecurringBillingPackages } from '../sdk/BillingSDK';
import { storePaymentToken } from './billingActions';

export const storeRecurringPackages = (payload: any): any => {
  return {
    type: 'SETTINGS_RECURRING_PACKAGES',
    payload,
  };
};

export const storeRecurringSettings = (payload: any): any => {
  return {
    type: 'STORE_SETTINGS_RECURRING',
    payload,
  };
};

export const removeRecurringSettings = (): any => {
  return {
    type: 'REMOVE_SETTINGS_RECURRING',
  };
};

export const getRecurringPackages = (order: any): any => {
  return (dispatch, getState) => {
    return getRecurringBillingPackages(getState().auth.jwToken, order).then(response => {
      dispatch(storeRecurringPackages({ packages: response.data.data }));
      return response;
    });
  };
};

export const getRecurringPayment = (): any => {
  return dispatch => {
    return sdk.getRecurringPayment().then(response => {
      dispatch(
        storeRecurringSettings({
          data: response.data.data,
          packages: response.data.meta.packages,
          paymentToken: response.data.meta.paymentToken,
        })
      );
      return response;
    });
  };
};

export const subscribeRecurringPayment = (payload: any): any => {
  return dispatch => {
    return sdk.subscribeRecurringPayment(payload).then(response => {
      dispatch(storeRecurringSettings({ data: null, subscribed: false }));
      return response;
    });
  };
};

export const unSubscribeRecurringPayment = (): any => {
  return dispatch => {
    return sdk
      .unSubscribeRecurringPayment()
      .then(response => {
        dispatch(removeRecurringSettings());
        return response;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
};

export const getPaymentSettings = (): any => {
  return dispatch => {
    return sdk.getPaymentToken().then(response => {
      dispatch(storePaymentToken(response.data));

      if (response?.data?.data) {
        const responseData = response.data.data.filter(data => data.recurring);

        if (responseData.length > 0) {
          const { recurring } = responseData[0];

          dispatch(storeRecurringSettings(recurring));

          return responseData[0];
        } else {
          dispatch(removeRecurringSettings());
        }

        return null;
      }
    });
  };
};
