import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  'Experiment name': string;
  'Variant name': string;
}

export const mixpanelTrackExperimentStarted = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: '$experiment_started',
    payload: {
      event_properties: eventProperties,
    },
  });
};
