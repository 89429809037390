import { AMSetDefaultOfferEvaluation } from './AMSetDefaultOffer';
import { GMDashboardEditableOfferEvaluation } from './GMDiscoverModeEditableOffer';
import { DiscoverSwipingModeAMEvaluation } from './discoverSwipingModeAM';
import { DiscoverSwipingModeDesktopEvaluation } from './discoverSwipingModeDesktop';
import { minOfferIncreaseEvaluation } from './minOfferIncrease';
import { OneTapOfferManagementEvaluation } from './oneTapOfferManagement';

// add more here for experiments
const evidentlyFeatures = [
  ...minOfferIncreaseEvaluation,
  ...AMSetDefaultOfferEvaluation,
  ...GMDashboardEditableOfferEvaluation,
  ...OneTapOfferManagementEvaluation,
  ...DiscoverSwipingModeAMEvaluation,
  ...DiscoverSwipingModeDesktopEvaluation,
];

export default evidentlyFeatures;
