import React, { useMemo } from 'react';
import CountDown from 'react-countdown';
import styled from '@emotion/styled';
import { getDate } from '../../common';
import Container from '../../components/blocks/Container';
import Headline from '../../components/common/Headline';
import { s3BucketDirect } from '../../config/Master';
import { getBrowserDetails } from '../../utils/helpers';
import VerticalSeparator from '../icons/VerticalSeparator';

const { isMobile } = getBrowserDetails();

const PromotionDescription = styled('div')({
  textAlign: 'center',
  padding: isMobile ? '5px 0 20px' : '10px 0',
});

const CountdownContainer = styled('div')({
  textAlign: 'center',
});

const SmallInfo = styled('small')({
  display: 'block',
  paddingBottom: 25,
  paddingTop: 5,
  fontSize: isMobile ? 15 : 16,
  maxWidth: isMobile ? 295 : 'unset',
  margin: 'auto',
  fontStyle: 'italic',
});

const PromoContainerBG = styled('div')({
  maxWidth: getBrowserDetails().isTablet && getBrowserDetails().isNotDesktopWidth ? 546 : 820,
  margin: '0 auto',
  color: '#fff',
  width:
    getBrowserDetails().isTablet && getBrowserDetails().isNotDesktopWidth
      ? '70%'
      : getBrowserDetails().isMobile
      ? 'unset'
      : '100%',
});

const Timer = ({ value, label }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: isMobile ? 25 : 30 }}>
      <span style={{ fontSize: isMobile ? 28 : 56, fontWeight: 600 }}>{value}</span>
      <span style={{ fontSize: isMobile ? 12 : 14, fontWeight: 300 }}> {label}</span>
    </div>
  );
};

interface PromotionalPackageContainerProps {
  countDown: any;
  isBilling?: boolean;
  promoVariant: 'vip' | 'special' | 'intro_credit_promo';
}

const billingPromotionalStyle = {
  background: '#0D81E2',
  borderRadius: 10,
  marginBottom: 10,
  padding: '0 16px 0 17px',
};

const PromotionalPackageContainer = ({
  countDown,
  isBilling = false,
  promoVariant,
}: PromotionalPackageContainerProps): any => {
  const date = countDown ? getDate(countDown.date, countDown.timezone) : null;

  const headlineCustomIntroCreditStyle = {
    maxWidth: isMobile ? 298 : 368,
    margin: 'auto',
    marginBottom: isMobile ? 0 : 10,
    letterSpacing: isMobile ? '-0.17px' : '-0.22px',
    lineHeight: isMobile ? '24px' : '31px',
    fontSize: isMobile ? 17 : 22,
  };

  const packageInfo = useMemo(() => {
    switch (promoVariant) {
      case 'vip':
        return {
          title: 'You’ve Unlocked VIP Pricing!',
          subTitle: 'Just like you, these special credit packs are too good to pass up.',
        };
      case 'special':
        return {
          title: 'Special Promo Packages Now Available!',
          subTitle: 'Take advantage before time runs out!',
        };
      case 'intro_credit_promo':
        return {
          title: 'Special Introductory Credit Pack Now Available!',
          subTitle: '',
        };
    }
  }, [promoVariant]);

  return (
    <PromoContainerBG>
      <Container style={isBilling ? billingPromotionalStyle : {}}>
        <PromotionDescription>
          <img src={`${s3BucketDirect}payment/promo_star.png`} width="80" alt="Promo Star" />
          <Headline
            style={{
              fontWeight: 700,
              color: '#fff',
              fontStyle: 'italic',
              fontSize: isMobile ? 17 : 26,
              paddingTop: isMobile ? 10 : 'unset',
              ...(promoVariant === 'intro_credit_promo' ? headlineCustomIntroCreditStyle : {}),
            }}
          >
            {packageInfo?.title}
          </Headline>
        </PromotionDescription>
        {packageInfo?.subTitle && (
          <SmallInfo style={promoVariant === 'special' ? { paddingBottom: isMobile ? 10 : 0 } : {}}>
            {packageInfo?.subTitle}
          </SmallInfo>
        )}
        {countDown && (
          <CountdownContainer>
            <CountDown
              date={Date.parse(date)}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: isMobile ? 15 : 20,
                    }}
                  >
                    <Timer label="Day" value={days} />
                    <VerticalSeparator />
                    <Timer label="Hrs" value={hours} />
                    <VerticalSeparator />
                    <Timer label="Min" value={minutes} />
                    <VerticalSeparator />
                    <Timer label="Sec" value={seconds} />
                  </div>
                );
              }}
            />
          </CountdownContainer>
        )}
      </Container>
    </PromoContainerBG>
  );
};

export default PromotionalPackageContainer;
