import React, { ReactElement } from 'react';

interface TrashIconProps {
  fillColor?: string;
}

const TrashIcon = ({ fillColor = '#000000' }: TrashIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" fill={fillColor} viewBox="0 0 13 17">
      <g>
        <path d="M11.4855 3L9.96623333 3 9.96623333 1.4464256C9.96623333.647183803 9.29305 0 8.463 0L4.537 0C3.70695 0 3.03376667.647183803 3.03376667 1.4464256L3.03376667 3 1.5145 3C.682716667 3 0 3.31136477 0 4 0 4.68571905.682716667 5 1.5145 5L11.4855 5C12.3172833 5 13 4.68571905 13 4 13 3.31136477 12.3172833 3 11.4855 3zM3.93553333 1.4464256C3.93553333 1.12835361 4.20593333.867980337 4.537.867980337L8.463.867980337C8.79406667.867980337 9.06446667 1.12835361 9.06446667 1.4464256L9.06446667 2.99944196 3.93553333 2.99944196 3.93553333 1.4464256zM1 14.9432115C1 16.0789615 1.99831278 17 3.2278162 17L9.7721838 17C11.0014581 17 12 16.0789615 12 14.9432115L12 6 1 6 1 14.9432115zM8.82336277 7.2984375L10.1234378 7.2984375 10.1234378 14.3936485C10.1234378 14.7276339 9.83198634 15 9.47271288 15 9.11504333 15 8.82336277 14.7276339 8.82336277 14.3936485L8.82336277 7.2984375zM5.84560907 7.2984375L7.14385102 7.2984375 7.14385102 14.3936485C7.14385102 14.7276339 6.8523996 15 6.49450092 15 6.13568572 15 5.84560907 14.7276339 5.84560907 14.3936485L5.84560907 7.2984375zM2.86602233 7.2984375L4.16426429 7.2984375 4.16426429 14.3936485C4.16426429 14.7276339 3.87441676 15 3.51674721 15 3.15793201 15 2.86602233 14.7276339 2.86602233 14.3936485L2.86602233 7.2984375z" />
      </g>
    </svg>
  );
};

export default TrashIcon;
