import React, { useState, useEffect, ReactElement, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SummaryActions from '../components/pages/AccountMigration/SummaryActions';
import { dimensions, textType } from '../style';
import ProfilePhotoContainer from '../atoms/profile/ProfilePhotoContainer';
import queryString from 'query-string';
import { extractLocationData, geocodeByPlaceId, normalizeLocationDisplay, renderAvatar } from '../common';
import EditableProfileCover from '../atoms/EditableProfileCover';
import ProfilePhoto from '../atoms/profile/ProfilePhoto';
import _isEmpty from 'lodash/isEmpty';
import DataLayer from '../utils/dataLayer';
import { commonIcons } from '../atoms/icons/materialIcons';
import { ternaryFunc } from '../utils/helpers';
import { auth10tixVerification, tomtomEnabled } from '../config/Master';
import EditButton from '../atoms/buttons/ProfileEditButton';
import ProfileCompletionProgress from '../components/blocks/ProfileCompletionProgress';
import {
  DEFAULT_REASON,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_VARIATIONS,
  getFeatureDetails,
} from '../constants/evidently';
import FavoriteButtonV2 from '../atoms/buttons/FavoriteButtonV2';

const Cover = styled('div')({
  display: 'flex',
  // maxWidth: '702px',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },

  '@media (max-width: 550px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px',
    maxWidth: '100%',
  },
});

const InfoContainer = styled('div')({
  paddingLeft: '12px',
  flex: 3,
  zIndex: 3,
  maxWidth: '494px',
  display: 'flex',
  flexDirection: 'column',
  height: 100,

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },
  [dimensions.SCREEN_MAX_XS]: {
    paddingLeft: '0px',
    margin: '0 15px',
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
  },
});

const Details = styled('div')({
  width: '100%',
});

const ProfileInfo = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '5px 0',

  [dimensions.SCREEN_MAX_XS]: {
    position: 'absolute',
    top: '-84px',
    width: '100%',
  },
});

const Username = styled('h1')({
  margin: 0,
  paddingBottom: '4px',
  ...textType.header,
  textTransform: 'none',
  [dimensions.SCREEN_MAX_XS]: {
    color: '#fff',
    fontSize: 18,
    letterSpacing: -0.54,
    fontWeight: 600,
    lineHeight: 'normal',
    paddingBottom: 3,
  },
});

const Detail = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  [dimensions.SCREEN_MAX_XS]: {
    color: '#CFCFCF',
    lineHeight: 'normal',
    fontSize: 15,
    fontWeight: 400,
    letterSpacing: -0.45,
    paddingBottom: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 300,
  },
});

const DetailLastActive = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  ...textType.caption,
  [dimensions.SCREEN_MAX_XS]: {
    lineHeight: 'normal',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.36,
    color: '#AFB0B1',
  },
});

const PhotoContainer = styled('div')({
  flex: 1,
  maxWidth: '245px',
  minWidth: '245px',
  textAlign: 'center',
  '@media (max-width: 550px)': {
    maxWidth: '100%',
  },
});

const StyledFavoriteButton = styled(FavoriteButtonV2)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    // [dimensions.SCREEN_MAX_SM]: {
    '@media (max-width: 550px)': {
      height: '28.21px',
    },
  },
});

const BaseCover = props => {
  const {
    className,
    username,
    age,
    location,
    lastLoginTime,
    lastLoginLocation,
    profileImg,
    hasFavorite,
    editable,
    favorited,
    onClickFavorite,
    onClickPhoto,
    onChangeAddress,
    onSelectLocation,
    handleSubmit,
    handleCancel,
    updateUsername,
    displayName,
    myLocation,
    editProfileCover,
    toggleEditable,
    errors,
    isMigration,
    myPhotos,
    otherProfilePhotos,
    myPhotosLength,
    otherProfilePhotosLength,
    isOtherProfile,
    isMobile,
    isPortrait,
    updateMaxLength,
    updateSliderPosition,
    sliderPosition,
    position,
    lightboxIsOpen,
    privatePhotoCount,
    currentUserIsGenerous,
    counterBadge,
    isLogin = false,
    isVerified = false,
    offer,
    myProfile,
    settings,
    isUpdatingProfileCover,
  } = props;

  const myName = editable ? displayName : username;
  const photoList = isOtherProfile ? otherProfilePhotos : (myPhotos && myPhotos.data) || [];
  // temporary workaround for gallery 2 photos only
  const otherTotal = otherProfilePhotosLength === 2 ? 3 : otherProfilePhotosLength;
  const myTotal = myPhotosLength === 0 ? 1 : myPhotosLength === 2 ? 3 : myPhotosLength;
  const totalLength = isOtherProfile ? otherTotal : myTotal;
  const [photoSlider, setPhotoSlider] = useState(isMobile && isPortrait);
  const [showToolTip, setShowToolTip] = useState(false);
  const [shouldShowFavoriteButton, setShouldShowFavoriteButton] = useState(true);
  const [freshLocation, setFreshLocation] = useState(myLocation);

  useEffect(() => {
    const { account } = props;

    if (account) {
      const { profile } = account || {};
      const locationFromProps = normalizeLocationDisplay(
        account.city ? account.city : profile?.data?.city,
        account.region ? account.region : profile?.data?.region,
        account.country ? account.country : profile?.data?.country
      );

      setFreshLocation(locationFromProps);
    }
  }, [props.account]);

  useEffect(() => {
    if (!settings?.evidently) {
      return;
    }

    const featureDetails = getFeatureDetails(settings?.evidently, EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE);

    const { variation = EVIDENTLY_FEATURE_VARIATIONS.CONTROL, reason = DEFAULT_REASON } = featureDetails || {};

    if (featureDetails && reason != DEFAULT_REASON) {
      setShouldShowFavoriteButton(variation === EVIDENTLY_FEATURE_VARIATIONS.CONTROL);
    }
  }, [settings?.evidently]);

  useEffect(() => {
    updateMaxLength && updateMaxLength(totalLength);
    if (position && position.current && totalLength !== null) {
      position.current.innerText = `${sliderPosition}/${totalLength}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherProfilePhotosLength, myPhotosLength]);

  useEffect(() => {
    setPhotoSlider(isMobile && isPortrait);
  }, [isPortrait, isMobile]);

  useEffect(() => {
    updateSliderPosition && updateSliderPosition(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoSlider]);

  window.addEventListener(
    'orientationchange',
    function() {
      // lets check if we are at the right page before we set the state to allow photo sliding
      const fullUrl = window.location.href.split('/');
      const currPage = fullUrl[fullUrl.length - 2];
      if (currPage === 'profile') {
        setPhotoSlider(isMobile && window.orientation !== 90);
      }
    },
    false
  );

  const coverInputFieldStyle = () => {
    return isLogin && !isMigration ? { color: 'rgba(0, 0, 0, 0.6)' } : {};
  };

  const setMyCurrLocation = () => {
    const evaluator = age && editable;
    const output1 = myLocation !== '' && ` • `;
    const output2 = location && ` • `;
    return ternaryFunc(evaluator, output1, output2);
  };

  const hasPhoto = photoList.filter(photo => photo?.type === 'photo');

  return (
    <Cover className={className}>
      {photoSlider && hasPhoto.length > 0 && photoList.length > 1 ? (
        <ProfilePhotoContainer
          profileImg={profileImg}
          onClickPhoto={onClickPhoto}
          photoList={photoList}
          isOtherProfile={isOtherProfile}
          privatePhotoCount={privatePhotoCount}
          currentUserIsGenerous={currentUserIsGenerous}
          updateMaxLength={updateMaxLength}
          updateSliderPosition={updateSliderPosition}
          sliderPosition={sliderPosition}
          lightboxIsOpen={lightboxIsOpen}
          counterBadge={counterBadge}
          totalImages={totalLength}
          offer={offer}
        />
      ) : (
        <PhotoContainer>
          <ProfilePhoto imgUrl={profileImg} onClick={onClickPhoto} offer={offer} myProfile={myProfile} />
        </PhotoContainer>
      )}
      {editProfileCover || isUpdatingProfileCover ? (
        <EditableProfileCover
          onChangeAddress={onChangeAddress}
          onSelectLocation={onSelectLocation}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          updateUsername={updateUsername}
          displayName={displayName}
          myLocation={myLocation}
          errors={errors}
          myProfile={myProfile}
        />
      ) : (
        <InfoContainer className="profile-cover__info">
          <ProfileInfo>
            <Details>
              <Username
                data-test-id="profile-username-info"
                style={isLogin && !isMigration ? { color: 'rgba(0, 0, 0, 0.6)' } : {}}
              >
                {myName}
                <span onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)}>
                  {auth10tixVerification && isVerified && (
                    <commonIcons.check.badge showToolTip={showToolTip && isOtherProfile} />
                  )}
                </span>
                {editable && (
                  <EditButton
                    dataTestId="edit-profile-cover"
                    handleClick={toggleEditable}
                    customStyle={{ float: 'right' }}
                  />
                )}
              </Username>
              <Detail data-test-id="profile-user-details" style={coverInputFieldStyle()}>
                {age}
                {setMyCurrLocation()}
                {isOtherProfile
                  ? ternaryFunc(editable, myLocation, location)
                  : isUpdatingProfileCover
                  ? 'Saving...'
                  : ternaryFunc(editable, freshLocation, myLocation)}
              </Detail>
              <DetailLastActive data-test-id="profile-last-log">
                {lastLoginTime} {lastLoginLocation && `in ${lastLoginLocation}`}
              </DetailLastActive>
            </Details>
            {hasFavorite && shouldShowFavoriteButton && (
              <StyledFavoriteButton
                data-test-id="profile-favorite-button"
                favorited={favorited}
                onClick={onClickFavorite}
              />
            )}
          </ProfileInfo>
          {props.children}
        </InfoContainer>
      )}
    </Cover>
  );
};

const CoverContent = styled('div')({
  margin: '5px 0',

  [dimensions.SCREEN_MAX_XS]: {
    margin: '20px 0 5px',
  },
});

export const ProfileCover = (props: any): ReactElement => {
  const { children, ...rest } = props;

  return (
    <BaseCover hasFavorite {...rest} className="profile-cover">
      <CoverContent>{children}</CoverContent>
    </BaseCover>
  );
};

export const MyProfileCover = (props: any): ReactElement => {
  // maintain a state here to manage the toggling of editable component to change name and location
  const {
    account,
    username,
    updateProfileCover,
    clearIndividualError,
    profileImgUrl,
    editable,
    isMobile,
    isMigration,
    onConfirmIDV,
    onContinueIDV,
    isUpdatingProfileCover,
  } = props;
  const [editProfileCover, setEditProfileCover] = useState(false);
  const [displayName, setDisplayName] = useState(username);
  const [myLocation, setMyLocation] = useState('');
  const initialLocation = useRef('');
  const [, setIsSubmitted] = useState(false);
  const updatedFields = useRef({
    username: false,
    location: false,
  });
  const [updatedLocation, setUpdatedLocation] = useState(undefined);
  const { profile } = account ? account : {};
  const queryStringParsed = queryString.parse(props.search);
  const isFirsTimeLogin = queryStringParsed.userFirstTimeLogin === '1';

  const [myPhoto, setPhoto] = useState({});

  useEffect(() => {
    if (isFirsTimeLogin && !_isEmpty(profile)) {
      const profileData = profile.data;

      new DataLayer().push({
        submitted_at: profileData.submitted_date || null,
        completed_at: profileData.completed_date || null,
        monetized_at: profileData.monetized_date || null,
        gender: profileData.sex,
        age: profileData.age,
        availableCredits: account.credits,
        publicPhotos: profileData.approved_public_photos_count || 0,
        outstandingOffers: account.offer_counts.new,
        type: profileData.account_type,
        looking_male: profileData.looking_male,
        looking_female: profileData.looking_female,
        event: 'joinComplete',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  useEffect(() => {
    if (isUpdatingProfileCover) return;

    profile &&
      setMyLocation(
        getLocationDisplay(
          account.city ? account.city : profile.data.city,
          account.region ? account.region : profile.data.region,
          account.country ? account.country : profile.data.country
        )
      );
  }, [account, profile, isUpdatingProfileCover]);

  useEffect(() => {
    if (myLocation && !initialLocation.current) {
      initialLocation.current = myLocation;
    }
  }, [myLocation]);

  useEffect(() => {
    setPhoto({ ...profileImgUrl });
  }, [profileImgUrl]);

  const toggleEditable = e => {
    e.preventDefault();
    setEditProfileCover(!editProfileCover);
  };

  const locationSelectHandler = (address, placeId, details = null) => {
    if (tomtomEnabled && details !== null) {
      setUpdatedLocation(details);
      setMyLocation(address);
    } else {
      geocodeByPlaceId(placeId)
        .then(results => {
          const loc = results[0];
          if (loc && loc.geometry) {
            setUpdatedLocation(loc, address);
            setMyLocation(address);
          }
        })
        .catch(error => {
          console.error('Issue with Geocoding: ', error);
        });
    }

    handleLocationFieldChange(address);
  };

  const handleUpdatedField = (field: 'username' | 'location', value = true) => {
    updatedFields.current = {
      ...updatedFields.current,
      [field]: value,
    };
  };

  const handleResetUpdatedFields = () => {
    updatedFields.current = {
      username: false,
      location: false,
    };
    initialLocation.current = '';
  };

  const handleLocationFieldChange = address => {
    const isUpdated = address !== initialLocation.current;
    handleUpdatedField('location', isUpdated);
  };

  const updateMyLocation = address => {
    handleLocationFieldChange(address);
    setMyLocation(address);
  };

  useEffect(() => {
    const isUpdated = displayName !== username;
    handleUpdatedField('username', isUpdated);
  }, [displayName, username]);

  const updateMyUsername = e => {
    clearIndividualError(e.target.id);
    setDisplayName(e.target.value);
  };

  const postSubmit = e => {
    document.getElementById('autocompleteLocation').value = '';

    toggleEditable(e);
    handleResetUpdatedFields();
  };

  const handleSubmit = async e => {
    setIsSubmitted(true);

    if (updatedLocation !== undefined) {
      const newLocation = extractLocationData(updatedLocation);

      await updateProfileCover({
        username: displayName,
        city: newLocation.city,
        country: newLocation.country,
        region: newLocation.region,
        latitude: newLocation.latitude,
        longitude: newLocation.longitude,
        updatedFields: updatedFields.current,
      }).then(() => postSubmit(e));
    } else {
      updatedFields.current.location = false;
      await updateProfileCover({
        username: displayName,
        city: account.city ? account.city : profile.data.city,
        country: account.country ? account.country : profile.data.country,
        region: account.region ? account.region : profile.data.region,
        updatedFields: updatedFields.current,
      }).then(() => postSubmit(e));
    }
  };

  const handleCancel = e => {
    // need to clear the fields if user select cancel button
    toggleEditable(e);
    setDisplayName(username);
    handleResetUpdatedFields();
    setUpdatedLocation(undefined);

    setMyLocation(
      getLocationDisplay(
        account.city ? account.city : profile.data.city,
        account.region ? account.region : profile.data.region,
        account.country ? account.country : profile.data.country
      )
    );
  };

  const getLocationDisplay = (city, region, country) => {
    return normalizeLocationDisplay(city, region, country);
  };

  return (
    <BaseCover
      className="my-profile-cover"
      onChangeAddress={updateMyLocation}
      onSelectLocation={locationSelectHandler}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      updateUsername={updateMyUsername}
      displayName={displayName}
      myLocation={myLocation}
      editProfileCover={editProfileCover}
      toggleEditable={toggleEditable}
      profileImg={renderAvatar(myPhoto)}
      editable={editable}
      isMigration={isMigration}
      onConfirmIDV={onConfirmIDV}
      onContinueIDV={onContinueIDV}
      {...props}
    >
      <CoverContent>
        {isMigration && !isMobile && <SummaryActions hashId={props.hashId} />}
        {!isMigration && (
          <ProfileCompletionProgress isFirsTimeLogin={isFirsTimeLogin} type="complete" onboarding={account} profilev2 />
        )}
      </CoverContent>
    </BaseCover>
  );
};

const baseProps = {
  username: PropTypes.string.isRequired,
  age: PropTypes.number,
  location: PropTypes.string,
  lastLoginTime: PropTypes.string,
  lastLoginLocation: PropTypes.string,
  profileImg: PropTypes.string,
  profileImgFallback: PropTypes.string,
  onClickPhoto: PropTypes.func.isRequired,
  hasFavorite: PropTypes.bool,
};

BaseCover.propTypes = {
  ...baseProps,
};

ProfileCover.propTypes = {
  ...baseProps,
  favorited: PropTypes.bool,
  onClickFavorite: PropTypes.func,
};

MyProfileCover.propTypes = {
  ...baseProps,
};
