import React, { useState, useEffect } from 'react';

import Container from '../../blocks/Container';
import ContextualButton from '../../../atoms/buttons/ContextualButton';
import Panel from '../../../atoms/panels/Panel';
import Header from '../../blocks/Header';
import Headline from '../../common/Headline';
import { Select } from '../../../atoms/Select';
import TextArea from '../../../atoms/TextArea';
import MasterConfig from '../../../config/Master';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const renderReasons = (reasons, isPhotoReport) => {
  if (!reasons) return null;
  if (isPhotoReport) return <option value="inappr_photo">Inappropriate photo</option>;

  return Object.entries(reasons).map((value, index) => {
    return (
      <option value={value[0]} key={index}>
        {value[1]}
      </option>
    );
  });
};

interface ReportProps {
  extprofile: any;
  submit: any;
  errors: any;
  location: any;
}

const Report = ({ extprofile, submit, errors }: ReportProps): any => {
  const [photoReport, setPhotReport] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reasonType, setReasonType] = useState('inappr_content');
  const [message, setMessage] = useState('');
  const [imgId, setImgId] = useState('');
  const [account, setAccount] = useState(extprofile.hash_id);
  const [username, setUsername] = useState(extprofile.username);
  const [filters, setFilters] = useState({
    reasons: [],
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryStringParsed = queryString.parse(location.search);
    setPhotReport(!!queryStringParsed.photo);
    setImgId(!!queryStringParsed.photo && queryStringParsed.photo.toString());
  }, [location.search]);

  useEffect(() => {
    setUsername(extprofile.username);
  }, [extprofile.username]);

  useEffect(() => {
    setAccount(extprofile.hash_id);
  }, [extprofile.hash_id]);

  const handleReason = e => {
    setReasonType(e.target.value);
    setFilters({
      reasons: [e.target.value],
    });
  };

  const handleMsg = e => {
    setMessage(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);
    const reportMessage = photoReport ? `Image Report [${imgId}]: ${message}` : message;
    const reportData = {
      submitting,
      reason_type: reasonType,
      message: reportMessage,
      account,
      username,
      filters,
    };

    submit(reportData, navigate);
  };

  return (
    <Container screenTop width="thin">
      <Panel withHeader>
        <form onSubmit={handleSubmit}>
          <Header>
            <Headline>Report {extprofile.username}</Headline>
          </Header>
          <p className="pageHeader-description">
            We review every case individually and will take appropriate action. Thank you for helping improve{' '}
            {MasterConfig.SITE_NAME}.
          </p>
          <Select
            inputLabel="Reason for reporting"
            style={{ display: 'inline-block' }}
            onChange={handleReason}
            value={reasonType}
            id="report"
            disabled={photoReport}
          >
            {renderReasons(extprofile.reasons, photoReport)}
          </Select>
          <TextArea inputLabel={'Description'} value={message} rows={3} resize="vertical" onChange={handleMsg} />
          {extprofile.username && (
            <ContextualButton
              type="submit"
              id="report-user"
              disabled={!message || submitting}
              loading={submitting && Object.keys(errors).length <= 0}
              buttonText={`Report ${extprofile.username}`}
            />
          )}
        </form>
      </Panel>
    </Container>
  );
};

export default Report;
