import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { saveToken, saveVirgil } from '../actions/authActions';
import { refreshProfile } from '../actions/profileActions';
import { toaster } from '../common';
import { storeSpecificErrors, clearEverything } from '../actions/commonActions';
import queryString from 'query-string';
import { removeCookie, resetMixpanel } from '../utils/helpers';

const ContinueContainer = ({ location, tokenLogin, profile }) => {
  useEffect(() => {
    // capture incoming jwt, and log user in.
    const { token, message, userFirstTimeLogin, action, username, resetToken, ...rest } = queryString.parse(
      location.search
    );

    const { hash_id } = profile;

    // remove cookie from realgifts
    removeCookie('rgstore_user');

    // reset mixpanel
    resetMixpanel();

    // attempt to grab profile and log user in
    tokenLogin(token, message, userFirstTimeLogin, rest['x-auth-type'], action, username, resetToken, hash_id);
  }, [location.search, tokenLogin, profile]);

  return <div>Just a moment..</div>;
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tokenLogin: (
      token,
      msg,
      userFirstTimeLogin,
      authType,
      action = 'dashboard',
      username = '/',
      resetToken = null,
      hashId
    ) => {
      if (!token) {
        // wipe the session, add an error and redirect to /login
        dispatch(clearEverything());
        dispatch(storeSpecificErrors('loginHeader', msg || 'There was an Issue.'));
        window.location.href = '/login';
      } else {
        // save token
        dispatch(saveToken({ token }));
        // save virgil
        dispatch(saveVirgil(authType === 'virgil'));
        // retrieve logged in profile.
        dispatch(refreshProfile())
          .then(() => {
            toaster.dismissAll();
            // we got a profile with this token, redirect user to IndexRoute
            // where they will either be sent to "join-profile" or "dashboard"
            if (userFirstTimeLogin === '1') {
              ownProps.navigate(`/profile/${hashId}?userFirstTimeLogin=1`);
            } else if (action === 'profile') {
              ownProps.navigate(`~${username}`);
            } else if (action === 'profile_edit') {
              ownProps.navigate(`/${action.replace('_', '/')}`);
            } else if (action === 'reset_password') {
              ownProps.navigate({
                pathname: '/restore',
                search: `?token=${resetToken}&ref=email`,
              });
            } else if (action === 'mail') {
              ownProps.navigate(`/mail/${username}`);
            } else {
              ownProps.navigate(`/${action}`);
            }
            toaster.success(msg);
          })
          .catch(() => {
            // invalid token.
            toaster.error('Invalid Token');
            window.location.href = '/login?logged_out=true';
          });
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueContainer);
