import React, { useState } from 'react';
import DashboardDiscoverConfirmationModal from '../DashboardDiscoverConfirmationModal';
import DashboardDiscoverModeActionConfirmation from '../DashboardDiscoverModeActionConfirmation';
import { Profile } from '../../../../models/Profile';

type UseFirstSwipeConfirmationModalType = () => {
  showFirstSwipeRightConfirmation: (
    actions: {
      onFirstSwipeRightCancelAction?: () => void;
      onFirstSwipeRightConfirmAction?: (doNotShowAgain?: boolean) => void;
    },
    ownProfile: Profile,
    username: string,
    offerPrice: string | number
  ) => void;
  showFirstSwipeLeftConfirmation: (
    actions: {
      onFirstSwipeLeftCancelAction?: () => void;
      onFirstSwipeLeftConfirmAction?: (doNotShowAgain?: boolean) => void;
    },
    username: string
  ) => void;
  initDiscoverConfirmationModal: () => JSX.Element | null;
};

const useFirstSwipeConfirmationModal: UseFirstSwipeConfirmationModalType = () => {
  const [modalState, setModalState] = useState<{
    showConfirmation: boolean;
    modalBody: React.ReactNode | null;
    onCloseClickHandler: () => void;
  }>({
    showConfirmation: false,
    modalBody: null,
    onCloseClickHandler: () => undefined,
  });

  const resetModal = () => {
    setModalState({
      showConfirmation: false,
      modalBody: null,
      onCloseClickHandler: () => undefined,
    });
  };

  const showConfirmationModal = (modalBody: React.ReactNode, onCloseClickHandler: () => void) => {
    setModalState({
      showConfirmation: true,
      modalBody,
      onCloseClickHandler,
    });
  };

  const initDiscoverConfirmationModal = () => (
    <DashboardDiscoverConfirmationModal
      showConfirmation={modalState.showConfirmation}
      onClose={modalState.onCloseClickHandler}
    >
      {modalState.modalBody}
    </DashboardDiscoverConfirmationModal>
  );

  return {
    showFirstSwipeRightConfirmation: (actions, ownProfile, username, offerPrice) => {
      const { onFirstSwipeRightCancelAction, onFirstSwipeRightConfirmAction } = actions;

      showConfirmationModal(
        <DashboardDiscoverModeActionConfirmation
          title={ownProfile.isGenerous() ? 'Make Me an Offer!' : `Send $${offerPrice} Offer Request?`}
          desc={
            ownProfile.isGenerous() ? (
              'This is my Opening Offer Amount. Sending this will increase your chance of me accepting your offer.'
            ) : (
              <>
                You swiped right. Send <strong>{username}</strong> your WYP Date Offer?
              </>
            )
          }
          confirmButtonLabel={ownProfile.isGenerous() ? 'Send Offer?' : 'Yes!'}
          onConfirm={doNotShowAgain => {
            onFirstSwipeRightConfirmAction?.(doNotShowAgain);
            resetModal();
          }}
          onCancel={() => {
            onFirstSwipeRightCancelAction?.();
            resetModal();
          }}
        />,
        () => {
          onFirstSwipeRightCancelAction?.();
          resetModal();
        }
      );
    },
    showFirstSwipeLeftConfirmation: (actions, username) => {
      const { onFirstSwipeLeftCancelAction, onFirstSwipeLeftConfirmAction } = actions;

      showConfirmationModal(
        <DashboardDiscoverModeActionConfirmation
          title="Pass?"
          desc={
            <p>
              Swiping left on <strong>{username}</strong> means {`you're`} not interested.
            </p>
          }
          confirmButtonLabel="Confirm?"
          onConfirm={doNotShowAgain => {
            onFirstSwipeLeftConfirmAction?.(doNotShowAgain);
            resetModal();
          }}
          onCancel={() => {
            onFirstSwipeLeftCancelAction?.();
            resetModal();
          }}
        />,
        () => {
          onFirstSwipeLeftCancelAction?.();
          resetModal();
        }
      );
    },
    initDiscoverConfirmationModal,
  };
};

export default useFirstSwipeConfirmationModal;
