import React, { memo, useMemo, Dispatch, SetStateAction } from 'react';
import ModalWithPattern from './ModalWithPattern';
import SvgQuestion from '../../../atoms/icons/SvgQuestion';
import styled from '@emotion/styled';
import { isDarkMode, IsMobileViewPort } from '../../../utils/helpers';
import { dimensions, patternModal } from '../../../style';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import useEvidently from '../../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../../constants/evidently';
import { mixpanelTrackWhatsAnOpeningOfferModalShown } from '../../../utils/mixpanel/infoModalShown';

const OpeningOfferContainer = styled('div')({
  display: 'initial',
});
const OpeningOfferDescContainer = styled('div')({
  marginBottom: 28,
  [dimensions.SCREEN_MAX_SM]: {
    paddingLeft: 35,
    paddingRight: 35,
  },
});

const StyledSpan = styled('span')({
  fontSize: 13,
  lineHeight: '16px',
  fontWeight: 400,
  cursor: 'pointer',
  color: '#8392A7',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2490DE',
  },
});

const StyledLinkSpan = styled('span')({
  fontSize: 14,
  lineHeight: '17px',
  fontWeight: 400,
  cursor: 'pointer',
  color: '#8392A7',
  marginTop: 4,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  maxHeight: 600,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

const FirstDateModalBodyContainer = styled('div')({
  textAlign: 'center',
  marginTop: 20,
});

const ButtonActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 10,
});

const TitleContainer = styled('h3')({
  fontStyle: 'normal',
  fontWeight: 700,
  color: patternModal.titleColor,
  fontSize: IsMobileViewPort ? 22 : 22,
  lineHeight: '35px',
  letterSpacing: '-0.44px',
  marginTop: 25,
  marginBottom: 20,
  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 35,
  },
});

const ContentList = styled('ul')({
  listStyleType: 'disc',
  lineHeight: '24px',
  minHeight: 100,
  paddingLeft: 20,
});

const ContentListItem = styled('li')({
  textAlign: 'left',
  paddingBottom: 10,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '23px',
});

interface OpeningOfferModalProps {
  customStyle?: React.CSSProperties;
  iconOnly?: boolean;
  linkStyle?: Record<string, unknown>;
  onClose?(): void;
  svgIconHeight?: number;
  svgIconWeight?: number;
  openEditOffer: any;
  currencySymbols: any;
  showOpeningOffer: boolean;
  setShowOpenningOffer: Dispatch<SetStateAction<boolean>>;
}

const OpeningOfferModal = ({
  iconOnly = false,
  linkStyle = {},
  svgIconWeight = 14,
  svgIconHeight = 14,
  customStyle = {},
  openEditOffer,
  currencySymbols,
  showOpeningOffer,
  setShowOpenningOffer,
}: OpeningOfferModalProps): JSX.Element => {
  const { useActiveVariation } = useEvidently();
  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const handleOnLinkClick = e => {
    e.preventDefault();

    if (!showOpeningOffer) {
      setShowOpenningOffer(true);
      mixpanelTrackWhatsAnOpeningOfferModalShown();
    }
  };

  const handleCloseModal = () => {
    setShowOpenningOffer(false);
  };

  return (
    <OpeningOfferContainer style={customStyle}>
      <StyledSpan style={linkStyle} onClick={e => handleOnLinkClick(e)}>
        <SvgQuestion
          viewBox="0 0 512 500"
          styles={{
            fill: isDarkMode() ? '#71B5E4' : '#2490DE',
            height: svgIconHeight,
            width: svgIconWeight,
          }}
        />{' '}
        {iconOnly ? '' : 'What’s this?'}
      </StyledSpan>
      <ModalWithPattern
        dataTestID="first-date-offer-tutorial-modal"
        isModalOpen={showOpeningOffer}
        modalContentLabel="First Date Offer Tutorial"
        closeOnOverlayClick
        closeRequest={handleCloseModal}
        closeBtnClassName="first-date-offer-tutorial-close"
        closeBtnDataTestId="first-date-offer-tutorial-close"
        onCloseClick={handleCloseModal}
        customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
        customContentStyle={IsMobileViewPort ? mobileContentStyle : { maxWidth: 345 }}
      >
        <FirstDateModalBodyContainer>
          <TitleContainer>{`What is an Opening Offer?`}</TitleContainer>
          <OpeningOfferDescContainer>
            <ContentList>
              <ContentListItem>The default offer amount shown on your profile.</ContentListItem>
              <ContentListItem>
                {`Your opening offer amount can be anywhere from ${currencySymbols.currencyLabel}${defaultMinOffer} to ${currencySymbols.currencyLabel}500.`}
              </ContentListItem>
              <ContentListItem>
                Any offers you get that are less than this amount can still be countered by you, giving you the final
                say.
              </ContentListItem>
            </ContentList>
          </OpeningOfferDescContainer>
          <ButtonActionContainer>
            <PrimaryButton
              color="blue"
              size="small"
              data-test-id="close-first-date-offer-modal"
              onClick={handleCloseModal}
              label="Got it"
              customStyle={{ marginBottom: 10, width: 86 }}
            />
            <StyledLinkSpan
              onClick={() => {
                openEditOffer();
              }}
            >
              Edit My Opening Offer
            </StyledLinkSpan>
          </ButtonActionContainer>
        </FirstDateModalBodyContainer>
      </ModalWithPattern>
    </OpeningOfferContainer>
  );
};

export default memo(OpeningOfferModal);
