import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
  page: 'Login',
};

export const enum LoginViewVysionEventsEnum {
  VIEW_ENTER_VERIFICATION = 'enterVerification',
  VIEW_INCORRECT_CODE = 'incorrectCode',
  VIEW_INCORRECT_CODE_NO_MORE_ATTEMPTS = 'incorrecCodeNoMoreAttempts',
  VIEW_REQUEST_ATTEMPTS_EXCEEDED = 'requestAttemptsExceeded',
}

export const loginViewVysionEvents = {
  [LoginViewVysionEventsEnum.VIEW_ENTER_VERIFICATION]: {
    ...commonValues,
    eventName: 'login-enterVerification',
    component: 'Enter Verification Code',
  },
  [LoginViewVysionEventsEnum.VIEW_INCORRECT_CODE]: {
    ...commonValues,
    eventName: 'login-incorrectCode',
    component: 'Incorrect Code Modal',
  },
  [LoginViewVysionEventsEnum.VIEW_INCORRECT_CODE_NO_MORE_ATTEMPTS]: {
    ...commonValues,
    eventName: 'login-incorrectCode',
    component: 'Incorrect Code Modal',
    extraInfo: 'No More Attempts',
  },
  [LoginViewVysionEventsEnum.VIEW_REQUEST_ATTEMPTS_EXCEEDED]: {
    ...commonValues,
    eventName: 'login-requestAttemptsExceeded',
    component: 'Request Attempts Exceeded',
  },
};
