import React, { ReactElement } from 'react';

const StatusCaution = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="#FF7C00">
        <path d="M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z M14,11 C15.6568542,11 17,9.65685425 17,8 C17,6.34314575 15.6568542,5 14,5 C12.3431458,5 11,6.34314575 11,8 C11,9.65685425 12.3431458,11 14,11 Z M6,11 C7.65685425,11 9,9.65685425 9,8 C9,6.34314575 7.65685425,5 6,5 C4.34314575,5 3,6.34314575 3,8 C3,9.65685425 4.34314575,11 6,11 Z M6,14 L6,15 L14,15 L14,14 L6,14 Z" />
        <circle cx="14" cy="8" r="1" />
        <circle cx="6" cy="8" r="1" />
      </g>
    </svg>
  );
};

export default StatusCaution;
