import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import colors from '../../theme/colors';
import { connect } from 'react-redux';
import {
  NotificationBannerItem,
  NotificationBannerState,
  NotificationBannerType,
} from '../../reducers/notificationBanners.ts';
import { getBrowserDetails } from '../../utils/helpers';
import useNotificationBanner from '../../hooks/useNotificationBanner.tsx';

// Todo: Fix css to show multiple banners at a time

const styles = ({
  bannerType,
  topPosition,
  isVisible,
}: {
  bannerType: NotificationBannerType;
  topPosition: number;
  isVisible: boolean;
}) => {
  return css`
    position: fixed;
    top: ${topPosition}px;
    left: ${getBrowserDetails().isMobile ? 'inherit' : '35%'};
    max-width: ${getBrowserDetails().isMobile ? 'inherit' : '480px'};
    width: 100%;
    margin: 0 auto;
    z-index: ${getBrowserDetails().isMobile ? '1001' : '1000'};
    color: ${colors.white};
    text-align: center;
    font-size: 15px;
    opacity: ${isVisible ? 1 : 0};
    transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;
    background: ${bannerType === 'success'
      ? colors.light.green.primary
      : bannerType === 'warning'
      ? colors.light.warning.primary
      : colors.light.warning.primary};
  `;
};

const NotificationBannerContainer = styled.div`
  ${styles};
`;

interface IAutoDismissNotificationBannerProps {
  notificationBanners: NotificationBannerState;
}

interface IAutoDismissNotificationBanner {
  notificationBanner: NotificationBannerItem;
}

const NotificationBanner: React.FC<IAutoDismissNotificationBanner> = ({ notificationBanner }) => {
  const { id, type, message, hideTimeout = 3000 } = notificationBanner;
  const conditionedTimeOut = process.env.REACT_APP_ENV === 'testqa' ? 7000 : hideTimeout;

  const noHeaderPages = ['/billing', '/payment', '/profile'];
  const isPageNoHeader = noHeaderPages.filter(item => document.location.pathname.indexOf(item) > -1).length > 0;
  const visibleTopPosition = isPageNoHeader && getBrowserDetails().isMobile ? 0 : getBrowserDetails().isMobile ? 0 : 65;
  const invisibleTopPosition = -50;
  const [isVisible, setIsVisible] = useState(true);
  const [topPosition, setTopPosition] = useState(invisibleTopPosition);
  const { removeNotificationBanner } = useNotificationBanner();

  useEffect(() => {
    const setVisibleTimer = setTimeout(() => {
      setIsVisible(true);
      setTopPosition(visibleTopPosition);
    }, 300);

    const setInvisibleTimer = setTimeout(() => {
      setTopPosition(invisibleTopPosition);
    }, conditionedTimeOut + 1000);

    const removeBannerTimer = setTimeout(() => {
      setIsVisible(false);
      removeNotificationBanner(id);
    }, conditionedTimeOut + 2000);

    return () => {
      clearTimeout(setVisibleTimer);
      clearTimeout(setInvisibleTimer);
      clearTimeout(removeBannerTimer);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <NotificationBannerContainer id={id} bannerType={type} topPosition={topPosition} isVisible={isVisible}>
      {typeof message === 'string' ? (
        <div
          style={{
            padding: 11,
          }}
        >
          {message}
        </div>
      ) : (
        message
      )}
    </NotificationBannerContainer>
  );
};

const NotificationBanners: React.FC<IAutoDismissNotificationBannerProps> = ({ notificationBanners }) => {
  return (
    <>
      {notificationBanners.map((notificationBanner, index) => (
        <NotificationBanner key={index} notificationBanner={notificationBanner} />
      ))}
    </>
  );
};

const mapStateToProps = state => {
  return {
    notificationBanners: state.notificationBanners,
  };
};

export default connect(mapStateToProps, null)(NotificationBanners);
