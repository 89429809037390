import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setSearchVysion = (param: any): void => {
  switch (param.event) {
    case 'APPLY FILTERS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'search-applyFilters');
      break;
    case 'RESET FILTERS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'search-resetFilters');
      break;
    default:
      return;
  }
};

export { setSearchVysion };
