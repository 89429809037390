import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from './buttons/Button';
import ConfirmationModal from '../modules/modals/ConfirmationModal';
import EditableBiometricsItem from './EditableBiometricsItem';
import { dimensions, spacing, type, borders, color, textColor, textType } from '../style';

const StyledContainer = styled('div')({
  ...textColor.DARKER,
  display: 'flex',
  alignItems: 'center',
  ...spacing.PADDING_MD,
  borderBottom: borders.DEFAULT,

  [dimensions.SCREEN_MIN_MD]: {
    ':hover': {
      backgroundColor: color.GREY_LIGHTEST,
    },
  },

  [dimensions.SCREEN_MAX_SM]: {
    ...spacing.PADDING_SM,
  },
});

const User = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const Title = styled('div')({
  ...type.title.XS,
});

const ButtonContainer = styled('div')({
  flex: '0 0 auto',
});

const EditDeviceName = styled('a')({
  paddingBottom: 4,
  paddingLeft: 5,
  fontSize: textType.text.fontSize,
  fontWeight: textType.text.fontWeight,
  textDecoration: 'underline',
  color: color.BRAND_2,
});

interface BiometricListItemProps {
  name: any;
  removeThisCredential: any;
  handleChangeName: any;
  biometricId: any;
}

const BiometricListItem = ({
  name,
  removeThisCredential,
  handleChangeName,
  biometricId,
}: BiometricListItemProps): ReactElement => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deviceName, setDeviceName] = useState(name);
  const [isEdit, setIsEdit] = useState(false);

  const toggleConfirmModalState = status => {
    setConfirmModalOpen(status);
  };

  const onChangeName = val => {
    setDeviceName(val);
  };

  const toggleEditable = e => {
    e.preventDefault();
    setIsEdit(true);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setDeviceName(name);
  };

  const submitEdit = async name => {
    const resStatus = await handleChangeName(name, biometricId);

    if (resStatus === 422) {
      onChangeName(deviceName);
    }
    if (resStatus === 200) {
      onChangeName(name);
      setIsEdit(false);
    }
  };

  return (
    <StyledContainer>
      <User>
        {isEdit ? (
          <EditableBiometricsItem handleSubmit={submitEdit} handleCancel={cancelEdit} deviceName={deviceName} />
        ) : (
          <Title>
            {deviceName}
            <EditDeviceName href="" onClick={toggleEditable}>
              Edit
            </EditDeviceName>
          </Title>
        )}
      </User>
      {!isEdit && (
        <ButtonContainer>
          <Button buttonType="error" onClick={() => toggleConfirmModalState(true)}>
            Remove Device
          </Button>
        </ButtonContainer>
      )}
      <ConfirmationModal
        isOpen={confirmModalOpen}
        description="Do you really want to remove this device from the list?"
        cancelText="Cancel"
        confirmText="Proceed"
        contentLabel="Remove device biometric"
        onClose={() => toggleConfirmModalState(false)}
        onConfirm={removeThisCredential}
      />
    </StyledContainer>
  );
};

BiometricListItem.propTypes = {
  name: PropTypes.string.isRequired,
  removeThisCredential: PropTypes.func.isRequired,
};

export default BiometricListItem;
