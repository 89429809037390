import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

interface UsernameInputProps {
  inputLabel: any;
  value: any;
  error: any;
  status: any;
  onChange: any;
  customStyleLabel: any;
  customInputStyle: any;
  isLogin: boolean;
  autoFocus: boolean;
  customStyle: any;
}

const UsernameInput = ({
  inputLabel,
  value,
  error,
  status,
  onChange,
  customStyleLabel,
  customInputStyle = {},
  isLogin = false,
  autoFocus = false,
  customStyle = {},
}: UsernameInputProps): ReactElement => {
  const [fieldError, setFieldError] = useState('');

  const handleOnTextChange = e => {
    const inputValue = e.target.value;
    let inputError =
      inputValue.length < 3 || inputValue.length > 14 ? 'Display name must be 3 to 14 characters long.' : null;

    if (!inputValue.match(/^[a-zA-Z0-9]+$/)) {
      inputError = 'Your username cannot have any special characters or spaces.';
    }
    setFieldError(inputError);
    onChange(e);
  };

  return (
    <TextInput
      id="username"
      type="text"
      inputLabel={inputLabel}
      name="username"
      value={value || ''}
      onChange={handleOnTextChange}
      onBlur={handleOnTextChange}
      status={(fieldError && 'error') || status}
      statusMessage={fieldError || error}
      customStyle={{ ...customStyle, maxWidth: '100%' }}
      customInputStyle={customInputStyle}
      customStyleLabel={customStyleLabel}
      isLogin={isLogin}
      autoFocus={autoFocus}
    />
  );
};

UsernameInput.propTypes = {
  status: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default UsernameInput;
