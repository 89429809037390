import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Toggle from '../../../../../atoms/toggle/Toggle';
import { getLocalItem, removeLocalItem, setLocalItem } from '../../../../../common';
import { unsubscribeWebPush } from '../../../../../sdk';
import { checkPwaTrue } from '../../../../../utils/helpers';
import { register, unregister } from '../../../../../utils/serviceWorker';

const ClickableLink = styled('a')({
  color: 'rgb(19, 131, 210)',
  fontWeight: 'bold',
});

const deniedMessage = () => {
  return (
    <React.Fragment>
      You have turned off desktop notifications and must grant permission in your browser (
      <ClickableLink target="_blank" href="https://support.google.com/chrome/answer/3220216">
        link to instructions
      </ClickableLink>
      ) to see them.
    </React.Fragment>
  );
};

const verifyPermissionState = permission => {
  switch (permission) {
    case 'granted':
      return false;
    case 'denied':
      return deniedMessage();
    default:
      return 'Grant permission to show desktop notifications.';
  }
};

interface SliderDesktopNotificationProps {
  status: any;
  toggleDesktopNotification: any;
}

const SliderDesktopNotification = ({ status, toggleDesktopNotification }: SliderDesktopNotificationProps): any => {
  const notifications = window.Notification;
  const [toggle, setToggle] = useState(false);
  const [permissionMsg, setPermissionMsg] = useState('');

  useEffect(() => {
    if (notifications) {
      const getPermissionState = verifyPermissionState(notifications.permission);
      getPermissionState === false ? setToggle(status) : setPermissionMsg(getPermissionState);
    }
  }, [status, notifications]);

  const unsubscribePush = async () => {
    const currentSubscription = getLocalItem('pushSubscription');
    if (currentSubscription !== null) {
      const unsubRes = await unsubscribeWebPush(currentSubscription);

      if (!(unsubRes.data && unsubRes.data.success)) {
        if (unsubRes.data && unsubRes.data.message === 'no subscription found' && currentSubscription) {
          // force remove pushSubscription in local storage to allow to re-register
          removeLocalItem('pushSubscription');
        } else {
          console.log('An error occurred');
        }
      } else {
        removeLocalItem('pushSubscription');
      }
    }
  };

  const subscribePush = async () => {
    checkPwaTrue() ? register() : unregister();
  };

  const handleToggleDesktopNotification = async () => {
    let newStatus = !status; // negate previous status to toggle value
    let permission = notifications.permission;

    if (permission === 'default') {
      permission = await notifications.requestPermission();
      newStatus = permission === 'granted';
      setPermissionMsg('');
    }

    toggleDesktopNotification(newStatus);

    if (newStatus) {
      setLocalItem('wyp_desktop_notification', true);
      await subscribePush();
    } else {
      setLocalItem('wyp_desktop_notification', false);
      await unsubscribePush();
    }

    const getPermissionState = verifyPermissionState(permission);
    getPermissionState === false ? setToggle(newStatus) : setPermissionMsg(getPermissionState);
  };

  return (
    <Toggle
      toggleId="desktop_notification"
      isChecked={toggle}
      onChange={handleToggleDesktopNotification}
      showBoth={notifications && notifications.permission === 'default'}
      showText={permissionMsg}
    />
  );
};

export default SliderDesktopNotification;
