import React, { useState, useEffect, ReactElement } from 'react';
import './toggle.css';

const sliderDisabled = isChecked => {
  return {
    backgroundColor: isChecked ? '#BBD7B9' : '#EAEAE4',
    boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 28%)',
  };
};

const toggleStyle = (disabled, isToggle) => (disabled ? sliderDisabled(isToggle) : {});

interface ToggleProps {
  isChecked: any;
  toggleId: any;
  disabled: any;
  showText: any;
  showBoth: any;
}

const Toggle = ({ isChecked, toggleId, disabled, showText, showBoth, ...rest }: ToggleProps): ReactElement => {
  const [isToggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(isChecked);
  }, [isChecked]);

  const ToggleWithText = () => {
    return showBoth ? (
      <div>
        <div>
          <label className="switch" style={{ float: 'right' }}>
            <input id={toggleId} type="checkbox" value={isToggle} checked={isToggle} disabled={disabled} {...rest} />
            <div className="toggle" style={toggleStyle(disabled, isToggle)}></div>
          </label>
          {showText}
        </div>
      </div>
    ) : (
      <div>{showText}</div>
    );
  };

  return (
    <div>
      {!showText || showText === '' ? (
        <label className="switch">
          <input id={toggleId} type="checkbox" value={isToggle} checked={isToggle} disabled={disabled} {...rest} />
          <div className="toggle" style={toggleStyle(disabled, isToggle)}></div>
        </label>
      ) : (
        ToggleWithText()
      )}
    </div>
  );
};

export default Toggle;
