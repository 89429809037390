import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import MultilevelSidebar from './MultilevelSidebar';
import { ToggleV2 } from 'swui';
import SliderDesktopNotification from './SidebarToggle/SliderDesktopNotification';
import SliderGoogleToggle from './SidebarToggle/SliderGoogleToggle';
import PopupForSecurity from './SidebarToggle/PopupForSecurity';
import RequestForInformation from './SidebarToggle/RequestForInformation';
import SidebarHeader from './SidebarHeader';
import { getBrowserDetails, getCookie, removeCookie, setCookie, termsOfUseUrl } from '../../../../utils/helpers';
import { SvgIcon } from '@material-ui/core';
import Button from '../../../../atoms/buttons/Button';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isUVPAA } from '../../../../utils/webAuthn';
import {
  ExitToAppOutlined,
  NotificationsActiveOutlined,
  SecurityOutlined,
  SettingsOutlined,
  HelpOutlineOutlined,
  PanToolOutlined,
  SupervisedUserCircleOutlined,
  FingerprintOutlined,
  VpnKeyOutlined,
  ImportExportOutlined,
  UpdateOutlined,
  ContactPhoneOutlined,
  LaunchOutlined,
  RemoveCircleOutlineOutlined,
  LocalPhone,
  BlockOutlined,
} from '@material-ui/icons';
import UserNavItem from '../UserNavItem';
import MasterConfig, { nuxEnabled } from '../../../../config/Master';
import 'react-multilevel-sidebar/src/Sidebar.css';
import './sidebar.css';
import { backgroundTheme, buttonTheme } from '../../../../style';
import { sendPurchaseAccountMenuBuyCreditAction } from '../../../../actions/purchaseActions';
import PurchaseIDV from '../../../../modules/PurchaseIDV';
import useMixPanel from '../../../../hooks/useMixPanel';
import PaymentSettingsIcon from '../../../../atoms/icons/PaymentSettingsIcon';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import EditPhoneForm from '../../../phone/phoneSettings/EditPhoneForm';
import DeactivateAccount from './DeactivateAccount/DeactivateAccount';
import { handleDeactivateAccountVysionEvent } from '../../../../utils/vysion/deactivateAccount';
import { DeactivateAccountClickVysionEventsEnum } from '../../../../utils/vysion/deactivateAccount/click';
import { DeactivateAccountViewVysionEventsEnum } from '../../../../utils/vysion/deactivateAccount/view';
import { VYSION_ACTIONS } from '../../../../utils/vysion';
import { useApp } from '../../../../contexts/AppContext';
import PWASidebarBanner from '../../../../components/pwa/PWASidebarBanner';
import SecondaryButton from '../../../../atoms/buttons/SecondaryButton';
import { resetHiddenDialogs } from '../../../../actions/profileActions';
import { connect } from 'react-redux';
import { doNotShowDeclineOfferConfirmationKey } from '../../../../hooks/useModal';
import useNotificationBanner from '../../../../hooks/useNotificationBanner';
import { toggleDarkMode } from '../../../../sdk/SettingsSDK';
import { ALLOW_THEME_COOKIE_NAME } from '../../../../config/constants';
import { mixpanelTrackToggledHideMeFromSearchResults } from '../../../../utils/mixpanel/toggledHideMeFromSearchResults';
import { mixpanelTrackToggleHideMyOnlineStatusAndLoginDate } from '../../../../utils/mixpanel/toggleHideMyOnlineStatusAndLoginDate';
import { mixpanelTrackToggleHideProfileViews } from '../../../../utils/mixpanel/toggleHideProfileView';
import { mixpanelTrackDarkModeEnabled } from '../../../../utils/mixpanel/darkModeEnabled';

const myWidth = () => {
  return getBrowserDetails().isMobile && !getBrowserDetails().isTablet ? screen.width : 428;
};

const commonStyles = {
  fontSize: 26,
  color: '#0D5C93',
  stroke: '#0D5C93',
  strokeWidth: '0.5px',
};

const UserNavItemContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 1,
});

const parentMenuItems = [1, 2, 11, 20, 31, 38, 40, 42];

const mapArrayToObj = arr => {
  return arr.reduce((map, obj) => {
    map[obj.key] = obj.val;
    return map;
  }, {});
};

const StyledButton = {
  height: 36,
  color: buttonTheme.buttonText,
  backgroundColor: backgroundTheme.creditColor,
};

const ResetBtnStyle = {
  color: '#2B8FD7',
  backgroundColor: '#fff',
  border: '1px solid #2B8FD7',
  fontWeight: 600,
  fontSize: 13,
  padding: 8,
  height: 30,
};

const StyledToggle = styled(ToggleV2)({
  height: 28,
  width: 54,
});

const options = (
  objOptions,
  trackPackagesPageVisited,
  hashId: string,
  isAccountIsMixpanelEligableEnabled: boolean,
  resetHiddenDialogs: () => void
) => {
  const permission = window.Notification ? window.Notification.permission : false;
  const videoCall = objOptions['settings'].video_call;

  return [
    {
      content: objOptions['canUpgrade']
        ? [
            {
              id: 1,
              name: <b>{`${objOptions['credits']} Credits`}</b>,
              icon: (
                <Button
                  customStyle={StyledButton}
                  buttonType="error"
                  onClick={() => {
                    sendPurchaseAccountMenuBuyCreditAction();
                    trackPackagesPageVisited({ Source: 'Buy Credits Menu' });
                  }}
                >
                  Buy Credits
                </Button>
              ),
              to: '/packages',
              link: true,
            },
          ]
        : [{ hidden: true }],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 40,
          name: 'Dark Mode',
          icon: (
            <StyledToggle
              id="dark_mode"
              checked={objOptions['allowDarkMode'] ? objOptions['allowDarkMode'] : false}
              onChange={objOptions['allowThemeOS']}
              defaultValue={false}
            />
          ),
        },
        {
          id: 2,
          name: 'Notifications',
          icon: <SvgIcon component={NotificationsActiveOutlined} style={commonStyles} />,
          children: [
            {
              title: 'App Notifications',
              hideBorder: true,
              content: [
                permission
                  ? {
                      id: 3,
                      name: permission === 'granted' ? 'Push Notifications' : '',
                      icon: (
                        <SliderDesktopNotification
                          status={objOptions['settings'].desktopNotificationStatus}
                          toggleDesktopNotification={objOptions['toggleDesktopNotificationStatus']}
                        />
                      ),
                    }
                  : { hidden: true },
                {
                  id: 4,
                  name: 'Getting Favorited',
                  icon: (
                    <StyledToggle
                      id="alert_favorite"
                      checked={objOptions['settings'].alert_favorite ? objOptions['settings'].alert_favorite : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                {
                  id: 5,
                  name: 'New Offers',
                  icon: (
                    <StyledToggle
                      id="alert_offer"
                      checked={objOptions['settings'].alert_offer ? objOptions['settings'].alert_offer : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                {
                  id: 6,
                  name: 'New Messages',
                  icon: (
                    <StyledToggle
                      id="alert_mail"
                      checked={objOptions['settings'].alert_mail ? objOptions['settings'].alert_mail : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                nuxEnabled
                  ? {
                      id: 43,
                      name: 'Reset Guided Tour',
                      icon: (
                        <Button customStyle={ResetBtnStyle} buttonType="chromeless" onClick={objOptions['resetNux']}>
                          Reset
                        </Button>
                      ),
                      link: true,
                    }
                  : { hidden: true },
                {
                  id: 44,
                  name: 'Reset Hidden Dialogs',
                  icon: (
                    <SecondaryButton
                      label="Reset"
                      onClick={resetHiddenDialogs}
                      color="blue"
                      size="x-small"
                      customStyle={{
                        width: 70,
                      }}
                    />
                  ),
                  link: true,
                },
              ],
            },
            {
              title: 'Email Me',
              hideBorder: true,
              content: [
                {
                  id: 7,
                  name: 'New Members',
                  icon: (
                    <StyledToggle
                      id="notify_new_members"
                      checked={
                        objOptions['settings'].notify_new_members ? objOptions['settings'].notify_new_members : false
                      }
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                {
                  id: 8,
                  name: 'Favorites',
                  icon: (
                    <StyledToggle
                      id="notify_favorite"
                      checked={objOptions['settings'].notify_favorite ? objOptions['settings'].notify_favorite : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                {
                  id: 9,
                  name: 'New Offers',
                  icon: (
                    <StyledToggle
                      id="notify_offer"
                      checked={objOptions['settings'].notify_offer ? objOptions['settings'].notify_offer : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
                {
                  id: 10,
                  name: 'New Messages',
                  icon: (
                    <StyledToggle
                      id="notify_message"
                      checked={objOptions['settings'].notify_message ? objOptions['settings'].notify_message : false}
                      onChange={objOptions['updateAccountSetting']}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 11,
          name: 'Privacy & Safety',
          icon: <SvgIcon component={SecurityOutlined} style={commonStyles} />,
          children: [
            {
              title: 'Visibility',
              hideBorder: true,
              content: [
                {
                  id: 12,
                  name: 'Hide me from Search Results',
                  icon: (
                    <StyledToggle
                      id="hidden"
                      checked={objOptions['settings'].hidden ? objOptions['settings'].hidden : false}
                      onChange={e => {
                        mixpanelTrackToggledHideMeFromSearchResults(
                          {
                            Status: objOptions['settings'].hidden ? 'Deactivated' : 'Activated',
                          },
                          { 'Hidden from Search': !objOptions['settings'].hidden }
                        );

                        objOptions['updateAccountSetting'](e);
                      }}
                    />
                  ),
                },
                {
                  id: 13,
                  name: 'Hide my online status & login date',
                  icon: (
                    <StyledToggle
                      id="hidden_last_login_dt"
                      checked={
                        objOptions['settings'].hidden_last_login_dt
                          ? objOptions['settings'].hidden_last_login_dt
                          : false
                      }
                      onChange={e => {
                        mixpanelTrackToggleHideMyOnlineStatusAndLoginDate(
                          {
                            Status: objOptions['settings'].hidden_last_login_dt ? 'Deactivated' : 'Activated',
                          },
                          { 'Online status & login date hidden': !objOptions['settings'].hidden_last_login_dt }
                        );

                        objOptions['updateAccountSetting'](e);
                      }}
                    />
                  ),
                },
                {
                  id: 14,
                  name: 'Hide profile views',
                  icon: (
                    <StyledToggle
                      id="hidden_view"
                      checked={objOptions['settings'].hidden_view ? objOptions['settings'].hidden_view : false}
                      onChange={e => {
                        mixpanelTrackToggleHideProfileViews(
                          {
                            Status: objOptions['settings'].hidden_view ? 'Deactivated' : 'Activated',
                          },
                          { 'Profile views hidden': !objOptions['settings'].hidden_view }
                        );

                        objOptions['updateAccountSetting'](e);
                      }}
                    />
                  ),
                },
              ],
            },
            {
              title: 'Video Calls',
              hideBorder: true,
              content: [
                {
                  id: 15,
                  name: 'Block incoming video call',
                  icon: (
                    <StyledToggle
                      id="block_incoming"
                      checked={videoCall ? videoCall.block_incoming : false}
                      onChange={objOptions['updateVideoSetting']}
                    />
                  ),
                },
                {
                  id: 16,
                  name: `Only receive calls from people I've conversed with`,
                  icon: (
                    <StyledToggle
                      id="have_conversed"
                      checked={videoCall ? videoCall?.have_conversed ?? false : false}
                      onChange={objOptions['updateVideoSetting']}
                    />
                  ),
                },
              ],
            },
            {
              title: 'Dating Safely',
              hideBorder: true,
              content: [
                {
                  id: 17,
                  name: 'Blocked Members List',
                  icon: <SvgIcon component={SecurityOutlined} style={commonStyles} />,
                  to: '/blocked',
                  link: true,
                },
                {
                  id: 18,
                  name: 'Reporting Abuse',
                  icon: <SvgIcon component={PanToolOutlined} style={commonStyles} />,
                  to: '/support/contact',
                  link: true,
                },
                {
                  id: 19,
                  name: 'Safe Dating Tips',
                  icon: <SvgIcon component={SupervisedUserCircleOutlined} style={commonStyles} />,
                  to: `${MasterConfig.FULL_URL}/blog/tips-for-dating-safely-during-covid-and-after`,
                  link: true,
                  fullurl: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 20,
          name: 'Account Settings',
          key: 'account-settings',
          icon: <SvgIcon component={SettingsOutlined} style={commonStyles} />,
          children: [
            {
              title: 'Login Options',
              hideBorder: true,
              content: [
                {
                  id: 21,
                  name: 'Login with Google',
                  icon: (
                    <SliderGoogleToggle
                      isDisabled={objOptions['requiredVerification']}
                      needsAccountPassword={objOptions['needsAccountPassword']}
                      social={objOptions['settings'].social}
                      attachSocialLogin={objOptions['attachSocialLogin']}
                      handleSidebarToggle={objOptions['handleSidebarToggle']}
                      menuOpen={objOptions['menuOpen']}
                    />
                  ),
                },
                isUVPAA()
                  ? {
                      id: 22,
                      name: 'Biometric Sign In',
                      icon: <SvgIcon component={FingerprintOutlined} style={commonStyles} />,
                      to: '/biometrics',
                      link: true,
                    }
                  : { hidden: true },
                {
                  id: 23,
                  name: '',
                  icon: (
                    <PopupForSecurity
                      handleSidebarToggle={objOptions['handleSidebarToggle']}
                      destroyOtherDeviceSession={objOptions['destroyOtherDeviceSession']}
                      menuOpen={objOptions['menuOpen']}
                    />
                  ),
                },
                {
                  id: 41,
                  name: 'Change Phone Number',
                  hasSecondChild: true,
                  icon: <SvgIcon component={LocalPhone} style={commonStyles} />,
                  children: [
                    {
                      title: '',
                      hideBorder: true,
                      isCustomComponent: true,
                      content: <EditPhoneForm />,
                    },
                  ],
                },
                {
                  id: 24,
                  name: `${objOptions['needsAccountPassword'] ? 'Create' : 'Change'} Email & Password`,
                  icon: <SvgIcon component={VpnKeyOutlined} style={commonStyles} />,
                  to: '/settings/email-password',
                  link: true,
                },
              ],
            },
            {
              title: 'Account History',
              hideBorder: true,
              content: [
                {
                  id: 25,
                  name: 'Canceled Offers',
                  icon: <SvgIcon component={ImportExportOutlined} style={commonStyles} />,
                  to: '/cancelled-offers',
                  link: true,
                },
                {
                  id: 26,
                  name: 'Purchase History',
                  icon: <SvgIcon component={UpdateOutlined} style={commonStyles} />,
                  to: '/credits',
                  link: true,
                },
              ],
            },

            {
              title: 'Data Protection',
              hideBorder: true,
              content: [
                {
                  id: 28,
                  name: '',
                  icon: (
                    <RequestForInformation
                      userInfo={objOptions['settings'].userInfo}
                      requestUserInfo={objOptions['requestUserInfo']}
                    />
                  ),
                },
                {
                  id: 29,
                  name: 'Revoke Consent',
                  icon: <SvgIcon component={RemoveCircleOutlineOutlined} style={commonStyles} />,
                  to: '/gdpr-delete',
                  link: true,
                },
                {
                  id: 30,
                  name: 'Deactivate or Delete My Account',
                  title: 'Deactivate My Account',
                  key: 'deactivate-my-account',
                  hasSecondChild: true,
                  icon: (
                    <SvgIcon
                      component={BlockOutlined}
                      style={{ ...commonStyles, ...{ color: '#BC2028', stroke: '#BC2028' } }}
                    />
                  ),
                  children: [
                    {
                      title: '',
                      hideBorder: true,
                      isCustomComponent: true,
                      content: <DeactivateAccount isGenerous={objOptions['canUpgrade']} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      hideBorder: true,
      content: objOptions['canUpgrade']
        ? [
            {
              id: 42,
              name: 'Payment Settings',
              icon: <PaymentSettingsIcon />,
              children: [
                {
                  title: '',
                  hideBorder: true,
                  isCustomComponent: true,
                  content: (
                    <PaymentSettings
                      menuOpen={objOptions['menuOpen']}
                      settings={objOptions['settings']}
                      getRecurringPackages={objOptions['getRecurringPackages']}
                      subscribeRecurringPayment={objOptions['subscribeRecurringPayment']}
                      unSubscribeRecurringPayment={objOptions['unSubscribeRecurringPayment']}
                      getRecurringPayment={objOptions['getRecurringPayment']}
                      removeSavedToken={objOptions['removeSavedToken']}
                    />
                  ),
                },
              ],
            },
          ]
        : [{ hidden: true }],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 31,
          name: 'Help Center',
          icon: <SvgIcon component={HelpOutlineOutlined} style={commonStyles} />,
          children: [
            {
              title: 'Help',
              hideBorder: true,
              content: [
                {
                  id: 32,
                  name: 'Frequently Asked Questions',
                  icon: <SvgIcon component={HelpOutlineOutlined} style={commonStyles} />,
                  to: `${MasterConfig.FULL_URL}/faq`,
                  link: true,
                  fullurl: true,
                },
                {
                  id: 33,
                  name: 'Contact Support',
                  icon: <SvgIcon component={ContactPhoneOutlined} style={commonStyles} />,
                  to: '/support/contact',
                  link: true,
                },
              ],
            },
            {
              title: 'Site Information',
              hideBorder: true,
              content: [
                {
                  id: 34,
                  name: 'Privacy Policy',
                  icon: <SvgIcon component={LaunchOutlined} style={commonStyles} />,
                  to: `${MasterConfig.FULL_URL}/privacy`,
                  link: true,
                  fullurl: true,
                },
                {
                  id: 35,
                  name: 'Terms Of Use',
                  icon: <SvgIcon component={LaunchOutlined} style={commonStyles} />,
                  to: termsOfUseUrl(false),
                  link: true,
                  fullurl: true,
                },
                {
                  id: 36,
                  name: 'Community Guidelines',
                  icon: <SvgIcon component={LaunchOutlined} style={commonStyles} />,
                  to: `${MasterConfig.FULL_URL}/community-guidelines`,
                  link: true,
                  fullurl: true,
                },
                {
                  id: 37,
                  name: 'GDPR',
                  icon: <SvgIcon component={LaunchOutlined} style={commonStyles} />,
                  to: `https://gdpr-info.eu/`,
                  link: true,
                  fullurl: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 39,
          name: 'IDV Status',
          noLabel: true,
          icon: <PurchaseIDV account={objOptions['account']} desktopContinue sideBar />,
          link: false,
        },
      ],
    },
    {
      hideBorder: true,
      content: [
        objOptions['showAccountInfo'] &&
        objOptions['settings'].userInfo &&
        objOptions['settings'].userInfo.status === 'finished'
          ? {
              id: 39,
              icon: (
                <RequestForInformation
                  userInfo={objOptions['settings'].userInfo}
                  requestUserInfo={objOptions['requestUserInfo']}
                />
              ),
              hidden: false,
            }
          : {},
      ],
    },
    {
      hideBorder: true,
      content: [
        {
          id: 38,
          name: 'Logout',
          icon: <SvgIcon component={ExitToAppOutlined} style={commonStyles} />,
          to: '/logout',
          link: true,
        },
      ],
    },
    {
      hideBorder: true,
      isCustomComponent: true,
      content: <PWASidebarBanner />,
    },
  ];
};

interface SliderSidebarProps {
  avatar: any;
  credits: any;
  username: any;
  canUpgrade: any;
  settings: any;
  updateAccountSetting: any;
  updateVideoSetting: any;
  handleSidebarToggle: any;
  attachSocialLogin: any;
  menuOpen: any;
  navBarHeight: any;
  toggleDesktopNotificationStatus: any;
  destroyOtherDeviceSession: any;
  requestUserInfo: any;
  deleteGDPRForm: any;
  errors: any;
  clearNotificationMessage: any;
  clearErrors: any;
  validateCredentials: any;
  data: any;
  hashId: any;
  hasNotify: any;
  handleSidebarHide: any;
  getVideoCallSettings: any;
  getCheckInfoRequest: any;
  revertBackLastUpdate: any;
  updatingSettings: any;
  lastUpdate: any;
  isMobile: any;
  showAccountInfo: any;
  requiredVerification: any;
  resetNux: any;
  account: any;
  getRecurringPackages: any;
  subscribeRecurringPayment: any;
  unSubscribeRecurringPayment: any;
  getRecurringPayment: any;
  removeSavedToken: any;
  resetHiddenDialogsProp: () => void;
}

const SliderSidebar = ({
  avatar,
  credits,
  username,
  canUpgrade,
  settings,
  updateAccountSetting,
  updateVideoSetting,
  handleSidebarToggle,
  attachSocialLogin,
  menuOpen,
  navBarHeight,
  toggleDesktopNotificationStatus,
  destroyOtherDeviceSession,
  requestUserInfo,
  deleteGDPRForm,
  errors,
  clearNotificationMessage,
  clearErrors,
  validateCredentials,
  data,
  hashId,
  hasNotify,
  handleSidebarHide,
  getVideoCallSettings,
  getCheckInfoRequest,
  revertBackLastUpdate,
  updatingSettings,
  lastUpdate,
  isMobile,
  showAccountInfo,
  requiredVerification,
  resetNux,
  account,
  getRecurringPackages,
  subscribeRecurringPayment,
  unSubscribeRecurringPayment,
  getRecurringPayment,
  removeSavedToken,
  resetHiddenDialogsProp,
  ...rest
}: SliderSidebarProps): any => {
  // create a sidebar component so we can use our own hamburger icon and manage the cutsomization internally
  const [GDPR, setGDPR] = useState(false);
  const [allowDarkMode, setAllowDarkMode] = useState(false);
  const buyCredits = useRef(null);
  const { isAccountIsMixpanelEligableEnabled } = useApp();
  const { showNotificationBanner } = useNotificationBanner();

  const { trackPageView, trackPackagesPageVisited } = useMixPanel();

  useEffect(() => {
    // do componentDidMount logic
    checkDarkMode();
    getVideoCallSettings && getVideoCallSettings();
    getCheckInfoRequest && getCheckInfoRequest();
    buyCredits.current = document.getElementById(`${credits} Credits-Section`);
    if (buyCredits && buyCredits.current) {
      buyCredits.current.addEventListener(
        'click',
        e => {
          sendPurchaseAccountMenuBuyCreditAction(e);
          trackPackagesPageVisited({ Source: 'Buy Credits Menu' });
        },
        false
      );

      return () => {
        buyCredits.current.removeEventListener('click', () => null, false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDarkMode = () => {
    const allowTheme = getCookie(ALLOW_THEME_COOKIE_NAME);
    setAllowDarkMode(allowTheme === 'true');
  };

  const allowThemeOS = () => {
    setAllowDarkMode(!allowDarkMode);
    removeCookie(ALLOW_THEME_COOKIE_NAME);
    setCookie(ALLOW_THEME_COOKIE_NAME, !allowDarkMode, true);
    toggleDarkMode({ darkmode: !allowDarkMode }).then(() => {
      mixpanelTrackDarkModeEnabled({ 'Dark Mode Enabled': !allowDarkMode });

      setTimeout(() => {
        location.reload();
      }, 500);
    });
  };

  const handleClick = itemOptions => {
    if (itemOptions.id === 2) {
      trackPageView(true, 'menu_notifications');
    }
    if (itemOptions.id === 11) {
      trackPageView(true, 'menu_privacy_safety');
    }

    if (itemOptions.name === 'Deactivate or Delete My Account') {
      handleDeactivateAccountVysionEvent({
        event: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_ACCOUNT_SETTINGS_DEACTIVATE_OR_DELETE_MY_ACCOUNT,
      });

      /**
       * Put view event here
       * because it doesn't work when put in DeactivateAccount component
       * Technically, when this menu is clicked, the DeactivateAccount will show up
       */
      handleDeactivateAccountVysionEvent({
        event: DeactivateAccountViewVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE__ACCOUNT,
        action: VYSION_ACTIONS.VIEW,
      });
      trackPageView(false, 'deactivate_or_delete_my_account');
    }

    if (itemOptions.link) {
      handleSidebarToggle(!menuOpen);
    }
  };

  const handleGDPRRequest = () => {
    setGDPR(!GDPR);
  };

  const clearLastUpdate = itemOptions => {
    if (itemOptions.includes(2)) {
      trackPageView(false, 'menu_notifications');
    }
    if (itemOptions.includes(11)) {
      trackPageView(false, 'menu_privacy_safety');
    }
    GDPR && handleGDPRRequest();
    revertBackLastUpdate();
  };

  const unlockPage = () => {
    clearAllBodyScrollLocks();
  };

  const resetHiddenDialogs = () => {
    // Pass modal (Discover mode)
    // Send Offer modal (Discover mode)
    resetHiddenDialogsProp();

    /// Decline modal:
    localStorage.removeItem(doNotShowDeclineOfferConfirmationKey);

    showNotificationBanner({ type: 'success', message: 'All dialogs have been reset' });
  };

  return (
    <UserNavItemContainerStyled>
      <UserNavItem
        credits={credits}
        clickHandler={handleSidebarToggle}
        navHeight={navBarHeight}
        username={username}
        avatar={avatar}
        canUpgrade={canUpgrade}
        isMobile={isMobile}
      />
      {settings && (
        <MultilevelSidebar
          isMobile={isMobile}
          open={menuOpen}
          onToggle={handleSidebarToggle}
          onToggleHide={handleSidebarHide}
          hasNotify={hasNotify}
          options={options(
            mapArrayToObj([
              { key: 'menuOpen', val: menuOpen },
              { key: 'handleSidebarToggle', val: handleSidebarToggle },
              { key: 'credits', val: credits },
              { key: 'canUpgrade', val: canUpgrade },
              { key: 'settings', val: settings },
              { key: 'updateAccountSetting', val: updateAccountSetting },
              { key: 'updateVideoSetting', val: updateVideoSetting },
              { key: 'toggleDesktopNotificationStatus', val: toggleDesktopNotificationStatus },
              { key: 'attachSocialLogin', val: attachSocialLogin },
              { key: 'needsAccountPassword', val: data.profile && data.profile.needs_account_password },
              { key: 'destroyOtherDeviceSession', val: destroyOtherDeviceSession },
              { key: 'requestUserInfo', val: requestUserInfo },
              { key: 'deleteGDPRForm', val: deleteGDPRForm },
              { key: 'errors', val: errors },
              { key: 'clearNotificationMessage', val: clearNotificationMessage },
              { key: 'clearErrors', val: clearErrors },
              { key: 'validateCredentials', val: validateCredentials },
              { key: 'handleGDPRRequest', val: handleGDPRRequest },
              { key: 'GDPR', val: GDPR },
              { key: 'allowDarkMode', val: allowDarkMode },
              { key: 'allowThemeOS', val: allowThemeOS },
              { key: 'showAccountInfo', val: showAccountInfo },
              { key: 'requiredVerification', val: requiredVerification },
              { key: 'resetNux', val: resetNux },
              { key: 'account', val: account },
              { key: 'getRecurringPackages', val: getRecurringPackages },
              { key: 'subscribeRecurringPayment', val: subscribeRecurringPayment },
              { key: 'unSubscribeRecurringPayment', val: unSubscribeRecurringPayment },
              { key: 'getRecurringPayment', val: getRecurringPayment },
              { key: 'removeSavedToken', val: removeSavedToken },
            ]),
            trackPackagesPageVisited,
            hashId,
            isAccountIsMixpanelEligableEnabled,
            resetHiddenDialogs
          )}
          header={
            <SidebarHeader
              handleSidebarToggle={handleSidebarToggle}
              con
              avatar={avatar}
              username={username}
              hashId={hashId}
              isVerified={data.profile.id_verification?.data?.status === 'approved'}
            />
          }
          onItemClick={handleClick}
          onBackClick={clearLastUpdate}
          width={myWidth()}
          updatingSettings={updatingSettings}
          lastUpdate={lastUpdate}
          GDPR={GDPR}
          parentMenuItems={parentMenuItems}
          onClose={unlockPage}
          {...rest}
        />
      )}
    </UserNavItemContainerStyled>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    resetHiddenDialogsProp: () => {
      dispatch(resetHiddenDialogs());
    },
  };
};

export default connect(null, mapDispatchToProps)(SliderSidebar);
