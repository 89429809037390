import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { statusColor } from '../style/index';
import StatusIcon from '../atoms/icons/StatusIcon';
import InputLabel from './InputLabel';

const StatusMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 4px;
`;

const StatusMessage = styled('div')(
  ({ status }) =>
    status && {
      color: statusColor[status],
      paddingLeft: 4,
    }
);

const ButtonGroupStyle = styled('div')(
  ({ newCard }) =>
    newCard && {
      marginBottom: newCard ? 0 : 25,
      '&: > div > label': {
        marginBottom: 16,
      },
      '&: > div:last-of-type > label': {
        marginBottom: 0,
      },
    }
);

const ButtonGroup = styled('div')`
  margin-bottom: 14px;
  position: relative;
  border: 1px solid ${props => (props.status !== 'default' ? statusColor[props.status] : 'transparent')};
  & > div > label {
    margin-bottom: 16px;
  }
  & > div:last-of-type > label {
    margin-bottom: 0;
  }
`;

const renderStatusMessage = (status, statusMessage) => {
  return (
    <StatusMessageContainer>
      <StatusIcon status={status} />
      <StatusMessage status={status}>{statusMessage}</StatusMessage>
    </StatusMessageContainer>
  );
};

const setTooltip = tooltip => (tooltip ? tooltip : null);

interface RadioButtonGroupProps {
  inputLabel: any;
  labelDescription: any;
  status: any;
  statusMessage: any;
  tooltip: any;
  children: any;
  newCard?: any;
  joinForm?: any;
  customStyle?: any;
  customInputLabelStyle?: React.CSSProperties;
  customInputLabel?: React.CSSProperties;
  isRequired?: boolean;
}

const RadioButtonGroup = ({
  inputLabel,
  labelDescription,
  status,
  statusMessage,
  tooltip,
  children,
  newCard = {},
  joinForm,
  customStyle,
  isRequired = false,
  customInputLabelStyle,
}: RadioButtonGroupProps): ReactElement => {
  return (
    <div>
      {inputLabel ? (
        <InputLabel
          label={inputLabel}
          customContainerStyle={
            joinForm && !customInputLabelStyle ? { marginBottom: 0, fontSize: 15 } : customInputLabelStyle
          }
          customStyle={customStyle}
          labelDescription={labelDescription}
          tooltip={setTooltip(tooltip)}
          isRequired={isRequired}
        />
      ) : null}
      {joinForm ? (
        <ButtonGroup status={status}>{children}</ButtonGroup>
      ) : (
        <ButtonGroupStyle newCard={newCard}>{children}</ButtonGroupStyle>
      )}
      {statusMessage && status !== 'default' ? renderStatusMessage(status, statusMessage) : null}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  /** Label of radio button group (optional) */
  inputLabel: PropTypes.string,
  /** Label description (optional) */
  labelDescription: PropTypes.string,
  /** Tooltip next to label */
  tooltip: PropTypes.shape({
    icon: PropTypes.any,
    text: PropTypes.string,
    overlayStyle: PropTypes.object,
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  }),
  /** Determines if the radio group will be outlined with a specfic status color border (transparent for default status) */
  status: PropTypes.oneOf(['default', 'success', 'caution', 'error']),
  /** Message at bottom of radio group to describe status */
  statusMessage: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  status: 'default',
};

export default RadioButtonGroup;
