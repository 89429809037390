import React, { useEffect, useState } from 'react';
import { mixpanelTrackWaitTime } from '../../utils/mixpanel/waitTime';

interface IProfilerComponentProps {
  page: string;
}

const TrackLoadingToMixpanel: React.FC<IProfilerComponentProps> = (props: IProfilerComponentProps) => {
  const { page } = props;
  const [startTime] = useState(Date.now());

  useEffect(() => {
    return () => {
      const endTime = Date.now();
      const waitTime = endTime - startTime;
      const Duration = waitTime / 1000; // in seconds

      // Don't send pre mature loading time of sending payment
      if (page === 'Payment' && Duration < 1) {
        return;
      }

      mixpanelTrackWaitTime({
        Duration,
        Content: page,
      });
    };
  }, []);

  return null;
};

export default TrackLoadingToMixpanel;
