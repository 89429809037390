import React, { ReactElement, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import CounterSmallDotIcon from '../icons/CounterSmallDotIcon';
import CounterDotIcon from '../icons/DiscoverCounterDotIcon';
import colors from '../../theme/colors';

interface ItemProps {
  order: number;
  image: string;
  private: boolean;
  privateReal: boolean;
  type: string;
  total: number;
}

interface Photo {
  order: number;
  image: string;
  private: boolean;
  privateReal: boolean;
  type: string;
  total: number;
  status: string;
}

const ProfileMediaRenderDots = ({
  item,
  index,
  currentSlide,
  photos,
}: {
  item: ItemProps;
  index: number;
  currentSlide: number;
  photos: Photo[];
}): ReactElement => {
  const activeDot = currentSlide === index;
  const lastDotSmall = photos.length >= 4 && index > currentSlide + 2;
  const hideDots = photos.length >= 4 && (index > currentSlide + 3 || index < currentSlide - 3);
  const firstDotSmall = photos.length >= 4 && index < currentSlide - 2;

  const renderDots = style => {
    const animatedStyles = { ...style, display: 'inherit', height: 6, alignItems: 'center' };

    if (lastDotSmall || firstDotSmall) {
      return hideDots ? (
        <></>
      ) : (
        <animated.div style={animatedStyles}>
          <CounterSmallDotIcon
            key={`small-${index}-${item.order}`}
            fillCircle={item.private || item.privateReal ? '#D06369' : colors.gray['500']}
          />
        </animated.div>
      );
    } else if (!item.private && !item.privateReal) {
      return (
        <animated.div style={animatedStyles}>
          <CounterDotIcon key={`public-${index}-${item.order}`} fillColor={activeDot ? 'white' : colors.gray['500']} />
        </animated.div>
      );
    } else {
      return (
        <animated.div style={animatedStyles}>
          <CounterDotIcon key={`private-${index}-${item.order}`} isActivePrivate={activeDot} fillColor={'#D06369'} />
        </animated.div>
      );
    }
  };

  const transRef = useSpringRef();
  const transitions = useTransition(activeDot, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(5%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    trail: 150,
  });

  useEffect(() => {
    transRef.start();
  }, [activeDot]);

  return transitions(style => {
    return renderDots(style);
  });
};

export default ProfileMediaRenderDots;
