import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';

const IconUploadSuccess = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <circle cx="13" cy="13" r="13" fill={isDarkMode() ? '#5EBE5E' : '#24A83A'} />
      <mask
        id="mask0_3469_817"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="18"
        height="18"
      >
        <rect x="4.15918" y="4.16016" width="17.68" height="17.68" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3469_817)">
        <path
          d="M11.8916 14.4059L10.2845 12.7988C10.1463 12.6605 9.97613 12.5914 9.77412 12.5914C9.5721 12.5914 9.39312 12.6667 9.23718 12.8172C9.09892 12.9624 9.02979 13.1357 9.02979 13.3369C9.02979 13.5382 9.10238 13.7079 9.24758 13.8462L11.3695 15.9865C11.5178 16.1317 11.6909 16.2043 11.8887 16.2043C12.0865 16.2043 12.2615 16.1317 12.4137 15.9865L16.6903 11.6994C16.8478 11.5489 16.9265 11.3743 16.9265 11.1758C16.9265 10.9772 16.8478 10.8 16.6903 10.6441C16.5398 10.5058 16.3604 10.4397 16.1523 10.4459C15.9441 10.452 15.7682 10.5242 15.6246 10.6625L11.8916 14.4059ZM13.0004 20.6275C11.9511 20.6275 10.9627 20.4286 10.0352 20.0309C9.10781 19.6332 8.29763 19.0878 7.60469 18.3949C6.91175 17.702 6.36642 16.8923 5.96868 15.9658C5.57094 15.0393 5.37207 14.0514 5.37207 13.002C5.37207 11.9404 5.57094 10.9459 5.96868 10.0184C6.36642 9.09101 6.91121 8.28372 7.60307 7.59656C8.29491 6.9094 9.10431 6.36539 10.0313 5.96455C10.9583 5.5637 11.9467 5.36328 12.9967 5.36328C14.0589 5.36328 15.0541 5.56343 15.9824 5.96374C16.9106 6.36404 17.7181 6.90732 18.4047 7.59356C19.0914 8.2798 19.635 9.08677 20.0355 10.0145C20.436 10.9422 20.6363 11.9375 20.6363 13.0006C20.6363 14.0509 20.4359 15.0397 20.035 15.9671C19.6342 16.8944 19.0902 17.7041 18.403 18.3961C17.7159 19.0882 16.9091 19.6332 15.9826 20.0309C15.0561 20.4286 14.0621 20.6275 13.0004 20.6275Z"
          fill={isDarkMode() ? '#113855' : 'white'}
        />
      </g>
    </svg>
  );
};

export default IconUploadSuccess;
