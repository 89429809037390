import React, { ReactElement } from 'react';
interface ISliderPlayIconProps {
  style?: any;
  onClick?: () => any;
}

const SliderPlayIcon = ({ ...rest }: ISliderPlayIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      {...rest}
      className="pressable"
    >
      <circle opacity="0.75" cx="38" cy="38.5" r="38" fill="#333333" />
      <path
        d="M32.18 31.6V45.4C32.18 46.12 32.49 46.66 33.11 47.02C33.73 47.38 34.34 47.36 34.94 46.96L45.8 40C46.36 39.64 46.64 39.14 46.64 38.5C46.64 37.86 46.36 37.36 45.8 37L34.94 30.04C34.34 29.64 33.73 29.62 33.11 29.98C32.49 30.34 32.18 30.88 32.18 31.6ZM38 62.5C34.72 62.5 31.62 61.87 28.7 60.61C25.78 59.35 23.23 57.63 21.05 55.45C18.87 53.27 17.15 50.72 15.89 47.8C14.63 44.88 14 41.78 14 38.5C14 35.22 14.63 32.12 15.89 29.2C17.15 26.28 18.87 23.73 21.05 21.55C23.23 19.37 25.78 17.65 28.7 16.39C31.62 15.13 34.72 14.5 38 14.5C41.28 14.5 44.38 15.13 47.3 16.39C50.22 17.65 52.77 19.37 54.95 21.55C57.13 23.73 58.85 26.28 60.11 29.2C61.37 32.12 62 35.22 62 38.5C62 41.78 61.37 44.88 60.11 47.8C58.85 50.72 57.13 53.27 54.95 55.45C52.77 57.63 50.22 59.35 47.3 60.61C44.38 61.87 41.28 62.5 38 62.5Z"
        fill="white"
      />
    </svg>
  );
};

export default SliderPlayIcon;
