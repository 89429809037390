import React, { ReactElement } from 'react';
import { isDarkMode } from '../../../../../utils/helpers';

const OfferDeclineIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <g filter="url(#filter0_d_4326_1478)">
        <circle cx="32" cy="29.9995" r="27.5" fill={isDarkMode() ? '#041A2A' : 'white'} />
        <circle
          cx="32"
          cy="29.9995"
          r="26.5"
          stroke={isDarkMode() ? '#2B8FD7' : '#C9C9C9'}
          strokeWidth={isDarkMode() ? 2 : 1}
        />
      </g>
      <g filter="url(#filter1_i_4326_1478)">
        <path
          d="M44.4504 20.6162L42.0052 18.1709L32.3108 27.8653L22.6164 18.1709L20.1711 20.6162L29.8655 30.3105L20.1711 40.0049L22.6164 42.4502L32.3108 32.7558L42.0052 42.4502L44.4504 40.0049L34.7561 30.3105L44.4504 20.6162Z"
          fill="#DD0B24"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4326_1478"
          x="0.5"
          y="0.499512"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4326_1478" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4326_1478" result="shape" />
        </filter>
        <filter
          id="filter1_i_4326_1478"
          x="20.1711"
          y="18.1709"
          width="24.2793"
          height="25.2793"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4326_1478" />
        </filter>
      </defs>
    </svg>
  );
};

export default OfferDeclineIcon;
