import { VYSION_ACTIONS } from '../..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Join',
  path: 'Join',
};

export const enum NewJoinFlowClickVysionEventsEnum {
  I_AM_MAN = 'join-iAm-man',
  I_AM_WOMAN = 'join-iAm-woman',
  I_WANT_TO_MAKE_OFFERS = 'join-iWantTo-makeOffers',
  I_WANT_TO_RECEIVE_OFFERS = 'join-iWantTo-receiveOffers',
  I_AM_INTERESTED_IN_MEETING_MEN = 'join-iAmInterestedInMeeting-men',
  I_AM_INTERESTED_IN_MEETING_WOMEN = 'join-iAmInterestedInMeeting-women',
  I_AM_INTERESTED_IN_MEETING_BOTH = 'join-iAmInterestedInMeeting-both',
  BIRTH_MONTH = 'join-birthdate-month',
  BIRTH_DAY = 'join-birthdate-day',
  BIRTH_YEAR = 'join-birthdate-year',
  EMAIL_DOMAIN_SELECTION = 'join-emailEntry-domainSelection',
  EMAIL_ENTRY = 'join-emailEntry',
  NEXT = 'join-next',
}

export const newJoinFlowClickVysionEvents = {
  [NewJoinFlowClickVysionEventsEnum.I_AM_MAN]: {
    ...commonValues,
    extraInfo: 'man',
    component: 'I am',
    eventName: NewJoinFlowClickVysionEventsEnum.I_AM_MAN,
  },
  [NewJoinFlowClickVysionEventsEnum.I_AM_WOMAN]: {
    ...commonValues,
    extraInfo: 'woman',
    component: 'I am',
    eventName: NewJoinFlowClickVysionEventsEnum.I_AM_WOMAN,
  },
  [NewJoinFlowClickVysionEventsEnum.I_WANT_TO_MAKE_OFFERS]: {
    ...commonValues,
    extraInfo: 'make offers',
    component: 'I want to',
    eventName: NewJoinFlowClickVysionEventsEnum.I_WANT_TO_MAKE_OFFERS,
  },
  [NewJoinFlowClickVysionEventsEnum.I_WANT_TO_RECEIVE_OFFERS]: {
    ...commonValues,
    extraInfo: 'receive offers',
    component: 'I want to',
    eventName: NewJoinFlowClickVysionEventsEnum.I_WANT_TO_RECEIVE_OFFERS,
  },
  [NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_MEN]: {
    ...commonValues,
    extraInfo: 'men',
    component: 'I am interested in meeting',
    eventName: NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_MEN,
  },
  [NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_WOMEN]: {
    ...commonValues,
    extraInfo: 'women',
    component: 'I am interested in meeting',
    eventName: NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_WOMEN,
  },
  [NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_BOTH]: {
    ...commonValues,
    extraInfo: 'both',
    component: 'I am interested in meeting',
    eventName: NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_BOTH,
  },
  [NewJoinFlowClickVysionEventsEnum.BIRTH_MONTH]: {
    ...commonValues,
    extraInfo: 'month',
    component: 'Birthdate',
    eventName: NewJoinFlowClickVysionEventsEnum.BIRTH_MONTH,
  },
  [NewJoinFlowClickVysionEventsEnum.BIRTH_DAY]: {
    ...commonValues,
    extraInfo: 'day',
    component: 'Birthdate',
    eventName: NewJoinFlowClickVysionEventsEnum.BIRTH_DAY,
  },
  [NewJoinFlowClickVysionEventsEnum.BIRTH_YEAR]: {
    ...commonValues,
    extraInfo: 'year',
    component: 'Birthdate',
    eventName: NewJoinFlowClickVysionEventsEnum.BIRTH_YEAR,
  },
  [NewJoinFlowClickVysionEventsEnum.EMAIL_DOMAIN_SELECTION]: {
    ...commonValues,
    extraInfo: 'domain-selection',
    component: 'Email Entry',
    eventName: NewJoinFlowClickVysionEventsEnum.EMAIL_DOMAIN_SELECTION,
  },
  [NewJoinFlowClickVysionEventsEnum.EMAIL_ENTRY]: {
    ...commonValues,
    extraInfo: '',
    component: 'Email Entry',
    eventName: NewJoinFlowClickVysionEventsEnum.EMAIL_ENTRY,
  },
  [NewJoinFlowClickVysionEventsEnum.NEXT]: {
    ...commonValues,
    component: 'Next',
    eventName: NewJoinFlowClickVysionEventsEnum.NEXT,
  },
};
