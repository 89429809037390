import React, { useState, useEffect, ReactElement } from 'react';
import ConfirmationModal from '../../modules/modals/ConfirmationModal';
import MasterConfig from '../../config/Master';
import Checkbox from '../../atoms/Checkbox';
import styled from '@emotion/styled';
import { textColor } from '../../style';
import { getBrowserDetails, tabletWidth, isDarkMode } from '../../utils/helpers';

const ReminderLinks = styled('div')({
  fontSize: 20,
  fontWeight: 400,
  lineHeight: '32px',
  textDecoration: !isDarkMode() ? 'underline #1383d2' : 'underline #AFB0B1',
  a: {
    ...textColor.LINK,
  },
});

const SubHadingContent = styled('p')({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '32px',
});

const ContentList = styled('li')({
  fontSize: 20,
  lineHeight: '32px',
});

const isTablet = getBrowserDetails().isMobile && tabletWidth;

const CheckboxStyle = () => (!isDarkMode() ? { border: '1px solid #575757' } : {});

interface ReminderModalProps {
  acctType: any;
  handleReminderStatus: any;
  reminderStatus: any;
}

const ReminderModal = ({ acctType, handleReminderStatus, reminderStatus }: ReminderModalProps): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reminderOption1, setReminderOption1] = useState(false);
  const [reminderOption2, setReminderOption2] = useState(false);
  const [reminderOption3, setReminderOption3] = useState(false);
  const [reminderOption4, setReminderOption4] = useState(false);
  const [reminderOption5, setReminderOption5] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (reminderStatus !== 0) {
      setModalOpen(true);
    }
  }, [reminderStatus]);

  useEffect(() => {
    reminderStatus === 2
      ? setAllChecked(reminderOption1 && reminderOption2 && reminderOption3)
      : setAllChecked(reminderOption1 && reminderOption2 && reminderOption3 && reminderOption4 && reminderOption5);
  }, [reminderOption1, reminderOption2, reminderOption3, reminderOption4, reminderOption5, reminderStatus]);

  const closeModal = () => {
    setModalOpen(false);

    if (typeof handleReminderStatus === 'function') {
      handleReminderStatus();
    }
  };

  const updateReminderOptions = (index, status) => {
    switch (index) {
      case 1:
        setReminderOption1(status);
        break;
      case 2:
        setReminderOption2(status);
        break;
      case 3:
        setReminderOption3(status);
        break;
      case 4:
        setReminderOption4(status);
        break;
      case 5:
        setReminderOption5(status);
        break;
    }
  };

  const displayContent = () => {
    switch (acctType) {
      case 'Generous':
        return (
          <div>
            <SubHadingContent>As a member you agree to:</SubHadingContent>
            <ul style={{ listStyleType: 'none', marginLeft: -20 }}>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Be respectful and abide by the Community Guidelines at all times."
                  value={reminderOption1}
                  id="reminder_opt1"
                  onChange={() => updateReminderOptions(1, !reminderOption1)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Report any inappropriate or suspicious behavior."
                  value={reminderOption2}
                  id="reminder_opt2"
                  onChange={() => updateReminderOptions(2, !reminderOption2)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Read the Safe Dating Tips and be cautious while interacting with other members."
                  value={reminderOption3}
                  id="reminder_opt3"
                  onChange={() => updateReminderOptions(3, !reminderOption3)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              {reminderStatus !== 2 && (
                <div>
                  <ContentList>
                    <Checkbox
                      inline
                      noSpace
                      label="Give the First Date Incentive on your date as reimbursement for your date's expenses."
                      value={reminderOption4}
                      id="reminder_opt4"
                      onChange={() => updateReminderOptions(4, !reminderOption4)}
                      customStyle={CheckboxStyle()}
                      customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                    />
                  </ContentList>
                  <ContentList>
                    <Checkbox
                      inline
                      noSpace
                      label="Not renegotiate the agreed amount or ask for the First Date Incentive in advance."
                      value={reminderOption5}
                      id="reminder_opt5"
                      onChange={() => updateReminderOptions(5, !reminderOption5)}
                      customStyle={CheckboxStyle()}
                      customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                    />
                  </ContentList>
                </div>
              )}
            </ul>
          </div>
        );
      case 'Attractive':
        return (
          <div>
            <SubHadingContent>As a member you agree to:</SubHadingContent>
            <ul style={{ listStyleType: 'none', marginLeft: -20 }}>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Be respectful and abide by the Community Guidelines at all times."
                  value={reminderOption1}
                  id="reminder_opt1"
                  onChange={() => updateReminderOptions(1, !reminderOption1)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Report any inappropriate or suspicious behavior."
                  value={reminderOption2}
                  id="reminder_opt2"
                  onChange={() => updateReminderOptions(2, !reminderOption2)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              <ContentList>
                <Checkbox
                  inline
                  noSpace
                  label="Read the Safe Dating Tips and be cautious while interacting with other members."
                  value={reminderOption3}
                  id="reminder_opt3"
                  onChange={() => updateReminderOptions(3, !reminderOption3)}
                  customStyle={CheckboxStyle()}
                  customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                />
              </ContentList>
              {reminderStatus !== 2 && (
                <div>
                  <ContentList>
                    <Checkbox
                      inline
                      noSpace
                      label="Accept the First Date Incentive on your date as reimbursement for your expenses."
                      value={reminderOption4}
                      id="reminder_opt4"
                      onChange={() => updateReminderOptions(4, !reminderOption4)}
                      customStyle={CheckboxStyle()}
                      customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                    />
                  </ContentList>
                  <ContentList>
                    <Checkbox
                      inline
                      noSpace
                      label="Not renegotiate the agreed amount or ask for the First Date Incentive in advance."
                      value={reminderOption5}
                      id="reminder_opt5"
                      onChange={() => updateReminderOptions(5, !reminderOption5)}
                      customStyle={CheckboxStyle()}
                      customLabelTextStyle={{ fontSize: 20, fontWeight: 400 }}
                    />
                  </ContentList>
                </div>
              )}
            </ul>
          </div>
        );
      default:
        return <div />;
    }
  };

  const renderLinks = () => {
    return (
      <ReminderLinks>
        <a
          data-test-id={'cypress-reminder-link-safe-dating'}
          href={`${MasterConfig.FULL_URL}/blog/tips-for-dating-safely-during-covid-and-after`}
          target="_blank"
          rel="noreferrer"
        >
          Read Safe Dating Tips
        </a>
        <div style={{ paddingTop: 5 }} />
        <a
          data-test-id={'cypress-reminder-link-community-guidelines'}
          href={`${MasterConfig.FULL_URL}/community-guidelines`}
          target="_blank"
          rel="noreferrer"
        >
          Read Community Guidelines
        </a>
      </ReminderLinks>
    );
  };

  return (
    <div>
      <ConfirmationModal
        contentLabel="Dating Safely"
        header="Dating Safely"
        version2
        isOpen={modalOpen}
        description={displayContent()}
        hideCancel
        confirmText="I Agree"
        onConfirm={closeModal}
        footerLinks={renderLinks()}
        customStyle={{ content: { borderRadius: 0, maxWidth: isTablet ? 520 : 1028, padding: 0, width: 'auto' } }}
        allChecked={allChecked}
      />
    </div>
  );
};

export default ReminderModal;
