import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';

import Panel from './Panel';
import { dimensions, spacing, leading, textType } from '../../style';

const InfoContainer = styled('div')({
  ...spacing.MARGIN_BOTTOM_LG,

  [dimensions.SCREEN_MAX_MD]: {
    paddingRight: 0,
    paddingLeft: 0,
  },
});

const Info = styled('span')({
  display: 'inline-block',
  ...textType.text,
  ...leading.LOOSE,
  ...spacing.PADDING_RIGHT_SM,
  breakWord: 'nowrap',
  border: '1px solid rgba(36,145,222,1)',
  background: 'rgba(36,145,222,1)',
  color: '#FFFFFF',
  margin: 6,
  padding: '6px 18px',
  borderRadius: 20,
  marginLeft: 0,
});

interface PanelTagsWithHeaderProps {
  title: any;
  data: any;
  className: any;
  toggleEditable: any;
}

const PanelTagsWithHeader = ({
  title,
  data,
  className,
  toggleEditable,
  ...rest
}: PanelTagsWithHeaderProps): ReactElement => {
  const renderItems = () => {
    if (isEmpty(data)) return null;

    return Object.keys(data).map(key => {
      const item = data[key];
      return <Info key={key}>{item}</Info>;
    });
  };

  return (
    <Panel withHeader title={title} className={className} toggleEditable={toggleEditable} {...rest}>
      <InfoContainer>{renderItems(data)}</InfoContainer>
    </Panel>
  );
};

PanelTagsWithHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
};

PanelTagsWithHeader.defaultProps = {
  className: '',
  title: '',
  data: [],
};

export default PanelTagsWithHeader;
