/**
 * @Author: Daniel Rhees <Daniel>
 * @Date:   2017-01-21T19:19:46-08:00
 * @Email:  daniel@reflexmedia.com
 * @Last modified by:   Daniel
 * @Last modified time: 2017-08-21T17:39:53-07:00
 */

import { dimensions, spacing } from '../../style';
import styled from '@emotion/styled';
import { ternaryFunc } from '../../utils/helpers';

const sizes = {
  thin: {
    maxWidth: '420px',
  },

  medium: {
    maxWidth: '640px',
  },
};

const Container = styled('div')`
  padding: 0 ${spacing.PADDING_SM.padding};
  max-width: ${props =>
    props.width ? sizes[props.width]['maxWidth'] : ternaryFunc(props.wideHeader, '1920px', '1070px')};
  margin: 0 auto;
  ${props => (props.relative ? 'position: relative;' : null)} ${dimensions.SCREEN_MAX_MD} {
    ${props => (props.fullWidth ? 'padding: 0;' : null)} ${props =>
      props.spacedBottom ? 'margin-bottom: 22px;' : null};
  }
  ${dimensions.SCREEN_MIN_MD} {
    ${props => (props.screenTop ? 'margin-top: 22px; margin-bottom: 22px;' : null)};
  }
  ${props => props.css};
`;

export default Container;
