import React, { Fragment, ReactElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useModal from '../../../hooks/useModal';
import { IsMobileViewPort } from '../../../utils/helpers';
import { FormContainer } from './styledElements';
import EditablePhoneInput from './EditablePhoneInput';
import VerificationForm from './VerificationForm';
import VerifyAccountOwnerShip from './VerifyAccountOwnerShip';
import { PHONE_VERIFICATION_TYPES } from '../../../constants/phone';
import AddPhoneInput from './AddPhoneInput';
import usePhoneActionModal from '../../../hooks/usePhoneActionModal';
import { updatePhoneNumber } from '../../../actions/profileActions';
import { useApp } from '../../../contexts/AppContext';
import { handlePhoneSettingsVysionEvent } from '../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PhoneSettingsViewVysionEventsEnum } from '../../../utils/vysion/phoneSettings/view';
import { mixpanelTrackPhoneNumberChanged } from '../../../utils/mixpanel/phoneNumberChanged';

interface EditPhoneFormProps {
  phoneNumber?: string;
  profileCountry?: string;
  updateVerifiedPhoneNumber?: any;
  errors: any;
  hashId: string;
}

const EditPhoneForm: React.FC<EditPhoneFormProps> = ({
  phoneNumber,
  profileCountry,
  updateVerifiedPhoneNumber,
  errors,
  hashId,
}: EditPhoneFormProps): ReactElement => {
  const { basicModal, resetModal } = useModal();
  const {
    setShowOtpCountdown,
    currentPhoneSettingsStep,
    setCurrentPhoneSettingsStep,
    setForceCloseSliderMenu,
  } = useApp();
  const {
    showPhoneSuccessModal,
    utils: { requestResendOtp },
  } = usePhoneActionModal();
  const [currentPhone, setCurrentPhone] = useState(phoneNumber ?? '');
  const [currentStepVerification, setCurrentStepVerification] = useState<PHONE_VERIFICATION_TYPES>(
    PHONE_VERIFICATION_TYPES.PASSWORD
  );

  const resetForm = () => {
    setCurrentPhoneSettingsStep(1);
    setCurrentStepVerification(PHONE_VERIFICATION_TYPES.PASSWORD);
    setShowOtpCountdown(false);
  };

  const handleSuccessModal = (isVerifiedPhoneNumber = false, setIsProcessing) => {
    const handleConfirmOk = () => {
      resetModal();
      if (isVerifiedPhoneNumber) {
        resetForm();
      }
    };

    if (isVerifiedPhoneNumber) {
      updateVerifiedPhoneNumber({ phone_number: currentPhone }, () => {
        setIsProcessing(false);
        mixpanelTrackPhoneNumberChanged(
          {
            'Verification Method':
              currentStepVerification === PHONE_VERIFICATION_TYPES.PASSWORD ? 'Password' : 'Phone Number',
          },
          { 'Phone Number': true }
        );

        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.VIEW,
          event: PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PHONE_UPDATE_SUCCESS,
        });

        showPhoneSuccessModal({
          title: 'Your phone number has been successfully updated!',
          onClosed: handleConfirmOk,
          isPhoneSettings: true,
          isVerified: true,
        });

        resetForm();

        // toggle to hide the slider menu
        setForceCloseSliderMenu(prev => prev + 1);
      });
    } else {
      setCurrentPhoneSettingsStep(4);
      showPhoneSuccessModal({
        title: 'Phone successfully verified!',
        onClosed: handleConfirmOk,
      });
    }
  };

  const handleEditPhone = () => {
    IsMobileViewPort
      ? basicModal({
          dateTestId: 'phone-verification-select',
          modalContentLabel: 'Phone Success Select',
          isModalOpen: true,
          withoutBgPattern: true,
          closeOnOverlayClick: false,
          modalBody: (
            <VerifyAccountOwnerShip
              nextStep={() => {
                setCurrentPhoneSettingsStep(3);
                resetModal();
              }}
              handleCurrenStepVerification={setCurrentStepVerification}
              currentPhone={currentPhone}
              customStyle={{ paddingLeft: 10, paddingRight: 10 }}
              onCancel={resetForm}
            />
          ),
          customContentStyle: { maxWidth: 420 },
        })
      : setCurrentPhoneSettingsStep(2);
  };

  useEffect(() => {
    if (errors?.phone_number) {
      resetForm();
      setCurrentPhone(phoneNumber ?? '');
    }
  }, [errors]);

  return (
    <FormContainer>
      {phoneNumber ? (
        <Fragment>
          {currentPhoneSettingsStep === 1 && (
            <EditablePhoneInput nextStep={handleEditPhone} currentPhone={currentPhone} />
          )}

          {currentPhoneSettingsStep === 2 && (
            <VerifyAccountOwnerShip
              nextStep={() => setCurrentPhoneSettingsStep(3)}
              handleCurrenStepVerification={setCurrentStepVerification}
              currentPhone={currentPhone}
              onCancel={resetForm}
            />
          )}

          {currentPhoneSettingsStep === 3 && (
            <div>
              <EditablePhoneInput displayOnly currentPhone={currentPhone} />
              <VerificationForm
                type={currentStepVerification}
                onSuccess={setIsProcessing => {
                  handleSuccessModal(false, setIsProcessing);
                }}
                onCancel={resetForm}
                currentPhone={currentPhone}
                hashId={hashId}
              />
            </div>
          )}

          {currentPhoneSettingsStep === 4 && (
            <EditablePhoneInput
              allowChange
              nextStep={newPhoneValue => {
                setCurrentPhone(newPhoneValue);
                setCurrentPhoneSettingsStep(5);
              }}
              currentPhone={currentPhone}
            />
          )}

          {currentPhoneSettingsStep === 5 && (
            <div>
              <EditablePhoneInput displayOnly currentPhone={currentPhone} />
              <VerificationForm
                type={PHONE_VERIFICATION_TYPES.OTP}
                onSuccess={setIsProcessing => {
                  handleSuccessModal(true, setIsProcessing);
                }}
                onCancel={() => {
                  resetForm();
                  setCurrentPhone(phoneNumber);
                }}
                currentPhone={currentPhone}
                hashId={hashId}
                hasUpdatedPhone
              />
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {currentPhoneSettingsStep === 1 && (
            <AddPhoneInput
              profileCountry={profileCountry}
              nextStep={newPhoneValue => {
                setCurrentPhone(newPhoneValue);
                setCurrentPhoneSettingsStep(2);
              }}
            />
          )}

          {currentPhoneSettingsStep === 2 && (
            <div>
              <EditablePhoneInput displayOnly currentPhone={currentPhone} />
              <VerificationForm
                type={PHONE_VERIFICATION_TYPES.PASSWORD}
                onSuccess={() => {
                  // when user verify there password then send OTP for the number
                  requestResendOtp({
                    isLogin: false,
                    phoneNumber: currentPhone,
                    showSuccessModal: false,
                    onResendSuccess: () => {
                      setCurrentPhoneSettingsStep(3);
                    },
                    onResendFailed: resetForm,
                  });
                }}
                onCancel={resetForm}
                currentPhone={currentPhone}
                hashId={hashId}
              />
            </div>
          )}

          {currentPhoneSettingsStep === 3 && (
            <div>
              <EditablePhoneInput displayOnly currentPhone={currentPhone} />
              <VerificationForm
                type={PHONE_VERIFICATION_TYPES.OTP}
                onSuccess={setIsProcessing => handleSuccessModal(true, setIsProcessing)}
                onCancel={resetForm}
                currentPhone={currentPhone}
                hashId={hashId}
                hasUpdatedPhone
              />
            </div>
          )}
        </Fragment>
      )}
    </FormContainer>
  );
};

const mapStateToProps = ({ profile, common }) => {
  return {
    errors: common.errors,
    phoneNumber: profile.phone_number,
    hashId: profile.hash_id,
    profileCountry: profile.country ?? profile.profile.data.country,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateVerifiedPhoneNumber(payload, callback) {
      return dispatch(updatePhoneNumber(payload, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPhoneForm);
