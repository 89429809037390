import {
  REMOVE_DISCOVER_MODE_ACCOUNTS,
  RESET_DISCOVER_MODE_ACCOUNTS,
  STORE_DISCOVER_MODE_ACCOUNTS,
  UPDATE_DISCOVER_MODE_ACCOUNTS,
} from '../reducers/types/Dashboard.d';
import store from '../store';

const setDiscoverModeAccountsAction = (payload: any): any => {
  return {
    type: STORE_DISCOVER_MODE_ACCOUNTS,
    payload,
  };
};

const updateDiscoverModeAccountsAction = (payload: any): any => {
  return {
    type: UPDATE_DISCOVER_MODE_ACCOUNTS,
    payload,
  };
};

const resetDiscoverModeAccountsAction = (): any => {
  return {
    type: RESET_DISCOVER_MODE_ACCOUNTS,
  };
};

const removeDiscoverModeAccountsAction = (payload: any): any => {
  return {
    type: REMOVE_DISCOVER_MODE_ACCOUNTS,
    payload,
  };
};

const removeDiscoverModeAccountsByHashId = (accountHashIds: string[]): any => {
  const dashboardAccounts = store.getState().dashboard.DiscoverModeAccounts;
  const filteredAccounts = dashboardAccounts.filter((account: any) => !accountHashIds.includes(account.hash_id));

  store.dispatch(updateDiscoverModeAccountsAction(filteredAccounts));
};

export {
  setDiscoverModeAccountsAction,
  removeDiscoverModeAccountsAction,
  resetDiscoverModeAccountsAction,
  updateDiscoverModeAccountsAction,
  removeDiscoverModeAccountsByHashId,
};
