import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { backgroundTheme, textColor } from '../style';

const BANNER_COLOR = {
  caution: '#F5A623',
  error: '#CC0000',
  success: '#77B300',
  info: backgroundTheme.completionBanner,
};

const StyledBanner = styled('div')(
  {
    padding: 16,
    color: textColor.DARKER.color,
    borderRadius: '5px',
    marginBottom: '16px',
  },
  ({ status }) => ({
    display: status === 'none' ? 'none' : 'block',
    backgroundColor: status !== 'none' && BANNER_COLOR[status],
    color: status === 'info' && textColor.DARKER.color,
    border: status === 'info' ? 'solid 1px #5BB1F1' : 'none',
  })
);

interface TopBannerProps {
  status: any;
  children: ReactNode;
}

const TopBanner = ({ status, children }: TopBannerProps): ReactElement => {
  return <StyledBanner status={status}>{children}</StyledBanner>;
};

TopBanner.propTypes = {
  status: PropTypes.oneOf(['caution', 'error', 'success', 'info']),
};

TopBanner.defaultProps = {
  status: 'none',
};

export default TopBanner;
