import React from 'react';
import styled from '@emotion/styled';
import colors from '../../../../../theme/colors';
import OfferDeclineIcon from '../icons/OfferDeclineIcon';
import OfferDollarIcon from '../icons/OfferDollarIcon';
import OfferAcceptIcon from '../icons/OfferAcceptIcon';

const ButtonActionContainer = styled('div')({
  width: 64,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 8,
});

const ButtonLabelCounter = styled('span')({
  fontSize: 13,
  fontWeight: 400,
  letterSpacing: '-0.44px',
  lineHeight: '18px',
  color: colors['light'].blue.tertiary,
});

interface IProps {
  onClick: () => void;
  variant: 'decline' | 'accept' | 'offer' | 'request';
  btnRef?: React.RefObject<HTMLDivElement>;
}

const OneTapSwipeOfferActionButton: React.FC<IProps> = ({ onClick, variant, btnRef }: IProps) => {
  const renderIcon = () => {
    switch (variant) {
      case 'decline':
        return <OfferDeclineIcon />;
      case 'accept':
        return <OfferAcceptIcon />;
      case 'offer':
      case 'request':
        return <OfferDollarIcon />;
      default:
        return <></>;
    }
  };

  const renderLabel = () => {
    switch (variant) {
      case 'decline':
        return 'Decline';
      case 'accept':
        return 'Accept';
      case 'offer':
        return 'Offer';
      case 'request':
        return 'Request';
      default:
        return '';
    }
  };

  return (
    <ButtonActionContainer onClick={onClick} ref={btnRef}>
      {renderIcon()}
      <ButtonLabelCounter>{renderLabel()}</ButtonLabelCounter>
    </ButtonActionContainer>
  );
};

export default OneTapSwipeOfferActionButton;
