import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
  page: 'Account Menu',
};

export const enum DeactivateAccountViewVysionEventsEnum {
  ACCOUNT_MENU_DEACTIVATE__ACCOUNT = 'accountMenu-deactivateAccount',
}

export const deactivateAccountViewVysionEvents = {
  [DeactivateAccountViewVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE__ACCOUNT]: {
    ...commonValues,
    eventName: DeactivateAccountViewVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE__ACCOUNT,
    component: 'Deactivate Account Screen',
  },

};
