import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DashboardDiscoverModeOfferActionPass from './components/OfferActions/DashboardDiscoverModeOfferActionPass';
import DashboardDiscoverModeOfferActionInput from './components/OfferActions/DashboardDiscoverModeOfferActionInput';
import DashboardDiscoverModeOfferActionOffer from './components/OfferActions/DashboardDiscoverModeOfferActionOffer';
import { updateShowTutorialOffer } from '../../../actions/profileActions';
import { connect } from 'react-redux';
import { useDashboardDiscoverMode } from '../../../contexts/DashboardDiscoverModeContext';
import { isDarkMode } from '../../../utils/helpers';
import { useApp } from '../../../contexts/AppContext';
import { CurrencySymbols } from './DashboardDiscoverMode';
import colors from '../../../theme/colors';
import borders from '../../../theme/borders';
import DashboardDiscoverModeWhatsAnOffer from './components/DashboardDiscoverModeWhatsAnOffer';
import { GMDiscoverModelEditableOfferVariations } from '../../../utils/evidentlyFeatures/GMDiscoverModeEditableOffer';
import { Profile, ProfileMaker } from '../../../models/Profile';
import { dimensions } from '../../../style';

const ActionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
const OfferActionContainer = styled('div')({
  maxWidth: 'calc(100% - 20px)',
  width: '100%',
  position: 'fixed',
  [dimensions.SCREEN_MIN_MD]: {
    padding: '0 10px',
    position: 'relative',
    top: '95px',
    paddingBottom: 83,
  },
});

const OfferLabelInfo = styled('span')({
  fontSize: 13,
  fontWeight: 400,
  marginRight: 5,
  lineHeight: '20px',
  letterSpacing: '-0.26',
  color: borders.light.secondary,
});

const OfferPriceContainer = styled('div')({
  fontSize: 56,
  fontWeight: 600,
  color: isDarkMode() ? colors['dark'].blue.secondary : colors['light'].blue.tertiary,
  letterSpacing: '-2.24px',
  lineHeight: '42px',
  marginRight: 20,
  marginLeft: 20,
  paddingBottom: 5,
});

const OfferLabelInfoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 3,
  [dimensions.SCREEN_MIN_MD]: {
    paddingBottom: 83,
  },
});

interface Props {
  offerPrice: null | number;
  updateOfferPrice: (offerPrice: string, hasError: boolean) => void;
  swipe: (direction: 'left' | 'right') => void;
  hasError: boolean;
  currencySymbols: CurrencySymbols;
  gmEditableOfferVariation: GMDiscoverModelEditableOfferVariations;
  ownProfile: Profile;
  disableManualClick: boolean;
}

const DashboardDiscoverModeOfferActions: React.FC<Props> = ({
  offerPrice,
  updateOfferPrice,
  swipe,
  hasError,
  currencySymbols,
  gmEditableOfferVariation,
  ownProfile,
  disableManualClick,
}: Props) => {
  const { offerActionContainerRef } = useDashboardDiscoverMode();
  const [bottom, setBottom] = useState(0); // Default value
  const { siteFooterLogoRef } = useApp();

  useEffect(() => {
    const siteFooterLogoEl = siteFooterLogoRef?.current;

    if (siteFooterLogoEl) {
      const siteFooterLogoClRec = siteFooterLogoEl.getBoundingClientRect();
      const OFFER_ACTION_MARGIN_BOTTOM = 15;
      const computeDynamicBottom = window.innerHeight - siteFooterLogoClRec.top + OFFER_ACTION_MARGIN_BOTTOM;
      setBottom(computeDynamicBottom);
    }
  }, [siteFooterLogoRef?.current]);

  return (
    <OfferActionContainer ref={offerActionContainerRef} style={{ bottom }}>
      <ActionContainer>
        <DashboardDiscoverModeOfferActionPass disableManualClick={disableManualClick} onClick={() => swipe('left')} />
        {gmEditableOfferVariation && gmEditableOfferVariation === 'NonEditableOffers' ? (
          <OfferPriceContainer>
            {currencySymbols.currencyInitial}
            {currencySymbols.currencyLabel}
            {offerPrice}
          </OfferPriceContainer>
        ) : (
          <DashboardDiscoverModeOfferActionInput
            offerPrice={offerPrice}
            updateOfferPrice={updateOfferPrice}
            hasError={hasError}
            currencySymbols={currencySymbols}
          />
        )}
        <DashboardDiscoverModeOfferActionOffer disableManualClick={disableManualClick} onClick={() => swipe('right')} />
      </ActionContainer>
      <OfferLabelInfoContainer>
        <OfferLabelInfo>First date offer {ownProfile.isAttractive() && 'request'}</OfferLabelInfo>
        <DashboardDiscoverModeWhatsAnOffer />
      </OfferLabelInfoContainer>
    </OfferActionContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateOfferTutorialState: () => {
      dispatch(updateShowTutorialOffer(false));
    },
  };
};

const mapStateToProps = state => {
  return {
    ownProfile: ProfileMaker.create(state.profile.profile),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDiscoverModeOfferActions);
