import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Duration: number;
  Content: string;
}

export const mixpanelTrackWaitTime = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Wait Time',
    payload: {
      event_properties: eventProperties,
    },
  });
};
