import React from 'react';
import InputLabel from './InputLabel';
import Button from './buttons/Button';
import CancelButton from './buttons/ProfileCancelButton';
import Autocomplete from './AutocompleteMain';
import UsernameInput from './UsernameInput';
import styled from '@emotion/styled';
import { IsMobileViewPort } from '../utils/helpers';
import { connect } from 'react-redux';
import Optional from './Optional';
import { dimensions } from '../style';

const AutocompleteContainer = styled('div')({
  maxWidth: '476px',
});

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

const EditProfileCoverContainer = styled('div')({
  paddingTop: 15,
  paddingRight: 0,
  paddingLeft: 15,
  flex: 3,
  maxWidth: '100%',
  flexDirection: 'column',
  [dimensions.SCREEN_MAX_XS_2]: {
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15,
  },
});

const EditableProfileCover = (props: any): JSX.Element => {
  const {
    errors,
    displayName,
    myLocation,
    handleSubmit,
    handleCancel,
    updateUsername,
    onChangeAddress,
    onSelectLocation,
    isUpdatingProfileCover,
  } = props;

  const locationInputProps = {
    value: myLocation,
    id: 'autocompleteLocation',
    placeholder: 'Enter a Location',
    type: 'text',
    onChange: onChangeAddress,
    status: errors && errors.city && 'error',
    statusMessage: errors && errors.city,
  };

  const checkEmptyandError = (myName, loc) => {
    return myName && loc && !(myName.length < 3 || myName.length > 14) && myName.match(/^[a-zA-Z0-9]+$/);
  };

  const locationEmpty = loc => {
    return loc !== undefined && loc.length === 0;
  };

  return (
    <EditProfileCoverContainer>
      <Optional show={!isUpdatingProfileCover}>
        <ButtonContainer>
          <CancelButton
            dataTestId="profile-cover-cancel-btn"
            handleCancel={handleCancel}
            customStyle={{ marginRight: 0 }}
          />
        </ButtonContainer>
      </Optional>
      <UsernameInput
        inputLabel="Display Name"
        error={errors && errors.username}
        status={errors && errors.username && 'error'}
        value={displayName}
        onChange={updateUsername}
        customStyleLabel={{ fontWeight: 700 }}
        customInputStyle={{ color: 'rgba(0, 0, 0, 0.8)' }}
        customStyle={{ marginRight: 0 }}
        autoFocus
      />
      <AutocompleteContainer style={{ maxWidth: '100%' }}>
        <InputLabel
          eleRef={r => {
            this._city = r;
          }}
          label="Location"
        />
        <Autocomplete
          inputProps={locationInputProps}
          onSelect={onSelectLocation}
          error={errors && errors.city}
          status={errors && errors.city && 'error'}
          statusMessage={errors && errors.city}
          customInputStyle={{ color: 'rgba(0, 0, 0, 0.8)', paddingRight: '60px !important' }}
          emptyLocation={locationEmpty(myLocation)}
          customStyle={{ marginRight: 0 }}
          isEditProfile
        />
      </AutocompleteContainer>
      <ButtonContainer style={IsMobileViewPort ? { justifyContent: 'center', marginBottom: 15 } : {}}>
        <Button
          data-test-id="profile-cover-save-btn"
          buttonType="error"
          onClick={handleSubmit}
          disabled={!checkEmptyandError(displayName, myLocation) || isUpdatingProfileCover}
        >
          {isUpdatingProfileCover ? 'Saving...' : 'Save'}
        </Button>
      </ButtonContainer>
    </EditProfileCoverContainer>
  );
};

const mapStateToProps = state => {
  return {
    isUpdatingProfileCover: state.profile.isUpdatingProfileCover,
  };
};

export default connect(mapStateToProps, {})(EditableProfileCover);
