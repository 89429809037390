/**
 * @Author: zachalam
 * @Date:   2017-01-19T13:38:09-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T16:13:02-08:00
 */

import { combineReducers } from 'redux';
import { CLEAR_EVERYTHING, RootActionType } from './types/Index.d';
import common from './common';
import auth from './auth';
import profile from './profile';
import extprofile from './extprofile';
import fav from './fav';
import settings from './settings';
import convo from './convo';
import blocked from './blocked';
import biometrics from './biometrics';
import billing from './billing';
import search from './search';
import meta from './meta';
import offers from './offers';
import credits from './credits';
import counter from './counter';
import dashboard from './dashboard';
import notification from './notification';
import global_notification from './globalnotification';
import unlink from './unlink';
import alerts from './alerts';
import mail from './mail';
import ddc from './ddc';
import uploadSnackbar from './uploadSnackbar';
import unreadOneTapOffersCount from './unreadOffersCount';
import notificationBanners from './notificationBanners';
import attributes from './attributes';

const appReducer = combineReducers({
  common,
  auth,
  profile,
  extprofile,
  fav,
  settings,
  convo,
  blocked,
  billing,
  search,
  meta,
  offers,
  credits,
  notification,
  counter,
  dashboard,
  unlink,
  alerts,
  global_notification,
  biometrics,
  mail,
  ddc,
  uploadSnackbar,
  unreadOneTapOffersCount,
  notificationBanners,
  attributes,
});

const rootReducer = (state: any, action: RootActionType): any => {
  if (action.type === CLEAR_EVERYTHING) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
