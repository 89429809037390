import React, { ReactElement } from 'react';

const ConfirmModalSuccessIcon = (): ReactElement => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_1655_1271)">
        <path
          d="M17.5835 23.5278L13.6668 19.6111C13.3983 19.3426 13.065 19.2083 12.6668 19.2083C12.2687 19.2083 11.9261 19.3518 11.639 19.6389C11.3705 19.9074 11.2363 20.2407 11.2363 20.6389C11.2363 21.037 11.3705 21.3704 11.639 21.6389L16.6113 26.6389C16.8891 26.9166 17.2131 27.0555 17.5835 27.0555C17.9539 27.0555 18.2779 26.9166 18.5557 26.6389L28.3335 16.8611C28.6205 16.574 28.764 16.2315 28.764 15.8333C28.764 15.4352 28.6205 15.0926 28.3335 14.8055C28.0465 14.537 27.6992 14.4074 27.2918 14.4166C26.8844 14.4259 26.5465 14.5648 26.278 14.8333L17.5835 23.5278ZM20.0002 36.6666C17.7131 36.6666 15.5557 36.2291 13.528 35.3541C11.5002 34.4791 9.73165 33.287 8.22237 31.7778C6.71312 30.2685 5.521 28.5 4.646 26.4722C3.771 24.4444 3.3335 22.287 3.3335 20C3.3335 17.6944 3.771 15.5278 4.646 13.5C5.521 11.4722 6.71312 9.70831 8.22237 8.20831C9.73165 6.70831 11.5002 5.52081 13.528 4.64581C15.5557 3.77081 17.7131 3.33331 20.0002 3.33331C22.3057 3.33331 24.4724 3.77081 26.5002 4.64581C28.5279 5.52081 30.2918 6.70831 31.7918 8.20831C33.2918 9.70831 34.4793 11.4722 35.3543 13.5C36.2293 15.5278 36.6668 17.6944 36.6668 20C36.6668 22.287 36.2293 24.4444 35.3543 26.4722C34.4793 28.5 33.2918 30.2685 31.7918 31.7778C30.2918 33.287 28.5279 34.4791 26.5002 35.3541C24.4724 36.2291 22.3057 36.6666 20.0002 36.6666Z"
          fill="#24A83A"
        />
      </g>
    </svg>
  );
};

export default ConfirmModalSuccessIcon;
