export const STORE_PROFILE_EXTERNAL = 'STORE_PROFILE_EXTERNAL';
export const STORE_REPORT_REASONS = 'STORE_REPORT_REASONS';
export const STORE_PROFILE_EXTERNAL_404 = 'STORE_PROFILE_EXTERNAL_404';
export const REMOVE_PROFILE_EXTERNAL = 'REMOVE_PROFILE_EXTERNAL';
export const STORE_PROFILE_FAV_CHANGE = 'STORE_PROFILE_FAV_CHANGE';
export const TOGGLE_BLOCK_USER_PROFILE = 'TOGGLE_BLOCK_USER_PROFILE';
export const STORE_OFFER_IN_PROFILE = 'STORE_OFFER_IN_PROFILE';
export const REMOVE_SINGLE_OFFER = 'REMOVE_SINGLE_OFFER';
export const REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA = 'REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA';

export interface ExtProfile {
    profile: Record<string, unknown>;
} 

export interface Reasons {
    reasons: unknown[];
}

export interface Data {
    data: Record<string, unknown>;
}

export interface Offer {
    offer: Data | null;
}

export interface ExtProfileState extends Reasons, Offer {
    is_favorite: boolean | null;
    is_blocked: boolean | null;
}

interface  STORE_PROFILE_EXTERNAL_ACTION {
    type: typeof STORE_PROFILE_EXTERNAL;
    payload: ExtProfile
}

interface STORE_REPORT_REASONS_ACTION {
    type: typeof STORE_REPORT_REASONS;
    payload: Reasons;
}

interface STORE_PROFILE_EXTERNAL_404_ACTION {
    type: typeof STORE_PROFILE_EXTERNAL_404;
}

interface REMOVE_PROFILE_EXTERNAL_ACTION {
    type: typeof REMOVE_PROFILE_EXTERNAL;
}

interface STORE_PROFILE_FAV_CHANGE_ACTION {
    type: typeof STORE_PROFILE_FAV_CHANGE;
}

interface TOGGLE_BLOCK_USER_PROFILE_ACTION {
    type: typeof TOGGLE_BLOCK_USER_PROFILE;
}

interface STORE_OFFER_IN_PROFILE_ACTION {
    type: typeof STORE_OFFER_IN_PROFILE;
    payload: Offer;
}

interface REMOVE_SINGLE_OFFER_ACTION {
    type: typeof REMOVE_SINGLE_OFFER;
}

interface REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA_ACTION {
    type: typeof REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA;
    payload: Data;
}

export type ExtProfileActionType = 
    | STORE_PROFILE_EXTERNAL_ACTION
    | STORE_REPORT_REASONS_ACTION
    | STORE_PROFILE_EXTERNAL_404_ACTION
    | REMOVE_PROFILE_EXTERNAL_ACTION
    | STORE_PROFILE_FAV_CHANGE_ACTION
    | TOGGLE_BLOCK_USER_PROFILE_ACTION
    | STORE_OFFER_IN_PROFILE_ACTION
    | REMOVE_SINGLE_OFFER_ACTION
    | REFRESH_EXTERNAL_PROFILE_ADDITIONAL_DATA_ACTION;
