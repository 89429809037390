import React from 'react';
import GalleryMenu from '../../../../atoms/GalleryMenu';
import MediaPlayIcon from '../../../../atoms/icons/MediaPlayIcon';
import VideoGalleryMenu from '../../../../atoms/VideoGalleryMenu';
import {
  Photo,
  PhotoAspectRatio,
  PhotoStatusTitle,
  PhotoStatus,
  VideoInProgress,
  MediaPlayWrap,
} from './GalleryStyles';
import VideoGear from '../../../../icons/VIdeoGear';

interface PublicGalleryProps {
  url: any;
  status: any;
  isProfile: any;
  isOtherProfile: any;
  openLightbox: any;
  index: any;
  photoKey: any;
  setPrivacy: any;
  makeDefault: any;
  deletePhoto: any;
  hasPublicPhotos: any;
  isPrivate: any;
  singlePublicLeft: any;
  photoId: any;
  isAvatar: any;
  hasPublicApprovedPhotos: any;
  mediaType: any;
  deleteMedia: any;
  setMediaPrivacy: any;
  isPrivateReal: any;
  hasBulkSelection: any;
}

const PublicGallery = ({
  url,
  status,
  isProfile,
  isOtherProfile,
  openLightbox,
  index,
  photoKey,
  setPrivacy,
  makeDefault,
  deletePhoto,
  hasPublicPhotos,
  isPrivate,
  singlePublicLeft,
  photoId,
  isAvatar,
  hasPublicApprovedPhotos,
  mediaType,
  setMediaPrivacy,
  deleteMedia,
  isPrivateReal,
  hasBulkSelection,
}: PublicGalleryProps): any => {
  const isPending = status === 'new';
  const isCenterAlign = !isProfile && !isPending;
  const isVideo = mediaType === 'video';

  /**
   * Add hash for video thumbnail
   * to prevent browser from caching the newly created thumbnail url
   * which is not created yet in S3
   * ref: https://jira.reflexmedia.com/browse/WYP-12922
   */
  const shouldAppendHashInThumbnail = isVideo && ['pending', 'new'].includes(status);
  const mediaUrl = new URL(url);
  const hasSignedUrl = mediaUrl.searchParams.has('Signature');
  const imgUrl = shouldAppendHashInThumbnail && !hasSignedUrl ? `${url}?hash=${new Date().getTime()}` : url;

  return (
    <PhotoAspectRatio
      id={`PhotoB${index}`}
      data-test-id={`${isPrivateReal ? 'private' : 'public'}-${isVideo ? 'video' : 'photo'}-container`}
    >
      {isVideo && status === 'pending' ? (
        <VideoInProgress
          onClick={e => {
            e.preventDefault();
            openLightbox(index, e);
          }}
        >
          <VideoGear />
          <div style={{ margin: '10px auto', width: '80%' }}>Video Processing...</div>
        </VideoInProgress>
      ) : (
        <Photo
          imgUrl={imgUrl}
          onClick={e => {
            e.preventDefault();
            openLightbox(index, e);
          }}
        />
      )}

      {isVideo && status !== 'pending' && (
        <MediaPlayWrap>
          <MediaPlayIcon
            onClick={e => {
              e.preventDefault();
              openLightbox(index, e);
            }}
          />
        </MediaPlayWrap>
      )}

      {!isOtherProfile && (
        <PhotoStatus isCenterAlign={isCenterAlign}>
          {isProfile && isPending && <PhotoStatusTitle isPending={isProfile && isPending}>Pending</PhotoStatusTitle>}

          {isVideo ? (
            <VideoGalleryMenu
              key={photoKey}
              theKey={photoKey}
              setPrivacy={setMediaPrivacy}
              deleteMedia={deleteMedia}
              hasPublicPhotos={hasPublicPhotos}
              isPrivate={isPrivate}
              singlePublicLeft={singlePublicLeft}
              mediaId={photoId}
              mediaType={mediaType}
              isPending={isPending}
              hasPublicApprovedPhotos={hasPublicApprovedPhotos}
              isCenterAlign={isCenterAlign}
              hasBulkSelection={hasBulkSelection}
            />
          ) : (
            <GalleryMenu
              key={photoKey}
              theKey={photoKey}
              setPrivacy={setPrivacy}
              makeDefault={makeDefault}
              deletePhoto={deletePhoto}
              hasPublicPhotos={hasPublicPhotos}
              isPrivate={isPrivate}
              singlePublicLeft={singlePublicLeft}
              photoId={photoId}
              mediaType={mediaType}
              isAvatar={isAvatar}
              isPending={isPending}
              hasPublicApprovedPhotos={hasPublicApprovedPhotos}
              isCenterAlign={isCenterAlign}
              hasBulkSelection={hasBulkSelection}
            />
          )}
          {isProfile && !isVideo && <PhotoStatusTitle>Profile</PhotoStatusTitle>}
          {!isProfile && isPending && <PhotoStatusTitle>Pending</PhotoStatusTitle>}
        </PhotoStatus>
      )}
    </PhotoAspectRatio>
  );
};

export default PublicGallery;
