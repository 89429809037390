import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, dimensions, textColor } from '../../style';
import Button from '../../atoms/buttons/Button';
import { VideoCallIcon, EndCall } from '../icons/VideoCall';
import UnsupportedBrowserModal from '../../components/blocks/UnsupportedBrowserModal';
import { getBrowserDetails } from '../../utils/helpers';
const activityColor = {
  panelColor: backgroundTheme.block,
  fontColor: textColor.DARK.color,
};

const OverLayAlertContainer = styled('div')`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 100px;
  z-index: 1000;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
`;

const AlertContainer = styled('div')`
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 400px;
  min-height: 72px;
  padding: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  background-color: ${activityColor.panelColor};
  width: 300px;
  margin-top: 7%;
  flex-direction: column;
  margin: 0 auto;

  ${dimensions.SCREEN_MAX_XS} {
    margin: 10px auto 0;
    position: fixed;
    left: 0;
    right: 0;
  }
`;

const Avatar = styled('div')(
  {
    width: 300,
    height: 300,
    borderRadius: 5,
    background: '#000000',
    flexShrink: 0,
  },
  ({ backgroundUrl }) =>
    backgroundUrl && {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
);

const MessageContainer = styled('div')`
  text-align: center;
  width: 100%;
`;

const Message = styled('div')`
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  color: ${activityColor.fontColor};
`;

const CallerName = styled('div')`
  font-size: 18px;
  padding: 10px;
  font-weight: 900;
  color: ${activityColor.fontColor};
`;

const ActionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

interface VideocallalertProps {
  timeout: number;
  onTimeout(): void;
  label: string;
  profilePhoto: string;
  callerName: string;
  onDecline(): void;
  onAnswer(): void;
}

const VideoCallAlert = ({
  timeout,
  onTimeout,
  label,
  profilePhoto,
  callerName,
  onDecline,
  onAnswer,
}: VideocallalertProps): JSX.Element => {
  const [disableButton, setDisableButton] = useState(true);
  const [showUnsupportedModal, setShowUnsupportedModal] = useState(false);
  const thisTimeOut = useRef(null);

  useEffect(() => {
    if (timeout > 0 && typeof onTimeout === 'function') {
      thisTimeOut.current = setTimeout(() => onTimeout(), timeout);
    }
    setDisableDelay();
    if (getBrowserDetails().RTCunsupported) {
      setShowUnsupportedModal(true);
    }
    return () => {
      clearTimeout(thisTimeOut.current);
    };
  }, [timeout, onTimeout]);

  const setDisableDelay = () => {
    setDisableButton(true);
    setTimeout(() => setDisableButton(false), 300);
  };

  const onShowUnsupportedModal = (show = true) => {
    setShowUnsupportedModal(show);
  };

  if (getBrowserDetails().RTCunsupported) {
    return <UnsupportedBrowserModal isOpen={showUnsupportedModal} isReceiver setModalOpen={onShowUnsupportedModal} />;
  }

  return (
    <OverLayAlertContainer>
      <AlertContainer id="video-call-alert">
        <Avatar backgroundUrl={profilePhoto} />
        <MessageContainer>
          <CallerName>{callerName}</CallerName>
          <Message>{label}</Message>
          <ActionContainer>
            <Button
              disabled={disableButton}
              onClick={onDecline}
              buttonType="error"
              customStyle={{ flex: 1, borderRadius: '21px 0 0 21px' }}
            >
              <EndCall fillColor={'#FFFFFF'} />
            </Button>
            <Button
              disabled={disableButton}
              onClick={onAnswer}
              buttonType="success"
              customStyle={{ flex: 1, borderRadius: '0 21px 21px 0' }}
            >
              {disableButton ? 'connecting..' : <VideoCallIcon fillColor={'#FFFFFF'} />}
            </Button>
          </ActionContainer>
        </MessageContainer>
      </AlertContainer>
    </OverLayAlertContainer>
  );
};

export default VideoCallAlert;
