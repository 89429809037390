import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import CounterInfoIcon from '../../../../../atoms/icons/CounterInfoIcon';
import useNav from '../../../../../hooks/useNav';
import useMixPanel from '../../../../../hooks/useMixPanel';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../../../constants/mixpanel';
import OneTapSwipeOfferMediaRenderDots from './OneTapSwipeOfferMediaRenderDots';

const MediaCounterContainer = styled('div')({
  background: '#000',
  height: 41,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

const CounterWrapper = styled('div')({
  display: 'inline-block',
  marginRight: 1,
  marginTop: 2,
  marginLeft: '80px',
  width: 'calc(100% - 160px)',
  textAlign: 'center',
});

const DotsWrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '5px 8px',
  borderRadius: '8px',
  background: '#262626',
  gap: 6,
});

const InfoIconWrapper = styled('div')({
  padding: '1px 32px 11px 22px',
  cursor: 'pointer',
});

interface OneTapSwipeOfferMediaCounterProps {
  photos: any;
  currentSlide: number;
  hashId: string;
  otherAccountIsGenerous: boolean;
}

const OneTapSwipeOfferMediaCounter = ({
  photos,
  currentSlide,
  hashId,
  otherAccountIsGenerous,
}: OneTapSwipeOfferMediaCounterProps): ReactElement => {
  const { goTo } = useNav();
  const { setMixpanelDataByKeyHandler } = useMixPanel();

  return (
    <MediaCounterContainer>
      <CounterWrapper className="counter-info-wrapper">
        {photos.length > 0 && (
          <DotsWrapper className="dots-wrapper">
            {photos.map((item, index) => {
              return (
                <OneTapSwipeOfferMediaRenderDots
                  key={index}
                  item={item}
                  index={index}
                  currentSlide={currentSlide}
                  photos={photos}
                />
              );
            })}
          </DotsWrapper>
        )}
      </CounterWrapper>
      <InfoIconWrapper
        onClick={() => {
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.OFFER_MADE,
            payload: {
              source: !otherAccountIsGenerous
                ? TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedRequest
                : TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedOffers,
            },
          });
          goTo(`/profile/${hashId}`);
        }}
        className="pressable"
      >
        <CounterInfoIcon />
      </InfoIconWrapper>
    </MediaCounterContainer>
  );
};

export default OneTapSwipeOfferMediaCounter;
