import { vysionService } from '../utils/vysion/vysionService';
import { convertTitleCase, convertSentanceTitleCase } from '../utils/helpers';

const setCorrectPage = eventType => {
  switch (eventType) {
    case 'GOOGLE SIGNUP':
      return 'Join';
    case 'GOOGLE LOGIN':
      return 'Login';
    default:
      return 'Footer';
  }
};

const setSocialAction = eventType => {
  const googleEventTypes = ['GOOGLE SIGNUP', 'GOOGLE LOGIN'];
  const isGoogleSSO = googleEventTypes.includes(eventType);

  return {
    type: 'SOCIAL',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: setCorrectPage(eventType),
        extraInfo: null,
        component: isGoogleSSO ? 'Google SSO' : `${setComponent(eventType)}`,
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setComponent = eventName => {
  return eventName === 'COMMUNITY GUIDELINES' ? convertSentanceTitleCase(eventName) : convertTitleCase(eventName);
};

const sendSocialAction = (event: any, type: any): any => {
  vysionService(setSocialAction(type));
};

export { sendSocialAction };
