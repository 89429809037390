import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { renderAvatar } from '../../common';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { dimensions } from '../../style';
import './photoslider.css';
import { profilePhoto, otherProfilePhoto } from '../../actions/profileTrackAction';
import { triggerTracking } from '../../utils/helpers';
import { s3BucketDirect } from '../../config/Master';
import { HeartNotificationIcon, OfferNew } from '../icons/favoriteIcons';
import MediaPlayIcon from '../icons/MediaPlayIcon';

const setHeight = deviceHeight => {
  switch (deviceHeight) {
    case 896:
      return '550px';
    case 844:
      return '519px';
    case 926:
      return '570px';
    default:
      return '500px';
  }
};

const PrivateLabelContainer = styled('div')({
  zIndex: 1001,
  left: 0,
  width: '100%',
  paddingTop: 20,
  marginLeft: '72%',
});

const PrivatePhotoMessageContainer = styled('div')({
  paddingTop: '80%',
  zIndex: 1001,
  color: '#ffffff',
  textAlign: 'center',
  left: 0,
  bottom: '12%',
  width: '100%',
});

const SliderHeading = styled('p')({
  fontSize: 18,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  color: '#2490de',
});

const SliderText = styled('p')({
  fontSize: 12,
  padding: '0 2rem',
  margin: 10,
});

const Photo = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  ({ backgroundImage, privateMonkey }) =>
    backgroundImage && {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: privateMonkey ? '50% 35%' : 'top',
      backgroundSize: privateMonkey ? 'unset' : 'cover',
      backgroundRepeat: 'no-repeat',
    }
);

const Gradient = styled('div')({
  [dimensions.SCREEN_MAX_XS]: {
    background: `rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0,0.2) 5%, rgba(255, 255, 255, 0) 15%,rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0,0.7) 100%) repeat scroll 0% 0%`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

const ProfileCardBanner = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '6px 0',
    width: '100%',

    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    color: '#fff',
    svg: {
      width: 14,
      height: 14,
      marginTop: 1,
      marginRight: 4,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 12,
      svg: {
        height: 12,
      },
    },
  },
  ({ customBackground }) => ({
    background: `${customBackground ? customBackground : 'rgba(188,32,40,0.9)'}`,
  })
);

const VideoAction = styled('div')({
  zIndex: 999,
  height: '100%',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const monkeyIcon = (hasStatus = false) => {
  const privateEye = `${s3BucketDirect}thumbs/private_eye.png`;

  return <img src={privateEye} style={{ width: 30 + 'px', marginTop: hasStatus ? 20 : 3 + 'px' }} alt="" />;
};

const otherProfileCond = obj => {
  if (obj.type === 'video') {
    return obj.urls && obj.urls.thumbnail ? obj.urls.thumbnail : obj.thumbnail;
  }

  return obj.private && !obj.privateReal ? obj.src : renderAvatar(obj.src);
};

const getMyprofileCond = obj => {
  if (obj.type === 'video') {
    return obj.urls && obj.urls.thumbnail ? obj.urls.thumbnail : obj.thumbnail;
  }

  return renderAvatar(obj.urls);
};

const Gallery = (photoList, isOtherProfile, privatePhotoCount) => {
  const gallery = [];

  photoList.forEach(obj => {
    gallery.push({
      image: isOtherProfile ? otherProfileCond(obj) : getMyprofileCond(obj),
      private: obj.private && !obj.privateReal && isOtherProfile,
      privateReal: (!obj.private && obj.privateReal) || (!isOtherProfile && obj.private && !obj.privateReal),
      privatePhotoCount: privatePhotoCount,
      type: obj.type,
    });
  });

  // temp workaround for 2 image count only
  return gallery.length === 2
    ? [
        ...gallery,
        {
          image: `${s3BucketDirect}resized/private_eye.png`,
          private: true,
          privatePhotoCount: 0,
          privateReal: false,
          type: 'photo',
        },
      ]
    : gallery;
};

const setPhotoAction = (e, profileStatus) => {
  switch (profileStatus) {
    case 'myProfile':
      profilePhoto(e);
      break;
    case 'otherProfile':
      otherProfilePhoto(e);
      break;
    default:
      return;
  }
};

interface ProfilePhotoSliderProps {
  photoList: Array<unknown>;
  onClick: (index: number, e: Record<string, unknown>) => void;
  isOtherProfile: boolean;
  updateSliderPosition: (index: number) => void;
  privatePhotoCount: number;
  currentUserIsGenerous: boolean;
  sliderPosition: number;
  lightboxIsOpen: boolean;
  counterBadge: Record<string, unknown>;
  totalImages: number;
  offer: any;
}

const ProfilePhotoSlider = ({
  photoList,
  onClick,
  isOtherProfile,
  updateSliderPosition,
  privatePhotoCount,
  currentUserIsGenerous,
  sliderPosition,
  lightboxIsOpen,
  counterBadge,
  totalImages,
  offer,
}: ProfilePhotoSliderProps): JSX.Element => {
  const gallery = Gallery(photoList, isOtherProfile, privatePhotoCount);
  const [myGallery, setMyGallery] = useState(gallery);
  const [privateText] = useState(currentUserIsGenerous ? 'an offer' : 'a request');
  const [currPosition, setCurrPosition] = useState(sliderPosition);
  const [rerender, setRerender] = useState(false);
  const myProfile = useRef(null);
  const otherProfile = useRef(null);

  useEffect(() => {
    const sliderEle = document.getElementsByClassName('slider')[0];
    if (sliderEle) sliderEle.style.height = setHeight(window.innerHeight);
  });

  useEffect(() => {
    const allPhotos = Gallery(photoList, isOtherProfile, privatePhotoCount);
    setMyGallery(allPhotos);
  }, [photoList, isOtherProfile, privatePhotoCount]);

  useEffect(() => {
    !lightboxIsOpen && setRerender(true);
    setTimeout(() => {
      setCurrPosition(sliderPosition);
      setRerender(false);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightboxIsOpen]);

  useEffect(() => {
    triggerTracking('myProfile', myProfile, setPhotoAction);
    triggerTracking('otherProfile', otherProfile, setPhotoAction);
  }, []);

  const renderProfileCardBanner = () => {
    if (offer && offer.status) {
      if (offer.status === 'accepted') {
        return (
          <ProfileCardBanner data-test-id="profile-banner-offer-accepted" customBackground="rgba(19, 131, 210, 0.9)">
            <HeartNotificationIcon /> Offer Accepted!
          </ProfileCardBanner>
        );
      }

      if (offer.status === 'pending') {
        if (offer.to_account_hash_id === myProfile.hash_id) {
          return (
            <ProfileCardBanner
              data-test-id={offer.suggest ? 'profile-banner-suggest-request' : 'profile-banner-offer-request'}
              customBackground="rgba(191, 146, 0, 0.9)"
            >
              {offer.suggest ? 'Asked you to Offer!' : 'Sent You an Offer!'}
            </ProfileCardBanner>
          );
        }

        return (
          <ProfileCardBanner data-test-id="profile-banner-pending-offer" customBackground="rgba(191, 146, 0, 0.9)">
            <OfferNew fill="rgba(255,255,255,1)" /> Pending Offer
          </ProfileCardBanner>
        );
      }
    }

    return '';
  };

  return !rerender ? (
    <Slider
      disabled={true}
      infinite={true}
      previousButton={false}
      nextButton={false}
      slideIndex={currPosition - 1}
      duration={100}
      onSlideChange={e => {
        const currSliderIndex = e.slideIndex + 1;

        setTimeout(() => {
          if (counterBadge && counterBadge.current) {
            counterBadge.current.innerText = `${currSliderIndex}/${totalImages}`;
          }
        }, 0);
        updateSliderPosition(currSliderIndex);
      }}
    >
      {myGallery.map((slide, index) => {
        return (
          <div
            key={index}
            id={`index${slide.type}${currPosition}`}
            ref={isOtherProfile ? otherProfile : myProfile}
            onClick={e => {
              e.preventDefault();
              onClick(index, e);
            }}
          >
            {renderProfileCardBanner()}
            <Photo
              id={`photoslide-${index + 1}`}
              data-test-id="profile-photo-container"
              backgroundImage={slide.image}
              privateMonkey={slide.private}
              onClick={e => {
                e.preventDefault();
                onClick(index, e);
              }}
            >
              {slide.privateReal && <PrivateLabelContainer>{monkeyIcon(offer && offer.status)}</PrivateLabelContainer>}
              {slide.private && (
                <PrivatePhotoMessageContainer>
                  <SliderHeading>
                    +{slide.privatePhotoCount} <br />
                    Private Photos/Videos
                  </SliderHeading>
                  <SliderText>You can see private photos/videos if they respond to {privateText}</SliderText>
                </PrivatePhotoMessageContainer>
              )}
              {slide.type === 'video' && (
                <VideoAction>
                  <MediaPlayIcon
                    customWrapperStyle={{ top: 0, position: 'relative', cursor: 'pointer', width: 'auto', zIndex: 999 }}
                    onClick={e => {
                      e.preventDefault();
                      onClick(index, e);
                    }}
                    maskId={`cover-${index}`}
                  />
                </VideoAction>
              )}
            </Photo>
            <Gradient />
          </div>
        );
      })}
    </Slider>
  ) : (
    <div />
  );
};

ProfilePhotoSlider.propTypes = {
  photoList: PropTypes.array.isRequired,
  isOtherProfile: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default ProfilePhotoSlider;
