import React, { ReactElement } from 'react';
interface IDiscoverModePrivateIconProps {
  style?: React.CSSProperties;
}

const DiscoverModePrivateIcon = ({ ...rest }: IDiscoverModePrivateIconProps): ReactElement => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g id="visibility_off">
        <mask
          id="mask0_1831_5735"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="49"
          height="49"
        >
          <rect id="Bounding box" x="0.000488281" y="0.25" width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1831_5735)">
          <path
            id="visibility_off_2"
            d="M40.7003 44.7L32.4003 36.55C31.267 37.0167 29.9586 37.3834 28.4753 37.65C26.992 37.9167 25.5003 38.05 24.0003 38.05C19.2003 38.05 14.842 36.7084 10.9253 34.025C7.00863 31.3417 4.13363 27.75 2.30029 23.25C2.96696 21.5167 3.89196 19.8334 5.07529 18.2C6.25863 16.5667 7.68363 15 9.35029 13.5L3.10029 7.20002L5.10029 5.15002L42.5503 42.6L40.7003 44.7ZM24.0003 31.6C24.467 31.6 24.9836 31.55 25.5503 31.45C26.117 31.35 26.5836 31.2167 26.9503 31.05L16.2003 20.35C16.0336 20.75 15.9003 21.2084 15.8003 21.725C15.7003 22.2417 15.6503 22.75 15.6503 23.25C15.6503 25.5834 16.467 27.5584 18.1003 29.175C19.7336 30.7917 21.7003 31.6 24.0003 31.6ZM37.6503 33.65L31.3003 27.3C31.6336 26.7667 31.892 26.1334 32.0753 25.4C32.2586 24.6667 32.3503 23.95 32.3503 23.25C32.3503 20.9167 31.542 18.9417 29.9253 17.325C28.3086 15.7084 26.3336 14.9 24.0003 14.9C23.3003 14.9 22.592 14.9834 21.8753 15.15C21.1586 15.3167 20.517 15.5667 19.9503 15.9L14.5003 10.4C15.6336 9.90002 17.117 9.45002 18.9503 9.05002C20.7836 8.65002 22.5503 8.45002 24.2503 8.45002C28.9503 8.45002 33.2503 9.78336 37.1503 12.45C41.0503 15.1167 43.917 18.7167 45.7503 23.25C44.8836 25.35 43.7753 27.275 42.4253 29.025C41.0753 30.775 39.4836 32.3167 37.6503 33.65ZM29.2003 25.15L22.0503 18.05C23.017 17.65 24.0253 17.5584 25.0753 17.775C26.1253 17.9917 27.0336 18.4667 27.8003 19.2C28.567 19.9667 29.1003 20.8334 29.4003 21.8C29.7003 22.7667 29.6336 23.8834 29.2003 25.15Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default DiscoverModePrivateIcon;
