import React, { useEffect } from 'react';
import Report from '../components/pages/Settings/Report';
import { connect } from 'react-redux';
import { refreshExternalProfile, reportUser, getReportReasons } from '../actions/profileActions';
import { useParams } from 'react-router-dom';

const ReportContainer = props => {
  const params = useParams();
  const { refreshExternal } = props;
  const username = params.username;

  useEffect(() => {
    refreshExternal(username);
  }, [refreshExternal, username]);

  return <Report {...props} />;
};

const mapStateToProps = state => {
  return {
    extprofile: state.extprofile,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshExternal(username) {
      dispatch(refreshExternalProfile(username));
      dispatch(getReportReasons());
    },
    submit: (formData, navigate) => {
      dispatch(reportUser(formData, navigate));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
