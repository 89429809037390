import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import colors from '../../../../theme/colors';
import SvgQuestion from '../../../../atoms/icons/SvgQuestion';
import ModalWithPattern from '../../../common/modals/ModalWithPattern';
import { IsMobileViewPort } from '../../../../utils/helpers';
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton';
import { dimensions, patternModal } from '../../../../style';
import { Profile, ProfileMaker } from '../../../../models/Profile';
import { mixpanelTrackWhatsAnOfferModalShown } from '../../../../utils/mixpanel/infoModalShown';

const TutorialContainer = styled('div')({
  display: 'initial',
});

const StyledSpan = styled('span')({
  fontSize: 12,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2490DE',
  },
});

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  maxHeight: 600,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

const FirstDateModalBodyContainer = styled('div')({
  textAlign: 'center',
  marginTop: 20,
});

const TitleContainer = styled('h3')({
  fontStyle: 'normal',
  fontWeight: 700,
  color: patternModal.titleColor,
  fontSize: 28,
  lineHeight: '26px',
  letterSpacing: '-0.56px',
  marginBottom: 20,
  marginTop: 50,
});

const OpeningOfferDescContainer = styled('div')({
  maxWidth: 295,
  margin: 'auto',
  letterSpacing: -0.32,
  lineHeight: '21px',
  marginBottom: 20,
  textAlign: 'center',
});

const ButtonActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 10,
});

const ButtonOkContainer = styled(PrimaryButton)({
  marginBottom: 10,
  width: 70,
  fontSize: 18,
  fontWeight: 700,
  height: 48,
  [dimensions.SCREEN_MIN_MD]: {
    width: 93,
  },
});

interface OffersTutorialProps {
  onClose?(): void;
  ownProfile: Profile;
}

const DashboardDiscoverModeWhatsAnOffer = ({ onClose = () => null, ownProfile }: OffersTutorialProps): JSX.Element => {
  const [showOffersTutorial, setShowOffersTutorial] = useState(false);

  const handleOnLinkClick = e => {
    e.preventDefault();

    if (!showOffersTutorial) {
      setShowOffersTutorial(true);
      mixpanelTrackWhatsAnOfferModalShown('Discover Mode');
    }
  };

  const handleCloseModal = () => {
    setShowOffersTutorial(false);
    onClose?.();
  };

  return (
    <TutorialContainer>
      <StyledSpan onClick={e => handleOnLinkClick(e)}>
        <SvgQuestion
          viewBox="0 0 512 500"
          styles={{
            fill: colors.blue[500],
            height: 12,
            width: 12,
          }}
        />
      </StyledSpan>
      <ModalWithPattern
        dataTestID="discovermode-whats-an-offer"
        isModalOpen={showOffersTutorial}
        modalContentLabel="Offers Tutorial"
        closeOnOverlayClick
        closeRequest={handleCloseModal}
        closeBtnClassName="discovermode-offers-tutorial-close"
        closeBtnDataTestId="discovermode-offers-tutorial-close"
        onCloseClick={handleCloseModal}
        customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
        customContentStyle={IsMobileViewPort ? mobileContentStyle : {}}
      >
        <FirstDateModalBodyContainer>
          <TitleContainer>
            What’s an{' '}
            {ownProfile.isGenerous() ? (
              `Offer`
            ) : (
              <>
                <br /> Offer Request
              </>
            )}
            ?
          </TitleContainer>
          <OpeningOfferDescContainer>
            {ownProfile.isGenerous() ? (
              <>
                It’s is an incentive you give to an <br />
                Attractive member; a gift intended to <br />
                encourage them to go on a first date.
              </>
            ) : (
              <>
                It’s the amount you suggest for the <br /> Generous member to offer you for <br /> a first date.
              </>
            )}
          </OpeningOfferDescContainer>
          <ButtonActionContainer>
            <ButtonOkContainer
              color="red"
              size="small"
              data-test-id="close-first-date-offer-modal"
              onClick={handleCloseModal}
              label="OK"
            />
          </ButtonActionContainer>
        </FirstDateModalBodyContainer>
      </ModalWithPattern>
    </TutorialContainer>
  );
};

const mapStateToProps = state => {
  return {
    ownProfile: ProfileMaker.create(state.profile.profile),
  };
};

export default connect(mapStateToProps, {})(DashboardDiscoverModeWhatsAnOffer);
