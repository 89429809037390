import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Input = styled('input')`
  display: none;
  cursor: pointer;
  &:checked + label::before {
    border: 7px solid #2490de;
  }
`;

const Label = styled('label')`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border: 2px solid #2490de;
    border-radius: 50%;
    margin-right: 8px;
  }
  &::before + input:checked {
    border: 7px solid #2490de;
  }
`;

const updateToBold = (label, id, isChecked) => {
  if (id === 'radio-new-card' && isChecked) {
    return <strong>{`${label}`}</strong>;
  }
  if (id === 'radio-card-reference') {
    const words = label.split(' ');
    return words.map(str => {
      return str === 'Visa' ||
        str === 'Mastercard' ||
        str === 'Amex' ||
        str === 'Discover' ||
        (parseInt(str) && typeof parseInt(str) === 'number') ? (
        <strong key={Math.floor(Math.random() * 999)}>{` ${str}`}</strong>
      ) : (
        ` ${str}`
      );
    });
  }

  return label;
};

interface RadioButtonProps {
  id: any;
  name: any;
  label: any;
  style: any;
  onRadioChange: any;
  value: any;
  checked: any;
  labelId: string | null;
}

const RadioButton = ({
  id,
  name,
  label,
  style,
  onRadioChange,
  value,
  checked,
  labelId = '',
}: RadioButtonProps): ReactElement => {
  return (
    <div style={style}>
      <Input
        type="radio"
        data-test-id={`radio-${name}-${value}`}
        name={name}
        id={id}
        onChange={onRadioChange}
        value={value}
        checked={checked}
      />
      <Label htmlFor={`${id}`} data-test-id={labelId}>
        <span>{updateToBold(label, labelId, checked)}</span>
      </Label>
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  checked: PropTypes.bool,
};

export default RadioButton;
