import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SliderArrowRightIcon from '../../../../../atoms/icons/SliderArrowRightIcon';
import SliderArrowLeftIcon from '../../../../../atoms/icons/SliderArrowLeftIcon';
import SliderPlayIcon from '../../../../../atoms/icons/SliderPlayIcon';
import { useOffers } from '../../../../../contexts/OffersContext';
import PrivateMonkeyIcon from '../../../../../atoms/icons/PrivateMonkeyIcon';
import { getSwipeProfilePhotoUrl } from '../../../../../utils/swipe';

const SliderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
`;

const SliderContent = styled.div<{ width: number | string }>`
  display: flex;
  transition: transform 0.5s;
  width: ${props => props.width || '100%'}; // Set a fixed width for SliderContent
  height: 100%; // Set a fixed height for SliderContent
`;

const Slide = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 100%; // Set a fixed height for each slide
`;

const Img = styled('div')<{ imgUrl: string }>(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  ({ imgUrl }) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
);

const SliderArrowContainer = styled('div')({
  position: 'absolute',
  width: '150px',
  height: '100%',
  top: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  transform: 'translate(0px,-50%)',
  zIndex: 2,
});

const sliderPlayIconStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -35%)',
  zIndex: 10,
};

interface OneTapSwipeOfferMediaSliderProps {
  photos: any;
  contentWidth?: string; // Add a prop to customize the width of SliderContent
  currentSlide: number;
  handleSlide: (step: number) => any;
}

interface Photo {
  id: number;
  hash_id: string;
  path: string;
  status: string;
  private: boolean;
  is_avatar: boolean;
  type: string;
  urls: {
    resized: string;
    '720px': string;
    '820px': string;
  };
  created_dt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  approved_by: string;
  approved_dt: string;
  denied_dt: string;
  denied_reason: string;
}

const OneTapSwipeOfferMediaSlider = ({
  photos,
  contentWidth = '100%',
  handleSlide,
  currentSlide,
}: OneTapSwipeOfferMediaSliderProps): JSX.Element => {
  const { setVideoPreview } = useOffers();
  const [selectedVideo, setSelectedVideo] = useState({ type: '', thumbnail: '', media: '' });

  useEffect(() => {
    const slide = photos[currentSlide];

    if (slide.type === 'video' && slide.id) {
      setSelectedVideo({
        type: slide.type,
        thumbnail: slide.urls['thumbnail'],
        media: slide.urls['media'],
      });
    } else {
      setSelectedVideo({ type: '', thumbnail: '', media: '' });
    }
    return () => {
      setSelectedVideo({ type: '', thumbnail: '', media: '' });
    };
  }, [currentSlide]);

  return (
    <SliderContainer>
      <SliderContent width={contentWidth} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {photos.map((photo: Photo, index: number) => {
          const photoUrl = photo.type === 'video' ? photo.urls['thumbnail'] : getSwipeProfilePhotoUrl(photo);
          const imgUrl = photoUrl;

          return (
            <Slide key={index}>
              <Img className="data-avatar-image-url" imgUrl={imgUrl} />
              {photo.private && <PrivateMonkeyIcon style={{ position: 'absolute', top: 15, right: 15, zIndex: 2 }} />}
            </Slide>
          );
        })}
      </SliderContent>
      {selectedVideo && selectedVideo.type === 'video' && selectedVideo.media && (
        <SliderPlayIcon
          style={sliderPlayIconStyle}
          onClick={() => {
            setVideoPreview({
              thumbnail: selectedVideo.thumbnail,
              media: selectedVideo.media,
            });
          }}
        />
      )}
      {currentSlide > 0 && (
        <SliderArrowContainer
          className="pressable"
          onClick={() => handleSlide(-1)}
          style={{ left: 0, paddingLeft: '12px', justifyContent: 'flex-start' }}
        >
          <SliderArrowLeftIcon customStyle={{ pointerEvents: 'none' }} />
        </SliderArrowContainer>
      )}

      {currentSlide < photos.length - 1 && (
        <SliderArrowContainer
          className="pressable"
          onClick={() => {
            handleSlide(1);
          }}
          style={{ right: 0, paddingRight: '12px', justifyContent: 'flex-end' }}
        >
          <SliderArrowRightIcon customStyle={{ pointerEvents: 'none' }} />
        </SliderArrowContainer>
      )}
    </SliderContainer>
  );
};

export default OneTapSwipeOfferMediaSlider;
