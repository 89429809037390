import React, { useState, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { backgroundTheme, slidebarTheme, textColor, buttonTheme, sideBarNav } from '../../../../style';
import { removeBodyScroll, getBrowserDetails, isDarkMode } from '../../../../utils/helpers';
import { useApp } from '../../../../contexts/AppContext';
import Button from '../../../../atoms/buttons/Button';
import ArrowLeft from '../../../../atoms/icons/ArrowLeft';
import { handlePhoneSettingsVysionEvent } from '../../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../../utils/vysion';
import { PhoneSettingsClickVysionEventsEnum } from '../../../../utils/vysion/phoneSettings/click';
import { PhoneSettingsViewVysionEventsEnum } from '../../../../utils/vysion/phoneSettings/view';
import { handlePaymentVysionEvent } from '../../../../utils/vysion/payment';
import { PaymentClickVysionEventsEnum } from '../../../../utils/vysion/payment/click';
import { PaymentViewVysionEventsEnum } from '../../../../utils/vysion/payment/view';
import { mixpanelTrackDeactivateOrDeleteMyAccountClicked } from '../../../../utils/mixpanel/deactivateOrDeleteMyAccountClicked';

const SidebarFooter = styled('div')({
  padding: '40px 10px 40px 10px',
  minHeight: 40,
});

const StyledSlidebar = styled('div')({
  backgroundColor: `${backgroundTheme.block} !important`,

  'ul li, span': {
    ...textColor.DARK,
  },

  'ul li:hover': {
    backgroundColor: slidebarTheme.listHover,
  },
});

const BackArrowContainer = styled('div')({
  position: 'fixed',
  top: 16,
  borderRadius: '50%',
  width: 'auto',
  height: 'auto',
  boxShadow: isDarkMode() ? '1px 1px 4px rgba(4, 26, 42, 0.75)' : '1px 1px 4px #cbcbcb',
  background: isDarkMode() ? 'rgba(4, 26, 42, 0.75)' : '#FFF',
  fontSize: 14,
});

const SidebarTitle = styled('div')({
  '> span': { ...textColor.DARK_2 },
});

const handleAccountTabScrollLock = hidden => {
  const element = document.getElementById(`child-sidebar1-Account Settings`);

  if (element) {
    element.style.overflowY = hidden ? 'hidden' : 'scroll';
    element.scrollTop = 0;
  }
};

const tabDataChildren = (tabData, activeTab, activeTabName, setActiveTab, setActiveTabName) => {
  if (tabData.children) {
    document.getElementById('sidebar-parent').scrollTop = 0;
    const data = [...activeTab];
    const dataName = [...activeTabName];
    data.push(tabData.id);
    dataName.push(tabData.name);
    setActiveTab(data);
    setActiveTabName(dataName);
    document.getElementById('sidebar-header').scrollIntoView({ behavior: 'smooth' });
    enableBodyScroll(document.getElementById('sidebar-parent'));
    disableBodyScroll(document.getElementById(`child-sidebar1-${tabData.name}`));
    document.getElementById('sidebar-parent').style.overflowY = 'hidden';
    setCustomSideBarMainContentStyle(false);

    if (tabData.id === 30) {
      handleAccountTabScrollLock(false);
      setCustomSideBarMainContentStyle(true);
    }
  }
};

const setCustomSideBarMainContentStyle = addPadding => {
  const smcElem = document.getElementById('sidebar-main-content-account-settings');

  if (!smcElem) {
    return;
  }

  if (!getBrowserDetails().isMobile) {
    return;
  }

  smcElem.style.paddingBottom = addPadding ? '200px' : '0';
};

const setActiveTabHeight = (activeTab, activeTabName) => {
  if (activeTab.length > 0) {
    const client = document.getElementById(`child-sidebar1-${activeTabName[0]}`);
    client.style.minHeight = '';
  }
};

const handleBackClickBehaviour = (tabData, activeTab, activeTabName, setActiveTab, setActiveTabName, resetTabs) => {
  if (tabData) {
    const data = [...activeTab];
    const dataName = [...activeTabName];
    const index = data.findIndex(id => id === tabData.id);
    const indexforName = dataName.findIndex(name => name === tabData.name);
    data.splice(index, 1);
    dataName.splice(indexforName, 1);
    setActiveTab(data);
    setActiveTabName(dataName);
    handleAccountTabScrollLock(false);
    document.getElementById('sidebar-parent').style.overflowY = 'scroll';
    disableBodyScroll(document.getElementById('sidebar-parent'));
    setCustomSideBarMainContentStyle(false);
  } else {
    resetTabs();
  }
};

const MultilevelSidebar = (props: any): any => {
  const { open, wrapperClassName, headerClassName, header, options, width, hasNotify, onToggleHide } = props;
  const [activeTab, setActiveTab] = useState([]);
  const [activeTabName, setActiveTabName] = useState([]);
  const {
    setCurrentPhoneSettingsStep,
    setHasRequestOtpError,
    setShowOtpCountdown,
    forceCloseSliderMenu,
    setPaymentSettingsOpen,
    forceResetTabs,
  } = useApp();
  const location = useLocation();

  const resetTabs = (shouldDisableBodyScroll = true) => {
    const childSidebar = document.getElementById(`child-sidebar1-${activeTabName[0]}`);
    childSidebar && enableBodyScroll(childSidebar);

    if (shouldDisableBodyScroll) {
      disableBodyScroll(document.getElementById('sidebar-parent'));
    }

    document.getElementById('sidebar-parent').style.overflowY = 'scroll';
    handleAccountTabScrollLock(false);
    setActiveTab([]);
    setActiveTabName([]);
    setCustomSideBarMainContentStyle(false);
  };

  useEffect(() => {
    onToggleHide();
    removeBodyScroll();

    if (Array.isArray(activeTab) && activeTab.length > 0) {
      resetTabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    hasNotify &&
      setTimeout(() => {
        setActiveTab([]);
        setActiveTabName([]);
        clearAllBodyScrollLocks();
        document.getElementById('sidebar-parent').style.overflowY = 'scroll';
        handleAccountTabScrollLock(false);
      }, 500);
  }, [hasNotify]);

  useEffect(() => {
    setTimeout(() => {
      setActiveTabHeight(activeTab, activeTabName);
    }, 500);
  }, [activeTab, activeTabName]);

  const resetChangePhoneSettings = () => {
    // used for back drop and back button
    setCurrentPhoneSettingsStep(1);
    setHasRequestOtpError('');
    setHasRequestOtpError('');
    setShowOtpCountdown(false);
  };

  const handleBackdropClick = () => {
    const { onToggle, onClose } = props;
    onToggle(false);
    onClose && onClose();
    setCustomSideBarMainContentStyle(false);
    removeBodyScroll();
    resetTabs();

    resetChangePhoneSettings();
    setPaymentSettingsOpen(false);
  };

  useEffect(() => {
    // listen to trigger close the slider menu inside the slider menu content
    if (forceCloseSliderMenu > 0) {
      handleBackdropClick();
    }
  }, [forceCloseSliderMenu]);

  useEffect(() => {
    // listen to trigger close the slider menu inside the slider menu content
    if (forceResetTabs > 0) {
      resetTabs();
    }
  }, [forceResetTabs]);

  const handleVysionTabClickEvent = (tabName: string) => {
    switch (tabName) {
      case 'Account Settings':
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_MENU_SETTINGS,
        });
        break;
      case 'Change Phone Number':
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_MENU_CHANGE_PHONE_NUMBER,
        });
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.VIEW,
          event: PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_CHANGE_PHONE_NUMBER,
        });
        break;
      case 'Payment Settings':
        handlePaymentVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS,
        });
        handlePaymentVysionEvent({
          action: VYSION_ACTIONS.VIEW,
          event: PaymentViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS,
        });
        break;
      case 'Deactivate or Delete My Account':
        localStorage.removeItem('came_from_delete_page');

        mixpanelTrackDeactivateOrDeleteMyAccountClicked();
        break;
    }
  };

  const handleTabClick = tabData => {
    const { onItemClick } = props;
    if (tabData.disabled) {
      return;
    }

    if (tabData.id === 42) {
      setPaymentSettingsOpen(true);
    }

    handleVysionTabClickEvent(tabData?.name ?? '');
    tabDataChildren(tabData, activeTab, activeTabName, setActiveTab, setActiveTabName);

    if (tabData.link) {
      resetTabs();
    }
    onItemClick && onItemClick(tabData);
  };

  const handleBackClick = tabData => {
    const { onBackClick } = props;

    handleBackClickBehaviour(tabData, activeTab, activeTabName, setActiveTab, setActiveTabName, resetTabs);
    onBackClick && onBackClick(activeTab);

    resetChangePhoneSettings();

    if (tabData.id === 42) {
      setPaymentSettingsOpen(false);
    }
  };

  const getParentHeight = () => {
    if (activeTab.length > 0) {
      if (activeTab.includes(30)) {
        return '1080px';
      }

      return '900px';
    }
    return '100vh';
  };

  const renderBackBtn = (handleClick, dataTestId, list) => {
    return (
      <BackArrowContainer style={{ left: 16 }}>
        <Button
          customStyle={{ padding: 15, height: 'unset' }}
          buttonType="link"
          onClick={() => handleClick(list)}
          data-test-id={dataTestId}
        >
          <ArrowLeft
            width="14"
            color={backgroundTheme.iconFill}
            cssStyle={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) rotateZ(180deg)',
              transformStyle: 'preserve-3d',
            }}
          />
        </Button>
      </BackArrowContainer>
    );
  };

  const renderSecondChildren = (parent, list) => {
    return (
      <SidebarContent
        {...props}
        sidebarProps={{
          id: `child-sidebar2-${list.name}`,
          key: list.key || list.id,
          className: classNames('sidebar-main second', {
            show: activeTab.includes(list.id),
            [wrapperClassName]: wrapperClassName,
          }),
          style: { minHeight: getParentHeight(), width, overflow: 'hidden', background: sideBarNav.bgGradient },
        }}
        headerContent={
          <SidebarTitle id={`subHeader_${list.id}`} className="second-back-btn" style={{ borderBottom: 'none' }}>
            {renderBackBtn(handleBackClick, 'second-back-btn', list)}
            <span>{list.title || list.name}</span>
          </SidebarTitle>
        }
        options={list.children}
        handleTabClick={handleTabClick}
      />
    );
  };

  const renderFirstChildren = list => {
    return (
      <SidebarContent
        {...props}
        sidebarProps={{
          id: `child-sidebar1-${list.name}`,
          key: list.key || null,
          className: classNames('sidebar-main second', {
            show: activeTab.includes(list.id),
            [wrapperClassName]: wrapperClassName,
          }),
          style: { minHeight: getParentHeight(), width: width, background: sideBarNav.bgGradient },
        }}
        headerContent={
          <SidebarTitle id={`subHeader_${list.id}`} className="first-back-btn">
            {renderBackBtn(handleBackClick, 'first-back-btn', list)}
            <span>{list.name}</span>
          </SidebarTitle>
        }
        options={list.children}
        handleTabClick={handleTabClick}
      >
        {data => data.children && renderSecondChildren(list, data)}
      </SidebarContent>
    );
  };

  return (
    <div id="react-sidebar" className="slidebar">
      <div className={classNames('sidebar-backdrop', { show: open })} onClick={handleBackdropClick} />
      <SidebarContent
        {...props}
        sidebarProps={{
          id: 'sidebar-parent',
          className: classNames(`sidebar-main ${props.isMobile ? 'mobile' : 'desktop'}`, {
            show: open,
            [wrapperClassName]: wrapperClassName,
          }),
          style: { width: width, background: sideBarNav.bgGradient },
        }}
        headerContent={
          typeof header === 'string' ? (
            <div
              className={`sidebar-header ${classNames({
                [headerClassName]: headerClassName,
              })}`}
            >
              {header}
            </div>
          ) : (
            <div
              className={classNames({
                [headerClassName]: headerClassName,
              })}
            >
              {header}
            </div>
          )
        }
        options={options}
        handleTabClick={handleTabClick}
      >
        {list => list.children && renderFirstChildren(list)}
      </SidebarContent>
    </div>
  );
};

export default MultilevelSidebar;

const SidebarContent = props => {
  const { sidebarProps, headerContent, options, children, handleTabClick } = props;

  const parentMenu = (id, hasSecondChild = false) => {
    return identifyParent(id) && !hasSecondChild
      ? { fontSize: 20 }
      : { maxWidth: getBrowserDetails().isMobile ? 260 : 320 };
  };

  // create a method to determine which menu links are parent ones
  const identifyParent = id => {
    const { parentMenuItems } = props;
    for (const item of parentMenuItems) {
      if (id === item) {
        return true;
      }
    }
    return false;
  };

  const renderParentLink = list => {
    return (
      <li
        id={identifyParent(list.id) ? `${list.name}-Section` : `${list.name}`}
        className={classNames({
          disabled: list.disabled,
        })}
        onClick={() => handleTabClick(list)}
      >
        {list.name !== '' && (
          <span className="flex-align-center">
            <span style={parentMenu(list.id)}>{list.name}</span>
          </span>
        )}
        {(children && list.children && list.icon) || list.icon}
      </li>
    );
  };

  const renderlink = (fullurl, list) => {
    if (fullurl) {
      return (
        <a data-test-id={`cypress-${list.name}-link`} href={list.to} target="_blank" rel="noreferrer">
          {renderParentLink(list)}
        </a>
      );
    } else {
      return <Link to={list.to}>{renderParentLink(list)}</Link>;
    }
  };

  const renderList = (isParent, list) => {
    return isParent ? `${list.name}-Section` : `${list.name}`;
  };

  const titleIcon = data => {
    return data.titleIcon ? data.titleIcon : '';
  };

  const navList = (list, index) => {
    const withListProperties = Object.keys(list);
    return (
      withListProperties.length > 0 && (
        <div key={index}>
          {list.to && !list.children && !list.disabled ? (
            renderlink(list.fullurl, list)
          ) : (
            <li
              id={renderList(identifyParent(list.id), list)}
              className={classNames({ disabled: list.disabled })}
              onClick={() => handleTabClick(list)}
            >
              {!list.noLabel && list.name !== '' && (
                <span className="flex-align-center">
                  <span style={parentMenu(list.id, list?.hasSecondChild ?? false)}>{list.name}</span>
                </span>
              )}
              {(children && list.children && list.icon) || list.icon}
            </li>
          )}
          {children && children(list)}
        </div>
      )
    );
  };

  return (
    <StyledSlidebar {...sidebarProps}>
      <div className="sidebar-main-content" id={`sidebar-main-content-${sidebarProps.key || sidebarProps.id}`}>
        {headerContent}
        <div className="sidebar-body">
          {options.map((data, idx) => {
            return (
              <div key={idx}>
                {!(!!data.hideBorder || idx === 0) && <hr className="section-seprator" />}
                {data.title && (
                  <div className="section-heading">
                    {titleIcon(data)}
                    <span className={classNames({ text: data.titleIcon })} style={{ color: buttonTheme.darkReverse }}>
                      {data.title}
                    </span>
                  </div>
                )}
                {data?.isCustomComponent ? (
                  <div>{data.content}</div>
                ) : (
                  <ul>
                    {data.content.length > 0 ? (
                      data.content.map((list, index) => {
                        {
                          if (!list.hidden) {
                            return navList(list, index);
                          } else {
                            return <div key={index}></div>;
                          }
                        }
                      })
                    ) : (
                      <div style={{ padding: '1.3rem 1.5rem 1.3rem 1.5rem' }} />
                    )}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <SidebarFooter />
    </StyledSlidebar>
  );
};

MultilevelSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  persist: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  onClose: PropTypes.func,
  onItemClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

MultilevelSidebar.defaultProps = {
  persist: false,
};
