import _camelCase from 'lodash/camelCase';
import _capitalize from 'lodash/capitalize';
import { VYSION_ACTIONS } from '..';
import { IProfileCompletionVysionEvents } from './common';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
};

export const enum ProfileCompletionOnFocusVysionEventsEnum {
  FOCUS_ABOUT_ME = 'finishMyProfileAboutMe',
  FOCUS_IDEAL_FIRST_DATE = 'finishMyProfileIdealFirstDate',
}

export const handleProfileCompletionOnFocusVysionEvents = (props: IProfileCompletionVysionEvents): any => {
  const pageName = _camelCase(props.page ?? 'Profile');
  const page = _capitalize(pageName);
  return {
    [ProfileCompletionOnFocusVysionEventsEnum.FOCUS_ABOUT_ME]: {
      ...commonValues,
      page,
      eventName: `${pageName}-finishMyProfile-aboutMe`,
      component: 'Finish my profile popup',
      extraInfo: 'About me',
    },
    [ProfileCompletionOnFocusVysionEventsEnum.FOCUS_IDEAL_FIRST_DATE]: {
      ...commonValues,
      page,
      eventName: `${pageName}-finishMyProfile-idealFirstDate`,
      component: 'Finish my profile popup',
      extraInfo: 'Ideal first date',
    },
  };
};
