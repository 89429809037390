import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { renderAvatar } from '../../../common';
import { connectScreenSize } from 'react-screen-size';
import BlankSlate from '../../blocks/BlankSlate';
import Header from '../../blocks/Header';
import isEmpty from 'lodash/isEmpty';
import { borders, textType } from '../../../style';
import CardList from '../../../modules/CardList';
import Card from '../../../atoms/Card';
import { SectionHeader } from '../../../atoms/text';
import styled from '@emotion/styled';
import { ternaryFunc, lazilyEvaluateVal } from '../../../utils/helpers';
import { vysionTrack } from '../../../utils/vysion/vysionService';
import useMixPanel from '../../../hooks/useMixPanel';
import { useNavigate } from 'react-router-dom';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../constants/mixpanel';

const HeaderStyled = styled(Header)(
  {
    marginBottom: '10px',
    padding: '24px 0 8px 0',
    border: 0,
    borderTop: borders.DEFAULT,
  },
  ({ firstSection }) =>
    firstSection && {
      borderTop: 'none',
      padding: '0 0 8px 0',
    }
);

const SearchMoreBox = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const SearchMoreLink = styled('div')({
  cursor: 'pointer',
  paddingTop: 12,
  paddingBottom: 12,
  textTransform: 'capitalize',
  ...textType.text,
  color: '#1383d2',
});

const MemberGrid = ({
  size = 8,
  screenSizeIsMobile,
  screenSizeIsSmall,
  title,
  members,
  criteria,
  saveSearch = false,
  saveFilters,
  id,
  firstSection = false,
}) => {
  const { trackProfileView, trackSearchView, setMixpanelDataByKeyHandler } = useMixPanel();
  const navigate = useNavigate();

  const trackCardClickEvent = (hashId: string) => {
    vysionTrack({
      action: 'click',
      component: 'Profile Card',
      extraInfo: title,
      page: 'Dashboard',
    });

    const formatTitle = title.replace(/\b\w/g, x => x.toUpperCase());
    trackProfileView({ Source: `${formatTitle} Block`, User: hashId });
    setMixpanelDataByKeyHandler({
      key: EVENT_NAMES.OFFER_MADE,
      payload: { source: TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewDashboard },
    });
  };

  const renderCardList = () => {
    if (!isEmpty(members) && members[0].hash_id) {
      const items = [];

      for (const key in members) {
        if (key > size - 1) {
          break;
        }

        if (!(key in members)) continue;

        const member = members[key];
        const age = member.age;
        const avatar =
          lazilyEvaluateVal(member, 3, false, 'avatar', 'data', 'urls') && renderAvatar(member.avatar.data.urls);
        const city = member.city;
        const username = member.account ? member.account.data.username : '';
        const hashId = member.hash_id || username;
        const photoCount = parseInt(member.approved_public_photos_count);
        const privatePhotoCount = member.private_photos_count ? parseInt(member.private_photos_count) : 0;
        const online = member.online;
        const dataKey = `${dataTitle}_${key}`;
        const userVerified = member.id_verified;

        avatar &&
          items.push(
            <Card
              link={`/profile/${hashId}?origin=dashboard`}
              key={dataKey}
              age={age}
              city={city}
              username={username}
              hashId={hashId}
              photoCount={photoCount}
              privatePhotoCount={privatePhotoCount}
              online={online}
              imgUrl={avatar}
              cardWidth={ternaryFunc(screenSizeIsMobile, '48%', '24%')}
              cardMargin={ternaryFunc(screenSizeIsMobile, '5px 1%', '.5%')}
              detailsPadding={ternaryFunc(screenSizeIsMobile, '5px', '10px')}
              isVerified={userVerified}
              onClick={() => trackCardClickEvent(hashId)}
              screenIsMobile={screenSizeIsMobile || screenSizeIsSmall}
            />
          );
      }

      return <CardList isMobile={screenSizeIsMobile}>{items}</CardList>;
    } else {
      return <BlankSlate bordered={true} title="No members to display." />;
    }
  };

  const handleGoToSearchPageWithPresetFilters = () => {
    vysionTrack({
      action: 'click',
      component: 'View More',
      extraInfo: title,
      page: 'Dashboard',
    });

    // saveSearch to indicate if search should be stored
    const history = { pathname: '/search', saveSearch };
    let searchSource = title;

    if (title !== 'Last Search') {
      const formatTitle = title.replace(/\b\w/g, x => x.toUpperCase());
      searchSource = `${formatTitle} Block`;
    }

    trackSearchView(true, { Source: searchSource });

    if (criteria && Object.keys(criteria).length > 0) {
      history.search = `?${queryString.stringify(criteria)}`;
      saveFilters(criteria);
    }

    navigate(
      {
        pathname: history.pathname,
        search: history.search,
      },
      {
        state: {
          saveSearch,
        },
      }
    );
  };

  const renderSearchIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="6 -8 20 36">
        <path
          fill="#1383d2"
          d="M20.4902559,19.6466556 L20.4902559,19.6466556 L26.1719263,25.328326 C26.6600816,25.8164813 26.6600816,26.6079376 26.1719263,27.0960929 C25.6837709,27.5842483 24.8923147,27.5842483 24.4041593,27.0960929 L18.7039228,21.3958564 C18.6629129,21.3548465 18.6253481,21.3116959 18.5912287,21.2667644 C17.3302096,22.0486071 15.842819,22.5 14.25,22.5 C9.69365081,22.5 6,18.8063492 6,14.25 C6,9.69365081 9.69365081,6 14.25,6 C18.8063492,6 22.5,9.69365081 22.5,14.25 C22.5,16.3134755 21.742434,18.200012 20.4902559,19.6466556 Z M14.25,20 C17.4256373,20 20,17.4256373 20,14.25 C20,11.0743627 17.4256373,8.5 14.25,8.5 C11.0743627,8.5 8.5,11.0743627 8.5,14.25 C8.5,17.4256373 11.0743627,20 14.25,20 Z"
        />
      </svg>
    );
  };

  const dataTitle = title
    .split(' ')
    .join('-')
    .toLowerCase();
  return (
    <div data-test-id={dataTitle} id={id}>
      <HeaderStyled bottomRuled firstSection={firstSection}>
        <SectionHeader>{title}</SectionHeader>
      </HeaderStyled>
      {renderCardList()}
      <SearchMoreBox>
        <SearchMoreLink data-test-id="user-grid-search-more-link" onClick={handleGoToSearchPageWithPresetFilters}>
          {renderSearchIcon()}
          View more
        </SearchMoreLink>
      </SearchMoreBox>
    </div>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
    screenSizeIsSmall: screenSize.small,
  };
};

export default connectScreenSize(mapScreenToProps)(MemberGrid);

MemberGrid.propTypes = {
  title: PropTypes.string,
  members: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  size: PropTypes.number,
  saveSearch: PropTypes.bool,
};
