import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { CountryRegionData } from 'react-country-region-selector';
import CaretDown from '../icons/CaretDown';
import StatusIcon from '../atoms/icons/StatusIcon';
import { borders, spacing, statusColor, inputTheme } from '../style/';
import InputLabel from './InputLabel';
import { getBlacklistedCountries, getPriorityCountries } from '../common';
import { isDarkMode } from '../utils/helpers';

const dropdownStyles = {
  border: borders.DARKER,
  boxShadow: '0 1px 4px 0 rgba(0,0,0,.04)',
  padding: '0 14px',
  borderRadius: 3,
  paddingRight: 26,
  appearance: 'none',
  backgroundColor: 'transparent',
  display: 'inline-block',
  fontSize: 14,
  lineHeight: '38px',
  cursor: 'pointer',
  background: inputTheme.backgroundInput,
  color: inputTheme.colorInput,
  width: '100%',
  height: 40,

  '&:focus': {
    outline: 'none',
    border: borders.FOCUS,
  },
};

const Dropdown = styled('select')`
  ${{ ...dropdownStyles }};
  border-color: ${({ status }) => statusColor[status]};
  ${({ customSelectStyle }) => css(customSelectStyle)}
  }),
`;

const StatusDescription = styled('div')(
  {
    position: 'absolute',
    ...spacing.MARGIN_TOP_XS,
  },
  ({ status }) => ({
    color: statusColor[status],
  })
);

const Icon = styled('div')({
  position: 'absolute',
  right: 12,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  pointerEvents: 'none',
});

const DropdownContainer = styled('div')({
  position: 'relative',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const StatusIconContainer = styled('div')`
  position: absolute;
  right: -24px;
`;

const SelectContainer = styled('div')(
  ({ noSpacing }) =>
    noSpacing
      ? {
          paddingBottom: 0,
          marginRight: 0,
        }
      : {
          paddingBottom: 24,
          marginRight: 24,
        },
  ({ customStyle }) => css(customStyle)
);

const renderStatusIcon = status => {
  return (
    status !== 'default' && (
      <StatusIconContainer>
        {' '}
        <StatusIcon status={status} />
      </StatusIconContainer>
    )
  );
};

const renderStatusMessage = (status, statusMessage) => {
  return (
    statusMessage && status !== 'default' && <StatusDescription status={status}>{statusMessage}</StatusDescription>
  );
};

interface BaseSelectProps {
  id: string;
  inputLabel: string;
  tooltip: {
    icon: unknown;
    iconWidth: string;
    text: string;
    overlayStyle: Record<string, unknown>;
    placement: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  };
  labelDescription: string;
  status: string;
  statusMessage: string;
  style: Record<string, unknown>;
  noSpacing: boolean;
  children: unknown;
  noStatusIcon: boolean;
  isRequired: boolean;
  customStyleLabel?: Record<string, unknown>;
  baseSelectStyle?: React.CSSProperties;
}

export const BaseSelect = ({
  id,
  inputLabel,
  tooltip,
  labelDescription,
  status,
  statusMessage,
  style,
  noSpacing,
  children,
  noStatusIcon,
  isRequired = false,
  customStyleLabel = {},
  baseSelectStyle,
}: BaseSelectProps): JSX.Element => {
  const showStatus = !noSpacing;
  const darkMode = isDarkMode();

  return (
    <SelectContainer customStyle={baseSelectStyle ? baseSelectStyle : style} noSpacing={noSpacing}>
      {inputLabel ? (
        <InputLabel
          label={inputLabel}
          tooltip={tooltip}
          labelDescription={labelDescription}
          inputId={`label-${id}`}
          data-test-id={`label-${id}`}
          isBilling
          isRequired={isRequired}
          customStyle={customStyleLabel}
        />
      ) : null}
      <DropdownContainer className={darkMode ? 'darkmode' : 'lightmode'} data-test-id={`select-${id}`}>
        {children}
        <Icon>
          <CaretDown />
        </Icon>
        {showStatus && !noStatusIcon && renderStatusIcon(status)}
      </DropdownContainer>
      {showStatus && renderStatusMessage(status, statusMessage)}
    </SelectContainer>
  );
};

export const Select = ({ customDropDownStyle, ...rest }: any): JSX.Element => {
  return (
    <BaseSelect {...rest}>
      <Dropdown {...rest} style={{ ...rest.style, ...customDropDownStyle }}>
        {rest.children}
      </Dropdown>
    </BaseSelect>
  );
};

interface CountrySelectProps {
  status: any;
  countryDropdownType?: string;
  value: any;
  style: any;
  isBilling: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isRequired: boolean;
}

export const CountrySelect = ({ status, isRequired = false, ...rest }: CountrySelectProps): any => {
  const blacklist = getBlacklistedCountries();
  const priority = getPriorityCountries();

  return (
    <BaseSelect status={status} isRequired={isRequired} {...rest}>
      <Dropdown {...rest}>
        <option>Select Country</option>
        {CountryRegionData.map((item, key) => {
          if (blacklist.indexOf(item[1]) < 0 && priority.indexOf(item[1]) > -1) {
            return (
              <option key={key} value={item[1]}>
                {item[0]}
              </option>
            );
          }
        }).reverse()}
        <option>----</option>
        {CountryRegionData.map((item, key) => {
          if (blacklist.indexOf(item[1]) < 0 && priority.indexOf(item[1]) < 0) {
            return (
              <option key={key} value={item[1]}>
                {item[0]}
              </option>
            );
          }
        })}
      </Dropdown>
    </BaseSelect>
  );
};

const baseSelectPropTypes = {
  /** Unique id for select dropdown */
  id: PropTypes.string,
  /** Label of select input */
  inputLabel: PropTypes.string,
  /** Label description */
  labelDescription: PropTypes.string,
  /** Tooltip next to label */
  tooltip: PropTypes.shape({
    icon: PropTypes.any,
    iconWidth: PropTypes.string,
    text: PropTypes.string,
    overlayStyle: PropTypes.styles,
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  }),
  /** Status that determines input style */
  status: PropTypes.oneOf(['default', 'success', 'error', 'caution']),
  /** Status message */
  statusMessage: PropTypes.string,
  /** Determines if status icon is visible */
  showStatusIcon: PropTypes.bool,
  /** Removes padding and margin defined to display status icons and messages - setting this to true will remove the abiity for the dropdown to display a status message/icon */
  noSpacing: PropTypes.bool,
};

BaseSelect.propTypes = {
  ...baseSelectPropTypes,
};

// Prop types

Select.propTypes = {
  ...baseSelectPropTypes,
  /** Default value of dropdown */
  defaultValue: PropTypes.string,
  /** Disable dropdown */
  disabled: PropTypes.bool,
  /** Function to handle change of selection */
  onChange: PropTypes.func.isRequired,
};

CountrySelect.propTypes = {
  ...baseSelectPropTypes,
  /** Function to handle change of selection */
  onChange: PropTypes.func.isRequired,
};

// Default props

BaseSelect.defaultProps = {
  status: 'default',
  showStatusIcon: true,
};

Select.defaultProps = {
  status: 'default',
};

CountrySelect.defaultProps = {
  status: 'default',
};
