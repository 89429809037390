import React, { ReactElement } from 'react';

interface Props {
  customStyle?: React.CSSProperties;
}

const Checkmark: React.FC<Props> = ({ customStyle }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      fill="currentColor"
      fillOpacity="0"
      stroke="#fff"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ height: '32px', width: '32px', display: 'block', ...customStyle }}
    >
      <path d="M19.1 25.2l4.7 6.2 12.1-11.2" />
    </svg>
  );
};

export default Checkmark;
