import React, { ReactElement, useState, useEffect } from 'react';
import Toggle from '../../../atoms/toggle/Toggle';
import styled from '@emotion/styled';

interface GiftSentToggleProps {
  type: 'wishlist' | 'gift_sent';
  toggle: boolean;
  toggleAction: any;
}

const GiftSentToggleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const GiftSentToggle = ({ type, toggle, toggleAction }: GiftSentToggleProps): ReactElement => {
  const [isToggle, setIsToggle] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleToggle = () => {
    setIsDisabled(true);
    setIsToggle(state => {
      setIsDisabled(true);
      toggleAction({ type, value: !state }, () => setIsDisabled(false));
      return !state;
    });
  };

  useEffect(() => {
    setIsToggle(toggle);
  }, [toggle]);

  return (
    <GiftSentToggleContainer>
      <Toggle
        toggleId="show_gift_sent_toggle"
        isChecked={Boolean(isToggle)}
        onChange={handleToggle}
        disabled={isDisabled}
      />
    </GiftSentToggleContainer>
  );
};

export default GiftSentToggle;
