export default {
  light: {
    primary: '#DAE1E9',
    secondary: '#8392A7',
    line1: '#DAE1E9',
    green: '#5EBE5E',
  },
  dark: {
    primary: '#6C6C6C',
    primaryCard: '#8392A7',
    line1: '#8392A7',
  },
};
