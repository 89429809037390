import React from 'react';
import { theme } from '../../../../theme';
import typography from '../../../../theme/typography';
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton';
import uuidv4 from 'uuid';
import GalleryVideoUploaderMediaCard from './GalleryVideoUploaderMediaCard';
import GalleryUploadMobileActionButtonContainer from '../components/GalleryUploadMobileActionButtonContainer';
import GalleryUploadDesktopActionButtonContainer from '../components/GalleryUploadDesktopActionButtonContainer';
import { FileListTypes, VideoFileType } from '../../constants';
import { MAX_NUMBER_PHOTOS } from '../../../../config/Master';
import {
  StyledCountDetailsContainer,
  StyledCounterH2,
  StyledMediasContainer,
  StyledMediasInnerContainer,
  StyledVerticalLine,
} from '../styles';

interface ISelectedPhotosProps {
  existingUploadedMediaCount?: number;
  files: FileListTypes;
  isLoading: boolean;
  onClose: () => void;
  onPlay: (file: any) => void;
  onRemove: (video: VideoFileType) => void;
  onToggleVisibility: (video: VideoFileType) => any;
  onSubmit: () => void;
}

const GalleryVideoUploaderPreview: React.FC<ISelectedPhotosProps> = (props: ISelectedPhotosProps) => {
  const {
    existingUploadedMediaCount = 10,
    files = [],
    onClose,
    onPlay,
    isLoading,
    onRemove,
    onToggleVisibility,
    onSubmit,
  } = props;

  if (!files || files.length < 1) return null;

  const totalFilesCount = existingUploadedMediaCount + files.length;
  const buttonLabel = `Upload (${files.length}) Videos`;

  return (
    <>
      <StyledCountDetailsContainer>
        <StyledCounterH2 isValidCount={totalFilesCount > MAX_NUMBER_PHOTOS}>
          {totalFilesCount}/{MAX_NUMBER_PHOTOS}
        </StyledCounterH2>
        <StyledVerticalLine />
        <span
          style={{
            color: typography[theme].primaryText,
            fontSize: 14,
            letterSpacing: -0.28,
          }}
        >
          Total Profile Photos & Videos
        </span>
      </StyledCountDetailsContainer>
      <StyledMediasContainer>
        <StyledMediasInnerContainer>
          {files.map(file => {
            return (
              <GalleryVideoUploaderMediaCard
                file={file}
                key={uuidv4()}
                onToggleVisibility={() => onToggleVisibility(file)}
                onRemove={() => onRemove(file)}
                onPlay={() => onPlay(file)}
                isLoading={isLoading}
              />
            );
          })}
        </StyledMediasInnerContainer>
      </StyledMediasContainer>
      <GalleryUploadDesktopActionButtonContainer>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={onSubmit}
          disabled={totalFilesCount > MAX_NUMBER_PHOTOS}
          customStyle={{
            width: 'inherit',
            minWidth: 150,
          }}
        />
      </GalleryUploadDesktopActionButtonContainer>
      <GalleryUploadMobileActionButtonContainer onCancel={onClose}>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={onSubmit}
          disabled={totalFilesCount > MAX_NUMBER_PHOTOS}
          customStyle={{
            width: 'inherit',
            minWidth: 150,
          }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </>
  );
};

export default GalleryVideoUploaderPreview;
