/** @jsx jsx */
import Button from './Button';
import styled from '@emotion/styled';
import { dimensions, buttonTheme, backgroundTheme, buttonIcons } from '../../style';
import { getBrowserDetails } from '../../utils/helpers';

const { isSafari } = getBrowserDetails();

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'max-content',
});

const DefaultButton = styled('button')(
  {
    color: `${buttonTheme.darkReverse} !important`,
    padding: 0,
    background: backgroundTheme.block,
    borderRadius: '50%',
    width: 55,
    height: 55,
    border: buttonIcons.borderStyle,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    position: 'relative',
    display: 'block',
    outline: 0,
    [dimensions.SCREEN_MAX_MD]: {
      padding: 0,
      width: 40,
      height: 40,
      borderWidth: 2,
    },
    [dimensions.SCREEN_MAX_SM]: {
      padding: 0,
      width: 40,
      height: 40,
      borderWidth: 2,
    },
    '+label': {
      display: 'block',
      fontSize: 14,
      color: 'rgba(62, 121, 165, 1)',
      [dimensions.SCREEN_MAX_MD]: {
        fontSize: 11,
      },
      [dimensions.SCREEN_MAX_SM]: {
        fontSize: 11,
      },
    },
    margin: '5px 10px',
  },
  ({ isFavorited }) =>
    isFavorited && {
      color: 'rgba(188, 32, 40, 1) !important',
      background: 'rgba(188, 32, 40, 1) !important',
      border: buttonIcons.borderStyleHover,
    },
  ({ page }) =>
    page &&
    (page === 'viewed_me' || page === 'from') && {
      margin: '5px 10px',
      [dimensions.SCREEN_MAX_SM]: {
        margin: '5px 10px',
      },
    }
);

const CustomButton = styled(DefaultButton)`
  & svg path {
    fill: ${props => (props.isFavorited ? 'white' : 'rgba(62, 121, 165, 1)')};
  }
`;

const IconContainer = styled('div')`
  display: flex;
  & > svg {
    height: 38.21px;
  }

  ${dimensions.SCREEN_MAX_MD} {
    margin-right: 0px;
    & > svg {
      height: 28.21px;
    }
  }
  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 28.21px;
    }
  }
`;

const ButtonText = styled('div')`
  display: block;
  align-items: center;
  font-family: system-ui, -apple-system, Arial, sans-serif;
  ${dimensions.SCREEN_MAX_SM} {
    display: none;
  }
`;

const ButtonWrap = styled(Button)`
  ${dimensions.SCREEN_MAX_SM} {
    display: none;
  }
`;

const DismissButton = styled(DefaultButton)`
  &:hover {
    color: rgba(149, 199, 235, 1) !important;
    background: rgba(149, 199, 235, 1) !important;
  }

  &:hover svg path {
    fill: rgba(255, 255, 255, 1);
  }
`;

const DismissIconContainer = styled('div')`
  margin-top: 5px;

  & > svg {
    fill: rgba(62, 121, 165, 1);
    height: 24px;
    width: 24px;
  }

  ${dimensions.SCREEN_MAX_MD} {
    margin-right: 0px;
    & > svg {
      height: 17px;
    }
  }

  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 17px;
    }
  }
`;

const OfferNewButton = styled(DefaultButton)`
  &:hover {
    color: rgba(255, 175, 0, 1) !important;
    background: rgba(255, 175, 0, 1) !important;
  }

  &:hover svg path {
    fill: rgba(255, 255, 255, 1);
  }
`;

const OfferIconContainer = styled('div')`
  & > svg {
    fill: rgba(62, 121, 165, 1);
    height: 31px;
    width: 17px;
  }

  ${dimensions.SCREEN_MAX_MD} {
    margin-right: 0px;
    & > svg {
      height: 20px;
    }
  }
  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 20px;
    }
  }
`;

const OfferDeclineButton = styled(DefaultButton)`
  &:hover {
    color: rgba(188, 32, 40, 1) !important;
    background: rgba(188, 32, 40, 1) !important;
    &::before {
      border-bottom: 2px solid #fff;
    }
  }

  &:hover svg path {
    fill: rgba(205, 88, 94, 1);
  }

  &::before {
    content: '';
    position: absolute;
    border-bottom: ${buttonIcons.borderCrashOutStyle};
    transform: translateY(25px) translateX(10px) rotate(-44deg);
    top: -47px;
    left: -30px;
    width: 54px;
    height: 54px;
    z-index: 1;

    ${dimensions.SCREEN_MAX_MD} {
      top: -43px;
      left: -24px;
      width: 40px;
      height: 40px;
      border-bottom-width: 2px;
    }
    ${dimensions.SCREEN_MAX_SM} {
      top: -43px;
      left: -24px;
      width: 40px;
      height: 40px;
      border-bottom-width: 2px;
    }
    ${'@media (max-width: 550px)'} {
      top: -43px;
      left: -26px;
      width: 40px;
      height: 40px;
      border-bottom-width: 2px;
    }
  }
`;

const OfferDeclineIconContainer = styled('div')`
  & > svg {
    fill: rgba(238, 199, 201, 1);
    height: 27px;
    width: 30px;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 20px;
      position: absolute;
      top: 54%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const OfferCancelButton = styled(DefaultButton)`
  &:hover {
    color: rgba(188, 32, 40, 1) !important;
    background: rgba(188, 32, 40, 1) !important;
  }

  &:hover svg path {
    fill: rgba(255, 255, 255, 1);
  }
`;

const OfferCancelIconContainer = styled('div')`
  & > svg {
    fill: rgba(188, 32, 40, 1);
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${dimensions.SCREEN_MAX_MD} {
    margin-right: 0px;
    & > svg {
      height: 17px;
    }

  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 17px;
    }
  }
`;

const OfferAcceptButton = styled(DefaultButton)`
  &:hover {
    color: rgba(36, 168, 58, 1) !important;
    background: rgba(36, 168, 58, 1) !important;
  }

  &:hover svg path {
    fill: rgba(255, 255, 255, 1);
    d: path(
      'M15.8716 28.024L13.6713 26.021C5.85643 18.9344 0.697083 14.2607 0.697083 8.5247C0.697083 3.85094 4.36932 0.178703 9.04309 0.178703C11.6835 0.178703 14.2176 1.40784 15.8716 3.35018C17.5257 1.40784 20.0598 0.178703 22.7002 0.178703C27.3739 0.178703 31.0462 3.85094 31.0462 8.5247C31.0462 14.2607 25.8868 18.9344 18.0719 26.0361L15.8716 28.024Z'
    );
  }
`;

const OfferAccepIconContainer = styled('div')`
  display: flex;

  & > svg {
    fill: rgba(62, 121, 165, 1);
    height: 27px;
    width: ${isSafari ? '30px' : 'auto'};
  }

  ${dimensions.SCREEN_MAX_MD} {
    margin-right: 0px;
    & > svg {
      height: 20px;
    }
  }
  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 20px;
    }
  }
`;

const OfferCounterButton = styled(DefaultButton)`
  &:hover {
    color: rgba(43, 143, 215, 1) !important;
    background: rgba(43, 143, 215, 1) !important;
  }

  &:hover svg path {
    fill: rgba(255, 255, 255, 1);
  }
`;

const OfferCounterIconContainer = styled('div')`
  display: flex;

  & > svg {
    fill: rgba(62, 121, 165, 1);
    height: 24px;
    width: 24px;
  }

  ${dimensions.SCREEN_MAX_SM} {
    margin-right: 0px;
    & > svg {
      height: 17px;
    }
  }
`;

export {
  ButtonContainer,
  DefaultButton,
  CustomButton,
  IconContainer,
  ButtonText,
  ButtonWrap,
  OfferNewButton,
  OfferIconContainer,
  OfferDeclineButton,
  OfferDeclineIconContainer,
  OfferCancelButton,
  OfferCancelIconContainer,
  OfferAcceptButton,
  OfferAccepIconContainer,
  DismissButton,
  DismissIconContainer,
  OfferCounterButton,
  OfferCounterIconContainer,
};
