import React, { useEffect, useState, useRef, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ConfirmationModal from '../modules/modals/ConfirmationModal';
import { unsetBodyScrollOverflow } from '../hooks/useDisableBodyScroll';

const useStyles = makeStyles(theme => ({
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(
  {
    borderRadius: '20px !important',
    minWidth: '25px !important',
    fontSize: '18px !important',
    float: 'right',
    padding: '0px !important',
    cursor: 'pointer',
  },
  ({ iscenteralign }) => ({ float: iscenteralign === 'true' ? 'none' : 'right' })
);

const VideoGalleryMenu = (props: any): ReactElement => {
  const { theKey, setPrivacy, deleteMedia, isPrivate, mediaId, isCenterAlign, hasBulkSelection } = props;
  const classes = useStyles();
  const [openVideoGalleryMenuDropdown, setOpenVideoGalleryMenuDropdown] = useState(false);
  const videoGalleryAnchorRef = useRef(null);
  const [deleteMediaModalOpen, setDeleteMediaModalOpen] = useState(false);

  const toggledeleteMediaModal = () => {
    setDeleteMediaModalOpen(!deleteMediaModalOpen);

    if (openVideoGalleryMenuDropdown) {
      setOpenVideoGalleryMenuDropdown(false);
    }
  };

  const handleToggle = () => {
    setOpenVideoGalleryMenuDropdown(openVideoGalleryMenuDropdown => !openVideoGalleryMenuDropdown);
  };

  const handleClose = event => {
    if (videoGalleryAnchorRef.current && videoGalleryAnchorRef.current.contains(event.target)) {
      return;
    }

    setOpenVideoGalleryMenuDropdown(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenVideoGalleryMenuDropdown(false);
    }
  }

  const prevOpen = useRef(openVideoGalleryMenuDropdown);
  useEffect(() => {
    if (prevOpen && prevOpen.current === true && openVideoGalleryMenuDropdown === false) {
      videoGalleryAnchorRef.current.focus();
    }

    prevOpen.current = openVideoGalleryMenuDropdown;
  }, []);

  return (
    <div className={classes.root}>
      <StyledButton
        iscenteralign={isCenterAlign.toString()}
        ref={videoGalleryAnchorRef}
        aria-controls={openVideoGalleryMenuDropdown ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={hasBulkSelection}
      >
        <MoreHoriz style={{ color: hasBulkSelection ? 'rgba(13,92,147,0.9)' : '#fff' }} />
      </StyledButton>
      <Popper
        style={{ zIndex: 10 }}
        open={openVideoGalleryMenuDropdown}
        anchorEl={videoGalleryAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={openVideoGalleryMenuDropdown}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={toggledeleteMediaModal}>Delete Video</MenuItem>
                  <MenuItem
                    onClick={e => {
                      e.preventDefault();
                      setPrivacy(mediaId, theKey);

                      if (openVideoGalleryMenuDropdown) {
                        setOpenVideoGalleryMenuDropdown(false);
                      }
                    }}
                  >
                    {`${isPrivate ? 'Make Public' : 'Make Private'}`}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ConfirmationModal
        contentLabel="Delete Video Modal"
        isOpen={deleteMediaModalOpen}
        header="Delete Video"
        description="Are you sure? Deleting this video will remove it from your profile."
        cancelText="Cancel"
        confirmText="Delete"
        onClose={toggledeleteMediaModal}
        onConfirm={() => {
          unsetBodyScrollOverflow();
          deleteMedia(mediaId, theKey);
        }}
      />
    </div>
  );
};

export default VideoGalleryMenu;
