import { VYSION_ACTIONS } from '..';
import { EventData } from '../vysionService';
import { handleVysionEvent } from '../vysionService';
import { handleProfileCompletionClickVysionEvents } from './click';
import { handleProfileCompletionOnFocusVysionEvents } from './onFocus';
import { handleProfileCompletionViewVysionEvents } from './view';
import { TRACK_PAGE_VIEW_PATHS } from './common';

export const handleProfileCompletionVysionEvent = (data: EventData, pathName: string): void => {
  const page = TRACK_PAGE_VIEW_PATHS[pathName];
  const events = {
    [VYSION_ACTIONS.ON_FOCUS]: { ...handleProfileCompletionOnFocusVysionEvents({ page }) },
    [VYSION_ACTIONS.CLICK]: { ...handleProfileCompletionClickVysionEvents({ page }) },
    [VYSION_ACTIONS.VIEW]: { ...handleProfileCompletionViewVysionEvents({ page }) },
  };

  handleVysionEvent<typeof events>(data, events);
};
