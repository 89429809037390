import React, { ReactElement } from 'react';

const OfferMessageIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <path
        d="M4.58385 11.9973H10.5214C10.743 11.9973 10.925 11.9229 11.0675 11.7741C11.21 11.6254 11.2812 11.4336 11.2812 11.1988C11.2812 10.9798 11.206 10.7959 11.0555 10.6472C10.905 10.4984 10.719 10.424 10.4975 10.424H4.55991C4.35433 10.424 4.17631 10.4984 4.02583 10.6472C3.87535 10.7959 3.80011 10.9798 3.80011 11.1988C3.80011 11.4336 3.87535 11.6254 4.02583 11.7741C4.17631 11.9229 4.36231 11.9973 4.58385 11.9973ZM4.58385 9.01536H14.4401C14.6457 9.01536 14.8237 8.94117 14.9742 8.79279C15.1246 8.64403 15.1999 8.45996 15.1999 8.24058C15.1999 8.0058 15.1246 7.81422 14.9742 7.66584C14.8237 7.51708 14.6377 7.4427 14.4162 7.4427H4.55991C4.35433 7.4427 4.17631 7.51708 4.02583 7.66584C3.87535 7.81422 3.80011 8.0058 3.80011 8.24058C3.80011 8.45996 3.87535 8.64403 4.02583 8.79279C4.17631 8.94117 4.36231 9.01536 4.58385 9.01536ZM4.58385 6.03401H14.4401C14.6457 6.03401 14.8237 5.95963 14.9742 5.81088C15.1246 5.66212 15.1999 5.47824 15.1999 5.25923C15.1999 5.03985 15.1246 4.85597 14.9742 4.70759C14.8237 4.55883 14.6377 4.48446 14.4162 4.48446H4.55991C4.35433 4.48446 4.17631 4.55883 4.02583 4.70759C3.87535 4.85597 3.80011 5.03985 3.80011 5.25923C3.80011 5.47824 3.87535 5.66212 4.02583 5.81088C4.17631 5.95963 4.36231 6.03401 4.58385 6.03401ZM0 17.6084V2.27732C0 1.85472 0.158457 1.48696 0.47537 1.17404C0.791904 0.860748 1.16392 0.704102 1.59141 0.704102H17.4086C17.8361 0.704102 18.2081 0.860748 18.5246 1.17404C18.8415 1.48696 19 1.85472 19 2.27732V14.1571C19 14.5797 18.8415 14.9476 18.5246 15.2609C18.2081 15.5738 17.8361 15.7303 17.4086 15.7303H3.80011L1.35372 18.1487C1.10065 18.3989 0.811854 18.4575 0.48734 18.3245C0.162447 18.1916 0 17.9528 0 17.6084Z"
        fill="white"
      />
    </svg>
  );
};

export default OfferMessageIcon;
