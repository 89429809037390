import React, { ReactElement, useState, useEffect } from 'react';
import Toggle from '../../../atoms/toggle/Toggle';
import Tooltip from '../Tooltip';
import styled from '@emotion/styled';

interface GiftWishlistToggleProps {
  type: 'wishlist' | 'gift_sent';
  toggle: boolean;
  toggleAction: any;
}

const GiftWishlistToggleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const GiftWishlistToggle = ({ type, toggle, toggleAction }: GiftWishlistToggleProps): ReactElement => {
  const [isToggle, setIsToggle] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleToggle = () => {
    setIsDisabled(true);
    setIsToggle(state => {
      setIsDisabled(true);
      toggleAction({ type, value: !state }, () => setIsDisabled(false));
      return !state;
    });
  };

  useEffect(() => {
    setIsToggle(toggle);
  }, [toggle]);

  return (
    <Tooltip
      variant="primary"
      htmlElementLabel={`<strong>Show/Hide</strong> your wishlist on your profile`}
      trigger="hover"
      placement="top"
    >
      <GiftWishlistToggleContainer>
        <Toggle
          toggleId="show_wishlist_toggle"
          isChecked={Boolean(isToggle)}
          onChange={handleToggle}
          disabled={isDisabled}
        />
      </GiftWishlistToggleContainer>
    </Tooltip>
  );
};

export default GiftWishlistToggle;
