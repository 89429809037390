import React from 'react';
import { connect } from 'react-redux';
import { withPusher } from '../contexts/PusherContext';
import Container from '../components/blocks/Container';
import EditPhoneForm from '../components/phone/phoneSettings/EditPhoneForm';

const DemoContainer = () => {
  return (
    <Container screenTop>
      <div className="Demo" style={{ width: 428, border: 'solid 1px #ddd' }}>
        <EditPhoneForm />
      </div>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

// actions to refresh internal/external profile.
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withPusher(DemoContainer));
