import React, { ReactElement } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';

import Container from '../../blocks/Container';
import { spacing, type, borders } from '../../../style';
import Panel from '../../../atoms/panels/Panel';
import Header from '../../blocks/Header';
import Headline from '../../common/Headline';

const Item = styled('div')({
  display: 'table',
  ...type.MD,
  ...spacing.PADDING_SM,
  borderBottom: borders.DEFAULT,
  ':last-child': {
    borderBottomWidth: '0',
  },
});

const Cell = styled('div')({
  display: 'table-cell',
  ':first-of-type': {
    width: '10000px',
  },
});

interface BillingHistoryProps {
  payments: any;
}

const BillingHistory = ({ payments }: BillingHistoryProps): ReactElement => {
  const renderPaymentHistory = () => {
    // loop through each mail item.
    if (!_.isEmpty(payments)) {
      return Object.keys(payments).map(key => {
        const thePayment = payments[key];
        const thePackage = thePayment.package.data;
        return (
          <Item key={key}>
            <Cell>{thePackage.description}</Cell>
            <Cell>{thePayment.charge_amt}</Cell>
          </Item>
        );
      });
    }
  };

  if (payments) {
    return (
      <div>
        <Container>
          <Header>
            <Headline>Payment History</Headline>
          </Header>
        </Container>
        <Container fullWidth>
          <Panel padding="none">{renderPaymentHistory()}</Panel>
        </Container>
      </div>
    );
  } else {
    // no  payment history..
    return <div>No payment history</div>;
  }
};

export default BillingHistory;
