import { DEFAULT_REASON, EvidentlyFeatureExperiment } from '../../constants/evidently';
import { Profile } from '../../models/Profile';
import { TrackEvidentlyMetricsParams } from '../../sdk/CommonSDK';
import { getBrowserDetails } from '../helpers';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

export const GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME = 'EditableOffers';
export const GM_DISCOVER_MODE_EDITABLE_OFFER_EXPERIMENT_NAME = 'EditableOffers_DiscoverMode';
export const GM_DISCOVER_MODE_EDITABLE_OFFER_LOCAL_STORAGE_KEY = 'evidently_gm_discover_mode_editable_offer';

export type GMDiscoverModelEditableOfferVariations = 'Control' | 'NonEditableOffers';

/**
 * This is only used in batchEvaluation
 *
 * @param profile Profile
 * @returns boolean
 */
const handleIsFeatureEvaluate = (profile: Profile): boolean => {
  return profile.isGenerous() && getBrowserDetails().isMobile;
};

const handleFeatureCallback = async (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled: boolean
): Promise<void> => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  mixpanelTrackExperimentStarted({
    'Experiment name': 'Editable Offers in Discover Mode',
    'Variant name': details.variation === 'Control' ? 'Editable Offers' : 'Non-Editable Offers',
  });
};

const featureMetrics = (details: EvidentlyFeatureExperiment): TrackEvidentlyMetricsParams<string>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  return [
    {
      featureName: GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME,
      experimentName: GM_DISCOVER_MODE_EDITABLE_OFFER_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'editableOffersNonEditableOffersSent',
      value: 0,
    },
    {
      featureName: GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME,
      experimentName: GM_DISCOVER_MODE_EDITABLE_OFFER_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'editableOffersAcceptedOffersNonEditable',
      value: 0,
    },
  ];
};

export const GMDashboardEditableOfferEvaluation = [
  {
    featureMetrics,
    featureName: GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
