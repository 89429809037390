import React, { ReactElement } from 'react';
import { getBrowserDetails } from '../../utils/helpers';
interface IVerticalSeparatorProps {
  customStyle?: any;
}

const { isMobile } = getBrowserDetails();

const VerticalSeparator = ({ customStyle }: IVerticalSeparatorProps): ReactElement => {
  const styles = {
    viewBox: isMobile ? '0 0 1 64' : '0 0 1 94',
    y1: isMobile ? '63.5' : '93.5',
    height: isMobile ? '64' : '94',
  };

  return (
    <svg
      width="1"
      height={styles.height}
      viewBox={styles.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
    >
      <line
        x1="0.5"
        y1={styles.y1}
        x2="0.499997"
        y2="0.5"
        stroke="#60ABE1"
        strokeLinecap="round"
        strokeDasharray="2 4"
      />
    </svg>
  );
};

export default VerticalSeparator;
