import { vysionService } from '../utils/vysion/vysionService';

const setToastAction = (eventType: any, actionType: any): any => {
  return {
    type: 'OFFERS',
    payload: {
      event: eventType,
      eventData: {
        action: actionType,
        page: 'Toast',
        extraInfo: null,
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setComponent = eventName => {
  switch (eventName) {
    case 'TOAST MESSAGE':
      return 'Messaged You';
    case 'TOAST FAVOURITE':
      return 'Favorited You';
    case 'TOAST OFFER':
      return 'Offered You';
    default:
      return;
  }
};

const sendToastMessageView = (): any => {
  vysionService(setToastAction('TOAST MESSAGE', 'view'));
};

const sendToastMessageClick = (): any => {
  vysionService(setToastAction('TOAST MESSAGE', 'click'));
};

const sendToastFavouriteView = (): any => {
  vysionService(setToastAction('TOAST FAVOURITE', 'view'));
};

const sendToastFavouriteClick = (): any => {
  vysionService(setToastAction('TOAST FAVOURITE', 'click'));
};

const sendToastOfferView = (): any => {
  vysionService(setToastAction('TOAST OFFER', 'view'));
};

const sendToastOfferClick = (): any => {
  vysionService(setToastAction('TOAST OFFER', 'click'));
};

export {
  sendToastMessageView,
  sendToastMessageClick,
  sendToastFavouriteView,
  sendToastFavouriteClick,
  sendToastOfferView,
  sendToastOfferClick,
};
