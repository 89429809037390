import styled from '@emotion/styled';

export const FormContainer = styled('div')({
  maxWidth: '100%',
  paddingBottom: 20,
});

export const FormWrapper = styled('div')({
  maxWidth: '100%',
  paddingLeft: 28,
  paddingRight: 28,
});

export const ButtonAction = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 20,
  paddingBottom: 10,
});

export const Heading = styled('h4')({
  fontSize: 18,
  fontWeight: 600,
});

export const Heading1 = styled('h3')({
  fontSize: 24,
  fontWeight: 700,
  color: '#2B8FD7',
});

export const LinkAction = styled('span')({
  color: '#2B8FD7',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 700,
  marginLeft: 5,
});

export const Description = styled('p')({});

export const DashlineSeparator = styled('span')({
  width: '100%',
  display: 'block',
  borderTop: '1px dashed #DAE1E9',
  marginTop: 20,
});

export const StyledContainer = styled('div')({
  textAlign: 'center',
  maxWidth: 300,
  margin: 'auto',
  paddingTop: 20,
});

export const ErrorMessage = styled('p')({
  color: 'red',
  fontSize: 14,
});

export const StyledEditablePhoneInput = styled('div')({
  display: 'flex',
});
