import React, { useState, useRef, useEffect, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import styled from '@emotion/styled';
import 'react-image-crop/dist/ReactCrop.css';
import { getBlob, getBrowserDetails } from '../../utils/helpers';
import { PhotoBlurOutput } from './PhotoBlurOutput';
import { useStateCallback } from '../../utils/customHooks';
import { dimensions } from '../../style';
import SecondaryButton from '../../atoms/buttons/SecondaryButton';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import GalleryUploadMobileActionButtonContainer from './GalleryUpload/components/GalleryUploadMobileActionButtonContainer';

const StyledReacBlur = styled(ReactCrop)({
  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
  },
  '.ReactCrop__crop-selection': {
    position: 'absolute',
    zIndex: 10,
    borderRadius: '50%',
  },
  '.ReactCrop__image': {
    // maxHeight: getBrowserDetails().hImgCalibration,
    // maxWidth: getBrowserDetails().wImgCalibration,
    // height: getBrowserDetails().hImgCalibration,
    // width: getBrowserDetails().wImgCalibration,
    maxWidth: getBrowserDetails().wImgModalCalibration,
    height: getBrowserDetails().hImgModalCalibration,
    maxHeight: getBrowserDetails().hImgCalibration,
    [dimensions.SCREEN_MAX_XS]: {
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      width: '100%',
    },
  },
});

const PhotoBlurContainer = styled('div')({
  marginTop: 31,
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: 18,
  },
});

const CropContainer = styled('div')({
  overflow: 'hidden',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  minHeight: 320,
  padding: 38,
  borderRadius: 8,

  [dimensions.SCREEN_MAX_XS]: {
    height: '100%',
    maxHeight: '100%',
    padding: 0,
  },
});

const StyledDesktopActionButtons = styled('div')({
  padding: '20px 5px',
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'space-between',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const DragBlurStyled = styled('span')({
  color: '#8F8F8F',
  padding: '10px 0',
  float: 'left',
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '-0.42px',
  [dimensions.SCREEN_MAX_XS]: {
    order: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: 16,
  },
});

const ActionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [dimensions.SCREEN_MAX_XS]: {
    flex: '1 100%',
    justifyContent: 'space-between',
  },
});

const StyledLabelActionMobileWrapper = styled.div`
  display: none;
  text-align: center;
  margin-top: 17px;
  margin-bottom: -5px;

  ${dimensions.SCREEN_MAX_XS} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
`;

interface PhotoBlurProps {
  imageUrl: string;
  onLastPreview: () => any;
  cancelBlur: () => any;
  onCancel?: () => any;
}

const PhotoBlur = ({
  imageUrl,
  onLastPreview,
  cancelBlur,
  onCancel = () => undefined,
  ...rest
}: PhotoBlurProps): ReactElement => {
  const [crop, setCrop] = useState({ unit: '%', aspect: 1, width: 128 });
  const [currentImg, setCurrentImg] = useStateCallback('');
  const [photoBlurOutputRef, setPhotoBlurOutputRef] = useState(null);

  const [blurData, setBlurData] = useState({
    threshold: 10,
    smooth: getBrowserDetails().isBlurAllowed,
    data: [
      {
        height: 64,
        width: 64,
        x: 0,
        y: 0,
      },
    ],
    image: '',
  });
  const cropRef = useRef();
  const setCropRef = cropRefSetting => (cropRef.current = cropRefSetting);

  useEffect(() => {
    createImageData();
  }, [createImageData]);

  const createImageData = useCallback(() => {
    setCurrentImg(imageUrl, newState => {
      const uri = newState;
      const img = new Image();
      img.src = uri;
      img.onload = () => {
        setBlurData({
          ...blurData,
          image: {
            uri,
            width: img.width,
            height: img.height,
          },
        });
      };
    });
  }, [imageUrl, blurData, setCurrentImg]);

  const handleOnChange = cropSettings => {
    setCrop({
      ...crop,
      ...cropSettings,
    });

    setBlurData({
      ...blurData,
      data: [
        {
          // (this.state.blurData.image.height / 320) to compensate scale for precise blur area
          height: cropSettings.height * (blurData.image.height / getBrowserDetails().hImgCalibration),
          width: cropSettings.width * (blurData.image.width / getBrowserDetails().wImgCalibration),
          x: cropSettings.x * (blurData.image.height / getBrowserDetails().hImgCalibration),
          y: cropSettings.y * (blurData.image.width / getBrowserDetails().wImgCalibration),
        },
      ],
    });
  };

  const handleAddBlurredPhoto = () => {
    const blurredCanvas = photoBlurOutputRef;

    const pixelWidth = 200 * blurredCanvas.width;
    const pixelHeight = 200 * blurredCanvas.height;
    const pixelX = 200 * blurredCanvas.width;
    const pixelY = 200 * blurredCanvas.height;

    // Calculate crop values of full image size
    const croppedCtx = blurredCanvas.getContext('2d');
    croppedCtx.save();
    croppedCtx.drawImage(blurredCanvas, pixelX, pixelY, pixelWidth, pixelHeight, 0, 0, pixelWidth, pixelHeight);

    // Generate data url from cropped image drawn to hidden canvas
    // Image is in jpg format at 90% quality
    const blurredFileName = 'blob';
    const blurredImgUrl = blurredCanvas.toDataURL('image/jpeg', 0.9);
    const blurredImgBlob = getBlob(blurredImgUrl);
    const blurredImgFile = new Blob([blurredImgBlob], { type: blurredImgBlob.type });
    blurredImgFile.name = blurredFileName;

    croppedCtx.restore();

    onLastPreview(blurredImgFile);
  };

  const getCanvasRef = canvasRef => {
    setPhotoBlurOutputRef(canvasRef);
  };

  return (
    <PhotoBlurContainer>
      <CropContainer data-test-id="upload-photo-blur-tool">
        <StyledReacBlur
          ref={ref => setCropRef(ref)}
          src={currentImg}
          onChange={handleOnChange}
          crop={crop}
          style={{
            overflow: 'visible',
            margin: '0 auto',
          }}
          keepSelection={true}
          minWidth={64}
        />
        {currentImg !== '' && (
          <PhotoBlurOutput
            blurData={blurData}
            currentImage={currentImg}
            cancelBlur={cancelBlur}
            getCanvasRef={getCanvasRef}
            {...rest}
          />
        )}
      </CropContainer>
      {currentImg !== '' && (
        <>
          <StyledDesktopActionButtons>
            <ActionContainer>
              <SecondaryButton
                size="small"
                color="red"
                onClick={cancelBlur}
                data-test-id="cancel-photo-blur"
                label="Cancel Blur"
              />
              <DragBlurStyled>Drag and Resize Blur</DragBlurStyled>
              <PrimaryButton
                color="blue"
                size="small"
                data-test-id="submit-photo-blur"
                onClick={handleAddBlurredPhoto}
                label="Save and Close"
              />
            </ActionContainer>
          </StyledDesktopActionButtons>

          <StyledLabelActionMobileWrapper>
            <DragBlurStyled>Drag and Resize Blur</DragBlurStyled>
          </StyledLabelActionMobileWrapper>

          <GalleryUploadMobileActionButtonContainer onCancel={onCancel}>
            <PrimaryButton
              size="small"
              color="blue"
              label="Save and Close"
              onClick={handleAddBlurredPhoto}
              data-test-id="submit-photo-blur"
              customStyle={{ width: 'inherit', minWidth: 150 }}
            />
          </GalleryUploadMobileActionButtonContainer>
        </>
      )}
    </PhotoBlurContainer>
  );
};

PhotoBlur.defaultProps = {
  fileName: 'blob',
};

PhotoBlur.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  onLastPreview: PropTypes.func.isRequired,
  cancelBlur: PropTypes.func.isRequired,
};

export default PhotoBlur;
