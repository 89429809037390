import { IS_IPQA_ENABLED, FD_IPQS_KEY, WYP_IPQS_KEY, isFD } from '../config/Master';
import { IPQS_VALUES } from '../config/constants';
import { getCookie, removeCookie, setCookie } from './helpers';

interface ICallBackResponse {
  message: string;
  success: boolean;
  guid: string;
  guid_confidence: number;
  device_id: string;
  unique: boolean;
  is_crawler: boolean;
  fraud_chance: number;
  proxy: boolean;
  vpn: boolean;
  tor: boolean;
  time: number;
  country: string;
  city: string;
  recent_abuse: boolean;
  active_vpn: boolean;
  active_tor: boolean;
  ip_address: string;
  connection_type: string;
  region: string;
  timezone: string;
  operating_system: string;
  browser: string;
  brand: string;
  model: string;
  mobile: boolean;
  isp: string;
  bot_status: boolean;
  webgl_hash: string;
  canvas_hash: string;
  ssl_fingerprint: string;
  first_seen: string;
  last_seen: string;
  click_date: string;
  request_id: string;
}

export interface IRequestPayload {
  request_id: string;
  device_id: string;
}

const isIPQSCookiesExists = (): boolean => {
  const ipqsCookies = getIPQSCookie();

  return ipqsCookies?.device_id.length > 0 && ipqsCookies?.request_id.length > 0;
};

const getIPQSKey = (): string => {
  if (isFD) {
    return `firstdate.com/${FD_IPQS_KEY}`;
  }

  return `whatsyourprice.com/${WYP_IPQS_KEY}`;
};

export const getIPQSCookie = (): IRequestPayload => {
  let deviceId = '';
  let requestId = '';

  if (IS_IPQA_ENABLED && getCookie(IPQS_VALUES.DEVICE_ID) && getCookie(IPQS_VALUES.REQUEST_ID)) {
    deviceId = getCookie(IPQS_VALUES.DEVICE_ID);
    requestId = getCookie(IPQS_VALUES.REQUEST_ID);
  }

  return {
    device_id: deviceId,
    request_id: requestId,
  };
};

const attemptGetIPQS = (): IRequestPayload => {
  if (!IS_IPQA_ENABLED || isIPQSCookiesExists()) {
    return getIPQSCookie();
  }

  const scriptElement = document.getElementById('ipqs-script');

  if (scriptElement) {
    scriptElement.remove();
  }

  const script = document.createElement('script');
  script.src = `https://www.ipqscdn.com/api/${getIPQSKey()}/learn.js`;
  script.setAttribute('crossorigin', 'anonymous');
  script.id = 'ipqs-script';
  document.head.appendChild(script);

  const IPQ = {
    Callback: function() {
      Startup.AfterResult(function(result: ICallBackResponse) {
        const cookieExpiration = 1 * 3600 * 1000;
        setCookie(IPQS_VALUES.DEVICE_ID, result.device_id, true, cookieExpiration, 'strict');
        setCookie(IPQS_VALUES.REQUEST_ID, result.request_id, true, cookieExpiration, 'strict');
      });
      Startup.Pause();
      Startup.Init();

      const ipqsDeviceIdCookie = getCookie(IPQS_VALUES.DEVICE_ID);
      const ipqsRequestIdCookie = getCookie(IPQS_VALUES.REQUEST_ID);
      const cookieDoesNotExist = !ipqsDeviceIdCookie && !ipqsRequestIdCookie;
      const useCookie = false;

      if (!useCookie) {
        Startup.Resume(); // Start the fraud tracker.
      }

      if (useCookie && cookieDoesNotExist) {
        Startup.Resume(); // Start the fraud tracker.
      }
    },
  };

  window.IPQ = IPQ;

  return getIPQSCookie();
};

export const getUniqueDevice = (): IRequestPayload => {
  if (isIPQSCookiesExists()) {
    return getIPQSCookie();
  }

  return attemptGetIPQS();
};

export const removeIPQSCookies = (): void => {
  if (isIPQSCookiesExists()) {
    removeCookie(IPQS_VALUES.DEVICE_ID);
    removeCookie(IPQS_VALUES.REQUEST_ID);
  }
};

export default attemptGetIPQS;
