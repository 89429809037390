import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AutoSignup from '../components/pages/AccountMigration/AutoSignup';
import { validateActivationToken } from '../actions/authActions';
import queryString from 'query-string';
import Footer from '../components/blocks/Footer';
import PageWithFooter from '../components/blocks/PageWithFooter';
import { clearNotificationMessage } from '../actions/notificationActions';
import { loadGoogleMaps, setCookie } from '../utils/helpers';
import { ThemeProvider } from '@emotion/react';
import { WYPTheme } from 'swui';
import useModal from '../hooks/useModal';
import _isEmpty from 'lodash/isEmpty';
import { useApp } from '../contexts/AppContext';
import HeaderOut from '../atoms/HeaderOut';
import styled from '@emotion/styled';
import { completionTheme } from '../style';
import Button from '../atoms/buttons/Button';
import { getBrowserDetails, copyToClipBoard } from '../utils/helpers';
import TextInput from '../atoms/TextInput';
import CopyIcon from '../atoms/icons/CopyIcon';
import Tooltip from 'rc-tooltip';
import { useNavigate } from 'react-router-dom';
import { ALLOW_THEME_COOKIE_NAME } from '../config/constants';

const SuccessHeader = styled('h3')({
  color: '#000',
});

const LineSeparator = styled('p')({
  borderBottom: '1px dashed #cbcbcb',
  paddingBottom: 20,
});

const InfoContainer = styled('div')({
  padding: 16,
  borderRadius: 5,
  marginBottom: 16,
  display: 'block',
  backgroundColor: completionTheme.containerBG,
  border: completionTheme.containerBG,
});

const CopyTextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: 50,
});

const TextInputContainer = styled('div')({});

const YourTemp = styled('i')({
  fontSize: 12,
});

const LinkToProfile = styled('a')({
  color: '#2B8FD7',
  fontWeight: 600,
  '&:hover': {
    borderBottom: '1px solid #2B8FD7',
  },
});

const IconContainer = styled('div')({});

const overlayStyle = {
  zIndex: 10000,
  backgroundColor: '#2B8FD7',
};

interface AutoSignupProps {
  location: any;
  validateMigrateToken: any;
  asyncValidateMigrateToken: any;
  isFetching: any;
  errors: any;
  profile: any;
}

const AutoSignupContainer = ({ location, asyncValidateMigrateToken, isFetching, errors, profile }: AutoSignupProps) => {
  const { basicModal, resetModal } = useModal();
  const [migrationInfo, setMigrationInfo] = useState(null);
  const [profileLink, setProfileLink] = useState('/profile');
  const queryStringParsed = queryString.parse(location.search);

  const { modalState } = useApp();

  const navigate = useNavigate();

  useEffect(() => {
    setCookie(ALLOW_THEME_COOKIE_NAME, false);
  }, [modalState]);

  useEffect(() => {
    if (queryStringParsed) {
      const { token } = queryStringParsed;

      loadGoogleMaps();
      setTimeout(() => {
        asyncValidateMigrateToken(token)
          .then(asyncRes => {
            setMigrationInfo(asyncRes);
          })
          .catch(err => console.error(err));
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_isEmpty(migrationInfo) && migrationInfo.temp_password) {
      setProfileLink(profile && profile.hash_id ? `/profile/${profile.hash_id}?migration_edit=true` : '/profile');
      showSuccessModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [migrationInfo, profile]);

  // const validateToken = (token) => {
  //   asyncValidateMigrateToken(token).then(asyncRes => {
  //     console.log('--> asyncRes', asyncRes);
  //     setMigrationInfo(asyncRes)
  //   }).catch(err => console.error(err));
  // }

  const renderToolTip = () => {
    return (
      <Tooltip
        placement="top"
        overlayClassName="custom-rc-tooltip-primary"
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        overlay={
          <div style={{ textAlign: 'center', color: '#FFFFFF', padding: '0 10px' }}>
            Password
            <br />
            Copied!
          </div>
        }
        overlayStyle={overlayStyle}
        trigger={['click']}
      >
        <CopyIcon
          onClick={() => {
            copyToClipBoard(migrationInfo.temp_password);
          }}
        />
      </Tooltip>
    );
  };

  const showSuccessModal = () => {
    basicModal({
      dataTestID: 'profile-success-creation',
      modalContentLabel: 'Profile successfully created!',
      withoutBgPattern: true,
      customContentStyle: { width: 540, padding: getBrowserDetails().isMobile ? 12 : '12px 44px' },
      hideCloseBtn: true,
      closeOnOverlayClick: false,
      modalBody: (
        <div style={{ textAlign: 'center' }}>
          <HeaderOut paddingTop={20} width={getBrowserDetails().isMobile ? 260 : 340} />
          <SuccessHeader>Profile successfully created!</SuccessHeader>
          <p>
            Continue <LinkToProfile href={profileLink}>making edits</LinkToProfile> or start finding your next date!
          </p>
          <InfoContainer>
            <h5 style={{ margin: '10px 0', fontSize: 16 }}>Temporary password created</h5>
            <LineSeparator>
              You&apos;ve given a temporary password. Please save this password or update in Account Settings
            </LineSeparator>
            <CopyTextContainer>
              <TextInputContainer>
                <TextInput
                  value={migrationInfo.temp_password}
                  onClick={e => e.target.select()}
                  customStyle={{ marginRight: 8 }}
                />
              </TextInputContainer>
              <IconContainer>{renderToolTip()}</IconContainer>
            </CopyTextContainer>
            <YourTemp>Your Temporary Password</YourTemp>
          </InfoContainer>
          <CopyTextContainer>
            <Button
              customStyle={{
                border: 'solid 2px #2490de',
                marginRight: 10,
                fontSize: getBrowserDetails().isMobile ? 14 : 16,
                color: '#2490de',
              }}
              buttonType="normal"
              onClick={() => {
                resetModal();
                navigate('/settings/email-password');
              }}
            >
              Edit Password
            </Button>
            <Button
              customStyle={{ fontSize: getBrowserDetails().isMobile ? 14 : 16 }}
              buttonType="primary"
              onClick={() => {
                resetModal();
                navigate('/search');
              }}
            >
              Browse Members
            </Button>
          </CopyTextContainer>
        </div>
      ),
    });
  };

  return (
    <ThemeProvider theme={WYPTheme}>
      <div id="main">
        <PageWithFooter>
          <AutoSignup isFetching={isFetching} errors={errors} />
        </PageWithFooter>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.common.errors,
    isFetching: state.common.isFetching,
    notification: state.notification,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    loadPageUrl(linkUrl) {
      dispatch(clearNotificationMessage());
      ownProps.navigate(linkUrl);
    },
    validateMigrateToken: token => {
      dispatch(validateActivationToken(token, ownProps.navigate));
    },
    asyncValidateMigrateToken: async token => {
      return await dispatch(validateActivationToken(token, ownProps.navigate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSignupContainer);
