import React, { ReactElement, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { borders, dimensions, textColor } from '../../style';
import Button from '../../atoms/buttons/Button';
import ThreadEntry from '../../atoms/mail/ThreadEntryV2';
import { Select } from '../../atoms/Select';
import { renderAvatar, tsSince } from '../../common';
import _isEmpty from 'lodash/isEmpty';
import { getBrowserDetails } from '../../utils/helpers';
import { GENERAL_MESSAGE_AVATAR, GENERAL_MESSAGE_USERNAME } from '../../config/Master';
import LoadingBackdrop from '../../atoms/loader/LoadingBackdrop';

const ThreadsContainer = styled('div')({});

const ThreadList = styled('div')({
  overflow: 'hidden',
  overflowY: 'scroll',
  height: `calc(${getBrowserDetails().isIpad && screen.height > 1024 ? '87vh' : '100vh'} - 140px)`,

  [dimensions.SCREEN_MAX_MD]: {
    height: 'calc(100vh - 121px)',
  },
});

const ThreadHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: borders.DEFAULT,
  padding: '9px 20px',
  justifyContent: 'space-between',
});

interface ThreadsProps {
  collection: any;
  openThreadHashId: any;
  onThreadClick: any;
  fetching: any;
  fetchingMore: any;
  onThreadChange: any;
  order: any;
  canLoadMore: any;
  onFetchThreads: any;
  meta: any;
  openConversation: boolean;
  showPwaInstallPromptBanner: boolean;
}

const Threads = ({
  collection,
  openThreadHashId,
  onThreadClick,
  fetching,
  fetchingMore,
  onThreadChange,
  order,
  canLoadMore,
  onFetchThreads,
  meta,
  openConversation,
  showPwaInstallPromptBanner,
}: ThreadsProps): ReactElement => {
  const threadRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const offsetTop = threadRef.current.offsetTop + 80;
      if (screenWidth <= 375 && !openConversation) {
        const pwaBannerInstallPromptHeight = showPwaInstallPromptBanner ? 130 : 0;
        const height = screenHeight - (offsetTop - pwaBannerInstallPromptHeight);
        threadRef.current.style.height = `${height}px`;
        document.body.classList.add('body-scroll-disabled');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('body-scroll-disabled');
    };
  }, [threadRef, openConversation, showPwaInstallPromptBanner]);

  const renderThreads = () => {
    return collection.map(({ locked, last_message, other_profile, status, admin, ...item }) => {
      const otherProfile = _find(['data'], other_profile) || {};
      const otherAccount = _find(['account', 'data'], otherProfile) || {};
      const avatar = admin ? GENERAL_MESSAGE_AVATAR : renderAvatar(otherProfile.avatar.data.urls, true);
      const lastMessage = _find(['data'], last_message) || {};
      const username = admin ? GENERAL_MESSAGE_USERNAME : otherAccount.username || '';
      return (
        <ThreadEntry
          isDeleted={lastMessage.to_status === 'deleted' || lastMessage.status === 'deleted' || lastMessage.is_deleted}
          isOnline={otherProfile.online}
          key={item.id}
          username={username}
          avatar={avatar}
          message={lastMessage.message ? lastMessage.message : ''}
          isPhoto={lastMessage.type && lastMessage.type === 'photo'}
          iReplied={!_isEmpty(lastMessage) && lastMessage.message && lastMessage.hash_id !== otherAccount.hash_id}
          isNew={status === 'new'}
          isLocked={locked}
          isUnread={!_isEmpty(lastMessage) && lastMessage.id && !lastMessage.i_read}
          lastMsgDate={tsSince(
            (lastMessage.created_at && lastMessage.created_at.date) || (item.created_at && item.created_at.date)
          )}
          onThreadClick={() => onThreadClick(item.id, otherAccount.hash_id)}
          selected={otherAccount.hash_id === openThreadHashId}
          isSeveredAccount={item.deleted_reason === 'severed'}
          accountType={otherProfile.account_type}
          admin={admin}
        />
      );
    });
  };

  return (
    <ThreadsContainer>
      <ThreadHeader>
        <span style={{ fontSize: 16, fontWeight: 'bold', ...textColor.BRAND }}>Conversations</span>
        <Select
          onChange={onThreadChange}
          value={order}
          style={{ paddingBottom: 0, marginRight: 0 }}
          id="mail_order_dropdown"
        >
          <option value="recent">Most recent</option>
          <option value="unlocked">Unlocked</option>
          <option value="locked">Locked</option>
          <option value="archive">Archived</option>
          <option value="unread">Unread</option>
        </Select>
      </ThreadHeader>
      <ThreadList ref={threadRef}>
        {(!fetching || fetchingMore) && renderThreads()}
        {!fetching && canLoadMore && (
          <Button
            onClick={() => onFetchThreads(meta.pagination.current_page + 1)}
            style={{ width: '100%', marginTop: 10 }}
          >
            Load older...
          </Button>
        )}
        {fetching && <LoadingBackdrop active={true} showLoadingBg={true} style={{ width: '100%', height: 100 }} />}
      </ThreadList>
    </ThreadsContainer>
  );
};

Threads.propTypes = {
  openThreadHashId: PropTypes.string,
  collection: PropTypes.arrayOf(PropTypes.object),
  onThreadChange: PropTypes.func.isRequired,
  onThreadClick: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  onFetchThreads: PropTypes.func.isRequired,
};

Threads.defaultProps = {
  collection: [],
};

export default Threads;
