import React from 'react';
import { theme } from '../../../../theme';
import typography from '../../../../theme/typography';
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton';
import GalleryPhotoUploaderMediaCard from './GalleryPhotoUploaderMediaCard';
import GalleryUploadMobileActionButtonContainer from '../components/GalleryUploadMobileActionButtonContainer';
import GalleryUploadDesktopActionButtonContainer from '../components/GalleryUploadDesktopActionButtonContainer';
import { PhotoListTypes, PhotoFileType } from '../../constants';
import uuidv4 from 'uuid';
import { MAX_NUMBER_PHOTOS } from '../../../../config/Master';
import {
  StyledCountDetailsContainer,
  StyledCounterH2,
  StyledMediasContainer,
  StyledMediasInnerContainer,
  StyledVerticalLine,
} from '../styles';

interface ISelectedPhotosProps {
  existingUploadedMediaCount?: number;
  photos: PhotoListTypes;
  onCancel: () => void;
  onEdit: (photo: PhotoFileType) => void;
  onRemove: (photo: PhotoFileType) => void;
  onSubmit: () => void;
}

const GalleryPhotoUploaderPreview: React.FC<ISelectedPhotosProps> = (props: ISelectedPhotosProps) => {
  const { existingUploadedMediaCount = 10, photos = [], onCancel, onRemove, onEdit, onSubmit } = props;

  if (!photos || photos.length < 1) return null;

  const totalPhotosCount = existingUploadedMediaCount + photos.length;
  const buttonLabel = `Upload (${photos.length}) Photos`;
  return (
    <div>
      <StyledCountDetailsContainer>
        <StyledCounterH2 isValidCount={totalPhotosCount > MAX_NUMBER_PHOTOS}>
          {totalPhotosCount}/{MAX_NUMBER_PHOTOS}
        </StyledCounterH2>
        <StyledVerticalLine />
        <span
          style={{
            color: typography[theme].primaryText,
            fontSize: 14,
            letterSpacing: -0.28,
          }}
        >
          Total Profile Photos & Videos
        </span>
      </StyledCountDetailsContainer>
      <StyledMediasContainer>
        <StyledMediasInnerContainer>
          {photos.map(photo => {
            return (
              <GalleryPhotoUploaderMediaCard
                file={photo}
                key={uuidv4()}
                onEdit={() => {
                  onEdit(photo);
                }}
                onRemove={() => {
                  onRemove(photo);
                }}
              />
            );
          })}
        </StyledMediasInnerContainer>
      </StyledMediasContainer>
      <GalleryUploadDesktopActionButtonContainer>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={onSubmit}
          disabled={totalPhotosCount > MAX_NUMBER_PHOTOS}
          customStyle={{
            width: 'inherit',
          }}
        />
      </GalleryUploadDesktopActionButtonContainer>
      <GalleryUploadMobileActionButtonContainer onCancel={onCancel}>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={onSubmit}
          disabled={totalPhotosCount > MAX_NUMBER_PHOTOS}
          customStyle={{
            width: 'inherit',
            minWidth: 150,
          }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </div>
  );
};

export default GalleryPhotoUploaderPreview;
