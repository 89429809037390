import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

interface LocationProps {
  width: any;
  height: any;
  onClick: any;
  isEditProfile: boolean;
  fill: string;
}

const Location = ({ width, height, onClick, isEditProfile = false, fill = '#80868B' }: LocationProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      onClick={onClick}
      style={isEditProfile ? { marginRight: 16 } : {}}
    >
      <defs>
        <path
          id="location-a"
          d="M12,8 C9.79,8 8,9.79 8,12 C8,14.21 9.79,16 12,16 C14.21,16 16,14.21 16,12 C16,9.79 14.21,8 12,8 Z M20.94,11 C20.48,6.83 17.17,3.52 13,3.06 L13,1 L11,1 L11,3.06 C6.83,3.52 3.52,6.83 3.06,11 L1,11 L1,13 L3.06,13 C3.52,17.17 6.83,20.48 11,20.94 L11,23 L13,23 L13,20.94 C17.17,20.48 20.48,17.17 20.94,13 L23,13 L23,11 L20.94,11 Z M12,19 C8.13,19 5,15.87 5,12 C5,8.13 8.13,5 12,5 C15.87,5 19,8.13 19,12 C19,15.87 15.87,19 12,19 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        {!isEditProfile && (
          <path
            fill="#FFF"
            stroke="#CACACA"
            d="M0.5,18 C0.5,27.6664046 8.33425252,35.5 18,35.5 C27.6664046,35.5 35.5,27.6657475 35.5,18 C35.5,8.33359539 27.6657475,0.5 18,0.5 C8.33359539,0.5 0.5,8.33425252 0.5,18 Z"
          />
        )}
        <g transform="translate(6 6)">
          <mask id="location-b" fill="#fff">
            <use xlinkHref="#location-a" />
          </mask>
          <g fill={fill} mask="url(#location-b)">
            <rect width="24" height="24" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Location.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

Location.defaultProps = {
  width: 36,
  height: 36,
};

export default Location;
