import { vysionService } from '../utils/vysion/vysionService';
import { convertSentanceTitleCase, removeFirstWord } from '../utils/helpers';

const setBillingAction = (eventType: any, extraInfo?: any): any => {
  return {
    type: 'BILLING',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: 'Billing',
        extraInfo: setExtraInfo(eventType, extraInfo),
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setExtraInfo = (eventType: string) => {
  switch (eventType) {
    case 'SOFORT FIRST NAME':
    case 'SOFORT LAST NAME':
    case 'SOFORT COUNTRY':
      return convertSentanceTitleCase(removeFirstWord(eventType));
    default:
      return null;
  }
};

const setComponent = (eventType: any): any => {
  switch (eventType) {
    case 'SOFORT FIRST NAME':
    case 'SOFORT LAST NAME':
    case 'SOFORT COUNTRY':
      return 'Sofort';
    default:
      return null;
  }
};

const billingSofortFirstName = (extraInfo?: any): void => {
  vysionService(setBillingAction('SOFORT FIRST NAME', extraInfo));
};

const billingSofortLastName = (extraInfo?: any): void => {
  vysionService(setBillingAction('SOFORT LAST NAME', extraInfo));
};

const billingSofortCountry = (extraInfo?: any): void => {
  vysionService(setBillingAction('SOFORT COUNTRY', extraInfo));
};

export { billingSofortFirstName, billingSofortLastName, billingSofortCountry };
