import React, { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import styled from '@emotion/styled';
import TextInput from '../../atoms/TextInput';
import Button from '../../atoms/buttons/Button';
import { Select } from '../../atoms/Select';
import Checkbox from '../../atoms/Checkbox';
import { dimensions, statusColor, inputTheme, creditCardInputField } from '../../style';
import SvgQuestion from '../../atoms/icons/SvgQuestion';
import InputLabel from '../../atoms/InputLabel';
import CreditCardInput from 'react-credit-card-input';
import Tooltip from 'rc-tooltip';
import { getBrowserDetails, isDarkMode, renderTaxLabel } from '../../utils/helpers';
import LockIcon from '@material-ui/icons/Lock';
import AfterPayment from './AfterPayment';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import { useStateCallback } from '../../utils/customHooks';
import { ADDRESS_COMPLETER_MICROSERVICE_URI, ADDRESS_COMPLETER_MICROSERVICE_CLIENT_ID } from '../../config/Master';
import { checkCardExpiry } from '../../utils/helpers';
import '../../style/customCC.css';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { ISuggestionItem } from 'swui/dist/modules/AutoComplete/AutoComplete.interface';
import { IAddressSuggestion } from 'swui/dist/modules/BillingAddressAutoComplete/BillingAddressAutoComplete.interface';
import { BillingAddressAutoComplete } from 'swui';
import countries from '../../constants/countries';
import { handleMelissaBillingFormVysionEvent } from '../../utils/vysion/melissaBillingForm';
import { MelissaClickVysionEventsEnum } from '../../utils/vysion/melissaBillingForm/click';
import { MelissaOnFocusVysionEventsEnum } from '../../utils/vysion/melissaBillingForm/onFocus';
import {
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  EVIDENTLY_MELISSA_METRIC_KEYS,
  trackEvidentlyMetric,
} from '../../constants/evidently';
import BillingTermsAndCondition from './BillingTermsAndCondition';
import { mixpanelTrackMelissaLookupFinished } from '../../utils/mixpanel/mellisaLookupFinished';

const currentYear = new Date().getFullYear();

const fetchSuggestions = async (
  country: string,
  partialAddress: string,
  onNotFound: () => void
): Promise<ISuggestionItem<IAddressSuggestion>[]> => {
  if (!partialAddress) return [];

  try {
    const responseStr = await fetch(
      `${ADDRESS_COMPLETER_MICROSERVICE_URI}?country=${country}&partialAddress=${partialAddress}`,
      {
        headers: {
          'x-client-id': ADDRESS_COMPLETER_MICROSERVICE_CLIENT_ID,
        },
      }
    );
    const response = await responseStr.json();
    const newSuggestions = response.data.map((suggestion: any) => ({
      value: suggestion,
      element: suggestion.full_address,
      key: suggestion.full_address,
    }));

    mixpanelTrackMelissaLookupFinished({ Success: true, 'Lookup Address': partialAddress });

    return newSuggestions;
  } catch (err) {
    mixpanelTrackMelissaLookupFinished({ Success: false, 'Lookup Address': partialAddress });

    onNotFound();
    return [];
  }
};

const ExpAndCode = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  [dimensions.SCREEN_MAX_XS]: {
    flexDirection: 'column',
  },
});

const StatusMessage = styled('div')(
  {
    marginTop: '-20px',
  },
  ({ status }) => ({
    color: statusColor[status],
  })
);

const darkMode = isDarkMode();

const BillingAddressSection = styled('section')({
  '#autocomplete-dropdown': {
    background: darkMode ? '#041a2a' : '#ffffff',
    color: darkMode ? '#AFB0B1' : 'inherit',
  },
});

const BillingAddressHeading = styled('h4')({
  display: 'block',
  marginTop: 0,
  fontWeight: 700,
  position: 'relative',
  marginBottom: 14,
});

const ExpDateContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  maxWidth: '276px',
  position: 'relative',
});

const FormSection = styled('div')(({ newCard }) =>
  newCard !== true
    ? {
        padding: '10px 10px',
        borderRadius: 5,
        marginBottom: 25,
      }
    : {
        marginBottom: 10,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTop: 0,
        padding: '10px 10px',
      }
);

const PayWithCardButton = styled(Button)({
  margin: '0 auto',
  backgroundColor: 'rgb(19, 131, 210)',
  padding: '16px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
  height: 51,
  width: 232,
  borderRadius: 90,
});

const mobileOrtablet = () => getBrowserDetails().isMobile || getBrowserDetails().isTablet;

const notBigTablets = () => screen.width >= 1080;

const notTabletORMobile = () => (notBigTablets() ? 20 : 50);

const setLabelTooltipTop = caseNo => {
  switch (caseNo) {
    case 1:
      return 43;
    case 2:
      return 40;
    case 3:
      return 40;
    case 4:
      return 38;
    default:
      return;
  }
};

const setCaseNo = () => {
  switch (true) {
    case getBrowserDetails().isIos && getBrowserDetails().isMobile:
      return 1;
    case mobileOrtablet():
      return 2;
    case notBigTablets():
      return 3;
    default:
      return 4;
  }
};

const LabelToolTip = styled('span')({
  position: 'absolute',
  cursor: 'pointer',
  right: mobileOrtablet() ? 15 : notTabletORMobile(),
  top: setLabelTooltipTop(setCaseNo()),
  zIndex: 10,
  width: 30,
  marginRight: mobileOrtablet() ? 5 : 0,
});

const dropDownOptions = (start, end, defaultValue) => {
  const options = [];
  options.push(
    <option selected key={defaultValue} value="" defaultValue={defaultValue} disabled>
      {defaultValue}
    </option>
  );

  for (let i = start; i <= end; i++) {
    options.push(
      <option value={i} key={i.toString()}>
        {i}
      </option>
    );
  }
  return options;
};

const getStatus = status => {
  return status && status.status ? status.status : 'default';
};

const getStatusMessage = status => {
  return status && status.message ? status.message : null;
};

const getExpDateStatus = (month, year) => {
  const expDateHasStatus = statusType => {
    return month.status === statusType || year.status === statusType;
  };

  // status of exp date dropdown will be determined by the dropdown with
  // the highest priority status
  // i.e. if one dropdown is marked as 'error', both exp date dropdowns are
  // marked as 'error'
  // priority: error > caution > success > default
  if (expDateHasStatus('error')) {
    return 'error';
  } else if (expDateHasStatus('caution')) {
    return 'caution';
  } else if (expDateHasStatus('success')) {
    return 'success';
  } else return 'default';
};

const renderExpStatusMessage = (month, year, status) => {
  const message = (month.status === status && month.message) || (year.status === status && year.message);

  if (message && status !== 'default') {
    return <StatusMessage status={status}>{message}</StatusMessage>;
  } else return null;
};

const verifyStatus = (status, props) => {
  return status && getStatus(status[props]);
};

const verifyStatusMessage = (status, props) => {
  return status && getStatusMessage(status[props]);
};

interface BillingFormWithMelissaIntegrationProps {
  status: any;
  cardNumber: any;
  cardExpMonth: any;
  cardExpYear: any;
  cardCVV: any;
  onInputChange: any;
  onAddressChange: any;
  handleCountryChange: any;
  handleSubmit: any;
  nameOnCard: any;
  billingAddress: any;
  billingCity: any;
  billingState: any;
  billingCountry: any;
  billingZip: any;
  onSelectSaveCard: any;
  saveCard: any;
  storyMode: any;
  totalAmount: any;
  currencyCharCode: any;
  selectedPackage: any;
  descriptors: any;
  newCard: any;
  userCountry: any;
  handleUpdateMonthYrInput: any;
  discountAmount: any;
  disablePurchase: any;
  recurring: any;
}

const BillingFormWithMelissaIntegration = ({
  status,
  cardNumber,
  cardExpMonth,
  cardExpYear,
  cardCVV,
  onInputChange,
  handleSubmit,
  nameOnCard,
  onSelectSaveCard,
  saveCard,
  storyMode = false,
  totalAmount,
  currencyCharCode,
  selectedPackage,
  descriptors,
  newCard,
  userCountry,
  handleUpdateMonthYrInput,
  discountAmount,
  onAddressChange,
  handleCountryChange,
  billingCountry,
  billingCity,
  billingAddress,
  billingState,
  billingZip,
  recurring,
}: BillingFormWithMelissaIntegrationProps): ReactElement => {
  const [cardExpiry, setCardExpiry] = useState('');
  const [currStatus] = useState(status);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [myCardNumber, setMyCardNumber] = useState('');
  const [disableBtn, setDisableBtn] = useStateCallback(true);
  const [hasError, setHasError] = useState(false);

  const [country, setCountry] = useState(billingCountry);
  const [streetAddress, setStreetAddress] = useState(billingAddress);
  const [zipCode, setZipCode] = useState(billingZip);
  const [state, setState] = useState(billingState);
  const [city, setCity] = useState(billingCity);
  const mounted = useRef();

  useEffect(() => {
    const ele = document.getElementById('field_billing_country');
    if (ele) {
      ele.onclick = () => {
        handleMelissaBillingFormVysionEvent({
          event: MelissaClickVysionEventsEnum.PAYMENT_COUNTRY,
        });
      };
    }
  }, []);

  const melissaFieldStyles = darkMode
    ? {
        backgroundColor: 'rgba(255,255,255,0.07)',
        color: '#ffffff',
      }
    : {};

  useEffect(() => {
    if (country) {
      handleCountryChange(country);
    }
  }, [country]);

  useEffect(() => {
    const newAddress = {
      use2pay_billing_state: state,
      use2pay_billing_city: city,
      use2pay_billing_zip: zipCode,
      use2pay_billing_address: streetAddress,
    };

    onAddressChange(newAddress);
    checkAllowSubmit();
    setDisableBtn([streetAddress, state, city, zipCode].includes('') || !allowSubmit);
  }, [streetAddress, state, city, zipCode]);

  const expiryVal = checkCardExpiry(cardExpiry, cardExpMonth, cardExpYear);

  const countryData = country =>
    countries.map(item => (
      <option value={item.code} key={item.code} selected={country === item.code}>
        {item.name}
      </option>
    ));

  const renderCardErrorMsg = (type, value) => {
    switch (type) {
      case 'cardNumber':
        return value === '' ? 'Credit card number is required.' : 'Enter a valid credit card number.';
      case 'expiryDate':
        return value === '' ? 'Expiry date is required.' : 'Expiry date is invalid.';
      case 'cvc':
        return value === '' ? 'Security code is required.' : 'CVC is invalid.';
      default:
        return;
    }
  };

  const expDateStatus =
    status && status.use2pay_card_exp_month && status.use2pay_card_exp_year
      ? getExpDateStatus(status.use2pay_card_exp_month, status.use2pay_card_exp_year)
      : 'default';

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      checkAllowSubmit();
      mounted.current = true;
    }
  });

  useEffect(() => {
    if (status !== currStatus) {
      validateRequiredFields();
    }
  }, [status, currStatus, validateRequiredFields]);

  const checkAllowSubmit = () => {
    const reqValues = cardNumber && cardExpMonth && cardExpYear && cardCVV;
    setAllowSubmit(!!reqValues);
  };

  const inputOnChange = e => {
    setDisableBtn(false);
    checkAllowSubmit();
    onInputChange(e.target.id, e.target.value);
  };

  const onCardChange = (targetId, e) => {
    setDisableBtn(false);
    const inputVal = e.target.value;
    if (targetId === 'card_number') {
      setMyCardNumber(inputVal);
    }
    if (targetId === 'card_expiry') {
      setCardExpiry(inputVal);
      if (inputVal.length === 7) {
        const valArr = inputVal.split(' / ');
        handleUpdateMonthYrInput(parseInt(valArr[0]), parseInt(`20${valArr[1]}`));
        checkAllowSubmit();
      }
      return;
    }
    onInputChange(targetId, inputVal);
    checkAllowSubmit();
  };

  const onCardError = () => {
    setAllowSubmit(false);
    setDisableBtn(true);
  };

  const onHandleSubmit = e => {
    e.preventDefault();
    if (!allowSubmit) {
      setDisableBtn(true, () => document.getElementById('card_number').focus());
      return;
    }

    // No error on 1st attempt
    // Send evidently metric
    if (![streetAddress, state, city, zipCode].includes('') && !hasError) {
      trackEvidentlyMetric(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MELISSA, {
        experimentName: EVIDENTLY_EXPERIMENTS.MELISSA,
        featureName: EVIDENTLY_FEATURES.MELISSA,
        key: EVIDENTLY_MELISSA_METRIC_KEYS.PURCHASED_FROM_1ST_ATTEMPT,
        value: 1,
      });
    }

    handleSubmit();
  };

  const validateRequiredFields = useCallback(() => {
    switch (true) {
      case !!status.use2pay_card_number:
      case !!status.use2pay_card_cvv:
        document.getElementById('card_number').focus();
        setHasError(true);
        break;
      case !!status.use2pay_billing_name_on_card:
        setHasError(true);
        document.getElementById('billing_name_on_card').focus();
        break;
      case !!status.use2pay_billing_zip:
        setHasError(true);
        document.getElementById('field_billing_zip').focus();
        break;
      case !!status.use2pay_billing_city:
        setHasError(true);
        document.getElementById('field_billing_city').focus();
        break;
      case !!status.use2pay_billing_address:
        setHasError(true);
        document.getElementById('field_billing_address').focus();
        break;
      case !!status.use2pay_billing_phone:
        setHasError(true);
        document.getElementById('field_billing_phone').focus();
        break;
      default:
        setHasError(false);
        break;
    }
  }, [status]);

  const verifyValidCardNumber = () => {
    return myCardNumber && myCardNumber.split(' ').length > 1 ? '23' : '16';
  };

  return (
    <form onSubmit={onHandleSubmit} style={{ position: 'relative' }}>
      <FormSection newCard={newCard}>
        <label data-test-id="label-card_info" style={{ fontWeight: '400' }}>
          Card Information
        </label>
        <Tooltip
          placement={'topRight'}
          visible={showTooltip}
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          overlay="The CVC/security code has 3 digits and is located at the back of your credit card. For American Express cards, the security code has 4 digits and is located at the front"
          overlayStyle={{ maxWidth: 300 }}
        >
          <LabelToolTip onMouseOver={() => setShowToolTip(true)} onMouseOut={() => setShowToolTip(false)}>
            <SvgQuestion styles={{ height: 14, fill: 'rgb(151, 151, 151)', float: 'right', paddingTop: '10px' }} />
          </LabelToolTip>
        </Tooltip>
        {!storyMode ? (
          <CreditCardInput
            cardNumberInputProps={{
              value: cardNumber,
              onChange: e => onCardChange('card_number', e),
              placeholder: 'Card Number',
              maxLength: verifyValidCardNumber(),
              id: 'card_number',
              onError: () => onCardError('card_number'),
            }}
            cardExpiryInputProps={{
              value: expiryVal,
              onChange: e => onCardChange('card_expiry', e),
              onFocus: e => {
                e.target.select();
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_EXPIRATION_ENTRY,
                });
              },
              id: 'card_expiry',
              onError: () => onCardError('card_expiry'),
            }}
            cardCVCInputProps={{
              value: cardCVV,
              onChange: e => onCardChange('card_cvv', e),
              onFocus: e => {
                e.target.select();
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_SECURITY_CODE_ENTRY,
                });
              },
              id: 'card_cvv',
              onError: () => onCardError('card_cvv'),
            }}
            dangerTextClassName="error-card-text"
            containerStyle={{
              width: 'calc(100% - 3px)',
              height: '40px',
              marginBottom: '15px',
              marginTop: '8px',
              paddingBottom: '8px',
            }}
            fieldStyle={{
              border: '1px solid #cccccc',
              borderRadius: '3px',
              padding: '12px',
              background: inputTheme.backgroundInput,
            }}
            inputStyle={{
              fontSize: '14px',
              color: inputTheme.colorInput,
              background: creditCardInputField.backgroundInput,
            }}
            customTextLabels={{
              invalidCardNumber: renderCardErrorMsg('cardNumber', cardNumber),
              expiryError: {
                invalidExpiryDate: renderCardErrorMsg('expiryDate', expiryVal),
              },
              invalidCvc: renderCardErrorMsg('cvc', cardCVV),
            }}
          />
        ) : (
          <React.Fragment>
            <TextInput
              id="card_number"
              type="text"
              inputLabel="Card number"
              value={cardNumber}
              onChange={inputOnChange}
              status={verifyStatus(status, 'use2pay_card_number')}
              statusMessage={verifyStatusMessage(status, 'use2pay_card_number')}
              isBilling
              noBorder={false}
              customStyle={{ border: '1px solid #cccccc;' }}
            />
            <ExpAndCode>
              <ExpDateContainer>
                <InputLabel label="Expiration date" />
                <div style={{ display: 'flex' }}>
                  <Select
                    id="card_exp_month"
                    value={cardExpMonth}
                    status={expDateStatus}
                    noSpacing
                    onChange={inputOnChange}
                    style={{ flex: 1, paddingRight: 8, marginRight: '0px' }}
                  >
                    {dropDownOptions(1, 12, 'Month')}
                  </Select>
                  <Select
                    id="card_exp_year"
                    value={cardExpYear}
                    status={expDateStatus}
                    showStatusIcon={true}
                    onChange={inputOnChange}
                    style={{
                      flex: 1,
                      display: 'flex',
                      paddingRight: 4,
                    }}
                  >
                    {dropDownOptions(currentYear, currentYear + 20, 'Year')}
                  </Select>
                </div>
                {status &&
                  status.use2pay_card_exp_month &&
                  status.use2pay_card_exp_year &&
                  renderExpStatusMessage(status.use2pay_card_exp_month, status.use2pay_card_exp_year, expDateStatus)}
              </ExpDateContainer>
              <TextInput
                width={134}
                id="card_cvv"
                type="text"
                inputLabel="Security code"
                value={cardCVV}
                tooltip={{
                  text:
                    'The security code has 3 digits and is located at the back of your credit card. For American Express cards, the security code has 4 digits and is located at the front',
                  overlayStyle: { maxWidth: '300px' },
                  placement: 'top',
                  icon: <SvgQuestion />,
                }}
                onChange={inputOnChange}
                status={verifyStatus(status, 'use2pay_card_cvv')}
                statusMessage={verifyStatusMessage(status, 'use2pay_card_cvv')}
                customStyle={{ maxWidth: 134 }}
              />
            </ExpAndCode>
          </React.Fragment>
        )}
        <TextInput
          id="billing_name_on_card"
          type="text"
          inputLabel="Name on Card"
          value={nameOnCard}
          customStyle={{ marginRight: 4 }}
          customInputStyle={{ border: '1px solid #cccccc !important' }}
          onChange={inputOnChange}
          status={verifyStatus(status, 'use2pay_billing_name_on_card')}
          statusMessage={verifyStatusMessage(status, 'use2pay_billing_name_on_card')}
          onFocus={() => {
            handleMelissaBillingFormVysionEvent({
              action: VYSION_ACTIONS.ON_FOCUS,
              event: MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_NAME_ENTRY,
            });
          }}
        />
        <BillingAddressSection className="billingSection">
          <BillingAddressHeading>Billing Address</BillingAddressHeading>
          <BillingAddressAutoComplete
            isCityStateInline={getBrowserDetails().isDesktop}
            handleAddressSuggestionClick={() => {
              handleMelissaBillingFormVysionEvent({
                event: MelissaClickVysionEventsEnum.PAYMENT_ADDRESS_SELECT,
              });
            }}
            fetchAddressSuggestions={(country, partialAddress, onNotFound) =>
              fetchSuggestions(country, partialAddress, onNotFound)
            }
            countryProps={{
              label: <label style={{ display: 'block', marginBottom: 8 }}>Country</label>,
              value: country,
              onChange: e => {
                handleMelissaBillingFormVysionEvent({
                  event: MelissaClickVysionEventsEnum.PAYMENT_COUNTRY_SELECT,
                });
                setCountry(e.target.value);
              },
              children: countryData(country),
              hasError: verifyStatus(status, 'use2pay_billing_country') === 'error',
              errorText: verifyStatusMessage(status, 'use2pay_billing_country'),
              id: 'field_billing_country',
              style: melissaFieldStyles,
            }}
            streetAddressProps={{
              label: <label style={{ display: 'block', marginBottom: 8 }}>Street Address</label>,
              onFocus: () => {
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS,
                });
              },
              onChange: e => {
                setStreetAddress(e.target.value);
              },
              value: streetAddress,
              change: setStreetAddress,
              hasError: verifyStatus(status, 'use2pay_billing_address') === 'error',
              errorText: verifyStatusMessage(status, 'use2pay_billing_address'),
              id: 'field_billing_address',
              style: melissaFieldStyles,
            }}
            zipCodeProps={{
              label: <label style={{ display: 'block', marginBottom: 8 }}>Zip / Postal Code</label>,
              onFocus: () => {
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_ZIP,
                });
              },
              onChange: e => setZipCode(e.target.value),
              value: zipCode,
              change: value => {
                setZipCode(value);
              },
              hasError: verifyStatus(status, 'use2pay_billing_zip') === 'error',
              errorText: verifyStatusMessage(status, 'use2pay_billing_zip'),
              id: 'field_billing_zip',
              style: melissaFieldStyles,
            }}
            cityProps={{
              label: <label style={{ display: 'block', marginBottom: 8 }}>City</label>,
              onChange: e => setCity(e.target.value),
              onFocus: () => {
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_CITY,
                });
              },
              value: city,
              change: setCity,
              hasError: verifyStatus(status, 'use2pay_billing_city') === 'error',
              errorText: verifyStatusMessage(status, 'use2pay_billing_city'),
              id: 'field_billing_city',
              style: melissaFieldStyles,
            }}
            stateProps={{
              label: <label style={{ display: 'block', marginBottom: 8 }}>State</label>,
              onChange: e => setState(e.target.value),
              onFocus: () => {
                handleMelissaBillingFormVysionEvent({
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_STATE,
                });
              },
              value: state,
              change: setState,
              hasError: verifyStatus(status, 'use2pay_billing_state') === 'error',
              errorText: verifyStatusMessage(status, 'use2pay_billing_state'),
              id: 'field_billing_state',
              style: melissaFieldStyles,
            }}
          />
        </BillingAddressSection>
        <Checkbox
          id="cc_save"
          label={'Save for next time'}
          disabled={recurring && selectedPackage?.identifier !== 'idv_last_minute'}
          value={recurring && selectedPackage?.identifier !== 'idv_last_minute' ? true : saveCard}
          onChange={onSelectSaveCard}
          customStyle={
            recurring && selectedPackage?.identifier !== 'idv_last_minute'
              ? { backgroundColor: '#a7d2f1d9 !important', borderColor: '#a7d2f1d9 !important' }
              : {}
          }
        />
      </FormSection>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <PayWithCardButton
          disabled={disableBtn}
          data-test-id="billing-purchase-button"
          customStyle={{ margin: '0 auto', backgroundColor: 'rgb(19, 131, 210)', padding: '0 40px' }}
          buttonType="primary"
        >
          <LockIcon style={{ fontSize: 19, paddingRight: 3 }} />
          {` Pay ${String.fromCharCode(currencyCharCode)}`}
          {selectedPackage ? renderTaxLabel(selectedPackage, discountAmount) : totalAmount}
        </PayWithCardButton>
      </div>
      <BillingTermsAndCondition userCountry={userCountry} />
      <BillingPaymentProcessor userCountry={userCountry} />
      <AfterPayment descriptors={descriptors} />
    </form>
  );
};

export default BillingFormWithMelissaIntegration;
