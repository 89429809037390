/**
 * @Author: zachalam
 * @Date:   2017-02-15T14:12:47-08:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-02-15T14:12:47-08:00
 */

import * as sdk from '../sdk';
import { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/react';
import { SendEvidentlyMetricsParams } from '../sdk/CommonSDK';
import { UPDATING_VARIANTS } from '../reducers/types/Common.d';
import { setLocalItem } from '../common';
import { EVIDENTLY_FEATURES, EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS } from '../constants/evidently';

export const clearEverything = (): { type: string } => {
  return {
    type: 'CLEAR_EVERYTHING',
  };
};

export const clearErrors = (): { type: string } => {
  return {
    type: 'CLEAR_ERRORS',
  };
};

/* Update a global state when we are updating settings. This is to inform the 
   the sidebar component that we are toggling settings and we do not need to 
   invoke the "goToTop" method */

export const updatingSettings = (
  payload: Record<string, unknown>
): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'UPDATING_SETTINGS',
    payload,
  };
};

export const revertLastUpdate = (): { type: string } => {
  return {
    type: 'REVERT_LAST_UPDATE',
  };
};

export const visitMessagesPage = (): { type: string } => {
  return {
    type: 'VISIT_MESSAGES',
  };
};

export const clearIndividualError = (
  payload: Record<string, unknown>
): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'CLEAR_INDIVIDUAL_ERROR',
    payload,
  };
};

export const removeError = (payload: Record<string, unknown>): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'REMOVE_ERROR',
    payload,
  };
};

export const storeErrors = (payload: Record<string, unknown>): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'STORE_ERRORS',
    payload,
  };
};

export const storeSpecificErrors = (
  name: string,
  payload: Record<string, unknown>
): {
  type: string;
  name: string;
  payload: Record<string, unknown>;
} => {
  return {
    type: 'STORE_SPECIFIC_ERRORS',
    name,
    payload,
  };
};

/** store value to detect portrait  **/
export const setPortrait = (payload: Record<string, unknown>): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'SET_PORTRAIT',
    payload,
  };
};

/** sets or removes load status **/
export const setLoading = (): { type: string } => {
  return {
    type: 'IS_FETCHING',
  };
};

/** removes load status **/
export const killLoading = (): { type: string } => {
  return {
    type: 'KILL_IS_FETCHING',
  };
};

export const setHeaderHidden = (
  payload: Record<string, unknown>
): { type: string; payload: Record<string, unknown> } => {
  return {
    type: 'SET_HEADER_HIDDEN',
    payload,
  };
};

export const clearDirtyStatus = (): { type: string } => {
  return {
    type: 'CLEAR_DIRTY',
  };
};

export const forgotPasswordSending = (
  isSent: boolean,
  status: string
): {
  type: string;
  isSent: boolean;
  status: string;
} => {
  return {
    type: 'FORGOT_PASSWORD_SENDING',
    isSent,
    status,
  };
};

export const updateTestVariants = (
  featureName: string,
  payload: Record<string, unknown>
): {
  type: string;
  name: string;
  payload: Record<string, unknown>;
} => {
  return {
    name: featureName,
    type: UPDATING_VARIANTS,
    payload,
  };
};

export const sendPoke = (targetId: string): AxiosPromise => {
  return sdk.sendPoke(targetId);
};

export const fetchEvalFeature = (featureName: string): any => {
  return dispatch => {
    return sdk
      .fetchEvalFeature(featureName)
      .then(res => {
        const index = Object.values(EVIDENTLY_FEATURES).indexOf((featureName as unknown) as EVIDENTLY_FEATURES);
        const featureKey = Object.keys(EVIDENTLY_FEATURES)[index];
        const { variation, userId, reason } = res.data;

        if (typeof EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS[featureKey] !== 'undefined') {
          setLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS[featureKey], JSON.stringify({ variation, userId, reason }));
        }

        dispatch(updateTestVariants(featureName, res.data));
        return res.data;
      })
      .catch(error => {
        dispatch(
          storeSpecificErrors('evalFeature', {
            value: 'Fetching Error',
          })
        );
        console.error('Error Fetching Feature, reverting to original UI:::', error);
        Sentry.captureException(error, {
          tags: { component: 'EvidentlyEvalFeature', action: 'fetchEvalFeatureError' },
        });
      });
  };
};

export const sendEvidentlyMetrics = (params: SendEvidentlyMetricsParams): any => {
  return sdk.sendEvidentlyMetrics(params).catch(error => {
    console.error('Error Sending Metrics', error);
    storeErrors(error);
    Sentry.captureException(error, {
      tags: { component: 'EvidentlySendMetrics', action: 'sendEvidentlyMetricsError' },
    });
  });
};

// action that stores current url when a page is visited
export const storeCurrPage = (): { type: string } => {
  return {
    type: 'STORE_CURR_PAGE',
  };
};

export const getEvidentlyExperiment = (experiment: string): any => {
  return sdk.getEvidentlyExperiment(experiment).catch(error => {
    storeErrors(error);
    Sentry.captureException(error, {
      tags: { component: 'EvidentlyGetExperiment', action: 'getEvidentlyExperiment' },
    });
  });
};
