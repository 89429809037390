/**
 * @Author: zachalam
 * @Date:   2017-01-19T14:10:57-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T15:50:24-08:00
 */

import React, { Component, ReactElement } from 'react';
import BillingMaintenance from '../components/pages/BillingMaintenance';

class BillingMaintenanceContainer extends Component {
  render(): ReactElement {
    return <BillingMaintenance />;
  }
}

export default BillingMaintenanceContainer;
