import React from 'react';
import MasterConfig from '../../config/Master';
import { TOSPath } from '../../utils/helpers';
import { dimensions, paymentTheme } from '../../style';
import styled from '@emotion/styled';

const Content = styled('div')({
  color: paymentTheme.TERMS.color,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px' /* 125% */,
  letterSpacing: '-0.32px',
  maxWidth: 280,
  [dimensions.SCREEN_MIN_MD]: {
    maxWidth: 564,
  },
});

const Container = styled('div')({
  textAlign: 'center',
  marginTop: 30,
  borderBottom: '1px  solid #e3e3e3',
  paddingBottom: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface IBillingTermsAndCondition {
  userCountry: string;
}

const BillingTermsAndCondition = ({ userCountry }: IBillingTermsAndCondition): React.ReactElement => {
  return (
    <Container>
      <Content>
        By submitting the payment, you agree to the purchasing{' '}
        <a
          href={`${MasterConfig.FULL_URL}/${TOSPath(userCountry)}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          <b>Terms & Conditions</b>
        </a>
      </Content>
    </Container>
  );
};

export default BillingTermsAndCondition;
