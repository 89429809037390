import styled from '@emotion/styled';
import { borders } from '../../style';

const bottomBorder = {
  borderBottom: borders.DEFAULT,
  marginBottom: 18,
};

const inPanel = {
  padding: 16,
  marginBottom: 0,
};

const Header = styled('div')(
  {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
  },
  ({ panelHeader }) => (panelHeader ? inPanel : null),
  ({ bottomRuled }) => (bottomRuled ? bottomBorder : null)
);

export default Header;
