import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Source: 'Profile Completion Progress';
}

export const mixpanelTrackEmailVerificationResent = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Verification Email Resent',
    payload: {
      event_properties: eventProperties,
    },
  });
};
