export const googlePay = {
  merchantInfo: {
    merchantId: process.env.REACT_APP_GOOGLEPAY_MERCHANT_ID,
    merchantName: process.env.REACT_APP_GOOGLEPAY_MERCHANT_NAME || 'W8 Tech Limited',
  },
  allowedMethodType: 'CARD',
  allowedAuthMethods: ['PAN_ONLY'],
  allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
  tokenizationSpecification: {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: process.env.REACT_APP_GOOGLEPAY_MERCHANT || 'rocketgate',
      gatewayMerchantId: process.env.REACT_APP_GOOGLEPAY_GATEWAY_MERCHANT_ID,
    },
  },
  environment: process.env.REACT_APP_GOOGLEPAY_IS_LIVE === 'true' ? 'PRODUCTION' : 'TEST',
  isLive: process.env.REACT_APP_GOOGLEPAY_IS_LIVE === 'true' || false,
  apiVersion: 2,
  apiVersionMinor: 0,
};

export const GPAY_PAYMENT_CHANNEL = 'rocketgate_googlepay';
export const GPAY_PAYMENT_TYPE = 'gpay';
