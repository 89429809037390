import React, { FC } from 'react';
import { css } from 'glamor';
import { isFD, s3BucketDirect } from '../../config/Master';
import { isDarkMode } from '../../utils/helpers';

const fdPatternImg = `${s3BucketDirect}modal_pattern/fd_pattern.png`;
const wypPatternImg = isDarkMode()
  ? `${s3BucketDirect}modal_pattern/wyp_pattern_dark.png`
  : `${s3BucketDirect}modal_pattern/wyp_pattern.png`;

interface ModalPatternProps {
  padding?: number | string;
  backgroundSize?: string;
  display?: string;
  customStyle?: any;
  children?: any;
}

const ModalPattern: FC = ({
  padding = 22,
  backgroundSize = 'cover',
  display = 'inherit',
  customStyle = {},
  children,
}: ModalPatternProps) => {
  const bgImageStyle = {
    backgroundImage: `url(${isFD ? fdPatternImg : wypPatternImg})`,
    backgroundSize,
    padding,
    display,
  };

  return (
    <div className={`${css(bgImageStyle)}`} style={customStyle}>
      {children}
    </div>
  );
};

export default ModalPattern;
