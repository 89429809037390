import { STORE_COUNTER, CLEAR_COUNTER } from './types/Counter.d';

/* stores logged in profile */
const counter = (state = null, action: Record<'payload' | 'type', string>): any => {
  switch (action.type) {
    case STORE_COUNTER:
      return action.payload;
    case CLEAR_COUNTER:
      return {};
    default:
      return state;
  }
};

export default counter;
