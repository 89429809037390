import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, type } from '../../style';
import { Report } from '@material-ui/icons';

const DeclinedContainer = styled('div')(
  {
    margin: '0 auto',
    padding: '15px 10px',
    marginBottom: 20,
    border: 'solid 1px #F00',
    backgroundColor: '#F8E9EA',
    borderRadius: 6,
  },
  ({ showTips }) =>
    showTips && {
      position: 'relative',
      paddingBottom: 40,
    }
);

const LockIconConainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const TipContainer = styled('div')({
  ...type.MEDIUM,
  textAlign: 'center',
  backgroundColor: '#F00',
  color: '#ffffff',
  padding: '4px 0',
  position: 'absolute',
  bottom: 0,
  left: 0,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  width: '100%',
});

const StyledIcon = styled(Report)({
  fontSize: '34px !important',
  paddingRight: 3,
  color: '#DD0B24',
});

const CreditCardText = styled('div')({
  textAlign: 'center',
  margin: '5px 0',
  color: '#595959',
});

const TitleText = styled('h4')({
  textAlign: 'center',
  margin: '5px 0',
  color: '#595959',
  [dimensions.SCREEN_MAX_MD]: {
    maxWidth: 250,
    textAlign: 'center',
    margin: '5px auto',
  },
});

interface IPaymentDeclined {
  showTips?: boolean;
}

const PaymentDeclined: React.FC<IPaymentDeclined> = ({ showTips = false }): ReactElement => {
  return (
    <DeclinedContainer data-test-id="cy-declined-payment" showTips={showTips}>
      <LockIconConainer>
        <StyledIcon />
      </LockIconConainer>
      <TitleText>Payment Validation Failed: Processor Declined</TitleText>
      <CreditCardText>For security reasons, please re-enter your credit card information.</CreditCardText>
      {showTips && <TipContainer>Tip: You may try another credit card</TipContainer>}
    </DeclinedContainer>
  );
};

export default PaymentDeclined;
