import React, { Component, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../../atoms/Select';
import { textType } from '../../../style';

const selectStyles = {
  borderWidth: 0,
  boxShadow: 'none',
  paddingLeft: 0,
  borderStyle: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  ...textType.text,

  '&:focus': {
    borderStyle: 'none',
  },
};

class SearchSort extends Component {
  render(): ReactElement {
    const { order, onChangeHandler } = this.props;

    return (
      <Select noSpacing customSelectStyle={selectStyles} onChange={onChangeHandler} value={order || ''} id="order">
        <option value="recent">Recent Activity</option>
        <option value="newest">Newest Members</option>
        <option value="distance">Nearest To Me</option>
      </Select>
    );
  }
}

SearchSort.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
};

export default SearchSort;
