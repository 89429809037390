import React, { ReactElement } from 'react';

const OfferDollarIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <g filter="url(#filter0_d_4175_13956)">
        <circle cx="32" cy="30" r="27.5" fill="#24A83A" />
        <circle cx="32" cy="30" r="26.5" stroke="#5EBE5E" strokeWidth="2" />
      </g>
      <path
        d="M30.3376 46V42.2501C28.5967 41.9306 27.1585 41.2404 26.023 40.1794C24.8874 39.1185 24.0835 37.7308 23.6113 36.0162L26.9744 34.7523C27.4169 36.2052 28.0955 37.2658 29.0102 37.9341C29.9241 38.603 31.0154 38.9375 32.2841 38.9375C33.5818 38.9375 34.6288 38.6466 35.4253 38.0649C36.2217 37.4838 36.6199 36.6555 36.6199 35.5799C36.6199 34.4757 36.2366 33.5965 35.4699 32.9422C34.7031 32.2886 33.1987 31.613 30.9567 30.9155C28.7146 30.2473 27.0775 29.3318 26.0453 28.169C25.0131 27.0062 24.497 25.5822 24.497 23.8969C24.497 22.2689 25.035 20.8881 26.1111 19.7546C27.1879 18.6212 28.5967 17.9383 30.3376 17.706V14H33.6115V17.706C35.0274 17.9383 36.2369 18.4541 37.2401 19.2535C38.2425 20.0528 38.9947 21.0775 39.4967 22.3275L36.2217 23.6783C35.8387 22.7777 35.3003 22.1022 34.6065 21.6516C33.9134 21.201 33.0359 20.9757 31.974 20.9757C30.7351 20.9757 29.7765 21.2299 29.0983 21.7384C28.4201 22.2469 28.081 22.9371 28.081 23.8091C28.081 24.7682 28.4718 25.5456 29.2533 26.1413C30.0349 26.7376 31.6204 27.3989 34.0097 28.125C36.0748 28.7354 37.6234 29.6509 38.6556 30.8716C39.6878 32.0922 40.2039 33.6181 40.2039 35.4491C40.2039 37.3966 39.6216 38.9445 38.4571 40.0926C37.2918 41.2407 35.6766 41.9745 33.6115 42.294V46H30.3376Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4175_13956"
          x="0.5"
          y="0.5"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4175_13956" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4175_13956" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default OfferDollarIcon;
