import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import borders from '../../../../../theme/borders';
import { theme } from '../../../../../theme';
import WYPIcon from '../../../../../atoms/icons/WYPIcon';
import PrimaryButton from '../../../../../atoms/buttons/PrimaryButton';
import { getBrowserDetails } from '../../../../../utils/helpers';

import backgrounds from '../../../../../theme/backgrounds';
import { dimensions } from '../../../../../style';

export const Container = styled('div')({
  height: getBrowserDetails().hImgCalibration,
  maxHeight: getBrowserDetails().hImgCalibration,
  textAlign: 'center',
});

export const MessageCard = styled('div')({
  borderRadius: 10,
  background:
    theme === 'light' ? 'radial-gradient(50% 50% at 50% 50%, #FFF 0%, #EAF4FB 100%)' : backgrounds.dark.primaryCard,
  border: `1px solid ${borders[theme].primary}`,
  padding: '77px 48px',
  marginBottom: 23,
  textAlign: 'center',
  [dimensions.SCREEN_MIN_XS_4]: {
    padding: '38px 48px',
  },
});

export const DescriptionText = styled('p')({
  fontSize: 18,
  lineHeight: '23px',
});

const OneTapNoMoreOffersScreen: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <MessageCard>
        <WYPIcon customStyle={{ width: 100, height: 79 }} />
        <h3 style={{ fontSize: 18, marginTop: 31, marginBottom: 16, lineHeight: '23px' }}>
          You have no more <br />
          Offer Requests to review.
        </h3>
        <DescriptionText>
          You may be at the end of your offers, but {`we're`} not at the end of the fun! Check out Search and make some
          offers of your own!
        </DescriptionText>
      </MessageCard>
      <PrimaryButton
        size="regular"
        color="blue"
        label="Go to Search"
        onClick={() => navigate('/search')}
        customStyle={{
          width: 'inherit',
          padding: '16px 32px',
          minWidth: 207,
          height: 48,
          fontSize: 18,
          fontWeight: 700,
        }}
      />
    </Container>
  );
};

export default OneTapNoMoreOffersScreen;
