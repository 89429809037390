import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { connectScreenSize } from 'react-screen-size';
import { SvgIcon } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import uuidv4 from 'uuid';
import Button from '../../atoms/buttons/Button';
import borders from '../../theme/borders';
import ModalWithPattern from '../../components/common/modals/ModalWithPattern';
import { IsMobileViewPort } from '../../utils/helpers';
import PriceInput from '../../components/pages/Offers/PriceInput'; //adjustStylePerDigit
import { CurrencyTip } from '../../components/pages/Offers/common';
import { getDefaultOfferAmount } from '../../utils/offers';
import WYPButtonIcon from '../../atoms/icons/WYPButtonIcon';
import { DashlineSeparator } from '../../components/join/styledElements';
import CardList from '../CardList';
import { renderAvatar } from '../../common';
import Card from '../../atoms/Card';
import useEvidently from '../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../constants/evidently';
import { counterModalCustomAmount } from '../../actions/profileTrackAction';
import {
  ButtonDiv,
  CloseBtn,
  ContainerDiv,
  CurrencyLabelSpan,
  HeaderDiv,
  LogoIconWrapper,
  OfferTextInputWrapper,
  PriceContainer,
  ProfileCardsWrapper,
  Subtitle,
  Subtitle2,
  Title,
  adjustStylePerDigit,
  cardMarginForOddCard,
  closeButton,
  desktopContentStyle,
  mobileContentStyle,
  mobileOverlayStyle,
  inputPropsStyle,
  cardMarginForEvenCard,
} from './quickOfferModalStyle';
import useModal from '../../hooks/useModal';
import OfferSuccess from '../../components/pages/Offers/OfferSuccess';
import OfferModalFooter from '../../components/pages/Offers/OfferModalFooter';
import WhatIsAnOfferModal from '../../components/common/modals/WhatIsAnOfferModal';

interface Props {
  screenSizeIsMobile: boolean;
  screenSizeIsSmall: boolean;
  profile: any;
  isModalOpen: boolean;
  offer: any;
  onClose: () => void;
  clearErrors: any;
  userProfiles: any;
  postQuickOffer: (formData: { accounts: string[]; price: number }) => any;
  setIsQuickOfferModalOpen: (isQuickOfferModalOpen?: boolean) => void;
}

const LogoIcon = () => {
  return (
    <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path
          id="Vector"
          d="M18.2967 20.4938C18.5014 20.2587 18.7181 20.0297 18.9377 19.8067C16.528 17.4167 13.2903 15.72 10.5304 14.0172C10.4651 13.9599 10.3731 13.8996 10.2455 13.8393C9.40271 13.3149 8.61627 12.7845 7.91295 12.23C5.06106 9.33975 7.25711 5.73826 10.7025 5.91909C12.5692 6.01553 14.7919 7.80271 15.9374 8.84247C15.6406 7.00406 16.7891 4.66837 19 2.7757C16.982 1.17839 14.1005 0 10.6521 0C10.5185 0 10.3879 0.00904138 10.2544 0.015069C9.8419 0.0301379 9.4383 0.0632897 9.04361 0.117538C4.12329 0.792628 0.749113 4.46644 0.114042 8.92987C0.0724948 9.21618 0.0428222 9.50551 0.0250164 9.79784C0.0428222 9.50551 0.0724948 9.21618 0.114042 8.92987C0.0250128 9.49948 -0.0135648 10.0811 0.00424091 10.6598C0.00127328 10.4458 0.0101725 10.2348 0.0190754 10.0269C0.0101725 10.2409 0.00127328 10.4549 0.00424091 10.6718V10.6598C0.00424091 10.6598 0.00424091 10.6779 0.00424091 10.6869C0.00424091 10.7713 0.00719911 10.8497 0.0131344 10.931C0.0190696 11.0124 0.0250164 11.0908 0.0250164 11.1721C0.443451 18.0707 6.77339 19.4691 10.2603 21.636C12.6226 23.1007 14.8453 24.3726 16.6556 26C16.3855 24.2158 16.7594 22.2569 18.2967 20.4938ZM13.2013 0.244117C13.1271 0.229048 13.0529 0.216993 12.9787 0.204938C13.0529 0.216993 13.1271 0.232062 13.2013 0.244117ZM11.2545 0.0180828C11.1892 0.0180828 11.121 0.0120552 11.0557 0.0120552C11.121 0.0120552 11.1892 0.015069 11.2545 0.0180828Z"
          fill="#BC2028"
        />
        <path
          id="Vector_2"
          d="M19.5048 32C17.5708 32 16 33.5676 16 35.4952C16 37.4228 17.5708 39 19.5048 39C21.4388 39 23 37.4324 23 35.4952C23 33.558 21.4356 32 19.5048 32Z"
          fill="#2B8FD7"
        />
        <path
          id="Vector_3"
          d="M28.654 0.0151164C28.9898 -1.46414e-08 29.1438 0 28.9467 0C28.8419 0 28.7464 0.0151164 28.6417 0.0151164C28.4661 0.0181397 28.2812 0.0332561 28.1025 0.0423259C27.169 0.0906984 26.2787 0.226746 25.4407 0.423259C25.7826 0.344654 26.1308 0.281165 26.4758 0.229769C26.1308 0.287211 25.7857 0.344654 25.4314 0.426282C18.5118 2.04374 14.975 8.10541 18.2099 10.4454C20.3234 11.9722 22.3444 9.8317 22.6617 9.611C22.6617 9.611 26.1554 6.07074 28.8943 5.9347C32.4527 5.75632 34.7233 9.33589 31.8242 12.2292C28.4137 14.8564 22.9267 16.9273 19.5747 20.6429C16.6787 23.8537 17.7447 27.7114 19.6086 30C21.5649 26.0576 25.262 24.159 29.3503 21.7071C33.0565 19.482 39.8498 18.0701 39.9977 10.7205C40.1179 4.9098 35.5151 -0.108838 28.6509 0.0151164H28.654Z"
          fill="#2B8FD7"
        />
      </g>
    </svg>
  );
};

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.data.min_counter_price : offer && offer.data.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};

const QuickOfferModal: FC<Props> = ({
  screenSizeIsMobile,
  screenSizeIsSmall,
  profile,
  isModalOpen,
  offer,
  clearErrors,
  onClose,
  userProfiles,
  postQuickOffer,
  setIsQuickOfferModalOpen,
}): ReactElement => {
  const isAttractiveUser = profile?.profile?.data?.account_type === 'Attractive';
  const currencyInitial = profile?.currency?.data?.initial;
  const currencyLabelUnicode =
    profile?.currency?.data?.label_unicode && String.fromCharCode(profile.currency.data.label_unicode);
  const defaultOfferPrice = getDefaultOfferAmount();
  const { basicModal, resetModal } = useModal();
  const { useActiveVariation } = useEvidently();

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState(defaultOfferPrice);
  const [hasErrors, setHasErrors] = useState(false);
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer, 'min', defaultMinOffer));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer, 'max', '500'));
  const [selectedHashIds, setSelectedHashIds] = useState([]);
  const [offerSendingLoader, setOfferSendingLoader] = useState(false);
  const sendOfferBtn = useRef(null);
  const priceInput = useRef(null);
  const checkBoxRef = useRef([]);

  useEffect(() => {
    clearErrors();
    trackFocus(priceInput, counterModalCustomAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfiles.length > 0) {
      const hashIds = userProfiles.map(item => item.hash_id);
      setSelectedHashIds(hashIds);
    }
  }, [userProfiles]);

  useEffect(() => {
    setMaxValue(offerMinMaxVal(offer, 'max', '500'));
    setMinValue(offerMinMaxVal(offer, 'min', defaultMinOffer));
  }, [offer, defaultMinOffer]);

  const colorType = (price, hasErrors) => {
    switch (price) {
      case true:
        return 'default';
      case false:
        return !hasErrors ? 'brand' : 'alert';
      default:
        return;
    }
  };

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const validateTextInput = e => {
    const Key = e.key;
    const regex = /[0-9]/;
    if (!regex.test(Key)) {
      if (e.preventDefault) e.preventDefault();
    }
  };

  const inputOnChange = e => {
    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValueNum.toString().length;
    if (e.target.value.length <= maxLength) {
      const isError = inputVal < minValueNum || inputVal > maxValueNum;
      updateOfferPrice(inputVal, isError);
    } else {
      e.preventDefault();
    }
  };

  const handleSelectedUsers = (hashId: string) => {
    let updatedHashIds = JSON.parse(JSON.stringify(selectedHashIds));
    if (!updatedHashIds.includes(hashId)) {
      updatedHashIds.push(hashId);
    } else {
      updatedHashIds = updatedHashIds.filter(function(id: string) {
        return id != hashId;
      });
    }
    setSelectedHashIds(updatedHashIds);
  };

  const renderUserProfileResults = (
    profile: any,
    index: number,
    screenSizeIsMobile: boolean,
    screenSizeIsSmall: boolean
  ) => {
    try {
      const age = profile?.profile?.data?.age;
      const avatar = profile?.photo?.data?.length && renderAvatar(profile.photo.data[0].urls);
      const city = profile.profile.data.city;
      const username = profile?.username || '';
      const hashId = profile.hash_id || username;
      const photoCount = parseInt(profile.profile.data.approved_public_photos_count, 10);
      const privatePhotoCount = profile.profile.data.private_photos_count
        ? parseInt(profile.profile.data.private_photos_count, 10)
        : 0;
      const online = profile.profile.data.online;
      const userVerified = profile.id_verification?.data?.status === 'approved';
      const cardMargin = screenSizeIsMobile
        ? index % 2 == 0
          ? '3.5px 3.5px 3.5px 7px'
          : '3.5px 7px 3.5px 3.5px'
        : '2.18%';
      const cardMarginForSmallXS = index % 2 == 0 ? cardMarginForEvenCard : cardMarginForOddCard;
      const isChecked = (selectedHashIds.length > 0 && selectedHashIds.includes(hashId)) || false;
      return (
        <Card
          id={`quickoffer-user-card-${hashId}`}
          onClick={handleSelectedUsers}
          key={uuidv4()}
          age={age}
          city={city}
          username={username}
          hashId={hashId}
          photoCount={photoCount}
          privatePhotoCount={privatePhotoCount}
          online={online}
          cardWidth={screenSizeIsMobile ? '46.9%' : '45.2%'}
          cardMargin={cardMargin}
          imgUrl={avatar}
          isVerified={userVerified}
          screenIsMobile={screenSizeIsMobile || screenSizeIsSmall}
          link={'#'}
          checkBoxRef={checkBoxRef}
          index={index}
          showSelectUserCheckbox={true}
          isChecked={isChecked}
          customCardStyle={cardMarginForSmallXS}
        />
      );
    } catch (e) {
      console.error('error', e);
      return null;
    }
  };

  const handleSendOffer = e => {
    e.preventDefault();
    try {
      const formData = {
        accounts: selectedHashIds,
        price: offerPrice,
      };
      setOfferSendingLoader(true);
      postQuickOffer(formData)
        .then(() => {
          sessionStorage.setItem('quickOfferSubmitted', 'true');
          setIsQuickOfferModalOpen(false);
          setOfferSendingLoader(false);
          basicModal({
            dateTestId: 'offer-success',
            modalContentLabel: 'Success Offer Modal',
            isModalOpen: true,
            modalBody: (
              <>
                <OfferSuccess
                  offerAmount={''}
                  avatarSrc={''}
                  username={''}
                  successOpen={resetModal}
                  buttonID="quickOffersSend"
                  isCounter={false}
                  isRevised={false}
                  isSuggest={false}
                  mainTitle={isAttractiveUser ? 'Offer Requests Sent!' : 'Offers Sent!'}
                  subTitleOne={isAttractiveUser ? 'Your offer requests have been sent!' : 'Your offers have been sent!'}
                  subTitleTwo={'Check your Offers page to see who’s accepted.'}
                  customHeaderStyle={{ marginTop: 20 }}
                  customTitleStyle={{ marginTop: 18 }}
                />
                <OfferModalFooter />
              </>
            ),
            closeRequest: () => {
              sessionStorage.removeItem('hasAddedPhoto');
            },
            onCloseClickHandler: () => {
              sessionStorage.removeItem('hasAddedPhoto');
            },
          });
        })
        .catch(() => {
          setOfferSendingLoader(false);
          setHasErrors(true);
        });
    } catch (error) {
      setOfferSendingLoader(false);
      console.log('error', error);
    }
  };

  return (
    <ModalWithPattern
      dataTestID="quick-offer-modal"
      isModalOpen={isModalOpen}
      modalContentLabel="Quick Offer Modal"
      closeOnOverlayClick
      closeRequest={onClose}
      closeBtnClassName="quick-offer-close"
      closeBtnDataTestId="quick-offer-close"
      onCloseClick={onClose}
      withoutBgPattern={true}
      customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
      customContentStyle={IsMobileViewPort ? mobileContentStyle : desktopContentStyle}
      customContentBodyStyle={{ padding: 0, background: 'none' }}
      hideCloseBtn={true}
    >
      <ContainerDiv>
        <CloseBtn onClick={onClose}>
          <SvgIcon component={CancelRounded} style={closeButton} />
        </CloseBtn>
        <LogoIconWrapper>
          <LogoIcon />
        </LogoIconWrapper>
        <HeaderDiv>
          <Title>{`Let's go!`}</Title>
          <Subtitle>Nearby members are eager to date you.</Subtitle>
          <Subtitle2>{`Set your ${
            isAttractiveUser ? 'Offer Request' : 'Offer'
          }, choose one (or all), send, and start planning your next date!`}</Subtitle2>
        </HeaderDiv>
        <OfferTextInputWrapper>
          <Subtitle>{isAttractiveUser ? 'Your offer request amount' : 'Your offer amount'}:</Subtitle>
          <PriceContainer hasErrors={hasErrors}>
            <CurrencyTip
              offerPrice={colorType(offerPrice, hasErrors)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
              }}
            >
              <CurrencyLabelSpan>
                {currencyInitial}
                {currencyLabelUnicode}
              </CurrencyLabelSpan>

              <PriceInput
                inputRef={priceInput}
                data-test-id="offer-price-input"
                hasErrors={hasErrors}
                value={offerPrice}
                onChange={inputOnChange}
                validateTextFn={validateTextInput}
                placeholder={defaultValue}
                inputProps={{
                  style: {
                    ...inputPropsStyle,
                    marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                  },
                }}
                minWidth={100}
              />
            </CurrencyTip>
          </PriceContainer>
          <WhatIsAnOfferModal isAttractiveUser={isAttractiveUser} />
        </OfferTextInputWrapper>
        <ProfileCardsWrapper>
          <CardList isMobile={screenSizeIsMobile} listMargin={screenSizeIsMobile ? '0px -20px 0px -20px' : '0px 30px'}>
            {userProfiles.length > 0 &&
              userProfiles.map((profile, index) => {
                return renderUserProfileResults(profile, index, screenSizeIsMobile, screenSizeIsSmall);
              })}
          </CardList>
        </ProfileCardsWrapper>
        {IsMobileViewPort ? null : <DashlineSeparator style={{ width: '55%', marginBottom: 0 }} />}
        <ButtonDiv>
          <Button
            id={'quick-offer-btn'}
            buttonRef={sendOfferBtn}
            disabled={selectedHashIds.length < 1 || hasErrors || offerSendingLoader}
            buttonType="error"
            onClick={handleSendOffer}
            type="button"
            customStyle={{ width: '195px' }}
          >
            <WYPButtonIcon style={{ marginRight: 6 }} />
            {offerSendingLoader ? 'Sending...' : isAttractiveUser ? 'Send Requests' : 'Send Offers'}
          </Button>
          <Button
            buttonType="chromeless"
            data-test-id="s"
            customStyle={{
              marginTop: '20px',
              fontSize: 12,
              lineHeight: '17px',
              fontWeight: 400,
              padding: '4px 0px',
              color: borders.light.secondary,
              height: '20px',
            }}
            onClick={onClose}
          >
            Not right now
          </Button>
        </ButtonDiv>
      </ContainerDiv>
    </ModalWithPattern>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
    screenSizeIsSmall: screenSize.small,
  };
};

export default connectScreenSize(mapScreenToProps)(QuickOfferModal);
