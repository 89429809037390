import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';
import { extractLocationData } from '../common';

const api = MasterConfig.api;

const createUserProfile = (token: any, formData: any): AxiosPromise => {
  const { sex, interested, year, month, day, location } = formData;
  location = extractLocationData(location);

  return axios.post(`${api}/profile`, {
    sex,
    interested,
    year,
    month,
    day,
    ...location,
  });
};

const getLoggedProfile = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/auth/user?include=profile,photo,video`,
    data: {},
  });
};

const getProfileMapFields = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/map_fields/all`,
  });
};

const getSignedEmailPromoToken = (token: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/auth/validate-email-promo-token?email_promo_token=${token}`,
    data: {},
  });
};

const getLoggedAccount = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/auth/user`,
    data: {},
  });
};

const updateProfileCoverData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/cover`,
    data: formData,
  });
};

const updateProfileOpeningOfferData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/opening_offer`,
    data: formData,
  });
};
const updateProfileAboutData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/about`,
    data: formData,
  });
};

const updateProfileInterestData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/interested_in`,
    data: formData,
  });
};

const updateProfileDescriptionData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/description`,
    data: formData,
  });
};

const updateProfileSeekingData = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile/seeking`,
    data: formData,
  });
};

const updateLoggedProfile = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/profile`,
    data: formData,
  });
};

const getAccountByUserName = (token: any, username: string): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/account/${username}`,
    data: {},
  });
};

const getExternalProfile = (token: any, username: string): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/account/pre/${username}?include=profile,offer,photo,video`,
    data: {},
  });
};

const getAccountSettings = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/settings?include=social,recurring`,
    data: {},
  });
};

const getVideoChatPreferences = (hash_id: number | string): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/settings/video-call/${hash_id}`,
    data: {},
  });
};

const getTurnCredentials = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/thread/video-call/turn-credential`,
  });
};

const getCheckInfoRequest = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/settings/check-info-request`,
    data: {},
  });
};

const requestUserInfo = (): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/settings/request-user-info`,
    data: {},
  });
};

const getBlockedList = (token: any, page = 1): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/block?include=to_account,to_account.profile&page=${page}`,
    data: {},
  });
};

const editBiometricName = (newName: any, deviceId: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/webauthn/edit/${deviceId}`,
    data: {
      name: newName,
    },
  });
};

const updateAccountSettings = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings`,
    data: formData,
  });
};

const updateVideoChatPreferences = (hash_id: any, formData: any): AxiosPromise => {
  const videoFormData = {
    block_incoming: !!formData.block_incoming,
    have_conversed: !!formData?.have_conversed,
  };
  return axios({
    method: 'put',
    url: `${api}/settings/video-call/${hash_id}`,
    data: videoFormData,
  });
};

const gdprDelete = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/account/gdpr_delete`,
    data: formData,
  });
};

const gdprAcknowledged = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/account/gdpr_acknowledge`,
    data: formData,
  });
};

const mockProcessPhoto = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/photo-process`,
    data: formData,
  });
};

const postNewPhoto = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/photo`,
    data: formData,
  });
};

const uploadToS3 = (url: string, media: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: url,
    data: media,
    withCredentials: false,
  });
};

const getPhoto = (photoUrl: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: photoUrl,
    withCredentials: false,
  });
};

const processPhoto = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/photo-process`,
    data: formData,
  });
};

const processPhotoMessage = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/message/upload/photo`,
    data: formData,
  });
};

const deletePhoto = (token: any, photoId: any): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/photo/${photoId}`,
    data: {},
  });
};

const putPhotoAvatar = (token: any, photoId: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/photo/avatar`,
    data: { id: photoId },
  });
};

const putPhotoPrivacy = (token: any, photoId: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/photo/${photoId}`,
    data: {},
  });
};

const reportUser = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/report`,
    data: formData,
  });
};

const getReportReasons = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/report/reasons/legacy`,
  });
};

const blockUser = (token: any, username: string): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/block/${username}`,
    data: {},
  });
};

const getCredits = (token: any, per_page: number, page = 1): AxiosPromise => {
  const numberToGet = per_page === 0 ? MasterConfig.per_page : per_page;

  return axios({
    method: 'GET',
    url: `${api}/credits?page=${page}&per_page=${numberToGet}`,
    data: {},
  });
};

/*
// WYP-13499 Deprecate ActivityFeed
const recentActivity = (token: any, num: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/activity/${num}`,
    data: {},
  });
};
*/

const updateMessageReminder = (): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings/message-reminder`,
  });
};

const updateShowOfferTutorial = (state: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings/offer-tutorial`,
    data: {
      state,
    },
  });
};

const getNuxGuides = (curRoute: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/nux?route=` + curRoute,
  });
};

const updateNuxGuideStates = (formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/nux/state`,
    data: formData,
  });
};

const updateShowWishlist = (state: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings/show-wishlist`,
    data: {
      state,
    },
  });
};

const updateShowGiftSent = (state: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings/show-gift-sent`,
    data: {
      state,
    },
  });
};

const resetNuxGuide = (): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/nux/reset`,
  });
};

const skipNuxGuides = (): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/settings/skip-nux-guides`,
  });
};

const updateMandatoryCompletion = (formData: any): AxiosPromise => {
  return axios.post(`${api}/profile/mandatory-completion/update`, formData);
};

const getMandatoryCompletion = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/profile/mandatory-completion/validate`,
    data: {},
  });
};

const verifyPassword = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account/verify-password`,
    data: formData,
  });
};

const updatePhoneNumber = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account/change-phone-number`,
    data: formData,
  });
};

const updateProfileNewJoinFlow = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/profile/new-join-flow/update`,
    data: formData,
  });
};

const getSwipeModeAccounts = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/dashboard/swipe-mode/accounts?include=profile,photo,video`,
  });
};

const getQuickOfferUsers = (limit: number): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/dashboard/swipe-mode/accounts?include=profile,photo,video&limit=${limit}`,
  });
};

const swipeModePassAccount = (toAccountHashId: string): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/dashboard/swipe-mode/pass-account`,
    data: {
      to_account_hash_id: toAccountHashId,
    },
  });
};

export {
  createUserProfile,
  getLoggedProfile,
  getProfileMapFields,
  getSignedEmailPromoToken,
  getLoggedAccount,
  updateProfileCoverData,
  updateProfileAboutData,
  updateProfileInterestData,
  updateProfileDescriptionData,
  updateProfileSeekingData,
  updateLoggedProfile,
  getAccountByUserName,
  getExternalProfile,
  getAccountSettings,
  getVideoChatPreferences,
  getTurnCredentials,
  getCheckInfoRequest,
  requestUserInfo,
  getBlockedList,
  editBiometricName,
  updateAccountSettings,
  updateVideoChatPreferences,
  gdprDelete,
  gdprAcknowledged,
  mockProcessPhoto,
  postNewPhoto,
  uploadToS3,
  getPhoto,
  processPhoto,
  processPhotoMessage,
  deletePhoto,
  putPhotoAvatar,
  putPhotoPrivacy,
  reportUser,
  getReportReasons,
  blockUser,
  getCredits,
  // recentActivity, // WYP-13499 Deprecate ActivityFeed
  updateMessageReminder,
  getNuxGuides,
  updateNuxGuideStates,
  updateShowOfferTutorial,
  updateShowWishlist,
  updateShowGiftSent,
  resetNuxGuide,
  skipNuxGuides,
  updateMandatoryCompletion,
  getMandatoryCompletion,
  verifyPassword,
  updatePhoneNumber,
  updateProfileNewJoinFlow,
  getSwipeModeAccounts,
  getQuickOfferUsers,
  swipeModePassAccount,
  updateProfileOpeningOfferData,
};
