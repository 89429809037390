import { vysionService } from '../utils/vysion/vysionService';
import { convertSentanceTitleCase } from '../utils/helpers';

const setOfferAction = (eventType: any): any => {
  return {
    type: 'OFFERS',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: 'Offers',
        extraInfo: setExtraInfo(eventType),
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setComponent = (eventType: any): any => {
  return convertSentanceTitleCase(eventType);
};

const setExtraInfo = eventType => {
  switch (eventType) {
    case 'IGNORE OFFER':
    case 'COUNTER OFFER':
    case 'ACCEPT OFFER':
      return 'Received Offers';
    case 'CANCEL OFFER':
      return 'Sent Offers';
    default:
      return;
  }
};

const ignoreOffer = (): any => {
  vysionService(setOfferAction('IGNORE OFFER'));
};

const counterOffer = (): any => {
  vysionService(setOfferAction('COUNTER OFFER'));
};

const acceptOffer = (): any => {
  vysionService(setOfferAction('ACCEPT OFFER'));
};

const cancelOffer = (): any => {
  vysionService(setOfferAction('CANCEL OFFER'));
};

export { ignoreOffer, counterOffer, acceptOffer, cancelOffer };
