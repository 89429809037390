import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, textColor } from '../../../../style';
import CameraSmall from '../../../../atoms/icons/Gallery/CameraSmall';
import VideoSmall from '../../../../atoms/icons/Gallery/VideoSmall';
import ExistingPhoto from '../../../../atoms/icons/Gallery/ExistingPhoto';
import ExistingVideo from '../../../../atoms/icons/Gallery/ExistingVideo';
import CapturePhoto from '../../../../atoms/icons/Gallery/CapturePhoto';
import RecordVideo from '../../../../atoms/icons/Gallery/RecordVideo';
import { PhotoConfig, VideoConfig } from '../../../../config/Media';
// import Button from '../../../../atoms/buttons/Button';
import GalleryModal from '../../../modals/GalleryModal';
import { isDarkMode, IsMobileViewPort, tabletWidth } from '../../../../utils/helpers';
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll';
import { handleUploadGAlleryVysionEvent } from '../../../../utils/vysion/profile/uploadGallery';
import { ProfileUploadGalleryClickVysionEventsEnum } from '../../../../utils/vysion/profile/uploadGallery/click';
import SecondaryButton from '../../../../atoms/buttons/SecondaryButton';
import { getBrowserDetails } from '../../../../utils/helpers';

const { isMobile } = getBrowserDetails();

const ModalHeader = styled('h3')(() => ({
  marginTop: 10,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '32px',
  color: isDarkMode() ? '#D06369' : '#BC2028',
  textAlign: 'center',
  marginBottom: 10,
  letterSpacing: -0.56,
}));

const ModalSubHeader = styled('p')(() => ({
  marginTop: 0,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  marginBottom: 30,
  color: isDarkMode() ? '#AFB0B1' : '#595959',
}));

const StyledChooseLabel = styled('span')(() => ({
  fontWeight: 500,
  letterSpacing: '-0.28px',
  fontSize: 14,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const UploadOptionWrap = styled('div')({
  background: isDarkMode() ? '#113956' : '#0D5C93',
  borderRadius: 22,
  display: 'flex',
  maxWidth: 350,
  minHeight: 40,
  border: `2px solid ${isDarkMode() ? '#113956' : '#0D5C93'}`,
});

const UploadOptionSwitch = styled('button')(
  {
    background: isDarkMode() ? '#113956' : '#0D5C93',
    borderRadius: 22,
    color: '#FFFFFF',
    width: IsMobileViewPort ? 143 : 173,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    '& svg': {
      marginRight: 2,
    },
    '& span': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '25px',
    },
  },
  ({ active }) => active && { background: '#2B8FD7' }
);

const PhotoUploadSwitch = styled(UploadOptionSwitch)({});

const VideoUploadSwitch = styled(UploadOptionSwitch)({});

const UploadActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  maxWidth: 350,
  padding: 20,
  [dimensions.SCREEN_MAX_XS]: {
    padding: 0,
    marginTop: 20,
  },
});

const UploadActionsButton = styled('button')({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '49%',
  background: 'transparent',
  flexWrap: 'wrap',
  flexDirection: 'column',
  border: 'none',
  alignItems: 'center',
  color: '#2B8FD7',
});

const StyleList = styled('ul')({
  fontSize: 14,
  marginLeft: -20,
  marginBottom: 30,
  color: isDarkMode() ? '#AFB0B1' : '#595959',
  marginTop: 0,
  [dimensions.SCREEN_MAX_XS]: {
    marginTop: 20,
  },
  '& li': { padding: '5px 0' },
});

const StyleDivider = styled('div')({
  width: 2,
  height: 110,
  background: isDarkMode() ? '#6C6C6C' : '#DAE1E9',
});

interface IGalleryUploadOption {
  onClose: any;
  isOpen: boolean;
  onSelected: any;
  onToggle: any;
  header?: string;
  uploadOption: string;
}

const GalleryUploadOption = ({
  onClose,
  onSelected,
  onToggle,
  header = 'Upload Gallery Content',
  isOpen,
  uploadOption,
  ...rest
}: IGalleryUploadOption): ReactElement => {
  useDisableBodyScroll(isOpen);

  return (
    <GalleryModal
      onClose={() => {
        handleUploadGAlleryVysionEvent({
          event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL,
        });
        onClose();
      }}
      customStyle={{
        content: {
          maxWidth: IsMobileViewPort && !tabletWidth ? 'none' : 450,
          padding: 20,
          border: `solid 1px ${isDarkMode() ? '#6C6C6C' : 'rgb(204, 204, 204)'}`,
        },
      }}
      customCloseBtnStyle={{ top: 7, right: 7 }}
      customContentStyle={{ position: 'unset', padding: isMobile ? 2 : 22, paddingBottom: 10 }}
      isOpen={isOpen}
      contentLabel=""
      {...rest}
    >
      <ModalDescription hasHeader={true} data-test-id="gallery-add-content-option">
        <ModalHeader>{header}</ModalHeader>
        <ModalSubHeader>What would you like to upload?</ModalSubHeader>
        <UploadOptionWrap data-test-id="gallery-add-content-switch">
          <PhotoUploadSwitch
            data-test-id="gallery-add-content-photo"
            active={uploadOption === 'photo'}
            onClick={() => {
              handleUploadGAlleryVysionEvent({
                event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO,
              });
              onToggle('photo');
            }}
          >
            <CameraSmall /> <span>Photo</span>
          </PhotoUploadSwitch>
          <VideoUploadSwitch
            data-test-id="gallery-add-content-video"
            active={uploadOption === 'video'}
            onClick={() => {
              handleUploadGAlleryVysionEvent({
                event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO,
              });
              onToggle('video');
            }}
          >
            <VideoSmall /> <span>Video</span>
          </VideoUploadSwitch>
        </UploadOptionWrap>
        <UploadActions data-test-id={`gallery-actions-${uploadOption}`}>
          <UploadActionsButton
            data-test-id={`gallery-actions-existing-${uploadOption}`}
            onClick={() => {
              handleUploadGAlleryVysionEvent({
                event:
                  uploadOption === 'photo'
                    ? ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CHOOSE_EXISTING
                    : ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CHOOSE_EXISTING,
              });
              onSelected('existing', uploadOption);
            }}
          >
            {uploadOption === 'photo' ? <ExistingPhoto customStyle={{ marginBottom: 6 }} /> : <ExistingVideo />}
            <StyledChooseLabel>Choose Existing</StyledChooseLabel>
          </UploadActionsButton>
          <StyleDivider />
          <UploadActionsButton
            data-test-id={`gallery-actions-new-${uploadOption}`}
            onClick={() => {
              handleUploadGAlleryVysionEvent({
                event:
                  uploadOption === 'photo'
                    ? ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CREATE_NEW
                    : ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CREATE_NEW,
              });
              onSelected('capture', uploadOption);
            }}
          >
            {uploadOption === 'photo' ? <CapturePhoto customStyle={{ marginBottom: 6 }} /> : <RecordVideo />}
            <StyledChooseLabel>Create New</StyledChooseLabel>
          </UploadActionsButton>
        </UploadActions>
        <StyleList data-test-id="gallery-add-content-guidelines">
          {uploadOption === 'video' ? (
            <li style={{ fontWeight: 700 }}>All videos will be moderated for content</li>
          ) : (
            <li style={{ fontWeight: 700 }}>All photos will be moderated for content</li>
          )}

          {uploadOption === 'video' ? (
            <>
              <li>
                Max length: <strong>{VideoConfig.maxDuration} seconds</strong>
              </li>
              <li>
                Max file size: <strong>{VideoConfig.maxFileSizeMB} Mb</strong>
              </li>
              <li>
                Max photos & videos allowed: <strong>20</strong>
              </li>
              <li>
                Accepted video formats:
                <div>
                  <strong>{VideoConfig.allowedMimeTypeText}</strong>
                </div>
              </li>
            </>
          ) : (
            <>
              <li>
                Recommended width: <strong>400px or more</strong>
              </li>
              <li>
                Max file size: <strong>{PhotoConfig.maxFileSizeMB} Mb</strong>
              </li>
              <li>
                Max photos & videos allowed: <strong>20</strong>
              </li>
              <li>
                Accepted photo formats:
                <div>
                  <strong>{PhotoConfig.allowedMimeTypeText}</strong>
                </div>
              </li>
            </>
          )}
        </StyleList>
        <SecondaryButton
          customStyle={{ width: 'inherit', fontSize: 14, padding: '12px 22px' }}
          color="blue"
          size="small"
          data-test-id={`gallery-upload-cancel`}
          onClick={() => {
            handleUploadGAlleryVysionEvent({
              event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL,
            });
            onClose();
          }}
          label="Cancel"
        />
      </ModalDescription>
    </GalleryModal>
  );
};

export default GalleryUploadOption;
