import React, { ReactElement } from 'react';
interface ISliderArrowLeftIconProps {
  customStyle?: any;
  onClick?: () => any;
}

const SliderArrowLeftIcon = ({ customStyle = {}, ...rest }: ISliderArrowLeftIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      style={customStyle}
      className="pressable"
      {...rest}
    >
      <g filter="url(#filter0_d_371_12939)">
        <path
          d="M13.8 15H17C17.2833 15 17.5208 14.9042 17.7125 14.7125C17.9042 14.5208 18 14.2833 18 14C18 13.7167 17.9042 13.4792 17.7125 13.2875C17.5208 13.0958 17.2833 13 17 13H13.8L14.7 12.1C14.8833 11.9167 14.975 11.6833 14.975 11.4C14.975 11.1167 14.8833 10.8833 14.7 10.7C14.5167 10.5167 14.2833 10.425 14 10.425C13.7167 10.425 13.4833 10.5167 13.3 10.7L10.7 13.3C10.5 13.5 10.4 13.7333 10.4 14C10.4 14.2667 10.5 14.5 10.7 14.7L13.3 17.3C13.4833 17.4833 13.7167 17.575 14 17.575C14.2833 17.575 14.5167 17.4833 14.7 17.3C14.8833 17.1167 14.975 16.8833 14.975 16.6C14.975 16.3167 14.8833 16.0833 14.7 15.9L13.8 15ZM14 24C12.6167 24 11.3167 23.7375 10.1 23.2125C8.88333 22.6875 7.825 21.975 6.925 21.075C6.025 20.175 5.3125 19.1167 4.7875 17.9C4.2625 16.6833 4 15.3833 4 14C4 12.6167 4.2625 11.3167 4.7875 10.1C5.3125 8.88333 6.025 7.825 6.925 6.925C7.825 6.025 8.88333 5.3125 10.1 4.7875C11.3167 4.2625 12.6167 4 14 4C15.3833 4 16.6833 4.2625 17.9 4.7875C19.1167 5.3125 20.175 6.025 21.075 6.925C21.975 7.825 22.6875 8.88333 23.2125 10.1C23.7375 11.3167 24 12.6167 24 14C24 15.3833 23.7375 16.6833 23.2125 17.9C22.6875 19.1167 21.975 20.175 21.075 21.075C20.175 21.975 19.1167 22.6875 17.9 23.2125C16.6833 23.7375 15.3833 24 14 24Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_371_12939"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_371_12939" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_371_12939" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default SliderArrowLeftIcon;
