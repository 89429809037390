import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import { SvgIcon } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';
import colors from '../../theme/colors';
import { dimensions } from '../../style';

const StyledProfileEditButtonV2 = styled(Button)({
  fontSize: 14,
  color: '#2B8FD7',
  fontWeight: 600,
  borderRadius: 90,
  border: getBrowserDetails().isMobile ? '1px solid transparent' : '1px solid #2B8FD7',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  padding: 12,
  height: 32,
  cursor: 'pointer',
  lineHeight: '17px',
  ':hover': {
    backgroundColor: isDarkMode() ? 'transparent' : 'unset',
    color: 'rgb(28,115,177)',
    border: '1px solid rgb(28,115,177)',
  },
  ':focus': {
    backgroundColor: 'inherit',
    color: colors.blue[500],
    border: 'inherit',
  },
  [dimensions.SCREEN_MAX_XS_2]: {
    float: 'unset !important',
    paddingBottom: 0,
    paddingLeft: 2,
    fontSize: 13,
    fontWeight: 600,
    color: '#95C7EB',
    paddingTop: 0,
    height: '100%',
    '& svg': {
      color: '#95C7EB !important',
      marginRight: '2px !important',
      height: '15px !important',
      width: '15px !important',
    },
    ':hover': {
      border: 'solid 1px transparent',
    },
  },
});

const closeButton = {
  marginRight: 4,
  color: '#2B8FD7',
  cursor: 'pointer',
  height: 16,
  width: 16,
};

interface CancelButtonProps {
  handleClick(): void;
  dataTestId: string;
  customStyle: any;
}

const ProfileEditButtonV2 = ({ handleClick, dataTestId, customStyle }: CancelButtonProps): ReactElement => {
  return (
    <StyledProfileEditButtonV2 data-test-id={dataTestId} onClick={handleClick} customStyle={customStyle}>
      <SvgIcon component={EditIcon} style={closeButton} />
      Edit
    </StyledProfileEditButtonV2>
  );
};

export default ProfileEditButtonV2;
