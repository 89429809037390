import React, { useEffect, useState } from 'react';
import Panel from './panels/Panel';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import styled from '@emotion/styled';
import { Select } from './Select';
import TextInput from './TextInput';
import Button from './buttons/Button';
import { isDarkMode, useFilteredBodyType } from '../utils/helpers';
import CheckboxGroup from '../atoms/CheckboxGroup';
import { inputTheme } from '../style';
import ToggleButton from './togglebutton/ToggleButton';
import CancelButton from './buttons/ProfileCancelButton';

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const toggleBtnLabelStyle = {
  padding: '2px 12px 0px',
  lineHeight: '24px',
  color: 'rgba(36,145,222,1)',
};

const customToggle = {
  paddingBottom: 10,
  border: '1px solid rgba(36,145,222,1)',
  backgroundColor: 'transparent',
};

const customToggleSelected = {
  paddingBottom: 10,
  backgroundColor: 'rgba(36,145,222,1)',
  border: '1px solid rgba(36,145,222,1)',
};

const checkTabletWidth = screen.width >= 768 && screen.width <= 820;

const errorPropEvaluator = (error, output) => {
  return error && output;
};

interface EditablePersonalInfoProps {
  errors: any;
  bodyTypes: any;
  height: any;
  totalChildren: any;
  smoking: any;
  drinking: any;
  education: any;
  occupation: any;
  income: any;
  lookingMale: any;
  lookingFemale: any;
  onChangeHandler: any;
  mapFields: any;
  title: any;
  className: any;
  handleCancel: any;
  handleSubmit: any;
  onChangeBodyType: any;
  onCheckboxChangeHandler: any;
  onToggleChildren: any;
  isGenerous: any;
  gender: string;
}

const EditablePersonalInfo = ({
  errors,
  bodyTypes,
  height,
  totalChildren,
  smoking,
  drinking,
  education,
  occupation,
  income,
  lookingMale,
  lookingFemale,
  onChangeHandler,
  mapFields,
  title,
  className,
  handleCancel,
  handleSubmit,
  onChangeBodyType,
  onCheckboxChangeHandler,
  onToggleChildren,
  isGenerous,
  gender,
}: EditablePersonalInfoProps): JSX.Element => {
  const [lookingError, setLookingError] = useState(null);
  const [heightError, setHeightError] = useState(null);
  const [bodyTypeError, setBodyTypeError] = useState(null);
  const [childError, setChildError] = useState(null);
  const [smokingError, setSmokingError] = useState(null);
  const [drinkingError, setDrinkingError] = useState(null);
  const [educationError, setEducationError] = useState(null);
  const [occupationError, setOccupationError] = useState(null);
  const [incomeError, setIncomeError] = useState(null);

  const filteredBodyType = useFilteredBodyType(gender);

  const renderEditField = (field, disabled = true) => {
    return Object.keys(field).map((key, index) => {
      return (
        <option key={index} value={key} disabled={key === '0' && disabled}>
          {field[key]}
        </option>
      );
    });
  };

  const handleDisableOptions = () => {
    return bodyTypes.length > 0;
  };

  useEffect(() => {
    const bodyTypeRef = document.getElementById('bodyTypeRef');
    const bodyTypesSelectInput = document.getElementsByClassName('select__input')[0];
    if (bodyTypeRef) bodyTypeRef.style.color = '#000000';
    if (bodyTypesSelectInput) bodyTypesSelectInput.style.color = inputTheme.colorInput;
    if (checkTabletWidth && bodyTypeRef) bodyTypeRef.style.width = '735px';
  }, []);

  const setCustomStyle = item => {
    return item ? customToggleSelected : customToggle;
  };

  const setCustomLabelStyle = item => {
    return item ? { ...toggleBtnLabelStyle, color: '#FFFFFF' } : toggleBtnLabelStyle;
  };

  const handleErrorMessage = (field: string): string[] => {
    return [`The ${field} field is required`];
  };

  const submitHandler = e => {
    setLookingError(lookingMale === lookingFemale ? handleErrorMessage('interested') : null);
    setHeightError(height === null ? handleErrorMessage('height') : null);
    setBodyTypeError(_isEmpty(bodyTypes) ? handleErrorMessage('body type') : null);

    setChildError(totalChildren === 0 ? handleErrorMessage('children') : null);
    setSmokingError(smoking === 0 ? handleErrorMessage('smoking') : null);
    setDrinkingError(drinking === 0 ? handleErrorMessage('drinking') : null);
    setEducationError(education === 0 ? handleErrorMessage('education') : null);
    isGenerous &&
      setOccupationError(occupation === null || occupation === '' ? handleErrorMessage('occupation') : null);
    isGenerous && setIncomeError(income === 0 ? handleErrorMessage('income') : null);

    handleSubmit(e);
  };

  return (
    <Panel
      data-test-id="editable-about-info"
      withHeader
      title={title}
      className={className}
      cancelButton={
        <CancelButton
          dataTestId="editable-textarea-cancel-btn"
          handleCancel={handleCancel}
          customStyle={{ marginRight: 0 }}
        />
      }
      panelStyle={isDarkMode() ? 'borderless' : 'white'}
    >
      <CheckboxGroup
        inputLabel="Interested in"
        status={errorPropEvaluator(errors.looking_male || lookingError, 'error')}
        statusMessage={errors.looking_male || lookingError}
        customStyle={
          errors.looking_male || lookingError ? { paddingBottom: 34, color: '#CC5453' } : { paddingBottom: 10 }
        }
        customLabelStyle={{ fontWeight: 400, fontSize: 16 }}
        isRequired
        noStatusIcon
      >
        <ToggleButton
          customStyle={setCustomStyle(lookingMale)}
          labelStyle={setCustomLabelStyle(lookingMale)}
          dataTestId="looking_male"
          hideIcons
          title="Men"
          id="looking_male"
          isChecked={lookingMale}
          onChangeHandler={() => onCheckboxChangeHandler('looking_male')}
        />
        <ToggleButton
          customStyle={setCustomStyle(lookingFemale)}
          labelStyle={setCustomLabelStyle(lookingFemale)}
          dataTestId="looking_female"
          hideIcons
          title="Women"
          id="looking_female"
          isChecked={lookingFemale}
          onChangeHandler={() => onCheckboxChangeHandler('looking_female')}
        />
      </CheckboxGroup>
      <CheckboxGroup
        inputLabel="Body Type (Choose one)"
        status={errorPropEvaluator(errors.body_type || bodyTypeError, 'error')}
        statusMessage={errors.body_type || bodyTypeError}
        customStyle={
          errors.body_type || bodyTypeError ? { paddingBottom: 48, color: '#CC5453' } : { paddingBottom: 10 }
        }
        customLabelStyle={{ fontWeight: 400, fontSize: 16 }}
        isRequired
        noStatusIcon
      >
        {filteredBodyType.map((item, index) => (
          <ToggleButton
            customStyle={{ width: '38%' }}
            dataTestId={item.value}
            key={index}
            title={item.value === 5 ? 'A Few Extra' : item.label}
            id={item.value}
            isChecked={_find(bodyTypes, item)}
            isDisabled={handleDisableOptions() && !_find(bodyTypes, item)}
            onChangeHandler={
              handleDisableOptions() && !_find(bodyTypes, item) ? () => null : () => onChangeBodyType(item)
            }
          />
        ))}
      </CheckboxGroup>
      <Select
        value={height || '0'}
        id="height"
        inputLabel="Height"
        onChange={onChangeHandler}
        error={errors.height || heightError}
        status={errorPropEvaluator(errors.height || heightError, 'error')}
        statusMessage={heightError || (errors.height && errors.height[0])}
        style={{ width: '100%', maxWidth: '711px' }}
        customStyleLabel={{ paddingBottom: 6 }}
        isRequired
        noStatusIcon
      >
        <option value={'0'} disabled>
          —
        </option>
        <option value={`< 5'0" (<152cm)`}>{`< 5'0" (<152cm)`}</option>
        <option value={`5'0" (152cm)`}>{`5'0" (152cm)`}</option>
        <option value={`5'1" (155cm)`}>{`5'1" (155cm)`}</option>
        <option value={`5'2" (157cm)`}>{`5'2" (157cm)`}</option>
        <option value={`5'3" (160cm)`}>{`5'3" (160cm)`}</option>
        <option value={`5'4" (163cm)`}>{`5'4" (163cm)`}</option>
        <option value={`5'5" (165cm)`}>{`5'5" (165cm)`}</option>
        <option value={`5'6" (168cm)`}>{`5'6" (168cm)`}</option>
        <option value={`5'7" (170cm)`}>{`5'7" (170cm)`}</option>
        <option value={`5'8" (173cm)`}>{`5'8" (173cm)`}</option>
        <option value={`5'9" (175cm)`}>{`5'9" (175cm)`}</option>
        <option value={`5'10" (178cm)`}>{`5'10" (178cm)`}</option>
        <option value={`5'11" (180cm)`}>{`5'11" (180cm)`}</option>
        <option value={`6'0" (183cm)`}>{`6'0" (183cm)`}</option>
        <option value={`6'1" (185cm)`}>{`6'1" (185cm)`}</option>
        <option value={`6'2" (188cm)`}>{`6'2" (188cm)`}</option>
        <option value={`6'3" (191cm)`}>{`6'3" (191cm)`}</option>
        <option value={`6'4" (193cm)`}>{`6'4" (193cm)`}</option>
        <option value={`6'5" (196cm)`}>{`6'5" (196cm)`}</option>
        <option value={`6'6" (198cm)`}>{`6'6" (198cm)`}</option>
        <option value={`6'7" (201cm)`}>{`6'7" (201cm)`}</option>
        <option value={`6'8" (203cm)`}>{`6'8" (203cm)`}</option>
        <option value={`6'9" (206cm)`}>{`6'9" (206cm)`}</option>
        <option value={`6'10" (208cm)`}>{`6'10" (208cm)`}</option>
        <option value={`6'11" (211cm)`}>{`6'11" (211cm)`}</option>
        <option value={`7'0" (214cm)`}>{`7'0" (214cm)`}</option>
        <option value={`> 7'0" (> 214cm)`}>{`> 7'0" (> 214cm)`}</option>
      </Select>
      <div style={{ marginBottom: 10 }}></div>
      <CheckboxGroup
        inputLabel="Children"
        status={errorPropEvaluator(errors.children || childError, 'error')}
        statusMessage={errors.children || childError}
        customStyle={childError ? { paddingBottom: 34 } : { paddingBottom: 10 }}
        customLabelStyle={{ fontWeight: 400, fontSize: 16 }}
        isRequired
        noStatusIcon
      >
        {Object.keys(mapFields.children).map((key, index) => {
          const isSelected = key === totalChildren.toString();
          return (
            index !== 0 && (
              <ToggleButton
                customStyle={setCustomStyle(isSelected)}
                labelStyle={setCustomLabelStyle(isSelected)}
                radioBehaviour
                dataTestId={mapFields.children[key]}
                hideIcons
                key={index}
                title={mapFields.children[key]}
                id={mapFields.children[key]}
                isChecked={isSelected}
                onChangeHandler={() => onToggleChildren('children', key)}
              />
            )
          );
        })}
      </CheckboxGroup>
      <CheckboxGroup
        inputLabel="Smoking"
        status={errorPropEvaluator(errors.smoking || smokingError, 'error')}
        statusMessage={errors.smoking || smokingError}
        customStyle={smokingError ? { paddingBottom: 34 } : { paddingBottom: 10 }}
        customLabelStyle={{ fontWeight: 400, fontSize: 16 }}
        isRequired
        noStatusIcon
      >
        {Object.keys(mapFields.smoking).map((key, index) => {
          const isSelected = key === smoking.toString();
          return (
            index !== 0 && (
              <ToggleButton
                customStyle={setCustomStyle(isSelected)}
                labelStyle={setCustomLabelStyle(isSelected)}
                radioBehaviour
                dataTestId={mapFields.smoking[key]}
                hideIcons
                key={index}
                title={mapFields.smoking[key]}
                id={mapFields.smoking[key]}
                isChecked={isSelected}
                onChangeHandler={() => onToggleChildren('smoking', key)}
              />
            )
          );
        })}
      </CheckboxGroup>
      <CheckboxGroup
        inputLabel="Drinking"
        status={errorPropEvaluator(errors.drinking || drinkingError, 'error')}
        statusMessage={errors.drinking || drinkingError}
        customStyle={drinkingError ? { paddingBottom: 34 } : { paddingBottom: 10 }}
        customLabelStyle={{ fontWeight: 400, fontSize: 16 }}
        isRequired
        noStatusIcon
      >
        {Object.keys(mapFields.drinking).map((key, index) => {
          const isSelected = key === drinking.toString();
          return (
            index !== 0 && (
              <ToggleButton
                customStyle={setCustomStyle(isSelected)}
                labelStyle={setCustomLabelStyle(isSelected)}
                radioBehaviour
                dataTestId={mapFields.drinking[key]}
                hideIcons
                key={index}
                title={mapFields.drinking[key]}
                id={mapFields.drinking[key]}
                isChecked={isSelected}
                onChangeHandler={() => onToggleChildren('drinking', key)}
              />
            )
          );
        })}
      </CheckboxGroup>
      <Select
        value={education || '0'}
        id="education"
        inputLabel="Education"
        onChange={onChangeHandler}
        status={errorPropEvaluator(errors.education || educationError, 'error')}
        statusMessage={errors.education || educationError}
        style={{ width: '100%', maxWidth: '711px' }}
        baseSelectStyle={{ width: '100%', maxWidth: '711px', paddingBottom: educationError ? 34 : 24 }}
        customStyleLabel={{ paddingBottom: 6 }}
        isRequired
        noStatusIcon
      >
        <option value={'0'} disabled>
          —
        </option>
        <option value={'High School'}>High school</option>
        <option value={'Some College'}>Some college</option>
        <option value={'Associates Degree'}>Associate degree</option>
        <option value={"Bachelor's Degree"}>Bachelor&apos;s degree</option>
        <option value={'Graduate Degree'}>Graduate degree</option>
        <option value={'PhD / Post Doctoral'}>Post-doctoral degree</option>
      </Select>
      <TextInput
        id="occupation"
        inputLabel="Occupation"
        name="occupation"
        value={occupation || ''}
        onChange={onChangeHandler}
        status={errorPropEvaluator(errors.occupation || occupationError, 'error')}
        statusMessage={errors.occupation || occupationError}
        customStyle={{ width: '100%', maxWidth: '711px', paddingBottom: occupationError ? 34 : 24 }}
        customInputStyle={{ fontSize: '14px !important' }}
        hideStatusIcon
        isRequired={isGenerous}
        customStyleLabel={{ paddingBottom: 6 }}
      />

      {isGenerous && (
        <Select
          value={income}
          id="income"
          inputLabel="Income"
          onChange={onChangeHandler}
          status={errorPropEvaluator(errors.income || incomeError, 'error')}
          statusMessage={errors.income || incomeError}
          style={{ width: '100%', maxWidth: '711px' }}
          baseSelectStyle={{ width: '100%', maxWidth: '711px', paddingBottom: educationError ? 34 : 24 }}
          customStyleLabel={{ paddingBottom: 6 }}
          isRequired={true}
          noStatusIcon
        >
          {!isNaN(income)
            ? renderEditField(mapFields.income, false)
            : [
                <option key={0}>Prefer Not To Say</option>,
                <option key={1}>Less than US$20,000</option>,
                <option key={2}>US$20,001 - $30,000</option>,
                <option key={3}>US$30,001 - $40,000</option>,
                <option key={4}>US$40,001 - $50,000</option>,
                <option key={5}>US$50,001 - $60,000</option>,
                <option key={6}>US$60,001 - $70,000</option>,
                <option key={7}>US$70,001 - $80,000</option>,
                <option key={8}>US$80,001 - $100,000</option>,
                <option key={9}>US$100,001 - $150,000</option>,
                <option key={10}>US$150,001 - $200,000</option>,
                <option key={11}>US$200,001 - $300,000</option>,
                <option key={12}>US$300,001 - $400,000</option>,
                <option key={13}>US$400,001 - $500,000</option>,
                <option key={14}>US$500,001 - $1 million</option>,
                <option key={15}>More than $1 million</option>,
              ]}
        </Select>
      )}

      <ButtonContainer>
        <Button onClick={handleCancel} customStyle={{ paddingRight: 30, paddingLeft: 30, marginRight: 10 }}>
          Cancel
        </Button>
        <Button
          data-test-id="save-about-info"
          customStyle={{ paddingRight: 30, paddingLeft: 30 }}
          buttonType="error"
          onClick={e => submitHandler(e)}
        >
          Save
        </Button>
      </ButtonContainer>
    </Panel>
  );
};

export default EditablePersonalInfo;
