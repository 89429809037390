import React, { ReactElement } from 'react';
interface IStepOneIconProps {
  customStyle?: any;
}

const StepOneIcon = ({ customStyle }: IStepOneIconProps): ReactElement => {
  return (
    <svg width="29" style={customStyle} height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.5" cy="14.0995" r="13" fill="white" stroke="#2B8FD7" strokeWidth="2" />
      <path
        d="M17.3531 8.00858V21.0995H14.5853V10.6357H14.5086L11.5107 12.515V10.0604L14.7515 8.00858H17.3531Z"
        fill="#2B8FD7"
      />
    </svg>
  );
};

export default StepOneIcon;
