import React from 'react';
import './SpinnerStyle.css';

interface ISpinnerProps {
  width?: any;
}

const Spinner: React.FC<ISpinnerProps> = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
