import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from '../components/App';
import { endVerificationSession, generateVerificationUrl, resendEmail } from '../actions/authActions';
import { clearNotificationMessage } from '../actions/notificationActions';
import {
  getProfileSettings,
  getProfileMapFields,
  refreshAccount,
  getIceServers,
  refreshProfile as refreshUserProfile,
  resetNuxGuide,
  updateAccountConfig,
} from '../actions/profileActions';
import { storeCurrPage } from '../actions/commonActions';
import { getMemberType, updatePageTitle } from '../common';
import ErrorContainer from './ErrorContainer';
import NotificationAlerts from '../components/blocks/NotificationAlerts';
import { ConnectPusher } from '../contexts/PusherContext';
import { register, unregister } from '../utils/serviceWorker';
import { clearSearch, resetFilters } from '../actions/searchActions';
import { checkPwaTrue, getBrowserDetails, getCookie, loadGoogleMaps, setCookie } from '../utils/helpers';
import { storeMeta } from '../actions/metaActions';
import { initiateFavPending } from '../actions/favActions';
import { getGlobalNotifications, clearGlobalNotifications } from '../actions/globalNotificationActions';
import { refreshOffers } from '../actions/offerActions';
import { setVWOObj } from '../utils/vwo';
import Loader from '../atoms/LoaderStyled';
import { ThemeProvider } from '@emotion/react';
import { WYPTheme } from 'swui';
import { storeEvidently } from '../actions/globalActions';
import {
  getRecurringPackages,
  getRecurringPayment,
  subscribeRecurringPayment,
  unSubscribeRecurringPayment,
} from '../actions/paymentSettingsActions';
import { deleteSavedToken } from '../actions/billingActions';
import { IS_ACCOUNT_INSTALLED_PWA_IN_IOS } from '../models/AccountSettings';
import { AccountMaker } from '../models/Account';
import { AM_SET_DEFAULT_OFFER_FEATURE_NAME } from '../utils/evidentlyFeatures/AMSetDefaultOffer';
import { ALLOW_THEME_COOKIE_NAME } from '../config/constants';

const AppContainer = props => {
  const { account, profile, path, updateAccountConfigProp, settings } = props;
  const location = useLocation();
  const params = useParams();

  const isBillingPage = (): boolean => {
    return location.pathname === '/packages' || location.pathname === '/payment';
  };

  const isProfilePage = (): boolean => {
    return location.pathname.includes('/profile') && !!params?.hashId;
  };

  useEffect(() => {
    const darkmodeConfig = account && account.account_config?.account_system_config?.darkmode;

    if (darkmodeConfig) {
      const deviceDarkmode = getBrowserDetails().isMobile ? darkmodeConfig.mobile : darkmodeConfig.desktop;

      if (typeof deviceDarkmode !== 'undefined' && typeof getCookie(ALLOW_THEME_COOKIE_NAME) === 'undefined') {
        appleDarkmodeTheme(deviceDarkmode);
      }
    }

    //check if app is installed in ios standalone mode
    if (
      account &&
      account?.hash_id &&
      !account.account_config.is_account_installed_pwa_in_ios &&
      getBrowserDetails().isStandaloneMode &&
      getBrowserDetails().isIos
    ) {
      updateAccountConfigProp({ key: IS_ACCOUNT_INSTALLED_PWA_IN_IOS, state: true });
    }
  }, []);

  const appleDarkmodeTheme = useCallback(deviceDarkmode => {
    setCookie(ALLOW_THEME_COOKIE_NAME, deviceDarkmode, true);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, []);

  useEffect(() => {
    const logoutOnTokenInvalidation = e => {
      if (e && e.oldValue && !e.newValue && e.key === 'state') {
        window.location.href = '/logout';
      }
    };

    window.addEventListener('storage', logoutOnTokenInvalidation);

    return () => {
      window.removeEventListener('storage', logoutOnTokenInvalidation);
    };
  }, []);

  useEffect(() => {
    registerServiceWorker();
    const { mapProfileFields, setProfileSettings, refreshGlobalNotification, getIceSettings } = props;

    initFSTracking();
    mapProfileFields();
    setProfileSettings();
    refreshGlobalNotification();
    getIceSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadGoogleMaps();
    updatePageTitle(account.unread_messages);
    Sentry.withScope(scope => {
      scope.setUser({
        id: account.hash_id,
        username: account.username,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account && settings) {
      const accountModel = AccountMaker.create(account);

      // It should wait for the AMSetDefaultOffers experiment to be loaded for AM users
      // to make sure VWOobj.evidently property is set correctly
      const isRequiredAMEvidentlyLoaded = accountModel.getProfile().isGenerous()
        ? true
        : settings?.evidently?.[AM_SET_DEFAULT_OFFER_FEATURE_NAME] !== undefined;

      if (isRequiredAMEvidentlyLoaded) {
        setVWOObj(AccountMaker.create(account), settings);
      }
    }
  }, [account, settings?.evidently]);

  useEffect(() => {
    props.storeCurrentUrl();
    if (getBrowserDetails().isMobile && (isBillingPage() || isProfilePage())) {
      document.documentElement.classList.add('disable-pull');
    } else {
      document.documentElement.classList.remove('disable-pull');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, location]);

  useEffect(() => {
    if (!window?.google || !window?.google?.accounts) return;

    const oneTapGoogleElement = document.getElementById('credential_picker_container');

    if (oneTapGoogleElement) {
      oneTapGoogleElement.remove();
      window.google.accounts.id.cancel();
    }
  }, []);

  const registerServiceWorker = useCallback(() => {
    checkPwaTrue() ? register() : unregister();
  }, []);

  const initFSTracking = useCallback(() => {
    if (!('FS' in window)) {
      return console.error('Full story script is not enabled on this page!');
    }

    if (!account || !account.hash_id || !profile || !profile.data) {
      return false;
    }

    window.FS.identify(account.hash_id);
    window.FS.setUserVars({
      displayName: account.username,
      member_type_int: getMemberType(profile.data),
      submitted_at_bool: Boolean(profile.data.submitted_at),
      completed_at_bool: Boolean(profile.data.completed_at),
      monetized_at_bool: Boolean(profile.data.monetized_at),
    });
  }, []);

  return (
    <ThemeProvider theme={WYPTheme}>
      <ErrorContainer>
        {props.tokenRefreshing ? (
          <Loader active={true} background={'#fff'} topPosition={20} style={{ width: '100%', height: 100 }} />
        ) : (
          <App onboarding={props.onboardingState} {...props} />
        )}
        <NotificationAlerts
          getOfferHighlights={props.getOfferHighlights}
          isOnCall={props.account.onCallStatus}
          auth={props.auth}
        />
      </ErrorContainer>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    alerts: state.alerts,
    auth: state.auth,
    tokenRefreshing: state.auth.tokenRefreshing,
    account: state.profile,
    profile: state.profile.profile,
    isFetching: state.common.isFetching,
    visitMessages: state.common.visitMessages,
    notification: state.notification,
    globalNotification: state.global_notification,
    settings: state.settings,
    lastSearchTime: state.meta.search_last_time,
    onboardingState: {
      profile: state.profile,
      emailConfirmed: state.profile.email_confirmed,
      profileApproved: state.profile.is_approved,
      profileFinished: state.profile.profile && state.profile.profile.data.required_fields_completed,
      photoFinished: state.profile.photo_complete,
      photoApproved: Boolean(
        state.profile.photo && state.profile.photo.data[0] && state.profile.photo.data[0].status === 'approved'
      ),
    },
    uploadSnackbar: state.uploadSnackbar,
  };
};

// actions to refresh internal/external profile.
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resendEmail() {
      return dispatch(resendEmail());
    },
    mapProfileFields() {
      // get profile data.
      dispatch(getProfileMapFields());
    },
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    loadPageUrl(linkUrl) {
      dispatch(clearNotificationMessage());
      ownProps.navigate(linkUrl);
    },
    setProfileSettings: () => {
      dispatch(getProfileSettings());
    },
    getIceSettings: () => {
      dispatch(getIceServers());
    },
    clearSearch: () => {
      dispatch(resetFilters());
      dispatch(clearSearch());
      dispatch(refreshAccount());
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
    removeLastSearchMeta: () => {
      dispatch(
        storeMeta({
          search_last_time: null,
          search_max_limit: false,
        })
      );
    },
    initiatePendingFav: hashId => {
      // run pending Favorite api calls
      dispatch(initiateFavPending(hashId));
    },
    getOfferHighlights: () => {
      dispatch(refreshOffers('highlights', 'recent', true, 1));
    },
    generateUrl: () => {
      return dispatch(generateVerificationUrl());
    },
    finishVerification: formData => {
      return dispatch(endVerificationSession(formData));
    },
    refreshProfile: () => {
      return dispatch(refreshUserProfile());
    },
    storeCurrentUrl() {
      dispatch(storeCurrPage());
    },
    resetNux: () => {
      dispatch(resetNuxGuide());
    },
    storeEvidently: payload => {
      dispatch(storeEvidently(payload));
    },
    getRecurringPackages: order => {
      return dispatch(getRecurringPackages(order));
    },
    subscribeRecurringPayment: payload => {
      return dispatch(subscribeRecurringPayment(payload));
    },
    unSubscribeRecurringPayment: () => {
      return dispatch(unSubscribeRecurringPayment());
    },
    getRecurringPayment: () => {
      return dispatch(getRecurringPayment());
    },
    removeSavedToken: (tokenId: number, withLoading = false) => {
      return dispatch(deleteSavedToken(tokenId, withLoading));
    },
    updateAccountConfigProp: payload => {
      dispatch(updateAccountConfig(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPusher(AppContainer));
