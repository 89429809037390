import React, { Fragment, ReactElement, useState } from 'react';
import Button from '../../../atoms/buttons/Button';
import PhoneNumberInput from '../../../atoms/PhoneNumberInput';
import EditPhoneIcon from '../../../atoms/icons/EditPhoneIcon';
import usePhoneActionModal from '../../../hooks/usePhoneActionModal';
import {
  FormWrapper,
  DashlineSeparator,
  ButtonAction,
  Heading1,
  ErrorMessage,
  StyledEditablePhoneInput,
} from './styledElements';
import { useApp } from '../../../contexts/AppContext';
import useGeolocationData from '../../../hooks/useGeolocationData';
import { tomtomEnabled } from '../../../config/Master';
import { handlePhoneSettingsVysionEvent } from '../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PhoneSettingsClickVysionEventsEnum } from '../../../utils/vysion/phoneSettings/click';
import { PhoneSettingsOnFocusVysionEventsEnum } from '../../../utils/vysion/phoneSettings/onFocus';
import { PhoneSettingsViewVysionEventsEnum } from '../../../utils/vysion/phoneSettings/view';
import { TwilioVerbiage } from '../TwilioVerbiage';

interface IEditablePhoneInputProps {
  allowChange?: boolean;
  profileCountry?: string;
  nextStep?: (value: string) => void;
  displayOnly?: boolean;
  currentPhone: string;
}

const EditablePhoneInput = ({
  allowChange = false,
  profileCountry,
  nextStep,
  displayOnly,
  currentPhone,
}: IEditablePhoneInputProps): ReactElement => {
  const [newPhoneValue, setNewPhoneValue] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    utils: { requestResendOtp },
  } = usePhoneActionModal();
  const { hasRequestOtpError, setHasRequestOtpError } = useApp();
  const { countryCode } = useGeolocationData(tomtomEnabled);

  const handleSubmitPhone = () => {
    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_UPDATE,
    });

    //validate if phone is existing already
    if (currentPhone === newPhoneValue) {
      setHasRequestOtpError('You cannot use the same number as your phone number.');
      return false;
    }

    if (typeof nextStep === 'function') {
      setIsProcessing(true);
      requestResendOtp({
        isLogin: false,
        phoneNumber: newPhoneValue,
        showSuccessModal: false,
        onResendSuccess: () => {
          nextStep(newPhoneValue);
          handlePhoneSettingsVysionEvent({
            action: VYSION_ACTIONS.VIEW,
            event: PhoneSettingsViewVysionEventsEnum.VIEW_ACCOUNT_MENU_UPDATED_NUMBER_VERIFICATION,
          });
        },
        onResendCompleted: () => setIsProcessing(false),
      });
    }
  };

  return (
    <FormWrapper data-test-id="edit_phone_settings_container">
      {displayOnly ? (
        <Fragment>
          <DashlineSeparator style={{ marginTop: '0px', marginBottom: '20px' }} />
          <StyledEditablePhoneInput>
            <PhoneNumberInput
              defaultCountry={'us'}
              label="Current Phone Number"
              customLabelStyle={{ fontWeight: 600 }}
              autoFocus
              value={currentPhone}
              disabled={true}
              customStyle={{ width: '100%' }}
            />
            <EditPhoneIcon customStyle={{ cursor: 'pointer', width: 40, height: 40, marginTop: 28, marginLeft: 5 }} />
          </StyledEditablePhoneInput>

          <DashlineSeparator />
          <TwilioVerbiage profileCountry={profileCountry} />
          <DashlineSeparator />
        </Fragment>
      ) : (
        <Fragment>
          <DashlineSeparator style={{ marginTop: '0px', marginBottom: '20px' }} />
          <StyledEditablePhoneInput>
            <PhoneNumberInput
              dataTestId={'text_edit-phone'}
              defaultCountry={'us'}
              label="Current Phone Number"
              customLabelStyle={{ fontWeight: 600 }}
              autoFocus
              value={currentPhone}
              disabled={true}
              customStyle={{ width: '100%' }}
            />
            <EditPhoneIcon
              onClick={state => {
                if (!allowChange && typeof nextStep === 'function') {
                  nextStep(state);
                  handlePhoneSettingsVysionEvent({
                    action: VYSION_ACTIONS.CLICK,
                    event: PhoneSettingsClickVysionEventsEnum.CLICK_ACCOUNT_EDIT_CHANGE_PHONE_NUMBER,
                  });
                }
              }}
              isDisabled={allowChange}
              customStyle={{ cursor: 'pointer', width: 40, height: 40, marginTop: 28, marginLeft: 5 }}
            />
          </StyledEditablePhoneInput>

          <DashlineSeparator />
          <TwilioVerbiage profileCountry={profileCountry} />
          <DashlineSeparator />
          {allowChange && (
            <div>
              <Heading1>Change phone number</Heading1>
              <PhoneNumberInput
                defaultCountry={countryCode ?? 'us'}
                label="Enter new phone"
                value={newPhoneValue}
                autoFocus
                onChange={value => setNewPhoneValue(value)}
                onFocus={() => {
                  setHasRequestOtpError('');
                  handlePhoneSettingsVysionEvent({
                    action: VYSION_ACTIONS.ON_FOCUS,
                    event: PhoneSettingsOnFocusVysionEventsEnum.ENTER_CHANGE_PHONE_NUMBER,
                  });
                }}
                handleOnChangeCountry={country => {
                  handlePhoneSettingsVysionEvent({
                    action: VYSION_ACTIONS.CLICK,
                    event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY,
                    extraInfo: country.name,
                    newEventName: `accountMenu-changePhoneNumber-countryCode-${country.countryCode}`,
                  });
                }}
                handleClickCountryDropdown={showDropdown => {
                  if (showDropdown) {
                    handlePhoneSettingsVysionEvent({
                      action: VYSION_ACTIONS.CLICK,
                      event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY_CODE,
                    });
                  }
                }}
                customLabelStyle={{ fontStyle: 'italic' }}
              />
              {hasRequestOtpError && <ErrorMessage>{hasRequestOtpError}</ErrorMessage>}
            </div>
          )}
          <ButtonAction>
            <Button
              data-test-id="phone-settings-button"
              customStyle={{}}
              buttonType="primary"
              onClick={handleSubmitPhone}
              disabled={!allowChange || isProcessing}
              type={'button'}
              size="small"
            >
              {isProcessing ? 'Updating...' : 'Update'}
            </Button>
          </ButtonAction>
        </Fragment>
      )}
    </FormWrapper>
  );
};

export default EditablePhoneInput;
