/**
 * @Author: zachalam
 * @Date:   2017-01-25T11:42:03-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-25T11:42:03-08:00
 */

import React, { useEffect, useCallback } from 'react';

import { connect } from 'react-redux';
import { clearEverything } from '../actions/commonActions';
import { getLocalItem, updatePageTitle } from '../common';
import { unsubscribeWebPush, killAuthToken } from '../sdk';
import { isEmpty, removeCookie, resetMixpanel } from '../utils/helpers';
import styled from '@emotion/styled';
import LoadingBackdrop from '../atoms/loader/LoadingBackdrop';
import { clearLocalStorageExcept } from '../localStorage';
import {
  PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
  PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT,
  PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
} from '../utils/pwa';
import { removeIPQSCookies } from '../utils/ipQualityScore';
import { ALLOW_THEME_COOKIE_NAME } from '../config/constants';

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  position: 'relative',
});

const LogoutContainer = props => {
  const clearAll = useCallback(async () => {
    // just redirect user to login where store is cleared.

    try {
      await killAuthToken();
      props.handleClearEverything();
      localStorage.removeItem('state');

      // Adds query string
      const redirect = getLocalItem('redirectTo');
      const keysToRetain = [
        PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
        PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
        PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT,
      ];
      clearLocalStorageExcept(keysToRetain);
      removeCookie('rgstore_user');
      removeCookie(ALLOW_THEME_COOKIE_NAME);
      resetMixpanel();
      removeIPQSCookies();

      if (window?.google?.accounts?.id) {
        window.google.accounts.id.disableAutoSelect();
      }

      if (!isEmpty(redirect)) {
        window.location.href = redirect;
      }

      window.location.href = '/login?logged_out=true';
    } catch (e) {
      console.log('error killAuthToken', e);
    }
  }, [props.handleClearEverything]);

  const removeSubscription = useCallback(async () => {
    const currentSubscription = getLocalItem('pushSubscription');
    if (currentSubscription) {
      await unsubscribeWebPush(currentSubscription)
        .then(() => {
          clearAll();
        })
        .catch(err => {
          console.log('error unsubscribe', err);
          clearAll();
        });
    }
    clearAll();
  }, [clearAll]);

  useEffect(() => {
    removeSubscription();
  }, [removeSubscription]);

  return (
    <LoadingContainer>
      <LoadingBackdrop active={true} showLoadingBg={false} />
    </LoadingContainer>
  );
};

const mapStateToProps = state => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleClearEverything: () => {
      dispatch(clearEverything());
      updatePageTitle();
      removeCookie('rgstore_user');
      resetMixpanel();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
