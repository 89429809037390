import React, { useMemo } from 'react';
import { textColor } from '../../../../../style';
import styled from '@emotion/styled';
import PriceInput from '../../../Offers/PriceInput';
import colors from '../../../../../theme/colors';
import { isDarkMode } from '../../../../../utils/helpers';
import useEvidently from '../../../../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../../../../constants/evidently';
import { CurrencySymbols } from '../../DashboardDiscoverMode';

const PriceContainer = styled('div')(
  {
    marginLeft: 12,
    marginRight: 12,
    textAlign: 'center',
    width: 185,
    height: 55,
    borderRadius: 6,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16) inset',
    backgroundColor: isDarkMode() ? '#041A2A' : '#FFF',
    border: isDarkMode() ? '1px solid #6C6C6C' : '1px solid #DAE1E9',
  },

  ({ hasErrors }) =>
    hasErrors && {
      border: '1px solid #cc5454',
    }
);

const setColor = {
  default: {
    color: 'grey',
  },
  brand: {
    color: '#3e79a5',
  },
  alert: { ...textColor.RED },
};
const CurrencyTip = styled('div')(
  {
    display: 'inline-flex',
    paddingLeft: 10,
  },
  ({ offerPrice }) => ({
    ...setColor[offerPrice],
    ':before': {
      position: 'absolute',
      top: 14,
      right: '85%',
      fontSize: 16,
    },
  })
);

const CurrencyLabelSpan = styled('span')({
  fontSize: 42,
  fontWeight: 600,
  color: isDarkMode() ? colors['dark'].blue.secondary : colors['light'].blue.tertiary,
  letterSpacing: '-1.68px',
  lineHeight: '42px',
  position: 'relative',
  top: 7,
});

const InitialInputProps = {
  style: {
    fontSize: 42,
    height: 55,
    lineHeight: '42px',
    fontWeight: 600,
    outline: 'none',
    border: 'none',
    marginLeft: -17,
    color: isDarkMode() ? colors['dark'].blue.secondary : colors['light'].blue.tertiary,
    letterSpacing: '-1.68px',
    fontFamily: 'system-ui, -apple-system, Arial, sans-serif',
  },
};

const adjustStylePerDigit = number => {
  const numString = number ? number.toString() : '0';
  let marginLeft = -17;
  let paddingLeft = 10;
  switch (numString.length) {
    case 3:
      marginLeft = -10;
      paddingLeft = 10;
      break;
    case 2:
      marginLeft = -22;
      paddingLeft = 20;
      break;
    case 1:
      marginLeft = -32;
      paddingLeft = 30;
      break;
    default:
      marginLeft = -32;
      paddingLeft = 10;
      break;
  }
  return {
    marginLeft,
    paddingLeft,
  };
};

interface Props {
  offerPrice?: number | null;
  updateOfferPrice: (offerPrice: string | number, hasError: boolean) => void;
  hasError: boolean;
  currencySymbols: CurrencySymbols;
}

const DashboardDiscoverModeOfferActionInput: React.FC<Props> = ({
  offerPrice,
  updateOfferPrice,
  hasError,
  currencySymbols,
}) => {
  const { useActiveVariation } = useEvidently();

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const minValue = defaultMinOffer;
  const maxValue = '500';

  const validateTextInput = e => {
    const Key = e.key;
    const regex = /[0-9]/;
    if (!regex.test(Key)) {
      if (e.preventDefault) e.preventDefault();
    }
  };

  const inputOnChange = e => {
    const inputValue = Number(e.target.value);
    const MaxLength = maxValue.toString().length;

    if (e.target.value.length <= MaxLength) {
      const isInvalid = Boolean(Number(inputValue) < Number(minValue) || Number(inputValue) > Number(maxValue));
      updateOfferPrice(inputValue, isInvalid);
    } else {
      e.preventDefault();
    }
  };

  const colorType = (price, hasErrors) => {
    switch (price) {
      case true:
        return 'default';
      case false:
        return !hasErrors ? 'brand' : 'alert';
      default:
        return;
    }
  };

  return (
    <PriceContainer hasErrors={hasError}>
      <CurrencyTip
        offerPrice={colorType(offerPrice, hasError)}
        style={{
          paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
        }}
      >
        <CurrencyLabelSpan>
          {currencySymbols.currencyInitial}
          {currencySymbols.currencyLabel}
        </CurrencyLabelSpan>
        <PriceInput
          data-test-id="dashboard-discovermode-offer-price-input"
          hasErrors={hasError}
          value={offerPrice}
          onChange={inputOnChange}
          validateTextFn={validateTextInput}
          inputProps={{
            style: {
              ...InitialInputProps.style,
              marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
            },
          }}
          minWidth={100}
        />
      </CurrencyTip>
    </PriceContainer>
  );
};

export default DashboardDiscoverModeOfferActionInput;
