import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Avatar from '../Avatar';
import ButtonGroup from '../buttons/ButtonGroup';
import { tsSince } from '../../common';
import { textColor, type, textType, dimensions, borders, color, backgroundTheme } from '../../style';
import { Link, useNavigate } from 'react-router-dom';
import { HighlightFavoriteButton } from '../buttons/FavoriteButton';
import { generalIcons } from '../icons/materialIcons';
import { getBrowserDetails, ternaryFunc } from '../../utils/helpers';
import { ignoreOffer, counterOffer, acceptOffer, cancelOffer } from '../../actions/offerTrackAction';
import { triggerTracking } from '../../utils/helpers';

const ItemContainer = styled('li')(
  {
    display: 'block',
    listStyle: 'none',
    borderBottom: borders.DEFAULT,
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  ({ isOfferHighlight }) => ({
    borderBottom: isOfferHighlight ? 'none' : borders.DEFAULT,
  })
);

const Item = styled('div')(
  {
    display: 'flex',
    [dimensions.SCREEN_MAX_XS]: {
      display: 'none',
    },
  },
  ({ isOfferHighlight }) => ({
    padding: isOfferHighlight ? 0 : 16,
  })
);

const DetailsActionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
});

const OfferDetails = styled('div')(
  {
    color: textColor.DARK.color,
  },
  ({ isOfferHighlight }) => ({
    padding: isOfferHighlight ? 10 : 0,
    [dimensions.SCREEN_MAX_XS]: {
      padding: 10,
    },
  })
);

const Username = styled('span')({
  color: textColor.DARKER.color,
  fontWeight: 600,
});

const OfferDescription = styled('span')({
  color: textColor.DARKER.color,
});

//keep price separated

const OfferLabel = styled('div')(
  {
    fontSize: '14px',
    color: textColor.DARKEST.color,
    [dimensions.SCREEN_MAX_XS]: {
      color: '#FFFFFF',
    },
  },
  ({ isOfferHighlight }) =>
    isOfferHighlight && {
      color: '#3b4859',
      ...textType.header,
    }
);

const OfferPriceDesktop = styled('div')({
  textAlign: 'center',
  display: 'block',

  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const OfferPriceMobile = styled('div')({
  textAlign: 'right',
  display: 'none',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'block',
  },
});

const OfferPrice = styled('div')({
  textAlign: 'right',
});

const PriceLabel = styled('div')(
  {
    paddingTop: '2px',
    verticalAlign: 'top',

    display: 'inline-block',
    fontSize: '14pxß',
  },
  ({ isOfferHighlight }) =>
    isOfferHighlight && {
      lineHeight: '30px',
      color: '#1383d2',
      fontWeight: 600,
      ...type.XL,
    }
);

const Price = styled('div')(
  {
    fontSize: '32px',
    lineHeight: '32px',

    display: 'inline-block',
  },
  ({ isOfferHighlight }) =>
    isOfferHighlight && {
      color: '#1383d2',
      fontSize: type.XXXL.fontSize,
      fontWeight: 600,
      lineHeight: type.XXXL.fontSize,
    }
);

// OFFER CARD

const ItemContainerMobile = styled('li')({
  listStyle: 'none',
});

const Card = styled('div')(({ isOfferHighlight }) => ({
  display: 'none',
  background: backgroundTheme.block,
  marginBottom: '22px',
  borderBottom: borders.DEFAULT,
  [dimensions.SCREEN_MAX_XS]: {
    display: isOfferHighlight ? 'none' : 'block',
    marginBottom: isOfferHighlight ? 0 : 12,
  },
}));

const CardTop = styled('div')({
  position: 'relative',
});

const CardOfferPrice = styled(OfferPriceMobile)({
  position: 'absolute',
  bottom: '20px',
  right: '12px',
  color: 'white',
  zIndex: 2,
});

const Gradient = styled('div')({
  background:
    'rgba(0, 0, 0, 0) linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0) 30%) repeat scroll 0% 0%',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1,
});

const CardBottom = styled('div')({
  padding: getBrowserDetails().isMobile ? 0 : 16,
});

const StyledSwipeContainer = styled('div')(
  {
    display: 'none !important',
    height: 0,
    [dimensions.SCREEN_MAX_SM]: {
      display: 'block !important',
    },
  },
  ({ highlightMobile }) =>
    highlightMobile && {
      height: '100%',
    }
);

const HighlightsButtonGroup = styled('div')({
  textAlign: 'center',
  padding: '14px 0',
  border: `solid 1px ${color.BRAND_1}`,
  boxShadow: `0 0 1px 0 ${color.BRAND_1}`,
  borderRadius: 10,
  background: backgroundTheme.block,
  [dimensions.SCREEN_MAX_XS]: {
    border: 'none',
    boxShadow: 'none',
  },
});

const OfferIcons = styled('div')(
  {
    display: 'inline-flex',
    marginTop: 10,
  },
  ({ isOfferHighlight }) =>
    isOfferHighlight && {
      svg: {
        width: '64px !important',
        height: '64px !important',
      },
    }
);

const ButtonGroupContainer = styled('div')({
  padding: 0,
  [dimensions.SCREEN_MAX_XS]: {
    textAlign: 'center',
    padding: '8px 10px 16px 10px',
  },
});

const formatPrice = (currency, price, offerLabel = 'Offer', isOfferHighlight = false) => {
  if (!currency || !price) {
    return 'No amount suggested.';
  }

  return (
    <React.Fragment>
      <OfferLabel isOfferHighlight={isOfferHighlight}>{offerLabel}</OfferLabel>
      <PriceLabel isOfferHighlight={isOfferHighlight}>
        {currency.initial}
        {String.fromCharCode(currency.label)}
      </PriceLabel>
      <Price isOfferHighlight={isOfferHighlight}>{price}</Price>
    </React.Fragment>
  );
};

const renderOfferIcon = () => <generalIcons.offers.genOffer />;

const renderRequestIcon = () => <generalIcons.offers.request />;

const setTriggerOfferAction = (e, offerStatus) => {
  switch (offerStatus) {
    case 'ignore':
      ignoreOffer(e);
      break;
    case 'counter':
      counterOffer(e);
      break;
    case 'accept':
      acceptOffer(e);
      break;
    case 'cancel':
      cancelOffer(e);
      break;
    default:
      return;
  }
};

const mapOfferRefId = (offerTypes, index) => {
  switch (offerTypes) {
    case 'offer_new':
      return [
        `triggerIgnoreOfferHighlight${index}`,
        `triggerCounterOfferHighlight${index}`,
        `triggerAcceptOfferHighlight${index}`,
      ];
    case 'suggestion_new':
      return [`triggerIgnoreOfferHighlight${index}`, `triggerCounterRequestHighlight${index}`];
    case 'offer_pending':
      return `triggerCancelOfferHighlight${index}`;
    case 'suggestion_pending':
      return `triggerCancelSuggestHighlight${index}`;
    default:
      return;
  }
};

const mapOfferStatus = offerTypes => {
  switch (offerTypes) {
    case 'offer_new':
      return ['ignore', 'counter', 'accept'];
    case 'suggestion_new':
      return ['ignore', 'counter'];
    case 'offer_pending':
      return 'cancel';
    case 'suggestion_pending':
      return 'cancel';
    default:
      return;
  }
};

interface OfferListItemProps {
  index: any;
  avatar: any;
  currency: any;
  price: any;
  username: any;
  date: any;
  age: any;
  location: any;
  accountHashId: any;
  acceptOffer?: any;
  ignoreOffer?: any;
  cancelOffer?: any;
  cancelSuggestion: any;
  offerType: any;
  counterOfferDisabled: any;
  currentUserIsGenerous: any;
  isOfferHighlight: any;
  toggleFav: any;
  isFavorite: any;
  counterOffer: any;
  checkProfile: any;
  getNextOfferQueue: any;
  counterSuggestOffer: any;
  isFetching: any;
}

const OfferListItem = ({
  index,
  avatar,
  currency,
  price,
  username,
  date,
  age,
  location,
  accountHashId,
  acceptOffer,
  ignoreOffer,
  cancelOffer,
  cancelSuggestion,
  offerType,
  counterOfferDisabled,
  currentUserIsGenerous,
  isOfferHighlight,
  toggleFav,
  isFavorite,
  counterOffer,
  checkProfile,
  getNextOfferQueue,
  counterSuggestOffer,
  isFetching,
}: OfferListItemProps): any => {
  const [favorited, setFavorited] = useState(isFavorite);
  const [hasOfferAction, setHasOfferAction] = useState(false);
  const [clickCounterAction, setClickCounterAction] = useState(false);
  const [clickProfileAction, setClickProfileAction] = useState(false);
  const [clickSuggestAction, setClickSuggestAction] = useState(false);
  const [hasSwipeAction, setHasSwipeAction] = useState(false);

  const swipeRef = useRef();
  const inputAccept = useRef();
  const inputSuggest = useRef();
  const isMobileTablet = getBrowserDetails().isTablet || getBrowserDetails().isIpad;
  const highlightMobile = !isMobileTablet && isOfferHighlight;
  const showOfferDetails = getBrowserDetails().isTablet || getBrowserDetails().isIpad || getBrowserDetails().isDesktop;
  const ignoreOfferButton = useRef(null);
  const counterOfferButton = useRef(null);
  const cancelOfferButton = useRef(null);
  const acceptOfferButton = useRef(null);

  const navigate = useNavigate();

  const setOfferRef = (id, status) => {
    switch (status) {
      case 'ignore':
        ignoreOfferButton.current = document.getElementById(id);
        return ignoreOfferButton;
      case 'counter':
        counterOfferButton.current = document.getElementById(id);
        return counterOfferButton;
      case 'cancel':
        cancelOfferButton.current = document.getElementById(id);
        return cancelOfferButton;
      case 'accept':
        acceptOfferButton.current = document.getElementById(id);
        return acceptOfferButton;
      default:
        return;
    }
  };

  useEffect(() => {
    if ((clickProfileAction || clickSuggestAction || clickCounterAction) && typeof checkProfile === 'function') {
      checkProfile(accountHashId).then(response => {
        if (typeof response === 'undefined') {
          getNextOfferQueue();
          return;
        } else if (clickCounterAction) {
          counterOffer();
          return;
        } else if (clickSuggestAction) {
          counterSuggestOffer();
          return;
        }

        if (!hasOfferAction) {
          navigate(`/profile/${accountHashId}`);
        }
      });
    }

    setHasOfferAction(false);
    setHasSwipeAction(false);
    setClickCounterAction(false);
    setClickSuggestAction(false);
    setClickProfileAction(false);
  }, [
    hasSwipeAction,
    hasOfferAction,
    clickCounterAction,
    clickProfileAction,
    offerType,
    accountHashId,
    checkProfile,
    clickSuggestAction,
    counterOffer,
    counterSuggestOffer,
    getNextOfferQueue,
  ]);

  useEffect(() => {
    const currOfferTypes = mapOfferRefId(offerType, index);
    const offerStatus = mapOfferStatus(offerType);
    if (typeof currOfferTypes === 'object') {
      currOfferTypes.forEach((property, i) => {
        triggerTracking(offerStatus[i], setOfferRef(property, offerStatus[i]), setTriggerOfferAction);
      });
    } else if (typeof currOfferTypes === 'string') {
      triggerTracking(offerStatus, setOfferRef(currOfferTypes, offerStatus), setTriggerOfferAction);
    }
  }, [index, offerType]);

  const handleIgnoreOffer = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    swipeRef.current && swipeRef.current.swipeTo('left');
    ignoreOffer();
  };

  const handleCancelOffer = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    cancelOffer();
  };

  const handleCancelSuggestion = e => {
    e.preventDefault();
    if (isFetching) return;

    cancelSuggestion();
  };

  const handleCounter = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    setClickCounterAction(true);
  };

  const handleAcceptOffer = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    swipeRef.current && swipeRef.current.swipeTo('right');
    acceptOffer(accountHashId);
  };

  const handleSuggestOffer = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    swipeRef.current && swipeRef.current.swipeTo('right');
    setClickSuggestAction(true);
  };

  const handleFavUser = e => {
    e.preventDefault();
    if (isFetching) return;

    setHasOfferAction(true);
    setFavorited(!favorited);
    toggleFav(accountHashId);
  };

  const renderActionButtons = () => {
    switch (offerType) {
      case 'offer_new':
        return (
          <React.Fragment>
            <OfferIcons
              id={`triggerIgnoreOfferHighlight${index}`}
              data-test-id="trigger-ignore-offer-highlight"
              onClick={handleIgnoreOffer}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.ignore.highlight />
            </OfferIcons>
            {isOfferHighlight ? (
              <OfferIcons
                id={`triggerCounterOfferHighlight${index}`}
                data-test-id="trigger-counter-offer-highlight"
                disabled={counterOfferDisabled}
                onClick={handleCounter}
                isOfferHighlight={isOfferHighlight}
              >
                <generalIcons.offers.highlight />
              </OfferIcons>
            ) : (
              <OfferIcons
                id={`triggerCounterOfferHighlight${index}`}
                data-test-id="trigger-counter-offer-highlight"
                isOfferHighlight={isOfferHighlight}
              >
                <Link to={!counterOfferDisabled && `/profile/${accountHashId}?fromOfferList=true&isCounter=true`}>
                  <generalIcons.offers.highlight />
                </Link>
              </OfferIcons>
            )}
            <OfferIcons
              id={`triggerAcceptOfferHighlight${index}`}
              data-test-id="trigger-accept-offer-highlight"
              onClick={handleAcceptOffer}
              isOfferHighlight={isOfferHighlight}
              ref={inputAccept}
            >
              <generalIcons.accept.highlight />
            </OfferIcons>
          </React.Fragment>
        );
      case 'suggestion_new':
        return (
          <React.Fragment>
            <OfferIcons
              id={`triggerIgnoreOfferHighlight${index}`}
              data-test-id="trigger-ignore-offer-highlight"
              onClick={handleIgnoreOffer}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.ignore.highlight />
            </OfferIcons>
            {isOfferHighlight ? (
              <OfferIcons
                id={`triggerCounterRequestHighlight${index}`}
                data-test-id="trigger-counter-request-highlight"
                onClick={handleSuggestOffer}
                isOfferHighlight={isOfferHighlight}
                ref={inputSuggest}
              >
                <generalIcons.suggest.highlight />
              </OfferIcons>
            ) : (
              <OfferIcons
                id={`triggerCounterRequestHighlight${index}`}
                data-test-id="trigger-counter-request-highlight"
                isOfferHighlight={isOfferHighlight}
              >
                <Link to={`/profile/${accountHashId}?fromOfferList=true`}>
                  {ternaryFunc(currentUserIsGenerous, renderOfferIcon(), renderRequestIcon())}
                </Link>
              </OfferIcons>
            )}
          </React.Fragment>
        );
      case 'offer_pending':
        return (
          <React.Fragment>
            <OfferIcons
              id={`triggerCancelOfferHighlight${index}`}
              data-test-id="trigger-cancel-offer-highlight"
              onClick={handleCancelOffer}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.ignore.cancel />
            </OfferIcons>
          </React.Fragment>
        );
      case 'suggestion_pending':
        return (
          <React.Fragment>
            <OfferIcons
              id={`triggerCancelSuggestHighlight${index}`}
              data-test-id="trigger-cancel-suggest-highlight"
              onClick={handleCancelSuggestion}
              isOfferHighlight={isOfferHighlight}
            >
              <generalIcons.ignore.cancel />
            </OfferIcons>
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  const getOfferDescription = () => {
    switch (offerType) {
      case 'suggestion_new':
        return ' wants to go on a date!';
      case 'suggestion_pending':
        return ' is considering a price';
      case 'offer_pending':
        return ' is considering your offer';

      default:
        return null;
    }
  };

  const offerHighlightDetails = () => {
    const offerDescription = getOfferDescription();
    return (
      <DetailsActionsContainer className="css-offer-profile-details">
        <OfferDetails data-test-id="offer-highlight-info" isOfferHighlight={isOfferHighlight}>
          <HighlightFavoriteButton style={{ float: 'right' }} favorited={favorited} onClick={e => handleFavUser(e)} />
          <Username data-test-id="offer-highlight-username">{username}</Username>{' '}
          <OfferDescription data-test-id="offer-highlight-desc">{offerDescription}</OfferDescription> &bull;{' '}
          {tsSince(date)}
          <br />
          {age} &bull; {location}
        </OfferDetails>
      </DetailsActionsContainer>
    );
  };

  const renderOfferDetails = () => {
    const offerDescription = getOfferDescription();
    if (isOfferHighlight) {
      return (
        <DetailsActionsContainer className="css-offer-profile-details">
          {(showOfferDetails || !highlightMobile) && (
            <OfferDetails data-test-id="offer-highlight-info" isOfferHighlight={isOfferHighlight}>
              <HighlightFavoriteButton
                style={{ float: 'right' }}
                favorited={favorited}
                onClick={e => handleFavUser(e)}
              />
              <Username data-test-id="offer-highlight-username">{username}</Username>{' '}
              <OfferDescription data-test-id="offer-highlight-desc">{offerDescription}</OfferDescription> &bull;{' '}
              {tsSince(date)}
              <br />
              {age} &bull; {location}
            </OfferDetails>
          )}
          <HighlightsButtonGroup>
            <ButtonGroup>
              <OfferPriceDesktop className="css-offer-profile-price-container-desktop">
                <div className="css-offer-profile-price">
                  {formatPrice(currency, price, 'First Date Incentive Offer', isOfferHighlight)}{' '}
                </div>
              </OfferPriceDesktop>
              {renderActionButtons()}
            </ButtonGroup>
          </HighlightsButtonGroup>
        </DetailsActionsContainer>
      );
    }

    return (
      <DetailsActionsContainer className="css-offer-profile-details">
        <OfferDetails>
          <Username>{username}</Username> <OfferDescription>{offerDescription}</OfferDescription> &bull; {tsSince(date)}
          <br />
          {age} &bull; {location}
        </OfferDetails>
        <ButtonGroupContainer>
          <ButtonGroup>{renderActionButtons()}</ButtonGroup>
        </ButtonGroupContainer>
      </DetailsActionsContainer>
    );
  };

  return (
    <React.Fragment>
      <ItemContainer isOfferHighlight={isOfferHighlight} className="css-offer-profile-list-item-desktop">
        <Link
          className="css-offer-profile-list-item-link-desktop"
          onClick={() => {
            setClickProfileAction(true);
          }}
          to="#"
        >
          <Item isOfferHighlight={isOfferHighlight}>
            <Avatar
              className="css-offer-profile-avatar-desktop"
              src={avatar}
              customStyle={{ marginRight: isOfferHighlight ? 10 : 16 }}
              width={isOfferHighlight ? 240 : 96}
            />
            {showOfferDetails && renderOfferDetails()}
            {!isOfferHighlight && (
              <OfferPrice className="css-offer-profile-price-container-desktop">
                <div className="css-offer-profile-price">{formatPrice(currency, price)} </div>
              </OfferPrice>
            )}
            <OfferPriceMobile className="css-offer-profile-price-container-desktop">
              <div className="css-offer-profile-price">{formatPrice(currency, price)} </div>
            </OfferPriceMobile>
          </Item>
        </Link>
      </ItemContainer>

      <ItemContainerMobile data-test-id="offer-highlight-profile-link" className="css-offer-profile-list-item-mobile">
        <Link className="css-offer-profile-list-item-link-mobile" to={`/profile/${accountHashId}`}>
          <StyledSwipeContainer highlightMobile={highlightMobile}>
            {highlightMobile && !hasSwipeAction && (
              <div>
                <CardTop>
                  <CardOfferPrice className="css-offer-profile-price-container-mobile">
                    <div className="css-offer-profile-price" data-test-id="offer-highlights-price">
                      {formatPrice(currency, price)}{' '}
                    </div>
                  </CardOfferPrice>
                  <Gradient />
                  <Avatar
                    data-test-id="offer-highlight-avatar"
                    className="css-offer-profile-avatar-mobile"
                    src={avatar}
                    width={'100%'}
                  />
                </CardTop>
                <CardBottom>{offerHighlightDetails()}</CardBottom>
                <CardBottom>{renderOfferDetails()}</CardBottom>
              </div>
            )}
          </StyledSwipeContainer>
          <Card isOfferHighlight={isOfferHighlight}>
            {!isOfferHighlight && (
              <CardTop>
                <CardOfferPrice className="css-offer-profile-price-container-mobile">
                  <div className="css-offer-profile-price" data-test-id="offer-highlights-price">
                    {formatPrice(currency, price)}{' '}
                  </div>
                </CardOfferPrice>
                <Gradient />
                <Avatar
                  data-test-id="offer-highlight-avatar"
                  className="css-offer-profile-avatar-mobile"
                  src={avatar}
                  width={'100%'}
                />
              </CardTop>
            )}
            {!highlightMobile && <CardBottom>{renderOfferDetails()}</CardBottom>}
          </Card>
        </Link>
      </ItemContainerMobile>
    </React.Fragment>
  );
};

OfferListItem.defaultProps = {
  counterOfferDisabled: false,
};

OfferListItem.propTypes = {
  offerType: PropTypes.oneOf([
    'offer_new',
    'suggestion_new',
    'offer_pending',
    'suggestion_pending',
    'offer_rejected',
    'suggestion_rejected',
  ]),
  accountHashId: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  currentUserIsGenerous: PropTypes.bool,
  date: PropTypes.string,
  age: PropTypes.number,
  location: PropTypes.string,
  currency: PropTypes.shape({
    initial: PropTypes.string,
    label: PropTypes.string,
  }),
  price: PropTypes.number,
  ignoreOffer: PropTypes.func,
  acceptOffer: PropTypes.func,
  counterOffer: PropTypes.func,
  counterOfferDisabled: PropTypes.bool,
  cancelOffer: PropTypes.func.isRequired,
  cancelSuggestion: PropTypes.func.isRequired,
};

export default OfferListItem;
