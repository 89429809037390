import React, { PureComponent, ReactElement } from 'react';
import ReactSelect from 'react-select';
import styled from '@emotion/styled';
import _isEmpty from 'lodash/isEmpty';
import { Header } from '../../atoms/text';
import Container from '../../components/blocks/Container';
import Panel from '../../atoms/panels/Panel';
import InputGroup from '../../components/blocks/InputGroup';
import { Select } from '../../atoms/Select';
import TextInput from '../../atoms/TextInput';
import TextArea from '../../atoms/TextArea';
import CheckboxGroup from '../../atoms/CheckboxGroup';
import Checkbox from '../../atoms/Checkbox';
import Button from '../../atoms/buttons/Button';
import Autocomplete from '../../atoms/AutocompleteMain';
import UsernameInput from '../../atoms/UsernameInput';
import { type, statusColor } from '../../style';
import InputLabel from '../../atoms/InputLabel';
import ToggleButton from '../../atoms/togglebutton/ToggleButton';
import { getBrowserDetails } from '../../utils/helpers';
import { BODY_TYPE_OPTIONS } from '../../components/join/constants';

const TitleSpan = styled('span')({
  ...type.title.XS,
});

const ToggleButtonsContainer = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: getBrowserDetails().isMobile ? '1fr 1fr' : '1fr 1fr 1fr',
  gridTemplateRows: getBrowserDetails().isMobile ? '34% 34% 34%' : '52% 52%',
  paddingBottom: 10,
});

const OnBoardingPanel = styled(Panel)({
  backgroundColor: '#FFF',
  border: '1px solid rgb(218, 225, 233)',
  borderRadius: 6,
  minHeight: 300,
});

const AutocompleteContainer = styled('div')({
  maxWidth: '100%',
});

const TextAreaContainer = styled('div')({
  maxWidth: '480px',
});

const bodyTypeDropdownStyle = error => ({
  control: styles => ({
    ...styles,
    backgroundColor: '#fff',
    border: error ? `1px solid ${statusColor.error}` : '1px solid rgba(0,0,0,0.15)',
    ':hover': {
      border: error ? statusColor.error : '1px solid rgba(0,0,0,0.15)',
      cursor: 'pointer',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? '#999' : '#000',
    };
  },
});

const HiddenElements = styled('div')({
  display: 'none',
});

const OnBoardingText = styled('p')({
  backgroundColor: '#EAF4FB',
  borderRadius: 3,
  padding: 16,
  textAlign: 'center',
  fontSize: 15,
});

class GettingStarted extends PureComponent {
  renderEditField = (field: string, disabled = true): any => {
    return Object.keys(field).map((key, index) => {
      return (
        <option key={index} value={key} disabled={key === '0' && disabled}>
          {field[key]}
        </option>
      );
    });
  };

  handleDisableOptions = (): boolean => {
    const { bodyTypes } = this.props;

    return bodyTypes.length > 1;
  };

  /**
   * Cleans up city,region to avoid duplicates
   *
   * @returns {string}
   */

  render(): ReactElement {
    const {
      onSubmit,
      lookingMale,
      lookingFemale,
      address,
      username,
      bodyTypes,
      height,
      datingJustDating,
      datingFriendship,
      datingMarriage,
      datingMarried,
      datingAdult,
      datingMentorship,
      education,
      totalChildren,
      mapFields,
      smoking,
      drinking,
      occupation,
      income,
      description,
      seeking,
      onCheckboxChangeHandler,
      onChangeAddress,
      onSelectLocation,
      onChangeBodyType,
      onChangeHandler,
      isGenerous,
      country,
      isProcessing,
    } = this.props;

    const locationInputProps = {
      value: address,
      id: 'autocompleteLocation',
      placeholder: 'Enter a Location',
      type: 'text',
      onChange: onChangeAddress,
      status: this.props.errors.city && 'error',
      statusMessage: this.props.errors.city,
    };

    const checkRequiredFields = () => {
      return _isEmpty(address) || seeking.length < 20 || _isEmpty(country);
    };

    const renderHiddenElements = () => {
      return (
        <HiddenElements>
          <CheckboxGroup
            inputLabel="Interested in"
            status={this.props.errors.looking_male && 'error'}
            statusMessage={this.props.errors.looking_male}
            eleRef={r => {
              this._looking_male = r;
            }}
          >
            <Checkbox
              inline={true}
              value={lookingMale}
              id="looking_male"
              onChange={onCheckboxChangeHandler}
              label="Men"
              hasError={this.props.errors.looking_male}
            />
            <Checkbox
              inline={true}
              value={lookingFemale}
              id="looking_female"
              onChange={onCheckboxChangeHandler}
              label="Women"
              hasError={this.props.errors.looking_male}
            />
          </CheckboxGroup>
          <UsernameInput
            inputLabel="Display Name"
            error={this.props.errors.username}
            status={this.props.errors.username && 'error'}
            value={username}
            onChange={onChangeHandler}
            isLogin
          />
          <InputGroup
            vertical
            width="SM"
            inputLabel="Body Type"
            rightText={'(Choose up to two)'}
            errors={_isEmpty(bodyTypes) && this.props.errors.body_type}
            eleRef={r => {
              this._body_type = r;
            }}
          >
            <ReactSelect
              defaultValue={bodyTypes}
              closeMenuOnSelect={false}
              isOptionDisabled={this.handleDisableOptions}
              isMulti
              read
              onChange={onChangeBodyType}
              name="colors"
              options={BODY_TYPE_OPTIONS}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={bodyTypeDropdownStyle(_isEmpty(bodyTypes) && this.props.errors.body_type)}
            />
          </InputGroup>
          <Select
            value={height || '0'}
            id="height"
            inputLabel="Height"
            onChange={onChangeHandler}
            error={this.props.errors.height}
            status={this.props.errors.height && 'error'}
            statusMessage={this.props.errors.height}
            eleRef={r => {
              this._height = r;
            }}
            style={{ maxWidth: '452px' }}
          >
            <option value={'0'} disabled>
              —
            </option>
            <option value={`< 5'0" (<152cm)`}>{`< 5'0" (<152cm)`}</option>
            <option value={`5'0" (152cm)`}>{`5'0" (152cm)`}</option>
            <option value={`5'1" (155cm)`}>{`5'1" (155cm)`}</option>
            <option value={`5'2" (157cm)`}>{`5'2" (157cm)`}</option>
            <option value={`5'3" (160cm)`}>{`5'3" (160cm)`}</option>
            <option value={`5'4" (163cm)`}>{`5'4" (163cm)`}</option>
            <option value={`5'5" (165cm)`}>{`5'5" (165cm)`}</option>
            <option value={`5'6" (168cm)`}>{`5'6" (168cm)`}</option>
            <option value={`5'7" (170cm)`}>{`5'7" (170cm)`}</option>
            <option value={`5'8" (173cm)`}>{`5'8" (173cm)`}</option>
            <option value={`5'9" (175cm)`}>{`5'9" (175cm)`}</option>
            <option value={`5'10" (178cm)`}>{`5'10" (178cm)`}</option>
            <option value={`5'11" (180cm)`}>{`5'11" (180cm)`}</option>
            <option value={`6'0" (183cm)`}>{`6'0" (183cm)`}</option>
            <option value={`6'1" (185cm)`}>{`6'1" (185cm)`}</option>
            <option value={`6'2" (188cm)`}>{`6'2" (188cm)`}</option>
            <option value={`6'3" (191cm)`}>{`6'3" (191cm)`}</option>
            <option value={`6'4" (193cm)`}>{`6'4" (193cm)`}</option>
            <option value={`6'5" (196cm)`}>{`6'5" (196cm)`}</option>
            <option value={`6'6" (198cm)`}>{`6'6" (198cm)`}</option>
            <option value={`6'7" (201cm)`}>{`6'7" (201cm)`}</option>
            <option value={`6'8" (203cm)`}>{`6'8" (203cm)`}</option>
            <option value={`6'9" (206cm)`}>{`6'9" (206cm)`}</option>
            <option value={`6'10" (208cm)`}>{`6'10" (208cm)`}</option>
            <option value={`6'11" (211cm)`}>{`6'11" (211cm)`}</option>
            <option value={`7'0" (214cm)`}>{`7'0" (214cm)`}</option>
            <option value={`> 7'0" (> 214cm)`}>{`> 7'0" (> 214cm)`}</option>
          </Select>
          <Select
            value={education || '0'}
            id="education"
            inputLabel="Education"
            onChange={onChangeHandler}
            status={this.props.errors.education && 'error'}
            statusMessage={this.props.errors.education}
            eleRef={r => {
              this._education = r;
            }}
            style={{ maxWidth: '452px' }}
          >
            <option value={'0'} disabled>
              —
            </option>
            <option value={'High School'}>High school</option>
            <option value={'Some College'}>Some college</option>
            <option value={'Associates Degree'}>Associate degree</option>
            <option value={"Bachelor's Degree"}>Bachelor&apos;s degree</option>
            <option value={'Graduate Degree'}>Graduate degree</option>
            <option value={'PhD / Post Doctoral'}>Post-doctoral degree</option>
          </Select>
          <Select
            value={totalChildren || '0'}
            id="children"
            inputLabel="Children"
            onChange={onChangeHandler}
            error={this.props.errors.children}
            status={this.props.errors.children && 'error'}
            statusMessage={this.props.errors.children}
            eleRef={r => {
              this._children = r;
            }}
            style={{ maxWidth: '452px' }}
          >
            {!isNaN(totalChildren)
              ? this.renderEditField(mapFields.children)
              : [
                  <option key={0} value={'0'} disabled>
                    —
                  </option>,
                  <option key={1} value={'No Children'}>
                    No children
                  </option>,
                  <option key={2} value={'1 Child'}>
                    1 child
                  </option>,
                  <option key={3} value={'2 Children'}>
                    2 children
                  </option>,
                  <option key={4} value={'3 Children'}>
                    3 children
                  </option>,
                  <option key={5} value={'> 3 Children'}>
                    4 or more children
                  </option>,
                ]}
          </Select>
          <Select
            value={smoking || '0'}
            id="smoking"
            inputLabel="Smoking"
            onChange={onChangeHandler}
            status={this.props.errors.smoking && 'error'}
            statusMessage={this.props.errors.smoking}
            eleRef={r => {
              this._smoking = r;
            }}
            style={{ maxWidth: '452px' }}
          >
            {!isNaN(smoking)
              ? this.renderEditField(mapFields.smoking)
              : [
                  <option key={0} value={'0'} disabled>
                    —
                  </option>,
                  <option key={1} value={'Non-Smoker'}>
                    Not a smoker
                  </option>,
                  <option key={2} value={'Light Smoker'}>
                    Light smoker
                  </option>,
                  <option key={3} value={'Heavy Smoker'}>
                    Heavy smoker
                  </option>,
                ]}
          </Select>
          <Select
            value={drinking || '0'}
            id="drinking"
            inputLabel="Drinking"
            onChange={onChangeHandler}
            status={this.props.errors.drinking && 'error'}
            statusMessage={this.props.errors.drinking}
            eleRef={r => {
              this._drinking = r;
            }}
            style={{ maxWidth: '452px' }}
          >
            {!isNaN(drinking)
              ? this.renderEditField(mapFields.drinking)
              : [
                  <option key={0} value={'0'} disabled>
                    —
                  </option>,
                  <option key={1} value={'Non-Drinker'}>
                    Not a drinker
                  </option>,
                  <option key={2} value={'Social Drinker'}>
                    Social drinker
                  </option>,
                  <option key={3} value={'Heavy Drinker'}>
                    Heavy drinker
                  </option>,
                ]}
          </Select>
          <TextInput
            id="occupation"
            eleRef={r => {
              this._occupation = r;
            }}
            inputLabel="Occupation"
            name="occupation"
            value={occupation || ''}
            onChange={onChangeHandler}
            status={this.props.errors.occupation && 'error'}
            statusMessage={this.props.errors.occupation}
            customStyle={{ maxWidth: '452px' }}
          />
          <Select
            value={income}
            id="income"
            inputLabel="Income"
            onChange={onChangeHandler}
            status={this.props.errors.income && 'error'}
            statusMessage={this.props.errors.income}
            style={{ maxWidth: '452px' }}
          >
            {!isNaN(income)
              ? this.renderEditField(mapFields.income, false)
              : [
                  <option key={0}>Prefer Not To Say</option>,
                  <option key={1}>Less than US$20,000</option>,
                  <option key={2}>US$20,001 - $30,000</option>,
                  <option key={3}>US$30,001 - $40,000</option>,
                  <option key={4}>US$40,001 - $50,000</option>,
                  <option key={5}>US$50,001 - $60,000</option>,
                  <option key={6}>US$60,001 - $70,000</option>,
                  <option key={7}>US$70,001 - $80,000</option>,
                  <option key={8}>US$80,001 - $100,000</option>,
                  <option key={9}>US$100,001 - $150,000</option>,
                  <option key={10}>US$150,001 - $200,000</option>,
                  <option key={11}>US$200,001 - $300,000</option>,
                  <option key={12}>US$300,001 - $400,000</option>,
                  <option key={13}>US$400,001 - $500,000</option>,
                  <option key={14}>US$500,001 - $1 million</option>,
                  <option key={15}>More than $1 million</option>,
                ]}
          </Select>
          <Header bottomRuled>
            <TitleSpan> Tell us about yourself </TitleSpan>
          </Header>
          <TextAreaContainer>
            <TextArea
              id="description"
              inputLabel="Describe Yourself"
              value={description || ''}
              onChange={onChangeHandler}
              rows={10}
              resize="vertical"
              status={this.props.errors.description && 'error'}
              statusMessage={this.props.errors.description}
              eleRef={r => {
                this._description = r;
              }}
              showCounter={true}
              minCount={20}
              customInputStyle={{ fontSize: '16px' }}
            />
            <TextArea
              id="seeking"
              inputLabel="Your ideal first date"
              value={seeking || ''}
              onChange={onChangeHandler}
              rows={10}
              resize="vertical"
              status={this.props.errors.seeking && 'error'}
              statusMessage={this.props.errors.seeking}
              eleRef={r => {
                this._seeking = r;
              }}
              showCounter={true}
              minCount={20}
              customInputStyle={{ fontSize: '16px' }}
            />
          </TextAreaContainer>
        </HiddenElements>
      );
    };

    return (
      <form id="profile-getting-started" onSubmit={onSubmit}>
        <Container fullWidth style={{ maxWidth: 500 }}>
          <OnBoardingPanel withHeader isRowMobile>
            {renderHiddenElements()}
            <OnBoardingText>
              Fill in these last final details{isGenerous ? ' and get 50 free credits' : ''}
            </OnBoardingText>
            <AutocompleteContainer>
              <InputLabel
                eleRef={r => {
                  this._city = r;
                }}
                label="Location"
              />
              <Autocomplete
                inputProps={locationInputProps}
                onSelect={onSelectLocation}
                error={this.props.errors.city}
                status={this.props.errors.city && 'error'}
                statusMessage={this.props.errors.city}
                customInputStyle={{ color: 'rgba(0, 0, 0, 0.8)' }}
                customStyle={{ marginRight: 0 }}
                onBoarding
                isEditProfile
              />
            </AutocompleteContainer>
            <CheckboxGroup
              vertical
              width="SM"
              inputLabel="Interests"
              errors={this.props.errors.dating_just_dating}
              customStyle={{ maxWidth: '480px' }}
              eleRef={r => {
                this._dating_just_dating = r;
              }}
            >
              <ToggleButtonsContainer>
                <ToggleButton
                  title="Dating"
                  id="dating_just_dating"
                  isChecked={datingJustDating}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_just_dating')}
                />
                <ToggleButton
                  title="Friendship"
                  id="dating_friendship"
                  isChecked={datingFriendship}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_friendship')}
                />
                <ToggleButton
                  title="Long-term"
                  id="dating_marriage"
                  isChecked={datingMarriage}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_marriage')}
                />
                <ToggleButton
                  title="Discreet affair"
                  id="dating_married"
                  isChecked={datingMarried}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_married')}
                />
                <ToggleButton
                  title="Casual"
                  id="dating_adult"
                  isChecked={datingAdult}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_adult')}
                />
                <ToggleButton
                  title="Mentorship"
                  id="dating_mentorship"
                  isChecked={datingMentorship}
                  onChangeHandler={() => onCheckboxChangeHandler('dating_mentorship')}
                />
              </ToggleButtonsContainer>
            </CheckboxGroup>
            <TextArea
              id="seeking"
              data-test-id="getting-started-seeking"
              inputLabel="My ideal first date"
              value={seeking || ''}
              onChange={onChangeHandler}
              rows={4}
              resize="vertical"
              status={this.props.errors.seeking && 'error'}
              statusMessage={this.props.errors.seeking}
              eleRef={r => {
                this._seeking = r;
              }}
              showCounter={true}
              minCount={20}
              customInputStyle={{ fontSize: 15, borderColor: 'rgba(0, 0, 0, 0.15) !important', padding: 10 }}
            />
            <div style={{ textAlign: 'center' }}>
              <Button
                customStyle={{ margin: '0 auto' }}
                buttonType="primary"
                disabled={isProcessing || checkRequiredFields()}
                id="saveProfile"
              >
                Save My Profile
              </Button>
            </div>
          </OnBoardingPanel>
        </Container>
      </form>
    );
  }
}

export default GettingStarted;
