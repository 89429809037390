import * as Sentry from '@sentry/react';

export default class DataLayer {
  /**
   * Get data from local storage
   *
   * @returns {object}
   */
  getData(): Record<string, unknown> {
    return JSON.parse(window.localStorage.getItem('wypDatalayer')) || [];
  }

  /**
   * Clear data from local storage
   *
   * @returns {void}
   */
  clearData(): void {
    window.localStorage.removeItem('wypDataLayer');
  }

  /**
   * Push data object to GA DataLayer
   *
   * @param {object} data
   */
  toDataLayer(data: any): Record<string, unknown> {
    if (!('dataLayer' in window)) {
      return console.error('Datalayer does not exist!');
    }

    window.dataLayer.push(data);
  }

  /**
   * Push data object to Sentry
   *
   * @param {object} data
   */
  toSentry(data: any): Record<string, unknown> {
    if (!('Raven' in window)) {
      return console.error('Raven does not exist!');
    }

    Sentry.captureMessage('Data push', {
      level: 'info',
      extra: {
        ...data,
      },
    });
  }

  /**
   * Push data to local storage
   *
   * @param {object} data
   * @returns {void}
   */
  push(data: any): Record<string, unknown> {
    if ('localStorage' in window) {
      const localData = this.getData();

      // push data to our localstorage
      localData.push(data);
      window.localStorage.setItem('wypDataLayer', JSON.stringify(localData));
    }

    this.toDataLayer(data);
  }

  gtag(): Record<string, unknown> {
    if (!('dataLayer' in window)) {
      return console.error('Datalayer does not exist!');
    }

    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}
