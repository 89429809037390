import React from 'react';
import { IPaymentToken } from '../../../../../reducers/types/PaymentSettings/PaymentToken';
import styled from '@emotion/styled';
import PaymentOptionRadio from '../../../../../atoms/PaymentOptionRadio';
import { DeleteCardIcon } from '../../../../../atoms/icons/Billing';
import { isDarkMode } from '../../../../../utils/helpers';
import useModal from '../../../../../hooks/useModal';

const DeleteCard = styled('a')({
  cursor: 'pointer',
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const SavedPaymentTokenContainer = styled('h4')({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px dashed #DAE1E9',
  paddingBottom: 20,
});

const SavedPaymentTokenHeader = styled('h4')({
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.32px',
  margin: 0,
  padding: 0,
  paddingBottom: 20,
  borderBottom: '1px dashed #DAE1E9',
});

const NoData = styled('div')({
  color: '#BC2028',
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '-0.03px',
  paddingTop: 20,
  fontStyle: 'italic',
});

const SavedCard = styled('div')({
  color: '#BC2028',
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '-0.03px',
  paddingTop: 20,
  fontStyle: 'italic',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const ModalParagraph = styled('p')({
  margin: 0,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21px',
  marginBottom: 10,
});

interface ISavedPaymentToken {
  data: IPaymentToken | null | undefined;
  removeSavedToken: (savedTokenId: number) => void;
}

const SavedPaymentToken: React.FC<ISavedPaymentToken> = ({ data, removeSavedToken }): React.ReactElement => {
  const { confirmActionModal, resetModal } = useModal();

  const handleRemoveToken = (savedTokenId: any): void => {
    if (savedTokenId) {
      confirmActionModal({
        dataTestID: 'payment-settings-delete-saved-token',
        modalContentLabel: 'Delete Payment Info',
        centerAlign: false,
        modalIcon: (
          <DeleteCardIcon customStyle={{ color: isDarkMode() ? '#D06369' : '#BC2028', width: 40, height: 40 }} />
        ),
        confirmationProps: {
          title: `Delete Payment Info`,
          message: (
            <>
              <ModalParagraph>Are you sure you want to remove this payment option?</ModalParagraph>
              <ModalParagraph>Your Auto Credit Top-Up will also be cancelled.</ModalParagraph>
            </>
          ),
          confirmText: `Yes`,
          confirmAction: () => {
            removeSavedToken(savedTokenId);
            resetModal();
          },
        },
      });
    }
  };

  return (
    <SavedPaymentTokenContainer>
      <SavedPaymentTokenHeader>Payment Card on File</SavedPaymentTokenHeader>
      {data?.last_four ? (
        <SavedCard>
          <PaymentOptionRadio
            label={`...${data.last_four}`}
            value={data}
            cardType={data.card_type}
            paymentType={data.payment_type}
            hideInput={true}
          />
          <DeleteCard onClick={() => handleRemoveToken(data?.id)}>
            <DeleteCardIcon customStyle={{ color: isDarkMode() ? '#D06369' : '#BC2028' }} />
          </DeleteCard>
        </SavedCard>
      ) : (
        <NoData>No saved card on file</NoData>
      )}
    </SavedPaymentTokenContainer>
  );
};

export default SavedPaymentToken;
