import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { dimensions, spacing, borders, backgroundTheme } from '../../style';
import { Title, Header } from '../text';
import EditButton from '../buttons/ProfileEditButton';

const PADDING = {
  none: 0,
  small: spacing.SM,
  medium: spacing.MD,
  large: spacing.LG,
};

const PANEL_STYLE = (panelStyle, isLogin) => {
  const styles = {
    default: {
      border: borders.DEFAULT,
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
      borderRadius: '10px',
      backgroundColor: backgroundTheme.block,
      [dimensions.SCREEN_MAX_MD]: {
        boxShadow: 'none',
        border: isLogin ? '1px solid #DAE1E9' : borders.DEFAULT,
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
        ...spacing.MARGIN_BOTTOM_SM,
      },
    },
    white: {
      border: borders.DEFAULT,
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
      borderRadius: '10px',
      backgroundColor: backgroundTheme.block2,
      marginBottom: 15,
      [dimensions.SCREEN_MAX_MD]: {
        boxShadow: 'none',
        border: isLogin ? '1px solid #DAE1E9' : borders.DEFAULT,
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
      },
    },
    transparent: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    borderless: {
      border: 'none',
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
      borderRadius: '10px',
      backgroundColor: backgroundTheme.block,
      [dimensions.SCREEN_MAX_MD]: {
        boxShadow: 'none',
        border: 'none',
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,.04)',
        ...spacing.MARGIN_BOTTOM_SM,
      },
    },
  };
  return styles[panelStyle];
};

const StyledPanel = styled('div')(
  {
    marginBottom: spacing.LG,
  },

  ({ padding }) => ({
    padding: PADDING[padding],
  }),
  ({ withHeader }) => ({
    paddingTop: withHeader && 0,
  }),

  ({ panelStyle, isLogin }) => PANEL_STYLE(panelStyle, isLogin),

  ({ customStyle }) => customStyle,

  ({ isRowMobile }) => {
    if (isRowMobile === true) {
      return {
        [dimensions.SCREEN_MAX_XS]: {
          borderRadius: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
        },
      };
    }
  }
);

const PanelHeader = styled(Header)(
  {
    flex: 10,
    padding: '8px 0',
    paddingBottom: 11,
  },
  ({ panelStyle }) =>
    panelStyle === 'transparent' && {
      [dimensions.SCREEN_MAX_MD]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    }
);

const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const CancelContainer = styled('div')({
  flex: 1,
  position: 'absolute',
  right: 0,
  paddingBottom: 8,
});

const Panel = (props: any): ReactElement => {
  const {
    children,
    title,
    panelStyle,
    editable,
    toggleEditable,
    dataTestId = 'edit-about-info',
    isLogin = false,
    cancelButton = null,
    customComponent,
    customPanelHeaderStyle,
    disabled = false,
    ...rest
  } = props;

  return (
    <StyledPanel isLogin panelStyle={panelStyle} {...rest}>
      <HeaderContainer>
        {title && (
          <PanelHeader bottomRuled panelStyle={panelStyle} style={customPanelHeaderStyle}>
            <Title style={isLogin ? { color: 'rgba(0, 0, 0, 0.6)' } : {}} size="lg">
              {title}
            </Title>
            {customComponent && customComponent}
          </PanelHeader>
        )}
        {editable ? (
          <EditButton
            handleClick={toggleEditable}
            dataTestId={dataTestId}
            customStyle={{ position: 'absolute', right: 0 }}
            disabled={disabled}
          />
        ) : cancelButton ? (
          <CancelContainer>{cancelButton}</CancelContainer>
        ) : (
          ''
        )}
      </HeaderContainer>
      {children}
    </StyledPanel>
  );
};

Panel.propTypes = {
  spaced: PropTypes.bool,
  withHeader: PropTypes.bool,
  padding: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  isRowMobile: PropTypes.bool,
  panelStyle: PropTypes.oneOf(['default', 'transparent', 'borderless', 'white']),
  customStyle: PropTypes.object,
  toggleEditable: PropTypes.func,
};

Panel.defaultProps = {
  padding: 'medium',
  panelStyle: 'default',
  customStyle: {},
};

export default Panel;
