import React, { ReactElement } from 'react';
import { isDarkMode } from '../../../../utils/helpers';

interface IPlusInstructionIconProps {
  customStyle?: any;
}

const PlusInstructionIcon = ({ customStyle }: IPlusInstructionIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" style={customStyle}>
      <path
        d="M11.2386 1.59368C11.9024 2.25417 12 3.18932 12 4.30758V9.33646C12 10.4547 11.9024 11.3833 11.2386 12.0504C10.5748 12.7174 9.63774 12.822 8.52495 12.822H3.46855C2.36226 12.822 1.42516 12.7174 0.761388 12.0504C0.0976139 11.3833 0 10.4547 0 9.33646V4.28796C0 3.19586 0.0976139 2.26071 0.761388 1.59368C1.42516 0.926654 2.36226 0.822021 3.45553 0.822021H8.52495C9.63774 0.822021 10.5748 0.926654 11.2386 1.59368ZM10.9523 4.12447C10.9523 3.4509 10.8677 2.75772 10.4707 2.35881C10.0803 1.96644 9.37744 1.87488 8.70716 1.87488H3.31236C2.62907 1.87488 1.91974 1.9599 1.52278 2.35881C1.13232 2.75118 1.04772 3.45744 1.04772 4.14409V9.51303C1.04772 10.1931 1.13232 10.8863 1.52278 11.2852C1.91974 11.6776 2.62256 11.7692 3.29284 11.7692H8.70716C9.37744 11.7692 10.0803 11.6776 10.4707 11.2852C10.8677 10.8863 10.9523 10.1931 10.9523 9.51303V4.12447ZM2.98698 6.81221C2.98698 6.48524 3.20824 6.25635 3.54664 6.25635H5.45987V4.33374C5.45987 4.00022 5.67462 3.77134 5.99349 3.77134C6.31887 3.77134 6.54664 4.00022 6.54664 4.33374V6.25635H8.46638C8.79176 6.25635 9.01952 6.48524 9.01952 6.81221C9.01952 7.13265 8.79176 7.34845 8.46638 7.34845H6.54664V9.27761C6.54664 9.61112 6.31887 9.83347 5.99349 9.83347C5.67462 9.83347 5.45987 9.60458 5.45987 9.27761V7.34845H3.54664C3.21475 7.34845 2.98698 7.13265 2.98698 6.81221Z"
        fill={isDarkMode() ? 'white' : 'black'}
      />
    </svg>
  );
};

export default PlusInstructionIcon;
