import React from 'react';
import { ButtonColors, ButtonSizes, ButtonStyleReturnType, buttonCommonStyles } from '../../theme/button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { dimensions } from '../../style';
import colors, { white } from '../../theme/colors';
import { theme } from '../../theme';

export const secondaryButtonColorStyle = ({ color }: { color: ButtonColors }): ButtonStyleReturnType => {
  return {
    light: {
      ...buttonCommonStyles,
      color: colors[color][500],
      borderColor: colors[color][500],
      backgroundColor: white,
      hover: {
        color: colors[color][100],
        borderColor: colors[color][500],
        backgroundColor: colors[color][500],
      },
      active: {
        color: colors[color][300],
        borderColor: colors[color][600],
        backgroundColor: colors[color][600],
      },
      disabled: {
        color: colors[color][200],
        borderColor: colors[color][200],
        backgroundColor: white,
      },
    },
    dark: {
      ...buttonCommonStyles,
      color: colors[color][400],
      borderColor: colors[color][400],
      backgroundColor: 'transparent',
      hover: {
        color: colors[color][100],
        borderColor: colors[color][600],
        backgroundColor: colors[color][600],
      },
      active: {
        color: colors[color][400],
        borderColor: colors[color][700],
        backgroundColor: colors[color][700],
      },
      disabled: {
        color: colors[color][700],
        borderColor: colors[color][700],
        backgroundColor: 'transparent',
      },
    },
  };
};

const secondaryButtonSizeStyles = (size: ButtonSizes) => {
  const { fontSize, width, height, fontWeight, padding, borderRadius, lineHeight, borderWidth } = buttonCommonStyles;

  return css`
    font-size: ${fontSize[size]};
    border-radius: ${borderRadius};
    line-height: ${lineHeight};
    width: ${width[size]};
    height: ${height[size]};
    font-weight: ${fontWeight[size]};
    padding: ${padding[size]};
    border-width: ${borderWidth[size]};
  `;
};

const styles = ({ color = 'blue', size = 'small' }: { color: ButtonColors; size: ButtonSizes }) => {
  const { backgroundColor, borderColor, hover, disabled, active, color: defaultColor } = secondaryButtonColorStyle({
    color,
  })[theme];

  return css`
    color: ${defaultColor};
    background-color: ${backgroundColor};
    border: 2px solid ${borderColor};
    ${secondaryButtonSizeStyles(size)}

    &:hover {
      background-color: ${hover.backgroundColor};
      border-color: ${hover.borderColor};
      color: ${hover.color};

      & svg {
        & path {
          fill: ${hover.color} !important;
        }
      }
    }

    &:disabled {
      background-color: ${disabled.backgroundColor};
      border-color: ${disabled.borderColor};
      color: ${disabled.color};
      cursor: not-allowed;
    }

    &:active {
      background-color: ${active.backgroundColor};
      border-color: ${active.borderColor};
      color: ${active.color};
    }

    & svg {
      height: 19px;
      width: 19px;
      fill: ${defaultColor};
    }

    ${dimensions.SCREEN_MAX_SM} {
      border: 1px solid ${borderColor};
      ${secondaryButtonSizeStyles('small')}
    }

    ${dimensions.SCREEN_MAX_XS} {
      border: 1px solid ${borderColor};
      ${secondaryButtonSizeStyles('x-small')}
    }
  `;
};

const StyledButton = styled.button`
  ${styles};
`;

interface ISecondaryButtonProps {
  label: string;
  color: ButtonColors;
  size: ButtonSizes;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick: () => void;
  withIcon?: React.ReactElement;
}

const SecondaryButton: React.FC<ISecondaryButtonProps> = (props: ISecondaryButtonProps) => {
  const { disabled = false, customStyle, label, size = 'regular', color = 'blue', onClick, withIcon, ...rest } = props;
  return (
    <StyledButton disabled={disabled} size={size} color={color} onClick={onClick} style={{ ...customStyle }} {...rest}>
      {withIcon}
      {label}
    </StyledButton>
  );
};

export default SecondaryButton;
