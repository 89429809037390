import React, { useState, useEffect } from 'react';
import Packages from '../screens/billing/Packages';
import { connect } from 'react-redux';
import { getBillingPackages, getKountDetails, postVisitLogs } from '../actions/billingActions';
import { clearErrors, fetchEvalFeature, updateTestVariants } from '../actions/commonActions';
import KountLogo from '../atoms/billing/KountLogo';
import queryString from 'query-string';
import { getBrowserDetails } from '../utils/helpers';
import { refreshProfile } from '../actions/profileActions';
import Loading from '../atoms/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import TrackLoadingToMixpanel from '../components/common/TrackLoadingToMixPanel';
import { createNewOffer, postQuickOffer } from '../actions/offerActions';
import QuickOfferModalInitial from '../modules/QuickOfferModal/QuickOfferModalInitial';

const findPackages = (allPackages, identifier) => {
  identifier.forEach(label => {
    const result = allPackages.find(item => item.identifier === label);

    if (result) {
      return result;
    }
  });
};

const getPackageId = (packageId, defaultPackage) => {
  return packageId || (typeof defaultPackage === 'object' && defaultPackage.identifier) || null;
};

const stepLayout = (location, setShowPayment, setPackageId, setPromoCode) => {
  const queryStringParsed = queryString.parse(location.search);
  const payment = queryStringParsed.step === 'payment';
  const myPackageId = queryStringParsed.package_id || null;
  const myPromoCode = queryStringParsed.promo_code || null;

  setShowPayment(payment);
  setPackageId(myPackageId);
  setPromoCode(myPromoCode);
};

const redirectToDashboard = (profile, packageId, navigate) => {
  if (profile.profile && profile.profile.data.account_type === 'Attractive' && packageId !== 'idv_last_minute') {
    navigate('/dashboard');
  }
};

const BillingPackagesContainer = props => {
  const [processingPayment, setProcessingPayment] = useState(false);
  const [timestampIdentifier, setMyTimestampIdentifier] = useState('');
  const [isStackedVariant, setIsStackedVariant] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [preJoinPromoCode, setPrejoinPromoCode] = useState(null);
  const [isVisitLogged, setIsVisitLogged] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { profile, logPageVisit, paymentToken, errors, packages, kountDetails, featureVariant, postQuickOffer } = props;

  const defaultPackage =
    typeof packages === 'object' && packages.filter(item => item.promotional === true).length > 0
      ? findPackages(packages, ['generous_cyber_monday_2017_2', 'generous_pp_special_2'])
      : packages[1];
  const defaultPackageId = getPackageId(packageId, defaultPackage);

  const queryStringParsed = queryString.parse(location.search);
  const promoCodeFromUrlParam = queryStringParsed.promo || null;

  useEffect(() => {
    props.refreshMyProfile();
    props.getBillingPackages('desc', promoCodeFromUrlParam).then(response => {
      if (response.meta?.promo?.prejoin_code) {
        setPrejoinPromoCode(response.meta.promo.prejoin_code);
        navigate('/packages');
      }
    });
    props.getKountDetails();
    props.clearErrors();
  }, [promoCodeFromUrlParam]);

  useEffect(() => {
    setTimeStampIdentifier();
    setVariantLayout();
    stepLayout(location, setShowPayment, setPackageId, setPromoCode);
  }, [location]);

  useEffect(() => {
    if (!isVisitLogged) {
      setTimeout(() => {
        logPageVisit('packages', 1);
        setIsVisitLogged(true);
      }, 500);
    }
  }, [isVisitLogged, logPageVisit]);

  useEffect(() => {
    const savedCard = (paymentToken && paymentToken[0]) || null;
    const newCard = document.getElementById('new_card');

    if (processingPayment && errors && typeof errors.data !== 'undefined') {
      setProcessingPayment(false);

      if (savedCard && newCard !== null) {
        newCard.click();
      }
    }
  }, [errors, paymentToken, processingPayment]);

  const setVariantLayout = () => {
    const stackedVariant = getBrowserDetails().isMobile;
    setIsStackedVariant(stackedVariant);
  };

  const setTimeStampIdentifier = () => {
    const dateTime = Date.now();
    const timeIdentifier = Math.floor(dateTime / 1000);
    setMyTimestampIdentifier(timeIdentifier);
  };

  if (!packages) {
    return (
      <div>
        <TrackLoadingToMixpanel page="Packages" />
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <Loading />
        </div>
      </div>
    );
  }

  if (typeof packages !== 'object') {
    return false;
  }

  redirectToDashboard(profile, packageId, navigate);

  return (
    <>
      <Packages
        packages={packages}
        stackedVariant={isStackedVariant}
        defaultPackageId={defaultPackageId}
        showPayment={showPayment}
        promoCode={promoCode}
        featureVariant={featureVariant}
        userCountry={profile && profile.profile && profile.profile.data.country}
        promoCodeFromUrlParam={preJoinPromoCode || promoCodeFromUrlParam}
        {...props}
      />
      <KountLogo details={kountDetails} timestamp={timestampIdentifier} />
      {profile?.profile?.data?.account_type === 'Generous' && (
        <QuickOfferModalInitial
          profile={profile}
          clearErrors={clearErrors}
          postQuickOffer={postQuickOffer}
          id={'packagesQuickOffer'}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    packages: state.billing.packages,
    kountDetails: state.billing.kountDetails,
    loading: state.common.isFetching,
    errors: state.common.errors,
    featureVariant: state.common.featureVariant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTestVariants: (featureName: string, payload: Record<string, unknown>) => {
      return dispatch(updateTestVariants(featureName, payload));
    },
    fetchEvalFeature: variantName => {
      return dispatch(fetchEvalFeature(variantName));
    },
    refreshMyProfile: () => {
      dispatch(refreshProfile());
    },
    getBillingPackages: (order = 'desc', promoCode = undefined, isPreJoin = false) => {
      return dispatch(getBillingPackages(order, promoCode, isPreJoin));
    },
    getKountDetails: () => {
      dispatch(getKountDetails());
    },
    createNewOffer: (formData, favKey, section) => {
      return dispatch(createNewOffer(formData, true, favKey, section));
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    logPageVisit: (type, step) => {
      dispatch(postVisitLogs(type, step));
    },
    postQuickOffer: (formData: { accounts: string[]; price: number }) => {
      return dispatch(postQuickOffer(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPackagesContainer);
