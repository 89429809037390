import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouterV6Hooks(Component: any): any {
  function ComponentWithRouterV6Props(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterV6Props;
}
