import React, { ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import Headline from '../../common/Headline';
import { dimensions, patternModal } from '../../../style';
import ButtonSet from '../../blocks/ButtonSet';
import Button from '../../../atoms/buttons/Button';
import { IsMobileViewPort } from '../../../utils/helpers';
import Checkbox from '../../../atoms/Checkbox';
import borders from '../../../theme/borders';
import colors from '../../../theme/colors';

const ContainerDiv = styled('div')({
  paddingTop: 0,
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 26,

  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 12,
  },
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  marginBottom: 12,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 28,
  },
  [dimensions.SCREEN_MIN_XS_4]: {
    paddingTop: 12,
  },
});

const ButtonDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 18,
});

export interface ConfirmationProps {
  title: string;
  message: string | React.ReactNode;
  buttonID?: string;
  cancelText?: string;
  confirmText?: string;
  includeDoNotShowAgainOption?: boolean;
  includeDoNotShowAgainLabel?: string;
  doNotShowAgainValue?: boolean;
  cancelAction?: () => void;
  confirmAction: (doNotShowAgain?: boolean) => void;
}

const ConfirmationV2 = ({
  title = '',
  message = '',
  buttonID = '',
  cancelText = 'Nevermind',
  confirmText,
  cancelAction,
  confirmAction,
  includeDoNotShowAgainOption,
  includeDoNotShowAgainLabel,
  doNotShowAgainValue,
}: ConfirmationProps): ReactElement => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(doNotShowAgainValue);

  return (
    <ContainerDiv>
      <HeaderDiv>
        <TitleDiv>{title}</TitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            marginTop: 20,
            fontSize: 16,
            maxWidth: 250,
            margin: 'auto',
          }}
          data-test-id="profile-offer-headline"
          size="SM"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </HeaderDiv>
      <ButtonDiv>
        <ButtonSet align="center">
          <Button
            id={buttonID}
            buttonType="normal"
            onClick={cancelAction}
            type="submit"
            customStyle={{
              background: patternModal.cancelBG,
              border: patternModal.cancelBorder,
              color: patternModal.cancelBlue,
              marginRight: 20,
              fontSize: IsMobileViewPort ? 14 : 16,
              minWidth: 134,
              maxWidth: 145,
            }}
            data-test-id="modal-decline-offer-btn"
          >
            {cancelText}
          </Button>
        </ButtonSet>
        <ButtonSet align="center">
          <Button
            buttonType="error"
            data-test-id={`${buttonID}Cancel`}
            customStyle={{
              fontSize: IsMobileViewPort ? 14 : 16,
              minWidth: 134,
              maxWidth: 145,
            }}
            onClick={e => {
              confirmAction(doNotShowAgain);
              e.preventDefault();
            }}
          >
            {confirmText}
          </Button>
        </ButtonSet>
      </ButtonDiv>
      {includeDoNotShowAgainOption && (
        <Checkbox
          label={includeDoNotShowAgainLabel || 'Do not show this again'}
          onChange={() => {
            setDoNotShowAgain(!doNotShowAgain);
          }}
          value={doNotShowAgain}
          labelCustomStyle={{ textAlign: 'center', marginTop: 24, marginRight: 0 }}
          customLabelTextStyle={{
            color: borders.dark.line1,
            paddingLeft: 4,
            fontSize: 14,
            paddingTop: 1,
            position: 'relative',
            top: 0.5,
          }}
          customStyle={{
            height: 12,
            width: 12,
            backgroundColor: doNotShowAgain ? colors.light.blue.tertiary : 'inherit',
            borderColor: colors.light.blue.tertiary,
          }}
          svgCustomStyle={{ height: 25, width: 26, paddingTop: 1, paddingLeft: 0.3 }}
        />
      )}
    </ContainerDiv>
  );
};

export default ConfirmationV2;
