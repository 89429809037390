import React, { ReactElement } from 'react';

interface Props {
  customStyle?: React.CSSProperties;
  fill?: string;
}
const IconHelpfulTips = ({ fill = 'none', customStyle, ...rest }: Props): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <g mask="url(#mask0_4455_17892)">
        <path
          d="M43.35 17.7004L40.4 16.3004L43.35 14.9504L44.65 12.0504L46 15.0004L48.95 16.3004L46 17.6504L44.65 20.6004L43.35 17.7004ZM36.3 6.85039L31.95 4.80039L36.3 2.75039L38.3 -1.59961L40.35 2.75039L44.7 4.80039L40.4 6.85039L38.3 11.2004L36.3 6.85039ZM17.35 45.3004C16.15 45.3004 15.1333 44.8587 14.3 43.9754C13.4667 43.0921 13.05 42.0171 13.05 40.7504H21.7C21.7 42.0171 21.275 43.0921 20.425 43.9754C19.575 44.8587 18.55 45.3004 17.35 45.3004ZM10.7 37.4504C10.2333 37.4504 9.84166 37.2921 9.52499 36.9754C9.20833 36.6587 9.04999 36.2671 9.04999 35.8004C9.04999 35.3337 9.20833 34.9421 9.52499 34.6254C9.84166 34.3087 10.2333 34.1504 10.7 34.1504H24C24.4667 34.1504 24.8583 34.3087 25.175 34.6254C25.4917 34.9421 25.65 35.3337 25.65 35.8004C25.65 36.2671 25.4917 36.6587 25.175 36.9754C24.8583 37.2921 24.4667 37.4504 24 37.4504H10.7ZM9.29999 30.9004C6.96666 29.4004 5.10833 27.5171 3.72499 25.2504C2.34166 22.9837 1.64999 20.4171 1.64999 17.5504C1.64999 13.3171 3.19999 9.64206 6.29999 6.52539C9.39999 3.40872 13.0833 1.85039 17.35 1.85039C21.6167 1.85039 25.3 3.40872 28.4 6.52539C31.5 9.64206 33.05 13.3171 33.05 17.5504C33.05 20.4171 32.3667 22.9837 31 25.2504C29.6333 27.5171 27.7667 29.4004 25.4 30.9004H9.29999ZM10.7 26.9504H24.05C25.6167 25.9171 26.85 24.5754 27.75 22.9254C28.65 21.2754 29.1 19.4837 29.1 17.5504C29.1 14.3171 27.95 11.5587 25.65 9.27539C23.35 6.99206 20.5833 5.85039 17.35 5.85039C14.1167 5.85039 11.3583 6.99206 9.07499 9.27539C6.79166 11.5587 5.64999 14.3171 5.64999 17.5504C5.64999 19.4837 6.09166 21.2754 6.97499 22.9254C7.85833 24.5754 9.09999 25.9171 10.7 26.9504Z"
          fill="#BC2028"
        />
      </g>
    </svg>
  );
};

export default IconHelpfulTips;
