import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { endVerificationSession, generateVerificationUrl } from '../actions/authActions';
import VerificationRequired from '../components/pages/Verification/VerificationRequired';
import { refreshProfile as refreshUserProfile } from '../actions/profileActions';
import MasterConfig from '../config/Master';

const VerificationContainer = ({ account, generateUrl, finishVerification, refreshProfile }) => {
  const [verificationStatus, setVerificationStatus] = useState('required');

  useEffect(() => {
    if (
      MasterConfig.auth10tixVerification &&
      account.id_verification &&
      account.id_verification.data &&
      (account.id_verification.data.status === 'required' ||
        account.id_verification.data.status === 'pending' ||
        account.id_verification.data.status === 'revoked' ||
        account.id_verification.data.status === 'denied')
    ) {
      setVerificationStatus(account.id_verification.data.status);
    }
  }, [account]);

  return (
    <VerificationRequired
      verificationStatus={verificationStatus}
      generateUrl={generateUrl}
      finishVerification={finishVerification}
      refreshProfile={refreshProfile}
      account={account}
    />
  );
};

const mapStateToProps = state => {
  return {
    errors: state.common.errors,
    account: state.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateUrl: () => {
      return dispatch(generateVerificationUrl());
    },
    finishVerification: formData => {
      return dispatch(endVerificationSession(formData));
    },
    refreshProfile: () => {
      return dispatch(refreshUserProfile());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerificationContainer);
