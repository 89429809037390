import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import { backgroundTheme, dimensions } from '../../style';
import Button from '../../atoms/buttons/Button';
import CloseIcon from '@material-ui/icons/Close';

const ReminderContainer = styled('div')`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 20%;
  left: 50%;
  margin-left: -200px;
  z-index: 10000;

  ${dimensions.SCREEN_MAX_XS} {
    width: 100%;
  }
`;

const BackdropContainer = styled('div')`
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;

  ${props =>
    props.scrollableInMobile &&
    `${dimensions.SCREEN_MAX_XS} {
      overflow-y: scroll;
    }
    `};
`;

const OfferInfo = styled('div')`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
`;

const OfferClose = styled('a')`
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 20px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  color: #8f8f8f;
  z-index: 4;
`;

const OfferProfileContainer = styled('div')`
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  color: #fff;
  position: absolute;
  bottom: 25px;
  left: 25px;
  text-align: left;
  z-index: 3;
`;

const OfferUsername = styled('div')`
  font-weight: bold;
  font-size: 16px;
`;

const OfferAlertContainer = styled('div')`
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  min-height: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin: 40px auto 0;
  position: relative;
  background-clip: padding-box;
  box-sizing: border-box;
  border: solid 5px transparent;
  top: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(135deg, #ff0099 0%, #ffba72 100%);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }
  ${dimensions.SCREEN_MAX_XS} {
    top: 47%;
    transform: translateY(-50%);
  }

  ${props =>
    props.scrollableInMobile &&
    `${dimensions.SCREEN_MAX_XS} {
      top: 55%;
      margin-bottom: 150px !important
    }
    `};

  ${dimensions.SCREEN_MIN_XXS} {
    width: 300px;
    margin: 15px auto 0;
  }
  ${dimensions.SCREEN_MIN_XS} {
    width: 320px;
    margin: 30px auto 0;
  }
  ${dimensions.SCREEN_MIN_SM} {
    width: 360px;
    margin: 40px auto 0;
  }
`;

const OfferAvatar = styled('div')`
  width: 100%;
  height: 340px;
  background: #000000;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  ${props =>
    props.backgroundUrl &&
    `
        background-image: url(${props.backgroundUrl});
        background-position: top;
        background-size: cover;
    `};
  ${dimensions.SCREEN_MIN_XXS} {
    height: 240px;
  }
`;

const OfferMessageContainer = styled('div')`
  flex: 1;
  padding: 14px;
  border-radius: 0px 0px 20px 20px;
  background-color: ${props => (props.color ? activityColor[props.color].panelColor : backgroundTheme.block)};
`;

const OfferMessage = styled('div')`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #333333;
  margin: 10px;
`;

const OfferMessageDesc = styled('div')`
  text-align: center;
  font-size: 16px;
  color: #595959;
  margin: 10px;
`;

const OfferProfileDetail = styled('div')`
  font-size: 13px;
  color: #afb0b1;
  margin-top: 3px;
`;

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 81%, rgba(0,0,0,1) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
});

interface CompleteProfileModalV2Props {
  avatar: any;
  username: string;
  age: string | number;
  location: string;
  editProfileLink: any;
  onClose: () => any;
  isOpen: any;
  closeLightbox?: any;
  scrollableInMobile?: boolean;
}

const CompleteProfileModalV2 = ({
  avatar,
  username,
  age,
  location,
  editProfileLink,
  onClose,
  isOpen,
  closeLightbox,
  scrollableInMobile = false,
}: CompleteProfileModalV2Props): ReactElement => {
  return (
    <ReminderContainer isOpen={isOpen} data-test-id="complete-profile-reminder">
      {isOpen && (
        <BackdropContainer scrollableInMobile={scrollableInMobile}>
          <OfferAlertContainer data-test-id="complete-profile-modal" scrollableInMobile={scrollableInMobile}>
            <OfferAvatar backgroundUrl={avatar}>
              <OfferClose
                data-test-id="complete-profile-skip"
                onClick={e => {
                  e.preventDefault();
                  onClose();
                }}
              >
                <CloseIcon style={{ fontSize: 28 }} />
              </OfferClose>
              <OfferProfileContainer>
                <OfferUsername>{username}</OfferUsername>
                <OfferProfileDetail>
                  {' '}
                  {age} • {location}
                </OfferProfileDetail>
              </OfferProfileContainer>
              <Gradient />
            </OfferAvatar>
            <OfferMessageContainer data-test-id="complete-profile-modal-info">
              <OfferMessage>Complete your profile to send and receive Date Offers</OfferMessage>
              <OfferMessageDesc>
                Make sure you upload a public photo and have your height and body type filled out.
              </OfferMessageDesc>
              <OfferInfo>
                <Button
                  buttonType="primary"
                  data-test-id="edit-profile-link"
                  to={editProfileLink}
                  onClick={() => {
                    onClose();
                    closeLightbox && closeLightbox();
                  }}
                >
                  Edit My Profile
                </Button>
              </OfferInfo>
            </OfferMessageContainer>
          </OfferAlertContainer>
        </BackdropContainer>
      )}
    </ReminderContainer>
  );
};

export default CompleteProfileModalV2;
