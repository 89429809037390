import React, { useState, useEffect, useCallback } from 'react';
import interest from './Interest/interest.json';
import _ from 'lodash';
import EditableInterestedIn from '../../../atoms/EditableInterestedIn';
import { isDarkMode } from '../../../utils/helpers';
import PanelTagsWithHeader from '../../../atoms/panels/PanelTagsWithHeader';
interface MoreInfoProps {
  profile: any;
  errors: any;
  updateProfileInterest: any;
}

const interestData = profile => {
  const interestsData: any[] = [];

  if (profile) {
    interest.data.map(item => {
      interestsData.push({
        id: item.id,
        value: item.value,
        is_selected: profile['dating_' + item.id] || profile['dating_just_' + item.id] ? true : item.is_selected,
      });
    });
  }

  return interestsData;
};

const MoreInfo = ({ profile, errors, updateProfileInterest, ...rest }: MoreInfoProps): any => {
  const [interests, setInterests] = useState(false);
  const [dating, setDating] = useState(false);
  const [friendship, setFriendship] = useState(false);
  const [marriage, setMarriage] = useState(false);
  const [married, setMarried] = useState(false);
  const [adult, setAdult] = useState(false);
  const [mentorship, setMentorship] = useState(false);

  useEffect(() => {
    mapAllStates();
    return () => {
      setInterests(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const mapAllStates = useCallback(() => {
    setDating(findInterest('dating', 'is_selected'));
    setFriendship(findInterest('friendship', 'is_selected'));
    setMarriage(findInterest('marriage', 'is_selected'));
    setMarried(findInterest('married', 'is_selected'));
    setAdult(findInterest('adult', 'is_selected'));
    setMentorship(findInterest('mentorship', 'is_selected'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const findInterest = (id, option) => {
    const allInterests = interestData(profile);
    const myInterest = allInterests.find(type => type.id === id);
    return myInterest[option];
  };

  const makeCall = (id, val) => {
    eval(`set${_.startCase(id)}(${val})`);
  };

  const toggleEditable = e => {
    e.preventDefault();
    setInterests(!interests);
  };

  const onChangeHandler = (e, value) => {
    // we will track each checkbox onChange and update the state accordingly
    const id = e.currentTarget.id;
    makeCall(id, value);
  };
  const handleSubmit = e => {
    updateProfileInterest({
      dating_just_dating: dating,
      dating_friendship: friendship,
      dating_marriage: marriage,
      dating_married: married,
      dating_adult: adult,
      dating_mentorship: mentorship,
    });
    toggleEditable(e);
  };

  const handleCancel = e => {
    toggleEditable(e);
    mapAllStates();
  };

  const moreInfo = {
    dating: dating,
    friendship: friendship,
    marriage: marriage,
    married: married,
    adult: adult,
    mentorship: mentorship,
  };

  const renderData = () => {
    const data = [];
    Object.getOwnPropertyNames(moreInfo).map(name => {
      moreInfo[name] && data.push(findInterest(name, 'value'));
    });
    return data;
  };

  return (
    <div>
      {interests ? (
        <EditableInterestedIn
          className="profile__interested-in"
          title="Interests"
          allInterests={moreInfo}
          errors={errors}
          onChangeHandler={onChangeHandler}
          findInterest={findInterest}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      ) : (
        <PanelTagsWithHeader
          className="profile__interested-in"
          title="Interests"
          data={renderData()}
          toggleEditable={toggleEditable}
          dataTestId="edit-interested-in"
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
          {...rest}
        />
      )}
    </div>
  );
};

export default MoreInfo;
