import React, { Fragment, ReactElement } from 'react';
import styled from '@emotion/styled';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { dimensions, type } from '../../style';
import ButtonSet from '../../components/blocks/ButtonSet';
import Button from '../buttons/Button';
import { tsSince } from '../../common';
import { Lock, Message } from '@material-ui/icons';
import useMixPanel from '../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../constants/mixpanel';
import { useNavigate } from 'react-router-dom';

const PriceContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
});

const Time = styled('div')({
  color: '#8492a6',
  textAlign: 'center',
  ...type.SM,
  marginTop: 10,
});

const Amount = styled('label')(
  {
    color: '#0D5C93',
    fontSize: 60,
    fontWeight: 500,
    letterSpacing: '-0.02em',
    [dimensions.SCREEN_MAX_MD_2]: {
      fontSize: 25,
    },
    [dimensions.SCREEN_MAX_MD]: {
      fontSize: 30,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 28,
    },
    [dimensions.SCREEN_MAX_XXS]: {
      fontSize: 24,
      marginBottom: 1,
    },
  },
  ({ viewType }) =>
    viewType === 'list' && {
      [dimensions.SCREEN_MIN_XS_2]: {
        fontSize: 23,
      },
    }
);

const ButtonDiv = styled('div')({
  marginTop: 10,

  [dimensions.SCREEN_MAX_SM]: {
    width: '100%',
  },
});

const ButtonStyle = {
  backgroundColor: '#bc2028',
  color: '#fff',
  diplay: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

interface AcceptedOfferPanelProps {
  offer: any;
  createdAt: any;
  offerPrice: any;
  myProfile: any;
  otherProfile: any;
  otherAccount: any;
  isGenerous: any;
  unlockMessage: any;
}

const AcceptedOfferPanel = ({
  offer,
  otherAccount,
  myProfile,
  createdAt,
  isGenerous,
  unlockMessage,
}: AcceptedOfferPanelProps): ReactElement => {
  const { mixpanelData, resetMixpanelData, trackPackagesPageVisited } = useMixPanel();

  const navigate = useNavigate();

  const handleGoToConversation = () => {
    navigate(`/mail/${otherAccount.hash_id}`);
  };

  const handleUnlockConversation = () => {
    const creditCost = offer.credit_cost;
    const currentCredit = myProfile.credits;
    const hashId = otherAccount.hash_id;

    if (creditCost > currentCredit) {
      trackPackagesPageVisited({ Source: 'Unlock Attempt' });
      navigate('/packages');
      return;
    }

    unlockMessage(hashId, mixpanelData[EVENT_NAMES.CONVERSATION_UNLOCKED].triggerToBuy, () => {
      resetMixpanelData(EVENT_NAMES.CONVERSATION_UNLOCKED);
    });

    setTimeout(() => (isGenerous ? navigate(`/mail/${hashId}`) : navigate('/mail/remindMessage=true')), 500);
  };

  const formatPrice = (currency, price) => {
    if (!currency || !price) {
      return '';
    }

    return `${currency.initial}${String.fromCharCode(currency.label)}${price}`;
  };

  return (
    <Fragment>
      <PriceContainer>
        <Amount>
          {formatPrice(
            {
              initial: offer ? offer.currency_original.data.initial : '',
              label: offer ? offer.currency_original.data.label_unicode : '',
            },
            offer.price_original
          )}
        </Amount>
      </PriceContainer>
      {createdAt ? <Time>{createdAt && tsSince(createdAt)}</Time> : null}
      <ButtonDiv>
        <ButtonSet align="center">
          {offer.is_deal_unlocked || !isGenerous ? (
            <Button
              id={'btn-go-to-conversation'}
              onClick={handleGoToConversation}
              type="button"
              customStyle={ButtonStyle}
            >
              <Fragment>
                <Message color="inherit" />
                &ensp;
                <span>Go To Messages</span>
              </Fragment>
            </Button>
          ) : (
            <Button
              id={'btn-unlock-conversation'}
              onClick={handleUnlockConversation}
              type="button"
              customStyle={ButtonStyle}
            >
              <Fragment>
                <Lock color="inherit" />
                &ensp;
                <span>Unlock Messages</span>
              </Fragment>
            </Button>
          )}
        </ButtonSet>
      </ButtonDiv>
    </Fragment>
  );
};

export default AcceptedOfferPanel;
