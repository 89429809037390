import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const Cards = styled('div')(
  {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ({ listMargin, isMobile }) => {
    if (listMargin) {
      return { margin: listMargin };
    } else {
      return { margin: isMobile ? '-1%' : '-.5%' };
    }
  }
);

const CardList = (props: any): ReactElement => {
  const { listMargin, isMobile } = props;

  return (
    <Cards listMargin={listMargin} isMobile={isMobile}>
      {props.children}
    </Cards>
  );
};

export default CardList;
