import React, { FC } from 'react';
import { connect } from 'react-redux';
import { updateFingerPrint, updateFingerPrintStartTime, updateDCCIFrameLoaded } from '../actions/ddcActions';
import { IDDC } from '../reducers/types/DDC.d';
import DeviceDataCollection, { IDeviceDataCollection } from '../components/pages/Billing/DeviceDataCollection';
interface IDDCContainer extends Omit<IDeviceDataCollection, 'ddcData' | 'initialIDs'> {
  ddc: IDDC;
  billing: any;
  profile: any;
}

const DDCContainer: FC<IDDCContainer> = ({
  ddc,
  profile,
  updateFingerPrint,
  updateFingerPrintStartTime,
  updateDCCIFrameLoaded,
}) => {
  return (
    <>
      {ddc.ddcData.map(ddcData =>
        ddcData.shouldRenderIframe ? (
          <DeviceDataCollection
            key={ddcData.iFrameKey}
            profile={profile}
            ddcData={ddcData}
            initialIDs={ddc.initialIDs}
            updateFingerPrint={updateFingerPrint}
            updateFingerPrintStartTime={updateFingerPrintStartTime}
            updateDCCIFrameLoaded={updateDCCIFrameLoaded}
          />
        ) : null
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    billing: state.billing,
    ddc: state.ddc,
  };
};

export default connect(mapStateToProps, { updateFingerPrint, updateFingerPrintStartTime, updateDCCIFrameLoaded })(
  DDCContainer
);
