import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { FULL_URL } from '../config/Master';
import { removeLocalItem } from '../common';
import TextLink from '../components/common/TextLink';
import TextLinkV2 from '../components/common/TextLinkV2';
import { useApp } from '../contexts/AppContext';

import { spacing } from '../style';

const MessageContainer = styled('div')({
  textAlign: 'center',
  ...spacing.MARGIN_TOP_LG,
  marginBottom: '9px',
  marginTop: 0,
});

interface NeedAccountMessageProps {
  customStyle?: React.CSSProperties;
}

const NeedAccountMessage = ({ customStyle }: NeedAccountMessageProps): ReactElement => {
  const { setJoinPhoneNumber, setOtpFormInputFocus, setHasRequestOtpError } = useApp();
  return (
    <MessageContainer style={customStyle}>
      Need an account?{' '}
      <TextLink
        onClick={() => {
          removeLocalItem('join_predata');
          removeLocalItem('join_onboarding_predata');
          setJoinPhoneNumber('');
          setOtpFormInputFocus(0);
          setHasRequestOtpError('');
        }}
        to="/join"
      >
        Create one here
      </TextLink>
      . <br /> Please review our{' '}
      <TextLinkV2 rel="noopener" href={`${FULL_URL}/community-guidelines`} target="_blank">
        {' '}
        community guidelines{' '}
      </TextLinkV2>
    </MessageContainer>
  );
};

export default NeedAccountMessage;
