import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Source: string;
  'Days after registration': number;
}

export const mixpanelTrackPWAInstalled = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'PWA Installed',
    payload: eventProperties,
  });
};
