import React, { ReactElement } from 'react';
interface IVisibilityIconProps {
  customStyle?: any;
}

const VisibilityIcon = ({ customStyle }: IVisibilityIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={customStyle}>
      <mask
        id="mask0_2497_13558"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2497_13558)">
        <path
          d="M16.292 18.583L12.938 15.208C12.4653 15.3613 11.986 15.476 11.5 15.552C11.014 15.6287 10.514 15.667 10 15.667C7.94468 15.667 6.11834 15.101 4.52101 13.969C2.92368 12.837 1.77068 11.368 1.06201 9.562C1.34001 8.854 1.70134 8.19433 2.14601 7.583C2.59001 6.97233 3.08301 6.424 3.62501 5.938L1.37501 3.625L2.54201 2.5L17.458 17.438L16.292 18.583ZM10 13.229C10.1387 13.229 10.288 13.2187 10.448 13.198C10.608 13.1773 10.7503 13.153 10.875 13.125L6.45801 8.729L6.37501 9.146C6.34701 9.28467 6.33301 9.42333 6.33301 9.562C6.33301 10.59 6.68734 11.4583 7.39601 12.167C8.10401 12.875 8.97201 13.229 10 13.229ZM15.958 13.562L13.396 11.021C13.4933 10.8263 13.5627 10.6007 13.604 10.344C13.646 10.0867 13.667 9.826 13.667 9.562C13.667 8.53467 13.3127 7.66667 12.604 6.958C11.896 6.25 11.028 5.896 10 5.896C9.76401 5.896 9.52101 5.91667 9.27101 5.958C9.02101 6 8.77801 6.06267 8.54201 6.146L6.54201 4.146C7.09734 3.92333 7.66334 3.76 8.24001 3.656C8.81601 3.552 9.40268 3.5 10 3.5C12.0413 3.5 13.8643 4.059 15.469 5.177C17.073 6.295 18.2293 7.75667 18.938 9.562C18.618 10.354 18.208 11.0867 17.708 11.76C17.208 12.434 16.6247 13.0347 15.958 13.562ZM12.208 9.812L9.72901 7.354C10.1043 7.326 10.4517 7.36767 10.771 7.479C11.0903 7.59033 11.354 7.764 11.562 8C11.7987 8.264 11.976 8.54533 12.094 8.844C12.212 9.14267 12.25 9.46533 12.208 9.812Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default VisibilityIcon;
