import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ConfirmationModal from '../modules/modals/ConfirmationModal';
import BasicModal from '../modules/modals/BasicModal';
import { MenuList, MenuListItem } from '../components/blocks/MenuList';
import Button from '../atoms/buttons/Button';
import { backgroundTheme, dimensions, textColor, type } from '../style';
import { isDarkMode } from '../utils/helpers';

const BlockOrReportContainer = styled('div')({
  borderRadius: 10,
  backgroundColor: backgroundTheme.block2,
  paddingTop: '14px',
  paddingBottom: '14px',
  textAlign: 'center',
  cursor: 'pointer',
  color: '#2490DE ',
  fontWeight: 'bold',
  border: '1px solid rgb(218, 225, 233)',
  ...type.MD,
  [dimensions.SCREEN_MAX_LG]: {
    marginLeft: isDarkMode() ? 15 : 0,
    marginRight: isDarkMode() ? 15 : 0,
  },
});

interface BlockOrReportProps {
  hashId: any;
  username: any;
  isBlocked: any;
  onToggleBlock: any;
}

const BlockOrReport = ({ hashId, username, isBlocked, onToggleBlock }: BlockOrReportProps): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleBlockModal = () => {
    setIsModalOpen(false);
    setIsBlockModalOpen(!isBlockModalOpen);
  };

  const handleOnUnblock = () => {
    toggleModal();
    onToggleBlock();
  };

  const blockLabel = isBlocked ? 'Unblock' : 'Block';
  return (
    <React.Fragment>
      <BlockOrReportContainer data-test-id="block-report-container" onClick={toggleModal} darkMode={isDarkMode}>
        {blockLabel} or Report
      </BlockOrReportContainer>
      <BasicModal
        contentLabel="Block or Report Modal"
        isOpen={isModalOpen}
        closeOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onClose={toggleModal}
        hideClose={true}
      >
        <MenuList>
          <MenuListItem>
            <Button
              data-test-id="profile-report-button"
              buttonType="chromeless"
              fit
              to={`/report/${hashId}`}
              customStyle={{ ...textColor.DARK }}
            >
              Report User
            </Button>
          </MenuListItem>
          <MenuListItem>
            {isBlocked ? (
              <Button
                data-test-id="profile-unblock-button"
                buttonType="chromeless"
                fit
                onClick={handleOnUnblock}
                customStyle={{ ...textColor.DARK }}
              >
                Unblock User
              </Button>
            ) : (
              <Button
                data-test-id="profile-block-button"
                buttonType="chromeless"
                fit
                onClick={toggleBlockModal}
                customStyle={{ ...textColor.DARK }}
              >
                Block User
              </Button>
            )}
          </MenuListItem>
        </MenuList>
      </BasicModal>

      <ConfirmationModal
        buttonID="profile-block"
        contentLabel="Block Modal"
        isOpen={isBlockModalOpen}
        description={`Are you sure you want to block ${username}?`}
        cancelText="Nevermind"
        confirmText="Block"
        onClose={toggleBlockModal}
        onConfirm={onToggleBlock}
      />
    </React.Fragment>
  );
};

BlockOrReport.propTypes = {
  hashId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  onToggleBlock: PropTypes.func.isRequired,
};

export default BlockOrReport;
