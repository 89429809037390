import React from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../theme/colors';
import DashboardDiscoverModeCrossIcon from '../icons/DashboardDiscoverModeCrossIcon';
import { theme } from '../../../../theme';
import { dimensions } from '../../../../style';

const ContainerWrapper = styled('div')(() => ({
  display: 'inline-flex',
  padding: '11px 20px',
  alignItems: 'center',
  gap: 5,
  borderRadius: 15,
  border: `3px solid ${colors[theme].red.primary}`,
  background: white,
  position: 'absolute',
  left: 10,
  top: 30,
  opacity: 0.6,
  [dimensions.SCREEN_MIN_MD]: {
    left: 20,
  },
}));

const PassTextWrapper = styled('div')({
  color: colors[theme].red.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
});

const DashboardDiscoverModePassFlagOverlay: React.FC = () => {
  return (
    <ContainerWrapper>
      <DashboardDiscoverModeCrossIcon />
      <PassTextWrapper>NOPE!</PassTextWrapper>
    </ContainerWrapper>
  );
};

export default DashboardDiscoverModePassFlagOverlay;
