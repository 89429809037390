export const STORE_FAVS = 'STORE_FAVS';
export const CLEAR_FAVS = 'CLEAR_FAVS';
export const CLEAR_IS_FETCHING = 'CLEAR_IS_FETCHING';
export const STORE_FAV_CHANGE = 'STORE_FAV_CHANGE';
export const REQUEST_FAVS = 'REQUEST_FAVS';
export const REQUEST_INTERESTED = 'REQUEST_INTERESTED';
export const CLEAR_INTERESTED = 'CLEAR_INTERESTED';
export const STORE_INTERESTED = 'STORE_INTERESTED';
export const IGNORE_INTERESTED_FAV = 'IGNORE_INTERESTED_FAV';
export const STORE_FAV_OFFER_CHANGE = 'STORE_FAV_OFFER_CHANGE';

export interface Items {
    items: unknown[];
}

export interface To {
    to: unknown[];
}

export interface From {
    from: unknown[];
}

export interface Viewed {
    viewed: unknown[];
}

export interface Featured {
    featured: unknown[];
}

export interface Interested {
    interested: unknown[];
}

export interface Id {
    id: string;
}

export interface FavPayload {
    payload: Items | To | From | Viewed | Featured | Interested;
}

export interface FavouriteState extends Items, To, From, Viewed, Featured, Interested {
    isFetching: boolean;
    isFetchingFav: boolean;
}

interface STORE_FAVS_ACTION {
    type: typeof STORE_FAVS;
    payload: FavPayload;
    section: string;
    firstCall: boolean;
}

interface CLEAR_FAVS_ACTION {
    type: typeof CLEAR_FAVS;
}

interface CLEAR_IS_FETCHING_ACTION {
    type: typeof CLEAR_IS_FETCHING;
}

interface STORE_FAV_CHANGE_ACTION {
    type: typeof STORE_FAV_CHANGE;
    payload: string;
    section: string;
}

interface REQUEST_FAVS_ACTION {
    type: typeof REQUEST_FAVS;
}

interface REQUEST_INTERESTED_ACTION {
    type: typeof REQUEST_INTERESTED;
}

interface CLEAR_INTERESTED_ACTION {
    type: typeof CLEAR_INTERESTED;
}

interface STORE_INTERESTED_ACTION {
    type: typeof STORE_INTERESTED;
    payload: Interested;
    firstCall: boolean;
}

interface IGNORE_INTERESTED_FAV_ACTION {
    type: typeof IGNORE_INTERESTED_FAV;
    payload: Id;
}

interface STORE_FAV_OFFER_CHANGE {
    type: typeof STORE_FAV_OFFER_CHANGE;
    payload: Items | To | From | Viewed | Featured | Interested;
    key: string;
    section: string;
}

export type FavouriteActionType = 
    | STORE_FAVS_ACTION
    | CLEAR_FAVS_ACTION
    | CLEAR_IS_FETCHING_ACTION
    | STORE_FAV_CHANGE_ACTION
    | REQUEST_FAVS_ACTION
    | REQUEST_INTERESTED_ACTION
    | CLEAR_INTERESTED_ACTION
    | STORE_INTERESTED_ACTION
    | IGNORE_INTERESTED_FAV_ACTION
    | STORE_FAV_OFFER_CHANGE;

    
