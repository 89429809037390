import React from 'react';
import SecondaryButton from '../../../../../atoms/buttons/SecondaryButton';
import RoundCheckIcon from '../../../../../atoms/icons/RoundCheckIcon';

interface IProps {
  onConfirm: () => void;
  customStyle?: React.CSSProperties;
}

const OneTapSwipeTutorialGotItButton: React.FC<IProps> = ({ onConfirm, customStyle }: IProps) => {
  return (
    <SecondaryButton
      size="x-small"
      color="blue"
      onClick={onConfirm}
      data-test-id="dashboard-swipe-mode-got-it"
      label="Got It!"
      customStyle={{ width: 'inherit', margin: '0 auto', display: 'flex', alignItems: 'center', ...customStyle }}
      withIcon={<RoundCheckIcon customStyle={{ width: 13, height: 13, marginRight: 10 }} />}
    />
  );
};

export default OneTapSwipeTutorialGotItButton;
