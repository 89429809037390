import { mixpanelTrackEventFromBE } from '.';

export type Pages = 'Viewed me' | 'My Favorites' | 'Favorited Me';
export type Modes = 'list' | 'grid';

const getSourcePageFromUrl = (): Pages | undefined => {
  const sources: {
    [key: string]: any;
  } = {
    '/interests/my-favorites': 'My Favorites',
    '/interests/favorited-me': 'Favorited Me',
    '/interests': 'Viewed Me',
  };

  const source = sources[window.location.pathname];

  if (source) {
    return source;
  }
};

export const mixpanelTrackInterestsDisplayModeChanged = async (mode: Modes): Promise<void> => {
  const source = getSourcePageFromUrl();

  if (!source) return;

  await mixpanelTrackEventFromBE({
    event_name: 'Interests - Display Mode Changed',
    payload: {
      event_properties: {
        Page: source,
        'New Mode': mode === 'list' ? 'Table' : 'Grid',
      },
    },
  });
};
