import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';

import Panel from './Panel';
import { color, type } from '../../style';
import { getBrowserDetails, tabletWidth } from '../../utils/helpers';

const Item = styled('dl')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0',
  padding:
    getBrowserDetails().isMobile && !getBrowserDetails().isTablet ? '10px 0px' : tabletWidth ? '14px 16px' : '8px 0',
  '&:first-of-type': {
    paddingTop: '0px',
  },
});

const ItemLabel = styled('dt')(
  {
    color: color.GREY_2,
    ...type.MD,
  },
  ({ isLogin }) => isLogin && { color: 'rgba(0, 0, 0, 0.44)' }
);

const ItemInfo = styled('dd')(({ label }) => ({
  textAlign: 'right',
  WebkitMarginStart: label === 'Body Type' ? 25 : 'auto',
  ...type.MD,
}));

interface PanelStatsWithHeaderProps {
  title: any;
  data: any;
  className: any;
  toggleEditable: any;
  isLogin: boolean;
}

const PanelStatsWithHeader = ({
  title,
  data,
  className,
  toggleEditable,
  isLogin = false,
  ...rest
}: PanelStatsWithHeaderProps): ReactElement => {
  const renderItems = () => {
    if (isEmpty(data)) return null;

    return Object.entries(data).map(([key, item]) => {
      const label = item.label;
      const info = item.info;
      const dataId = item.dataId !== undefined ? item.dataId : '';

      return (
        <Item key={key} data-id={dataId}>
          <ItemLabel isLogin={isLogin}>{label}</ItemLabel>
          <ItemInfo label={label}>{info}</ItemInfo>
        </Item>
      );
    });
  };

  return (
    <Panel
      withHeader
      title={title}
      className={className}
      toggleEditable={toggleEditable}
      customStyle={isLogin ? { backgroundColor: '#FFF', border: '1px solid rgb(218, 225, 233)' } : {}}
      isLogin={isLogin}
      {...rest}
    >
      {renderItems()}
    </Panel>
  );
};

PanelStatsWithHeader.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

PanelStatsWithHeader.defaultProps = {
  title: '',
  data: [],
};

export default PanelStatsWithHeader;
