import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setPurchaseVysion = (param: any): void => {
  switch (param.event) {
    case 'BUY CREDITS NAVBAR':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'navBar-buyCredits');
      break;
    case 'BUY CREDITS ACCTMENU':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'accountMenu-buyCredits');
      break;
    case 'SUFFICIENT CREDITS UNLOCK':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'messages-sufficientCredits-unlock');
      break;
    case 'INSUFFICIENT CREDITS UNLOCK':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'messages-insufficientCredits-unlock');
      break;
    case 'BUY 1000 CREDITS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'packages-1000Credits');
      break;
    case 'BUY 500 CREDITS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'packages-500Credits');
      break;
    case 'BUY 100 CREDITS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'packages-100Credits');
      break;
    default:
      return;
  }
};

export { setPurchaseVysion };
