import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  'Verification Method': string;
}

interface UserProperties {
  'Phone Number': boolean;
}

export const mixpanelTrackPhoneNumberChanged = async (
  eventProperties: EventProperties,
  userProperties: UserProperties
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Phone Number Changed',
    payload: {
      event_properties: eventProperties,
      user_properties: userProperties,
    },
  });
};
