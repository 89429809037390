import React, { ReactElement, useEffect } from 'react';
import styled from '@emotion/styled';

import Avatar from '../../../atoms/Avatar';
import { Link, useLocation } from 'react-router-dom';
import { dimensions, spacing, borders, type, textColor, textType, backgroundTheme } from '../../../style';
import { displayUserLocation, tsSince } from '../../../common';
import { isDarkMode, lazilyEvaluateVal } from '../../../utils/helpers';
import { renderAvatar } from '../../../common';
import { PrivatePhotoIcon, PublicPhotoIcon, StarIcon } from '../../../atoms/icons/favoriteIcons';
import OfferActionButton from './OfferActionButton';
import { auth10tixVerification } from '../../../config/Master';
import { commonIcons } from '../../../atoms/icons/materialIcons';
import { getBrowserDetails } from '../../../utils/helpers';
import useMixPanel from '../../../hooks/useMixPanel';
import { resetUnreadOneTapOffersCount } from '../../../actions/unreadOneTapOffersCountActions';
import { connect } from 'react-redux';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../constants/mixpanel';

const ResultItem = styled('div')(
  {
    ...textColor.DARKER,
    display: 'inline-flex',
    backgroundColor: backgroundTheme.block,
    flex: '1 0 100%',
    position: 'relative',
    [dimensions.SCREEN_MAX_SM]: {
      flex: '1 0 40%',
    },
    paddingBottom: 15,
    paddingTop: 15,
    borderBottom: borders.DEFAULT,
  },
  ({ backgroundColor }) => ({
    backgroundColor: `${backgroundColor}`,
  })
);

const WrapTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  [dimensions.SCREEN_MAX_SM]: {
    alignItems: 'inherit',
  },
});

const Title = styled('div')({
  ...type.title.XS,
  ...textColor.DARKER,
  maxWidth: 160,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 16,
    maxWidth: 87,
    fontWeight: 600,
  },
});

const Meta = styled('div')({
  ...textType.caption,
  ...spacing.MARGIN_BOTTOM_XS,
  [dimensions.SCREEN_MAX_SM]: {
    maxWidth: 94,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const Details = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  marginRight: 12,
  ...textType.caption,
  [dimensions.SCREEN_MAX_SM]: {
    marginRight: 10,
  },
});

const Wrapper = styled(Link)({
  flexGrow: 1,
  display: 'flex',
  marginLeft: 20,
  [dimensions.SCREEN_MAX_SM]: {
    marginLeft: 10,
  },
});

const Timestamp = styled('div')({
  marginTop: 25,
  fontSize: 13,
  ...textColor.NORMAL,
  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 0,
  },
});

const PhotoCountDetails = styled('div')({
  ...type.SM,
});

const PhotoCount = styled('span')({
  ...type.SM,
  ...textColor.NORMAL,
  position: 'relative',
});

const StyledPhotoCount = styled('span')({
  marginRight: 5,
});

const StyledPublicPhotoCount = styled('span')({
  borderRight: '1px solid #BC2028',
  paddingRight: 5,
  marginRight: 5,
});

const Icon = styled('span')({
  paddingRight: 5,
});

const OnlineDot = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: 'lightgreen',
  borderRadius: '50%',
  display: 'inline-block',
});

const StyledStatusBannerText = styled('span')(
  {
    fontWeight: 700,
    fontStyle: 'italic',
    letterSpacing: '-0.02em',
    fontSize: 14,
    display: 'inline-block',
    position: 'relative',
    '::after': {
      content: '"•"',
      display: 'inline-block',
      ...textColor.DARKER,
      margin: '0 4px',
      float: 'right',
    },
    svg: {
      height: 12,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 12,
      minWidth: 110,
      svg: {
        display: 'none',
      },
    },
  },
  ({ customColor }) => ({
    color: `${customColor}`,
  })
);

const VerifiedBadge = styled('span')({
  marginLeft: 4,
  zIndex: 2,
});

interface OfferProfileListViewProps {
  offerKey: any;
  profile: any;
  title: any;
  hashId: any;
  isFavoritedMe: any;
  isMatched: any;
  timestamp: any;
  offer: any;
  user: any;
  ignoreUser: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  ignoreOfferFromList: any;
  unlockMessage(): void;
  updateOffer(): void;
  pageTitle: any;
  resetUnreadOffersCountAction(): void;
}

const OfferProfileListView = ({
  offerKey,
  profile,
  title,
  hashId,
  isFavoritedMe,
  isMatched,
  timestamp,
  offer,
  user,
  ignoreUser,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  ignoreOfferFromList,
  unlockMessage,
  updateOffer,
  pageTitle,
  resetUnreadOffersCountAction,
}: OfferProfileListViewProps): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/offers/new') {
      resetUnreadOffersCountAction();
    }
  }, [location.pathname]);

  const { isDesktop } = getBrowserDetails();
  const { trackProfileView, setMixpanelDataByKeyHandler } = useMixPanel();
  const getInfoText = (profile, otherProfile) => {
    if (!profile || !otherProfile) return '';

    const userLoc = {
      country: profile.country,
      region: profile.region,
      city: profile.city,
    };

    const otherUserLoc = {
      country: otherProfile.country,
      region: otherProfile.region,
      city: otherProfile.city,
    };

    return `${otherProfile.age} • ${displayUserLocation(userLoc, otherUserLoc)}`;
  };

  const getStatusBanner = (isCustomBackground = false) => {
    const darkMode = isDarkMode();
    if (isMatched) {
      if (isCustomBackground) {
        return darkMode ? 'rgba(208, 99, 105, 0.4)' : 'rgba(249, 233, 234, 1)';
      }

      return (
        <StyledStatusBannerText data-test-id="fav-banner-mutual-favorite" customColor="rgba(188, 32, 40, 1)">
          <StarIcon isActive={false} fill="rgba(188, 32, 40, 1)" /> Mutual Favorite!
        </StyledStatusBannerText>
      );
    }

    if (isFavoritedMe) {
      if (isCustomBackground) {
        return darkMode ? 'rgba(208, 99, 105, 0.4)' : 'rgba(249, 233, 234, 1)';
      }

      return (
        <StyledStatusBannerText data-test-id="fav-banner-favorited-you" customColor="rgba(188, 32, 40, 1)">
          <StarIcon isActive={false} fill="rgba(188, 32, 40, 1)" /> Favorited You!
        </StyledStatusBannerText>
      );
    }

    if (isCustomBackground) {
      return darkMode ? 'transparent' : 'rgba(255, 255, 255, 1)';
    }

    return '';
  };

  const profileData = profile.profile.data;

  const avatar =
    lazilyEvaluateVal(profileData, 3, false, 'avatar', 'data', 'urls') && renderAvatar(profileData.avatar.data.urls);

  const photoCount = parseInt(profileData.approved_public_photos_count);
  const privatePhotoCount = profileData.private_photos_count ? parseInt(profileData.private_photos_count) : 0;
  const userVerified = profileData.id_verified;

  const trackCardClickEvent = () => {
    trackProfileView({ Source: pageTitle, User: hashId });

    setMixpanelDataByKeyHandler({
      key: EVENT_NAMES.OFFER_MADE,
      payload: {
        source: user.isGenerous
          ? TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedRequest
          : TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedOffers,
      },
    });
    return true;
  };

  return (
    <ResultItem className="css-offers-list-item" backgroundColor={getStatusBanner(true)}>
      <Wrapper to={`/profile/${hashId}`} onClick={trackCardClickEvent}>
        <Avatar
          src={avatar}
          width="100px"
          alt="Avatar photo"
          customStyle={{
            ...spacing.MARGIN_RIGHT_MD,
            flex: '0 0 100px',
            height: 100,
            [dimensions.SCREEN_MAX_SM]: {
              flex: '0 0 65px',
              width: 65,
              height: 65,
              marginRight: 8,
            },
            border: isMatched ? '1px solid rgba(188, 32, 40, 1)' : '1px solid rgba(218, 225, 233, 1)',
          }}
        />
        <Details>
          <WrapTitle>
            <Title>{title}</Title>
            {auth10tixVerification && userVerified && (
              <VerifiedBadge data-test-id="data-verified-badge">
                <commonIcons.check.badge />
              </VerifiedBadge>
            )}
          </WrapTitle>
          <Meta>
            {profileData.online ? <OnlineDot /> : ''} {getInfoText(user, profileData)}
          </Meta>
          <PhotoCountDetails data-test-id="data-member-photo-count">
            <PhotoCount>
              <Icon data-test-id="data-camera-icon">
                <PublicPhotoIcon />
              </Icon>
              <StyledPublicPhotoCount>{photoCount}</StyledPublicPhotoCount>
              <Icon data-test-id="data-eye-hidden-icon">
                <PrivatePhotoIcon />
              </Icon>
              <StyledPhotoCount>{privatePhotoCount}</StyledPhotoCount>
            </PhotoCount>
          </PhotoCountDetails>
          <Timestamp>
            {isDesktop && getStatusBanner()}
            {tsSince(timestamp)}
          </Timestamp>
        </Details>
      </Wrapper>
      <OfferActionButton
        offer={offer}
        user={user}
        otherAccount={profile}
        avatar={avatar}
        ignoreUser={ignoreUser}
        createNewOffer={createNewOffer}
        sendSuggestion={sendSuggestion}
        clearErrors={clearErrors}
        errors={errors}
        showWarn={showWarn}
        acceptOffer={acceptOffer}
        counterOffer={counterOffer}
        isMatched={isMatched}
        offerKey={offerKey}
        viewType="list"
        isSuggestRequest={offer.data.suggest}
        ignoreOffer={ignoreOffer}
        ignoreOfferFromList={ignoreOfferFromList}
        unlockMessage={unlockMessage}
        updateOffer={updateOffer}
        pageTitle={pageTitle}
      />
    </ResultItem>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    resetUnreadOffersCountAction: () => {
      dispatch(resetUnreadOneTapOffersCount());
    },
  };
};

export default connect(null, mapDispatchToProps)(OfferProfileListView);
