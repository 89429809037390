import React, { useState, useEffect, ReactElement } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, dimensions, borders } from '../style';
import Threads from '../modules/mail/Threads';
import Conversation from '../modules/mail/Conversation';
import NoMessages from '../atoms/mail/NoMessages';
import ReminderModal from '../atoms/mail/ReminderModal';
import SkeletonLoader from '../atoms/SkeletonLoader';
import { disableBodyScroll, clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import { getBrowserDetails, tabletWidth, removeBodyScroll } from '../utils/helpers';
import MasterConfig from '../config/Master';
import TrackLoadingToMixpanel from '../components/common/TrackLoadingToMixPanel';
import PWAMessagePageBanner from '../components/pwa/PWAMessagePageBanner';
import Optional from '../atoms/Optional';

const setWidth = getBrowserDetails().isMobile && tabletWidth ? 280 : 360;

const MailContainer = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    maxWidth: 1400,
    margin: '0 auto',
    padding: '9px 15px',
  },
});

const InnerMailContainer = styled('div')({
  borderRadius: 3,
  backgroundColor: backgroundTheme.block,
  height: 'inherit',
  width: '100%',
  display: 'flex',
  ...borders.DEFAULT,
});

const ThreadsContainer = styled('div')(
  {
    width: '100%',

    [dimensions.SCREEN_MIN_MD]: {
      width: setWidth,
      height: `calc(${getBrowserDetails().isIpad && screen.height > 1024 ? '87vh' : '100vh'} - 80px)`,
      borderRight: borders.DEFAULT,
    },
  },
  ({ openConversation }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      display: openConversation ? 'none' : 'block',
    },
  })
);

const ConvoContainer = styled('div')(
  {
    [dimensions.SCREEN_MIN_MD]: {
      display: 'block',
      width: `calc(100% - ${setWidth}px)`,
    },
  },
  ({ hideConversation }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      display: hideConversation ? 'block' : 'none',
      top: 0,
      left: 0,
      width: '100%',
      backgroundColor: backgroundTheme.block,
      position: hideConversation ? 'relative' : 'fixed',
      paddingBottom: hideConversation ? '0px' : '100px',
    },
  }),
  ({ openConversation, showPwaInstallPromptBanner }) => ({
    [dimensions.SCREEN_MAX_MD]: {
      position: openConversation ? 'fixed' : 'absolute',
      marginTop: openConversation ? 0 : showPwaInstallPromptBanner ? 225 : 122,
      zIndex: openConversation ? 9999 : 995,
    },
  })
);

const isMessagingModalReminderEnabled = MasterConfig.messagingReminderEnabled;

interface MailProps {
  authUser: any;
  auth: any;
  openThreadHashId: any;
  threads: any;
  order: any;
  conversation: any;
  fetchingThreads: any;
  fetchingMoreThreads: any;
  fetchingConversation: any;
  isOtherAccountTyping: any;
  handleThreadChange: any;
  handleThreadClick: any;
  handleGoToProfile: any;
  handlePostMessage: any;
  handleFetchConversation: any;
  handleFetchThreads: any;
  handlePostPoke: any;
  handleUnlockOffer: any;
  handleToggleArchiveConversation: any;
  handleCancelOffer: any;
  handleBlockUser: any;
  handleCloseConversation: any;
  handlePostTypingStatus: any;
  handleGoToSearch: any;
  handleResendMessage: any;
  handleDeleteConversation: any;
  iceServers: any;
  videoInfo: any;
  uploadPhotoMessage: any;
  isUploading: any;
  handleDeleteImage: any;
  handleUnblurImage: any;
  handleReportImage: any;
  handleReminderStatus: any;
  reminderStatus: any;
  visitMessages: any;
  fetchPageNux: any;
  updatePageNux: any;
  showPwaInstallPromptBanner: boolean;
}

const Mail = ({
  authUser,
  auth,
  openThreadHashId,
  threads,
  order,
  conversation,
  fetchingThreads,
  fetchingMoreThreads,
  fetchingConversation,
  isOtherAccountTyping,
  handleThreadChange,
  handleThreadClick,
  handleGoToProfile,
  handlePostMessage,
  handleFetchConversation,
  handleFetchThreads,
  handlePostPoke,
  handleUnlockOffer,
  handleToggleArchiveConversation,
  handleCancelOffer,
  handleBlockUser,
  handleCloseConversation,
  handlePostTypingStatus,
  handleGoToSearch,
  handleResendMessage,
  handleDeleteConversation,
  iceServers,
  videoInfo,
  uploadPhotoMessage,
  isUploading,
  handleDeleteImage,
  handleUnblurImage,
  handleReportImage,
  handleReminderStatus,
  reminderStatus,
  visitMessages,
  fetchPageNux,
  updatePageNux,
  skipNuxGuides,
  showPwaInstallPromptBanner,
}: MailProps): ReactElement => {
  const [reminderPopup, setReminderPopup] = useState(reminderStatus);
  const deviceHeight = window.innerHeight;

  useEffect(() => {
    if (visitMessages) deviceHeight > 1024 && getBrowserDetails().isIpad && this.props.visitMessagesPage();
  }, [visitMessages, deviceHeight]);

  useEffect(() => {
    if (reminderPopup) {
      const modalOpen = document.getElementsByClassName('ReactModal__Body--open')[0];
      const overlayAfterOpen = document.getElementsByClassName('ReactModal__Overlay--after-open')[0];
      const modalContent = document.getElementsByClassName('ReactModal__Content ReactModal__Content--after-open')[0];

      setTimeout(() => {
        modalOpen && disableBodyScroll(modalOpen);
        overlayAfterOpen && disableBodyScroll(overlayAfterOpen);
        modalContent && enableBodyScroll(modalContent);
        if (document.getElementsByClassName('ReactModal__Body--open').length > 0)
          document.getElementsByClassName('ReactModal__Body--open')[0].style.overflowY = 'hidden';
      }, 1000);
    } else {
      clearAllBodyScrollLocks();
      removeBodyScroll();
    }
  }, [reminderPopup]);

  useEffect(() => {
    setReminderPopup(reminderStatus !== 0);
  }, [reminderStatus]);

  const pagination = _find(['meta', 'pagination'], threads);
  const canLoadMoreThreads = pagination.current_page < pagination.total_pages || false;
  const noThreads = threads?.collection && threads?.collection.length === 0;

  return (
    <MailContainer>
      <InnerMailContainer>
        <ThreadsContainer
          hideThreads={Boolean(conversation.id) || noThreads}
          openConversation={Boolean(conversation.id)}
        >
          <Threads
            order={order}
            fetching={fetchingThreads}
            fetchingMore={fetchingMoreThreads}
            openThreadHashId={openThreadHashId}
            canLoadMore={canLoadMoreThreads}
            onThreadChange={handleThreadChange}
            onThreadClick={handleThreadClick}
            onFetchThreads={handleFetchThreads}
            openConversation={Boolean(conversation.id)}
            showPwaInstallPromptBanner={showPwaInstallPromptBanner}
            {...threads}
          />
        </ThreadsContainer>
        <ConvoContainer
          hideConversation={Boolean(conversation.id) || noThreads}
          openConversation={Boolean(conversation.id)}
          showPwaInstallPromptBanner={showPwaInstallPromptBanner}
        >
          {conversation.id && (
            <div>
              <PWAMessagePageBanner customStyle={{ paddingTop: 20 }} />
              <Conversation
                onUnlockOffer={handleUnlockOffer}
                onPostMessage={handlePostMessage}
                onFetchConversation={handleFetchConversation}
                onPostPoke={handlePostPoke}
                onToggleArchiveConversation={handleToggleArchiveConversation}
                onCancelOffer={handleCancelOffer}
                onBlockUser={handleBlockUser}
                onCloseConversation={handleCloseConversation}
                onResendMessage={handleResendMessage}
                handlePostTypingStatus={handlePostTypingStatus}
                fetching={fetchingConversation}
                isOtherAccountTyping={isOtherAccountTyping}
                authUser={authUser}
                auth={auth}
                goToProfile={handleGoToProfile}
                onDeleteConversation={handleDeleteConversation}
                iceServers={iceServers}
                videoInfo={videoInfo}
                uploadPhotoMessage={uploadPhotoMessage}
                isUploading={isUploading}
                handleDeleteImage={handleDeleteImage}
                handleUnblurImage={handleUnblurImage}
                handleReportImage={handleReportImage}
                fetchPageNux={fetchPageNux}
                updatePageNux={updatePageNux}
                skipNuxGuides={skipNuxGuides}
                showPwaInstallPromptBanner={showPwaInstallPromptBanner}
                {...conversation}
              />
            </div>
          )}
          <Optional show={!fetchingThreads && (!threads.collection || !noThreads) && !conversation.id}>
            <SkeletonLoader width="100%" />
          </Optional>
          {(fetchingThreads || fetchingConversation) && <TrackLoadingToMixpanel page="Conversations" />}
          {!fetchingThreads && (!threads.collection || noThreads) && <NoMessages onClick={handleGoToSearch} />}
        </ConvoContainer>
      </InnerMailContainer>
      {isMessagingModalReminderEnabled && !fetchingConversation && !noThreads && (
        <ReminderModal
          acctType={(authUser && authUser.profile && authUser.profile.data.account_type) ?? null}
          reminderStatus={authUser.account_config?.message_reminder}
          handleReminderStatus={handleReminderStatus}
        />
      )}
    </MailContainer>
  );
};

export default Mail;
