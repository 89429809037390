import React from 'react';
import Button from '../../atoms/buttons/Button';

const styles = {
  width: 192,
  padding: '0px 22px',
  fontWeight: 600,
  marginBottom: 20,
  marginTop: 20,
};

interface IJoinButtonActionProps {
  isDisabled?: boolean;
  buttonType?: string;
  onClick?: (event: React.MouseEvent) => void;
  label: string;
  customStyle?: React.CSSProperties;
  type?: string;
}
const JoinActionButton: React.FC<IJoinButtonActionProps> = (props: IJoinButtonActionProps) => {
  const { isDisabled, onClick, label, buttonType = 'error', customStyle, type, ...rest } = props;
  return (
    <Button
      data-test-id="join-action-button"
      customStyle={{ ...styles, ...customStyle, ...{ opacity: isDisabled ? '0.7' : '1', fontSize: 14 } }}
      buttonType={buttonType}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default JoinActionButton;
