import React, { ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';
import BlankSlate from '../../blocks/BlankSlate';
import SkeletonLoader from '../../../atoms/SkeletonLoader';
import Button from '../../../atoms/buttons/Button';
import OfferProfileGridView from './OfferProfileGridView';
import OfferProfileListView from './OfferProfileListView';
import GridViewSlider, { SliderSource } from '../../common/GridViewSlider';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';

interface OfferListProps {
  activeRoute: any;
  isFetching: any;
  user: any;
  toggle: any;
  noResultsTitle: any;
  noResultsMsg: any;
  noResultsImg: any;
  offers: any;
  screenSizeIsMobile: any;
  viewType: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  updateOffer?: any;
  ignoreOfferFromList: any;
  unlockMessage(): void;
  pageTitle: any;
  enableSlider?: boolean;
  sliderLoadMore?: () => void;
  offerType: string;
}

const OfferList = ({
  activeRoute,
  isFetching,
  user,
  toggle,
  noResultsTitle,
  noResultsMsg,
  noResultsImg,
  offers,
  screenSizeIsMobile,
  viewType,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  updateOffer,
  ignoreOfferFromList,
  unlockMessage,
  pageTitle,
  enableSlider,
  sliderLoadMore = () => undefined,
  offerType,
}: OfferListProps): ReactElement => {
  return (
    <>
      {(isEmpty(offers) && isFetching && (
        <>
          <TrackLoadingToMixpanel page={`offers - ${offerType}`} />
          <SkeletonLoader width="100%" />
        </>
      ))}

      {!isFetching && isEmpty(offers) && (
        <BlankSlate
          img={noResultsImg}
          title={noResultsTitle}
          message={noResultsMsg}
          action={
            <Button buttonType="primary" to="/search">
              Find a date
            </Button>
          }
        />
      )}

      {!isEmpty(offers) && (
        <GridViewSlider
          source={SliderSource.OFFERS}
          enableSlider={enableSlider ?? false}
          sliderLoadMore={sliderLoadMore}
        >
          {offers.map((item, key) => {
            const member = item.other_account.data;
            const offer = {
              data: item,
            };

            const isFavoritedMe = item.favorited_me;
            const isMatched = member?.is_favorite?.favorited_me;

            return viewType === 'list' ? (
              <OfferProfileListView
                key={key}
                offerKey={key}
                profile={member}
                isFavorite={false}
                isFavoritedMe={isFavoritedMe}
                isMatched={isMatched}
                offer={offer}
                title={member.username}
                hashId={member.hash_id}
                toggle={toggle}
                screenSizeIsMobile={screenSizeIsMobile}
                timestamp={typeof item.created_at === 'string' ? item.created_at : item.created_at.date}
                user={user}
                createNewOffer={createNewOffer}
                sendSuggestion={sendSuggestion}
                clearErrors={clearErrors}
                errors={errors}
                showWarn={showWarn}
                acceptOffer={acceptOffer}
                counterOffer={counterOffer}
                ignoreOffer={ignoreOffer}
                ignoreOfferFromList={ignoreOfferFromList}
                unlockMessage={unlockMessage}
                updateOffer={updateOffer}
                pageTitle={pageTitle}
              />
            ) : (
              <OfferProfileGridView
                activeRoute={activeRoute}
                key={key}
                offerKey={key}
                profile={member}
                isFavorite={false}
                isFavoritedMe={isFavoritedMe}
                isMatched={isMatched}
                offer={offer}
                title={member.username}
                hashId={member.hash_id}
                toggle={toggle}
                screenSizeIsMobile={screenSizeIsMobile}
                timestamp={typeof item.created_at === 'string' ? item.created_at : item.created_at.date}
                user={user}
                createNewOffer={createNewOffer}
                sendSuggestion={sendSuggestion}
                clearErrors={clearErrors}
                errors={errors}
                showWarn={showWarn}
                acceptOffer={acceptOffer}
                counterOffer={counterOffer}
                ignoreOffer={ignoreOffer}
                ignoreOfferFromList={ignoreOfferFromList}
                unlockMessage={unlockMessage}
                updateOffer={updateOffer}
                pageTitle={pageTitle}
                enableSlider={enableSlider}
              />
            );
          })}
        </GridViewSlider>
      )}
    </>
  );
};

export default OfferList;
