import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../theme/colors';
import DashboardDiscoverModeCrossIcon from '../icons/DashboardDiscoverModeCrossIcon';
import { theme } from '../../../../theme';
import { dimensions } from '../../../../style';

const ContainerWrapper = styled('div')(({ topPosition, isVisible }) => ({
  display: 'inline-flex',
  padding: '11px 20px',
  alignItems: 'center',
  gap: 5,
  borderRadius: 15,
  border: `3px solid ${colors[theme].red.primary}`,
  background: white,
  position: 'absolute',
  left: 10,
  top: topPosition,
  opacity: isVisible ? 0.8 : 1,
  transition: 'top 0.5s ease-in-out, opacity 0.5s ease-in-out',
  [dimensions.SCREEN_MIN_MD]: {
    left: 20,
  },
}));

const PassTextWrapper = styled('div')({
  color: colors[theme].red.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
});

interface Props {
  onHidden: () => void;
}

const DashboardDiscoverModePassFlag: React.FC<Props> = ({ onHidden }) => {
  const [topPosition, setTopPosition] = useState(30);
  const [isVisible, setIsVisible] = useState(true);
  const hideTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setTopPosition(10);
      setIsVisible(false);

      // Additional delay before hiding (adjust as needed)
      hideTimeoutRef.current = window.setTimeout(() => {
        onHidden();
      }, 800);
    }, 0);

    return () => {
      clearTimeout(animationTimeout);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [onHidden]);

  return (
    <ContainerWrapper topPosition={topPosition} isVisible={isVisible}>
      <DashboardDiscoverModeCrossIcon />
      <PassTextWrapper>NOPE!</PassTextWrapper>
    </ContainerWrapper>
  );
};

export default DashboardDiscoverModePassFlag;
