import React, { ReactElement } from 'react';

interface GiftIconProps {
  fillColor?: string;
}

const GiftIcon = ({ fillColor = '#BC2028' }: GiftIconProps): ReactElement => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_573_3501)">
        <path
          d="M19.0714 4.23009H19.0031C19.293 3.4471 19.3048 2.6353 19.0065 1.89722C18.5642 0.802387 17.5388 0.0770183 16.3322 0.00668471C14.3362 -0.112798 12.598 1.41166 11.565 2.74376C11.249 2.56412 10.8884 2.45311 10.5 2.45311C10.1116 2.45311 9.74843 2.56496 9.43079 2.74631C8.39697 1.41336 6.65286 -0.113645 4.66273 0.00668471C3.45534 0.0778657 2.42995 0.802387 1.9876 1.89722C1.68934 2.6353 1.70113 3.4471 1.99181 4.23009H1.92862C0.86531 4.23009 0 5.10036 0 6.16977V8.35351C0 9.3314 0.726288 10.1339 1.66322 10.2652V19.29C1.66322 20.2323 2.42573 21 3.3635 21H17.6365C18.5734 21 19.3368 20.2331 19.3368 19.29V10.2652C20.2737 10.1339 21 9.3314 21 8.35351V6.16977C21 5.10036 20.1347 4.23009 19.0714 4.23009V4.23009ZM8.31777 8.57976H1.92862C1.80477 8.57976 1.70282 8.47807 1.70282 8.35351V6.16977C1.70282 6.04521 1.80392 5.94267 1.92862 5.94267H8.31777V8.57976ZM8.35989 4.23009H3.91033C3.4882 3.65047 3.36013 3.04883 3.56488 2.54124C3.75782 2.06416 4.21618 1.74723 4.76216 1.71503C6.15575 1.62605 7.60075 3.04798 8.36832 4.18433C8.36495 4.19959 8.36242 4.21484 8.35905 4.23009H8.35989ZM12.63 4.17925C13.3984 3.04374 14.8333 1.62775 16.2328 1.71588C16.7788 1.74808 17.2371 2.065 17.4301 2.54208C17.6357 3.04967 17.5076 3.65132 17.0855 4.23094H12.6409C12.6376 4.21399 12.6342 4.19704 12.63 4.1801V4.17925ZM19.298 8.35351C19.298 8.47807 19.1969 8.57976 19.0722 8.57976H12.6831V5.94267H19.0722C19.1961 5.94267 19.298 6.04436 19.298 6.16977V8.35351Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_573_3501">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GiftIcon;
