import React, { ReactElement } from 'react';

const OfferAcceptIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <g filter="url(#filter0_d_4326_1299)">
        <circle cx="32" cy="29.9995" r="27.5" fill="#24A83A" />
        <circle cx="32" cy="29.9995" r="26.5" stroke="#5EBE5E" strokeWidth="2" />
      </g>
      <path
        d="M27.4253 37.635L20.8228 31.0325L18.5745 33.265L27.4253 42.1158L46.4253 23.1158L44.1928 20.8833L27.4253 37.635Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4326_1299"
          x="0.5"
          y="0.499512"
          width="63"
          height="63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4326_1299" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4326_1299" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default OfferAcceptIcon;
