import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import CloseIcon from '../../atoms/icons/CloseIcon';
import { backgroundTheme, textColor } from '../../style';
import { useDisableBodyElementScroll } from '../../hooks/useDisableBodyScroll';

ReactModal.setAppElement('body');

const setModalStyle = customStyle => {
  const customOverlay = customStyle.overlay || {};
  const customContent = customStyle.content || {};

  return {
    overlay: {
      zIndex: 8000,
      backgroundColor: 'rgba(0,0,0,0.65)',
      overflowY: 'auto',
      ...customOverlay,
    },
    content: {
      maxWidth: '520px',
      transform: 'scale(1)',
      margin: '0 auto',
      outline: 'none',
      top: '82px',
      bottom: 'initial',
      ':focus': {
        outline: 'none',
      },
      backgroundColor: backgroundTheme.block,
      ...customContent,
      ...textColor.DARK,
    },
  };
};

const Content = styled('div')({
  position: 'relative',
  minHeight: '75px',
});

const IconContainer = styled('div')({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  fontSize: '28px',
  lineHeight: '16px',
  '&:hover': {
    cursor: 'pointer',
  },
});

interface BasicModalProps {
  isOpen: boolean;
  closeOnOverlayClick?: boolean;
  onClose: () => void;
  hideClose?: boolean;
  customStyle?: Record<string, any>;
  children?: React.ReactNode;
}

const BasicModal = ({
  closeOnOverlayClick,
  onClose,
  hideClose,
  customStyle,
  children,
  ...rest
}: BasicModalProps): ReactElement => {
  useDisableBodyElementScroll(rest.isOpen);

  return (
    <ReactModal
      style={setModalStyle(customStyle)}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={onClose}
      {...rest}
    >
      <Content>
        {!hideClose && (
          <IconContainer onClick={onClose}>
            <CloseIcon />
          </IconContainer>
        )}
        {children}
      </Content>
    </ReactModal>
  );
};

BasicModal.propTypes = {
  /* Name of modal - Required by react-modal for accessibility*/
  contentLabel: PropTypes.string,
  /* Bool to determine if modal is open */
  isOpen: PropTypes.bool.isRequired,
  /* Bool to determine if modal will close on clicking overlay */
  closeOnOverlayClick: PropTypes.bool,
  /* Function to handle closing modal; called on clicking close button or overlay click (optional)*/
  onClose: PropTypes.func.isRequired,
  /* Bool to determine if close icon on top right of modal should be hidden */
  hideClose: PropTypes.bool,
  customStyle: PropTypes.object,
  children: PropTypes.node,
};

BasicModal.defaultProps = {
  closeOnOverlayClick: false,
  customStyle: {},
};

export default BasicModal;
