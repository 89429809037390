import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';
import { MixpanelTrackEvent } from '../utils/mixpanel';

const api = MasterConfig.api;

const mixpanelTrack = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/mixpanel/track`,
    data: formData,
  });
};

const mixpanelTrackEvent = (formData: MixpanelTrackEvent): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/mixpanel/track-event`,
    data: formData,
  });
};

export { mixpanelTrack, mixpanelTrackEvent };
