import React from 'react';
import StarFillIcon from '../icons/StarFillIcon';
import StarV2Icon from '../icons/StarV2Icon';

interface Props {
  action: 'favorited' | 'unfavorited';
}
const FavoritedProfileNotificationBanner: React.FC<Props> = ({ action }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 11,
      }}
    >
      {action === 'favorited' ? (
        <>
          <StarFillIcon cssStyle={{ marginRight: 6 }} />
          <span>Profile Favorited!</span>
        </>
      ) : (
        <>
          <StarV2Icon width={16} height={15} cssStyle={{ marginRight: 6 }} />
          <span>Profile removed from Favorites</span>
        </>
      )}
    </div>
  );
};

export default FavoritedProfileNotificationBanner;
