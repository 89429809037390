import React from 'react';

interface IGooglePayIcon {
  clean?: boolean;
  height?: any;
  width?: any;
  viewBox?: any;
  customStyle?: React.CSSProperties;
  darkmode?: boolean;
}

const GooglePayIcon: React.FC<IGooglePayIcon> = ({
  clean = false,
  width = 68,
  height = 36,
  customStyle = {},
  darkmode = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
    >
      <g clipPath="url(#clip0_3773_9913)">
        {!clean && (
          <>
            <path
              d="M49.68 0H18C8.1 0 0 8.1 0 18C0 27.9 8.1 36 18 36H49.68C59.58 36 67.68 27.9 67.68 18C67.68 8.1 59.58 0 49.68 0Z"
              fill={darkmode ? 'black' : 'white'}
            />
            <path
              d="M49.68 1.458C51.903 1.458 54.063 1.899 56.097 2.763C58.068 3.6 59.832 4.797 61.362 6.318C62.883 7.839 64.08 9.612 64.917 11.583C65.781 13.617 66.222 15.777 66.222 18C66.222 20.223 65.781 22.383 64.917 24.417C64.08 26.388 62.883 28.152 61.362 29.682C59.841 31.203 58.068 32.4 56.097 33.237C54.063 34.101 51.903 34.542 49.68 34.542H18C15.777 34.542 13.617 34.101 11.583 33.237C9.612 32.4 7.848 31.203 6.318 29.682C4.797 28.161 3.6 26.388 2.763 24.417C1.899 22.383 1.458 20.223 1.458 18C1.458 15.777 1.899 13.617 2.763 11.583C3.6 9.612 4.797 7.848 6.318 6.318C7.839 4.797 9.612 3.6 11.583 2.763C13.617 1.899 15.777 1.458 18 1.458H49.68ZM49.68 0H18C8.1 0 0 8.1 0 18C0 27.9 8.1 36 18 36H49.68C59.58 36 67.68 27.9 67.68 18C67.68 8.1 59.58 0 49.68 0Z"
              fill={darkmode ? 'white' : '#3C4043'}
            />
          </>
        )}

        <path
          d="M32.2739 19.2783V24.7233H30.5459V11.2773H35.1269C36.2879 11.2773 37.2779 11.6643 38.0879 12.4383C38.9159 13.2123 39.3299 14.1573 39.3299 15.2733C39.3299 16.3893 38.9159 17.3613 38.0879 18.1263C37.2869 18.8913 36.2969 19.2693 35.1269 19.2693H32.2739V19.2783ZM32.2739 12.9333V17.6223H35.1629C35.8469 17.6223 36.4229 17.3883 36.8729 16.9293C37.3319 16.4703 37.5659 15.9123 37.5659 15.2823C37.5659 14.6523 37.3319 14.1123 36.8729 13.6533C36.4229 13.1763 35.8559 12.9423 35.1629 12.9423H32.2739V12.9333Z"
          fill={darkmode ? 'white' : '#3C4043'}
        />
        <path
          d="M43.848 15.2188C45.126 15.2188 46.134 15.5607 46.872 16.2447C47.61 16.9287 47.979 17.8647 47.979 19.0527V24.7228H46.332V23.4447H46.26C45.549 24.4977 44.595 25.0197 43.407 25.0197C42.39 25.0197 41.544 24.7227 40.86 24.1198C40.176 23.5167 39.834 22.7697 39.834 21.8697C39.834 20.9157 40.194 20.1597 40.914 19.6017C41.634 19.0347 42.597 18.7557 43.794 18.7557C44.82 18.7557 45.666 18.9447 46.323 19.3227V18.9267C46.323 18.3237 46.089 17.8197 45.612 17.3967C45.135 16.9737 44.577 16.7667 43.938 16.7667C42.975 16.7667 42.21 17.1717 41.652 17.9907L40.131 17.0367C40.968 15.8217 42.21 15.2188 43.848 15.2188ZM41.616 21.8967C41.616 22.3467 41.805 22.7248 42.192 23.0217C42.57 23.3188 43.02 23.4717 43.533 23.4717C44.262 23.4717 44.91 23.2018 45.477 22.6617C46.044 22.1217 46.332 21.4917 46.332 20.7627C45.792 20.3397 45.045 20.1238 44.082 20.1238C43.38 20.1238 42.795 20.2947 42.327 20.6277C41.85 20.9787 41.616 21.4018 41.616 21.8967Z"
          fill={darkmode ? 'white' : '#3C4043'}
        />
        <path
          d="M57.375 15.5156L51.615 28.7636H49.833L51.975 24.1286L48.177 15.5156H50.058L52.794 22.1216H52.83L55.494 15.5156H57.375Z"
          fill={darkmode ? 'white' : '#3C4043'}
        />
        <path
          d="M25.4006 18.1814C25.4006 17.618 25.3502 17.0789 25.2566 16.5605H18.0134V19.5305H22.1849C22.0157 20.5196 21.4712 21.362 20.6369 21.9236V23.8505H23.12C24.5699 22.5086 25.4006 20.525 25.4006 18.1814Z"
          fill="#4285F4"
        />
        <path
          d="M20.6378 21.924C19.9466 22.3902 19.0565 22.6629 18.0152 22.6629C16.0037 22.6629 14.2973 21.3075 13.6862 19.4805H11.1248V21.4677C12.3938 23.9859 15.002 25.7139 18.0152 25.7139C20.0978 25.7139 21.8474 25.029 23.1209 23.85L20.6378 21.924Z"
          fill="#34A853"
        />
        <path
          d="M13.445 18.0053C13.445 17.4923 13.5305 16.9964 13.6862 16.5302V14.543H11.1248C10.6001 15.5843 10.3049 16.7597 10.3049 18.0053C10.3049 19.2509 10.601 20.4263 11.1248 21.4676L13.6862 19.4804C13.5305 19.0142 13.445 18.5183 13.445 18.0053Z"
          fill="#FABB05"
        />
        <path
          d="M18.0152 13.3479C19.1519 13.3479 20.1698 13.7394 20.9735 14.5044L23.174 12.3057C21.8375 11.061 20.0951 10.2969 18.0152 10.2969C15.0029 10.2969 12.3938 12.0249 11.1248 14.5431L13.6862 16.5303C14.2973 14.7033 16.0037 13.3479 18.0152 13.3479Z"
          fill="#E94235"
        />
      </g>
      <defs>
        <clipPath id="clip0_3773_9913">
          <rect width="67.68" height="36" fill={darkmode ? 'black' : 'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GooglePayIcon;
