import { DEFAULT_REASON, EvidentlyFeatureExperiment } from '../../constants/evidently';
import { AccountMaker } from '../../models/Account';
import { TrackEvidentlyMetricsParams, trackEvidentlyMultipleMetrics } from '../../sdk/CommonSDK';
import store from '../../store';
import { getBrowserDetails } from '../helpers';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

export const ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME = '1-TapOfferManagement';
export const ONE_TAP_OFFER_MANAGEMENT_EXPERIMENT_NAME = 'NewReceivedOffersPageTest';
export const ONE_TAP_OFFER_MANAGEMENT_LOCAL_STORAGE_KEY = 'evidently_one_tap_offer';

export type OneTapOfferManagementVariations = 'Control' | '1-TapOfferManagement';

export type OneTapOfferManagementFeatureMetricKeys =
  | 'oneTapOfferEngagement'
  | 'oneTapAcceptedOffers'
  | 'oneTapUnlockedConversations';

export const oneTapOfferTrackMetric = async (keys: OneTapOfferManagementFeatureMetricKeys[]): Promise<void> => {
  const accountModel = AccountMaker.create(store.getState().profile);
  const metrics = keys.map(key => {
    return {
      key,
      value: 1,
      featureName: ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
      experimentName: ONE_TAP_OFFER_MANAGEMENT_EXPERIMENT_NAME,
      hashId: accountModel.getData().hash_id,
      shouldAddUpValue: true,
    };
  });

  await trackEvidentlyMultipleMetrics<OneTapOfferManagementFeatureMetricKeys>(metrics);
};
/**
 * This is only used in batchEvaluation
 *
 * @returns boolean
 */
const handleIsFeatureEvaluate = (): boolean => {
  return getBrowserDetails().isMobile;
};

const handleFeatureCallback = async (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled: boolean
): Promise<void> => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  mixpanelTrackExperimentStarted({
    'Experiment name': 'New Received Offers Page: 1-Tap',
    'Variant name': details.variation === 'Control' ? 'Control' : '1-Tap Offer Management',
  });
};

const featureMetrics = (
  details: EvidentlyFeatureExperiment
): TrackEvidentlyMetricsParams<OneTapOfferManagementFeatureMetricKeys>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  const metrics: TrackEvidentlyMetricsParams<OneTapOfferManagementFeatureMetricKeys>[] = [
    {
      featureName: ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
      experimentName: ONE_TAP_OFFER_MANAGEMENT_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'oneTapOfferEngagement',
      value: 0,
    },
    {
      featureName: ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
      experimentName: ONE_TAP_OFFER_MANAGEMENT_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'oneTapAcceptedOffers',
      value: 0,
    },
  ];

  const accountModel = AccountMaker.create(store.getState().profile);

  if (accountModel.getProfile().isGenerous()) {
    metrics.push({
      featureName: ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
      experimentName: ONE_TAP_OFFER_MANAGEMENT_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'oneTapUnlockedConversations',
      value: 0,
    });
  }

  return metrics;
};

export const OneTapOfferManagementEvaluation = [
  {
    featureMetrics,
    featureName: ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
