import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, shadowTheme } from '../../../style';
import { tabletWidth, getBrowserDetails, iOSwithHomeBtn, ipadNoHomeButton } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
import WYPIcon from '../../../atoms/icons/WYPIcon';
import FDIcon from '../../../atoms/icons/FDIcon';
import { isFD } from '../../../config/Master';
import { useApp } from '../../../contexts/AppContext';
import RedLine from '../../../atoms/icons/RedLine';

const bottomLength = homeBtn => (homeBtn ? 40 : 10);

const IconWypContainer = styled('div')({
  position: 'fixed',
  bottom:
    getBrowserDetails().isIos && getBrowserDetails().isStandaloneMode && !tabletWidth
      ? bottomLength(!iOSwithHomeBtn())
      : bottomLength(ipadNoHomeButton),
  left: '50%',
  width: 57,
  height: 52,
  padding: 10,
  borderRadius: '50%',
  background: backgroundTheme.personalInfo,
  boxShadow: shadowTheme.footerLogo,
  transform: 'translate(-50%,0)',
  textAlign: 'center',
});

const DiscoverTabStyle = styled('div')(
  {
    fontSize: 13,
    paddingTop: 2,
  },
  ({ active }) => ({
    fontWeight: active ? 700 : 400,
    marginBottom: '-12px',
  })
);

interface footerLogoProps {
  currentPath: string;
  activePathPattern: any;
}

const FooterWYPLogo = (props: footerLogoProps): ReactElement => {
  const { currentPath, activePathPattern } = props;
  const { siteFooterLogoRef } = useApp();
  const isActive = activePathPattern?.test(currentPath);

  return (
    <Link to="/dashboard">
      <IconWypContainer id="IconWypContainer" ref={siteFooterLogoRef}>
        {isFD ? <FDIcon /> : <WYPIcon />}
        <DiscoverTabStyle active={isActive}>Discover</DiscoverTabStyle>
        {isActive && <RedLine customStyle={{ width: '100%' }} />}
      </IconWypContainer>
    </Link>
  );
};

export default FooterWYPLogo;
