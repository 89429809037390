import React, { ReactElement } from 'react';
interface IRoundCheckIconProps {
  customStyle?: React.CSSProperties;
  customFill?: any;
}

const RoundCheckIcon = ({ customStyle, customFill = '#2B8FD7' }: IRoundCheckIconProps): ReactElement => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" style={customStyle}>
      <path
        d="M15.5283 20.8448L12.1141 17.4306C11.7723 17.0888 11.3494 16.9179 10.8454 16.9179C10.3414 16.9179 9.90523 17.0981 9.53679 17.4584C9.19498 17.8106 9.0254 18.2382 9.02804 18.741C9.03065 19.2438 9.20808 19.6661 9.56033 20.0079L14.2185 24.6939C14.592 25.0606 15.0276 25.244 15.5255 25.244C16.0234 25.244 16.4609 25.0606 16.8381 24.6939L26.3097 15.2066C26.6805 14.8463 26.8659 14.4181 26.8659 13.9219C26.8659 13.4258 26.6805 12.9935 26.3097 12.625C25.9494 12.2832 25.5127 12.117 24.9994 12.1262C24.4862 12.1355 24.0546 12.311 23.7047 12.6528L15.5283 20.8448ZM18.0021 35.3262C15.609 35.3262 13.3593 34.8736 11.2532 33.9685C9.14716 33.0634 7.30995 31.8267 5.74162 30.2584C4.17329 28.69 2.93658 26.8536 2.0315 24.7489C1.12638 22.6442 0.673828 20.3953 0.673828 18.0022C0.673828 15.5905 1.12638 13.3317 2.0315 11.2256C2.93658 9.11949 4.17247 7.28663 5.73916 5.72699C7.30586 4.16738 9.14191 2.93268 11.2473 2.02287C13.3527 1.11309 15.6025 0.658203 17.9965 0.658203C20.409 0.658203 22.669 1.11269 24.7763 2.02166C26.8836 2.93063 28.7167 4.16424 30.2755 5.72249C31.8344 7.28072 33.0685 9.11308 33.9778 11.2196C34.8871 13.3261 35.3418 15.5863 35.3418 18C35.3418 20.3946 34.8869 22.6449 33.9771 24.7508C33.0673 26.8567 31.8326 28.6932 30.273 30.2603C28.7134 31.8273 26.8812 33.0634 24.7766 33.9685C22.6719 34.8736 20.4138 35.3262 18.0021 35.3262Z"
        fill={customFill}
      />
    </svg>
  );
};

export default RoundCheckIcon;
