import React, { ReactElement } from 'react';
interface IPrivateMonkeyIcon {
  style?: React.CSSProperties;
}

const PrivateMonkeyIcon = ({ ...rest }: IPrivateMonkeyIcon): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none" {...rest}>
      <path
        d="M23.0727 27L17.9182 21.9697C17.2023 22.1919 16.4815 22.3588 15.7557 22.4703C15.0292 22.581 14.2773 22.6364 13.5 22.6364C10.4114 22.6364 7.66023 21.7931 5.24659 20.1067C2.83295 18.4194 1.08409 16.2323 0 13.5455C0.429545 12.4747 0.971591 11.4796 1.62614 10.56C2.28068 9.64121 3.02727 8.81818 3.86591 8.09091L0.490909 4.69697L2.20909 3L24.7909 25.303L23.0727 27ZM13.5 19C13.725 19 13.9345 18.9899 14.1284 18.9697C14.3231 18.9495 14.533 18.9091 14.758 18.8485L8.13068 12.303C8.06932 12.5253 8.02841 12.7325 8.00795 12.9248C7.9875 13.1164 7.97727 13.3232 7.97727 13.5455C7.97727 15.0606 8.514 16.3487 9.58745 17.4097C10.6617 18.4699 11.9659 19 13.5 19ZM22.4591 19.5455L18.5625 15.7273C18.7057 15.3838 18.8182 15.0356 18.9 14.6824C18.9818 14.3285 19.0227 13.9495 19.0227 13.5455C19.0227 12.0303 18.486 10.7422 17.4125 9.68121C16.3383 8.62101 15.0341 8.09091 13.5 8.09091C13.0909 8.09091 12.7072 8.13131 12.3488 8.21212C11.9913 8.29293 11.6386 8.41414 11.2909 8.57576L8.16136 5.48485C9 5.14141 9.85909 4.88364 10.7386 4.71152C11.6182 4.5402 12.5386 4.45455 13.5 4.45455C16.5886 4.45455 19.3398 5.29778 21.7534 6.98424C24.167 8.67151 25.9159 10.8586 27 13.5455C26.5295 14.7374 25.911 15.8436 25.1444 16.8642C24.3769 17.884 23.4818 18.7778 22.4591 19.5455ZM16.783 13.9697L13.1011 10.3333C13.6739 10.2323 14.2008 10.2776 14.6819 10.4691C15.1621 10.6614 15.5761 10.9394 15.9239 11.303C16.2716 11.6667 16.5224 12.0861 16.6762 12.5612C16.8292 13.0356 16.8648 13.505 16.783 13.9697Z"
        fill="#CD585E"
      />
    </svg>
  );
};

export default PrivateMonkeyIcon;
