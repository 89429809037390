import React, { FC } from 'react';
import styled from '@emotion/styled';

import { textColor } from '../../style';
import Button from '../../atoms/buttons/Button';
import GalleryModal from '../../modules/modals/GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import IconExclamationPointCircle from '../../atoms/icons/IconExclamationPointCircle';
import colors from '../../theme/colors';
import { PhotoConfig } from '../../config/Media';

const ModalHeader = styled('h3')(() => ({
  marginTop: 10,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '31px',
  textAlign: 'left',
  paddingRight: 30,
  marginBottom: 0,
  ...textColor.DARKEST,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: '20px',
    marginTop: 20,
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }: { hasHeader: boolean }) => !hasHeader && { marginTop: 0 }
);

interface InvalidFilesErrorModalProps {
  onClose(): void;
  isOpen: boolean;
  alwaysDisabledBodyScroll?: boolean;
  mediaType: string;
  invalidFiles: File[];
  validFiles: File[];
  hasMultipleFileSelected?: boolean;
}

const InvalidFilesErrorModal: FC<InvalidFilesErrorModalProps> = ({
  onClose,
  isOpen,
  alwaysDisabledBodyScroll = false,
  invalidFiles,
  mediaType,
  validFiles,
  hasMultipleFileSelected,
}) => {
  useDisableBodyScroll(isOpen || alwaysDisabledBodyScroll);

  const detailsErrorMessage = () => {
    if (invalidFiles.length === 1) {
      return (
        <p>
          Your {mediaType === 'photo' ? 'image' : 'video'} could not be uploaded because it exceeds{' '}
          {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
        </p>
      );
    }

    // multiple files selected
    // and has no valid files
    if (validFiles.length < 1 && hasMultipleFileSelected) {
      return (
        <p>
          Your {mediaType === 'photo' ? 'images' : 'videos'} could not be uploaded because they exceed{' '}
          {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
        </p>
      );
    }

    // multiple files selected
    // and has multiple invalid files
    return (
      <p>
        Some {mediaType === 'photo' ? 'images' : 'videos'} could not be uploaded because they exceed{' '}
        {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
      </p>
    );
  };

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{
        overlay: {
          zIndex: 80000,
        },
        content: {
          borderColor: '#DD0B24',
          backgroundColor: '#FFF8F8',
          padding: 20,
          maxWidth: 335,
          left: 0,
          right: 0,
        },
      }}
      contentLabel=""
      isOpen={isOpen}
    >
      <ModalDescription hasHeader={true} data-test-id="unsupported-file-type">
        <IconExclamationPointCircle
          customStyle={{
            width: 40,
            height: 40,
          }}
          fill={colors.dark.notifications.yellow}
        />
        <ModalHeader>Exceeded Max File Size</ModalHeader>
        {detailsErrorMessage()}
        {mediaType === 'photo' ? (
          <p>
            Images must be <strong>{PhotoConfig.allowedMimeTypeText}</strong> and less than <strong>15 MB</strong>.
          </p>
        ) : (
          <p>
            Videos must be <strong>MP4, AVI, MOV, WMV, WebM, MKV, AVCHD</strong> and less than <strong>75 Mb</strong>{' '}
            and <strong>15 seconds</strong>.
          </p>
        )}

        <div style={{ marginTop: 5 }} data-test-id="always-show-agreement"></div>
        <Button
          data-test-id={`upload-another`}
          onClick={onClose}
          customStyle={{
            borderColor: '#2B8FD7',
            backgroundColor: '#2B8FD7',
            color: '#FFFFFF',
            fontWeight: 600,
            width: 'fit-content',
            padding: '12px 22px',
            margin: '0 auto',
          }}
        >
          OK
        </Button>
      </ModalDescription>
    </GalleryModal>
  );
};

const MemoizedInvalidFilesErrorModal = React.memo(InvalidFilesErrorModal);

export default MemoizedInvalidFilesErrorModal;
