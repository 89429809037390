export const STORE_BLOCKED = 'STORE_BLOCKED';
export const STORE_NEW_BLOCKED_USER = 'STORE_NEW_BLOCKED_USER';

export interface Blocked {
    data:  unknown[];
    meta: { pagination: number; }
}

export interface BlockedState {
    howManyPagesLoaded: number;
    blockedItems: unknown[];
}

interface STORE_BLOCKED_ACTION {
    type: typeof STORE_BLOCKED;
    payload: Blocked;
}

interface STORE_NEW_BLOCKED_USER_ACTION {
    type: typeof STORE_NEW_BLOCKED_USER;
    payload: Blocked;
}

export type BlockedActionType = 
    | STORE_BLOCKED_ACTION
    | STORE_NEW_BLOCKED_USER_ACTION;


