export const STORE_ERRORS = 'STORE_ERRORS';
export const STORE_SPECIFIC_ERRORS = 'STORE_SPECIFIC_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_INDIVIDUAL_ERROR = 'CLEAR_INDIVIDUAL_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const IS_FETCHING = 'IS_FETCHING';
export const SET_PORTRAIT = 'SET_PORTRAIT';
export const KILL_IS_FETCHING = 'KILL_IS_FETCHING';
export const SET_HEADER_HIDDEN = 'SET_HEADER_HIDDEN';
export const CLEAR_DIRTY = 'CLEAR_DIRTY';
export const FORGOT_PASSWORD_SENDING = 'FORGOT_PASSWORD_SENDING';
export const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
export const UPDATING_VARIANTS = 'UPDATING_VARIANTS';
export const REVERT_LAST_UPDATE = 'REVERT_LAST_UPDATE';
export const VISIT_MESSAGES = 'VISIT_MESSAGES';
export const STORE_CURR_PAGE = 'STORE_CURR_PAGE';

export interface Error {
  errors: Record<string, unknown>;
}

export interface Portrait {
  portrait: boolean;
}

export interface HeaderHidden {
  headerHidden: boolean;
}

export interface IsSent {
  emailSent: boolean;
}

export interface Status {
  emailStatus: string;
}

export interface LastUpdate {
  lastUpdate: string | null;
}

export interface TestVariants {
  variants: Record<string, unknown>;
}

export type EvidentlyFeatureDetails = {
  reason: string;
  userId: string;
  value: { stringValue: string };
  variation: string;
};

export interface CommonState extends Error, Portrait, HeaderHidden, IsSent, Status, LastUpdate, TestVariants {
  isFetching: boolean;
  modalOpen: boolean;
  isDirty: boolean;
  updatingSettings: boolean;
  visitMessages: boolean;
  currPage: unknown[];
  prevPage: unknown[];
  featureVariant: {
    NewGoogleSSO: EvidentlyFeatureDetails;
  };
}

interface STORE_ERRORS_ACTION {
  type: typeof STORE_ERRORS;
  payload: Error;
}

interface STORE_SPECIFIC_ERRORS_ACTION {
  type: typeof STORE_SPECIFIC_ERRORS;
  payload: Error;
  name: string;
}

interface CLEAR_ERRORS_ACTION {
  type: typeof CLEAR_ERRORS;
}

interface CLEAR_INDIVIDUAL_ERROR_ACTION {
  type: typeof CLEAR_INDIVIDUAL_ERROR;
  payload: string;
}

interface REMOVE_ERROR_ACTION {
  type: typeof REMOVE_ERROR;
  payload: string;
}

interface IS_FETCHING_ACTION {
  type: typeof IS_FETCHING;
}

interface SET_PORTRAIT_ACTION {
  type: typeof SET_PORTRAIT;
  payload: Portrait;
}

interface KILL_IS_FETCHING_ACTION {
  type: typeof KILL_IS_FETCHING;
}

interface SET_HEADER_HIDDEN_ACTION {
  type: typeof SET_HEADER_HIDDEN;
  payload: HeaderHidden;
}

interface CLEAR_DIRTY_ACTION {
  type: typeof CLEAR_DIRTY;
}

interface FORGOT_PASSWORD_SENDING_ACTION {
  type: typeof FORGOT_PASSWORD_SENDING;
  isSent: IsSent;
  status: Status;
}

interface UPDATING_SETTINGS_ACTION {
  type: typeof UPDATING_SETTINGS;
  payload: LastUpdate;
}

interface UPDATING_VARIANT_ACTION {
  type: typeof UPDATING_VARIANTS;
  payload: TestVariants;
}

interface REVERT_LAST_UPDATE_ACTION {
  type: typeof REVERT_LAST_UPDATE;
}

interface VISIT_MESSAGES_ACTION {
  type: typeof VISIT_MESSAGES;
}

interface STORE_CURR_PAGE_ACTION {
  type: typeof STORE_CURR_PAGE;
}

export type CommonActionType =
  | STORE_ERRORS_ACTION
  | STORE_SPECIFIC_ERRORS_ACTION
  | CLEAR_ERRORS_ACTION
  | CLEAR_INDIVIDUAL_ERROR_ACTION
  | REMOVE_ERROR_ACTION
  | IS_FETCHING_ACTION
  | SET_PORTRAIT_ACTION
  | KILL_IS_FETCHING_ACTION
  | SET_HEADER_HIDDEN_ACTION
  | CLEAR_DIRTY_ACTION
  | FORGOT_PASSWORD_SENDING_ACTION
  | UPDATING_SETTINGS_ACTION
  | REVERT_LAST_UPDATE_ACTION
  | VISIT_MESSAGES_ACTION
  | STORE_CURR_PAGE_ACTION
  | UPDATING_VARIANT_ACTION;
