import React, { useState, useRef, ReactElement, useEffect } from 'react';
import MasterConfig from '../../../config/Master';
import styled from '@emotion/styled';
import { css } from 'glamor';
import Button from '../../../atoms/buttons/Button';
import Loader from '../../../atoms/LoaderStyled';
import { dimensions, backgroundTheme } from '../../../style';
import { getBrowserDetails, IsMobileViewPort, tabletWidth, ternaryFunc } from '../../../utils/helpers';
import AddContentIcon from '../../../atoms/icons/AddContentIcon';
import { Add } from '@material-ui/icons';
import { VideoConfig, PhotoConfig } from '../../../config/Media';
import CaptureMedia from '../CaptureMedia';
import UploadMediaAgreementModal from '../../modals/UploadMediaAgreementModal';
import TrackLoadingToMixpanel from '../../../components/common/TrackLoadingToMixPanel';
import GalleryUploadOption from './GalleryUploadOption/GalleryUploadOption';
import { PhotoListTypes, MediaTypes } from '../constants';
import GalleryFileUploadedContainer from './GalleryFileUploader/GalleryFileUploadedContainer';
import { handleUploadGAlleryVysionEvent } from '../../../utils/vysion/profile/uploadGallery';
import { ProfileUploadGalleryClickVysionEventsEnum } from '../../../utils/vysion/profile/uploadGallery/click';
import { useDisableBodyElementScroll } from '../../../hooks/useDisableBodyScroll';
import { connect } from 'react-redux';
import UnsupportedFileTypeErrorModal from '../../modals/UnsupportedFileTypeErrorModal';

const StyledDropzone = styled('div')(({ photoheight }) => ({
  boxSizing: 'border-box',
  border: '2px solid #2B8FD7',
  background: backgroundTheme.AddMedia,
  borderRadius: 6,
  textAlign: 'center',
  cursor: 'pointer',
  paddingTop: !getBrowserDetails().isMobile ? '37%' : ternaryFunc(IsMobileViewPort, '27%', '44%'),
  width: '100%',
  minHeight: photoheight ? photoheight : ternaryFunc(tabletWidth, 237, 205),
  [dimensions.SCREEN_MAX_MD]: {
    minHeight: photoheight ? photoheight : ternaryFunc(IsMobileViewPort, 148, 237),
  },
}));

const PhotoItem = styled('div')(
  {
    boxSizing: 'border-box',
    float: 'left',
    width: '100%',
    // height: '100%',
  },
  ({ isRelative }) => (isRelative ? { position: 'relative' } : null)
);

const Uploader = styled(StyledDropzone)`
  &:hover,
  &:hover button {
    color: #bc2028 !important;
  }

  &:hover svg,
  &:hover svg path,
  &:hover button svg path {
    fill: #bc2028 !important;
    color: #bc2028 !important;
    stroke: #bc2028 !important;
  }
`;

interface GalleryUploadProps {
  refreshAndClearErrors: (param: boolean) => void; // not sure what param is for
  uploadPhoto: any;
  isUploading: any;
  displayMessage: (param: unknown) => void;
  uploadMedia: any;
  clearBulkSelection: any;
  hasPublicPhotos: boolean;
  showAddContentText: boolean;
  maxUpload: number;
  uploadSnackbar: IUploadSnackbar;
}

const GalleryUpload = ({
  uploadPhoto,
  uploadMedia,
  isUploading,
  displayMessage,
  showAddContentText = true,
  clearBulkSelection,
  maxUpload = MasterConfig.MAX_NUMBER_PHOTOS,
  refreshAndClearErrors,
  uploadSnackbar,
}: GalleryUploadProps): ReactElement => {
  const uploaderRef = useRef(null);
  const [fileList, setFileList] = useState<PhotoListTypes | null>(null);
  const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false);
  const [showUploadOption, setShowUploadOption] = useState(false);
  const [useCaptureMedia, setUseCaptureMedia] = useState(false);
  const [showUploadReview, setShowUploadReview] = useState(false);
  const [mediaType, setMediaType] = useState<MediaTypes>('photo');
  const [photoHeight, setPhotoHeight] = useState<number | null>(null);
  const [isUploadedFromQA, setIsUploadedFromQA] = useState(false);
  const [showUnsupportedFileModal, setShowUnsupportedFileModal] = useState(false);
  const [filename, setFilename] = useState('');

  useDisableBodyElementScroll(showUploadReview);

  useEffect(() => {
    refreshAndClearErrors(false);
    document.getElementById('Photo0') && setPhotoHeight(document.getElementById('Photo0').clientHeight);
    document.getElementById('PhotoB0') && setPhotoHeight(document.getElementById('PhotoB0').clientHeight);
  }, []);

  const handleFileChange = async (selectedFiles: FileList | null) => {
    if (!selectedFiles) {
      displayMessage({
        info: `Error uploading ${mediaType} no file to upload. Please try again or `,
        linkInfo: 'contact support.',
        url: `/support/contact`,
      });
      setFileList(null);
      return;
    }
    const allSelectedFiles = Array.from(selectedFiles);

    for (let index = 0; index < allSelectedFiles.length; index++) {
      const selectedFile = allSelectedFiles[index];

      if (mediaType === 'photo' && !PhotoConfig.allowedMimeType.includes(selectedFile.type)) {
        setFilename(selectedFile.name);
        setShowUnsupportedFileModal(true);
        return;
      }

      if (mediaType === 'video' && !VideoConfig.allowedMimeType.includes(selectedFile.type)) {
        setFilename(selectedFile.name);
        setShowUnsupportedFileModal(true);
        return;
      }
    }

    setShowUploadOption(false);
    setShowUploadReview(true);
    setFileList(allSelectedFiles);
  };

  const handleSubmitQAPhoto = async () => {
    setIsUploadedFromQA(true);
    setShowUploadOption(true);
    setFileList(document.getElementById('upload-qa-photo').files);
  };

  const resetState = () => {
    setFileList(null);
    setMediaType('photo');
    setUseCaptureMedia(false);
    if (uploaderRef && uploaderRef.current) {
      uploaderRef.current.value = null;
    }
  };

  const toggleSwitch = option => {
    setMediaType(option);
  };

  const addContent = () => {
    /**
     * don't allow upload when there active uploads.
     * which is true if `uploadSnackbar.show` is truthy
     * component GalleryUploadBackgroundProgress.tsx
     *
     * and don't allow upload when user has uploaded maximum gallery already
     */
    if (maxUpload >= MasterConfig.MAX_NUMBER_PHOTOS || uploadSnackbar.show) {
      return;
    }

    // add check before triggering event
    // because for some reason, when clicking "choose existing" button
    // this is being triggered again
    if (!showUploadOption) {
      handleUploadGAlleryVysionEvent({
        event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_ADD_CONTENT,
      });
    }

    handleShowAgreement();

    // Make sure upload process is reset
    setFileList(null);
    setUseCaptureMedia(false);
  };

  const handleAddPhoto = (imageFile, isPrivate = false) => {
    uploadPhoto(imageFile, isPrivate);
    resetState();
  };

  const handleAddVideo = (media, isPrivate = false, mediaType) => {
    uploadMedia(media, isPrivate, mediaType);
    resetState();
  };

  const handleShareMedia = (media, type, isPrivate = false) => {
    return media.media.then(res => {
      if (type === 'photo') {
        return handleAddPhoto(res.blob, isPrivate);
      }

      if (type === 'video') {
        return handleAddVideo(res.blob, isPrivate, 'video/mp4');
      }
    });
  };

  const handleConfirmUpload = () => {
    setShowConfirmUploadModal(false);

    // Programatically trigger dropzone click event
    uploaderRef && uploaderRef.current && uploaderRef.current.click();
  };

  const handleShowAgreement = () => {
    if (localStorage.getItem('wyp_gallery_agreement') !== 'true') {
      setShowConfirmUploadModal(true);
    } else {
      onConfirmAgreement(true);
    }

    clearBulkSelection();
  };

  const onConfirmAgreement = alwasyShow => {
    setShowConfirmUploadModal(false);
    setShowUploadOption(true);

    localStorage.setItem('swuiMediaType', 'photo');

    if (alwasyShow === false && localStorage.getItem('wyp_gallery_agreement') !== 'true') {
      localStorage.setItem('wyp_gallery_agreement', 'true');
    }
  };

  const renderUploader = (isFetching, disabled = false) => {
    if (uploadSnackbar.show) {
      return null;
    }

    return (
      <>
        <div style={{ display: 'none' }}>
          <form>
            <input multiple type="file" name="file" id="upload-qa-photo" data-test-id="upload-qa-photo" />
            <button id="submit-qa-photo" data-test-id="submit-qa-photo" type="button" onClick={handleSubmitQAPhoto}>
              Submit
            </button>
          </form>
        </div>
        <PhotoItem isRelative onClick={addContent} data-test-a="PhotoItem">
          <Loader active={isFetching} background={backgroundTheme.gallery} data-test-a="1" base={{ height: '100%' }}>
            {isFetching && <TrackLoadingToMixpanel page="Profile - loading new uploaded media" />}
            <Uploader photoheight={photoHeight}>
              {!disabled ? (
                <div className="add-content-box">
                  {mediaType && (
                    <input
                      type="file"
                      name="gallery-upload"
                      id="ugallery-upload"
                      data-test-id="gallery-upload"
                      ref={uploaderRef}
                      style={{ display: 'none' }}
                      onChange={e => {
                        handleFileChange(e.target.files);
                      }}
                      accept={mediaType === 'photo' ? PhotoConfig.allowedMimeType : VideoConfig.allowedMimeType}
                      multiple
                      min={1}
                      max={2}
                    />
                  )}
                  <AddContentIcon cssStyle={{ marginBottom: 5, '&:hover': { color: '#BC2028 !important' } }} />
                  <Button
                    buttonType="chromeless"
                    data-test-id="gallery-add-content"
                    customStyle={{
                      fontWeight: 'bold',
                      color: '#3e79a5',
                      padding: 0,
                      width: '100%',
                      '&:hover': { color: '#BC2028 !important' },
                    }}
                  >
                    <div style={{ display: 'inline-flex', alignItems: 'center', flexFlow: 'column' }}>
                      {showAddContentText ? (
                        <>
                          <Add fontSize="large" style={{ color: '#3e79a5', stroke: '#3e79a5', strokeWidth: '5%' }} />
                          <div style={{ display: 'block', fontWeight: 400 }}>Add Content</div>
                        </>
                      ) : null}
                    </div>
                  </Button>
                </div>
              ) : (
                <div className={`${css({ display: 'table', height: '100%' })}`}>
                  <div
                    className={`${css({
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      fontSize: 14,
                      padding: '0 5px',
                    })}`}
                  >
                    You have reached your maximum number of photos.{' '}
                  </div>
                </div>
              )}
            </Uploader>
          </Loader>
        </PhotoItem>
      </>
    );
  };

  const handleUploadOption = (type, mediaType) => {
    setShowConfirmUploadModal(false);
    setShowUploadOption(false);
    setMediaType(mediaType);

    if (type === 'capture') {
      // bypass swui media selected type
      localStorage.setItem('swuiMediaType', mediaType);
      setUseCaptureMedia(true);
      return;
    }

    if (isUploadedFromQA) {
      setShowUploadReview(true);
    } else {
      handleConfirmUpload();
    }
  };

  const handleUnsupportedFileTypeCloseModal = () => {
    setShowUnsupportedFileModal(false);
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', overflow: 'hidden' }}>
      {renderUploader(
        isUploading ? isUploading.public || isUploading.private : false,
        Boolean(maxUpload >= MasterConfig.MAX_NUMBER_PHOTOS)
      )}
      <UploadMediaAgreementModal
        isOpen={showConfirmUploadModal}
        onClose={() => setShowConfirmUploadModal(false)}
        onConfirm={alwasyShow => {
          onConfirmAgreement(alwasyShow);
        }}
      />
      <GalleryUploadOption
        isOpen={showUploadOption}
        onClose={() => {
          setShowUploadOption(false);
          setMediaType('photo');
        }}
        onSelected={handleUploadOption}
        uploadOption={mediaType}
        onToggle={toggleSwitch}
      />

      {/* Todo: get existing media count */}
      {showUploadReview && fileList && (
        <GalleryFileUploadedContainer
          mediaType={mediaType}
          files={fileList}
          existingUploadedMediaCount={maxUpload}
          onUploadAgainClicked={() => {
            resetState();
            setShowUploadOption(true);
            setShowUploadReview(false);
          }}
          onClose={() => {
            handleUploadGAlleryVysionEvent({
              event: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL,
            });
            setShowUploadReview(false);
            resetState();
          }}
        />
      )}
      {useCaptureMedia && (
        <CaptureMedia
          onClose={() => {
            setUseCaptureMedia(false);
            setShowUploadOption(true);
          }}
          onShare={handleShareMedia}
          isOpen={useCaptureMedia}
        />
      )}
      <UnsupportedFileTypeErrorModal
        isOpen={showUnsupportedFileModal}
        filename={filename}
        onUploadAnother={handleUnsupportedFileTypeCloseModal}
        onClose={handleUnsupportedFileTypeCloseModal}
        mediaType={mediaType}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    uploadSnackbar: state.uploadSnackbar,
  };
};

export default connect(mapStateToProps, {})(GalleryUpload);
