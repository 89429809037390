export const STORE_BIOMETRIC_LIST = 'STORE_BIOMETRIC_LIST';

export interface BiometricsList {
    data: unknown[]
}

export interface BiometricState {
    howManyPagesLoaded: number;
    biometricsList: unknown[]
}

interface STORE_BIOMETRIC_LIST_ACTION {
    type: typeof STORE_BIOMETRIC_LIST;
    payload: BiometricsList;
}

export type BiometricActionType = 
    | STORE_BIOMETRIC_LIST_ACTION;