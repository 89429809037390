import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { FULL_URL } from '../../../config/Master';
import { dimensions, patternModal } from '../../../style';
import { IsMobileViewPort } from '../../../utils/helpers';

const CenterDashDiv = styled('div')({
  textAlign: 'center',
  borderTop: '1px dashed #8F8F8F',
  padding: 20,
  marginTop: 10,
  [dimensions.SCREEN_MAX_SM]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
  },
});

const StyledLink = styled('a')({
  textDecoration: 'underline',
  fontSize: 14,
  color: patternModal.linkBlue,
  [dimensions.SCREEN_MAX_SM]: {
    lineHeight: '25px',
  },
});

const OfferModalFooter = (): ReactElement => {
  return (
    <CenterDashDiv>
      <StyledLink href={`${FULL_URL}/blog/tips-for-dating-safely-during-covid-and-after`} target="_blank">
        Read Safe Dating Tips
      </StyledLink>
      {!IsMobileViewPort && ` | `}
      <StyledLink href={`${FULL_URL}/community-guidelines`} target="_blank">
        Read Community Guidelines
      </StyledLink>
    </CenterDashDiv>
  );
};

export default OfferModalFooter;
