import { vysionService } from '../utils/vysion/vysionService';
import { convertSentanceTitleCase } from '../utils/helpers';

const setSearchAction = (eventType: any, extraInfo?: any): any => {
  return {
    type: 'SEARCH',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: 'Search',
        extraInfo: setExtraInfo(eventType, extraInfo),
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const setExtraInfo = (eventType: string, extraInfo: any) => {
  switch (eventType) {
    case 'APPLY FILTERS':
      return extraInfo;
    case 'RESET FILTERS':
      return convertSentanceTitleCase(eventType);
    default:
      return null;
  }
};

const setComponent = (eventType: any): any => {
  return convertSentanceTitleCase(eventType);
};

const searchApplyFilters = (extraInfo: any): void => {
  vysionService(setSearchAction('APPLY FILTERS', extraInfo));
};

const searchResetFilters = (): void => {
  vysionService(setSearchAction('RESET FILTERS'));
};

export { searchApplyFilters, searchResetFilters };
