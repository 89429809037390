import React, { useEffect } from 'react';
import BillingSuccess from '../components/pages/Billing/BillingSuccess';
import { connect } from 'react-redux';
import { refreshProfile } from '../actions/profileActions';
import {
  getBillingPayments,
  getBillingDescriptors,
  clearBillingPayments,
  postVisitLogs,
} from '../actions/billingActions';

const BillingSuccessContainer = ({ refresh, clearPayments, payments, logPageVisit, ...rest }) => {
  useEffect(() => {
    refresh();
    logPageVisit('success', 3, true);
    return () => {
      clearPayments();
    };
  }, [clearPayments, logPageVisit, refresh]);

  if (payments.length > 0) {
    return <BillingSuccess payments={payments} {...rest} />;
  }
  return null;
};

const mapStateToProps = state => {
  return {
    profile: state.profile.profile.data,
    payments: state.billing.payments,
    descriptors: state.meta.descriptors,
    account: state.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearPayments: () => {
      dispatch(clearBillingPayments());
    },
    refresh: () => {
      dispatch(refreshProfile());
      dispatch(getBillingPayments());
      dispatch(getBillingDescriptors());
    },
    logPageVisit: (type, step, isSuccessPage) => {
      dispatch(postVisitLogs(type, step, isSuccessPage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingSuccessContainer);
