import React, { useState, useEffect, useRef, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { styler, tween } from 'popmotion';
import { usePrevious } from '../../utils/customHooks';

import Button from './Button';
import Loading from '../Loading';

interface ContextualButtonProps {
  id: any;
  buttonText: any;
  buttonTextOnClick: any;
  buttonType: any;
  onClick: any;
  showLoadingIndicator: any;
  loading: any;
}

const ContextualButton = ({
  id,
  buttonText,
  buttonTextOnClick,
  buttonType,
  onClick,
  showLoadingIndicator,
  loading,
  ...rest
}: ContextualButtonProps): ReactElement => {
  const buttonRef = useRef();
  const prevLoading = usePrevious(loading);
  const [initButtonWidth, setInitButtonWidth] = useState(0);
  const [animatedButtonWidth, setAnimatedButtonWidth] = useState(0);

  useEffect(() => {
    const ghostButton = buttonRef.current.querySelector('#ghost-button');
    const newAnimatedButtonWidth = ghostButton.offsetWidth;
    ghostButton.style.display = 'none';

    setAnimatedButtonWidth(newAnimatedButtonWidth);
    setTimeout(() => ghostButton.parentNode.removeChild(ghostButton), 300);
  }, []);

  useEffect(() => {
    setInitButtonWidth(buttonRef.current.querySelector(`#loading-button-${id}`).offsetWidth);
  }, [id]);

  useEffect(() => {
    const buttonEl = buttonRef.current.querySelector(`#loading-button-${id}`);
    const button = styler(buttonEl);

    if (!!prevLoading !== loading) {
      tween({
        from: { width: loading ? initButtonWidth : animatedButtonWidth },
        to: { width: loading ? animatedButtonWidth : initButtonWidth },
        duration: 800,
      }).start(button.set);
    }
  }, [loading, animatedButtonWidth, id, initButtonWidth, prevLoading]);

  const transitionButton = (
    <div style={{ position: 'relative', display: 'flex' }}>
      {buttonTextOnClick || buttonText}

      {showLoadingIndicator && (
        <div style={{ position: 'absolute', right: '-28px', top: '0' }}>
          <Loading width={18} color={buttonType === 'primary' ? '#FFF' : 'rgba(0,0,0,0.3)'} />
        </div>
      )}
    </div>
  );

  return (
    <div ref={buttonRef} style={{ width: '100%' }}>
      <Button
        id={`loading-button-${id}`}
        onClick={onClick}
        buttonType="primary"
        iconPosition="right"
        alignContent="left"
        customStyle={{ position: 'relative', overflow: 'hidden' }}
        {...rest}
      >
        {loading ? transitionButton : buttonText}
      </Button>

      <Button id="ghost-button" disabled>
        {buttonTextOnClick || buttonText}

        {showLoadingIndicator && (
          <div style={{ paddingLeft: '10px' }}>
            <Loading width={18} />
          </div>
        )}
      </Button>
    </div>
  );
};

ContextualButton.defaultProps = {
  showLoadingIndicator: true,
};

ContextualButton.propTypes = {
  id: PropTypes.string.isRequired,
  /** Button content */
  buttonText: PropTypes.any.isRequired,
  /** What content will animate to; will default to buttonText if no defined */
  buttonTexOnClick: PropTypes.string,
  /** Show loading icon next to buttonText */
  showLoadingIndicator: PropTypes.bool,
  /** If true, animates to buttonTextOnClick; if false, animates to buttonText */
  loading: PropTypes.bool,
};

export default ContextualButton;
