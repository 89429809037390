import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';

const api = MasterConfig.api;

const getOffers = (token: any, type: string, order: string, per_page: string | number, page = 1): AxiosPromise => {
  const numberToGet = per_page === 0 ? MasterConfig.per_page : per_page;

  return axios({
    method: 'GET',
    url: `${api}/offer/${type}?include=other_account.profile,other_account.photo,other_account.video&page=${page}&per_page=${numberToGet}&order=${order}`,
    data: {},
  });
};

const createNewOffer = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/offer`,
    data: formData,
  });
};

const counterOffer = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/offer/counter`,
    data: formData,
  });
};

const acceptOffer = (token: any, account: any, message: any, source: string): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/offer/accept`,
    data: { account, message, source },
  });
};

const ignoreOffer = (token: any, account: any): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/offer`,
    data: { account },
  });
};

const sendSuggestion = (token: any, account: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/offer`,
    data: { account, suggest: true },
  });
};

const unlockOffer = (token: any, account: any, triggerToBuy: boolean): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/offer/unlock`,
    data: { account, trigger_to_buy: triggerToBuy },
  });
};

const updateOffer = (formData: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/offer/update`,
    data: formData,
  });
};

const quickOffer = (formData: any): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/quick-offer`,
    data: formData,
  });
};

export {
  getOffers,
  createNewOffer,
  counterOffer,
  acceptOffer,
  ignoreOffer,
  sendSuggestion,
  unlockOffer,
  updateOffer,
  quickOffer,
};
