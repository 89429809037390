import React, { useState, useEffect, useRef } from 'react';
import MasterConfig from '../../../config/Master';
import styled from '@emotion/styled';
import { css } from 'glamor';

import { spacing, dimensions, color, textColor } from '../../../style';
import Container from '../../blocks/Container';
import Button from '../../../atoms/buttons/Button';
import Header from '../../blocks/Header';
import Headline from '../../common/Headline';
import Dropzone from 'react-dropzone';
import { isEmpty } from 'lodash';
import UserPhoto from '../../../atoms/photos/UserPhoto';
import PhotoModal from '../../common/modals/PhotoModal';
import PhotoCropper from '../../../modules/gallery/PhotoCropper';
import PhotoBlur from '../../../modules/gallery/PhotoBlur';
import PhotoPreview from '../../../modules/gallery/PhotoPreview';
import Loader from '../../../atoms/LoaderStyled';
import { renderAvatar } from '../../../common';
import { isDarkMode } from '../../../utils/helpers';
import { PhotoConfig } from '../../../config/Media';

const PhotoList = styled('div')({
  //I think this is the only clearfix in the whole app.
  ':after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
});

const StyledDropzone = styled(Dropzone)({
  boxSizing: 'border-box',
  border: '2px dashed #bbb',
  borderRadius: 3,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  textAlign: 'center',
  cursor: 'pointer',
  paddingBottom: '25px',
  marginBottom: '25px',
});

const AddButton = styled('button')({
  height: '32px',
  width: '32px',
  borderRadius: '24px',
  backgroundColor: color.BRAND_2,
  margin: '0 auto',
  display: 'block',
  border: 'none',
  padding: '0px',
  marginTop: 'calc(50% - 32px)',
  lineHeight: '0',
  ':focus': { outline: 'none' },
});

const PhotoItem = styled('div')(
  {
    boxSizing: 'border-box',
    float: 'left',
    width: '100%',
    ...spacing.PADDING_SM,

    [dimensions.SCREEN_MIN_XS]: {
      width: '50%',
    },

    [dimensions.SCREEN_MIN_MD]: {
      width: '33.333%',
    },

    [dimensions.SCREEN_MIN_LG]: {
      width: '25%',
    },
  },
  ({ isRelative }) => (isRelative ? { position: 'relative' } : null)
);

const PhotoHeader = styled(Header)({
  [dimensions.SCREEN_MAX_MD]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const NoPublicText = styled('div')({
  marginBottom: 22,
});

const NoPublicTitle = styled('strong')({
  display: 'block',
  fontSize: '16px',
  marginBottom: '4px',
});

const options = (option, state, onDropPublic, onDropPrivate) => {
  switch (state) {
    case 'button':
      return option ? 'Public' : 'Private';
    case 'drop':
      return option ? onDropPublic : onDropPrivate;
    default:
      return;
  }
};

const dropCondition = fileType => !fileType || typeof fileType.size === 'undefined' || fileType.size >= 15000000;

const renderErrorForDrop = (fileType, displayFailMessage) => {
  if (dropCondition(fileType)) {
    displayFailMessage({
      info: 'Error uploading photo due to file size. Please try again or ',
      linkInfo: 'contact support.',
      url: 'faq/giving-feedback',
    });
  }
};

const isPhotoCropper = (img, imgUrl, isCropperOpen, handleShowPreview) => {
  if (isCropperOpen) {
    return <PhotoCropper onAddPhoto={handleShowPreview} fileName={img.name} imageUrl={imgUrl} />;
  } else {
    return null;
  }
};

const showPhotoPreview = (img, imgUrl, isPreviewOpen, isLastPreview, handleAddPhoto, handleBlurNext) => {
  if (isPreviewOpen) {
    return (
      <PhotoPreview
        isLastPreview={isLastPreview}
        nextBlur={handleBlurNext}
        onAddPhoto={() => handleAddPhoto(img)}
        imageUrl={imgUrl}
      />
    );
  } else {
    return null;
  }
};

const showBlurTool = (img, imgUrl, isBlurToolOpen, handleShowPreview) => {
  if (isBlurToolOpen) {
    return (
      <PhotoBlur
        onLastPreview={handleShowPreview}
        cancelBlur={() => handleShowPreview()}
        fileName={img.name}
        imageUrl={imgUrl}
        imageFile={img}
      />
    );
  } else {
    return null;
  }
};

const renderPhotoModal = (photoOpt, option, isLastPreview, handleShowPreview, handleAddPhoto, handleBlurNext) => {
  if (option[0]) return isPhotoCropper(photoOpt[0], photoOpt[1], option[0], handleShowPreview);

  if (option[1])
    return showPhotoPreview(photoOpt[0], photoOpt[1], option[1], isLastPreview, handleAddPhoto, handleBlurNext);

  if (option[2]) return showBlurTool(photoOpt[0], photoOpt[1], option[2], handleShowPreview);
};

interface PhotosProps {
  refreshAndClearErrors: any;
  uploadPhoto: any;
  hasPublicPhotos: any;
  hasPublicApprovedPhotos: any;
  singlePublicLeft: any;
  isFetching: any;
  makeDefault: any;
  setPrivacy;
  deletePhoto: any;
  isUploading: any;
  photos: any;
  displayFailMessage: any;
}

const Photos = ({
  refreshAndClearErrors,
  uploadPhoto,
  hasPublicPhotos,
  hasPublicApprovedPhotos,
  singlePublicLeft,
  isFetching,
  makeDefault,
  setPrivacy,
  deletePhoto,
  isUploading,
  photos,
  displayFailMessage,
}: PhotosProps): any => {
  const dropZone = useRef(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [isBlurToolOpen, setIsBlurToolOpen] = useState(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isLastPreview, setIsLastPreview] = useState(false);

  useEffect(() => {
    refreshAndClearErrors(false);
  }, [refreshAndClearErrors]);

  const onDropPrivate = acceptedFiles => {
    const imagePrivateFile = acceptedFiles[0];
    renderErrorForDrop(imagePrivateFile, displayFailMessage);
    setIsCropperOpen(true);
    setIsPhotoModalOpen(true);
    setFile(acceptedFiles[0]);
    setIsPrivate(true);
  };

  const onDropPublic = acceptedFiles => {
    const imagePublicFile = acceptedFiles[0];
    renderErrorForDrop(imagePublicFile, displayFailMessage);
    setIsCropperOpen(true);
    setIsPhotoModalOpen(true);
    setFile(acceptedFiles[0]);
    setIsPrivate(false);
  };

  const handleAddPhoto = imageFile => {
    uploadPhoto(imageFile, isPrivate);
    setIsPrivate(false);
    setIsCropperOpen(false);
    setIsBlurToolOpen(false);
    setIsPhotoModalOpen(false);
    setIsPreviewOpen(false);
    setFile(null);
    setIsLastPreview(false);
  };

  const handleBlurNext = () => {
    setIsCropperOpen(false);
    setIsBlurToolOpen(true);
    setIsPreviewOpen(false);
  };

  const handleShowPreview = (image = null, lastPreview = null) => {
    setIsCropperOpen(false);
    setIsBlurToolOpen(false);
    setIsPreviewOpen(true);
    setFile(image || file);
    setIsLastPreview(lastPreview);
  };

  const handleSubmitPublic = () => {
    onDropPublic(document.getElementById('upload-qa-public-photo').files);
  };

  const handleSubmitPrivate = () => {
    onDropPrivate(document.getElementById('upload-qa-private-photo').files);
  };

  const photoList = (images, privateImage = false) => {
    // loop through each photo item.
    if (!isEmpty(images)) {
      return Object.keys(images).map(key => {
        // get thumb of image
        const photo = images[key];
        if (photo.private !== privateImage) return null;

        const thumb = renderAvatar(photo.urls, true);
        // Need the public profile array size to check whether the user can set a private as a profile image.

        // return a block for each thumb
        // that allows user to manage photo.
        return (
          <PhotoItem key={key}>
            <UserPhoto
              photoId={photo.id}
              imgUrl={thumb}
              isAvatar={photo.is_avatar}
              isPending={photo.status.toLowerCase() === 'new'}
              isPrivate={photo.private}
              theKey={key}
              makeDefault={makeDefault}
              setPrivacy={setPrivacy}
              deletePhoto={deletePhoto}
              hasPublicPhotos={hasPublicPhotos}
              hasPublicApprovedPhotos={hasPublicApprovedPhotos}
              singlePublicLeft={singlePublicLeft}
              isFetching={isFetching}
            />
          </PhotoItem>
        );
      });
    }
  };

  const renderDropzone = (images, processing, disabled, publicUpload = true) => {
    return (
      <PhotoItem isRelative>
        <Loader active={processing} background={isDarkMode() ? '#020d15' : '#F4F7FA'}>
          <StyledDropzone
            accept={PhotoConfig.allowedMimeType}
            ref={dropZone}
            multiple={false}
            onDrop={options(publicUpload, 'drop', onDropPublic, onDropPrivate)}
            maxSize={MasterConfig.MAX_PHOTO_FILE_SIZE}
            disabled={disabled}
            disableClick={disabled}
            data-test-id="upload-photo"
            data-nux="upload-photo"
          >
            {!disabled ? (
              <div>
                <AddButton>
                  <svg height="24px" width="24px" version="1.1" fill="#FFF" viewBox="0 0 16 16" x="0px" y="0px">
                    <path clipRule="evenodd" d="M2,7h5v-5h2v5h5v2h-5v5h-2v-5h-5z" fillRule="evenodd" />
                  </svg>
                </AddButton>
                <Button buttonType="chromeless" customStyle={{ fontWeight: 'normal', color: textColor.DARK.color }}>
                  Add {options(publicUpload, 'button', onDropPublic, onDropPrivate)} Photo
                </Button>
                <div className={`${css({ display: 'table', height: '100%' })}`}>
                  <div className={`${css({ display: 'table-cell', verticalAlign: 'middle', padding: '0 16px' })}`}>
                    You know the deal: No contact info, no nudes...just be cool, okay?
                  </div>
                </div>
              </div>
            ) : (
              <div className={`${css({ display: 'table', height: '100%' })}`}>
                <div className={`${css({ display: 'table-cell', verticalAlign: 'middle', padding: '0 16px' })}`}>
                  You have reached your maximum number of photos.{' '}
                </div>
              </div>
            )}
          </StyledDropzone>
        </Loader>
      </PhotoItem>
    );
  };

  const renderImageTool = img => {
    const imgUrl = URL.createObjectURL(img);

    return renderPhotoModal(
      [img, imgUrl],
      [isCropperOpen, isPreviewOpen, isBlurToolOpen],
      isLastPreview,
      handleShowPreview,
      handleAddPhoto,
      handleBlurNext
    );
  };

  const noPublicPhotoMsg = () => (
    <NoPublicText>
      <div className={`${css({ float: 'left', marginRight: '16px' })}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 90 90">
          <g fill="none">
            <path
              fill={`${color.BRAND_2}`}
              d="M89.9999996,44.9999996 C89.9999996,69.852814 69.8528132,90.0000008 44.9999994,90.0000008 C20.1471856,90.0000008 -7.65045482e-07,69.852814 -7.65045492e-07,44.9999996 C-7.65045497e-07,20.1471853 20.1471856,-1.47398771e-06 44.9999994,-1.47398771e-06 C69.8528132,-1.47398771e-06 89.9999996,20.1471853 89.9999996,44.9999996 L89.9999996,44.9999996 Z"
            />
            <path
              fill="#fff"
              d="M50.734509,15.16786 C52.477331,15.16792 53.946097,15.77229 55.14081,16.98098 C56.335476,18.18978 56.932821,19.65152 56.932846,21.3662 C56.932821,23.08098 56.328448,24.54272 55.119727,25.75142 C53.910959,26.96021 52.449221,27.56458 50.734509,27.56454 C49.047869,27.56458 47.600186,26.96021 46.391457,25.75142 C45.182697,24.54272 44.578324,23.08098 44.578338,21.3662 C44.578324,19.65152 45.175669,18.18978 46.370374,16.98098 C47.565049,15.77229 49.019759,15.16792 50.734509,15.16786 Z M53.348774,34.56402 L44.704834,64.54372 C44.226945,66.25852 43.988007,67.38292 43.98802,67.91702 C43.988007,68.22622 44.114504,68.51432 44.36751,68.78132 C44.62049,69.04842 44.887538,69.18192 45.168655,69.18192 C45.646518,69.18192 46.124393,68.97112 46.602284,68.54942 C47.867235,67.50942 49.385193,65.62602 51.156165,62.89932 L52.589794,63.74262 C48.345111,71.13562 43.8334,74.83212 39.054649,74.83212 C37.227469,74.83212 35.772759,74.31912 34.690514,73.29312 C33.608263,72.26702 33.067138,70.96692 33.06714,69.39282 C33.067138,68.35272 33.306076,67.03152 33.783955,65.42922 L39.644967,45.27412 C40.207166,43.33452 40.488269,41.87275 40.488278,40.88885 C40.488269,40.27046 40.221221,39.72231 39.687133,39.2444 C39.153028,38.76655 38.422159,38.52762 37.494524,38.52758 C37.072862,38.52762 36.566876,38.54168 35.976564,38.56978 L36.524716,36.88315 L50.81884,34.56405 L53.348774,34.56402 Z"
            />
          </g>
        </svg>
      </div>
      <div className={`${css({ marginLeft: '60px' })}`}>
        <NoPublicTitle>You don&apos;t have a public profile photo</NoPublicTitle>
        Without one, members will be less likely to find and interact with you.
      </div>
    </NoPublicText>
  );

  return (
    <div>
      <Container>
        <PhotoHeader bottomRuled>
          <div>
            <Headline data-test-id="photos-public-header">Public Photos</Headline>
            <div>Visible to all members</div>
          </div>
        </PhotoHeader>
      </Container>
      <Container>
        {!hasPublicPhotos ? noPublicPhotoMsg() : null}
        <PhotoList>
          {photoList(photos.data, false)}
          {renderDropzone(
            photos.data,
            isUploading ? isUploading.public : false,
            Boolean(photos.data.length >= MasterConfig.MAX_NUMBER_PHOTOS),
            true
          )}
          <div style={{ display: 'none' }}>
            <form>
              <input type="file" name="file" id="upload-qa-public-photo" data-test-id="upload-qa-public-photo" />
              <button
                type="button"
                id="submit-qa-public-photo"
                data-test-id="submit-qa-public-photo"
                onClick={handleSubmitPublic}
              >
                Submit
              </button>
            </form>
          </div>
        </PhotoList>
      </Container>
      <Container>
        <PhotoHeader bottomRuled>
          <div>
            <Headline data-test-id="photos-private-header">Private Photos/Videos</Headline>
            <div>Only visible when you send or accept an offer. Canceling this offer hides these photos.</div>
          </div>
        </PhotoHeader>
      </Container>
      <Container>
        <PhotoList>
          {photoList(photos.data, true)}
          {renderDropzone(
            photos.data,
            isUploading ? isUploading.private : false,
            Boolean(photos.data.length >= MasterConfig.MAX_NUMBER_PHOTOS),
            false
          )}
          <div style={{ display: 'none' }}>
            <form>
              <input type="file" name="file" id="upload-qa-private-photo" />
              <button type="button" id="submit-qa-private-photo" onClick={handleSubmitPrivate}>
                Submit
              </button>
            </form>
          </div>
        </PhotoList>
      </Container>
      <PhotoModal
        shouldOpen={isPhotoModalOpen}
        hideModalCloseBtn={false}
        hideConfirmBtn={true}
        hideCancelBtn={true}
        onCloseHandler={() => {
          setIsPhotoModalOpen(false);
          setFile(null);
          setIsLastPreview(false);
        }}
        confirmationText={file && renderImageTool(file)}
      />
    </div>
  );
};

export default Photos;
