import React, { useEffect, memo, useState } from 'react';
import ModalWithPattern from './ModalWithPattern';
import Carousel from '../Carousel';
import { SITE_NAME, s3BucketDirect } from '../../../config/Master';
import SvgQuestion from '../../../atoms/icons/SvgQuestion';
import styled from '@emotion/styled';
import { isDarkMode, IsMobileViewPort } from '../../../utils/helpers';
import { isFD } from '../../../config/Master';
import { mixpanelTrackWhatsAnOfferModalShown } from '../../../utils/mixpanel/infoModalShown';

const TutorialContainer = styled('div')({
  display: 'initial',
});

const StyledSpan = styled('span')({
  fontSize: 12,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2490DE',
  },
});

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  maxHeight: 600,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

interface OffersTutorialProps {
  hideArrows?: boolean;
  clickableIndicators?: boolean;
  iconOnly?: boolean;
  linkStyle?: Record<string, unknown>;
  showTutorial?: boolean;
  onClose?(): void;
  isFirstTimeOffer?: boolean;
  svgIconHeight?: number;
  svgIconWeight?: number;
}

const OffersTutorialModal = ({
  iconOnly = false,
  hideArrows = true,
  clickableIndicators = true,
  linkStyle = {},
  showTutorial = false,
  onClose = () => null,
  isFirstTimeOffer = false,
  svgIconWeight = 12,
  svgIconHeight = 12,
}: OffersTutorialProps): JSX.Element => {
  const [showOffersTutorial, setShowOffersTutorial] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setShowOffersTutorial(showTutorial);

    () => {
      setShowOffersTutorial(false);
    };
  }, [showTutorial]);

  const tutorialData = [
    {
      icon: `${s3BucketDirect}offers_tutorial/step1.svg`,
      title: `What is a ${isFD ? 'FD' : 'WYP'} Date Offer?`,
      content: [
        'It\'s a "first date incentive" for Attractive members',
        'Offers can be initiated by either member group',
        'The incentive is always gifted by the Generous member',
      ],
      subTitle: "It's the ultimate ice breaker!",
    },
    {
      icon: `${s3BucketDirect}offers_tutorial/step2.svg`,
      title: 'Send offers quickly and easily',
      content: [
        "Click the Offer button located on a member's profile page",
        "Don't be shy - send an offer!",
        'Offers may be Accepted, Declined or Countered',
      ],
      subTitle: 'Make an offer and start your epic date!',
    },
    {
      icon: `${s3BucketDirect}offers_tutorial/step3.svg`,
      title: 'Accepted offers are just the beginning',
      content: [
        'Once accepted, Generous members must "unlock" messaging so communication can begin',
        `Incentives are handled between members at the first date, not through the ${SITE_NAME} app.`,
      ],
      subTitle: 'Get Straight to the Date™',
    },
  ];

  const handleOnLinkClick = e => {
    e.preventDefault();

    if (!showOffersTutorial) {
      setShowOffersTutorial(true);
      mixpanelTrackWhatsAnOfferModalShown('Discover Mode');
    }
  };

  const handleCloseModal = () => {
    setShowOffersTutorial(false);
    onClose?.();
  };

  return (
    <TutorialContainer>
      <StyledSpan
        style={linkStyle}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={e => handleOnLinkClick(e)}
      >
        <SvgQuestion
          viewBox="0 0 512 500"
          styles={{
            fill: isHover ? '#2490DE' : isDarkMode() ? '#71B5E4' : 'rgb(151, 151, 151)',
            height: svgIconHeight,
            width: svgIconWeight,
          }}
        />{' '}
        {iconOnly ? '' : 'What is an Offer?'}
      </StyledSpan>
      <ModalWithPattern
        dataTestID="offers-tutorial-modal"
        isModalOpen={showOffersTutorial}
        modalContentLabel="Offers Tutorial"
        closeOnOverlayClick
        closeRequest={handleCloseModal}
        closeBtnClassName="offers-tutorial-close"
        closeBtnDataTestId="offers-tutorial-close"
        onCloseClick={handleCloseModal}
        customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
        customContentStyle={IsMobileViewPort ? mobileContentStyle : {}}
      >
        <Carousel
          slidesData={tutorialData}
          hideArrows={hideArrows}
          clickableIndicators={clickableIndicators}
          onClose={handleCloseModal}
          isFirstTimeOffer={isFirstTimeOffer}
        />
      </ModalWithPattern>
    </TutorialContainer>
  );
};

export default memo(OffersTutorialModal);
