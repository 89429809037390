export const NOTIFICATION_BANNER_DISPLAY_SUCCESS = 'NOTIFICATION_BANNER_DISPLAY_SUCCESS';
export const NOTIFICATION_BANNER_DISPLAY_WARN = 'NOTIFICATION_BANNER_DISPLAY_WARN';
export const NOTIFICATION_BANNER_DISPLAY_FAIL = 'NOTIFICATION_BANNER_DISPLAY_FAIL';
export const NOTIFICATION_BANNER_CLEAR = 'NOTIFICATION_BANNER_CLEAR';
export const STORE_AUTO_DISMISS_NOTIFICATION_BANNER = 'STORE_AUTO_DISMISS_NOTIFICATION_BANNER';

interface NOTIFICATION_BANNER_DISPLAY_SUCCESS_ACTION {
  type: typeof NOTIFICATION_BANNER_DISPLAY_SUCCESS;
  message: string;
}

interface NOTIFICATION_BANNER_DISPLAY_WARN_ACTION {
  type: typeof NOTIFICATION_BANNER_DISPLAY_WARN;
  message: string;
}

interface NOTIFICATION_BANNER_DISPLAY_FAIL_ACTION {
  type: typeof NOTIFICATION_BANNER_DISPLAY_FAIL;
  message: string;
  isPersist?: boolean;
}

interface NOTIFICATION_BANNER_CLEAR_ACTION {
  type: typeof NOTIFICATION_BANNER_CLEAR;
}

export type NotificationActionType =
  | NOTIFICATION_BANNER_DISPLAY_SUCCESS_ACTION
  | NOTIFICATION_BANNER_DISPLAY_WARN_ACTION
  | NOTIFICATION_BANNER_DISPLAY_FAIL_ACTION
  | NOTIFICATION_BANNER_CLEAR_ACTION;
