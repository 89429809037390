import React, { ReactElement } from 'react';

import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { backgroundTheme } from '../../style';

interface ArrowForwardIosProps {
  color?: any;
  cssStyle?: any;
  selected?: boolean;
}

const ArrowForward = ({
  color = backgroundTheme.iconFill,
  cssStyle = {},
  selected = false,
}: ArrowForwardIosProps): ReactElement => {
  switch (true) {
    case selected:
      return (
        <SvgIcon
          component={KeyboardArrowDown}
          viewBox={'4 3 18 18'}
          style={{ fontSize: 26, color: color, ...cssStyle }}
        />
      );
    default:
      return (
        <SvgIcon
          component={KeyboardArrowRight}
          viewBox={'4 3 18 18'}
          style={{ fontSize: 26, color: color, ...cssStyle }}
        />
      );
  }
};

export default ArrowForward;
