import React, { useEffect, useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Autocomplete from '../../../atoms/AutocompleteMain';
import { extractLocationData, geocodeByPlaceId, normalizeLocationDisplay } from '../../../common';
import { tomtomEnabled } from '../../../config/Master';

const StyledForm = styled('div')({
  display: 'flex',
});

const StyledInput = styled('div')({
  flex: '1 1 auto',
});

interface SearchBarProps {
  searchFilters: any;
  saveLocation: any;
  setHiddenMobileApplyFilterButton: any;
}

const SearchBar = ({ searchFilters, saveLocation, setHiddenMobileApplyFilterButton }: SearchBarProps): ReactElement => {
  const locText = searchFilters.city
    ? normalizeLocationDisplay(searchFilters.city, searchFilters.region, searchFilters.country)
    : '';
  const [myLocText, setMyLocText] = useState(locText);

  useEffect(() => {
    if (searchFilters.city === '' && searchFilters.region === '' && searchFilters.country === '') {
      setMyLocText('');
    }
  }, [searchFilters]);

  const locationSelectHandler = (address, placeId, details = null) => {
    if (tomtomEnabled && details !== null) {
      saveLocation(details);

      // apply to state
      const placeData = extractLocationData(details);

      const location = placeData.city
        ? normalizeLocationDisplay(placeData.city, placeData.region, placeData.country)
        : '';

      setMyLocText(location);
    } else {
      geocodeByPlaceId(placeId).then(results => {
        const place = results[0];
        if (!place) {
          console.error('Error with result data');

          return;
        }

        //save location
        saveLocation(place);
        // apply to state
        const placeData = extractLocationData(place);
        const location = placeData.city
          ? normalizeLocationDisplay(placeData.city, placeData.region, placeData.country)
          : '';

        setMyLocText(location);
      });
    }
  };

  const locationChangeHandler = value => {
    if (value === '') saveLocation(null);
    setMyLocText(value);
  };

  const handleInputKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      setHiddenMobileApplyFilterButton(false);
      return;
    }

    if (searchFilters && searchFilters.hiddenMobileApplyFilterButton === false) {
      setHiddenMobileApplyFilterButton(true);
    }
  };

  const inputProps = {
    value: myLocText,
    id: 'autocompleteLocation',
    type: 'text',
    onChange: locationChangeHandler,
    onBlur: () => {
      setHiddenMobileApplyFilterButton(false);
    },
    onFocus: () => {
      setHiddenMobileApplyFilterButton(true);
    },
    onKeyDown: handleInputKeyDown,
  };

  return (
    <StyledForm>
      <StyledInput>
        <Autocomplete
          noIcon
          inputProps={inputProps}
          onSelect={locationSelectHandler}
          customStyle={{ marginRight: 0, paddingBottom: 0 }}
        />
      </StyledInput>
    </StyledForm>
  );
};

SearchBar.propTypes = {
  saveLocation: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
};

export default SearchBar;
