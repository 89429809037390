import store from '../../store';

const getPronoun = gender => {
  if (gender === 'Male') {
    return 'He';
  }

  return 'She';
};

const getOfferAction = accountType => {
  if (accountType === 'Generous') {
    return 'Send a message';
  }

  return 'Unlock the conversation';
};

// default notification details
let notifDetails = {
  label: 'You have new notifications!',
  action: 'Check them out',
  color: 'blue',
  hasFavIcon: false,
  link: '',
  giftData: {},
};

const getNotifLink = (data, isMail = false, isFav = false) => {
  switch (true) {
    case isMail:
      return `/mail/${data.account.hash_id}?notif_ref=true`;
    case isFav:
      return '/interests/favorited-me';
    default:
      return `/profile/${data.account.hash_id}?notif_ref=true`;
  }
};

const getConversationLink = data => {
  return `/mail/${data.account.hash_id}`;
};

const getOfferNotification = (action, data) => {
  const { alert_offer } = store.getState().settings;
  const profileData = store.getState()?.profile?.profile?.data;
  const isGenerous = profileData?.account_type === 'Generous';

  if (!alert_offer) return;

  switch (action) {
    case 'receive':
      notifDetails = {
        ...notifDetails,
        label: `${data.account.username} sent you ${isGenerous ? 'a Request' : 'an Offer'}!`,
        action: `Review ${isGenerous ? 'Request' : 'Offer'}!`,
        link: getNotifLink(data),
        hasFavIcon: false,
        color: 'gold',
      };
      return notifDetails;
    case 'suggest':
      notifDetails = {
        ...notifDetails,
        label: `${getPronoun(data.profile.gender)}'s interested!`,
        action: 'Check them out',
        link: getNotifLink(data),
        hasFavIcon: false,
        color: 'gold',
      };
      return notifDetails;
    case 'accept':
      notifDetails = {
        ...notifDetails,
        label: `${isGenerous ? 'Offer' : 'Request'} Accepted`,
        action: getOfferAction(data.profile.account_type),
        link: getNotifLink(data, true),
        hasFavIcon: false,
      };
      return notifDetails;
    case 'update':
      notifDetails = {
        ...notifDetails,
        label: `Great news! ${data.account.username} raised their ${isGenerous ? 'request' : 'offer'}!`,
        action: `Review ${isGenerous ? 'Request' : 'Offer'}!`,
        link: getNotifLink(data),
        hasFavIcon: false,
        color: 'gold',
      };
      return notifDetails;
  }
};

const getMailNotification = (action, data) => {
  const { alert_mail } = store.getState().settings;
  if (!alert_mail) return;

  switch (action) {
    case 'receive':
      notifDetails = {
        label: `${data.account.username} has sent you a new message`,
        action: 'Your turn',
        link: getNotifLink(data, true),
        color: 'blue',
        hasFavIcon: false,
      };
      return notifDetails;
    case 'unlock':
      notifDetails = {
        label: `${data.account.username} has unlocked your conversation!`,
        action: 'Send a message',
        link: getNotifLink(data, true),
        color: 'blue',
        hasFavIcon: false,
      };
      return notifDetails;
  }
};

const getFavoritedNotification = (action, data) => {
  const { alert_favorite } = store.getState().settings;
  if (!alert_favorite) return;

  notifDetails = {
    ...notifDetails,
    label: ` You've been favorited!`,
    action: ' Check them out',
    link: getNotifLink(data, false, true),
    hasFavIcon: true,
    color: 'red',
  };
  return notifDetails;
};

const getPokeNotification = (action, data) => {
  notifDetails = {
    ...notifDetails,
    label: `${getPronoun(data.profile.gender)} sent a reminder!`,
    action: 'Unlock the conversation',
    link: getNotifLink(data),
  };
  return notifDetails;
};

const getVideoCallNotification = (action, data) => {
  notifDetails = {
    label: `is calling you for a video chat`,
    action,
    link: getConversationLink(data),
    videoData: {
      ...data.extras,
      signal: data.extras.signal || null,
    },
    profilePhoto: data.profile.photo,
    callerName: data.account.username,
  };
  return notifDetails;
};

const getGiftNotification = (action, data) => {
  switch (action) {
    case 'sent': {
      const orderId = data.extras.order_id;
      const receiverId = data.extras.receiver_id;
      const redeemLink = `/profile/${receiverId}?rgstore_mode=redeem&rgstore_order_id=${orderId}#rgstore`;

      notifDetails = {
        ...notifDetails,
        label: `${data.account.username} sent you a gift!`,
        action: `Review Gift`,
        link: redeemLink,
        giftData: {
          ...data.extras,
        },
        hasFavIcon: false,
        color: 'red',
      };
      return notifDetails;
    }

    case 'accept':
      notifDetails = {
        ...notifDetails,
        label: `${data.account.username} accepted your gift!`,
        action: 'View her profile',
        link: getNotifLink(data),
        giftData: {
          ...data.extras,
        },
        hasFavIcon: false,
        color: 'blue',
      };
      return notifDetails;
  }
};

export const getResponse = (type: string, action: any, data: any): any => {
  const notifDetails = {
    ...notifDetails,
    label: `You've got a new ${type}!`,
    link: getNotifLink(data),
  };

  switch (type) {
    case 'video_call':
      return getVideoCallNotification(action, data);
    case 'favorites':
      return getFavoritedNotification(action, data);
    case 'offers':
      return getOfferNotification(action, data);
    case 'mail':
      return getMailNotification(action, data);
    case 'poke':
      return getPokeNotification(action, data);
    case 'gift':
      return getGiftNotification(action, data);
    case 'viewed_me':
      return;
    default:
      return notifDetails;
  }
};
