import React, { ReactElement } from 'react';

const StatusError = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 19.17 19.17">
      <defs />
      <g id="Layer_2" data-name="Layer 2" fill="#c00">
        <g className="status_error__cls-1" id="Layer_1-2" data-name="Layer 1">
          <g className="status-error__cls-1">
            <path
              className="status-error__cls-2"
              d="M4.77,1.29A9.37,9.37,0,0,1,9.59,0,9.38,9.38,0,0,1,14.4,1.29a9.54,9.54,0,0,1,3.49,3.49,9.38,9.38,0,0,1,1.29,4.81,9.37,9.37,0,0,1-1.29,4.81,9.55,9.55,0,0,1-3.49,3.49,9.39,9.39,0,0,1-4.81,1.29,9.38,9.38,0,0,1-4.81-1.29A9.55,9.55,0,0,1,1.29,14.4,9.38,9.38,0,0,1,0,9.59,9.38,9.38,0,0,1,1.29,4.77,9.54,9.54,0,0,1,4.77,1.29Zm6.38,10,.23-7.75a.24.24,0,0,0-.13-.22.46.46,0,0,0-.3-.1H8.21a.47.47,0,0,0-.3.1.24.24,0,0,0-.12.22L8,11.27a.27.27,0,0,0,.13.22.49.49,0,0,0,.3.09h2.31a.46.46,0,0,0,.29-.09A.32.32,0,0,0,11.16,11.27Zm0,4.29V13.19a.41.41,0,0,0-.11-.29.37.37,0,0,0-.27-.12H8.4a.43.43,0,0,0-.41.41v2.37A.43.43,0,0,0,8.4,16h2.4a.36.36,0,0,0,.27-.12A.41.41,0,0,0,11.18,15.57Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StatusError;
