import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
  page: 'Join',
};

export const enum JoinOnFocusVysionEventsEnum {
  DISPLAY_NAME = 'joinDisplayName',
  PHONE_NUMBER = 'joinPhoneNumber',
  ENTER_NEW_PHONE_NUMBER = 'joinEnterANewNumberPhoneNumber',
  ENTER_PHONE_VERIFICATION_CODE = 'join-enterPhoneVerificationCode-enterCode',
}

export const joinOnFocusVysionEvents = {
  [JoinOnFocusVysionEventsEnum.DISPLAY_NAME]: {
    ...commonValues,
    component: 'Display Name',
    eventName: 'join-displayName',
  },
  [JoinOnFocusVysionEventsEnum.PHONE_NUMBER]: {
    ...commonValues,
    component: 'Phone Number Entry',
    eventName: 'join-phoneNumber',
  },
  [JoinOnFocusVysionEventsEnum.ENTER_NEW_PHONE_NUMBER]: {
    ...commonValues,
    component: 'Enter a New Number Modal',
    eventName: 'join-enterANewNumber-phoneNumber',
    extraInfo: 'Phone Number Entry',
  },
  [JoinOnFocusVysionEventsEnum.ENTER_PHONE_VERIFICATION_CODE]: {
    ...commonValues,
    component: 'Enter Verification Code',
    eventName: 'join-enterVerificationCode-enterCode',
    extraInfo: 'Verification Code Entry',
  },
};
