/**
 * @Author: zachalam
 * @Date:   2017-04-12T10:38:53-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-04-12T10:38:53-07:00
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import WYPapp from './reducers/index';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash/throttle';
import createLogger from 'redux-logger';

const existingState = loadState();

const middleware = [thunk];

// remove logging in production
if (process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'prodfd') {
  middleware.push(createLogger());
}

const store = createStore(WYPapp, existingState, applyMiddleware(...middleware));

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// persist reducer state to ls at most every 2 seconds (on reducer change).
store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
      profile: store.getState().profile,
      search: store.getState().search,
      meta: store.getState().meta,
      settings: store.getState().settings,
    });
  }, 2000)
);

export default store;
