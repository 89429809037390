import React, { ReactElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDisableBodyScroll } from '../../../../../hooks/useDisableBodyScroll';
import { dimensions, textColor } from '../../../../../style';
import { IsMobileViewPort } from '../../../../../utils/helpers';
import Button from '../../../../../atoms/buttons/Button';
import AutoTopUpBaseModal from '../../../../../modules/modals/AutoTopUpBaseModal';
import RoundCheckIcon from '../../../../../atoms/icons/RoundCheckIcon';
import { handlePaymentVysionEvent } from '../../../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../../../utils/vysion';
import { PaymentViewVysionEventsEnum } from '../../../../../utils/vysion/payment/view';
import { PaymentClickVysionEventsEnum } from '../../../../../utils/vysion/payment/click';
import CreditCardOff from '../../../../../atoms/icons/CreditCardOff';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../../../../atoms/buttons/SecondaryButton';

const ModalHeader = styled('h3')(() => ({
  marginTop: 12,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '25px',
  textAlign: 'left',
  fontStyle: 'normal',
  width: '100%',
  marginBottom: 12,
  ...textColor.DARK_2,
}));

const ModalDescription = styled('div')(
  {
    padding: 30,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    flexDirection: 'column',
    textAlign: 'left',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const ModalContent = styled('div')({
  textAlign: 'left',
  fontSize: 16,
  lineHeight: '21px',
  ...textColor.DARK,
});

const ModalButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px 30px 20px',
});

const BuyCreditsButton = styled(Link)({
  borderColor: '#2B8FD7',
  backgroundColor: '#2B8FD7',
  color: '#FFFFFF',
  fontWeight: 600,
  display: 'flex',
  padding: '12px 22px',
  justifyContent: 'center',
  alignItems: 'baseline',
  gap: '6px',
  flex: '1 0 0',
  borderRadius: '90px',
  fontSize: 14,
  fontStyle: 'normal',
  lineHeight: '16px',
  marginLeft: 20,
  [dimensions.SCREEN_MAX_MD]: {
    maxWidth: 113,
    marginLeft: 0,
  },
});

const AutoTopupModal = (props: any): ReactElement => {
  const { onClose, ...rest } = props;
  useDisableBodyScroll(props.isOpen);

  useEffect(() => {
    if (props.isOpen) {
      handlePaymentVysionEvent({
        action: VYSION_ACTIONS.VIEW,
        event: PaymentViewVysionEventsEnum.VIEW_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL,
      });
    }
  }, [props.isOpen]);

  return (
    <AutoTopUpBaseModal
      onClose={() => {
        onClose();
        handlePaymentVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_CLOSE,
        });
      }}
      customStyle={{ content: { maxWidth: IsMobileViewPort ? '315px' : '380px' } }}
      {...rest}
      contentLabel=""
    >
      {props.error ? (
        <>
          <ModalDescription hasHeader={true} data-test-id="auto-topup-modal">
            <CreditCardOff customStyle={{ marginTop: 30 }} />
            <ModalHeader>No Payment Card on file</ModalHeader>
            <ModalContent>
              You must have a payment card on file to enroll in Auto Credit Top-Up. To add a card, simply turn on Auto
              Credit Top-Up during checkout when purchasing any credit package.
            </ModalContent>
          </ModalDescription>
          <ModalButtonGroup>
            <SecondaryButton
              data-test-id={`auto-topup-error-btn-dismiss`}
              onClick={() => {
                onClose();
              }}
              customStyle={{
                padding: '12px 22px',
                borderWidth: 1,
                height: 'auto',
                width: 'auto',
                minWidth: 98,
                fontSize: 14,
                fontStyle: 'normal',
                lineHeight: '16px',
              }}
              label="Dismiss"
            />
            <BuyCreditsButton
              to="/packages"
              id="btn-buy-credits"
              onClick={() => {
                onClose(true);
              }}
            >
              Buy Credits
            </BuyCreditsButton>
          </ModalButtonGroup>
        </>
      ) : (
        <ModalDescription hasHeader={true} data-test-id="auto-topup-modal">
          <RoundCheckIcon customFill={'#24A83A'} customStyle={{ marginTop: 30 }} />
          <ModalHeader>{props.subscribed ? `Auto Top-Up Successful!` : `Your settings have been saved!`}</ModalHeader>
          <ModalContent>
            {props.subscribed ? (
              <div>
                You will <b>auto top-up {props.selectPackage}</b> when your credit balance gets to{' '}
                <b>{props.creditThreshold} Credits</b> or below.
              </div>
            ) : (
              <div>You will no longer automatically repurchase credit packs.</div>
            )}
          </ModalContent>
          <Button
            data-test-id={`auto-topup-succes-btn`}
            onClick={() => {
              onClose(true);
              handlePaymentVysionEvent({
                action: VYSION_ACTIONS.CLICK,
                event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_OK,
              });
            }}
            customStyle={{
              borderColor: '#2B8FD7',
              backgroundColor: '#2B8FD7',
              color: '#FFFFFF',
              fontWeight: 600,
              padding: '12px 22px',
              margin: '0 auto',
              marginTop: 30,
              width: 70,
            }}
          >
            OK
          </Button>
        </ModalDescription>
      )}
    </AutoTopUpBaseModal>
  );
};

AutoTopupModal.defaultProps = {
  header: 'Auto Top-Up',
  onClose: () => null,
  subscribed: false,
};

AutoTopupModal.propTypes = {
  selectPackage: PropTypes.string,
  creditThreshold: PropTypes.number,
  subscribed: PropTypes.bool,
  error: PropTypes.bool,
  header: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  closeOnOverlayClick: PropTypes.bool,
};

export default AutoTopupModal;
