import styled from '@emotion/styled';
import { paymentTheme } from '../../../style';
import { getBrowserDetails } from '../../../utils/helpers';

const isMobile = getBrowserDetails().isMobile;

export const DiscountDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 18,
  color: paymentTheme.SUMMARY.color,
  fontWeight: 400,
});

export const SummaryAmountText = styled('div')({
  color: paymentTheme.SUMMARY.color,
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '21px',
  letterSpacing: '-0.15px',
});

export const PromoCodeContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexFlow: isMobile ? 'wrap' : 'initial',
});

export const DiscountText = styled('div')({
  color: '#24A83A',
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '-0.15px',
});

export const ErrorDisplay = styled('div')({
  color: '#F00',
  fontStyle: 'normal',
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '-0.15px',
  marginLeft: isMobile ? 0 : 10,
  flex: isMobile ? '1 0 100%' : 'auto',
});

export const PromoInputContainer = styled('div')({
  display: 'flex',
  alignItems: isMobile ? 'initial' : 'center',
  flexFlow: isMobile ? 'column' : 'initial',
});
