import React, { ReactElement } from 'react';

interface FDTextLogoProps {
  width: any;
  height: any;
  viewbox: any;
  customStyle: any;
}

const FDTextLogo = ({ width, height, viewbox, customStyle }: FDTextLogoProps): ReactElement => {
  return (
    <svg width={width} height={height} viewBox={viewbox} style={customStyle}>
      <g>
        <path
          fill="#2378bd"
          d="M237.46,10.16h16.2a52.76,52.76,0,0,1,7.73.53c11.78,1.72,21.6,10.8,21.6,26.77,0,13.5-8.1,23.93-20.18,26.63a42.72,42.72,0,0,1-9,.82H237.46Zm15.45,45.75a38.34,38.34,0,0,0,7.28-.45c6.52-1.57,12.45-8.55,12.45-17.84,0-10.21-4.58-16.43-13-18.08a44.83,44.83,0,0,0-6.91-.38h-5V55.91Z"
        />
        <path
          fill="#2378bd"
          d="M282.53,53.79c0-8.25,6.83-11.25,15.68-11.25a27.87,27.87,0,0,1,7.2,1.05,12,12,0,0,0-.3-3.3c-.6-1.2-2.78-1.88-4.8-1.88a19.54,19.54,0,0,0-9.9,2.4l-4.13-7.87a29.05,29.05,0,0,1,14.48-3.53c7.95,0,13.35,4.43,14.4,8.78a33.88,33.88,0,0,1,.6,8V64.81h-8.85l-.45-2.47c-1.5,1.42-3.68,3.22-8.85,3.22C289.66,65.56,282.53,62.11,282.53,53.79Zm22.88.22V51.69a20.49,20.49,0,0,0-7.2-1.28c-3.23,0-5.4.83-5.4,3.38s2.17,2.77,5.25,2.77A14.22,14.22,0,0,0,305.41,54Z"
        />
        <path
          fill="#2378bd"
          d="M336.73,54.64c-2.67,1.44-4.24,1.88-5.43,1.88-1.35,0-1.57-1-1.72-2s-.22-3.23-.22-5.25V37.41h6.79l1.46-7.29h-8.25V19H319.08V50.07a35.06,35.06,0,0,0,.52,7.58c1.13,4.57,5.18,7.87,11.63,7.87,2.68,0,5.56-.69,10.7-3.22A20.94,20.94,0,0,1,336.73,54.64Z"
        />
        <path
          fill="#2378bd"
          d="M338.82,47.07c0-9.82,8.4-18.07,19.65-18.07,13.42,0,19,8.62,19,18.22v.9L377.37,50h-27.9c1,4.87,5.17,6.15,9.15,6.15a24.12,24.12,0,0,0,13.27-3.38l3.9,8.1a34.41,34.41,0,0,1-17.32,4.28C347.67,65.15,338.82,57.27,338.82,47.07Zm27.6-3.45c-.08-2.25-1.73-6-8.18-6-2.92,0-7.5,1.43-8.55,6Z"
        />
        <path fill="#2378bd" d="M97.33,10h35.48v9h-25.2v14H130v9H107.61V64.77H97.33Z" />
        <path
          fill="#2378bd"
          d="M174.85,39.65A12.58,12.58,0,0,1,178,31.22a15.66,15.66,0,0,0-7.72-1.85,12.79,12.79,0,0,0-8.93,4l-.45-3.23h-9.37V64.77h10.27V42.27a8.67,8.67,0,0,1,6.83-3.89,9.24,9.24,0,0,1,6.31,2.28C174.88,40.33,174.85,40,174.85,39.65Z"
        />
        <path
          fill="#2378bd"
          d="M175.05,58.4l6.75-6.53c2.33,2.33,4.95,4.8,9.6,4.8,2,0,3.6-.52,3.6-1.8,0-1.5-2.17-2.1-4.5-3.07l-2.7-1.13c-4.8-2-9.45-4.57-9.45-11,0-6.3,5.4-10.28,13.05-10.28a20.12,20.12,0,0,1,13,4.35l-4.8,7.43a12.33,12.33,0,0,0-8.1-2.93c-1.8,0-2.78.53-2.78,1.43s1,1.35,2.4,2l4.05,1.65c4.58,1.87,10.13,4.12,10.13,11.17,0,7.65-6.38,11.18-14.18,11.18A21.59,21.59,0,0,1,175.05,58.4Z"
        />
        <path
          fill="#2378bd"
          d="M209,57.65a35.06,35.06,0,0,1-.52-7.58V19h10.27v11.1h8.26l-1.46,7.29h-6.8V49.25c0,2,.15,4.27.23,5.25s.37,2,1.72,2c1.8,0,4-.9,7.43-2.92l3.75,8.47a22.74,22.74,0,0,1-11.25,3.45C214.22,65.52,210.17,62.22,209,57.65Z"
        />
        <path
          fill="#2378bd"
          d="M143,23.67a5.81,5.81,0,0,0,5.78-5.7A5.93,5.93,0,0,0,143,12.2,5.81,5.81,0,0,0,137.3,18,5.7,5.7,0,0,0,143,23.67Zm-9.79,14.25,4.69,2.73V64.77h10.35V30.12H137.9Z"
        />
      </g>
    </svg>
  );
};

export default FDTextLogo;
