import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { backgroundTheme, buttonTheme, type } from '../style';

import Camera from '../atoms/icons/Camera';
import { commonIcons, generalIcons } from './icons/materialIcons';
import { auth10tixVerification } from '../config/Master';
import { getBrowserDetails, ternaryFunc } from '../utils/helpers';
import RoundedCheckbox from './RoundedCheckbox';

const CardContainer = styled('div')(
  {
    position: 'relative',
  },
  ({ cardWidth, cardMargin, customCardStyle }) => ({
    width: cardWidth,
    margin: cardMargin,
    ...customCardStyle,
  })
);

const CardBorder = styled('div')({
  borderRadius: '6px',
  overflow: 'hidden',
});

const PhotoAspectRatio = styled('div')({
  paddingTop: '133%',
  position: 'relative',
});

const AccountDetailsContainer = styled('div')({
  color: 'white',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  zIndex: 3,
});

const AccountDetails = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'bottom',
    padding: '10px',
    flexWrap: 'wrap',
    width: '75%',
    textOverflow: 'ellipsis',
  },
  ({ detailsPadding }) => ({
    padding: detailsPadding,
  })
);

const UsernameContainer = styled('div')({
  ...type.MD,
  width: '100%',
  color: buttonTheme.GreyAndWhite,
});

const Username = styled('div')(
  {
    width: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  ({ overrideWidth }) => ({
    width: overrideWidth,
  })
);

const UserDetails = styled('div')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
  width: '85%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const PhotoCountDetails = styled('div')(
  {
    ...type.SM,
    position: 'absolute',
    padding: '10px',
    bottom: '0px',
    right: '0px',
  },
  ({ detailsPadding }) => ({
    padding: detailsPadding,
  })
);

const PhotoCount = styled('span')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
});

const PublicPhotoCount = styled('div')({
  borderBottom: '1px solid red',
});

const Gradient = styled('div')({
  background:
    'rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 70%, rgb(0, 0, 0) 100%) repeat scroll 0% 0%',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
});

const Img = styled('div')(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  ({ imgUrl }) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
);

const OnlineDot = styled('div')({
  height: '7px',
  width: '7px',
  backgroundColor: '#5EBE5E',
  borderRadius: '50%',
  display: 'inline-block',
});

const StyledPhotoCount = styled('span')({
  alignSelf: 'flex-end',
});

const Icon = styled('span')({
  paddingRight: '6px',
});

const VerifiedBadge = styled('span')({
  zIndex: 2,
  display: 'inline-block',
  marginLeft: 4,
});

const customCheckBoxStyle = {
  position: 'relative',
  alignContent: 'center',
};

const checkboxContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '11px',
  marginBottom: '10px',
};

interface CardProps {
  imgUrl: string;
  cardWidth: string;
  cardMargin: string;
  online: boolean;
  username: string;
  age: string;
  city: string;
  photoCount: number;
  privatePhotoCount: number;
  hashId: string;
  onClick: (id: string) => void;
  id: string;
  detailsPadding: string;
  isVerified: boolean;
  screenIsMobile?: boolean;
  link?: string;
  showSelectUserCheckbox?: boolean;
  checkBoxRef?: any;
  index?: number;
  isChecked?: boolean;
  customCardStyle?: React.CSSProperties;
}

const Card = ({
  imgUrl,
  cardWidth,
  cardMargin,
  online,
  username,
  age,
  city,
  photoCount,
  privatePhotoCount,
  hashId,
  onClick,
  id,
  detailsPadding,
  isVerified = false,
  link,
  showSelectUserCheckbox = false,
  checkBoxRef = null,
  index = 0,
  isChecked = false,
  customCardStyle,
}: CardProps): JSX.Element => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <CardContainer
      id={id}
      className="card-items"
      cardWidth={cardWidth}
      cardMargin={cardMargin}
      customCardStyle={customCardStyle}
      onClick={() => onClick(hashId)}
    >
      <CardBorder>
        <Link to={link || `/profile/${hashId}`}>
          <PhotoAspectRatio>
            <AccountDetailsContainer>
              <AccountDetails
                style={{ width: '75%' }}
                className="account-details-container"
                detailsPadding={detailsPadding}
              >
                <UsernameContainer>
                  <Username
                    data-test-id="data-member-username"
                    overrideWidth={ternaryFunc(isVerified, 'auto', '100%')}
                    style={{ maxWidth: getBrowserDetails().isMobile ? '80%' : '85%' }}
                  >
                    {username}
                  </Username>
                  {auth10tixVerification && isVerified && (
                    <VerifiedBadge
                      data-test-id="data-verified-badge"
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <commonIcons.check.badge showToolTip={showToolTip} />
                    </VerifiedBadge>
                  )}
                </UsernameContainer>
                <UserDetails data-test-id="data-member-age-city">
                  {online ? <OnlineDot /> : ''} {age} &middot; {city}
                </UserDetails>
              </AccountDetails>
              <PhotoCountDetails detailsPadding={detailsPadding} data-test-id="data-member-photo-count">
                <PhotoCount>
                  <PublicPhotoCount>
                    <Icon data-test-id="data-camera-icon">
                      <Camera />
                    </Icon>
                    <StyledPhotoCount>{photoCount}</StyledPhotoCount>
                  </PublicPhotoCount>
                  <Icon data-test-id="data-eye-hidden-icon">
                    <generalIcons.eye.hidden />
                  </Icon>
                  <StyledPhotoCount>{privatePhotoCount}</StyledPhotoCount>
                </PhotoCount>
              </PhotoCountDetails>
            </AccountDetailsContainer>
            <Gradient />
            <Img className="data-avatar-image-url" imgUrl={imgUrl} />
          </PhotoAspectRatio>
        </Link>
      </CardBorder>
      {showSelectUserCheckbox && (
        <RoundedCheckbox
          ref={element => {
            checkBoxRef.current[index] = element;
          }}
          data={id}
          containerStyle={checkboxContainerStyle}
          customStyle={customCheckBoxStyle}
          mediaIds={null}
          isChecked={isChecked}
          onChange={() => onClick(hashId)}
        />
      )}
    </CardContainer>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  city: PropTypes.string,
  username: PropTypes.string,
  hashId: PropTypes.string,
  photoCount: PropTypes.number,
  privatePhotoCount: PropTypes.number,
  online: PropTypes.bool,
  cardWidth: PropTypes.string,
  cardMargin: PropTypes.string,
  detailsPadding: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  retinaImgUrl: PropTypes.string,
  screenSizeIsMobile: PropTypes.bool,
};

Card.defaultProps = {
  cardWidth: '300px',
  id: '',
};

export default Card;
