import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { type } from '../../style';

const StyledButton = styled('button')(
  {
    fontWeight: 600,
    borderStyle: 'none',
    backgroundColor: '#EAF4FB',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    padding: '0 16px',
    margin: '0 5px',
    '&:hover': {
      border: '1px solid #3E79A5',
    },
    ...type.MD,
  },
  ({ customStyle }) => ({ ...customStyle })
);

const fitStyledButton = {
  display: 'block',
  boxSizing: 'border-box',
  width: '100%',
};

const Content = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const IconContainer = styled('div')(
  {
    display: 'inline-flex',
    justifyContent: 'center',
  },
  ({ iconPosition }) =>
    iconPosition === 'right'
      ? {
          order: 2,
          marginLeft: 4,
        }
      : {
          marginRight: 4,
        },
  ({ noMargin }) => noMargin && { marginRight: 0 }
);

const RotateButton = (props: any): ReactElement => {
  const { buttonType = 'default', size, fit, customStyle, icon, iconPosition, children, buttonRef, ...rest } = props;

  return (
    <StyledButton
      ref={buttonRef}
      buttontype={buttonType}
      size={size}
      style={fit ? fitStyledButton : {}}
      customStyle={customStyle}
      {...rest}
    >
      <Content>
        {icon && (
          <IconContainer iconPosition={iconPosition} noMargin={React.Children.count(children) === 0}>
            {icon}
          </IconContainer>
        )}
      </Content>
    </StyledButton>
  );
};

RotateButton.defaultProps = {
  buttonType: 'normal',
  size: 'medium',
  iconPosition: 'left',
  customStyle: {},
};

RotateButton.propTypes = {
  /** Determines how button will be rendered */
  buttonType: PropTypes.oneOf(['normal', 'primary', 'link', 'chromeless', 'success', 'error', 'cancel']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Determines if button should be full width of container */
  fit: PropTypes.bool,
  /** Icon to be displayed next to button text */
  icon: PropTypes.any,
  /** Position of icon next to button text */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** Define custom button styles if necessary */
  customStyle: PropTypes.object,
};

export default RotateButton;
