import { VYSION_ACTIONS } from '..';
import { getLocalItem } from '../../../common';
import {
  DEFAULT_REASON,
  EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  getFeatureDetails,
} from '../../../constants/evidently';
import store from '../../../store';
import { EventData, handleVysionEvent, vysionTrack } from '../vysionService';
import { joinClickVysionEvents } from './click';
import { joinOnFocusVysionEvents } from './onFocus';
import { JoinViewVysionEvents } from './view';

export const events = {
  [VYSION_ACTIONS.ON_FOCUS]: { ...joinOnFocusVysionEvents },
  [VYSION_ACTIONS.CLICK]: { ...joinClickVysionEvents },
  [VYSION_ACTIONS.VIEW]: { ...JoinViewVysionEvents },
};

const getDistinctId = (featureStorageKey: EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS): string | undefined => {
  const featureStorageKeyData = JSON.parse(getLocalItem(featureStorageKey));

  if (featureStorageKeyData !== null && featureStorageKeyData?.reason !== DEFAULT_REASON) {
    return featureStorageKeyData.userId;
  }

  return undefined;
};

export const handleJoinVysionEvent = (data: EventData): void => {
  if (data.event.toLowerCase().includes('phone')) {
    data.distinctId = getDistinctId(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.PHONE_NUMBER_SIGNUP) as string;
  } else {
    data.distinctId = getDistinctId(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MANDATORY_STEP_IN_REGISTRATION) as string;
  }

  handleVysionEvent<typeof events>(data, events);
};

export const mergeJoinVysionDistinctIds = (userId: string): void => {
  const phoneSignupDistinctId = getDistinctId(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.PHONE_NUMBER_SIGNUP);

  if (phoneSignupDistinctId) {
    vysionTrack(
      {
        user_id: userId,
        distinct_id: phoneSignupDistinctId,
      },
      'mergeDistinctIdUserId'
    );
  }

  const mandatoryStepDistinctId = getDistinctId(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MANDATORY_STEP_IN_REGISTRATION);

  if (mandatoryStepDistinctId) {
    vysionTrack(
      {
        user_id: userId,
        distinct_id: mandatoryStepDistinctId,
      },
      'mergeDistinctIdUserId'
    );
  }
};

export const mergeExperimentIds = (hashId: string, featureName: string): void => {
  const props = store.getState();
  const experimentData = getFeatureDetails(props.settings?.evidently, featureName);

  if (!experimentData) return;

  const { userId, reason } = experimentData;

  if (reason === DEFAULT_REASON || !userId) {
    return;
  }

  vysionTrack(
    {
      user_id: hashId,
      distinct_id: userId,
    },
    'mergeDistinctIdUserId'
  );
};
