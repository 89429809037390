import React, { ReactElement } from 'react';
interface IRoundPrivateIconProps {
  customStyle?: any;
}

const RoundPrivateIcon = ({ customStyle, ...rest }: IRoundPrivateIconProps): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M34.0727 38L28.9182 33.1793C28.2023 33.3923 27.4815 33.5522 26.7557 33.659C26.0292 33.7651 25.2773 33.8182 24.5 33.8182C21.4114 33.8182 18.6602 33.0101 16.2466 31.3939C13.833 29.7769 12.0841 27.681 11 25.1061C11.4295 24.08 11.9716 23.1263 12.6261 22.245C13.2807 21.3645 14.0273 20.5758 14.8659 19.8788L11.4909 16.6263L13.2091 15L35.7909 36.3737L34.0727 38ZM24.5 30.3333C24.725 30.3333 24.9345 30.3237 25.1284 30.3043C25.3231 30.2849 25.533 30.2462 25.758 30.1881L19.1307 23.9154C19.0693 24.1284 19.0284 24.327 19.008 24.5113C18.9875 24.6948 18.9773 24.8931 18.9773 25.1061C18.9773 26.5581 19.514 27.7925 20.5875 28.8093C21.6617 29.8253 22.9659 30.3333 24.5 30.3333ZM33.4591 30.8561L29.5625 27.197C29.7057 26.8678 29.8182 26.5341 29.9 26.1957C29.9818 25.8565 30.0227 25.4933 30.0227 25.1061C30.0227 23.654 29.486 22.4196 28.4125 21.4028C27.3383 20.3868 26.0341 19.8788 24.5 19.8788C24.0909 19.8788 23.7072 19.9175 23.3488 19.9949C22.9913 20.0724 22.6386 20.1886 22.2909 20.3434L19.1614 17.3813C20 17.0522 20.8591 16.8052 21.7386 16.6402C22.6182 16.476 23.5386 16.3939 24.5 16.3939C27.5886 16.3939 30.3398 17.202 32.7534 18.8182C35.167 20.4352 36.9159 22.5311 38 25.1061C37.5295 26.2483 36.911 27.3085 36.1444 28.2866C35.3769 29.2639 34.4818 30.1204 33.4591 30.8561ZM27.783 25.5126L24.1011 22.0278C24.6739 21.931 25.2008 21.9743 25.6819 22.1579C26.1621 22.3422 26.5761 22.6086 26.9239 22.9571C27.2716 23.3056 27.5224 23.7075 27.6762 24.1628C27.8292 24.6174 27.8648 25.0673 27.783 25.5126Z"
        fill="#BC2028"
      />
    </svg>
  );
};

export default RoundPrivateIcon;
