import React, { ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import { dimensions, textColor, backgroundTheme } from '../../../style';
import { lazilyEvaluateVal, ternaryFunc } from '../../../utils/helpers';
import { renderAvatar } from '../../../common';
import OfferCard from '../../../atoms/OfferCard';
import OfferActionButton from './OfferActionButton';
import { vysionTrack } from '../../../utils/vysion/vysionService';
import useMixPanel from '../../../hooks/useMixPanel';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../constants/mixpanel';

const ResultItem = styled('div')(
  {
    ...textColor.DARKER,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: backgroundTheme.personalInfo,
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
    position: 'relative',
    maxWidth: 228,
    width: '25%',
    ['@media (max-width: 1080px)']: {
      width: '23%',
    },
    ['@media (max-width: 860px)']: {
      width: '22%',
    },
    [dimensions.SCREEN_MAX_MD]: {
      width: '22%',
    },
    [dimensions.SCREEN_MAX_SM]: {
      margin: '10px 9px',
      width: '45%',
    },
    [dimensions.SCREEN_MIN_XS_4]: {
      margin: '10px 8px',
      width: '45%',
    },
  },
  ({ enableSlider }) =>
    enableSlider && {
      [dimensions.SCREEN_MAX_SM]: {
        width: '100%',
        maxWidth: '100%',
      },
    }
);

interface OfferProfileGridViewProps {
  activeRoute: any;
  offerKey: any;
  profile: any;
  hashId: any;
  isFavorite: any;
  isFavoritedMe: any;
  isMatched: any;
  screenSizeIsMobile: any;
  offer: any;
  user: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  ignoreOfferFromList: any;
  unlockMessage(): void;
  updateOffer(): void;
  pageTitle: any;
  enableSlider?: boolean;
}

const OfferProfileGridView = ({
  activeRoute,
  offerKey,
  profile,
  hashId,
  isFavorite,
  isFavoritedMe,
  isMatched,
  screenSizeIsMobile,
  offer,
  user,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  ignoreOfferFromList,
  unlockMessage,
  updateOffer,
  pageTitle,
  enableSlider,
}: OfferProfileGridViewProps): ReactElement => {
  const { trackProfileView, setMixpanelDataByKeyHandler } = useMixPanel();
  const profileData = profile.profile.data;
  const avatar = useMemo(
    () =>
      lazilyEvaluateVal(profileData, 3, false, 'avatar', 'data', 'urls') && renderAvatar(profileData.avatar.data.urls),
    [profileData?.avatar?.data?.urls]
  );
  const age = profileData.age;
  const city = profileData.city;
  const username = profile.username ? profile.username : '';
  const photoCount = parseInt(profileData.approved_public_photos_count);
  const privatePhotoCount = profileData.private_photos_count ? parseInt(profileData.private_photos_count) : 0;
  const online = profileData.online;
  const dataKey = `offer_${offerKey}`;
  const userVerified = profileData.id_verified;

  const trackCardClickEvent = () => {
    vysionTrack({
      action: 'click',
      component: 'Profile Card',
      extraInfo: activeRoute === 'new' ? 'receivedOffers' : 'sentOffers',
      page: 'Offers',
    });

    trackProfileView({ Source: pageTitle, User: hashId });

    setMixpanelDataByKeyHandler({
      key: EVENT_NAMES.OFFER_MADE,
      payload: {
        source: user.isGenerous
          ? TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedRequest
          : TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewReceivedOffers,
      },
    });
  };

  return (
    <ResultItem className="css-offers-list-item grid-list-item" enableSlider={enableSlider}>
      <OfferCard
        key={dataKey}
        age={age}
        city={city}
        username={username}
        hashId={hashId}
        photoCount={photoCount}
        privatePhotoCount={privatePhotoCount}
        online={online}
        imgUrl={avatar}
        cardWidth={ternaryFunc(screenSizeIsMobile, '100%', '100%')}
        cardMargin={ternaryFunc(screenSizeIsMobile, '5px 1%', '.5%')}
        detailsPadding={ternaryFunc(screenSizeIsMobile, '5px', '10px')}
        isVerified={userVerified}
        onClick={trackCardClickEvent}
        favorited={isFavorite}
        favoritedMe={isFavoritedMe}
        isMatched={isMatched}
        offer={offer}
        user={user}
        screenIsMobile={screenSizeIsMobile}
      />
      <OfferActionButton
        offer={offer}
        user={user}
        otherAccount={profile}
        createNewOffer={createNewOffer}
        sendSuggestion={sendSuggestion}
        clearErrors={clearErrors}
        errors={errors}
        showWarn={showWarn}
        acceptOffer={acceptOffer}
        counterOffer={counterOffer}
        isMatched={isMatched}
        offerKey={offerKey}
        viewType="grid"
        isSuggestRequest={offer && offer.data && offer.data.suggest}
        ignoreOffer={ignoreOffer}
        ignoreOfferFromList={ignoreOfferFromList}
        unlockMessage={unlockMessage}
        updateOffer={updateOffer}
        pageTitle={pageTitle}
        enableSlider={enableSlider}
      />
    </ResultItem>
  );
};

export default OfferProfileGridView;
