import React, { ReactElement } from 'react';
interface ICounterInfoIcon {
  onTouchStart?: () => any;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => any;
}

const DiscoverCounterInfoIcon = ({ ...rest }: ICounterInfoIcon): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" {...rest}>
      <path
        d="M12.9929 19.5C13.3362 19.5 13.6263 19.3832 13.8633 19.1496C14.1003 18.916 14.2188 18.6266 14.2188 18.2812V12.5938C14.2188 12.2484 14.1026 11.959 13.8704 11.7254C13.6382 11.4918 13.3504 11.375 13.0071 11.375C12.6638 11.375 12.3737 11.4918 12.1367 11.7254C11.8997 11.959 11.7812 12.2484 11.7812 12.5938V18.2812C11.7812 18.6266 11.8974 18.916 12.1296 19.1496C12.3618 19.3832 12.6496 19.5 12.9929 19.5ZM12.9929 8.9375C13.3362 8.9375 13.6263 8.82139 13.8633 8.58917C14.1003 8.35693 14.2188 8.06917 14.2188 7.72589C14.2188 7.38259 14.1026 7.09245 13.8704 6.85547C13.6382 6.61849 13.3504 6.5 13.0071 6.5C12.6638 6.5 12.3737 6.61611 12.1367 6.84833C11.8997 7.08057 11.7812 7.36833 11.7812 7.71161C11.7812 8.05491 11.8974 8.34505 12.1296 8.58203C12.3618 8.81901 12.6496 8.9375 12.9929 8.9375ZM13.0093 26C11.2201 26 9.53559 25.6615 7.95573 24.9844C6.37587 24.3073 4.99349 23.3763 3.80859 22.1914C2.6237 21.0065 1.69271 19.6246 1.01562 18.0457C0.338542 16.4668 0 14.7797 0 12.9845C0 11.1893 0.338542 9.50738 1.01562 7.9388C1.69271 6.37023 2.6237 4.99349 3.80859 3.80859C4.99349 2.6237 6.37539 1.69271 7.95431 1.01562C9.53324 0.338542 11.2203 0 13.0155 0C14.8107 0 16.4926 0.338542 18.0612 1.01562C19.6298 1.69271 21.0065 2.6237 22.1914 3.80859C23.3763 4.99349 24.3073 6.37275 24.9844 7.94639C25.6615 9.52004 26 11.2015 26 12.9907C26 14.7799 25.6615 16.4644 24.9844 18.0443C24.3073 19.6241 23.3763 21.0065 22.1914 22.1914C21.0065 23.3763 19.6272 24.3073 18.0536 24.9844C16.48 25.6615 14.7985 26 13.0093 26Z"
        fill="#AFB0B1"
      />
    </svg>
  );
};

export default DiscoverCounterInfoIcon;
