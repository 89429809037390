import React, { ReactElement } from 'react';

interface KountLogoProps {
  details: any;
  timestamp: any;
}

const KountLogo = ({ details, timestamp }: KountLogoProps): ReactElement | null => {
  if (!details.enabled) {
    return null;
  }

  const kountParams = `?m=${details.merchant_id}&s=${timestamp}`;
  const iFrameSrc = details => `${details.htm_url}${kountParams}`;
  const imgSrc = details => `${details.gif_url}${kountParams}`;
  return (
    <iframe id="kountframe" style={{ display: 'none' }} src={iFrameSrc(details)}>
      <img src={imgSrc(details)} />
    </iframe>
  );
};

export default KountLogo;
