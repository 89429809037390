import React, { FC, ReactElement, useEffect } from 'react';
import styled from '@emotion/styled';
import { dimensions } from '../../../style';
import ButtonSet from '../../blocks/ButtonSet';
import Button from '../../../atoms/buttons/Button';
import typography from '../../../theme/typography';
import { theme } from '../../../theme';
import borders from '../../../theme/borders';
import IconExclamationPointCircle from '../../../atoms/icons/IconExclamationPointCircle';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import IconHelpfulTips from '../../../atoms/icons/IconHelpfulTips';
import IconCreditsCircle from '../../../atoms/icons/IconCreditsCircle';
import { SvgIcon } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import {
  MixpanelInfoModalShownSourcePage,
  mixpanelTrackTooManyOffersModalShown,
} from '../../../utils/mixpanel/infoModalShown';
import { EVENT_NAMES } from '../../../constants/mixpanel';
import useMixPanel from '../../../hooks/useMixPanel';

const ContainerDiv = styled('div')({
  maxWidth: 340,
  paddingBottom: 8,
  margin: 'auto',

  [dimensions.SCREEN_MAX_SM]: {
    maxWidth: 255,
    paddingTop: 30,
    paddingBottom: 20,
  },
});

const HeaderDiv = styled('div')({
  marginTop: 26,

  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 12,
  },
});

const ButtonDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
});

const IconDiv = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  marginTop: 10,
  marginBottom: 10,
});
const ModalTitle = styled('span')({
  color: typography[theme].heading,
  fontSize: 22,
  lineHeight: '27px',
  fontStyle: 'normal',
  fontWeight: 600,
  display: 'block',
  marginBottom: 10,
});

const ModalContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  fontSize: 16,
  color: typography[theme].primaryText,
  fontWeight: 400,
  lineHeight: '21px',
  paddingRight: 10,
});

const HelpfulTipsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'left',
});

const TipsContent = styled('div')({
  padding: 0,
});

const TipsTitle = styled('p')({
  color: '#BC2028',
  fontWeight: 700,
  margin: '0 0 0 11px',
});

const TipsBody = styled('p')({
  margin: '10px 10px 0 11px',
});

const LineSeparator = styled('div')({
  height: 1,
  borderTop: `1px solid ${borders[theme].primary}`,
  margin: '10px 0',
});

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '24px',
  height: '24px',
  position: 'absolute',
  top: 20,
  right: 20,
  zIndex: 1000,

  [dimensions.SCREEN_MAX_SM]: {
    top: 30,
    right: 30,
  },
});

const closeButton = {
  fontSize: 20,
  color: '#8F8F8F',
};
interface Props {
  onClose: () => void;
  source?: MixpanelInfoModalShownSourcePage;
  type?: string;
}
const TooManyPendingOffersModal: FC<Props> = ({ onClose, source, type }): ReactElement => {
  const navigate = useNavigate();
  const { setMixpanelDataByKeyHandler } = useMixPanel();

  useEffect(() => {
    mixpanelTrackTooManyOffersModalShown(source);
  }, []);

  return (
    <ContainerDiv>
      <CloseBtn onClick={onClose}>
        <SvgIcon component={CancelRounded} style={closeButton} />
      </CloseBtn>
      <HeaderDiv>
        <IconDiv>
          <IconExclamationPointCircle
            customStyle={{
              width: 40,
              height: 40,
            }}
          />
        </IconDiv>
        <ModalTitle>Too Many Pending Offers </ModalTitle>
        <ModalContent>
          {type && type === 'sent'
            ? `You must cancel some of your pending offers first.`
            : `You must Accept and Unlock or Decline some offers first.`}
        </ModalContent>
      </HeaderDiv>
      <LineSeparator />
      <HelpfulTipsContainer>
        <div>
          <IconHelpfulTips />
        </div>
        <TipsContent>
          <TipsTitle>Helpful Tip!</TipsTitle>
          <TipsBody>Purchase some credits to remove the max pending offers limit.</TipsBody>
        </TipsContent>
      </HelpfulTipsContainer>
      <ButtonSet align="center">
        <PrimaryButton
          label="Buy Credits"
          color="blue"
          size="small"
          customStyle={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 10,
            maxWidth: 190,
          }}
          withIconLeft={
            <IconCreditsCircle
              customStyle={{
                marginRight: 6,
              }}
            />
          }
          onClick={() => {
            setMixpanelDataByKeyHandler({
              key: EVENT_NAMES.CREDITS_PURCHASED,
              payload: {
                Source: 'Too Many Offers Modal',
              },
            });

            navigate('/packages?newPayWall=true&unlimitedAccess=true');
            onClose();
          }}
        />
      </ButtonSet>
      <LineSeparator />
      <ButtonDiv>
        <ButtonSet align="center">
          <Button
            buttonType="chromeless"
            data-test-id="s"
            customStyle={{
              fontSize: 16,
              fontWeight: 400,
              minWidth: 134,
              maxWidth: 145,
              color: borders.light.secondary,
              height: 'inherit',
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </ButtonSet>
      </ButtonDiv>
    </ContainerDiv>
  );
};

export default TooManyPendingOffersModal;
