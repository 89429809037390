import React, { useState, useEffect } from 'react';
import _find from 'lodash/find';
import * as Sentry from '@sentry/react';
import QuickOfferModal from './QuickOfferModal';
import useEvidently from '../../hooks/useEvidently';
import { getLocalItem } from '../../common';
import { getQuickOfferUsers } from '../../sdk/ProfileSDK';
import {
  ONBOARDING_MANDATORY_FIELDS_AM_FEATURE_NAME,
  ONBOARDING_MANDATORY_FIELDS_GM_FEATURE_NAME,
  ALLBUTPHOTO_MANDATORY,
  ALL_MANDATORY,
  OnboardingMandatoryFieldsVariations,
  ALL_2PHOTOS_MANDATORY,
  ALL_3PHOTOS_MANDATORY,
} from '../../utils/evidentlyFeatures/onboardingFieldsMandatory/common';

interface QuickOfferModalProps {
  profile: any;
  clearErrors: any;
  id: string;
  postQuickOffer: (formData: { accounts: string[]; price: number }) => any;
}

const QuickOfferModalInitial: React.FC<QuickOfferModalProps> = props => {
  const [isQuickOfferModalOpen, setIsQuickOfferModalOpen] = useState(false);
  const [userProfiles, setUserProfiles] = useState([]);
  const { profile, clearErrors, postQuickOffer, id } = props;

  const { useActiveVariation } = useEvidently();

  const isGenerous = profile?.profile?.data?.account_type === 'Generous';

  const onboardingFeatureName = isGenerous
    ? ONBOARDING_MANDATORY_FIELDS_GM_FEATURE_NAME
    : ONBOARDING_MANDATORY_FIELDS_AM_FEATURE_NAME;

  const onBoardingMandatoryVariant = useActiveVariation(onboardingFeatureName) as OnboardingMandatoryFieldsVariations;

  const hasAddedPhoto = sessionStorage.getItem('hasAddedPhoto');
  const isMandatoryAndHasPhoto =
    onBoardingMandatoryVariant &&
    [ALL_MANDATORY, ALLBUTPHOTO_MANDATORY, ALL_2PHOTOS_MANDATORY, ALL_3PHOTOS_MANDATORY].includes(
      onBoardingMandatoryVariant
    ) &&
    hasAddedPhoto;

  const offer = _find(['offer', 'data'], profile) || null;
  const profileLimit = 8;

  const getUserProfileList = async () => {
    try {
      const response = await getQuickOfferUsers(profileLimit);
      if (response?.data?.data.length > 0) {
        const filteredAccounts = response.data.data.filter(account => typeof account?.profile !== 'undefined');
        setUserProfiles(filteredAccounts);
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: { component: 'QuickOffer', action: 'getUserListAPIError' },
      });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('quickOfferSubmitted') === 'true' && hasAddedPhoto) {
      sessionStorage.removeItem('hasAddedPhoto');
    }
  }, []);

  useEffect(() => {
    if (isMandatoryAndHasPhoto) {
      getUserProfileList();
    }
  }, [isMandatoryAndHasPhoto]);

  useEffect(() => {
    const userFirstTimeLogin = getLocalItem('user_first_time_login');
    const quickOfferSubmitted = sessionStorage.getItem('quickOfferSubmitted');
    //if userfirsttime login and userprofiles are greater to 0 and onboarding mandatory fields and photo uploaded then show modal
    if (userFirstTimeLogin == 'true' && userProfiles.length > 0 && isMandatoryAndHasPhoto && !quickOfferSubmitted) {
      setIsQuickOfferModalOpen(true);
    }
  }, [isMandatoryAndHasPhoto, userProfiles?.length, profile?.photo]);

  return (
    <QuickOfferModal
      key={id}
      profile={profile}
      isModalOpen={isQuickOfferModalOpen}
      offer={offer}
      clearErrors={clearErrors}
      onClose={() => {
        setIsQuickOfferModalOpen(false);
        sessionStorage.removeItem('hasAddedPhoto');
      }}
      userProfiles={userProfiles}
      postQuickOffer={postQuickOffer}
      setIsQuickOfferModalOpen={setIsQuickOfferModalOpen}
    />
  );
};
export default React.memo(QuickOfferModalInitial);
