import React, { ReactElement, ReactNode } from 'react';
import { secondaryButtonColorStyle } from '../../../../atoms/buttons/SecondaryButton';
import styled from '@emotion/styled';
import IconCross from '../../../../atoms/icons/IconCross';
import { theme } from '../../../../theme';
import { dimensions } from '../../../../style';
import typography from '../../../../theme/typography';
import Button from '../../../../atoms/buttons/Button';
import { white } from '../../../../theme/colors';
import borders from '../../../../theme/borders';
import { getBrowserDetails, tabletWidth } from '../../../../utils/helpers';
import BasicModal from '../../../modals/BasicModal';
import backgrounds from '../../../../theme/backgrounds';

const cancelButtonStyle = secondaryButtonColorStyle({ color: 'red' });
const StyledCrossIcon = styled(IconCross)`
  height: 13px !important;
  width: 13px !important;
  position: relative;
  left: -4px;
  top: 1px;
  fill: ${cancelButtonStyle[theme].color};

  ${dimensions.SCREEN_MAX_SM} {
    width: 20px !important;
    height: 20px !important;
  }
`;

const StepDescription = styled('span')({
  fontSize: 14,
  color: typography[theme].primaryText,
  display: 'inline-block',
  fontWeight: 'normal',
  marginLeft: 10,
  letterSpacing: -0.42,
});

const CancelButton = styled(Button)`
  font-size: 13px;
  font-weight: 600;
  color: ${cancelButtonStyle[theme].color};
  border-radius: 90px;
  border: 1px solid ${cancelButtonStyle[theme].borderColor};
  padding: 8px 18px;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 17px;
  margin-top: 20px;
  height: 32px;
  width: 99px;

  ${dimensions.SCREEN_MIN_MD} {
    &:hover {
      color: ${cancelButtonStyle[theme].hover.color};
      border: 1px solid ${cancelButtonStyle[theme].hover.borderColor} !important;
      background-color: ${cancelButtonStyle[theme].hover.backgroundColor};

      & svg {
        & path {
          fill: ${cancelButtonStyle[theme].hover.color} !important;
        }
      }
    }

    &:disabled {
      color: ${cancelButtonStyle[theme].disabled.color};
      border: 1px solid ${cancelButtonStyle[theme].disabled.borderColor} !important;
      background-color: ${cancelButtonStyle[theme].disabled.backgroundColor};
    }

    &:active {
      color: ${cancelButtonStyle[theme].active.color};
      border: 1px solid ${cancelButtonStyle[theme].active.borderColor} !important;
      background-color: ${cancelButtonStyle[theme].active.backgroundColor};

      & svg {
        & path {
          fill: ${cancelButtonStyle[theme].active.color} !important;
        }
      }
    }

    &:focus {
      color: ${cancelButtonStyle[theme].hover.color};
      background-color: ${cancelButtonStyle[theme].hover.backgroundColor};
      border: 1px solid ${cancelButtonStyle[theme].hover.borderColor} !important;

      & svg {
        & path {
          fill: ${cancelButtonStyle[theme].hover.color} !important;
        }
      }
    }
  }

  ${dimensions.SCREEN_MAX_SM} {
    border: none;
    padding: 5px;
    margin-right: -10px;
    margin-top: 14px;
    width: inherit;

    &:active {
      background-color: none !important;
      border: none !important;
    }
  }
`;

const CancelButtonText = styled('span')({
  fontSize: 13,
  lineHeight: typography.lineHeight,
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const HeaderContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  marginTop: 6,
  marginRight: 2,
  maxHeight: 48,

  [dimensions.SCREEN_MAX_XS]: {
    borderBottom: `1px solid ${borders[theme].primary}`,
  },
});

const HeaderText = styled('div')({
  flex: 10,
});

const Title = styled('h2')({
  borderBottom: `1px solid ${borders[theme].primary}`,
  fontSize: 20,
  color: typography[theme].heading,
  marginRight: 12,
  marginTop: 40,
  paddingBottom: 4,
  lineHeight: '24.2px',

  [dimensions.SCREEN_MAX_XS]: {
    borderBottom: 'none',
  },
});

interface GalleryUploaderModalProps {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  contentLabel?: string;
  shouldCloseOnOverlayClick?: boolean;
  className?: string;
  description?: string;
  onClose: () => void;
}

const GalleryUploaderModal = ({
  description,
  title,
  isOpen,
  shouldCloseOnOverlayClick = false,
  onClose,
  className,
  children,
}: GalleryUploaderModalProps): ReactElement => {
  const getCustomStyle = () => {
    const browserDetails = getBrowserDetails();
    return {
      overlay: {
        zIndex: 10001,
      },
      content: {
        padding: `5px ${browserDetails.isMobile ? '15px' : '20px'}`,
        margin: browserDetails.isMobile && !tabletWidth ? '0px auto' : '25px auto',
        overflow: browserDetails.isMobile && !tabletWidth ? 'scroll' : 'unset',
        top: browserDetails.isMobile && !tabletWidth ? 0 : 25,
        height: browserDetails.isMobile && !tabletWidth ? '100%' : 'auto',
        borderRadius: 6,
        maxWidth: 685,
        backgroundColor: theme === 'light' ? white : backgrounds.dark.primaryCard,
        border: `1px solid ${theme === 'light' ? borders.light.primary : borders.dark.primary}`,
      },
    };
  };

  return (
    <BasicModal
      hideClose={true}
      ariaHideApp={false}
      className={`${className} wyp-bulk-photo-popup`}
      isOpen={isOpen}
      customStyle={getCustomStyle()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onClose={onClose}
      data={{ 'test-id': 'bulk-upload-photo-modal' }}
    >
      <HeaderContainer>
        <HeaderText>
          <Title>
            {title}
            {description && <StepDescription>{description}</StepDescription>}
          </Title>
        </HeaderText>
        <div className="top-actions">
          <CancelButton onClick={onClose}>
            <StyledCrossIcon />
            <CancelButtonText>Cancel</CancelButtonText>
          </CancelButton>
        </div>
      </HeaderContainer>
      {children}
    </BasicModal>
  );
};

export default GalleryUploaderModal;
