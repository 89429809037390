import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../theme/colors';
import { theme } from '../../../../theme';
import { CurrencySymbols } from '../DashboardDiscoverMode';
import { dimensions } from '../../../../style';

const ContainerWrapper = styled('div')(({ topPosition, isVisible }) => ({
  display: 'inline-flex',
  padding: '11px 12px',
  alignItems: 'center',
  gap: 5,
  borderRadius: 15,
  border: `3px solid ${colors[theme].green.primary}`,
  background: white,
  position: 'absolute',
  right: 10,
  top: topPosition,
  opacity: isVisible ? 0.8 : 1,
  transition: 'top 0.5s ease-in-out, opacity 0.5s ease-in-out',
  [dimensions.SCREEN_MIN_MD]: {
    right: 20,
  },
}));

const IconWrapper = styled('div')({
  background: colors[theme].green.primary,
  borderRadius: 50,
  height: 30,
  width: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PassTextWrapper = styled('div')({
  color: colors[theme].green.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
});

interface Props {
  onHidden: () => void;
  currencySymbols: CurrencySymbols;
}

const DashboardDiscoverModeOfferSentFlag: React.FC<Props> = ({ onHidden, currencySymbols }) => {
  const [topPosition, setTopPosition] = useState(30);
  const [isVisible, setIsVisible] = useState(true);
  const hideTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setTopPosition(10);
      setIsVisible(false);

      hideTimeoutRef.current = window.setTimeout(() => {
        onHidden();
      }, 800);
    }, 0);

    return () => {
      clearTimeout(animationTimeout);
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [onHidden]);

  return (
    <ContainerWrapper topPosition={topPosition} isVisible={isVisible}>
      <IconWrapper>
        <span
          style={{
            color: white,
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          {currencySymbols.currencyLabel}
        </span>
      </IconWrapper>
      <PassTextWrapper>OFFER!</PassTextWrapper>
    </ContainerWrapper>
  );
};

export default DashboardDiscoverModeOfferSentFlag;
