import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Result: boolean;
  Context: string;
  Error?: string;
  Attempts?: number;
}

export const mixpanelTrackSmsCodeVerified = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'SMS Code Verified',
    payload: {
      event_properties: eventProperties,
    },
  });
};
