import React, { useEffect } from 'react';
import ProfileSummary from '../components/pages/AccountMigration/ProfileSummary';
import { connect } from 'react-redux';
import { clearPendingPhotosUpload, refreshExternalProfile, refreshProfile } from '../actions/profileActions';
import { displayWarnMessage } from '../actions/notificationActions';
import { withPusher } from '../contexts/PusherContext';
import { ThemeProvider } from '@emotion/react';
import { WYPTheme } from 'swui';

const ProfileContainer = props => {
  const { params, refreshExternal, myprofile } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.refreshProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshExternal(params.hashId);
  }, [params.hashId, refreshExternal]);

  useEffect(() => {
    refreshExternal(params.hashId);
  }, [myprofile.hash_id, params.hashId, refreshExternal]);

  return (
    <ThemeProvider theme={WYPTheme}>
      <ProfileSummary {...props} />
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    myprofile: state.profile,
    profile: state.profile !== undefined ? state.profile : null,
    onboarded:
      Boolean(state.profile.profile && state.profile.profile.data.city) &&
      state.profile.profile_complete &&
      Boolean(state.profile.photo && state.profile.photo.data.length > 0) &&
      Boolean(state.profile.photo && state.profile.photo.data[0] && state.profile.photo.data[0].status === 'approved'),
    errors: state.common.errors,
    mapFields: state.meta.map_fields,
  };
};

// actions to refresh internal/external profile.
const mapDispatchToProps = dispatch => {
  return {
    refreshProfile: () => {
      dispatch(refreshProfile());
    },
    refreshExternal: username => {
      return dispatch(refreshExternalProfile(username));
    },
    refreshAndClearErrors: () => {
      dispatch(clearPendingPhotosUpload());
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withPusher(ProfileContainer));
