import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  TutorialSectionWrapper,
  TutorialSectionSubheader,
  TutorialHeader,
  TutorialComponentContainer,
  IconDescription,
} from './DashboardDisCoverModeStyle';
import DashboardDiscoverModeGotItButton from './components/DashboardDiscoverModeGotItButton';
import DashboardDiscoverModeArrowLeftIcon from './icons/DashboardDiscoverModeArrowLeftIcon';
import DashboardDiscoverModeArrowRightIcon from './icons/DashboardDiscoverModeArrowRightIcon';
import DashboardDiscoverModeInfoIcon from './icons/DashboardDiscoverModeInfoIcon';

import { useApp } from '../../../contexts/AppContext';
import DiscoverModeNewVariantOfferIcon from './icons/DiscoverModeNewVariantOfferIcon';
import DiscoverModeNewVariantPassIcon from './icons/DiscoverModeNewVariantPassIcon';

export const TutorialPanel = styled('div')({
  overflow: 'hidden',
  overflowY: 'scroll',
  transition: 'transform 0.5s ease , opacity 0.5s ease',
  opacity: 1,
  paddingLeft: 50,
  paddingRight: 50,
});

interface IProps {
  onConfirm: () => void;
}

const DashboardDiscoverModeTutorialWithoutSwipe: React.FC<IProps> = ({ onConfirm }: IProps) => {
  const { siteFooterLogoRef } = useApp();
  const [activePanel, setActivePanel] = useState<string>('panel1');
  const [tutorialTransform, setTutorialTransform] = useState<string>('translateY(0%)');
  const [dynamicHeight, setDynamicHeight] = useState();

  const handlePanelTransition = () => {
    setActivePanel(prevPanel => (prevPanel === 'panel1' ? 'panel2' : 'panel1'));
  };

  const handleHideTutorial = () => {
    setTutorialTransform('translateY(100%)');
    onConfirm();
  };

  useEffect(() => {
    const siteFooterLogoEl = siteFooterLogoRef?.current;
    if (siteFooterLogoEl) {
      const siteFooterLogoClRec = siteFooterLogoEl.getBoundingClientRect();
      const SITE_HEADER_HEIGHT = 48;

      setDynamicHeight(`${siteFooterLogoClRec.top - SITE_HEADER_HEIGHT}px`);
    }
  }, [siteFooterLogoRef?.current]);

  return (
    <TutorialComponentContainer style={{ transform: tutorialTransform }}>
      <TutorialPanel
        id="panel1"
        style={{
          transform: activePanel === 'panel1' ? 'translateY(0%)' : 'translateY(-100%)',
          opacity: activePanel === 'panel1' ? 1 : 0,
          height: dynamicHeight,
          maxHeight: dynamicHeight,
        }}
      >
        <TutorialSectionWrapper style={{ paddingTop: 50 }}>
          <TutorialHeader>
            Welcome to the new <br />
            Discover page
          </TutorialHeader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper>
          <TutorialSectionSubheader style={{ maxWidth: 241, margin: 'auto' }}>
            Sending date offers to new members is easier than ever!
          </TutorialSectionSubheader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper style={{ marginBottom: 36 }}>
          <DiscoverModeNewVariantOfferIcon customStyle={{ position: 'relative' }} />
          <IconDescription style={{ marginTop: 18 }}>Tap to Send an Offer</IconDescription>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper style={{ marginBottom: 36 }}>
          <DiscoverModeNewVariantPassIcon customStyle={{ position: 'relative' }} />
          <IconDescription style={{ marginTop: 24 }}> Tap to Pass on this profile</IconDescription>
        </TutorialSectionWrapper>
        <DashboardDiscoverModeGotItButton onConfirm={handlePanelTransition} customStyle={{ marginBottom: 50 }} />
      </TutorialPanel>

      <TutorialPanel
        id="panel2"
        style={{
          transform: activePanel === 'panel2' ? 'translateY(-100%)' : 'translateY(0%)',
          opacity: activePanel === 'panel2' ? 1 : 0,
          height: dynamicHeight,
          maxHeight: dynamicHeight,
        }}
      >
        <TutorialSectionWrapper style={{ paddingTop: 71 }}>
          <TutorialHeader>Like what you see?</TutorialHeader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper>
          <TutorialSectionSubheader>
            Tap these icons to see more <br /> about the member
          </TutorialSectionSubheader>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper style={{ marginBottom: 72 }}>
          <DashboardDiscoverModeArrowLeftIcon customStyle={{ marginRight: 42 }} />
          <DashboardDiscoverModeArrowRightIcon />
          <IconDescription style={{ maxWidth: 241, margin: 'auto', marginTop: 30 }}>
            View additional profile photos(or tap the right or left sides of the photo)
          </IconDescription>
        </TutorialSectionWrapper>
        <TutorialSectionWrapper style={{ marginBottom: 36 }}>
          <DashboardDiscoverModeInfoIcon />
          <IconDescription style={{ marginTop: 30 }}>Check out the full profile</IconDescription>
        </TutorialSectionWrapper>
        <DashboardDiscoverModeGotItButton onConfirm={handleHideTutorial} customStyle={{ marginBottom: 50 }} />
      </TutorialPanel>
    </TutorialComponentContainer>
  );
};

export default DashboardDiscoverModeTutorialWithoutSwipe;
