import { mixpanelTrackEventFromBE } from '.';

export const mixpanelTrackAbuseReported = async (subject: string): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Abuse Reported',
    payload: {
      event_properties: {
        Subject: subject,
      },
    },
  });
};
