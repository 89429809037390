import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { renderAvatar } from '../../common';
import ProfileMediaSlider from './ProfileMediaSlider';
import ProfileMediaCounter from './ProfileMediaCounter';
import { useProfile } from '../../contexts/ProfileContext';

const CardContainer = styled('div')({
  position: 'relative',
  backgroundColor: '#fff',
  width: '100%',
  overflow: 'hidden',
});

const PhotoAspectRatio = styled('div')(({ paddingTop }) => ({
  paddingTop: paddingTop || '133%',
  position: 'relative',
}));

const Gradient = styled('div')({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 82.5%)',
  position: 'absolute',
  width: '100%',
  height: 130,
  bottom: 0,
  left: 0,
  zIndex: 0,
});

const getOtherProfileImages = obj => {
  if (obj.type === 'video') {
    return obj.urls && obj.urls.thumbnail ? obj.urls.thumbnail : obj.thumbnail;
  }

  return obj.private && !obj.privateReal ? obj.src : renderAvatar(obj.src);
};

const getMyProfileImages = obj => {
  if (obj.type === 'video') {
    return obj.urls && obj.urls.thumbnail ? obj.urls.thumbnail : obj.thumbnail;
  }

  return obj?.urls ? renderAvatar(obj.urls) : obj.url;
};

interface CardProps {
  photoList: any;
  onClickPhoto: (index: number, e: Record<string, unknown>) => void;
  isOtherProfile: boolean;
  profileDetailsComponent: any;
}

const ProfilePhotoSliderCard = ({
  photoList,
  onClickPhoto,
  isOtherProfile,
  profileDetailsComponent,
}: CardProps): JSX.Element => {
  const { photoAspectRatioRef, galleryMediaRatioValue } = useProfile();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [gallery, setGallery] = useState([]);

  const privateGalleryList = useMemo(() => gallery.filter(item => item.private), [gallery]);
  const privateCount = useMemo(() => (privateGalleryList.length > 0 ? privateGalleryList[0].total : 0), [
    privateGalleryList,
  ]);
  const hasDefaultPhoto = useMemo(() => gallery.find(item => item.status === 'default'), [gallery]);

  useEffect(() => {
    const updateOtherProfileGallery = () => {
      const updatedGallery = photoList.map(obj => ({
        order: obj.order,
        image: getOtherProfileImages(obj),
        private: obj.private && !obj.privateReal,
        privateReal: !obj.private && obj.privateReal,
        type: obj.type,
        total: obj.private && !obj.privateReal ? obj?.total ?? 0 : 0,
        status: obj.status,
      }));
      setGallery(updatedGallery);
    };

    const updateMyProfileGallery = () => {
      const updatedGallery = photoList.map(obj => ({
        order: obj.order,
        image: getMyProfileImages(obj),
        private: false,
        privateReal: obj.private,
        type: obj.type,
        total: 0,
        status: obj.status,
      }));
      setGallery(updatedGallery);
    };

    if (isOtherProfile) {
      updateOtherProfileGallery();
    } else {
      updateMyProfileGallery();
    }
  }, [photoList, isOtherProfile]);

  return (
    <CardContainer className="card">
      <PhotoAspectRatio paddingTop={galleryMediaRatioValue} ref={photoAspectRatioRef}>
        <ProfileMediaSlider
          photos={gallery}
          currentSlide={currentSlide}
          privateCount={privateCount}
          onClickPhoto={onClickPhoto}
          setCurrentSlide={setCurrentSlide}
          isOtherProfile={isOtherProfile}
        />
        {!hasDefaultPhoto && <ProfileMediaCounter photos={gallery} currentSlide={currentSlide} />}
        <Gradient />
      </PhotoAspectRatio>

      {profileDetailsComponent}
    </CardContainer>
  );
};

export default ProfilePhotoSliderCard;
