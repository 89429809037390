import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import ReactModal from 'react-modal';
import { MediaRecorder } from 'swui';
import Button from '../../atoms/buttons/Button';
import ArrowLeft from '../../atoms/icons/ArrowLeft';
import PrivateEyeIcon from '../../atoms/icons/PrivateEyeIcon';
import PublicEyeIcon from '../../atoms/icons/PublicEyeIcon';
import { RecordingConfig } from '../../config/Media';
import { textColor } from '../../style';
import './CaptureMedia.css';

const CaptureMediaContainer = styled('div')({
  height: '100vh',
});
const customStyle = {
  overlay: {
    zIndex: 8000,
    backgroundColor: 'rgba(0,0,0,0.92)',
    overflowY: 'auto',
  },
  content: {
    margin: '0 auto',
    outline: 'none',
    bottom: 'initial',
    ':focus': {
      outline: 'none',
    },
    top: 0,
    padding: 0,
    border: 0,
    borderRadius: 'none',
    height: '100vh',
    backgroundColor: '#333333',
    ...textColor.DARK,
  },
};

const ActionButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 10,
});

const ButtonIconStyle = {
  marginRight: 6,
  cursor: 'pointer',
  height: 16,
  width: 16,
};

const ActionButton = styled(Button)({});

interface CaptureMediaProps {
  onClose: any;
  isOpen: any;
  onShare: any;
}

const CaptureMedia = ({ onClose, isOpen, onShare }: CaptureMediaProps): ReactElement => {
  const renderHeader = (): React.ReactNode => (
    <div className={'capture-media-header'} style={{ position: 'absolute', top: 20, left: 20 }}>
      <span className={'close-capture-media'} onClick={onClose}>
        <ArrowLeft color={'white'} cssStyle={{ filter: 'drop-shadow(-1px 3px 2px rgb(0 0 0 / 0.4))' }} />
      </span>
    </div>
  );

  const getMediaType = (capitalize = false) => {
    const mediaType = localStorage.getItem('swuiMediaType');

    if (!mediaType) {
      return '';
    }

    if (capitalize) {
      return mediaType.charAt(0).toUpperCase() + mediaType.slice(1).toLowerCase();
    }

    return mediaType.toLowerCase();
  };

  const renderActions = (media): React.ReactNode => {
    return (
      <ActionButtonGroup>
        <ActionButton buttonType="primary" onClick={() => onShare(media, getMediaType(), true)}>
          <PrivateEyeIcon color={`#FFFFFF`} customStyle={ButtonIconStyle} />
          Add as Private
        </ActionButton>
        <ActionButton buttonType="primary" onClick={() => onShare(media, getMediaType(), false)}>
          <PublicEyeIcon color={`#FFFFFF`} customStyle={ButtonIconStyle} />
          Add as Public
        </ActionButton>
      </ActionButtonGroup>
    );
  };
  // @TODO Add custom video preview
  return (
    <ReactModal
      style={customStyle}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <CaptureMediaContainer id="media-record">
        <MediaRecorder
          renderHeader={renderHeader}
          renderFooter={renderActions}
          maxVideoTime={RecordingConfig.maxDuration}
          instructionPanel={{
            show: false,
          }}
          discardModal={{
            title: `Discard the current ${getMediaType()}?`,
            description: 'You cannot undo this.',
            actionText: `Discard ${getMediaType(true)}`,
          }}
          sharePanel={{ show: true }}
          inactiveTime={10000000}
          footerCustomClass={'media-recorder-footer'}
        />
      </CaptureMediaContainer>
    </ReactModal>
  );
};

export default CaptureMedia;
