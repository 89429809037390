/**
 * @Author: zachalam
 * @Date:   2017-03-21T14:48:57-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-21T14:48:57-07:00
 */

import _ from 'lodash';
import UserConfig from '../config/User';
import DefaultSearch from '../config/Search';

const initialState = DefaultSearch;

const search = (state = initialState, action: any): any => {
  switch (action.type) {
    case 'CLEAR_SEARCH':
      return {
        ...state,
        profile: [],
        profileResults: 0,
      };
    case 'SAVE_FILTERS':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.formData }, // save search filters.
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: initialState.filters,
      };
    case 'RESET_INDIVIDUAL_FILTER': {
      const filterToReset = action.payload;
      // reset date or departing_from filters.
      let filtersThatReset;

      switch (filterToReset) {
        case 'No Photos':
          filtersThatReset = { has_photo: false };
          break;
        case 'Is Online':
          filtersThatReset = { online: false };
          break;
        case 'Dating Type':
          filtersThatReset = {
            dating_just_dating: false,
            dating_friendship: false,
            dating_marriage: false,
            dating_sugar: false,
            dating_married: false,
            dating_adult: false,
            dating_mentorship: false,
          };
          break;
        case 'Age':
          filtersThatReset = {
            age_min: UserConfig.minimumAge,
            age_max: UserConfig.maximumAge,
          };
          break;
        case 'Height':
          filtersThatReset = {
            height_min: UserConfig.minimumHeight, // in cm
            height_max: UserConfig.maximumHeight,
          };
          break;
        case 'Smoking':
          filtersThatReset = { smoking: [] };
          break;
        case 'Drinking':
          filtersThatReset = { drinking: [] };
          break;
        case 'Relationship':
          filtersThatReset = { relationship_status: [] };
          break;
        case 'Children':
          filtersThatReset = { children: [] };
          break;
        case 'Ethnicity':
          filtersThatReset = { ethnicity: [] };
          break;
        case 'Body':
          filtersThatReset = { body_types: [] };
          break;
        case 'Education':
          filtersThatReset = { education: [] };
          break;
        case 'Keywords':
          filtersThatReset = { keywords: '' };
          break;
        default:
          console.error('Nothing to reset');
          break;
      }

      return {
        ...state,
        filters: { ...state.filters, ...filtersThatReset },
      };
    }
    case 'STORE_PROFILE_SEARCH': {
      const oldProfiles = _.map(state.profile, v => {
        return v;
      });

      // check if received payload is undefined
      const newProfiles = action.payload ? { ...oldProfiles.concat(action.payload) } : [];

      return {
        ...state,
        profile: newProfiles,
        isFetching: false,
        hasFetchError: false,
      };
    }
    case 'REQUEST_SEARCH':
      return {
        ...state,
        hasFetchError: false,
        isFetching: true,
      };

    case 'REQUEST_SEARCH_FAILED':
      return {
        ...state,
        hasFetchError: true,
        isFetching: false,
      };
    case 'STORE_PROFILE_RESULT_COUNT':
      return {
        ...state,
        profileResults: action.payload,
      };
    case 'REMOVE_FROM_SEARCH': {
      const blockedProfileKey = Object.keys(state.profile).filter(
        key => state.profile[key].account.data.username === action.payload
      );

      delete state.profile[blockedProfileKey[0]];
      return {
        ...state,
        profile: state.profile,
      };
    }
    case 'STORE_LAST_SELECTED_SEARCH_RESULT':
      return {
        ...state,
        lastSelectedSearch: action.payload.id,
      };
    case 'SEARCH_PRESERVE_RESULT': {
      const preservedResult = Object.assign([], state);

      if (preservedResult.profile && preservedResult.filters && preservedResult.filters.fromDashboard) {
        return preservedResult;
      }

      return {
        ...state,
        preservedResult,
      };
    }
    case 'SEARCH_LOAD_PRESERVED_RESULT': {
      const oldResult = Object.assign([], state.preservedResult);
      if (oldResult.preservedResult) delete oldResult.preservedResult;
      return {
        ...oldResult,
        filters:
          typeof oldResult.filters === 'object' && Object.keys(oldResult.filters).length > 0
            ? oldResult.filters
            : DefaultSearch.filters,
      };
    }
    case 'STORE_PGID':
      return {
        ...state,
        pgid: action.payload.pgid,
      };
    default:
      return state;
  }
};

export default search;
