import React, { ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import { dimensions, textColor, backgroundTheme } from '../../../style';
import { vysionTrack } from '../../../utils/vysion/vysionService';
import { IsMobileViewPort, lazilyEvaluateVal, ternaryFunc } from '../../../utils/helpers';
import { renderAvatar } from '../../../common';
import FavoriteCard from '../../../atoms/FavoriteCard';
import FavoriteActionButton from './FavoriteActionButton';
import useMixPanel from '../../../hooks/useMixPanel';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../constants/mixpanel';
import { mixpanelTrackProfileFavorited } from '../../../utils/mixpanel/profileFavorited';

const ResultItem = styled('div')(
  {
    ...textColor.DARKER,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: backgroundTheme.personalInfo,
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 24,
    position: 'relative',
    maxWidth: 228,
    width: '25%',
    ['@media (max-width: 1080px)']: {
      width: '23%',
    },
    ['@media (max-width: 860px)']: {
      width: '22%',
    },
    [dimensions.SCREEN_MAX_MD]: {
      width: '22%',
    },
    [dimensions.SCREEN_MAX_SM]: {
      margin: '10px 9px',
      width: '45%',
    },
    [dimensions.SCREEN_MIN_XS_4]: {
      margin: '10px 8px',
      width: '45%',
    },
  },
  ({ enableSlider }) =>
    enableSlider && {
      [dimensions.SCREEN_MAX_SM]: {
        width: '100%',
        maxWidth: '100%',
      },
    }
);

interface FavoritesProfileGridViewProps {
  favKey: any;
  profile: any;
  hashId: any;
  isFavorite: any;
  isFavoritedMe: any;
  isMatched: any;
  toggle: any;
  page: any;
  screenSizeIsMobile: any;
  offer: any;
  user: any;
  ignoreUser: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  unlockMessage(): void;
  pageTitle: any;
  enableSlider?: boolean;
  isRead?: boolean;
}

const FavoritesProfileGridView = ({
  favKey,
  profile,
  hashId,
  isFavorite,
  isFavoritedMe,
  isMatched,
  toggle,
  page,
  screenSizeIsMobile,
  offer,
  user,
  ignoreUser,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  unlockMessage,
  pageTitle,
  enableSlider,
  isRead,
}: FavoritesProfileGridViewProps): ReactElement => {
  const { trackProfileView, setMixpanelDataByKeyHandler } = useMixPanel();
  const profileData = profile.profile.data;
  const extraInfo = page === 'to' ? 'favoritedYou' : 'yourFavorites';
  const avatar = useMemo(() => {
    return (
      lazilyEvaluateVal(profileData, 3, false, 'avatar', 'data', 'urls') && renderAvatar(profileData.avatar.data.urls)
    );
  }, [profileData.avatar]);

  const age = profileData.age;
  const city = profileData.city;
  const username = profile.username ? profile.username : '';
  const photoCount = parseInt(profileData.approved_public_photos_count);
  const privatePhotoCount = profileData.private_photos_count ? parseInt(profileData.private_photos_count) : 0;
  const online = profileData.online;
  const dataKey = `fav_${favKey}`;
  const userVerified = profileData.id_verified;
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const trackCardClickEvent = () => {
    vysionTrack({
      action: 'click',
      component: 'Profile Card',
      extraInfo,
      page: 'Favorites',
    });

    if (page === 'viewed_me' && !enableSlider && IsMobileViewPort) {
      trackProfileView({ Source: '2Col Viewed Me', User: hashId });
    } else {
      trackProfileView({ Source: pageTitle, User: hashId });
    }

    let mxOfferMadeSourceValue = TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewDirect;

    if (pageTitle === 'Viewed Me') {
      mxOfferMadeSourceValue = TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewViewedMe;
    } else if (pageTitle === 'Favorited Me') {
      mxOfferMadeSourceValue = TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewFavoritedMe;
    } else if (pageTitle === 'My Favorites') {
      mxOfferMadeSourceValue = TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewMyFavorites;
    }

    setMixpanelDataByKeyHandler({
      key: EVENT_NAMES.OFFER_MADE,
      payload: { source: mxOfferMadeSourceValue },
    });
  };

  const toggleTracking = () => {
    if (isFavorite) {
      toggle(hashId, favKey, page);
      return;
    }

    vysionTrack({
      action: 'click',
      component: isFavorite ? 'unfavorite' : 'favorite',
      extraInfo,
      page: 'Favorites',
    });

    // when user favorites
    // check if capable
    showMandatoryCompleteProfile(
      () => {
        toggle(hashId, favKey, page);
        mixpanelTrackProfileFavorited({ Source: pageTitle, User: hashId });
      },
      () => undefined,
      'Add to Favorites'
    );
  };

  return (
    <ResultItem className="css-favorite-list-item grid-list-item" enableSlider={enableSlider}>
      <FavoriteCard
        key={dataKey}
        age={age}
        city={city}
        username={username}
        hashId={hashId}
        photoCount={photoCount}
        privatePhotoCount={privatePhotoCount}
        online={online}
        imgUrl={avatar}
        cardWidth={ternaryFunc(screenSizeIsMobile, '100%', '100%')}
        cardMargin={ternaryFunc(screenSizeIsMobile, '5px 1%', '.5%')}
        detailsPadding={ternaryFunc(screenSizeIsMobile, '5px', '10px')}
        isVerified={userVerified}
        onClick={trackCardClickEvent}
        favorited={isFavorite === true}
        favoritedMe={isFavoritedMe}
        isMatched={isMatched}
        page={page}
        offer={offer}
        user={user}
        screenIsMobile={screenSizeIsMobile}
        isRead={isRead}
        enableSlider={enableSlider}
      />
      <FavoriteActionButton
        favorited={isFavorite}
        toggleTracking={toggleTracking}
        page={page}
        offer={offer}
        user={user}
        otherAccount={profile}
        avatar={avatar}
        ignoreUser={ignoreUser}
        createNewOffer={createNewOffer}
        sendSuggestion={sendSuggestion}
        clearErrors={clearErrors}
        errors={errors}
        showWarn={showWarn}
        acceptOffer={acceptOffer}
        counterOffer={counterOffer}
        isMatched
        favKey={favKey}
        viewType="grid"
        isSuggestRequest={offer && offer.data && offer.data.suggest}
        ignoreOffer={ignoreOffer}
        unlockMessage={unlockMessage}
        pageTitle={pageTitle}
        enableSlider={enableSlider}
      />
    </ResultItem>
  );
};

export default FavoritesProfileGridView;
