import React, { useState, useLayoutEffect, useRef, ReactElement, Dispatch, SetStateAction, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import TextInput from '../../atoms/TextInput';
import Button from '../../atoms/buttons/Button';
import { newAuthPhoneLogin, verifyOtp } from '../../actions/authActions';
import { setCookie } from '../../utils/helpers';
import { clearNotificationMessage } from '../../actions/notificationActions';
import { clearErrors, setLoading } from '../../actions/commonActions';
import usePhoneActionModal from '../../hooks/usePhoneActionModal';
import { EVENT_NAMES, trackMixpanelAnonymousEvents, trackMixpanelEvents } from '../../constants/mixpanel';
import { useApp } from '../../contexts/AppContext';
import { handleLoginVysionEvent } from '../../utils/vysion/login';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { LoginClickVysionEventsEnum } from '../../utils/vysion/login/click';
import { handleJoinVysionEvent } from '../../utils/vysion/join';
import { JoinClickVysionEventsEnum } from '../../utils/vysion/join/click';
import OtpCountdown from './OtpCountdown';
import { handlePhoneSettingsVysionEvent } from '../../utils/vysion/phoneSettings';
import { PhoneSettingsClickVysionEventsEnum } from '../../utils/vysion/phoneSettings/click';
import { useLocation, useNavigate } from 'react-router-dom';
import { mixpanelTrackSmsCodeVerified } from '../../utils/mixpanel/smsCodeVerified';

export const OtpForm = styled('form')({
  display: 'block',
});

export const ChildrenContainer = styled('div')({
  marginTop: 24,
});

interface IOtpFormInputProps {
  children: React.ReactElement;
  onSuccess: (setIsProcessing: Dispatch<SetStateAction<boolean>>, response?: any) => void;
  loginUserByPhone: any;
  verifyInputOtp: any;
  phoneValue: string;
  isLogin?: boolean;
  isSignUp?: boolean;
  profile: any;
  handleOnFocus?: () => void;
  hasUpdatedPhone?: boolean;
}

const OtpFormInput = ({
  children,
  onSuccess,
  loginUserByPhone,
  verifyInputOtp,
  phoneValue,
  isLogin = false,
  isSignUp = false,
  profile,
  handleOnFocus,
  hasUpdatedPhone = false,
}: IOtpFormInputProps): ReactElement => {
  const [otp, setOtp] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { showIncorrectCodeModal, showCodeAttemptsExceededModal } = usePhoneActionModal();
  const { setOtpRemainingAttempts, otpFormInputFocus, showOtpCountdown, isAccountIsMixpanelEligableEnabled } = useApp();

  const navigate = useNavigate();
  const location = useLocation();

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 6);
    setOtp(numericValue);
  };

  const handleOnSuccess = response => {
    onSuccess?.(setIsProcessing, response);

    if (profile?.hash_id) {
      mixpanelTrackSmsCodeVerified({
        Result: true,
        Context: profile.phone_number === phoneValue ? 'Old Number' : 'New Number',
      });
    } else {
      trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_VERIFIED, {
        Result: true,
        Context: isLogin ? 'Login' : 'Join',
      });
    }

    setOtpRemainingAttempts(2);

    return response;
  };

  const handleOtpFormInputAutoFocus = () => {
    if (formRef.current) {
      const inputElement = formRef.current.querySelector('input[type="text"]');

      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const handleOnError = error => {
    const errorCode = error.response.data?.code;
    const remainingAttempts = error.response.data?.remaining_attempts ?? 0;
    switch (errorCode) {
      case 0:
        showIncorrectCodeModal({
          isLogin,
          isSignUp,
          phoneNumber: phoneValue,
          onResendSuccess: () => {
            if (profile?.hash_id) {
              if (isAccountIsMixpanelEligableEnabled) {
                trackMixpanelEvents(profile.hash_id, EVENT_NAMES.SMS_CODE_REQUESTED);
              }
            } else {
              trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_REQUESTED);
            }
          },
          remainingAttempts,
        });

        if (profile?.hash_id) {
          mixpanelTrackSmsCodeVerified({
            Result: false,
            Error: 'Incorrect Code',
            Attempts: remainingAttempts,
            Context: profile.phone_number === phoneValue ? 'Old Number' : 'New Number',
          });
        } else {
          trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_VERIFIED, {
            Result: false,
            Error: 'Incorrect Code',
            Attempts: remainingAttempts,
            Context: isLogin ? 'Login' : 'Join',
          });
        }

        break;
      case 40022003:
        showCodeAttemptsExceededModal({ isLogin });

        if (profile?.hash_id) {
          mixpanelTrackSmsCodeVerified({
            Result: false,
            Error: 'Requests Attempts Exceeded',
            Attempts: 3,
            Context: profile.phone_number === phoneValue ? 'Old Number' : 'New Number',
          });
        } else {
          trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_VERIFIED, {
            Result: false,
            Error: 'Requests Attempts Exceeded',
            Attempts: 3,
            Context: isLogin ? 'Login' : 'Join',
          });
        }
        break;
    }
    setIsProcessing(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!otp.match(/^\d{6}$/)) {
      return;
    }

    if (isLogin) {
      handleLoginVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: LoginClickVysionEventsEnum.CLICK_LOGIN_ENTER_VERIFICATION_VERIFY,
      });
    }

    if (isSignUp) {
      handleJoinVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_VERIFICATION_CODE_VERIFY,
      });
    }

    if (!isLogin && !isSignUp) {
      if (hasUpdatedPhone) {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_VERIFY,
        });
      } else {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_VERIFY,
        });
      }
    }

    setIsProcessing(true);

    if (isLogin) {
      loginUserByPhone(phoneValue, otp, handleOnSuccess, handleOnError, navigate, location);
    } else {
      verifyInputOtp(phoneValue, otp, handleOnSuccess, handleOnError, isSignUp, navigate);
    }
  };

  useEffect(() => {
    if (otpFormInputFocus) {
      setOtp('');
      handleOtpFormInputAutoFocus();
    }
  }, [otpFormInputFocus]);

  useLayoutEffect(() => {
    let ac: AbortController | undefined;

    const handleOtpCredential = () => {
      if ('OTPCredential' in window) {
        const input = formRef.current?.querySelector('input[autocomplete="one-time-code"]');
        if (!input) return;

        ac = new AbortController();
        const form = input.closest('form');
        if (form) {
          form.addEventListener('submit', () => {
            if (ac) ac.abort();
          });
        }
        navigator.credentials
          .get({
            otp: { transport: ['sms'] },
            signal: ac.signal,
          })
          .then(otp => {
            setOtp(otp.code);
            if (formRef.current) {
              const submitEvent = new Event('submit', { cancelable: true });
              formRef.current.dispatchEvent(submitEvent);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    };

    handleOtpCredential();

    return () => {
      if (ac) ac.abort();

      const input = formRef.current?.querySelector('input[autocomplete="one-time-code"]');
      const form = input ? input.closest('form') : null;
      if (form) {
        form.removeEventListener('submit', () => {
          if (ac) ac.abort();
        });
      }
    };
  }, []);

  const clonedChildren = React.Children.toArray(children.props.children);
  const updatedClonedChildren = clonedChildren.map(child => {
    if (React.isValidElement(child) && child.type === Button && child.props.type == 'submit') {
      // disabled the submit button when otp is empty or below 6 characters

      return React.cloneElement(
        child,
        { disabled: otp.trim() === '' || otp.length <= 5 || isProcessing },
        isProcessing ? 'Verifiying...' : 'Verify'
      );
    }
    return child;
  });

  return (
    <OtpForm ref={formRef} onSubmit={handleSubmit} data-test-id="form-otp">
      <TextInput
        id="otp"
        inputLabel="Enter code:"
        customStyleLabel={{ display: 'flex' }}
        type="text"
        inputMode="numeric"
        name="otp"
        autoComplete="one-time-code"
        value={otp}
        onChange={handleOtpChange}
        customStyle={{ marginRight: 0, paddingBottom: 0 }}
        customInputStyle={{
          fontSize: 18,
          textAlign: 'center',
          letterSpacing: '1em',
          fontWeight: 700,
          color: '#595959',
        }}
        onFocus={() => {
          if (typeof handleOnFocus === 'function') {
            handleOnFocus();
          }
        }}
      />
      {showOtpCountdown && <OtpCountdown isLogin={isLogin} hashId={profile?.hash_id} currentPhone={phoneValue} />}
      <ChildrenContainer>{updatedClonedChildren}</ChildrenContainer>
    </OtpForm>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUserByPhone: (phone, otp, callback, onError, navigate, location) => {
      dispatch(newAuthPhoneLogin(phone, otp, navigate, location))
        .then(response => {
          // jwt saved, redirect to dashboard
          setCookie('isAuthenticated', true, true);
          dispatch(clearNotificationMessage());
          dispatch(clearErrors());
          callback(response);
        })
        .catch(error => {
          console.log(error);
          onError(error);
          // hide loading
          dispatch(setLoading());
        });
    },
    verifyInputOtp: (phone, otp, callback, onError, isSignUp, navigate) => {
      dispatch(verifyOtp(phone, otp, isSignUp, navigate))
        .then(response => {
          callback(response);
        })
        .catch(error => {
          onError(error);
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpFormInput);
