import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, color, textColor } from '../../style';
import CheckIcon from '@material-ui/icons/Check';
import { addCommas, getBrowserDetails, isDarkMode, KeyGenerator } from '../../utils/helpers';
import '../../style/customSWui.css';
import 'animate.css';
import { getLocalItem } from '../../common';
import { handlePackagesVysionEvent } from '../../utils/vysion/packages';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { PackagesClickVysionEventsEnum } from '../../utils/vysion/packages/click';
import CircleIcon from '../icons/CircleIcon';
import { useApp } from '../../contexts/AppContext';

const isMobile = getBrowserDetails().isMobile;

const creditStyle = (credit, type = 'borderortext'): string => {
  switch (parseInt(credit)) {
    case 300:
    case 250:
    case 1000:
    case 1500:
      return '#BC2028';
    case 100:
      return '#24A83A';
    case 500:
      return '#2B8FD7';
    default:
      return type !== 'icon' ? '#DF1019' : color.BRAND_2;
  }
};

const creditBG = (credit): string => {
  switch (parseInt(credit)) {
    case 300:
    case 250:
    case 500:
    case 1500:
      return isDarkMode()
        ? 'linear-gradient(180deg, #041A2A 62%, #043557 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #EAF4FB 100%)';
    case 100:
      return isDarkMode()
        ? 'linear-gradient(180deg, #041A2A 62%, #073900 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #EDFBEB 100%)';
    case 1000:
      return isDarkMode()
        ? 'linear-gradient(180deg, #041A2A 62%, #37030A 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #FBF0F1 100%)';
    default:
      return '#FFFFFF';
  }
};

const setPackageInfo = (credit, index, isVIP = false) => {
  if (isVIP) {
    switch (parseInt(credit)) {
      case 1500: {
        const credit1500 = ['Save 60%!*', 'Special VIP pricing just for you!'];
        return credit1500[index];
      }
      case 250: {
        const credit250 = ['Save 34%!*', 'Special VIP pricing just for you!'];
        return credit250[index];
      }
    }
  } else {
    switch (parseInt(credit)) {
      case 1000: {
        const credit1000 = ['Best value!'];
        return credit1000[index];
      }
      case 500: {
        const credit500 = ['5X more credits!'];
        return credit500[index];
      }
      case 100: {
        const credit100 = ['Make your move!'];
        return credit100[index];
      }
      case 1500: {
        const credit1500 = ['Save 60%!*'];
        return credit1500[index];
      }
      case 300: {
        const credit300 = ['Save 34%!*'];
        return credit300[index];
      }
      case 250: {
        const credit250 = ['Save 20%!*'];
        return credit250[index];
      }
    }
  }
};

const RenderPackageInfo = (length, credits, identifier) => {
  const packageInfos = [];
  const VIP = ['generous_pp_special_2', 'generous_pp_special_1'];
  const isVIP = VIP.find(ele => ele === identifier);
  if (isVIP) {
    packageInfos.push(PackageDetailsVIP(credits));
    return packageInfos;
  }
  for (let i = 0; i < length; i++) {
    packageInfos.push(PackageInfoDetails(i, length, credits, isVIP));
  }
  return packageInfos;
};

const PackageInfoDetails = (index, length, credits, isVIP) => {
  return (
    <div style={{ lineHeight: '10px' }} key={KeyGenerator()}>
      <span
        style={{
          fontWeight: index === 0 ? 'bold' : 'unset',
          alignItems: 'center',
          lineHeight: '16px',
          color: isDarkMode() ? '#AFB0B1' : '#595959',
        }}
        data-test-id={`package-icon-${credits}`}
      >
        <CheckIcon style={CreditInfoIcon(credits)} />
        {`${setPackageInfo(credits, index, isVIP)}`}
      </span>
      {index < length - 1 && <br />}
    </div>
  );
};

const PackageDetailsVIP = credits => {
  return (
    <div key={KeyGenerator()}>
      <span data-test-id={`package-icon-${credits}`}>
        <CheckIcon style={CreditInfoIcon(credits)} />
        <b>{` ${setPackageInfo(credits, 0, true)}`}</b>
        {` ${setPackageInfo(credits, 1, true)}`}
      </span>
    </div>
  );
};

const PackageContainer = styled('div')(
  ({ newBilling }) => ({
    paddingRight: newBilling ? (isMobile ? 0 : 10) : 6,
    paddingBottom: isMobile ? 10 : 0,
    paddingLeft: newBilling ? (isMobile ? 0 : 10) : 6,
  }),
  ({ isSpecialPackage }) => ({
    minHeight: !isSpecialPackage ? 'auto' : 180,
  }),
  ({ selected }) =>
    isMobile &&
    selected && {
      marginTop: 8,
      marginBottom: 8,
    }
);

const PopularContainer = styled('div')(
  {
    top: isMobile ? '-29px' : '-30px',
    width: 'calc(100% - 2px)',
    position: 'absolute',
    left: 0,
    padding: '6px 0',
    color: '#FFF',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '8px 8px 0 0',
    paddingBottom: 10,
  },
  ({ credits }: any) => ({
    background: creditStyle(credits),
    border: `1px solid ${creditStyle(credits)}`,
  }),
  () =>
    isMobile && {
      fontSize: 14,
    }
);

const EmptyPopularContainer = styled('div')(
  {
    top: '-4px',
    width: 'calc(100% - 2px)',
    position: 'absolute',
    left: 0,
    padding: '6px 0',
    color: '#FFF',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '8px 8px 0 0',
  },
  ({ credits }: any) => ({
    background: creditStyle(credits),
    border: `1px solid ${creditStyle(credits)}`,
  })
);

const StrikeThrough = styled('del')(
  {
    textDecorationColor: '#595959',
  },
  ({ disableStrike = false }: any) => ({
    textDecoration: disableStrike ? 'none' : 'line-through',
    marginRight: disableStrike || isMobile ? 0 : 5,
  })
);

const DiscountedAmount = styled('span')({
  fontSize: isMobile ? 18 : 26,
  fontWeight: 'bold',
  color: '#24A83A',
});

const Card = styled('div')(
  ({ newBilling, credits }: any) => ({
    marginTop: 'auto !important',
    cursor: 'pointer',
    borderRadius: 8,
    background: `${creditBG(credits)}`,
    border: '1px solid #8392A7',
    textAlign: newBilling ? 'left' : 'center',
    flex: 1,
    position: 'relative',
    lineHeight: '30px',
    margin: 0,
    padding: isMobile ? 5 : 20,
    '&:nth-of-type(1)': {
      marginLeft: '0px',
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  }),
  ({ recommended, isSpecialPackage, vip, selectionOff }) => ({
    padding:
      recommended && !isSpecialPackage && selectionOff && !vip
        ? '52px 20px 20px'
        : (isSpecialPackage && vip) || isMobile
        ? 10
        : '43px 20px',
  }),
  ({ selected, credits, isPromoApplied }) =>
    isMobile && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: selected && !isPromoApplied ? 188 : 180,
      ...(selected && !isDarkMode() && { background: '#FFF', border: `2px solid ${creditStyle(credits)}` }),
    }
);

const CardContainer = styled('div')({
  padding: 0,
  margin: '0 5px',
  position: 'relative',
  flex: 1,
});

const CreditsPerCurrency = styled('div')`
  font-size: 12px;
  color: ${backgroundTheme.greyText};
  padding-top: 16px;
`;

const CreditContainer = styled('div')({
  paddingTop: 0,
});

const CreditsTitle = styled('div')(({ credits, selected }: any) => ({
  fontSize: isMobile ? (selected ? 28 : 24) : 36,
  fontWeight: 600,
  color: `${creditStyle(credits)}`,
  textAlign: 'center',
  paddingTop: 10,
  lineHeight: '18px',
}));

const Amount = styled('div')({
  fontSize: isMobile ? 18 : 26,
  fontWeight: 'bold',
  color: textColor.DARK,
  textAlign: 'center',
  justifyContent: 'flex-start',
  paddingTop: isMobile ? 13 : 10,
  lineHeight: '20px',
  display: isMobile ? 'flex' : 'block',
  flexDirection: isMobile ? 'column' : 'row',
});

const CreditInfo = styled('div')({
  padding: '20px 0px',
  fontSize: isMobile ? 12 : 16,
  color: isDarkMode() ? 'rgba(0, 0, 0, 0.65)' : '#595959',
  fontWeight: 400,
  textAlign: 'center',
  lineHeight: '22px',
  borderBottom: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
  borderTop: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
  maxWidth: 350,
  margin: '0 auto',
  minWidth: 240,
  marginTop: 20,
});

const CreditInfoIcon = credits => {
  const isMobilePromo = credits === '1500' || credits === '300';
  return {
    fontSize: 24,
    paddingRight: 4,
    color: `${creditStyle(credits, 'icon')}`,
    verticalAlign: 'text-bottom',
    marginLeft: isMobilePromo ? (getBrowserDetails().isIpad ? 50 : 30) : 0,
    marginBottom: '-2px',
  };
};

const ButtonContainer = styled('div')(
  {
    paddingTop: 10,
    textAlign: 'center',
  },
  ({ checkBoxVariant }) =>
    checkBoxVariant && {
      display: 'flex',
      justifyContent: 'center',
    }
);

const renderCreditsPerCurrency = (
  credits,
  hasTopTag,
  price,
  newLabel = false,
  currencyCode = '$',
  discountedTotalPrice
) => {
  let creditsText;
  let totalPrice = price;

  if (discountedTotalPrice) {
    totalPrice = discountedTotalPrice > 0 ? discountedTotalPrice : 0;
  }

  const newPrice = (totalPrice / credits).toFixed(2);

  switch (parseInt(credits, 10)) {
    case 220:
    case 500:
    case 100:
    case 250:
    case 450:
    case 1000:
      creditsText = `${currencyCode}${newPrice}`;
      break;
    default:
      creditsText = '';
  }
  return (() => {
    if (creditsText) {
      return newLabel ? creditsText : <CreditsPerCurrency hasTopTag={hasTopTag}>{creditsText}</CreditsPerCurrency>;
    }
  })();
};

const handlePackageSelection = (selected, stepBilling, identifier, selectPackage) => {
  if (!selected) {
    selectPackage(identifier);
  }
};

const renderPackageTitle = credits => {
  let packageTitle = '';
  switch (parseInt(credits)) {
    case 1500:
      packageTitle = 'VIP Package 1';
      break;
    case 250:
      packageTitle = 'VIP Package 2';
      break;
    default:
      packageTitle = '';
  }
  return packageTitle;
};

const renderSelectedBanner = (credits: string, isPromoApplied: boolean): string => {
  let packageTitle = '';
  switch (parseInt(credits)) {
    case 100:
      packageTitle = 'Get Started!';
      break;
    case 1000:
      packageTitle = isPromoApplied ? 'Our Best Deal!' : 'Save 50%!';
      break;
    default:
      packageTitle = 'Popular!';
  }
  return packageTitle;
};

interface AppliedPromoDiscount {
  type: string;
  discount: number;
  discounted_amount: string;
  less_amount: number;
}

interface BillingPackageProps {
  selected: any;
  selectPackage: any;
  credits: any;
  promotional: any;
  identifier: any;
  currencyCharCode: any;
  price: any;
  checkBoxVariant: any;
  stepBilling: any;
  emailPromo: any;
  showPayment: any;
  index: any;
  selectionOff: any;
  name: any;
  appliedPromoDiscount: AppliedPromoDiscount;
}

const BillingPackageVariant = ({
  selected,
  selectPackage,
  credits,
  promotional,
  identifier,
  currencyCharCode,
  price,
  checkBoxVariant,
  stepBilling,
  index,
  selectionOff,
  name,
  appliedPromoDiscount,
}: BillingPackageProps): any => {
  const isPromoApplied = useMemo(() => Object.keys(appliedPromoDiscount).length > 0, [appliedPromoDiscount]);
  const { setSelectedSpecialOrVipPromo } = useApp();

  const discountedTotalPrice = useMemo(() => {
    if (!isPromoApplied) return 0;

    const { discounted_amount } = appliedPromoDiscount;
    const parsedDiscountedAmount = parseInt(discounted_amount);

    return parsedDiscountedAmount > 0 ? parsedDiscountedAmount.toFixed() : '0';
  }, [isPromoApplied]);

  useEffect(() => {
    // Set initially selected
    const prevSelected = getLocalItem('wyp_selected_package');
    selectPackage(prevSelected ? prevSelected : name === '500 CREDIT PACKAGE' ? identifier : '');
  }, [selected, identifier, selectPackage]);

  const isSpecialPackage = promotional;
  const setSpecialPackage = isSpecialPackage && checkBoxVariant;
  const isRecommended = parseInt(credits) === 500 || isSpecialPackage;

  const VIP = ['generous_pp_special_2', 'generous_pp_special_1'];
  const isVIP = VIP.find(ele => ele === identifier);

  return (
    <CardContainer style={{ top: isMobile && !isPromoApplied ? 7 : 0 }}>
      {!isSpecialPackage && selected ? (
        <PopularContainer
          className="animate__animated animate__fadeInUp animate__faster"
          credits={credits}
          selected={selected}
        >
          {renderSelectedBanner(credits, isPromoApplied)}
        </PopularContainer>
      ) : (
        <EmptyPopularContainer credits={credits} />
      )}
      <Card
        id={`package-${index}`}
        data-test-id={isSpecialPackage ? `package-special-${index}` : `package-${index}`}
        selected={selected && !selectionOff}
        isPromoApplied={isPromoApplied}
        checkBoxVariant={checkBoxVariant}
        credits={credits}
        onClick={() => {
          setSelectedSpecialOrVipPromo(null);
          handlePackageSelection(selected, stepBilling, identifier, selectPackage);
          handlePackagesVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: PackagesClickVysionEventsEnum.CLICK_PACKAGE_SELECTED_PACKAGE,
            component: `${credits} credits`,
            newEventName: `packages-${credits}credits`,
          });
        }}
        newBilling={!checkBoxVariant}
        isSpecialPackage={isSpecialPackage}
        vip={isVIP}
        recommended={isRecommended}
        selectionOff={selectionOff}
      >
        <PackageContainer
          isSpecialPackage={setSpecialPackage}
          newBilling={!checkBoxVariant}
          selected={selected && !selectionOff}
        >
          <CreditContainer newBilling>
            <CreditsTitle credits={credits} selected={selected}>
              {isSpecialPackage && isVIP && `${renderPackageTitle(credits)} - `}
              <b style={{ fontWeight: 'bolder' }}>{addCommas(credits)}</b> {isMobile && <br />}
              <span style={isMobile ? { ...textColor.DARK, fontWeight: 'normal', fontSize: 12 } : {}}>Credits</span>
            </CreditsTitle>
          </CreditContainer>
          {!isMobile && (
            <CreditInfo id="creditInfo">
              {RenderPackageInfo(1, credits, identifier).map(info => {
                return info;
              })}
            </CreditInfo>
          )}
          <ButtonContainer checkBoxVariant={setSpecialPackage}>
            <div
              style={
                isMobile
                  ? {
                      borderBottom: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
                      borderTop: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
                    }
                  : {}
              }
            >
              <Amount>
                <StrikeThrough disableStrike={!isPromoApplied}>
                  {renderCreditsPerCurrency(credits, true, price, true, String.fromCharCode(currencyCharCode))}
                </StrikeThrough>
                {isPromoApplied && (
                  <DiscountedAmount>
                    {renderCreditsPerCurrency(
                      credits,
                      true,
                      price,
                      true,
                      String.fromCharCode(currencyCharCode),
                      discountedTotalPrice
                    )}
                  </DiscountedAmount>
                )}
                {!isMobile && ` / Credit`}
              </Amount>
              {isMobile && <div style={{ fontSize: 12, lineHeight: '12px', marginBottom: 13 }}>per credit</div>}
            </div>
            <div>
              <Amount
                style={{
                  fontWeight: 'normal',
                  fontSize: isMobile ? 13 : 16,
                  display: isMobile ? 'flex' : 'inline-block',
                  width: isMobile ? 'unset' : 'max-content',
                  lineHeight: isMobile ? '16px' : '24px',
                }}
              >
                <StrikeThrough disableStrike={!isPromoApplied}>
                  {String.fromCharCode(currencyCharCode)}
                  {parseInt(price).toFixed()} total
                </StrikeThrough>
                {isPromoApplied && (
                  <DiscountedAmount style={{ fontSize: isMobile ? 13 : 16 }}>
                    {String.fromCharCode(currencyCharCode)}
                    {discountedTotalPrice} total
                  </DiscountedAmount>
                )}
              </Amount>
            </div>
          </ButtonContainer>
        </PackageContainer>
      </Card>
      {isMobile && selected && (
        <CircleIcon
          fill={creditStyle(credits)}
          customStyle={{ position: 'absolute', bottom: '-12px', left: '50%', transform: 'translate(-50%, 0)' }}
        />
      )}
    </CardContainer>
  );
};

export default BillingPackageVariant;
