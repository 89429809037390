import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import { getBrowserDetails } from '../utils/helpers';
import { textColor } from '../style';

const LabelDescription = styled('div')`
  padding-top: 6px;
  font-weight: normal;
  font-size: 12px;
`;

const Label = styled('label')`
  display: inline-block;
  margin-top: 0;
  font-weight: 400;
  position: relative;
  margin-right: 16px;
`;

const BillingLabel = styled('label')`
  display: inline-block;
  margin-top: 0;
  font-weight: 400;
  position: relative;
  margin-right: 16px;
`;

const LabelToolTip = styled('span')`
  position: absolute;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: -10px;
  cursor: pointer;
`;

const RedRequired = styled('span')({
  ...textColor.RED,
});

interface InputLabelProps {
  label: any;
  labelDescription: any;
  tooltip: any;
  inputId: any;
  customStyle: any;
  isBilling: boolean;
  customContainerStyle: any;
  isRequired: boolean;
}

const InputLabel = ({
  label,
  labelDescription,
  tooltip,
  inputId,
  customStyle = {},
  isBilling = false,
  customContainerStyle = {},
  isRequired = false,
}: InputLabelProps): ReactElement => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowHideTooltip = status => {
    if (status !== 'click') {
      setShowTooltip(status);
      return;
    }
    setShowTooltip(!showTooltip);
  };

  const handleOnClick = () => {
    handleShowHideTooltip('click');
  };

  const browserDetails = getBrowserDetails();

  const renderToolTip = tooltipProps => {
    const { icon, iconWidth, text, overlayStyle, placement } = tooltipProps;
    const otherProps = browserDetails.isMobile ? { visible: showTooltip } : { trigger: 'hover' };
    return (
      <Tooltip
        placement={placement ? placement : 'top'}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        overlay={text ? text : ''}
        overlayStyle={overlayStyle ? overlayStyle : null}
        {...otherProps}
      >
        <LabelToolTip iconWidth={iconWidth} onClick={browserDetails.isMobile ? handleOnClick : null}>
          {icon}
        </LabelToolTip>
      </Tooltip>
    );
  };

  const setToolTip = tooltip ? renderToolTip(tooltip) : null;

  return (
    <div style={{ marginBottom: isBilling ? 4 : 10, ...customContainerStyle }}>
      {isBilling ? (
        <BillingLabel style={customStyle} data-test-id={inputId}>
          {label}
          {isRequired ? <RedRequired>*</RedRequired> : ''}
          {setToolTip}
        </BillingLabel>
      ) : (
        <Label style={customStyle} data-test-id={inputId}>
          {label}
          {isRequired ? <RedRequired>*</RedRequired> : ''}
          {setToolTip}
        </Label>
      )}
      {labelDescription ? <LabelDescription data-test-id={inputId}>{labelDescription}</LabelDescription> : null}
    </div>
  );
};

export default InputLabel;
