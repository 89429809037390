import UserConfig from './User';

export default {
  profile: [],
  profileResults: 0,
  newestMembers: [],
  onlineMembers: [],
  isFetching: false,
  hasFetchError: false,
  pgid: null,
  filters: {
    distance: 50,
    order: 'recent',
    photos: false,
    has_photo: false,
    online: false,
    age_min: UserConfig.minimumAge,
    age_max: UserConfig.maximumAge,
    height_min: UserConfig.minimumHeight, // in cm
    height_max: UserConfig.maximumHeight,
    keywords: '',
    id_verified: [],
    smoking: [],
    drinking: [],
    relationship_status: [],
    ethnicity: [],
    body_types: [],
    education: [],
    children: [],
    dating_just_dating: false,
    dating_friendship: false,
    dating_marriage: false,
    dating_sugar: false,
    dating_married: false,
    dating_adult: false,
    dating_mentorship: false,
    city: '',
    country: '',
    region: '',
  },
};
