import React, { ReactElement, useEffect } from 'react';
import { createCanvas, loadImage } from 'canvas';
import { getBrowserDetails } from '../../utils/helpers';
import styled from '@emotion/styled';
import { useComponentDidUpdate } from '../../utils/customHooks';
import { dimensions } from '../../style';

const CanvasContainer = styled('div')({
  textAlign: 'center',
  position: 'absolute',
  // width: getBrowserDetails().wImgCalibration,
  // height: getBrowserDetails().hImgCalibration,
  maxWidth: getBrowserDetails().wImgModalCalibration,
  height: getBrowserDetails().hImgModalCalibration,
  margin: '0 auto',
  maxHeight: getBrowserDetails().hImgCalibration,
  left: '50%',
  transform: 'translate(-50%, 0)',
  [dimensions.SCREEN_MAX_XS]: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
});

interface PhotoBlurOutputProps {
  blurData: any;
  getCanvasRef: () => any;
}

export const PhotoBlurOutput = ({ blurData, getCanvasRef }: PhotoBlurOutputProps): ReactElement => {
  let canvasRef = null;
  const { threshold, data, smooth, image } = blurData;
  const { width, height } = image;
  // We'll be pixelating the image by threshold
  let percent = 0;

  const setPercent = subtractedVal => {
    percent = 1 - subtractedVal / 10;
  };

  useEffect(() => {
    if (canvasRef) {
      getCanvasRef(canvasRef);
    }
  }, [canvasRef]);

  useComponentDidUpdate(() => {
    // do componentDidUpdate logic
    // If no data
    if (data.length < 1) return;

    // Output Canvas and Context
    const outputCanvas = canvasRef;
    const outputCtx = outputCanvas.getContext('2d');

    // Hidden Canvas and Context
    const hiddenCanvas = createCanvas(width, height);
    const hiddenCtx = hiddenCanvas.getContext('2d');

    // Load Image
    loadImage(image.uri)
      .then(newImage => {
        const face = getFaceData(data[0]);

        if (smooth) {
          // New canvases for applying blurring and feathering (canvases for inverted mask of blurred images)
          const imaskCanvas = createCanvas(width, height);
          const imaskCtx = imaskCanvas.getContext('2d');
          const imaskCanvas2 = createCanvas(width, height);
          const imaskCtx2 = imaskCanvas2.getContext('2d');

          // Set global composite operation to destination in
          imaskCtx.globalCompositeOperation = 'destination-in';

          // Draw blurred faces to inverted mask canvas (x,y,w,h are modified due to blurring and feathering)
          // Determine the blur amount by width of face
          let blurAmount = threshold;
          if (face.w >= 300) blurAmount = threshold * 2.5;
          else if (face.w <= 30) blurAmount = threshold * 0.25;

          hiddenCtx.filter = `blur(${blurAmount}px)`; // Add blur filter

          // Make blur rounded
          hiddenCtx.save();
          hiddenCtx.beginPath();
          hiddenCtx.arc(face.x + face.h / 2, face.y + face.w / 2, face.h / 2, 0, 2 * Math.PI, true);
          hiddenCtx.closePath();
          hiddenCtx.clip();

          hiddenCtx.drawImage(newImage, 0, 0, width, height); // Draw original image to hidden canvas
          imaskCtx.putImageData(
            hiddenCtx.getImageData(face.x - 10, face.y - 10, face.w + 20, face.h + 20),
            face.x - 10,
            face.y - 10
          ); // Add blurred faces to blank canvas

          // Draw blurred faces onto 2nd inverted mask canvas
          imaskCtx2.drawImage(imaskCanvas, 0, 0);
          imaskCtx2.shadowColor = 'black'; // Required for feathering
          imaskCtx2.shadowBlur = 30;
          imaskCtx2.globalCompositeOperation = 'destination-in';

          // Feathering
          imaskCtx2.shadowBlur = 20;
          imaskCtx2.drawImage(imaskCanvas, 0, 0);
          imaskCtx2.shadowBlur = 10;
          imaskCtx2.drawImage(imaskCanvas, 0, 0);

          // Clear visible canvas then draw original image to it and then add the blurred images
          outputCtx.clearRect(0, 0, width, height);
          outputCtx.drawImage(newImage, 0, 0);
          outputCtx.drawImage(imaskCanvas2, 0, 0);
        } else {
          // For pixelation
          hiddenCanvas.style.cssText =
            'image-rendering: optimizeSpeed;' + // FireFox < 6.0
            'image-rendering: -moz-crisp-edges;' + // FireFox
            'image-rendering: -o-crisp-edges;' + // Opera
            'image-rendering: -webkit-crisp-edges;' + // Chrome
            'image-rendering: crisp-edges;' + // Chrome
            'image-rendering: -webkit-optimize-contrast;' + // Safari
            'image-rendering: pixelated; ' + // Future browsers
            '-ms-interpolation-mode: nearest-neighbor;'; // IE

          // Use nearest-neighbor scaling when images are resized instead of the resizing algorithm to create blur
          hiddenCtx.webkitImageSmoothingEnabled = false;
          hiddenCtx.mozImageSmoothingEnabled = false;
          hiddenCtx.msImageSmoothingEnabled = false;
          hiddenCtx.imageSmoothingEnabled = false;

          // Set threshold to 9.8 if it's 10 so the blurred faces aren't rendered white
          threshold === 10 ? setPercent(9.8) : setPercent(threshold);

          // Calculate the scaled dimensions
          const scaledWidth = width * percent;
          const scaledHeight = height * percent;

          // Render image smaller
          hiddenCtx.drawImage(newImage, 0, 0, scaledWidth, scaledHeight);

          // Stretch the smaller image onto larger context
          hiddenCtx.drawImage(hiddenCanvas, 0, 0, scaledWidth, scaledHeight, 0, 0, width, height);

          // Clear visible canvas and draw original image to it
          outputCtx.clearRect(0, 0, width, height);
          outputCtx.drawImage(newImage, 0, 0);

          // Draw pixelated faces to canvas
          outputCtx.putImageData(hiddenCtx.getImageData(face.x, face.y, face.w, face.h), face.x, face.y);
        }
      })
      .catch(err => {
        console.log(err);
      });
  });

  const getFaceData = selection => {
    return {
      x: selection.x,
      y: selection.y,
      w: selection.width,
      h: selection.height,
    };
  };

  const setBlurCanvas = imageCanvas => {
    canvasRef = imageCanvas;
  };

  return (
    <CanvasContainer data-test-id="photo-blur-canvas">
      <canvas
        ref={ref => setBlurCanvas(ref)}
        width={width}
        height={height}
        style={{
          maxWidth: getBrowserDetails().isMobile ? '100%' : getBrowserDetails().wImgCalibration,
          maxHeight: getBrowserDetails().isMobile ? '100%' : getBrowserDetails().hImgModalCalibration,
          width: getBrowserDetails().isMobile && !getBrowserDetails().isTablet ? '100%' : 'auto',
          height:
            getBrowserDetails().isMobile && !getBrowserDetails().isTablet
              ? '100%'
              : getBrowserDetails().hImgCalibration,
        }}
      />
    </CanvasContainer>
  );
};

PhotoBlurOutput.defaultProps = {
  image: {
    uri: '',
    width: 0,
    height: 0,
  },
  threshold: 9,
  data: [],
  smooth: true,
};
