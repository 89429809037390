import styled from '@emotion/styled';
import { getBrowserDetails } from '../../utils/helpers';

export const Wrapper = styled('div')({
  padding: 12,
});

export const HeaderWrapper = styled('div')({
  textAlign: 'center',
  marginTop: 20,
});

export const Title = styled('p')({
  fontSize: 18,
  fontWeight: 600,
});

export const Title2 = styled('h1')({
  fontSize: 24,
  fontWeight: 700,
  color: '#2B8FD7',
  marginBottom: 5,
});

export const Description = styled('p')({
  fontSize: 16,
  fontWeight: 400,
  margin: 0,
  padding: 0,
});

interface IStepLabelProps {
  cross?: boolean;
}

export const StepLabel = styled('p')(
  {
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    margin: 0,
    marginTop: 5,
    marginLeft: 5,
  },
  ({ cross = false }: IStepLabelProps) => ({
    textDecoration: cross ? 'line-through' : 'unset',
  })
);

export const LinkStyle1 = styled('a')`
  color: #2b8fd7;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  margin-left: 5px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const LinkStyle2 = styled('a')`
  color: #595959;
  font-weight: 400;
  font-size: 13px;
  text-decoration: underline;
  margin-left: 5px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const BodyWrapper = styled('div')({
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 20,
});

export const Form = styled('form')({});

const { isMobile } = getBrowserDetails();

export const styledDropzoneStylesSmallPicture = {
  paddingTop: isMobile ? 30 : 45,
  height: isMobile ? 107 : 135,
  minHeight: isMobile ? '107px !important' : '135px !important',
};
