import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useModal from './useModal';
import { useApp } from '../contexts/AppContext';
import FirstStepCompleteProfile from '../components/completeProfile/FirstStepCompleteProfile';
import SecondStepCompleteProfile from '../components/completeProfile/SecondStepCompleteProfile';
import * as Sentry from '@sentry/react';
import { getBrowserDetails } from '../utils/helpers';
import useMixPanel from './useMixPanel';
import { EVENT_NAMES } from '../constants/mixpanel';
import styled from '@emotion/styled';
import Container from '../components/blocks/Container';
import { dimensions } from '../style';
import store from '../store';
import { handleProfileCompletionVysionEvent } from '../utils/vysion/profileCompletion';
import { VYSION_ACTIONS } from '../utils/vysion';
import { ProfileCompletionViewVysionEventsEnum } from '../utils/vysion/profileCompletion/view';
import { ProfileCompletionClickVysionEventsEnum } from '../utils/vysion/profileCompletion/click';

const StyledContainer = styled(Container)({
  [dimensions.SCREEN_MIN_MD]: {
    padding: '0',
  },
});

interface IUseCompleteProfileModal {
  showMandatoryCompleteProfile(callback?: () => void, callbackAfterValidate?: () => void, action?: string): void;
}

const ModalBody = ({ validate, action }): ReactElement => {
  const { profileCompletionStep } = useApp();
  const location = useLocation();

  useEffect(() => {
    if (profileCompletionStep === 1) {
      handleProfileCompletionVysionEvent(
        {
          action: VYSION_ACTIONS.VIEW,
          event: ProfileCompletionViewVysionEventsEnum.VIEW_PROFILE_COMPLETION,
        },
        location.pathname
      );
    }

    if (profileCompletionStep === 2) {
      handleProfileCompletionVysionEvent(
        {
          action: VYSION_ACTIONS.VIEW,
          event: ProfileCompletionViewVysionEventsEnum.VIEW_FINISH_MY_PROFILE,
        },
        location.pathname
      );
    }
  }, [profileCompletionStep, location.pathname]);

  return profileCompletionStep === 1 ? (
    <FirstStepCompleteProfile validate={validate} action={action} />
  ) : (
    <SecondStepCompleteProfile action={action} />
  );
};

const useCompleteProfileModal = (): IUseCompleteProfileModal => {
  const { basicModal } = useModal();
  const { trackProfileWallStep } = useMixPanel();
  const { isMobile } = getBrowserDetails();
  const {
    setProfileCompletionStep,
    setProfileCompletionCallback,
    setProfileCompletionCallbackAfterValidate,
  } = useApp();
  const location = useLocation();

  const handlePopupVysionOnClose = step => {
    if (step === 1) {
      handleProfileCompletionVysionEvent(
        {
          action: VYSION_ACTIONS.CLICK,
          event: ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_CLOSE,
        },
        location.pathname
      );
    }

    if (step === 2) {
      handleProfileCompletionVysionEvent(
        {
          action: VYSION_ACTIONS.CLICK,
          event: ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_CLOSE,
        },
        location.pathname
      );
    }
  };

  const showMandatoryCompleteProfile = (
    callback = () => undefined,
    callbackAfterValidate = () => undefined,
    action = ''
  ) => {
    try {
      const profileState = store.getState()?.profile ?? null;

      if (profileState === null) {
        return;
      }

      const profileValidation = profileState.mandatory_profile_status;
      const isProfileComplete = profileState.mandatory_profile_completed;
      const isGenerous = profileState.profile?.data?.account_type?.toLowerCase() === 'generous';

      if (isGenerous || isProfileComplete) {
        callback();
        return;
      }

      setProfileCompletionCallback(() => callback);
      setProfileCompletionCallbackAfterValidate(() => callbackAfterValidate);

      // add mixpanel
      trackProfileWallStep(EVENT_NAMES.PROFILE_WALL_STEP_1_SHOWN, {
        Action: action,
        'Fields Missed': profileValidation?.fields_missed ?? [],
      });

      basicModal({
        dateTestId: 'complete-profile-reminder',
        modalContentLabel: 'Complete Profile Reminder',
        isModalOpen: true,
        withoutBgPattern: true,
        closeOnOverlayClick: false,
        modalBody: (
          <StyledContainer>
            <ModalBody validate={profileValidation} action={action} />
          </StyledContainer>
        ),
        customContentStyle: { maxWidth: isMobile ? 420 : 500 },
        onCloseClickHandler: () => {
          setProfileCompletionStep(2);
          handlePopupVysionOnClose(2);
          callbackAfterValidate();
        },
        enableTouchMove: true,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: { component: 'useCompleteProfileModal', action: 'showMandatoryCompleteProfile' },
      });
    }
  };

  return {
    showMandatoryCompleteProfile,
  };
};

export default useCompleteProfileModal;
