import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Success: boolean;
  'Lookup Address': string;
}

export const mixpanelTrackMelissaLookupFinished = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Melissa - Lookup Finished',
    payload: {
      event_properties: eventProperties,
    },
  });
};
