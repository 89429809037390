import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';
import store from '../store';

const api = MasterConfig.api;

const deleteBiometric = (id: string | number): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${api}/webauthn/unregister/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getBiometricKeys = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/webauthn/keys`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getCredentialPublicKey = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/webauthn/register-begin`,
    data: formData,
    headers: {
      Authorization: `Bearer ${store.getState().auth.jwToken}`,
    },
  });
};

const registerBiometric = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/webauthn/register-finish`,
    data: formData,
    headers: {
      Authorization: `Bearer ${store.getState().auth.jwToken}`,
      'Content-Type': 'application/json',
    },
  });
};

const getAuthenticationPublicKey = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/webauthn/auth-begin`,
    data: formData,
  });
};

const authenticateBiometric = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/webauthn/auth-finish`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  deleteBiometric,
  getBiometricKeys,
  getCredentialPublicKey,
  registerBiometric,
  getAuthenticationPublicKey,
  authenticateBiometric,
};
