import { VYSION_ACTIONS } from '../..';
import { EventData, handleVysionEvent } from '../../vysionService';
import { NewJoinFlowClickVysionEventsEnum, newJoinFlowClickVysionEvents } from './click';

export const events = {
  [VYSION_ACTIONS.CLICK]: { ...newJoinFlowClickVysionEvents },
};

export const handleNewJoinFlowVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};

export const handleNewJoinFlowInterestedInVysionEvent = (selected: number): void => {
  let event;

  if (selected === 1) {
    event = NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_WOMEN;
  }

  if (selected === 2) {
    event = NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_MEN;
  }

  if (selected === 3) {
    event = NewJoinFlowClickVysionEventsEnum.I_AM_INTERESTED_IN_MEETING_BOTH;
  }

  if (event) {
    handleNewJoinFlowVysionEvent({ event });
  }
};

