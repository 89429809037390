import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { isDarkMode } from '../../../../../utils/helpers';
import { buttonTheme, dimensions } from '../../../../../style';

export const DeactivateAccountContainer = styled('div')({
  margin: '0 20px',
  marginBottom: 20,
  paddingTop: 20,
  borderTop: '1px dashed #DAE1E9',
  maxWidth: 365,
  [dimensions.SCREEN_MAX_MD_2]: {
    maxWidth: '100%',
    margin: '0 80px',
    marginBottom: 20,
  },
  [dimensions.SCREEN_MAX_SM]: {
    maxWidth: 365,
    margin: '0 20px',
    marginBottom: 20,
  },
});

export const DeactivateAccountTitle = styled('h3')({
  color: '#0D5C93',
  fontSize: 20,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.4px',
  marginTop: 0,
  marginBottom: 20,
});

export const DeactivateAccountContent = styled('div')({
  color: isDarkMode() ? '#AFB0B1' : '#595959',
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  marginBottom: 20,
  '> p': {
    margin: 0,
    fontWeight: 'normal',
  },
  '> ul': {
    listStyle: 'initial',
    listStyleType: 'disc',
    paddingLeft: 20,
  },
  '> ul > li': {
    fontSize: 15,
    border: 'none',
    padding: '0.5rem 0',
    margin: 0,
    display: 'list-item',
    fontWeight: 600,
  },
  '> ul > li:hover': {
    border: 'none',
    cursor: 'initial',
  },
});

export const DeactivateAccountContentLink = styled('a')({
  textDecoration: 'underline !important',
  color: '#2B8FD7 !important',
  '&:hover': {
    textDecoration: 'none !important',
  },
});

const ButtonLink = styled(Link)({
  display: 'flex',
  padding: '12px 22px',
  justifyVontent: 'center',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderRadius: '90px',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
  justifyContent: 'center',
});

export const DeactivateAccountButton = styled(ButtonLink)({
  border: `1px solid ${buttonTheme.primaryRed.border}`,
  background: buttonTheme.primaryRed.background,
  color: `${buttonTheme.primaryRed.text} !important`,
  '&:hover': {
    background: buttonTheme.primaryRed.hover.background,
    borderColor: buttonTheme.primaryRed.hover.border,
  },
  '&:active': {
    background: buttonTheme.primaryRed.active.background,
    borderColor: buttonTheme.primaryRed.active.border,
  },
});

export const DeleteAccountButton = styled(ButtonLink)({
  border: `1px solid ${buttonTheme.secondaryRed.border}`,
  background: isDarkMode() ? 'transparent' : '#FFF',
  color: `${buttonTheme.secondaryRed.text} !important`,
  padding: '9.5px 22px',
  gap: '1px',
  '> span': {
    color: `${buttonTheme.secondaryRed.text} !important`,
    fontSize: 14,
  },
  '&:hover': {
    background: `${buttonTheme.secondaryRed.hover.background} !important`,
    color: `${buttonTheme.secondaryRed.hover.text} !important`,
    orderColor: buttonTheme.secondaryRed.active.border,
  },
  '&:hover > span': {
    color: `${buttonTheme.secondaryRed.hover.text} !important`,
  },
  '&:active': {
    background: buttonTheme.secondaryRed.active.background,
    borderColor: buttonTheme.secondaryRed.active.border,
  },
  '&:active > span': {
    borderColor: buttonTheme.secondaryRed.active.border,
  },
});

export const ButtonLinkIconStyle = {
  width: 14,
  height: 14,
};
