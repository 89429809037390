import React, { useState } from 'react';
import Panel from './panels/Panel';
import TextArea from './TextArea';
import Button from './buttons/Button';
import styled from '@emotion/styled';
import { getBrowserDetails, tabletWidth } from '../utils/helpers';
import CancelButton from './buttons/ProfileCancelButton';

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: getBrowserDetails().isMobile && !getBrowserDetails().isTablet && !tabletWidth ? 'center' : 'flex-end',
});

const textInputStyle = {
  fontSize: 16,
  color: 'rgba(0, 0, 0, 0.8)',
};

const EditableTextarea = (props: any): any => {
  const {
    id,
    title,
    placeholder,
    className,
    updateValue,
    handleSubmit,
    handleCancel,
    value,
    errors,
    onChange,
    disabled,
    minCount,
    customStyle,
    isProcessing,
    ...rest
  } = props;
  const [text, setText] = useState('');

  const onChangeHandler = event => {
    setText(event.target.value);
    updateValue(event, event.target.value);
  };

  const countLength = sentences => {
    return sentences && sentences.length > 49 && sentences === text;
  };

  return (
    // do not pass editable props here
    <Panel
      withHeader
      title={title}
      className={className}
      isProfile
      disabled={isProcessing}
      {...rest}
      cancelButton={
        <CancelButton
          dataTestId="editable-textarea-cancel-btn"
          handleCancel={handleCancel}
          customStyle={{ marginRight: 0 }}
        />
      }
    >
      <TextArea
        id={id}
        value={value || ''}
        onChange={onChange ? onChange : onChangeHandler}
        rows={5}
        resize="vertical"
        status={errors[id] && 'error'}
        statusMessage={errors[id]}
        showCounter={true}
        minCount={minCount}
        placeholder={`${placeholder}`}
        customInputStyle={textInputStyle}
        customStyle={customStyle}
      />
      <ButtonContainer>
        <Button
          data-test-id="editable-textarea-save-btn"
          customStyle={{ paddingRight: 30, paddingLeft: 30 }}
          buttonType="error"
          onClick={handleSubmit}
          disabled={disabled ? disabled : !countLength(value)}
        >
          Save
        </Button>
      </ButtonContainer>
    </Panel>
  );
};

export default EditableTextarea;
