import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { color as styleColor } from '../style';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled('div')({
  animation: `${rotateAnimation} 3s linear infinite`,
  display: 'inline-flex',
});

interface LoadingProps {
  color?: any;
  width?: any;
  isEditProfile?: boolean;
}

const Loading = ({ color, width, isEditProfile = false }: LoadingProps): ReactElement => {
  return (
    <LoaderContainer style={isEditProfile ? { marginRight: 16 } : {}}>
      <svg fill={color} xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 39 40">
        <path
          d="M129.322349,59.1666667 L134.819546,59.1666667 C137.362363,63.8333595 142.311377,67 148,67 C156.284271,67 163,60.2842712 163,52 C163,43.7157288 156.284271,37 148,37 C142.243912,37 137.24508,40.2421966 134.730093,45 L129.259215,45 C132.096688,37.4066643 139.416947,32 148,32 C159.045695,32 168,40.954305 168,52 C168,63.045695 159.045695,72 148,72 C139.479877,72 132.204098,66.6723273 129.322349,59.1666667 Z"
          transform="translate(-129 -32)"
        />
      </svg>
    </LoaderContainer>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

Loading.defaultProps = {
  color: styleColor.BRAND_1,
  width: 40,
};

export default Loading;
