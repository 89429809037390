import React, { ReactElement, useState } from 'react';
import Button from '../../../atoms/buttons/Button';
import PhoneNumberInput from '../../../atoms/PhoneNumberInput';
import { useApp } from '../../../contexts/AppContext';
import { FormWrapper, ButtonAction, DashlineSeparator, ErrorMessage, StyledEditablePhoneInput } from './styledElements';
import { validatePhoneNumber } from '../../../utils/helpers';
import useGeolocationData from '../../../hooks/useGeolocationData';
import { tomtomEnabled } from '../../../config/Master';
import { handlePhoneSettingsVysionEvent } from '../../../utils/vysion/phoneSettings';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PhoneSettingsClickVysionEventsEnum } from '../../../utils/vysion/phoneSettings/click';
import { TwilioVerbiage } from '../TwilioVerbiage';

interface IAddPhoneInputProps {
  allowChange?: boolean;
  profileCountry?: string;
  nextStep?: (value: string) => void;
}

const AddPhoneInput = ({ profileCountry, nextStep }: IAddPhoneInputProps): ReactElement => {
  const [newPhoneValue, setNewPhoneValue] = useState<string>('');
  const { hasRequestOtpError, setHasRequestOtpError } = useApp();
  const { countryCode } = useGeolocationData(tomtomEnabled);

  const handleSubmitPhone = () => {
    handlePhoneSettingsVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_UPDATE,
    });

    if (!validatePhoneNumber(newPhoneValue)) {
      setHasRequestOtpError('The phone number format is invalid.');
      return;
    }

    if (typeof nextStep === 'function') {
      nextStep(newPhoneValue);
    }
  };

  return (
    <FormWrapper data-test-id="add_phone_settings_container">
      <DashlineSeparator style={{ marginTop: '0px', marginBottom: '20px' }} />
      <StyledEditablePhoneInput>
        <PhoneNumberInput
          dataTestId="text-add-phone"
          defaultCountry={countryCode ?? 'us'}
          customLabelStyle={{ fontWeight: 600 }}
          customStyle={{ width: '100%' }}
          label="Current Phone Number"
          value={newPhoneValue}
          autoFocus
          onChange={value => setNewPhoneValue(value)}
          onFocus={() => setHasRequestOtpError('')}
          handleOnChangeCountry={country => {
            handlePhoneSettingsVysionEvent({
              action: VYSION_ACTIONS.CLICK,
              event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY,
              extraInfo: country.name,
              newEventName: `accountMenu-changePhoneNumber-countryCode-${country.countryCode}`,
            });
          }}
          handleClickCountryDropdown={showDropdown => {
            if (showDropdown) {
              handlePhoneSettingsVysionEvent({
                action: VYSION_ACTIONS.CLICK,
                event: PhoneSettingsClickVysionEventsEnum.CLICK_CHANGE_PHONE_NUMBER_COUNTRY_CODE,
              });
            }
          }}
        >
          {hasRequestOtpError && <ErrorMessage>{hasRequestOtpError}</ErrorMessage>}
        </PhoneNumberInput>
      </StyledEditablePhoneInput>
      <DashlineSeparator />
      <TwilioVerbiage profileCountry={profileCountry} />
      <DashlineSeparator />
      <ButtonAction>
        <Button
          data-test-id="phone-settings-button"
          customStyle={{}}
          buttonType="primary"
          onClick={handleSubmitPhone}
          type={'button'}
          size="small"
        >
          Update
        </Button>
      </ButtonAction>
    </FormWrapper>
  );
};

export default AddPhoneInput;
