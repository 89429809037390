import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import colors, { white } from '../../../../../theme/colors';
import OfferFlagIcons from '../icons/OfferFlagIcons';
import { theme } from '../../../../../theme';
import { useOffers } from '../../../../../contexts/OffersContext';

const ContainerWrapper = styled('div')(({ topPosition, isVisible, variant }) => ({
  display: 'inline-flex',
  padding: '13px 10px',
  alignItems: 'center',
  borderRadius: 15,
  border: `3px solid ${
    ['decline', 'counter'].includes(variant) ? colors[theme].red.primary : colors[theme].green.primary
  }`,
  background: white,
  position: 'absolute',
  right: ['accept', 'request', 'offer'].includes(variant) ? 10 : 'auto',
  left: ['decline', 'counter'].includes(variant) ? 10 : 'auto',
  top: topPosition,
  opacity: isVisible ? 0.5 : 1,
  transition: 'top 0.5s ease-in-out, opacity 0.5s ease-in-out',
}));

const FlagTextWrapper = styled('div')(({ variant }) => ({
  color: ['decline', 'counter'].includes(variant) ? colors[theme].red.primary : colors[theme].green.primary,
  textAlign: 'center',
  fontSize: 22,
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: -0.44,
  textTransform: 'uppercase',
  marginLeft: 5,
}));

interface Props {
  onHidden: () => void;
}

const OneTapSwipeOfferFlag: React.FC<Props> = ({ onHidden }) => {
  const { oneTapOfferFlagVariant } = useOffers();
  const [topPosition, setTopPosition] = useState(30);
  const [isVisible, setIsVisible] = useState(true);

  const renderLabel = () => {
    switch (oneTapOfferFlagVariant) {
      case 'decline':
        return 'Declined';
      case 'accept':
        return 'Accepted!';
      case 'offer':
        return 'Offer!';
      case 'request':
        return 'Requested!';
      case 'counter':
        return 'Countered!';
      default:
        return '';
    }
  };

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setTopPosition(10);
      setIsVisible(false);

      // Additional delay before hiding (adjust as needed)
      const hideTimeout = setTimeout(() => {
        onHidden();
      }, 1300);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(hideTimeout);
    }, 0);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(animationTimeout);
  }, [onHidden]);

  return (
    <ContainerWrapper topPosition={topPosition} isVisible={isVisible} variant={oneTapOfferFlagVariant}>
      <OfferFlagIcons variant={oneTapOfferFlagVariant} />
      <FlagTextWrapper variant={oneTapOfferFlagVariant}>{renderLabel()}</FlagTextWrapper>
    </ContainerWrapper>
  );
};

export default OneTapSwipeOfferFlag;
