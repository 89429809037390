/**
 * @Author: zachalam
 * @Date:   2017-01-26T14:18:52-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-26T14:18:52-08:00
 */

import _ from 'lodash';
import { ProfileObject } from '../models/Profile';

export type ProfileState = {
  profile: ProfileObject;
  [key: string]: any;
};
export interface ProfileValidation {
  profile_email_confirmed: boolean;
  profile_details_completed: boolean;
  fields_missed: Array<string>;
}

const initialState = {
  pendingUploads: {
    private: false,
    public: false,
  },
  photo: { data: [] },
  direct_s3_upload: false,
  isFetching: false,
  onCallStatus: false,
  isProcessing: false,
  id_verification: {
    data: {
      status: '',
    },
  },
  nuxGuides: {
    isFetching: false,
    data: [],
  },
  gallery: {
    isFetching: false,
  },
  mandatory_profile_completed: false,
  mandatory_profile_status: {
    profile_email_confirmed: false,
    profile_details_completed: false,
    fields_missed: [],
  },
  phone_number: null,
  isUpdatingProfileCover: false,
  account_config: {},
  isUpdatingDescription: false,
  isUpdatingSeekingDesc: false,
};

/* stores logged in profile */
const profile = (state = initialState, action: any): any => {
  let newPhotoData;
  let updatedNux;

  switch (action.type) {
    case 'STORE_PROFILE_V2': {
      const isGenerousAcctV2 = payloadData => payloadData.account_type === 'Generous';
      const copyState = action.payload;

      if (action.payload.photo && action.payload.photo.data && action.payload.video && action.payload.video.data) {
        copyState.profile.data.photo.data = [...copyState.photo.data, ...copyState.video.data];
      }

      return {
        ...state,
        ...copyState,
        isGenerous: isGenerousAcctV2(action.payload),
      };
    }
    case 'STORE_PROFILE': {
      const isGenerousAcct = payloadData => payloadData.profile && payloadData.profile.data.account_type === 'Generous';

      if (action.payload.photo && action.payload.photo.data && action.payload.video && action.payload.video.data) {
        action.payload.photo.data = [...action.payload.photo.data, ...action.payload.video.data];
      }

      return {
        ...state,
        ...action.payload,
        isGenerous: isGenerousAcct(action.payload),
      };
    }
    case 'SAVE_PROFILE_DESCRIPTION': {
      const copyStateProfile = state.profile !== undefined ? { ...state.profile } : null;
      if (action.payload.description && copyStateProfile) {
        copyStateProfile.data.description = action.payload.description;
        return {
          ...state,
          profile: copyStateProfile,
        };
      } else {
        return state;
      }
    }
    case 'SAVE_PROFILE_SEEKING': {
      const copyStateProfile = state.profile !== undefined ? { ...state.profile } : null;
      if (action.payload.seeking && copyStateProfile) {
        copyStateProfile.data.seeking = action.payload.seeking;
        return {
          ...state,
          profile: copyStateProfile,
        };
      } else {
        return state;
      }
    }
    case 'IS_PROCESSING':
      return {
        ...state,
        isProcessing: !state.isProcessing,
      };
    case 'IS_UPDATING_PROFILE_COVER':
      return {
        ...state,
        isUpdatingProfileCover: action.payload,
      };
    case 'IS_UPDATING_DESCRIPTION':
      return {
        ...state,
        isUpdatingDescription: action.payload,
      };
    case 'IS_UPDATING_SEEKING_DESC':
      return {
        ...state,
        isUpdatingSeekingDesc: action.payload,
      };
    case 'MAKE_PREMIUM':
      return {
        ...state,
        is_premium: true,
      };
    case 'CHANGE_CALL_STATUS':
      return {
        ...state,
        onCallStatus: action.payload,
      };
    case 'STORE_SINGLE_PHOTO':
      // adds one new photo to profile list.
      return {
        ...state,
        photo: {
          data: [...state.photo.data, action.payload],
        },
      };
    case 'UPDATE_PHOTO_UPLOAD_PROCESS':
      // adds one new photo to profile list.
      return {
        ...state,
        direct_s3_upload: action.payload.direct_s3_upload,
      };
    case 'DELETE_SINGLE_PHOTO':
      // removes a single photo from profile list.

      return {
        ...state,
        photo: { data: _.omit(state.photo.data, action.payload) },
      };
    case 'DELETE_SINGLE_PHOTO_FROM_PROFILE': {
      const arr = [];
      const object = _.omit(state.photo.data, action.payload);
      for (const property in object) {
        typeof object[property] === 'object' && arr.push(object[property]);
      }
      return {
        ...state,
        photo: { data: arr },
      };
    }
    case 'MAKE_ALL_AVATARS_FALSE': {
      newPhotoData = _.map(state.photo.data, v => {
        v.is_avatar = false;
        return v;
      });
      return {
        ...state,
        photo: { data: newPhotoData },
      };
    }
    case 'STORE_DEFAULT_PHOTO': {
      //make all avatars false.
      newPhotoData = _.map(state.photo.data, v => {
        v.is_avatar = false;
        return v;
      });
      // set new default photo.
      newPhotoData[action.payload].is_avatar = true;
      return {
        ...state,
        photo: { data: newPhotoData },
      };
    }
    case 'STORE_NEW_PRIVACY': {
      // swaps photo privacy..
      newPhotoData = _.map(state.photo.data, v => {
        return v;
      });

      const isPrivate = newPhotoData && newPhotoData[action.payload].private;
      newPhotoData[action.payload].private = !isPrivate;
      return {
        ...state,
        photo: { data: newPhotoData },
      };
    }
    case 'PENDING_UPLOAD':
      return {
        ...state,
        pendingUploads: action.payload.isPrivate ? { private: true } : { public: true },
      };
    case 'END_PENDING_UPLOAD':
      return {
        ...state,
        pendingUploads: action.payload.isPrivate ? { private: false } : { public: false },
      };
    case 'CLEAR_ALL_UPLOADS':
      return {
        ...state,
        pendingUploads: { private: false, public: false },
      };
    case 'UPDATE_NOTIFICATION_COUNTS':
      return {
        ...state,
        new_favorites: action.payload.data.new_favorites,
        offer_counts: {
          ...state.offer_counts,
          ...action.payload.data.offer_counts,
        },
        unread_messages: action.payload.data.unread_messages,
        has_fav_update: action.payload.data.has_fav_update,
        new_profile_views: action.payload.data.new_profile_views,
      };
    case 'DECREASE_NEW_PROFILE_VIEW_COUNT':
      return {
        ...state,
        new_profile_views: state.new_profile_views - 1,
      };
    case 'STORE_OFFERS_COUNT':
      return {
        ...state,
        offer_counts: {
          ...state.offer_counts,
          new: state.offer_counts.new - 1,
        },
      };
    case 'SET_NUX_GUIDES_FETCHING':
      return {
        ...state,
        nuxGuides: {
          isFetching: true,
          data: [...state.nuxGuides.data],
        },
      };
    case 'STORE_NUX_GUIDES':
      return {
        ...state,
        nuxGuides: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    case 'CLEAR_NUX_GUIDES':
      return {
        ...state,
        nuxGuides: {
          data: [],
          isFetching: false,
        },
      };
    case 'UPDATE_NUX_GUIDES':
      updatedNux = state.nuxGuides.data.filter(guide => guide.id != action.payload.data.id);

      return {
        ...state,
        nuxGuides: {
          isFetching: false,
          data: updatedNux,
        },
      };
    case 'RESET_NUX_STATES':
      updatedNux = state.nuxGuides.data.filter(guide => guide.id != action.payload.data.id);

      return {
        ...state,
        nuxGuides: {
          isFetching: false,
          data: updatedNux,
        },
      };
    case 'SET_GALLERY_FETCHING':
      return {
        ...state,
        gallery: {
          isFetching: true,
        },
      };
    case 'KILL_GALLERY_FETCHING':
      return {
        ...state,
        gallery: {
          isFetching: false,
        },
      };
    case 'UPDATE_VIDEO_STATUS': {
      // swaps photo privacy..
      newPhotoData = _.map(state.photo.data, v => {
        return v;
      });

      const index = _.findIndex(newPhotoData, _.pick({ id: action.payload.key, type: 'video' }, 'id'));

      if (newPhotoData[index] && newPhotoData[index].status) {
        newPhotoData[index].status = action.payload.status;
      }

      return {
        ...state,
        photo: { data: newPhotoData },
      };
    }
    case 'UPDATE_PHONE':
      return {
        ...state,
        phone_number: action.payload.phone_number,
      };
    default:
      return state;
  }
};

export default profile;
