export const SET_SITE_ATTRIBUTES = 'SET_SITE_ATTRIBUTES';

import { getSiteAttributes } from '../sdk/AttributeSDK';
import { AppDispatch } from '../store';
import { storeSettings } from './profileActions';

export const setSiteAttributes = (payload: any): any => {
  return {
    type: SET_SITE_ATTRIBUTES,
    payload,
  };
};

export const handleSiteAttributes = (): AppDispatch => async dispatch => {
  try {
    const response = await getSiteAttributes();
    const { captcha, is_eu_user: isEuUser, is_evidently_enabled, ...restOfData } = response.data;

    dispatch(
      setSiteAttributes({
        ...restOfData,
        isEuUser,
        captcha,
        isJoinCaptchaEnabled: captcha?.source === 'join' && !!captcha,
        isLoginCaptchaEnabled: captcha?.source === 'login' && !!captcha,
      })
    );
    dispatch(storeSettings({ is_evidently_enabled: is_evidently_enabled || false }));
  } catch (error) {
    console.error('Error fetching site attributes', error);
  }
};
