import { Profile, ProfileMaker, ProfileObject } from './Profile';
import {
  PWAInstallationPromptReturnObject,
  showPWAInstallationGlobalBanner,
  showPWAInstallationMessagePageBanner,
} from '../utils/pwa';
import { OfferActions } from '../utils/offers';

// Todo: Add all account attrs
export interface AccountCurrency {
  data: {
    code: string;
    initial: string;
    label: string;
    label_unicode: string;
    name: string;
  };
}
export interface AccountDataObject {
  hash_id: string;
  username: string;
  is_premium: boolean;
  is_admin: boolean;
  has_photo: boolean;
  is_favorite?: boolean | null;
  is_blocked?: boolean | null;
  credits: number;
  distance?: number;
  product_affinity: string;
  profile: ProfileObject;
  photo: any; // Todo: fix type. create photo model
  video: any; // Todo: fix type. create photo model
  id_verification: any; // Todo: fix type. create photo model
  mandatory_profile_completed: boolean;
  currency: AccountCurrency;
  is_mixpanel_eligible_and_enabled: boolean;
  cannot_make_offer_reasons?: [string];
  [key: string]: any;
}

export class Account {
  constructor(public data: AccountDataObject) {}

  getData(): AccountDataObject {
    return this.data;
  }

  getProfile(): Profile {
    return ProfileMaker.create(this.data.profile);
  }

  isMandatoryProfileCompleted(): boolean {
    return this.data.mandatory_profile_completed;
  }

  async showPWAInstallationGlobalBanner(): Promise<PWAInstallationPromptReturnObject> {
    return showPWAInstallationGlobalBanner(this);
  }

  async showPWAInstallationMessageScreenBanner(): Promise<PWAInstallationPromptReturnObject> {
    return showPWAInstallationMessagePageBanner(this);
  }

  isEligibleForMixpanelEvents(): boolean {
    return this.data.is_mixpanel_eligible_and_enabled;
  }

  getCannotMakeOfferReasons(): string[] {
    return this.data.cannot_make_offer_reasons || [];
  }

  hasManyLockedOffersDeals(): boolean {
    return this.getCannotMakeOfferReasons().some(reason => reason.includes('many locked deals'));
  }

  hasTooManyPendingReceivedOffers(): boolean {
    return this.getCannotMakeOfferReasons().some(reason => reason.includes('many pending received offers'));
  }

  hasTooManyPendingSentOffers(): boolean {
    return this.getCannotMakeOfferReasons().some(reason => reason.includes('many pending sent offers'));
  }

  shouldShowTooManyLockedOffersDeals(action?: OfferActions): boolean {
    // Should still be able to accept or ignore offers
    // When reason is 'many pending received offers
    if (action && ['decline', 'accept'].includes(action)) {
      return this.hasManyLockedOffersDeals();
    }

    return this.hasManyLockedOffersDeals() || this.hasTooManyPendingReceivedOffers();
  }

  shouldShowTooManySentOffers(action?: OfferActions): boolean {
    // Should still be able to accept or ignore offers
    // When reason is 'many pending received offers
    if (action && ['decline', 'accept'].includes(action)) {
      return this.hasManyLockedOffersDeals();
    }

    return this.hasTooManyPendingSentOffers();
  }
}

export class AccountMaker {
  static create(account: AccountDataObject): Account {
    return new Account(account);
  }
}
