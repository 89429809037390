import React from 'react';

interface IApplePayIcon {
  clean?: boolean;
  height?: any;
  width?: any;
  viewBox?: any;
  customStyle?: React.CSSProperties;
  darkmode?: boolean;
}

const ApplePayIcon: React.FC<IApplePayIcon> = ({
  clean = false,
  width = 58,
  height = 36,
  customStyle = {},
  darkmode = false,
}) => {
  if (clean) {
    return null;
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 58 36`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
    >
      <g clipPath="url(#clip0_7130_11799)">
        <path
          d="M52.8426 0H5.55718C5.35882 0 5.16394 0 4.96559 0C4.79855 0 4.63499 0 4.46795 0.00679437C4.10604 0.0169859 3.74065 0.0373691 3.38569 0.0985184C3.02378 0.163065 2.68622 0.264981 2.35563 0.431443C2.032 0.591111 1.7362 0.801736 1.47868 1.05313C1.22117 1.30452 1.00541 1.59328 0.841855 1.90922C0.674818 2.23195 0.56694 2.56148 0.504301 2.91479C0.438182 3.2647 0.417302 3.618 0.406862 3.97131C0.403382 4.13438 0.399902 4.29404 0.399902 4.45711C0.399902 4.65075 0.399902 4.84099 0.399902 5.03463V30.962C0.399902 31.1556 0.399902 31.3458 0.399902 31.5395C0.399902 31.7026 0.399902 31.8622 0.406862 32.0253C0.417302 32.3786 0.438182 32.7319 0.504301 33.0818C0.570419 33.4351 0.674818 33.7646 0.841855 34.0874C1.00541 34.4033 1.22117 34.6921 1.47868 34.9435C1.7362 35.1949 2.032 35.4055 2.35563 35.5652C2.68622 35.7282 3.02378 35.8335 3.38569 35.8981C3.74413 35.9592 4.10604 35.983 4.46795 35.9898C4.63499 35.9932 4.79855 35.9966 4.96559 35.9966C5.16046 35.9966 5.35882 35.9966 5.55718 35.9966H52.8391C53.034 35.9966 53.2324 35.9966 53.4307 35.9966C53.5978 35.9966 53.7613 35.9966 53.9284 35.9898C54.2903 35.9796 54.6522 35.9592 55.0106 35.8981C55.3725 35.8335 55.7101 35.7316 56.0407 35.5652C56.3643 35.4055 56.6601 35.1949 56.9176 34.9435C57.1752 34.6921 57.3909 34.4033 57.5545 34.0874C57.7215 33.7646 57.8294 33.4351 57.892 33.0818C57.9581 32.7319 57.979 32.3786 57.986 32.0253C57.9895 31.8622 57.9929 31.7026 57.9929 31.5395C57.9929 31.3458 57.9929 31.1556 57.9929 30.962V5.03463C57.9929 4.84099 57.9929 4.65075 57.9929 4.45711C57.9929 4.29404 57.9929 4.13438 57.986 3.97131C57.9755 3.618 57.9547 3.2613 57.892 2.91479C57.8259 2.56148 57.7215 2.23195 57.5545 1.90922C57.3909 1.59328 57.1752 1.30452 56.9176 1.05313C56.6601 0.801736 56.3643 0.591111 56.0407 0.431443C55.7101 0.268378 55.3725 0.163065 55.0106 0.0985184C54.6522 0.0373691 54.2868 0.0135887 53.9284 0.00679437C53.7613 0.00339719 53.5978 0 53.4307 0C53.2359 0 53.0375 0 52.8391 0L52.8426 0Z"
          fill={darkmode ? 'white' : 'black'}
        />
        <path
          d="M52.8427 1.19871H53.4239C53.5805 1.19871 53.7405 1.20211 53.8971 1.2055C54.1721 1.2123 54.4957 1.22589 54.795 1.28024C55.056 1.3278 55.2752 1.39575 55.484 1.50106C55.6928 1.60298 55.8807 1.73886 56.0477 1.90193C56.2148 2.06499 56.354 2.25184 56.4584 2.45567C56.5663 2.6595 56.6359 2.87352 56.6811 3.12831C56.7333 3.41707 56.7507 3.73301 56.7576 4.00479C56.7611 4.15766 56.7646 4.31053 56.7646 4.4668C56.7646 4.65705 56.7646 4.84389 56.7646 5.03413V30.9615C56.7646 31.1517 56.7646 31.3386 56.7646 31.5322C56.7646 31.6851 56.7646 31.8379 56.7576 31.9908C56.7507 32.2626 56.7368 32.5751 56.6811 32.8707C56.6359 33.1255 56.5628 33.3361 56.4584 33.5399C56.3505 33.7438 56.2148 33.9306 56.0477 34.0937C55.8807 34.2567 55.6928 34.3926 55.4805 34.4945C55.2717 34.5999 55.0525 34.6678 54.795 34.7154C54.4887 34.7697 54.1512 34.7833 53.9006 34.7901C53.7405 34.7935 53.5839 34.7969 53.4204 34.7969C53.2255 34.7969 53.0341 34.7969 52.8392 34.7969H5.55031C5.35892 34.7969 5.16752 34.7969 4.97264 34.7969C4.81256 34.7969 4.65597 34.7969 4.50285 34.7901C4.24533 34.7833 3.90778 34.7697 3.60502 34.7154C3.34403 34.6712 3.12479 34.5999 2.91252 34.4945C2.70372 34.3926 2.5158 34.2567 2.34877 34.0937C2.18173 33.9306 2.04601 33.7472 1.93813 33.5433C1.83025 33.3395 1.76066 33.1255 1.71194 32.8673C1.65626 32.5751 1.64234 32.2592 1.63538 31.9908C1.6319 31.8379 1.62842 31.6851 1.62842 31.5322V31.0804V30.9615V4.91523V4.46341C1.62842 4.31053 1.62842 4.15766 1.63538 4.00139C1.64234 3.73301 1.65626 3.41707 1.71194 3.12152C1.75718 2.86673 1.83025 2.65271 1.93813 2.44887C2.04253 2.24504 2.18173 2.0616 2.34877 1.89853C2.5158 1.73547 2.70372 1.60298 2.91252 1.49766C3.12131 1.39235 3.34055 1.32441 3.60154 1.27685C3.90082 1.22249 4.22445 1.2089 4.49937 1.20211C4.65597 1.19871 4.81604 1.19531 4.96916 1.19531H5.55379H52.8358"
          fill={darkmode ? 'black' : 'white'}
        />
        <path
          d="M16.1258 12.1059C16.62 11.5046 16.9541 10.6961 16.8671 9.86719C16.1467 9.90116 15.2628 10.3326 14.7547 10.9339C14.2954 11.4503 13.8917 12.2928 13.9961 13.0843C14.8069 13.1523 15.6178 12.6903 16.1258 12.1025"
          fill={darkmode ? 'white' : 'black'}
        />
        <path
          d="M16.8564 13.2428C15.6802 13.1749 14.678 13.8951 14.1177 13.8951C13.5574 13.8951 12.6944 13.2768 11.7653 13.2938C10.5543 13.3107 9.43023 13.98 8.81428 15.0433C7.55107 17.17 8.48021 20.3259 9.70863 22.0619C10.3072 22.918 11.024 23.8624 11.9741 23.8284C12.8684 23.7945 13.2199 23.2611 14.3091 23.2611C15.3983 23.2611 15.715 23.8284 16.6616 23.8115C17.6464 23.7945 18.2588 22.952 18.8574 22.0959C19.5429 21.1175 19.8213 20.1765 19.8422 20.1221C19.8248 20.1051 17.9457 19.4019 17.9283 17.2923C17.9109 15.5257 19.4037 14.6866 19.4733 14.6323C18.6312 13.4161 17.3123 13.2768 16.8564 13.2428Z"
          fill={darkmode ? 'white' : 'black'}
        />
        <path
          d="M27.1014 10.8516C29.6592 10.8516 31.4409 12.5739 31.4409 15.0777C31.4409 17.5814 29.6244 19.3241 27.0388 19.3241H24.2061V23.7201H22.1599V10.8516H27.1014ZM24.2061 17.6493H26.5551C28.3368 17.6493 29.3495 16.7117 29.3495 15.0913C29.3495 13.4708 28.3368 12.54 26.562 12.54H24.2061V17.6493Z"
          fill={darkmode ? 'white' : 'black'}
        />
        <path
          d="M31.9769 21.0575C31.9769 19.4166 33.2644 18.4077 35.5508 18.282L38.1816 18.1291V17.4055C38.1816 16.3626 37.4613 15.7375 36.2537 15.7375C35.1123 15.7375 34.3989 16.2708 34.2249 17.1099H32.3597C32.4675 15.4147 33.95 14.168 36.3233 14.168C38.6966 14.168 40.1408 15.3706 40.1408 17.2526V23.7175H38.2512V22.1751H38.206C37.6492 23.2181 36.4347 23.8771 35.1715 23.8771C33.2888 23.8771 31.9734 22.7357 31.9734 21.0507L31.9769 21.0575ZM38.1816 20.2116V19.471L35.8152 19.6137C34.6355 19.6952 33.9709 20.2014 33.9709 21.0065C33.9709 21.8116 34.6669 22.362 35.7248 22.362C37.1028 22.362 38.1816 21.4346 38.1816 20.2116Z"
          fill={darkmode ? 'white' : 'black'}
        />
        <path
          d="M41.9295 27.1739V25.6146C42.0757 25.6486 42.4028 25.6486 42.5698 25.6486C43.485 25.6486 43.9757 25.2749 44.2785 24.3101C44.2785 24.2931 44.4525 23.7394 44.4525 23.7292L40.9795 14.3359H43.1162L45.5452 21.9694H45.58L48.009 14.3359H50.0935L46.4952 24.2082C45.6739 26.4843 44.7239 27.2147 42.7299 27.2147C42.5663 27.2147 42.0722 27.1977 41.926 27.1705L41.9295 27.1739Z"
          fill={darkmode ? 'white' : 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7130_11799">
          <rect width="57.6" height="36" fill={darkmode ? 'black' : 'white'} transform="translate(0.399902)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApplePayIcon;
