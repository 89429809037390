import React, { FC } from 'react';
import { termsOfUseUrl, isEU } from '../../utils/helpers';
import { SITE_NAME } from '../../config/Master';

interface ITwilioVerbiage {
  profileCountry?: string;
}
export const TwilioVerbiage: FC<ITwilioVerbiage> = ({ profileCountry = '' }) => {
  const isEUCountry = isEU(profileCountry);
  const termsLink = (
    <a href={termsOfUseUrl(isEUCountry)} target="_blank" rel="noreferrer">
      here
    </a>
  );

  return (
    <div className="twilioVerbiage">
      By sharing your mobile number with {SITE_NAME}, you agree to receive account-related text messages. This agreement
      isn&#39;t a condition of any purchase. Terms and Privacy Policy can be found {termsLink}. Message frequency
      varies. Message and data rates may apply.
    </div>
  );
};
