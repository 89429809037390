import React from 'react';
import {
  Search,
  StarBorder,
  Star,
  SwapVerticalCircle,
  SwapVerticalCircleOutlined,
  Forum,
  ForumOutlined,
  AssignmentReturnedSharp,
  SwapVert,
  Close,
  Favorite,
  Visibility,
  DateRange,
  Videocam,
  AttachMoney,
  ChatBubbleOutline,
  MailOutline,
  Replay,
  AccountCircle,
  Create as CreateIcon,
  PhotoCamera,
  CloudUpload,
  AccessTime,
  WatchLater,
  CheckCircle,
  Send,
  InsertEmoticon,
  Add,
  Tune,
  KeyboardOutlined,
  InsertPhotoOutlined,
  BrokenImage,
  VisibilityOffOutlined,
} from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import styled from '@emotion/styled';
import { backgroundTheme, borders, color, svgTheme } from '../../style';
import { getBrowserDetails } from '../../utils/helpers';
import Tooltip from 'rc-tooltip';
import MasterConfig from '../../config/Master';

const generalStyles = {
  fontSize: 32,
  // color: '#336699',
  color: backgroundTheme.block,
};

const commonStyles = {
  fontSize: 26,
  color: '#1383d2',
};

const smallIconStyles = {
  fontSize: 16,
  color: '#1383d2',
};

const successStyle = {
  fontSize: 44,
  color: color.BRAND_1_ONLINE,
  marginLeft: 'auto',
};

const verifiedStyle = {
  fontSize: 14,
  color: color.BRAND_2,
};

const verifiedBadgeStyle = {
  fontSize: 16,
  color: color.BRAND_2,
};

const emojiGifStyles = {
  fontSize: 33,
  color: backgroundTheme.iconFill,
  // position: 'absolute',
  top: 12,
  left: 12,
  height: '1em',
  width: '1em',
  zIndex: 1,
};

const clearTextStyle = {
  fontSize: 24,
  color: svgTheme.svgColor,
};

const sendGifStyles = {
  left: 'unset',
  right: 14,
  fontSize: 30,
  top: 14,
};

const gifButtonStyle = {
  fontSize: 39,
  color: backgroundTheme.iconFill,
  marginTop: 2,
  height: '1em',
  width: '1em',
};

const defaultColor = {
  color: backgroundTheme.iconFill,
};

const navbarStyles = {
  fontSize: getBrowserDetails().isMobile ? 26 : 33,
  color: '#0d5c93',
};

const darkStyles = {
  color: '#71B5E4',
};

const commonFontStyles = {
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.65)',
  fontWeight: 400,
};

const highlightNavbarStyle = {
  fontSize: 60,
};

const highlightStyle = {
  fontSize: 60,
  boxSizing: 'border-box',
  height: 82,
  width: 82,
  border: borders.DEFAULT,
  borderRadius: 100,
  backgroundColor: backgroundTheme.block,
  padding: 14,
  cursor: 'pointer',
};

const HighlightsDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: 600,
  margin: '0 6px',
});

const FooterNavLink = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: 600,
  margin: '0 10px',
});

const HighlightsLabel = styled('span')({
  color: '#3E79A5',
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: 0,
  textAlign: 'center',
  paddingTop: 2,
});

const ignoreStyle = {
  color: '#888888',
};

const counterStyle = {
  color: '#007EF3',
};

const suggestOfferStyle = {
  color: '#F3B509',
  padding: 8,
};

const acceptStyle = {
  color: '#FF00BC',
};

const darkFontStyles = {
  color: '#cfcfcf',
};

const searchIcon = customStyle => {
  return <SvgIcon component={Search} style={customStyle} />;
};

const favouriteIcon = customStyle => {
  return <SvgIcon component={StarBorder} style={customStyle} />;
};

const offerIcon = customStyle => {
  return <SvgIcon component={SwapVert} style={customStyle} />;
};

const mailIcon = customStyle => {
  return <SvgIcon component={ChatBubbleOutline} style={customStyle} />;
};

const emailIcon = customStyle => {
  return <SvgIcon component={MailOutline} style={customStyle} />;
};

const resendIcon = customStyle => {
  return <SvgIcon component={Replay} style={customStyle} />;
};

const accountIcon = customStyle => {
  return <SvgIcon component={AccountCircle} style={customStyle} />;
};

const editIcon = customStyle => {
  return <SvgIcon component={CreateIcon} style={customStyle} />;
};

const cameraIcon = customStyle => {
  return <SvgIcon component={PhotoCamera} style={customStyle} />;
};

const uploadIcon = customStyle => {
  return <SvgIcon component={CloudUpload} style={customStyle} />;
};

const waitIcon = customStyle => {
  return <SvgIcon component={AccessTime} style={customStyle} />;
};

const sendIcon = customStyle => {
  return <SvgIcon component={Send} style={customStyle} />;
};

const closeIcon = customStyle => {
  return <SvgIcon component={Close} style={customStyle} />;
};

const keyboardIcon = customStyle => {
  return <SvgIcon component={KeyboardOutlined} style={customStyle} />;
};

const textForIcon = (text, customStyle) => {
  return <span style={customStyle}>{text}</span>;
};

const getNavIcon = (iconName, setIconStyles) => {
  switch (iconName) {
    case 'Search':
      return searchIcon(setIconStyles);
    case 'Favorites':
    case 'Interests':
      return favouriteIcon(setIconStyles);
    case 'Offers':
      return offerIcon(setIconStyles);
    case 'Messages':
      return mailIcon(setIconStyles);
    default:
      break;
  }
};

const defaultActiveNavLink = textLink => {
  return (
    <FooterNavLink>
      {getNavIcon(textLink, navbarStyles)}
      {textForIcon(textLink, commonFontStyles)}
    </FooterNavLink>
  );
};

const darkAndDarkActiveNavLink = textLink => {
  return (
    <FooterNavLink>
      {getNavIcon(textLink, { ...navbarStyles, ...darkStyles })}
      {textForIcon(textLink, { ...commonFontStyles, ...darkFontStyles })}
    </FooterNavLink>
  );
};

const navbarIcons = {
  search: {
    default: (): any => defaultActiveNavLink('Search'),
    active: (): any => defaultActiveNavLink('Search'),
    dark: (): any => darkAndDarkActiveNavLink('Search'),
    darkActive: (): any => darkAndDarkActiveNavLink('Search'),
    noLabel: (): any => searchIcon(navbarStyles),
  },
  favorites: {
    default: (): any => defaultActiveNavLink('Favorites'),
    active: (): any => defaultActiveNavLink('Favorites'),
    dark: (): any => darkAndDarkActiveNavLink('Favorites'),
    darkActive: (): any => darkAndDarkActiveNavLink('Favorites'),
    noLabel: (): any => favouriteIcon(navbarStyles),
  },
  interests: {
    default: (): any => defaultActiveNavLink('Interests'),
    active: (): any => defaultActiveNavLink('Interests'),
    dark: (): any => darkAndDarkActiveNavLink('Interests'),
    darkActive: (): any => darkAndDarkActiveNavLink('Interests'),
    noLabel: (): any => favouriteIcon(navbarStyles),
  },
  offers: {
    default: (): any => defaultActiveNavLink('Offers'),
    active: (): any => defaultActiveNavLink('Offers'),
    noLabel: (): any => offerIcon(navbarStyles),
    highlight: (): any => (
      <HighlightsDiv>
        {offerIcon({ ...highlightNavbarStyle, color: color.BRAND_1 })}
        {textForIcon('Offers', { color: color.BRAND_1 })}
      </HighlightsDiv>
    ),
    dark: (): any => darkAndDarkActiveNavLink('Offers'),
    darkActive: (): any => darkAndDarkActiveNavLink('Offers'),
  },
  mail: {
    default: (): any => defaultActiveNavLink('Messages'),
    active: (): any => defaultActiveNavLink('Messages'),
    dark: (): any => darkAndDarkActiveNavLink('Messages'),
    darkActive: (): any => darkAndDarkActiveNavLink('Messages'),
    noLabel: (): any => mailIcon(navbarStyles),
  },
};

const commonIcons = {
  email: {
    default: (): any => emailIcon(commonStyles),
    active: (): any => emailIcon(commonStyles),
    small: (): any => emailIcon(smallIconStyles),
  },
  resend: {
    default: (): any => resendIcon(commonStyles),
    active: (): any => resendIcon(commonStyles),
    small: (): any => resendIcon(smallIconStyles),
  },
  account: {
    default: (): any => accountIcon(commonStyles),
    active: (): any => accountIcon(commonStyles),
    small: (): any => accountIcon(smallIconStyles),
  },
  edit: {
    default: (): any => editIcon(commonStyles),
    active: (): any => editIcon(commonStyles),
    small: (): any => editIcon(smallIconStyles),
  },
  camera: {
    default: (): any => cameraIcon(commonStyles),
    active: (): any => cameraIcon(commonStyles),
    small: (): any => cameraIcon(smallIconStyles),
  },
  upload: {
    default: (): any => uploadIcon(commonStyles),
    active: (): any => uploadIcon(commonStyles),
    small: (): any => uploadIcon(smallIconStyles),
  },
  wait: {
    default: (): any => waitIcon(commonStyles),
    active: (): any => <SvgIcon component={WatchLater} style={commonStyles} />,
    small: (): any => waitIcon(smallIconStyles),
  },
  check: {
    default: (): any => <SvgIcon component={CheckCircle} style={successStyle} viewBox={'2 0 22 26'} />,
    raw: (): any => <SvgIcon component={CheckCircle} style={{ fontSize: 16 }} />,
    hollow: (): any => (
      <SvgIcon component={CheckCircle} style={{ backgroundColor: 'transparent', color: '#fff', fontSize: 16 }} />
    ),
    verified: (): any => <SvgIcon component={CheckCircle} style={verifiedStyle} />,
    badge: (props: any): any => {
      return (
        <Tooltip
          visible={props.showToolTip}
          placement="top"
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          overlay={`This member has had their identity individually verified by the ${MasterConfig.SITE_NAME} team and a third-party service`}
          overlayStyle={{ maxWidth: '280px' }}
        >
          <VerifiedBadgeIcon style={props.customStyle ? props.customStyle : verifiedBadgeStyle} viewBox="0 0 46 46" />
        </Tooltip>
      );
    },
  },
  send: {
    default: (): any => sendIcon({ ...emojiGifStyles, ...sendGifStyles, ...defaultColor, position: 'absolute' }),
    inactive: (): any => sendIcon({ ...emojiGifStyles, ...sendGifStyles, color: '#a7d3f2', position: 'absolute' }),
    active: (): any => sendIcon(commonStyles),
    small: (): any => sendIcon(smallIconStyles),
  },
  emoticon: {
    default: (): any => <SvgIcon component={InsertEmoticon} style={emojiGifStyles} />,
    inactive: (): any => <SvgIcon component={InsertEmoticon} style={{ ...emojiGifStyles, color: '#a7d3f2' }} />,
    keyboard: (): any => keyboardIcon(emojiGifStyles),
  },
  plus: {
    default: (): any => <SvgIcon component={Add} style={{ ...emojiGifStyles, ...sendGifStyles }} />,
  },
  close: {
    default: (): any => closeIcon(emojiGifStyles),
    gifClose: (): any => closeIcon({ ...emojiGifStyles, ...sendGifStyles }),
    clear: (): any => closeIcon(clearTextStyle),
  },
  keyboard: {
    emoji: (): any => keyboardIcon(emojiGifStyles),
    gif: (): any => keyboardIcon({ ...emojiGifStyles, ...sendGifStyles }),
  },
  photo: {
    default: (): any => (
      <SvgIcon viewBox="0 2 24 24" component={InsertPhotoOutlined} style={{ ...gifButtonStyle, ...defaultColor }} />
    ),
    inactive: (): any => (
      <SvgIcon viewBox="0 2 24 24" component={InsertPhotoOutlined} style={{ ...gifButtonStyle, color: '#a7d3f2' }} />
    ),
  },
  tune: {
    default: (): any => <SvgIcon component={Tune} style={commonStyles} />,
  },
  brokenImage: {
    default: (): any => <SvgIcon component={BrokenImage} style={{ ...commonStyles, color: '#FFFFFF' }} />,
  },
};

const renderInterestedIcon = () => {
  return (
    <HighlightsDiv>
      <InterestedFavorite
        component={svgProps => {
          return (
            <svg {...svgProps} viewBox="0 0 24 24">
              <defs>
                <linearGradient id="FavoriteIconGradient" x1="40%" y2="60%">
                  <stop offset="0%" stopColor={'#F3B509'} />
                  <stop offset="100%" stopColor={'#F3B509'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#FavoriteIconGradient)' })}
            </svg>
          );
        }}
      />
      <HighlightsLabel>Favorite</HighlightsLabel>
    </HighlightsDiv>
  );
};

const renderInterestedFalseIcon = () => {
  return (
    <HighlightsDiv>
      <InterestedFavoriteFalse
        component={svgProps => {
          return (
            <svg {...svgProps} viewBox="0 0 24 24">
              <defs>
                <linearGradient id="FavoriteIconGradient" x1="40%" y2="60%">
                  <stop offset="0%" stopColor={'#F3B509'} />
                  <stop offset="100%" stopColor={'#F3B509'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#FavoriteIconGradient)' })}
            </svg>
          );
        }}
      />
      <HighlightsLabel>Favorite</HighlightsLabel>
    </HighlightsDiv>
  );
};

const renderOfferIcon = () => {
  return (
    <SuggestOfferIcon
      component={svgProps => {
        return (
          <svg {...svgProps} viewBox="-1 0 24 24">
            <defs>
              <linearGradient id="SuggestIconGradient" x1="40%" y2="60%">
                <stop offset="0%" stopColor={'#F3B509'} />
                <stop offset="100%" stopColor={'#F3B509'} />
              </linearGradient>
            </defs>
            {React.cloneElement(svgProps.children[0], { fill: 'url(#SuggestIconGradient)' })}
          </svg>
        );
      }}
    />
  );
};

const renderIgnoreIcon = () => {
  return (
    <IgnoreIcon
      component={svgProps => {
        return (
          <svg {...svgProps}>
            <defs>
              <linearGradient id="IgnoreIconGradient" x2="20%" y2="80%">
                <stop offset="0%" stopColor={'#BBBBBB'} />
                <stop offset="100%" stopColor={'#888888'} />
              </linearGradient>
            </defs>
            {React.cloneElement(svgProps.children[0], { fill: 'url(#IgnoreIconGradient)' })}
          </svg>
        );
      }}
    />
  );
};

const generalIcons = {
  search: {
    default: (): any => searchIcon(generalStyles),
    active: (): any => searchIcon(generalStyles),
  },
  mail: {
    default: (): any => <SvgIcon component={Forum} style={generalStyles} />,
    active: (): any => <SvgIcon component={ForumOutlined} style={generalStyles} />,
    activity: (): any => (
      <MessageIcon
        component={svgProps => {
          return (
            <svg {...svgProps} style={{ width: 27, height: 27 }} viewBox="0 6 24 15">
              <defs>
                <linearGradient id="MessageIconGradient" x2="0%" y2="100%">
                  <stop offset="0%" stopColor={'#00B894'} />
                  <stop offset="100%" stopColor={'#008000'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#MessageIconGradient)' })}
            </svg>
          );
        }}
      />
    ),
  },
  date: {
    default: (): any => <SvgIcon component={DateRange} style={generalStyles} />,
    activity: (): any => (
      <DateIcon
        component={svgProps => {
          return (
            <svg {...svgProps} style={{ width: 27, height: 27 }} viewBox="0 6 24 15">
              <defs>
                <linearGradient id="DateIconGradient" x2="0%" y2="100%">
                  <stop offset="0%" stopColor={'#1F85CE'} />
                  <stop offset="100%" stopColor={'#5BB1F1'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#DateIconGradient)' })}
            </svg>
          );
        }}
      />
    ),
  },
  eye: {
    activity: (): any => (
      <EyeIcon
        component={svgProps => {
          return (
            <svg {...svgProps} style={{ width: 27 }} viewBox="0 0 24 15">
              <defs>
                <linearGradient id="EyeIconGradient" x2="0%" y2="100%">
                  <stop offset="0%" stopColor={'#5BB1F1'} />
                  <stop offset="100%" stopColor={'#1F85CE'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#EyeIconGradient)' })}
            </svg>
          );
        }}
      />
    ),
    hidden: (): any => (
      <SvgIcon
        component={VisibilityOffOutlined}
        viewBox="0 -4 24 22"
        style={{ color: 'rgba(255,255,255,0.7)', fontSize: 13 }}
      />
    ),
  },
  videoCam: {
    activity: (): any => (
      <CamIcon
        component={svgProps => {
          return (
            <svg {...svgProps} style={{ width: 27 }} viewBox="0 0 24 15">
              <defs>
                <linearGradient id="CamIconGradient" x2="0%" y2="100%">
                  <stop offset="0%" stopColor={'#5BB1F1'} />
                  <stop offset="100%" stopColor={'#2490de'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#CamIconGradient)' })}
            </svg>
          );
        }}
      />
    ),
  },
  favorites: {
    default: (): any => favouriteIcon(generalStyles),
    active: (): any => <SvgIcon component={Star} style={generalStyles} />,
    activity: (): any => (
      <FavIcon
        component={svgProps => {
          return (
            <svg {...svgProps} style={{ width: 27, height: 25 }} viewBox="5 2 15 20">
              <defs>
                <linearGradient id="FavIconGradient" x2="0%" y2="100%">
                  <stop offset="0%" stopColor={'#FFFF00'} />
                  <stop offset="100%" stopColor={'#FFAF00'} />
                </linearGradient>
              </defs>
              {React.cloneElement(svgProps.children[0], { fill: 'url(#FavIconGradient)' })}
            </svg>
          );
        }}
      />
    ),
  },
  interested: {
    active: (): any => renderInterestedIcon(),
    default: (): any => renderInterestedFalseIcon(),
  },
  offers: {
    default: (): any => <SvgIcon component={SwapVerticalCircle} style={generalStyles} />,
    active: (): any => <SvgIcon component={SwapVerticalCircleOutlined} style={generalStyles} />,
    highlight: (): any => (
      <HighlightsDiv>
        <OffersIcon
          component={svgProps => {
            return (
              <svg {...svgProps}>
                <defs>
                  <linearGradient id="OfferIconGradient" x2="20%" y2="80%">
                    <stop offset="0.08%" stopColor={'#007EF3'} />
                    <stop offset="100%" stopColor={'#001BBB'} />
                  </linearGradient>
                </defs>
                {React.cloneElement(svgProps.children[0], { fill: 'url(#OfferIconGradient)' })}
              </svg>
            );
          }}
        />
        <HighlightsLabel>Counter</HighlightsLabel>
      </HighlightsDiv>
    ),
    request: (): any => (
      <HighlightsDiv>
        {renderOfferIcon()}
        <HighlightsLabel>Request Offer</HighlightsLabel>
      </HighlightsDiv>
    ),
    genOffer: (): any => (
      <HighlightsDiv>
        {renderOfferIcon()}
        <HighlightsLabel>Make Offer</HighlightsLabel>
      </HighlightsDiv>
    ),
  },
  ignore: {
    default: (): any => <SvgIcon component={AssignmentReturnedSharp} style={generalStyles} />,
    highlight: (): any => (
      <HighlightsDiv>
        {renderIgnoreIcon()}
        <HighlightsLabel>Ignore</HighlightsLabel>
      </HighlightsDiv>
    ),
    cancel: (): any => (
      <HighlightsDiv>
        {renderIgnoreIcon()}
        <HighlightsLabel>Cancel</HighlightsLabel>
      </HighlightsDiv>
    ),
  },
  accept: {
    default: (): any => <SvgIcon component={Favorite} style={generalStyles} />,
    highlight: (): any => (
      <HighlightsDiv>
        <FavoriteIcon
          component={svgProps => {
            return (
              <svg {...svgProps}>
                <defs>
                  <linearGradient id="FavoriteIconGradient" x1="40%" y2="60%">
                    <stop offset="0%" stopColor={'#FFBA72'} />
                    <stop offset="100%" stopColor={'#FF00BC'} />
                  </linearGradient>
                </defs>
                {React.cloneElement(svgProps.children[0], { fill: 'url(#FavoriteIconGradient)' })}
              </svg>
            );
          }}
        />
        <HighlightsLabel>Accept</HighlightsLabel>
      </HighlightsDiv>
    ),
  },
  suggest: {
    default: (): any => <SvgIcon component={AttachMoney} style={generalStyles} />,
    highlight: (): any => (
      <HighlightsDiv>
        <SuggestOfferIcon
          component={svgProps => {
            return (
              <svg {...svgProps} viewBox="-1 0 24 24">
                <defs>
                  <linearGradient id="SuggestIconGradient" x1="40%" y2="60%">
                    <stop offset="0%" stopColor={'#F3B509'} />
                    <stop offset="100%" stopColor={'#F3B509'} />
                  </linearGradient>
                </defs>
                {React.cloneElement(svgProps.children[0], { fill: 'url(#SuggestIconGradient)' })}
              </svg>
            );
          }}
        />
        <HighlightsLabel>Send Offer</HighlightsLabel>
      </HighlightsDiv>
    ),
  },
};

const FavoriteIcon = (props: any) => {
  return (
    <SvgIcon component={Favorite} {...props} style={{ ...highlightStyle, ...acceptStyle }}>
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
    </SvgIcon>
  );
};

const IgnoreIcon = (props: any) => {
  return (
    <SvgIcon component={Close} {...props} style={{ ...highlightStyle, ...ignoreStyle }}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </SvgIcon>
  );
};

const OffersIcon = props => {
  return (
    <SvgIcon component={SwapVert} {...props} style={{ ...highlightStyle, ...counterStyle }}>
      <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"></path>
    </SvgIcon>
  );
};

const SuggestOfferIcon = props => {
  return (
    <SvgIcon component={AttachMoney} {...props} style={{ ...highlightStyle, ...suggestOfferStyle }}>
      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"></path>
    </SvgIcon>
  );
};

const InterestedFavorite = props => {
  return (
    <SvgIcon component={Star} {...props} style={{ ...highlightStyle, ...suggestOfferStyle }}>
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
    </SvgIcon>
  );
};

const InterestedFavoriteFalse = props => {
  return (
    <SvgIcon component={StarBorder} {...props} style={{ ...highlightStyle, ...suggestOfferStyle }}>
      <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
    </SvgIcon>
  );
};

const MessageIcon = props => {
  return (
    <SvgIcon component={Forum} {...props}>
      <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"></path>
    </SvgIcon>
  );
};

const DateIcon = props => {
  return (
    <SvgIcon component={DateRange} {...props}>
      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
    </SvgIcon>
  );
};

const FavIcon = props => {
  return (
    <SvgIcon component={Star} {...props}>
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
    </SvgIcon>
  );
};

const EyeIcon = props => {
  return (
    <SvgIcon component={Visibility} {...props}>
      <path d="M12.1767413,10.341455 C10.5369745,10.4495539 9.18195766,9.03397392 9.28996625,7.31468771 C9.37337834,5.89396019 10.4731513,4.74605353 11.8281191,4.65339739 C13.4679349,4.54529856 14.8229027,5.96087852 14.7148941,7.68016472 C14.6265235,9.10603981 13.5268487,10.2539465 12.1767413,10.341455 M12.3289353,12.7814001 C9.28505677,12.9821551 6.77135318,10.3517502 6.96287205,7.15511325 C7.11997545,4.51956074 9.15741025,2.38332189 11.6710647,2.21859986 C14.7148941,2.01784489 17.2285486,4.64824983 17.037128,7.84488675 C16.875066,10.4752917 14.8376312,12.6115305 12.3289353,12.7814001 M0.187787665,6.90288264 C-0.0625958883,7.25291695 -0.0625958883,7.74193548 0.187787665,8.0919698 C3.25621356,12.3695951 7.41454434,15 11.9999509,15 C16.5854557,15 20.7437864,12.3695951 23.8122123,8.09711736 C24.0625959,7.74708305 24.0625959,7.25806452 23.8122123,6.9080302 C20.7437864,2.63040494 16.5854557,0 11.9999509,0 C7.41454434,0 3.25621356,2.63040494 0.187787665,6.90288264 Z"></path>
    </SvgIcon>
  );
};

const CamIcon = props => {
  return (
    <SvgIcon component={Videocam} {...props}>
      <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"></path>
    </SvgIcon>
  );
};

const VerifiedBadgeIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fill="#1986d4"
        d="M24,2l4.5,5L35,5l1.5,6.5L43,13l-2,6.5l5,4.5l-5,4.5l2,6.5l-6.5,1.5L35,43l-6.5-2L24,46l-4.5-5L13,43l-1.5-6.5	L5,35l2-6.5L2,24l5-4.5L5,13l6.5-1.5L13,5l6.5,2L24,2z"
      ></path>
      <path fill="#ffffff" d="M34.6,14.6L21,28.2l-5.6-5.6l-2.8,2.8l8.4,8.4l16.4-16.4L34.6,14.6z"></path>
    </SvgIcon>
  );
};

export { generalIcons, navbarIcons, commonIcons };
