import React, { useEffect } from 'react';
import ReactLightbox from 'react-image-lightbox';
import { Props } from './types/Lightbox';

const Lightbox: React.FC<Props> = ({
  onCloseRequest,
  onAfterOpen,
  onMovePrevRequest,
  onMoveNextRequest,
  imageTitle,
  currentImageIndex,
  ...props
}) => {
  useEffect(() => {
    document.addEventListener('click', handleMoveNextPhotoOnImageClick);
    return () => {
      document.removeEventListener('click', handleMoveNextPhotoOnImageClick);
    };
  }, [currentImageIndex]);

  const handleMoveNextPhotoOnImageClick: EventListener = e => {
    const target = e.target as HTMLImageElement;

    if (target.className === 'ril-image-current ril__image' && onMoveNextRequest) {
      onMoveNextRequest();
    }
  };

  const handleClose: EventListener = e => {
    e.preventDefault();
    const target = e.target as HTMLDivElement;

    if (target.className === 'ril-toolbar ril__toolbar' && onCloseRequest) {
      onCloseRequest();
    }
    return false;
  };

  const handleAfterOpen = (): void => {
    const toolbar = document.querySelector('.ril-toolbar');

    if (toolbar) {
      toolbar.addEventListener('click', handleClose);
    }
    if (onAfterOpen) {
      onAfterOpen();
    }
  };

  return (
    <ReactLightbox
      onCloseRequest={onCloseRequest}
      onAfterOpen={handleAfterOpen || onAfterOpen}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
      imageTitle={imageTitle}
      {...props}
    />
  );
};

export default Lightbox;
