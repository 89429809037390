/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import { trackUnlockMessage } from '../../../actions/profileTrackAction';
import useMixPanel from '../../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../../constants/mixpanel';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { useNavigate } from 'react-router-dom';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 26,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const ButtonDiv = styled('div')({
  marginTop: 22,

  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 12,
    marginTop: 0,
    paddingTop: 12,
    width: '100%',
  },
});

interface OfferAcceptUnlockProps {
  offerAmount: any;
  avatarSrc: any;
  username: any;
  buttonID: any;
  successOpen: any;
  creditCost: any;
  hashId: any;
  currentUserIsGenerous: any;
  closeModal: any;
  currentCredit: any;
  unlockMessage(hashId: any, triggerToBuy: boolean, callback: any): void;
}

const OfferAcceptUnlock = ({
  offerAmount,
  avatarSrc,
  username,
  buttonID,
  successOpen,
  creditCost,
  hashId,
  currentUserIsGenerous,
  closeModal,
  currentCredit,
  unlockMessage,
}: OfferAcceptUnlockProps): ReactElement => {
  const unlockCancelBtn = useRef(null);
  const { mixpanelData, resetMixpanelData, trackPackagesPageVisited } = useMixPanel();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    successOpen(false);

    trackUnlockMessage();

    if (creditCost > currentCredit) {
      trackPackagesPageVisited({ Source: 'Unlock Attempt' });
      navigate('/packages');
      return;
    }

    showMandatoryCompleteProfile(
      () => {
        unlockMessage(hashId, mixpanelData[EVENT_NAMES.CONVERSATION_UNLOCKED].triggerToBuy, () => {
          resetMixpanelData(EVENT_NAMES.CONVERSATION_UNLOCKED);
        });
        setTimeout(
          () =>
            currentUserIsGenerous
              ? navigate(`/mail/${hashId}`)
              : navigate('/mail/remindMessage=true'),
          500
        );
      },
      () => undefined,
      'Unlock Offer'
    );
  };

  const getHeadline = () => {
    // Your first message has been unlocked.
    const subText = currentUserIsGenerous ? (
      <p style={{ marginTop: 10 }}>
        Unlock messaging for <strong>{creditCost} Credits</strong> and start planning your date!
      </p>
    ) : (
      <p style={{ marginTop: 10 }}>Send {username} a note and start planning your date!</p>
    );

    return (
      <div>
        <span>
          You&lsquo;ve accepted <strong>{username}</strong>&lsquo;s offer of {offerAmount}.{' '}
        </span>
        <br />
        {subText}
      </div>
    );
  };

  return (
    <Form>
      <HeaderDiv>
        <AvatarTitleDiv>
          <Avatar
            data-test-id="offer-modal-avatar"
            src={avatarSrc}
            width={getBrowserDetails().isMobile ? '75px' : '100px'}
            customStyle={{
              backgroundColor: '#ccc',
            }}
          />
          <TitleDiv>Offer Accepted!</TitleDiv>
        </AvatarTitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            height: IsMobileViewPort ? 'auto' : 30,
            marginTop: 20,
            marginBottom: IsMobileViewPort ? 0 : 20,
            fontSize: getBrowserDetails().isMobile ? 14 : 16,
          }}
          data-test-id="profile-offer-headline"
          size="SM"
        >
          {getHeadline()}
        </Headline>
      </HeaderDiv>
      <Content>
        <ButtonDiv marginTop={16}>
          <ButtonSet align="center">
            <Button id={buttonID} buttonType="error" onClick={handleSubmit} type="submit">
              {currentUserIsGenerous ? 'Unlock Messaging' : 'Go to Conversation'}
            </Button>
          </ButtonSet>
          <ButtonSet align="center">
            <Button
              buttonType="chromeless"
              buttonRef={unlockCancelBtn}
              data-test-id={`${buttonID}Cancel`}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
              customStyle={{ color: patternModal.linkBlue, fontWeight: 'normal', textDecoration: 'underline' }}
            >
              {' '}
              Cancel
            </Button>
          </ButtonSet>
        </ButtonDiv>
      </Content>
    </Form>
  );
};

export default OfferAcceptUnlock;
