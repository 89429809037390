import { mixpanelTrackEventFromBE } from '.';
export type MixpanelPushNotifClickedCampaign =
  | 'Viewed You'
  | 'Favorited You'
  | 'Sent You An Offer'
  | 'Accepted Your Offer'
  | 'Suggest You An Offer'
  | 'Sent You A Message'
  | 'Unlock Your Conversation'
  | 'Revised Offer';

export type Types = 'offers' | 'mail' | 'favorites';
export type Actions = 'receive' | 'update' | 'accept' | 'suggest' | 'unlock';

const getCampaignValue = (campaignKey: string): MixpanelPushNotifClickedCampaign | undefined => {
  let campaignValue: MixpanelPushNotifClickedCampaign | undefined;

  switch (campaignKey) {
    case 'offers-receive':
    case 'new_offer':
      campaignValue = 'Sent You An Offer';
      break;
    case 'offers-update':
      campaignValue = 'Revised Offer';
      break;
    case 'offers-accept':
    case 'offer_accepted':
      campaignValue = 'Accepted Your Offer';
      break;
    case 'offers-suggest':
    case 'suggest_offer':
      campaignValue = 'Suggest You An Offer';
      break;
    case 'favorites-receive':
    case 'favorited':
      campaignValue = 'Favorited You';
      break;
    case 'mail-receive':
    case 'message':
      campaignValue = 'Sent You A Message';
      break;
    case 'mail-unlock':
    case 'unlocked_message':
      campaignValue = 'Unlock Your Conversation';
      break;
  }

  return campaignValue;
};

export const mixpanelTrackPushNotifClicked = async (type: Types, action: Actions): Promise<void> => {
  const campaignKey = `${type}-${action}`;
  const campaign = getCampaignValue(campaignKey);

  if (!campaign) return;

  await mixpanelTrackEventFromBE({
    event_name: 'Push Notif Clicked',
    payload: {
      event_properties: {
        Campaign: campaign,
      },
    },
  });
};

export const mixpanelTrackPushNotifClickedAfterRedirection = async (location: any): Promise<void> => {
  const queryParams = new URLSearchParams(location.search);
  const utmMedium = queryParams.get('utm_medium');
  const utmCampaign = queryParams.get('utm_campaign');

  if (utmMedium === 'push_notification' && utmCampaign) {
    const campaign = getCampaignValue(utmCampaign);

    if (!campaign) return;

    await mixpanelTrackEventFromBE({
      event_name: 'Push Notif Clicked',
      payload: {
        event_properties: {
          Campaign: campaign,
        },
      },
    });
  }
};
