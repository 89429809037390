import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

import { borders } from '../../style';

const List = styled('ul')({
  margin: '0',
  padding: '0',
  listStyle: 'none',
});

const ListItem = styled('li')({
  margin: '0',
  padding: '0',
  textAlign: 'center',

  borderBottom: borders.DEFAULT,
  ':last-child': {
    marginTop: 10,
    borderBottomWidth: '0',
  },
});

const MenuList = (props: any): ReactElement => {
  return <List>{props.children}</List>;
};

const MenuListItem = (props: any): ReactElement => {
  return <ListItem>{props.children}</ListItem>;
};

export { MenuList, MenuListItem };
