import React, { ReactElement } from 'react';

const OfferCrossIcon = ({
  customStyle,
  variant,
  ...rest
}: {
  customStyle?: React.CSSProperties;
  variant: 'decline' | 'accept' | 'offer' | 'request' | 'counter';
}): ReactElement => {
  const renderSvg = () => {
    switch (variant) {
      case 'decline':
        return (
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={customStyle}
            {...rest}
          >
            <circle cx="15" cy="15.5" r="15" fill="#BC2028" />
            <path
              d="M7.50497 25L6 23.495L13.462 16L6 8.50497L7.50497 7L15 14.462L22.495 7L24 8.50497L16.538 16L24 23.495L22.495 25L15 17.538L7.50497 25Z"
              fill="white"
            />
          </svg>
        );
      case 'accept':
        return (
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={customStyle}
            {...rest}
          >
            <circle cx="15" cy="15.5" r="15" fill="#24A83A" />
            <path
              d="M12.062 19.5518L8.23953 15.7293L6.93787 17.0218L12.062 22.146L23.062 11.146L21.7695 9.85352L12.062 19.5518Z"
              fill="white"
            />
          </svg>
        );
      case 'offer':
      case 'request':
        return (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={customStyle}
            {...rest}
          >
            <circle cx="15.5" cy="15.5" r="15" fill="#24A83A" />
            <path
              d="M14.0918 25.9163V23.475C12.9585 23.267 12.0221 22.8176 11.2828 22.1269C10.5436 21.4362 10.0202 20.5327 9.71277 19.4165L11.9023 18.5936C12.1904 19.5395 12.6321 20.23 13.2276 20.6651C13.8227 21.1006 14.5331 21.3183 15.3591 21.3183C16.2039 21.3183 16.8856 21.129 17.4041 20.7502C17.9226 20.372 18.1819 19.8327 18.1819 19.1324C18.1819 18.4136 17.9323 17.8411 17.4332 17.4152C16.934 16.9897 15.9546 16.5498 14.4949 16.0957C13.0352 15.6607 11.9694 15.0646 11.2974 14.3076C10.6254 13.5506 10.2894 12.6235 10.2894 11.5263C10.2894 10.4664 10.6396 9.56747 11.3402 8.82952C12.0413 8.09157 12.9585 7.64698 14.0918 7.49576V5.08301H16.2233V7.49576C17.1451 7.64698 17.9326 7.98281 18.5857 8.50323C19.2383 9.02366 19.728 9.69076 20.0548 10.5046L17.9226 11.384C17.6733 10.7977 17.3228 10.3579 16.8711 10.0645C16.4199 9.77114 15.8486 9.62446 15.1572 9.62446C14.3506 9.62446 13.7266 9.78999 13.285 10.121C12.8435 10.4521 12.6227 10.9015 12.6227 11.4691C12.6227 12.0935 12.8771 12.5996 13.386 12.9875C13.8948 13.3757 14.927 13.8063 16.4825 14.279C17.827 14.6763 18.8352 15.2724 19.5072 16.0671C20.1792 16.8618 20.5152 17.8552 20.5152 19.0473C20.5152 20.3152 20.1361 21.3229 19.378 22.0704C18.6193 22.8179 17.5677 23.2956 16.2233 23.5036V25.9163H14.0918Z"
              fill="white"
            />
          </svg>
        );
      case 'counter':
        return (
          <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={customStyle}
            {...rest}
          >
            <circle cx="15" cy="15.5" r="15" fill="#BC2028" />
            <path d="M19 21.01V14H17V21.01H14L18 25L22 21.01H19ZM12 7L8 10.99H11V18H13V10.99H16L12 7Z" fill="white" />
          </svg>
        );
      default:
        return <></>;
    }
  };

  return renderSvg();
};

export default OfferCrossIcon;
