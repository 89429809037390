import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { dimensions } from '../../../../style';
import SecondaryButton from '../../../../atoms/buttons/SecondaryButton';
import { primaryBG, primaryBGDark } from '../../../../theme/colors';
import { getBrowserDetails, isDarkMode } from '../../../../utils/helpers';

const parentGradientWidth = getBrowserDetails().wImgModalCalibration + 9

const StyleGradient = styled('div')({
  [dimensions.SCREEN_MAX_XS]: {
    height: 100,
    display: 'block',
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 100%)`,
    position: 'relative',
    bottom: '15px',
    width: parentGradientWidth,
    borderRadius: '8px',
  },
});

const StyledContainer = styled.div(() => ({
  textAlign: 'center',
  padding: '23px 0',
  display: 'none',

  [dimensions.SCREEN_MAX_XS]: {
    display: 'block',
    backgroundColor: isDarkMode() ? primaryBGDark : primaryBG,
    borderRadius: '8px',
    position: 'absolute',
    bottom: '-5px',
    width: parentGradientWidth,
  },
}));

interface IBulkUploadActionButtonContainerProps {
  children: ReactNode;
  customStyle?: React.CSSProperties;
  onCancel: () => void;
}

const GalleryUploadMobileActionButtonContainer: React.FC<IBulkUploadActionButtonContainerProps> = (
  props: IBulkUploadActionButtonContainerProps
) => {
  const { children, onCancel, customStyle } = props;
  return (
    <StyleGradient>
      <StyledContainer style={{ ...customStyle }}>
        <div style={{
          margin: '0 auto'
        }}>
        <SecondaryButton
          size="small"
          color="gray"
          label="Cancel"
          onClick={onCancel}
          customStyle={{
            marginRight: 15,
            width: 154,
          }}
        />
        {children}
        </div>
      </StyledContainer>
    </StyleGradient>
  );
};

export default GalleryUploadMobileActionButtonContainer;
