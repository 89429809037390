import React from 'react';
import { GPAY_PAYMENT_CHANNEL, googlePay } from '../../../config/gpay';
import useMixPanel from '../../../hooks/useMixPanel';
import { getPackageSubTotal } from '../../../utils/billing';
import { PaymentClickVysionEventsEnum } from '../../../utils/vysion/payment/click';
import { handlePaymentVysionEvent } from '../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { EVENT_NAMES } from '../../../constants/mixpanel';
import GooglePayButton from '@google-pay/button-react';
import BillingTermsAndCondition from '../../../modules/billing/BillingTermsAndCondition';
import BillingPaymentProcessor from '../BillingPaymentProcessor';
import AfterPayment from '../../../modules/billing/AfterPayment';
import styled from '@emotion/styled';
import omit from 'lodash/omit';
import './GooglePayStyle.css';
import { isDarkMode } from '../../../utils/helpers';

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyItems: 'center',
});

interface IGooglePayForm {
  paymentChannel: string | null | undefined;
  use2PayData: any;
  handleSubmit: any;
  discount: any;
  userCountry: string;
  hidePackageForm: any;
  descriptors: any;
}

const GooglePayForm: React.FC<IGooglePayForm> = ({
  use2PayData,
  discount,
  userCountry,
  handleSubmit,
  hidePackageForm,
  descriptors
}): React.ReactElement => {
  const { setMixpanelDataByKeyHandler } = useMixPanel();

  const paymentRequest = {
    apiVersion: googlePay.apiVersion,
    apiVersionMinor: googlePay.apiVersionMinor,
    allowedPaymentMethods: [
      {
        type: googlePay.allowedMethodType,
        parameters: {
          allowedAuthMethods: googlePay.allowedAuthMethods,
          allowedCardNetworks: googlePay.allowedCardNetworks,
        },
        tokenizationSpecification: googlePay.tokenizationSpecification,
      },
    ],
    merchantInfo: googlePay.merchantInfo,
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: getPackageSubTotal(use2PayData?.selectedPackage, discount).toString(),
      currencyCode: use2PayData?.selectedPackage?.currency?.data.code || 'USD',
      countryCode: use2PayData?.use2pay_billing_country || 'US',
    },
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
  };

  return (
    <Container>
      <GooglePayButton
        environment={googlePay.isLive ? 'PRODUCTION' : 'TEST'}
        buttonType={'pay'}
        buttonColor={isDarkMode() ? 'white' : 'default'}
        buttonSizeMode={'fill'}
        paymentRequest={paymentRequest}
        onLoadPaymentData={paymentRequest => {
          use2PayData.googlepay_token = paymentRequest.paymentMethodData.tokenizationData.token;
          use2PayData.use2pay_payment_channel = GPAY_PAYMENT_CHANNEL;
          use2PayData.googlepay_card_type = paymentRequest.paymentMethodData?.info?.cardNetwork;

          if (use2PayData.use2pay_billing_state === null || use2PayData.use2pay_billing_state.length <= 0) {
            use2PayData = omit(use2PayData, ['use2pay_billing_state']);
          }

          if (use2PayData.googlepay_token) {
            hidePackageForm();
            handleSubmit(
              omit(use2PayData, [
                'new_card',
                'use2pay_billing_address',
                'use2pay_billing_city',
                'use2pay_billing_name_on_card',
                'use2pay_billing_zip',
                'use2pay_card_cvv',
                'use2pay_card_exp_month',
                'use2pay_card_exp_year',
                'use2pay_card_number',
              ])
            );

            setMixpanelDataByKeyHandler({
              key: EVENT_NAMES.CREDITS_PURCHASED,
              payload: {
                Amount: use2PayData?.selectedPackage.credits,
                Total: getPackageSubTotal(use2PayData?.selectedPackage, discount).toString(),
                AutoTopup: use2PayData.recurring,
                PaymentMethod: 'Google Pay',
              },
            });
          }
        }}
        onClick={() => {
          handlePaymentVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: PaymentClickVysionEventsEnum.CLICK_GOOGLE_PAY,
          });
        }}
        onError={error => {
          if (error instanceof Error) {
            console.error('Error', error, error.message, error.stack);
          } else {
            console.error('Error', error.statusCode, error.statusMessage);
          }
        }}
        onPaymentAuthorized={() => ({
          transactionState: 'SUCCESS',
        })}
      />
      <BillingTermsAndCondition userCountry={userCountry} />
      <BillingPaymentProcessor userCountry={userCountry} />
      <AfterPayment userCountry={userCountry} descriptors={descriptors} />
    </Container>
  );
};

export default GooglePayForm;
