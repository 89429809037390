import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { VisaIcon, DiscoverIcon, MasterCardIcon, AmexIcon } from '../icons/Billing';
import { dimensions } from '../../style';
import GooglePayIcon from './GooglePay/GooglePayIcon';
import ApplePayIcon from './GooglePay/ApplePayIcon';
import { isDarkMode } from '../../utils/helpers';

const ImagesPanel = styled('div')({
  marginLeft: 'auto',
  [dimensions.SCREEN_MAX_MD_2]: {
    marginLeft: 'unset',
    marginTop: 10,
  },
});

const PaymentImageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  '& > * + *': {
    marginLeft: '10px',
  },
});

const CreditCardPanel = ({ rocketgateGooglepay, isApplePayEligible }: any): ReactElement => {
  return (
    <ImagesPanel>
      <PaymentImageContainer>
        <VisaIcon />
        <MasterCardIcon />
        <AmexIcon />
        <DiscoverIcon />
        {isApplePayEligible && <ApplePayIcon height={28} customStyle={{ marginLeft: 0 }} darkmode={isDarkMode()} />}
        {rocketgateGooglepay && (
          <GooglePayIcon
            height={28}
            customStyle={{ marginLeft: isApplePayEligible ? '-8px' : 0 }}
            darkmode={isDarkMode()}
          />
        )}
      </PaymentImageContainer>
      {/*<CreditCardText> Pay with credit card</CreditCardText>*/}
    </ImagesPanel>
  );
};

export default CreditCardPanel;
