import React, { ReactElement } from 'react';
interface IEditPhoneIconProps {
  customStyle?: any;
  isDisabled?: boolean;
}

const EditPhoneIcon = ({ customStyle, isDisabled = true, ...rest }: IEditPhoneIconProps): ReactElement => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <path
        d="M2.83333 24.6499C2.19167 24.6499 1.64236 24.4215 1.18542 23.9645C0.728472 23.5076 0.5 22.9583 0.5 22.3166V5.98327C0.5 5.3416 0.728472 4.7923 1.18542 4.33535C1.64236 3.87841 2.19167 3.64993 2.83333 3.64993H13.2458L10.9125 5.98327H2.83333V22.3166H19.1667V14.2083L21.5 11.8749V22.3166C21.5 22.9583 21.2715 23.5076 20.8146 23.9645C20.3576 24.4215 19.8083 24.6499 19.1667 24.6499H2.83333ZM15.8708 4.32077L17.5333 5.9541L9.83333 13.6541V15.3166H11.4667L19.1958 7.58743L20.8583 9.22077L13.1292 16.9499C12.9153 17.1638 12.6674 17.334 12.3854 17.4604C12.1035 17.5867 11.8069 17.6499 11.4958 17.6499H8.66667C8.33611 17.6499 8.05903 17.5381 7.83542 17.3145C7.61181 17.0909 7.5 16.8138 7.5 16.4833V13.6541C7.5 13.343 7.55833 13.0465 7.675 12.7645C7.79167 12.4826 7.95694 12.2347 8.17083 12.0208L15.8708 4.32077ZM20.8583 9.22077L15.8708 4.32077L18.7875 1.4041C19.2542 0.937435 19.8132 0.704102 20.4646 0.704102C21.116 0.704102 21.6653 0.937435 22.1125 1.4041L23.7458 3.0666C24.1931 3.51382 24.4167 4.05827 24.4167 4.69993C24.4167 5.3416 24.1931 5.88605 23.7458 6.33327L20.8583 9.22077Z"
        fill={isDisabled ? '#DEDEDE' : '#2B8FD7'}
      />
    </svg>
  );
};

export default EditPhoneIcon;
