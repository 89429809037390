import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import { useApp } from '../../contexts/AppContext';
import { connect } from 'react-redux';
import { AccountMaker } from '../../models/Account';
import { showPWAInstallationMessagePageBanner, trackPWaMixpanelEventInstalled } from '../../utils/pwa';
import PWAMessagePageBannerContent from './PWAMessagePageBannerContent';
import borders from '../../theme/borders';
import { setLocalItem } from '../../common';
import { PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY } from '../../utils/pwa';
import { format } from 'date-fns';
import { mixpanelTrackPWAInstallBannerShown } from '../../utils/mixpanel/pwaInstallBannerShown';

const PWASidebarBannerContainer = styled('div')({
  borderBottom: `1px solid ${borders.light.primary}`,
});

interface Props {
  account: any; // fix type
  customStyle?: React.CSSProperties;
  onClose?: () => void;
}
const PWAMessagePageBanner: React.FC<Props> = ({ account, customStyle, onClose }) => {
  const { setShowIntallationBanner } = useApp();
  const [show, setShow] = useState(false);
  const [platform, setPlatform] = useState('');
  const accountLocale = useMemo(() => account?.profile?.data?.created_at?.timezone ?? null, [account]);

  useEffect(() => {
    (async () => {
      const showBanner = await showPWAInstallationMessagePageBanner(AccountMaker.create(account));
      if (showBanner.show) {
        mixpanelTrackPWAInstallBannerShown();
      }

      setShow(showBanner.show);
      setPlatform(showBanner.platform);
    })();
  }, [account.hash_id]);

  if (!show) {
    return null;
  }

  const onCloseHandler = () => {
    setLocalItem(
      PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
      format(new Date(), 'YYYY-MM-DD', { locale: accountLocale })
    );

    onClose?.();
    setShow(false);
  };
  return (
    <PWASidebarBannerContainer id="pwa-message-page-install-prompt" style={customStyle}>
      {platform === 'android' && (
        <PWAInstallerPrompt
          render={({ onClick }) => <PWAMessagePageBannerContent onClick={onClick} onClose={onCloseHandler} />}
        />
      )}
      {platform === 'ios' && (
        <PWAMessagePageBannerContent
          onClose={onCloseHandler}
          onClick={() => {
            trackPWaMixpanelEventInstalled('Message');
            setShow(false);
            setShowIntallationBanner(true);
          }}
        />
      )}
    </PWASidebarBannerContainer>
  );
};

const mapStateToProps = state => {
  return {
    account: state.profile,
  };
};

export default connect(mapStateToProps, {})(PWAMessagePageBanner);
