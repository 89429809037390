import React from 'react';
import RCTooltip from 'rc-tooltip';
import styled from '@emotion/styled';
import { IsMobileViewPort } from '../../utils/helpers';
import 'rc-tooltip/assets/bootstrap_white.css';
import '../../style/customRCTooltip.css';

export type Trigger = 'hover' | 'click' | 'focus';
export type Placement = 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

export interface RCTooltipProps {
  children?: React.ReactNode;
  ref?: React.LegacyRef<any> | undefined;
  overlayClassName?: string | undefined;
  trigger: Trigger;
  mouseEnterDelay?: number | undefined;
  mouseLeaveDelay?: number | undefined;
  overlayStyle?: React.CSSProperties | undefined;
  prefixCls?: string | undefined;
  transitionName?: string | undefined;
  onVisibleChange?: ((visible?: boolean) => void) | undefined;
  afterVisibleChange?: ((visible?: boolean) => void) | undefined;
  visible?: boolean | undefined;
  defaultVisible?: boolean | undefined;
  placement?: Placement | undefined;
  align?: string | undefined;
  onPopupAlign?: ((popupDomNode: Element, align: string) => void) | undefined;
  overlay?: (() => React.ReactChild) | React.ReactChild | React.ReactFragment | React.ReactPortal;
  arrowContent?: React.ReactNode | undefined;
  getTooltipContainer?: (() => Element) | undefined;
  destroyTooltipOnHide?: boolean | undefined;
  id?: string | undefined;
}

interface TooltipProps extends RCTooltipProps {
  variant: 'primary' | 'default';
  htmlElementLabel: React.ReactNode | string;
}

const TooltipBody = styled('span')`
  background-color: #2b8fd7;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

const Tooltip = ({ variant, htmlElementLabel, children, ...rest }: TooltipProps): any => {
  return !IsMobileViewPort ? (
    <RCTooltip
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
      overlay={<TooltipBody dangerouslySetInnerHTML={{ __html: htmlElementLabel }} />}
      overlayClassName={`custom-rc-tooltip-${variant}`}
      {...rest}
    >
      {children}
    </RCTooltip>
  ) : (
    children
  );
};

export default Tooltip;
