import { vysionService, vysionTrack } from '../utils/vysion/vysionService';
import { convertTitleCase } from '../utils/helpers';
import { getLocalItem } from '../common';
import { SITE_ID } from '../config/Master';

const setGlobalAction = (eventType: any, currUrl: any): any => {
  const fullUrl = currUrl ? currUrl.split(',') : '';
  // determine when its length is 5, take the 4 value of the url
  const currPage = fullUrl.length === 5 ? fullUrl[fullUrl.length - 2] : fullUrl[fullUrl.length - 1];
  return {
    type: 'GLOBAL',
    payload: {
      page: currPage,
      event: eventType,
      eventData: {
        action: eventType.toLowerCase(),
        page: '',
        extraInfo: convertTitleCase(eventType),
        component: '',
      },
      userData: '',
      deviceData: '',
      extendedData: '',
    },
  };
};

const sendGlobalAbandonAction = (event: any, currPage: any): any => {
  vysionService(setGlobalAction('ABANDON', currPage));
};

const sendGlobalBackAction = (event, currPage) => {
  vysionService(setGlobalAction('BACK', currPage));
};

const abandonAction = (): any => {
  window.addEventListener('beforeunload', e => sendGlobalAbandonAction(e, getLocalItem('prevPage')));
};

const backAction = (): any => {
  window.addEventListener('popstate', e => sendGlobalBackAction(e, getLocalItem('prevPage')));
};

const pageLoadAction = (currLocation: any, pageNotFound: any): any => {
  if (pageNotFound) return;
  const currentPage = currLocation.pathname.substr(1);
  const currPageSplit = currLocation.pathname.substr(1).split('/');

  const createOnloadEventName = () => {
    const eventName = currentPage.replace('/', '-');
    // set of pages with custom eventName and have hashID additional url
    const customEventName = ['profile', 'report', 'account-summary'];

    if (customEventName.filter(name => currentPage.indexOf(name) > -1).length > 0) return currPageSplit[0];

    if (currPageSplit[0] === 'mail') return 'messages';

    switch (eventName) {
      case 'favorites-my':
        return 'favorites-yourFavorites';
      case 'offers-new':
        return 'offers-receivedOffers';
      case 'offers-sent':
        return 'offers-sentOffers';
      case 'favorites':
        return 'favorites-favoritedYou';
      default:
        return eventName;
    }
  };

  vysionTrack(
    {
      action: 'view',
      component: '',
      extraInfo: '',
      page: convertTitleCase(currPageSplit[0]),
      url: window.location.href,
      site: SITE_ID.toUpperCase(),
      view: `/${currPageSplit[0]}`,
    },
    createOnloadEventName()
  );
};

const storeEvidently = (payload: any): any => {
  return {
    type: 'SETTINGS_EVIDENTLY',
    payload,
  };
};

export { abandonAction, backAction, pageLoadAction, storeEvidently };
