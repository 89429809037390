import React, { ReactElement } from 'react';

interface IStepNumberIconProps {
  customStyle?: any;
  number?: number;
}

const StepNumberIcon = ({ customStyle, number }: IStepNumberIconProps): ReactElement => {
  if (number === 2) {
    return (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={customStyle}
      >
        <rect y="0.822021" width="20" height="20" rx="10" fill="white" />
        <path
          d="M6.77557 15.322V14.18L9.8054 11.2098C10.0952 10.9172 10.3366 10.6572 10.5298 10.43C10.723 10.2027 10.8679 9.98253 10.9645 9.76946C11.0611 9.5564 11.1094 9.32912 11.1094 9.08765C11.1094 8.81208 11.0469 8.57628 10.9219 8.38026C10.7969 8.1814 10.625 8.02799 10.4062 7.92003C10.1875 7.81208 9.93892 7.7581 9.66051 7.7581C9.37358 7.7581 9.12216 7.81776 8.90625 7.93708C8.69034 8.05356 8.52273 8.21975 8.40341 8.43566C8.28693 8.65157 8.22869 8.90867 8.22869 9.20696H6.72443C6.72443 8.65299 6.85085 8.17145 7.10369 7.76236C7.35653 7.35327 7.70455 7.03651 8.14773 6.81208C8.59375 6.58765 9.10511 6.47543 9.68182 6.47543C10.267 6.47543 10.7813 6.58481 11.2244 6.80356C11.6676 7.02231 12.0114 7.32202 12.2557 7.7027C12.5028 8.08338 12.6264 8.51804 12.6264 9.00668C12.6264 9.33338 12.5639 9.65441 12.4389 9.96975C12.3139 10.2851 12.0938 10.6345 11.7784 11.018C11.4659 11.4016 11.027 11.8661 10.4616 12.4115L8.95739 13.9413V14.001H12.7585V15.322H6.77557Z"
          fill="black"
        />
      </svg>
    );
  }

  if (number === 1) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={customStyle}
      >
        <rect width="20" height="20" rx="10" fill="white" />
        <path
          d="M11.3253 5.77273V14.5H9.74432V7.31108H9.69318L7.65199 8.61506V7.16619L9.82102 5.77273H11.3253Z"
          fill="black"
        />
      </svg>
    );
  }

  return <div></div>;
};

export default StepNumberIcon;
