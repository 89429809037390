import React, { ReactElement, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Description, LinkAction } from './phoneSettings/styledElements';
import CountDown from 'react-countdown';
import usePhoneActionModal from '../../hooks/usePhoneActionModal';
import { useApp } from '../../contexts/AppContext';
import { EVENT_NAMES, trackMixpanelAnonymousEvents, trackMixpanelEvents } from '../../constants/mixpanel';
import { handleLoginVysionEvent } from '../../utils/vysion/login';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { LoginClickVysionEventsEnum } from '../../utils/vysion/login/click';
import { handleJoinVysionEvent } from '../../utils/vysion/join';
import { JoinClickVysionEventsEnum } from '../../utils/vysion/join/click';
import { JoinViewVysionEventsEnum } from '../../utils/vysion/join/view';
import { IsMobileViewPort } from '../../utils/helpers';
import { handlePhoneSettingsVysionEvent } from '../../utils/vysion/phoneSettings';
import { PhoneSettingsClickVysionEventsEnum } from '../../utils/vysion/phoneSettings/click';

const CountdownContainer = styled('div')({
  fontSize: '56px',
  fontWeight: 400,
  textAlign: 'center',
});

interface IRequestOtpLinkActionProps {
  currentPhone: string;
  isLogin?: boolean;
  isSignUp?: boolean;
  hashId?: string;
  hasUpdatedPhone?: boolean;
}

const RequestOtpLinkAction = ({
  currentPhone,
  isLogin = false,
  isSignUp = false,
  hasUpdatedPhone = false,
  hashId,
}: IRequestOtpLinkActionProps): ReactElement => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [allowRequest, setAllowRequest] = useState(false);
  const { otpResendThreshold, showOtpCountdown, isAccountIsMixpanelEligableEnabled } = useApp();
  const resetTimer = useMemo(() => Date.now() + 28000, [otpResendThreshold]);
  const {
    utils: { requestResendOtp },
  } = usePhoneActionModal();

  const handleRequestOtp = async () => {
    setIsProcessing(true);

    if (isLogin) {
      handleLoginVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: LoginClickVysionEventsEnum.CLICK_LOGIN_ENTER_VERIFICATION_REQUEST_AGAIN,
      });
    }

    if (isSignUp) {
      handleJoinVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_VERIFICATION_CODE_REQUEST_AGAIN,
      });
    }

    if (!isLogin && !isSignUp) {
      if (hasUpdatedPhone) {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_UPDATED_NUMBER_VERIFICATION_REQUEST_AGAIN,
        });
      } else {
        handlePhoneSettingsVysionEvent({
          action: VYSION_ACTIONS.CLICK,
          event: PhoneSettingsClickVysionEventsEnum.CLICK_CURRENT_NUMBER_VERIFICATION_REQUEST_AGAIN,
        });
      }
    }

    requestResendOtp({
      isLogin,
      isSignUp,
      phoneNumber: currentPhone,
      onResendSuccess: () => {
        setAllowRequest(false);

        if (hashId) {
          if (isAccountIsMixpanelEligableEnabled) {
            trackMixpanelEvents(hashId, EVENT_NAMES.SMS_CODE_REQUESTED);
          }
        } else {
          trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_REQUESTED);
        }

        if (isSignUp) {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.VIEW,
            event: JoinViewVysionEventsEnum.VIEW_VERIFICATON_MODAL,
          });
        }
      },
      onResendCompleted: () => setIsProcessing(false),
    });
  };

  return (
    <Description>
      {showOtpCountdown && (
        <CountdownContainer>
          <div style={{ display: 'none' }}>
            <CountDown date={resetTimer} onComplete={() => setAllowRequest(true)} key={otpResendThreshold} />
          </div>
        </CountdownContainer>
      )}
      <span style={{ fontSize: IsMobileViewPort ? 14 : 16 }}>Didn’t receive the code?</span>
      {isProcessing ? (
        <LinkAction style={{ cursor: 'unset', textDecoration: 'none', fontSize: IsMobileViewPort ? 14 : 16 }}>
          Requesting...
        </LinkAction>
      ) : (
        <LinkAction
          onClick={allowRequest || !showOtpCountdown ? handleRequestOtp : () => null}
          style={
            allowRequest || !showOtpCountdown
              ? { color: '#2B8FD7', fontSize: IsMobileViewPort ? 14 : 16 }
              : { color: '#cbcbcb', cursor: 'unset', fontSize: IsMobileViewPort ? 14 : 16 }
          }
        >
          {isProcessing ? 'Requesting....' : 'Request Again'}
        </LinkAction>
      )}
    </Description>
  );
};

export default RequestOtpLinkAction;
