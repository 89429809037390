import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Account Menu',
};

export const enum DeactivateAccountClickVysionEventsEnum {
  ACCOUNT_MENU_ACCOUNT_SETTINGS_DEACTIVATE_OR_DELETE_MY_ACCOUNT = 'accountMenu-accountSettings-deactivateOrDeleteMyAccount',
  ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DEACTIVATE_MY_ACCOUNT = 'accountMenu-deactivateAccount-deactivateMyAccount',
  ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DELETE_MY_ACCOUNT = 'accountMenu-deactivateAccount-deleteMyAccount',
  GDPR_DELETE_DEACTIVATING = 'gdprDelete-deactivating',
  GDPR_DELETE_DEACTIVATE_INSTEAD = 'gdprDelete-deactivateInstead',
}

export const deactivateAccountClickVysionEvents = {
  [DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_ACCOUNT_SETTINGS_DEACTIVATE_OR_DELETE_MY_ACCOUNT]: {
    ...commonValues,
    eventName: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_ACCOUNT_SETTINGS_DEACTIVATE_OR_DELETE_MY_ACCOUNT,
    extraInfo: 'Deactivate or Delete My Account',
    component: 'Account Settings',
  },
  [DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DEACTIVATE_MY_ACCOUNT]: {
    ...commonValues,
    eventName: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DEACTIVATE_MY_ACCOUNT,
    extraInfo: 'Deactivate My Account',
    component: 'Deactivate Account Screen',
  },
  [DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DELETE_MY_ACCOUNT]: {
    ...commonValues,
    eventName: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DELETE_MY_ACCOUNT,
    extraInfo: 'Delete My Account',
    component: 'Deactivate Account Screen',
  },
  [DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATING]: {
    ...commonValues,
    eventName: DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATING,
    page: 'Delete',
    extraInfo: '"deactivating" link',
    component: 'Delete Account Page',
  },
  [DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATE_INSTEAD]: {
    ...commonValues,
    eventName: DeactivateAccountClickVysionEventsEnum.GDPR_DELETE_DEACTIVATE_INSTEAD,
    page: 'Delete',
    extraInfo: 'Deactivate Instead',
    component: 'Delete Account Page',
  },
};
