import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
  page: 'Login',
};

export const enum LoginOnFocusVysionEventsEnum {
  PHONE_NUMBER = 'loginPhoneNumber',
  VERIFICATION_CODE = 'loginEnterVerificationEnterCode',
}

export const loginOnFocusVysionEvents = {
  [LoginOnFocusVysionEventsEnum.PHONE_NUMBER]: {
    ...commonValues,
    component: 'Enter Phone Number',
    eventName: 'login-phoneNumber',
  },
  [LoginOnFocusVysionEventsEnum.VERIFICATION_CODE]: {
    ...commonValues,
    component: 'Verification Code Entry',
    eventName: 'login-enterVerification-enterCode',
    extraInfo: 'Verification Code Entry',
  },
};
