import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
  page: 'Account Menu',
};

export const enum PhoneSettingsOnFocusVysionEventsEnum {
  ENTER_PASSWORD = 'verifyEnterPassword',
  ENTER_CHANGE_PHONE_NUMBER = 'enterChangePhoneNumber',
  UPDATE_ENTER_NUMBER_VERIFICATION_CODE = 'updateEnterNumberVerificationCode',
  CURRENT_NUMBER_VERIFICATION_ENTER_CODE = 'currentNumberVerificationEnterCode',
}

export const phoneSettingsOnFocusVysionEvents = {
  [PhoneSettingsOnFocusVysionEventsEnum.ENTER_PASSWORD]: {
    ...commonValues,
    eventName: 'accountMenu-verifyAccount-enterPassword',
    component: 'Enter Password',
  },
  [PhoneSettingsOnFocusVysionEventsEnum.ENTER_CHANGE_PHONE_NUMBER]: {
    ...commonValues,
    eventName: 'accountMenu-changePhoneNumber-phoneNumber',
    component: 'Change Phone Number',
    extraInfo: 'Phone Number Entry',
  },
  [PhoneSettingsOnFocusVysionEventsEnum.UPDATE_ENTER_NUMBER_VERIFICATION_CODE]: {
    ...commonValues,
    eventName: 'accountMenu-updatedNumberVerification-enterCode',
    component: 'Updated Number Verification Code',
    extraInfo: 'Verification Code Entry',
  },
  [PhoneSettingsOnFocusVysionEventsEnum.CURRENT_NUMBER_VERIFICATION_ENTER_CODE]: {
    ...commonValues,
    eventName: 'accountMenu-currentNumberVerification-enterCode',
    component: 'Current Number Verification Code',
    extraInfo: 'Verification Code Entry',
  },
};
