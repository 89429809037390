/**
 * @Author: zachalam
 * @Date:   2017-01-19T14:10:57-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T15:50:24-08:00
 */

import React, { useEffect, ReactElement } from 'react';
import NotFound from '../components/pages/NotFound';
import { vysionTrack } from '../utils/vysion/vysionService';

const NotFoundContainer = (): ReactElement => {
  useEffect(() => {
    setTimeout(() => {
      vysionTrack(
        {
          action: 'view',
          component: '',
          extraInfo: '',
          page: 'Page Not Found',
        },
        'pageNotFound'
      );
    }, 1000);
  }, []);

  return <NotFound />;
};

export default NotFoundContainer;
