import React from 'react';
import GalleryMenu from '../../../../atoms/GalleryMenu';
import { getBrowserDetails } from '../../../../utils/helpers';
import MediaPlayIcon from '../../../../atoms/icons/MediaPlayIcon';
import { Photo, PhotoStatusTitle, PhotoStatus, PrivateTotal, VideoInProgress, MediaPlayWrap } from './GalleryStyles';
import VideoGalleryMenu from '../../../../atoms/VideoGalleryMenu';
import VideoGear from '../../../../icons/VIdeoGear';

interface PrivateGalleryProps {
  url: any;
  status: any;
  isProfile: any;
  openLightbox: any;
  index: any;
  photoKey: any;
  setPrivacy: any;
  makeDefault: any;
  deletePhoto: any;
  hasPublicPhotos: any;
  isPrivate: any;
  singlePublicLeft: any;
  photoId: any;
  isAvatar: any;
  hasPublicApprovedPhotos: any;
  mediaType: any;
  deleteMedia: any;
  setMediaPrivacy: any;
  hasBulkSelection: any;
}

const PrivateGallery = ({
  url,
  status,
  isProfile,
  openLightbox,
  index,
  photoKey,
  setPrivacy,
  makeDefault,
  deletePhoto,
  hasPublicPhotos,
  isPrivate,
  singlePublicLeft,
  photoId,
  isAvatar,
  hasPublicApprovedPhotos,
  mediaType,
  setMediaPrivacy,
  deleteMedia,
  hasBulkSelection,
}: PrivateGalleryProps): any => {
  const isPending = status === 'new';
  const isCenterAlign = !isProfile && !isPending;
  const isVideo = mediaType === 'video';

  return (
    <PrivateTotal>
      {isVideo && status === 'pending' ? (
        <VideoInProgress>
          <VideoGear />
          <div style={{ margin: '10px auto', width: '80%' }}>Video Processing...</div>
        </VideoInProgress>
      ) : (
        <Photo
          data-test-id="private-img-monkey"
          imgUrl={url}
          onClick={e => {
            e.preventDefault();
            openLightbox(index, e);
          }}
        />
      )}

      {isVideo && status !== 'pending' && (
        <MediaPlayWrap>
          <MediaPlayIcon
            onClick={e => {
              e.preventDefault();
              openLightbox(index, e);
            }}
          />
        </MediaPlayWrap>
      )}

      <PhotoStatus isCenterAlign={isCenterAlign}>
        {isProfile && isAvatar && isPending && (
          <PhotoStatusTitle
            mobilePending={getBrowserDetails().isMobile && isProfile && isPending}
            isPending={isProfile && isPending}
          >
            Pending {mediaType}
          </PhotoStatusTitle>
        )}

        {isVideo ? (
          <VideoGalleryMenu
            key={photoKey}
            theKey={photoKey}
            setPrivacy={setMediaPrivacy}
            deleteMedia={deleteMedia}
            hasPublicPhotos={hasPublicPhotos}
            isPrivate={isPrivate}
            singlePublicLeft={singlePublicLeft}
            mediaId={photoId}
            isPending={isPending}
            hasPublicApprovedPhotos={hasPublicApprovedPhotos}
            isCenterAlign={isCenterAlign}
            hasBulkSelection={hasBulkSelection}
          />
        ) : (
          <GalleryMenu
            key={photoKey}
            theKey={photoKey}
            setPrivacy={setPrivacy}
            makeDefault={makeDefault}
            deletePhoto={deletePhoto}
            hasPublicPhotos={hasPublicPhotos}
            isPrivate={isPrivate}
            singlePublicLeft={singlePublicLeft}
            photoId={photoId}
            isAvatar={isAvatar}
            isPending={isPending}
            hasPublicApprovedPhotos={hasPublicApprovedPhotos}
            isCenterAlign={isCenterAlign}
            hasBulkSelection={hasBulkSelection}
          />
        )}
        {isProfile && isAvatar && <PhotoStatusTitle>Profile</PhotoStatusTitle>}
        {!(isProfile && isAvatar) && isPending && <PhotoStatusTitle>Pending</PhotoStatusTitle>}
      </PhotoStatus>
    </PrivateTotal>
  );
};

export default PrivateGallery;
