import React, { ReactElement } from 'react';
interface ExistingPhotoProps {
  customStyle?: any;
}

const ExistingPhoto = ({ customStyle }: ExistingPhotoProps): ReactElement => {
  return (
    <svg
      width="42"
      height="37"
      style={{ ...customStyle }}
      data-test-id="existing-photo-icon"
      viewBox="0 0 42 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_852_5276)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.63 36.03C38.05 36.03 39.42 35.47 40.43 34.46C41.44 33.45 42 32.09 42 30.67V11.2C42 8.24 39.59 5.84 36.63 5.84H31.98C31.79 5.84 31.63 5.73 31.54 5.57L29.69 1.88C29.11 0.73 27.93 0 26.63 0H15.37C14.07 0 12.89 0.73 12.31 1.88L10.46 5.57C10.38 5.73 10.21 5.84 10.02 5.84H5.37C2.41 5.84 0 8.24 0 11.2V30.67C0 32.09 0.57 33.45 1.57 34.46C2.58 35.46 3.94 36.03 5.37 36.03H36.63ZM21 10.71C15.88 10.71 11.72 14.85 11.72 19.96C11.72 25.07 15.88 29.21 21 29.21C26.12 29.21 30.28 25.07 30.28 19.96C30.28 14.85 26.12 10.71 21 10.71ZM21 13.63C24.5 13.63 27.35 16.47 27.35 19.96C27.35 23.45 24.51 26.29 21 26.29C17.49 26.29 14.65 23.45 14.65 19.96C14.65 16.47 17.49 13.63 21 13.63Z"
          fill="#2B8FD7"
        />
      </g>
      <defs>
        <clipPath id="clip0_852_5276">
          <rect width="42" height="36.03" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExistingPhoto;
