import React, { ReactElement } from 'react';

const OfferUnlockIcon = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06108 18.5587C3.36144 18.8529 3.72702 19 4.1578 19H15.8421C16.2729 19 16.6385 18.8529 16.9389 18.5587C17.2389 18.2648 17.3889 17.9067 17.3889 17.4843V7.93929C17.3889 7.51694 17.2389 7.15866 16.9389 6.86443C16.6385 6.57056 16.2729 6.42363 15.8421 6.42363H14.4567V4.36511C14.4567 3.15889 14.0218 2.12981 13.1522 1.27789C12.2821 0.425962 11.2314 0 9.99997 0C8.89161 0 7.92955 0.339286 7.1138 1.01786C6.29768 1.69643 5.79725 2.55595 5.61253 3.59643C5.56634 3.79222 5.61641 3.96937 5.76271 4.12789C5.90901 4.28604 6.10538 4.36511 6.3518 4.36511C6.53653 4.36511 6.70186 4.30106 6.84779 4.17294C6.9941 4.04483 7.0981 3.87528 7.1598 3.66429C7.31386 3.03095 7.65246 2.51451 8.17561 2.11497C8.69913 1.71507 9.30725 1.51511 9.99997 1.51511C10.8157 1.51511 11.5085 1.79414 12.0782 2.3522C12.6478 2.91026 12.9327 3.58123 12.9327 4.36511V6.42363H4.1578C3.72702 6.42363 3.36144 6.57056 3.06108 6.86443C2.76108 7.15866 2.61108 7.51694 2.61108 7.93929V17.4843C2.61108 17.9067 2.76108 18.2648 3.06108 18.5587ZM9.99997 15.4815C11.3602 15.4815 12.4629 14.3788 12.4629 13.0185C12.4629 11.6583 11.3602 10.5556 9.99997 10.5556C8.63972 10.5556 7.53701 11.6583 7.53701 13.0185C7.53701 14.3788 8.63972 15.4815 9.99997 15.4815Z"
        fill="white"
      />
    </svg>
  );
};

export default OfferUnlockIcon;
