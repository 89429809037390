import React, { ReactElement } from 'react';
interface ICheckCircleIconProps {
  customStyle?: any;
}

const CheckCircleIcon = ({ customStyle }: ICheckCircleIconProps): ReactElement => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={customStyle}>
      <path
        d="M15.0283 20.3395L11.6141 16.9252C11.2723 16.5834 10.8494 16.4125 10.3454 16.4125C9.84144 16.4125 9.40523 16.5927 9.03679 16.953C8.69498 17.3052 8.5254 17.7328 8.52804 18.2356C8.53065 18.7384 8.70808 19.1607 9.06033 19.5025L13.7185 24.1885C14.092 24.5552 14.5276 24.7386 15.0255 24.7386C15.5234 24.7386 15.9609 24.5552 16.3381 24.1885L25.8097 14.7012C26.1805 14.3409 26.3659 13.9127 26.3659 13.4165C26.3659 12.9204 26.1805 12.4881 25.8097 12.1197C25.4494 11.7779 25.0127 11.6116 24.4994 11.6209C23.9862 11.6301 23.5546 11.8057 23.2047 12.1475L15.0283 20.3395ZM17.5021 34.8208C15.109 34.8208 12.8593 34.3682 10.7532 33.4631C8.64716 32.558 6.80995 31.3213 5.24162 29.753C3.67329 28.1847 2.43658 26.3482 1.5315 24.2435C0.626384 22.1389 0.173828 19.89 0.173828 17.4968C0.173828 15.0852 0.626384 12.8263 1.5315 10.7202C2.43658 8.61412 3.67247 6.78126 5.23916 5.22162C6.80586 3.66201 8.64191 2.4273 10.7473 1.5175C12.8527 0.607721 15.1025 0.152832 17.4965 0.152832C19.909 0.152832 22.169 0.607318 24.2763 1.51629C26.3836 2.42526 28.2167 3.65887 29.7755 5.21712C31.3344 6.77535 32.5685 8.60771 33.4778 10.7142C34.3871 12.8207 34.8418 15.0809 34.8418 17.4947C34.8418 19.8893 34.3869 22.1395 33.4771 24.2455C32.5673 26.3513 31.3326 28.1878 29.773 29.7549C28.2134 31.322 26.3812 32.558 24.2766 33.4631C22.1719 34.3682 19.9138 34.8208 17.5021 34.8208Z"
        fill="#24A83A"
      />
    </svg>
  );
};

export default CheckCircleIcon;
