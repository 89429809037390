/**
 * @Author: zachalam
 * @Date:   2017-01-19T16:56:51-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T16:57:37-08:00
 */

import React, { useState, useEffect } from 'react';
import Interests from '../components/pages/Favorites/Interests';
import { connect } from 'react-redux';
import {
  favToggle,
  storeFavChange,
  refreshFavs,
  refreshInterestedV2,
  ignoreInterested,
  clearInterested,
  markAsReadInterested,
  refreshInterested,
} from '../actions/favActions';
import { refreshAccount } from '../actions/profileActions';
import { withPusher } from '../contexts/PusherContext';
import { PUSHER } from '../config/constants';
import { clearErrors } from '../actions/commonActions';
import { createNewOffer, sendSuggestion, acceptOffer, counterOffer, ignoreOffer } from '../actions/offerActions';
import { displayWarnMessage } from '../actions/notificationActions';
import { useStateCallback } from '../utils/customHooks';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import { unlockOutsideMessage } from '../actions/mailActions';
import { getLocalItem } from '../common';
import { SliderSourceLSKey } from '../components/common/GridViewSlider';
import { IsMobileViewPort } from '../utils/helpers';
import { useLocation } from 'react-router-dom';

interface InterestsContainerProps {
  bindListener: any;
  refreshInterestFav: any;
  clearInterested: any;
  userProfile: any;
  refresh: any;
  fav: any;
  stopListening: any;
  meta: any;
  toggle: any;
  refreshGlobalNotification: any;
  onRealtimeEvent: any;
  loadMore: any;
  batchMarkAsRead: any;
}

const InterestsContainer = (props: InterestsContainerProps) => {
  const {
    bindListener,
    refreshInterestFav,
    clearInterested,
    userProfile,
    refresh,
    fav,
    stopListening,
    meta,
    toggle,
    refreshGlobalNotification,
    onRealtimeEvent,
    loadMore,
    batchMarkAsRead,
  } = props;
  const location = useLocation();

  const getActivePage = () => {
    let activePage = 'viewed_me';

    switch (location.pathname) {
      case '/interests':
        activePage = 'viewed_me';
        break;
      case '/interests/my-favorites':
        activePage = 'from';
        break;
      case '/interests/favorited-me':
        activePage = 'to';
        break;
    }

    return activePage;
  };
  const [page, setPage] = useStateCallback(getActivePage());
  const [prevLocation, setPrevLocation] = useState(location);
  const [prevHashId, setPrevHashId] = useState(userProfile.hash_id);
  // determine which fav list to get (and pagination).
  const favList = fav[page];
  const moreExists = Boolean(meta[`fav_${page}_current_page`] < meta[`fav_${page}_total_pages`]);

  useEffect(() => {
    bindListener(listen);

    // check if favorites from or to is empty
    if (fav[page].length < 1) refreshEmptyFavs(page);
    return () => {
      stopListening(listen);
      refreshGlobalNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case '/interests':
        refreshInterestFav();
        break;
      case '/interests/my-favorites':
        refreshEmptyFavs('from');
        break;
      case '/interests/favorited-me':
        refreshEmptyFavs('to');
        break;
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== prevLocation.pathname) {
      const prevPage = page;
      setPrevLocation(location);
      setPage(getActivePage(), newState => {
        if ((newState && newState !== prevPage) || userProfile.hash_id !== prevHashId) {
          setPrevHashId(userProfile.hash_id);
          refreshEmptyFavs(newState);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const storageHashIds = JSON.parse(getLocalItem(SliderSourceLSKey.INTERESTS_LS)) || [];
    if (storageHashIds.length > 0) {
      batchMarkAsRead(storageHashIds);
    }
  }, []);

  const refreshEmptyFavs = route => {
    const isViewedMe = route === 'viewed_me';
    const shouldRefreshTo = userProfile.has_fav_update > 0 || (route === 'to' && fav.to.length === 0);
    const shouldRefreshFrom = route === 'from' && fav.from.length === 0;

    if (!isViewedMe && (shouldRefreshTo || shouldRefreshFrom)) {
      refresh(route);
    }

    if (isViewedMe && location.pathname === '/interests') {
      refreshInterestFav();
    }
  };

  const listen = () => {
    const { EVENTS } = PUSHER;

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      if (data.type === 'favorites' && data.action === 'receive') {
        loadMore(page);
      }
    });
  };

  const toggleFav = (username, favKey, section) => {
    toggle(username, favKey, section);
  };

  return (
    <Interests
      {...props}
      toggle={toggleFav}
      page={page}
      fav={favList}
      moreExists={moreExists}
      refreshInterestFav={refreshInterestFav}
      clearInterested={clearInterested}
      newFavorites={userProfile.new_favorites}
      newViews={userProfile.new_profile_views}
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    userProfile: state.profile,
    fav: state.fav,
    meta: state.meta,
    viewedMe: state.fav.viewed_me,
    isFetching: state.fav.isFetching,
    isFetchingFav: state.fav.isFetchingFav,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refresh(route) {
      // refresh data according to route.
      dispatch(refreshFavs(route, true));
      // also refresh account (for header counts).
      dispatch(refreshAccount());
    },
    refreshInterestFav() {
      if (IsMobileViewPort) {
        dispatch(refreshInterestedV2());
      } else {
        dispatch(refreshInterested());
      }
      dispatch(refreshAccount());
    },
    clearInterested() {
      dispatch(clearInterested());
    },
    ignoreUser(userHashId) {
      dispatch(ignoreInterested(userHashId));
    },
    toggle: (username, favKey, section) => {
      // notify store
      dispatch(storeFavChange(favKey, section));
      dispatch(favToggle(username));
    },
    toggleFav: username => {
      // notify api
      dispatch(favToggle(username));
    },
    loadMore(route) {
      if (route === 'viewed_me') {
        if (IsMobileViewPort) {
          const storageHashIds = JSON.parse(getLocalItem(SliderSourceLSKey.INTERESTS_LS)) || [];
          if (storageHashIds.length > 0) {
            dispatch(markAsReadInterested(storageHashIds, false));
          } else {
            dispatch(refreshInterestedV2(false));
          }
        } else {
          dispatch(refreshInterested(false));
        }
      } else {
        dispatch(refreshFavs(route, false));
      }
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    createNewOffer: (formData, favKey, section) => {
      return dispatch(createNewOffer(formData, true, favKey, section));
    },
    acceptOffer: (username, message, favKey, section, source) => {
      return dispatch(acceptOffer(username, message, true, favKey, section, false, source, ownProps.navigate));
    },
    counterOffer: (formData, favKey, section) => {
      return dispatch(counterOffer(formData, true, ownProps.navigate, favKey, section));
    },
    sendSuggestion: (username, favKey, section) => {
      return dispatch(sendSuggestion(username, true, favKey, section));
    },
    ignoreOffer: (hashId, favKey, section) => {
      dispatch(ignoreOffer(hashId, false, true, favKey, section));
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
    triggerUnlockMessage: (hashId, triggerToBuy, callback = () => undefined) => {
      dispatch(unlockOutsideMessage(hashId, triggerToBuy, callback));
    },
    batchMarkAsRead: (hashIds: string[]) => {
      dispatch(markAsReadInterested(hashIds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withPusher(InterestsContainer));
