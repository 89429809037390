export const STORE_PACKAGES = 'STORE_PACKAGES';
export const STORE_IDV_PACKAGES = 'STORE_IDV_PACKAGES';
export const STORE_KOUNT_DETAILS = 'STORE_KOUNT_DETAILS';
export const STORE_PAYMENTS = 'STORE_PAYMENTS';
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';
export const STORE_PAYMENT_TOKEN = 'STORE_PAYMENT_TOKEN';
export const REMOVE_PAYMENT_TOKEN = 'REMOVE_PAYMENT_TOKEN';
export const SET_PAYMENT_PROCESSING = 'SET_PAYMENT_PROCESSING';
export const KILL_PAYMENT_PROCESSING = 'KILL_PAYMENT_PROCESSING';

export interface Packages {
  packages: string;
}

export interface IdvPackages {
  idv_packages: string;
}

export interface Payments {
  payments: string;
}

export interface KountDetails {
  kountDetails: string;
}

export interface PaymentToken {
  paymentToken: unknown[];
}

export interface DdcData {
  ddcData: {
    ddcReferenceId?: string;
    ddcUrl?: string;
    jwt?: string;
    merchAcctId?: string;
    bin?: string;
  };
}

export interface IsddcSaved {
  isddcSaved: boolean;
}

export interface IsDCCFormSubmitted {
  isDCCFormSubmitted: boolean;
}

export interface iFrameKey {
  iFrameKey: string;
}

export interface isProcessing {
  isProcessing: boolean;
}

export interface BillingState extends IdvPackages, Packages, Payments, KountDetails {}
interface STORE_PACKAGES_ACTION {
  type: typeof STORE_PACKAGES;
  payload: Packages;
}

interface STORE_IDV_PACKAGES_ACTION {
  type: typeof STORE_IDV_PACKAGES;
  payload: Packages;
}

interface STORE_KOUNT_DETAILS_ACTION {
  type: typeof STORE_KOUNT_DETAILS;
  payload: KountDetails;
}

interface STORE_PAYMENTS_ACTION {
  type: typeof STORE_PAYMENTS;
  payload: Payments;
}

interface CLEAR_PAYMENTS_ACTION {
  type: typeof CLEAR_PAYMENTS;
  payload: BillingState;
}

interface STORE_PAYMENT_TOKEN_ACTION {
  type: typeof STORE_PAYMENT_TOKEN;
  payload: PaymentToken;
}

interface REMOVE_PAYMENT_TOKEN_ACTION {
  type: typeof REMOVE_PAYMENT_TOKEN;
  payload: PaymentToken;
}

interface SET_PAYMENT_PROCESSING {
  type: typeof SET_PAYMENT_PROCESSING;
  payload: isProcessing;
}

interface KILL_PAYMENT_PROCESSING {
  type: typeof KILL_PAYMENT_PROCESSING;
  payload: isProcessing;
}

export type BillingActionTypes =
  | STORE_PACKAGES_ACTION
  | STORE_IDV_PACKAGES_ACTION
  | STORE_KOUNT_DETAILS_ACTION
  | STORE_PAYMENTS_ACTION
  | CLEAR_PAYMENTS_ACTION
  | STORE_PAYMENT_TOKEN_ACTION
  | REMOVE_PAYMENT_TOKEN_ACTION
  | SET_PAYMENT_PROCESSING
  | KILL_PAYMENT_PROCESSING;
