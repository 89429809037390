import React, { ReactElement } from 'react';

const RecordVideo = (): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      data-test-id="capture-video-icon"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_852_5318"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_852_5318)">
        <path
          d="M20.1 32C20.5333 32 20.8917 31.8583 21.175 31.575C21.4583 31.2917 21.6 30.9333 21.6 30.5V25.4H26.7C27.1333 25.4 27.4917 25.2583 27.775 24.975C28.0583 24.6917 28.2 24.3333 28.2 23.9C28.2 23.4667 28.0583 23.1083 27.775 22.825C27.4917 22.5417 27.1333 22.4 26.7 22.4H21.6V17.3C21.6 16.8667 21.4583 16.5083 21.175 16.225C20.8917 15.9417 20.5333 15.8 20.1 15.8C19.6667 15.8 19.3083 15.9417 19.025 16.225C18.7417 16.5083 18.6 16.8667 18.6 17.3V22.4H13.5C13.0667 22.4 12.7083 22.5417 12.425 22.825C12.1417 23.1083 12 23.4667 12 23.9C12 24.3333 12.1417 24.6917 12.425 24.975C12.7083 25.2583 13.0667 25.4 13.5 25.4H18.6V30.5C18.6 30.9333 18.7417 31.2917 19.025 31.575C19.3083 31.8583 19.6667 32 20.1 32ZM7 40C6.2 40 5.5 39.7 4.9 39.1C4.3 38.5 4 37.8 4 37V11C4 10.2 4.3 9.5 4.9 8.9C5.5 8.3 6.2 8 7 8H33C33.8 8 34.5 8.3 35.1 8.9C35.7 9.5 36 10.2 36 11V21.75L42.7 15.05C42.9667 14.7833 43.25 14.7167 43.55 14.85C43.85 14.9833 44 15.2167 44 15.55V32.45C44 32.7833 43.85 33.0167 43.55 33.15C43.25 33.2833 42.9667 33.2167 42.7 32.95L36 26.25V37C36 37.8 35.7 38.5 35.1 39.1C34.5 39.7 33.8 40 33 40H7Z"
          fill="#2B8FD7"
        />
      </g>
    </svg>
  );
};

export default RecordVideo;
