import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { spacing, color, type, textColor, inputTheme } from '../style';
import Checkmark from './icons/Checkmark';
import { CheckboxV2 } from 'swui';

const Label = styled('label')(
  {
    display: 'block',
    cursor: 'pointer',
    ...spacing.MARGIN_TOP_SM,
    ...spacing.MARGIN_BOTTOM_SM,
    ...spacing.MARGIN_RIGHT_MD,
    ...textColor.NORMAL,
  },
  ({ inline }) => inline && { display: 'inline-block' },
  ({ isLogin }) => isLogin && { color: 'rgba(0,0,0,0.6)' },
  ({ noSpace }) =>
    noSpace && {
      margin: 0,
    },

  ({ customStyle }) =>
    customStyle && {
      ...customStyle,
    }
);

const CheckboxInput = styled(CheckboxV2)({
  position: 'absolute',
  width: '0',
  opacity: '0',
});

const StyledCheckbox = styled('div')(
  {
    background: inputTheme.backgroundInput,
    border: '1px solid #DBDBDB',
    borderRadius: '3px',
    height: '18px',
    width: '18px',
    display: 'inline-block',
    textAlign: 'center',
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
  ({ focused }) => focused && { borderColor: color.BRAND_1 },
  ({ checked }) =>
    checked && {
      backgroundColor: inputTheme.checkedInput,
      borderColor: color.BRAND_1,
    },
  ({ hasError }) =>
    hasError && {
      border: '1px solid #CC5453',
    },
  ({ customStyle }) =>
    customStyle && {
      ...customStyle,
    }
);

const CheckmarkContainer = styled('div')({
  position: 'relative',
  margin: '-7px',
  left: '-1px',
});

const LabelText = styled('span')(
  {
    ...spacing.PADDING_LEFT_SM,
  },
  ({ disabled }) =>
    disabled && {
      color: '#cccccc',
    },
  ({ size }) => (size ? { ...type[size] } : { ...type['SM'] }),
  ({ customLabelTextStyle }) =>
    customLabelTextStyle && {
      ...customLabelTextStyle,
    }
);

interface CheckboxProps {
  disabled?: any;
  label?: any;
  inline?: any;
  noSpace?: any;
  size?: any;
  hasError?: any;
  value: any;
  customStyle: any;
  customLabelTextStyle: any;
  isLogin?: boolean;
  svgCustomStyle?: React.CSSProperties;
  labelCustomStyle?: React.CSSProperties;
  onChange?: () => void;
}

const Checkbox = ({
  disabled,
  label,
  inline,
  noSpace,
  size,
  hasError,
  value,
  customStyle,
  customLabelTextStyle = {},
  svgCustomStyle = {},
  labelCustomStyle = {},
  isLogin = false,
  ...rest
}: CheckboxProps): ReactElement => {
  const [focused, setFocused] = useState(false);

  const focusHandler = () => {
    setFocused(true);
  };

  const blurHandler = () => {
    setFocused(false);
  };

  return (
    <Label inline={inline} isLogin={isLogin} noSpace={noSpace} customStyle={labelCustomStyle}>
      <CheckboxInput onFocus={focusHandler} onBlur={blurHandler} value={value} {...rest} />
      <StyledCheckbox hasError={hasError} focused={focused} checked={value} customStyle={customStyle}>
        {value && (
          <CheckmarkContainer>
            <Checkmark customStyle={svgCustomStyle} />
          </CheckmarkContainer>
        )}
      </StyledCheckbox>
      <LabelText disabled={disabled} size={size} customLabelTextStyle={customLabelTextStyle}>
        {label}
      </LabelText>
    </Label>
  );
};

Checkbox.defaultProps = {
  size: 'SM',
  hasError: false,
};

Checkbox.propTypes = {
  /** Determines size of font SM (default): 14px, MD: 16px */
  size: PropTypes.oneOf(['SM', 'MD']),

  onChange: PropTypes.func.isRequired,
  /** Label next to checkbox */
  label: PropTypes.any,
  /** Determines if checkbox should display inline-block */
  inline: PropTypes.bool,
  /** Determines if checkbox should have red borders */
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Checkbox;
