import { getCookie } from '../utils/helpers';
import { REALGIFTS_HOST_COOKIE_DOMAIN, REALGIFTS_THEME_ID, REALGIFTS_ENABLE } from '../constants/realgifts';
import * as sdk from '../sdk';

interface UseRealGiftsProps {
  initRGUserCookie: any;
  handleRGLogoff: () => any;
  handleRGWishlist: any;
  formatRGReceiverData: (data: ReceiverDataProps) => any;
  isEnableRealGifts: boolean;
}

export interface ReceiverDataProps {
  id: string;
  username: string;
  gender: string;
  country: string;
  birthday: string;
  isPremium: boolean;
  photo: string;
}

const useRealGifts = (): UseRealGiftsProps => {
  const initRGUserCookie = async (hashId: string) => {
    const rgStoreUser = getCookie('rgstore_user');
    if (!rgStoreUser && REALGIFTS_ENABLE) {
      const result = await sdk.getRealGiftsCookie(hashId);
      if (window.RealGifts) {
        window.RealGifts.user.logOn(result.data.cookie, REALGIFTS_HOST_COOKIE_DOMAIN);
      }
    }
  };

  const handleRGLogoff = () => {
    window.RealGifts && window.RealGifts.user.logOff();
  };

  const formatRGReceiverData = (data: ReceiverDataProps) => {
    return {
      receiver_id: data.id,
      receiver_nickname: data.username,
      receiver_gender: data.gender,
      receiver_country: data.country,
      receiver_birthday: data.birthday,
      receiver_is_premium: data.isPremium,
      receiver_photo: data.photo,
    };
  };

  interface HandleRGWishlistProps {
    buttonRef: any;
    receiverData: ReceiverDataProps;
    userTemplateSetId: string;
  }

  const handleRGWishlist = ({ buttonRef, receiverData, userTemplateSetId }: HandleRGWishlistProps): void => {
    const formattedReceiverData = formatRGReceiverData(receiverData);

    window.RealGifts &&
      window.RealGifts.button.init(buttonRef.current, {
        mode: 'purchase',
        go: 'wishlist',
        user_template_set_id: userTemplateSetId,
        ...formattedReceiverData,
        user_theme_id: REALGIFTS_THEME_ID,
        onclose: 'onCloseRGWidget',
      });
  };

  const isEnableRealGifts = REALGIFTS_ENABLE;

  return {
    initRGUserCookie,
    handleRGLogoff,
    handleRGWishlist,
    formatRGReceiverData,
    isEnableRealGifts,
  };
};

export default useRealGifts;
