import React from 'react';
import NotFoundContainer from '../containers/NotFoundContainer';
import RouteContainer from '../containers/RouteContainer';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import publicRoutes from './public';
import privateRoutes from './private';
import ErrorBoundary from '../components/ErrorBoundary';

const notfoundRoute = {
  path: '/*',
  element: (
    <RouteContainer authorize isNotFoundPage>
      <NotFoundContainer />
    </RouteContainer>
  ),
};
export type BasicRouteItem = {
  path: string;
  component: JSX.Element;
};

export type RouteItem = BasicRouteItem & { children?: RouteItem[] };

export const formatRoutes = (routes: RouteItem[], authorize: boolean): RouteObject[] => {
  return routes.map(route => {
    const { path, component, children = [] } = route;
    return {
      path,
      element: <RouteContainer authorize={authorize}>{component}</RouteContainer>,
      children: formatRoutes(children, authorize),
      errorElement: <ErrorBoundary />,
    };
  });
};

export default createBrowserRouter([
  ...formatRoutes(publicRoutes, false),
  ...formatRoutes(privateRoutes, true),
  { ...notfoundRoute },
]);
