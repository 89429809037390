import { DEFAULT_REASON, EvidentlyFeatureExperiment } from '../../constants/evidently';
import { Profile } from '../../models/Profile';
import { TrackEvidentlyMetricsParams } from '../../sdk/CommonSDK';
import { getBrowserDetails } from '../helpers';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

export const DISCOVER_SWIPING_MODE_AM_FEATURE_NAME = 'DiscoverSwipingModeAM';
export const DISCOVER_SWIPING_MODE_AM_EXPERIMENT_NAME = 'DiscoverMode-AM';
export const DISCOVER_SWIPING_MODE_AM_LOCAL_STORAGE_KEY = 'evidently_discover_swipe_mode_am';

export type DiscoverSwipeModeAMVariations = 'Control' | 'DiscoverModeAM';
const mixpanelEventVariation = {
  Control: 'Control',
  DiscoverModeAM: 'Discover Mode AM',
};

/**
 * This is only used in batchEvaluation
 *
 * @param profile Profile
 * @returns boolean
 */
const handleIsFeatureEvaluate = (profile: Profile): boolean => {
  return profile.isAttractive() && getBrowserDetails().isMobile;
};

const handleFeatureCallback = async (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled?: boolean
): Promise<void> => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  mixpanelTrackExperimentStarted({
    'Experiment name': DISCOVER_SWIPING_MODE_AM_EXPERIMENT_NAME,
    'Variant name': mixpanelEventVariation?.[details.variation] ?? 'Control',
  });
};

const featureMetrics = (details: EvidentlyFeatureExperiment): TrackEvidentlyMetricsParams<string>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  return [
    {
      featureName: DISCOVER_SWIPING_MODE_AM_FEATURE_NAME,
      experimentName: DISCOVER_SWIPING_MODE_AM_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'offersSentDay0',
      value: 0,
    },
  ];
};
export const DiscoverSwipingModeAMEvaluation = [
  {
    featureMetrics,
    featureName: DISCOVER_SWIPING_MODE_AM_FEATURE_NAME,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
