import styled from '@emotion/styled';
import { color } from '../../style';

const TextLink = styled('a')(
  {
    fontWeight: 600,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  ({ fontcolor }) => {
    return fontcolor === 'red' ? { ...textColor.RED } : { color: color.BRAND_1 };
  }
);

export default TextLink;
