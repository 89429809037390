import React, { ReactElement } from 'react';

interface EyeIconProps {
  isActive: any;
  fill: string;
  width: number;
  height: number;
}

const EyeIcon = ({ isActive, fill = '#8C8C8C', width = 18, height = 18 }: EyeIconProps): ReactElement =>
  isActive ? (
    <svg width={width} height={height} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
        fill={fill ? fill : '#BC2028'}
      />
    </svg>
  ) : (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
        fill={fill ? fill : '#6C6C6C'}
      />
    </svg>
  );

interface StarIconProps {
  isActive: any;
  fill: string;
  width: number;
  height: number;
}

const StarIcon = ({ isActive, fill = '#8C8C8C', width = 18, height = 18 }: StarIconProps): ReactElement =>
  isActive ? (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00001 14.5195L13.15 17.0295C13.91 17.4895 14.84 16.8095 14.64 15.9495L13.54 11.2295L17.21 8.04952C17.88 7.46952 17.52 6.36952 16.64 6.29952L11.81 5.88952L9.92001 1.42952C9.58001 0.619521 8.42001 0.619521 8.08001 1.42952L6.19001 5.87952L1.36001 6.28952C0.480012 6.35952 0.120012 7.45952 0.790012 8.03952L4.46001 11.2195L3.36001 15.9395C3.16001 16.7995 4.09001 17.4795 4.85001 17.0195L9.00001 14.5195Z"
        fill={fill}
        className="star-icon-path"
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00001 14.5195L13.15 17.0295C13.91 17.4895 14.84 16.8095 14.64 15.9495L13.54 11.2295L17.21 8.04952C17.88 7.46952 17.52 6.36952 16.64 6.29952L11.81 5.88952L9.92001 1.42952C9.58001 0.619521 8.42001 0.619521 8.08001 1.42952L6.19001 5.87952L1.36001 6.28952C0.480012 6.35952 0.120012 7.45952 0.790012 8.03952L4.46001 11.2195L3.36001 15.9395C3.16001 16.7995 4.09001 17.4795 4.85001 17.0195L9.00001 14.5195Z"
        fill={fill ? fill : '#8C8C8C'}
      />
    </svg>
  );

interface StarOutlineIconProps {
  isActive: any;
  fill: string;
  width: number;
  height: number;
}

const StarOutlineIcon = ({ isActive, fill = '#8C8C8C', width = 18, height = 18 }: StarOutlineIconProps): ReactElement =>
  isActive ? (
    <svg width={width} height={height} viewBox="0 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6485 6.29464L11.8085 5.87464L9.91855 1.42464C9.57855 0.614639 8.41855 0.614639 8.07855 1.42464L6.18855 5.88464L1.35855 6.29464C0.478547 6.36464 0.118547 7.46464 0.788547 8.04464L4.45855 11.2246L3.35855 15.9446C3.15855 16.8046 4.08855 17.4846 4.84855 17.0246L8.99855 14.5246L13.1485 17.0346C13.9085 17.4946 14.8385 16.8146 14.6385 15.9546L13.5385 11.2246L17.2085 8.04464C17.8785 7.46464 17.5285 6.36464 16.6485 6.29464ZM8.99855 12.6546L5.23855 14.9246L6.23855 10.6446L2.91855 7.76464L7.29855 7.38464L8.99855 3.35464L10.7085 7.39464L15.0885 7.77464L11.7685 10.6546L12.7685 14.9346L8.99855 12.6546Z"
        fill={fill ? fill : '#BC2028'}
      />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6485 6.29464L11.8085 5.87464L9.91855 1.42464C9.57855 0.614639 8.41855 0.614639 8.07855 1.42464L6.18855 5.88464L1.35855 6.29464C0.478547 6.36464 0.118547 7.46464 0.788547 8.04464L4.45855 11.2246L3.35855 15.9446C3.15855 16.8046 4.08855 17.4846 4.84855 17.0246L8.99855 14.5246L13.1485 17.0346C13.9085 17.4946 14.8385 16.8146 14.6385 15.9546L13.5385 11.2246L17.2085 8.04464C17.8785 7.46464 17.5285 6.36464 16.6485 6.29464ZM8.99855 12.6546L5.23855 14.9246L6.23855 10.6446L2.91855 7.76464L7.29855 7.38464L8.99855 3.35464L10.7085 7.39464L15.0885 7.77464L11.7685 10.6546L12.7685 14.9346L8.99855 12.6546Z"
        fill={fill ? fill : '#6C6C6C'}
      />
    </svg>
  );

const ListView = (): ReactElement => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4ZM0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4Z"
      fill="#C9C9C9"
    />
  </svg>
);

const GridView = (): ReactElement => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0V8H8V0H0ZM6 6H2V2H6V6ZM0 10V18H8V10H0ZM6 16H2V12H6V16ZM10 0V8H18V0H10ZM16 6H12V2H16V6ZM10 10V18H18V10H10ZM16 16H12V12H16V16Z"
      fill="#C9C9C9"
    />
  </svg>
);

interface ButtonIconProps {
  fill: string;
  height?: number;
  width?: number;
}

const ButtonIcon = ({ fill = 'none', height = 56, width = 55 }: ButtonIconProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 1 40 39" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.4279 15.6849L23.8494 14.6865L19.3243 4.02591L14.7992 14.7026L3.2207 15.6849L12.0133 23.3019L9.37228 34.6228L19.3243 28.6161L29.2763 34.6228L26.6514 23.3019L35.4279 15.6849ZM19.3243 25.6047L13.2694 29.2603L14.8797 22.3679L9.53332 17.7301L16.5867 17.1181L19.3243 10.6284L22.078 17.1342L29.1314 17.7462L23.785 22.384L25.3954 29.2764L19.3243 25.6047Z"
      fill="rgba(62, 121, 165, 1)"
    />
  </svg>
);

const FavoritedIcon = (): ReactElement => (
  <svg width="48" height="59" viewBox="0 0 48 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_377_3952)">
      <circle cx="24" cy="22" r="20" fill="#F4F7FA" />
      <circle cx="24" cy="22" r="19" stroke="white" strokeWidth="2" />
    </g>
    <path
      d="M35.7658 19.3532L27.345 18.627L24.0541 10.8739L20.7631 18.6387L12.3423 19.3532L18.7369 24.8928L16.8162 33.1261L24.0541 28.7577L31.2919 33.1261L29.3829 24.8928L35.7658 19.3532ZM24.0541 26.5676L19.6504 29.2261L20.8216 24.2135L16.9333 20.8406L22.0631 20.3955L24.0541 15.6757L26.0568 20.4072L31.1865 20.8523L27.2982 24.2252L28.4694 29.2378L24.0541 26.5676Z"
      fill="#F2D2D4"
    />
    <line x1="10.2929" y1="35.3469" x2="36.7794" y2="8.86046" stroke="white" strokeWidth="2" />
    <defs>
      <filter
        id="filter0_d_377_3952"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_377_3952" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_377_3952" result="shape" />
      </filter>
    </defs>
  </svg>
);

interface StarNotificationIconProps {
  isActive: any;
  fill: string;
}

const StarNotificationIcon = ({ isActive, fill = '#BC2028' }: StarNotificationIconProps): ReactElement =>
  isActive ? (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00001 14.5195L13.15 17.0295C13.91 17.4895 14.84 16.8095 14.64 15.9495L13.54 11.2295L17.21 8.04952C17.88 7.46952 17.52 6.36952 16.64 6.29952L11.81 5.88952L9.92001 1.42952C9.58001 0.619521 8.42001 0.619521 8.08001 1.42952L6.19001 5.87952L1.36001 6.28952C0.480012 6.35952 0.120012 7.45952 0.790012 8.03952L4.46001 11.2195L3.36001 15.9395C3.16001 16.7995 4.09001 17.4795 4.85001 17.0195L9.00001 14.5195Z"
        fill={fill ? fill : '#BC2028'}
        className="star-icon-path"
      />
    </svg>
  ) : (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00001 14.5195L13.15 17.0295C13.91 17.4895 14.84 16.8095 14.64 15.9495L13.54 11.2295L17.21 8.04952C17.88 7.46952 17.52 6.36952 16.64 6.29952L11.81 5.88952L9.92001 1.42952C9.58001 0.619521 8.42001 0.619521 8.08001 1.42952L6.19001 5.87952L1.36001 6.28952C0.480012 6.35952 0.120012 7.45952 0.790012 8.03952L4.46001 11.2195L3.36001 15.9395C3.16001 16.7995 4.09001 17.4795 4.85001 17.0195L9.00001 14.5195Z"
        fill={fill ? fill : '#6C6C6C'}
      />
    </svg>
  );

interface HeartNotificationIconProps {
  fill: string;
}

const HeartNotificationIcon = ({ fill = 'rgba(255,255,255,1)' }: HeartNotificationIconProps): ReactElement => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.78739 9.1536C5.40099 9.4986 4.80612 9.4986 4.41972 9.1486L4.36379 9.0986C1.69453 6.7236 -0.0493877 5.1686 0.0167082 3.2286C0.047214 2.3786 0.489549 1.5636 1.20644 1.0836C2.54869 0.183599 4.20617 0.603599 5.10101 1.6336C5.99585 0.603599 7.65333 0.178599 8.99559 1.0836C9.71247 1.5636 10.1548 2.3786 10.1853 3.2286C10.2565 5.1686 8.50749 6.7236 5.83824 9.1086L5.78739 9.1536Z"
      fill={fill ? fill : 'rgba(255,255,255,1)'}
    />
  </svg>
);

interface PrivatePhotoIconProps {
  fill: string;
}

const PrivatePhotoIcon = ({ fill = 'rgba(108, 108, 108, 1)' }: PrivatePhotoIconProps): ReactElement => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.11113 2.46519C7.51668 2.46519 8.65742 3.49186 8.65742 4.75686C8.65742 4.99061 8.6065 5.21519 8.5352 5.42602L10.0935 6.82852C10.8014 6.26477 11.3616 5.55894 11.713 4.75227C10.832 2.74477 8.65742 1.31936 6.11113 1.31936C5.46437 1.31936 4.84307 1.41102 4.25743 1.58061L5.36252 2.57519C5.60187 2.51102 5.85141 2.46519 6.11113 2.46519ZM1.38011 0.934355C1.1815 1.11311 1.1815 1.40186 1.38011 1.58061L2.38335 2.48352C1.55835 3.07477 0.901407 3.85394 0.509277 4.75686C1.3903 6.76894 3.56483 8.19436 6.11113 8.19436C6.8852 8.19436 7.62363 8.05686 8.30604 7.81852L9.69122 9.06519C9.88983 9.24394 10.2107 9.24394 10.4093 9.06519C10.6079 8.88644 10.6079 8.59769 10.4093 8.41894L2.10326 0.934355C1.90465 0.755605 1.57872 0.755605 1.38011 0.934355ZM6.11113 7.04852C4.70557 7.04852 3.56483 6.02186 3.56483 4.75686C3.56483 4.40394 3.6565 4.06936 3.81437 3.77602L4.61391 4.49561C4.59863 4.57811 4.58335 4.66519 4.58335 4.75686C4.58335 5.51769 5.26576 6.13186 6.11113 6.13186C6.21298 6.13186 6.30465 6.11811 6.40141 6.09977L7.20094 6.81936C6.86993 6.96602 6.50326 7.04852 6.11113 7.04852ZM7.62363 4.60561C7.54724 3.96394 6.98705 3.46436 6.27918 3.39561L7.62363 4.60561Z"
      fill={fill ? fill : 'rgba(108, 108, 108, 1)'}
    />
  </svg>
);

interface PublicPhotoIconProps {
  fill: string;
}

const PublicPhotoIcon = ({ fill = 'rgba(108, 108, 108, 1)' }: PublicPhotoIconProps): ReactElement => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 8.56667C7.06039 8.56667 7.92 7.69113 7.92 6.61111C7.92 5.53109 7.06039 4.65556 6 4.65556C4.93961 4.65556 4.08 5.53109 4.08 6.61111C4.08 7.69113 4.93961 8.56667 6 8.56667Z"
      fill={fill ? fill : 'rgba(108, 108, 108, 1)'}
    />
    <path
      d="M4.2 0.5L3.102 1.72222H1.2C0.54 1.72222 0 2.27222 0 2.94444V10.2778C0 10.95 0.54 11.5 1.2 11.5H10.8C11.46 11.5 12 10.95 12 10.2778V2.94444C12 2.27222 11.46 1.72222 10.8 1.72222H8.898L7.8 0.5H4.2ZM6 9.66667C4.344 9.66667 3 8.29778 3 6.61111C3 4.92444 4.344 3.55556 6 3.55556C7.656 3.55556 9 4.92444 9 6.61111C9 8.29778 7.656 9.66667 6 9.66667Z"
      fill={fill ? fill : 'rgba(108, 108, 108, 1)'}
    />
  </svg>
);

interface OfferAcceptProps {
  fill?: string;
}

const OfferAccept = ({ fill = '#3E79A5' }: OfferAcceptProps): ReactElement => (
  <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2001 0.178711C19.5597 0.178711 17.0256 1.40785 15.3716 3.35019C13.7175 1.40785 11.1834 0.178711 8.54302 0.178711C3.86926 0.178711 0.197021 3.85095 0.197021 8.52471C0.197021 14.2607 5.35637 18.9345 13.1713 26.0361L15.3716 28.024L17.5719 26.021C25.3868 18.9345 30.5461 14.2607 30.5461 8.52471C30.5461 3.85095 26.8739 0.178711 22.2001 0.178711ZM15.5233 23.7751L15.3716 23.9269L15.2198 23.7751C7.99674 17.2349 3.23193 12.9102 3.23193 8.52471C3.23193 5.4898 5.50811 3.21362 8.54302 3.21362C10.8799 3.21362 13.1561 4.7159 13.9603 6.79481H16.798C17.5871 4.7159 19.8632 3.21362 22.2001 3.21362C25.235 3.21362 27.5112 5.4898 27.5112 8.52471C27.5112 12.9102 22.7464 17.2349 15.5233 23.7751Z"
      fill={fill ? fill : '#3E79A5'}
    />
  </svg>
);

interface OfferNewProps {
  fill: string;
}
const OfferNew = ({ fill = '#3E79A5' }: OfferNewProps): ReactElement => (
  <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_971_2171)">
      <path
        d="M9.98724 14.3357C6.05053 13.3125 4.78454 12.2546 4.78454 10.6071C4.78454 8.71679 6.53612 7.39877 9.46697 7.39877C12.5539 7.39877 13.6985 8.87287 13.8026 11.0407H17.6352C17.5138 8.05778 15.6929 5.31769 12.0683 4.43323V0.635254H6.86562V4.3812C3.50121 5.10958 0.795804 7.29471 0.795804 10.6418C0.795804 14.6479 4.10819 16.6422 8.9467 17.8042C13.2823 18.8447 14.1494 20.3708 14.1494 21.9837C14.1494 23.1803 13.2996 25.088 9.46697 25.088C5.89445 25.088 4.48972 23.4925 4.29896 21.4461H0.483643C0.691751 25.244 3.53589 27.3771 6.86562 28.0882V31.8515H12.0683V28.1229C15.4501 27.4812 18.1381 25.5215 18.1381 21.9663C18.1381 17.0411 13.924 15.3589 9.98724 14.3357Z"
        fill={fill ? fill : '#3E79A5'}
      />
    </g>
    <defs>
      <filter
        id="filter0_i_971_2171"
        x="0.483643"
        y="0.635254"
        width="17.6545"
        height="32.2163"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_971_2171" />
      </filter>
    </defs>
  </svg>
);

interface OfferDeclineProps {
  fill: string;
}

const OfferDecline = ({ fill = '#EEC7C9' }: OfferDeclineProps): ReactElement => (
  <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8716 28.024L13.6713 26.021C5.85637 18.9345 0.697021 14.2607 0.697021 8.52471C0.697021 3.85095 4.36926 0.178711 9.04302 0.178711C11.6834 0.178711 14.2175 1.40785 15.8716 3.35019C17.5256 1.40785 20.0597 0.178711 22.7001 0.178711C27.3739 0.178711 31.0461 3.85095 31.0461 8.52471C31.0461 14.2607 25.8868 18.9345 18.0719 26.0361L15.8716 28.024Z"
      fill={fill ? fill : '#EEC7C9'}
    />
  </svg>
);

interface OfferCounterProps {
  fill: string;
}

const OfferCounter = ({ fill = 'rgda(255, 255, 255, 1)' }: OfferCounterProps): ReactElement => (
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1892 19.3105V10.027H12.5405V19.3105H8.56756L13.8649 24.5946L19.1622 19.3105H15.1892ZM5.91891 0.756756L0.621613 6.04081H4.59459V15.3243H7.24323V6.04081H11.2162L5.91891 0.756756Z"
      fill={fill ? fill : 'rgba(255,255,255,1)'}
    />
  </svg>
);

interface CrossIconProps {
  fill: string;
  customStyle?: React.CSSProperties;
}

const CrossIcon = ({ fill = '#BC2028', customStyle, ...rest }: CrossIconProps): ReactElement => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...customStyle }}
    {...rest}
  >
    <g filter="url(#filter0_i_971_778)">
      <path
        d="M24.6396 2.54888L22.1944 0.103607L12.5 9.79798L2.80562 0.103607L0.360352 2.54888L10.0547 12.2432L0.360352 21.9376L2.80562 24.3829L12.5 14.6885L22.1944 24.3829L24.6396 21.9376L14.9453 12.2432L24.6396 2.54888Z"
        fill={fill ? fill : '#BC2028'}
      />
    </g>
    <defs>
      <filter
        id="filter0_i_971_778"
        x="0.360352"
        y="0.103607"
        width="24.2793"
        height="25.2793"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_971_778" />
      </filter>
    </defs>
  </svg>
);

const ConversationIcon = (): ReactElement => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1165_2211)">
      <path
        d="M21.8333 0.333496H3.16659C1.88325 0.333496 0.844919 1.3835 0.844919 2.66683L0.833252 23.6668L5.49992 19.0002H21.8333C23.1166 19.0002 24.1666 17.9502 24.1666 16.6668V2.66683C24.1666 1.3835 23.1166 0.333496 21.8333 0.333496ZM6.66658 8.50016H18.3333C18.9749 8.50016 19.4999 9.02516 19.4999 9.66683C19.4999 10.3085 18.9749 10.8335 18.3333 10.8335H6.66658C6.02492 10.8335 5.49992 10.3085 5.49992 9.66683C5.49992 9.02516 6.02492 8.50016 6.66658 8.50016ZM13.6666 14.3335H6.66658C6.02492 14.3335 5.49992 13.8085 5.49992 13.1668C5.49992 12.5252 6.02492 12.0002 6.66658 12.0002H13.6666C14.3083 12.0002 14.8333 12.5252 14.8333 13.1668C14.8333 13.8085 14.3083 14.3335 13.6666 14.3335ZM18.3333 7.3335H6.66658C6.02492 7.3335 5.49992 6.8085 5.49992 6.16683C5.49992 5.52516 6.02492 5.00016 6.66658 5.00016H18.3333C18.9749 5.00016 19.4999 5.52516 19.4999 6.16683C19.4999 6.8085 18.9749 7.3335 18.3333 7.3335Z"
        fill="#3E79A5"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1165_2211"
        x="0.833252"
        y="0.333496"
        width="23.3333"
        height="24.3335"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1165_2211" />
      </filter>
    </defs>
  </svg>
);

interface HeartIconProps {
  isActive: any;
}

const HeartIcon = ({ isActive }: HeartIconProps): ReactElement =>
  isActive ? (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 16.5282L8.23125 15.3732C3.725 11.2869 0.75 8.59192 0.75 5.28442C0.75 2.58942 2.8675 0.471924 5.5625 0.471924C7.085 0.471924 8.54625 1.18067 9.5 2.30067C10.4537 1.18067 11.915 0.471924 13.4375 0.471924C16.1325 0.471924 18.25 2.58942 18.25 5.28442C18.25 8.59192 15.275 11.2869 10.7688 15.3819L9.5 16.5282Z"
        fill="#BC2028"
      />
    </svg>
  ) : (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4375 0.471924C11.915 0.471924 10.4537 1.18067 9.5 2.30067C8.54625 1.18067 7.085 0.471924 5.5625 0.471924C2.8675 0.471924 0.75 2.58942 0.75 5.28442C0.75 8.59192 3.725 11.2869 8.23125 15.3819L9.5 16.5282L10.7688 15.3732C15.275 11.2869 18.25 8.59192 18.25 5.28442C18.25 2.58942 16.1325 0.471924 13.4375 0.471924ZM9.5875 14.0782L9.5 14.1657L9.4125 14.0782C5.2475 10.3069 2.5 7.81317 2.5 5.28442C2.5 3.53442 3.8125 2.22192 5.5625 2.22192C6.91 2.22192 8.2225 3.08817 8.68625 4.28692H10.3225C10.7775 3.08817 12.09 2.22192 13.4375 2.22192C15.1875 2.22192 16.5 3.53442 16.5 5.28442C16.5 7.81317 13.7525 10.3069 9.5875 14.0782Z"
        fill="#8F8F8F"
      />
    </svg>
  );

export {
  EyeIcon,
  StarIcon,
  StarOutlineIcon,
  GridView,
  ListView,
  ButtonIcon,
  FavoritedIcon,
  StarNotificationIcon,
  HeartNotificationIcon,
  PublicPhotoIcon,
  PrivatePhotoIcon,
  OfferAccept,
  OfferNew,
  OfferCounter,
  OfferDecline,
  CrossIcon,
  ConversationIcon,
  HeartIcon,
};
