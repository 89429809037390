import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { WYP_GIF_LOADER } from '../../config/Master';

interface LoaderProps {
  active: boolean;
  customStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  showLoadingBg?: boolean;
}

const StyledLoaderContainer = styled<'div', LoaderProps>('div')(props => ({
  display: props.active ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: props.showLoadingBg ? 'rgba(255, 255, 255, 0.7)' : 'none',
  zIndex: 9999,
  ...(props.customStyle || {}),
}));

const StyledLoader = styled('div')({
  textAlign: 'center',
});

const Loader: React.FC<LoaderProps> = ({ customStyle, imgStyle, active, showLoadingBg=true }) => {
  return (
    <StyledLoaderContainer customStyle={customStyle} active={active} showLoadingBg={showLoadingBg}>
      <StyledLoader>
        <img src={WYP_GIF_LOADER} alt="Loading..." style={imgStyle} />
      </StyledLoader>
    </StyledLoaderContainer>
  );
};

export default Loader;
