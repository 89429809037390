import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { CancelRounded } from '@material-ui/icons';
import Headline from '../../components/common/Headline';
import ConfirmationModal from '../../modules/modals/ConfirmationModal';
import { SvgIcon } from '@material-ui/core';
import JoinActionButton from '../../components/join/JoinActionButton';
import JoinSkipButton from '../../components/join/JoinSkipButton';
import { DashlineSeparator } from '../../components/join/styledElements';
import { IsMobileViewPort } from '../../utils/helpers';
import useNav from '../../hooks/useNav';
import MasterConfig from '../../config/Master';
import Button from '../../atoms/buttons/Button';
import TextLinkV2 from '../../components/common/TextLinkV2';
import { commonIcons } from '../../atoms/icons/materialIcons';
import { isFD } from '../../config/Master';
import FDIcon from '../../atoms/icons/FDIcon';
import WYPIcon from '../../atoms/icons/WYPIcon';
import { getBrowserDetails } from '../../utils/helpers';
import { mixpanelTrackEmailVerificationResent } from '../../utils/mixpanel/verificationEmailResent';

const { isMobile } = getBrowserDetails();

const EmailConfirmContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  maxWidth: 315,
  margin: '25px auto 5px',
});

const Email = styled('div')({
  margin: '10px 0 4px',
  fontWeight: 600,
  color: '#2B8FD7',
});

const LinkStyle1 = styled('a')`
  color: #2b8fd7;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  margin-left: 5px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 0,
  width: 300,
  textAlign: 'center',
});

const FooterContainer = styled('div')({});

const FooterLabel = styled('p')({
  textAlign: IsMobileViewPort ? 'left' : 'center',
  fontSize: 16,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  marginLeft: IsMobileViewPort ? -15 : 0,
});

const FooterList = styled('li')({
  fontSize: 14,
  fontWeight: 400,
  paddingLeft: IsMobileViewPort ? 0 : 'unset',
  marginBottom: 8,
});

const FooterFAQLink = styled('a')`
  color: #2b8fd7;
  font-weight: 700;
  font-size: 14;
  text-decoration: underline;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: -38,
  right: 0,
});

const SmallIcon = styled('div')({
  fontSize: 11,
});

const ActionLink = styled('span')({
  textDecoration: 'underline',
  color: '#000',
  fontWeight: 'normal',
});

const renderBtnLnk = props => {
  switch (props.variant) {
    case 'variantNewJoinFlow':
      return;
    case 'variant1':
      return (
        <LinkStyle1 onClick={!props.isSending && props.handleOnClick}>
          {props.isSending ? 'Resending..' : 'Resend Verification Email'}
        </LinkStyle1>
      );
    case 'variant2':
      return (
        <JoinActionButton
          buttonType="primary"
          label={props.isSending ? 'Resending...' : 'Resend Verification'}
          isDisabled={props.isSending}
          onClick={props.handleOnClick}
          customStyle={{ marginBottom: 10 }}
        />
      );
    case 'profilev2':
      return (
        <Button
          data-test-id="resend-email-button"
          onClick={() => {
            props.handleOnClick();
          }}
          customStyle={{ width: 65, height: 26, fontSize: 13 }}
        >
          Send
        </Button>
      );
    case 'completionProgress':
      return (
        <TextLinkV2
          onClick={() => {
            props.handleOnClick();
            mixpanelTrackEmailVerificationResent({
              Source: 'Profile Completion Progress',
            });
          }}
          style={{ textDecoration: 'underline', color: '#2B8FD7', fontSize: 12, fontWeight: 600 }}
        >
          Resend
        </TextLinkV2>
      );
    default:
      return (
        <div>
          <TextLinkV2
            data-test-id="resend-email-button"
            onClick={() => {
              props.handleOnClick();
            }}
          >
            <SmallIcon>
              <commonIcons.resend.small />
            </SmallIcon>
            <ActionLink>Resend</ActionLink>
          </TextLinkV2>
        </div>
      );
  }
};

const EmailConfirmV2 = (props: any): ReactElement => {
  const { goTo } = useNav();

  const customWidth = IsMobileViewPort ? { maxWidth: 335 } : { width: 450 };

  return (
    <React.Fragment>
      {renderBtnLnk(props)}
      <ConfirmationModal
        contentLabel="Email Confirm Modal"
        isOpen={props.openStatus}
        hideCancel={true}
        buttonPosition={'center'}
        onConfirm={() => {
          props.handleToggleOpenStatus();
        }}
        customStyle={{ content: { borderRadius: 10, ...customWidth } }}
        hideButton={true}
      >
        <CloseBtn data-test-id={'email-confirmation-content-close-btn'} onClick={() => props.handleToggleOpenStatus()}>
          <SvgIcon component={CancelRounded} style={{ fontSize: 20, color: '#8F8F8F' }} />
        </CloseBtn>
        <EmailConfirmContainer data-test-id="email-confirmation-content">
          {isFD ? (
            <FDIcon
              customStyle={{ transform: isMobile ? 'scale(1.4)' : 'scale(1.3)', marginTop: isMobile ? 20 : 10 }}
            />
          ) : (
            <WYPIcon
              customStyle={{ transform: isMobile ? 'scale(1.4)' : 'scale(1.3)', marginTop: isMobile ? 20 : 10 }}
            />
          )}

          <DashlineSeparator style={{ width: '100%' }} />
          <Headline
            style={{ color: '#333333', fontWeight: 600, fontSize: isMobile ? 16 : 18, marginTop: isMobile ? 0 : 10 }}
          >
            An activation email has been sent to
          </Headline>
          <Email>{props.email}</Email>

          <p style={{ textAlign: 'center', maxWidth: 278 }}>
            Activate your account now to make sure people can see your profile!
          </p>

          <ButtonContainer>
            <JoinActionButton
              buttonType="primary"
              label="Open Email App"
              isDisabled={props.isSending}
              onClick={() => {
                props.handleToggleOpenStatus();
                window.open(`mailto:${props.email}`);
              }}
              customStyle={{ marginBottom: 0, width: '100%' }}
            />
            <JoinActionButton
              buttonType="cancel"
              label="Change Email"
              onClick={() => {
                props.handleToggleOpenStatus();
                goTo('/settings/email-password');
              }}
              customStyle={{ marginBottom: 10, width: '100%', marginTop: 10 }}
            />
            <JoinSkipButton
              label="Skip for now"
              customStyle={{ fontSize: 14, color: '#595959' }}
              onClick={() => props.handleToggleOpenStatus()}
            />
            <DashlineSeparator style={{ width: '100%' }} />
          </ButtonContainer>

          <FooterContainer>
            <FooterLabel>{`Didn't get the email?`}</FooterLabel>
            <ul style={{ paddingLeft: IsMobileViewPort ? 7 : 'unset', marginTop: IsMobileViewPort ? 10 : 16 }}>
              <FooterList> Check your Junk/Spam folder</FooterList>
              <FooterList> Ensure your email is correct</FooterList>
              <FooterList> Email can take a few minutes to deliver</FooterList>
              <FooterList>
                Check out our{' '}
                <FooterFAQLink href={`${MasterConfig.FULL_URL}/faq`} target="_blank" rel="noreferrer">
                  FAQ
                </FooterFAQLink>
              </FooterList>
            </ul>
          </FooterContainer>
        </EmailConfirmContainer>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default EmailConfirmV2;
