import styled from '@emotion/styled';
import React from 'react';
import { isDarkMode, addCommas } from '../../../../../utils/helpers';
import { color, textColor, dimensions } from '../../../../../style';
import PackageRadioButton from '../../../../../atoms/PackageRadioButton';
import { handlePaymentVysionEvent } from '../../../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../../../utils/vysion';
import { PaymentClickVysionEventsEnum } from '../../../../../utils/vysion/payment/click';

const isMobile = true;

const creditStyle = (credit, type = 'borderortext'): string => {
  switch (parseInt(credit)) {
    case 1000:
      return '#E04455';
    case 100:
      return '#24A83A';
    case 500:
      return '#2B8FD7';
    default:
      return type !== 'icon' ? '#DF1019' : color.BRAND_2;
  }
};

const creditBG = (credit): string => {
  switch (parseInt(credit)) {
    case 500:
      return isDarkMode()
        ? 'linear-gradient(180deg, #000000 62%, rgba(234, 244, 251, 0.3) 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #EAF4FB 100%)';
    case 100:
      return isDarkMode()
        ? 'linear-gradient(180deg, #000000 62%, rgba(36, 168, 58, 0.3) 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #EDFBEB 100%)';
    case 1000:
      return isDarkMode()
        ? 'linear-gradient(180deg, #000000 62%, rgba(224, 68, 85, 0.3) 100%)'
        : 'linear-gradient(180deg, #FFFFFF 0%, #FBF0F1 100%)';
    default:
      return '#FFFFFF';
  }
};

const Card = styled('div')(
  ({ newBilling, credits }: any) => ({
    width: 95,
    marginTop: 'auto !important',
    cursor: 'pointer',
    borderRadius: 8,
    background: `${creditBG(credits)}`,
    border: `1px solid ${creditStyle(credits)}`,
    textAlign: newBilling ? 'left' : 'center',
    flex: 1,
    position: 'relative',
    lineHeight: '30px',
    margin: isMobile ? '0 5px 20px 0' : 0,
    padding: isMobile ? 5 : 20,
    borderTop: `5px solid ${creditStyle(credits)} !important`,
    '&:nth-of-type(1)': {
      marginLeft: '0px',
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
    [dimensions.SCREEN_MAX_SM]: {
      width: 85,
    },
    [dimensions.SCREEN_MAX_MD_2]: {
      width: 'auto',
    },
  }),
  ({ recommended, isSpecialPackage, vip, selectionOff }) => ({
    padding:
      recommended && !isSpecialPackage && selectionOff && !vip
        ? '52px 20px 20px'
        : (isSpecialPackage && vip) || isMobile
        ? 10
        : 20,
  }),
  ({ selected, credits }) => ({
    boxShadow: selected ? `0 0 3px ${creditStyle(credits)}` : 'unset',
  })
);

const CardContainer = styled('div')({
  padding: 0,
  margin: '0 5px',
  position: 'relative',
  flex: '1 1 0%',
});

const CreditsPerCurrency = styled('div')`
  font-size: 16px;
  font-weight: 700;
  color: #595959;
  line-height: 18px;
`;

const CreditContainer = styled('div')({
  paddingTop: 0,
});

const CreditsTitle = styled('div')(({ credits }: any) => ({
  fontSize: isMobile ? 20 : 30,
  fontWeight: 600,
  color: `${creditStyle(credits)}`,
  textAlign: 'center',
  paddingTop: 10,
  lineHeight: '18px',
}));

const Amount = styled('div')({
  fontSize: 13,
  fontWeight: 'bold',
  ...textColor.DARK,
  textAlign: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  lineHeight: '20px',
});

const CreditInfo = styled('div')({
  padding: '10px 0',
  fontSize: isMobile ? 12 : 16,
  ...textColor.DARK,
  fontWeight: 400,
  textAlign: 'center',
  lineHeight: 'normal',
  borderBottom: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
  borderTop: isDarkMode() ? '1px dashed #6c6c6c' : '1px dashed rgba(14, 29, 64, 0.2)',
  margin: '0 auto',
  marginTop: 20,
});

const CreditsText = styled('span')({
  ...textColor.DARK,
  fontWeight: 'normal',
  fontSize: isMobile ? 12 : 14,
  lineHeight: '12px',
});

const CreditsSmallText = styled('span')({
  ...textColor.DARK,
  fontWeight: 'normal',
  fontSize: 12,
  lineHeight: '12px',
});

const PackageContainer = styled('div')({});
const ButtomContainer = styled('div')({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
});

const PackageListContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PaymentSettingsProps {
  packages: any;
  selectedPackage: any;
  setSelectedPackage: any;
}

const AutoTopupPackages = ({ packages, selectedPackage, setSelectedPackage }: PaymentSettingsProps): any => {
  const renderCreditsPerCurrency = (credits, price, currencyCode = '$') => {
    let creditsText;
    const newPrice = (price / credits).toFixed(2);

    switch (parseInt(credits, 10)) {
      case 500:
      case 100:
      case 1000:
        creditsText = `${currencyCode}${newPrice}${isMobile ? '' : ' / Credit'}`;
        break;
      default:
        creditsText = '';
    }
    return (() => {
      if (creditsText) {
        return <CreditsPerCurrency>{creditsText}</CreditsPerCurrency>;
      }
    })();
  };

  const handleSetSelectedPackage = (identifier: string) => {
    setSelectedPackage(identifier);
    const findSelectedPackage = packages.find(pkg => pkg.identifier === identifier);

    if (findSelectedPackage) {
      handlePaymentVysionEvent({
        action: VYSION_ACTIONS.CLICK,
        event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_PACKAGE,
        newEventName: `accountMenu-paymentSettings-creditPackage-${findSelectedPackage.credits}Credits`,
        extraInfo: `${findSelectedPackage.credits} Credits`,
      });
    }
  };

  const renderPackages = () => {
    return packages.map((pkg, index) => {
      const currecyCode = String.fromCharCode(pkg.currency?.data.label_unicode);

      return (
        <CardContainer key={`package-${index}`}>
          <Card credits={pkg.credits} onClick={() => handleSetSelectedPackage(pkg.identifier)}>
            <PackageContainer>
              <CreditContainer>
                <CreditsTitle credits={pkg.credits}>
                  <b style={{ fontWeight: 'bolder' }}>{addCommas(pkg.credits)}</b> {isMobile && <br />}
                  <CreditsText style={isMobile ? { ...textColor.DARK, fontWeight: 'normal', fontSize: 14 } : {}}>
                    Credits
                  </CreditsText>
                </CreditsTitle>
              </CreditContainer>
              <CreditInfo>
                {renderCreditsPerCurrency(pkg.credits, pkg.price, currecyCode)}{' '}
                {isMobile && <CreditsSmallText>per credit</CreditsSmallText>}
              </CreditInfo>
              <Amount style={{ fontWeight: 'normal', fontSize: 16, display: 'inline-block', width: 'max-content' }}>
                {currecyCode}
                {parseInt(pkg.price).toFixed()} total
              </Amount>
            </PackageContainer>
          </Card>
          <ButtomContainer>
            <PackageRadioButton
              label={``}
              labelId={`recurring_${pkg.identifier}`}
              checked={pkg.identifier === selectedPackage}
              id={`recurring_${pkg.identifier}`}
              name={`recurring_package`}
              onRadioChange={() => handleSetSelectedPackage(pkg.identifier)}
              style={{ cursor: 'pointer' }}
            />
          </ButtomContainer>
        </CardContainer>
      );
    });
  };

  return <PackageListContainer data-test-id="auto-toup-package-list">{renderPackages()}</PackageListContainer>;
};

export default AutoTopupPackages;
