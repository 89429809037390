import React, { useState, useEffect } from 'react';
import BillingOrder from '../screens/billing/BillingOrder';
import { connect } from 'react-redux';
import {
  postBillingPayment,
  getBillingPackages,
  getKountDetails,
  getPaymentToken,
  deleteSavedToken,
  getBillingDescriptors,
  postVisitLogs,
} from '../actions/billingActions';
import { killLoading, setLoading, clearErrors } from '../actions/commonActions';
import KountLogo from '../atoms/billing/KountLogo';
import queryString from 'query-string';
import { getBrowserDetails } from '../utils/helpers';
import { useComponentWillMount, useStateCallback } from '../utils/customHooks';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import DDCContainer from './DDCContainer';
import { useLocation, useNavigate } from 'react-router-dom';

// Function to map the errors that are passed down in this.props.errors to the expected error object.
const mapErrorsToStatus = errors => {
  const statuses = {};

  Object.keys(errors).forEach(key => {
    if (typeof errors[key] !== 'object' || typeof errors[key][0] === 'undefined') return;

    statuses[key] = {
      status: 'error',
      message: errors[key][0],
    };
  });

  return statuses;
};

const findPackageIdentifier = (packages, identifier) => {
  return packages.find(item => item.identifier === identifier);
};

const verifyPackage = (evaluator, package1, package2) => {
  return evaluator ? package1 : package2;
};

const BillingOrderContainer = props => {
  const [processingPayment, setProcessingPayment] = useStateCallback(false);
  const [timestampIdentifier, setMyTimestampIdentifier] = useState('');
  const [isStackedVariant, setIsStackedVariant] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [isVisitLogged, setIsVisitLogged] = useState(false);

  const {
    profile,
    logPageVisit,
    paymentToken,
    errors,
    packages,
    kountDetails,
    descriptors,
    removeSavedToken,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const queryStringParsed = queryString.parse(location.search);
  const savedCard = (paymentToken && paymentToken[0]) || null;
  const defaultPackage =
    typeof packages === 'object' && packages.filter(item => item.promotional === true).length > 0
      ? verifyPackage(
          findPackageIdentifier(packages, 'generous_cyber_monday_2017_2'),
          findPackageIdentifier(packages, 'generous_cyber_monday_2017_2'),
          findPackageIdentifier(packages, 'generous_pp_special_2')
        )
      : packages[1];
  const defaultPackageId = packageId || (typeof defaultPackage === 'object' && defaultPackage.identifier) || null;
  const statuses = mapErrorsToStatus(errors);
  const myProfile = profile.profile;

  useComponentWillMount(() => {
    props.getBillingPackages();
    props.getKountDetails();
    props.getBillingDescriptors();
    props.getPaymentToken();
    props.clearErrors();
  });

  useEffect(() => {
    // redirect to packages with url parameters
    window.location.replace('/packages' + location.search);

    return () => {
      // refresh Global Notification on page unload
      props.refreshGlobalNotification();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeStampIdentifier();
    setVariantLayout();
    setShowPayment(queryStringParsed.step === '2');
    setPackageId(queryStringParsed.package_id || null);

    if (myProfile && myProfile.data && myProfile.data.account_type === 'Attractive') {
      navigate('/dashboard');
    }

    if (!isVisitLogged) {
      setTimeout(() => {
        logPageVisit('billing', 1);
        setIsVisitLogged(true);
      }, 500);
    }
  }, [isVisitLogged, logPageVisit, myProfile, queryStringParsed]);

  useEffect(() => {
    const newCard = document.getElementById('new_card');

    if (processingPayment && errors && typeof errors.data !== 'undefined') {
      setProcessingPayment(false);

      if (savedCard && newCard !== null) {
        newCard.click();
      }
    }
  }, [errors, processingPayment, savedCard, setProcessingPayment]);

  const setVariantLayout = () => {
    const stackedVariant = getBrowserDetails().isMobile;
    setIsStackedVariant(stackedVariant);
  };

  const setTimeStampIdentifier = () => {
    const dateTime = Date.now();
    const timeIdentifier = Math.floor(dateTime / 1000);
    setMyTimestampIdentifier(timeIdentifier);
  };

  const handlePaymentSubmit = data => {
    setProcessingPayment(true);

    const dataKount = data.use2pay_card_number
      ? {
          ...data,
          use2pay_card_number: data.use2pay_card_number.replace(/\s/g, ''),
          kount_identifier: timestampIdentifier,
        }
      : {
          ...data,
          kount_identifier: timestampIdentifier,
        };

    props.postPayment(dataKount, navigate).then(result => setProcessingPayment(result, () => setTimeStampIdentifier()));
  };

  if (queryStringParsed.package_id) {
    return null;
  }

  if (typeof packages !== 'object') {
    return false;
  }

  return (
    <React.Fragment>
      <BillingOrder
        handleSubmit={handlePaymentSubmit}
        handleDeleteSavedCard={removeSavedToken}
        defaultPackageId={defaultPackageId}
        packages={packages}
        descriptors={descriptors}
        paymentToken={paymentToken}
        savedCard={savedCard}
        statuses={statuses}
        processingPayment={processingPayment && (!errors.data || !errors.data.error_code)}
        defaultCountry={'US'}
        stackedVariant={isStackedVariant}
        showPayment={showPayment}
        errorMessage={errors.error}
        {...props}
      />
      <KountLogo details={kountDetails} timestamp={timestampIdentifier} />
      <DDCContainer location={location} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    packages: state.billing.packages,
    kountDetails: state.billing.kountDetails,
    descriptors: state.meta.descriptors,
    paymentToken: state.billing.paymentToken,
    loading: state.common.isFetching,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postPayment: (formData, navigate) => {
      dispatch(clearErrors());
      // loading state
      // dispatch(setLoading());
      // send payment for processing.
      return dispatch(postBillingPayment(formData, navigate));
    },
    removeSavedToken: tokenId => {
      dispatch(setLoading());
      dispatch(deleteSavedToken(tokenId));
    },
    getBillingPackages: (order = 'desc') => {
      dispatch(getBillingPackages(order));
    },
    getKountDetails: () => {
      dispatch(getKountDetails());
    },
    getPaymentToken: () => {
      dispatch(getPaymentToken());
    },
    getBillingDescriptors: () => {
      dispatch(getBillingDescriptors());
    },
    killLoading: () => {
      dispatch(killLoading());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    logPageVisit: (type, step) => {
      dispatch(postVisitLogs(type, step));
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingOrderContainer);
