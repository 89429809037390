import React from 'react';
import _ from 'lodash';
import BlankSlate from '../../blocks/BlankSlate';
import SkeletonLoader from '../../../atoms/SkeletonLoader';
import Button from '../../../atoms/buttons/Button';
import FavoritesProfileGridView from './FavoritesProfileGridView';
import FavoritesProfileListView from './FavoritesProfileListView';
import GridViewSlider, { SliderSource, SliderSourceLSKey } from '../../common/GridViewSlider';
import { getLocalItem } from '../../../common';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';

interface FavoritedListProps {
  isFetching: any;
  user: any;
  toggle: any;
  noResultsTitle: any;
  noResultsMsg: any;
  noResultsImg: any;
  favorites: any;
  page: any;
  screenSizeIsMobile: any;
  viewType: any;
  ignoreUser: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  ignoreOffer: any;
  unlockMessage(): void;
  pageTitle?: any;
  enableSlider?: boolean;
  sliderLoadMore?: () => void;
}

const FavoritedList = ({
  isFetching,
  user,
  toggle,
  noResultsTitle,
  noResultsMsg,
  noResultsImg,
  favorites,
  page,
  screenSizeIsMobile,
  viewType,
  ignoreUser,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  ignoreOffer,
  unlockMessage,
  pageTitle,
  enableSlider,
  sliderLoadMore = () => undefined,
}: FavoritedListProps): any => {
  // loop through each mail item.
  if (_.isEmpty(favorites) && isFetching)
    return (
      <>
        <TrackLoadingToMixpanel page={`Interests - ${pageTitle}`} />
        <SkeletonLoader width="100%" />
      </>
    );

  const getMatched = fav => {
    if (page === 'viewed_me') {
      return fav.is_matched;
    }

    const member = fav.other_account.data;

    if (page === 'from') {
      return fav.favorited_me;
    }

    return member.is_favorite && fav.favorited_me;
  };

  if (!_.isEmpty(favorites)) {
    return (
      <GridViewSlider
        source={SliderSource.INTERESTS}
        enableSlider={enableSlider ?? false}
        sliderLoadMore={sliderLoadMore}
      >
        {Object.keys(favorites).map(key => {
          const member = favorites[key].other_account.data;
          const offer = favorites[key].offer;
          const isMatched = getMatched(favorites[key]);
          const isMyFavorite = page === 'viewed_me' ? favorites[key].is_favorited : member.is_favorite;
          let isRead = page === 'viewed_me' ? favorites[key].is_read : true;
          const isFavoritedMe = favorites[key].favorited_me;

          const storageHashIds = JSON.parse(getLocalItem(SliderSourceLSKey.INTERESTS_LS)) || [];
          if (storageHashIds.includes(member.hash_id) && page === 'viewed_me') {
            isRead = true;
          }

          if (viewType === 'list') {
            return (
              <FavoritesProfileListView
                key={key}
                favKey={key}
                profile={member}
                isFavorite={isMyFavorite}
                isFavoritedMe={isFavoritedMe}
                isMatched={isMatched}
                offer={offer}
                title={member.username}
                hashId={member.hash_id}
                toggle={toggle}
                page={page}
                screenSizeIsMobile={screenSizeIsMobile}
                timestamp={
                  typeof favorites[key].created_at === 'string'
                    ? favorites[key].created_at
                    : favorites[key].created_at.date
                }
                user={user}
                ignoreUser={ignoreUser}
                createNewOffer={createNewOffer}
                sendSuggestion={sendSuggestion}
                clearErrors={clearErrors}
                errors={errors}
                showWarn={showWarn}
                acceptOffer={acceptOffer}
                counterOffer={counterOffer}
                ignoreOffer={ignoreOffer}
                unlockMessage={unlockMessage}
                pageTitle={pageTitle}
              />
            );
          }

          return (
            <FavoritesProfileGridView
              key={key}
              favKey={key}
              profile={member}
              isFavorite={isMyFavorite}
              isFavoritedMe={isFavoritedMe}
              isMatched={isMatched}
              offer={offer}
              title={member.username}
              hashId={member.hash_id}
              isRead={isRead}
              toggle={toggle}
              page={page}
              screenSizeIsMobile={screenSizeIsMobile}
              timestamp={
                typeof favorites[key].created_at === 'string'
                  ? favorites[key].created_at
                  : favorites[key].created_at.date
              }
              user={user}
              ignoreUser={ignoreUser}
              createNewOffer={createNewOffer}
              sendSuggestion={sendSuggestion}
              clearErrors={clearErrors}
              errors={errors}
              showWarn={showWarn}
              acceptOffer={acceptOffer}
              counterOffer={counterOffer}
              ignoreOffer={ignoreOffer}
              unlockMessage={unlockMessage}
              pageTitle={pageTitle}
              enableSlider={enableSlider}
            />
          );
        })}
      </GridViewSlider>
    );
  } else if (_.isEmpty(favorites) && !isFetching) {
    const actionBtn = (
      <Button buttonType="primary" to="/search">
        Find a date
      </Button>
    );
    return <BlankSlate img={noResultsImg} title={noResultsTitle} message={noResultsMsg} action={actionBtn} />;
  }
};

export default FavoritedList;
