import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const StyledPackageListContainer = styled('div')(
  {
    margin: '0 auto',
  },
  ({ maxWidth }) =>
    maxWidth && {
      maxWidth: maxWidth,
    }
);

const PackageListContainer = (props: any): ReactElement => {
  return <StyledPackageListContainer {...props}>{props.children}</StyledPackageListContainer>;
};

export default PackageListContainer;
