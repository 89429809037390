/**
 * @Author: Daniel Rhees <Daniel>
 * @Date:   2017-05-30T15:41:44-07:00
 * @Email:  daniel@reflexmedia.com
 * @Last modified by:   Daniel
 * @Last modified time: 2017-06-12T15:34:28-07:00
 */

import styled from '@emotion/styled';
import { dimensions } from '../../style';

export const Content = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    display: 'flex',
    flexFlow: 'row',
    minWidth: 0,
    flex: 1,
  },
});

export const Aside = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    marginRight: 20,
    width: 210,
  },
});

export const PrimaryContent = styled('div')({
  [dimensions.SCREEN_MIN_XS]: {
    minHeight: 175,
  },
  [dimensions.SCREEN_MIN_MD]: {
    minWidth: 0,
    flex: 1,
  },
});
