import React, { ReactElement } from 'react';
import StepDoneIcon from '../../../atoms/icons/StepDoneIcon';
import StepOneIcon from '../../../atoms/icons/StepOneIcon';
import { BodyWrapper, StepLabel } from '../styledElements';
import { DashlineSeparator } from '../../join/styledElements';
import JoinActionButton from '../../join/JoinActionButton';
import JoinSkipButton from '../../join/JoinSkipButton';

interface IProfileVerifiedEmailIncompleteStepProps {
  setSkip: () => any;
  handleChangeEmail: () => any;
  children: React.ReactNode;
}

const ProfileVerifiedEmailIncompleteStep = ({
  setSkip,
  handleChangeEmail,
  children,
}: IProfileVerifiedEmailIncompleteStepProps): ReactElement => {
  return (
    <BodyWrapper>
      <div style={{ display: 'flex' }}>
        <StepDoneIcon />
        <StepLabel cross>All profile info filled out</StepLabel>
      </div>
      <DashlineSeparator />
      <div style={{ display: 'flex' }}>
        <StepOneIcon />
        <StepLabel>Verify your email address</StepLabel>
      </div>
      <div
        style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}
      >
        {children}
        <JoinActionButton
          buttonType="cancel"
          label="Change Email"
          onClick={handleChangeEmail}
          customStyle={{ marginBottom: 10 }}
        />
        <JoinSkipButton label="Skip for now" onClick={setSkip} />
      </div>
    </BodyWrapper>
  );
};

export default ProfileVerifiedEmailIncompleteStep;
