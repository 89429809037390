import React, { useState, useEffect, ReactElement } from 'react';
import PropTypes from 'prop-types';
import DataLayer from '../utils/dataLayer';

const ExperimentInjector = (props: any): ReactElement => {
  const { testId, prodId } = props;
  const [experimentId, setExperimentId] = useState(null);

  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    const name = env.indexOf('prod') > -1 || env.indexOf('gold') > -1 ? prodId : testId;

    if (name) {
      new DataLayer().gtag('event', 'optimize.callback', {
        name,
        callback: value => {
          setExperimentId(value);
        },
      });
    }
  }, [prodId, testId]);

  return React.cloneElement(props.children, { experimentId });
};

ExperimentInjector.propTypes = {
  testId: PropTypes.string.isRequired,
  prodId: PropTypes.string.isRequired,
};

export default ExperimentInjector;
