import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { type, borders, textColor } from '../style';
import { tsCustomFormat } from '../common';

const CreditItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  ...type.MD,
  borderBottom: borders.DEFAULT,
  ':last-child': {
    borderBottom: 'none',
  },
  padding: '12px 0',
});

const CreditDate = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexBasis: 0,
  flexFlow: 'column nowrap',
  textAlign: 'center',
  fontWeight: 600,
});

const CreditDescription = styled('div')({
  marginRight: '12px',
  marginLeft: '12px',
  display: 'flex',
  flexGrow: 6,
  flexBasis: 0,
});

const CreditSpent = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexBasis: 0,
  textAlign: 'right',
  whiteSpace: 'nowrap',
  ...textColor.BRAND,
});

interface CreditHistoryItemProps {
  createdAt: any;
  description: any;
  creditType: any;
  credits: any;
  hideCredits: any;
}

const CreditHistoryItem = ({
  createdAt,
  description,
  creditType,
  credits,
  hideCredits,
}: CreditHistoryItemProps): ReactElement => {
  return (
    <CreditItem className="css-credit-history-item">
      <CreditDate>
        <div>{tsCustomFormat(createdAt, 'MMM')}</div>
        <div>{tsCustomFormat(createdAt, 'DD')}</div>
      </CreditDate>
      <CreditDescription>{description}</CreditDescription>
      <CreditSpent>
        {!hideCredits && (
          <span>
            {creditType === 'spent' ? '-' : ''} {credits} credits
          </span>
        )}
      </CreditSpent>
    </CreditItem>
  );
};

CreditHistoryItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  creditType: PropTypes.string,
  credits: PropTypes.number,
  hideCredits: PropTypes.bool,
};

export default CreditHistoryItem;
