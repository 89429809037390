import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

const NotificationContainer = styled('div')(
  {
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#76E9E9',
    position: 'fixed',
    height: 60,
    zIndex: 99999,
    '& a': {
      textDecoration: 'underline',
    },
  },
  ({ showNotification }) => ({
    display: showNotification ? 'block' : 'none',
  })
);

export const SiteNotification = (): ReactElement => {
  const [showNotification, setShowNotification] = useState(false);
  const container = document.getElementsByTagName('body')[0];
  const element = document.createElement('div');

  useEffect(() => {
    const gtmLoad = setInterval(() => {
      const { google_tag_manager } = window;
      if (google_tag_manager && google_tag_manager.dataLayer && google_tag_manager.dataLayer.gtmLoad) {
        clearInterval(gtmLoad);
        initialize();
      }
    }, 100);
  }, [initialize]);

  const initialize = useCallback(() => {
    const { startDate, endDate } = window;

    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (start <= end && end >= new Date().getTime()) {
      setShowNotification(true);
      container.prepend(this.element);

      if (document.getElementById('site-header')) {
        document.getElementById('site-header').style.position = 'relative';
      }
    }
  }, [container]);

  const hideNotification = () => {
    setShowNotification(false);

    const siteHeader = document.getElementById('site-header');
    if (siteHeader && siteHeader.length > 0) {
      siteHeader.style.position = 'fixed';
    }
  };

  if (!showNotification) {
    return null;
  }

  return createPortal(
    <NotificationContainer showNotification={showNotification}>
      <div style={{ padding: '0 15px' }} dangerouslySetInnerHTML={{ __html: window.content }}></div>
      <div
        style={{
          position: 'absolute',
          right: 15,
          top: 20,
          cursor: 'pointer',
        }}
        onClick={hideNotification}
      >
        <strong>X</strong>
      </div>
    </NotificationContainer>,
    element
  );
};
