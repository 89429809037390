import styled from '@emotion/styled';
import { dimensions } from '../../style';

const PageWithFooter = styled('div')({
  paddingBottom: '135px',
  [dimensions.SCREEN_MAX_MD]: {
    paddingBottom: '340px',
  },
});

export default PageWithFooter;
