import { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { VideoTypeFile } from '../modules/gallery/constants';
import { isIOS, osVersion } from 'react-device-detect';

type VideoFile = VideoTypeFile;

const useCapturedThumbnails = (files: VideoFile[]): [VideoFile[], boolean] => {
  const [processedVideos, setProcessedVideos] = useState<VideoFile[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);

  useEffect(() => {
    const generateThumbnails = async () => {
      const promises = [];
      let index = 0;

      for (const file of files) {
        if (!file) {
          continue;
        }

        promises.push(
          new Promise(resolve => {
            const video = document.createElement('video');
            const canvas = document.createElement('canvas');

            videoRefs.current.push(video);
            canvasRefs.current.push(canvas);

            getVideoThumbnail(video, canvas, file, 1)
              .then(thumbnail => {
                resolve({
                  index,
                  raw: file,
                  private: false,
                  temp_thumbnail: thumbnail,
                });
                index++;
              })
              .catch(error => {
                Sentry.captureException(error, {
                  tags: { component: 'useCapturedThumbnails', action: 'generateThumbnails' },
                });
                resolve(null);
              });

            // this will handle to preload the video so that it will capture the preview screenshot properly
            video.preload = 'auto';
            video.load();

            // force to play the video using button element
            const playButton = document.createElement('button');
            playButton.textContent = 'Play Video';
            playButton.addEventListener('click', () => {
              video.play();
            });
            document.body.appendChild(playButton);
          }) as never
        );
      }

      await Promise.all(promises)
        .then(data => {
          setProcessedVideos(data);
          setIsLoading(false);
        })
        .catch(() => {
          setProcessedVideos([]);
          setIsLoading(false);
        });
    };

    const getVideoThumbnail = (
      videoPlayer: HTMLVideoElement,
      canvas: HTMLCanvasElement,
      file: VideoFile,
      seekTo = 0.0
    ): Promise<string> => {
      return new Promise((resolve, reject) => {
        try {
          if (isIOS && parseFloat(osVersion).toFixed(2) >= 17.4 && parseFloat(osVersion).toFixed(2) < 17.5) {
            // Show default video controls.
            videoPlayer.controls = true;
            // Set child in source attribute
            const videoSource1 = document.createElement('source');
            videoSource1.type = 'video/mp4';
            videoSource1.src = URL.createObjectURL(file);
            videoPlayer.appendChild(videoSource1);
          } else {
            // load the file to a video player
            videoPlayer.src = URL.createObjectURL(file);
          }
          videoPlayer.load();
          videoPlayer.addEventListener('error', () => {
            reject(`error when loading video file`);
          });
          // load metadata of the video to get video duration and dimensions
          videoPlayer.addEventListener('loadedmetadata', () => {
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
              videoPlayer.currentTime = seekTo;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener('seeked', () => {
              // define a canvas to have the same dimension as the video
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              // draw the video frame to canvas
              const ctx = canvas.getContext('2d');
              ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              // return the canvas image as a blob then convert it to base 64
              ctx?.canvas.toBlob(
                blob => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob as Blob);
                  reader.onloadend = function() {
                    const base64data = reader.result;
                    resolve(base64data as string);
                  };
                },
                'image/jpeg',
                1
              );
            });
          });
        } catch (error) {
          reject(error);
        }
      });
    };

    if (files.length > 0) {
      generateThumbnails();
    } else {
      setIsLoading(false);
    }
  }, [files]);

  return [processedVideos, isLoading];
};

export default useCapturedThumbnails;
