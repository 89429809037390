import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { color, type, borders, buttonTheme } from '../../style';
import { ButtonV2 } from 'swui';

const defaultButtonStyle = `
    border: ${borders.DEFAULT};
    color: ${buttonTheme.darkReverse};
    overflow: hidden;
    :hover {
      border: ${borders.DEFAULT};
      background-color: ${buttonTheme.GreyAndWhite};
      border-color: rgb(170, 170, 170);
      color: ${buttonTheme.DarkButton};
    };
    :focus {
      background-color: ${buttonTheme.GreyAndWhite};
      color: ${buttonTheme.DarkButton};
      border: solid 1px rgb(19, 131, 210) !important;
    };
    :active {
      border-color: rgb(21, 86, 133);
      color: rgb(21, 86, 133);
      box-shadow: none;
      background-color: unset;
    };
    :disabled {
      border-color: rgb(224, 224, 224);
      color: rgba(180,180,180,0.3);
      cursor: default;
    }
`;

const buttonStyle = {
  normal: defaultButtonStyle,
  primary: `
    overflow: hidden;
    background-color: #2490de;
    color: ${buttonTheme.GreyAndWhite};
    border-color: #2490de;
    &:hover {
      color: #fff;
      background-color: ${buttonTheme.hoverFocus};
      border-color: rgb(28, 115, 177);
    };
    &:focus {
      color: #FFF;
      background-color: ${buttonTheme.hoverFocus};
      border-color: rgb(28, 115, 177);
    };
    &:active {
      color: #FFF;
      background-color: ${buttonTheme.active};
      border-color: rgb(21, 86, 133);
    };
    &:disabled {
      color: #FFF;
      background-color: ${buttonTheme.disabled} !important;
      border-color: rgb(167, 210, 241);
      cursor: default;
    };
    `,
  chromeless: `
    overflow: hidden;
    border-width: 0px;
    border-color: transparent;
    padding: 0;
    &:active {
      box-shadow: none;
      background-color: unset;
    };
  `,
  link: `
    color: ${color.BRAND_1};
    border-style: none;
    &:active {
      box-shadow: none;
    };
  `,
  success: `
    overflow: hidden;
    background-color: #32CD32;
    color: #FFF;
    border-color: #32CD32;
    &:hover {
      color: #fff;
      background-color: #228B22;
      border-color: #228B22;
    };
    &:focus {
      color: #FFF;
      background-color: #228B22;
      border-color: #228B22;
    };
    &:active {
      color: #FFF;
      background-color: #228B22;
      border-color: #228B22;
    };
    &:disabled {
      color: #cbcbcb;
      background-color: #228B22;
      border-color: #228B22;
      cursor: default;
    };
    `,
  error: `
    overflow: hidden;
    background-color: #bc2028;
    color: #FFF;
    border-color: #bc2028;
    &:hover {
      color: #fff;
      background-color: #bc2028;
      border-color: #bc2028;
    };
    &:focus {
      color: #FFF;
      background-color: #bc2028;
      border-color: #d56b6b;
    };
    &:active {
      color: #FFF;
      background-color: #bc2028;
      border-color: #d56b6b;
    };
    &:disabled {
      color: #F8E9EA;
      background-color: #d56b6b;
      border-color: #d56b6b;
      cursor: default;
    };
    `,
  cancel: `
    overflow: hidden;
    background-color: #ffffff;
    color: #2490de;
    border-color: #2490de;
    &:hover {
      color: #ffffff;
      background-color: #2490de;
      border-color: #2490de;
    };
    &:focus {
      color: #ffffff;
      background-color: #2490de;
      border-color: #2490de;
    };
    &:active {
      color: #ffffff;
      background-color: #2490de;
      border-color: #2490de;
    };
    &:disabled {
      color: rgb(36, 144, 222, 0.5);
      background-color: #ffffff;
      border-color: rgb(36, 144, 222, 0.5);
      cursor: default;
    };
  `,
  secondaryBlue: `
    overflow: hidden;
    background-color: ${buttonTheme.secondaryBlue.background};
    color: ${buttonTheme.secondaryBlue.text};
    border-color:  ${buttonTheme.secondaryBlue.border};
    &:hover {
      color:  ${buttonTheme.secondaryBlue.hover.text};
      background-color: ${buttonTheme.secondaryBlue.hover.background};
      border-color: ${buttonTheme.secondaryBlue.hover.border};
    };
    &:focus {
      color:  ${buttonTheme.secondaryBlue.hover.text};
      background-color: ${buttonTheme.secondaryBlue.hover.background};
      border-color: ${buttonTheme.secondaryBlue.hover.border};
    };
    &:active {
      color:  ${buttonTheme.secondaryBlue.active.text};
      background-color: ${buttonTheme.secondaryBlue.active.background};
      border-color: ${buttonTheme.secondaryBlue.active.border};
    };
    &:disabled {
      color:  ${buttonTheme.secondaryBlue.disabled.text};
      background-color: ${buttonTheme.secondaryBlue.disabled.background};
      border-color: ${buttonTheme.secondaryBlue.disabled.border};
    };
    `,
  deactivate: `
    overflow: hidden;
    background-color: ${buttonTheme.secondaryRed.background};
    color: ${buttonTheme.secondaryRed.text};
    border-color:  ${buttonTheme.secondaryRed.border};
    &:hover {
      color:  ${buttonTheme.secondaryRed.hover.text};
      background-color: ${buttonTheme.secondaryRed.hover.background};
      border-color: ${buttonTheme.secondaryRed.hover.border};
    };
    &:focus {
      color:  ${buttonTheme.secondaryRed.hover.text};
      background-color: ${buttonTheme.secondaryRed.hover.background};
      border-color: ${buttonTheme.secondaryRed.hover.border};
    };
    &:active {
      color:  ${buttonTheme.secondaryRed.active.text};
      background-color: ${buttonTheme.secondaryRed.active.background};
      border-color: ${buttonTheme.secondaryRed.active.border};
    };
    &:disabled {
      color:  ${buttonTheme.secondaryRed.disabled.text};
      background-color: ${buttonTheme.secondaryRed.disabled.background};
      border-color: ${buttonTheme.secondaryRed.disabled.border};
    };
    `,
  primaryRed: `
    overflow: hidden;
    background-color: ${buttonTheme.primaryRed.background};
    color: ${buttonTheme.primaryRed.text};
    border-color:  ${buttonTheme.primaryRed.border};
    &:hover {
      color:  ${buttonTheme.primaryRed.hover.text} !important;
      background-color: ${buttonTheme.primaryRed.hover.background};
      border-color: ${buttonTheme.primaryRed.hover.border};
    };
    &:focus {
      color:  ${buttonTheme.primaryRed.hover.text} !important;
      background-color: ${buttonTheme.primaryRed.hover.background};
      border-color: ${buttonTheme.primaryRed.hover.border};
    };
    &:active {
      color:  ${buttonTheme.primaryRed.active.text} !important;
      background-color: ${buttonTheme.primaryRed.active.background};
      border-color: ${buttonTheme.primaryRed.active.border};
    };
    &:disabled {
      color:  ${buttonTheme.primaryRed.disabled.text} !important;
      background-color: ${buttonTheme.primaryRed.disabled.background};
      border-color: ${buttonTheme.primaryRed.disabled.border};
    };
    `,
};

const buttonSize = {
  small: {
    borderRadius: '15px',
    fontSize: '12px',
    height: '30px',
    padding: '0 12px',
    ...type.SM,
  },
  medium: {
    borderRadius: '21px',
    height: '42px',
    padding: '0 16px',
    ...type.MD,
  },
  large: {
    borderRadius: '21px',
    fontSize: '18px',
    height: '50px',
    padding: '0 40px',
    ...type.LG,
  },
};

const StyledButton = styled('button')(
  {
    fontWeight: 600,
    borderStyle: 'none',
    backgroundColor: 'transparent',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderWidth: '1px',
    '&:focus': {
      outline: 'none',
    },
  },
  ({ buttontype }) => buttonStyle[buttontype],
  ({ size }) => buttonSize[size],
  ({ customStyle }) => ({ ...customStyle })
);

const fitStyledButton = {
  display: 'block',
  boxSizing: 'border-box',
  width: '100%',
};

const StyledButtonV2 = styled(ButtonV2)(
  {
    borderRadius: 21,
    height: 42,
  },
  ({ buttontype }) => buttonStyle[buttontype],
  ({ style }) => ({ ...style })
);

const Content = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ alignContent }) =>
    alignContent === 'left' && {
      justifyContent: 'flex-start',
    }
);

const IconContainer = styled('div')(
  {
    display: 'inline-flex',
    justifyContent: 'center',
  },
  ({ iconPosition }) =>
    iconPosition === 'right'
      ? {
          order: 2,
          marginLeft: 4,
        }
      : {
          marginRight: 4,
        },
  ({ noMargin }) => noMargin && { marginRight: 0 }
);

const ButtonText = styled('div')`
  display: inline-flex;
`;

interface ButtonProps {
  type?: 'button' | 'submit';
  buttonType?:
    | 'normal'
    | 'primary'
    | 'link'
    | 'chromeless'
    | 'success'
    | 'error'
    | 'cancel'
    | 'deactivate'
    | 'primaryBlue'
    | 'primaryRed';
  iconPosition?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
  fit?: boolean;
  icon?: any;
  to?: string;
  customStyle?: any;
  alignContent?: any;
  children?: any;
  isDefault?: any;
  buttonRef?: any;
  onClick?: any;
  buttonTextCustomStyle?: any;
  id?: string;
  disabled?: boolean;
}

const Button = (props: ButtonProps): ReactElement => {
  const {
    to,
    buttonType = 'default',
    size,
    fit,
    customStyle,
    icon,
    iconPosition,
    alignContent,
    children,
    isDefault,
    buttonRef,
    onClick,
    buttonTextCustomStyle,
    ...rest
  } = props;

  const button = (
    <StyledButton
      ref={buttonRef}
      buttontype={buttonType}
      size={size}
      style={fit ? fitStyledButton : {}}
      customStyle={customStyle}
      onClick={onClick}
      {...rest}
    >
      <Content alignContent={alignContent}>
        {icon && (
          <IconContainer iconPosition={iconPosition} noMargin={React.Children.count(children) === 0}>
            {icon}
          </IconContainer>
        )}
        <ButtonText>{children}</ButtonText>
      </Content>
    </StyledButton>
  );

  const buttonSWui = (
    <StyledButtonV2
      ref={buttonRef}
      data-swui-id={`swui-${buttonType}`}
      buttontype={buttonType}
      shape="curved"
      style={fit ? { ...customStyle, ...fitStyledButton } : customStyle}
      onClick={onClick}
      {...rest}
    >
      <Content alignContent={alignContent}>
        {icon && (
          <IconContainer iconPosition={iconPosition} noMargin={React.Children.count(children) === 0}>
            {icon}
          </IconContainer>
        )}
        <ButtonText style={buttonTextCustomStyle}>{children}</ButtonText>
      </Content>
    </StyledButtonV2>
  );

  const renderBtn = () => {
    return isDefault ? button : buttonSWui;
  };

  return <React.Fragment>{to ? <Link to={to}>{button}</Link> : renderBtn()}</React.Fragment>;
};

Button.defaultProps = {
  buttonType: 'normal',
  size: 'medium',
  iconPosition: 'left',
  alignContent: 'center',
  customStyle: {},
};

export default Button;
