import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { clearEverything } from '../actions/commonActions';
import Unsubscribe from '../components/pages/Unsubscribe';

const UnsubscribeContainer = ({ clearEverything }) => {
  useEffect(() => {
    clearEverything();
  }, [clearEverything]);

  return <Unsubscribe />;
};

const mapDispatchToProps = dispatch => {
  return {
    clearEverything: () => {
      dispatch(clearEverything());
    },
  };
};

export default connect(null, mapDispatchToProps)(UnsubscribeContainer);
