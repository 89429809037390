/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useRef, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails } from '../../../utils/helpers';
import TextArea from '../../../atoms/TextArea';
import { acceptOffer } from '../../../actions/profileTrackAction';
import OfferMessageIcon from './oneTapSwipeOffer/icons/OfferMessageIcon';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
  alignItems: 'center',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 26,
    letterSpacing: '0.52px',
    lineHeight: '26px',
    marginBottom: 21,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const MessageTextArea = styled(TextArea)({
  border: '1px solid #C4C4C4',
  boxShadow: 'inset 2px 2px 4px rgb(0 0 0 / 12%)',
  borderRadius: 6,
  marginTop: 20,
  padding: 10,
  overflow: 'unset',
});

const ButtonDiv = styled('div')({
  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 12,
    paddingTop: 12,
    width: '100%',
  },
});

interface OfferAcceptMessageV2Props {
  offerAmount: any;
  avatarSrc: any;
  username: any;
  buttonID: any;
  closeModal: any;
  onSubmit: any;
  currencySymbols: any;
}

const OfferAcceptMessageV2 = ({
  offerAmount,
  avatarSrc,
  username,
  buttonID,
  closeModal,
  onSubmit,
  currencySymbols,
}: OfferAcceptMessageV2Props): ReactElement => {
  const [dealMessage, setDealMessage] = useState('');
  const [isAcceptingOffer, setIsAcceptingOffer] = useState(false);
  const unlockCancelBtn = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();
    setIsAcceptingOffer(true);

    onSubmit(dealMessage)
      .then(() => {
        acceptOffer(e);
        setIsAcceptingOffer(false);
      })
      .catch(() => {
        setIsAcceptingOffer(false);
      });
  };

  const getHeadline = () => {
    return (
      <div>
        <span>
          You&lsquo;ve accepted <strong>{username}</strong>&lsquo;s offer of {currencySymbols.currencyInitial}
          {currencySymbols.currencyLabel}
          {offerAmount}.{' '}
        </span>
        <div style={{ marginTop: 15 }}>
          Your first message has been unlocked. Introduce yourself and start planning your first date!
        </div>
      </div>
    );
  };

  return (
    <Form>
      <HeaderDiv>
        <AvatarTitleDiv>
          <TitleDiv>Offer Accepted!</TitleDiv>
          <Avatar
            data-test-id="offer-modal-avatar"
            src={avatarSrc}
            width={getBrowserDetails().isMobile ? '91px' : '100px'}
            customStyle={{
              backgroundColor: '#ccc',
            }}
          />
        </AvatarTitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            marginTop: 20,
            fontSize: 16,
            lineHeight: '21px',
            letterSpacing: '-0.32px',
          }}
          data-test-id="profile-offer-headline"
          size="SM"
        >
          {getHeadline()}
        </Headline>
      </HeaderDiv>
      <Content>
        <MessageTextArea
          id="dealMessage"
          rows={4}
          onChange={e => setDealMessage(e.target.value)}
          value={dealMessage}
          placeholder={`Type your message to ${username} here...`}
          customInputStyle={{ fontSize: '16px' }}
          data-test-id="first-message-input"
          customStyle={{ paddingBottom: 14 }}
        />
        <ButtonDiv style={{ marginTop: 0 }}>
          <ButtonSet align="center">
            <Button
              id={buttonID}
              buttonType="error"
              onClick={handleSubmit}
              type="submit"
              data-test-id="accept-sent-offer-btn"
              customStyle={{ fontSize: 18, padding: '16px 24px' }}
            >
              <OfferMessageIcon customStyle={{ marginRight: 6 }} />
              {isAcceptingOffer ? 'Sending Message' : 'Send Message'}
            </Button>
          </ButtonSet>
          <ButtonSet align="center">
            <Button
              buttonType="chromeless"
              buttonRef={unlockCancelBtn}
              data-test-id={`${buttonID}Cancel`}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
              customStyle={{ color: '#8392A7', fontWeight: 400, fontSize: 14 }}
            >
              {' '}
              Close
            </Button>
          </ButtonSet>
        </ButtonDiv>
      </Content>
    </Form>
  );
};

export default OfferAcceptMessageV2;
