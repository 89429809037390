import { STORE_CREDITS, CLEAR_CREDITS, IS_FETCHING_CREDITS, CreditsState, CreditsActionTypes } from './types/Credits.d';
import _ from 'lodash';

const initialState: CreditsState = {
  isFetching: false,
  credits: {},
};

/* stores logged in profile */
const credits = (state = initialState, action: CreditsActionTypes): any => {
  switch (action.type) {
    case STORE_CREDITS: {
      const oldCredits = _.map(state.credits, v => {
        return v;
      });

      return {
        ...state,
        credits: { ...oldCredits.concat(action.payload) },
      };
    }
    case CLEAR_CREDITS:
      return {
        ...state,
        credits: {},
      };
    case IS_FETCHING_CREDITS:
      return {
        ...state,
        isFetching: !state.isFetching,
      };
    default:
      return state;
  }
};

export default credits;
