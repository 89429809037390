import React, { useState, useEffect, ReactElement, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { dimensions, backgroundTheme, textColor } from '../../style';
import { displayUserLocation, getOfferType, renderAvatar, tsSince } from '../../common';
import ConfirmationModal from '../modals/ConfirmationModal';
import { css } from 'glamor';
import OfferCreate from '../../components/pages/Offers/OfferCreate';
import ReactModal from 'react-modal';
import Avatar from '../../atoms/Avatar';
import TextArea from '../../atoms/TextArea';
import { CloseOutlined } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import OfferModal from './OfferModal';
import { vysionTrack } from '../../utils/vysion/vysionService';
import useMixPanel from '../../hooks/useMixPanel';
import useCompleteProfileModal from '../../hooks/useCompleteProfileModal';
import { useNavigate } from 'react-router-dom';
import { Account, AccountMaker } from '../../models/Account';
import { connect } from 'react-redux';
import CompleteProfileModalV2 from '../modals/CompleteProfileModalV2';
import { Profile, ProfileMaker } from '../../models/Profile';
import useModal from '../../hooks/useModal';
import OfferCounterOffer from '../../components/pages/Offers/OfferCounterOffer';
import OfferModalFooter from '../../components/pages/Offers/OfferModalFooter';
import { counterModalClose } from '../../actions/profileTrackAction';
import FavOfferCreate from '../../components/pages/Offers/FavOfferCreate';
import { OfferActions } from '../../utils/offers';

const ModalStyles = {
  backgroundColor: backgroundTheme.block,
  position: 'relative',
  boxSizing: 'border-box',
  outline: 'none',
  maxHeight: '80vh',
  overflow: 'auto',
  ...textColor.DARK,
  ':focus': {
    outline: 'none',
  },

  [dimensions.SCREEN_MAX_SM]: {
    height: '100%',
  },

  [dimensions.SCREEN_MIN_SM]: {
    padding: 22,
    borderRadius: 4,
    position: 'relative',
    top: 80,
    maxWidth: 540,
    margin: '32px auto',
  },
};

const OverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
  backgroundColor: 'rgba(0,0,0,0.65)',
  overflow: 'auto',

  [dimensions.SCREEN_MAX_SM]: {
    backgroundColor: '#fff',
  },
};

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: 0,
  right: 0,
});

const AvatarContainer = styled('div')({
  display: 'none',
  [dimensions.SCREEN_MIN_XS]: {
    display: 'block',
    width: 96,
    height: 96,
    marginRight: 16,
    flexShrink: 0,
  },
});

const closeButton = {
  fontSize: 32,
  color: backgroundTheme.iconFill,
};

interface OfferHighlightModalProps {
  profile: any;
  offer: any;
  acceptOffer: any;
  ignoreOffer: any;
  clearErrors: any;
  createNewOffer: any;
  counterOffer: any;
  sendSuggestion: any;
  errors: any;
  showWarn: any;
  containerId: any;
  isGenerous: any;
  onClose: any;
  isOpen: any;
  isFetching: any;
  checkProfile: any;
  getNextOfferQueue: any;
  ownAccount: Account;
  ownProfile: Profile;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfferHighlightModal = ({
  profile,
  offer,
  acceptOffer,
  ignoreOffer,
  clearErrors,
  createNewOffer,
  counterOffer,
  sendSuggestion,
  errors,
  showWarn,
  containerId,
  isGenerous,
  onClose,
  isOpen,
  setIsOpen,
  isFetching,
  checkProfile,
  getNextOfferQueue,
  ownAccount,
  ownProfile,
}: OfferHighlightModalProps): ReactElement => {
  const { basicModal, resetModal } = useModal();
  const { trackOfferIgnored } = useMixPanel();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const [acceptOfferModalOpen, setAcceptOfferModalOpen] = useState(false);
  const [ignored, setIgnored] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isCounter, setIsCounter] = useState(false);
  const [justSuggested, setJustSuggested] = useState(false);
  const [setHasErrors] = useState(false);
  const [dealMessage, setDealMessage] = useState('');
  const [hasOfferAction, setHasOfferAction] = useState(false);
  const [clickCounterAction, setClickCounterAction] = useState(false);
  const [clickProfileAction, setClickProfileAction] = useState(false);
  const [clickSuggestAction, setClickSuggestAction] = useState(false);
  const [showGenerousMandatoryProfileCompletionModal, setShowGenerousMandatoryProfileCompletionModal] = useState(false);

  const { showTooManyPendingOffersErrorModal } = useModal();

  const { data: pData } = offer.other_account;
  const { data: profileData } = pData.profile;
  const avatar = renderAvatar(profileData.avatar.data.urls);
  const location = displayUserLocation(profile, profileData);
  const offerType = getOfferType(offer, profile);
  const counterDisabled = offer.min_counter_price === offer.max_counter_price;
  const accountHashId = profileData.hash_id;

  const navigate = useNavigate();

  useEffect(() => {
    if ((clickProfileAction || clickSuggestAction || clickCounterAction) && typeof checkProfile === 'function') {
      checkProfile(accountHashId).then(response => {
        if (typeof response === 'undefined') {
          getNextOfferQueue();
          return;
        } else if (clickCounterAction) {
          counterOfferReceived();
          return;
        } else if (clickSuggestAction) {
          counterSuggestOffer();
          return;
        }

        if (!hasOfferAction) {
          navigate(`/profile/${accountHashId}`);
        }
      });
    }

    setHasOfferAction(false);
    setClickCounterAction(false);
    setClickSuggestAction(false);
    setClickProfileAction(false);
  }, [
    hasOfferAction,
    clickCounterAction,
    clickProfileAction,
    offerType,
    accountHashId,
    checkProfile,
    clickSuggestAction,
    getNextOfferQueue,
    counterOfferReceived,
    counterSuggestOffer,
  ]);

  useEffect(() => {
    handleVysionTrack('view', 'display');
  }, []);

  let currencyInitial;
  let currencyLabelUnicode;

  if (profile && profile.profile.data.account_type === 'Attractive') {
    currencyInitial = pData.currency.data.initial;
    currencyLabelUnicode = pData.currency.data.label_unicode;
  } else {
    currencyInitial = profile && profile.currency && profile.currency.data.initial;
    currencyLabelUnicode = profile && profile.currency && profile.currency.data.label_unicode;
  }

  const handleVysionTrack = (action, component = '') => {
    vysionTrack({
      action,
      component,
      extraInfo: '',
      page: 'Forced action modal',
    });
  };

  const handleSuggestion = () => {
    setJustSuggested(true);

    sendSuggestion(profileData.hash_id)
      .then(() => {
        setIsOfferModalOpen(false);
      })
      .catch(() => {
        setHasErrors(true);
      });
  };

  const acceptAttractive = (hashId, message) => {
    setAcceptOfferModalOpen(false);
    acceptOffer(hashId, message);
  };

  const handleIgnore = () => {
    ignoreOffer(offer);
    setIgnored(true);
    trackOfferIgnored(offer.initiator_hash_id, { Amount: offer.price_original, User: profile.hash_id });
  };

  const getOfferDescription = () => {
    switch (offerType) {
      case 'suggestion_new':
        return 'Wants to go on a date!';
      case 'suggestion_pending':
        return 'Is considering a price';
      case 'offer_pending':
        return 'Is considering your offer';
      default:
        return null;
    }
  };

  const isProfileEligible = useCallback(
    (action: OfferActions) => {
      const isGenerous = ownProfile.isGenerous();
      const shouldShowTooManyLockedOffersDeals = ownAccount.shouldShowTooManyLockedOffersDeals(action);
      const isMandatoryProfileCompleted = ownAccount.isMandatoryProfileCompleted();

      setIsOpen(false);

      if (!isMandatoryProfileCompleted && isGenerous) {
        setShowGenerousMandatoryProfileCompletionModal(true);
        return false;
      }

      if (isGenerous) {
        if (shouldShowTooManyLockedOffersDeals) {
          showTooManyPendingOffersErrorModal();
          return false;
        }

        if (action === 'counter' && ownAccount.shouldShowTooManySentOffers()) {
          showTooManyPendingOffersErrorModal({ type: 'sent' });
          return false;
        }
      }

      return true;
    },
    [ownProfile, ownAccount]
  );

  const counterOfferReceived = useCallback(() => {
    if (isProfileEligible('counter')) {
      setIsCounter(true);
      setIsOfferModalOpen(true);
      handleVysionTrack('click', 'counterOfferReceived');
    }
  }, []);

  const counterSuggestOffer = useCallback(() => {
    setJustSuggested(true);
    setIsOfferModalOpen(true);
    handleVysionTrack('click', 'counterSuggestOffer');
  }, []);

  const handleIgnoreOffer = e => {
    e.preventDefault();

    if (isFetching) return;

    handleVysionTrack('click', 'ignore');

    setHasOfferAction(true);
    handleIgnore();
  };

  const handleCancelOffer = e => {
    e.preventDefault();

    if (isFetching) return;
    handleVysionTrack('click', 'cancelOffer');
  };

  const handleCancelSuggestion = e => {
    e.preventDefault();

    if (isFetching) return;
    handleVysionTrack('click', 'cancelSuggestion');

    handleIgnore();
  };

  const handleCounterOffer = formData => {
    return counterOffer(formData)
      .then(() => {
        setIsOfferModalOpen(false);
      })
      .catch(() => setIsOfferModalOpen(false));
  };

  const handleForceOpenCounterModal = () => {
    if (!isProfileEligible('counter')) {
      return;
    }

    showMandatoryCompleteProfile(
      () => {
        if (isProfileEligible('counter')) {
          setIsOpen(true);

          basicModal({
            dataTestID: 'offer-accept-message',
            modalContentLabel: 'Offer Accept and Message',
            onCloseClickHandler: counterModalClose,
            closeOnOverlayClick: false,
            customContentStyle: {
              borderRadius: 20,
            },
            modalBody: (
              <Fragment>
                <OfferCounterOffer
                  avatarSrc={renderAvatar(profileData.avatar.data.urls, true)}
                  offer={{
                    data: offer,
                  }}
                  errors={errors}
                  closeModal={resetModal}
                  currentUserIsGenerous={isGenerous}
                  clearErrors={() => undefined}
                  username={pData.username}
                  counterOffer={handleCounterOffer}
                  buttonID="counterOffer"
                  successOpen={() => setIsOpen(false)}
                  hashId={accountHashId}
                />
                <OfferModalFooter />
              </Fragment>
            ),
          });
        }
      },
      () => undefined,
      'Counter Offer'
    );
  };

  const handleSendOfferFromSuggestedOffer = () => {
    showMandatoryCompleteProfile(
      () => {
        if (isProfileEligible('suggest')) {
          setIsOpen(true);

          basicModal({
            dataTestId: 'offer-modal',
            modalContentLabel: 'Create Offer Modal',
            modalBody: (
              <Fragment>
                <FavOfferCreate
                  avatarSrc={renderAvatar(profileData.avatar.data.urls, true)}
                  username={pData.username}
                  hashId={accountHashId}
                  clearErrors={clearErrors}
                  createNewOffer={() => undefined}
                  successOpen={() => setIsOpen(false)}
                  counterOffer={handleCounterOffer}
                  isCounter={false}
                  closeModal={resetModal}
                  currencyInitial={currency.initial}
                  currencyLabel={currency.label}
                  currentUserIsGenerous={isGenerous}
                  errors={errors}
                  sex={profile.sex}
                  sendSuggestion={handleSuggestion}
                  showWarn={showWarn}
                  cannot_make_offer_reasons={profile.cannot_make_offer_reasons}
                  buttonID="interestedOffers"
                  isSuggestRequest={true}
                  title="Make an Offer!"
                  pageTitle={'Dashboard'}
                  otherProfile={offer.other_account.data.profile.data}
                />
                <OfferModalFooter />
              </Fragment>
            ),
          });
        }
      },
      () => undefined,
      'Make Offer'
    );
  };

  const handleCounter = e => {
    e.preventDefault();

    if (isFetching) return;

    handleVysionTrack('click', 'counter');
    handleForceOpenCounterModal();
  };

  const handleAcceptOffer = e => {
    e.preventDefault();

    if (isFetching || !isProfileEligible('accept')) return;

    handleVysionTrack('click', 'accept');

    showMandatoryCompleteProfile(
      () => {
        setHasOfferAction(true);
        offerAccepted(accountHashId);
      },
      () => setAcceptOfferModalOpen(false),
      'Accept Offer'
    );
  };

  const handleOnClose = () => {
    handleVysionTrack('click', 'close');
    onClose();
  };

  const handleSuggestOffer = e => {
    e.preventDefault();

    if (isFetching) return;
    handleSendOfferFromSuggestedOffer();
  };

  const offerAccepted = hashId => {
    isGenerous ? acceptOffer(hashId) : setAcceptOfferModalOpen(true);
  };

  const currency = {
    initial: offer.currency_original.data.initial,
    label: offer.currency_original.data.label_unicode,
  };

  const offerDescription = getOfferDescription();

  return (
    <div id={containerId} data-test-id={containerId}>
      <OfferModal
        isOpen={isOpen}
        avatar={avatar}
        onClose={handleOnClose}
        setClickProfileAction={setClickProfileAction}
        username={pData.username}
        age={profileData.age}
        location={location}
        offerDescription={offerDescription}
        lastUpdated={tsSince(offer.updated_at.date)}
        currency={currency}
        price={offer.price_original}
        offerType={offerType}
        handleIgnoreOffer={handleIgnoreOffer}
        handleCounter={handleCounter}
        counterDisabled={counterDisabled}
        accountHashId={accountHashId}
        handleAcceptOffer={handleAcceptOffer}
        handleSuggestOffer={handleSuggestOffer}
        isGenerous={isGenerous}
        handleCancelOffer={handleCancelOffer}
        handleCancelSuggestion={handleCancelSuggestion}
      />
      <ConfirmationModal
        data-test-id="accept-modal"
        contentLabel="Accept Offer Modal"
        isOpen={acceptOfferModalOpen}
        cancelText="Cancel"
        confirmText="Accept and Send"
        onClose={() => setAcceptOfferModalOpen(false)}
        onConfirm={() => acceptAttractive(profileData.hash_id, dealMessage)}
        buttonPosition="center"
        buttonID="acceptOfferHighlights"
        customStyle={{ overlay: { zIndex: 20000 } }}
      >
        <AvatarContainer style={{ margin: '0 auto', display: 'block', width: '96px' }}>
          <Avatar src={avatar} />
        </AvatarContainer>
        <p style={{ color: '#4396d7', fontWeight: 'bold', textAlign: 'center' }}>
          Send {pData.username} a message to accept their offer.
        </p>
        <TextArea
          rows={6}
          onChange={e => setDealMessage(e.target.value)}
          value={dealMessage}
          placeholder="Introduce yourself and tell them what you'd like to do on your first date!"
          resize="vertical"
          customInputStyle={{ fontSize: '16px' }}
        />
      </ConfirmationModal>

      <ReactModal
        data-test-id="counter-modal"
        isOpen={isOfferModalOpen}
        className={`${css(ModalStyles)}`}
        overlayClassName={`${css(OverlayStyles)}`}
        contentLabel="Create Offer Modal"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsOfferModalOpen(false)}
      >
        <CloseBtn onClick={() => setIsOfferModalOpen(false)}>
          <SvgIcon component={CloseOutlined} style={closeButton} />
        </CloseBtn>
        <OfferCreate
          avatarSrc={renderAvatar(profileData.avatar.data.urls, true)}
          username={pData.username}
          hashId={profileData.hash_id}
          offer={offer}
          clearErrors={clearErrors}
          createNewOffer={createNewOffer}
          counterOffer={counterOffer}
          isCounter={isCounter}
          closeModal={() => setIsOfferModalOpen(false)}
          currencyInitial={currencyInitial}
          currencyLabel={currencyLabelUnicode}
          currentUserIsGenerous={isGenerous}
          errors={errors}
          sex={profile.sex}
          sendSuggestion={handleSuggestion}
          showWarn={showWarn}
          cannot_make_offer_reasons={profile.cannot_make_offer_reasons}
          ignoreStatus={ignored}
          justSuggested={justSuggested}
          buttonID="counterOfferHighlights"
          otherProfile={profileData}
        />
      </ReactModal>

      {showGenerousMandatoryProfileCompletionModal && ownAccount && (
        <CompleteProfileModalV2
          avatar={renderAvatar(ownAccount?.data?.profile?.data?.avatar?.data?.urls || {})}
          username={ownAccount?.data.username || ''}
          location={displayUserLocation(ownProfile.profile, ownAccount?.data.profile.data)}
          age={ownAccount?.data.profile.data.age}
          editProfileLink={`/profile/${ownAccount.data.hash_id}`}
          isOpen={showGenerousMandatoryProfileCompletionModal}
          onClose={() => {
            setShowGenerousMandatoryProfileCompletionModal(false);
            setIsOpen(true);
          }}
        />
      )}
    </div>
  );
};

OfferHighlightModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  /* Bool to determine if modal is open */
  isOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    ownAccount: AccountMaker.create(state.profile),
    ownProfile: ProfileMaker.create(state.profile.profile),
  };
};

export default connect(mapStateToProps, null)(OfferHighlightModal);
