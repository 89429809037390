import React, { ReactElement } from 'react';

const Camera = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13px" height="13px" viewBox="0 0 12 9">
      {' '}
      <g id="Search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Updated-search" transform="translate(-137.000000, -360.000000)">
          <g id="row-copy-3" transform="translate(12.000000, 257.000000)">
            <g id="Block">
              <g id="camera" transform="translate(126.000000, 103.000000)">
                <path
                  d="M10.25,9.71157895 C10.25,10.0021814 10.0320115,10.25 9.7462963,10.25 L0.253703704,10.25 C-0.0320114598,10.25 -0.25,10.0021814 -0.25,9.71157895 L-0.25,2.28842105 C-0.25,1.99781855 -0.0320114598,1.75 0.253703704,1.75 L9.7462963,1.75 C10.0320115,1.75 10.25,1.99781855 10.25,2.28842105 L10.25,9.71157895 Z"
                  id="Path"
                  stroke="rgba(255,255,255,0.7)"
                  strokeWidth="0.5"
                />
                <path
                  d="M5,3 C3.34576471,3 2,4.34576471 2,6 C2,7.65423529 3.34576471,9 5,9 C6.65423529,9 8,7.65423529 8,6 C8,4.34576471 6.65423529,3 5,3"
                  id="Fill-3"
                  fill="rgba(255,255,255,0.7)"
                />
                <path
                  d="M3.19047619,0.5 L6.80952381,0.5 C6.91485714,0.5 7,0.38825 7,0.25 C7,0.11175 6.91485714,0 6.80952381,0 L3.19047619,0 C3.08514286,0 3,0.11175 3,0.25 C3,0.38825 3.08514286,0.5 3.19047619,0.5"
                  id="Fill-5"
                  fill="rgba(255,255,255,0.7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Camera;
