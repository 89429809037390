import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';
import Loader from './LoaderStyled';
import BarLoader from 'react-spinners/BarLoader';
import PhotoMenu from './PhotoMenu';
import { imageOrientation } from '../common';
import PhotoLightbox from '../components/pages/Photos/PhotoLightbox';
import { commonIcons } from './icons/materialIcons';

const PhotoRenderer = styled('img')(
  {
    width: '100%',
    height: 'auto',
    minHeight: 100,
    minWidth: 130,
    borderRadius: 4,
    margin: '4px 0px',
    cursor: 'pointer',
  },
  ({ isBlurred }) => ({
    webkitFilter: isBlurred ? 'blur(10px) brightness(0.5)' : 'unset',
    filter: isBlurred ? 'blur(10px) brightness(0.5)' : 'unset',
  })
);

const LoaderStyled = styled(Loader)({
  width: '100%',
  height: 'auto',
  minWidth: 100,
  minHeight: 20,
});

const TapToReveal = styled('span')({
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  left: '0',
  top: '46%',
  color: '#FFFFFF',
  cursor: 'pointer',
});

interface RenderPhotoProps {
  error: string | null;
  isBlurred: any;
  photoUrl: any;
  isSent: any;
  hashId: any;
  handleDeleteImg: any;
  messageId: any;
  handleUnblurImage: any;
  handleReportImg: any;
  urls: any;
}

const RenderPhoto = ({
  error = 'false',
  isBlurred,
  photoUrl,
  isSent,
  hashId,
  handleDeleteImg,
  messageId,
  handleUnblurImage,
  handleReportImg,
  urls,
}: RenderPhotoProps): ReactElement => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [imgAvailable, setImgAvailable] = useState(true);
  const [fetchThreshold, setFetchThreshold] = useState(0);

  const unblurView = id => {
    if (isBlurred) {
      handleUnblurImage(id);
      return;
    }
    setLightBoxOpen(true);
  };

  const imgFetchLimit = () => {
    if (fetchThreshold <= 5) {
      setImgAvailable(false);
      return;
    }
    setFetchThreshold(fetchThreshold + 1);
  };

  if (typeof urls === 'undefined') {
    return (
      <LoaderStyled active barLoader spinner={<BarLoader color="#FFFFFF" />} text="Processing..." color="#FFFFFF" />
    );
  }

  const photoSrc = urls['820px'];
  const images = [
    {
      src: photoSrc,
      isPrivate: false,
    },
  ];

  if (!imgAvailable) {
    return (
      <div>
        <commonIcons.brokenImage.default /> <span style={{ marginTop: 4, float: 'right' }}>Image Unavailable</span>
      </div>
    );
  }

  const imgOrientation = () => imageOrientation(photoSrc);

  return (
    <div data-test-id={`render-photo-${imgOrientation()}`} className="render-photo-wrapper">
      <PhotoMenu
        hasError={error}
        messageId={messageId}
        isSent={isSent}
        hashId={hashId}
        imgPath={photoUrl.path}
        handleDeleteImg={handleDeleteImg}
        handleReportImg={handleReportImg}
      />
      <PhotoRenderer
        data-test-id={`photo-orientation-${imgOrientation()}`}
        className={`photo-orientation`}
        isBlurred={isBlurred}
        src={photoSrc}
        alt=""
        onError={imgFetchLimit}
        onClick={() => (imgAvailable ? unblurView(messageId) : null)}
      />
      {isBlurred && (
        <TapToReveal data-test-id="message-tap-reveal" onClick={() => handleUnblurImage(messageId)}>
          Tap to Reveal
        </TapToReveal>
      )}
      <PhotoLightbox
        images={images}
        allowZoom
        lightboxIsOpen={lightBoxOpen}
        currentImage={0}
        onClose={() => setLightBoxOpen(false)}
        photoListLength={1}
        privatePhotoCount={0}
        onOfferClick={() => null}
        username={hashId || ''}
        hashId={hashId || ''}
        isPhotoMessage
      />
    </div>
  );
};

export default RenderPhoto;
