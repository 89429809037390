import React, { useEffect, useState, useMemo, Fragment } from 'react';
import styled from '@emotion/styled';
import { useOffers } from '../../../../../contexts/OffersContext';
import { useApp } from '../../../../../contexts/AppContext';
import OneTapSwipeOfferActionButton from './OneTapSwipeOfferActionButton';
import OneTapSwipeOfferSuggestOrCounterButton from './OneTapSwipeOfferSuggestOrCounterButton';
import useModal from '../../../../../hooks/useModal';
import { CurrencySymbols } from '../../../DashboardDiscoverMode/DashboardDiscoverMode';
import useCompleteProfileModal from '../../../../../hooks/useCompleteProfileModal';
import { displayUserLocation, renderAvatar } from '../../../../../common';
import { connect } from 'react-redux';
import { refreshProfile } from '../../../../../actions/profileActions';
import {
  refreshOffers,
  createNewOffer,
  counterOffer,
  sendSuggestion,
  acceptOffer,
  ignoreOffer,
} from '../../../../../actions/offerActions';
import { unlockOutsideMessage } from '../../../../../actions/mailActions';
import { displayWarnMessage } from '../../../../../actions/notificationActions';
import CompleteProfileModalV2 from '../../../../../modules/modals/CompleteProfileModalV2';
import OfferModalFooter from '../../OfferModalFooter';
import { clearErrors } from '../../../../../actions/commonActions';
import { counterModalClose } from '../../../../../actions/profileTrackAction';
import OfferCounterOffer from '../../OfferCounterOffer';
import OfferAcceptUnlockV2 from '../../OfferAcceptUnlockV2';
import OfferAcceptMessageV2 from '../../OfferAcceptMessageV2';
import OfferCreateV2 from '../../OfferCreateV2';
import { oneTapOfferTrackMetric } from '../../../../../utils/evidentlyFeatures/oneTapOfferManagement';
import useMixPanel from '../../../../../hooks/useMixPanel';
import { AccountMaker } from '../../../../../models/Account';

const ActionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const OfferActionContainer = styled('div')({
  maxWidth: 'calc(100% - 40px)',
  width: '100%',
  position: 'fixed',
});

interface Props {
  swipe: (direction: 'left' | 'right') => void;
  ignoreOffer: any;
  createNewOffer: any;
  refreshOffers: any;
  showWarn: any;
  user: any;
  errors: any;
  clearErrors: any;
  counterOffer: any;
  sendSuggestion: any;
  acceptOffer: any;
  pageTitle: any;
  triggerUnlockMessage: any;
  restoreCurrentCard: () => void;
}

const OneTapSwipeOfferActions: React.FC<Props> = ({
  swipe,
  user,
  ignoreOffer,
  createNewOffer,
  showWarn,
  errors,
  clearErrors,
  counterOffer,
  sendSuggestion,
  acceptOffer,
  triggerUnlockMessage,
  pageTitle,
  restoreCurrentCard,
}: Props) => {
  const {
    offerActionContainerRef,
    oneTapActiveAccount,
    setOneTapOfferFlagVariant,
    calculateDynamicMediaRatio,
    declineBtnRef,
    offerBtnRef,
  } = useOffers();
  const [bottom, setBottom] = useState(0);
  const { siteFooterLogoRef } = useApp();
  const { basicModal, resetModal, confirmDeclineOffer, showTooManyPendingOffersErrorModal } = useModal();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const { trackOfferDeclined } = useMixPanel();

  const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
  const accountModel = AccountMaker.create(user);

  const userCanMakeOffer = useMemo(() => user.can_make_offer, [user.can_make_offer]);

  const isOtherAccountIsAttractive = useMemo(() => {
    return oneTapActiveAccount?.profile.isAttractive();
  }, [oneTapActiveAccount]);

  const otherAccount = useMemo(() => {
    return oneTapActiveAccount?.account?.data;
  }, [oneTapActiveAccount]);

  const otherProfile = useMemo(() => {
    return otherAccount?.profile?.data;
  }, [otherAccount]);

  const currencySymbols: CurrencySymbols = useMemo(() => {
    let currencyInitial;
    let currencyLabelUnicode;

    const currencyOriginal = oneTapActiveAccount?.currency_original?.data;
    if (currencyOriginal) {
      currencyInitial = currencyOriginal.initial;
      currencyLabelUnicode = currencyOriginal.label_unicode;
    }

    return {
      currencyInitial,
      currencyLabel: String.fromCharCode(currencyLabelUnicode),
    };
  }, [oneTapActiveAccount]);

  const offerActionVariant = useMemo(() => {
    if (oneTapActiveAccount?.suggest) {
      return isOtherAccountIsAttractive ? 'offer' : 'request';
    } else {
      return 'accept';
    }
  }, [oneTapActiveAccount, isOtherAccountIsAttractive]);

  const handleCreateNewOffer = (formData, shouldRefreshList = false) => {
    return createNewOffer(formData, shouldRefreshList);
  };

  const handleCounterOffer = formData => {
    return counterOffer(formData, null, null);
  };

  const handleSuggestion = (profileId, showToastMessage = true) => {
    return sendSuggestion(profileId, null, null, showToastMessage);
  };

  const trackOfferEngagementAction = async () => {
    await oneTapOfferTrackMetric(['oneTapOfferEngagement']);
  };

  const handleIgnoreOffer = () => {
    confirmDeclineOffer({
      isGenerous: isOtherAccountIsAttractive,
      username: otherAccount.username,
      onConfirm: () => {
        setOneTapOfferFlagVariant('decline');
        swipe('left');
        ignoreOffer(oneTapActiveAccount.hash_id);
        trackOfferDeclined(oneTapActiveAccount.initiator_hash_id, {
          Amount: oneTapActiveAccount.price_original,
          User: user.hash_id,
          Source: isOtherAccountIsAttractive ? '1-Tap Received Requests' : '1-Tap Received Offers',
        });
        trackOfferEngagementAction();
        resetModal();
      },
      onCancel: () => {
        restoreCurrentCard();
        resetModal();
      },
    });
  };

  const handleNewOffer = () => {
    showMandatoryCompleteProfile(
      () => {
        if (!userCanMakeOffer && isOtherAccountIsAttractive) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            return setShowCompleteProfileModal(true);
          }

          if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            showTooManyPendingOffersErrorModal({ onCloseCallback: restoreCurrentCard });
            return false;
          }

          if (accountModel.shouldShowTooManySentOffers()) {
            showTooManyPendingOffersErrorModal({ onCloseCallback: restoreCurrentCard, type: 'sent' });
            return false;
          }
        }

        basicModal({
          dataTestId: 'offer-modal',
          modalContentLabel: 'Create Offer Modal',
          closeOnOverlayClick: false,
          customContentStyle: {
            borderRadius: 20,
          },
          modalBody: (
            <Fragment>
              <OfferCreateV2
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={clearErrors}
                createNewOffer={handleCreateNewOffer}
                successOpen={() => {
                  setOneTapOfferFlagVariant(isOtherAccountIsAttractive ? 'offer' : 'request');
                  swipe('right');
                  trackOfferEngagementAction();
                }}
                counterOffer={handleCounterOffer}
                isCounter={false}
                closeModal={() => {
                  resetModal();
                  restoreCurrentCard();
                }}
                currencyInitial={otherAccount.currency.data.initial}
                currencyLabel={otherAccount.currency.data.label_unicode}
                currentUserIsGenerous={isOtherAccountIsAttractive}
                errors={errors}
                sex={otherProfile.sex}
                sendSuggestion={handleSuggestion}
                showWarn={showWarn}
                cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                buttonID="interestedOffers"
                isSuggestRequest={oneTapActiveAccount?.suggest}
                title={isOtherAccountIsAttractive ? 'Make an Offer!' : 'Request a Date!'}
                pageTitle={isOtherAccountIsAttractive ? 'Make Offer' : 'Request Date'}
                otherProfile={otherProfile}
              />
              <OfferModalFooter />
            </Fragment>
          ),
          onCloseClickHandler: () => {
            restoreCurrentCard();
          },
        });
      },
      () => restoreCurrentCard(),
      isOtherAccountIsAttractive ? 'Make Offer' : 'Request Date'
    );
  };

  const handleForceOpenCounterModal = () => {
    showMandatoryCompleteProfile(
      () => {
        if (!userCanMakeOffer && isOtherAccountIsAttractive) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            setShowCompleteProfileModal(true);
            return;
          }

          if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            showTooManyPendingOffersErrorModal({ onCloseCallback: restoreCurrentCard });
            return false;
          }

          if (accountModel.shouldShowTooManySentOffers()) {
            showTooManyPendingOffersErrorModal({ onCloseCallback: restoreCurrentCard, type: 'sent' });
            return false;
          }
        }

        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          onCloseClickHandler: counterModalClose,
          closeOnOverlayClick: false,
          customContentStyle: {
            borderRadius: 20,
          },
          modalBody: (
            <Fragment>
              <OfferCounterOffer
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                offer={{
                  data: oneTapActiveAccount,
                }}
                errors={errors}
                closeModal={resetModal}
                currentUserIsGenerous={isOtherAccountIsAttractive}
                clearErrors={() => undefined}
                username={otherAccount.username}
                counterOffer={handleCounterOffer}
                hashId={otherAccount.hash_id}
                buttonID="counterOffer"
                successOpen={() => {
                  setOneTapOfferFlagVariant('counter');
                  swipe('left');
                  trackOfferEngagementAction();
                }}
                mixpanelEventSource={isOtherAccountIsAttractive ? '1-Tap Received Requests' : '1-Tap Received Offers'}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Counter Offer'
    );
  };

  const handleAcceptOffer = () => {
    showMandatoryCompleteProfile(
      async () => {
        if (!userCanMakeOffer && isOtherAccountIsAttractive) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            setShowCompleteProfileModal(true);
            return;
          }

          if (oneTapActiveAccount?.suggest && accountModel.shouldShowTooManyLockedOffersDeals()) {
            showTooManyPendingOffersErrorModal({ onCloseCallback: restoreCurrentCard });
            return false;
          }
        }

        oneTapOfferTrackMetric(['oneTapOfferEngagement', 'oneTapAcceptedOffers']);

        if (isOtherAccountIsAttractive) {
          const result = await acceptOffer(otherAccount.hash_id, '', '1-Tap Received Requests');
          handleAcceptUnlock(true, oneTapActiveAccount.price_original, result);
        } else {
          handleAttractiveMessage(oneTapActiveAccount.price_original);
        }
      },
      () => restoreCurrentCard(),
      'Accept Offer'
    );
  };

  const handleAcceptUnlock = (toggle, amount = '', result) => {
    const onCloseModal = () => {
      resetModal();
      setOneTapOfferFlagVariant('accept');
      swipe('right');
    };

    showMandatoryCompleteProfile(() => {
      basicModal(
        {
          dateTestId: 'offer-success',
          modalContentLabel: 'Success Offer Modal',
          isModalOpen: toggle,
          onCloseClickHandler: onCloseModal,
          closeOnOverlayClick: false,
          modalBody: (
            <Fragment>
              <OfferAcceptUnlockV2
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                successOpen={resetModal}
                buttonID="interestedOffers"
                currentUserIsGenerous={isOtherAccountIsAttractive}
                creditCost={result.credit_cost}
                hashId={otherAccount.hash_id}
                closeModal={onCloseModal}
                currentCredit={user.credits}
                unlockMessage={triggerUnlockMessage}
                currencySymbol={currencySymbols}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        },
        () => restoreCurrentCard(),
        'Unlock Offer'
      );
    });
  };

  const handleAttractiveMessage = (amount = '') => {
    const onCloseModal = () => {
      resetModal();
      setOneTapOfferFlagVariant('accept');
      swipe('right');
      return acceptOffer(otherAccount.hash_id, '', '1-Tap Received Offers');
    };

    showMandatoryCompleteProfile(
      () => {
        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          closeOnOverlayClick: false,
          onCloseClickHandler: onCloseModal,
          modalBody: (
            <Fragment>
              <OfferAcceptMessageV2
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                buttonID="interestedOffers"
                closeModal={onCloseModal}
                currencySymbols={currencySymbols}
                onSubmit={dealMessage => {
                  resetModal();
                  setOneTapOfferFlagVariant('accept');
                  swipe('right');
                  return acceptOffer(otherAccount.hash_id, dealMessage, pageTitle);
                }}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => restoreCurrentCard(),
      'Accept Offer'
    );
  };

  useEffect(() => {
    const siteFooterLogoEl = siteFooterLogoRef?.current;

    if (siteFooterLogoEl) {
      const siteFooterLogoClRec = siteFooterLogoEl.getBoundingClientRect();
      const OFFER_ACTION_MARGIN_BOTTOM = 15;
      const computeDynamicBottom = window.innerHeight - siteFooterLogoClRec.top + OFFER_ACTION_MARGIN_BOTTOM;
      setBottom(computeDynamicBottom);
    }
  }, [siteFooterLogoRef?.current]);

  useEffect(() => {
    //  when bottom values is changed, we need to recalculate the dynamic media ratio
    if (bottom) {
      calculateDynamicMediaRatio();
    }
  }, [bottom]);

  return (
    <OfferActionContainer ref={offerActionContainerRef} style={{ bottom }}>
      <ActionContainer>
        <OneTapSwipeOfferActionButton
          btnRef={declineBtnRef}
          onClick={() => {
            handleIgnoreOffer();
          }}
          variant="decline"
        />
        <OneTapSwipeOfferSuggestOrCounterButton
          variant={oneTapActiveAccount?.suggest ? 'suggest' : 'counter'}
          offerPrice={oneTapActiveAccount?.price_original ?? ''}
          currencySymbols={currencySymbols}
          onClick={() => {
            if (offerActionVariant === 'accept') {
              handleForceOpenCounterModal();
            }
          }}
        />
        <OneTapSwipeOfferActionButton
          btnRef={offerBtnRef}
          onClick={() => {
            if (['offer', 'request'].includes(offerActionVariant)) {
              handleNewOffer();
              return;
            }

            if (offerActionVariant === 'accept') {
              handleAcceptOffer();
              return;
            }
          }}
          variant={offerActionVariant}
        />
      </ActionContainer>
      {otherProfile && (
        <CompleteProfileModalV2
          avatar={renderAvatar(otherProfile?.avatar?.data?.urls, true)}
          username={otherAccount && otherAccount.username}
          location={displayUserLocation(otherProfile, {
            country: otherProfile.country,
            region: otherProfile.region,
            city: otherProfile.city,
          })}
          age={otherAccount.age}
          editProfileLink={`/profile/${oneTapActiveAccount.to_account_hash_id}`}
          isOpen={showCompleteProfileModal}
          onClose={() => {
            restoreCurrentCard();
            setShowCompleteProfileModal(false);
          }}
        />
      )}
    </OfferActionContainer>
  );
};

const mapStateTopProps = state => {
  return {
    user: state.profile,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    declineOfferMessage: message => {
      dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    refreshOffers(route, orderType) {
      dispatch(refreshProfile());
      dispatch(refreshOffers(route, orderType));
    },
    createNewOffer: formData => {
      return dispatch(createNewOffer(formData, false, null, null, true));
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    counterOffer: (formData, favKey, section) => {
      return dispatch(counterOffer(formData, false, ownProps.navigate, favKey, section, true));
    },
    sendSuggestion: (username, favKey, section, showToastMessage) => {
      return dispatch(sendSuggestion(username, true, favKey, section, showToastMessage));
    },
    acceptOffer: (username, message, source) => {
      return dispatch(acceptOffer(username, message, false, null, null, true, source, ownProps.navigate));
    },
    ignoreOffer: hashId => {
      dispatch(ignoreOffer(hashId, false, false, null, null));
    },
    triggerUnlockMessage: (hashId, triggerToBuy, callback = () => undefined) => {
      dispatch(unlockOutsideMessage(hashId, triggerToBuy, callback));
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(OneTapSwipeOfferActions);
