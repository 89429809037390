import { mixpanelTrackEventFromBE } from '.';

export type Types = 'GoogleSSO' | 'Phone Number' | 'Email';

export const mixpanelTrackUserLoggedIn = async (type: Types): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'User Logged In',
    payload: {
      event_properties: {
        Type: type,
      },
    },
  });
};
