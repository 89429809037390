export const TRACK_PAGE_VIEW_PATHS = {
  '/dashboard': 'Dashboard',
  '/favorites/my': 'My Favorites',
  '/favorites/viewed-me': 'Viewed me',
  '/favorites': 'Favorited Me',
  '/offers/new': 'Received Requests',
  '/offers/sent': 'Sent Offers',
  '/credits': 'Purchase History',
  '/cancelled-offers': 'Cancelled Offers',
  '/blocked': 'Blocked Accounts',
  '/mail': 'Messages',
  '/search': 'Search',
};

export interface IProfileCompletionVysionEvents {
  page: string;
}
