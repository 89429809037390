import styled from '@emotion/styled';
import { dimensions } from '../../../style';
import { theme } from '../../../theme';
import colors from '../../../theme/colors';
import borders from '../../../theme/borders';
import typography from '../../../theme/typography';
import { getBrowserDetails } from '../../../utils/helpers';

export const StyledCounterH2 = styled.h2(({ isValidCount }: { isValidCount: boolean }) => ({
  color: isValidCount ? colors.red[500] : theme === 'light' ? colors[theme].blue.primary : colors[theme].blue.secondary,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: typography.lineHeight,
}));

export const StyledVerticalLine = styled('span')({
  display: 'inline-block',
  borderLeft: `2px solid ${borders[theme].primary}`,
  margin: '0 8px',
  height: 36,
});

export const StyledCountDetailsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  paddingTop: 30,
  paddingBottom: 30,

  [dimensions.SCREEN_MAX_XS]: {
    paddingTop: 18,
    paddingBottom: 18,
  },
});

export const StyledMediasContainer = styled.div`
  background-color: ${theme === 'light' ? colors.gray[200] : colors.blue[800]};
  padding: 12px;
  border-radius: 8px;
  min-height: 320px;

  ${dimensions.SCREEN_MAX_XS} {
    position: relative;
    padding-left: 15px;
    padding-right: 10px;
  }
`;

export const scrollbarColor =
  theme === 'light'
    ? `${colors.light.blue.primary} ${colors.light.gray.active}`
    : `${colors.dark.blue.secondary} ${colors.blue[900]}`;

export const StyledMediasInnerContainer = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  overflow-y: auto;
  -ms-overflow-style: auto;
  scrollbar-width: thin;
  scrollbar-color: ${scrollbarColor};
  max-height: 420px;

  ${dimensions.SCREEN_MAX_XS} {
    height: ${getBrowserDetails().hImgCalibration}px;
    max-height: ${getBrowserDetails().hImgCalibration}px;
  }

  &::-webkit-scrollbar-track: {
    background: ${theme === 'light' ? colors.light.gray.active : colors.blue[900]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme === 'light' ? colors.light.gray.active : colors.blue[900]};
    border-radius: 5px;
    border: 3px solid ${theme === 'light' ? colors.light.blue.primary : colors.dark.blue.secondary};
  }

  &::-webkit-scrollbar {
    background-color: ${theme === 'light' ? colors.light.gray.active : colors.blue[900]};
    width: 5px;
    border-radius: 5px;
  }
`;

export const StyledMediaItemContainer = styled.div`
  width: 140px;
  height: 194px;
  position: relative;
  padding: 7.5px;
`;
