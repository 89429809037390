import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import './DashboardDisCoverModeStyle.css';
import DashboardDiscoverModeOfferActions from './DashboardDiscoverModeOfferActions';
import DashboardDiscoverModeProfileCard from './components/DashboardDiscoverModeProfileCard';
import DashboardDiscoverModeVideoPreviewLightBox from './components/DashboardDiscoverModeVideoPreviewLightBox';
import DashboardDiscoverModeTutorialWithoutSwipe from './DashboardDiscoverModeTutorialWithoutSwipe';
import DashboardDiscoverModeTutorial from './DashboardDiscoverModeTutorial';
import { AccountMaker, AccountDataObject, Account } from '../../../models/Account';
import { getSwipeModeAccounts, swipeModePassAccount } from '../../../sdk/ProfileSDK';
import { createNewOffer } from '../../../actions/offerActions';
import { connect } from 'react-redux';
import DashboardDiscoverModeNoMoreProfilesScreen from './DashboardDiscoverModeNoMoreProfilesScreen';
import DashboardDiscoverModePassFlag from './components/DashboardDiscoverModePassFlag';
import DashboardDiscoverModeOfferSentFlag from './components/DashboardDiscoverModeOfferSentFlag';
import { updateAccountConfig } from '../../../actions/profileActions';
import {
  SWIPE_MODE_SHOW_OFFER_CONFIRMATION,
  SWIPE_MODE_SHOW_PASS_CONFIRMATION,
  SWIPE_MODE_SHOW_TUTORIAL,
} from '../../../models/AccountSettings';
import LoadingBackdrop from '../../../atoms/loader/LoadingBackdrop';
import useFirstSwipeConfirmationModal from './hooks/useFirstSwipeConfirmationModal';
import { Profile, ProfileMaker } from '../../../models/Profile';
import CompleteProfileModalV2 from '../../../modules/modals/CompleteProfileModalV2';
import { displayUserLocation, renderAvatar } from '../../../common';
import { EVENT_NAMES } from '../../../constants/mixpanel';
import useMixPanel from '../../../hooks/useMixPanel';
import TinderCard from '../../../packages/react-tinder-card';
import { isDiscoverModeSwipeEnabled } from '../../../utils/swipe';
import { useDashboardDiscoverMode } from '../../../contexts/DashboardDiscoverModeContext';
import useEvidently from '../../../hooks/useEvidently';
import {
  GMDiscoverModelEditableOfferVariations,
  GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME,
} from '../../../utils/evidentlyFeatures/GMDiscoverModeEditableOffer';
import DashboardDiscoverModePassFlagOverlay from './components/DashboardDiscoverModePassFlagOverlay';
import DashboardDiscoverModeOfferSentFlagOverlay from './components/DashboardDiscoverModeOfferSentFlagOverlay';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import {
  resetDiscoverModeAccountsAction,
  setDiscoverModeAccountsAction,
  updateDiscoverModeAccountsAction,
} from '../../../actions/discoverModeActions';
import useModal from '../../../hooks/useModal';
import useUnmount from '../../../hooks/useUnmount';
import styled from '@emotion/styled';
import { dimensions } from '../../../style';
import { getBrowserDetails } from '../../../utils/helpers';
import { trackDiscoverSwipingModeDesktopMetrics } from '../../../utils/evidentlyFeatures/discoverSwipingModeDesktop';
import { useDisableXBodyElementScroll } from '../../../hooks/useDisableBodyScroll';
import { mixpanelTrackDiscoverModeViewed } from '../../../utils/mixpanel/discoverModeViewed';
import { mixpanelTrackDiscoverHitZero } from '../../../utils/mixpanel/discoverHitZero';
import { mixpanelTrackDiscoverModeAction } from '../../../utils/mixpanel/discoverModeAction';

type AccountType = AccountDataObject & { status?: 'viewed' | 'offer_sent' };
export type CurrencySymbols = {
  currencyInitial: string;
  currencyLabel: string;
};

interface Props {
  ownProfile: Profile;
  ownAccount: Account;
  createNewOfferProp: (payload: any) => void;
  updateAccountConfigProp: (payload: { key: string; state: boolean }) => void;
  discoverModeAccounts: AccountType[];
  setDiscoverModeAccounts: (accounts: AccountType[]) => void;
  resetDiscoverModeAccountsAction: () => void;
  updateDiscoverModeAccounts: (accounts: AccountType[]) => void;
  discoverModeAccountsExpiration: number;
}

const DashboardDiscoverModeV2Container = styled('div')({
  maxWidth: 'calc(100% - 20px)',
  margin: '0px auto',
  textAlign: 'center',
  [dimensions.SCREEN_MIN_MD]: {
    maxWidth: 1400,
    margin: '0px auto',
  },
});

const NoMoreProfilesScreenContainer = styled('div')({
  maxWidth: 'calc(100% - 20px)',
  margin: '0 auto',
  textAlign: 'center',
  [dimensions.SCREEN_MIN_MD]: {
    maxWidth: 'calc(100% - 200px)',
    margin: '100px auto',
  },
});

const DashboardDiscoverModeV2: React.FC<Props> = ({
  createNewOfferProp,
  ownProfile,
  ownAccount,
  updateAccountConfigProp,
  discoverModeAccounts,
  setDiscoverModeAccounts,
  resetDiscoverModeAccountsAction,
  updateDiscoverModeAccounts,
  discoverModeAccountsExpiration,
}: Props) => {
  const defaultGlobalOfferPrice = ownProfile.defaultOfferAmount();
  const isSwipeEnabled = isDiscoverModeSwipeEnabled('dashboard_discover_swipe_enable');
  const { mixpanelData, resetMixpanelData, trackPageView } = useMixPanel();
  const { discoverActiveAccount, setDiscoverActiveAccount } = useDashboardDiscoverMode();
  const { useActiveVariation } = useEvidently();
  const gmEditableOfferVariation = useActiveVariation(
    GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME
  ) as GMDiscoverModelEditableOfferVariations;

  const {
    showFirstSwipeLeftConfirmation,
    showFirstSwipeRightConfirmation,
    initDiscoverConfirmationModal,
  } = useFirstSwipeConfirmationModal();

  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const { showTooManyPendingOffersErrorModal } = useModal();

  const [offerPrice, setOfferPrice] = useState(defaultGlobalOfferPrice);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassFlag, setShowPassFlag] = useState(false);
  const [isFulfilledSwipeLeft, setFulfilledSwipeLeft] = useState(false);
  const [isFulfilledSwipeRight, setFulfilledSwipeRight] = useState(false);
  const [showSentOfferFlag, setShowSentOfferFlag] = useState(false);
  const [showPassConfirmation, setShowPassConfirmation] = useState<undefined | boolean>(undefined);
  const [showOfferConfirmation, setShowOfferConfirmation] = useState<undefined | boolean>(undefined);
  const [hasError, setHasError] = useState(false);
  const [hasNoMoreResponse, setHasNoMoreResponse] = useState(false);
  const [showGenerousMandatoryProfileCompletionModal, setShowGenerousMandatoryProfileCompletionModal] = useState(false);
  const [disableManualClick, setDisableManualClick] = useState(false);
  const [autoSwiped, setAutoSwiped] = useState(false);
  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [currentIndex, setCurrentIndex] = useState(accounts.length - 1);
  const [ignoreList, setIgnoreList] = useState<string[]>([]);

  const currentIndexRef = useRef(currentIndex);

  const hasAccountsToShow = useMemo(() => accounts.filter(account => account.status === undefined).length > 0, [
    accounts,
  ]);

  useUnmount(() => {
    trackPageView(false, 'dashboard-swipe-mode');
  });

  useDisableXBodyElementScroll();

  const childRefs = useMemo(
    () =>
      Array(accounts.length)
        .fill(0)
        .map(() => React.createRef()),
    [accounts]
  );

  const currencySymbols: CurrencySymbols = useMemo(() => {
    let currencyInitial;
    let currencyLabelUnicode;

    if (ownAccount.data.currency) {
      currencyInitial = ownAccount.data.currency.data.initial;
      currencyLabelUnicode = ownAccount.data.currency.data.label_unicode;
    } else {
      currencyInitial = ownProfile && ownProfile?.profile?.currency?.data?.initial;
      currencyLabelUnicode = ownProfile && ownProfile?.profile?.currency?.data?.label_unicode;
    }

    return {
      currencyInitial,
      currencyLabel: String.fromCharCode(currencyLabelUnicode),
    };
  }, [ownAccount, ownProfile]);

  const restoreCurrentIndexCard = () => {
    childRefs[currentIndex].current && childRefs[currentIndex].current.restoreCard();
  };

  const updateCurrentIndex = val => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const getAccounts = async () => {
    setIsLoading(true);

    const ispreFetchExpired = discoverModeAccountsExpiration < new Date().getTime();

    if (
      !ispreFetchExpired &&
      typeof discoverModeAccounts !== 'undefined' &&
      discoverModeAccounts.length > 1 &&
      currentIndex !== 0
    ) {
      setHasNoMoreResponse(discoverModeAccounts.length < 1);
      setAccounts(discoverModeAccounts);
      setCurrentIndex(discoverModeAccounts.length - 1);
    } else {
      const response = await getSwipeModeAccounts();

      setHasNoMoreResponse(response?.data.data.length < 1);

      const filteredAccounts = response?.data.data.filter(account => typeof account?.profile !== 'undefined');
      const clonedAccounts = [...filteredAccounts];

      resetDiscoverModeAccountsAction();
      setDiscoverModeAccounts(clonedAccounts);
      setAccounts(clonedAccounts.reverse());
      updateCurrentIndex(clonedAccounts.length - 1);
    }
    setIsLoading(false);
    resetFulfilledSwipeFlags();
  };

  const updateOfferPrice = (val, isInvalidAmount) => {
    if (isInvalidAmount) {
      resetFulfilledSwipeFlags();
    }
    setHasError(isInvalidAmount);
    setDisableManualClick(false);
    setOfferPrice(val);
  };

  const resetFulfilledSwipeFlags = () => {
    setFulfilledSwipeLeft(false);
    setFulfilledSwipeRight(false);
    setShowPassFlag(false);
    setShowSentOfferFlag(false);
  };

  const passAccount = (accountHashId: string) => {
    const mxPayload = {
      ...mixpanelData[EVENT_NAMES.DISCOVER_MODE_ACTION],
      Result: 'Dismissed',
      'Offer Amount': 0,
    };

    removeAccountFromTheList(accountHashId);
    swipeModePassAccount(accountHashId);
    setHasError(false);

    setTimeout(() => {
      mixpanelTrackDiscoverModeAction(mxPayload, () => resetMixpanelData(EVENT_NAMES.DISCOVER_MODE_ACTION));
    }, 500);
  };

  const removeAccountFromTheList = (accountHashId: string) => {
    setIgnoreList(ignoreList => [...ignoreList, accountHashId]);
  };

  const offerAccount = async () => {
    if (!discoverActiveAccount || hasError) {
      return;
    }

    const hashId = ownProfile.profile.data.hash_id as string;
    const mxPayload = {
      ...mixpanelData[EVENT_NAMES.DISCOVER_MODE_ACTION],
      Result:
        discoverActiveAccount.profile?.data?.default_offer_amount === parseInt(offerPrice)
          ? 'Default Offer Sent'
          : 'Offer Sent',
      'Offer Amount': offerPrice,
    };

    removeAccountFromTheList(discoverActiveAccount.hash_id);
    setHasError(false);
    createNewOfferProp({
      account: discoverActiveAccount.hash_id,
      price: offerPrice,
      source: 'DashboardSwipeMode',
    });

    setTimeout(() => {
      mixpanelTrackDiscoverModeAction(mxPayload, () => resetMixpanelData(EVENT_NAMES.DISCOVER_MODE_ACTION));

      if (!getBrowserDetails().isMobile) {
        trackDiscoverSwipingModeDesktopMetrics(hashId, 'discoverSwipingModeDesktopOffersMadeDashboard');
      }
    }, 500);
  };

  const onPassButtonClickHandler = async () => {
    if (typeof showPassConfirmation !== 'undefined' && showPassConfirmation == true) {
      showFirstSwipeLeftConfirmation(
        {
          onFirstSwipeLeftConfirmAction: doNotShowAgain => {
            if (doNotShowAgain) {
              updateAccountConfigProp({ key: SWIPE_MODE_SHOW_PASS_CONFIRMATION, state: false });
              setShowPassConfirmation(false);
            }

            if (childRefs[currentIndex] && currentIndex < accounts.length) {
              setAutoSwiped(true);
              setTimeout(async () => {
                await childRefs[currentIndex].current.swipe('left'); // Swipe the card!
              }, 300);
            }
          },
          onFirstSwipeLeftCancelAction: () => {
            setDisableManualClick(false);
          },
        },
        discoverActiveAccount.username as string
      );
      return;
    }

    if (currentIndex < accounts.length) {
      setAutoSwiped(true);
      await childRefs[currentIndex].current.swipe('left'); // Swipe the card!
    }
  };

  const onOfferButtonClickHandler = async () => {
    if (hasError) {
      return;
    }

    if (typeof showOfferConfirmation !== 'undefined' && showOfferConfirmation === true) {
      showFirstSwipeRightConfirmation(
        {
          onFirstSwipeRightConfirmAction: doNotShowAgain => {
            if (doNotShowAgain) {
              updateAccountConfigProp({ key: SWIPE_MODE_SHOW_OFFER_CONFIRMATION, state: false });
              setShowOfferConfirmation(false);
            }
            if (currentIndex < accounts.length) {
              setAutoSwiped(true);
              setTimeout(async () => {
                await childRefs[currentIndex].current.swipe('right'); // Swipe the card!
              }, 300);
            }
          },
          onFirstSwipeRightCancelAction: () => {
            setDisableManualClick(false);
          },
        },
        ownProfile,
        discoverActiveAccount.username as string,
        offerPrice as number | string
      );

      return;
    }

    if (currentIndex < accounts.length) {
      setAutoSwiped(true);
      await childRefs[currentIndex].current.swipe('right'); // Swipe the card!
    }
  };

  const onSwipedLeftHandler = (index: number) => {
    const updatedIndex = index - 1;

    if (typeof showPassConfirmation !== 'undefined' && showPassConfirmation === true && !autoSwiped) {
      restoreCurrentIndexCard();

      showFirstSwipeLeftConfirmation(
        {
          onFirstSwipeLeftConfirmAction: doNotShowAgain => {
            if (doNotShowAgain) {
              updateAccountConfigProp({ key: SWIPE_MODE_SHOW_PASS_CONFIRMATION, state: false });
              setShowPassConfirmation(false);
            }

            updateCurrentIndex(updatedIndex);
            setShowPassFlag(true);
            passAccount(discoverActiveAccount.hash_id);

            if (updatedIndex < 0) {
              getAccounts();
            }
          },
          onFirstSwipeLeftCancelAction: () => {
            setDisableManualClick(false);
            restoreCurrentIndexCard();
          },
        },
        discoverActiveAccount.username as string
      );
      return;
    }

    updateCurrentIndex(updatedIndex);
    setShowPassFlag(true);
    setAutoSwiped(false);
  };

  const onSwipedRightHandler = (index: number) => {
    if (typeof showOfferConfirmation !== 'undefined' && showOfferConfirmation === true && !autoSwiped) {
      showFirstSwipeRightConfirmation(
        {
          onFirstSwipeRightConfirmAction: doNotShowAgain => {
            if (doNotShowAgain) {
              updateAccountConfigProp({ key: SWIPE_MODE_SHOW_OFFER_CONFIRMATION, state: false });
              setShowOfferConfirmation(false);
            }

            handleSendOffer(index);
          },
          onFirstSwipeRightCancelAction: () => {
            setDisableManualClick(false);
            restoreCurrentIndexCard();
          },
        },
        ownProfile,
        discoverActiveAccount.username as string,
        offerPrice as number | string
      );

      return;
    } else {
      handleSendOffer(index);
      setAutoSwiped(false);
    }
  };

  const handleSendOffer = index => {
    if (!ownAccount.isMandatoryProfileCompleted() && ownProfile.isGenerous()) {
      setShowGenerousMandatoryProfileCompletionModal(true);
      return;
    }

    showMandatoryCompleteProfile(
      () => {
        if (ownAccount.shouldShowTooManyLockedOffersDeals()) {
          showTooManyPendingOffersErrorModal({
            source: 'Discover Mode',
            onCloseCallback: () => {
              setDisableManualClick(false);
              restoreCurrentIndexCard();
            },
          });
          return;
        }

        if (ownAccount.shouldShowTooManySentOffers()) {
          showTooManyPendingOffersErrorModal({
            source: 'Discover Mode',
            onCloseCallback: () => {
              setDisableManualClick(false);
              restoreCurrentIndexCard();
            },
            type: 'sent',
          });
          return;
        }

        const updatedIndex = index - 1;

        updateCurrentIndex(updatedIndex);
        setShowSentOfferFlag(true);
        offerAccount();

        if (updatedIndex < 0) {
          getAccounts();
        }
      },
      () => {
        restoreCurrentIndexCard();
      },
      'Request Offer'
    );
  };

  const swiped = (direction: 'left' | 'right' | 'up' | 'down', index: number) => {
    resetFulfilledSwipeFlags();

    if (direction === 'left') {
      onSwipedLeftHandler(index);
      return;
    }

    if (direction === 'right') {
      onSwipedRightHandler(index);
      return;
    }
  };

  const handleClickToSwipe = async (direction: 'left' | 'right') => {
    if (disableManualClick) return;

    setDisableManualClick(true);

    if (direction === 'left') {
      onPassButtonClickHandler();
    } else if (direction === 'right') {
      onOfferButtonClickHandler();
    }
  };

  const handleOnCardLeftTheScreen = useCallback(
    dir => {
      if (dir === 'left' && !showPassConfirmation) {
        passAccount(discoverActiveAccount.hash_id);

        if (currentIndex === 0) {
          getAccounts();
        }
      }

      setDisableManualClick(false);
    },
    [currentIndex, discoverActiveAccount, showPassConfirmation, showOfferConfirmation, offerPrice]
  );

  const handleSwipeRequirementFulfilled = useCallback(
    (dir: string) => {
      if (hasError) {
        resetFulfilledSwipeFlags();
        return;
      }

      setShowPassFlag(false);
      setShowSentOfferFlag(false);

      if (dir === 'left') {
        setFulfilledSwipeLeft(true);
        return;
      }

      if (dir === 'right') {
        setFulfilledSwipeRight(true);
        return;
      }

      resetFulfilledSwipeFlags();
    },
    [hasError]
  );

  useEffect(() => {
    if (accounts.length > 0 && accounts[currentIndex]) {
      const otherAccount = accounts[currentIndex];
      setDiscoverActiveAccount({
        ...accounts[currentIndex],
        account: AccountMaker.create(otherAccount),
        profile: otherAccount.profile,
      });
      setOfferPrice(otherAccount.profile?.data?.default_offer_amount ?? defaultGlobalOfferPrice);
    }
  }, [currentIndex, accounts]);

  useEffect(() => {
    setShowPassConfirmation(ownAccount.data.account_config?.swipe_mode_show_pass_confirmation);
    setShowOfferConfirmation(ownAccount.data.account_config?.swipe_mode_show_offer_confirmation);
  }, [
    ownAccount.data.account_config?.swipe_mode_show_pass_confirmation,
    ownAccount.data.account_config?.swipe_mode_show_offer_confirmation,
  ]);

  useEffect(() => {
    if (accounts.length > 0 && ownProfile?.profile?.data?.hash_id && !isLoading) {
      mixpanelTrackDiscoverModeViewed({ 'Number of Cards': accounts.length });

      // page view mixpanel custom page name. will delete this after the trackPageView fired
      localStorage.setItem('mxTrackPageView_page_name', 'Discover Mode');
    }
  }, [accounts, ownProfile?.profile?.data?.hash_id, isLoading]);

  useEffect(() => {
    if (!hasAccountsToShow && !isLoading && hasNoMoreResponse && ownProfile?.profile?.data?.hash_id) {
      mixpanelTrackDiscoverHitZero();
    }
  }, [hasAccountsToShow, ownProfile?.profile?.data?.hash_id, isLoading, hasNoMoreResponse]);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (ignoreList && ignoreList.length > 0) {
      const filteredAccounts = accounts.filter(account => !ignoreList.includes(account.hash_id));

      updateDiscoverModeAccounts(filteredAccounts);
    }
  }, [ignoreList]);

  if (isLoading) {
    return (
      <>
        <TrackLoadingToMixpanel page={`Discover Mode - loading profiles`} />
        <LoadingBackdrop active={true} showLoadingBg={false} />{' '}
      </>
    );
  }

  if (!hasAccountsToShow && !isLoading && hasNoMoreResponse) {
    return (
      <NoMoreProfilesScreenContainer>
        <DashboardDiscoverModeNoMoreProfilesScreen />
      </NoMoreProfilesScreenContainer>
    );
  }

  const swipeRotation = getBrowserDetails().isMobile ? 45 : 360;

  return (
    <DashboardDiscoverModeV2Container>
      {ownAccount.data.account_config?.swipe_mode_show_tutorial &&
        (isSwipeEnabled ? (
          <DashboardDiscoverModeTutorial
            onConfirm={() => updateAccountConfigProp({ key: SWIPE_MODE_SHOW_TUTORIAL, state: false })}
          />
        ) : (
          <DashboardDiscoverModeTutorialWithoutSwipe
            onConfirm={() => updateAccountConfigProp({ key: SWIPE_MODE_SHOW_TUTORIAL, state: false })}
          />
        ))}
      <div className="cardContainer">
        {accounts.map((account, index) => (
          <TinderCard
            ref={childRefs[index]}
            key={account.hash_id}
            className="swipe"
            onSwipe={dir => {
              swiped(dir, index);
            }}
            preventSwipe={hasError ? ['up', 'down', 'right'] : ['up', 'down']}
            disableTouchSwipe={!isSwipeEnabled || discoverActiveAccount?.hash_id !== account.hash_id}
            swipeThreshold={getBrowserDetails().isMobile ? 100 : 300}
            onCardLeftScreen={handleOnCardLeftTheScreen}
            swipeRequirementType="position"
            onSwipeRequirementFulfilled={handleSwipeRequirementFulfilled}
            onSwipeRequirementUnfulfilled={() => resetFulfilledSwipeFlags()}
            swipeRotation={swipeRotation}
            disabledAnimationY={true}
          >
            <DashboardDiscoverModeProfileCard profile={account.profile.data} account={AccountMaker.create(account)} />
          </TinderCard>
        ))}

        {isFulfilledSwipeLeft && <DashboardDiscoverModePassFlagOverlay />}
        {showPassFlag && (
          <DashboardDiscoverModePassFlag
            onHidden={() => {
              setShowPassFlag(false);
            }}
          />
        )}
        {isFulfilledSwipeRight && <DashboardDiscoverModeOfferSentFlagOverlay currencySymbols={currencySymbols} />}
        {showSentOfferFlag && (
          <DashboardDiscoverModeOfferSentFlag
            onHidden={() => setShowSentOfferFlag(false)}
            currencySymbols={currencySymbols}
          />
        )}
      </div>
      <DashboardDiscoverModeVideoPreviewLightBox />
      {showGenerousMandatoryProfileCompletionModal && discoverActiveAccount && (
        <CompleteProfileModalV2
          avatar={renderAvatar(
            discoverActiveAccount?.profile?.data?.avatar?.data?.urls ||
              discoverActiveAccount?.photo.data?.[0].urls ||
              {}
          )}
          username={discoverActiveAccount?.username || ''}
          location={displayUserLocation(ownProfile.profile, discoverActiveAccount?.profile.data)}
          age={discoverActiveAccount?.profile.data.age}
          editProfileLink={`/profile/${ownAccount.data.hash_id}`}
          isOpen={showGenerousMandatoryProfileCompletionModal}
          onClose={() => {
            restoreCurrentIndexCard();
            setShowGenerousMandatoryProfileCompletionModal(false);
          }}
        />
      )}
      <DashboardDiscoverModeOfferActions
        offerPrice={offerPrice}
        updateOfferPrice={updateOfferPrice}
        swipe={handleClickToSwipe}
        hasError={hasError}
        currencySymbols={currencySymbols}
        gmEditableOfferVariation={gmEditableOfferVariation}
        disableManualClick={disableManualClick}
      />
      {initDiscoverConfirmationModal()}
    </DashboardDiscoverModeV2Container>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    createNewOfferProp: payload => {
      dispatch(createNewOffer(payload));
    },
    updateAccountConfigProp: payload => {
      dispatch(updateAccountConfig(payload));
    },
    setDiscoverModeAccounts: accounts => {
      dispatch(setDiscoverModeAccountsAction(accounts));
    },
    resetDiscoverModeAccountsAction: () => {
      dispatch(resetDiscoverModeAccountsAction());
    },
    updateDiscoverModeAccounts: accounts => {
      dispatch(updateDiscoverModeAccountsAction(accounts));
    },
  };
};

const mapStateToProps = state => {
  return {
    ownProfile: ProfileMaker.create(state.profile.profile),
    ownAccount: AccountMaker.create(state.profile),
    discoverModeAccounts: state.dashboard.DiscoverModeAccounts,
    discoverModeAccountsExpiration: state.dashboard.DiscoverModeAccountsExpiration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDiscoverModeV2);
