import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../../../atoms/buttons/Button';
import CompleteProfileModalV2 from '../../../modules/modals/CompleteProfileModalV2';
import { displayUserLocation } from '../../../common';
import { s3BucketDirect } from '../../../config/Master';
import VideoGear from '../../../icons/VIdeoGear';
import MediaPlayIcon from '../../../atoms/icons/MediaPlayIcon';
import { dimensions } from '../../../style';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import useMixPanel from '../../../hooks/useMixPanel';
import useNav from '../../../hooks/useNav';
import { useDisableBodyElementScroll } from '../../../hooks/useDisableBodyScroll';
import { Profile, ProfileMaker } from '../../../models/Profile';
import { Account, AccountMaker } from '../../../models/Account';
import useModal from '../../../hooks/useModal';
import OfferModalFooter from '../Offers/OfferModalFooter';
import FavOfferCreate from '../Offers/FavOfferCreate';
import { clearErrors } from '../../../actions/commonActions';
import { counterOffer, createNewOffer, sendSuggestion } from '../../../actions/offerActions';
import OfferSuccess from '../Offers/OfferSuccess';
import { displayWarnMessage } from '../../../actions/notificationActions';
import { EVENT_NAMES } from '../../../constants/mixpanel';
import {
  mixpanelTrackLightBoxClosed,
  updateMixpanelLightBoxClosedPayload,
} from '../../../utils/mixpanel/lightboxClosed';
import Lightbox from '../../common/Lightbox';
import './PhotoLightbox.css';
import { IAccount } from '../../../types/common';

const PrivatePhotoMessageContainer = styled('div')({
  position: 'fixed',
  zIndex: 1001,
  color: '#ffffff',
  textAlign: 'center',
  left: 0,
  bottom: '12%',
  width: '100%',
});

const VideoContainer = styled('div')({
  position: 'fixed',
  zIndex: 1002,
  color: '#ffffff',
  textAlign: 'center',
  left: '50%',
  top: 'calc(50% + 30px)',
  transform: 'translate(-50%, -50%)',
  maxWidth: 480,
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  [dimensions.SCREEN_MAX_SM]: {
    maxWidth: 'none',
  },
});

const VideoAction = styled('div')({
  zIndex: 1001,
  height: '100%',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VideoNavButton = styled('button')({
  border: 'none',
  position: 'absolute',
  top: '-60px',
  bottom: 0,
  width: '20px',
  height: '34px',
  padding: '40px 30px',
  margin: 'auto',
  cursor: 'pointer',
  opacity: 0.7,
  zIndex: 1003,
  ':hover': {
    opacity: 1,
  },
  display: 'none',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'block',
  },
});

const MediaNavButtonRight = styled(VideoNavButton)({
  right: 0,
  background:
    'rgba(0, 0, 0, 0.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") no-repeat center',
});

const MediaNavButtonLeft = styled(VideoNavButton)({
  left: 0,
  background:
    'rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) no-repeat center',
});

const VideoPlayer = styled('video')({
  margin: '0 auto',
  width: '95%',
  zIndex: 1000,
  [dimensions.SCREEN_MAX_MD_2]: {
    maxWidth: 'none',
    width: '90%',
  },
});

const age = account => (account.profile && account.profile.data ? account.profile.data.age : ``);

const setUseStateOptions = (currentUserIsGenerous, type) => {
  switch (type) {
    case 'button':
      return currentUserIsGenerous ? 'Make Offer' : 'Request Offer';
    case 'private':
      return currentUserIsGenerous ? 'an offer' : 'a request';
    default:
      return;
  }
};

const renderPrivatePhotoLabel = (userHasPrivatePhotos, photoIndex, photoListLength, setShowPrivatePhotoLabel) => {
  if (userHasPrivatePhotos) {
    setShowPrivatePhotoLabel(photoIndex === parseInt(photoListLength, 10) - 1);
  }
};

const privateLabelCond = (lightboxIsOpen, userHasPrivatePhotos, currentImageProps, photoListLength) => {
  return lightboxIsOpen && userHasPrivatePhotos && currentImageProps === parseInt(photoListLength, 10) - 1;
};

const setCurrImgProps = currentImageProps => currentImageProps || 0;

const manyOffers = (cannot_make_offer_reasons, showWarn, handleOffersLimitError) => {
  const cond = cannot_make_offer_reasons[0].indexOf('many unlocked offers') > -1;
  if (cond) handleOffersLimitError();
};

const renderOfferOptions = (cannot_make_offer_reasons, showWarn, onOfferClick, handleOffersLimitError) => {
  if (!cannot_make_offer_reasons) {
    onOfferClick(false);
  } else if (cannot_make_offer_reasons[0]) {
    manyOffers(cannot_make_offer_reasons, showWarn, handleOffersLimitError);
  } else {
    showWarn(`You cannot make an offer: ${cannot_make_offer_reasons[0]}`);
  }
};

const srcImage = (images, cond) => {
  return images.length > 1 ? images[cond].src : undefined;
};

interface PhotoLightboxProps {
  images: any;
  allowZoom: any;
  lightboxIsOpen: any;
  privatePhotoCount: any;
  isOnboarded: any;
  offer: any;
  justSuggested: any;
  ignoreStatus: any;
  isOfferBtnVisible: any;
  currentImage: any;
  userHasPrivatePhotos: any;
  onClose: any;
  photoListLength: any;
  myprofile: any;
  onOfferClick: any;
  showWarn: any;
  currentUserIsGenerous: any;
  account: IAccount;
  avatar: any;
  isPhotoMessage: boolean;
  ownAccount: Account;
  ownProfile: Profile;
  errors: any;
  clearErrors: any;
  createNewOffer: any;
  sendSuggestion: any;
  counterOffer: any;
}

const PhotoLightbox = ({
  images,
  allowZoom,
  lightboxIsOpen,
  privatePhotoCount,
  isOnboarded,
  offer,
  justSuggested,
  ignoreStatus,
  isOfferBtnVisible,
  currentImage: currentImageProps,
  userHasPrivatePhotos,
  onClose,
  photoListLength,
  myprofile,
  onOfferClick,
  currentUserIsGenerous,
  account,
  avatar,
  isPhotoMessage = false,
  ownAccount,
  ownProfile,
  errors,
  clearErrors,
  createNewOffer,
  sendSuggestion,
  showWarn,
  counterOffer,
}: PhotoLightboxProps): any => {
  const [currentImage, setCurrentImage] = useState(setCurrImgProps(currentImageProps));
  const [showPrivatePhotoLabel, setShowPrivatePhotoLabel] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [finishProfileModalOpen, setFinishProfileModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [hasJustCompleteMandatoryProfile, setHasJustCompleteMandatoryProfile] = useState(false);
  const [buttonText] = useState(setUseStateOptions(currentUserIsGenerous, 'button'));
  const [privateText] = useState(setUseStateOptions(currentUserIsGenerous, 'private'));
  const videoRef = useRef();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const { trackPackagesPageVisited, mixpanelData, resetMixpanelData, setMixpanelDataByKeyHandler } = useMixPanel();
  const { basicModal, resetModal } = useModal();
  const { goTo } = useNav();
  const { showTooManyPendingOffersErrorModal } = useModal();

  const [videoPoster, setVideoPoster] = useState('');
  const [photoViewCount, setPhotoViewCount] = useState(1);

  useDisableBodyElementScroll(lightboxIsOpen);

  /**
   * Need to handle in useEffect
   * because for some reason the `myprofile.cannot_make_offer_reasons`
   * is not being updated when the user finish and submit the mandatory profile modal
   */
  useEffect(() => {
    if (hasJustCompleteMandatoryProfile) {
      renderOfferOptions(myprofile?.cannot_make_offer_reasons, showWarn, onOfferClick, handleOffersLimitError);
    }
  }, [hasJustCompleteMandatoryProfile, myprofile?.cannot_make_offer_reasons]);

  useEffect(() => {
    renderVideoPlayer(currentImage);

    if (typeof images[currentImage]?.src !== 'undefined') {
      const thumbnail = images[currentImage].src;
      const shouldAppendHashInThumbnail = ['pending', 'new'].includes(images[currentImage]?.meta?.status);
      const mediaUrl = new URL(thumbnail);
      const hasSignedUrl = mediaUrl.searchParams.has('Signature');

      setVideoPoster(
        shouldAppendHashInThumbnail && !hasSignedUrl ? `${thumbnail}?hash=${new Date().getTime()}` : thumbnail
      );
    }
  }, [currentImageProps, currentImage, images.length]);

  useEffect(() => {
    if (privateLabelCond(lightboxIsOpen, userHasPrivatePhotos, currentImageProps, photoListLength)) {
      setShowPrivatePhotoLabel(true);
    }
  }, [lightboxIsOpen, userHasPrivatePhotos, currentImageProps, photoListLength]);

  useEffect(() => {
    setCurrentImage(currentImageProps);
    setVideoSrc(null);
    renderVideoPlayer(currentImageProps);
  }, [currentImageProps, renderVideoPlayer]);

  useEffect(() => {
    if (videoSrc) setShowPlayButton(true);
  }, [videoSrc]);

  const getTitle = (curr, len, isPrivateVisible = false) => {
    if (isPhotoMessage) return;

    const privateEye = `${s3BucketDirect}thumbs/private_eye.png`;

    return (
      <span>
        {' '}
        {`${curr} of ${len} ${isPrivateVisible ? '- Private' : ''}`}
        {isPrivateVisible && <img src={privateEye} style={{ width: 20 + 'px', marginLeft: 5 + 'px' }} alt="" />}
      </span>
    );
  };

  useEffect(() => {
    if (photoViewCount > 0) {
      updateMixpanelLightBoxClosedPayload(setMixpanelDataByKeyHandler, photoViewCount);
    }
  }, [photoViewCount]);

  const closeLightbox = () => {
    onClose(currentImage);
    setCurrentImage(-1);
    setShowPrivatePhotoLabel(false);

    if (ownProfile?.hashId() !== account?.hash_id) {
      mixpanelTrackLightBoxClosed(mixpanelData, resetMixpanelData);
      setPhotoViewCount(1);
    }
  };

  const gotoPrevious = () => {
    const photoIndex = (currentImage + images.length - 1) % images.length;
    setCurrentImage(photoIndex);
    setVideoSrc(null);
    renderVideoPlayer(photoIndex);
    renderPrivatePhotoLabel(userHasPrivatePhotos, photoIndex, photoListLength, setShowPrivatePhotoLabel);
    setPhotoViewCount(count => count + 1);
  };

  const gotoNext = () => {
    const photoIndex = (currentImage + 1) % images.length;
    setCurrentImage(photoIndex);
    setVideoSrc(null);
    renderPrivatePhotoLabel(userHasPrivatePhotos, photoIndex, photoListLength, setShowPrivatePhotoLabel);
    renderVideoPlayer(photoIndex);
    setPhotoViewCount(count => count + 1);
  };

  const renderVideoPlayer = useCallback(
    mediaIndex => {
      if (images) {
        const media = images[mediaIndex];
        const isVideo = media && media.type === 'video';

        setVideoSrc(null);
        setShowVideoPlayer(false);

        if (isVideo) {
          setShowVideoPlayer(true);
          setTimeout(() => {
            setVideoSrc(images[mediaIndex].meta.mediaUrl);
          }, 50);
        }
      }
    },
    [images]
  );

  const handleOffersLimitError = () => {
    trackPackagesPageVisited({ Source: 'Offers Limit' });
    goTo('/packages?newPayWall=true');
  };

  const handleOfferSuccess = (toggle, amount = '', isCounter = false) => {
    resetMixpanelData(EVENT_NAMES.OFFER_MADE);

    basicModal({
      dateTestId: 'offer-success',
      modalContentLabel: 'Success Offer Modal',
      isModalOpen: toggle,
      modalBody: (
        <Fragment>
          <OfferSuccess
            offerAmount={amount}
            avatarSrc={avatar}
            username={account.username}
            successOpen={resetModal}
            ignoreStatus={() => null}
            buttonID="interestedOffers"
            currentUserIsGenerous={currentUserIsGenerous}
            isCounter={isCounter}
          />
          <OfferModalFooter />
        </Fragment>
      ),
    });
  };

  const handleCreateNewOffer = formData => {
    const mxOfferMadePayload = mixpanelData[EVENT_NAMES.OFFER_MADE];

    return createNewOffer({ ...formData, source: mxOfferMadePayload.source });
  };

  const handleSuggestion = profileId => {
    return sendSuggestion(profileId);
  };

  const handleCounterOffer = formData => {
    return counterOffer(formData);
  };

  const handleOfferClick = () => {
    // open offer modal
    const { cannot_make_offer_reasons } = myprofile;

    showMandatoryCompleteProfile(
      () => {
        const shouldShowOldMandatoryProfileModal =
          currentUserIsGenerous && cannot_make_offer_reasons && cannot_make_offer_reasons[0];

        if (shouldShowOldMandatoryProfileModal) {
          toggleFinishProfileModal();
          return;
        }

        setHasJustCompleteMandatoryProfile(false);

        basicModal({
          dataTestID: 'offer-modal',
          closeBtnDataTestId: 'fav',
          modalContentLabel: 'Create Offer Modal',
          modalBody: (
            <Fragment>
              <FavOfferCreate
                avatarSrc={avatar}
                username={account.username}
                hashId={account.hash_id}
                clearErrors={clearErrors}
                createNewOffer={handleCreateNewOffer}
                successOpen={handleOfferSuccess}
                counterOffer={handleCounterOffer}
                isCounter={false}
                closeModal={resetModal}
                currencyInitial={account.currency.data.initial}
                currencyLabel={account.currency.data.label_unicode}
                currentUserIsGenerous={currentUserIsGenerous}
                errors={errors}
                sex={account.profile.data.sex}
                userGender={account.profile.data.sex}
                sendSuggestion={handleSuggestion}
                showWarn={showWarn}
                cannot_make_offer_reasons={myprofile.cannot_make_offer_reasons}
                buttonID="interestedOffers"
                isSuggestRequest={false}
                pageTitle={'Profile'}
                otherProfile={account.profile}
                title={currentUserIsGenerous ? 'Make an Offer!' : 'Request A Date!'}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Make Offer'
    );
  };

  const toggleFinishProfileModal = () => {
    if (!ownAccount.isMandatoryProfileCompleted() && ownProfile.isGenerous()) {
      setFinishProfileModalOpen(!finishProfileModalOpen);
    } else if (ownProfile.isGenerous() && ownAccount.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
    } else if (ownProfile.isGenerous() && ownAccount.shouldShowTooManySentOffers()) {
      showTooManyPendingOffersErrorModal({ type: 'sent' });
    }
  };

  if (!lightboxIsOpen || !images[currentImage] || !images[currentImage].src) {
    return null;
  }

  const renderVideoProcessing = () => {
    if (showPrivatePhotoLabel) {
      return null;
    }

    return (
      showVideoPlayer && (
        <VideoContainer>
          <div style={{ width: '100%', flex: 1 }}>
            <VideoGear />
            <div style={{ fontWieght: 'bold', marginTop: 10 }}>Video Processing...</div>
          </div>
        </VideoContainer>
      )
    );
  };

  const onVideoPlay = () => {
    if (videoSrc && videoRef && videoRef.current) {
      videoRef.current.load();
      setTimeout(() => {
        videoRef.current.play();
      }, 0);
      setShowPlayButton(false);
    }
  };

  const renderNewFeatureMandatoryPopup = () => {
    return (
      <Button
        type="button"
        buttonType="primary"
        onClick={handleOfferClick}
        disabled={offer || justSuggested || ignoreStatus}
      >
        {buttonText}
      </Button>
    );
  };

  // To keep, will e useful later when experiment is enable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderControlProfileCompletion = () => {
    return isOnboarded ? (
      <Button
        type="button"
        buttonType="primary"
        onClick={handleOfferClick}
        disabled={offer || justSuggested || ignoreStatus}
      >
        {buttonText}
      </Button>
    ) : (
      <React.Fragment>
        <Button type="button" buttonType="primary" onClick={toggleFinishProfileModal}>
          {buttonText}
        </Button>
        <CompleteProfileModalV2
          avatar={avatar}
          username={account.username}
          location={displayUserLocation(account.profile, account.profile.data)}
          age={age(account)}
          editProfileLink={`/profile/${myprofile.hash_id}`}
          closeLightbox={closeLightbox}
          isOpen={finishProfileModalOpen}
          onClose={toggleFinishProfileModal}
        />
      </React.Fragment>
    );
  };

  const mainSrc = images[currentImage].type === 'video' && !showPrivatePhotoLabel ? '' : images[currentImage].src;

  return (
    <React.Fragment>
      <Lightbox
        animationDisabled
        mainSrc={mainSrc}
        nextSrc={srcImage(images, (currentImage + 1) % images.length)}
        prevSrc={srcImage(images, (currentImage + images.length - 1) % images.length)}
        onCloseRequest={closeLightbox}
        onMovePrevRequest={gotoPrevious}
        onMoveNextRequest={gotoNext}
        clickOutsideToClose={true}
        enableZoom={allowZoom && images[currentImage].type !== 'video'}
        imageTitle={getTitle(
          currentImage + 1,
          images.length,
          images[currentImage].private === false && images[currentImage].privateReal === true
        )}
        wrapperClassName="profile-lightbox"
        currentImageIndex={currentImage}
      />
      {!showPrivatePhotoLabel && showVideoPlayer && videoSrc && images[currentImage].meta.status !== 'pending' ? (
        <VideoContainer>
          <VideoPlayer
            controls={!showPlayButton}
            playsInline
            ref={videoRef}
            poster={videoPoster}
            onEnded={() => {
              setShowPlayButton(true);
            }}
          >
            <source src={videoSrc} />
          </VideoPlayer>
          <MediaNavButtonLeft onClick={gotoPrevious} />
          <MediaNavButtonRight onClick={gotoNext} />
          {showPlayButton && (
            <VideoAction onClick={onVideoPlay} style={{ cursor: 'pointer' }}>
              <MediaPlayIcon
                customWrapperStyle={{ top: 0, position: 'relative', cursor: 'pointer', width: 'auto' }}
                onClick={onVideoPlay}
                maskId={`gallery-${currentImage}`}
              />
            </VideoAction>
          )}
        </VideoContainer>
      ) : (
        renderVideoProcessing()
      )}
      {showPrivatePhotoLabel && (
        <PrivatePhotoMessageContainer>
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              margin: 0,
              padding: 0,
              color: '#bc2028',
            }}
          >
            +{privatePhotoCount} <br />
            Private Photos/Videos
          </p>
          <p
            style={{
              fontSize: '12px',
              padding: '0 2rem',
              margin: '10px',
            }}
          >
            You can see private photos/videos if they respond to {privateText}
          </p>
        </PrivatePhotoMessageContainer>
      )}
      {(isOfferBtnVisible || showPrivatePhotoLabel) && (
        <div>
          <CompleteProfileModalV2
            avatar={avatar}
            username={account.username}
            location={displayUserLocation(account.profile, account.profile.data)}
            age={age(account)}
            editProfileLink={`/profile/${myprofile.hash_id}`}
            closeLightbox={closeLightbox}
            isOpen={finishProfileModalOpen}
            onClose={toggleFinishProfileModal}
          />
          <div
            style={{
              position: 'fixed',
              zIndex: 1001,
              textAlign: 'center',
              bottom: '5%',
              left: '35%',
              right: '35%',
            }}
          >
            {currentUserIsGenerous ? renderControlProfileCompletion() : renderNewFeatureMandatoryPopup()}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

PhotoLightbox.propTypes = {
  lightboxIsOpen: PropTypes.bool.isRequired,
  privatePhotoCount: PropTypes.number,
  isOnboarded: PropTypes.bool,
  isBlocked: PropTypes.bool,
  onOfferClick: PropTypes.func.isRequired,
  justSuggested: PropTypes.bool,
  ignoreStatus: PropTypes.bool,
  username: PropTypes.string.isRequired,
  hashId: PropTypes.string.isRequired,
  isOfferBtnVisible: PropTypes.bool,
};

PhotoLightbox.defaultProps = {
  privatePhotoCount: 0,
  isOnboarded: true,
  isOfferBtnVisible: false,
};

const mapStateToProps = state => {
  return {
    ownProfile: ProfileMaker.create(state.profile.profile),
    ownAccount: AccountMaker.create(state.profile),
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearErrors: () => {
      dispatch(clearErrors());
    },
    createNewOffer: formData => {
      return dispatch(createNewOffer(formData, true));
    },

    sendSuggestion: username => {
      return dispatch(sendSuggestion(username, false, null, ''));
    },
    counterOffer: formData => {
      return dispatch(counterOffer(formData, false, ownProps.navigate, null, null));
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoLightbox);
