import { useEffect, useState } from 'react';
import { getCountryCode, isEU } from '../utils/helpers';
import { searchByCoordinates, TomtomLocation } from '../utils/tomtom';
import { getLocalItem, setLocalItem } from '../common';

interface useGeolocationDataProps {
  isLocationEU: boolean;
  countryCode: string | undefined;
}

const useGeolocationData = (tomtomEnabled: boolean): useGeolocationDataProps => {
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const [isLocationEU, setIsLocationEU] = useState<boolean>(false);

  const locationChanged = (pos: any): boolean => {
    const location = getLocalItem('wyp_geolocation');

    if (location && location?.lat !== pos?.lat && location?.lng !== pos.lng) {
      const jsonLocation = JSON.parse(location);

      setCountryCode(jsonLocation.country.toLowerCase());
      return false;
    }

    return true;
  };

  useEffect(() => {
    const fetchLocation = (): void => {
      if (typeof navigator.geolocation === 'undefined') return;

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          if (!locationChanged(pos)) {
            return;
          }

          if (tomtomEnabled) {
            searchByCoordinates(pos).then((res: any) => {
              const place: TomtomLocation = res[0];
              if (place) {
                const countryCode = getCountryCode(place.country)[1];
                setCountryCode(countryCode.toLowerCase());

                setLocalItem('wyp_geolocation', JSON.stringify({ ...pos, ...{ country: countryCode } }));
              }
            });
          } else {
            if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
              return;
            }

            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ location: pos }, function(results, state) {
              if (state !== 'OK') {
                return;
              }

              const place = results.find((myPlace: any) => myPlace.types.indexOf('locality') > -1);

              if (place) {
                const countryCode = getCountryCode(place.formatted_address)[1];
                setCountryCode(countryCode.toLowerCase());
                setIsLocationEU(isEU(place.formatted_address));
              }
            });
          }
        },
        (error: GeolocationPositionError) => {
          console.error('Error in getting current location:', error);
        }
      );
    };

    fetchLocation();
  }, [tomtomEnabled]);

  return {
    countryCode,
    isLocationEU,
  };
};

export default useGeolocationData;
