/**
 * @Author: zachalam
 * @Date:   2017-01-23T15:07:27-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-24T12:06:20-08:00
 */

import * as sdk from '../sdk';
import { refreshProfile, setProcessing } from './profileActions';
import { storeErrors, clearErrors, setLoading, killLoading, forgotPasswordSending } from './commonActions';
import { displayFailMessage } from './notificationActions';
import { toaster, browserHistory, getLocalItem, removeLocalItem } from '../common';
import store from '../store';
import MasterConfig from '../config/Master';
import remove from 'lodash/remove';
import { vysionService } from '../utils/vysion/vysionService';
import { isEmpty } from '../utils/helpers';
import { NavigateFunction } from 'react-router-dom';
import { removeIPQSCookies } from '../utils/ipQualityScore';
import { mixpanelTrackUserLoggedIn } from '../utils/mixpanel/userLoggedIn';
import { mixpanelTrackSmsCodeVerified } from '../utils/mixpanel/smsCodeVerified';

export const saveToken = (payload: any): any => {
  return {
    type: 'LOGIN_USER',
    payload,
  };
};

export const setAuthLoading = (): { type: string } => {
  return {
    type: 'IS_FETCHING',
  };
};
export const resetAuthLoading = (): { type: string } => {
  return {
    type: 'RESET_IS_FETCHING',
  };
};

export const killToken = (): any => {
  return {
    type: 'LOGOUT_USER',
    payload: { isAuthenticated: false, jwToken: false },
  };
};

export const setResetTokenValidity = (payload: any): any => {
  return {
    type: 'RESET_TOKEN_VALIDITY',
    payload,
  };
};

export const storeHttpRequest = (payload: any): any => (dispatch: any) => {
  dispatch({
    type: 'STORE_HTTP_REQUEST',
    payload,
  });
  return Promise.resolve();
};

export const removeHttpRequest = (payload: any): any => (dispatch: any) => {
  dispatch({
    type: 'REMOVE_HTTP_REQUEST',
    payload,
  });
  return Promise.resolve();
};

export const storeWebTimestamp = (webTimestamp: any, bundleUpdate = false): any => (dispatch: any) => {
  dispatch({
    type: 'STORE_WEB_TIMESTAMP',
    payload: { webTimestamp, bundleUpdate },
  });
  return Promise.resolve();
};

export const storeReloaded = (): any => (dispatch: any): any => {
  dispatch({
    type: 'STORE_RELOADED',
  });
  return Promise.resolve();
};

export const removeStaleHttpRequests = (): any => (dispatch: any): any => {
  dispatch({
    type: 'REMOVE_STALE_HTTP_REQUESTS',
  });
  return Promise.resolve();
};

export const removeBlockFlags = (): any => ({
  type: 'AUTH_REMOVE_BLOCK_PROPERTIES',
});

export const refreshUserToken = (tokenPath: any): any => {
  const freshTokenPromise = sdk
    .getNewAuthToken(tokenPath)
    .then(response => {
      // token received, save new token
      store.dispatch(saveToken(response.data));
      store.dispatch({ type: 'DONE_REFRESHING_TOKEN' });

      return response.data.token
        ? Promise.resolve(response.data.token)
        : Promise.reject({ message: 'could not refresh' });
    })
    .catch(() => {
      return Promise.reject({ message: 'could not refresh' });
    });

  store.dispatch({ type: 'REFRESHING_TOKEN', payload: freshTokenPromise });

  return freshTokenPromise;
};

export const logoutUserAction = (): any => {
  return (dispatch, getState) => {
    return sdk
      .killAuthToken(getState().auth.jwToken)
      .then(() => {
        dispatch(killToken());
      })
      .catch(error => {
        console.warn('Error log out API', error);
      });
  };
};

export const createAccountAction = (formData: any): any => {
  return () => {
    return sdk.createUserAccount(formData);
  };
};

export const createUserAccountV2Action = (formData: any): any => {
  return () => {
    return sdk.createUserAccountV2(formData);
  };
};

export const requestPassReminder = (email: string): any => {
  return dispatch => {
    return sdk
      .requestPassReminder(email)
      .then(() => {
        // password reminder accepted.
        dispatch(clearErrors());
        dispatch(forgotPasswordSending(true, 'success'));
      })
      .catch(error => {
        // not valid email.
        try {
          const { data } = error.response;
          dispatch(storeErrors(data));
        } catch (e) {
          console.error('invalid response.data', e);
        }
        dispatch(forgotPasswordSending(false, 'error'));
      });
  };
};

export const newAuthLogin = (
  login: any,
  password: any,
  navigate: NavigateFunction,
  location: Location,
  captcha: string
): any => {
  return (dispatch: any) => {
    dispatch(setAuthLoading());
    return sdk.getFirstAuthToken({ login, password, 'h-captcha-response': captcha }).then(response => {
      // save token
      dispatch(saveToken(response.data));

      // retrieve logged in profile.
      dispatch(refreshProfile()).then(result => {
        if (result?.hash_id) {
          mixpanelTrackUserLoggedIn('Email');
        }

        const redirectTo = getLocalItem('redirectTo');

        removeIPQSCookies();
        // hide loading
        dispatch(setAuthLoading());
        // clear errors
        dispatch(clearErrors());

        // clear common loading
        dispatch(killLoading());

        if (!isEmpty(redirectTo)) {
          removeLocalItem('redirectTo');
          navigate(redirectTo, {
            replace: true,
          });
        } else if (location !== undefined && location.search !== undefined) {
          // Todo: ask what this is for
          // const searchQuery = browserHistory.location.lastLocation.search;
          // browserHistory.push(`${browserHistory.location.lastLocation.pathname}${searchQuery}`);

          const searchQuery = location.search;
          navigate(`/dashboard${searchQuery}`, {
            replace: true,
          });
        } else {
          navigate('/dashboard', {
            replace: true,
          });
        }
      });
    });
  };
};

export const newAuthPhoneLogin = (
  phone_number: string,
  otp: string,
  navigate: NavigateFunction,
  location: Location
): any => {
  return (dispatch: any) => {
    dispatch(setAuthLoading());
    return sdk.requestOtpAuthVerify({ phone_number, otp }).then(response => {
      // save token
      dispatch(saveToken(response.data));

      // retrieve logged in profile.
      dispatch(refreshProfile()).then(result => {
        if (result?.hash_id) {
          mixpanelTrackSmsCodeVerified({
            Result: true,
            Context: 'Login',
          });
          mixpanelTrackUserLoggedIn('Phone Number');
        }

        const redirectTo = getLocalItem('redirectTo');

        removeIPQSCookies();
        // hide loading
        dispatch(setAuthLoading());
        // clear errors
        dispatch(clearErrors());

        // clear common loading
        dispatch(killLoading());

        if (!isEmpty(redirectTo)) {
          removeLocalItem('redirectTo');
          navigate(redirectTo, {
            replace: true,
          });
        } else if (location !== undefined && location.search !== undefined) {
          const searchQuery = location.search;
          navigate(`/dashboard${searchQuery}`, {
            replace: true,
          });
        } else {
          navigate('/dashboard', {
            replace: true,
          });
        }
      });
    });
  };
};

export const verifyOtp = (phone_number: string, otp: string, isSignUp = false, navigate: NavigateFunction): any => {
  return (dispatch: any) => {
    return sdk.requestOtpVerify({ phone_number, otp }).then(response => {
      // trying to sign up with existing phone number
      if (response.data?.token && isSignUp) {
        // save token
        dispatch(saveToken(response.data));

        removeLocalItem('join_predata');

        // retrieve logged in profile.
        dispatch(refreshProfile()).then(result => {
          if (result?.hash_id) {
            mixpanelTrackSmsCodeVerified({
              Result: true,
              Context: 'Login',
            });
            mixpanelTrackUserLoggedIn('Phone Number');
          }

          navigate('/dashboard');
        });

        return response.data;
      }
    });
  };
};

const loginError = error => {
  return dispatch => {
    // new password not accepted
    dispatch(setLoading());
    try {
      const { data } = error.response;
      dispatch(storeErrors(data));
    } catch (e) {
      window.location.href = '/login';
    }
  };
};

export const setNewPassword = (formData: any, navigate: NavigateFunction): any => {
  return (dispatch: any) => {
    dispatch(setLoading());
    return sdk
      .setNewPassword(formData)
      .then(response => {
        // save token
        dispatch(saveToken(response.data));
        // retrieve logged in profile.
        dispatch(refreshProfile()).then(() => {
          // hide loading
          dispatch(setLoading());
          // clear errors
          dispatch(clearErrors());
          // redirect user
          navigate('/dashboard');
          toaster.success('Password reset');
        });
      })
      .catch(error => {
        loginError(error);
      });
  };
};

export const setActivationPassword = (formData: any, navigate: NavigateFunction): any => {
  return (dispatch: any) => {
    dispatch(setLoading());
    dispatch(setProcessing());
    return sdk
      .setActivationPassword(formData)
      .then(response => {
        // save token
        dispatch(saveToken(response.data));
        // retrieve logged in profile.
        dispatch(refreshProfile()).then(() => {
          // hide loading
          dispatch(setLoading());
          // clear errors
          dispatch(clearErrors());
          dispatch(setProcessing());
          // redirect user
          navigate('/account-getting-started');
          toaster.success('Password set successfully');
        });
      })
      .catch(error => {
        const errors = error.response.data;
        dispatch(setProcessing());
        dispatch(
          storeErrors({
            password: errors.password,
            token: errors.token,
          })
        );
      });
  };
};

export const validateActivationToken = (token: any, navigate: NavigateFunction): any => {
  return dispatch => {
    dispatch(setResetTokenValidity(true));
    return sdk
      .validateActivationPasswordToken(token)
      .then(response => {
        // valid token
        if (response.status === 200) {
          if (response.data.temp_password) {
            dispatch(saveToken(response.data));
            dispatch(refreshProfile());
            return response.data;
          }
          navigate(`/account-activation?token=${response.data.token}`);
        }
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
        // invalid token
        dispatch(setResetTokenValidity(false));
      });
  };
};

export const validateResetPasswordToken = (token: any): any => {
  return (dispatch: any) => {
    dispatch(setResetTokenValidity(true));
    return sdk
      .validateResetPasswordToken(token)
      .then(() => {
        // valid token
        dispatch(setResetTokenValidity(true));
      })
      .catch(() => {
        // invalid token
        dispatch(setResetTokenValidity(false));
      });
  };
};

export const deactivateAccount = (formData: any): any => {
  return (dispatch, getState) => {
    return sdk
      .deactivateAccount(getState().auth.jwToken, formData)
      .then(() => {
        // account deactivated, auth killed by server.
        // log user out
        localStorage.removeItem('came_from_delete_page');
        dispatch(clearErrors());
        window.location.href = '/login?logged_out=true&deactivated=1';
      })
      .catch(error => {
        // account could not deactivate.
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const resendEmail = (): any => {
  return (dispatch, getState) => {
    return sdk
      .resendEmail(getState().auth.jwToken)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };
};

const parseSocialRequest = (socialSiteResponse, site, action) => {
  const formData = {
    meta: null,
    site,
    token: null,
    email: null,
    action: undefined,
  };

  if (action === 'disconnect') {
    return formData;
  }

  if (site === MasterConfig.GOOGLE_AUTH) {
    formData.meta = {
      authResponse: socialSiteResponse.getAuthResponse(),
      basicProfile: socialSiteResponse.getBasicProfile(),
    };
    formData.token = socialSiteResponse.getAuthResponse().id_token;
    formData.email = socialSiteResponse.getBasicProfile().getEmail();
  }

  return formData;
};

export const attemptSociaLogin = (formData: any, site: any, action = null, navigate: NavigateFunction): any => {
  const payload = parseSocialRequest(formData, site, action);

  return dispatch => {
    dispatch(setAuthLoading());
    return sdk
      .attemptSocialLogin(payload)
      .then(response => {
        // save token
        dispatch(saveToken(response.data));
        // retrieve logged in profile.
        dispatch(refreshProfile()).then(() => {
          // hide loading
          dispatch(setAuthLoading());
          // clear errors
          dispatch(clearErrors());
          // redirect user
          navigate('/dashboard');
        });
      })
      .catch(() => {
        throw new Error('Error attempting social login');
      });
  };
};

export const attachSocialLogin = (socialSiteResponse: any, site: any, action: any): any => {
  const payload = parseSocialRequest(socialSiteResponse, site, action);

  payload.action = action;

  return (dispatch, getState) => {
    return sdk
      .attachSocialLogin(getState().auth.jwToken, payload)
      .then(response => {
        let currentSocial = response.data;

        if (action === 'disconnect') {
          currentSocial = remove(currentSocial, {
            social_site_name: site,
          });
        }

        dispatch({
          type: 'UPDATE_USER_SOCIAL',
          payload: { ...currentSocial },
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors && response.data.errors[0]) {
          dispatch(displayFailMessage({ info: response.data.errors[0].title }));
        }
      });
  };
};

export const validateSocialCredentials = (formData: any, site: any, action: any): any => {
  const payload = parseSocialRequest(formData, site, action);

  return (dispatch, getState) => {
    return sdk.validateSocialCredentials(getState().auth.jwToken, payload);
  };
};

export const saveVirgil = (toSave = false): any => {
  return dispatch => {
    if (toSave) {
      dispatch({
        type: 'SET_VIRGIL',
      });
    } else {
      dispatch({
        type: 'UNSET_VIRGIL',
      });
    }
  };
};

// WYP-6446 refactor just let me in together with disabled password
export const checkEmailTokenValidity = (token: any): any => {
  return (dispatch: any) => {
    return sdk.getSignedLinkToken(token).then(response => {
      dispatch(removeBlockFlags());
      return response;
    });
  };
};

export const destroyOtherDeviceSession = (): any => {
  return dispatch => {
    return sdk.destroyOtherDeviceSession().then(({ data }) => {
      if (data && data.token) {
        dispatch(saveToken(data));

        toaster.success('You have successfully logged out from other devices!');
      }
    });
  };
};

export const validateMagicSignup = (queryString: any): any => {
  return dispatch => {
    return sdk
      .validateAutoSignup(queryString)
      .then(response => {
        dispatch(saveToken(response.data));
        dispatch(refreshProfile()).then(() => {
          setTimeout(() => {
            browserHistory.push('/profile/edit?userFirstTimeLogin=1');
            toaster.success('You have successfully created a profile!');
            dispatch(killLoading());
            dispatch(clearErrors());
          }, 800);
        });
      })
      .catch(error => {
        dispatch(killLoading());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const generateVerificationUrl = (): any => {
  return dispatch => {
    return sdk
      .getVerificationStatus()
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        dispatch(killLoading());
        dispatch(storeErrors(error.response.data));
      });
  };
};

const setCartAbandonAction = (eventType: any, extInfo: any = {}): any => {
  return {
    type: 'CART_ABANDON',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: eventType === 'DEACTIVATE' ? 'deactivate' : 'gdpr-delete',
        extraInfo: extInfo,
        component: null,
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const sendDeactivationAction = (extInfo: any): any => {
  vysionService(setCartAbandonAction('DEACTIVATE', extInfo));
};

const sendGDPRDeleteAction = (extInfo: any): any => {
  vysionService(setCartAbandonAction('GDPR_DELETE', extInfo));
};

export const endVerificationSession = (formData: any): any => {
  return dispatch => {
    return sdk
      .setVerificationStatus(formData)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        dispatch(killLoading());
        dispatch(storeErrors(error.response.data));
      });
  };
};

export const fetchCartAbandonmentQuestions = (title = 'deactivate_account'): any => {
  return dispatch => {
    return sdk
      .fetchSurveyQuestions(title)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        dispatch(storeErrors(error.response.data));
      });
  };
};

export { sendDeactivationAction, sendGDPRDeleteAction };
