import styled from '@emotion/styled';
import { textColor, dimensions } from '../../style';

const sizes = {
  SM: 18,
  MD: 22,
  LG: 28,
  XL: 32,
};

const Headline = styled('h1')(
  {
    margin: 0,
    fontWeight: 'normal',
    ...textColor.BRAND,
  },
  ({ size = 'MD' }) => ({ fontSize: sizes[size] }),
  ({ spaceAfter }) => ({ marginBottom: spaceAfter ? 16 : 0 }),
  ({ spacedMobile }) => {
    if (spacedMobile === true) {
      return {
        [dimensions.SCREEN_MAX_XS]: {
          padding: '0 22px',
        },
      };
    }
  }
);

export default Headline;
