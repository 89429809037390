import React from 'react';

const styles = {
  cursor: 'pointer',
  color: '#2B8FD7',
  fontSize: 12,
  textDecoration: 'underline',
};

interface IJoinButtonActionProps {
  buttonType?: string;
  onClick?: (event: React.MouseEvent) => void;
  label: string;
  customStyle?: React.CSSProperties;
}

const JoinSkipButton: React.FC<IJoinButtonActionProps> = (props: IJoinButtonActionProps) => {
  const { customStyle, onClick, label } = props;
  return (
    <div>
      <a onClick={onClick} style={{ ...styles, ...customStyle }} data-test-id="join-action-skip">
        {label}
      </a>
    </div>
  );
};

export default JoinSkipButton;
