import React, { ReactElement } from 'react';
import StatusCaution from './StatusCaution';
import StatusError from './StatusError';
import StatusSuccess from './StatusSuccess';

const getStatusIcon = status => {
  switch (status) {
    case 'caution':
      return <StatusCaution />;
    case 'error':
      return <StatusError />;
    case 'success':
      return <StatusSuccess />;
    default:
      return null;
  }
};

const StatusIcon = (props: any): ReactElement => {
  const { status } = props;
  return <React.Fragment>{getStatusIcon(status)}</React.Fragment>;
};

export default StatusIcon;
