import axios, { AxiosPromise } from 'axios';
// import MasterConfig, { isProdEnv } from '../config/Master';
import MasterConfig from '../config/Master';
import { getGa } from '../common';
import { getUniqueDevice } from '../utils/ipQualityScore';

const api = MasterConfig.api;

const createUserAccountV2 = (formData: any): AxiosPromise => {
  return axios.post(`${api}/account/create-v2`, formData);
};

const createUserAccount = (formData: any): AxiosPromise => {
  return axios.post(`${api}/account`, formData);
};

const getNewAuthToken = (tokenPath: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/auth/${tokenPath}`,
    data: {},
  });
};

const killAuthToken = (): AxiosPromise => {
  return axios.post(`${api}/auth/logout`, null);
};

const requestPassReminder = (email: string): AxiosPromise => {
  return axios({
    method: 'post',
    url: `${api}/forgot/password`,
    data: {
      email: email,
      gaClientId: getGa(),
    },
  });
};

const getFirstAuthToken = (formData: any): AxiosPromise => {
  const deviceIdentifier = getUniqueDevice();

  if (deviceIdentifier.request_id) {
    formData.ipqs_device_id = deviceIdentifier.device_id;
    formData.ipqs_request_id = deviceIdentifier.request_id;
  }

  // include js fingerprint.
  return axios({
    method: 'post',
    url: `${api}/auth/login`,
    data: {
      ...formData,
      fingerprint: window.fingerprint,
      gaClientId: getGa(),
    },
  });
};

const setNewPassword = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/reset/password`,
    data: {
      ...formData,
      gaClientId: getGa(),
    },
  });
};

const setActivationPassword = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account/migration/complete`,
    data: {
      ...formData,
    },
  });
};

const validateActivationPasswordToken = (token: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account/migration/validate-token`,
    data: { token },
    // data: isProdEnv
    //   ? { token }
    //   : {
    //       token,
    //       retry: true,
    //     },
  });
};

const validateResetPasswordToken = (token: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/reset/password/validate-token`,
    data: { token },
  });
};

const deactivateAccount = (token: any, formData: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/account/deactivate`,
    data: formData,
  });
};

const resendEmail = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/account/resend_activation`,
    data: {},
  });
};

const attemptSocialLogin = (formData: any): AxiosPromise => {
  // include js fingerprint.
  return axios({
    method: 'post',
    url: `${api}/auth/social/validate-login`,
    data: {
      ...formData,
    },
  });
};

const attachSocialLogin = (token: any, formData: any): AxiosPromise => {
  if (formData.action === 'disconnect') {
    return axios({
      method: 'DELETE',
      url: `${api}/auth/social/attach-social`,
      data: formData,
    });
  }
  return axios({
    method: 'POST',
    url: `${api}/auth/social/attach-social`,
    data: formData,
  });
};

const validateSocialCredentials = (token: any, formData: any): AxiosPromise => {
  // include js fingerprint.
  return axios({
    method: 'post',
    url: `${api}/auth/social/validate-credentials`,
    data: formData,
  });
};

const getSignedLinkToken = (token: any): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/auth/validate-email-token?email_token=${token}`,
    data: {},
  });
};

const destroyOtherDeviceSession = (): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/settings/revoke-other-sessions`,
  });
};

const validateAutoSignup = (queryString: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account/migrate`,
    data: queryString,
  });
};

const getVerificationStatus = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/id-verification`,
    data: {},
  });
};

const setVerificationStatus = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/id-verification`,
    data: formData,
  });
};

const getRealGiftsCookie = (hashId: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${api}/realgifts/cookies/${hashId}`,
  });
};

const requestOtpAuth = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/otp/auth`,
    data: formData,
  });
};
const requestOtpAuthVerify = (formData: any): AxiosPromise => {
  const deviceIdentifier = getUniqueDevice();

  if (deviceIdentifier.request_id) {
    formData.ipqs_device_id = deviceIdentifier.device_id;
    formData.ipqs_request_id = deviceIdentifier.request_id;
  }

  return axios({
    method: 'POST',
    url: `${api}/otp/auth/verify`,
    data: formData,
  });
};

const requestOtp = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/otp`,
    data: formData,
  });
};

const requestOtpVerify = (formData: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/otp/verify`,
    data: formData,
  });
};

export {
  createUserAccountV2,
  createUserAccount,
  getNewAuthToken,
  killAuthToken,
  requestPassReminder,
  getFirstAuthToken,
  setNewPassword,
  setActivationPassword,
  validateActivationPasswordToken,
  validateResetPasswordToken,
  deactivateAccount,
  resendEmail,
  attemptSocialLogin,
  attachSocialLogin,
  validateSocialCredentials,
  getSignedLinkToken,
  destroyOtherDeviceSession,
  validateAutoSignup,
  getVerificationStatus,
  setVerificationStatus,
  getRealGiftsCookie,
  requestOtp,
  requestOtpVerify,
  requestOtpAuth,
  requestOtpAuthVerify,
};
