import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import TextInput from '../../atoms/TextInput';
import Button from '../../atoms/buttons/Button';
import { textColor } from '../../style';
import { renderTaxLabel } from '../../utils/helpers';
import LockIcon from '@material-ui/icons/Lock';
import AfterPayment from './AfterPayment';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import { useStateCallback } from '../../utils/customHooks';
import { MasterConfig } from '../../config/Master';
import { TOSPath } from '../../utils/helpers';
import '../../style/customCC.css';
import { CountrySelect } from '../../atoms/Select';
import { billingSofortLastName, billingSofortFirstName, billingSofortCountry } from '../../actions/billingTrackAction';

const FormSection = styled('div')({
  marginBottom: 10,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderTop: 0,
  padding: '10px 10px',
  display: 'flex',
});

const TermsCond = styled('span')({
  color: textColor.DARK,
  fontSize: 14,
  fontWeight: 400,
});

const getStatus = status => {
  return status && status.status ? status.status : 'default';
};

const getStatusMessage = status => {
  return status && status.message ? status.message : null;
};

const verifyStatus = (status, props) => {
  return status && getStatus(status[props]);
};

const verifyStatusMessage = (status, props) => {
  return status && getStatusMessage(status[props]);
};

interface SofortFormProps {
  billingCountry: any;
  billingFirstName: any;
  billingLastName: any;
  billingChannel: any;

  totalAmount: any;
  currencyCharCode: any;
  selectedPackage: any;
  descriptors: any;
  newCard: any;
  userCountry: any;
  handleUpdateMonthYrInput: any;
  discountAmount: any;
  handleCountryChange: any;
  handleSubmit: any;
  handleTextFieldChange: any;
  recurring?: boolean;
}

const SofortForm = ({
  billingFirstName,
  billingLastName,
  billingCountry,
  totalAmount,
  currencyCharCode,
  selectedPackage,
  descriptors,
  userCountry,
  handleSubmit,
  discountAmount,
  handleTextFieldChange,
  handleCountryChange,
  recurring = false,
}: SofortFormProps): ReactElement => {
  const [disableBtn, setDisableBtn] = useStateCallback(true);

  const inputOnChange = e => {
    checkRequired();
    handleTextFieldChange(e.target.id, e.target.value);
  };

  const checkRequired = () => {
    const reqValues = billingFirstName && billingLastName;
    setDisableBtn(!reqValues);
  };

  const onHandleSubmit = e => {
    e.preventDefault();
    checkRequired();
    handleSubmit();
  };

  const onCountryChange = e => {
    checkRequired();
    handleCountryChange(e.target.value);
  };

  return (
    <form onSubmit={onHandleSubmit} style={{ position: 'relative' }}>
      <FormSection>
        <TextInput
          id="billing_fname"
          type="text"
          inputLabel="First Name"
          value={billingFirstName}
          customStyle={{ marginRight: 4, width: 100, flex: 'auto' }}
          customInputStyle={{ border: 'none', width: 100 }}
          onChange={inputOnChange}
          status={verifyStatus(status, 'use2pay_billing_fname')}
          statusMessage={verifyStatusMessage(status, 'use2pay_billing_fname')}
          noBorder
          onClick={() => billingSofortFirstName()}
          isRequired
        />
        <TextInput
          id="billing_lname"
          type="text"
          inputLabel="Last Name"
          value={billingLastName}
          customStyle={{ marginRight: 4, width: 100, flex: 'auto' }}
          customInputStyle={{ border: 'none', width: 100 }}
          onChange={inputOnChange}
          status={verifyStatus(status, 'use2pay_billing_lname')}
          statusMessage={verifyStatusMessage(status, 'use2pay_billing_lname')}
          noBorder
          onClick={() => billingSofortLastName()}
          isRequired
        />
        <CountrySelect
          countryDropdownType="short"
          id="billing_country"
          inputLabel="Country"
          value={billingCountry}
          style={{ marginRight: 4, border: 0, boxShadow: 'none', width: 100, flex: 'auto' }}
          onChange={onCountryChange}
          status={verifyStatus(status, 'use2pay_billing_country')}
          statusMessage={verifyStatusMessage(status, 'use2pay_billing_country')}
          isBilling
          onClick={() => billingSofortCountry()}
          isRequired
        />
      </FormSection>
      {recurring && (
        <p
          style={{
            fontSize: 14,
            padding: '0px 20px 0 10px',
            textAlign: 'center',
            marginTop: '-30px',
          }}
        >
          <strong>
            This payment option is for one-time payments only. If you would like to enroll in Auto Credit Top-Up please
            select a different payment method.
          </strong>
        </p>
      )}
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Button
          disabled={disableBtn}
          data-test-id="billing-purchase-button"
          customStyle={{ margin: '0 auto', backgroundColor: 'rgb(19, 131, 210)', padding: '0 40px' }}
          buttonType="primary"
        >
          <LockIcon style={{ fontSize: 16, paddingRight: 3 }} />
          {` Pay ${String.fromCharCode(currencyCharCode)}`}
          {selectedPackage ? renderTaxLabel(selectedPackage, discountAmount) : totalAmount}
        </Button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px', borderBottom: '1px  solid #e3e3e3', paddingBottom: 20 }}>
        <TermsCond>
          By submitting payment you agree to the purchase{' '}
          <a
            href={`${MasterConfig.FULL_URL}/${TOSPath(userCountry)}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            <b>terms & conditions</b>
          </a>
        </TermsCond>
        .
      </div>
      <BillingPaymentProcessor userCountry={userCountry} />
      <AfterPayment descriptors={descriptors} />
    </form>
  );
};

export default SofortForm;
