import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions } from '../../style';
import { profilePhoto } from '../../actions/profileTrackAction';
import { HeartNotificationIcon, OfferNew } from '../icons/favoriteIcons';
import { mixpanelTrackLightBoxOpened } from '../../utils/mixpanel/lightboxOpened';

const PhotoContainer = styled('div')({
  width: '100%',
  paddingTop: '133%',
  borderRadius: 6,
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  [dimensions.SCREEN_MAX_XS]: {
    maxWidth: '100%',
    minHeight: 'inherit',
    paddingTop: '133%', // 3:4 aspect ratio,
    borderRadius: 0,
  },
});

const GradientBottom = styled('div')({
  [dimensions.SCREEN_MAX_XS]: {
    background:
      'rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0,0.2) 5%, rgba(255, 255, 255, 0) 15%,rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0,0.7) 100%) repeat scroll 0% 0%',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

const Photo = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  ({ backgroundImage }) =>
    backgroundImage && {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
);

const ProfileCardBanner = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '6px 0',
    width: '100%',

    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    color: '#fff',
    svg: {
      width: 14,
      height: 14,
      marginTop: 1,
      marginRight: 4,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 12,
      svg: {
        height: 12,
      },
    },
  },
  ({ customBackground }) => ({
    background: `${customBackground ? customBackground : 'rgba(188,32,40,0.9)'}`,
  })
);

interface ProfilePhotoProps {
  imgUrl: string;
  onClick: any;
  offer: any;
  myProfile: any;
  isOtherProfile: boolean;
}

const ProfilePhoto = ({ imgUrl, onClick, offer, myProfile, isOtherProfile }: ProfilePhotoProps): ReactElement => {
  const renderProfileCardBanner = () => {
    if (offer && offer.status) {
      if (offer.status === 'accepted') {
        return (
          <ProfileCardBanner data-test-id="profile-banner-offer-accepted" customBackground="rgba(19, 131, 210, 0.9)">
            <HeartNotificationIcon /> Offer Accepted!
          </ProfileCardBanner>
        );
      }

      if (offer.status === 'pending') {
        if (offer.to_account_hash_id === myProfile?.hash_id) {
          return (
            <ProfileCardBanner
              data-test-id={offer.suggest ? 'profile-banner-suggest-request' : 'profile-banner-offer-request'}
              customBackground="rgba(191, 146, 0, 0.9)"
            >
              {offer.suggest ? 'Asked you to Offer!' : 'Sent You an Offer!'}
            </ProfileCardBanner>
          );
        }

        return (
          <ProfileCardBanner data-test-id="profile-banner-pending-offer" customBackground="rgba(191, 146, 0, 0.9)">
            <OfferNew fill="rgba(255,255,255,1)" /> Pending Offer
          </ProfileCardBanner>
        );
      }
    }

    return '';
  };

  return (
    <PhotoContainer
      onClick={e => {
        profilePhoto(e);
        e.preventDefault();
        onClick(0, e);

        if (myProfile?.hash_id) {
          if (isOtherProfile) {
            mixpanelTrackLightBoxOpened('Main Photo');
          }
        }
      }}
    >
      {renderProfileCardBanner()}
      <Photo data-test-id="profile-photo-container" backgroundImage={imgUrl} />
      <GradientBottom />
    </PhotoContainer>
  );
};

export default ProfilePhoto;
