import React, { ReactElement } from 'react';
import { useApp } from '../../../contexts/AppContext';
import StepOneIcon from '../../../atoms/icons/StepOneIcon';
import StepTwoIcon from '../../../atoms/icons/StepTwoIcon';
import { BodyWrapper, StepLabel, LinkStyle2 } from '../styledElements';
import { DashlineSeparator } from '../../join/styledElements';
import JoinActionButton from '../../join/JoinActionButton';
import JoinSkipButton from '../../join/JoinSkipButton';

interface IEmailProfileIncompleteStepProps {
  setSkip: () => any;
  handleChangeEmail: () => any;
  handleMixpanel: () => any;
  children: React.ReactNode;
}

const EmailProfileIncompleteStep = ({
  setSkip,
  handleChangeEmail,
  handleMixpanel,
  children,
}: IEmailProfileIncompleteStepProps): ReactElement => {
  const { setProfileCompletionStep } = useApp();

  return (
    <BodyWrapper>
      <div style={{ display: 'flex' }}>
        <StepOneIcon />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StepLabel>Verify your email address</StepLabel>
          {children}
          <LinkStyle2 onClick={handleChangeEmail}>Change Email Address</LinkStyle2>
        </div>
      </div>
      <DashlineSeparator />
      <div style={{ display: 'flex' }}>
        <StepTwoIcon />
        <StepLabel>All profile info filled out</StepLabel>
      </div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <JoinActionButton
          buttonType="primary"
          label="Finish My Profile"
          customStyle={{ marginBottom: 10 }}
          onClick={() => {
            setProfileCompletionStep(2);
            handleMixpanel();
          }}
        />
        <JoinSkipButton label="Skip for now" onClick={setSkip} />
      </div>
    </BodyWrapper>
  );
};

export default EmailProfileIncompleteStep;
