import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { backgroundTheme, dimensions, paymentTheme, textColor } from '../../style';
import { addCommas } from '../../utils/helpers';
import AutoTopupToggle from './AutoTopup/AutoTopupToggle';
import PromoCode from './PromoCode/PromoCode';
import { getPackageSubTotal, getPackageVat } from '../../utils/billing';

const OrderSummaryContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: `solid 1px ${paymentTheme.SUMMARY.borderColor}`,
  borderRadius: 6,
});

const OrderSection = styled('div')({
  padding: '10px 0',
  width: '100%',
  color: paymentTheme.SUMMARY.color,
  fontSize: 16,
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 14,
  },
});

const ItemChoice = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 18,
  color: paymentTheme.SUMMARY.color,
  fontWeight: 400,
});

const VatDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 16,
  color: paymentTheme.SUMMARY.color,
  fontWeight: 400,
});

const CreditText = styled('span')({
  color: paymentTheme.SUMMARY.color,
  fontWeight: 900,
});

const SummaryAmountText = styled('div')({
  color: paymentTheme.SUMMARY.color,
  fontWeight: 'normal',
  fontSize: 16,
});

const Total = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 18,
  color: paymentTheme.SUMMARY.color,
  fontWeight: 700,
  backgroundColor: backgroundTheme.paymentHighlight,
  padding: 15,
  borderRadius: '0px 0px 6px 6px',
  [dimensions.SCREEN_MAX_MD]: {
    padding: '15px 12px',
  },
});

const TermsCond = styled('span')({
  color: textColor.DARK.color,
  fontSize: 14,
  fontWeight: 400,
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 13,
  },
});

const OrderSummaryDivider = styled('hr')({
  border: 'none',
  borderTop: `1px dashed ${paymentTheme.DIVIDER.borderColor}`,
  margin: '15px 0',
});

const OrderSummaryItems = styled('div')({
  padding: 15,
  [dimensions.SCREEN_MAX_MD]: {
    padding: '15px 12px',
  },
});

const TermsCondWrap = styled('div')({
  textAlign: 'center',
  padding: 10,
});

interface OrderSummaryProps {
  price: any;
  credits: any;
  currencyCharCode: any;
  currencyCode: any;
  vatDetails: any;
  userCountry: any;
  isIDV: any;
  discountAmount: any;
  hasRecurring: boolean;
  recurring: boolean;
  onAutoTopupChange: any;
  packages: any;
  selectedPackageData: any;
  discountData: any;
  profile: any;
  setDiscount: any;
  setDiscountAmount: any;
  applyPromoCode: any;
  setSelectedPackageData: any;
  taxCountry?: string;
  paymentChannel?: string | null;
}

const OrderSummary = ({
  price,
  credits,
  currencyCharCode,
  currencyCode,
  vatDetails,
  isIDV,
  discountAmount,
  hasRecurring,
  recurring,
  onAutoTopupChange,
  selectedPackageData,
  packages,
  discountData,
  profile,
  setDiscount,
  setDiscountAmount,
  applyPromoCode,
  setSelectedPackageData,
  taxCountry,
  paymentChannel = null,
}: OrderSummaryProps): ReactElement => {
  const discount = discountAmount === undefined ? 0 : discountAmount;
  const grandTotal = getPackageSubTotal(selectedPackageData, discount, vatDetails);

  return isIDV ? (
    <div>
      <OrderSection>Your Order Details</OrderSection>
      <OrderSummaryContent>
        <OrderSummaryItems>
          <ItemChoice>
            <b>Verification Package</b>
            <div>
              {String.fromCharCode(currencyCharCode)}
              {parseFloat(price).toFixed(2)} {currencyCode}
            </div>
          </ItemChoice>
          <OrderSummaryDivider />
          <PromoCode
            discountAmount={discountAmount}
            currencyCharCode={currencyCharCode}
            currencyCode={currencyCode}
            discount={discountData}
            profile={profile}
            setDiscount={setDiscount}
            setDiscountAmount={setDiscountAmount}
            selectedPackage={selectedPackageData}
            applyPromoCode={applyPromoCode}
            paymentChannel={paymentChannel}
            setSelectedPackageData={setSelectedPackageData}
            taxCountry={taxCountry}
          />
          {vatDetails && (
            <div>
              <OrderSummaryDivider />
              <VatDetails>
                <div>Estimated tax ({`${vatDetails.location} ${vatDetails.rate * 100}%`})</div>
                <SummaryAmountText>
                  {String.fromCharCode(currencyCharCode)}
                  {getPackageVat(selectedPackageData, discount, vatDetails).toFixed(2)} {currencyCode}
                </SummaryAmountText>
              </VatDetails>
            </div>
          )}
        </OrderSummaryItems>
        <Total>
          <div>TOTAL:</div>
          <b>
            {String.fromCharCode(currencyCharCode)}
            {grandTotal.toFixed(2)} {currencyCode}
          </b>
        </Total>
      </OrderSummaryContent>
      <TermsCondWrap>
        <TermsCond>Identity verification offered by a third-party service</TermsCond>.
      </TermsCondWrap>
    </div>
  ) : (
    <div>
      <OrderSection>Your Order Details</OrderSection>

      <OrderSummaryContent>
        <OrderSummaryItems>
          <ItemChoice>
            <b>
              <CreditText>{addCommas(credits)}</CreditText> Credit Package
            </b>
            <SummaryAmountText>
              {String.fromCharCode(currencyCharCode)}
              {parseFloat(price).toFixed(2)} {currencyCode}
            </SummaryAmountText>
          </ItemChoice>
          {hasRecurring && (
            <div>
              <OrderSummaryDivider />
              <AutoTopupToggle
                recurring={recurring}
                onAutoTopupChange={onAutoTopupChange}
                packages={packages}
                selectedPackage={selectedPackageData}
              />
            </div>
          )}
          <OrderSummaryDivider />
          <PromoCode
            discountAmount={discountAmount}
            currencyCharCode={currencyCharCode}
            currencyCode={currencyCode}
            discount={discountData}
            profile={profile}
            setDiscount={setDiscount}
            setDiscountAmount={setDiscountAmount}
            selectedPackage={selectedPackageData}
            applyPromoCode={applyPromoCode}
            setSelectedPackageData={setSelectedPackageData}
            taxCountry={taxCountry}
            paymentChannel={paymentChannel}
          />

          {vatDetails && (
            <div>
              <OrderSummaryDivider />
              <VatDetails>
                <div>Estimated tax ({`${vatDetails.location} ${vatDetails.rate * 100}%`})</div>
                <SummaryAmountText>
                  {String.fromCharCode(currencyCharCode)}
                  {getPackageVat(selectedPackageData, discount, vatDetails).toFixed(2)} {currencyCode}
                </SummaryAmountText>
              </VatDetails>
            </div>
          )}
        </OrderSummaryItems>
        <Total>
          <div style={{ fontSize: 16 }}>TOTAL:</div>
          <b>
            {String.fromCharCode(currencyCharCode)}
            {grandTotal.toFixed(2)} {currencyCode}
          </b>
        </Total>
      </OrderSummaryContent>
    </div>
  );
};

OrderSummary.propTypes = {
  price: PropTypes.string,
  credits: PropTypes.number,
  currencyCharCode: PropTypes.string,
  currencyCode: PropTypes.string,
  vatDetails: PropTypes.shape({
    rate: PropTypes.number,
    tax_amount: PropTypes.number,
    gross_amount: PropTypes.number,
  }),
  hasRecurring: PropTypes.bool,
};

export default OrderSummary;
