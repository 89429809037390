import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import RoundDismissIcon from '../../atoms/icons/RoundDismissIcon';
import WYPBannerIcon from './icons/WYPBannerIcon';
import FDBannerIcon from './icons/FDBannerIcon';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import { isDarkMode } from '../../utils/helpers';
import { isFD, SITE_NAME } from '../../config/Master';
import { useApp } from '../../contexts/AppContext';
import { getLocalItem, setLocalItem } from '../../common';
import {
  PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
  trackPWaMixpanelEventInstalled,
} from '../../utils/pwa';
import { format } from 'date-fns';
import store from '../../store';
import PWAInstallationPromptModal from './PWAInstallationPromptModal';

const dropAnimation = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const PWABannerContainer = styled('div')({
  position: 'fixed',
  background: isDarkMode() ? '#020D15' : 'linear-gradient(180deg, #EAF4FB 0%, #FFF 100%)',
  boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.25)',
  borderTop: '4px solid #0D5C93',
  zIndex: 1001,
  width: '100%',
  top: 0,
  animation: `${dropAnimation} 0.2s ease-out`,
});

const PWABannerWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 10,
  paddingBottom: 10,
  justifyContent: 'space-between',
});

const PWABannerLogoTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const BannerTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 140,
  marginLeft: 10,
});

const BannerTitle = styled('p')({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '16px',
  color: isDarkMode() ? '#71B5E4' : '#2B8FD7',
  padding: 0,
  margin: 0,
});

const BannerDescription = styled('p')({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '14px',
  color: isDarkMode() ? 'AFB0B1' : '#595959',
  padding: 0,
  margin: 0,
  marginTop: 5,
});

interface PWAGlobalBannerContentProps {
  onClick?: any;
  isFirsTimeLogin?: boolean;
}

const PWAGlobalBannerContent: React.FC<PWAGlobalBannerContentProps> = ({ onClick, isFirsTimeLogin }) => {
  const { pwaBannerState, setPwaBannerState, setShowIntallationBanner } = useApp();
  const location = useLocation();
  const isPackagePage = location.pathname.split('/')[1] === 'packages';
  const account = store.getState().profile;
  const accountLocale = useMemo(() => account.profile?.data?.created_at?.timezone ?? null, [account]);
  if (getLocalItem('user_first_time_login') === 'true') {
    return <PWAInstallationPromptModal onClick={onClick} locale={accountLocale} />;
  }

  return (
    <PWABannerContainer style={{ zIndex: isPackagePage ? 998 : 1001 }}>
      <PWABannerWrapper>
        <RoundDismissIcon
          onClick={() => {
            setShowIntallationBanner(false);
            setLocalItem(
              PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
              format(new Date(), 'YYYY-MM-DD', { locale: accountLocale })
            );
            setPwaBannerState(prev => {
              return {
                ...prev,
                show: false,
                platform: null,
              };
            });
          }}
        />
        <PWABannerLogoTextWrapper>
          {isFD ? <FDBannerIcon /> : <WYPBannerIcon />}
          <BannerTextContainer>
            <BannerTitle>{SITE_NAME}</BannerTitle>
            <BannerDescription>Install the app on your device for more features</BannerDescription>
          </BannerTextContainer>
        </PWABannerLogoTextWrapper>
        <PrimaryButton
          customStyle={{ width: 74 }}
          data-test-id="pwa-banner-install"
          id="install"
          onClick={() => {
            onClick();
            trackPWaMixpanelEventInstalled(isFirsTimeLogin ? 'Registration' : pwaBannerState.source);
          }}
          label="Install"
          size="x-small"
          color="red"
        />
      </PWABannerWrapper>
    </PWABannerContainer>
  );
};

export default PWAGlobalBannerContent;
