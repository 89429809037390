import {
  STORE_OFFERS,
  CLEAR_OFFERS,
  REQUEST_OFFERS,
  UPDATE_OFFER_LIST,
  TERMINATE_FETCHING,
  OfferActionTypes,
  OfferState,
  RESET_NEW_OFFERS,
} from './types/Offers.d';

const initialState: OfferState = {
  items: [],
  new: [],
  sent: [],
  accepted: [],
  rejected: [],
  highlights: [],
  isFetching: false,
  offerPreFetchExpiration: 0,
};

/* stores logged in profile */
const offers = (state = initialState, action: OfferActionTypes): any => {
  switch (action.type) {
    case STORE_OFFERS: {
      const newList = action.firstCall ? action.payload : state[action.section].concat(action.payload);
      let newOfferPreFetchExpiration = state.offerPreFetchExpiration;

      if (action.section === 'new') {
        newOfferPreFetchExpiration =
          action.firstCall || action.isRealtimeEvent ? new Date().getTime() + 3600000 : state.offerPreFetchExpiration;
      }

      return {
        ...state,
        [action.section]: newList,
        isFetching: false,
        offerPreFetchExpiration: newOfferPreFetchExpiration,
      };
    }
    case CLEAR_OFFERS:
      return {
        ...state,
        items: [],
      };
    case REQUEST_OFFERS:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_OFFER_LIST:
      return {
        ...state,
        [action.payload.offerType]: action.payload.offerList,
      };
    case TERMINATE_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_NEW_OFFERS:
      return {
        ...state,
        new: [],
      };
    default:
      return state;
  }
};

export default offers;
