import React, { useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import ConfirmationModal from '../../../../../modules/modals/ConfirmationModal';
import styled from '@emotion/styled';

const commonStyles = {
  fontSize: 26,
  color: '#0D5C93',
};

const SpanText = styled('span')({
  fontSize: 16,
  paddingTop: 3,
});

const LogoutAllDeviceDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const PopupForSecurity = (props: any): any => {
  /* Need to create a component that will overwrite the entire section 
       of menu option so that we can trigger the popup when user select
       to logout all devices */
  const [modalOpen, toggleModalOpen] = useState(false);
  const { destroyOtherDeviceSession, handleSidebarToggle, menuOpen } = props;

  const proceed = () => {
    destroyOtherDeviceSession();
  };

  return (
    <div style={{ width: '100%' }}>
      <LogoutAllDeviceDiv onClick={() => toggleModalOpen(true)}>
        <SpanText data-test-id="logout-all-devices">Logout All Devices</SpanText>
        <SvgIcon component={ExitToAppOutlined} style={commonStyles} />
      </LogoutAllDeviceDiv>
      <ConfirmationModal
        isOpen={modalOpen}
        description="Do you really want to log out all of your other sessions?"
        cancelText="Cancel"
        confirmText="Proceed"
        contentLabel="Device logout modal"
        onClose={() => toggleModalOpen(false)}
        onConfirm={() => {
          setTimeout(() => {
            handleSidebarToggle(!menuOpen, true);
          }, 1000);
          proceed();
        }}
      />
    </div>
  );
};

export default PopupForSecurity;
