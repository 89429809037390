import React, { FC } from 'react';
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import PWAGlobalBannerContent from './PWAGlobalBannerContent';

const PWAndroidInstallerPrompt: FC = () => {
  return <PWAInstallerPrompt render={({ onClick }) => <PWAGlobalBannerContent onClick={onClick} />} />;
};

const MemoizedPWAndroidInstallerPrompt = React.memo(PWAndroidInstallerPrompt);

export default MemoizedPWAndroidInstallerPrompt;
