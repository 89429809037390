import React, { ReactElement } from 'react';
interface RedLineProps {
  customStyle: any;
}

const RedLine = ({ customStyle = {} }: RedLineProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="4" viewBox="0 0 57 4" fill="none" style={customStyle}>
      <path d="M2 2L55 2" stroke="#BC2028" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};

export default RedLine;
