type UploadSnackBarStatus = 'failed' | 'success' | 'uploading' | 'retry' | '';

export type UploadSnackbarType = 'photo' | 'video';

export interface IUploadSnackbarItem {
  id: string;
  status: UploadSnackBarStatus;
  file: unknown;
  type?: UploadSnackbarType;
  retry: number;
}

export interface IUploadSnackbar {
  show: boolean;
  uploadType: UploadSnackbarType;
  items: IUploadSnackbarItem[];
}

export type IStatusUpdateSnackbarPayload = Omit<IUploadSnackbarItem, 'file' | 'type' | 'retry'>;

export const SHOW_UPLOAD_SNACKBAR = 'SHOW_UPLOAD_SNACKBAR';
export const HIDE_UPLOAD_SNACKBAR = 'HIDE_UPLOAD_SNACKBAR';
export const UPDATE_UPLOAD_SNACKBAR_STATUS = 'UPDATE_UPLOAD_SNACKBAR_STATUS';
export const INCREMENT_UPLOAD_SNACKBAR_RETRY = 'INCREMENT_UPLOAD_SNACKBAR_RETRY';
export const UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE = 'UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE';

export interface IShowUploadSnackbar {
  type: typeof SHOW_UPLOAD_SNACKBAR;
  payload: IUploadSnackbarItem;
}

export interface IHideUploadSnackbar {
  type: typeof HIDE_UPLOAD_SNACKBAR;
}

export interface IUpdateUploadSnackbarStatus {
  type: typeof UPDATE_UPLOAD_SNACKBAR_STATUS;
  payload: IStatusUpdateSnackbarPayload;
}

export interface IUpdateUploadSnackbarUploadType {
  type: typeof UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE;
  payload: UploadSnackbarType;
}

export interface IIncrementSnackbarItemRetry {
  type: typeof INCREMENT_UPLOAD_SNACKBAR_RETRY;
  payload: {
    id: string;
  };
}

export type UploadSnackbarActionTypes =
  | IShowUploadSnackbar
  | IHideUploadSnackbar
  | IUpdateUploadSnackbarStatus
  | IUpdateUploadSnackbarUploadType
  | IIncrementSnackbarItemRetry;
