import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setSocialVysion = (param: any): void => {
  switch (param.event) {
    case 'FACEBOOK':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-facebook');
      break;
    case 'TWITTER':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-twitter');
      break;
    case 'INSTAGRAM':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-instagram');
      break;
    case 'TERMS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-terms');
      break;
    case 'TERMS-EU':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-terms-eu');
      break;
    case 'PRIVACY':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-privacy');
      break;
    case 'FAQ':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-faq');
      break;
    case 'BLOG':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-blog');
      break;
    case 'TIPS':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-tips');
      break;
    case 'COMMUNITY GUIDELINES':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'footer-communityGuidelines');
      break;
    case 'GOOGLE SIGNUP':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'join-signUpWithGoogle');
      break;
    case 'GOOGLE LOGIN':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'login-loginWithGoogle');
      break;
    default:
      return;
  }
};

export { setSocialVysion };
