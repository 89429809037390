import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import SupportTicketForm from '../modules/SupportTicketForm';
import Headline from '../components/common/Headline';
import { backgroundTheme, borders, dimensions } from '../style';
import Container from '../components/blocks/Container';
import styled from '@emotion/styled';
import Header from '../components/blocks/Header';

interface SupportTicketFSModalProps {
  defaultSubject: any;
  errorCode: any;
  success: any;
  id: any;
  submitSupportTicket: any;
}

const CustomContainer = styled(Container)({
  position: 'relative',
  marginTop: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: backgroundTheme.personalInfo,
  borderRadius: 8,
  border: borders.DARKER,
  [dimensions.SCREEN_MAX_MD]: {
    margin: '0 auto',
    width: '95%',
    minHeight: '80vh',
    alignItems: 'self-start',
  },
});

const CustomHeader = styled(Header)({});

const Content = styled.div({
  minWidth: 460,
  padding: '20px 0',
  [dimensions.SCREEN_MAX_MD]: {
    minWidth: '85vw',
  },
});

const SupportTicketFSModal = ({
  defaultSubject,
  errorCode,
  success,
  id,
  submitSupportTicket,
}: SupportTicketFSModalProps): ReactElement => {
  const [subject, setSubject] = useState(defaultSubject || 'billing');
  const [message, setMessage] = useState('');

  const handleChangeSubject = e => {
    setSubject(e.target.value);
  };

  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitSupportTicket({
      id,
      subject,
      message,
    });
  };

  return (
    <CustomContainer fullWidth>
      <Content>
        <CustomHeader bottomRuled>
          <Headline data-test-id="support-ticket-headline">Create Ticket</Headline>
        </CustomHeader>
        <SupportTicketForm
          defaultSubject={defaultSubject}
          subject={subject}
          message={message}
          onChangeSubject={handleChangeSubject}
          onChangeMessage={handleChangeMessage}
          onSubmit={handleSubmit}
          errorCode={errorCode}
          success={success}
        />
      </Content>
    </CustomContainer>
  );
};

SupportTicketFSModal.propTypes = {
  /** Logged in user's id */
  id: PropTypes.string.isRequired,
  /** Preselected subject; Subject dropdown will not render if this is provided */
  defaultSubject: PropTypes.string,
  /** Function to submit values of Support Ticket Form */
  submitSupportTicket: PropTypes.func.isRequired,
  /** Error code to determine which error message is to be displayed */
  errorCode: PropTypes.number,
  /** Determine if the ticket submission is a success */
  success: PropTypes.bool,
};

export default SupportTicketFSModal;
