export const STORE_CREDITS = 'STORE_CREDITS';
export const CLEAR_CREDITS = 'CLEAR_CREDITS';
export const IS_FETCHING_CREDITS = 'IS_FETCHING_CREDITS';

const CREDITS = {};

export interface Fetching {
  isFetching?: boolean,
}

export interface Credits {
  credits: typeof CREDITS;
}

export interface CreditsState extends Fetching, Credits {}

interface STORE_CREDITS_ACTION {
  type: typeof STORE_CREDITS;
  payload: Credits;
}

interface CLEAR_CREDITS_ACTION {
  type: typeof CLEAR_CREDITS;
  payload: Messages;
}

interface IS_FETCHING_CREDITS_ACTION {
  type: typeof IS_FETCHING_CREDITS;
  payload: CreditsState;
}

export type CreditsActionTypes =
  | STORE_CREDITS_ACTION
  | CLEAR_CREDITS_ACTION
  | IS_FETCHING_CREDITS_ACTION;