// import { useHistory } from 'react-router-dom';
import H from 'history';
import { useNavigate } from 'react-router-dom';

interface IUseNav extends H.History {
  goTo: (path: string, state?: H.state) => void;
  goBack: () => void;
}

const useNav = (): IUseNav => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const goTo = (path: string, state: H.state) => {
    navigate(path, { state });
  };

  return {
    ...history,
    goTo,
    goBack,
  };
};

export default useNav;

export const clearHistoryState = (): void => window.history.replaceState({}, document.title);
