import { mixpanelTrackEventFromBE } from '.';
import { MixpanelDefaultStateProps } from '../../constants/mixpanel';
import { getBrowserDetails } from '../helpers';

export const PROFILE_CLOSED_EVENT_NAME = 'Profile Closed';

export const PROFILE_CLOSED_EVENT_PROPERTIES = {
  'Gallery Photos Seen': 1,
};

export const updateMixpanelProfileClosedPayload = (setMixpanelDataByKeyHandler: any, count: number): void => {
  if (!getBrowserDetails().isMobile) return;

  setMixpanelDataByKeyHandler({
    key: PROFILE_CLOSED_EVENT_NAME,
    payload: {
      ...PROFILE_CLOSED_EVENT_PROPERTIES,
      'Gallery Photos Seen': count,
    },
  });
};

export const mixpanelTrackProfileClosed = async (
  mixpanelData: MixpanelDefaultStateProps,
  resetMixpanelData?: (key: typeof PROFILE_CLOSED_EVENT_NAME) => void
): Promise<void> => {
  if (!getBrowserDetails().isMobile) {
    if (resetMixpanelData) {
      resetMixpanelData(PROFILE_CLOSED_EVENT_NAME);
    }

    return;
  }

  const payload = {
    event_properties: {
      ...PROFILE_CLOSED_EVENT_PROPERTIES,
      ...mixpanelData[PROFILE_CLOSED_EVENT_NAME],
    },
  };

  await mixpanelTrackEventFromBE({
    event_name: PROFILE_CLOSED_EVENT_NAME,
    payload,
  });

  if (resetMixpanelData) {
    resetMixpanelData(PROFILE_CLOSED_EVENT_NAME);
  }
};
