import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Source: string;
  'VWO Campaign Name'?: string;
  'VWO Campaign ID'?: number | string;
  'Promo Code'?: string;
  Discount?: string;
}

export const mixpanelTrackPackagesVisited = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Packages Visited',
    payload: {
      event_properties: eventProperties,
    },
  });
};
