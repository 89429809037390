import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import { css } from 'glamor';
import { getLocalItem, setLocalItem } from '../../common';
import { Link } from 'react-router-dom';

const Alert = {
  padding: '2px 0 1px',
  borderRadius: 0,
  marginBottom: 0,
  minHeight: 0,
  zIndex: 18999,
  width: '100%',
  fontWeight: '600',
};

const AlertSuccess = {
  color: '#fff',
  backgroundColor: '#2d3436',
};

const AlertWarn = {
  color: '#fff',
  backgroundColor: '#FF7C00',
};

const AlertFail = {
  color: '#fff',
  backgroundColor: '#CC0000',
};

interface SiteNotificationProps {
  notification: any;
  acctType: any;
}

const SiteNotification = ({ notification, acctType }: SiteNotificationProps): ReactElement => {
  const [alertStatus, setAlertStatus] = useState(null);
  const [hideBanner, setHideBanner] = useState(true);

  const hideToUser = useCallback(() => {
    if (notification.user_group === 'all') {
      return false;
    }
    if (notification.user_group === acctType.toLowerCase()) {
      return false;
    }
    return true;
  }, [acctType, notification.user_group]);

  const checkHideBanner = useCallback(() => {
    const wypHideBannerId = getLocalItem('wyp_hideBannerId');
    const noNotification = !notification || notification.type === undefined;
    const hideThisId = noNotification ? true : wypHideBannerId === notification.id.toString();
    return hideToUser() || noNotification || hideThisId;
  }, [hideToUser, notification]);

  useEffect(() => {
    if (notification) {
      setHideBanner(checkHideBanner);
      handleStatusChange(notification.type);
    }
  }, [notification, checkHideBanner]);

  const handleStatusChange = status => {
    switch (status) {
      case 'success':
        setAlertStatus(AlertSuccess);
        break;
      case 'warning':
        setAlertStatus(AlertWarn);
        break;
      case 'error':
        setAlertStatus(AlertFail);
        break;
      default:
        setAlertStatus(null);
    }
  };

  const onHandleClose = () => {
    setLocalItem('wyp_hideBannerId', notification.id);
    setHideBanner(true);
  };

  if (hideBanner) {
    return null;
  }

  return (
    <div className="global_site_notification">
      <div className={`${css(Alert)} ${css(alertStatus)}`}>
        <div className="layoutContainer">
          <table style={{ width: '100%', height: '45px' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', paddingLeft: '1rem', verticalAlign: 'middle' }}>
                  <div className="container" style={{ fontSize: 13 }}>
                    {notification.messages.info !== undefined ? <span>{notification.messages.info}</span> : null}
                    {notification.messages.link_text !== undefined && notification.messages.link_url !== undefined ? (
                      <Link
                        to={notification.messages.link_url}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          marginLeft: '5px',
                        }}
                      >
                        {notification.messages.link_text}
                      </Link>
                    ) : null}
                  </div>
                </td>
                <td
                  onClick={onHandleClose}
                  style={{
                    textAlign: 'center',
                    width: '45px',
                    cursor: 'pointer',
                    fontSize: '24px',
                    opacity: notification.type === 'offline' ? 0 : 1,
                    verticalAlign: 'middle',
                    paddingBottom: '5px',
                  }}
                >
                  &times;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SiteNotification;
