import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import HeaderOut from '../../../atoms/HeaderOut';
import { color } from '../../../style';
import styled from '@emotion/styled';

const HeaderContainer = styled('div')({
  display: 'flex',
  padding: '16px 0',
});

const LeftContent = styled('div')({
  alignItems: 'flex-start',
  flex: 1,
});

const RightContent = styled('div')({
  alignItems: 'flex-end',
  textAlign: 'right',
  flex: 10,
});

class OnboardingHeader extends Component {
  render(): any {
    return (
      <HeaderContainer>
        <LeftContent data-test-id="onboarding-logo">
          <HeaderOut width={'auto'} paddingTop={'10'} marginBottom={'0'} onBoarding />
        </LeftContent>
        <RightContent data-test-id="onboarding-skip">
          <Link to={'/privacy-updated'}>
            <span style={{ fontWeight: 'bold', color: color.BRAND_2, textDecoration: 'underline', padding: 5 }}>
              Skip
            </span>
          </Link>
        </RightContent>
      </HeaderContainer>
    );
  }
}

export default OnboardingHeader;
