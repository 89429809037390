import React, { Dispatch, useMemo, useState, useRef, useEffect, useCallback, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { Account, AccountMaker } from '../../../../../models/Account';
import OneTapSwipeOfferActions from './OneTapSwipeOfferActions';
import OneTapSwipeOfferProfileCard from './OneTapSwipeOfferProfileCard';
import TinderCard from '../../../../../packages/react-tinder-card';
import { useOffers } from '../../../../../contexts/OffersContext';
import { ProfileMaker } from '../../../../../models/Profile';
import LoadingBackdrop from '../../../../../atoms/loader/LoadingBackdrop';
import OneTapSwipeOfferFlag from './OneTapSwipeOfferFlag';
import OneTapSwipeOfferVideoPreviewLightBox from './OneTapSwipeOfferVideoPreviewLightBox';
import OneTapSwipeNoMoreOffersScreen from './OneTapSwipeNoMoreOffersScreen';
import OneTapSwipeOfferTutorial from './OneTapSwipeOfferTutorial';
import OneTapSwipeOfferTutorialWithoutSwipe from './OneTapSwipeOfferTutorialWithoutSwipe';
import { updateAccountConfig } from '../../../../../actions/profileActions';
import { connect } from 'react-redux';
import { OFFER_ONE_TAP_MODE_SHOW_TUTORIAL } from '../../../../../models/AccountSettings';
import { isDiscoverModeSwipeEnabled } from '../../../../../utils/swipe';
import OneTapSwipeOfferFlagOverlay from './OneTapSwipeOfferFlagOverlay';
import { doNotShowDeclineOfferConfirmationKey } from '../../../../../hooks/useModal';

interface IProps {
  pageTitle: any;
  isRealTimeEvent: boolean;
  isRealtimeFetching: boolean;
  setIsRealtimeFetching: Dispatch<SetStateAction<boolean>>;
  account: Account;
  updateAccountConfigProp: (payload: { key: string; state: boolean }) => void;
  [key: string]: any;
}

const StyledOfferListContainer = styled('div')({
  position: 'fixed',
  width: 'calc(100% - 40px)',
});

const OneTapSwipeOfferLists: React.FC<IProps> = ({
  pageTitle,
  offers,
  isFetching,
  moreExists,
  loadMore,
  isRealTimeEvent,
  isRealtimeFetching,
  setIsRealtimeFetching,
  updateAccountConfigProp,
  account,
}: IProps) => {
  const isSwipeEnabled = isDiscoverModeSwipeEnabled('offer_discover_swipe_enable');
  const {
    oneTapActiveAccount,
    setOneTapActiveAccount,
    videoPreview,
    setOneTapOfferFlagVariant,
    declineBtnRef,
    offerBtnRef,
  } = useOffers();
  const [currentOffers, setCurrentOffers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(currentOffers.length - 1);
  const currentIndexRef = useRef(currentIndex);
  const [hasAccountsToShow, setHasAccountsToShow] = useState(true);
  const [showSwipeFlag, setShowSwipeFlag] = useState(false);
  const [showSwipeFlagOverlay, setShowSwipeFlagOverlay] = useState(false);

  const doNotShowDeclineOfferConfirmation = localStorage.getItem(doNotShowDeclineOfferConfirmationKey);
  const shouldShowConfirmation = !doNotShowDeclineOfferConfirmation || doNotShowDeclineOfferConfirmation === 'false';

  const canSwipe = currentIndex >= 0;

  const childRefs = useMemo(
    () =>
      Array(currentOffers.length)
        .fill(0)
        .map(() => React.createRef()),
    [currentOffers]
  );

  const updateCurrentIndex = val => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swiped = useCallback(
    (direction: 'left' | 'right' | 'up' | 'down', index: number) => {
      if (showSwipeFlagOverlay) {
        setShowSwipeFlagOverlay(false);

        if (direction === 'left') {
          declineBtnRef?.current.click();

          // handle confirmation decline offer
          if (shouldShowConfirmation) {
            return false;
          }
        }

        if (direction === 'right') {
          offerBtnRef?.current.click();
          return false;
        }
      }

      if (['left', 'right'].includes(direction)) {
        updateCurrentIndex(index - 1);
        setShowSwipeFlag(true);
      }
    },
    [showSwipeFlagOverlay, declineBtnRef, offerBtnRef, shouldShowConfirmation]
  );

  const restoreCurrentIndexCard = () => {
    childRefs[currentIndex].current && childRefs[currentIndex].current.restoreCard();
  };

  const handleClickToSwipe = async dir => {
    if (canSwipe && currentIndex < currentOffers.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const handleOnCardLeftTheScreen = useCallback(
    (dir: string) => {
      if (dir === 'left' && currentIndex === 0 && !isFetching && !moreExists && !shouldShowConfirmation) {
        setHasAccountsToShow(false);
        return;
      }

      if (currentIndex === 0 && !isFetching && !moreExists && !showSwipeFlagOverlay) {
        setHasAccountsToShow(false);
        return;
      }

      if (currentIndex === 0 && moreExists) {
        loadMore();
        return;
      }
    },
    [currentIndex, isFetching, moreExists, oneTapActiveAccount, showSwipeFlagOverlay, shouldShowConfirmation]
  );

  const handleSwipeRequirementFulfilled = useCallback(
    (dir: string) => {
      setShowSwipeFlag(false);

      if (dir === 'left') {
        setOneTapOfferFlagVariant('decline');
        setShowSwipeFlagOverlay(true);
        return;
      }

      if (dir === 'right') {
        const offerLabel = oneTapActiveAccount?.profile.isAttractive() ? 'offer' : 'request';
        const flagVariant = oneTapActiveAccount.suggest ? offerLabel : 'accept';
        setOneTapOfferFlagVariant(flagVariant);
        setShowSwipeFlagOverlay(true);
        return;
      }

      resetFulfilledSwipeFlags();
    },
    [oneTapActiveAccount]
  );

  const resetFulfilledSwipeFlags = () => {
    setOneTapOfferFlagVariant(null);
    setShowSwipeFlagOverlay(false);
  };

  useEffect(() => {
    if (offers) {
      const cloneOffers = [...offers];

      // Detect if when someone send an offer in realtime ,then append to 2nd index
      if (isRealTimeEvent && cloneOffers && cloneOffers.length > 1) {
        [cloneOffers[0], cloneOffers[1]] = [cloneOffers[1], cloneOffers[0]];
        setIsRealtimeFetching(false);
      }

      setCurrentOffers(cloneOffers.reverse());
      updateCurrentIndex(cloneOffers.length - 1);
      setHasAccountsToShow(cloneOffers.length > 0);
    }
  }, [offers, isRealTimeEvent]);

  useEffect(() => {
    if (currentOffers.length > 0 && currentOffers[currentIndex]) {
      const otherAccount = currentOffers[currentIndex].other_account.data;
      setOneTapActiveAccount({
        ...currentOffers[currentIndex],
        account: AccountMaker.create(otherAccount),
        profile: ProfileMaker.create(otherAccount.profile),
      });
    }
  }, [currentIndex, currentOffers]);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };

    if (!hasAccountsToShow && offers.length === 0) {
      document.body.style.overflow = 'unset';
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.scrollTo(0, 0);
      window.addEventListener('scroll', handleScroll);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('scroll', handleScroll);
    };
  });

  if ((isFetching && !isRealtimeFetching) || (isFetching && isRealtimeFetching && currentOffers.length === 0)) {
    return <LoadingBackdrop active={true} showLoadingBg={false} />;
  }

  return (
    <StyledOfferListContainer
      style={{ position: hasAccountsToShow && videoPreview.media === null ? 'fixed' : 'absolute' }}
    >
      {account.data.account_config?.[OFFER_ONE_TAP_MODE_SHOW_TUTORIAL] &&
        (isSwipeEnabled ? (
          <OneTapSwipeOfferTutorial
            onConfirm={() => updateAccountConfigProp({ key: OFFER_ONE_TAP_MODE_SHOW_TUTORIAL, state: false })}
          />
        ) : (
          <OneTapSwipeOfferTutorialWithoutSwipe
            onConfirm={() => updateAccountConfigProp({ key: OFFER_ONE_TAP_MODE_SHOW_TUTORIAL, state: false })}
          />
        ))}

      {!hasAccountsToShow && offers.length === 0 && <OneTapSwipeNoMoreOffersScreen />}
      {hasAccountsToShow &&
        currentOffers.map((item: any, key: number) => {
          const member = item.other_account.data;
          return (
            <TinderCard
              ref={childRefs[key]}
              key={member.hash_id}
              className="swipe"
              preventSwipe={['up', 'down']}
              disableTouchSwipe={!isSwipeEnabled || oneTapActiveAccount?.hash_id !== member.hash_id}
              swipeThreshold={100}
              onSwipe={dir => {
                swiped(dir, key);
              }}
              onCardLeftScreen={handleOnCardLeftTheScreen}
              swipeRequirementType="position"
              onSwipeRequirementFulfilled={handleSwipeRequirementFulfilled}
              onSwipeRequirementUnfulfilled={() => resetFulfilledSwipeFlags()}
            >
              <OneTapSwipeOfferProfileCard profile={member.profile.data} account={AccountMaker.create(member)} />
            </TinderCard>
          );
        })}

      {hasAccountsToShow && <OneTapSwipeOfferVideoPreviewLightBox />}
      {showSwipeFlagOverlay && <OneTapSwipeOfferFlagOverlay />}
      {showSwipeFlag && (
        <OneTapSwipeOfferFlag
          onHidden={() => {
            setShowSwipeFlag(false);
          }}
        />
      )}
      {hasAccountsToShow && (
        <OneTapSwipeOfferActions
          swipe={dir => handleClickToSwipe(dir)}
          pageTitle={pageTitle}
          restoreCurrentCard={restoreCurrentIndexCard}
        />
      )}
    </StyledOfferListContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccountConfigProp: payload => {
      dispatch(updateAccountConfig(payload));
    },
  };
};

const mapStateToProps = state => {
  return {
    account: AccountMaker.create(state.profile),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTapSwipeOfferLists);
