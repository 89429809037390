export const STORE_GLOBAL_NOTIFICATION = 'STORE_GLOBAL_NOTIFICATION';
export const CLEAR_GLOBAL_NOTIFICATION = 'CLEAR_GLOBAL_NOTIFICATION';
export const REQUEST_GLOBAL_NOTIFICATION = 'REQUEST_GLOBAL_NOTIFICATION';

export interface GlobalNotification {
    notification: Record<string, unknown>;
}

export interface GlobalNotificationState extends GlobalNotification {
    isFetching: boolean;
}

interface STORE_GLOBAL_NOTIFICATION_ACTION {
    type: typeof STORE_GLOBAL_NOTIFICATION;
    payload: GlobalNotification;
}

interface CLEAR_GLOBAL_NOTIFICATION_ACTION {
    type: typeof CLEAR_GLOBAL_NOTIFICATION;
}

interface REQUEST_GLOBAL_NOTIFICATION_ACTION {
    type: typeof REQUEST_GLOBAL_NOTIFICATION;
}

export type GlobalNotificationActionType = 
    | STORE_GLOBAL_NOTIFICATION_ACTION
    | CLEAR_GLOBAL_NOTIFICATION_ACTION
    | REQUEST_GLOBAL_NOTIFICATION_ACTION;