export const STORE_OFFERS = 'STORE_OFFERS';
export const CLEAR_OFFERS = 'CLEAR_OFFERS';
export const REQUEST_OFFERS = 'REQUEST_OFFERS';
export const UPDATE_OFFER_LIST = 'UPDATE_OFFER_LIST';
export const TERMINATE_FETCHING = 'TERMINATE_FETCHING';
export const RESET_NEW_OFFERS = 'RESET_NEW_OFFERS';

export interface Items {
  items: unknown[];
}

export interface New {
  new: unknown[];
}

export interface Sent {
  sent: unknown[];
}

export interface Accepted {
  accepted: unknown[];
}

export interface Rejected {
  rejected: unknown[];
}

export interface Highlights {
  highlights: unknown[];
}

export interface IsFetching {
  isFetching: boolean;
}

export interface OfferType {
  offerType: string;
}

export interface OfferList {
  offerList: unknown[];
}

export interface offerPreFetchExpiration {
  offerPreFetchExpiration: number;
}

export interface Payload extends OfferType, OfferList {}

export interface OfferState
  extends Items,
    New,
    Sent,
    Accepted,
    Rejected,
    Highlights,
    IsFetching,
    offerPreFetchExpiration {}

interface STORE_OFFERS_ACTION {
  type: typeof STORE_OFFERS;
  firstCall: boolean;
  payload: unknown[];
  section: number | string;
  isFetching: IsFetching;
  isRealtimeEvent: boolean;
  offerPreFetchExpiration: number;
}

interface CLEAR_OFFERS_ACTION {
  type: typeof CLEAR_OFFERS;
  items: Items;
}

interface REQUEST_OFFERS_ACTION {
  type: typeof REQUEST_OFFERS;
  isFetching: IsFetching;
}

interface UPDATE_OFFER_LIST_ACTION {
  type: typeof UPDATE_OFFER_LIST;
  payload: Payload;
}

interface TERMINATE_FETCHING_ACTION {
  type: typeof TERMINATE_FETCHING;
}
interface RESET_NEW_OFFERS_ACTION {
  type: typeof RESET_NEW_OFFERS;
}

export type OfferActionTypes =
  | STORE_OFFERS_ACTION
  | CLEAR_OFFERS_ACTION
  | REQUEST_OFFERS_ACTION
  | UPDATE_OFFER_LIST_ACTION
  | TERMINATE_FETCHING_ACTION
  | RESET_NEW_OFFERS_ACTION;
