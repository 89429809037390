import { vysionEnabled } from '../../config/Master';
import Vysion from 'react-vysion';
import store from '../../store';
import { setGlobalVysion } from './globalVysion';
import { setPurchaseVysion } from './purchasesVysion';
import { setSocialVysion } from './socialVysion';
import { setOfferVysion } from './offerVysion';
import { setCartAbandonVysion } from './cartAbandonVysion';
import { setSearchVysion } from './searchVysion';
import { setBillingVysion } from './billingVysion';
import _camelCase from 'lodash/camelCase';
import { VYSION_ACTIONS } from '.';

export type EventDetails = {
  [key: string]: {
    action: VYSION_ACTIONS;
    page: 'string';
    component?: string;
    eventName: string;
    extraInfo?: string;
  };
};

export type EventData = {
  event: string;
  action?: VYSION_ACTIONS;
  extraInfo?: string;
  component?: string;
  newEventName?: string;
  distinctId?: string;
};

// create methods for generating vysion payload and mapping the correct url and its sub section
const vysionService = (action: any): void => {
  if (!vysionEnabled) {
    return;
  }

  switch (action.type) {
    case 'GLOBAL':
      // import the Global module and pass the params needed for determining what payload to send
      setGlobalVysion(action.payload);
      break;
    case 'OFFERS':
      // import the Offers module and pass the params needed for determining what payload to send
      setOfferVysion(action.payload);
      break;
    case 'PURCHASES':
      // import the Purchases module and pass the params needed for determining what payload to send
      setPurchaseVysion(action.payload);
      break;
    case 'SOCIAL':
      // import the Social module and pass the params needed for determining what payload to send
      setSocialVysion(action.payload);
      break;
    case 'CART_ABANDON':
      // import the Cart abandon module and pass the params needed for determining what payload to send
      setCartAbandonVysion(action.payload);
      break;
    case 'SEARCH':
      // import the search module and pass the params needed for determining what payload to send
      setSearchVysion(action.payload);
      break;
    case 'BILLING':
      // import the billing module and pass the params needed for determining what payload to send
      setBillingVysion(action.payload);
      break;
    default:
      return;
  }
};

const vysionTrack = (eventData: any, eventDefaultName = ''): void => {
  if (!vysionEnabled) {
    return;
  }

  // Vysion vysion.track(eventName, userData, eventData, uuid, deviceData, extendedData);
  const uuid = store.getState().profile.hash_id;
  const { page = '', extraInfo = '', component = '' } = eventData;
  const eventPage = page ? _camelCase(page.toLowerCase()) : '';
  const eventExtraInfo = extraInfo ? `${page ? '-' : ''}${_camelCase(extraInfo)}${component ? '-' : ''}` : '';
  const eventComponent = component ? `${page && !extraInfo ? '-' : ''}${_camelCase(component)}` : '';
  const eventName = eventDefaultName ? eventDefaultName : `${eventPage}${eventExtraInfo}${eventComponent}`;

  Vysion.track(eventName, {}, eventData, uuid);
};

const handleVysionEvent = <ValidEventsType>(data: EventData, validEvents: ValidEventsType): void => {
  const { event, action = VYSION_ACTIONS.CLICK, extraInfo, distinctId, newEventName, component } = data;
  const events = validEvents[action];

  if (!(event in events)) {
    return;
  }

  const { eventName, ...eventData } = events[event];

  if (extraInfo) {
    eventData.extraInfo = extraInfo;
  }

  if (distinctId) {
    eventData.distinctId = distinctId;
  }

  if (component) {
    eventData.component = component;
  }

  vysionTrack(
    {
      ...eventData,
    },
    newEventName ?? eventName
  );
};

export { vysionService, vysionTrack, handleVysionEvent };
