import React, { ReactElement, Fragment } from 'react';
import styled from '@emotion/styled';
import FavoriteButton from '../../../atoms/buttons/FavoriteButton';
import FavoriteOfferButton from '../../../atoms/buttons/FavoriteOfferButton';
import { dimensions } from '../../../style';
import { renderAvatar, displayUserLocation } from '../../../common';
import FavOfferCreate from '../Offers/FavOfferCreate';
import OfferModalFooter from '../Offers/OfferModalFooter';
import OfferSuccess from '../Offers/OfferSuccess';
import OfferAccept from '../Offers/OfferAccept';
import OfferAcceptUnlock from '../Offers/OfferAcceptUnlock';
import OfferAcceptMessage from '../Offers/OfferAcceptMessage';
import useModal from '../../../hooks/useModal';
import { declineOffer } from '../../../actions/profileTrackAction';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { useApp } from '../../../contexts/AppContext';
import { IsMobileViewPort } from '../../../utils/helpers';
import useMixPanel from '../../../hooks/useMixPanel';
import { toaster } from '../../../common';
import { useNavigate } from 'react-router-dom';
import { AccountMaker } from '../../../models/Account';

const FavoriteActionWrap = styled('div')(
  {
    margin: 0,
    justifyContent: 'middle',
    marginRight: 20,
    [dimensions.SCREEN_MAX_SM]: {
      marginRight: 0,
    },
  },
  ({ viewType }) =>
    viewType === 'grid' && {
      marginRight: 0,
    }
);

const ViewedMeButtonWrap = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'row',
  alignItems: 'center',
});

interface FavoriteActionButtonProps {
  favKey: any;
  favorited: any;
  toggleTracking: any;
  offer: any;
  page: any;
  user: any;
  viewType: any;
  otherAccount: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  isMatched: any;
  isSuggestRequest: any;
  ignoreOffer: any;
  unlockMessage(): void;
  pageTitle: string;
  enableSlider?: boolean;
}

const FavoriteActionButton = ({
  favKey,
  favorited,
  toggleTracking,
  offer,
  page,
  user,
  viewType,
  otherAccount,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  isMatched,
  isSuggestRequest,
  ignoreOffer,
  unlockMessage,
  pageTitle,
  enableSlider,
}: FavoriteActionButtonProps): ReactElement => {
  const { trackOfferDeclined } = useMixPanel();
  const hasOffer = offer !== undefined && offer.data !== undefined;
  const offerData = offer && offer.data;
  const offerAccepted = hasOffer && offerData.status === 'accepted';
  const offerIsFromOtherUser =
    hasOffer && offerData.status === 'pending' && offerData.to_account_hash_id === user.hash_id;
  const otherProfile = otherAccount.profile.data;
  const isGenerous = otherProfile.account_type === 'Attractive';
  const canSendOffer = isMatched && !offer;
  const userGender = user && user.profile.data.sex;
  const accountModel = AccountMaker.create(user);
  const { showTooManyPendingOffersErrorModal } = useModal();

  const offerTextGrid = isGenerous ? 'Make Offer' : 'Request Offer';
  const offerTextList = isGenerous ? 'Offer' : 'Request';

  const { setOldCompleteProfileModalState } = useApp();
  const navigate = useNavigate();

  const { basicModal, resetModal } = useModal();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const otherUserLoc = {
    country: otherProfile.country,
    region: otherProfile.region,
    city: otherProfile.city,
  };

  const completeProfileModalState = {
    avatar: renderAvatar(otherProfile.avatar.data.urls, true),
    username: otherAccount.username,
    location: displayUserLocation(otherProfile, otherUserLoc),
    age: otherAccount.age,
    editProfileLink: `/profile/${user.hash_id}`,
  };

  const handleGoToConversation = () => {
    navigate(`/mail/${otherAccount.hash_id}`);
  };

  const handleAttractiveMessage = (amount = '') => {
    showMandatoryCompleteProfile(
      () => {
        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          closeBtnDataTestId: 'fav',
          modalBody: (
            <Fragment>
              <OfferAcceptMessage
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                successOpen={resetModal}
                buttonID="interestedOffers"
                currentUserIsGenerous={isGenerous}
                creditCost={offer && offer.data.credit_cost}
                hashId={otherAccount.hash_id}
                closeModal={resetModal}
                onSubmit={handleAcceptWithMessage}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Accept Offer'
    );
  };

  const handleAcceptWithMessage = dealMessage => {
    if (accountModel.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
      return;
    }

    const newPageTitle = !enableSlider ? '2Col Viewed Me' : pageTitle;

    return acceptOffer(otherAccount.hash_id, dealMessage, favKey, page, newPageTitle);
  };

  const handleIgnoreOffer = async () => {
    if (accountModel.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
      return;
    }

    await ignoreOffer(otherAccount.hash_id, favKey, page);
    declineOffer();

    trackOfferDeclined(offer.data.initiator_hash_id, {
      Amount: offer.data.price_original,
      User: user.hash_id,
      Source: pageTitle,
    });

    toaster.warn('Offer Declined');

    resetModal();
  };

  const handleOfferSuccess = (toggle, amount = '', isCounter = false) => {
    basicModal({
      dateTestId: 'offer-success',
      modalContentLabel: 'Success Offer Modal',
      isModalOpen: toggle,
      modalBody: (
        <Fragment>
          <OfferSuccess
            offerAmount={amount}
            avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
            username={otherAccount.username}
            successOpen={resetModal}
            ignoreStatus={() => null}
            buttonID="interestedOffers"
            currentUserIsGenerous={isGenerous}
            isCounter={isCounter}
          />
          <OfferModalFooter />
        </Fragment>
      ),
    });
  };

  const handleAcceptUnlock = (toggle, amount = '', result) => {
    showMandatoryCompleteProfile(() => {
      if (accountModel.shouldShowTooManyLockedOffersDeals('accept')) {
        showTooManyPendingOffersErrorModal();
        return;
      }

      basicModal(
        {
          dateTestId: 'offer-success',
          modalContentLabel: 'Success Offer Modal',
          closeBtnDataTestId: 'fav',
          isModalOpen: toggle,
          modalBody: (
            <Fragment>
              <OfferAcceptUnlock
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                successOpen={resetModal}
                buttonID="interestedOffers"
                currentUserIsGenerous={isGenerous}
                creditCost={result.credit_cost}
                currentCredit={user.credits}
                hashId={otherAccount.hash_id}
                closeModal={resetModal}
                unlockMessage={unlockMessage}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        },
        () => undefined,
        'Unlock Offer'
      );
    });
  };

  const renderFavoriteButton = () => {
    return <FavoriteButton favorited={favorited} page={page} onClick={toggleTracking} />;
  };

  const renderSuggestButton = () => {
    return (
      <FavoriteOfferButton
        data-test-id="favorite-btn-offer"
        hasOffer={hasOffer}
        offer={offer}
        page={page}
        viewType={viewType}
        type="offer"
        contentText={viewType === 'list' && IsMobileViewPort ? offerTextList : offerTextGrid}
        customLabelStyle={
          !isGenerous && (viewType === 'grid' || (viewType === 'list' && !IsMobileViewPort))
            ? { maxWidth: IsMobileViewPort ? 61 : 73, textAlign: 'center' }
            : {}
        }
        customButtonStyle={viewType === 'list' && IsMobileViewPort ? { margin: '5px 10px' } : {}}
        onClick={() => {
          showMandatoryCompleteProfile(
            () => {
              if (!user.can_make_offer && isGenerous) {
                if (!accountModel.isMandatoryProfileCompleted()) {
                  setOldCompleteProfileModalState(prevState => {
                    return {
                      ...prevState,
                      props: completeProfileModalState,
                      isOpen: true,
                    };
                  });
                } else if (accountModel.shouldShowTooManyLockedOffersDeals()) {
                  showTooManyPendingOffersErrorModal();
                }

                return;
              }

              basicModal({
                dataTestID: 'offer-modal',
                closeBtnDataTestId: 'fav',
                modalContentLabel: 'Create Offer Modal',
                modalBody: (
                  <Fragment>
                    <FavOfferCreate
                      avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                      username={otherAccount.username}
                      hashId={otherAccount.hash_id}
                      clearErrors={clearErrors}
                      createNewOffer={handleCreateNewOffer}
                      successOpen={handleOfferSuccess}
                      counterOffer={handleCounterOffer}
                      isCounter={false}
                      closeModal={resetModal}
                      currencyInitial={otherAccount.currency.data.initial}
                      currencyLabel={otherAccount.currency.data.label_unicode}
                      currentUserIsGenerous={isGenerous}
                      errors={errors}
                      sex={userGender === 'Male' ? 'Female' : 'Male'}
                      userGender={userGender}
                      sendSuggestion={handleSuggestion}
                      showWarn={showWarn}
                      cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                      buttonID="interestedOffers"
                      isSuggestRequest={isSuggestRequest}
                      pageTitle={pageTitle}
                      otherProfile={otherProfile}
                      title={isGenerous ? 'Make an Offer!' : 'Request A Date!'}
                    />
                    <OfferModalFooter />
                  </Fragment>
                ),
              });
            },
            () => undefined,
            'Suggest Offer'
          );
        }}
      />
    );
  };

  const renderAcceptButton = () => {
    if (!offerIsFromOtherUser) {
      return '';
    }

    if (isSuggestRequest) {
      return renderSuggestButton();
    }

    return (
      <FavoriteOfferButton
        data-test-id="favorite-btn-accept"
        hasOffer={hasOffer}
        offer={offer}
        page={page}
        viewType={viewType}
        type="accept"
        onClick={() => {
          showMandatoryCompleteProfile(
            () => {
              if (!user.can_make_offer && isGenerous) {
                if (!accountModel.isMandatoryProfileCompleted()) {
                  setOldCompleteProfileModalState(prevState => {
                    return {
                      ...prevState,
                      props: completeProfileModalState,
                      isOpen: true,
                    };
                  });

                  return;
                }
              }

              basicModal({
                dataTestID: 'offer-success',
                closeBtnDataTestId: 'fav',
                modalContentLabel: 'Success Offer Modal',
                modalBody: (
                  <Fragment>
                    <OfferAccept
                      acceptUnlock={handleAcceptUnlock}
                      avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                      username={otherAccount.username}
                      hashId={otherAccount.hash_id}
                      clearErrors={clearErrors}
                      onHandleSubmit={() => {
                        if (accountModel.shouldShowTooManyLockedOffersDeals('accept')) {
                          showTooManyPendingOffersErrorModal();
                          return;
                        }

                        const newPageTitle = !enableSlider ? '2Col Viewed Me' : pageTitle;
                        return acceptOffer(otherAccount.hash_id, '', favKey, page, newPageTitle);
                      }}
                      onHandleAttractiveSubmit={handleAttractiveMessage}
                      successOpen={handleOfferSuccess}
                      counterOffer={handleCounterOffer}
                      closeModal={resetModal}
                      currentUserIsGenerous={isGenerous}
                      errors={errors}
                      sendSuggestion={() => handleSuggestion(otherAccount)}
                      showWarn={showWarn}
                      cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                      ignoreStatus={() => null}
                      justSuggested={false}
                      buttonID="acceptOffer"
                      offer={offer}
                      ignoreOffer={handleIgnoreOffer}
                      pageTitle={pageTitle}
                    />
                    <OfferModalFooter />
                  </Fragment>
                ),
              });
            },
            () => undefined,
            'Accept Offer'
          );
        }}
      />
    );
  };

  const renderSendOfferButton = () => {
    if (!canSendOffer || hasOffer || offerAccepted || offerIsFromOtherUser || isSuggestRequest) {
      return '';
    }

    return (
      <FavoriteOfferButton
        data-test-id="favorite-btn-offer"
        hasOffer={hasOffer}
        offer={offer}
        page={page}
        viewType={viewType}
        type="offer"
        contentText={viewType === 'list' && IsMobileViewPort ? offerTextList : offerTextGrid}
        customLabelStyle={
          !isGenerous && (viewType === 'grid' || (viewType === 'list' && !IsMobileViewPort))
            ? { maxWidth: IsMobileViewPort ? 61 : 73, textAlign: 'center' }
            : {}
        }
        customButtonStyle={viewType === 'list' && IsMobileViewPort ? { margin: '5px 10px' } : {}}
        onClick={() => {
          showMandatoryCompleteProfile(
            () => {
              if (!user.can_make_offer && isGenerous) {
                if (!accountModel.isMandatoryProfileCompleted()) {
                  setOldCompleteProfileModalState(prevState => {
                    return {
                      ...prevState,
                      props: completeProfileModalState,
                      isOpen: true,
                    };
                  });
                  return;
                } else if (accountModel.shouldShowTooManyLockedOffersDeals()) {
                  showTooManyPendingOffersErrorModal();
                  return;
                } else if (accountModel.shouldShowTooManySentOffers()) {
                  showTooManyPendingOffersErrorModal({ type: 'sent' });
                  return;
                }

                return;
              }

              basicModal({
                dataTestID: 'offer-modal',
                closeBtnDataTestId: 'fav',
                modalContentLabel: 'Create Offer Modal',
                modalBody: (
                  <Fragment>
                    <FavOfferCreate
                      avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                      username={otherAccount.username}
                      hashId={otherAccount.hash_id}
                      clearErrors={clearErrors}
                      createNewOffer={handleCreateNewOffer}
                      successOpen={handleOfferSuccess}
                      counterOffer={handleCounterOffer}
                      isCounter={false}
                      closeModal={resetModal}
                      currencyInitial={otherAccount.currency.data.initial}
                      currencyLabel={otherAccount.currency.data.label_unicode}
                      currentUserIsGenerous={isGenerous}
                      errors={errors}
                      sex={userGender === 'Male' ? 'Female' : 'Male'}
                      userGender={userGender}
                      sendSuggestion={handleSuggestion}
                      showWarn={showWarn}
                      cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                      buttonID="interestedOffers"
                      isSuggestRequest={isSuggestRequest}
                      pageTitle={pageTitle}
                      otherProfile={otherProfile}
                      title={isGenerous ? 'Make an Offer!' : 'Request A Date!'}
                    />
                    <OfferModalFooter />
                  </Fragment>
                ),
              });
            },
            () => undefined,
            'Make Offer'
          );
        }}
      />
    );
  };

  const handleCreateNewOffer = formData => {
    if (accountModel.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
      return;
    }

    const newPageTitle = !enableSlider ? '2Col Viewed Me' : pageTitle;
    return createNewOffer({ ...formData, source: newPageTitle }, favKey, page);
  };

  const handleCounterOffer = formData => {
    if (accountModel.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
      return;
    }

    return counterOffer(formData, favKey, page);
  };

  const handleSuggestion = profileId => {
    if (accountModel.shouldShowTooManyLockedOffersDeals()) {
      showTooManyPendingOffersErrorModal();
      return;
    }

    return sendSuggestion(profileId, favKey, page);
  };

  return (
    <FavoriteActionWrap viewType={viewType}>
      <ViewedMeButtonWrap
        style={
          !isGenerous && (viewType === 'grid' || (viewType === 'list' && !IsMobileViewPort))
            ? { alignItems: 'flex-start' }
            : {}
        }
      >
        {renderFavoriteButton()}
        {renderSendOfferButton()}
        {renderAcceptButton()}
        {hasOffer && offerAccepted && (
          <FavoriteOfferButton
            data-test-id="favorite-btn-conversation"
            hasOffer={hasOffer}
            offer={offer}
            viewType={viewType}
            page={page}
            onClick={handleGoToConversation}
            type="conversation"
            style={{ margin: '0px 0px 0px 0px' }}
          />
        )}
      </ViewedMeButtonWrap>
    </FavoriteActionWrap>
  );
};

export default FavoriteActionButton;
