import React, { useState, useEffect, useRef, ReactElement } from 'react';
import styled from '@emotion/styled';
import { commonIcons } from '../../atoms/icons/materialIcons';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { browserVersion, isIOS, isMobile, isSafari } from 'react-device-detect';
import { isQAautomation } from '../../utils/helpers';
import { nuxEnabled } from '../../config/Master';

const TooltipBody = styled('div')(
  {
    backgroundColor: '#2490de',
    minWidth: 200,
    maxWidth: 300,
    borderRadius: 8,
    zIndex: 1000,
    padding: '7px 10px',
  },
  ({ isSkip }) => ({
    backgroundColor: isSkip ? '#24A83A' : '#2B8FD7',
    border: '1px solid transparent',
    borderColor: isSkip ? '#90EE90' : 'transparent',
  })
);

const TooltipTitle = styled('div')`
  color: #fff;
  padding: 10px;
`;

const TooltipContent = styled('div')`
  color: #fff;
  padding: 10px;
`;

// Not the best way but it does it job as there is only one button for now
const TooltipFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

const TextLink = styled('a')`
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  cursor: pointer;
  margin-right: 5px;
`;

const StyledButton = styled('button')``;

const PrimaryButton = styled('button')(
  {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: 80,
    border: '1px solid #fff',
    borderRadius: 30,
    padding: '5px 10px',
    color: '#ffffff',

    '&:focus': {
      outline: 'none',
    },
  },
  ({ isSkip }) => ({
    '&:hover': {
      backgroundColor: '#ffffff',
      color: isSkip ? '#24A83A' : '#2490DE',
    },
  })
);

const skipContent = () => {
  return '<p>You can turn the <strong>Guided Tour</strong> back on by resetting the NUX in <strong>Account Settings &rsaquo; Notifications</strong></p>';
};

interface NuxGuideProps {
  profile: any;
  route: any;
  fetchPageNux: any;
  updateNuxState: any;
  onNuxInit: any;
  onNuxRunning: any;
  onNuxComplete: any;
  skipNuxGuides: any;
}

const NuxGuide = ({
  profile,
  route,
  fetchPageNux,
  updateNuxState,
  onNuxInit,
  onNuxRunning,
  onNuxComplete,
  skipNuxGuides,
}: NuxGuideProps): ReactElement => {
  const [skip, setSkip] = useState(false);

  const Tooltip = ({ continuous, step, closeProps, primaryProps, tooltipProps }) => (
    <TooltipBody {...tooltipProps} isSkip={skip}>
      {step.title && !skip && (
        <TooltipTitle>
          <strong>{step.title}</strong>
        </TooltipTitle>
      )}
      <TooltipContent
        id={'joyride-tooltip-content'}
        dangerouslySetInnerHTML={{ __html: skip ? skipContent() : step.content }}
      />
      <TooltipFooter>
        {!skip && <TextLink onClick={handleSkipNux}>Turn Off Guided Tour</TextLink>}
        {continuous && (
          <PrimaryButton {...primaryProps} isSkip={skip}>
            <commonIcons.check.raw /> Got It!
          </PrimaryButton>
        )}
        {!continuous && <StyledButton {...closeProps}>Close</StyledButton>}
      </TooltipFooter>
    </TooltipBody>
  );

  const customJoyrideCss = {
    options: {
      arrowColor: skip ? 'transparent' : '#2490de',
      backgroundColor: 'transparent',
      beaconSize: 36,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#2490de',
      spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
      textColor: '#fff',
      zIndex: 1001, // Just nice to cover the header z-index
    },
  };

  const [joyrideState, setJoyrideState] = useState({
    run: false,
    steps: [],
    stepIndex: 0,
  });
  const [update, setUpdate] = useState(false);
  const [joyrideStatus, setJoyrideStatus] = useState(null);

  const mounted = useRef(true);

  useEffect(() => {
    if (nuxEnabled) {
      setTimeout(() => {
        fetchPageNux(route);
      }, 1000);
    }
  }, [fetchPageNux, route]);

  useEffect(() => {
    const { nuxGuides } = profile;
    const qaAutomation = isQAautomation() !== undefined && isQAautomation() === true;

    if (!nuxEnabled) {
      return;
    }

    // To handle current logged in user without nuxGuides in their profile reducer
    if (nuxGuides === undefined) {
      return;
    }

    if (nuxGuides.data.length === 0) {
      return;
    }

    if (update === false && qaAutomation === false) {
      const guides = [];

      nuxGuides.data.forEach(item => {
        if (route === item.route) {
          const guide = {};

          guide['id'] = item.id;
          guide['target'] = isMobile ? item.mobile_target : item.target;
          guide['title'] = item.title;
          guide['content'] = item.content;
          guide['placement'] = item.placement === undefined ? 'auto' : item.placement;
          guide['disableBeacon'] = true;
          guide['isFixed'] = true;
          guide['floaterProps'] = {
            styles: {
              arrow: {
                length: 12,
                spread: 24,
              },
            },
            disableAnimation: true,
          };
          guides.push(guide);
        }
      });

      setJoyrideState({
        run: guides.length === 0 ? false : true,
        steps: guides,
        stepIndex: 0,
      });
    }
  }, [profile.nuxGuides, profile, route, update]);

  useEffect(() => {
    if (mounted.current) {
      mounted.current = false;
      return;
    }

    // Complete nux straight away if the nux is being disabled
    if (isQAautomation() === true) {
      if (onNuxComplete !== undefined) {
        onNuxComplete();
      }

      return;
    }

    if (profile.nuxGuides.isFetching === false && profile.nuxGuides.data.length === 0) {
      if (onNuxComplete !== undefined) {
        onNuxComplete();
      }
    }
  }, [profile.nuxGuides?.isFetching, onNuxComplete, profile.nuxGuides.data.length]);

  useEffect(() => {
    const contentSelector = document.getElementById('main');

    if (joyrideState.run === true) {
      if (!isIOS && !isMobile) {
        document.body.style.overflow = 'hidden';
      }

      document.body.style.touchAction = 'none';
      contentSelector.style.touchAction = 'none';
      contentSelector.style.overflow = 'visible';

      if (isSafari && browserVersion == '12') {
        document.documentElement.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'auto';
      contentSelector.style.touchAction = 'auto';
      contentSelector.style.overflow = 'unset';

      if (isSafari && browserVersion == '12') {
        document.documentElement.style.overflow = 'unset';
      }
    }
  }, [joyrideState.run]);

  useEffect(() => {
    if (joyrideStatus == STATUS.READY) {
      if (onNuxInit !== undefined) {
        onNuxInit();
      }
    }

    if (joyrideStatus == STATUS.RUNNING) {
      if (onNuxRunning !== undefined) {
        onNuxRunning();
      }
    }

    if (joyrideStatus == STATUS.FINISHED) {
      setJoyrideState({
        run: false,
        steps: [],
        stepIndex: 0,
      });

      if (onNuxComplete !== undefined) {
        onNuxComplete();
      }
    }
  }, [joyrideStatus, onNuxComplete, onNuxInit, onNuxRunning]);

  const handleSkipNux = e => {
    e.preventDefault();

    setSkip(true);
  };

  const handleJoyrideCallback = data => {
    const { action, index, status } = data;
    const guide = joyrideState.steps[index];

    setJoyrideStatus(status);

    if ([ACTIONS.NEXT].includes(action)) {
      // Update state to advance the tour

      if (skip) {
        skipNuxGuides();
        setJoyrideState({
          run: false,
          steps: [],
          stepIndex: index,
        });

        if (onNuxComplete !== undefined) {
          onNuxComplete();
        }
      } else {
        setUpdate(true);
        setJoyrideState({
          run: true,
          steps: joyrideState.steps,
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        });

        updateNuxState({
          nux_guide_id: guide.id,
          seen: true,
        });
      }
    }
  };

  if (!nuxEnabled) {
    return <div></div>;
  }

  if (joyrideState.steps.length !== 0) {
    return (
      <Joyride
        run={joyrideState.run}
        steps={joyrideState.steps}
        scrollOffset={isMobile ? 50 : 100}
        scrollToFirstStep={true}
        disableOverlayClose={true}
        showSkipButton={true}
        continuous={true}
        callback={handleJoyrideCallback}
        styles={customJoyrideCss}
        tooltipComponent={Tooltip}
        spotlightPadding={5}
      />
    );
  } else {
    return <div></div>;
  }
};

export default NuxGuide;
