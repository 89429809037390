import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')(
  {
    fill: 'none',
    height: '14px !important',
    width: '14px !important',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

interface PrivateEyeIconProps {
  color?: string;
  customStyle: any;
}

const PrivateEyeIcon = ({ color = '#FFFFFF', customStyle = {} }: PrivateEyeIconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 14 14" customStyle={customStyle}>
      <mask
        id="mask0_824_1625"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="14"
        height="12"
      >
        <rect y="2.07397" width="14" height="11.9259" fill={color} />
      </mask>
      <g mask="url(#mask0_824_1625)">
        <path
          d="M11.9636 13.9999L9.29091 11.5003C8.9197 11.6107 8.54594 11.6936 8.16964 11.749C7.79291 11.804 7.40303 11.8316 7 11.8316C5.39848 11.8316 3.97197 11.4125 2.72045 10.5745C1.46894 9.73608 0.562121 8.6493 0 7.31415C0.222727 6.78211 0.503788 6.2876 0.843182 5.83064C1.18258 5.37408 1.5697 4.96511 2.00455 4.60372L0.254545 2.91722L1.14545 2.07397L12.8545 13.1567L11.9636 13.9999ZM7 10.0246C7.11667 10.0246 7.22527 10.0196 7.32582 10.0095C7.42679 9.9995 7.53561 9.97942 7.65227 9.9493L4.21591 6.69678C4.18409 6.8072 4.16288 6.9102 4.15227 7.00577C4.14167 7.10093 4.13636 7.20373 4.13636 7.31415C4.13636 8.06705 4.41467 8.70712 4.97127 9.23435C5.5283 9.76118 6.20455 10.0246 7 10.0246ZM11.6455 10.2956L9.625 8.39833C9.69924 8.22767 9.75758 8.05461 9.8 7.87913C9.84242 7.70325 9.86364 7.51493 9.86364 7.31415C9.86364 6.56125 9.58533 5.92119 9.02873 5.39396C8.4717 4.86713 7.79545 4.60372 7 4.60372C6.78788 4.60372 6.58891 4.62379 6.40309 4.66395C6.2177 4.7041 6.03485 4.76434 5.85455 4.84464L4.23182 3.30873C4.66667 3.13807 5.11212 3.00998 5.56818 2.92445C6.02424 2.83932 6.50151 2.79676 7 2.79676C8.60151 2.79676 10.028 3.21577 11.2795 4.0538C12.5311 4.89223 13.4379 5.97901 14 7.31415C13.7561 7.90643 13.4353 8.45615 13.0378 8.9633C12.6399 9.47006 12.1758 9.91417 11.6455 10.2956ZM8.70227 7.52497L6.79318 5.71801C7.09015 5.66781 7.36336 5.6903 7.61282 5.78547C7.86185 5.88104 8.07652 6.01917 8.25682 6.19986C8.43712 6.38056 8.56715 6.58896 8.64691 6.82507C8.72624 7.06078 8.7447 7.29408 8.70227 7.52497Z"
          fill={color}
        />
      </g>
    </Icon>
  );
};

export default PrivateEyeIcon;
