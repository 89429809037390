import React from 'react';
import styled from '@emotion/styled';
import { AmexIcon, DiscoverIcon, MasterCardIcon, VisaIcon } from './icons/Billing';
import GooglePayIcon from './billing/GooglePay/GooglePayIcon';
import { dimensions } from '../style';
import { isDarkMode } from '../utils/helpers';
import ApplePayIcon from './billing/GooglePay/ApplePayIcon';
import { APAY_PAYMENT_TYPE } from '../config/apay';
import { GPAY_PAYMENT_TYPE } from '../config/gpay';

const Input = styled('input')`
  display: none;
  cursor: pointer;
  &:checked + label::before {
    border: 7px solid #2490de;
  }
`;

const Label = styled('label')`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: '';
    ${props => (props.hideInput ? 'display: none;' : 'display: inline-block;')}
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border: 2px solid #2490de;
    border-radius: 50%;
    margin-right: 8px;
  }
  &::before + input:checked {
    border: 7px solid #2490de;
  }
  ${dimensions.SCREEN_MIN_XS_4} {
    font-size: 15px;
  }
`;

interface IRadioButtonProps {
  id: any;
  name: any;
  label: any;
  style: any;
  onRadioChange: any;
  value: any;
  checked: boolean;
  labelId: string | null;
  cardType?: string | null;
  paymentType?: string | null;
  hideInput?: boolean;
}

const PaymentOptionRadio: React.FC<IRadioButtonProps> = ({
  id,
  name,
  label,
  style,
  onRadioChange,
  value,
  checked,
  labelId = '',
  cardType,
  paymentType,
  hideInput = false,
}) => {
  const renderLabel = (label, id, isChecked): React.ReactNode => {
    if (hideInput) {
      return <strong>{`${label}`}</strong>;
    }

    if (id === 'radio-new-card' && isChecked) {
      return <strong>{`${label}`}</strong>;
    }

    if (id === 'radio-card-reference') {
      const words = label.split(' ');
      return words.map(str => {
        return str === 'Visa' ||
          str === 'Mastercard' ||
          str === 'Amex' ||
          str === 'Discover' ||
          (parseInt(str) && typeof parseInt(str) === 'number') ? (
          <strong key={Math.floor(Math.random() * 999)}>{` ${str}`}</strong>
        ) : (
          ` ${str}`
        );
      });
    }

    return label;
  };

  const renderCardType = (): React.ReactElement | null => {
    switch (cardType?.toLowerCase()) {
      case 'visa':
        return <VisaIcon customStyle={{ marginRight: 10 }} />;
      case 'master card':
      case 'mastercard':
      case 'master':
        return <MasterCardIcon customStyle={{ marginRight: 10 }} />;
      case 'discover':
      case 'discovery':
        return <DiscoverIcon customStyle={{ marginRight: 10 }} />;
      case 'american express':
      case 'amex':
        return <AmexIcon customStyle={{ marginRight: 10 }} />;
      default:
        return null;
    }
  };

  const renderPaymentMethod = (paymentType: any): any => {
    switch (paymentType) {
      case GPAY_PAYMENT_TYPE:
        return <GooglePayIcon height={28} darkmode={isDarkMode()} />;
      case APAY_PAYMENT_TYPE:
        return <ApplePayIcon height={28} darkmode={isDarkMode()} />;
      default:
        return null;
    }
  };

  return (
    <div style={style}>
      {!hideInput && (
        <Input
          type="radio"
          data-test-id={`radio-${name}-${value}`}
          name={name}
          id={id}
          onChange={onRadioChange}
          value={value}
          checked={checked}
        />
      )}
      <Label htmlFor={`${id}`} data-test-id={labelId} hideInput={hideInput}>
        {renderPaymentMethod(paymentType)}
        {paymentType !== APAY_PAYMENT_TYPE && renderCardType()}
        <span>{renderLabel(label, labelId, checked)}</span>
      </Label>
    </div>
  );
};

export default PaymentOptionRadio;
