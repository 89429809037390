import React, { useEffect, useState, useMemo, SetStateAction, Dispatch } from 'react';
import styled from '@emotion/styled';
import Container from '../../blocks/Container';
import ResultList from '../../blocks/ResultList';
import { Select } from '../../../atoms/Select';
import Button from '../../../atoms/buttons/Button';
import { dimensions } from '../../../style';
import { connectScreenSize } from 'react-screen-size';
import { Content, Aside, PrimaryContent } from '../../blocks/FlexLayout';
import { getLocalItem, setLocalItem } from '../../../common';
import { HeartIcon, GridView, ListView } from '../../../atoms/icons/favoriteIcons';
import { getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import TopNav from '../../blocks/TopNav';
import OffersTutorialModal from '../../common/modals/OffersTutorialModal';
import OfferList from './OfferList';
import Loading from '../../../atoms/Loading';
import { PER_PAGE } from '../../../constants/offers';
import { OFFERS_NEW_PATH, OFFERS_SENT_PATH } from '../../../constants/paths';
import { useOffers } from '../../../contexts/OffersContext';
import { useLocation } from 'react-router-dom';
import OneTapSwipeOfferLists from './oneTapSwipeOffer/components/OneTapSwipeOfferLists';
import OneTapSwipeOfferTopNav from './oneTapSwipeOffer/components/OneTapSwipeOfferTopNav';
import useEvidently from '../../../hooks/useEvidently';
import {
  ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
  OneTapOfferManagementVariations,
} from '../../../utils/evidentlyFeatures/oneTapOfferManagement';

const calcOfferListHeight = (isCardSwipeGridView: boolean, isFetching: boolean, offersLength: number) => {
  if (isCardSwipeGridView) {
    if (isFetching) {
      return 'auto';
    } else if (offersLength === 0) {
      return getBrowserDetails().hImgCalibration + 120;
    } else {
      return 'calc(100vh - 50px)';
    }
  } else {
    return 'auto';
  }
};

const ButtonContainer = styled('div')({
  margin: '16px auto',
  maxWidth: '200px',
});

const HiddenContainer = styled('div')({
  display: 'none',
});

const SubHeader = styled('div')({
  fontSize: 18,
  fontWeight: 400,
  color: 'rgba(140, 140, 140, 1)',
  borderBottom: '1px solid rgba(201, 201, 201, 1)',
  paddingBottom: 10,
  flex: '1 0 100%',
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  width: '100%',
  [dimensions.SCREEN_MAX_SM]: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
    paddingTop: 5,
  },
  [dimensions.SCREEN_MAX_SM]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const SubHeaderText = styled('span')({
  [dimensions.SCREEN_MAX_SM]: {
    display: 'none',
  },
});

const VerticalLine = styled('span')({
  display: 'inline-block',
  borderLeft: '1px solid #D9E1E8',
  margin: '0 10px',
  height: 20,
});

const Box = styled.div(props => ({
  display: 'flex',
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
  width: '100%',
  [dimensions.SCREEN_MAX_SM]: {
    paddingLeft: props.isLeft ? 10 : 0,
    paddingRight: props.isLeft ? 0 : 10,
  },
}));

const ViewActions = styled('a')({
  cursor: 'pointer',
  marginRight: 15,
  height: 22,
  width: 22,
  [dimensions.SCREEN_MAX_SM]: {
    marginRight: 15,
  },
});

const OffersList = styled(ResultList)({
  padding: '26px 40px',
  display: 'flex',
  flexFlow: 'wrap',
  borderRadius: '10px !important',
  [dimensions.SCREEN_MAX_MD]: {
    padding: '16px 10px',
    borderRadius: '0 !important',
  },
  [dimensions.SCREEN_MAX_SM]: {
    padding: 0,
  },
  justifyContent: 'center',
});

const OffersListWrapper = styled('div')(
  {
    padding: '0px 40px',
    display: 'flex',
    flexFlow: 'wrap',
    flex: '1 0 100%',
    // gap: '10px 23px',
    ['> * + *']: {
      marginLeft: '23px',
      ['@media (max-width: 962px)']: {
        marginLeft: '20px',
      },
      ['&:nth-of-type(4n+5)']: {
        marginLeft: 0,
        [dimensions.SCREEN_MAX_MD]: {
          marginLeft: '8px',
        },
      },
    },
    [dimensions.SCREEN_MAX_SM]: {
      justifyContent: 'flex-start',
      marginLeft: '5vw',
    },
    [dimensions.SCREEN_MAX_MD_2]: {
      marginLeft: 0,
    },
    [dimensions.SCREEN_MAX_MD]: {
      padding: '0px 4px',
    },
  },
  ({ isListView }) =>
    isListView && {
      padding: '0px 40px',
      marginLeft: 0,
      ['> * + *']: {
        marginLeft: 0,
      },
      [dimensions.SCREEN_MAX_MD]: {
        marginLeft: 0,
      },
      [dimensions.SCREEN_MAX_SM]: {
        marginLeft: '0vw',
      },
    }
);

interface OffersV2Props {
  offerType: any;
  refresh: any;
  ignoreOffer: any;
  acceptOffer: any;
  counterOffer: any;
  isFetching: any;
  profile: any;
  loadMore: any;
  offers: any;
  moreExists: any;
  screenSizeIsMobile: any;
  createNewOffer: any;
  clearErrors: any;
  errors: any;
  sendSuggestion: any;
  ignoreOfferFromList: any;
  showWarn: any;
  updateOffer: any;
  updateOfferTutorialState: any;
  isFirstCall: any;
  triggerUnlockMessage: any;
  meta: any;
  isRealtimeFetching: boolean;
  setIsRealtimeFetching: Dispatch<SetStateAction<boolean>>;
}

const Offers = ({
  offerType,
  refresh,
  ignoreOffer,
  acceptOffer,
  counterOffer,
  isFetching,
  profile,
  loadMore,
  offers,
  moreExists,
  screenSizeIsMobile,
  createNewOffer,
  clearErrors,
  errors,
  sendSuggestion,
  ignoreOfferFromList,
  showWarn,
  updateOffer,
  updateOfferTutorialState,
  triggerUnlockMessage,
  meta,
  isRealtimeFetching,
  setIsRealtimeFetching,
}: OffersV2Props): any => {
  const location = useLocation();
  const [orderingType, setOrderingType] = useState('recent');
  const [, setLastOfferType] = useState(offerType);
  const { offer_counts } = profile;
  const [viewType, setViewType] = useState(getLocalItem('offers-view') || 'grid');
  const { isMobile } = getBrowserDetails();
  const { useActiveVariation } = useEvidently();
  const showOfferTutorial = useMemo(() => profile.account_config?.show_offer_tutorial, [
    profile.account_config?.show_offer_tutorial,
  ]);

  const { setOffersActionHandler, setVisitedTabHandler } = useOffers();

  const oneTapOfferVariation = useActiveVariation(
    ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME
  ) as OneTapOfferManagementVariations;

  const activeRoute = useMemo(() => (location.pathname === OFFERS_NEW_PATH ? 'new' : 'sent'), [location.pathname]);
  const isOneTapModeVariation =
    oneTapOfferVariation && oneTapOfferVariation === '1-TapOfferManagement' && getBrowserDetails().isMobile;

  const isCardSwipeGridView = useMemo(
    () => activeRoute === 'new' && viewType === 'grid' && isOneTapModeVariation && getBrowserDetails().isMobile,
    [activeRoute, viewType, isOneTapModeVariation]
  );

  useEffect(() => {
    setOffersActionHandler({ updateOffer });
  }, [updateOffer, setOffersActionHandler]);

  useEffect(() => {
    setVisitedTabHandler(offerType, true);
  }, [offerType, setVisitedTabHandler]);

  const isGenerous = useMemo(() => profile?.profile?.data.account_type === 'Generous', [
    profile?.profile?.data.account_type,
  ]);

  const subRoutes = useMemo(
    () => [
      {
        page: `Received ${isGenerous ? 'Requests' : 'Offers'} (${offer_counts?.new ?? 0})`,
        headline: `Received ${isGenerous ? 'Requests' : 'Offers'}`,
        icon: {
          default: <HeartIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={false} />,
          active: <HeartIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={true} />,
        },
        url: OFFERS_NEW_PATH,
        active: /.*new.*/,
        noResultsTitle: `Your dates are out there!`,
        noResultsMsg: `You don’t  have any offers now, but don’t worry, the more you interact with other members, the more you get!.`,
        noResultsImg: 'offer',
      },
      {
        page: `Sent ${isGenerous ? 'Offers' : 'Requests'} (${offer_counts?.pending ?? 0})`,
        headline: `Sent ${isGenerous ? 'Offers' : 'Requests'}`,
        icon: {
          default: <HeartIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={false} />,
          active: <HeartIcon width={getBrowserDetails().isMobile ? 14 : 18} isActive={true} />,
        },
        url: OFFERS_SENT_PATH,
        active: /.*sent$/,
        noResultsTitle: 'It takes two to tango!',
        noResultsMsg: 'The more offers you make, the more dates you get (it’s science).',
        noResultsImg: 'offer',
      },
    ],
    [isGenerous, offer_counts]
  );

  const getActivePagetTitle = () => {
    const activePage = location.pathname;

    const activeRoute = subRoutes.find(function(routeItem) {
      const regex = routeItem.active;
      const active = regex.test(activePage);

      return active ? routeItem : false;
    });

    return activeRoute && activeRoute.headline;
  };

  useEffect(() => {
    setOrderingType('recent');
    setLastOfferType(offerType);
  }, [offerType]);

  const changeOrder = e => {
    setOrderingType(e.target.value);
    setVisitedTabHandler(offerType, true);
    refresh(offerType, e.target.value, true);
  };

  const handleViewSwitcher = () => {
    const viewTypeValue = viewType === 'list' ? 'grid' : 'list';
    setViewType(viewTypeValue);
    setLocalItem('offers-view', viewTypeValue);
  };

  const renderOrderDropDown = () => {
    return (
      <Select onChange={changeOrder} value={orderingType} style={{ overflow: 'hidden' }} noSpacing>
        <option value="recent" key="new">
          Newest First
        </option>
        <option value="oldest" key="old">
          Oldest First
        </option>
        <option value="highest" key="high">
          Highest Price
        </option>
        <option value="lowest" key="low">
          Lowest Price
        </option>
      </Select>
    );
  };

  const sliderLoadMore = () => {
    if (!moreExists) return;
    offers.length && !isFetching && loadMore(activeRoute, orderingType, false, PER_PAGE);
  };

  const renderMoreExists = () => {
    if (!moreExists) return;

    return (
      <>
        {isFetching && offers.length > 0 ? (
          <ButtonContainer>
            <Loading />
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button
              buttonType="primary"
              fit
              onClick={() => {
                loadMore(activeRoute, orderingType, false, PER_PAGE);
              }}
              customStyle={{ fontWeight: 500 }}
            >
              Load More...
            </Button>
          </ButtonContainer>
        )}
      </>
    );
  };

  if (!offer_counts) return null;

  const noResults = {};

  for (let i = 0; i < subRoutes.length; i++) {
    const subRoute = subRoutes[i];

    if (subRoute.active.test(location.pathname)) {
      noResults.title = subRoute.noResultsTitle;
      noResults.msg = subRoute.noResultsMsg;
      noResults.img = subRoute.noResultsImg;
    }
  }

  return (
    <Container fullWidth screenTop>
      <OffersTutorialModal
        showTutorial={showOfferTutorial}
        isFirstTimeOffer
        onClose={updateOfferTutorialState}
        linkStyle={{ display: 'none' }}
      />
      <Content className="offers-content" isMobile={isMobile}>
        <Aside className="offers--menu-wrap">
          {isOneTapModeVariation ? (
            <OneTapSwipeOfferTopNav
              className="offers-sub-nav"
              location={location}
              subRoutes={subRoutes}
              customItem={
                <ViewActions
                  data-test-id="list-grid-view-btn"
                  onClick={() => handleViewSwitcher()}
                  aria-label={viewType === 'list' ? 'List' : 'Grid'}
                  style={{ marginRight: 9 }}
                >
                  {viewType === 'list' ? <GridView /> : <ListView />}
                </ViewActions>
              }
            />
          ) : (
            <TopNav className="offers-sub-nav" location={location} subRoutes={subRoutes} />
          )}
        </Aside>
        <PrimaryContent>
          <OffersList style={{ height: calcOfferListHeight(isCardSwipeGridView, isFetching, offers.length) }}>
            <SubHeader viewType={viewType}>
              {!isOneTapModeVariation ? (
                <>
                  <Box alignItems={'flex-end'} isLeft={true}>
                    <SubHeaderText data-test-id="offers-sub-header">{getActivePagetTitle()}</SubHeaderText>
                    {!IsMobileViewPort && <VerticalLine />}
                    <OffersTutorialModal onClose={updateOfferTutorialState} hideArrows clickableIndicators />
                  </Box>
                  <Box
                    justifyContent={IsMobileViewPort ? 'space-between' : 'flex-end'}
                    alignItems="center"
                    isLeft={false}
                  >
                    <ViewActions
                      data-test-id="list-grid-view-btn"
                      onClick={() => handleViewSwitcher()}
                      aria-label={viewType === 'list' ? 'List' : 'Grid'}
                    >
                      {viewType === 'list' ? <GridView /> : <ListView />}
                    </ViewActions>
                    {renderOrderDropDown()}
                  </Box>
                </>
              ) : (
                // This line of code is wrapped in a HiddenContainer component.
                // Removing this line may cause an addEventListener error, not sure really why??
                <HiddenContainer> {renderOrderDropDown()}</HiddenContainer>
              )}
            </SubHeader>
            {isCardSwipeGridView ? (
              <OneTapSwipeOfferLists
                pageTitle={getActivePagetTitle()}
                isFetching={isFetching}
                offers={offers}
                moreExists={moreExists}
                isRealTimeEvent={meta?.offer_new_is_realtime_event ?? false}
                loadMore={() => loadMore(activeRoute, 'recent', false, PER_PAGE)}
                isRealtimeFetching={isRealtimeFetching}
                setIsRealtimeFetching={setIsRealtimeFetching}
              />
            ) : (
              <OffersListWrapper data-test-id="offer-list-wrapper" isListView={viewType === 'list'}>
                <OfferList
                  activeRoute={activeRoute}
                  offers={offers}
                  isFetching={isFetching}
                  toggle={true}
                  noResultsTitle={noResults.title}
                  noResultsMsg={noResults.msg}
                  noResultsImg={noResults.img}
                  user={profile}
                  screenSizeIsMobile={screenSizeIsMobile}
                  viewType={viewType}
                  createNewOffer={createNewOffer}
                  sendSuggestion={sendSuggestion}
                  clearErrors={clearErrors}
                  errors={errors}
                  showWarn={showWarn}
                  acceptOffer={acceptOffer}
                  counterOffer={counterOffer}
                  ignoreOffer={ignoreOffer}
                  ignoreOfferFromList={ignoreOfferFromList}
                  unlockMessage={triggerUnlockMessage}
                  updateOffer={updateOffer}
                  pageTitle={getActivePagetTitle()}
                  sliderLoadMore={sliderLoadMore}
                  offerType={offerType}
                />
              </OffersListWrapper>
            )}

            {!isCardSwipeGridView && renderMoreExists()}
          </OffersList>
        </PrimaryContent>
      </Content>
    </Container>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
  };
};

export default connectScreenSize(mapScreenToProps)(Offers);
