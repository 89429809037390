import React from 'react';

import Container from '../../blocks/Container';
import ResultList from '../../blocks/ResultList';
import Button from '../../../atoms/buttons/Button';
import { Header } from '../../../atoms/text';
import Headline from '../../common/Headline';
import BlankSlate from '../../blocks/BlankSlate';
import BiometricListItem from '../../../atoms/BiometricListItem';
import _ from 'lodash';
import Loader from '../../../atoms/LoaderStyled';
import { isUVPAA, registerNewCredential, removeCurrentCredentials } from '../../../utils/webAuthn';

interface BiometricsListProps {
  biometrics: any;
  moreExists: any;
  isFetching: any;
  handleChangeName: any;
}

const BiometricsList = ({ biometrics, moreExists, isFetching, handleChangeName }: BiometricsListProps): any => {
  const renderBiometricList = () => {
    // loop through each mail item.
    if (!_.isEmpty(biometrics.biometricsList)) {
      const BiometricList = biometrics.biometricsList.map((biometricData, index) => {
        return biometricData ? (
          <BiometricListItem
            key={index}
            name={`${biometricData.name}`}
            removeThisCredential={() => removeCurrentCredentials(biometricData.id)}
            handleChangeName={handleChangeName}
            biometricId={biometricData.id}
          />
        ) : null;
      });
      return (
        <div>
          <ResultList>{BiometricList}</ResultList>
          {moreExists ? (
            <Loader active={isFetching} background="transparent">
              <div style={{ margin: '16px auto', maxWidth: '200px' }}>
                <Button
                  buttonType="primary"
                  fit
                  onClick={() => {
                    loadMore(blocked.pagination !== undefined ? parseInt(blocked.pagination.current_page, 10) + 1 : 1);
                  }}
                >
                  Load More
                </Button>
              </div>
            </Loader>
          ) : null}
        </div>
      );
    } else {
      return <BlankSlate title={'You have no registered device'} message="" />;
    }
  };

  const renderBiometricRegister = () => {
    return (
      <Button
        disabled={!isUVPAA()}
        buttonType="primary"
        customStyle={{ marginTop: '10px', marginBottom: '20px', maxWidth: '240px' }}
        fit
        onClick={registerNewCredential}
      >
        Register This Device
      </Button>
    );
  };

  return (
    <div>
      <Container fullWidth>
        <Header bottomRuled>
          <div style={{ padding: '0 1rem' }}>
            <Headline data-test-id="fs-modal-headline" hasDescription>
              Biometric Device List
            </Headline>
          </div>
        </Header>
        {renderBiometricRegister()}
        {renderBiometricList()}
      </Container>
    </div>
  );
};

export default BiometricsList;
