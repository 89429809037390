import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';

interface WYPIconProps {
  customStyle?: React.CSSProperties;
}

const WYPIcon = ({ customStyle }: WYPIconProps): ReactElement => {
  return (
    <svg width="50" height="42" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" style={customStyle}>
      <g>
        <path
          fill="#bc2028"
          d="M14.7,15.8c0.2-0.2,0.3-0.4,0.5-0.5c-1.9-1.8-4.5-3.2-6.8-4.5c-0.1,0-0.1-0.1-0.2-0.1l0,0
                        c-0.7-0.4-1.3-0.8-1.9-1.2C4,7.3,5.8,4.5,8.5,4.6c1.5,0.1,3.3,1.5,4.2,2.3c-0.2-1.4,0.7-3.2,2.5-4.7C13.6,0.9,11.3,0,8.6,0
                        C8.4,0,8.3,0,8.2,0c-0.3,0-0.7,0-1,0.1C3.2,0.6,0.5,3.5,0,6.9c0.1,0.2,0,0.4,0,0.7c0-0.2,0-0.4,0.1-0.7C0,7.3,0,7.8,0,8.2
                        C0,8,0,7.9,0,7.7C0,7.9,0,8,0,8.2l0,0l0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.3,5.3,5.4,6.4,8.2,8.1c1.9,1.1,3.7,2.1,5.1,3.4
                        C13.2,18.7,13.5,17.2,14.7,15.8z M10.6,0.2c-0.1,0-0.1,0-0.2,0C10.5,0.2,10.5,0.2,10.6,0.2z M9.7,0.1L9.7,0.1L9.7,0.1z M9,0H8.9H9
                        z"
        />
        <ellipse fill={isDarkMode() ? '#71B5E4' : '#2b8fd7'} cx="15.7" cy="27.4" rx="2.6" ry="2.6" />
        <path
          fill={isDarkMode() ? '#71B5E4' : '#2b8fd7'}
          d="M23,0.4c0.3,0,0.4,0,0.2,0C23.1,0.4,23,0.4,23,0.4c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0.1-2.1,0.3
                        c0.3-0.1,0.5-0.1,0.8-0.1c-0.3,0-0.5,0.1-0.8,0.2c-5.4,1.2-8.1,5.9-5.6,7.7c1.6,1.2,3.2-0.5,3.4-0.6c0,0,2.7-2.7,4.8-2.8
                        c2.8-0.1,4.5,2.6,2.3,4.8c-2.6,2-6.9,3.6-9.5,6.5c-2.2,2.5-1.4,5.4,0,7.2c1.5-3,4.4-4.5,7.5-6.4c2.9-1.7,8.1-2.8,8.2-8.4
                        C31.8,4.1,28.3,0.3,23,0.4z"
        />
      </g>
    </svg>
  );
};

export default WYPIcon;
