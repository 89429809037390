export const minimumAgeJoin = 18; // minimum age to signup.
export const minimumAge = 18; // minimum age to search.
export const maximumAge = 90; // maximum age at signup.
export const maximumAgeMaleForFemale = 40;
export const maximumAgeFemaleForMale = 55;
export const minPassLength = 6; // minimum password length.
export const minUsernameLength = 3; // minimum username length.
export const minimumHeight = 150; // min height in CM
export const maximumHeight = 215; // max height in CM

export default {
  minimumAgeJoin,
  minimumAge,
  maximumAge,
  maximumAgeMaleForFemale,
  maximumAgeFemaleForMale,
  minPassLength,
  minUsernameLength,
  minimumHeight,
  maximumHeight,
};
