import React, { Fragment, useEffect, useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { MailOutlineOutlined } from '@material-ui/icons';
import styled from '@emotion/styled';
import { textColor } from '../../../../../style';

const commonStyles = {
  fontSize: 26,
  color: '#0D5C93',
};

const RequestForInformationDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const SpanText = styled('span')({
  fontSize: 16,
  paddingTop: 3,
});

const DataProtectionStatus = styled('div')(
  {
    fontSize: 13,
    marginTop: 10,
    ...textColor.DARK,
  },
  ({ capitalize }) => capitalize && { textTransform: 'capitalize' }
);

const RequestForInformation = (props: any): any => {
  /* This component is created to handle the rendering of 
       userinfo status whether they are in the following statuses:

       - Data not present(Dosen't do anything)
       - Data has been cleaned(show status of the userinfo)
       - Data has not been sent to user yet(userinfo === null)
       - Data is ready for download
       - Data is processing
    */
  const { userInfo, requestUserInfo } = props;
  const [myInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(userInfo);
  }, [userInfo]);

  const RequestMenu = () => {
    return (
      <RequestForInformationDiv>
        <SpanText>Request Information</SpanText>
        <SvgIcon component={MailOutlineOutlined} style={commonStyles} />
      </RequestForInformationDiv>
    );
  };

  const isDownloadable = () => {
    if (myInfo.status === 'finished') {
      return (
        <div>
          {RequestMenu()}
          <DataProtectionStatus>
            Email Sent, or{' '}
            <a style={{ textDecoration: 'underline' }} href={myInfo.file_link}>
              Download
            </a>
          </DataProtectionStatus>
        </div>
      );
    } else if (myInfo.status === 'processing') {
      return (
        <div>
          {RequestMenu()}
          <DataProtectionStatus>Processing</DataProtectionStatus>
        </div>
      );
    } else {
      return (
        <Fragment>
          <RequestForInformationDiv>
            <SpanText>Request Information</SpanText>
            <SvgIcon component={MailOutlineOutlined} style={commonStyles} />
          </RequestForInformationDiv>
          <DataProtectionStatus capitalize>{myInfo.status}</DataProtectionStatus>
        </Fragment>
      );
    }
  };

  const evalUserInfoStatus = () => {
    return myInfo === null || myInfo.status === 'expired' || myInfo.status === 'failed';
  };

  const checkRequestForInfoStatus = () => {
    if (myInfo === undefined) {
      return null;
    } else if (evalUserInfoStatus()) {
      requestUserInfo();
    }
  };

  if (myInfo === undefined || evalUserInfoStatus()) {
    return (
      <div style={{ width: '100%' }}>
        <RequestForInformationDiv onClick={checkRequestForInfoStatus}>
          <SpanText>Request Information</SpanText>
          <SvgIcon component={MailOutlineOutlined} style={commonStyles} />
        </RequestForInformationDiv>
      </div>
    );
  } else {
    return <div style={{ width: '100%' }}>{isDownloadable()}</div>;
  }
};

export default RequestForInformation;
