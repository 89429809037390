import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Spinner from './Spinner/Spinner';

interface LoadingProps {
  color?: any;
  width?: any;
}

const LoaderContainer = styled('div')({
  display: 'inline-flex',
});

const LoadingV2 = ({ width = 100 }: LoadingProps): ReactElement => {
  return (
    <LoaderContainer>
      <Spinner width={width} />
    </LoaderContainer>
  );
};

export default LoadingV2;
