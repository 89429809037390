import React, { useState, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Avatar from '../../atoms/Avatar';
import RenderPhoto from '../../atoms/RenderPhoto';
import StatusError from '../icons/StatusError';
import { tsSince } from '../../common';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PulseLoader from 'react-spinners/PulseLoader';
import { textColor } from '../../style';
import { ternaryFunc } from '../../utils/helpers';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  marginBottom: 14,
  marginLeft: 10,
  zIndex: 1,
});

const BubbleWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  ({ isSent }) => ({
    float: isSent ? 'right' : 'none',
    alignItems: isSent ? 'flex-end' : 'flex-start',
  })
);

const Bubble = styled('div')(
  {
    borderRadius: 4,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'pre-wrap',
    maxWidth: '45%',
    position: 'relative',
    overflowWrap: 'break-word',
  },
  ({ isSent, isError, isIndicator }) => ({
    top: isIndicator ? -1 : '',
    backgroundColor: isError ? '#cc0000' : ternaryFunc(isSent, '#2490de', '#f1f0f0'),
    color: isSent ? '#ffffff' : ternaryFunc(!isError, '#000000', '#ffffff'),
    float: isSent ? 'right' : 'none',
    padding: '10px 14px',
  })
);

const BubbleDeleted = styled('div')(
  {
    borderRadius: 4,
    margin: 10,
    whiteSpace: 'pre-wrap',
    maxWidth: '45%',
    position: 'relative',
    overflowWrap: 'break-word',
  },
  ({ isSent, isError }) => ({
    backgroundColor: isError ? '#cc0000' : 'none',
    color: !isError ? '#333333' : '#ffffff',
    float: isSent ? 'right' : 'none',
  })
);

const BubbleTime = styled('div')({
  marginLeft: 10,
  marginRight: 10,
  fontSize: 12,
});

const BubbleTail = styled('div')(
  {
    position: 'absolute',
    bottom: 20,
    borderStyle: 'solid',
  },
  ({ isSent, isError }) => ({
    right: isSent ? 5 : 'auto',
    left: isSent ? 'auto' : 5,
    borderWidth: isSent ? '5px 0px 5px 5px' : '5px 5px 5px 0px',
    borderColor: isSent
      ? `transparent transparent transparent ${ternaryFunc(isError, 'rgb(204, 0, 0)', 'rgb(36, 144, 222)')}`
      : 'transparent rgb(241, 240, 240) transparent transparent',
  })
);
const StyledDeleteIcon = styled('div')(
  {
    position: 'absolute',
    top: -3,
    color: '#adadad',
  },
  ({ isSent }) => ({
    right: isSent ? 'unset' : -25,
    left: isSent ? -25 : 'unset',
  })
);

interface MessageBubbleProps {
  id: any;
  onResendMessage: any;
  avatar: any;
  created_at: any;
  isRead: any;
  isSent: any;
  error: any;
  message: any;
  type: any;
  isUploading: any;
  scrollToBottom: any;
  isBlurred: boolean;
  handleDeleteImg: any;
  handleUnblurImage: any;
  handleReportImg: any;
  urls: any;
}

const MessageBubble = ({
  id,
  onResendMessage,
  avatar,
  created_at,
  isRead,
  isSent,
  error,
  message,
  type,
  isUploading,
  scrollToBottom,
  isBlurred = true,
  handleDeleteImg,
  handleUnblurImage,
  handleReportImg,
  urls,
  ...rest
}: MessageBubbleProps): ReactElement => {
  const [date, setDate] = useState(created_at ? tsSince(created_at.date, false, false) : '');
  const isDeleted = rest.is_deleted;
  const showPhotoMessage = type === 'photo' && !isDeleted;
  const showDeleted = type === 'photo' && isDeleted;
  created_at && setInterval(() => setDate(tsSince(created_at.date, false, false)), 3000);

  const renderAvatar = useCallback(() => {
    return !isSent && <Avatar src={avatar} width={28} />;
  }, [isSent, avatar]);
  const renderErrorStatus = useCallback((error, id) => {
    return (
      error && (
        <div onClick={() => onResendMessage(id)} style={{ cursor: 'pointer', position: 'absolute', left: -30, top: 0 }}>
          <StatusError />
        </div>
      )
    );
  }, []);

  const setEmojiSize = useCallback(textLength => {
    switch (textLength) {
      case 4:
        return 42;
      case 6:
        return 32;
      default:
        return 60;
    }
  }, []);

  if (type === 'indicator') {
    return (
      <Container>
        <div>
          <BubbleWrapper isSent={isSent}>
            <BubbleTail isSent={isSent} isIndicator />
            <Bubble isSent={isSent} isIndicator>
              <PulseLoader color="#000000" size={8} />
            </Bubble>
          </BubbleWrapper>
        </div>
      </Container>
    );
  }

  if (showPhotoMessage) {
    return (
      <Container>
        {renderAvatar()}
        <div style={{ width: '100%' }} id={id}>
          <BubbleWrapper isSent={isSent}>
            <BubbleTail isError={error} isSent={isSent} />
            <Bubble isError={error} isSent={isSent}>
              {renderErrorStatus(error, id)}
              <RenderPhoto
                error={error}
                messageId={id}
                handleDeleteImg={handleDeleteImg}
                handleUnblurImage={handleUnblurImage}
                handleReportImg={handleReportImg}
                hashId={rest.hash_id}
                isSent={isSent}
                scrollToBottom={scrollToBottom}
                isBlurred={isBlurred}
                isUploading={isUploading}
                photoUrl={message !== '' && JSON.parse(message)}
                urls={urls}
              />
            </Bubble>
            <BubbleTime>
              {date}
              {ternaryFunc(isRead, ' • Read', '')}
            </BubbleTime>
          </BubbleWrapper>
        </div>
      </Container>
    );
  }

  if (type === 'emoji') {
    return (
      <Container>
        {renderAvatar()}
        <div style={{ width: '100%' }} id={id}>
          <BubbleWrapper isSent={isSent}>
            <p
              data-test-id={`emoji-size-${setEmojiSize(message.length)}`}
              style={{ margin: '0 10px', fontSize: setEmojiSize(message.length) }}
            >
              {message}
            </p>
            <BubbleTime>
              {date}
              {ternaryFunc(isRead, ' • Read', '')}
            </BubbleTime>
          </BubbleWrapper>
        </div>
      </Container>
    );
  }

  if (showDeleted) {
    return (
      <Container>
        {renderAvatar()}
        <div style={{ width: '100%' }} id={id}>
          <BubbleWrapper isSent={isSent}>
            <BubbleDeleted isError={error} isSent={isSent}>
              {renderErrorStatus(error, id)}
              {!error && (
                <StyledDeleteIcon isSent={isSent}>
                  <DeleteOutlineIcon />
                </StyledDeleteIcon>
              )}
              <p style={{ margin: error ? 10 : 0, ...textColor.DARK }}>This message is deleted</p>
            </BubbleDeleted>
            <BubbleTime>
              {date}
              {ternaryFunc(isRead, ' • Read', '')}
            </BubbleTime>
          </BubbleWrapper>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {renderAvatar()}
      <div style={{ width: '100%' }} id={id}>
        <BubbleWrapper isSent={isSent}>
          <BubbleTail isError={error} isSent={isSent} />
          <Bubble isError={error} isSent={isSent}>
            {renderErrorStatus(error, id)}
            <p style={{ margin: 0 }}>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </p>
          </Bubble>
          <BubbleTime>
            {date}
            {ternaryFunc(isRead, ' • Read', '')}
          </BubbleTime>
        </BubbleWrapper>
      </div>
    </Container>
  );
};

MessageBubble.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // Object containing the date and timezone strings
  created_at: PropTypes.shape({
    date: PropTypes.string,
    timezone: PropTypes.string,
  }),
  avatar: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.any,
  isSent: PropTypes.bool,
};

export default React.memo(MessageBubble);
