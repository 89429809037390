import React, { ReactElement } from 'react';
import Button from '../../atoms/buttons/Button';
import ButtonSet from '../blocks/ButtonSet';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import DetectRTC from 'detectrtc';
import '../../style/customModalAnimate.css';
import MasterConfig, { s3BucketDirect } from '../../config/Master';

const Content = styled('div')({
  position: 'relative',
  minHeight: '75px',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
});

const IconContainer = styled('div')({
  position: 'relative',
  margin: 10,
});

const ModalHeader = styled('span')({
  marginTop: 0,
  flex: 1,
});

const BrowserName = styled('span')({
  marginTop: 0,
  flex: 1,
});

const BrowserImages = styled('img')`
  padding: 3px;
  display: block;
  width: 75px;
  height: 75px;
`;

interface UnsupportedBrowserModalProps {
  setModalOpen: any;
  isOpen: any;
  isReceiver: boolean;
}

const UnsupportedBrowserModal = ({
  setModalOpen,
  isOpen,
  isReceiver = false,
}: UnsupportedBrowserModalProps): ReactElement => {
  const setModalVisibility = status => {
    setModalOpen(status);
  };

  const setModalStyle = () => {
    return {
      overlay: {
        zIndex: 8000,
        backgroundColor: 'rgba(0,0,0,0.65)',
        overflowY: 'auto',
      },
      content: {
        maxWidth: '520px',
        transform: 'scale(1)',
        margin: '0 auto',
        outline: 'none',
        top: '82px',
        bottom: 'initial',
        ':focus': {
          outline: 'none',
        },
        maxHeight: '80vh',
        overflow: 'auto',
      },
    };
  };

  const browserTitles = () => {
    if (DetectRTC.isMobileDevice && DetectRTC.osName === 'iOS') {
      return 'Safari';
    }
    return 'Google Chrome or Mozilla Firefox';
  };

  const renderIcons = () => {
    if (DetectRTC.isMobileDevice && DetectRTC.osName === 'iOS') {
      return (
        <IconContainer>
          <BrowserImages src={`${s3BucketDirect}browsers/safari-ios.svg`} width="32" height="20" alt="Safari" />
          <BrowserName>Safari</BrowserName>
        </IconContainer>
      );
    }
    return (
      <React.Fragment>
        <IconContainer>
          <BrowserImages src={`${s3BucketDirect}browsers/chrome.svg`} width="32" height="20" alt="Chrome" />
          <BrowserName>Chrome</BrowserName>
        </IconContainer>
        <IconContainer>
          <BrowserImages src={`${s3BucketDirect}browsers/firefox.svg`} width="32" height="20" alt="Firefox" />
          <BrowserName>Mozilla Firefox</BrowserName>
        </IconContainer>
      </React.Fragment>
    );
  };

  const renderReceiverText = () => {
    if (isReceiver) return 'Someone is trying to call you.';
  };

  return (
    <ReactModal
      closeTimeoutMS={300}
      style={setModalStyle()}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setModalVisibility(false)}
      isOpen={isOpen}
    >
      <ModalHeader>
        {renderReceiverText()} Unfortunately, your browser doesn&apos;t support our new video-calling feature yet. To{' '}
        {isReceiver ? 'receive' : 'make'} a call, open {MasterConfig.BASE_URL} in {browserTitles()}.
      </ModalHeader>
      <Content>{renderIcons()}</Content>
      <ButtonSet align={'right'}>
        <Button onClick={() => setModalVisibility(false)} buttonType="primary" customStyle={{ marginTop: 15 }}>
          OK
        </Button>
      </ButtonSet>
    </ReactModal>
  );
};

export default UnsupportedBrowserModal;
