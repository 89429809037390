import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { dimensions, textColor, borders, textType, backgroundTheme, statusColor } from '../../style';
import Container from './Container';

const StyledContainer = styled(Container)({
  width: '100%',
  [dimensions.SCREEN_MIN_MD]: {
    padding: '0 70px',
  },
});

const Nav = styled('div')({
  backgroundColor: backgroundTheme.personalInfo,
  borderBottom: borders.DEFAULT,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderWidth: 0,
  fontSize: 12,
  display: 'flex',
  listStyle: 'none',
  fontWeight: 'bold',
  ...textColor.DARK,
  [dimensions.SCREEN_MIN_MD]: {
    backgroundColor: 'transparent',
  },
  [dimensions.SCREEN_MAX_MD]: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
});

const List = styled('ul')({
  margin: '0',
  padding: '0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  listStyle: 'none',
  marginBottom: 10,
  [dimensions.SCREEN_MAX_MD]: {
    marginBottom: 0,
  },
});

const ListItem = styled('li')(
  {
    fontWeight: 'bold',
    ...textColor.DARK,
    [dimensions.SCREEN_MIN_MD]: {
      display: 'block',
    },
    display: 'inline-block',
    textAlign: 'center',
    position: 'relative',
  },
  ({ isSelected }) =>
    isSelected && {
      color: 'rgba(188, 32, 40, 1)',
    },
  ({ itemCount }) =>
    itemCount && {
      width: `${100 / itemCount}%`,
    }
);

const StyledLink = styled(Link)(
  {
    display: 'inline-block',
    padding: '22px 8px 8px 8px',
    whiteSpace: 'nowrap',
    ...textType.text,
    fontSize: 18,
    [dimensions.SCREEN_MAX_MD]: {
      padding: 8,
      fontSize: 14,
    },
    [dimensions.SCREEN_MAX_XXS]: {
      padding: 8,
      fontSize: 12,
    },
  },
  ({ selected }) =>
    selected && {
      fontWeight: 700,
      color: 'rgba(188, 32, 40, 1)',
    }
);

const BorderBottom = styled('div')(
  {
    borderRadius: 10,
    backgroundColor: 'transparent',
    height: 4,
    width: '100%',
    position: 'relative',
    marginTop: 8,
  },
  ({ isSelected }) =>
    isSelected && {
      backgroundColor: 'rgba(188, 32, 40, 1)',
      '::after': {
        content: '""',
        position: 'absolute',
        top: '-4px',
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 0,
        height: 0,
        borderBottom: 'solid 4px rgba(188, 32, 40, 1)',
        borderLeft: 'solid 4px transparent',
        borderRight: 'solid 4px transparent',
      },
    }
);

const LinkIconContianer = styled('span')`
  ${dimensions.SCREEN_MAX_SM} {
    & > svg {
      height: 12px;
    }
  }
`;

const NotificationCount = styled('span')({
  position: 'absolute',
  right: -14, //offset by padding
  top: -10,
  fontSize: 12,
  color: '#fff',
  backgroundColor: statusColor.notification,
  lineHeight: '8px',
  minWidth: 8,
  textAlign: 'center',
  borderRadius: 16,
  padding: 4,
  fontWeight: 600,
  border: `2px solid ${statusColor.notification}`,
});

const IconPlaceholder = styled('div')({
  position: 'relative',
});

interface TopNavProps {
  location: any;
  subRoutes: any;
  className: any;
  favCount: number;
  viewCount: number;
}

const TopNav = ({
  location,
  subRoutes,
  className,
  favCount = 0,
  viewCount = 0,
  children,
}: TopNavProps): ReactElement => {
  const getBadge = routeItem => {
    switch (routeItem) {
      case '/favorites':
      case '/interests/favorited-me':
        return (
          <IconPlaceholder>
            {favCount > 0 && <NotificationCount>{favCount > 99 ? '99+' : favCount}</NotificationCount>}
          </IconPlaceholder>
        );
      case '/interests':
      case '/favorites/viewed-me':
        return (
          <IconPlaceholder>
            {viewCount > 0 && <NotificationCount>{viewCount > 99 ? '99+' : viewCount}</NotificationCount>}
          </IconPlaceholder>
        );
      default:
        return '';
    }
  };

  const buildMenu = () => {
    const activePage = location.pathname;
    const itemCount = Object.keys(subRoutes).length;

    return subRoutes.map(function(routeItem) {
      const regex = routeItem.active;
      const active = regex.test(activePage);

      return (
        <ListItem itemCount={itemCount} key={routeItem.url} isSelected={active}>
          <StyledLink to={routeItem.url} selected={active}>
            {getBadge(routeItem.url)}
            <LinkIconContianer>
              {active ? routeItem.icon.active : routeItem.icon.default} {routeItem.page}
            </LinkIconContianer>
            <BorderBottom isSelected={active} />
          </StyledLink>
        </ListItem>
      );
    });
  };

  return (
    <Nav className={className} data-test-id={className}>
      <StyledContainer fullWidth>
        <List id="top-nav-list">{buildMenu()}</List>
      </StyledContainer>
      {children}
    </Nav>
  );
};

TopNav.propTypes = {
  subRoutes: PropTypes.array.isRequired,
};

export default TopNav;
