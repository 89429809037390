import React, { ReactElement } from 'react';
interface IPromptModalButtonIconProps {
  customStyle?: any;
}

const PromptModalButtonIcon = ({ customStyle, ...rest }: IPromptModalButtonIconProps): ReactElement => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <g id="AddCircle/Solid">
        <path
          id="icon_art"
          d="M6.95167 10.5H8.11833V7.63H11V6.46334H8.11833V3.5H6.95167V6.46334H4V7.63H6.95167V10.5ZM7.5031 14C6.53684 14 5.62879 13.8162 4.77894 13.4487C3.9291 13.0812 3.18722 12.5806 2.55333 11.9467C1.91944 11.3128 1.41875 10.5706 1.05125 9.7202C0.68375 8.86977 0.5 7.96109 0.5 6.99417C0.5 6.02724 0.68375 5.11856 1.05125 4.26815C1.41875 3.41772 1.91944 2.6775 2.55333 2.0475C3.18722 1.4175 3.92938 0.91875 4.7798 0.55125C5.63023 0.18375 6.53891 0 7.50583 0C8.47276 0 9.38144 0.18375 10.2319 0.55125C11.0823 0.91875 11.8225 1.4175 12.4525 2.0475C13.0825 2.6775 13.5812 3.41833 13.9487 4.27C14.3162 5.12167 14.5 6.03063 14.5 6.9969C14.5 7.96316 14.3162 8.87121 13.9487 9.72106C13.5812 10.5709 13.0825 11.3121 12.4525 11.9445C11.8225 12.577 11.0817 13.0777 10.23 13.4466C9.37833 13.8155 8.46937 14 7.5031 14Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default PromptModalButtonIcon;
