import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
  page: 'Payment',
};

export const enum PaymentOnFocusVysionEventsEnum {
  CARD_NAME_ENTRY = 'cardNameEntry',
  CARD_NUMBER_ENTRY = 'cardNumberEntry',
  CARD_EXPIRATION_ENTRY = 'cardExpiryEntry',
  ZIP_POSTAL_CODE_ENTRY = 'zipPostalCodeEntry',
  CARD_SECURITY_CODE_ENTRY = 'cardSecurityCodeEntry',
  CITY_ENTRY = 'cityEntry',
  STATE_PROVINCE_ENTRY = 'stateProvinceEntry',
  ADDRESS_ENTRY = 'addressEntry',
  PROMO_CODE = 'promoCodeEntry',
}

export const paymentOnFocusVysionEvents = {
  [PaymentOnFocusVysionEventsEnum.CARD_NAME_ENTRY]: {
    ...commonValues,
    component: 'Card Name Entry',
    eventName: 'payment-cardNameEntry',
  },
  [PaymentOnFocusVysionEventsEnum.CARD_NUMBER_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardNumberEntry',
    component: 'Card Number Entry',
  },
  [PaymentOnFocusVysionEventsEnum.CARD_EXPIRATION_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardExpirationEntry',
    component: 'Card Expiration Entry',
  },
  [PaymentOnFocusVysionEventsEnum.CARD_SECURITY_CODE_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardSecurityCodeEntry',
    component: 'Card Security Code Entry',
  },
  [PaymentOnFocusVysionEventsEnum.ZIP_POSTAL_CODE_ENTRY]: {
    ...commonValues,
    eventName: 'payment-zipPostalCodeEntry',
    component: 'Zip Postal Code Entry',
  },
  [PaymentOnFocusVysionEventsEnum.CITY_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cityEntry',
    component: 'City Entry',
  },
  [PaymentOnFocusVysionEventsEnum.STATE_PROVINCE_ENTRY]: {
    ...commonValues,
    eventName: 'payment-stateProvinceEntry',
    component: 'State Province Entry',
  },
  [PaymentOnFocusVysionEventsEnum.ADDRESS_ENTRY]: {
    ...commonValues,
    eventName: 'payment-addressEntry',
    component: 'Address Entry',
  },
  [PaymentOnFocusVysionEventsEnum.PROMO_CODE]: {
    ...commonValues,
    eventName: 'payment-promoCode',
    component: 'Promo Code',
  },
};
