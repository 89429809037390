import React from 'react';
import styled from '@emotion/styled';
import { dimensions, textType } from '../style';
import { commonIcons } from '../atoms/icons/materialIcons';
import { ternaryFunc } from '../utils/helpers';
import ProfileEditButtonV2 from '../atoms/buttons/ProfileEditButtonV2';

const Details = styled('div')({
  width: '100%',
  [dimensions.SCREEN_MAX_XS_2]: {
    padding: '0 15px',
  },
});

const Username = styled('h1')({
  margin: 0,
  paddingBottom: '4px',
  ...textType.header,
  textTransform: 'none',
  [dimensions.SCREEN_MAX_XS_2]: {
    color: '#fff',
    fontSize: 18,
    letterSpacing: -0.54,
    fontWeight: 600,
    lineHeight: 'normal',
    paddingBottom: 3,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
});

const Detail = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: 420,
  whiteSpace: 'nowrap',
  [dimensions.SCREEN_MAX_XS_2]: {
    color: '#CFCFCF',
    lineHeight: 'normal',
    fontSize: 15,
    fontWeight: 400,
    letterSpacing: -0.45,
    paddingBottom: 3,
    width: '100%',
    whiteSpace: 'unset',
    overflow: 'unset',
  },
});

const DetailLastActive = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  ...textType.caption,
  [dimensions.SCREEN_MAX_XS_2]: {
    lineHeight: 'normal',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.36,
    color: '#AFB0B1',
  },
});

const MyProfileCoverInfo = ({
  displayName,
  auth10tixVerification,
  isVerified,
  age,
  toggleEditable,
  lastLoginTime,
  lastLoginLocation,
  setMyCurrLocation,
  editable,
  freshLocation,
  myLocation,
}: any): any => {
  return (
    <Details>
      <Username data-test-id="profile-username-info">
        {displayName}
        {auth10tixVerification && isVerified && (
          <span style={{ marginLeft: 1 }}>
            <commonIcons.check.badge showToolTip={false} />
          </span>
        )}
        {editable && (
          <ProfileEditButtonV2
            dataTestId="edit-profile-cover"
            handleClick={toggleEditable}
            customStyle={{
              float: 'right',
            }}
          />
        )}
      </Username>
      <Detail data-test-id="profile-user-details">
        {age}
        {setMyCurrLocation()}
        {ternaryFunc(editable, freshLocation, myLocation)}
      </Detail>
      <DetailLastActive data-test-id="profile-last-log">
        {lastLoginTime} {lastLoginLocation && `in ${lastLoginLocation}`}
      </DetailLastActive>
    </Details>
  );
};

export default MyProfileCoverInfo;
