import React, { useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { dimensions } from '../../style';
import { profilePhoto, otherProfilePhoto } from '../../actions/profileTrackAction';
import { triggerTracking } from '../../utils/helpers';
import { HeartNotificationIcon, OfferNew } from '../icons/favoriteIcons';
import ProfilePhotoSliderCard from './ProfilePhotoSliderCard';
import { useProfile } from '../../contexts/ProfileContext';

const fadeInOut = keyframes`
  0% {
    opacity: 1;
    margin-top: 0px;
  }
  80% {
    margin-top: 0px;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.7;
    margin-top: -27px;
  }
`;

const ProfileCardBanner = styled('div')`
  position: relative;
  padding: 6px 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  svg {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-right: 4px;
  }
  ${dimensions.SCREEN_MAX_SM} {
    font-size: 12px;
    svg {
      height: 12px;
    }
  }
  background: ${props => (props.customBackground ? props.customBackground : 'rgba(188,32,40,0.9)')};
  animation: ${fadeInOut} 3.5s ease-in-out forwards;
`;

const SliderWrapper = styled('div')(() => ({
  position: 'relative',
}));

const setPhotoAction = (e, profileStatus) => {
  switch (profileStatus) {
    case 'myProfile':
      profilePhoto(e);
      break;
    case 'otherProfile':
      otherProfilePhoto(e);
      break;
    default:
      return;
  }
};

interface ProfilePhotoSliderV2Props {
  photoList: Array<unknown>;
  onClick: (index: number, e: Record<string, unknown>) => void;
  offer: any;
  isOtherProfile: boolean;
  profileDetailsComponent: any;
}

const ProfilePhotoSliderV2 = ({
  photoList,
  onClick,
  offer,
  isOtherProfile,
  profileDetailsComponent,
}: ProfilePhotoSliderV2Props): JSX.Element => {
  const { profileCardBannerRef } = useProfile();
  const myProfile = useRef(null);
  const otherProfile = useRef(null);
  const hasOfferStatus = useMemo(() => offer && offer.status, [offer]);

  useEffect(() => {
    triggerTracking('myProfile', myProfile, setPhotoAction);
    triggerTracking('otherProfile', otherProfile, setPhotoAction);
  }, []);

  const renderProfileCardBanner = () => {
    if (hasOfferStatus) {
      if (offer.status === 'accepted') {
        return (
          <ProfileCardBanner
            data-test-id="profile-banner-offer-accepted"
            customBackground="rgba(19, 131, 210, 0.9)"
            ref={profileCardBannerRef}
          >
            <HeartNotificationIcon /> Offer Accepted!
          </ProfileCardBanner>
        );
      }

      if (offer.status === 'pending') {
        if (offer.to_account_hash_id === myProfile.hash_id) {
          return (
            <ProfileCardBanner
              ref={profileCardBannerRef}
              data-test-id={offer.suggest ? 'profile-banner-suggest-request' : 'profile-banner-offer-request'}
              customBackground="rgba(191, 146, 0, 0.9)"
            >
              {offer.suggest ? 'Asked you to Offer!' : 'Sent You an Offer!'}
            </ProfileCardBanner>
          );
        }

        return (
          <ProfileCardBanner
            data-test-id="profile-banner-pending-offer"
            customBackground="rgba(191, 146, 0, 0.9)"
            ref={profileCardBannerRef}
          >
            <OfferNew fill="rgba(255,255,255,1)" /> Pending Offer
          </ProfileCardBanner>
        );
      }
    }

    return '';
  };

  return (
    <>
      {renderProfileCardBanner()}
      <SliderWrapper>
        <ProfilePhotoSliderCard
          photoList={photoList}
          onClickPhoto={onClick}
          isOtherProfile={isOtherProfile}
          profileDetailsComponent={profileDetailsComponent}
        />
      </SliderWrapper>
    </>
  );
};

export default ProfilePhotoSliderV2;
