import React, { useEffect, useState } from 'react';
import ApplePayMechantConfig from './ApplePayMechantConfig';
import { IApplePayMechantConfig } from './ApplePayTypes';
import ApplePayButton from './ApplePayButton';
import styled from '@emotion/styled';
import BillingTermsAndCondition from '../../modules/billing/BillingTermsAndCondition';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import AfterPayment from '../../modules/billing/AfterPayment';
import { getPackageSubTotal, isApplePayEligible } from '../../utils/billing';
import { APAY_PAYMENT_CHANNEL } from '../../config/apay';
import omit from 'lodash/omit';
import './ApplePayStyles.css';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { PaymentClickVysionEventsEnum } from '../../utils/vysion/payment/click';
import { handlePaymentVysionEvent } from '../../utils/vysion/payment';
import { logException } from '../../utils/helpers';

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyItems: 'center',
});

const ButtonWrap = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyItems: 'center',
});

interface IApplePayForm {
  use2PayData: any;
  handleSubmit: any;
  discount: any;
  userCountry: string;
  applepayCredentials: any;
  hidePackageForm: any;
  descriptors: any;
}

const ApplePayForm: React.FC<IApplePayForm> = ({
  use2PayData,
  discount,
  userCountry,
  applepayCredentials,
  handleSubmit,
  hidePackageForm,
  descriptors,
}): React.ReactElement => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isApplePayButtonDisabled, setIsApplePayButtonDisabled] = useState(false);
  const [merchantConfig, setMerchantConfig] = useState<IApplePayMechantConfig>(ApplePayMechantConfig);

  useEffect(() => {
    if (applepayCredentials) {
      setMerchantConfig({
        ...merchantConfig,
        ...applepayCredentials,
        ...{ paymentRequest: getPaymentRequestdata(use2PayData, ApplePayMechantConfig) },
      });
      setIsReady(true);
    }
  }, [applepayCredentials, use2PayData]);

  const getPaymentRequestdata = (use2PayData: any, applePayMechantConfig: IApplePayMechantConfig): any => {
    const paymentRequest = applePayMechantConfig.paymentRequest;

    return {
      ...paymentRequest,
      ...{
        countryCode: use2PayData?.use2pay_billing_country || 'US',
        currencyCode: use2PayData?.selectedPackage?.currency?.data.code || 'USD',
        total: {
          label: '',
          type: 'final',
          amount: getPackageSubTotal(use2PayData?.selectedPackage, discount).toString(),
        },
      },
    };
  };

  const validateMerchant = (merchantConfig: IApplePayMechantConfig): any => {
    const data = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: '*/*',
      },
      body: JSON.stringify({
        merchantIdentifier: merchantConfig.PARTNER_INTERNAL_MERCH_ID,
        displayName: merchantConfig.DISPLAY_NAME,
        initiative: merchantConfig.INITIATIVE,
        initiativeContext: merchantConfig.DOMAIN_LIST[0].DOMAIN_NAME,
      }),
    };

    return fetch(merchantConfig.fetchUrl, data);
  };

  const onApplePayClick = () => {
    if (!window.ApplePaySession) {
      return;
    }

    setIsApplePayButtonDisabled(true);

    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PaymentClickVysionEventsEnum.CLICK_GOOGLE_PAY,
    });

    const paymentRequest = merchantConfig.paymentRequest;

    const session = new window.ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = async () => {
      validateMerchant(merchantConfig)
        .then(res => res.json()) // Parse the response as JSON.
        .then(merchantSession => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch(error => {
          logException(error, { tags: { component: 'ApplePay', action: 'applePayValidateMerchant' } }, 'warning');
          console.error('Error fetching merchant session', error);
        });
    };

    session.onpaymentauthorized = async event => {
      const result = {
        status: window.ApplePaySession.STATUS_SUCCESS,
      };

      const applePaymentToken = event.payment;
      const tokenEncoded = btoa(JSON.stringify(applePaymentToken));

      use2PayData.applepay_token = tokenEncoded;
      use2PayData.use2pay_payment_channel = APAY_PAYMENT_CHANNEL;
      use2PayData.applepay_card_type = applePaymentToken.token?.paymentMethod?.network;

      if (use2PayData.use2pay_billing_state === null || use2PayData.use2pay_billing_state.length <= 0) {
        use2PayData = omit(use2PayData, ['use2pay_billing_state']);
      }

      if (use2PayData.applepay_token) {
        hidePackageForm();
        handleSubmit(
          omit(use2PayData, [
            'new_card',
            'use2pay_billing_address',
            'use2pay_billing_city',
            'use2pay_billing_name_on_card',
            'use2pay_billing_zip',
            'use2pay_card_cvv',
            'use2pay_card_exp_month',
            'use2pay_card_exp_year',
            'use2pay_card_number',
          ])
        );
      }

      session.completePayment(result);
    };

    session.oncancel = event => {
      setIsApplePayButtonDisabled(false);

      console.info('Apple Pay cancelled:', event);
    };

    session.begin();
  };

  return (
    <Container>
      <ButtonWrap>
        <ApplePayButton
          handleClick={onApplePayClick}
          disabled={!isApplePayEligible(applepayCredentials) || !isReady || isApplePayButtonDisabled}
        />
      </ButtonWrap>
      <BillingTermsAndCondition userCountry={userCountry} />
      <BillingPaymentProcessor userCountry={userCountry} />
      <AfterPayment userCountry={userCountry} descriptors={descriptors} />
    </Container>
  );
};

export default ApplePayForm;
