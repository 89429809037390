import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { textColor, textType } from '../../style';
import Button from '../../atoms/buttons/Button';
import GalleryModal from './GalleryModal';
import { IsMobileViewPort, tabletWidth } from '../../utils/helpers';
import PublicEyeIcon from '../../atoms/icons/PublicEyeIcon';
import PrivateEyeIcon from '../../atoms/icons/PrivateEyeIcon';
import DeleteTrashIcon from '../../atoms/icons/DeleteTrashIcon';

const ModalHeader = styled('h3')({
  ...textType.header,
  marginTop: 20,
  fontSize: 24,
  textAlign: 'left',
  marginBottom: 10,
});

const ModalContent = styled('div')({ marginBottom: 20 });

const ModalMessage = styled('p')(() => ({
  ...textType.text,
  fontSize: 16,
  textAlign: 'left',
  fontWeight: 'normal',
  margin: '10px 0',
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const ButtonSet = styled('div')(
  ({ mobile, hasFooter }) => ({
    button: {
      marginRight: '8px',
      marginTop: mobile && hasFooter ? 20 : 0,
    },
    'button:last-child': {
      marginRight: 0,
    },
  }),
  ({ align }) => ({ textAlign: align }),
  ({ hasFooter, mobile }) =>
    hasFooter && {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: mobile ? 'flex-start' : 'center',
      padding: '10px 20px 20px 20px',
      flexDirection: mobile ? 'column' : 'row',
    }
);

const HeaderIcon = styled('div')({
  marginBottom: 12,
});

const BulkActionButton = styled(Button)({
  border: '2px solid #2B8FD7',
  color: '#2B8FD7',
  fontSize: 18,
  padding: '16px 32px',
  '&:hover': {
    backgroundColor: '#2B8FD7',
    borderColor: '#2B8FD7',
    color: '#FFFFFF',
  },
});

const ButtonIconStyle = {
  cursor: 'pointer',
  height: '32px !important',
  width: 'auto !important',
};

const GalleryBulkActionModal = (props: any): ReactElement => {
  const { bulkAction, bulkActionData, onClose, action, isMobile, onConfirm, ...rest } = props;

  const getCount = () => {
    const privatePhotoCount = bulkActionData.photo.public.length;
    const publicPhotoCount = bulkActionData.photo.private.length;
    const privateVideoCount = bulkActionData.video.public.length;
    const publicVideoCount = bulkActionData.video.private.length;

    return privatePhotoCount + publicPhotoCount + privateVideoCount + publicVideoCount;
  };

  const renderContent = () => {
    if (bulkAction === 'public') {
      return (
        <ModalContent>
          <ModalHeader>
            <HeaderIcon>
              <PublicEyeIcon customStyle={ButtonIconStyle} color="#2B8FD7" />
            </HeaderIcon>
            <span>Confirm Public</span>
          </ModalHeader>
          <ModalMessage>Are you sure you want to make {getCount()} photos/videos Public?</ModalMessage>
        </ModalContent>
      );
    }

    if (bulkAction === 'private') {
      return (
        <ModalContent>
          <ModalHeader>
            <HeaderIcon>
              <PrivateEyeIcon customStyle={ButtonIconStyle} color="#2B8FD7" />
            </HeaderIcon>
            <span>Confirm Private</span>
          </ModalHeader>
          <ModalMessage>Are you sure you want to make {getCount()} photos/videos private?</ModalMessage>
        </ModalContent>
      );
    }

    if (bulkAction === 'delete') {
      return (
        <ModalContent>
          <ModalHeader>
            <HeaderIcon>
              <DeleteTrashIcon customStyle={ButtonIconStyle} />
            </HeaderIcon>
            <span>Confirm Delete</span>
            <ModalMessage>Are you sure you want to delete {getCount()} photos/videos private?</ModalMessage>
          </ModalHeader>
        </ModalContent>
      );
    }
  };

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{ content: { maxWidth: IsMobileViewPort && !tabletWidth ? 'none' : '520px' } }}
      {...rest}
      contentLabel=""
    >
      <ModalDescription hasHeader={true} data-test-id="gallery-bulk-action-description">
        {renderContent()}
        <ButtonSet mobile={isMobile}>
          {action}
          <BulkActionButton data-test-id={`bulk-action-modal-cancel`} onClick={onClose}>
            No
          </BulkActionButton>
          <BulkActionButton data-test-id="bulk-action-modal-confirm-button" onClick={onConfirm}>
            Yes
          </BulkActionButton>
        </ButtonSet>
      </ModalDescription>
    </GalleryModal>
  );
};

GalleryBulkActionModal.defaultProps = {
  onClose: () => null,
  onSelected: () => null,
};

GalleryBulkActionModal.propTypes = {
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  closeOnOverlayClick: PropTypes.bool,
};

export default GalleryBulkActionModal;
