import React, { ReactElement, useMemo } from 'react';
import BillingPackageVariant from '../../atoms/billing/BillingPackageVariant';
import styled from '@emotion/styled';
import PackageListContainer from '../../atoms/billing/PackageListContainer';
import { getBrowserDetails, checkVIP, IsMobileViewPort, isDarkMode } from '../../utils/helpers';
import PromotionalPackageContainer from '../../atoms/billing/PromotionalPackageContainer';
import BillingPackagePromoVariant from '../../atoms/billing/BillingPackagePromoVariant';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';

const { isMobile, isTablet } = getBrowserDetails();

const DivContainer = styled('div')`
  margin: 0 auto;
  width: ${getBrowserDetails().isTablet && getBrowserDetails().isNotDesktopWidth
    ? '70%'
    : getBrowserDetails().isMobile
    ? '100%'
    : 'unset'};
  padding: ${getBrowserDetails().isTablet && getBrowserDetails().isNotDesktopWidth ? '0 15%' : '0'};
  max-width: ${getBrowserDetails().isTablet && getBrowserDetails().isNotDesktopWidth ? '546px' : '100%'};
`;

const PackageList = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageListStacked = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PackageListIntroCreditOffer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageListVIP = styled('div')`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'space-between' : 'center'};
`;

const Line = styled('small')({
  margin: isTablet ? '40px auto' : isMobile ? '40px auto 30px' : '30px auto 45px',
  display: 'block',
  fontSize: 16,
});

const creditStyle = (isVip: boolean) => {
  return {
    background: isVip
      ? isDarkMode()
        ? 'linear-gradient(135deg, #A50000 0%, #3A0000 100%)'
        : 'linear-gradient(135deg, #CF0404 0%, #680000 100%)'
      : 'linear-gradient(135deg, #0F6CE2 0%, #BC2028 100%)',
  };
};

interface BillingPackagesProps {
  packages: any;
  promoPackages: any;
  selected: any;
  selectPackage: any;
  stepBilling: any;
  emailPromo: any;
  showPayment: any;
  selectionOff: any;
  selectedPackage: any;
  stackedVariant: any;
  normalPromoBanner: any;
  promoCountdownExpiresAt: any;
}

const BillingPackagesVariant = ({
  packages,
  promoPackages,
  selected,
  selectPackage,
  stepBilling,
  emailPromo,
  showPayment,
  selectionOff,
  selectedPackage,
  stackedVariant,
  normalPromoBanner,
  promoCountdownExpiresAt,
}: BillingPackagesProps): ReactElement => {
  const hasPromoPackage = promoPackages && promoPackages.length > 0;

  const isIntroCreditOffer = useMemo(() => {
    return promoPackages.some(pkg => pkg.identifier === 'generous_intro_credit_promo');
  }, [promoPackages]);

  const renderPackages = allPackages => {
    const VIP = ['generous_pp_special_2', 'generous_pp_special_1'];
    let isVIP = '';
    let isPromotionalPgk = false;
    const filteredCards = allPackages.reverse().filter(pkg => pkg.packageVersion !== 'new');

    const cards = filteredCards.map((pkg, index) => {
      const recommended = !hasPromoPackage && pkg.recommended;
      isVIP = VIP.find(ele => ele === pkg.identifier);
      isPromotionalPgk = pkg.promotional;

      return isVIP || pkg.promotional ? (
        <BillingPackagePromoVariant
          index={index}
          key={pkg.identifier}
          selectPackage={selectPackage}
          credits={pkg.credits}
          identifier={pkg.identifier}
          currencyCharCode={pkg.currency.data.label_unicode}
          price={pkg.price}
          variant={isIntroCreditOffer ? 'intro_credit_promo' : isVIP ? 'vip' : 'special'}
        />
      ) : (
        <BillingPackageVariant
          index={index}
          key={pkg.identifier}
          selected={selected === pkg.identifier}
          selectPackage={selectPackage}
          credits={parseFloat(pkg.credits)}
          promotional={pkg.promotional}
          identifier={pkg.identifier}
          currencyCharCode={pkg.currency.data.label_unicode}
          price={pkg.price}
          isRecommended={recommended}
          vatDetails={pkg.taxes}
          stepBilling={stepBilling}
          emailPromo={emailPromo}
          showPayment={showPayment}
          selectionOff={selectionOff}
          name={pkg.name}
          appliedPromoDiscount={pkg?.discount ?? {}}
        />
      );
    });

    const renderCards = () => {
      switch (true) {
        case isIntroCreditOffer:
          return <PackageListIntroCreditOffer id="packageList">{cards}</PackageListIntroCreditOffer>;
        case (isVIP || isPromotionalPgk) && stackedVariant && !isIntroCreditOffer:
          return <PackageListStacked id="packageList">{cards}</PackageListStacked>;
        case (isVIP || isPromotionalPgk) && !isIntroCreditOffer:
          return <PackageListVIP id="packageList">{cards}</PackageListVIP>;
        default:
          return (
            <PackageList style={{ minHeight: IsMobileViewPort ? 250 : 'unset' }} id="packageList">
              {cards}
            </PackageList>
          );
      }
    };

    return (
      <React.Fragment>
        {renderCards()}
        {!isVIP && !isPromotionalPgk && (
          <PrimaryButton
            size="regular"
            color="red"
            onClick={e => {
              e.stopPropagation();
              if (stepBilling) {
                showPayment(selectedPackage, emailPromo);
              }
            }}
            data-test-id="continue-package-payment"
            label="Continue"
            customStyle={{
              fontSize: 16,
              marginTop: isMobile ? 30 : 45,
              width: 'inherit',
              height: 44,
              padding: '0px 25px',
              fontWeight: 700,
              minWidth: 212,
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const isPromo = hasPromoPackage && promoPackages.length > 0;

  const setPackageStylePromo = isVip => {
    const { background } = creditStyle(isVip);

    return {
      maxWidth: isTablet ? 490 : 780,
      background,
      padding: isMobile ? '20px 10px 25px 10px' : '20px 15px 30px',
      display: 'grid',
      margin: isTablet || !isMobile ? 'auto' : '0px -10px 0px',
      borderRadius: isTablet || !isMobile ? 10 : 0,
    };
  };

  return (
    <DivContainer data-test-id="package-list">
      {isPromo && (
        <PackageListContainer style={setPackageStylePromo(checkVIP(promoPackages))} maxWidth={'100%'}>
          <PromotionalPackageContainer
            countDown={promoCountdownExpiresAt}
            promoVariant={isIntroCreditOffer ? 'intro_credit_promo' : checkVIP(promoPackages) ? 'vip' : 'special'}
          />
          {renderPackages(promoPackages.reverse())}
        </PackageListContainer>
      )}
      {normalPromoBanner}
      {hasPromoPackage && packages.length > 0 ? (
        <Line
          style={
            normalPromoBanner && isMobile
              ? {
                  margin: '40px auto',
                }
              : {}
          }
        >
          – Other Credit Packages –
        </Line>
      ) : null}
      <PackageListContainer>{packages.length > 0 ? renderPackages(packages) : null}</PackageListContainer>
    </DivContainer>
  );
};

export default BillingPackagesVariant;
