/** @jsx jsx */
import React, { useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { dimensions, backgroundTheme, shadowTheme } from '../../../style';
import HeaderLogo from '../../../atoms/HeaderLogo';
import SliderSidebarContainer from './Sidebar/SliderSidebarContainer';
import Container from '../Container';
import HeaderNavLink from './HeaderNavLink';
import { navbarIcons } from '../../../atoms/icons/materialIcons';
import styled from '@emotion/styled';
import { removeBodyScroll } from '../../../utils/helpers';
import FDTextLogo from '../../../atoms/icons/FDText';
import queryString from 'query-string';
import { useStateCallback } from '../../../utils/customHooks';
import {
  sendDashboardNavbarSearch,
  sendDashboardNavbarInterests,
  sendDashboardNavbarOffers,
  sendDashboardNavbarFavourites,
  sendDashboardNavbarMessages,
} from '../../../actions/dashboardTrackAction';
import { triggerTracking } from '../../../utils/helpers';
import useMixPanel from '../../../hooks/useMixPanel';
import useOnClickCallback from '../../../hooks/useOnClickCallback';
import WYPTextCTALogo from '../../../atoms/icons/WYPTextCTALogo';

const navBarHeight = 65;

const containerStyles = {
  display: 'flex',
  [dimensions.SCREEN_MAX_SM]: { justifyContent: 'space-between' },
};

const SidebarButton = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 8,
  flexBasis: '100%',
  alignItems: 'center',
});

const linkStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  flexBasis: '100%',
  alignItems: 'center',
};

const WYPTextContainer = styled('div')({
  paddingTop: 8,
  marginRight: '-16px',
  width: 145,
  height: 33,
  display: 'flex',
  alignItems: 'center',
});

const determinePosition = positionAbsolute => {
  return positionAbsolute ? 'absolute' : 'fixed';
};

// changed from 1383d2 to FFF, icons to change to #0d5c93
const SiteHeader = styled('div')(({ isMobile, activeSiteNotification, isAbsolutePos }) => ({
  boxShadow: shadowTheme.header,
  backgroundColor: backgroundTheme.block,
  verticalAlign: 'middle',
  position: activeSiteNotification ? 'relative' : determinePosition(isAbsolutePos),
  top: 0,
  left: 0,
  right: 0,
  height: isMobile ? 48 : 'auto',
  zIndex: 997,
}));

const NavBar = styled('div')(({ activeSiteNotification }) => ({
  paddingTop: activeSiteNotification ? '0px' : `${navBarHeight}px`,
}));

const LogoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;

  ${dimensions.SCREEN_MAX_MD} {
    padding: 0 8px 0 16px;
  }
`;

const HeaderNavContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
}));

const renderIcon = (isDarkMode, icon, status) => {
  switch (status) {
    case 'default':
      return isDarkMode ? icon.dark : icon.default;
    case 'active':
      return isDarkMode ? icon.darkActive : icon.active;
    default:
      return;
  }
};

const renderLogo = (isFD, viewBoxProp) => {
  switch (isFD) {
    case true:
      return <FDTextLogo width={145} height={50} viewbox={viewBoxProp} customStyle={{ fill: '#2e79bd7' }} />;
    case false:
      return <WYPTextCTALogo />;
    default:
      return null;
  }
};

const viewboxConfig = (canUpgrade, isFD) => {
  switch (isFD) {
    case true:
      return '-5 -40 450 160';
    case false:
      return '0 -50 830 260';
    default:
      return;
  }
};

const renderLogoPlaceholder = (isMobile, avatar, handleSidebarToggle) => {
  return isMobile ? (
    <SidebarButton data-test-id="header-avatar-button" onClick={() => handleSidebarToggle(true)}>
      <HeaderLogo avatar={avatar} iconOnly />
    </SidebarButton>
  ) : (
    <Link to="/dashboard" style={linkStyles}>
      <HeaderLogo width={297} height={39} />
    </Link>
  );
};

const setMenuCallback = (isShow, location, navigate) => {
  if (!isShow && location && location.search) {
    const queryStringParsed = queryString.parse(location.search);
    const showSettings = queryStringParsed.showSettings === 'true';

    if (showSettings) {
      navigate(location.pathname);
    }
  }
};

const setCorrectPageAction = (e, page) => {
  switch (page) {
    case 'Search':
      sendDashboardNavbarSearch();
      break;
    case 'Favourites':
      sendDashboardNavbarFavourites();
      break;
    case 'Interests':
      sendDashboardNavbarInterests();
      break;
    case 'Offers':
      sendDashboardNavbarOffers();
      break;
    case 'Messages':
      sendDashboardNavbarMessages();
      break;
    default:
      return;
  }
};

interface HeaderProps {
  location: any;
  toggleSidebar: any;
  history: any;
  hideSidebar: any;
  username: any;
  newFavorites: any;
  newOffers: any;
  messages: any;
  avatar: any;
  canUpgrade: any;
  credits: any;
  nagNotification: any;
  pathname: any;
  hashId: any;
  hasGlobalNotif: any;
  isMobile: any;
  isDarkMode: any;
  requiredVerification: any;
  showPaymentSettings: any;
}

const Header = ({
  toggleSidebar,
  history,
  hideSidebar,
  username,
  newFavorites,
  newOffers,
  messages,
  avatar,
  canUpgrade,
  credits,
  nagNotification,
  pathname,
  hashId,
  hasGlobalNotif,
  isMobile,
  isDarkMode,
  requiredVerification,
  showPaymentSettings,
  ...rest
}: HeaderProps): ReactElement => {
  const [menuOpen, setMenuOpen] = useStateCallback(false);
  const [hasNotify, setHasNotify] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const visitSearch = useRef(null);
  const visitFavourites = useRef(null);
  const visitOffers = useRef(null);
  const visitMessages = useRef(null);
  const { trackPageView, trackSearchView } = useMixPanel();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    triggerTracking('Search', visitSearch, setCorrectPageAction);
    triggerTracking('Interests', visitFavourites, setCorrectPageAction);
    triggerTracking('Offers', visitOffers, setCorrectPageAction);
    triggerTracking('Messages', visitMessages, setCorrectPageAction);
  }, []);

  useOnClickCallback(visitSearch, () => trackSearchView(true, { Source: 'Menu' }));

  //   you can also use this function on any of your event to open/close the sidebar
  const handleSidebarToggle = useCallback(
    (isShow, notified = false) => {
      removeBodyScroll();
      toggleSidebar();
      trackPageView(isShow, 'menu');
      setHasNotify(notified);
      setMenuOpen(isShow, newState => setMenuCallback(newState, location, navigate));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMenuOpen, toggleSidebar]
  );

  useEffect(() => {
    if (location && location.search) {
      const queryStringParsed = queryString.parse(location.search);
      const showSettings = queryStringParsed.showSettings === 'true';
      const showInfo = queryStringParsed.accountInfo === 'true';

      if (showSettings) {
        setShowAccountInfo(showInfo);
        handleSidebarToggle(true);
      }
    } else {
      setShowAccountInfo(false);
    }
  }, []);

  const handleSidebarHide = (isShow, notified = false) => {
    removeBodyScroll();
    hideSidebar();
    setMenuOpen(false);
    setHasNotify(notified);
  };

  const noNavPages =
    isMobile &&
    (pathname.indexOf('/payment') > -1 ||
      pathname.indexOf('/packages') > -1 ||
      pathname.indexOf('/billing') > -1 ||
      pathname.split('/')[1] === 'profile');

  const isSearchPage = pathname.indexOf('/search') > -1;
  const isFD = process.env.REACT_APP_NAME === 'fd';
  const hideBuyCredits =
    isMobile &&
    (pathname.indexOf('/payment') > -1 ||
      pathname.indexOf('/packages') > -1 ||
      pathname.indexOf('/billing') > -1 ||
      !canUpgrade);

  // add hiding footer nave when user is in mobile and profile page
  if (noNavPages && pathname.split('/')[1] === 'profile') return null;

  return (
    <NavBar activeSiteNotification={hasGlobalNotif}>
      <SiteHeader
        id="site-header"
        isMobile={isMobile}
        activeSiteNotification={hasGlobalNotif}
        isAbsolutePos={isSearchPage && isMobile}
        style={{ background: backgroundTheme.headerCTA }}
      >
        <Container
          data-test-id="header-inner-links"
          wideHeader
          fullWidth
          css={{ ...containerStyles, ...{ maxHeight: isFD && isMobile ? 41 : 'auto' } }}
        >
          <LogoWrapper marginRight={12}>{renderLogoPlaceholder(isMobile, avatar, handleSidebarToggle)}</LogoWrapper>
          {!isMobile ? (
            <HeaderNavContainer id="header-nav-container">
              <HeaderNavLink
                headerRef={visitSearch}
                to="/search"
                currentPath={pathname}
                activePathPattern={/.*search/}
                iconComponent={renderIcon(isDarkMode, navbarIcons.search, 'default')}
                activeIconComponent={renderIcon(isDarkMode, navbarIcons.search, 'active')}
              />
              <HeaderNavLink
                headerRef={visitFavourites}
                to={'/interests'}
                currentPath={pathname}
                activePathPattern={/.*interests/}
                iconComponent={renderIcon(isDarkMode, navbarIcons.interests, 'default')}
                activeIconComponent={renderIcon(isDarkMode, navbarIcons.interests, 'active')}
                notification={newFavorites}
              />
              <HeaderNavLink
                headerRef={visitOffers}
                to="/offers/new"
                currentPath={pathname}
                activePathPattern={/^.offers/}
                iconComponent={renderIcon(isDarkMode, navbarIcons.offers, 'default')}
                activeIconComponent={renderIcon(isDarkMode, navbarIcons.offers, 'active')}
                notification={newOffers}
              />
              <HeaderNavLink
                headerRef={visitMessages}
                to="/mail"
                currentPath={pathname}
                activePathPattern={/^.mail\/?.*/}
                iconComponent={renderIcon(isDarkMode, navbarIcons.mail, 'default')}
                activeIconComponent={renderIcon(isDarkMode, navbarIcons.mail, 'active')}
                notification={messages}
              />
            </HeaderNavContainer>
          ) : (
            <div
              style={{ display: 'flex', justifyContent: 'center', width: '100%', marginRight: hideBuyCredits ? 30 : 0 }}
            >
              <Link
                to="/dashboard"
                style={{ ...linkStyles, ...{ alignItems: hideBuyCredits ? 'flex-end' : 'center' } }}
              >
                <WYPTextContainer style={{ width: 'unset', height: 'unset' }}>
                  {renderLogo(isFD, viewboxConfig(canUpgrade, isFD))}
                </WYPTextContainer>
              </Link>
            </div>
          )}
          <SliderSidebarContainer
            avatar={avatar}
            credits={credits}
            username={username}
            canUpgrade={canUpgrade}
            hashId={hashId}
            handleSidebarToggle={handleSidebarToggle}
            handleSidebarHide={handleSidebarHide}
            menuOpen={menuOpen}
            hasNotify={hasNotify}
            navBarHeight={navBarHeight}
            isMobile={isMobile}
            showAccountInfo={showAccountInfo}
            history={history}
            requiredVerification={requiredVerification}
            showPaymentSettings={showPaymentSettings}
            {...rest}
          />
        </Container>
      </SiteHeader>
      {nagNotification}
    </NavBar>
  );
};

export default Header;
