import React, { useState, useEffect, ReactElement } from 'react';
import Button from './buttons/Button';
import TextInput from './TextInput';
import styled from '@emotion/styled';
import { color } from '../style/index';

const EditProfileCoverContainer = styled('div')({
  paddingLeft: 12,
  paddingTop: 12,
  paddingRight: 12,
  flex: 3,
  maxWidth: 494,
  flexDirection: 'column',
});

const EditableProfileCover = (props: any): ReactElement => {
  const { errors, deviceName, handleSubmit, handleCancel } = props;

  const [name, setName] = useState('');

  useEffect(() => {
    setName(deviceName);
  }, [deviceName]);

  const checkEmptyError = myName => {
    return myName && (myName.match(/^[0-9a-zA-Z]+$/) || myName.charAt(0).match(/^[a-zA-Z]+$/));
  };

  const saveNewName = () => {
    handleSubmit(name);
  };

  return (
    <EditProfileCoverContainer>
      <TextInput
        id="biometricChangeName"
        inputLabel="Device Name"
        error={errors && errors.username}
        status={errors && errors.username && 'error'}
        value={name}
        onChange={e => setName(e.target.value)}
        customStyleLabel={{ fontWeight: 700 }}
        customInputStyle={{ color: 'rgba(0, 0, 0, 0.8)' }}
      />
      <Button onClick={handleCancel} customStyle={{ paddingRight: 50, paddingLeft: 50, marginRight: 10 }}>
        Cancel
      </Button>
      <Button
        customStyle={{ background: color.BRAND_2, paddingRight: 60, paddingLeft: 60 }}
        buttonType="primary"
        onClick={saveNewName}
        disabled={!checkEmptyError(name)}
      >
        Save
      </Button>
    </EditProfileCoverContainer>
  );
};

export default EditableProfileCover;
