import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

const Icon = styled('svg')(
  {
    fill: 'none',
    height: '14px',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

interface DeleteTrashIconProps {
  color: string;
  customStyle: any;
}

const DeleteTrashIcon = ({ color = '#BC2028', customStyle = {} }: DeleteTrashIconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 28 31" customStyle={customStyle}>
      <path
        d="M5.12499 30.0054C4.37499 30.0054 3.72933 29.7347 3.18799 29.1934C2.64599 28.6514 2.37499 27.9914 2.37499 27.2134V4.17237H0.666992V1.38037H8.66699V0.00537109H19.333V1.38037H27.333V4.17237H25.625V27.2134C25.625 27.9634 25.354 28.6164 24.812 29.1724C24.2707 29.7277 23.625 30.0054 22.875 30.0054H5.12499ZM22.875 4.17237H5.12499V27.2134H22.875V4.17237ZM9.20799 23.7134H11.958V7.63037H9.20799V23.7134ZM16.042 23.7134H18.792V7.63037H16.042V23.7134Z"
        fill={color}
      />
    </Icon>
  );
};

export default DeleteTrashIcon;
