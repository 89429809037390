import React, { useState, FC } from 'react';
import queryString from 'query-string';

import Button from '../../atoms/buttons/Button';
import Container from '../blocks/Container';
import Headline from '../common/Headline';
import Panel from '../../atoms/panels/Panel';
import HeaderOut from '../../atoms/HeaderOut';
import Checkbox from '../../atoms/Checkbox';
import { Link } from 'react-router-dom';
import Text from '../common/Text';
import NeedAccountMessage from '../../atoms/NeedAccountMessage';

const UnlinkAccount: FC = ({ hashId, location, submit, hasBeenUnlinked, username, email }) => {
  const [confirmCheckBox, setConfirmCheckBox] = useState(false);

  const handleChange = () => {
    setConfirmCheckBox(!confirmCheckBox);
  };

  const handleSubmit = () => {
    const queryStringParsed = queryString.parse(location.search);

    submit({
      accountId: hashId,
      email: email,
      data: queryStringParsed.data,
    });
  };

  return (
    <Container width="thin">
      <HeaderOut />
      {hasBeenUnlinked === false ? (
        <Panel padding="large">
          <Headline spaceAfter>Please confirm this is not your account</Headline>
          <h4>Username: {username}</h4>
          <h4>Email: {email}</h4>
          <Checkbox
            value={confirmCheckBox}
            id="confirmCheckBox"
            label="I confirm that I did not create this account and want to remove my email from it"
            onChange={handleChange}
          />
          <div style={{ paddingTop: '30px' }}>
            <Button buttonType="primary" fit onClick={handleSubmit} disabled={confirmCheckBox === false}>
              Remove email
            </Button>
          </div>
        </Panel>
      ) : (
        <Panel padding="large">
          <Headline spaceAfter>We&#39;ve removed you from our database.</Headline>
          <Text>We apologize for the inconvenience.</Text>
          <div style={{ paddingTop: '100px' }}>
            <Link to="/login">
              <Button buttonType="primary" fit>
                Got it!
              </Button>
            </Link>
          </div>
        </Panel>
      )}
      <NeedAccountMessage />
    </Container>
  );
};

export default UnlinkAccount;
