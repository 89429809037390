import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { statusColor } from '../style/index';
import StatusIcon from '../atoms/icons/StatusIcon';
import InputLabel from './InputLabel';

const Container = styled('div')(
  {
    paddingBottom: '24px',
  },
  ({ customStyle }) => customStyle && { ...customStyle }
);

const CheckboxList = styled('div')({});

const StatusMessageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  marginTop: 0,
});

const StatusMessage = styled('div')(
  ({ status, noStatusIcon }) =>
    status && {
      color: statusColor[status],
      paddingLeft: !noStatusIcon ? 4 : 0,
    }
);

const renderStatusMessage = (status, statusMessage, noStatusIcon) => {
  return (
    <StatusMessageContainer>
      {!noStatusIcon && <StatusIcon status={status} />}
      <StatusMessage status={status} noStatusIcon={noStatusIcon}>
        {statusMessage}
      </StatusMessage>
    </StatusMessageContainer>
  );
};

interface CheckboxGroupProps {
  inputLabel: any;
  labelDescription: any;
  status: any;
  statusMessage: any;
  tooltip: any;
  customStyle: any;
  customLabelStyle: any;
  noStatusIcon: any;
  children: ReactNode;
  isRequired: boolean;
  customCheckboxListStyle?: React.CSSProperties;
  customContainerStyle?: React.CSSProperties;
}

const CheckboxGroup = ({
  inputLabel,
  labelDescription,
  status,
  statusMessage,
  tooltip,
  customStyle,
  customLabelStyle,
  noStatusIcon,
  children,
  isRequired = false,
  customCheckboxListStyle,
  customContainerStyle,
}: CheckboxGroupProps): ReactElement => {
  return (
    <Container customStyle={customStyle}>
      {inputLabel && (
        <InputLabel
          customStyle={customLabelStyle}
          customContainerStyle={customContainerStyle}
          label={inputLabel}
          labelDescription={labelDescription}
          tooltip={tooltip ? tooltip : null}
          isRequired={isRequired}
        />
      )}

      <CheckboxList style={customCheckboxListStyle} status={status}>
        {children}
      </CheckboxList>
      {statusMessage && status !== 'default' ? renderStatusMessage(status, statusMessage, noStatusIcon) : null}
    </Container>
  );
};

CheckboxGroup.defaultProps = {
  status: 'default',
};

CheckboxGroup.propTypes = {
  inputLabel: PropTypes.string,
  labelDescription: PropTypes.string,
  /** Tooltip on right side of label */
  tooltip: PropTypes.shape({
    icon: PropTypes.any,
    text: PropTypes.string,
    overlayStyle: PropTypes.object,
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  }),
  status: PropTypes.oneOf(['default', 'success', 'caution', 'error']),
  statusMessage: PropTypes.string,
};

export default CheckboxGroup;
