import React, { useState, ReactElement } from 'react';
import Headline from '../../common/Headline';
import { extractLocationData, geocodeByPlaceId } from '../../../common';
import GettingStarted from '../../../modules/migration/GettingStarted';
import NotificationBanner from '../../blocks/NotificationBanner';
import { useStateCallback } from '../../../utils/customHooks';
import HeaderOut from '../../../atoms/HeaderOut';
import styled from '@emotion/styled';
import _isEmpty from 'lodash/isEmpty';
import { BODY_TYPE_OPTIONS } from '../../join/constants';

const OnBoardingHeader = styled(Headline)({
  color: '#333333',
  textAlign: 'center',
  marginBottom: 20,
});

const mapDefaultBodyTypes = profile => {
  const defaultBodyTypes = [];
  if (
    profile &&
    profile !== undefined &&
    profile.body_types &&
    profile.body_types !== undefined &&
    profile.body_types.data !== undefined &&
    profile.body_types.data.length > 0
  ) {
    profile.body_types.data.map(item =>
      defaultBodyTypes.push({ value: parseInt(item.value, 10), label: item.description })
    );
  }
  return defaultBodyTypes;
};

const clearAllErrors = (targetId, clearIndividualError) => {
  if (targetId === 'looking_male' || targetId === 'looking_female') clearIndividualError('looking_male');
  else clearIndividualError('dating_just_dating');
};

interface ProfileProps {
  profile: any;
  account: any;
  update: any;
  clearIndividualError: any;
  errors: any;
  mapFields: any;
  notification: any;
  clearNotificationMessage: any;
  loadPageUrl: any;
  isAuthenticated: any;
  location: any;
  isProcessing: any;
}

const Profile = ({
  profile,
  account,
  update,
  clearIndividualError,
  errors,
  mapFields,
  notification,
  clearNotificationMessage,
  loadPageUrl,
  isAuthenticated,
  location,
  isProcessing,
  ...rest
}: ProfileProps): ReactElement => {
  const [defaultBodyTypes] = useState(mapDefaultBodyTypes(profile));
  const [currentAddress, setCurrentAddress] = useState('');
  const [profileData, setProfileData] = useStateCallback(
    Object.assign({}, profile, {
      address: '',
      username: account.username,
      body_types: defaultBodyTypes,
    })
  );

  const submit = (newProfileState = {}) => {
    const profileState = Object.assign({}, newProfileState);

    // reset location
    document.getElementById('autocompleteLocation').value = '';
    // remove location data
    resetLocation();

    if (profileData.body_types.length > 0 && !('value' in profileData.body_types[0])) {
      const myDefaultBodyTypes = [];
      profileData.body_types.map(item => {
        myDefaultBodyTypes.push(BODY_TYPE_OPTIONS.find(type => type.value === item));
      });

      setProfileData({
        ...profileState,
        body_types: myDefaultBodyTypes,
      });
    }
    // submit form update
    update(
      _isEmpty(profileState) ? profileData : profileState,
      Boolean(profile.avatar.data.id == null),
      true,
      profile.hash_id
    );
  };

  const handleSubmit = e => {
    // prevent default form submission
    e.preventDefault();

    const profileState = Object.assign({}, profileData);
    // if new location provided, copy over data.
    if (profileData.NEWLOCATION !== undefined) {
      // extract new location, and submit

      const newLocation = extractLocationData(profileData.NEWLOCATION);
      const newProfileState = {
        ...profileState,
        ...newLocation,
      };
      // submit updated form with new location
      setProfileData(newProfileState, () => {
        submit(newProfileState);
      });
    } else {
      // otherwise, just submit form data (without location).
      submit();
    }
  };

  const onChangeHandler = e => {
    const profileState = Object.assign({}, profileData);
    clearIndividualError(e.target.id);
    setProfileData({
      ...profileState,
      [e.target.id]:
        isNaN(e.target.value) || e.target.type !== 'select-one' ? e.target.value : parseInt(e.target.value),
    });
  };

  const bodyTypeOnChangeHandler = value => {
    const profileState = Object.assign({}, profileData);
    setProfileData({
      ...profileState,
      body_types: value,
    });
  };

  const onCheckboxChangeHandler = targetId => {
    // update checkbox
    clearAllErrors(targetId, clearIndividualError);
    const newState = Object.assign({}, profileData);
    newState[targetId] = !newState[targetId];
    setProfileData(newState);
  };

  const locationSelectHandler = (address, placeId) => {
    setCurrentAddress(address);
    const profileState = Object.assign({}, profileData);
    geocodeByPlaceId(placeId)
      .then(results => {
        const loc = results[0];
        let newCountry = loc.formatted_address
          .split(',')
          .pop()
          .trimStart();
        loc.address_components.map(address => {
          if (address.types.includes('country')) {
            newCountry = address.long_name;
          }
        });
        if (loc.geometry) {
          setProfileData({
            ...profileState,
            NEWLOCATION: loc,
            address,
            country: newCountry,
          });
        }
      })
      .catch(error => {
        console.error('Issue with Geocoding: ', error);
      });
  };

  const resetLocation = () => {
    const profileState = Object.assign({}, profileData);
    setProfileData({
      ...profileState,
      NEWLOCATION: undefined,
    });
  };

  const inputOnChange = value => {
    setCurrentAddress(value);
    const profileState = Object.assign({}, profileData);
    setProfileData(
      value === ''
        ? {
            ...profileState,
            address: value,
            country: '',
          }
        : {
            ...profileState,
            address: value,
          }
    );
  };

  return mapFields === undefined ? null : (
    <div>
      <NotificationBanner
        notification={notification}
        clearNotificationMessage={clearNotificationMessage}
        loadPageUrl={loadPageUrl}
        isAuthenticated={isAuthenticated}
        location={location}
      />
      <HeaderOut onBoarding />
      <OnBoardingHeader spaceAfter>You are moments away from the Online Dating Shortcut®!</OnBoardingHeader>
      <GettingStarted
        newLocation={profileData.NEWLOCATION}
        isGenerous={profileData.account_type === 'Generous'}
        errors={errors}
        onSubmit={handleSubmit}
        lookingMale={profileData.looking_male}
        lookingFemale={profileData.looking_female}
        city={profileData.city}
        region={profileData.region}
        country={profileData.country}
        address={currentAddress}
        username={profileData.username}
        bodyTypes={profileData.body_types}
        height={profileData.height}
        datingJustDating={profileData.dating_just_dating}
        datingFriendship={profileData.dating_friendship}
        datingMarriage={profileData.dating_marriage}
        datingMarried={profileData.dating_married}
        datingAdult={profileData.dating_adult}
        datingMentorship={profileData.dating_mentorship}
        education={profileData.education}
        totalChildren={profileData.children}
        mapFields={mapFields}
        smoking={profileData.smoking}
        drinking={profileData.drinking}
        occupation={profileData.occupation}
        income={profileData.income}
        description={profileData.description}
        seeking={profileData.seeking === null ? ' ' : profileData.seeking}
        onChangeAddress={inputOnChange}
        onSelectLocation={locationSelectHandler}
        onChangeBodyType={bodyTypeOnChangeHandler}
        onCheckboxChangeHandler={onCheckboxChangeHandler}
        onChangeHandler={onChangeHandler}
        isProcessing={isProcessing}
        {...rest}
      />
    </div>
  );
};

export default Profile;
