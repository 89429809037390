import React, { useMemo } from 'react';
import ToggleButton from '../../atoms/togglebutton/ToggleButton';

interface IButtonOptionProps {
  title: string;
  id: number;
  customStyle?: React.CSSProperties;
  radioBehaviour?: boolean;
  labelStyle?: React.CSSProperties;
  isSelected: boolean;
  onChangeHandler: (event: React.MouseEventHandler<HTMLDivElement>, isChecked: boolean) => void;
  value?: string | number;
  animate?: boolean;
  additionalStyle?: React.CSSProperties;
  elementRef?: any;
  index: number;
  name?: string;
}

const JoinOptionButton: React.FC<IButtonOptionProps> = (props: IButtonOptionProps) => {
  const {
    title,
    id,
    onChangeHandler,
    labelStyle = {},
    isSelected = false,
    value,
    animate = true,
    elementRef,
    index,
    additionalStyle = {},
    name,
  } = props;

  const toggleBtnLabelStyle1 = useMemo(() => {
    return {
      padding: '2px 6px 0px',
      lineHeight: '24px',
      fontSize: 13,
      fontWeight: 600,
      color: isSelected ? '#FFFFFF' : '#2A8FD7',
      ...labelStyle,
    };
  }, [isSelected, labelStyle]);

  const toggleBtnCustomStyle = useMemo(() => {
    const opacity = animate ? (value === null ? 1 : isSelected ? 1 : 0) : 1;
    const backgroundColor = isSelected ? '#2A8FD7' : 'transparent';
    const zIndex = isSelected ? 1 : 0;

    return {
      cursor: 'pointer',
      paddingTop: 3,
      paddingBottom: 3,
      order: index + 1,
      height: 28,
      textAlign: 'center',
      borderRadius: 24,
      borderColor: '#2A8FD7',
      margin: '6px 15px 5px 0',
      backgroundColor,
      zIndex,
      opacity,
      overflow: 'hidden',
      ...additionalStyle,
    };
  }, [isSelected, additionalStyle, value, animate, index]);

  return (
    <ToggleButton
      elementRef={elementRef}
      labelStyle={toggleBtnLabelStyle1}
      customStyle={toggleBtnCustomStyle}
      dataTestId={`${name}${id}`}
      key={id}
      title={title}
      id={id}
      onChangeHandler={onChangeHandler}
      hideIcons={true}
    />
  );
};

export default JoinOptionButton;
