import styled from '@emotion/styled';
import { borders, spacing, textType, textColor } from '../../style';

const Header = styled('div')(
  {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0',
  },
  ({ panelHeader }) =>
    panelHeader && {
      padding: 16,
      marginBottom: 0,
    },
  ({ bottomRuled }) =>
    bottomRuled && {
      borderBottom: borders.DEFAULT,
      marginBottom: 18,
    }
);

const Text = styled('div')({
  ...textColor.NORMAL,
  lineHeight: '20px',
  wordBreak: 'break-word',
  ...spacing.MARGIN_BOTTOM_SM,
  ...textType.text,
});

const Title = styled('h2')({ ...textType.header }, ({ marginBtm }) => ({
  marginBottom: marginBtm ? 12 : 0,
}));

const SectionHeader = styled('h1')({
  ...textType.header,
});

export { Header, Text, Title, SectionHeader };
