import React from 'react';

interface IOptionalProps {
  show: boolean;
  children: React.ReactNode;
}
const Optional: React.FC<IOptionalProps> = (props: IOptionalProps) => {
  const { show, children } = props;
  if (!show) return null;
  return <>{children}</>;
};

export default Optional;
