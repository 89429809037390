import React, { ReactElement } from 'react';

const IconCross = ({
  customStyle,
  fill,
  ...rest
}: {
  fill?: string;
  color?: string;
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      viewBox="0 0 13 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...customStyle }}
      {...rest}
    >
      <g mask="url(#mask0_2906_1830)">
        <path
          d="M1.08693 13.5L0 12.4131L5.38925 7L0 1.58693L1.08693 0.5L6.5 5.88925L11.9131 0.5L13 1.58693L7.61075 7L13 12.4131L11.9131 13.5L6.5 8.11075L1.08693 13.5Z"
        />
      </g>
    </svg>
  );
};

export default IconCross;
