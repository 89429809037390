import React, { ReactElement } from 'react';

const IconMakePublic = ({
  iconColor: iconColor,
  fillColor,
  customStyle,
  ...rest
}: {
  iconColor: string;
  fillColor: string;
  customStyle?: React.CSSProperties;
}): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 24, height: 24, ...customStyle }}
      {...rest}
    >
      <circle opacity="0.65" cx="12" cy="12" r="12" fill={fillColor} />
      <path
        d="M12 14.9556C12.7955 14.9556 13.4717 14.6683 14.0287 14.0939C14.5853 13.519 14.8636 12.821 14.8636 12C14.8636 11.179 14.5853 10.4811 14.0287 9.90618C13.4717 9.3317 12.7955 9.04447 12 9.04447C11.2045 9.04447 10.5283 9.3317 9.97127 9.90618C9.41467 10.4811 9.13636 11.179 9.13636 12C9.13636 12.821 9.41467 13.519 9.97127 14.0939C10.5283 14.6683 11.2045 14.9556 12 14.9556ZM12 13.7734C11.5227 13.7734 11.1172 13.6008 10.7833 13.2558C10.449 12.9112 10.2818 12.4926 10.2818 12C10.2818 11.5074 10.449 11.0886 10.7833 10.7436C11.1172 10.399 11.5227 10.2267 12 10.2267C12.4773 10.2267 12.8831 10.399 13.2174 10.7436C13.5512 11.0886 13.7182 11.5074 13.7182 12C13.7182 12.4926 13.5512 12.9112 13.2174 13.2558C12.8831 13.6008 12.4773 13.7734 12 13.7734ZM12 16.9259C10.4515 16.9259 9.04091 16.4798 7.76818 15.5874C6.49545 14.6955 5.57273 13.4997 5 12C5.57273 10.5004 6.49545 9.30434 7.76818 8.41198C9.04091 7.52006 10.4515 7.0741 12 7.0741C13.5485 7.0741 14.9591 7.52006 16.2318 8.41198C17.5045 9.30434 18.4273 10.5004 19 12C18.4273 13.4997 17.5045 14.6955 16.2318 15.5874C14.9591 16.4798 13.5485 16.9259 12 16.9259Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default IconMakePublic;
