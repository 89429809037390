import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../../atoms/buttons/Button';
import { dimensions, borders, backgroundTheme } from '../../style';
import { numberWithCommas } from '../../common';
import RadioButton from '../RadioButton';

const PackageContainer = styled('div')`
  padding-right: 8px;
  padding-bottom: 30px;
  padding-left: 8px;
  padding-top: ${props => (props.isSpecialPackage ? `38px` : `8px`)};
  min-height: ${props => (props.isSpecialPackage ? 'auto' : `205px`)};
`;

const Card = styled('div')(
  {
    maxWidth: '250px',
    cursor: 'pointer',
    borderRadius: '5px',
    backgroundColor: backgroundTheme.block,
    border: borders.DEFAULT,
    overflow: 'hidden',
    textAlign: 'center',
    flex: 1,
    padding: '1px 0',
    position: 'relative',
    margin: '0 5px',
    '&:nth-child(1)': {
      marginLeft: '0px',
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  },

  ({ selected }) =>
    selected &&
    `
        margin: 0 4px;
        border: 2px solid #1478ca;
        cursor: default;
        padding: 0 0;
        background-color: ${backgroundTheme.block};
      `,
  ({ large }) =>
    large &&
    `
        max-width: 280px;
        ${dimensions.SCREEN_MAX_XS} {
          margin: 0;
        }
      `,

  ({ withNewPackage }) => ({ minWidth: withNewPackage ? '22.5%' : '31.5%' })
);

const CreditsPerCurrency = styled('div')`
  font-size: 12px;
  color: ${backgroundTheme.greyText};
  padding-top: 16px;
`;

const CreditContainer = styled('div')`
  padding-top: 18px;
`;

const Credits = styled('div')`
  color: ${backgroundTheme.creditText};
  font-size: ${props => (props.fontSize ? props.fontSize : 22)}px;

  ${dimensions.SCREEN_MIN_XXS} {
    font-size: 25px;
  }
`;

const PackageTag = styled('div')`
  font-size: 18px;
  padding: 10px 0;
  color: #ffffff;
  background-color: #1378ca;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  ${({ selected }) => selected && `padding-top: 9px`};

  ${dimensions.SCREEN_MAX_XS} {
    font-size: 13px;
  }
`;

const Currency = styled('div')`
  color: ${backgroundTheme.greyText};
  font-size: 18px;
  padding-top: 18px;
`;

const ButtonContainer = styled('div')`
  padding-top: 18px;

  ${({ checkBoxVariant }) =>
    checkBoxVariant &&
    `
    display: flex;
    justify-content: center;
  `};
`;

const StackedSaveContainer = styled('div')`
  color: ${backgroundTheme.greyText};
  font-size: ${props => (props.fontSize ? props.fontSize : 22)}px;
  margin-top: 10px;
`;

const ShowBuyButton = styled(Button)({
  padding: '0 25px',
});

const renderCreditsPerCurrency = (credits, currencyCode = '$', hasTopTag, price) => {
  let creditsText;
  const creditsPP = Math.round((credits / parseInt(price, 10)) * 2) / 2;

  switch (parseInt(credits, 10)) {
    case 220:
      creditsText = `2.2 credits per ${currencyCode}1`;
      break;
    case 500:
      creditsText = `3.3 credits per ${currencyCode}1`;
      break;
    case 100:
    case 250:
    case 450:
    case 1000:
    case 1500:
      creditsText = `${creditsPP} credits per ${currencyCode}1`;
      break;
    case 300:
      // FIXME: added this as temp as 3.5 credits per $1 is simply untrue
      creditsText = `3 credits per ${currencyCode}1`;
      break;
    default:
      creditsText = '';
  }
  return creditsText ? <CreditsPerCurrency hasTopTag={hasTopTag}>{creditsText}</CreditsPerCurrency> : null;
};

const renderPackageTag = (recommended, promotional, selected) => {
  return (
    <div>
      <PackageTag selected={selected}>{recommended && !promotional ? `Recommended` : `Special offer!`}</PackageTag>
    </div>
  );
};

interface BillingPackageOldProps {
  selected: any;
  selectPackage: any;
  credits: any;
  promotional: any;
  hasPromoPackage: any;
  identifier: any;
  currencyCharCode: any;
  price: any;
  checkBoxVariant: any;
  savings: any;
  isRecommended: any;
  withNewPackage: any;
  stepBilling: any;
  emailPromo: any;
  showPayment: any;
}

const BillingPackageOld = ({
  selected,
  selectPackage,
  credits,
  promotional,
  hasPromoPackage,
  identifier,
  currencyCharCode,
  price,
  checkBoxVariant,
  savings,
  isRecommended,
  withNewPackage,
  stepBilling,
  emailPromo,
  showPayment,
}: BillingPackageOldProps): any => {
  // Recommended tag will only render if the package is recommended and there are no promotional packages in the list of packages
  // Special Offer! tag will only render if the package is promotional and selected
  const isRecommendedPackage = isRecommended && !hasPromoPackage;
  const isSpecialPackage = isRecommendedPackage || promotional;

  return (
    <Card
      selected={selected}
      checkBoxVariant={checkBoxVariant}
      large={isSpecialPackage}
      onClick={() => {
        if (!selected) selectPackage(identifier);
        if (stepBilling && withNewPackage) showPayment(identifier, emailPromo);
      }}
      withNewPackage={withNewPackage}
    >
      {isSpecialPackage ? renderPackageTag(isRecommendedPackage, promotional, selected) : null}
      <PackageContainer isSpecialPackage={isSpecialPackage}>
        {checkBoxVariant ? (
          <React.Fragment>
            <CreditContainer>
              <Credits fontSize={36}>{numberWithCommas(credits)}</Credits>
              <Credits fontSize={22}>credits</Credits>
            </CreditContainer>
            <Currency>
              {String.fromCharCode(currencyCharCode)} {price}
            </Currency>
            {renderCreditsPerCurrency(credits, String.fromCharCode(currencyCharCode), true, price)}
            {savings > 0 && (
              <StackedSaveContainer fontSize={16}>
                Save <b>{savings}%</b>
              </StackedSaveContainer>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {renderCreditsPerCurrency(credits, String.fromCharCode(currencyCharCode), true, price)}
            <CreditContainer>
              <Credits fontSize={36}>{numberWithCommas(credits)}</Credits>
              <Credits fontSize={22}>credits</Credits>
            </CreditContainer>
            <Currency>
              {String.fromCharCode(currencyCharCode)} {price}
            </Currency>
          </React.Fragment>
        )}
        <ButtonContainer checkBoxVariant={checkBoxVariant}>
          {stepBilling && withNewPackage ? (
            <ShowBuyButton
              data-test-id="package-buy-now"
              buttonType="error"
              onClick={e => {
                e.stopPropagation();
                selectPackage(identifier);
                showPayment(identifier, emailPromo);
              }}
              customStyle={{ backgroundColor: '#BC2028' }}
            >
              Buy
            </ShowBuyButton>
          ) : checkBoxVariant ? (
            <RadioButton
              label={''}
              checked={selected}
              onRadioChange={() => null}
              name={'Package Selected'}
              id={price}
            />
          ) : (
            <Button
              disabled={selected}
              buttonType={selected ? 'primary' : 'normal'}
              onClick={e => {
                e.stopPropagation();
                selectPackage(identifier);
              }}
              customStyle={(selected && { '&:disabled': { backgroundColor: '#2490de' } }) || {}}
            >
              {selected ? `Selected` : `Select`}
            </Button>
          )}
        </ButtonContainer>
      </PackageContainer>
    </Card>
  );
};

BillingPackageOld.propTypes = {
  /** Signifies if package is selected */
  selected: PropTypes.bool,
  /** Function to handle selecting a package */
  selectPackage: PropTypes.func.isRequired,
  /** Number of credits */
  credits: PropTypes.number.isRequired,
  /** Signifies if tag on top of package card should render "Special Offer!" */
  promotional: PropTypes.bool,
  /** Unique identifier for package (i.e. generous_male_1) - this is used for use2pay */
  identifier: PropTypes.string.isRequired,
  /** Currency char code */
  currencyCharCode: PropTypes.string.isRequired,
  /** Price of package */
  price: PropTypes.string.isRequired,
  /** Signifies this package is part of a package list that includes a promo package */
  hasPromoPackage: PropTypes.bool,
};

export default BillingPackageOld;
