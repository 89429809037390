import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from '../../blocks/Container';
import Panel from '../../../atoms/panels/Panel';
import Headline from '../../common/Headline';
// import Header from '../../blocks/Header';
import Button from '../../../atoms/buttons/Button';
import styled from '@emotion/styled';
import { textColor, type, backgroundTheme, paymentTheme, dimensions } from '../../../style';
import DataLayer from '../../../utils/dataLayer';
import BillingSuccessHeader from '../../../components/blocks/headerNav/BillingSuccessHeader';
import { removeLocalItem } from '../../../common';
import {
  addCommas,
  customToFixed,
  ternaryFunc,
  getBrowserDetails,
  getCookie,
  removeCookie,
  isDarkMode,
} from '../../../utils/helpers';
import useMixPanel from '../../../hooks/useMixPanel';
import { PROMO_COOKIE } from '../../../config/constants';
import { useApp } from '../../../contexts/AppContext';
import GooglePayIcon from '../../../atoms/billing/GooglePay/GooglePayIcon';
import { SofortIcon } from '../../../atoms/icons/Billing';
import { MIXPANEL_CREDITS_PURCHASED } from '../../../constants/mixpanel';
import ApplePayIcon from '../../../atoms/billing/GooglePay/ApplePayIcon';
import { APAY_PAYMENT_TYPE } from '../../../config/apay';
import { GPAY_PAYMENT_TYPE } from '../../../config/gpay';
import { removeIPQSCookies } from '../../../utils/ipQualityScore';

const StyledHeadline = styled(Headline)({
  ...textColor.NORMAL,
});

const CreditCardNote = styled('div')({
  marginTop: 20,
  ...type.XS,
  textAlign: 'center',
});

const SubHeader = styled('div')({
  with: '100%',
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '21px',
});

const Row = styled('div')({
  color: paymentTheme.SUCCESS.item.color,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 0',
  margin: '0 15px',
  alignItems: 'center',
  borderBottom: `1px dashed ${paymentTheme.DIVIDER.borderColor}`,
  '&:last-of-type': {
    borderBottom: 'none',
    paddingBottom: 20,
  },
  [dimensions.SCREEN_MAX_MD]: {
    padding: '12px 0',
    '&:last-of-type': {
      paddingBottom: 15,
    },
  },
});

const ReferenceRow = styled('div')({
  color: paymentTheme.SUCCESS.item.color,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 0',
  margin: '0 15px',
  alignItems: 'center',
  '&:last-of-type': {
    borderBottom: 'none',
    paddingBottom: 20,
  },
  [dimensions.SCREEN_MAX_MD]: {
    padding: '12px 0',
    '&:last-of-type': {
      paddingBottom: 15,
    },
  },
});

const Total = styled('div')({
  color: paymentTheme.SUCCESS.total.color,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
  backgroundColor: backgroundTheme.paymentHighlight,
  borderRadius: '0px 0px 5px 5px',
  [dimensions.SCREEN_MAX_MD]: { paddingRight: 15, paddingLeft: 15 },
});

const LeftCol = styled('div')({
  flexGrow: 1,
  textAlign: 'left',
});

const LeftColPackage = styled('div')({
  flexGrow: 1,
  textAlign: 'left',
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '24px',
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 18,
    lineHeight: '18px',
  },
});

const RightCol = styled('div')({
  flexGrow: 10,
  textAlign: 'right',
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '18px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});

const RightColPackage = styled(RightCol)({});
const LeftColTotal = styled(LeftCol)({});
const RightColTotal = styled(RightCol)({
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '24px',
});

const SubText = styled('div')({
  ...textColor.NORMAL,
  ...type.NORMAL,
  ...type.XS,
});

const BillingSummary = styled('div')({
  background: backgroundTheme.orderSummary,
  borderRadius: '5px 5px 0px 0px',
  borderTop: `1px solid ${paymentTheme.SUCCESS.borderColor}`,
  borderRight: `1px solid ${paymentTheme.SUCCESS.borderColor}`,
  borderLeft: `1px solid ${paymentTheme.SUCCESS.borderColor}`,
  marginTop: 20,
});

const CreditText = styled('b')({
  color: paymentTheme.SUMMARY.color,
  fontWeight: 900,
});

const renderTransactionRow = transactionID => {
  return (
    <ReferenceRow>
      <LeftCol style={{ fontSize: getBrowserDetails().isMobile ? 14 : 16 }}>Transaction Reference</LeftCol>
      <RightCol style={{ fontSize: getBrowserDetails().isMobile ? 14 : 16 }}>{`${transactionID}`}</RightCol>
    </ReferenceRow>
  );
};

const renderSubtotal = lastPayment => {
  return parseFloat(lastPayment && lastPayment.original_price ? lastPayment.original_price : 0);
};

const renderDiscountAmount = (thePackage, promoCode) => {
  const amount = thePackage.taxes ? thePackage.taxes.taxable_amount : thePackage.price;
  let discountAmount = promoCode.discount_amount ? promoCode.discount_amount : 0;

  if (promoCode.discount_rate) {
    discountAmount = amount * promoCode.discount_rate;
  }

  if (thePackage.taxes && thePackage.taxes.discount_amount) {
    discountAmount = thePackage.taxes.discount_amount ?? 0;
  }

  return customToFixed(discountAmount).toFixed(2);
};

interface BillingSuccessProps {
  payments: any;
  profile: any;
  descriptors: any;
  account: any;
}

const BillingSuccess = ({ payments, profile, descriptors, account }: BillingSuccessProps): any => {
  const { trackCreditsPurchased } = useMixPanel();
  const { setSelectedSpecialOrVipPromo } = useApp();
  const [lastPayment] = useState(payments[0]);

  useEffect(() => {
    return () => {
      removeLocalItem('wyp_billing_packages');
      removeLocalItem(MIXPANEL_CREDITS_PURCHASED);
      removeIPQSCookies();
    };
  }, []);

  useEffect(() => {
    const lastPackage = lastPayment.package.data;
    new DataLayer().push({
      submitted_at: profile.submitted_date || null,
      completed_at: profile.completed_date || null,
      monetized_at: profile.monetized_date || null,
      transactionId: lastPayment.id,
      gender: profile.sex,
      age: profile.age,
      availableCredits: account.credits,
      publicPhotos: account.approved_public_photos_count || 0,
      outstandingOffers: account.offer_counts.new,
      type: profile.account_type,
      looking_male: profile.looking_male,
      looking_female: profile.looking_female,
      transactionAffiliation: 'Credit Purchase',
      transactionTotal: lastPayment.charge_amt,
      currencyCode: lastPackage.currency.data.code,
      transactionProducts: [
        {
          sku: `${lastPackage.identifier}`,
          name: `${lastPackage.credits} Credits`,
          category: 'Credit Purchase',
          price: `${lastPackage.price}`,
          currency: lastPackage.currency.data.code,
          quantity: '1',
        },
      ],
      event: 'transactionComplete',
    });
  }, [payments, account, lastPayment, profile]);

  useEffect(() => {
    if (lastPayment.id) {
      let additionalPayload = {};
      if (lastPayment?.promo_code) {
        additionalPayload = {
          promo_code: lastPayment.promo_code.code,
          discount: renderDiscountAmount(lastPayment.package.data, lastPayment.promo_code),
        };

        // Remove promo cookes
        const promoCodeCookies = getCookie(PROMO_COOKIE);

        if (promoCodeCookies && promoCodeCookies === lastPayment?.promo_code.code) {
          removeCookie(PROMO_COOKIE);
        }
      }

      trackCreditsPurchased(additionalPayload);

      setSelectedSpecialOrVipPromo(null);
      removeLocalItem('wyp_billing_packages');
      removeIPQSCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPayment.id]);

  const thePackage = lastPayment.package.data;
  const promoCode = lastPayment.promo_code;
  const transactionID = lastPayment.last_transaction_reference;
  const { taxes } = thePackage;

  const renderCurrencyCode = (): string => {
    if (thePackage.currency.data?.code?.toUpperCase()) {
      return ` ${thePackage.currency.data?.code?.toUpperCase()}`;
    }

    return '';
  };

  const renderPaymentMethod = (lastPayment: any): any => {
    if (lastPayment?.method === 'use2pay-AlternativePayments') {
      return <SofortIcon isDarkMode={isDarkMode()} />;
    }

    let paymentMethodIcon: any;

    switch (lastPayment?.purchase_type) {
      case GPAY_PAYMENT_TYPE:
        paymentMethodIcon = (
          <GooglePayIcon width={56.4} height={30} customStyle={{ marginRight: 10 }} darkmode={isDarkMode()} />
        );
        break;
      case APAY_PAYMENT_TYPE:
        paymentMethodIcon = (
          <ApplePayIcon width={56.4} height={30} customStyle={{ marginRight: 5 }} darkmode={isDarkMode()} />
        );
        break;
      default:
        paymentMethodIcon = null;
    }

    return (
      <>
        {paymentMethodIcon}
        <span style={{ margin: '' }}>
          {lastPayment.card_type}
          {lastPayment?.last_4 && <>•••• {lastPayment.last_4}</>}
        </span>
      </>
    );
  };

  return (
    <Container padding="thin" screenTop>
      <BillingSuccessHeader label="Boom! You have credits!" isSuccess />
      {lastPayment ? (
        <>
          <Panel
            customStyle={{
              maxWidth: '658px',
              margin: '0 auto',
              border: '2px solid #24A83A',
              backgroundColor: backgroundTheme.orderSummary,
              borderRadius: 10,
              padding: '30px 20px',
              [dimensions.SCREEN_MAX_MD]: { padding: '30px 10px', border: '2px solid #24A83A' },
            }}
          >
            <SubHeader>Receipt</SubHeader>
            <BillingSummary>
              <Row>
                <LeftColPackage>
                  <CreditText>{addCommas(thePackage.credits)}</CreditText>{' '}
                  <span style={{ fontWeight: 400 }}>Credit Package</span>
                </LeftColPackage>
                <RightColPackage>
                  {`${String.fromCharCode(thePackage.currency.data.label_unicode)}${parseFloat(
                    renderSubtotal(lastPayment)
                  ).toFixed(2)}`}
                  {renderCurrencyCode()}
                </RightColPackage>
              </Row>
              {promoCode && (promoCode.discount_amount !== null || promoCode.discount_rate !== null) && (
                <Row>
                  <LeftCol>Promo Code ({`${promoCode.code}`})</LeftCol>
                  <RightCol>
                    {`- ${String.fromCharCode(thePackage.currency.data.label_unicode)}${renderDiscountAmount(
                      thePackage,
                      promoCode
                    )}`}
                  </RightCol>
                </Row>
              )}
              {taxes && (
                <Row>
                  <LeftCol>
                    Estimated tax ({`${thePackage.taxes.location} ${thePackage.taxes.rate * 100}%`})
                    <SubText>(VAT Registration Number: CY10384442N)</SubText>
                  </LeftCol>
                  <RightCol>
                    {`${String.fromCharCode(thePackage.currency.data.label_unicode)}${parseFloat(
                      thePackage.taxes.tax_amount
                    ).toFixed(2)}`}
                  </RightCol>
                </Row>
              )}
              <Row>
                <LeftCol>Paid with</LeftCol>
                <RightCol>{renderPaymentMethod(lastPayment)}</RightCol>
              </Row>
              {ternaryFunc(transactionID, renderTransactionRow(transactionID), null)}
              <Total>
                <LeftColTotal style={{ paddingTop: 5 }}>
                  <b>TOTAL</b> {getBrowserDetails().isMobile && <br />}
                  <span style={{ fontWeight: 'normal' }}>(amount billed today):</span>
                </LeftColTotal>
                <RightColTotal style={{}}>
                  {`${String.fromCharCode(thePackage.currency.data.label_unicode)}${parseFloat(
                    lastPayment.charge_amt
                  ).toFixed(2)}`}
                  {renderCurrencyCode()}
                </RightColTotal>
              </Total>
            </BillingSummary>

            <CreditCardNote>
              <b>Note:</b>
              <br /> {descriptors.join(', ').replace(/,(?!.*,)/gim, ' or')} will appear on your credit card statement
              for this purchase.
            </CreditCardNote>
          </Panel>
          <div style={{ textAlign: 'center' }}>
            <Button buttonType="primary" customStyle={{ marginTop: 30 }}>
              <Link data-test-id="payment-success-dashboard-link" to="/dashboard">
                Start Browsing Members
              </Link>
            </Button>
          </div>
        </>
      ) : (
        <Panel>
          <StyledHeadline size="SM">No Purchase History!</StyledHeadline>
        </Panel>
      )}
    </Container>
  );
};

export default BillingSuccess;
