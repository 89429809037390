import { unreadOffersCountKey } from '../actions/unreadOneTapOffersCountActions';

export const INCREMENT_UNREAD_OFFERS_COUNT = 'INCREMENT_UNREAD_OFFERS_COUNT';
export const DECREMENT_UNREAD_OFFERS_COUNT = 'DECREMENT_UNREAD_OFFERS_COUNT';
export const RESET_UNREAD_OFFERS_COUNT = 'RESET_UNREAD_OFFERS_COUNT';

export interface IUnreadOffersCount {
  count: number;
}

const unreadOffersCountValue = localStorage.getItem(unreadOffersCountKey);

const initialState: IUnreadOffersCount = {
  count: !unreadOffersCountValue ? 0 : parseInt(unreadOffersCountValue),
};

export interface IIncrementUnreadOffersCount {
  type: typeof INCREMENT_UNREAD_OFFERS_COUNT;
}

export interface IDecrementUnreadOffersCount {
  type: typeof DECREMENT_UNREAD_OFFERS_COUNT;
}

export interface IResetUnreadOffersCount {
  type: typeof RESET_UNREAD_OFFERS_COUNT;
}

const unreadOneTapOffersCount = (state = initialState, action: any): IUnreadOffersCount => {
  switch (action.type) {
    case INCREMENT_UNREAD_OFFERS_COUNT:
      return {
        count: state.count + 1,
      };
    case DECREMENT_UNREAD_OFFERS_COUNT:
      return {
        count: state.count < 1 ? 0 : state.count - 1,
      };
    case RESET_UNREAD_OFFERS_COUNT:
      return {
        count: 0,
      };
    default:
      return state;
  }
};

export default unreadOneTapOffersCount;
