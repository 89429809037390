import React from 'react';
import CheckboxGroup from '../../atoms/CheckboxGroup';

interface IJoinCheckboxFormGroupProps {
  status?: string;
  statusMessage?: string;
  label: string;
  children: React.ReactNode;
  tooltip?: any;
  isRequired?: boolean;
  customStyle?: any;
  customCheckboxListStyle?: React.CSSProperties;
  customContainerStyle?: React.CSSProperties;
}
const JoinCheckboxFormGroup: React.FC<IJoinCheckboxFormGroupProps> = ({
  status,
  label,
  statusMessage,
  children,
  tooltip,
  isRequired = false,
  customStyle = {},
  customCheckboxListStyle,
  customContainerStyle = { marginBottom: 5 },
}) => {
  return (
    <CheckboxGroup
      inputLabel={label}
      status={status}
      statusMessage={statusMessage}
      customStyle={{ paddingBottom: 0, ...customStyle }}
      customLabelStyle={{ fontSize: 15, fontWeight: 600 }}
      customContainerStyle={customContainerStyle}
      tooltip={tooltip}
      isRequired={isRequired}
      customCheckboxListStyle={customCheckboxListStyle}
    >
      {children}
    </CheckboxGroup>
  );
};

export default JoinCheckboxFormGroup;
