import React, { useState, useEffect, ReactElement } from 'react';
import styled from '@emotion/styled';
import { checkNetworkReachable } from '../../utils/helpers';

const OfflineStatusContainer = styled('div')`
  transition: 0.25s ease-in-out;
  border: none;
  border-radius: 3px;
  width: 180px;
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 10px;
  z-index: 1002;
  color: #fff;
  font-weight: 500;
  left: 50%;
  margin-left: -100px;
  ${props => !props.isVisible && 'opacity: 0; height: 0; overflow: hidden; display: none'};
`;

interface OfflineContainerProps {
  forceShow: any;
}

const OfflineContainer = ({ forceShow }: OfflineContainerProps): ReactElement => {
  const [offlineStatus, setOfflineStatus] = useState({
    isOnline: navigator.onLine,
    message: 'You are currently offline.',
  });

  useEffect(() => {
    addListeners();
    return () => {
      removeListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addListeners = () => {
    window.addEventListener('online', setStatusOffline);
    window.addEventListener('offline', setStatusOffline);
  };

  const removeListeners = () => {
    window.removeEventListener('online', setStatusOffline);
    window.removeEventListener('offline', setStatusOffline);
  };

  const setStatusOffline = () => {
    const hasNetwork = checkNetworkReachable();
    const navigatorFalsePersist = !navigator.onLine && hasNetwork;

    setOfflineStatus({
      ...offlineStatus,
      isOnline: navigator.onLine || navigatorFalsePersist,
    });
  };

  return (
    <OfflineStatusContainer id="OfflineStatus" isVisible={forceShow || !offlineStatus.isOnline}>
      {offlineStatus.message}
    </OfflineStatusContainer>
  );
};

export default OfflineContainer;
