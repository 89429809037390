import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  'Number of Cards': number;
}

export const mixpanelTrackDiscoverModeViewed = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Discover Mode Viewed',
    payload: {
      event_properties: eventProperties,
    },
  });
};
