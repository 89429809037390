import React, { ReactElement } from 'react';

const ConfirmModalErrorIcon = (): ReactElement => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_1655_1258)">
        <path
          d="M19.9999 28.1974C20.4732 28.1974 20.8778 28.0295 21.2136 27.6937C21.5494 27.3579 21.7173 26.9533 21.7173 26.48C21.7173 26.0126 21.5494 25.6056 21.2136 25.259C20.8778 24.9124 20.4732 24.7391 19.9999 24.7391C19.5266 24.7391 19.1221 24.9124 18.7863 25.259C18.4504 25.6056 18.2825 26.0126 18.2825 26.48C18.2825 26.9474 18.4504 27.3505 18.7863 27.6893C19.1221 28.0281 19.5266 28.1974 19.9999 28.1974ZM18.6086 22.2916H21.3912V10.509H18.6086V22.2916ZM13.5144 35.0942L4.41113 25.9951V12.9963L13.5103 3.89307H26.509L35.6123 12.9922V25.9909L26.5131 35.0942H13.5144Z"
          fill="#DD0B24"
        />
      </g>
    </svg>
  );
};

export default ConfirmModalErrorIcon;
