import { composeOptionsVysion } from '../helpers';
import { vysionTrack } from './vysionService';

const setBillingVysion = (param: any): void => {
  switch (param.event) {
    case 'SOFORT FIRST NAME':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'billing-sofort-firstName');
      break;
    case 'SOFORT LAST NAME':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'billing-sofort-lastName');
      break;
    case 'SOFORT COUNTRY':
      vysionTrack(composeOptionsVysion(param, 'eventData'), 'billing-sofort-country');
      break;
    default:
      return;
  }
};

export { setBillingVysion };
