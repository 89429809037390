import React, { PureComponent, ReactElement, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import EmailInput from '../atoms/EmailInput';
import TextInput from '../atoms/TextInput';
import Button from '../atoms/buttons/Button';

const Email = styled('div')({
  paddingBottom: '24px',
  fontSize: '16px',
});

class CreateEmailAndPasswordForm extends PureComponent {
  handleOnSubmit = (e: ChangeEvent): void => {
    e.preventDefault();
    this.props.onSubmit();
  };

  render(): ReactElement {
    const { newEmail, currentEmail, newPassword, onChange, disableSubmit, errors } = this.props;

    return (
      <form onSubmit={this.handleOnSubmit}>
        <Email>
          <b>Current email:</b> {currentEmail}
        </Email>
        <EmailInput
          inputLabel={'New Email'}
          value={newEmail}
          onChange={onChange}
          autoFocus
          status={errors.account_email && 'error'}
          statusMessage={errors.account_email && errors.account_email[0]}
        />

        <TextInput
          id={'password'}
          type="password"
          showPassword={true}
          inputLabel="New Password"
          value={newPassword}
          onChange={onChange}
          status={errors.account_password && 'error'}
          statusMessage={errors.account_password && errors.account_password[0]}
          customStyle={{ maxWidth: '452px' }}
        />

        <Button buttonType="primary" type="submit" disabled={disableSubmit}>
          Save
        </Button>
      </form>
    );
  }
}

CreateEmailAndPasswordForm.propTypes = {
  newEmail: PropTypes.string,
  currentEmail: PropTypes.string.isRequired,
  newPassword: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default CreateEmailAndPasswordForm;
