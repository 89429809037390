import {
  DEFAULT_REASON,
  EvidentlyFeatureExperiment,
  getFeatureDetails,
  isEvidentlyExperimentLive,
} from '../../constants/evidently';
import { TrackEvidentlyMetricsParams, trackEvidentlyMetrics } from '../../sdk/CommonSDK';
import store from '../../store';
import { getBrowserDetails } from '../helpers';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

export const DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME = 'DiscoverSwipingModeDesktop';
export const DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME = 'DiscoverDesktopTest';
export const DISCOVER_SWIPING_MODE_DESKTOP_LOCAL_STORAGE_KEY = 'evidently_discover_swipe_mode_desktop';

export type DiscoverSwipeModeDesktopVariations = 'Control' | 'DiscoverModeDesktop';
const mixpanelEventVariation = {
  Control: 'Control',
  DiscoverModeDesktop: 'Discover Mode Desktop',
};

export type DiscoverSwipeModeDesktopMetricKeys =
  | 'discoverSwipingModeDesktopOffersMadeDashboard'
  | 'discoverSwipingModeDesktopOffersMadeProfile';

/**
 * This is only used in batchEvaluation
 *
 * @param profile Profile
 * @returns boolean
 */
const handleIsFeatureEvaluate = (): boolean => {
  return !getBrowserDetails().isMobile;
};

const handleFeatureCallback = async (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled?: boolean
): Promise<void> => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  mixpanelTrackExperimentStarted({
    'Experiment name': DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME,
    'Variant name': mixpanelEventVariation?.[details.variation] ?? 'Control',
  });
};

const featureMetrics = (details: EvidentlyFeatureExperiment): TrackEvidentlyMetricsParams<string>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  return [
    {
      featureName: DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
      experimentName: DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'discoverSwipingModeDesktopOffersMadeDashboard',
      value: 0,
    },
    {
      featureName: DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
      experimentName: DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'discoverSwipingModeDesktopOffersMadeProfile', // offer from a member's profile page that was clicked on from the dashboard
      value: 0,
    },
  ];
};

export const trackDiscoverSwipingModeDesktopMetrics2 = async (
  hashId: string,
  key: DiscoverSwipeModeDesktopMetricKeys
): Promise<void> => {
  const isLive = await isEvidentlyExperimentLive(DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME);
  const settingsState = store.getState().settings;
  const discoverModeDesktopExperimentDetails = getFeatureDetails(
    settingsState.evidently,
    DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME
  );

  if (
    isLive &&
    discoverModeDesktopExperimentDetails &&
    discoverModeDesktopExperimentDetails.reason !== DEFAULT_REASON
  ) {
    trackEvidentlyMetrics({
      hashId,
      key,
      featureName: DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
      experimentName: DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME,
      value: 1,
      shouldAddUpValue: false,
    });
  }
};

export const trackDiscoverSwipingModeDesktopMetrics = async (
  hashId: string,
  key: DiscoverSwipeModeDesktopMetricKeys
): Promise<void> => {
  const [isLive, settingsState] = await Promise.all([
    isEvidentlyExperimentLive(DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME),
    store.getState().settings,
  ]);

  const discoverModeDesktopExperimentDetails = getFeatureDetails(
    settingsState.evidently,
    DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME
  );

  const shouldTrackMetrics =
    isLive && discoverModeDesktopExperimentDetails && discoverModeDesktopExperimentDetails.reason !== DEFAULT_REASON;

  if (shouldTrackMetrics) {
    trackEvidentlyMetrics({
      hashId,
      key,
      featureName: DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
      experimentName: DISCOVER_SWIPING_MODE_DESKTOP_EXPERIMENT_NAME,
      value: 1,
      shouldAddUpValue: false,
    });
  }
};

export const DiscoverSwipingModeDesktopEvaluation = [
  {
    featureMetrics,
    featureName: DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
