const getPackageSubTotal = (pckg: any, discount = 0, vatDetails?: any): number => {
  const packagePrice = parseAmount(pckg?.price);

  if (vatDetails) {
    return getDiscountedTaxSubTotalAmount(
      packagePrice,
      parseAmount(vatDetails.gross_amount) || 0,
      parseAmount(vatDetails?.discount_amount) || 0,
      vatDetails?.rate || 0,
      discount || 0
    );
  }

  if (packagePrice == discount) {
    return 0;
  }

  if (pckg.taxes) {
    return getPackageChargeVat(pckg, discount);
  }

  const subTotal = parseAmount(pckg?.price) - discount;

  return subTotal > 0 ? subTotal : 0;
};

const getDiscountedTaxSubTotalAmount = (
  packagePrice: number,
  grossAmount: number,
  discountedAmount: number,
  taxRate: number,
  discount = 0
): number => {
  if (discountedAmount > 0) {
    return grossAmount > 0 ? grossAmount : 0;
  }

  if (discount && packagePrice != discount) {
    // Get orignal price
    const discountedAmount = packagePrice - discount;
    // Re-calculate tax amount
    if (taxRate > 0) {
      const taxAmount = parseFloat((discountedAmount * taxRate).toFixed(2));
      const total = discountedAmount + taxAmount;
      return total > 0 ? total : 0;
    }

    return discountedAmount;
  }

  return grossAmount > 0 ? grossAmount : 0;
};

const getPackageChargeVat = (pckg: any, discount = 0): number => {
  const packagePrice = parseAmount(pckg?.price);

  if (packagePrice == discount) {
    return 0;
  }

  if (pckg?.taxes) {
    return getDiscountedTaxSubTotalAmount(
      packagePrice,
      parseAmount(pckg?.taxes.gross_amount) || 0,
      parseAmount(pckg?.taxes.discount_amount) || 0,
      pckg?.taxes?.rate || 0,
      discount || 0
    );
  }

  return 0;
};

const getPackageVat = (pckg: any, discount = 0, vatDetails?: any): number => {
  if (vatDetails) {
    return parseAmount(vatDetails.tax_amount);
  }

  const price = parseAmount(pckg?.price);

  if (price == discount) {
    return 0;
  }

  return parseAmount(pckg?.taxes?.tax_amount);
};

const parseAmount = (amount: any): number => {
  if (typeof amount === 'undefined' || amount === null) {
    return 0;
  }

  return typeof amount === 'string' ? parseFloat(amount) : amount;
};

const isApplePayEligible = (applepayCredentials: any): boolean => {
  return window.ApplePaySession && applepayCredentials;
};

export { getPackageSubTotal, getPackageChargeVat, getPackageVat, parseAmount, isApplePayEligible };
