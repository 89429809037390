import React, { ReactElement } from 'react';
interface IRoundErrorIconProps {
  customStyle?: any;
}

const RoundErrorIcon = ({ customStyle }: IRoundErrorIconProps): ReactElement => {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg" style={customStyle}>
      <path
        d="M17.4994 25.328C17.9164 25.328 18.2661 25.187 18.5485 24.9049C18.8309 24.6228 18.9721 24.2733 18.9721 23.8563C18.9721 23.4393 18.8311 23.0896 18.549 22.8072C18.2669 22.5248 17.9174 22.3836 17.5004 22.3836C17.0834 22.3836 16.7337 22.5246 16.4513 22.8067C16.1689 23.0888 16.0277 23.4383 16.0277 23.8553C16.0277 24.2723 16.1688 24.622 16.4508 24.9044C16.7329 25.1868 17.0824 25.328 17.4994 25.328ZM17.5881 18.8558C17.983 18.8558 18.3124 18.7227 18.5763 18.4565C18.8402 18.1903 18.9721 17.8604 18.9721 17.4669V9.88355C18.9721 9.49002 18.8386 9.16016 18.5714 8.89396C18.3043 8.62777 17.9733 8.49467 17.5784 8.49467C17.1835 8.49467 16.8541 8.62777 16.5902 8.89396C16.3263 9.16016 16.1944 9.49002 16.1944 9.88355V17.4669C16.1944 17.8604 16.3279 18.1903 16.5951 18.4565C16.8622 18.7227 17.1932 18.8558 17.5881 18.8558ZM17.5073 33.6613C15.2067 33.6613 13.0447 33.2238 11.0212 32.3488C8.99777 31.4738 7.23141 30.2817 5.72213 28.7725C4.21288 27.2632 3.02075 25.4961 2.14575 23.4713C1.27075 21.4465 0.833252 19.283 0.833252 16.9808C0.833252 14.6786 1.27075 12.5151 2.14575 10.4903C3.02075 8.46542 4.21288 6.703 5.72213 5.203C7.23141 3.703 8.99845 2.5155 11.0233 1.6405C13.0481 0.765503 15.2116 0.328003 17.5138 0.328003C19.816 0.328003 21.9795 0.765503 24.0043 1.6405C26.0292 2.5155 27.7916 3.703 29.2916 5.203C30.7916 6.703 31.9791 8.46689 32.8541 10.4947C33.7291 12.5224 34.1666 14.6867 34.1666 16.9873C34.1666 19.2879 33.7291 21.4499 32.8541 23.4734C31.9791 25.4968 30.7916 27.2615 29.2916 28.7673C27.7916 30.2732 26.0277 31.4653 23.9999 32.3437C21.9721 33.2221 19.8079 33.6613 17.5073 33.6613Z"
        fill="#DD0B24"
      />
    </svg>
  );
};

export default RoundErrorIcon;
