/**
 * @Author: zachalam
 * @Date:   2017-03-15T16:16:14-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-07-27T17:30:31-07:00
 */

import React, { useState, useEffect } from 'react';
import Blocked from '../components/pages/Settings/Blocked';
import { connect } from 'react-redux';
import { getBlockedList, toggleBlockProfile, loadMoreData, toggleBlockInStore } from '../actions/profileActions';
import { requestFavs } from '../actions/favActions';
import { killLoading, setLoading } from '../actions/commonActions';
import { displayFailMessage } from '../actions/notificationActions';
import { useNavigate } from 'react-router-dom';

const BlockedContainer = ({ refresh, blocked, unblockProps, ...rest }) => {
  const navigate = useNavigate();

  const [moreExists, setMoreExists] = useState(false);
  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    const hasMore =
      blocked.pagination !== undefined
        ? Boolean(blocked.pagination['current_page'] < blocked.pagination['total_pages'])
        : false;
    setMoreExists(hasMore);
  }, [blocked]);

  const unblock = (username: string, hashId: string) => {
    unblockProps(username, hashId, navigate)
  }

  return <Blocked moreExists={moreExists} blocked={blocked} unblock={unblock} {...rest} />;
};

const mapStateToProps = state => {
  return {
    blocked: state.blocked,
    myProfile: state.profile.profile.data,
    isFetching: state.fav.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refresh: () => {
      dispatch(getBlockedList());
    },
    unblockProps: (username, hashId, navigate) => {
      dispatch(setLoading());
      // notify api.
      dispatch(toggleBlockProfile(username, hashId, false)).then(response => {
        dispatch(killLoading());
        if (response.status === 200) {
          dispatch(toggleBlockInStore(hashId));

          // redirect to profile.
          navigate(`/profile/${hashId}`);
          setTimeout(() => {
            dispatch(
              displayFailMessage({
                info: `You have unblocked ${username}`,
              })
            );
          }, 100);
        }
      });
    },
    loadMore(page) {
      dispatch(requestFavs());
      dispatch(loadMoreData(page));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BlockedContainer);
