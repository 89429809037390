import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Container from '../../blocks/Container';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';

import { MasterConfig } from '../../../config/Master';
import { borders } from '../../../style';
import { TOSPath, setCookie, isDarkMode } from '../../../utils/helpers';
import RadioButton from '../../../atoms/RadioButton';
import { useNavigate } from 'react-router-dom';
import { ALLOW_THEME_COOKIE_NAME } from '../../../config/constants';

const PrivacyPolicyContainer = styled('div')({
  fontSize: 16,
  maxWidth: 600,
  margin: '22px auto',
  border: borders.DEFAULT,
  padding: '1.5rem',
  borderRadius: 6,
  boxShadow: '#e8e8e8 0px 0px 1px 1px',
  background: isDarkMode() ? '#041a2a' : '#fff',
  'p, span': {
    color: isDarkMode() ? '#c9c9c9' : 'inherit',
    fontSize: 14,
  },
  'h2, h3': {
    color: isDarkMode() ? '#fff' : 'inherit',
  },
});

const TextLink = styled('a')(() => ({
  display: 'inline-block',
  fontWeight: 600,
  cursor: 'pointer',
  color: 'rgb(62, 121, 165)',
}));

interface PrivacyPolicyProps {
  gdpr_acknowledged: any;
  gdpr_Step2: any;
  notifyEmailUpdate: any;
  buttonEventHandler: any;
  profile: any;
}

const PrivacyPolicy = ({
  gdpr_acknowledged,
  gdpr_Step2,
  notifyEmailUpdate,
  buttonEventHandler,
  profile,
}: PrivacyPolicyProps): any => {
  const [optToReceiveFutureEmail, setOptToReceiveFutureEmail] = useState<boolean | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setCookie(ALLOW_THEME_COOKIE_NAME, false);
  }, []);

  if (gdpr_acknowledged === undefined || gdpr_acknowledged === null) {
    navigate('/dashboard');
  }

  const siteName = MasterConfig.SITE_NAME;
  const siteBaseUrl = MasterConfig.BASE_URL;
  const siteAcro = MasterConfig.SITE_ACRONYM.toUpperCase();
  const userCountry = profile && profile.data && profile.data.country;

  return (
    <div>
      <Container>
        <PrivacyPolicyContainer>
          {!gdpr_acknowledged && !gdpr_Step2 && (
            <div>
              <div className="inputGroup-title">
                <span>Updates to {siteName} Privacy Policy - Step 1 of 2</span>
              </div>
              <div>
                <h2>{siteName.toUpperCase()} PRIVACY POLICY</h2>
                <p>Effective Date: October 1, 2021</p>

                <h3>OUR COMMITMENT</h3>
                <p>
                  {siteBaseUrl} (“{siteAcro}”), which is operated by Reflex Media, Inc. (“Reflex”) and W8 Tech Cyprus
                  (“W8 Cyprus”), respects your privacy. W8 Cyprus operates the Website in the European Union, the
                  European Economic Area and the United Kingdom. Reflex operates the Website in all other applicable
                  areas. We are committed to protecting the privacy of our users and we want to provide a safe and
                  secure user experience. We will use our best efforts to ensure that the information you submit to us
                  remains private and is used only for the purposes set forth herein. This policy details how data about
                  you is collected, used, and shared when you access our websites and services or interact with us
                  (collectively, the “{siteAcro} Services”).
                </p>

                <h3>APPLICABILITY OF PRIVACY POLICY</h3>
                <p>
                  This privacy policy applies to all information we collect through our web and mobile applications from
                  current and former {siteAcro} users and visitors. When you are no longer our customer, we continue to
                  share your information as described in this policy. This policy only applies to the {siteAcro}{' '}
                  Services and does not apply to the practices of any other Reflex or W8 Cyprus service. This policy
                  also covers our treatment of any personally identifiable information that our business partners share
                  with us.
                </p>

                <h3>REFLEX MEDIA and W8 CYPRUS AFFILIATES</h3>
                <p>Reflex and W8 Cyprus are affiliated with various entities. These entities are as follows:</p>
                <p>
                  <ul>
                    <li>Clover8 Investments Pte Ltd.</li>
                    <li>W8 Tech Limited (Hong Kong)</li>
                    <li>W8 Tech Limited, Inc. (USA)</li>
                  </ul>
                </p>

                <h3>INFORMATION COLLECTED</h3>
                <p>
                  When you use the {siteAcro} Services, you may be asked for certain personal information, including
                  your name, email address, zip code, phone numbers, credit card information, occupation, hobbies and
                  interests. Our website also automatically receives and records information on our server logs from
                  your browser, including your IP address, browser type and {siteAcro} Cookie (defined below)
                  information. As previously stated, we are committed to providing you a safe and secure user
                  environment. Therefore, before permitting you to use {siteAcro}’s Services, we may require additional
                  information from you that we can use to verify your identity, address or other information or to
                  manage risk and compliance throughout our relationship. We may also obtain information about you from
                  third parties such as identity verification, fraud prevention, and similar services. Reflex and/or W8
                  Cyprus, collect data to operate effectively and provide better quality experiences. If you do not
                  agree to our collection of the information, you may be limited or restricted in the {siteAcro}{' '}
                  Services available to you.
                </p>

                <h3>HOW REFLEX and W8 CYPRUS, USE PERSONAL DATA:</h3>
                <p>{siteAcro} uses this information to:</p>
                <p>
                  <ul>
                    <li>Provide, maintain, and improve the {siteAcro} Services;</li>
                    <li>Personalize the content you see;</li>
                    <li>Provide Customer Service;</li>
                    <li>
                      Help protect the safety of {siteAcro} and its users, which includes blocking suspected spammers,
                      investigating and addressing abuse, and enforcing the {siteAcro} Terms of Use and Privacy Policy;
                    </li>
                    <li>
                      Inform you of updates to our services, security alerts, and other support and administrative
                      messages;
                    </li>
                    <li>
                      Communicate with you about products, services, offers, promotions, and events, and provide other
                      news and information we think will be of interest to you (for information about how to opt out of
                      these communications, see “Choices” below); and
                    </li>
                    <li>
                      Monitor and analyze trends, usage, and activities in connection with the {siteAcro} Services.
                    </li>
                  </ul>
                </p>

                <h3>WEBSITE VISITORS</h3>
                <p>
                  We gather information about all users collectively, such as what areas users visit most frequently and
                  what services users access the most. We only use such data anonymously. This information helps us
                  determine what is the most beneficial for our users and how we can continually create a better overall
                  user experience. We may share anonymized information with our partners so that they may also
                  understand how users and visitors use our services. We may use data stitching, which is the process of
                  combining your datasets from multiple sources, to better track website use. Our Website uses Google
                  Analytics. To learn more about how Google Analytics collects and uses data, or to opt out,{' '}
                  <TextLink href="https://policies.google.com/" target="_blank">
                    click HERE
                  </TextLink>
                </p>

                <h3>INFORMATION FROM MINORS</h3>
                <p>
                  We do not knowingly solicit, collect, or share information from any individuals under the age of 18.
                </p>

                <h3>COOKIES</h3>
                <p>
                  When you utilize the {siteAcro} Services, we&mdash;along with certain business partners and
                  vendors&mdash;may use cookies and other tracking technologies (collectively, “Cookies”). We use
                  Cookies as outlined in the “How Your Information Is Used Section.” Certain aspects and features of the{' '}
                  {siteAcro} Services are only available through the use of Cookies, so if you choose to disable or
                  decline Cookies, your use of the {siteAcro} Services may be limited or not possible.
                </p>

                <h3>DO NOT TRACK</h3>
                <p>
                  Do Not Track (“DNT”) is an optional browser setting that allows you to express your preferences
                  regarding tracking by advertisers and other third parties. We do not respond to DNT signals.
                </p>

                <h3>SHARING INFORMATION</h3>
                <p>
                  When you use the {siteAcro} Services, certain information may be shared with other users and the
                  public. For example:
                </p>
                <p>
                  <ul>
                    <li>
                      When you submit a post or comment to the {siteAcro} Services, visitors to and users of the{' '}
                      {siteAcro} Services will be able to see the content of your posts and comments, the username
                      associated with your posts or comments, and the date and time you originally submitted the post or
                      comment. Although some parts of the services may be private or quarantined, they may become public
                      and you should take that into consideration before posting to the {siteAcro} Services.
                    </li>
                    <li>
                      When you send private messages or messages, the recipients of those messages will be able to see
                      the content of your message, your username, and the date and time the message was sent. Please use
                      caution when sharing information about yourself to third parties, as we have no control over how
                      they will use the information you share with them.
                    </li>
                    <li>
                      When other users view your profile, they will be able to see information about your activities on
                      the {siteAcro} Services, such as your username, prior posts and comments, etc.
                    </li>
                    <li>
                      We will not share, sell, or give away any of our users’ personal information to third parties,
                      unless one of the following circumstances applies:
                    </li>
                    <li>
                      We may share information with vendors, consultants, and other service providers who need access to
                      such information to carry out work for us. A complete list of the types of these third parties is
                      at Appendix I at the end of this Policy. These companies may have access to your personal
                      information as necessary to perform their functions, and they are contractually obligated to
                      maintain the confidentiality of any personal information in which they come in contact as part of
                      their services to {siteAcro}.
                    </li>
                    <li>
                      If you participate in promotions, special offers, or other events or activities in connection with
                      the {siteAcro} Services, we may share information with entities that partner with us to provide
                      these offerings;
                    </li>
                    <li>
                      We may share information in response to a request for information if we believe disclosure is in
                      accordance with, or required by, any applicable law, regulation, legal process or governmental
                      request, including, but not limited to meeting national security or law enforcement requirements;
                    </li>
                    <li>
                      We may share information in response to an emergency if we believe it is necessary to prevent
                      imminent and serious bodily harm to a person;
                    </li>
                    <li>
                      We may share aggregated or anonymized information, which cannot reasonably be used to identify
                      you;
                    </li>
                    <li>
                      We may share information between and among {siteAcro}, and its current and future parents,
                      affiliates, subsidiaries, and other companies under common control and ownership; and
                    </li>
                    <li>We may share information with your consent or at your direction.</li>
                  </ul>
                </p>
                <h3>SOCIAL SHARING</h3>
                <p>
                  The {siteAcro} Services may offer social sharing features or other integrated tools that let you share
                  content or action you take using the {siteAcro} Services with other media. Your use of these features
                  enables the sharing of certain information with your friends or the public, depending on the settings
                  you establish with the third party that provides the social sharing feature. For more information
                  about the purpose and scope of data collection and processing in connection with social sharing
                  features, please visit the privacy policies of the third parties that provide these social sharing
                  features.
                </p>

                <h3>ONLINE TRANSACTIONS</h3>
                <p>
                  When you place an order online, your personal details and credit card information are encrypted using
                  SSL encryption technology before being sent over the Internet. Although we cannot guarantee encryption
                  or the privacy of your personal details, SSL makes it very difficult for your information to be stolen
                  or intercepted while being transferred. Our credit card transactions are processed by InovioPay and
                  RocketGate. All information sent to InovioPay and RocketGate is in the secure SSL environment, which
                  will protect it against disclosure to third parties. When we receive your order, it is on an SSL
                  server. For InovioPay’s privacy statement, please click{' '}
                  <TextLink href="https://www.rocketgate.com/resources/privacy-policy.php" target="_blank">
                    HERE
                  </TextLink>
                  . At the point when an end-user wishes to make a payment, his registration details may be passed to
                  PaymentWall, a company that provides payment processing services, in order for PaymentWall to better
                  protect service- and end-users from unauthorized payments.
                </p>

                <h3>ACCOUNTS PAYABLE OR COLLECTIONS</h3>
                <p>
                  It is your duty to make sure that you keep your account(s) in good standing with us. If you have an
                  outstanding balance, or if you dispute your payment to us with your credit card or financial
                  institution for any reason, please note that all information collected from your activity on our
                  website may be disclosed to the bank and/or collections agency we work with for the purposes of
                  establishing proof of users’ identity, proof of use, proof that service was provided, and/or
                  collecting any outstanding debt owed to us.
                </p>

                <h3>CALIFORNIA CONSUMERS</h3>
                <p>
                  Pursuant to the California Consumer Privacy Act (the “CCPA”), we are required to disclose the
                  categories of Personal Data we collect from You and how we use it, as well as the third parties with
                  whom we share it, which we have explained above and in Appendix I.
                </p>
                <p>
                  Please note: If You have used our services in the preceding twelve (12) months, then we have collected
                  Personal Data from You, the devices You use, our partners, and from sources otherwise described in
                  this Privacy Policy.{' '}
                </p>
                <p>
                  The CCPA provides California consumers, defined as “natural persons who are California residents,”
                  with certain rights under the CCPA. These rights are as follows:
                </p>
                <p>
                  <ul>
                    <li>
                      You have the right to know and request information regarding the categories of Personal Data:
                      <ul>
                        <li>We collect, use, or share; </li>
                        <li>The purposes for which these categories are collected or used by us; </li>
                        <li>The sources from which we collect Personal Data; and </li>
                        <li>The specific Personal Data we have collected about you.</li>
                      </ul>
                    </li>
                    <li>
                      You have the right to equal service. Reflex and will not discriminate against You for exercising
                      your privacy rights under California law.
                    </li>
                    <li>You have to right to request deletion of your Personal Data.</li>
                  </ul>
                </p>
                <p>
                  To request access to or deletion of your Personal Data, please contact Reflex at the following email:
                  privacyofficer@reflexmedia.com or via our toll-free telephone number: 1-888-928-3887. Any requests are
                  subject to identity verification.
                </p>
                <p>Reflex does not sell the Personal Data of our users.</p>

                <h3>INTERNATIONAL DATA</h3>
                <p>
                  {siteAcro} is a worldwide service. We are based in the United States and the information we collect is
                  governed by U.S. law. By accessing or using the {siteAcro} Services or otherwise providing information
                  to us, you consent to the processing, transfer, and storage of information in and to the U.S. and
                  other countries, where you may not have the same rights as you do under local law.{' '}
                </p>

                <p>
                  <em>European Union; European Economic Area; Switzerland</em>
                </p>
                <p>
                  In connection with {siteAcro}’s processing of personal data received from the European Union and
                  Switzerland, we adhere to the E.U.General Data Protection Regulation (the “GDPR”) and the Swiss
                  Federal Act on Data Protection (the “SFADP”) and comply with their frameworks and principles. Please
                  direct any inquiries or complaints regarding our compliance with GDPR or SFADP to the email listed in
                  the “Data Protection Officer” section below. If we do not resolve your complaint, you may submit your
                  complaint free of charge to the International Centre For Dispute Resolution-American Arbitration
                  Association (ICDR-AAA) based in the United States. Under certain conditions, you may also be able to
                  invoke binding arbitration to resolve your complaint. We are subject to the investigatory and
                  enforcement powers of the E.U. and Switzerland. If we share E.U. or Swiss data with a third-party
                  service provider that processes the data solely on our behalf, then we will be liable for that third
                  party’s processing of E.U. or Swiss data in violation of the GDPR or SFADP, unless we can prove that
                  we are not responsible for the event giving rise to the damage.
                </p>

                <p>
                  <em>United Kingdom</em>
                </p>
                <p>
                  In connection with {siteAcro}’s processing of personal data received from the United Kingdom (the
                  “U.K.”), we adhere to the U.K. GDPR/2018 Data Protection Act (the “U.K. GDPR”) and comply with its
                  frameworks and principles. Please direct any inquiries or complaints regarding our compliance with
                  U.K. GDPR to the email listed in the “Data Protection Officer” section below. If we do not resolve
                  your complaint, you may submit your complaint free of charge to the International Centre For Dispute
                  Resolution-American Arbitration Association (ICDR-AAA) based in the United States. Under certain
                  conditions, you may also be able to invoke binding arbitration to resolve your complaint. We are
                  subject to the investigatory and enforcement powers of the U.K. If we share U.K. data with a
                  third-party service provider that processes the data solely on our behalf, then we will be liable for
                  that third party’s processing of U.K. data in violation of the U.K. GDPR, unless we can prove that we
                  are not responsible for the event giving rise to the damage.
                </p>

                <p>
                  <em>Brazil</em>
                </p>
                <p>
                  In connection with {siteAcro}’s processing of personal data received from Brazil, we adhere to the
                  Brazilian General Data Protection Law (Lei Geral de Protecao de Dados Pessoais) (the “LGDP”) and
                  comply with its frameworks and principles. Please direct any inquiries or complaints regarding our
                  compliance with LGPD to the email listed in the “Data Protection Officer” section below.{' '}
                </p>

                <p>
                  <em>New Zealand</em>
                </p>
                <p>
                  In connection with {siteAcro}’s processing of personal data, we adhere to the New Zealand Privacy Act.
                  Please note that while {siteAcro} strives to ensure the privacy of your personal data, by using the{' '}
                  {siteAcro} Services your personal data may not be given the same protection as provided by the New
                  Zealand Privacy Act.{' '}
                </p>

                <h3>PROTECTION &amp; STORAGE OF PERSONAL INFORMATION</h3>
                <p>
                  Your personal information is protected by physical, electronic, and procedural safeguards in
                  compliance with applicable U.S. federal and state regulations. We also use computer safeguards such as
                  firewalls and data encryption. For example, we use SSL encryption to safeguard your credit card data
                  when used in e-commerce transactions. In addition, we enforce physical access controls to our offices
                  and files, and we authorize access to users’ personal information only for personnel who require it to
                  fulfill their job responsibilities. We strive to ensure security on our systems. Despite our efforts,
                  we cannot guarantee that personal information may not be accessed, disclosed, altered or destroyed by
                  breach of our administrative, managerial, and technical safeguards. Therefore, we urge you to take
                  adequate precautions to protect your personal data as well, including never sharing the username and
                  password used in connection with your participation in the {siteAcro} Services. If {siteAcro} learns
                  of a systems security breach, we may attempt to notify you electronically so that you can take
                  appropriate protective steps. By using the {siteAcro} Services, you agree that {siteAcro} may
                  communicate with you electronically. {siteAcro} may post a notice on the website or mobile application
                  if a security breach occurs. We may also send an email to you at the email address you have provided
                  to us. Depending on where you live, you may have a legal right to receive notice of a security breach
                  in writing. To receive free written notice of a security breach (or to withdraw your consent from
                  receiving electronic notice of a security breach) you should notify us of your request here.{' '}
                </p>

                <h3>UPDATING ACCOUNT INFORMATION</h3>
                <p>
                  {siteAcro} allows you to change your personal information at any time. To do so, simply log on to{' '}
                  {siteAcro} with your username and password, and you will be able to update the information you have
                  submitted.
                </p>

                <h3>CHOICES</h3>
                <p>
                  We also provide you with the opportunity to opt-in to receive these types of communications at the
                  time of registration, but later decide to opt out of several types of communications at the time of
                  registration. If you no longer want to receive them, simply change your preferences on your account
                  settings or communicate your preferences to us via email. You may opt out of receiving promotional
                  communications from us by following the instructions in those communications. If you opt out, we may
                  still send you non-promotional communications, such as information about your account or your use of
                  the {siteAcro} Services. With your consent, we may send promotional and non-promotional push
                  notifications or alerts to your mobile device. You can deactivate these messages at any time by
                  changing the notification settings on your mobile device. As a Data Subject in the European Economic
                  Area, you have the right to request, access, rectify, withdraw, delete, and restrict how we process
                  your Personal Data. These rights include the ability to review the Personal Data we have in our
                  Information and Communications systems concerning you, the ability to make any corrections to that
                  Personal Data, the ability to be informed of who that data may have been shared with, the ability to
                  withdraw your consent for Reflex and/or W8 Cyprus to process your Personal Data, the ability to
                  request that we delete all of your Personal Data, and the ability to restrict how we process your
                  Personal Data.
                  <br />
                  <br />
                  If you deactivate your account or request that Reflex and/or W8 Cyprus delete your Personal Data,
                  Reflex and/or W8 Cyprus may still retain certain information associated with your account for
                  analytical purposes and record-keeping integrity, as well as to prevent fraud, collect any fees owed,
                  enforce our terms and conditions, take actions we deem necessary to protect the integrity of our
                  website or our users, or take other actions otherwise permitted by the General Data Protection
                  Regulation. In addition, if certain information has already been provided to third parties as
                  described in our Privacy Policy, retention of that information will be subject to those third parties’
                  policies.
                </p>

                <h3>CHANGES TO OUR PRIVACY POLICY</h3>
                <p>
                  We may update this Privacy Policy from time to time. We will place any updates to this policy on our
                  website. You may also request a paper copy of the Privacy Policy by contacting us at the email or
                  address noted below. You are bound by any changes to the Privacy Policy by using the {siteAcro}{' '}
                  Services after such changes have been posted to the website.
                </p>

                <h3>DATA PROTECTION OFFICER</h3>
                <p>
                  To communicate with our Data Protection Officer, please email{' '}
                  <TextLink href="mailto:datacomplianceofficer@reflexmedia.com" target="_blank">
                    datacomplianceofficer@reflexmedia.com
                  </TextLink>
                  .
                </p>

                <h3>CONTACT US</h3>
                <p>
                  Depending on where you live, Reflex and/or W8 Cyprus are responsible for your information. If you have
                  any questions, You can contact Reflex or W8 Cyprus via the following email{' '}
                  <TextLink href="mailto:privacyofficer@reflexmedia.com" target="_blank">
                    privacyofficer@reflexmedia.com
                  </TextLink>{' '}
                  or at the following addresses{' '}
                  <strong>
                    Reflex Media, 3250 Pepper Ln, Las Vegas, NV 89120; W8 Tech Ltd. Cyprus, IRAKLEOUS 2, FLAT/OFFICE
                    101, 2320 Nicosia, Cyprus
                  </strong>
                  .{' '}
                </p>

                <p>
                  You can also contact our Data Protection Officer at the e-mail noted above, or our Data Protection
                  Representative located in the EEA at{' '}
                  <TextLink href="http://www.datarep.com/reflex" target="_blank">
                    www.datarep.com/reflex
                  </TextLink>{' '}
                  or, or our Data Protection Representative located in the U.K. at{' '}
                  <TextLink href="http://www.datarep.uk/reflex" target="_blank">
                    www.datarep.uk/reflex
                  </TextLink>
                  .
                </p>

                <h3>APPENDIX I</h3>
                <p>Types of Reflex/W8 Cyprus Vendor Third Parties</p>
                <p>
                  <ul>
                    <li>Web server hosting</li>
                    <li>Data analysis</li>
                    <li>Marketing services</li>
                    <li>Security services</li>
                    <li>Card and payment processors</li>
                  </ul>
                </p>
                <p>
                  To receive a copy of the complete list of third parties, please contact{' '}
                  <TextLink
                    href="mailto:privacyofficer@reflexmedia.com?subject=Third%20Party%20List%20Request"
                    target="_blank"
                  >
                    privacyofficer@reflexmedia.com{' '}
                  </TextLink>
                  and use the subject line &quot;Third Party Request&quot;. The list is proprietary and confidential and
                  may not be used for any other purpose or distributed in any form; violation of this requirement is a
                  violation of the Terms of Use governing this site, and its use and is subject to the Commercial Use
                  provisions in{' '}
                  <TextLink href={`${MasterConfig.FULL_URL}/${TOSPath(userCountry)}`} rel="noopener" target="_blank">
                    the Terms of Use.
                  </TextLink>
                </p>

                <hr style={{ border: borders.DEFAULT }} />
                <p style={{ fontStyle: 'italic' }}>
                  In order to continue using {siteName}, you must accept the updated privacy policy.
                </p>
                <div style={{ padding: '1rem 0' }}>
                  <ButtonSet align={'center'}>
                    <Button buttonType="error" onClick={() => buttonEventHandler(false)} id={'acknowledgeDecline'}>
                      I decline
                    </Button>
                    <Button buttonType="primary" onClick={() => buttonEventHandler(true)} id={'acknowledgeAccept'}>
                      I accept
                    </Button>
                  </ButtonSet>
                </div>
              </div>
            </div>
          )}

          {!gdpr_acknowledged && gdpr_Step2 && (
            <div>
              <div className="inputGroup-title">
                <span>Continue receiving emails - Step 2 of 2</span>
              </div>
              <div>
                <p>
                  Opt in to make sure you get personalized emails and be the first to hear about our best promotions.
                </p>
                <div>
                  <RadioButton
                    style={{ marginBottom: 10 }}
                    size="SM"
                    value={optToReceiveFutureEmail}
                    id="continue_receiving_emails"
                    checked={optToReceiveFutureEmail}
                    onRadioChange={() => setOptToReceiveFutureEmail(true)}
                    label="Opt in to continue receiving emails"
                  />
                  <RadioButton
                    size="SM"
                    checked={optToReceiveFutureEmail === false}
                    value={optToReceiveFutureEmail}
                    id="future_emails"
                    onRadioChange={() => setOptToReceiveFutureEmail(false)}
                    label="Opt out of future emails"
                  />
                </div>
                <div style={{ padding: '1rem 0' }}>
                  <ButtonSet align={'center'}>
                    <Button
                      buttonType="primary"
                      id={'acknowledgeSave'}
                      disabled={typeof optToReceiveFutureEmail === 'undefined'}
                      onClick={() => notifyEmailUpdate(optToReceiveFutureEmail)}
                    >
                      Save changes {optToReceiveFutureEmail}
                    </Button>
                  </ButtonSet>
                </div>
              </div>
            </div>
          )}
        </PrivacyPolicyContainer>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
