import React, { ReactElement } from 'react';

interface RepliedIconProps {
  size: number;
  color: string;
}

const RepliedIcon = ({ size = 10, color = '#CCCCCC' }: RepliedIconProps): ReactElement => {
  return (
    <span>
      <svg viewBox="0 0 1024 1024" width={`${size}px`} height={`${size}px`}>
        <path
          d="M777.3,421.5C714.6,376.6,620,345,544.7,345l-98.7,0.1V124.7c0-18.5-22.5-27.8-35.6-14.7l-319,318.9 c-8.1,8.1-8.1,21.3,0,29.4l319,318.9c13.1,13.1,35.6,3.8,35.6-14.7V531.4c0,0,83.4-0.2,122-0.2c60.9,0,102.3,7.9,142.8,35.4 c55,37.3,80,94.2,85.4,141.2c7.4,85.1-19.2,119.1-62.1,167.7c-31.8,35.9,8,71.6,45.5,46.3c51.3-34.7,119-112.6,126.1-225.7 C912.3,589.4,868.2,486.7,777.3,421.5z"
          fill={color}
        />
      </svg>
    </span>
  );
};

export default RepliedIcon;
