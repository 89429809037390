/** @jsx jsx */
import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { OfferDecline, OfferNew, OfferAccept, CrossIcon, ConversationIcon, OfferCounter } from '../icons/favoriteIcons';
import { jsx } from '@emotion/core';
import {
  ButtonContainer,
  OfferNewButton,
  OfferIconContainer,
  OfferDeclineButton,
  OfferDeclineIconContainer,
  OfferAcceptButton,
  OfferAccepIconContainer,
  DismissButton,
  DismissIconContainer,
  OfferCounterButton as MessageButton,
  OfferCounterIconContainer,
  OfferCancelButton,
  OfferCancelIconContainer,
} from './FavoriteButtonStyles';

const FavoriteOfferButton = (props: any): ReactElement => {
  const { label, customLabelStyle = {}, forceLabel, contentText = null, customButtonStyle = {}, ...rest } = props;
  const actionType = props.type ? props.type : 'offer';

  return (
    <div>
      {actionType === 'cancel' && (
        <ButtonContainer aria-label="Cancel">
          <OfferCancelButton
            className="favorite-offer-cancel-button"
            {...rest}
            contentText='"Cancel"'
            type={actionType}
            aria-label="Cancel"
            title="Cancel your Date Offer"
            style={{ margin: props.isFromOffer ? '5px 0' : '5px 10px', position: 'relative' }}
          >
            <OfferCancelIconContainer>
              <CrossIcon fill="rgba(188, 32, 40, 1)" />
            </OfferCancelIconContainer>
          </OfferCancelButton>
          <label>Cancel</label>
        </ButtonContainer>
      )}
      {actionType === 'decline' && (
        <ButtonContainer aria-label="Decline">
          <OfferDeclineButton
            className="favorite-offer-decline-button"
            {...rest}
            style={{ margin: '5px 15px' }}
            contentText='"Decline"'
            type={actionType}
            aria-label="Decline"
            title="Decline a Date Offer"
          >
            <OfferDeclineIconContainer>
              <OfferDecline />
            </OfferDeclineIconContainer>
          </OfferDeclineButton>
          <label style={customLabelStyle}>Decline</label>
        </ButtonContainer>
      )}
      {actionType === 'offer' && (
        <ButtonContainer aria-label="Offer">
          <OfferNewButton
            className="favorite-offer-new-button"
            {...rest}
            contentText={contentText ?? 'Make Offer'}
            type={actionType}
            aria-label="Offer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: props.isFromOffer ? '5px 0' : '5px 15px',
              ...customButtonStyle,
            }}
            title="Make a Date Offer"
          >
            <OfferIconContainer style={{ paddingTop: 5 }}>
              <OfferNew />{' '}
            </OfferIconContainer>
          </OfferNewButton>
          {forceLabel ? (
            <label style={customLabelStyle}>{forceLabel}</label>
          ) : (
            <label style={customLabelStyle}>{label ?? contentText ?? 'Make Offer'}</label>
          )}
        </ButtonContainer>
      )}
      {actionType === 'accept' && (
        <ButtonContainer aria-label="Accept">
          <OfferAcceptButton
            className="favorite-offer-review-button"
            data-test-id="favorite-offer-review-button"
            {...rest}
            contentText='"Review"'
            type={actionType}
            aria-label="review"
            title="Review a Date Offer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: props.isFromOffer ? '5px 0' : '5px 15px',
            }}
          >
            <OfferAccepIconContainer>
              <OfferAccept />{' '}
            </OfferAccepIconContainer>
          </OfferAcceptButton>
          <label style={customLabelStyle}>{label ?? 'Review'}</label>
        </ButtonContainer>
      )}
      {actionType === 'conversation' && (
        <ButtonContainer aria-label="Message">
          <MessageButton
            className="favorite-message-button"
            {...rest}
            contentText='"Message"'
            type={actionType}
            aria-label="Message"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            title="Go to Messages"
          >
            <OfferCounterIconContainer>
              <ConversationIcon />{' '}
            </OfferCounterIconContainer>
          </MessageButton>
          <label>Message</label>
        </ButtonContainer>
      )}
      {actionType === 'dismiss' && (
        <ButtonContainer aria-label="Dismiss">
          <DismissButton
            className="favorite-dismiss-button"
            {...rest}
            contentText='"Dismiss"'
            type={actionType}
            aria-label="Dismiss"
            title="Dismiss profile"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DismissIconContainer>
              <CrossIcon fill="rgba(62, 121, 165, 1)" />{' '}
            </DismissIconContainer>
          </DismissButton>
          <label style={customLabelStyle}>Dismiss</label>
        </ButtonContainer>
      )}
      {actionType === 'counter' && (
        <ButtonContainer aria-label="Counter">
          <MessageButton
            className="favorite-counter-button"
            {...rest}
            contentText="Counter"
            type={actionType}
            aria-label="Counter"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '5px 15px',
            }}
            title="Go to Counter"
          >
            <OfferCounterIconContainer>
              <OfferCounter />{' '}
            </OfferCounterIconContainer>
          </MessageButton>
          <label style={customLabelStyle}>Counter</label>
        </ButtonContainer>
      )}
    </div>
  );
};

FavoriteOfferButton.defaultProps = {
  hasOffer: false,
  label: null,
  isFromOffer: false,
};

FavoriteOfferButton.propTypes = {
  hasOffer: PropTypes.any,
  offer: PropTypes.any,
  label: PropTypes.any,
  isFromOffer: PropTypes.bool,
  forceLabel: PropTypes.string,
};

export default FavoriteOfferButton;
