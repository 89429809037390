import React, { Component, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import Button from '../atoms/buttons/Button';
import Logo from '../atoms/HeaderOut';

const Container = styled('div')({
  backgroundColor: '#ffffff',
  height: '100%',
  textAlign: 'center',
});

export default class ErrorBoundary extends Component {
  componentDidCatch(error: any, info: any): any {
    this.setState({ hasError: true });

    Sentry.captureException(error, info);
  }

  render(): ReactElement | ReactNode {
    return (
      <Container>
        <Logo width={300} height={50} style={{ marginLeft: -40 }} />
        <h2>Oops, something went wrong!</h2>
        <p>
          This can most likely be solved by reloading the page, <br /> or you may need to logout and login again.
        </p>
        <Button
          onClick={() => (window.location.href = '/login?logged_out=true')}
          type="button"
          style={{ marginRight: 10 }}
        >
          Logout
        </Button>
        <Button onClick={() => window.location.reload()} type="button" buttonType="primary">
          Reload page
        </Button>
      </Container>
    );
  }
}
