import { VYSION_ACTIONS } from '..';
import { EventData, handleVysionEvent } from '../vysionService';
import { packagesClickVysionEvents } from './click';

export const events = {
  [VYSION_ACTIONS.CLICK]: { ...packagesClickVysionEvents },
};

export const handlePackagesVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
