import React, { FC } from 'react';
import styled from '@emotion/styled';

import { color, textColor } from '../../style';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(
  {
    fontWeight: 600,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  ({ fontcolor }) => {
    return fontcolor === 'red' ? { ...textColor.RED } : { color: color.BRAND_1 };
  }
);

const TextLink: FC = (props: any) => {
  const { color, children, linkRef, ...rest } = props;

  return (
    <StyledLink ref={linkRef} fontcolor={color} {...rest}>
      {children}
    </StyledLink>
  );
};

export default TextLink;
