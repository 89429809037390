import React, { useState, useEffect, useMemo } from 'react';
import Dashboard from '../components/pages/Dashboard/Dashboard';
import { connect } from 'react-redux';
import {
  // getActivity, // WYP-13499 Deprecate ActivityFeed
  refreshProfile as refreshUserProfile,
  lessOffersCount,
  getAccountByUserName,
  fetchPageNux,
  updateNuxGuideStates,
  updateShowTutorialOffer,
  skipNuxGuides,
} from '../actions/profileActions';
import queryString from 'query-string';
import { profileSearch, profileDashboardFavorites, profileDashboardBoosted } from '../sdk';
import { setLocalItem, getLocalItem } from '../common';
import { canConvertToJson, checkActiveSubscription } from '../utils/helpers';
import { clearSearch as clrSearch, saveFilters, storeLastSelectedSearchResult } from '../actions/searchActions';
import DefaultSearch from '../config/Search';
import { usePrevious } from '../utils/customHooks';
import {
  acceptOfferHighlight,
  counterOffer,
  createNewOffer,
  ignoreOffer,
  refreshOffers,
  sendSuggestion,
} from '../actions/offerActions';
import { clearErrors } from '../actions/commonActions';
import { displayWarnMessage } from '../actions/notificationActions';
import { favToggle, storeProfileFavChange } from '../actions/favActions';
import { enabledOfferHighlights, enableBoostedProfiles } from '../config/Master';
import { requestNotifPermission } from '../utils/serviceWorker';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import { useLocation } from 'react-router-dom';
import { DashboardDiscoveModeProvider } from '../contexts/DashboardDiscoverModeContext';
// import DashboardDiscoverMode from '../components/pages/DashboardDiscoverMode/DashboardDiscoverMode';
import { getBrowserDetails } from '../utils/helpers';
import DashboardDiscoverModeV2 from '../components/pages/DashboardDiscoverMode/DashboardDiscoverModeV2';
import useEvidently from '../hooks/useEvidently';
import {
  DISCOVER_SWIPING_MODE_AM_FEATURE_NAME,
  DiscoverSwipeModeAMVariations,
} from '../utils/evidentlyFeatures/discoverSwipingModeAM';
import LoadingBackdrop from '../atoms/loader/LoadingBackdrop';
import { ProfileMaker } from '../models/Profile';
import {
  DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
  DiscoverSwipeModeDesktopVariations,
} from '../utils/evidentlyFeatures/discoverSwipingModeDesktop';

const DashboardContainer = ({
  refreshProfile,
  // getActivities, // WYP-13499 Deprecate ActivityFeed
  clearLastSelected,
  searchIsDefault,
  clearSearch,
  profile,
  getOfferHighlights,
  offers,
  fetchPageNux,
  updateNuxState,
  skipNuxGuides,
  ...rest
}) => {
  const prevProfile = usePrevious(profile);
  const storedRecentProfiles = getLocalItem('wyp_dashboard_recent');
  const storedNewestProfiles = getLocalItem('wyp_dashboard_newest');
  const storedFavProfiles = getLocalItem('wyp_dashboard_favorites');
  const storedBoostedProfiles = getLocalItem('wyp_dashboard_boosted');
  const location = useLocation();

  const [recent, setRecent] = useState(canConvertToJson(storedRecentProfiles) ? JSON.parse(storedRecentProfiles) : []);
  const [newest, setNewest] = useState(canConvertToJson(storedNewestProfiles) ? JSON.parse(storedNewestProfiles) : []);
  const [favProfiles, setFavProfiles] = useState(
    canConvertToJson(storedFavProfiles) ? JSON.parse(storedFavProfiles) : []
  );
  const [boostedProfiles, setBoostedProfiles] = useState(
    canConvertToJson(storedBoostedProfiles) ? JSON.parse(storedBoostedProfiles) : []
  );
  const [shouldShowSwipeMode, setShouldShowSwipeMode] = useState<boolean | undefined>(undefined);

  const isMobile = useMemo(() => getBrowserDetails().isMobile, []);

  const isGenerous = useMemo(() => {
    if (typeof profile === 'undefined') {
      return undefined;
    }

    const profileModel = ProfileMaker.create(profile.profile);
    return profileModel.isGenerous();
  }, [profile]);

  const { useActiveVariation } = useEvidently();
  const discoverSwipingModeAMVariation = useActiveVariation(
    DISCOVER_SWIPING_MODE_AM_FEATURE_NAME
  ) as DiscoverSwipeModeAMVariations;

  const discoverSwipingModeDesktopVariation = useActiveVariation(
    DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME
  ) as DiscoverSwipeModeDesktopVariations;

  // logic mobile GM and AM
  useEffect(() => {
    if (typeof isGenerous === 'undefined') return;

    let retryCount = 0;
    const maxRetries = 3;

    if (isMobile && isGenerous) {
      setShouldShowSwipeMode(true);
      return;
    }

    const retryInterval = setInterval(() => {
      if (isMobile) {
        if (retryCount >= maxRetries || discoverSwipingModeDesktopVariation !== null) {
          clearInterval(retryInterval);

          if (!isGenerous && discoverSwipingModeAMVariation) {
            const isDiscoverModeAM = discoverSwipingModeAMVariation === 'DiscoverModeAM';
            setShouldShowSwipeMode(isDiscoverModeAM);
          }
        } else {
          retryCount++;
        }
      }
    }, 1000);

    return () => clearInterval(retryInterval);
  }, [isMobile, isGenerous, discoverSwipingModeAMVariation]);

  // logic for desktop GM and AM
  useEffect(() => {
    if (typeof isGenerous === 'undefined') return;

    let retryCount = 0;
    const maxRetries = 3;

    const retryInterval = setInterval(() => {
      if (!isMobile) {
        if (retryCount >= maxRetries || discoverSwipingModeDesktopVariation !== null) {
          clearInterval(retryInterval);

          if (discoverSwipingModeDesktopVariation) {
            const isDiscoverModeDesktop = discoverSwipingModeDesktopVariation === 'DiscoverModeDesktop';
            setShouldShowSwipeMode(isDiscoverModeDesktop);
          }
        } else {
          retryCount++;
        }
      }
    }, 1000);

    return () => clearInterval(retryInterval);
  }, [isMobile, discoverSwipingModeDesktopVariation, isGenerous]);

  useEffect(() => {
    if (typeof shouldShowSwipeMode === 'undefined') return;

    if (shouldShowSwipeMode) {
      refreshProfile();
    } else {
      if (enabledOfferHighlights) getOfferHighlights();

      // getActivities(); // WYP-13499 Deprecate ActivityFeed
      refreshProfile();

      initializeDashboardSearch('recent');
      initializeDashboardSearch('newest');
      initializeFavorites();
      initializeBoostedProfiles();

      checkClearSearch();
      checkShowTutorial();
      clearLastSelected();
    }

    return () => {
      // refresh Global Notification on page unload
      rest.refreshGlobalNotification();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowSwipeMode]);

  useEffect(() => {
    checkPushSubscription();
    const queryStringParsed = queryString.parse(location.search);

    if (queryStringParsed.email_token && profile && prevProfile && profile.hash_id !== prevProfile.hash_id) {
      // getActivities(); // WYP-13499 Deprecate ActivityFeed
      initializeDashboardSearch('recent');
      initializeDashboardSearch('newest');
      initializeFavorites();
      initializeBoostedProfiles();
    }
  }, [profile, location.search, prevProfile]);

  const checkClearSearch = () => {
    clearSearch(!searchIsDefault);
  };

  const checkShowTutorial = () => {
    const isAutomationBypass = getLocalItem('wyp_automation_testing');
    if (isAutomationBypass && isAutomationBypass === 'true') {
      rest.updateOfferTutorialState();
    }
  };

  const checkPushSubscription = () => {
    checkActiveSubscription() && requestNotifPermission();
  };

  const initializeFavorites = async () => {
    try {
      const { data } = await profileDashboardFavorites(8);

      const profiles = data.data;
      const newFavProfiles: Array<any> = [];

      // cleanup profiles
      profiles.map(fProfile => {
        return newFavProfiles.push({
          age: fProfile.other_account.data.profile.data.age,
          avatar: fProfile.other_account.data.profile.data.avatar,
          city: fProfile.other_account.data.profile.data.city,
          username: fProfile.other_account.data.username,
          hash_id: fProfile.other_account.data.profile.data.hash_id,
          approved_public_photos_count: fProfile.other_account.data.profile.data.approved_public_photos_count,
          private_photos_count: fProfile.other_account.data.profile.data.private_photos_count,
          online: fProfile.other_account.data.profile.data.online,
          id_verified: fProfile.other_account.data.profile.data.id_verified,
        });
      });
      setLocalItem('wyp_dashboard_favorites', JSON.stringify(newFavProfiles));
      setFavProfiles(newFavProfiles);
    } catch (e) {
      console.error(e);
    }
  };

  const initializeBoostedProfiles = async () => {
    if (!enableBoostedProfiles) return;

    try {
      const { data } = await profileDashboardBoosted(4);
      const boostedData = data.data;
      const newBoostedProfiles: Array<any> = [];

      // cleanup profiles
      boostedData.map(bProfile => {
        newBoostedProfiles.push({
          age: bProfile.age,
          avatar: bProfile.avatar,
          city: bProfile.city,
          username: bProfile.account.data.username,
          hash_id: bProfile.hash_id,
          approved_public_photos_count: bProfile.approved_public_photos_count,
          private_photos_count: bProfile.private_photos_count,
          online: bProfile.online,
          id_verified: bProfile.id_verified,
        });
      });
      setLocalItem('wyp_dashboard_boosted', JSON.stringify(newBoostedProfiles));
      setBoostedProfiles(newBoostedProfiles);
    } catch (e) {
      console.error(e);
    }
  };

  const initializeDashboardSearch = async order => {
    try {
      const { data } = await profileSearch({
        order,
        flexibility: 'distance',
        min_results: 8,
        per_page: 8,
        age_min: 1,
        age_max: 90,
        height_min: 150,
        height_max: 215,
      });

      // capture API or microService data: profiles.data || profiles
      const profiles = data.data;
      const profilesData = profiles.data || profiles;

      setLocalItem(`wyp_dashboard_${order}`, JSON.stringify(profilesData));

      switch (order) {
        case 'recent':
          setRecent(profilesData);
          break;
        case 'newest':
          setNewest(profilesData);
          break;
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (typeof shouldShowSwipeMode === 'undefined') {
    return <LoadingBackdrop active={true} showLoadingBg={false} />;
  }

  return (
    <>
      {shouldShowSwipeMode ? (
        <DashboardDiscoveModeProvider>
          <DashboardDiscoverModeV2 {...rest} profile={profile} />
        </DashboardDiscoveModeProvider>
      ) : (
        <Dashboard
          {...rest}
          recent={recent}
          newest={newest}
          profile={profile}
          offers={offers}
          favorites={favProfiles}
          featuredProfiles={boostedProfiles}
          fetchPageNux={fetchPageNux}
          updatePageNux={updateNuxState}
          skipNuxGuides={skipNuxGuides}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.profile,
    // activity: state.dashboard.activity, // WYP-13499 Deprecate ActivityFeed
    recentSearchResults:
      state.search.preservedResult &&
      state.search.preservedResult.profile &&
      state.search.preservedResult.profile.length > 0
        ? state.search.preservedResult.profile
        : state.search.profile,
    evidently: state.settings?.evidently,
    searchIsDefault: Boolean(JSON.stringify(state.search.filters) === JSON.stringify(DefaultSearch.filters)),
    offers: state.offers,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    /*
    // WYP-13499 Deprecate ActivityFeed
    getActivities: () => {
      // retrieves the last X activity items.
      dispatch(getActivity(12));
    },
    */
    refreshProfile: () => {
      dispatch(refreshUserProfile());
    },
    clearSearch: saveSearch => {
      if (!saveSearch) {
        dispatch(clrSearch());
      }
    },
    clearLastSelected: () => {
      dispatch(storeLastSelectedSearchResult({ id: null }));
    },
    saveFilters: filters => {
      dispatch(saveFilters({ formData: { ...filters } }));
      dispatch(refreshUserProfile());
    },
    getOfferHighlights: () => {
      dispatch(refreshOffers('highlights', 'recent', true, 1));
    },
    acceptOffer(username, message) {
      dispatch(acceptOfferHighlight(username, message));
    },
    counterOffer: formData => {
      return dispatch(counterOffer(formData, true, undefined));
    },
    ignoreOffer(offerObject = {}) {
      dispatch(ignoreOffer(offerObject.other_account.data.hash_id, true));
      dispatch(lessOffersCount());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    createNewOffer: formData => {
      return dispatch(createNewOffer(formData));
    },
    sendSuggestion: username => {
      return dispatch(sendSuggestion(username));
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    toggleFav: hashId => {
      // notify extprofile store
      dispatch(storeProfileFavChange());

      // notify api.
      dispatch(favToggle(hashId));
    },
    checkProfile: username => {
      return dispatch(getAccountByUserName(username));
    },
    getNextOfferQueue: () => {
      dispatch(refreshOffers('highlights', 'recent', true, 1));
    },
    fetchPageNux: curRoute => {
      dispatch(fetchPageNux(curRoute));
    },
    updateNuxState: nuxUpdates => {
      dispatch(updateNuxGuideStates(nuxUpdates));
    },
    skipNuxGuides: () => {
      return dispatch(skipNuxGuides());
    },
    updateOfferTutorialState: () => {
      dispatch(updateShowTutorialOffer(false));
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
