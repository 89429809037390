/**
 * @Author: zachalam
 * @Date:   2017-03-09T09:07:59-08:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-09T09:07:59-08:00
 */

import * as sdk from '../sdk';
import { storeErrors, setLoading, clearErrors } from './commonActions';
import { refreshAccount, makePremium } from './profileActions';
import { setLocalItem, getLocalItem } from '../common';
import { displayFailMessage } from './notificationActions';
import { lazilyEvaluateVal, logException } from '../utils/helpers';
import * as BillingSDK from '../sdk/BillingSDK';
import { clearDDCData, saveDataCollector } from './ddcActions';
import { IDDCData } from '../reducers/types/DDC.d';
import { getRecurringPayment, removeRecurringSettings, storeRecurringSettings } from './paymentSettingsActions';
import { getUniqueDevice } from '../utils/ipQualityScore';
import { AxiosPromise } from 'axios';
import { APAY_PAYMENT_CHANNEL, APAY_PAYMENT_TYPE } from '../config/apay';
import { NavigateFunction } from 'react-router-dom';

export const storeBillingPackages = (payload: any): any => {
  return {
    type: 'STORE_PACKAGES',
    payload,
  };
};

export const storeIDVPackages = (payload: any): any => {
  return {
    type: 'STORE_IDV_PACKAGES',
    payload,
  };
};

export const storeKountDetails = (payload: any): any => {
  return {
    type: 'STORE_KOUNT_DETAILS',
    payload,
  };
};

export const storeBillingDescriptors = (payload: any): any => {
  return {
    type: 'STORE_DESCRIPTORS',
    payload,
  };
};

export const storePaymentToken = (payload: any): any => {
  return {
    type: 'STORE_PAYMENT_TOKEN',
    payload,
  };
};

export const removePaymentToken = (): any => {
  return {
    type: 'REMOVE_PAYMENT_TOKEN',
  };
};

export const storeBillingPayments = (payload: any): any => {
  return {
    type: 'STORE_PAYMENTS',
    payload,
  };
};

export const clearBillingPayments = (payload: any): any => {
  return {
    type: 'CLEAR_PAYMENTS',
    payload,
  };
};

export const setIsProcessing = (): any => {
  return {
    type: 'SET_PAYMENT_PROCESSING',
  };
};

export const killIsProcessing = (): any => {
  return {
    type: 'KILL_PAYMENT_PROCESSING',
  };
};

export const getBillingPackages = (order: any, promoCode?: string, isPreJoin?: boolean): any => {
  return (dispatch, getState) => {
    return sdk
      .getBillingPackages(getState().auth.jwToken, order, promoCode, isPreJoin)
      .then(response => {
        dispatch(storeBillingPackages(response.data.data));

        return response.data;
      })
      .catch(() => {
        // error.
      });
  };
};

export const getIDVPackages = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getIDVPackages(getState().auth.jwToken)
      .then(response => {
        dispatch(storeIDVPackages(response.data.data));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const getKountDetails = (): any => {
  return (dispatch, getState) => {
    return sdk
      .getKountDetails(getState().auth.jwToken)
      .then(response => {
        dispatch(storeKountDetails(response.data.data));
      })
      .catch(() => {
        // error.
      });
  };
};

export const getBillingDescriptors = (): any => {
  return dispatch => {
    return sdk.getBillingDescriptors().then(response => {
      dispatch(storeBillingDescriptors(response.data));
    });
  };
};

const getDDCReferenceIds = (fingerPrints: IDDCData[]) => {
  const ddcReferenceIds = {};

  fingerPrints.forEach((data: IDDCData) => {
    if (data.ddcReferenceId) {
      ddcReferenceIds[data.gateway] = data.ddcReferenceId;
    }
  });
  return ddcReferenceIds;
};

export const postBillingPayment = (formData: any, navigate: NavigateFunction): any => {
  return (dispatch: any, getState: any) => {
    const isIDV = formData.use2pay_package_id === 'idv_last_minute';
    const sdkPayment = isIDV ? sdk.postIDVPayment : sdk.postBillingPayment;

    dispatch(setIsProcessing());
    dispatch(clearErrors());

    const deviceIdentifier = getUniqueDevice();

    formData.ipqs_device_id = deviceIdentifier.device_id;
    formData.ipqs_request_id = deviceIdentifier.request_id;

    if (formData.payment_channel === 'sofort') {
      return processSofortPayment(formData, sdkPayment, getState, dispatch, navigate);
    }

    const doCharge = (paymentPayload: any): any => {
      return sdkPayment(getState().auth.jwToken, paymentPayload)
        .then(response => {
          // check if payment is pending...
          const resp = response.data.data;
          if (resp.status === 'pending') {
            // payment pending - do redirect
            // do not hide loader here - browser will reset.
            window.location = resp.url;
          } else {
            dispatch(refreshAccount()).then(() => {
              // payment is great - hide Loader
              // make premium, remove loading bar, redirect to success.
              dispatch(makePremium());
              // redirect to billing/success
              isIDV ? navigate('/verification') : navigate('/billing/success');
            });

            if (paymentPayload.recurring) {
              dispatch(getRecurringPayment());
            }
          }

          dispatch(killIsProcessing());
        })
        .catch(error => {
          dispatch(killIsProcessing());
          const msg = 'We were unable to process your payment. Click here to contact support.';
          dispatch(storeErrors({ ...error.response.data, invalid: msg }));

          const errorData = error.response.data;

          if (
            (errorData.data !== undefined &&
              (typeof errorData.data.error === 'undefined' ||
                typeof errorData.data.error_code === 'undefined' ||
                errorData.data.error_code === 'BILL_GENERIC_ERROR')) ||
            (errorData.errors !== undefined &&
              errorData.errors[0] !== undefined &&
              errorData.errors[0].code === 40009004)
          ) {
            console.error(errorData.data.error);
          }

          const declineCodes = [
            'BILL_DECLINED',
            'BILL_RECENTLY_DECLINED_ERROR',
            'BILL_CVV2_REQUIRED',
            'BILL_REPEATED_CARD_ERROR',
          ];

          if (errorData.data === undefined || declineCodes.includes(errorData.data.error_code)) {
            return false;
          }

          dispatch(
            displayFailMessage({
              info: errorData.data.error,
            })
          );
        });
    };

    const getPmtBin = (formData: any, ddcData: any): any => {
      let pmtBin;

      if (ddcData && ddcData instanceof Array) {
        pmtBin = ddcData
          .map(({ PMT_BIN }) => {
            return PMT_BIN;
          })
          .filter(PMT_BIN => PMT_BIN !== undefined);
      } else {
        pmtBin = ddcData.PMT_BIN;
      }

      return formData.use2pay_card_number
        ? formData.use2pay_card_number
            .split(' ')
            .join('')
            .substring(0, 6)
        : (pmtBin && pmtBin[0]) || null;
    };

    const skipDataCollector =
      formData.payment_channel === APAY_PAYMENT_CHANNEL || formData.paymentType === APAY_PAYMENT_TYPE;

    delete formData.paymentType;

    if (skipDataCollector) {
      return doCharge(formData);
    }

    return sdk
      .postDDC(getState().auth.jwToken, formData)
      .then(response => {
        const responseData = response.data;

        dispatch(saveDataCollector({ ddcResponse: responseData, bin: getPmtBin(formData, responseData) }));

        let retryCounter = 0;
        const interValId = setInterval(() => {
          const { isAllFingerPrintSaved, ddcData, initialIDs: initialDDCReferenceIds } = getState().ddc;
          const ddcReferenceId = getDDCReferenceIds(ddcData);

          retryCounter += 1;

          if (retryCounter >= 30) {
            // if DDC takes a long time, continue with normal payment
            console.warn('DDC response is taking too long, proceeding to payment');
            clearInterval(interValId);
            return doCharge({
              ...formData,
              ddcReferenceId: ddcReferenceId,
              pmtBin: getPmtBin(formData, responseData),
            });
          }

          if (!isAllFingerPrintSaved && retryCounter >= 30) {
            logException(
              'DDC: DDC Incomplete',
              {
                step: 'Retry Exceeded',
                duration: retryCounter / 2,
                ddcData,
                initialDDCReferenceIds,
                ddcDataString: JSON.stringify(ddcData),
                currentDDCReferenceId: ddcReferenceId,
                isAllFingerPrintSaved,
              },
              'error',
              getState().profile
            );
          }

          if (isAllFingerPrintSaved) {
            let hasMissingDDC = false;
            Object.keys(ddcReferenceId).forEach(key => {
              if (!ddcReferenceId[key]) {
                hasMissingDDC = true;
              }
            });

            if (hasMissingDDC) {
              logException(
                'DDC: DDC Incomplete - Missing',
                {
                  step: 'Finger Print Done',
                  duration: retryCounter / 2,
                  ddcData,
                  initialDDCReferenceIds,
                  ddcDataString: JSON.stringify(ddcData),
                  currentDDCReferenceId: ddcReferenceId,
                  isAllFingerPrintSaved,
                },
                'error',
                getState().profile
              );
            }

            clearInterval(interValId);
            dispatch(clearDDCData());

            return doCharge({
              ...formData,
              ddcReferenceId: ddcReferenceId,
              pmtBin: getPmtBin(formData, responseData),
            });
          }

          return false;
        }, 500);
      })
      .catch(e => {
        console.error('DDC Error', e);
        dispatch(clearDDCData());
        return doCharge(formData);
      });
  };
};

export const processSofortPayment = (formData: any, sdkPayment: any, getState: any, dispatch: any, navigate: any): AxiosPromise => {
  const isIDV = formData.use2pay_package_id === 'idv_last_minute';

  return sdkPayment(getState().auth.jwToken, formData)
    .then(response => {
      const resp = response.data.data;
      if (resp.status === 'pending') {
        window.location = resp.url;
      } else {
        dispatch(refreshAccount()).then(() => {
          dispatch(makePremium());
          isIDV ? navigate('/verification') : navigate('/billing/success');
        });
      }
    })
    .catch(error => {
      const isSofortCountryError =
        !!error.response.data.use2pay_billing_country &&
        error.response.data.use2pay_billing_country[0] === 'Use2pay billing country is not a valid option.';
      const msg = isSofortCountryError
        ? 'Billing country is not a valid option.'
        : 'We were unable to process your payment. Click here to contact support.';
      dispatch(storeErrors({ ...error.response.data, invalid: msg }));

      const errorData = error.response.data;

      if (
        typeof errorData.data.error === 'undefined' ||
        typeof errorData.data.error_code === 'undefined' ||
        errorData.data.error_code === 'BILL_GENERIC_ERROR' ||
        (errorData.errors !== undefined && errorData.errors[0] !== undefined && errorData.errors[0].code === 40009004)
      ) {
        console.error(errorData.data.error);
      }

      if (errorData.data.error_code === 'BILL_DECLINED') {
        return;
      }

      return dispatch(
        displayFailMessage({
          info: isSofortCountryError ? 'Billing country is not a valid option.' : errorData.data.error,
        })
      );
    });
};

export const getBillingPayments = (): any => {
  return (dispatch, getState) => {
    return sdk.getBillingPayments(getState().auth.jwToken).then(response => {
      dispatch(storeBillingPayments(response.data.data));
    });
  };
};

export const getPaymentToken = (data?: any): any => {
  if (data && !data.applepaySupported) {
    delete data.applepaySupported;
  }

  return dispatch => {
    return sdk
      .getPaymentToken(data)
      .then(response => {
        dispatch(storePaymentToken(response.data));

        // check if payment token has data
        // validate from code
        // user default country

        if (response?.data?.data) {
          const responseData = response.data.data.filter(data => data.recurring);

          if (responseData.length > 0) {
            const { recurring } = responseData[0];

            dispatch(storeRecurringSettings(recurring));
          } else {
            dispatch(removeRecurringSettings());
          }
        }
      })
      .catch(() => {
        //
      });
  };
};

export const deleteSavedToken = (tokenId: any, withLoading = true): any => {
  return (dispatch, getState) => {
    return sdk.deleteSavedPaymentToken(getState().auth.jwToken, tokenId).then(() => {
      dispatch(removePaymentToken());
      dispatch(removeRecurringSettings());
      if (withLoading) {
        dispatch(setLoading());
      }
    });
  };
};

export const postVisitLogs = (type: any, step: any, isSuccessPage = false): any => {
  return () => {
    const visitLogs = JSON.parse(getLocalItem('wyp_payment_visit'));
    const lastIndex = lazilyEvaluateVal(visitLogs, 1, false, 'steps') ? visitLogs.steps.length - 1 : 0;
    const hasSteps = lazilyEvaluateVal(visitLogs, 2, false, 'steps', 'length') > 0;
    const hasType = lazilyEvaluateVal(visitLogs, 1, false, 'type');
    let formData = {
      steps: undefined,
      type: undefined,
    };

    if (
      hasType &&
      visitLogs.type === type &&
      hasSteps &&
      visitLogs.steps[lastIndex] &&
      visitLogs.steps[lastIndex] === step
    )
      return Promise.resolve();

    if (isSuccessPage) {
      type = visitLogs && visitLogs.type;
    }

    if (isSuccessPage && hasType && visitLogs.type === 'billing') {
      step = 2;
    }

    if (hasType && visitLogs.type === type && hasSteps && step > visitLogs.steps[lastIndex]) {
      visitLogs.steps.push(step);
      formData = visitLogs;
    }

    if (
      (!hasType && !hasSteps) ||
      (hasType && visitLogs.type !== type) ||
      (hasType && visitLogs.type === type && hasSteps && step < visitLogs.steps[lastIndex])
    ) {
      formData = {
        steps: [step],
        type: type,
      };
    }
    const storageData = JSON.stringify(formData);
    formData.steps = JSON.stringify(Object.assign({}, formData.steps));

    return sdk
      .logPaymentVisit(formData)
      .then(() => {
        setLocalItem('wyp_payment_visit', storageData);
      })
      .catch(() => {
        // do nothing
      });
  };
};

export const checkChargeVat = (country: any, pacakgeId: any, promoCode: any): any => {
  return BillingSDK.checkChargeVat({ country, package: pacakgeId, promo_code: promoCode });
};

export const calculateChargeAmount = (country: any, pacakgeId: any, promoCode: any, paymentChannel: any): any => {
  return BillingSDK.calculateChargeAmount({
    country,
    package: pacakgeId,
    promo_code: promoCode,
    payment_channel: paymentChannel,
  });
};

export const getApplePayCredential = (data: any): any => {
  return dispatch => {
    return BillingSDK.getApplePayCredential(data).then(() => {
      dispatch(removePaymentToken());
      dispatch(removeRecurringSettings());
    });
  };
};
