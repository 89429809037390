export type ButtonSizes = 'regular' | 'small' | 'x-small';
export type ButtonColors = 'red' | 'blue' | 'gray';

type SizeStyle = { [key in ButtonSizes]: number | string };

export type ButtonStyle = {
  padding: SizeStyle;
  borderRadius: number | string;
  color: string | { [key in ButtonColors]: string };
  borderColor: string | { [key in ButtonColors]: string };
  backgroundColor: string | { [key in ButtonColors]: string };
  fontSize: SizeStyle;
  width: SizeStyle;
  height: SizeStyle;
  fontWeight: SizeStyle;
  [key: string]: unknown;
};

export type ButtonHoverStyle = {
  hover: Partial<ButtonStyle>;
};

export type ButtonDisabledStyle = {
  disabled: Partial<ButtonStyle>;
};

export type ButtonActiveStyle = {
  active: Partial<ButtonStyle>;
};

export type ButtonStyleReturnType = {
  light: ButtonStyle & ButtonHoverStyle & ButtonDisabledStyle & ButtonActiveStyle;
  dark: ButtonStyle & ButtonHoverStyle & ButtonDisabledStyle & ButtonActiveStyle;
};

export const buttonCommonStyles = {
  borderRadius: '90px',
  lineHeight: '6px',
  padding: {
    regular: '16px 32px',
    small: '12px 22px',
    'x-small': '8px 18px',
  },
  fontSize: {
    regular: '18px',
    small: '14px',
    'x-small': '13px',
  },
  width: {
    regular: '207px',
    small: '154px',
    'x-small': '138px',
  },
  height: {
    regular: '48px',
    small: '40px',
    'x-small': '32px',
  },
  fontWeight: {
    regular: 700,
    small: 600,
    'x-small': 600,
  },
  borderWidth: {
    regular: '2px',
    small: '1px',
    'x-small': '1px',
  },
};
