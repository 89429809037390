/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { connectScreenSize } from 'react-screen-size';
import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import queryString from 'query-string';
import { keyframes } from '@emotion/react';

/* Profile Actions Bar */
import Button from '../../../atoms/buttons/Button';
import BlankSlate from '../../blocks/BlankSlate';
import NuxGuide from '../../blocks/NuxGuide';
import { css } from 'glamor';

/* OfferComponent */
import OfferCreate from '../Offers/OfferCreate';

/* sidebar components */
import InterestedIn from './InterestedIn';

import BlockOrReport from '../../../atoms/BlockOrReport';
import ExperimentInjector from '../../../modules/ExperimentInjector';
import { displayUserLocation, tsSince, renderAvatar, getOfferType } from '../../../common';
import {
  scrollToTop,
  tabletWidth,
  getBrowserDetails,
  isDarkMode,
  removeForbiddenCharacters,
} from '../../../utils/helpers';

/* right side bar components */
import PersonalInfo from './PersonalInfo';
import Container from '../../blocks/Container';
import Panel from '../../../atoms/panels/Panel';
import Photos from './Photos';
import AboutMe from './AboutMe';
import FirstDate from './FirstDate';

import PhotoLightbox from '../Photos/PhotoLightbox';
import LoadingMsg from '../../common/LoadingMsg';
import ConfirmationModal from '../../../modules/modals/ConfirmationModal';
import CompleteProfileModalV2 from '../../../modules/modals/CompleteProfileModalV2';
import OfferPanelV2 from '../../../atoms/profile/OfferPanelV2';
import PurchaseIDV from '../../../modules/PurchaseIDV';

import { backgroundTheme, dimensions, spacing, textColor } from '../../../style';
import Avatar from '../../../atoms/Avatar';
import TextArea from '../../../atoms/TextArea';
import { CloseOutlined } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import {
  sendClose,
  displayOfferModal,
  counterModal,
  counterModalClose,
  profileMakeOffer,
} from '../../../actions/profileTrackAction';
import { vysionTrack } from '../../../utils/vysion/vysionService';
import { nuxEnabled, s3BucketDirect } from '../../../config/Master';
import Text from '../../common/Text';
import Gifts from './Gifts';
import _sortBy from 'lodash/sortBy';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import _isEmpty from 'lodash/isEmpty';
import useMixPanel from '../../../hooks/useMixPanel';
import useModal from '../../../hooks/useModal';
import HeaderOut from '../../../atoms/HeaderOut';
import ButtonSet from '../../blocks/ButtonSet';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { useLocation, useNavigate } from 'react-router-dom';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';
import { trackEvidentlyMetrics } from '../../../sdk/CommonSDK';
import {
  DEFAULT_REASON,
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_NO_PROFILE_FAVORITE_KEYS,
  getFeatureDetails,
  isEvidentlyExperimentLive,
} from '../../../constants/evidently';
import { connect } from 'react-redux';
import LoadingBackdrop from '../../../atoms/loader/LoadingBackdrop';
import OpeningOffer from './OpeningOffer';
import Optional from '../../../atoms/Optional';
import useNotificationBanner from '../../../hooks/useNotificationBanner';
import FavoritedProfileNotificationBanner from '../../../atoms/profile/FavoritedProfileNotificationBanner';
import { useProfile } from '../../../contexts/ProfileContext';
import OtherProfileCover from '../../../modules/OtherProfileCover';
import MyProfileCover from '../../../modules/MyProfileCover';
import ArrowBackIcon from '../../../atoms/icons/ArrowBackIcon';
import { ProfileMaker, ProfileObject } from '../../../models/Profile';
import { trackDiscoverSwipingModeDesktopMetrics } from '../../../utils/evidentlyFeatures/discoverSwipingModeDesktop';
import { mixpanelTrackProfileFavorited } from '../../../utils/mixpanel/profileFavorited';

const MainPanel = styled(Panel)(
  {
    marginBottom: 15,
    [dimensions.SCREEN_MAX_LG]: {
      boxShadow: 'none',
      borderRadius: 0,
      border: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },

    [dimensions.SCREEN_MAX_MD_2]: {
      marginBottom: 0,
      overflow: 'hidden',
    },

    [dimensions.SCREEN_MAX_SM]: {
      padding: 0,
      border: 0,
    },
  },
  ({ darkMode }) => ({
    border: darkMode ? 'none' : '1px solid #DAE1E9',
  })
);

const WrappedWithMobile = styled('div')({
  display: 'none',
  [dimensions.SCREEN_MAX_MD_2]: {
    display: 'block',
  },
});

const WrappedWithDesktop = styled('div')({
  display: 'block',
  [dimensions.SCREEN_MAX_MD_2]: {
    display: 'none',
  },
});

const ProfileHeading = styled('div')({
  borderRadius: 0,
  borderBottomWidth: 1,
  marginBottom: 0,

  [dimensions.SCREEN_MAX_LG]: {
    marginBottom: 0,
  },

  [dimensions.SCREEN_MAX_SM]: {
    padding: 0,
    border: 0,
  },
});

const ProfileGallery = styled(Panel)({
  marginTop: 15,
  marginBottom: 15,
  padding: '0 15px 15px',
  [dimensions.SCREEN_MAX_LG]: {
    marginTop: 0,
    boxShadow: 'none',
    borderRadius: 0,
    border: 'none',
  },
});

const LeftContent = styled('div')(
  {
    flex: 1,
  },
  ({ isLightboxOpen }) => ({
    [dimensions.SCREEN_MAX_XS]: {
      display: 'block',
      position: !isLightboxOpen ? 'unset' : 'absolute',
      top: !isLightboxOpen ? '-60px' : 0,
      left: 0,
      zIndex: 1000,
      width: '100%',
      backgroundColor: backgroundTheme.block,
    },
  })
);

const LeftContentMainPanel = styled('div')({
  [dimensions.SCREEN_MAX_LG]: {
    backgroundColor: backgroundTheme.block2,
  },
});

const LeftContentWrapper = styled('div')({
  [dimensions.SCREEN_MAX_LG]: {
    marginLeft: isDarkMode() ? 0 : 15,
    marginRight: isDarkMode() ? 0 : 15,
  },
});

const RightContent = styled('div')(
  {
    marginLeft: isDarkMode() ? 0 : 15,
    marginRight: isDarkMode() ? 0 : 15,
    [dimensions.SCREEN_MAX_MD]: {
      marginTop: 16,
    },

    [dimensions.SCREEN_MIN_LG]: {
      flexBasis: 356,
    },
  },
  ({ isNonTablet }) =>
    isNonTablet && {
      flex: '0 0 280px',
      marginLeft: 15,
      marginRight: 0,
    }
);

const OverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
  backgroundColor: 'rgba(0,0,0,0.65)',
  overflow: 'auto',

  [dimensions.SCREEN_MAX_SM]: {
    backgroundColor: backgroundTheme.block,
  },
};

const animateSlideUp = keyframes`
  from {
    top: 24px;
  }
  80% {
    top: 24px;
  }
  to {
    top: 0px;
  }
`;

const ModalStyles = {
  backgroundColor: backgroundTheme.block,
  ...textColor.DARKER,
  position: 'relative',
  boxSizing: 'border-box',
  outline: 'none',
  maxHeight: '80vh',
  overflow: 'auto',
  ':focus': {
    outline: 'none',
  },

  [dimensions.SCREEN_MAX_SM]: {
    height: '100%',
  },

  [dimensions.SCREEN_MIN_SM]: {
    padding: 22,
    borderRadius: 10,
    position: 'relative',
    top: 80,
    maxWidth: 540,
    margin: '32px auto',
  },
};

const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  width: '48px',
  height: '48px',
  position: 'absolute',
  top: 0,
  right: 0,
});

const StyledAvatar = styled(Avatar)({
  margin: '0 auto',

  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const CloseProfileContainer = styled('div')(
  {
    display: 'none !important',

    [dimensions.SCREEN_MAX_MD_2]: {
      display: 'block !important',
      position: 'fixed',
      top: 10,
      left: 10,
      zIndex: 1000,
      padding: '16px',
      svg: {
        width: 40,
        height: 40,
      },
    },

    [dimensions.SCREEN_MAX_XS]: {
      top: -1,
      left: 0,
      svg: {
        width: 28,
        height: 28,
      },
    },
  },
  ({ hasStatus }) => ({
    [dimensions.SCREEN_MAX_XS]: {
      top: hasStatus ? 24 : -1,
      left: 0,
      animation: hasStatus ? `${animateSlideUp} 3.5s ease-in-out forwards` : 'none',
    },
  })
);

const BlockedContainer = styled('div')({
  background: '#F8E9EA',
  border: '2px solid #BC2028',
  borderRadius: 4,
  padding: '40px 28px',
  textAlign: 'center',
});

const IdVerificationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 15,
  marginRight: 15,
  [dimensions.SCREEN_MAX_LG]: {
    marginTop: 15,
  },
});

const StyledText = styled(Text)({
  ...textColor.RED,
  fontWeight: 700,
  fontSize: 16,
});

const closeButton = {
  fontSize: 32,
  color: backgroundTheme.iconFill,
};

const CloseProfileHandler = styled('div')({
  float: 'left',
  cursor: 'pointer',
});

const getVideoSrc = media => {
  if (media.type === 'video') {
    return media.urls.media;
  }

  return null;
};

const getMediaThumbnail = gallery => {
  if (gallery.type === 'photo') {
    return gallery.urls.profile;
  }

  return gallery.urls.thumbnail ?? `${s3BucketDirect}thumbs/private_eye.png`;
};

const getPropsValue = (value, properties) => {
  switch (properties.length) {
    case 1:
      return value[properties[0]];
    case 2:
      return value[properties[0]][properties[1]];
    case 3:
      return value[properties[0]][properties[1]][properties[2]];
    default:
      return;
  }
};

const getMyProfileData = (myprofile, props) => {
  const properties = props.split('.');
  return myprofile && myprofile.profile && getPropsValue(myprofile.profile, properties);
};

const getMyOfferData = (myOffer, props) => {
  const properties = props.split('.');
  return myOffer && getPropsValue(myOffer, properties);
};

const getPhotoLength = photos => {
  return photos && photos.data.length;
};

const getOtherPhotoLength = photoListCover => {
  return photoListCover && photoListCover.length;
};

const verifyURLParams = (queryStringParsed, props) => {
  return Boolean(queryStringParsed[props]) || false;
};

const ternaryFunc = (evaluator, result1, result2) => {
  return evaluator ? result1 : result2;
};

const isNotTablet = () => !tabletWidth && !getBrowserDetails().isMobile;

const isAttractive = acctProfile => acctProfile && acctProfile.account_type === 'Attractive';

const getAcctProfile = account => account && account.profile && account.profile.data;

const verifyIsOtherProfile = (acctProfile, myprofile) => acctProfile && myprofile.hash_id !== acctProfile.hash_id;

const verifyIsMyProfile = (acctProfile, myprofile) => acctProfile && myprofile.hash_id === acctProfile.hash_id;

const generatePhotoList = (account, isSlider = false) => {
  const photoList = [];
  const privatePhotoList = [];

  !_isEmpty(account) &&
    account.photo &&
    account.photo.data.map(photo => {
      // return if photo has missing or incomplete data
      if (!photo.hash_id) return;

      if (photo.id === null) {
        return privatePhotoList.push({
          order: parseInt(removeForbiddenCharacters(photo?.created_dt?.date)),
          src: `${s3BucketDirect}resized/private_eye.png`,
          thumbnail: `${s3BucketDirect}thumbs/private_eye.png`,
          private: true,
          is_avatar: false,
          privateReal: photo?.private,
          type: photo.type,
          rank: 2,
          meta: {
            mediaUrl: getVideoSrc(photo),
            status: photo.status,
          },
        });
      }

      return photoList.push({
        order: parseInt(removeForbiddenCharacters(photo?.created_dt?.date)),
        src: isSlider ? photo.urls : getMediaSrc(photo),
        thumbnail:
          photo?.private && photo.type === 'photo'
            ? `${s3BucketDirect}thumbs/private_eye.png`
            : getMediaThumbnail(photo),
        private: false,
        is_avatar: photo.is_avatar,
        privateReal: photo?.private,
        type: photo.type,
        rank: photo.is_avatar ? 0 : photo?.private ? 2 : 1,
        meta: {
          mediaUrl: getVideoSrc(photo),
          status: photo.status,
        },
      });
    });

  //If user has any amount of private photos, adding only one private picture object to array
  if (privatePhotoList.length > 0) {
    privatePhotoList[0].total = privatePhotoList.length;
    photoList.push(privatePhotoList[0]);
  }

  return [_sortBy(photoList, ['rank', 'order']), _sortBy(privatePhotoList, ['rank', 'order'])];
};

const getMediaSrc = media => {
  if (media.type === 'photo') {
    return renderAvatar(media.urls);
  }

  return media.urls.thumbnail ?? `${s3BucketDirect}thumbs/private_eye.png`;
};

const Profile = ({
  sendSuggestion,
  profile,
  profile: account,
  myprofile,
  showWarn,
  onboarded,
  toggleBlock,
  acceptOffer: acceptOfferProps,
  toggleFav,
  photos,
  portrait,
  errors,
  updateProfileCover,
  clearEachIndividualError,
  mapFields,
  screenSizeIsMobile,
  clearNotificationMessage,
  refreshAndClearErrors,
  uploadPhoto,
  isUploading,
  displayMessage,
  setPrivacy,
  makeDefault,
  deletePhoto,
  hasPublicPhotos,
  singlePublicLeft,
  hasPublicApprovedPhotos,
  updateProfileDescription,
  updateProfileSeeking,
  updateProfileAbout,
  updateProfileOpeningOffer,
  updateProfileInterest,
  isProcessing,
  clearErrors,
  createNewOffer: createNewOfferProps,
  counterOffer,
  handleUnlockOffer,
  fetchPageNux,
  updateNuxState,
  skipNuxGuides,
  updateOfferTutorialState,
  ignoreOffer,
  updateOffer,
  uploadMedia,
  deleteMedia,
  setMediaPrivacy,
  updateShowWishlist,
  updateShowGiftSent,
  triggerUnlockMessage,
  bulkTogglePrivacy,
  bulkDeleteMedia,
  settings,
  prevPage,
  resetDiscoverModeAccountsAction,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { gallerySwiper } = useProfile();

  const queryStringParsed = queryString.parse(location.search);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const [allowZoom] = useState(true);
  const [justSuggested, setJustSuggested] = useState(false);
  const [isCounter, setIsCounter] = useState(verifyURLParams(queryStringParsed, 'isCounter'));
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(verifyURLParams(queryStringParsed, 'fromOfferList'));
  const [isUserAcceptModalOpen, setIsUserAcceptModalOpen] = useState(false);
  const [isUserUnlockModalOpen, setIsUserUnlockModalOpen] = useState(false);
  const [isUserFinishProfileModalOpen, setIsUserFinishProfileModalOpen] = useState(false);
  const [dealMessage, setDealMessage] = useState('');
  const [sliderPosition, setSliderPosition] = useState(1);
  const [, setIsLightbox] = useState(false);
  const [, setTotalImages] = useState(null);
  const [, setHasErrors] = useState(false);
  const [ignored] = useState(false);
  const position = useRef();
  const acctProfile = getAcctProfile(account);
  const isOtherProfile = verifyIsOtherProfile(acctProfile, myprofile);
  const isEditable = verifyIsMyProfile(acctProfile, myprofile);
  const myOffer = _find(['offer', 'data'], account) || null;
  const isMobile = getBrowserDetails().isMobile && !tabletWidth;
  // NUX set True for disabled config
  const [isNuxCompleted, setIsNuxCompleted] = useState(true);
  const [showOfferTutorial, setShowOfferTutorial] = useState(false);
  const [isGenerous, setIsGenerous] = useState(false);
  const mounted = useRef();

  const { trackPackagesPageVisited } = useMixPanel();
  const { basicModal, resetModal, showTooManyPendingOffersErrorModal } = useModal();
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const memoisedAccountAvatar = useMemo(() => {
    if (!acctProfile?.avatar?.data?.urls) return null;

    return renderAvatar(acctProfile?.avatar?.data?.urls);
  }, [acctProfile?.avatar?.data?.urls]);

  const originPage = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('origin') || '';
  }, [location]);

  const memoisedAccountThumbAvatar = useMemo(() => {
    if (!acctProfile?.avatar?.data?.urls) return null;

    return renderAvatar(acctProfile?.avatar?.data?.urls, true);
  }, [acctProfile?.avatar?.data?.urls]);

  const [photoList, privatePhotoList] = generatePhotoList(isOtherProfile ? account : myprofile);

  useDisableBodyScroll(isUserFinishProfileModalOpen);

  useEffect(() => {
    setIsGenerous(
      myprofile && myprofile.profile && Boolean(getMyProfileData(myprofile, 'data.account_type') === 'Generous')
    );
    const queryStringParsed = queryString.parse(location.search);
    if (queryStringParsed.successfulMigration === 'true') showSuccessMigrateModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted.current && isNuxCompleted === true) {
      // do componentDidMount logic
      mounted.current = true;
      setShowOfferTutorial(myprofile.account_config && myprofile.account_config.show_offer_tutorial);
    }
  }, [isNuxCompleted, myprofile.account_config]);

  useEffect(() => {
    setShowOfferTutorial(myprofile.account_config && myprofile.account_config.show_offer_tutorial);
  }, [myprofile]);

  useEffect(() => {
    if (isOfferModalOpen === true && !isCounter) {
      isLightboxOpen
        ? vysionTrack(
            {
              action: 'click',
              component: 'Make Offer',
              extraInfo: '',
              page: 'Lightbox',
            },
            'lightbox-makeOffer'
          )
        : displayOfferModal(window.event);
    }
  }, [isOfferModalOpen, isCounter, isLightboxOpen]);

  useEffect(() => {
    if (isOfferModalOpen === true && isCounter === true) {
      counterModal('view');
    }
  }, [isOfferModalOpen, isCounter]);

  const noFavoriteButtonExperimentDetails = getFeatureDetails(
    settings.evidently,
    EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE
  );

  const isNoFavoriteButtonExperimentLive = async () => {
    return await isEvidentlyExperimentLive(EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE);
  };

  const createNewOffer = async formData => {
    const isLive = await isNoFavoriteButtonExperimentLive();

    if (isLive && noFavoriteButtonExperimentDetails && noFavoriteButtonExperimentDetails.reason !== DEFAULT_REASON) {
      trackEvidentlyMetrics({
        hashId: myprofile.hash_id,
        featureName: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE,
        experimentName: EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE,
        key: EVIDENTLY_NO_PROFILE_FAVORITE_KEYS.OFFERS_SENT,
        value: 1,
        shouldAddUpValue: false,
      });
    }

    if (originPage === 'dashboard' && !getBrowserDetails().isMobile) {
      trackDiscoverSwipingModeDesktopMetrics(myprofile.hash_id, 'discoverSwipingModeDesktopOffersMadeProfile');
    }

    createNewOfferProps(formData);
  };

  const acceptOffer = async (hashId: string, message: string) => {
    const isLive = await isNoFavoriteButtonExperimentLive();

    if (isLive && noFavoriteButtonExperimentDetails && noFavoriteButtonExperimentDetails.reason !== DEFAULT_REASON) {
      trackEvidentlyMetrics({
        hashId: myprofile.hash_id,
        featureName: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE,
        experimentName: EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE,
        key: EVIDENTLY_NO_PROFILE_FAVORITE_KEYS.OFFERS_ACCEPTED,
        value: 1,
        shouldAddUpValue: false,
      });
    }

    acceptOfferProps(hashId, message);
  };

  const showSuccessMigrateModal = () => {
    basicModal({
      dateTestId: 'migrate-success',
      modalContentLabel: 'Success Profile Migrate',
      isModalOpen: true,
      modalBody: (
        <div style={{ textAlign: 'center' }}>
          <HeaderOut onBoardingSuccess />
          <h3>Profile successfully created!</h3>
          <div style={{ marginBottom: 20 }}>
            Continue making edits or start finding your next date!
            <br /> {isGenerous ? '50 credits has been deposited into your account.' : ''}
          </div>
          <ButtonSet align="center">
            <Button
              id="EditProfile"
              buttonType="normal"
              customStyle={{ borderColor: '#2490de', color: '#2490de' }}
              onClick={resetModal}
              type="submit"
            >
              Edit Profile
            </Button>
            <Button
              id="EditProfile"
              buttonType="primary"
              onClick={() => {
                window.location.href = '/search';
                resetModal();
              }}
              type="submit"
            >
              Browse Members
            </Button>
          </ButtonSet>
        </div>
      ),
    });
  };
  const completeNux = () => {
    setIsNuxCompleted(true);
  };

  let currencyInitial;
  let currencyLabelUnicode;
  if (account && account.currency && isAttractive(acctProfile)) {
    currencyInitial = account.currency.data.initial;
    currencyLabelUnicode = account.currency.data.label_unicode;
  } else {
    currencyInitial = myprofile && myprofile.currency && myprofile.currency.data.initial;
    currencyLabelUnicode = myprofile && myprofile.currency && myprofile.currency.data.label_unicode;
  }

  const updateSliderPosition = (currPosition, isLightboxOn = false) => {
    setSliderPosition(currPosition);
    setIsLightbox(isLightboxOn);
  };

  const updateMaxLength = length => {
    setTotalImages(length);
  };

  const handleSuggestion = () => {
    setJustSuggested(true);

    sendSuggestion(profile.hash_id)
      .then(() => {
        offerModalClose();
      })
      .catch(() => {
        setHasErrors(true);
      });
  };

  const lightboxOnClose = (activeIndex: number) => {
    setIsLightboxOpen(false);
    setCurrentImage(-1);
    if (gallerySwiper) {
      gallerySwiper.slideToLoop(activeIndex, 0);
    }
  };

  const openLightbox = index => {
    setCurrentImage(index);
    setIsLightboxOpen(true);
  };

  const openTrackLightbox = (index, event) => {
    vysionTrack(
      {
        action: 'view',
        component: 'Display',
        extraInfo: '',
        page: 'Lightbox',
      },
      'lightbox'
    );
    openLightbox(index, event);
  };

  const offerModalOpen = openCounter => {
    setIsOfferModalOpen(true);
    setIsCounter(openCounter);
  };

  const offerModalClose = () => {
    setIsOfferModalOpen(false);
  };

  const closeModalTracking = e => {
    isCounter ? counterModalClose(e) : sendClose(e);
    offerModalClose();
  };

  const canMakeOffer = e => {
    profileMakeOffer(e);
    try {
      const { cannot_make_offer_reasons } = myprofile;

      if (cannot_make_offer_reasons === null) {
        return true;
      }

      if (cannot_make_offer_reasons[0].indexOf('many unlocked offers') > -1) {
        trackPackagesPageVisited({ Source: 'Offers Limit' });
        navigate('/packages?newPayWall=true&unlimitedAccess=true');

        return false;
      }

      if (cannot_make_offer_reasons[0].indexOf('many locked deals') > -1) {
        trackPackagesPageVisited({ Source: 'Offers Limit' });
        showTooManyPendingOffersErrorModal();
        return false;
      }

      return true;

      // showWarn(`You cannot make an offer: ${cannot_make_offer_reasons[0]}`);
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const toggleProfileBlock = () => {
    scrollToTop();
    toggleBlock(profile.username, profile.hash_id, !profile.is_blocked);
  };

  const handleDealMessageUpdate = e => {
    setDealMessage(e.target.value);
  };

  const backOrDashboard = () => {
    const urlEmailToken = location.search.indexOf('email_ref') > -1;
    const urlNotifToken = location.search.indexOf('notif_ref') > -1;
    const urlMigration = location.search.indexOf('migration_edit') > -1;
    const notAppUrl = prevPage && prevPage.length <= 0;

    if (urlEmailToken || urlNotifToken || urlMigration || notAppUrl) {
      navigate('/dashboard');
      return;
    }
    navigate(-1);
  };

  const handleContinueIDV = () => {
    navigate('/verification');
  };

  const renderNux = otherProfile => {
    const page = otherProfile ? '/others-profile' : '/profile';

    return (
      <NuxGuide
        profile={myprofile}
        route={page}
        fetchPageNux={fetchPageNux}
        updateNuxState={updateNuxState}
        onNuxComplete={completeNux}
        skipNuxGuides={skipNuxGuides}
      />
    );
  };

  const renderBlockedWarning = () => {
    return (
      <BlockedContainer darkMode={isDarkMode()}>
        <StyledText>You have Blocked this member</StyledText>
        <Button buttonType="error" onClick={() => toggleProfileBlock()}>
          Unblock
        </Button>
      </BlockedContainer>
    );
  };

  const RenderProfileCover = (otherProfile = false) => {
    const { showNotificationBanner } = useNotificationBanner();
    const myProfileData = getMyProfileData(myprofile, 'data');
    const [photoListCover, privatePhotoListCover] = generatePhotoList(account, true);
    const myPhotosLength = getPhotoLength(photos);
    const otherProfilePhotosLength = getOtherPhotoLength(photoListCover);
    const myPhotos = { data: _sortBy(photos.data, ['rank', 'order']) };

    const onFavoritedProfile = () => {
      if (account.is_favorite) {
        toggleFav(account.hash_id);
        showNotificationBanner({
          type: 'error',
          message: <FavoritedProfileNotificationBanner action="unfavorited" />,
        });
        return;
      }

      // when user favorites
      // check if capable
      showMandatoryCompleteProfile(
        () => {
          toggleFav(account.hash_id);
          mixpanelTrackProfileFavorited({ Source: 'Profile', User: account.hash_id });

          showNotificationBanner({
            type: 'success',
            message: <FavoritedProfileNotificationBanner action="favorited" />,
          });
        },
        () => undefined,
        'Add to Favorites'
      );
    };

    if (otherProfile) {
      const offer = _find(['offer', 'data'], account) || null;
      const otherProfileModel = ProfileMaker.create({
        data: acctProfile,
      } as ProfileObject);

      return (
        <OtherProfileCover
          account={account}
          username={account.username}
          age={acctProfile.age}
          location={displayUserLocation(myProfileData, acctProfile)}
          lastLoginTime={otherProfileModel.lastActivityStatus()}
          lastLoginLocation={acctProfile.last_access_location_full || ''}
          profileImg={memoisedAccountAvatar}
          profileImgFallback={acctProfile.avatar.data.urls['resized']}
          onClickPhoto={openTrackLightbox}
          favorited={Boolean(account.is_favorite)}
          onClickFavorite={onFavoritedProfile}
          hasFavorite={!account.is_blocked}
          myPhotos={myPhotos}
          otherProfilePhotos={photoListCover}
          myPhotosLength={myPhotosLength}
          otherProfilePhotosLength={otherProfilePhotosLength}
          privatePhotoCount={privatePhotoListCover.length}
          isOtherProfile={otherProfile}
          isMobile={isMobile}
          isPortrait={portrait}
          updateMaxLength={updateMaxLength}
          updateSliderPosition={updateSliderPosition}
          sliderPosition={sliderPosition}
          position={position}
          counterBadge={position}
          lightboxIsOpen={isLightboxOpen}
          currentUserIsGenerous={isGenerous}
          isVerified={acctProfile.id_verified}
          offer={offer}
          myProfile={myProfileData}
          settings={settings}
        >
          {myProfileData && myProfileData.account_type === acctProfile.account_type ? (
            <p style={{ color: '#ff0000' }}>You can&#39;t make offers to members with the same account type as you</p>
          ) : !account.is_blocked ? (
            <ExperimentInjector testId="SJq179csTtWq7OXP3KL2iA" prodId="6Q9KuEX1QrqJDclOYgDEhA">
              <OfferPanelV2
                account={account}
                offerType={getOfferType(offer, myprofile)}
                createdAt={getMyOfferData(offer, 'created_at.date')}
                myProfile={myprofile}
                avatarSrc={memoisedAccountThumbAvatar}
                username={account.username}
                hashId={account.hash_id}
                offer={myOffer}
                clearErrors={clearErrors}
                createNewOffer={createNewOffer}
                counterOffer={counterOffer}
                isCounter={isCounter}
                closeModal={offerModalClose}
                currencyInitial={currencyInitial}
                currencyLabel={currencyLabelUnicode}
                currentUserIsGenerous={
                  myprofile &&
                  myprofile.profile &&
                  Boolean(getMyProfileData(myprofile, 'data.account_type') === 'Generous')
                }
                errors={errors}
                sex={acctProfile.sex}
                sendSuggestion={handleSuggestion}
                showWarn={showWarn}
                cannot_make_offer_reasons={myprofile.cannot_make_offer_reasons}
                ignoreStatus={ignored}
                justSuggested={justSuggested}
                showOfferTutorial={showOfferTutorial}
                updateOffer={updateOffer}
                updateOfferTutorialState={updateOfferTutorialState}
                isFirstTimeTutorial={myprofile.account_config && myprofile.account_config.show_offer_tutorial}
                acceptOffer={acceptOffer}
                ignoreOffer={ignoreOffer}
                canMakeOffer={canMakeOffer}
                unlockMessage={triggerUnlockMessage}
                setIsUserFinishProfileModalOpen={setIsUserFinishProfileModalOpen}
              />
            </ExperimentInjector>
          ) : (
            renderBlockedWarning()
          )}
        </OtherProfileCover>
      );
    }

    return (
      <MyProfileCover
        username={myprofile.username}
        age={acctProfile.age}
        location={displayUserLocation(acctProfile, myprofile.profile.data)}
        lastLoginTime={(acctProfile.last_activity_dt && tsSince(acctProfile.last_activity_dt.date)) || ''}
        lastLoginLocation={acctProfile.last_access_location_full || ''}
        profileImgUrl={myprofile.profile.data.avatar.data.urls}
        profileImgFallback={acctProfile.avatar.data.urls['resized']}
        onClickPhoto={openTrackLightbox}
        search={location.search}
        account={myprofile}
        wishListCount={account ? account.total_wishlist_count : 0}
        errors={errors}
        updateProfileCover={updateProfileCover}
        clearIndividualError={clearEachIndividualError}
        myPhotos={myPhotos}
        myPhotosLength={myPhotosLength}
        otherProfilePhotos={photoListCover}
        otherProfilePhotosLength={otherProfilePhotosLength}
        isOtherProfile={otherProfile}
        isMobile={isMobile}
        isPortrait={portrait}
        updateMaxLength={updateMaxLength}
        updateSliderPosition={updateSliderPosition}
        sliderPosition={sliderPosition}
        position={position}
        counterBadge={position}
        lightboxIsOpen={isLightboxOpen}
        onConfirmIDV={() => navigate('/idv-packages?step=payment&package_id=idv_last_minute')}
        onContinueIDV={handleContinueIDV}
        isVerified={acctProfile.id_verified}
        isUpdatingProfileCover={myprofile.isUpdatingProfileCover}
        editable
        resetDiscoverModeAccounts={resetDiscoverModeAccountsAction}
      />
    );
  };

  if (account.notFound && !account.hash_id) {
    clearNotificationMessage();
    return (
      <BlankSlate
        title="We can't show you this profile"
        message="This profile was either deactivated, removed, or never existed."
        action={
          <Button onClick={backOrDashboard} buttonType="primary">
            Find someone better
          </Button>
        }
      />
    );
  }

  if (!account.profile) {
    return (
      <div>
        <TrackLoadingToMixpanel page="Profile - loading profile" />
        <LoadingMsg msg="Loading Profile" />
      </div>
    );
  }

  return (
    <Container
      id="ProfileContainer"
      fullWidth
      css={{
        [dimensions.SCREEN_MIN_MD]: {
          padding: '0 0 140px 0',
        },
        [dimensions.SCREEN_MAX_MD]: {
          padding: '0 0 115px 0',
        },
        [dimensions.SCREEN_MIN_LG]: {
          ...spacing.MARGIN_TOP_LG,
          display: 'flex',
          maxWidth: 1150,
        },
        [dimensions.SCREEN_MAX_LG]: {
          display: 'block',
          position: ternaryFunc(isLightboxOpen, 'unset', 'absolute'),
          top: ternaryFunc(isLightboxOpen, '-60px', 0),
          left: 0,
          zIndex: 900,
          width: '100%',
          backgroundColor: backgroundTheme.block,
        },
        [dimensions.SCREEN_MAX_XS]: {
          marginTop: ternaryFunc(isLightboxOpen, -16, 0),
        },
      }}
    >
      <LeftContent isLightboxOpen={isLightboxOpen}>
        <CloseProfileContainer
          id="closeProfileContainer"
          data-test-id="close-profile-container"
          hasStatus={myOffer && (myOffer.status === 'pending' || myOffer.status === 'accepted')}
        >
          <CloseProfileHandler onClick={backOrDashboard}>
            <ArrowBackIcon />
          </CloseProfileHandler>
        </CloseProfileContainer>
        <LeftContentMainPanel>
          <MainPanel darkMode={isDarkMode()} panelStyle="white">
            <ProfileHeading>{RenderProfileCover(isOtherProfile)}</ProfileHeading>
          </MainPanel>

          {!isOtherProfile && (
            <IdVerificationContainer>
              <PurchaseIDV
                onConfirmIDV={() => navigate('/idv-packages?step=payment&package_id=idv_last_minute')}
                onContinueIDV={handleContinueIDV}
                account={account}
                desktopContinue
                customIDVerificationStatusStyle={{
                  marginLeft: 0,
                  marginRight: 0,
                  minWidth: 'unset',
                }}
              />
            </IdVerificationContainer>
          )}

          <ProfileGallery
            data-test-id="user-gallery-container"
            withHeader={false}
            panelStyle={isDarkMode() ? 'borderless' : 'white'}
          >
            {myprofile.gallery.isFetching && <LoadingBackdrop active={true} showLoadingBg={true} />}
            <Photos
              photos={photoList}
              openLightbox={openTrackLightbox}
              isOtherProfile={isOtherProfile}
              refreshAndClearErrors={refreshAndClearErrors}
              uploadPhoto={uploadPhoto}
              isUploading={isUploading}
              photoData={photos}
              displayMessage={displayMessage}
              setPrivacy={setPrivacy}
              makeDefault={makeDefault}
              deletePhoto={deletePhoto}
              hasPublicPhotos={hasPublicPhotos}
              singlePublicLeft={singlePublicLeft}
              hasPublicApprovedPhotos={hasPublicApprovedPhotos}
              uploadMedia={uploadMedia}
              deleteMedia={deleteMedia}
              setMediaPrivacy={setMediaPrivacy}
              bulkTogglePrivacy={bulkTogglePrivacy}
              bulkDeleteMedia={bulkDeleteMedia}
              profile={myprofile}
            />
          </ProfileGallery>
        </LeftContentMainPanel>

        <LeftContentWrapper>
          <Optional show={getBrowserDetails().isMobile}>
            <WrappedWithMobile>
              {isEditable && Boolean(getMyProfileData(myprofile, 'data.account_type') === 'Attractive') && (
                <OpeningOffer
                  data-test-id="profile-opening-offer"
                  updateProfileOpeningOffer={updateProfileOpeningOffer}
                />
              )}
            </WrappedWithMobile>
          </Optional>

          <AboutMe
            data-test-id="user-about-myself-container"
            errors={errors}
            description={ternaryFunc(
              isEditable && myprofile,
              myprofile.profile && myprofile.profile.data.description,
              acctProfile.description
            )}
            editable={isEditable}
            updateProfileDescription={updateProfileDescription}
            clearIndividualError={clearEachIndividualError}
            isProcessing={myprofile.isUpdatingDescription}
          />
          <FirstDate
            data-test-id="user-first-date-container"
            errors={errors}
            seeking={ternaryFunc(
              isEditable && myprofile,
              myprofile.profile && myprofile.profile.data.seeking,
              acctProfile.seeking
            )}
            editable={isEditable}
            updateProfileSeeking={updateProfileSeeking}
            clearIndividualError={clearEachIndividualError}
            isProcessing={myprofile.isUpdatingSeekingDesc}
          />
        </LeftContentWrapper>
      </LeftContent>

      <RightContent isNonTablet={isNotTablet()}>
        <Optional show={!getBrowserDetails().isMobile}>
          <WrappedWithDesktop>
            {isEditable && Boolean(getMyProfileData(myprofile, 'data.account_type') === 'Attractive') && (
              <OpeningOffer
                data-test-id="profile-opening-offer"
                updateProfileOpeningOffer={updateProfileOpeningOffer}
              />
            )}
          </WrappedWithDesktop>
        </Optional>

        <PersonalInfo
          data-test-id="profile-info-about"
          myProfile={ternaryFunc(isEditable && myprofile, myprofile.profile && myprofile.profile.data, acctProfile)}
          mapFields={mapFields}
          editable={isEditable}
          errors={errors}
          updateProfileAbout={updateProfileAbout}
          clearIndividualError={clearEachIndividualError}
          isProcessing={isProcessing}
          acctProfile={acctProfile}
        />

        <Gifts
          profile={profile}
          myProfile={myprofile}
          toggleWishlist={updateShowWishlist}
          toggleGiftSent={updateShowGiftSent}
        />

        <InterestedIn
          data-test-id="profile-info-interested"
          profile={ternaryFunc(isEditable && myprofile, myprofile.profile && myprofile.profile.data, acctProfile)}
          editable={isEditable}
          errors={errors}
          updateProfileInterest={updateProfileInterest}
          clearIndividualError={clearEachIndividualError}
        />
        {isOtherProfile && (
          <div>
            <BlockOrReport
              username={account.username}
              hashId={account.hash_id}
              isBlocked={Boolean(account.is_blocked)}
              onToggleBlock={toggleProfileBlock}
            />
          </div>
        )}
      </RightContent>

      <PhotoLightbox
        avatar={memoisedAccountAvatar}
        images={photoList}
        allowZoom={allowZoom}
        lightboxIsOpen={isLightboxOpen}
        currentImage={currentImage}
        onClose={lightboxOnClose}
        userHasPrivatePhotos={privatePhotoList.length > 0}
        photoListLength={photoList.length}
        privatePhotoCount={privatePhotoList.length}
        isOnboarded={onboarded}
        onOfferClick={offerModalOpen}
        justSuggested={justSuggested}
        ignoreStatus={ignored}
        username={account.username}
        account={account}
        hashId={account.hash_id}
        isBlocked={account.is_blocked}
        myprofile={myprofile}
        showWarn={showWarn}
        offer={myOffer}
        isOfferBtnVisible={isOtherProfile && screenSizeIsMobile && !myOffer}
        updateSliderPosition={updateSliderPosition}
        currentUserIsGenerous={isGenerous}
      />

      <ReactModal
        isOpen={isOfferModalOpen}
        className={`${css(ModalStyles)}`}
        overlayClassName={`${css(OverlayStyles)}`}
        contentLabel="Create Offer Modal"
        shouldCloseOnOverlayClick={true}
        onRequestClose={offerModalClose}
      >
        <CloseBtn onClick={e => closeModalTracking(e)}>
          <SvgIcon component={CloseOutlined} style={closeButton} />
        </CloseBtn>
        <OfferCreate
          buttonID="button-offer-create"
          avatarSrc={memoisedAccountThumbAvatar}
          username={account.username}
          hashId={account.hash_id}
          offer={myOffer}
          clearErrors={clearErrors}
          createNewOffer={createNewOffer}
          counterOffer={counterOffer}
          isCounter={isCounter}
          closeModal={offerModalClose}
          currencyInitial={currencyInitial}
          currencyLabel={currencyLabelUnicode}
          currentUserIsGenerous={isGenerous}
          errors={errors}
          sex={acctProfile.sex}
          sendSuggestion={handleSuggestion}
          showWarn={showWarn}
          cannot_make_offer_reasons={myprofile.cannot_make_offer_reasons}
          ignoreStatus={ignored}
          justSuggested={justSuggested}
          otherProfile={acctProfile}
        />
      </ReactModal>

      {isUserAcceptModalOpen && (
        <ConfirmationModal
          isOpen={true}
          contentLabel="Attractive Accept Offer Modal"
          cancelText="Cancel"
          confirmText="Accept and Send"
          buttonPosition="center"
          onConfirm={() => acceptOffer(account.hash_id, dealMessage)}
          onClose={() => setIsUserAcceptModalOpen(false)}
        >
          <div>
            <StyledAvatar src={acctProfile.avatar.data.urls.thumbs} />
            <p style={{ color: '#4396d7', fontWeight: 'bold', textAlign: 'center' }}>
              Send {account.username} a message to accept their offer.
            </p>
            <TextArea
              id={`${account.username}_message`}
              rows={6}
              onChange={handleDealMessageUpdate}
              value={dealMessage}
              placeholder="Introduce yourself and tell them what you'd like to do on your first date!"
              customInputStyle={{ fontSize: '16px' }}
            />
          </div>
        </ConfirmationModal>
      )}

      {isUserUnlockModalOpen && (
        <ConfirmationModal
          isOpen={true}
          contentLabel="Offer Unlock Modal"
          cancelText="Cancel"
          confirmText="Unlock"
          header="Unlock Offer"
          description={`Do you want to unlock this date for credits?`}
          onClose={() => setIsUserUnlockModalOpen(false)}
          onConfirm={() => handleUnlockOffer(account.hash_id)}
        />
      )}

      <CompleteProfileModalV2
        avatar={memoisedAccountAvatar}
        username={account.username}
        location={displayUserLocation(acctProfile, account.profile.data)}
        age={acctProfile.age}
        editProfileLink={`/profile/${myprofile.hash_id}`}
        isOpen={isUserFinishProfileModalOpen}
        onClose={() => setIsUserFinishProfileModalOpen(false)}
      />
      {nuxEnabled && renderNux(isOtherProfile)}
    </Container>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
  };
};

const mapStateToProps = state => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {})(connectScreenSize(mapScreenToProps)(Profile));
