import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';

import { textColor } from '../../style';
import Button from '../../atoms/buttons/Button';
import GalleryModal from '../../modules/modals/GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { SvgIcon } from '@material-ui/core';
import { Report } from '@material-ui/icons';
import { PhotoConfig, VideoConfig } from '../../config/Media';
import IconExclamationPointCircle from '../../atoms/icons/IconExclamationPointCircle';
import colors from '../../theme/colors';

const SpanBold = styled('span')(() => ({
  fontWeight: 600,
}));

const ModalHeader = styled('h3')(() => ({
  marginTop: 10,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '31px',
  textAlign: 'left',
  paddingRight: 30,
  marginBottom: 0,
  ...textColor.DARKEST,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: '20px',
    marginTop: 20,
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }: { hasHeader: boolean }) => !hasHeader && { marginTop: 0 }
);

interface UnsupportedFileTypeErrorModalProps {
  onClose(): void;
  onUploadAnother(): void;
  isOpen: boolean;
  alwaysDisabledBodyScroll?: boolean;
  filename?: string;
  mediaType?: string;
  isWarning?: boolean;
  isMultiple?: boolean;
}

const UnsupportedFileTypeErrorModal: FC<UnsupportedFileTypeErrorModalProps> = ({
  onClose,
  onUploadAnother,
  isOpen,
  alwaysDisabledBodyScroll = false,
  filename,
  mediaType,
  isMultiple,
  isWarning,
}) => {
  useDisableBodyScroll(isOpen || alwaysDisabledBodyScroll);

  const title = useMemo(() => {
    if (mediaType === 'video') {
      return isMultiple ? 'Videos' : 'Video';
    }

    return isWarning && isMultiple ? 'Images' : 'Image';
  }, [mediaType, isMultiple, isWarning]);

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{
        overlay: {
          zIndex: 80000,
        },
        content: {
          borderColor: '#DD0B24',
          backgroundColor: '#FFF8F8',
          padding: 20,
          maxWidth: 335,
          left: 0,
          right: 0,
        },
      }}
      contentLabel=""
      isOpen={isOpen}
    >
      <ModalDescription hasHeader={true} data-test-id="unsupported-file-type">
        {isWarning ? (
          <IconExclamationPointCircle
            customStyle={{
              width: 40,
              height: 40,
            }}
            fill={colors.dark.notifications.yellow}
          />
        ) : (
          <SvgIcon fontSize="large" style={{ color: '#DD0B24', height: 48, width: 48 }} component={Report} />
        )}
        <ModalHeader>Unsupported {title}</ModalHeader>
        <p>
          {isMultiple ? (
            'Multiple files'
          ) : (
            <>
              The file <SpanBold>{filename}</SpanBold>
            </>
          )}{' '}
          could not be uploaded because {isMultiple ? 'they are' : 'it is'} not a valid file type. <br />
          <br />
          Image must{' '}
          <SpanBold>
            {mediaType === 'video' ? VideoConfig.allowedMimeTypeText : PhotoConfig.allowedMimeTypeText}
          </SpanBold>{' '}
          and less than <SpanBold>15 MB.</SpanBold>
        </p>

        <div style={{ marginTop: 5 }} data-test-id="always-show-agreement"></div>
        <Button
          data-test-id={`upload-another`}
          onClick={onUploadAnother}
          customStyle={{
            borderColor: '#2B8FD7',
            backgroundColor: '#2B8FD7',
            color: '#FFFFFF',
            fontWeight: 600,
            width: 'fit-content',
            padding: '12px 22px',
            margin: '0 auto',
          }}
        >
          {isWarning ? 'OK' : 'Upload Again'}
        </Button>
      </ModalDescription>
    </GalleryModal>
  );
};

const MemoizedUnsupportedFileTypeErrorModal = React.memo(UnsupportedFileTypeErrorModal);

export default MemoizedUnsupportedFileTypeErrorModal;
