import jwtDecode from 'jwt-decode';
import { tsNow } from '../common';
import { differenceInSeconds } from 'date-fns';
import {
  LOGIN_USER,
  REFRESHING_TOKEN,
  DONE_REFRESHING_TOKEN,
  RESET_TOKEN_VALIDITY,
  STORE_WEB_TIMESTAMP,
  GDPR_ACKNOWLEDGED,
  GDPR_OPEN_STEP2,
  REMOVE_STALE_HTTP_REQUESTS,
  STORE_HTTP_REQUEST,
  REMOVE_HTTP_REQUEST,
  SET_VIRGIL,
  UNSET_VIRGIL,
  STORE_RELOADED,
  AUTH_REMOVE_BLOCK_PROPERTIES,
  AuthState,
  AuthActionTypes,
  IS_FETCHING,
  RESET_IS_FETCHING,
} from './types/Auth.d';

const initialState: AuthState = {
  isAuthenticated: false,
  jwToken: '',
  gdpr_acknowledged: null,
  gdpr_Step2: false,
  jwtUnixExpiration: '',
  freshTokenPromise: null,
  resetTokenValidity: true,
  webTimestamp: '',
  requestList: [{ loc: null, time: 0 }],
  virgil: false,
  isRecentlyReloaded: false,
  password_disabled: false,
  tokenRefreshing: false,
  isFetching: false,
};

const auth = (state = initialState, action: AuthActionTypes): any => {
  //let {data} = action.payload;
  switch (action.type) {
    case LOGIN_USER: {
      const { token, gdpr_acknowledged, password_disabled } = action.payload;
      // parse jwt for expiration timeStamp
      const jwtUnixExpiration = jwtDecode(token).exp;
      // save everything.
      return {
        ...state,
        isAuthenticated: true,
        jwToken: token,
        jwtUnixExpiration,
        gdpr_acknowledged,
        password_disabled,
      };
    }
    // these next cases are for making sure we dont attempt to refresh mutliple JWT's.
    case REFRESHING_TOKEN:
      return {
        ...state,
        freshTokenPromise: action.payload,
        tokenRefreshing: true,
      };
    case DONE_REFRESHING_TOKEN:
      return {
        ...state,
        freshTokenPromise: null,
        tokenRefreshing: false,
      };
    case RESET_TOKEN_VALIDITY:
      return {
        ...state,
        resetTokenValidity: action.payload,
      };
    case STORE_WEB_TIMESTAMP:
      return {
        ...state,
        webTimestamp: action.payload.webTimestamp,
        bundleUpdate: action.payload.bundleUpdate,
      };
    case GDPR_ACKNOWLEDGED:
      return {
        ...state,
        gdpr_Step2: false,
        gdpr_acknowledged: true,
      };
    case GDPR_OPEN_STEP2:
      return {
        ...state,
        gdpr_Step2: true,
      };
    case REMOVE_STALE_HTTP_REQUESTS:
      // remove stale requests (> 1000ms old)
      if (!state.requestList)
        return {
          ...state,
          requestList: [],
        };
      else
        return {
          ...state,
          requestList: state.requestList.filter(function(a) {
            const requestStaleTime = differenceInSeconds(tsNow(), a.time);
            return requestStaleTime < 1;
          }),
        };
    case STORE_HTTP_REQUEST: {
      return {
        ...state,
        requestList: [{ loc: action.payload.toLowerCase(), time: tsNow() }, ...state.requestList],
      };
    }
    case REMOVE_HTTP_REQUEST:
      return {
        ...state,
        requestList: state.requestList.filter(function(a) {
          return a.loc !== action.payload;
        }),
      };
    case SET_VIRGIL:
      return {
        ...state,
        virgil: true,
      };
    case UNSET_VIRGIL:
      return {
        ...state,
        virgil: true,
      };
    case STORE_RELOADED:
      return {
        ...state,
        isRecentlyReloaded: Date.now(),
      };
    case AUTH_REMOVE_BLOCK_PROPERTIES:
      return {
        ...state,
        password_disabled: false,
      };
    case IS_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };
    case RESET_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default auth;
