import MasterConfig from '../config/Master';
import axios, { AxiosPromise } from 'axios';

const api = MasterConfig.api;

const getSiteAttributes = (): AxiosPromise => {
  const pageName = window.location.pathname.split('/')[1];

  return axios({
    method: 'GET',
    url: `${api}/settings/attributes?source=${pageName}`,
  });
};

export { getSiteAttributes };
