import React, { ReactElement, useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Edit as EditIcon } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { borders, inputTheme } from '../style';
import { isDarkMode } from '../utils/helpers';

const editIcon = {
  marginRight: 4,
  color: '#2B8FD7',
  cursor: 'pointer',
  height: 16,
  width: 16,
};

const containerStyleEdit = {
  marginBottom: 20,
};

const inputStyleEdit = {
  border: 'none',
  background: 'none',
  color: '#2B8FD7',
  textDecoration: 'underline',
  fontWeight: 600,
  width: 'auto',
  paddingLeft: 0,
  textAlign: 'center',
  cursor: 'pointer',
  caretColor: 'transparent',
};

const buttonStyleEdit = {
  display: 'none',
};

const dropdownStyleEdit = {};

const iconFlexStyle = {
  position: 'absolute',
  top: 9,
};

interface PhoneNumberInputProps {
  label?: string;
  defaultCountry?: string;
  containerClass?: string;
  inputClass?: string;
  buttonClass?: string;
  dropdownClass?: string;
  customStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  customLabelStyle?: React.CSSProperties;
  value?: string;
  autoFocus: any;
  onChange?: (value: string) => void;
  onFocus?: any;
  onBlur?: () => void;
  isVerifyEdit?: boolean;
  onEditNumber?: any;
  children?: React.ReactNode;
  handleOnChangeCountry?: (countryProps: any) => void;
  handleClickCountryDropdown?: (showDropdown: boolean) => void;
  dataTestId?: string;
}

const StyledPhoneInput = styled(PhoneInput)`
  .phoneButton .selected-flag:hover {
    background: ${inputTheme.backgroundInput};
  }

  .phoneButton .flag-dropdown.open .selected-flag.open {
    background: ${inputTheme.backgroundInput};
  }

  .selected-flag {
    &.open {
      background: ${inputTheme.backgroundInput} !important;
    }
  }
`;

export const PhoneNumberInput = ({
  label,
  defaultCountry = 'us',
  containerClass = 'phoneContainer',
  inputClass = 'phoneInput',
  buttonClass = 'phoneButton',
  dropdownClass = 'phoneDropdown',
  containerStyle = { margin: '10px 0' },
  autoFocus,
  inputStyle = {
    width: '100%',
    background: inputTheme.backgroundInput,
    color: inputTheme.colorInput,
    height: 40,
    border: borders.DEFAULT,
  },
  buttonStyle = {
    background: inputTheme.backgroundInput,
    color: inputTheme.colorInput,
    border: borders.DEFAULT,
  },

  dropdownStyle = {
    background: isDarkMode() ? '#031a2a' : '#ffff',
    color: inputTheme.colorInput,
    border: borders.DEFAULT,
  },
  value,
  onChange,
  children,
  customStyle,
  isVerifyEdit = false,
  onEditNumber,
  handleOnChangeCountry,
  handleClickCountryDropdown,
  customLabelStyle,
  dataTestId,
  ...rest
}: PhoneNumberInputProps): ReactElement => {
  const [phone, setPhone] = useState(value || '');
  const [inputWidth, setInputWidth] = useState(326);
  const [selectedCountry, setSelectedCountry] = useState({
    countryCode: '',
    dialCode: '',
    format: '',
    name: '',
  });
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if (value) {
      setPhone(value);
    }
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      const elemInput = window.document.getElementsByClassName('phoneInput')[0];
      elemInput.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const elemDiv = document.getElementById('elemDiv');
    const elemInput = window.document.getElementsByClassName('phoneInput')[0];
    elemDiv.innerText = elemInput.value;
    setInputWidth(elemDiv.clientWidth);
  }, []);

  useEffect(() => {
    if (selectedCountry.name && typeof handleOnChangeCountry === 'function') {
      handleOnChangeCountry(selectedCountry);
    }
  }, [selectedCountry.name]);

  const handlePhoneChange = (value: string, country) => {
    setPhone(value);
    if (onChange) {
      onChange(value);
    }

    setSelectedCountry(country);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      if (phoneInputRef.current && typeof handleClickCountryDropdown === 'function') {
        handleClickCountryDropdown(phoneInputRef.current.state.showDropdown);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [phoneInputRef]);

  return (
    <div style={customStyle} onClick={onEditNumber ? onEditNumber : () => null}>
      {label && <label style={customLabelStyle}>{label}</label>}
      <div
        id="elemDiv"
        style={{ visibility: 'hidden', display: 'inline-block', height: 0, position: 'absolute' }}
      ></div>
      <StyledPhoneInput
        ref={phoneInputRef}
        country={defaultCountry}
        value={phone}
        onChange={handlePhoneChange}
        containerClass={containerClass}
        inputClass={inputClass}
        buttonClass={buttonClass}
        dropdownClass={dropdownClass}
        containerStyle={isVerifyEdit ? containerStyleEdit : containerStyle}
        inputStyle={isVerifyEdit ? inputStyleEdit : inputStyle}
        buttonStyle={isVerifyEdit ? buttonStyleEdit : buttonStyle}
        dropdownStyle={isVerifyEdit ? dropdownStyleEdit : dropdownStyle}
        autoFocus={autoFocus}
        inputProps={{
          'data-test-id': dataTestId,
        }}
        {...rest}
      />
      {isVerifyEdit && (
        <div style={{ ...iconFlexStyle, left: `calc((50% - ${inputWidth}px / 2) - 16px)` }}>
          <SvgIcon component={EditIcon} style={editIcon} />
        </div>
      )}
      {children}
    </div>
  );
};

export default PhoneNumberInput;
