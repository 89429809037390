import styled from '@emotion/styled';
import colors, { white, primaryBGDark } from '../../../theme/colors';
import { isDarkMode } from '../../../utils/helpers';
import { dimensions } from '../../../style';
import { theme } from '../../../theme';

interface ITutorialSectionSubheader {
  maxWidth?: number;
}

interface ITutorialSectionWrapper {
  marginBottom?: number;
  paddingTop?: number;
}

export const TutorialHeader = styled('h1')({
  fontSize: 26,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  letterSpacing: -0.52,
  color: colors[theme].black.tertiary,
});

export const TutorialSectionWrapper = styled('div')(({ marginBottom, paddingTop }: ITutorialSectionWrapper) => ({
  marginBottom: marginBottom || 30,
  paddingTop,
  color: colors[theme].black.tertiary,
  [dimensions.SCREEN_MIN_MD]: {
    marginBottom: 36,
    paddingTop: 0,
  },
}));

export const TutorialSectionProfileInfoWrapper = styled('div')(({ marginBottom }: ITutorialSectionWrapper) => ({
  marginBottom: marginBottom || 36,
  color: colors[theme].black.tertiary,
  [dimensions.SCREEN_MIN_MD]: {
    marginBottom: 50,
  },
}));

export const TutorialSectionSubHeaderWrapper = styled('div')({
  marginBottom: 30,
  color: colors[theme].black.tertiary,
  [dimensions.SCREEN_MIN_MD]: {
    marginBottom: 50,
  },
});

export const TutorialSectionSubheader = styled('p')(({ maxWidth }: ITutorialSectionSubheader) => ({
  color: isDarkMode() ? '#AFB0B1' : white,
  maxWidth,
  [dimensions.SCREEN_MIN_MD]: {
    maxWidth: '100%',
  },
}));

export const TutorialComponentContainer = styled('div')({
  flexShrink: 0,
  background: isDarkMode() ? primaryBGDark : colors['light'].blue.secondary,
  opacity: 0.96,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  color: white,
  padding: '48px 0px 140px 0px',
  zIndex: 1,
  overflow: 'hidden',
  transition: 'transform 0.5s ease',
  [dimensions.SCREEN_MIN_MD]: {
    padding: '0px',
    zIndex: 997,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const IconDescription = styled('p')({
  letterSpacing: '-0.32px',
  fontSize: 16,
  fontWeight: 700,
  [dimensions.SCREEN_MIN_MD]: {
    marginBottom: 0,
  },
});

export const TutorialSectionSwipeInfoWrapper = styled('div')({
  [dimensions.SCREEN_MIN_MD]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '90px',
  },
});
