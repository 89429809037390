import React, { ReactElement, useState } from 'react';
import TextInput from '../../TextInput';
import Button from '../../buttons/Button';
import {
  DiscountDetails,
  DiscountText,
  ErrorDisplay,
  PromoCodeContainer,
  PromoInputContainer,
  SummaryAmountText,
} from './PromoCodeStyles';
import { promoCodeCheck } from '../../../sdk';
import { borders } from '../../../style';
import { handlePaymentVysionEvent } from '../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { PaymentOnFocusVysionEventsEnum } from '../../../utils/vysion/payment/onFocus';
import { PaymentClickVysionEventsEnum } from '../../../utils/vysion/payment/click';
import { getBrowserDetails } from '../../../utils/helpers';

const isMobile = getBrowserDetails().isMobile;
interface PromoCodeProps {
  currencyCharCode: any;
  currencyCode: any;
  discountAmount: any;
  setDiscount: any;
  setDiscountAmount: any;
  discount: any;
  profile: any;
  selectedPackage: any;
  applyPromoCode: any;
  setSelectedPackageData: any;
  taxCountry: any;
  paymentChannel?: string | null;
}

const PromoCode = ({
  currencyCharCode,
  currencyCode,
  discountAmount,
  discount,
  setDiscount,
  setDiscountAmount,
  profile,
  selectedPackage,
  applyPromoCode,
  setSelectedPackageData,
  taxCountry,
  paymentChannel = null,
}: PromoCodeProps): ReactElement => {
  const [promoCode, setPromoCode] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState({ status: 'default', message: '' });

  const renderDiscount = discount => {
    if (discount) {
      if (discount.type === 'percentage' && discount.discount_rate) {
        const discountRate = Math.floor(discount.discount_rate * 100);

        return `${discountRate}% OFF promo applied!`;
      }

      if (discount.type === 'fixed' && discount.discount_amount) {
        return `${String.fromCharCode(currencyCharCode)}${discount.discount_amount} OFF promo applied!`;
      }
    }

    return 'Discount Amount';
  };

  const handleApplyPromoCode = e => {
    e.preventDefault();
    setProcessing(true);

    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PaymentClickVysionEventsEnum.CLICK_PROMO_CODE_APPLY,
    });

    promoCodeCheck(
      profile.profile.data.hash_id,
      promoCode,
      selectedPackage.identifier,
      false,
      taxCountry,
      true,
      paymentChannel
    )
      .then(response => {
        if (response.data === null) {
          setError({
            status: 'error',
            message: 'Invalid code',
          });
          setProcessing(false);
          return;
        }
        if (response.data.discount_rate) {
          const discountRate = response.data.discount_rate;
          discountAmount = selectedPackage.price * discountRate;
        }

        if (response.data.discount_amount) {
          discountAmount = response.data.discount_amount;
        }

        if (response.data.package) {
          setSelectedPackageData(response.data.package.data);
        }

        setDiscount(response.data);
        setDiscountAmount(discountAmount);
        applyPromoCode(promoCode);
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
        setError({
          status: 'error',
          message: 'Error checking promo package.',
        });
      });
  };

  if (parseFloat(discountAmount) > 0) {
    return (
      <DiscountDetails>
        <DiscountText>{renderDiscount(discount)}</DiscountText>
        <SummaryAmountText>
          - {String.fromCharCode(currencyCharCode)}
          {parseFloat(discountAmount).toFixed(2)} {currencyCode}
        </SummaryAmountText>
      </DiscountDetails>
    );
  }

  return (
    <PromoCodeContainer>
      <PromoInputContainer>
        <TextInput
          id="promo_code"
          type="text"
          placeholder="Enter Promo Code"
          value={promoCode}
          onFocus={() => {
            handlePaymentVysionEvent({
              action: VYSION_ACTIONS.ON_FOCUS,
              event: PaymentOnFocusVysionEventsEnum.PROMO_CODE,
            });
          }}
          customStyle={{
            width: 200,
            borderRadius: '6px',
            paddingBottom: 0,
            marginRight: 0,
          }}
          customInputStyle={{
            borderRadius: '6px !important',
            padding: '10px 14px  !important',
            boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.12) inset',
            border: error.status === 'error' ? '1px solid #F00 !important' : borders.DEFAULT + ' !important',
          }}
          onChange={e => {
            if (e.target.value.length <= 0) {
              setError({ status: 'default', message: '' });
            }

            setPromoCode(e.target.value);
          }}
        />
        {error.status === 'error' && !isMobile && <ErrorDisplay>{error.message}</ErrorDisplay>}
      </PromoInputContainer>
      <Button
        buttonType="primary"
        disabled={promoCode.length <= 0 || processing}
        customStyle={{ fontSize: 13, height: 32, padding: '8px 18px', borderRadius: '90px' }}
        onClick={handleApplyPromoCode}
      >
        Apply
      </Button>
      {error.status === 'error' && isMobile && <ErrorDisplay>{error.message}</ErrorDisplay>}
    </PromoCodeContainer>
  );
};

export default PromoCode;
