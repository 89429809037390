import React, { useState, useRef, Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import Lightbox from 'react-image-lightbox';
import { dimensions } from '../../../../style';
import { useDashboardDiscoverMode } from '../../../../contexts/DashboardDiscoverModeContext';
import { VIDEO_PREVIEW_DEFAULT } from '../../../../constants/dashboardDiscoverMode';
import SliderPlayIcon from '../../../../atoms/icons/SliderPlayIcon';
import SliderPauseIcon from '../../../../atoms/icons/SliderPauseIcon';

const VideoContainer = styled('div')({
  background: 'rgba(0, 0, 0, 0.85)',
  position: 'fixed',
  zIndex: 1006,
  color: '#ffffff',
  textAlign: 'center',
  left: '50%',
  top: 60,
  transform: 'translate(-50%, 0)',
  maxWidth: 480,
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  [dimensions.SCREEN_MAX_SM]: {
    maxWidth: 'none',
  },
});

const VideoAction = styled('div')({
  zIndex: 1001,
  height: '100%',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VideoPlayer = styled('video')({
  margin: '0 auto',
  width: 'calc(100% - 20px)',
  zIndex: 1000,
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  [dimensions.SCREEN_MAX_MD_2]: {
    maxWidth: 'none',
    width: 'calc(100% - 20px)',
  },
});

const DashboardDiscoverModeVideoPreviewLightBox: React.FC = () => {
  const { videoPreview, setVideoPreview } = useDashboardDiscoverMode();
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(true);

  const onCloseLightBox = () => {
    setVideoPreview(VIDEO_PREVIEW_DEFAULT);
    setShowPlayButton(false);
    setShowPauseButton(false);
  };

  const videoRef = useRef();

  const onVideoPlay = () => {
    if (videoPreview.media && videoRef && videoRef.current) {
      videoRef.current.play();
      setShowPlayButton(false);
    }
  };

  const handlePauseIconWhilePlaying = () => {
    setShowPlayButton(false);
    setShowPauseButton(true);
    setTimeout(() => {
      setShowPauseButton(false);
    }, 100);
  };

  const onVideoPause = () => {
    if (videoPreview.media && videoRef && videoRef.current) {
      videoRef.current.pause();
      setShowPlayButton(true);
      setShowPauseButton(false);
    }
  };

  useEffect(() => {
    if (videoRef && videoPreview.media && videoRef.current) {
      videoRef.current.addEventListener('play', handlePauseIconWhilePlaying);
      return () => {
        videoRef && videoRef.current && videoRef.current.removeEventListener('play', handlePauseIconWhilePlaying);
        setShowPlayButton(false);
        setShowPauseButton(false);
      };
    }
  }, [videoPreview.media, videoRef]);

  return videoPreview.media ? (
    <Fragment>
      <Lightbox
        animationDisabled
        mainSrc=""
        onCloseRequest={onCloseLightBox}
        clickOutsideToClose={true}
        enableZoom={false}
        reactModalStyle={{
          overlay: {
            background: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        wrapperClassName="discover-mode-video-lightbox"
      />
      <VideoContainer>
        <VideoPlayer
          autoPlay={true}
          controls={!showPlayButton}
          playsInline
          ref={videoRef}
          poster={videoPreview.thumbnail}
          onEnded={() => {
            setShowPlayButton(true);
            setShowPauseButton(false);
          }}
        >
          <source src={videoPreview.media} />
        </VideoPlayer>
        {showPlayButton && (
          <VideoAction onClick={onVideoPlay} style={{ cursor: 'pointer' }}>
            <SliderPlayIcon style={{ position: 'absolute', cursor: 'pointer', width: 'auto' }} onClick={onVideoPlay} />
          </VideoAction>
        )}
        {showPauseButton && (
          <VideoAction onClick={onVideoPause} style={{ cursor: 'pointer' }}>
            <SliderPauseIcon
              style={{ position: 'absolute', cursor: 'pointer', width: 'auto' }}
              onClick={onVideoPause}
            />
          </VideoAction>
        )}
      </VideoContainer>
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default DashboardDiscoverModeVideoPreviewLightBox;
