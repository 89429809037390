import { mixpanelTrackEventFromBE } from '.';
import { MixpanelDefaultStateProps } from '../../constants/mixpanel';

export const LIGHTBOX_CLOSED_EVENT_NAME = 'Lightbox Closed';

export const LIGHTBOX_CLOSED_EVENT_PROPERTIES = {
  'Lightbox Photos Seen': 1,
};

export const updateMixpanelLightBoxClosedPayload = (setMixpanelDataByKeyHandler: any, count: number): void => {
  setMixpanelDataByKeyHandler({
    key: LIGHTBOX_CLOSED_EVENT_NAME,
    payload: {
      ...LIGHTBOX_CLOSED_EVENT_PROPERTIES,
      'Lightbox Photos Seen': count,
    },
  });
};

export const mixpanelTrackLightBoxClosed = async (
  mixpanelData: MixpanelDefaultStateProps,
  resetMixpanelData?: (key: typeof LIGHTBOX_CLOSED_EVENT_NAME) => void
): Promise<void> => {
  const payload = {
    event_properties: {
      ...LIGHTBOX_CLOSED_EVENT_PROPERTIES,
      ...mixpanelData[LIGHTBOX_CLOSED_EVENT_NAME],
    },
  };

  await mixpanelTrackEventFromBE({
    event_name: LIGHTBOX_CLOSED_EVENT_NAME,
    payload,
  });

  if (resetMixpanelData) {
    resetMixpanelData(LIGHTBOX_CLOSED_EVENT_NAME);
  }
};
