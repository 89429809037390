import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Payment',
};

export const enum PaymentClickVysionEventsEnum {
  CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_ON = 'paymentAutoCreditTopUpOn',
  CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_OFF = 'paymentAutoCreditTopUpOff',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS = 'accountMenuPaymentSettings',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_ON = 'accountMenuPaymentSettingsAutoCreditTopUpOn',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_OFF = 'accountMenuPaymentSettingsAutoCreditTopUpOff',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_THRESHOLD = 'accountMenuPaymentSettingsCreditThreshold',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_PACKAGE = 'accountMenuPaymentSettingsCreditPackage',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_SAVE = 'accountMenuPaymentSettingsSave',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL = 'accountMenuPaymentSettingsAutoTopUpSuccessful',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_CLOSE = 'accountMenuPaymentSettingsAutoTopUpSuccessfullClose',
  CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_OK = 'accountMenuPaymentSettingsAutoTopUpSuccessfullOk',
  CLICK_PROMO_CODE_APPLY = 'paymentPromoCodeApply',
  CLICK_GOOGLE_PAY = 'paymentGooglePay',
  CLICK_APPLE_PAY = 'ApplePay',
}

export const paymentClickVysionEvents = {
  [PaymentClickVysionEventsEnum.CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_ON]: {
    ...commonValues,
    eventName: 'payment-autoCreditTopUp-on',
    component: 'Auto Credit Top-Up Toggle',
    extraInfo: 'on',
  },
  [PaymentClickVysionEventsEnum.CLICK_PAYMENT_AUTO_CREDIT_TOP_UP_OFF]: {
    ...commonValues,
    eventName: 'payment-autoCreditTopUp-off',
    component: 'Auto Credit Top-Up Toggle',
    extraInfo: 'off',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings',
    component: 'Payment Settings',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_ON]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoCreditTopUp-on',
    component: 'Auto Credit Top-Up Toggle',
    extraInfo: 'on',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_OFF]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoCreditTopUp-off',
    component: 'Auto Credit Top-Up Toggle',
    extraInfo: 'off',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_THRESHOLD]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: '',
    component: 'Credit Threshold',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_CREDIT_PACKAGE]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: '',
    component: 'Top-Up Credit Package',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_SAVE]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-save',
    component: 'Save',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoTopUpSuccessful',
    component: 'Auto Top-Up Success Modal',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_CLOSE]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoTopUpSuccessful-close',
    component: 'Auto Top-Up Success Modal',
    extraInfo: 'Close',
  },
  [PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_SUCCESSFULL_OK]: {
    ...commonValues,
    page: 'Account Menu',
    eventName: 'accountMenu-paymentSettings-autoTopUpSuccessful-ok',
    component: 'Auto Top-Up Success Modal',
    extraInfo: 'Ok',
  },
  [PaymentClickVysionEventsEnum.CLICK_PROMO_CODE_APPLY]: {
    ...commonValues,
    eventName: 'payment-promoCode-apply',
    component: 'Promo Code',
    extraInfo: 'Apply',
  },
  [PaymentClickVysionEventsEnum.CLICK_GOOGLE_PAY]: {
    ...commonValues,
    eventName: 'payment-googlePay',
    component: 'Google Pay Button',
    extraInfo: '',
  },
  [PaymentClickVysionEventsEnum.CLICK_APPLE_PAY]: {
    ...commonValues,
    eventName: 'ApplePay',
    component: 'Apple Pay Button',
    extraInfo: '',
  },
};
