import { isFD } from '../config/Master';
import * as sdk from '../sdk';

export const storeGlobalNotifications = (payload: any): any => {
  return {
    type: 'STORE_GLOBAL_NOTIFICATION',
    payload,
  };
};

export const requestGlobalNotifications = (): any => {
  return {
    type: 'REQUEST_GLOBAL_NOTIFICATION',
  };
};

export const clearGlobalNotifications = (): any => {
  return {
    type: 'CLEAR_GLOBAL_NOTIFICATION',
  };
};

export const getGlobalNotifications = (): any => {
  if (!isFD) {
    return (dispatch: any): any => {
      dispatch(clearGlobalNotifications());
    };
  }

  return (dispatch: any): any => {
    dispatch(clearGlobalNotifications());
    dispatch(requestGlobalNotifications());
    sdk.getGlobalNotifications().then(response => {
      dispatch(storeGlobalNotifications(response.data.data));
    });
  };
};
