/**
 * Find a key on an multi-level object and return the value
 *
 * @param {array} path
 * @param {object} object
 * @returns mixed
 */

import * as Sentry from '@sentry/react';

import Cookies from 'universal-cookie';
import {
  MAX_COOKIE_AGE,
  MAX_SUBSCRIPTION_HRS,
  SITE_ID,
  vysionApiKey,
  vysionStage,
  vysionEnabled,
  googleMapKey,
  FULL_URL,
  DDC_FINGER_PRINTING_URL,
  isProdEnv,
  IS_LOCAL,
  EXCLUDE_HTTP_REQUESTS,
} from '../config/Master';
import _upperFirst from 'lodash/upperFirst';
import DetectRTC from 'detectrtc';
import { getLocalItem } from '../common';
import moment from 'moment';
import {
  isIOS,
  isMobile as isMobileDetect,
  isMobileOnly,
  isTablet as isTabletDetect,
  isDesktop as isDesktopDetect,
} from 'react-device-detect';
import { ALLOW_THEME_COOKIE_NAME, HEIGHTS_VALUES, countriesEU } from '../config/constants';

import Vysion from 'react-vysion';
import { s3BucketDirect } from '../config/Master';
import { CountryRegionData } from 'react-country-region-selector';
import mixpanel from 'mixpanel-browser';
import {
  EVENT_NAMES,
  MIXPANEL_DEBUG,
  MIXPANEL_DEFAULT_STATE,
  MIXPANEL_PROJECT_TOKEN,
  MIXPANEL_ENABLE,
  TrackSearchViewProps,
} from '../constants/mixpanel';
import {
  BODY_TYPE_OPTIONS,
  EDUCATION_OPTIONS,
  FEMALE_BODY_TYPE_OPTIONS,
  INTERESTS_OPTIONS,
  MALE_BODY_TYPE_OPTIONS,
  MALE_USER_EXCLUDE_BODY_TYPES,
} from '../components/join/constants';
import { getPackageSubTotal } from './billing';
import { useMemo } from 'react';

const vysionInit = (): void => {
  vysionEnabled && Vysion.init('2.5.1', vysionStage, vysionApiKey);
};

const composeOptionsVysion = (param: any, prop: any): any => {
  // filter props we need and constuct an object payload and pass these props in
  return param[prop] ? { ...param[prop] } : param[prop];
};

const triggerTracking = (eventType: string, ref: any, actionMethod: any): any => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e, eventType), false);

    return () => {
      ref.current.removeEventListener('click', () => null, false);
    };
  }
};

const convertTitleCase = (str: string): string => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

const convertSentanceTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

const urlBase64ToUint8Array = (base64String: string): any => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const isLandscape = window.innerHeight < window.innerWidth;

const scrollToTop = (behavior = 'smooth'): void => {
  window.scrollTo({ top: 0, behavior });
};

// method to format formData before submission of it to update profile info
const formatFormData = (formData: any): any => {
  for (const key in formData) {
    const val = formData[key];
    if (val === '-') formData[key] = null;
  }

  const bodyTypeArray = [];

  if (formData['body_types'].length > 0) {
    formData['body_types'].map(item => {
      return bodyTypeArray.push(item.value);
    });
    delete formData['body_type'];
    formData['body_types'] = bodyTypeArray;
  } else {
    formData['body_type'] = '';
  }

  return formData;
};

const KeyGenerator = (): number => Math.floor(Math.random() * 999);

const computeAge = (dateString: string): number => {
  // Create Date objects in Las Vegas time
  const todayLasVegas = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
  const today = new Date(todayLasVegas);
  const birthDate = new Date(dateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const find = (path: any, object: any): any => {
  if (path.length === 0) {
    return false;
  }

  if (typeof object !== 'object' || object === null || !(path[0] in object)) {
    return false;
  }

  const value = object[path[0]];

  path.shift();

  if (path.length > 0) {
    return find(path, value);
  }

  return value;
};

const googleAuth = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      const resolveAuth2 = () => {
        const auth = window.gapi.auth2.init({ client_id: process.env.REACT_APP_EXTERNAL_GOOGLE_CLIENT_ID });
        resolve(auth);
      };

      if (window.gapi && typeof window.gapi.load === 'function' && !window.gapi.auth2) {
        return window.gapi.load('auth2', resolveAuth2);
      }

      resolveAuth2();
    } catch (error) {
      reject(error);
    }
  });
};

const loadGoogleGsiClient = (): undefined | HTMLScriptElement => {
  const scriptElement = document.getElementById('googleGsiClient');

  if (scriptElement) {
    scriptElement.remove();
  }

  const script = document.createElement('script');
  script.src = 'https://accounts.google.com/gsi/client';
  script.id = 'googleGsiClient';
  script.defer = true;
  script.async = true;

  document.body.appendChild(script);

  return script;
};

const isEmpty = (value: any): boolean => {
  if (typeof value === 'undefined' || value === '' || value === null || value !== value) {
    return true;
  }

  if (value.length <= 0) {
    return true;
  }

  return Object.keys(value).length === 0 && value.constructor === Object;
};

// cookies outside each cookie function
// otherwise will fail on test because cookies is scoped per function
const cookies = new Cookies();
const cookieDomain = IS_LOCAL ? 'localhost' : process.env.REACT_APP_COOKIE_DOMAIN;

const getCookie = (cookieName: string): any => {
  return cookies.get(cookieName);
};

const removeCookie = (cookieName: string): void => {
  cookies.remove(cookieName, {
    path: '/',
    expires: new Date(Date.now() + MAX_COOKIE_AGE),
    domain: cookieDomain,
  });
};

const setCookie = (
  cookieName: string,
  cookieValue: any = true,
  noExpiry = false,
  expiration: number | null = null,
  sameSite: boolean | 'none' | 'lax' | 'strict' | undefined = undefined
): void => {
  // adds 1 year to expiry if noExpiry is true
  const expiryAge = expiration ? expiration : noExpiry ? 10 * 365 * 24 * 60 * 60 * 100 : MAX_COOKIE_AGE;
  cookies.set(cookieName, cookieValue, {
    path: '/',
    expires: new Date(Date.now() + expiryAge),
    domain: cookieDomain,
    sameSite: sameSite,
  });
};

// this checks if ok to use JSON.parse
// prevents JSON.parse: unexpected character error
const canConvertToJson = (str: string): boolean => {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

const iOSwithHomeBtn = (): boolean => {
  let hasHomeBtn = false;
  const currentDeviceInfo = JSON.stringify({
    width: window.screen.width > window.screen.height ? window.screen.height : window.screen.width,
    height: window.screen.width > window.screen.height ? window.screen.width : window.screen.height,
    ratio: window.devicePixelRatio,
  });

  const modelData = {
    '2G/3G/3GS': {
      width: 320,
      height: 480,
      ratio: 1,
    },
    '4/4S': {
      width: 320,
      height: 480,
      ratio: 2,
    },
    '5/5S/5C/SE': {
      width: 320,
      height: 568,
      ratio: 2,
    },
    '6/6S/7/8': {
      width: 375,
      height: 667,
      ratio: 2,
    },
    '6+/6S+/7+/8+': {
      width: 414,
      height: 736,
      ratio: 3,
    },
  };

  Object.entries(modelData).forEach(model => {
    if (JSON.stringify(model[1]) === currentDeviceInfo) {
      hasHomeBtn = true;
    }
  });

  return hasHomeBtn;
};

const browserName = () => {
  let sBrowser;
  const sUsrAg = window.navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Firefox';
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'Samsung Internet';
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'MS IE';
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'MS Edge';
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome';
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Safari';
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = 'unknown';
  }

  return sBrowser;
};

const webkitVerification = (userAgent, browserType) => {
  const isWebkit = !!userAgent.match(/WebKit/i);

  switch (browserType) {
    case 'safari':
      return isWebkit && !userAgent.match(/(CriOS|FxiOS|OPiOS|mercury|Chrome)/i);
    case 'chrome':
      return isWebkit && !!userAgent.match(/(CriOS|FxiOS|OPiOS|mercury|Chrome)/i);
    default:
      return;
  }
};

const ternaryFunc = (evaluator: any, result1: any, result2: any): any => {
  return evaluator ? result1 : result2;
};

const evaluateOrOperator = (firstVal: any, secondVal: any): any => {
  return firstVal || secondVal;
};

const getStandaloneStatus = window.matchMedia ? window.matchMedia('(display-mode: standalone)').matches : false;

// Detects if browser allows blur https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
const isBlurAllowed = () => {
  const test = function(regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return false;
    case test(/opr/i) && evaluateOrOperator(!!window.opr, !!window.opera):
      return false;
    case test(/chrome/i) && !!window.chrome:
      return true;
    case test(/trident/i):
      return false;
    case test(/firefox/i):
      return true;
    case test(/safari/i):
      return false;
    default:
      return false;
  }
};

// can be used to detect anywhere on the site
const getBrowserDetails = (): any => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  // specific detection for smaller devices
  const isSmallViewPort = window.innerHeight < 680;
  const isNotDesktopWidth = window.innerWidth < 1024;

  // Detects if device is Tablet
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );

  // Detects device name
  const deviceName = () => {
    let device = 'Unknown';
    const ua = {
      Linux: /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      ChromeOS: /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  };

  // Detects if device is on iOS
  const isIosIphone = () => {
    return /iphone|ipod/.test(userAgent);
  };

  const isIosIpad = () => {
    return isTabletDetect && isIOS && !isMobileOnly && isMobileDetect;
  };

  const isSafari = () => {
    return webkitVerification(userAgent, 'safari');
  };

  const isChrome = () => {
    return webkitVerification(userAgent, 'chrome');
  };

  // Detects if device is in standalone mode
  const isInWebAppiOS = 'standalone' in window.navigator && window.navigator.standalone;
  const isInWebAppChrome = getStandaloneStatus;
  const isInStandaloneMode = () => evaluateOrOperator(isInWebAppiOS, isInWebAppChrome);

  // checks if not iOS and permission
  const notificationAllowed = typeof Notification === 'function' && Notification.permission === 'granted';

  // checks if mobile browser
  const scWidth = ternaryFunc(window.innerWidth > 0, window.innerWidth, screen.width);
  const isMobile =
    evaluateOrOperator(typeof window.orientation !== 'undefined', navigator.userAgent.indexOf('IEMobile') !== -1) &&
    scWidth < 1024;

  // Returns true not Standalone mode
  const isIos = evaluateOrOperator(isIosIphone(), isIosIpad());
  const showInstall = isIos && isSafari() && !isInStandaloneMode();
  const showInstallIosChrome = isIos && isChrome() && !isInStandaloneMode();
  const showAndroidInstall = !isIos && isMobile && isChrome() && !isInStandaloneMode();

  const RTCunsupportedIOS =
    DetectRTC.osName === 'iOS' &&
    evaluateOrOperator(
      evaluateOrOperator(DetectRTC.browser.name === 'Chrome', DetectRTC.browser.name === 'Firefox'),
      DetectRTC.browser.name === 'Opera'
    );

  // const wImgCalibration = isMobile && !isTablet ? window.innerWidth - 40 : 370;
  // const hImgCalibration = isMobile && !isTablet ? wImgCalibration * 1.325 : 490;
  const wImgCalibration = isMobile && !tabletWidth ? window.innerWidth - 40 : 292;
  const hImgCalibration = isMobile && !tabletWidth ? wImgCalibration * 1.325 : 390;
  const wImgModalCalibration = isMobile && !tabletWidth ? window.innerWidth - 40 : 670;
  const hImgModalCalibration = isMobile && !tabletWidth ? wImgCalibration * 1.325 : 'auto';

  return {
    showInstallIosChrome,
    showInstall,
    showAndroidInstall,
    isIpad: isIosIpad(),
    isStandaloneMode: isInStandaloneMode(),
    notificationAllowed,
    isSafari: isSafari(),
    isMobile,
    isTablet,
    deviceName,
    browserName,
    isIos,
    isBlurAllowed: isBlurAllowed(),
    wImgCalibration,
    hImgCalibration,
    wImgModalCalibration,
    hImgModalCalibration,
    RTCunsupported: evaluateOrOperator(RTCunsupportedIOS, !DetectRTC.isWebRTCSupported),
    isDesktop: isDesktopDetect,
    isSmallViewPort,
    isNotDesktopWidth,
    scWidth,
    isChrome: isChrome(),
  };
};

const tabletWidth = screen.width >= 712 && screen.width <= 834;

const ipadNoHomeButton = (tabletWidth && screen.height === 1194) || screen.height === 1191 || screen.height === 1180;

const IsMobileViewPort =
  getBrowserDetails().isMobile && !getBrowserDetails().isTablet && !(screen.width >= 768 && screen.width <= 820);

/*
extra step to check if there is a network to prevent false
information when navigator.onLine persistently returns false
https://bugs.chromium.org/p/chromium/issues/detail?id=678075
*/
const checkNetworkReachable = (): boolean => {
  const ImageObject = new Image();
  ImageObject.src = `${s3BucketDirect}icons/icon-72x72.png?_t=${Math.floor(Math.random() * 999)}`;
  return ImageObject.height > 0;
};

const checkPwaTrue = (): boolean => {
  return process.env.REACT_APP_PWA === 'true';
};

const checkActiveSubscription = (): boolean => {
  const currentSubscription = getLocalItem('pushSubscription');
  const currentSubscriptionDate = currentSubscription !== null && JSON.parse(currentSubscription).date;
  const currentDate = moment(new Date()); //current date
  const subscriptionDate = moment(currentSubscriptionDate); // subscription date
  const dateDuration = moment.duration(currentDate.diff(subscriptionDate));
  const hrsDiff = dateDuration.asHours();

  return hrsDiff >= MAX_SUBSCRIPTION_HRS;
};

const addCommas = (nStr: string): string => {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
};

const convertPathToTitle = (pathName: string): string => {
  const titleArray = pathName.split('/');
  let convertedTitle = _upperFirst(titleArray[1]);
  const dashedTitle = convertedTitle.split('-');
  if (dashedTitle.length === 2) {
    convertedTitle = `${_upperFirst(dashedTitle[0])} ${_upperFirst(dashedTitle[1])}`;
  }
  return convertedTitle;
};

const getBlob = (dataUrl: any): any => {
  const arr = dataUrl.split(',');
  const mime = (arr && arr.length > 0 && arr[0].match(/:(.*?);/)[1]) || 'jpg';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const isQAautomation = (): any => {
  return getCookie('wyp_qapostdeploycheckbypass') && parseInt(getCookie('wyp_qapostdeploycheckbypass')) === 1;
};

const getVideoPermissions = (): Promise<any> => {
  return new Promise((res, rej) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(stream => {
        res(stream);
      })
      .catch(err => {
        if (isQAautomation()) {
          throw new Error(`Unable to fetch stream ${err}`);
        } else {
          rej(err);
        }
      });
  });
};

const loadGoogleMaps = (callback: any = () => null): any => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `//maps.googleapis.com/maps/api/js?libraries=places&language=en&key=${googleMapKey}`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const useFilteredBodyType = (gender: string): Record<string, unknown>[] => {
  const bodyTypeOptionsFilter = useMemo(() => (gender === 'Male' ? MALE_BODY_TYPE_OPTIONS : FEMALE_BODY_TYPE_OPTIONS), [
    gender,
  ]);

  return bodyTypeOptionsFilter;
};

const renderTaxLabel = (selectedPackage: any, discountAmount = 0): number | string => {
  const discount = customToFixed(discountAmount);

  if (selectedPackage.taxes) {
    return getPackageSubTotal(selectedPackage, discountAmount).toFixed(2);
  }

  const totalAmount = selectedPackage.price - discount;
  return totalAmount > 0 ? totalAmount.toFixed(2) : '0.00';
};

const isDarkMode = (): boolean => {
  // not checking from
  // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return getCookie(ALLOW_THEME_COOKIE_NAME) === 'true';
};

const removeBodyScroll = (): void => {
  setTimeout(() => {
    document.body.style.overflow = 'scroll';
  }, 500);
};

const isEU = (cName: string): boolean => {
  return countriesEU.some(country => country.name === cName);
};

const inverseVal = (toggle, val) => {
  return toggle ? !val : val;
};

const lazilyEvaluateVal = (
  value: any,
  level: any,
  inverse: any,
  propertyChild1: any,
  propertyChild2 = null,
  propertyChild3 = null
): any => {
  switch (level) {
    case 1:
      return value && inverseVal(inverse, value[propertyChild1]);
    case 2:
      return value && value[propertyChild1] && inverseVal(inverse, value[propertyChild1][propertyChild2]);
    case 3:
      return (
        value &&
        value[propertyChild1] &&
        value[propertyChild1][propertyChild2] &&
        inverseVal(inverse, value[propertyChild1][propertyChild2][propertyChild3])
      );
    default:
      return false;
  }
};

const TOSPath = (cName: string): string => {
  if (isEU(cName) && SITE_ID === 'wyp') {
    return 'terms/eu';
  }

  return 'terms';
};

const customToFixed = (floatingNumber: number): number => {
  return Math.trunc(floatingNumber * 100) / 100;
};

const termsOfUseUrl = (isEu: boolean): string => {
  if (isEu) {
    return `${FULL_URL}/${SITE_ID === 'wyp' ? 'terms/eu' : 'terms-conditions-eu'}`;
  }

  return `${FULL_URL}/${SITE_ID === 'wyp' ? 'terms' : 'terms-conditions'}`;
};

const checkCardExpiry = (cardExpiry: string, cardExpMonth: string, cardExpYear: string): string => {
  if (cardExpiry !== '') {
    return cardExpiry;
  } else if (cardExpMonth && cardExpYear) {
    return `${('0' + cardExpMonth).slice(-2)} / ${cardExpYear.toString().slice(-2)}`;
  } else {
    return cardExpiry;
  }
};

// DDC
type FingerPrintingProps = {
  event: {
    origin: string;
    data: string;
  };
  handleUpdateFingerPrint(refId: string, data: unknown): void;
  profile: any;
};

const handleDeviceFingerPrinting = ({ event, handleUpdateFingerPrint, profile }: FingerPrintingProps): void => {
  if (event.origin === DDC_FINGER_PRINTING_URL) {
    const data = JSON.parse(event.data);
    let sessionId = '';

    console.warn('Merchant received a message:', data);

    if (typeof data === 'undefined') {
      logSentryForDDC('FingerPrinting No data from event', data, profile);
    }

    if (data !== undefined) {
      console.warn('Songbird ran DF successfully');

      sessionId = data.Payload?.SessionId || data.SessionId;

      console.warn(`SessionId: ${sessionId}`);

      if (sessionId) {
        console.warn('Using Session ID:', sessionId);
      }
    }

    handleUpdateFingerPrint(sessionId, data);
  }
};

const removeForbiddenCharacters = (stringInput: string): string => {
  const forbiddenChars = ['-', ' ', ':'];

  for (const char of forbiddenChars) {
    stringInput = stringInput.split(char).join('');
  }
  return stringInput;
};

const calculatePercentage = (partialValue: number, totalValue: number): number => {
  return (100 * partialValue) / totalValue;
};

const getCountryCode = (countryName: string): any => {
  // workaround for Deutschland to use Germany
  const isDeutschland = countryName?.toLowerCase() === 'deutschland';
  const countryNameFix = isDeutschland ? 'Germany' : countryName;

  const cRegionData = CountryRegionData.filter(country => country[0] === countryNameFix)[0];
  let filteredData = cRegionData;

  // condition to fallback United States if country region fails to provide data
  switch (cRegionData) {
    case undefined:
      return (filteredData = CountryRegionData.filter(country => country[0] === 'United States')[0]);
    default:
      return filteredData;
  }
};

const removeFirstWord = (str: string): string => {
  const indexOfSpace = str.indexOf(' ');

  if (indexOfSpace === -1) {
    return '';
  }

  return str.substring(indexOfSpace + 1);
};

const getQueryParam = (url: string, param: string): string => {
  // Expects a raw URL
  // eslint-disable-next-line no-empty-character-class
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = '[?&]' + param + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
};

const profileAllAboutFields = ({ profile, isIncludeRequiredFields = true }: any): boolean => {
  const profileData = profile && profile.profile && profile.profile.data;
  const noWishlist = profileData && profileData.account_type === 'Generous';

  const restApproved = noWishlist
    ? Boolean((profileData && profileData.children) || (profile && profile.children)) &&
      Boolean((profileData && profileData.smoking) || (profile && profile.smoking)) &&
      Boolean((profileData && profileData.drinking) || (profile && profile.drinking)) &&
      Boolean((profileData && profileData.education) || (profile && profile.education)) &&
      Boolean((profileData && profileData.occupation) || (profile && profile.occupation)) &&
      Boolean((profileData && profileData.income) || (profile && profile.income))
    : Boolean((profileData && profileData.children) || (profile && profile.children)) &&
      Boolean((profileData && profileData.smoking) || (profile && profile.smoking)) &&
      Boolean((profileData && profileData.drinking) || (profile && profile.drinking)) &&
      Boolean((profileData && profileData.education) || (profile && profile.education));

  if (isIncludeRequiredFields) {
    return profileData && profileData.required_fields_completed && restApproved;
  }

  return profileData && restApproved;
};

const profilePercentage = (onboarding: any): number => {
  const { profile, publicPhotos, publicVideos } = onboarding;
  const profileData = profile && profile.profile && profile.profile.data;
  const noWishlist = profileData && profileData.account_type === 'Generous';
  const photoCount = publicPhotos > publicVideos && publicPhotos;

  // const calculatePhotoPercentage = isGenerous => {
  const calculatePhotoPercentage = () => {
    let photoPercentage = 0;

    if (photoCount > 0) {
      photoPercentage = 15;
    }
    /*
    if (isGenerous) {
      switch (true) {
        case photoCount === 1:
          photoPercentage = 18.6;
          break;
        case photoCount > 1:
          photoPercentage = 37.2;
          break;
        default:
          break;
      }
    } else {
      switch (true) {
        case photoCount === 1:
          photoPercentage = 9.3;
          break;
        case photoCount === 2:
          photoPercentage = 18.6;
          break;
        case photoCount === 3:
          photoPercentage = 27.9;
          break;
        case photoCount > 3:
          photoPercentage = 37.2;
          break;
        default:
          break;
      }
    }
    */
    return photoPercentage;
  };

  // PROFILE COMPLETION
  // 12.5% - Verified Email

  // const emailVerified = emailConfirmed || (profile && profile.email_confirmed) ? 12.5 : 0;
  const emailVerified = 0;

  // 9.3% per Photo for Attractive until 4 Photos
  // 18.6% per Photo for Generous until 2 Photos
  const picApproved = photoCount ? calculatePhotoPercentage(noWishlist) : 0;

  // 38.3% Height, Body &  Interests
  const profileInfo =
    profileData && profileData.required_fields_completed && checkInterestStatus(profileData) ? 38.3 : 0;

  // 13.33% (All ‘About Me’ fields ie. Height, Body, Children, Smoking, etc)
  const profileApproved = profileAllAboutFields({ profile, isIncludeRequiredFields: false }) ? 13.33 : 0;

  // 16.66% A Little Bit About Myself
  const aboutApproved = (profileData && profileData.description) || (profile && profile.description) ? 16.66 : 0;
  // 16.66% MyIdeal First Date
  const idealDateApproved = (profileData && profileData.seeking) || (profile && profile.seeking) ? 16.66 : 0;

  const completionSum = (
    emailVerified +
    profileInfo +
    picApproved +
    profileApproved +
    aboutApproved +
    idealDateApproved
  ).toFixed(2);
  const sumPercentage =
    parseFloat(completionSum) >= 95 ? Math.ceil(parseFloat(completionSum)) : parseFloat(completionSum);

  return calculatePercentage(Math.round(sumPercentage), 100);
};

const checkInterestStatus = (response: any): boolean => {
  const datingKeys: string[] = [
    'dating_just_dating',
    'dating_friendship',
    'dating_marriage',
    'dating_sugar',
    'dating_married',
    'dating_adult',
    'dating_mentorship',
  ];

  return datingKeys.some(key => response[key] === true);
};

const initMixPanel = (): void => {
  if (!MIXPANEL_ENABLE) {
    return;
  }

  mixpanel.init(MIXPANEL_PROJECT_TOKEN, { debug: MIXPANEL_DEBUG });

  // use for outside react mixpanel used
  const script = document.createElement('script');
  script.textContent = `
      var isMixpanelEnabled = ${MIXPANEL_ENABLE};

      if (isMixpanelEnabled) {
        mixpanel.init("${MIXPANEL_PROJECT_TOKEN}", {
          debug: ${MIXPANEL_DEBUG}
        });
      }

      function testMixpanelEvent() {
        if (isMixpanelEnabled) {
          mixpanel.identify('dsd1212121221asassasa');
          mixpanel.track('Signed Up', {
            'Signup Type': 'Referral',
          });
        } else {
          console.log('Mixpanel is disabled');
        }
      }
    `;

  document.body.appendChild(script);
};

const resetMixpanel = (): void => {
  // allow reset for tests and staging env. To ensure and handle multiple identities in one device
  if (MIXPANEL_ENABLE && !isProdEnv) {
    mixpanel.reset();
  }
};

const mapMixPanelTrackSearchViewProperties = (formData: any, meta: any): TrackSearchViewProps => {
  const defaultState = MIXPANEL_DEFAULT_STATE[EVENT_NAMES.SEARCH_VIEW];

  const sortingModes = {
    recent: 'Recent Activity',
    newest: 'Newest Members',
    distance: 'Nearest To Me',
  };

  const additionalParamsField = {
    Location: ['city', 'country', 'region'],
    'Looking For': [
      'dating_just_dating',
      'dating_friendship',
      'dating_marriage',
      'dating_sugar',
      'dating_married',
      'dating_adult',
      'dating_mentorship',
    ],
    'Profile Keywords': ['keywords'],
    'ID Verified': ['id_verified'],
    Smoking: ['smoking'],
    Drinking: ['drinking'],
    Children: ['children'],
    'Body Types': ['body_types'],
    Education: ['education'],
  };

  const fields = Object.keys(additionalParamsField)
    .map(key => {
      return additionalParamsField[key].some(property => {
        return (
          (typeof formData[property] === 'string' && formData[property] !== '') ||
          (Array.isArray(formData[property]) && formData[property].length > 0) ||
          (typeof formData[property] === 'boolean' && formData[property])
        );
      })
        ? key
        : '';
    })
    .filter(item => item !== '');

  const payload: TrackSearchViewProps = {
    ...defaultState,
    'Page Number': meta ? meta.pagination?.current_page ?? 0 : 0,
    'Matches Number': meta ? meta.pagination?.total ?? 0 : 0,
    'Sorting Mode': sortingModes[formData.order],
    Parameters: [...defaultState['Parameters'], ...fields],
  };

  if (formData.distance && formData.distance !== undefined && formData.distance !== 50) {
    payload['Distance'] =
      formData.distance.toString() === '20000' ? 'within any distance' : `${formData.distance} miles away`;
    payload['Filter Applied'] = true;
  }

  if (formData.age_min !== 18 || ![40, 55].includes(formData.age_max)) {
    payload['Age From'] = formData.age_min;
    payload['Age To'] = formData.age_max;
    payload['Filter Applied'] = true;
  }

  if (formData.height_min !== 150 || formData.height_max !== 215) {
    payload['Height From'] = formData.height_min;
    payload['Height To'] = formData.height_max;
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Location')) {
    payload['Location'] = additionalParamsField['Location'].map(key => formData[key]).join(', ');
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Looking For')) {
    payload['Looking For'] = additionalParamsField['Looking For']
      .map(item => {
        const key = formData[item];
        return key && INTERESTS_OPTIONS.find(option => option.value === item)?.label;
      })
      .filter(item => item);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Body Types')) {
    payload['Body Types'] = formData.body_types
      .map(item => {
        return BODY_TYPE_OPTIONS.find(option => option.value === item)?.label;
      })
      .filter(item => !MALE_USER_EXCLUDE_BODY_TYPES.includes(item));
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Education')) {
    payload['Education'] = formData.education
      .map(item => {
        return EDUCATION_OPTIONS.find(option => option.id === item)?.value;
      })
      .filter(item => item);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('ID Verified')) {
    payload['ID Verified'] = mapIdentifierValues(formData.id_verified);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Smoking')) {
    payload['Smoking'] = mapIdentifierValues(formData.smoking);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Children')) {
    payload['Children'] = mapIdentifierValues(formData.children);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Drinking')) {
    payload['Drinking'] = mapIdentifierValues(formData.drinking);
    payload['Filter Applied'] = true;
  }

  if (fields.includes('Profile Keywords')) {
    payload['Profile Keywords'] = formData.keywords;
    payload['Filter Applied'] = true;
  }

  return payload;
};

export const mapIdentifierValues = (options: number[]): string => {
  const identifier = [1, 2, 3];
  const values = ['No', 'Yes', 'Occasionally'];

  return options.map(child => values[identifier.indexOf(child)]).join(',');
};

const logException = (error: any, errorInfo?: any, level: Sentry.SeverityLevel = 'fatal', profile?: any): void => {
  const { user = { hash_id: '', username: '' } } = profile || {};

  Sentry.withScope(scope => {
    scope.setUser({
      id: user.hash_id,
      username: user.username,
    });
    scope.setLevel(level);
    scope.setExtra('account_type', user.isGenerous ? 'Generous' : 'Attractve');
    scope.setExtra('uid', user.hash_id);
    scope.setExtra('user_type', 'user');

    if (errorInfo) {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    }

    if (level === 'fatal') {
      scope.setTag('logger', 'react.componentDidCatch');
    }

    Sentry.captureException(error);
  });
};

const logSentryForDDC = (step: string, data: unknown, profile?: any): void => {
  logException(
    'DDC',
    {
      step,
      description: 'Inside the fingerprinting helper',
      eventData: data,
    },
    'info',
    profile
  );
};

const detectUserPlatform = (): string => {
  const userAgent = window.navigator.userAgent;
  let platform = 'Desktop';

  if (userAgent.match(/Android/i)) {
    if (userAgent.match(/wv/i)) {
      platform = 'Android Native';
    } else {
      platform = 'Android Web';
    }
  } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
    platform = 'iOS Web';
  }

  return platform;
};

const getInterestedIn = (userData: any): string => {
  if (userData.looking_female && userData.looking_male) {
    return 'both';
  }

  if (userData.looking_male) {
    return 'men';
  }

  if (userData.looking_female) {
    return 'women';
  }

  return 'both';
};

const copyToClipBoard = (textToCopy: string): any => {
  navigator.clipboard.writeText(textToCopy);
};

const secondsDiffOfTwoDates = (date1: Date, date2: Date): number => {
  const diff = (date2.getTime() - date1.getTime()) / 1000;
  return Math.abs(Math.round(diff));
};

const checkVIP = (pkgs: any): any => {
  const promoPkgs = pkgs.map(pkg => {
    return pkg.identifier;
  });
  const VIP = ['generous_pp_special_2', 'generous_pp_special_1'];
  const pkgVIP = JSON.stringify(VIP) === JSON.stringify(promoPkgs);
  return pkgVIP;
};

const validatePhoneNumber = (input: any): boolean => {
  if (input.trim().length === 0) {
    // Required field is empty
    return false;
  }

  const regex = /^(\+)?([0-9]{9,15})$/;
  if (!regex.test(input)) {
    // Input doesn't match the regex pattern
    return false;
  }

  return true;
};

const enableOneTapGoogleLogin = (enabled = true): any => {
  if (!window?.google) return false;

  setTimeout(() => {
    if (enabled) {
      window.google.accounts?.id.prompt();
    } else {
      window.google.accounts?.id.cancel();
    }
  }, 800);
};

const cmToInches = (cm: number): string => {
  const inches = (cm * 0.393701).toFixed(2);

  if (isNaN(inches)) {
    return `in`;
  }

  return `${inches}in`;
};

const cmToFeetInches = (cm: number): string => {
  if (cm > 213) {
    return `7'0"`;
  }

  if (cm < 153) {
    return `5'0"`;
  }

  const height = HEIGHTS_VALUES[convertCMToInchesHeight(cm)];

  if (!height) {
    return `in`;
  }

  const lastIndex = height.lastIndexOf('(');

  return height.substring(0, lastIndex);
};

const convertCMToInchesHeight = (cm: number): string => {
  if (cm >= 213) {
    return `7.0`;
  }

  if (cm <= 153) {
    return `5.0`;
  }

  const realFeet = (cm * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);

  if (`${feet}.${inches}` === '5.12') {
    return '6.0';
  }

  return `${feet}.${inches}`;
};

const shouldExcludeRequest = (url: string): boolean => {
  return EXCLUDE_HTTP_REQUESTS.some(excluded => url.indexOf(excluded) > 0);
};

export {
  urlBase64ToUint8Array,
  find,
  googleAuth,
  isEmpty,
  getCookie,
  removeCookie,
  setCookie,
  computeAge,
  canConvertToJson,
  getBrowserDetails,
  checkNetworkReachable,
  checkPwaTrue,
  addCommas,
  scrollToTop,
  convertPathToTitle,
  getBlob,
  getVideoPermissions,
  loadGoogleMaps,
  useFilteredBodyType,
  isQAautomation,
  iOSwithHomeBtn,
  isDarkMode,
  removeBodyScroll,
  formatFormData,
  KeyGenerator,
  tabletWidth,
  ipadNoHomeButton,
  IsMobileViewPort,
  isLandscape,
  checkActiveSubscription,
  isEU,
  renderTaxLabel,
  ternaryFunc,
  lazilyEvaluateVal,
  evaluateOrOperator,
  TOSPath,
  vysionInit,
  composeOptionsVysion,
  triggerTracking,
  convertTitleCase,
  convertSentanceTitleCase,
  customToFixed,
  termsOfUseUrl,
  handleDeviceFingerPrinting,
  checkCardExpiry,
  removeForbiddenCharacters,
  calculatePercentage,
  getCountryCode,
  removeFirstWord,
  getQueryParam,
  profilePercentage,
  initMixPanel,
  mapMixPanelTrackSearchViewProperties,
  loadGoogleGsiClient,
  resetMixpanel,
  logException,
  logSentryForDDC,
  profileAllAboutFields,
  detectUserPlatform,
  getInterestedIn,
  copyToClipBoard,
  secondsDiffOfTwoDates,
  checkVIP,
  validatePhoneNumber,
  enableOneTapGoogleLogin,
  cmToInches,
  checkInterestStatus,
  cmToFeetInches,
  convertCMToInchesHeight,
  shouldExcludeRequest,
};
