import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { dimensions } from '../../../../style';
import { primaryBG } from '../../../../theme/colors';

const StyledContainer = styled('div')({
  textAlign: 'center',
  padding: '30px 0',
  display: 'block',

  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
    backgroundColor: primaryBG,
    borderRadius: 8,
    marginTop: -65,
    position: 'relative',
  },
});

interface IBulkUploadActionButtonContainerProps {
  children: ReactNode;
  customStyle?: React.CSSProperties;
}

const GalleryUploadDesktopActionButtonContainer: React.FC<IBulkUploadActionButtonContainerProps> = (
  props: IBulkUploadActionButtonContainerProps
) => {
  const { children, customStyle } = props;
  return <StyledContainer style={{ ...customStyle }}>{children}</StyledContainer>;
};

export default GalleryUploadDesktopActionButtonContainer;
