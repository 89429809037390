import React, { ReactElement } from 'react';

interface DotIconProps {
  fill?: string;
  fillCircle?: string;
  style?: React.CSSProperties;
}

const CounterSmallDotIcon = ({ fillCircle = '#808080', ...rest }: DotIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none" {...rest}>
      <circle cx="2" cy="2" r="1.5" fill={fillCircle} />
    </svg>
  );
};

export default CounterSmallDotIcon;
