import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Domain: string;
}

export const mixpanelTrackUserClickedEmailDomain = async (eventProperties: EventProperties): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'User Clicked Email Domain',
    payload: {
      event_properties: eventProperties,
    },
  });
};
