import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CreditThreshold from './CreditThreshold';
import AutoTopupPackages from './AutoTopupPackages';
import { getBrowserDetails } from '../../../../../utils/helpers';
import { ButtonV2, ToggleV2 } from 'swui';
import { useApp } from '../../../../../contexts/AppContext';
import AutoTopupModal from './AutoTopupModal';
import { handlePaymentVysionEvent } from '../../../../../utils/vysion/payment';
import { VYSION_ACTIONS } from '../../../../../utils/vysion';
import { PaymentClickVysionEventsEnum } from '../../../../../utils/vysion/payment/click';
import SavedPaymentToken from './SavedPaymentToken';
import { dimensions } from '../../../../../style';
import Spinner from '../../../../../atoms/loader/Spinner/Spinner';

const isMobile = getBrowserDetails().isMobile;

const PaymentSettingsContainer = styled('div')({
  padding: '1.3rem 1.5rem 1.3rem 2rem',
});

const StyledToggle = styled(ToggleV2)({
  float: 'right',
  width: isMobile ? 56 : 58,
  height: 28,
});

const PaymentSettingsHeading = styled('div')({
  display: 'flex',
  borderBottom: '1px dashed #DAE1E9',
  paddingBottom: 25,
  marginBottom: 25,
  flexWrap: 'wrap',
});

const PackageContainer = styled('div')({
  borderBottom: '1px dashed #DAE1E9',
  paddingBottom: 10,
  marginBottom: 25,
});

const CreditThresholdContainer = styled('div')({
  borderBottom: '1px dashed #DAE1E9',
  paddingBottom: 20,
  marginBottom: 20,
});

const PaymentSettingsHeadingText = styled('h4')({
  margin: 0,
});

const PaymentSettingsHeadingAction = styled('div')({
  flex: 'auto',
});

const PaymentSettingsInfo = styled('div')({
  borderBottom: '1px dashed #DAE1E9',
});

const PaymentSettingsNote = styled('div')({
  color: '#BC2028',
  fontSize: 14,
  background: '#F8E9EA',
  borderRadius: 10,
  lineHeight: '21px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  width: '100%',
});

const PaymentSettingsNoteContent = styled('div')({
  color: '#BC2028',
  fontSize: 14,
  lineHeight: '21px',
  [dimensions.SCREEN_MIN_MD]: {
    textAlign: 'center',
    maxWidth: 236,
  },
});

const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const PaymentSettingsAction = styled('div')({
  position: 'relative',
  margin: '25px auto',
});

interface PaymentSettingsProps {
  menuOpen: any;
  settings: any;
  subscribeRecurringPayment: any;
  unSubscribeRecurringPayment: any;
  getRecurringPayment: any;
  removeSavedToken: any;
}

const PaymentSettings = ({
  settings,
  subscribeRecurringPayment,
  unSubscribeRecurringPayment,
  getRecurringPayment,
  removeSavedToken,
}: PaymentSettingsProps): any => {
  const [isChanged, setIsChanged] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [recurringPackages, setRecurringPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [creditThreshold, setCreditThreshold] = useState(25);
  const [recurring, setRecurring] = useState(settings.recurring);
  const [savedCard, setSavedCard] = useState(settings?.recurring?.paymentToken || null);
  const { paymentSettingsOpen, setForceCloseSliderMenu, setPaymentSettingsOpen } = useApp();

  useEffect(() => {
    if (!paymentSettingsOpen) {
      setSubscribed(false);
      setIsProcessing(false);
      setHasError(false);
      setIsChanged(false);
      setCreditThreshold(25);
      setRecurringPackages([]);
      setSelectedPackage(null);
      setRecurring(settings.recurring);
      setSavedCard(settings.recurring?.paymentToken || null);
      setIsLoading(true);
      return;
    }

    getRecurringSettings();
  }, [paymentSettingsOpen]);

  const getRecurringSettings = (): void => {
    // get recurring records
    getRecurringPayment()
      .then(response => {
        const recurringData = response.data?.data;
        const recurringPackages = response.data?.meta?.packages?.data;

        setRecurring(recurringData);
        setSavedCard(response.data?.meta?.paymentToken?.data);

        if (recurringData?.subscribed) {
          setSubscribed(recurringData.subscribed);
        }

        if (recurringData?.credit_threshold) {
          setCreditThreshold(recurringData.credit_threshold);
        }

        if (recurringPackages) {
          updateRecurringPackages(recurringPackages, recurringData?.package);
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch(error => {
        console.error('fetchPaymentSubscriptionError', error);
        setIsLoading(false);
      });
  };

  const updateRecurringPackages = (packages: any, recurringPackage: any): void => {
    setRecurringPackages(packages);

    if (recurringPackage) {
      setSelectedPackage(recurringPackage);
      return;
    }

    const defaultPackage = packages.filter(recurringPackage => recurringPackage.name == '100 CREDIT PACKAGE');

    setSelectedPackage(defaultPackage ? defaultPackage[0].identifier : null);
  };

  const getSelectPackage = (): string => {
    const selectedFromPackageList = recurringPackages.filter(
      recurringPackage => recurringPackage.identifier === selectedPackage
    );

    return selectedFromPackageList && selectedFromPackageList[0] && selectedFromPackageList[0].name
      ? `${selectedFromPackageList[0].credits} Credits`
      : '';
  };

  const handleSavePaymentSettings = () => {
    if (isProcessing) return;

    setIsProcessing(true);

    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_SAVE,
    });

    if (!subscribed) {
      unSubscribeRecurringPayment()
        .then(() => {
          setSubscribed(false);
          setSelectedPackage(null);
          setRecurring(null);
          setCreditThreshold(25);
          setRecurringPackages([]);
          setShowModal(true);
          setIsProcessing(false);
        })
        .catch(error => {
          handlErrorResponse(error);
          setIsProcessing(false);
        });

      return;
    }

    subscribeRecurringPayment({ threshold: creditThreshold, package: selectedPackage })
      .then(() => {
        setSubscribed(true);
        setShowModal(true);
        setIsProcessing(false);
      })
      .catch(error => {
        handlErrorResponse(error);
        setIsProcessing(false);
      });
  };

  const handlErrorResponse = error => {
    try {
      const { data } = error.response;

      const noErrorData =
        typeof data.errors === 'undefined' ||
        typeof data.errors[0] === 'undefined' ||
        typeof data.errors[0].code === 'undefined';

      if (!noErrorData) {
        const err = data.errors[0];

        if (err.code === 40422002 || err.code === 40422001) {
          setHasError(true);
          setShowModal(true);
        }
      }
    } catch (e) {
      console.error('invalid response.data', e);
    }
  };

  const handlePaymentSettingsToggle = event => {
    handlePaymentVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: event.target.checked
        ? PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_ON
        : PaymentClickVysionEventsEnum.CLICK_ACCOUNT_MENU_PAYMENT_SETTINGS_AUTO_TOP_UP_OFF,
    });

    if (event.target.checked && (typeof savedCard === 'undefined' || savedCard === 'null')) {
      setShowModal(true);
      setHasError(true);
      return;
    }

    setSubscribed(event.target.checked);

    if (recurring && event.target.checked) {
      setIsChanged(recurring.package !== selectedPackage || recurring.credit_threshold !== creditThreshold);
    } else {
      setIsChanged(true);
    }
  };

  const handleCloseSettings = (closeSidebar = false): any => {
    setShowModal(false);

    if (closeSidebar) {
      setSubscribed(false);
      setPaymentSettingsOpen(false);
      setForceCloseSliderMenu(prev => prev + 1);
    }
  };

  const handleRemoveSavedToken = (savedTokenId: number): void => {
    setIsLoading(true);
    removeSavedToken(savedTokenId, false)
      .then(() => {
        setSubscribed(false);
        setIsProcessing(false);
        setHasError(false);
        setCreditThreshold(25);
        setRecurringPackages([]);
        setSelectedPackage(null);
        setRecurring(settings.recurring || null);
        setSavedCard(settings.recurring.paymentToken || null);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('removeTokenError', error);
        setIsLoading(false);
      });
  };

  const handleThresholdChange = (threshold: any) => {
    setCreditThreshold(threshold);

    if (!recurring) {
      setIsChanged(true);
      return;
    }

    setIsChanged(recurring.credit_threshold !== threshold || recurring.package !== selectedPackage);
  };

  const handlePackageChange = (identifier: any) => {
    setSelectedPackage(identifier);

    if (!recurring) {
      setIsChanged(true);
      return;
    }

    setIsChanged(recurring.package !== identifier || recurring.credit_threshold !== creditThreshold);
  };

  return (
    <PaymentSettingsContainer data-test-id="payment-settings">
      {!isLoading ? (
        <>
          <SavedPaymentToken data={savedCard} removeSavedToken={handleRemoveSavedToken} />
          <PaymentSettingsHeading>
            <PaymentSettingsHeadingText data-test-id="payment-settings-title">
              Auto Credit Top-Up
            </PaymentSettingsHeadingText>
            <PaymentSettingsHeadingAction>
              {!isLoading && (
                <StyledToggle
                  className="toggleSWUI"
                  id="auto-topup-toggle"
                  checked={subscribed}
                  onChange={handlePaymentSettingsToggle}
                />
              )}
            </PaymentSettingsHeadingAction>
            <PaymentSettingsNote data-test-id="payment-settings-note">
              <PaymentSettingsNoteContent>
                Auto Credit Top-Up will use the linked payment card on file.
              </PaymentSettingsNoteContent>
            </PaymentSettingsNote>
          </PaymentSettingsHeading>
          <PaymentSettingsInfo>
            <p data-test-id="payment-settings-info">
              Auto Credit Top-Up ensures you&apos;re never out of the game by automatically refilling your credits when
              you reach your low credit threshold.
            </p>
            <p style={{ fontStyle: 'italic' }}>Never miss a connection again!</p>
          </PaymentSettingsInfo>
          {subscribed && (
            <div>
              <CreditThresholdContainer data-test-id="payment-settings-threshold">
                <h4>Choose your low credit threshold</h4>
                <CreditThreshold threshold={creditThreshold} setThreshold={handleThresholdChange} />
              </CreditThresholdContainer>
              <PackageContainer data-test-id="payment-settings-package">
                <h4>Choose a Top-Up Credit Package</h4>
                <AutoTopupPackages
                  packages={recurringPackages}
                  selectedPackage={selectedPackage}
                  setSelectedPackage={handlePackageChange}
                />
                <p align="center">Credits never expire.</p>
                <p align="center">The more you buy, the more you save.</p>
              </PackageContainer>
            </div>
          )}
          <PaymentSettingsAction data-test-id="payment-settings-action">
            {(recurring?.subscribed || subscribed) && (
              <ButtonV2
                disabled={isProcessing || !isChanged}
                shape="curved"
                appearance={'primary'}
                style={{ width: '100%' }}
                onClick={handleSavePaymentSettings}
              >
                {isProcessing ? `Saving...` : `Save`}
              </ButtonV2>
            )}
          </PaymentSettingsAction>

          <AutoTopupModal
            isOpen={showModal}
            selectPackage={getSelectPackage()}
            creditThreshold={creditThreshold}
            error={hasError}
            subscribed={subscribed}
            onClose={closeSidebar => {
              handleCloseSettings(closeSidebar);
            }}
            onConfirm={() => {
              handleCloseSettings(true);
            }}
          />
        </>
      ) : (
        <LoaderWrapper data-test-id="payment-settings-loader">
          <Spinner />
        </LoaderWrapper>
      )}
    </PaymentSettingsContainer>
  );
};

export default PaymentSettings;
