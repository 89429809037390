import React, { ReactElement } from 'react';

const VideoCallIcon = (fillColor = '#FFFFFF'): ReactElement => {
  return (
    <svg width="19px" height="19px" viewBox="0 0 21 14" version="1.1">
      <g id="Video-Messaging" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="D---Inbox-SD" transform="translate(-973.000000, -798.000000)" fill={fillColor.fillColor}>
          <g id="Body-Full" transform="translate(292.000000, 78.000000)">
            <g id="Bottom" transform="translate(0.000000, 705.000000)">
              <path
                d="M697.333333,20.25 L697.333333,16.1666667 C697.333333,15.525 696.808333,15 696.166667,15 L682.166667,15 C681.525,15 681,15.525 681,16.1666667 L681,27.8333333 C681,28.475 681.525,29 682.166667,29 L696.166667,29 C696.808333,29 697.333333,28.475 697.333333,27.8333333 L697.333333,23.75 L702,28.4166667 L702,15.5833333 L697.333333,20.25 Z"
                id="ico_video"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const PauseVideo = (fillColor = '#FFFFFF'): ReactElement => {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
      <g id="Video-Messaging-MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Video-window"
          transform="translate(-481.000000, -543.000000)"
          fill={fillColor.fillColor}
          fillRule="nonzero"
        >
          <g id="Action" transform="translate(428.000000, 534.000000)">
            <path
              d="M72,13.5 L68,17.5 L68,14 C68,13.45 67.55,13 67,13 L60.82,13 L72,24.18 L72,13.5 Z M54.27,9 L53,10.27 L55.73,13 L55,13 C54.45,13 54,13.45 54,14 L54,24 C54,24.55 54.45,25 55,25 L67,25 C67.21,25 67.39,24.92 67.54,24.82 L70.73,28 L72,26.73 L54.27,9 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Mic = (fillColor = '#FFFFFF'): ReactElement => {
  return (
    <svg width="14px" height="19px" viewBox="0 0 14 19" version="1.1">
      <g id="Video-Messaging-MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Video-window---muted"
          transform="translate(-439.000000, -543.000000)"
          fill={fillColor.fillColor}
          fillRule="nonzero"
        >
          <g id="Action" transform="translate(428.000000, 534.000000)">
            <path
              d="M18,21 C19.66,21 20.99,19.66 20.99,18 L21,12 C21,10.34 19.66,9 18,9 C16.34,9 15,10.34 15,12 L15,18 C15,19.66 16.34,21 18,21 Z M23.3,18 C23.3,21 20.76,23.1 18,23.1 C15.24,23.1 12.7,21 12.7,18 L11,18 C11,21.41 13.72,24.23 17,24.72 L17,28 L19,28 L19,24.72 C22.28,24.24 25,21.42 25,18 L23.3,18 Z"
              id="ico-mic"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const NoMic = (fillColor = '#FFFFFF'): ReactElement => {
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1">
      <g id="Video-Messaging-MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Video-window"
          transform="translate(-437.000000, -543.000000)"
          fill={fillColor.fillColor}
          fillRule="nonzero"
        >
          <g id="Action" transform="translate(428.000000, 534.000000)">
            <path
              d="M25,18 L23.3,18 C23.3,18.74 23.14,19.43 22.87,20.05 L24.1,21.28 C24.66,20.3 25,19.19 25,18 L25,18 Z M20.98,18.17 C20.98,18.11 21,18.06 21,18 L21,12 C21,10.34 19.66,9 18,9 C16.34,9 15,10.34 15,12 L15,12.18 L20.98,18.17 Z M10.27,10 L9,11.27 L15.01,17.28 L15.01,18 C15.01,19.66 16.34,21 18,21 C18.22,21 18.44,20.97 18.65,20.92 L20.31,22.58 C19.6,22.91 18.81,23.1 18,23.1 C15.24,23.1 12.7,21 12.7,18 L11,18 C11,21.41 13.72,24.23 17,24.72 L17,28 L19,28 L19,24.72 C19.91,24.59 20.77,24.27 21.54,23.82 L25.73,28 L27,26.73 L10.27,10 Z"
              id="ico-nomic"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

interface NoMicWithBorderProps {
  style: any;
}

const NoMicWithBorder = ({ style }: NoMicWithBorderProps): ReactElement => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" style={style}>
      <g id="Video-Messaging-MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Video-window---muted"
          transform="translate(-795.000000, -145.000000)"
          fill="#CF0404"
          fillRule="nonzero"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <path
            d="M802.711372,155.687158 L803.01,156 C803.01,157.107147 803.455019,158.106827 804.175566,158.829783 C804.896168,159.552795 805.89289,160 807,160.1 C805.840025,160.1 804.726492,159.647401 803.920141,158.871478 C803.111103,158.09297 802.617598,156.984391 802.711372,155.687158 Z M802.055436,155 L799.066977,155 C798.757428,157.31349 799.542851,159.462787 800.968335,161.05933 C802.0262,162.244139 803.436309,163.121307 805,163.53346 L805,167 L809,167 L809,163.536117 C809.467626,163.413258 809.920189,163.247682 810.352941,163.045914 L814.730844,167.413369 L817.414214,164.73 L799.27,146.585786 L796.585786,149.27 L802.055436,155 Z M811.3,156 C811.3,156.841144 811.042181,157.601749 810.643028,158.237241 L813.250515,160.844729 C814.050283,159.779368 814.795329,158.552042 814.481319,157.264597 C814.321639,156.60991 813.891279,155.89324 812.873462,155.180768 C812.142279,154.66894 811.512831,154.442713 810.967972,154.383341 C811.176381,154.873727 811.3,155.417555 811.3,156 Z M807,146 C805.875117,146 804.860678,146.460405 804.132015,147.232788 C803.354155,148.05732 802.896713,149.244887 803.019934,150.622498 L811,158.363162 L811,150 C811,148.893333 810.55284,147.893626 809.829607,147.170393 C809.106374,146.44716 808.106667,146 807,146 Z"
            id="ico-nomic"
          />
        </g>
      </g>
    </svg>
  );
};

const EndCall = (fillColor = '#FFFFFF'): ReactElement => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
      <g id="Video-Messaging-MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Video-window"
          transform="translate(-568.000000, -542.000000)"
          fill={fillColor.fillColor}
          fillRule="nonzero"
        >
          <g id="Action" transform="translate(428.000000, 534.000000)">
            <path
              d="M155.95,19.73 L154.52,18.3 C155.08,17.57 155.57,16.8 155.99,15.98 L153.79,13.78 C153.51,13.5 153.43,13.11 153.54,12.76 C153.91,11.64 154.11,10.44 154.11,9.19 C154.11,8.64 154.56,8.19 155.11,8.19 L158.61,8.19 C159.16,8.19 159.61,8.64 159.61,9.19 C159.61,13.17 158.24,16.83 155.95,19.73 Z M153.13,22.54 C150.24,24.83 146.58,26.19 142.61,26.19 C142.06,26.19 141.61,25.74 141.61,25.19 L141.61,21.7 C141.61,21.15 142.06,20.7 142.61,20.7 C143.85,20.7 145.06,20.5 146.18,20.13 C146.53,20.01 146.93,20.1 147.2,20.37 L149.4,22.57 C150.21,22.15 150.98,21.67 151.7,21.11 L140,9.41 L141.42,8 L159.8,26.39 L158.39,27.8 L153.13,22.54 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { VideoCallIcon, PauseVideo, EndCall, Mic, NoMic, NoMicWithBorder };
