import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Panel from './Panel';
import { Text } from '../text';

const UserText = styled(Text)`
  white-space: pre-line;
  overflow-wrap: anywhere;
`;

const PanelTextWithHeader = (props: any): ReactElement => {
  const { title, content, className, contentTestId = null, toggleEditable, isProcessing, ...rest } = props;

  return (
    <Panel
      withHeader
      title={title}
      className={className}
      toggleEditable={toggleEditable}
      disabled={isProcessing}
      {...rest}
    >
      <UserText data-test-id={contentTestId ? `cy-test-${contentTestId}` : ''} SM>
        {content ? unescape(content) : ''}
      </UserText>
    </Panel>
  );
};

PanelTextWithHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  toggleEditable: PropTypes.func,
  isProcessing: PropTypes.bool,
};

PanelTextWithHeader.defaultProps = {
  title: '',
  content: '',
  isProcessing: false,
};

export default PanelTextWithHeader;
