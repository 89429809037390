import heic2any from 'heic2any';
import { PhotoConfig, VideoConfig } from '../../config/Media';
import { MediaTypes } from './constants';

type ValidFilesType = { file: File; status: 'valid' | 'invalid' };

const getVideoLength = (selectedFile): Promise<number> => {
  return new Promise(resolve => {
    const video = URL.createObjectURL(selectedFile);
    const audio = new Audio();
    audio.src = video;

    /* eslint-disable @typescript-eslint/no-unused-vars */
    audio.onloadedmetadata = _ => {
      resolve(audio.duration);
    };
  });
};

const isFileSizeValid = (mediaType: MediaTypes, file: File) => {
  const isVideo = mediaType === 'video';
  const allowedSize = isVideo ? VideoConfig.maxFileSizeByte : PhotoConfig.maxFileSizeByte;

  return file && typeof file.size !== 'undefined' && file.size <= allowedSize;
};

const isVideoLengthValid = async (file: File) => {
  const allowedDuration = VideoConfig.maxDuration + VideoConfig.maxDurationBuffer; // 30 secs
  const videoLength = await getVideoLength(file);

  return videoLength > 0 && videoLength <= allowedDuration;
};

export const validateFiles = async (
  mediaType: MediaTypes,
  files: File[]
): Promise<{
  invalidFilesCount: number;
  validFiles: File[];
  invalidFiles: File[];
}> => {
  const isVideo = mediaType === 'video';
  const promises = [];

  for (const file of files) {
    promises.push(
      new Promise(resolve => {
        const resolveFunc = isValidFile => {
          resolve({
            file,
            status: isValidFile ? 'valid' : 'invalid',
          });
        };

        const isValidSize = isFileSizeValid(mediaType, file);

        if (!isValidSize) {
          resolveFunc(isValidSize);
          return;
        }

        // file has valid size
        // check further for video type
        if (isVideo && isValidSize) {
          isVideoLengthValid(file).then(data => {
            resolveFunc(data);
          });
        } else {
          // resolve for photo
          resolveFunc(isValidSize);
        }
      }) as never
    );
  }

  const processedFiles = ((await Promise.all(promises)) as unknown) as ValidFilesType[];

  const validFiles = processedFiles.reduce((accumulator, currentValue) => {
    if (currentValue.status === 'valid') {
      return [...accumulator, currentValue.file];
    } else {
      return accumulator;
    }
  }, [] as File[]);

  const invalidFiles = processedFiles.reduce((accumulator, currentValue) => {
    if (currentValue.status === 'invalid') {
      return [...accumulator, currentValue.file];
    } else {
      return accumulator;
    }
  }, [] as File[]);

  return { validFiles, invalidFiles, invalidFilesCount: invalidFiles.length };
};

export const getImage = (imageFile: Blob): Promise<string> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result);
    };
    reader.readAsDataURL(imageFile);
  });
};

export const getHeicImage = async (imageFile: File): Promise<string> => {
  const fileName = imageFile.name;

  const newImage = await heic2any({
    blob: imageFile,
    toType: 'image/jpeg',
  });

  newImage.name = fileName + '.jpg';

  return await getImage(newImage as Blob);
};

export const baseurltoFile = (url: string): Promise<File> => {
  return fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], 'photo', { type: 'image/png' });
      return file;
    });
};
