/**
 * @Author: zachalam
 * @Date:   2017-01-23T16:03:15-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-24T11:47:06-08:00
 */

import axios, { AxiosPromise } from 'axios';
import MasterConfig from './config/Master';
import { cleanObject } from './common';
import qs from 'qs';
import store from './store';
import {
  refreshUserToken,
  storeHttpRequest,
  removeStaleHttpRequests,
  removeHttpRequest,
  storeWebTimestamp,
} from './actions/authActions';
import { getCookie, shouldExcludeRequest } from './utils/helpers';
import { displayFailMessage } from './actions/notificationActions';
import { storeSpecificErrors, killLoading } from './actions/commonActions';
import { findIndex } from 'lodash';
import SearchConfig from './config/Search';
import { WEB_TIMESTAMP, IS_REFRESH_TOKEN_COOKIE } from './config/constants';
import TextLink from './components/common/TextLinkV2';
import React from 'react';
import * as auth from './sdk/AuthSDK';
import * as billing from './sdk/BillingSDK';
import * as favourites from './sdk/FavSDK';
import * as offer from './sdk/OfferSDK';
import * as profile from './sdk/ProfileSDK';
import * as webAuthn from './sdk/WebAuthnSDK';
import * as mail from './sdk/MailSDK';
import * as gallery from './sdk/GallerySDK';
import * as mixpanel from './sdk/MixpanelSDK';
import * as common from './sdk/CommonSDK';
import * as settings from './sdk/SettingsSDK';
import { getUniqueDevice } from './utils/ipQualityScore';
import { setMailError } from './actions/mailActions';

// check for PRODUCTION defined api, if so use that instead.
const api = MasterConfig.api;

const handleErrorCode = (code: any, err: any): any => {
  switch (code) {
    case 42204001:
      store.dispatch(
        displayFailMessage({
          info: (
            <span>
              Too many open offers or requests, please{' '}
              <TextLink href="/billing">
                <span>purchase credits</span>
              </TextLink>{' '}
              to continue.
            </span>
          ),
        })
      );
      break;
    case 40419001:
    case 40419002: {
      let theError;

      try {
        const displayError = err.description;
        if (displayError) theError = displayError;
        else {
          theError = 'Invalid email/password combination.';
        }
      } catch (e) {
        theError = 'Please enter an email and password.';
      }

      store.dispatch(storeSpecificErrors('social', theError));
      // hide loading
      store.dispatch(killLoading());
      break;
    }
    // Don't show notification banner
    case 40101001:
    case 40101006:
    case 40101007:
    case 40422001:
    case 40422002:
      break;

    default:
      store.dispatch(
        displayFailMessage({
          info: err.description,
        })
      );
      break;
  }
};

const interceptError = (error: any): boolean | null => {
  const { response } = error;
  const { data, status } = response;

  const noErrorData =
    typeof data.errors === 'undefined' ||
    typeof data.errors[0] === 'undefined' ||
    typeof data.errors[0].code === 'undefined';

  if (typeof data.error !== 'undefined' && noErrorData) {
    if (data.error === 'Too Many Requests') {
      store.dispatch(storeSpecificErrors('form', { errorCode: 42920003 }));
    } else if (data.error === 'Sorry, something went wrong.') {
      store.dispatch(storeSpecificErrors('form', { errorCode: 40120001 }));
    }
  }

  if (typeof data.email !== 'undefined' && noErrorData) {
    const errorText = data.email[0];
    const regex = RegExp(/Too many login attempts/i);
    if (regex.test(errorText)) {
      store.dispatch(storeSpecificErrors('form', { errorCode: 42901001 }));
    }
  }

  if (typeof status !== 'undefined' && status === 429) {
    store.dispatch(
      displayFailMessage({
        info: 'Woah! Something went wrong. Please wait a moment and try again.',
      })
    );
  }

  if (noErrorData) {
    return null;
  }

  const err = data.errors[0];
  handleErrorCode(err.code, err);

  return true;
};

// http://jira.infostreamgroup.com/browse/WYP-6441
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  config => {
    // attach auth headers only on our api only
    if (config.url.indexOf(api) > -1) {
      const { virgil, jwToken } = store.getState().auth;
      config.headers.Accept = 'application/json';

      if (jwToken) {
        config.headers.Authorization = 'Bearer ' + jwToken;
      }

      if (WEB_TIMESTAMP) {
        config.headers['web-timestamp'] = WEB_TIMESTAMP;
      }

      if (virgil) {
        config.headers['X-Auth-Type'] = 'virgil';
      }
    }

    if (config.url.indexOf('auth/refresh') > 0) {
      return config;
    }

    return store
      .dispatch(removeStaleHttpRequests())
      .then(() => {
        // store open http requests
        const httprequest = config.method + config.url;
        const activeHttp = findIndex(store.getState().auth.requestList, v => {
          return v.loc === httprequest.toLowerCase();
        });

        if (shouldExcludeRequest(config.url)) {
          return config;
        }

        if (activeHttp === -1) {
          return store.dispatch(storeHttpRequest(httprequest)).then(() => {
            return config;
          });
        } else {
          // active request open - block this one.
          console.log('REQUEST BLOCKED');
          return Promise.reject({ ...config, errorMessage: 'requestBlocked' });
        }
      })
      .catch(error => {
        // error removing stale requests
        return Promise.reject(error);
      });
  },
  error => {
    return Promise.reject(error);
  }
);

let tokenRefreshing = false;

const handleAuthError = (resolve: any, reject: any, error: any, originalRequest: any): any => {
  let maxRequest = 0;
  const continueRequest = setInterval(() => {
    if (maxRequest > 5) {
      clearInterval(continueRequest);
      return reject(error);
    }

    if (!tokenRefreshing) {
      if (!store.getState().auth.isAuthenticated) {
        clearInterval(continueRequest);
        return reject(error);
      }

      const res = axios({
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${store.getState().auth.jwToken}`,
        },
      });

      clearInterval(continueRequest);
      return resolve(res);
    }

    maxRequest++;
  }, 2000);
};

const refreshAuthToken = (error: any): any => {
  const originalRequest = error.config;

  if (store.getState().auth.isAuthenticated) {
    // The auth token has expired. Refresh token and resend request
    if (!tokenRefreshing) {
      tokenRefreshing = true;
      let tokenPath = 'refresh';

      // Check if the new is_refresh_token exist in the Cookie
      if (getCookie(IS_REFRESH_TOKEN_COOKIE)) {
        tokenPath = 'refresh-token';
      }

      return refreshUserToken(tokenPath)
        .then(token => {
          tokenRefreshing = false;

          return axios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            },
          });
        })
        .catch(() => {
          tokenRefreshing = false;
          return Promise.reject(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        handleAuthError(resolve, reject, error, originalRequest);
      });
    }
  }
};

const checkUserAuth = (error: any): any => {
  if (error.response.data.message !== undefined && error.response.data.message === 'Token has expired') {
    // attempt to get a new token if the user is authenticated...
    // the API said the user was authenticated, if they were previously
    // authenticated attempt to refresh their token.
    if (store.getState().auth.isAuthenticated) {
      // The auth token has expired. Refresh token and resend request
      return refreshAuthToken(error);
    }
  }
};

const needTokenRefresh = (error: any): any => {
  const typing = error.response.request.responseURL.indexOf('typing-indicator') > -1;
  if (typing && error.response.data && error.response.data.error && error.response.data.error.code === 40301066) {
    if (store.getState().auth.isAuthenticated) {
      // The auth token has expired. Refresh token and resend request
      return refreshAuthToken(error);
    }
  }
};

const reloadAndFetchUpdatedResource = (error: any): any => {
  // refresh the whole page to get the new bundle
  if (typeof error.response.headers['web-timestamp'] !== 'undefined') {
    store.dispatch(storeWebTimestamp(error.response.headers['web-timestamp'], true)).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000); // give 2000 to sync the api version to reducer
    });
  }
};

const handleFormError = (error: any): any => {
  const paymentVisit = error.response.request.responseURL.indexOf('payment/visited') > -1;
  const verifyPhonePassword = error.response.request.responseURL.indexOf('account/verify-password') > -1;
  const requestOtp = error.response.request.responseURL.indexOf('otp') > -1;
  const errorData = error.response.data;
  if (
    errorData === undefined ||
    errorData.data === undefined ||
    errorData.data.source === undefined ||
    !(errorData.data.source === 'gateway' || errorData.data.source[0] === 'offer')
  ) {
    if (!paymentVisit && !verifyPhonePassword && !requestOtp) {
      const isU2pCountryError =
        !!errorData.use2pay_billing_country &&
        errorData.use2pay_billing_country[0] === 'Use2pay billing country is not a valid option.';
      store.dispatch(
        displayFailMessage({
          info: isU2pCountryError
            ? 'Billing country is not valid. Please try again.'
            : 'There was an error submitting the form. Please try again.',
        })
      );
    }
  }
};

const handleBadRequest = (error: any, login: any, logout: any) => {
  const errorMessage = error.response.data.error;
  const { data } = error.response;

  if (
    typeof data.message !== 'undefined' &&
    data.message === 'Your ID verification status is not allowed to access this resource.'
  ) {
    return;
  }

  if (
    error.response.data.message !== undefined &&
    (error.response.data.message === 'The token could not be parsed from the request' ||
      error.response.data.message === 'The token has been blacklisted')
  ) {
    if (!login && !logout) window.location.href = '/login?logged_out=true';
  } else if (errorMessage === 'Unauthorized') {
    window.location.href = '/login?logged_out=true';
  } else {
    const ignoreMessages = ['OTP_REQUEST_MAX_ATTEMPT', 'OTP_RETRY_MAX_ATTEMPT'];

    if (!ignoreMessages.includes(error.response.data.message)) {
      store.dispatch(
        displayFailMessage({
          info: 'Oh $#@!, something went wrong. Please refresh the page.',
        })
      );
    }
  }
};

const handleTooManyRequest = (error: any): any => {
  if (
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.description &&
    [42920004].indexOf(error.response.data.error.code) > -1
  ) {
    store.dispatch(
      displayFailMessage({
        info: error.response.data.error.description,
      })
    );
  }
};

const handleErrorStatus = (error: any): any => {
  const status = error.response && error.response.status;
  const errorMessage = error.response.data.error;
  const login = error.response.request.responseURL.indexOf('auth/login') > -1;
  const logout = error.response.request.responseURL.indexOf('auth/logout') > -1;
  const otpVerify =
    error.response.request.responseURL.indexOf('otp/verify') > -1 ||
    error.response.request.responseURL.indexOf('otp/auth/verify') > -1;

  switch (status) {
    case 401: {
      const tokenPresent = checkUserAuth(error);
      if (tokenPresent) return tokenPresent;

      if (errorMessage === 'Unauthorized') window.location.href = '/login?logged_out=true';

      if (!login && !logout && !otpVerify) window.location.href = '/login?logged_out=true';

      return Promise.reject(error);
    }
    case 403: {
      const tokenPresent = needTokenRefresh(error);
      if (tokenPresent) return tokenPresent;

      if (error.response.request.responseURL.indexOf('offer/unlock')) {
        store.dispatch(setMailError(status, errorMessage));
        return Promise.reject({ error });
      }
      return Promise.reject(error);
    }
    case 409: {
      reloadAndFetchUpdatedResource(error);
      return Promise.reject(error);
    }
    case 422: {
      handleFormError(error);
      return Promise.reject(error);
    }
    case 400: {
      handleBadRequest(error, login, logout);
      return Promise.reject(error);
    }
    case 429: {
      handleTooManyRequest(error);
      return Promise.reject(error);
    }
    default:
      store.dispatch(
        displayFailMessage({
          info: 'Oh $#@!, something went wrong. Please refresh the page.',
        })
      );
      return Promise.reject(error);
  }
};

axios.interceptors.response.use(
  response => {
    // no issue, just return the normal response.
    store.dispatch(removeHttpRequest(response.config.method + response.config.url));

    if (typeof response.headers['web-timestamp'] !== 'undefined') {
      storeWebTimestamp(response.headers['web-timestamp']);
    }

    return response;
  },
  error => {
    const { pathname } = window.location;

    if (!error.response && !error.status && error.message === 'Network Error') {
      if (pathname === '/login') {
        store.dispatch(
          storeSpecificErrors('form', {
            errorCode: 'Network Error',
          })
        );
      }

      if (pathname === '/join') {
        store.dispatch(
          displayFailMessage(
            {
              info: (
                <span>
                  Sorry, we have experienced an issue loading the content.
                  <br />
                  Please{' '}
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => window.location.reload()}
                  >
                    click here
                  </span>{' '}
                  to retry.
                </span>
              ),
            },
            true
          )
        );
      }

      return Promise.reject(error);
    }

    // store the apiVersion header present in API response
    if (!error.config) return Promise.reject(error);
    store.dispatch(removeHttpRequest(error.config.method + error.config.url));

    if (error.config.url.indexOf('auth/refresh') > 0) {
      window.location.href = '/login?logged_out=true';
      return Promise.reject(error);
    }

    if (error.config.url.indexOf('auth/refresh-token') > 0) {
      window.location.href = '/login?logged_out=true';
      return Promise.reject(error);
    }

    if (typeof error.response === 'undefined') {
      return Promise.reject(error);
    }

    if (error.response.status === 503) {
      if (error.response.data !== undefined && error.response.data.maintenance_mode) {
        window.location.href = 'http://maintenance.whatsyourprice.com';
        return window.location.href;
      }
    }

    /** handle mail page invalid/suspended user */
    if (
      error.response.status === 404 &&
      error.config.url.indexOf('thread') > -1 &&
      window.location.href.indexOf('mail') > -1
    ) {
      store.dispatch(
        displayFailMessage({
          info: 'This conversation is no longer available or never existed.',
        })
      );
      return Promise.reject(error);
    }

    /** handle error code */
    // @TODO fully migrate all error handling to using error codes
    const errorIntercepted = interceptError(error);
    if (errorIntercepted) {
      return Promise.reject(error);
    }

    // handle response error status below
    return handleErrorStatus(error);
  }
);

/**
 Retrieves JWToken
 @param login
 @param password
 **/
export function getAccountToken(login: any, password: any): AxiosPromise {
  return axios({
    method: 'post',
    url: `${api}/auth/login`,
    data: { login, password },
  });
}

export function promoCodeCheck(
  hashId: string,
  promoCode: any,
  packageId: any,
  isPreJoin?: boolean,
  country?: string | null,
  withPackage = false,
  paymentChannel = null
): AxiosPromise {
  let url = `${api}/promo-code/check/${hashId}?promo_code=${promoCode}&package_id=${packageId}`;

  if (isPreJoin) {
    url += '&prejoin=true';
  }

  if (country) {
    url += `&billing_country=${country}`;
  }

  if (withPackage) {
    url += '&with_package=true';
  }

  if (paymentChannel) {
    url += `&payment_channel=${paymentChannel}`;
  }

  return axios({
    method: 'GET',
    url: url,
  });
}

/**
 Gets favorites for dashboard
 **/
export function profileDashboardFavorites(limit = 8): AxiosPromise {
  return axios({
    method: 'get',
    url: `${api}/favorite/featured?include=other_account.profile&limit=${limit}`,
    data: {},
  });
}

/**
 Gets boosted profiles for dashboard
 **/
export function profileDashboardBoosted(limit = 4, distance = 50, order = 'random'): AxiosPromise {
  return axios({
    method: 'GET',
    url: `${MasterConfig.searchMicroserviceUrl}/featured?include=account&limit=${limit}&distance=${distance}&order=${order}`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${store.getState().auth.jwToken}`,
      'x-client-id': MasterConfig.searchMicroserviceKey,
    },
  });
}

/**
 * Store account email password
 *
 * @param {object} data
 * @returns Axios
 */
export function postAccountEmailPassword(data: any): AxiosPromise {
  return axios({
    method: 'PUT',
    url: `${api}/settings/add-email-account`,
    data,
  });
}

/**
 * Update account email password
 *
 * @param {object} data
 * @returns Axios
 */
export function updateAccountEmailPassword(data: any): AxiosPromise {
  return axios({
    method: 'PUT',
    url: `${api}/settings/update-email-account`,
    data,
  });
}

/**
 * Search profiles
 *
 * @param {object} formData
 * @param {boolean} fetchFirst
 * @param {boolean} refTimestamp
 */
export function profileSearch(formData = null, fetchFirst = true, refTimestamp = false, pgid = null): any {
  const { meta, search } = store.getState();

  // if no custom data is given pull out the user defined filters
  if (formData === null || typeof formData !== 'object' || Object.keys(formData).length === 0) {
    formData = search.filters;
  }

  if (refTimestamp && formData.ref_timestamp !== undefined) delete formData.ref_timestamp;

  if (refTimestamp && meta.search_timestamp && meta.search_timestamp > 0 && !fetchFirst) {
    formData.ref_timestamp = meta.search_timestamp;
  }

  if (pgid !== null || typeof pgid === 'undefined') {
    formData.pgid = pgid;
  }

  const data = {
    include: 'account',
    page: fetchFirst ? 1 : meta.search_current_page + 1,
    per_page: MasterConfig.per_page,
    ...SearchConfig.filters,
    ...formData,
    has_photo: true,
  };

  const url = `${api}/profile?${qs.stringify(cleanObject(data))}`;

  if (MasterConfig.searchMicroserviceEnabled === true) {
    return axios({
      method: 'GET',
      url: `${MasterConfig.searchMicroserviceUrl}?${qs.stringify(cleanObject(data))}`,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${store.getState().auth.jwToken}`,
        'x-client-id': MasterConfig.searchMicroserviceKey,
      },
    });
  }

  return axios({
    method: 'GET',
    url: url,
  });
}

/**
 * Actualy POST request to unlink the email to an account
 * @param {Object} formData
 * @returns {AxiosPromise}
 */
export function unlinkEmailToAccount(formData: any): AxiosPromise {
  return axios({
    method: 'POST',
    url: `${api}/unlink/${formData.accountId}`,
    data: formData,
  });
}

/**
 * GET request to retrieve account to unlink
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export function getAccountToUnlink(data: any): AxiosPromise {
  return axios({
    method: 'GET',
    url: `${api}/unlink?data=${data.data}`,
  });
}

/**
 * Sends support ticket to deskpro
 *
 * @param object formData
 */
export function sendSupportTicket(formData: any): AxiosPromise {
  return axios({
    method: 'POST',
    url: `${api}/ticket`,
    data: formData,
  });
}

export function signupCheck(data: any): AxiosPromise {
  const deviceIdentifier = getUniqueDevice();

  if (deviceIdentifier.request_id) {
    data.ipqs_device_id = deviceIdentifier.device_id;
    data.ipqs_request_id = deviceIdentifier.request_id;
  }

  return axios({
    method: 'POST',
    url: `${api}/auth/signup/check`,
    data,
  });
}

export function subscribeWebPush(subscription: any): AxiosPromise {
  return axios({
    method: 'POST',
    url: `${api}/webpush/subscription`,
    data: subscription,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function unsubscribeWebPush(subscription: any): AxiosPromise {
  return axios({
    method: 'DELETE',
    url: `${api}/webpush/subscription`,
    data: subscription,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getGlobalNotifications(): AxiosPromise {
  return axios({
    method: 'GET',
    url: `${api}/notification-banner`,
  });
}

export function triggerVideoCallEvent(hash_id: string, signal: any): AxiosPromise {
  return axios({
    method: 'POST',
    url: `${api}/thread/video-call/start/${hash_id}`,
    data: {
      signal,
    },
  });
}

export function updateVideoCallStatus(video_uid: string, status: any, reason = ''): AxiosPromise {
  return axios({
    method: 'PUT',
    url: `${api}/thread/video-call/status`,
    data: {
      video_uid,
      status,
      reason,
    },
  });
}

export function getPostalLookup(zipcode: any, country: string): AxiosPromise {
  return axios({
    method: 'GET',
    url: `${api}/zipcodes?zipcode=${zipcode}&country=${country}`,
  });
}

export function saveLastSearch(formData: any, fetchFirst = true): AxiosPromise {
  const { meta } = store.getState();

  const searchData = {
    include: 'account',
    page: fetchFirst ? 1 : meta.search_current_page + 1,
    per_page: MasterConfig.per_page,
    ...SearchConfig.filters,
    ...formData,
    has_photo: true,
  };

  return axios({
    method: 'POST',
    url: `${api}/profile/save-last-search`,
    data: searchData,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function fetchSurveyQuestions(title: string): AxiosPromise {
  return axios({
    method: 'GET',
    url: `${api}/survey/${title}`,
  });
}

export const {
  fetchEvalFeature,
  doWebTimestampCheck,
  sendEvidentlyMetrics,
  getEvidentlyExperiment,
  batchEvaluateFeature,
} = common;

export const {
  getThreads,
  getConversation,
  postMessage,
  sendPoke,
  toggleArchive,
  sendTypingIndicator,
  toggleThreadMessageStatus,
  deleteMailThread,
  deletePhotoMessage,
  reportPhotoMessage,
} = mail;

export const {
  getBillingPackages,
  getKountDetails,
  getBillingDescriptors,
  postBillingPayment,
  getBillingPayments,
  getPaymentToken,
  deleteSavedPaymentToken,
  logPaymentVisit,
  getIDVPackages,
  postIDVPayment,
  postDDC,
} = billing;

export const {
  createUserAccountV2,
  createUserAccount,
  getNewAuthToken,
  killAuthToken,
  requestPassReminder,
  getFirstAuthToken,
  setNewPassword,
  setActivationPassword,
  validateActivationPasswordToken,
  validateResetPasswordToken,
  deactivateAccount,
  resendEmail,
  attemptSocialLogin,
  attachSocialLogin,
  validateSocialCredentials,
  getSignedLinkToken,
  destroyOtherDeviceSession,
  validateAutoSignup,
  getVerificationStatus,
  setVerificationStatus,
  getRealGiftsCookie,
  requestOtp,
  requestOtpVerify,
  requestOtpAuth,
  requestOtpAuthVerify,
} = auth;

export const {
  getFavorites,
  getInterested,
  getInterestedV2,
  ignoreInterested,
  toggleFavorite,
  markAsReadInterested,
} = favourites;

export const {
  getOffers,
  createNewOffer,
  counterOffer,
  acceptOffer,
  ignoreOffer,
  sendSuggestion,
  unlockOffer,
  updateOffer,
  quickOffer,
} = offer;

export const {
  createUserProfile,
  getLoggedProfile,
  getProfileMapFields,
  getSignedEmailPromoToken,
  getLoggedAccount,
  updateProfileCoverData,
  updateProfileOpeningOfferData,
  updateProfileAboutData,
  updateProfileInterestData,
  updateProfileDescriptionData,
  updateProfileSeekingData,
  updateLoggedProfile,
  getAccountByUserName,
  getExternalProfile,
  getAccountSettings,
  getVideoChatPreferences,
  getTurnCredentials,
  getCheckInfoRequest,
  requestUserInfo,
  getBlockedList,
  editBiometricName,
  updateAccountSettings,
  updateVideoChatPreferences,
  gdprDelete,
  gdprAcknowledged,
  mockProcessPhoto,
  postNewPhoto,
  uploadToS3,
  getPhoto,
  processPhoto,
  processPhotoMessage,
  deletePhoto,
  putPhotoAvatar,
  putPhotoPrivacy,
  reportUser,
  getReportReasons,
  blockUser,
  getCredits,
  // recentActivity, // WYP-13499 Deprecate ActivityFeed
  updateMessageReminder,
  getNuxGuides,
  updateNuxGuideStates,
  updateShowOfferTutorial,
  updateShowWishlist,
  updateShowGiftSent,
  resetNuxGuide,
  skipNuxGuides,
  getMandatoryCompletion,
  updateMandatoryCompletion,
  verifyPassword,
  updatePhoneNumber,
  updateProfileNewJoinFlow,
} = profile;

export const {
  deleteBiometric,
  getBiometricKeys,
  getCredentialPublicKey,
  registerBiometric,
  getAuthenticationPublicKey,
  authenticateBiometric,
} = webAuthn;

export const { mixpanelTrack } = mixpanel;

export const { addNewMedia, deleteMedia, updateMediaPrivacy, bulkDelete, bulkTogglePrivacy } = gallery;

export const {
  subscribeRecurringPayment,
  unSubscribeRecurringPayment,
  getRecurringPayment,
  updateStateBasedSettings,
  resetHiddenDialogs,
} = settings;
