import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { backgroundTheme, textColor } from '../../../style';

ReactModal.setAppElement('body');

interface ConfirmationProps {
  styles: Record<string, unknown>;
  contentLabel: string,
  shouldCloseOnOverlayClick: boolean,
  confirmationText: string,
  cancelBtnText: string,
  confirmationBtnText: string,
  btnAlignment: string,
  className: string,
  hideCancelBtn: boolean,
  hideConfirmBtn: boolean,
  hideModalCloseBtn: boolean,
  confirmationHeaderText: boolean,
  isConfirmBtnDisabled: boolean,
  shouldOpen: boolean,
  confirmAction(): void,
  onCloseHandler(): void,
}

const Confirmation = ({
  styles,
  contentLabel,
  shouldCloseOnOverlayClick,
  confirmationText,
  cancelBtnText,
  confirmationBtnText,
  btnAlignment,
  className,
  hideCancelBtn,
  hideConfirmBtn,
  hideModalCloseBtn,
  confirmationHeaderText,
  isConfirmBtnDisabled,
  shouldOpen,
  confirmAction,
  onCloseHandler,
}: ConfirmationProps): JSX.Element => {
  const [, setIsConfirmationModalOpen] = useState(false);
  const userBlockModalClose = () => {
    if (typeof onCloseHandler === 'function') onCloseHandler();
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmAction = () => {
    userBlockModalClose();
    if (typeof confirmAction === 'function') confirmAction();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className={className}
      isOpen={shouldOpen}
      style={styles}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={userBlockModalClose}
    >
      {!hideModalCloseBtn && (
        <div className="top-actions" style={{ textAlign: 'right' }}>
          <span style={{ cursor: 'pointer' }} onClick={userBlockModalClose}>
            &#10005;
          </span>
        </div>
      )}
      {confirmationHeaderText && (
        <h3 style={{ marginTop: 0, color: textColor.DARKER.color }}>{confirmationHeaderText}</h3>
      )}
      <div style={{ marginBottom: '20px', color: textColor.DARK.color }}>{confirmationText}</div>
      <ButtonSet align={btnAlignment}>
        {!hideCancelBtn && <Button onClick={userBlockModalClose}>{cancelBtnText}</Button>}
        {!hideConfirmBtn && (
          <Button buttonType="primary" onClick={handleConfirmAction} disabled={isConfirmBtnDisabled}>
            {confirmationBtnText}
          </Button>
        )}
      </ButtonSet>
    </ReactModal>
  );
};

Confirmation.propTypes = {
  styles: PropTypes.object,
  contentLabel: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelBtnText: PropTypes.string.isRequired,
  confirmationBtnText: PropTypes.string.isRequired,
  onCloseHandler: PropTypes.func,
  btnAlignment: PropTypes.string,
  className: PropTypes.string,
  hideCancelBtn: PropTypes.bool,
  hideConfirmBtn: PropTypes.bool,
  hideModalCloseBtn: PropTypes.bool,
  confirmationHeaderText: PropTypes.string,
  isConfirmBtnDisabled: PropTypes.bool,
};

Confirmation.defaultProps = {
  confirmationHeaderText: '',
  confirmationText: 'Are you sure with this?',
  confirmationBtnText: 'Yes',
  cancelBtnText: 'No',
  shouldOpen: false,
  btnAlignment: 'right',
  styles: {
    overlay: { zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.65)', overflowY: 'auto' },
    content: {
      background: backgroundTheme.block,
      bottom: 'initial',
      top: 82,
      maxWidth: '520px',
      transform: 'scale(1)',
      margin: '0 auto',
      outline: 'none',
      ':focus': { outline: 'none' },
    },
  },
  shouldCloseOnOverlayClick: false,
  hideCancelBtn: false,
  hideConfirmBtn: false,
  hideModalCloseBtn: true,
  isConfirmBtnDisabled: false,
};

export default Confirmation;
