import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';

const IconUploadInProgress = ({ customStyle, ...rest }: { customStyle?: React.CSSProperties }): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      style={customStyle}
      {...rest}
    >
      <circle cx="13" cy="13" r="13" fill={isDarkMode() ? '#71B5E4' : '#2B8FD7'} />
      <mask
        id="mask0_3469_1272"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="18"
        height="18"
      >
        <rect x="4.15918" y="4.16016" width="17.68" height="17.68" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3469_1272)">
        <path
          d="M8.30463 19.1536C7.90552 19.1536 7.56259 19.0102 7.27584 18.7235C6.98908 18.4367 6.8457 18.0938 6.8457 17.6947V15.7905C6.8457 15.5854 6.91631 15.4126 7.05753 15.272C7.19874 15.1314 7.37236 15.0611 7.57839 15.0611C7.78444 15.0611 7.95699 15.1314 8.09605 15.272C8.23511 15.4126 8.30463 15.5854 8.30463 15.7905V17.6947H17.6939V15.7905C17.6939 15.5854 17.7645 15.4126 17.9057 15.272C18.047 15.1314 18.2203 15.0611 18.4257 15.0611C18.6311 15.0611 18.8054 15.1314 18.9486 15.272C19.0917 15.4126 19.1633 15.5854 19.1633 15.7905V17.6947C19.1633 18.0938 19.0189 18.4367 18.7301 18.7235C18.4413 19.0102 18.0959 19.1536 17.6939 19.1536H8.30463ZM12.2698 9.64901L10.6235 11.2953C10.4808 11.4405 10.3126 11.5117 10.1189 11.5091C9.92525 11.5064 9.75341 11.4322 9.6034 11.2865C9.45491 11.1408 9.38268 10.9672 9.38672 10.7656C9.39078 10.5641 9.4654 10.3873 9.6106 10.2351L12.4772 7.3581C12.5536 7.28346 12.6373 7.22677 12.7283 7.18802C12.8193 7.14926 12.9108 7.12988 13.0029 7.12988C13.095 7.12988 13.1854 7.14926 13.2742 7.18802C13.363 7.22677 13.4454 7.28346 13.5214 7.3581L16.4168 10.2535C16.562 10.3987 16.6346 10.571 16.6346 10.7704C16.6346 10.9698 16.5644 11.1386 16.424 11.2769C16.2712 11.4295 16.0945 11.504 15.8938 11.5003C15.6932 11.4965 15.5202 11.4221 15.375 11.2769L13.7287 9.64901V15.2524C13.7287 15.4576 13.6581 15.6304 13.5169 15.771C13.3757 15.9116 13.2021 15.9819 12.9961 15.9819C12.79 15.9819 12.6175 15.9116 12.4784 15.771C12.3393 15.6304 12.2698 15.4576 12.2698 15.2524V9.64901Z"
          fill={isDarkMode() ? '#113855' : 'white'}
        />
      </g>
    </svg>
  );
};

export default IconUploadInProgress;
