import {
  UPDATE_USER_SOCIAL,
  STORE_SETTINGS,
  STORE_VIDEO_SETTINGS,
  STORE_VIDEO_INFO,
  CLEAR_VIDEO_INFO,
  STORE_ICE_SERVERS,
  STORE_GDPR_USER_INFO,
  INVERT_SETTING,
  INVERT_VIDEO_SETTING,
  SETTINGS_TOGGLE_DESKTOP_NOTIFICATION,
  SettingsState,
  SettingsActionType,
  SETTINGS_EVIDENTLY,
  SETTINGS_RECURRING_PACKAGES,
  STORE_SETTINGS_RECURRING,
  REMOVE_SETTINGS_RECURRING,
  SET_UPDATING_EMAIL_AND_PASSWORD,
} from './types/Settings.d';

const initialState: SettingsState = {
  social: [],
  desktopNotificationStatus: false,
  evidently: {},
  default_offer_amount: null,
  email_and_password: {
    isUpdating: false,
  },
};

const settings = (state = initialState, action: SettingsActionType): any => {
  switch (action.type) {
    case UPDATE_USER_SOCIAL:
      return {
        ...state,
        social: {
          data: action.payload,
        },
      };
    case STORE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        email_and_password: {
          isUpdating: false,
        },
        evidently: {
          ...state.evidently,
          ...action.payload.evidently,
        },
      };
    case STORE_VIDEO_SETTINGS:
      return {
        ...state,
        video_call: {
          ...action.payload,
        },
      };
    case STORE_VIDEO_INFO:
      return {
        ...state,
        video_info: {
          ...action.payload,
        },
      };
    case CLEAR_VIDEO_INFO:
      return {
        ...state,
        video_info: null,
      };
    case STORE_ICE_SERVERS:
      return {
        ...state,
        iceServers: action.payload,
      };
    case STORE_GDPR_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case INVERT_SETTING: {
      // invert a setting value when clicked (checkbox on settings page).
      const newState = Object.assign({}, state);
      newState[action.payload] = !state[action.payload];
      return newState;
    }
    case INVERT_VIDEO_SETTING: {
      // invert a setting value when clicked (checkbox on video settings page).
      const newVideoState = Object.assign({}, state);
      newVideoState.video_call[action.payload] = !state.video_call[action.payload];
      return newVideoState;
    }
    case SETTINGS_TOGGLE_DESKTOP_NOTIFICATION:
      return {
        ...state,
        desktopNotificationStatus: action.payload.status,
      };
    case SETTINGS_EVIDENTLY:
      return {
        ...state,
        evidently: { ...state.evidently, ...action.payload },
      };
    case SETTINGS_RECURRING_PACKAGES:
      return {
        ...state,
        recurring: { ...state.recurring, ...action.payload },
      };
    case STORE_SETTINGS_RECURRING:
      return {
        ...state,
        recurring: { ...state.recurring, ...action.payload },
      };
    case REMOVE_SETTINGS_RECURRING:
      return {
        ...state,
        recurring: { ...state.recurring, ...{ data: null } },
      };
    case SET_UPDATING_EMAIL_AND_PASSWORD:
      return {
        ...state,
        email_and_password: {
          isUpdating: action.payload,
        },
      };
    default:
      return state;
  }
};

export default settings;
