import styled from '@emotion/styled';
import { spacing, type, textColor } from '../../style';

const Text = styled('div')({
  ...textColor.NORMAL,
  lineHeight: '20px',
  wordBreak: 'break-word',
  ...spacing.MARGIN_BOTTOM_SM,
  ...type.SM,
});

export default Text;
