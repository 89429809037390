import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import BasicModal from '../../../modules/modals/BasicModal';
import { getBrowserDetails, tabletWidth } from '../../../utils/helpers';
import { CloseOutlined } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import styled from '@emotion/styled';
import Button from '../../../atoms/buttons/Button';
import { borders, dimensions } from '../../../style';

const closeButton = {
  fontSize: 32,
  color: '#2B8FD7',
  cursor: 'pointer',
};

const StepDescription = styled('span')({
  fontSize: 14,
  color: '#8F8F8F',
  display: 'inline-block',
  fontWeight: 'normal',
});

const CancelButton = styled(Button)`
  font-size: 14px;
  color: #2b8fd7;
  font-weight: 600;
  border-radius: 90px;
  border: 1px solid #2b8fd7;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 2px 16px;
  cursor: pointer;
  height: 32px;
  line-height: 17px;
  margin-top: 16px;

  &:hover {
    color: #2b8fd7;
  }

  ${dimensions.SCREEN_MAX_SM} {
    border: none;
    padding: 5px;

    & svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const CancelButtonText = styled('span')({
  fontSize: 14,
  [dimensions.SCREEN_MAX_XS]: {
    display: 'none',
  },
});

const HeaderContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItemsL: 'center',
});

const HeaderText = styled('div')({
  flex: 10,
});

const Title = styled('h2')({
  borderBottom: borders.DEFAULT,
});

interface PhotoModalProps {
  contentLabel: any;
  shouldOpen: any;
  shouldCloseOnOverlayClick: any;
  confirmationText: any;
  onCloseHandler: any;
  className: any;
  isBlurToolOpen: any;
  isCropperOpen: any;
  isPreviewOpen: any;
}

const PhotoModal = ({
  contentLabel,
  shouldOpen,
  shouldCloseOnOverlayClick,
  confirmationText,
  onCloseHandler,
  className,
  isBlurToolOpen,
  isCropperOpen,
  isPreviewOpen,
}: PhotoModalProps): ReactElement => {
  const userBlockModalClose = () => {
    onCloseHandler();
  };

  const getCustomStyle = () => {
    const browserDetails = getBrowserDetails();
    return {
      content: {
        padding: '5px 20px',
        margin: '25px auto',
        top: browserDetails.isMobile && !tabletWidth ? 0 : 25,
        border: 0,
        height: browserDetails.isMobile && !tabletWidth ? '100%' : 'auto',
        borderRadius: 6,
        maxWidth: isBlurToolOpen ? 670 : 670,
      },
    };
  };

  const renderStepDescription = () => {
    if (isPreviewOpen) {
      return <StepDescription>{`Step 2 of 2: Finalize`}</StepDescription>;
    }

    if (isBlurToolOpen) {
      return <StepDescription>{`Step 2 of 2: Blur`}</StepDescription>;
    }

    if (isCropperOpen) {
      return <StepDescription>{`Step 1 of 2: Cropping`}</StepDescription>;
    }
  };

  return (
    <BasicModal
      hideClose={true}
      ariaHideApp={false}
      className={`${className} wyp-photo-popup`}
      isOpen={shouldOpen}
      customStyle={getCustomStyle()}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onClose={userBlockModalClose}
      data={{ 'test-id': 'upload-photo-modal' }}
    >
      <HeaderContainer>
        <HeaderText>
          <Title>Edit Photo {renderStepDescription()}</Title>
        </HeaderText>
        <div className="top-actions">
          <CancelButton onClick={userBlockModalClose}>
            <SvgIcon component={CloseOutlined} style={closeButton} />
            <CancelButtonText>Cancel</CancelButtonText>
          </CancelButton>
        </div>
      </HeaderContainer>
      <div>{confirmationText}</div>
    </BasicModal>
  );
};

PhotoModal.propTypes = {
  styles: PropTypes.object,
  contentLabel: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  isBlurToolOpen: PropTypes.bool,
  isCropperOpen: PropTypes.bool,
  isPreviewOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelBtnText: PropTypes.string.isRequired,
  googleSignupText: PropTypes.string.isRequired,
  onCloseHandler: PropTypes.func,
  btnAlignment: PropTypes.string,
  className: PropTypes.string,
};

PhotoModal.defaultProps = {
  styles: {
    overlay: { zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.65)', overflowY: 'auto' },
    content: {
      background: '#fff',
      bottom: 'initial',
      top: 82,
      transform: 'scale(1)',
      margin: '0 auto',
      outline: 'none',
      ':focus': { outline: 'none' },
    },
  },
  contentLabel: 'Social login confirmation modal',
  shouldOpen: false,
  isBlurToolOpen: false,
  isCropperOpen: false,
  isPreviewOpen: false,
  shouldCloseOnOverlayClick: false,
  confirmationText: 'Are you sure with this?',
  cancelBtnText: 'Cancel',
  googleSignupText: 'Sign up with Google',
  onCloseHandler: () => null,
  btnAlignment: 'center',
  className: '',
};

export default PhotoModal;
