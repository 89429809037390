import { mixpanelTrackEventFromBE } from '.';
export type MixpanelInfoModalShownSourcePage =
  | 'Dashboard'
  | 'My Favorites'
  | 'Favorited Me'
  | 'Viewed Me'
  | 'Received Requests'
  | 'Received Offers'
  | 'Sent Offers'
  | 'Search'
  | 'Discover Mode'
  | 'Member Profile';

export type Message = 'What is an Offer' | 'What is an Opening Offer' | 'Too Many Offers';

const getSourcePageFromUrl = (
  sourcePage?: MixpanelInfoModalShownSourcePage
): MixpanelInfoModalShownSourcePage | undefined => {
  const sources: {
    [key: string]: MixpanelInfoModalShownSourcePage;
  } = {
    '/': 'Dashboard',
    '/dashboard': 'Dashboard',
    '/offers/sent': 'Sent Offers',
    '/offers/new': 'Received Offers',
    '/interests/my-favorites': 'My Favorites',
    '/interests/favorited-me': 'Favorited Me',
    '/interests': 'Viewed Me',
  };

  if (sourcePage) return sourcePage;

  const source = sources[window.location.pathname];

  if (source) {
    return source;
  }

  if (window.location.pathname.includes('profile')) {
    return 'Member Profile';
  }
};

export const mixpanelTrackWhatsAnOfferModalShown = async (
  sourcePage?: MixpanelInfoModalShownSourcePage
): Promise<void> => {
  const source = getSourcePageFromUrl(sourcePage);

  if (!source) return;

  await mixpanelTrackEventFromBE({
    event_name: 'Info Modal Shown',
    payload: {
      event_properties: {
        Source: source,
        Message: 'What is an Offer',
      },
    },
  });
};

export const mixpanelTrackWhatsAnOpeningOfferModalShown = async (
  sourcePage?: MixpanelInfoModalShownSourcePage
): Promise<void> => {
  const source = getSourcePageFromUrl(sourcePage);

  if (!source) return;

  await mixpanelTrackEventFromBE({
    event_name: 'Info Modal Shown',
    payload: {
      event_properties: {
        Source: source,
        Message: 'What is an Opening Offer',
      },
    },
  });
};

export const mixpanelTrackTooManyOffersModalShown = async (
  sourcePage?: MixpanelInfoModalShownSourcePage
): Promise<void> => {
  const source = getSourcePageFromUrl(sourcePage);

  if (!source) return;

  await mixpanelTrackEventFromBE({
    event_name: 'Info Modal Shown',
    payload: {
      event_properties: {
        Source: source,
        Message: 'Too Many Offers',
      },
    },
  });
};
