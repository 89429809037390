import React, { useState, useEffect, ReactElement } from 'react';
import PanelTextWithHeader from '../../../atoms/panels/PanelTextWithHeader';
import EditableTextarea from '../../../atoms/EditableTextarea';
import { isDarkMode } from '../../../utils/helpers';

const AboutMe = (props: any): ReactElement => {
  /* maintain a state here and create a method to pass down as props to toggle the state
     to show the editable fields for updating the about myself data */

  const { description, editable, errors, isProcessing, ...rest } = props;
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [aboutMe, setAboutMe] = useState('');

  useEffect(() => {
    setAboutMe(description);
    return () => {
      setEditAboutMe(false);
    };
  }, [description]);

  const toggleEditable = e => {
    e.preventDefault();
    setEditAboutMe(!editAboutMe);
  };

  const updateAboutMe = (e, value) => {
    setAboutMe(value);
  };

  const handleSubmit = e => {
    const { updateProfileDescription } = props;
    updateProfileDescription({
      description: aboutMe,
    });
    toggleEditable(e);
  };

  const handleCancel = e => {
    // need to clear the fields if user select cancel button
    toggleEditable(e);
    setAboutMe(description);
  };

  return (
    <div>
      {editAboutMe ? (
        <EditableTextarea
          className="profile__about-me"
          title="A little bit about myself"
          value={aboutMe}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          updateValue={updateAboutMe}
          errors={errors}
          id={'description'}
          minCount={50}
          placeholder="Let people know some fun facts about who you are and what you're into. Icebreakers for your dates!"
          customStyle={{ paddingBottom: 20 }}
          isProcessing={isProcessing}
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
        />
      ) : (
        <PanelTextWithHeader
          className="profile__about-me"
          title="A little bit about myself"
          editable={editable}
          content={aboutMe}
          toggleEditable={toggleEditable}
          dataTestId="edit-desc-info"
          isProcessing={isProcessing}
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
          style={{ marginBottom: 15, padding: '0 15px 15px' }}
          minCount={50}
          {...rest}
        />
      )}
    </div>
  );
};

export default AboutMe;
