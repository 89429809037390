import React, { ReactElement } from 'react';
interface IRoundPublicIconProps {
  customStyle?: any;
}

const RoundPublicIcon = ({ customStyle, ...rest }: IRoundPublicIconProps): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M24.5 30.2C26.0341 30.2 27.3383 29.646 28.4125 28.5381C29.486 27.4294 30.0227 26.0833 30.0227 24.5C30.0227 22.9167 29.486 21.5706 28.4125 20.4619C27.3383 19.354 26.0341 18.8 24.5 18.8C22.9659 18.8 21.6617 19.354 20.5875 20.4619C19.514 21.5706 18.9773 22.9167 18.9773 24.5C18.9773 26.0833 19.514 27.4294 20.5875 28.5381C21.6617 29.646 22.9659 30.2 24.5 30.2ZM24.5 27.92C23.5795 27.92 22.7974 27.5873 22.1535 26.9219C21.5087 26.2573 21.1864 25.45 21.1864 24.5C21.1864 23.55 21.5087 22.7423 22.1535 22.0769C22.7974 21.4123 23.5795 21.08 24.5 21.08C25.4205 21.08 26.203 21.4123 26.8478 22.0769C27.4917 22.7423 27.8136 23.55 27.8136 24.5C27.8136 25.45 27.4917 26.2573 26.8478 26.9219C26.203 27.5873 25.4205 27.92 24.5 27.92ZM24.5 34C21.5136 34 18.7932 33.1395 16.3386 31.4185C13.8841 29.6984 12.1045 27.3922 11 24.5C12.1045 21.6078 13.8841 19.3012 16.3386 17.5802C18.7932 15.8601 21.5136 15 24.5 15C27.4864 15 30.2068 15.8601 32.6614 17.5802C35.1159 19.3012 36.8955 21.6078 38 24.5C36.8955 27.3922 35.1159 29.6984 32.6614 31.4185C30.2068 33.1395 27.4864 34 24.5 34Z"
        fill="#2B8FD7"
      />
    </svg>
  );
};

export default RoundPublicIcon;
