/**
 * @Author: zachalam
 * @Date:   2017-03-14T13:43:31-07:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-03-14T13:43:31-07:00
 */

import React, { useEffect } from 'react';
import BillingHistory from '../components/pages/Billing/BillingHistory';
import { connect } from 'react-redux';
import { getBillingPayments } from '../actions/billingActions';

const BillingHistoryContainer = ({ refresh, ...rest }) => {
  useEffect(() => {
    refresh();
  }, [refresh]);

  return <BillingHistory {...rest} />;
};

const mapStateToProps = state => {
  return {
    payments: state.billing.payments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refresh: () => {
      dispatch(getBillingPayments());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BillingHistoryContainer);
