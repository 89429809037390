import React, { ReactElement } from 'react';

const CaretDown = (): ReactElement => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
      <g id="WYP" fill="none" fillRule="evenodd">
        <g id="Styleguide" transform="translate(-188 -1619)" fill="#B9B9B9">
          <g id="Dropdown" transform="translate(54 1600)">
            <polygon
              id="Caret---Down"
              points="135.090909 19 134 20.0769231 140 26 146 20.0769231 144.909091 19 140 23.8461538"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CaretDown;
