import React, { useState, useEffect, ReactElement } from 'react';
import Container from '../../blocks/Container';
import Panel from '../../../atoms/panels/Panel';
import Button from '../../../atoms/buttons/Button';
import Header from '../../blocks/Header';
import Headline from '../../common/Headline';
import Text from '../../common/Text';
import { SITE_ID, auth10tixOrigin, FULL_URL } from '../../../config/Master';
import SkeletonLoader from '../../../atoms/SkeletonLoader';
import styled from '@emotion/styled';
import { borders } from '../../../style';
import { toaster } from '../../../common';

const StyledIframe = styled('iframe')({
  border: borders.DARKER,
  minHeight: 550,
  width: '100%',
});

interface VerificationRequiredProps {
  generateUrl: any;
  finishVerification: any;
  verificationStatus: any;
  refreshProfile: any;
  account: any;
}

const VerificationRequired = ({
  generateUrl,
  finishVerification,
  verificationStatus,
  refreshProfile,
  account,
}: VerificationRequiredProps): ReactElement => {
  const [startVerification, setSTartVerification] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState(null);

  const receiveMessage = event => {
    if (event.origin !== auth10tixOrigin) return;

    const { data } = event;

    if (data.eventType === 'Success') {
      finishVerification(verificationUrl).then(() => {
        refreshProfile().then(result => {
          if (result.id_verification?.data?.status === 'pending') {
            toaster.success('ID validation sent for review');
            setTimeout(() => location.replace(`/profile/${account.hash_id}`), 1500);
          }
        });
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', e => receiveMessage(e));

    return () => {
      window.removeEventListener('message', e => receiveMessage(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setSTartVerification(true);
    const responseData = await generateUrl();
    setVerificationUrl(responseData);
  };

  const redirectToHelpCenter = () => {
    if (SITE_ID === 'fd') {
      window.location.replace(`${FULL_URL}/faq`);
    } else {
      window.location.replace(`${FULL_URL}/faq/giving-feedback`);
    }
  };

  if (startVerification) {
    return (
      <Container screenTop width="medium">
        {verificationUrl ? (
          <StyledIframe src={verificationUrl.iframe_url} title="ID Verification" allow="camera *; microphone *" />
        ) : (
          <SkeletonLoader width="100%" />
        )}
      </Container>
    );
  }

  const renderVerificationContent = () => {
    if (verificationStatus === 'denied' || verificationStatus === 'revoked') {
      return (
        <Container screenTop width="medium">
          <Panel withHeader>
            <Header>
              <Headline data-test-id="fs-modal-headline">ID Verification Denied</Headline>
            </Header>
            <Text size="SM">
              Your verification has been denied. If you have any questions, please reach out to customer support.
            </Text>
            <Button buttonType="primary" onClick={redirectToHelpCenter}>
              Visit Help Center
            </Button>
          </Panel>
        </Container>
      );
    }

    if (verificationStatus === 'pending') {
      return (
        <Container screenTop width="medium">
          <Panel withHeader>
            <Header>
              <Headline data-test-id="fs-modal-headline">ID Verification Pending</Headline>
            </Header>
            <Text size="SM">
              Your verification is being processed. If it has been more than 24 hours, please contact customer service.
            </Text>
          </Panel>
        </Container>
      );
    }

    if (verificationStatus === 'required' || verificationStatus === '') {
      return (
        <Container screenTop width="medium">
          <Panel withHeader>
            <Header>
              <Headline data-test-id="fs-modal-headline">Please confirm your identity</Headline>
            </Header>
            <Text size="SM">
              To encourage and maintain trust between members, you will need to confirm your identity to have your
              account re-activated. Once successfully completed, your profile will have a blue check mark to let others
              know you have been ID verified.
            </Text>
            <Text size="SM">
              We have partnered with Au10tix.com to securely and privately process this verification. Login and navigate
              to your profile where you can begin the process. Please have a photo issued government identification,
              such as passport or drivers license, ready.
            </Text>
            <Button buttonType="primary" onClick={handleSubmit}>
              Get ID Verified
            </Button>
          </Panel>
        </Container>
      );
    }

    return <SkeletonLoader width="100%" />;
  };

  return renderVerificationContent();
};

export default VerificationRequired;
