import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import ProfilePhotoSlider from './ProfilePhotoSlider';

const PhotoContainer = styled('div')({
  flex: 1,
  maxWidth: '245px',
  minWidth: '245px',
  minHeight: 500,
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  '@media (max-width: 550px)': {
    maxWidth: '100%',
  },
});

interface ProfilePhotoContainerProps {
  onClickPhoto: any;
  photoList: any;
  isOtherProfile: any;
  updateMaxLength: any;
  updateSliderPosition: any;
  privatePhotoCount: any;
  currentUserIsGenerous: any;
  sliderPosition: any;
  lightboxIsOpen: any;
  counterBadge: any;
  totalImages: any;
  offer: any;
}

const ProfilePhotoContainer = ({
  onClickPhoto,
  photoList,
  isOtherProfile,
  updateMaxLength,
  updateSliderPosition,
  privatePhotoCount,
  currentUserIsGenerous,
  sliderPosition,
  lightboxIsOpen,
  counterBadge,
  totalImages,
  offer,
}: ProfilePhotoContainerProps): ReactElement => {
  return (
    <PhotoContainer>
      <ProfilePhotoSlider
        photoList={photoList}
        onClick={onClickPhoto}
        isOtherProfile={isOtherProfile}
        updateMaxLength={updateMaxLength}
        updateSliderPosition={updateSliderPosition}
        privatePhotoCount={privatePhotoCount}
        currentUserIsGenerous={currentUserIsGenerous}
        sliderPosition={sliderPosition}
        lightboxIsOpen={lightboxIsOpen}
        counterBadge={counterBadge}
        totalImages={totalImages}
        offer={offer}
      />
    </PhotoContainer>
  );
};

export default ProfilePhotoContainer;
