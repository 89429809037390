import React, { ReactElement } from 'react';
import { Select } from '../../../atoms/Select';
import FilterSection from '../../blocks/FilterSection';
import SearchBar from './SearchBar';

interface FiltersLivingInProps {
  onChangeHandler: any;
  theState: any;
  saveLocation: any;
  setHiddenMobileApplyFilterButton: any;
  resetFiltersState: any;
}

const FiltersLivingIn = ({
  onChangeHandler,
  theState,
  saveLocation,
  setHiddenMobileApplyFilterButton,
  resetFiltersState,
}: FiltersLivingInProps): ReactElement => {
  return (
    <div>
      <FilterSection fullWidth collapsable={false} header="Location">
        <SearchBar
          setHiddenMobileApplyFilterButton={setHiddenMobileApplyFilterButton}
          saveLocation={saveLocation}
          searchFilters={theState}
          resetFiltersState={resetFiltersState}
        />
      </FilterSection>
      <FilterSection collapsable={false} fullWidth header="Distance">
        <Select
          onChange={onChangeHandler}
          value={theState.distance}
          id="distance"
          style={{ width: 'auto', display: 'inline-block', paddingBottom: '0px', marginRight: '0px' }}
        >
          <option value={10}>10 miles away</option>
          <option value={20}>20 miles away</option>
          <option value={30}>30 miles away</option>
          <option value={40}>40 miles away</option>
          <option value={50}>50 miles away</option>
          <option value={100}>100 miles away</option>
          <option value={200}>200 miles away</option>
          <option value={300}>300 miles away</option>
          <option value={400}>400 miles away</option>
          <option value={500}>500 miles away</option>
          <option value={1000}>1000 miles away</option>
          <option value={20000}>within any distance</option>
        </Select>
      </FilterSection>
    </div>
  );
};

export default FiltersLivingIn;
