/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { connectScreenSize } from 'react-screen-size';
import isEmpty from 'lodash/isEmpty';
import { Waypoint } from 'react-waypoint';
import { differenceInHours } from 'date-fns';
import SearchSort from './SearchSort';
import Filters from './Filters';
import BlankSlate from '../../blocks/BlankSlate';
import Button from '../../../atoms/buttons/Button';
import Container from '../../blocks/Container';
import Headline from '../../common/Headline';
import { commonIcons } from '../../../atoms/icons/materialIcons';
import CloseIcon from '../../../atoms/icons/CloseIcon';
import { backgroundTheme, color, textType } from '../../../style';
import { extractLocationData, displayUserLocation, pluralize, renderAvatar } from '../../../common';
import CardList from '../../../modules/CardList';
import Card from '../../../atoms/Card';
import BackToTop from '../../blocks/BackToTopContainer';
import { Sticky } from 'react-sticky';
import { mapMixPanelTrackSearchViewProperties, scrollToTop } from '../../../utils/helpers';
import { useStateCallback } from '../../../utils/customHooks';
import NuxGuide from '../../blocks/NuxGuide';
import useMixPanel from '../../../hooks/useMixPanel';
import { nuxEnabled } from '../../../config/Master';
import { EVENT_NAMES, TRACK_PROFILE_VIEWS_FOR_OFFER_MADE } from '../../../constants/mixpanel';
import TrackLoadingToMixpanel from '../../common/TrackLoadingToMixPanel';
import { useLocation, useNavigate } from 'react-router-dom';

import uuidv4 from 'uuid';
import Spinner from '../../../atoms/loader/Spinner/Spinner';
import LoadingBackdrop from '../../../atoms/loader/LoadingBackdrop';

const LoadingContainer = styled('div')({
  paddingTop: '22px',
});

const ListContainer = styled('div')({
  paddingRight: '10px',
  paddingLeft: '10px',
});

const FilterHeaderContainer = styled(Container)({
  background: backgroundTheme.surface,
  zIndex: 10,
  padding: '0 1%',
  width: '98% !important',
  maxWidth: 1400,
});

const SortMatchesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Matches = styled('div')({
  ...textType.text,
});

const WaypointContainer = styled('div')`
  font-size: 16px;
  text-align: center;
  padding: 0 24px 24px;
`;

const FilterButtonText = styled('span')({
  marginRight: '10px',
});

const FilterHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
  justifyContent: 'space-between',
});

const SearchSummary = styled('div')({
  fontSize: '16px',
  flexGrow: '1px',
});

const LocationText = styled('h1')({
  textDecoration: 'underline',
  color: 'color.BRAND_1',
  cursor: 'pointer',
  ...textType.header,
});

const renderSearchProfileResults = (
  profile,
  screenSizeIsMobile,
  screenSizeIsSmall,
  handleSearchResultCardSelect,
  trackProfileView,
  setMixpanelDataByKeyHandler,
  updateInitialProfileToProfilePage
) => {
  try {
    const age = profile.age;
    const avatar = renderAvatar(profile.avatar.data.urls);
    const city = profile.city;
    const username = profile.account ? profile.account.data.username : '';
    const hashId = profile.hash_id || username;
    const photoCount = parseInt(profile.approved_public_photos_count, 10);
    const privatePhotoCount = profile.private_photos_count ? parseInt(profile.private_photos_count, 10) : 0;
    const online = profile.online;
    const userVerified = profile.id_verified;

    return (
      <Card
        id={`user-card-${hashId}`}
        onClick={() => {
          handleSearchResultCardSelect(hashId);
          trackProfileView({ Source: 'Search', User: hashId });
          setMixpanelDataByKeyHandler({
            key: EVENT_NAMES.OFFER_MADE,
            payload: { source: TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewSearch },
          });

          updateInitialProfileToProfilePage(profile);
        }}
        key={uuidv4()}
        age={age}
        city={city}
        username={username}
        hashId={hashId}
        photoCount={photoCount}
        privatePhotoCount={privatePhotoCount}
        online={online}
        cardWidth={screenSizeIsMobile ? '48%' : '24%'}
        cardMargin={screenSizeIsMobile ? '1%' : '.5%'}
        imgUrl={avatar}
        isVerified={userVerified}
        screenIsMobile={screenSizeIsMobile || screenSizeIsSmall}
      />
    );
  } catch (e) {
    return null;
  }
};

const Search = props => {
  const {
    search,
    saveFilters,
    refreshProfile,
    screenSizeIsMobile,
    screenSizeIsSmall,
    handleSearchResultCardSelect,
    userProfile,
    featuredProfiles,
    fetchPageNux,
    updateNuxState,
    skipNuxGuides,
    currentUserLocation,
    updateInitialProfileToProfilePage,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const {
    resetFilters,
    resetIndividualFilter,
    moreExists,
    resetFiltersState,
    searchCount,
    searchLimit,
    searchProfiles,
  } = props;

  const [formData, setFormData] = useStateCallback(null);
  const [filterToggled, setFilterToggled] = useState(false);
  const mounted = useRef();
  const { trackProfileView, trackSearchView, setMixpanelDataByKeyHandler } = useMixPanel();
  const [searchItems, setSearchItems] = useState(searchProfiles);

  const { gender, account_type, looking_male, looking_female } =
    (userProfile.profile && userProfile.profile.data) || {};

  useEffect(() => {
    if (searchProfiles) {
      setSearchItems(searchProfiles);
    }
  }, [searchProfiles]);

  // hide and show the filter bar.
  const filterClickHandler = () => {
    scrollToTop('auto');
    setFilterToggled(!filterToggled);
  };

  // run new Search - which type do we run?
  const initiateSearch = () => {
    window.differenceInHours = differenceInHours;

    // run a fresh search if search data is missing
    if (search && search.profile && Object.keys(search.profile).length === 0) {
      const hasPreserveresult =
        search.preservedResult && search.preservedResult.profile && search.preservedResult.profile.length > 0;
      const isLoadFromDashboard = location.search;

      if (hasPreserveresult || isLoadFromDashboard) {
        return;
      }

      runNewSearch();
    }
  };

  const runNewSearch = (newData = false) => {
    const updatedData = newData ? newData : formData;
    saveFilters(updatedData);
    refreshProfile(null, props => {
      if (updatedData) {
        const payload = mapMixPanelTrackSearchViewProperties(updatedData, props.meta);
        trackSearchView(false, {
          ...payload,
          'User Location': currentUserLocation,
        });
      }
    });
  };

  useEffect(() => {
    if (!mounted.current) {
      // componentDidMount logic
      initiateSearch();
      mounted.current = true;
    } else if (formData !== search.filters) {
      // componentDidUpdate logic
      setFormData(search.filters);
      const payload = mapMixPanelTrackSearchViewProperties(search.filters, null);
      setMixpanelDataByKeyHandler({
        key: EVENT_NAMES.SEARCH_VIEW,
        payload: {
          ...payload,
          'User Location': currentUserLocation,
        },
      });
    }
  }, [formData, search.filters, setFormData, setMixpanelDataByKeyHandler, currentUserLocation, mounted]);

  const profileResultList = () => {
    // Return null if the results are being fetched to show only the indicator.
    if (search.isFetching && isEmpty(searchItems)) return null;
    if (!isEmpty(searchItems)) {
      const ResultList = !isEmpty(searchItems)
        ? Object.values(searchItems).map(profile => {
            return renderSearchProfileResults(
              profile,
              screenSizeIsMobile,
              screenSizeIsSmall,
              handleSearchResultCardSelect,
              trackProfileView,
              setMixpanelDataByKeyHandler,
              updateInitialProfileToProfilePage
            );
          })
        : [];

      // boosted Profiles
      const BoostedProfiles = !isEmpty(featuredProfiles)
        ? Object.values(featuredProfiles).map(profile => {
            return renderSearchProfileResults(
              profile,
              screenSizeIsMobile,
              screenSizeIsSmall,
              handleSearchResultCardSelect,
              trackProfileView,
              setMixpanelDataByKeyHandler,
              updateInitialProfileToProfilePage
            );
          })
        : [];

      return <CardList isMobile={screenSizeIsMobile}>{[...BoostedProfiles, ...ResultList]}</CardList>;
    } else {
      return (
        <BlankSlate
          img="search"
          title="No members found"
          message="We couldn’t find anyone matching your search criteria. Try increasing the range and changing your filters."
        />
      );
    }
  };

  const saveLocation = place => {
    // apply location to state
    const loc = extractLocationData(place);
    setFormData({ ...formData, ...loc });
  };

  const applyFiltersHandler = newData => {
    // apply filters to search
    setFormData({ ...formData, ...newData }, newState => {
      navigate(location.pathname, {
        replace: true,
        state: {
          saveSearch: true,
        },
      });
      // apply to search
      runNewSearch(newState);
      // hide filter menu.
      setFilterToggled(false);
    });
  };

  const loadMoreItems = () => {
    const { loadMore } = props;

    loadMore(formData, props => {
      if (formData) {
        const payload = mapMixPanelTrackSearchViewProperties(formData, props.meta);
        trackSearchView(false, {
          ...payload,
          'User Location': currentUserLocation,
        });
      }
    });
  };

  const sortByOnChange = e => {
    const target = e.target;
    const newFormData = Object.assign({}, { ...formData }, { [target.id]: target.value });

    //  Apply the new Sort to the filters
    applyFiltersHandler(newFormData);
  };

  const filterButton = () => {
    const btnText = filterToggled ? null : 'Filter';
    const icon = filterToggled ? (
      <CloseIcon customStyle={{ stroke: color.BRAND_2, strokeWidth: 2, width: 19, height: 19 }} />
    ) : (
      <commonIcons.tune.default />
    );

    return (
      <Button
        buttonType="chromeless"
        icon={icon}
        iconPosition="right"
        onClick={filterClickHandler}
        css={{ paddingLeft: 8, paddingRight: 8, fontSize: 16, color: color.BRAND_2 }}
        customStyle={{ paddingLeft: 8, paddingRight: 8, fontSize: 16, color: color.BRAND_2 }}
      >
        <FilterButtonText>{btnText}</FilterButtonText>
      </Button>
    );
  };

  const renderWaypoint = () => {
    if (!search.isFetching) {
      if (!search.hasFetchError) {
        return (
          <Waypoint
            scrollableAncestor={window}
            onEnter={({ event }) => {
              if (event && event.type === 'scroll') {
                loadMoreItems();
              }
            }}
          />
        );
      } else {
        return (
          <Button
            fit
            buttonType="primary"
            onClick={() => {
              loadMoreItems();
            }}
          >
            Load More
          </Button>
        );
      }
    } else {
      return null;
    }
  };

  const clickToTop = e => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const renderSearchSummary = (display = true) => {
    const { city, country, region } = (userProfile.profile && userProfile.profile.data) || {};
    const userLoc = { city, country, region };
    const searchLoc = search.filters.city
      ? { city: search.filters.city, country: search.filters.country, region: search.filters.region }
      : userLoc;
    const searchLineLocation = displayUserLocation(userLoc, searchLoc, true);

    if (display) {
      return (
        <div>
          {(userLoc.city || searchLoc.city) && (
            <span>
              <LocationText
                onClick={() => {
                  filterClickHandler();
                }}
              >
                {searchLineLocation}
              </LocationText>
            </span>
          )}
        </div>
      );
    }
  };

  const matchesCount = () => {
    const featuredCount = featuredProfiles ? featuredProfiles.length : 0;
    return searchCount === 0 ? 0 : searchCount + featuredCount;
  };

  const displayFilterNav = () => {
    return (
      <FilterHeader data-test-id="search-filter-wrap">
        <SearchSummary>{!filterToggled ? renderSearchSummary() : <Headline>Filters</Headline>}</SearchSummary>
        <div data-test-id="search-filter-button">{filterButton()}</div>
      </FilterHeader>
    );
  };

  const renderMoreExists = () => {
    const renderSearchLimit = () => {
      return searchLimit ? (
        <div>
          In order to show you the most relevant results as quickly as possible, we have omitted the least relevant
          results past the 1,000 already displayed. Please refine your search filters to see different results.
        </div>
      ) : (
        <div>No More Results</div>
      );
    };

    const renderFetching = () => {
      return !search.isFetching ? <WaypointContainer>{renderSearchLimit()}</WaypointContainer> : null;
    };

    return moreExists ? <WaypointContainer>{renderWaypoint()}</WaypointContainer> : renderFetching();
  };

  const renderFilterHeaderContainer = style => {
    return (
      <FilterHeaderContainer style={style} distanceFromTop={60}>
        {displayFilterNav()}
      </FilterHeaderContainer>
    );
  };

  const renderNux = (route = '/search') => {
    return (
      <NuxGuide
        profile={userProfile}
        route={route}
        fetchPageNux={fetchPageNux}
        updateNuxState={updateNuxState}
        skipNuxGuides={skipNuxGuides}
      />
    );
  };

  return (
    <div>
      {filterToggled ? (
        <div>
          <Sticky topOffset={60}>{({ style }) => renderFilterHeaderContainer(style)}</Sticky>
          <Filters
            searchFilters={search.filters}
            filterClickHandler={filterClickHandler}
            applyFiltersHandler={applyFiltersHandler}
            resetFilters={resetFilters}
            resetIndividualFilter={resetIndividualFilter}
            resetFiltersState={resetFiltersState}
            saveLocation={saveLocation}
            accountType={account_type}
            lookingMale={looking_male}
            lookingFemale={looking_female}
            gender={gender}
          />
        </div>
      ) : (
        <Container
          fullWidth
          css={{
            padding: '10px 0',
            maxWidth: 1400,
            margin: '0 auto',
          }}
        >
          <Sticky topOffset={60}>{({ style }) => renderFilterHeaderContainer(style)}</Sticky>
          <Container
            css={{
              marginBottom: 12,
              maxWidth: 1400,
            }}
          >
            {!search.isFetching && (
              <SortMatchesContainer>
                <Matches>
                  {Number(matchesCount()).toLocaleString()} {pluralize(matchesCount(), 'Match', 'Matches')}
                </Matches>
                <SearchSort order={formData && formData.order} onChangeHandler={sortByOnChange} />
              </SortMatchesContainer>
            )}
          </Container>
          <ListContainer>{profileResultList()}</ListContainer>
          <LoadingContainer>
            {search.isFetching && <TrackLoadingToMixpanel page="Search" />}
            <LoadingBackdrop active={!moreExists && search.isFetching} showLoadingBg={false} />
            {moreExists && (
              <div style={{ textAlign: 'center' }}>
                <Spinner width={100} />
              </div>
            )}
            {!search.isFetching && renderMoreExists()}
            <BackToTop isVisible={!search.isFetching} onClick={clickToTop} />
          </LoadingContainer>
        </Container>
      )}
      {nuxEnabled && renderNux()}
    </div>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
    screenSizeIsSmall: screenSize.small,
  };
};

export default connectScreenSize(mapScreenToProps)(Search);
