import React, { ReactElement } from 'react';
import RadioButton from '../RadioButton';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../style';
import ArrowRightIos from '../icons/ArrowRightIos';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';
import SofortIcon from '../icons/SofortIcon';
import GooglePay from './GooglePay/GooglePay';
import { DeleteCardIcon } from '../icons/Billing';
import PaymentOptionRadio from '../PaymentOptionRadio';
import TextInput from '../TextInput';
import CvvIcon from '../icons/Billing/CvvIcon';
import { GPAY_PAYMENT_CHANNEL } from '../../config/gpay';
import ApplePayPaymentOption from '../../modules/ApplePay/ApplePayPaymentOption';
import { APAY_PAYMENT_CHANNEL } from '../../config/apay';

const PaymentOptionContainer = styled('div')({
  width: 564,
  [dimensions.SCREEN_MAX_MD_2]: {
    width: 'auto',
  },
});

const DeleteCard = styled('div')({
  cursor: 'pointer',
  paddingLeft: 12,
  color: patternModal.headerColor,
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
});

const ToggleSectionBaseStyle = {
  borderRadius: 6,
  padding: '15px 20px',
  backgroundColor: isDarkMode() ? '#020D15' : '#FFFFFF',
  display: 'flex',
  marginBottom: 20,
  alignItems: 'center',
  flexWrap: 'wrap',
  [dimensions.SCREEN_MIN_XS_4]: {
    fontSize: 15,
  },
};

const ToggleSection = styled('div')({
  borderBottom: '0px',
  ...ToggleSectionBaseStyle,
});

const CardSection = styled('div')({
  ...ToggleSectionBaseStyle,
});

const OtherPaymentSection = styled('div')({
  ...ToggleSectionBaseStyle,
  display: 'block',
  padding: 0,
  minHeight: 66,
});

const PaymentOptionCustomStyle = {
  flexGrow: 4,
  minHeight: 36,
  alignItems: 'center',
  display: 'flex',
};

const TokenCvvForm = styled('div')({
  flexBasis: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  alignItems: 'center',
  marginTop: 15,
  marginLeft: 30,
  maxWidth: 263,
});

const TokenCvvFormError = styled('small')({
  fontSize: 13,
  color: '#F00',
  fontWeight: 400,
  fontStyle: 'italic',
  marginTop: 15,
});

interface CreditCardSwitcherProps {
  newCard: any;
  savedCardId: any;
  savedCardToken: any;
  savedCardType: any;
  savedCardPaymentType: any;
  savedCardLastFour: any;
  onRadioButtonChangeHandler: any;
  handleDeleteCard: any;
  paymentChannel: any;
  use2PayData: any;
  hidePackageForm?: any;
  handleSubmit: any;
  handleSelectSaveCard: any;
  descriptors?: any;
  profileData?: any;
  discountAmount: any;
  statuses?: any;
  savedCard: any;
  alternativePayment: boolean;
  rocketgateGooglepay?: boolean;
  showCvv2?: boolean;
  allowTokenSubmit?: boolean;
  onTokenCvvInputChange: any;
  isApplePayEligible: boolean;
}

const CreditCardSwitcher = ({
  newCard,
  savedCardId,
  savedCardToken,
  savedCardType,
  savedCardPaymentType,
  savedCardLastFour,
  onRadioButtonChangeHandler,
  handleDeleteCard,
  paymentChannel,
  use2PayData,
  handleSubmit,
  savedCard,
  alternativePayment,
  discountAmount,
  rocketgateGooglepay,
  onTokenCvvInputChange,
  showCvv2,
  allowTokenSubmit,
  isApplePayEligible,
}: CreditCardSwitcherProps): ReactElement => {
  const isBanktransfer = paymentChannel === 'sofort';
  const isGooglePay = paymentChannel === GPAY_PAYMENT_CHANNEL;
  const isApplePay = paymentChannel === APAY_PAYMENT_CHANNEL;
  const hasTokenCvv2 = use2PayData?.use2pay_token_cvv?.length > 0 || false;

  const getSaveCardLabel = (savedCardPaymentType: any, savedCardLastFour: any): string => {
    if (savedCardLastFour && savedCardLastFour.length > 0) {
      return `${savedCardPaymentType ? '' : 'Use card'} ending in ${savedCardLastFour}`;
    }

    return '';
  };

  return (
    <PaymentOptionContainer>
      {savedCard && (
        <CardSection data-test-id="payment-switcher-card">
          <PaymentOptionRadio
            id="card_reference"
            name="saved_card"
            label={getSaveCardLabel(savedCardPaymentType, savedCardLastFour)}
            value={savedCardToken}
            onRadioChange={() => onRadioButtonChangeHandler(false)}
            checked={!newCard && !isBanktransfer && !isGooglePay && !isApplePay}
            style={PaymentOptionCustomStyle}
            labelId="radio-card-reference"
            cardType={savedCardType}
            paymentType={savedCardPaymentType}
          />
          <DeleteCard onClick={() => handleDeleteCard(savedCardId)}>
            <DeleteCardIcon customStyle={{ color: isDarkMode() ? '#D06369' : '#BC2028' }} />
            {getBrowserDetails().isMobile ? '' : ' Remove Card'}
          </DeleteCard>
          {showCvv2 && (
            <TokenCvvForm>
              <TextInput
                status={hasTokenCvv2 && allowTokenSubmit ? 'default' : 'error'}
                id="token_cvv"
                hideStatusIcon={true}
                customInputStyle={{
                  borderRadius: '6px !important',
                  height: 40,
                  borderColor: hasTokenCvv2 && allowTokenSubmit ? '#6c6c6c' : '#F00 !important',
                }}
                customStyle={{ maxWidth: 80, paddingBottom: 0, marginRight: 10, borderRadius: 6 }}
                data-test-id="cvv"
                value={use2PayData.use2pay_token_cvv}
                onChange={event => onTokenCvvInputChange(event, savedCardType)}
              />
              <label style={{ marginRight: 10, fontSize: 13, fontWeight: 400 }}>Enter CVV Code</label>
              <CvvIcon />
              {!allowTokenSubmit && <TokenCvvFormError>Invalid code. Please input again.</TokenCvvFormError>}
            </TokenCvvForm>
          )}
        </CardSection>
      )}
      {isApplePayEligible && (
        <OtherPaymentSection>
          <ApplePayPaymentOption onChange={onRadioButtonChangeHandler} paymentChannel={paymentChannel} />
        </OtherPaymentSection>
      )}
      {rocketgateGooglepay && (
        <OtherPaymentSection>
          <GooglePay
            onChange={onRadioButtonChangeHandler}
            paymentChannel={paymentChannel}
            use2PayData={use2PayData}
            handleSubmit={handleSubmit}
            discount={discountAmount}
          />
        </OtherPaymentSection>
      )}
      {alternativePayment && (
        <ToggleSection newCard={paymentChannel} data-test-id="payment-switcher-bank-transfer">
          <RadioButton
            id="bank_transfer"
            name="bank_transfer"
            label="Bank Transfer"
            value={'bank_transfer'}
            onRadioChange={() => onRadioButtonChangeHandler(true, 'sofort')}
            checked={isBanktransfer}
            style={PaymentOptionCustomStyle}
            labelId="radio-bank-transfer"
          />
          <SofortIcon isDarkMode={isDarkMode()} />
        </ToggleSection>
      )}
      <ToggleSection newCard={newCard} data-test-id="payment-switcher-new">
        <RadioButton
          id="new_card"
          name="saved_card"
          label="Pay with a new card"
          value={'new_card'}
          onRadioChange={() => onRadioButtonChangeHandler(true)}
          checked={newCard && !isBanktransfer && !isGooglePay && !isApplePay}
          style={PaymentOptionCustomStyle}
          labelId="radio-new-card"
        />
        <ArrowRightIos selected={newCard && !isBanktransfer && !isGooglePay && !isApplePay} />
      </ToggleSection>
    </PaymentOptionContainer>
  );
};

export default CreditCardSwitcher;
