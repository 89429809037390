import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { textColor } from '../../style';

const CyprusAddress = styled('div')({
  fontSize: 14,
  color: textColor.DARK.color,
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '20px',
  letterSpacing: ' -0.28px',
});

const AfterPayment = (props: any): ReactElement => {
  const displayDescriptors = descriptorsArray => {
    if (typeof descriptorsArray === 'undefined' || descriptorsArray === null) return '';

    const descriptorsLength = descriptorsArray.length;
    let stringDescriptors = '';

    if (descriptorsLength === 0) {
      stringDescriptors = '';
    } else if (descriptorsLength === 1) {
      stringDescriptors = descriptorsArray[0];
    } else if (descriptorsLength === 2) {
      stringDescriptors = descriptorsArray.join(' or ');
    } else {
      stringDescriptors = descriptorsArray.slice(0, -1).join(', ') + ' or ' + descriptorsArray.slice(-1);
    }

    return stringDescriptors;
  };

  return (
    <CyprusAddress>
      <p style={{ textAlign: 'center' }}>
        {`For your protection, your credit card statement will read ${displayDescriptors(props.descriptors)}.`}
      </p>
      <p style={{ textAlign: 'center' }}>All sales are final and we do not offer refunds.</p>
      <p style={{ textAlign: 'center', marginBottom: 0 }}>
        W8Tech Cyprus Limited
        <br />
        Irakleous 2, Flat/Office 101
        <br />
        2320 NicoSia, Cyprus
      </p>
    </CyprusAddress>
  );
};

export default AfterPayment;
