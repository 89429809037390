import _camelCase from 'lodash/camelCase';
import _capitalize from 'lodash/capitalize';
import { VYSION_ACTIONS } from '..';
import { IProfileCompletionVysionEvents } from './common';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
};
const mandatoryCompletionCommonValues = {
  component: 'Mandatory Profile Completion Popup',
  ...commonValues,
};

const finishProfileCommonValues = {
  component: 'Finish my profile popup',
  ...commonValues,
};

export const enum ProfileCompletionClickVysionEventsEnum {
  CLICK_MANDATORY_COMPLETION_CLOSE = 'mandatoryProfileCompletionClose',
  CLICK_MANDATORY_COMPLETION_RESEND_EMAIL = 'mandatoryProfileCompletionresendVerificationEmail',
  CLICK_MANDATORY_COMPLETION_CHANGE_EMAIL_ADDRESS = 'mandatoryProfileCompletionChangeEmailAddress',
  CLICK_MANDATORY_COMPLETION_FINISH_MY_PROFILE = 'mandatoryProfileCompletionfinishMyProfile',
  CLICK_MANDATORY_COMPLETION_SKIP_FOR_NOW = 'mandatoryProfileCompletionskipForNow',
  CLICK_FINISH_PROFILE_CLOSE = 'finishMyProfileClose',
  CLICK_FINISH_PROFILE_ADD_PHOTO = 'finishMyProfileaddPhoto',
  CLICK_FINISH_PROFILE_EDUCATION = 'finishMyProfileeducation',
  CLICK_FINISH_PROFILE_ANNUAL_INCOME = 'finishMyProfileannualIncome',
  CLICK_FINISH_PROFILE_SAVE_AND_FINISH = 'finishMyProfileSaveAndFinish',
  CLICK_FINISH_PROFILE_SKIP_FOR_NOW = 'finishMyProfileSkipForNow',
}

export const handleProfileCompletionClickVysionEvents = (props: IProfileCompletionVysionEvents): any => {
  const pageName = _camelCase(props.page ?? 'Profile');
  const page = _capitalize(pageName);
  return {
    [ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_CLOSE]: {
      ...mandatoryCompletionCommonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion-close`,
      extraInfo: 'close',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_RESEND_EMAIL]: {
      ...mandatoryCompletionCommonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion-resendVerificationEmail`,
      extraInfo: 'resend verification email',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_CHANGE_EMAIL_ADDRESS]: {
      ...mandatoryCompletionCommonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion-ChangeEmailAddress`,
      extraInfo: 'Change email address',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_FINISH_MY_PROFILE]: {
      ...mandatoryCompletionCommonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion-finishMyProfile`,
      extraInfo: 'Finish my profile',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_MANDATORY_COMPLETION_SKIP_FOR_NOW]: {
      ...mandatoryCompletionCommonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion-skipForNow`,
      extraInfo: 'Skip for now',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_CLOSE]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-close`,
      extraInfo: 'close',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_ADD_PHOTO]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-addPhoto`,
      extraInfo: 'Add Photo',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_EDUCATION]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-education`,
      extraInfo: 'education',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_ANNUAL_INCOME]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-annualIncome`,
      extraInfo: 'Annual income',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_SAVE_AND_FINISH]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-SaveAndFinish`,
      extraInfo: 'Save and finish',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_SKIP_FOR_NOW]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-skipForNow`,
      extraInfo: 'Skip for now',
    },
    [ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_CLOSE]: {
      ...finishProfileCommonValues,
      page,
      eventName: `${pageName}-finishMyProfile-close`,
      extraInfo: 'close',
    },
  };
};
