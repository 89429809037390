import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import RoundDismissIcon from '../../atoms/icons/RoundDismissIcon';
import WYPBannerIcon from './icons/WYPBannerIcon';
import backgrounds from '../../theme/backgrounds';
import { theme } from '../../theme';
import { isFD, SITE_NAME } from '../../config/Master';
import FDBannerIcon from './icons/FDBannerIcon';
import { evaluateOrOperator, isDarkMode } from '../../utils/helpers';
import borders from '../../theme/borders';
import PWAInstallIos from './installationScreens/PWAInstallIos';
import PWAInstallIosChrome from './installationScreens/PWAInstallIosChrome';
import { getBrowserDetails } from '../../utils/helpers';
import { useApp } from '../../contexts/AppContext';
import store from '../../store';
import { AccountMaker } from '../../models/Account';
import { showPWAInstallationGlobalBanner } from '../../utils/pwa';
import { mixpanelTrackPWAInstallBannerShown } from '../../utils/mixpanel/pwaInstallBannerShown';

const slideUpAnimation = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const PWAInstallationContainer = styled('div')({
  position: 'fixed',
  background: backgrounds[theme].primaryCard,
  boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.16)',
  zIndex: -1,
  width: '100%',
  bottom: 0,
  minHeight: 469,
  borderRadius: '20px 20px 0px 0px',
  animation: `${slideUpAnimation} 0.2s ease-out`,
});

const PWABannerWrapper = styled('div')({
  display: 'flex',
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 20,
  paddingBottom: 15,
  flexDirection: 'column',
});

const PWABannerHeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `solid 1px ${borders[theme].line1}`,
  width: '100%',
  paddingBottom: 15,
});

const PWAModalHeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

const PWAModalBannerTitle = styled('p')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '-0.16px',
  color: isDarkMode() ? '#CFCFCF' : '#595959',
  padding: 0,
  margin: 0,
  marginLeft: 12,
});

const logoStyles = {
  width: 48,
  height: 48,
};

const { showInstall, showInstallIosChrome } = getBrowserDetails();

const PWAInstallationModal: React.FC = () => {
  const { showInstallationBanner, setShowIntallationBanner, setPwaBannerState } = useApp();
  const account = store.getState().profile;

  const bannerContainerRef = useRef(null);

  const handleDismissBanner = async () => {
    const accountModel = AccountMaker.create(account);
    const shouldShow = await showPWAInstallationGlobalBanner(accountModel);

    if (shouldShow.show) {
      mixpanelTrackPWAInstallBannerShown();
    }

    setPwaBannerState({ ...shouldShow });
    setShowIntallationBanner(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        bannerContainerRef.current &&
        !bannerContainerRef.current.contains(event.target) &&
        evaluateOrOperator(showInstall, showInstallIosChrome) &&
        showInstallationBanner
      ) {
        handleDismissBanner();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [setShowIntallationBanner, showInstallationBanner]);

  return (
    <PWAInstallationContainer
      ref={bannerContainerRef}
      style={{ zIndex: showInstallationBanner ? 10000 : -5, display: showInstallationBanner ? 'block' : 'none' }}
    >
      <PWABannerWrapper>
        <PWABannerHeaderContainer>
          <RoundDismissIcon customStyle={{ position: 'absolute', right: 15, top: 20 }} onClick={handleDismissBanner} />
          <PWAModalHeaderWrapper>
            {isFD ? <FDBannerIcon customStyle={logoStyles} /> : <WYPBannerIcon customStyle={logoStyles} />}
            <PWAModalBannerTitle>{`Install ${SITE_NAME}`}</PWAModalBannerTitle>
          </PWAModalHeaderWrapper>
        </PWABannerHeaderContainer>
        {showInstall && <PWAInstallIos />}
        {showInstallIosChrome && <PWAInstallIosChrome />}
      </PWABannerWrapper>
    </PWAInstallationContainer>
  );
};

export default PWAInstallationModal;
