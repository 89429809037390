import React, { ReactElement } from 'react';
interface IStepDoneIconProps {
  customStyle?: any;
}

const StepDoneIcon = ({ customStyle }: IStepDoneIconProps): ReactElement => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" style={customStyle} xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.5" cy="14.0995" r="13" fill="white" stroke="#2B8FD7" strokeWidth="2" />
      <path
        d="M9.89213 21.0995V19.1051L14.5519 14.7905C14.9482 14.407 15.2806 14.0618 15.5491 13.755C15.8218 13.4482 16.0285 13.1478 16.1691 12.8537C16.3097 12.5554 16.3801 12.2337 16.3801 11.8885C16.3801 11.505 16.2927 11.1747 16.118 10.8978C15.9433 10.6165 15.7046 10.4013 15.4021 10.2522C15.0995 10.0987 14.7565 10.022 14.373 10.022C13.9724 10.022 13.623 10.103 13.3247 10.2649C13.0264 10.4269 12.7963 10.6591 12.6343 10.9617C12.4724 11.2642 12.3914 11.6243 12.3914 12.0419H9.76429C9.76429 11.1854 9.95819 10.4418 10.346 9.8111C10.7338 9.18042 11.2771 8.6925 11.9759 8.34733C12.6748 8.00216 13.4802 7.82957 14.3921 7.82957C15.3296 7.82957 16.1457 7.99576 16.8403 8.32815C17.5392 8.65627 18.0825 9.11224 18.4703 9.69605C18.858 10.2799 19.0519 10.9489 19.0519 11.7031C19.0519 12.1975 18.9539 12.6854 18.7579 13.1669C18.5661 13.6485 18.2231 14.1833 17.7288 14.7713C17.2345 15.3551 16.5377 16.0561 15.6386 16.8743L13.7274 18.7472V18.8367H19.2245V21.0995H9.89213Z"
        fill="#2B8FD7"
      />
      <circle cx="14.5" cy="14.0002" r="12" fill="#24A83A" stroke="white" strokeWidth="2" />
      <path
        d="M20.1009 9.0952L13.1852 16.1756C13.0662 16.2976 12.8723 16.2976 12.7532 16.1756L8.89566 12.2238C8.7766 12.1017 8.5827 12.1017 8.46364 12.2238L6.5893 14.1456C6.47023 14.2677 6.47023 14.4665 6.5893 14.5886L12.7532 20.9086C12.8723 21.0307 13.0662 21.0307 13.1852 20.9086L22.4107 11.46C22.5298 11.3379 22.5298 11.1391 22.4107 11.0135L20.5364 9.09171C20.4173 8.96963 20.2234 8.96963 20.1043 9.09171L20.1009 9.0952Z"
        fill="white"
      />
    </svg>
  );
};

export default StepDoneIcon;
