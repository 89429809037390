export const white = '#ffffff';
export const black = '#000000';
export const primaryBG = '#F5F7FA';
export const primaryBGDark = '#041A2A';

const colors = {
  red: {
    900: '#260608',
    800: '#4B0D10',
    700: '#711318',
    600: '#961A20',
    500: '#BC2028',
    400: '#CD585E',
    300: '#DD8F93',
    200: '#EEC7C9',
    100: '#F8E9EA',
  },
  blue: {
    900: '#041A2A',
    800: '#113956',
    700: '#1A5681',
    600: '#2272AC',
    500: '#2B8FD7',
    400: '#60ABE1',
    300: '#95C7EB',
    200: '#CAE3F5',
    100: '#EAF4FB',
  },
  gray: {
    900: '#161616',
    800: '#2C2C2C',
    700: '#424242',
    600: '#585858',
    500: '#808080',
    400: '#999999',
    300: '#C2C2C2',
    200: '#DEDEDE',
    100: '#F2F2F2',
  },
};
export default {
  ...colors,
  white: '#ffffff',
  black: '#000000',

  light: {
    warning: {
      primary: '#DD0B24',
    },
    red: {
      primary: '#BC2028',
      warning: '#DD0B24',
    },
    blue: {
      primary: '#2B8FD7',
      secondary: '#0D5C93',
      tertiary: '#3E79A5',
    },
    gray: {
      primary: '#DEDEDE',
      light: '#595959',
      active: '#C9C9C9',
    },
    green: {
      primary: '#24A83A',
      notification: '#5EBE5E',
    },
    black: {
      primary: '#282828',
      secondary: '#595959',
      tertiary: '#FFFFFF',
    },
    notifications: {
      red: '#FF0000',
      yellow: '#FFAF00',
      gold: '#BF9200',
      green: '#24A83A',
    },
  },
  dark: {
    red: {
      primary: '#BC2028',
      light: '#CD585E',
      disabled: '#DD8F93',
    },
    blue: {
      primary: '#2B8FD7',
      secondary: '#71B5E4',
      tertiary: '#3E79A5',
    },
    gray: {
      primary: '#DEDEDE',
      light: '#595959',
      active: '#C9C9C9',
    },
    green: {
      primary: '#24A83A',
    },
    black: {
      primary: '#CFCFCF',
      secondary: '#AFB0B1',
      tertiary: '#CFCFCF',
    },
    notifications: {
      red: '#FF0000',
      yellow: '#FFAF00',
      gold: '#BF9200',
      green: '#24A83A',
    },
  },
};
