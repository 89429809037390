import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';

import Headline from '../../common/Headline';
import Header from '../../blocks/Header';
import BlankSlate from '../../blocks/BlankSlate';
import Button from '../../../atoms/buttons/Button';
import CreditHistoryItem from '../../../atoms/CreditHistoryItem';

const ListContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  borderRadius: '4px',
});

interface CreditsProps {
  credits: any;
  moreExists: any;
  loadMore: any;
  isGenerous: any;
}

const Credits = ({ credits, moreExists, loadMore, isGenerous }: CreditsProps): any => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const myCredits = credits.credits;
  const isFetching = credits.isFetching;

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(!isFirstRender);
    }
  }, [isFirstRender]);

  const renderCreditHistory = () => {
    // loop through each mail item.
    if (!_.isEmpty(myCredits) && !isFetching) {
      return Object.keys(myCredits).map(key => {
        const theCredit = myCredits[key];
        return (
          <CreditHistoryItem
            key={key}
            createdAt={theCredit.created_at}
            description={theCredit.description}
            creditType={theCredit.type}
            credits={theCredit.credits}
            hideCredits={theCredit.is_idv_purchase}
          />
        );
      });
    } else if (!isFetching && !isFirstRender) {
      return <BlankSlate title={isGenerous ? `No Credit Transactions` : `No Purchase Transactions`} />;
    }
  };

  return (
    <div>
      <Header bottomRuled>
        <div>
          <Headline data-test-id="credit-history-header" hasDescription>
            Purchase History
          </Headline>
          <div>Below are the latest purchase transactions against your account.</div>
        </div>
      </Header>
      <ListContainer>
        {renderCreditHistory()}
        {moreExists ? (
          <Button fit customStyle={{ marginTop: 20 }} onClick={loadMore}>
            Load More
          </Button>
        ) : (
          ''
        )}
      </ListContainer>
    </div>
  );
};
export default Credits;
