import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { dimensions, textType } from '../../../style';
import GalleryUpload from '../../../modules/gallery/GalleryUpload/GalleryUpload';
import PrivateGallery from './Gallery/PrivateGallery';
import PublicGallery from './Gallery/PublicGallery';
import { privatePhoto, otherProfilePhoto } from '../../../actions/profileTrackAction';
import { MAX_NUMBER_PHOTOS, s3BucketDirect } from '../../../config/Master';
import Button from '../../../atoms/buttons/Button';
import RoundedCheckbox from '../../../atoms/RoundedCheckbox';
import PublicEyeIcon from '../../../atoms/icons/PublicEyeIcon';
import PrivateEyeIcon from '../../../atoms/icons/PrivateEyeIcon';
import GalleryBulkActionModal from '../../../modules/modals/GalleryBulkActionModal';
import { isDarkMode } from '../../../utils/helpers';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import { mixpanelTrackLightBoxOpened } from '../../../utils/mixpanel/lightboxOpened';

const PrivateEyeContainer = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  height: 80,
  width: 80,
  zIndex: 1,
  borderTopLeftRadius: 5,
});

const PrivateMonkey = styled('img')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '20px',
  zIndex: 3,
  padding: '8px',
});

const PrivateNotch = styled('div')({
  position: 'absolute',
  zIndex: 2,
  backgroundColor: 'rgb(188, 32, 40, 0.9)',
  transform: 'rotate(45deg)',
  width: 80,
  height: 80,
  top: -40,
  left: -40,
});

const PrivateTotal = styled('div')({
  position: 'absolute',
  top: '0',
  margin: '0 auto',
  fontWeight: 'bold',
  width: '100%',
  textAlign: 'center',
  color: '#3a9be1',

  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #dde3ec',
  borderRadius: '4px',
  boxSizing: 'border-box',
});

const PrivateTotalCount = styled('div')({
  paddingTop: '12px',
  color: '#bc2028',
});

const PrivateTotalTitle = styled('div')({
  paddingTop: '5px',
  fontSize: '12px',
  color: '#bc2028',
});

const Photos = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-.5%',
  position: 'relative',
  [dimensions.SCREEN_MAX_LG]: {
    marginTop: 0,
  },
});

const PhotoContainer = styled('div')({
  margin: '.5%',
  width: '24%',
  position: 'relative',
  ':hover': {
    cursor: 'pointer',
  },
  borderRadius: 5,
  [dimensions.SCREEN_MAX_SM]: {
    margin: '.5%',
    width: '32%',
  },
});

const PhotoAspectRatio = styled('div')({
  paddingTop: '133%',
  position: 'relative',
  backgroundImage: `url(${s3BucketDirect}private_bg.png)`,
  backgroundSize: 'contain',
});

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0,
    visibility: hidden;
  }

  to {
    opacity: 1;
    height: 'initial',
    visibility: vissible;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 'initial',
    visibility: vissible;
  }

  to {
    opacity: 0;
    height: 0,
    visibility: hidden;
  }
`;

const slideKeyframes = keyframes`
  from {
    opacity: 0;
    width: 0;
    padding: 8px 0;
  }

  to {
    opacity: 1;
    width: auto;
    padding: 8px 18px;
  }
`;

const BulkAction = styled('div')(
  {
    flex: '1 1 100%',
    justifyItems: 'start',
    justifyContent: 'start',
    display: 'flex',
    gap: 5,
    margin: '0 0.5%',
    transition: 'all 0.6s linear',
    opacity: 0,
    visibility: 'hidden',
    height: 0,
  },
  ({ visible }) =>
    visible === 'true'
      ? {
          height: 'initial',
          opacity: 1,
          visibility: 'visible',
          animation: `${fadeIn} 0.6s ease-out`,
          marginBottom: 14,
        }
      : {
          height: 0,
          opacity: 0,
          visibility: 'hidden',
          animation: `${fadeOut} 0.6s ease-in`,
          marginBottom: 0,
        }
);
const BulkActionTogglePrivacy = styled(Button)({
  height: 32,
  fontSize: 13,

  transition: 'all 0.6s linear',
  width: 0,
  padding: '8px 0',
  animation: `${slideKeyframes} 0.6s forwards`,
});
const BulkActionDelete = styled(Button)({ height: 32, fontSize: 13, padding: '8px 18px' });
const PrivacyButtonIconStyle = {
  marginRight: 6,
  cursor: 'pointer',
  height: 16,
  width: 16,
};

const GalleryHeader = styled('div')({
  flex: '1 1 100%',
  color: '#333333',
  borderBottom: isDarkMode() ? '1px solid  #3e79a5' : '1px solid  #DAE1E9',
  padding: '8px 0',
  margin: '20px 0.5% 18px 0.5%',
  display: 'flex',
  justifyContent: 'space-between',
  [dimensions.SCREEN_MAX_LG]: {
    marginBottom: 15,
    paddingTop: 0,
    marginTop: 15,
  },
});
const GalleryHeaderText = styled('h2')({ margin: 0, ...textType.header });
const GalleryHeaderActions = styled('div')({});
const ClearSelectedActions = styled('a')({
  fontSize: 12,
  textDecoration: 'underline',
  color: '#2B8FD7',
  cursor: 'pointer',
});

const renderPrivateMonkeyCount = (url, total, openLightbox, index) => {
  return (
    <PrivateTotal
      onClick={e => {
        e.preventDefault();
        privatePhoto(e);
        openLightbox(index, e);
      }}
    >
      <img alt="private" data-test-id="private-img-monkey" src={url} style={{ width: '34%' }} />
      <PrivateTotalCount>+{total}</PrivateTotalCount>
      <PrivateTotalTitle>Private photos/videos</PrivateTotalTitle>
    </PrivateTotal>
  );
};

// convert to functional component
const Gallery = (props: any): any => {
  const { images, openLightbox, isOtherProfile, photos, makeDefault, storyMode = false } = props;

  const [mediaIds, setMediaIds] = useState({ photo: { private: [], public: [] }, video: { private: [], public: [] } });
  const [showBulkAction, setShowBulkAction] = useState(false);
  const [showBulkActionPublic, setShowBulkActionPublic] = useState(false);
  const [showBulkActionPrivate, setShowBulkActionPrivate] = useState(false);
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState(false);
  const [bulkAction, setIsBulkAction] = useState(null);
  const [bulkActionData, setIsBulkActionData] = useState({});
  const checkBoxRef = useRef([]);

  useDisableBodyScroll(isBulkActionModalOpen);

  const setAsProfile = (photoId, theKey, setOpenGalleryMenuDropdown, prevOpen) => {
    setOpenGalleryMenuDropdown(!prevOpen);
    makeDefault(photoId, theKey);
  };

  const photoListIndex = index => {
    return photos && Object.keys(photos.data).length > 0 ? Object.keys(photos.data)[index] : undefined;
  };

  const renderPrivateMedia = (obj, index) => {
    return isOtherProfile ? (
      renderPrivateMonkeyCount(obj.src, obj.total, openLightbox, index)
    ) : (
      <PrivateGallery
        key={photoListIndex(index)}
        url={obj.type === 'video' ? obj.urls.thumbnail : obj.urls.profile}
        status={obj.status}
        isProfile={index === 0}
        openLightbox={e => {
          openLightbox(e);
          otherProfilePhoto(e);
        }}
        index={index}
        photoKey={photoListIndex(index)}
        setPrivacy={props.setPrivacy}
        makeDefault={setAsProfile}
        deletePhoto={props.deletePhoto}
        hasPublicPhotos={props.hasPublicPhotos}
        isPrivate={obj.private}
        singlePublicLeft={props.singlePublicLeft}
        photoId={obj.id}
        isAvatar={obj.is_avatar}
        hasPublicApprovedPhotos={props.hasPublicApprovedPhotos}
        mediaType={obj.type}
        setMediaPrivacy={props.setMediaPrivacy}
        deleteMedia={props.deleteMedia}
        hasBulkSelection={showBulkAction}
      />
    );
  };

  const publicPhotoUrl = (obj, isOtherProfile) => {
    if (obj.type === 'video') {
      return isOtherProfile ? obj.thumbnail : obj.urls.thumbnail;
    }

    if (isOtherProfile) {
      return obj.src;
    }

    return (
      obj.urls['820px'] ||
      obj.urls['720px'] ||
      obj.urls['410px'] ||
      obj.urls['resized'] ||
      obj.urls['profile'] ||
      obj.urls[Object.keys(obj.urls)[0]]
    );
  };

  const publicPhotoStatus = (obj, isOtherProfile) => {
    return isOtherProfile ? 'approved' : obj.status;
  };

  const shouldDisplayPrivate = (obj, isOtherProfile, thumbnail) => {
    if (!isOtherProfile && obj.type === 'video' && obj.status === 'pending') {
      return obj.private;
    }

    return !isOtherProfile && obj.private ? obj.private : thumbnail !== 'private_eye.png' && obj.privateReal;
  };

  const onMediaCheck = (id, type, isMediaPrivate = false) => {
    const privacy = isMediaPrivate ? 'private' : 'public';

    if (!mediaIds[type][privacy].includes(id)) {
      mediaIds[type][privacy].push(id);
    } else {
      mediaIds[type][privacy] = mediaIds[type][privacy].filter(function(e) {
        return e != id;
      });
    }

    setMediaIds(mediaIds);
    setShowBulkAction(
      mediaIds['photo']['public'].length > 0 ||
        mediaIds['photo']['private'].length > 0 ||
        mediaIds['video']['public'].length > 0 ||
        mediaIds['video']['private'].length > 0
    );
    setShowBulkActionPublic(mediaIds['photo']['public'].length > 0 || mediaIds['video']['public'].length > 0);
    setShowBulkActionPrivate(mediaIds['photo']['private'].length > 0 || mediaIds['video']['private'].length > 0);
  };

  const onBulkAction = (open, action, data) => {
    setIsBulkActionModalOpen(open);
    setIsBulkAction(action);
    setIsBulkActionData(data);
  };

  const resetSelectedMedia = () => {
    setMediaIds({ photo: { private: [], public: [] }, video: { private: [], public: [] } });
    for (let i = 0; i < checkBoxRef.current.length; i++) {
      if (checkBoxRef.current[i] && checkBoxRef.current[i].checked) {
        checkBoxRef.current[i].checked = false;
        checkBoxRef.current[i].value = false;
      }
    }

    setShowBulkAction(false);
    setShowBulkActionPrivate(false);
    setShowBulkActionPublic(false);
  };

  const renderGallery = () => {
    const photosData = isOtherProfile ? images : (photos && photos.data) || [];
    const { profile } = props;
    const gallery = photosData.map((obj, index) => {
      const thumbnail = obj.src && obj.src.split('/').pop();
      const privateMonkey = shouldDisplayPrivate(obj, isOtherProfile, thumbnail);

      return (
        <PhotoContainer key={index} data-test-id={`${index}-container-media`}>
          {!isOtherProfile && !obj.is_avatar && (
            <RoundedCheckbox
              ref={element => {
                checkBoxRef.current[index] = element;
              }}
              data={obj}
              containerStyle={{ zIndex: 2 }}
              customStyle={{ left: 'initial', top: 10, right: 10 }}
              mediaIds={mediaIds}
              onChange={() => onMediaCheck(obj.id, obj.type, obj.private)}
            />
          )}
          {privateMonkey && (
            <PrivateEyeContainer>
              <PrivateNotch />
              <PrivateMonkey src={`${s3BucketDirect}thumbs/private_eye_white.png`} />
            </PrivateEyeContainer>
          )}
          {obj.private ? (
            <PhotoAspectRatio
              id={`${obj.type && obj.type === 'video' ? 'video' : 'photo'}${index}`}
              data-test-id={`private-${obj.type && obj.type === 'video' ? 'video' : 'photo'}-container`}
              isPrivate
            >
              {renderPrivateMedia(obj, index)}
            </PhotoAspectRatio>
          ) : (
            <PublicGallery
              key={photoListIndex(index)}
              url={publicPhotoUrl(obj, isOtherProfile)}
              status={publicPhotoStatus(obj, isOtherProfile)}
              isProfile={index === 0 && obj.type !== 'video'}
              isOtherProfile={isOtherProfile}
              openLightbox={e => {
                openLightbox(e);
                otherProfilePhoto(e);
                if (profile?.hash_id && obj.type === 'photo') {
                  if (isOtherProfile) {
                    mixpanelTrackLightBoxOpened('Thumbnail Gallery');
                  }
                }
              }}
              index={index}
              photoKey={photoListIndex(index)}
              setPrivacy={props.setPrivacy}
              makeDefault={setAsProfile}
              deletePhoto={props.deletePhoto}
              hasPublicPhotos={props.hasPublicPhotos}
              isPrivate={obj.private}
              singlePublicLeft={props.singlePublicLeft}
              photoId={obj.id}
              isAvatar={obj.is_avatar}
              hasPublicApprovedPhotos={props.hasPublicApprovedPhotos}
              mediaType={obj.type}
              setMediaPrivacy={props.setMediaPrivacy}
              deleteMedia={props.deleteMedia}
              isPrivateReal={obj.privateReal}
              hasBulkSelection={showBulkAction}
            />
          )}
        </PhotoContainer>
      );
    });

    return (
      <Photos>
        <GalleryHeader>
          {isOtherProfile ? (
            <GalleryHeaderText>Gallery</GalleryHeaderText>
          ) : (
            <GalleryHeaderText>
              Gallery ({photos.data.length}/{MAX_NUMBER_PHOTOS})
            </GalleryHeaderText>
          )}
          <GalleryHeaderActions>
            {showBulkAction && (
              <ClearSelectedActions onClick={() => resetSelectedMedia()}>Deselect All</ClearSelectedActions>
            )}
          </GalleryHeaderActions>
        </GalleryHeader>

        <BulkAction visible={showBulkAction.toString()}>
          {showBulkActionPrivate && (
            <BulkActionTogglePrivacy
              visible={showBulkActionPrivate.toString()}
              size="small"
              buttonType="primary"
              type="button"
              onClick={() => onBulkAction(true, 'public', mediaIds)}
            >
              <PublicEyeIcon customStyle={PrivacyButtonIconStyle} /> Make Public
            </BulkActionTogglePrivacy>
          )}{' '}
          {showBulkActionPublic && (
            <BulkActionTogglePrivacy
              visible={showBulkActionPublic.toString()}
              size="small"
              buttonType="primary"
              type="button"
              onClick={() => onBulkAction(true, 'private', mediaIds)}
            >
              <PrivateEyeIcon customStyle={PrivacyButtonIconStyle} /> Make Private
            </BulkActionTogglePrivacy>
          )}{' '}
          {(showBulkActionPublic || showBulkActionPrivate) && (
            <BulkActionDelete
              size="small"
              buttonType="cancel"
              type="button"
              onClick={() => onBulkAction(true, 'delete', mediaIds)}
            >
              Delete
            </BulkActionDelete>
          )}
        </BulkAction>
        {gallery}
        {!storyMode && !isOtherProfile && (
          <PhotoContainer>
            <GalleryUpload
              hasPublicPhotos={props.hasPublicPhotos}
              refreshAndClearErrors={props.refreshAndClearErrors}
              uploadPhoto={props.uploadPhoto}
              uploadMedia={props.uploadMedia}
              isUploading={props.isUploading}
              photos={photos}
              displayMessage={props.displayMessage}
              maxUpload={photos.data.length}
              clearBulkSelection={resetSelectedMedia}
            />
          </PhotoContainer>
        )}

        {isBulkActionModalOpen && (
          <GalleryBulkActionModal
            isOpen={true}
            bulkAction={bulkAction}
            bulkActionData={bulkActionData}
            contentLabel="Bulk Action Modal"
            cancelText="Cancel"
            confirmText="Unlock"
            header="Unlock Offer"
            description={`Do you want to unlock this date for credits?`}
            onClose={() => setIsBulkActionModalOpen(false)}
            onConfirm={() => {
              setIsBulkActionModalOpen(false);

              if (bulkAction === 'delete') {
                props.bulkDeleteMedia(bulkActionData);
              } else {
                props.bulkTogglePrivacy(bulkActionData, bulkAction);
              }

              resetSelectedMedia();
            }}
          />
        )}
      </Photos>
    );
  };

  return renderGallery();
};

Gallery.propTypes = {
  images: PropTypes.array,
  openLightbox: PropTypes.func.isRequired,
};

export default Gallery;
