import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { isDarkMode } from '../../../utils/helpers';
import Panel from '../../../atoms/panels/Panel';
import OpeningOfferModal from '../../common/modals/OpeningOfferModal';

import typography from '../../../theme/typography';
import { useDisableBodyElementScroll } from '../../../hooks/useDisableBodyScroll';
import EditableOpeningOfferModal from '../../common/modals/EditableOpeningOfferModal';
import useEvidently from '../../../hooks/useEvidently';
import {
  AMSetDefaultOfferVariations,
  AM_SET_DEFAULT_OFFER_FEATURE_NAME,
} from '../../../utils/evidentlyFeatures/AMSetDefaultOffer';
import { connect } from 'react-redux';
import { Account, AccountMaker } from '../../../models/Account';
import { ProfileMaker } from '../../../models/Profile';
import { useSearchParams } from 'react-router-dom';
import { getDefaultOfferAmount } from '../../../utils/offers';

const OpeningOfferWrapper = styled('div')({});

const OpeningAmountLabel = styled('span')({
  fontSize: 26,
  fontWeight: 700,
  lineHeight: '21px',
  letterSpacing: '-0.52px',
  color: isDarkMode() ? typography['dark'].primaryText : '#3E79A5',
});

interface OpeningOfferProps {
  account: Account;
  updateProfileOpeningOffer: any;
  settings: any;
}

const OpeningOffer = ({ account, updateProfileOpeningOffer }: OpeningOfferProps): any => {
  const profileModel = ProfileMaker.create(account?.data?.profile);
  const defaultOfferPrice = profileModel?.profile.data.default_offer_amount ?? getDefaultOfferAmount();

  const [searchParams] = useSearchParams();

  const [toggleEditOpeningOffer, setToggleEditOpeningOffer] = useState(false);
  const [showOpeningOffer, setShowOpenningOffer] = useState(false);
  const [offerPrice, setOfferPrice] = useState(defaultOfferPrice);

  useDisableBodyElementScroll(showOpeningOffer || toggleEditOpeningOffer);

  const { useActiveVariation } = useEvidently();
  const AMDefaultOfferVariation = useActiveVariation(AM_SET_DEFAULT_OFFER_FEATURE_NAME) as AMSetDefaultOfferVariations;

  useEffect(() => {
    const action = searchParams.get('action');
    if (action && action === 'editOpeningOffer' && toggleEditOpeningOffer === false) {
      setToggleEditOpeningOffer(true);
    }
  }, []);

  const currencySymbols = useMemo(() => {
    if (!account?.data.currency) {
      return null;
    }

    return {
      currencyInitial: account.data.currency.data.initial,
      currencyLabel: String.fromCharCode(Number(account.data.currency.data.label_unicode)),
    };
  }, [account?.data?.currency]);

  const toggleEditable = e => {
    e.preventDefault();

    // remove action query param from url
    if (searchParams.has('action')) {
      const url = new URL(window.location.href);
      url.searchParams.delete('action');
      history.replaceState({ hashId: profileModel.hashId() }, '', url.toString());
    }

    setToggleEditOpeningOffer(prev => !prev);
  };

  const handleSubmit = e => {
    toggleEditable(e);
    updateProfileOpeningOffer({
      default_offer_amount: offerPrice,
    });
  };

  const handleCancel = e => {
    toggleEditable(e);
    setOfferPrice(defaultOfferPrice);
  };

  if (AMDefaultOfferVariation === 'Control' || !currencySymbols) {
    return null;
  }

  return (
    <OpeningOfferWrapper>
      <Panel
        editable
        withHeader
        title="My Opening Offer"
        className="profile__opening-offer"
        toggleEditable={toggleEditable}
        panelStyle={isDarkMode() ? 'borderless' : 'white'}
        style={{ marginBottom: 15, padding: '0 15px 15px' }}
        customComponent={
          <OpeningOfferModal
            iconOnly
            linkStyle={{ marginLeft: 4 }}
            showOpeningOffer={showOpeningOffer}
            setShowOpenningOffer={setShowOpenningOffer}
            openEditOffer={() => {
              setToggleEditOpeningOffer(true);
              setShowOpenningOffer(false);
            }}
            currencySymbols={currencySymbols}
          />
        }
        customPanelHeaderStyle={{ justifyContent: 'flex-start' }}
      >
        {currencySymbols.currencyLabel && (
          <OpeningAmountLabel>{`${currencySymbols.currencyInitial}${currencySymbols.currencyLabel}${defaultOfferPrice}`}</OpeningAmountLabel>
        )}
      </Panel>

      <EditableOpeningOfferModal
        title="My Opening Offer"
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        currencySymbols={currencySymbols}
        offerPrice={offerPrice}
        setOfferPrice={setOfferPrice}
        toggleEditOpeningOffer={toggleEditOpeningOffer}
      />
    </OpeningOfferWrapper>
  );
};

const mapStateToProps = state => {
  return {
    account: AccountMaker.create(state.profile),
    settings: state.settings,
  };
};

export default connect(mapStateToProps, null)(OpeningOffer);
