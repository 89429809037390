import { DEFAULT_REASON, EvidentlyFeatureExperiment } from '../../constants/evidently';
import { Profile } from '../../models/Profile';
import { TrackEvidentlyMetricsParams } from '../../sdk/CommonSDK';
import { mixpanelTrackExperimentStarted } from '../mixpanel/experimentStarted';

export const AM_SET_DEFAULT_OFFER_FEATURE_NAME = 'AMSetDefaultOffers';
export const AM_SET_DEFAULT_OFFER_EXPERIMENT_NAME = 'AMSetDefaultOffers';
export const AM_SET_DEFAULT_OFFER_LOCAL_STORAGE_KEY = 'evidently_am_set_default_offers';

export type AMSetDefaultOfferVariations = 'Control' | 'AMSetDefaultOffer';

/**
 * This is only used in batchEvaluation
 *
 * @param profile Profile
 * @returns boolean
 */
const handleIsFeatureEvaluate = (profile: Profile): boolean => {
  return profile.isAttractive();
};

const handleFeatureCallback = async (
  details: EvidentlyFeatureExperiment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAccountIsMixpanelEligableEnabled?: boolean
): Promise<void> => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return;
  }

  // Not using the isAccountIsMixpanelEligableEnabled state property from useApp() hook
  // the same as other feature helpers like src/utils/evidentlyFeatures/minOfferIncrease.ts
  // because when this is triggered from Onboarding page
  // for some reason, the value is not updated yet. It's always false

  mixpanelTrackExperimentStarted({
    'Experiment name': AM_SET_DEFAULT_OFFER_EXPERIMENT_NAME,
    'Variant name': details.variation ?? 'Control',
  });
};

const featureMetrics = (details: EvidentlyFeatureExperiment): TrackEvidentlyMetricsParams<string>[] => {
  if (details.reason === DEFAULT_REASON && !details.userId) {
    return [];
  }

  return [
    {
      featureName: AM_SET_DEFAULT_OFFER_FEATURE_NAME,
      experimentName: AM_SET_DEFAULT_OFFER_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'defaultOffersSent',
      value: 0,
    },
    {
      featureName: AM_SET_DEFAULT_OFFER_FEATURE_NAME,
      experimentName: AM_SET_DEFAULT_OFFER_EXPERIMENT_NAME,
      hashId: details.userId,
      key: 'defaultOffersSent',
      value: 0,
    },
  ];
};
export const AMSetDefaultOfferEvaluation = [
  {
    featureMetrics,
    featureName: AM_SET_DEFAULT_OFFER_FEATURE_NAME,
    featureCallback: handleFeatureCallback,
    featureHandleEvaluate: handleIsFeatureEvaluate,
  },
];
