import React, { ReactElement } from 'react';

const GDPRLightIcon = (): ReactElement => {
  return (
    <svg width="86" height="37" viewBox="0 0 86 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_529_546)">
        <g clipPath="url(#clip0_529_546)">
          <path
            d="M3 9.00001C3 4.58173 6.58172 1 11 1H32.8246V31.1887H11C6.58172 31.1887 3 27.607 3 23.1887V9.00001Z"
            fill="#8F8F8F"
          />
          <path
            d="M32.8245 1H74.9999C79.4182 1 82.9999 4.58172 82.9999 9V23.1887C82.9999 27.607 79.4182 31.1887 74.9999 31.1887H32.8245V1Z"
            fill="white"
          />
          <path
            d="M18.0451 7.14506L17.2656 7.61684C17.2308 7.63841 17.1959 7.64919 17.1584 7.6465C17.1209 7.6438 17.0888 7.63032 17.0593 7.60875C17.0299 7.58719 17.0084 7.56023 16.9924 7.52518C16.9763 7.49283 16.9736 7.45509 16.9816 7.41465L17.1879 6.52231L16.4968 5.92112C16.4647 5.89147 16.4459 5.86181 16.4379 5.82407C16.4298 5.78902 16.4325 5.75128 16.4459 5.71893C16.4593 5.68388 16.4781 5.65692 16.5022 5.63266C16.5263 5.61109 16.5611 5.59761 16.6066 5.58953L17.5174 5.50865L17.8683 4.66753C17.8844 4.62979 17.9085 4.60013 17.9406 4.58126C17.9728 4.56239 18.0076 4.55161 18.0424 4.55161C18.0772 4.55161 18.1094 4.56239 18.1442 4.58126C18.1763 4.60013 18.2005 4.62979 18.2165 4.66753L18.5674 5.50865L19.4782 5.58953C19.5211 5.59492 19.5559 5.61109 19.5827 5.63266C19.6068 5.65423 19.6255 5.68388 19.6389 5.71893C19.6523 5.75398 19.655 5.78902 19.647 5.82407C19.6389 5.85912 19.6202 5.89147 19.588 5.92112L18.8969 6.52231L19.1032 7.41465C19.1139 7.45509 19.1085 7.49283 19.0925 7.52518C19.0764 7.55753 19.055 7.58449 19.0255 7.60875C18.996 7.63032 18.9639 7.6438 18.9264 7.6465C18.8889 7.64919 18.8541 7.6411 18.8192 7.61684L18.0397 7.14506H18.0451Z"
            fill="#FFD477"
          />
          <path
            d="M23.0087 8.49305L22.2292 8.96483C22.1944 8.9864 22.1596 8.99718 22.1221 8.99449C22.0846 8.99179 22.0524 8.97831 22.023 8.95674C21.9935 8.93518 21.9721 8.90822 21.956 8.87317C21.9399 8.84082 21.9372 8.80308 21.9453 8.76264L22.1515 7.8703L21.4604 7.26911C21.4283 7.23946 21.4095 7.2098 21.4015 7.17206C21.3935 7.13701 21.3961 7.09927 21.4095 7.06692C21.4229 7.03187 21.4417 7.00491 21.4658 6.98065C21.4899 6.95908 21.5247 6.94561 21.5703 6.93752L22.481 6.85664L22.8319 6.01552C22.848 5.97778 22.8721 5.94812 22.9042 5.92925C22.9364 5.91038 22.9712 5.8996 23.006 5.8996C23.0409 5.8996 23.073 5.91038 23.1078 5.92925C23.14 5.94812 23.1641 5.97778 23.1802 6.01552L23.5311 6.85664L24.4418 6.93752C24.4847 6.94291 24.5195 6.95908 24.5463 6.98065C24.5704 7.00222 24.5891 7.03187 24.6025 7.06692C24.6159 7.10197 24.6186 7.13701 24.6106 7.17206C24.6025 7.20711 24.5838 7.23946 24.5516 7.26911L23.8605 7.8703L24.0668 8.76264C24.0775 8.80308 24.0722 8.84082 24.0561 8.87317C24.04 8.90552 24.0186 8.93248 23.9891 8.95674C23.9597 8.97831 23.9275 8.99179 23.89 8.99449C23.8525 8.99718 23.8177 8.9891 23.7829 8.96483L23.0034 8.49305H23.0087Z"
            fill="#FFD477"
          />
          <path
            d="M13.0842 25.7899L12.3047 26.2617C12.2698 26.2833 12.235 26.2941 12.1975 26.2914C12.16 26.2887 12.1279 26.2752 12.0984 26.2536C12.0689 26.2321 12.0475 26.2051 12.0314 26.17C12.0154 26.1377 12.0127 26.1 12.0207 26.0595L12.227 25.1672L11.5359 24.566C11.5037 24.5363 11.485 24.5067 11.4769 24.4689C11.4689 24.4339 11.4716 24.3961 11.485 24.3638C11.4984 24.3287 11.5171 24.3018 11.5412 24.2775C11.5653 24.256 11.6002 24.2425 11.6457 24.2344L12.5564 24.1535L12.9074 23.3124C12.9234 23.2747 12.9475 23.245 12.9797 23.2261C13.0118 23.2073 13.0467 23.1965 13.0815 23.1965C13.1163 23.1965 13.1484 23.2073 13.1833 23.2261C13.2154 23.245 13.2395 23.2747 13.2556 23.3124L13.6065 24.1535L14.5173 24.2344C14.5601 24.2398 14.5949 24.256 14.6217 24.2775C14.6458 24.2991 14.6646 24.3287 14.678 24.3638C14.6914 24.3988 14.6941 24.4339 14.686 24.4689C14.678 24.504 14.6592 24.5363 14.6271 24.566L13.936 25.1672L14.1422 26.0595C14.153 26.1 14.1476 26.1377 14.1315 26.17C14.1155 26.2024 14.094 26.2294 14.0646 26.2536C14.0351 26.2752 14.0029 26.2887 13.9654 26.2914C13.9279 26.2941 13.8931 26.286 13.8583 26.2617L13.0788 25.7899H13.0842Z"
            fill="#FFD477"
          />
          <path
            d="M23.0087 25.7899L22.2292 26.2617C22.1944 26.2833 22.1596 26.2941 22.1221 26.2914C22.0846 26.2887 22.0524 26.2752 22.023 26.2536C21.9935 26.2321 21.9721 26.2051 21.956 26.17C21.9399 26.1377 21.9372 26.1 21.9453 26.0595L22.1515 25.1672L21.4604 24.566C21.4283 24.5363 21.4095 24.5067 21.4015 24.4689C21.3935 24.4339 21.3961 24.3961 21.4095 24.3638C21.4229 24.3287 21.4417 24.3018 21.4658 24.2775C21.4899 24.256 21.5247 24.2425 21.5703 24.2344L22.481 24.1535L22.8319 23.3124C22.848 23.2747 22.8721 23.245 22.9042 23.2261C22.9364 23.2073 22.9712 23.1965 23.006 23.1965C23.0409 23.1965 23.073 23.2073 23.1078 23.2261C23.14 23.245 23.1641 23.2747 23.1802 23.3124L23.5311 24.1535L24.4418 24.2344C24.4847 24.2398 24.5195 24.256 24.5463 24.2775C24.5704 24.2991 24.5891 24.3287 24.6025 24.3638C24.6159 24.3988 24.6186 24.4339 24.6106 24.4689C24.6025 24.504 24.5838 24.5363 24.5516 24.566L23.8605 25.1672L24.0668 26.0595C24.0775 26.1 24.0722 26.1377 24.0561 26.17C24.04 26.2024 24.0186 26.2294 23.9891 26.2536C23.9597 26.2752 23.9275 26.2887 23.89 26.2914C23.8525 26.2941 23.8177 26.286 23.7829 26.2617L23.0034 25.7899H23.0087Z"
            fill="#FFD477"
          />
          <path
            d="M27.9777 17.1415L27.1982 17.6133C27.1634 17.6348 27.1286 17.6456 27.0911 17.6429C27.0536 17.6402 27.0214 17.6267 26.9919 17.6052C26.9625 17.5836 26.9411 17.5567 26.925 17.5216C26.9089 17.4893 26.9062 17.4515 26.9143 17.4111L27.1205 16.5187L26.4294 15.9176C26.3973 15.8879 26.3785 15.8582 26.3705 15.8205C26.3625 15.7855 26.3651 15.7477 26.3785 15.7154C26.3919 15.6803 26.4107 15.6534 26.4348 15.6291C26.4589 15.6075 26.4937 15.594 26.5392 15.586L27.45 15.5051L27.8009 14.664C27.817 14.6262 27.8411 14.5966 27.8732 14.5777C27.9054 14.5588 27.9402 14.548 27.975 14.548C28.0099 14.548 28.042 14.5588 28.0768 14.5777C28.109 14.5966 28.1331 14.6262 28.1491 14.664L28.5001 15.5051L29.4108 15.586C29.4537 15.5913 29.4885 15.6075 29.5153 15.6291C29.5394 15.6507 29.5581 15.6803 29.5715 15.7154C29.5849 15.7504 29.5876 15.7855 29.5796 15.8205C29.5715 15.8555 29.5528 15.8879 29.5206 15.9176L28.8295 16.5187L29.0358 17.4111C29.0465 17.4515 29.0412 17.4893 29.0251 17.5216C29.009 17.554 28.9876 17.5809 28.9581 17.6052C28.9286 17.6267 28.8965 17.6402 28.859 17.6429C28.8215 17.6456 28.7867 17.6375 28.7519 17.6133L27.9723 17.1415H27.9777Z"
            fill="#FFD477"
          />
          <path
            d="M8.11517 17.1415L7.33567 17.6133C7.30085 17.6348 7.26602 17.6456 7.22852 17.6429C7.19102 17.6402 7.15888 17.6267 7.12941 17.6052C7.09994 17.5836 7.07852 17.5567 7.06244 17.5216C7.04637 17.4893 7.04369 17.4515 7.05173 17.4111L7.25799 16.5187L6.57224 15.9176C6.5401 15.8879 6.52135 15.8582 6.51331 15.8205C6.50527 15.7855 6.50795 15.7477 6.52135 15.7154C6.53474 15.6803 6.55349 15.6534 6.5776 15.6291C6.60171 15.6075 6.63653 15.594 6.68207 15.586L7.59283 15.5051L7.94374 14.664C7.95981 14.6262 7.98392 14.5966 8.01606 14.5777C8.04821 14.5588 8.08303 14.548 8.11785 14.548C8.15267 14.548 8.18482 14.5588 8.21964 14.5777C8.25179 14.5966 8.27589 14.6262 8.29197 14.664L8.64288 15.5051L9.55364 15.586C9.59649 15.5913 9.63132 15.6075 9.6581 15.6291C9.68221 15.6507 9.70096 15.6803 9.71436 15.7154C9.72775 15.7504 9.73043 15.7855 9.72239 15.8205C9.71436 15.8555 9.69561 15.8879 9.66346 15.9176L8.97236 16.5187L9.17862 17.4111C9.18933 17.4515 9.18397 17.4893 9.1679 17.5216C9.15183 17.554 9.1304 17.5809 9.10093 17.6052C9.07147 17.6267 9.03932 17.6402 9.00182 17.6429C8.96432 17.6456 8.9295 17.6375 8.89467 17.6133L8.11517 17.1415Z"
            fill="#FFD477"
          />
          <path
            d="M9.46794 12.1541L8.68844 12.6258C8.65362 12.6474 8.61879 12.6582 8.58129 12.6555C8.54379 12.6528 8.51165 12.6393 8.48218 12.6178C8.45271 12.5962 8.43128 12.5692 8.41521 12.5342C8.39914 12.5018 8.39646 12.4641 8.4045 12.4237L8.61076 11.5313L7.91965 10.9301C7.88751 10.9005 7.86876 10.8708 7.86072 10.8331C7.85269 10.798 7.85536 10.7603 7.86876 10.7279C7.88215 10.6929 7.9009 10.6659 7.92501 10.6417C7.94912 10.6201 7.98394 10.6066 8.02948 10.5985L8.94024 10.5177L9.29115 9.67653C9.30722 9.63879 9.33133 9.60913 9.36347 9.59026C9.39562 9.57139 9.43044 9.56061 9.46526 9.56061C9.50009 9.56061 9.53223 9.57139 9.56705 9.59026C9.5992 9.60913 9.62331 9.63879 9.63938 9.67653L9.99029 10.5177L10.901 10.5985C10.9439 10.6039 10.9787 10.6201 11.0055 10.6417C11.0296 10.6632 11.0484 10.6929 11.0618 10.7279C11.0752 10.763 11.0778 10.798 11.0698 10.8331C11.0618 10.8681 11.043 10.9005 11.0109 10.9301L10.3198 11.5313L10.526 12.4237C10.5367 12.4641 10.5314 12.5018 10.5153 12.5342C10.4992 12.5665 10.4778 12.5935 10.4483 12.6178C10.4189 12.6393 10.3867 12.6528 10.3492 12.6555C10.3117 12.6582 10.2769 12.6501 10.2421 12.6258L9.46258 12.1541H9.46794Z"
            fill="#FFD477"
          />
          <path
            d="M26.6249 12.1541L25.8454 12.6258C25.8106 12.6474 25.7758 12.6582 25.7383 12.6555C25.7008 12.6528 25.6686 12.6393 25.6392 12.6178C25.6097 12.5962 25.5883 12.5692 25.5722 12.5342C25.5561 12.5018 25.5534 12.4641 25.5615 12.4237L25.7677 11.5313L25.0766 10.9301C25.0445 10.9005 25.0257 10.8708 25.0177 10.8331C25.0097 10.798 25.0123 10.7603 25.0257 10.7279C25.0391 10.6929 25.0579 10.6659 25.082 10.6417C25.1061 10.6201 25.1409 10.6066 25.1865 10.5985L26.0972 10.5177L26.4481 9.67653C26.4642 9.63879 26.4883 9.60913 26.5205 9.59026C26.5526 9.57139 26.5874 9.56061 26.6222 9.56061C26.6571 9.56061 26.6892 9.57139 26.724 9.59026C26.7562 9.60913 26.7803 9.63879 26.7964 9.67653L27.1473 10.5177L28.058 10.5985C28.1009 10.6039 28.1357 10.6201 28.1625 10.6417C28.1866 10.6632 28.2054 10.6929 28.2188 10.7279C28.2321 10.763 28.2348 10.798 28.2268 10.8331C28.2188 10.8681 28.2 10.9005 28.1679 10.9301L27.4768 11.5313L27.683 12.4237C27.6937 12.4641 27.6884 12.5018 27.6723 12.5342C27.6562 12.5665 27.6348 12.5935 27.6053 12.6178C27.5759 12.6393 27.5437 12.6528 27.5062 12.6555C27.4687 12.6582 27.4339 12.6501 27.3991 12.6258L26.6196 12.1541H26.6249Z"
            fill="#FFD477"
          />
          <path
            d="M26.6249 22.1262L25.8454 22.598C25.8106 22.6195 25.7758 22.6303 25.7383 22.6276C25.7008 22.6249 25.6686 22.6115 25.6392 22.5899C25.6097 22.5683 25.5883 22.5414 25.5722 22.5063C25.5561 22.474 25.5534 22.4362 25.5615 22.3958L25.7677 21.5034L25.0766 20.9023C25.0445 20.8726 25.0257 20.843 25.0177 20.8052C25.0097 20.7702 25.0123 20.7324 25.0257 20.7001C25.0391 20.665 25.0579 20.6381 25.082 20.6138C25.1061 20.5922 25.1409 20.5788 25.1865 20.5707L26.0972 20.4898L26.4481 19.6487C26.4642 19.6109 26.4883 19.5813 26.5205 19.5624C26.5526 19.5435 26.5874 19.5327 26.6222 19.5327C26.6571 19.5327 26.6892 19.5435 26.724 19.5624C26.7562 19.5813 26.7803 19.6109 26.7964 19.6487L27.1473 20.4898L28.058 20.5707C28.1009 20.5761 28.1357 20.5922 28.1625 20.6138C28.1866 20.6354 28.2054 20.665 28.2188 20.7001C28.2321 20.7351 28.2348 20.7702 28.2268 20.8052C28.2188 20.8403 28.2 20.8726 28.1679 20.9023L27.4768 21.5034L27.683 22.3958C27.6937 22.4362 27.6884 22.474 27.6723 22.5063C27.6562 22.5387 27.6348 22.5656 27.6053 22.5899C27.5759 22.6115 27.5437 22.6249 27.5062 22.6276C27.4687 22.6303 27.4339 22.6222 27.3991 22.598L26.6196 22.1262H26.6249Z"
            fill="#FFD477"
          />
          <path
            d="M9.46794 22.1262L8.68844 22.598C8.65362 22.6195 8.61879 22.6303 8.58129 22.6276C8.54379 22.6249 8.51165 22.6115 8.48218 22.5899C8.45271 22.5683 8.43128 22.5414 8.41521 22.5063C8.39914 22.474 8.39646 22.4362 8.4045 22.3958L8.61076 21.5034L7.91965 20.9023C7.88751 20.8726 7.86876 20.843 7.86072 20.8052C7.85269 20.7702 7.85536 20.7324 7.86876 20.7001C7.88215 20.665 7.9009 20.6381 7.92501 20.6138C7.94912 20.5922 7.98394 20.5788 8.02948 20.5707L8.94024 20.4898L9.29115 19.6487C9.30722 19.6109 9.33133 19.5813 9.36347 19.5624C9.39562 19.5435 9.43044 19.5327 9.46526 19.5327C9.50009 19.5327 9.53223 19.5435 9.56705 19.5624C9.5992 19.5813 9.62331 19.6109 9.63938 19.6487L9.99029 20.4898L10.901 20.5707C10.9439 20.5761 10.9787 20.5922 11.0055 20.6138C11.0296 20.6354 11.0484 20.665 11.0618 20.7001C11.0752 20.7351 11.0778 20.7702 11.0698 20.8052C11.0618 20.8403 11.043 20.8726 11.0109 20.9023L10.3198 21.5034L10.526 22.3958C10.5367 22.4362 10.5314 22.474 10.5153 22.5063C10.4992 22.5387 10.4778 22.5656 10.4483 22.5899C10.4189 22.6115 10.3867 22.6249 10.3492 22.6276C10.3117 22.6303 10.2769 22.6222 10.2421 22.598L9.46258 22.1262H9.46794Z"
            fill="#FFD477"
          />
          <path
            d="M13.0842 8.49305L12.3047 8.96483C12.2698 8.9864 12.235 8.99718 12.1975 8.99449C12.16 8.99179 12.1279 8.97831 12.0984 8.95674C12.0689 8.93518 12.0475 8.90822 12.0314 8.87317C12.0154 8.84082 12.0127 8.80308 12.0207 8.76264L12.227 7.8703L11.5359 7.26911C11.5037 7.23946 11.485 7.2098 11.4769 7.17206C11.4689 7.13701 11.4716 7.09927 11.485 7.06692C11.4984 7.03187 11.5171 7.00491 11.5412 6.98065C11.5653 6.95908 11.6002 6.94561 11.6457 6.93752L12.5564 6.85664L12.9074 6.01552C12.9234 5.97778 12.9475 5.94812 12.9797 5.92925C13.0118 5.91038 13.0467 5.8996 13.0815 5.8996C13.1163 5.8996 13.1484 5.91038 13.1833 5.92925C13.2154 5.94812 13.2395 5.97778 13.2556 6.01552L13.6065 6.85664L14.5173 6.93752C14.5601 6.94291 14.5949 6.95908 14.6217 6.98065C14.6458 7.00222 14.6646 7.03187 14.678 7.06692C14.6914 7.10197 14.6941 7.13701 14.686 7.17206C14.678 7.20711 14.6592 7.23946 14.6271 7.26911L13.936 7.8703L14.1422 8.76264C14.153 8.80308 14.1476 8.84082 14.1315 8.87317C14.1155 8.90552 14.094 8.93248 14.0646 8.95674C14.0351 8.97831 14.0029 8.99179 13.9654 8.99449C13.9279 8.99718 13.8931 8.9891 13.8583 8.96483L13.0788 8.49305H13.0842Z"
            fill="#FFD477"
          />
          <path
            d="M18.0451 27.1352L17.2656 27.607C17.2308 27.6285 17.1959 27.6393 17.1584 27.6366C17.1209 27.6339 17.0888 27.6204 17.0593 27.5989C17.0299 27.5773 17.0084 27.5503 16.9924 27.5153C16.9763 27.4829 16.9736 27.4452 16.9816 27.4048L17.1879 26.5124L16.4968 25.9112C16.4647 25.8816 16.4459 25.8519 16.4379 25.8142C16.4298 25.7791 16.4325 25.7414 16.4459 25.709C16.4593 25.674 16.4781 25.647 16.5022 25.6228C16.5263 25.6012 16.5611 25.5877 16.6066 25.5796L17.5174 25.4988L17.8683 24.6576C17.8844 24.6199 17.9085 24.5902 17.9406 24.5714C17.9728 24.5525 18.0076 24.5417 18.0424 24.5417C18.0772 24.5417 18.1094 24.5525 18.1442 24.5714C18.1763 24.5902 18.2005 24.6199 18.2165 24.6576L18.5674 25.4988L19.4782 25.5796C19.5211 25.585 19.5559 25.6012 19.5827 25.6228C19.6068 25.6443 19.6255 25.674 19.6389 25.709C19.6523 25.7441 19.655 25.7791 19.647 25.8142C19.6389 25.8492 19.6202 25.8816 19.588 25.9112L18.8969 26.5124L19.1032 27.4048C19.1139 27.4452 19.1085 27.4829 19.0925 27.5153C19.0764 27.5476 19.055 27.5746 19.0255 27.5989C18.996 27.6204 18.9639 27.6339 18.9264 27.6366C18.8889 27.6393 18.8541 27.6312 18.8192 27.607L18.0397 27.1352H18.0451Z"
            fill="#FFD477"
          />
          <path
            d="M15.5231 20.5262C15.2929 20.5262 15.0948 20.4426 14.9316 20.2781C14.7683 20.1136 14.6853 19.9141 14.6853 19.6821V15.462C14.6853 15.2301 14.7683 15.0305 14.9316 14.866C15.0948 14.7016 15.2929 14.618 15.5231 14.618H15.9434V13.7739C15.9434 13.1888 16.1468 12.6926 16.5563 12.28C16.9658 11.8674 17.4584 11.6625 18.0392 11.6625C18.62 11.6625 19.1126 11.8674 19.5221 12.28C19.9316 12.6926 20.1351 13.1888 20.1351 13.7739V14.618H20.5553C20.7855 14.618 20.9836 14.7016 21.1468 14.866C21.3101 15.0305 21.3931 15.2301 21.3931 15.462V19.6821C21.3931 19.9141 21.3101 20.1136 21.1468 20.2781C20.9836 20.4426 20.7855 20.5262 20.5553 20.5262H15.5285H15.5231ZM18.0365 18.4148C18.2667 18.4148 18.4648 18.3312 18.6281 18.1667C18.7914 18.0022 18.8743 17.8026 18.8743 17.5707C18.8743 17.3388 18.7914 17.1393 18.6281 16.9748C18.4648 16.8103 18.2667 16.7267 18.0365 16.7267C17.8063 16.7267 17.6083 16.8103 17.445 16.9748C17.2817 17.1393 17.1987 17.3388 17.1987 17.5707C17.1987 17.8026 17.2817 18.0022 17.445 18.1667C17.6083 18.3312 17.8063 18.4148 18.0365 18.4148ZM16.7785 14.6153H19.2919V13.7712C19.2919 13.4207 19.1688 13.1214 18.9252 12.8733C18.6816 12.6279 18.3845 12.5038 18.0339 12.5038C17.6832 12.5038 17.3888 12.6279 17.1425 12.8733C16.8989 13.1187 16.7758 13.418 16.7758 13.7712V14.6153H16.7785Z"
            fill="#F5F7FA"
          />
          <path
            d="M38.0909 22.4873C38.0909 20.9069 39.2047 19.8235 40.5969 19.8235C41.488 19.8235 42.094 20.2624 42.4838 20.8417L41.9662 21.1434C41.6878 20.7114 41.1767 20.3961 40.5937 20.3961C39.5388 20.3961 38.7199 21.26 38.7199 22.4873C38.7199 23.7146 39.5388 24.5785 40.5937 24.5785C41.1767 24.5785 41.6845 24.2699 41.9662 23.8311L42.4904 24.1328C42.0776 24.719 41.488 25.151 40.5937 25.151C39.1981 25.151 38.0876 24.0711 38.0876 22.4873H38.0909Z"
            fill="#8F8F8F"
          />
          <path
            d="M45.5885 19.9095C47.0462 19.9095 48.0345 21.0142 48.0345 22.4873C48.0345 23.9603 47.043 25.065 45.5885 25.065C44.1341 25.065 43.1426 23.9603 43.1426 22.4873C43.1426 21.0142 44.1242 19.9095 45.5885 19.9095ZM45.5885 20.4635C44.4788 20.4635 43.7762 21.3228 43.7762 22.4873C43.7762 23.6517 44.4788 24.511 45.5885 24.511C46.6982 24.511 47.4008 23.6451 47.4008 22.4873C47.4008 21.3294 46.6917 20.4635 45.5885 20.4635Z"
            fill="#8F8F8F"
          />
          <path
            d="M52.9597 20.7745L51.2592 25.065H51.0029L49.3091 20.7745V25.065H48.6868V19.9095H49.5786L51.1294 23.8362L52.6868 19.9095H53.5787V25.065H52.9564V20.7745H52.9597Z"
            fill="#8F8F8F"
          />
          <path
            d="M54.2343 19.9095H56.2466C57.2544 19.9095 57.8183 20.6195 57.8183 21.4634C57.8183 22.3072 57.241 23.0172 56.2466 23.0172H54.855V25.065H54.231V19.9129L54.2343 19.9095ZM56.1732 20.4823H54.8583V22.4444H56.1732C56.7672 22.4444 57.1709 22.0431 57.1709 21.4634C57.1709 20.8837 56.7672 20.4823 56.1732 20.4823Z"
            fill="#8F8F8F"
          />
          <path d="M58.4705 19.9095H59.0907V24.4918H61.4056V25.065H58.4705V19.9095Z" fill="#8F8F8F" />
          <path d="M62.0579 19.9095H62.7101V25.065H62.0579V19.9095Z" fill="#8F8F8F" />
          <path
            d="M66.9756 23.922H64.4783L64.0418 25.065H63.3625L65.3414 19.9095H66.1027L68.0914 25.065H67.4121L66.9756 23.922ZM64.6555 23.3488H66.7919L65.7286 20.4827L64.6555 23.3488Z"
            fill="#8F8F8F"
          />
          <path
            d="M69.3569 20.9083V25.065H68.7437V19.9095H69.3701L72.2069 23.9838V19.9095H72.8202V25.065H72.2299L69.3569 20.9083Z"
            fill="#8F8F8F"
          />
          <path d="M75.1045 20.4827H73.4724V19.9095H77.3859V20.4827H75.747V25.065H75.1045V20.4827Z" fill="#8F8F8F" />
          <path
            d="M43.4455 7.03775C45.6139 7.03775 46.9493 8.15009 47.6754 9.43096L45.5394 10.604C45.1375 9.92308 44.3822 9.38377 43.4455 9.38377C41.8281 9.38377 40.6839 10.6815 40.6839 12.3635C40.6839 14.0455 41.8281 15.3432 43.4455 15.3432C44.2169 15.3432 44.9138 15.0634 45.3157 14.7263V13.8634H43.0436V11.6556H47.8245V15.687C46.7549 16.9072 45.3157 17.6926 43.4455 17.6926C40.4927 17.6926 38.0876 15.6398 38.0876 12.3668C38.0876 9.09389 40.4927 7.04112 43.4455 7.04112V7.03775Z"
            fill="#8F8F8F"
          />
          <path
            d="M48.7981 7.20422H52.9112C55.9952 7.20422 58.2104 9.15372 58.2104 12.3567C58.2104 15.5597 55.992 17.5261 52.924 17.5261H48.7981V7.20422ZM52.9112 15.2049C54.6295 15.2049 55.6265 13.8906 55.6265 12.3567C55.6265 10.8228 54.716 9.52538 52.924 9.52538H51.3243V15.2049H52.9112Z"
            fill="#8F8F8F"
          />
          <path
            d="M59.1841 7.20422H64.1633C66.399 7.20422 67.6227 8.76855 67.6227 10.6403C67.6227 12.5121 66.399 14.0292 64.1633 14.0292H61.7485V17.5261H59.1841V7.20422ZM63.8183 9.44767H61.7453V11.7688H63.8183C64.505 11.7688 65.0094 11.3195 65.0094 10.6234C65.0094 9.92744 64.5017 9.44767 63.8183 9.44767Z"
            fill="#8F8F8F"
          />
          <path
            d="M72.4516 14.0292H71.1471V17.5261H68.5964V7.20422H73.5489C75.7435 7.20422 76.9897 8.72125 76.9897 10.6403C76.9897 12.4344 75.9377 13.394 75.0184 13.7352L77.035 17.5261H74.1153L72.4548 14.0292H72.4516ZM73.1637 9.44767H71.1471V11.7688H73.1637C73.8305 11.7688 74.3937 11.3195 74.3937 10.6099C74.3937 9.90041 73.8305 9.44767 73.1637 9.44767Z"
            fill="#8F8F8F"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_529_546"
          x="0"
          y="0"
          width="86"
          height="36.1887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_529_546" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_529_546" result="shape" />
        </filter>
        <clipPath id="clip0_529_546">
          <rect x="3" y="1" width="80" height="30.1887" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GDPRLightIcon;
