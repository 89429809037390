import React, {
  useState,
  useContext,
  createContext,
  ReactElement,
  Dispatch,
  SetStateAction,
  useRef,
  useCallback,
} from 'react';
import { useApp } from './AppContext';

interface IUseProfile {
  gallerySwiper: any;
  setGallerySwiper: Dispatch<SetStateAction<any>>;
  offerBoxContainerRef: any;
  photoAspectRatioRef: any;
  profileCardBannerRef: any;
  calculateGalleryMediaRatio: () => void;
  galleryMediaRatioValue: string;
}

const ProfileContext = createContext({});

const ProfileProvider = (props: any): ReactElement => {
  const [gallerySwiper, setGallerySwiper] = useState(null);
  const [galleryMediaRatioValue, setGalleryMediaRatioValue] = useState('133%');
  const offerBoxContainerRef = useRef(null);
  const photoAspectRatioRef = useRef(null);
  const profileCardBannerRef = useRef(null);
  const { siteFooterLogoRef, footerTopPosition } = useApp();

  const calculateGalleryMediaRatio = useCallback(() => {
    const photoAspectEl = photoAspectRatioRef?.current;
    const offerActionEl = offerBoxContainerRef?.current;
    const siteFooterEl = siteFooterLogoRef?.current;
    const profileCardBannerEl = profileCardBannerRef?.current;

    if (footerTopPosition > 0 && photoAspectEl && offerActionEl && siteFooterEl) {
      const offerActionClRec = offerActionEl.getBoundingClientRect();

      let cardBannerTop = 0;

      if (profileCardBannerEl) {
        const profileCardBannerClRec = profileCardBannerEl.getBoundingClientRect();

        cardBannerTop = profileCardBannerClRec.top < 0 ? 0 : profileCardBannerClRec.height;
      }

      const OFFER_BOX_MARGIN = 20;

      const dynamicPaddingTop = footerTopPosition - offerActionClRec.height - OFFER_BOX_MARGIN - cardBannerTop;

      setGalleryMediaRatioValue(`${dynamicPaddingTop}px`);
    }
  }, [
    footerTopPosition,
    photoAspectRatioRef?.current,
    offerBoxContainerRef?.current,
    profileCardBannerRef?.current,
    siteFooterLogoRef?.current,
  ]);

  const values: IUseProfile = {
    gallerySwiper,
    setGallerySwiper,
    offerBoxContainerRef,
    photoAspectRatioRef,
    profileCardBannerRef,
    calculateGalleryMediaRatio,
    galleryMediaRatioValue,
  };

  return <ProfileContext.Provider value={values} {...props} />;
};

const useProfile = (): IUseProfile => useContext(ProfileContext) as IUseProfile;

export { ProfileProvider, useProfile };
