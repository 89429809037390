/**
 * @Author: zachalam
 * @Date:   2017-01-19T17:00:10-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T17:00:44-08:00
 */

import React, { useEffect, useMemo } from 'react';
import Search from '../components/pages/Search/Search';
import { connect } from 'react-redux';
import {
  clearSearch,
  profileSearch,
  resetFilters,
  resetIndividualFilter,
  saveFilters,
  storeLastSelectedSearchResult,
  preserveResults,
  loadPreservedResults,
} from '../actions/searchActions';
import {
  refreshAccount,
  fetchPageNux,
  updateNuxGuideStates,
  skipNuxGuides,
  getExternalProfile,
} from '../actions/profileActions';
import { setHeaderHidden, clearErrors } from '../actions/commonActions';
import DefaultSearch from '../config/Search';
import { Object } from 'es6-shim';
import queryString from 'query-string';
import { mapMixPanelTrackSearchViewProperties, scrollToTop } from '../utils/helpers';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import useMixPanel from '../hooks/useMixPanel';
import { EVENT_NAMES } from '../constants/mixpanel';
import { displayUserLocation } from '../common';
import { useLocation } from 'react-router-dom';
import QuickOfferModalInitial from '../modules/QuickOfferModal/QuickOfferModalInitial';
import { postQuickOffer } from '../actions/offerActions';
import { INITIAL_PROFILE_PAGE_STATE } from '../constants/profile';

const SearchContainer = props => {
  const location = useLocation();
  const { loadFiltersFromDashboard, search, refreshGlobalNotification, profile, clearErrors, postQuickOffer } = props;
  const { mixpanelData, trackSearchView, resetMixpanelData } = useMixPanel();

  const { city, country, region } = props.profile?.profile?.data || {};
  const userLocation = { city, country, region };
  const currentUserLocation = useMemo(() => displayUserLocation(userLocation, userLocation, true), [userLocation]);

  useEffect(() => {
    handleResultsPreload();
    return () => {
      // refresh Global Notification on page unload
      refreshGlobalNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      //when user left the page
      trackSearchView(false, mixpanelData[EVENT_NAMES.SEARCH_VIEW]);
      resetMixpanelData(EVENT_NAMES.SEARCH_VIEW);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResultsPreload = () => {
    if (search.lastSelectedSearch) {
      return;
    } else if (search.preservedResult && search.preservedResult.profile && search.preservedResult.profile.length > 0) {
      scrollToTop();
      props.loadPreservedResults();
    } else if (location.search) {
      scrollToTop();
      const qString = queryString.parse(location.search);

      const filterQuery = {
        ...qString,
        distance: qString.distance ? parseInt(qString.distance) : '',
      };
      loadFiltersFromDashboard(filterQuery, props => {
        const payload = mapMixPanelTrackSearchViewProperties(filterQuery, props.meta);
        trackSearchView(false, {
          ...payload,
          'User Location': currentUserLocation,
        });
      });
    }
  };

  const handleSearchResultCardSelect = hashId => {
    props.storeLastSelectedSearchResult(hashId);
  };

  return (
    <>
      <Search
        handleSearchResultCardSelect={handleSearchResultCardSelect}
        {...props}
        currentUserLocation={currentUserLocation}
      />
      {profile?.profile?.data?.account_type === 'Attractive' && (
        <QuickOfferModalInitial
          profile={profile}
          clearErrors={clearErrors}
          postQuickOffer={postQuickOffer}
          id={'searchQuickOffer'}
        />
      )}
    </>
  );
};

const mapStateTopProps = state => {
  return {
    profile: state.profile,
    search: {
      ...state.search,
      filters:
        !state.search.filters || Object.keys(state.search.filters).length <= 0
          ? DefaultSearch.filters
          : state.search.filters,
    },
    lastSearchTime: state.meta.search_last_time,
    featuredProfiles: state.meta.search_featured_profiles,
    searchCount: state.search.profileResults,
    moreExists: Boolean(state.meta.search_current_page < state.meta.search_total_pages),
    userProfile: state.profile,
    searchLimit: state.meta.search_max_limit,
    searchProfiles: state.search.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshProfile: (formData, trackMixpanel) => {
      // initial state
      dispatch(profileSearch(formData, true, true, trackMixpanel));
      dispatch(clearSearch());
      dispatch(refreshAccount());
    },
    resetFiltersState: filterType => {
      dispatch(resetFilters());
      dispatch(resetIndividualFilter(filterType));
      dispatch(clearSearch());
    },
    resetFilters: () => {
      dispatch(resetFilters());
      // re-run search
      dispatch(clearSearch());
      dispatch(profileSearch(null, true, true, () => undefined));
    },
    resetIndividualFilter: filterType => {
      dispatch(resetIndividualFilter(filterType));
      dispatch(clearSearch());
      dispatch(profileSearch(null, true, true, () => undefined));
    },
    loadFiltersFromDashboard: (filters, trackMixpanel) => {
      dispatch(preserveResults());
      dispatch(clearSearch());
      dispatch(resetFilters());
      dispatch(saveFilters({ formData: { ...filters, fromDashboard: true } }));
      dispatch(profileSearch(null, true, true, trackMixpanel));
    },
    loadPreservedResults: () => {
      dispatch(loadPreservedResults());
    },
    saveFilters: filters => {
      dispatch(saveFilters({ formData: { ...filters } }));
    },
    clearSearch: saveSearch => {
      if (!saveSearch) {
        dispatch(clearSearch());
      }
    },
    loadMore: (formData, trackMixpanel) => {
      // get more paginated results.
      dispatch(profileSearch(formData, false, true, trackMixpanel));
    },
    setHeaderHidden: isHidden => {
      dispatch(setHeaderHidden(isHidden));
    },
    storeLastSelectedSearchResult: hashId => {
      dispatch(storeLastSelectedSearchResult({ id: hashId }));
    },
    fetchPageNux: curRoute => {
      dispatch(fetchPageNux(curRoute));
    },
    updateNuxState: nuxUpdates => {
      dispatch(updateNuxGuideStates(nuxUpdates));
    },
    skipNuxGuides: () => {
      return dispatch(skipNuxGuides());
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    postQuickOffer: (formData: { accounts: string[]; price: number }) => {
      return dispatch(postQuickOffer(formData));
    },
    updateInitialProfileToProfilePage: profileData => {
      const { account, avatar } = profileData;
      const { data: photoData } = INITIAL_PROFILE_PAGE_STATE.photo;
      const { hash_id } = account.data;

      const createInitialProfileFormat = {
        ...INITIAL_PROFILE_PAGE_STATE,
        ...account.data,
        profile: { data: profileData },
        photo: {
          data: [
            {
              ...photoData[0],
              ...avatar.data,
              hash_id,
            },
          ],
        },
      };
      dispatch(getExternalProfile(createInitialProfileFormat));
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SearchContainer);
