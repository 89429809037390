import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { dimensions, statusColor, backgroundTheme } from '../../../style';

const NotificationCount = styled('span')({
  position: 'absolute',
  left: '50%', //offset by padding
  top: -4,
  fontSize: 13,
  color: '#fff',
  backgroundColor: statusColor.notification,
  lineHeight: '14px',
  minWidth: 14,
  textAlign: 'center',
  borderRadius: 16,
  padding: 4,
  fontWeight: '600',
  border: `2px solid ${statusColor.notification}`,
});

const NavLink = styled(Link)(
  {
    position: 'relative',
    padding: '10px 14px 22px 14px',
    height: 33,

    [dimensions.SCREEN_MAX_MD]: {
      padding: '14px 4px 22px 4px',
      flexGrow: 1,
      textAlign: 'center',
    },
  },
  ({ active }) => ({
    backgroundColor: active === 'true' ? backgroundTheme.navBG : 'transparent',
  })
);

const IconPlaceholder = styled('div')({
  position: 'relative',
});

interface HeaderNavLinkProps {
  to: any;
  headerRef: any;
  currentPath: any;
  activePathPattern: any;
  iconComponent: any;
  activeIconComponent: any;
  notification: any;
}

const HeaderNavLink = ({
  to,
  headerRef,
  currentPath,
  activePathPattern,
  iconComponent,
  activeIconComponent,
  notification,
}: HeaderNavLinkProps): ReactElement => {
  let Icon;
  let isActive = false;
  if (iconComponent) {
    if (activePathPattern.test(currentPath)) {
      Icon = activeIconComponent;
      isActive = true;
    } else {
      Icon = iconComponent;
      isActive = false;
    }
  }

  return (
    <NavLink ref={headerRef} to={to} active={isActive.toString()}>
      <IconPlaceholder>
        {notification > 0 && <NotificationCount>{notification > 99 ? '99+' : notification}</NotificationCount>}
        <Icon />
      </IconPlaceholder>
    </NavLink>
  );
};

export default HeaderNavLink;
