import React, { PureComponent } from 'react';
import Button from '../../atoms/buttons/Button';
import Loading from '../../atoms/Loading';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import UnsupportedBrowserModal from '../../components/blocks/UnsupportedBrowserModal';
import BusyModal from '../../components/blocks/BusyModal';
import { VideoCallIcon, PauseVideo, EndCall, Mic, NoMic } from '../../atoms/icons/VideoCall';
import { withPusher } from '../../contexts/PusherContext';
import { PUSHER } from '../../config/constants';
import { getBrowserDetails, isQAautomation, getVideoPermissions } from '../../utils/helpers';

import Pusher from 'pusher-js';
import Peer from 'simple-peer';
import { changeCallStatus, clearVideoInfo } from '../../actions/profileActions';
import { triggerVideoCallEvent, updateVideoCallStatus, getTurnCredentials } from '../../sdk';

import '../../style/customModalAnimate.css';
import store from '../../store';
import DataLayer from '../../utils/dataLayer';
import WarningIcon from '@material-ui/icons/Warning';
import { buttonTheme } from '../../style';
import { withRouterV6Hooks } from '../../wrappers/withRouterV6Hooks';

const API_URL = process.env.REACT_APP_SDK_BASE_URL;

const Content = styled('div')({
  position: 'relative',
  minHeight: '75px',
});

const CallControlsContainer = styled('div')({
  textAlign: 'center',
  position: 'absolute',
  bottom: '16px',
  width: '100%',
});

const StatusIndicatorContainer = styled('h3')({
  textAlign: 'center',
  position: 'absolute',
  bottom: '45px',
  width: '100%',
  color: '#FFFFFF',
  textShadow: '2px 2px 4px #000',
});

const renderIncomingCall = (otherProfile, customStyle, callTo, renderCallText, closeCall) => {
  return (
    <div>
      <Button
        onClick={() => callTo(otherProfile.hash_id)}
        buttonType="success"
        customStyle={{ ...customStyle, borderRadius: '21px 0 0 21px' }}
      >
        {renderCallText()}
      </Button>
      <Button
        onClick={() => closeCall()}
        buttonType="error"
        customStyle={{ marginTop: 5, borderRadius: '0 21px 21px 0' }}
      >
        Cancel
      </Button>
    </div>
  );
};

const renderOutgoingCall = (disableButton, customStyle, stopVideos) => {
  return (
    <Button
      disabled={disableButton}
      onClick={() => stopVideos()}
      buttonType="error"
      customStyle={{ ...customStyle, marginTop: 5 }}
    >
      Cancel / End Call
    </Button>
  );
};

const ternaryFunc = (evaluator, result1, result2) => {
  return evaluator ? result1 : result2;
};

class VideoCallModal extends PureComponent {
  constructor() {
    super();

    this.state = {
      hasMedia: false,
      otherUserId: null,
      callStatus: 'waiting',
      callModalOpen: false,
      showUnsupportedModal: false,
      showBusyModal: false,
      myStream: null,
      pauseVideo: false,
      muteAudio: true,
      showIncomingAlert: false,
      iceServers: null,
      videoUID: '',
      errors: [],
      connecting: false,
      response: null,
      disableButton: true,
      streamAdded: false,
      isCallAccepted: false,
      isCaller: false,
      hasPermission: true,
    };

    this.channel = null;
    this.userStream = null; // stream
    this.peers = {};
    this.channelName = null;
    this.channelEvent = null;
    this.channelEventAccepted = null;
    this.nux = [];
  }

  componentDidMount() {
    const { bindListener } = this.props;

    bindListener(this.listen);
    this.fetchIceServers();

    if (!this.channel) {
      this.setChannel();
    }
  }

  componentDidUpdate() {
    const location = this.props.location;
    if (location?.state?.answerCall && this.state.callStatus === 'waiting') {
      this.answerFromOutside();
    }

    if (!this.channel) {
      this.setChannel();
    }
  }

  componentWillUnmount() {
    this.props.stopListening(this.listen);
    this.unsubscribeToChannel(this.channelName);
  }

  setChannel = () => {
    const APP_KEY = process.env.REACT_APP_PUSHER_KEY;
    const { profile, otherProfile, auth } = this.props;

    if (!profile?.profile?.data?.account_type) {
      return;
    }

    const isGenerous = profile.profile?.data?.account_type.toLowerCase() === 'generous';
    const channelKey = isGenerous
      ? `${otherProfile.hash_id}.${profile.hash_id}`
      : `${profile.hash_id}.${otherProfile.hash_id}`;
    this.channelKey = channelKey;
    this.channelName = `presence-video_call.${channelKey}`;
    this.channelEvent = `client-signal-${this.channelKey}`;
    this.channelEventAccepted = `${this.channelEvent}-accepted`;

    if (!this.channel) {
      try {
        const pusher = new Pusher(APP_KEY, {
          cluster: 'us2',
          forceTLS: true,
          authEndpoint: `${API_URL}/thread/video-call/authorize/${otherProfile.hash_id}`,
          auth: {
            params: profile.id,
            headers: {
              Authorization: `Bearer ${auth.jwToken}`,
              'X-Auth-Type': auth.virgil ? 'virgil' : '',
            },
          },
        });

        this.channel = pusher.subscribe(this.channelName);
      } catch (error) {
        Sentry.captureException(error, { tags: { component: 'Mail', action: 'VideoCallModal Pusher connection' } });
      }
    }
  };

  unsubscribeToChannel = channelName => {
    const pusherInstance = this.getExistingPusherInstance();
    if (!channelName || !pusherInstance) {
      return false;
    }
    return pusherInstance.unsubscribe(channelName);
  };

  getExistingPusherInstance = () => {
    if (window.Pusher && window.Pusher.instances.length > 0) {
      return window.Pusher.instances[0];
    }
    return null;
  };

  answerFromOutside = () => {
    this.setCallStatus('answered');
    store.dispatch(changeCallStatus(true));
    const { otherProfile, videoInfo } = this.props;

    if (isQAautomation()) {
      this.handleAnswerFromOutside(otherProfile, videoInfo);
      return;
    }

    getVideoPermissions()
      .then(() => {
        this.handleAnswerFromOutside(otherProfile, videoInfo);
      })
      .catch(() => {
        if (!isQAautomation()) {
          this.setState({
            isCaller: false,
            showIncomingAlert: true,
            connecting: true,
            hasPermission: false,
            disableButton: false,
          });
          this.setModalVisibility(true);
        }
      });
  };

  handleAnswerFromOutside = (otherProfile, videoInfo) => {
    if (!videoInfo?.video_uid) {
      return;
    }

    this.setState(
      {
        isCaller: false,
        videoUID: videoInfo.video_uid,
        showIncomingAlert: false,
        connecting: true,
      },
      () => {
        setTimeout(() => {
          this.setModalVisibility(true);
          this.startCall(otherProfile.hash_id);
        }, 1400);
      }
    );
    this.updateCallStatus('answered', videoInfo.video_uid);
  };

  fetchIceServers = async () => {
    const result = await getTurnCredentials();
    const { ice_servers } = result.data;
    this.setState({ iceServers: ice_servers });
  };

  listen = () => {
    const { onRealtimeEvent } = this.props;
    const { EVENTS } = PUSHER;

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      if (data.type === 'video_call') {
        switch (data.action) {
          case 'start':
            break;
          case 'declined':
            this.onDecline();
            break;
          case 'ended':
            this.onEnded();
            break;
          case 'cancelled':
            this.onCancelled();
            break;
          case 'answered':
            this.onAnswered();
            break;
          default:
            break;
        }
      }
    });
  };

  onDecline = () => {
    this.setCallStatus('waiting');
    store.dispatch(clearVideoInfo());
    store.dispatch(changeCallStatus(false));
    this.closeCall();
    this.onShowBusyModal();
    this.onDataLayerPush('declined');
  };

  onEnded = () => {
    this.setCallStatus('waiting');
    store.dispatch(clearVideoInfo());
    store.dispatch(changeCallStatus(false));
    this.closeCall();
    this.onDataLayerPush('ended');
  };

  onCancelled = () => {
    store.dispatch(clearVideoInfo());
    store.dispatch(changeCallStatus(false));
    this.setCallStatus('waiting');
    this.onDataLayerPush('cancelled');
  };

  onAnswered = () => {
    this.setCallStatus('answered');
    store.dispatch(changeCallStatus(true));
    this.onDataLayerPush('answered');
  };

  onDataLayerPush = status => {
    const { profile } = this.props;
    const { isCaller } = this.state;
    new DataLayer().push({
      event: 'video_call',
      status,
      isGenerous: profile.isGenerous,
      isCaller,
    });
  };

  setupPusher = () => {
    this.channel.bind(`client-signal-${this.channelKey}`, signal => {
      let peer = this.peers[signal.userId];
      // if peer is not already exists, we got an incoming call
      this.setState({ otherUserId: signal.userId });
      if (peer === undefined) {
        peer = this.startPeer(signal.userId, this.state.isCaller);
      }

      this.peers[signal.userId] = peer;

      signal.sdp += '\n';
      peer.signal(signal.data);
    });

    // bind channel call accepted event
    if (this.state.isCaller) {
      this.channel.bind(`client-signal-${this.channelKey}-accepted`, () => {
        this.setState({ isCallAccepted: true });
        if (!this.state.streamAdded) {
          this.addStreamToPeer();
        }
        this.setCallStatus('answered');
        store.dispatch(changeCallStatus(true));
      });
    }
  };

  addStreamToPeer = () => {
    const peer = this.peers[this.props.otherProfile.hash_id];
    if (peer && this.userStream) {
      peer.addStream(this.userStream);
      this.setState({ streamAdded: true });
    }
  };

  startPeer = (userId, initiator = true) => {
    const { profile } = this.props;

    const peer = new Peer({
      initiator,
      trickle: false,
      config: {
        iceServers: this.state.iceServers || this.props.iceServers,
      },
    });

    // calling
    peer.on('signal', data => {
      if (this.channel) {
        this.channel.trigger(`client-signal-${this.channelKey}`, {
          type: 'signal',
          userId: profile.hash_id,
          data: data,
        });
      }

      if (this.state.callStatus !== 'answered') {
        this.triggerCallStart(data).then(res => {
          this.setState({ videoUID: res.video_uid, connecting: true, disableButton: false });
        });
      }
    });

    peer.on('connect', () => {
      // wait for 'connect' event before using the data channel
      this.setState({ connecting: false });
      clearTimeout(this.callTimeout);

      if (!this.state.isCaller && !this.state.streamAdded) {
        this.addStreamToPeer();
        this.channel.trigger(`client-signal-${this.channelKey}-accepted`, { signal: '' });
      }
    });

    // receiving call
    peer.on('stream', stream => {
      if (this.userVideo) {
        try {
          this.userVideo.srcObject = stream;
        } catch (e) {
          this.userVideo.src = window.URL.createObjectURL(stream);
        }
      }
      if (this.state.callStatus === 'waiting') {
        this.setCallStatus('incoming');
      } else if (this.state.callStatus === 'outgoing') {
        this.setCallStatus('answered');
        store.dispatch(changeCallStatus(true));
        this.setState({ connecting: false });
      }
    });

    peer.on('close', () => {
      if (this.state.errors.length > 0) {
        return;
      }
      this.closeCall();
      clearTimeout(this.callTimeout);
    });

    peer.on('error', error => {
      this.setState({ errors: error });
      this.onDataLayerPush('error');
    });

    // Commented out instead (is used for local development)
    // peer._debug = console.log;

    return peer;
  };

  stopVideos = (status = null) => {
    if (this.state.callStatus === 'waiting' && status === 'ended') return;
    this.pauseVideoStream();
    this.setModalVisibility(false);
    if (this.state.callStatus === 'outgoing') {
      this.setCallStatus('cancelled');
      this.updateCallStatus('cancelled');
      this.closeCall();
    } else {
      this.setCallStatus('waiting');
      this.updateCallStatus('ended');
      this.closeCall();
    }
    this.resetCallButtons();
    store.dispatch(clearVideoInfo());
    store.dispatch(changeCallStatus(false));
  };

  setCallStatus = callStatus => {
    this.setState({ callStatus });
  };

  setModalVisibility = status => {
    if (getBrowserDetails().RTCunsupported) {
      this.onShowUnsupportedModal();
      return;
    }
    this.setState({ callModalOpen: status });
    if (status === false) this.setState({ connecting: false });
  };

  onShowUnsupportedModal = (showUnsupportedModal = true) => {
    this.setState({ showUnsupportedModal });
  };

  onShowBusyModal = (showBusyModal = true) => {
    this.setState({ showBusyModal });
  };

  stopVideoStream = thisVideo => {
    if (!thisVideo || !thisVideo.srcObject) return;
    const stream = thisVideo.srcObject;
    const tracks = stream.getTracks();
    const videoTracks = stream.getVideoTracks();
    const audioTracks = stream.getAudioTracks();
    videoTracks.forEach(function(track) {
      track.enabled = !videoTracks.enabled;
    });
    audioTracks.forEach(function(track) {
      track.enabled = !track.enabled;
    });
    tracks.forEach(function(track) {
      track.stop();
    });
    try {
      thisVideo.srcObject = null;
    } catch (e) {
      thisVideo.src = null;
    }
  };

  pauseVideoStream = () => {
    const thisVideo = this.myVideo;
    if (!thisVideo || !thisVideo.srcObject) return;
    const stream = thisVideo.srcObject;
    const tracks = stream.getVideoTracks();
    tracks.forEach(function(track) {
      track.enabled = !track.enabled;
    });
    this.setState({ pauseVideo: !this.state.pauseVideo });
  };

  muteAudioStream = () => {
    const thisVideo = this.myVideo;
    const stream = thisVideo.srcObject;
    const tracks = stream.getAudioTracks();
    tracks.forEach(function(track) {
      track.enabled = !track.enabled;
    });
    this.setState({ muteAudio: !this.state.muteAudio });
  };

  startCall = userId => {
    getVideoPermissions()
      .then(stream => {
        this.handleStartCall(userId, stream);
      })
      .catch(() => {
        if (!isQAautomation()) {
          this.setState({ hasPermission: false, disableButton: false });
        }
      });
  };

  handleStartCall = (userId, stream) => {
    this.setupPusher();

    this.setState({ hasMedia: true });
    this.userStream = stream;

    if (this.myVideo) {
      try {
        this.myVideo.srcObject = stream;
      } catch (e) {
        this.myVideo.src = URL.createObjectURL(stream);
      }
    }

    this.setState({ myStream: stream });
    this.peers[userId] =
      this.peers[userId] === undefined ? this.startPeer(userId, this.state.isCaller) : this.peers[userId];
    if (!this.state.isCaller) {
      if (!this.state.isCallAccepted) {
        const signal = this.props.videoInfo.signal;
        signal.sdp += '\n';
        this.peers[userId].signal(signal);
        this.setState({ isCallAccepted: true });
        this.onDataLayerPush('accepted');
      }
    }
    this.onDataLayerPush('initiated');

    this.callTimeout = setTimeout(() => {
      if (this.state.connecting && this.state.callStatus === 'outgoing') {
        this.updateCallStatus('timeout');
        this.closeCall();
        this.onDataLayerPush('timeout');
        store.dispatch(changeCallStatus(false));
      }
    }, 35000);
  };

  closeCall = () => {
    this.setState({ disableButton: true });
    const { otherProfile } = this.props;
    this.stopVideoStream(this.userVideo);
    this.stopVideoStream(this.myVideo);
    this.setState({ callModalOpen: false, connecting: false, isCallAccepted: false, streamAdded: false });
    const peerToClose = this.peers[otherProfile.hash_id];
    if (peerToClose !== undefined) {
      peerToClose.destroy();
    }
    if (this.channel) this.channel.unbind();
    this.userStream = null; // stream
    this.peers = {};
    this.props.navigate(
      {
        pathname: this.props.location.pathname,
      },
      {
        state: {
          answerCall: false,
        },
      }
    );
  };

  triggerCallStart = async signalData => {
    const { otherProfile } = this.props;
    const result = await triggerVideoCallEvent(otherProfile.hash_id, signalData);
    const { status, data } = result;
    if (status === 200) {
      return data.data;
    }
  };

  callTo = userId => {
    this.setState({ isCaller: true });
    this.setCallStatus('outgoing');
    store.dispatch(changeCallStatus(true));
    this.startCall(userId);
  };

  answerIncoming = response => {
    this.setCallStatus('answered');
    store.dispatch(changeCallStatus(true));
    const { otherProfile } = this.props;
    this.setState(
      {
        isCaller: false,
        videoUID: response.videoData.video_uid,
        showIncomingAlert: false,
        connecting: true,
        response,
      },
      () => {
        setTimeout(() => {
          this.setModalVisibility(true);
          this.startCall(otherProfile.hash_id);
        }, 300);
      }
    );
    this.updateCallStatus('answered', response.videoData.video_uid);
  };

  updateCallStatus = (status, video_uid = null) => {
    if (status === 'timeout' && this.state.callStatus === 'answered') return;
    const vUid = video_uid || this.state.videoUID;
    if (!vUid) return;
    if (status === 'ended' || status === 'timeout') {
      this.setCallStatus('waiting');
    }
    updateVideoCallStatus(vUid, status);
  };

  resetCallButtons = () => {
    this.setState({
      pauseVideo: false,
      muteAudio: true,
      streamAdded: false,
    });
    this.setCallStatus('waiting');
  };

  renderCallControls = () => {
    return (
      <div>
        {this.state.callStatus !== 'outgoing'
          ? renderIncomingCall(
              this.props.otherProfile,
              this.props.customStyle,
              this.callTo,
              this.renderCallText,
              this.closeCall
            )
          : renderOutgoingCall(this.state.disableButton, this.props.customStyle, this.stopVideos)}
      </div>
    );
  };

  renderVideoCallIcon = () => {
    return (
      <div>
        {!this.state.pauseVideo ? <VideoCallIcon fillColor={'#2490de'} /> : <PauseVideo fillColor={'#d56b6b'} />}
      </div>
    );
  };

  renderAudioIcon = () => {
    return <div>{!this.state.muteAudio ? <NoMic fillColor={'#d56b6b'} /> : <Mic fillColor={'#2490de'} />}</div>;
  };

  renderModal = () => {
    const { customStyle, otherProfile, profile } = this.props;
    const mobileMode = getBrowserDetails().isMobile && !getBrowserDetails().isTablet;

    const setModalStyle = () => {
      const customOverlay = customStyle.overlay || {};
      const customContent = customStyle.content || {};

      return {
        overlay: {
          zIndex: 8000,
          backgroundColor: 'rgba(0,0,0,0.65)',
          overflowY: 'auto',
          ...customOverlay,
        },
        content: {
          maxWidth: '520px',
          transform: 'scale(1)',
          margin: '0 auto',
          outline: 'none',
          border: 'none',
          background: 'none',
          top: '82px',
          bottom: 'initial',
          maxHeight: '80vh',
          overflow: 'auto',
          ':focus': {
            outline: 'none',
          },
          padding: '4px',
          ...customContent,
        },
      };
    };

    const otherProfileBackground =
      otherProfile.avatar && this.state.callStatus === 'answered'
        ? '#333333'
        : `url(${this.props.otherProfileAvatar}) #333333 top center no-repeat`;

    const profileAvatar = profile.profile && profile.profile.data.avatar && this.props.profileAvatar;

    const profileBackground =
      profile.profile && this.state.callStatus === 'answered'
        ? '#333333'
        : `url(${profileAvatar}) #333333 top center no-repeat`;

    return (
      <ReactModal
        closeTimeoutMS={300}
        style={setModalStyle()}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          this.setModalVisibility(false);
          this.setState({ hasPermission: true });
        }}
        isOpen={this.state.callModalOpen}
      >
        {this.state.hasPermission ? (
          <Content>
            <div className="video-container">
              {this.state.connecting && (
                <StatusIndicatorContainer>
                  <Loading width={14} color={'#FFFFFF'} /> Connecting with {otherProfile.account.data.username}..
                </StatusIndicatorContainer>
              )}
              <video
                style={{
                  height: ternaryFunc(mobileMode, 'auto', '90px'),
                  minHeight: 70,
                  width: ternaryFunc(mobileMode, '70px', '120px'),
                  position: 'absolute',
                  border: 'solid 1px #cbcbcb',
                  zIndex: 1,
                  top: 0,
                  right: 0,
                  borderRadius: 5,
                  boxShadow: '0px 0px 4px #000',
                  background: profileBackground,
                  backgroundSize: '100%',
                }}
                className="my-video"
                ref={ref => (this.myVideo = ref)}
                muted
                autoPlay
                playsInline
              />
              <video
                style={{
                  height: ternaryFunc(mobileMode, 'auto', '390px'),
                  minHeight: 400,
                  width: '100%',
                  borderRadius: 5,
                  border: 'solid 1px #cbcbcb',
                  boxShadow: '0px 0px 4px #000',
                  background: otherProfileBackground,
                  backgroundSize: '100%',
                }}
                className="user-video"
                ref={ref => (this.userVideo = ref)}
                autoPlay
                playsInline
              />
            </div>

            {this.state.callStatus !== 'answered' && (
              <CallControlsContainer>{this.renderCallControls()}</CallControlsContainer>
            )}
            {this.state.callStatus === 'answered' && (
              <CallControlsContainer>
                <Button
                  disabled={this.state.callStatus !== 'answered'}
                  onClick={() => this.stopVideos()}
                  buttonType="error"
                  customStyle={{ borderRadius: '21px 0 0 21px' }}
                >
                  <div>
                    <EndCall fillColor={'#dedede'} />
                  </div>
                </Button>
                <Button
                  disabled={this.state.callStatus !== 'answered'}
                  onClick={() => this.pauseVideoStream()}
                  buttonType="normal"
                  customStyle={{ backgroundColor: '#FFFFFF', borderRadius: 0 }}
                >
                  {this.renderVideoCallIcon()}
                </Button>
                <Button
                  disabled={this.state.callStatus !== 'answered'}
                  onClick={() => this.muteAudioStream()}
                  buttonType="normal"
                  customStyle={{ backgroundColor: '#FFFFFF', borderRadius: '0 21px 21px 0' }}
                >
                  {this.renderAudioIcon()}
                </Button>
              </CallControlsContainer>
            )}
          </Content>
        ) : (
          <Content>
            <div
              style={{
                height: mobileMode ? 'auto' : '390px',
                minHeight: '400px',
                borderRadius: 5,
                width: '100%',
                padding: '15px',
                top: 0,
                zIndex: 100,
                boxSizing: 'border-box',
                textAlign: 'center',
                background: '#fff',
              }}
            >
              <WarningIcon style={{ fontSize: '45px', marginTop: '100px' }} />
              <h4 style={{ marginTop: '0', marginBottom: 0 }}>No camera or microphone access</h4>
              <p style={{ marginTop: '0' }}>
                You have denied permissions. Go to browser&#39;s settings and allow permissions.
              </p>
              <Button
                onClick={() => this.stopVideos()}
                buttonType="error"
                customStyle={{ ...customStyle, marginTop: 5 }}
              >
                Close
              </Button>
            </div>
          </Content>
        )}
      </ReactModal>
    );
  };

  renderCallText = () => {
    const { profile } = this.props;
    switch (this.state.callStatus) {
      case 'incoming':
        return 'Answer';
      case 'outgoing':
        return profile.isGenerous ? 'Answer' : 'Calling...';
      case 'answered':
        return 'Answered';
      default:
        return 'Start Call';
    }
  };

  renderButton = () => {
    const { customStyle, otherProfile, isVideoButtonEnabled } = this.props;
    const disableBtn = !otherProfile.online;
    if (!isVideoButtonEnabled) return;
    return (
      <div>
        <Button
          id="wyp_videoCallFeature"
          disabled={disableBtn}
          onClick={() => {
            if (!disableBtn) this.setModalVisibility(true);
            this.resetCallButtons();
          }}
          buttonType={'normal'}
          customStyle={{ ...customStyle, backgroundColor: !disableBtn ? '#25a83a' : buttonTheme.videoCall }}
        >
          <VideoCallIcon fillColor="#FFFFFF" />
        </Button>
        {/* <FeaturedModal
          elementFeature="wyp_videoCallFeature"
          featureTitle="Introducing Video Calling"
          featureContent={`${MasterConfig.SITE_NAME} now has video calls! You can call anyone that you've already started a conversation with.`}
        /> */}
      </div>
    );
  };

  render() {
    const { showUnsupportedModal, showBusyModal } = this.state;
    return (
      <React.Fragment>
        {this.renderButton()}
        {this.renderModal()}
        <UnsupportedBrowserModal isOpen={showUnsupportedModal} setModalOpen={this.onShowUnsupportedModal} />
        <BusyModal isOpen={showBusyModal} setModalOpen={this.onShowBusyModal} />
      </React.Fragment>
    );
  }
}

export default withPusher(withRouterV6Hooks(VideoCallModal));
