import React, { useMemo, useState } from 'react';

import MasterConfig from '../../config/Master';
import TextInput from '../../atoms/TextInput';
import Panel from '../../atoms/panels/Panel';
import Button from '../../atoms/buttons/Button';
import Container from '../blocks/Container';
import Headline from '../common/Headline';
import HeaderOut from '../../atoms/HeaderOut';
import TextLink from '../common/TextLink';

interface Errors {
  password: string;
  email: string;
  password_confirmation: string;
}

interface RestoreProps {
  token: string;
  handleSubmit(): void;
  errors: Errors;
  tokenValid: boolean;
}

const Restore = ({ token, handleSubmit, errors, tokenValid }: RestoreProps): JSX.Element => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirm] = useState('');
  const myToken = useMemo(() => token, [token]);

  const onChangeHandler = e => {
    const field = e.target.id;
    switch (field) {
      case 'password':
        setPassword(e.target.value);
        break;
      case 'password_confirmation':
        setPasswordConfirm(e.target.value);
        break;
      default:
        return;
    }
  };

  const handleSubmission = e => {
    e.preventDefault();
    handleSubmit({
      password: password,
      password_confirmation: passwordConfirmation,
      token: myToken,
    });
  };

  return tokenValid ? (
    <Container width="thin">
      <HeaderOut />
      <Panel isLogin customStyle={{ backgroundColor: '#FFF', border: '1px solid rgb(218, 225, 233)' }} padding="large">
        <Headline spaceAfter style={{ color: 'rgb(62, 121, 165)' }}>
          Set new password
        </Headline>
        <p>Enter your new password below to reset it.</p>
        <form id="restoring" className="form-horizontal" onSubmit={handleSubmission}>
          <TextInput
            inputLabel="New Password"
            type="password"
            status={(errors.password || errors.email) && 'error'}
            statusMessage={errors.password}
            value={password}
            id="password"
            onChange={onChangeHandler}
            customStyle={{ marginRight: 0 }}
            customInputStyle={{ borderColor: 'rgba(0, 0, 0, 0.15) !important' }}
            isLogin
          />
          <TextInput
            id="password_confirmation"
            type="password"
            inputLabel="Confirm New Password"
            value={passwordConfirmation}
            onChange={onChangeHandler}
            status={errors.password_confirmation && 'error'}
            statusMessage={errors.password_confirmation}
            customStyle={{ marginRight: 0 }}
            customInputStyle={{ borderColor: 'rgba(0, 0, 0, 0.15) !important' }}
            isLogin
          />
          <div className="buttonSet">
            <Button type="submit" buttonType="primary" name="commit">
              Update Password
            </Button>
          </div>
        </form>
      </Panel>
    </Container>
  ) : (
    <Container width="thin">
      <HeaderOut />
      <Panel isLogin customStyle={{ backgroundColor: '#FFF', border: '1px solid rgb(218, 225, 233)' }} padding="large">
        <Headline spaceAfter>Invalid Reset Password Link</Headline>
        This reset password link has already been used to change your password, and can only be used once.
        <ul>
          <li>
            Please <TextLink to="/login">login using your new password</TextLink>.
          </li>
          <li>
            If you need to <TextLink to="/restoring">reset your password again</TextLink>, please request another link.
          </li>
          <li>
            If you&#39;re having trouble resetting your password,{' '}
            <a href={`${MasterConfig.FULL_URL}/faq`}>contact support</a>.
          </li>
        </ul>
      </Panel>
    </Container>
  );
};

export default Restore;
