import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.ON_FOCUS,
  page: 'Payment',
};

export const enum MelissaOnFocusVysionEventsEnum {
  PAYMENT_ADDRESS = 'paymentAddress',
  PAYMENT_ADDRESS_ZIP = 'paymentAddressZip',

  PAYMENT_ADDRESS_STATE = 'paymentAddressState',
  PAYMENT_ADDRESS_CITY = 'paymentAddressCity',
  PAYMENT_CARD_NAME_ENTRY = 'paymentCardNameEntry',
  PAYMENT_CARD_SECURITY_CODE_ENTRY = 'paymentCardSecurityCodeEntry',
  PAYMENT_CARD_EXPIRATION_ENTRY = 'paymentCardExpirationEntry',
}

export const melissaBillingFormOnFocusVysionEvents = {
  [MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS]: {
    ...commonValues,
    eventName: 'payment-address',
    component: 'Melissa Data Address Form',
  },
  [MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_ZIP]: {
    ...commonValues,
    eventName: 'payment-address-zip',
    component: 'Melissa Data Address Form',
    extraInfo: 'Zip Code',
  },

  [MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_STATE]: {
    ...commonValues,
    eventName: 'payment-address-state',
    component: 'Melissa Data Address Form',
    extraInfo: 'State',
  },
  [MelissaOnFocusVysionEventsEnum.PAYMENT_ADDRESS_CITY]: {
    ...commonValues,
    eventName: 'payment-address-city',
    component: 'Melissa Data Address Form',
    extraInfo: 'City',
  },
  [MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_NAME_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardNameEntry',
    component: 'Melissa Data Address Form',
    extraInfo: 'Card Name',
  },
  [MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_SECURITY_CODE_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardSecurityCodeEntry',
    component: 'Melissa Data Address Form',
    extraInfo: 'Card Security Code',
  },
  [MelissaOnFocusVysionEventsEnum.PAYMENT_CARD_EXPIRATION_ENTRY]: {
    ...commonValues,
    eventName: 'payment-cardExpirationEntry',
    component: 'Melissa Data Address Form',
    extraInfo: 'Card Expiration',
  },
};
