export const displaySuccessMessage = (message: any): any => {
  return {
    type: 'NOTIFICATION_BANNER_DISPLAY_SUCCESS',
    message,
  };
};

export const displayWarnMessage = (message: any): any => {
  return {
    type: 'NOTIFICATION_BANNER_DISPLAY_WARN',
    message,
  };
};

export const displayFailMessage = (message: any, isPersist?: boolean): any => {
  return {
    type: 'NOTIFICATION_BANNER_DISPLAY_FAIL',
    message,
    isPersist,
  };
};

export const displayErrorMessage = (message: any): any => {
  if (typeof message.errors === 'undefined' || typeof message.errors[0] === 'undefined') {
    return {
      type: null,
    };
  }

  return {
    type: 'NOTIFICATION_BANNER_DISPLAY_FAIL',
    message: {
      info: message.errors[0].description,
    },
  };
};

export const clearNotificationMessage = (): any => {
  return dispatch => {
    return dispatch({
      type: 'NOTIFICATION_BANNER_CLEAR',
    });
  };
};
