import React, { useState, useEffect } from 'react';
import BillingPayment from '../screens/billing/BillingPayment';
import { connect } from 'react-redux';
import {
  postBillingPayment,
  getKountDetails,
  getPaymentToken,
  deleteSavedToken,
  getBillingDescriptors,
  getBillingPackages,
  postVisitLogs,
  calculateChargeAmount,
} from '../actions/billingActions';
import { killLoading, setLoading, clearErrors } from '../actions/commonActions';
import { getLocalItem } from '../common';
import KountLogo from '../atoms/billing/KountLogo';
import { useComponentWillMount } from '../utils/customHooks';
import DDCContainer from './DDCContainer';
import { getCountryCode, isEmpty } from '../utils/helpers';
import { clearNotificationMessage } from '../actions/notificationActions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import attemptGetIPQS from '../utils/ipQualityScore';
import { mixpanelTrackBillingPageVisited } from '../utils/mixpanel/billingPageVisited';

// Function to map the errors that are passed down in this.props.errors to the expected error object.
const mapErrorsToStatus = errors => {
  const statuses = {};

  Object.keys(errors).forEach(key => {
    if (typeof errors[key] !== 'object' || typeof errors[key][0] === 'undefined') return;

    statuses[key] = {
      status: 'error',
      message: errors[key][0],
    };
  });

  return statuses;
};

const BillingOrderContainer = props => {
  const [timestampIdentifier, setMyTimeStampIdentifier] = useState('');
  const [isVisitLogged, setIsVisitLogged] = useState(false);
  const [localPackages] = useState(getLocalItem('wyp_billing_packages'));
  const [showAutoTopup, setShowAutoTopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { profile, logPageVisit, paymentToken, errors, kountDetails, descriptors, removeSavedToken } = props;

  const savedCard = (paymentToken && paymentToken[0]) || null;
  const statuses = mapErrorsToStatus(errors);
  const location = useLocation();
  const navigate = useNavigate();

  const defaultCountry =
    paymentToken && typeof paymentToken[0] !== 'undefined'
      ? paymentToken[0].country
      : getCountryCode(profile.profile && profile.profile.data.country)[1];
  const [searchParams] = useSearchParams();

  useComponentWillMount(() => {
    setIsLoading(true);
    props.getKountDetails();
    props.getBillingDescriptors();

    props.getPaymentToken({ includes: 'settings', applepaySupported: !isEmpty(window.ApplePaySession) });
    props.clearErrors();

    if (localPackages === null) {
      props.getBillingPackages();
    }

    attemptGetIPQS();
  });

  useEffect(() => {
    const { recurringData } = props;
    const hasSusbcribeToAutotopup = recurringData && recurringData.data && recurringData.data.subscribed;
    setShowAutoTopup(!hasSusbcribeToAutotopup);
  }, [props.recurringData]);

  useEffect(() => {
    setTimeStampIdentifier();

    // const queryStringParsed = queryString.parse(location.search);
    const packageId = searchParams.get('selectedPackageId') || null;

    if (packageId !== 'idv_last_minute' && profile?.profile?.data.account_type === 'Attractive') {
      navigate('/dashboard');
    }
  }, [profile?.profile?.data.account_type, location.search]);

  useEffect(() => {
    if (!isVisitLogged) {
      setTimeout(() => {
        logPageVisit('packages', 2);
        setIsVisitLogged(true);
      }, 500);
    }
  }, [isVisitLogged, logPageVisit]);

  useEffect(() => {
    const savedMyCard = (paymentToken && paymentToken[0]) || null;
    const newCard = document.getElementById('new_card');

    if (props.processingPayment && errors && typeof errors.data !== 'undefined') {
      if (savedMyCard && newCard !== null) {
        newCard.click();
      }
    }
  }, [errors, paymentToken, props.processingPayment]);

  useEffect(() => {
    mixpanelTrackBillingPageVisited();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTimeStampIdentifier = () => {
    const dateTime = Date.now();
    const timeIdentifier = Math.floor(dateTime / 1000);
    setMyTimeStampIdentifier(timeIdentifier);
  };

  const handlePaymentSubmit = data => {
    if (data.payment_channel === 'sofort') {
      // if payment type is sofort remove recurring in payload
      delete data.recurring;
      props.postPayment(data, navigate);
      return;
    }

    const monthExpiry =
      data.use2pay_card_exp_month && data.use2pay_card_exp_month.toString().length === 1
        ? `0${data.use2pay_card_exp_month}`
        : data.use2pay_card_exp_month;

    const dataKount = {
      ...data,
      ...(data.use2pay_card_exp_month && { use2pay_card_exp_month: monthExpiry }),
      kount_identifier: timestampIdentifier,
      use2pay_currency: data.selectedPackage ? data.selectedPackage.currency.data.code : 'USD',
    };

    props.postPayment(dataKount, navigate);
  };

  if (!props.packages && !localPackages) navigate('/packages');

  return (
    <React.Fragment>
      <BillingPayment
        processingPayment={props.isProcessing && (!errors.data || !errors.data.error_code)}
        statuses={statuses}
        descriptors={descriptors}
        savedCard={savedCard}
        handleSubmit={handlePaymentSubmit}
        handleDeleteSavedCard={removeSavedToken}
        defaultCountry={defaultCountry}
        defaultState={paymentToken && typeof paymentToken[0] !== 'undefined' && savedCard ? paymentToken[0].state : ''}
        errorMessage={errors.error}
        showAutoTopup={showAutoTopup}
        setShowAutoTopup={setShowAutoTopup}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        {...props}
      />
      <KountLogo details={kountDetails} timestamp={timestampIdentifier} />
      <DDCContainer location={location} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    packages: state.billing.packages,
    kountDetails: state.billing.kountDetails,
    descriptors: state.meta.descriptors,
    paymentToken: state.billing.paymentToken,
    loading: state.common.isFetching,
    errors: state.common.errors,
    alternativePayment: state.billing.alternativePayment,
    rocketgateGooglepay: state.billing.rocketgateGooglepay,
    applepayCredentials: state.billing.applepayCredentials,
    featureVariant: state.common.featureVariant,
    evidently: state.settings.evidently,
    recurringData: state.settings.recurring,
    isProcessing: state.billing.isProcessing || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postPayment: (formData, navigate) => {
      dispatch(clearNotificationMessage());
      dispatch(clearErrors());
      return dispatch(postBillingPayment(formData, navigate));
    },
    removeSavedToken: tokenId => {
      dispatch(setLoading());
      dispatch(deleteSavedToken(tokenId));
    },
    getKountDetails: () => {
      dispatch(getKountDetails());
    },
    getPaymentToken: (data: any) => {
      return dispatch(getPaymentToken(data));
    },
    getBillingDescriptors: () => {
      dispatch(getBillingDescriptors());
    },
    getBillingPackages: (order = 'desc') => {
      dispatch(getBillingPackages(order));
    },
    killLoading: () => {
      dispatch(killLoading());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    logPageVisit: (type, step) => {
      dispatch(postVisitLogs(type, step));
    },
    calculateChargeAmount: (country, packageId, promoCode, paymentChannel) => {
      return calculateChargeAmount(country, packageId, promoCode, paymentChannel);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingOrderContainer);
