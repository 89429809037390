export const STORE_CONVO = 'STORE_CONVO';
export const PREPEND_CONVO = 'PREPEND_CONVO';
export const STORE_MESSAGE = 'STORE_MESSAGE';
export const REQUEST_CONVO = 'REQUEST_CONVO';
export const CLEAR_CONVO = 'CLEAR_CONVO';
export const CONVO_APPEND = 'CONVO_APPEND';
export const CONVO_READ_ALL = 'CONVO_READ_ALL';
export const CONVO_MARK_MESSAGE_STATUS = 'CONVO_MARK_MESSAGE_STATUS';
export const CONVO_REMOVE_MESSAGE = 'CONVO_REMOVE_MESSAGE';
export const CONVO_ARCHIVING = 'CONVO_ARCHIVING';
export const CONVO_ADD_POKE = 'CONVO_ADD_POKE';

export interface ConvoState {
  isFetching?: boolean,
}

export interface ConvoID {
  id: string;
}

export interface Status {
  status: string;
}

export interface ConvoData {
  data?: string;
}

export interface MessagesData {
  status: string;
  id: string;
  they_read: boolean;
}

export interface Messages {
  messages?: {
    data: MessagesData[];
  };
}

export interface Message {
  message: string;
}

export interface Pokes {
  pokes?: {
    data: unknown[];
  };
}

export interface ConvoIDStatus extends  ConvoID, Status {}

export interface ConvoPayloadState extends ConvoState, ConvoID, Status, ConvoData, Messages, Pokes {}

interface STORE_CONVO_ACTION {
  type: typeof STORE_CONVO;
  payload: ConvoPayloadState;
}

interface PREPEND_CONVO_ACTION {
  type: typeof PREPEND_CONVO;
  payload: Messages;
}

interface STORE_MESSAGE_ACTION {
  type: typeof STORE_MESSAGE;
  payload: MessagesData;
}

interface REQUEST_CONVO_ACTION {
  type: typeof REQUEST_CONVO;
  payload: ConvoState;
}

interface CLEAR_CONVO_ACTION {
  type: typeof CLEAR_CONVO;
  payload: ConvoState;
}

interface CONVO_APPEND_ACTION {
  type: typeof CONVO_APPEND;
  payload: Message;
}

interface CONVO_READ_ALL_ACTION {
  type: typeof CONVO_READ_ALL;
  payload: ConvoState;
}

interface CONVO_MARK_MESSAGE_STATUS_ACTION {
  type: typeof CONVO_MARK_MESSAGE_STATUS;
  payload: ConvoIDStatus;
}

interface CONVO_REMOVE_MESSAGE_ACTION {
  type: typeof CONVO_REMOVE_MESSAGE;
  payload: ConvoID;
}

interface CONVO_ARCHIVING_ACTION {
  type: typeof CONVO_ARCHIVING;
  payload: Status;
}

interface CONVO_ADD_POKE_ACTION {
  type: typeof CONVO_ADD_POKE;
  payload: ConvoData;
}


export type ConvoActionTypes =
  | STORE_CONVO_ACTION
  | PREPEND_CONVO_ACTION
  | STORE_MESSAGE_ACTION
  | REQUEST_CONVO_ACTION
  | CLEAR_CONVO_ACTION
  | CONVO_APPEND_ACTION
  | CONVO_READ_ALL_ACTION
  | CONVO_MARK_MESSAGE_STATUS_ACTION
  | CONVO_REMOVE_MESSAGE_ACTION
  | CONVO_ARCHIVING_ACTION
  | CONVO_ADD_POKE_ACTION;