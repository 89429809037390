import React, { ReactElement } from 'react';
import { isDarkMode } from '../../utils/helpers';
interface IRoundDismissIconProps {
  customStyle?: any;
}

const RoundDismissIcon = ({ customStyle, ...rest }: IRoundDismissIconProps): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <circle cx="12" cy="12" r="12" fill="#8F8F8F" />
      <mask
        id="mask0_403_1231"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="12"
        height="12"
      >
        <rect x="6.40002" y="6.39999" width="11.2" height="11.2" fill={isDarkMode() ? '#6C6C6C' : '#D9D9D9'} />
      </mask>
      <g mask="url(#mask0_403_1231)">
        <path
          d="M7.33645 17.6L6.40002 16.6636L11.0431 12L6.40002 7.33642L7.33645 6.39999L12 11.043L16.6636 6.39999L17.6 7.33642L12.957 12L17.6 16.6636L16.6636 17.6L12 12.9569L7.33645 17.6Z"
          fill={isDarkMode() ? '#020D15' : 'white'}
        />
      </g>
    </svg>
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" fill="#6C6C6C" />
    </svg>
  );
};

export default RoundDismissIcon;
