import React, { ReactElement } from 'react';
import { color } from '../../style';

interface LockedIconProps {
  size: number;
  unread: boolean;
  locked: boolean;
}

const LockedIcon = ({ size = 14, unread = false, locked = false }: LockedIconProps): ReactElement => {
  const iconColor = unread || locked ? color.BRAND_1 : '#CCCCCC';

  return (
    <svg version="1.1" viewBox="0 0 12 12" width={`${size}px`} height={`${size}px`}>
      <g id="Symbols" stroke="none" strokeWidth="1">
        <g transform="translate(-9.000000, -36.000000)">
          <g id="Group-2" transform="translate(0.000000, 2.000000)">
            <g id="lock-copy" transform="translate(9.000000, 34.000000)">
              <path
                d="M7.14064208,5.24561241 L2.86000755,5.24561241 L2.86000755,5.04236802 C2.86000755,4.59462365 2.87561132,4.14596923 2.85711796,3.69913492 C2.81146249,2.59767164 3.56969018,1.79227781 4.45563762,1.56840562 C5.52160635,1.29903096 6.64594475,1.85719467 7.00858794,2.86310275 C7.09036326,3.09000843 7.12677206,3.345429 7.13486291,3.58962562 C7.15335626,4.13504864 7.14064208,4.68168506 7.14064208,5.24561241 M9.99902177,5.72217501 C9.99873281,5.3745361 9.87650327,5.25137606 9.54651241,5.25076936 C9.22779094,5.24985931 8.90906947,5.25046601 8.56954297,5.25046601 C8.56954297,5.15915771 8.57012089,5.0860504 8.56925401,5.01294308 C8.56289692,4.44689228 8.60479593,3.87416778 8.54035814,3.31539737 C8.32566181,1.45555948 6.94270535,0.120820177 5.16358649,0.00645728639 C3.38128909,-0.108208954 1.70561745,1.318142 1.48109652,3.18010334 C1.41290226,3.74463739 1.4455546,4.32282219 1.43284042,4.89463664 C1.43023979,5.00990958 1.43255146,5.12548587 1.43255146,5.25046601 C1.08233348,5.25046601 0.762745136,5.24955596 0.44286783,5.25076936 C0.126746986,5.25167941 0.00133890022,5.37999639 0.00104994149,5.71519797 C-0.000394852126,7.65754701 -0.000394852126,9.5995927 0.00133890022,11.5419417 C0.00162785894,11.8637959 0.129058656,11.9987866 0.434199068,11.99909 C3.47606755,12.0003033 6.51793603,12.0003033 9.55980451,11.99909 C9.8756364,11.99909 9.99873281,11.8665261 9.99902177,11.5301111 C10.0001776,9.5941324 10.0004666,7.65815371 9.99902177,5.72217501"
                fill={`${iconColor}`}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LockedIcon;
