import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Packages',
};

export const enum PackagesClickVysionEventsEnum {
  CLICK_PACKAGE_SELECTED_PACKAGE = 'packageCreditSelected',
}

export const packagesClickVysionEvents = {
  [PackagesClickVysionEventsEnum.CLICK_PACKAGE_SELECTED_PACKAGE]: {
    ...commonValues,
    eventName: '',
    component: '',
    extraInfo: '',
  },
};
