import React, { FC } from 'react';
import styled from '@emotion/styled';

import { textColor } from '../../style';
import GalleryModal from '../../modules/modals/GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';

const ModalHeader = styled('h3')(() => ({
  marginTop: 30,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '31px',
  textAlign: 'left',
  marginBottom: 11,
  alignSelf: 'baseline',
  color: '#333333',
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const SkipButton = styled('a')({
  color: '#595959',
  fontSize: 16,
  textDecoration: 'underline',
  marginTop: 12,
  cursor: 'pointer',
  '&:hover': {
    color: '#2B8FD7',
  },
});

interface JoinSkipConfirmationProps {
  onClose(): void;
  onGoBack(): void;
  onSkip(): void;
  isOpen: boolean;
  alwaysDisabledBodyScroll?: boolean;
  customStyleSkipButton?: React.CSSProperties;
}

const JoinSkipConfirmation: FC<JoinSkipConfirmationProps> = ({
  onClose,
  onGoBack,
  onSkip,
  isOpen,
  alwaysDisabledBodyScroll = false,
  customStyleSkipButton,
}) => {
  useDisableBodyScroll(alwaysDisabledBodyScroll || isOpen, alwaysDisabledBodyScroll);

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{ content: { maxWidth: 400, left: 0, right: 0, paddingBottom: 0 } }}
      customContentStyle={{ padding: 30 }}
      contentLabel=""
      isOpen={isOpen}
      customCloseBtnStyle={{ top: 17, right: 17 }}
      forceDisableBodyScroll={alwaysDisabledBodyScroll}
    >
      <ModalDescription hasHeader={true} data-test-id="gallery-agreement">
        <ModalHeader>Are you sure you want to skip?</ModalHeader>
        <p style={{ lineHeight: '21px', marginTop: 0, marginBottom: 0, letterSpacing: '0.4px' }}>
          {`Skipping this step means you won't be able to send or receive date offers nor add people to Favorites.`}
        </p>

        <div style={{ marginTop: 30 }} data-test-id="always-show-agreement"></div>
        <PrimaryButton
          onClick={onGoBack}
          size="small"
          label="No, go back and finish"
          color="blue"
          customStyle={{ padding: '0 32px', width: 'inherit', fontSize: 18, fontWeight: 700, lineHeight: '16px' }}
        />
        <SkipButton data-test-id="join-modal-skip" onClick={onSkip} style={customStyleSkipButton}>
          Yes, skip for now
        </SkipButton>
      </ModalDescription>
    </GalleryModal>
  );
};

export default JoinSkipConfirmation;
