import { mixpanelTrackEventFromBE } from '.';

export type Sources = 'Main Photo' | 'Thumbnail Gallery';

export const mixpanelTrackLightBoxOpened = async (source: Sources): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Lightbox Opened',
    payload: {
      event_properties: {
        'Lightbox Source': source,
      },
    },
  });
};
