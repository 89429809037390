export const ONBOARDING_MANDATORY_FIELDS_GM_FEATURE_NAME = 'MandatoryOnboardingGM';
export const ONBOARDING_MANDATORY_FIELDS_AM_FEATURE_NAME = 'MandatoryOnboardingAM';

export const ONBOARDING_MANDATORY_FIELDS_GM_EXPERIMENT_NAME = 'MandatoryFieldsGM';
export const ONBOARDING_MANDATORY_FIELDS_AM_EXPERIMENT_NAME = 'MandatoryFieldsAM';

export const ONBOARDING_MANDATORY_FIELDS_GM_LOCAL_STORAGE_KEY = 'evidently_mandatory_fields_gm';
export const ONBOARDING_MANDATORY_FIELDS_AM_LOCAL_STORAGE_KEY = 'evidently_mandatory_fields_am';

export const ALL_MANDATORY = 'All_Mandatory';
export const ALLBUTPHOTO_MANDATORY = 'AllbutPhotos_Mandatory';
export const ALL_2PHOTOS_MANDATORY = 'All_2Photos_Mandatory';
export const ALL_3PHOTOS_MANDATORY = 'All_3Photos_Mandatory';

export type OnboardingMandatoryFeatureMetricKeys = 'JoinsCompleted';

export type OnboardingMandatoryFieldsVariations =
  | 'Control'
  | typeof ALL_MANDATORY
  | typeof ALLBUTPHOTO_MANDATORY
  | typeof ALL_2PHOTOS_MANDATORY
  | typeof ALL_3PHOTOS_MANDATORY;
