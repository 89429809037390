import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import Spinner from './Spinner/Spinner';

interface LoaderProps {
  active: boolean;
  customStyle?: CSSProperties;
  showLoadingBg?: boolean;
  width?: number;
}

const StyledLoaderContainer = styled<'div', LoaderProps>('div')(props => ({
  display: props.active ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 'calc(100% - 53px)',
  background: props.showLoadingBg ? 'rgba(255, 255, 255, 0.7)' : 'none',
  zIndex: 994,
  ...(props.customStyle || {}),
}));

const StyledLoader = styled('div')({
  textAlign: 'center',
});

const LoadingBackdrop: React.FC<LoaderProps> = ({ customStyle, active, showLoadingBg = true, width = 100 }) => {
  return (
    <StyledLoaderContainer customStyle={customStyle} active={active} showLoadingBg={showLoadingBg}>
      <StyledLoader>
        <Spinner width={width} />
      </StyledLoader>
    </StyledLoaderContainer>
  );
};

export default LoadingBackdrop;
