/**
 * @Author: Daniel Rhees <Daniel>
 * @Date:   2017-02-01T17:22:46-08:00
 * @Email:  daniel@reflexmedia.com
 * @Last modified by:   Daniel
 * @Last modified time: 2017-07-20T14:39:27-07:00
 */

import { backgroundTheme, borders, dimensions } from '../../style';
import styled from '@emotion/styled';

const ResultList = styled('div')`
  padding: 0;
  margin: 0;
  background-color: ${backgroundTheme.personalInfo};
  ${dimensions.SCREEN_MIN_MD} {
    border: ${borders.DEFAULT};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }
`;

export default ResultList;
