import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import Panel from '../../../atoms/panels/Panel';
import GiftWishlist from '../../common/rg-widgets/GiftWishlist';
import GiftWishlistToggle from '../../common/rg-widgets/GiftWishlistToggle';
import GiftWishlistTutorialModal from '../../common/modals/GiftWishlistTutorialModal';
import { isDarkMode } from '../../../utils/helpers';
import useRealGifts from '../../../hooks/useRealGifts';
import GiftSent from '../../common/rg-widgets/GiftSent';
import GiftSentToggle from '../../common/rg-widgets/GiftSentToggle';
import { dimensions } from '../../../style';

const StyledPanel = styled(Panel)(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    [dimensions.SCREEN_MAX_LG]: {
      marginLeft: isDarkMode() ? 15 : 0,
      marginRight: isDarkMode() ? 15 : 0,
    },
  },
  ({ isDarkMode, isAttractiveViewingOwnProfile, isCustomStyles }) => ({
    border: isCustomStyles && isDarkMode && !isAttractiveViewingOwnProfile && 'solid 1px #dd8f93 !important',
    ':hover': {
      background:
        (isCustomStyles && !isAttractiveViewingOwnProfile && isDarkMode && '#4b0d10') ||
        (!isAttractiveViewingOwnProfile && isCustomStyles && '#f8e9ea'),
    },
  })
);

const withBorderAndShadowStyle = {
  border: isDarkMode() ? 'solid 1px #D06369 ' : 'solid 1px #BC2028',
  boxShadow: isDarkMode() ? '0px 2px 4px rgba(0, 0, 0, 0.25)' : 'rgb(0 0 0 / 25%) 0px 2px 4px 0px',
  cursor: 'pointer',
};
interface GiftsProps {
  profile: any;
  myProfile: any;
  toggleWishlist: any;
  toggleGiftSent: any;
}

const Gifts = ({ profile, myProfile, toggleWishlist, toggleGiftSent }: GiftsProps): any => {
  const gender = useMemo(() => profile?.profile?.data?.sex.substring(0, 1), [profile]);
  const { isEnableRealGifts } = useRealGifts();

  const isAttractiveViewingOwnProfile = useMemo(
    () => profile.hash_id === myProfile.hash_id && profile.profile.data.account_type === 'Attractive',
    [profile, myProfile]
  );

  const isGenerousViewingOwnProfile = useMemo(
    () => profile.hash_id === myProfile.hash_id && profile.profile.data.account_type === 'Generous',
    [profile, myProfile]
  );

  const showGiftWishlist = useMemo(() => profile.profile.data.account_type === 'Attractive' && profile.show_wishlist, [
    profile,
  ]);

  const showGiftSent = useMemo(() => profile.profile.data.account_type === 'Generous' && profile.show_gift_sent, [
    profile,
  ]);

  const receiverData = useMemo(() => {
    return {
      id: profile.hash_id,
      username: profile.username,
      gender,
      country: profile.profile.data.country,
      birthday: '',
      isPremium: false,
      photo: profile.photo?.data[0]?.urls?.thumbs ?? '',
    };
  }, [profile, gender]);

  return (
    <div>
      {isEnableRealGifts && (isAttractiveViewingOwnProfile || showGiftWishlist) && (
        <StyledPanel
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
          isDarkMode={isDarkMode()}
          isCustomStyles={true}
          isAttractiveViewingOwnProfile={isAttractiveViewingOwnProfile}
          customStyle={!isAttractiveViewingOwnProfile && withBorderAndShadowStyle}
        >
          <GiftWishlist
            isEditProfile={isAttractiveViewingOwnProfile}
            badgeCount={profile.total_wishlist_count}
            receiverData={receiverData}
            userTemplateSetId=""
            tutorialComponent={
              <GiftWishlistTutorialModal
                isGenerous={!isAttractiveViewingOwnProfile}
                receiverData={receiverData}
                iconOnly
              />
            }
            toggleComponent={
              <GiftWishlistToggle type="wishlist" toggle={profile.show_wishlist} toggleAction={toggleWishlist} />
            }
          />
        </StyledPanel>
      )}
      {isEnableRealGifts && (isGenerousViewingOwnProfile || showGiftSent) && (
        <StyledPanel isCustomStyles={false} panelStyle={isDarkMode() ? 'borderless' : 'white'}>
          <GiftSent
            isEditProfile={isGenerousViewingOwnProfile}
            badgeCount={profile.total_gifts_sent}
            receiverData={receiverData}
            userTemplateSetId=""
            toggleComponent={
              <GiftSentToggle type="gift_sent" toggle={profile.show_gift_sent} toggleAction={toggleGiftSent} />
            }
          />
        </StyledPanel>
      )}
    </div>
  );
};

export default Gifts;
