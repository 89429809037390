import { VYSION_ACTIONS } from '..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Login',
};

export const enum LoginClickVysionEventsEnum {
  CLICK_LOGIN_WITH_PHONE = 'loginWithPhone',
  CLICK_LOGIN_PHONE_COUNTRY_CODE = 'loginPhoneNumberCountryCode',
  CLICK_LOGIN_PHONE_COUNTRY_CODE_SELECTION = 'loginPhoneNumberCountryCodeSelection',
  CLICK_LOGIN_SEND_CODE = 'loginSendCode',
  CLICK_LOGIN_ENTER_VERIFICATION_GO_BACK = 'loginEnterVerificationGoBack',
  CLICK_LOGIN_ENTER_VERIFICATION_VERIFY = 'loginEnterVerificationVerify',
  CLICK_LOGIN_ENTER_VERIFICATION_REQUEST_AGAIN = 'loginEnterVerificationRequestAgain',
  CLICK_LOGIN_INCORRECT_CODE_CLOSE = 'loginIncorrectCodeClose',
  CLICK_LOGIN_INCORRECT_CODE_TRY_AGAIN = 'loginIncorrectCodeTryAgain',
  CLICK_LOGIN_INCORRECT_CODE_RESEND = 'loginIncorrectCodeResend',
  CLICK_LOGIN_INCORRECT_CODE_CANCEL = 'loginIncorrectCodeCancel',
  CLICK_LOGIN_INCORRECT_CODE_NO_MORE_ATTEMPTS_OK = 'loginIncorrectCodeNoMoreAttemptsOk',
  CLICK_LOGIN_INCORRECT_CODE_NO_MORE_ATTEMPTS_CLOSE = 'loginIncorrectCodeNoMoreAttemptsClose',
  CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_OK = 'loginRequestAttemptsExceededOk',
  CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_CLOSE = 'loginRequestAttemptsExceededClose',
}

export const loginClickVysionEvents = {
  [LoginClickVysionEventsEnum.CLICK_LOGIN_WITH_PHONE]: {
    ...commonValues,
    eventName: 'login-signInWithPhone',
    component: 'Sign In With Phone Number',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_PHONE_COUNTRY_CODE]: {
    ...commonValues,
    eventName: 'login-phoneNumber-countryCode',
    component: 'Country Code Selection',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_PHONE_COUNTRY_CODE_SELECTION]: {
    ...commonValues,
    eventName: 'login-phoneNumber-countryCode-',
    component: 'Country Code Selection',
    extraInfo: '',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_SEND_CODE]: {
    ...commonValues,
    eventName: 'login-sendCode',
    component: 'Send Code',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_ENTER_VERIFICATION_GO_BACK]: {
    ...commonValues,
    eventName: 'login-enterVerification-goBack',
    component: 'Enter Verification Code',
    extraInfo: 'Go Back',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_ENTER_VERIFICATION_VERIFY]: {
    ...commonValues,
    eventName: 'login-enterVerification-verify',
    component: 'Enter Verification Code',
    extraInfo: 'Verify',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_ENTER_VERIFICATION_REQUEST_AGAIN]: {
    ...commonValues,
    eventName: 'login-enterVerification-requestAgain',
    component: 'Enter Verification Code',
    extraInfo: 'Request Again Link',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_CLOSE]: {
    ...commonValues,
    eventName: 'login-incorrectCode-close',
    component: 'Incorrect Code Modal',
    extraInfo: 'Close',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_TRY_AGAIN]: {
    ...commonValues,
    eventName: 'login-incorrectCode-tryAgain',
    component: 'Incorrect Code Modal',
    extraInfo: 'Try Again?',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_RESEND]: {
    ...commonValues,
    eventName: 'login-incorrectCode-resend',
    component: 'Incorrect Code Modal',
    extraInfo: 'Resend',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_CANCEL]: {
    ...commonValues,
    eventName: 'login-incorrectCode-cancel',
    component: 'Incorrect Code Modal',
    extraInfo: 'Cancel',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_NO_MORE_ATTEMPTS_OK]: {
    ...commonValues,
    eventName: 'login-incorrectCode-noMoreAttempts-ok',
    component: 'No More Attempts',
    extraInfo: 'Ok',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_INCORRECT_CODE_NO_MORE_ATTEMPTS_CLOSE]: {
    ...commonValues,
    eventName: 'login-incorrectCode-noMoreAttempts-close',
    component: 'No More Attempts',
    extraInfo: 'Close',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_OK]: {
    ...commonValues,
    eventName: 'login-requestAttemptsExceeded-ok',
    component: 'Request Attempts Exceeded',
    extraInfo: 'Ok',
  },
  [LoginClickVysionEventsEnum.CLICK_LOGIN_REQUEST_ATTEMPTS_EXCEEDED_CLOSE]: {
    ...commonValues,
    eventName: 'login-requestAttemptsExceeded-close',
    component: 'Request Attempts Exceeded',
    extraInfo: 'Close',
  },
};
