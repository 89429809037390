import mixpanel, { Mixpanel } from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

import uuidv4 from 'uuid';
import { getLocalItem, removeLocalItem, setLocalItem } from '../common';
import { isEmpty } from '../utils/helpers';

export const MIXPANEL_PROJECT_TOKEN =
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '47162de9ff34457fff351ea3480cfda1';
export const MIXPANEL_DEBUG = process.env.REACT_APP_MIXPANEL_DEBUG === 'true';
export const MIXPANEL_ENABLE = process.env.REACT_APP_MIXPANEL_ENABLE === 'true';
export interface UserPropertyOnceProps {
  'Registration Date': string;
  'Activation Date': string;
  'First Purchase Date': string;
  'First Profile Completed Date': string;
  'First Offer Made Date': string;
  'First Offer Accepted Date': string;
  'First Message Reply Date': string;
  'First Phone Number Exchange Date': string;
  'Initial Platform': string;
}

export interface UserPropertyProps {
  $email: string;
  $name: string;
  GoogleSSO: boolean;
  Gender: string;
  'Want To': string;
  'Interested In': string;
  'Display Name': string;
  'Profile City': string;
  'Profile Country': string;
  DOB: string;
  'Body Type': string[];
  Height: string;
  Children: boolean;
  Smoking: boolean;
  Drinking: boolean;
  Education: string;
  Occupation: string;
  Income: string;
  Interests: string;
  'Email Verified': boolean;
  'Initial Profile Completed': boolean;
  'Hash Id': string;
  'Page Name'?: string;
  'Initial Platform'?: string;
}

export type TrackFirstStepCompletedProps = Pick<UserPropertyProps, 'GoogleSSO'>;
export type TrackSecondStepCompletedProps = Pick<
  UserPropertyProps,
  | 'Gender'
  | 'Want To'
  | 'Interested In'
  | 'Display Name'
  | 'Profile City'
  | 'Profile Country'
  | 'DOB'
  | '$name'
  | 'Hash Id'
>;

export type TrackPhotoStepCompletedProps = {
  Result: string;
  'Number of photos added': number;
  'Hash Id'?: string;
};

export type TrackPackagesPageVisitedProps = {
  Source: string;
};

export type TrackProfileFavoritedProps = {
  Source: string;
  User: string;
};

export type TrackCreditsPurchasedProps = {
  Amount: number;
  Source: string;
  Total: string;
  AutoTopup: boolean;
  PaymentMethod?: string;
};

export type TrackProfileViewProps = {
  Source: string;
  User: string;
};
export type TrackConversationUnlocked = {
  triggerToBuy: boolean;
};

export type TrackSearchViewProps = {
  Source: string;
  'Page Number': number;
  'Filter Applied': boolean;
  Parameters: string[];
  'Matches Number': number;
  'Sorting Mode': string;
  'User Location': string;
};

export type TrackOfferDeclinedProps = {
  Amount: number;
  Source?: string;
  User: string;
};

export type TrackOfferIgnoredProps = {
  Amount: number;
  User: string;
};

export type TrackOfferCancelledProps = {
  Amount: number;
  Source: string;
  User: string;
};

export const MIXPANEL_USER_PROPERTIES_DEFAULT: Pick<UserPropertyProps, '$email' | 'GoogleSSO'> = {
  $email: '',
  GoogleSSO: false,
};

export const enum EVENT_NAMES {
  FIRST_STEP_COMPLETED = 'First Step Completed',
  SECOND_STEP_COMPLETED = 'Second Step Completed',
  CREDITS_PURCHASED = 'Credits Purchased',
  TUTORIAL_COMPLETED = 'Tutorial Completed',
  PROFILE_VIEW = 'Profile View',
  OFFER_MADE = 'Offer Made',
  CONVERSATION_UNLOCKED = 'Conv Unlocked',
  MESSAGE_TYPED = 'Message Typed',
  PAGE_VIEW = 'Page View',
  SEARCH_VIEW = 'Search View',
  PHOTO_STEP_COMPLETED = 'Photo Step Completed',
  OFFER_TUTORIAL_STEP_COMPLETED = 'Offer Tutorial Step Completed',
  PROFILE_WALL_STEP_1_SHOWN = 'Profile Wall Step 1 Shown',
  PROFILE_WALL_STEP_2_COMPLETED = 'Profile Wall Step 2 Completed',
  SMS_CODE_REQUESTED = 'SMS Code Requested',
  SMS_CODE_VERIFIED = 'SMS Code Verified',
  JOIN_PHONE_NUMBER_PROVIDED = 'Join - Phone Number Provided',
  PROFILE_DETAILS_STEP_1_COMPLETED = 'Profile Details Step 1 Completed',
  PROFILE_DETAILS_STEP_2_COMPLETED = 'Profile Details Step 2 Completed',
  JOIN_CLICKED_BACK = 'Join Clicked Back',
  USER_CLICKED_EMAIL_DOMAIN = 'User Clicked Email Domain',
  DISCOVER_MODE_ACTION = 'Discover Mode Action',
  PROFILE_CLOSED = 'Profile Closed',
  LIGHTBOX_CLOSED = 'Lightbox Closed',
  ACCOUNT_DELETION_STARTED = 'Account Deletion Started',
}

export const enum INCREMENTAL_VALUES_NAMES {
  PROFILE_VIEWS = '# profile views',
  COINS_PURCHASED = '# coins purchased',
  COINS_SPENT = '# coins spent',
  FAVORITES = '# favorites',
  OFFERS_SENT = '# offers sent',
  OFFERS_ACCEPTED = '# offers accepted',
  CONVERSATION_UNLOCKED = '# conv unlocked',
  MESSAGES_SENT = '# messages sent',
}

export const TRACK_PAGE_VIEW_PATHS = {
  'dashboard-normal': 'Dashboard View', // not actual path
  'dashboard-swipe-mode': 'Discover Mode', // not actual path
  '/interests/my-favorites': 'My Favorites View',
  '/interests': 'Viewed me View',
  '/interests/favorited-me': 'Favorited Me View',
  '/offers/new': 'Received Requests View',
  '/offers/sent': 'Sent Offers View',
  '/credits': 'Purchase History',
  '/cancelled-offers': 'Cancelled Offers',
  '/blocked': 'Blocked Accounts',
  '/mail': 'Messages View',
  menu: 'Menu',
  menu_notifications: 'Notifications',
  menu_privacy_safety: 'Privacy & Safety',
  menu_edit_profile: 'Profile Edit',
  '/search': 'Search',
  view_member_profile: 'Member Profile',
  terms: 'Terms',
  term_eu: 'Term EU',
  privacy: 'Privacy',
  faq: 'FAQ',
  blog: 'Blog',
  tips: 'Tips',
  guidelines: 'Guidelines',
  commitment: 'Commitment',
  facebook: 'Facebook',
  twitter: 'X',
  instagram: 'Instagram',
};

export const TRACK_PROFILE_VIEWS_FOR_OFFER_MADE = {
  profileViewDashboard: 'Profile Viewed from Dashboard',
  profileViewDiscover: 'Profile Viewed from Discover',
  profileViewSearch: 'Profile Viewed from Search',
  profileViewViewedMe: 'Profile Viewed from Viewed Me',
  profileViewReceivedRequest: 'Profile Viewed from Received Requests',
  profileViewReceivedOffers: 'Profile Viewed from Received Offers',
  profileViewMyFavorites: 'Profile Viewed from My Favorites',
  profileViewFavoritedMe: 'Profile Viewed from Favorited Me',
  profileViewDirect: 'Profile Direct',
};

export interface MixpanelDefaultStateProps {
  [EVENT_NAMES.CREDITS_PURCHASED]: TrackCreditsPurchasedProps;
  [EVENT_NAMES.CONVERSATION_UNLOCKED]: TrackConversationUnlocked;
  [EVENT_NAMES.PROFILE_VIEW]: TrackProfileViewProps;
  [EVENT_NAMES.SEARCH_VIEW]: TrackSearchViewProps;
}

export const MIXPANEL_DEFAULT_STATE: MixpanelDefaultStateProps = {
  [EVENT_NAMES.CREDITS_PURCHASED]: { Source: 'Direct URL', Amount: 0, Total: '', AutoTopup: false },
  [EVENT_NAMES.CONVERSATION_UNLOCKED]: { triggerToBuy: false },
  [EVENT_NAMES.PROFILE_VIEW]: { Source: '', User: '' },
  [EVENT_NAMES.SEARCH_VIEW]: {
    Source: '',
    'Page Number': 0,
    'Filter Applied': false,
    Parameters: ['Distance', 'Age', 'Height'],
    'Matches Number': 0,
    'Sorting Mode': 'Recent Activity',
    'User Location': '',
  },
  [EVENT_NAMES.DISCOVER_MODE_ACTION]: {
    Result: '',
    'Profile Viewed': false,
    'Number of Photos Viewed': 1,
    'Offer Amount': 0,
  },
  [EVENT_NAMES.OFFER_MADE]: {
    source: TRACK_PROFILE_VIEWS_FOR_OFFER_MADE.profileViewDirect,
  },
  [EVENT_NAMES.PROFILE_CLOSED]: {
    'Gallery Photos Seen': 1,
  },
  [EVENT_NAMES.LIGHTBOX_CLOSED]: {
    'Lightbox Photos Seen': 1,
  },
};

export const trackMixpanelAnonymousEvents = (eventName: string, payload = {}): Mixpanel | void => {
  if (MIXPANEL_ENABLE) {
    mixpanel.track(eventName, payload);

    return mixpanel;
  }
};

export const trackMixpanelEvents = (identifier: string, eventName: string, payload = {}): Mixpanel | void => {
  if (MIXPANEL_ENABLE) {
    try {
      mixpanel.identify(identifier);
      mixpanel.track(eventName, payload);
    } catch (error) {
      Sentry.captureException(error, {
        tags: { component: 'mixpanel', action: `trackMixpanel${eventName}` },
      });
    }
    return mixpanel;
  }
};

export const interestedInForMixpanel = (userData: any): string => {
  if (userData.looking_female && userData.looking_male) {
    return 'both';
  }

  if (userData.looking_male) {
    return 'men';
  }

  if (userData.looking_female) {
    return 'women';
  }

  return 'both';
};

export const IS_USED_MELISSA_IN_PURCHASED_LOCAL_STORAGE_KEY = 'is_melissa_used_in_billing_form';
export const MIXPANEL_CREDITS_PURCHASED = 'mixpanel_credits_purchased';

export const ACCOUNT_TEMP_HASH_ID_LOCAL_STORAGE_KEY = 'wyp_account_temp_hash_id';

export function generateAccountTempHashId(): string {
  const tempHashId = uuidv4();

  setLocalItem(ACCOUNT_TEMP_HASH_ID_LOCAL_STORAGE_KEY, tempHashId);

  return tempHashId;
}

export function removeAccountTempHashId(): void {
  removeLocalItem(ACCOUNT_TEMP_HASH_ID_LOCAL_STORAGE_KEY);
}

export function getAccountTempHashId(): string {
  const existingValues = getLocalItem(ACCOUNT_TEMP_HASH_ID_LOCAL_STORAGE_KEY);
  return !isEmpty(existingValues) ? existingValues : generateAccountTempHashId();
}
