/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { connectScreenSize } from 'react-screen-size';
import styled from '@emotion/styled';

/* sidebar components */
import SummaryActions from './SummaryActions';

import { MyProfileCover } from '../../../modules/ProfileCover';
import { displayUserLocation, tsSince, renderAvatar } from '../../../common';

/* right side bar components */
import PersonalInfo from '../Profile/PersonalInfo';
import Container from '../../blocks/Container';
import Panel from '../../../atoms/panels/Panel';
import Photos from '../Profile/Photos';

import PhotoLightbox from '../Photos/PhotoLightbox';
import LoadingMsg from '../../common/LoadingMsg';

import { dimensions, spacing } from '../../../style';
import { Header } from '../../../atoms/text';
import Headline from '../../common/Headline';
import OnboardingHeader from '../../blocks/headerNav/OnboardingHeader';
import { getBrowserDetails } from '../../../utils/helpers';
import { s3BucketDirect } from '../../../config/Master';

const ProfileHeading = styled(Panel)({
  background: '#FFFFFF',
  [dimensions.SCREEN_MAX_MD]: {
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.04)',
    borderRadius: 0,
    borderBottomWidth: 1,
  },
  [dimensions.SCREEN_MAX_XS]: {
    padding: 0,
  },
});

const LeftContent = styled('div')(
  {
    flex: 1,
  },
  ({ isLightboxOpen }) => ({
    [dimensions.SCREEN_MAX_XS]: {
      display: 'block',
      position: !isLightboxOpen ? 'unset' : 'absolute',
      top: !isLightboxOpen ? '-60px' : 0,
      left: 0,
      zIndex: 1000,
      width: '100%',
      backgroundColor: '#FFFFFF',
    },
  })
);

const RightContent = styled('div')({
  [dimensions.SCREEN_MAX_MD]: {
    marginTop: 16,
  },

  [dimensions.SCREEN_MIN_MD]: {
    flex: '0 0 280px',
    marginLeft: 15,
    marginRight: 0,
  },

  [dimensions.SCREEN_MIN_LG]: {
    flexBasis: 320,
  },
});

const mapPhotoList = (profileAcct, photoType) => {
  const privatePhotoList = [];
  const photoList = [];

  switch (photoType) {
    case 'private':
      profileAcct.photo.data.map(photo => {
        if (photo.id === null) {
          privatePhotoList.push({
            src: `${s3BucketDirect}resized/private_eye.png`,
            thumbnail: `${s3BucketDirect}thumbs/private_eye.png`,
            private: true,
            privateReal: photo.private,
          });
        }
      });
      return privatePhotoList;

    case 'public':
      profileAcct.photo.data.map(photo => {
        photoList.push({
          src: renderAvatar(photo.urls),
          thumbnail: photo.private ? `${s3BucketDirect}thumbs/private_eye_white.png` : photo.urls.profile,
          private: false,
          privateReal: photo.private,
        });
      });
      return photoList;

    default:
      return;
  }
};

const Profile = props => {
  const { onboarded, showWarn, profile: profileAcct, myprofile, mapFields, screenSizeIsMobile } = props;

  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [allowZoom] = useState(false);
  const profile = profileAcct.profile.data;
  const photoList = mapPhotoList(profileAcct, 'public');
  const privatePhotoList = mapPhotoList(profileAcct, 'private');
  const isMobile = getBrowserDetails().isMobile;

  useEffect(() => {
    // If user has any amount of private photos, adding only one private picture object to array
    if (privatePhotoList.length > 0) {
      privatePhotoList[0].total = privatePhotoList.length;
      photoList.push(privatePhotoList[0]);
    }
  }, [photoList, privatePhotoList]);

  const lightboxOnClose = () => {
    setLightboxOpen(false);
  };

  const openLightbox = index => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  return !profileAcct.profile ? (
    <LoadingMsg msg="Loading Profile" />
  ) : (
    <div>
      <Container>
        <OnboardingHeader />
        <Header>
          <Headline data-test-id="onboarding-summary-header" style={{ color: 'rgb(62, 121, 165)' }}>
            You&apos;re all set!
          </Headline>
        </Header>
      </Container>
      <Container
        id="ProfileContainer"
        fullWidth
        css={{
          [dimensions.SCREEN_MIN_MD]: {
            ...spacing.MARGIN_TOP_LG,
            display: 'flex',
            marginTop: 0,
          },
          [dimensions.SCREEN_MAX_XS]: {
            display: 'block',
            position: isLightboxOpen ? 'unset' : 'absolute',
            top: isLightboxOpen ? '-60px' : '115px',
            left: 0,
            zIndex: 1000,
            width: '100%',
            backgroundColor: '#FFFFFF',
          },
        }}
      >
        <LeftContent isLightboxOpen={isLightboxOpen}>
          <ProfileHeading>
            <MyProfileCover
              isMigration={true}
              username={profileAcct.username}
              age={profile.age}
              location={displayUserLocation(profile, myprofile.profile.data)}
              lastLoginTime={(profile.last_activity_dt && tsSince(profile.last_activity_dt.date, true)) || ''}
              lastLoginLocation={profile.last_access_location_full || ''}
              profileImg={renderAvatar(profile.avatar.data.urls)}
              profileImgFallback={profile.avatar.data.urls['resized']}
              onClickPhoto={() => openLightbox(0)}
              hashId={myprofile.profile.data.hash_id}
              isMobile={isMobile}
              isLogin
            />
          </ProfileHeading>

          {typeof photoList === 'object' && photoList.length > 0 && (
            <Panel isRowMobile>
              <Photos photos={photoList} openLightbox={openLightbox} migration />
            </Panel>
          )}
        </LeftContent>

        <RightContent>
          <PersonalInfo myProfile={profile} mapFields={mapFields} isLogin />
          {isMobile && <SummaryActions hashId={myprofile.hash_id} />}
        </RightContent>

        <PhotoLightbox
          images={photoList}
          allowZoom={allowZoom}
          lightboxIsOpen={isLightboxOpen}
          currentImage={currentImage}
          onClose={lightboxOnClose}
          userHasPrivatePhotos={privatePhotoList.length > 0}
          photoListLength={photoList.length}
          privatePhotoCount={privatePhotoList.length}
          isOnboarded={onboarded}
          onOfferClick={() => null}
          ignoreStatus={false}
          username={profileAcct.username}
          account={profileAcct}
          hashId={profileAcct.hash_id}
          isBlocked={profileAcct.is_blocked}
          myprofile={myprofile}
          showWarn={showWarn}
          updateSliderPosition={false}
          isOfferBtnVisible={myprofile.hash_id !== profile.hash_id && screenSizeIsMobile}
          currentUserIsGenerous={
            myprofile &&
            myprofile.profile &&
            Boolean(myprofile.profile && myprofile.profile.data.account_type === 'Generous')
          }
        />
      </Container>
    </div>
  );
};

const mapScreenToProps = screenSize => {
  return {
    screenSizeIsMobile: screenSize.mobile,
  };
};

export default connectScreenSize(mapScreenToProps)(Profile);
