import { useState, useEffect, useRef, useCallback } from 'react';
import { doWebTimestampCheck } from '../sdk';

const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useStateCallback = (initialState: any): any => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((newState, cb) => {
    cbRef.current = cb;
    setState(newState);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

const useComponentWillMount = (func: any): void => {
  const willMount = useRef(true);

  if (willMount.current) func();

  willMount.current = false;
};

const useComponentDidUpdate = (callback: any, deps: any): void => {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, [deps, callback]);
};

type UseForceReload = () => {
  isLoading: boolean;
};

const useWebTimestampCheck: UseForceReload = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleForceReload = async () => {
      setIsLoading(true);

      try {
        const response = await doWebTimestampCheck();
        const isForceReloaded = localStorage.getItem('forceReload');

        if (response.data.shouldReloadWebApp && !isForceReloaded) {
          localStorage.setItem('forceReload', 'true');
          window.location.reload();
          return;
        }

        localStorage.removeItem('forceReload');
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    handleForceReload();
  }, []);

  return { isLoading };
};

export { usePrevious, useStateCallback, useComponentWillMount, useComponentDidUpdate, useWebTimestampCheck };
