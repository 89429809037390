import React, { useEffect, useState, useRef, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Headline from '../components/common/Headline';
import ConfirmationModal from '../modules/modals/ConfirmationModal';
import { unsetBodyScrollOverflow } from '../hooks/useDisableBodyScroll';

const useStyles = makeStyles(theme => ({
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(
  {
    borderRadius: '20px !important',
    minWidth: '25px !important',
    fontSize: '18px !important',
    float: 'right',
    padding: '0px !important',
    cursor: 'pointer',
  },
  ({ iscenteralign }) => ({ float: iscenteralign === 'true' ? 'none' : 'right' })
);

const DeleteLastPhotoTitle = styled('div')({
  textAlign: 'center',
});

const DeleteLastPhotoHeadline = styled(Headline)({
  fontSize: 20,
});

const DeleteLastPhotoDescription = styled('div')({
  margin: '16px 0 24px',
  textAlign: 'center',
});

const GalleryMenu = (props: any): ReactElement => {
  const {
    theKey,
    isPending,
    hasPublicApprovedPhotos,
    isAvatar,
    setPrivacy,
    makeDefault,
    deletePhoto,
    isPrivate,
    singlePublicLeft,
    photoId,
    isCenterAlign,
    hasBulkSelection,
  } = props;
  const btnDisabled = (isPending && hasPublicApprovedPhotos) || isAvatar || isPrivate;
  const classes = useStyles();
  const [openGalleryMenuDropdown, setOpenGalleryMenuDropdown] = useState(false);
  const gallerMenuAnchorRef = useRef(null);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState(false);

  const toggleDeletePhotoModal = () => {
    setDeletePhotoModalOpen(!deletePhotoModalOpen);

    if (openGalleryMenuDropdown) {
      setOpenGalleryMenuDropdown(false);
    }
  };

  const handleToggle = () => {
    setOpenGalleryMenuDropdown(openGalleryMenuDropdown => !openGalleryMenuDropdown);
  };

  const handleClose = event => {
    if (gallerMenuAnchorRef.current && gallerMenuAnchorRef.current.contains(event.target)) {
      return;
    }

    setOpenGalleryMenuDropdown(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenGalleryMenuDropdown(false);
    }
  }

  const prevOpen = useRef(openGalleryMenuDropdown);
  useEffect(() => {
    if (prevOpen && prevOpen.current === true && openGalleryMenuDropdown === false) {
      gallerMenuAnchorRef.current.focus();
    }

    prevOpen.current = openGalleryMenuDropdown;
  }, []);

  return (
    <div className={classes.root}>
      <StyledButton
        iscenteralign={isCenterAlign.toString()}
        ref={gallerMenuAnchorRef}
        aria-controls={openGalleryMenuDropdown ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={hasBulkSelection}
      >
        <MoreHoriz style={{ color: hasBulkSelection ? 'rgba(13,92,147,0.9)' : '#fff' }} />
      </StyledButton>
      <Popper
        style={{ zIndex: 10 }}
        open={openGalleryMenuDropdown}
        anchorEl={gallerMenuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={openGalleryMenuDropdown} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={toggleDeletePhotoModal}>Delete Photo</MenuItem>
                  <MenuItem
                    onClick={e => {
                      e.preventDefault();
                      setPrivacy(photoId, theKey);

                      if (openGalleryMenuDropdown) {
                        setOpenGalleryMenuDropdown(false);
                      }
                    }}
                    disabled={!isPrivate && singlePublicLeft}
                  >
                    {`${isPrivate ? 'Make Public' : 'Make Private'}`}
                  </MenuItem>
                  <MenuItem
                    onClick={e => {
                      e.preventDefault();
                      makeDefault(photoId, theKey, setOpenGalleryMenuDropdown, prevOpen);

                      if (openGalleryMenuDropdown) {
                        setOpenGalleryMenuDropdown(false);
                      }
                    }}
                    disabled={btnDisabled}
                  >
                    Set Profile
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {!isPrivate && singlePublicLeft ? (
        <ConfirmationModal
          contentLabel="Delete Photo Modal"
          isOpen={deletePhotoModalOpen}
          cancelText="Cancel"
          confirmText="Delete"
          onClose={toggleDeletePhotoModal}
          onConfirm={() => {
            unsetBodyScrollOverflow();
            deletePhoto(photoId, theKey);
          }}
          buttonPosition="center"
        >
          <DeleteLastPhotoTitle>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
              <g fill="none" fillRule="evenodd">
                <path
                  fill="#1F85CF"
                  fillRule="nonzero"
                  d="M63.61 59.839L4.161.39a1.334 1.334 0 0 0-1.886 0L.39 2.276c-.521.52-.521 1.365 0 1.885l11.026 11.026A34.267 34.267 0 0 0 .064 31.593a1.34 1.34 0 0 0 0 .815C4.599 46.52 17.434 56 32 56a32.951 32.951 0 0 0 16.068-4.16l11.77 11.77c.521.52 1.365.52 1.886 0l1.885-1.886c.521-.52.521-1.365 0-1.885zM32 50.667c-10.293 0-18.667-8.374-18.667-18.667 0-4.052 1.333-7.96 3.733-11.164l5.797 5.798c-.96 1.61-1.53 3.43-1.53 5.366 0 5.882 4.786 10.667 10.667 10.667 1.936 0 3.756-.57 5.367-1.53l5.796 5.797A18.622 18.622 0 0 1 32 50.667zM30.714 22.162a1.354 1.354 0 0 0 .29 1.48l9.354 9.353a1.352 1.352 0 0 0 2.309-.972c-.068-5.928-4.763-10.622-10.69-10.69-.539.058-1.049.321-1.263.829z"
                />
                <path
                  fill="#1F85CF"
                  fillRule="nonzero"
                  d="M22.472 15.163c.4.395 1.01.496 1.518.257a18.666 18.666 0 0 1 8.026-1.812c10.288 0 18.657 8.258 18.657 18.41 0 2.742-.618 5.407-1.836 7.919a1.303 1.303 0 0 0 .26 1.497l4.62 4.558c.249.246.587.385.941.385a1.35 1.35 0 0 0 .948-.39 34.19 34.19 0 0 0 8.33-13.568c.085-.26.085-.543 0-.804-4.532-13.917-17.36-23.267-31.92-23.267-3.996 0-7.922.7-11.672 2.085a1.32 1.32 0 0 0-.835.953c-.097.437.04.893.36 1.209l2.603 2.568z"
                />
                <path d="M0 0h64v64H0z" />
              </g>
            </svg>
            <DeleteLastPhotoHeadline>Are you sure you want to delete this photo?</DeleteLastPhotoHeadline>
          </DeleteLastPhotoTitle>
          <DeleteLastPhotoDescription>
            If you have no public photos, you won&apos;t be seen in search and people and are less likely to interact
            with you.
          </DeleteLastPhotoDescription>
        </ConfirmationModal>
      ) : (
        <ConfirmationModal
          contentLabel="Delete Photo Modal"
          isOpen={deletePhotoModalOpen}
          header="Delete Photo"
          description="Are you sure? Deleting this picture will remove it from your profile."
          cancelText="Cancel"
          confirmText="Delete"
          onClose={toggleDeletePhotoModal}
          onConfirm={() => {
            unsetBodyScrollOverflow();
            deletePhoto(photoId, theKey);
          }}
        />
      )}
    </div>
  );
};

export default GalleryMenu;
