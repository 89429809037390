import React, { ReactElement } from 'react';
const CameraSmall = (): ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_852_5286"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_852_5286)">
        <path
          d="M9.99999 14.583C11.0693 14.583 11.9687 14.2187 12.698 13.49C13.4273 12.7607 13.792 11.868 13.792 10.812C13.792 9.75667 13.4273 8.86433 12.698 8.135C11.9687 7.40633 11.0693 7.042 9.99999 7.042C8.94466 7.042 8.05566 7.40633 7.33299 8.135C6.61099 8.86433 6.24999 9.75667 6.24999 10.812C6.24999 11.868 6.61099 12.7607 7.33299 13.49C8.05566 14.2187 8.94466 14.583 9.99999 14.583ZM3.41699 17.5C2.93099 17.5 2.51766 17.33 2.17699 16.99C1.83699 16.6493 1.66699 16.236 1.66699 15.75V5.896C1.66699 5.41 1.83699 4.99667 2.17699 4.656C2.51766 4.316 2.93099 4.146 3.41699 4.146H5.95799L6.95799 3.062C7.12466 2.882 7.31933 2.74333 7.54199 2.646C7.76399 2.54867 7.99999 2.5 8.24999 2.5H11.771C12.007 2.5 12.236 2.54867 12.458 2.646C12.6807 2.74333 12.8753 2.882 13.042 3.062L14.062 4.146H16.583C17.069 4.146 17.4823 4.316 17.823 4.656C18.163 4.99667 18.333 5.41 18.333 5.896V15.75C18.333 16.236 18.163 16.6493 17.823 16.99C17.4823 17.33 17.069 17.5 16.583 17.5H3.41699Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CameraSmall;
