import React, { useState, useEffect } from 'react';
import GalleryVideoUploaderPreview from './GalleryVideoUploaderPreview';
import GalleryUploaderModal from '../GalleryFileUploader/GalleryUploaderModal';
import VideoPreview from '../../VideoPreview';
import useCapturedThumbnails from '../../../../hooks/useCapturedThumbnails';
import { FileType, VideoListTypes } from '../../constants';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { IUploadSnackbarItem } from '../../../../reducers/types/uploadSnackbar';
import uuidv4 from 'uuid';
import { showUploadSnackbar } from '../../../../actions/uploadSnackbarActions';
import { galleryBulkUploadMedia } from '../../../../actions/galleryActions';
import GalleryLoadingMedia from '../components/GalleryLoadingMedia';

type StepsType = 'preview' | 'play_video';

interface IGalleryFileReview {
  files: FileType[];
  onClose: () => void;
  isOpen: boolean;
  existingUploadedMediaCount: number;
  uploadVideos: () => void;
  showSnackbar: (payload: IUploadSnackbarItem) => void;
  isProcessing: boolean;
}

const GalleryVideoUploaderContainer: React.FC<IGalleryFileReview> = ({
  onClose,
  isOpen,
  files,
  existingUploadedMediaCount,
  showSnackbar,
  uploadVideos,
  isProcessing,
}: IGalleryFileReview) => {
  const [activeFile, setActiveFile] = useState<any | null>(null);
  const [currentStep, setCurrentStep] = useState<StepsType>('preview');
  const [videos, setVideos] = useState<any>([]);

  const [processedVideos, isLoading] = useCapturedThumbnails(files);

  const sortVideos = (videos: VideoListTypes) => {
    return sortBy(videos, ['index']);
  };

  const reset = () => {
    setActiveFile(null);
    setCurrentStep('preview');
  };

  const onCancel = () => {
    const steps = {
      preview: () => {
        reset();
        onClose();
      },
      play_video: () => {
        reset();
      },
    };

    steps[currentStep]();
  };

  const onRemove = (selectedVideo: FileType) => {
    const clonedVideos = [...videos];
    const cleanVideos = clonedVideos.filter(video => video.index != selectedVideo.index);

    setVideos(sortVideos(cleanVideos));
  };

  const onToggleVisibility = (file: FileType) => {
    const updatedActiveFile = { ...file };
    updatedActiveFile.private = !updatedActiveFile.private;

    const clonedVideos = [...videos];
    const cleanVideos = clonedVideos.filter(video => video.index != file.index);

    setVideos(sortVideos([...cleanVideos, updatedActiveFile]));
  };

  useEffect(() => {
    if (processedVideos.length > 0) {
      setVideos(sortVideos(processedVideos));
    }
  }, [files, processedVideos, isLoading]);

  const onPlay = (file: any) => {
    setActiveFile(file.raw);
    setCurrentStep('play_video');
  };

  const onSubmit = async () => {
    for (const video of videos) {
      showSnackbar({
        id: uuidv4(),
        status: '',
        type: 'video',
        file: video,
      });
    }

    // start upload process
    uploadVideos();

    setActiveFile(null);
    onClose();
  };

  if (isProcessing || isLoading) {
    // to handle processing files and ongoing capture video screenshots
    return (
      <GalleryUploaderModal onClose={onClose} isOpen={true} title="Upload Videos">
        <GalleryLoadingMedia counter={files.length} mediaType="video" onCancel={onClose} />
      </GalleryUploaderModal>
    );
  }

  if (!videos || videos.length < 1) return null;

  return (
    <GalleryUploaderModal
      onClose={onCancel}
      isOpen={isOpen}
      contentLabel=""
      title={'Upload Videos'}
      description={activeFile && currentStep === 'play_video' && 'Preview video'}
    >
      {!activeFile && currentStep === 'preview' && (
        <GalleryVideoUploaderPreview
          onSubmit={onSubmit}
          files={videos}
          existingUploadedMediaCount={existingUploadedMediaCount}
          onClose={onClose}
          onPlay={onPlay}
          isLoading={isLoading}
          onRemove={onRemove}
          onToggleVisibility={onToggleVisibility}
        />
      )}

      {activeFile && currentStep === 'play_video' && (
        <VideoPreview
          videoFile={activeFile}
          onClosePreview={() => {
            setCurrentStep('preview');
            setActiveFile(null);
          }}
        />
      )}
    </GalleryUploaderModal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: payload => {
      dispatch(showUploadSnackbar(payload));
    },
    uploadVideos: () => {
      dispatch(galleryBulkUploadMedia('video'));
    },
  };
};

export default connect(null, mapDispatchToProps)(GalleryVideoUploaderContainer);
