import React, { useEffect, useState } from 'react';
import { MediaTypes } from '../../constants';
import GalleryPhotoUploaderContainer from '../Photo/GalleryPhotoUploaderContainer';
import GalleryVideoUploaderContainer from '../Video/GalleryVideoUploaderContainer';
import { validateFiles } from '../../helpers';
import useModal from '../../../../hooks/useModal';
import { preparePhotoMediaList } from '../Photo/helpers';
import GalleryLoadingMedia from '../components/GalleryLoadingMedia';
import GalleryUploaderModal from './GalleryUploaderModal';
import { MAX_NUMBER_PHOTOS } from '../../../../config/Master';
import useGalleryUploadErrorMessageModal from '../../hooks/useGalleryUploadErrorMessageModal';

interface IGalleryFileReview {
  files: File[];
  mediaType: MediaTypes;
  existingUploadedMediaCount: number;
  onClose: () => void;
  onUploadAgainClicked: () => void;
}

const GalleryFileUploadedContainer: React.FC<IGalleryFileReview> = ({
  onClose,
  files,
  mediaType,
  existingUploadedMediaCount,
  onUploadAgainClicked,
}: IGalleryFileReview) => {
  const { resetModal } = useModal();

  const confirmModalAction = (files: File[]) => {
    resetModal();

    if (files.length < 1) {
      onUploadAgainClicked();
      onClose();
    }
  };

  const cancelModalAction = () => {
    resetModal();
    onClose();
  };

  const { showTooManyFilesWarning, showInvalidFilesErrorModal } = useGalleryUploadErrorMessageModal({
    mediaType,
    confirmModalAction,
    cancelModalAction,
  });

  const [isProcessing, setIsProcessing] = useState(false);
  const [finalFiles, setFinalFiles] = useState<File[]>();
  const [invalidFilesCount, setInvalidFilesCount] = useState<number>();

  const validatedFiles = async () => {
    const { invalidFilesCount, validFiles, invalidFiles } = await validateFiles(mediaType, files);
    let finalFiles = validFiles;
    // For video files are setup in it's component
    // see GalleryVideoUploaderContainer.tsx
    finalFiles = validFiles;

    if (mediaType === 'photo') {
      finalFiles = ((await preparePhotoMediaList(validFiles)) as unknown) as FileList;
    }

    return { invalidFilesCount, invalidFiles, validFiles: finalFiles };
  };

  useEffect(() => {
    (async () => {
      setIsProcessing(true);

      const { invalidFilesCount, validFiles, invalidFiles } = await validatedFiles();
      const totalFilesCount = existingUploadedMediaCount + validFiles.length;

      setFinalFiles(validFiles);
      setIsProcessing(false);

      if (invalidFiles.length > 0) {
        showInvalidFilesErrorModal({ invalidFiles, validFiles });
        return;
      }

      if (totalFilesCount > MAX_NUMBER_PHOTOS) {
        showTooManyFilesWarning(validFiles);
        return;
      }

      setInvalidFilesCount(invalidFilesCount);
    })();
  }, [files]);

  useEffect(() => {
    if (typeof invalidFilesCount === 'undefined' || typeof finalFiles === 'undefined') {
      return;
    }

    setIsProcessing(false);
    if (invalidFilesCount > 0 && finalFiles) {
      showTooManyFilesWarning(finalFiles);
    }
  }, [finalFiles, invalidFilesCount]);

  if (isProcessing && mediaType === 'photo') {
    // only for photo loader
    return (
      <GalleryUploaderModal onClose={onClose} isOpen={true} title={'Upload Photos'}>
        <GalleryLoadingMedia counter={files.length} mediaType={mediaType} onCancel={onClose} />
      </GalleryUploaderModal>
    );
  }

  if (!finalFiles || finalFiles.length < 1) return null;

  return (
    <>
      {mediaType === 'photo' ? (
        <GalleryPhotoUploaderContainer
          existingUploadedMediaCount={existingUploadedMediaCount}
          isOpen={true}
          files={finalFiles}
          onClose={onClose}
        />
      ) : (
        <GalleryVideoUploaderContainer
          existingUploadedMediaCount={existingUploadedMediaCount}
          isOpen={true}
          files={finalFiles}
          onClose={onClose}
          isProcessing={isProcessing}
        />
      )}
    </>
  );
};

export default GalleryFileUploadedContainer;
