import React, { useState, ReactElement, useRef } from 'react';
import { css } from 'glamor';
import styled from '@emotion/styled';
import Button from '../../atoms/buttons/Button';
import { IsMobileViewPort } from '../../utils/helpers';
import { patternModal } from '../../style';

const CarouselContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: IsMobileViewPort ? 'unset' : 490,
  padding: IsMobileViewPort ? '10px 0' : '20px 0',
});

const CarouselStyled = styled('div')({
  position: 'relative',
});

const StyledList = styled('ul')({
  padding: 0,
  margin: 0,
  listStyleType: 'none',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 14,
});

const StyledCloseButton = styled('span')({
  color: '#0D5C93',
  textDecoration: 'underline',
  marginTop: 10,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'none',
  },
});

const ContentList = styled('ul')({
  listStyleType: 'disc',
  lineHeight: '24px',
  minHeight: 100,
  paddingLeft: IsMobileViewPort ? 20 : 'auto',
});

const ContentListItem = styled('li')({
  textAlign: 'left',
  paddingBottom: 10,
});

const ContentParagraphItem = styled('p')({
  paddingBottom: 10,
});

const slideStyle = {
  marginRight: 'auto',
  marginLeft: 'auto',
  maxWidth: 900,
  listStyleType: 'none',
  textAlign: 'center',
  display: 'none',
  minHeight: 200,
};

const slideStyleActive = {
  display: 'block',
};

const slideContent = {
  fontFamily: `'Open Sans', 'Trebuchet MS', sans-serif`,
  fontSize: 16,
  width: '90%',
  padding: '0 5%',
};

const ArrowStyled = styled('a')({
  position: 'absolute',
  top: '5%',
  display: 'block',
  color: '#111111',
  cursor: 'pointer',
  opacity: '0.75',
  transform: 'translateY(-50%)',
  transition: 'opacity .15s cubic-bezier(.4, 0, 1, 1)',
});

const arrowLeft = {
  left: 32,
};

const arrowRight = {
  right: 32,
};

const StyledIndicators = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: 20,
};

const StyledIndicatorsList = styled('li')({
  '&:nth-of-type(n + 2)': {
    marginLeft: 9,
  },
});

const indicatorStyle = {
  display: 'block',
  width: 10,
  height: 10,
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
  transition: 'opacity .15s cubic-bezier(.4, 0, 1, 1)',
  borderRadius: '50%',
  border: 'solid 1px #2B8FD7',
};

const indicatorStyleActive = {
  backgroundColor: '#2B8FD7',
};

const TitleContainer = styled('h3')({
  fontStyle: 'normal',
  fontWeight: 700,
  color: patternModal.titleColor,
  fontSize: IsMobileViewPort ? 22 : 28,
});

const SubTitle = styled('h4')({
  fontStyle: 'normal',
  fontWeight: 600,
  color: patternModal.headerColor,
  fontSize: IsMobileViewPort ? 16 : 20,
  margin: IsMobileViewPort ? 10 : 20,
});

const CarouselLeftArrow = onClick => {
  return (
    <ArrowStyled href="#" className={`${css(arrowLeft)}`} onClick={onClick}>
      {'<'}
    </ArrowStyled>
  );
};

const CarouselRightArrow = onClick => {
  return (
    <ArrowStyled href="#" className={`${css(arrowRight)}`} onClick={onClick}>
      {'>'}
    </ArrowStyled>
  );
};

const CarouselIndicator = (idx, activeIdx, onClick, clickable) => {
  return (
    <StyledIndicatorsList key={idx}>
      <a
        className={
          idx == activeIdx ? `${css({ ...indicatorStyle, ...indicatorStyleActive })}` : `${css(indicatorStyle)}`
        }
        style={{ cursor: clickable ? 'pointer' : 'default' }}
        onClick={clickable ? onClick : null}
      />
    </StyledIndicatorsList>
  );
};

const CarouselSlide = (idx, activeIdx, slide, isParagraphContent) => {
  const imgSrc = slide.icon;
  const imgComponent = slide?.iconComponent;
  const imgStyle = slide?.iconStyle ?? {};
  return (
    <li
      key={Math.floor(Math.random() * 999)}
      className={idx == activeIdx ? `${css({ ...slideStyle, ...slideStyleActive })}` : `${css(slideStyle)}`}
    >
      {imgSrc && (
        <img height={IsMobileViewPort ? 90 : 120} width={IsMobileViewPort ? 90 : 120} src={imgSrc} style={imgStyle} />
      )}
      {imgComponent && imgComponent}

      <TitleContainer>{slide.title}</TitleContainer>
      <div className={`${css(slideContent)}`}>
        {!isParagraphContent ? (
          <ContentList>
            {slide.content.map((item, index) => {
              return <ContentListItem key={index}>{item}</ContentListItem>;
            })}
          </ContentList>
        ) : (
          slide.content.map((item, index) => {
            return <ContentParagraphItem key={index}>{item}</ContentParagraphItem>;
          })
        )}
      </div>
      <SubTitle>{slide.subTitle}</SubTitle>
    </li>
  );
};

interface CarouselProps {
  slidesData: any;
  hideArrows: boolean;
  clickableIndicators: boolean;
  onClose: any;
  onCloseLabel: string;
  isFirstTimeOffer: boolean;
  isParagraphContent: boolean;
  customButtonContainerStyle: any;
}

const Carousel = ({
  slidesData = {},
  hideArrows = true,
  clickableIndicators = false,
  onClose = null,
  isFirstTimeOffer = false,
  onCloseLabel = 'Close',
  isParagraphContent = false,
  customButtonContainerStyle = {},
}: CarouselProps): ReactElement => {
  const [activeIndex, setActiveIndex] = useState(0);
  const closeBtnRef = useRef(null);

  const goToSlide = idx => {
    setActiveIndex(idx);
  };

  const goToPrevSlide = e => {
    e.preventDefault();

    let idx = activeIndex;
    const slidesLength = slidesData.length;

    if (idx < 1) {
      idx = slidesLength;
    }

    --idx;
    setActiveIndex(idx);
  };

  const goToNextSlide = e => {
    e.preventDefault();

    let idx = activeIndex;
    const slidesLength = slidesData.length - 1;

    if (idx === slidesLength) {
      return false;
      // idx = -1;
    }

    ++idx;
    setActiveIndex(idx);
  };

  const isLastSlide = activeIndex === slidesData.length - 1;
  return (
    <CarouselContainer>
      <CarouselStyled>
        {!hideArrows && <CarouselLeftArrow onClick={e => goToPrevSlide(e)} />}
        <StyledList>
          {slidesData.map((slide, index) => CarouselSlide(index, activeIndex, slide, isParagraphContent))}
        </StyledList>
        {!hideArrows && <CarouselRightArrow onClick={e => goToNextSlide(e)} />}

        <StyledList className={`${css(StyledIndicators)}`}>
          {slidesData.map((slide, index) =>
            CarouselIndicator(index, activeIndex, () => goToSlide(index), clickableIndicators)
          )}
        </StyledList>
        <ButtonsContainer style={customButtonContainerStyle}>
          <Button
            buttonRef={closeBtnRef}
            id="carouselNextBtn"
            buttonType="error"
            onClick={isLastSlide ? () => onClose(closeBtnRef) : e => goToNextSlide(e)}
            type="submit"
            data-test-id="carousel-next-btn"
            customStyle={{ padding: '0 28px' }}
          >
            {isLastSlide ? (isFirstTimeOffer ? 'Start Making Offers' : onCloseLabel) : 'Next'}
          </Button>
          {!isLastSlide && (
            <StyledCloseButton data-test-id="carousel-close-btn" onClick={onClose}>
              Close
            </StyledCloseButton>
          )}
        </ButtonsContainer>
      </CarouselStyled>
    </CarouselContainer>
  );
};

export default Carousel;
