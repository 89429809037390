import React, { useState, useEffect, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions } from '../style';
import ProfilePhoto from '../atoms/profile/ProfilePhoto';
import { auth10tixVerification } from '../config/Master';
import {
  DEFAULT_REASON,
  EVIDENTLY_FEATURES,
  EVIDENTLY_FEATURE_VARIATIONS,
  getFeatureDetails,
} from '../constants/evidently';
import ProfilePhotoSliderV2 from '../atoms/profile/ProfilePhotoSliderV2';
import OtherProfileCoverInfo from './OtherProfileCoverInfo';

const Cover = styled('div')({
  display: 'flex',
  // maxWidth: '702px',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },

  [dimensions.SCREEN_MAX_XS_2]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px',
    maxWidth: '100%',
  },
});

const InfoContainer = styled('div')({
  paddingLeft: '12px',
  flex: 3,
  zIndex: 3,
  maxWidth: '494px',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },
  [dimensions.SCREEN_MAX_XS_2]: {
    paddingLeft: '0px',
    margin: '0 15px',
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
  },
});

const PhotoWrapperDesktop = styled('div')({
  display: 'block',
  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'none',
  },
});

const PhotoWrapperMobile = styled('div')({
  display: 'none',
  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'block',
  },
});

const ProfileInfoDesktop = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: 0,

  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'none',
  },
});

const ProfileInfoMobile = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 50,
  zIndex: 3,
  width: '100%',
});

const PhotoContainer = styled('div')({
  flex: 1,
  maxWidth: '245px',
  minWidth: '245px',
  textAlign: 'center',
  [dimensions.SCREEN_MAX_XS_2]: {
    maxWidth: '100%',
  },
});

const CoverContent = styled('div')({
  margin: '0',

  [dimensions.SCREEN_MAX_XS_2]: {
    margin: '15px 0 0px',
  },
});

const OtherProfileCover = (props: any): ReactElement => {
  const {
    username,
    age,
    location,
    lastLoginTime,
    lastLoginLocation,
    profileImg,
    favorited,
    onClickFavorite,
    onClickPhoto,
    myPhotos,
    otherProfilePhotos,
    isOtherProfile,
    isVerified = false,
    offer,
    myProfile,
    settings,
  } = props;

  const photoList = isOtherProfile ? otherProfilePhotos : (myPhotos && myPhotos.data) || [];
  const [shouldShowFavoriteButton, setShouldShowFavoriteButton] = useState(true);

  useEffect(() => {
    if (!settings?.evidently) {
      return;
    }

    const featureDetails = getFeatureDetails(settings?.evidently, EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE);

    const { variation = EVIDENTLY_FEATURE_VARIATIONS.CONTROL, reason = DEFAULT_REASON } = featureDetails || {};

    if (featureDetails && reason != DEFAULT_REASON) {
      setShouldShowFavoriteButton(variation === EVIDENTLY_FEATURE_VARIATIONS.CONTROL);
    }
  }, [settings?.evidently]);

  const renderProfileInfo = () => {
    return (
      <OtherProfileCoverInfo
        age={age}
        isVerified={isVerified}
        username={username}
        favorited={favorited}
        lastLoginTime={lastLoginTime}
        onClickFavorite={onClickFavorite}
        auth10tixVerification={auth10tixVerification}
        location={location}
        lastLoginLocation={lastLoginLocation}
        shouldShowFavoriteButton={shouldShowFavoriteButton}
      />
    );
  };

  return (
    <Cover className="profile-cover">
      <PhotoWrapperDesktop>
        <PhotoContainer>
          <ProfilePhoto
            imgUrl={profileImg}
            onClick={onClickPhoto}
            offer={offer}
            myProfile={myProfile}
            isOtherProfile={true}
          />
        </PhotoContainer>
      </PhotoWrapperDesktop>
      <PhotoWrapperMobile>
        <ProfilePhotoSliderV2
          photoList={photoList}
          onClick={onClickPhoto}
          offer={offer}
          isOtherProfile={isOtherProfile}
          profileDetailsComponent={<ProfileInfoMobile>{renderProfileInfo()}</ProfileInfoMobile>}
        />
      </PhotoWrapperMobile>
      <InfoContainer className="profile-cover__info">
        <ProfileInfoDesktop>{renderProfileInfo()}</ProfileInfoDesktop>
        <CoverContent>{props.children}</CoverContent>
      </InfoContainer>
    </Cover>
  );
};

export default OtherProfileCover;
