import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import { SvgIcon } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';

const StyledCancelButton = styled(Button)({
  fontSize: 14,
  color: '#2B8FD7',
  fontWeight: 600,
  borderRadius: 90,
  border: '1px solid #2B8FD7',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  padding: 12,
  height: 32,
  cursor: 'pointer',
  lineHeight: '17px',
  ':hover': {
    backgroundColor: isDarkMode() ? 'transparent' : 'unset',
    color: isDarkMode() ? '#FFFFFF' : 'unset',
  },
});

const closeButton = {
  fontSize: 32,
  color: '#2B8FD7',
  cursor: 'pointer',
};

interface CancelButtonProps {
  handleCancel(): void;
  dataTestId: string;
  customStyle: any;
}

const CancelButton = ({ handleCancel, dataTestId, customStyle }: CancelButtonProps): ReactElement => {
  return (
    <StyledCancelButton
      data-test-id={dataTestId}
      onClick={handleCancel}
      customStyle={customStyle}
      buttonTextCustomStyle={
        getBrowserDetails().isMobile ? { display: 'flex', alignContent: 'center', alignItems: 'center' } : {}
      }
    >
      <SvgIcon component={CloseOutlined} style={closeButton} />
      Cancel
    </StyledCancelButton>
  );
};

CancelButton.defaultProps = {
  handleCancel: () => null,
  dataTestId: '',
  customStyle: {},
};

export default CancelButton;
