import { GATEWAY } from '../../utils/billingEnum';

export const SAVE_DATA_COLLECTOR = 'SAVE_DATA_COLLECTOR';
export const UPDATE_FINGER_PRINT_STATUS = 'UPDATE_FINGER_PRINT_STATUS';
export const UPDATE_FINGER_PRINT_START_TIME = 'UPDATE_FINGER_PRINT_START_TIME';
export const CLEAR_DDC_DATA = 'CLEAR_DDC_DATA';
export const UPDATE_DDC_IFRAME_LOADED = 'UPDATE_DDC_IFRAME_LOADED';

type FingerPrintStatus = 'TIME_OUT' | 'SUCCESS' | 'INVALID_RESPONSE';

export interface IDDCData {
  gateway: GATEWAY;
  ddcUrl: string;
  ddcReferenceId?: string;
  jwt: string;
  bin?: string;
  iFrameKey: string;
  shouldRenderIframe: boolean;
  isSaved: boolean;
  initialDdcReferenceId: string;
  status?: FingerPrintStatus;
  startedTime?: number;
  isSubmitted?: boolean;
  isIFrameLoaded?: boolean;
}

interface IDDCResponse {
  DDC_URL: string;
  DDC_REFERENCEID: string;
  JWT: string;
  PMT_BIN?: string;
  GATEWAY: GATEWAY;
}

interface IUpdateDDCIFrameLoadedAction {
  type: typeof UPDATE_DDC_IFRAME_LOADED;
  gateway: GATEWAY;
}

export interface IDDC {
  ddcData: IDDCData[];
  isAllFingerPrintSaved: boolean;
  initialIDs: Record<string, string>;
  bin: string;
}

interface IUpdateFingerPrintStatusPayload {
  gateway: GATEWAY;
  ddcReferenceId: string;
}

export interface ISaveDataCollectorPayload {
  ddcResponse: IDDCResponse[];
  bin?: string;
}

interface ISaveDataCollectorAction {
  type: typeof SAVE_DATA_COLLECTOR;
  payload: ISaveDataCollectorPayload;
}

interface IUpdateFingerPrintStatusAction extends IUpdateFingerPrintStatusPayload {
  type: typeof UPDATE_FINGER_PRINT_STATUS;
}

interface IClearDDCDataAction {
  type: typeof CLEAR_DDC_DATA;
}

interface IUpdateFingerPrintStartTimeAction {
  type: typeof UPDATE_FINGER_PRINT_START_TIME;
  gateway: GATEWAY;
}

export type DDCActionTypes =
  | ISaveDataCollectorAction
  | IUpdateFingerPrintStatusAction
  | IClearDDCDataAction
  | IUpdateFingerPrintStartTimeAction
  | IUpdateDDCIFrameLoadedAction;

export type SaveDataCollector = (payload: ISaveDataCollectorPayload) => ISaveDataCollectorAction;

export type UpdateFingerPrintStatus = (gateway: GATEWAY, ddcReferenceId?: string) => IUpdateFingerPrintStatusAction;

export type UpdateFingerPrintStartTime = (gateway: GATEWAY) => IUpdateFingerPrintStartTimeAction;

export type UpdateDDCIFrameLoaded = (gateway: GATEWAY) => IUpdateDDCIFrameLoadedAction;

export type ClearDDCData = () => IClearDDCDataAction;
