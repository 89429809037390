export default {
  APPLEPAY_CLI_CONF_ID: '', // internal unique id; do not change
  REQ_ID: '', // trace ID, used for troubleshooting
  CLIENT_ID: '', // your gateway api client_id
  INITIATIVE: 'web', // identifies e-commerce transaction
  DISPLAY_NAME: ' ', // Short, localized description of the merchant.
  // MERCH_IDENTIFIER: '63791-06607F83784E3D7CE0636F0F150A58B', //name of the merchant
  PARTNER_MERCH_NAME: '', //name of the merchant
  PARTNER_INTERNAL_MERCH_ID: '', // identifies the merchant to apple
  ENCRYPT_TO: '', // merchant ID to Apple
  DOMAIN_LIST: [
    // domain registered in inovio portal serving this page
    {
      DOMAIN_NAME: 'members-staging.whatsyourprice.com',
      DOMAIN_STATUS: '',
    },
  ],
  paymentRequest: {
    countryCode: 'US',
    currencyCode: 'USD',
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
    requiredBillingContactFields: ['postalAddress', 'email', 'name', 'phone'],
    total: {
      label: '', // required; a short, localized description of the line item
      type: 'final', // do not change
      amount: '0', // must be greater than or equal to zero }
    },
  },
  fetchUrl: 'https://api.inoviopay.com/apple-pay-services/api/session/create',
};
