import React, { useState, useEffect } from 'react';
import Container from '../../components/blocks/Container';
import styled from '@emotion/styled';
import OrderSummary from '../../atoms/billing/OrderSummary';
import CreditCardPanel from '../../atoms/billing/CreditCardPanel';
import PaymentDeclined from '../../atoms/billing/PaymentDeclined';
import BillingPackagesOld from '../../modules/billing/BillingPackagesOld';
import BillingPackagesStackedOld from '../../modules/billing/BillingPackagesStackedOld';
import BillingForm from '../../modules/billing/BillingForm';
import { dimensions, borders, textColor, backgroundTheme, textType } from '../../style';
import find from 'lodash/find';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import every from 'lodash/every';
import values from 'lodash/values';
import omit from 'lodash/omit';
import CreditCardSwitcher from '../../atoms/billing/CreditCardSwitcher';
import Button from '../../atoms/buttons/Button';
import PromotionalPackageContainer from '../../atoms/billing/PromotionalPackageContainer';
import Loading from '../../atoms/Loading';
import BillingHeader from '../../components/blocks/headerNav/BillingHeader';
import { getBrowserDetails, renderTaxLabel } from '../../utils/helpers';
import LockIcon from '@material-ui/icons/Lock';
import AfterPayment from '../../modules/billing/AfterPayment';
import BillingPaymentProcessor from '../../atoms/billing/BillingPaymentProcessor';
import { s3BucketDirect } from '../../config/Master';
import { useNavigate } from 'react-router-dom';

const TopTitle = styled('div')({
  color: backgroundTheme.greyText,
  fontSize: '22px',
  fontWeight: '600',
  textAlign: 'center',
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: '16px',
  },
  margin: '22px 0 6px 0',
});

const PackageContainer = styled('div')({
  margin: '36px auto',
  padding: '5px 0 5px 0',
  textAlign: 'center',
});

const FormPanel = styled('div')({
  maxWidth: '600px',
  margin: '0 auto',
  backgroundColor: backgroundTheme.block,
  border: borders.DEFAULT,
  borderRadius: '4px',
  [dimensions.SCREEN_MAX_SM]: {
    backgroundColor: 'transparent',
    border: 0,
  },
});

const BillingFormContainer = styled('div')({
  padding: getBrowserDetails().isMobile ? '24px 10px 16px 10px' : '24px 60px 16px 60px',
  maxWidth: 700,
  margin: '0 auto',
  border: '1px solid #24A83A',
  background: backgroundTheme.paymentBlock,
  borderRadius: 10,
});

const BillingFormHeader = styled('div')({
  fontSize: 16,
  ...textColor.DARKER,
  fontWeight: '600',
  paddingBottom: 10,
  marginBottom: 10,
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
});

const ProcessingHeader = styled('h3')({
  ...textType.processingHeader,
});

const VatContainer = styled('div')({
  margin: '0 auto',
  marginTop: '10px',
  color: '#797979',
  textAlign: 'center',
});

const PaymentCheckMobile = styled('img')({
  marginLeft: 'auto',
});

const PaymentSecureText = styled('span')({
  float: 'left',
});

const PaymentSecureTextMobile = styled('span')({
  marginRight: 'auto',
});

const PaymentCheck = styled('img')({
  float: 'left',
});

const CreditCardPanelMobile = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexBasis: '100%',
});

const setPromoPackagesVal = (promoCountdownPackages, altVal, prop = null) => {
  const promoPackageVal = prop ? promoCountdownPackages[0] && promoCountdownPackages[0][prop] : promoCountdownPackages;
  return promoCountdownPackages.length > 0 ? promoPackageVal : altVal;
};

const savedCardLazyEvaluator = (savedCard, output) => {
  return savedCard && output;
};

const billingFormHeader = () => {
  return <BillingFormHeader>Select Payment Method</BillingFormHeader>;
};

interface Card {
  id: number | string;
  token: string;
  card_type: string;
  last_four: string;
}

interface BillingorderProps {
  defaultCountry: string;
  savedCard?: Card;
  defaultPackageId: string;
  errors?: { data?: Record<string, never> };
  processingPayment: boolean;
  packages: Record<string, never>[];
  statuses?: Record<string, never>;
  descriptors?: (string | number)[];
  handleSubmit(data: Record<string, never>): void;
  handleDeleteSavedCard(tokenId: string): void;
  stackedVariant: boolean;
  stackedVariantNewPackage: boolean;
  history: History;
  emailPromo: boolean;
  profile: Record<string, never>;
}

const BillingOrder = ({
  defaultCountry,
  savedCard,
  defaultPackageId,
  errors,
  processingPayment,
  packages,
  statuses,
  descriptors,
  handleSubmit,
  handleDeleteSavedCard,
  stackedVariant,
  stackedVariantNewPackage,
  emailPromo,
  profile,
}: BillingorderProps): JSX.Element => {
  const [use2PayData, setUse2PayData] = useState({
    use2pay_package_id: '',
    use2pay_billing_name_on_card: '',
    use2pay_card_number: '',
    use2pay_card_cvv: '',
    use2pay_billing_address: '',
    use2pay_billing_city: '',
    use2pay_billing_state: '',
    use2pay_billing_zip: '',
    use2pay_billing_country: defaultCountry,
    use2pay_card_exp_month: null,
    use2pay_card_exp_year: null,
    save_card: true,
    new_card: true,
    showForm: false,
    payment_channel: null,
    showDeclinedPayment: false,
  });

  const selectedPackage = find(packages, { identifier: use2PayData.use2pay_package_id }) || packages[1];
  const promoPackages = filter(packages, { promotional: true, email_promo: !!emailPromo }) || null;
  const normalPackages = filter(packages, { promotional: false, email_promo: !!emailPromo }) || null;
  const promoCountdownPackages = reject(promoPackages, { expires_at: null });
  const promoCountdownExpiresAt = setPromoPackagesVal(promoCountdownPackages, null, 'expires_at');
  const isPromo = setPromoPackagesVal(promoCountdownPackages, promoPackages);
  const profileData = profile.profile.data;

  const navigate = useNavigate();

  useEffect(() => {
    setUse2PayData({
      use2pay_package_id: defaultPackageId,
      new_card: typeof savedCard === 'undefined',
      showForm: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!use2PayData.showForm && errors && typeof errors.data !== 'undefined') {
      setUse2PayData({
        showForm: true,
        use2pay_card_number: '',
        use2pay_card_cvv: '',
        use2pay_card_exp_month: null,
        use2pay_card_exp_year: null,
        showDeclinedPayment: true,
      });
    }
  }, [use2PayData, errors]);

  const handleSelectPackage = identifier => {
    const mySelectedPackage = { use2pay_package_id: identifier };

    setUse2PayData({
      ...use2PayData,
      ...mySelectedPackage,
    });
  };

  const handlePostalCodeChange = newAddress => {
    setUse2PayData({
      ...use2PayData,
      ...newAddress,
    });
  };

  const handleTextFieldChange = (id, value) => {
    const textInput = { [`use2pay_${id}`]: value };

    setUse2PayData({
      ...use2PayData,
      ...textInput,
    });
  };

  const handleUpdateMonthYrInput = (mth, yr) => {
    const cardMthYr = {
      use2pay_card_exp_month: mth,
      use2pay_card_exp_year: yr,
    };

    setUse2PayData({
      ...use2PayData,
      ...cardMthYr,
    });
  };

  const handleCountryChange = value => {
    const billingCountry = { use2pay_billing_country: value };

    setUse2PayData({
      ...use2PayData,
      ...billingCountry,
    });
  };

  const handleSelectSaveCard = () => {
    const saveCard = { save_card: !use2PayData.save_card };

    setUse2PayData({
      ...use2PayData,
      ...saveCard,
    });
  };

  const handleChangePaymentOption = (paymentChannel = null) => {
    const newCard = {
      new_card: paymentChannel === 'sofort' ? false : !use2PayData.new_card,
      payment_channel: paymentChannel,
    };

    setUse2PayData({
      ...use2PayData,
      ...newCard,
    });
  };

  const hideBillingForm = () => {
    const showForm = { showForm: false };

    setUse2PayData({
      ...use2PayData,
      ...showForm,
    });
  };

  if ((processingPayment || !use2PayData.showForm) && (statuses && Object.keys(statuses).length) <= 0) {
    return (
      <div
        data-test-id="cy-processing-payment"
        style={{
          textAlign: 'center',
          margin: `${getBrowserDetails().isMobile ? '50%' : '180px'} auto 0`,
          maxWidth: 350,
        }}
      >
        <Loading width={80} />
        <ProcessingHeader>Processing payment...</ProcessingHeader>
        <p>
          Please wait a moment as we authorize your payment. You may be asked for additonal verification from your card
          issuer. If so, please return after providing it.
        </p>
      </div>
    );
  }

  const renderSummaryHeader = () => {
    return getBrowserDetails().isMobile ? (
      <BillingFormHeader data-test-id="billing-form-header">
        <PaymentCheckMobile
          src={`${s3BucketDirect}payment/payment_check.png`}
          width="32"
          height="32"
          alt="Secure Payment Details"
        />{' '}
        <PaymentSecureTextMobile>Secure Payment Details</PaymentSecureTextMobile>
        <CreditCardPanelMobile>
          <CreditCardPanel />
        </CreditCardPanelMobile>
      </BillingFormHeader>
    ) : (
      <BillingFormHeader data-test-id="billing-form-header">
        <PaymentCheck
          src={`${s3BucketDirect}payment/payment_check.png`}
          width="32"
          height="32"
          alt="Secure Payment Details"
        />{' '}
        <PaymentSecureText>Secure Payment Details</PaymentSecureText> <CreditCardPanel />
      </BillingFormHeader>
    );
  };

  return (
    <Container size="MD" style={{ marginBottom: '20px' }}>
      {getBrowserDetails().isMobile ? (
        <BillingHeader label="Buy Credits" onBackClick={() => navigate('/dashboard')} />
      ) : (
        <BillingHeader label="Buy Credits" />
      )}
      <TopTitle> Credits never expire. Buy them to unlock conversations and go on first dates!</TopTitle>
      <PackageContainer>
        <PromotionalPackageContainer isBilling countDown={promoCountdownExpiresAt} />
        {stackedVariant ? (
          <BillingPackagesStackedOld
            packages={normalPackages}
            selected={selectedPackage?.identifier}
            selectPackage={handleSelectPackage}
            withNewPackage={stackedVariantNewPackage}
            promoPackages={isPromo}
          />
        ) : (
          <BillingPackagesOld
            packages={normalPackages}
            selected={selectedPackage?.identifier}
            selectPackage={handleSelectPackage}
            withNewPackage={stackedVariantNewPackage}
            promoPackages={isPromo}
          />
        )}

        {selectedPackage.taxes && (
          <VatContainer>
            <div>Prices exclude VAT ({`${selectedPackage.taxes.location} ${selectedPackage.taxes.rate * 100}%`})</div>
          </VatContainer>
        )}
      </PackageContainer>
      <FormPanel data-test-id="payment-form-panel">
        {use2PayData.showDeclinedPayment && <PaymentDeclined />}
        <BillingFormContainer data-test-id="payment-form-container">
          {/* If has saved card, show switcher */}
          {renderSummaryHeader()}
          {savedCardLazyEvaluator(savedCard, billingFormHeader())}
          {savedCard ? (
            <CreditCardSwitcher
              savedCardId={savedCard.id}
              savedCardToken={savedCard.token}
              savedCardType={savedCard.card_type}
              savedCardLastFour={savedCard.last_four}
              onRadioButtonChangeHandler={handleChangePaymentOption}
              newCard={use2PayData.new_card}
              handleDeleteCard={handleDeleteSavedCard}
              new_card={use2PayData.new_card}
              paymentChannel={use2PayData.payment_channel}
              currencyCode={use2PayData.selectedPackage.currency.data.code}
              use2PayData={use2PayData}
              handleTextFieldChange={handleTextFieldChange}
              handlePostalCodeChange={handlePostalCodeChange}
              handleUpdateMonthYrInput={handleUpdateMonthYrInput}
              handleCountryChange={handleCountryChange}
              handleSubmit={handleSubmit}
              handleSelectSaveCard={handleSelectSaveCard}
              descriptors={descriptors}
              profileData={profileData}
              statuses={statuses}
              savedCard={savedCard}
            />
          ) : null}
          {/* If existing card selected, render purchase button else show billing form */}
          {savedCardLazyEvaluator(savedCard, !use2PayData.new_card) ? (
            <div style={{ marginBottom: '20px' }}>
              <OrderSummary
                price={selectedPackage.price}
                credits={selectedPackage.credits}
                currency={selectedPackage.currency}
                currencyCharCode={selectedPackage.currency.data.label_unicode}
                currencyCode={selectedPackage.currency.data.code}
                vatDetails={selectedPackage.taxes}
                userCountry={profileData.country}
              />
              <Button
                buttonType="primary"
                onClick={() => {
                  hideBillingForm();
                  handleSubmit({
                    use2pay_package_id: selectedPackage?.identifier,
                    use2pay_card_reference: savedCard.token,
                  });
                }}
                fit
              >
                <LockIcon style={{ fontSize: 16, paddingRight: 3 }} />
                {` Pay ${String.fromCharCode(selectedPackage.currency.data.label_unicode)}`}
                {renderTaxLabel(selectedPackage)}
              </Button>
              <BillingPaymentProcessor userCountry={profileData.country} />
              <AfterPayment descriptors={descriptors} />
            </div>
          ) : (
            <BillingForm
              nameOnCard={use2PayData.use2pay_billing_name_on_card}
              cardNumber={use2PayData.use2pay_card_number}
              cardCVV={use2PayData.use2pay_card_cvv}
              billingAddress={use2PayData.use2pay_billing_address}
              billingCity={use2PayData.use2pay_billing_city}
              billingState={use2PayData.use2pay_billing_state}
              billingZip={use2PayData.use2pay_billing_zip}
              billingCountry={use2PayData.use2pay_billing_country}
              cardExpMonth={use2PayData.use2pay_card_exp_month}
              cardExpYear={use2PayData.use2pay_card_exp_year}
              onInputChange={handleTextFieldChange}
              onAddressChange={handlePostalCodeChange}
              handleUpdateMonthYrInput={handleUpdateMonthYrInput}
              handleCountryChange={handleCountryChange}
              disablePurchase={!every(values(omit(use2PayData, ['save_card', 'new_card'])), v => Boolean(v))}
              handleSubmit={() => {
                hideBillingForm();
                handleSubmit(omit(use2PayData, ['new_card']));
              }}
              status={statuses}
              onSelectSaveCard={handleSelectSaveCard}
              saveCard={use2PayData.save_card}
              totalAmount={selectedPackage.price}
              currencyCharCode={selectedPackage.currency.data.label_unicode}
              newCard={savedCardLazyEvaluator(savedCard, use2PayData.new_card)}
              selectedPackage={selectedPackage}
              descriptors={descriptors}
              userCountry={profileData.country}
            />
          )}
        </BillingFormContainer>
      </FormPanel>
    </Container>
  );
};

export default BillingOrder;
