import React from 'react';
import {
  ButtonLinkIconStyle,
  DeactivateAccountButton,
  DeactivateAccountContainer,
  DeactivateAccountContent,
  DeactivateAccountContentLink,
  DeactivateAccountTitle,
  DeleteAccountButton,
} from './DeactivateAccountStyles';
import { Close, VisibilityOff } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import MasterConfig from '../../../../../config/Master';
import { useApp } from '../../../../../contexts/AppContext';
import { handleDeactivateAccountVysionEvent } from '../../../../../utils/vysion/deactivateAccount';
import { DeactivateAccountClickVysionEventsEnum } from '../../../../../utils/vysion/deactivateAccount/click';

interface DeactivateAccountProps {
  isGenerous: boolean;
}

const DeactivateAccount = ({ isGenerous }: DeactivateAccountProps): React.ReactElement => {
  const { setForceResetTabs } = useApp();

  return (
    <>
      <DeactivateAccountContainer>
        <DeactivateAccountTitle>Need a break?</DeactivateAccountTitle>
        <DeactivateAccountContent>
          <p>{`We totally get it. Sometimes you just need to take a breather or enjoy the company of someone you hopefully just met on WhatsYourPrice. Whatever the reason, deactivating your account is the way to go and here’s why:`}</p>
          <ul>
            <li>Your profile and photos will be retained but be hidden from the site.</li>
            {isGenerous && <li>Any unused credits will be retained</li>}
            <li>Members you’ve previously messaged will still be able to read those messages.</li>
            <li>When you’re ready to come back, simply reactivate your profile and pick up where you left off.</li>
            <li>Reactivating is easy. Just log back in at any time.</li>
          </ul>
        </DeactivateAccountContent>
        <DeactivateAccountButton
          to={'/deactivate'}
          onClick={() => {
            handleDeactivateAccountVysionEvent({
              event: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DEACTIVATE_MY_ACCOUNT,
            });
            setForceResetTabs(prev => prev + 1);
          }}
        >
          <SvgIcon component={VisibilityOff} style={ButtonLinkIconStyle} />
          Deactivate My Account
        </DeactivateAccountButton>
      </DeactivateAccountContainer>

      <DeactivateAccountContainer>
        <DeactivateAccountTitle>{`I'm good, lets delete my account`}</DeactivateAccountTitle>
        <DeactivateAccountContent>
          <p>No worries, please note the following:</p>
          <ul>
            <li>THIS IS PERMANENT. All your data will be deleted including your profile and payment information.</li>
            {isGenerous && <li>Any unused credits will be lost.</li>}
            <li>If you want to use WhatsYourPrice again, you’ll need to create a whole new account and profile.</li>
            <li>
              Read more about how account deletion works in our{' '}
              <DeactivateAccountContentLink href={`${MasterConfig.FULL_URL}/faq`} target="_blank">
                FAQ
              </DeactivateAccountContentLink>
              .
            </li>
          </ul>
        </DeactivateAccountContent>
        <DeleteAccountButton
          to={`/gdpr-delete`}
          onClick={() => {
            handleDeactivateAccountVysionEvent({
              event: DeactivateAccountClickVysionEventsEnum.ACCOUNT_MENU_DEACTIVATE_ACCOUNT_DELETE_MY_ACCOUNT,
            });
            setForceResetTabs(prev => prev + 1);
          }}
        >
          <SvgIcon component={Close} /> <span>Delete My Account</span>
        </DeleteAccountButton>
      </DeactivateAccountContainer>
    </>
  );
};

export default DeactivateAccount;
