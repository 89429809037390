import React, { useState, useEffect, ReactElement, useRef } from 'react';
import styled from '@emotion/styled';
import { dimensions } from '../style';
import { extractLocationData, geocodeByPlaceId, normalizeLocationDisplay, renderAvatar } from '../common';
import EditableProfileCover from '../atoms/EditableProfileCover';
import ProfilePhoto from '../atoms/profile/ProfilePhoto';
import { ternaryFunc } from '../utils/helpers';
import { auth10tixVerification, tomtomEnabled } from '../config/Master';
import ProfileCompletionProgress from '../components/blocks/ProfileCompletionProgress';
import ProfilePhotoSliderV2 from '../atoms/profile/ProfilePhotoSliderV2';
import MyProfileCoverInfo from './MyProfileCoverInfo';

const Cover = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },

  [dimensions.SCREEN_MAX_XS_2]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px',
    maxWidth: '100%',
  },
});

const InfoContainer = styled('div')({
  paddingLeft: '12px',
  flex: 3,
  zIndex: 3,
  maxWidth: '494px',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'space-between',

  [dimensions.SCREEN_MAX_LG]: {
    maxWidth: '100%',
  },
  [dimensions.SCREEN_MAX_XS_2]: {
    paddingLeft: '0px',
    margin: '0 15px',
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
  },
});

const PhotoWrapperDesktop = styled('div')({
  display: 'block',
  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'none',
  },
});

const PhotoWrapperMobile = styled('div')({
  display: 'none',
  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'block',
  },
});

const ProfileInfoDesktop = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '5px 0',

  [dimensions.SCREEN_MAX_XS_2]: {
    display: 'none',
  },
});

const ProfileInfoMobile = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 50,
  zIndex: 2,
  width: '100%',
  [dimensions.SCREEN_MIN_XS_2]: {
    maxWidth: 285,
  },
  [dimensions.SCREEN_MIN_XS_4]: {
    maxWidth: 275,
  },
});

const PhotoContainer = styled('div')({
  flex: 1,
  maxWidth: '245px',
  minWidth: '245px',
  textAlign: 'center',
  [dimensions.SCREEN_MAX_XS_2]: {
    maxWidth: '100%',
  },
});

const CoverContent = styled('div')({
  margin: 0,
});

const BaseCover = props => {
  const {
    className,
    age,
    location,
    lastLoginTime,
    lastLoginLocation,
    profileImg,
    editable,
    onClickPhoto,
    onChangeAddress,
    onSelectLocation,
    handleSubmit,
    handleCancel,
    updateUsername,
    displayName,
    myLocation,
    editProfileCover,
    toggleEditable,
    errors,
    myPhotos,
    isVerified = false,
    offer,
    myProfile,
    isUpdatingProfileCover,
  } = props;

  const defaultPhotoList = [
    {
      order: 1,
      url: profileImg,
      type: 'photo',
      status: 'default',
      private: false,
      privateReal: false,
      total: 0,
    },
  ];

  const photoList = myPhotos?.data?.length ? myPhotos.data : defaultPhotoList;
  const [freshLocation, setFreshLocation] = useState(myLocation);

  useEffect(() => {
    const { account } = props;

    if (account) {
      const { profile } = account || {};
      const locationFromProps = normalizeLocationDisplay(
        account.city ? account.city : profile?.data?.city,
        account.region ? account.region : profile?.data?.region,
        account.country ? account.country : profile?.data?.country
      );

      setFreshLocation(locationFromProps);
    }
  }, [props.account]);

  const setMyCurrLocation = () => {
    const evaluator = age && editable;
    const output1 = myLocation !== '' && ` • `;
    const output2 = location && ` • `;

    return ternaryFunc(evaluator, output1, output2);
  };

  const renderProfileInfo = () => {
    return (
      <MyProfileCoverInfo
        displayName={displayName}
        isVerified={isVerified}
        lastLoginTime={lastLoginTime}
        age={age}
        auth10tixVerification={auth10tixVerification}
        freshLocation={freshLocation}
        lastLoginLocation={lastLoginLocation}
        myLocation={myLocation}
        setMyCurrLocation={setMyCurrLocation}
        toggleEditable={toggleEditable}
        editable={editable}
      />
    );
  };

  return (
    <Cover className={className}>
      <PhotoWrapperDesktop>
        <PhotoContainer>
          <ProfilePhoto
            imgUrl={profileImg}
            onClick={onClickPhoto}
            offer={offer}
            myProfile={myProfile}
            isOtherProfile={false}
          />
        </PhotoContainer>
      </PhotoWrapperDesktop>
      <PhotoWrapperMobile>
        <ProfilePhotoSliderV2
          photoList={photoList}
          onClick={onClickPhoto}
          offer={offer}
          isOtherProfile={false}
          profileDetailsComponent={<ProfileInfoMobile>{!editProfileCover && renderProfileInfo()}</ProfileInfoMobile>}
        />
      </PhotoWrapperMobile>

      {editProfileCover || isUpdatingProfileCover ? (
        <EditableProfileCover
          onChangeAddress={onChangeAddress}
          onSelectLocation={onSelectLocation}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          updateUsername={updateUsername}
          displayName={displayName}
          myLocation={myLocation}
          errors={errors}
          myProfile={myProfile}
        />
      ) : (
        <InfoContainer className="profile-cover__info">
          <ProfileInfoDesktop>{renderProfileInfo()}</ProfileInfoDesktop>
          {props.children}
        </InfoContainer>
      )}
    </Cover>
  );
};

const MyProfileCover = (props: any): ReactElement => {
  const {
    account,
    username,
    updateProfileCover,
    clearIndividualError,
    profileImgUrl,
    editable,
    isMigration,
    onConfirmIDV,
    onContinueIDV,
    isUpdatingProfileCover,
    resetDiscoverModeAccounts,
  } = props;
  const [editProfileCover, setEditProfileCover] = useState(false);
  const [displayName, setDisplayName] = useState(username);
  const [myLocation, setMyLocation] = useState('');
  const initialLocation = useRef('');
  const [, setIsSubmitted] = useState(false);
  const updatedFields = useRef({
    username: false,
    location: false,
  });
  const [updatedLocation, setUpdatedLocation] = useState(undefined);
  const { profile } = account ? account : {};

  const [myPhoto, setPhoto] = useState({});

  useEffect(() => {
    if (isUpdatingProfileCover) return;

    profile &&
      setMyLocation(
        getLocationDisplay(
          account.city ? account.city : profile.data.city,
          account.region ? account.region : profile.data.region,
          account.country ? account.country : profile.data.country
        )
      );
  }, [account, profile, isUpdatingProfileCover]);

  useEffect(() => {
    if (myLocation && !initialLocation.current) {
      initialLocation.current = myLocation;
    }
  }, [myLocation]);

  useEffect(() => {
    setPhoto({ ...profileImgUrl });
  }, [profileImgUrl]);

  const toggleEditable = e => {
    e.preventDefault();
    setEditProfileCover(!editProfileCover);
  };

  const locationSelectHandler = (address, placeId, details = null) => {
    if (tomtomEnabled && details !== null) {
      setUpdatedLocation(details);
      setMyLocation(address);
    } else {
      geocodeByPlaceId(placeId)
        .then(results => {
          const loc = results[0];

          if (loc && loc.geometry) {
            setUpdatedLocation(loc, address);
            setMyLocation(address);
          }
        })
        .catch(error => {
          console.error('Issue with Geocoding: ', error);
        });
    }

    handleLocationFieldChange(address);
  };

  const handleUpdatedField = (field: 'username' | 'location', value = true) => {
    updatedFields.current = {
      ...updatedFields.current,
      [field]: value,
    };
  };

  const handleResetUpdatedFields = () => {
    updatedFields.current = {
      username: false,
      location: false,
    };
    initialLocation.current = '';
  };

  const handleLocationFieldChange = address => {
    const isUpdated = address !== initialLocation.current;

    handleUpdatedField('location', isUpdated);
  };

  const updateMyLocation = address => {
    handleLocationFieldChange(address);
    setMyLocation(address);
  };

  useEffect(() => {
    const isUpdated = displayName !== username;

    handleUpdatedField('username', isUpdated);
  }, [displayName, username]);

  const updateMyUsername = e => {
    clearIndividualError(e.target.id);
    setDisplayName(e.target.value);
  };

  const postSubmit = e => {
    document.getElementById('autocompleteLocation').value = '';

    toggleEditable(e);
    handleResetUpdatedFields();
  };

  const handleSubmit = async e => {
    setIsSubmitted(true);

    if (updatedLocation !== undefined) {
      const newLocation = extractLocationData(updatedLocation);
      const currentCountry = account?.country ?? profile?.data?.country;
      const currentCity = account?.city ?? profile?.data?.city;

      await updateProfileCover({
        username: displayName,
        city: newLocation.city,
        country: newLocation.country,
        region: newLocation.region,
        latitude: newLocation.latitude,
        longitude: newLocation.longitude,
        updatedFields: updatedFields.current,
      }).then(() => {
        postSubmit(e);

        if (newLocation.country !== currentCountry || newLocation.city !== currentCity) {
          resetDiscoverModeAccounts();
        }
      });
    } else {
      updatedFields.current.location = false;

      await updateProfileCover({
        username: displayName,
        city: account.city ? account.city : profile.data.city,
        country: account.country ? account.country : profile.data.country,
        region: account.region ? account.region : profile.data.region,
        updatedFields: updatedFields.current,
      }).then(() => postSubmit(e));
    }
  };

  const handleCancel = e => {
    // need to clear the fields if user select cancel button
    toggleEditable(e);
    setDisplayName(username);
    handleResetUpdatedFields();
    setUpdatedLocation(undefined);

    setMyLocation(
      getLocationDisplay(
        account.city ? account.city : profile.data.city,
        account.region ? account.region : profile.data.region,
        account.country ? account.country : profile.data.country
      )
    );
  };

  const getLocationDisplay = (city, region, country) => {
    return normalizeLocationDisplay(city, region, country);
  };

  return (
    <BaseCover
      className="my-profile-cover"
      onChangeAddress={updateMyLocation}
      onSelectLocation={locationSelectHandler}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      updateUsername={updateMyUsername}
      displayName={displayName}
      myLocation={myLocation}
      editProfileCover={editProfileCover}
      toggleEditable={toggleEditable}
      profileImg={renderAvatar(myPhoto)}
      editable={editable}
      isMigration={isMigration}
      onConfirmIDV={onConfirmIDV}
      onContinueIDV={onContinueIDV}
      {...props}
    >
      <CoverContent>
        <ProfileCompletionProgress type="complete" onboarding={account} profilev2 />
      </CoverContent>
    </BaseCover>
  );
};

export default MyProfileCover;
