import React, { useEffect, memo, useState } from 'react';
import styled from '@emotion/styled';
import ModalWithPattern from '../../common/modals/ModalWithPattern';
import { IsMobileViewPort } from '../../../utils/helpers';

const DashboardDiscoverConfirmationModalContainer = styled('div')({
  display: 'initial',
});

const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const mobileContentStyle = {
  borderRadius: 10,
  maxHeight: 600,
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

interface DashboardDiscoverConfirmationModalProps {
  showConfirmation: boolean;
  onClose?(): void;
  children: any;
}

const DashboardDiscoverConfirmationModal = ({
  showConfirmation = false,
  onClose = () => null,
  children,
}: DashboardDiscoverConfirmationModalProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(showConfirmation);

    () => {
      setShowModal(false);
    };
  }, [showConfirmation]);

  const handleCloseModal = () => {
    setShowModal(false);
    onClose?.();
  };

  return (
    <DashboardDiscoverConfirmationModalContainer>
      <ModalWithPattern
        dataTestID="discover-confirmation-modal"
        isModalOpen={showModal}
        modalContentLabel="Discover Confirmation Modal"
        closeOnOverlayClick
        closeRequest={handleCloseModal}
        closeBtnClassName="discover-confirmation-close"
        closeBtnDataTestId="discover-confirmation-close"
        onCloseClick={handleCloseModal}
        customOverlayStyle={IsMobileViewPort ? mobileOverlayStyle : {}}
        customContentStyle={IsMobileViewPort ? mobileContentStyle : {}}
        customCloseIconStyle={{
          fontSize: 24,
        }}
        customCloseBtnStyle={{
          top: 20,
        }}
      >
        {children}
      </ModalWithPattern>
    </DashboardDiscoverConfirmationModalContainer>
  );
};

export default memo(DashboardDiscoverConfirmationModal);
