/**
 * @Author: zachalam
 * @Date:   2017-01-19T17:15:26-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-07-27T17:33:36-07:00
 */

import React, { useCallback, useEffect, useState } from 'react';
import Profile from '../components/pages/Profile/Profile';
import { connect } from 'react-redux';
import {
  refreshExternalProfile,
  toggleBlockProfile,
  toggleBlockInStore,
  updateProfileContent,
  refreshProfile,
  updateProfileCover,
  updateProfileAbout,
  updateProfileOpeningOffer,
  updateProfileInterest,
  updateProfileDescription,
  updateProfileSeeking,
  clearPendingPhotosUpload,
  addNewPhoto,
  setNewPrivacy,
  makePhotoDefault,
  deletePhoto,
  removePhotofromProfile,
  removeExternalProfile,
  fetchPageNux,
  updateNuxGuideStates,
  updateShowTutorialOffer,
  updateShowWishlist,
  skipNuxGuides,
  updateShowGiftSent,
  updateVideoStatus,
  setGalleryLoading,
  killGalleryLoading,
} from '../actions/profileActions';
import { filter, find } from 'lodash';
import { storeProfileFavChange, favToggle } from '../actions/favActions';
import {
  acceptOffer,
  ignoreOffer,
  sendSuggestion,
  createNewOffer,
  counterOffer,
  unlockOffer,
  updateOffer,
} from '../actions/offerActions';
import { clearNotificationMessage, displayFailMessage, displayWarnMessage } from '../actions/notificationActions';
import { clearIndividualError, clearDirtyStatus, clearErrors } from '../actions/commonActions';
import { withPusher } from '../contexts/PusherContext';
import { PUSHER } from '../config/constants';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import { addNewMedia, bulkDelete, bulkTogglePrivacy, deleteMedia, setNewMediaPrivacy } from '../actions/galleryActions';
import { unlockOutsideMessage } from '../actions/mailActions';
import useMixPanel from '../hooks/useMixPanel';
import useUnmount from '../hooks/useUnmount';
import { EVENT_NAMES } from '../constants/mixpanel';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ProfileProvider } from '../contexts/ProfileContext';
import { mixpanelTrackProfileClosed } from '../utils/mixpanel/profileClosed';
import { mixpanelTrackPushNotifClickedAfterRedirection } from '../utils/mixpanel/pushNotifClicked';
import { resetDiscoverModeAccountsAction } from '../actions/discoverModeActions';

const ProfileContainer = props => {
  const [, setCurrentProfileHashId] = useState(null);
  const { mixpanelData, resetMixpanelData, trackPackagesPageVisited, trackProfileView, trackPageView } = useMixPanel();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.hashId !== props.profile.hash_id) {
      props.removeExternal();
    }
  }, [params.hashId]);

  useUnmount(() => {
    if (props.myprofile.hash_id === props.profile.hash_id) {
      trackPageView(false, 'menu_edit_profile');
    } else {
      trackPageView(false, 'view_member_profile');
    }
  });

  useUnmount(() => {
    if (props.myprofile.hash_id !== props.profile.hash_id) {
      trackProfileView(mixpanelData[EVENT_NAMES.PROFILE_VIEW], false);
      trackPageView(false, 'view_member_profile');
      mixpanelTrackProfileClosed(mixpanelData, resetMixpanelData);
    }
  });

  useEffect(() => {
    if (location) {
      mixpanelTrackPushNotifClickedAfterRedirection(location);
    }
  }, [location]);

  useEffect(() => {
    const {
      refreshMyProfile,
      bindListener,
      myprofile,
      stopListening,
      errors,
      clearEachIndividualError,
      refreshGlobalNotification,
    } = props;

    window.scrollTo(0, 0);
    setCurrentProfileHashId(myprofile.hash_id);
    refreshMyProfile();

    bindListener(listen);

    if (typeof errors === 'object') {
      const errorKeys = Object.keys(errors);

      if (errorKeys.length > 0) {
        errorKeys.map(key => {
          clearEachIndividualError(key);
        });
      }
    }

    return () => {
      stopListening(listen);
      refreshGlobalNotification();
      resetMixpanelData(EVENT_NAMES.OFFER_MADE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // redirect to own profile if no hashID present in the url
    const urlPath = location.pathname;
    const noHashIdUrl = urlPath === '/profile' || urlPath === '/profile/';
    if (props.profile.notFound && props.myprofile.hash_id !== null && noHashIdUrl) {
      navigate(`/profile/${props.myprofile.hash_id}`);
      return;
    }
  }, [props.profile.notFound, props.myprofile.hash_id]);

  useEffect(() => {
    // redirect to own profile with RG wishlist if no hashID present in the url
    const urlPath = location.pathname;
    const urlSearch = location.search;
    const urlHash = location.hash;

    const noWishlistHashIdUrl = urlPath === '/profile/wishlist' || urlPath === '/profile/wishlist/';
    if (props.profile.notFound && props.myprofile.hash_id !== null && noWishlistHashIdUrl) {
      navigate(`/profile/${props.myprofile.hash_id}/wishlist${urlSearch}${urlHash}`);
      return;
    }
  }, [props.profile.notFound, props.myprofile.hash_id]);

  useEffect(() => {
    const { refreshExternal, myprofile } = props;

    refreshExternal(params.hashId);
    setCurrentProfileHashId(myprofile.hash_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.hashId]);

  const listen = () => {
    const { onRealtimeEvent, updateMyProfileContent } = props;
    const { EVENTS } = PUSHER;

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      if (data.type === 'video-event') {
        if (data.data && data.data.video && data.data.video.id && data.data.video.status) {
          props.updateVideoStatus(data.data.video.id, data.data.video.status);
        }

        return false;
      }

      if (data.type !== 'offers' || (data.type === 'mail' && data.action !== 'unlock')) {
        return false;
      }

      updateMyProfileContent(params.hashId);
    });
  };

  const handleUnlockOffer = useCallback(hashId => {
    const { myprofile, profile, unlockMyOffer } = props;

    if (myprofile.credits < profile.offer.data.credit_cost) {
      trackPackagesPageVisited({ Source: 'Unlock Attempt' });
      return navigate('/packages');
    }

    unlockMyOffer(hashId, mixpanelData[EVENT_NAMES.CONVERSATION_UNLOCKED].triggerToBuy, () => {
      resetMixpanelData(EVENT_NAMES.CONVERSATION_UNLOCKED);
    });
  }, []);

  return (
    <ProfileProvider>
      <Profile handleUnlockOffer={handleUnlockOffer} {...props} />
    </ProfileProvider>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    myprofile: state.profile,
    profile: state.extprofile,
    isProcessing: state.profile.isProcessing,
    onboarded:
      Boolean(state.profile.profile && state.profile.profile.data.city) &&
      state.profile.profile_complete &&
      Boolean(state.profile.photo && state.profile.photo.data.length > 0) &&
      Boolean(
        state.profile.photo &&
          state.profile.photo.data[0] &&
          state.profile.photo.data[0].status === 'approved' &&
          state.profile.photo.data[0].type === 'photo'
      ),
    errors: state.common.errors,
    mapFields: state.meta.map_fields,
    isDirty: state.common.isDirty,
    isUploading: state.profile.pendingUploads,
    photos: state.profile.photo,
    singlePublicLeft: Boolean(filter(state.profile.photo.data, { private: false, type: 'photo' }).length === 1),
    hasPublicPhotos: Boolean(find(state.profile.photo.data, p => Boolean(p.private === false && p.type === 'photo'))),
    hasPublicApprovedPhotos: Boolean(state.profile.has_photo),
    portrait: state.common.portrait,
    prevPage: state.common.prevPage,
  };
};

// actions to refresh internal/external profile.
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refreshMyProfile: () => {
      dispatch(refreshProfile());
    },
    removeExternal: () => {
      dispatch(removeExternalProfile());
    },
    refreshExternal: username => {
      setTimeout(() => dispatch(refreshExternalProfile(username)), 0);
    },
    toggleFav: hashId => {
      // notify extprofile store
      dispatch(storeProfileFavChange());

      // notify api.
      dispatch(favToggle(hashId));
    },
    ignoreOffer: hashId => {
      // ignore offer
      dispatch(ignoreOffer(hashId));
      setTimeout(() => dispatch(refreshExternalProfile(hashId)), 0);
    },
    acceptOffer: (hashId, message) => {
      return dispatch(acceptOffer(hashId, message, false, null, null, false, 'Profile', ownProps.navigate));
    },
    toggleBlock: (username, hashId, blockType) => {
      // notify store.
      dispatch(toggleBlockInStore(hashId));
      // notify api.
      dispatch(toggleBlockProfile(username, hashId, blockType));
    },
    sendSuggestion: (username, isFromFavorites, favSection, favKey) => {
      return dispatch(sendSuggestion(username, isFromFavorites, favSection, favKey));
    },
    /** Below Methods are needed to handle the Create Offer and Counter Offer in the Modal */
    clearErrors: () => {
      dispatch(clearErrors());
    },
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    createNewOffer: formData => {
      return dispatch(createNewOffer(formData));
    },
    counterOffer: formData => {
      return dispatch(counterOffer(formData, false, ownProps.navigate));
    },
    unlockMyOffer: (hashId, triggerToBuy, callback = () => undefined) => {
      return dispatch(unlockOffer(hashId, null, triggerToBuy)).then(() => {
        callback();
        ownProps.navigate(`/mail/${hashId}`);
      });
    },
    showWarn: message => {
      return dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    updateMyProfileContent: hashId => {
      return dispatch(updateProfileContent(hashId));
    },
    updateProfileCover(formData) {
      dispatch(clearDirtyStatus());
      return dispatch(updateProfileCover(formData));
    },
    updateProfileOpeningOffer(formData) {
      dispatch(clearDirtyStatus());
      dispatch(updateProfileOpeningOffer(formData));
    },
    updateProfileAbout(formData) {
      dispatch(clearDirtyStatus());
      dispatch(updateProfileAbout(formData));
    },

    updateProfileInterest(formData) {
      dispatch(clearDirtyStatus());
      dispatch(updateProfileInterest(formData));
    },
    updateProfileDescription(formData) {
      dispatch(clearDirtyStatus());
      dispatch(updateProfileDescription(formData));
    },
    updateProfileSeeking(formData) {
      dispatch(clearDirtyStatus());
      dispatch(updateProfileSeeking(formData));
    },
    clearEachIndividualError: fieldName => {
      dispatch(clearIndividualError(fieldName));
    },
    refreshAndClearErrors: () => {
      dispatch(clearPendingPhotosUpload());
    },
    uploadPhoto: (formData, loadingStatus) => {
      dispatch(addNewPhoto(formData, loadingStatus));
    },
    deletePhoto: (photoId, photoKey) => {
      // remove photo from API.
      dispatch(setGalleryLoading());
      dispatch(deletePhoto(photoId))
        .then(() => {
          dispatch(refreshProfile());
        })
        .catch(() => {
          dispatch(killGalleryLoading());
        });
      // remove from store.
      dispatch(removePhotofromProfile(photoKey));
    },
    setPrivacy: (photoId, photoKey) => {
      // swaps photo privacy.
      dispatch(setGalleryLoading());
      dispatch(setNewPrivacy(photoId, photoKey))
        .then(() => {
          dispatch(refreshProfile());
        })
        .catch(() => {
          dispatch(killGalleryLoading());
        });
    },
    makeDefault: (photoId, photoKey) => {
      // makes photo your default
      dispatch(makePhotoDefault(photoId, photoKey)).then(() => {
        dispatch(refreshProfile());
      });
    },
    displayMessage: ({ info, linkInfo, url }) => dispatch(displayFailMessage({ info, linkInfo, url })),
    fetchPageNux: curRoute => {
      dispatch(fetchPageNux(curRoute));
    },
    updateNuxState: nuxUpdates => {
      dispatch(updateNuxGuideStates(nuxUpdates));
    },
    skipNuxGuides: () => {
      return dispatch(skipNuxGuides());
    },
    updateOfferTutorialState: () => {
      dispatch(updateShowTutorialOffer(false));
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
    updateOffer: formData => {
      return dispatch(updateOffer(formData));
    },
    uploadMedia: (formData, loadingStatus, mediaType) => {
      dispatch(addNewMedia(formData, loadingStatus, mediaType));
    },
    deleteMedia: (mediaId, mediaKey) => {
      // remove video from API.
      dispatch(deleteMedia(mediaId)).then(() => {
        dispatch(refreshProfile());
      });
      // remove from store.
      dispatch(removePhotofromProfile(mediaKey));
    },
    setMediaPrivacy: (mediaId, mediaKey) => {
      // swaps video privacy.
      dispatch(setNewMediaPrivacy(mediaId, mediaKey)).then(() => {
        dispatch(refreshProfile());
      });
    },
    updateShowWishlist: (formData, cb) => {
      if (formData.type === 'wishlist') {
        dispatch(updateShowWishlist(formData.value, cb));
      }
    },
    updateShowGiftSent: (formData, cb) => {
      if (formData.type === 'gift_sent') {
        dispatch(updateShowGiftSent(formData.value, cb));
      }
    },
    triggerUnlockMessage: (hashId, triggerToBuy, callback = () => undefined) => {
      dispatch(unlockOutsideMessage(hashId, triggerToBuy, callback));
    },
    bulkDeleteMedia: mediaIds => {
      dispatch(bulkDelete(mediaIds));
    },
    bulkTogglePrivacy: (mediaIds, privacy) => {
      dispatch(bulkTogglePrivacy(mediaIds, privacy));
    },
    updateVideoStatus: (key, status) => {
      dispatch(updateVideoStatus(key, status));
      dispatch(refreshProfile());
    },
    resetDiscoverModeAccountsAction: () => {
      dispatch(resetDiscoverModeAccountsAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withPusher(ProfileContainer));
