import React from 'react';
import styled from '@emotion/styled';
import WYPBannerIcon from './icons/WYPBannerIcon';
import FDBannerIcon from './icons/FDBannerIcon';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';
import { isDarkMode } from '../../utils/helpers';
import { isFD } from '../../config/Master';
import backgrounds from '../../theme/backgrounds';
import { theme } from '../../theme';
import RoundDismissIcon from '../../atoms/icons/RoundDismissIcon';
import { trackPWaMixpanelEventInstalled } from '../../utils/pwa';

const PromptContainer = styled('div')({
  backgroundColor: backgrounds[theme].blue,
  borderRadius: 10,
  padding: 15,
  margin: '-10px 10px 10px 10px',
});

const BannerLogoActions = styled('div')({
  textAlign: 'right',
  marginTop: 10,
});

const SidebarBannerTitle = styled('p')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '18px',
  color: isDarkMode() ? '#CFCFCF' : '#333',
  padding: 0,
  margin: 0,
  letterSpacing: '-0.16px',
});

const BannerDescription = styled('p')({
  fontSize: 14,
  fontWeight: 400,
  color: isDarkMode() ? 'AFB0B1' : '#595959',
  padding: 0,
  margin: 0,
  marginTop: 10,
  lineHeight: '19px',
  letterSpacing: '-0.14px',
});

const PWABannerLogoTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const BannerTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '80%',
  marginLeft: 10,
});

const CloseButtonContainer = styled('div')({
  width: '100%',
  textAlign: 'right',
});

interface PWASidebarBannerContentProps {
  onClick?: any;
  onClose: () => void;
}

const PWAMessagePageBannerContent: React.FC<PWASidebarBannerContentProps> = ({ onClick, onClose }) => {
  return (
    <PromptContainer>
      <CloseButtonContainer>
        <RoundDismissIcon onClick={onClose} />
      </CloseButtonContainer>
      <PWABannerLogoTextWrapper>
        <>
          {isFD ? (
            <FDBannerIcon customStyle={{ marginTop: '-1%' }} />
          ) : (
            <WYPBannerIcon customStyle={{ marginTop: '-1%' }} />
          )}
        </>
        <BannerTextContainer>
          <SidebarBannerTitle>Get instant notifications on your device</SidebarBannerTitle>
          <BannerDescription>Never miss a message again!</BannerDescription>
        </BannerTextContainer>
      </PWABannerLogoTextWrapper>
      <BannerLogoActions>
        <PrimaryButton
          customStyle={{ width: 'inherit' }}
          data-test-id="pwa-banner-install"
          id="install"
          onClick={() => {
            onClick();
            trackPWaMixpanelEventInstalled('Message');
          }}
          label="Install the App"
          size="small"
          color="blue"
        />
      </BannerLogoActions>
    </PromptContainer>
  );
};

export default PWAMessagePageBannerContent;
