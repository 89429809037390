import React, { ReactElement } from 'react';
import { DashlineSeparator } from '../../join/styledElements';
import TextArea from '../../../atoms/TextArea';
import { handleProfileCompletionVysionEvent } from '../../../utils/vysion/profileCompletion';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { ProfileCompletionOnFocusVysionEventsEnum } from '../../../utils/vysion/profileCompletion/onFocus';
import { EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS } from '../../../constants/evidently';

interface ICompleteAboutMeForm {
  formData: any;
  handleFormData: any;
  showRequiredProfileDetailLists: any;
  variant: EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS;
}

const CompleteAboutMeForm = ({
  formData,
  handleFormData,
  showRequiredProfileDetailLists,
  variant,
}: ICompleteAboutMeForm): ReactElement => {
  return (
    <div>
      {showRequiredProfileDetailLists.description && (
        <div>
          <TextArea
            placeholder="Let people know some fun facts about who you are and what you're into. Icebreakers for your dates!"
            inputLabel="Tell everyone a little bit about yourself"
            customStyle={{ paddingBottom: 10, fontSize: 15 }}
            value={formData.description}
            minCount={50}
            showCounter={true}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleFormData({ description: event.target.value });
            }}
            customLabelStyle={{ fontSize: 15, fontWeight: 600 }}
            isRequired={variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.OLD_PROFILE_WALL}
            onFocus={() => {
              handleProfileCompletionVysionEvent(
                {
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: ProfileCompletionOnFocusVysionEventsEnum.FOCUS_ABOUT_ME,
                },
                location.pathname
              );
            }}
          />
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.seeking && (
        <div>
          <TextArea
            placeholder="Dinner, drinks and going for a walk after? What's your ideal first date?"
            inputLabel="What does your ideal first date look like?"
            customStyle={{ fontSize: 15 }}
            value={formData.seeking}
            minCount={50}
            showCounter={true}
            isRequired={variant === EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS.OLD_PROFILE_WALL}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleFormData({ seeking: event.target.value });
            }}
            customLabelStyle={{ fontSize: 15, fontWeight: 600 }}
            onFocus={() => {
              handleProfileCompletionVysionEvent(
                {
                  action: VYSION_ACTIONS.ON_FOCUS,
                  event: ProfileCompletionOnFocusVysionEventsEnum.FOCUS_IDEAL_FIRST_DATE,
                },
                location.pathname
              );
            }}
          />
          <DashlineSeparator />
        </div>
      )}
    </div>
  );
};

export default CompleteAboutMeForm;
