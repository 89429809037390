import { getLocalItem, setLocalItem } from '../common';
import _isEmpty from 'lodash/isEmpty';
import _union from 'lodash/union';

// visited profile functions
const localVisitedProfiles = (): any => JSON.parse(getLocalItem('visited_profiles')) || [];

const profilePayload = (profile: any): any => {
  return {
    ...profile.account.data,
    photo: {
      data: [profile.avatar.data],
    },
    profile: {
      data: {
        ...profile,
        account: null,
      },
    },
  };
};

const localDashboardProfiles = (): any => {
  const dashboardRecent = JSON.parse(getLocalItem('wyp_dashboard_recent')) || [];
  const dashboardNewest = JSON.parse(getLocalItem('wyp_dashboard_newest')) || [];
  const dashboardFavorites = JSON.parse(getLocalItem('wyp_dashboard_favorites')) || [];

  // clean up dashboard profiles to match visited profiles structure
  const recentProfiles = dashboardRecent.map(profile => {
    return profilePayload(profile);
  });

  const newestProfiles = dashboardNewest.map(profile => {
    return profilePayload(profile);
  });

  return _union(recentProfiles, newestProfiles, dashboardFavorites);
};

const updateVisitedProfiles = (changeAction: any, profileData: any = {}): void => {
  const visitedProfiles = localVisitedProfiles();
  if (visitedProfiles.length > 9) {
    visitedProfiles.shift();
  }

  const filteredProfiles = visitedProfiles.filter(profile => profile['hash_id'] !== profileData.hash_id);

  // added changeAction switch for future customization on each action taken
  switch (changeAction) {
    case 'add':
    case 'store_reason':
    case 'fav_change':
    case 'block_user':
    case 'store_offer':
    case 'remove_offer':
    case 'refresh_external':
      filteredProfiles.push(profileData);
      setLocalItem('visited_profiles', JSON.stringify(filteredProfiles));
      break;
    default:
      break;
  }
};

// messages thread functions
const localMessageThreads = (): any => {
  const defaultThreadData = {
    collection: [],
    meta: {},
  };
  return JSON.parse(getLocalItem('messages_thread')) || defaultThreadData;
};

const updateMessageThreads = (threadData = {}): void => {
  setLocalItem('messages_thread', JSON.stringify(threadData));
};

// message conversation functions
const localMessageConversations = (conversationID: string | number): void => {
  const conversationMessages = JSON.parse(getLocalItem('message_conversations')) || [];
  const filteredMessage = !_isEmpty(conversationMessages)
    ? conversationMessages.filter(conversation => {
        return conversation.other_profile && conversation.other_profile.data.hash_id === conversationID;
      })
    : {};
  return filteredMessage[0];
};

const updateMessageConversations = (changeAction: any, conversationData = {}): void => {
  const messageConversation = JSON.parse(getLocalItem('message_conversations')) || [];
  if (messageConversation.length > 23) {
    messageConversation.shift();
  }

  const filteredConversation = !_isEmpty(messageConversation)
    ? messageConversation.filter(conversation => conversation['id'] !== conversationData.id)
    : [];

  // added changeAction switch for future customization on each action taken
  if (changeAction === 'add') {
    filteredConversation.push(conversationData);
    setLocalItem('message_conversations', JSON.stringify(filteredConversation));
  }
};

/*
// WYP-13499 Deprecate ActivityFeed
// recent activities functions
const localRecentActivity = (): any => JSON.parse(getLocalItem('wyp_recent_activity')) || [];
const updateRecentActivity = (activityData = []): void => {
  setLocalItem('wyp_recent_activity', JSON.stringify(activityData));
};
*/

export {
  // visited Profiles
  localVisitedProfiles,
  updateVisitedProfiles,
  // messages thread
  localMessageThreads,
  updateMessageThreads,
  // message conversations
  localMessageConversations,
  updateMessageConversations,
  // dashboard Profiles
  localDashboardProfiles,
  /*
  // WYP-13499 Deprecate ActivityFeed
  // recent activities
  localRecentActivity,
  updateRecentActivity,
  */
};
