import React, { ReactElement } from 'react';

interface DotMenuProps {
  width: number;
  height: number;
  color: string;
}

const DotMenu = ({ width = 22, height = 5, color = '#2490DE' }: DotMenuProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 343 92">
      <path
        fill={color}
        d="M45.225,0.972 C70.151,0.972 90.444,21.256 90.444,46.191 C90.444,71.132 70.151,91.41 45.225,91.41 C20.284,91.41 0,71.131 0,46.191 C0,21.256 20.284,0.972 45.225,0.972 Z M173.409,0.972 C198.345,0.972 218.635,21.256 218.635,46.191 C218.635,71.132 198.345,91.41 173.409,91.41 C148.471,91.41 128.184,71.131 128.184,46.191 C128.184,21.256 148.471,0.972 173.409,0.972 Z M297.165,0.972 C322.091,0.972 342.382,21.256 342.382,46.191 C342.382,71.132 322.091,91.41 297.165,91.41 C272.233,91.41 251.943,71.131 251.943,46.191 C251.943,21.256 272.233,0.972 297.165,0.972 Z"
      />
    </svg>
  );
};

export default DotMenu;
