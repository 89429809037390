import React, { ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import CountDown from 'react-countdown';
import usePhoneActionModal from '../../hooks/usePhoneActionModal';
import { useApp } from '../../contexts/AppContext';
import { EVENT_NAMES, trackMixpanelAnonymousEvents, trackMixpanelEvents } from '../../constants/mixpanel';

const CountdownContainer = styled('div')({
  display: 'flex',
  marginTop: 5,
});
const CountDownLabel = styled('div')({
  fontSize: 14,
  fontWeight: 400,
});
const CountDownTimer = styled('div')({
  fontSize: 14,
  fontWeight: 600,
  paddingLeft: 3,
});

interface OtpCountdownProps {
  isLogin: boolean;
  hashId: string;
  currentPhone: string;
}

const OtpCountdown = ({ isLogin, hashId, currentPhone }: OtpCountdownProps): ReactElement => {
  const { otpResendThreshold, otpClockRef, isAccountIsMixpanelEligableEnabled } = useApp();
  const mainTimer = useMemo(() => Date.now() + 59000, [otpResendThreshold]);
  const { showExpiredOTPCountdownModal } = usePhoneActionModal();

  return (
    <CountdownContainer>
      <CountDownLabel>Time Remaining:</CountDownLabel>
      <CountDown
        renderer={({ seconds }) => <CountDownTimer>{`00:${seconds}`}</CountDownTimer>}
        date={mainTimer}
        onComplete={() =>
          showExpiredOTPCountdownModal({
            isLogin,
            phoneNumber: currentPhone,
            onResendSuccess: () => {
              if (hashId) {
                if (isAccountIsMixpanelEligableEnabled) {
                  trackMixpanelEvents(hashId, EVENT_NAMES.SMS_CODE_REQUESTED);
                }
              } else {
                trackMixpanelAnonymousEvents(EVENT_NAMES.SMS_CODE_REQUESTED);
              }
            },
          })
        }
        key={otpResendThreshold}
        ref={otpClockRef}
      />
    </CountdownContainer>
  );
};

export default OtpCountdown;
