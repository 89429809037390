import React, { ReactElement } from 'react';

interface LocationV2Props {
  width?: any;
  height?: any;
  onClick: any;
  fill: string;
}

const LocationV2 = ({ width = 22, height = 22, onClick, fill = '#8F8F8F' }: LocationV2Props): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      onClick={onClick}
    >
      <path
        d="M9.99999 22V20C7.91665 19.7667 6.12932 18.9043 4.63799 17.413C3.14599 15.921 2.28332 14.1333 2.04999 12.05H0.0499878V10.05H2.04999C2.28332 7.96667 3.14599 6.17933 4.63799 4.688C6.12932 3.196 7.91665 2.33333 9.99999 2.1V0.0999985H12V2.1C14.0833 2.33333 15.871 3.196 17.363 4.688C18.8543 6.17933 19.7167 7.96667 19.95 10.05H21.95V12.05H19.95C19.7167 14.1333 18.8543 15.921 17.363 17.413C15.871 18.9043 14.0833 19.7667 12 20V22H9.99999ZM11 18.05C12.9333 18.05 14.5833 17.3667 15.95 16C17.3167 14.6333 18 12.9833 18 11.05C18 9.11666 17.3167 7.46667 15.95 6.1C14.5833 4.73333 12.9333 4.05 11 4.05C9.06665 4.05 7.41665 4.73333 6.04999 6.1C4.68332 7.46667 3.99999 9.11666 3.99999 11.05C3.99999 12.9833 4.68332 14.6333 6.04999 16C7.41665 17.3667 9.06665 18.05 11 18.05Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocationV2;
