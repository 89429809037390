import React, { useState } from 'react';
import Carousel from '../Carousel';
import SvgQuestion from '../../../atoms/icons/SvgQuestion';
import styled from '@emotion/styled';
import { IsMobileViewPort } from '../../../utils/helpers';
import { s3BucketDirect } from '../../../config/Master';
import useRealGifts, { ReceiverDataProps } from '../../../hooks/useRealGifts';
import useModal from '../../../hooks/useModal';

const TutorialContainer = styled('div')({
  display: 'initial',
});

const StyledImgContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: 130,
  marginTop: 20,
});

const StyledLink = styled('a')({
  fontSize: 12,
  '&:hover': {
    textDecoration: 'underline',
    color: '#2490DE',
  },
});

interface OffersTutorialProps {
  hideArrows?: boolean;
  clickableIndicators?: boolean;
  iconOnly?: boolean;
  linkStyle?: Record<string, unknown>;
  isGenerous: boolean;
  receiverData: ReceiverDataProps;
}

const Step2AttractiveIcon = () => (
  <StyledImgContainer>
    <img
      src={`${s3BucketDirect}gift_wishlist_tutorial/step2_attractive/Bag.png`}
      style={{ position: 'absolute', left: IsMobileViewPort ? '-3%' : '17%', zIndex: 1 }}
    />
    <img
      src={`${s3BucketDirect}gift_wishlist_tutorial/step2_attractive/Chocolates.png`}
      style={{ position: 'absolute', left: '50%', zIndex: 2, transform: 'translate(-50%, 0)' }}
    />
    <img
      src={`${s3BucketDirect}gift_wishlist_tutorial/step2_attractive/Shoes.png`}
      style={{ position: 'absolute', left: '59%', zIndex: 1 }}
    />
  </StyledImgContainer>
);

const GiftWishlistTutorialModal = ({
  iconOnly = false,
  hideArrows = true,
  clickableIndicators = true,
  linkStyle = {},
  isGenerous,
  receiverData,
}: OffersTutorialProps): JSX.Element => {
  const { handleRGWishlist } = useRealGifts();
  const [isHover, setIsHover] = useState(false);
  const { basicModal, resetModal } = useModal();

  // attractive view own profile
  const tutorialData1 = [
    {
      icon: `${s3BucketDirect}gift_wishlist_tutorial/step1_generous.svg`,
      iconStyle: { width: 123, height: 163 },
      title: `What is a Wishlist`,
      content: ['It\'s your personal "Gift Shop" where members can purchase and send gifts right to you!'],
      subTitle: 'Get the gifts you want!',
    },
    {
      iconComponent: <Step2AttractiveIcon />,
      title: 'Hundreds of items to choose from',
      content: [
        'From designer fashion to decadent chocolates, choose all the items on your Wishlist from our extensive catalog!',
      ],
      subTitle: 'Wishlists curated by you!',
    },
    {
      icon: `${s3BucketDirect}gift_wishlist_tutorial/step3_attractive.svg`,
      iconStyle: { width: 159, height: 132 },
      title: 'Privacy is everything',
      content: [
        'Wishlists keep your personal info like size and shipping address private. Gifts arrive at your door smoothly and discretely, with your privacy top of mind.',
      ],
      subTitle: 'Create your Wishlist now!',
    },
  ];

  // generous viewing attractive profile
  const tutorialData2 = [
    {
      icon: `${s3BucketDirect}gift_wishlist_tutorial/step1_generous.svg`,
      iconStyle: { width: 123, height: 163 },
      title: `What is a Gift Wishlist?`,
      content: ['Wishlists are curated lists by Attractive members and showcase their most desired gifts!'],
      subTitle: 'Get them a gift they’ll love!',
      onCloseLabel: '',
    },
    {
      icon: `${s3BucketDirect}gift_wishlist_tutorial/step2_generous.svg`,
      iconStyle: { width: 136, height: 143 },
      title: 'Everyone loves receiving gifts',
      content: [
        'Surprise the member you’re interested in with a gift they’ll love and make your offer standout even more!',
      ],
      subTitle: 'Show her you are really interested!',
    },
    {
      icon: `${s3BucketDirect}gift_wishlist_tutorial/step3_generous.svg`,
      iconStyle: { width: 151, height: 153 },
      title: 'Gifting made easy',
      content: [
        'Send your gift along with a personalized message and we’ll take care of the rest making sure they get the right size and the gift gets to them quickly!',
      ],
      subTitle: 'Check out the FAQ in anyone’s Wishlist for more!',
    },
  ];

  const handleOnLinkClick = e => {
    e.preventDefault();
    e.stopPropagation();

    basicModal({
      dataTestID: '"gift-wishlist-tutorial',
      modalContentLabel: 'Gift Wishlist Tutorial',
      modalBody: (
        <Carousel
          slidesData={isGenerous ? tutorialData2 : tutorialData1}
          hideArrows={hideArrows}
          clickableIndicators={clickableIndicators}
          onClose={handleGiftWishlist}
          isFirstTimeOffer={false}
          onCloseLabel={isGenerous ? 'Start Gifting' : 'Create Your Wishlist'}
          isParagraphContent
          customButtonContainerStyle={{ marginTop: 19 }}
        />
      ),
    });
  };

  const handleGiftWishlist = buttonRef => {
    if (buttonRef.current) {
      handleRGWishlist({
        buttonRef,
        receiverData,
        userTemplateSetId: '',
      });
    }
    resetModal();
  };

  return (
    <TutorialContainer>
      <StyledLink
        href="#"
        style={linkStyle}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={e => handleOnLinkClick(e)}
      >
        <SvgQuestion
          viewBox="0 0 512 500"
          styles={{ fill: isHover ? '#2490DE' : 'rgb(151, 151, 151)', height: 12, width: 12 }}
        />{' '}
        {iconOnly ? '' : 'What is a Wishlist?'}
      </StyledLink>
    </TutorialContainer>
  );
};

export default GiftWishlistTutorialModal;
