import React, { cloneElement, useEffect, useState, useRef, useMemo } from 'react';
import AppContainer from './AppContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  checkEmailToken as checkEmailTokenAction,
  checkEmailPromoToken as checkEmailPromoTokenAction,
  refreshProfile as refreshProfileAction,
  updateProfileSetting,
  toggleDesktopNotificationStatus,
  invertSetting,
  invertVideoSetting,
  updateVideoSetting,
  requestUserInfo,
  deleteGDPR,
  getVideoCallSettings,
  getCheckInfoRequest,
  refreshAccount,
} from '../actions/profileActions';
import { attachSocialLogin, destroyOtherDeviceSession, validateSocialCredentials } from '../actions/authActions';
import { updatingSettings, revertLastUpdate, clearErrors, setPortrait } from '../actions/commonActions';
import { getLocalItem, setLocalItem } from '../common';
import { clearNotificationMessage, displayFailMessage } from '../actions/notificationActions';
import { isEmpty } from '../utils/helpers';
import Loader from '../atoms/LoaderStyled';
import { SiteNotification } from '../modules/SiteNotification';
import moment from 'moment';
import MasterConfig from '../config/Master';
import { useComponentWillMount } from '../utils/customHooks';
import { abandonAction, backAction, pageLoadAction } from '../actions/globalActions';
import useModal from '../hooks/useModal';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LoadingBackdrop from '../atoms/loader/LoadingBackdrop';
import { useApp } from '../contexts/AppContext';

const RouteContainer = props => {
  const [validateEmailToken, setValidateEmailToken] = useState(false);
  const [validateEmailPromoToken, setValidateEmailPromoToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { modalState } = useApp();

  const { initModalLayout } = useModal();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const memoizedInitModalLayout = useMemo(() => initModalLayout(), [
    modalState.children,
    modalState.isModalOpen,
    modalState.customOverlayStyle,
    modalState.customContentStyle,
  ]);

  useComponentWillMount(() => {
    setValidateEmailToken(location.search.indexOf('email_token') > -1);
    setValidateEmailPromoToken(location.search.indexOf('email_promo_token') > -1);
  });

  useEffect(() => {
    const { isNotFoundPage = false } = props;
    pageLoadAction(location, isNotFoundPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    backAction();
    abandonAction();

    const { checkEmailToken, checkEmailPromoToken, refreshProfile } = props;
    const queryStringParsed = queryString.parse(location.search);

    // Checks if there is a just let me in flag enabled from email token
    if (validateEmailToken) {
      const resultCheckEmailToken = checkEmailToken(
        queryStringParsed.email_token,
        `${location.pathname}`,
        navigate,
        params
      );

      if (resultCheckEmailToken && typeof resultCheckEmailToken.then === 'function') {
        resultCheckEmailToken
          .then(() => {
            setValidateEmailToken(false);
            refreshProfile().then(() => navigate(`${location.pathname}?email_ref=true`));
          })
          .catch(() => setValidateEmailToken(false))
          .finally(() => setIsLoading(false));
      }
    }
    // Checks if there is a just let me in flag enabled  from payment token
    else if (validateEmailPromoToken) {
      const resultCheckEmailPromoToken = checkEmailPromoToken(
        queryStringParsed.email_promo_token,
        `${location.pathname}`
      );

      if (typeof resultCheckEmailPromoToken.then === 'function') {
        resultCheckEmailPromoToken
          .then(() => {
            setValidateEmailPromoToken(false);

            let promoCode = '';

            if (queryStringParsed.promo_code) {
              promoCode = `&promo_code=${queryStringParsed.promo_code}`;
            }

            refreshProfile().then(() =>
              navigate(`/packages?step=payment&package_id=${queryStringParsed.package_id}${promoCode}`)
            );
          })
          .catch(() => setValidateEmailPromoToken(false))
          .finally(() => setIsLoading(false));
      }
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { auth } = props;
    const urlParams = new URLSearchParams(location.search);
    const loggedOut = urlParams.get('logged_out');
    const joinPredata = JSON.parse(getLocalItem('join_predata'));
    const preJoinData = JSON.parse(getLocalItem('join_onboarding_predata'));

    if (
      auth.gdpr_acknowledged !== null &&
      auth.gdpr_acknowledged !== undefined &&
      !auth.gdpr_acknowledged &&
      location.pathname !== '/privacy-updated' &&
      loggedOut !== 'true' &&
      !joinPredata &&
      !preJoinData
    ) {
      return navigate('/privacy-updated');
    }

    if (
      ((auth.gdpr_acknowledged !== undefined && auth.gdpr_acknowledged) || auth.gdpr_acknowledged === undefined) &&
      location.pathname === '/privacy-updated'
    ) {
      return navigate('/dashboard');
    }
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authorize, props.auth.isAuthenticated]);

  const validateCredentials = async callback => {
    try {
      const auth = await _googleAuth();
      const authUser = await auth.signIn({ prompt: 'select_account' });
      props.validateSocialCredentials(
        authUser,
        MasterConfig.GOOGLE_AUTH,
        () => typeof callback === 'function' && callback()
      );
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrientation = () => {
    props.setPortrait(window.orientation !== 90);
  };

  const orientationChangeHandlerRef = useRef(updateOrientation);

  useEffect(() => {
    window.addEventListener('orientationchange', orientationChangeHandlerRef.current);

    return () => {
      window.removeEventListener('orientationchange', orientationChangeHandlerRef.current);
    };
  }, []);

  const renderRoutes = () => {
    const { auth, authorize, prevPage, ...rest } = props;

    setLocalItem('prevPage', prevPage);

    // If redirection is from email promo link
    if (validateEmailPromoToken) {
      return null;
    }

    if (location.pathname === '/') {
      navigate('/dashboard');
    }

    if (isLoading) {
      return (
        <Loader
          active={true}
          style={{ position: 'fixed', width: '100%', height: '100%', top: 0 }}
          background="#F4F7FA"
        />
      );
    }

    // if unauthorized, redirect to public Support Ticket page - "/faq/giving-feedback"
    // else if authorized, redirect to Members Support Ticket page - "/support/contact"
    if (
      (!auth.isAuthenticated || validateEmailToken || validateEmailPromoToken) &&
      location.pathname === '/support/contact'
    ) {
      window.location.href = `${MasterConfig.FULL_URL}/faq/giving-feedback`;

      return null;
    }

    // if route needs auth user but unauthenticated redirect to login
    // if unauthenticated and visited non existent page redirect to login
    if ((authorize && !auth.isAuthenticated) || (!authorize && rest.path === '*')) {
      const { pathname, search, hash } = location;
      const redirectTo = pathname + (!isEmpty(search) ? search : '') + (!isEmpty(hash) ? hash : '');

      window.location.href = `/login${redirectTo !== null ? `?redirectTo=${redirectTo}` : ''}`;

      return null;
    }

    const content = (
      <>
        {/* // Todo: Create better fallback loader */}
        <LoadingBackdrop active={validateEmailToken || validateEmailPromoToken} showLoadingBg={true} />
        {cloneElement(props.children, { ...props, params, location, navigate })}
        {memoizedInitModalLayout}
      </>
    );

    return (
      <HelmetProvider>
        <Helmet>
          <title>WhatsYourPrice | Bid on first dates</title>
          <meta
            name="description"
            content="Online dating where you can buy &amp; sell first dates. 100% free for attractive singles. Join now, and go on a first date today, guaranteed. Featured on CNN, NBC, &amp; FOX News"
          />
          <meta
            property="og:description"
            content="A dating website where Generous and Attractive people can buy & sell first dates. Join now, and go on a first date today, guaranteed. Featured on CNN, NBC, & FOX News"
          />
          <meta
            property="twitter:description"
            content="A dating website where Generous and Attractive people can buy & sell first dates. Join now, and go on a first date today, guaranteed. Featured on CNN, NBC, & FOX News"
          />
        </Helmet>
        {authorize ? (
          <AppContainer
            validateCredentials={validateCredentials}
            navigate={navigate}
            location={location}
            params={params}
            {...props}
          >
            {content}
          </AppContainer>
        ) : (
          content
        )}
        <SiteNotification />
      </HelmetProvider>
    );
  };

  return renderRoutes();
};

RouteContainer.propTypes = {
  authorize: PropTypes.bool,
  // component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteContainer.defaultProps = {
  authorize: false,
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  isFetching: state.common.isFetching,
  settings: state.settings,
  updatingSettings: state.common.updatingSettings,
  lastUpdate: state.common.lastUpdate,
  errors: state.common.errors,
  data: state,
  portrait: state.common.portrait,
  prevPage: state.common.prevPage,
});

const mapDispatchToProps = dispatch => {
  return {
    getVideoCallSettings: () => {
      dispatch(refreshAccount()).then(() => {
        dispatch(getVideoCallSettings());
      });
    },
    getCheckInfoRequest() {
      dispatch(getCheckInfoRequest());
    },
    checkEmailToken: (token, redirect, navigate, params) => {
      const { username } = params;
      return dispatch(checkEmailTokenAction(token, username, redirect, navigate));
    },
    checkEmailPromoToken: (token, redirect) => {
      return dispatch(checkEmailPromoTokenAction(token, redirect));
    },
    refreshProfile: () => {
      return dispatch(refreshProfileAction());
    },
    toggleDesktopNotificationStatus(status) {
      dispatch(toggleDesktopNotificationStatus(status));
    },
    updateAccountSetting: e => {
      // update global state to inform that we are updating settings
      dispatch(updatingSettings());
      // update specific account setting (in redux) - set to opposite val
      dispatch(invertSetting(e.target.id));
      // also notify API of change.

      dispatch(updateProfileSetting(e.target.id));
      setTimeout(() => {
        dispatch(updatingSettings(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')));
      }, 500);
    },
    revertBackLastUpdate: () => {
      dispatch(revertLastUpdate());
    },
    setPortrait: status => {
      dispatch(setPortrait(status));
    },
    updateVideoSetting: e => {
      // update global state to inform that we are updating settings
      dispatch(updatingSettings());
      // update specific account setting (in redux) - set to opposite val
      dispatch(invertVideoSetting(e.target.id));
      // also notify API of change.
      dispatch(updateVideoSetting());
      setTimeout(() => {
        dispatch(updatingSettings(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')));
      }, 500);
    },
    deleteGDPRForm: (password, callback) => {
      // update email address (in API)
      dispatch(deleteGDPR(password)).then(() => {
        callback();
      });
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    validateSocialCredentials(socialResponse, site, callback) {
      return dispatch(validateSocialCredentials(socialResponse, site))
        .then(() => {
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors && response.data.errors[0]) {
            dispatch(displayFailMessage({ info: response.data.errors[0].title }));
          }
        });
    },
    requestUserInfo() {
      dispatch(requestUserInfo());
    },
    attachSocialLogin(socialResponse, site, action) {
      dispatch(attachSocialLogin(socialResponse, site, action));
    },
    destroyOtherDeviceSession() {
      dispatch(destroyOtherDeviceSession());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteContainer);
