import React, { ReactElement } from 'react';
import _isEmpty from 'lodash/isEmpty';
import MasterConfig, { s3BucketDirect } from '../../../config/Master';
import Panel from '../../../atoms/panels/Panel';
import Container from '../../blocks/Container';
import Headline from '../../common/Headline';
import HeaderOut from '../../../atoms/HeaderOut';
import TextLink from '../../common/TextLink';
import Loading from '../../../atoms/Loading';
import { color } from '../../../style';
import styled from '@emotion/styled';

const SaWypGif = `${s3BucketDirect}join_onboarding/sa_wyp.gif`;

const GifBanner = styled('img')({
  maxWidth: 600,
  padding: '20px 10px',
  borderBottom: '1px solid rgb(218, 225, 233)',
  marginBottom: 20,
  width: 'calc(100% - 40px)',
});

const OnBoardingHeader = styled(Headline)({
  color: '#333333',
  textAlign: 'center',
  marginBottom: 20,
});

const OnBoardingText = styled('p')({
  backgroundColor: '#EAF4FB',
  borderRadius: 3,
  padding: 16,
  textAlign: 'center',
  fontSize: 15,
});

const OnBoardingNewText = styled('span')({
  color: '#333333',
  textAlign: 'center',
  fontSize: 14,
  width: '100%',
  display: 'block',
});

const OnBoardingPanel = styled(Panel)({
  backgroundColor: '#FFF',
  border: '1px solid rgb(218, 225, 233)',
  borderRadius: 6,
  minHeight: 300,
});

interface AutoSignupProps {
  errors: any;
  isFetching?: boolean;
}

const AutoSignup = ({ errors = [] }: AutoSignupProps): ReactElement => {
  const renderCreatingAccount = isNew => {
    return isNew ? (
      <div style={{ paddingTop: 120, textAlign: 'center' }}>
        <GifBanner src={SaWypGif} alt="Seeking Whatsyourprice Crossover" />
        <OnBoardingHeader spaceAfter>Hang tight! We’re importing your Seeking profile now!</OnBoardingHeader>
        <OnBoardingNewText>
          You’re a few minutes away from the Online Dating Shortcut® where your time is valued!
        </OnBoardingNewText>
      </div>
    ) : (
      <React.Fragment>
        <HeaderOut onBoarding />
        <OnBoardingHeader spaceAfter>Creating your account</OnBoardingHeader>
        <Container width="thin" style={{ maxWidth: 500 }}>
          <OnBoardingPanel padding="large">
            <OnBoardingText>Please wait while we import your profile from Seeking</OnBoardingText>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Loading />
            </div>
          </OnBoardingPanel>
        </Container>
      </React.Fragment>
    );
  };
  const renderContent = () => {
    if (!_isEmpty(errors)) {
      return (
        <React.Fragment>
          <HeaderOut onBoarding />
          <OnBoardingHeader spaceAfter>Invalid Signup Token</OnBoardingHeader>
          <Container width="thin">
            <OnBoardingPanel padding="large" data-test-id="invalid-token-wrap">
              This auto signup link is invalid or has already been used, and can only be used once.
              <ul>
                <li>
                  Please <TextLink to="/login">login</TextLink> using your email and password.
                </li>
                <li>
                  If you&apos;re having trouble signing up,{' '}
                  <a href={`${MasterConfig.FULL_URL}/faq`} style={{ color: color.BRAND_1_DARK, fontWeight: 'bold' }}>
                    contact support
                  </a>
                  .
                </li>
              </ul>
            </OnBoardingPanel>
          </Container>
        </React.Fragment>
      );
    } else {
      return renderCreatingAccount(true);
    }
  };

  return renderContent();
};

export default AutoSignup;
