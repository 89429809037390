/**
 * @Author: zachalam
 * @Date:   2017-01-19T16:33:10-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-19T16:57:12-08:00
 */

import React, { useEffect, useState, useCallback } from 'react';
import Settings from '../components/pages/Settings/Settings';
import { connect } from 'react-redux';
import {
  invertSetting,
  invertVideoSetting,
  updateProfileSetting,
  deleteGDPR,
  getCheckInfoRequest,
  requestUserInfo,
  toggleDesktopNotificationStatus,
  getProfileSettings,
  refreshProfile,
  updateVideoSetting,
  getVideoCallSettings,
  refreshAccount,
  getAccountByUserName,
} from '../actions/profileActions';
import { refreshOffers } from '../actions/offerActions';
import { clearErrors, storeErrors } from '../actions/commonActions';
import { clearNotificationMessage, displayFailMessage } from '../actions/notificationActions';
import {
  attachSocialLogin,
  destroyOtherDeviceSession,
  saveToken,
  validateSocialCredentials,
} from '../actions/authActions';
import { setLocalItem, toaster } from '../common';
import { updateAccountEmailPassword, postAccountEmailPassword } from '../sdk';
import MasterConfig from '../config/Master';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUpdatingEmailAndPassword } from '../actions/settingActions';

const getPage = path => {
  switch (path) {
    case '/settings/password':
      return 'password';
    case '/settings/email':
      return 'email';
    case '/settings/email-password':
      return 'email-password';
    case '/settings/account-information':
      return 'account-information';
    case '/cancelled-offers':
      return 'rejected';
    default:
      return '';
  }
};

const SettingsContainer = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const { clearErrors } = props;
  const [prevLocation, setPrevLocation] = useState(location);

  useEffect(() => {
    loadPageData(getPage(location.pathname));
  }, [loadPageData, location.pathname]);

  useEffect(() => {
    const page = getPage(location.pathname);
    const prevPage = getPage(prevLocation.pathname);

    if (page !== prevPage) {
      loadPageData(page);
    }
    setPrevLocation(location);
  }, [location, prevLocation.pathname, loadPageData]);

  useEffect(() => {
    if (location.pathname !== prevLocation.pathname) {
      clearErrors();
    }
  }, [location, prevLocation.pathname, clearErrors]);

  const updateAccountEmailPassword = data => {
    props.updateAccountEmailPasswordProps(data, navigate);
  };

  const validateCredentials = async callback => {
    try {
      const auth = await _googleAuth();
      const authUser = await auth.signIn({ prompt: 'select_account' });
      props.validateSocialCredentials(
        authUser,
        MasterConfig.GOOGLE_AUTH,
        () => typeof callback === 'function' && callback()
      );
    } catch (error) {
      console.error(error);
    }
  };

  const loadPageData = useCallback(
    page => {
      props.getVideoCallSettings();
      if (page === 'rejected') {
        // check if cancelled offers is empty
        if (props.offers[page].length < 1) props.loadRejected();
      } else {
        props.getCheckInfoRequest();
      }
    },
    [props]
  );

  return (
    <Settings
      page={getPage(location.pathname)}
      validateCredentials={validateCredentials}
      refreshOfferType={props.refreshOfferType}
      updateAccountEmailPassword={updateAccountEmailPassword}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    meta: state.meta,
    offers: state.offers,
    profile: state.profile,
    isFetching: state.offers.isFetching,
    settings: state.settings,
    isAlertPopupsEnabled: state.alerts.enabled.popup,
    blocked: state.settings,
    errors: state.common.errors,
    isFemale: state.profile.profile !== undefined ? Boolean(state.profile.profile.data.gender === 'Female') : false,
    moreExists: Boolean(state.meta.credit_current_page < state.meta.credit_total_pages),
    showCreditsHistory:
      state.profile.profile !== undefined ? Boolean(state.profile.profile.data.account_type === 'Generous') : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getVideoCallSettings: () => {
      dispatch(refreshAccount()).then(() => {
        dispatch(getVideoCallSettings());
      });
    },
    refreshOfferType: OfferType => {
      dispatch(refreshOffers('rejected', OfferType, true));
    },
    addAccountEmailPassword: async data => {
      try {
        const response = await postAccountEmailPassword(data);

        if (response.data.meta.token) {
          dispatch(saveToken(response.data.meta));
        }

        dispatch(refreshProfile());
        dispatch(getProfileSettings());
        dispatch(clearErrors());
        setLocalItem('trigger_emailChange', 'true');

        ownProps.history.goBack();
        toaster.success('Email and password have been added successfully.');
      } catch (error) {
        dispatch(storeErrors(error.response.data));
        console.error(error);
      }
    },
    updateAccountEmailPasswordProps: async (data, navigate) => {
      try {
        dispatch(setUpdatingEmailAndPassword(true));

        const response = await updateAccountEmailPassword(data);

        if (response.data.meta.token) {
          dispatch(saveToken(response.data.meta));
        }

        dispatch(getProfileSettings());
        dispatch(clearErrors());

        setLocalItem('trigger_emailChange', 'true');
        dispatch(setUpdatingEmailAndPassword(false));

        navigate('/dashboard');
        toaster.success('Email and/or password has been updated successfully.');
      } catch (error) {
        dispatch(setUpdatingEmailAndPassword(false));
        error.response && dispatch(storeErrors(error.response.data));
        console.error(error);
      } finally {
        dispatch(setUpdatingEmailAndPassword(false));
      }
    },
    deleteGDPRForm: (password, callback) => {
      // update email address (in API)
      dispatch(deleteGDPR(password)).then(() => {
        callback();
      });
    },
    updateAccountSetting: e => {
      // update specific account setting (in redux) - set to opposite val
      dispatch(invertSetting(e.target.id));
      // also notify API of change.
      dispatch(updateProfileSetting());
    },
    updateVideoSetting: e => {
      // update specific account setting (in redux) - set to opposite val
      dispatch(invertVideoSetting(e.target.id));
      // also notify API of change.
      dispatch(updateVideoSetting());
    },
    clearErrors: () => {
      dispatch(clearErrors());
    },
    loadRejected() {
      dispatch(refreshOffers('rejected', 'recent', true));
      dispatch(refreshProfile());
    },
    loadRejectedMore(OfferType) {
      dispatch(refreshOffers('rejected', OfferType, false));
      dispatch(refreshProfile());
    },
    clearNotificationMessage() {
      dispatch(clearNotificationMessage());
    },
    requestUserInfo() {
      dispatch(requestUserInfo());
    },
    getCheckInfoRequest() {
      dispatch(getCheckInfoRequest());
    },
    attachSocialLogin(socialResponse, site, action) {
      dispatch(attachSocialLogin(socialResponse, site, action));
    },
    validateSocialCredentials(socialResponse, site, callback) {
      return dispatch(validateSocialCredentials(socialResponse, site))
        .then(() => {
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.errors && response.data.errors[0]) {
            dispatch(displayFailMessage({ info: response.data.errors[0].title }));
          }
        });
    },
    toggleDesktopNotificationStatus(status) {
      dispatch(toggleDesktopNotificationStatus(status));
    },
    destroyOtherDeviceSession() {
      dispatch(destroyOtherDeviceSession());
    },
    checkProfile: username => {
      return dispatch(getAccountByUserName(username));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
