/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef, ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import TextLink from '../../common/TextLink';
import PriceInput, { adjustStylePerDigit } from './PriceInput';
import OfferPriceBtnSet from './OfferPriceBtnSet';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { addCommas, getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import {
  sendOffer,
  sendCancel,
  sendSuggest,
  sendAmountCustom,
  counterOffer,
  counterCancel,
  counterModalCustomAmount,
} from '../../../actions/profileTrackAction';
import isEmpty from 'lodash/isEmpty';
import WYPButtonIcon from '../../../atoms/icons/WYPButtonIcon';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import useEvidently from '../../../hooks/useEvidently';
import { EVIDENTLY_FEATURES, MinOfferIncreaseVariations } from '../../../constants/evidently';
import { PriceContainer, InputProps, CurrencyTip, CurrencyLabelSpan, status } from './common';
import { getDefaultOfferAmount } from '../../../utils/offers';
import { connect } from 'react-redux';
import { Account, AccountMaker } from '../../../models/Account';
import useModal from '../../../hooks/useModal';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const CenterDiv = styled('div')({
  textAlign: 'center',
  padding: '20px 0',
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_XS]: {
    fontSize: 27,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

const CounterRange = styled('div')({
  fontSize: 14,
  marginBottom: 12,
  textAlign: 'center',
});

const ErrorContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
});

const ErrorMessage = styled('div')({
  color: 'rgb(204, 84, 84)',
  margin: '16px auto 0',
  maxWidth: '280px',
});

const PurchaseCredits = styled('div')({
  textAlign: 'center',
  marginTop: '12px',
  textDecoration: 'underline',
});

const ButtonDiv = styled('div')({
  marginTop: 10,
  paddingTop: 20,

  [dimensions.SCREEN_MAX_SM]: {
    marginBottom: 12,
    paddingTop: 12,
    width: '100%',
  },
});

const OfferAmounts = [50, 75, 100, 150, 200];

const getHeadline = (label, currInitial, offer, currentUserIsGenerous, isCounter, username, userGender) => {
  const hasOffer =
    offer && !offer.suggest && offer.price_local && offer.price_original && offer.price_original !== null;

  const offerDescription = currentUserIsGenerous && isCounter ? 'request' : 'offer';

  if (isCounter || hasOffer) {
    const price_original = offer && offer.price_original ? offer.price_original : 0;
    return `Counter ${currInitial}${label}${price_original} ${offerDescription} from ${username}`;
  } else {
    return userGender === 'Male' ? (
      <span>
        Send <strong>{username}</strong> a first date incentive!
      </span>
    ) : (
      <span>
        Request a first date incentive from <strong>{username}</strong>!
      </span>
    );
  }
};

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.data.min_counter_price : offer && offer.data.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const renderCounterRange = (isCounter, currencyInitial, currLabel, minValue, maxValue) => {
  if (isCounter) {
    return (
      <CounterRange>
        min {currencyInitial}
        {currLabel}
        {minValue} max {currencyInitial}
        {currLabel}
        {maxValue}
      </CounterRange>
    );
  }
  return null;
};

const setCreateOfferAction = (e, offerAction) => {
  switch (offerAction) {
    case 'Send Counter':
      counterOffer(e);
      break;
    case 'Cancel Counter':
      counterCancel(e);
      break;
    case 'Send Offer':
      sendOffer(e);
      break;
    case 'Cancel Offer':
      sendCancel(e);
      break;
    case 'Suggest Offer':
      sendSuggest(e);
      break;
    default:
      return;
  }
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};

interface FavOfferCreateProps {
  avatarSrc: any;
  offer: any;
  errors: any;
  isCounter: any;
  closeModal: any;
  currencyLabel: any;
  currencyInitial: any;
  currentUserIsGenerous: any;
  sex: any;
  clearErrors: any;
  username: any;
  sendSuggestion: any;
  showWarn: any;
  cannot_make_offer_reasons: any;
  counterOffer: any;
  createNewOffer: any;
  hashId: any;
  buttonID: any;
  successOpen: any;
  isSuggestRequest: any;
  userGender: string;
  title: string;
  pageTitle: string;
  otherProfile: any;
  accountModel: Account;
}

const FavOfferCreate = ({
  avatarSrc,
  offer = '',
  errors,
  isCounter,
  closeModal,
  currencyLabel,
  currencyInitial,
  currentUserIsGenerous,
  sex,
  clearErrors,
  username,
  sendSuggestion,
  showWarn,
  cannot_make_offer_reasons,
  counterOffer,
  createNewOffer,
  hashId,
  buttonID,
  successOpen,
  isSuggestRequest,
  userGender = 'Male',
  title = 'Request A Date!',
  pageTitle,
  otherProfile,
  accountModel,
}: FavOfferCreateProps): ReactElement => {
  const { useActiveVariation } = useEvidently();

  const minOfferIncreaseVariation = useActiveVariation(
    EVIDENTLY_FEATURES.MIN_OFFER_INCREASE
  ) as MinOfferIncreaseVariations;

  const defaultOfferPrice = useMemo(() => {
    const defaultAmount = getDefaultOfferAmount();

    if (otherProfile.account_type === 'Attractive') {
      return otherProfile?.default_offer_amount ?? defaultAmount;
    }

    return defaultAmount;
  }, [otherProfile]);

  const shouldShowTooManyPendingOffersErrorModal = useMemo(() => accountModel.shouldShowTooManyLockedOffersDeals(), [
    accountModel,
  ]);

  const defaultMinOffer = useMemo(() => {
    switch (minOfferIncreaseVariation) {
      case '25Min':
        return '25';
      case '50Min':
        return '50';
      case '75Min':
        return '75';
      default:
        return '5';
    }
  }, [minOfferIncreaseVariation]);

  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState(isCounter ? '' : defaultOfferPrice);
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer, 'min', defaultMinOffer));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);
  const sendOfferBtn = useRef(null);
  const sendCancelBtn = useRef(null);
  const sendSuggestLink = useRef(null);
  const priceInput = useRef(null);
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const { showTooManyPendingOffersErrorModal } = useModal();

  useOnClickTriggerTracking(isCounter ? 'Send Counter' : 'Send Offer', sendOfferBtn, setCreateOfferAction);
  useOnClickTriggerTracking(isCounter ? 'Cancel Counter' : 'Cancel Offer', sendCancelBtn, setCreateOfferAction);
  useOnClickTriggerTracking('Suggest Offer', sendSuggestLink, setCreateOfferAction);

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    trackFocus(priceInput, isCounter ? counterModalCustomAmount : sendAmountCustom);
  }, [isCounter]);

  useEffect(() => {
    setMaxValue(offerMinMaxVal(offer, 'max', '500'));
    setMinValue(offerMinMaxVal(offer, 'min', defaultMinOffer));
  }, [offer, defaultMinOffer]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const inputOnChange = e => {
    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValue.toString().length;

    if (e.target.value.length <= maxLength) {
      const isError = inputVal < minValueNum || inputVal > maxValueNum;
      updateOfferPrice(inputVal, isError);
    } else {
      e.preventDefault();
    }
  };

  const currLabel = String.fromCharCode(currencyLabel);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = { price: offerPrice, account: hashId, source: pageTitle };
    const offerAmount = `${currencyInitial}${currLabel}${offerPrice}`;
    setOfferSending(true);

    showMandatoryCompleteProfile(
      () => {
        if (isCounter || isSuggestRequest) {
          counterOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
              successOpen(true, offerAmount, true);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        } else {
          createNewOffer(formData)
            .then(() => {
              closeModal();
              setOfferSending(false);
              successOpen(true, offerAmount);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        }
      },
      () => setOfferSending(false),
      isCounter || isSuggestRequest ? 'Counter Offer' : 'Make Offer'
    );
  };

  const onHandleSuggestion = e => {
    e.preventDefault();
    // check to see if user can make an offer before allowing it.
    setOfferSending(true);
    showMandatoryCompleteProfile(
      () => {
        if (cannot_make_offer_reasons === null) {
          // process suggestion

          sendSuggestion(hashId)
            .then(() => {
              closeModal();
              setOfferSending(false);
              // successOpen(true, offerAmount);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        } else {
          if (shouldShowTooManyPendingOffersErrorModal) {
            showTooManyPendingOffersErrorModal();
          } else {
            // explain suggestion fail reason.
            showWarn(`You cannot send an offer suggestion: ${cannot_make_offer_reasons[0]}`);
            setOfferSending(false);
          }
        }
      },
      () => setOfferSending(false),
      'Make Offer'
    );
  };

  const formatSubmitButtonText = () => {
    return isOfferSending ? `Sending ${status(currentUserIsGenerous)}...` : `Send ${status(currentUserIsGenerous)}`;
  };

  return (
    <Form>
      <HeaderDiv>
        <AvatarTitleDiv>
          <Avatar
            data-test-id="offer-modal-avatar"
            src={avatarSrc}
            width={getBrowserDetails().isMobile ? '75px' : '100px'}
            customStyle={{
              backgroundColor: '#ccc',
            }}
          />
          <TitleDiv>{title}</TitleDiv>
        </AvatarTitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            marginTop: 20,
            fontSize: getBrowserDetails().isMobile ? 14 : 16,
          }}
          data-test-id="profile-offer-headline"
          size="SM"
        >
          {getHeadline(currLabel, currencyInitial, offer, currentUserIsGenerous, isCounter, username, userGender)}
        </Headline>
      </HeaderDiv>
      <Content>
        <React.Fragment>
          <PriceContainer hasErrors={hasErrors}>
            <CurrencyTip
              offerPrice={colorType(offerPrice, hasErrors)}
              style={{
                paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
              }}
            >
              <CurrencyLabelSpan>
                {currencyInitial}
                {currLabel}
              </CurrencyLabelSpan>
              <PriceInput
                inputRef={priceInput}
                data-test-id="offer-price-input"
                hasErrors={hasErrors}
                value={offerPrice}
                onChange={inputOnChange}
                validateTextFn={validateTextInput}
                placeholder={defaultValue}
                inputProps={{
                  style: {
                    ...InputProps.style,
                    marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                    textAlign: 'left',
                  },
                }}
                minWidth={IsMobileViewPort ? 100 : 120}
              />
            </CurrencyTip>
            {renderCounterRange(isCounter, currencyInitial, currLabel, minValue, maxValue)}
          </PriceContainer>
          <OfferPriceBtnSet
            prices={minOfferIncreaseVariation === '75Min' ? OfferAmounts.filter(amount => amount !== 50) : OfferAmounts}
            updateOfferPrice={updateOfferPrice}
            currentAmount={offerPrice}
            currLabel={currLabel}
            currInitial={currencyInitial}
            offer={offer}
            isCounter={isCounter}
          />
          <div
            style={{
              textAlign: 'center',
              paddingTop: IsMobileViewPort ? 10 : 20,
              fontSize: IsMobileViewPort ? 14 : 16,
            }}
            data-test-id="profile-offer-desc"
          >
            Maximum {currentUserIsGenerous ? 'Offer' : 'Request'} amount is{' '}
            {currencyInitial + currLabel + addCommas(parseInt(maxValue, 10))}
          </div>
          {!isEmpty(errors) ? (
            <ErrorContainer>
              <ErrorMessage>{errors.price}</ErrorMessage>
              {currentUserIsGenerous && Number(offerPrice) >= 200 && (
                <PurchaseCredits>
                  <TextLink to="/packages">Purchase credits now.</TextLink>
                </PurchaseCredits>
              )}
            </ErrorContainer>
          ) : null}
        </React.Fragment>
        <ButtonDiv marginTop={16}>
          <ButtonSet align="center">
            <Button
              id={buttonID}
              buttonRef={sendOfferBtn}
              disabled={hasErrors || isOfferSending}
              buttonType="error"
              onClick={handleSubmit}
              type="submit"
            >
              <WYPButtonIcon style={{ marginRight: 6 }} />
              {formatSubmitButtonText()}
            </Button>
          </ButtonSet>
          {!isSuggestRequest && (
            <div>
              <CenterDiv>
                <strong>- OR -</strong>
              </CenterDiv>
              <span>
                <ButtonSet align="center">
                  <TextLink
                    linkRef={sendSuggestLink}
                    data-test-id="offer-suggest-link"
                    to="/"
                    disabled={hasErrors || isOfferSending}
                    onClick={e => onHandleSuggestion(e)}
                    type="submit"
                    style={{ color: '#BC2028', textDecoration: 'underline' }}
                  >
                    {' '}
                    Ask {sex === 'Male' ? 'him' : 'her'} to suggest an amount
                  </TextLink>
                </ButtonSet>
              </span>
            </div>
          )}
          <ButtonSet align="center">
            <Button
              buttonType="chromeless"
              buttonRef={sendCancelBtn}
              data-test-id={`${buttonID}Cancel`}
              disabled={isOfferSending}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
              customStyle={{ color: patternModal.linkBlue, fontWeight: 'normal', textDecoration: 'underline' }}
            >
              {' '}
              Cancel
            </Button>
          </ButtonSet>
        </ButtonDiv>
      </Content>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    accountModel: AccountMaker.create(state.profile),
  };
};

export default connect(mapStateToProps, {})(FavOfferCreate);
