import { AxiosPromise } from 'axios';
import {
  sendEvidentlyMetrics,
  SendEvidentlyMetricsParams,
  trackEvidentlyMetrics,
  TrackEvidentlyMetricsParams,
  trackEvidentlyMultipleMetrics,
} from '../sdk/CommonSDK';
import { EvidentlyFeatureDetails } from '../reducers/types/Common.d';
import { getLocalItem } from '../common';
import { getEvidentlyExperiment } from '../sdk';
import {
  AM_SET_DEFAULT_OFFER_FEATURE_NAME,
  AM_SET_DEFAULT_OFFER_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/AMSetDefaultOffer';
import {
  GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME,
  GM_DISCOVER_MODE_EDITABLE_OFFER_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/GMDiscoverModeEditableOffer';
import {
  ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME,
  ONE_TAP_OFFER_MANAGEMENT_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/oneTapOfferManagement';
import {
  DISCOVER_SWIPING_MODE_AM_FEATURE_NAME,
  DISCOVER_SWIPING_MODE_AM_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/discoverSwipingModeAM';
import {
  DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME,
  DISCOVER_SWIPING_MODE_DESKTOP_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/discoverSwipingModeDesktop';
import {
  ONBOARDING_MANDATORY_FIELDS_GM_FEATURE_NAME,
  ONBOARDING_MANDATORY_FIELDS_GM_LOCAL_STORAGE_KEY,
  ONBOARDING_MANDATORY_FIELDS_AM_FEATURE_NAME,
  ONBOARDING_MANDATORY_FIELDS_AM_LOCAL_STORAGE_KEY,
} from '../utils/evidentlyFeatures/onboardingFieldsMandatory/common';
import store from '../store';

export type NewCreditOfferVariations = 'Control' | 'IntroCreditOffer' | 'NoIntroOffer';
export type MinOfferIncreaseVariations = 'Control' | '25Min' | '50Min' | '75Min';
export const enum NewGoogleSSOExperimentMetrics {
  SIGNUP_FORM_VIEW = 'signUpFormView',
  JOIN_COMPLETED = 'joinCompleted',
}

export const evidentlyEnabled = (): boolean => (store && store.getState()?.settings?.is_evidently_enabled) ?? false;

export const RULE_MATCH_REASON = 'EXPERIMENT_RULE_MATCH';
export const DEFAULT_REASON = 'DEFAULT';

export type EvidentlyFeatureExperiment = {
  reason: string;
  userId: string;
  variation: string;
};

// export type NewGoogleSSOExperimentMetricKeyType = typeof NewGoogleSSOExperimentMetrics;

export const enum EVIDENTLY_EXPERIMENTS {
  MANDATORY_STEP_IN_REGISTRATION = 'MandatoryProfileStepInRegExperimentV2',
  NEW_PROFILE_WALL = 'NewProfileWallExperiment2',
  PHONE_NUMBER_SIGNUP = 'Phone-Number-Sign-Up-Experiment',
  MELISSA = 'MelissaExperimentV2',
  NO_PROFILE_FAVORITE = 'NoProfileFavoriteExperiment',
  NEW_CREDIT_OFFER = 'NewCreditOfferExperiment',
  MIN_OFFER_INCREASE = 'MinOfferIncreaseExperiment',
}

/**
 * NOTE: Don't use const enum
 * Need to access key
 * Else will get error:
 * 'const' enums can only be used in property or index access expressions
 * or the right hand side
 * of an import declaration or export assignment or type query.
 * ref: https://www.typescriptlang.org/docs/handbook/enums.html
 */
export enum EVIDENTLY_FEATURES {
  MANDATORY_STEP_IN_REGISTRATION = 'MandatoryProfileStepInReg',
  MANDATORY_PHOTO_AND_ABOUT_ME = 'MandatoryPhotoAndAboutMe',
  NEW_PROFILE_WALL = 'NewProfileWall',
  PHONE_NUMBER_SIGNUP = 'Phone-Number-Sign-Up',
  MELISSA = 'Melissa',
  NO_PROFILE_FAVORITE = 'NoProfileFavorite',
  NEW_CREDIT_OFFER = 'NewCreditOffer',
  MIN_OFFER_INCREASE = 'MinOfferIncrease',
}

export const enum EVIDENTLY_FEATURE_VARIATIONS {
  CONTROL = 'Control',
  NEW_FEATURE = 'NewFeature',
}
/**
 * NOTE: Don't use const enum
 * Need to access key
 * Else will get error:
 * 'const' enums can only be used in property or index access expressions
 * or the right hand side
 * of an import declaration or export assignment or type query.
 * ref: https://www.typescriptlang.org/docs/handbook/enums.html
 */
export enum EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS {
  MANDATORY_STEP_IN_REGISTRATION = 'evidently_registration_variation',
  MANDATORY_PHOTO_AND_ABOUT_ME = 'evidently_mandatory_photo_and_about_me',
  NEW_PROFILE_WALL = 'evidently_new_profile_wall_v2',
  PHONE_NUMBER_SIGNUP = 'evidently_phone_number_signup',
  MELISSA = 'evidently_melissa',
  NO_PROFILE_FAVORITE = 'evidently_no_profile_favorite',
  NEW_CREDIT_OFFER = 'evidently_new_credit_offer',
  MIN_OFFER_INCREASE = 'evidently_min_offer_increase',
}

export const EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS_BASED_ON_FEATURES = {
  [EVIDENTLY_FEATURES.MANDATORY_STEP_IN_REGISTRATION]: 'evidently_registration_variation',
  [EVIDENTLY_FEATURES.MANDATORY_PHOTO_AND_ABOUT_ME]: 'evidently_mandatory_photo_and_about_me',
  [EVIDENTLY_FEATURES.MELISSA]: 'evidently_melissa',
  [EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE]: 'evidently_no_profile_favorite',
  [EVIDENTLY_FEATURES.NEW_CREDIT_OFFER]: 'evidently_new_credit_offer',
  [EVIDENTLY_FEATURES.MIN_OFFER_INCREASE]: EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MIN_OFFER_INCREASE,
  [AM_SET_DEFAULT_OFFER_FEATURE_NAME]: AM_SET_DEFAULT_OFFER_LOCAL_STORAGE_KEY,
  [GM_DISCOVER_MODE_EDITABLE_OFFER_FEATURE_NAME]: GM_DISCOVER_MODE_EDITABLE_OFFER_LOCAL_STORAGE_KEY,
  [ONE_TAP_OFFER_MANAGEMENT_FEATURE_NAME]: ONE_TAP_OFFER_MANAGEMENT_LOCAL_STORAGE_KEY,
  [DISCOVER_SWIPING_MODE_AM_FEATURE_NAME]: DISCOVER_SWIPING_MODE_AM_LOCAL_STORAGE_KEY,
  [ONBOARDING_MANDATORY_FIELDS_GM_FEATURE_NAME]: ONBOARDING_MANDATORY_FIELDS_GM_LOCAL_STORAGE_KEY,
  [ONBOARDING_MANDATORY_FIELDS_AM_FEATURE_NAME]: ONBOARDING_MANDATORY_FIELDS_AM_LOCAL_STORAGE_KEY,
  [DISCOVER_SWIPING_MODE_DESKTOP_FEATURE_NAME]: DISCOVER_SWIPING_MODE_DESKTOP_LOCAL_STORAGE_KEY,
};

export const enum EVIDENTLY_METRICS_TIME_SESSIONS_STORAGE_KEYS {
  PAYWALL_N_OF_PURCHASED = 'evidently_paywall_send_metric_n_of_purchased',
  PAYWALL_AMOUNT_OF_PURCHASED = 'evidently_paywall_send_metric_amount_of_purchased',
  NEW_PROFILE_WALL_COMPLETIONS = 'evidently_new_profile_wall_completions',
}

export const enum EVIDENTLY_NEW_PROFILE_WALL_FEATURE_VARIATIONS {
  OLD_PROFILE_WALL = 'OldProfileWall',
  NEW_PROFILE_WALL_NO_ABOUT = 'NewProfileWallNoAbout',
  NEW_PROFILE_WALL_OPT_ABOUT = 'NewProfileWallOptAbout',
}

export const enum EVIDENTLY_NEW_PROFILE_WALL_METRIC_KEYS {
  COMPLETIONS = 'newProfileWallCompletions',
}

export const enum EVIDENTLY_MELISSA_METRIC_KEYS {
  PACKAGES_PURCHASED = 'melissaPackagesPurchased',
  PURCHASED_FROM_1ST_ATTEMPT = 'melissaPurchasesFrom1stAttempt',
  PURCHASED_ERRORS_FROM_KOUNT = 'melissaErrorsFromKount',
}

export const enum EVIDENTLY_NO_PROFILE_FAVORITE_KEYS {
  OFFERS_SENT = 'noProfileFavoriteOffersSent',
  OFFERS_ACCEPTED = 'noProfileFavoriteOffersAccepted',
  AMOUNT_PURCHASED = 'noProfileFavoriteAmountPurchased',
}
// in seconds
export const EVIDENTLY_METRICS_TIME_SESSIONS = {
  [EVIDENTLY_FEATURES.NEW_PROFILE_WALL]: 3600, // 1 hour
};

export const sendEvidentlyMetric = (
  feature: EvidentlyFeatureDetails,
  details: SendEvidentlyMetricsParams['details']
): void | AxiosPromise => {
  if (!evidentlyEnabled || !feature) return;
  const { userId } = feature;
  return sendEvidentlyMetrics({ userId, details });
};

export const sendEvidentlyNewGoogleSSOMetric = (
  feature: EvidentlyFeatureDetails,
  metricKey: NewGoogleSSOExperimentMetrics
): void | AxiosPromise => {
  return sendEvidentlyMetric(feature, { [metricKey]: 1 });
};

export const evidentlyRegistrationFeatureName = 'MandatoryProfileStepInReg';
export const evidentlyRegistrationExperimentName = 'MandatoryProfileStepInRegExperimentV2';
export const enum evidentlyRegistrationVariations {
  CONTROL = 'Control',
  NEW_FEATURE = 'NewFeature',
}
export const evidentlyRegistrationVariationLocalStorageKey = 'evidently_registration_variation';

export const enum OffersMetrics {
  OFFER_ACCEPTED = 'newOfferPageOffersAccepted',
}

// Offers page AB/Tet
export const enum evidentlyOfferVariations {
  CONTROL = 'OldOfferPage',
  NEW_FEATURE = 'NewOfferPage',
}

// MandatoryPhotoAndAboutMe
export const evidentlyMandatoryPhotoAndAboutMeFeatureName = 'MandatoryPhotoAndAboutMe';
export const enum evidentlyMandatoryPhotoAndAboutMeVariations {
  CONTROL = 'Control',
  NEW_FEATURE = 'NewFeature',
}
export const evidentlyMandatoryPhotoAndAboutMeLocalStorageKey = 'evidently_mandatory_photo_and_about_me';

export const enum MandatoryPhotoAndAboutMeMetrics {
  NUMBER_OF_OFFERS_MADE = 'numberOfOffersMade',
  NUMBER_OF_OFFERS_ACCEPTED = 'numberOfOffersAccepted',
  NUMBER_OF_FAVORITED_PROFILES = 'numberOfFavoritedProfiles',
}

// Phone number signups
export const enum evidentlyPhoneNumberSignupVariations {
  CONTROL = 'Control',
  PHONE_NUMBER_SIGNUP = 'PhoneNumberSignUp',
}

export const enum phoneNumberSignupMetrics {
  COMPLETIONS = 'phoneNumberSignUpCompletedJoins',
}

// Offers page AB/Tet
export const enum evidentlyMelissaVariations {
  CONTROL = 'Control',
  NEW_FEATURE = 'Melissa',
}

export const sendEvidentlyMandatoryPhotoAndAboutMeMetric = (
  metricKey: MandatoryPhotoAndAboutMeMetrics
): void | AxiosPromise => {
  const feature = JSON.parse(getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS.MANDATORY_PHOTO_AND_ABOUT_ME));
  if (feature === null) {
    return;
  }

  return sendEvidentlyMetric(feature, { [metricKey]: 1 });
};

export async function isEvidentlyExperimentLive(experiment: string): Promise<boolean> {
  if (!evidentlyEnabled) {
    return false;
  }

  try {
    const res = await getEvidentlyExperiment(experiment);
    const { isLive } = res.data;

    return isLive;
  } catch (error) {
    return false;
  }
}

export const trackEvidentlyMetric = async (
  localStorageKey: EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  formData: TrackEvidentlyMetricsParams
): Promise<void> => {
  if (!evidentlyEnabled) return;

  const feature = JSON.parse(getLocalItem(localStorageKey));
  if (feature === null) {
    return;
  }

  if (feature.reason === DEFAULT_REASON) {
    return;
  }

  await trackEvidentlyMetrics({
    ...formData,
    hashId: feature.userId,
  });
};

export const trackEvidentlyMultipleMetric = async <KeysType>(
  localStorageKey: EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS,
  formData: TrackEvidentlyMetricsParams<KeysType>[]
): Promise<void> => {
  if (!evidentlyEnabled) return;

  const feature = JSON.parse(getLocalItem(localStorageKey));
  if (feature === null) {
    return;
  }

  if (feature.reason === DEFAULT_REASON) {
    return;
  }

  const metrics: TrackEvidentlyMetricsParams<KeysType>[] = formData.map(data => {
    return {
      ...data,
      hashId: data.hashId || feature.userId,
    };
  });

  await trackEvidentlyMultipleMetrics(metrics);
};

export const showNewVariation = (evidently: any, feature: any): string | null => {
  if (!evidently || !(feature in evidently) || evidently[feature] === 'undefined') return null;

  const { variation } = evidently[feature];
  const localStorageVariation = getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS_BASED_ON_FEATURES[feature]);
  const bypassVariation = localStorageVariation ? JSON.parse(localStorageVariation) : null;

  return bypassVariation ? bypassVariation.variation : variation;
};

export const getFeatureDetails = (evidently: any, feature: any): null | EvidentlyFeatureExperiment => {
  if (!evidently || !(feature in evidently) || evidently[feature] === 'undefined') return null;

  const localStorageVariation = getLocalItem(EVIDENTLY_FEATURE_LOCAL_STORAGE_KEYS_BASED_ON_FEATURES[feature]);
  const bypassVariation = localStorageVariation ? JSON.parse(localStorageVariation) : null;

  return bypassVariation ? bypassVariation : evidently[feature];
};
