import React from 'react';
import colors, { white } from '../../../theme/colors';
import { theme } from '../../../theme';
import { connect } from 'react-redux';

interface Props {
  count: number;
}
const OneTapOfferUnreadBadge: React.FC<Props> = ({ count }) => {
  if (!count || count < 1) {
    return null;
  }

  return (
    <span
      className="oneTapUnreadOfferBadge"
      style={{
        position: 'absolute',
        top: -13,
        right: -13,
        padding: '4px 7px',
        textAlign: 'center',
        borderRadius: 17,
        backgroundColor: colors[theme].notifications.red,
        color: white,
        fontSize: 11,
        fontWeight: 500,
        minWidth: 10,
      }}
    >
      {count > 99 ? '99+' : count}
    </span>
  );
};

const mapStateToProps = state => {
  return {
    count: state.unreadOneTapOffersCount.count,
  };
};

export default connect(mapStateToProps, null)(OneTapOfferUnreadBadge);
