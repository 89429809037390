/**
 * @Author: zachalam
 * @Date:   2017-01-25T11:21:47-08:00
 * @Last modified by:   zachalam
 * @Last modified time: 2017-01-25T11:21:47-08:00
 */

import * as Sentry from '@sentry/react';

type Loadstate = Record<string, unknown> | void;
type Savestate = void;

export const loadState = (): Loadstate => {
  if (!'localStorage' in window) {
    return console.error('Local storage is not supported.');
  }

  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    Sentry.captureException(err, { tags: { component: 'LocalStorage', action: 'LoadState' } });
  }
};

export const saveState = (state: Record<string, unknown>): Savestate => {
  if (!'localStorage' in window) {
    return console.error('Local storage is not supported.');
  }

  const transformState = {
    ...state,
    profile: profileTransformer(state),
  };

  try {
    const serializedState = JSON.stringify(transformState);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    Sentry.captureException(err, { tags: { component: 'LocalStorage', action: 'SaveState' } });
  }
};

export const clearLocalStorageExcept = (keysToRetain: string[]): void => {
  if (!'localStorage' in window) {
    return console.error('Local storage is not supported.');
  }

  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);

    if (!keysToRetain.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

const profileTransformer = (state: any) => {
  const account = state?.profile ?? null;

  if (account === null) {
    return {};
  }

  // comment out for now having issues when doing hard refresh
  // redux state is relying on localstorage when user is doing a hard refresh
  return {
    // hash: account.hash_id,
    // id_verification: account.id_verification,
    // offer_counts: account.offer_counts,
    // credits: account.credits,
    // product_affinity: account.product_affinity,
    // profile: account.profile,
    ...account,
  };
};
