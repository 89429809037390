import axios, { AxiosPromise } from 'axios';
import MasterConfig from '../config/Master';
import { setLocalItem } from '../common';

const api = MasterConfig.api;

const getFavorites = (token: any, type: string, per_page: number, page = 1): AxiosPromise => {
  const numberToGet = per_page === 0 ? MasterConfig.per_page : per_page;

  return axios({
    method: 'get',
    url: `${api}/favorite/pre/${type}?include=other_account.profile,offer&page=${page}&per_page=${numberToGet}`,
    data: {},
  });
};

const getInterested = (token: any, isFresh: any, per_page: number, page = 1): AxiosPromise => {
  const numberToGet = per_page === 0 ? MasterConfig.per_page : per_page;

  return axios({
    method: 'get',
    url: `${api}/account-interested?include=other_account.profile,offer&page=${page}&per_page=${numberToGet}&fresh=${isFresh}`,
    data: {},
  });
};

const getInterestedV2 = (token: any, isFresh: any, per_page: number, page = 1): AxiosPromise => {
  const numberToGet = per_page === 0 ? MasterConfig.per_page : per_page;

  return axios({
    method: 'get',
    url: `${api}/account-interested?include=other_account.profile,offer&page=${page}&per_page=${numberToGet}&fresh=${isFresh}&is_new_variant=true`,
    data: {},
  });
};

const ignoreInterested = (token: any, userHashId: any): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account-interested/ignore`,
    data: { hash_id: userHashId },
  });
};

const markAsReadInterested = (token: any, hashIds: string[]): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/account-interested/mark-as-read`,
    data: { hash_ids: hashIds },
  });
};

const toggleFavorite = (token: any, profileId: any): AxiosPromise => {
  const favAxios = {
    method: 'put',
    url: `${api}/favorite/${profileId}`,
    data: {},
  };
  if (!navigator.onLine) {
    const pendingFav = {
      profile_id: profileId,
      value: favAxios,
    };
    setLocalItem('pending_fav', JSON.stringify(pendingFav));
  }
  return axios(favAxios);
};

export { getFavorites, getInterested, getInterestedV2, ignoreInterested, toggleFavorite, markAsReadInterested };
