import React, { useRef } from 'react';
import styled from '@emotion/styled';
import {
  sendAmount50,
  sendAmount75,
  sendAmount100,
  sendAmount150,
  sendAmount200,
  sendSuggest,
} from '../../../actions/profileTrackAction';
import { dimensions } from '../../../style';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import colors from '../../../theme/colors';
import borders from '../../../theme/borders';

const OfferPriceBtnWrapper = styled('ul')({
  fontSize: 15,
  margin: '5px',
  padding: 0,
  listStyle: 'none',
  textAlign: 'center',
});

const OfferPriceBtn = styled('li')(
  {
    display: 'inline-block',
    marginRight: 8,
    padding: '2px 9px',
    cursor: 'pointer',
    border: `1px solid ${borders.light.line1}`,
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    color: '#000',
    marginTop: 10,
    '&:last-of-type': {
      marginRight: 0,
    },
    [dimensions.SCREEN_MAX_XS]: {
      fontSize: 12,
      padding: '4px 10px',
      marginRight: 2,
    },
  },
  props =>
    props.isActive
      ? {
          backgroundColor: colors.light.blue.secondary,
          borderColor: colors.blue[400],
          color: 'white',
        }
      : null
);

const setPriceAction = (e, price) => {
  switch (price) {
    case 'Send 50':
      sendAmount50(e);
      break;
    case 'Send 74':
      sendAmount75(e);
      break;
    case 'Send 100':
      sendAmount100(e);
      break;
    case 'Send 150':
      sendAmount150(e);
      break;
    case 'Send 200':
      sendAmount200(e);
      break;
    case 'Send Suggestion':
      sendSuggest(e);
      break;
    default:
      return;
  }
};

function OfferPriceBtnSet(props: any): any {
  const { prices, updateOfferPrice, currentAmount, isCounter, currLabel, currInitial, offer, customStyle = {} } = props;
  const send50Btn = useRef(null);
  const send75Btn = useRef(null);
  const send100Btn = useRef(null);
  const send150Btn = useRef(null);
  const send200Btn = useRef(null);
  const sendSuggestionBtn = useRef(null);

  useOnClickTriggerTracking('Send 50', send50Btn, setPriceAction);
  useOnClickTriggerTracking('Send 74', send75Btn, setPriceAction);
  useOnClickTriggerTracking('Send 100', send100Btn, setPriceAction);
  useOnClickTriggerTracking('Send 150', send150Btn, setPriceAction);
  useOnClickTriggerTracking('Send 200', send200Btn, setPriceAction);
  useOnClickTriggerTracking('Send Suggestion', sendSuggestionBtn, setPriceAction);

  const offerBtn = {
    50: send50Btn,
    75: send75Btn,
    100: send100Btn,
    150: send150Btn,
    200: send200Btn,
    '???': sendSuggestionBtn,
  };

  const Amounts = prices.map(number => {
    if (offer && offer.suggest && number === '???') {
      return null;
    }

    return (
      <OfferPriceBtn
        ref={offerBtn[number]}
        isActive={Number(currentAmount) === number || (number === '???' && currentAmount === '???')}
        key={number}
        onClick={() => updateOfferPrice(number.toString())}
        data-value={number}
      >
        {`${currInitial}${currLabel}${number}`}
      </OfferPriceBtn>
    );
  });

  if ((isCounter && offer && offer.suggest && offer.price_original) || !isCounter) {
    return <OfferPriceBtnWrapper style={customStyle}>{Amounts}</OfferPriceBtnWrapper>;
  } else {
    return null;
  }
}

export default OfferPriceBtnSet;
