import React, { useState } from 'react';
import styled from '@emotion/styled';

import TopBanner from '../../atoms/TopBanner';
import Button from '../../atoms/buttons/Button';
import { dimensions, textColor, iconsTheme, backgroundTheme } from '../../style';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { resendEmail } from '../../actions/authActions';
import EmailConfirmV2 from '../../modules/modals/EmailConfirmV2';
import { getLocalItem } from '../../common';
import { auth10tixVerification } from '../../config/Master';
import { commonIcons } from '../../atoms/icons/materialIcons';
import {
  CheckCircleOutline,
  PolicyOutlined,
  ErrorOutlineOutlined,
  CameraAltOutlined,
  MailOutlineOutlined,
  AccountCircleOutlined,
  VerifiedUserOutlined,
} from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { lazilyEvaluateVal } from '../../utils/helpers';

const Container = styled('div')`
  padding: 0;
  margin: 0 auto;
  margin-top: 15px;
  ${props => (props.relative ? 'position: relative;' : null)} ${dimensions.SCREEN_MAX_MD} {
    ${props => (props.fullWidth ? 'padding: 0;' : null)} ${props =>
      props.spacedBottom ? 'margin-bottom: 22px;' : null};
  }
  ${dimensions.SCREEN_MIN_MD} {
    ${props => (props.screenTop ? 'margin-top: 22px; margin-bottom: 22px;' : null)};
  }
`;

const InfoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoItemLeft = styled('div')`
  padding-right: 5px;
`;

const InfoItemRight = styled('div')`
  padding-left: 5px;
`;

const InfoHeader = styled('div')`
  display: flex;
  font-weight: 600;
  max-width: 1070px;
  margin: 0 auto;
  ${props => props.profilev2 && 'color: #3e79a5; font-size: 16px;'}
`;

const InfoItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 1070px;
  margin: 0 auto;
`;

const InfoItem = styled('div')`
  display: flex;
  padding: 12px 10px 0px 10px;
  width: 100%;
  justify-content: space-between;
`;

const IconWrapper = styled('div')`
  margin-right: 5px;
`;

const SmallIcon = styled('div')`
  font-size: 11px;
`;

const InfoItemText = styled('div')`
  display: flex;
  ${props => props.profilev2 && 'align-items: center;'}
`;

const InfoItemDescription = styled('div')(({ profilev2 }) => ({
  fontWeight: profilev2 ? 400 : 600,
  fontSize: profilev2 ? 16 : 'inherit',
  color: profilev2 ? `${textColor.DARKER}` : 'inherit',
}));

const InfoItemTextStatus = styled('div')`
  font-weight: normal;
  font-size: 12px;
`;

const InfoItemAction = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 10px;
  width: 45px;
`;

const ActionLink = styled('span')`
  text-decoration: underline;
  color: ${textColor.DARKER.color};
  font-weight: normal;
`;

const determineColor = status => {
  switch (status) {
    case 'complete':
    case 'approved':
      return '#098a00';
    case 'denied':
    case 'error':
      return '#e17055';
    case 'not submitted':
    case 'not_started':
    default:
      return iconsTheme.profileCompleteness;
  }
};

const IconStyle = {
  fontSize: 26,
  color: '#3e79a5',
};

const commonStyles = status => {
  return {
    fontSize: 26,
    color: determineColor(status),
  };
};

const buttonStyles = {
  background: backgroundTheme.block,
  ':hover': {
    backgroundColor: backgroundTheme.block,
    ...textColor.DARKEST,
  },
};

const renderIcon = profileVersion => {
  if (profileVersion) {
    return <SvgIcon component={MailOutlineOutlined} style={IconStyle} />;
  } else {
    return <commonIcons.email.default />;
  }
};

const renderEmailActionBtn = (
  stillPendingConfirmation,
  onboarding,
  openStatus,
  handleResendEmail,
  handleToggleOpenStatus
) => {
  if (stillPendingConfirmation) {
    const email = onboarding.profile.email;
    return (
      <EmailConfirmV2
        email={email}
        openStatus={openStatus}
        handleOnClick={handleResendEmail}
        handleToggleOpenStatus={handleToggleOpenStatus}
        variant="profilev2"
      />
    );
  } else {
    return <SvgIcon id="CheckCircleOutline" component={CheckCircleOutline} style={commonStyles('complete')} />;
  }
};

const renderPhotoStatus = (hasSubmittedPhoto, hasDeniedPhoto) => {
  if (hasSubmittedPhoto) {
    return 'Your photos are in review';
  } else if (hasDeniedPhoto) {
    return <span style={textColor.RED}>Your photo/s have been denied.</span>;
  } else {
    return '';
  }
};

const profileStyles = profileFinished => (profileFinished ? 'complete' : 'not submitted');

const CompletProfileBanner = ({
  type,
  profilev2,
  onboarding,
  isFirsTimeLogin,
  resendReminderEmail,
  handleEditProfile,
}) => {
  const emailSent = getLocalItem('trigger_emailChange');
  const [openStatus, setOpenStatus] = useState((isFirsTimeLogin && !emailSent) || false);

  const navigate = useNavigate();

  const handleResendEmail = async () => {
    const res = await resendReminderEmail();
    setOpenStatus(res);
  };

  const handleToggleOpenStatus = status => {
    if (profilev2) {
      const fullUrl = window.location.href.split('?');
      const profileId = fullUrl[0].split('/').pop();
      navigate(`/profile/${profileId}`);
    }
    setOpenStatus(status);
  };

  const editProfileLink = `profile/`;
  const submittedProfile =
    onboarding.profileFinished && !onboarding.profile.is_approved && onboarding.profile.under_review.length > 0;
  const hasSubmittedPhoto =
    onboarding.photoFinished && !onboarding.photoApproved && onboarding.profile.photo.data.length > 0;
  const hasDeniedPhoto =
    onboarding.photoFinished && !onboarding.photoApproved && onboarding.profile.photo.data.length <= 0;
  const hasApprovedProfile =
    onboarding.profileFinished && !onboarding.profile.is_approved && onboarding.profile.under_review.length <= 0;
  const { profileFinished } = onboarding;

  const displayInCompleteProfile = () => {
    if (onboarding && onboarding.profile && onboarding.profile.is_approved) {
      return false;
    }

    if (!submittedProfile && !hasApprovedProfile) {
      return true;
    }

    if (submittedProfile && !hasSubmittedPhoto) {
      return true;
    }

    if (hasDeniedPhoto) {
      return true;
    }

    return false;
  };

  const displayInCompletePhoto = () => {
    if (
      (onboarding && onboarding.profile && onboarding.profile.has_photo) ||
      (onboarding && onboarding.profile && onboarding.profile.is_approved)
    ) {
      return false;
    }

    if (!hasSubmittedPhoto || hasDeniedPhoto) {
      return true;
    }

    if (hasSubmittedPhoto && !submittedProfile) {
      return true;
    }

    return false;
  };

  const getCondition = category => {
    switch (category) {
      case 'Photo':
        return [
          (hasSubmittedPhoto && hasApprovedProfile) || (!hasSubmittedPhoto && !hasDeniedPhoto),
          hasSubmittedPhoto && !hasApprovedProfile,
          hasDeniedPhoto,
        ];
      case 'Profile':
        return [profileFinished, !profileFinished];
      default:
        return [];
    }
  };

  const outputResult = {
    photo_id: ['CheckCircleOutline', 'PolicyOutlined', 'ErrorOutlineOutlined', 'CheckCircleOutline'],
    profile_id: ['CheckCircleOutline', 'CheckCircleOutline'],
    photo_icon: [CheckCircleOutline, PolicyOutlined, ErrorOutlineOutlined, CheckCircleOutline],
    profile_icon: [CheckCircleOutline, CheckCircleOutline],
  };

  // this method below is to determine what svg icon is set based on specific condition so cypress can detect
  const determineSVGIcon = (category, outputType) => {
    const conditions = getCondition(category);
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i]) {
        return outputResult[outputType][i];
      }
    }
    return outputResult[outputType][conditions.length];
  };

  const photoStyles = () => {
    switch (true) {
      case !displayInCompletePhoto():
        return 'complete';
      case hasSubmittedPhoto && !submittedProfile:
        return 'complete';
      case !hasSubmittedPhoto && !hasDeniedPhoto:
        return 'not submitted';
      case hasDeniedPhoto:
        return 'error';
      default:
        return 'not submitted';
    }
  };

  const verificationIcon = () => {
    switch (onboarding.profile.id_verification?.data?.status) {
      case 'approved':
        return CheckCircleOutline;
      case 'denied':
        return ErrorOutlineOutlined;
      default:
        return ErrorOutlineOutlined;
    }
  };

  const renderVerificationStatus = () => {
    // commenting out possible logic for now. need to verify
    // const showStatus = auth10tixVerification && onboarding.profile.id_verification && onboarding.profile.id_verification.status === 'approved';

    return (
      auth10tixVerification &&
      profilev2 && (
        <InfoItem data-test-id="profile-verification-info">
          <InfoItemText profilev2={profilev2}>
            <IconWrapper>
              <SvgIcon component={VerifiedUserOutlined} style={IconStyle} />
            </IconWrapper>
            <InfoItemDescription profilev2={profilev2}>ID Verification</InfoItemDescription>
          </InfoItemText>
          <InfoItemAction>
            <SvgIcon
              id={determineSVGIcon('Verification', 'profile_id')}
              component={verificationIcon()}
              style={commonStyles(onboarding.profile.id_verification?.data?.status || null)}
            />
          </InfoItemAction>
        </InfoItem>
      )
    );
  };

  return (
    <Container data-test-id="completion-banner">
      {type === 'complete' ? (
        <TopBanner status="info">
          <InfoHeader profilev2={profilev2}>Complete your profile</InfoHeader>
          <InfoItemContainer data-test-id="profile-completion-banner">
            {(lazilyEvaluateVal(onboarding, 2, true, 'profile', 'email_confirmed') || profilev2) && (
              <InfoItem data-test-id="profile-verify-email">
                <InfoItemText profilev2={profilev2}>
                  <IconWrapper>{renderIcon(profilev2)}</IconWrapper>
                  <InfoItemDescription profilev2={profilev2}>Verify your email address</InfoItemDescription>
                </InfoItemText>
                <InfoItemAction>
                  {renderEmailActionBtn(
                    lazilyEvaluateVal(onboarding, 2, true, 'profile', 'email_confirmed'),
                    onboarding,
                    openStatus,
                    handleResendEmail,
                    handleToggleOpenStatus,
                    profilev2
                  )}
                </InfoItemAction>
              </InfoItem>
            )}

            {profilev2 && (
              <InfoItem data-test-id="profile-upload-photo">
                <InfoItemText profilev2={profilev2}>
                  <IconWrapper>
                    <SvgIcon component={CameraAltOutlined} style={IconStyle} />
                  </IconWrapper>
                  <InfoItemDescription profilev2={profilev2}>Add a photo</InfoItemDescription>
                </InfoItemText>
                <InfoItemAction>
                  <SvgIcon
                    id={determineSVGIcon('Photo', 'photo_id')}
                    component={determineSVGIcon('Photo', 'photo_icon')}
                    style={commonStyles(photoStyles())}
                  />
                </InfoItemAction>
              </InfoItem>
            )}

            {profilev2 && (
              <InfoItem data-test-id="profile-complete-info">
                <InfoItemText profilev2={profilev2}>
                  <IconWrapper>
                    <SvgIcon component={AccountCircleOutlined} style={IconStyle} />
                  </IconWrapper>
                  <InfoItemDescription profilev2={profilev2}>Complete your profile info</InfoItemDescription>
                </InfoItemText>
                <InfoItemAction>
                  <SvgIcon
                    id={determineSVGIcon('Profile', 'profile_id')}
                    component={determineSVGIcon('Profile', 'profile_icon')}
                    style={commonStyles(profileStyles(profileFinished))}
                  />
                </InfoItemAction>
              </InfoItem>
            )}

            {displayInCompleteProfile() && !profilev2 && (
              <InfoItem data-test-id="profile-complete-info">
                <InfoItemText>
                  <IconWrapper>
                    <commonIcons.account.default />
                  </IconWrapper>
                  <InfoItemDescription>
                    Complete your profile info
                    {submittedProfile && <InfoItemTextStatus>Waiting for moderator approval.</InfoItemTextStatus>}
                  </InfoItemDescription>
                </InfoItemText>
                <InfoItemAction>
                  <Link
                    to=""
                    onClick={e => {
                      e.preventDefault();
                      handleEditProfile();
                    }}
                  >
                    <SmallIcon>
                      <commonIcons.edit.small />
                    </SmallIcon>
                    <ActionLink>Edit</ActionLink>
                  </Link>
                </InfoItemAction>
              </InfoItem>
            )}

            {displayInCompletePhoto() && !profilev2 && (
              <InfoItem data-test-id="profile-upload-photo">
                <InfoItemText>
                  <IconWrapper>
                    <commonIcons.camera.default />
                  </IconWrapper>
                  <InfoItemDescription>
                    Add a photo
                    <InfoItemTextStatus>{renderPhotoStatus(hasSubmittedPhoto, hasDeniedPhoto)}</InfoItemTextStatus>
                  </InfoItemDescription>
                </InfoItemText>
                <InfoItemAction>
                  <Link to={`${editProfileLink}${lazilyEvaluateVal(onboarding, 2, false, 'profile', 'hash_id')}`}>
                    <SmallIcon>
                      <commonIcons.upload.small />
                    </SmallIcon>
                    <ActionLink>Upload</ActionLink>
                  </Link>
                </InfoItemAction>
              </InfoItem>
            )}

            {onboarding &&
              onboarding.profile &&
              !onboarding.profile.is_approved &&
              !displayInCompletePhoto() &&
              !displayInCompleteProfile() &&
              !profilev2 && (
                <InfoItem>
                  <InfoItemText>
                    <IconWrapper>
                      <commonIcons.wait.default />
                    </IconWrapper>
                    <InfoItemDescription>
                      Your profile is in moderation
                      <InfoItemTextStatus>We&#39;ll email you when it&#39;s ready.</InfoItemTextStatus>
                    </InfoItemDescription>
                  </InfoItemText>
                </InfoItem>
              )}
            {renderVerificationStatus()}
          </InfoItemContainer>
        </TopBanner>
      ) : (
        <TopBanner status="info">
          <InfoContainer data-test-id="dashboard-completion-banner">
            <InfoItemLeft>Complete your profile to start dating.</InfoItemLeft>
            <InfoItemRight>
              <Button
                size="small"
                to={`/${editProfileLink}${lazilyEvaluateVal(onboarding, 2, false, 'profile', 'hash_id')}`}
                customStyle={buttonStyles}
              >
                Edit Profile
              </Button>
            </InfoItemRight>
          </InfoContainer>
        </TopBanner>
      )}
    </Container>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    onboarding: {
      profile,
      emailConfirmed: profile && profile.email_confirmed,
      profileApproved: profile && profile.is_approved,
      profileFinished: profile && profile.profile && profile.profile.data.required_fields_completed,
      photoFinished: profile && profile.photo_complete,
      verificationStatus: profile && profile.id_verification?.data?.status,
      photoApproved:
        profile && Boolean(profile.photo && profile.photo.data[0] && profile.photo.data[0].status === 'approved'),
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resendReminderEmail() {
      return dispatch(resendEmail());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletProfileBanner);
