// ThreadEntryV2 resolve conflict
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { borders, color, textColor, textType, type } from '../../style';
import RepliedIcon from '../../atoms/icons/Replied';
import LockedIcon from '../../atoms/icons/Locked';
import Avatar from '../../atoms/Avatar';
import { commonIcons } from '../icons/materialIcons';

const Entry = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    borderBottom: borders.DEFAULT,
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    padding: '10px',
  },
  ({ isOpen }) => ({
    borderRight: isOpen ? '4px solid #3E79A5' : 'none',
    paddingRight: isOpen ? '6px' : '10px',
  })
);

const ThreadStatus = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  width: 24,
  justifyContent: 'center',
});

const Preview = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  marginLeft: '6px',

  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Details = styled('div')({
  display: 'flex',
  width: '98%',
  '> div:first-of-type': {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const ThreadDetails = styled('div')({
  flex: 1,
});

const Snippet = styled('div')(
  {
    display: 'flex',
    fontSize: 13,
  },
  ({ isUnread }) => ({
    fontWeight: isUnread ? 600 : 400,
    color: isUnread ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.65)',
  })
);

const ThreadMessage = styled('span')(
  {
    ...textColor.DARK,
    '&:first-of-type': {
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  ({ isLocked }) => !isLocked && { width: '146px' }
);

const UnreadIcon = styled('div')({
  height: 12,
  width: 12,
  borderRadius: 7,
  backgroundColor: color.BRAND_1,
});

const OnlineIcon = styled('div')({
  height: 12,
  width: 12,
  borderRadius: 7,
  backgroundColor: color.BRAND_1_ONLINE,
});

const TimeStampStatus = styled('div')({
  marginBottom: '6px',
  textAlign: 'right',
});

const NewMessage = styled('span')({
  color: '#ffffff',
  borderRadius: 3,
  backgroundColor: '#1986D4',
  fontSize: 12,
  textAlign: 'center',
  lineHeight: '17px',
  padding: '0 5px',
  fontWeight: 'bold',
  marginRight: 5,
});

const Username = styled('div')(
  {
    ...textType.header,
    ...type.MD,
  },
  ({ isUnread }) => ({
    fontWeight: isUnread ? 600 : 400,
    ...textColor.DARK,
  })
);

const TimeStamp = styled('span')({
  ...textType.caption,
});

const PhotoMessage = styled('span')({
  color: '#FFFFFF',
  backgroundColor: '#FF9900',
  padding: '2px 8px',
  fontSize: 10,
  borderRadius: 4,
  display: 'inline-block',
});

const renderMessage = (message, isPhoto = false, isDeleted = false) => {
  if (isPhoto && !isDeleted) {
    return <PhotoMessage>IMG</PhotoMessage>;
  }
  if (isPhoto && isDeleted) {
    return 'This message is deleted';
  }

  return <span dangerouslySetInnerHTML={{ __html: message }} />;
};

interface ThreadEntryProps {
  username: any;
  avatar: any;
  message: any;
  isPhoto: any;
  isNew: any;
  iReplied: any;
  isLocked: any;
  isUnread: any;
  lastMsgDate: any;
  onThreadClick: any;
  selected: any;
  isSeveredAccount: any;
  accountType: any;
  isOnline: any;
  isDeleted: any;
  admin: boolean;
}

const ThreadEntry = ({
  username,
  avatar,
  message,
  isPhoto,
  isNew,
  iReplied,
  isLocked,
  isUnread,
  lastMsgDate,
  onThreadClick,
  selected,
  isSeveredAccount,
  accountType,
  isOnline,
  isDeleted,
  admin,
}: ThreadEntryProps): any => {
  return (
    <Entry onClick={onThreadClick} isOpen={selected}>
      <Avatar src={avatar} width={66} shape="circle" isAdmin={admin} />
      {isSeveredAccount ? (
        <Preview>
          <Details isNew={isNew} isUnread={isUnread || isLocked}>
            <div>{accountType}</div>
          </Details>
        </Preview>
      ) : (
        <ThreadDetails>
          <TimeStampStatus>
            {isNew && <NewMessage>New</NewMessage>}
            <TimeStamp>{lastMsgDate}</TimeStamp>
          </TimeStampStatus>
          <Preview>
            <div style={{ display: 'inline-block' }}>
              <Details>
                <Username isUnread={isUnread || isNew || isLocked}>
                  {username} {admin && <commonIcons.check.badge />}
                </Username>
              </Details>
              <Snippet isUnread={isUnread || isNew || isLocked}>
                <ThreadMessage isLocked={isLocked}>
                  {iReplied && <RepliedIcon />}

                  {isLocked ? 'Conversation locked' : renderMessage(message, isPhoto, isDeleted)}
                </ThreadMessage>
              </Snippet>
            </div>

            <ThreadStatus>
              {isLocked && <LockedIcon unread={isUnread} locked={isLocked} />}
              {!isLocked && isUnread && <UnreadIcon />}
              {!isLocked && !isUnread && isOnline && <OnlineIcon />}
            </ThreadStatus>
          </Preview>
        </ThreadDetails>
      )}
    </Entry>
  );
};

ThreadEntry.propTypes = {
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  iReplied: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
  lastMsgDate: PropTypes.string.isRequired,
  onThreadClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ThreadEntry;
