import React, { useEffect, useState, ReactElement, useRef } from 'react';
import PropTypes from 'prop-types';
import Rheostat from 'rheostat';
import MediaQuery from 'react-responsive';
import { remove, includes } from 'lodash';
import styled from '@emotion/styled';

import FiltersLivingIn from './FiltersLivingIn';
import Container from '../../blocks/Container';
import Checkbox from '../../../atoms/Checkbox';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import TextInput from '../../../atoms/TextInput';
import FilterSection from '../../blocks/FilterSection';
import UserConfig from '../../../config/User';
import { extractLocationData, metricConv } from '../../../common';
import { backgroundTheme, buttonTheme, dimensions, rheostatTheme } from '../../../style';
import { isDarkMode, getBrowserDetails } from '../../../utils/helpers';
import { auth10tixVerification } from '../../../config/Master';
import DefaultSearch from '../../../config/Search';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import { searchResetFilters, searchApplyFilters } from '../../../actions/searchTrackAction';

const MobileApplyFiltersButton = styled(Button)`
  ${({ hideButton }) => hideButton && 'display: none'}
`;

const FilterSpacer = styled('div')({
  paddingTop: 90,

  [dimensions.SCREEN_MAX_SM]: {
    paddingTop: 0,
  },
});

const generousProfile = (gender, accountType, lookingFemale, lookingMale) =>
  gender === 'Male' && accountType === 'Generous' && lookingFemale && !lookingMale;

const setAgeFilter = (gender, accountType, lookingFemale, lookingMale, setSearchFilters) => {
  if (generousProfile(gender, accountType, lookingFemale, lookingMale)) {
    return setSearchFilters.age_max < UserConfig.maximumAgeMaleForFemale
      ? setSearchFilters.age_min + '-' + setSearchFilters.age_max
      : setSearchFilters.age_min + '+';
  } else if (setSearchFilters.age_max < UserConfig.maximumAgeFemaleForMale) {
    return setSearchFilters.age_min + '-' + setSearchFilters.age_max;
  } else {
    return setSearchFilters.age_min + '+';
  }
};

const setRheostatDarkMode = rheostat => {
  const themeDarkMode = isDarkMode();

  if (themeDarkMode) {
    rheostat[0].style.backgroundColor = rheostatTheme.bgColor;
    rheostat[1].style.backgroundColor = rheostatTheme.bgColor;
  }
};

const setAgeMax = (searchFilters, defaultMaxAge) =>
  searchFilters.age_max < defaultMaxAge ? searchFilters.age_max : defaultMaxAge;

const setLoc = place => {
  const emptyLocation = {
    city: '',
    country: '',
    latitude: '',
    longitude: '',
    region: '',
  };

  // apply location to state
  return !place ? emptyLocation : extractLocationData(place);
};

interface FiltersProps {
  gender: any;
  accountType: any;
  lookingMale: any;
  lookingFemale: any;
  searchFilters: any;
  resetFiltersState: any;
  resetFilters: any;
  screenSize: any;
  applyFiltersHandler: any;
}

const Filters = ({
  gender,
  accountType,
  lookingMale,
  lookingFemale,
  searchFilters,
  resetFiltersState,
  resetFilters,
  screenSize,
  applyFiltersHandler,
}: FiltersProps): ReactElement => {
  const defaultMaxAge = generousProfile(gender, accountType, lookingFemale, lookingMale)
    ? UserConfig.maximumAgeMaleForFemale
    : UserConfig.maximumAgeFemaleForMale;
  const [mySearchFilters, setSearchFilters] = useState({
    ...searchFilters,
    age_max: setAgeMax(searchFilters, defaultMaxAge),
  });
  const [hiddenMobileApplyFilterButton, setHiddenMobileApplyFilter] = useState(false);
  const bodyTypes = mySearchFilters.body_types !== undefined ? 'body_types' : 'body_type';
  const sameSearchFilters = mySearchFilters === searchFilters;

  const resetFilterBtn = useRef(null);

  useOnClickTriggerTracking('Reset Filters', resetFilterBtn, searchResetFilters);

  const setHiddenMobileApplyFilterButton = status => {
    // true = hidden, false = visible.
    setHiddenMobileApplyFilter(status);
  };

  useEffect(() => {
    // do componentDidMount logic
    const rheostat = document.getElementsByClassName('rheostat-progress');
    setRheostatDarkMode(rheostat);
  }, []);

  useEffect(() => {
    if (sameSearchFilters) return false;
    // receiving new search filters from redux
    setSearchFilters({
      ...searchFilters,
      age_max: setAgeMax(searchFilters, defaultMaxAge),
    });
  }, [searchFilters, defaultMaxAge, sameSearchFilters]);

  const onResetFilterHandler = () => {
    setSearchFilters({
      ...DefaultSearch.filters,
      age_max: setAgeMax(searchFilters, defaultMaxAge),
    });
    resetFiltersState();
    resetFilters();
  };

  const onChangeHandler = e => {
    const newSearchFilterVal = {};
    newSearchFilterVal[e.target.id] = e.target.value;
    setSearchFilters({ ...mySearchFilters, ...newSearchFilterVal });
  };

  const onChangeBoolean = e => {
    const newSearchFilterVal = {};
    newSearchFilterVal[e.target.id] = !mySearchFilters[e.target.id];
    setSearchFilters({ ...mySearchFilters, ...newSearchFilterVal });
  };

  const onChangeAge = (age_min, age_max) => {
    const newSearchFilterVal = { age_min: age_min, age_max: age_max };
    setSearchFilters({ ...mySearchFilters, ...newSearchFilterVal });
  };

  const onChangeHeight = (height_min, height_max) => {
    const newSearchFilterVal = { height_min: height_min, height_max: height_max };
    setSearchFilters({ ...mySearchFilters, ...newSearchFilterVal });
  };

  const onCheckBoxChange = (attr, theVal) => {
    // assign create arrays for each checkbox input block
    if (isCheckBoxChecked(attr, theVal)) {
      setSearchFilters({
        ...mySearchFilters,
        [attr]: remove(mySearchFilters[attr], n => {
          return n !== theVal;
        }),
      });
    } else {
      // add item to array
      const newState = mySearchFilters && mySearchFilters[attr] ? mySearchFilters[attr].slice() : [];
      newState.push(theVal);
      setSearchFilters({ ...mySearchFilters, [attr]: newState });
    }
  };

  const isCheckBoxChecked = (attr, theVal) => {
    // returns value of Checkbox item
    const theArray = mySearchFilters[attr];
    return includes(theArray, theVal);
  };

  const saveLocation = place => {
    const loc = setLoc(place);
    setSearchFilters({ ...mySearchFilters, ...loc });
  };

  return (
    <div style={{ backgroundColor: backgroundTheme.block }}>
      <Container style={{ maxWidth: 1400 }}>
        <div>{screenSize}</div>
        <FiltersLivingIn
          theState={mySearchFilters}
          onChangeBoolean={onChangeBoolean}
          onChangeHandler={onChangeHandler}
          saveLocation={saveLocation}
          setHiddenMobileApplyFilterButton={setHiddenMobileApplyFilterButton}
          resetFiltersState={onResetFilterHandler}
        />

        <FilterSection
          header="Looking for"
          numActive={[
            searchFilters.dating_just_dating,
            searchFilters.dating_friendship,
            searchFilters.dating_marriage,
            searchFilters.dating_sugar,
            searchFilters.dating_married,
            searchFilters.dating_adult,
            searchFilters.dating_mentorship,
          ].reduce(function(a, b) {
            return b ? a + 1 : a;
          }, 0)}
        >
          <Checkbox
            size="MD"
            label="Dating"
            value={mySearchFilters.dating_just_dating}
            onChange={onChangeBoolean}
            id="dating_just_dating"
          />
          <Checkbox
            size="MD"
            label="Friendship"
            value={mySearchFilters.dating_friendship}
            onChange={onChangeBoolean}
            id="dating_friendship"
          />
          <Checkbox
            size="MD"
            label="Long-term"
            value={mySearchFilters.dating_marriage}
            onChange={onChangeBoolean}
            id="dating_marriage"
          />
          <Checkbox
            size="MD"
            label="Discreet affair"
            value={mySearchFilters.dating_married}
            onChange={onChangeBoolean}
            id="dating_married"
          />
          <Checkbox
            size="MD"
            label="Casual"
            value={mySearchFilters.dating_adult}
            onChange={onChangeBoolean}
            id="dating_adult"
          />
          <Checkbox
            size="MD"
            label="Mentorship"
            value={mySearchFilters.dating_mentorship}
            onChange={onChangeBoolean}
            id="dating_mentorship"
          />
        </FilterSection>

        <FilterSection
          fullWidth
          header="Age"
          hideSelectedText
          numActive={setAgeFilter(gender, accountType, lookingFemale, lookingMale, mySearchFilters)}
        >
          <div style={{ fontSize: 16, marginBottom: 22 }}>
            {setAgeFilter(gender, accountType, lookingFemale, lookingMale, mySearchFilters)}
          </div>
          <Rheostat
            min={UserConfig.minimumAge}
            max={
              generousProfile(gender, accountType, lookingFemale, lookingMale)
                ? UserConfig.maximumAgeMaleForFemale
                : UserConfig.maximumAgeFemaleForMale
            }
            values={[mySearchFilters.age_min, mySearchFilters.age_max]}
            snap={true}
            onChange={e => {
              onChangeAge(e.values[0], e.values[1]);
            }}
            onValuesUpdated={e => {
              onChangeAge(e.values[0], e.values[1]);
            }}
          />
        </FilterSection>
        <FilterSection
          fullWidth
          header="Height"
          numActive={
            UserConfig.minimumHeight !== mySearchFilters.height_min ||
            UserConfig.maximumHeight !== mySearchFilters.height_max
              ? `${metricConv(mySearchFilters.height_min)} – ${metricConv(mySearchFilters.height_max)}`
              : undefined
          }
          hideSelectedText
        >
          <div style={{ fontSize: 16, marginBottom: 22 }}>
            {metricConv(mySearchFilters.height_min)} – {metricConv(mySearchFilters.height_max)}
          </div>
          <Rheostat
            min={UserConfig.minimumHeight}
            max={UserConfig.maximumHeight}
            values={[mySearchFilters.height_min, mySearchFilters.height_max]}
            snap={true}
            onChange={e => {
              onChangeHeight(e.values[0], e.values[1]);
            }}
            onValuesUpdated={e => {
              onChangeHeight(e.values[0], e.values[1]);
            }}
          />
        </FilterSection>

        {auth10tixVerification && (
          <FilterSection header="ID Verified" numActive={mySearchFilters.smoking.length}>
            <Checkbox
              size="MD"
              value={isCheckBoxChecked('id_verified', 1)}
              onChange={() => {
                onCheckBoxChange('id_verified', 1);
              }}
              label="No"
              id="verified_no"
            />
            <Checkbox
              size="MD"
              value={isCheckBoxChecked('id_verified', 2)}
              onChange={() => {
                onCheckBoxChange('id_verified', 2);
              }}
              label="Yes"
              id="verified_yes"
            />
          </FilterSection>
        )}
        <FilterSection header="Smoking" numActive={mySearchFilters.smoking.length}>
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('smoking', 1)}
            onChange={() => {
              onCheckBoxChange('smoking', 1);
            }}
            label="No"
            id="smoking_no"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('smoking', 2)}
            onChange={() => {
              onCheckBoxChange('smoking', 2);
            }}
            label="Yes"
            id="smoking_yes"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('smoking', 3)}
            onChange={() => {
              onCheckBoxChange('smoking', 3);
            }}
            label="Occasionally"
            id="smoking_occasionally"
          />
        </FilterSection>
        <FilterSection header="Drinking" numActive={mySearchFilters.drinking.length}>
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('drinking', 1)}
            onChange={() => {
              onCheckBoxChange('drinking', 1);
            }}
            label="No"
            id="drinking_no"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('drinking', 2)}
            onChange={() => {
              onCheckBoxChange('drinking', 2);
            }}
            label="Yes"
            id="drinking_yes"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('drinking', 3)}
            onChange={() => {
              onCheckBoxChange('drinking', 3);
            }}
            label="Occasionally"
            id="drinking_occasionally"
          />
        </FilterSection>
        <FilterSection header="Children" numActive={mySearchFilters.children.length}>
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('children', 1)}
            onChange={() => {
              onCheckBoxChange('children', 1);
            }}
            label="No"
            id="children_no"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('children', 2)}
            onChange={() => {
              onCheckBoxChange('children', 2);
            }}
            label="Yes"
            id="children_yes"
          />
        </FilterSection>
        <FilterSection
          header="Body types"
          numActive={
            mySearchFilters.body_types !== undefined
              ? mySearchFilters.body_types.length
              : mySearchFilters.body_type.length
          }
        >
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 5)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 5);
            }}
            label="A few extra pounds"
            id="body_type_extra_pounds"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 2)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 2);
            }}
            label="Athletic"
            id="body_type_athletic"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 3)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 3);
            }}
            label="Average"
            id="body_type_average"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 4)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 4);
            }}
            label="Curvy"
            id="body_type_curvy"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 6)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 6);
            }}
            label="Full-figured"
            id="body_type_full_figured"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 7)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 7);
            }}
            label="Muscular"
            id="body_type_muscular"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked(bodyTypes, 1)}
            onChange={() => {
              onCheckBoxChange(bodyTypes, 1);
            }}
            label="Slender"
            id="body_type_slender"
          />
        </FilterSection>
        <FilterSection header="Education" numActive={mySearchFilters.education.length}>
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 1)}
            onChange={() => {
              onCheckBoxChange('education', 1);
            }}
            label="High school"
            id="education_high_school"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 2)}
            onChange={() => {
              onCheckBoxChange('education', 2);
            }}
            label="Some college"
            id="education_some_college"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 3)}
            onChange={() => {
              onCheckBoxChange('education', 3);
            }}
            label="Associates degree"
            id="education_associates_degree"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 4)}
            onChange={() => {
              onCheckBoxChange('education', 4);
            }}
            label="Bachelor's degree"
            id="education_bachelors_degree"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 5)}
            onChange={() => {
              onCheckBoxChange('education', 5);
            }}
            label="Graduate degree"
            id="education_graduate_degree"
          />
          <Checkbox
            size="MD"
            value={isCheckBoxChecked('education', 6)}
            onChange={() => {
              onCheckBoxChange('education', 6);
            }}
            label="Post-doctoral degree"
            id="education_post_doctoral"
          />
        </FilterSection>
        <FilterSection header="Profile Keywords" collapsable={false}>
          <TextInput
            className="textInput"
            type="text"
            id="keywords"
            name="keywords"
            onChange={onChangeHandler}
            value={mySearchFilters.keywords}
            customStyle={{ paddingBottom: '4px' }}
          />
        </FilterSection>
        <FilterSpacer>{/* spacer to push bottom filter above fixed filter bar */}</FilterSpacer>
      </Container>
      <div
        style={{
          position: 'fixed',
          padding: '30px 0',
          bottom: '0',
          zIndex: getBrowserDetails().isMobile ? 996 : 2,
          backgroundColor: backgroundTheme.block,
          width: '100%',
          borderTop: '1px solid rgba(0,0,0,0.14)',
        }}
      >
        <Container style={{ paddingTop: getBrowserDetails().isMobile ? 13 : 'unset', maxWidth: 1400 }}>
          <MediaQuery minWidth={640}>
            <ButtonSet align="right">
              <Button
                buttonRef={resetFilterBtn}
                customStyle={{ color: buttonTheme.darkReverse }}
                onClick={onResetFilterHandler}
              >
                <span>Reset Filter</span>
              </Button>
              <Button
                buttonType="primary"
                onClick={() => {
                  applyFiltersHandler(mySearchFilters);
                  searchApplyFilters(mySearchFilters);
                }}
              >
                Apply Filters
              </Button>
            </ButtonSet>
          </MediaQuery>
          <MediaQuery maxWidth={640}>
            <Button
              buttonRef={resetFilterBtn}
              customStyle={{ width: '46%', margin: '0 2%' }}
              onClick={onResetFilterHandler}
            >
              <span>Reset Filter</span>
            </Button>
            <MobileApplyFiltersButton
              customStyle={{ width: '46%', margin: '0 2%' }}
              buttonType="primary"
              disabled={hiddenMobileApplyFilterButton}
              onClick={() => {
                applyFiltersHandler(mySearchFilters);
                searchApplyFilters(mySearchFilters);
              }}
            >
              View Results
            </MobileApplyFiltersButton>
          </MediaQuery>
        </Container>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filterClickHandler: PropTypes.func.isRequired,
};
export default Filters;
