import React, { ReactElement } from 'react';
interface ICounterInfoIcon {
  onTouchStart?: () => any;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => any;
}

const CounterInfoIcon = ({ ...rest }: ICounterInfoIcon): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
      <path
        d="M9.99451 15C10.2586 15 10.4818 14.9102 10.6641 14.7305C10.8464 14.5508 10.9375 14.3281 10.9375 14.0625V9.6875C10.9375 9.42188 10.8482 9.19922 10.6696 9.01953C10.4909 8.83984 10.2696 8.75 10.0055 8.75C9.74142 8.75 9.51823 8.83984 9.33594 9.01953C9.15365 9.19922 9.0625 9.42188 9.0625 9.6875V14.0625C9.0625 14.3281 9.15182 14.5508 9.33044 14.7305C9.50909 14.9102 9.73044 15 9.99451 15ZM9.99451 6.875C10.2586 6.875 10.4818 6.78569 10.6641 6.60706C10.8464 6.42841 10.9375 6.20706 10.9375 5.943C10.9375 5.67892 10.8482 5.45573 10.6696 5.27344C10.4909 5.09115 10.2696 5 10.0055 5C9.74142 5 9.51823 5.08931 9.33594 5.26794C9.15365 5.44659 9.0625 5.66794 9.0625 5.93201C9.0625 6.19609 9.15182 6.41927 9.33044 6.60156C9.50909 6.78385 9.73044 6.875 9.99451 6.875ZM10.0072 20C8.63087 20 7.33507 19.7396 6.11979 19.2188C4.90451 18.6979 3.84115 17.9818 2.92969 17.0703C2.01823 16.1589 1.30208 15.0959 0.78125 13.8813C0.260417 12.6667 0 11.369 0 9.98807C0 8.60714 0.260417 7.31337 0.78125 6.10677C1.30208 4.90017 2.01823 3.84115 2.92969 2.92969C3.84115 2.01823 4.90415 1.30208 6.1187 0.78125C7.33326 0.260417 8.63101 0 10.0119 0C11.3929 0 12.6866 0.260417 13.8932 0.78125C15.0998 1.30208 16.1589 2.01823 17.0703 2.92969C17.9818 3.84115 18.6979 4.90212 19.2188 6.1126C19.7396 7.32311 20 8.61651 20 9.99281C20 11.3691 19.7396 12.6649 19.2188 13.8802C18.6979 15.0955 17.9818 16.1589 17.0703 17.0703C16.1589 17.9818 15.0979 18.6979 13.8874 19.2188C12.6769 19.7396 11.3835 20 10.0072 20Z"
        fill="#AFB0B1"
      />
    </svg>
  );
};

export default CounterInfoIcon;
