import { VYSION_ACTIONS } from '..';
import _camelCase from 'lodash/camelCase';
import _capitalize from 'lodash/capitalize';
import { IProfileCompletionVysionEvents } from './common';

const commonValues = {
  action: VYSION_ACTIONS.VIEW,
};

export const enum ProfileCompletionViewVysionEventsEnum {
  VIEW_PROFILE_COMPLETION = 'mandatoryProfileCompletion',
  VIEW_FINISH_MY_PROFILE = 'finishMyProfile',
}

export const handleProfileCompletionViewVysionEvents = (props: IProfileCompletionVysionEvents): any => {
  const pageName = _camelCase(props.page ?? 'Profile');
  const page = _capitalize(pageName);
  return {
    [ProfileCompletionViewVysionEventsEnum.VIEW_PROFILE_COMPLETION]: {
      ...commonValues,
      page,
      eventName: `${pageName}-mandatoryProfileCompletion`,
      component: 'Mandatory Profile Completion Popup',
    },
    [ProfileCompletionViewVysionEventsEnum.VIEW_FINISH_MY_PROFILE]: {
      ...commonValues,
      page,
      eventName: `${pageName}-finishMyProfile`,
      component: 'Finish my profile popup',
    },
  };
};
