import React from 'react';
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton';
import GalleryUploadMobileActionButtonContainer from './GalleryUploadMobileActionButtonContainer';
import GalleryUploadDesktopActionButtonContainer from './GalleryUploadDesktopActionButtonContainer';
import { MediaTypes } from '../../constants';
import { StyledMediaItemContainer, StyledMediasContainer, StyledMediasInnerContainer } from '../styles';
import styled from '@emotion/styled';
import colors, { white } from '../../../../theme/colors';
import Loader from '../../../../atoms/loader/Loader';

const StyledCardContainer = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  border-radius: 10px;
`;

interface ISelectedPhotosProps {
  counter: number;
  mediaType: MediaTypes;
  onCancel: () => void;
}

const GalleryLoadingMedia: React.FC<ISelectedPhotosProps> = ({ counter, mediaType, onCancel }) => {
  const buttonLabel = `Upload (${counter}) ${mediaType === 'photo' ? 'Photos' : 'Videos'}`;

  return (
    <>
      <div
        style={{
          color: colors.red[500],
          fontSize: 14,
          fontWeight: 600,
          margin: '20px 0',
        }}
      >
        Hang tight, we’re preparing your {mediaType === 'photo' ? 'Photos' : 'Videos'}...
      </div>
      <StyledMediasContainer>
        <StyledMediasInnerContainer>
          {Array.from({ length: counter }).map((it, index) => {
            return (
              <StyledMediaItemContainer key={index}>
                <StyledCardContainer>
                  <Loader active={true} imgStyle={{ width: 45 }} showLoadingBg={false} />
                </StyledCardContainer>
              </StyledMediaItemContainer>
            );
          })}
        </StyledMediasInnerContainer>
      </StyledMediasContainer>
      <GalleryUploadDesktopActionButtonContainer>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={() => null}
          disabled={true}
          customStyle={{
            width: 'inherit',
          }}
        />
      </GalleryUploadDesktopActionButtonContainer>
      <GalleryUploadMobileActionButtonContainer onCancel={onCancel}>
        <PrimaryButton
          size="small"
          color="blue"
          label={buttonLabel}
          onClick={() => null}
          disabled={true}
          customStyle={{
            width: 'inherit',
          }}
        />
      </GalleryUploadMobileActionButtonContainer>
    </>
  );
};

export default GalleryLoadingMedia;
