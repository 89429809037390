import styled from '@emotion/styled';
import { theme } from '../../../theme';
import colors, { white } from '../../../theme/colors';
import backgrounds from '../../../theme/backgrounds';
import { color, dimensions, textColor } from '../../../style';
import { IsMobileViewPort, tabletWidth } from '../../../utils/helpers';

export const PriceContainer = styled('div')(
  {
    background: theme === 'light' ? white : backgrounds.dark.blue,
    textAlign: 'center',
    width: '260px',
    margin: '1rem auto',
    borderRadius: 6,
    boxShadow: 'inset 2px 2px 4px rgba(0, 0, 0, 0.12)',
  },

  ({ hasErrors }) => ({
    border: hasErrors ? `2px solid ${colors.light.warning.primary}` : '1px solid #C4C4C4',
  })
);

export const InputProps = {
  style: {
    fontSize: IsMobileViewPort && !tabletWidth ? 42 : 56,
    lineHeight: IsMobileViewPort && !tabletWidth ? '42px' : '56px',
    fontWeight: 600,
    outline: 'none',
    border: 'none',
    color: theme === 'light' ? colors.light.blue.secondary : colors.blue[200],
    errorColor: theme === 'light' ? colors.light.blue.secondary : colors.blue[200],
  },
};

export const setColor = {
  default: {
    color: 'grey',
  },
  brand: {
    color: color.BRAND_1,
  },
  alert: { ...textColor.RED },
};

export const CurrencyTip = styled('div')(
  {
    display: 'inline-block',
    position: 'relative',
  },
  ({ offerPrice }) => ({
    ...setColor[offerPrice],
    ':before': {
      position: 'absolute',
      top: 14,
      right: '85%',
      fontSize: 22,
    },
  })
);

export const CurrencyLabelSpan = styled('span')({
  fontSize: 56,
  height: 72,
  fontWeight: 600,
  color: theme === 'light' ? colors.light.blue.secondary : colors.blue[200],
  marginRight: -5,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 42,
    lineHeight: '42px',
    height: 56,
  },
});

export const status = (currentUserIsGenerous: boolean): string => (currentUserIsGenerous ? 'Offer' : 'Offer Request');
