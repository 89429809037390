import React, { ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import GiftIcon from '../../../atoms/icons/GiftIcon';
import { isDarkMode } from '../../../utils/helpers';
import { dimensions } from '../../../style';

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
});

const GiftSentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',
  padding: 0,
});

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: 15,
  width: '100%',
  [dimensions.SCREEN_MAX_LG]: {
    marginLeft: isDarkMode() ? 0 : 15,
    marginRight: isDarkMode() ? 0 : 15,
  },
});

interface GiftSentProps {
  badgeCount: number;
  userTemplateSetId: string;
  useRGBadgeWishlistCount?: boolean;
  children: any;
  tutorialComponent: any;
  toggleComponent: any;
  isEditProfile: boolean;
}

const GiftSent = ({ badgeCount = 0, toggleComponent, isEditProfile }: GiftSentProps): ReactElement => {
  const EditProfileGenerous = () => (
    <MainContainer>
      <GiftSentContainer>
        <StyledContainer>Display total gifts sent on my profile &ensp;</StyledContainer>
        <StyledContainer>{toggleComponent}</StyledContainer>
      </GiftSentContainer>
      <GiftSentContainer style={{ paddingTop: 3 }}>
        <StyledContainer>
          <GiftIcon fillColor={isDarkMode() ? '#D06369' : '#BC2028'} /> &ensp;Gift Sent:
        </StyledContainer>
        <StyledContainer>{badgeCount}</StyledContainer>
      </GiftSentContainer>
    </MainContainer>
  );

  const ViewProfileGenerous = () => (
    <MainContainer>
      <GiftSentContainer>
        <StyledContainer>
          <GiftIcon fillColor={isDarkMode() ? '#D06369' : '#BC2028'} /> &ensp;Gifts Sent: {badgeCount}
        </StyledContainer>
      </GiftSentContainer>
    </MainContainer>
  );

  // prevent re-rendering component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MemoizedGiftSent = useMemo(() => (isEditProfile ? <EditProfileGenerous /> : <ViewProfileGenerous />), [
    isEditProfile,
    badgeCount,
  ]);

  return MemoizedGiftSent;
};

export default GiftSent;
