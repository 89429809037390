import React, { useState, useEffect, useRef, ReactElement } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';
import { connect } from 'react-redux';
import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import TextLink from '../../common/TextLink';
import PriceInput, { adjustStylePerDigit } from './PriceInput';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import {
  sendOffer,
  sendCancel,
  counterOffer,
  counterCancel,
  counterModalCustomAmount,
  acceptOffer,
  counterModalClose,
} from '../../../actions/profileTrackAction';
import TransactionAwareness from './TransactionAwareness';
import useModal from '../../../hooks/useModal';
import WYPButtonIcon from '../../../atoms/icons/WYPButtonIcon';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { PriceContainer, InputProps, CurrencyTip, CurrencyLabelSpan } from './common';
import { Account, AccountMaker } from '../../../models/Account';
import useMixPanel from '../../../hooks/useMixPanel';
import { EVENT_NAMES } from '../../../constants/mixpanel';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MIN_SM]: {
    marginTop: 12,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 27,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

const CounterRange = styled('div')({
  fontSize: 14,
  marginBottom: 12,
  textAlign: 'center',
});

const ErrorContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
});

const ErrorMessage = styled('div')({
  color: 'rgb(204, 84, 84)',
  margin: '16px auto 0',
  maxWidth: '280px',
});

const PurchaseCredits = styled('div')({
  textAlign: 'center',
  marginTop: '12px',
  textDecoration: 'underline',
});

const ButtonDiv = styled('div')({
  marginTop: 25,

  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 10,
    marginBottom: 12,
    paddingTop: 12,
    width: '100%',
  },
});

const getHeadline = (label, currInitial, offer, currentUserIsGenerous, isCounter, username) => {
  if (isCounter) {
    return (
      <span>
        Counter <strong>{username}&lsquo;s</strong> offer by submitting a new amount below.
      </span>
    );
  } else {
    return (
      <span>
        Accept <strong>{username}&lsquo;s</strong> offer or submit a{' '}
        <strong>
          <i> Counter Offer </i>
        </strong>
        and negotiate a better price.
      </span>
    );
  }
};

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.min_counter_price : offer && offer.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

const renderCounterRange = (isCounter, currencyInitial, currLabel, minValue, maxValue) => {
  if (isCounter) {
    return (
      <CounterRange>
        Enter a new amount between {currencyInitial}
        {currLabel}
        {minValue} and {currencyInitial}
        {currLabel}
        {maxValue}
      </CounterRange>
    );
  }
  return null;
};

const setCreateOfferAction = (e, offerAction) => {
  switch (offerAction) {
    case 'Send Counter':
      counterOffer(e);
      break;
    case 'Cancel Counter':
      counterCancel(e);
      break;
    case 'Send Offer':
      sendOffer(e);
      break;
    case 'Accept Offer':
      acceptOffer(e);
      break;
    case 'Cancel Offer':
      sendCancel(e);
      break;
    case 'Counter Modal Close':
      counterModalClose(e);
      break;
    default:
      return;
  }
};

const trackFocus = (ref, actionMethod) => {
  if (ref && ref.current) {
    ref.current.addEventListener('click', e => actionMethod(e), false);
  }

  return () => {
    ref.current.removeEventListener('click', () => null, false);
  };
};

interface OfferAcceptProps {
  avatarSrc: any;
  offer: any;
  errors: any;
  closeModal: any;
  currentUserIsGenerous: any;
  clearErrors: any;
  username: any;
  counterOffer: any;
  onHandleSubmit: any;
  onHandleAttractiveSubmit: any;
  hashId: any;
  buttonID: any;
  acceptUnlock: any;
  ignoreOffer: any;
  successOpen: any;
  forceCounter: boolean;
  pageTitle: string;
  accountModel: Account;
}

const OfferAccept = ({
  avatarSrc,
  offer,
  errors,
  closeModal,
  currentUserIsGenerous,
  clearErrors,
  username,
  counterOffer,
  onHandleSubmit,
  onHandleAttractiveSubmit,
  hashId,
  buttonID,
  acceptUnlock,
  ignoreOffer,
  successOpen,
  forceCounter = false,
  pageTitle,
  accountModel,
}: OfferAcceptProps): ReactElement => {
  const { mixpanelData } = useMixPanel();
  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState(offer.data.price_original);
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer.data, 'min', '5'));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer.data, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);
  const [isCounter, setIsCounter] = useState(forceCounter);
  const sendOfferBtn = useRef(null);
  const declineOfferBtn = useRef(null);
  const sendCancelBtn = useRef(null);
  const priceInput = useRef(null);
  const [currencyInitial, setCurrencyInitial] = useState('');
  const [currencyLabel, setCurrencyLabel] = useState('');
  const { showMandatoryCompleteProfile } = useCompleteProfileModal();

  const {
    resetModal,
    resetModalPosition,
    setModalStateHandler,
    confirmDeclineOffer,
    showTooManyPendingOffersErrorModal,
  } = useModal();

  useOnClickTriggerTracking('Cancel Offer', sendCancelBtn, setCreateOfferAction);

  useOnClickTriggerTracking(forceCounter ? 'Counter Modal Close' : '', sendCancelBtn, setCreateOfferAction);

  useEffect(() => {
    // vysion events trigger when closing the counter offer modal
    if (isCounter) {
      setModalStateHandler({
        onCloseClick: () => {
          counterModalClose();
          resetModal();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounter]);

  useEffect(() => {
    clearErrors();
    trackFocus(priceInput, counterModalCustomAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { data } = offer;
    setMaxValue(offerMinMaxVal(data, 'max', '500'));
    setMinValue(offerMinMaxVal(data, 'min', '5'));
    setCurrencyInitial(data.currency_original.data.initial);
    setCurrencyLabel(data.currency_original.data.label_unicode);
    setOfferPrice(data.price_original);
  }, [offer]);

  useEffect(() => {
    setIsCounter(forceCounter || offerPrice.toString() !== offer.data.price_original.toString());
  }, [offerPrice, forceCounter, offer.data.price_original]);

  useEffect(() => {
    if (forceCounter) {
      setOfferPrice('');
      if (priceInput.current) {
        priceInput.current.focus();
      }
    }
  }, [forceCounter, maxValue, priceInput]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  const inputOnChange = e => {
    if (!e.target || typeof e.target.value !== 'string') {
      return;
    }

    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValueNum.toString().length;

    if (e.target.value.length <= maxLength) {
      const isError = inputVal < minValueNum || inputVal > maxValueNum;
      updateOfferPrice(inputVal, isError);
    } else {
      e.preventDefault();
    }
  };

  const mixpanelCounterOfferMadeSource = (pageTitle: string) => {
    const mxOfferMadePayload = mixpanelData[EVENT_NAMES.OFFER_MADE];

    const source = {
      Profile: mxOfferMadePayload.source,
    };

    return pageTitle in source ? source[pageTitle] : pageTitle;
  };

  const handleSubmit = e => {
    e.preventDefault();

    let offerAction = 'accept' as OfferActions;

    if (isCounter) {
      offerAction = 'counter';
    } else if (offer && offer.suggest) {
      offerAction = 'suggest';
    }

    const counterOfferFormData = {
      price: offerPrice,
      account: hashId,
      source: mixpanelCounterOfferMadeSource(pageTitle),
    };
    const offerAmount = `${currencyInitial}${currLabel}${offerPrice}`;

    setOfferSending(true);

    showMandatoryCompleteProfile(
      () => {
        if (accountModel.shouldShowTooManyLockedOffersDeals(offerAction)) {
          showTooManyPendingOffersErrorModal();
          return;
        }

        if (isCounter && accountModel.shouldShowTooManySentOffers()) {
          showTooManyPendingOffersErrorModal({ type: 'sent' });
          return;
        }

        if (isCounter || (offer && offer.suggest)) {
          setCreateOfferAction(e, 'Send Counter');

          counterOffer(counterOfferFormData)
            .then(() => {
              closeModal();
              setOfferSending(false);
              successOpen(true, offerAmount, true);
            })
            .catch(() => {
              setOfferSending(false);
              setHasErrors(true);
            });
        } else {
          currentUserIsGenerous
            ? onHandleSubmit(offerAmount)
                .then(res => {
                  setCreateOfferAction(e, 'Accept Offer');
                  closeModal();
                  setOfferSending(false);
                  acceptUnlock(true, offerAmount, res);
                })
                .catch(() => {
                  setOfferSending(false);
                  setHasErrors(true);
                })
            : onHandleAttractiveSubmit(offerAmount);
        }
      },
      () => setOfferSending(false),
      isCounter || (offer && offer.suggest) ? 'Counter Offer' : 'Accept Offer'
    );
  };

  const currLabel = String.fromCharCode(currencyLabel);

  const submitBtnLabel = () => {
    if (isCounter) {
      return 'Submit Counter Offer';
    }
    const initialLabel = currentUserIsGenerous && !IsMobileViewPort ? 'Accept and Send' : 'Accept';
    return isOfferSending ? 'Accepting Offer' : initialLabel;
  };

  useEffect(() => {
    if (isCounter) {
      resetModalPosition();
    }
  }, [isCounter, resetModalPosition]);

  return (
    <>
      <Form>
        <HeaderDiv>
          <AvatarTitleDiv>
            <Avatar
              data-test-id="offer-modal-avatar"
              src={avatarSrc}
              width={getBrowserDetails().isMobile ? '75px' : '100px'}
              customStyle={{
                backgroundColor: '#ccc',
              }}
            />
            <TitleDiv>{isCounter ? 'Counter Offer!' : 'Accept Offer!'}</TitleDiv>
          </AvatarTitleDiv>
          <Headline
            style={{
              color: patternModal.headerColor,
              marginTop: 20,
              fontSize: getBrowserDetails().isMobile ? 14 : 16,
            }}
            data-test-id="profile-offer-headline"
            size="SM"
          >
            {getHeadline(currLabel, currencyInitial, offer, currentUserIsGenerous, isCounter, username)}
          </Headline>
        </HeaderDiv>
        <Content>
          <div>
            <PriceContainer hasErrors={hasErrors}>
              <CurrencyTip
                offerPrice={colorType(offerPrice, hasErrors)}
                style={{
                  paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
                }}
              >
                <CurrencyLabelSpan>
                  {currencyInitial}
                  {currLabel}
                </CurrencyLabelSpan>
                <PriceInput
                  inputRef={priceInput}
                  data-test-id="offer-price-input"
                  hasErrors={hasErrors}
                  value={offerPrice}
                  onChange={inputOnChange}
                  validateTextFn={validateTextInput}
                  placeholder={defaultValue}
                  inputProps={{
                    style: {
                      ...InputProps.style,
                      marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                      textAlign: 'left',
                    },
                  }}
                  minWidth={IsMobileViewPort ? 100 : 120}
                />
              </CurrencyTip>
            </PriceContainer>
            {isCounter ? (
              renderCounterRange(isCounter, currencyInitial, currLabel, minValue, maxValue)
            ) : (
              <div
                style={{ textAlign: 'center', fontSize: IsMobileViewPort ? 14 : 16 }}
                data-test-id="counter-offer-desc"
              >
                To submit a <strong style={{ textDecoration: 'underline' }}>Counter Offer</strong>, enter a new amount
                before submitting.
              </div>
            )}

            {errors ? (
              <ErrorContainer>
                <ErrorMessage>{errors.price}</ErrorMessage>
                {currentUserIsGenerous && Number(offerPrice) >= 200 && (
                  <PurchaseCredits>
                    <TextLink to="/packages" onClick={() => closeModal()}>
                      Purchase credits now.
                    </TextLink>
                  </PurchaseCredits>
                )}
              </ErrorContainer>
            ) : null}
          </div>
          <ButtonDiv marginTop={16}>
            <ButtonSet align="center">
              {!forceCounter && !isCounter && (
                <Button
                  id={buttonID}
                  buttonRef={declineOfferBtn}
                  disabled={hasErrors || isOfferSending}
                  buttonType="normal"
                  onClick={e => {
                    e.preventDefault();

                    if (accountModel.shouldShowTooManyLockedOffersDeals('decline')) {
                      showTooManyPendingOffersErrorModal();
                    } else {
                      confirmDeclineOffer({
                        isGenerous: currentUserIsGenerous,
                        username: username,
                        onConfirm: () => {
                          ignoreOffer();
                        },
                      });
                    }
                  }}
                  type="submit"
                  customStyle={{
                    background: patternModal.cancelBG,
                    border: patternModal.cancelBorder,
                    color: patternModal.cancelBlue,
                  }}
                  data-test-id="modal-decline-offer-btn"
                >
                  Decline Offer
                </Button>
              )}

              <Button
                id={buttonID}
                buttonRef={sendOfferBtn}
                disabled={hasErrors || isOfferSending}
                buttonType="error"
                onClick={handleSubmit}
                type="submit"
                data-test-id="modal-accept-offer-btn"
              >
                <WYPButtonIcon style={{ marginRight: 6 }} />
                {submitBtnLabel()}
              </Button>
            </ButtonSet>
            <ButtonSet align="center">
              <Button
                buttonType="chromeless"
                buttonRef={sendCancelBtn}
                data-test-id={`${buttonID}Cancel`}
                disabled={isOfferSending}
                onClick={e => {
                  e.preventDefault();
                  closeModal();
                }}
                customStyle={{ color: patternModal.linkBlue, fontWeight: 'normal', textDecoration: 'underline' }}
              >
                {' '}
                Cancel
              </Button>
            </ButtonSet>
          </ButtonDiv>
        </Content>
        {!isCounter && <TransactionAwareness />}
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  return {
    accountModel: AccountMaker.create(state.profile),
  };
};

export default connect(mapStateToProps, {})(OfferAccept);
