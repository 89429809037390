import {
  STORE_ERRORS,
  STORE_SPECIFIC_ERRORS,
  CLEAR_ERRORS,
  CLEAR_INDIVIDUAL_ERROR,
  REMOVE_ERROR,
  IS_FETCHING,
  SET_PORTRAIT,
  KILL_IS_FETCHING,
  SET_HEADER_HIDDEN,
  CLEAR_DIRTY,
  FORGOT_PASSWORD_SENDING,
  UPDATING_SETTINGS,
  REVERT_LAST_UPDATE,
  VISIT_MESSAGES,
  STORE_CURR_PAGE,
  UPDATING_VARIANTS,
  CommonState,
  CommonActionType,
} from './types/Common.d';

const initialState: CommonState = {
  errors: {},
  isFetching: false,
  modalOpen: false,
  headerHidden: false,
  isDirty: false,
  emailSent: false,
  emailStatus: 'default',
  updatingSettings: false,
  lastUpdate: null,
  portrait: window.orientation !== 90,
  visitMessages: false,
  currPage: [],
  prevPage: [],
  featureVariant: {},
};

const common = (state = initialState, action: CommonActionType): any => {
  let errors = {};
  switch (action.type) {
    case STORE_ERRORS:
      // store a general purpose error
      return {
        ...state,
        errors: action.payload,
      };
    case STORE_SPECIFIC_ERRORS:
      // store a unique error.
      errors[action.name] = action.payload;
      return {
        ...state,
        errors,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        emailSent: false,
        emailStatus: 'default',
      };
    case CLEAR_INDIVIDUAL_ERROR:
      errors = state.errors;
      if (errors[action.payload]) errors[action.payload] = '';
      return {
        ...state,
        isDirty: true,
        errors,
      };
    // @TODO: centralize this error handling
    // currently this handles removal of error per key
    case REMOVE_ERROR:
      errors = state.errors;
      if (errors[action.payload]) delete errors[action.payload];
      return {
        ...state,
        errors,
      };
    case IS_FETCHING:
      return {
        ...state,
        isFetching: !state.isFetching,
      };
    case SET_PORTRAIT:
      return {
        ...state,
        portrait: action.payload,
      };
    case KILL_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case SET_HEADER_HIDDEN:
      return {
        ...state,
        headerHidden: action.payload,
      };
    case CLEAR_DIRTY:
      return {
        ...state,
        isDirty: false,
      };
    case FORGOT_PASSWORD_SENDING:
      return {
        ...state,
        emailSent: action.isSent,
        emailStatus: action.status,
      };
    case UPDATING_SETTINGS: {
      const newState = Object.assign({}, state);
      newState.updatingSettings = !state.updatingSettings;
      newState.lastUpdate = action.payload;
      return newState;
    }
    case UPDATING_VARIANTS: {
      const variantName = action.name;
      return {
        ...state,
        featureVariant: {
          ...state.featureVariant,
          [variantName]: action.payload,
        },
      };
    }
    case REVERT_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: null,
      };
    case VISIT_MESSAGES:
      return {
        ...state,
        visitMessages: !state.visitMessages,
      };
    case STORE_CURR_PAGE:
      return {
        ...state,
        prevPage: state.currPage,
        currPage: window.location.href.split('/'),
      };
    default:
      return state;
  }
};

export default common;
