import React, { ReactElement } from 'react';

interface DotIconProps {
  fillColor?: string;
  style?: React.CSSProperties;
  fill?: string;
  isActivePrivate?: boolean;
}

const DiscoverCounterDotIcon = ({ fillColor = '#808080', isActivePrivate, ...rest }: DotIconProps): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="none" viewBox="0 0 6 6" {...rest}>
      {isActivePrivate ? (
        <circle cx="3" cy="3" r="2.5" fill="#BC2028" stroke="white" />
      ) : (
        <circle cx="3" cy="3" r="3" fill={fillColor} />
      )}
    </svg>
  );
};

export default DiscoverCounterDotIcon;
