/**
 * @Author: zachalam
 * @Date:   2017-02-17T16:24:19-08:00
 * @Email:  zach@reflexmedia.com
 * @Last modified by:   zachalam
 * @Last modified time: 2017-02-17T16:24:19-08:00
 */

import * as sdk from '../sdk';
import { storeMeta } from './metaActions';
import { removeLocalItem } from '../common';
import { SliderSourceLSKey } from '../components/common/GridViewSlider';
import { removeDiscoverModeAccountsByHashId } from './discoverModeActions';

export const storeFavs = (payload: any, section: any, firstCall: any): any => {
  return {
    type: 'STORE_FAVS',
    payload,
    section,
    firstCall,
  };
};

export const clearFavs = (): any => {
  return {
    type: 'CLEAR_FAVS',
  };
};

export const clearIsFetching = (): any => {
  return {
    type: 'CLEAR_IS_FETCHING',
  };
};

export const storeFavChange = (payload: any, section: any): any => {
  return {
    type: 'STORE_FAV_CHANGE',
    payload,
    section,
  };
};

export const storeProfileFavChange = (): any => {
  return {
    type: 'STORE_PROFILE_FAV_CHANGE',
  };
};

export const requestFavs = (): any => {
  return {
    type: 'REQUEST_FAVS',
  };
};

export const requestInterested = (): any => {
  return {
    type: 'REQUEST_INTERESTED',
  };
};

export const clearInterested = (): any => {
  return {
    type: 'CLEAR_INTERESTED',
  };
};

export const storeInterested = (payload: any, firstCall: any): any => {
  return {
    type: 'STORE_INTERESTED',
    payload,
    firstCall,
  };
};

export const ignoreInterestedFav = (payload: any): any => {
  return {
    type: 'IGNORE_INTERESTED_FAV',
    payload,
  };
};

export const storeFavOfferChange = (payload: any, section: any, key: any): any => {
  return {
    type: 'STORE_FAV_OFFER_CHANGE',
    payload,
    section,
    key,
  };
};

export const updateViewedMeCount = (): any => {
  return {
    type: 'DECREASE_NEW_PROFILE_VIEW_COUNT',
  };
};

export const refreshFavs = (type = 'to', firstCall = true, per_page = 0): any => {
  return (dispatch, getState) => {
    let pageToGet = 0;

    dispatch(requestFavs());

    if (!firstCall) {
      pageToGet = getState().meta[`fav_${type}_current_page`] + 1;
    }

    // get "favorites" - to/from
    // if type === 'from', default to recent online filter
    const favType = type === 'from' ? 'recent-online' : type;

    return sdk
      .getFavorites(getState().auth.jwToken, favType, per_page, pageToGet)
      .then(response => {
        if (firstCall) {
          dispatch(clearFavs());
        }
        dispatch(storeFavs(response.data.data, type, firstCall));
        // store pagination
        const page = response.data.meta.pagination;
        dispatch(
          storeMeta({ [`fav_${type}_current_page`]: page.current_page, [`fav_${type}_total_pages`]: page.total_pages })
        );
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const removeInterested = (): any => {
  return (dispatch: any): any => {
    dispatch(clearInterested());
  };
};

export const refreshInterested = (firstCall = true, per_page = 0): any => {
  return (dispatch, getState) => {
    let pageToGet = 0;
    let isFreshData = true;
    const type = 'viewed_me';

    dispatch(requestInterested());

    if (!firstCall) {
      pageToGet = getState().meta[`fav_${type}_current_page`] + 1;
      isFreshData = false;
    }

    return sdk
      .getInterested(getState().auth.jwToken, isFreshData, per_page, pageToGet)
      .then(response => {
        if (firstCall) {
          dispatch(clearInterested());
        }

        const page = response.data.meta.pagination;

        dispatch(storeInterested(response.data.data, firstCall));
        dispatch(
          storeMeta({ [`fav_${type}_current_page`]: page.current_page, [`fav_${type}_total_pages`]: page.total_pages })
        );
      })
      .catch(err => {
        console.error(err);
      });
  };
};
export const refreshInterestedV2 = (firstCall = true, per_page = 0): any => {
  return (dispatch, getState) => {
    let pageToGet = 0;
    let isFreshData = true;
    const type = 'viewed_me';

    dispatch(requestInterested());

    if (!firstCall) {
      pageToGet = getState().meta[`fav_${type}_current_page`] + 1;
      isFreshData = false;
    }

    return sdk
      .getInterestedV2(getState().auth.jwToken, isFreshData, per_page, pageToGet)
      .then(response => {
        if (firstCall) {
          dispatch(clearInterested());
        }

        const page = response.data.meta.pagination;

        dispatch(storeInterested(response.data.data, firstCall));
        dispatch(
          storeMeta({ [`fav_${type}_current_page`]: page.current_page, [`fav_${type}_total_pages`]: page.total_pages })
        );
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const ignoreInterested = (userHashId: any): any => {
  return (dispatch, getState) => {
    return sdk
      .ignoreInterested(getState().auth.jwToken, userHashId)
      .then(() => {
        dispatch(ignoreInterestedFav(userHashId));
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const markAsReadInterested = (hashIds: string[], firstCall = true): any => {
  return (dispatch, getState) => {
    return sdk
      .markAsReadInterested(getState().auth.jwToken, hashIds)
      .then(() => {
        dispatch(refreshInterestedV2(firstCall));
        removeLocalItem(SliderSourceLSKey.INTERESTS_LS);
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const favToggle = (hashId: any): any => {
  // flip favorite status
  return (dispatch, getState) => {
    removeDiscoverModeAccountsByHashId([hashId]);
    
    // notify api.
    return sdk.toggleFavorite(getState().auth.jwToken, hashId);
  };
};

export const initiateFavPending = (hashId: any): any => {
  return (dispatch, getState) => {
    removeDiscoverModeAccountsByHashId([hashId]);

    return sdk.toggleFavorite(getState().auth.jwToken, hashId).then(() => {
      removeLocalItem('pending_fav');
    });
  };
};
