import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

interface PublicEyeIconProps {
  color?: string;
  customStyle: any;
}

const Icon = styled('svg')(
  {
    fill: 'none',
    height: '14px !important',
    width: '14px !important',
  },
  ({ customStyle }) => ({
    ...customStyle,
  })
);

const PublicEyeIcon = ({ color = '#FFFFFF', customStyle = {} }: PublicEyeIconProps): ReactElement => {
  return (
    <Icon customStyle={customStyle} viewBox="0 0 14 10">
      <path
        d="M7 7.95546C7.79545 7.95546 8.4717 7.66822 9.02873 7.09375C9.58533 6.51884 9.86364 5.82089 9.86364 4.9999C9.86364 4.17891 9.58533 3.48096 9.02873 2.90605C8.4717 2.33158 7.79545 2.04435 7 2.04435C6.20455 2.04435 5.5283 2.33158 4.97127 2.90605C4.41467 3.48096 4.13636 4.17891 4.13636 4.9999C4.13636 5.82089 4.41467 6.51884 4.97127 7.09375C5.5283 7.66822 6.20455 7.95546 7 7.95546ZM7 6.77323C6.52273 6.77323 6.11715 6.60072 5.78327 6.25568C5.44897 5.91109 5.28182 5.49249 5.28182 4.9999C5.28182 4.50731 5.44897 4.08849 5.78327 3.74346C6.11715 3.39887 6.52273 3.22657 7 3.22657C7.47727 3.22657 7.88306 3.39887 8.21736 3.74346C8.55124 4.08849 8.71818 4.50731 8.71818 4.9999C8.71818 5.49249 8.55124 5.91109 8.21736 6.25568C7.88306 6.60072 7.47727 6.77323 7 6.77323ZM7 9.92583C5.45152 9.92583 4.04091 9.47965 2.76818 8.58729C1.49545 7.69537 0.572727 6.49957 0 4.9999C0.572727 3.50023 1.49545 2.30422 2.76818 1.41186C4.04091 0.519935 5.45152 0.0739746 7 0.0739746C8.54848 0.0739746 9.95909 0.519935 11.2318 1.41186C12.5045 2.30422 13.4273 3.50023 14 4.9999C13.4273 6.49957 12.5045 7.69537 11.2318 8.58729C9.95909 9.47965 8.54848 9.92583 7 9.92583Z"
        fill={color}
      />
    </Icon>
  );
};

export default PublicEyeIcon;
