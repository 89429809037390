import {
  STORE_FAVS,
  CLEAR_FAVS,
  CLEAR_IS_FETCHING,
  STORE_FAV_CHANGE,
  REQUEST_FAVS,
  REQUEST_INTERESTED,
  CLEAR_INTERESTED,
  STORE_INTERESTED,
  IGNORE_INTERESTED_FAV,
  STORE_FAV_OFFER_CHANGE,
  FavouriteState,
  FavouriteActionType,
} from './types/Favourite.d';
import _ from 'lodash';

const initialState: FavouriteState = {
  items: [],
  to: [], // favorited you
  from: [], // your favorites
  viewed: [], // viewed me
  featured: [], // dashboard featured favorites
  viewed_me: [], // dashboard featured favorites
  isFetching: false,
  isFetchingFav: false,
};

const fav = (state = initialState, action: FavouriteActionType): any => {
  switch (action.type) {
    case STORE_FAVS: {
      // use new list, or concat?
      const newList = action.firstCall ? action.payload : state[action.section].concat(action.payload);
      return {
        ...state,
        [action.section]: newList,
        isFetching: false,
      };
    }
    case CLEAR_FAVS:
      return {
        ...state,
        items: [],
      };
    case CLEAR_IS_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case STORE_FAV_CHANGE: {
      // add/remove fav status
      const newFavData = _.map(state[action.section], v => {
        return v;
      });

      // swap status
      const favStatus = newFavData[action.payload].other_account.data.is_favorite;

      if (!favStatus) {
        newFavData[action.payload].other_account.data.is_favorite = true;
        newFavData[action.payload].is_favorited = true;

        if (action.section == 'viewed_me' && newFavData[action.payload].favorited_me) {
          newFavData[action.payload].is_matched = true;
        }
      } else {
        newFavData[action.payload].other_account.data.is_favorite = null;
        newFavData[action.payload].is_favorited = false;

        if (action.section == 'viewed_me') {
          newFavData[action.payload].is_matched = false;
        }
      }

      if (action.section == 'from' && !newFavData[action.payload].other_account.data.is_favorite) {
        newFavData.splice(action.payload, 1);
      }

      return {
        ...state,
        [action.section]: newFavData,
        isFetchingFav: false,
      };
    }
    case REQUEST_FAVS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_INTERESTED:
      return {
        ...state,
        isFetchingFav: true,
        isFetching: true,
      };
    case CLEAR_INTERESTED:
      return {
        ...state,
        interested: [],
      };
    case STORE_INTERESTED: {
      const newList = action.firstCall ? action.payload : state.viewed_me.concat(action.payload);
      return {
        ...state,
        viewed_me: newList,
        isFetchingFav: false,
        isFetching: false,
      };
    }
    case IGNORE_INTERESTED_FAV: {
      const remainingProfile = state.viewed_me.filter(profile => profile.id !== action.payload);

      return {
        ...state,
        viewed_me: remainingProfile,
        isFetchingFav: false,
        isFetching: false,
      };
    }
    case STORE_FAV_OFFER_CHANGE: {
      // add/remove fav status
      const newFavData = _.map(state[action.section], v => {
        return v;
      });

      // update offer data
      if (action.key) {
        newFavData[action.key].offer = action.payload;

        return {
          ...state,
          [action.section]: newFavData,
          isFetchingFav: false,
        };
      }
    }
  }
  return state;
};

export default fav;
