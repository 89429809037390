import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import ThreeDSIFrame from '../../../modules/billing/ThreeDSiFrame';
import { IDDCData, UpdateFingerPrintStatus } from '../../../reducers/types/DDC.d';
import { handleDeviceFingerPrinting, logException } from '../../../utils/helpers';
import { GATEWAY } from '../../../utils/billingEnum';
export interface IDeviceDataCollection {
  ddcData: IDDCData;
  initialIDs: Record<string, string>;
  updateFingerPrint: UpdateFingerPrintStatus;
  profile: any;
  updateDCCIFrameLoaded(gateway: GATEWAY): void;
  updateFingerPrintStartTime: (gateway: string) => void;
}

const DeviceDataCollection: FC<IDeviceDataCollection> = ({
  ddcData,
  updateFingerPrint,
  initialIDs,
  profile,
  updateDCCIFrameLoaded,
  updateFingerPrintStartTime,
}) => {
  const { jwt, bin, ddcUrl, iFrameKey, gateway, isSaved, initialDdcReferenceId, startedTime } = ddcData;

  const ddcIframeRef = useRef<HTMLIFrameElement>();

  const [isFrameLoaded, setIsIframeLoaded] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleIFrameLoad = () => {
    updateDCCIFrameLoaded(gateway);
    setIsIframeLoaded(true);
  };

  const handleUpdateFingerPrint = useCallback(
    (sessionId: string, data: unknown) => {
      if (!sessionId) {
        logException(
          'DDC: Session ID is not available',
          {
            step: 'Update Finger Print Status Event Listener',
            gateway,
            initialDdcReferenceId,
            sessionId,
            initialIDs,
            startedTime,
            data,
            stringifiedData: JSON.stringify(data || {}),
          },
          'info',
          profile
        );
      }

      if (!isSaved) {
        /**
         * We are doing since event.origin for both "Rocket Gate" & "Inovio pay" is same
         * added this workaround to identify the gateway ddcReferenceId.
         */
        if (
          (sessionId && !initialDdcReferenceId && initialIDs[GATEWAY.INOVIO_PAY] !== sessionId) ||
          initialDdcReferenceId === sessionId
        ) {
          updateFingerPrint(gateway, sessionId || '');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSaved, initialDdcReferenceId, initialIDs, gateway, startedTime, updateFingerPrint]
  );

  useEffect(() => {
    const handleFingerPrinting = (event: MessageEvent) =>
      handleDeviceFingerPrinting({
        event,
        handleUpdateFingerPrint,
        profile,
      });

    if (!isSaved) {
      window.addEventListener('message', handleFingerPrinting, false);
    }

    return () => {
      window.removeEventListener('message', handleFingerPrinting);
    };
  }, [ddcData, handleUpdateFingerPrint, isSaved, profile]);

  useEffect(() => {
    if (isFrameLoaded && !isFormSubmitted && ddcUrl && jwt) {
      const ddcIframe = ddcIframeRef.current.contentWindow.document;

      const jwtInput = ddcIframe.getElementById('JWT') as HTMLInputElement;
      const binInput = ddcIframe.getElementById('Bin') as HTMLInputElement;
      const formElement = ddcIframe.getElementById('ddcForm') as HTMLFormElement;

      if (jwtInput) {
        jwtInput.value = jwt;
      }

      if (binInput && ddcUrl.length > 0 && !bin) {
        binInput.remove();
      } else if (binInput) {
        binInput.value = bin || '';
      }

      if (formElement) {
        formElement.action = ddcUrl;
        formElement.submit();
        console.log('Submitting Inside Iframe....');
        setIsFormSubmitted(true);
        updateFingerPrintStartTime(gateway);
      }
    }
  }, [bin, ddcUrl, isFormSubmitted, isFrameLoaded, jwt, gateway, updateFingerPrintStartTime]);

  return <ThreeDSIFrame key={iFrameKey} ref={ddcIframeRef} onLoad={handleIFrameLoad} />;
};

export default DeviceDataCollection;
