import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { backgroundTheme, buttonTheme, type, dimensions } from '../style';
import { commonIcons, generalIcons } from './icons/materialIcons';
import { auth10tixVerification } from '../config/Master';
import { StarIcon } from './icons/favoriteIcons';
import { getBrowserDetails, ternaryFunc } from '../utils/helpers';
import Camera from './icons/Camera';

const CardContainer = styled('div')(
  {
    position: 'relative',
    borderRadius: '6px',
    overflow: 'hidden',
    border: '1px solid rgba(218, 225, 233, 1)',
  },
  ({ cardWidth, cardMargin }) => ({
    width: cardWidth,
    margin: cardMargin,
  }),
  ({ isMatched }) =>
    isMatched && {
      border: '1px solid rgba(188, 32, 40, 1)',
      boxShadow: 'inset 0px 0px 0px 1px rgb(188 32 40)',
    }
);

const PhotoAspectRatio = styled('div')({
  paddingTop: '133%',
  position: 'relative',
});

const AccountDetailsContainer = styled('div')({
  color: 'white',
  position: 'absolute',
  left: '0px',
  bottom: '0px',
  width: '100%',
  zIndex: 3,
});

const AccountDetails = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'bottom',
    padding: '10px',
    flexWrap: 'wrap',
    width: '75%',
    textOverflow: 'ellipsis',
  },
  ({ detailsPadding }) => ({
    padding: detailsPadding,
  })
);

const UsernameContainer = styled('div')({
  ...type.MD,
  width: '100%',
  color: buttonTheme.GreyAndWhite,
});

const Username = styled('div')(
  {
    width: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  ({ overrideWidth }) => ({
    width: overrideWidth,
  })
);

const UserDetails = styled('div')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
  width: '85%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const PhotoCountDetails = styled('div')(
  {
    ...type.SM,
    position: 'absolute',
    padding: '10px',
    bottom: '0px',
    right: '0px',
  },
  ({ detailsPadding }) => ({
    padding: detailsPadding,
  })
);

const PhotoCount = styled('span')({
  ...type.SM,
  color: backgroundTheme.cardDetails,
});

const PublicPhotoCount = styled('div')({
  borderBottom: '1px solid red',
});

const Gradient = styled('div')({
  background:
    'rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 70%, rgb(0, 0, 0) 100%) repeat scroll 0% 0%',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 2,
});

const Img = styled('div')(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 1,
  },
  ({ imgUrl }) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })
);

const OnlineDot = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: 'lightgreen',
  borderRadius: '50%',
  display: 'inline-block',
});

const StyledPhotoCount = styled('span')({
  alignSelf: 'flex-end',
});

const Icon = styled('span')({
  paddingRight: '6px',
});

const VerifiedBadge = styled('span')({
  marginLeft: 4,
  zIndex: 2,
});

const ProfileCardBanner = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '6px 0',
    width: '100%',

    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    color: '#fff',
    svg: {
      width: 14,
      height: 14,
      marginTop: 1,
      marginRight: 4,
    },
    [dimensions.SCREEN_MAX_SM]: {
      fontSize: 12,
      svg: {
        height: 12,
      },
    },
  },
  ({ customBackground }) => ({
    background: `${customBackground ? customBackground : 'rgba(188,32,40,0.9)'}`,
  })
);
interface CardProps {
  imgUrl: string;
  cardWidth: string;
  cardMargin: string;
  online: boolean;
  username: string;
  age: string;
  city: string;
  photoCount: number;
  privatePhotoCount: number;
  hashId: string;
  onClick();
  id: string;
  detailsPadding: string;
  isVerified: boolean;
  favorited?: boolean;
  favoritedMe: boolean;
  page: string;
  offer: unknown;
  user: unknown;
  screenIsMobile?: boolean;
  isMatched: boolean;
}

const OfferCard = ({
  imgUrl,
  cardWidth,
  cardMargin,
  online,
  username,
  age,
  city,
  photoCount,
  privatePhotoCount,
  hashId,
  onClick,
  id,
  detailsPadding,
  isVerified = false,
  favoritedMe,
  isMatched,
}: CardProps): JSX.Element => {
  const [showToolTip, setShowToolTip] = useState(false);

  const renderProfileCardBanner = () => {
    if (isMatched) {
      return (
        <ProfileCardBanner data-test-id="fav-banner-mutual-favorite">
          <StarIcon isActive={false} fill="#FFF" /> Mutual Favorite!
        </ProfileCardBanner>
      );
    }

    if (favoritedMe) {
      return (
        <ProfileCardBanner data-test-id="fav-banner-favorited-you">
          <StarIcon isActive={false} fill="#FFF" /> Favorited You!
        </ProfileCardBanner>
      );
    }

    return '';
  };

  return (
    <CardContainer
      id={id}
      className="card-items"
      cardWidth={cardWidth}
      cardMargin={cardMargin}
      isMatched={isMatched}
      onClick={onClick}
    >
      {renderProfileCardBanner()}
      <Link to={`/profile/${hashId}`}>
        <PhotoAspectRatio>
          <AccountDetailsContainer>
            <AccountDetails
              style={{ width: '75%' }}
              className="account-details-container"
              detailsPadding={detailsPadding}
            >
              <UsernameContainer>
                <Username
                  data-test-id="data-member-username"
                  overrideWidth={ternaryFunc(isVerified, 'auto', '100%')}
                  style={{ maxWidth: getBrowserDetails().isMobile ? '80%' : '85%' }}
                >
                  {username}
                </Username>
                {auth10tixVerification && isVerified && (
                  <VerifiedBadge
                    data-test-id="data-verified-badge"
                    onMouseEnter={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                  >
                    <commonIcons.check.badge showToolTip={showToolTip} />
                  </VerifiedBadge>
                )}
              </UsernameContainer>
              <UserDetails data-test-id="data-member-age-city">
                {online ? <OnlineDot /> : ''} {age} &middot; {city}
              </UserDetails>
            </AccountDetails>
            <PhotoCountDetails detailsPadding={detailsPadding} data-test-id="data-member-photo-count">
              <PhotoCount>
                <PublicPhotoCount>
                  <Icon data-test-id="data-camera-icon">
                    <Camera />
                  </Icon>
                  <StyledPhotoCount>{photoCount}</StyledPhotoCount>
                </PublicPhotoCount>
                <Icon data-test-id="data-eye-hidden-icon">
                  <generalIcons.eye.hidden />
                </Icon>
                <StyledPhotoCount>{privatePhotoCount}</StyledPhotoCount>
              </PhotoCount>
            </PhotoCountDetails>
          </AccountDetailsContainer>
          <Gradient />
          <Img className="data-avatar-image-url" imgUrl={imgUrl} />
        </PhotoAspectRatio>
      </Link>
    </CardContainer>
  );
};

OfferCard.defaultProps = {
  cardWidth: '300px',
  id: '',
};

export default OfferCard;
