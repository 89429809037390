import { format } from 'date-fns';

export const INITIAL_PROFILE_PAGE_STATE = {
  username: '',
  hash_id: '',
  profile: {
    data: {},
  },
  photo: {
    data: [
      {
        type: 'photo',
        hash_id: '',
        created_dt: {
          date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          timezone: 'America/Los_Angeles',
          timezone_type: 3,
        },
      },
    ],
  },
  video: [],
};
