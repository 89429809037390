export const NOTIFICATION_PUSH = 'NOTIFICATION_PUSH';
export const NOTIFICATION_POP = 'NOTIFICATION_POP';
export const NOTIFICATION_SHIFT = 'NOTIFICATION_SHIFT';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_TOGGLE = 'NOTIFICATION_TOGGLE';

export interface Data {
    data: Record<string, unknown>;
}

export interface ID {
    id: string;
}

export interface Notifications {
    notifications: unknown[];
}

export interface Enabled {
    enabled: {
        count: boolean;
        popup: boolean;
        popup_favorite: boolean;
        popup_offer: boolean;
        popup_mail: boolean;
        popup_favorite_settings: boolean;
        popup_offer_settings: boolean;
        popup_mail_settings: boolean;
    }
}

export interface AlertsState extends Notifications, Enabled {}

interface NOTIFICATION_PUSH_ACTION {
    type: typeof NOTIFICATION_PUSH;
    payload: Data;
}

interface NOTIFICATION_POP_ACTION {
    type: typeof NOTIFICATION_POP;
    payload: Notifications;
}

interface NOTIFICATION_SHIFT_ACTION {
    type: typeof NOTIFICATION_SHIFT;
    payload: Notifications;
}

interface NOTIFICATION_DELETE_ACTION {
    type: typeof NOTIFICATION_DELETE;
    payload: ID;
}

interface NOTIFICATION_TOGGLE_ACTION {
    type: typeof NOTIFICATION_TOGGLE;
    payload: Enabled;
}

export type AlertActionTypes = 
    | NOTIFICATION_PUSH_ACTION
    | NOTIFICATION_POP_ACTION
    | NOTIFICATION_SHIFT_ACTION
    | NOTIFICATION_DELETE_ACTION
    | NOTIFICATION_TOGGLE_ACTION;
