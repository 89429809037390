import React from 'react';

interface IDoNotDisturbOnOutlinedIcon {
  width?: number;
  height?: number;
  fill?: string;
  maskFile?: string;
}

const DoNotDisturbOnOutlinedIcon = ({
  width = 40,
  height = 40,
  fill = '#FFAF00',
  maskFile = '#D9D9D9',
}: IDoNotDisturbOnOutlinedIcon): React.ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3036_986"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width={width} height={height} fill={maskFile} />
      </mask>
      <g mask="url(#mask0_3036_986)">
        <path
          d="M11.6667 21.3052H28.3334V18.5275H11.6667V21.3052ZM20 36.6663C17.713 36.6663 15.5556 36.2288 13.5278 35.3538C11.5001 34.4788 9.73153 33.2867 8.22225 31.7775C6.713 30.2682 5.52087 28.4997 4.64587 26.4719C3.77087 24.4441 3.33337 22.2867 3.33337 19.9997C3.33337 17.6941 3.77087 15.5275 4.64587 13.4997C5.52087 11.4719 6.713 9.70801 8.22225 8.20801C9.73153 6.70801 11.5001 5.52051 13.5278 4.64551C15.5556 3.77051 17.713 3.33301 20 3.33301C22.3056 3.33301 24.4723 3.77051 26.5 4.64551C28.5278 5.52051 30.2917 6.70801 31.7917 8.20801C33.2917 9.70801 34.4792 11.4719 35.3542 13.4997C36.2292 15.5275 36.6667 17.6941 36.6667 19.9997C36.6667 22.2867 36.2292 24.4441 35.3542 26.4719C34.4792 28.4997 33.2917 30.2682 31.7917 31.7775C30.2917 33.2867 28.5278 34.4788 26.5 35.3538C24.4723 36.2288 22.3056 36.6663 20 36.6663ZM20 33.8886C23.8704 33.8886 27.1528 32.5367 29.8473 29.833C32.5417 27.1293 33.889 23.8515 33.889 19.9997C33.889 16.1293 32.5417 12.8469 29.8473 10.1524C27.1528 7.45798 23.8704 6.11076 20 6.11076C16.1482 6.11076 12.8704 7.45798 10.1667 10.1524C7.46299 12.8469 6.11112 16.1293 6.11112 19.9997C6.11112 23.8515 7.46299 27.1293 10.1667 29.833C12.8704 32.5367 16.1482 33.8886 20 33.8886Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default DoNotDisturbOnOutlinedIcon;
