import React, { ReactElement, useRef, useMemo } from 'react';
import styled from '@emotion/styled';
import useRealGifts, { ReceiverDataProps } from '../../../hooks/useRealGifts';
import GiftIcon from '../../../atoms/icons/GiftIcon';
import { isDarkMode } from '../../../utils/helpers';
import Tooltip from '../Tooltip';

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
});

const GiftWishlistContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',
  padding: 16,
});

const GiftLabel = styled('label')({
  color: isDarkMode() ? '#D06369' : '#BC2028',
  fontSize: 16,
  paddingLeft: 10,
  fontWeight: 700,
  cursor: 'pointer',
  marginRight: 10,
});

const GiftBadge = styled('span')({
  borderRadius: 17,
  background: '#BC2028',
  color: '#FFFFFF',
  fontWeight: 500,
  fontSize: 14,
  padding: '2px 6px 2px 6px',
  marginRight: 10,
});

interface GiftWishlistProps {
  badgeCount: number;
  receiverData: ReceiverDataProps;
  userTemplateSetId: string;
  useRGBadgeWishlistCount?: boolean;
  children: any;
  tutorialComponent: any;
  toggleComponent: any;
  isEditProfile: boolean;
}

const GiftWishlist = ({
  badgeCount = 0,
  receiverData,
  userTemplateSetId,
  useRGBadgeWishlistCount = false,
  tutorialComponent,
  toggleComponent,
  isEditProfile,
}: GiftWishlistProps): ReactElement => {
  const { handleRGWishlist, formatRGReceiverData } = useRealGifts();
  const buttonRef = useRef(null);

  const rgStoreJson = JSON.stringify({
    ...formatRGReceiverData(receiverData),
    user_template_set_id: userTemplateSetId,
  });

  const handleGiftWishlist = () => {
    handleRGWishlist({
      buttonRef,
      receiverData,
      userTemplateSetId,
    });
  };

  const EditProfileAttractive = () => (
    <GiftWishlistContainer data-test-id="wishlist-own-view">
      <StyledContainer>
        {!useRGBadgeWishlistCount ? (
          <GiftBadge>{badgeCount}</GiftBadge>
        ) : (
          <span className="rgstore-wishlist-badge" data-rgstore={rgStoreJson} />
        )}
        <GiftIcon fillColor={isDarkMode() ? '#D06369' : '#BC2028'} />
        <Tooltip
          variant="primary"
          htmlElementLabel={`<strong>Add/Remove</strong> items on your Wishlist`}
          trigger="hover"
          placement="top"
        >
          <GiftLabel style={{ textDecoration: 'underline' }} ref={buttonRef} onClick={handleGiftWishlist}>
            Edit My Wishlist
          </GiftLabel>
        </Tooltip>
        {tutorialComponent}
      </StyledContainer>
      <StyledContainer>{toggleComponent}</StyledContainer>
    </GiftWishlistContainer>
  );

  const ViewProfileAttractive = () => (
    <GiftWishlistContainer
      ref={buttonRef}
      onClick={handleGiftWishlist}
      style={{ cursor: 'pointer', justifyContent: badgeCount > 0 ? 'space-between' : 'center' }}
      data-test-id="wishlist-generous-view"
    >
      <StyledContainer>
        <GiftIcon fillColor={isDarkMode() ? '#D06369' : '#BC2028'} />
        <GiftLabel style={{ fontWeight: 500 }}>
          Check out my <strong>Wishlist</strong>
        </GiftLabel>
        {tutorialComponent}
      </StyledContainer>
      <StyledContainer>
        {!useRGBadgeWishlistCount ? (
          badgeCount > 0 && <GiftBadge>{badgeCount}</GiftBadge>
        ) : (
          <span className="rgstore-wishlist-badge" data-rgstore={rgStoreJson} />
        )}
      </StyledContainer>
    </GiftWishlistContainer>
  );

  // prevent re-rendering component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const MemoizedGiftWishlist = useMemo(() => (isEditProfile ? <EditProfileAttractive /> : <ViewProfileAttractive />), [
    isEditProfile,
    badgeCount,
  ]);

  return MemoizedGiftWishlist;
};

export default GiftWishlist;
