import React, { useEffect } from 'react';
import BiometricsList from '../components/pages/Settings/BiometricsList';
import { connect } from 'react-redux';
import { getBiometricsList, updateBiometricName } from '../actions/profileActions';

const BiometricsContainer = ({ refreshBiometricList, biometrics, handleChangeName, rest }) => {
  useEffect(() => {
    refreshBiometricList();
  }, [refreshBiometricList]);

  return <BiometricsList biometrics={biometrics} handleChangeName={handleChangeName} {...rest} />;
};

const mapStateToProps = state => {
  return {
    biometrics: state.biometrics,
    myProfile: state.profile.profile.data,
    isFetching: state.fav.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshBiometricList: () => {
      dispatch(getBiometricsList());
    },
    handleChangeName: async (bioName, deviceId) => {
      const res = await dispatch(updateBiometricName(bioName, deviceId));
      setTimeout(() => dispatch(getBiometricsList()), 300);
      return res.status;
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BiometricsContainer);
