import React from 'react';

const DiscoverIcon = ({ customStyle }: { customStyle?: React.CSSProperties }): React.ReactElement => {
  return (
    <svg
      width="40"
      height="26"
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...customStyle }}
    >
      <path
        d="M39.2925 3.25V22.75C39.2925 24.5449 37.8374 26 36.0425 26H3.54248C3.16534 26.0029 2.79086 25.9368 2.43748 25.805C1.15065 25.3398 0.293063 24.1183 0.29248 22.75V3.25C0.29248 1.45507 1.74755 0 3.54248 0H36.0425C37.8374 0 39.2925 1.45507 39.2925 3.25Z"
        fill="#212B36"
      />
      <path
        d="M39.2925 13V22.7212C39.2925 24.532 37.8169 25.9999 35.9966 25.9999H3.03807C2.65561 26.0028 2.27585 25.9361 1.91748 25.8032C4.686 25.4097 7.35564 24.9179 9.97585 24.3606C10.668 24.2294 11.3436 24.0655 12.0193 23.918C22.9121 21.4426 32.3382 17.8032 37.6446 14.2295C38.2378 13.8197 38.7981 13.4098 39.2925 13Z"
        fill="#EE7623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54248 8.64483H5.02123V8.61233C6.15325 8.53045 7.20895 9.18781 7.63494 10.2398C8.06093 11.2918 7.76004 12.4985 6.88998 13.2273C6.35904 13.6433 5.69459 13.8513 5.02123 13.8123H3.54248V8.64483ZM4.82623 12.9998C5.33455 13.0444 5.83912 12.8801 6.22373 12.5448C6.58956 12.2121 6.79639 11.7393 6.79248 11.2448C6.79955 10.7497 6.59217 10.2757 6.22373 9.94481C5.83269 9.6236 5.32973 9.47154 4.82623 9.52231H4.56623V12.9998H4.82623Z"
        fill="white"
      />
      <rect x="8.29553" y="8.63672" width="1.01562" height="5.21625" fill="white" />
      <path
        d="M11.8056 10.6352C11.1962 10.4158 10.9931 10.2615 10.9931 9.97708C10.9931 9.69271 11.31 9.40021 11.7487 9.40021C12.0688 9.41364 12.3664 9.5684 12.5612 9.82271L13.0893 9.13208C12.6724 8.75102 12.1266 8.542 11.5618 8.54708C11.1457 8.52039 10.7367 8.66498 10.4299 8.94731C10.123 9.22964 9.94486 9.62516 9.93684 10.0421C9.93684 10.7652 10.2618 11.1308 11.2125 11.4721C11.4568 11.5504 11.6935 11.651 11.9193 11.7727C12.1109 11.8833 12.2286 12.0878 12.2281 12.309C12.2266 12.5176 12.138 12.7161 11.9837 12.8565C11.8294 12.9969 11.6234 13.0665 11.4156 13.0483C10.9418 13.0535 10.5091 12.7799 10.3106 12.3496L9.65247 12.9833C10.0365 13.6336 10.7509 14.0158 11.505 13.9746C11.9739 14.0066 12.4348 13.84 12.7748 13.5154C13.1148 13.1909 13.3027 12.7383 13.2925 12.2683C13.2925 11.4396 12.9593 11.0577 11.8056 10.6352Z"
        fill="white"
      />
      <path
        d="M13.6256 11.2448C13.6188 11.9744 13.9079 12.6757 14.4269 13.1885C14.9459 13.7014 15.6505 13.9821 16.38 13.9667C16.823 13.9693 17.2603 13.8663 17.6556 13.6661V12.4717C17.3487 12.8305 16.9009 13.0381 16.4287 13.0405C15.9511 13.0524 15.4902 12.8646 15.157 12.5222C14.8238 12.1797 14.6487 11.7138 14.6737 11.2367C14.6584 10.7722 14.8306 10.321 15.1516 9.98473C15.4725 9.6485 15.9152 9.45551 16.38 9.44922C16.8696 9.45118 17.3346 9.66442 17.6556 10.0342V8.83984C17.2727 8.62756 16.8422 8.51575 16.4044 8.51484C15.6732 8.50618 14.9688 8.78945 14.4472 9.3019C13.9256 9.81435 13.6299 10.5136 13.6256 11.2448Z"
        fill="white"
      />
      <path
        d="M25.7237 12.1386L24.3262 8.63672H23.2212L25.4312 13.983H25.9756L28.2343 8.63672H27.1293L25.7237 12.1386Z"
        fill="white"
      />
      <path
        d="M28.6895 13.853H31.582V12.9673H29.7051V11.5617H31.5088V10.6761H29.7051V9.52234H31.582V8.63672H28.6895V13.853Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6038 10.172C35.6592 10.9036 35.1426 11.5546 34.4176 11.667L36.0426 13.812H34.8076L33.3938 11.7483H33.2801V13.812H32.2563V8.64453H33.7513C34.9376 8.64453 35.6038 9.19703 35.6038 10.172ZM33.2801 11.0334H33.6051C34.2063 11.0334 34.5638 10.8221 34.5638 10.2696C34.5638 9.7171 34.2226 9.4571 33.6051 9.4571H33.2801V11.0334Z"
        fill="white"
      />
      <path
        d="M23.5218 11.2443C23.5218 12.7744 22.2814 14.0149 20.7512 14.0149C19.221 14.0149 17.9806 12.7744 17.9806 11.2443C17.9806 9.71408 19.221 8.47363 20.7512 8.47363C22.2795 8.4781 23.5174 9.71594 23.5218 11.2443Z"
        fill="#EE7623"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1401 8.74195C36.1375 8.79773 36.0973 8.8446 36.0426 8.8557L36.1888 9.06695H36.0588L35.9126 8.87195V9.06695H35.7988V8.5957H35.9613C36.0751 8.5957 36.1401 8.64445 36.1401 8.74195ZM36.0264 8.74192C36.0264 8.69317 35.9939 8.69317 35.9451 8.69317V8.79067H35.9614C36.0046 8.79067 36.0202 8.79067 36.0254 8.78332C36.0299 8.77686 36.0264 8.76472 36.0264 8.74192Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6038 8.82357C35.6036 8.6054 35.7758 8.42604 35.9938 8.41732C36.1043 8.41289 36.2118 8.45373 36.2916 8.5304C36.3713 8.60708 36.4163 8.71295 36.4163 8.82357C36.4163 9.04793 36.2344 9.22982 36.01 9.22982C35.7856 9.22982 35.6038 9.04793 35.6038 8.82357ZM35.9938 9.16476C36.0756 9.16734 36.1539 9.13121 36.205 9.06726C36.2659 9.00047 36.3006 8.91388 36.3025 8.82351C36.3025 8.65299 36.1643 8.51476 35.9938 8.51476C35.8233 8.51476 35.685 8.65299 35.685 8.82351C35.6783 8.91029 35.7076 8.99602 35.766 9.06056C35.8244 9.12511 35.9068 9.16279 35.9938 9.16476Z"
        fill="white"
      />
    </svg>
  );
};

export default DiscoverIcon;
