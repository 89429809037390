import React, { useEffect, useCallback, useState, FC } from 'react';
import HeaderNav from '../components/blocks/headerNav/Header';
import FooterNav from '../components/blocks/footerNav/Footer';
import { backgroundTheme, dimensions, textColor } from '../style/';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import NotificationBanner from './blocks/NotificationBanner';
import SiteNotification from './blocks/SiteNotification';
import { ToastContainer } from 'react-toastify';
import '../style/normalize.css';
import '../style/toastify-theme.css';
import Loader from '../atoms/LoaderStyled';
import styled from '@emotion/styled';
import OfflineContainer from './blocks/OfflineContainer';
import { differenceInSeconds } from 'date-fns';
import { EXPIRE_SEARCH_HOURS as expireSearchHrs } from '../config/Master';
import { getDate, getLocalItem, renderAvatar } from '../common';
import { NOTIF_TIMEOUT as notifTimeout } from '../config/constants';
import { getBrowserDetails, isDarkMode, removeBodyScroll, ipadNoHomeButton } from '../utils/helpers';
import { StickyContainer } from 'react-sticky';
import { useStateCallback } from '../utils/customHooks';
import useRealGifts from '../hooks/useRealGifts';
import useMixPanel from '../hooks/useMixPanel';
import usePageViews from '../hooks/usePageViews';
import { useApp } from '../contexts/AppContext';

// Removing speedy false until we can remove css() calls in global module scope.
import { speedy } from 'glamor';
import VerificationRequired from './pages/Verification/VerificationRequired';
import {
  DEFAULT_REASON,
  EVIDENTLY_EXPERIMENTS,
  EVIDENTLY_FEATURES,
  EVIDENTLY_NO_PROFILE_FAVORITE_KEYS,
  getFeatureDetails,
} from '../constants/evidently';
import { batchEvaluateFeature, trackEvidentlyMultipleMetrics } from '../sdk/CommonSDK';
import { useLocation } from 'react-router-dom';
import store from '../store';
import GalleryUploadBackgroundProgress from '../modules/gallery/GalleryUpload/components/GalleryUploadBackgroundProgress';
import useEvidently from '../hooks/useEvidently';
import evidentlyFeatures from '../utils/evidentlyFeatures';
import { ProfileMaker } from '../models/Profile';
import Footer from './blocks/Footer';
import PWAGlobalBanner from './pwa/PWAGlobalBanner';
import PWAInstallationModal from './pwa/PWAInstallationModal';
import { AccountMaker } from '../models/Account';
import '../style/customSWui.css';
import NotificationBanners from './blocks/NotificationBanners';
import { mixpanelTrackPWAInstallBannerShown } from '../utils/mixpanel/pwaInstallBannerShown';

speedy(false);

type GetFeatureNamesToEvaluateCallbackType = () => { feature: EVIDENTLY_FEATURES }[];

const publicPages = ['', '/login', '/join', '/restoring'];
const footerlessPages = ['mail', 'search'];

const Contents = styled('div')(
  {
    minHeight: '99.97%',
  },
  ({ footer }) =>
    footer && {
      paddingBottom: '135px',
      [dimensions.SCREEN_MAX_MD]: {
        paddingBottom: '500px',
      },
    },
  ({ isMobile }) =>
    isMobile && {
      paddingBottom: '40px',
    },
  ({ isInMessages }) =>
    isInMessages && {
      paddingBottom: !ipadNoHomeButton ? 13 : 33,
    }
);

const StyledContainer = styled(StickyContainer)({
  backgroundColor: backgroundTheme.surface,
  ...textColor.DARK,
});

const ContentContainer = styled('div')`
  transition: 0.25s ease-in-out;
  ${props => props.isLoading && 'opacity: 0; height: 0; overflow: hidden;'};
`;

const toasterStyle = {
  top: getBrowserDetails().isMobile ? 0 : 65,
  zIndex: getBrowserDetails().isMobile ? 1001 : 1000,
};

const App: FC = (props: any) => {
  const {
    initiatePendingFav,
    lastSearchTime,
    clearSearch,
    removeLastSearchMeta,
    notification,
    clearNotificationMessage,
    profile,
    account,
    isFetching,
    children,
    loadPageUrl,
    isAuthenticated,
    globalNotification,
    visitMessages,
    generateUrl,
    finishVerification,
    refreshProfile,
    settings,
  } = props;
  const { initRGUserCookie } = useRealGifts();
  const { trackPageView } = useMixPanel();
  const { setAccountHashId, setPwaBannerState, setIsAccountIsMixpanelEligableEnabled } = useApp();
  const location = useLocation();
  const { useFeaturesEvaluation } = useEvidently();
  useFeaturesEvaluation(evidentlyFeatures, ProfileMaker.create(profile));

  usePageViews(({ location, isLeaving }) => trackPageView(!isLeaving, location.pathname));

  useEffect(() => {
    (async () => {
      const accountModel = AccountMaker.create(account);
      const shouldShow = await accountModel.showPWAInstallationGlobalBanner();

      if (shouldShow.show) {
        mixpanelTrackPWAInstallBannerShown();
      }

      setPwaBannerState({ ...shouldShow });
    })();
  }, [account.hash_id]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
    });
  }, []);

  const { status, is_paid } = account.id_verification?.data ?? {};

  const [sidebarToggle, setSidebarToggle] = useStateCallback(false);
  const requiredVerification =
    status === 'required' || status === 'pending' || status === 'revoked' || status === 'denied';
  const [verificationStatus, setVerificationStatus] = useState(status);
  const { isMobile } = getBrowserDetails();
  const page = location.pathname;
  const isPublic = Boolean(publicPages.indexOf(page) >= 0);
  const isFooterless = isMobile || Boolean(footerlessPages.indexOf(page.split('/')[1]) >= 0);
  const avatar = profile && profile.data && profile.data.avatar && profile.data.avatar.data;
  const nagNotification = null;
  const darkMode = isDarkMode();

  const checkLastSearchTime = useCallback(() => {
    const secondDiff = process.env.REACT_APP_ENV !== 'production' ? 60 : expireSearchHrs;

    if (lastSearchTime === null) {
      return;
    }

    const shouldExpireSearch = differenceInSeconds(getDate(), new Date(lastSearchTime)) >= secondDiff;

    if (shouldExpireSearch && navigator.onLine) {
      clearSearch();
      removeLastSearchMeta();
    }
  }, [lastSearchTime]);

  const checkPendingFav = useCallback(() => {
    if (navigator.onLine) {
      const pendingFav = getLocalItem('pending_fav');
      if (pendingFav) {
        const item = JSON.parse(pendingFav);
        item && initiatePendingFav(item.profile_id);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('online', checkPendingFav);
    window.addEventListener('offline', checkPendingFav);
    checkLastSearchTime();

    return () => {
      window.removeEventListener('online', checkPendingFav);
      window.removeEventListener('offline', checkPendingFav);
    };
  }, []);

  useEffect(() => {
    if (status) {
      setVerificationStatus(status);
    }
  }, [status]);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle, newState => {
      if (newState) {
        disableBodyScroll(document.getElementById('sidebar-parent'));
      } else {
        clearAllBodyScrollLocks();
        removeBodyScroll();
      }
    });
  };

  const hideSidebar = () => {
    setSidebarToggle(false, () => {
      clearAllBodyScrollLocks();
      removeBodyScroll();
    });
  };

  const ToastCloseBtn = ({ closeToast }) => (
    <div onClick={closeToast}>
      <i className="toastify__close">
        <svg viewBox="0 0 12 12" height="12" width="12" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="11" x2="11" y2="1" stroke="#FFF" strokeWidth="3" />
          <line x1="1" y1="1" x2="11" y2="11" stroke="#FFF" strokeWidth="3" />
        </svg>
      </i>
    </div>
  );

  const getFeatureNamesToEvaluate = useCallback<GetFeatureNamesToEvaluateCallbackType>(() => {
    const evaluateNoProfileFavorite = () => {
      const featureDetails = getFeatureDetails(settings.evidently, EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE);
      return featureDetails === null ? { feature: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE } : null;
    };

    return [evaluateNoProfileFavorite()].filter(feature => feature !== null);
  }, [account?.mandatory_profile_completed, profile?.data?.account_type]);

  useEffect(() => {
    if (account.hash_id) {
      initRGUserCookie(account.hash_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.hash_id]);

  useEffect(() => {
    if (account.hash_id) {
      setAccountHashId(account.hash_id);
      setIsAccountIsMixpanelEligableEnabled(account.is_mixpanel_eligible_and_enabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.hash_id, profile, settings]);

  useEffect(() => {
    const features = getFeatureNamesToEvaluate();

    if (!profile?.data?.hash_id || features.length === 0) return;

    (async () => {
      try {
        const response = await batchEvaluateFeature({ userId: profile.data.hash_id, features });
        response.data.map(feature => {
          const { reason, variation, userId, featureName } = feature;

          props.storeEvidently({ [featureName]: { variation, userId, reason } });
        });
      } catch (error) {
        console.error('batchEvaluateFeature', { error, features });
      }
    })();
  }, [profile?.data?.hash_id, getFeatureNamesToEvaluate]);

  useEffect(() => {
    const featureDetails = getFeatureDetails(
      store.getState().settings.evidently,
      EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE
    );

    const userId = profile?.data?.hash_id;
    const { reason = DEFAULT_REASON } = featureDetails || {};

    if (featureDetails && reason != DEFAULT_REASON && userId) {
      trackEvidentlyMultipleMetrics([
        {
          hashId: userId,
          featureName: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE,
          experimentName: EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE,
          key: EVIDENTLY_NO_PROFILE_FAVORITE_KEYS.OFFERS_SENT,
          value: 0,
          shouldAddUpValue: false,
        },
        {
          hashId: userId,
          featureName: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE,
          experimentName: EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE,
          key: EVIDENTLY_NO_PROFILE_FAVORITE_KEYS.OFFERS_ACCEPTED,
          value: 0,
          shouldAddUpValue: false,
        },
        {
          hashId: userId,
          featureName: EVIDENTLY_FEATURES.NO_PROFILE_FAVORITE,
          experimentName: EVIDENTLY_EXPERIMENTS.NO_PROFILE_FAVORITE,
          key: EVIDENTLY_NO_PROFILE_FAVORITE_KEYS.AMOUNT_PURCHASED,
          value: 0,
          shouldAddUpValue: false,
        },
      ]);
    }
  }, [profile?.data?.hash_id, store.getState().settings.evidently]);

  return (
    <StyledContainer id="main">
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .dash { background: ${isDarkMode() ? '#020d15' : '#F4F7FA'}}`,
        }}
      />
      {profile && globalNotification && globalNotification.id && (
        <SiteNotification
          notification={globalNotification}
          clearNotificationMessage={clearNotificationMessage}
          loadPageUrl={loadPageUrl}
          isAuthenticated={isAuthenticated}
          acctType={profile && profile.data && profile.data.account_type}
        />
      )}
      {!sessionStorage.getItem('hasAddedPhoto') && <PWAGlobalBanner />}
      <PWAInstallationModal />
      <ToastContainer
        className={isPublic ? 'container--public' : ''}
        autoClose={notifTimeout}
        position="top-center"
        closeOnClick={false}
        closeButton={<ToastCloseBtn />}
        style={toasterStyle}
      />
      <GalleryUploadBackgroundProgress />
      <NotificationBanner
        notification={notification}
        clearNotificationMessage={clearNotificationMessage}
        loadPageUrl={loadPageUrl}
        isAuthenticated={isAuthenticated}
        location={location}
        style={toasterStyle}
      />
      <NotificationBanners />
      {account && profile && (
        <HeaderNav
          hasGlobalNotif={globalNotification && globalNotification.messages}
          username={account.username}
          hashId={account.hash_id}
          newFavorites={(account.new_favorites || 0) + (account.new_profile_views || 0)}
          newOffers={account.offer_counts.total}
          messages={account.unread_messages}
          avatar={(avatar && renderAvatar(avatar.urls, true)) || null}
          canUpgrade={Boolean(profile && profile.data && profile.data.account_type === 'Generous') || false}
          credits={account.credits}
          pathname={location.pathname}
          nagNotification={nagNotification}
          isMobile={isMobile}
          isDarkMode={darkMode}
          toggleSidebar={toggleSidebar}
          hideSidebar={hideSidebar}
          requiredVerification={requiredVerification}
          {...props}
        />
      )}
      <Contents className="contents" isMobile={isMobile} footer={!isFooterless} isInMessages={visitMessages}>
        <Loader active={isFetching} style={{ position: 'absolute', width: '100%', top: '40%' }} color={'#1383D2'} />
        <ContentContainer isLoading={isFetching}>
          {verificationStatus === 'denied' || (!is_paid && requiredVerification) ? (
            <VerificationRequired
              verificationStatus={verificationStatus}
              generateUrl={generateUrl}
              finishVerification={finishVerification}
              refreshProfile={refreshProfile}
              account={account}
            />
          ) : (
            children
          )}
        </ContentContainer>
      </Contents>
      <Footer isFooterless={isFooterless} profile={profile} />
      {isMobile && account && profile && (
        <FooterNav
          newFavorites={(account.new_favorites || 0) + (account.new_profile_views || 0)}
          newOffers={account.offer_counts.total}
          messages={account.unread_messages}
          pathname={location.pathname}
          isDarkMode={darkMode}
          requiredVerification={requiredVerification}
          profile={profile}
        />
      )}
      <OfflineContainer />
    </StyledContainer>
  );
};

export default App;
