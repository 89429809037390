import React, { ReactElement } from 'react';

const StatusSuccess = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 19.17 19.17">
      <defs />
      <g id="Layer_2" data-name="Layer 2" fill="#0db800">
        <g className="status-success__cls-1" id="Layer_1-2" data-name="Layer 1">
          <g className="status-success__cls-1">
            <path
              className="status-success__cls-2"
              d="M17.89,4.77a9.38,9.38,0,0,1,1.29,4.81,9.37,9.37,0,0,1-1.29,4.81,9.54,9.54,0,0,1-3.49,3.49,9.39,9.39,0,0,1-4.81,1.29,9.38,9.38,0,0,1-4.81-1.29A9.55,9.55,0,0,1,1.29,14.4,9.38,9.38,0,0,1,0,9.59,9.38,9.38,0,0,1,1.29,4.77,9.54,9.54,0,0,1,4.77,1.29,9.37,9.37,0,0,1,9.59,0,9.38,9.38,0,0,1,14.4,1.29,9.54,9.54,0,0,1,17.89,4.77ZM16,7.56A.77.77,0,0,0,15.8,7L14.67,5.87a.78.78,0,0,0-1.12,0L8.45,10.95,5.63,8.13a.78.78,0,0,0-1.12,0L3.37,9.25a.78.78,0,0,0-.22.57.76.76,0,0,0,.22.56L7.89,14.9a.77.77,0,0,0,.56.24A.78.78,0,0,0,9,14.9L15.8,8.13A.76.76,0,0,0,16,7.56Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StatusSuccess;
