import styled from '@emotion/styled';
import typography from '../../theme/typography';
import { theme } from '../../theme';
import backgrounds from '../../theme/backgrounds';
import colors from '../../theme/colors';
import { isDarkMode } from '../../utils/helpers';
import { dimensions } from '../../style';

export const mobileOverlayStyle = {
  borderRadius: 10,
  height: '100%',
  width: '90%',
  padding: '0 10%',
  left: '-5%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

export const ContainerDiv = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
});

export const LogoIconWrapper = styled('div')({
  display: 'flex',
  marginTop: '20px',
  marginBottom: '20px',
  width: '40px',
  height: '39px',
  justifyContent: 'center',
  alignSelf: 'center',
  flexShrink: 0,
});

export const HeaderDiv = styled('div')({
  textAlign: 'center',
});

export const ButtonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
});

export const Title = styled('p')({
  color: '#2B8FD7',
  fontSize: 22,
  lineHeight: 'normal',
  fontStyle: 'normal',
  fontWeight: 700,
  margin: '0 0 15px 0',
});

export const Subtitle = styled('p')({
  fontSize: 15,
  color: typography[theme].primaryText,
  fontWeight: 400,
  lineHeight: '20px',
  margin: 0,
  letterSpacing: '-0.3px',
});

export const Subtitle2 = styled('p')({
  fontSize: 15,
  marginTop: 10,
  color: typography[theme].primaryText,
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '-0.3px',
});

export const OfferTextInputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: '15px',
});

export const mobileContentStyle = {
  padding: '30px 20px',
  borderRadius: 10,
  maxHeight: '80vh',
  top: '50%',
  height: 'auto',
  transform: 'translate(0, -50%)',
};

export const desktopContentStyle = {
  borderWidth: 0,
  padding: '30px 30px',
};

export const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  height: '24px',
  padding: 0,
});

export const PriceContainer = styled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme === 'light' ? '#fff' : backgrounds.dark.blue,
    textAlign: 'center',
    width: '185px',
    height: '55px',
    margin: '10px auto',
    borderRadius: 6,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16) inset',
  },
  ({ hasErrors }) => ({
    border: hasErrors ? `2px solid ${colors.light.warning.primary}` : '1px solid #C4C4C4',
  })
);

export const CurrencyLabelSpan = styled('span')({
  textAlign: 'right',
  fontSize: '42px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '42px',
  letterSpacing: '-1.68px',
  color: theme === 'light' ? colors.light.blue.tertiary : colors.blue[200],
});

export const ProfileCardsWrapper = styled('div')({
  marginTop: 30,
});

export const closeButton = {
  fontSize: 20,
  color: '#8F8F8F',
};

export const inputPropsStyle = {
  fontSize: 42,
  height: 55,
  lineHeight: '42px',
  fontWeight: 600,
  outline: 'none',
  border: 'none',
  marginLeft: -17,
  color: isDarkMode() ? colors['dark'].blue.secondary : colors['light'].blue.tertiary,
  letterSpacing: '-1.68px',
  fontFamily: 'system-ui, -apple-system, Arial, sans-serif',
};

export const adjustStylePerDigit = (numb: number): any => {
  const numString = numb ? numb.toString() : '0';
  let marginLeft = -17;
  let paddingLeft = 10;
  switch (numString.length) {
    case 3:
      marginLeft = -10;
      paddingLeft = 10;
      break;
    case 2:
      marginLeft = -22;
      paddingLeft = 20;
      break;
    case 1:
      marginLeft = -34;
      paddingLeft = 30;
      break;
    default:
      marginLeft = -32;
      paddingLeft = 10;
      break;
  }
  return {
    marginLeft,
    paddingLeft,
  };
};

export const cardMarginForOddCard = {
  [dimensions.SCREEN_MIN_XS_4]: {
    width: '48.9%',
    margin: '3.5px 0px 3.5px 3.5px',
  },
};

export const cardMarginForEvenCard = {
  [dimensions.SCREEN_MIN_XS_4]: {
    width: '48.9%',
    margin: '3.5px 3.5px 3.5px 0px',
  },
};
