import React from 'react';
// import Optional from '../../atoms/Optional';
import RadioButtonGroup from '../../atoms/RadioButtonGroup';

interface IJoinRadioButtonFormGroupProps {
  status?: string;
  statusMessage?: string;
  label: string;
  isRequired?: boolean;
  children: React.ReactNode;
  customInputLabelStyle?: React.CSSProperties;
}
const JoinRadioButtonFormGroup: React.FC<IJoinRadioButtonFormGroupProps> = ({
  status,
  label,
  statusMessage,
  children,
  isRequired = false,
  customInputLabelStyle,
}) => {
  return (
    <RadioButtonGroup
      inputLabel={label}
      status={status}
      statusMessage={statusMessage}
      isRequired={isRequired}
      joinForm
      customStyle={{ fontSize: 15, fontWeight: 600 }}
      customInputLabelStyle={customInputLabelStyle}
    >
      {children}
    </RadioButtonGroup>
  );
};

export default JoinRadioButtonFormGroup;
