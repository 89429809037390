/* UI component constants
 *
 *
 */
import { isDarkMode } from '../utils/helpers';

const themeDarkMode = isDarkMode();

export const socialColor = {
  GOOGLE: '#4285F4',
};

export const statusColor = {
  success: '#0DB800',
  error: '#CC5453',
  caution: '#FF7C00',
  notification: '#FF0000',
  default: !themeDarkMode ? 'rgba(0,0,0,0.15)' : 'rgba(255,255,255,0.15)',
};

export const space = {
  XS: 4,
  SM: 8,
  MD: 10,
  LG: 20,
  XL: 32,
};

export const dimensions = {
  SCREEN_MIN_XXS: '@media (min-width: 321px)',
  SCREEN_MAX_XXS: '@media (max-width: 320px)',

  SCREEN_MIN_XS_4: '@media (max-width: 375px)',
  SCREEN_MIN_XS_3: '@media (max-width: 361px)',
  SCREEN_MIN_XS_2: '@media (max-width: 426px)',
  SCREEN_MIN_XS: '@media (min-width: 480px)',
  SCREEN_MAX_XS: '@media (max-width: 479px)',
  SCREEN_MAX_XS_2: '@media (max-width: 620px)',

  SCREEN_MIN_SM: '@media (min-width: 640px)',
  SCREEN_MAX_SM: '@media (max-width: 639px)',

  SCREEN_MIN_MD: '@media (min-width: 769px)',
  SCREEN_MAX_MD: '@media (max-width: 768px)',
  SCREEN_MAX_MD_1: '@media (max-width: 767px)',
  SCREEN_MAX_MD_2: '@media (max-width: 834px)',

  SCREEN_MIN_LG: '@media (min-width: 945px)',
  SCREEN_MAX_LG: '@media (max-width: 944px)',

  SCREEN_MIN_XL: '@media (min-width: 1080px)',
  SCREEN_MAX_XL: '@media (max-width: 1079px)',

  SCREEN_MIN_XXL: '@media (min-width: 1366px)',
  SCREEN_MAX_XXL: '@media (max-width: 1365px)',
};

export const rawMediaQuery = (mq: any): any => mq.replace('@media ', '');

export const textColor = {
  // Grey colors. Use for normal body and Title text.
  LIGHTER: { color: !themeDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)' },
  NORMAL: { color: !themeDarkMode ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)' },
  DARK: { color: !themeDarkMode ? 'rgba(0, 0, 0, 0.65)' : '#AFB0B1' },
  DARKER: { color: !themeDarkMode ? 'rgba(0, 0, 0, 0.8)' : '#cfcfcf' },
  DARKEST: { color: !themeDarkMode ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)' },
  DARK_2: { color: !themeDarkMode ? '#333' : '#AFB0B1' },
  //Misc. Colors
  BRAND: { color: !themeDarkMode ? '#3e79a5' : '#cfcfcf' },
  RED: { color: 'rgb(204, 84, 84)' },
  LINK: { color: !themeDarkMode ? '#1383d2' : '#AFB0B1' },
};

export const color = {
  GREY_1: 'rgba(0, 0, 0, 0.033)',
  GREY_2: !themeDarkMode ? 'rgba(0, 0, 0, 0.44)' : '#cfcfcf',
  GREY_3: 'rgba(0, 0, 0, 0.066)',
  GREY_LIGHTEST: !themeDarkMode ? 'rgba(0, 0, 0, 0.02)' : 'rgba(0, 0, 0, 0.85)',

  //Brand colors
  BRAND_1: !themeDarkMode ? '#3e79a5' : '#6c6c6c',
  BRAND_2: '#1383d2',
  BRAND_1_DARK: '#3e79a5',
  BRAND_1_ONLINE: '#32CD32',
  BRAND_BLUE_BG: '#D6E5FD',

  //
  EMAIL: !themeDarkMode ? '#263445' : '#cfcfcf',
};

export const size = {
  SIZE_40X40: {
    height: '40px',
    width: '40px',
  },
  SIZE_60X60: {
    height: '60px',
    width: '60px',
  },
  SIZE_80X80: {
    height: '80px',
    width: '80px',
  },
  SIZE_90X90: {
    height: '90px',
    width: '90px',
  },
};

export const type = {
  XS: { fontSize: '12px' },
  SM: { fontSize: '13px' },
  MD: { fontSize: '16px' },
  LG: { fontSize: '20px' },
  XL: { fontSize: '26px' },
  XXL: { fontSize: '33px' },
  XXXL: { fontSize: '53px' },

  //weights
  THIN: { fontWeight: '300' },
  NORMAL: { fontWeight: '400' },
  MEDIUM: { fontWeight: '600' },
  BOLD: { fontWeight: '700' },

  title: {
    XS: { fontSize: '18px' },
    SM: { fontSize: '20px' },
    MD: { fontSize: '24px' },
    LG: { fontSize: '30px' },
    XL: { fontSize: '36px' },
  },
};

export const textType = {
  header: {
    fontSize: '20px',
    fontWeight: 600,
    color: !themeDarkMode ? '#333' : '#cfcfcf',
    textTransform: 'capitalize',
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    color: !themeDarkMode ? 'rgba(0,0,0,0.65)' : '#AFB0B1',
  },
  caption: {
    fontSize: '13px',
    fontWeight: 400,
    color: !themeDarkMode ? 'rgba(0,0,0,0.65)' : '#AFB0B1',
  },
  processingHeader: {
    ...type.XL,
    ...type.MEDIUM,
    color: !themeDarkMode ? 'rgba(0,0,0,0.8)' : '#cfcfcf',
  },
  blockHeader: {
    fontWeight: 600,
    color: !themeDarkMode ? '#333333' : '#cfcfcf',
  },
};

export const leading = {
  // Relative Units
  BASE: { lineHeight: '1.4' },
  TIGHT: { lineHeight: '1.2' },
  TIGHTER: { lineHeight: '1.1' },
  TIGHTEST: { lineHeight: '1' },
  LOOSE: { lineHeight: '1.6' },
  INHERIT: { lineHeight: 'inherit' },

  // Add absolute units here.
};

export const spacing = {
  SM: 10,
  MD: 15,
  LG: 22,
  XL: 32,

  MARGIN_0: { margin: '0' },
  MARGIN_SM: { margin: '10px' },
  MARGIN_MD: { margin: '16px' },

  MARGIN_BOTTOM_XS: { marginBottom: '4px' },
  MARGIN_BOTTOM_SM: { marginBottom: '10px' },
  MARGIN_BOTTOM_MD: { marginBottom: '16px' },
  MARGIN_BOTTOM_LG: { marginBottom: '22px' },
  MARGIN_BOTTOM_XLG: { marginBottom: '24px' },
  MARGIN_BOTTOM_XL: { marginBottom: '32px' },

  MARGIN_TOP_XS: { marginTop: '4px' },
  MARGIN_TOP_SM: { marginTop: '10px' },
  MARGIN_TOP_MD: { marginTop: '16px' },
  MARGIN_TOP_LG: { marginTop: '22px' },
  MARGIN_TOP_XL: { marginTop: '32px' },

  MARGIN_V_XS: { margin: '4px 0' },
  MARGIN_V_SM: { margin: '10px 0' },
  MARGIN_V_MD: { margin: '22px 0' },
  MARGIN_V_LG: { margin: '32px 0' },

  MARGIN_LEFT_XS: { marginLeft: '4px' },
  MARGIN_LEFT_SM: { marginLeft: '10px' },
  MARGIN_LEFT_MD: { marginLeft: '16px' },
  MARGIN_LEFT_LG: { marginLeft: '22px' },
  MARGIN_LEFT_XL: { marginLeft: '32px' },

  MARGIN_LEFT_NEG_XL: { marginLeft: '-32px' },

  MARGIN_RIGHT_XS: { marginRight: '4px' },
  MARGIN_RIGHT_SM: { marginRight: '10px' },
  MARGIN_RIGHT_MD: { marginRight: '16px' },
  MARGIN_RIGHT_LG: { marginRight: '22px' },
  MARGIN_RIGHT_XL: { marginRight: '32px' },

  MARGIN_H_MD: { margin: '0 22px' },
  MARGIN_H_LG: { margin: '0 32px' },

  MARGIN_RIGHT_SM_NEG: { marginRight: -10 },

  PADDING_0: { padding: '0' },
  PADDING_XS: { padding: '4px' },
  PADDING_SM: { padding: '10px' },
  PADDING_MD: { padding: '16px' },
  PADDING_LG: { padding: '22px' },

  // padding

  PADDING_H_SM: { padding: '0 10px' },
  PADDING_H_MD: { padding: '0 22px' },

  PADDING_BOTTOM_XS: { paddingBottom: '4px' },
  PADDING_BOTTOM_SM: { paddingBottom: '10px' },
  PADDING_BOTTOM_MD: { paddingBottom: '16px' },
  PADDING_BOTTOM_LG: { paddingBottom: '22px' },
  PADDING_BOTTOM_XL: { paddingBottom: '32px' },

  PADDING_TOP_XS: { paddingTop: '4px' },
  PADDING_TOP_SM: { paddingTop: '10px' },
  PADDING_TOP_MD: { paddingTop: '16px' },
  PADDING_TOP_LG: { paddingTop: '22px' },
  PADDING_TOP_XL: { paddingTop: '32px' },

  PADDING_V_XS: { padding: '4px 0' },
  PADDING_V_SM: { padding: '10px 0' },
  PADDING_V_MD: { padding: '22px 0' },
  PADDING_V_XL: { padding: '32px 0' },

  PADDING_LEFT_XS: { paddingLeft: '4px' },
  PADDING_LEFT_SM: { paddingLeft: '10px' },
  PADDING_LEFT_MD: { paddingLeft: '16px' },
  PADDING_LEFT_LG: { paddingLeft: '22px' },
  PADDING_LEFT_XL: { paddingLeft: '32px' },

  PADDING_RIGHT_XS: { paddingRight: '4px' },
  PADDING_RIGHT_SM: { paddingRight: '10px' },
  PADDING_RIGHT_MD: { paddingRight: '16px' },
  PADDING_RIGHT_LG: { paddingRight: '22px' },
  PADDING_RIGHT_XL: { paddingRight: '32px' },

  //spacing (Margin) utils
  //spacing (inter - Padding) utils
};

export const borders = {
  DEFAULT: `1px solid ${!themeDarkMode ? '#DAE1E9' : '#6c6c6c'}`,
  DARKER: `1px solid ${!themeDarkMode ? 'rgba(0,0,0,0.15)' : '#6c6c6c'}`,
  BRAND: `1px solid ${!themeDarkMode ? '#1383d2' : '#2b8fd7'}`,
  ERROR: `1px solid ${!themeDarkMode ? 'rgb(204, 84, 84)' : '#bc2028'}`,
  FOCUS: `1px solid ${!themeDarkMode ? '#1383d2' : '#cfcfcf'}`,
  DASHED: `1px dashed ${!themeDarkMode ? '#D8D8D8' : '#D8D8D8'}`,
};

export const box = {
  border: borders.DEFAULT,
  boxShadow: '0 1px 4px 0 rgba(0,0,0,.04)',
  borderRadius: '6px',
};

/**
 * FORM Components
 * Use these until we create common form Components
 */

export const textInput = {
  width: '100%',
  boxSizing: 'border-box',
  padding: '0 15px',
  height: '38px',
  outline: 'none',
  borderRadius: '3px',
  border: borders.DARKER,
  ...type.MD,
};

export const comboBox = {
  appearance: 'none',
  boxSizing: 'border-box',
  background: '0 0',
  border: borders.DARKER,
  padding: '0 15px',
  height: '38px',
  outline: 'none',
  borderRadius: '3px',
  ...type.SM,
};

export const headline = {
  color: textColor.BRAND.color,
  fontSize: '22px',
  fontWeight: 'normal',
  margin: '0 0 16px 0',
};

export const backgroundTheme = {
  surface: themeDarkMode ? '#020d15' : '#f5f7fa',
  fsModal: themeDarkMode ? '#020d15' : '#ffffff',
  block: !themeDarkMode ? '#f5f7fa' : '#041a2a',
  block2: !themeDarkMode ? '#fff' : '#041a2a',
  paymentBlock: !themeDarkMode ? '#F1F7FF' : '#041a2a',
  paymentHighlight: !themeDarkMode ? '#f1f7ff' : '#0E2E45',
  navBG: !themeDarkMode ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.07)',
  fill: !themeDarkMode ? '#0d5c93' : '#eeeeee',
  gallery: !themeDarkMode ? '#f4f6f9' : '#041a2a',
  personalInfo: !themeDarkMode ? '#ffffff' : '#041a2a',
  defaultText: !themeDarkMode ? '#eeeeee' : '#71B5E4',
  greyText: !themeDarkMode ? '#3c4858' : '#cfcfcf',
  billingHeading: !themeDarkMode ? 'rgba(0,0,0,.80)' : '#cfcfcf',
  selectedPackage: !themeDarkMode ? '#D6E5FD' : '#041a2a',
  creditText: !themeDarkMode ? '#2490de' : '#cfcfcf',
  defaultBnW: !themeDarkMode ? '#bc2028' : '#FFFFFF',
  creditColor: !themeDarkMode ? '#bc2028' : '#d06369',
  placeHolderBG: themeDarkMode ? '#020d15' : 'linear-gradient(to right, #EFF1F3 8%, #FBFBFD 18%, #EFF1F3 33%)',
  emText: themeDarkMode ? '#cfcfcf' : 'rgba(0, 0, 0, 0.6)',
  completionBanner: themeDarkMode ? '#020d15' : '#E6F4FD',
  footerLinks: themeDarkMode ? '#71B5E4' : '#595959',
  cardDetails: themeDarkMode ? '#AFB0B1' : 'rgba(255, 255, 255, 0.7)',
  blankDetails: themeDarkMode ? '#AFB0B1' : 'rgba(0, 0, 0, 0.7)',
  iconFill: themeDarkMode ? '#AFB0B1' : '#0d5c93',
  abandonmentBG: themeDarkMode ? '#020d15' : '#EAF4FB',
  AddMedia: themeDarkMode ? '#113956' : '#EAF4FB',
  orderSummary: !themeDarkMode ? '#ffffff' : '#041a2a',
  paymetPackage: !themeDarkMode ? '#ffffff' : '#041a2a',
  packageCard: !themeDarkMode ? '#ffffff' : '#041a2a',
  headerCTA: !themeDarkMode ? 'linear-gradient(180deg, #EAF4FB 0%, #FFF 100%)' : '#041a2a',
};

export const iconsTheme = {
  profileCompleteness: themeDarkMode ? '#3c4858' : 'rgba(0, 0, 0, 0.45)',
};

export const shadowTheme = {
  footer: themeDarkMode ? '0 -1px 2px 0px rgba(232, 232, 232, 0.2)' : '0 -1px 2px 0px rgba(0, 0, 0, 0.2)',
  header: themeDarkMode ? '0 2px 2px -2px rgba(232, 232, 232, 0.2)' : '0 2px 2px -2px rgba(0, 0, 0, 0.2)',
  footerLogo: themeDarkMode
    ? '0 -2px 0.5px -0.5px rgba(108, 122, 137, 0.3)'
    : '0 -2px 0.5px -0.5px rgb(0, 0, 0,  0.2);',
};

export const buttonTheme = {
  hoverFocus: !themeDarkMode ? 'rgb(28, 115, 177)' : 'rgba(28, 115, 177, 0.8)',
  active: !themeDarkMode ? 'rgb(21, 86, 133)' : 'rgba(21, 86, 133, 0.8)',
  disabled: !themeDarkMode ? 'rgb(167, 210, 241)' : 'rgba(167, 210, 241, 0.8)',
  videoCall: themeDarkMode ? '#0d5c93' : '#dedede',
  buttonText: themeDarkMode ? '#020d15' : '#f5f7fa',
  GreyAndWhite: themeDarkMode ? '#f5f7fa' : 'rgb(255, 255, 255)',
  DarkButton: themeDarkMode ? '#000000' : 'rgba(0, 0, 0, 0.8)',
  payButton: themeDarkMode ? '#1383d2' : '#020d15',
  darkReverse: themeDarkMode ? '#f5f7fa' : 'rgba(0, 0, 0, 0.8)',
  MenuGreyAndWhite: themeDarkMode ? 'rgba(255, 255, 255, 0.07)' : 'rgb(255, 255, 255)',
  primaryRed: {
    background: '#BC2028',
    border: '#BC2028',
    text: '#FFF',
    hover: {
      text: '#FFF',
      background: '#CD585E',
      border: '#CD585E',
    },
    disabled: {
      text: themeDarkMode ? '#DD8F93' : '#F8E9EA',
      background: themeDarkMode ? '#961A20' : '#DD8F93',
      border: themeDarkMode ? '#961A20' : '#DD8F93',
    },
    active: {
      text: '#DD8F93',
      background: '#961A20',
      border: '#961A20',
    },
  },
  secondaryRed: {
    background: themeDarkMode ? 'transparent' : '#fff',
    border: themeDarkMode ? '#D06369' : '#BC2028',
    text: themeDarkMode ? '#D06369' : '#BC2028',
    hover: {
      text: themeDarkMode ? '#FFF' : '#BC2028',
      background: themeDarkMode ? '#BC2028' : '#EEC7C9',
      border: themeDarkMode ? '#BC2028' : '#BC2028',
      boxShadow: 'none',
    },
    disabled: {
      text: themeDarkMode ? '#711318' : '#EEC7C9',
      border: themeDarkMode ? '#711318' : '#EEC7C9',
    },
    active: {
      text: themeDarkMode ? '#CD585E' : '#DD8F93',
      background: themeDarkMode ? '#711318' : '#961A20',
      border: themeDarkMode ? '#711318' : '#961A20',
      boxShadow: 'none',
    },
  },
  secondaryBlue: {
    background: themeDarkMode ? 'transparent' : '#fff',
    border: themeDarkMode ? '#60ABE1' : '#2B8FD7',
    text: themeDarkMode ? '#60ABE1' : '#2B8FD7',
    hover: {
      text: themeDarkMode ? '#FFF' : '#FFF',
      background: themeDarkMode ? '#2272AC' : '#2B8FD7',
      border: themeDarkMode ? '#2272AC' : '#2B8FD7',
    },
    disabled: {
      text: themeDarkMode ? '#1A5681' : '#CAE3F5',
      border: themeDarkMode ? '#1A5681' : '#CAE3F5',
    },
    active: {
      text: themeDarkMode ? '#60ABE1' : '#2272AC',
      background: themeDarkMode ? '#1A5681' : '#95C7EB',
      border: themeDarkMode ? '#1A5681' : '#95C7EB',
    },
  },
};

export const slidebarTheme = {
  listHover: themeDarkMode ? '#020d15' : '#f3f3f3',
  headerBG: themeDarkMode ? '#041a2a' : '#f2f3f5',
  headerText: themeDarkMode ? '#f2f3f5' : 'rgba(0,0,0,.8)',
};

export const inputTheme = {
  clearBtn: themeDarkMode ? 'transparent' : '#FFFFFF',
  backgroundInput: themeDarkMode ? 'rgba(255, 255, 255, 0.07)' : '#FFFFFF',
  colorInput: themeDarkMode ? '#FFFFFF' : '#000000',
  checkedInput: themeDarkMode ? 'rgba(255, 255, 255, 0.07)' : '#1383d2',
};

export const svgTheme = {
  svgColor: themeDarkMode ? '#FFF' : '#000',
  arrowBG: themeDarkMode ? 'rgba(4, 26, 42, 0.75)' : 'rgba(51, 51, 51, 0.75)',
};

export const creditCardInputField = {
  backgroundInput: themeDarkMode ? 'transparent' : '#FFFFFF',
};

export const rheostatTheme = {
  bgColor: themeDarkMode ? '#020d15' : '#2490de',
};

export const patternModal = {
  bgGradient: themeDarkMode
    ? 'linear-gradient(180deg, #1A5681 0%, #020D15 100%)'
    : 'linear-gradient(180deg, #CAE3F5 0%, #FFFFFF 100%)',
  borderStyle: themeDarkMode ? '2px solid #2B8FD7' : 'solid 2px #ffffff',
  headerColor: themeDarkMode ? '#AFB0B1' : '#595959',
  amtColor: themeDarkMode ? '#cfcfcf' : '#3e79a5',
  linkBlue: themeDarkMode ? '#71B5E4' : '#0D5C93',
  titleColor: themeDarkMode ? '#D06369' : '#BC2028',

  // cancel button
  cancelBlue: themeDarkMode ? '#71B5E4' : '#2B8FD7',
  cancelBG: themeDarkMode ? 'transparent' : '#FFFFFF',
  cancelBorder: themeDarkMode ? '2px solid #71B5E4' : '2px solid #2B8FD7',
};

export const buttonIcons = {
  borderStyle: themeDarkMode ? '2px solid #2272AC' : '2px solid #FFF',
  borderStyleHover: '2px solid #FFF',
  borderCrashOutStyle: !themeDarkMode ? '3px solid rgba(255, 255, 255, 1)' : '2px solid #2272AC',
};

export const amountButtonTheme = {
  backgroundStyle: !themeDarkMode ? 'rgba(244,247,250,1)' : '#041a2a',
  borderStyle: !themeDarkMode ? '2px solid #FFF' : '2px solid #2272AC',
  borderHoverStyle: '2px solid #FFF',
};

export const completionTheme = {
  containerBG: themeDarkMode ? '#113956' : 'rgb(230, 244, 253)',
  itemBG: themeDarkMode ? '#082D48' : '#FFFFFF',
  containerBorder: themeDarkMode ? 'solid 1px #113956' : 'solid 1px #EAF4FB',
};

export const idvTheme = {
  successIDV: themeDarkMode ? '#082D48' : '#FFFFFF',
  textColor: themeDarkMode ? '#FFFFFF' : '#2B8FD7',
  gradientBG: themeDarkMode
    ? 'linear-gradient(#020d15, #020d15) padding-box, linear-gradient(to right, #2B8FD7, #BC2028) border-box'
    : 'linear-gradient(white, white) padding-box, linear-gradient(to right, #2B8FD7, #BC2028) border-box',
  sideBarGradientBG: themeDarkMode
    ? 'linear-gradient(#020d15, #020d15) padding-box, linear-gradient(to right, #2B8FD7, #BC2028) border-box'
    : 'linear-gradient(#F5F7FA, #F5F7FA) padding-box, linear-gradient(to right, #2B8FD7, #BC2028) border-box',
  btnBG: themeDarkMode ? '#020d15' : '#EAF4FB',
};

export const paymentTheme = {
  SUMMARY: { color: !themeDarkMode ? '#353946' : '#cfcfcf', borderColor: !themeDarkMode ? '#F1F7FF' : '#0E2E45' },
  DIVIDER: { borderColor: !themeDarkMode ? '#D8D8D8' : '#0E2E45' },
  SUCCESS: {
    borderColor: !themeDarkMode ? '#F1F7FF' : '#0E2E45',
    total: {
      color: themeDarkMode ? '#cfcfcf' : '#353946',
    },
    item: {
      color: themeDarkMode ? '#cfcfcf' : '#333',
    },
  },
  TERMS: { color: !themeDarkMode ? '#232323' : '#AFB0B1' },
};

export const sideBarNav = {
  bgGradient: themeDarkMode
    ? 'linear-gradient(180deg, #1A5681 0%, #020D15 35%)'
    : 'linear-gradient(180deg, #CAE3F5 0%, #FFFFFF 35%)',
};
