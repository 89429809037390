import React, { ReactElement } from 'react';
interface IStepTwoIconProps {
  customStyle?: any;
}

const StepTwoIcon = ({ customStyle }: IStepTwoIconProps): ReactElement => {
  return (
    <svg width="29" style={customStyle} height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.5" cy="14.0995" r="13" fill="white" stroke="#2B8FD7" strokeWidth="2" />
      <path
        d="M9.89213 21.0995V19.1052L14.5519 14.7905C14.9482 14.407 15.2806 14.0618 15.5491 13.755C15.8218 13.4482 16.0285 13.1478 16.1691 12.8537C16.3097 12.5555 16.3801 12.2337 16.3801 11.8885C16.3801 11.505 16.2927 11.1748 16.118 10.8978C15.9433 10.6165 15.7046 10.4013 15.4021 10.2522C15.0995 10.0988 14.7565 10.0221 14.373 10.0221C13.9724 10.0221 13.623 10.103 13.3247 10.265C13.0264 10.4269 12.7963 10.6591 12.6343 10.9617C12.4724 11.2643 12.3914 11.6243 12.3914 12.042H9.76429C9.76429 11.1854 9.95819 10.4418 10.346 9.81113C10.7338 9.18045 11.2771 8.69253 11.9759 8.34736C12.6748 8.00219 13.4802 7.8296 14.3921 7.8296C15.3296 7.8296 16.1457 7.99579 16.8403 8.32818C17.5392 8.65631 18.0825 9.11227 18.4703 9.69608C18.858 10.2799 19.0519 10.9489 19.0519 11.7032C19.0519 12.1975 18.9539 12.6854 18.7579 13.167C18.5661 13.6485 18.2231 14.1833 17.7288 14.7714C17.2345 15.3552 16.5377 16.0562 15.6386 16.8743L13.7274 18.7472V18.8367H19.2245V21.0995H9.89213Z"
        fill="#2B8FD7"
      />
    </svg>
  );
};

export default StepTwoIcon;
