import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { visitMessagesPage } from '../actions/commonActions';
import MailScreen from '../screens/Mail';
import {
  triggerThreadsFetching,
  fetchThreadTimeout,
  setConversationPage,
  enableConversation,
  updateThreadsCollection,
  setThread,
  updateMessages,
  toggleAcctTyping,
  updateMessageandConvo,
  triggerConvoFetching,
  triggerPostMessageProcess,
  triggerReplaceMessageId,
  handleDeleteImage,
  handleUnblurImage,
  setSendPoke,
  handleReportImage,
  handleResendMessage,
  handleUnlockOffer,
  handleDeleteConversation,
  setToggleArchive,
  handleCancelOffer,
  closeConversation,
  handleThreadClick,
  setblockUser,
  handlePostTypingStatus,
  setToggleThreadMessageStatus,
  setDeletePhotoMessage,
  presetOrder,
  fetchReceiverThread,
} from '../actions/mailActions';
import { withPusher } from '../contexts/PusherContext';
import {
  uploadPhotoMessage,
  refreshAccount,
  endPendingUpload,
  updateMsgReminder,
  fetchPageNux,
  updateNuxGuideStates,
  skipNuxGuides,
} from '../actions/profileActions';
import { PUSHER, TYPING_TIMEOUT } from '../config/constants';
import { localMessageConversations } from '../utils/storedLocalData';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { clearGlobalNotifications, getGlobalNotifications } from '../actions/globalNotificationActions';
import useMixPanel from '../hooks/useMixPanel';
import { EVENT_NAMES } from '../constants/mixpanel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PWAMessagePageBanner from '../components/pwa/PWAMessagePageBanner';
import { showPWAInstallationMessagePageBanner } from '../utils/pwa';
import { AccountMaker } from '../models/Account';
import CompleteProfileModalV2 from '../modules/modals/CompleteProfileModalV2';
import { displayUserLocation, renderAvatar } from '../common';
import { mixpanelTrackPushNotifClickedAfterRedirection } from '../utils/mixpanel/pushNotifClicked';

const getPage = path => {
  if (path.indexOf('archive') > -1) {
    return 'archive';
  }

  if (path.indexOf('unlocked') > -1) {
    return 'unlocked';
  }

  if (path.indexOf('locked') > -1) {
    return 'locked';
  }

  if (path.indexOf('unread') > -1) {
    return 'unread';
  }

  return 'recent';
};

const newMessage = {
  message: 'This message is deleted',
  is_deleted: true,
  status: 'deleted',
};

const MessagesContainer = ({
  bindListener,
  presetOrder,
  getThreads,
  endPendingUpload,
  setConversationPage,
  triggerReplaceMessageId,
  isUploading,
  order,
  onRealtimeEvent,
  fetchThreadTimeout,
  updateThreadsCollection,
  enableConversation,
  conversation,
  account,
  toggleThreadMessageStatus,
  threads,
  saveNewThreads,
  updateMessages,
  updateMessageandConvo,
  stopListening,
  triggerConvoFetching,
  uploadPhotoMessage,
  handlePostMessageProps,
  triggerDeleteImage,
  triggerReportImage,
  triggerUnlockOffer,
  toggleArchive,
  setPostTypingStatus,
  handleCloseConversation,
  auth,
  iceServers,
  videoInfo,
  isOtherAccountTyping,
  fetchingThreads,
  fetchingMoreThreads,
  fetchingConversation,
  updateReminder,
  handleUnblurImage,
  handleResendMessage,
  handlePostPoke,
  handleDeleteConversation,
  visitMessagesPage,
  visitMessages,
  handleCancelOffer,
  handleBlockUser,
  handleThreadClick,
  setOtherAcctTyping,
  fetchPageNux,
  updateNuxState,
  skipNuxGuides,
  refreshGlobalNotification,
  fetchReceiverThread,
  mailError,
}) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { mixpanelData, resetMixpanelData, trackPackagesPageVisited, trackProfileView } = useMixPanel();

  const getThreadsCancelTokenSource = axios.CancelToken.source();
  const [showPwaInstallPromptBanner, setShowPwaInstallPromptBanner] = useState(false);
  const memoisedAvatar = useMemo(() => renderAvatar(account?.profile?.data?.avatar?.data?.urls || {}), [
    account?.profile?.data?.avatar?.data,
  ]);

  useEffect(() => {
    (async () => {
      const showBanner = await showPWAInstallationMessagePageBanner(AccountMaker.create(account));
      setShowPwaInstallPromptBanner(showBanner.show);
    })();
  }, [account]);

  const [isUserFinishProfileModalOpen, setIsUserFinishProfileModalOpen] = useState(false);

  useEffect(() => {
    bindListener(listen);
    presetOrder(getPage(location.pathname));
    endPendingUpload();
    setTimeout(() => checkUnlock(), 1500); // setTimout for user delay on fetching conversation
    return () => {
      stopListening(listen);
      refreshGlobalNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order) {
      getThreads(1, navigate, location, getThreadsCancelTokenSource);
    }

    // Cancel get threads api requests
    return () => {
      getThreadsCancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (mailError?.code === 403 && !account?.can_make_offer) {
      setIsUserFinishProfileModalOpen(true);
    }
  }, [mailError]);

  useEffect(() => {
    if (mailError?.code === 403 && !account?.can_make_offer) {
      setIsUserFinishProfileModalOpen(true);
    }
  }, [mailError]);

  useEffect(() => {
    fetchConversation();
  }, [params.hashId]);

  useEffect(() => {
    setConversationPage(getPage(location.pathname), !params.hashId);
  }, [params, location.pathname]);

  useEffect(() => {
    triggerReplaceMessageId(params);
  }, [isUploading]);

  useEffect(() => {
    if (location) {
      mixpanelTrackPushNotifClickedAfterRedirection(location);
    }
  }, [location]);

  const handlePostMessage = (message: string, type = '', source = null, extension = null) => {
    handlePostMessageProps(message, type, source, extension, params, navigate, location);
  };

  const checkUnlock = () => {
    const currentConversation = getConversationMessage();
    const queryStringParsed = queryString.parse(location.search);
    const unlockMsg = queryStringParsed && queryStringParsed.unlockMessage === 'true';
    if (unlockMsg && currentConversation.locked) {
      handleUnlockFromFav();
    }
  };

  const getConversationMessage = useCallback(() => {
    const currentHashId = window.location.pathname.split('/')[2];
    const conversationMessage = localMessageConversations(currentHashId);

    return _isEmpty(conversationMessage) ? {} : conversationMessage;
  }, [location.pathname]);

  const getConversationAfterUnlock = async () => {
    const currentHashId = location.pathname.split('/')[2];
    const conversationMessage = await localMessageConversations(currentHashId);
    return _isEmpty(conversationMessage) ? {} : conversationMessage;
  };

  const listen = () => {
    const { EVENTS } = PUSHER;

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      // logic for unlocking conversation after generous unlocked it
      if (data.type !== 'mail' || data.action !== 'unlock') {
        return false;
      }

      const { data: realtimeData } = data;
      const senderHashId = realtimeData.account.hash_id;

      if (
        conversation.hash_id === senderHashId ||
        (conversation.to_account_hash_id === senderHashId && data.action === 'unlock')
      ) {
        enableConversation();
      }
      updateThreadsCollection(senderHashId).then(() => {
        fetchThreadTimeout(navigate, location);
      });
      // ---- end logic
    });

    onRealtimeEvent(EVENTS.ACCOUNT_ALERT_MESSAGE, ({ data }) => {
      const { message, thread } = data;
      let messageData = [];
      if (conversation.messages !== undefined) {
        messageData = conversation.messages.data;
      }
      const isConversationPath = window.location.pathname === `/mail/${message.hash_id}`;

      if (isConversationPath) {
        message.i_read = true;
      }

      const lastMessage = messageData[messageData.length - 1];
      const newThread =
        lastMessage && message.id > lastMessage.id
          ? {
              ...threads,
              collection: threads.collection.map(item => {
                if (item.id === thread.id) {
                  item.last_message = { data: message };
                }

                return item;
              }),
            }
          : {
              ...threads,
            };

      // Update threads state collection
      saveNewThreads(newThread).then(() => {
        fetchReceiverThread();
        fetchConversation();
      });

      if (conversation.id !== thread.id) {
        return false;
      }

      if (isConversationPath) {
        toggleThreadMessageStatus(true, thread.id, account.hash_id, 'read');
      }

      const messages = conversation.messages !== undefined ? Object.assign([], conversation.messages) : undefined;
      // check if message id already exists for status change
      const msgIdx = messages.data.findIndex(msg => msg.id === message.id && message.status === 'deleted');
      if (msgIdx < 0) {
        messages.data.push(message);
      } else {
        messages.data[msgIdx] = message;
      }

      updateMessages(messages);

      const conversationBody = document.getElementById('conversation_body');
      if (conversationBody) {
        conversationBody.scrollTop = conversationBody.scrollHeight;
      }
    });

    onRealtimeEvent(EVENTS.ACCOUNT_TYPING_INDICATOR, ({ data }) => {
      if (params.hashId === data.account.hash_id) {
        setOtherAcctTyping();

        setTimeout(() => setOtherAcctTyping(), TYPING_TIMEOUT);
      }
    });

    onRealtimeEvent(EVENTS.ACCOUNT_MESSAGE_STATUS, ({ data }) => {
      if (params.hashId === data.account.hash_id) {
        updateMessageandConvo();
      }
    });
  };

  const fetchConversation = useCallback(
    (page = 1) => {
      const currentConversation = getConversationMessage();
      triggerConvoFetching(currentConversation, params, navigate, location, page);
    },
    [getConversationMessage, triggerConvoFetching]
  );

  const handleUploadPhotoMessage = async (formData, hashID) => {
    const extension = formData.name.split('.');
    const resPhoto = await uploadPhotoMessage(formData, hashID);
    if (resPhoto)
      handlePostMessageProps(
        resPhoto.message,
        'photo',
        null,
        extension[extension.length - 1],
        params,
        navigate,
        location
      );
    return resPhoto;
  };

  const handleDeleteImage = (option, messageID) => {
    triggerDeleteImage(newMessage, messageID, option);
  };

  const handleReportImage = messageID => {
    triggerReportImage(newMessage, messageID);
  };

  const handleUnlockOffer = (threadId, hashId) => {
    const currentConversation = getConversationAfterUnlock();
    triggerUnlockOffer(
      currentConversation,
      threadId,
      hashId,
      mixpanelData[EVENT_NAMES.CONVERSATION_UNLOCKED].triggerToBuy,
      params,
      navigate,
      location,
      () => resetMixpanelData(EVENT_NAMES.CONVERSATION_UNLOCKED)
    );
  };

  const handleUnlockFromFav = () => {
    getConversationAfterUnlock()
      .then(currentConversation => {
        if (currentConversation && currentConversation.offer.data.credit_cost > account.credits) {
          trackPackagesPageVisited({ Source: 'Unlock Attempt' });
          navigate('/packages');
          return;
        }

        setTimeout(() => {
          if (currentConversation.locked) {
            triggerUnlockOffer(
              currentConversation,
              currentConversation.id,
              currentConversation.other_profile.data.hash_id,
              mixpanelData[EVENT_NAMES.CONVERSATION_UNLOCKED].triggerToBuy,
              params,
              navigate,
              location,
              () => resetMixpanelData(EVENT_NAMES.CONVERSATION_UNLOCKED)
            );
          }
        }, 500);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleToggleArchiveConversation = hashId => {
    toggleArchive(hashId, 1);
  };

  const handlePostTypingStatus = () => {
    setPostTypingStatus(params.hashId);
  };

  const handleThreadChange = e => {
    const order = e.target.value;

    handleCloseConversation(navigate, location, params);

    navigate(`/mail/${order !== 'recent' ? order : ''}`);
  };

  const handleGoToProfile = hashId => {
    trackProfileView({ Source: 'Conversations', User: hashId });
    navigate(`/profile/${hashId}`);
  };

  const handleGoToSearch = () => {
    navigate('/search');
  };

  const threadClickHandler = (threadId, hashId) => {
    handleThreadClick(threadId, hashId, params, navigate);
  };

  const fetchThreadsHandler = (page: number) => {
    getThreads(page, navigate, location, getThreadsCancelTokenSource);
  };

  return (
    <div>
      {typeof params.hashId === 'undefined' && (
        <PWAMessagePageBanner onClose={() => setShowPwaInstallPromptBanner(false)} />
      )}
      <MailScreen
        showPwaInstallPromptBanner={showPwaInstallPromptBanner}
        threads={threads}
        conversation={conversation}
        isOtherAccountTyping={isOtherAccountTyping}
        fetchingThreads={fetchingThreads}
        fetchingMoreThreads={fetchingMoreThreads}
        fetchingConversation={fetchingConversation}
        order={order || 'recent'}
        authUser={account}
        auth={auth}
        openThreadHashId={params.hashId}
        handlePostMessage={handlePostMessage}
        uploadPhotoMessage={handleUploadPhotoMessage}
        handleFetchConversation={fetchConversation}
        handleFetchThreads={fetchThreadsHandler}
        handlePostPoke={handlePostPoke}
        handleUnlockOffer={handleUnlockOffer}
        handleToggleArchiveConversation={handleToggleArchiveConversation}
        handleCancelOffer={handleCancelOffer}
        handleBlockUser={handleBlockUser}
        handleThreadChange={handleThreadChange}
        handleThreadClick={threadClickHandler}
        handleGoToProfile={handleGoToProfile}
        handleCloseConversation={handleCloseConversation}
        handlePostTypingStatus={handlePostTypingStatus}
        handleGoToSearch={handleGoToSearch}
        handleResendMessage={handleResendMessage}
        handleDeleteConversation={handleDeleteConversation}
        iceServers={iceServers}
        videoInfo={videoInfo}
        isUploading={isUploading}
        handleDeleteImage={handleDeleteImage}
        handleUnblurImage={handleUnblurImage}
        handleReportImage={handleReportImage}
        visitMessagesPage={visitMessagesPage}
        visitMessages={visitMessages}
        handleReminderStatus={updateReminder}
        fetchPageNux={fetchPageNux}
        updatePageNux={updateNuxState}
        skipNuxGuides={skipNuxGuides}
      />
      {account?.profile && (
        <CompleteProfileModalV2
          avatar={memoisedAvatar}
          username={account.username}
          location={displayUserLocation(account?.profile?.data, account?.profile?.data)}
          age={account.age}
          editProfileLink={`/profile/${account.hash_id}`}
          isOpen={isUserFinishProfileModalOpen}
          onClose={() => setIsUserFinishProfileModalOpen(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    account: state.profile,
    iceServers: state.settings.iceServers,
    videoInfo: state.settings.video_info,
    isUploading: state.profile.pendingUploads,
    visitMessages: state.common.visitMessages,
    threads: state.mail.threads,
    conversation: state.mail.conversation,
    isOtherAccountTyping: state.mail.isOtherAccountTyping,
    fetchingThreads: state.mail.fetchingThreads,
    fetchingMoreThreads: state.mail.fetchingMoreThreads,
    fetchingConversation: state.mail.fetchingConversation,
    messagePendingUpdate: state.mail.messagePendingUpdate,
    order: state.mail.order,
    failedMsg: state.mail.failedMsg,
    mailError: state.mail.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refreshAccount: () => dispatch(refreshAccount()),
    endPendingUpload: () => dispatch(endPendingUpload(false)),
    visitMessagesPage: () => dispatch(visitMessagesPage()),
    uploadPhotoMessage: (formData, otherHashId) => {
      return dispatch(uploadPhotoMessage(formData, otherHashId));
    },
    updateReminder: () => dispatch(updateMsgReminder()),
    getThreads: (page = 1, navigate, location, axiosCancelRequestTokenSource) => {
      dispatch(triggerThreadsFetching(page, navigate, location, axiosCancelRequestTokenSource));
    },
    fetchThreadTimeout: (navigate, location) => {
      return dispatch(fetchThreadTimeout(navigate, location));
    },
    fetchReceiverThread: () => {
      return dispatch(fetchReceiverThread());
    },
    enableConversation: () => {
      dispatch(enableConversation());
    },
    setConversationPage: (section, hashId) => {
      dispatch(setConversationPage(section, hashId));
    },
    updateThreadsCollection: senderHashId => {
      return dispatch(updateThreadsCollection(senderHashId));
    },
    saveNewThreads: newThread => {
      return dispatch(setThread(newThread));
    },
    updateMessages: messages => {
      dispatch(updateMessages(messages));
    },
    setOtherAcctTyping: () => {
      dispatch(toggleAcctTyping());
    },
    updateMessageandConvo: () => {
      dispatch(updateMessageandConvo());
    },
    triggerConvoFetching: (currentConvo, params, navigate, location, page) => {
      dispatch(triggerConvoFetching(currentConvo, params, navigate, location, page));
    },
    handlePostMessageProps: (message, type = '', source = null, extension = null, params, navigate, location) => {
      dispatch(triggerPostMessageProcess(message, type, source, extension, params, navigate, location));
    },
    triggerReplaceMessageId: params => {
      return dispatch(triggerReplaceMessageId(params));
    },
    triggerDeleteImage: (newMessage, messageID, option) => {
      dispatch(handleDeleteImage(newMessage, messageID, option, ownProps.navigate, ownProps.location));
    },
    handleUnblurImage: messageID => {
      dispatch(handleUnblurImage(messageID));
    },
    triggerReportImage: (newMessage, messageID) => {
      dispatch(handleReportImage(newMessage, messageID, ownProps.navigate, ownProps.location));
    },
    handleResendMessage: id => {
      dispatch(handleResendMessage(id, ownProps.navigate, ownProps.location, ownProps.params));
    },
    triggerUnlockOffer: (
      currConvo,
      threadId,
      hashId,
      triggerToBuy,
      params,
      navigate,
      location,
      callback = () => undefined
    ) => {
      dispatch(handleUnlockOffer(currConvo, threadId, hashId, params, navigate, location, triggerToBuy, callback));
      setTimeout(() => dispatch(triggerThreadsFetching(1, navigate, location)), 400);
    },
    handlePostPoke: hashId => {
      dispatch(setSendPoke(hashId));
    },
    handleDeleteConversation: (threadId, hashId) => {
      dispatch(handleDeleteConversation(threadId, hashId, ownProps.navigate, ownProps.location, ownProps.params));
    },
    presetOrder: order => {
      dispatch(presetOrder(order));
    },
    handleCancelOffer: hashId => {
      dispatch(handleCancelOffer(hashId, ownProps.navigate, ownProps.location, ownProps.params));
    },
    handleThreadClick: (threadId, hashId, params, navigate) => {
      dispatch(handleThreadClick(threadId, hashId, params, navigate));
    },
    handleCloseConversation: (navigate, location, params) => dispatch(closeConversation(navigate, location, params)),
    setPostTypingStatus: hashId => dispatch(handlePostTypingStatus(hashId)),
    toggleArchive: (hashId, page) => {
      dispatch(setToggleArchive(hashId, page, ownProps.navigate, ownProps.location));
    },
    handleBlockUser: (hashId, username) => dispatch(setblockUser(hashId, username, ownProps.navigate)),
    toggleThreadMessageStatus: (isRead, id, hash_id, status) =>
      dispatch(setToggleThreadMessageStatus(isRead, id, hash_id, status)),
    deletePhotoMessage: (option, messageID) => dispatch(setDeletePhotoMessage(option, messageID)),
    fetchPageNux: curRoute => {
      dispatch(fetchPageNux(curRoute));
    },
    updateNuxState: nuxUpdates => {
      dispatch(updateNuxGuideStates(nuxUpdates));
    },
    skipNuxGuides: () => {
      return dispatch(skipNuxGuides());
    },
    refreshGlobalNotification: () => {
      dispatch(clearGlobalNotifications());
      dispatch(getGlobalNotifications());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withPusher(MessagesContainer));
