import React from 'react';
import DoNotDisturbOnOutlinedIcon from '../../../atoms/icons/DoNotDisturbOnOutlinedIcon';
import { MAX_NUMBER_PHOTOS } from '../../../config/Master';
import useModal from '../../../hooks/useModal';
import { MediaTypes } from '../constants';
import { PhotoConfig } from '../../../config/Media';

type ErrorModalType = {
  validFiles: File[];
  invalidFiles: File[];
};

type UseGalleryUploadErrorMessageModalActionType = {
  mediaType: MediaTypes;
  confirmModalAction: (validFiles: File[]) => void;
  cancelModalAction: () => void;
};

type UseGalleryUploadErrorMessageModalType = ({
  mediaType,
  confirmModalAction,
  cancelModalAction,
}: UseGalleryUploadErrorMessageModalActionType) => {
  showTooManyFilesWarning: (validFiles: File[]) => void;
  showInvalidFilesErrorModal: ({ validFiles, invalidFiles }: ErrorModalType) => void;
};

const useGalleryUploadErrorMessageModal: UseGalleryUploadErrorMessageModalType = ({
  mediaType,
  confirmModalAction,
  cancelModalAction,
}) => {
  const { confirmActionModal, confirmErrorModal } = useModal();

  return {
    showTooManyFilesWarning: (validateFiles: File[]) => {
      confirmActionModal({
        dataTestID: 'too-many-files-selected-modal',
        centerAlign: false,
        withCancel: false,
        hideCloseBtn: true,
        customOverlayStyle: { zIndex: 10002 },
        modalContentLabel: 'Uh oh! Too many pics',
        modalIcon: <DoNotDisturbOnOutlinedIcon />,
        confirmationProps: {
          title: 'Uh oh! Too many files',
          message: (
            <>
              <p>Your profile can only have {MAX_NUMBER_PHOTOS} pics and videos in total.</p>
              <p>Please go through your upload and remove extra files.</p>
            </>
          ),
          confirmText: 'OK',
          confirmAction: () => confirmModalAction(validateFiles),
          cancelAction: () => cancelModalAction(),
        },
      });
    },

    showInvalidFilesErrorModal: ({ invalidFiles, validFiles }: ErrorModalType) => {
      if (invalidFiles.length < 1) return;

      const hasMultipleFileSelected = invalidFiles.length + validFiles.length > 1;

      const detailsErrorMessage = () => {
        if (invalidFiles.length === 1) {
          return (
            <p>
              Your {mediaType === 'photo' ? 'image' : 'video'} could not be uploaded because it exceeds{' '}
              {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
            </p>
          );
        }

        // multiple files selected
        // and has no valid files
        if (validFiles.length < 1 && hasMultipleFileSelected) {
          return (
            <p>
              Your {mediaType === 'photo' ? 'images' : 'videos'} could not be uploaded because they exceed{' '}
              {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
            </p>
          );
        }

        // multiple files selected
        // and has multiple invalid files
        return (
          <p>
            Some {mediaType === 'photo' ? 'images' : 'videos'} could not be uploaded because they exceed{' '}
            {mediaType === 'photo' ? '15 MB' : '15 seconds in length or 75 Mbs'}.
          </p>
        );
      };

      if (validFiles.length < 1) {
        confirmErrorModal({
          dataTestID: 'invalid-file-selected-modal',
          modalContentLabel: 'Success!',
          customOverlayStyle: { zIndex: 10002 },
          confirmationProps: {
            message: (
              <>
                {detailsErrorMessage()}
                {mediaType === 'photo' ? (
                  <p>
                    Images must be <strong>{PhotoConfig.allowedMimeTypeText}</strong> and less than{' '}
                    <strong>15 MB</strong>.
                  </p>
                ) : (
                  <p>
                    Videos must be <strong>MP4, AVI, MOV, WMV, WebM, MKV, AVCHD</strong> and less than{' '}
                    <strong>75 Mb</strong> and <strong>15 seconds</strong>.
                  </p>
                )}
              </>
            ),
            title: 'Exceeded Max File Size',
            confirmText: `${validFiles.length < 1 ? 'Upload Again' : 'OK'}`,
            confirmAction: () => confirmModalAction(validFiles),
            cancelAction: () => cancelModalAction(),
          },
        });
      } else {
        confirmActionModal({
          dataTestID: 'invalid-file-selected-modal',
          centerAlign: false,
          withCancel: false,
          hideCloseBtn: true,
          modalContentLabel: 'Exceeded Max File Size',
          modalIcon: <DoNotDisturbOnOutlinedIcon />,
          customCss: {},
          customOverlayStyle: { zIndex: 10002 },
          confirmationProps: {
            message: (
              <>
                {detailsErrorMessage()}
                {mediaType === 'photo' ? (
                  <p>
                    Images must be <strong>{PhotoConfig.allowedMimeTypeText}</strong> and less than{' '}
                    <strong>15 MB</strong>.
                  </p>
                ) : (
                  <p>
                    Videos must be <strong>MP4, AVI, MOV, WMV, WebM, MKV, AVCHD</strong> and less than{' '}
                    <strong>75 Mb</strong> and <strong>15 seconds</strong>.
                  </p>
                )}
              </>
            ),
            title: 'Exceeded Max File Size',
            confirmText: `${validFiles.length < 1 ? 'Upload Again' : 'OK'}`,
            confirmAction: () => confirmModalAction(validFiles),
            cancelAction: () => cancelModalAction(),
          },
        });
      }
    },
  };
};

export default useGalleryUploadErrorMessageModal;
