import { VYSION_ACTIONS } from '../..';

const commonValues = {
  action: VYSION_ACTIONS.CLICK,
  page: 'Profile',
  path: 'Photo Upload',
};

export const enum ProfileUploadGalleryClickVysionEventsEnum {
  PROFILE_UPLOAD_GALLERY_ADD_CONTENT = 'profile-addContent',
  PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO = 'profile-uploadGalleryContent-photo',
  PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO = 'profile-uploadGalleryContent-video',
  PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CHOOSE_EXISTING = 'profile-uploadGalleryContent-photo-chooseExisting',
  PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CREATE_NEW = 'profile-uploadGalleryContent-photo-createNew',
  PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CHOOSE_EXISTING = 'profile-uploadGalleryContent-video-chooseExisting',
  PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CREATE_NEW = 'profile-uploadGalleryContent-video-createNew',
  PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL = 'profile-uploadGalleryContent-cancel',
}

export const profileUploadGalleryClickVysionEvents = {
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_ADD_CONTENT]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_ADD_CONTENT,
    component: 'Add content',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO,
    component: 'Upload Gallery Content',
    extraInfo: 'Photo',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO,
    component: 'Upload Gallery Content',
    extraInfo: 'Video',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CHOOSE_EXISTING]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CHOOSE_EXISTING,
    component: 'Upload Gallery Content',
    extraInfo: 'Choose Existing',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CREATE_NEW]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_PHOTO_CREATE_NEW,
    component: 'Upload Gallery Content',
    extraInfo: 'Create New',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CHOOSE_EXISTING]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CHOOSE_EXISTING,
    component: 'Upload Gallery Content',
    extraInfo: 'Choose Existing',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CREATE_NEW]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_VIDEO_CREATE_NEW,
    component: 'Upload Gallery Content',
    extraInfo: 'Create New',
  },
  [ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL]: {
    ...commonValues,
    eventName: ProfileUploadGalleryClickVysionEventsEnum.PROFILE_UPLOAD_GALLERY_CONTENT_CANCEL,
    component: 'Upload Gallery Content',
    extraInfo: 'Cancel',
  },
};
