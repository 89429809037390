import React, { useState, useEffect, ReactElement } from 'react';
import PanelTextWithHeader from '../../../atoms/panels/PanelTextWithHeader';
import EditableTextarea from '../../../atoms/EditableTextarea';
import { isDarkMode } from '../../../utils/helpers';

const FirstDate = (props: any): ReactElement => {
  const { seeking, editable, errors, isProcessing, ...rest } = props;
  const [editFirstDate, setEditFirstDate] = useState(false);
  const [firstDate, setFirstDate] = useState('');

  useEffect(() => {
    setFirstDate(seeking);
    return () => {
      setEditFirstDate(false);
    };
  }, [seeking]);

  const toggleEditable = e => {
    e.preventDefault();
    setEditFirstDate(!editFirstDate);
  };

  const updateFirstDate = (e, value) => {
    setFirstDate(value);
  };

  const handleSubmit = e => {
    const { updateProfileSeeking } = props;
    updateProfileSeeking({
      seeking: firstDate,
    });
    toggleEditable(e);
  };

  const handleCancel = e => {
    // need to clear the fields if user select cancel button
    toggleEditable(e);
    setFirstDate(seeking);
  };

  return (
    <div>
      {editFirstDate ? (
        <EditableTextarea
          className="profile__first-date"
          title="My ideal first date"
          value={firstDate}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          updateValue={updateFirstDate}
          errors={errors}
          id={'seeking'}
          minCount={50}
          placeholder="Dinner, drinks and going for a walk after? What's your ideal first date?"
          customStyle={{ paddingBottom: 20 }}
          isProcessing={isProcessing}
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
        />
      ) : (
        <PanelTextWithHeader
          className="profile__first-date"
          title="My ideal first date"
          editable={editable}
          content={firstDate}
          toggleEditable={toggleEditable}
          isProcessing={isProcessing}
          dataTestId="edit-seeking-info"
          panelStyle={isDarkMode() ? 'borderless' : 'white'}
          minCount={50}
          {...rest}
        />
      )}
    </div>
  );
};

export default FirstDate;
