import {
  UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE,
  HIDE_UPLOAD_SNACKBAR,
  IHideUploadSnackbar,
  IShowUploadSnackbar,
  IStatusUpdateSnackbarPayload,
  IUpdateUploadSnackbarStatus,
  IUploadSnackbarItem,
  SHOW_UPLOAD_SNACKBAR,
  UPDATE_UPLOAD_SNACKBAR_STATUS,
  UploadSnackbarType,
  INCREMENT_UPLOAD_SNACKBAR_RETRY,
  IUpdateUploadSnackbarUploadType,
  IIncrementSnackbarItemRetry,
} from '../reducers/types/uploadSnackbar.d';

export const showUploadSnackbar = (payload: IUploadSnackbarItem): IShowUploadSnackbar => ({
  type: SHOW_UPLOAD_SNACKBAR,
  payload,
});

export const hideUploadSnackbar = (): IHideUploadSnackbar => ({
  type: HIDE_UPLOAD_SNACKBAR,
});

export const updateUploadStatusSnackbar = (payload: IStatusUpdateSnackbarPayload): IUpdateUploadSnackbarStatus => ({
  type: UPDATE_UPLOAD_SNACKBAR_STATUS,
  payload,
});

export const updateUploadUploadTypeSnackbar = (payload: UploadSnackbarType): IUpdateUploadSnackbarUploadType => ({
  type: UPDATE_UPLOAD_SNACKBAR_UPLOAD_TYPE,
  payload,
});

export const incrementUploadSnackbar = (payload: { id: string }): IIncrementSnackbarItemRetry => ({
  type: INCREMENT_UPLOAD_SNACKBAR_RETRY,
  payload,
});
