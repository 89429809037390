import React from 'react';
interface ISliderArrowRightIconProps {
  customStyle?: any;
  onClick?: () => any;
}

const SliderArrowRightIcon = ({ customStyle = {}, ...rest }: ISliderArrowRightIconProps): any => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      style={customStyle}
      className="pressable"
      {...rest}
    >
      <g filter="url(#filter0_d_371_12940)" className="pressable">
        <path
          d="M14 24C12.6167 24 11.3167 23.7375 10.1 23.2125C8.88333 22.6875 7.825 21.975 6.925 21.075C6.025 20.175 5.3125 19.1167 4.7875 17.9C4.2625 16.6833 4 15.3833 4 14C4 12.6167 4.2625 11.3167 4.7875 10.1C5.3125 8.88333 6.025 7.825 6.925 6.925C7.825 6.025 8.88333 5.3125 10.1 4.7875C11.3167 4.2625 12.6167 4 14 4C15.3833 4 16.6833 4.2625 17.9 4.7875C19.1167 5.3125 20.175 6.025 21.075 6.925C21.975 7.825 22.6875 8.88333 23.2125 10.1C23.7375 11.3167 24 12.6167 24 14C24 15.3833 23.7375 16.6833 23.2125 17.9C22.6875 19.1167 21.975 20.175 21.075 21.075C20.175 21.975 19.1167 22.6875 17.9 23.2125C16.6833 23.7375 15.3833 24 14 24ZM14.2 15L13.3 15.9C13.1167 16.0833 13.025 16.3167 13.025 16.6C13.025 16.8833 13.1167 17.1167 13.3 17.3C13.4833 17.4833 13.7167 17.575 14 17.575C14.2833 17.575 14.5167 17.4833 14.7 17.3L17.3 14.7C17.5 14.5 17.6 14.2667 17.6 14C17.6 13.7333 17.5 13.5 17.3 13.3L14.7 10.7C14.5167 10.5167 14.2833 10.425 14 10.425C13.7167 10.425 13.4833 10.5167 13.3 10.7C13.1167 10.8833 13.025 11.1167 13.025 11.4C13.025 11.6833 13.1167 11.9167 13.3 12.1L14.2 13H11C10.7167 13 10.4792 13.0958 10.2875 13.2875C10.0958 13.4792 10 13.7167 10 14C10 14.2833 10.0958 14.5208 10.2875 14.7125C10.4792 14.9042 10.7167 15 11 15H14.2Z"
          fill="white"
          className="pressable"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_371_12940"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_371_12940" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_371_12940" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default SliderArrowRightIcon;
