import { vysionService } from '../utils/vysion/vysionService';
import { convertTitleCase } from '../utils/helpers';

const setDashboardAction = (eventType: any): any => {
  return {
    type: 'OFFERS',
    payload: {
      event: eventType,
      eventData: {
        action: 'click',
        page: 'Dashboard',
        extraInfo: setExtraInfo(eventType),
        component: setComponent(eventType),
      },
      userData: null,
      deviceData: null,
      extendedData: null,
    },
  };
};

const splitEventName = (eventName: any): any => eventName.split(' ');

const getKeyWord = (eventName: any): any => {
  const eventArr = splitEventName(eventName);
  return eventArr[eventArr.length - 1];
};

const setComponent = (eventName: any): any => {
  if (getKeyWord(eventName) === 'ACTIVITY') {
    return splitEventName(eventName)[0] === 'VISIT'
      ? `Activity ${convertTitleCase(splitEventName(eventName)[1])}`
      : `${convertTitleCase(splitEventName(eventName)[0])} ${convertTitleCase(splitEventName(eventName)[1])}`;
  } else {
    return convertTitleCase(getKeyWord(eventName));
  }
};

const setExtraInfo = eventName => {
  switch (getKeyWord(eventName)) {
    case 'ACTIVITY':
      return 'Activity Feed';
    case 'SEARCH':
    case 'INTERESTS':
    case 'OFFERS':
    case 'FAVOURITES':
      return 'Nav Bar';
    default:
      return;
  }
};

const sendDashboardActivityFeedCard = (): any => {
  vysionService(setDashboardAction('VISIT CARD ACTIVITY'));
};

const sendDashboardShowMoreActivity = (): any => {
  vysionService(setDashboardAction('SHOW MORE ACTIVITY'));
};

const sendDashboardNavbarSearch = (): any => {
  vysionService(setDashboardAction('NAVBAR SEARCH'));
};

const sendDashboardNavbarInterests = (): any => {
  vysionService(setDashboardAction('NAVBAR INTERESTS'));
};

const sendDashboardNavbarMessages = (): any => {
  vysionService(setDashboardAction('NAVBAR MESSAGES'));
};

const sendDashboardNavbarOffers = (): any => {
  vysionService(setDashboardAction('NAVBAR OFFERS'));
};

const sendDashboardNavbarFavourites = (): any => {
  vysionService(setDashboardAction('NAVBAR FAVOURITES'));
};

export {
  sendDashboardActivityFeedCard,
  sendDashboardShowMoreActivity,
  sendDashboardNavbarSearch,
  sendDashboardNavbarInterests,
  sendDashboardNavbarMessages,
  sendDashboardNavbarOffers,
  sendDashboardNavbarFavourites,
};
