import { stringDesc } from '../common';
import { getDefaultOfferAmount } from '../utils/offers';
import { ModelDateObject } from './types';

export type ProfileAccountType = 'Generous' | 'Attractive';

export type ProfileItem = {
  hash_id: string;
  account_type: ProfileAccountType;
  age: number;
  age_max: number | null;
  age_min: number | null;
  approved_public_photos_count: number;
  avatar: any; // Todo fix type
  default_offer_amount: number | null;
  has_set_default_offer_amount: boolean;
  created_at: ModelDateObject;
  last_activity_dt: ModelDateObject;
  online: boolean;
  [key: string]: unknown;
};

// Todo: Add all profile attrs
export type ProfileObject = ProfileItem & {
  data: ProfileItem; // there are parts of the code where profile format is `profile.data...
};

export const generateFakeProfileOItem = (customValues: Partial<ProfileItem>): Partial<ProfileItem> => {
  return {
    hash_id: '',
    account_type: 'Generous' as ProfileAccountType,
    age: 0,
    age_max: 0,
    age_min: 0,
    approved_public_photos_count: 0,
    avatar: '',
    default_offer_amount: 150,
    has_set_default_offer_amount: false,
    created_at: {
      date: '',
      timezone: '',
      timezone_type: 1,
    },
    ...customValues,
  };
};

export class Profile {
  constructor(public profile: ProfileObject) {}

  getData(): ProfileItem | null {
    return this.profile?.data || null;
  }

  hashId(): string {
    return this.profile?.data.hash_id;
  }

  isGenerous(): boolean {
    return this.profile?.data?.account_type === 'Generous';
  }

  isAttractive(): boolean {
    return !this.isGenerous();
  }

  defaultOfferAmount(): number | null {
    const defaultOfferAmount = getDefaultOfferAmount() || 150;
    return this.isAttractive() ? this.getData()?.default_offer_amount || defaultOfferAmount : defaultOfferAmount;
  }

  lastActivityStatus(): string {
    const data = this.getData();
    const lastActivityDate = data?.last_activity_dt?.date;
    const online = data?.online;

    if (!lastActivityDate) {
      return 'Recently';
    }

    return online ? 'Active Just Now' : `Active ${stringDesc(lastActivityDate, true, false)}`;
  }
}

export class ProfileMaker {
  static create(profile: ProfileObject): Profile {
    return new Profile(profile);
  }
}
