import {
  STORE_PACKAGES,
  STORE_KOUNT_DETAILS,
  STORE_PAYMENTS,
  CLEAR_PAYMENTS,
  STORE_PAYMENT_TOKEN,
  REMOVE_PAYMENT_TOKEN,
  STORE_IDV_PACKAGES,
  SET_PAYMENT_PROCESSING,
  KILL_PAYMENT_PROCESSING,
  BillingState,
  BillingActionTypes,
} from './types/Billing.d';

const initialState: BillingState = {
  packages: '',
  idv_packages: '',
  payments: '',
  kountDetails: '',
};

const billing = (state = initialState, action: BillingActionTypes): any => {
  switch (action.type) {
    case STORE_PACKAGES:
      // stores packages
      return {
        ...state,
        packages: action.payload,
      };
    case STORE_IDV_PACKAGES:
      // stores packages
      return {
        ...state,
        idv_packages: action.payload,
      };
    case STORE_KOUNT_DETAILS:
      // stores Kount details
      return {
        ...state,
        kountDetails: action.payload,
      };
    case STORE_PAYMENTS:
      // stores payments
      return {
        ...state,
        payments: action.payload,
      };
    case CLEAR_PAYMENTS:
      // clear payments
      return {
        ...initialState,
      };
    case STORE_PAYMENT_TOKEN:
      return {
        ...state,
        paymentToken: action.payload.data,
        alternativePayment: action.payload.meta.sofort_alternative_payment || false,
        rocketgateGooglepay: action.payload.meta.rocketgate_googlepay || false,
        applepayCredentials: action.payload?.meta?.applepay_credentials || undefined,
      };
    case REMOVE_PAYMENT_TOKEN:
      return {
        ...state,
        paymentToken: [],
      };
    case SET_PAYMENT_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };
    case KILL_PAYMENT_PROCESSING:
      return {
        ...state,
        isProcessing: false,
      };
    default:
      return state;
  }
};

export default billing;
