import { mixpanelTrackEventFromBE } from '.';

interface EventProperties {
  Result: string;
  'Profile Viewed': boolean;
  'Number of Photos Viewed': number;
  'Offer Amount': number | string;
}

export const mixpanelTrackDiscoverModeAction = async (
  eventProperties: EventProperties,
  callBack: () => void
): Promise<void> => {
  await mixpanelTrackEventFromBE({
    event_name: 'Discover Mode Action',
    payload: {
      event_properties: eventProperties,
    },
  });

  callBack();
};
