import React from 'react';

const MasterCardIcon = ({ customStyle }: { customStyle?: React.CSSProperties }): React.ReactElement => {
  return (
    <svg
      width="40"
      height="26"
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...customStyle }}
    >
      <rect x="0.754272" width="39" height="26" rx="4" fill="#212B36" />
      <g opacity="0.01">
        <rect x="4.53247" y="2.59961" width="31.4519" height="20.8" fill="white" />
      </g>
      <rect x="16.1106" y="6.22363" width="8.29562" height="13.5525" fill="#F26122" />
      <path
        d="M17.0043 12.9997C16.9906 10.3619 18.1886 7.86389 20.2543 6.22342C16.7201 3.44409 11.6433 3.84768 8.59268 7.15048C5.54202 10.4533 5.54202 15.546 8.59268 18.8488C11.6433 22.1516 16.7201 22.5552 20.2543 19.7759C18.1886 18.1354 16.9906 15.6374 17.0043 12.9997Z"
        fill="#EA1D25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3681 18.0618V18.338H33.3193V18.0618H33.2056V18.0049H33.4899V18.0618H33.3681ZM33.9206 18.0049V18.338H33.8637V18.0861L33.7743 18.3055H33.7093L33.6118 18.0861V18.338H33.555V18.0049H33.6362L33.7418 18.2405L33.8393 18.0049H33.9206Z"
        fill="#F69E1E"
      />
      <path
        d="M34.1968 12.9999C34.1946 16.2981 32.3108 19.306 29.3444 20.7478C26.378 22.1895 22.849 21.8123 20.2543 19.7762C23.9918 16.8333 24.6391 11.419 21.7005 7.67804C21.2793 7.13632 20.7936 6.64793 20.2543 6.22366C22.849 4.18753 26.378 3.81033 29.3444 5.25205C32.3108 6.69378 34.1946 9.70169 34.1968 12.9999Z"
        fill="#F69E1E"
      />
    </svg>
  );
};

export default MasterCardIcon;
