import React, { ReactElement, useState, useEffect } from 'react';
import Button from '../../atoms/buttons/Button';
import PhoneNumberInput from '../../atoms/PhoneNumberInput';
import {
  StyledContainer,
  Heading,
  ButtonAction,
  ErrorMessage,
} from '../../components/phone/phoneSettings/styledElements';
import { useApp } from '../../contexts/AppContext';
import { enableScroll } from '../../hooks/useDisableBodyScroll';
import usePhoneActionModal from '../../hooks/usePhoneActionModal';
import { IsMobileViewPort } from '../../utils/helpers';
import { VYSION_ACTIONS } from '../../utils/vysion';
import { handleJoinVysionEvent } from '../../utils/vysion/join';
import { JoinClickVysionEventsEnum } from '../../utils/vysion/join/click';
import { JoinOnFocusVysionEventsEnum } from '../../utils/vysion/join/onFocus';

const SignUpEditPhone = (): ReactElement => {
  const { joinPhoneNumber, setJoinPhoneNumber, hasRequestOtpError, setHasRequestOtpError } = useApp();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(joinPhoneNumber);
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    utils: { requestResendOtp },
  } = usePhoneActionModal();

  const handleSubmitEditPhone = () => {
    handleJoinVysionEvent({
      action: VYSION_ACTIONS.CLICK,
      event: JoinClickVysionEventsEnum.CLICK_JOIN_ENTER_PHONE_UPDATE,
    });

    if (joinPhoneNumber === updatedPhoneNumber) {
      setHasRequestOtpError('You cannot update to the same phone number.');
      return;
    }

    setIsProcessing(true);
    requestResendOtp({
      isLogin: false,
      isSignUp: true,
      phoneNumber: updatedPhoneNumber,
      onResendSuccess: () => {
        setJoinPhoneNumber(updatedPhoneNumber);
      },
      onResendCompleted: () => setIsProcessing(false),
    });
  };

  useEffect(() => {
    if (IsMobileViewPort) {
      // allow to scroll the country drpopdown issue in mobile
      enableScroll();
    }
  }, []);

  return (
    <StyledContainer>
      <Heading style={{ textAlign: 'left' }}>Enter a new number</Heading>
      <PhoneNumberInput
        defaultCountry={'us'}
        value={joinPhoneNumber}
        onChange={value => setUpdatedPhoneNumber(value)}
        autoFocus
        onFocus={() => {
          setHasRequestOtpError('');
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.ON_FOCUS,
            event: JoinOnFocusVysionEventsEnum.ENTER_NEW_PHONE_NUMBER,
          });
        }}
        handleOnChangeCountry={country => {
          handleJoinVysionEvent({
            action: VYSION_ACTIONS.CLICK,
            event: JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE_COUNTRY,
            extraInfo: country.name,
            newEventName: `join-enterANewNumber-countryCode-${country.countryCode}`,
          });
        }}
        handleClickCountryDropdown={showDropdown => {
          if (showDropdown) {
            handleJoinVysionEvent({
              action: VYSION_ACTIONS.CLICK,
              event: JoinClickVysionEventsEnum.CLICK_JOIN_PHONE_ENTER_NEW_NUMBER_COUNTRY_CODE,
            });
          }
        }}
      >
        {hasRequestOtpError && <ErrorMessage>{hasRequestOtpError}</ErrorMessage>}
      </PhoneNumberInput>
      <ButtonAction>
        <Button
          customStyle={{ margin: '0 auto' }}
          onClick={handleSubmitEditPhone}
          data-test-id="otp-modal-button"
          buttonType="primary"
          type="button"
          size="small"
          disabled={isProcessing}
        >
          {isProcessing ? 'Updating...' : 'Update'}
        </Button>
      </ButtonAction>
    </StyledContainer>
  );
};

export default SignUpEditPhone;
