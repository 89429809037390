import React, { useCallback } from 'react';
import Gallery from './Gallery';
import GalleryMigration from './GalleryMigration';
import _sortBy from 'lodash/sortBy';
import { getBrowserDetails, removeForbiddenCharacters } from '../../../utils/helpers';

interface PhotosProps {
  photos: any;
  openLightbox: any;
  isOtherProfile: any;
  refreshAndClearErrors: any;
  uploadPhoto: any;
  isUploading: any;
  photoData: any;
  displayMessage: any;
  setPrivacy: any;
  makeDefault: any;
  deletePhoto: any;
  hasPublicPhotos: any;
  singlePublicLeft: any;
  hasPublicApprovedPhotos: any;
  migration: any;
  uploadMedia: any;
  deleteMedia: any;
  setMediaPrivacy: any;
  bulkTogglePrivacy: any;
  bulkDeleteMedia: any;
  profile: any;
}

const Photos = ({
  profile,
  photos,
  openLightbox,
  isOtherProfile,
  refreshAndClearErrors,
  uploadPhoto,
  isUploading,
  photoData,
  displayMessage,
  setPrivacy,
  makeDefault,
  deletePhoto,
  hasPublicPhotos,
  singlePublicLeft,
  hasPublicApprovedPhotos,
  migration,
  uploadMedia,
  deleteMedia,
  setMediaPrivacy,
  bulkTogglePrivacy,
  bulkDeleteMedia,
}: PhotosProps): any => {
  const { isMobile } = getBrowserDetails();

  const generatePhotoList = useCallback(() => {
    const photoList = [];

    photoData &&
      photoData.data.map(photo => {
        photo['order'] = parseInt(removeForbiddenCharacters(photo?.created_dt?.date));

        if (photo.id === null) {
          photo['rank'] = 2;

          return photo;
        }

        photo['rank'] = photo.private || photo.id === null ? 2 : 1;

        if (isMobile || !isOtherProfile) {
          if (photo.is_avatar) photo['rank'] = 0;
          photoList.push(photo);
        } else if (!photo.is_avatar) {
          photoList.push(photo);
        }

        return photo;
      });

    return { data: _sortBy(photoList, ['rank', 'order']) };
  }, [photoData]);

  const generateOtherProfilePhotoList = useCallback(() => {
    if (isMobile) {
      return photos;
    }

    return photos.filter(photo => !photo.is_avatar);
  }, [photos]);

  return !migration ? (
    <Gallery
      images={generateOtherProfilePhotoList()}
      openLightbox={(inx, e) => {
        const photoInx = isMobile || !isOtherProfile ? inx : inx + 1;
        openLightbox(photoInx, e);
      }}
      isOtherProfile={isOtherProfile}
      refreshAndClearErrors={refreshAndClearErrors}
      uploadPhoto={uploadPhoto}
      isUploading={isUploading}
      photos={generatePhotoList()}
      displayMessage={displayMessage}
      setPrivacy={setPrivacy}
      makeDefault={makeDefault}
      deletePhoto={deletePhoto}
      hasPublicPhotos={hasPublicPhotos}
      singlePublicLeft={singlePublicLeft}
      hasPublicApprovedPhotos={hasPublicApprovedPhotos}
      uploadMedia={uploadMedia}
      deleteMedia={deleteMedia}
      setMediaPrivacy={setMediaPrivacy}
      bulkTogglePrivacy={bulkTogglePrivacy}
      bulkDeleteMedia={bulkDeleteMedia}
      profile={profile}
    />
  ) : (
    <GalleryMigration images={photos} openLightbox={openLightbox} />
  );
};

export default Photos;
