import React, { useState, useEffect, ReactElement, Fragment } from 'react';
import styled from '@emotion/styled';
import { Slider, Switch } from 'swui';
import { ANNUAL_INCOME_OPTIONS, EDUCATION_OPTIONS, YES_NO_OPTIONS } from '../../join/constants';
import JoinCheckboxFormGroup from '../../join/JoinCheckboxFormGroup';
import JoinOptionButtonGroup from '../../join/JoinOptionButtonGroup';
import JoinRadioButtonFormGroup from '../../join/JoinRadioButtonFormGroup';
import { DashlineSeparator } from '../../join/styledElements';
import TextInput from '../../../atoms/TextInput';
import { handleProfileCompletionVysionEvent } from '../../../utils/vysion/profileCompletion';
import { VYSION_ACTIONS } from '../../../utils/vysion';
import { ProfileCompletionClickVysionEventsEnum } from '../../../utils/vysion/profileCompletion/click';
import { HEIGHTS_VALUES } from '../../../config/constants';
import { cmToFeetInches, convertCMToInchesHeight, useFilteredBodyType } from '../../../utils/helpers';
import { shouldShowHeignInFeet } from '../../../common';

const StyleSlider = styled('div')({
  marginTop: 0,
  span: {
    backgroundColor: '#D9D9D9 !important',
  },
});

const SliderValue = styled('span')({
  fontSize: 14,
  fontWeight: 600,
});

interface ICompleteProfileDetailsFormProps {
  handleFormData: any;
  formData: any;
  showRequiredProfileDetailLists: any;
  gender: any;
  country: any;
}

const SliderLabel: React.FC<{ isHeightInFt: boolean }> = ({ isHeightInFt, children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {isHeightInFt ? (
        <>
          <span className="label" style={{ fontSize: 14 }}>{`<5'`}</span>
          {children}
          <span className="label" style={{ float: 'right', fontSize: 14 }}>{`>7'`}</span>
        </>
      ) : (
        <>
          <span className="label" style={{ fontSize: 14 }}>{`< 152cm`}</span>
          {children}
          <span className="label" style={{ float: 'right', fontSize: 14 }}>{`>214cm`}</span>
        </>
      )}
    </div>
  );
};

const CompleteProfileDetailsForm = ({
  formData,
  handleFormData,
  showRequiredProfileDetailLists,
  gender,
  country,
}: ICompleteProfileDetailsFormProps): ReactElement => {
  const [height, setHeight] = useState('0');
  const [isHeightInFt, setIsHeightInFt] = useState(shouldShowHeignInFeet(country));
  const [heightInCm, setHeightInCm] = useState(151);
  const [heightInFt, setHeightInFt] = useState('5');
  const [defaultHeight, setDefaultHeight] = useState<any[]>();

  const filteredBodyType = useFilteredBodyType(gender);

  const onHeightMeasurementChange = (value: boolean) => {
    setIsHeightInFt(value);
  };

  useEffect(() => {
    if (defaultHeight) {
      calculateHeight(defaultHeight[0]);
    }
  }, [defaultHeight, isHeightInFt]);

  const calculateHeight = (value: number): void => {
    setHeightInCm(value);

    const convertHeightInFt = convertCMToInchesHeight(value);
    const validHeight = convertHeightInFt ?? '0';

    if (isHeightInFt) {
      setHeight(validHeight);
    } else {
      setHeight(value ? value.toFixed(0) : '0');
    }

    setHeightInFt(convertHeightInFt);
  };

  const getHeightFormDataValue = () => {
    if (heightInCm > 213) {
      return `> 7'0" (> 214cm)`;
    }

    if (heightInCm < 153) {
      return `< 5'0" (<152cm)`;
    }

    return HEIGHTS_VALUES[heightInFt];
  };

  useEffect(() => {
    handleFormData({ height: getHeightFormDataValue() });
  }, [heightInCm]);

  // set default height
  useEffect(() => {
    handleFormData({ height: getHeightFormDataValue() });
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>
      {showRequiredProfileDetailLists.body_type && (
        <div style={{ overflow: 'hidden' }}>
          <JoinRadioButtonFormGroup label="Body Type (select one)" isRequired>
            <JoinOptionButtonGroup
              lists={filteredBodyType}
              value={formData.body_type}
              onChangeHandler={item => {
                if (formData.body_type === item.value) {
                  return handleFormData({
                    body_type: null,
                  });
                }
                handleFormData({ body_type: item.value });
              }}
              additionalStyle={{ width: '36%', marginRight: 15 }}
            />
          </JoinRadioButtonFormGroup>
        </div>
      )}

      {showRequiredProfileDetailLists.height && (
        <div>
          <JoinCheckboxFormGroup customStyle={{ position: 'relative' }} label="Height" isRequired>
            <StyleSlider className="switchSWUI">
              <Switch onText="in" offText="cm" checked={isHeightInFt} onChange={onHeightMeasurementChange} />
            </StyleSlider>
            <StyleSlider>
              <Slider
                min={152}
                max={214}
                defaultValue={[]}
                onHandleChange={height => {
                  setDefaultHeight(height);
                }}
              />
            </StyleSlider>
            <SliderLabel isHeightInFt={isHeightInFt}>
              <SliderValue style={{ left: isHeightInFt ? '48%' : '44%' }}>
                {height === 0 ? '' : !isHeightInFt && height}
                {isHeightInFt ? cmToFeetInches(heightInCm) : 'cm'}
              </SliderValue>
            </SliderLabel>
          </JoinCheckboxFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.children && (
        <div>
          <JoinRadioButtonFormGroup label="Do You Have Any Children?" isRequired>
            <JoinOptionButtonGroup
              lists={YES_NO_OPTIONS(false, true)}
              value={formData.children}
              onChangeHandler={item => {
                if (formData.children === item.value) {
                  return handleFormData({
                    children: null,
                  });
                }
                handleFormData({ children: item.value });
              }}
            />
          </JoinRadioButtonFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.smoking && (
        <div>
          <JoinRadioButtonFormGroup label="Do You Smoke?" isRequired>
            <JoinOptionButtonGroup
              lists={YES_NO_OPTIONS(true, true)}
              value={formData.smoking}
              onChangeHandler={item => {
                if (formData.smoking === item.value) {
                  return handleFormData({
                    smoking: null,
                  });
                }
                handleFormData({ smoking: item.value });
              }}
            />
          </JoinRadioButtonFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.drinking && (
        <div>
          <JoinRadioButtonFormGroup label="Do You Drink?" isRequired>
            <JoinOptionButtonGroup
              lists={YES_NO_OPTIONS(true, true)}
              value={formData.drinking}
              onChangeHandler={item => {
                if (formData.drinking === item.value) {
                  return handleFormData({
                    drinking: null,
                  });
                }
                handleFormData({ drinking: item.value });
              }}
            />
          </JoinRadioButtonFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.education && (
        <div>
          <JoinRadioButtonFormGroup label="Education (select one)" isRequired>
            <JoinOptionButtonGroup
              lists={EDUCATION_OPTIONS}
              value={formData.education}
              onChangeHandler={item => {
                if (formData.education === item.value) {
                  return handleFormData({
                    education: null,
                  });
                }
                handleFormData({ education: item.value });
                handleProfileCompletionVysionEvent(
                  {
                    action: VYSION_ACTIONS.CLICK,
                    event: ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_EDUCATION,
                  },
                  location.pathname
                );
              }}
              additionalStyle={{ width: '36%', marginRight: 15 }}
            />
          </JoinRadioButtonFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.occupation && (
        <div>
          <JoinCheckboxFormGroup label="Occupation" isRequired>
            <TextInput
              id="occupation"
              customStyle={{ width: '100%', paddingBottom: 0 }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                handleFormData({ occupation: event.target.value });
              }}
              joinForm
            />
          </JoinCheckboxFormGroup>
          <DashlineSeparator />
        </div>
      )}

      {showRequiredProfileDetailLists.income && (
        <div>
          <JoinRadioButtonFormGroup label="Annual Income (select one)" isRequired>
            <JoinOptionButtonGroup
              lists={ANNUAL_INCOME_OPTIONS}
              value={formData.income}
              onChangeHandler={item => {
                if (formData.income === item.value) {
                  return handleFormData({
                    income: null,
                  });
                }
                handleFormData({ income: item.value });
                handleProfileCompletionVysionEvent(
                  {
                    action: VYSION_ACTIONS.CLICK,
                    event: ProfileCompletionClickVysionEventsEnum.CLICK_FINISH_PROFILE_ANNUAL_INCOME,
                  },
                  location.pathname
                );
              }}
              additionalStyle={{ width: '42%', marginRight: 15, paddingLeft: 0, paddingRight: 0 }}
            />
          </JoinRadioButtonFormGroup>
          <DashlineSeparator />
        </div>
      )}
    </div>
  );
};

export default CompleteProfileDetailsForm;
