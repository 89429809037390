import {
  CONVO_APPEND,
  CONVO_READ_ALL,
  CONVO_MARK_MESSAGE_STATUS,
  CONVO_REMOVE_MESSAGE,
  CONVO_ARCHIVING,
  CONVO_ADD_POKE,
  STORE_CONVO,
  PREPEND_CONVO,
  STORE_MESSAGE,
  REQUEST_CONVO,
  CLEAR_CONVO,
  ConvoPayloadState,
  ConvoActionTypes,
} from './types/Convo.d';

const initialState: ConvoPayloadState = {
  id: '',
  status: '',
  isFetching: false,
};

const convo = (state = initialState, action: ConvoActionTypes): any => {
  /**
   * Check if current conversation is the parent of sent message
   *
   * @param {number} thread_id
   * @returns {boolean}
   */
  const isConvoOpen = thread_id => {
    return thread_id === state.id;
  };

  /**
   * Append new received message
   *
   * @param {object} data
   */
  const appendMessage = ({ message }) => {
    const messages = state.messages;

    if (isConvoOpen(message.thread_id)) {
      messages.data.push(message);
    }

    return messages;
  };

  const markReadAllMessages = () => {
    const messages = Object.assign([], state.messages);

    if (typeof messages.data !== 'undefined') {
      messages.data.map(message => {
        message.they_read = true;
        return message;
      });
    }

    return messages;
  };

  const markMessageStatus = ({ id, status }) => {
    const messages = Object.assign([], state.messages);

    if (typeof messages.data !== 'undefined') {
      messages.data.map(message => {
        if (message.id === id) {
          message.status = status;
        }
        return message;
      });
    }

    return messages;
  };

  const removeMessage = id => {
    const messages = Object.assign([], state.messages);

    if (typeof messages.data !== 'undefined') {
      messages.data = messages.data.filter(message => message.id !== id);
    }

    return messages;
  };

  switch (action.type) {
    case STORE_CONVO:
      return {
        ...action.payload,
        isFetching: false,
      };
    case PREPEND_CONVO: {
      const { payload } = action;
      return {
        ...state,
        messages: {
          data: payload.messages.data.concat(state.messages.data),
        },
        isFetching: false,
      };
    }
    case STORE_MESSAGE: {
      // add message to current convo.
      const newState = Object.assign({}, state);
      newState.messages.data.push(action.payload);
      return newState;
    }
    case REQUEST_CONVO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CLEAR_CONVO: {
      delete state.messages;
      return {
        ...state,
        isFetching: false,
      };
    }
    case CONVO_APPEND:
      return {
        ...state,
        messages: appendMessage(action.payload),
      };
    case CONVO_READ_ALL:
      return {
        ...state,
        messages: markReadAllMessages(),
      };
    case CONVO_MARK_MESSAGE_STATUS:
      return {
        ...state,
        messages: markMessageStatus(action.payload),
      };
    case CONVO_REMOVE_MESSAGE:
      return {
        ...state,
        messages: removeMessage(action.payload.id),
      };
    case CONVO_ARCHIVING:
      return {
        ...state,
        archiving: action.payload.status,
      };
    case CONVO_ADD_POKE: {
      const pokes = (state.pokes && state.pokes.data) || [];
      pokes.push(action.payload.data);
      return {
        ...state,
        pokes: {
          data: pokes,
        },
      };
    }
    default:
      return state;
  }
};

export default convo;
