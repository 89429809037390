import { Account, AccountMaker } from '../models/Account';
import { getBrowserDetails } from '../utils/helpers';
import { getLocalItem, setLocalItem } from '../common';
import { isBefore, isEqual, format, subDays, isAfter, differenceInDays } from 'date-fns';
import store from '../store';
import { mixpanelTrackPWAInstalled } from './mixpanel/pwaInstalled';

export type PWAInstallationPromptReturnObject = {
  platform: 'ios' | 'android';
  show: boolean;
  source?: 'Daily' | 'Weekly' | 'Registration' | 'Message';
};
export const PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY =
  'wyp_pwa_global_installation_prompt_last_seen_at';

export const PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT = 'wyp_pwa_global_installation_prompt_shown_count';

export const PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY =
  'wyp_pwa_message_page_installation_prompt_last_seen_at';

const deviceName = getBrowserDetails().deviceName();
const platform = getBrowserDetails().isIos ? 'ios' : deviceName === 'Android' ? 'android' : deviceName;
const isValidPlatform = ['android', 'ios'].includes(platform);

const shouldShowByPlatform = (account: Account) => {
  const { isChrome, isSafari, showAndroidInstall, showInstall, showInstallIosChrome } = getBrowserDetails();

  if (!isValidPlatform) {
    return false;
  }

  if (platform === 'ios' && isSafari) {
    return showInstall && !account.data.account_config.is_account_installed_pwa_in_ios;
  }

  if (platform === 'ios' && isChrome) {
    return showInstallIosChrome && !account.data.account_config.is_account_installed_pwa_in_ios;
  }

  if (platform === 'android') {
    return showAndroidInstall;
  }

  return false;
};

export const trackPWaMixpanelEventInstalled = (source: string): void => {
  const accountModel = AccountMaker.create(store.getState().profile);
  const accountLocale = accountModel.data.profile.data.created_at.timezone;
  const accountCreatedAt = format(accountModel.data.profile.data.created_at.date, 'YYYY-MM-DD', {
    locale: accountLocale,
  });
  const _now = format(new Date(), 'YYYY-MM-DD', { locale: accountLocale });
  const daysAfterRegistration = differenceInDays(_now, accountCreatedAt);

  mixpanelTrackPWAInstalled({
    Source: source,
    'Days after registration': daysAfterRegistration > 0 ? daysAfterRegistration : 0,
  });
};

/**
 * Show PWA installation prompt
 * if account has not installed PWA prompt
 *
 *  If account is in 7 days from registration, show installation prompt daily
 * else;
 * Show account installation prompt weekly
 *
 * @param account Account
 * @returns Promise<{ platform: 'ios' | 'android'; show: boolean; }>
 */
export async function showPWAInstallationGlobalBanner(account: Account): Promise<PWAInstallationPromptReturnObject> {
  if (!shouldShowByPlatform(account)) {
    return {
      platform,
      show: false,
    };
  }

  const accountLocale = account.data?.profile?.data?.created_at?.timezone || '';
  const accountCreatedAt = format(account.data.profile.data.created_at.date, 'YYYY-MM-DD', {
    locale: accountLocale,
  });
  const _now = format(new Date(), 'YYYY-MM-DD', { locale: accountLocale });
  const subtracted7DaysDate = format(subDays(_now, 7), 'YYYY-MM-DD', { locale: accountLocale });
  const installationPromptLastSeenAt = getLocalItem(PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY);
  const hasSeenToday = isEqual(installationPromptLastSeenAt, _now);
  const pwaShownCountFromStorage = getLocalItem(PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT);
  const shownCount =
    typeof pwaShownCountFromStorage === 'undefined' || pwaShownCountFromStorage === null
      ? 0
      : parseInt(pwaShownCountFromStorage);

  if (hasSeenToday) {
    return {
      platform,
      show: false,
    };
  }

  /**
   * User haven't shown an installation prompt
   * And the registration date is over 7 days from current date
   */
  if (!installationPromptLastSeenAt) {
    let source: PWAInstallationPromptReturnObject['source'] = 'Daily';

    // Source is Weekly
    // because account created date
    // is >= 7 days
    if (differenceInDays(_now, accountCreatedAt) >= 7) {
      source = 'Weekly';
    }

    setLocalItem(PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT, shownCount + 1);

    return {
      source,
      platform,
      show: true,
    };
  }

  /**
   * User is in 7 days after registration from current date
   * and haven't shown installation prompt with current date
   */
  if (
    (isBefore(subtracted7DaysDate, accountCreatedAt) || isEqual(subtracted7DaysDate, accountCreatedAt)) &&
    !hasSeenToday
  ) {
    setLocalItem(PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT, shownCount + 1);

    return {
      platform,
      show: true,
      source: 'Daily',
    };
  }

  /**
   * User registration has over 7 days from current date
   * And last seen was 7 days ago
   * And has haven't shown installation prompt
   */
  if (
    (isAfter(subtracted7DaysDate, installationPromptLastSeenAt) ||
      isEqual(subtracted7DaysDate, installationPromptLastSeenAt)) &&
    !hasSeenToday
  ) {
    setLocalItem(PWA_GLOBAL_INSTALLATION_PROMPT_SHOWN_COUNT, shownCount + 1);

    return {
      platform,
      show: true,
      source: 'Weekly',
    };
  }

  return {
    platform,
    show: false,
  };
}

/**
 * Show PWA installation prompt in message page weekly
 *
 * @param account Account
 * @returns Promise<{ platform: 'ios' | 'android'; show: boolean; }>
 */
export async function showPWAInstallationMessagePageBanner(
  account: Account
): Promise<PWAInstallationPromptReturnObject> {
  if (!shouldShowByPlatform(account)) {
    return {
      platform,
      show: false,
    };
  }

  const accountLocale = account.data.profile.data.created_at.timezone;
  const _now = format(new Date(), 'YYYY-MM-DD', { locale: accountLocale });
  const subtracted7DaysDate = format(subDays(_now, 7), 'YYYY-MM-DD', { locale: accountLocale });
  const installationPromptLastSeenAt = getLocalItem(
    PWA_MESSAGE_PAGE_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY
  );
  const hasSeenToday = isEqual(installationPromptLastSeenAt, _now);

  if (hasSeenToday) {
    return {
      platform,
      show: false,
    };
  }

  /**
   * User haven't shown an installation prompt
   * And the registration date is over 7 days from current date
   */
  if (!installationPromptLastSeenAt) {
    return {
      platform,
      show: true,
      source: 'Message',
    };
  }

  /**
   * User registration has over 7 days from current date
   * And last seen was 7 days ago
   * And has haven't shown installation prompt
   */
  if (
    (isAfter(subtracted7DaysDate, installationPromptLastSeenAt) ||
      isEqual(subtracted7DaysDate, installationPromptLastSeenAt)) &&
    !hasSeenToday
  ) {
    return {
      platform,
      show: true,
      source: 'Weekly',
    };
  }

  return {
    platform,
    show: false,
  };
}
