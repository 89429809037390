import { VYSION_ACTIONS } from '..';
import { EventData, handleVysionEvent } from '../vysionService';
import { loginClickVysionEvents } from './click';
import { loginOnFocusVysionEvents } from './onFocus';
import { loginViewVysionEvents } from './view';

export const events = {
  [VYSION_ACTIONS.ON_FOCUS]: { ...loginOnFocusVysionEvents },
  [VYSION_ACTIONS.CLICK]: { ...loginClickVysionEvents },
  [VYSION_ACTIONS.VIEW]: { ...loginViewVysionEvents },
};

export const handleLoginVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
