import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme } from '../../style';
import { getBrowserDetails } from '../../utils/helpers';
import BackPackageIcon from '../../atoms/icons/BackPackageIcon';

const { isMobile } = getBrowserDetails();

const HeaderContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  padding: isMobile ? '20px 0' : '40px 0',
});

const LeftContent = styled('div')({
  position: 'fixed',
  top: 60,
  left: 10,
  width: 'auto',
  height: 'auto',
  zIndex: 996,
});

const RightContent = styled('div')({
  alignItems: 'flex-end',
  textAlign: 'center',
  flex: 10,
});

const TopTitle = styled('div')({
  color: backgroundTheme.billingHeading,
  fontSize: 26,
  fontWeight: 600,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
});

const StyledSpan = styled('span')({
  margin: 'auto',
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: '-0.18px',
});

interface BillingPackageHeaderProps {
  label: string;
  onBackClick?: any;
}

const BillingPackageHeader = ({ label = '', onBackClick }: BillingPackageHeaderProps): ReactElement => {
  return (
    <HeaderContainer>
      {onBackClick && (
        <LeftContent>
          <BackPackageIcon onClick={onBackClick} data-test-id="billing-header-back" />
        </LeftContent>
      )}
      <RightContent>
        <TopTitle data-test-id="billing-header-label">
          <StyledSpan>{label}</StyledSpan>
        </TopTitle>
      </RightContent>
    </HeaderContainer>
  );
};

export default BillingPackageHeader;
