export const PUSHER = {
  EVENTS: {
    ACCOUNT_ALERTS: 'AccountAlerts',
    ACCOUNT_ALERT_MESSAGE: 'AccountAlertMessage',
    ACCOUNT_TYPING_INDICATOR: 'AccountMessageTypingIndicator',
    ACCOUNT_MESSAGE_STATUS: 'AccountAlertUpdateMessageStatus',
  },
};

export const NOTIF_TIMEOUT = process.env.REACT_APP_ENV === 'production' ? 3500 : 16000;
export const TYPING_TIMEOUT = process.env.REACT_APP_TYPING_INDICATOR_TIMEOUT
  ? parseInt(process.env.REACT_APP_TYPING_INDICATOR_TIMEOUT)
  : 1000;

export const HEIGHTS_VALUES = {
  '5.0': `5'0" (152cm)`,
  '5.1': `5'1" (155cm)`,
  '5.2': `5'2" (157cm)`,
  '5.3': `5'3" (160cm)`,
  '5.4': `5'4" (163cm)`,
  '5.5': `5'5" (165cm)`,
  '5.6': `5'6" (168cm)`,
  '5.7': `5'7" (170cm)`,
  '5.8': `5'8" (173cm)`,
  '5.9': `5'9" (175cm)`,
  '5.10': `5'10" (178cm)`,
  '5.11': `5'11" (180cm)`,
  '6.0': `6'0" (183cm)`,
  '6.1': `6'1" (185cm)`,
  '6.2': `6'2" (188cm)`,
  '6.3': `6'3" (191cm)`,
  '6.4': `6'4" (193cm)`,
  '6.5': `6'5" (196cm)`,
  '6.6': `6'6" (198cm)`,
  '6.7': `6'7" (201cm)`,
  '6.8': `6'8" (203cm)`,
  '6.9': `6'9" (206cm)`,
  '6.10': `6'10" (208cm)`,
  '6.11': `6'11" (211cm)`,
  '7.0': `7'0" (214cm)`,
};

export const countriesEU = [
  {
    name: 'Andorra',
    code: 'AD',
    tax: false,
  },
  {
    name: 'Albania',
    code: 'AL',
    tax: false,
  },
  {
    name: 'Austria',
    code: 'AT',
    tax: true,
  },
  {
    name: 'Åland Islands',
    code: 'AX',
    tax: false,
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    tax: false,
  },
  {
    name: 'Belgium',
    code: 'BE',
    tax: true,
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    tax: true,
  },
  {
    name: 'Belarus',
    code: 'BY',
    tax: false,
  },
  {
    name: 'Switzerland',
    code: 'CH',
    tax: false,
  },
  {
    name: 'Cyprus',
    code: 'CY',
    tax: true,
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    tax: true,
  },
  {
    name: 'Germany',
    code: 'DE',
    tax: true,
  },
  {
    name: 'Denmark',
    code: 'DK',
    tax: true,
  },
  {
    name: 'Estonia',
    code: 'EE',
    tax: true,
  },
  {
    name: 'Spain',
    code: 'ES',
    tax: true,
  },
  {
    name: 'Finland',
    code: 'FI',
    tax: true,
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    tax: false,
  },
  {
    name: 'France',
    code: 'FR',
    tax: true,
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    tax: true,
  },
  {
    name: 'Guernsey',
    code: 'GG',
    tax: false,
  },
  {
    name: 'Greece',
    code: 'GR',
    tax: true,
  },
  {
    name: 'Croatia',
    code: 'HR',
    tax: true,
  },
  {
    name: 'Hungary',
    code: 'HU',
    tax: true,
  },
  {
    name: 'Ireland',
    code: 'IE',
    tax: true,
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    tax: false,
  },
  {
    name: 'Iceland',
    code: 'IC',
    tax: false,
  },
  {
    name: 'Italy',
    code: 'IT',
    tax: true,
  },
  {
    name: 'Jersey',
    code: 'JE',
    tax: false,
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    tax: false,
  },
  {
    name: 'Lithuania',
    code: 'LT',
    tax: true,
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    tax: true,
  },
  {
    name: 'Latvia',
    code: 'LV',
    tax: true,
  },
  {
    name: 'Monaco',
    code: 'MC',
    tax: false,
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
    tax: false,
  },
  {
    name: 'Moldova',
    code: 'MD',
    tax: false,
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    tax: false,
  },
  {
    name: 'Macedonia',
    code: 'MK',
    tax: false,
  },
  {
    name: 'Malta',
    code: 'MT',
    tax: true,
  },
  {
    name: 'Netherlands',
    code: 'NL',
    tax: true,
  },
  {
    name: 'Norway',
    code: 'NO',
    tax: false,
  },
  {
    name: 'Poland',
    code: 'PL',
    tax: true,
  },
  {
    name: 'Portugal',
    code: 'PT',
    tax: true,
  },
  {
    name: 'Romania',
    code: 'RO',
    tax: true,
  },
  {
    name: 'Russian Federation',
    code: 'RU',
    tax: false,
  },
  {
    name: 'Russia',
    code: 'RU',
    tax: false,
  },
  {
    name: 'Sweden',
    code: 'SE',
    tax: true,
  },
  {
    name: 'Slovenia',
    code: 'SI',
    tax: true,
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    tax: false,
  },
  {
    name: 'Slovakia',
    code: 'SK',
    tax: true,
  },
  {
    name: 'San Marino',
    code: 'SM',
    tax: false,
  },
  {
    name: 'Ukraine',
    code: 'UA',
    tax: false,
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    tax: false,
  },
  {
    name: 'Holy See',
    code: 'VA',
    tax: false,
  },
  {
    name: 'Montenegro',
    code: 'ME',
    tax: false,
  },
  {
    name: 'Serbia',
    code: 'RS',
    tax: false,
  },
];

export const WEB_TIMESTAMP = process.env.REACT_APP_WEB_TIMESTAMP;

export const IS_REFRESH_TOKEN_COOKIE = 'is_refresh_token';

export const PROMO_COOKIE = 'PROMOREF';
export const PROMO_COOKIE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // Miliseconds expiration age

export const IPQS_VALUES = {
  DEVICE_ID: 'IPQS_device_id',
  SSL_FINGERPRINT: 'IPQS_ssl_fingerprint',
  REQUEST_ID: 'IPQS_request_id',
};

export const EMAIL_ALLOWED_DOMAINS = ['@gmail.com', '@hotmail.com', '@icloud.com', '@outlook.com', '@yahoo.com'];

const THEME_COOKIE_NAME = process.env.REACT_APP_NAME === 'fd' ? `fd_allow_theme` : `wyp_allow_theme`;
export const ALLOW_THEME_COOKIE_NAME = `${THEME_COOKIE_NAME}${
  ['production', 'prodfd'].includes(process.env.REACT_APP_ENV || '') ? '' : '_' + process.env.REACT_APP_ENV
}`;

export const H_CAPTCHA_SITEKEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY || '10000000-ffff-ffff-ffff-000000000001';
