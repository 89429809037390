import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { isEU } from '../../utils/helpers';
import { s3BucketDirect } from '../../config/Master';

const PaymentImages = styled('img')({
  padding: 3,
  display: 'block',
  width: 60,
});

const PaymentImageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  paddingTop: '1em',
});

interface BillingPaymentProcessorProps {
  userCountry: any;
}

const BillingPaymentProcessor = ({ userCountry }: BillingPaymentProcessorProps): ReactElement | null => {
  if (isEU(userCountry)) {
    return (
      <PaymentImageContainer data-test-id="payment-processor">
        <PaymentImages
          src={`${s3BucketDirect}payment/MasterCardSecureCode.svg`}
          alt="MasterCardSecureCode"
          height="25"
        />
        <PaymentImages src={`${s3BucketDirect}payment/VerifiedByVisa.svg`} alt="VerifiedByVisa" height="25" />
      </PaymentImageContainer>
    );
  }
  return null;
};

export default BillingPaymentProcessor;
