/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { backgroundTheme, dimensions } from '../../../style';
import { commonIcons } from '../../../atoms/icons/materialIcons';
import BackPackageIcon from '../../../atoms/icons/BackPackageIcon';

const HeaderContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  padding: '43px 0',
  [dimensions.SCREEN_MAX_MD]: {
    padding: '20px 0 10px 0',
  },
});

const LeftContent = styled('div')({
  position: 'fixed',
  top: 60,
  left: 10,
  width: 'auto',
  height: 'auto',
  zIndex: 996,
});

const RightContent = styled('div')({
  alignItems: 'flex-end',
  textAlign: 'center',
  flex: 10,
});

const TopTitle = styled('div')({
  color: backgroundTheme.billingHeading,
  fontSize: 36,
  fontWeight: 600,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  [dimensions.SCREEN_MAX_MD]: {
    fontSize: 20,
  },
});

interface BillingHeaderProps {
  label: string;
  onBackClick: any;
  isSuccess: boolean;
}

const BillingHeader = ({ label = '', onBackClick, isSuccess = false }: BillingHeaderProps): ReactElement => {
  return (
    <HeaderContainer>
      {onBackClick && (
        <LeftContent>
          <BackPackageIcon onClick={onBackClick} data-test-id="billing-header-back" />
        </LeftContent>
      )}
      <RightContent>
        <TopTitle data-test-id="billing-header-label">
          {isSuccess && <commonIcons.check.default />}{' '}
          <span style={isSuccess ? { marginRight: 'auto' } : { margin: 'auto', fontWeight: 700 }}>{label}</span>
        </TopTitle>
      </RightContent>
    </HeaderContainer>
  );
};

export default BillingHeader;
