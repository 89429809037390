import { VYSION_ACTIONS } from '..';
import { EventData, handleVysionEvent } from '../vysionService';
import { deactivateAccountClickVysionEvents } from './click';
import { deactivateAccountViewVysionEvents } from './view';

export const events = {
  [VYSION_ACTIONS.CLICK]: { ...deactivateAccountClickVysionEvents },
  [VYSION_ACTIONS.VIEW]: { ...deactivateAccountViewVysionEvents },
};

export const handleDeactivateAccountVysionEvent = (data: EventData): void => {
  handleVysionEvent<typeof events>(data, events);
};
