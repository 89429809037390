import React, { ReactElement } from 'react';

interface SofortIconProps {
  isDarkMode: boolean;
  height?: any;
  width?: any;
  viewBox?: any;
}

const SofortIcon = ({
  isDarkMode = false,
  height = 30,
  width = 81,
  viewBox = '0 0 81 30',
}: SofortIconProps): ReactElement => {
  const bgColor = isDarkMode ? '#FFFFFF' : '#393A41';
  const textColor = isDarkMode ? '#393A41' : '#EDEDED';

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1557_3468)">
        <path d="M75.9453 30H0L5.05467 0H81L75.9453 30Z" fill={bgColor} />
        <path
          d="M24.9865 10C21.5748 10 18.9847 12.4868 18.9847 15.4497C18.9847 18.1305 21.1104 20 24.0041 20C27.4158 20 29.988 17.4427 29.988 14.4797C30.0058 11.7813 27.8802 10 24.9865 10ZM24.2184 17.478C22.9145 17.478 22.0035 16.6314 22.0035 15.3263C22.0035 13.8801 23.1288 12.5044 24.7543 12.5044C26.0583 12.5044 26.9692 13.3862 26.9692 14.6914C26.9871 16.1552 25.8439 17.478 24.2184 17.478Z"
          fill={textColor}
        />
        <path
          d="M45.5105 10C42.0988 10 39.5267 12.4868 39.5267 15.4497C39.5267 18.1305 41.6523 20 44.546 20C47.9574 20 50.5298 17.4427 50.5298 14.4797C50.5477 11.7813 48.4222 10 45.5105 10ZM44.7603 17.478C43.4562 17.478 42.5453 16.6314 42.5453 15.3263C42.5453 13.8801 43.6705 12.5044 45.2962 12.5044C46.6 12.5044 47.5109 13.3862 47.5109 14.6914C47.5109 16.1552 46.3857 17.478 44.7603 17.478Z"
          fill={textColor}
        />
        <path
          d="M60.5684 13.1572C60.5684 11.3583 59.1574 10.2119 56.5853 10.2119H52.977L51.3871 19.7886H54.4239L54.9237 16.7728H55.0668L56.6744 19.7886H60.1577L57.9248 16.4377C59.586 15.8733 60.5684 14.6564 60.5684 13.1572ZM55.7098 14.7445H55.2633L55.6028 12.734H56.0135C56.9602 12.734 57.4604 13.0161 57.4604 13.6334C57.4604 14.3565 56.7459 14.7445 55.7098 14.7445Z"
          fill={textColor}
        />
        <path
          d="M15.5016 14.0388C14.3405 13.4568 14.0905 13.3157 14.0905 12.9982C14.0905 12.6102 14.5549 12.4162 15.18 12.4162C15.9124 12.4162 17.0199 12.5044 18.1095 13.5273C18.3774 12.6984 18.824 11.9577 19.3956 11.3051C17.8594 10.4409 16.4304 10 15.1265 10C12.5364 10 11.036 11.3757 11.036 13.0335C11.036 14.4621 12.1256 15.2028 13.4117 15.8554C14.5727 16.4374 14.8585 16.6314 14.8585 16.9841C14.8585 17.3721 14.3763 17.5838 13.7332 17.5838C12.6257 17.5838 11.3396 16.8959 10.5715 16.1905L10.125 18.8536C10.9109 19.418 12.0542 20 13.7868 20C16.4662 20 17.913 18.6596 17.913 16.9489C17.913 15.5379 16.9841 14.7795 15.5016 14.0388Z"
          fill={textColor}
        />
        <path
          d="M38.0083 14.4094H34.4358L34.7217 12.734H38.5442C39.0444 11.7287 39.8122 10.8645 40.7589 10.2119H34.507C33.1139 10.2119 31.935 11.1467 31.7385 12.4165L30.5239 19.8063H33.5605L34.0605 16.7904H37.6687L37.9367 15.1502C37.9546 14.8856 37.9725 14.6564 38.0083 14.4094Z"
          fill={textColor}
        />
        <path
          d="M60.64 10.2119C61.4614 10.8292 61.9616 11.6934 62.0689 12.734H64.6052L63.4442 19.7886H66.4807L67.6417 12.734H70.4642L70.875 10.2119H60.64Z"
          fill={textColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1557_3468">
          <rect width="81" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SofortIcon;
