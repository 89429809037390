import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _pickBy from 'lodash/pickBy';
import ChangeEmailAndPasswordForm from '../../../modules/ChangeEmailAndPasswordForm';
import CreateEmailAndPasswordForm from '../../../modules/CreateEmailAndPasswordForm';
import { connect } from 'react-redux';
import LoadingBackdrop from '../../../atoms/loader/LoadingBackdrop';
import Headline from '../../common/Headline';
import Header from '../../blocks/Header';
import Container from '../../blocks/Container';
import styled from '@emotion/styled';
import { backgroundTheme, borders, dimensions } from '../../../style';

interface EmailPasswordProps {
  errors: any;
  needsPassword: any;
  settings: any;
  profile: any;
  validateCredentials: any;
  addAccountEmailPassword: any;
  updateAccountEmailPassword: any;
  settingsState: any;
}

const CustomContainer = styled(Container)({
  position: 'relative',
  marginTop: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: backgroundTheme.personalInfo,
  borderRadius: 8,
  border: borders.DARKER,
  [dimensions.SCREEN_MAX_MD]: {
    margin: '0 auto',
    width: '95%',
    minHeight: '80vh',
    alignItems: 'self-start',
  },
});

const CustomHeader = styled(Header)({});

const Content = styled.div({
  minWidth: 460,
  padding: '20px 0',
  [dimensions.SCREEN_MAX_MD]: {
    minWidth: '85vw',
  },
});

const EmailPassword = ({
  errors,
  needsPassword,
  settings,
  profile,
  validateCredentials,
  addAccountEmailPassword,
  updateAccountEmailPassword,
  settingsState,
}: EmailPasswordProps): any => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [accountEmail, setAccountEmail] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const enableSubmit = (needsPassword && accountEmail && accountPassword) || (currentPassword && (email || password));

  useEffect(() => {
    initGoogleScript();
  }, []);

  const initGoogleScript = () => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_GOOGLE_JS;
    script.defer = true;
    script.onload = () => window.gapi && window.gapi.load('auth2');
    document.body.appendChild(script);
  };

  const onInputChange = ({ target }, hasError = false) => {
    setDisableSubmit(hasError);

    switch (target.id) {
      case 'email':
      case 'new_email':
        needsPassword ? setAccountEmail(target.value) : setEmail(target.value);
        break;
      case 'password':
        needsPassword ? setAccountPassword(target.value) : setPassword(target.value);
        break;
      case 'current_password':
        setCurrentPassword(target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmitPassword = () => {
    if (needsPassword) {
      validateCreds();
      return;
    }
    updateAccountDetails();
  };

  const validateCreds = () => {
    const account = {
      account_email: accountEmail,
      account_password: accountPassword,
    };
    validateCredentials(() => {
      addAccountEmailPassword(account);
    });
  };

  const updateAccountDetails = () => {
    const accounts = {
      email,
      password,
      current_password: currentPassword,
      account_email: accountEmail,
      account_password: accountPassword,
    };
    updateAccountEmailPassword(_pickBy(accounts));
  };

  return (
    <div>
      {settingsState?.email_and_password?.isUpdating && <LoadingBackdrop active={true} showLoadingBg={true} />}
      {needsPassword ? (
        <CustomContainer fullWidth>
          <Content>
            <CustomHeader bottomRuled>
              <Headline>Create Email and Password</Headline>
            </CustomHeader>
            <CreateEmailAndPasswordForm
              currentEmail={settings.email}
              newEmail={accountEmail || email}
              newPassword={accountPassword || password}
              onChange={onInputChange}
              onSubmit={handleSubmitPassword}
              disableSubmit={disableSubmit || !enableSubmit}
              errors={errors}
            />
          </Content>
        </CustomContainer>
      ) : (
        <CustomContainer fullWidth>
          <Content>
            <CustomHeader bottomRuled>
              <Headline>Change Email and Password</Headline>
            </CustomHeader>
            <ChangeEmailAndPasswordForm
              hashId={profile.hash_id}
              currentEmail={settings.email}
              currentPassword={currentPassword}
              newEmail={accountEmail || email}
              newPassword={accountPassword || password}
              onChange={onInputChange}
              onSubmit={handleSubmitPassword}
              disableSubmit={disableSubmit || !enableSubmit}
              errors={errors}
            />
          </Content>
        </CustomContainer>
      )}
    </div>
  );
};

EmailPassword.propTypes = {
  addAccountEmailPassword: PropTypes.func.isRequired,
  needsPassword: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    settingsState: state.settings,
  };
};

export default connect(mapStateToProps, {})(EmailPassword);
