import { unlinkEmailToAccount } from '../sdk';
import { killLoading } from './commonActions';

export const storeUserToUnlink = (payload: any): any => {
  return {
    type: 'STORE_ACCOUNT_UNLINK',
    payload,
  };
};

export const userHasBeenUnlinked = (): any => {
  return {
    type: 'ACCOUNT_HAS_BEEN_UNLINKED',
  };
};

export const unlinkAccount = (payload: any): any => {
  return dispatch => {
    unlinkEmailToAccount(payload).then(
      () => {
        dispatch(killLoading());
        dispatch(userHasBeenUnlinked());
      },
      () => {
        dispatch(killLoading());
      }
    );
  };
};
