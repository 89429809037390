import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { textColor } from '../../style';

import GalleryModal from './GalleryModal';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import PrimaryButton from '../../atoms/buttons/PrimaryButton';

const ModalHeader = styled('h3')(() => ({
  marginTop: 20,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '32px',
  textAlign: 'left',
  paddingRight: 30,
  marginBottom: 10,
  ...textColor.DARKEST,
}));

const ModalDescription = styled('div')(
  {
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxWidth: 400,
    ...textColor.DARK,
  },
  ({ hasHeader }) => !hasHeader && { marginTop: 0 }
);

const AgreementList = styled('ul')({
  padding: 0,
  margin: 0,
  width: '100%',
  paddingLeft: 20,
  marginBottom: 30,
  maxWidth: 290,
});

const StyleList = styled('li')({
  padding: '5px 0',
});

const UploadMediaAgreementModal = (props: any): ReactElement => {
  const { onClose, onConfirm, ...rest } = props;
  useDisableBodyScroll(props.isOpen);

  return (
    <GalleryModal
      onClose={onClose}
      customStyle={{ content: { maxWidth: 380, left: 0, right: 0 } }}
      {...rest}
      contentLabel=""
      customContentStyle={{ padding: 30 }}
      customCloseBtnStyle={{ top: 17, right: 17 }}
    >
      <ModalDescription hasHeader={true} data-test-id="gallery-agreement">
        <ModalHeader>Any content that contains the following is prohibited:</ModalHeader>
        <AgreementList>
          <StyleList>Commercial Activity</StyleList>
          <StyleList>Escorting/Prostitution</StyleList>
          <StyleList>Scamming/Phishing/Spamming</StyleList>
          <StyleList>Sexually explicit/nudity</StyleList>
          <StyleList>Underage People</StyleList>
          <StyleList>Personal Contact information</StyleList>
          <StyleList>Criminal Activity</StyleList>
        </AgreementList>

        <PrimaryButton
          customStyle={{ width: 'inherit' }}
          data-test-id={`gallery-upload-agree`}
          onClick={() => {
            onConfirm(false);
          }}
          label="Agree and continue"
        />
      </ModalDescription>
    </GalleryModal>
  );
};

UploadMediaAgreementModal.defaultProps = {
  header: 'Upload Gallery Content',
  onClose: () => null,
};

UploadMediaAgreementModal.propTypes = {
  header: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  closeOnOverlayClick: PropTypes.bool,
};

export default UploadMediaAgreementModal;
