import React, { useState } from 'react';
import styled from '@emotion/styled';
import FavoriteButtonV2 from '../atoms/buttons/FavoriteButtonV2';
import { dimensions, textType } from '../style';
import { commonIcons } from '../atoms/icons/materialIcons';

const Details = styled('div')({
  width: '100%',
  [dimensions.SCREEN_MAX_XS_2]: {
    padding: '0 15px',
  },
});

const Username = styled('h1')({
  margin: 0,
  paddingBottom: '4px',
  ...textType.header,
  textTransform: 'none',
  [dimensions.SCREEN_MAX_XS_2]: {
    color: '#fff',
    fontSize: 18,
    letterSpacing: -0.54,
    fontWeight: 600,
    lineHeight: 'normal',
    paddingBottom: 3,
  },
});

const Detail = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: 420,
  whiteSpace: 'nowrap',
  [dimensions.SCREEN_MAX_XS_2]: {
    color: '#CFCFCF',
    lineHeight: 'normal',
    fontSize: 15,
    fontWeight: 400,
    letterSpacing: -0.45,
    paddingBottom: 3,
    width: '100%',
    whiteSpace: 'unset',
    overflow: 'unset',
  },
});

const DetailLastActive = styled('p')({
  lineHeight: '20px',
  margin: 0,
  ...textType.text,
  ...textType.caption,
  [dimensions.SCREEN_MAX_XS_2]: {
    lineHeight: 'normal',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.36,
    color: '#AFB0B1',
  },
});

const StyledFavoriteButton = styled(FavoriteButtonV2)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    [dimensions.SCREEN_MAX_XS_2]: {
      height: '28.21px',
    },
  },
  '+ label': {
    marginTop: 0,
    display: 'none',
  },
});

const FavButtonWrapper = styled('div')({
  [dimensions.SCREEN_MAX_XS_2]: {
    paddingRight: 15,
  },
});

const OtherProfileCoverInfo = ({
  username,
  auth10tixVerification,
  isVerified,
  age,
  location,
  lastLoginTime,
  lastLoginLocation,
  favorited,
  onClickFavorite,
  shouldShowFavoriteButton,
}: any): any => {
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <>
      <Details>
        <Username data-test-id="profile-username-info">
          {username}
          {auth10tixVerification && isVerified && (
            <span
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              style={{ marginLeft: 1 }}
            >
              <commonIcons.check.badge showToolTip={showToolTip} />{' '}
            </span>
          )}
        </Username>
        <Detail data-test-id="profile-user-details">{`${age} • ${location}`}</Detail>
        <DetailLastActive data-test-id="profile-last-log">
          {lastLoginTime} {lastLoginLocation && `in ${lastLoginLocation}`}
        </DetailLastActive>
      </Details>
      {shouldShowFavoriteButton && (
        <FavButtonWrapper>
          <StyledFavoriteButton
            data-test-id="profile-favorite-button"
            favorited={favorited}
            onClick={onClickFavorite}
          />
        </FavButtonWrapper>
      )}
    </>
  );
};

export default OtherProfileCoverInfo;
