import React, { ReactElement } from 'react';

interface Props {
  customStyle?: React.CSSProperties;
  fill?: string;
}
const IconCreditsCircle = ({ fill = 'none', customStyle, ...rest }: Props): ReactElement => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
      {...rest}
    >
      <path
        d="M6.45167 10.5H7.61833V7.63H10.5V6.46335H7.61833V3.5H6.45167V6.46335H3.5V7.63H6.45167V10.5ZM7.0031 14C6.03684 14 5.12879 13.8162 4.27894 13.4487C3.4291 13.0812 2.68722 12.5806 2.05333 11.9467C1.41944 11.3128 0.91875 10.5706 0.55125 9.7202C0.18375 8.86977 0 7.96109 0 6.99417C0 6.02724 0.18375 5.11856 0.55125 4.26815C0.91875 3.41772 1.41944 2.6775 2.05333 2.0475C2.68722 1.4175 3.42938 0.91875 4.2798 0.55125C5.13023 0.18375 6.03891 0 7.00583 0C7.97276 0 8.88144 0.18375 9.73186 0.55125C10.5823 0.91875 11.3225 1.4175 11.9525 2.0475C12.5825 2.6775 13.0812 3.41833 13.4487 4.27C13.8162 5.12167 14 6.03063 14 6.9969C14 7.96316 13.8162 8.87121 13.4487 9.72106C13.0812 10.5709 12.5825 11.3121 11.9525 11.9445C11.3225 12.577 10.5817 13.0777 9.73 13.4466C8.87833 13.8155 7.96937 14 7.0031 14Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCreditsCircle;
