import * as sdk from '../sdk';
import { displayFailMessage } from './notificationActions';
import { toaster } from '../common';
import {
  pendingUpload,
  endPendingUpload,
  refreshProfile,
  refreshAccount,
  storeNewPrivacy,
  setGalleryLoading,
  killGalleryLoading,
  UploadMediaFormDataType,
  getPhotoUploadResponse,
} from './profileActions';
import store, { AppDispatch, RootState } from '../store';
import MasterConfig from '../config/Master';
import * as Sentry from '@sentry/react';
import {
  hideUploadSnackbar,
  incrementUploadSnackbar,
  updateUploadStatusSnackbar,
  updateUploadUploadTypeSnackbar,
} from './uploadSnackbarActions';
import { cropImage, generateImageBlob } from '../modules/gallery/GalleryUpload/Photo/helpers';
import { IUploadSnackbarItem, UploadSnackbarType } from '../reducers/types/uploadSnackbar';
import { MAX_RETRY } from '../reducers/uploadSnackbar';

export const galleryBulkUploadMedia = (mediaType: UploadSnackbarType) => {
  return async (dispatch: any, getState: any): Promise<void> => {
    const { uploadSnackbar } = getState();
    if (typeof uploadSnackbar === 'undefined') {
      return;
    }

    dispatch(updateUploadUploadTypeSnackbar(mediaType));
    const freshItems = uploadSnackbar.items.filter((item: IUploadSnackbarItem) => item.status === '');

    const uploads = {
      photo: async () => {
        for (const item of freshItems) {
          await galleryUploadPhoto(
            {
              id: item.id,
              file: item.file.edited,
              isPrivate: item.file.private,
              isCropped: true,
            },
            dispatch,
            getState
          );
        }
      },
      video: async () => {
        for (const item of freshItems) {
          await dispatch(
            galleryUploadVideo({
              id: item.id,
              file: item.file.raw,
              isPrivate: item.file.private,
            })
          );
        }
      },
    };

    if (typeof uploads[mediaType] !== 'undefined') {
      uploads[mediaType]();
    }
  };
};

export const retryProcessPhoto = async ({
  path,
  isPrivate,
  isCropped,
  dispatch,
  state,
  snackbarItemId,
}: {
  path: string;
  isPrivate: boolean;
  isCropped: boolean;
  dispatch: AppDispatch;
  state: RootState;
  snackbarItemId: string;
}): Promise<void> => {
  const { uploadSnackbar } = store.getState();
  const uploadSnackbarItem = uploadSnackbar.items.find((item: IUploadSnackbarItem) => item.id === snackbarItemId);

  if (uploadSnackbarItem && uploadSnackbarItem.retry >= MAX_RETRY) {
    dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'failed' }));
    return;
  }

  dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'retry' }));
  dispatch(incrementUploadSnackbar({ id: snackbarItemId }));

  processPhoto({
    path,
    isPrivate,
    isCropped,
    dispatch,
    state,
    snackbarItemId,
  });
};

export const processPhoto = async ({
  path,
  isPrivate,
  isCropped,
  dispatch,
  state,
  snackbarItemId,
}: {
  path: string;
  isPrivate: boolean;
  isCropped: boolean;
  dispatch: AppDispatch;
  state: RootState;
  snackbarItemId: string;
}): Promise<void> => {
  try {
    await sdk.processPhoto(state.auth.jwToken, {
      path: path,
      private: isPrivate,
      is_cropped: isCropped,
    });

    dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'success' }));

    dispatch(refreshProfile());
    dispatch(refreshAccount());
  } catch (error) {
    Sentry.captureException(error, { tags: { component: 'Photos', action: 'Upload' } });
    dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'failed' }));
  }
};

export const refreshUserPhoto = async ({
  path,
  photoUrl,
  isPrivate,
  isCropped,
  dispatch,
  state,
  snackbarItemId,
}: {
  path: string;
  photoUrl: string;
  isPrivate: boolean;
  isCropped: boolean;
  dispatch: AppDispatch;
  state: RootState;
  snackbarItemId: string;
}): Promise<void> => {
  const directPhotoUrl = `${MasterConfig.s3BucketDirect}820px/${path}.jpg`;

  try {
    const photoResponse = await sdk.getPhoto(directPhotoUrl);

    if (!('data' in photoResponse)) {
      Sentry.captureException('bulk upload photo failed to upload/processed', {
        extra: {
          photoPath: photoUrl,
          data: photoResponse,
        },
      });

      dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'failed' }));

      return;
    }

    return await processPhoto({
      path,
      state,
      isPrivate,
      isCropped,
      dispatch,
      snackbarItemId,
    });
  } catch (error) {
    dispatch(updateUploadStatusSnackbar({ id: snackbarItemId, status: 'failed' }));

    Sentry.captureException('Bulk upload photo failed to upload/processed', {
      extra: {
        photoPath: path,
        data: error,
      },
    });
  }
};

export const galleryUploadPhoto = async (
  formData: UploadMediaFormDataType,
  dispatch: any,
  getState: any
): Promise<any> => {
  const { isPrivate, file, isCropped, id } = formData;
  const croppedFile = await cropImage(file);

  try {
    const blobFile = generateImageBlob(croppedFile);
    const useDirectUpload = getState().profile.direct_s3_upload;
    const fileExtension = blobFile.type.substr(blobFile.type.lastIndexOf('/') + 1);

    if (!useDirectUpload) {
      dispatch(hideUploadSnackbar());
      dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));

      throw new Error('Error not isDirectUpload');
    }

    const photoUploadResponse = await getPhotoUploadResponse(
      useDirectUpload,
      fileExtension,
      isPrivate,
      croppedFile,
      getState
    );

    const apiResponse = photoUploadResponse.data.data;
    const { uploadUrl } = photoUploadResponse.data.meta;

    const fileName = apiResponse.path + '.' + fileExtension;
    const photoUrl = apiResponse.urls['820px'] || null;
    let fileWithUpdatedName: Blob | null = null;

    try {
      fileWithUpdatedName = generateImageBlob(croppedFile, fileName);
    } catch (error) {
      Sentry.captureException('Bulk upload photo error generateImageBlob. error: ', {
        extra: {
          photoPath: apiResponse.path,
          data: error,
        },
      });

      dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));
    }

    dispatch(updateUploadStatusSnackbar({ id, status: 'uploading' }));

    try {
      await sdk.uploadToS3(uploadUrl, fileWithUpdatedName);

      return await new Promise(
        resolve =>
          setTimeout(async () => {
            const response = await refreshUserPhoto({
              photoUrl,
              isPrivate,
              dispatch,
              isCropped,
              state: getState(),
              path: apiResponse.path,
              snackbarItemId: id,
            });

            resolve(response);
          }, 6000) // lets wait for the lambda to fully process the image resizing
      );
    } catch (error) {
      Sentry.captureException('Bulk upload photo failed to upload to s3. error: ', {
        extra: {
          photoPath: apiResponse.path,
          data: error,
        },
      });

      dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));
    }
  } catch (error) {
    // might need to update all snackbar items to failed
    dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));
  }
};

export const galleryUploadVideo = (formData: Omit<UploadMediaFormDataType, 'isCropped'>): unknown => {
  return async (dispatch: AppDispatch, getState: RootState) => {
    const { isPrivate, file, id } = formData;
    const mediaType = 'video/mp4';

    dispatch(updateUploadStatusSnackbar({ id, status: 'uploading' }));

    return await sdk
      .addNewMedia(getState().auth.jwToken, {
        mimetype: mediaType,
        private: isPrivate,
      })
      .then(response => {
        const apiResponse = response.data.data;
        const { uploadUrl } = response.data.meta;

        const newFile = getUploadFile(apiResponse, file, mediaType);

        const setTimeoutId = setTimeout(async () => {
          await sdk.uploadToS3(uploadUrl, newFile).then(
            () => {
              dispatch(updateUploadStatusSnackbar({ id, status: 'success' }));
              dispatch(refreshProfile());
              dispatch(refreshAccount()); // lets wait for the lambda to fully process the image resizing
              clearTimeout(setTimeoutId);
            },
            () => {
              clearTimeout(setTimeoutId);
              dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));
            }
          );
        }, 5000);
      })
      .catch(() => {
        dispatch(updateUploadStatusSnackbar({ id, status: 'failed' }));
      });
  };
};

export const addNewMedia = (formData: any, isPrivate: any, mediaType: any): any => {
  return (dispatch, getState) => {
    dispatch(pendingUpload(isPrivate));

    const mimeType = mediaType ? mediaType : formData.type;

    return sdk
      .addNewMedia(getState().auth.jwToken, {
        mimetype: mimeType,
        private: isPrivate,
      })
      .then(response => {
        const apiResponse = response.data.data;
        const { uploadUrl } = response.data.meta;

        const newFile = getUploadFile(apiResponse, formData, mediaType);

        sdk.uploadToS3(uploadUrl, newFile).then(
          () => {
            dispatch(endPendingUpload(isPrivate));
            dispatch(refreshProfile());
            dispatch(refreshAccount()); // lets wait for the lambda to fully process the image resizing
          },
          () => {
            // dispatch(deleteMedia(apiResponse.id));
            dispatch(endPendingUpload(isPrivate));
          }
        );
      })
      .catch(error => {
        uploadErrorFlow(error, isPrivate, dispatch);
      });
  };
};

const getUploadFile = (apiResponse: any, formData: any, mediaType: any): any => {
  const lastModifiedDate = new Date();
  const fileExtension = mediaType
    ? mediaType.substr(mediaType.lastIndexOf('/') + 1)
    : formData.type.substr(formData.type.lastIndexOf('/') + 1);
  const fileName = apiResponse.path + '.' + fileExtension;
  const mimeType = mediaType ? mediaType : formData.type;

  if (formData instanceof Blob) {
    return formData;
  }

  try {
    return new File([formData], fileName, { type: mimeType });
  } catch (err) {
    const newFile = new Blob([formData], { type: mimeType });

    newFile['lastModifiedDate'] = lastModifiedDate;
    newFile['lastModified'] = +lastModifiedDate;
    newFile['name '] = fileName;

    return newFile;
  }
};

export const deleteMedia = (mediaId: any): any => {
  return (dispatch, getState) => {
    return sdk
      .deleteMedia(getState().auth.jwToken, mediaId)
      .then(() => {
        /*dispatch(refreshProfile()); /* refresh main photo */
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const setNewMediaPrivacy = (mediaId: any, mediaKey: any): any => {
  // set new photo privacy.
  return (dispatch: any, getState: any) => {
    // notify store
    dispatch(storeNewPrivacy(mediaKey));
    // notify api
    return sdk.updateMediaPrivacy(getState().auth.jwToken, mediaId);
  };
};

const uploadErrorFlow = (error: any, isPrivate: any, dispatch: any): any => {
  // toast error
  dispatch(endPendingUpload(isPrivate));
  let msg = null;

  if (error.response && error.response.data) {
    if (error.response.data.video) {
      msg = error.response.data.video[0];
    } else if (error.response.data.errors) {
      msg = error.response.data.errors[0].detail;
    }
  }

  if (msg) {
    toaster.warn(msg);
  } else {
    dispatch(
      displayFailMessage({
        info: 'Error uploading video. Please try again or ',
        linkInfo: 'contact support.',
        url: '/support/contact',
      })
    );
  }
};

export const bulkDelete = (mediaIds: any): any => {
  const formData = {
    video: [...mediaIds['video']['public'], ...mediaIds['video']['private']],
    photo: [...mediaIds['photo']['public'], ...mediaIds['photo']['private']],
  };

  return dispatch => {
    dispatch(setGalleryLoading());
    return sdk
      .bulkDelete(formData)
      .then(response => {
        dispatch(refreshProfile());
        dispatch(refreshAccount());
        dispatch(killGalleryLoading());
        toaster.success('Successfully deleted photos/videos.');

        return response.data.data;
      })
      .catch(error => {
        dispatch(killGalleryLoading());
        dispatch(
          displayFailMessage({
            info: 'Error deleting photos/videos. Please try again or',
            linkInfo: 'contact support.',
            url: '/support/contact',
          })
        );

        console.error(error);
      });
  };
};

export const bulkTogglePrivacy = (mediaIds: any, privacy: any): any => {
  const formData = {
    video: [...mediaIds['video']['public'], ...mediaIds['video']['private']],
    photo: [...mediaIds['photo']['public'], ...mediaIds['photo']['private']],
    privacy: privacy,
  };

  return dispatch => {
    dispatch(setGalleryLoading());
    return sdk
      .bulkTogglePrivacy(formData)
      .then(response => {
        dispatch(refreshProfile());
        dispatch(refreshAccount());
        dispatch(killGalleryLoading());

        toaster.success(`Successfully updated the privacy of photos/videos to ${privacy}.`);
        return response.data.data;
      })
      .catch(error => {
        dispatch(killGalleryLoading());
        dispatch(
          displayFailMessage({
            info: 'Error updating privacy of photos/videos. Please try again or',
            linkInfo: 'contact support.',
            url: '/support/contact',
          })
        );

        console.error(error);
      });
  };
};
