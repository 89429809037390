import { STORE_BLOCKED, STORE_NEW_BLOCKED_USER, BlockedState, BlockedActionType } from './types/Blocked.d';

const initialState: BlockedState = {
  howManyPagesLoaded: 1,
  blockedItems: [],
};

const blocked = (state = initialState, action: BlockedActionType): any => {
  switch (action.type) {
    case STORE_BLOCKED:
      return {
        ...state,
        blockedItems: action.payload.data,
        pagination: action.payload.meta.pagination,
      };
    case STORE_NEW_BLOCKED_USER:
      return {
        ...state,
        blockedItems: state.blockedItems.concat(action.payload.data),
        pagination: action.payload.meta.pagination,
      };
    default:
      return state;
  }
};

export default blocked;
