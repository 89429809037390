import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import IconUploadInProgress from '../../../../atoms/icons/IconUploadInProgress';
import IconUploadFailed from '../../../../atoms/icons/IconUploadFailed';
import IconUploadSuccess from '../../../../atoms/icons/IconUploadSuccess';
import { dimensions } from '../../../../style';
import { MediaTypes } from '../../constants';
import { pluralize } from '../../../../common';
import { hideUploadSnackbar } from '../../../../actions/uploadSnackbarActions';
import { connect } from 'react-redux';
import { IUploadSnackbar, UploadSnackBarStatus } from '../../../../reducers/types/uploadSnackbar';
import { isDarkMode } from '../../../../utils/helpers';

const StyledCustomSnackBarContainer = styled('div')((props: { show: boolean }) => ({
  borderRadius: 6,
  border: isDarkMode() ? '1px solid #4A5E7B' : 'solid 1px #DAE1E9',
  background: isDarkMode() ? '#113855' : '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'fixed',
  bottom: 50,
  left: props.show ? '50px' : '-100%',
  zIndex: 3000,
  padding: '10px 20px',
  transition: 'left 0.5s ease-in-out',

  [dimensions.SCREEN_MAX_XS]: {
    padding: 10,
    bottom: 110,
    left: props.show ? '50%' : '-100%',
    transform: 'translate(-50%, 0)',
  },

  '& svg': {
    width: 32,
    height: 32,

    [dimensions.SCREEN_MAX_XS]: {
      width: 26,
      height: 26,
    },
  },
}));

const StyledCustomSnackBarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledLabelWrapper = styled('div')({
  display: 'flex',
  marginLeft: 8,
  color: isDarkMode() ? '#AFB0B1' : '#595959',

  [dimensions.SCREEN_MAX_XS]: {
    flexDirection: 'column',
  },
});

const StyledTitle = styled('span')({
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: '-0.28px',
  marginRight: 5,

  [dimensions.SCREEN_MAX_XS]: {
    fontSize: 12,
  },
});

const StyledDescription = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '-0.28px',

  [dimensions.SCREEN_MAX_XS]: {
    width: 181,
    fontSize: 12,
  },
});

const UploadInProgress = ({
  mediaLength,
  currentMediaCount = 1,
}: {
  mediaLength: number;
  currentMediaCount?: number;
}) => (
  <StyledCustomSnackBarWrapper>
    <IconUploadInProgress />
    <StyledLabelWrapper>
      <StyledTitle>
        {`${currentMediaCount > 0 ? currentMediaCount : 1}/${mediaLength}`} {mediaLength > 1 ? 'files are' : 'file is'}{' '}
        uploading.
      </StyledTitle>
      <StyledDescription>Please do not close this window.</StyledDescription>
    </StyledLabelWrapper>
  </StyledCustomSnackBarWrapper>
);

const UploadFailed = ({ mediaLength, mediaFailedLength = 0 }: { mediaLength: number; mediaFailedLength?: number }) => (
  <StyledCustomSnackBarWrapper>
    <IconUploadFailed />
    <StyledLabelWrapper>
      <StyledTitle>
        {`${mediaFailedLength}/${mediaLength}`} {`${pluralize(mediaFailedLength, 'file', 'files')}`} failed to upload
      </StyledTitle>
    </StyledLabelWrapper>
  </StyledCustomSnackBarWrapper>
);

const UploadSuccess = ({ mediaLength, mediaType }: { mediaLength: number; mediaType: MediaTypes }) => (
  <StyledCustomSnackBarWrapper>
    <IconUploadSuccess />
    <StyledLabelWrapper>
      <StyledDescription>
        <strong>{`${mediaLength}/${mediaLength}`} </strong>
        {`${pluralize(mediaLength, mediaType, `${mediaType}s`)} uploaded successfully!`}
      </StyledDescription>
    </StyledLabelWrapper>
  </StyledCustomSnackBarWrapper>
);

const GalleryUploadBackgroundProgress: React.FC<{
  hideSnackBar: () => void;
  uploadSnackbar: IUploadSnackbar;
}> = ({ hideSnackBar, uploadSnackbar }) => {
  const countItemsByStatus = (items, status: UploadSnackBarStatus[]) => {
    const filtered = items.filter(i => status.includes(i.status));
    return filtered.length || 0;
  };

  const [mediaType, setMediaType] = useState<MediaTypes>('photo');
  const [show, setShow] = useState(false);
  const [mediaCount, setMediaCount] = useState(0);
  const [successMediaCount, setSuccessMediaCount] = useState(0);
  const [failedMediaCount, setFailedMediaCount] = useState(0);
  const [uploadingMediaCount, setUploadingMediaCount] = useState(0);

  const status = useMemo(() => {
    const { items } = uploadSnackbar;
    const processing = countItemsByStatus(items, ['uploading', 'retry', '']);
    const failed = countItemsByStatus(items, ['failed']);
    const success = countItemsByStatus(items, ['success']);
    const itemsCount = items.length;

    if (failed > 0 && processing < 1) {
      return 'failed';
    }

    if (success === itemsCount && processing < 1) {
      return 'success';
    }

    if (processing > 0) {
      return 'processing';
    }
  }, [uploadSnackbar]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (status === 'processing' && uploadSnackbar.show) {
      setShow(true);
    } else if ((status === 'success' || status === 'failed') && uploadSnackbar.show) {
      setShow(false);

      timeout = setTimeout(() => {
        setShow(true);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [status, uploadSnackbar.show]);

  useEffect(() => {
    window.onbeforeunload = window.onunload = (e: BeforeUnloadEvent) =>
      status === 'processing'
        ? () => {
            e.preventDefault();
            e.returnValue = 'Are you sure want to leave ? Upload is in progress.';
          }
        : null;
  }, [status]);

  useEffect(() => {
    let autoClose: NodeJS.Timeout | null = null;

    if (status !== 'processing' && show) {
      const uploading = countItemsByStatus(uploadSnackbar.items, ['uploading', '']);

      autoClose = setTimeout(() => {
        if (failedMediaCount > 0 && uploading < 1) {
          setShow(false);
          hideSnackBar();
        }

        if (successMediaCount > 0 && successMediaCount === mediaCount && uploading < 1) {
          hideSnackBar();
          setShow(false);
        }

        if (failedMediaCount < 1 && successMediaCount < 1 && uploading > 0) {
          if (autoClose) {
            clearTimeout(autoClose);
          }
        }
      }, 5000);
    }

    return () => {
      if (autoClose) {
        clearTimeout(autoClose);
      }
    };
  }, [failedMediaCount, successMediaCount, uploadSnackbar, mediaCount, show]);

  useEffect(() => {
    setMediaCount(uploadSnackbar.items.length);

    const { items } = uploadSnackbar;

    setFailedMediaCount(countItemsByStatus(items, ['failed']));
    setSuccessMediaCount(countItemsByStatus(items, ['success']));
    setUploadingMediaCount(countItemsByStatus(items, ['uploading', 'retry']));
    setMediaType(uploadSnackbar.uploadType);
  }, [uploadSnackbar]);

  return (
    <StyledCustomSnackBarContainer show={show}>
      {status === 'processing' && (
        <UploadInProgress mediaLength={mediaCount} currentMediaCount={successMediaCount + uploadingMediaCount} />
      )}
      {status === 'failed' && <UploadFailed mediaLength={mediaCount} mediaFailedLength={failedMediaCount} />}
      {status === 'success' && <UploadSuccess mediaLength={mediaCount} mediaType={mediaType} />}
    </StyledCustomSnackBarContainer>
  );
};

const mapStateToProps = state => {
  return {
    uploadSnackbar: state.uploadSnackbar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideSnackBar: () => {
      dispatch(hideUploadSnackbar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryUploadBackgroundProgress);
