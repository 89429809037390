import React from 'react';
import AsyncContainer from '../containers/AsyncContainer';
import ContinueContainer from '../containers/ContinueContainer';
import LogoutContainer from '../containers/LogoutContainer';
import UnlinkEmailAccountContainer from '../containers/UnlinkEmailAccountContainer';
import UnsubscribeContainer from '../containers/UnsubscribeContainer';
import RestoreContainer from '../containers/RestoreContainer';
import AutoSignupContainer from '../containers/AutoSignupContainer';
import AccountMigrateProfileContainer from '../containers/AccountMigrateProfileContainer';
import AccountMigrateSummaryContainer from '../containers/AccountMigrateSummaryContainer';

const AsyncAcctMigrateActivationContainer = AsyncContainer(() =>
  import('../containers/AccountMigrateActivationContainer')
);
const AsyncLoginContainer = AsyncContainer(() => import('../containers/LoginContainer'));
const AsyncJoinContainer = AsyncContainer(() => import('../containers/JoinContainer'));
const AsyncRestoringContainer = AsyncContainer(() => import('../containers/RestoringContainer'));

export default [
  {
    path: '/login',
    component: <AsyncLoginContainer />,
  },
  {
    path: '/join',
    component: <AsyncJoinContainer />,
  },
  {
    path: '/logout',
    component: <LogoutContainer />,
  },
  {
    path: '/continue',
    component: <ContinueContainer />,
  },
  {
    path: '/restoring',
    component: <AsyncRestoringContainer />,
  },
  {
    path: '/unlink',
    component: <UnlinkEmailAccountContainer />,
  },
  {
    path: '/unsubscribe',
    component: <UnsubscribeContainer />,
  },
  {
    path: '/restore',
    component: <RestoreContainer />,
  },
  {
    path: '/account-migration',
    component: <AutoSignupContainer />,
  },
  {
    path: '/account-activation',
    component: <AsyncAcctMigrateActivationContainer />,
  },
  {
    path: '/account-getting-started',
    component: <AccountMigrateProfileContainer />,
  },
  {
    path: '/account-summary/:hashId',
    component: <AccountMigrateSummaryContainer />,
  },
];
