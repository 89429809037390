import React from 'react';
import ButtonSet from '../blocks/ButtonSet';
import Button from '../../atoms/buttons/Button';
import styled from '@emotion/styled';
import { useApp } from '../../contexts/AppContext';
import { setLocalItem } from '../../common';
import { PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY } from '../../utils/pwa';
import { format } from 'date-fns';
import ModalPattern from '../common/ModalPattern';
import { getBrowserDetails, isDarkMode } from '../../utils/helpers';
import { patternModal } from '../../style';
import FDBannerIcon from './icons/FDBannerIcon';
import WYPBannerIcon from './icons/WYPBannerIcon';
import { isFD } from '../../config/Master';
import PromptModalButtonIcon from './icons/PromptModalButtonIcon';
import { SvgIcon } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';

const PWAInstallationPromptContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
  paddingBottom: 0,
  textAlign: 'center',
  fontSize: 16,
});

const PWAInstallationModal = styled('div')({
  padding: 10,
  display: 'inherit',
  borderRadius: 20,
  top: '50%',
  height: 'auto',
  transform: 'translate(0px, -50%)',
  position: 'relative',
  boxSizing: 'border-box',
  border: patternModal.borderStyle,
  outline: 'none',
  color: isDarkMode() ? '#AFB0B1' : '#595959',
  background: patternModal.bgGradient,
  maxWidth: 335,
  maxHeight: 577,
  margin: '0px auto',
});

const PWAInstallationPromptOverLay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
  overflow: 'hidden',
  height: '100%',
  width: '90%',
  padding: '0px 5%',
});

const PWAInstallationPromptTitle = styled('h2')({
  color: 'rgb(188, 32, 40)',
  fontSize: 26,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '26px',
  letterSpacing: '-0.52px',
  margin: '21px auto',
});

const PWAInstallationPromptDescription = styled('p')({ margin: 0, textAlign: 'left', marginBottom: 21 });

const Spacer = styled('div')({ height: 21, padding: 0 });
const PWAInstallationPromptContentList = styled('ul')({
  textAlign: 'left',
  margin: 0,
  marginBottom: 42,
  paddingLeft: 26,
});
const PWAInstallationPromptContentListItem = styled('li')({ marginBottom: 10, fontSize: 16 });
const CloseBtn = styled('button')({
  appearance: 'none',
  border: 'none',
  background: 'none',
  height: '42px',
  position: 'absolute',
  top: 0,
  right: 0,
});

const closeButton = {
  fontSize: 24,
  color: '#8F8F8F',
};
interface PWAInstallationPromptModalProps {
  onClick?: any;
  locale?: any;
}

const PWAInstallationPromptModal: React.FC<PWAInstallationPromptModalProps> = ({ onClick, locale }) => {
  const { setShowIntallationBanner, setPwaBannerState } = useApp();

  const onCloseClick = (): void => {
    setShowIntallationBanner(false);
    setLocalItem(
      PWA_GLOBAL_INSTALLATION_PROMPT_LAST_SEEN_AT_LOCAL_STORAGE_KEY,
      format(new Date(), 'YYYY-MM-DD', { locale: locale })
    );
    setPwaBannerState(prev => {
      return {
        ...prev,
        show: false,
        platform: null,
      };
    });
  };

  return (
    <PWAInstallationPromptOverLay>
      <PWAInstallationModal>
        <ModalPattern padding={getBrowserDetails().isMobile ? 10 : 22}>
          <PWAInstallationPromptContainer>
            <CloseBtn
              className={'pwa-prompt-modal-close'}
              data-test-id={'pwa-prompt-modal-close'}
              onClick={onCloseClick}
            >
              <SvgIcon component={CancelRounded} style={closeButton} />
            </CloseBtn>
            {isFD ? <FDBannerIcon width="75" height="75" /> : <WYPBannerIcon width="75" height="75" />}
            <PWAInstallationPromptTitle>One More Thing!</PWAInstallationPromptTitle>
            <PWAInstallationPromptDescription>
              Install the WhatsYourPrice web app on your device and get:
            </PWAInstallationPromptDescription>
            <PWAInstallationPromptContentList>
              <PWAInstallationPromptContentListItem>
                Quick & easy access to WhatsYourPrice
              </PWAInstallationPromptContentListItem>
              <PWAInstallationPromptContentListItem>
                <strong>Notifications for new offers, when your offer is accepted, and new messages</strong>
              </PWAInstallationPromptContentListItem>
              <PWAInstallationPromptContentListItem>Faster load times</PWAInstallationPromptContentListItem>
              <PWAInstallationPromptContentListItem>A more app-like experience</PWAInstallationPromptContentListItem>
            </PWAInstallationPromptContentList>
            <ButtonSet align="center">
              <Button
                buttonType="error"
                onClick={() => {
                  onClick();
                }}
                type="button"
                customStyle={{
                  width: '100%',
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: '16px',
                  fontStyle: 'normal',
                }}
              >
                <PromptModalButtonIcon customStyle={{ marginRight: 6 }} /> Install the App
              </Button>
            </ButtonSet>
            <Spacer />
            <ButtonSet align="center">
              <Button
                buttonType="chromeless"
                customStyle={{
                  color: '#8392A7',
                  fontWeight: 400,
                  fontSize: 14,
                  padding: '4px 0',
                  height: 'auto',
                }}
                onClick={onCloseClick}
              >
                {`I'll do it later`}
              </Button>
            </ButtonSet>
          </PWAInstallationPromptContainer>
        </ModalPattern>
      </PWAInstallationModal>
    </PWAInstallationPromptOverLay>
  );
};

export default PWAInstallationPromptModal;
