import React, { useState, ReactElement, useMemo } from 'react';
import { css } from 'glamor';
import styled from '@emotion/styled';
import { IsMobileViewPort, getBrowserDetails } from '../../utils/helpers';
import { patternModal } from '../../style';
import SimpleCarousel from 'react-simply-carousel';
import { s3BucketDirect } from '../../config/Master';
import _isEmpty from 'lodash/isEmpty';

const startMsgIcon = `${s3BucketDirect}billing_carousel/start_message.png`;
const sendOfferIcon = `${s3BucketDirect}billing_carousel/send_offer.png`;
const creditsExpireIcon = `${s3BucketDirect}billing_carousel/credits_expire.png`;

const CarouselContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const CarouselStyled = styled('div')({
  position: 'relative',
});

const StyledList = styled('ul')({
  padding: 0,
  margin: 0,
  listStyleType: 'none',
});

const slideStyle = {
  marginRight: 'auto',
  marginLeft: 'auto',
  maxWidth: 900,
  listStyleType: 'none',
  textAlign: 'center',
  display: 'none',
  minHeight: 200,
};

const slideStyleActive = {
  display: 'block',
};

const indicatorStyle = {
  display: 'block',
  width: 8,
  height: 8,
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
  transition: 'opacity .15s cubic-bezier(.4, 0, 1, 1)',
  borderRadius: '50%',
  border: 'solid 1px #2B8FD7',
  padding: 4,
  margin: '0 5px',
};

const indicatorStyleActive = {
  backgroundColor: '#2B8FD7',
};

const TitleContainer = styled('h3')({
  fontStyle: 'normal',
  fontWeight: 700,
  // color: patternModal.amtColor,
  color: '#2B8FD7',
  fontSize: IsMobileViewPort ? 22 : 28,
});

const SubTitle = styled('div')({
  fontStyle: 'normal',
  color: patternModal.headerColor,
  fontSize: IsMobileViewPort ? 14 : 16,
  paddingTop: 10,
  maxWidth: 300,
  margin: '5px auto',
  height: 40,
});

const CarouselSlide = (idx, activeIdx, slide) => {
  const imgSrc = slide.icon;
  const imgComponent = slide?.iconComponent;
  const imgStyle = slide?.iconStyle ?? {};
  return (
    <li
      key={Math.floor(Math.random() * 999)}
      className={idx == activeIdx ? `${css({ ...slideStyle, ...slideStyleActive })}` : `${css(slideStyle)}`}
    >
      <TitleContainer>{slide.title}</TitleContainer>
      {imgSrc && <img height={IsMobileViewPort ? 90 : 120} src={imgSrc} style={imgStyle} />}
      {imgComponent && imgComponent}
      <SubTitle>{slide.subTitle}</SubTitle>
    </li>
  );
};

interface CarouselProps {
  slidesData?: any;
  hideArrows: boolean;
  clickableIndicators: boolean;
  isParagraphContent?: boolean;
  activeSlideIndex?: number;
}

const Carousel = ({ slidesData = {}, activeSlideIndex = 0 }: CarouselProps): ReactElement => {
  const [activeIndex, setActiveIndex] = useState(activeSlideIndex);

  const billingCarouselData = useMemo(
    () =>
      !_isEmpty(slidesData)
        ? slidesData
        : [
            {
              icon: startMsgIcon,
              title: 'Start Messaging Now',
              subTitle: 'Unlock all your conversations!',
            },
            {
              icon: sendOfferIcon,
              title: 'Send Unlimited Offers',
              subTitle: 'No more being capped at 40 offers. Send as many as you want!',
            },
            {
              icon: creditsExpireIcon,
              title: 'Credits Never Expire',
              subTitle: 'Keep your credits forever so buy more and save more!',
            },
          ],
    [slidesData]
  );

  const renderSimpleCarousel = () => {
    return (
      <SimpleCarousel
        containerProps={{
          style: {
            width: '100%',
            userSelect: 'none',
          },
        }}
        autoplay
        autoplayDelay={5000}
        swipeTreshold={60}
        activeSlideIndex={activeIndex}
        onRequestChange={setActiveIndex}
        forwardBtnProps={{ show: false }}
        backwardBtnProps={{ show: false }}
        dotsNav={{
          show: true,
          itemBtnProps: {
            style: indicatorStyle,
          },
          activeItemBtnProps: {
            style: { ...indicatorStyle, ...indicatorStyleActive },
          },
        }}
        itemsToShow={1}
        itemsToScroll={1}
        speed={300}
        persistentChangeCallbacks
        easing="ease"
      >
        {billingCarouselData.map((item, index) => (
          <div
            style={{
              width: 300,
              height: getBrowserDetails().isTablet ? 280 : getBrowserDetails().isMobile ? 235 : 300,
              textAlign: 'center',
              boxSizing: 'border-box',
            }}
            key={index}
          >
            {CarouselSlide(index, activeIndex, item)}
          </div>
        ))}
      </SimpleCarousel>
    );
  };

  return (
    <CarouselContainer>
      <CarouselStyled>
        <StyledList>{renderSimpleCarousel()}</StyledList>
      </CarouselStyled>
    </CarouselContainer>
  );
};

export default Carousel;
