import React, { FC } from 'react';
import styled from '@emotion/styled';

import { type, spacing, dimensions } from '../../style';

const Loading = styled('div')({
  ...spacing.MARGIN_TOP_SM,
  ...spacing.PADDING_H,
  ...type.LG,
  textAlign: 'center',
  [dimensions.SCREEN_MAX_XS]: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    marginTop: 0,
    paddingTop: '20px',
  },
});

const LoadingMsg: FC = (props: any) => {
  const { msg } = props;

  return <Loading>{msg}...</Loading>;
};

export default LoadingMsg;
