import React, { PureComponent, createContext } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import { updatePageTitle } from '../common';
import { PUSHER } from '../config/constants';
import store from '../store';
import {
  decrementUnreadOneTapOffersCount,
  incrementUnreadOneTapOffersCount,
} from '../actions/unreadOneTapOffersCountActions';
import { resetNewOffers } from '../actions/offerActions';

const { Provider, Consumer } = createContext({
  isConnected: false,
  onRealtimeEvent: null,
});

export const ConnectPusher = (ChildComponent: any): any => {
  const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
  const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
  const API_ENDPOINT = process.env.REACT_APP_SDK_BASE_URL;

  class PusherProvider extends PureComponent {
    constructor() {
      super();

      this.state = {
        channel: null,
      };

      this.bindedListeners = [];
    }

    componentDidMount() {
      this.pageMonitoring = null;
      this.init();
    }

    componentWillUnmount() {
      this.disconnect();
    }

    /**
     * Initialize connection
     */
    init() {
      try {
        this.connect();
      } catch (error) {
        if (this.connection && typeof this.connection.disconnect === 'function') {
          this.connection.disconnect();
        }

        if ('Raven' in window) {
          Sentry.captureException(error, { tags: { component: 'Realtime', action: 'Initialize' } });
        }

        console.error(error);
      }
    }

    /**
     * Connect to pusher and add event listeners
     *
     * @param {number} tryCount
     */
    connect(tryCount = 0) {
      let currentTry = tryCount;

      if (this.connection && typeof this.connection.subscribe === 'function') {
        this.subscribe();
        return;
      }

      const { virgil } = store.getState().auth;

      const pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        forceTLS: true,
        authEndpoint: `${API_ENDPOINT}/auth/socket`,
        auth: {
          headers: {
            Authorization: `Bearer ${this.props.auth.jwToken}`,
            'X-Auth-Type': virgil ? 'virgil' : '',
          },
        },
      });

      pusher.connection.bind('error', () => {
        this.disconnect();

        if (currentTry === 3) {
          return console.error('Max pusher connection attempts reached!');
        }

        currentTry += 1;
        this.connect(currentTry);
      });

      pusher.connection.bind('disconnected', () => {
        this.connection = null;
      });

      pusher.connection.bind('connected', () => {
        currentTry = 0;
        this.connection = pusher;
        this.subscribe();
      });
    }

    /**
     * Disconnect to pusher
     */
    disconnect() {
      if (this.connection && typeof this.connection.disconnect === 'function') {
        this.connection.disconnect();
      }
    }

    /**
     * Subscribe to a channel
     *
     * @param {number} tryCount
     */
    subscribe(tryCount = 0) {
      let currentTry = tryCount;
      const channel = this.connection && this.connection.subscribe(`private-${this.props.account.hash_id}`);

      if (this.connection) {
        channel.bind('pusher:subscription_succeeded', () => {
          currentTry = 0;
          this.setState({ channel }, () => this.listen());
        });

        channel.bind('pusher:subscription_error', status => {
          this.setState({ channel: null });

          if (currentTry === 3) {
            this.disconnect();
            return console.error('Max channel subscription attempts reached!');
          }

          if ([401, 408, 503, 403].indexOf(status) > -1) {
            currentTry += 1;
            this.subscribe(currentTry);
          }
        });
      }
    }

    handleNewOfferNotification(data) {
      if (!data || (data && data.type !== 'offers')) return;

      const pathName = window.location.pathname;
      const offersView = localStorage.getItem('offers-view') as string;
      const { incrementUnreadOneTapOffersCountAction, decrementUnreadOneTapOffersCountAction } = this.props;

      if (data.action === 'cancel') {
        decrementUnreadOneTapOffersCountAction();
      } else if (data.action === 'receive' || data.action === 'suggest') {
        if (pathName !== '/offers/new' && (!offersView || offersView === 'grid')) {
          incrementUnreadOneTapOffersCountAction();
        }
      }
    }

    /**
     * Listen to events
     */
    listen() {
      const { channel } = this.state;
      const { updateNotificationCounts } = this.props;
      const { EVENTS } = PUSHER;

      channel.bind(EVENTS.ACCOUNT_ALERTS, data => {
        this.handleNewOfferNotification(data);

        if (data.type === 'video-event') {
          return false;
        }

        const { counts } = data && data.data && data.data.account;

        updatePageTitle(counts.unread_messages);
        updateNotificationCounts(counts);

        if (data.action === 'cancel' || data.type === 'counts') {
          return false;
        }
      });

      this.monitorPusher();
    }

    monitorPusher() {
      try {
        this.bindedListeners.forEach(listener => listener());
      } catch (error) {
        // no binded listeners available
      }
    }

    /**
     * Bind event and callback to channel
     */
    onRealtimeEvent = (event, callback) => {
      const { channel } = this.state;

      if (channel) {
        channel.bind(event, callback);
      }
    };

    /**
     * Unbinds event listeners to channels
     */
    stopListening = listener => {
      const { channel } = this.state;

      if (typeof listener === 'function' && Array.isArray(this.bindedListeners)) {
        this.bindedListeners = this.bindedListeners.filter(item => listener !== item);
      }

      if (channel) {
        channel.unbind();
        this.listen();
      }
    };

    /**
     * Store listener consumer listener for reinitialization upon reconnection
     */
    bindListener = listener => {
      if (typeof listener === 'function') {
        this.bindedListeners.push(listener);
        listener();
      }
    };

    render() {
      return (
        <Provider
          value={{
            bindListener: this.bindListener,
            onRealtimeEvent: this.onRealtimeEvent,
            stopListening: this.stopListening,
          }}
        >
          <ChildComponent {...this.props} />
        </Provider>
      );
    }
  }

  return connect(
    state => ({
      settings: state.settings,
      account: state.profile,
    }),
    dispatch => ({
      updateNotificationCounts: counts => {
        dispatch({
          type: 'UPDATE_NOTIFICATION_COUNTS',
          payload: {
            data: {
              ...counts,
              has_fav_update: true,
            },
          },
        });
      },
      incrementUnreadOneTapOffersCountAction: () => {
        dispatch(incrementUnreadOneTapOffersCount());
        dispatch(resetNewOffers());
      },
      decrementUnreadOneTapOffersCountAction: () => {
        dispatch(decrementUnreadOneTapOffersCount());
        dispatch(resetNewOffers());
      },
    })
  )(PusherProvider);
};

export const withPusher = (ChildComponent: any): any => {
  return class extends PureComponent {
    render() {
      return <Consumer>{value => <ChildComponent {...value} {...this.props} />}</Consumer>;
    }
  };
};
