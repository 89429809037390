/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef, ReactElement, useMemo } from 'react';
import styled from '@emotion/styled';
import { dimensions, patternModal } from '../../../style';

import Avatar from '../../../atoms/Avatar';
import Headline from '../../common/Headline';
import TextLink from '../../common/TextLink';
import PriceInput, { adjustStylePerDigit } from './PriceInput';
import Button from '../../../atoms/buttons/Button';
import ButtonSet from '../../blocks/ButtonSet';
import { getBrowserDetails, IsMobileViewPort } from '../../../utils/helpers';
import { reviseOffer, sendCancel, sendSuggest } from '../../../actions/profileTrackAction';
import useOnClickTriggerTracking from '../../../hooks/useOnclickTriggerTracking';
import borders from '../../../theme/borders';
import colors, { white } from '../../../theme/colors';
import { connect } from 'react-redux';
import { ProfileMaker } from '../../../models/Profile';
import { ProfileState } from '../../../reducers/profile';
import PrimaryButton from '../../../atoms/buttons/PrimaryButton';
import { CrossIcon } from '../../../atoms/icons/favoriteIcons';
import { PriceContainer, InputProps, CurrencyTip, CurrencyLabelSpan } from './common';

const EditAmountRange = styled('div')(
  {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 400,
    margin: '0 auto',
  },
  ({ hasErrors }) => ({
    color: hasErrors ? colors.light.warning.primary : 'inherit',
    fontStyle: hasErrors ? 'italic' : 'inherit',

    [dimensions.SCREEN_MAX_SM]: {
      width: 260,
    },
  })
);

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const HeaderDiv = styled('div')({
  textAlign: 'center',
  marginTop: 42,

  [dimensions.SCREEN_MAX_SM]: {
    marginTop: 12,
  },
});

const CenterDiv = styled('div')({
  textAlign: 'center',
  padding: '20px 0',

  [dimensions.SCREEN_MAX_SM]: {
    padding: 15,
  },
});

const AvatarTitleDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
});

const TitleDiv = styled('span')({
  color: patternModal.titleColor,
  fontSize: 32,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 8,
  [dimensions.SCREEN_MAX_SM]: {
    fontSize: 20,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  justifyContent: 'space-between',
});

const colorType = (price, hasErrors) => {
  switch (price) {
    case true:
      return 'default';
    case false:
      return !hasErrors ? 'brand' : 'alert';
    default:
      return;
  }
};

const ButtonDiv = styled('div')(
  {
    [dimensions.SCREEN_MAX_SM]: {
      marginBottom: 0,
      width: '100%',
    },
  },
  ({ hasErrors }) => ({
    marginTop: hasErrors ? 30 : 40,
  })
);

const status = currentUserIsGenerous => (currentUserIsGenerous ? 'Offer' : 'Request');

const offerMinMaxVal = (offer, type, defaultAmount) => {
  const offerVal = offer && type === 'min' ? offer.data.min_counter_price : offer && offer.data.max_counter_price;
  return offer ? offerVal : defaultAmount;
};

const validateTextInput = e => {
  const Key = e.key;
  const regex = /[0-9]/;
  if (!regex.test(Key)) {
    if (e.preventDefault) e.preventDefault();
  }
};

interface OfferEditProps {
  avatarSrc: any;
  offer: any;
  isCounter: any;
  closeModal: any;
  currencyLabel: any;
  currencyInitial: any;
  currentUserIsGenerous: any;
  clearErrors: any;
  username: any;
  updateOffer: any;
  hashId: any;
  buttonID: any;
  currentOffer: any;
  successOpen: any;
  sex: string;
  profile: ProfileState;
  onCancelAndSendNewOfferHandler: () => void;
}

const OfferEdit = ({
  avatarSrc,
  offer = '',
  closeModal,
  currencyLabel,
  currencyInitial,
  currentUserIsGenerous,
  clearErrors,
  username,
  updateOffer,
  hashId,
  buttonID,
  currentOffer,
  successOpen,
  sex,
  profile,
  onCancelAndSendNewOfferHandler,
}: OfferEditProps): ReactElement => {
  const [defaultValue] = useState('');
  const [offerPrice, setOfferPrice] = useState('');
  const [minValue, setMinValue] = useState(offerMinMaxVal(offer, 'min', '5'));
  const [maxValue, setMaxValue] = useState(offerMinMaxVal(offer, 'max', '500'));
  const [hasErrors, setHasErrors] = useState(false);
  const [isOfferSending, setOfferSending] = useState(false);

  const sendOfferBtn = useRef(null);
  const sendCancelBtn = useRef(null);
  const priceInput = useRef(null);
  const sendSuggestLink = useRef(null);
  const profileModel = ProfileMaker.create(profile.profile);
  const isGenerous = profileModel.isGenerous();
  const currLabel = String.fromCharCode(currencyLabel);

  const hasAmountOffer = currentOffer !== null && parseInt(currentOffer) !== 0;
  const isOfferAmountIsMinimumOrMaximum = isGenerous
    ? parseInt(currentOffer) === parseInt(maxValue)
    : parseInt(currentOffer) === parseInt(minValue);

  useOnClickTriggerTracking('Revise Offer', sendOfferBtn, reviseOffer);
  useOnClickTriggerTracking('Send Cancel', sendCancelBtn, sendCancel);
  useOnClickTriggerTracking('Suggest Offer', sendSuggestLink, sendSuggest);

  useEffect(() => {
    if (isOfferAmountIsMinimumOrMaximum) {
      setHasErrors(true);
    }
  }, [isOfferAmountIsMinimumOrMaximum]);

  useEffect(() => {
    setMaxValue(offerMinMaxVal(offer, 'max', '500'));
    setMinValue(offerMinMaxVal(offer, 'min', '5'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const updateOfferPrice = (val, error) => {
    if (!error) {
      clearErrors();
    }
    setOfferPrice(val);
    setHasErrors(error || false);
  };

  useEffect(() => {
    setOfferPrice(currentOffer);
  }, [currentOffer]);

  const offerAmountMessage = useMemo(() => {
    if (isOfferAmountIsMinimumOrMaximum) {
      return `Cannot edit your offer since it is already at the ${
        isGenerous ? 'maximum' : 'minimum'
      }. Cancel and resubmit a new offer instead?`;
    }

    const prefixMessage = hasErrors
      ? isGenerous
        ? 'You must enter a new offer between'
        : 'Your new offer request must be between'
      : `Enter a new offer ${isGenerous ? '' : 'request'} between`;

    const fromValue = isGenerous
      ? hasAmountOffer
        ? currentOffer + 1
        : minValue
      : hasAmountOffer
      ? currentOffer - 1
      : maxValue;
    const toValue = isGenerous ? maxValue : minValue;

    return `${prefixMessage} ${currencyInitial}${currLabel}${fromValue} and ${currencyInitial}${currLabel}${toValue}.`;
  }, [offerPrice, currencyInitial]);

  const isValidateOfferAmountByType = offerAmount => {
    if (parseInt(offerAmount) === parseInt(currentOffer)) {
      return false;
    }

    // user is initially asking for offer suggestion from other account
    if (!hasAmountOffer) {
      return true;
    }

    if (parseInt(offerAmount) === parseInt(currentOffer)) {
      return false;
    }

    return profileModel.isGenerous() ? Boolean(offerAmount > currentOffer) : Boolean(offerAmount < currentOffer);
  };

  const inputOnChange = e => {
    const inputVal = Number(e.target.value);
    const minValueNum = Number(minValue);
    const maxValueNum = Number(maxValue);
    const maxLength = maxValueNum.toString().length;

    if (e.target.value.length <= maxLength) {
      const error = inputVal < minValueNum || inputVal > maxValueNum || !isValidateOfferAmountByType(inputVal);

      updateOfferPrice(inputVal, error);
    } else {
      e.preventDefault();
    }
  };

  const handleSubmit = async e => {
    setOfferSending(true);
    e.preventDefault();

    const formData = { price: offerPrice, account: hashId };

    try {
      const response = await updateOffer(formData);
      const currencyInitial = response.currency_original.data.initial;
      const currLabel = String.fromCharCode(response.currency_original.data.label_unicode);
      const offerAmount = `${currencyInitial}${currLabel}${offerPrice}`;
      successOpen(true, offerAmount, false, true);
      setOfferSending(false);
    } catch {
      setOfferSending(false);
      setHasErrors(true);
    }
  };

  const onHandleSuggestion = async e => {
    setOfferSending(true);
    e.preventDefault();
    const formData = { price: 0, account: hashId, suggest: true };

    try {
      await updateOffer(formData);
      successOpen(true, '', false, true);
      setOfferSending(false);
    } catch {
      setOfferSending(false);
      setHasErrors(true);
    }
  };

  const cancelAndShowANewOfferModal = () => {
    // trigger cancel and show new offer modal
    // close modal
    closeModal();
    onCancelAndSendNewOfferHandler();
  };

  return (
    <Form>
      <HeaderDiv>
        <AvatarTitleDiv>
          <Avatar
            data-test-id="offer-modal-avatar"
            src={avatarSrc}
            width={getBrowserDetails().isMobile ? '75px' : '100px'}
            customStyle={{
              backgroundColor: '#ccc',
              border: `1px solid ${borders.light.primary}`,
            }}
          />
          <TitleDiv>{`Edit Your ${currentUserIsGenerous ? 'Offer' : 'Request'}`}</TitleDiv>
        </AvatarTitleDiv>
        <Headline
          style={{
            color: patternModal.headerColor,
            marginTop: IsMobileViewPort ? 15 : 20,
            fontSize: getBrowserDetails().isMobile ? 14 : 16,
          }}
          data-test-id="profile-offer-headline"
          size="SM"
        >
          <span>
            Edit your offer {isGenerous ? '' : 'request'} to <strong>{username}</strong>
          </span>
        </Headline>
      </HeaderDiv>
      <Content>
        <React.Fragment>
          <PriceContainer hasErrors={hasErrors}>
            <CurrencyTip
              offerPrice={colorType(offerPrice, hasErrors)}
              style={{
                paddingLeft: adjustStylePerDigit(offerPrice).paddingLeft,
              }}
            >
              <CurrencyLabelSpan>
                {currencyInitial}
                {currLabel}
              </CurrencyLabelSpan>
              <PriceInput
                inputRef={priceInput}
                data-test-id="offer-price-input"
                hasErrors={hasErrors}
                value={offerPrice}
                onChange={inputOnChange}
                validateTextFn={validateTextInput}
                placeholder={defaultValue}
                inputProps={{
                  style: {
                    ...InputProps.style,
                    marginLeft: adjustStylePerDigit(offerPrice).marginLeft,
                    textAlign: 'left',
                  },
                }}
                minWidth={IsMobileViewPort ? 100 : 120}
              />
            </CurrencyTip>
          </PriceContainer>
          <EditAmountRange hasErrors={hasErrors}>{offerAmountMessage}</EditAmountRange>
        </React.Fragment>
        <ButtonDiv hasErrors={hasErrors}>
          <ButtonSet align="center">
            {isOfferAmountIsMinimumOrMaximum ? (
              <PrimaryButton
                size="small"
                color="red"
                label="Cancel and Send New Offer"
                onClick={cancelAndShowANewOfferModal}
                withIconLeft={
                  <CrossIcon
                    fill={white}
                    customStyle={{ width: 15, height: 15, marginRight: 3, position: 'relative', top: -1.5 }}
                  />
                }
                customStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'inherit',
                  height: 42,
                }}
              />
            ) : (
              <Button
                id={buttonID}
                buttonRef={sendOfferBtn}
                disabled={hasErrors || isOfferSending || parseInt(currentOffer) === parseInt(offerPrice)}
                buttonType="error"
                onClick={handleSubmit}
                type="submit"
              >
                {isOfferSending
                  ? `Submitting ${status(currentUserIsGenerous)}...`
                  : `Send New Offer ${!isGenerous ? 'Request' : ''}`}
              </Button>
            )}
          </ButtonSet>
          {currentOffer && (
            <React.Fragment>
              <CenterDiv>
                <strong>- OR -</strong>
              </CenterDiv>
              <span>
                <ButtonSet align="center">
                  <TextLink
                    linkRef={sendSuggestLink}
                    data-test-id="offer-suggest-link"
                    to="/"
                    disabled={hasErrors || isOfferSending}
                    onClick={e => onHandleSuggestion(e)}
                    type="submit"
                    style={{ color: '#BC2028', textDecoration: 'underline' }}
                  >
                    {' '}
                    Ask {sex === 'Male' ? 'him' : 'her'} to suggest an offer amount
                  </TextLink>
                </ButtonSet>
              </span>
            </React.Fragment>
          )}
          <ButtonSet align="center">
            <Button
              buttonType="chromeless"
              buttonRef={sendCancelBtn}
              data-test-id={`${buttonID}Cancel`}
              disabled={isOfferSending}
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
              customStyle={{
                color: borders.light.secondary,
                fontWeight: 'normal',
                textDecoration: 'none',
                marginTop: currentOffer ? 10 : 'unset',
                marginBottom: 0,
              }}
            >
              {' '}
              Cancel
            </Button>
          </ButtonSet>
        </ButtonDiv>
      </Content>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, {})(OfferEdit);
