import React, { useMemo, Fragment, ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import FavoriteOfferButton from '../../../atoms/buttons/FavoriteOfferButton';
import OfferAmountButton from '../../../atoms/buttons/OfferAmountButton';
import { backgroundTheme, dimensions } from '../../../style';
import { renderAvatar, displayUserLocation } from '../../../common';
import FavOfferCreate from '../Offers/FavOfferCreate';
import OfferModalFooter from '../Offers/OfferModalFooter';
import OfferSuccess from '../Offers/OfferSuccess';
import OfferAccept from '../Offers/OfferAccept';
import OfferAcceptUnlock from '../Offers/OfferAcceptUnlock';
import OfferAcceptMessage from '../Offers/OfferAcceptMessage';
import OfferEdit from './OfferEdit';
import useModal from '../../../hooks/useModal';
import { getBrowserDetails } from '../../../utils/helpers';
import { useOffers } from '../../../contexts/OffersContext';
import { OFFERS_NEW_PATH } from '../../../constants/paths';
import Tooltip from '../../common/Tooltip';
import CompleteProfileModalV2 from '../../../modules/modals/CompleteProfileModalV2';
import { declineOffer, counterModalClose } from '../../../actions/profileTrackAction';
import { cancelOffer, counterOffer } from '../../../actions/offerTrackAction';
import useMixPanel from '../../../hooks/useMixPanel';
import useCompleteProfileModal from '../../../hooks/useCompleteProfileModal';
import { toaster } from '../../../common';
import CreateNewOffer from './CreateNewOffer';
import { refreshProfile } from '../../../actions/profileActions';
import { refreshOffers } from '../../../actions/offerActions';
import { displayWarnMessage } from '../../../actions/notificationActions';
import { connect } from 'react-redux';
import { AccountMaker } from '../../../models/Account';

const OfferActionWrap = styled('div')(
  {
    margin: 0,
    justifyContent: 'middle',
    marginRight: 20,
    width: '100%',
    [dimensions.SCREEN_MAX_SM]: {
      marginRight: 0,
    },
  },
  ({ viewType }) =>
    viewType === 'grid'
      ? {
          backgroundColor: backgroundTheme.personalInfo,
          marginRight: 0,
        }
      : {
          width: 'unset',
          [dimensions.SCREEN_MAX_SM]: {
            marginRight: 8,
          },
        }
);

const OfferActionsButtonWrap = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row',
    alignItems: 'center',
  },
  ({ isChangeView }) =>
    isChangeView && {
      flexDirection: 'row-reverse',
    },
  ({ enableSlider }) =>
    enableSlider && {
      [dimensions.SCREEN_MAX_SM]: {
        justifyContent: 'center',
      },
    }
);

interface OfferActionButtonProps {
  offerKey: any;
  offer: any;
  user: any;
  viewType: any;
  otherAccount: any;
  createNewOffer: any;
  sendSuggestion: any;
  clearErrors: any;
  errors: any;
  showWarn: any;
  acceptOffer: any;
  counterOffer: any;
  isMatched: any;
  isSuggestRequest: any;
  ignoreOffer: any;
  updateOffer(): void;
  ignoreOfferFromList: any;
  unlockMessage(): void;
  pageTitle: string;
  enableSlider?: boolean;
  refreshOffers: (route: string, orderType: string) => void;
}

const handleVysionCounterOffer = () => counterOffer();

const OfferActionButton = ({
  offerKey,
  offer,
  user,
  viewType,
  otherAccount,
  createNewOffer,
  sendSuggestion,
  clearErrors,
  errors,
  showWarn,
  acceptOffer,
  counterOffer,
  isMatched,
  isSuggestRequest,
  ignoreOffer,
  updateOffer,
  ignoreOfferFromList,
  unlockMessage,
  pageTitle,
  enableSlider = false,
  refreshOffers,
}: OfferActionButtonProps): ReactElement => {
  const { trackOfferDeclined, trackOfferCancelled } = useMixPanel();
  const trackMixPanelPageName = pageTitle === 'Received Requests' ? 'Received Offers' : pageTitle;
  const hasOffer = offer !== undefined && offer.data !== undefined;
  const offerData = offer && offer.data;
  const offerIsFromOtherUser =
    hasOffer && offerData.status === 'pending' && offerData.to_account_hash_id === user.hash_id;
  const otherProfile = otherAccount.profile.data;
  const isGenerous = otherProfile.account_type === 'Attractive';
  const canSendOffer = isMatched && !offer;
  const { isDesktop } = getBrowserDetails();
  const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
  const { setVisitedTabHandler } = useOffers();
  const accountModel = AccountMaker.create(user);
  const { showTooManyPendingOffersErrorModal } = useModal();

  const { confirmModal, basicModal, resetModal } = useModal();
  const location = useLocation();

  const activePage = useMemo(() => location.pathname, [location]);
  const isReceivedOffersListViewDesktop = useMemo(
    () => isDesktop && viewType === 'list' && activePage === OFFERS_NEW_PATH,
    [isDesktop, viewType, activePage]
  );

  const { showMandatoryCompleteProfile } = useCompleteProfileModal();
  const userCanMakeOffer = useMemo(() => user.can_make_offer, [user.can_make_offer]);

  const formatPrice = (currency, price) => {
    if (!currency || !price) {
      return '';
    }
    return `${currency.initial}${String.fromCharCode(currency.label)}${price}`;
  };

  const otherUserLoc = {
    country: otherProfile.country,
    region: otherProfile.region,
    city: otherProfile.city,
  };

  const handleAttractiveMessage = (amount = '') => {
    showMandatoryCompleteProfile(
      () => {
        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          modalBody: (
            <Fragment>
              <OfferAcceptMessage
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                buttonID="interestedOffers"
                closeModal={resetModal}
                onSubmit={dealMessage => {
                  return acceptOffer(otherAccount.hash_id, dealMessage, pageTitle);
                }}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Accept Offer'
    );
  };

  const handleForceIgnoreOffer = async () => {
    await ignoreOffer(otherAccount.hash_id);

    declineOffer();
    trackOfferDeclined(offer.data.initiator_hash_id, {
      Amount: offer.data.price_original,
      User: user.hash_id,
      Source: trackMixPanelPageName,
    });

    toaster.warn('Offer Declined');

    resetModal();
  };

  const handleIgnoreOffer = () => {
    confirmModal({
      dataTestID: 'offer-cancel-sent-offers',
      modalContentLabel: 'Received Ignore',
      confirmationProps: {
        title: `Decline ${isGenerous ? 'Offer' : 'Request'}?`,
        message: `Are you sure you want to <strong>Decline</strong> the ${
          isGenerous ? 'offer' : 'request'
        } from <strong>${otherAccount.username}</strong>?`,
        confirmText: `Decline ${isGenerous ? 'Offer' : 'Request'}`,
        confirmAction: async () => {
          await ignoreOffer(otherAccount.hash_id);

          declineOffer();
          trackOfferDeclined(offer.data.initiator_hash_id, {
            Amount: offer.data.price_original,
            User: user.hash_id,
            Source: trackMixPanelPageName,
          });

          toaster.warn('Offer Declined');

          resetModal();
        },
      },
    });
  };

  const handleCancelSentOffer = () => {
    confirmModal({
      dataTestID: 'offer-cancel-sent-offers',
      modalContentLabel: 'Offers Cancel Sent Offers',
      confirmationProps: {
        title: `Cancel ${isGenerous ? 'Offer' : 'Request'}?`,
        message: `Are you sure you want to <strong>Cancel</strong> your ${
          isGenerous ? 'offer' : 'request'
        } to <strong>${otherAccount.username}</strong>?`,
        confirmText: `Cancel ${isGenerous ? 'Offer' : 'Request'}`,
        confirmAction: async () => {
          await ignoreOfferFromList(offer.data);

          cancelOffer();
          trackOfferCancelled(offer.data.initiator_hash_id, {
            Amount: offer.data.price_original,
            User: user.hash_id,
            Source: 'sent offers',
          });

          resetModal();
        },
      },
    });
  };

  const handleOfferSuccess = (toggle, amount = '', isCounter = false, isRevised = false, isSuggest = false) => {
    if (isCounter) {
      setVisitedTabHandler('sent', false);
    }

    showMandatoryCompleteProfile(
      () => {
        basicModal({
          dateTestId: 'offer-success',
          modalContentLabel: 'Success Offer Modal',
          isModalOpen: toggle,
          modalBody: (
            <Fragment>
              <OfferSuccess
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                successOpen={resetModal}
                buttonID="interestedOffers"
                isCounter={isCounter}
                isRevised={isRevised}
                isSuggest={isSuggest}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Accept Offer'
    );
  };

  const handleAcceptUnlock = (toggle, amount = '', result) => {
    showMandatoryCompleteProfile(() => {
      basicModal(
        {
          dateTestId: 'offer-success',
          modalContentLabel: 'Success Offer Modal',
          isModalOpen: toggle,
          modalBody: (
            <Fragment>
              <OfferAcceptUnlock
                offerAmount={amount}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                successOpen={resetModal}
                buttonID="interestedOffers"
                currentUserIsGenerous={isGenerous}
                creditCost={result.credit_cost}
                hashId={otherAccount.hash_id}
                closeModal={resetModal}
                currentCredit={user.credits}
                unlockMessage={unlockMessage}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        },
        () => undefined,
        'Unlock Offer'
      );
    });
  };

  const handleCreateNewOffer = (formData, shouldRefreshList = false) => {
    return createNewOffer(formData, shouldRefreshList);
  };

  const handleCounterOffer = formData => {
    handleVysionCounterOffer();
    return counterOffer(formData, offerKey, null);
  };

  const handleSuggestion = (profileId, showToastMessage = true) => {
    return sendSuggestion(profileId, offerKey, null, showToastMessage);
  };

  const handleNewOffer = () => {
    showMandatoryCompleteProfile(
      () => {
        if (!userCanMakeOffer && isGenerous) {
          return setShowCompleteProfileModal(true);
        }

        basicModal({
          dateTestId: 'new-offer',
          modalContentLabel: 'New Offer Modal',
          isModalOpen: true,
          modalBody: (
            <Fragment>
              <CreateNewOffer
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={clearErrors}
                createNewOffer={handleCreateNewOffer}
                onOfferSuccess={() => {
                  refreshOffers('sent', 'recent');
                }}
                successOpen={handleOfferSuccess}
                closeModal={resetModal}
                currencyInitial={otherAccount.currency.data.initial}
                currencyLabel={otherAccount.currency.data.label_unicode}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                sex={otherProfile.sex}
                sendSuggestion={hashId => handleSuggestion(hashId, false)}
                showWarn={showWarn}
                cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                buttonID="interestedOffers"
                isSuggestRequest={false}
                title={isGenerous ? 'Make an Offer!' : 'Request A Date!'}
                pageTitle={'Make Request'}
                defaultOfferAmount={otherProfile?.default_offer_amount}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Edit Sent Offer'
    );
  };

  const handleEditSentOffer = () => {
    showMandatoryCompleteProfile(
      () => {
        if (!userCanMakeOffer && isGenerous) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            return setShowCompleteProfileModal(true);
          } else if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            showTooManyPendingOffersErrorModal();
            return false;
          }
        }

        basicModal({
          dateTestId: 'edit-offer',
          modalContentLabel: 'Edit Offer Modal',
          isModalOpen: true,
          modalBody: (
            <Fragment>
              <OfferEdit
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={clearErrors}
                updateOffer={updateOffer}
                closeModal={resetModal}
                currencyInitial={otherAccount.currency.data.initial}
                currencyLabel={otherAccount.currency.data.label_unicode}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                buttonID="editOffer"
                currentOffer={offer.data.price_original}
                successOpen={handleOfferSuccess}
                sex={user && user.profile.data.sex === 'Male' ? 'Female' : 'Male'}
                onCancelAndSendNewOfferHandler={async () => {
                  await ignoreOfferFromList(offer.data);

                  cancelOffer();
                  trackOfferCancelled(offer.data.initiator_hash_id, {
                    Amount: offer.data.price_original,
                    User: user.hash_id,
                    Source: 'sent offers',
                  });

                  handleNewOffer();
                }}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Edit Sent Offer'
    );
  };

  const handleForceOpenCounterModal = () => {
    showMandatoryCompleteProfile(
      () => {
        if (!userCanMakeOffer && isGenerous) {
          if (!accountModel.isMandatoryProfileCompleted()) {
            return setShowCompleteProfileModal(true);
          }

          if (accountModel.shouldShowTooManyLockedOffersDeals()) {
            showTooManyPendingOffersErrorModal();
            return false;
          }

          if (offerIsFromOtherUser && accountModel.shouldShowTooManySentOffers()) {
            showTooManyPendingOffersErrorModal({ type: 'sent' });
            return false;
          }
        }

        basicModal({
          dataTestID: 'offer-accept-message',
          modalContentLabel: 'Offer Accept and Message',
          onCloseClickHandler: counterModalClose,
          modalBody: (
            <Fragment>
              <OfferAccept
                acceptUnlock={handleAcceptUnlock}
                avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                username={otherAccount.username}
                hashId={otherAccount.hash_id}
                clearErrors={() => undefined}
                onHandleSubmit={() => acceptOffer(otherAccount.hash_id, '', pageTitle)}
                successOpen={handleOfferSuccess}
                counterOffer={handleCounterOffer}
                closeModal={resetModal}
                currentUserIsGenerous={isGenerous}
                errors={errors}
                sendSuggestion={() => handleSuggestion(otherAccount)}
                showWarn={showWarn}
                cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                ignoreStatus={() => null}
                justSuggested={false}
                buttonID="acceptOffer"
                offer={offer}
                ignoreOffer={!isReceivedOffersListViewDesktop ? handleForceIgnoreOffer : handleIgnoreOffer}
                forceCounter={true}
              />
              <OfferModalFooter />
            </Fragment>
          ),
        });
      },
      () => undefined,
      'Counter Offer'
    );
  };

  const renderAcceptButton = () => {
    if (!offerIsFromOtherUser) {
      return '';
    }
    if (isSuggestRequest) {
      return renderSendOfferButton();
    }

    return (
      <FavoriteOfferButton
        data-test-id="offer-btn-accept"
        hasOffer={hasOffer}
        offer={offer}
        viewType={viewType}
        type="accept"
        isFromOffer={true}
        onClick={() => {
          if (!userCanMakeOffer && isGenerous) {
            if (!accountModel.isMandatoryProfileCompleted()) {
              return setShowCompleteProfileModal(true);
            }
          }

          showMandatoryCompleteProfile(
            () => {
              basicModal({
                dataTestID: 'offer-success-modal',
                modalContentLabel: 'Success Offer Modal',
                modalBody: (
                  <Fragment>
                    <OfferAccept
                      acceptUnlock={handleAcceptUnlock}
                      avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                      username={otherAccount.username}
                      hashId={otherAccount.hash_id}
                      clearErrors={() => undefined}
                      onHandleSubmit={() => acceptOffer(otherAccount.hash_id, '', pageTitle)}
                      onHandleAttractiveSubmit={handleAttractiveMessage}
                      successOpen={handleOfferSuccess}
                      counterOffer={handleCounterOffer}
                      closeModal={resetModal}
                      currentUserIsGenerous={isGenerous}
                      errors={errors}
                      sendSuggestion={() => handleSuggestion(otherAccount)}
                      showWarn={showWarn}
                      cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                      ignoreStatus={() => null}
                      justSuggested={false}
                      buttonID="acceptOffer"
                      offer={offer}
                      ignoreOffer={!isReceivedOffersListViewDesktop ? handleForceIgnoreOffer : handleIgnoreOffer}
                      pageTitle={pageTitle}
                    />
                    <OfferModalFooter />
                  </Fragment>
                ),
              });
            },
            () => undefined,
            'Accept Offer'
          );
        }}
      />
    );
  };

  const renderSendOfferButton = () => {
    return (
      <FavoriteOfferButton
        data-test-id="offer-btn-offer"
        hasOffer={hasOffer}
        offer={offer}
        viewType={viewType}
        type="offer"
        forceLabel={enableSlider ? 'Make Offer' : null}
        isFromOffer={isReceivedOffersListViewDesktop ? false : true}
        onClick={() => {
          showMandatoryCompleteProfile(
            () => {
              if (!userCanMakeOffer && isGenerous) {
                if (!accountModel.isMandatoryProfileCompleted()) {
                  return setShowCompleteProfileModal(true);
                } else if (accountModel.shouldShowTooManyLockedOffersDeals()) {
                  showTooManyPendingOffersErrorModal();
                  return false;
                }
              }
              basicModal({
                dataTestId: 'offer-modal',
                modalContentLabel: 'Create Offer Modal',
                modalBody: (
                  <Fragment>
                    <FavOfferCreate
                      avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
                      username={otherAccount.username}
                      hashId={otherAccount.hash_id}
                      clearErrors={clearErrors}
                      createNewOffer={handleCreateNewOffer}
                      successOpen={handleOfferSuccess}
                      counterOffer={handleCounterOffer}
                      isCounter={false}
                      closeModal={resetModal}
                      currencyInitial={otherAccount.currency.data.initial}
                      currencyLabel={otherAccount.currency.data.label_unicode}
                      currentUserIsGenerous={isGenerous}
                      errors={errors}
                      sex={otherProfile.sex}
                      sendSuggestion={handleSuggestion}
                      showWarn={showWarn}
                      cannot_make_offer_reasons={user.cannot_make_offer_reasons}
                      buttonID="interestedOffers"
                      isSuggestRequest={isSuggestRequest}
                      title="Make an Offer!"
                      pageTitle={pageTitle}
                      otherProfile={otherProfile}
                    />
                    <OfferModalFooter />
                  </Fragment>
                ),
              });
            },
            () => undefined,
            'Make Offer'
          );
        }}
      />
    );
  };

  const renderDesktopListViewButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <FavoriteOfferButton
          data-test-id="offer-btn-offer"
          hasOffer={hasOffer}
          offer={offer}
          type="decline"
          onClick={!isReceivedOffersListViewDesktop ? handleForceIgnoreOffer : handleIgnoreOffer}
        />
        {isSuggestRequest ? (
          renderSendOfferButton()
        ) : (
          <Fragment>
            <FavoriteOfferButton
              data-test-id="offer-btn-offer"
              hasOffer={hasOffer}
              offer={offer}
              type="counter"
              onClick={handleForceOpenCounterModal}
            />
            <FavoriteOfferButton
              data-test-id="offer-btn-offer"
              hasOffer={hasOffer}
              offer={offer}
              type="accept"
              label="Accept"
              onClick={() => {
                showMandatoryCompleteProfile(
                  () => {
                    if (!userCanMakeOffer && isGenerous) {
                      if (!accountModel.isMandatoryProfileCompleted()) {
                        setShowCompleteProfileModal(true);
                        return false;
                      } else if (accountModel.shouldShowTooManyLockedOffersDeals('accept')) {
                        showTooManyPendingOffersErrorModal();
                        return false;
                      }
                    }
                    confirmModal({
                      includeFooter: true,
                      includeAwarenessText: true,
                      dataTestID: 'offer-accept-offers',
                      modalContentLabel: 'Accept Offer',
                      confirmationProps: {
                        title: `Accept ${isGenerous ? 'Offer' : 'Request'}?`,
                        message: `Are you sure you want to accept the ${
                          isGenerous ? 'offer' : 'request'
                        } from <strong>${otherAccount.username}</strong>?`,
                        confirmText: `Accept ${isGenerous ? 'Offer' : 'Request'}`,
                        confirmAction: async () => {
                          if (isGenerous) {
                            const result = await acceptOffer(otherAccount.hash_id, '', pageTitle);
                            handleAcceptUnlock(true, offer.data.price_original, result);
                          } else {
                            handleAttractiveMessage(offer.data.price_original);
                          }
                        },
                      },
                    });
                  },
                  () => undefined,
                  'Accept Offer'
                );
              }}
            />
          </Fragment>
        )}
      </div>
    );
  };

  const renderSliderButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <FavoriteOfferButton
          data-test-id="offer-btn-offer"
          hasOffer={hasOffer}
          offer={offer}
          type="decline"
          onClick={!isReceivedOffersListViewDesktop ? handleForceIgnoreOffer : handleIgnoreOffer}
        />
        {isSuggestRequest ? (
          <Fragment>
            <OfferAmountButton
              type="received"
              forceLabel="Asked You to Suggest an Amount"
              viewType="list"
              price={formatPrice(
                {
                  initial: offer.data.currency_original.data.initial,
                  label: offer.data.currency_original.data.label_unicode,
                },
                offer.data.price_original
              )}
              hasHoverEffect={false}
              onClick={() => undefined}
            />
            {renderSendOfferButton()}
          </Fragment>
        ) : (
          <Fragment>
            <OfferAmountButton
              type="received"
              viewType={viewType}
              price={formatPrice(
                {
                  initial: offer.data.currency_original.data.initial,
                  label: offer.data.currency_original.data.label_unicode,
                },
                offer.data.price_original
              )}
              hasHoverEffect={!isSuggestRequest && viewType === 'grid'}
              onClick={() => !isSuggestRequest && viewType === 'grid' && handleForceOpenCounterModal()}
            />
            <FavoriteOfferButton
              data-test-id="offer-btn-offer"
              hasOffer={hasOffer}
              offer={offer}
              type="accept"
              label="Accept"
              onClick={() => {
                showMandatoryCompleteProfile(
                  () => {
                    confirmModal({
                      includeFooter: true,
                      includeAwarenessText: true,
                      dataTestID: 'offer-accept-offers',
                      modalContentLabel: 'Accept Offer',
                      confirmationProps: {
                        title: `Accept ${isGenerous ? 'Offer' : 'Request'}?`,
                        message: `Are you sure you want to accept the ${
                          isGenerous ? 'offer' : 'request'
                        } from <strong>${otherAccount.username}</strong>?`,
                        confirmText: `Accept ${isGenerous ? 'Offer' : 'Request'}`,
                        confirmAction: async () => {
                          if (isGenerous) {
                            const result = await acceptOffer(otherAccount.hash_id, '', pageTitle);
                            handleAcceptUnlock(true, offer.data.price_original, result);
                          } else {
                            handleAttractiveMessage(offer.data.price_original);
                          }
                        },
                      },
                    });
                  },
                  () => undefined,
                  'Accept Offer'
                );
              }}
            />
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <OfferActionWrap viewType={viewType}>
      <OfferActionsButtonWrap isChangeView={isReceivedOffersListViewDesktop} enableSlider={enableSlider}>
        {activePage === '/offers/sent' ? (
          <Fragment>
            <FavoriteOfferButton
              data-test-id="offer-btn-offer"
              hasOffer={hasOffer}
              offer={offer}
              viewType={viewType}
              type="cancel"
              isFromOffer={viewType === 'grid' ? true : false}
              onClick={handleCancelSentOffer}
            />
            <Tooltip
              variant="primary"
              htmlElementLabel={`<strong>Edit/Revise</strong> your Offer here`}
              trigger="hover"
              placement="top"
            >
              <OfferAmountButton
                type="sent"
                viewType={viewType}
                price={formatPrice(
                  {
                    initial: offer.data.currency_original.data.initial,
                    label: offer.data.currency_original.data.label_unicode,
                  },
                  offer.data.price_original
                )}
                hasHoverEffect={true}
                onClick={handleEditSentOffer}
              />
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment>
            {!enableSlider ? (
              <Fragment>
                <OfferAmountButton
                  type="received"
                  viewType={viewType}
                  price={formatPrice(
                    {
                      initial: offer.data.currency_original.data.initial,
                      label: offer.data.currency_original.data.label_unicode,
                    },
                    offer.data.price_original
                  )}
                  hasHoverEffect={!isSuggestRequest && viewType === 'grid'}
                  onClick={() => !isSuggestRequest && viewType === 'grid' && handleForceOpenCounterModal()}
                />
                {canSendOffer && renderSendOfferButton()}
                {isReceivedOffersListViewDesktop ? renderDesktopListViewButtons() : renderAcceptButton()}
              </Fragment>
            ) : (
              renderSliderButtons()
            )}
          </Fragment>
        )}
      </OfferActionsButtonWrap>
      <CompleteProfileModalV2
        avatarSrc={renderAvatar(otherProfile.avatar.data.urls, true)}
        username={otherAccount.username}
        location={displayUserLocation(otherProfile, otherUserLoc)}
        age={otherAccount.age}
        editProfileLink={`/profile/${user.hash_id}`}
        isOpen={showCompleteProfileModal}
        onClose={() => setShowCompleteProfileModal(false)}
      />
    </OfferActionWrap>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    declineOfferMessage: message => {
      dispatch(
        displayWarnMessage({
          info: message,
        })
      );
    },
    refreshOffers(route, orderType) {
      dispatch(refreshProfile());
      dispatch(refreshOffers(route, orderType));
    },
  };
};

export default connect(null, mapDispatchToProps)(OfferActionButton);
