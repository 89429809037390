import React from 'react';
import styled from '@emotion/styled';
import colors, { primaryBG, primaryBGDark } from '../../../../../theme/colors';
import { isDarkMode } from '../../../../../utils/helpers';

const ButtonContainer = styled('div')({
  borderRadius: 5,
  width: 131,
  height: 74,
  backgroundColor: isDarkMode() ? primaryBGDark : primaryBG,
  border: isDarkMode() ? 'solid 2px #2272AC' : 'solid 2px #fff',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
  marginLeft: 24,
  marginRight: 24,
  display: 'flex',
  justingContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
});

const ButtonCounterContainer = styled('div')({
  display: 'flex',
  justingContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
});

const ButtonLabelSuggest = styled('span')({
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '-0.28px',
  lineHeight: '17px',
  color: colors['light'].blue.secondary,
  padding: 10,
});

const ButtonLabelOfferPrice = styled('span')({
  fontSize: 42,
  fontWeight: 600,
  letterSpacing: '-1.68px',
  lineHeight: '20px',
  color: colors['light'].blue.secondary,
  marginBottom: 12,
  marginTop: 12,
});

const ButtonLabelCounter = styled('span')({
  fontSize: 11,
  fontWeight: 400,
  letterSpacing: '-0.44px',
  lineHeight: '20px',
  color: '#8F8F8F',
});

interface IProps {
  onClick: () => void;
  variant: 'suggest' | 'counter';
  offerPrice: string;
  currencySymbols: any;
}
const OneTapSwipeOfferSuggestOrCounterButton: React.FC<IProps> = ({
  onClick,
  offerPrice,
  variant,
  currencySymbols,
}: IProps) => {
  const renderLabels = () => {
    switch (variant) {
      case 'suggest':
        return <ButtonLabelSuggest>Asked You to Suggest an Amount</ButtonLabelSuggest>;
      case 'counter':
        return (
          <ButtonCounterContainer>
            <ButtonLabelOfferPrice>
              {currencySymbols.currencyInitial}
              {currencySymbols.currencyLabel}
              {offerPrice}
            </ButtonLabelOfferPrice>
            <ButtonLabelCounter>Counter this Offer?</ButtonLabelCounter>
          </ButtonCounterContainer>
        );
      default:
        return <></>;
    }
  };

  return <ButtonContainer onClick={onClick}>{renderLabels()}</ButtonContainer>;
};

export default OneTapSwipeOfferSuggestOrCounterButton;
