import { PhotoListTypes } from '../../constants';
import { getHeicImage, getImage } from '../../helpers';

interface ImageBlob extends Blob {
  lastModifiedDate?: Date;
  lastModified?: number;
  name?: string;
}

export const cropImage = (url: string): Promise<string> => {
  return new Promise(resolve => {
    const inputImage = new Image();

    inputImage.onload = () => {
      const aspectRatio = 3 / 4;
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;
      const inputImageAspectRatio = inputWidth / inputHeight;

      let outputWidth = inputWidth;
      let outputHeight = inputHeight;
      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      const outputImage = document.createElement('canvas');

      outputImage.width = outputWidth;
      outputImage.height = outputHeight;

      const ctx = outputImage.getContext('2d');
      if (ctx) {
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage.toDataURL());
      } else {
        console.log('ctx is null');
      }
    };

    inputImage.src = url;
  });
};

export function b64toBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export function generateImageBlob(imageFile: string, filename?: string, cb?: () => void): Blob {
  const blobImage: ImageBlob = b64toBlob(imageFile);
  blobImage.lastModifiedDate = new Date();
  blobImage.lastModified = +blobImage.lastModifiedDate;
  blobImage.name = filename || 'img.jpg';
  if (blobImage.size <= 0) {
    if (cb) {
      cb();
    }

    throw Error('GENERATE_BLOB_ERROR');
  }
  return blobImage;
}

export const preparePhotos = async (validFiles: File[]): Promise<string[]> => {
  const promises = [];

  Object.keys(validFiles).forEach(index => {
    const currentFile = validFiles[index];

    if (!currentFile || /^image\/[a-z0-9]*/.test(currentFile.type) === false) {
      return;
    }

    if (['image/heic', 'image/heif'].includes(currentFile.type)) {
      promises.push(getHeicImage(currentFile) as never);
    } else {
      promises.push(getImage(currentFile) as never);
    }
  });

  return await Promise.all(promises);
};

export const preparePhotoMediaList = async (validFiles: File[]): Promise<PhotoListTypes> => {
  const cleanFiles = await preparePhotos(validFiles);

  return cleanFiles.map((file, index) => {
    return {
      index,
      edited: file,
      raw: file,
      private: false,
      primary: false,
    };
  });
};
