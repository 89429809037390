// import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import store from './store';
import { vysionInit, initMixPanel } from './utils/helpers';
import { AppProvider } from './contexts/AppContext';
import { MixpanelProvider } from './contexts/MixpanelContext';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';

vysionInit();
initMixPanel();

const ignoreSentry = (event: Sentry.Event, hint?: Sentry.EventHint) => {
  const error = hint?.originalException as Error & { response?: { isNetworkError?: boolean } };
  return (
    (error instanceof Error && error.message === 'Network Error') ||
    (typeof error !== 'string' && error?.response?.isNetworkError) ||
    event?.exception?.values?.[0]?.module?.includes('node_modules')
  );
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_RELEASE_VERSION,
  integrations: [Sentry.globalHandlersIntegration({ onunhandledrejection: false })],
  ignoreErrors: [
    `NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
    `NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
    /Request failed with status code 409/i,
    /Request failed with status code 401/i,
    /Request failed with status code 403/i,
    /Error: Request failed with status code 409/i,
    /Error: Request failed with status code 401/i,
    /Error: Request failed with status code 403/i,
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  beforeSend(event, hint) {
    if (ignoreSentry(event, hint)) {
      return null;
    }

    return event;
  },
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <AppProvider>
    <MixpanelProvider>
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
      </ReduxProvider>
    </MixpanelProvider>
  </AppProvider>
);
