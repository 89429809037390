import MasterConfig from '../config/Master';
import axios, { AxiosPromise } from 'axios';

const api = MasterConfig.api;

const subscribeRecurringPayment = (payload: any): AxiosPromise => {
  return axios({
    method: 'put',
    url: `${api}/settings/payment/recurring`,
    data: payload,
  });
};

const unSubscribeRecurringPayment = (): AxiosPromise => {
  return axios({
    method: 'delete',
    url: `${api}/settings/payment/recurring`,
    data: {},
  });
};

const getRecurringPayment = (): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${api}/settings/payment/recurring`,
    data: {},
  });
};

const updateStateBasedSettings = ({ key, state }: { key: string; state: boolean }): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/settings/update-state-based-value`,
    data: {
      key,
      state,
    },
  });
};

const resetHiddenDialogs = (): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${api}/settings/reset-hidden-dialogs`,
  });
};

const toggleDarkMode = (payload: any): AxiosPromise => {
  return axios({
    method: 'PUT',
    url: `${api}/settings/darkmode`,
    data: payload,
  });
};

export {
  subscribeRecurringPayment,
  unSubscribeRecurringPayment,
  getRecurringPayment,
  updateStateBasedSettings,
  resetHiddenDialogs,
  toggleDarkMode,
};
