import React, { useState, useEffect } from 'react';
import { renderAvatar, setLocalItem, getLocalItem } from '../../common';
import styled from '@emotion/styled';
import _arrayIncludes from 'lodash/_arrayIncludes';
import store from '../../store';
import moment from 'moment';
import { withPusher } from '../../contexts/PusherContext';
import { PUSHER, NOTIF_TIMEOUT } from '../../config/constants';
import { dimensions } from '../../style';
import ActivityAlert, { OfferAlert } from '../../atoms/activityAlert/ActivityAlert';
import VideoCallAlert from '../../atoms/activityAlert/VideoCallAlert';
import { getResponse } from '../../utils/alerts/responses';
import { updateVideoCallStatus } from '../../sdk';
import { storeVideoInfo, clearVideoInfo, changeCallStatus } from '../../actions/profileActions';
import { notificationExpiration } from '../../config/Master';
import DataLayer from '../../utils/dataLayer';
import { useLocation, useNavigate } from 'react-router-dom';
import { mixpanelTrackPushNotifClicked } from '../../utils/mixpanel/pushNotifClicked';

const NotificationContainer = styled('div')({
  position: 'fixed',
  bottom: '3%',
  left: '1%',
  zIndex: 999,
  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
  },
});

const OfferNotificationContainer = styled('div')({
  position: 'fixed',
  top: '20%',
  left: '50%',
  marginLeft: '-200px',
  zIndex: 999,
  [dimensions.SCREEN_MAX_XS]: {
    width: '100%',
  },
});

const getOfferNotifications = () => {
  return JSON.parse(getLocalItem('notifications_queue')) || [];
};

const storeOfferNotification = notifications => {
  return setLocalItem('notifications_queue', JSON.stringify(notifications));
};

const canDisplayOfferNotifications = () => {
  const displayIn = ['/dashboard', '/search', '/offers', '/favorites'];

  return displayIn.filter(item => document.location.pathname.indexOf(item) > -1).length > 0;
};

const lazilyEvaluator = (cond1, cond2, operator) => {
  switch (operator) {
    case 'and':
      return cond1 && cond2;
    case 'or':
      return cond1 || cond2;
    default:
      return;
  }
};

const hideNotificationAlert = (data, location) => {
  if (data.type === 'mail' && data.action === 'receive' && location.pathname.indexOf(data.data.account.hash_id) > -1) {
    return false;
  }
};

const removeOfferNotificationFromQueue = (offerNotificationsCollection, data, removeOfferNotification) => {
  if (data.action === 'cancel' || data.type === 'counts') {
    if (data.type === 'offers' && data.action === 'cancel') {
      // remove in notification queue
      const offer =
        offerNotificationsCollection.filter(item => item.data.account.hash_id === data.data.account.hash_id) || [];
      if (offer.length > 0) {
        // remove to notification queue
        removeOfferNotification(offer[0].id);
      }
    }
    return false;
  }
};

const manageVideoCallNotification = (
  data,
  offerNotificationsCollection,
  callNotificationsCollection,
  setOfferNotifications,
  setCallNotifications
) => {
  if (data.type === 'video_call') {
    if (offerNotificationsCollection.length > 0) {
      setOfferNotifications([]);
    }

    if (data.action === 'start') {
      callNotificationsCollection.push(data);
      setCallNotifications(callNotificationsCollection);
    } else {
      setCallNotifications([]);
    }

    store.dispatch(storeVideoInfo(data.data.extras));
  }
};

const fetchOfferHilights = (data, getOfferHighlights) => {
  if (data.type === 'offers') {
    getOfferHighlights();
  }
};

const NotificationAlerts = ({ stopListening, bindListener, onRealtimeEvent, isOnCall, getOfferHighlights }) => {
  const [notifications, setNotifications] = useState([]);
  const [callNotifications, setCallNotifications] = useState([]);
  const [offerNotifications, setOfferNotifications] = useState(getOfferNotifications);
  const [currentOfferNotifications, setCurrentOfferNotifications] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    bindListener(() => listen());
    return () => {
      stopListening(() => listen());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listen = () => {
    const { EVENTS } = PUSHER;
    const offerNotificationsCollection = Object.assign([], getOfferNotifications);
    const callNotificationsCollection = Object.assign([], callNotifications);

    onRealtimeEvent(EVENTS.ACCOUNT_ALERTS, data => {
      if (data.type === 'video-event') {
        return false;
      }

      const hideOfferNotify = removeOfferNotificationFromQueue(
        offerNotificationsCollection,
        data,
        removeOfferNotification
      );

      // let's not show the notification alert
      // if the user is on the thread with the one who sends the message
      const hideNotify = hideNotificationAlert(data, location);

      if (lazilyEvaluator(hideOfferNotify === false, hideNotify === false, 'or')) {
        return false;
      }

      manageVideoCallNotification(
        data,
        offerNotificationsCollection,
        callNotificationsCollection,
        setOfferNotifications,
        setCallNotifications
      );

      fetchOfferHilights(data, getOfferHighlights);

      const notificationsCollection = Object.assign([], notifications);
      // capture the accept offers and add queue
      if (lazilyEvaluator(data.action === 'accept', data.type === 'offers', 'and')) {
        if (offerNotificationsCollection.indexOf(data) < 0) {
          data.id = Date.now();
          notificationsCollection.push(data);
          offerNotificationsCollection.push(data);
          storeOfferNotification(offerNotificationsCollection);
        }

        setOfferNotifications(offerNotificationsCollection);
      } else {
        if (lazilyEvaluator(callNotificationsCollection.length > 0, data.type !== 'video_call', 'and')) {
          return false;
        }
        if (notificationsCollection.indexOf(data) < 0) {
          notificationsCollection.push(data);
        }

        setNotifications(notificationsCollection);
      }
    });
  };

  const removeOfferNotification = id => {
    const myOfferNotifications = getOfferNotifications().filter(item => item.id !== id) || [];

    if (myOfferNotifications.length <= 0) {
      setCurrentOfferNotifications(null);
    }

    setOfferNotifications(myOfferNotifications);
    storeOfferNotification(myOfferNotifications);
  };

  const clearOfferNotificartions = () => {
    setOfferNotifications([]);
    storeOfferNotification([]);
    setCurrentOfferNotifications(null);
  };

  const pushDataLayer = action => {
    const profile = store.getState().profile;

    new DataLayer().push({
      event: 'offer_accepted',
      action,
      hashId: profile.hash_id,
      isGenerous: profile.isGenerous,
    });
  };

  const removeNotification = id => {
    if (id > 0) {
      return false;
    }

    setNotifications(notifications.filter(item => item.id !== id));
  };

  const removeVideoCallNotif = (clearVidData = false) => {
    setNotifications([]);

    if (callNotifications.length > 0) {
      setCallNotifications([]);
    }

    if (clearVidData) {
      store.dispatch(clearVideoInfo());
      store.dispatch(changeCallStatus(false));
    }
  };

  const renderOfferNotification = () => {
    if (callNotifications.length > 0) {
      return null;
    }

    const offerNotif = offerNotifications.length > 0 && offerNotifications[0];
    const expirteAt = moment(offerNotif.id).add(notificationExpiration, 's');

    if (
      moment().diff(expirteAt, 'seconds') > 0 ||
      typeof offerNotif.data.extras.currency === 'undefined' ||
      typeof offerNotif.data.extras.avatars === 'undefined'
    ) {
      removeOfferNotification(offerNotif.id);
      return null;
    }

    const { type, action, data } = offerNotif;
    const response = getResponse(type, action, data);

    if (!response) {
      removeOfferNotification(offerNotif.id);
      return null;
    }

    const currency = offerNotif.data.extras.currency.data;
    const offerAmount = `${currency.initial}${String.fromCharCode(currency.label_unicode)}${
      offerNotif.data.extras.offer_amount
    }`;
    const avatars = offerNotif.data.extras.avatars;
    const otherProfile = offerNotif.data.extras.other_profile;

    if (currentOfferNotifications !== offerNotif.id) {
      pushDataLayer('displayed');
      setCurrentOfferNotifications(offerNotif.id);
    }

    return (
      <OfferNotificationContainer>
        <OfferAlert
          color={'white'}
          avatar={renderAvatar(avatars)}
          message={response.label}
          timeout={0}
          onTimeout={() => {
            removeOfferNotification(offerNotif.id);
            pushDataLayer('not_yet');
          }}
          userName={otherProfile.username}
          userAge={otherProfile.age}
          userLocation={`${otherProfile.city}, ${otherProfile.country}`}
          offerAmount={offerAmount}
          onClick={() => {
            removeOfferNotification(offerNotif.id);
            navigate(response.link);
            pushDataLayer('send_message');
          }}
          onClose={() => {
            clearOfferNotificartions();
          }}
        />
      </OfferNotificationContainer>
    );
  };

  const inMailIgnoreMessageAlert = (type, response, id) => {
    if (type === 'mail') {
      const mailUrl = response.link.split('?');
      const inConversation = location.pathname.indexOf(mailUrl[0]) > -1;
      if (inConversation) removeNotification(id);
      return inConversation;
    } else {
      return false;
    }
  };

  const handleAlertBtn = ({ link, type, action }) => {
    if (type === 'gift' && action == 'sent') {
      // to open RG widget with redeem link
      window.location.href = link;
    } else {
      mixpanelTrackPushNotifClicked(type, action);
      navigate(link);
    }
  };

  const shouldDisplayNotification = (type: string, notificationId: number | string) => {
    if (typeof type !== 'string' || type !== 'offers') {
      return true;
    }

    const dontShowInPages = ['/offers/new', '/offers/sent'];
    const normalizedPath = window.location.pathname.split('?')[0]; // Remove query parameters
    const isPageAllowed = !dontShowInPages.includes(normalizedPath);

    if (!isPageAllowed) {
      removeNotification(notificationId);
    }

    return isPageAllowed;
  };

  return (
    <React.Fragment>
      {notifications.map(({ type, action, data, id }, key) => {
        const response = getResponse(type, action, data);

        // do not show alert if on the same conversation and remove from notification
        // const inConversation = location.pathname.indexOf(response.link) > -1;
        // if (inConversation) removeVideoCallNotif();

        const actionsToIgnore = ['timeout', 'ended', 'declined', 'answered'];
        if (!response || _arrayIncludes(actionsToIgnore, response.action)) return;
        if (inMailIgnoreMessageAlert(type, response, id)) return;

        if (type === 'video_call' && response.action === 'start') {
          if (isOnCall) {
            removeVideoCallNotif(true);
            updateVideoCallStatus(response.videoData.video_uid, 'declined', 'user is busy');
            return;
          }

          return (
            <NotificationContainer key={key}>
              <VideoCallAlert
                key={`${key}-${Math.floor(Math.random() * 999)}`}
                profilePhoto={response.profilePhoto}
                callerName={response.callerName}
                label={response.label}
                timeout={30000}
                onTimeout={() => {
                  removeVideoCallNotif(true);
                }}
                onAnswer={() => {
                  removeVideoCallNotif();

                  navigate(response.link, {
                    state: {
                      answerCall: true,
                    },
                  });
                }}
                onDecline={() => {
                  removeVideoCallNotif(true);
                  updateVideoCallStatus(response.videoData.video_uid, 'declined');
                }}
              />
            </NotificationContainer>
          );
        }

        if (type !== 'video_call' && shouldDisplayNotification(type, id)) {
          return (
            <NotificationContainer key={key}>
              <ActivityAlert
                key={key}
                color={response.color}
                avatar={type === 'gift' ? response.giftData.image_url_high_res : data.profile.photo}
                message={response.label}
                actionText={response.action}
                notificationType={type}
                timeout={NOTIF_TIMEOUT}
                onTimeout={() => removeNotification(id)}
                onClick={() => {
                  removeNotification(id);
                  handleAlertBtn({ link: response.link, type, action });
                }}
                hasFavIcon={response.hasFavIcon}
              />
            </NotificationContainer>
          );
        }
      })}

      {canDisplayOfferNotifications() &&
        !isOnCall &&
        offerNotifications &&
        offerNotifications.length > 0 &&
        renderOfferNotification()}
    </React.Fragment>
  );
};

export default withPusher(NotificationAlerts);
