import React, { useState, useRef, useEffect, ReactElement } from 'react';
import { dimensions, spacing } from '../../../style';
import styled from '@emotion/styled';
import { enabledOfferHighlights, nuxEnabled } from '../../../config/Master';
import { setLocalItem, getLocalItem } from '../../../common';
import SkeletonLoader from '../../../atoms/SkeletonLoader';

import OfferHighlightModal from '../../../modules/OfferHighlight/OfferHighlightModal';
import CompleteProfileBanner from '../../blocks/CompleteProfileBanner';
import NuxGuide from '../../blocks/NuxGuide';
import MemberGrid from './MemberGrid';
import CustomGrid from './CustomGrid';
import useUnmount from '../../../hooks/useUnmount';
import useMixPanel from '../../../hooks/useMixPanel';

const DashboardContainer = styled('div')({
  padding: `0 ${spacing.PADDING_SM.padding}`,
  maxWidth: 1400,
  margin: '0 auto',

  [dimensions.SCREEN_MIN_MD]: {
    marginTop: 22,
    marginBottom: 22,
  },
});

interface DashboardProps {
  // activity: any; // WYP-13499 Deprecate ActivityFeed
  recentSearchResults: any;
  recent: any;
  newest: any;
  isFetching: any;
  profile: any;
  offers: any;
  favorites: any;
  featuredProfiles: any;
  isFetchingFavs: any;
  fetchPageNux: any;
  updatePageNux: any;
  skipNuxGuides: any;
}

const Dashboard = ({
  // activity, // WYP-13499 Deprecate ActivityFeed
  recentSearchResults,
  recent,
  newest,
  // isFetching, // WYP-13499 Deprecate ActivityFeed
  profile,
  offers,
  favorites,
  featuredProfiles,
  isFetchingFavs,
  fetchPageNux,
  updatePageNux,
  skipNuxGuides,
  ...rest
}: DashboardProps): ReactElement => {
  const isGenerous = profile && profile.profile && profile.profile.data.account_type.toLowerCase() === 'generous';
  const hasPendingOffers = profile.offer_counts && offers.highlights && offers.highlights.length !== 0;
  const [isModalOpen, setIsOpen] = useState(false);
  // NUX set True for disabled config
  const [isNuxCompleted, setIsNuxCompleted] = useState(true);
  const mounted = useRef();
  const { trackPageView } = useMixPanel();

  useUnmount(() => {
    trackPageView(false, 'dashboard-normal');
  });

  const onClose = () => {
    setIsOpen(false);
    offFirstDateNotification();
  };

  const offFirstDateNotification = () => {
    setLocalItem('wyp_offFirstDateOffers', true);
  };

  const renderOfferModal = () => {
    const offFirstDateOffers = getLocalItem('wyp_offFirstDateOffers');
    (offFirstDateOffers === null || offFirstDateOffers === 'false') && setIsOpen(true);
  };

  useEffect(() => {
    if (!mounted.current && isNuxCompleted === true) {
      // do componentDidMount logic
      mounted.current = true;
      renderOfferModal();
    }
  }, [isNuxCompleted]);

  const renderFavSection = () => {
    if (favorites && favorites.length < 2) return;
    return <CustomGrid id="FavMembers" title="Your Favorites" members={favorites} {...rest} />;
  };

  const renderFeaturedProfiles = () => {
    if (featuredProfiles && featuredProfiles.length < 1) return;

    const gridID = isGenerous ? 'FeatMembers' : 'GenerousMembers';
    const gridTitle = isGenerous ? 'Featured' : 'Very Generous';

    return (
      <CustomGrid id={gridID} size={4} title={gridTitle} members={featuredProfiles} isFeatured firstSection {...rest} />
    );
  };

  /*
  // WYP-13499 Deprecate ActivityFeed
  const renderRactivity = () => {
    return (
      <RecentActivity
        containerId={'RecentActivity'}
        activities={activity}
        isFetching={isFetching}
        isGenerous={isGenerous}
      />
    );
  };*/

  const renderOfferHighlights = () => {
    if (hasPendingOffers && enabledOfferHighlights) {
      return (
        <OfferHighlightModal
          containerId="OfferHighlight"
          profile={profile}
          isFetching={offers.isFetching}
          isGenerous={isGenerous}
          offer={offers.highlights[0]}
          onClose={onClose}
          isOpen={isModalOpen}
          setIsOpen={setIsOpen}
          {...rest}
        />
      );
    }
  };

  const renderCompleteProfileBanner = () => {
    if (profile && !profile.mandatory_profile_completed) {
      return <CompleteProfileBanner type="basic" />;
    }
  };

  const renderNewestNearby = () => {
    return (
      newest &&
      newest.length > 0 && (
        <MemberGrid
          id="NewMembers"
          title="New members"
          criteria={{ distance: 50, order: 'newest' }}
          members={newest}
          firstSection={featuredProfiles && featuredProfiles.length < 1}
          {...rest}
        />
      )
    );
  };

  const renderRecentOnline = () => {
    return (
      recent &&
      recent.length > 0 && (
        <MemberGrid
          id="RecentlyOnline"
          title="Recently online"
          criteria={{ distance: 50, order: 'recent' }}
          members={recent}
          {...rest}
        />
      )
    );
  };

  const completeNux = () => {
    setIsNuxCompleted(true);
  };

  const renderNux = () => {
    return (
      <NuxGuide
        profile={profile}
        route={'/dashboard'}
        fetchPageNux={fetchPageNux}
        updateNuxState={updatePageNux}
        onNuxComplete={completeNux}
        skipNuxGuides={skipNuxGuides}
      />
    );
  };

  return (
    <React.Fragment>
      <DashboardContainer screenTop>
        {renderCompleteProfileBanner()}
        {renderOfferHighlights()}
        {/* {renderRactivity()} // WYP-13499 Deprecate ActivityFeed */}
        {isFetchingFavs ? <SkeletonLoader width="100%" /> : renderFeaturedProfiles()}

        {/* Newest members nearby */}
        {renderNewestNearby()}

        {/* Members recently online */}
        {renderRecentOnline()}

        {/* Favorites Section */}
        {isFetchingFavs ? <SkeletonLoader width="100%" /> : renderFavSection()}

        {/* Recent Searches */}
        {recentSearchResults && Object.keys(recentSearchResults).length > 0 && (
          <MemberGrid id="LastSearch" title="Last Search" members={recentSearchResults} saveSearch {...rest} />
        )}
      </DashboardContainer>
      {nuxEnabled && renderNux()}
    </React.Fragment>
  );
};

export default Dashboard;
